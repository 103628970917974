import _ from 'lodash';

import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import { Button, Chip, IconButton, Stack, TextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import AdvancedIcon from './AdvancedIcon';
import ResetIcon from './ResetIcon';
import { ReactComponent as SearchIcon } from '@/img/bookings/search.svg';

import AdvancedFiltersDrawer from './AdvancedFiltersDrawer.react';
import ExportButton from './ExportButton.react';
import BookingsConstants from '@/constants/BookingsConstants';

import { EXTRAS } from './consts';
import { getLabel, getFilterName } from './helpers/utils';
import ManagementConstants from '@/constants/ManagementConstants';

const format = ManagementConstants.DATE_PICKER_FORMAT;

const BookingsFilterComponent = ({
	status,
	downloadStatus,
	profile,
	bookings,

	initFilters,
	filters,
	setFilters,

	search,
	setSearch,

	initPagination,
	setPagination,

	initSort,
	setSort,

	openDrawer,
	setOpenDrawer,

	handleDeleteTag,
	activeFilters,
}) => {
	const show_export = useMemo(() => {
		const { agent, super_agent, travel_manager } = profile;
		return agent || super_agent || travel_manager;
	}, [profile]);

	const is_agent = useMemo(() => {
		const { agent, super_agent } = profile;
		return agent || super_agent;
	}, [profile]);

	const export_to_csv_disabled = useMemo(() => {
		return !bookings || bookings.length === 0 || status === BookingsConstants.STATUS.BUSY;
	}, [bookings]);

	const [startOpen, setStartOpen] = useState(false);
	const [endOpen, setEndOpen] = useState(false);

	const clearAllClick = () => {
		setPagination(initPagination);
		setSort(initSort);
		setSearch(initFilters.search);
		setFilters(initFilters);
	};

	const onSearchClick = () => {
		if (_.isEmpty(search)) {
			return;
		}

		setPagination(initPagination);
		setSearch(initFilters.search);
		setFilters({ ...filters, search });
	};

	const onSearchEnterClick = (e) => {
		if (e.key === 'Enter') {
			onSearchClick();
		}
	};

	const getTags = () => {
		const tags = Object.keys(filters).map((key, index) => {
			if (key === EXTRAS.AGENCY_BRANCHES) {
				return false;
			}

			const filter_name = getFilterName(key);

			const label = getLabel(key, filters);

			const labelRender = (
				<div className="tag">
					<div>{filter_name}:</div>
					<div>{label}</div>
				</div>
			);

			return (
				!_.isEmpty(filters[key]) && (
					<Chip
						label={labelRender}
						key={`tag-${index}`}
						onDelete={() => handleDeleteTag(key)}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								handleDeleteTag(key);
							}
						}}
						aria-label={`Delete ${filter_name} filter`}
					/>
				)
			);
		});
		const agencyBranches = filters[EXTRAS.AGENCY_BRANCHES].map((id) => {
			const filtered = profile.company_branches.filter(({ _id }) => _id === id);
			if (!filtered.length) return false;
			const branch = filtered[0];
			const labelRender = (
				<div className="tag">
					<div>{getFilterName(EXTRAS.AGENCY_BRANCHES)}:</div>
					<div>
						{branch.name}
						{branch.is_archived ? ' (Archived)' : ''}
					</div>
				</div>
			);

			return (
				<Chip
					label={labelRender}
					key={`tag-${id}`}
					onDelete={() => handleDeleteTag(EXTRAS.AGENCY_BRANCHES, id)}
				/>
			);
		});
		tags.push(...agencyBranches);
		return tags;
	};

	const hasTags = () => {
		return _.some(filters, (value) => !_.isEmpty(value));
	};

	return (
		<div className={classNames('bookings-filters-component', { tags: hasTags() })}>
			<div className="bookings-filters-component__filters">
				<div className="bookings-filters-component__filter search space-right-sm">
					<label htmlFor="search-input">Search</label>
					<TextField
						id="search-input"
						placeholder={
							is_agent
								? 'Find by destination, travelers, client or reservation ID'
								: 'Find by destination, travelers or reservation ID'
						}
						variant="outlined"
						InputProps={{
							endAdornment: (
								<IconButton aria-label="Search" onClick={onSearchClick}>
									<SearchIcon />
								</IconButton>
							),
						}}
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						onKeyDown={onSearchEnterClick}
					/>
				</div>

				<div className="bookings-filters-component__filter when space-right-sm">
					<label htmlFor="when-input">When</label>
					<LocalizationProvider
						dateAdapter={AdapterMoment}
						adapterLocale={ManagementConstants.ADAPTER_MOMENT_LOCALE}
					>
						<DesktopDatePicker
							format={format}
							onClose={() => setStartOpen(false)}
							open={startOpen}
							className="desktop-date-picker"
							slotProps={{
								textField: {
									InputProps: {
										onClick: (e) => {
											e.preventDefault();
											setStartOpen(true);
										},
										onKeyDown: (e) => {
											if (e.key !== 'Tab') {
												e.preventDefault();
												setStartOpen(true);
											}
										},
									},
								},
							}}
							label="Start"
							value={filters.start_date}
							onChange={(date) => {
								setFilters({
									...filters,
									start_date: date,
									// if start_date date is after end_date data, reset end_date date
									...(date.isAfter(filters.end_date) && { end_date: initFilters.end_date }),
								});
							}}
						/>
					</LocalizationProvider>
				</div>

				<div className="bookings-filters-component__filter when no-label space-right-md">
					<LocalizationProvider
						dateAdapter={AdapterMoment}
						adapterLocale={ManagementConstants.ADAPTER_MOMENT_LOCALE}
					>
						<DesktopDatePicker
							format={format}
							onClose={() => setEndOpen(false)}
							open={endOpen}
							className="desktop-date-picker"
							slotProps={{
								textField: {
									InputProps: {
										onClick: (e) => {
											e.preventDefault();
											setEndOpen(true);
										},
										onKeyDown: (e) => {
											if (e.key !== 'Tab') {
												e.preventDefault();
												setStartOpen(true);
											}
										},
									},
								},
							}}
							label="End"
							value={filters.end_date}
							minDate={filters.start_date}
							onChange={(date) => setFilters({ ...filters, end_date: date })}
						/>
					</LocalizationProvider>
				</div>

				<div className="bookings-filters-component__filter advanced no-label space-right-sm">
					<Button
						id="advanced-input"
						variant="outlined"
						size="large"
						startIcon={<AdvancedIcon />}
						onClick={() => setOpenDrawer(true)}
					>
						Advanced Search
					</Button>
				</div>

				<div className="bookings-filters-component__filter clear no-label space-right-sm">
					<Button
						id="clear-input"
						variant="outlined"
						size="large"
						startIcon={<ResetIcon />}
						onClick={clearAllClick}
					>
						Clear All
					</Button>
				</div>

				{show_export && (
					<ExportButton
						profile={profile}
						disabled={export_to_csv_disabled}
						downloadStatus={downloadStatus}
						activeFilters={activeFilters}
					/>
				)}
			</div>
			<div className="bookings-filters-component__tags">
				<Stack direction="row" spacing={1}>
					{getTags()}
				</Stack>
			</div>

			<AdvancedFiltersDrawer
				open={openDrawer}
				onClose={() => setOpenDrawer(false)}
				filters={filters}
				setFilters={setFilters}
				setPagination={setPagination}
				initPagination={initPagination}
				profile={profile}
			/>
		</div>
	);
};
export default BookingsFilterComponent;
