import _ from 'lodash';
import React, { useState, useRef, useEffect } from 'react';
import { FormControl, FormHelperText, OutlinedInput, Button, InputAdornment } from '@mui/material';
import { styled } from '@mui/material/styles';

// Custom styled button that looks like an InputAdornment
const AdornmentButton = styled(Button)(({ theme }) => ({
	minWidth: 'auto',
	padding: theme.spacing(0.5, 1),
	borderRadius: 0,
	borderLeft: `1px solid ${theme.palette.divider}`,
	height: '100%',
	'&:hover': {
		backgroundColor: theme.palette.action.hover,
	},
}));

const SettlePaymentName = ({ booking, updateName, updateNameLocked }) => {
	const [name, setName] = useState('');
	const [isLocked, setIsLocked] = useState(false);
	const inputRef = useRef(null);

	useEffect(() => {
		const { travelers, payment } = booking;
		const { invoice_name } = payment;
		const first_traveler = _.get(travelers, '0');

		if (invoice_name || (first_traveler && (first_traveler?.first_name || first_traveler?.last_name))) {
			const traveler_name = invoice_name || `${first_traveler.first_name} ${first_traveler.last_name}`.trim();

			setName(traveler_name);
			updateName(traveler_name);
			setIsLocked(true);
		}
	}, []);

	useEffect(() => {
		updateNameLocked(isLocked);
	}, [isLocked]);

	const handleNameChange = (e) => {
		const reg = /^[a-zA-Z\s]*$/;
		if (reg.test(e.target.value)) {
			setName(e.target.value);
		}
	};

	const handleActionClick = () => {
		if (isLocked) {
			setIsLocked(false);
			setTimeout(() => inputRef.current.focus(), 0);
		} else {
			setIsLocked(true);
			updateName(name);
		}
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Enter' && name.trim() !== '') {
			setIsLocked(true);
			updateName(name);
		}
	};

	return (
		<FormControl fullWidth variant="outlined" className="name-on-invoice">
			<FormHelperText id="outlined-helper-text">Name on invoice</FormHelperText>
			<OutlinedInput
				id="outlined-adornment-name"
				value={name}
				onChange={handleNameChange}
				onKeyPress={handleKeyPress}
				disabled={isLocked}
				inputRef={inputRef}
				aria-describedby="outlined-helper-text"
				size="small"
				endAdornment={
					name.trim() !== '' && (
						<InputAdornment position="end">
							<AdornmentButton onClick={handleActionClick} disabled={name.trim() === ''} tabIndex={0}>
								{isLocked ? 'Change' : 'Add'}
							</AdornmentButton>
						</InputAdornment>
					)
				}
			/>
		</FormControl>
	);
};

export default SettlePaymentName;
