const _ = require('lodash');
const moment = require('moment');
const { getUserObject } = require('../../services/userService');
const { SUPPORTED_LANGUAGES } = require('../../../../translation/const');
const IGNORED_EVENTS = require('./ignoredEvents');
const APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;
let _active = false;

function boot(profile, options = {}) {
  _active = _.get(profile, 'chat.enabled', false);

  if (_active) {
    const params = {
      app_id: APP_ID,
      ...getUserObject(profile),
      language_override: profile.language || SUPPORTED_LANGUAGES.EN,
    };

    if (options.intercom_settings) {
      Object.assign(params, options.intercom_settings);
    }

    console.log("Called Intercom boot", params);
    window.Intercom("boot", params);
  } else {
    console.log("Intercom boot wasn't called");
  }
}

function update(profile) {
  if (_active && window.Intercom) {
    console.log("Called Intercom update");
    //TODO: update zendesk user
    window.Intercom("update", {
      first_name: profile.first_name, // Full name
      last_name: profile.last_name, // Full name
      email: profile.email, // Email address
    });

  } else {
    console.log("Intercom update wasn't called");
  }
}


function fixFormats(data) {
  let payload = {};
  for (var prop in data) {
    let value = data[prop];
    ///
    /// convert date to intercom date format
    ///
    if ((value instanceof Date || value instanceof moment) && moment(value).isValid(value)) {
      prop = (prop.toLowerCase().endsWith('_at') ? prop : prop + '_at');
      value = moment.utc(moment(value).format('YYYY-MM-DD')).unix();
    }
    payload[prop] = value;
  }
  return payload;
}

// Submits an event using the trackEvent method.
// This will associate the event with the currently logged in user and send it to Intercom.
// The final parameter is a map that can be used to send optional metadata about the event.
function trackEvent(event, metadata) {
  if (IGNORED_EVENTS.includes(event)) {
    return;
  }

  if (_active && window.Intercom) {
    metadata = fixFormats(metadata);
    window.Intercom(
      'trackEvent',
      event,
      metadata
    );
    console.log("Called Intercom trackEvent", event, metadata);
  } else {
    console.log("Intercom trackEvent wasn't called");
  }
}

// When a user logs out of your app, make a shutdown call to notify Intercom and reset the Messenger
function shutdown() {
  if (_active && window.Intercom) {
    console.log("Called Intercom shutdown");
    window.Intercom("shutdown");
  } else {
    console.log("Intercom shutdown wasn't called");
  }
}

module.exports = {
  boot,
  update,
  trackEvent,
  shutdown
};
