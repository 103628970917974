const logoMap = {
	"htzone": "https://i.imgur.com/bBrfEPu.png",
	"arbitrip": "https://s3.amazonaws.com/arbitrip/logos/arbitrip_logo.png",
};

const defaultLogo = logoMap["arbitrip"];


const getLogoBasedOnSubDomain = (() => {
	let cachedLogo = null;

	return function () {
		if (cachedLogo !== null) {
			return cachedLogo;
		}

		// Get the current subdomain
		const host = window.location.hostname;
		const subdomain = host.split('.')[0]; // Supported Structure "sub.xxx.com"

		// Default to arbitrip logo if subdomain is not mapped
		cachedLogo = logoMap[subdomain] || defaultLogo;
		return cachedLogo;
	};
})();

export default getLogoBasedOnSubDomain;
