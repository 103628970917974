import React from 'react';
import PropTypes from 'prop-types';

import { CircularProgress, Dialog, Typography, IconButton, Snackbar, Button } from '@mui/material';

import { ExpandMore as ExpandMoreIcon, Close as CloseIcon, Check as CheckIcon } from '@mui/icons-material';

import withStyles from '@mui/styles/withStyles';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';

import ProfileConstants from '../../../../../constants/ProfileConstants';
import Currencies from 'arbitrip-common/general/utils/Currencies';

import ProfileStore from '../../../../../stores/ProfileStore';
import ProfileActions from '../../../../../actions/ProfileActions';

import UserActions from '../../../../../actions/UserActions';

import Analytics from 'arbitrip-common/client/analytics';

const { hotelsCurrencies } = Currencies;

const snackbar_auto_hide_duration = 3333;
const snackbar_messages = {
	[ProfileConstants.STATUS.INITIAL]: '',
	[ProfileConstants.STATUS.BUSY]: 'Changing display currency...',
	[ProfileConstants.STATUS.SUCCESS]: 'Display currency changed',
	[ProfileConstants.STATUS.FAILED]: 'Display currency change failed',
};

const styles = (theme) => ({
	terms: {
		maxWidth: 550,
	},
	termsButton: {
		textAlign: 'center',
		marginTop: 20,
	},
	loader: {
		marginLeft: 10,
		marginTop: -2,
	},
});

const dialogStyles = (theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
});

const DialogTitle = withStyles(dialogStyles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose} size="large">
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);

function getDisplayCurrencyState() {
	return {
		modal_open: false,
		hover: false,
		snackbar_open: false,
		selected_currency: null,
		show_currency_terms_loader: false,
		approved_currency_terms: ProfileStore.getApprovedCurrencyTerms(),
	};
}
class DisplayCurrencyComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = getDisplayCurrencyState();

		this.approveCurrencyTerms = this.approveCurrencyTerms.bind(this);
		this.handleClickOpen = this.handleClickOpen.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleMouseEnter = this.handleMouseEnter.bind(this);
		this.handleMouseLeave = this.handleMouseLeave.bind(this);
		this.handleSelectCurrency = this.handleSelectCurrency.bind(this);
		this.renderHotelCurrency = this.renderHotelCurrency.bind(this);
		this.handleSnackbarOpen = this.handleSnackbarOpen.bind(this);
		this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this);
		this.handleRetry = this.handleRetry.bind(this);
	}

	handleClickOpen() {
		this.setState({ modal_open: true });
	}

	handleClose() {
		const _self = this;
		setTimeout(() => _self.setState({ modal_open: false }));
	}

	handleMouseEnter() {
		this.setState({ hover: true });
	}

	handleMouseLeave() {
		this.setState({ hover: false });
	}

	handleSelectCurrency(currency) {
		this.setState({ selected_currency: currency });

		if (currency !== this.props.profile.display_currency) {
			Analytics.actions.interactions.changedDisplayCurrency(
				this.props.profile,
				this.props.profile.display_currency,
				currency,
			);
			ProfileActions.changeDisplayCurrency(currency);
		}

		this.handleClose();
	}

	renderHotelCurrency(hc, mainMode) {
		const active = !!hc.main === mainMode && hc.currency === this.props.profile.display_currency;

		return (
			<li key={hc.currency} className={active ? 'active' : ''}>
				<a
					href="#!"
					onClick={() => {
						this.handleSelectCurrency(hc.currency);
					}}
				>
					<em>{hc.currency}</em>
					{hc.display}
					{active ? <CheckIcon className="check-icon" /> : null}
				</a>
			</li>
		);
	}

	handleSnackbarOpen() {
		this.setState({ snackbar_open: true });
	}

	handleCloseSnackbar() {
		this.setState({ snackbar_open: false });
	}

	handleRetry() {
		ProfileActions.changeDisplayCurrency(this.state.selected_currency);
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.profile.display_currency_status === ProfileConstants.STATUS.BUSY &&
			this.props.profile.display_currency_status === ProfileConstants.STATUS.FAILED
		) {
			this.setState({ snackbar_open: true });
		}
	}

	approveCurrencyTerms() {
		this.setState({ show_currency_terms_loader: true });
		UserActions.approveCurrencyTermsForUser()
			.done((res) => {
				this.setState({
					show_currency_terms_loader: false,
					approved_currency_terms: true,
				});
				ProfileActions.updateApprovedCurrencyTerms();
			})
			.fail((err) => {
				console.log(err);
			});
	}

	shouldLockDisplayCurrency(profile) {
		return (
			this.props.reservationPage ||
			// if flag doesn't exist, allow change
			profile.allow_change_display_currency === false
		);
	}

	getDisplayCurrencyRender() {
		const { display_currency } = this.props.profile;
		const { hover } = this.state;
		const hotelCurrency = hotelsCurrencies.find((hc) => hc.currency === display_currency);

		return this.shouldLockDisplayCurrency(this.props) ? (
			<div
				className={`display-currency${hover ? ' hovered' : ''}`}
				title={hotelCurrency ? hotelCurrency.display : ''}
			>
				{
					<React.Fragment>
						<span className="currency">{display_currency}</span>
					</React.Fragment>
				}
			</div>
		) : (
			<div
				className={`display-currency${hover ? ' hovered' : ''}`}
				title={hotelCurrency ? hotelCurrency.display : 'Select currency'}
				onMouseEnter={this.handleMouseEnter}
				onMouseLeave={this.handleMouseLeave}
			>
				{ProfileStore.isDisplayCurrencyBusy() ? (
					<CircularProgress className="busy" size={20} />
				) : (
					<React.Fragment>
						<span
							className="currency"
							aria-label={`Change currency. Current currency is ${display_currency}`}
						>
							{display_currency}
						</span>
						<ExpandMoreIcon />
					</React.Fragment>
				)}
			</div>
		);
	}

	getCurrencyListRender() {
		const _self = this;

		return (
			<React.Fragment>
				<ul className="currency-list main">
					{hotelsCurrencies
						.filter((hc) => hc.main)
						.sort((a, b) => a.order - b.order)
						.map((hc) => _self.renderHotelCurrency(hc, true))}
				</ul>

				<ul className="currency-list">
					{hotelsCurrencies
						.filter((hc) => !hc.main)
						.sort((hc) => hc.currency)
						.map((hc) => _self.renderHotelCurrency(hc, false))}
				</ul>
			</React.Fragment>
		);
	}

	getApproveCurrencyTerms() {
		const { classes } = this.props;
		const { show_currency_terms_loader } = this.state;

		return (
			<div className={classes.terms}>
				Changing the currency will only show an approximation of the final price. However, you will still be
				charged in USD. Before making the payment you will be provided with the exact price in USD.
				<br />
				<div className={classes.termsButton}>
					<Button onClick={this.approveCurrencyTerms} color="primary" variant="outlined">
						I Understand{' '}
						{show_currency_terms_loader && <CircularProgress className={classes.loader} size={12} />}
					</Button>
				</div>
			</div>
		);
	}

	getDialogRender() {
		const { modal_open, approved_currency_terms } = this.state;

		return (
			<Dialog
				onClose={this.handleClose}
				aria-labelledby="customized-dialog-title"
				open={modal_open}
				PaperProps={{ className: 'display-currency-dialog-paper' }}
			>
				{approved_currency_terms && (
					<DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
						Select currency:
					</DialogTitle>
				)}
				<DialogContent dividers>
					{approved_currency_terms ? this.getCurrencyListRender() : this.getApproveCurrencyTerms()}
				</DialogContent>
			</Dialog>
		);
	}

	getSnackbarRender() {
		const { profile } = this.props;
		const { snackbar_open } = this.state;

		return (
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				open={snackbar_open}
				autoHideDuration={snackbar_auto_hide_duration}
				onClose={this.handleCloseSnackbar}
				message={snackbar_messages[profile.display_currency_status]}
				action={
					<React.Fragment>
						<Button color="primary" size="small" onClick={this.handleRetry}>
							RETRY
						</Button>
						<IconButton size="small" aria-label="close" color="inherit" onClick={this.handleCloseSnackbar}>
							<CloseIcon fontSize="small" />
						</IconButton>
					</React.Fragment>
				}
			/>
		);
	}

	render() {
		const lockCurrency = this.shouldLockDisplayCurrency(this.props);

		return (
			<button className="display-currency-container" onClick={lockCurrency ? () => {} : this.handleClickOpen}>
				{this.getDisplayCurrencyRender()}

				{this.getDialogRender()}

				{this.getSnackbarRender()}
			</button>
		);
	}
}

DisplayCurrencyComponent.propTypes = {
	profile: PropTypes.object,
	classes: PropTypes.object,
};

export default withStyles(styles)(DisplayCurrencyComponent);
