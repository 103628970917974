import AppDispatcher from '../dispatcher/AppDispatcher';
import BasketConstants from '../constants/BasketConstants';
import Ajax from '../utils/Ajax';
import Basket from 'arbitrip-common/client/entities/Basket';

const BasketActions = {
	updateAgentPricing: function (deal, agentPricing) {
		AppDispatcher.handleAction({
			actionType: BasketConstants.UPDATE_AGENT_PRICING_ON_BASKET_DEAL,
			data: {
				deal,
				agent_pricing: agentPricing,
			},
		});
	},

	initializeBasket: function (profile) {
		AppDispatcher.handleAction({
			actionType: BasketConstants.INITIALIZE_BASKET,
			data: profile,
		});
	},

	basketChanged: function () {
		AppDispatcher.handleAction({
			actionType: BasketConstants.BASKET_CHANGED,
		});
	},

	openBasket: function () {
		AppDispatcher.handleAction({
			actionType: BasketConstants.OPEN_BASKET,
		});
	},

	closeBasket: function () {
		AppDispatcher.handleAction({
			actionType: BasketConstants.CLOSE_BASKET,
		});
	},

	addEmail: function (email) {
		AppDispatcher.handleAction({
			actionType: BasketConstants.ADD_EMAIL,
			data: email,
		});
	},

	removeEmail: function (email) {
		AppDispatcher.handleAction({
			actionType: BasketConstants.REMOVE_EMAIL,
			data: email,
		});
	},

	updateName: function (name) {
		AppDispatcher.handleAction({
			actionType: BasketConstants.UPDATE_NAME,
			data: name,
		});
	},

	updateRemarks: function (remarks) {
		AppDispatcher.handleAction({
			actionType: BasketConstants.UPDATE_REMARKS,
			data: remarks,
		});
	},

	updateSendToCombtas: function (send_to_combtas) {
		AppDispatcher.handleAction({
			actionType: BasketConstants.UPDATE_SEND_TO_COMBTAS,
			data: send_to_combtas,
		});
	},

	updateCombtasId: function (combtas_id) {
		AppDispatcher.handleAction({
			actionType: BasketConstants.UPDATE_COMBTAS_ID,
			data: combtas_id,
		});
	},

	addHotelDeal: function (hotel, deal, travel_policy_exists, profile, contract_client_company_id) {
		AppDispatcher.handleAction({
			actionType: BasketConstants.ADD_HOTEL_DEAL,
			data: {
				hotel,
				deal,
				travel_policy_exists,
				profile,
				contract_client_company_id,
			},
		});
	},

	removeHotelDeal: function (deal) {
		AppDispatcher.handleAction({
			actionType: BasketConstants.REMOVE_HOTEL_DEAL,
			data: deal,
		});
	},

	clearBasket: function () {
		AppDispatcher.handleAction({
			actionType: BasketConstants.CLEAR_BASKET,
		});
	},

	sendBasket: function (basket) {
		AppDispatcher.handleAction({
			actionType: BasketConstants.SEND,
			data: {
				basket,
				status: BasketConstants.STATUS.BUSY,
			},
		});

		Ajax.sendBasket(Basket.reformatPayload(basket))
			.done(function (res) {
				AppDispatcher.handleAction({
					actionType: BasketConstants.SEND,
					data: {
						basket,
						status: BasketConstants.STATUS.SUCCESS,
						response: res,
					},
				});
			})
			.fail(function (err) {
				console.error('Error while sending basket: ', basket, ' | ERR: ', err);
				AppDispatcher.handleAction({
					actionType: BasketConstants.SEND,
					data: {
						basket,
						status: BasketConstants.STATUS.FAILED,
						error: err,
					},
				});
			});
	},
};

export default BasketActions;
