const getTravelPolicyEntryObject = (entry) => {
  if (!entry) {
    return;
  }

  const { place, price } = entry;
  const { city, state, country, countryCode, placeId } = place;

  return {
    ...(price && { price }),
    ...(city && { city }),
    ...(state && { state }),
    ...(country && { country }),
    ...(countryCode && { country_code: countryCode }),
    ...(placeId && { place_id: placeId }),
  };
};

module.exports = {
  getTravelPolicyEntryObject
};

