import React from 'react';
import createClass from 'create-react-class';
import BookingStatus from '../bookings/BookingStatus.react';
import moment from 'moment';
import Currencies from '../../../utils/Currencies';

function formatDate(date) {
	return date && moment(date).format('MMM D');
}

const PreviewDetailedAccommodation = createClass({
	displayName: 'PreviewDetailedAccommodation',

	render: function () {
		let { accommodation } = this.props;
		let { status, hotel, deal, travelers } = accommodation;

		console.log('PreviewDetailedAccommodation', accommodation);

		return (
			<div className="accommodation">
				<div className="title">
					<span className="hotel-name">{hotel.name.toLowerCase()}</span>
				</div>
				<div className="sub-title">
					<i className="fa fa-map-marker" />
					<span className="address">{hotel.address.toLowerCase()}</span>
					<span className="city">{hotel.city.toLowerCase()}</span>
					<span className={'country' + (hotel.country.length <= 3 ? ' initials' : '')}>
						{hotel.country.toLowerCase()}
					</span>
				</div>
				<div className="status-container">
					<BookingStatus status={status} />
				</div>

				{/*<div className="pic">*/}
				{/*</div>*/}

				<div className="section reservation-details">
					<div className="title">
						<span>Reservation Details:</span>
					</div>
					<div className="section-content">
						<div className="deal">
							<div className="field check-in">
								<span className="field-name">Check-in:</span>
								<span className="field-value">{formatDate(deal.details.check_in)}</span>
							</div>
							<div className="field check-out">
								<span className="field-name">Check-out:</span>
								<span className="field-value">{formatDate(deal.details.check_out)}</span>
							</div>
							<div className="field nights">
								<span className="field-value">
									{deal.details.nights +
										' ' +
										(deal.details.nights === 1 ? 'Night' : 'Nights') +
										' Stay'}
								</span>
							</div>
							{deal.details.breakfast_included ? (
								<div className="field breakfast">
									<span className="field-icon">
										<i className="fa fa-coffee" />
									</span>
									<span className="field-value">Breakfast Included</span>
								</div>
							) : null}
							<div className="field travelers-rooms">
								{Array.isArray(travelers) && travelers.length ? (
									<span className="field-value">
										{travelers.length + ' ' + (travelers.length === 1 ? 'Traveler' : 'Travelers')}
									</span>
								) : null}
								<span className="field-value">
									{deal.details.room_count + ' ' + (deal.details.room_count === 1 ? 'Room' : 'Rooms')}
								</span>
							</div>
							<div className="field room-description">
								<span className="field-value">{deal.details.room_description}</span>
							</div>
						</div>
						<div className="meta">
							<div className="field reservation-id">
								<span className="field-name">Booking ID:</span>
								<span className="field-value">{accommodation.group_id}</span>
							</div>
							<div className="field created-by">
								<span className="field-name">Created By:</span>
								<span className="field-value">{accommodation.creator}</span>
							</div>
							<div className="field creation-date">
								<span className="field-name">Creation Date:</span>
								<span className="field-value">{formatDate(accommodation.creation_date)}</span>
							</div>
							<div className="field cost">
								<span className="field-name">Cost:</span>
								<span className="field-value">
									{Currencies.getPriceWithCurrency(
										deal.cheapopoPricing.cheapopoTotalPrice,
										deal.cheapopoPricing.currency,
									)}
								</span>
							</div>
						</div>
					</div>
				</div>

				<div className="section cancellation-policy">
					<div className="title">
						<span>Cancellation Policy:</span>
					</div>
					<div className="section-content">
						<div className="policy">
							<span>{deal.dca.cancellationPoliciesText}</span>
						</div>
					</div>
				</div>

				<div className="section terms-of-supplier">
					<div className="title">
						<span>Terms of Supplier:</span>
					</div>
					<div className="section-content">
						<div className="terms">
							<span>{deal.terms === '' ? 'Not available' : deal.terms}</span>
						</div>
					</div>
				</div>

				{accommodation.specialRequests ? (
					<div className="section special-requests">
						<div className="title">
							<span>Special Requests:</span>
						</div>
						<div className="section-content">
							<div className="requests">
								<span>{accommodation.specialRequests}</span>
							</div>
						</div>
					</div>
				) : null}
			</div>
		);
	},
});

export default PreviewDetailedAccommodation;
