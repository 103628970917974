import AppDispatcher from '../dispatcher/AppDispatcher';
import { EventEmitter } from 'events';
import FooterBarConstants from '../constants/FooterBarConstants';
import _ from 'lodash';

// Define initial data points
var _data = {};

// Method to load result data from mock API
function loadFooterBarData(data) {
	_data = data;
}

// Extend Search Store with EventEmitter to add eventing capabilities
var FooterBarStore = _.extend({}, EventEmitter.prototype, {
	// Return search results
	getItems: function () {
		return _data;
	},

	// Return # of results in search
	getItemCount: function () {
		return Object.keys(_data).length;
	},

	// TODO: method for currency conversion

	// Emit Change event
	emitChange: function () {
		this.emit('change');
	},

	// Add change listener
	addChangeListener: function (callback) {
		this.on('change', callback);
	},

	// Remove change listener
	removeChangeListener: function (callback) {
		this.removeListener('change', callback);
	},
});

// Register callback with AppDispatcher
AppDispatcher.register(function (payload) {
	var action = payload.action;
	//var text;

	switch (action.actionType) {
		// Respond to RECEIVE_DATA action
		case FooterBarConstants.RECEIVE_FOOTER_BAR_DATA:
			loadFooterBarData(action.data);
			break;

		default:
			return true;
	}

	// If action was responded to, emit change event
	FooterBarStore.emitChange();

	return true;
});

export default FooterBarStore;
