const { ReservationConstants } = require('arbitrip-common/client/constants');
const _ = require('lodash');

function isRequestActionable(ticket) {
	return (
		ticket.status !== ReservationConstants.REQUEST_STATUS.APPROVED &&
		ticket.status !== ReservationConstants.REQUEST_STATUS.REJECTED &&
		ticket.status !== ReservationConstants.REQUEST_STATUS.EXPIRED
	);
}

function getBreakfastIncluded(reservation) {
	return (
		_.get(reservation, 'deal.dca.heuristicOptions.includesBreakfast') ||
		_.get(reservation, 'deal.dca.heuristicOptions.isHalfBoard') ||
		_.get(reservation, 'deal.dca.heuristicOptions.isFullBoard') ||
		_.get(reservation, 'deal.dca.heuristicOptions.isAllInclusive')
	);
}

module.exports = {
	isRequestActionable,
	getBreakfastIncluded,
};
