import React from 'react';
import createClass from 'create-react-class';
import moment from 'moment';
import _ from 'lodash';

import ReservationStore from '../../../stores/ReservationStore';
import PaymentStore from '../../../stores/PaymentStore';

import Poller from '../../../utils/Poller';
import MaterialDesignDialog from '../../general/MaterialDesignDialog.react';
import Config from 'arbitrip-common/client/utils/Config';
import ReservationActions from '../../../actions/ReservationActions';
import ReservationConstants from '../../../constants/ReservationConstants';

const credit_card_url_state_path = 'paymentData.creditCardUrl';
const payment_session_state_path = 'paymentData.payment_session_response';

const threeds_iframe_url = Config.prod
	? 'https://static.pay.expedia.com/3ds/threeDsIframe.html'
	: 'https://static.pay.expedia.com/3ds/sandboxThreeDsIframe.html';

function getCreditCardComponentState() {
	return {
		reservationData: ReservationStore.getReservationData(),
		paymentData: PaymentStore.getPaymentData(),
		didBook: false,
		errorMessage: '',
		isChallenging: false,
		isFinishing: false,
		isFinished: false,
		expediaBookingData: ReservationStore.expediaBookingResponse(),
		expediaBookingDataStatus: ReservationStore.expediaBookingResponseStatus(),
	};
}

function isFinished(state) {
	return (
		[ReservationConstants.STATUS.BOOKED, ReservationConstants.STATUS.FAILED].includes(
			state.reservationData.status,
		) && !state.isFinished
	);
}

const ExpediaCreditCardComponent = createClass({
	displayName: 'ExpediaCreditCardComponent',

	getInitialState: function () {
		return getCreditCardComponentState();
	},

	componentDidMount: function () {
		ReservationStore.addChangeListener(this._onChange);
		PaymentStore.addChangeListener(this._onChange);
		if (isFinished(this.state)) {
			this.setState({
				isFinished: true,
			});
		}
	},

	componentDidUpdate: function (prevProps, prevState) {
		if (isFinished(this.state)) {
			this.setState({
				isFinished: true,
			});
			return;
		}
		if (!_.get(prevState, credit_card_url_state_path) && _.get(this.state, credit_card_url_state_path)) {
			Poller.startPaymentStatusPolling();
		}

		if (!_.get(prevState, payment_session_state_path) && _.get(this.state, payment_session_state_path)) {
			this.handlePaymentSession();
		}

		const busy = ReservationConstants.STATUS.BUSY;
		if (prevState.expediaBookingDataStatus === busy && this.state.expediaBookingDataStatus !== busy) {
			this.handleBookingResponse();
		}

		if (this.state.reservationData.status === ReservationConstants.STATUS.BOOKED && !this.state.isFinished) {
			this.setState({
				isFinished: true,
			});
		}
	},

	componentWillUnmount: function () {
		Poller.stopPaymentStatusPolling();

		PaymentStore.removeChangeListener(this._onChange);
		ReservationStore.removeChangeListener(this._onChange);
	},

	handlePaymentSession: async function () {
		try {
			const paymentSession = _.get(this.state, payment_session_state_path, {});
			const paymentSessionId = paymentSession.payment_session_id;
			if (paymentSession.encoded_init_config) {
				await this.props.payThreeDSConnectorHelper.cashier3DSinitSession(
					paymentSessionId,
					paymentSession.encoded_init_config,
				);
			}
			ReservationActions.createExpediaBooking({
				reservation: { id: this.state.reservationData.id },
				payment_type: 'expedia_merchant_of_record',
			});
		} catch (err) {
			console.error(err);
			this.setState({
				errorMessage: err.message,
			});
		}
	},

	handleBookingResponse: async function () {
		console.log('Create Booking Response: ', this.state.expediaBookingData);
		const createBookingResponse = this.state.expediaBookingData;
		const paymentSession = _.get(this.state, payment_session_state_path, {});
		const paymentSessionId = paymentSession?.payment_session_id;
		try {
			if (createBookingResponse?.encoded_challenge_config) {
				this.setState({
					isChallenging: true,
				});
				await this.props.payThreeDSConnectorHelper.executeChallenge(
					paymentSessionId,
					createBookingResponse.encoded_challenge_config,
				);
			}
			this.setState({
				isFinishing: true,
				isChallenging: false,
			});
			ReservationActions.completeExpediaSession({
				reservation: { id: this.state.reservationData.id },
				payment_type: 'expedia_merchant_of_record',
			});
		} catch (err) {
			console.error(err);
			this.setState({
				errorMessage: err.message,
			});
		}
	},

	closeErrorModal: function () {
		this.setState({
			errorMessage: '',
		});
	},

	render: function () {
		let charge_date_text = 'Please note: Your credit card will be charged immediately.';
		const charge_date = moment(this.state.reservationData.if_credit_card_charge_date);
		if (charge_date?.diff(moment(), 'hours') > 24) {
			const sum_in_charge_currency = _.get(this.state.paymentData, 'sum_in_charge_currency');
			const charge_currency = _.get(this.state.paymentData, 'charge_currency');
			if (sum_in_charge_currency && sum_in_charge_currency) {
				charge_date_text = `Please note: Your credit card will be charged ${sum_in_charge_currency} (${charge_currency}) on ${charge_date.format('DD MMM YYYY')}.`;
			} else {
				charge_date_text = `Please note: Your credit card will be charged on ${charge_date.format('DD MMM YYYY')}.`;
			}
		}

		const { errorMessage, isChallenging, isFinishing, isFinished } = this.state;

		return (
			<div className="credit-card-component pci">
				{this.state.paymentData.creditCardUrl && !isChallenging && !isFinishing && !isFinished
					? [
							<div key="cccd" className="credit-card-charge-date">
								{charge_date_text}
							</div>,
							<div key="ccif" className="iframe-container pci">
								<iframe
									title="credit-card-iframe"
									id="credit-card-iframe"
									src={this.state.paymentData.creditCardUrl}
									frameBorder="0"
									scrolling="no"
									width={'100%'}
									height={'100%'}
								></iframe>
							</div>,
						]
					: null}
				<div className={isChallenging ? 'iframe-container threeDS' : 'iframe-container threeDS-hidden'}>
					<iframe
						id={this.props.payThreeDSConnectorHelper.threeds_iframe_id}
						src={threeds_iframe_url}
						width={isChallenging ? '100%' : '0'}
						height={isChallenging ? '100%' : '0'}
						frameBorder="0"
						scrolling="no"
						sandbox="allow-scripts allow-forms allow-same-origin"
					/>
				</div>
				{isFinishing && (
					<div className="processing-payment">
						<span>Processing the payment...</span>
						<i className="fa fa-spin fa-spinner fa-2x" />
					</div>
				)}
				<MaterialDesignDialog
					open={!!errorMessage}
					onClose={this.closeErrorModal}
					title={'BookingDetails!'}
					content={
						<div>
							<h3>We got an error while processing your payment</h3>
							<div>{errorMessage}</div>
						</div>
					}
					actions={[{ onClick: this.closeErrorModal, text: 'Got it' }]}
				/>
			</div>
		);
	},

	_onChange: function () {
		this.setState(getCreditCardComponentState());
	},
});

export default ExpediaCreditCardComponent;
