import _ from 'lodash';
import { useMemo, useState, useRef } from 'react';
import { CircularProgress, Menu, MenuItem } from '@mui/material';
import { ReactComponent as DownloadIcon } from '@/img/bookings/download.svg';
import { ReactComponent as DownloadIconBlack } from '@/img/bookings/download-b.svg';
import { CSV_EXPORT_TYPES } from 'arbitrip-common/general/constants';
import BookingsActions from '@/actions/BookingsActions';
import Analytics from 'arbitrip-common/client/analytics';
import BookingsConstants from '@/constants/BookingsConstants';

const NewExportButton = ({ profile, disabled, downloadStatus, activeFilters, activeTab }) => {
	const [exportAnchorEl, setExportAnchorEl] = useState(null);

	const exportButtonRef = useRef(null);

	const priority_export_enabled = useMemo(() => {
		return _.get(profile, 'company.settings', false);
	}, [profile]);

	const onExportClick = (e) => {
		setExportAnchorEl(e.currentTarget);
	};

	const onExportClose = () => {
		setExportAnchorEl(null);
	};

	const onDownloadBookingsCsvClick = (exportType) => {
		const company_id = _.get(profile, 'company.id');
		const is_agent = _.get(profile, 'agent') || _.get(profile, 'super_agent');

		BookingsActions.downloadedBookingsCsv(is_agent, activeFilters, exportType, activeTab);
		Analytics.actions.interactions.downloadedBookingsCsv(company_id, is_agent, activeFilters);
	};

	const onExportMenuChoice = (exportType) => {
		onExportClose();
		onDownloadBookingsCsvClick(exportType);
	};

	const isBusy = () => downloadStatus === BookingsConstants.STATUS.BUSY;

	return (
		<div className="new-bookings-filters-component__filter export">
			<div
				tabIndex={0}
				role="button"
				aria-disabled={disabled || isBusy()}
				aria-controls="dropdown-menu"
				aria-haspopup="true"
				onClick={(e) => {
					if (!disabled && !isBusy()) {
						priority_export_enabled ? onExportClick(e) : onDownloadBookingsCsvClick(CSV_EXPORT_TYPES.CSV);
					}
				}}
				ref={exportButtonRef}
				onKeyDown={(e) => {
					if (!disabled && !isBusy() && (e.key === 'Enter' || e.key === ' ')) {
						e.preventDefault();
						priority_export_enabled ? onExportClick(e) : onDownloadBookingsCsvClick(CSV_EXPORT_TYPES.CSV);
					}
				}}
				style={{
					cursor: disabled || isBusy() ? 'not-allowed' : 'pointer',
					display: 'flex',
					alignItems: 'center',
				}}
			>
				{isBusy() ? (
					<div className="busy-indicator">
						<CircularProgress size={16} />
					</div>
				) : (
					<DownloadIcon />
				)}
			</div>
			{priority_export_enabled && (
				<Menu
					id="csv-dropdown-menu"
					anchorEl={exportAnchorEl}
					open={Boolean(exportAnchorEl)}
					onClose={onExportClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
				>
					<MenuItem onClick={() => onExportMenuChoice(CSV_EXPORT_TYPES.CSV)}>
						<DownloadIconBlack className="menu-item-icon" /> Export CSV
					</MenuItem>
					<MenuItem onClick={() => onExportMenuChoice(CSV_EXPORT_TYPES.PRIORITY)}>
						<DownloadIconBlack className="menu-item-icon" /> Export CSV (Priority ERP)
					</MenuItem>
				</Menu>
			)}
		</div>
	);
};

export default NewExportButton;
