import ManagementConstants from '../constants/ManagementConstants';

function CarReservation(car, display_order) {
	this.id = car.id;

	this.rental_company = car.rental_company;

	this.pick_up = car.pick_up;
	// this.airport = car.airport;
	this.drop_off = car.drop_off;

	this.pick_up_date = car.pick_up_date;
	this.drop_off_date = car.drop_off_date;

	// TODO: rental period (# of calendar days) ?

	this.currency = car.currency;
	this.price = car.price;

	this.component_type = ManagementConstants.TRIP_REQUEST_COMPONENT_TYPE.CAR;
	this.display_order = display_order;
}

export default CarReservation;
