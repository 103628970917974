import AppDispatcher from '../dispatcher/AppDispatcher';
import MapConstants from '../constants/MapConstants';

var MapActions = {
	updateInfoBubble: function (infoBubble, marker, hotel) {
		AppDispatcher.handleAction({
			actionType: MapConstants.UPDATE_INFO_BUBBLE,
			data: {
				infoBubble: infoBubble,
				marker: marker,
				hotel: hotel,
			},
		});
	},

	clearMapData: function () {
		AppDispatcher.handleAction({
			actionType: MapConstants.CLEAR_MAP_DATA,
		});
	},

	clearMapFilters: function () {
		AppDispatcher.handleAction({
			actionType: MapConstants.CLEAR_MAP_FILTERS,
		});
	},

	updateCenter: function (center) {
		AppDispatcher.handleAction({
			actionType: MapConstants.UPDATE_CENTER,
			data: center,
		});
	},

	updateZoom: function (zoom) {
		AppDispatcher.handleAction({
			actionType: MapConstants.UPDATE_ZOOM,
			data: zoom,
		});
	},

	updateSelectedHotelId: function (hotelId) {
		AppDispatcher.handleAction({
			actionType: MapConstants.UPDATE_SELECTED_HOTEL_ID,
			data: hotelId,
		});
	},

	toggleFullMapMode: function () {
		AppDispatcher.handleAction({
			actionType: MapConstants.TOGGLE_FULL_MAP_MODE,
		});
	},

	// Receive initial places data
	getPlaces: function (data) {
		AppDispatcher.handleAction({
			actionType: MapConstants.RECEIVE_MAP_DATA,
			data: data, // ???
		});
	},
};

export default MapActions;
