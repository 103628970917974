import { styled } from '@mui/material/styles';

const ActionButton = styled('a')(({ theme }) => ({
	width: 42,
	height: 42,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	borderRadius: '4px',
	cursor: 'pointer',
	backgroundColor: theme.palette.gray.gray5,

	'&:hover': {
		backgroundColor: theme.palette.blue.lightBlue,
	},
}));

export const TripActionButton = ({ children, onClick }) => {
	return <ActionButton onClick={onClick}>{children}</ActionButton>;
};
