// import ManagementConstants from '../constants/ManagementConstants';
//
// function FlightReservation(flight, display_order) {
//   this.id = flight.id;
//
//   this.airline = flight.airline;
//
//   this.from = flight.from;
//   this.to = flight.to;
//   // this.one_way = flight.one_way;
//
//   this.depart_date = flight.depart_date;
//   this.depart_time = flight.depart_time;// || "Any";
//   // this.return_date = flight.return_date;
//   // this.return_time = flight.return_time;
//
//   this.duration = flight.duration;
//
//   this.currency = flight.currency;
//   this.price = flight.price;
//
//   this.component_type = ManagementConstants.TRIP_REQUEST_COMPONENT_TYPE.FLIGHT;
//   this.display_order = display_order;
// }
//
// export default FlightReservation;

import Airport from './Airport';

function FlightReservation(flight, display_order) {
	if (!flight) {
		return;
	}

	this.id = flight._id;
	this.status = flight.status;
	this.display_order = display_order || flight.display_order;
	this.airline_code = flight.airline_code;
	this.airline_name = flight.airline_name;
	this.number = flight.flight_number;
	this._number = this.number.replace(this.airline_code, '');
	this.class = flight.class;
	this.duration_hours = flight.duration_hours;

	// departure fields
	this.depart_airport = new Airport(flight.depart_airport);
	this.depart_date = flight.depart_date;
	this.depart_time = flight.depart_time;
	this.depart_terminal = flight.depart_terminal;

	// arrival fields
	this.arrival_airport = new Airport(flight.arrival_airport);
	this.arrival_date = flight.arrival_date;
	this.arrival_time = flight.arrival_time;
	this.arrival_terminal = flight.arrival_terminal;

	this.is_parsed = this.depart_time && this.arrival_date;
}

export default FlightReservation;
