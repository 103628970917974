const StarsComponent = ({ stars: propStars, mode }) => {
	const stars = parseFloat(propStars);
	if (isNaN(stars) || stars <= 0) {
		return null;
	}

	const keyBase = mode === 'filter' ? 'filter-star-component-' : 'star-component-';

	const EL = [];

	for (let i = 1; i <= 5; i++) {
		if (i <= stars) {
			EL.push(<div className="star full" key={keyBase + i}></div>);
		} else if (stars + 0.5 === i) {
			EL.push(<div className="star half" key={keyBase + i}></div>);
		} else {
			EL.push(<div className="star empty" key={keyBase + i}></div>);
		}
	}

	return <div className="stars">{EL}</div>;
};

export default StarsComponent;
