import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import ChangeClientPrice from './ChangeClientPrice.react';

const DEFAULT_DIALOG_TITLE = 'Change Client Price';

const ChangeClientPriceDialog = ({
	dialogOpen = false,
	dialogTitle = DEFAULT_DIALOG_TITLE,

	deal,
	initializing,
	disabled,

	onCancel,
	onUpdate,

	profile,

	basketMode,
}) => (
	<Dialog
		open={dialogOpen}
		className="change-client-price"
		{...(basketMode ? { classes: { root: 'basket-agent-pricing' } } : {})}
	>
		<DialogTitle>
			<div className="change-client-price-title">{dialogTitle}</div>
		</DialogTitle>
		<DialogContent>
			<ChangeClientPrice
				deal={deal}
				initializing={initializing}
				disabled={disabled}
				onCancel={onCancel}
				onUpdate={onUpdate}
				profile={profile}
				basketMode={basketMode}
			/>
		</DialogContent>
	</Dialog>
);

ChangeClientPriceDialog.propTypes = {
	dialogOpen: PropTypes.bool,
	dialogTitle: PropTypes.string,

	deal: PropTypes.object.isRequired,
	initializing: PropTypes.bool,
	disabled: PropTypes.bool,

	onCancel: PropTypes.func,
	onUpdate: PropTypes.func,

	profile: PropTypes.object,

	basketMode: PropTypes.bool,
};

export default ChangeClientPriceDialog;
