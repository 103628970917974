import ManagementConstants from '@/constants/ManagementConstants';

const draft_regex = new RegExp('draft', 'i');
const new_request_regex = new RegExp('new request', 'i');
const pending_regex = new RegExp('pending|waiting', 'i');
const approved_regex = new RegExp('approved', 'i');
const canceled_regex = new RegExp('canceled', 'i');
const rejected_regex = new RegExp('rejected', 'i');
const declined_regex = new RegExp('declined', 'i');
const forbidden_characters_regex = new RegExp('[-_]', 'g');

function normalizeTripStatus(status) {
	if (typeof status === 'string') {
		return status.replace(forbidden_characters_regex, ' ');
	}
	return '';
}

const TripStatusComponent = ({ trip, icon_only, show_sub_status, ongoing }) => {
	let status = trip.status;
	let approval_str = '';

	if (show_sub_status && !icon_only) {
		if (status === ManagementConstants.TRIP_STATUS.PENDING) {
			approval_str = trip.waiting_for_pre_approval
				? ' (Waiting for initial approval)'
				: trip.waiting_for_post_approval
					? ' (Waiting for final confirmation)'
					: '';
			if (!approval_str) {
				approval_str = trip.post_approved ? '' : trip.pre_approved ? '(Initial Approval)' : '';
			}
		} else if (status === ManagementConstants.TRIP_STATUS.DECLINED) {
			approval_str = trip.pre_rejected ? ' (Initial Approval)' : '';
		}
	}

	let status_class = 'status-icon';
	if (draft_regex.test(status)) {
		status_class += ' draft';
	} else if (new_request_regex.test(status)) {
		status_class += ' new-request';
	} else if (pending_regex.test(status)) {
		status_class += ' pending';
	} else if (approved_regex.test(status)) {
		status_class += ' approved';
		if (ongoing) {
			status_class += ' ongoing';
			status = 'Ongoing';
		}
	} else if (canceled_regex.test(status)) {
		status_class += ' cancelled';
	} else if (rejected_regex.test(status)) {
		status_class += ' rejected';
	} else if (declined_regex.test(status)) {
		status_class += ' declined';
	}

	return (
		<div className={`trip-status-component${icon_only ? ' icon-only' : ''}`}>
			<span className={status_class} title={status}>
				<i className="fa fa-circle" aria-hidden="true" />
			</span>
			{!icon_only && <span className="status-text">{normalizeTripStatus(status)}</span>}
			{approval_str && <span className="approval-status">{approval_str}</span>}
		</div>
	);
};

export default TripStatusComponent;
