import { useMemo } from 'react';
import HotelService from '../../services/hotel/HotelService';

const override_max_reasons = 10;

const HotelReasons = ({ hotel }) => {
	const REASONS = useMemo(() => HotelService.getReasonsRender(hotel, override_max_reasons), [hotel]);

	if (REASONS.length === 0) return null;

	return (
		<div className="reasons">
			<ul>{REASONS}</ul>
		</div>
	);
};

export default HotelReasons;
