import _ from 'lodash';

const place_types = {
    // table 1
    'accounting': true,
    'airport': true, // indicates an airport.
    'amusement_park': true,
    'aquarium': true,
    'art_gallery': true,
    'atm': true,
    'bakery': true,
    'bank': true,
    'bar': true,
    'beauty_salon': true,
    'bicycle_store': true,
    'book_store': true,
    'bowling_alley': true,
    'bus_station': true, // indicates the location of a bus stop.
    'cafe': true,
    'campground': true,
    'car_dealer': true,
    'car_rental': true,
    'car_repair': true,
    'car_wash': true,
    'casino': true,
    'cemetery': true,
    'church': true, // indicates the location of a church.
    'city_hall': true,
    'clothing_store': true,
    'convenience_store': true,
    'courthouse': true,
    'dentist': true,
    'department_store': true,
    'doctor': true,
    'drugstore': true,
    'electrician': true,
    'electronics_store': true,
    'embassy': true,
    'fire_station': true,
    'florist': true,
    'funeral_home': true,
    'furniture_store': true,
    'gas_station': true,
    'gym': true,
    'hair_care': true,
    'hardware_store': true,
    'hindu_temple': true,
    'home_goods_store': true,
    'hospital': true,
    'insurance_agency': true,
    'jewelry_store': true,
    'laundry': true,
    'lawyer': true,
    'library': true,
    'light_rail_station': true, // indicates the location of a light rail station.
    'liquor_store': true,
    'local_government_office': true,
    'locksmith': true,
    'lodging': true,
    'meal_delivery': true,
    'meal_takeaway': true,
    'mosque': true,
    'movie_rental': true,
    'movie_theater': true,
    'moving_company': true,
    'museum': true,
    'night_club': true,
    'painter': true,
    'park': true, // indicates a named park.
    'parking': true,
    'pet_store': true,
    'pharmacy': true,
    'physiotherapist': true,
    'plumber': true,
    'police': true,
    'post_office': true, // indicates the location of a post office.
    'primary_school': true,
    'real_estate_agency': true,
    'restaurant': true,
    'roofing_contractor': true,
    'rv_park': true,
    'school': true,
    'secondary_school': true,
    'shoe_store': true,
    'shopping_mall': true,
    'spa': true,
    'stadium': true,
    'storage': true,
    'store': true,
    'subway_station': true, // indicates the location of a subway station.
    'supermarket': true,
    'synagogue': true,
    'taxi_stand': true,
    'tourist_attraction': true,
    'train_station': true, // indicates the location of a train station.
    'transit_station': true, // indicates the location of a transit station.
    'travel_agency': true,
    'university': true, // indicates a university.
    'veterinary_care': true,
    'zoo': true,

    // table 2
    'administrative_area_level_1': false, // indicates a first-order civil entity below the country level.
    'administrative_area_level_2': false, // indicates a second-order civil entity below the country level
    'administrative_area_level_3': false, // indicates a third-order civil entity below the country level.
    'administrative_area_level_4': false, // indicates a fourth-order civil entity below the country level.
    'administrative_area_level_5': false, // indicates a fifth-order civil entity below the country level.
    'archipelago': false,
    'colloquial_area': true, // indicates a commonly-used alternative name for the entity.
    'continent': false, // indicates a continent.
    'country': false, // indicates the national political entity, and is typically the highest order type returned by the Geocoder.
    'establishment': false, // typically indicates a place that has not yet been categorized.
    'finance': true, // indicates the location of a finance institute.
    'floor': true, // indicates the floor in the address of the building.
    'food': false,
    'general_contractor': false,
    'geocode': false,
    'health': false,
    'intersection': true, // indicates a major intersection, usually of two major roads.
    'landmark': false,
    'locality': false, // indicates an incorporated city or town political entity.
    'natural_feature': false, // indicates a prominent natural feature.
    'neighborhood': true, // indicates a named neighborhood.
    'place_of_worship': true,
    'plus_code': false,
    'point_of_interest': true, // indicates a named point of interest.
    'political': false, // indicates a political entity.
    'post_box': false,
    'postal_code': false, // indicates a postal code as used to address postal mail within the country.
    'postal_code_prefix': false, // indicates a postal code prefix as used to address postal mail within the country.
    'postal_code_suffix': false,
    'postal_town': false, // indicates a grouping of geographic areas, such as locality and sublocality, used for mailing addresses in some countries.
    'premise': true, // indicates a named location, usually a building or collection of buildings with a common name.
    'room': true, // indicates the room in the address of the building
    'route': true, // indicates a named route (such as "US 101").
    'street_address': true, // indicates a precise street address.
    'street_number': true, // indicates a precise street number.
    'sublocality': false, // indicates a first-order civil entity below a locality.
    'sublocality_level_1': true,
    'sublocality_level_2': true,
    'sublocality_level_3': true,
    'sublocality_level_4': true,
    'sublocality_level_5': true,
    'subpremise': true, // indicates a first-order entity below a named location, usually a singular building within a collection of buildings with a common name
    'town_square': true,
};

function isSpecificPlace(place) {
    if (!place) {
        return false;
    }

    if (place.hotel_id) {
        return true;
    }

    if (place.place_id) {
        if (Array.isArray(place.types)) {
            return place.types.some(t => place_types[t]);
        }
    }

    return false;
}

// const autocomplete_types = [

// ]
const RADIAN = Math.PI / 180;
const RADIUS = 6371;

function getDistanceBetweenCoordinates(lat1, lon1, lat2, lon2) {
    const dLat = (lat2 - lat1) * RADIAN;
    const dLon = (lon2 - lon1) * RADIAN;
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * RADIAN) * Math.cos(lat2 * RADIAN) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return RADIUS * c;
}

function getAddressDistanceDescription(hotel, place) {
    const force_address = false;
    const full_address = `${hotel.address}, ${hotel.city}`
    const distance_in_km = _.get(hotel, 'distanceInKm', null);
    const headline = _.get(hotel, 'text_descriptions.headline', null);
    const neighborhood_name = _.get(hotel, 'alternative_distance.neighborhood.name', null);
    const alternative_distance_neighborhood = neighborhood_name
        ? `Near ${neighborhood_name}`
        : null;

    const specific_place = isSpecificPlace(place);
    // const address_description = address;
    // const distance_description = distanceInKm > 0 ? `(${Number(distanceInKm).toFixed(1)} km)` : '';
    const address_description = (specific_place || force_address)
        ? full_address
        : headline || alternative_distance_neighborhood || full_address;
    const distance_description = (distance_in_km > 0)
        ? `${Math.round(Number(distance_in_km) * 10) / 10} km from ${specific_place ? 'destination' : 'center'}`
        : '';

    let address_distance_description = address_description;
    if (distance_description) {
        address_distance_description += ` (${distance_description})`;
    }

    return address_distance_description;
}

function isIsrael(place) {
    if (place) {
        if (place.place_id) {
            if (place.country_code === 'IL') {
                return true;
            }

            const address_components = _.get(place, 'address_components', []);
            for (const ac of address_components) {
                const types = _.get(ac, 'types', []);
                if (types.includes('country')) {
                    if (ac.short_name === 'IL') {
                        return true;
                    }
                    if (ac.long_name === 'Israel') {
                        return true;
                    }
                }
            }
        } else if (place.hotel_id) {
            if (place.country_code === 'IL') {
                return true;
            }
        }
    }

    return false;
}

export {
    place_types,
    isSpecificPlace,
    getDistanceBetweenCoordinates,
    getAddressDistanceDescription,
    isIsrael
};
