import _ from 'lodash';
import OfflineDB from './OfflineDB';
import Fetch from './Fetch';
import Config from './Config';

import PointOfInterest from '../entities/PointOfInterest';
import ApiURLs from '../constants/ApiURLs';
// import WebStorageManager from '../../../src/utils/WebStorageManager';
// const WebStorageManager = require('./WebStorageManager');

const { objectifyPointOfInterestId } = require('./PointOfInterestUtils');

import { SUPPORTED_LANGUAGES } from '../../translation/const';
import { hebrew_letters } from "../../general/constants/regexes";
const { SEARCH_AUTOCOMPLETE_PLACE } = ApiURLs(Config.api_urls);

const places_fields = ['address_components', 'formatted_address', 'geometry', 'id', 'name', 'place_id', 'types'];
const extra_fields = ['category', 'location', 'hotel_id'];

const pickObjectKeys = (obj, keys) => (Array.isArray(keys) ? keys : [keys])
    .map(k => k in obj ? { [k]: obj[k] } : {})
    .reduce((res, o) => Object.assign(res, o), {});

function normalizePOI(data) {
    let place = Array.isArray(data)
        ? data[0]
        : data;

    return new PointOfInterest(place);
}

function isPointOfInterestValid(poi) {
    return poi
        && (poi.place_id || poi.hotel_id)
        && poi.location
        && poi.location.lat
        && poi.location.lng
        && poi.address;
}

function getPointOfInterest(payload) {
    return new Promise(async (resolve, reject) => {
        if (payload) {
            const {
                hotel_id,
                place_id,
                description
            } = payload;

            let getPointOfInterestFromOfflineDB = () => reject();
            if (hotel_id) {
                getPointOfInterestFromOfflineDB = () => OfflineDB.getPointOfInterestHotel(hotel_id);
            } else if (place_id) {
                getPointOfInterestFromOfflineDB = () => OfflineDB.getPointOfInterestPlace(place_id);
            }

            try {
                const db_place = await getPointOfInterestFromOfflineDB();
                if (db_place) {
                    // WebStorageManager.updateInWebStorage('_recommendation_place', db_place);
                    return resolve(db_place);
                }

                if (hotel_id) {
                    const db_poi = normalizePOI(payload);
                    if (isPointOfInterestValid(db_poi)) {
                        // WebStorageManager.updateInWebStorage('_recommendation_place', db_poi);
                        return resolve(db_poi);
                    }
                }

                let poi_id_object = objectifyPointOfInterestId(hotel_id, place_id);
                if (_.isEmpty(poi_id_object)) {
                    return reject('getPointOfInterest ::: no hotel_id nor place_id params');
                }

                if (description) {
                    const language = hebrew_letters.test(description) ? SUPPORTED_LANGUAGES.HE : SUPPORTED_LANGUAGES.EN;

                    Object.assign(poi_id_object, { language });
                }

                const api_place = await Fetch.get(SEARCH_AUTOCOMPLETE_PLACE, poi_id_object);
                if (api_place) {
                    const { data } = api_place;
                    if (data) {
                        const api_poi = normalizePOI(data);
                        if (isPointOfInterestValid(api_poi)) {
                            if (api_poi.hotel_id) {
                                OfflineDB.setPointOfInterestHotel(api_poi.hotel_id, api_poi);
                            } else if (api_poi.place_id) {
                                OfflineDB.setPointOfInterestPlace(api_poi.place_id, api_poi);
                            } else {
                                return reject('getPointOfInterest ::: valid poi but no poi id');
                            }
                            // WebStorageManager.updateInWebStorage('_recommendation_place', api_poi);
                            return resolve(api_poi);
                        } else {
                            return reject('getPointOfInterest ::: invalid api poi');
                        }
                    } else {
                        return reject('getPointOfInterest ::: no data in api poi');
                    }
                } else {
                    return reject('getPointOfInterest ::: no api poi');
                }
            } catch (err) {
                return reject(err);
            }
        }
    });
}

function dietPlace(place) {
    return pickObjectKeys(place, places_fields.concat(extra_fields));
}

export {
    getPointOfInterest,
    dietPlace,
    objectifyPointOfInterestId
};
