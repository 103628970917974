import React from 'react';
import { getFormattedExpirationTime } from '@/utils/TasId';

const CombtasBar = (props) => {
	const { tasId, endCombtasSession } = props;
	const expirationTime = getFormattedExpirationTime();

	return (
		<div className="combtas-bar">
			<div className="combtas-session">
				Booking session for TAS ID: <span className="tas-id">{tasId}</span>
				{expirationTime && <span className="end-time">Ends at {expirationTime}</span>}
			</div>
			<button className="end-session-button" onClick={() => endCombtasSession()}>
				End now
			</button>
		</div>
	);
};

export default CombtasBar;
