function stripProtocol(url) {
  var noProtocolMatch = url
    ? url.match(/(https?:)?(\/\/.*)/)
    : null;

  return (noProtocolMatch && noProtocolMatch.length > 1)
    ? noProtocolMatch[2]
    : url;
}

module.exports = { stripProtocol };
