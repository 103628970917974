export const formatPlaceName = (place) => {
	if (!place) {
		return '';
	}

	if (place.city && place.state) {
		return `${place.city}, ${place.state}`;
	} else if (place.city) {
		return place.city;
	} else if (place.state) {
		return place.state;
	}
	return place.country;
};

export const doesSearchMatchCountry = (searchQuery, countryName) => {
	return countryName.toLowerCase().includes(searchQuery.toLowerCase());
};
