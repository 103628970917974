let web_storage;

function storageAvailable(type) {
  try {
    var storage = window[type],
      x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  }
  catch (e) {
    return e instanceof DOMException && (
      // everything except Firefox
      e.code === 22 ||
      // Firefox
      e.code === 1014 ||
      // test name field too, because code might not be present
      // everything except Firefox
      e.name === 'QuotaExceededError' ||
      // Firefox
      e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage.length !== 0;
  }
}

function setWebStorage() {
  if (storageAvailable('localStorage')) {
    // Yippee! We can use localStorage awesomeness
    web_storage = localStorage;
  } else {
    // Too bad, no localStorage for us
  }
}
setWebStorage();

// if (storageAvailable('sessionStorage')) {
//   web_storage = sessionStorage;
// }

function getFromWebStorage(keyName) {
  // setWebStorage();
  if (web_storage && web_storage.getItem) {
    try {
      return JSON.parse(web_storage.getItem(keyName));
    } catch (e) {
      return null;
    }
  }
}

function addToWebStorage(keyName, keyValue) {
  if (web_storage && web_storage.setItem) {
    web_storage.setItem(keyName, JSON.stringify(keyValue));
  }
}

function updateInWebStorage(keyName, keyValue) {
  web_storage[keyName] = JSON.stringify(keyValue);
}

function removeFromWebStorage(keyName) {
  if (web_storage && web_storage.removeItem) {
    web_storage.removeItem(keyName);
  }
}

function clearWebStorage() {
  if (web_storage && web_storage.clear) {
    web_storage.clear();
  }
}

module.exports = {
  getFromWebStorage,
  addToWebStorage,
  updateInWebStorage,
  removeFromWebStorage,
  clearWebStorage
};
