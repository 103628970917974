import _ from 'lodash';
import Fetch from "./Fetch";
import RmsURLs from "../constants/RmsURLs";
import { SEARCH_SESSION_EVENT } from "../constants/SearchSessionConstants";
import Config from './Config';
import ApiURLs from '../constants/ApiURLs';

const { CATALOG } = ApiURLs(Config.api_urls);

const throttle = _.throttle((self, func, ...args) => {
    let f = func.bind(self);
    f(...args);
}, 3000);

let settings_saved = false;
let _enable_room_mapping = false;
let _enable_room_mapping_debug = false;
let _enable_room_mapping_optimization = false;
let _jwt_token = '';

class RoomMappingService {
    run(searchSession, hotel_id) {
        if (!settings_saved) {
            _enable_room_mapping = _.get(searchSession, 'room_mapping.settings.enable_room_mapping', false);
            _enable_room_mapping_debug = _.get(searchSession, 'room_mapping.settings.enable_room_mapping_debug', false);
            _enable_room_mapping_optimization = _.get(searchSession, 'room_mapping.settings.enable_room_mapping_optimization', false);
            _jwt_token = _.get(searchSession, 'room_mapping.settings.room_mapping_jwt_token', '');

            settings_saved = true;
        }

        throttle(this, this.setSupplierRoomsData, searchSession, hotel_id);
    }

    async setSupplierRoomsData(searchSession, hotel_id) {
        if (!_enable_room_mapping) {
            return;
        }
        
        let vocabulary = _.get(searchSession, 'room_mapping.vocabulary');
        if (!vocabulary) {
            console.warn('setSuppliersRooms: vocabulary not exists, can\'t run room mapping');
            return;
        }

        try {
            let _hotel = searchSession.search_results.getHotelById(hotel_id);

            if (!_hotel || !_hotel.hasDeals()) {
                console.warn('setSupplierRoomsData: no hotel or hotel deals are found');
                return;
            }

            let room_suppliers = [];

            if (_.isArray(_hotel.deals)) {
                _hotel.deals.forEach(deal => {
                    room_suppliers.push({
                        breakfast: _.get(deal, 'breakfast'),
                        board_code: _.get(deal, 'details.board_code'),
                        cancel_policy: _.get(deal, 'cancelPolicies'),
                        currency: _.get(deal, 'currency'),
                        deal_id: _.get(deal, 'id'),
                        name: _.get(deal, 'details.room_description'),
                        price: _.get(deal, 'pricePerNight'),
                        room_id: _.get(deal, 'room_id'),
                        refundable: _.get(deal, 'refundable')
                    });
                });
            }

            searchSession.room_mapping.addRoomSuppliers(room_suppliers);

            if (!_enable_room_mapping_optimization) {
                this.compress(searchSession, _hotel);
            } else {
                this.optimize(searchSession, _hotel);
            }
        } catch (e) {
            console.error(e);
        }
    }

    async compress(searchSession, _hotel) {
        if (!_enable_room_mapping) {
            return;
        }

        if (!searchSession.room_mapping.room_suppliers.length) {
            console.warn('optimize: no room mapping for hotel.');
            return;
        }

        let data = await this.post(RmsURLs.COMPRESS, searchSession, _hotel);

        if (data && data.results) {
            let mapping_data = {};
            data.results.forEach(room => {
                mapping_data[room.deal_id] = room;
            });
            searchSession.room_mapping.addMapping(mapping_data);

            searchSession.search_results.markRoomMappingSupersededDeals(searchSession, _hotel.id);

            searchSession.notifyEvent(SEARCH_SESSION_EVENT.ROOM_MAPPING);
        }
    }

    async optimize(searchSession, _hotel) {
        if (!_enable_room_mapping) {
            return;
        }

        if (!searchSession.room_mapping.room_suppliers.length) {
            console.warn('optimize: no room mapping for hotel.');
            return;
        }

        let data = await this.post(RmsURLs.OPTIMIZE, searchSession, _hotel);

        if (data && data.results) {
            let mapping_data = {};
            data.results.forEach(room => {
                mapping_data[room.deal_id] = room;
            });
            searchSession.room_mapping.addMapping(mapping_data);

            searchSession.search_results.markRoomMappingSupersededDeals(searchSession, _hotel.id);

            searchSession.notifyEvent(SEARCH_SESSION_EVENT.ROOM_MAPPING);
        }
    }

    async post(url, searchSession, _hotel) {
        const hotel_id = _.get(_hotel, 'id') || _.get(searchSession, 'search_terms.destination.place.hotel_id');
        if (!hotel_id) {
            console.warn('RoomMappingService ::: no hotel_id found');
            return;
        }

        let params = {
            hotel_id,
            proposals: searchSession.room_mapping.room_suppliers
        };

        if (_enable_room_mapping_debug) {
            let vocabulary = _.get(searchSession, 'room_mapping.vocabulary');
            if (vocabulary) {
                params.vocabulary = _.get(searchSession, 'room_mapping.vocabulary');
            }
        }

        let headers = {
            Authorization: `bearer ${_jwt_token}`
        };

        return await Fetch.post(url, params, headers);
    }

    flush(searchSession, hotel_id) {
        // there is no context when flush is called
        // so we need to check enable_room_mapping flag from source
        _enable_room_mapping = _.get(searchSession, 'room_mapping.settings.enable_room_mapping', false);
        if (!_enable_room_mapping) {
            return;
        }

        searchSession.search_results.markRoomMappingSupersededDeals(searchSession, hotel_id);
        searchSession.room_mapping.setFinishedProcess(true);

        searchSession.notifyEvent(SEARCH_SESSION_EVENT.COMPLETED);
        searchSession.notifyEvent(SEARCH_SESSION_EVENT.ROOM_MAPPING_COMPLETED);

        throttle.flush();
    }

    async setUnifiedCatalog(searchSession, hotel_id) {
        let catalog = await Fetch.get(`${CATALOG}/${hotel_id}`, {});

        if (catalog && catalog.merged_catalog) {
            searchSession.room_mapping.setUnifiedCatalog(catalog.merged_catalog);

            searchSession.notifyEvent();
        } else {
            console.error('setUnifiedCatalog ::: no catalog found');
        }
    }

    async setServerVocabulary(searchSession) {
        try {
            let headers = this.getHeaders(searchSession);
            let vocabulary = await Fetch.post(RmsURLs.DICTIONARY, {}, headers);

            searchSession.room_mapping.setVocabulary(vocabulary);
        } catch (e) {
            console.error(e);
        }
    }

    getHeaders(searchSession) {
        if (!_jwt_token) {
            _jwt_token = _.get(searchSession, 'room_mapping.settings.room_mapping_jwt_token', '');
        }

        return {
            Authorization: `bearer ${_jwt_token}`
        };
    }
}

export default new RoomMappingService();