const Events = require('../../events/index');

const IGNORED_EVENTS = [
  ...Object.values(Events.TRAVEL_POLICY),
  Events.VIEWS.VIEWED_TRAVEL_POLICY_PAGE,
  Events.VIEWS.OPEN_DOUBLE_BOOKING_DIALOG,
  Events.SEARCH.GEO_LOCATION_DESTINATION_SELECTED,
];

module.exports = IGNORED_EVENTS;
