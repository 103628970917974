import ProfileConstants from '../constants/ProfileConstants';
import Ajax from '../utils/Ajax';
import WebStorageManager from '../utils/WebStorageManager';

import AppDispatcher from '../dispatcher/AppDispatcher';
import Profile from '../entities/Profile';

import TravelAgentConstants from '../constants/TravelAgentConstants';
import AuthConstants from '../constants/AuthConstants';
import _ from 'lodash';
import Validator from 'arbitrip-common/client/utils/Validator';

import Analytics from 'arbitrip-common/client/analytics';

import { EventEmitter } from 'events';
import SearchStore from './SearchStore';
import PointsActions from '../actions/PointsActions';

import { sendUserToSentry } from '@/utils/SentryConfig';
import Config from 'arbitrip-common/client/utils/Config';
import i18n from 'arbitrip-common/translation/desktop.js';
import { addCompanyTranslation } from 'arbitrip-common/theme/translation/helpers';
import { TRANSLATION_ENV } from 'arbitrip-common/translation/const';
import { COUNTRY_CODES } from '@arbitrip/constants';
import { setUser as sendUserToClarity } from 'arbitrip-common/client/analytics/providers/clarity';

let _profile = {};
let _room_mapping_settings = null;
let _active_session = true;
let _save_profile_status = ProfileConstants.STATUS.INITIAL;

initStore();

function unsetProfile() {
	cleanWebStorage();
	// _profile = null;
	_active_session = false;
	ProfileStore.emitChange();
}

function addThemeTranslation(data) {
	const company_id = _.get(data, 'company.id');
	addCompanyTranslation(TRANSLATION_ENV.DESKTOP, company_id, i18n.addResourceBundle);
}

function cleanWebStorage() {
	WebStorageManager.removeFromWebStorage(_lsKey);
}

function markSessionTimeout() {
	_active_session = false;

	Analytics.actions.sessions.sessionHasExpired(_profile);
}

function updateContract(contract) {
	let agency_contract = _profile.agency_contracts.find((c) => c._id === contract._id);
	if (agency_contract) {
		Object.assign(agency_contract, contract && contract['pricing_config']);
	}
}

const ProfileStore = _.extend({}, EventEmitter.prototype, {
	clear() {
		cleanWebStorage();
		_profile = null;
	},

	getSearchResultsDelayInSeconds() {
		return _.get(_profile, 'company.settings.search_results_delay_in_seconds');
	},

	isLeisureExperienceEnabled() {
		if (_profile) {
			if (_.isFunction(_profile.isLeisureExperienceEnabled)) {
				return _profile.isLeisureExperienceEnabled();
			}

			return _.get(_profile, 'company.settings.leisure_experience', false);
		}

		return false;
	},

	hasArbitripPoints() {
		if (_profile) {
			if (_.isFunction(_profile.hasArbitripPoints)) {
				return _profile.hasArbitripPoints();
			}

			return _.get(_profile, 'bank.balance', 0) > 0;
		}

		return false;
	},

	isPrivateTravelSearchEnabled() {
		return _profile && _profile.private_travel_search_enabled;
	},

	isPrivateTravelOnly() {
		return _profile && _.isFunction(_profile.isPrivateTravelOnly) && _profile.isPrivateTravelOnly();
	},

	isPrivateTravel() {
		return _profile && _.isFunction(_profile.isPrivateTravel) && _profile.isPrivateTravel();
	},

	forcePrivateTravel() {
		if (!_profile || !_.isFunction(_profile.forcePrivateTravel)) {
			return console.warn('ProfileStore.forcePrivateTravel() ::: bad or missing profile', _profile);
		}

		return _profile.forcePrivateTravel();
	},

	getApprovedCurrencyTerms() {
		if (_profile?.company?.partners_ui?.expedia_partner) {
			return true;
		}
		if (_profile?.approved_currency_terms) {
			return true;
		}
		return false;
	},

	getAllowChangeCurrencyDisplay() {
		return _profile.allow_change_currency_display || false;
	},

	getContractClientCompany(contract_id) {
		if (!_profile) {
			console.warn('ProfileStore.getContractClientCompany() ::: bad or missing profile', _profile);
		} else if (Array.isArray(_profile.agency_contracts)) {
			let contract = _profile.agency_contracts.find((c) => c && c._id === contract_id);
			if (contract) {
				return contract.company;
			}
		}
		return null;
	},
	getSelfOrFirstContract() {
		if (_profile && Array.isArray(_profile.agency_contracts_dashboard)) {
			// if (_profile.access_all_company_reservations) {
			//     return _profile.agency_contracts_dashboard.find(c => c.agency);
			// }
			return _profile.agency_contracts_dashboard.find((c) => c.agency) || _profile.agency_contracts_dashboard[0];
		}
		return null;
	},
	shouldDisplayPricingExtraInfo(enablePricingExtraInfo, contractClientCompanyId) {
		const pricing_extra_info_enabled =
			enablePricingExtraInfo || _.get(_profile, 'enable_pricing_extra_info', false);
		if (_.isArray(_.get(_profile, 'agency_contracts'))) {
			const contract = _profile.agency_contracts.find((c) => _.get(c, 'company._id') === contractClientCompanyId);
			if (contract) {
				return (
					pricing_extra_info_enabled &&
					!_.get(contract, 'pricing_config.ignore_contract_margin_settings', false)
				);
			}
		}

		return pricing_extra_info_enabled;
	},

	getSelfContractCompanyId() {
		if (Array.isArray(_profile.agency_contracts)) {
			const agency_company = _profile.agency_contracts.find((c) => c.company._id === _profile.company.id);

			return _.get(agency_company, 'company._id', null);
		}

		return null;
	},

	toggleTM: function () {
		_profile = _profile || {};
		_profile.travel_manager = !_profile.travel_manager;
		this.emitChange();
	},

	toggleAgent: function () {
		_profile = _profile || {};
		_profile.agent = !_profile.agent;
		this.emitChange();
	},

	getSaveProfileStatus: function () {
		return _save_profile_status;
	},

	isSessionActive: function () {
		return _active_session;
	},

	getProfile: function () {
		return _.extend({}, _profile);
	},

	getFullName: function () {
		return _profile && _profile.first_name + ' ' + _profile.last_name;
	},

	getRoomMappingSettings() {
		if (!_room_mapping_settings) {
			_room_mapping_settings = {
				enable_room_mapping: _.get(_profile, 'settings.enable_room_mapping', false),
				enable_room_mapping_debug: _.get(_profile, 'settings.enable_room_mapping_debug', false),
				enable_room_mapping_optimization: _.get(_profile, 'settings.enable_room_mapping_optimization', false),
				room_mapping_jwt_token: _.get(_profile, 'settings.room_mapping_jwt_token', false),
			};
		}

		return _room_mapping_settings;
	},

	isItMe: function (user_id) {
		return _profile && _profile.id === user_id;
	},

	setProfile: async function (data, save) {
		if (save) {
			WebStorageManager.addToWebStorage(_lsKey, _.omit(data, 'bank'));
		}
		addThemeTranslation(data);
		_profile = new Profile(data);

		sendUserToSentry(_profile);
		sendUserToClarity(_profile);

		_active_session = true;
		this.emitChange();
	},

	getDisplayCurrency: function () {
		return _profile && _profile.display_currency;
	},

	getDisplayCurrencyExchangeRate: function () {
		return _profile && _profile.display_currency_exchange_rate;
	},

	isDisplayCurrencyBusy: function () {
		return _profile && _.isFunction(_profile.isDisplayCurrencyBusy) && _profile.isDisplayCurrencyBusy();
	},

	setDisplayCurrency: function (currency) {
		if (Validator.validateCurrency(currency)) {
			_profile.setDisplayCurrency(currency);
		} else {
			console.error(`Currency ${currency} is not supported.`);
		}
	},

	getCurrentChargeCurrency: function () {
		return _profile.charge_currency;
	},

	getCompanyName: function () {
		return _.get(_profile, 'company.name');
	},

	getRecommendationWeights: function () {
		return _profile && _profile.weights;
	},

	getUserCurrency: function () {
		if (_profile?.company?.partners_ui?.use_user_currency) {
			return _.get(_profile, 'user_currency', null);
		}
		return null;
	},

	isUseUserCurrency: function () {
		return !!ProfileStore.getProfile().company?.partners_ui?.use_user_currency;
	},

	emitChange: function () {
		this.emit('change');
	},

	addChangeListener: function (callback) {
		this.on('change', callback);
	},

	removeChangeListener: function (callback) {
		this.removeListener('change', callback);
	},

	shouldShowAccessibilityWidget: function () {
		return _profile?.company?.country_code === COUNTRY_CODES.IL;
	},
});

AppDispatcher.register(function (payload) {
	const action = payload.action;

	switch (action.actionType) {
		case ProfileConstants.CHANGE_DISPLAY_CURRENCY:
			_profile.setDisplayCurrencyStatus(ProfileConstants.STATUS.BUSY);
			break;

		case ProfileConstants.DISPLAY_CURRENCY_CHANGED:
			_profile.setDisplayCurrency(action.data.currency);
			_profile.setDisplayCurrencyExchangeRate(action.data.response.display_currency_exchange_rate);
			_profile.setUserCurrency(action.data.response.user_currency);
			_profile.setDisplayCurrencyStatus(ProfileConstants.STATUS.SUCCESS);
			break;

		case ProfileConstants.DISPLAY_CURRENCY_CHANGE_FAILED:
			_profile.setDisplayCurrencyStatus(ProfileConstants.STATUS.FAILED);
			break;

		case ProfileConstants.RECEIVE_PROFILE_DATA:
			// loadProfileData(action.data);
			break;

		case AuthConstants.SESSION_TIMEOUT:
			cleanWebStorage();
			markSessionTimeout();
			break;

		case ProfileConstants.SAVE_PROFILE:
			_save_profile_status = ProfileConstants.STATUS.BUSY;
			break;
		case ProfileConstants.PROFILE_SAVED:
			_profile = _.extend({}, _profile, new Profile(action.data));
			_save_profile_status = ProfileConstants.STATUS.SUCCESS;
			break;
		case ProfileConstants.PROFILE_SAVE_FAILED:
			_save_profile_status = ProfileConstants.STATUS.FAILED;
			break;
		case ProfileConstants.RESET_SAVE_PROFILE_STATUS:
			_save_profile_status = ProfileConstants.STATUS.IDLE;
			break;

		case ProfileConstants.UPDATE_APPROVED_CURRENCY_TERMS:
			_profile.approved_currency_terms = true;
			break;
		case ProfileConstants.CHANGE_CHARGE_CURRENCY:
			_profile.charge_currency = action.data.currency;
			break;

		case TravelAgentConstants.UPDATE_COMPANY_CONTRACT_SUCCESS:
			updateContract(action.data.res.updated_contract);
			break;

		default:
			return true;
	}

	ProfileStore.emitChange();

	return true;
});

var _lsKey = 'current_user';

function initStore() {
	loadFromWebStorage();
	loadFromServer();
}

function loadFromWebStorage() {
	setTimeout(() => {
		const profile = WebStorageManager.getFromWebStorage(_lsKey);
		if (profile) {
			ProfileStore.setProfile(profile);
		}
	}, 50);
}

function loadFromServer() {
	// if (document.location.hostname === 'localhost') {
	//   return setTimeout(function () {
	//     ProfileStore.setProfile({
	//       "id": "593d5a55e3e07e8033e575aa",
	//       "first_name": "Gustav",
	//       "last_name": "Silver",
	//       "picture": "//dummyimage.com/60x45/3f4e6b/ffffff.png&text=PS",
	//       "email": "gustav@arbitrip.com",
	//       "roles": ["employee", "travel_manager"],
	//       "company": {
	//         "name": "Arbitrip",
	//         "picture": "https://lh5.googleusercontent.com/-avTHbIvvjKY/AAAAAAAAAAI/AAAAAAAAAoY/XbY3XEWyohs/s0-c-k-no-ns/photo.jpg",
	//         "is_premium": true,
	//         "settings": {"client_trip_id": true, "approval_level": "out-of-policy"}
	//       },
	//       "company_policy_allowed": true,
	//       "pay_at_hotel_allowed": true,
	//       "credit_card_allowed": true,
	//       "is_israeli_credit_holder": true,
	//       "settings": {"loyalty_program": {"enabled": true, "percentage": 3}},
	//       "currency": "USD",
	//       "private_travel": true
	//     }, false);
	//   }, 1200);
	// }
	//PATCH cause of Voodoo race condition error
	setTimeout(() => {
		Ajax.getCurrentUser()
			.then((res) => {
				ProfileStore.setProfile(res, true);
				SearchStore.updateGuests(res);

				PointsActions.getPointsIfApplicable(_profile);
			})
			.fail((err) => {
				console.error(err);
				unsetProfile();
			});
	}, 100);
}

export default ProfileStore;
