class UtilsHelper {
	sortCustomSelectAlphabetically(a, b) {
		if (a.label.toLowerCase() < b.label.toLowerCase()) {
			return -1;
		}
		if (a.label.toLowerCase() > b.label.toLowerCase()) {
			return 1;
		}
		return 0;
	}
}

export default new UtilsHelper();
