import _ from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';
import BookingField from './BookingDrawerField.react';
import Currencies from 'arbitrip-common/general/utils/Currencies';
import { ReactComponent as OutOfPolicyIcon } from '../../../img/results_page/check_brown@3x.svg';
import { ReactComponent as InPolicyIcon } from '../../../img/results_page/check_green@3x.svg';

const date_format = 'D MMM YYYY';

const BookingDrawerReservationSummary = ({ booking, totalPrice }) => {
	if (!booking) {
		return null;
	}

	const checkIn = useMemo(() => {
		const date = _.get(booking, 'deal.details.check_in');
		if (moment.isMoment(date)) {
			return date.format(date_format);
		}
	}, [booking]);

	const checkOut = useMemo(() => {
		const date = _.get(booking, 'deal.details.check_out');
		if (moment.isMoment(date)) {
			return date.format(date_format);
		}
	}, [booking]);

	const traveler = useMemo(() => {
		return _.get(booking, 'travelers', []).length;
	}, [booking]);

	const room = useMemo(() => {
		return _.get(booking, 'deal.details.room_count');
	}, [booking]);

	const nights = useMemo(() => {
		return _.get(booking, 'deal.details.nights');
	}, [booking]);

	const price = useMemo(() => {
		if (totalPrice) {
			return Currencies.getPriceWithCurrency(totalPrice, booking.deal.cheapopoPricing.currency);
		}
		return Currencies.getPriceWithCurrency(
			booking.deal.cheapopoPricing.cheapopoTotalPrice,
			booking.deal.cheapopoPricing.currency,
		);
	}, [booking]);

	const getInPolicy = () => {
		return totalPrice ? null : (
			<div className="policy">
				{_.get(booking, 'deal.in_policy') ? (
					<div className="in_policy">
						<InPolicyIcon />
						In Policy
					</div>
				) : (
					<div className="out_of_policy">
						<OutOfPolicyIcon />
						Out of Policy
					</div>
				)}
			</div>
		);
	};

	const commission = useMemo(() => Number(_.get(booking, 'deal.cheapopoPricing.commissionTotal', 0)), [booking]);

	const getCommission = () => {
		const currency = _.get(booking, 'deal.cheapopoPricing.currency');

		return (
			<div className="commission">
				{Currencies.getPriceWithDisplayCurrencyByPrecision(commission, 1, currency, 2)}
			</div>
		);
	};

	const getPayAtTheHotel = () => {
		const currency = _.get(booking, 'deal.cheapopoPricing.payments_excluded_currency');

		return (
			<div className="pay-at-hotel">
				{Currencies.getPriceWithDisplayCurrencyByPrecision(payments_excluded, 1, currency, 2)}
			</div>
		);
	};

	const payments_excluded = useMemo(
		() => Number(_.get(booking, 'deal.cheapopoPricing.payments_excluded', 0)),
		[booking],
	);

	return (
		<BookingField
			fieldTitle="Reservation Summary"
			fieldValue={
				<React.Fragment>
					<div className="booking-reservation-summary">
						<div>Check-in</div>
						<div>Check-out</div>
					</div>
					<div className="booking-reservation-summary bold">
						<div>{checkIn}</div>
						<div>{checkOut}</div>
					</div>

					<div className="booking-reservation-summary">
						<div>{traveler === 1 ? 'Traveler' : 'Travelers'}</div>
						<div>{room === 1 ? 'Room' : 'Rooms'}</div>
						<div>{nights === 1 ? 'Night' : 'Nights'}</div>
					</div>
					<div className="booking-reservation-summary bold">
						<div>{traveler}</div>
						<div>{room}</div>
						<div>{nights}</div>
					</div>

					<div className="booking-reservation-summary">
						<div>Total Price</div>
						<div>{commission > 0 ? 'Commission' : booking.travel_policy && getInPolicy()}</div>
						<div>{commission > 0 && booking.travel_policy && getInPolicy()}</div>
					</div>

					<div className="booking-reservation-summary bold">
						<div>{price}</div>
						<div>{commission > 0 && getCommission()}</div>
						<div></div>
					</div>

					{payments_excluded > 0 && !totalPrice && (
						<React.Fragment>
							<div className="booking-reservation-summary">
								<div className="pay-at-the-hotel">Pay at the Hotel</div>
							</div>
							<div className="booking-reservation-summary bold">
								<div>{getPayAtTheHotel()}</div>
							</div>
						</React.Fragment>
					)}
				</React.Fragment>
			}
		/>
	);
};

export default BookingDrawerReservationSummary;
