class DomService {
	isElementInViewport(el) {
		//special bonus for those using jQuery
		/*eslint-env jquery*/
		if (typeof window.jQuery === 'function' && el instanceof window.jQuery) {
			el = el[0];
		}

		var rect = el.getBoundingClientRect();

		return (
			rect.top >= 0 &&
			rect.left >= 0 &&
			rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) /*or $(window).height() */ &&
			rect.right <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */
		);
	}
}

export default new DomService();
