import withStyles from '@mui/styles/withStyles';
import _ from 'lodash';

import React from 'react';
import PropTypes from 'prop-types';

import SearchActions from '../../../actions/SearchActions';
import Analytics from 'arbitrip-common/client/analytics';
import TopDestinationsConstants from 'arbitrip-common/client/constants/TopDestinationsConstants';
import { withTranslation } from 'react-i18next';
const styles = {};

class LeisureDestinations extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	setDestination(item) {
		const { profile } = this.props;
		let { destination } = item;

		if (!_.isEmpty(destination)) {
			SearchActions.updateDestination(destination);
			Analytics.actions.interactions.selectedLeisureDestination(profile, destination);

			// PATCH: pulses the search box upon choosing a new set of search terms
			setTimeout(() => {
				window.Velocity(document.getElementById('searchBox'), 'callout.pulse', {});
			}, 11);
		}
	}

	render() {
		const { t } = this.props;
		const TOP_DESTINATIONS =
			TopDestinationsConstants[t('TopDestinationsList')] ?? TopDestinationsConstants.TOP_DESTINATIONS;
		const itemClassName = TOP_DESTINATIONS.length === 3 ? 'item' : 'item-wrap';
		return (
			<div className="leisure_destinations">
				<div className="header">Top Destinations</div>
				<div className="leisure_destinations_small">
					{TOP_DESTINATIONS.map((destination, i) => {
						return (
							<button
								aria-label={destination.title}
								onClick={() => this.setDestination(destination)}
								key={`destination-${i}`}
								className={itemClassName}
							>
								<div className="image" style={{ backgroundImage: `url("img/${destination.image}")` }} />
								<div className="title">{destination.title}</div>
								<div className="sub_title">{destination.sub_title}</div>
							</button>
						);
					})}
				</div>
			</div>
		);
	}
}

LeisureDestinations.propTypes = {
	profile: PropTypes.any,
	classes: PropTypes.object.isRequired,
};

const LeisureDestinationsTranslated = withTranslation()(LeisureDestinations);

export default withStyles(styles)(LeisureDestinationsTranslated);
