import { useState, useRef, useEffect } from 'react';

import { getLocation, getPlace } from './utils';

import { Autocomplete, TextField, InputAdornment } from '@mui/material';
import { ReactComponent as LocationIcon } from '@/img/travel_policy/location.svg';
import { ReactComponent as SearchIcon } from '@/img/travel_policy/search.svg';
import { ReactComponent as CloseIcon } from '@/img/icons/x-close.svg';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
	customInput: {
		height: 42,

		'& .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot': {
			padding: '4px 16px',
			'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
				borderColor: theme.palette.blue.secondary,
			},
			'&:hover .MuiOutlinedInput-notchedOutline': {
				borderColor: theme.palette.blue.secondary,
			},
			'&:focus-within': {
				backgroundColor: theme.palette.blue.lightBlue,
			},
			'& .MuiOutlinedInput-input::placeholder': {
				color: theme.palette.gray.gray2,
				opacity: 1,
			},
		},
	},
	textContainer: {
		marginLeft: 10,
	},
	name: {
		fontSize: 15,
		fontWeight: 700,
		color: theme.palette.black.main,
	},
	description: {
		fontSize: 13,
		color: theme.palette.gray.gray2,
	},
	iconButton: {
		'&:hover': {
			cursor: 'pointer',
		},
	},
}));

const GooglePlacesAutocomplete = ({ onAutocompleteSelect, autoFocus = false, placeholder, searchText = '' }) => {
	const classes = useStyles();
	const [locationsList, setLocationsList] = useState([]);
	const [inputValue, setInputValue] = useState(searchText);

	const inputRef = useRef(null);

	useEffect(() => {
		if (autoFocus) {
			inputRef.current?.focus();
		}
	}, []);

	const handleTextFieldChange = async (e) => {
		const value = e.target.value;
		setInputValue(value);

		if (value) {
			const result = await getLocation(value);
			setLocationsList(result || []);
		} else {
			setLocationsList([]);
		}
	};

	const handleLocationClick = async (location) => {
		const place = await getPlace({ ...location, language: 'en' });

		if (!place) {
			return;
		}

		let city;
		let country;
		let state;
		let countryCode;
		let placeId;

		place.address_components.forEach((address) => {
			if (address.types.includes('locality')) {
				city = address.long_name;
			}
			if (address.types.includes('country')) {
				country = address.long_name;
				countryCode = address.short_name;
			}
			if (address.types.includes('administrative_area_level_1')) {
				state = address.short_name;
			}
		});

		if (place.place_id) {
			placeId = place.place_id;
		}

		onAutocompleteSelect(location, {
			country,
			city,
			state,
			countryCode,
			placeId,
		});
	};

	const handleAutocompleteChange = async (e, location) => {
		if (location) {
			await handleLocationClick(location);
		}
	};

	const handleCloseIconClick = () => {
		setInputValue('');
		setLocationsList([]);
	};

	const renderOption = (props, option) => (
		<li {...props}>
			<LocationIcon />
			<div className={classes.textContainer}>
				<div className={classes.name}>{option.name}</div>
				{option.description !== option.name && (
					<div className={classes.description}>{option.short_address}</div>
				)}
			</div>
		</li>
	);

	const renderInputField = (params) => (
		<TextField
			{...params}
			className={classes.customInput}
			inputRef={inputRef}
			type="text"
			fullWidth
			variant="outlined"
			placeholder={placeholder}
			value={inputValue}
			onChange={handleTextFieldChange}
			InputProps={{
				...params.InputProps,
				endAdornment: (
					<InputAdornment position="end">
						{inputValue ? (
							<CloseIcon onClick={handleCloseIconClick} className={classes.iconButton} />
						) : (
							<SearchIcon />
						)}
					</InputAdornment>
				),
			}}
		/>
	);

	return (
		<Autocomplete
			freeSolo
			autoHighlight
			inputValue={inputValue}
			onInputChange={(e, newInputValue) => {
				setInputValue(newInputValue);
			}}
			options={locationsList}
			renderOption={renderOption}
			renderInput={renderInputField}
			onChange={handleAutocompleteChange}
		/>
	);
};

export default GooglePlacesAutocomplete;
