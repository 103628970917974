import _ from 'lodash';

import React from 'react';
import createClass from 'create-react-class';
import StarsComponent from '../../../StarsComponent.react';
import HotelAddress from '../../hotel/HotelAddressComponent.react';
import NegotiatedRate from '../../../general/NegotiatedRate.react';
import ImageUtils from '../../../../utils/ImageUtils';

import RouterWrapper from '../../../../utils/RouterWrapper';
import { withRouter } from '../../../../utils/withRouter';

// Method to retrieve state from Stores
function getReservationSummaryState() {
	return {
		should_render: true,
	};
}

function renderImageTagWithFallbackV2(hotel, fallback_source) {
	if (!_.get(hotel, 'images.0')) {
		return;
	}

	return (
		<div
			className="hotel-image"
			style={{
				backgroundImage: ImageUtils.getHotelImagesCSSBackground(
					hotel,
					fallback_source || ImageUtils.FALLBACK_IMAGE,
				),
			}}
		/>
	);
}

// Define main Controller View
const HotelDetailsContainer = createClass({
	displayName: 'HotelDetailsContainer',

	// Get initial state from stores
	getInitialState: function () {
		return getReservationSummaryState();
	},

	// Add change listeners to stores
	componentDidMount: function () {
		const { reservation } = this.props;

		if (!_.get(reservation, 'deal.chooseprod')) {
			this.setState({
				should_render: false,
			});
		}
	},

	// Render our child components, passing state via props
	render() {
		const { profile, hotel, reservation, navigate } = this.props;
		if (_.isEmpty(reservation.deal)) {
			RouterWrapper.goToSearchPage(navigate);
			return;
		}

		if (!this.state.should_render) {
			return null;
		}

		const company_name = _.get(profile, 'company.name', '');

		const HOTEL_NAME = hotel.name;
		const HOTEL_STARS = hotel.stars;
		const DEAL = reservation.deal;

		const numberOfGuests = DEAL.guestsPerRoom * DEAL.rooms;
		const GUESTS = `${numberOfGuests} ${Number(numberOfGuests) === 1 ? 'Adult' : 'Adults'}`;
		const numberOfChildren =
			DEAL.children_ages && DEAL.children_ages.length > 0 ? DEAL.children_ages.length * DEAL.rooms : 0;
		const children_ages = _.get(DEAL, 'children_ages', []).map((age) => (age === 0 ? 'under 1' : age));
		const CHILDREN = numberOfChildren
			? `${numberOfChildren} ${numberOfChildren === 1 ? 'Child' : 'Children'} (${numberOfChildren === 1 ? 'age' : 'ages'} ${children_ages.join(', ')})`
			: null;
		const numberOfRooms = DEAL.rooms;
		const ROOMS = `${numberOfRooms} ${Number(numberOfRooms) === 1 ? 'Room' : 'Rooms'}`;

		let ROOM_TERMS = [];
		if (_.get(DEAL, 'board_bases.all_inclusive')) {
			ROOM_TERMS.push(
				<div className="breakfast" key="reservation-summary-breakfast-key">
					<img className="icon" src="../../img/amenities/breakfast.svg" alt="breakfast" />
					All Inclusive
				</div>,
			);
		} else if (_.get(DEAL, 'board_bases.full_board')) {
			ROOM_TERMS.push(
				<div className="breakfast" key="reservation-summary-breakfast-key">
					<img className="icon" src="../../img/amenities/breakfast.svg" alt="breakfast" />
					Full Board
				</div>,
			);
		} else if (_.get(DEAL, 'board_bases.half_board')) {
			ROOM_TERMS.push(
				<div className="breakfast" key="reservation-summary-breakfast-key">
					<img className="icon" src="../../img/amenities/breakfast.svg" alt="breakfast" />
					Half Board
				</div>,
			);
		} else if (_.get(DEAL, 'breakfast')) {
			ROOM_TERMS.push(
				<div className="breakfast" key="reservation-summary-breakfast-key">
					<img className="icon" src="../../img/amenities/breakfast.svg" alt="breakfast" />
					Breakfast Included
				</div>,
			);
		}

		const votes = _.get(hotel, 'review.votes');
		let VOTES_TEXT = `${votes} review`;
		if (votes > 1) {
			VOTES_TEXT += 's';
		}

		return (
			<div className="hotel-terms flex">
				<div className="image-container">{renderImageTagWithFallbackV2(hotel, ImageUtils.FALLBACK_IMAGE)}</div>
				<div className="content-container">
					<span className="name">{HOTEL_NAME}</span>
					<div className="review-container flex">
						<StarsComponent stars={HOTEL_STARS} />
						<div className="review-content">{votes && ` | ${VOTES_TEXT}`}</div>
					</div>
					<div className="info-container flex flex-column">
						<HotelAddress hotel={hotel} forceAddress={true} />
						<div className="rooms">
							{ROOMS} | {GUESTS} {CHILDREN && `| ${CHILDREN}`}
						</div>
						{ROOM_TERMS}
						<NegotiatedRate
							companyName={company_name}
							show={!!DEAL.negotiated_rate}
							rawCode={DEAL.original_negotiated_rate || ''}
							code={DEAL.negotiated_rate || ''}
							codeDisplayName={DEAL.negotiated_rate_display_name || ''}
						/>
					</div>
				</div>
			</div>
		);
	},
});

export default withRouter(HotelDetailsContainer);
