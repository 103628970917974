const _ = require('lodash');
const Providers = require('./providers');
const GlobalProperties = require('./global_properties_attached_to_events');
const { CHAT_PROVIDERS } = require("../../general/constants/chat-providers");

let _profile;
let _chat_enabled;
let _chat_provider;

function invokeAll(method, ...args) {
    for (const provider in Providers) {
        if (_chat_enabled && CHAT_PROVIDERS[provider.toUpperCase()] && _chat_provider !== provider.toLowerCase()) {
            // skip chat provider that is not enabled
            continue;
        }

        if (_.isFunction(Providers[provider][method])) {
            // check if function exists before call
            Providers[provider][method](...args);
        }
    }
}

function startSession(profile, options = {}) {
    _profile = profile;
    _chat_enabled = _.get(profile, 'chat.enabled', false);
    _chat_provider = _.get(profile, 'chat.provider', CHAT_PROVIDERS.INTERCOM);

    invokeAll('startSession', profile, options);
}

function endSession(profile) {
    invokeAll('endSession', profile);
}

function trackEvent(event, metadata, options) {
    options = options || {};
    invokeAll(
        'trackEvent',
        event,
        Object.assign({}, metadata, GlobalProperties.items())
    );
    if (options.update === true) {
        setTimeout(function() {
            // TODO: add zendesk update

            Providers.Intercom.update(_profile);
        }, options.delay);

    }
}

module.exports = {
    startSession, // enabling interaction with intercom (chat, analytics) in localhost
    endSession, // enabling interaction with intercom (chat, analytics) in localhost
    trackEvent // enabling interaction with intercom (chat, analytics) in localhost
    // startSession: (Config.localhost ? (() => { }) : startSession),
    // endSession: (Config.localhost ? (() => { }) : endSession),
    // trackEvent: (Config.localhost ? (() => { }) : trackEvent)
};
