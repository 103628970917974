import { CircleFlag } from 'react-circle-flags';

const CountryFlag = ({ place }) => {
	if (!place?.countryCode || typeof place.countryCode !== 'string') {
		return <CircleFlag height="32" />;
	}

	return <CircleFlag countryCode={place.countryCode.toLowerCase()} height="32" title={place.country ?? ''} />;
};

export default CountryFlag;
