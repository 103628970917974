import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState, useMemo, useRef } from 'react';
import { useLocation } from 'react-router';

import Analytics from 'arbitrip-common/client/analytics';
import { isRetroactivePointsUsageEnabled } from 'arbitrip-common/client/utils/RetroactivePoints';

import BookingsConstants from '@/constants/BookingsConstants';
import BookingsActions from '@/actions/BookingsActions';
import BookingsStore from '@/stores/BookingsStore';
import ProfileStore from '@/stores/ProfileStore';
import PointsStore from '@/stores/PointsStore';

import BookingsFilterComponent from './BookingsFilterComponent.react';
import NewBookingsFiltersComponent from './NewBookingsFiltersComponent';
import BookingsTable from './BookingsTable.react';
import BookingDrawer from './BookingDrawer.react';
import BookingsTabs from './BookingsTabs';
import Snackbar from '@mui/material/Snackbar';
import ReservationConstants from '@/constants/ReservationConstants';
import { EXTRAS, TABS_CONFIG, TAB_LABELS } from './consts';

import TitleManager from '@/utils/TitleManager';
import DocumentTitle from '@/components/general/DocumentTitle.react';

const { ORDER_BY, ORDER } = BookingsConstants;

const snackbar_messages = {
	[ReservationConstants.STATUS.INITIAL]: '',
	[ReservationConstants.STATUS.BUSY]: 'Sending booking confirmation...',
	[ReservationConstants.STATUS.SUCCESS]: 'Booking confirmation sent',
	[ReservationConstants.STATUS.FAILED]: 'Booking confirmation failed',
};

const initFilters = {
	search: '',
	where: '',
	destination: '',
	start_date: null,
	end_date: null,
	agent_name: '',
	company_name: '',
	traveler_name: '',
	reservation_id: '',
	hotel_name: '',
	show_confirmed: false,
	travel_booster_not_synced: false,
	show_upcoming: false,
	cancellation_deadline: false,
	show_ongoing: false,
	furthest_deadline: false,
	closest_deadline_excludes_passed_due: false,
	closest_deadline_includes_passed_due: false,
	extra: null,
	bookings_from_branch: '',
	agency_branches: [],
	cancellation_deadline_start: null,
	cancellation_deadline_end: null,
};

const initPagination = {
	page: 0,
	rows_per_page: BookingsConstants.BOOKINGS_LIMIT,
};

const initSort = {
	order_by: '',
	order: '',
};

function getState() {
	const profile = ProfileStore.getProfile();

	return {
		profile,

		bookings: BookingsStore.getItems(),
		bookings_count: BookingsStore.getCount(),

		status: BookingsStore.getStatus(),
		download_status: BookingsStore.getDownloadStatus(),
		agent_notes_status: BookingsStore.getAgentNotesStatus(),

		sync_travel_booster_status: BookingsStore.getSyncTravelBoosterStatus(),
		booking_confirmation_status: BookingsStore.getSendBookingConfirmationStatus(),

		points: PointsStore.getPoints(),
		update_points_usage_status: PointsStore.getUpdatePointsUsageStatus(),

		bookings_page_title: TitleManager.buildDefaultPageTitle({ profile, page: 'Bookings' }),

		settle_payment_status: null, // TODO:
	};
}

const createTabIndexMap = (tabsConfig) => {
	return tabsConfig.reduce((map, tab, index) => {
		map[tab.label] = index;
		return map;
	}, {});
};

const BookingsPage = () => {
	const [state, setState] = useState(getState());

	const [search, setSearch] = useState(initFilters.search);
	const [filters, setFilters] = useState(initFilters);
	const [pagination, setPagination] = useState(initPagination);
	const [sort, setSort] = useState(initSort);

	const [openDrawer, setOpenDrawer] = useState(false);
	const [bookingDrawer, setBookingDrawer] = useState(null);
	const [snackbarOpen, setSnackbarOpen] = useState(false);

	const [openFiltersDrawer, setOpenFiltersDrawer] = useState(false);

	const location = useLocation();
	const bookingIdFromLocation = location?.state?.id;

	const [initialLoad, setInitialLoad] = useState(false);
	const prevStatus = useRef(state.status);

	const [selectedTab, setSelectedTab] = useState(0);

	const displayTabs = useMemo(() => {
		const { agent, super_agent, freelance_agent, travel_manager } = state.profile;
		return agent || super_agent || freelance_agent || travel_manager;
	}, [state.profile]);

	const tabsConfig = useMemo(() => {
		return TABS_CONFIG.map((tab) => {
			if (tab.label === TAB_LABELS.UNPAID) {
				const isFreelanceAgent = state.profile.freelance_agent;
				return {
					...tab,
					shouldDisplay: isFreelanceAgent,
				};
			}
			if (tab.label === TAB_LABELS.MISSING_DOCKET) {
				const showTravelBooster =
					state.profile.agent && state.profile.settings?.travel_booster_integration_enabled;
				return {
					...tab,
					shouldDisplay: showTravelBooster,
				};
			}
			return tab;
		}).filter((tab) => tab.shouldDisplay);
	}, []);

	const tabIndexMap = useMemo(() => createTabIndexMap(tabsConfig), [tabsConfig]);

	useEffect(() => {
		if (
			prevStatus.current === BookingsConstants.STATUS.BUSY &&
			state.status === BookingsConstants.STATUS.SUCCESS &&
			!initialLoad
		) {
			if (selectedTab === tabIndexMap[TAB_LABELS.UPCOMING] && state.bookings.length === 0) {
				setSelectedTab(tabIndexMap[TAB_LABELS.ALL]);
				setTimeout(() => {
					setInitialLoad(true);
				}, 1000);
			} else {
				setInitialLoad(true);
			}
		}
		prevStatus.current = state.status;
	}, [state.status]);

	useEffect(() => {
		BookingsStore.addChangeListener(_onChange);
		PointsStore.addChangeListener(_onChange);

		getBookings();

		Analytics.actions.views.bookingsPage(state.profile);

		return () => {
			BookingsStore.removeChangeListener(_onChange);
			PointsStore.removeChangeListener(_onChange);
		};
	}, []);

	const getSortConfigMap = (tabsConfig, tabIndexMap) => ({
		[tabsConfig[tabIndexMap[TAB_LABELS.UPCOMING]]?.label]: { order_by: ORDER_BY.CHECK_IN, order: ORDER.ASC },
		[tabsConfig[tabIndexMap[TAB_LABELS.ONGOING]]?.label]: { order_by: ORDER_BY.CHECK_IN, order: ORDER.ASC },
		[tabsConfig[tabIndexMap[TAB_LABELS.ARCHIVE]]?.label]: { order_by: ORDER_BY.CHECK_OUT, order: ORDER.DESC },
		[tabsConfig[tabIndexMap[TAB_LABELS.UNPAID]]?.label]: { order_by: ORDER_BY.CHECK_IN, order: ORDER.ASC },
	});

	useEffect(() => {
		if (displayTabs) {
			const tabLabel = tabsConfig[selectedTab]?.label;
			const sortConfigMap = getSortConfigMap(tabsConfig, tabIndexMap);
			const sortConfig = getSortForTab(tabLabel, sortConfigMap);
			setSort(sortConfig);

			setPagination(initPagination);
			getBookings();
		}
	}, [selectedTab, tabsConfig, tabIndexMap]);

	useEffect(() => {
		getBookings();
		setPagination(initPagination);
	}, [filters]);

	useEffect(() => {
		getBookings();
	}, [pagination, sort]);

	useEffect(() => {
		if (state.booking_confirmation_status === BookingsConstants.STATUS.SUCCESS) {
			setSnackbarOpen(true);
		}
	}, [state.booking_confirmation_status]);

	useEffect(() => {
		if (!_.isEmpty(state.bookings) && bookingIdFromLocation) {
			const booking = state.bookings.find((booking) => booking.id === bookingIdFromLocation);

			if (booking) {
				setOpenDrawer(true);
				setBookingDrawer(booking);
			}
		}
	}, [state.bookings]);

	const getSortForTab = (tabLabel) => {
		const sortMap = {
			[TAB_LABELS.UPCOMING]: { order_by: ORDER_BY.CHECK_IN, order: ORDER.ASC },
			[TAB_LABELS.ONGOING]: { order_by: ORDER_BY.CHECK_IN, order: ORDER.ASC },
			[TAB_LABELS.ARCHIVE]: { order_by: ORDER_BY.CHECK_OUT, order: ORDER.DESC },
			[TAB_LABELS.UNPAID]: { order_by: ORDER_BY.CANCELLATION_DEADLINE, order: ORDER.ASC },
		};

		return sortMap[tabLabel] || initSort;
	};

	const getActiveFilters = () => {
		const tabFilter = tabsConfig[selectedTab]?.filterKey;

		return {
			...(!_.isEmpty(filters.search) && { search: filters.search }),
			...(!_.isEmpty(filters.where) && { destination_hotel_name: filters.where }),
			...(!_.isEmpty(filters.destination) && { destination: filters.destination }),
			...(moment.isMoment(filters.start_date) && { after_date: moment(filters.start_date).format('YYYY-MM-DD') }),
			...(moment.isMoment(filters.end_date) && { before_date: moment(filters.end_date).format('YYYY-MM-DD') }),
			...(!_.isEmpty(filters.agent_name) && { agent_name: filters.agent_name }),
			...(!_.isEmpty(filters.company_name) && { company_name: filters.company_name }),
			...(!_.isEmpty(filters.traveler_name) && { traveler_name: filters.traveler_name }),
			...(!_.isEmpty(filters.reservation_id) && { reservation_id: filters.reservation_id }),
			...(!_.isEmpty(filters.hotel_name) && { hotel_name: filters.hotel_name }),
			...(filters.extra === EXTRAS.SHOW_CONFIRMED && { [EXTRAS.SHOW_CONFIRMED]: EXTRAS.SHOW_CONFIRMED }),
			...(filters.travel_booster_not_synced && { travel_booster_not_synced: filters.travel_booster_not_synced }),
			...(filters.extra === EXTRAS.SHOW_UPCOMING && { [EXTRAS.SHOW_UPCOMING]: EXTRAS.SHOW_UPCOMING }),
			...(filters.extra === EXTRAS.CANCELLATION_DEADLINE && {
				[EXTRAS.CANCELLATION_DEADLINE]: EXTRAS.CANCELLATION_DEADLINE,
			}),
			...(filters.extra === EXTRAS.SHOW_ONGOING && { [EXTRAS.SHOW_ONGOING]: EXTRAS.SHOW_ONGOING }),
			...(filters.bookings_from_branch && { [EXTRAS.BOOKINGS_FROM_BRANCH]: filters.bookings_from_branch }),
			...(filters.agency_branches.length && { [EXTRAS.AGENCY_BRANCHES]: filters.agency_branches.join(',') }),
			...(filters.extra === EXTRAS.CLOSEST_DEADLINE && { [EXTRAS.CLOSEST_DEADLINE]: EXTRAS.CLOSEST_DEADLINE }),
			...(filters.extra === EXTRAS.CLOSEST_DEADLINE_PASSED && {
				[EXTRAS.CLOSEST_DEADLINE_PASSED]: EXTRAS.CLOSEST_DEADLINE_PASSED,
			}),
			...(filters.extra === EXTRAS.FURTHEST_DEADLINE && { [EXTRAS.FURTHEST_DEADLINE]: EXTRAS.FURTHEST_DEADLINE }),
			...(moment.isMoment(filters.cancellation_deadline_start) && {
				cancellation_deadline_after: moment(filters.cancellation_deadline_start).format('YYYY-MM-DD'),
			}),
			...(moment.isMoment(filters.cancellation_deadline_end) && {
				cancellation_deadline_before: moment(filters.cancellation_deadline_end).format('YYYY-MM-DD'),
			}),
			...(displayTabs && tabFilter && { [tabFilter]: tabFilter }),
		};
	};

	const getActivePagination = () => ({
		page: pagination.page > 0 ? pagination.page : 0,
		rows_per_page: pagination.rows_per_page > 0 ? pagination.rows_per_page : BookingsConstants.BOOKINGS_LIMIT,
	});

	const getActiveSort = () => ({
		...(!_.isEmpty(sort.order_by) && { order_by: sort.order_by }),
		...(!_.isEmpty(sort.order) && { order: sort.order }),
	});

	const getActiveSkipLimit = () => {
		const active_pagination = getActivePagination();

		const limit =
			active_pagination.rows_per_page > 0 ? active_pagination.rows_per_page : BookingsConstants.BOOKINGS_LIMIT;

		return {
			skip: active_pagination.page > 0 ? active_pagination.page * limit : 0,

			limit,
		};
	};

	const sendFiltersAnalytics = () => {
		const filters = getActiveFilters();

		// send all filters to analytics
		for (const [key, value] of Object.entries(filters)) {
			Analytics.actions.interactions.filteredBookings(profile, _.startCase(key), value);
		}
	};

	const getBookings = () => {
		const { skip, limit } = getActiveSkipLimit();
		const { order_by, order } = getActiveSort();

		const filters = getActiveFilters();

		BookingsActions.getBookingsDebounced(skip, limit, filters, order_by, order);
		sendFiltersAnalytics();
		setBookingDrawer(null);
	};

	const _onChange = () => {
		setState(getState());
	};

	const openBookingDrawer = (booking) => {
		if (booking.id === _.get(bookingDrawer, 'id')) {
			// if row is already open, close it
			bookingDrawerOnClose();
		} else {
			setOpenDrawer(true);
			setBookingDrawer(booking);

			const redeemable = isRetroactivePointsUsageEnabled({ profile: state?.profile, booking });
			Analytics.actions.interactions.openedBookingsSidepanel(booking?.id, redeemable);
		}
	};

	const updateBookingDrawer = (booking) => {
		if (booking && booking.id === _.get(bookingDrawer, 'id')) {
			setBookingDrawer(booking);
		}
	};

	const bookingDrawerOnClose = () => {
		setOpenDrawer(false);
		setTimeout(() => {
			setBookingDrawer(false);
		}, 400);
	};

	const onCloseSnackBar = () => {
		setSnackbarOpen(false);
	};

	const handleDeleteTag = (key, branchId) => {
		// Set bookings status to transition
		// to avoid a state where there are no filters
		// and the bookings busy state is not called yet
		BookingsActions.setBookingsTransitionStatus();

		if (key === 'search') {
			setSearch(initFilters.search);
		}

		if (key === EXTRAS.AGENCY_BRANCHES) {
			const list = [...filters[key]];
			const index = list.indexOf(branchId);
			if (index > -1) {
				list.splice(index, 1);
			}
			setFilters({ ...filters, [key]: list });
		} else {
			// remove tag and set to original data
			setFilters({ ...filters, [key]: initFilters[key] });
		}

		setPagination(initPagination);
	};

	const {
		profile,
		bookings,
		bookings_count,
		status,
		download_status,
		sync_travel_booster_status,
		agent_notes_status,
		points,
		update_points_usage_status,
		bookings_page_title,
		settle_payment_status,
	} = state;

	return (
		<DocumentTitle title={bookings_page_title}>
			<div className="new-bookings-page">
				{displayTabs ? (
					<>
						<BookingsTabs
							selectedTab={selectedTab}
							onChange={(_, newValue) => setSelectedTab(newValue)}
							showLoader={!initialLoad}
							tabsConfig={tabsConfig}
						/>
						<NewBookingsFiltersComponent
							status={status}
							downloadStatus={download_status}
							profile={profile}
							bookings={bookings}
							initFilters={initFilters}
							filters={filters}
							setFilters={setFilters}
							initPagination={initPagination}
							setPagination={setPagination}
							initSort={initSort}
							setSort={setSort}
							search={search}
							setSearch={setSearch}
							activeFilters={getActiveFilters()}
							handleDeleteTag={handleDeleteTag}
							activeTab={tabsConfig[selectedTab]?.label}
						/>
					</>
				) : (
					<BookingsFilterComponent
						status={status}
						downloadStatus={download_status}
						profile={profile}
						bookings={bookings}
						initFilters={initFilters}
						filters={filters}
						setFilters={setFilters}
						initPagination={initPagination}
						setPagination={setPagination}
						initSort={initSort}
						setSort={setSort}
						search={search}
						setSearch={setSearch}
						openDrawer={openFiltersDrawer}
						setOpenDrawer={setOpenFiltersDrawer}
						activeFilters={getActiveFilters()}
						handleDeleteTag={handleDeleteTag}
					/>
				)}

				<BookingsTable
					showLoader={!initialLoad}
					displayTabs={displayTabs}
					profile={profile}
					bookings={bookings}
					selectedBooking={bookingDrawer}
					openDrawer={openBookingDrawer}
					bookingsCount={bookings_count}
					status={status}
					filters={filters}
					pagination={pagination}
					setPagination={setPagination}
					sort={sort}
					setSort={setSort}
					points={points}
					updateDrawer={updateBookingDrawer}
				/>

				<BookingDrawer
					open={openDrawer}
					onClose={bookingDrawerOnClose}
					booking={bookingDrawer}
					profile={profile}
					syncTravelBoosterStatus={sync_travel_booster_status}
					agentNotesStatus={agent_notes_status}
					confirmationStatus={state.booking_confirmation_status}
					points={points}
					updatePointsUsageStatus={update_points_usage_status}
					settlePaymentStatus={settle_payment_status}
				/>

				<Snackbar
					open={snackbarOpen}
					message={snackbar_messages[state.booking_confirmation_status] || ''}
					autoHideDuration={4000}
					onClose={onCloseSnackBar}
				/>
			</div>
		</DocumentTitle>
	);
};

export default BookingsPage;
