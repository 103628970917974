import React from 'react';
import createClass from 'create-react-class';

// const blacklist = [
//   /Missing property description/i
// ];

function isDescriptionValid(desc) {
	if (typeof desc !== 'string') {
		return false;
	}

	var trimmed = desc.trim();

	if (trimmed.length === 0) {
		return false;
	}

	// if (blacklist.some(function(regex) {return regex.test(trimmed)})) {
	if (trimmed.indexOf('Missing property description.') >= 0) {
		return false;
	}

	return true;
}

function capitalizeFirstLetter(word) {
	return word.charAt(0).toUpperCase() + word.slice(1);
}

function reformatTitle(title) {
	return title.split('_').map(capitalizeFirstLetter).join(' ');
}

/*
dining
business_amentities
rooms
attractions
location
headline
ametnities
renovations
national_ratings
*/

function renderTitle(title) {
	if (title && typeof title === 'string') {
		return '<strong>' + reformatTitle(title) + ':</strong>';
	}

	return null;
}

function renderDescriptionWithTitle(description) {
	if (description && description.constructor === Object) {
		if (description.headline) {
			description.location = description.headline + '.<br>' + description.location;
			delete description.headline;
		}
		return Object.entries(description).map(([title, content]) => (
			<p key={title} dangerouslySetInnerHTML={{ __html: renderTitle(title) + ' ' + content }} />
		));
	}

	return description;
}

var AboutComponent = createClass({
	displayName: 'AboutComponent',

	render: function () {
		var description = renderDescriptionWithTitle(this.props.description);

		if (!isDescriptionValid(description)) {
			description = renderDescriptionWithTitle(description);
			if (!description) {
				return null;
			}
		}

		return (
			<div className="about-container">
				<div className="about">
					<div className="title">
						<span>About</span>
						<span> </span>
						<span>{this.props.hotel_name}</span>
					</div>
					<div className="description">{description}</div>
				</div>
			</div>
		);
	},
});

export default AboutComponent;
