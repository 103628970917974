import Validator from 'arbitrip-common/client/utils/Validator';
import { email_regex } from 'arbitrip-common/general/constants/regexes';

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';

import Events from '../../../events/Events';
import Constants from '../../../events/constants/ReservationPaymentPage';

import SearchStore from '../../../stores/SearchStore';
import ProfileStore from '../../../stores/ProfileStore';
import User from '../../../entities/User';

import ReservationActions from '../../../actions/ReservationActions';
import ReservationConstants from '../../../constants/ReservationConstants';
import Analytics from 'arbitrip-common/client/analytics/actions/interactions';

import TravelersHelper from './helpers/TravelersHelper';

import TravelersAutocomplete from './components/TravelersAutocomplete.react';

import Config from 'arbitrip-common/client/utils/Config';

import { TextField, Avatar, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { avatarProps } from '../../../utils/AvatarUtils';
import { ArbiCancelButton } from '@/components/general/styled/buttons';
import { MuiTelInput } from 'mui-tel-input';
import EditIcon from '@mui/icons-material/Edit';

const { SAVE_TRAVELER_CARD } = Constants;

const emailRegex = new RegExp(email_regex);

const CONTACT_CARD_MODES = {
	AUTOCOMPLETE: 'autocomplete',
	EDIT: 'edit',
	LOCKED: 'locked',
};

const LETTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

function getReservationPageState() {
	let { always_on_guest_mode } = ProfileStore.getProfile();
	let private_travel = SearchStore.isPrivateTravel();
	return {
		profile: ProfileStore.getProfile(),
		agent_contract_id: SearchStore.getContractId(),
		agent_client_company_id: SearchStore.getContractClientCompanyId(),
		always_on_guest_mode: private_travel ? true : always_on_guest_mode,
		validFirstName: true,
		validLastName: true,
		validEmail: true,
		validPhone: true,
		validationEnabled: false,
		private_travel,
	};
}

function isPrivateTravel() {
	return SearchStore.isPrivateTravel();
}

function isAutocompleteEnabled() {
	let profile = ProfileStore.getProfile();
	let private_travel = SearchStore.isPrivateTravel();
	if (private_travel) {
		return false;
	}
	if (profile) {
		if (profile.private_travel) {
			return false;
		}

		if (profile.agent && profile.company.id === SearchStore.getContractClientCompanyId()) {
			return false;
		}

		if (profile.always_on_guest_mode) {
			return false;
		}
	}

	return true;
}

class ContactCardLight extends React.Component {
	constructor(props) {
		super(props);

		const state = getReservationPageState();
		state.shouldCreateUser = true;

		const { reservation, contactIndex } = props;

		this.initializeTravelerFormState(state, reservation, contactIndex);

		if (isPrivateTravel()) {
			state.mode = CONTACT_CARD_MODES.EDIT;
			state.shouldCreateUser = false;
		} else if (!isAutocompleteEnabled()) {
			state.mode = CONTACT_CARD_MODES.EDIT;
			state.shouldCreateUser = false;
		} else {
			state.mode = CONTACT_CARD_MODES.AUTOCOMPLETE;
			state.failed = false;
		}

		state.search_text = '';

		this.state = state;

		let bind_functions = [
			'validateTravelerDetails',
			'saveCard',
			'removeCard',
			'onFirstNameChanged',
			'onLastNameChanged',
			'onEmailChanged',
			'onPhoneChanged',
			'getMockTraveler',
			'fillCardCheat',
			'updateTraveler',
			'onTravelerAutoCompleteBlur',
			'onTravelerAutoCompleteUpdateInput',
			'onTravelerAutoCompleteNewRequest',
			'createTraveler',
			'shouldDisplayRemoveButton',
			'shouldDisplayEmailError',
			'setEnglishCharsMessage',
			'updateTravelerIfNeeded',
			'initializeTravelerFormState',
			'editCard',
		];

		for (let f of bind_functions) {
			this[f] = this[f].bind(this);
		}

		Events.on(SAVE_TRAVELER_CARD, this.saveCard);

		this.displayName = 'ContactCardLight';
	}

	static getDerivedStateFromProps(nextProps, nextState) {
		const create_status = nextProps.createTravelerStatus;

		let derived_state = {
			busy: create_status === ReservationConstants.STATUS.BUSY,
			failed: create_status === ReservationConstants.STATUS.FAILED,
			created_traveler: create_status === ReservationConstants.STATUS.SUCCESS,
		};

		const valid = TravelersHelper.isValidTraveler(nextProps.reservation.travelers[nextProps.contactIndex]);

		if (nextProps.reservation.travelers[nextProps.contactIndex]?.id && valid && !isPrivateTravel()) {
			derived_state.mode = CONTACT_CARD_MODES.LOCKED;
		}

		return derived_state;
	}

	componentWillUnmount() {
		Events.removeEventListener(SAVE_TRAVELER_CARD, this.saveCard);
	}

	componentDidUpdate(prevProps, prevState) {
		// console.info('------------------------------------');
		// console.info('CCL did update...');
		// console.info('=============================================');

		let state = {};
		if (
			(!prevState.busy || !this.state.failed) &&
			((prevState.busy && this.state.created_traveler) || (!prevState.created_guest && this.state.created_guest))
		) {
			state.mode = CONTACT_CARD_MODES.LOCKED;
			state.failed = false;
		}

		if (prevState.mode === CONTACT_CARD_MODES.AUTOCOMPLETE && this.state.mode === CONTACT_CARD_MODES.EDIT) {
			state.showEnglishCharsMessage = false;
			state.validFirstName = true;
			state.validLastName = true;
			state.validEmail = true;
			state.validPhone = true;
			state.traveler = this.state.traveler;
			let text = this.state.search_text || '';
			if (Validator.validateEmailAddress(text)) {
				state.traveler.email = text;
			} else {
				let split_name = this.state.search_text
					.split(' ')
					.map((s) => s.trim())
					.filter((s) => s);
				if (split_name.length) {
					state.traveler.first_name = Validator.filterEnglishOnly(split_name[0]);
					state.traveler.last_name = Validator.filterEnglishOnly(split_name.slice(1).join(' '));
				}
			}
		}

		if (Object.keys(state).length) {
			this.setState(state);
		}

		if (this.props.autoFill && !this.state.did_auto_fill) {
			const _self = this;
			setTimeout(() => {
				_self.fillCardCheat();
				_self.setState({ did_auto_fill: true });
			}, 1);
		}
	}

	initializeTravelerFormState(state, reservation, contactIndex) {
		const profile = ProfileStore.getProfile();

		state.traveler = reservation?.travelers?.[contactIndex] || {};
		const mainTraveler = contactIndex === 0;

		// used for instance in HTZ integration to avoid any data to be prefilled
		const disable_travelers_data_prefill = _.get(
			profile,
			'company.partners_ui.disable_travelers_data_prefill',
			false,
		);
		state.avatarPrefilled = !disable_travelers_data_prefill;

		const prefill_with_external_travelers = _.get(
			profile,
			'company.partners_ui.prefill_with_external_travelers',
			false,
		);

		const mandatory_use_profile_email = _.get(profile, 'company.partners_ui.mandatory_use_profile_email', false);
		state.mandatoryUseProfileEmail = mandatory_use_profile_email;

		// prefill the form with external travelers, e.g. for RN
		if (prefill_with_external_travelers && profile.travelers?.length && mainTraveler) {
			state.traveler = profile.travelers[contactIndex] || {};
			if (mandatory_use_profile_email || !Validator.validateEmailAddress(state.traveler.email)) {
				state.traveler.email = profile.email ?? '';
			}
		} else if (
			isPrivateTravel() &&
			mainTraveler &&
			(!disable_travelers_data_prefill || mandatory_use_profile_email)
		) {
			state.traveler.first_name = profile.first_name ?? '';
			state.traveler.last_name = profile.last_name ?? '';

			if (profile.first_name && profile.last_name) {
				state.traveler.email = profile.email ?? '';
				state.traveler.phone = profile.tel ?? '';
			}
		}
	}

	validateTravelerDetails(traveler) {
		const { mainGuest } = this.props;
		const { validFirstName, validLastName, validEmail, validPhone } = TravelersHelper.getValidationResults(
			traveler,
			mainGuest,
		);

		this.setState({
			validFirstName,
			validLastName,
			validEmail,
			validPhone: mainGuest ? validPhone : true,
		});

		return validFirstName && validLastName && validEmail && (mainGuest ? validPhone : true);
	}

	updateTravelerIfNeeded(traveler, travelers) {
		const { contactIndex } = this.props;
		const original = traveler.last_name;
		const filterTraveler = ({ full_name }, index) => full_name === traveler.full_name && index !== contactIndex;
		let i = -1;
		while (travelers.filter(filterTraveler).length) {
			i++;
			traveler.last_name = `${original} ${LETTERS[i]}`;
			traveler.ghost = true;
			traveler = TravelersHelper.updateTravelerFullName(traveler);
		}
	}

	saveCard() {
		this.setState({
			validationEnabled: true,
		});
		let { roomNumber, contactIndex, reservation } = this.props;
		let { mode, traveler, shouldCreateUser } = this.state;

		if (mode === CONTACT_CARD_MODES.LOCKED) {
			ReservationActions.removeTravelerError(roomNumber, contactIndex);
		} else {
			if (this.validateTravelerDetails(traveler)) {
				this.updateTravelerIfNeeded(traveler, reservation.travelers);
				traveler.shouldCreateUser = shouldCreateUser;
				ReservationActions.addGuestTraveler(contactIndex, traveler, roomNumber);

				this.setState({
					mode: CONTACT_CARD_MODES.LOCKED,
					failed: false,
					created_guest: true,
					avatarPrefilled: true,
				});

				ReservationActions.removeTravelerError(roomNumber, contactIndex);
			} else {
				ReservationActions.addTravelerError(roomNumber, contactIndex);
			}
		}
	}

	removeCard(e) {
		let { reservation, contactIndex } = this.props;
		let { mode, mandatoryUseProfileEmail } = this.state;

		let traveler = Object.assign(
			{},
			mode === CONTACT_CARD_MODES.LOCKED ? reservation.travelers[contactIndex] : new User(this.state.traveler),
		);

		ReservationActions.resetTraveler(contactIndex);

		this.setState({
			mode: isAutocompleteEnabled() ? CONTACT_CARD_MODES.AUTOCOMPLETE : CONTACT_CARD_MODES.EDIT,
			traveler: {
				full_name: '',
				first_name: '',
				last_name: '',
				email: mandatoryUseProfileEmail && contactIndex === 0 ? this.state.traveler.email : '',
				phone: '',
				shouldCreateUser: this.state.shouldCreateUser,
			},
			search_text: '',
			showEnglishCharsMessage: false,
			validFirstName: true,
			validLastName: true,
			validEmail: true,
			validPhone: true,
			validationEnabled: false,
			failed: false,
			busy: false,
			created_traveler: false,
			created_guest: false,
		});

		Analytics.removedTraveler(reservation.id, traveler.first_name, traveler.last_name, traveler.email);
	}

	editCard(e) {
		const { reservation, contactIndex } = this.props;
		const { mode } = this.state;

		let traveler = Object.assign(
			{},
			mode === CONTACT_CARD_MODES.LOCKED ? reservation.travelers[contactIndex] : new User(this.state.traveler),
		);

		ReservationActions.resetTraveler(contactIndex);

		this.setState((prevState) => ({
			...prevState,
			traveler: {
				full_name: traveler?.fullName || '',
				first_name: traveler?.first_name || '',
				last_name: traveler?.last_name || '',
				email: traveler?.email || '',
				phone: traveler?.phone || '',
				shouldCreateUser: this.state.shouldCreateUser,
			},
			search_text: traveler?.first_name || prevState.searchText,
			mode: isAutocompleteEnabled() ? CONTACT_CARD_MODES.AUTOCOMPLETE : CONTACT_CARD_MODES.EDIT,
			showEnglishCharsMessage: false,
			validFirstName: true,
			validLastName: true,
			validEmail: true,
			validPhone: true,
			validationEnabled: false,
			failed: false,
			busy: false,
			created_traveler: false,
			created_guest: false,
		}));
	}

	onFirstNameChanged(e) {
		let name = e.target.value;
		let traveler = Object.assign({}, this.state.traveler);
		traveler.first_name = name;
		traveler = TravelersHelper.updateTravelerFullName(traveler);
		const { validationEnabled } = this.state;
		this.setState({
			traveler,
			...(validationEnabled ? { validFirstName: Validator.validateEnglishOnly(name.trim()) } : {}),
		});
	}

	onLastNameChanged(e) {
		let name = e.target.value;
		let traveler = Object.assign({}, this.state.traveler);
		traveler.last_name = name;
		traveler = TravelersHelper.updateTravelerFullName(traveler);
		const { validationEnabled } = this.state;
		this.setState({
			traveler,
			...(validationEnabled ? { validLastName: Validator.validateEnglishOnly(name.trim()) } : {}),
		});
	}

	onEmailChanged(e) {
		let traveler = Object.assign({}, this.state.traveler);
		traveler.email = e.target.value && e.target.value.trim();
		const { validationEnabled } = this.state;
		this.setState({
			traveler,
			...(validationEnabled
				? { validEmail: !traveler.email || Validator.validateEmailAddress(traveler.email) }
				: {}),
		});
	}

	onPhoneChanged(e) {
		const phoneValue = e.target ? e.target.value : e;
		const traveler = { ...this.state.traveler };
		traveler.phone = phoneValue;
		const { validationEnabled } = this.state;

		this.setState({
			traveler,
			...(validationEnabled ? { validPhone: Validator.validatePhoneNumber(phoneValue) } : {}),
		});
	}

	getMockTraveler() {
		switch (this.props.contactIndex) {
			case 0:
				return 'Gustav Silver';

			case 1:
				return 'Lillian Silver';

			case 2:
				return 'Gustav Mercury';

			case 3:
				return 'Lillian Mercury';

			default:
				return 'Gustav Silver';
		}
	}

	fillCardCheat() {
		const { mainGuest } = this.props;

		const full_name = this.getMockTraveler();
		const [first_name, last_name] = full_name.split(' ');

		const traveler = Object.assign({}, this.state.traveler, {
			first_name,
			last_name,
			full_name,
			shouldCreateUser: false,
		});

		if (mainGuest) {
			traveler.email = `${first_name.toLowerCase()}.${last_name.toLowerCase()}@example.com`;
			traveler.phone = '+1234567890';
		}

		if (this.validateTravelerDetails(traveler)) {
			ReservationActions.addGuestTraveler(this.props.contactIndex, traveler, this.props.roomNumber);
			this.setState({
				traveler,
				mode: CONTACT_CARD_MODES.LOCKED,
				failed: false,
				created_guest: true,
			});
		}
	}

	updateTraveler(profile) {
		ReservationActions.updateTraveler(
			this.props.contactIndex,
			new User(
				Object.assign(profile, {
					room: this.props.roomNumber,
				}),
			),
		);
	}

	onTravelerAutoCompleteBlur() {
		this.setState({
			validFirstName: true,
			validLastName: true,
			validEmail: true,
			validPhone: true,
		});
	}

	onTravelerAutoCompleteUpdateInput(searchText) {
		let traveler = Object.assign({}, this.state.traveler);
		traveler.first_name = searchText;
		traveler = TravelersHelper.updateTravelerFullName(traveler);
		const { validationEnabled } = this.state;
		this.setState({
			traveler,
			search_text: searchText,
			...(validationEnabled ? { validFirstName: Validator.validateEnglishOnly(searchText.trim()) } : {}),
		});
	}

	setEnglishCharsMessage(validated) {
		this.setState({ showEnglishCharsMessage: validated });
	}

	onTravelerAutoCompleteNewRequest(data) {
		if (!data) {
			return;
		}

		if (data.id !== -1) {
			this.updateTraveler(data);

			ReservationActions.removeTravelerError(this.props.roomNumber, this.props.contactIndex);
		}

		let state = {
			validFirstName: true,
			validLastName: true,
			validEmail: true,
			validPhone: true,
		};

		if (data.id === -1) {
			state.mode = CONTACT_CARD_MODES.EDIT;
		} else if (data.id) {
			state.mode = CONTACT_CARD_MODES.LOCKED;
			state.failed = false;

			let reservation = this.props.reservation;
			let traveler = new User(data);

			Analytics.pickedTravelerFromAutocomplete(
				this.state.profile.company,
				reservation.id,
				traveler.first_name,
				traveler.last_name,
				traveler.email,
			);
		} else {
			return;
		}

		this.setState(state);
	}

	createTraveler() {
		this.setState({
			validFirstName: true,
			validLastName: true,
			validEmail: true,
			validPhone: true,
			mode: CONTACT_CARD_MODES.EDIT,
		});
	}

	shouldDisplayRemoveButton() {
		let { mode } = this.state;

		if (mode === CONTACT_CARD_MODES.AUTOCOMPLETE) {
			return false;
		}

		return !(mode === CONTACT_CARD_MODES.EDIT && !isAutocompleteEnabled());
	}

	shouldDisplayEmailError() {
		const { validEmail, traveler } = this.state;
		const valid_email = emailRegex.test(traveler.email);

		if (validEmail) {
			return false;
		}

		return !(!traveler.email || valid_email);
	}

	renderPhoneInput() {
		const { traveler, validPhone, busy, profile } = this.state;
		const { contactIndex } = this.props;
		const show_country_code_phone = _.get(profile, 'company.partners_ui.show_country_code_phone', false);

		const commonProps = {
			className: classNames('field-input phone', { error: !validPhone }),
			value: traveler.phone,
			onChange: this.onPhoneChanged,
			fullWidth: true,
			disabled: busy,
		};

		return (
			<div style={{ position: 'relative' }}>
				{show_country_code_phone ? (
					<MuiTelInput {...commonProps} defaultCountry="US" id={`phone-number-input-${contactIndex}`} />
				) : (
					<TextField {...commonProps} type="tel" id={`phone-number-input-${contactIndex}`} />
				)}
				{show_country_code_phone && <div className="caret-down"></div>}
			</div>
		);
	}

	renderAvatar(reservation_traveler) {
		const { avatarPrefilled } = this.state;
		// avatar initial rendering is controled based on disable_travelers_data_prefill flag
		const showAvatar = avatarPrefilled || this.state.mode === CONTACT_CARD_MODES.LOCKED;

		return (
			<Avatar
				className="avatar"
				{...avatarProps({
					fullName: showAvatar ? reservation_traveler.full_name : '',
					url: reservation_traveler.picture,
				})}
				onDoubleClick={Config.dev_mode ? this.fillCardCheat : null}
			/>
		);
	}

	render() {
		let DETAILS = [];

		let { disabled, contactIndex, travelerCountPerRoom, mainGuest } = this.props;
		let {
			mode,
			busy,
			search_text,
			traveler,
			validFirstName,
			validLastName,
			validEmail,
			showEnglishCharsMessage,
			profile,
			mandatoryUseProfileEmail,
		} = this.state;

		if (!traveler) {
			traveler = {};
		}

		let autocomplete_mode = mode === CONTACT_CARD_MODES.AUTOCOMPLETE;
		let locked_mode = mode === CONTACT_CARD_MODES.LOCKED;

		let reservation_traveler = this.props.reservation.travelers[contactIndex];

		if (!reservation_traveler) {
			return null;
		}

		let email_exists = typeof reservation_traveler.email === 'string' && reservation_traveler.email.length;
		let phone_exists = reservation_traveler.phone.length;

		if (disabled || locked_mode) {
			DETAILS.push(
				<div
					className={'card-div traveler-name-div' + (email_exists ? '' : ' name-only')}
					key="traveler-name-key"
				>
					<span title={reservation_traveler.full_name}>{reservation_traveler.full_name}</span>
				</div>,
			);
			if (email_exists) {
				DETAILS.push(
					<div className="card-div traveler-email-div" key="traveler-email-key">
						<span title={reservation_traveler.email}>{reservation_traveler.email}</span>
					</div>,
				);
			}
			if (phone_exists) {
				DETAILS.push(
					<div className="card-div traveler-email-div" key="traveler-phone-key">
						<span title={reservation_traveler.phone}>{reservation_traveler.phone}</span>
					</div>,
				);
			}
		} else if (!locked_mode) {
			DETAILS.push(
				autocomplete_mode ? (
					<div className={'card-name-input' + (traveler.first_name ? ' has-value' : '')} key="first-name-key">
						<label htmlFor={`first-name-input-${contactIndex}`}>First Name</label>
						<TravelersAutocomplete
							id={`first-name-input-${contactIndex}`}
							key="autocomplete-key"
							onClose={this.onTravelerAutoCompleteBlur}
							fullWidth
							disabled={busy}
							searchText={search_text}
							onNewRequest={this.onTravelerAutoCompleteNewRequest}
							onUpdateInput={this.onTravelerAutoCompleteUpdateInput}
							setEnglishCharsMessage={this.setEnglishCharsMessage}
							error={!validFirstName}
							agent_contract_id={this.state.agent_contract_id}
							agent_client_company_id={this.state.agent_client_company_id}
						/>
						{showEnglishCharsMessage && (
							<span className="invalid-traveler" key="invalid-traveler-3">
								Please make sure you type in English
							</span>
						)}
					</div>
				) : (
					<div className={'card-name-input' + (traveler.first_name ? ' has-value' : '')} key="first-name-key">
						<label htmlFor={`first-name-input-${contactIndex}`}>First Name</label>
						<TextField
							id={`first-name-input-${contactIndex}`}
							className={classNames('field-input', !validFirstName && 'error')}
							disabled={busy}
							fullWidth
							value={traveler.first_name}
							onChange={this.onFirstNameChanged}
						/>
					</div>
				),
			);

			DETAILS.push(
				<div className={'card-name-input' + (traveler.last_name ? ' has-value' : '')} key="last-name-key">
					<label htmlFor={`last-name-input-${contactIndex}`}>Last Name</label>
					<TextField
						id={`last-name-input-${contactIndex}`}
						className={classNames('field-input', !validLastName && 'error')}
						disabled={busy}
						fullWidth
						value={traveler.last_name}
						onChange={this.onLastNameChanged}
					/>
				</div>,
			);

			DETAILS.push(
				<div
					className={'card-input traveler-email-input' + (traveler.email ? ' has-value' : '')}
					key="email-key"
				>
					<label htmlFor={`email-input-${contactIndex}`}>{mainGuest ? 'Email' : 'Email (optional)'}</label>
					<TextField
						id={`email-input-${contactIndex}`}
						className={classNames(
							'field-input',
							(!validEmail || this.shouldDisplayEmailError()) && 'error',
						)}
						disabled={busy || (mainGuest && mandatoryUseProfileEmail)}
						fullWidth
						value={traveler.email}
						onChange={this.onEmailChanged}
					/>
				</div>,
			);
			if (mainGuest) {
				DETAILS.push(
					<div
						className={'card-input traveler-phone-input' + (traveler.phone ? ' has-value' : '')}
						key="phone-key"
					>
						<label htmlFor={`phone-number-input-${contactIndex}`}>Phone Number</label>
						{this.renderPhoneInput()}
					</div>,
				);
			}
		}

		let ACTIONS = [];
		if (!locked_mode) {
			ACTIONS.push(
				<div key={`${contactIndex}-button-container`} className="button-container">
					<ArbiCancelButton
						key={contactIndex + '-clear'}
						onClick={busy ? _.noop : this.removeCard}
						disabled={busy}
						aria-label={`Clear traveler ${contactIndex + 1}`}
					>
						Clear
					</ArbiCancelButton>
					<LoadingButton
						className="action-button add-button"
						onClick={this.saveCard}
						loading={busy}
						disableRipple
						aria-label={`Add traveler ${contactIndex + 1}`}
					>
						{!busy && 'Add'}
					</LoadingButton>
				</div>,
			);
		}

		let contact_card_classes = 'contact-card';

		if (locked_mode) {
			contact_card_classes += ' locked';
		}

		return (
			<div className={contact_card_classes}>
				<div className="content-container">
					<p className="card-title">Traveler {travelerCountPerRoom}</p>
					<div className="user-container">
						<div className="avatar-container">{this.renderAvatar(reservation_traveler)}</div>
						<div className="contact-details">{DETAILS}</div>
					</div>

					{ACTIONS.length > 0 && <div className="action-container">{ACTIONS}</div>}

					{this.shouldDisplayRemoveButton() && (
						<IconButton
							onClick={busy ? _.noop : this.editCard}
							disabled={busy || disabled}
							aria-label={`Edit traveler ${contactIndex + 1}`}
							size="small"
							className="edit-traveler-button"
						>
							<EditIcon fontSize="inherit" />
						</IconButton>
					)}
				</div>
				{this.state.failed ? (
					<div className="card-alert failed-to-add-traveler">
						<span>
							Failed to add traveler. Please try again or{' '}
							<a href="mailto:support@arbitrip.com" target="_blank">
								contact us
							</a>
							.
						</span>
					</div>
				) : null}
				{!this.state.failed && this.shouldDisplayEmailError() && (
					<div className="card-alert invalid-email">
						<span>Please insert a valid email address.</span>
						{!this.state.private_travel && (
							<span>No worries! No email will be sent to travelers unless requested by you.</span>
						)}
					</div>
				)}
			</div>
		);
	}
}

ContactCardLight.propTypes = {
	reservation: PropTypes.any,
	contactIndex: PropTypes.number,
	createTravelerStatus: PropTypes.string,
	roomNumber: PropTypes.number,
	disabled: PropTypes.bool,
	mainGuest: PropTypes.bool,
};

export default ContactCardLight;
