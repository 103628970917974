import React from 'react';
import createClass from 'create-react-class';

import { TextField, MenuItem, Button, FormControl, InputLabel, Select } from '@mui/material';
import Icon from '../../../../ReactEvilIcon';
import Currencies from '../../../../../utils/Currencies';

import TravelAgentStore from '../../../../../stores/TravelAgentStore';
import TravelAgentActions from '../../../../../actions/TravelAgentActions';
import TravelAgentConstants from '../../../../../constants/TravelAgentConstants';

const numbers_regex = new RegExp('[^\\d.-]', 'g');

// function getContractMarginPercentageForDemo(contract) {
//   return Math.round(100 * (contract.pricing_config.margin_percent_fixed * 100 - 100)) / 100;
// }

function companyMenuItems(items, chosen_contract_id) {
	if (Array.isArray(items)) {
		return items
			.filter((o) => !o.agency)
			.map((option, idx) => {
				let chosen = option._id === chosen_contract_id;
				// let margin = chosen
				//   ? TravelAgentStore.getMargin()
				//   : getContractMarginPercentageForDemo(option);

				return (
					<MenuItem key={idx} value={option._id}>
						{option.company.name}
					</MenuItem>
				);
			});
	}

	return [];
}

function getAgentSettingsModalState() {
	let get_contract_status = TravelAgentStore.getGetContractStatus();
	let update_contract_status = TravelAgentStore.getUpdateContractStatus();

	return {
		get_status: get_contract_status,
		save_status: update_contract_status,
		contract_id: TravelAgentStore.getContractId() || '',
		margin: TravelAgentStore.getMargin() || '',
		fixed_fee: TravelAgentStore.getFixedFee() || '',
		agents_support_email: 'agents@arbitrip.com', // TODO: maybe manage in a global constants file with other arbitrip email addresses like general support
		busy:
			get_contract_status === TravelAgentConstants.STATUS.BUSY ||
			update_contract_status === TravelAgentConstants.STATUS.BUSY,
		discard_changes_dialog_open: false,
	};
}

var AgentSettingsModal = createClass({
	displayName: 'AgentSettingsModal',

	shouldAlert: function () {
		return (
			this.state.contract_id &&
			(Number(this.state.margin) !== Number(TravelAgentStore.getMargin()) ||
				Number(this.state.fixed_fee) !== Number(TravelAgentStore.getFixedFee()))
		);
	},

	openDiscardChangesDialog: function (action, data) {
		this.setState({
			discard_changes_dialog_open: true,
			action_before_discard_changes: action,
			action_data_before_discard_changes: data,
		});
	},

	closeDiscardChangesDialog: function () {
		this.setState({
			discard_changes_dialog_open: false,
			action_before_discard_changes: null,
			action_data_before_discard_changes: null,
		});
	},

	getInitialState: function () {
		return getAgentSettingsModalState();
	},
	componentDidMount: function () {
		TravelAgentStore.addChangeListener(this._onChange);
	},

	componentWillUnmount: function () {
		TravelAgentStore.removeChangeListener(this._onChange);
	},

	componentDidUpdate(prevProps) {
		const { closeRequestCount } = this.props;

		if (closeRequestCount > prevProps.closeRequestCount) {
			if (!this.shouldAlert()) {
				TravelAgentActions.closeTravelAgentSettingsModal();
				this.props.closeTopMenu();
			} else {
				this.openDiscardChangesDialog(TravelAgentConstants.MODAL_ACTIONS.CLOSE_REQUEST);
			}
		}
	},

	onCloseClick: function (e) {
		if (!this.shouldAlert()) {
			TravelAgentActions.closeTravelAgentSettingsModal();
			this.props.closeTopMenu();
		} else {
			this.openDiscardChangesDialog(TravelAgentConstants.MODAL_ACTIONS.CLOSE);
		}
	},

	onCancelClick: function (e) {
		if (!this.shouldAlert()) {
			TravelAgentActions.closeTravelAgentSettingsModal();
			this.props.closeTopMenu();
		} else {
			this.openDiscardChangesDialog(TravelAgentConstants.MODAL_ACTIONS.CANCEL);
		}
	},

	handleOnBlur: function (e) {},

	changeContract: function (contract_id) {
		this.setState({
			contract_id: contract_id,
		});

		TravelAgentActions.getCompanyContract(contract_id);
	},

	handleContractOnChange: function (event) {
		if (!this.shouldAlert()) {
			this.changeContract(event.target.value);
		} else {
			this.openDiscardChangesDialog(TravelAgentConstants.MODAL_ACTIONS.CHANGE_CLIENT, event.target.value);
		}
	},

	handleMarginOnChange: function (event) {
		const newValue = event.target.value;
		let new_margin = newValue && newValue.replace(numbers_regex, '');

		this.setState({
			margin: new_margin,
		});
	},

	handleFixedFeeOnChange: function (event) {
		const newValue = event.target.value;
		let new_fixed_fee = newValue && newValue.replace(numbers_regex, '');
		this.setState({
			fixed_fee: new_fixed_fee,
		});
	},

	handleSaveOnClick: function (e) {
		const contract = {
			margin: this.state.margin,
			fixed_fee: this.state.fixed_fee,
		};
		TravelAgentActions.updateCompanyContract(this.state.contract_id, contract);
	},

	handleDiscardChanges: function () {
		switch (this.state.action_before_discard_changes) {
			case TravelAgentConstants.MODAL_ACTIONS.CHANGE_CLIENT:
				this.changeContract(this.state.action_data_before_discard_changes);
				break;

			case TravelAgentConstants.MODAL_ACTIONS.CLOSE:
				TravelAgentActions.closeTravelAgentSettingsModal();
				this.props.closeTopMenu();
				break;

			case TravelAgentConstants.MODAL_ACTIONS.CANCEL:
				TravelAgentActions.closeTravelAgentSettingsModal();
				this.props.closeTopMenu();
				break;

			case TravelAgentConstants.MODAL_ACTIONS.CLOSE_REQUEST:
				TravelAgentActions.closeTravelAgentSettingsModal();
				this.props.closeTopMenu();
				break;

			default: // Do nothing
		}
	},

	handleCancelDiscardChanges: function () {
		this.closeDiscardChangesDialog();
		this.props.closeTopMenu();
	},

	render: function () {
		let { contract_id, margin, fixed_fee, agents_support_email, busy } = this.state;
		let { profile } = this.props;
		let cur = Currencies.getSymbol(profile.currency);

		return (
			<div className="agent-settings-modal">
				<div className="modal-title">
					<h4>Clients Settings</h4>
					<a href="#!" className="action close-modal" onClick={this.onCloseClick}>
						<Icon name="ei-close" size="m" className="evil-icon" />
					</a>
				</div>
				<div className="modal-fields">
					<div className="field company">
						<FormControl fullWidth disabled={busy || !profile.agency_contracts}>
							<InputLabel id="client-name-label">Client Name</InputLabel>
							<Select
								className="field-input select"
								labelId="client-name-label"
								placeholder="Choose a client name to change its settings"
								value={contract_id}
								onBlur={this.handleOnBlur}
								onChange={this.handleContractOnChange}
								variant="standard"
								displayEmpty
							>
								<MenuItem disabled value="">
									Choose a client name to change its settings
								</MenuItem>
								{companyMenuItems(profile.agency_contracts, contract_id)}
							</Select>
						</FormControl>
					</div>
					<div className="field margin">
						<TextField
							className="field-input"
							label="Margin (%)"
							placeholder="e.g. 5%"
							fullWidth={true}
							value={margin}
							onChange={this.handleMarginOnChange}
							disabled={busy}
							variant="standard"
							InputLabelProps={{ shrink: true }}
						/>
					</div>
					<div className="field fixed-fee">
						<TextField
							className="field-input"
							label="Fixed Fee"
							placeholder={'e.g. ' + cur + '30'}
							fullWidth={true}
							value={fixed_fee}
							onChange={this.handleFixedFeeOnChange}
							disabled={busy}
							variant="standard"
							InputLabelProps={{ shrink: true }}
						/>
					</div>
				</div>
				<div className="disclaimer">
					<span>Can't find your client? To add a new client please send an email to</span>
					<a href={'mailto:' + agents_support_email} target="_blank">
						{agents_support_email}
					</a>
				</div>
				<hr />
				<div className={'modal-actions' + (busy ? ' busy' : '')}>
					<div className="actions">
						<a href="#!" className="action save-changes" onClick={this.handleSaveOnClick}>
							Save
						</a>
						<a href="#!" className="action cancel-changes" onClick={this.onCancelClick}>
							Cancel
						</a>
						{busy ? (
							this.state.save_status === TravelAgentConstants.STATUS.BUSY ? (
								<span className="loader bottom-right-corner">Saving...</span>
							) : (
								<i className="fa fa-spin fa-spinner bottom-right-corner busy-indicator" />
							)
						) : null}
						{!busy && this.shouldAlert() ? (
							<span className="loader bottom-right-corner">Not saved</span>
						) : null}
						{!busy &&
						!this.shouldAlert() &&
						this.state.save_status === TravelAgentConstants.STATUS.SUCCESS ? (
							<span className="loader bottom-right-corner">Saved</span>
						) : null}
					</div>
				</div>
				{this.state.discard_changes_dialog_open ? (
					<div className="discard-changes-dialog">
						<div className="message">
							<div>Changes are not saved! Are you sure you want to ignore the changes?</div>
						</div>
						<br />
						<div className="actions">
							<Button variant="text" primary={true} onClick={this.handleDiscardChanges}>
								Yes
							</Button>
							<Button variant="text" primary={true} onClick={this.handleCancelDiscardChanges}>
								No
							</Button>
						</div>
					</div>
				) : null}
			</div>
		);
	},

	_onChange: function () {
		this.setState(getAgentSettingsModalState());
	},
});

export default AgentSettingsModal;
