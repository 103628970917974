const _ = require('lodash');

var _parsed_params = {};
const moment = require('moment');
const date_format = 'YYMMDD';

const Base64 = require('./Base64');

function convertToNumber(input, default_value = 0, max_invalid_value = -1) {
  var num = Number(input);
  return (num > max_invalid_value ? num : default_value);
}

const destination_address = 'destination_address';
const destination_place = 'destination_place';

// var convertToAtLeastNumberOne = (input) => convertToNumber(input, 1, 0);

var parse_function_mapping = {
  rooms: (x) => convertToNumber(x, 1, 0),
  guest_count: (x) => convertToNumber(x, 1, 0),
  check_in: SplitDateFromString,
  check_out: SplitDateFromString,
  destination_address: (x) => getDecodedUriComponent(destination_address, x),
  destination_place: (x) => getDecodedUriObject(destination_place, x),
  travelers: parseTravelers
};

function SplitDateFromString(date_input) {
  if (!date_input) {
    return null;
  }
  const split_date = date_input.split('-');
  if (split_date.length < 2) {
    return null;
  }
  return new Date(Date.UTC(split_date[0], split_date[1] - 1, split_date[2]));
}

function getDecodedUriComponent(parameter_name, parameter) {
  try {
    return decodeURIComponent(parameter)
  } catch (e) {
    console.error('could not parse ' + parameter_name, e);
    return undefined;
  }
}

function getDecodedUriObject(parameter_name, parameter) {
  try {
    return JSON.parse(decodeURIComponent(parameter));
  } catch (e) {
    console.error('could not parse ' + parameter_name, e);
    return undefined;
  }
}

function parseTravelers(travelers) {
  if (!travelers) {
    return [];
  }
  try {
    travelers = JSON.parse(decodeURIComponent(travelers));
    if (!travelers || !Array.isArray(travelers) || travelers.length === 0) {
      return [];
    }
    return travelers;
  }
  catch (e) {
    console.error('could not parse params.travelers ', e);
    return [];
  }
}

function Create(params) {
  Object.entries(params).forEach(entry => {
    var key = entry[0];
    var value = entry[1];
    if (value === 'undefined') {
      console.warn('No value for expected key ' + key);
    } else {
      _parsed_params[key] = (parse_function_mapping[key] ? (parse_function_mapping[key](value)) : value);
    }
  });

  if (_parsed_params[destination_address] && _parsed_params[destination_place]) {
    _parsed_params.destination = {
      place: _parsed_params[destination_place],
      address: _parsed_params[destination_address],
    };
  }

  if (_parsed_params["guest_count"]) {
    _parsed_params["guests"] = _parsed_params["guest_count"] / _parsed_params["rooms"];
  }

  _parsed_params["search_terms"] = true;
  return _parsed_params;
}

function isDate(d) {
  return moment.isMoment(d) || (d instanceof moment) || (d instanceof Date);
}

module.exports = {
  Create: Create,
  Instance: _parsed_params,
  objToURLParams: function toUrlParams(obj) {
    return Object.keys(obj).map(function (key) {
      let val = obj[key];
      if (key === 'address' && typeof val === 'string') {
        try {
          let compressed = Base64.encode(val);
          val = compressed;
        } catch (err) {
          console.error(err);
        }
      } else if (key === 'location' && typeof val === 'object') {
        try {
          val = Base64.encode(JSON.stringify(val));
        } catch (err) {
          console.error(err);
        }
      } else if (key === 'children_ages' && _.isArray(val) && val.length > 0) {
        return `${key}=${val.join(',')}`;
      } else if ((key === 'check_in') || (key === 'check_out')) {
        return key + '=' + (isDate(val)
          ? moment(val).format(date_format)
          : val); // TODO: consider more sophisticated handling...
      } else if (val instanceof Object) {
        // return key + '=' + encodeURIComponent(JSON.stringify(val));
        return '';
      } else if (!val) {
        return '';
      }
      return key + '=' + encodeURIComponent(val);
    }).filter(kv => kv).join('&')
  },
  DATE_FORMAT: date_format,
};
