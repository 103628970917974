import ContentLoader from 'react-content-loader';

const RepresentativeDealLoader = () => (
	<ContentLoader width={120} height={240} viewBox="0 0 120 240">
		{/* price */}
		<rect x="54" y="0" rx="0" ry="0" width="66" height="26" />

		{/* per night */}
		<rect x="58" y="33" rx="0" ry="0" width="62" height="21" />

		{/* policy */}
		<rect x="50" y="63" rx="0" ry="0" width="70" height="22" />

		{/* action */}
		<rect x="0" y="199" rx="4" ry="4" width="135" height="41" />
	</ContentLoader>
);

export default RepresentativeDealLoader;
