import _ from 'lodash';

import React from 'react';
import createClass from 'create-react-class';

import ManagementStore from '../../../stores/ManagementStore';
import ProfileStore from '../../../stores/ProfileStore';

import ManagementActions from '../../../actions/ManagementActions';

import TitleManager from '../../../utils/TitleManager';

import _NameBar from './NameBar.react';

import styles from '../../../utils/Styles';
// import AdditionalInfo from './AdditionalInfo.react';
import Validator from 'arbitrip-common/client/utils/Validator';
import ManagementConstants from '../../../constants/ManagementConstants';

import AddFlight from './AddFlight.react';

import EntityValidator from '../../../utils/EntityValidator';
import { withRouter } from '../../../utils/withRouter';
import { Checkbox, Paper, TextField } from '@mui/material';
import DocumentTitle from '../../general/DocumentTitle.react';

const NameBar = withRouter(_NameBar);

const EMAIL_IS_REQUIRED = 'Email is required!';
const EMAIL_IS_INVALID = 'Email is invalid!';

const cc_regex = new RegExp('cocacoola');

function getForwardRequestsPageState() {
	return {
		trip_request: ManagementStore.getTripRequest(),
		profile: ProfileStore.getProfile(),
		source: ManagementStore.getTripRequestPageSource(),
		status: ManagementStore.getForwardTripRequestStatus(),
	};
}

const ForwardRequestsPage = createClass({
	displayName: 'ForwardRequestsPage',

	getInitialState: function () {
		let page_state = getForwardRequestsPageState();

		page_state.to = '';
		page_state.to_error = '';

		page_state.checked = {};
		page_state.trip_request.flights.forEach((flight) => (page_state.checked[flight.id] = true));

		if (cc_regex.test(page_state.profile.email)) {
			page_state.to = 'agent@arbitrip.com';
		}

		page_state.special_requests = page_state.trip_request.special_requests;

		return page_state;
	},

	componentDidMount: function () {
		const { navigate } = this.props;

		ManagementStore.addChangeListener(this._onChange);
		ProfileStore.addChangeListener(this._onChange);
		window.scrollTo(0, 0);

		if (!EntityValidator.validateTripRequest(this.state.trip_request)) {
			navigate(ManagementConstants.SOURCES.TRIPS);
		}
	},

	componentWillUnmount: function () {
		ManagementStore.removeChangeListener(this._onChange);
		ProfileStore.removeChangeListener(this._onChange);
	},

	componentWillUpdate: function (nextProps, nextState) {
		// TODO: state update
		if (
			this.state.status !== ManagementConstants.STATUS.SUCCESS &&
			nextState.status === ManagementConstants.STATUS.SUCCESS
		) {
			const { navigate } = this.props;
			navigate('/' + ManagementConstants.SOURCES.TRIP + '/' + this.state.trip_request.id);
		}
	},

	handleToOnChange: function (e) {
		let { to_error } = this.state;
		let to = e.target.value;

		this.setState({
			to: to,
		});

		if (
			(to_error === EMAIL_IS_REQUIRED && to) ||
			(to_error === EMAIL_IS_INVALID && (!to || Validator.validateEmailAddress(to)))
		) {
			this.setState({
				to_error: '',
			});
		}
	},

	handleSpecialRequestOnChange: function (e) {
		this.setState({ special_requests: e.target.value });
	},

	handleOnForwardClick: function (e) {
		const { to, checked, status, special_requests, trip_request } = this.state;
		let busy = status === ManagementConstants.STATUS.BUSY;
		if (!busy) {
			if (!to) {
				this.setState({ to_error: EMAIL_IS_REQUIRED });
			} else if (!Validator.validateEmailAddress(to)) {
				this.setState({ to_error: EMAIL_IS_INVALID });
			} else {
				let components_ids = Object.keys(checked).filter((id) => checked[id]);
				ManagementActions.forwardTripRequest(components_ids, [to], special_requests, trip_request.id);
			}
		}
	},

	handleOnCancelClick: function (e) {
		const { navigate } = this.props;
		const { status, trip_request } = this.state;
		let busy = status === ManagementConstants.STATUS.BUSY;
		if (!busy) {
			navigate('/trip/' + trip_request.id);
		}
	},

	handleOnCheckAll: function () {
		let checked = this.state.checked;
		let check_all = Object.values(checked).every((c) => c);
		Object.keys(checked).forEach((id) => (checked[id] = !check_all));
		this.setState({ checked });
	},

	generateSingleFlightOnCheckHandler: function (id) {
		let _self = this;
		return function (e) {
			let checked = _self.state.checked;
			checked[id] = !checked[id];
			_self.setState({ checked });
		};
	},

	render: function () {
		let { trip_request, profile, to, to_error, checked, status } = this.state;
		let check_all = Object.values(checked).every((c) => c);
		let check_none = Object.values(checked).every((c) => !c);
		const title = TitleManager.buildTitleStartsWith(_.get(trip_request, 'name', ''));

		let busy = status === ManagementConstants.STATUS.BUSY;

		return (
			<DocumentTitle title={title}>
				<div className="forward-requests-page">
					<NameBar viewOnly={true} name={trip_request.name} trip_id={trip_request.id} closeMode={'trip'} />
					<Paper elevation={2} className="forward-requests paper">
						<div className={'meta' + (busy ? ' busy' : '')}>
							<div className="title">
								<span>Forward Requests</span>
							</div>
							<div className="field">
								<TextField
									className="field-input"
									autoFocus
									label="To"
									placeholder="e.g. john.smith@company.com"
									fullWidth
									value={to}
									error={Boolean(to_error)}
									helperText={to_error || ''}
									onChange={this.handleToOnChange}
								/>
							</div>
							<div className="additional-info section">
								<div className="field">
									<TextField
										className="field-input"
										label="Special Requests and Notes"
										fullWidth
										value={this.state.special_requests}
										onChange={this.handleSpecialRequestOnChange}
									/>
								</div>
							</div>
							{/*<AdditionalInfo trip_request={trip_request} profile={profile}/>*/}
						</div>
						<div className={'requests' + (busy ? ' busy' : '')}>
							<div className="title-line">
								<span>Requests to Forward</span>
								<div className="check">
									{check_all ? (
										<Checkbox
											title="Select All"
											checked={check_all}
											onChange={busy ? null : this.handleOnCheckAll}
										/>
									) : (
										<Checkbox
											checked={!check_none}
											indeterminate={!check_none}
											onChange={busy ? null : this.handleOnCheckAll}
										/>
									)}
								</div>
							</div>
							<div className={'components' + (busy ? ' busy' : '')}>
								{trip_request.flights
									.filter((flight) => flight.id)
									.map((flight) => (
										<div
											key={flight.id}
											className={'flight-container' + (checked[flight.id] ? ' checked' : '')}
										>
											<AddFlight
												trip={trip_request}
												flight={flight}
												key={flight.id}
												profile={profile}
												forward
												checked={checked[flight.id]}
												disabling
											/>
											<div className="check">
												<Checkbox
													checked={checked[flight.id]}
													onCheck={
														busy ? null : this.generateSingleFlightOnCheckHandler(flight.id)
													}
												/>
											</div>
										</div>
									))}
							</div>
						</div>
						<div className={'actions-container' + (busy ? ' busy' : '')}>
							<a href="#!" className="action forward" onClick={this.handleOnForwardClick}>
								Forward
							</a>
							<a href="#!" className="action cancel" onClick={this.handleOnCancelClick}>
								Cancel
							</a>
							{busy ? <i className="fa fa-spin fa-spinner bottom-right-corner busy-indicator" /> : null}
						</div>
					</Paper>
				</div>
			</DocumentTitle>
		);
	},

	_onChange: function () {
		this.setState(getForwardRequestsPageState());
	},
});

export default withRouter(ForwardRequestsPage);
