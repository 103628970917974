import React from 'react';

import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';

import { Snackbar, SnackbarContent, IconButton } from '@mui/material';

import {
	Close as CloseIcon,
	CheckCircle as CheckCircleIcon,
	Warning as WarningIcon,
	Error as ErrorIcon,
	Info as InfoIcon,
} from '@mui/icons-material';

import { green, amber } from '@mui/material/colors';

import PropTypes from 'prop-types';

import { SnackbarVariants, SnackbarDuration } from './SnackbarConstants';

const variantIcon = {
	[SnackbarVariants.SUCCESS]: CheckCircleIcon,
	[SnackbarVariants.WARNING]: WarningIcon,
	[SnackbarVariants.ERROR]: ErrorIcon,
	[SnackbarVariants.INFO]: InfoIcon,
};

const styles = (theme) => ({
	position: {
		bottom: '20px',
	},
	success: {
		backgroundColor: green[600],
	},
	error: {
		backgroundColor: theme.palette.error.dark,
	},
	info: {
		backgroundColor: theme.palette.primary.dark,
	},
	warning: {
		backgroundColor: amber[700],
	},
	icon: {
		fontSize: 20,
	},
	iconVariant: {
		opacity: 0.9,
		marginRight: 8,
	},
	message: {
		display: 'flex',
		alignItems: 'center',
	},
});

class SnackbarMessage extends React.Component {
	render() {
		let {
			classes,
			className,
			variant,
			message,
			open,
			onClose,
			anchorPosition = { vertical: 'bottom', horizontal: 'center' },
		} = this.props;

		const Icon = variantIcon[variant];

		return (
			<Snackbar
				className={classNames(classes.position)}
				anchorOrigin={anchorPosition}
				open={open}
				autoHideDuration={SnackbarDuration}
				onClose={onClose}
			>
				<SnackbarContent
					className={classNames(classes[variant], className)}
					aria-describedby="client-snackbar"
					message={
						<span id="client-snackbar" className={classes.message}>
							<Icon className={classNames(classes.icon, classes.iconVariant)} />
							{message}
						</span>
					}
					action={[
						<IconButton
							key="close"
							aria-label="Close"
							color="inherit"
							className={classes.close}
							onClick={onClose}
							size="large"
						>
							<CloseIcon className={classes.icon} />
						</IconButton>,
					]}
				/>
			</Snackbar>
		);
	}
}

SnackbarMessage.propTypes = {
	message: PropTypes.string,
	variant: PropTypes.oneOf(Object.values(SnackbarVariants)),
	anchorPosition: PropTypes.shape({
		vertical: PropTypes.oneOf(['top', 'bottom']),
		horizontal: PropTypes.oneOf(['left', 'center', 'right']),
	}),
	open: PropTypes.bool,
	onClose: PropTypes.func,
	classes: PropTypes.object.isRequired,
	className: PropTypes.string,
};

export default withStyles(styles)(SnackbarMessage);
