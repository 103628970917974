import React from 'react';
import { Button, IconButton, TextField, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ContentLoader from 'react-content-loader';

import Currencies from 'arbitrip-common/general/utils/Currencies';
import Points from 'arbitrip-common/general/utils/Points';

import PointsStore from '../../../stores/PointsStore';
import _ from 'lodash';

const NOT_AVAILABLE = 'N/A';

function getContentLoader(width, height, radius = 0) {
	return (
		<ContentLoader width={width} height={height} viewBox={`0 0 ${width} ${height}`} className="content-loader">
			<rect x="0" y="0" rx={radius} yx={radius} width={width} height={height} />
		</ContentLoader>
	);
}

export default function NewRedeemPoints({
	profile,
	points,
	shouldLockForm,
	redeemBusy,
	redeemLocked,
	reservation,
	privateTravel,
	redeemPointsUsage,
	handleRedeemPointsUsageChange,
	invalidPointsInputErrorMessage,
	handleAddClick,
	retroactiveMode,
}) {
	const loyalty = _.get(profile, 'loyalty', {});
	const {
		balance,
		// currency,
		// points_ex_rate,
		// max_cap,
		user_chosen_points,
	} = points;
	const paid_points = _.get(reservation, 'payment.loyalty.points') || 0;
	const balance_after_booking = balance - user_chosen_points + paid_points;
	const balance_after_booking_currency_value = Currencies.getPriceWithDisplayCurrencyByPrecision(
		balance_after_booking * loyalty.arbitrip_points_to_currency_exchange_rate,
		1,
		_.get(profile, 'company_currency'),
		2,
	);

	const price = Points.extractTotalPrice({ profile, reservation });
	const discount = user_chosen_points * loyalty.arbitrip_points_to_currency_exchange_rate;

	const total = price - discount;
	const expected_points_earned = Points.getExpectedPointsEarned({
		price_in_currency: price,
		points_used: user_chosen_points || 0,
		arbitrip_points_to_currency_exchange_rate: loyalty.arbitrip_points_to_currency_exchange_rate,
		private_travel: privateTravel,
		arbitrip_loyalty_program: loyalty,
	});
	const expected_points_earned_in_currency = Currencies.getPriceWithDisplayCurrencyByPrecision(
		expected_points_earned * loyalty.arbitrip_points_to_currency_exchange_rate,
		1,
		_.get(profile, 'company_currency'),
		2,
	);
	const valid_balance = _.isNumber(balance);
	// const price_value_in_points = (loyalty.arbitrip_points_to_currency_exchange_rate > 0)
	//     ? price / loyalty.arbitrip_points_to_currency_exchange_rate
	//     : 0;
	// const max_amount = valid_balance
	//     ? _.min([
	//         // max_cap, // can't use more points than allowed per reservation (commented out - not supported yet)
	//         balance, // can't use more points than there are in wallet
	//         price_value_in_points // can't use more points than the reservation is worth
	//     ])
	//     : 0;

	const redeem_points_usage_currency_value =
		Number(redeemPointsUsage) > 0
			? Currencies.getPriceWithDisplayCurrencyByPrecision(
					Number(redeemPointsUsage) * loyalty.arbitrip_points_to_currency_exchange_rate,
					1,
					_.get(profile, 'company_currency'),
					2,
				)
			: null;

	const getClass = (className) => className + (retroactiveMode ? ' retroactive' : '');

	return (
		<div className="redeem-points">
			<div className="points-usage">
				<div className="points-balance">
					<div className="balance-title">
						{retroactiveMode ? 'Your balance after update' : 'Your balance after booking'}
					</div>
					<div className="balance-amount">
						{redeemBusy ? (
							getContentLoader(200, 34, 4)
						) : valid_balance ? (
							<React.Fragment>
								<div className="balance-points">{balance_after_booking.toLocaleString()}</div>
								<div className="balance-suffix">{balance_after_booking === 1 ? 'Point' : 'Points'}</div>
								{balance_after_booking_currency_value > 0 && (
									<div className="balance-value">({balance_after_booking_currency_value})</div>
								)}
							</React.Fragment>
						) : (
							NOT_AVAILABLE
						)}
					</div>
				</div>
				<div className={getClass('usage-amount')}>
					<div className="amount-title">Points to Redeem</div>
					<div className="amount-input">
						<div className={getClass('amount-value')}>
							<TextField
								variant="outlined"
								size="small"
								// type='number'
								// inputProps={{ inputMode: 'numeric' }}
								value={redeemPointsUsage}
								// onKeyDown={e => ((e.key === '.') || (e.key === '-') || (e.key === '+') || (e.key === ' ')) && e.preventDefault()}
								// onKeyUp={e => e.target.value = parseInt(e.target.value, 10)}
								onChange={handleRedeemPointsUsageChange}
								fullWidth
								placeholder={valid_balance ? '' : NOT_AVAILABLE}
								disabled={redeemBusy || redeemLocked}
								error={!!invalidPointsInputErrorMessage}
								inputProps={{ maxLength: 7 }}
								// helperText={invalidPointsInputErrorMessage}
							/>
						</div>
						<div className={getClass('amount-button')}>
							<Button
								variant={retroactiveMode ? 'outlined' : 'contained'}
								color="primary"
								size="large"
								onClick={handleAddClick}
								disabled={
									shouldLockForm ||
									redeemBusy ||
									!valid_balance ||
									(!retroactiveMode && !redeemPointsUsage) ||
									(retroactiveMode && redeemPointsUsage === '')
								}
								fullWidth
							>
								{redeemLocked
									? 'Undo'
									: `${retroactiveMode ? 'Use Points' : 'Redeem'}${redeem_points_usage_currency_value ? ` (${redeem_points_usage_currency_value})` : ''}`}
							</Button>
						</div>
					</div>
					{invalidPointsInputErrorMessage && (
						<div className="amount-error">
							<span>{invalidPointsInputErrorMessage}</span>
						</div>
					)}
				</div>
			</div>
			<hr />
			<div className="payment-summary">
				<div className="points-earned">
					<div className="earned-prefix">From this deal you will earn</div>
					{redeemBusy ? (
						getContentLoader(180, 21, 2)
					) : (
						<div className="earned-amount">
							<div className="earned-points">{expected_points_earned}</div>{' '}
							<div className="earned-suffix">{expected_points_earned === 1 ? 'point' : 'points'}</div>{' '}
							{expected_points_earned > 0 && (
								<React.Fragment>
									<div className="earned-value">({expected_points_earned_in_currency})</div>
									<div className="earned-disclaimer">
										<Tooltip
											PopperProps={{
												modifiers: [{ name: 'offset', options: { offset: [0, -10] } }],
											}}
											placement="bottom"
											className="earned-disclaimer-tooltip"
											title={PointsStore.getPointsEarnedDisclaimerText()}
										>
											<IconButton sx={{ width: 20, height: 20 }}>
												<div className="earned-disclaimer-icon">
													<InfoOutlinedIcon sx={{ fontSize: 20 }} />
												</div>
											</IconButton>
										</Tooltip>
									</div>
								</React.Fragment>
							)}
						</div>
					)}
				</div>
				<div className={getClass('summary-prices')}>
					<div className="summary-price">
						<div className="price-prefix">Price</div>
						<div className="price-value">
							{Currencies.getPriceWithDisplayCurrencyByPrecision(
								price,
								1,
								_.get(profile, 'company_currency'),
								2,
							)}
						</div>
					</div>
					<div className="summary-discount">
						<div className="discount-prefix">Points Value</div>
						{redeemBusy ? (
							getContentLoader(120, 18, 2)
						) : (
							<div className="discount-value">
								-
								{Currencies.getPriceWithDisplayCurrencyByPrecision(
									discount,
									1,
									_.get(profile, 'company_currency'),
									2,
								)}
							</div>
						)}
					</div>
					<div className="summary-total">
						<div className="total-prefix">Total to Pay</div>
						{redeemBusy ? (
							getContentLoader(180, 34, 4)
						) : (
							<div className="total-value">
								{Currencies.getPriceWithDisplayCurrencyByPrecision(
									total,
									1,
									_.get(profile, 'company_currency'),
									2,
								)}
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
