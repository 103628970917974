import React from 'react';
import createClass from 'create-react-class';
import moment from 'moment';

import ManagementConstants from '../../../constants/ManagementConstants';
import BookedFlight from './BookedFlight';
import BookedAccommodation from './BookedAccommodation';

const booked_items_class = 'booked-items';

var BookedItems = createClass({
	displayName: 'BookedItems',

	render: function () {
		let { itinerary } = this.props;
		let accommodations = (itinerary && Array.isArray(itinerary.accommodations) && itinerary.accommodations) || [];
		let airfares = (itinerary && Array.isArray(itinerary.airfares) && itinerary.airfares) || [];
		let flights = airfares.reduce((acc, cur) => acc.concat(cur.flights), []);
		let items = accommodations
			.map((accommodation) => ({
				type: ManagementConstants.TRIP_BOOKED_COMPONENT_TYPE.ACCOMMODATION,
				component: accommodation,
			}))
			.concat(
				flights.map((flight) => ({
					type: ManagementConstants.TRIP_BOOKED_COMPONENT_TYPE.FLIGHT,
					component: flight,
				})),
			);

		if (!items.length) {
			return (
				<div className={booked_items_class}>
					<span className="no-booked-items">No booked items yet...</span>
				</div>
			);
		}

		// items.sort((a, b) => a.component.display_order - b.component.display_order); // TODO: verify
		items.sort((a, b) => {
			if (a.type === b.type) {
				return a.component.display_order - b.component.display_order;
			} else {
				let multiplier = 1;
				let accommodation = a.component;
				let flight = b.component;
				if (a.type === ManagementConstants.TRIP_BOOKED_COMPONENT_TYPE.FLIGHT) {
					multiplier *= -1;
					accommodation = b.component;
					flight = a.component;
				}

				let check_in = moment(accommodation.deal.details.check_in);
				let check_out = moment(accommodation.deal.details.check_in);
				let depart_date = moment(flight.depart_date);
				let arrival_date = flight.arrival_date ? moment(flight.arrival_date) : null;

				if (arrival_date && check_in.isSameOrAfter(arrival_date, 'day')) {
					return multiplier;
				} else if (check_out.isSameOrBefore(depart_date, 'day')) {
					return multiplier * -1;
				}
			}

			return 0;
		});

		let booked_items = items.map(function (item) {
			switch (item.type) {
				case ManagementConstants.TRIP_BOOKED_COMPONENT_TYPE.ACCOMMODATION:
					return <BookedAccommodation key={item.component.id} accommodation={item.component} mode="trips" />;
				case ManagementConstants.TRIP_BOOKED_COMPONENT_TYPE.FLIGHT:
					return <BookedFlight key={item.component.id} flight={item.component} mode="trips" />;
				default: // Do nothing
			}
			return null;
		});

		return (
			<div className={booked_items_class}>
				<div className="items">{booked_items}</div>
			</div>
		);
	},
});

export default BookedItems;
