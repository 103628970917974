import { useState, useEffect, useMemo } from 'react';
import {
	Popover,
	Paper,
	TextField,
	InputAdornment,
	IconButton,
	Button,
	Select,
	MenuItem,
	Tooltip,
	Checkbox,
} from '@mui/material';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import ManagementConstants from '@/constants/ManagementConstants';
import { ReactComponent as CloseIcon } from '@/img/bookings/x-close.svg';
import { ReactComponent as InfoIcon } from '@/img/icons/info-icon.svg';
import { getUserBranchName } from './helpers/utils';

const format = ManagementConstants.DATE_PICKER_FORMAT;

const bookingFromBranchTooltip = 'Show a list of reservations created by agents attached to agency branch.';
const agencyBranchesListTooltip =
	'Show a list of reservations created by agents attached to the particular agency branch.';

const AdvancedFiltersPopover = ({
	anchorEl,
	open,
	onClose,
	filters,
	setFilters,
	setPagination,
	initPagination,
	initFilters,
	profile,
}) => {
	const [destination, setDestination] = useState(filters.destination);
	const [travelerName, setTravelerName] = useState(filters.traveler_name);
	const [reservationId, setReservationId] = useState(filters.reservation_id);
	const [hotelName, setHotelName] = useState(filters.hotel_name);
	const [startCancellationDeadline, setStartCancellationDeadline] = useState(filters.cancellation_deadline_start);
	const [startCancellationDeadlineOpen, setStartCancellationDeadlineOpen] = useState(false);
	const [endCancellationDeadline, setEndCancellationDeadline] = useState(filters.cancellation_deadline_end);
	const [endCancellationDeadlineOpen, setEndCancellationDeadlineOpen] = useState(false);
	const [agentName, setAgentName] = useState(filters.agent_name);
	const [companyName, setCompanyName] = useState(filters.company_name);
	const [bookingFromBranch, setBookingFromBranch] = useState(filters.bookings_from_branch);
	const [agencyBranches, setAgencyBranches] = useState(filters.agency_branches);

	const bookingFromBranchExtra = useMemo(() => {
		return profile.agency_branch && (profile.agent || profile.travel_manager) && !profile.super_agent;
	}, [profile]);

	const agencyBranchesList = useMemo(() => {
		if (!profile.super_agent) return false;

		return profile.company_branches?.length ? profile.company_branches : false;
	}, [profile]);

	useEffect(() => {
		setDestination(filters.destination);
		setTravelerName(filters.traveler_name);
		setReservationId(filters.reservation_id);
		setHotelName(filters.hotel_name);
		setStartCancellationDeadline(filters.cancellation_deadline_start);
		setEndCancellationDeadline(filters.cancellation_deadline_end);
		setAgentName(filters.agent_name);
		setCompanyName(filters.company_name);
		setBookingFromBranch(filters.bookings_from_branch);
		setAgencyBranches(filters.agency_branches);
	}, [filters]);

	const clearFilters = () => {
		setDestination(initFilters.destination);
		setTravelerName(initFilters.traveler_name);
		setReservationId(initFilters.reservation_id);
		setHotelName(initFilters.hotel_name);
		setStartCancellationDeadline(initFilters.cancellation_deadline_start);
		setEndCancellationDeadline(initFilters.cancellation_deadline_end);
		setAgentName(initFilters.agent_name);
		setCompanyName(initFilters.company_name);
		setBookingFromBranch(initFilters.bookings_from_branch);
		setAgencyBranches(initFilters.agency_branches);
	};

	const applyFilters = () => {
		setPagination(initPagination);
		setFilters({
			...filters,
			destination,
			traveler_name: travelerName,
			reservation_id: reservationId,
			hotel_name: hotelName,
			cancellation_deadline_start: startCancellationDeadline,
			cancellation_deadline_end: endCancellationDeadline,
			agent_name: agentName,
			company_name: companyName,
			bookings_from_branch: bookingFromBranch,
			agency_branches: agencyBranches,
		});
		onClose();
	};

	return (
		<Popover
			open={open}
			className="bookings-advanced-filters-popover"
			anchorEl={anchorEl}
			onClose={onClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
		>
			<Paper className="bookings-advanced-filters">
				<span className="bookings-advanced-filters__title">Advanced search</span>
				<div className="bookings-advanced-filters__filter-group">
					<div className="bookings-advanced-filters__filter">
						<label htmlFor="destination-input">Destination</label>
						<TextField
							id="destination-input"
							placeholder="Find by destination"
							variant="outlined"
							value={destination}
							onChange={(e) => setDestination(e.target.value)}
							InputProps={{
								endAdornment: destination && (
									<InputAdornment position="end">
										<IconButton onClick={() => setDestination(initFilters.destination)}>
											<CloseIcon />
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					</div>
					{/* Traveler Name */}
					<div className="bookings-advanced-filters__filter">
						<label htmlFor="traveler-name-input">Traveler Name</label>
						<TextField
							id="traveler-name-input"
							placeholder="Find by traveler name"
							variant="outlined"
							value={travelerName}
							onChange={(e) => setTravelerName(e.target.value)}
							InputProps={{
								endAdornment: travelerName && (
									<InputAdornment position="end">
										<IconButton onClick={() => setTravelerName(initFilters.traveler_name)}>
											<CloseIcon />
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					</div>
					{/* Reservation ID */}
					<div className="bookings-advanced-filters__filter">
						<label htmlFor="booking-id-input">Reservation ID</label>
						<TextField
							id="booking-id-input"
							placeholder="Find by reservation ID"
							variant="outlined"
							value={reservationId}
							onChange={(e) => setReservationId(e.target.value)}
							InputProps={{
								endAdornment: reservationId && (
									<InputAdornment position="end">
										<IconButton onClick={() => setReservationId(initFilters.reservation_id)}>
											<CloseIcon />
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					</div>
					{/* Hotel Name */}
					<div className="bookings-advanced-filters__filter">
						<label htmlFor="hotel-name-input">Hotel Name</label>
						<TextField
							id="hotel-name-input"
							placeholder="Find by hotel name"
							variant="outlined"
							value={hotelName}
							onChange={(e) => setHotelName(e.target.value)}
							InputProps={{
								endAdornment: hotelName && (
									<InputAdornment position="end">
										<IconButton onClick={() => setHotelName(initFilters.hotel_name)}>
											<CloseIcon />
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					</div>
					{/* Cancellation deadline */}
					<div className="bookings-advanced-filters__filter">
						<label className="bookings-advanced-filters__filter-label">Cancellation Deadline</label>
						<div className="bookings-advanced-filters__filter__row">
							<LocalizationProvider
								dateAdapter={AdapterMoment}
								adapterLocale={ManagementConstants.ADAPTER_MOMENT_LOCALE}
							>
								<DesktopDatePicker
									format={format}
									open={startCancellationDeadlineOpen}
									className="desktop-date-picker"
									onClose={() => setStartCancellationDeadlineOpen(false)}
									onChange={(date) => setStartCancellationDeadline(date)}
									value={startCancellationDeadline}
									slots={{
										// display clear icon instead of default calendar icon
										openPickerButton: () =>
											startCancellationDeadline && (
												<IconButton
													onClick={(e) => {
														e.stopPropagation();
														setStartCancellationDeadline(
															initFilters.cancellation_deadline_start,
														);
													}}
												>
													<CloseIcon />
												</IconButton>
											),
									}}
									slotProps={{
										textField: {
											InputProps: {
												onClick: (e) => {
													e.preventDefault();
													setStartCancellationDeadlineOpen(true);
												},
												onKeyDown: (e) => {
													if (e.key !== 'Tab') {
														e.preventDefault();
														setStartCancellationDeadlineOpen(true);
													}
												},
											},
										},
									}}
									label="Start"
								/>
								<DesktopDatePicker
									format={format}
									open={endCancellationDeadlineOpen}
									className="desktop-date-picker"
									onClose={() => setEndCancellationDeadlineOpen(false)}
									onChange={(date) => setEndCancellationDeadline(date)}
									value={endCancellationDeadline}
									minDate={startCancellationDeadline}
									slots={{
										// display clear icon instead of default calendar icon
										openPickerButton: () =>
											endCancellationDeadline && (
												<IconButton
													onClick={(e) => {
														e.stopPropagation();
														setEndCancellationDeadline(
															initFilters.cancellation_deadline_end,
														);
													}}
												>
													<CloseIcon />
												</IconButton>
											),
									}}
									slotProps={{
										textField: {
											InputProps: {
												onClick: (e) => {
													e.preventDefault();
													setEndCancellationDeadlineOpen(true);
												},
												onKeyDown: (e) => {
													if (e.key !== 'Tab') {
														e.preventDefault();
														setEndCancellationDeadlineOpen(true);
													}
												},
											},
										},
									}}
									label="End"
								/>
							</LocalizationProvider>
						</div>
					</div>
					{/* Booking From Branch */}
					{bookingFromBranchExtra && (
						<div className="bookings-advanced-filters__filter">
							<div className="bookings-advanced-filters__filter__info">
								<label htmlFor="branch-select">Branch</label>
								<Tooltip title={bookingFromBranchTooltip} placement="top" arrow>
									<InfoIcon />
								</Tooltip>
							</div>
							<Select
								id="branch-select"
								value={bookingFromBranch || ''}
								onChange={(e) => setBookingFromBranch(e.target.value)}
								variant="outlined"
								displayEmpty
								renderValue={(selected) => {
									if (selected.length === 0) {
										return <span>Select branch</span>;
									}
									return selected;
								}}
							>
								<MenuItem value={getUserBranchName(profile)}>{getUserBranchName(profile)}</MenuItem>
							</Select>
						</div>
					)}
					{/* Agency Branches */}
					{agencyBranchesList?.length && (
						<div className="bookings-advanced-filters__filter">
							<div className="bookings-advanced-filters__filter__info">
								<label htmlFor="multi-branch-select">Branches</label>
								<Tooltip title={agencyBranchesListTooltip} placement="top" arrow>
									<InfoIcon />
								</Tooltip>
							</div>
							<Select
								id="multi-branch-select"
								multiple
								value={agencyBranches || []}
								onChange={(e) => setAgencyBranches(e.target.value)}
								variant="outlined"
								displayEmpty
								renderValue={(selected) => {
									if (selected.length === 0) {
										return <span>Select branches</span>;
									}
									return selected
										.map((id) => agencyBranchesList.find((branch) => branch._id === id)?.name)
										.join(', ');
								}}
							>
								{agencyBranchesList.map((branch) => (
									<MenuItem key={branch._id} value={branch._id}>
										<Checkbox checked={(agencyBranches || []).includes(branch._id)} />
										<span>{branch.name}</span>
										{branch.is_archived && <b> (Archived)</b>}
									</MenuItem>
								))}
							</Select>
						</div>
					)}
					{profile.agent && (
						<>
							{/* Agent Name */}
							<div className="bookings-advanced-filters__filter">
								<label htmlFor="agent-name-input">Agent Name</label>
								<TextField
									id="agent-name-input"
									placeholder="Find by travel agent name"
									variant="outlined"
									value={agentName}
									onChange={(e) => setAgentName(e.target.value)}
									InputProps={{
										endAdornment: agentName && (
											<InputAdornment position="end">
												<IconButton onClick={() => setAgentName(initFilters.agent_name)}>
													<CloseIcon />
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
							</div>
							{/* Company Name */}
							<div className="bookings-advanced-filters__filter">
								<label htmlFor="company-name-input">Company Name</label>
								<TextField
									id="company-name-input"
									placeholder="Find by company name"
									variant="outlined"
									value={companyName}
									onChange={(e) => setCompanyName(e.target.value)}
									InputProps={{
										endAdornment: companyName && (
											<InputAdornment position="end">
												<IconButton onClick={() => setCompanyName(initFilters.company_name)}>
													<CloseIcon />
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
							</div>
						</>
					)}
				</div>
				{/* Action buttons */}
				<div className="bookings-advanced-filters__actions">
					<Button
						className="bookings-advanced-filters__action-button_clear"
						onClick={clearFilters}
						disableRipple
					>
						Clear All
					</Button>
					<Button className="bookings-advanced-filters__action-button" onClick={applyFilters} disableRipple>
						Apply
					</Button>
				</div>
			</Paper>
		</Popover>
	);
};

export default AdvancedFiltersPopover;
