const CONSTANTS = module.exports = {};

CONSTANTS.TRANSACTION_TYPES = {
    DEPOSIT: 'deposit',
    WITHDRAW: 'withdraw'
};

// TODO: revise reasons naming
// also, consider manual "inner" reason (e.g. compensation, manual refund, manual deduction, manual gift, etc.)
CONSTANTS.ARBITRIP_WALLET_TRANSACTION_TYPE = {

    REFUND: 'refund', // DEPOSIT

    // points usage
    USAGE: 'usage', // WITHDRAW - amount should be negative (!!)

    // automatic points accumulated
    EARN: 'earn', // DEPOSIT

    NEW_WALLET_GIFT: 'new_wallet_gift', // DEPOSIT

    // manual actions (will be more explicit along with custom message)
    MANUAL_DEPOSIT: 'manual_deposit', // DEPOSIT
    MANUAL_WITHDRAW: 'manual_withdraw', // WITHDRAW - amount should be negative (!!)

    RETROACTIVE_POINTS_USAGE_ADDITION: 'retroactive_points_usage_addition', // DEPOSIT
    RETROACTIVE_POINTS_USAGE_SUBTRACTION: 'retroactive_points_usage_subtraction' // WITHDRAW - amount should be negative (!!)
};

CONSTANTS.CREDIT_ACTION_TYPE = {
    CHARGE: 'CHARGE',
    REFUND: 'REFUND',
    UPDATE: 'UPDATE',
};

// moved to common/general/constants/index.js (formerly common/generalconstants/payment.js)
// CONSTANTS.PAYMENT_TYPE = 
//     NA: 'not_exists',
//     COMPANY_POLICY: 'company_policy',
//     CREDIT_CARD: 'credit_card', // credit card used by organically in our system
//     CREDIT_CARD_DIRECT: 'credit_card_direct', // credit card used as an external system payment
//     PAY_AT_HOTEL: 'pay_at_hotel'
//     // BARTER : 'barter' // inside joke :)
//     // Other: payments types: possible recurring payment, cash, ...
// };

CONSTANTS.CREDIT_CLEARANCE = {
    CREDITGUARD: 'creditguard',
    YAAD: 'yaad',
    YAAD_3DS: 'yaad-3ds',
    SAFECHARGE: 'safecharge',
    GCASH: 'gcash',
    HT_ZONE: 'ht_zone',
    EXPEDIA: 'expedia', // When expedia is merchant of record
};

CONSTANTS.LOYALTY_GROUP = {
    NONE: 'none',
    POALIM_CARD: 'poalimcard'
};

CONSTANTS.CREDIT_CLEARANCE_DEFAULT = CONSTANTS.CREDIT_CLEARANCE.SAFECHARGE; // should be one from CREDIT_CLEARANCE

CONSTANTS.SAFECHARGE_SAVE_CREDIT_CARD_OPTIONS = {
    AUTO: 'auto', //  the payment page checks the SafeCharge database to determine if the customer has registered before.
    REGISTER: 'register', //the payment page requires the customer to register even if they have already registered in the system. The previously registered payment methods are deleted.
    READONLY: 'readonly', // the customer can only select payment methods they have previously used.
    REGISTERONLY: 'registeronly', //  the customer is required to register (by entering a new payment option even if they are already registered in the system. No other previously saved payment options will be deleted.
    READONLYUPO: 'readonlyupo' //  the customer is presented with a single payment option previously saved and specified on the Cashier page userPaymentOptionId parameter. If payment option parameters need to be completed, it will be editable on the Cashier page
};

// moved to common/general/constants/index.js (formerly common/generalconstants/payment.js)
// CONSTANTS.PAYMENT_STATUS = { // if you update this, update the model ...
//     NA: 'not_exists',
//     INITIALIZED: 'initialized',
//     J5: 'J5', // we managed to secure credit
//     POSTPONED: 'postponed', // an iska dehuya
//     PENDING_MANUAL_BILLING: 'pending_manual_billing', // postponed deals that will be billed manually
//     BILLED: 'billed', // represents a payment we got (credit card we actually billed), company policy & pay at hotel
//     CANCELLED: 'cancelled',
//     ERROR: 'error' // an irrecoverable error occurred
// };

// moved to common/general/constants/index.js (formerly common/generalconstants/payment.js)
// CONSTANTS.PAYMENT_CREDIT_CARD_ERROR_CODES = {
//     NA: 'not_exists',
//     NO_RESERVATION: 'missing_reservation',
//     RESERVATION_NOT_APPROVED: 'reservation_not_approved',
//     NO_PAYMENT_METHOD: 'missing_payment_method',
//     MISSING_DATA: 'missing_data',
//     COMMUNICATION_ERROR: 'communication_error',
//     CREDIT_CARD_NOT_ACCEPTED_ERROR: 'credit_card_not_accepted_error',
//     POLL_LATER_SUPPLIER_ERROR: 'poll_late_supplier_error',
//     CREDIT_CARD_NOT_SUPPORTED_LOYALTY_CARD: 'credit_card_not_supported_loyalty_card'
// };

CONSTANTS.PAYMENT_METHOD_STATUS = { // if you update this, update the model ...
    NA: 'not_exists',
    INITIALIZED: 'initialized',
    GET_TOKEN_WORKED: 'get_token_worked',
    ERROR: 'error' // an irrecoverable error occurred
};

CONSTANTS.PRICING_REMARKS = {
    REGULAR_PRICING: 'regular', // no remarks
    EXPEDIA_MARKETING_PRICE_HIGHER: 'expedia_marketing_price_to_high', // expdia+marketing_price was higher than us
    EXPEDIA_MARKETING_PRICE_TRUNCATED_US: 'expedia_marketing_price_truncated_us', // expdia+marketing_price was lower than us
    CERTIFICATION_FIXATION: 'certification_fixation', // when we want to display what we got
    DYNAMIC_MARGIN: 'dynamic_margin',
};

CONSTANTS.CURRENCIES = {
    NA: 'unknown',
    USD: 'USD',
    EUR: 'EUR',
    NIS: 'NIS',
    ILS: 'ILS',
    GBP: 'GBP',
    PHP: 'PHP'
    // don't add other currencies, as yaad pay currently only supports these
    // AUD: 'AUD',
    // CAD: 'CAD'
};

CONSTANTS.ARBITRIP_CURRENCIES = [
    { currency: 'AED', display: 'United Arab Emirates, Dirhams‏' },
    { currency: 'ARS', display: 'Argentine Peso‏' },
    { currency: 'AUD', display: 'Australian Dollars‏' },
    { currency: 'BRL', display: 'Brazilian Real (R$)‏' },
    { currency: 'CAD', display: 'Canadian Dollars‏' },
    { currency: 'CHF', display: 'Swiss Francs‏' },
    { currency: 'CNY', display: 'China, Yuan Renminbi‏' },
    { currency: 'COP', display: 'Colombia, Pesos‏' },
    { currency: 'DKK', display: 'Danish Kroner‏' },
    { currency: 'EGP', display: 'Egypt, Pounds‏' },
    { currency: 'EUR', display: 'Euro‏', main: true, order: 2, symbol: '€' },
    { currency: 'GBP', display: 'British Pounds‏', main: true, order: 3, symbol: '£' },
    { currency: 'HKD', display: 'Hong Kong Dollars‏' },
    { currency: 'IDR', display: 'Indonesia, Rupiahs‏' },
    { currency: 'ILS', display: 'Israeli New Shekels‏', main: true, order: 4, symbol: '₪' },
    { currency: 'INR', display: 'India, Rupees‏' },
    { currency: 'JPY', display: 'Japanese Yen‏' },
    { currency: 'KRW', display: 'Korea (South), Won‏' },
    { currency: 'LBP', display: 'Lebanon, Pounds‏' },
    { currency: 'MAD', display: 'Morocco, Dirhams‏' },
    { currency: 'MXN', display: 'Mexico, Pesos‏' },
    { currency: 'MYR', display: 'Malaysia, Ringgits‏' },
    { currency: 'NOK', display: 'Norwegian Kroner‏' },
    { currency: 'NZD', display: 'New Zealand Dollars‏' },
    { currency: 'PHP', display: 'Philippines, Pesos‏' },
    { currency: 'PLN', display: 'Poland, Zlotych‏' },
    { currency: 'QAR', display: 'Qatar, Rials‏' },
    { currency: 'RUB', display: 'Russia, Rubles‏' },
    { currency: 'SAR', display: 'Saudi Arabia, Riyals‏' },
    { currency: 'SEK', display: 'Swedish Krona‏' },
    { currency: 'SGD', display: 'Singapore Dollars‏' },
    { currency: 'THB', display: 'Thailand, Baht‏' },
    { currency: 'TRY', display: 'Turkish New Lira‏' },
    { currency: 'TWD', display: 'Taiwan Dollars‏' },
    { currency: 'USD', display: 'US Dollar‏', main: true, order: 1, symbol: '$' },
    { currency: 'VND', display: 'Viet Nam, Dong‏' },
    { currency: 'ZAR', display: 'South African Rand‏' },
];

CONSTANTS.EXPEDIA_CACHIER_CURRENCIES = CONSTANTS.ARBITRIP_CURRENCIES.reduce((obj, data) => {
    obj[data.currency] = data.currency;
    return obj;
}, {});

CONSTANTS.PAY_LATER_CURRENCIES = [
    CONSTANTS.CURRENCIES.USD,
    CONSTANTS.CURRENCIES.EUR,
    CONSTANTS.CURRENCIES.ILS,
    CONSTANTS.CURRENCIES.GBP,
];
