import React from 'react';
import PropTypes from 'prop-types';

class HotelDealsBookmark extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			container_style: {
				position: 'absolute',
				right: 0,
				bottom: 4,
			},
			style: {
				background: '#ccc',
				padding: '1px 6px 2px 4px',
				borderRadius: '4px 0 0 4px',
				fontSize: 12.5,
			},
		};
	}

	render() {
		const { hotel } = this.props;
		const { container_style, style } = this.state;

		const cnt = Array.isArray(hotel.deals) ? hotel.deals.length : '-';

		return (
			<div style={container_style}>
				<span style={style}>{cnt}</span>
			</div>
		);
	}
}

HotelDealsBookmark.propTypes = {
	hotel: PropTypes.object.isRequired,
};

export default HotelDealsBookmark;
