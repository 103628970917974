import AppDispatcher from '../dispatcher/AppDispatcher';
import { EventEmitter } from 'events';
import FiltersConstants from '../constants/FiltersConstants';
import _ from 'lodash';

// Define initial data points
//let _filters = {};
let _mapBoxData = {};
let _priceFilterData = {};
let _businessTravelersFilterData = {};
let _hotelClassFilterData = {};
let _reviewScoreFilterData = {};

let _starsFilterData = {};
let _arbitripScoreFilterData = {};
let _amenitiesFilterData = {};
let _debugFilterData = {};

let _dealsBreakfastOnlyFilterData = false;
let _dealsPostPayOnlyFilterData = false;
let _dealsNegotiatedRateOnlyFilterData = false;
let _dealsInPolicyOnlyFilterData = false;
let _dealsFreeCancelOnlyFilterData = false;
let _dealsHalfBoardOnlyFilterData = false;
let _dealsAllInclusiveOnlyFilterData = false;

//// Method to load result data from mock API
//function loadFiltersData(data) {
//    _filters = data;
//}

function loadMapBoxData(data) {
	_mapBoxData = data;
}

function loadPriceFilterData(data) {
	_priceFilterData = data;
}

function loadBusinessTravelersFilterData(data) {
	_businessTravelersFilterData = data;
}

function loadHotelClassFilterData(data) {
	_hotelClassFilterData = data;
}

function loadReviewScoreFilterData(data) {
	_reviewScoreFilterData = data;
}

function loadStarsFilterData(data) {
	_starsFilterData = data;
}

function loadArbitripScoreFilterData(data) {
	_arbitripScoreFilterData = data;
}

function loadAmenitiesFilterData(data) {
	_amenitiesFilterData = data;
}

function loadDebugFilterData(data) {
	_debugFilterData = data;
}

function resetDealsFilters() {
	_dealsBreakfastOnlyFilterData =
		_dealsInPolicyOnlyFilterData =
		_dealsFreeCancelOnlyFilterData =
		_dealsPostPayOnlyFilterData =
		_dealsNegotiatedRateOnlyFilterData =
		_dealsHalfBoardOnlyFilterData =
		_dealsAllInclusiveOnlyFilterData =
			false;
}

function loadDealsBreakfastOnlyFilterData(data) {
	_dealsBreakfastOnlyFilterData = data;
}

function loadDealsPostPayOnlyFilterData(data) {
	_dealsPostPayOnlyFilterData = data;
}

function loadDealsNegotiatedRateOnlyFilterData(data) {
	_dealsNegotiatedRateOnlyFilterData = data;
}

function loadDealsInPolicyOnlyFilterData(data) {
	_dealsInPolicyOnlyFilterData = data;
}

function loadDealsFreeCancelOnlyFilterData(data) {
	_dealsFreeCancelOnlyFilterData = data;
}

function loadDealsHalfBoardOnlyFilterData(data) {
	_dealsHalfBoardOnlyFilterData = data;
}

function loadDealsAllInclusiveOnlyFilterData(data) {
	_dealsAllInclusiveOnlyFilterData = data;
}

// Extend Search Store with EventEmitter to add eventing capabilities
var FiltersStore = _.extend({}, EventEmitter.prototype, {
	// Return search results
	getMapBoxData: function () {
		return _mapBoxData;
	},

	getPriceFilterData: function () {
		return _priceFilterData;
	},

	getBusinessTravelersFilterData() {
		return _businessTravelersFilterData;
	},
	getHotelClassFilterData() {
		return _hotelClassFilterData;
	},
	getReviewScoreFilterData() {
		return _reviewScoreFilterData;
	},
	getStarsFilterData() {
		return _starsFilterData;
	},
	getArbitripScoreFilterData() {
		return _arbitripScoreFilterData;
	},
	getAmenitiesFilterData() {
		return _amenitiesFilterData;
	},
	getDealsBreakfastFilterData() {
		return _dealsBreakfastOnlyFilterData;
	},
	getDealsPostPayFilterData() {
		return _dealsPostPayOnlyFilterData;
	},
	getDealsNegotiatedRateFilterData() {
		return _dealsNegotiatedRateOnlyFilterData;
	},
	getDealsInPolicyFilterData() {
		return _dealsInPolicyOnlyFilterData;
	},
	getDealsFreeCancelFilterData() {
		return _dealsFreeCancelOnlyFilterData;
	},
	getDealsFilterData() {
		return {
			breakfast_only: _dealsBreakfastOnlyFilterData,
			post_pay_only: _dealsPostPayOnlyFilterData,
			negotiated_rate_only: _dealsNegotiatedRateOnlyFilterData,
			in_policy_only: _dealsInPolicyOnlyFilterData,
			free_cancellation_only: _dealsFreeCancelOnlyFilterData,
			half_board_only: _dealsHalfBoardOnlyFilterData,
			all_inclusive_only: _dealsAllInclusiveOnlyFilterData,
		};
	},
	getDebugFilterData() {
		return _debugFilterData;
	},
	// TODO: method for currency conversion

	// Emit Change event
	emitChange: function () {
		this.emit('change');
	},

	// Add change listener
	addChangeListener: function (callback) {
		this.on('change', callback);
	},

	// Remove change listener
	removeChangeListener: function (callback) {
		this.removeListener('change', callback);
	},
});

// Register callback with AppDispatcher
AppDispatcher.register(function (payload) {
	let action = payload.action;
	switch (action.actionType) {
		// Respond to RECEIVE_DATA action
		case FiltersConstants.RECEIVE_MAP_BOX_DATA:
			loadMapBoxData(action.data);
			break;

		case FiltersConstants.RECEIVE_PRICE_FILTER_DATA:
			loadPriceFilterData(action.data);
			break;

		case FiltersConstants.RECEIVE_BUSINESS_TRAVELERS_DATA:
			loadBusinessTravelersFilterData(action.data);
			break;

		case FiltersConstants.RECEIVE_HOTEL_CLASS_DATA:
			loadHotelClassFilterData(action.data);
			break;

		case FiltersConstants.RECEIVE_REVIEW_SCORE_DATA:
			loadReviewScoreFilterData(action.data);
			break;

		case FiltersConstants.RECEIVE_STARS_FILTER_DATA:
			loadStarsFilterData(action.data);
			break;

		case FiltersConstants.RECEIVE_ARBITRIP_SCORE_FILTER_DATA:
			loadArbitripScoreFilterData(action.data);
			break;

		case FiltersConstants.RECEIVE_AMENITIES_FILTER_DATA:
			loadAmenitiesFilterData(action.data);
			break;
		case FiltersConstants.RECEIVE_DEBUG_FILTER_DATA:
			loadDebugFilterData(action.data);
			break;
		case FiltersConstants.RESET_HOTEL_DEALS_FILTERS:
			resetDealsFilters();
			break;
		case FiltersConstants.RECEIVE_DEALS_BREAKFAST_ONLY_FILTER_DATA:
			loadDealsBreakfastOnlyFilterData(action.data);
			break;
		case FiltersConstants.RECEIVE_DEALS_HALF_BOARD_ONLY_FILTER_DATA:
			loadDealsHalfBoardOnlyFilterData(action.data);
			break;
		case FiltersConstants.RECEIVE_DEALS_ALL_INCLUSIVE_ONLY_FILTER_DATA:
			loadDealsAllInclusiveOnlyFilterData(action.data);
			break;
		case FiltersConstants.RECEIVE_DEALS_POST_PAY_FILTER_DATA:
			loadDealsPostPayOnlyFilterData(action.data);
			break;
		case FiltersConstants.RECEIVE_DEALS_NEGOTIATED_RATE_FILTER_DATA:
			loadDealsNegotiatedRateOnlyFilterData(action.data);
			break;
		case FiltersConstants.RECEIVE_DEALS_FREE_CANCEL_ONLY_FILTER_DATA:
			loadDealsFreeCancelOnlyFilterData(action.data);
			break;
		case FiltersConstants.RECEIVE_DEALS_IN_POLICY_ONLY_FILTER_DATA:
			loadDealsInPolicyOnlyFilterData(action.data);
			break;
		default:
			return true;
	}

	// If action was responded to, emit change event
	FiltersStore.emitChange();

	return true;
});

export default FiltersStore;
