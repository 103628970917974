import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import RoomMappingService from 'arbitrip-common/client/utils/RoomMappingService';
import * as Sentry from '@sentry/react';

const STATUSES = {
	DEFAULT: 'Default Vocabulary',
	MODIFIED: 'Modified Vocabulary',
};

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class LoadMapping extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			searchSession: this.props.searchSession,
			open: false,
			showError: false,
			value: JSON.stringify(_.get(this.props.searchSession, 'room_mapping.vocabulary'), null, '\t'),
			loadedStatus: this.getVocabularyStatus(
				JSON.stringify(_.get(this.props.searchSession, 'room_mapping.vocabulary'), null, '\t'),
			),
			localStatus: this.getVocabularyStatus(
				JSON.stringify(_.get(this.props.searchSession, 'room_mapping.vocabulary'), null, '\t'),
			),
		};

		this.handleOpen = this.handleOpen.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.getVocabulary = this.getVocabulary.bind(this);
		this.getOriginalVocabulary = this.getOriginalVocabulary.bind(this);
		this.refreshMapping = this.refreshMapping.bind(this);
		this.handleCloseError = this.handleCloseError.bind(this);
		this.resetVocabulary = this.resetVocabulary.bind(this);
		this.setValue = this.setValue.bind(this);
		this.setLocalVocabularyStatus = this.setLocalVocabularyStatus.bind(this);
		this.getVocabularyStatus = this.getVocabularyStatus.bind(this);
		this.getLocalVocabularyStatusText = this.getLocalVocabularyStatusText.bind(this);
		this.getLoadedVocabularyStatusText = this.getLoadedVocabularyStatusText.bind(this);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevState.localStatus !== this.state.localStatus) {
			this.setLocalVocabularyStatus();
		}
	}

	getVocabulary() {
		const { searchSession } = this.state;

		return _.get(searchSession, 'room_mapping.vocabulary');
	}

	getOriginalVocabulary() {
		if (!this.state) {
			return;
		}

		const { searchSession } = this.state;

		return _.get(searchSession, 'room_mapping.original_vocabulary');
	}

	setLocalVocabularyStatus() {
		this.setState({ localStatus: this.getVocabularyStatus(this.state.value) });
	}

	getVocabularyStatus(value) {
		if (!value) {
			return;
		}

		const originalVocabulary = this.getOriginalVocabulary();

		if (!_.isObject(value)) {
			try {
				value = JSON.parse(value);
			} catch (e) {
				value = {};
				console.error(e);
				Sentry.captureException(e);
			}
		}

		return _.isEqual(originalVocabulary, value) ? STATUSES.DEFAULT : STATUSES.MODIFIED;
	}

	handleOpen() {
		this.setState({ open: true });
	}

	handleClose() {
		this.setState({ open: false });
	}

	handleChange(event) {
		this.setState({ value: event.target.value });

		setTimeout(() => {
			this.setLocalVocabularyStatus();
		});
	}

	handleCloseError() {
		this.setLocalVocabularyStatus();
		this.setState({ showError: false });
	}

	refreshMapping() {
		const { hotelId } = this.props;
		const { searchSession, value } = this.state;

		try {
			const newValue = JSON.parse(value);
			searchSession.room_mapping.setVocabulary(newValue);

			RoomMappingService.run(searchSession, hotelId);

			this.handleClose();
		} catch (e) {
			console.error(e);
			Sentry.captureException(e);
			this.setState({ showError: true });
		}
	}

	resetVocabulary() {
		const { searchSession } = this.state;
		searchSession.room_mapping.resetVocabulary();
		const originalVocabulary = this.getOriginalVocabulary();

		let value = JSON.stringify(originalVocabulary, null, '\t');
		this.setState({ value });

		setTimeout(() => {
			this.setLocalVocabularyStatus();
		});
	}

	setValue() {
		const vocabulary = this.getVocabulary();
		let value = JSON.stringify(vocabulary, null, '\t');
		this.setState({ value });

		setTimeout(() => {
			this.setLocalVocabularyStatus();
		});
	}

	getLocalVocabularyStatusText() {
		const { value } = this.state;
		return (
			<div style={{ color: this.getVocabularyStatus(value) === STATUSES.DEFAULT ? 'gray' : 'green' }}>
				{this.getVocabularyStatus(value)}
			</div>
		);
	}

	getLoadedVocabularyStatusText() {
		const vocabulary = this.getVocabulary();
		const loadedVocabularyStatus = this.getVocabularyStatus(vocabulary);

		return (
			<div style={{ color: loadedVocabularyStatus === STATUSES.DEFAULT ? 'gray' : 'green' }}>
				{loadedVocabularyStatus}
			</div>
		);
	}

	render() {
		const { open, value, showError } = this.state;

		return (
			<div className="mapping-button">
				<Button color="primary" variant="outlined" fullWidth onClick={this.handleOpen}>
					Open Vocabulary Mapping Object ({this.getLoadedVocabularyStatusText()})
				</Button>

				<Dialog
					open={open}
					onClose={this.handleClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
					maxWidth="xl"
					fullWidth
				>
					<DialogTitle>
						Change Mapping Vocabulary Object
						<Button
							onClick={this.resetVocabulary}
							color="primary"
							variant="outlined"
							style={{ float: 'right' }}
						>
							Reset Vocabulary
						</Button>
					</DialogTitle>
					<DialogContent>
						<div
							style={{
								width: '100%',
								height: 30,
								textAlign: 'center',
							}}
						>
							{this.getLocalVocabularyStatusText()}
						</div>
						<DialogContentText>
							<textarea rows="30" style={{ width: '100%' }} value={value} onChange={this.handleChange} />
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClose} color="primary" variant="outlined">
							Cancel
						</Button>
						<Button onClick={this.refreshMapping} color="primary" variant="contained" autoFocus>
							Test
						</Button>
					</DialogActions>
				</Dialog>

				<Snackbar
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					open={showError}
					autoHideDuration={5000}
					onClose={this.handleCloseError}
				>
					<Alert onClose={this.handleCloseError} severity="error">
						JSON data is not valid!
					</Alert>
				</Snackbar>
			</div>
		);
	}
}

LoadMapping.propTypes = {
	searchSession: PropTypes.object,
	refreshMapping: PropTypes.func,
	hotelId: PropTypes.string,
};

export default LoadMapping;
