import { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { InputAdornment } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import ManagementActions from '@/actions/ManagementActions';
import GooglePlacesAutocomplete from '@/components/general/google-places-autocomplete/GooglePlacesAutocomplete';
import ManagementConstants from '@/constants/ManagementConstants';
import { ReactComponent as Calendar } from '@/img/icons/calendar.svg';
import { getPlace } from '@/components/general/google-places-autocomplete/utils';

const min_date = moment();
const max_date = moment().add(2, 'years');

const AddDestination = ({ destination, single }) => {
	const [hovering, setHovering] = useState(false);
	const [startOpen, setStartOpen] = useState(false);
	const [endOpen, setEndOpen] = useState(false);

	const { destination: location, start_date, end_date } = destination;

	const handleAutocompleteSelect = async (googleLocation) => {
		const place = await getPlace({ ...googleLocation, language: 'en' });
		location.address = googleLocation.address;
		if (place) {
			location.place = place;
		}
		ManagementActions.updateWizardDestinationLocation(destination, location);
	};

	const handleStartDateOnChange = (date) => {
		ManagementActions.updateWizardDestinationStartDate(destination, date);
		if (end_date && date.isSameOrAfter(moment(end_date), 'day')) {
			ManagementActions.updateWizardDestinationEndDate(destination, date.add(1, 'day'));
		}
	};

	const handleEndDateOnChange = (date) => {
		ManagementActions.updateWizardDestinationEndDate(destination, date);
	};

	const handleAddDestinationOnMouseEnter = () => setHovering(true);

	const handleAddDestinationOnMouseLeave = () => setHovering(false);

	const handleAddDestinationRemoveOnClick = () => {
		ManagementActions.removeWizardDestination(destination);
	};

	let multipleProps = single
		? {}
		: {
				onMouseEnter: handleAddDestinationOnMouseEnter,
				onMouseLeave: handleAddDestinationOnMouseLeave,
			};
	let addDestinationClasses = `add-destination${hovering ? ' hovering' : ''}`;

	let endDateMinDate = start_date || min_date;

	return (
		<div className={addDestinationClasses} {...multipleProps}>
			<div className="destination-container flex align-flex-end">
				<div className="field destination">
					<label>Destination</label>
					<GooglePlacesAutocomplete
						onAutocompleteSelect={handleAutocompleteSelect}
						placeholder="Add destination"
						searchText={location && location.address}
					/>
				</div>
				<div className="field date start">
					<LocalizationProvider
						dateAdapter={AdapterMoment}
						adapterLocale={ManagementConstants.ADAPTER_MOMENT_LOCALE}
					>
						<DesktopDatePicker
							format={ManagementConstants.DATE_PICKER_FORMAT}
							open={startOpen}
							onClose={() => setStartOpen(false)}
							label="Start date"
							value={start_date}
							onChange={handleStartDateOnChange}
							fullWidth
							slotProps={{
								textField: {
									InputProps: {
										startAdornment: (
											<InputAdornment position="start">
												<Calendar />
											</InputAdornment>
										),
										placeholder: 'Start',
										onClick: (e) => {
											e.preventDefault();
											setStartOpen(true);
										},
									},
								},
							}}
							minDate={min_date}
							maxDate={max_date}
						/>
					</LocalizationProvider>
				</div>
				<div className="field date end">
					<LocalizationProvider
						dateAdapter={AdapterMoment}
						adapterLocale={ManagementConstants.ADAPTER_MOMENT_LOCALE}
					>
						<DesktopDatePicker
							format={ManagementConstants.DATE_PICKER_FORMAT}
							open={endOpen}
							onClose={() => setEndOpen(false)}
							label="End date"
							value={end_date}
							onChange={handleEndDateOnChange}
							fullWidth
							slotProps={{
								textField: {
									InputProps: {
										startAdornment: (
											<InputAdornment position="end">
												<Calendar />
											</InputAdornment>
										),
										placeholder: 'End',
										onClick: (e) => {
											e.preventDefault();
											setEndOpen(true);
										},
									},
								},
							}}
							minDate={endDateMinDate}
							maxDate={max_date}
						/>
					</LocalizationProvider>
				</div>
				{hovering && <button className="remove-button" onClick={handleAddDestinationRemoveOnClick} />}
			</div>
		</div>
	);
};

AddDestination.propTypes = {
	destination: PropTypes.object.isRequired,
	single: PropTypes.bool,
};

export default AddDestination;
