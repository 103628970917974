import _ from 'lodash';
import queryString from 'query-string';
import moment from 'moment';

import UrlParams from 'arbitrip-common/general/utils/UrlParams';
import { getPointOfInterest } from 'arbitrip-common/client/utils/PlacesWrapper';

import ProfileStore from '../stores/ProfileStore';

import Validator from 'arbitrip-common/client/utils/Validator';
import Base64 from 'arbitrip-common/general/utils/Base64';

import LogActions from '../actions/LogActions';

import { parseTasIdFromUrl } from '@/utils/TasId';

import SEARCH_CONSTANTS from 'arbitrip-common/general/constants/search-constants';

import * as Sentry from '@sentry/react';

const STATUSES = {
	INITIAL: 'initial',
	BUSY: 'busy',
	SUCCESS: 'success',
	FAIL: 'fail',
};
let _status = STATUSES.INITIAL;

function validate(search_params) {
	if (_.isEmpty(search_params)) {
		return true;
	}

	if (!search_params) {
		console.error('SearchTermsParser.validate(): no search_params supplied');
		return false;
	}

	// let {address, place_id, check_in, check_out, rooms, guests} = search_params;

	// if (!address) {
	//   console.error('SearchTermsParser.validate(): no address supplied');
	//   return false;
	// }

	const {
		check_in,
		check_out,
		rooms,
		guests,
		destination,
		children_ages,
		// private_travel
	} = search_params;

	if (!_.get(destination, 'place.place_id') && !_.get(destination, 'place.hotel_id')) {
		console.error('SearchTermsParser.validate(): no place_id nor hotel_id supplied', search_params);
		return false;
	}

	if (!validateDates(check_in, check_out)) {
		console.error('SearchTermsParser.validate(): invalid check-in/check-out', check_in, check_out);
		return false;
	}

	// TODO: consider integer (non float) parsing?
	if (!validateRooms(rooms)) {
		console.error('SearchTermsParser.validate(): invalid number-of-rooms', rooms);
		return false;
	}

	// TODO: consider integer (non float) parsing?
	if (!validateGuests(guests)) {
		console.error('SearchTermsParser.validate(): invalid number-of-guests', guests);
		return false;
	}

	if (children_ages && !validateChildrenAges(children_ages)) {
		console.error('SearchTermsParser.validate(): invalid children ages', children_ages);
		return false;
	}

	return true;
}

function validateChildrenAges(children_ages) {
	// if no children data
	// no need to validate
	if (!children_ages) {
		return false;
	}

	let children_ages_array = [];
	if (_.isArray(children_ages)) {
		children_ages_array = children_ages;
	} else if (_.isString(children_ages)) {
		children_ages_array = children_ages.split(',');
	} else {
		return false;
	}

	if (children_ages_array.length > SEARCH_CONSTANTS.MAX.CHILDREN) {
		return false;
	}

	return children_ages_array.every(Validator.validateAge);
}

function validateGuests(guests) {
	let number_of_guests = parseInt(guests);

	const profile = ProfileStore.getProfile() || {};
	const maxAdults = profile?.agent ? SEARCH_CONSTANTS.MAX.ADULTS_IF_AGENT : SEARCH_CONSTANTS.MAX.ADULTS;

	return number_of_guests > 0 && number_of_guests <= maxAdults;
}

function validateRooms(rooms) {
	let number_of_rooms = parseInt(rooms);
	return number_of_rooms > 0 && number_of_rooms <= SEARCH_CONSTANTS.MAX.ROOMS;
}

function validateDates(check_in, check_out) {
	let c_in = moment.utc(check_in, UrlParams.DATE_FORMAT);
	let c_out = moment.utc(check_out, UrlParams.DATE_FORMAT);
	let now = moment.utc();

	return (
		c_in.isValid() &&
		c_out.isValid() &&
		c_in.isSameOrAfter(now, 'day') &&
		c_in.isSameOrBefore(now.add(SEARCH_CONSTANTS.MAX_CHECK_IN), 'day') &&
		c_out.isAfter(c_in, 'day') &&
		c_out.isSameOrBefore(c_in.add(SEARCH_CONSTANTS.MAX_DURATION), 'day')
	);
}

function parseValidSearchTermsFromURL(callback) {
	try {
		_status = STATUSES.BUSY;
		let search_params = queryString.parse(window.location.search);
		if (search_params) {
			let search_terms = {};

			let {
				place_id,
				hotel_id,
				check_in,
				check_out,
				rooms,
				guests,
				children_ages,
				contract_id,
				search_token,
				management_trip_id,
				management_trip_name,
				travelers,
				quote_id,
				group_id,
				private_travel,
				derived_from,
				combtas_id,
				proposal_id,
				travel_booster_docket_number,
				tas_id,
			} = search_params;

			let c_in = moment.utc(check_in, UrlParams.DATE_FORMAT);
			let c_out = moment.utc(check_out, UrlParams.DATE_FORMAT);
			let now = moment.utc();

			if (
				c_in.isValid() &&
				c_in.isSameOrAfter(now, 'day') &&
				c_in.isSameOrBefore(now.add(SEARCH_CONSTANTS.MAX_CHECK_IN), 'day')
			) {
				search_terms.check_in = c_in.toDate();
				if (
					c_out.isValid() &&
					c_out.isAfter(c_in, 'day') &&
					c_out.isSameOrBefore(c_in.add(SEARCH_CONSTANTS.MAX_DURATION), 'day')
				) {
					search_terms.check_out = c_out.toDate();
				}
			} else if (
				c_out.isValid() &&
				c_out.isAfter(now, 'day') &&
				c_out.isSameOrBefore(now.add(SEARCH_CONSTANTS.MAX_DURATION), 'day')
			) {
				search_terms.check_out = c_out.toDate();
			}

			// TODO: consider integer (non float) parsing?
			let number_of_rooms = parseInt(rooms);
			if (validateRooms(number_of_rooms)) {
				search_terms.rooms = number_of_rooms;
			}

			// TODO: consider integer (non float) parsing?
			let number_of_guests = parseInt(guests);
			if (validateGuests(number_of_guests)) {
				search_terms.guests = number_of_guests;
			}

			if (validateChildrenAges(children_ages)) {
				search_terms.children_ages = children_ages ? children_ages.split(',').map(Number) : [];
			}

			if (
				private_travel === true ||
				(typeof private_travel === 'string' && private_travel.trim().toLowerCase() === true.toString())
			) {
				search_terms.private_travel = true;
			}

			// TODO: find out if it's an ObjectId...
			if (Validator.validateObjectId(contract_id)) {
				search_terms.contract_id = contract_id;
			}

			if (Validator.validateObjectId(group_id)) {
				search_terms.group_id = group_id;
			}

			if (Validator.validateObjectId(quote_id)) {
				search_terms.quote_id = quote_id;
			}

			if (Validator.validateObjectId(management_trip_id)) {
				search_terms.management_trip_id = management_trip_id;
				search_terms.management_trip_name = management_trip_name;
			}

			if (Validator.validateObjectId(derived_from)) {
				search_terms.derived_from = derived_from;
			}

			if (travelers) {
				try {
					travelers = JSON.parse(travelers);
					search_terms.travelers = travelers;
				} catch (ex) {
					console.error(ex);
					Sentry.captureException(ex);
				}
			}

			if (Validator.validateCombtasId(combtas_id)) {
				search_terms.combtas_id = combtas_id;

				if (proposal_id) {
					search_terms.proposal_id = proposal_id;
				}
			}

			if (tas_id) {
				tas_id = parseTasIdFromUrl(tas_id);
				if (Validator.validateCombtasId(tas_id)) {
					search_terms.tas_id = tas_id;
				}
			}

			if (travel_booster_docket_number) {
				const profile = ProfileStore.getProfile();
				if (Validator.validateTravelBoosterDocketNumber(travel_booster_docket_number, profile)) {
					search_terms.travel_booster_docket_number = travel_booster_docket_number;
				}
			}

			if (ProfileStore.isUseUserCurrency()) {
				search_terms.user_currency = ProfileStore.getUserCurrency();
				search_params.user_currency = ProfileStore.getUserCurrency();
			}

			if (place_id || hotel_id) {
				getPointOfInterest({ hotel_id, place_id })
					.then((place) => {
						if (!place) {
							console.warn('failed to get place object');
							return callback(search_terms);
						}

						if (place.hotel_id) {
							search_terms.hotel_id = place.hotel_id;
						} else if (place.place_id) {
							search_terms.place_id = place.place_id;
						}

						search_terms.address = place.address;

						if (_isValidToken(search_terms, search_token, search_params)) {
							search_terms.search_token = search_token;
						}

						if (hotel_id) {
							place.category = 'hotel';
						}

						return callback(
							Object.assign(search_terms, {
								destination: {
									address: place.address,
									place,
								},
							}),
						);
					})
					.catch((err) => {
						console.error('failed to get place object', err);
						Sentry.captureException(err);
						return callback(search_terms);
					});
			} else {
				return callback(search_terms);
			}
		} else {
			return callback({});
		}
	} catch (ex) {
		let msg = 'error while trying to parse url for valid search terms...';
		console.error(msg, ex);
		LogActions.logError({
			ex,
			context: { message: msg, search: window.location.search },
		});
		Sentry.captureException(ex);
		return callback({});
	}
}

function _isValidToken(search_terms, search_token, search_params) {
	let { hotel_id, place_id, check_in, check_out, rooms, guests, children_ages, private_travel, user_currency } =
		search_params;

	if (search_token) {
		try {
			let s = [
				hotel_id || place_id,
				check_in,
				check_out,
				guests,
				children_ages,
				!!private_travel,
				rooms,
				user_currency,
			].join('|');
			let params_signed = Base64.encode(s);

			return Validator.validateSearchToken(search_token, params_signed);
		} catch (err) {
			console.error(err);
			return false;
		}
	} else {
		return false;
	}
}

export default {
	validate,
	parseValidSearchTermsFromURL,

	validateRooms,
	validateGuests,
	validateDates,

	isInitial: () => _status === STATUSES.INITIAL,
	isBusy: () => _status === STATUSES.BUSY,
	didSucceed: () => _status === STATUSES.SUCCESS,
	didFail: () => _status === STATUSES.FAIL,
};
