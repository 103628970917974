export default class Destination {
  constructor(latlon, address) {
    this.place = {
      geometry: {
        location: {
          lat: latlon.lat,
          lng: latlon.lon
        }
      }
    };
    this.address = address;
  }
}