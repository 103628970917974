import _ from 'lodash';
import React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

function isActionValid(action) {
	if (!action) {
		window.alert('no action');
		return false;
	}

	if (!_.isFunction(action.onClick)) {
		window.alert('no onClick');
		return false;
	}

	if (!_.isString(action.text)) {
		window.alert('no text');
		return false;
	}

	return action.text.length > 0;
}

function renderDialogActions(actions) {
	if (!Array.isArray(actions)) {
		return null;
	}

	const dialog_actions = actions.filter(isActionValid).map((action, idx) => (
		<Button key={`dabtn-${idx + 1}`} color="primary" onClick={action.onClick}>
			{action.text}
		</Button>
	));

	if (dialog_actions.length > 0) {
		return (
			<DialogActions>
				<React.Fragment>{dialog_actions}</React.Fragment>
			</DialogActions>
		);
	}

	// window.alert('aaa');

	return null;
}

class MaterialDesignDialog extends React.Component {
	render() {
		const {
			open,
			enableBackdropClick,
			onClose,
			closeTimeoutInMilliseconds,
			title,
			content,
			actions,
			onKeyDown,
			minWidth,
			height,
		} = this.props;

		const handleClose = (event, reason) => {
			if (enableBackdropClick || reason !== 'backdropClick') {
				onClose(event, reason);
			}
		};

		const transitionDuration =
			_.isNumber(closeTimeoutInMilliseconds) && closeTimeoutInMilliseconds >= 0
				? { exit: closeTimeoutInMilliseconds }
				: {};

		const paperProps = {
			style: {
				minWidth: minWidth || 360,
				...(height && { height }),
			},
		};

		return (
			<Dialog
				open={open}
				onClose={handleClose}
				{...transitionDuration}
				disableEscapeKeyDown={true}
				style={{ minWidth: minWidth || 480 }}
				PaperProps={paperProps}
				{...(onKeyDown ? { onKeyDown } : {})}
			>
				{title && <DialogTitle>{title}</DialogTitle>}
				{content && <DialogContent>{content}</DialogContent>}
				{renderDialogActions(actions)}
			</Dialog>
		);
	}
}

export default MaterialDesignDialog;
