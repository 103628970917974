import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Button, CircularProgress, TextField } from '@mui/material';
// import { LoadingButton } from '@mui/lab';
// import { Sync as SyncIcon, Add as AddIcon } from '@mui/icons-material';
import BookingsActions from '../../../actions/BookingsActions';
import BookingsConstants from '../../../constants/BookingsConstants';
import BookingsStore from '../../../stores/BookingsStore';
import MaterialDesignDialog from '../../general/MaterialDesignDialog.react';
import Validator from 'arbitrip-common/client/utils/Validator';

const date_format = 'DD MMM YYYY';

const DOCKET_ACTIONS = {
	CREATE: 'created',
	UPDATE: 'updated',
};

function getState() {
	return {
		sync_travel_booster_status: BookingsStore.getSyncTravelBoosterStatus(),
	};
}

function didSyncSucceed(status) {
	return status === BookingsConstants.STATUS.SUCCESS;
}

class TravelBooster extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			travel_booster_docket_number: _.get(props.booking, 'travel_booster_docket_number', ''),
			showNotValidMessage: false,
			canShowFailedMessage: false,
			// show_edit: false,
			// edit_mode: true,
		};

		// this.toggleEditButton = this.toggleEditButton.bind(this);
		// this.showEditButton = this.showEditButton.bind(this);
		// this.hideEditButton = this.hideEditButton.bind(this);

		// this.edit = this.edit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.syncReservation = this.syncReservation.bind(this);
		this._onChange = this._onChange.bind(this);
		this.validateDocketNumber = this.validateDocketNumber.bind(this);

		this.displayName = 'TravelBooster';
	}

	componentDidMount() {
		BookingsStore.addChangeListener(this._onChange);
		console.log('listen');
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			!didSyncSucceed(prevState.sync_travel_booster_status) &&
			didSyncSucceed(this.state.sync_travel_booster_status)
		) {
			const reservation_id = _.get(this.props.booking, 'id');
			this.setState({
				// travel_booster_docket_number: _.get(this.props.booking, 'travel_booster_docket_number', '')
				travel_booster_docket_number: BookingsStore.getTravelBoosterDocketNumberByReservationId(reservation_id),
			});
		}
	}

	componentWillUnmount() {
		BookingsStore.removeChangeListener(this._onChange);
		// TODO: clear sync travel booster status ?
	}

	// toggleEditButton(showEdit) {
	//     this.setState({ show_edit: showEdit });
	// }

	// showEditButton() {
	//     if (!this.state.edit_mode) {
	//         this.toggleEditButton(true);
	//     }
	// }

	// hideEditButton() {
	//     this.toggleEditButton(false);
	// }

	// edit() {
	//     this.setState({
	//         edit_mode: true,
	//         show_edit: false
	//     });
	// }

	handleChange(event) {
		this.setState({ showNotValidMessage: false, canShowFailedMessage: false });

		if (event.target.value) {
			const travel_booster_docket_number = parseInt(event.target.value);
			if (travel_booster_docket_number >= 0 && travel_booster_docket_number <= Number.MAX_SAFE_INTEGER) {
				this.setState({ travel_booster_docket_number });
			}
		} else {
			this.setState({ travel_booster_docket_number: '' });
		}
	}

	validateDocketNumber() {
		this.setState({ showNotValidMessage: false });

		const { travel_booster_docket_number } = this.state;
		if (
			!travel_booster_docket_number ||
			Validator.validateTravelBoosterDocketNumber(travel_booster_docket_number, this.props.profile)
		) {
			this.syncReservation();
		} else {
			this.setState({ showNotValidMessage: true });
		}
	}

	syncReservation() {
		BookingsActions.syncTravelBooster(this.props.booking.id, this.state.travel_booster_docket_number);
		// // this.setState({ status: BookingsConstants.STATUS.BUSY });
		// // const _self = this;
		// // setTimeout(() => {
		// //     _self.setState({ status: 'succeeded' });
		// // }, 3333);
		// this.setState({ edit_mode: false });
		this.setState({
			canShowFailedMessage: true,
			last_action: this.state.travel_booster_docket_number ? DOCKET_ACTIONS.UPDATE : DOCKET_ACTIONS.CREATE,
		});
	}

	render() {
		const {
			booking,
			onClose,
			// syncTravelBoosterStatus
		} = this.props;
		const {
			travel_booster_docket_number,
			sync_travel_booster_status,
			// show_edit,
			// edit_mode,
			last_action,
		} = this.state;

		const check_in = _.get(booking, 'deal.details.check_in');
		const check_out = _.get(booking, 'deal.details.check_out');
		const nights = _.get(booking, 'deal.details.nights');

		const hotel_name = _.get(booking, 'hotel.name');

		const full_address = [
			_.get(booking, 'hotel.address'),
			_.get(booking, 'hotel.city'),
			_.get(booking, 'hotel.state'),
			_.get(booking, 'hotel.country'),
		]
			.filter(_.identity)
			.join(', ');

		const company_name = _.get(booking, 'company.name');

		const room_count = _.get(booking, 'deal.details.room_count');
		const room_description = _.get(booking, 'deal.details.room_description');

		// const travelers = _.get(booking, 'travelers');
		const adults_count = _.get(booking, 'deal.details.guest_count', 1);
		const children_count = _.get(booking, 'deal.details.children_count', 0);
		const total_travelers_count = room_count * (adults_count + children_count);

		const reservation_id = _.get(booking, 'id');
		const creator = _.get(booking, 'creator.full_name');
		const creation_date = _.get(booking, 'creation_date');
		// const cancellation_policy = _.get(booking, 'deal.dca.cancellationPoliciesText');
		// const terms_of_supplier = _.get(booking, 'supplierTerms');

		// const synced_travel_booster_docket_number = _.get(booking, 'travel_booster_docket_number');

		// const busy = (syncTravelBoosterStatus === BookingsConstants.STATUS.BUSY);
		// const succeeded = (syncTravelBoosterStatus === BookingsConstants.STATUS.SUCCESS);
		// const failed = (syncTravelBoosterStatus === BookingsConstants.STATUS.FAILED);
		const busy = sync_travel_booster_status === BookingsConstants.STATUS.BUSY;
		const succeeded = sync_travel_booster_status === BookingsConstants.STATUS.SUCCESS;
		const failed = sync_travel_booster_status === BookingsConstants.STATUS.FAILED;

		const synced_docket_number = _.get(booking, 'travel_booster_docket_number');

		return (
			<div className="travel-booster">
				{/* <Button color='gray' onClick={onClose} disabled={busy} className='x-button'>
                    <CloseIcon />
                </Button> */}
				<div className="reservation-summary">
					{/* <div className='reservation-summary-title'>
                        <span>
                            Reservation Summary:
                        </span>
                    </div> */}
					{/* <label>
                        Reservation Summary:
                    </label> */}
					<div className="reservation-summary-item">
						<span className="reservation-summary-item-title main-title">Reservation Summary</span>
					</div>
					<div className="reservation-summary-item">
						<span className="reservation-summary-item-title">Check-in</span>
						<span className="reservation-summary-item-value">{moment(check_in).format(date_format)}</span>
					</div>
					<div className="reservation-summary-item">
						<span className="reservation-summary-item-title">Check-out</span>
						<span className="reservation-summary-item-value">{moment(check_out).format(date_format)}</span>
					</div>
					<div className="reservation-summary-item">
						<span className="reservation-summary-item-title">Nights</span>
						<span className="reservation-summary-item-value">{nights}</span>
					</div>
					<div className="reservation-summary-item">
						<span className="reservation-summary-item-title">Hotel Name</span>
						<span className="reservation-summary-item-value">{hotel_name}</span>
					</div>
					<hr />
					<div className="column-item">
						<div className="reservation-summary-item">
							<span className="reservation-summary-item-title main-title">Hotel Address</span>
							<span className="reservation-summary-item-value">{full_address}</span>
						</div>
						{/* TODO: travelers count? adults count? children count? */}
						<div className="reservation-summary-item">
							<span className="reservation-summary-item-title main-title">
								{total_travelers_count.length > 1
									? `${total_travelers_count.length} Travelers`
									: '1 Traveler'}
							</span>
							<span className="reservation-summary-item-value">
								{/* {travelers.map(t => `${t.first_name} ${t.last_name}${t.email ? ` (${t.email})` : ''}`)} */}
								{adults_count} {adults_count > 1 ? 'Adults' : 'Adult'}
								{children_count > 0
									? `, ${children_count} ${children_count > 1 ? 'Children' : 'Child'}`
									: null}
							</span>
						</div>
					</div>
					<div className="column-item">
						<div className="reservation-summary-item column-item">
							<span className="reservation-summary-item-title main-title">
								{room_count > 1 ? `${room_count} Rooms` : '1 Room'}
							</span>
							<span className="reservation-summary-item-value">{room_description}</span>
						</div>
						<div className="reservation-summary-item column-item">
							<span className="reservation-summary-item-title main-title">Reservation ID</span>
							<span className="reservation-summary-item-value">{reservation_id}</span>
						</div>
					</div>
					<hr />
					<div className="reservation-summary-item">
						<span className="reservation-summary-item-title">Company</span>
						<span className="reservation-summary-item-value">{company_name}</span>
					</div>
					<div className="reservation-summary-item">
						<span className="reservation-summary-item-title">Creator</span>
						<span className="reservation-summary-item-value">{creator}</span>
					</div>
					<div className="reservation-summary-item">
						<span className="reservation-summary-item-title">Creation Date</span>
						<span className="reservation-summary-item-value">
							{moment(creation_date).format(date_format)}
						</span>
					</div>
					{/* <div className='reservation-summary-item'>
                        <span className='reservation-summary-item-title'>
                            Cancellation Policy
                        </span>
                        <span className='reservation-summary-item-value'>
                            {cancellation_policy}
                        </span>
                    </div>
                    <div className='reservation-summary-item'>
                        <span className='reservation-summary-item-title'>
                            Terms of Supplier
                        </span>
                        <span className='reservation-summary-item-value'>
                            {terms_of_supplier}
                        </span>
                    </div> */}
					{/* <div className='reservation-summary-item'>
                        <span className='reservation-summary-item-title'>
                            Synced Travel Booster ID
                            Synced Docket# or PNR
                            Synced Docket Number
                        </span>
                        <span className='reservation-summary-item-value'>
                            {synced_travel_booster_docket_number}
                        </span>
                    </div> */}
					{/* <hr /> */}

					{/* <div className='column-item full-width' onMouseEnter={this.showEditButton} onMouseLeave={this.hideEditButton}> */}
					<div className="column-item full-width docket-number">
						<div className="reservation-summary-item">
							<span className="reservation-summary-item-title main-title">Docket Number</span>
							{
								// edit_mode
								//     ? (
								//         // <TextField
								//         //     label='Docket Number'
								//         //     value={travel_booster_docket_number}
								//         //     onChange={this.handleChange}
								//         //     disabled={busy || !edit_mode}
								//         //     placeholder='e.g. 1234567'
								//         // /><
								<TextField
									className="edit-docket-number"
									variant="outlined"
									value={travel_booster_docket_number}
									onChange={this.handleChange}
									// disabled={busy || !edit_mode}
									disabled={busy}
									placeholder="e.g. 1234567"
									error={this.state.showNotValidMessage}
								/>
								// )
								// : (
								//     <span className='reservation-summary-item-value'>
								//         {travel_booster_docket_number}
								//         {/* {
								//             (edit_mode)
								//                 ? (
								//                     <TextField id="outlined-basic" label="Outlined" variant="outlined" />
								//                 )
								//                 : travel_booster_docket_number
								//         } */}
								//         {show_edit && (
								//             <Button variant="outlined" className="edit-button" onClick={this.edit}>
								//                 Edit
								//             </Button>
								//         )}
								//     </span>
								// )
							}
						</div>
					</div>
				</div>

				{/* <hr /> */}

				<div className="footer">
					<div className="status">
						{busy && (
							<div className="busy">
								<CircularProgress size={20} />
							</div>
						)}
						{/* {succeeded &&
                            <div className='status-text succeeded'>
                                <span>
                                    Successfully synced {synced_docket_number && ` (Docket #${synced_docket_number})`}.
                                </span>
                            </div>
                        } */}
						{this.state.showNotValidMessage && (
							<div className="status-text failed">
								<span>Please enter a valid docket.</span>
							</div>
						)}
						{this.state.canShowFailedMessage && failed && (
							<div className="status-text failed">
								<span>
									{/* Not synced{synced_docket_number && ` (Currently synced to docket number ${synced_docket_number})`} */}
									Failed to {travel_booster_docket_number ? 'update' : 'create'} docket.
								</span>
							</div>
						)}
					</div>
					<div className="action-buttons">
						<Button
							variant="outlined"
							onClick={onClose}
							disabled={busy}
							className="action-button close-button"
						>
							Close
						</Button>
						<Button
							variant="contained"
							color="primary"
							onClick={this.validateDocketNumber}
							disabled={busy}
							className="action-button sync-button"
						>
							{/* `Save & Sync` or `Re-Sync` */}
							{/* Sync */}
							{
								<React.Fragment>
									{travel_booster_docket_number ? 'Update' : 'Create'}
									{/* {
                                        busy
                                            // ? <span className='busy'>
                                                ?<CircularProgress size={14} />
                                            // </span>
                                            : (
                                                travel_booster_docket_number
                                                    ? <SyncIcon />
                                                    : <AddIcon />
                                            )
                                    } */}
								</React.Fragment>
							}
						</Button>
						{/* <LoadingButton
                            variant='contained'
                            color='primary'
                            onClick={this.syncReservation}
                            disabled={busy}
                            className='action-button sync-button'
                            endIcon={travel_booster_docket_number ? <SyncIcon /> : <AddIcon />}
                            loading={busy}
                            loadingPosition='end'

                        >
                            {
                                travel_booster_docket_number
                                    ? 'Update'
                                    : 'Create'
                            }
                        </LoadingButton> */}
					</div>
				</div>
				<MaterialDesignDialog
					open={succeeded}
					// onClose={onClose}
					enableBackdropClick={false}
					closeTimeoutInMilliseconds={150}
					title={`Docket ${last_action}.`}
					content={`Successfully ${last_action} docket #${synced_docket_number}.`}
					actions={[{ onClick: onClose, text: 'Got it' }]}
				/>
			</div>
		);
	}

	_onChange() {
		const state = getState();
		this.setState(state);
	}
}

export default TravelBooster;
