import React from 'react';

import SearchAutocomplete from '../../../search/SearchAutocomplete.react';
import SearchActions from '../../../../actions/SearchActions';
import ResultsStore from '../../../../stores/ResultsStore';
import PropTypes from 'prop-types';
import SearchStore from '../../../../stores/SearchStore';
import { withRouter } from '../../../../utils/withRouter';
import { WebStorageManager } from 'arbitrip-common/client/utils';
import { VARIANT } from '../../../search/constants';
import Analytics from 'arbitrip-common/client/analytics';
import debounce from 'lodash/debounce';

let search_input = '';
let results_count = 0;

class NameFilter extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			value: WebStorageManager.getFromWebStorage('_filtered_hotel') ?? '',
			focused: false,
		};

		this.onUpdateSearch = this.onUpdateSearch.bind(this);
		this.onFocus = this.onFocus.bind(this);
		this.onClean = this.onClean.bind(this);
		this.updateLocalSelection = this.updateLocalSelection.bind(this);
		this.reportAnalytics = debounce(this.reportAnalytics.bind(this), 200);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!WebStorageManager.getFromWebStorage('_filtered_hotel')) {
			this.onClean();
		}
	}

	onUpdateSearch(destination) {
		const terms = SearchStore.getSearchTerms();
		if (!destination || !destination.hotel_id || destination.hotel_id === terms.hotel_id) {
			return;
		}
		this.setState({
			value: destination,
			focused: false,
		});
		WebStorageManager.updateInWebStorage('_filtered_hotel', destination);
		const isHotelLoaded = !!ResultsStore.getHotelById(destination.hotel_id);
		this.reportAnalytics(false, destination.name, isHotelLoaded);
		if (isHotelLoaded) {
			this.updateLocalSelection(destination.hotel_id);
		} else {
			SearchActions.updateDestination(destination);
			setTimeout(() => {
				SearchActions.search(null, { log_search: false });
			}, 20);
		}
	}

	updateLocalSelection(selected_hotel_id) {
		ResultsStore.updateSelectedHotelInSession({ selected_hotel_id }).then(() => {
			SearchActions.showHotelLoader(true);
			window.scrollTo(0, 0);
			setTimeout(() => {
				ResultsStore.markHotelSelected({ selected_hotel_id });
				SearchActions.showHotelLoader(false);
			}, 500);
		});
	}

	onFocus(value) {
		this.setState({ focused: value });
		this.reportAnalytics(value);
	}

	reportAnalytics(isOpened, hotelName, isLoaded) {
		if (isOpened) {
			Analytics.actions.interactions.hotelNameFilterOpened(this.props.profile);
			return;
		}
		if (hotelName) {
			Analytics.actions.interactions.hotelNameFilterSelectedSuggestion({
				user: this.props.profile,
				search_input,
				results_count,
				is_in_results: isLoaded,
				selected_hotel_name: hotelName,
			});
		} else {
			Analytics.actions.interactions.hotelNameFilterClosed({
				user: this.props.profile,
				search_input,
				results_count,
			});
		}
		search_input = '';
		results_count = 0;
	}

	onClean() {
		if (!this.state.value) {
			return;
		}
		this.setState({ value: '' });
		WebStorageManager.removeFromWebStorage('_filtered_hotel');
		const searchTerms = SearchStore.getSearchTerms();
		this.updateLocalSelection(searchTerms?.destination?.place?.hotel_id);
	}

	render() {
		const { search_terms } = this.props;
		const { value, focused } = this.state;

		const nameContainsInputClass = 'form-control read-only-background';

		return (
			<div className="filter">
				<label className="filter-title" htmlFor="hotel-name-input">
					Hotel Name
				</label>
				<input
					id="hotel-name-input"
					type="text"
					className={nameContainsInputClass}
					placeholder="e.g. Hilton"
					value={value ? value.label : ''}
					onClick={() => this.onFocus(true)}
					onFocus={() => this.onFocus(true)}
					readOnly
				/>
				{focused && (
					<div className={`autocomplete-wrap`}>
						<SearchAutocomplete
							value={value}
							onUpdate={this.onUpdateSearch}
							onClean={this.onClean}
							autoFocus={true}
							onFocus={this.onFocus}
							baseDestination={search_terms?.destination}
							showSearchIcon={false}
							variant={VARIANT.CITY_SEARCH}
							placeholder="e.g. Hilton"
							openMenu={true}
							onInputChange={(e) => {
								console.log('input', e);
								search_input = e;
							}}
							onResultsLoaded={(res) => {
								if (!Array.isArray(res)) {
									res = [];
								}
								console.log('onResultsLoaded', res.length);
								results_count = res.length;
							}}
						/>
					</div>
				)}

				<button className="clear-button" onClick={this.onClean} aria-label="Clear" />
				<button className="clear-button-wrap" onClick={this.onClean} aria-label="Clear Hotel search">
					<div className="clear-button-img" />
				</button>
			</div>
		);
	}
}

NameFilter.propTypes = {
	filter: PropTypes.string.isRequired,
	profile: PropTypes.object.isRequired,
	search_terms: PropTypes.object.isRequired,
};

export default withRouter(NameFilter);
