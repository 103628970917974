import React from 'react';
import createClass from 'create-react-class';
import moment from 'moment';
import _ from 'lodash';

import { IconButton, Tooltip } from '@mui/material';
import StarsComponent from '../../../../StarsComponent.react';
// import Icon from 'react-evil-icons';
import Icon from '../../../../ReactEvilIcon';

import BasketActions from '../../../../../actions/BasketActions';
import Currencies from '../../../../../utils/Currencies';
import styles from '../../../../../utils/Styles';
import Analytics from 'arbitrip-common/client/analytics';

import EditOutlined from '@mui/icons-material/EditOutlined';
// import { TextField, InputAdornment, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
// import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
// import AgentPricing from '../../../../pages/reservation-payment/components/AgentPricing.react';
import ChangeClientPriceDialog from '../../../../general/ChangeClientPriceDialog.react';
import DealPrice from '../../../../DealPrice.react';

function formatDate(date) {
	return moment.utc(date, 'YYYY-MM-DD').format('MMM D');
}

function normalizeAddressPart(part) {
	if (part) {
		return ', ' + part;
	}

	return '';
}

function renderSimpleField(fieldName, fieldValue, className) {
	return (
		<div className={'field ' + className}>
			<div className="field-name">
				<span>{fieldName}</span>
			</div>
			<div className="field-value">
				<span>{fieldValue}</span>
			</div>
		</div>
	);
}

var BasketDeal = createClass({
	displayName: 'BasketDeal',

	getInitialState: function () {
		return {
			hovered: false,
		};
	},

	onRemoveDealClick: function (e) {
		let { deal } = this.props;
		BasketActions.removeHotelDeal(deal);
		Analytics.actions.interactions.removedFromBasket(deal.hotel, deal);
	},

	handleOnMouseEnter: function () {
		this.setState({
			hovered: true,
		});
	},

	handleOnMouseLeave: function () {
		this.setState({
			hovered: false,
		});
	},

	onEdit: function () {
		this.setState({
			edit_price_mode: true,
		});
	},

	editPrice: function (event) {
		this.setState({
			edit_price_value: event.target.value,
		});
	},

	handleCancelChangeClientPrice: function () {
		this.setState({
			edit_price_mode: false,
		});
	},

	handleUpdateChangeClientPrice: function (agentPricing) {
		BasketActions.updateAgentPricing(this.props.deal, agentPricing);

		this.setState({
			edit_price_mode: false,
		});
	},

	isValidPrice: function () {
		return (
			!isNaN(this.state.edit_price_value) && Number(this.state.edit_price_value) > this.props.deal.pricePerNight
		);
	},

	render: function () {
		const { hovered } = this.state;
		const { deal, profile } = this.props;
		const { hotel } = deal;

		let hotel_name = hotel.name;
		let hotel_stars = hotel.stars;
		let full_address =
			(typeof hotel.address === 'string' && hotel.address.toLowerCase()) + normalizeAddressPart(hotel.city);

		if (hotel.hotelInfo) {
			full_address += normalizeAddressPart(hotel.hotelInfo.state) + normalizeAddressPart(hotel.hotelInfo.country);
		}

		let review_score = hotel.review ? hotel.review.score + ' (' + hotel.review.description + ')' : 'Not Available';
		let price = deal.pricePerNight;
		let currency = deal.currency;

		let check_in = formatDate(deal.check_in);
		let check_out = formatDate(deal.check_out);
		let room_type = typeof deal.room === 'string' && deal.room.toLowerCase();

		let cancellation_terms = deal.chooseprod
			? deal.chooseprod.dca.nonRefundable
				? 'Non refundable'
				: 'Free cancellation before ' + formatDate(deal.chooseprod.dca.dealIsFullyRefundableTill)
			: 'N/A';

		let basket_deal_classes = 'basket-deal';
		if (hovered) {
			basket_deal_classes += ' hovered';
		}

		const {
			active: active_agent_pricing,
			client_price_total,
			client_price_per_night,
			absolute_margin_total,
			absolute_margin_per_night,
		} = _.get(deal, 'agent_pricing', {});

		const agent_pricing_props = active_agent_pricing
			? {
					agentTotalPrice: client_price_total,
					agentPricePerNight: client_price_per_night,
					agentCommission: absolute_margin_total,
					agentCommissionPerNight: absolute_margin_per_night,
				}
			: {};

		const avg_room_night = active_agent_pricing
			? Currencies.getRoundedPriceWithCurrency(client_price_per_night, currency)
			: Currencies.getRoundedPriceWithCurrency(price, currency);

		return (
			<div
				className={basket_deal_classes}
				onMouseEnter={this.handleOnMouseEnter}
				onMouseLeave={this.handleOnMouseLeave}
			>
				<span className="hotel-name">{hotel_name}</span>
				<div className="hotel-name-stars-address-dates-room">
					<div className="line">
						<div className="hotel-name-stars">
							{/*<span className="hotel-name">
              {hotel_name}
            </span>*/}
							<StarsComponent stars={hotel_stars} />
						</div>
						<div className="hotel-address">
							<span>{full_address}</span>
						</div>
					</div>
					<div className="line">
						{renderSimpleField('Check-in', check_in, 'date check-in')}
						{renderSimpleField('Check-out', check_out, 'date check-out')}
						{/*{renderSimpleField('Room Type', room_type,'room-type')}*/}
						<div className="field room-type">
							<div className="field-name">
								<span>Room Type</span>
							</div>
							<div className="field-value">
								{deal.breakfast ? (
									<Tooltip title={deal.catering}>
										<IconButton style={styles.breakfastIconButton}>
											<div className="arbi-icon icon-at-breakfast-o" />
										</IconButton>
									</Tooltip>
								) : null}
								<span title={room_type}>{room_type}</span>
							</div>
						</div>
					</div>
				</div>
				<div className="review-score-price-cancellation">
					<div className="line">
						{renderSimpleField('Score', review_score, 'score')}
						{/*{renderSimpleField('Avg/Room/Night', Currencies.getRoundedPriceWithCurrency(price, currency), 'price')}*/}
						<div className="field price">
							<div className="field-name">
								<div>Avg/Room/Night</div>
							</div>
							<div className="field-value">
								<React.Fragment>
									{profile.agent ? (
										<DealPrice
											// displayTotalPrice={false}
											displayTotalPrice={true}
											supplier={deal.supplier_name}
											currency={deal.currency}
											price={deal.totalPrice}
											pricePerNight={deal.pricePerNight}
											totalPrice={deal.totalPrice}
											netPrice={deal.net_price_total}
											commission={deal.commission_total}
											dealId={deal.id}
											profile={profile}
											// reservationMode={true}
											basketMode={true}
											{...agent_pricing_props}
											contractClientCompanyId={deal.contract_client_company_id}
										/>
									) : (
										<div>{avg_room_night}</div>
									)}
									{deal.travel_policy_exists ? (
										<Tooltip title={deal.in_policy ? 'In Policy' : 'Out of Policy'}>
											<IconButton
												style={styles.travelPolicyIconButton}
												className="travel-policy-icon-button"
											>
												<div
													className={deal.in_policy ? 'in-policy icon' : 'out-of-policy icon'}
												/>
											</IconButton>
										</Tooltip>
									) : null}
									{
										// // (hovered && profile.agent && profile.enable_my_list_price_edit) &&
										// (hovered && profile.agent && profile.enable_basket_price_edit) &&
										deal.change_client_price_enabled && hovered && (
											// <IconButton
											//   tooltip="Edit Price"
											//   style={styles.editPriceIconButton}
											//   className="edit-price-icon-button"
											//   iconClassName="edit-price icon"
											// />
											<IconButton
												color="primary"
												aria-label="edit price"
												component="span"
												size="small"
												onClick={this.onEdit}
											>
												<EditOutlined />
											</IconButton>
										)
									}
									{this.state.edit_price_mode && (
										<ChangeClientPriceDialog
											dialogOpen={this.state.edit_price_mode}
											dialogTitle="Change Client Price"
											deal={deal}
											initializing={false}
											disabled={false}
											onCancel={this.handleCancelChangeClientPrice}
											onUpdate={this.handleUpdateChangeClientPrice}
											profile={profile}
											basketMode={true}
										/>
									)}

									{/* <Dialog open={this.state.edit_price_mode}
                    classes={{ root: 'basket-agent-pricing' }}
                  >
                    <DialogTitle>
                      <div className="change-client-price-title">
                        Change Client Price
                      </div>
                    </DialogTitle>
                    <DialogContent>
                      <AgentPricing
                        initializing={false}
                        reservation={this.props.reservation}
                        basket={true}
                        shouldLockForm={false}
                      />
                    </DialogContent>
                    <DialogActions>
                      <div className="change-client-price-actions">
                        <Button autoFocus onClick={this.handleCancelChangeClientPrice} color="primary">
                          Cancel
                        </Button>
                        <Button onClick={this.handleUpdateChangeClientPrice} color="primary">
                          Update
                        </Button>
                      </div>
                    </DialogActions>
                  </Dialog> */}
								</React.Fragment>
							</div>
						</div>
					</div>
					<div className="line">
						{renderSimpleField('Cancellation Terms', cancellation_terms, 'cancellation-terms')}
					</div>
				</div>
				{hovered ? (
					<a href="#!" className="action remove-deal" onClick={this.onRemoveDealClick}>
						<Icon name="ei-close" size="m" className="evil-icon" />
					</a>
				) : null}
			</div>
		);
	},
});

export default BasketDeal;
