import _ from 'lodash';
import React from 'react';
import createClass from 'create-react-class';
import ManagementActions from '@/actions/ManagementActions';
import ManagementConstants from '@/constants/ManagementConstants';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { FormControl, FormHelperText, InputLabel, Select, MenuItem, TextField } from '@mui/material';
import MaterialDesignTravelersAutocomplete from '../../MaterialDesignTravelersAutocomplete.react';

import $ from 'jquery';

import Currencies from '@/utils/Currencies';

import moment from 'moment';

const integer_regex = /^[1-9]\d*$/;

const min_date = moment();
const max_date = moment().add(2, 'years');

const UPDATE_META_SHORT_DELAY = 4;
const UPDATE_META_LONG_DELAY = 950;

function formatDate(date) {
	return moment(date).format('MMM DD');
}

var has_changed = false;

var TravelRequest = createClass({
	displayName: 'TravelRequest',

	getInitialState: function () {
		return {
			hovering: false,
			editing: false,
		};
	},

	compareArrays: function (first, second, field) {
		return first.every((a) => second.some((b) => a[field] === b[field]));
	},

	componentDidMount() {
		this.timeoutId = setTimeout(() => {
			ManagementActions.previewCostEstimation();
		}, 10);
	},

	componentWillUnmount() {
		clearTimeout(this.timeoutId);
	},

	componentDidUpdate(prevProps) {
		const prevTripRequest = prevProps.trip_request;
		const nextTripRequest = this.props.trip_request;

		has_changed =
			prevTripRequest &&
			nextTripRequest &&
			nextTripRequest.id &&
			prevTripRequest.id === nextTripRequest.id &&
			Array.isArray(prevTripRequest.purposes) &&
			Array.isArray(prevTripRequest.travelers) &&
			Array.isArray(nextTripRequest.purposes) &&
			Array.isArray(nextTripRequest.travelers) &&
			(prevTripRequest.purposes.length !== nextTripRequest.purposes.length ||
				!this.compareArrays(prevTripRequest.purposes, nextTripRequest.purposes, '_id') ||
				prevTripRequest.cost_center !== nextTripRequest.cost_center ||
				(prevTripRequest.vacation_days_to && !nextTripRequest.vacation_days_to) ||
				(!prevTripRequest.vacation_days_to && nextTripRequest.vacation_days_to) ||
				(prevTripRequest.vacation_days_from && !nextTripRequest.vacation_days_from) ||
				(!prevTripRequest.vacation_days_from && nextTripRequest.vacation_days_from) ||
				(prevTripRequest.vacation_days_from &&
					prevTripRequest.vacation_days_from &&
					moment(prevTripRequest.vacation_days_from).diff(
						moment(nextTripRequest.vacation_days_from),
						'days',
					) !== 0) ||
				(prevTripRequest.vacation_days_to &&
					nextTripRequest.vacation_days_to &&
					moment(prevTripRequest.vacation_days_to).diff(moment(nextTripRequest.vacation_days_to), 'days') !==
						0) ||
				prevTripRequest.conference_name_or_website !== nextTripRequest.conference_name_or_website ||
				prevTripRequest.conference_estimated_cost_currency !==
					nextTripRequest.conference_estimated_cost_currency ||
				prevTripRequest.conference_estimated_cost_amount !== nextTripRequest.conference_estimated_cost_amount ||
				prevTripRequest.travelers.length !== nextTripRequest.travelers.length ||
				prevTripRequest.staying_with_text !== nextTripRequest.staying_with_text ||
				prevTripRequest.trips_schedule_and_agenda_text !== nextTripRequest.trips_schedule_and_agenda_text ||
				!this.compareArrays(prevTripRequest.travelers, nextTripRequest.travelers, 'id'));

		this.updateMetaData(UPDATE_META_LONG_DELAY);

		// closes purposes dropdown when picking the first purpose
		if (
			(!prevTripRequest.purposes || prevTripRequest.purposes.length === 0) &&
			nextTripRequest.purposes &&
			nextTripRequest.purposes.length > 0
		) {
			setTimeout(function () {
				$('.trip-purposes.field .field-input.select button').click();
			}, 20);
		}
	},

	handleOnBlur: function () {
		this.updateMetaData(UPDATE_META_SHORT_DELAY);
	},

	updateMetaData: function (delay) {
		if (has_changed) {
			setTimeout(function () {
				ManagementActions.editTripRequestMeta(delay);
			}, 4);
		}
	},

	handleConferenceNameOrWebsiteOnChange: function (e) {
		ManagementActions.updateConferenceNameOrWebsite(e.target.value);
	},

	handleConferenceEstimatedCostAmountOnChange: function (e) {
		let { profile, trip_request } = this.props;
		var amount = e.target.value;
		let currency_symbol = Currencies.getSymbol(profile.currency || trip_request.conference_estimated_cost_currency);

		if (amount.indexOf(currency_symbol) === 0) {
			amount = amount.substr(1, amount.length);
		}

		if (amount === '' || amount === '0' || integer_regex.test(amount)) {
			amount = Number(amount);
			ManagementActions.updateConferenceEstimatedCostAmount(amount);
		}
	},

	handleConferenceEstimatedCostCurrencyOnSelect: function (value, option) {
		ManagementActions.updateConferenceEstimatedCostCurrency(value);
		this.updateMetaData(UPDATE_META_SHORT_DELAY);
	},

	handleConferenceEstimatedCostCurrencyOnChange: function (event) {
		ManagementActions.updateConferenceEstimatedCostCurrency(event.target.value);
		this.updateMetaData(UPDATE_META_SHORT_DELAY);
	},

	handleStayingWithOnChange: function (e) {
		ManagementActions.updateStayingWith(e.target.value);
	},

	handleTripsScheduleAndAgendaOnChange: function (e) {
		ManagementActions.updateTripsScheduleAndAgenda(e.target.value);
	},

	getPurposesByIds: function (ids) {
		let { purposes } = this.props.profile.company;
		let purposes_by_ids = [];
		for (let i = 0; i < purposes.length; i++) {
			if (ids.some((id) => id === purposes[i]._id)) {
				purposes_by_ids.push(purposes[i]);
			}
		}

		return purposes_by_ids;
	},

	handlePurposesOnChange: function (event) {
		const { value } = event.target;

		ManagementActions.updatePurposes(this.getPurposesByIds(value));
	},

	getCostCenterById: function (id) {
		let { cost_centers } = this.props.profile.company;
		for (let i = 0; i < cost_centers.length; i++) {
			if (cost_centers[i]._id === id) {
				return cost_centers[i];
			}
		}

		console.error('NO COST CENTER !!! (TravelRequest.getCostCenterById)');
		return id;
	},

	handleCostCenterOnChange: function (event) {
		const { value } = event.target;
		ManagementActions.updateCostCenter(this.getCostCenterById(value));
	},

	handlePurposesOnSelect: function (value, option) {
		console.log('purpose selected:', value, option);
		ManagementActions.addPurpose(value);
	},

	handlePurposesOnDeselect: function (value) {
		console.log('purpose deselected:', value);
		ManagementActions.removePurpose(value);
	},

	handleCostCenterOnSelect: function (value, option) {
		console.log('cost center selected:', value);
		ManagementActions.updateCostCenter(value);
	},

	handleCreateNewTravelerOnClick: function (e) {
		console.log('create new traveler clicked:', e);
		this.setState({
			modal_visible: true,
		});
	},

	handleCreateTravelerCancelClick: function (e) {
		this.setState({
			modal_visible: false,
		});
	},

	disabledVacationDaysFromDate: function (from_date) {
		const to_date = this.props.trip_request.vacation_days_to;
		if (!from_date || !to_date) {
			return false;
		}
		return from_date.valueOf() > to_date.valueOf();
	},

	disabledVacationDaysToDate: function (to_date) {
		const from_date = this.props.trip_request.vacation_days_from;
		if (!to_date || !from_date) {
			return false;
		}
		return to_date.valueOf() < from_date.valueOf();
	},

	handleVacationDaysOnClick: function (event, date) {
		let { trip_request } = this.props;
		let from_date = trip_request.vacation_days_from;
		let to_date = trip_request.vacation_days_to;
		if (!from_date) {
			ManagementActions.updateVacationDaysFrom(trip_request.meta.start_date);
		}
		if (!to_date) {
			ManagementActions.updateVacationDaysTo(moment(trip_request.meta.start_date).add(5, 'hours'));
		}
	},

	handleVacationDaysFromOnChange: function (date) {
		let { trip_request } = this.props;
		let to_date = trip_request.vacation_days_to;

		ManagementActions.updateVacationDaysFrom(date);
		if (!to_date || moment(date).isSameOrAfter(moment(to_date), 'day')) {
			ManagementActions.updateVacationDaysTo(moment(date).add(1, 'day'));
		}
	},

	handleClearVacationDaysClick: function () {
		ManagementActions.clearVacationDaysTo();
	},

	handleVacationDaysToOnChange: function (date) {
		ManagementActions.updateVacationDaysTo(date);
	},

	handleOnMouseEnter: function (e) {
		if (this.props.borderHook) {
			this.props.borderHook(true);
		}

		this.setState({
			hovering: true,
		});
	},

	handleOnMouseLeave: function (e) {
		if (this.props.borderHook) {
			this.props.borderHook(false);
		}

		this.setState({
			hovering: false,
		});
	},

	handleOnEditClick: function (e) {
		if (this.props.borderHook) {
			this.props.borderHook(true);
		}

		this.setState({
			editing: true,
		});
	},

	handleTravelerOnChange: function (searchText) {
		this.setState({ searchText: searchText });
		ManagementActions.updateTraveler(null);
	},

	onTravelerAutoCompleteNewRequest: function (data) {
		console.log('onTravelerAutoCompleteNewRequest', data);
		ManagementActions.updateTraveler(data);
		// this.setState({searchText: null});
		let cost_center = this.getCostCenterById(data.cost_center);
		if (cost_center) {
			ManagementActions.updateCostCenter(cost_center);
		}
	},

	clearPreviewPane: function () {
		// ManagementActions.clearPreview();
		ManagementActions.previewCostEstimation();
	},

	getConferenceMode() {
		let { trip_request, profile, can_edit, should_validate } = this.props;

		let conference_mode =
			trip_request &&
			Array.isArray(trip_request.purposes) &&
			trip_request.purposes.some((purpose) => purpose.purpose_type === 'conference');

		if (conference_mode) {
			let conference_name_or_website = trip_request.conference_name_or_website;
			let conference_cost =
				Currencies.getSymbol(profile.currency || trip_request.conference_estimated_cost_currency) +
				trip_request.conference_estimated_cost_amount;
			if (!can_edit) {
				if (!conference_name_or_website) {
					conference_name_or_website = ManagementConstants.NO_VALUE;
				}
				if (conference_cost.length === 1) {
					// only symbol
					conference_cost = ManagementConstants.NO_VALUE;
				}
			}
			return (
				<div className="line">
					<div className="conference-name-or-website field" key="conference-name">
						<TextField
							onBlur={this.handleOnBlur}
							fullWidth
							value={conference_name_or_website}
							onChange={this.handleConferenceNameOrWebsiteOnChange}
							disabled={!can_edit}
							placeholder="e.g. WTM 2017"
							error={should_validate && !trip_request.conference_name_or_website}
						/>
					</div>
					<div className="conference-estimated-cost field" key="estimated-cost">
						{/* TODO: allow only numerals... */}
						<TextField
							onBlur={this.handleOnBlur}
							fullWidth
							value={conference_cost}
							onChange={this.handleConferenceEstimatedCostAmountOnChange}
							disabled={!can_edit}
							placeholder="e.g. 1250"
							error={should_validate && !trip_request.conference_estimated_cost_amount}
						/>
					</div>
				</div>
			);
		}
	},

	getStayingWithEnabled() {
		let { trip_request, profile, can_edit } = this.props;

		if (profile && profile.company && profile.company.settings && profile.company.settings.staying_with_enabled) {
			return (
				<div className="line">
					<div className="staying-with-text field" key="staying-with-text">
						<TextField
							onBlur={this.handleOnBlur}
							fullWidth
							value={trip_request.staying_with_text}
							onChange={this.handleStayingWithOnChange}
							disabled={!can_edit}
							placeholder="Other employees I will be staying with at the same hotel or will be flying with me"
							multiline
							variant="standard"
						/>
					</div>
				</div>
			);
		}
	},

	getTripsScheduleAndAgendaEnabled() {
		let { trip_request, profile, can_edit, should_validate } = this.props;

		if (
			profile &&
			profile.company &&
			profile.company.settings &&
			profile.company.settings.trips_schedule_and_agenda_enabled
		) {
			return (
				<div className="line">
					<div className="trips-schedule-and-agenda-text field" key="trips-schedule-and-agenda-text">
						<TextField
							onBlur={this.handleOnBlur}
							fullWidth
							value={trip_request.trips_schedule_and_agenda_text}
							onChange={this.handleTripsScheduleAndAgendaOnChange}
							disabled={!can_edit}
							placeholder="Trip's itinerary and goals"
							multiline
							variant="standard"
							error={should_validate && !trip_request.trips_schedule_and_agenda_text}
						/>
					</div>
				</div>
			);
		}
	},

	render: function () {
		let { trip_request, profile, can_edit, should_validate } = this.props;
		let { hovering, editing } = this.state;

		if (!trip_request || !trip_request.cost_center) {
			return null;
		}

		let vacation_days_from =
			trip_request && trip_request.vacation_days_from && moment(trip_request.vacation_days_from);
		let vacation_days_to = trip_request && trip_request.vacation_days_to && moment(trip_request.vacation_days_to);
		let vacation_days_total =
			vacation_days_from && vacation_days_to && vacation_days_to.diff(vacation_days_from, 'days') + 1;

		function serverMenuItems(options, field, multi) {
			return options.map((option, idx) => (
				<MenuItem
					key={idx}
					checked={
						multi
							? trip_request[field].some((field_value) => option._id === field_value._id)
							: option._id === trip_request[field]._id
					}
					value={option._id}
				>
					{option.name}
				</MenuItem>
			));
		}

		let travel_request_classes = 'travel-request section';
		if (hovering) {
			travel_request_classes += ' hovering';
		} else if (editing) {
			travel_request_classes += ' editing';
		}

		let text =
			(Array.isArray(trip_request.travelers) &&
				trip_request.travelers[0] &&
				trip_request.travelers[0].full_name) ||
			this.state.searchText;

		return (
			<div className={travel_request_classes} onMouseEnter={this.clearPreviewPane}>
				<div className="line">
					<div className="trip-purposes field">
						<FormControl fullWidth>
							{can_edit ? (
								<React.Fragment>
									<InputLabel variant="standard" shrink={true} htmlFor="trip-purposes-label">
										Trip Purposes
									</InputLabel>
									{!trip_request.purposes.length && (
										<InputLabel variant="standard" shrink={false}>
											e.g. Client Meeting
										</InputLabel>
									)}
									<Select
										labelId="trip-purposes-label"
										variant="standard"
										value={
											trip_request &&
											Array.isArray(trip_request.purposes) &&
											trip_request.purposes.map((purpose) => purpose._id)
										}
										multiple={true}
										onChange={this.handlePurposesOnChange}
										onBlur={this.handleOnBlur}
										error={should_validate && !trip_request.purposes.length}
									>
										{serverMenuItems(profile.company.purposes, 'purposes', true)}
									</Select>
								</React.Fragment>
							) : (
								<TextField
									variant="standard"
									label="Trip Purposes"
									fullWidth
									defaultValue={
										_.get(trip_request, 'purposes', []).length
											? trip_request.purposes.map((purpose) => purpose.name).join(', ')
											: ManagementConstants.NO_VALUE
									}
									disabled
								/>
							)}
						</FormControl>
					</div>
				</div>
				<div className="line">
					<div className="cost-center field">
						<FormControl fullWidth>
							{can_edit ? (
								<React.Fragment>
									<InputLabel variant="standard" shrink={true} htmlFor="cost-center-label">
										Cost Center
									</InputLabel>
									<Select
										labelId="cost-center-label"
										variant="standard"
										value={trip_request.cost_center._id}
										onChange={this.handleCostCenterOnChange}
										onBlur={this.handleOnBlur}
									>
										{serverMenuItems(profile.company.cost_centers, 'cost_center')}
									</Select>
								</React.Fragment>
							) : (
								<TextField
									variant="standard"
									label="Cost Center"
									fullWidth
									value={trip_request.cost_center.name || ManagementConstants.NO_VALUE}
									disabled
								/>
							)}
						</FormControl>
					</div>
				</div>
				<div className="line">
					<div className="vacation-days field">
						<InputLabel variant="standard" shrink={true}>
							Vacation Days
						</InputLabel>

						{can_edit ? (
							<LocalizationProvider
								dateAdapter={AdapterMoment}
								adapterLocale={ManagementConstants.ADAPTER_MOMENT_LOCALE}
							>
								<DesktopDatePicker
									format={ManagementConstants.DATE_PICKER_FORMAT}
									className="date-picker first"
									fullWidth
									label="From"
									variant="standard"
									inputFormat="DD/MM/YYYY"
									value={vacation_days_from}
									onChange={this.handleVacationDaysFromOnChange}
									minDate={min_date}
									maxDate={vacation_days_to ? vacation_days_to : max_date}
									slotProps={{ textField: { variant: 'standard' } }}
								/>
							</LocalizationProvider>
						) : (
							<TextField
								className="disabled-margin"
								variant="standard"
								label="From"
								fullWidth
								value={
									(vacation_days_from && formatDate(vacation_days_from.toDate())) ||
									ManagementConstants.NO_VALUE
								}
								disabled
							/>
						)}
						{can_edit ? (
							<LocalizationProvider
								dateAdapter={AdapterMoment}
								adapterLocale={ManagementConstants.ADAPTER_MOMENT_LOCALE}
							>
								<DesktopDatePicker
									format={ManagementConstants.DATE_PICKER_FORMAT}
									className="date-picker"
									fullWidth
									label="To"
									variant="standard"
									inputFormat="DD/MM/YYYY"
									value={vacation_days_to}
									onChange={this.handleVacationDaysToOnChange}
									minDate={vacation_days_from ? vacation_days_from : min_date}
									maxDate={max_date}
									slotProps={{ textField: { variant: 'standard' } }}
								/>
							</LocalizationProvider>
						) : (
							<TextField
								className="disabled-margin"
								variant="standard"
								label="To"
								fullWidth
								value={
									(vacation_days_to && formatDate(vacation_days_to.toDate())) ||
									ManagementConstants.NO_VALUE
								}
								disabled
							/>
						)}
						<div className="dates-summary">
							{vacation_days_total ? (
								<div className="vacation-days-total">
									({vacation_days_total} {vacation_days_total === 1 ? 'day' : 'days'} total)
								</div>
							) : null}
							{vacation_days_total && can_edit ? (
								<a
									href="#!"
									className="clear-vacation-days"
									onClick={this.handleClearVacationDaysClick}
								>
									Clear
								</a>
							) : null}
						</div>
					</div>
				</div>
				{profile.travel_manager && (
					<div className="traveler field" key="traveler">
						<MaterialDesignTravelersAutocomplete
							className="field-input"
							onBlur={this.handleOnBlur}
							placeholder="e.g. John Smith"
							label="Traveler"
							fullWidth={true}
							searchText={text}
							onNewRequest={this.onTravelerAutoCompleteNewRequest}
							autoFocus={this.props.autoFocus}
							error={should_validate && !_.get(trip_request, 'travelers[0].full_name', false)}
						/>
					</div>
				)}

				{this.getConferenceMode()}

				{this.getStayingWithEnabled()}

				{this.getTripsScheduleAndAgendaEnabled()}
			</div>
		);
	},
});

export default TravelRequest;
