import keyMirror from 'keymirror';

// Define action constants
export default keyMirror({
	START_FETCH_RECENT_SEARCHES: false,
	RECEIVE_RECENT_SEARCHES_DATA: null, // After postback
	RECEIVE_RECENT_SEARCHES_TERMS: null, // After postback // choosing a recent search...

	RECENT_SEARCHES_RETRIEVAL_FAILED: null,

	REMOVE_RECENT_SEARCH: null,
	RECENT_SEARCH_REMOVED: null,
	RECENT_SEARCH_REMOVAL_FAILED: null,
});
