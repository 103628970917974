const Core = require('./core');

function endSession(profile) {
  return undefined;
}

module.exports = {
  startSession: Core.init,
  endSession: endSession,
  trackEvent: Core.track
};
