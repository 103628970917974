const _ = require('lodash');
const moment = require('moment');
const DATE_FORMAT = 'YYYY-MM-DD';
const DATE_FORMAT_SPACE = 'D MMM YYYY';
const DATE_FORMAT_MIXPANEL = 'DD MMM YYYY';
const DATE_FORMAT_SPACE_REGEX = /^\d{1,2}\s[a-zA-Z\u0590-\u05FF\u200f\u200e]{3,4}\s\d{4}$/;
const { SUPPORTED_LANGUAGES } = require('../../translation/const');

function getMixpanelString(date) {
    const new_date = getDateInFormat(date);

    return moment(new_date, DATE_FORMAT).locale(SUPPORTED_LANGUAGES.EN).format(DATE_FORMAT_MIXPANEL)
}

function getDateInFormat(date) {
    if (_.isNumber(date)) {
        // if unix format
        return moment(date).format(DATE_FORMAT);
    }

    if (_.isString(date) && DATE_FORMAT_SPACE_REGEX.test(date)) {
        // if format is different (D MMM YYYY)
        // can take english and hebrew format
        return moment(date, DATE_FORMAT_SPACE).format(DATE_FORMAT);
    }

    // return the date as is and dont change it by the utc time
    return moment(date, DATE_FORMAT).format(DATE_FORMAT);
}

function getDateInIso(date) {
    const normalized_date = getDateInFormat(date);
    const new_date = new Date(normalized_date)

    // create a date iso string without utc impact
    // use of regular toISOString can change the date becuase of utc calculation
    return new Date(new_date.getTime() - new_date.getTimezoneOffset() * 60000).toISOString()
}

function isValidDate(date) {
    return (Object.prototype.toString.call(date) === '[object Date]') && !isNaN(date.getTime());
}

function ensureIsoString(dateOrIsoString) {
    if (isValidDate(dateOrIsoString)) {
        return dateOrIsoString.toISOString();
    }

    const m = moment(toUTC(dateOrIsoString));
    if (m.isValid()) {
        return m.toISOString();
    }

    return dateOrIsoString;
}

function toUTC(d) {
    if (!_.isDate(d)) {
        d = moment(d, [moment.ISO_8601, 'YYYY-MM-DD', 'YYMMDD'], true).toDate();
    }

    return new Date(d.getTime() - d.getTimezoneOffset() * 60 * 1000);
}

function formatCancellationDeadline(deadline) {
    if (!deadline) return null;

    // Parse the deadline as a UTC moment and adjust to the previous day at 11:59 PM
    const deadlineMoment = moment.utc(deadline).subtract(1, 'day').endOf('day');

    return `${deadlineMoment.format('MMM D, YYYY hh:mm A')} (GMT)`;
}


module.exports = {
    toUTC,
    dateToCheckInOutString: function (date) {
        if (date)
            return moment(date).format('DD MM YYYY');
    },

    reformatDate: function (date) {
        return moment(date).format(DATE_FORMAT);
    },

    parseFormatDate: function (date) {
        if (!date) return null;
        return moment.utc(date, DATE_FORMAT);
    },

    customDateOnlyParse: function (dateOrIsoString) {
        if (!dateOrIsoString) return null;

        try {
            const dateString = ensureIsoString(dateOrIsoString);
            const m = moment.utc(dateString.split('T')[0]);

            return m.isValid()
                ? m
                : dateOrIsoString;
        } catch (ex) {
            console.error(ex);

            return dateOrIsoString;
        }
    },
    getMixpanelString,
    getDateInFormat,
    getDateInIso,
    formatCancellationDeadline
};
