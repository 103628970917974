const Events = require('../events/index');
const Core = require('../core');
const { getPointsObject } = require('../services/pointsService');
const { getUserObject } = require('../services/userService');

function getPointsData(user, points) {
    const metadata = {
        ...getUserObject(user),
        ...getPointsObject(points)
    };

    Core.trackEvent(Events.POINTS.GET_POINTS_DATA, metadata);
}

function getPointsDataFailed(user, error) {
    const metadata = {
        ...getUserObject(user),
        error
    };

    Core.trackEvent(Events.POINTS.GET_POINTS_DATA_FAILED, metadata);
}

function hidePointsOnDifferentCurrency(user, points) {
    const metadata = {
        ...getUserObject(user),
        ...getPointsObject(points)
    };

    Core.trackEvent(Events.POINTS.HIDE_POINTS_ON_DIFFERENT_CURRENCY, metadata);
}

function openPointsCards(user, points) {
    const metadata = {
        ...getUserObject(user),
        ...getPointsObject(points)
    };

    Core.trackEvent(Events.POINTS.OPEN_POINTS_CARD, metadata);
}

function closePointsCards(user, points) {
    const metadata = {
        ...getUserObject(user),
        ...getPointsObject(points)
    };

    Core.trackEvent(Events.POINTS.CLOSE_POINTS_CARD, metadata);
}

function userAppliedPointsToPayment(user, points) {
    const metadata = {
        ...getUserObject(user),
        ...getPointsObject(points)
    };

    Core.trackEvent(Events.POINTS.USER_APPLIED_POINTS_TO_PAYMENT, metadata);
}

module.exports = {
    getPointsData,
    getPointsDataFailed,
    hidePointsOnDifferentCurrency,
    openPointsCards,
    closePointsCards,
    userAppliedPointsToPayment
};