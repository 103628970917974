import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import classNames from 'classnames';
import moment from 'moment';

import ResultsStore from '../../../../stores/ResultsStore';
import HotelStore from '../../../../stores/HotelStore';
import SearchStore from '../../../../stores/SearchStore';

import DealActions from '../../../../actions/DealActions';
import HotelActions from '../../../../actions/HotelActions';
import BasketActions from '../../../../actions/BasketActions';

import DealConstants from '../../../../constants/DealConstants';

import Currencies from '../../../../utils/Currencies';
import StringShortener from '../../../../utils/StringShortener';
import RouterWrapper from '../../../../utils/RouterWrapper';

import DealPrice from '../../../DealPrice.react';
import RepresentativeBookmark from './RepresentativeBookmark.react';
import GroupCountBookmark from './GroupCountBookmark.react';
import ProviderDealBookmark from './ProviderDealBookmark.react';
import MarginsBookmark from './MarginsBookmark.react';
import VirtualMarginsBookmark from './VirtualMarginsBookmark.react';
import DealRoomMapping from './DealRoomMapping.react';
import NegotiatedRate from '../../../general/NegotiatedRate.react';

import DealUtils from '../../../../entities/DealUtils';

import DealHelpers from './helpers';

import { Tooltip, Button } from '@mui/material';
import { Link } from 'react-router-dom';

import withStyles from '@mui/styles/withStyles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import Constants from './constants';

import Analytics from 'arbitrip-common/client/analytics';
import Config from 'arbitrip-common/client/utils/Config';
import ContentLoader from 'react-content-loader';
import ProfileStore from '../../../../stores/ProfileStore';
import { withRouter } from '../../../../utils/withRouter';
import { getRoomData } from 'arbitrip-common/client/utils/RoomData';
import { Dates as DateUtils } from 'arbitrip-common/client/utils';
import PriceBreakdown from 'arbitrip-common/client/utils/PriceBreakdown';
import DynamicPricingContainer from './DynamicPricingContainer';

const { NEWLINE, BOOKING_PROVIDER_STRING, HOTEL } = Constants;

const ADD_TO_BASKET_TEXT = 'Add to List';
const REMOVE_FROM_BASKET_TEXT = 'Remove from List';
const NOT_AVAILABLE_TEXT = 'Not Available';

const HtmlTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: '#ffffff',
		color: 'rgba(0, 0, 0, 0.87)',
		maxWidth: 320,
		fontSize: theme.typography.pxToRem(12),
		border: '1px solid #dadde9',
		marginBottom: 4,
		textAlign: 'center',
	},
}))(Tooltip);

const pluralize = (count, noun, suffix = 's') => `${count} ${noun}${count !== 1 ? suffix : ''}`;

class DealComponent extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			travel_policy: ResultsStore.getTravelPolicy(),
			hovered: false,
			book_hover: false,
			expediaRoomData: getRoomData(props.hotel, props.deal),
		};
	}

	componentDidUpdate(prevProps) {
		const { deal, hotel, index } = this.props;
		const dealStatus = deal?.status;

		if (prevProps.arbitripPointsApplied !== this.props.arbitripPointsApplied) {
			this.setState({ busy_applying_points: true });
			const _self = this;
			setTimeout(() => {
				_self.setState({ busy_applying_points: false });
			}, 1000);
		}

		if (this.props.hotel !== prevProps.hotel) {
			this.setState({
				expediaRoomData: getRoomData(this.props.hotel, this.props.deal),
			});
		}

		if (prevProps.deal?.status !== dealStatus) {
			if (index === 1) {
				if (
					dealStatus === DealConstants.DEAL_STATUS.NA ||
					dealStatus === DealConstants.DEAL_STATUS.FAIL ||
					deal.faultDeal
				) {
					Analytics.actions.responses.rateUnavailable(deal?.room, deal?.totalPrice, hotel?.name);
				}
			}
		}
	}

	handleOnSelectClick = () => {
		const { profile, searchTerms } = this.props;

		// TODO: hotel data to hotel + selected deal data to hotel
		HotelActions.getHotelData(this.props.hotel);
		if (this.props.deal) {
			DealActions.chooseDeal(this.props.deal);
		}

		const recommended_index = ResultsStore.calculateRecommendedIndex(this.props.hotel);
		HotelActions.setHotelRecommendedIndex(this.props.hotel.id, recommended_index);

		const recommended_count = ResultsStore.getRecommendedCount();
		const sort_method = ResultsStore.getSortMethod();
		const time_to_click_ml = ResultsStore.getTimePassedSinceSearchSessionCreatedInMilliSeconds();
		Analytics.actions.interactions.selectedHotel(
			profile,
			searchTerms.destination,
			searchTerms,
			recommended_index,
			recommended_count,
			sort_method,
			time_to_click_ml,
		);

		HotelActions.setHotelScroll(window.scrollY, this.props.hotel.id);
	};

	handleOnSelectDealsClick = () => {
		HotelActions.getHotelBookingDeals(this.props.hotel.id);

		// todo : should trigger price reloading (busy indication)

		// let eoda = $("#end-of-deals-anchor");
		// if (eoda.length) {
		//   eoda.velocity("scroll", {
		//     // container: $("#container"),
		//     duration: 800,
		//     delay: 500,
		//     offset: '-64px'
		//   });
		// } else {
		//   console.warn("didn't find end of deals anchor!");
		// }
	};

	handleOnBookClick = () => {
		const {
			navigate,
			profile,
			searchTerms,
			deal,
			roomMappingData,
			displayed_deal_order_count,
			reservationManipulation,
		} = this.props;

		if (roomMappingData) {
			deal.roomMappingData = roomMappingData;
		}

		if (Config.dev_mode || profile?.is_arbi_tester) {
			deal.reservation_manipulation = reservationManipulation;
		}

		DealActions.chooseDeal(deal);

		const deal_index = deal.deal_index;
		const deal_count = HotelStore.getDealCount();
		const total_displayed_deals_count = HotelStore.getTotalDisplayedDealsCount();
		const time_to_click_ml = ResultsStore.getTimePassedSinceSearchSessionCreatedInMilliSeconds();

		Analytics.actions.interactions.selectedDeal(
			profile,
			searchTerms.destination,
			searchTerms,
			deal,
			deal_index,
			deal_count,
			time_to_click_ml,
			displayed_deal_order_count,
			total_displayed_deals_count,
		);

		RouterWrapper.goToReservationPage(navigate);
	};

	handleOnShareClick = () => {
		const { travel_policy } = this.state;
		const travel_policy_exists = travel_policy && !!(travel_policy.default || travel_policy.place);
		const { hotel, deal, profile, contractClientCompanyId, searchTerms } = this.props;
		deal.place_id = searchTerms.destination.place.place_id;
		BasketActions.addHotelDeal(hotel, deal, travel_policy_exists, profile, contractClientCompanyId);
		Analytics.actions.interactions.addedToBasket(profile, hotel, deal);
	};

	handleOnRemoveFromListClick = () => {
		const { hotel, deal } = this.props;
		BasketActions.removeHotelDeal(deal);
		Analytics.actions.interactions.removedFromBasket(hotel, deal);
	};

	handleOnDealInfoWrapperClick = () => {
		if (Config.dev_mode) {
			console.log('deal: ', this.props.deal);
			if (
				this.props.deal.chooseprod &&
				this.props.deal.chooseprod.dca &&
				this.props.deal.chooseprod.dca.cancellationPoliciesText
			) {
				console.log('new cancellation sentence: ' + this.props.deal.chooseprod.dca.cancellationPoliciesText);
			}
		}
		// We always want a printout of this deal identifier
		console.log('S: ' + this.props.deal.id);
	};

	handleOnMouseEnter = () => {
		this.setState({
			hovered: true,
		});
	};

	handleOnMouseLeave = () => {
		this.setState({
			hovered: false,
		});
	};

	isInBasket = () => {
		const { basket, deal } = this.props;
		if (basket && Array.isArray(basket.deals)) {
			for (let basket_deal of basket.deals) {
				if (basket_deal.id === deal.id) {
					return true;
				}
			}
		}
		return false;
	};

	isBookingProviderDeal = () => {
		const { deal } = this.props;
		return DealUtils.isValidDeal(deal) && deal.id.startsWith(BOOKING_PROVIDER_STRING);
	};

	isDealStatusSuccessful = () => {
		return this.props.deal.status === 'success';
	};

	onBookMouseEnter = () => {
		this.setState({
			book_hover: true,
		});
	};

	onBookMouseLeave = () => {
		this.setState({
			book_hover: false,
		});
	};

	isPostPay() {
		return this.props.deal.post_pay;
	}

	openRoomDialog(room, name) {
		if (!room) return;
		const { showRoomDialog } = this.props;

		if (_.isFunction(showRoomDialog)) {
			showRoomDialog(room, name, Analytics.actions.views.roomInfoDialog);
		}
	}

	getRoomMappingName() {
		const { hotel, roomMappingData } = this.props;

		// if there is a room match and there is no no_match flag
		// update room name to match
		if (roomMappingData && !_.get(roomMappingData, 'debug.no_match')) {
			let rooms = _.get(hotel, 'rooms');
			if (rooms) {
				let room = _.find(rooms, (room) => room.id === roomMappingData.catalog_id);

				if (room) {
					let room_name;
					if (_.get(roomMappingData, 'debug.expedia_match', false)) {
						room_name = _.get(roomMappingData, 'debug.original_deal.name');
					} else {
						room_name = room.name;
					}
					let roomMatchNameShort = StringShortener.shortenString(room_name, 150);
					return (
						room &&
						room_name && (
							<span className="link" onClick={() => this.openRoomDialog(room)} title={room_name}>
								{roomMatchNameShort}
							</span>
						)
					);
				}
			}
		}

		return null;
	}

	getRoomNameRender() {
		const { deal, hotel, profile } = this.props;
		const deal_description = (deal && deal.description) || '';
		let ROOM_DESCRIPTION = StringShortener.shortenString(deal_description, 150);
		const dealStatus = deal && deal.status;
		const info = hotel.hotelInfo;
		const original_name = _.get(deal, 'original.details.room_description_original', '');
		const isSupport = Config.prod && profile.is_arbi_support;

		if (dealStatus === DealConstants.DEAL_STATUS.SUCCESS) {
			if (Config.dev_mode) {
				const room = _.find(info && info.rooms, { id: deal && deal.room_id });
				return (
					this.getRoomMappingName() || (
						<span
							title={deal_description + `(${original_name})`}
							onClick={() => this.openRoomDialog(room, deal_description)}
							className={classNames({ 'clickable-room': room })}
							style={{ color: room ? DealHelpers.getColor(deal.room_group) : 'inherit' }}
						>
							{ROOM_DESCRIPTION}
						</span>
					)
				);
			} else {
				return (
					this.getRoomMappingName() || (
						<span title={deal_description + `(${isSupport ? original_name : ''})`}>{ROOM_DESCRIPTION}</span>
					)
				);
			}
		} else {
			return (
				this.getRoomMappingName() || (
					<Link
						to={RouterWrapper.buildHotelRoute(
							this.props.hotel,
							this.props.searchToken,
							this.props.searchTerms,
						)}
					>
						<span
							title={deal_description + `(${original_name})`}
							onClick={this.handleOnSelectClick}
							className="clickable-room"
						>
							{ROOM_DESCRIPTION}
						</span>
					</Link>
				)
			);
		}
	}

	getMatchedHotelRender() {
		let { hotel } = this.props;

		return hotel.hotelMatch ? (
			<span className="message">
				It looks like there’s no availability for these dates.
				<br />
				But we got some great alternatives below.
			</span>
		) : (
			<span>It looks like there is no availability on these dates</span>
		);
	}

	getCancellation() {
		const { deal } = this.props;
		let CANCELLATION = null;

		if (deal) {
			if (!deal.id) {
				console.warn('unknown deal id: ', deal);
			} else {
				if (deal.chooseprod) {
					let CANCELLATION_TEXT = deal.chooseprod.dca.nonRefundable ? (
						<span>Non refundable</span>
					) : (
						<span>
							<span className="highlighted">Free cancellation&nbsp;</span>
							before {DateUtils.formatCancellationDeadline(deal.chooseprod.dca.dealIsFullyRefundableTill)}
						</span>
					);

					CANCELLATION = (
						<div key={deal.id + 'cancellation'} className="amenity">
							<span className="description">{CANCELLATION_TEXT}</span>
						</div>
					);
				} else if (deal.id.startsWith('S:')) {
					let CANCELLATION_TEXT = deal.refundable ? (
						<span>
							<span className="highlighted">Free cancellation&nbsp;</span>
							before {DateUtils.formatCancellationDeadline(deal.freeCancellationUntil)}
						</span>
					) : (
						<span>Non refundable</span>
					);
					CANCELLATION = (
						<div key={deal.id + 'cancellation'} className="amenity">
							<span className="description">{CANCELLATION_TEXT}</span>
						</div>
					);
				}
			}
		}

		return CANCELLATION;
	}

	getAmenities() {
		const { deal } = this.props;

		const BOARD_BASES_MAPPING = {
			'board_bases.all_inclusive': 'All Inclusive',
			'board_bases.full_board': 'Full Board',
			'board_bases.half_board': 'Half Board',
			breakfast: 'Breakfast included',
		};

		// Find the first matching board base and return the corresponding amenity
		for (const [key, description] of Object.entries(BOARD_BASES_MAPPING)) {
			if (_.get(deal, key)) {
				return (
					<div key={deal.id + '-breakfast'} className="amenity">
						<div className="icon breakfast" />
						<span className="description">{description}</span>
					</div>
				);
			}
		}

		return null;
	}

	getPriceDescription(text) {
		const { profile } = this.props;
		let className = 'tax-included';
		if (PriceBreakdown.isShowTotalPrice({ profile })) {
			className += ' tooltip-icon';
		}
		return <div className={className}>{text}</div>;
	}

	getBreakdownPriceDescription() {
		const { deal, profile } = this.props;
		const price = PriceBreakdown.isBreakdownPrice({ profile, deal })
			? deal.expedia_pricing.totalPrice
			: deal.totalPrice;
		if (!price) return null;
		if (PriceBreakdown.isBreakdownPrice({ profile, deal }) || PriceBreakdown.isShowTotalPrice({ profile })) {
			const checkIn = DateUtils.parseFormatDate(deal.check_in);
			const checkOut = DateUtils.parseFormatDate(deal.check_out);
			const nights = checkOut.diff(checkIn, 'days');

			return (
				<div className="tax-included align-right">
					{nights > 1 && (
						<div>
							<div>
								<span className="price">
									{Currencies.getPriceWithDisplayCurrencyByPrecision(
										price / nights,
										profile.display_currency_exchange_rate,
										profile.display_currency,
										0,
									)}
								</span>{' '}
								Avg/Night
							</div>
							{PriceBreakdown.isShowTotalPrice({ profile }) ? (
								this.getDealsRender()
							) : (
								<div>includes taxes & fees</div>
							)}
						</div>
					)}
				</div>
			);
		}
		return null;
	}

	getDealsRender() {
		const { deal } = this.props;
		const doesIncludeTax = deal && deal.does_price_include_tax;

		const tooltip_text = this.isPostPay()
			? 'Payment is handled by the property'
			: PriceBreakdown.TOTAL_PRICE_TOOLTIP_TEXT;

		return (
			<Tooltip title={tooltip_text} placement="top" arrow>
				{this.isPostPay() ? (
					this.getPriceDescription('Pay at Hotel')
				) : doesIncludeTax ? (
					this.getPriceDescription('Tax Included')
				) : (
					<div className="tax-not-included">Might not include taxes</div>
				)}
			</Tooltip>
		);
	}

	getResortFeeRender() {
		const resort_fee = _.get(this.props.hotel, 'fees.resort');

		return resort_fee ? (
			<HtmlTooltip title={resort_fee} placement="top">
				{this.getPriceDescription('Resort Fee')}
			</HtmlTooltip>
		) : null;
	}

	shouldDisplayPrice() {
		const { deal, display_price_without_recheck = false, representative = false } = this.props;

		if (display_price_without_recheck) {
			return true;
		}

		if (representative) {
			return true;
		}

		return deal.chooseprod; // don't display price unless after recheck
	}

	getBookAriaLabel = (roomName) => {
		const { profile, deal } = this.props;
		const display_currency = _.get(profile, 'display_currency');
		const display_currency_exchange_rate = _.get(profile, 'display_currency_exchange_rate');

		const totalPrice = Currencies.getPriceWithDisplayCurrencyByDefaultPrecision(
			PriceBreakdown.isBreakdownPrice({ profile, deal }) ? deal.expedia_pricing?.totalPrice : deal.totalPrice,
			profile.display_currency_exchange_rate,
			profile.display_currency,
			0,
		);

		const pricePerNight = Currencies.getPriceWithDisplayCurrencyByDefaultPrecision(
			deal.pricePerNight,
			display_currency_exchange_rate,
			display_currency,
			0,
		);

		const showTotalPrice = PriceBreakdown.isShowTotalPrice({ profile }) || profile.agent;
		return showTotalPrice
			? `Book ${roomName} for ${totalPrice} total`
			: `Book ${roomName} for ${pricePerNight} per night`;
	};

	render() {
		// eslint-disable-next-line react/prop-types
		const {
			add_to_list_disabled,
			deal,
			hotel,
			profile,
			mappingDebugMode,
			roomMappingData,
			arbitripPointsApplied,
			contractClientCompanyId,
		} = this.props;

		let optimized = _.get(roomMappingData, 'optimized');
		let optimizedPrice = optimized ? _.get(roomMappingData, 'new_price') : null;

		const hide_basket = _.get(profile, 'company.partners_ui.hide_basket', false);

		const { hovered, expediaRoomData } = this.state;

		const faultDeal = deal && deal.faultDeal;
		const dealStatus = deal && deal.status;
		const notAvailable =
			dealStatus === DealConstants.DEAL_STATUS.NA || dealStatus === DealConstants.DEAL_STATUS.FAIL || faultDeal;
		const deal_description = (deal && deal.description) || '';
		let ROOM_DESCRIPTION = StringShortener.shortenString(deal_description, 150);

		const company_name = _.get(profile, 'company.name', null);
		const block_reserving = profile.blocked_reserving && !SearchStore.isPrivateTravel();

		const inBasket = this.isInBasket();
		const blocked_reserving_click_handler = add_to_list_disabled
			? _.noop
			: inBasket
				? this.handleOnRemoveFromListClick
				: this.handleOnShareClick;
		const blocked_reserving_text = inBasket ? REMOVE_FROM_BASKET_TEXT : ADD_TO_BASKET_TEXT;
		const button_classes = block_reserving ? ' block-reserving' + (inBasket ? ' active' : '') : '';
		const book_button_text = block_reserving ? blocked_reserving_text : notAvailable ? NOT_AVAILABLE_TEXT : 'Book';

		// We have a deal, continue with Render method ->

		const CANCELLATION = this.getCancellation();
		const AMENITIES = this.getAmenities();

		// const cur = Currencies.getSymbol(deal.currency);

		// TODO: UNMOCK
		const travelPolicy = this.state.travel_policy;
		const doesTravelPolicyExist = travelPolicy && (travelPolicy.default || travelPolicy.place);
		// const isInPolicy = doesTravelPolicyExist && (deal.pricePerNight <= travelPolicy.price);
		const isInPolicy = deal.in_policy;
		const travelPolicyCurrency = doesTravelPolicyExist ? Currencies.getSymbol(travelPolicy.currency) : '';
		const travelPolicyLocation =
			travelPolicy && travelPolicy.default
				? 'this destination'
				: travelPolicy && travelPolicy.place
					? travelPolicy.place.city || travelPolicy.place.state || travelPolicy.place.country
					: '';
		const travelPolicyText = doesTravelPolicyExist
			? deal.out_of_policy_message ||
				'The maximum price allowed for ' +
					travelPolicyLocation +
					' is ' +
					(travelPolicyCurrency + travelPolicy.price) +
					' per night.'
			: null;

		const splitTravelPolicyText = travelPolicyText ? travelPolicyText.split(NEWLINE) : null;

		const dealIdPolicy = 'deal-' + deal.id + '-policy';
		const tooltip = (
			<div className="policy-tooltip-container">
				{splitTravelPolicyText
					? splitTravelPolicyText.map((line, idx) => <span key={dealIdPolicy + (idx + 1)}>{line}</span>)
					: null}
			</div>
		);

		const previousPrice =
			Config.dev_mode && deal.previousPricePerNight
				? `the old price per night: ${deal.previousPricePerNight}`
				: '';
		const originalCurrencyPrice =
			Config.dev_mode && deal.currency !== profile.display_currency
				? `the price in original currency: ${deal.currency}${deal.pricePerNight}`
				: '';
		// TODO! diff according to abs esplion?
		const hasPriceChanged =
			Config.dev_mode && deal.previousPricePerNight && deal.previousPricePerNight !== deal.pricePerNight;

		// const currency = cur;
		const shouldDisplayPrice = this.shouldDisplayPrice();

		const arbitrip_points_reduction = arbitripPointsApplied ? _.get(profile, 'bank.balance', 0) : 0;

		let price_classes = 'deal-price flex ';
		if (!PriceBreakdown.isBreakdownPrice({ profile, deal })) {
			price_classes += 'align-center';
		}

		let deal_price_classes = 'deal-price-container flex flex-end ';
		if (!PriceBreakdown.isBreakdownPrice({ profile, deal })) {
			deal_price_classes += 'fixed-height';
		}

		let DEAL_PRICE = (
			<div className={deal_price_classes}>
				{/* uncomment for Room Details functionality */}
				{/* {expediaRoomData && <div className="room-details" onClick={() => this.props.roomDetailsClickHandler(expediaRoomData)}>
                    Room details
                </div>} */}
				<div className="policy-average-wrapper flex flex-column align-flex-end">
					{PriceBreakdown.isBreakdownPrice({ profile, deal }) ||
					PriceBreakdown.isShowTotalPrice({ profile }) ? null : (
						<div className="avg-room-night">Avg/Room/Night</div>
					)}
					<div className="flex">
						{shouldDisplayPrice && !PriceBreakdown.isBreakdownPrice({ profile, deal }) ? (
							doesTravelPolicyExist ? (
								<Tooltip title={tooltip} placement="top-end" arrow>
									<div className="is-in-policy">
										<div className={isInPolicy ? 'in-policy icon' : 'out-of-policy icon'}></div>
										<div className={isInPolicy ? 'in-policy text' : 'out-of-policy text'}>
											<span>{isInPolicy ? 'In Policy' : 'Out of Policy'}</span>
										</div>
									</div>
								</Tooltip>
							) : null
						) : null}
						{PriceBreakdown.isBreakdownPrice({ profile, deal }) ||
						PriceBreakdown.isShowTotalPrice({ profile })
							? null
							: this.getDealsRender()}
					</div>
				</div>
				{this.state.busy_applying_points ? (
					<ContentLoader width={124} height={32} viewBox="0 0 160 32">
						<rect x="0" y="0" rx="0" yx="0" width="160" height="32" />
					</ContentLoader>
				) : (
					<React.Fragment>
						{ProfileStore.hasArbitripPoints() && arbitripPointsApplied && (
							<div className="old-price">
								<span>
									{Currencies.getPriceWithDisplayCurrencyByPrecision(
										faultDeal ? deal.previousPricePerNight : optimizedPrice || deal.pricePerNight,
										profile.display_currency_exchange_rate,
										profile.display_currency,
										0,
									)}
								</span>
							</div>
						)}
						<div className={price_classes}>
							<NegotiatedRate
								companyName={company_name}
								show={!!deal.negotiated_rate}
								rawCode={deal.original_negotiated_rate || ''}
								code={deal.negotiated_rate || ''}
								codeDisplayName={deal.negotiated_rate_display_name || ''}
							/>
							<div className="price-per-night">
								{shouldDisplayPrice ? (
									PriceBreakdown.isBreakdownPrice({ profile, deal }) ? (
										<div>
											{Currencies.getPriceWithDisplayCurrencyByDefaultPrecision(
												deal.expedia_pricing?.totalPrice,
												profile.display_currency_exchange_rate,
												profile.display_currency,
											)}

											{this.getBreakdownPriceDescription()}
										</div>
									) : PriceBreakdown.isShowTotalPrice({ profile }) ? (
										<div>
											{Currencies.getPriceWithDisplayCurrencyByDefaultPrecision(
												deal.totalPrice,
												profile.display_currency_exchange_rate,
												profile.display_currency,
											)}

											{this.getBreakdownPriceDescription()}
										</div>
									) : profile.agent ? (
										<DealPrice
											hasPriceChanged={hasPriceChanged}
											previousPrice={previousPrice}
											displayTotalPrice={true}
											supplier={deal.supplier_name}
											currency={deal.currency}
											price={deal.pricePerNight}
											totalPrice={deal.totalPrice}
											netPrice={deal.net_price_total}
											dynamicPricing={deal.dynamicPricing}
											commission={deal.commission_total}
											dealId={deal.id}
											faultDeal={faultDeal}
											profile={profile}
											contractClientCompanyId={contractClientCompanyId}
										/>
									) : (
										<div>
											<span
												title={
													Config.dev_mode &&
													(previousPrice + (deal.currency !== profile.display_currency)
														? ' | ' + originalCurrencyPrice
														: '')
												}
												// style={Config.dev_mode && hasPriceChanged ? { color: 'purple' } : {}}
												className={classNames(faultDeal && 'fault-deal')}
											>
												<span
													className={classNames(
														mappingDebugMode && optimizedPrice && 'optimized',
													)}
												>
													{Currencies.getPriceWithDisplayCurrencyByDefaultPrecision(
														(faultDeal
															? deal.previousPricePerNight
															: optimizedPrice || deal.pricePerNight) -
															arbitrip_points_reduction,
														profile.display_currency_exchange_rate,
														profile.display_currency,
													)}
												</span>

												{mappingDebugMode &&
													optimizedPrice &&
													arbitrip_points_reduction > 0 && (
														<span className="old-price">
															{Currencies.getPriceWithDisplayCurrencyByDefaultPrecision(
																faultDeal || optimizedPrice
																	? deal.previousPricePerNight
																	: deal.pricePerNight,
																profile.display_currency_exchange_rate,
																profile.display_currency,
															)}
														</span>
													)}
											</span>
											<DynamicPricingContainer
												dynamicPricing={deal.dynamicPricing}
												profile={profile}
												currency={deal.currency}
											/>
										</div>
									)
								) : null}
							</div>
						</div>

						{shouldDisplayPrice &&
						ProfileStore.isLeisureExperienceEnabled() &&
						arbitrip_points_reduction > 0 ? (
							<HtmlTooltip title="Your points are applied" placement="top">
								<InfoOutlinedIcon style={{ fontSize: 12 }} />
							</HtmlTooltip>
						) : null}
					</React.Fragment>
				)}
			</div>
		);

		const DEAL_ACTION =
			this.isDealStatusSuccessful() && !faultDeal ? (
				<div key="book-deal" className={'deal-action book-action'}>
					<Button
						aria-label={this.getBookAriaLabel(ROOM_DESCRIPTION)}
						className={'book-btn' + button_classes}
						variant="contained"
						color="primary"
						onClick={block_reserving ? blocked_reserving_click_handler : this.handleOnBookClick}
						disabled={block_reserving}
						onMouseEnter={this.onBookMouseEnter}
						onMouseLeave={this.onBookMouseLeave}
						disableRipple
					>
						{book_button_text}
					</Button>
				</div>
			) : (
				<div key="disabled-book-deal" className={'deal-action book-action'}>
					<Button
						className="book-btn default-style"
						variant="contained"
						color="primary"
						disabled
						aria-disabled="true"
					>
						{book_button_text}
					</Button>
				</div>
			);

		const ADD_TO_BASKET_ACTION =
			hovered && !block_reserving && !faultDeal && !hide_basket ? (
				inBasket ? (
					<a
						href="#!"
						className="deal-action share-action"
						onClick={add_to_list_disabled ? _.noop : this.handleOnRemoveFromListClick}
						key="remove-deal"
						disabled={add_to_list_disabled}
					>
						{REMOVE_FROM_BASKET_TEXT}
					</a>
				) : (
					<a
						href="#!"
						className="deal-action share-action"
						onClick={add_to_list_disabled ? _.noop : this.handleOnShareClick}
						key="share-deal"
						disabled={add_to_list_disabled}
					>
						{ADD_TO_BASKET_TEXT}
					</a>
				)
			) : null;

		const actionItems = this.isDealStatusSuccessful() ? [ADD_TO_BASKET_ACTION, DEAL_ACTION] : [DEAL_ACTION];

		let DEAL_ACTION_OR_SPINNER = <div className="deal-actions flex align-center">{actionItems}</div>;

		const BOOKING_LOGO =
			profile.show_supplier_logo_next_to_rate_enabled && this.isBookingProviderDeal() ? (
				<img className="booking-logo" src="https://arbitrip.s3.amazonaws.com/booking_logo.png" />
			) : null;

		let CANCEL_POLICY = deal && deal.chooseprod ? CANCELLATION : null;

		switch (dealStatus) {
			case DealConstants.DEAL_STATUS.INITIAL:
			case DealConstants.DEAL_STATUS.BUSY:
				DEAL_ACTION_OR_SPINNER = (
					<div className="checking-availability">
						<i title="Checking Availability..." className="fa fa-spin fa-spinner" />
						<span>Checking Availability...</span>
					</div>
				);
				break;
			case DealConstants.DEAL_STATUS.SUCCESS:
				break;
			case DealConstants.DEAL_STATUS.FAIL:
			case DealConstants.DEAL_STATUS.NA:
				CANCEL_POLICY = (
					<div key={deal.id + 'not-available'} className="amenity">
						<i className="arbi-icon fa fa-ban" aria-hidden="true" title={deal.error} />
						<span className="description"> This deal is not available</span>
					</div>
				);
				break;
			default:
		}

		if (this.props.booking_deals_loading) {
			DEAL_ACTION_OR_SPINNER = (
				<div className="checking-availability">
					<i title="Getting Deals..." className="fa fa-spin fa-spinner" />
					<span>Getting Deals...</span>
				</div>
			);
		}

		let WRAPPER_CLASS = 'deals-wrapper hotel-deal';

		if (hovered && !faultDeal) {
			WRAPPER_CLASS += ' hovered';
		}

		let room_name_classes = 'room-name';

		// Coloring for easy debug
		let debugStyle = {};
		if (Config.dev_mode) {
			/*if (this.isPostPay()) {
                debugStyle.background = 'rgba(0, 255, 0, 0.25)';
            } else*/
			// debugStyle['borderRadius'] = '4px';
			// if (deal.src_initial === 'A') {
			//     debugStyle.border = '1px solid aqua';
			// } else if (deal.src_initial === 'P') {
			//     debugStyle.border = '1px solid purple';
			// } else if (deal.src_initial === 'H') {
			//     debugStyle.border = '1px solid $ff69b4'; // hot pink
			// } else if (deal.src_initial === 'S') {
			//     debugStyle.border = '1px solid yellow';
			// } else if (deal.src_initial === 'R') {
			//     debugStyle.border = '1px solid red';
			// } else if (deal.src_initial === 'E') {
			//     debugStyle.border = '1px solid #ccff00'; // electric lime
			// } else {
			//     debugStyle.border = '1px solid green';
			// }
		}

		let deal_classes = 'deal single';
		if (this.props.booking_deals_loading) {
			deal_classes += ' loading';
		}

		let deal_info_classes = 'deal-info flex';
		if (PriceBreakdown.isBreakdownPrice({ profile: this.props.profile, deal })) {
			deal_info_classes += ' expanded';
		}

		const display_dev = Config.dev_mode || (Config.prod && profile.is_arbi_support);

		if (display_dev && deal.superseded) {
			room_name_classes += ' superseded';
		}

		const roomData = expediaRoomData || deal.room_group_details;

		return (
			<div
				className={WRAPPER_CLASS}
				onMouseOver={this.handleOnMouseEnter}
				onMouseEnter={this.handleOnMouseEnter}
				onMouseLeave={this.handleOnMouseLeave}
				style={display_dev ? { borderLeft: '10px solid ' + DealHelpers.getColor(deal.room_group) } : {}}
			>
				{display_dev && (
					<GroupCountBookmark
						count={deal.room_group_count}
						name={deal.room_group_description}
						display_debugging={display_dev}
					/>
				)}

				{Config.dev_mode && this.props.representative && <RepresentativeBookmark />}

				{display_dev && (
					<React.Fragment>
						<ProviderDealBookmark deal={deal} display_debugging={display_dev} />
						<MarginsBookmark hotel={hotel} />
						<VirtualMarginsBookmark hotel={hotel} />
					</React.Fragment>
				)}
				<div className={deal_classes} style={debugStyle}>
					<div className={deal_info_classes} onClick={this.handleOnDealInfoWrapperClick}>
						<div className="deal-policy-features flex flex-column space-between">
							<div className="flex align-center">
								<div className={room_name_classes}>
									{this.getRoomNameRender()}
									{mappingDebugMode && (
										<DealRoomMapping
											deals={hotel.deals}
											deal={deal}
											roomMappingData={roomMappingData}
										/>
									)}
								</div>
							</div>
							{CANCEL_POLICY}
							<div className="flex">
								{AMENITIES}

								{roomData && (
									<>
										{AMENITIES && <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>}
										<button
											aria-label={`${ROOM_DESCRIPTION} - Room details`}
											className="room-details"
											onClick={() =>
												this.props.roomDetailsClickHandler(
													roomData,
													deal.description,
													Analytics.actions.views.roomInfoDialog,
												)
											}
										>
											Room details
										</button>
									</>
								)}
							</div>
						</div>
						<div className="deal-price-wrap flex flex-column space-between">
							{DEAL_PRICE}
							{DEAL_ACTION_OR_SPINNER}
							{BOOKING_LOGO}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

DealComponent.propTypes = {
	deal: PropTypes.any,
	hotel: PropTypes.any,
	basket: PropTypes.any,
	searchToken: PropTypes.any,
	searchTerms: PropTypes.any,
	add_to_list_disabled: PropTypes.any,
	booking_deals_loading: PropTypes.any,
	representative: PropTypes.any,
	showRoomDialog: PropTypes.func,
	mappingDebugMode: PropTypes.bool,
	roomMappingData: PropTypes.any,
	roomDetailsClickHandler: PropTypes.func,
};

export default withRouter(DealComponent);
