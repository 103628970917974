import React from 'react';

import FiltersStore from '../../../../stores/FiltersStore';
import FilterActions from '../../../../actions/FiltersActions';
import FiltersActions from '../../../../actions/FiltersActions';

import FiltersConstants from '../../../../constants/FiltersConstants';
import Analytics from 'arbitrip-common/client/analytics';

import FilterCheckboxWithLabel from './components/FilterCheckboxWithLabel.react';

class AmenitiesFilter extends React.Component {
	constructor(props) {
		super(props);

		this.handleResetAmenitiesClick = this.handleResetAmenitiesClick.bind(this);
		this.handleCheckboxChangeByAmenity = this.handleCheckboxChangeByAmenity.bind(this);
		this.handleAmenityOnlyClick = this.handleAmenityOnlyClick.bind(this);
		this.updateBreakfastFilter = this.updateBreakfastFilter.bind(this);
	}

	componentDidMount() {
		// update breakfast filter on hotel page when component is mount
		this.updateBreakfastFilter();
	}

	updateBreakfastFilter() {
		// update the breakfast filter in the deal page
		const results_breakfast_filter = this.props.filter[FiltersConstants.AMENITIES.BREAKFAST_INCLUDED];
		if (FiltersStore.getDealsBreakfastFilterData() !== results_breakfast_filter) {
			setTimeout(
				() =>
					// dispatch in middle of dispatcher patch
					FilterActions.filterDealsByBreakfastOnly(results_breakfast_filter),
				1,
			);
		}
	}

	handleCheckboxChangeByAmenity(event, isInputChecked, amenity) {
		const { profile } = this.props;

		event.persist();
		FiltersActions.filterByAmenity(amenity, isInputChecked);
		setTimeout(function () {
			FiltersActions.filterByAmenity(amenity, isInputChecked);
		}, 10);

		const filter_value = (isInputChecked ? 'Filter by "' : 'Don\'t filter by "') + amenity + '"';
		Analytics.actions.interactions.filteredResults(profile, FiltersConstants.FILTER_METHOD.AMENITIES, filter_value);

		if (amenity === FiltersConstants.AMENITIES.BREAKFAST_INCLUDED) {
			// update breakfast filter on hotel page when breakfast clicked
			this.updateBreakfastFilter();
		}
	}

	handleAmenityOnlyClick(e) {
		const { profile } = this.props;

		e.persist();
		var filter_amenity_only = e.target.getAttribute('data-amenity');
		FiltersActions.filterByAmenityOnly(filter_amenity_only);
		setTimeout(function () {
			FiltersActions.filterByAmenityOnly(filter_amenity_only);
		}, 10);

		var filter_value = 'Show Only "' + filter_amenity_only + '" Amenity';
		Analytics.actions.interactions.filteredResults(
			profile,
			FiltersConstants.FILTER_METHOD.AMENITY_ONLY,
			filter_value,
		);
	}

	handleResetAmenitiesClick(e) {
		const { profile } = this.props;

		e.persist();
		FiltersActions.resetAmenitiesFilter();
		setTimeout(function () {
			FiltersActions.resetAmenitiesFilter();
		}, 10);

		const filter_value = 'Reset Amenities Filter';
		Analytics.actions.interactions.filteredResults(
			profile,
			FiltersConstants.FILTER_METHOD.RESET_AMENITIES_FILTER,
			filter_value,
		);
	}

	render() {
		const data = this.props.filter;
		const multiple_amenities = Object.keys(data).length > 1;

		const AMENITIES = [];
		for (let amenity in data) {
			const amenity_key = amenity.toLowerCase().replace(' ', '-');
			AMENITIES.push(
				<div className="checkbox-container" key={amenity_key}>
					<FilterCheckboxWithLabel
						checked={data[amenity]}
						category={amenity}
						label={FiltersConstants.AMENITY_FILTERS_TITLES[amenity]}
						onChange={(e, c, amenity) => this.handleCheckboxChangeByAmenity(e, c, amenity)}
					/>

					{multiple_amenities ? (
						<button
							className="btn btn-link"
							data-amenity={amenity}
							onClick={this.handleAmenityOnlyClick}
							aria-label={`Select ${FiltersConstants.AMENITY_FILTERS_TITLES[amenity]} only`}
						>
							Only
						</button>
					) : null}
				</div>,
			);
		}

		return (
			<div className="filter disabled">
				<label className="filter-title" htmlFor="amenities-filter">
					Amenities
				</label>
				<div name="amenities-filter" className="check-list" aria-label="Amenities filters">
					{AMENITIES}
				</div>
				{AMENITIES.length > 1 ? (
					<button
						className="clear-button-wrap"
						onClick={this.handleResetAmenitiesClick}
						aria-label="Reset amenities"
					>
						<div className="clear-button-img" />
					</button>
				) : null}
			</div>
		);
	}
}

export default AmenitiesFilter;
