const FAQ = [
    {
        title: 'About Us',
        secondary: [
            {
                description: 'Arbitrip is a travel technology company operating since 2015.\n\n' +
                    'The company has developed a hotel booking technology that finds the best-fit hotels for each search according to many parameters, while guaranteeing finding an affordable price through access to deeply discounted wholesale prices.\n\n' +
                    'The company grew in the world of business travel and today works successfully with thousands of companies around the world.\n\n' +
                    'Arbitrip recently began operating in the field of private travel through the advanced technology it developed and the great commercial power it gained in the business world.\n\n' +
                    'We will be happy to be at your service!'
            }
        ]
    },
    {
        title: 'Can a booking be modified? For example, name or date change? How?',
        description: 'Depending on the case, there are a few options for modifications',
        secondary: [
            {
                title: 'If the booking is still within the free cancellation period',
                description: 'A new booking must be made with the preferred details. Once the new booking is confirmed, cancel the old reservation that is still eligible for free cancellation.'
            },
            {
                title: 'If the booking is non-refundable or within the full-penalty period',
                description: 'Contact our Customer Service team (via the ‘Contact Us’ page) and we will contact the appropriate hotel or supplier with the request. It is important to note that this decision is ultimately up to the hotel and may take a few days.'
            }
        ]
    },
    {
        title: 'Is it possible to add additional nights to an existing reservation?',
        description: 'Depending on the case, there are a few options for an extension of stay',
        secondary: [
            {
                title: 'If the booking is still within the free cancellation period',
                description: 'A new booking can be made with the preferred dates. Once the new booking is confirmed, cancel the old reservation that is still eligible for free cancellation.'
            },
            {
                title: 'If the original booking cannot be canceled or there is another reason you do not want to cancel it',
                description: 'A separate booking must be created for the extra nights needed with the same room type. Note in the ‘special request’ section that this booking is an extension of an existing booking. Our Customer service team will reach out to the hotel to request the traveler is kept in the same room throughout their stay. If a different room type is booked for the extra nights, the traveler will have to change rooms during their stay.'
            }
        ]
    },
    {
        title: 'I booked a room-only reservation, is it possible to add breakfast?',
        description: 'Depending on the case, there are a few options for adding breakfast',
        secondary: [
            {
                title: 'If the booking is still within the free cancellation period',
                description: 'A new booking can be made with breakfast included. Once the new booking is confirmed, cancel the old reservation that is still eligible for free cancellation.'
            },
            {
                title: 'Otherwise',
                description: 'contact the hotel directly to coordinate the addition of breakfast to a reservation. Payment of breakfast will need to be handled on-site.'
            }
        ]
    },
    {
        title: 'How can I cancel a booking I made?',
        description: 'Depending on the case, there are a few options for cancellation',
        secondary: [
            {
                title: 'If the booking is still within the free cancellation period',
                description: 'The booking can be canceled via the ‘my bookings’ page on our platform. Select the booking and select ‘cancel reservation‘.'
            },
            {
                title: 'If the booking is non-refundable or within the full-penalty period',
                description: 'Contact our Customer Service team (via the ‘Contact Us’ page) and we will contact the hotel or supplier to negotiate a partial/full refund on the traveler\'s behalf. It is important to note that, while we will do our best to negotiate on behalf of the traveler, any waiver of penalty is ultimately up to the hotel. Please provide a reason or support documentation to further assist in negotiation with the hotel (i.e. flight cancellation email, Positive COVID test, etc…).'
            }
        ]
    },
    {
        title: 'can\'t find a specific hotel I want on the platform. Can you help me?',
        secondary: [
            {
                description: ' If the hotel is fully booked it will not show up in the search results. If there is uncertainty, please contact the Customer Service Team (via the ‘Contact Us’ page) with the name of the hotel, dates, and number of rooms you need and we will check why you are unable to find it. \n' +
                    'Sometimes the hotel changes ownership or name and that is why it does not show in our system under the new name yet. Sometimes the hotel only allows a certain number of rooms to be sold through a third-party supplier. There can be many different reasons.'
            }
        ]
    },
    {
        title: ' I have arrived at the hotel and they are requesting I pay for my reservation. Why is this happening and can you help me?',
        secondary: [
            {
                description: 'This is unusual and should not happen. If the hotel is not seeing the pre-payment of your reservation, contact our Customer Service team immediately (via the ‘Contact Us’ page). We will contact the hotel or supplier as soon as possible to try and sort out the issue before the travel has to pay anything directly.\n' +
                    'Sometimes this happens due to a communication error between the hotel system and the supplier system and can be sorted out fairly quickly.\n' +
                    'If the hotel has charged the traveler\'s personal credit card already, please send us the receipt of the charge so we can request a refund of the traveler\'s card once we have sorted out the payment by us. Please note, This may take some time depending on each hotels accounting and management procedures.'
            }
        ]
    },
    {
        title: ' I decided to check out early, is it possible to get a refund for the unused night/s?',
        secondary: [
            {
                description: 'If you have to leave your booking early, contact the Customer Service team (via the ‘Contact Us’ page) and provide us with the time and reason you are leaving early. We will request a refund for the unused nights but ultimately it is up to the hotel. As this is a post-stay request it may take longer than usual. \n' +
                    '\n' +
                    'A helpful tool to further this request is if the traveler speaks with someone at the hotel prior to their departure to note their exact time of departure and request approval of any sort of refund for the unused nights. Then, it is most helpful to let us know the name of the representative at the hotel they spoke with that approved a refund for the early check-out.'
            }
        ]
    },
    {
        title: 'I need to check in early, how can I guarantee my room will be ready?',
        secondary: [
            {
                description: 'The only way to guarantee an early check-in is by booking the previous night and noting the estimated time of arrival. Otherwise, early check-in is based on availability on the day of arrival and is up to the hotel\'s discretion and policy.'
            }
        ]
    },
    {
        title: 'I need a late check-in, how can I ensure the hotel will keep my reservation?',
        secondary: [
            {
                description: 'Many hotels will mark a traveler as ‘NOSHOW’ and resell the room if they do not arrive after a certain amount of time without notice. It is incredibly important to inform us or the hotel of any late arrival after the standard check-in hours. \n' +
                    '\n' +
                    'When booking, add the estimated time of arrival to the ‘special request’ section on the final booking page. \n' +
                    '\n' +
                    'Or,  contact the Customer Success team (via the ‘Contact Us’ page) with the booking details and the estimated time of arrival. We will contact the hotel and ensure the late arrival is noted and the room will be kept for the traveler.'
            }
        ]
    },
    {
        title: 'How can I add my Membership Number?',
        secondary: [
            {
                description: 'Add the Hotel Membership ID in the ‘special request’ section of the final booking page. Or,  contact the Customer Success team (via the ‘Contact Us’ page) with the booking info and membership ID. We will forward the information to the hotel. Please note that it is not guaranteed that the hotel will honor the use of their membership ID for rewards for a booking on a third-party platform like Arbitrip. We recommend reiterating the request upon check-in.'
            }
        ]
    }
    // {
    //     title: '',
    //     description: '',
    //     secondary: [
    //         {
    //             title: '',
    //             description: ''
    //         }
    //     ]
    // },
];

module.exports = {
    FAQ
};
