const _ = require('lodash');

const ProtocolStripper = require('./ProtocolStripper');
const { invalid_caption_patterns} = require('../../general/constants/regexes')

const fallback_text = '-property-images';
const fallback_pattern = new RegExp(fallback_text, 'i');

const FALLBACK_IMAGE = '/img/hotel_page/hotel_place_h@2x.png';

function enhanceImageWithFallbackIfNeeded(gallery_image, prop) {
  if (gallery_image[prop] && gallery_image[prop].match(fallback_pattern)) {
    gallery_image[prop + '_fallback'] = '//media.travsrv.com' + gallery_image[prop].split(fallback_text)[1];
  }
}

function transformImagesToGallery(input_images) {
  let images = [];
  if (Array.isArray(input_images)) {
    input_images.sort(function (a, b) {
      return a.display_order - b.display_order;
    });

    input_images.forEach(function (img) {
      function isCaptionInvalid(pattern) {
        return pattern.test(img.caption);
      }

      if (img.original && img.thumbnail) {
      // if (img.original && img.originalClass) {
        // if new object exists
        // no need to run the process again on the image
        images.push(img);
        return;
      }

      let gallery_image = {
        original: ProtocolStripper.stripProtocol(img.source_path),
        thumbnail: ProtocolStripper.stripProtocol(img.thumbnail_path),
        originalClass: "gallery-original",
        thumbnailClass: "gallery-thumbnail"
      };

      enhanceImageWithFallbackIfNeeded(gallery_image, 'original');
      enhanceImageWithFallbackIfNeeded(gallery_image, 'thumbnail');

      if (!invalid_caption_patterns.some(isCaptionInvalid)) {
        gallery_image.description = img.caption;
      }

      images.push(gallery_image);
    });
  }
  return images;
}

function getHotelImagesCSSBackground(hotel, fallbackImage = FALLBACK_IMAGE) {
  const original = _.get(hotel, 'images.0.original');
  let backgrounds = [];

  if (original) {
    backgrounds.push(original);
  }

  if (hotel.image) {
    backgrounds.push(hotel.image);
  }

  backgrounds.push(fallbackImage);

  return backgrounds.map((bg) => `url(${bg})`).join();
}

function getHotelImage(hotel) {
  const original = _.get(hotel, 'images.0.original');

  if (original) {
    return `https:${original}`;
  }

  if (hotel.image) {
    return `https:${hotel.image}`;
  }
  return null;
}

// function renderImageTagWithFallback(image_source, fallback_source) {
//   if (!image_source) {
//     return;
//   }
//
//   return (
//       <img src={image_source} alt=""
//            onError={(e) => {
//              e.target.onerror = null;
//              e.target.src = fallback_source;
//            }}
//       />
//   );
// }

module.exports = {
  transformImagesToGallery,
  enhanceObjectWithFallback: (object, prop) => { enhanceImageWithFallbackIfNeeded(object, prop) },
  getHotelImagesCSSBackground,
  getHotelImage,
  FALLBACK_IMAGE
};
