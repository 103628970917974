const i18n = require('i18next');
const { initReactI18next } = require('react-i18next');
const { SUPPORTED_LANGUAGES } = require('./const');
const resources = require('./desktop/index');

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: SUPPORTED_LANGUAGES.EN, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

module.exports = i18n;
