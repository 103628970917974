import AppDispatcher from '../dispatcher/AppDispatcher';
import DestinationsConstants from '../constants/DestinationsConstants';
import Ajax from '../utils/Ajax';
import Analytics from 'arbitrip-common/client/analytics';

var DestinationsActions = {
	getDestinations: function (contract_id) {
		AppDispatcher.handleAction({
			actionType: DestinationsConstants.RECEIVE_DESTINATIONS_DATA,
		});

		Ajax.getBusinessDestinations(contract_id)
			.done(function (res) {
				AppDispatcher.handleAction({
					actionType: DestinationsConstants.DESTINATION_RETRIEVED,
					data: {
						destinations: res,
						contract_id,
					},
				});
			})
			.fail(function (err) {
				console.error('Error while getting business destinations: ', err);
				AppDispatcher.handleAction({
					actionType: DestinationsConstants.DESTINATIONS_RETRIEVAL_FAILED,
					data: err, // ???
				});
			});
	},

	addDestination: function (business_destination) {
		AppDispatcher.handleAction({
			actionType: DestinationsConstants.ADD_DESTINATION,
		});
		Ajax.addBusinessDestination(business_destination)
			.done(function (res) {
				console.log('Added business destination: ', res);
				AppDispatcher.handleAction({
					actionType: DestinationsConstants.DESTINATION_ADDED,
					data: {
						response: res,
						business_destination: business_destination,
					},
				});

				Analytics.actions.interactions.addedBusinessDestination(business_destination.destination);
			})
			.fail(function (err) {
				console.error('Error while adding business destination: ', err);
				AppDispatcher.handleAction({
					actionType: DestinationsConstants.DESTINATION_ADDITION_FAILED,
					data: err, // ???
				});

				Analytics.actions.interactions.addedBusinessDestinationFailed(business_destination.destination, err);
			});
	},

	removeDestination: function (business_destination) {
		AppDispatcher.handleAction({
			actionType: DestinationsConstants.REMOVE_DESTINATION,
		});
		Ajax.removeBusinessDestination(business_destination)
			.done(function (res) {
				console.log('Removed business destination: ', res);
				AppDispatcher.handleAction({
					actionType: DestinationsConstants.DESTINATION_REMOVED,
					data: res, // ???
				});
			})
			.fail(function (err) {
				console.error('Error while removing business destination: ', err);
				AppDispatcher.handleAction({
					actionType: DestinationsConstants.DESTINATION_REMOVAL_FAILED,
					data: err, // ???
				});
			});
	},

	chooseDestination: function (data) {
		AppDispatcher.handleAction({
			actionType: DestinationsConstants.CHOOSE_DESTINATION,
			data: data,
		});
	},
};

export default DestinationsActions;
