import { useState, useEffect } from 'react';

import ManagementStore from '@/stores/ManagementStore';
import ProfileStore from '@/stores/ProfileStore';
import ManagementConstants from '@/constants/ManagementConstants';

import PreviewDetailedAirfare from './PreviewDetailedAirfare.react';
import PreviewDetailedAccommodation from './PreviewDetailedAccommodation.react';
import PreviewTripSummary from './PreviewTripSummary.react';
import PreviewAirfareTips from './preview-pane/PreviewAirfareTips';

function getPreviewPaneState() {
	return {
		profile: ProfileStore.getProfile(),
		trip: ManagementStore.getTripRequest(),
		previewed_booked_component: ManagementStore.getPreviewedBookedComponent(),
		previewed_tips_source: ManagementStore.getPreviewedTipsSource(),
	};
}

const PreviewPane = (props) => {
	const [state, setState] = useState(getPreviewPaneState());

	useEffect(() => {
		const _onChange = () => {
			setState(getPreviewPaneState());
		};

		ManagementStore.addChangeListener(_onChange);
		return () => {
			ManagementStore.removeChangeListener(_onChange);
		};
	}, []);

	const { title, sub_title, content } = props;
	const { profile, previewed_booked_component, previewed_tips_source, trip } = state;
	let preview_pane;

	switch (previewed_tips_source) {
		case ManagementConstants.SOURCES.COST_ESTIMATION:
			preview_pane = <PreviewTripSummary profile={profile} trip={trip} currency={profile.currency} />;
			break;
		case ManagementConstants.SOURCES.ADD_AIRFARE_WIZARD:
			preview_pane = <PreviewAirfareTips title={title} />;
			break;
		default:
			if (!previewed_booked_component) {
				preview_pane = (
					<div className="tips">
						<div className="title">
							<span>{title}</span>
						</div>
						<div className="sub-title">
							<span>{sub_title}</span>
						</div>
						{content && <div className="content">{content}</div>}
					</div>
				);
			} else if (previewed_booked_component.flights) {
				preview_pane = <PreviewDetailedAirfare airfare={previewed_booked_component} />;
			} else if (previewed_booked_component.hotel) {
				preview_pane = <PreviewDetailedAccommodation accommodation={previewed_booked_component} />;
			}
			break;
	}

	return <div className="preview-pane">{preview_pane}</div>;
};

export default PreviewPane;
