import _ from 'lodash';
import moment from 'moment';

// Firebase App (the core Firebase SDK) is always required and must be listed first
import { getApps, getApp, initializeApp } from 'firebase/app';
import { getFirestore, connectFirestoreEmulator, doc, collection, onSnapshot, getDocs } from 'firebase/firestore';

// import WebStorageManager from '../WebStorageManager';

import DefaultConfig from './DefaultConfig';
import onSnapshotWrapper from './onSnapshotWrapper';
import Config from "../Config";

const WebStorageManager = require('../WebStorageManager');

let __subscription_timestamp__ = null;

class FirebaseWrapper {
    constructor(config = DefaultConfig.default_config, collection_name, subcollection_name, snapshot_params, prefix_message, _onSnapshotWrapper = onSnapshotWrapper) {
        this._config = config;

        const force_long_polling = WebStorageManager.getFromWebStorage('force_long_polling');
        const initialize_mode = (getApps().length === 0);

        if (initialize_mode) {
            if (force_long_polling) {
                // Forces the SDK’s underlying network transport (WebChannel) to use long-polling.
                // Each response from the backend will be closed immediately after the backend sends data (by default responses are kept open in case the backend has more data to send).
                // This avoids incompatibility issues with certain proxies, antivirus software, etc. that incorrectly buffer traffic indefinitely.
                // Use of this option will cause some performance degradation though.
                // This setting cannot be used with experimentalAutoDetectLongPolling and may be removed in a future release.
                // If you find yourself using it to work around a specific network reliability issue, please tell us about it in https://github.com/firebase/firebase-js-sdk/issues/1674.
                config.experimentalForceLongPolling = true;
                // Whether to merge the provided settings with the existing settings.
                // If set to true, the settings are merged with existing settings.
                // If set to false or left unset, the settings replace the existing settings.
                config.merge = true;
            } else {
                config.experimentalAutoDetectLongPolling = true;
                config.merge = true;
            }
        }

        if (initialize_mode) {
            this._app = initializeApp(config);
        } else {
            this._app = getApp(); // if already initialized, use that one
        }

        this._firestore = getFirestore(this._app);
        if (initialize_mode && config.useEmulator) {
            connectFirestoreEmulator(this._firestore, config.emulatorHost, config.emulatorPort);
        }

        this.collection_name = collection_name;
        this.subcollection_name = subcollection_name;
        this.snapshot_params = snapshot_params;
        this.prefix_message = prefix_message;

        this._onSnapshotWrapper = _onSnapshotWrapper;
        this._subscriptions = {};
    }

    // register_snapshot_subcollection_callback(main_collection_id, subcollection_name, onSnapshot, onSnapshotError) {
    //     // See: https://cloud.google.com/firestore/docs/query-data/queries
    //     // See: https://cloud.google.com/firestore/docs/query-data/listen
    //     return this._firestore
    //         .collection(this.collection_name)
    //         .doc(main_collection_id)
    //         .collection(subcollection_name)
    //         .onSnapshot(onSnapshot, onSnapshotError);
    //     //.where(query_field_path, query_operator, query_value).onSnapshot(onSnapshot);
    // }

    _setExpiration(token, onTimeout) {
        const milliseconds = moment.duration(this._config.subscriptionTimeoutInSeconds, 'seconds').asMilliseconds();
        setTimeout(() => {
            this.unsubscribe(token);
            if (_.isFunction(onTimeout)) {
                onTimeout();
            }
        }, milliseconds);
    }

    subscribe(token, onData, onError, onTimeout, pollingTimeout) {
        if (token && _.isFunction(onData)) {
            this.unsubscribe(token);

            __subscription_timestamp__ = moment();
            const _onData = this._onSnapshotWrapper(onData, this.snapshot_params, this.prefix_message, __subscription_timestamp__);
            const _onError = _.isFunction(onError)
                ? onError
                : _.noop;

            const tokenDocumentRef = doc(this._firestore, this.collection_name, token);
            const subCollectionRef = collection(tokenDocumentRef, this.subcollection_name);
            if (Config.localhost && pollingTimeout) {
                this._subscriptions[token] = true;
                this._fetchLatestData(this, subCollectionRef, token, onData, pollingTimeout);

                // lets listen localhost for 40 sec
                const milliseconds = 40000;
                const self = this;
                setTimeout(() => {
                    self._subscriptions[token] = null;
                    if (_.isFunction(onTimeout)) {
                        onTimeout();
                    }
                }, milliseconds);
            } else {
                this._subscriptions[token] = onSnapshot(subCollectionRef, _onData, _onError);
            }

            this._setExpiration(token, onTimeout);
        }
    }

    async _fetchLatestData(self, subCollectionRef, token, onData, pollingTimeout) {
        const querySnapshot = await getDocs(subCollectionRef);
        const deals = [];
        const message_type = []
        querySnapshot.forEach(doc => {
            const dealsFromString = _.isString(doc.data().deals) ? JSON.parse(doc.data().deals) : doc.data().deals;
            if (_.isArray(dealsFromString)) {
                deals.push(...(dealsFromString))
            }
            message_type.push(doc.data().message_type);
        });


        onData({ message_type, deals});
        setTimeout(() => {
            if (self._subscriptions[token]) {
                self._fetchLatestData(self, subCollectionRef, token, onData)
            }
        }, pollingTimeout);
    }

    unsubscribe(token) {
        const unsub = this._subscriptions[token];
        if (_.isFunction(unsub)) {
            unsub();
        }
    }

    unsubscribeAll() {
        for (const token in this._subscriptions) {
            this.unsubscribe(token);
        }
    }
}

// Move to: If you use reserved Hosting URLs,  or to env variables
// your Firebase config is automatically pulled from your Firebase project, 
// so you don't need to explicitly provide the object in your code.

// const firebase_wrapper = (collection_name = default_collection, subcollection_name = default_subcollection, field_name = default_field) => new FirebaseWrapper(default_config, collection_name, subcollection_name, field_name);
export default FirebaseWrapper;