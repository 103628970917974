const basic = {
	fontSize: 12,
	fontWeight: 400,
};

const auto_width = {
	width: 'auto',
};

const full_width = {
	width: '100%',
};

// const half_width = {
//   width: '50%'
// };

const icon_button = {
	width: 'auto',
	height: 'auto',
	padding: 0,
};

export default {
	basic: basic,

	errorStyle: {
		color: 'red',
	},

	autoWidth: auto_width,

	inputStyle: basic,

	hintStyle: basic,

	floatingLabelStyle: basic,

	textFieldStyle: basic,

	// menuStyle: Object.assign(basic, {
	//   width: '100%',
	//   display: 'block'
	// }),
	// menuStyle: Object.assign(basic, auto_width),
	menuStyle: Object.assign(basic, full_width),

	menuItemStyle: basic, //Object.assign(basic, {
	// lineHeight: 36,
	// minHeight: 36,
	// maxHeight: 36,
	// height: 36
	// }),

	selectedMenuItemStyle: basic,

	// listStyle: basic, //Object.assign(basic, {
	// // width: 'auto'
	// // }),
	// listStyle: Object.assign(basic, auto_width),
	listStyle: Object.assign(basic, full_width),
	currencyListStyle: {
		marginLeft: -16,
		width: '200%',
	},

	iconStyle: {
		width: 18,
		height: 18,
		// padding: 6,
		fontSize: 18,
		color: '#999999',
	},

	travelPolicyIconButton: icon_button,

	breakfastIconButton: Object.assign(icon_button, {
		fontSize: 13,
		marginLeft: -16,
		marginRight: 3,
	}),

	chipStyle: Object.assign({}, basic, {
		width: 'auto',
		display: 'inline-block',
		backgroundColor: '#f5f5f5',
		border: '1px solid #d9d9d9',
		cursor: 'default',
		borderRadius: 4,
		marginRight: 4,
	}),

	dialogStyle: {
		// width: 480
	},

	basketIconStyle: {
		width: 12,
		height: 12,
		fontSize: 12,
		backgroundColor: '#999999',
	},

	selectIconStyle: {
		marginRight: -16,
	},

	menuProps: {
		menuItemStyle: basic,
		selectedMenuItemStyle: basic,
		autoWidth: true,
		desktop: true,
		width: auto_width.width,
		style: auto_width,
	},

	disablingProps: {
		disabled: true,
		underlineDisabledStyle: {
			borderBottom: 0, //'1px solid #e5e5e5'
			cursor: 'text',
		},
	},

	disabledText: Object.assign(basic, {
		cursor: 'text',
	}),

	disabledInput: {
		color: 'rgba(0, 0, 0, 0.87)',
	},
	underlineDisabledForwardStyle: {
		borderBottom: 0,
		cursor: 'text',
		color: '#333',
	},

	vacationDaysTextFieldStyle: {
		width: 80,
		fontSize: 12,
		fontWeight: 400,
		cursor: 'text',
	},

	selectLabelStyle: Object.assign(basic, {
		paddingRight: 16,
	}),

	preferredTimeSelectStyle: {
		marginLeft: -24,
		width: 128,
	},

	travelersAutocomplete: {
		container: {
			position: 'relative',
		},
		busyLoader: {
			position: 'absolute',
			top: 12,
			right: 10,
		},
		option: {
			'&[aria-selected="true"]:not(.Mui-focused)': {
				backgroundColor: '#fff',
			},
		},
	},

	materialCheckbox: {
		icon: {
			width: 16,
			height: 16,

			marginRight: 4,
			marginTop: 2,
		},

		input: {},

		label: {},

		disclaimerLabel: {
			fontSize: 14,
			fontWeight: 400,
		},

		guestIcon: {
			width: 16,
			height: 16,

			marginRight: 3, // a bit closer
			marginTop: 2,
		},

		guestInput: {
			width: 220, // a bit narrower
		},

		guestLabel: {
			fontWeight: 400, // normal font (not bold)
		},
	},
};
