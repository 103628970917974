function Airport(airport) {
	if (!airport) {
		return;
	}

	// AC
	this.id = airport._id || airport.id;
	this.display_name = airport.display_name;
	if (airport.international) {
		this.international = airport.international;
	}

	// FULL
	this.name = airport.name;
	this.iata_code = airport.iata_code;
	this.icao_code = airport.icao_code;
	this.city = airport.city;
	this.country = airport.country;
	if (airport.lon_lat && Array.isArray(airport.lon_lat.coordinates)) {
		this.lon = airport.lon_lat.coordinates[0];
		this.lat = airport.lon_lat.coordinates[1];
	}
	this.timezone = airport.timezone_name;
}

export default Airport;
