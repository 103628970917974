import _ from 'lodash';
import $ from 'jquery';
import moment from 'moment';

import AuthActions from '../actions/AuthActions';
import URLS from './ApiURLs';

import * as Sentry from '@sentry/react';

import { actions } from 'arbitrip-common/client/analytics';
import Config from 'arbitrip-common/client/utils/Config';

// const localhost = Config.localhost;
// var server = Config.api_url;
/// forcing non-mock APIs trick ->
// localhost = false;

const timeout = 90000; // 1.5 minute
let TIMEOUT = false;

function ajax(options) {
	if (TIMEOUT) {
		let def = $.Deferred();
		setTimeout(function () {
			def.reject({ message: 'session timed out' });
		}, 500);
		return def;
	}

	options.error = function (jqXHR, textStatus, errorThrown) {
		if (jqXHR?.status === 403) {
			return; // skip logging to Sentry 403 errors as it creates a lot of unnecessary noise
		}

		let errorMessage = 'AJAX request failed';

		if (textStatus) {
			errorMessage += `: ${textStatus}`;
		}
		if (errorThrown) {
			errorMessage += ` - ${errorThrown}`;
		}
		if (jqXHR?.responseJSON?.message) {
			errorMessage += ` - ${jqXHR.responseJSON.message}`;
		}

		Sentry.captureException(new Error(errorMessage), {
			extra: {
				url: options.url,
				statusCode: jqXHR ? jqXHR.status : 'Unknown',
			},
		});

		actions.responses.httpError(options.data, jqXHR?.responseJSON?.message, options.url);
	};

	return $.ajax(options);
}

const getSpecialRequests = (reservation) => {
	const specialRequestsFromSuggestions = Object.values(reservation.specialRequestsFromSuggestions)
		.map((value) => value.text)
		.join('. ');
	if (reservation.specialRequests) {
		return `${reservation.specialRequests}\r\n${specialRequestsFromSuggestions}`;
	}

	return specialRequestsFromSuggestions;
};

function advanceReservation(params) {
	const {
		url,
		payment_type,
		reservation,
		trip_id,
		use_bank_money,
		search_token,
		send_email_confirmation,
		send_voucher_separately_from_confirmation_mail,
		combtas_id,
		travel_booster_docket_number,
		travel_booster_should_create_a_new_docket,
		price_comparable,
		currency,
		client_custom_fields,
		search_terms,
		hotel_search_token,
		charge_currency,
		invoice_name,

		show_points,
		// points_only,
		user_chosen_points,
		threeDSData,
		reservation_manipulation,
	} = params;
	console.log('advanceReservation (Ajax): ', reservation, url);
	if (!reservation) {
		console.warn('no payload provided for payWithCompanyPolicy');
	}

	const payload = {
		reservation: {
			id: reservation.id,
			travelers: reservation.travelers,
			specialRequests: getSpecialRequests(reservation),
			management_trip_id: reservation.include_management_trip_id ? reservation.management_trip_id : '',
			out_of_policy_booking_reason: reservation.out_of_policy_booking_reason,
			search_token: hotel_search_token || search_token,
			send_email_confirmation,
			send_voucher_separately_from_confirmation_mail,
		},
		use_bank_money,
		// green_invoice_id: reservation.invoice_id,
		// green_invoice_currency: reservation.invoice_currency,
		invoice_id: reservation.invoice_id,
		invoice_currency: reservation.invoice_currency,
		send_copy_to_travelers: send_email_confirmation,
		send_voucher_separately_from_confirmation_mail,
		payment_type,
		invoice_name,
	};
	if (trip_id) {
		payload.reservation.trip_id = trip_id;
	}
	if (combtas_id) {
		payload.reservation.tas_id = combtas_id;

		// only adds original proposal_id if tas_id hasn't changed
		if (search_terms.proposal_id && search_terms.combtas_id === combtas_id) {
			payload.reservation.proposal_id = reservation.proposal_id;
		}
	}
	if (travel_booster_docket_number) {
		payload.reservation.travel_booster_docket_number = travel_booster_docket_number;
	}
	if (travel_booster_should_create_a_new_docket) {
		payload.reservation.travel_booster_should_create_a_new_docket = travel_booster_should_create_a_new_docket;
	}
	if (price_comparable) {
		payload.reservation.price_comparable = price_comparable;
	}
	if (currency) {
		payload.reservation.currency = currency;
	}
	if (charge_currency) {
		payload.reservation.charge_currency = charge_currency;
	}
	if (client_custom_fields) {
		payload.client_custom_fields = client_custom_fields;
	}

	if (reservation.contact_details) {
		payload.reservation.contact_details = reservation.contact_details;
	}

	if (reservation_manipulation) {
		payload.reservation_manipulation = reservation_manipulation;
	}

	const agent_pricing = _.get(reservation, 'deal.agent_pricing', {});
	if (agent_pricing.active) {
		payload.agent_pricing = _.pick(agent_pricing, [
			'pricing_method',

			'absolute_margin_per_night',
			'absolute_margin_total',

			'margin_percentage',

			'client_price_per_night',
			'client_price_total',
		]);
	}

	if (show_points) {
		payload.points = user_chosen_points;
	}

	if (threeDSData) {
		payload.threeDSData = threeDSData;
	}

	// When adding a parameter here, make sure you also add to requestOutOfPolicyRoom
	const options = getAjaxOptions({
		method: 'POST',
		url,
		contentType: 'application/json; charset=utf-8',
		dataType: 'json',
		data: JSON.stringify(payload),
		timeout,
	});
	return ajax(options);
}

var Communicator = {
	executeHotelsSearch: function (data, timeout) {
		if (!data) {
			console.warn('no payload provided for executeSearch');
		}

		const options = getAjaxOptions({
			timeout: timeout || moment.duration(1, 'minute').asMilliseconds(), // default 1 minute
			method: 'POST',
			url: data.token ? URLS.HOTELS_SEARCH + '?token=' + data.token : URLS.HOTELS_SEARCH,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify(data),
		});

		return ajax(options);
	},

	executeSearch: function (data, timeout) {
		if (!data) console.warn('no payload provided for executeSearch');
		// data.longTimeout = getAppFlags().longTimeout;
		var options = getAjaxOptions({
			timeout: timeout || moment.duration(1, 'minute').asMilliseconds(), // default 1 minute
			method: 'POST',
			url: data.token ? URLS.SEARCH + '?token=' + data.token : URLS.SEARCH,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify(data),
		});
		return ajax(options);
		// .then(function (res) {
		//   AppDispatcher.handleAction({
		//     actionType: AjaxConstants.AJAX_CALL_DONE,
		//     data: { type: '' } // ???
		//   })
		//   return res;
		// });
	},

	//get active user by session
	getCurrentUser: function () {
		var options = getAjaxOptions({
			method: 'GET',
			url: URLS.PROFILE,
		});
		return ajax(options).then(function (res) {
			console.log('Profile response:', res);
			return res;
		});
	},

	//
	// Search
	//
	searchAutocomplete: function (search, params) {
		let payload = {
			search,
			...(params ? params : {}),
		};
		let url = URLS.SEARCH_AUTOCOMPLETE;

		let options = getAjaxOptions({
			method: 'GET',
			url,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: payload,
			timeout: moment.duration(10, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	getPlace: function (data) {
		console.log('getPlace (Ajax) ', data);
		let payload = { ...data };

		let options = getAjaxOptions({
			method: 'GET',
			url: URLS.SEARCH_AUTOCOMPLETE_PLACE,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: payload,
			timeout: moment.duration(10, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	getHotelInfo: function (hotelId) {
		hotelId = hotelId || '';
		if (!hotelId) {
			console.warn('hotelId not provided in getHotelInfo');
		}
		var options = getAjaxOptions({
			method: 'GET',
			url: URLS.HOTEL_INFO + '/' + hotelId,
			//,data: data
		});
		return ajax(options);
	},

	getHotelDeals: function (data) {
		data.hotel_id = data.hotel_id || '';
		if (!data.hotel_id) {
			console.warn('hotel_id was not provided in getHotelDeals');
		}
		var options = getAjaxOptions({
			method: 'POST',
			// url: URLS.HOTEL_DEALS + '/' + data.hotel_id,
			url: URLS.HOTEL_DEALS + (data.token ? '?token=' + data.token : ''),
			data: JSON.stringify(data),
		});
		return ajax(options);
	},

	updateCitySearch: function (data) {
		if (!data.old_token) {
			console.warn('old_token was not provided in updateCitySearch');
		}
		if (!data.new_token) {
			console.warn('new_token was not provided in updateCitySearch');
		}

		if (!data.place) {
			console.warn('place was not provided in updateCitySearch');
		}

		const options = getAjaxOptions({
			timeout: moment.duration(9, 'seconds').asMilliseconds(),
			method: 'POST',
			url: URLS.HOTEL_CITY_SEARCH,
			data: JSON.stringify(data),
		});
		return ajax(options);
	},

	getHotelRecheckedDeals: function (data) {
		data.token = data.token || '';
		data.hotel_id = data.hotel_id || '';

		if (!data.hotel_id) {
			console.warn('hotel_id was not provided in getHotelRecheckedDeals');
		}
		if (!data.token) {
			console.warn('token was not provided in getHotelRecheckedDeals');
		}

		const options = getAjaxOptions({
			timeout: moment.duration(9, 'seconds').asMilliseconds(),
			tryCount: 3,
			method: 'POST',
			url: URLS.HOTEL_RECHECKED_DEALS + `?token=${data.token}&hotel_id=${data.hotel_id}`,
			data: JSON.stringify(data),
		});
		return ajax(options);
	},

	openNewReservation: function (
		deal,
		hotel,
		token,
		travel_policy,
		group_id,
		hotel_search_url,
		derived_from,
		contract_id,
		optimization_token,
		hotel_token,
		mapping_room_name,
		room_mapping_data,
		excluded_payments_reference_id,
	) {
		if (!deal || !hotel) {
			console.warn('no payload provided for openNewReservation (initReservation)');
		}

		var flags = {};
		var options = getAjaxOptions({
			method: 'POST',
			url: URLS.INIT_RESERVATION,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			tryCount: 3,
			data: JSON.stringify({
				optimization_token,
				mapping_room_name,
				room_mapping_data,
				deal_id: deal.id,
				recheck_id: deal.recheck_id,
				//deal: deal.original,
				//chooseprod: deal.chooseprod,
				hotel_id: hotel.id,
				token: hotel_token || token,
				options: flags,
				travel_policy,
				group_id,
				hotel_search_url,
				derived_from,
				contract_id,
				excluded_payments_reference_id,
				...(deal.reservation_manipulation ? { reservation_manipulation: deal.reservation_manipulation } : {}),
			}),
			timeout,
		});
		return ajax(options);
	},

	checkDoubleBooking: function (data) {
		console.log('Checking for double booking (Ajax)...');
		const options = getAjaxOptions({
			method: 'POST',
			url: URLS.CHECK_DOUBLE_BOOKING,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify(data),
			timeout: moment.duration(6, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	createExpediaBooking: function (data) {
		console.log('Creating expedia booking (Ajax)...');
		const options = getAjaxOptions({
			method: 'POST',
			url: URLS.CREATE_EXPEDIA_BOOKING,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify(data),
			timeout,
		});
		return ajax(options);
	},

	completeExpediaSession: function (data) {
		console.log('Complete expedia session (Ajax)...');
		const options = getAjaxOptions({
			method: 'POST',
			url: URLS.COMPLETE_EXPEDIA_SESSION,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify(data),
			timeout,
		});
		return ajax(options);
	},

	payLater: function (params) {
		params.url = URLS.PAY_LATER;
		return advanceReservation(params);
	},

	payCompanyPolicy: function (params) {
		params.url = URLS.PAY_COMPANY_POLICY;
		return advanceReservation(params);
	},

	payAtHotel: function (params) {
		params.url = URLS.PAY_AT_HOTEL;
		return advanceReservation(params);
	},

	payPointsOnly: function (params) {
		params.url = URLS.PAY_POINTS_ONLY;
		return advanceReservation(params);
	},

	payReGenerateCreditUrl: function (params) {
		params.url = URLS.REGENERATE_CREDIT_URL;
		return advanceReservation(params);
	},

	payGenerateCreditUrl: function (params) {
		params.url = URLS.GENERATE_CREDIT_URL;
		return advanceReservation(params);
	},

	quoteReservation: function (reservation, search_terms) {
		var search_terms_out = {
			destination: search_terms.destination,
		};
		console.log(
			'Quoting reservation (Ajax): ',
			reservation,
			' | trip: ',
			reservation.management_trip_id + ' search_terms ' + JSON.stringify(search_terms_out),
		);
		if (!reservation) {
			console.warn('no payload provided for quoteReservation');
		}
		var options = getAjaxOptions({
			method: 'POST',
			url: URLS.QUOTE_RESERVATION,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify({
				reservation: {
					id: reservation.id,
					travelers: reservation.travelers,
					specialRequests: getSpecialRequests(reservation),
					management_trip_id: reservation.management_trip_id,
					search_terms: search_terms_out,
				},
			}),
		});
		return ajax(options);
	},

	/*

   Possible Responses:

   CONSTANTS.PAYMENT_STATUS = { // if you update this, update the model ...
   NA: 'not_exists',
   INITIALIZED: 'initialized',
   J5: 'J5', // we managed to secure credit
   POSTPONED: 'postponed', // an iska dehuya
   PENDING_MANUAL_BILLING: 'pending_manual_billing', // postponed deals that will be bill manually
   BILLED: 'billed',
   CANCELLED: 'cancelled',
   ERROR: 'error' // an irrecoverable error occurred
   }

   INITIALIZED = Default - Keep polling
   J5, POSTPONED = Payment Accepted / Success - Stop polling
   PENDING_MANUAL_BILLING, BILLED, CANCELLED = Success - Stop polling
   NA, ERROR = Fail - Stop polling

   */
	samplePaymentStatus: function (reservation, payment) {
		if (!reservation || !payment) console.warn('no payload provided for samplePaymentStatus');
		var options = getAjaxOptions({
			method: 'GET',
			url: URLS.PAYMENT_STATUS,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: {
				paymentID: payment.id,
			},
			// data: JSON.stringify({
			//   paymentID: payment.id
			//   // ,reservation: {
			//   //   id: reservation.id
			//   // }
			// })
		});
		return ajax(options);
	},

	requestOutOfPolicyRoom: function (params) {
		const {
			reservation,
			trip_id,
			search_token,
			send_email_confirmation,
			combtas_id,
			price_comparable,
			currency,
			client_custom_fields,
			search_terms,
			hotel_search_token,
			send_voucher_separately_from_confirmation_mail,
		} = params;
		console.log('requesting out-of-policy room (Ajax): ', reservation);
		if (!reservation) console.warn('no payload provided for requestOutOfPolicyRoom');
		var payload = {
			reservation: {
				id: reservation.id,
				travelers: reservation.travelers,
				specialRequests: getSpecialRequests(reservation),
				management_trip_id: reservation.include_management_trip_id ? reservation.management_trip_id : '',
				out_of_policy_booking_reason: reservation.out_of_policy_booking_reason,
				search_token: hotel_search_token || search_token,
				send_email_confirmation: send_email_confirmation,
			},
			// green_invoice_id: reservation.invoice_id,
			// green_invoice_currency: reservation.invoice_currency,
			invoice_id: reservation.invoice_id,
			invoice_currency: reservation.invoice_currency,
			send_copy_to_travelers: send_email_confirmation,
			send_voucher_separately_from_confirmation_mail,
		};
		if (trip_id) {
			payload.reservation.trip_id = trip_id;
		}
		if (combtas_id) {
			payload.reservation.tas_id = combtas_id;
			// only adds original proposal_id if tas_id hasn't changed
			if (search_terms.proposal_id && search_terms.combtas_id === combtas_id) {
				payload.reservation.proposal_id = reservation.proposal_id;
			}
		}
		if (price_comparable) {
			payload.reservation.price_comparable = price_comparable;
		}
		if (currency) {
			payload.reservation.currency = currency;
		}
		if (client_custom_fields) {
			payload.client_custom_fields = client_custom_fields;
		}
		// Make sure that has same parameters as ADVANCE_RESERVATION
		var options = getAjaxOptions({
			method: 'POST',
			url: URLS.REQUEST_OUT_OF_POLICY_ROOM,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify(payload),
		});
		return ajax(options);
	},

	getRecentSearches: function () {
		console.log('Fetching recent searches...');
		var options = getAjaxOptions({
			method: 'GET',
			url: URLS.RECENT_SEARCHES,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
		});
		return ajax(options);
	},

	removeRecentSearch: function (recent_search) {
		console.log('removing recent search (Ajax): ', recent_search);
		var options = getAjaxOptions({
			method: 'DELETE',
			url: URLS.SEARCHES,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify({
				id: recent_search.id, // TODO : VERIFY!!!
			}),
		});
		return ajax(options);
	},

	getBusinessDestinations: function (contract_id) {
		console.log('Fetching business destinations...');
		var options = getAjaxOptions({
			method: 'GET',
			url: URLS.DESTINATIONS,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
		});

		if (contract_id) {
			options.data = { contract_id };
		}

		return ajax(options);
	},

	addBusinessDestination: function (business_destination) {
		console.log('adding business destination (Ajax): ', business_destination);
		var options = getAjaxOptions({
			method: 'POST',
			url: URLS.DESTINATIONS,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify(business_destination),
		});
		return ajax(options);
	},

	removeBusinessDestination: function (business_destination) {
		console.log('removing business destination (Ajax): ', business_destination);
		var options = getAjaxOptions({
			method: 'DELETE',
			url: URLS.DESTINATIONS,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify({
				id: business_destination.id,
			}),
		});
		return ajax(options);
	},

	getCompanyBookingsAndPrivateTravel: function (skip, limit, filters, orderBy, order) {
		console.log('getting company bookings and private travel (Ajax): ');
		var options = getAjaxOptions({
			method: 'GET',
			url: URLS.COMPANY_BOOKINGS_AND_PRIVATE_TRAVEL,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: {
				skip,
				limit,
				filters,
				order_by: orderBy,
				order,
			},
			timeout: moment.duration(30, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	getCompanyBookings: function () {
		console.log('getting company bookings (Ajax): ');
		var options = getAjaxOptions({
			method: 'GET',
			url: URLS.COMPANY_BOOKINGS,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			timeout: moment.duration(30, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	getPersonalBookings: function (skip, limit, filters, orderBy, order) {
		console.log('getting personal bookings (Ajax): ');
		var options = getAjaxOptions({
			method: 'GET',
			url: URLS.BOOKINGS,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: {
				skip,
				limit,
				filters,
				order_by: orderBy,
				order,
			},
			timeout: moment.duration(30, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	getBookingsCsv: function (is_agency, filters, export_type) {
		console.log('getBookingsCsv');
		var options = getAjaxOptions({
			method: 'POST',
			url: is_agency ? URLS.AGENCY_BOOKINGS_DOWNLOAD_CSV : URLS.COMPANY_BOOKINGS_DOWNLOAD_CSV,
			contentType: 'application/json; charset=utf-8',
			dataType: 'text', ///csv; charset=utf-8',
			data: JSON.stringify({
				filters,
				export_type,
			}),
			timeout: moment.duration(5, 'minutes').asMilliseconds(),
		});
		return ajax(options);
	},

	getBookingRequestsCsv: function (is_agency, filters) {
		console.log('getBookingRequestsCsv');
		var options = getAjaxOptions({
			method: 'GET',
			url: is_agency ? URLS.AGENCY_BOOKING_REQUESTS_DOWNLOAD_CSV : URLS.COMPANY_BOOKING_REQUESTS_DOWNLOAD_CSV,
			contentType: 'application/json; charset=utf-8',
			dataType: 'text', //'text/csv; charset=utf-8',
			data: {
				filters,
			},
			timeout: moment.duration(5, 'minutes').asMilliseconds(),
		});
		return ajax(options);
	},

	getReportsSummary: function (startDate, endDate, client_contract_id) {
		// console.log("getting reports summary (Ajax): ");
		var options = getAjaxOptions({
			method: 'GET',
			url: URLS.REPORTS_SUMMARY,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: {
				start: startDate.toISOString(),
				end: endDate.toISOString(),
				client_contract_id: client_contract_id,
			},
		});
		return ajax(options);
	},

	getReportsGraphsData: function (startDate, endDate, client_contract_id) {
		// console.log("getting reports graphs data (Ajax): ");
		var options = getAjaxOptions({
			method: 'GET',
			url: URLS.REPORTS_GRAPHS_DATA,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: {
				start: startDate.toISOString(),
				end: endDate.toISOString(),
				client_contract_id: client_contract_id,
			},
		});
		return ajax(options);
	},

	cancelReservation: function (reservation_id) {
		console.log('canceling booking (Ajax): ', reservation_id);
		var options = getAjaxOptions({
			method: 'POST',
			url: URLS.CANCEL_RESERVATION,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify({
				resv_id: reservation_id,
			}),
			timeout: moment.duration(20, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	getCompanyRequests: function (skip, limit, filters) {
		console.log('getting company requests (Ajax): ');
		var options = getAjaxOptions({
			method: 'GET',
			url: URLS.COMPANY_REQUESTS,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: {
				skip,
				limit,
				filters,
			},
			timeout: moment.duration(12, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	getPersonalRequests: function (skip, limit, filters) {
		console.log('getting personal requests (Ajax): ');
		var options = getAjaxOptions({
			method: 'GET',
			url: URLS.PERSONAL_REQUESTS,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: {
				skip,
				limit,
				filters,
			},
			timeout: moment.duration(12, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	approveCompanyRequest: function (reservation_request) {
		console.log('approving company request (Ajax)... | reservation_request:', reservation_request);
		var options = getAjaxOptions({
			method: 'POST',
			url: URLS.APPROVE_REQUEST,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify({
				reservation_id: reservation_request.reservation.id,
				group_id: reservation_request.request_ticket.attached,
				ticket_id: reservation_request.request_ticket.id,
			}),
			timeout: moment.duration(30, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	rejectCompanyRequest: function (reservation_request) {
		console.log('rejecting company request (Ajax)... | reservation_request:', reservation_request);
		var options = getAjaxOptions({
			method: 'POST',
			url: URLS.REJECT_REQUEST,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify({
				reservation_id: reservation_request.reservation.id,
				group_id: reservation_request.request_ticket.attached,
				ticket_id: reservation_request.request_ticket.id,
				reason: reservation_request.reason,
			}),
			timeout: moment.duration(30, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	sendInvitation: function (referral) {
		console.log('sending invitation (Ajax)... | referral:', referral);
		var options = getAjaxOptions({
			method: 'POST',
			url: URLS.SEND_INVITATION,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify(referral),
			timeout: moment.duration(6, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	checkBankBalance: function () {
		console.log('checking bank balance (Ajax): ');
		var options = getAjaxOptions({
			method: 'GET',
			url: URLS.BANK,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			timeout: moment.duration(12, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	upgradePlan: function () {
		console.log('upgrading plan (Ajax): ');
		var options = getAjaxOptions({
			method: 'POST',
			url: URLS.UPGRADE_PLAN,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			timeout: moment.duration(6, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	getCompanyTravelPolicies: function () {
		console.log('Retrieving company travel policies (Ajax)...');
		var options = getAjaxOptions({
			method: 'GET',
			url: URLS.TRAVEL_POLICY,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			timeout: moment.duration(6, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	addTravelPolicyEntry: function (data) {
		console.log('Adding entry to a company travel policy (Ajax)...');
		var options = getAjaxOptions({
			method: 'POST',
			url: URLS.TRAVEL_POLICY + `/entry`,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify({
				place: data.place,
				price: data.price,
				// currency: data.currency
			}),
		});
		return ajax(options);
	},

	editTravelPolicyEntry: function (entryId, data) {
		console.log('Editing entry of a company travel policy (Ajax)...');
		var options = getAjaxOptions({
			method: 'PATCH',
			url: URLS.TRAVEL_POLICY + `/entry/${entryId}`,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify({
				price: data,
			}),
		});
		return ajax(options);
	},

	editDefaultTravelPolicy: function (data) {
		console.log('Editing default company travel policy (Ajax)...');
		var options = getAjaxOptions({
			method: 'PATCH',
			url: URLS.TRAVEL_POLICY + `/default`,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify({
				price: data.price,
			}),
		});
		return ajax(options);
	},

	removeTravelPolicyEntry: function (entryId) {
		console.log('Removing entry of a company travel policy (Ajax)...');
		var options = getAjaxOptions({
			method: 'DELETE',
			url: URLS.TRAVEL_POLICY + `/entry/${entryId}`,
			contentType: 'application/json; charset=utf-8',
		});
		return ajax(options);
	},

	removeCompanyTravelPolicy: function (travel_policy) {
		console.log('Removing company travel policy (Ajax)...');
		var options = getAjaxOptions({
			method: 'DELETE',
			url: URLS.TRAVEL_POLICY,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify({
				// city: travel_policy.city
				travel_policy_id: travel_policy.id,
			}),
			timeout: moment.duration(6, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	///
	/// Management APIs
	///
	companyEmployeesLookup: function (term, ignore, agent_contract_id, agent_client_company_id) {
		console.log('companyEmployeesLookup (Ajax) ', term, ignore);
		let payload = { term, ignore };
		if (agent_contract_id) {
			payload.contract_id = agent_contract_id;
			payload.company_id = agent_client_company_id;
		}
		let options = getAjaxOptions({
			method: 'GET',
			url: URLS.CONTACTS_AUTOCOMPLETE,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: payload,
			timeout: moment.duration(10, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	companyCreateContact: function (contact, company_id, contract_id) {
		let payload = Object.assign({ company_id, contract_id }, contact);
		var options = getAjaxOptions({
			method: 'POST',
			url: URLS.CONTACTS,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify(payload),
			timeout: moment.duration(6, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	staticAirportsLookup: function (term) {
		console.log('staticAirportsLookup (Ajax) ', term);
		let options = getAjaxOptions({
			method: 'GET',
			url: URLS.AIRPORT_CITY_COUNTRY_AUTOCOMPLETE,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: {
				term: term,
			},
			timeout: moment.duration(3, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	initTripRequest: function () {
		console.log('initTripRequest (Ajax) ');
		var options = getAjaxOptions({
			method: 'POST',
			url: URLS.INIT_TRIP_REQUEST,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			timeout: moment.duration(6, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	saveTripRequestAsDraft: function (trip_request) {
		console.log('saveTripRequestAsDraft (Ajax) ', trip_request);
		if (!trip_request) console.warn('no payload provided for saveTripRequestAsDraft');
		var options = getAjaxOptions({
			method: 'POST',
			// url: URLS.SAVE_TRIP_REQUEST_AS_DRAFT,
			url: URLS.EDIT_TRIP_REQUEST_META,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify({
				// trip_request: trip_request
				trip_request_id: trip_request.id,
				payload: trip_request,
			}),
			timeout: moment.duration(6, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	editTripRequestMeta: function (trip_request) {
		console.log('editTripRequestMeta (Ajax) ', trip_request);
		if (!trip_request) console.warn('no payload provided for editTripRequestMeta');
		var options = getAjaxOptions({
			method: 'POST',
			url: URLS.EDIT_TRIP_REQUEST_META,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify({
				trip_request_id: trip_request.id,
				payload: trip_request,
			}),
			timeout: moment.duration(6, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	createTripRequestComponent: function (trip_request, component) {
		if (!trip_request || !component) console.warn('no payload provided for createTripRequestComponent');
		var options = getAjaxOptions({
			method: 'POST',
			url: URLS.CREATE_TRIP_REQUEST_COMPONENT,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify({
				trip_request_id: trip_request.id,
				component_type: component.component_type,
				payload: component,
			}),
			timeout: moment.duration(6, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	updateTripRequestComponent: function (trip_request, component) {
		if (!trip_request || !component) console.warn('no payload provided for updateTripRequestComponent');
		var options = getAjaxOptions({
			method: 'POST',
			url: URLS.UPDATE_TRIP_REQUEST_COMPONENT,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify({
				trip_request_id: trip_request.id,
				component_id: component.id,
				component_type: component.component_type,
				payload: component,
			}),
			timeout: moment.duration(6, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	removeTripRequestComponent: function (trip_request, component) {
		if (!trip_request || !component) console.warn('no payload provided for removeTripRequestComponent');
		var options = getAjaxOptions({
			method: 'POST',
			url: URLS.REMOVE_TRIP_REQUEST_COMPONENT,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify({
				trip_request_id: trip_request.id,
				component_id: component.id,
				component_type: component.component_type,
			}),
			timeout: moment.duration(6, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	submitTripRequest: function (trip_request) {
		if (!trip_request) console.warn('no payload provided for submitTripRequest');
		var options = getAjaxOptions({
			method: 'POST',
			url: URLS.SUBMIT_TRIP_REQUEST,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify({
				trip_request_id: trip_request.id,
			}),
			timeout: moment.duration(6, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	saveProfile: function (profile) {
		if (!profile) console.warn('no payload provided for saveProfile');
		var options = getAjaxOptions({
			method: 'POST',
			url: URLS.SAVE_PROFILE,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify({
				profile: profile,
			}),
			timeout: moment.duration(6, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	changeDisplayCurrency: function (currency) {
		if (!currency) console.warn('no payload provided for changeCurrency');
		var options = getAjaxOptions({
			method: 'POST',
			url: URLS.CHANGE_DISPLAY_CURRENCY,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify({ currency }),
			timeout: moment.duration(6, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	getTripRequests: function () {
		console.log('getAllTripRequests (Ajax) ');
		let options = getAjaxOptions({
			method: 'GET',
			url: URLS.TRIP_REQUESTS,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			timeout: moment.duration(24, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	getOngoingTrips: function () {
		console.log('getOngoingTrips (Ajax)');
		let options = getAjaxOptions({
			method: 'GET',
			url: URLS.ONGOING_TRIPS,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			timeout: moment.duration(24, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	getTripRequestById: function (id) {
		console.log('getTripRequestById (Ajax) ');
		let options = getAjaxOptions({
			method: 'GET',
			url: URLS.SINGLE_TRIP_REQUEST,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: {
				trip_request_id: id,
			},
			timeout: moment.duration(6, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	submitWizardDestinations: function (destinations) {
		console.log('submitWizardDestinations (Ajax) ');
		let options = getAjaxOptions({
			method: 'POST',
			url: URLS.INIT_TRIP_REQUEST,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify({
				initial_destinations: destinations,
			}),
			timeout: moment.duration(6, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	discardTripRequest: function (trip_request) {
		console.log('discardTripRequest (Ajax) ');
		let options = getAjaxOptions({
			method: 'POST',
			url: URLS.DISCARD_TRIP_REQUEST,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify({
				trip_request_id: trip_request.id,
			}),
			timeout: moment.duration(6, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	// same as discard but for TMs
	cancelTripRequest: function (trip_request) {
		console.log('cancelTripRequest (Ajax) ');
		let options = getAjaxOptions({
			method: 'POST',
			url: URLS.CANCEL_TRIP_REQUEST,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify({
				trip_request_id: trip_request.id,
			}),
			timeout: moment.duration(6, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},
	sendTripForPreApproval: function (trip) {
		console.log('sendTripForPreApproval (Ajax) ');
		let options = getAjaxOptions({
			method: 'POST',
			url: URLS.SEND_TRIP_FOR_PRE_APPROVAL,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify({
				trip_id: trip.id,
			}),
			timeout: moment.duration(6, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	sendTripForApproval: function (trip) {
		console.log('sendTripForApproval (Ajax) ');
		let options = getAjaxOptions({
			method: 'POST',
			url: URLS.SEND_TRIP_FOR_APPROVAL,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify({
				trip_id: trip.id,
			}),
			timeout: moment.duration(6, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	cloneTrip: function (trip) {
		console.log('cloneTrip (Ajax) ');
		let options = getAjaxOptions({
			method: 'POST',
			url: URLS.CLONE_TRIP,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify({
				trip_id: trip.id,
			}),
			timeout: moment.duration(6, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	forwardTripRequest: function (component_ids, to_emails, message, trip_request_id) {
		console.log('forwardTripRequest (Ajax) ');
		let options = getAjaxOptions({
			method: 'POST',
			url: URLS.FORWARD_TRIP_REQUEST,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify({
				component_ids: component_ids,
				to_emails: to_emails,
				message: message,
				trip_request_id: trip_request_id,
			}),
			timeout: moment.duration(6, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	// airfares
	createTripAirfare: function (trip_airfare) {
		console.log('createTripAirfare (Ajax) ');
		let options = getAjaxOptions({
			method: 'POST',
			url: URLS.CREATE_TRIP_AIRFARE,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			// data: JSON.stringify({
			//   trip_id: trip_airfare.trip_id,
			//   ...((Array.isArray(flights) && flights.length) ? {flights: flights} : {})
			//     ...(trip_airfare.cost ? {cost:t})
			// }),
			data: JSON.stringify(trip_airfare),
			timeout: moment.duration(15, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	removeTripAirfare: function (trip, airfare) {
		console.log('removeTripAirfare (Ajax) ');
		let options = getAjaxOptions({
			method: 'POST',
			url: URLS.REMOVE_TRIP_AIRFARE,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify({
				trip_id: trip.id,
				airfare_id: airfare.id,
			}),
			timeout: moment.duration(15, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	removeTripAccommodation: function (trip, accommodation) {
		console.log('removeTripAccommodation (Ajax) ');
		let options = getAjaxOptions({
			method: 'POST',
			url: URLS.REMOVE_TRIP_ACCOMMODATION,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify({
				trip_id: trip.id,
				accommodation_id: accommodation.id,
			}),
			timeout: moment.duration(15, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	updateTripAirfare: function (trip_id, airfare_id) {
		console.log('updateTripAirfare (Ajax) ');
		let options = getAjaxOptions({
			method: 'POST',
			url: URLS.UPDATE_TRIP_AIRFARE,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify({
				trip_id: trip_id,
				airfare_id: airfare_id,
			}),
			timeout: moment.duration(6, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	getManagementReportsSummary: function (startDate, endDate, client_contract_id) {
		console.log('getManagementReportsSummary (Ajax): ');
		var options = getAjaxOptions({
			method: 'GET',
			url: URLS.MANAGEMENT_REPORTS_SUMMARY,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: {
				start: startDate.toISOString(),
				end: endDate.toISOString(),
				client_contract_id: client_contract_id,
			},
		});
		return ajax(options);
	},

	getManagementReportsGraphsData: function (startDate, endDate, client_contract_id) {
		console.log('getManagementReportsGraphsData (Ajax): ');
		var options = getAjaxOptions({
			method: 'GET',
			url: URLS.MANAGEMENT_REPORTS_GRAPHS_DATA,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: {
				start: startDate.toISOString(),
				end: endDate.toISOString(),
				client_contract_id: client_contract_id,
			},
		});
		return ajax(options);
	},

	getManagementReportsCostCentersAnnualBudget: function (year, client_contract_id) {
		console.log('getManagementReportsCostCentersAnnualBudget (Ajax): ');
		var options = getAjaxOptions({
			method: 'GET',
			url: URLS.MANAGEMENT_REPORTS_COST_CENTERS_ANNUAL_BUDGET,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: {
				year: year,
				client_contract_id: client_contract_id,
			},
		});
		return ajax(options);
	},

	sendBasket: function (basket) {
		console.log('sendBasket (Ajax) ');
		let options = getAjaxOptions({
			method: 'POST',
			url: URLS.SEND_BASKET,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify({
				recipients: basket.recipients,
				remarks: basket.remarks,
				name: basket.name,
				deals: basket.deals,
				...(basket.send_to_combtas ? { combtas_id: basket.combtas_id } : {}),
			}),
			timeout: moment.duration(30, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	getAgencyBookings: function (skip, limit, filters, orderBy, order) {
		console.log('getting agency bookings (Ajax): ');
		var options = getAjaxOptions({
			method: 'GET',
			url: URLS.AGENCY_GET_BOOKINGS,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: {
				skip,
				limit,
				filters,
				order_by: orderBy,
				order,
			},
			timeout: moment.duration(30, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	updateReservationAgentNotes: function (reservationId, notes) {
		console.log('updateReservationAgentNotes (Ajax) ');
		const options = getAjaxOptions({
			method: 'POST',
			url: URLS.AGENCY_NOTES,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify({
				reservation_id: reservationId,
				notes,
			}),
			timeout: moment.duration(6, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	sendBookingConfirmation: function (booking_id) {
		console.log('sending booking confirmation (Ajax): ');
		var options = getAjaxOptions({
			method: 'POST',
			url: URLS.SEND_BOOKING_CONFIRMATION,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify({
				booking_id,
			}),
			timeout: moment.duration(20, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	sendBookingConfirmationByTravelManager: function (booking_id) {
		console.log('sending booking confirmation by travel manager (Ajax): ');
		var options = getAjaxOptions({
			method: 'POST',
			url: URLS.SEND_BOOKING_CONFIRMATION_BY_TRAVEL_MANAGER,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify({
				booking_id,
			}),
			timeout: moment.duration(20, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	getAgencyCompanyContract: function (contract_id) {
		console.log('getting agency-company contract (Ajax): ');
		var options = getAjaxOptions({
			method: 'GET',
			url: URLS.AGENCY_GET_CONTRACT,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: { contract_id },
			timeout: moment.duration(12, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	updateAgencyCompanyContract: function (contract_id, contract) {
		console.log('updating agency-company contract (Ajax): ');
		var options = getAjaxOptions({
			method: 'POST',
			url: URLS.AGENCY_UPDATE_CONTRACT,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify({ contract_id, contract }),
			timeout: moment.duration(6, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	getAgencyCompanies: function () {
		console.log("getting agency's companies (Ajax): ");
		var options = getAjaxOptions({
			method: 'GET',
			url: URLS.AGENCY_GET_COMPANIES,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			timeout: moment.duration(12, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	getUsersByCompany(payload) {
		let options = getAjaxOptions({
			method: 'GET',
			url: URLS.COMPANY_USERS,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: payload,
			timeout: moment.duration(12, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	addNewUser(payload) {
		let options = getAjaxOptions({
			method: 'POST',
			url: URLS.COMPANY_ADD_USER,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify(payload),
			timeout: moment.duration(12, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	activateUser(payload) {
		let options = getAjaxOptions({
			method: 'POST',
			url: URLS.COMPANY_ACTIVATE_USER,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify(payload),
			timeout: moment.duration(12, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	updateUser(payload) {
		let options = getAjaxOptions({
			method: 'POST',
			url: URLS.COMPANY_UPDATE_USER,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify(payload),
			timeout: moment.duration(12, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	inviteUser(payload) {
		let options = getAjaxOptions({
			method: 'POST',
			url: URLS.COMPANY_INVITE_USER,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify(payload),
			timeout: moment.duration(12, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	approveCurrencyTermsForUser(payload) {
		let options = getAjaxOptions({
			method: 'POST',
			url: URLS.APPROVE_CURRENCY_TERMS,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify(payload),
			timeout: moment.duration(12, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	resetPassword(payload) {
		let options = getAjaxOptions({
			method: 'POST',
			url: URLS.COMPANY_RESET_PASSWORD_USER,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify(payload),
			timeout: moment.duration(12, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	logError: function (payload) {
		console.log('sending error log to server (Ajax): ');
		var options = getAjaxOptions({
			method: 'POST',
			url: URLS.LOG,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify(payload),
			timeout: moment.duration(12, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	syncTravelBooster: function (payload) {
		const options = getAjaxOptions({
			method: 'POST',
			url: URLS.SYNC_TRAVEL_BOOSTER,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify(payload),
			timeout: moment.duration(12, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},

	getPoints: function (payload) {
		let options = getAjaxOptions({
			method: 'GET',
			url: URLS.POINTS,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: payload,
			timeout: moment.duration(12, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},
	updatePointsUsage: function (payload) {
		const options = getAjaxOptions({
			method: 'POST',
			url: URLS.POINTS,
			contentType: 'application/json; charset=utf-8',
			dataType: 'text', ///csv; charset=utf-8',
			data: JSON.stringify(payload),
			timeout: moment.duration(12, 'seconds').asMilliseconds(),
		});
		return ajax(options);
	},
	getRoomMappings: function (payload) {
		const options = getAjaxOptions({
			method: 'POST',
			url: URLS.MAPPING_EXPLORATION,
			contentType: 'application/json; charset=utf-8',
			dataType: 'text', ///csv; charset=utf-8',
			data: JSON.stringify(payload),
			timeout: moment.duration(5, 'minutes').asMilliseconds(),
		});
		return ajax(options);
	},

	generatePaymentLink: function (payload) {
		const options = getAjaxOptions({
			method: 'POST',
			url: URLS.GENERATE_PAYMENT_LINK,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			data: JSON.stringify(payload),
			timeout: moment.duration(5, 'minutes').asMilliseconds(),
		});
		return ajax(options);
	},
};

function getAjaxOptions(options) {
	if (!options || !options.method || !options.url) return null;
	var _options = {
		xhrFields: {
			withCredentials: true,
		},
		statusCode: {
			403: function () {
				console.log('Unauthorized!');
				(function deleteCookieBy(name) {
					document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=.arbitrip.com';
				})('logged_in');

				// show pop up (once), and then reload.
				if (options.url.indexOf(URLS.PROFILE) >= 0) {
					if (Config.rn) {
						document.location.href = Config.rn_link;
					} else if (Config.htz) {
						document.location.href = Config.htz_link;
					} else {
						document.location.href = URLS.LOGIN + '?ref_url=' + encodeURIComponent(document.URL);
					}
				} else {
					TIMEOUT = true;
					AuthActions.sessionTimeout();
				}
			},
		},
		contentType: 'application/json; charset=utf-8',
		headers: { 'Access-Control-Allow-Origin': '*.arbitrip.com' },
		dataType: 'json',
	};
	for (var option in options) {
		_options[option] = options[option];
	}
	return _options;
}

// function getAppFlags() {
//   return {
//     longTimeout: getUrlParameterByName('longTimeout') || false,
//     debug: getUrlParameterByName('longTimeout') || false,
//     localhost: localhost
//   }
// }

// function getUrlParameterByName(name, url) {
//   if (!url) url = window.location.href;
//   url = url.toLowerCase(); // This is just to avoid case sensitiveness
//   name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();// This is just to avoid case sensitiveness for query parameter name
//   var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
//     results = regex.exec(url);
//   if (!results) return null;
//   if (!results[2]) return '';
//   return decodeURIComponent(results[2].replace(/\+/g, " "));
// }

export default Communicator;
