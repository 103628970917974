/* global google*/
import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { dietPlace } from 'arbitrip-common/client/utils/PlacesWrapper';
import { Autocomplete, TextField } from '@mui/material';

class MaterialDesignGooglePlacesAutocomplete extends Component {
	constructor(props) {
		super(props);

		this.state = {
			dataSource: [],
			data: [],
		};

		this.autocompleteService = null;
		this.placesService = null;
		this.previousData = {};
	}

	componentDidMount() {
		if (google) {
			let map = new google.maps.Map(document.createElement('div'));
			this.autocompleteService = new google.maps.places.AutocompleteService();
			this.placesService = new google.maps.places.PlacesService(map);
		}
	}

	componentWillUnmount() {
		this.autocompleteService = null;
		this.placesService = null;
	}

	componentDidUpdate(prevProps) {
		if (this.props.searchText !== prevProps.searchText) {
			this.onUpdateInput(this.props.searchText);
		}
	}

	updateDatasource(data) {
		if (!data || !data.length) {
			return false;
		}

		if (this.state.data) {
			this.previousData = { ...this.state.data };
		}
		this.setState({
			dataSource: data.map((place) => place.description),
			data,
		});
	}

	getBounds() {
		if (!this.props.bounds || (!this.props.bounds.ne && !this.props.bounds.south)) {
			return undefined;
		}

		if (this.props.bounds.ne && this.props.bounds.sw && google) {
			return new google.maps.LatLngBounds(this.props.bounds.sw, this.props.bounds.ne);
		}

		return {
			...this.props.bounds,
		};
	}

	onUpdateInput(searchText) {
		if (!searchText || !searchText.length || !this.autocompleteService) {
			return false;
		}

		let request = {
			input: searchText,
			location: new google.maps.LatLng(this.props.location.lat, this.props.location.lng),
			radius: this.props.radius,
			types: this.props.types,
			bounds: this.getBounds(),
		};

		if (this.props.restrictions) {
			request.componentRestrictions = { ...this.props.restrictions };
		}

		this.autocompleteService.getPlacePredictions(request, this.updateDatasource.bind(this));
	}

	onNewRequest = (e, value) => {
		const { data } = this.state;
		const dataItem = _.find(data, { description: value });

		if (this.placesService && dataItem && dataItem.place_id) {
			let details_request = { placeId: dataItem.place_id };
			this.placesService.getDetails(details_request, (place, status) => {
				if (status === google.maps.places.PlacesServiceStatus.OK) {
					this.props.onNewRequest(dietPlace(place), value);
				} else {
					console.log('No details :(', value, dataItem);
					return false;
				}
			});
		}
	};

	onInputChange = (e, value) => {
		if (!value) {
			value = '';
		}

		this.props.onChange(value);
	};

	render() {
		const { searchText, location, radius, bounds, types, restrictions, ...autoCompleteProps } = this.props;

		return (
			<Autocomplete
				options={this.state.dataSource}
				filterOptions={this.props.filter}
				onChange={this.onNewRequest}
				onInputChange={this.onInputChange}
				fullWidth={autoCompleteProps.fullWidth}
				autoFocus={autoCompleteProps.autoFocus}
				openOnFocus={true}
				value={searchText}
				renderInput={(params) => (
					<TextField
						{...params}
						label={autoCompleteProps.label}
						placeholder={autoCompleteProps.placeholder}
						error={autoCompleteProps.error}
						variant="standard"
					/>
				)}
			/>
		);
	}
}

MaterialDesignGooglePlacesAutocomplete.propTypes = {
	location: PropTypes.object,
	radius: PropTypes.number,
	onNewRequest: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	getRef: PropTypes.func,
	types: PropTypes.arrayOf(PropTypes.string),
	bounds: PropTypes.object,
	restrictions: PropTypes.shape({
		country: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
	}),
};

MaterialDesignGooglePlacesAutocomplete.defaultProps = {
	location: { lat: 0, lng: 0 },
	radius: 0,
	filter: Autocomplete.noFilter,
};

export default MaterialDesignGooglePlacesAutocomplete;
