var _timer = null;
var _done = false;

var _default_duration_millis = 0;
var _callback = wrapCallback();

function wrapCallback(callback) {
  return function () {
    console.log("WRAPPED CALLBACK EXPIRED");
    _done = true;
    if (typeof callback === 'function') {
      return callback();
    }
  };
}

// duration millis is mandatory
function Timer(duration_millis, callback) {
  _default_duration_millis = duration_millis;
  _callback = wrapCallback(callback);

  this.stop = function () {
    if (_timer) {
      clearTimeout(_timer);
    }

    _timer = null;
    _done = false;
  };

  this.restart = function (duration_millis) {
    this.stop();
    this.start(duration_millis);
  };

  this.start = function (duration_millis) {
    console.log("TIMER STARTING...", duration_millis);
    if (!this.isActive()) {
      _timer = setTimeout(_callback, duration_millis || _default_duration_millis);
    }
  };

  this.isDone = function () {
    return _done;
  };

  this.isActive = function () {
    return !!_timer;
  };
}

export default Timer;
