const _ = require('lodash');
const mapping = require('arbitrip-common/theme/mapping');
const DEFAULT_THEME_CONFIG_NAME = 'default'; //defalt arbitrip theme colors config


export const loadThemeConfig = async (company_id) => {
  const company_theme_name = _.get(mapping, company_id, DEFAULT_THEME_CONFIG_NAME);

  let themeConfigModule;
  try {
    themeConfigModule = await import(`arbitrip-common/theme/colors/configs/${company_theme_name}.json`);
  } catch (error) {
    console.error('Error loading theme configuration:', error);
    themeConfigModule = await import(`arbitrip-common/theme/colors/configs/${DEFAULT_THEME_CONFIG_NAME}.json`);
  }

  return themeConfigModule.default;
};

// Flattens config file and sets css variables
export const setCssVariables = (themeConfig, prefix = '') => {
  Object.entries(themeConfig).forEach(([key, value]) => {
    if (typeof value === 'object' && value !== null) {
      // Recursive call for nested objects
      setCssVariables(value, `${prefix}${key}-`);
    } else {
      // Handle flat key-value pairs
      const cssVariableName = `--${prefix}${key.replace(/([A-Z])/g, '-$1').toLowerCase()}`;
      document.documentElement.style.setProperty(cssVariableName, value);
    }
  });
};

const toCamelCase = (str) => {
  return str.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
};

// Helper function to recursively transform object keys to camelCase
export const transformKeys = (obj) => {
  if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => {
      const camelCaseKey = toCamelCase(key);
      acc[camelCaseKey] = transformKeys(obj[key]); // Recursive call for nested objects
      return acc;
    }, Array.isArray(obj) ? [] : {});
  }
  return obj;
};
