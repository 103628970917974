import keyMirror from 'keymirror';
import _ from 'lodash';

const MANAGEMENT_ONGOING_CONSTANTS = {
	STATUS: {
		INITIAL: 'initial',
		BUSY: 'busy',
		SUCCESS: 'success',
		FAILED: 'failed',
	},
};

// Define action constants
export default _.extend(
	{},
	MANAGEMENT_ONGOING_CONSTANTS,
	keyMirror({
		GET_ONGOING_TRIPS: null,
		GET_ONGOING_TRIPS_SUCCESS: null,
		GET_ONGOING_TRIPS_FAILED: null,
	}),
);
