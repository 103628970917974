import React from 'react';
import createClass from 'create-react-class';

import TripStatusComponent from './trip-table/TripStatusComponent';
// import BookingStatus from '../bookings/BookingStatus.react';
import moment from 'moment';

import Currencies from '@/utils/Currencies';
import ManagementConstants from '@/constants/ManagementConstants';

const date_format = 'MMM D, YYYY';

// function formatDate(date) {
//   return date && moment(date).format('MMM D');
// }

function isReservationValid(reservation) {
	return (
		reservation &&
		(reservation.status.startsWith('pending') ||
			reservation.status.startsWith('approved') ||
			reservation.status.startsWith('quote'))
	);
}

function diffDays(from_date, to_date) {
	if (!from_date || !to_date) return 0;
	var to_date_moment = moment(to_date);
	var from_date_moment = moment(from_date);
	if (!from_date_moment.isValid()) {
		return 0;
	}
	if (!to_date_moment.isValid()) {
		return 1;
	}
	return to_date_moment.diff(from_date_moment, 'days') + 1; // inclusive number of dates
}

function buildTripBookedComponentsPayload(trip_booked_components, currency) {
	if (!trip_booked_components) {
		return '';
	}
	let earliest_moment = moment('2970-01-01');
	let latest_moment = moment('1970-01-01');

	let airfare_sum = 0;

	const trip_components_array_flights = trip_booked_components.airfares
		? trip_booked_components.airfares.map((airfare) => {
				airfare_sum += airfare.cost;

				let out = airfare;
				out.booked_airfare = true;
				out.price = airfare.cost;
				if (out.currency !== currency) {
					console.warn(
						' We are adding not same currencies in flight ... ' + airfare.currency + ' and ' + currency,
					);
				}
				out.flights = airfare.flights.map((f) => {
					let out = f;

					out.travel_booked_flight = true;

					out.departure_airport_name = f.depart_airport ? f.depart_airport.name : '';
					out.departure_airport_code = f.depart_airport ? f.depart_airport.icao_code : '';
					out.depart_date_moment = moment(f.depart_date);
					out.depart_date = out.depart_date_moment.format(date_format);

					out.arrival_airport_name = f.arrival_airport ? f.arrival_airport.name : '';
					out.arrival_airport_code = f.arrival_airport ? f.arrival_airport.icao_code : '';
					var arrival_date_moment = f.arrival_date ? moment(f.arrival_date) : null;
					if (
						arrival_date_moment &&
						arrival_date_moment.isValid() &&
						arrival_date_moment.isAfter(out.depart_date_moment)
					) {
						out.arrival_date_moment = arrival_date_moment;
						out.arrival_date = arrival_date_moment.format(date_format);

						if (out.arrival_date_moment.isAfter(latest_moment)) {
							latest_moment = out.arrival_date_moment;
						}
					}

					if (out.depart_date_moment.isBefore(earliest_moment)) {
						earliest_moment = out.depart_date_moment;
					}
					return out;
				});

				return out;
			})
		: [];

	let accommodation_sum = 0;
	const trip_components_array_accommodations = trip_booked_components.accommodations
		? trip_booked_components.accommodations
				.filter((resv) => isReservationValid(resv))
				.map((reservation) => {
					let out = reservation;

					accommodation_sum += reservation.deal.cheapopoPricing.cheapopoTotalPrice;

					out.booked_accommodation = true;
					out.name = reservation.hotel.name;
					out.address = reservation.hotel.address;
					out.check_in_moment = moment(reservation.deal.details.check_in);
					out.check_out_moment = moment(reservation.deal.details.check_out);
					out.check_in = out.check_in_moment.format(date_format);
					out.check_out = out.check_out_moment.format(date_format);
					out.price = reservation.deal.cheapopoPricing.cheapopoTotalPrice;
					out.currency = reservation.deal.cheapopoPricing.currency;

					if (out.check_in_moment.isBefore(earliest_moment)) {
						earliest_moment = out.check_in_moment;
					}
					if (out.check_out_moment.isAfter(latest_moment)) {
						latest_moment = out.check_out_moment;
					}
					return out;
				})
		: [];
	const trip_components_array_cars = [];
	const travel_booked_components = trip_components_array_flights
		.concat(trip_components_array_accommodations)
		.concat(trip_components_array_cars);
	return { travel_booked_components, accommodation_sum, airfare_sum, earliest_moment, latest_moment };
}

function getEstimationData(trip, currency) {
	let booked_itinerary = trip.booked_itinerary;
	let { airfare_sum, accommodation_sum, earliest_moment, latest_moment } = buildTripBookedComponentsPayload(
		booked_itinerary,
		currency,
	);
	currency = currency || 'USD';

	const vacation_days_total = diffDays(trip.vacation_days_from, trip.vacation_days_to);
	const total_days = latest_moment.diff(earliest_moment.clone().startOf('day'), 'days') + 1;
	const num_biz_days = Math.max(0, total_days - vacation_days_total);
	const per_diem = 60; // TODO! take from company
	const per_diem_total = per_diem * num_biz_days;

	const other_sum = trip.conference_estimated_cost_amount > 0 ? trip.conference_estimated_cost_amount : 0;

	let total = accommodation_sum + per_diem_total + other_sum + airfare_sum;

	return {
		flights: Math.round(airfare_sum),
		accommodations: Math.round(accommodation_sum),
		other: Math.round(other_sum),
		stay: num_biz_days,
		currency: currency,
		currency_symbol: Currencies.getSymbol(currency),
		per_diem: per_diem_total,
		total: total,
	};
}

const PreviewDetailedAccommodation = createClass({
	displayName: 'PreviewDetailedAccommodation',

	getInitialState: function () {
		let state = {
			new_trip: this.props.trip.status === ManagementConstants.TRIP_STATUS.INIT,
		};
		if (this.props.profile.travel_manager && !state.new_trip) {
			state.estimated_cost = getEstimationData(this.props.trip, this.props.currency);
		}
		return state;
	},

	render: function () {
		let { trip } = this.props;
		let { new_trip, estimated_cost } = this.state;

		return (
			<div className="accommodation">
				<div className="title">
					<span className="hotel-name">{trip.name}</span>
				</div>
				<div className="status">
					{new_trip ? 'New trip' : <TripStatusComponent trip={trip} show_sub_status={true} />}
				</div>

				{/*<div className="pic">*/}
				{/*</div>*/}
				{estimated_cost &&
				trip.status !== ManagementConstants.TRIP_STATUS.INIT &&
				trip.status !== ManagementConstants.TRIP_STATUS.DRAFT ? (
					<div className="section">
						<div className="title">
							<span>Cost Estimation:</span>
						</div>
						<div className="section-content">
							<div className="meta">
								<div className="field">
									<span className="field-name">Flights</span>
									<span className="field-value">
										{estimated_cost.currency_symbol + estimated_cost.flights}
									</span>
								</div>
								<div className="field">
									<span className="field-name">Hotels</span>
									<span className="field-value">
										{estimated_cost.currency_symbol + estimated_cost.accommodations}
									</span>
								</div>
								<div className="field">
									<span className="field-name">Per Diem</span>
									<span className="field-value">
										{estimated_cost.currency_symbol +
											estimated_cost.per_diem +
											(estimated_cost.stay ? ' (' + estimated_cost.stay + ' days)' : '')}
									</span>
								</div>
								{estimated_cost.other ? (
									<div className="field">
										<span className="field-name">Other</span>
										<span className="field-value">
											{estimated_cost.currency_symbol + estimated_cost.other}
										</span>
									</div>
								) : null}
							</div>
						</div>
						<div className="section-content title">
							<div className="field">
								<span className="field-name">Total</span>
								<span className="field-value">
									{estimated_cost.currency_symbol + estimated_cost.total}
								</span>
							</div>
						</div>
					</div>
				) : null}
			</div>
		);
	},
});

export default PreviewDetailedAccommodation;
