/**
 * Returns the Popup class.
 *
 * Unfortunately, the Popup class can only be defined after
 * google.maps.OverlayView is defined, when the Maps API is loaded.
 * This function should be called by initMap.
 */
import * as Sentry from '@sentry/react';
class PopUp {
	getPopupClass() {
		/**
		 * A customized popup on the map.
		 * @param {!google.maps.LatLng} position
		 * @param {!Element} content The bubble div.
		 * @constructor
		 * @extends {google.maps.OverlayView}
		 */
		function Popup(position, content, hotelId) {
			this.position = position;

			content.classList.add('popup-bubble');
			content.setAttribute('id', `popup-${hotelId}`);

			// This zero-height div is positioned at the bottom of the bubble.
			let bubbleAnchor = document.createElement('div');
			bubbleAnchor.classList.add('popup-bubble-anchor');
			bubbleAnchor.appendChild(content);

			let bubbleArrow = document.createElement('div');
			bubbleArrow.classList.add('popup-bubble-arrow');
			bubbleAnchor.appendChild(bubbleArrow);

			// This zero-height div is positioned at the bottom of the tip.
			this.containerDiv = document.createElement('div');
			this.containerDiv.classList.add('popup-container');
			this.containerDiv.appendChild(bubbleAnchor);

			// Optionally stop clicks, etc., from bubbling up to the map.
			if (window.google) {
				window.google.maps.OverlayView.preventMapHitsFrom(this.containerDiv);
			}
		}

		if (window.google) {
			// ES5 magic to extend google.maps.OverlayView.
			Popup.prototype = Object.create(window.google.maps.OverlayView.prototype);

			/** Called when the popup is added to the map. */
			Popup.prototype.onAdd = function () {
				this.getPanes().floatPane.appendChild(this.containerDiv);
			};

			/** Called when the popup is removed from the map. */
			Popup.prototype.onRemove = function () {
				if (this.containerDiv.parentElement) {
					try {
						this.containerDiv.parentElement.removeChild(this.containerDiv);
					} catch (err) {
						console.error(err);
						Sentry.captureException(err);
					}
				}
			};

			/** Called each frame when the popup needs to draw itself. */
			Popup.prototype.draw = function () {
				let divPosition = this.getProjection().fromLatLngToDivPixel(this.position);

				// Hide the popup when it is far out of view.
				let display = Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ? 'block' : 'none';

				if (display === 'block') {
					this.containerDiv.style.left = divPosition.x + 'px';
					this.containerDiv.style.top = divPosition.y + 'px';
				}
				if (this.containerDiv.style.display !== display) {
					this.containerDiv.style.display = display;
				}
			};
		}

		return Popup;
	}

	getStyle() {
		return {
			'& .popup-bubble': {
				position: 'absolute',
				top: 0,
				left: 0,
				transform: 'translate(-50%, -100%)',
				/* Style the bubble. */
				backgroundColor: 'white',
				padding: '7px 10px',
				borderRadius: 3,
				fontFamily: 'sans-serif',
				overflowY: 'auto',
				maxHeight: 60,
				boxShadow: '0px 2px 10px 1px rgba(0,0,0,0.5)',
				fontSize: 14,
				color: '#294c9b',
				cursor: 'pointer',
				transition: '.2s all ease',
				'&:hover, &.popup-active': {
					color: '#fff',
					backgroundColor: '#294c9b',
				},
				'&:hover + .popup-bubble-arrow, &.popup-active + .popup-bubble-arrow': {
					borderTop: '8px solid #294c9b',
				},
			},
			'& .popup-bubble-arrow': {
				position: 'absolute',
				top: '-1px',
				left: 0,
				transform: 'translate(-50%, 0)',
				width: 0,
				height: 0,
				borderLeft: '6px solid transparent',
				borderRight: '6px solid transparent',
				borderTop: '8px solid white',
				transition: '.2s all ease',
			},
			'& .popup-bubble-anchor': {
				position: 'absolute',
				width: '100%',
				bottom: 8,
				left: 0,
			},
			'& .popup-container': {
				cursor: 'auto',
				height: 0,
				position: 'absolute',
				width: 200,
			},
		};
	}
}

export default new PopUp();
