import React from 'react';
import createClass from 'create-react-class';
import { Tooltip } from '@mui/material';
import Currencies from '../utils/Currencies';

import Config from 'arbitrip-common/client/utils/Config';
import ProfileStore from '../stores/ProfileStore';
import DynamicPricingContainer from './pages/results/deal/DynamicPricingContainer';

const NotAvailableText = 'Not Available';

function normalizePriceForDisplay(price) {
	return Number(price.toFixed(2)).toLocaleString();
}

const DealPrice = createClass({
	displayName: 'DealPrice',

	getTooltipContent() {
		const {
			supplier,
			currency,
			netPrice,
			commission,
			displayTotalPrice,
			totalPrice,
			hasPriceChanged = false,
			faultDeal = false,
			profile,
			agentTotalPrice,
			agentCommission,
		} = this.props;

		const _profile = profile || {
			display_currency: this.props.currency,
			display_currency_exchange_rate: 1,
		};

		const cur = Currencies.getSymbol(currency);

		const total_price_base = agentTotalPrice || totalPrice;
		const total_price_na = total_price_base === null || typeof total_price_base === 'undefined';
		let _total_price = total_price_na ? NotAvailableText : normalizePriceForDisplay(total_price_base);

		const price_na = netPrice === null || typeof netPrice === 'undefined';
		let _netPrice = price_na ? NotAvailableText : normalizePriceForDisplay(netPrice);
		const price_cur = price_na ? null : cur;

		const commission_base = agentCommission || commission;
		const commission_na = commission_base === null || typeof commission_base === 'undefined';
		let _commission = commission_na ? NotAvailableText : normalizePriceForDisplay(commission_base);
		let commission_cur = commission_na ? null : cur;

		if (faultDeal) {
			_total_price = NotAvailableText;
			_commission = NotAvailableText;
			_netPrice = NotAvailableText;
			commission_cur = null;
		}

		const hasDifferentDisplayCurrency = currency !== _profile.display_currency;
		const totalPriceInDisplayCurrency = Currencies.getPriceWithDisplayCurrencyByPrecision(
			_total_price,
			_profile.display_currency_exchange_rate,
			_profile.display_currency,
			2,
		);
		const netPriceInDisplayCurrency = Currencies.getPriceWithDisplayCurrencyByPrecision(
			_netPrice,
			_profile.display_currency_exchange_rate,
			_profile.display_currency,
			2,
		);
		const commissionInDisplayCurrency = Currencies.getPriceWithDisplayCurrencyByPrecision(
			_commission,
			_profile.display_currency_exchange_rate,
			_profile.display_currency,
			2,
		);

		const tooltipValueClasses = `agent-tooltip-value${hasDifferentDisplayCurrency ? ' multiple-currencies' : ''}`;

		return (
			<div className="agent-tooltip">
				{supplier ? (
					<div className="agent-tooltip-item">
						<div className="agent-tooltip-title">Supplier</div>
						<div className="agent-tooltip-value">{supplier}</div>
					</div>
				) : null}
				{displayTotalPrice ? (
					<div className="agent-tooltip-item">
						<div className="agent-tooltip-title">Total Price</div>
						<div
							className={tooltipValueClasses}
							title={this.props.previousPrice}
							style={Config.dev_mode && hasPriceChanged ? { color: 'yellow' } : {}}
						>
							{total_price_na
								? _total_price
								: hasDifferentDisplayCurrency
									? `${totalPriceInDisplayCurrency} (${cur + _total_price})`
									: cur + _total_price}
						</div>
					</div>
				) : null}
				<div className="agent-tooltip-item">
					<div className="agent-tooltip-title">Net Price</div>
					<div className={tooltipValueClasses}>
						{price_na
							? _netPrice
							: hasDifferentDisplayCurrency
								? `${netPriceInDisplayCurrency} (${price_cur + _netPrice})`
								: price_cur + _netPrice}
					</div>
				</div>
				<div className="agent-tooltip-item">
					<div className="agent-tooltip-title">Commission</div>
					<div className={tooltipValueClasses}>
						{commission_na
							? _commission
							: hasDifferentDisplayCurrency
								? `${commissionInDisplayCurrency} (${commission_cur + _commission})`
								: commission_cur + _commission}
					</div>
				</div>
			</div>
		);
	},

	getContent() {
		const {
			currency,
			price,
			pricePerNight,
			faultDeal = false,
			profile,
			reservationMode = false,
			basketMode = false,
			agentTotalPrice,
			agentPricePerNight,
		} = this.props;

		const _profile = profile || {
			display_currency: this.props.currency,
			display_currency_exchange_rate: 1,
		};

		const price_base = basketMode ? agentPricePerNight || pricePerNight : agentTotalPrice || price;

		const hasDifferentDisplayCurrency = currency !== _profile.display_currency;

		return (
			<div className={faultDeal ? 'fault-deal' : ''}>
				{reservationMode && hasDifferentDisplayCurrency && <span className="approx">approx.</span>}

				{Currencies.getPriceWithDisplayCurrencyByPrecision(
					price_base,
					_profile.display_currency_exchange_rate,
					_profile.display_currency,
					0,
				)}
			</div>
		);
	},

	render() {
		const {
			enablePricingExtraInfo = false,
			contractClientCompanyId,
			dynamicPricing,
			currency,
			profile,
		} = this.props;

		return (
			<div style={{ display: 'inline-block' }}>
				{ProfileStore.shouldDisplayPricingExtraInfo(enablePricingExtraInfo, contractClientCompanyId) ? (
					<Tooltip placement="top" title={this.getTooltipContent()}>
						{this.getContent()}
					</Tooltip>
				) : (
					this.getContent()
				)}
				<DynamicPricingContainer dynamicPricing={dynamicPricing} profile={profile} currency={currency} />
			</div>
		);
	},
});

export default DealPrice;
