import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import CheckboxActiveIcon from './CheckboxActiveIcon';
import CheckboxInactiveIcon from './CheckboxInactiveIcon';
import { Checkbox } from '@mui/material';

const styles = {
	root: {
		padding: 0,
		margin: 6,
		marginRight: 12,
	},
};

function FilterCheckbox(props) {
	const { classes } = props;

	return (
		<Checkbox
			className={classes.root}
			icon={<CheckboxInactiveIcon />}
			checkedIcon={<CheckboxActiveIcon />}
			color="default"
			{...props}
		/>
	);
}

FilterCheckbox.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FilterCheckbox);
