import _ from 'lodash';
import React from 'react';
import moment from 'moment';

import BookingsConstants from '@/constants/BookingsConstants';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import TableSortLabel from '@mui/material/TableSortLabel';

import Currencies from 'arbitrip-common/general/utils/Currencies';
import Dates from 'arbitrip-common/client/utils/Dates';
import BookingsTableLoader from './BookingTableLoader.react';
import NoBookingsMessage from './NoBookingsMessage';
import BookingStatus from '@/utils/BookingStatus';

import { getIsUnpaid } from '../../../utils/PayLater';
import Tooltip from '@mui/material/Tooltip';

import { ReactComponent as SortIcon } from '@/img/bookings/sort.svg';
import { ReactComponent as SortIconAsc } from '@/img/bookings/sort-asc.svg';
import { ReactComponent as SortIconDesc } from '@/img/bookings/sort-desc.svg';

const { ORDER_BY, ORDER, ORDER_DEFAULTS, ORDER_OPPOSITES } = BookingsConstants;

const date_format = 'D MMM YYYY';

const border_spacing = {
	borderCollapse: 'separate',
	borderSpacing: '0 4px',
};

function getTravelersText(booking) {
	let names_cap = 4;
	let children_text = '';
	if (booking.travelers.length >= 4) {
		names_cap--;
	}
	if (booking.deal.details.children_count >= 1) {
		names_cap--;
		children_text = `${booking.deal.details.children_count} ${booking.deal.details.children_count > 1 ? 'Children' : 'Child'}`;
	}

	let travelers_text = booking.travelers
		.map((t, idx) => (idx < names_cap ? `${t.first_name} ${t.last_name}` : null))
		.join(', ');
	if (booking.travelers.length > names_cap) {
		const additional_adults = booking.travelers.length - names_cap;
		travelers_text += ` +${additional_adults} ${additional_adults > 1 ? 'Adults' : 'Adult'}`;
		if (children_text) {
			travelers_text += `, ${children_text}`;
		}
	} else if (children_text) {
		travelers_text += ` +${children_text}`;
	}

	return travelers_text;
}

function getCancellationDeadline(booking) {
	if (_.get(booking, 'deal.dca.nonRefundable') || !_.get(booking, 'deal.dca.dealIsFullyRefundableTill')) {
		return 'Non refundable';
	}

	const deadline = _.get(booking, 'deal.dca.dealIsFullyRefundableTill');

	return Dates.formatCancellationDeadline(deadline);
}

function getCancellationDeadlineClass(booking) {
	if (_.get(booking, 'deal.dca.nonRefundable') || !_.get(booking, 'deal.dca.dealIsFullyRefundableTill')) {
		return '';
	}
	const deadlineMoment = moment.utc(_.get(booking, 'deal.dca.dealIsFullyRefundableTill'), 'YYYY-MM-DDTHH:mm:ss.sssZ');
	let updatedDeadlineMoment;
	if (!deadlineMoment.hours()) {
		updatedDeadlineMoment = moment
			.utc(_.get(booking, 'deal.dca.dealIsFullyRefundableTill'), 'YYYY-MM-DDTHH:mm:ss.sssZ')
			.subtract(1, 'd');
	} else {
		updatedDeadlineMoment = deadlineMoment;
	}

	const isFutureReservation = booking && moment.utc().isBefore(updatedDeadlineMoment);
	return isFutureReservation ? '' : 'deadline_exited';
}

class BookingsTable extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			rows_per_page_options: [
				// 5,
				// 10,
				// 25,
				// ...(Config.dev_mode ? [{ label: 'All', value: -1 }] : [])
				BookingsConstants.BOOKINGS_LIMIT,
			],
		};

		// // Avoid a layout jump when reaching the last page with empty rows.
		// this.state.empty_rows = (this.state.page > 0)
		//     ? Math.max(0, (1 + this.state.page) * this.state.rows_per_page - this.state.rows.length)
		//     : 0;

		this.handleChangePage = this.handleChangePage.bind(this);
		this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
		this.createSortHandler = this.createSortHandler.bind(this);
		this.onRequestSort = this.onRequestSort.bind(this);
		this.getSortIcon = this.getSortIcon.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (prevProps?.points?.balance !== this.props?.points?.balance) {
			if (this.props.selectedBooking) {
				this.props.updateDrawer(this.props.selectedBooking);
			}
		}
	}

	getSortIcon(orderBy) {
		if (this.props.sort.order_by === orderBy) {
			switch (this.props.sort.order) {
				case ORDER.ASC:
					return <SortIconAsc />;

				case ORDER.DESC:
					return <SortIconDesc />;

				default:
					return <SortIcon />;
			}
		}

		return <SortIcon />;
	}

	areFiltersActive() {
		const { filters } = this.props;
		return _.some(filters, (value) => !_.isEmpty(value));
	}

	handleChangePage(event, newPage) {
		console.log('handleChangePage', event.target.value, newPage);
		this.props.setPagination({
			...this.props.pagination,
			page: newPage,
		});
	}

	handleChangeRowsPerPage(event, newRowsPerPage) {
		console.log('handleChangeRowsPerPage', event.target.value, newRowsPerPage);
		this.props.setPagination({
			...this.props.pagination,
			rows_per_page: parseInt(event.target.value, 10),
		});
	}

	createSortHandler(property) {
		const _self = this;
		return (event) => {
			_self.onRequestSort(event, property);
		};
	}

	onRequestSort(event, property) {
		const { order_by, order } = this.props.sort;
		const new_order_by = property;

		const default_order = ORDER_DEFAULTS[new_order_by];

		const new_order =
			order_by !== new_order_by
				? default_order
				: order !== default_order
					? default_order
					: ORDER_OPPOSITES[default_order];

		this.props.setSort({
			order_by: new_order_by,
			order: new_order,
		});
	}

	renderNoBookingsMessage() {
		const { status, bookings, displayTabs } = this.props;

		if (status !== BookingsConstants.STATUS.SUCCESS || bookings.length !== 0) {
			return null;
		}

		if (this.areFiltersActive()) {
			return (
				<NoBookingsMessage
					iconType="filter"
					message="We did not find any results for those filters!"
					subMessage=""
				/>
			);
		}

		if (displayTabs) {
			return (
				<NoBookingsMessage
					iconType="default"
					message="It looks like there are no bookings under this tab."
					subMessage="Explore other tabs for more details!"
				/>
			);
		}

		return (
			<NoBookingsMessage
				iconType="default"
				message="It looks like you haven't booked any yet."
				subMessage="Start now and become a travel champion!"
			/>
		);
	}

	render() {
		const { profile, bookings, bookingsCount, selectedBooking, openDrawer, status, pagination, sort, showLoader } =
			this.props;

		const { rows_per_page_options } = this.state;
		const { page, rows_per_page } = pagination;

		const { order_by, order } = sort;

		const _self = this;

		return (
			<div className="bookings-table-component">
				{(status === BookingsConstants.STATUS.BUSY || showLoader) && (
					<BookingsTableLoader profile={profile} classes={{ border_spacing }} />
				)}

				{bookings.length > 0 && status !== BookingsConstants.STATUS.BUSY && (
					<TableContainer component={Paper} className="bookings-table-container">
						<Table className="bookings-table" aria-label="bookings table" sx={{ ...border_spacing }}>
							<TableHead>
								<TableRow>
									{profile.agent && <TableCell className="cell__client">Client</TableCell>}
									<TableCell className="cell__hotel">Hotel</TableCell>
									<TableCell className="cell__city">City</TableCell>
									<TableCell
										className="cell__date check-in"
										//  key={headCell.id}
										//  align={headCell.numeric ? 'right' : 'left'}
										align="left"
										//  padding={headCell.disablePadding ? 'none' : 'normal'}
										sortDirection={order_by === ORDER_BY.CHECK_IN ? order : false}
									>
										<TableSortLabel
											active={order_by === ORDER_BY.CHECK_IN}
											direction={order_by === ORDER_BY.CHECK_IN ? order : ORDER.ASC}
											onClick={this.createSortHandler(ORDER_BY.CHECK_IN)}
											IconComponent={() => _self.getSortIcon(ORDER_BY.CHECK_IN)}
											aria-label={`Check-in column, currently ${order_by === ORDER_BY.CHECK_IN ? `sorted in ${order === 'asc' ? 'ascending' : 'descending'} order` : 'not sorted. Click to sort'}`}
										>
											Check-In
											{/* {orderBy === headCell.id ? (
                                            <Box component="span" sx={visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null} */}
										</TableSortLabel>
									</TableCell>
									<TableCell
										className="cell__date check-out"
										align="left"
										sortDirection={order_by === ORDER_BY.CHECK_OUT ? order : false}
									>
										<TableSortLabel
											active={order_by === ORDER_BY.CHECK_OUT}
											direction={
												order_by === ORDER_BY.CHECK_OUT
													? order
													: ORDER_DEFAULTS[ORDER_BY.CHECK_OUT]
											}
											onClick={this.createSortHandler(ORDER_BY.CHECK_OUT)}
											IconComponent={() => _self.getSortIcon(ORDER_BY.CHECK_OUT)}
											aria-label={`Check-out column, ${order_by === ORDER_BY.CHECK_OUT ? `sorted in ${order === 'asc' ? 'ascending' : 'descending'} order` : 'not sorted. Click to sort'}`}
										>
											Check-Out
										</TableSortLabel>
									</TableCell>
									{/* <TableCell className='short-id'>ID</TableCell> */}
									<TableCell className="cell__travelers">Traveler(s)</TableCell>
									{/* <TableCell className='cell__number rooms'>Room(s)</TableCell>
                                    <TableCell className='cell__number nights'>Night(s)</TableCell> */}
									<TableCell className="cell__id">Reservation ID</TableCell>
									<TableCell
										className="cell__cancellation"
										align="left"
										sortDirection={order_by === ORDER_BY.CANCELLATION_DEADLINE ? order : false}
									>
										<TableSortLabel
											active={order_by === ORDER_BY.CANCELLATION_DEADLINE}
											direction={
												order_by === ORDER_BY.CANCELLATION_DEADLINE
													? order
													: ORDER_DEFAULTS[ORDER_BY.CANCELLATION_DEADLINE]
											}
											onClick={this.createSortHandler(ORDER_BY.CANCELLATION_DEADLINE)}
											IconComponent={() => this.getSortIcon(ORDER_BY.CANCELLATION_DEADLINE)}
											aria-label={`Cancellation deadline column, ${order_by === ORDER_BY.CANCELLATION_DEADLINE ? `sorted in ${order === 'asc' ? 'ascending' : 'descending'} order` : 'not sorted. Click to sort'}`}
										>
											Cancellation Before
										</TableSortLabel>
									</TableCell>

									<TableCell className="cell__price">Total Price</TableCell>
									<TableCell
										className="booking-status"
										align="left"
										sortDirection={order_by === ORDER_BY.STATUS ? order : false}
									>
										<TableSortLabel
											active={order_by === ORDER_BY.STATUS}
											direction={
												order_by === ORDER_BY.STATUS ? order : ORDER_DEFAULTS[ORDER_BY.STATUS]
											}
											onClick={this.createSortHandler(ORDER_BY.STATUS)}
											IconComponent={() => _self.getSortIcon(ORDER_BY.STATUS)}
											aria-label={`Status column, ${order_by === ORDER_BY.STATUS ? `sorted in ${order === 'asc' ? 'ascending' : 'descending'} order` : 'not sorted. Click to sort'}`}
										>
											Status
										</TableSortLabel>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{bookings.map((booking) => {
									const travelers = getTravelersText(booking);

									const booking_status = BookingStatus.getBookingStatus(booking.status);

									return (
										<TableRow
											key={booking.id}
											onClick={() => openDrawer(booking)}
											selected={selectedBooking && selectedBooking.id === booking.id}
											className="booking-row"
											tabIndex={0}
											role="button"
											aria-label={`View more details about booking ${booking.short_id} to ${booking.hotel.name}, ${booking.hotel.city}`}
											onKeyDown={(event) => {
												if (event.key === 'Enter') {
													openDrawer(booking);
												}
											}}
										>
											{profile.agent && (
												<TableCell className="cell__client">
													<Tooltip title={booking.company.name}>
														<div className="booking-client-logo-container">
															<img
																src={booking.company.picture}
																className="booking-client-logo"
																alt={booking.company.name}
															/>
														</div>
													</Tooltip>
												</TableCell>
											)}
											<TableCell className="cell__hotel">{booking.hotel.name}</TableCell>
											<TableCell className="cell__city">{booking.hotel.city}</TableCell>
											<TableCell className="cell__date">
												{moment(booking.deal.details.check_in).format(date_format)}
											</TableCell>
											<TableCell className="cell__date">
												{moment(booking.deal.details.check_out).format(date_format)}
											</TableCell>
											{/* <TableCell className='cell__short-id'>{booking.short_id}</TableCell> */}
											<TableCell className="cell__travelers">{travelers}</TableCell>
											{/* <TableCell
                                                className='cell__number'>{booking.deal.details.room_count}</TableCell>
                                            <TableCell
                                                className='cell__number'>{booking.deal.details.nights}</TableCell> */}
											<TableCell className="cell__id">{booking.short_id}</TableCell>
											<TableCell
												className={`cell__cancellation ${getCancellationDeadlineClass(booking)}`}
											>
												{getCancellationDeadline(booking)}
											</TableCell>
											<TableCell className="cell__price">
												{Currencies.getPriceWithCurrency(
													booking.deal.cheapopoPricing.cheapopoTotalPrice,
													booking.deal.cheapopoPricing.currency,
												)}
											</TableCell>
											<TableCell
												className={`booking-status ${getIsUnpaid(profile, booking) ? 'unpaid' : booking.status}`}
											>
												● {booking_status}
												<br />
												{getIsUnpaid(profile, booking) && (
													<span className="booking-status pending indent">Unpaid</span>
												)}
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
							{bookingsCount > 0 && (
								<TableFooter>
									<TableRow>
										<TablePagination
											rowsPerPageOptions={rows_per_page_options}
											labelDisplayedRows={({ from, to, count }) =>
												`${from} - ${to} of ${count}${count % 100 ? '' : '+'}`
											}
											count={bookingsCount}
											rowsPerPage={rows_per_page}
											page={page}
											SelectProps={{
												inputProps: {
													'aria-label': 'rows per page',
												},
												native: true,
											}}
											onPageChange={this.handleChangePage}
											// onRowsPerPageChange={this.handleChangeRowsPerPage}
											ActionsComponent={TablePaginationActions}
											// ActionsComponent={BookingsTablePaginationActions}
											showFirstButton={false}
											showLastButton={false}
										/>
									</TableRow>
								</TableFooter>
							)}
						</Table>
					</TableContainer>
				)}

				{this.renderNoBookingsMessage()}
			</div>
		);
	}
}

export default BookingsTable;
