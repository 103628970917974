import React, { Component } from 'react';
import ManagementActions from '../../../actions/ManagementActions';
import ManagementConstants from '../../../constants/ManagementConstants';
import { TextField } from '@mui/material';

class AdditionalInfo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hasChanged: false,
		};
	}

	componentDidUpdate(prevProps) {
		const { trip_request } = this.props;
		const prevTripRequest = prevProps.trip_request;
		const nextTripRequest = trip_request;

		const hasChanged =
			prevTripRequest &&
			nextTripRequest &&
			prevTripRequest.id === nextTripRequest.id &&
			prevTripRequest.special_requests !== nextTripRequest.special_requests;

		if (hasChanged) {
			this.updateMetaData(950);
		}
	}

	handleOnBlur = () => {
		this.updateMetaData(4);
	};

	updateMetaData(delay) {
		if (this.state.hasChanged) {
			ManagementActions.editTripRequestMeta(delay);
		}
	}

	handleSpecialRequestOnChange = (e) => {
		ManagementActions.updateSpecialRequests(e.target.value);
		this.setState({ hasChanged: true });
	};

	getVacationDaysPopupContainer = (trigger) => {
		console.log('TRIGGER:', trigger);
		return document.getElementById('vacation-days');
	};

	render() {
		const { trip_request, can_edit } = this.props;

		let special_requests = trip_request.special_requests;
		if (!can_edit && !special_requests) {
			special_requests = ManagementConstants.NO_VALUE;
		}

		return (
			<div className="additional-info section">
				<TextField
					fullWidth
					multiline
					label="Special Requests and Notes"
					value={special_requests}
					onBlur={this.handleOnBlur}
					onChange={this.handleSpecialRequestOnChange}
					disabled={!can_edit}
					variant="standard"
				/>
			</div>
		);
	}
}

export default AdditionalInfo;
