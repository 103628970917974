import _ from 'lodash';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';

import React from 'react';
import PropTypes from 'prop-types';

import ProfileStore from '../../stores/ProfileStore';
import SEARCH_CONSTANTS from 'arbitrip-common/general/constants/search-constants';

const styles = (theme) => ({
	message: {
		display: 'inline-flex',
		alignItems: 'center',
		gap: '8px',
		fontSize: 15,
		color: theme.palette.pending.orange,
		backgroundColor: theme.palette.white,
		padding: '4px 10px',
		borderRadius: 6,
		marginTop: 10,
	},
});

function getState() {
	return {
		profile: ProfileStore.getProfile(),
	};
}

class SearchMessage extends React.Component {
	constructor(props) {
		super(props);

		this.state = getState();

		this._onChange = this._onChange.bind(this);
	}

	shouldShowButton(key) {
		const { i18n, t } = this.props;
		return i18n.exists(key) && !_.isEmpty(t(key));
	}

	getNumberOfNightsText() {
		const { nights } = this.props;
		return nights + (nights === 1 ? ' night ' : ' nights ') + 'stay';
	}

	getMailToUpdate() {
		const { t } = this.props;
		const { profile } = this.state;
		const supportEmail = t('HelpEmail');
		const fullName = profile.name;
		const subject = 'Reservation Request For ' + fullName;
		const mailtoUpdate = encodeURI('mailto:' + supportEmail + '?subject=' + subject);

		return (
			<a href={mailtoUpdate} target="_blank">
				{supportEmail}
			</a>
		);
	}

	render() {
		const { classes, nights } = this.props;

		return nights > SEARCH_CONSTANTS.MAX_DURATION_NUMBER_OF_NIGHTS ? (
			<div className={classes.message}>
				<div className="exclamation icon"></div>
				{this.shouldShowButton('HelpEmail') ? (
					<span>
						To book more than {SEARCH_CONSTANTS.MAX_DURATION_NUMBER_OF_NIGHTS} nights, please contact our
						team at&nbsp;{this.getMailToUpdate()}
					</span>
				) : (
					<span>Trip length is limited to 29 days, please select a shorter time frame</span>
				)}
			</div>
		) : null;
	}

	_onChange() {
		this.setState(getState());
	}

	componentDidMount() {
		ProfileStore.addChangeListener(this._onChange);
	}

	componentWillUnmount() {
		ProfileStore.removeChangeListener(this._onChange);
	}
}

SearchMessage.propTypes = {
	nights: PropTypes.number.isRequired,
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withTranslation()(SearchMessage));
