import keyMirror from 'keymirror';
import _ from 'lodash';

const PROFILE_CONSTANTS = {
	STATUS: {
		INITIAL: 'initial',
		BUSY: 'busy',
		IDLE: 'idle',
		SUCCESS: 'success',
		FAILED: 'failed',
	},
	REPORTS_COMPANY_ALL: {
		_id: '5b30cb8b2cf3686778d592d1',
		company: {
			_id: '5b30cb8b2cf3686778d592d1',
			name: 'All Bookings',
		},
		active: true,
	},
};

// Define action constants
export default _.extend(
	{},
	PROFILE_CONSTANTS,
	keyMirror({
		RECEIVE_PROFILE_DATA: null, // After postback

		SAVE_PROFILE: null,
		PROFILE_SAVED: null,
		PROFILE_SAVE_FAILED: null,

		CHANGE_DISPLAY_CURRENCY: null,
		DISPLAY_CURRENCY_CHANGED: null,
		DISPLAY_CURRENCY_CHANGE_FAILED: null,

		TOGGLE_ARBITRIP_POINTS_APPLIED: null,

		RESET_SAVE_PROFILE_STATUS: null,
		UPDATE_APPROVED_CURRENCY_TERMS: null,
		CHANGE_CHARGE_CURRENCY: null,

		GET_POINTS: null,
		GET_POINTS_SUCCESS: null,
		GET_POINTS_FAIL: null,
	}),
);
