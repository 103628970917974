const REACT_APP_GCASH_DEBUG_MODE = process.env.REACT_APP_GCASH_DEBUG_MODE;
const REACT_APP_JWT_TOKEN = process.env.REACT_APP_JWT_TOKEN;

async function request(url, params = {}, new_headers = {}, method = 'GET') {
    let headers = {
        'Content-Type': 'application/json',
        ...(REACT_APP_GCASH_DEBUG_MODE && {
            'ngrok-skip-browser-warning': true,
            'x-authorization': REACT_APP_JWT_TOKEN
        })
        // 'Content-Type': 'application/x-www-form-urlencoded',

        // 'Accept': 'application/json',
        // 'Content-Type': 'application/json; charset=utf-8',
        // 'Access-Control-Allow-Origin': '*.arbitrip.com'
    };

    // override headers with new headers
    Object.assign(headers, new_headers);
    
    const options = {
        method,
        credentials: 'include',
        headers,
        // mode: 'cors', // no-cors, *cors, same-origin
        // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        // credentials: 'same-origin', // include, *same-origin, omit
        // redirect: 'follow', // manual, *follow, error
        // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        // body: JSON.stringify(data) // body data type must match "Content-Type" header
    };

    if (params) {
        if (method === 'GET') {
            url += '?' + objectToQueryString(params);
        } else {
            options.body = JSON.stringify(params);
        }
    }

    const response = await fetch(url, options);

    if (response.status !== 200) {
        return generateErrorResponse('The server responded with an unexpected status.');
    }

    // console.log('FETCH RESPONSE', response);

    const result = await response.json();

    // console.log('FETCH RESULT', result);

    return result;

}

function objectToQueryString(obj) {
    return Object.keys(obj).map(key => key + '=' + obj[key]).join('&');
}

function generateErrorResponse(message) {
    return {
        status: 'error',
        message
    };
}

function get(url, params, headers) {
    return request(url, params, headers);
}

function create(url, params, headers) {
    return request(url, params, headers, 'POST');
}

function update(url, params, headers) {
    return request(url, params, headers, 'PUT');
}

function remove(url, params, headers) {
    return request(url, params, headers, 'DELETE');
}

export default {
    get,
    'post': create,
    'put': update,
    'delete': remove
};
