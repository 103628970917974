import _ from 'lodash';
import AppDispatcher from '../dispatcher/AppDispatcher';
import BookingsConstants from '../constants/BookingsConstants';
import BookingsStore from '../stores/BookingsStore';
import Ajax from '../utils/Ajax';
import ProfileStore from '../stores/ProfileStore';

const GET_BOOKINGS_DEBOUNCED_TIMEOUT = 500;

const BookingsActions = {
	updatePaymentMethodToCreditCard: function (id) {
		AppDispatcher.handleAction({
			actionType: BookingsConstants.UPDATE_PAYMENT_TYPE_TO_CREDIT_CARD,
			data: {
				id,
			},
		});
	},
	updateAgentNotes: function (reservationId, notes) {
		AppDispatcher.handleAction({
			actionType: BookingsConstants.UPDATE_AGENT_NOTES,
		});

		Ajax.updateReservationAgentNotes(reservationId, notes)
			.done((res) => {
				AppDispatcher.handleAction({
					actionType: BookingsConstants.UPDATE_AGENT_NOTES_SUCCEEDED,
					data: { response: res, reservation_id: reservationId, notes },
				});
			})
			.fail((err) => {
				AppDispatcher.handleAction({
					actionType: BookingsConstants.UPDATE_AGENT_NOTES_FAILED,
					data: { error: err, reservation_id: reservationId, notes },
				});
			});
	},

	setBookingsTransitionStatus: function () {
		AppDispatcher.handleAction({
			actionType: BookingsConstants.BOOKINGS_TRANSITION,
		});
	},

	getBookings: function (skip, limit, filters, orderBy, order) {
		let profile = ProfileStore.getProfile();

		console.log('getting ' + limit + ' bookings...');

		if (BookingsStore.getStatus() === BookingsConstants.STATUS.BUSY) {
			return console.warn('Passing bookings request, previous request is still running.');
		}

		AppDispatcher.handleAction({
			actionType: BookingsConstants.RETRIEVE_BOOKINGS,
		});

		var getBookingsAjaxCallback = profile.travel_manager
			? // ? Ajax.getCompanyBookings
				Ajax.getCompanyBookingsAndPrivateTravel
			: Ajax.getPersonalBookings;

		if (profile.agent) {
			getBookingsAjaxCallback = Ajax.getAgencyBookings;
		}

		getBookingsAjaxCallback(skip, limit, filters, orderBy, order)
			.done(function (res) {
				console.log('Got Bookings: ', res);
				let load_more_disabled = null;
				if (skip === 0 && res.bookings.length < BookingsConstants.BOOKINGS_LIMIT) load_more_disabled = true;
				else if (skip === 0) {
					load_more_disabled = false;
				}
				AppDispatcher.handleAction({
					actionType: BookingsConstants.BOOKINGS_RETRIEVAL_SUCCEEDED,
					data: { response: res, has_limit: !!limit, skip, load_more_disabled }, // ???
				});
			})
			.fail(function (err) {
				console.error('Error while getting bookings: ', err);
				AppDispatcher.handleAction({
					actionType: BookingsConstants.BOOKINGS_RETRIEVAL_FAILED,
					data: err, // ???
				});
			});
	},

	downloadedBookingsCsv: function (is_agent, filter_state, export_type, active_tab) {
		console.log('getting downloadedBookingsCsv...');

		if (BookingsStore.getStatus() === BookingsConstants.STATUS.BUSY) {
			return console.warn('Passing downloadedBookingsCsv request, previous request is still running');
		}

		AppDispatcher.handleAction({
			actionType: BookingsConstants.DOWNLOADED_BOOKINGS_CSV,
		});

		Ajax.getBookingsCsv(is_agent, filter_state, export_type, active_tab)
			.done(function (res) {
				console.log('Got download booking csv');
				AppDispatcher.handleAction({
					actionType: BookingsConstants.DOWNLOADED_BOOKINGS_CSV_SUCCEEDED,
					data: { response: res, export_type, active_tab }, // ???
				});
			})
			.fail(function (err) {
				console.error('Error while downloading csv bookings: ', err);
				AppDispatcher.handleAction({
					actionType: BookingsConstants.DOWNLOADED_BOOKINGS_CSV_FAILED,
					data: err, // ???
				});
			});
	},

	// TODO: implement booking requests properly (atm)
	downloadedBookingRequestsCsv: function (is_agent, filter_state) {
		console.log('getting downloadedBookingRequestsCsv...');

		if (BookingsStore.getRequestsStatus() === BookingsConstants.STATUS.BUSY) {
			return console.warn('Passing downloadedBookingRequestsCsv request, previous request is still running');
		}

		AppDispatcher.handleAction({
			actionType: BookingsConstants.DOWNLOADED_BOOKING_REQUESTS_CSV,
		});

		Ajax.getBookingRequestsCsv(is_agent, filter_state)
			.done(function (res) {
				console.log('Got download booking requests csv');
				AppDispatcher.handleAction({
					actionType: BookingsConstants.DOWNLOADED_BOOKING_REQUESTS_CSV_SUCCEEDED,
					data: res, // ???
				});
			})
			.fail(function (err) {
				console.error('Error while downloading csv of booking requests: ', err);
				AppDispatcher.handleAction({
					actionType: BookingsConstants.DOWNLOADED_BOOKING_REQUESTS_CSV_FAILED,
					data: err, // ???
				});
			});
	},

	// TODO: cancel booking, get voucher...

	getRequests: function (skip, limit, filters) {
		let profile = ProfileStore.getProfile();

		console.log('getting requests...');

		if (BookingsStore.getRequestsStatus() === BookingsConstants.STATUS.BUSY) {
			return console.warn('Passing bookings-requests request, previous request is still running.');
		}

		AppDispatcher.handleAction({
			actionType: BookingsConstants.RETRIEVE_REQUESTS,
		});

		var getRequestsAjaxCallback = profile.travel_manager ? Ajax.getCompanyRequests : Ajax.getPersonalRequests;

		getRequestsAjaxCallback(skip, limit, filters)
			.done(function (res) {
				console.log('Got Requests: ', res);
				AppDispatcher.handleAction({
					actionType: BookingsConstants.REQUESTS_RETRIEVAL_SUCCEEDED,
					data: res, // ???
				});
			})
			.fail(function (err) {
				console.error('Error while getting requests: ', err);
				AppDispatcher.handleAction({
					actionType: BookingsConstants.REQUESTS_RETRIEVAL_FAILED,
					data: err, // ???
				});
			});
	},

	////////////////////////////////////////////////////////////////////////////////

	syncTravelBooster: function (reservationId, travelBoosterDocketNumber) {
		const payload = {
			reservation_id: reservationId,
			...(travelBoosterDocketNumber ? { travel_booster_docket_number: travelBoosterDocketNumber } : {}),
		};

		AppDispatcher.handleAction({
			actionType: BookingsConstants.SYNC_TRAVEL_BOOSTER,
			// data: payload
		});

		Ajax.syncTravelBooster(payload)
			.done((res) => {
				console.log('Synced Travel Booster:', res);
				const {
					// docket_number,
					synced,
					// message
				} = res;
				AppDispatcher.handleAction({
					actionType: synced
						? BookingsConstants.SYNC_TRAVEL_BOOSTER_SUCCEEDED
						: BookingsConstants.SYNC_TRAVEL_BOOSTER_FAILED,
					data: {
						...payload,
						response: res,
					},
				});
			})
			.fail((err) => {
				console.error('Error while syncing Travel Booster:', err);
				AppDispatcher.handleAction({
					actionType: BookingsConstants.SYNC_TRAVEL_BOOSTER_FAILED,
					data: {
						...payload,
						error: err,
					},
				});
			});
	},

	updateAgentPricing: function (booking, agentPricing) {
		AppDispatcher.handleAction({
			actionType: BookingsConstants.UPDATE_AGENT_PRICING_ON_BOOKING,
			data: {
				reservation_id: booking?._id || booking?.id,
				agent_pricing: agentPricing,
			},
		});
	},
};

BookingsActions.getBookingsDebounced = _.debounce(BookingsActions.getBookings, GET_BOOKINGS_DEBOUNCED_TIMEOUT);
BookingsActions.getRequestsDebounced = _.debounce(BookingsActions.getRequests, GET_BOOKINGS_DEBOUNCED_TIMEOUT);

export default BookingsActions;
