import withStyles from '@mui/styles/withStyles';

import PropTypes from 'prop-types';

import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { ReactComponent as GroupIcon } from '../../img/search_page/search_bar/group.svg';
import { styled } from '@mui/material/styles';
import { lightBlue } from '@mui/material/colors';

import Analytics from 'arbitrip-common/client/analytics';

const modal_content =
	'Discover effortless group travel planning tailored to corporate needs. Our dedicated customer success team specializes in seamless group bookings for conferences, incentives, and events. They ensure a stress-free experience, handling logistics with attention to detail. Focus on creating lasting memories with your team, while we take care of the rest.';
const group_booking_request_url = process.env.REACT_APP_GROUP_BOOKING_REQUEST_URL;

const styles = (theme) => ({
	companies: {
		display: 'flex',
		alignItems: 'center',
		marginTop: 30,
		position: 'relative',
		zIndex: -1,
		float: 'left',
	},
	button: {
		width: 195,
		height: 45,
		border: '1px solid #275180',
		color: '#275180',
		overflow: 'hidden',
		position: 'relative',

		fontFamily: 'Lato',
		fontSize: 14,
		fontWeight: 700,
		lineHeight: '17px',
		letterSpacing: 0,
		textAlign: 'center',
	},
	ribbon: {
		background: '#FF9D0A',
		color: '#FAFAFA',
		transform: 'rotate(45deg)',
		position: 'absolute',
		top: 8,
		right: -37,
		width: 100,

		// width: 56,
		height: 12,
		// top: -10.5,
		// left: 165.5,

		fontFamily: 'Lato',
		fontSize: 11,
		fontWeight: 500,
		lineHeight: '11px',
		letterSpacing: 0,
		textAlign: 'center',
	},
	bookings: {
		marginRight: 20,
	},
	font: {
		fontSize: 18,
		color: theme.palette.white,
		textShadow: '0 0 13px rgba(0,0,0,.4)',
	},
	fontDark: {
		fontSize: 18,
		color: theme.palette.black.main,
	},
	dialogPaper: {
		padding: 24,
		width: 524,
		height: 603,
		textAlign: 'center',
		borderRadius: 10,
	},
	dialogTitle: {
		// width: 152,
		// height: 25,
		marginTop: 36,

		fontSize: 21,
		fontWeight: 800,
		lineHeight: '25px',
		letterSpacing: 0,
		textAlign: 'center',

		color: 'var(--general-colors-black)',
	},
	dialogContentContainer: {
		overflow: 'hidden',
	},
	dialogContent: {
		// width: 444,
		// height: 126,
		// marginTop: 8,
		marginTop: 10,

		fontFamily: 'Lato',
		fontSize: 15,
		fontWeight: 400,
		lineHeight: '21px',
		letterSpacing: 0,

		color: 'var(--general-colors-black)',

		// textWrap: 'balance'
		textAlign: 'justify',
		wordBreak: 'keep-all',
		padding: '0 5%',
	},
	dialogActions: {
		justifyContent: 'center',
	},
	dialogButton: {
		height: 41,
		borderRadius: 5,

		fontFamily: 'Lato',
		fontSize: 14,
		fontWeight: 700,
		lineHeight: '17px',
		letterSpacing: 0,
		textAlign: 'center',

		color: '#275180',
		textTransform: 'none',
	},
});

const ColorButton = styled(Button)(({ theme }) => ({
	color: theme.palette.getContrastText(lightBlue[100]),
	backgroundColor: lightBlue[100],
	'&:hover': {
		backgroundColor: lightBlue[200],
	},
}));

class GroupBookings extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			group_bookings_modal_open: false,
		};

		this.openGroupBookingsModal = this.openGroupBookingsModal.bind(this);
		this.handleGroupBookingsModalClose = this.handleGroupBookingsModalClose.bind(this);
		this.openGroupBookingRequestUrl = this.openGroupBookingRequestUrl.bind(this);
	}

	openGroupBookingsModal() {
		this.setState({ group_bookings_modal_open: true });

		Analytics.actions.interactions.showGroupBookingsModal(this.props.profile);
	}

	handleGroupBookingsModalClose() {
		this.setState({ group_bookings_modal_open: false });
	}

	openGroupBookingRequestUrl() {
		window.open(group_booking_request_url, '_blank', 'noreferrer');

		Analytics.actions.interactions.openGroupBookingRequestForm(this.props.profile);
	}

	render() {
		let { classes } = this.props;

		return (
			<div className={classes.companies}>
				<ColorButton
					component="label"
					variant="contained"
					startIcon={<GroupIcon />}
					onClick={this.openGroupBookingsModal}
					className={classes.button}
				>
					Group Bookings
					{/* <div className={classes.ribbon}>NEW</div> */}
				</ColorButton>
				<Dialog
					onClose={this.handleGroupBookingsModalClose}
					open={this.state.group_bookings_modal_open}
					classes={{ paper: classes.dialogPaper }}
				>
					<IconButton
						aria-label="close"
						onClick={this.handleGroupBookingsModalClose}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>
					<DialogContent className={classes.dialogContentContainer}>
						<img
							className="empty-state-image"
							src="./img/search_page/search_bar/group_bookings.svg"
							alt="no results"
						></img>
						<Typography className={classes.dialogTitle}>Group Bookings</Typography>
						<Typography className={classes.dialogContent}>{modal_content}</Typography>
					</DialogContent>
					<DialogActions className={classes.dialogActions}>
						<ColorButton
							component="label"
							variant="contained"
							onClick={this.openGroupBookingRequestUrl}
							className={classes.dialogButton}
						>
							Create a New Request
						</ColorButton>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

GroupBookings.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GroupBookings);
