import _ from 'lodash';

const DEFAULT_SUBSCRIPTION_TIMEOUT_IN_SECONDS = 60;

const default_config = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,

    subscriptionTimeoutInSeconds: process.env.REACT_APP_FIREBASE_DEFAULT_TIMEOUT_IN_SECONDS || DEFAULT_SUBSCRIPTION_TIMEOUT_IN_SECONDS,

    useEmulator: process.env.REACT_APP_FIREBASE_USE_EMULATOR === 'true',
    emulatorHost: process.env.REACT_APP_FIREBASE_EMULATOR_HOST,
    emulatorPort: Number(process.env.REACT_APP_FIREBASE_EMULATOR_PORT),
};

function isValid(config) {
    // const config = _.pick(this._config, [
    const _config = _.pick(config, [
        'apiKey',
        'authDomain',
        'databaseURL',
        'projectId',
        'storageBucket',
        'messagingSenderId',
        'appId',
        'measurementId',
    ]);
    return Object.values(_config).every((val) => _.isString(val) && (val !== ''));
}

export default {
    default_config,
    isValid
};