import PropTypes from 'prop-types';

import React from 'react';

import Config from 'arbitrip-common/client/utils/Config';

const badgeStyle = {
	fontSize: '10px',
	textAlign: 'center',
	color: 'green',
	border: '1px solid green',
	borderRadius: '4px',
	maxWidth: '120px',
	marginBottom: '4px',
	padding: '0 4px',
};

class NegotiatedRateComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { show, companyName, rawCode, code, codeDisplayName } = this.props;

		let displayName = codeDisplayName || companyName;
		let title = code;

		if (show && displayName) {
			displayName += ' Rate';
			if (rawCode !== code) {
				title += ` (${rawCode})`;
			}
			return (
				<div className="badge-position" style={badgeStyle} title={Config.dev_mode ? code : displayName}>
					{Config.localhost ? title : displayName}
				</div>
			);
		}

		return null;
	}
}

NegotiatedRateComponent.propTypes = {
	companyName: PropTypes.string,
	show: PropTypes.bool,
	rawCode: PropTypes.string,
	code: PropTypes.string,
	codeDisplayName: PropTypes.string,
};

export default NegotiatedRateComponent;
