import ManagementConstants from '../constants/ManagementConstants';
import moment from 'moment';

function TripRequestAccommodation(accommodation, display_order) {
	this.id = accommodation._id;

	this.destination = {
		address: accommodation.destination_address || '',
		place: accommodation.destination_place,
	};

	this.check_in = accommodation.check_in && moment(accommodation.check_in);
	this.check_out = accommodation.check_out && moment(accommodation.check_out);

	// this.late_check_in = accommodation.late_check_in;
	// this.early_check_in = accommodation.early_check_in;
	// this.late_check_out = accommodation.late_check_out;

	this.component_type = ManagementConstants.TRIP_REQUEST_COMPONENT_TYPE.ACCOMMODATION;
	this.display_order = display_order || accommodation.display_order;
}

export default TripRequestAccommodation;
