const { getDateInIso, getMixpanelString } = require('../../utils/Dates');

const getSearchTermsObject = (terms) => {
    if (!terms) {
        return;
    }

    return {
        ...(terms.check_in && { check_in: getMixpanelString(terms.check_in) }),
        ...(terms.check_out && { check_out: getMixpanelString(terms.check_out) }),
        ...(terms.check_in && { check_in_date: getDateInIso(terms.check_in) }),
        ...(terms.check_out && { check_out_date: getDateInIso(terms.check_out) }),
        ...(terms.children_ages && { children_ages: terms.children_ages }),
        ...(terms.guests && { guests: terms.guests }),
        ...(terms.guest_count && { guests: terms.guest_count }),
        ...(terms.rooms && { rooms: terms.rooms }),
        ...(terms.room_count && { rooms: terms.room_count }),
        ...(terms.private_travel && { private_travel: terms.private_travel }),
        ...(terms.travelers && { travelers: terms.travelers })
    };
};

module.exports = {
    getSearchTermsObject
};