const getRoomObject = (room) => {
    if (!room) {
        return;
    }

    return {
        ...(room.id && { room_id: room.id }),
        ...(room.name && { room_name: room.name }),
        ...(room.description && { room_description: room.description }),
    };
};

module.exports = {
    getRoomObject
};