import RouteBuilder from 'arbitrip-common/general/utils/RouteBuilder';
import { HOTEL_SEARCH_URL_FIELDS } from 'arbitrip-common/general/constants';

export default {
	buildSearchRoute: RouteBuilder.buildSearchRoute,
	goToSearchPage: (navigate, search_terms) => navigate(RouteBuilder.buildSearchRoute(search_terms)),

	buildResultsRoute: RouteBuilder.buildResultsRoute,
	goToResultsPage: (navigate, search_terms, search_token) =>
		navigate(RouteBuilder.buildResultsRoute(search_terms, search_token)),

	buildHotelRoute: RouteBuilder.buildHotelRoute,
	goToHotelPage: (navigate, hotel, search_token, search_terms) =>
		navigate(RouteBuilder.buildHotelRoute(hotel, search_token, search_terms)),
	buildHotelSearchURL: (hotel, search_terms) =>
		window.location.origin + RouteBuilder.buildHotelRoute(hotel, null, search_terms, HOTEL_SEARCH_URL_FIELDS),

	buildReservationRoute: RouteBuilder.buildReservationRoute,
	goToReservationPage: (navigate) => navigate(RouteBuilder.buildReservationRoute()),

	buildTripsRoute: RouteBuilder.buildTripsRoute,
	goToTripPage: (navigate, tripId) => navigate(RouteBuilder.buildTripRoute(tripId)),
	goToTripsPage: (navigate) => navigate(RouteBuilder.buildTripsRoute()),

	// // refresh: () => window.location.reload(),
	// refresh: () => history.go(0)
};
