const _ = require('lodash');
const { API_SERVICES } = require('../../general/constants');

const ROUTES = {
  LOG: { path: 'log', api_service: API_SERVICES.OPS },
  LOGIN: { path: 'login', api_service: API_SERVICES.OPS }, // only desktop
  LOGOUT: { path: 'logout', api_service: API_SERVICES.OPS },
  SEARCH: { path: 'search', api_service: API_SERVICES.HOTELS },
  HOTELS_SEARCH: { path: 'hotels', api_service: API_SERVICES.HOTELS },
  PROFILE: { path: 'profile', api_service: API_SERVICES.OPS },
  HOTEL_INFO: { path: 'hotelInfo', api_service: API_SERVICES.OPS },
  HOTEL_DEALS: { path: 'hotelDeals', api_service: API_SERVICES.HOTELS },
  HOTEL_RECHECKED_DEALS: { path: 'hotelRecheckedDeals', api_service: API_SERVICES.HOTELS },
  HOTEL_CITY_SEARCH: { path: 'hotelCitySearch', api_service: API_SERVICES.HOTELS },
  CATALOG: { path: 'catalog', api_service: API_SERVICES.OPS }, // only desktop
  INIT_RESERVATION: { path: 'initReservation', api_service: API_SERVICES.OPS },
  CHECK_DOUBLE_BOOKING: { path: 'checkDoubleBooking', api_service: API_SERVICES.OPS },
  CREATE_EXPEDIA_BOOKING: { path: 'expedia-create-payment-booking', api_service: API_SERVICES.OPS },
  COMPLETE_EXPEDIA_SESSION: { path: 'expedia-payment-session-complete', api_service: API_SERVICES.OPS },
  // ADVANCE_RESERVATION: { path: 'advanceReservation', api_service: API_SERVICES.OPS }, // obsolete
  QUOTE_RESERVATION: { path: 'quoteReservation', api_service: API_SERVICES.OPS },
  PAY_LATER: { path: 'pay/payLater', api_service: API_SERVICES.OPS }, // only common, not desktop
  PAY_COMPANY_POLICY: { path: 'pay/companyPolicy', api_service: API_SERVICES.OPS }, // only common, not desktop
  PAY_AT_HOTEL: { path: 'pay/postPay', api_service: API_SERVICES.OPS }, // only common, not desktop
  PAY_POINTS_ONLY: { path: 'pay/pointsOnly', api_service: API_SERVICES.OPS }, // only common, not desktop
  GENERATE_CREDIT_URL: { path: 'pay/generateCreditUrl', api_service: API_SERVICES.OPS }, // only common, not desktop
  REGENERATE_CREDIT_URL: { path: 'pay/regenerateCreditUrl', api_service: API_SERVICES.OPS }, // only common, not desktop
  GET_CREDIT_URL_FROM_ID: { path: 'pay/generateCreditUrl/:id', api_service: API_SERVICES.OPS }, // only common, not desktop
  PAYMENT_STATUS: { path: 'paymentStatus', api_service: API_SERVICES.OPS },
  BOOK_RESERVATION: { path: 'bookReservation', api_service: API_SERVICES.OPS },// only common, not desktop
  REQUEST_OUT_OF_POLICY_ROOM: { path: 'requestOutOfPolicyRoom', api_service: API_SERVICES.OPS },
  SEARCH_AUTOCOMPLETE_PLACE: { path: 'autocomplete/place', api_service: API_SERVICES.HOTELS },
  SEARCH_AUTOCOMPLETE: { path: 'autocomplete/search', api_service: API_SERVICES.HOTELS },
  RECENT_SEARCHES: { path: 'my/searches/recent', api_service: API_SERVICES.OPS },
  SEARCHES: { path: 'my/searches', api_service: API_SERVICES.OPS },
  DESTINATIONS: { path: 'company/destinations', api_service: API_SERVICES.OPS },
  COMPANY_BOOKINGS: { path: 'company/bookings', api_service: API_SERVICES.OPS },
  COMPANY_BOOKINGS_AND_PRIVATE_TRAVEL: { path: 'company/bookings_and_private_travel', api_service: API_SERVICES.OPS },
  COMPANY_BOOKINGS_DOWNLOAD_CSV: { path: 'company/bookings_csv', api_service: API_SERVICES.OPS },
  COMPANY_BOOKING_REQUESTS_DOWNLOAD_CSV: { path: 'company/booking_requests_csv', api_service: API_SERVICES.OPS },
  COMPANY_USERS: { path: 'company/users', api_service: API_SERVICES.OPS },
  COMPANY_ADD_USER: { path: 'company/add/user', api_service: API_SERVICES.OPS },
  COMPANY_ACTIVATE_USER: { path: 'company/activate/user', api_service: API_SERVICES.OPS },
  COMPANY_UPDATE_USER: { path: 'company/update/user', api_service: API_SERVICES.OPS },
  COMPANY_INVITE_USER: { path: 'company/invite/user', api_service: API_SERVICES.OPS },
  COMPANY_RESET_PASSWORD_USER: { path: 'company/reset/password/user', api_service: API_SERVICES.OPS },
  BOOKINGS: { path: 'my/bookings', api_service: API_SERVICES.OPS },
  VOUCHER_PRINT: { path: 'vouchers/print/:reservation_id', api_service: API_SERVICES.OPS },
  VOUCHER_PRINT_TRIP: { path: 'vouchers/print/:reservation_id/:group_id/:trip_id', api_service: API_SERVICES.OPS },
  REPORTS_SUMMARY: { path: 'reports/summary', api_service: API_SERVICES.OPS },
  REPORTS_GRAPHS_DATA: { path: 'reports/monthly_graphs', api_service: API_SERVICES.OPS },
  CANCEL_RESERVATION: { path: 'cancelReservation', api_service: API_SERVICES.OPS },
  COMPANY_REQUESTS: { path: 'company/requests', api_service: API_SERVICES.OPS },
  PERSONAL_REQUESTS: { path: 'my/requests', api_service: API_SERVICES.OPS },
  APPROVE_REQUEST: { path: 'company/requests/approve', api_service: API_SERVICES.OPS },
  REJECT_REQUEST: { path: 'company/requests/reject', api_service: API_SERVICES.OPS },
  SEND_INVITATION: { path: 'invite', api_service: API_SERVICES.OPS },
  UPGRADE_PLAN: { path: 'upgrade', api_service: API_SERVICES.OPS },
  TRAVEL_POLICY: { path: 'company/travel_policy', api_service: API_SERVICES.OPS },
  CONTACTS: { path: 'company/contacts', api_service: API_SERVICES.OPS },
  CONTACTS_AUTOCOMPLETE: { path: 'company/contacts/autocomplete', api_service: API_SERVICES.OPS },
  SYNC_TRAVEL_BOOSTER: { path: 'travel_booster/sync', api_service: API_SERVICES.OPS },

  AIRPORT_CITY_COUNTRY_AUTOCOMPLETE: { path: 'static/airport_complete', api_service: API_SERVICES.OPS },

  AGENCY_GET_BOOKINGS: { path: 'agency/bookings', api_service: API_SERVICES.OPS },
  AGENCY_BOOKINGS_DOWNLOAD_CSV: { path: 'agency/bookings_csv', api_service: API_SERVICES.OPS },
  AGENCY_BOOKING_REQUESTS_DOWNLOAD_CSV: { path: 'agency/booking_requests_csv', api_service: API_SERVICES.OPS },
  SEND_BOOKING_CONFIRMATION: { path: 'agency/send_booking_confirmation', api_service: API_SERVICES.OPS },
  SEND_BOOKING_CONFIRMATION_BY_TRAVEL_MANAGER: { path: 'company/send_booking_confirmation', api_service: API_SERVICES.OPS },
  AGENCY_GET_COMPANIES: { path: 'agency/companies', api_service: API_SERVICES.OPS },
  AGENCY_GET_CONTRACT: { path: 'agency/contract', api_service: API_SERVICES.OPS },
  AGENCY_UPDATE_CONTRACT: { path: 'agency/update_contract', api_service: API_SERVICES.OPS },
  AGENCY_NOTES: { path: 'agency/notes', api_service: API_SERVICES.OPS },

  INIT_TRIP_REQUEST: { path: 'management/actions/init', api_service: API_SERVICES.OPS }, // () => trip_request_id/Err
  EDIT_TRIP_REQUEST_META: { path: 'management/actions/edit_meta', api_service: API_SERVICES.OPS }, // (trip_request_id, payload) => (field, data) | payload form = (field, data) | payload fields = name/purpose/travelers/special_requests/self_travel
  CREATE_TRIP_REQUEST_COMPONENT: { path: 'management/actions/create_component', api_service: API_SERVICES.OPS }, // (trip_request_id, component_type, payload) => id/Err
  UPDATE_TRIP_REQUEST_COMPONENT: { path: 'management/actions/update_component', api_service: API_SERVICES.OPS }, // (trip_request_id, component_id, payload) => id/Err
  REMOVE_TRIP_REQUEST_COMPONENT: { path: 'management/actions/remove_component', api_service: API_SERVICES.OPS }, // (trip_request_id, component_id) => bool/Err
  SUBMIT_TRIP_REQUEST: { path: 'management/actions/submit', api_service: API_SERVICES.OPS }, // (trip_request_id) => bool/Err
  CANCEL_TRIP_REQUEST: { path: 'management/actions/cancel', api_service: API_SERVICES.OPS }, // (trip_request_id) => bool/Err
  TRIP_REQUESTS: { path: 'management/trips', api_service: API_SERVICES.OPS }, // () => [trip_request]/Err
  ONGOING_TRIPS: { path: 'management/trips/ongoing', api_service: API_SERVICES.OPS }, // () => [trip]/Err
  SINGLE_TRIP_REQUEST: { path: 'management/trip', api_service: API_SERVICES.OPS }, // () => trip_request/Err
  DISCARD_TRIP_REQUEST: { path: 'management/actions/discard', api_service: API_SERVICES.OPS }, // (trip_request_id) => bool/Err
  SEND_TRIP_FOR_APPROVAL: { path: 'management/actions/approve', api_service: API_SERVICES.OPS }, // (trip_id) => trip_id/Err
  SEND_TRIP_FOR_PRE_APPROVAL: { path: 'management/actions/pre_approve', api_service: API_SERVICES.OPS }, // (trip_id) => trip_id/Err
  CLONE_TRIP: { path: 'management/actions/clone', api_service: API_SERVICES.OPS }, // (trip_id) => trip_id/Err
  FORWARD_TRIP_REQUEST: { path: 'management/actions/forward', api_service: API_SERVICES.OPS }, // (component_ids, to_emails, message, trip_request_id) => JSON/Err

  CREATE_TRIP_AIRFARE: { path: 'management/actions/create_airfare', api_service: API_SERVICES.OPS }, // (trip_id) / (trip_id, cost, currency, flights: [flight_number, departure_date]) => (trip_id, airfare)/Err
  UPDATE_TRIP_AIRFARE: { path: 'management/actions/edit_airfare_meta', api_service: API_SERVICES.OPS }, // (trip_id, airfare_id, pnr, cost, currency) => trip/Err
  REMOVE_TRIP_AIRFARE: { path: 'management/actions/remove_airfare', api_service: API_SERVICES.OPS }, // (trip_id, airfare_id) => trip/Err
  REMOVE_TRIP_ACCOMMODATION: { path: 'management/actions/remove_accommodation', api_service: API_SERVICES.OPS }, // (trip_id, accommodation_id) => trip/Err

  CREATE_TRIP_AIRFARE_FLIGHT: { path: 'management/actions/create_flight', api_service: API_SERVICES.OPS }, // (trip_id, airfare_id, flight: (display_order, airline_code, flight_number, _flight_class, duration_hours, depart_airport, depart_date, depart_time, depart_terminal, arrival_airport, arrival_date, arrival_time, arrival_terminal)) => trip/Err
  UPDATE_TRIP_AIRFARE_FLIGHT: { path: 'management/actions/update_flight', api_service: API_SERVICES.OPS }, // (trip_id, airfare_id, flight: (display_order, airline_code, flight_number, _flight_class, duration_hours, depart_airport, depart_date, depart_time, depart_terminal, arrival_airport, arrival_date, arrival_time, arrival_terminal)) => trip/Err
  REMOVE_TRIP_AIRFARE_FLIGHT: { path: 'management/actions/remove_flight', api_service: API_SERVICES.OPS }, // (trip_id, airfare_id) => trip/Err

  MANAGEMENT_REPORTS_SUMMARY: { path: 'management/reports/summary', api_service: API_SERVICES.OPS },
  MANAGEMENT_REPORTS_GRAPHS_DATA: { path: 'management/reports/monthly_graphs', api_service: API_SERVICES.OPS },
  MANAGEMENT_REPORTS_COST_CENTERS_ANNUAL_BUDGET: { path: 'management/reports/cost_centers_annual_budget', api_service: API_SERVICES.OPS },

  SAVE_PROFILE: { path: 'my/profile', api_service: API_SERVICES.OPS },
  APPROVE_CURRENCY_TERMS: { path: 'my/approve_currency_terms', api_service: API_SERVICES.OPS },
  CHANGE_DISPLAY_CURRENCY: { path: 'my/display_currency', api_service: API_SERVICES.OPS },
  CHANGE_LANGUAGE: { path: 'my/language', api_service: API_SERVICES.OPS },
  GET_USER_POINTS: { path: 'my/points', api_service: API_SERVICES.OPS },
  SIGN_TERMS_OF_USE: { path: 'my/sign_term_of_use', api_service: API_SERVICES.OPS },

  SEND_BASKET: { path: 'basket/share', api_service: API_SERVICES.OPS },

  // loyalty
  POINTS: { path: 'my/points', api_service: API_SERVICES.OPS },
  LOYALTY_EXTERNAL_PURCHASE: { path: 'loyalty/external_purchase', api_service: API_SERVICES.OPS },

  MAPPING_EXPLORATION: { path: 'my/mapping_exploration_csv', api_service: API_SERVICES.OPS },

    GENERATE_PAYMENT_LINK: { path: 'payment_link/generate', api_service: API_SERVICES.OPS },
    GET_PAYMENT_LINK: { path: 'payment/:token', api_service: API_SERVICES.OPS }
};

function buildFullURL(url, base) {
  return new URL(url, base).href;
}

/**
 * 
 * @param {Object} apiUrls dictionary of api urls based on api service
 * e.g. {
 *        [API_SERVICE.OPS]: 'http://localhost:5000/',
 *        [API_SERVICE.HOTELS]: 'http://localhost:5001/'
 *      }
 */
module.exports = (apiUrls) => {
  if (_.isEmpty(apiUrls)) {
    throw new Error('api_url not provided');
  }

  return _.mapValues(ROUTES, (route) => buildFullURL(route.path, apiUrls[route.api_service]));
};
