const CurrentLocation = ({ trip }) => {
	let current_location = trip.live_meta?.current_location;

	if (current_location) {
		if (current_location.type === 'flight') {
			return (
				<span className="current-location flight" title={current_location.details.coordinates}>
					{current_location.details.flight_number}
				</span>
			);
		} else if (current_location.type === 'accommodation') {
			return (
				<span className="current-location accommodation" title={current_location.details.coordinates}>
					{current_location.details.hotel_name.toLowerCase()}
				</span>
			);
		}
	}

	return null;
};

export default CurrentLocation;
