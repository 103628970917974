import HotelService from '../../../services/hotel/HotelService';

const ReasonsBoxComponent = ({ hotel }) => {
	const reasons = HotelService.getReasonsRender(hotel);

	return (
		reasons.length > 0 && (
			<div className="hotel-reasons">
				<ul>{reasons}</ul>
			</div>
		)
	);
};

export default ReasonsBoxComponent;
