import React from 'react';
import _ from 'lodash';
import NewRedeemPoints from '../reservation-payment/NewRedeemPoints.react';
import PointsActions from '../../../actions/PointsActions';
import Points from 'arbitrip-common/general/utils/Points';
import { Button, CircularProgress } from '@mui/material';
import PointsConstants from '../../../constants/PointsConstants';
import classNames from 'classnames';
import Analytics from 'arbitrip-common/client/analytics';

function getMessage(maxValue, reason) {
	return `Please provide a value up to ${maxValue.toLocaleString()} (${reason})`;
}

function getState() {
	return {
		redeem_points_usage: '',
	};
}

function validatePoints(points) {
	return points > 0;
}

class RetroactivePointsUsage extends React.Component {
	constructor(props) {
		super(props);

		const paid_points = props.booking?.payment?.loyalty?.points;

		this.state = {
			redeem_locked: validatePoints(paid_points),
			status_text: '',
		};

		this._onChange = this._onChange.bind(this);
		this.handleRedeemPointsUsageChange = this.handleRedeemPointsUsageChange.bind(this);
		this.handleAddClick = this.handleAddClick.bind(this);
		this.handleApproveClick = this.handleApproveClick.bind(this);

		this.displayName = 'RetroactivePointsUsage';
	}

	componentDidMount() {
		const paid_points = this.props.booking?.payment?.loyalty?.points || 0;
		PointsActions.saveChosenUserPoints(paid_points);
		this.setState({
			redeem_points_usage: paid_points,
			redeem_locked: validatePoints(paid_points),
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.updatePointsUsageStatus !== this.props.updatePointsUsageStatus) {
			switch (this.props.updatePointsUsageStatus) {
				case PointsConstants.STATUS.BUSY:
					this.setState({ status_text: '' });
					break;
				case PointsConstants.STATUS.SUCCESS:
					this.setState({ status_text: 'Successfully Updated.' });
					this.props.onClose();
					break;
				case PointsConstants.STATUS.FAILED:
					this.setState({ status_text: 'Update Failed.' });
					break;
				default:
					break;
			}
		}

		const prev_user_chosen_points = _.get(prevProps, 'points.user_chosen_points');
		const user_chosen_points = _.get(this.props, 'points.user_chosen_points');
		if (prev_user_chosen_points !== user_chosen_points) {
			const arbitrip_points_to_currency_exchange_rate = _.get(
				this.props.profile,
				'loyalty.arbitrip_points_to_currency_exchange_rate',
				0,
			);
			const total_price = Points.extractTotalPrice({
				profile: this.props.profile,
				reservation: this.props.booking,
			});
			const price_value_in_points =
				arbitrip_points_to_currency_exchange_rate > 0
					? total_price // TODO: round to match price in currency
					: 0;
			const should_clear =
				(prev_user_chosen_points === null && !user_chosen_points) ||
				(prevState.redeem_locked && !this.state.redeem_locked && !this.state.redeem_points_usage);
			this.setState({
				redeem_points_usage: should_clear ? '' : user_chosen_points,
				points_only: price_value_in_points > 0 && user_chosen_points >= price_value_in_points,
			});
		}
	}

	handleRedeemPointsUsageChange(event) {
		const points_input = event.target.value.replaceAll(/\D/g, '');
		this.setState({
			redeem_points_usage: points_input === '' ? '' : parseInt(points_input, 10),
			invalid_points_input_error_message:
				this.state.redeem_points_usage === points_input ? this.state.invalid_points_input_error_message : '',
		});
	}

	handleAddClick(event) {
		const points_input = this.state.redeem_points_usage;
		const arbitrip_points_to_currency_exchange_rate = _.get(
			this.props.profile,
			'loyalty.arbitrip_points_to_currency_exchange_rate',
			0,
		);
		const total_price = Points.extractTotalPrice({ profile: this.props.profile, reservation: this.props.booking });
		const price_value_in_points =
			arbitrip_points_to_currency_exchange_rate > 0
				? total_price / arbitrip_points_to_currency_exchange_rate // TODO: round to match price in currency
				: 0;
		const balance = _.get(this.props.points, 'balance', 0);

		const paid_points = this.props.booking?.payment?.loyalty?.points || 0;
		const adjusted_balance = balance + paid_points;
		const points_input_diff = points_input - paid_points;

		// TODO: move to initial state
		const limits = [
			{
				value: price_value_in_points,
				message: getMessage(price_value_in_points, 'price value'),
			},
			{
				value: adjusted_balance,
				message:
					paid_points > 0
						? `Cannot add ${points_input_diff.toLocaleString()} points (exceeds balance)`
						: getMessage(balance, 'your balance'),
			},
		].sort((a, b) => a.value - b.value);

		if (points_input > limits[0].value) {
			this.setState({ invalid_points_input_error_message: limits[0].message });
		} else if (points_input > limits[1].value) {
			this.setState({ invalid_points_input_error_message: limits[1].message });
		} else {
			this.setState({ redeem_busy: true });

			const booking_id = _.get(this.props.booking, 'id');
			if (this.state.redeem_locked) {
				const user_chosen_points = _.get(this.props.booking, 'payment.loyalty.points') || 0;
				PointsActions.saveChosenUserPoints(user_chosen_points);
				this.setState({
					redeem_locked: false,
					redeem_points_usage: user_chosen_points,
				});
				Analytics.actions.interactions.editedPoints(booking_id, 'Undo', user_chosen_points);
			} else {
				PointsActions.saveChosenUserPoints(this.state.redeem_points_usage);
				this.setState({ redeem_locked: true });
				Analytics.actions.interactions.editedPoints(booking_id, 'Use Points', this.state.redeem_points_usage);
			}

			const _self = this;
			setTimeout(() => {
				_self.setState({ redeem_busy: false });
			}, 1000);
		}
	}

	handleApproveClick(event) {
		const reservation_id = _.get(this.props.booking, 'id');
		const points = _.get(this.props.points, 'user_chosen_points') || 0;
		PointsActions.updatePointsUsage({ reservation_id, points });
		Analytics.actions.interactions.redeemedPoints(reservation_id, points);
	}

	render() {
		const update_points_usage_busy = this.props.updatePointsUsageStatus === PointsConstants.STATUS.BUSY;
		const update_points_usage_success = this.props.updatePointsUsageStatus === PointsConstants.STATUS.SUCCESS;
		const update_points_usage_failed = this.props.updatePointsUsageStatus === PointsConstants.STATUS.FAILED;
		const disabled = this.state.redeem_busy || !this.state.redeem_locked || update_points_usage_busy;
		const status_class = classNames(
			update_points_usage_success && 'success',
			update_points_usage_failed && 'failed',
		);

		return (
			<div className="retroactive-points-usage">
				<NewRedeemPoints
					profile={this.props.profile}
					points={this.props.points}
					redeemPoints={this.state.redeem_points}
					shouldLockForm={false}
					redeemBusy={this.state.redeem_busy || update_points_usage_busy}
					redeemLocked={this.state.redeem_locked}
					reservation={this.props.booking}
					privateTravel={this.props.privateTravel}
					redeemPointsUsage={this.state.redeem_points_usage}
					handleRedeemPointsUsageChange={this.handleRedeemPointsUsageChange}
					invalidPointsInputErrorMessage={this.state.invalid_points_input_error_message}
					handleAddClick={this.handleAddClick}
					handleApproveClick={this.handleApproveClick}
					retroactiveMode={true}
				/>
				<hr />
				<div className="retroactive-actions">
					{(update_points_usage_success || update_points_usage_failed) && (
						<div className="status-container">
							<span className={status_class}>{this.state.status_text}</span>
						</div>
					)}
					{update_points_usage_busy && (
						<div className="busy-container">
							<CircularProgress size={12} />
						</div>
					)}
					{/* <div className='cancel-container'>
                        <Button color='primary' onClick={this.props.onClose} disabled={update_points_usage_busy}>
                            Cancel
                        </Button>
                    </div> */}
					<div className="update-container">
						<Button
							variant="contained"
							color="primary"
							onClick={this.handleApproveClick}
							disabled={disabled}
						>
							Redeem and Save
						</Button>
					</div>
				</div>
			</div>
		);
	}

	_onChange() {
		const state = getState();
		this.setState(state);
	}
}

export default RetroactivePointsUsage;
