import React from 'react';
import PropTypes from 'prop-types';

const categories_keywords_regex = {
	Apartment: [/\bapt([.]|\b)/gi, /apartment/gi],
	Hostel: [/hostel/gi, /hostal/gi],
};

function hasDifferentCategoryKeywordInName(name, category) {
	for (let cat in categories_keywords_regex) {
		if (!categories_keywords_regex[category]) {
			if (categories_keywords_regex[cat].some((regex) => regex.test(name))) {
				return true;
			}
		}
	}

	return false;
}

class HotelCategoryBookmark extends React.Component {
	constructor(props) {
		super(props);

		const different_category_keywork_in_name = hasDifferentCategoryKeywordInName(
			props.hotel.name,
			props.hotel.category,
		);

		this.state = {
			category_container_style: {
				position: 'absolute',
				bottom: 4,
			},
			category_style: {
				background: different_category_keywork_in_name ? '#eee' : '#ccc',
				padding: '1px 6px 2px 4px',
				borderRadius: '0 4px 4px 0',
				...(different_category_keywork_in_name ? { color: 'red' } : {}),
				fontSize: 12.5,
			},
		};
	}

	render() {
		const { hotel } = this.props;
		const { category_container_style, category_style } = this.state;

		return [
			<div style={Object.assign({}, category_container_style, { bottom: 52 })} key="B">
				<span style={category_style}>
					B :{' '}
					{hotel.b_converted_category
						? `${hotel.b_converted_category} (${hotel.b_original_category})`
						: 'N/A'}
				</span>
			</div>,
			<div style={Object.assign({}, category_container_style, { bottom: 28 })} key="E">
				<span style={category_style}>
					E :{' '}
					{hotel.e_converted_category
						? `${hotel.e_converted_category} (${hotel.e_original_category})`
						: 'N/A'}
				</span>
			</div>,
			<div style={category_container_style} key="T">
				<span style={category_style}>T : {hotel.category || 'N/A'}</span>
			</div>,
		];
	}
}

HotelCategoryBookmark.propTypes = {
	hotel: PropTypes.object.isRequired,
};

export default HotelCategoryBookmark;
