import AppDispatcher from '../dispatcher/AppDispatcher';
import ReportsConstants from '../constants/ReportsConstants';
import ReportsStore from '../stores/ReportsStore';
import Ajax from '../utils/Ajax';
import moment from 'moment';

var ReportsActions = {
	updateReportsStartDate: function (date) {
		AppDispatcher.handleAction({
			actionType: ReportsConstants.UPDATE_REPORTS_START_DATE,
			data: date,
		});

		this.getReports(date, ReportsStore.getEndDate(), ReportsStore.getClientContractId());
	},

	updateReportsEndDate: function (date) {
		AppDispatcher.handleAction({
			actionType: ReportsConstants.UPDATE_REPORTS_END_DATE,
			data: date,
		});

		this.getReports(ReportsStore.getStartDate(), date, ReportsStore.getClientContractId());
	},

	updateReportsClientContractId: function (client_contract_id) {
		AppDispatcher.handleAction({
			actionType: ReportsConstants.UPDATE_REPORTS_CLIENT_CONTRACT_ID,
			data: client_contract_id,
		});

		this.getReports(ReportsStore.getStartDate(), ReportsStore.getEndDate(), client_contract_id);
	},

	updateReportsDates: function (newStartDate, newEndDate, new_client_contract_id) {
		AppDispatcher.handleAction({
			actionType: ReportsConstants.UPDATE_REPORTS_DATES,
			data: {
				startDate: newStartDate,
				endDate: newEndDate,
				client_contract_id: new_client_contract_id,
			},
		});

		this.getReports(newStartDate, newEndDate, new_client_contract_id);
	},

	getReports: function (startDate, endDate, client_contract_id) {
		console.log('getting reports...');

		AppDispatcher.handleAction({
			actionType: ReportsConstants.RETRIEVE_REPORTS,
			data: {
				startDate: startDate,
				endDate: endDate,
				client_contract_id: client_contract_id,
			},
		});

		var _self = this;

		Ajax.getReportsSummary(startDate, endDate, client_contract_id)
			.done(function (res) {
				// console.log("Got Reports: ", res);
				AppDispatcher.handleAction({
					actionType: ReportsConstants.REPORTS_RETRIEVAL_SUCCEEDED,
					data: res, // ???
				});

				_self.getGraphsData(startDate, endDate, client_contract_id);
			})
			.fail(function (err) {
				console.error('Error while getting reports: ', err);
				AppDispatcher.handleAction({
					actionType: ReportsConstants.REPORTS_RETRIEVAL_FAILED,
					data: err, // ???
				});
			});
	},

	getGraphsData: function (startDate, endDate, client_contract_id) {
		var sentStartDate = startDate;
		var sentEndDate = endDate;

		var monthDiff = moment(endDate).diff(moment(startDate), 'months');
		if (monthDiff < 12) {
			var missingDiff = 11 - monthDiff;
			var halfDiff = Math.floor(missingDiff / 2);

			if (missingDiff % 2 === 0) {
				sentStartDate = moment(startDate).subtract(halfDiff, 'months').startOf('month').toDate();
			} else {
				sentStartDate = moment(startDate)
					.subtract(halfDiff + 1, 'months')
					.startOf('month')
					.toDate();
			}

			if (missingDiff > 1) {
				sentEndDate = moment(endDate).add(halfDiff, 'months').endOf('month').toDate();
			}
		}

		AppDispatcher.handleAction({
			actionType: ReportsConstants.RETRIEVE_GRAPHS_DATA,
			data: {
				startDate: sentStartDate,
				endDate: sentEndDate,
			},
		});

		Ajax.getReportsGraphsData(sentStartDate, sentEndDate, client_contract_id)
			.done(function (res) {
				AppDispatcher.handleAction({
					actionType: ReportsConstants.GRAPHS_DATA_RETRIEVAL_SUCCEEDED,
					data: res, // ???
				});
			})
			.fail(function (err) {
				console.error('Error while getting reports: ', err);
				AppDispatcher.handleAction({
					actionType: ReportsConstants.GRAPHS_DATA_RETRIEVAL_FAILED,
					data: err, // ???
				});
			});
	},

	// TODO: separate reports, parametrized requests...
};

export default ReportsActions;
