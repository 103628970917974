import keyMirror from 'keymirror';

// Define action constants
export default keyMirror({
	RECEIVE_MAP_DATA: null, // After postback
	TOGGLE_FULL_MAP_MODE: null,
	UPDATE_INFO_BUBBLE: null,
	UPDATE_CENTER: null,
	UPDATE_ZOOM: null,
	UPDATE_SELECTED_HOTEL_ID: null,
	CLEAR_MAP_DATA: null,
	CLEAR_MAP_FILTERS: null,
});
