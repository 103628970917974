const FAQ_EN = require('./Faq_En').FAQ;
const FAQ_HE = require('./Faq_He').FAQ;

const FAQ_WONDER_EN = require('./Faq_Wonder_En').FAQ;
const FAQ_WONDER_HE = require('./Faq_Wonder_He').FAQ;

const FAQ_HTZ_EN = require('./Faq_HTZ_En').FAQ;
const FAQ_HTZ_HE = require('./Faq_HTZ_He').FAQ;

const FAQ_GCASH_EN = require('./Faq_GCash_En').FAQ;

const FAQ_LETSTAY_EN = require('./Faq_LetStay_En').FAQ;
const FAQ_LETSTAY_HE = require('./Faq_LetStay_He').FAQ;

const LTR = 'ltr';
const RTL = 'rtl'

const SUPPORTED_LANGUAGES = {
    EN: 'en',
    HE: 'he'
};

const LANGUAGES = [
    { language: SUPPORTED_LANGUAGES.EN, display: 'English' },
    { language: SUPPORTED_LANGUAGES.HE, display: 'עברית' }
];

const TRANSLATION_ENV = {
    MOBILE: 'mobile',
    DESKTOP: 'desktop',
    SERVER: 'server'
}

module.exports = {
    LTR,
    RTL,
    SUPPORTED_LANGUAGES,
    LANGUAGES,
    FAQ_EN,
    FAQ_HE,
    FAQ_WONDER_EN,
    FAQ_WONDER_HE,
    FAQ_HTZ_EN,
    FAQ_HTZ_HE,
    FAQ_GCASH_EN,
    TRANSLATION_ENV,
    FAQ_LETSTAY_EN,
    FAQ_LETSTAY_HE,
}