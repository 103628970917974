const FAQ = [
    {
        title: 'מי אנחנו?',
        secondary: [
            {
                description: 'ארביטריפ היא חברת טכנולוגיה ישראלית הפועלת מאז 2015 בתחום התיירות. \n\n' +
                    'החברה פיתחה טכנולוגיה להזמנת חדרים המאפשרת לאתר את המלונות המתאימים ביותר לנסיעה על פי פרמטרים רבים, תוך הבטחה למציאת מחיר משתלם באמצעות נגישות למחירים סיטונאיים וסריקת היצע החדרים אצל ספקים שונים.\n\n' +
                    'החברה צמחה בעולם נסיעות העסקים ועובדת היום בהצלחה עם אלפי חברות בארץ ובעולם.\n\n' +
                    'לאחרונה החלה לפעול בתחום הנסיעות הפרטיות באמצעות הטכנולוגיה המתקדמת שפיתחה והכוח המסחרי הגדול שצברה בעולם העסקי.\n\n' +
                    'שיתוף הפעולה הייחודי עם בנק הפועלים מאפשר ללקוחות פועלים וונדר להנות ממחירים מצויינים ושירות מעולה כחול-לבן.\n\n' +
                    'נשמח לעמוד לשירותכם!'
            }
        ]
    },
    {
        title: 'איך המחירים נמוכים כל כך, והאם הם כוללים מיסים?',
        secondary: [
            {
                title: 'כן, זו לא טעות - המחירים באמת נמוכים!',
                description: 'ארביטריפ צמחה בעולם נסיעות העסקים ועובדת בהצלחה עם אלפי חברות בארץ ובעולם.\n' +
                    'לארביטריפ יש טכנולוגיה ייחודית וכוח מסחרי משמעותי, מה שמבטיח שתקבלו מחירים מצוינים שניתן למצוא בד"כ רק בארגונים גדולים.\n' +
                    '\n' +
                    'המחירים המוצגים בארביטריפ כוללים את כל המיסים והחיובים, מלבד מיסים מקומיים ועמלות לתשלום ישירות במלון, כמפורט בעמוד ההצעה טרם ביצוע ההזמנה.\n' +
                    'כשמשווים את המחירים בארביטריפ לאתרי תיירות אחרים, חשוב להשוות את המחיר הסופי כולל כל המיסים והחיובים.\n' +
                    'בכל שאלה בנושא ניתן לפנות אלינו כמפורט בעמוד "צור קשר" ונשמח לסייע.'
            }
        ]
    },
    {
        title: 'מתי מתבצע התשלום על ההזמנה?',
        secondary: [
            {
                title: 'התשלום הוא מיידי על מלוא סכום ההזמנה.',
                description: 'כאשר המערכת מציעה זאת, ניתן לבחור לשלם עד 3 תשלומים ללא ריבית בתנאים הבאים במצטבר:\n' +
                    '1. ההזמנה ניתנת לביטול ללא עלות\n' +
                    '2. עלות ההזמנה מעל 5000 ש"ח\n' +
                    '3. מועד ההגעה למלון (צ\'ק אין)  גדול מסוף החודש העוקב.\n\n' +
                    'לדוגמא, בוצעה הזמנה בתאריך ה 5.5. ההזמנה ניתנת לביטול ללא עלות, עלות ההזמנה 7500 ש"ח ומועד ההגעה למלון הינו ה-2.7 . במקרה זה ניתן לשלם בתשלומים\n\n' +
                    'במקרה של הזמנה עם אופציה לביטול ללא עלות, אם תתקבל בקשת ביטול במערכת עד לתאריך הביטול האחרון המצוין בעת ההזמנה, ההזמנה תבוטל מיידית והזיכוי בכרטיס האשראי ישתקף תוך מספר ימי עסקים.'
            }
        ]
    },
    {
        title: 'האם וכיצד ניתן לעדכן את שם ההזמנה ו/או תאריכי ההזמנה?',
        description: 'ישנן כמה אופציות:',
        secondary: [
            {
                title: 'במידה וההזמנה עדין ניתנת לביטול ללא עלות:',
                description: 'יש לבצע הזמנה חדשה עם הפרטים המעודכנים, לאחר קבלת אישור על ההזמנה החדשה, ניתן לבטל את ההזמנה הישנה.'
            },
            {
                title: 'במידה וההזמנה הינה בסטטוס של דמי ביטול מלאים:',
                description: 'יש ליצור קשר עם צוות השירות (כמוגדר בעמוד "צור קשר") ואנו ניצור קשר עם בית המלון ונבקש לבצע את השינויים הרצויים.\n' +
                    'יש לקחת בחשבון שתהליך זה תלוי באישור בית המלון בלבד ולכן ייתכן וייקח כמה ימים לאישור אם בכלל.'
            }
        ]
    },
    {
        title: 'כיצד אוכל  להוסיף לילה/לילות נוספים להזמנה קיימת?',
        description: '',
        secondary: [
            {
                title: 'במידה וההזמנה ניתנת לביטול ללא עלות',
                description: 'ניתן לבצע הזמנה חדשה עם התאריכים המעודכנים ולאחר אישורה לבטל את ההזמנה הישנה.'
            },
            {
                title: 'במידה וההזמנה המקורית אינה ניתנת לביטול או שמכל סיבה אחרת רוצים להוסיף על הקיים' ,
                description: 'יש לבצע הזמנה נוספת על הלילות המבוקשים לאותו סוג החדר המוזמן ולציין בהערות כי מדובר בהארכת הזמנה קיימת.' +
                    'צוות השירות יפנה ישירות אל בית המלון ויעדכן אותם על איחוד הזמנה קיימת על מנת למנוע מעבר בין חדרים.\n' +
                    'במידה ויוזמן סוג חדר שונה מסוג החדר המוגדר בהזמנה המקורית, יידרש הנוסע לעבור חדר במהלך שהותו.\n'
            }
        ]
    },
    {
        title: 'הזמנתי חדר בלבד ואני מעוניין להוסיף ארוחת בוקר-כיצד ניתן לבצע?',
        description: '',
        secondary: [
            {
                title: 'במידה וההזמנה ניתנת לביטול ללא עלות',
                description: 'ניתן לבצע הזמנה חדשה הכוללת ארוחת בוקר. לאחר אישור סופי על הזמנה החדשה- ניתן לבטל את ההזמנה הישנה.'
            },
            {
                title: 'ניתן לפנות ישירות לבית המלון לטיפול עצמאי בנושא (תשלום ישירות לבית המלון)'
            }
        ]
    },
    {
        title: 'כיצד ניתן לבטל הזמנה שביצעתי?',
        description: '',
        secondary: [
            {
                title: 'במידה וההזמנה ניתנת לביטול ללא עלות',
                description: 'ניתן לבטלה באופן עצמאי דרך המערכת:\n' +
                    'דרך עמוד "ההזמנות שלי", בחירת ההזמנה הרצויה, ולחיצה על "ביטול הזמנה"'
            },
            {
                title: 'במידה וההזמנה בסטטוס של דמי ביטול מלאים',
                description: 'יש לפנות לצוות התמיכה שלנו כמוגדר בעמוד "צור קשר" על מנת שנפנה למלון וננהל מולו מו"מ על החזר חלקי/מלא.' +
                    'קחו בחשבון כי נעשה כמיטב יכולתנו, אך בסופו של דבר ההחלטה תלויה בבית המלון בלבד.\n' +
                    'רצוי לספק סיבה והוכחות לביטול על מנת שנוכל לספקם למלון עם הבקשה.\n'
            }
        ]
    },
    {
        title: 'אני לא מוצא מלון ספציפי במערכת - תוכלו לעזור לי?',
        description: '',
        secondary: [
            {
                title: 'במידה והמלון בתפוסה מלאה',
                description: 'הוא אינו יופיע בתוצאות. כמו כן, לעתים, בית המלון משנה את שמו או את את הבעלות והדבר מקשה על מציאתו אצלנו במערכת.\n' +
                    'ניתן לשלוח אלינו, כמוגדר בעמוד "צור קשר" את שם בית המלון, תאריכים רצויים ומספר חדרים אשר אתם מעוניינים להזמין.\n' +
                    'נבצע בדיקה האם נוכל לבצע את ההזמנה עבורכם ישירות מבית המלון.\n'
            }
        ]
    },
    {
        title: 'היכן ניתן לצפות בחשבוניות עבור ההזמנות שלי?',
        description: '',
        secondary: [
            {
                title: 'מסמכים חשבונאיים יישלחו אליכם ישירות למייל.'
            },
            {
                title: 'דרך עמוד "ההזמנות שלי", בחירת ההזמנה הרצויה ולחיצה על "הצג חשבונית".'
            },
            {
                title: 'במידה ואינכם מוצאים מסמך חשבונאי',
                description: 'צרו איתנו קשר כמוגדר בעמוד "צור קשר" ואנו נשלח לכם את הנדרש.'
            }
        ]
    },
    {
        title: 'הגעתי למלון ועודכנתי כי אני צריך לשלם על ההזמנה, מדוע זה קורה והאם תוכלו לעזור לי?',
        secondary: [
            {
                title: 'זהו תרחיש חריג אשר לא אמור לקרות',
                description: 'אנא צרו עימנו קשר במיידי ואנו ניצור קשר עם בית המלון ונוודא כי לא תחוייבו.\n' +
                    'מצב זה נוצר בעקבות קצר בתקשורת במערכות בית המלון. \n' +
                    'במידה ובכל זאת חוייבתם על ידי בית המלון - נבקש כי תעבירו לנו הוכחה על החיוב על מנת שנוכל להעבירה לבית המלון עם הבקשה לזיכוי. יש לקחת בחשבון כי הטיפול בנושאים אלו יכול להימשך זמן מה אצל בית המלון, ואנו נדאג לעדכנכם בסופו של הטיפול.'
            }
        ]
    },
    {
        title: 'עשיתי צ\'ק אאוט מוקדם מבית המלון - האם אוכל לקבל החזר?',
        secondary: [
            {
                title: 'במידה ואתם נאלצים לצאת מוקדם מבית המלון',
                description: 'נא עדכנו אותנו עם כל פרטי ההזמנה וגם את הסיבה לכך. אנו ניצור קשר עם בית המלון וננהל מולם מו"מ לגבי החזר אפשרי. חשוב לציין כי ההחזר אינו מובטח ותלוי בבית המלון בלבד. לקידום הנושא אנו ממליצים לפנות בנוסף לקבלה בבית המלון עם הבקשה ולהעביר אלינו את השם של הנציג אשר דיברתם והאם אישר/ סירב לבקשתכם.'
            }
        ]
    },
    {
        title: 'אני צריך לעשות צ\'ק אין מוקדם - איך ניתן להבטיח זאת?',
        secondary: [
            {
                description: 'רוב בתי המלון דורשים להזמין את הלילה הקודם על מנת לאפשר כניסה מוקדמת יותר (מהמופיע בתנאי המלון) לחדר. \n' +
                    'חשוב להזמין את אותו סוג חדר ולעדכן אותנו לגבי כך על מנת שנוכל לעדכן את בית המלון כי יש לשמור את החדר עבורכם.\n' +
                    'ללא הזמנה של הלילה הקודם- לא נוכל להבטיח את הכניסה המוקדמת שכן הדבר תלוי בזמינות החדרים בבית המלון בלבד.'
            }
        ]
    },
    {
        title: 'אני מגיע למלון מאוחר - איך ניתן להבטיח שהמלון ישמור את החדר עבורי?',
        secondary: [
            {
                title: 'מלון עלול לבטל הזמנה של אורח שמגיע מאוחר, ללא הודעה',
                description: 'במידה ואורח מגיע ביום הצ\'ק אין אחרי השעה 20:00 או אחרי שעת הצ\'ק אין המאוחרת ביותר, כפי שמופיע בתנאי המלון, המלון עלול לבטל את הזמנתו.\n' +
                    'בכל מקרה של הגעה מאוחרת כזו, עליכם להודיע לנו כמופיע בעמוד "צור קשר" כדי שנוכל ליידע את המלון בהגעה המאוחרת ולהבטיח שההזמנה לא תבוטל.'
            }
        ]
    },
    {
        title: 'איך אוכל לצרף את מספר חבר המועדון שלי עבור רשת מלונות להזמנה אשר אני מבצע דרך המערכת?',
        secondary: [
            {
                title: 'תוכלו לציין את מספר חבר המועדון בסעיף "בקשות מיוחדות" במעמד ההזמנה',
                description: 'במידה וההזמנה כבר בוצעה, תוכלו להעבירו אלינו כמוגדר בעמוד "צור קשר" ואנו נעביר את הבקשה לבית המלון.\n' +
                    'שימו לב כי מכיוון שההזמנה בוצעה דרך גורם שלישי - ברוב המקרים בית המלון לא יכיר בחברות המועדון בהקשר להזמנה.'
            }
        ]
    }
    // {
    //     title: '',
    //     description: '',
    //     secondary: [
    //         {
    //             title: '',
    //             description: ''
    //         }
    //     ]
    // },
];

module.exports = {
    FAQ
};
