const _ = require('lodash');
const i18next = require('i18next');
const mapping = require('arbitrip-common/theme/mapping');
const { TRANSLATION_ENV } = require('arbitrip-common/translation/const');
const { THEME_PREFIX } = require('./const');

function addCompanyTranslation(translation_env, company_id, addResourceBundleFunc, useNamespace = false) {
    const company_theme_name = _.get(mapping, company_id);
    
    if (company_id && company_theme_name) {
        try {
            // useNamespace - uses the company_id as the namespace
            // it is called with true from getServerThemeTranslation function
            // the getServerThemeTranslation is used from lambdas code in order to keep seperation between users
            // every lambda keeps the translation in memory and share it between different users on different companies
            // so on lambdas we can not override the translation, but keep namespaces
            // and use the getServerThemeTranslation function for translation
            // on mobile and desktop there is no need in seperation and we can override the transltation
            // so we can use the default namespace 'translation'
            const namespace = useNamespace ? `${THEME_PREFIX}${company_id}` : 'translation';

            if (!useNamespace || (useNamespace && !i18next.hasResourceBundle('en', namespace))) {
                // Update English translation
                const enThemeTranslation = require(`arbitrip-common/theme/translation/${translation_env}/en/${company_theme_name}.json`);

                if (enThemeTranslation.filesToOverride) {
                    enThemeTranslation.filesToOverride.forEach((fileMame) => {
                        const filesJson = require(`arbitrip-common/theme/translation/${translation_env}/en/${company_theme_name}Pages/${fileMame}`);
                        addResourceBundleFunc('en', namespace, filesJson);
                    });
                }
                delete enThemeTranslation.filesToOverride;

                addResourceBundleFunc('en', namespace, enThemeTranslation);
            }

            if (translation_env === TRANSLATION_ENV.MOBILE || translation_env === TRANSLATION_ENV.SERVER) {
                if (!useNamespace || (useNamespace && !i18next.hasResourceBundle('he', namespace))) {
                    // Update Hebrew translation for mobile and server
                    const heThemeTranslation = require(`arbitrip-common/theme/translation/${translation_env}/he/${company_theme_name}.json`);
                    if (heThemeTranslation.filesToOverride) {
                        heThemeTranslation.filesToOverride.forEach((fileMame) => {
                            const filesJson = require(`arbitrip-common/theme/translation/${translation_env}/he/${company_theme_name}Pages/${fileMame}`);
                            addResourceBundleFunc('he', namespace, filesJson);
                        });
                    }
                    delete heThemeTranslation.filesToOverride;
                    addResourceBundleFunc('he', namespace, heThemeTranslation);
                }
            }
        } catch (error) {
            // console.error(`Error importing theme translation for company: ${company_theme_name}, on env: ${translation_env}`, error);
        }
    }
}

function isThemeExists(company_id) {
    const company_theme_name = _.get(mapping, company_id);
    return !!company_theme_name;
}

module.exports = {
    addCompanyTranslation,
    isThemeExists
}