// import User from './User';

export default function (name, quota) {
	this.name = name;
	this.quota = quota;
	//this.users = [];
	//
	//// Added for light ver
	//for (var i = 0; i < this.quota; i++) {
	//  this.users.push(new User({
	//    name: '',
	//    email: '',
	//    phone: ''
	//  }))
	//}
}
