import React from 'react';
import createClass from 'create-react-class';

const GroupCountBookmark = createClass({
	displayName: 'GroupCountBookmark',

	styles: {
		component: {
			position: 'absolute',
			// right: '32px',
			top: '-1px',
		},
		bookmark: {
			color: '#D0D1D2',
			fontSize: '24px',
			position: 'relative',
			marginLeft: '-26px',
			marginTop: '39px',
		},
		provider: {
			color: 'black',
			fontSize: '15px',
			position: 'absolute',
			left: '0',
			top: '5px',
			fontFamily: 'lato',
			textAlign: 'center',
			width: '22px',
		},
	},

	render() {
		if (!this.props.display_debugging) {
			return null;
		}

		let { styles, props } = this;

		return (
			<div style={styles.component}>
				<i className="fa fa-square" aria-hidden="true" style={styles.bookmark}>
					<span style={styles.provider} title={props.name}>
						{props.count}
					</span>
				</i>
			</div>
		);
	},
});

export default GroupCountBookmark;
