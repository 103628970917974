const MapThumb = () => {
	return (
		<svg width="226" height="132" viewBox="0 0 226 132" fill="none" xmlns="http://www.w3.org/2000/svg">
			<mask
				id="mask0"
				style={{ maskType: 'alpha' }}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="226"
				height="132"
			>
				<rect width="226" height="132" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0)">
				<path d="M230 0H-43.8042V131.917H230V0Z" fill="#E6E6E6" />
				<path
					d="M230 114.286V131.913H43.356C43.356 131.913 42.9924 122.726 43.0011 112.975C43.0011 112.618 43.0011 112.26 43.0011 111.897C43.0391 102.798 43.4247 93.4846 44.7538 90.9192V90.9104C44.8458 90.7313 44.9597 90.5288 45.0985 90.3044C46.6029 87.7711 50.6223 82.4815 57.2065 75.6172C57.5191 75.2909 57.8404 74.9559 58.1676 74.6223C60.0532 72.6906 62.133 70.6394 64.41 68.5038C64.5751 68.3435 64.7504 68.1818 64.9198 68.026C66.987 66.0943 69.209 64.1033 71.5858 62.0532C72.1106 61.5987 72.6462 61.1412 73.1924 60.6809L73.207 60.6663C75.5293 58.7026 77.9981 56.6976 80.6135 54.6513L81.262 54.1516L81.7002 53.8078C84.7372 51.4624 87.965 49.0903 91.3837 46.6915C91.7557 46.4293 92.133 46.1675 92.5156 45.9063C94.9626 44.2135 97.5035 42.5207 100.138 40.8279C100.299 40.726 100.451 40.6211 100.607 40.5366L100.69 40.4812C102.735 39.1701 104.802 37.8663 106.971 36.5625H107.02C107.224 36.7082 110.705 39.1847 115.854 42.7669L115.895 42.7989C116.07 42.9126 116.248 43.032 116.428 43.166C121.874 46.9537 129.037 51.9067 136.127 56.6646L136.807 57.1234C149.048 65.3309 160.906 72.8741 163.153 72.8741C163.93 72.8741 165.526 72.7823 167.692 72.6177C168.13 72.5857 168.595 72.5536 169.077 72.5128C180.141 71.6388 202.351 69.4012 210.755 68.2765C210.888 68.259 211.017 68.2401 211.142 68.227C211.873 68.1265 212.48 68.0347 212.954 67.956L213.176 68.5518L229.345 112.504C229.367 112.554 229.386 112.6 229.404 112.65L230 114.286Z"
					fill="#CFDDBD"
				/>
				<path
					d="M212.863 131.917H85.2729V131.928L84.5776 131.917L85.3327 117.29L85.3693 116.589L85.8702 106.852L98.1857 100.084L98.9028 99.6907H98.9072L110.487 93.3216L111.195 92.9356H111.204L112.299 92.3252L125.105 98.0387L125.116 98.043L125.782 98.3402L139.306 104.374L140.002 104.686L155.048 111.394L155.053 111.399L155.744 111.418L168.766 111.748L170.146 111.785L183.474 112.12L184.163 112.583L212.863 131.917Z"
					fill="#E6E6E6"
				/>
				<path
					d="M51.3994 131.92C51.3994 131.92 54.9237 123.485 54.043 112.59C53.1623 101.695 60.5615 90.6247 64.6131 84.1231C68.6647 77.6215 68.6647 73.0516 78.8843 70.0652C89.1038 67.0788 98.615 63.2183 102.668 61.2794C106.721 59.3404 118.699 58.2915 127.333 64.442C135.966 70.5925 154.816 79.5546 166.622 79.7309C178.427 79.9071 189.877 77.6215 194.282 83.4209C198.687 89.2203 207.319 98.3572 215.952 103.101C224.586 107.844 229.998 131.92 229.998 131.92H219.123C219.123 131.92 219.475 121.376 210.49 113.294C201.504 105.211 195.339 94.3147 181.774 92.5578C168.208 90.8009 158.165 94.3147 146.361 89.0441C134.557 83.7735 126.101 74.6336 115.883 74.9862C105.665 75.3387 99.4986 79.3783 86.6369 81.4878C73.7752 83.5972 67.9622 103.98 68.4909 109.251C69.0196 114.522 69.7236 127.877 68.6662 131.92H51.3994Z"
					fill="#D1F0FF"
				/>
				<path
					d="M0.908859 2.54713L-37.9658 60.0156L-36.8202 60.7865L2.05444 3.31805L0.908859 2.54713Z"
					fill="#F6F6F6"
				/>
				<path
					d="M11.3398 8.08076L-29.8242 68.9336L-28.6786 69.7045L12.4854 8.8517L11.3398 8.08076Z"
					fill="#F6F6F6"
				/>
				<path
					d="M17.2994 15.1078L-21.5293 72.5029L-20.3852 73.2729L18.4435 15.8778L17.2994 15.1078Z"
					fill="#F6F6F6"
				/>
				<path
					d="M19.1437 17.3297L-21.5166 75.6719L-20.3848 76.4566L20.2755 18.1144L19.1437 17.3297Z"
					fill="#F6F6F6"
				/>
				<path
					d="M2.87391 37.7888L1.98151 38.8391L1.51266 38.44L-4.55451 33.3252L-5.61342 32.4351L-13.572 25.7223L-14.6339 24.8278L-22.5881 18.115L-23.6499 17.2191L-43.8042 0.219973V0H-41.9274L-39.6401 1.92732L-38.5915 2.81304L-31.8071 8.5338L-30.7526 9.42389L-22.8729 16.0726L-21.8096 16.9671L-13.8568 23.6799L-12.7935 24.57L-4.8364 31.2828L-3.77749 32.1773L2.28968 37.2935L2.87391 37.7888Z"
					fill="#F6F6F6"
				/>
				<path
					d="M37.8743 31.1577L-8.41797 85.085L-7.36951 85.9803L38.9227 32.0531L37.8743 31.1577Z"
					fill="#F6F6F6"
				/>
				<path
					d="M14.9274 27.1659L14.0645 28.2402L64.5264 68.5631L65.3894 67.4887L14.9274 27.1659Z"
					fill="#F6F6F6"
				/>
				<path
					d="M8.85177 35.1824L7.98877 36.2568L58.4507 76.5797L59.3137 75.5053L8.85177 35.1824Z"
					fill="#F6F6F6"
				/>
				<path
					d="M-2.8549 52.7936L-3.70654 53.877L44.3474 91.4584L45.199 90.3751L-2.8549 52.7936Z"
					fill="#F6F6F6"
				/>
				<path
					d="M13.5725 88.3533L12.7095 89.4277L42.7016 113.394L43.5646 112.319L13.5725 88.3533Z"
					fill="#F6F6F6"
				/>
				<path
					d="M28.657 101.234L7.854 124.117L8.87648 125.042L29.6795 102.159L28.657 101.234Z"
					fill="#F6F6F6"
				/>
				<path
					d="M20.3803 94.6277L2.86328 112.13L3.8401 113.102L21.3572 95.6003L20.3803 94.6277Z"
					fill="#F6F6F6"
				/>
				<path
					d="M-21.5743 112.037L-22.0869 110.76L-1.03875 102.333L53.9003 46.333L54.8862 47.2959L-0.255891 103.503L-0.390264 103.557L-21.5743 112.037Z"
					fill="#F6F6F6"
				/>
				<path
					d="M4.72755 115.778L-16.5557 123.956L-16.0595 125.241L5.22375 117.062L4.72755 115.778Z"
					fill="#F6F6F6"
				/>
				<path
					d="M230 3.36426V4.78607L213.986 8.81406L212.647 9.14912L192.637 14.1823L190.414 14.7431L190.314 14.7388L188.358 14.6514L170.762 13.8618L169.388 13.7977L154.768 13.1407L153.388 13.0824L138.778 12.4254L137.402 12.3613L124.204 11.7699L122.824 11.7058L109.498 11.1085L107.354 11.0123L105.353 11.3707L83.9992 15.202L83.1054 15.3623L82.6643 15.7017L74.1317 22.3082L73.0085 23.1808L63.5879 30.4705L63.0913 30.8566L62.5801 31.4262L53.5085 41.5245L52.1152 43.0804L51.0884 42.1626L52.4555 40.6432L61.619 30.4487L62.193 29.8106L62.6254 29.4755L71.8912 22.3038L73.0173 21.4327L82.5314 14.0687L92.3201 12.3103L93.6769 12.067L105.372 9.97074L107.262 9.63131H107.34L109.521 9.72746L122.884 10.3291L124.264 10.3932L137.44 10.9847L138.819 11.0444L153.43 11.6999L154.809 11.7655L169.425 12.421L170.805 12.4808L187.797 13.247L190.277 13.3577L192.109 12.8974L212.215 7.83802L213.554 7.50296L230 3.36426Z"
					fill="#F6F6F6"
				/>
				<path
					d="M81.7078 53.8124L81.2696 54.1518L80.6211 54.6515L79.3606 53.0272L62.5832 31.4217L62.1275 30.839C61.9624 30.7064 61.7915 30.5724 61.6221 30.4442C56.9249 26.7513 51.1601 22.2221 45.2127 17.5517C44.8534 17.2662 44.4917 16.9816 44.1275 16.698C39.6596 13.1916 35.1202 9.62685 30.8744 6.30103L29.7877 5.45173L22.8193 -0.00390625H25.0599C26.7059 1.28534 28.5462 2.7261 30.6027 4.34167C30.9571 4.61652 31.3188 4.89961 31.6879 5.19097C33.3675 6.50595 35.185 7.93116 37.1402 9.4666C39.8028 11.5542 42.4625 13.6417 45.0111 15.6463C45.3734 15.9274 45.7414 16.2115 46.0963 16.4955C54.1645 22.8282 60.905 28.1264 62.6197 29.4711L63.0389 29.8061L63.1586 29.9256L63.5822 30.4675L80.4371 52.1837L81.7078 53.8124Z"
					fill="#F6F6F6"
				/>
				<path
					d="M92.9001 46.3935L91.8105 47.237L91.3913 46.6965L90.1265 45.0678L73.3125 23.4201L73.0087 23.1812C72.645 22.8899 72.2784 22.5985 71.8914 22.3072L53.4444 7.81368C53.0808 7.53398 52.7215 7.24846 52.3593 6.96439L43.4849 0.00390625H45.721C46.5623 0.665283 47.4333 1.34899 48.3339 2.05504C52.9698 5.69698 57.6027 9.32729 61.6411 12.5031C62.015 12.7944 62.3714 13.0756 62.7365 13.3611C67.4249 17.0409 71.1874 19.9953 73.0174 21.436C73.5798 21.873 73.961 22.179 74.1319 22.3101L74.237 22.3917L74.3524 22.5111L91.2584 44.2724L92.5233 45.9055L92.9001 46.3935Z"
					fill="#F6F6F6"
				/>
				<path
					d="M52.8218 6.41737L34.3945 28.2129L35.4497 29.1004L53.877 7.30484L52.8218 6.41737Z"
					fill="#F6F6F6"
				/>
				<path
					d="M35.5262 0L31.6893 5.19341L30.8714 6.30493L23.4502 16.3567L22.2453 17.9912L21.1367 17.1739L22.3928 15.471L29.7862 5.45563L30.6041 4.34557L33.81 0H35.5262Z"
					fill="#F6F6F6"
				/>
				<path
					d="M13.6208 0L9.83935 4.9647L8.60664 6.5788L7.50684 5.7499L8.78045 4.07898L11.8856 0H13.6208Z"
					fill="#F6F6F6"
				/>
				<path
					d="M76.9232 0L62.7367 13.3572L62.1569 13.902L61.209 12.9027L61.6413 12.4991L74.9134 0H76.9232Z"
					fill="#F6F6F6"
				/>
				<path
					d="M93.7791 12.7745L92.412 12.9668L92.32 12.3098L90.5586 0H91.952L93.6769 12.0665L93.7791 12.7745Z"
					fill="#F6F6F6"
				/>
				<path
					d="M83.3963 14.3227L82.2622 15.1074L100.168 40.8516L101.302 40.0669L83.3963 14.3227Z"
					fill="#F6F6F6"
				/>
				<path
					d="M124.696 0L124.264 10.3926L124.204 11.7693L123.78 22.0104L123.726 23.3856L123.256 34.6465L117.718 41.5604L116.429 43.1658L116.402 43.2036L115.896 42.8001L115.855 42.7666L115.325 42.3456L116.586 40.7708L121.895 34.141L122.824 11.7052H122.828L122.884 10.3285L123.312 0H124.696Z"
					fill="#F6F6F6"
				/>
				<path
					d="M139.137 0L138.819 11.0438L138.778 12.4248L138.465 23.3041L138.429 24.6807L137.549 55.1507L137.494 57.1421L136.809 57.1246L136.114 57.1057L136.128 56.6657L136.197 54.2373L137.049 24.5613L137.089 23.1802L137.402 12.3608L137.44 10.9841L137.757 0H139.137Z"
					fill="#F6F6F6"
				/>
				<path
					d="M155.145 0L154.809 11.7649L154.768 13.1401L154.436 24.7084L154.395 26.0894L153.852 44.9474L153.816 46.3284L153.268 65.3831L153.255 65.8012L151.875 65.7633L151.911 64.5338L153.02 25.97L153.056 24.5889L153.388 13.0819L153.43 11.6994L153.766 0H155.145Z"
					fill="#F6F6F6"
				/>
				<path
					d="M171.163 0L170.805 12.4802L170.762 13.8612L170.409 26.2992L170.362 27.7822L169.82 46.8048L169.778 48.1902L169.465 59.0053L169.429 60.3907L169.138 70.4352L169.078 72.5125L169.061 73.1593L167.681 73.1185L167.694 72.6189L167.754 70.5444L168.398 48.1727L168.436 46.7873L168.997 27.3787L169.033 25.9976L169.388 13.7971L169.425 12.4205L169.782 0H171.163Z"
					fill="#F6F6F6"
				/>
				<path
					d="M187.572 33.2387L186.192 33.2627L186.849 70.7763L188.229 70.7522L187.572 33.2387Z"
					fill="#F6F6F6"
				/>
				<path
					d="M230 16.376V17.8124L218.068 21.2066L216.737 21.584L197.928 26.9376L195.987 27.4883L194.271 28.9115L187.596 34.4487L187.012 34.9309L186.216 34.5915L170.363 27.7825L169.53 27.4242L168.997 27.379L154.396 26.0898L153.02 25.9703L138.429 24.6811L137.049 24.5616L123.727 23.386L123.004 23.3219L123.124 21.9511L123.781 22.0108L137.09 23.1806L138.465 23.3044L153.056 24.5893L154.437 24.7087L169.034 25.998L169.875 26.0708L170.409 26.2995L186.592 33.2556L186.781 33.3343L186.883 33.2469L193.714 27.5844L195.328 26.2456L197.393 25.6586L216.31 20.2758L217.635 19.8955L230 16.376Z"
					fill="#F6F6F6"
				/>
				<path
					d="M169.096 48.2617L153.061 46.2324L153.234 44.8674L169.114 46.8763L202.831 43.5039L202.969 44.8733L169.096 48.2617Z"
					fill="#F6F6F6"
				/>
				<path
					d="M230 48.1953V49.6317L227.644 50.3106L226.315 50.6966L209.869 55.4588L207.735 56.0779L207.675 56.0838L205.734 56.3037L188.009 58.2995L186.629 58.4554L169.429 60.3914L168.83 60.4599L168.674 59.0935L169.465 59.0061L186.605 57.07L187.985 56.9185L205.178 54.9781L207.463 54.7202L209.336 54.1798L225.887 49.3884L227.212 49.0038L230 48.1953Z"
					fill="#F6F6F6"
				/>
				<path
					d="M230 31.334V32.7514L222.478 34.619L221.136 34.9497L203.143 39.4133L200.352 40.1067L200.016 38.7708L202.606 38.1284L220.702 33.6371L222.046 33.3065L230 31.334Z"
					fill="#F6F6F6"
				/>
				<path
					d="M230 62.957V64.3788L215.159 68.0587L213.177 68.554L213.062 68.5816L211.337 69.0085L211.143 68.2291L211.005 67.6682L212.831 67.2152L214.616 66.7738L230 62.957Z"
					fill="#F6F6F6"
				/>
				<path
					d="M230 77.0381V78.4599L220.514 80.8097L218.785 81.2409L218.453 79.9006L219.972 79.5248L230 77.0381Z"
					fill="#F6F6F6"
				/>
				<path
					d="M214.106 131.918H211.636L184.163 113.414L183.258 112.803L182.783 112.79L170.129 112.468L168.748 112.436L155.729 112.105L154.896 112.084L154.768 112.028L154.354 111.845L140.002 105.439L138.621 104.824L125.736 99.076L124.393 98.4744L112.331 93.0916L111.871 93.3451L110.463 94.1201L99.5659 100.112L98.1345 100.897L86.542 107.27L86.0644 116.589L85.9899 117.965L85.2728 131.918V131.928L84.5776 131.918H83.8882L85.2042 106.43L85.5401 106.246L112.267 91.5547L112.579 91.6975L155.205 110.716L183.694 111.441L214.106 131.918Z"
					fill="#F6F6F6"
				/>
				<path d="M139.312 116.588H85.334V117.965H139.312V116.588Z" fill="#F6F6F6" />
				<path d="M140.001 104.374H138.621V131.917H140.001V104.374Z" fill="#F6F6F6" />
				<path
					d="M111.88 92.9544L111.871 93.3448L111.199 116.589L111.158 117.964L110.758 131.917H109.378L109.778 117.964L109.819 116.589L110.463 94.1198L110.486 93.3215L110.5 92.9121L111.195 92.9354H111.204L111.88 92.9544Z"
					fill="#F6F6F6"
				/>
				<path
					d="M99.5895 99.7319L99.5661 100.112L98.5072 116.59L98.4211 117.965L97.5228 131.918H96.1426L97.0408 117.965L97.127 116.59L98.1348 100.897L98.1859 100.084L98.2137 99.6445L98.903 99.6911H98.9074L99.5895 99.7319Z"
					fill="#F6F6F6"
				/>
				<path
					d="M125.801 98.0835L125.782 98.3399L125.736 99.0756L124.613 116.589L124.527 117.964L123.629 131.917H122.244L123.142 117.964L123.229 116.589L124.393 98.4739L124.425 97.9961L125.105 98.0383L125.116 98.0427L125.801 98.0835Z"
					fill="#F6F6F6"
				/>
				<path
					d="M155.744 111.413V111.417L155.729 112.105L155.275 131.917H153.895L154.355 111.844L154.363 111.381L155.048 111.394L155.053 111.398L155.744 111.413Z"
					fill="#F6F6F6"
				/>
				<path
					d="M170.146 111.784L170.129 112.468L169.626 131.917H168.246L168.749 112.436L168.766 111.748L170.146 111.784Z"
					fill="#F6F6F6"
				/>
				<path d="M184.163 112.12H182.783V131.919H184.163V112.12Z" fill="#F6F6F6" />
				<path
					d="M230 57.4728V61.8868L226.315 50.6958L225.887 49.3876L221.136 34.9495L220.702 33.637L216.737 21.5836L216.31 20.2754L212.647 9.14856L212.215 7.83746L209.635 0H211.089L213.554 7.5024L213.986 8.8135L217.635 19.8952L218.067 21.2063L222.046 33.3063L222.478 34.6188L227.212 49.0031L227.644 50.3098L230 57.4728Z"
					fill="#F6F6F6"
				/>
				<path
					d="M41.2867 132.002C41.0472 125.962 40.0029 95.5561 42.9299 89.9621C46.4542 83.2245 67.9361 57.6304 105.946 34.7925L107.114 34.0947L108.22 34.8785C132.119 51.8281 159.462 70.1412 163.225 70.8128C168.169 70.7807 205.774 67.0587 212.621 65.921L213.302 69.9999C206.067 71.2018 168.11 74.953 163.162 74.953C161.954 74.953 159.442 74.953 132.078 56.4388C121.055 48.9801 110.335 41.4413 106.93 39.0391C67.3884 63.0598 48.6684 87.9387 46.609 91.8836C44.7424 95.4527 44.8972 118.292 45.4406 131.849L41.2867 132.002Z"
					fill="white"
				/>
				<path
					d="M230 103.379V114.051C229.82 113.624 229.622 113.152 229.406 112.647C229.388 112.597 229.369 112.551 229.347 112.501C226.039 104.634 218.977 87.8378 211.59 70.2429C211.309 69.5863 211.032 68.9298 210.757 68.2733C210.481 67.6134 210.205 66.9622 209.929 66.2964C208.525 62.9459 207.119 59.6026 205.734 56.2986C205.55 55.8616 205.36 55.4129 205.176 54.9729C203.76 51.5917 202.366 48.2718 201.023 45.0668C200.839 44.6211 200.65 44.1811 200.47 43.7397C198.199 38.3264 196.089 33.283 194.27 28.9112C194.082 28.4611 193.898 28.0211 193.714 27.5855C191.206 21.5705 189.297 16.9729 188.36 14.6508C188.088 13.9938 187.899 13.5174 187.797 13.2465C187.781 13.2125 187.768 13.1774 187.756 13.1416C186.15 8.78729 187.981 2.21576 188.69 0H193.096L192.826 0.683238C192.113 2.72272 190.512 8.64306 191.643 11.7081C191.717 11.9106 191.878 12.3098 192.107 12.8925C192.253 13.2552 192.429 13.6806 192.632 14.1774C193.625 16.6087 195.286 20.609 197.387 25.6524C197.563 26.069 197.742 26.5002 197.922 26.9329C199.328 30.2951 200.907 34.0711 202.596 38.1224C202.775 38.5449 202.955 38.9761 203.133 39.4073C205.084 44.069 207.177 49.057 209.326 54.1732C209.5 54.6 209.679 55.0268 209.859 55.4522C211.428 59.1918 213.024 62.991 214.606 66.767C214.79 67.1938 214.969 67.625 215.149 68.0519C216.778 71.9385 218.397 75.7888 219.969 79.5182C220.147 79.9552 220.331 80.3762 220.511 80.803C224.135 89.417 227.447 97.3083 230 103.379Z"
					fill="white"
				/>
				<path
					d="M74.5217 59.116L73.2116 60.6704L73.197 60.6849L71.8591 62.2772L71.5962 62.0558L69.991 60.7126L54.4696 47.7225L53.4122 46.8325L37.6191 33.6137L36.5617 32.7279L20.9117 19.6242L19.8441 18.7341L16.6571 16.0639L10.5754 10.971L9.51206 10.0809L1.53155 3.40012L0.472642 2.51003L-2.53027 0H3.90934L8.78032 4.07897L9.83923 4.9647L22.3928 15.471L23.4502 16.3567L35.7292 26.6357L36.7822 27.52L52.4555 40.6426L53.5086 41.524L73.1926 58.0001L74.5217 59.116Z"
					fill="white"
				/>
				<path
					d="M13.6105 131.917H9.13096L3.2741 117.791L2.74538 116.515L-2.30524 104.324L-2.83397 103.053V103.048L-9.40796 87.1868L-9.55986 86.8197L-10.3968 86.1642L-43.8042 60.0383V54.7939L-37.9897 59.3405L-37.2901 59.8868L-28.7283 66.5807L-27.6387 67.4344L-21.4109 72.3073L-20.3198 73.1552L-18.7234 74.4036L-17.6382 75.2573L-7.04769 83.5347L-6.14652 84.2412L-6.04575 84.4787L0.639249 100.621L1.21325 102.007L4.66894 110.339L5.23856 111.716L9.57643 122.186V122.19L10.1607 123.595L13.6105 131.917Z"
					fill="white"
				/>
				<path
					d="M109.678 0L109.521 9.7269L109.498 11.1079L109.102 35.5089L109.083 36.5957L107.022 36.5636L106.968 36.5593L104.944 36.5272L104.961 35.3895L105.353 11.3701L105.372 9.97018L105.537 0H109.678Z"
					fill="white"
				/>
				<path
					d="M205.619 29.4647C205.592 27.2311 204.685 25.0976 203.093 23.5263C201.502 21.9549 199.353 21.0722 197.114 21.0693C197.052 21.0693 196.994 21.0693 196.933 21.078C194.725 21.1259 192.623 22.0274 191.07 23.5923C189.516 25.1571 188.633 27.2628 188.607 29.4647C188.607 29.4939 188.607 29.5245 188.607 29.5536C188.607 32.1918 190.819 36.769 192.138 39.263C193.001 40.8946 193.917 42.4592 194.717 43.6683C195.885 45.4281 196.397 45.871 196.934 45.9423C196.994 45.9511 197.055 45.9555 197.115 45.9554C197.723 45.9554 198.238 45.6131 199.528 43.6683C200.327 42.4635 201.243 40.9004 202.106 39.2615C203.42 36.7719 205.622 32.1991 205.622 29.5536C205.62 29.5172 205.619 29.4866 205.619 29.4647ZM197.114 33.232C196.112 33.232 195.151 32.8351 194.443 32.1286C193.735 31.4221 193.337 30.4639 193.337 29.4647C193.337 28.4656 193.735 27.5074 194.443 26.8009C195.151 26.0944 196.112 25.6975 197.114 25.6975C198.115 25.6975 199.076 26.0944 199.784 26.8009C200.493 27.5074 200.891 28.4656 200.891 29.4647C200.889 30.4626 200.49 31.4189 199.782 32.1238C199.074 32.8288 198.114 33.2247 197.114 33.2247V33.232Z"
					fill="var(--desktop-map-point)"
				/>
			</g>
		</svg>
	);
};

export default MapThumb;
