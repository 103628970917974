import PointsConstants from '../constants/PointsConstants';

import AppDispatcher from '../dispatcher/AppDispatcher';

import _ from 'lodash';

import { EventEmitter } from 'events';

const _points_earned_disclaimer_text =
	'Points are only available to use two weeks after the checkout of a reservation.';

let _points = {
	balance: null,
	currency: null,
	points_ex_rate: null,
	max_cap: null,
	provider: null,
	show: false,
	user_chosen_points: null,
};
let _status = PointsConstants.STATUS.BUSY;

let _update_points_usage_status = null;

function clearPoints() {
	_points = {
		balance: null,
		currency: null,
		points_ex_rate: null,
		max_cap: null,
		provider: null,
		show: false,
		user_chosen_points: null,
	};
}

const PointsStore = _.extend({}, EventEmitter.prototype, {
	getPoints: function () {
		return _.extend({}, _points);
	},

	getStatus: function () {
		return _status;
	},

	getUpdatePointsUsageStatus: function () {
		return _update_points_usage_status;
	},

	getBalance: function () {
		return _.get(_points, 'balance', null);
	},

	getUpcoming: function () {
		return _.get(_points, 'upcoming', null);
	},

	setPoints: function (points = {}) {
		_points = Object.assign({}, _points, points, { show: true });
	},

	setUserChosenPoints(userChosenPoints = 0) {
		const user_chosen_points = Number(userChosenPoints);
		if (!isNaN(user_chosen_points)) {
			_points.user_chosen_points = user_chosen_points;
		} else {
			console.warn('setUserChosenPoints ::: invalid userChosenPoints', { user_chosen_points });
		}
	},

	emitChange: function () {
		this.emit('change');
	},

	addChangeListener: function (callback) {
		this.on('change', callback);
	},

	removeChangeListener: function (callback) {
		this.removeListener('change', callback);
	},

	getPointsEarnedDisclaimerText: function () {
		return _points_earned_disclaimer_text;
	},
});

AppDispatcher.register(function (payload) {
	const action = payload.action;

	switch (action.actionType) {
		case PointsConstants.SAVE_CHOSEN_USER_POINTS:
			PointsStore.setUserChosenPoints(action.data);
			break;

		////////////////////////////////////////

		case PointsConstants.GET_POINTS:
			clearPoints();
			_status = PointsConstants.STATUS.BUSY;
			break;

		case PointsConstants.GET_POINTS_SUCCESS:
			PointsStore.setPoints(action.data);
			_status = PointsConstants.STATUS.SUCCESS;
			break;

		case PointsConstants.GET_POINTS_FAIL:
			console.error(action.data);
			clearPoints();
			_status = PointsConstants.STATUS.FAILED;
			break;

		////////////////////////////////////////

		case PointsConstants.UPDATE_POINTS_USAGE:
			_update_points_usage_status = PointsConstants.STATUS.BUSY;
			break;

		case PointsConstants.UPDATE_POINTS_USAGE_SUCCESS:
			_update_points_usage_status = PointsConstants.STATUS.SUCCESS;
			break;

		case PointsConstants.UPDATE_POINTS_USAGE_FAIL:
			_update_points_usage_status = PointsConstants.STATUS.FAILED;
			break;

		////////////////////////////////////////

		default:
			return true;
	}

	PointsStore.emitChange();

	return true;
});

export default PointsStore;
