import SearchTerms from './SearchTerms';
import moment from 'moment';

function RecentSearch(recent_search) {
	this.terms = new SearchTerms(recent_search.terms);
	this.search_timestamp = moment(recent_search.created_timestamp);
	this.id = recent_search._id;
}

export default RecentSearch;
