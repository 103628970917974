function _stringAsciiPRNG(value, m) {
	const charCodes = [...value].map((letter) => letter.charCodeAt(0));
	const len = charCodes.length;

	const a = (len % (m - 1)) + 1;
	const c = charCodes.reduce((current, next) => current + next) % m;

	let random = charCodes[0] % m;
	for (let i = 0; i < len; i++) random = (a * random + c) % m;

	return random;
}

const _defaultColors = ['#d5362a', '#9c45b7', '#0e5ede', '#528833', '#be1870', '#ce0146'];

function _getRandomColor(value, colors = _defaultColors) {
	// if no value is passed, always return transparent color otherwise
	// a rerender would show a new color which would will
	// give strange effects when an interface is loading
	// and gets rerendered a few consequent times
	if (!value) return '';

	// value based random color index
	// the reason we don't just use a random number is to make sure that
	// a certain value will always get the same color assigned given
	// a fixed set of colors
	const colorIndex = _stringAsciiPRNG(value, colors.length);
	return colors[colorIndex];
}

const _getImageSource = (url) => url || 'broken Image';

const defaultSize = 50;

function avatarProps({ fullName, url, size = defaultSize }) {
	if (!fullName)
		return {
			sx: {
				width: size,
				height: size,
			},
		};

	const nameArray = fullName.split(' ');
	let children = '';
	while (nameArray.length && children.length < 2) {
		const name = nameArray.shift();
		if (name) children += name[0];
	}

	return {
		sx: {
			bgcolor: _getRandomColor(fullName),
			width: size,
			height: size,
			fontSize: (1.25 * size) / defaultSize + 'rem',
		},
		children: children.toUpperCase(),
		src: _getImageSource(url),
	};
}

export { avatarProps };
