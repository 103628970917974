import { useEffect } from 'react';
import { makeStyles } from '@mui/styles';

import TravelPolicyStore from '../../../stores/TravelPolicyStore';
import TravelPolicyActions from '../../../actions/TravelPolicyActions';
import TravelPolicyConstants from '../../../constants/TravelPolicyConstants';

import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';

import { StyledDialogCancelButton, StyledDialogDeleteButton } from './components/styled/buttons';

import { SnackbarVariants } from '../../general/snackbar/SnackbarConstants';

const useStyles = makeStyles((theme) => ({
	title: {
		fontWeight: 700,
		fontSize: 21,
	},
	content: {
		fontSize: 15,
		color: theme.palette.black.main,
	},
	actions: {
		padding: theme.spacing(2),
	},
	deleteButton: {
		'&.MuiButton-root': {
			marginLeft: 16,
		},
	},
}));

const SnackbarMessages = {
	SUCCESS: 'Travel policy successfully removed',
	ERROR: 'Failed to remove travel policy',
};

const DeleteConfirmationDialog = ({ open, onClose, onSnackbarShow, entryToDelete }) => {
	const classes = useStyles();

	useEffect(() => {
		TravelPolicyStore.addChangeListener(_onChange);

		return () => {
			TravelPolicyStore.removeChangeListener(_onChange);
		};
	}, []);

	const _onChange = () => {
		const status = TravelPolicyStore.getDeleteStatus();
		let message, variant;

		if (status === TravelPolicyConstants.STATUS.SUCCESS) {
			message = SnackbarMessages.SUCCESS;
			variant = SnackbarVariants.SUCCESS;
		} else if (status === TravelPolicyConstants.STATUS.FAILED) {
			message = TravelPolicyStore.getErrorMessage() ?? SnackbarMessages.ERROR;
			variant = SnackbarVariants.ERROR;
		}

		if (message && variant) {
			onSnackbarShow(message, variant);
			setTimeout(() => {
				TravelPolicyActions.resetTravelPolicyStatus();
			}, 0);
		}
	};

	const handleDelete = () => {
		if (entryToDelete) {
			TravelPolicyActions.removeTravelPolicyEntry(entryToDelete);
			onClose();
		}
	};

	return (
		<Dialog
			open={open}
			onClose={onClose}
			aria-labelledby="delete-confirmation-title"
			aria-describedby="delete-confirmation-description"
		>
			<DialogTitle id="delete-confirmation-title" className={classes.title}>
				Remove Location
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="delete-confirmation-description" className={classes.content}>
					Are you sure you want to remove the travel policy for this location?
				</DialogContentText>
			</DialogContent>
			<DialogActions className={classes.actions}>
				<StyledDialogCancelButton onClick={onClose}>Cancel</StyledDialogCancelButton>
				<StyledDialogDeleteButton onClick={handleDelete} className={classes.deleteButton}>
					Remove
				</StyledDialogDeleteButton>
			</DialogActions>
		</Dialog>
	);
};

export default DeleteConfirmationDialog;
