import _ from 'lodash';
import React from 'react';
import createClass from 'create-react-class';
import withStyles from '@mui/styles/withStyles';

import {
	TextField,
	Dialog,
	Checkbox,
	Button,
	FormControlLabel,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
} from '@mui/material';
// import Icon from 'react-evil-icons';
import Icon from '../../../../ReactEvilIcon';

import BasketActions from '../../../../../actions/BasketActions';
import Validator from 'arbitrip-common/client/utils/Validator';
import BasketConstants from '../../../../../constants/BasketConstants';

import BasketAutocomplete from './BasketAutocomplete.react';
import BasketDeal from './BasketDeal.react';

import Analytics from 'arbitrip-common/client/analytics';
import ProfileStore from '../../../../../stores/ProfileStore';
import classNames from 'classnames';

const styles = {
	label: {
		color: 'rgba(0, 0, 0, 0.54)',
		padding: 0,
		fontSize: '1rem',
		fontFamily: 'Lato, Roboto, sans-serif',
		lineHeight: 1,
		transform: 'translate(0, 1.5px) scale(0.75)',
		transformOrigin: 'top left',
		transition: 'color 200ms',
	},
};

function getBasketModalState() {
	return {
		email: '',
		clear_modal_open: false,
		send_failed_modal_open: false,
		should_validate: false,
		invalidEmail: false,
	};
}

var BasketModal = createClass({
	displayName: 'BasketModal',

	getInitialState: function () {
		return getBasketModalState();
	},

	componentDidUpdate: function (prevProps) {
		if (
			this.props.send_status !== prevProps.send_status &&
			this.props.send_status === BasketConstants.STATUS.BUSY &&
			prevProps.send_status === BasketConstants.STATUS.FAILED
		) {
			this.setState({
				send_failed_modal_open: true,
			});
			let { basket } = this.props;
			Analytics.actions.interactions.failedToSendBasket(
				Object.keys(basket.recipients),
				basket.name,
				basket.remarks,
				basket.deals.length,
			);
		}
	},

	handleNameOnChange: function (e) {
		BasketActions.updateName(e.target.value);
	},

	handleRemarksOnChange: function (e) {
		BasketActions.updateRemarks(e.target.value);
	},

	handleEmailOnChange: function (e) {
		this.setState({
			email: e.target.value,
		});
	},

	handleDeleteEmail: function (email) {
		BasketActions.removeEmail(email);
	},

	handleOnKeyUp: function (e) {
		if (e.charCode === 9 || e.charCode === 13) {
			let email = e.target.value;
			if (Validator.validateEmailAddress(email)) {
				BasketActions.addEmail(email);
				this.setState({
					email: '',
				});
			}
		}
	},

	handleAddEmail: function (email) {
		if (Validator.validateEmailAddress(email)) {
			BasketActions.addEmail(email);
		}
	},

	onCloseClick: function (e) {
		BasketActions.closeBasket();
		Analytics.actions.interactions.closedBasket();
	},
	onSendClick: function (e) {
		let valid = this.isValid();
		if (valid) {
			let { basket } = this.props;
			const emails = Object.keys(basket.recipients);
			BasketActions.sendBasket(basket);
			Analytics.actions.interactions.sentBasket(emails, basket.name, basket.remarks, basket.deals.length);
		}
		this.setState({
			should_validate: !valid,
		});
	},
	handleClearOnClick: function (e) {
		this.setState({
			clear_modal_open: true,
		});
	},
	handleClearModalClose: function () {
		this.setState({
			clear_modal_open: false,
		});
	},
	handleSendFailedDialogClose: function () {
		this.setState({
			send_failed_modal_open: false,
		});
	},
	handleClearListApproval: function () {
		BasketActions.clearBasket();
		Analytics.actions.interactions.clearedBasket();
	},

	isNameValid: function () {
		if (!this.props.profile.anonymous) return true;
		else if (this.props.basket.name && this.props.basket.name.length >= 2) return true;
		else return false;
	},

	areRecipientsValid: function () {
		return Object.keys(this.props.basket.recipients).length;
	},

	areDealsValid: function () {
		return this.props.basket.deals.length;
	},

	isCombtasValid: function () {
		const { basket } = this.props;
		return !basket.send_to_combtas || Validator.validateCombtasId(basket.combtas_id);
	},

	invalidEmail() {
		let { invalidEmail } = this.state;

		return invalidEmail;
	},

	isValid: function () {
		return (
			this.isNameValid() &&
			this.areRecipientsValid() &&
			this.areDealsValid() &&
			this.isCombtasValid() &&
			!this.invalidEmail()
		);
	},

	getInvalidMessage: function () {
		if (!this.isNameValid()) {
			return 'Name must contain at least 2 letters';
		}

		if (this.invalidEmail()) {
			return 'Please notice, you have invalid email address';
		}

		if (!this.areRecipientsValid()) {
			return 'Please provide at least one valid email address';
		}

		if (!this.isCombtasValid()) {
			return 'Please provide a valid TAS ID';
		}

		if (!this.areDealsValid()) {
			return 'Please add at least one suggestion to the list';
		}

		return '';
	},

	setSendToCombtas: function (event, isInputChecked) {
		event.persist();
		BasketActions.updateSendToCombtas(isInputChecked);
	},

	handleCombtasIdOnChange: function (e) {
		BasketActions.updateCombtasId((e.target.value || '').trim());
	},

	onErrorRecipients(invalidEmail) {
		this.setState({ invalidEmail });
	},

	onUpdateRecipients(data) {
		let { basket } = this.props;
		let recipients = _.get(basket, 'recipients');
		let recipientsArr = Object.keys(recipients && _.isObject(recipients) ? recipients : {});
		this.setState({ invalidEmail: false });

		if (data) {
			// Add Emails
			data.forEach((item) => {
				if (!_.isObject(item) || !item.value) return;
				if (_.isArray(recipientsArr)) {
					if (!recipientsArr.includes(item.value)) {
						this.handleAddEmail(item.value);
					}
				}
			});

			// Delete Emails
			let deleteArr = _.reject(recipientsArr, (item) => {
				return data.some((el) => el.value === item);
			});

			deleteArr.forEach((item) => {
				this.handleDeleteEmail(item);
			});
		}
	},

	getBasketRecipients() {
		let { basket } = this.props;
		let recipients = _.get(basket, 'recipients');

		if (_.isObject(recipients)) {
			recipients = Object.keys(recipients);

			return recipients.map((item) => {
				return { label: item, value: item };
			});
		}

		return null;
	},

	render: function () {
		let { should_validate } = this.state;
		let { classes, basket, send_status, profile } = this.props;

		const busy = send_status === BasketConstants.STATUS.BUSY;
		const empty = !basket.deals.length;
		const three_or_more_deals = basket.deals.length >= 3;

		const integration_enabled = _.get(profile, 'company.settings.combtas_integration_enabled', false);
		const private_travel_only = ProfileStore.isPrivateTravelOnly();
		const combtas_enabled = integration_enabled && !private_travel_only;

		const clear_actions = [
			<Button key={'cancel-button'} variant="text" color="primary" onClick={this.handleClearModalClose}>
				Cancel
			</Button>,
			<Button key={'clear-button'} variant="text" color="primary" onClick={this.handleClearListApproval}>
				Clear
			</Button>,
		];

		const send_failed_actions = [
			<Button variant="text" color="primary" onClick={this.handleSendFailedDialogClose}>
				OK
			</Button>,
		];

		return (
			<div className="basket-modal">
				<div className="modal-title">
					<span>Send hotels for review</span>
					<button className="action close-modal" onClick={this.onCloseClick} aria-label="Close">
						<Icon name="ei-close" size="m" className="evil-icon" />
					</button>
				</div>
				<div className="modal-fields">
					{profile.anonymous ? (
						<React.Fragment>
							<div className="name">
								<TextField
									className="field-input"
									label="From"
									placeholder="Full Name"
									fullWidth
									InputLabelProps={{
										shrink: true,
									}}
									value={basket.name}
									onChange={this.handleNameOnChange}
									style={{
										fontSize: 12,
										height: 68,
									}}
								/>
							</div>
						</React.Fragment>
					) : null}
					<div className="to">
						<BasketAutocomplete
							value={this.getBasketRecipients()}
							onUpdate={this.onUpdateRecipients}
							onError={this.onErrorRecipients}
							label="To"
							disableAutoComplete={private_travel_only}
						/>
					</div>
					<br />
					<div className="remarks">
						<label htmlFor={'remarks'} className={classNames(classes.label)}>
							Remarks and Notes
						</label>

						<TextField
							className="field-input"
							id={'remarks'}
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							value={basket.remarks}
							onChange={this.handleRemarksOnChange}
							style={{
								fontSize: 12,
								height: 68,
							}}
						/>
					</div>
					{combtas_enabled
						? [
								<div className="field send-to-combtas" key="b1">
									<FormControlLabel
										control={
											<Checkbox
												checked={basket.send_to_combtas}
												disabled={busy}
												onChange={this.setSendToCombtas}
												size="small"
											/>
										}
										label="Send to TAS"
									/>
								</div>,
								basket.send_to_combtas ? (
									<div className="field combtas-id" key="b2">
										<TextField
											className="field-input"
											placeholder="e.g. TAS00123T"
											disabled={busy || !basket.send_to_combtas}
											fullWidth={true}
											value={basket.combtas_id}
											onChange={this.handleCombtasIdOnChange}
											style={{
												fontSize: 12,
												height: 68,
											}}
										/>
									</div>
								) : null,
							]
						: null}
				</div>
				{empty ? (
					<div className={`modal-deals${integration_enabled ? '' : ' no-integration'}`}>
						<div className="empty-list">
							<span>The suggestions list is empty.</span>
							<span>Add some deals to the list and forward it to your traveler(s).</span>
						</div>
					</div>
				) : (
					<div className={`modal-deals${integration_enabled ? '' : ' no-integration'}`}>
						<div className="title">
							<span>Hotels to Review:</span>
						</div>
						<div
							className={
								'deals' +
								(three_or_more_deals ? ' multiple' : '') +
								(integration_enabled ? '' : ' no-integration')
							}
						>
							{basket.deals.map((deal, idx) => (
								<BasketDeal deal={deal} key={idx} profile={profile} />
							))}
						</div>
					</div>
				)}
				<div className={'modal-actions' + (busy ? ' busy' : '')}>
					{three_or_more_deals ? null : <hr style={{ margin: '0px' }} />}
					<div className="actions">
						<button
							className={'action send' + (busy ? ' busy' : '')}
							onClick={(e) => (busy ? null : this.onSendClick(e))}
						>
							Send
						</button>
						<Dialog
							key="send-failed-dialog"
							actions={send_failed_actions}
							open={this.state.send_failed_modal_open}
							onClose={this.handleSendFailedDialogClose}
							aria-describedby="alert-dialog-description"
							fullWidth={true}
							maxWidth={'xs'}
							id="basket-dialog"
						>
							<DialogContent>
								<DialogContentText id="alert-dialog-description">
									Failed to deliver suggestions... Please try again.
								</DialogContentText>
							</DialogContent>
						</Dialog>
						<button
							className={'action cancel' + (busy ? ' busy' : '')}
							onClick={(e) => (busy ? null : this.onCloseClick(e))}
						>
							Close
						</button>
						{should_validate && !this.isValid() ? (
							<span className="invalid-basket">{this.getInvalidMessage()}</span>
						) : null}
						{busy ? (
							<i
								key={'spinner'}
								className="fa fa-spin fa-spinner bottom-right-corner busy-indicator"
								style={{ margin: '0px', top: '12px' }}
							/>
						) : (
							[
								<button
									key={'clear-button' + (busy ? ' busy' : '')}
									className="action clear bottom-right-corner"
									onClick={this.handleClearOnClick}
									aria-label="Clear"
								>
									<Icon key={'evil-icon'} name="ei-trash" size="s" className="evil-icon" />
								</button>,
								<Dialog
									key="clear-dialog"
									open={this.state.clear_modal_open}
									onClose={this.handleClearModalClose}
									aria-labelledby="alert-dialog-title"
									fullWidth={true}
									maxWidth={'xs'}
								>
									<DialogTitle id="alert-dialog-title">Clear List?</DialogTitle>
									<DialogActions>{clear_actions}</DialogActions>
								</Dialog>,
							]
						)}
					</div>
				</div>
			</div>
		);
	},

	_onChange: function () {
		this.setState(getBasketModalState());
	},
});

export default withStyles(styles, { withTheme: true })(BasketModal);
