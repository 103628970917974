import React from 'react';
import _ from 'lodash';
import ProfileStore from '../stores/ProfileStore';

import { ReactComponent as MoneyIcon } from '@/img/money.svg';

const InstallmentsBanner = () => {
	const profile = ProfileStore.getProfile();

	const company_installments_iframe_enabled = _.get(profile, 'company.settings.installments_iframe_enabled', false);

	if (!company_installments_iframe_enabled) {
		return null;
	}

	return (
		<div className="installments-banner">
			<div className="installments-banner__icon-wrap">
				<MoneyIcon />
			</div>
			<div className="installments-banner__text">You can pay in up to 3 installments</div>
		</div>
	);
};

export default InstallmentsBanner;
