import React from 'react';

import FiltersActions from '../../../../actions/FiltersActions';

import FiltersConstants from '../../../../constants/FiltersConstants';
import Analytics from 'arbitrip-common/client/analytics';

import FilterCheckboxWithLabel from './components/FilterCheckboxWithLabel.react';

class PreferredHotelsFilter extends React.Component {
	handleCheckboxChange = (event, isInputChecked) => {
		const { profile } = this.props;

		event.persist();
		FiltersActions.filterByPreferredHotels(isInputChecked);
		setTimeout(function () {
			FiltersActions.filterByPreferredHotels(isInputChecked);
		}, 10);

		const filter_value = isInputChecked ? 'Show preferred hotels' : 'Show all';
		Analytics.actions.interactions.filteredResults(
			profile,
			FiltersConstants.FILTER_METHOD.PREFERRED_HOTELS,
			filter_value,
		);
	};

	render() {
		return (
			<div className="filter disabled">
				<label className="filter-title" htmlFor="preferred-hotels-filter">
					Preferred Hotels
				</label>
				<div name="preferred-hotels-filter" className="check-list">
					<div className="checkbox-container">
						<FilterCheckboxWithLabel
							checked={this.props.filter}
							label="Show Preferred Hotels"
							onChange={(e, c) => this.handleCheckboxChange(e, c)}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default PreferredHotelsFilter;
