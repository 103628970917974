import moment from 'moment';

function WizardDestination(wizard_destination, display_order) {
	this.destination = {
		address: (wizard_destination && wizard_destination.destination && wizard_destination.destination.address) || '',
		place: wizard_destination && wizard_destination.destination && wizard_destination.destination.place,
	};
	this.start_date = wizard_destination && wizard_destination.start_date && moment(wizard_destination.start_date);
	this.end_date = wizard_destination && wizard_destination.end_date && moment(wizard_destination.end_date);

	this.display_order = display_order || wizard_destination.display_order;
}

export default WizardDestination;
