const keyMirror = require('keymirror');

const INFO_STATUS = {
    INITIAL: "initial",
    BUSY: "busy",
    SUCCESS: "success",
    FAIL: "fail"
};

const DEALS_STATUS = Object.assign({}, INFO_STATUS);
const RECHECK_STATUS = Object.assign({}, INFO_STATUS);

const HOTEL_CONSTANTS = {
    MAX_REASONS: 3,
    MAX_DEALS_TO_SHOW: 80,

    EXTRA_INFORMATION_PAYMENT_TYPE: {
        1: "American Express",
        2: "Visa",
        3: "Euro/Mastercard",
        4: "Carte Bleue",
        5: "Diners Club",
        7: "JCB",
        8: "PIN",
        9: "Red 6000",
        10: "Maestro",
        11: "Discover",
        12: "Bancontact",
        13: "Solo",
        14: "Switch",
        15: "Carte Blanche",
        16: "NICOS",
        17: "UC",
        18: "No creditcards accepted, only cash",
        19: "Bankcard",
        21: "CartaSi",
        22: "Argencard",
        23: "Cabal",
        25: "Red Compra",
        26: "Other Cards",
        27: "Greatwall",
        28: "Peony",
        29: "Dragon",
        30: "Pacific",
        31: "Jin Sui",
        32: "Eftpos",
        34: "Hipercard",
        35: "UnionPay debit card",
        36: "UnionPay credit card",
        37: "EC-Card",
        38: "BC-Card",
        39: "MasterCard (virtual credit card)",
        41: "KH Széchényi Pihenõkártya",
        42: "MKB Széchényi Pihenõkártya",
        43: "OTP Széchényi Pihenõkártya",
        44: "UnionPay credit card",
        45: "Cash",
        46: "PayPal",
        47: "Cheque (domestic only)",
        48: "ANCV chèques-vacances",
        49: "PayPlug",
        50: "Euro 6000",
        51: "Postepay",
        52: "Sistema 4B",
        53: "PagSeguro",
        54: "Elo Creditcard",
        55: "Bcash",
        56: "Interac e-Transfer",
        57: "M-Pesa",
        58: "Knet",
        59: "Western Union",
        60: "Apple Pay",
        61: "Square Cash",
        62: "iDeal",
        63: "BankAxept",
        64: "Reka-Check",
        65: "Paysera.com",
        66: "BankLink",
        67: "Alipay",
        68: "Tenpay",
        69: "Lastschrift",
        70: "Sadad",
        71: "Qiwi",
        72: "AirPlus",
        73: "Virtual Credit Card",
        74: "Visa (virtual credit card)",
        75: "American Express (virtual credit card)",
    }
};

module.exports = Object.assign({},
    HOTEL_CONSTANTS,
    { INFO_STATUS },
    { DEALS_STATUS },
    { RECHECK_STATUS },
    keyMirror({
        SET_HOTEL_RECOMMENDED_INDEX: null,
        SET_HOTEL_SCROLL: null,
        RECEIVE_HOTEL_DATA: null, // After postback
        RECEIVE_HOTEL_DATA_BY_ID: null,
        // RECEIVE_HOTEL_DEALS_DATA: null // After postback
        ENRICH_HOTEL_WITH_ADDITIONAL_INFO: null,
        ENRICH_HOTEL_WITH_BOOKING_DEALS: null, // Not in desktop client
        ENRICH_HOTEL_WITH_DEALS: null, // Obsolete?
        CLEAR_HOTEL_DATA: null,
        CLEAR_HOTEL_DATA_BY_ID: null,
        BAN_DEAL: null,

        GET_HOTEL_INFO: null,
        GET_HOTEL_INFO_SUCCEEDED: null,
        GET_HOTEL_INFO_FAILED: null,

        DEALS_AVAILABILITY_CHECK_SUCCESS: null,
        DEALS_AVAILABILITY_CHECK_FAIL: null,

        GET_HOTEL_DEALS: null,
        GET_HOTEL_DEALS_SUCCEEDED: null,
        GET_HOTEL_DEALS_FAILED: null,

        GET_HOTEL_BOOKING_DEALS: null,  // Not in desktop client
        GET_HOTEL_BOOKING_DEALS_SUCCEEDED: null,  // Not in desktop client
        GET_HOTEL_BOOKING_DEALS_FAILED: null, // Not in desktop client

        GET_HOTEL_RECHECKED_DEALS: null,
        GET_HOTEL_RECHECKED_DEALS_FAILED: null,

        FIRE_RECHECKED_DEALS_RESULTS: null,
        FIRE_RECHECKED_DEALS_ERROR: null,
        CLEAR_FIRE_RECHECKED_DEALS_RESULTS: null,
        SET_TOTAL_DISPLAYED_DEALS_COUNT: null,
    }));
