import React from 'react';
import createClass from 'create-react-class';

import FiltersActions from '../../../../actions/FiltersActions';

import FiltersConstants from '../../../../constants/FiltersConstants';
import Analytics from 'arbitrip-common/client/analytics';

const NameFilterOld = createClass({
	displayName: 'NameFilterOld',

	onNameContainsChange: function (e) {
		const name = e.target.value;
		const _self = this;

		const { profile, filter } = _self.props;

		if (filter !== name) {
			FiltersActions.filterByNameContains(name);
			setTimeout(() => {
				FiltersActions.filterByNameContains(name);
			}, 10);

			Analytics.actions.interactions.filteredResults(profile, FiltersConstants.FILTER_METHOD.HOTEL, name);
		}
	},

	onClearClick: function (e) {
		const { profile, filter } = this.props;

		console.log(filter);
		if (filter) {
			FiltersActions.filterByNameContains('');
			setTimeout(() => {
				FiltersActions.filterByNameContains('');
			}, 10);

			Analytics.actions.interactions.filteredResults(profile, FiltersConstants.FILTER_METHOD.HOTEL, '');
		}
	},

	render() {
		let nameContainsInputClass = 'form-control';
		nameContainsInputClass += this.props.filter ? '' : ' empty';

		return (
			<div className="filter">
				<label className="filter-title" htmlFor="hotel-name-input">
					Hotel Name
				</label>

				<input
					id="hotel-name-input"
					type="text"
					className={nameContainsInputClass}
					placeholder=""
					value={this.props.filter}
					onChange={this.onNameContainsChange}
				/>

				<button className="clear-button-wrap" onClick={this.onClearClick} aria-label="Clear Hotel Name">
					<div className="clear-button-img" />
				</button>
			</div>
		);
	},
});

export default NameFilterOld;
