import AppDispatcher from '../dispatcher/AppDispatcher';
import AppGridConstants from '../constants/AppGridConstants';

function setAppStatus(status) {
	AppDispatcher.handleAction({
		actionType: AppGridConstants.SET_GLOBAL_APP_STATUS,
		data: status,
	});
}

var AppGridActions = {
	getUserData: function (data) {
		AppDispatcher.handleAction({
			actionType: AppGridConstants.RECEIVE_USER_DATA,
			data: data, // ???
		});
	},

	getCompanyData: function (data) {
		AppDispatcher.handleAction({
			actionType: AppGridConstants.RECEIVE_COMPANY_DATA,
			data: data, // ???
		});
	},

	setAppStatusAsIdle: () => setAppStatus(AppGridConstants.STATUSES.IDLE),
	setAppStatusAsPending: () => setAppStatus(AppGridConstants.STATUSES.PENDING),
	setAppStatusAsParsing: () => setAppStatus(AppGridConstants.STATUSES.PARSING),
};

export default AppGridActions;
