const ApiURLs = require('./ApiURLs');

const ProfileConstants = require('./ProfileConstants');
const HotelConstants = require('./HotelConstants');
const DealConstants = require('./DealConstants');
const FiltersConstants = require('./FiltersConstants');
const ReservationConstants = require('./ReservationConstants');
const UsersManagementConstants = require('./UsersManagementConstants');
const FlagsConstants = require('./FlagsConstants');
const TopDestinationsConstants = require('./TopDestinationsConstants');
const PlacesConstants = require('./PlacesConstants');

module.exports = {
    ApiURLs,

    ProfileConstants,
    HotelConstants,
    DealConstants,
    FiltersConstants,
    ReservationConstants,
    UsersManagementConstants,
    FlagsConstants,
    TopDestinationsConstants,
    PlacesConstants
};
