import ReservationConstants from '../constants/ReservationConstants';

function getBookingStatus(status) {
	status = status.toLowerCase();
	let text = '';

	switch (status) {
		case ReservationConstants.RESERVATION_STATUS.QUOTE:
			text = 'Quote';
			break;
		case ReservationConstants.RESERVATION_STATUS.REQUESTED:
			text = 'Requested';
			break;
		case ReservationConstants.RESERVATION_STATUS.REJECTED:
			text = 'Rejected';
			break;
		case ReservationConstants.RESERVATION_STATUS.PENDING:
		case ReservationConstants.RESERVATION_STATUS.PENDING_SUPPLIER:
		case ReservationConstants.RESERVATION_STATUS.PENDING_PAYMENT:
		case ReservationConstants.RESERVATION_STATUS.PENDING_SUPPORT:
			text = 'Pending';
			break;
		case ReservationConstants.RESERVATION_STATUS.CANCELED:
		case ReservationConstants.RESERVATION_STATUS.CANCELLED:
			text = 'Canceled';
			break;
		case ReservationConstants.RESERVATION_STATUS.CANCELING:
			text = 'Cancelling...';
			break;
		case ReservationConstants.RESERVATION_STATUS.ABORTED:
			text = 'Aborted';
			break;
		case ReservationConstants.RESERVATION_STATUS.APPROVED:
			text = 'Confirmed';
			break;
		case ReservationConstants.RESERVATION_STATUS.ERROR:
			text = 'Error';
			break;
		default:
		// Do nothing
	}

	return text;
}

function getRequestStatus(status) {
	status = status.toLowerCase();
	let text = '';

	switch (status) {
		case ReservationConstants.REQUEST_STATUS.PENDING:
			text = 'Pending';
			break;
		case ReservationConstants.REQUEST_STATUS.APPROVED:
			text = 'Approved';
			break;
		case ReservationConstants.REQUEST_STATUS.REJECTED:
			text = 'Declined';
			break;
		case ReservationConstants.REQUEST_STATUS.EXPIRED:
			text = 'Expired';
			break;
		case ReservationConstants.REQUEST_STATUS.ERROR:
			text = 'Error';
			break;
		default:
		// Do nothing
	}

	return text;
}

export default {
	getBookingStatus,
	getRequestStatus,
};
