import ManagementConstants from '../constants/ManagementConstants';
import moment from 'moment';
import Airport from '../entities/Airport';

function TripRequestFlight(flight, display_order) {
	this.id = flight._id;

	this.from = new Airport(flight.from);
	this.to = new Airport(flight.to);

	// this.one_way = flight.one_way;

	this.depart_date = flight.depart_date && moment(flight.depart_date);
	this.depart_time = flight.depart_time; // || "Any";
	// this.return_date = flight.return_date && moment(flight.return_date);
	// this.return_time = flight.return_time;// || "";

	this.component_type = ManagementConstants.TRIP_REQUEST_COMPONENT_TYPE.FLIGHT;
	this.display_order = display_order || flight.display_order;
}

export default TripRequestFlight;
