import _ from 'lodash';
import React, { Component } from 'react';
import classNames from 'classnames';
import Popper from '@mui/material/Popper';

import Ajax from '../../../../utils/Ajax';
import { Autocomplete, TextField } from '@mui/material';
import ProfileEntity from '../../../../entities/Profile';
import ReservationConstants from '../../../../constants/ReservationConstants';
import Validator from 'arbitrip-common/client/utils/Validator';
import Styles from '../../../../utils/Styles';

const delay_millis = 145;

function CustomPopper(props) {
	return <Popper {...props} placement="bottom-start" style={{ width: 'fit-content' }} />;
}

function getTravelersAutoCompleteState() {
	return {
		data: [],
		busy: false,
		txt: null,
		open: false,
	};
}

class TravelersAutocomplete extends Component {
	constructor(props) {
		super(props);

		this.state = getTravelersAutoCompleteState();
		this.previous_data = null;
	}

	delay(callback, millis) {
		let timer = 0;

		clearTimeout(timer);
		setTimeout(callback, millis);
	}

	updateData(data) {
		if (!Array.isArray(data)) {
			return false;
		}

		if (this.props.create_new_traveler) {
			data.push(
				new ProfileEntity({
					id: -1,
					full_name: ReservationConstants.CREATE_NEW_TRAVELER_TEXT,
					email: '',
				}),
			);
		}

		if (this.state.data) {
			this.previous_data = { ...this.state.data };
		}

		this.setState({
			data: data.map((traveler) =>
				Object.assign(new ProfileEntity(traveler), {
					full_name_and_email: traveler.full_name + (traveler.email ? ' (' + traveler.email + ')' : ''),
				}),
			),
		});
	}

	handleOnUpdateInput = (e, searchText) => {
		if (_.isFunction(this.props.onUpdateInput)) {
			this.props.onUpdateInput(searchText);
		}

		if (searchText && !Validator.validateEnglishOnly(searchText, true)) {
			if (_.isFunction(this.props.setEnglishCharsMessage)) {
				this.props.setEnglishCharsMessage(true);
			}

			this.setState({
				busy: false,
			});
			return false;
		}

		if (!searchText || !searchText.length) {
			this.setState({
				busy: false,
				txt: '',
			});
			this.updateData([]);
			return false;
		}

		if (this.props.create_new_traveler && searchText === ReservationConstants.CREATE_NEW_TRAVELER_TEXT) {
			this.setState({
				busy: false,
			});
			return false;
		}

		this.setState({
			busy: true,
			txt: searchText,
		});

		if (Validator.validateEnglishOnly(searchText, true) && _.isFunction(this.props.setEnglishCharsMessage)) {
			this.props.setEnglishCharsMessage(false);
		}

		let _self = this;
		this.delay(function () {
			console.log('delayed travelers ac callback');
			Ajax.companyEmployeesLookup(
				searchText.trim(),
				null,
				_self.props.agent_contract_id,
				_self.props.agent_client_company_id,
			)
				.done(function (data) {
					console.log('Travelers AutoComplete Search Results', data);
					if (_self.state.txt) {
						_self.updateData(data);
					} else {
						console.log('box is empty so results are not stored');
						_self.updateData([]);
					}
				})
				.fail(function (err) {
					console.log('Travelers AutoComplete Search Error', err);
				})
				.always(function () {
					_self.setState({
						busy: false,
					});
				});
		}, delay_millis);
	};

	handleOnNewRequest = (e, chosenRequest) => {
		if (!chosenRequest) {
			return;
		}

		const index = _.get(chosenRequest, 'id');
		const full_name = chosenRequest && chosenRequest.full_name;

		console.log('ONR', chosenRequest, index, ' PREV', this.previous_data, 'THISTATE', this.state.data);
		this.setState({
			txt: full_name,
		});

		if (_.isFunction(this.props.onNewRequest)) {
			this.props.onNewRequest(chosenRequest, index);
		}
	};

	closeAutocomplete = () => {
		this.setState({ open: false });
	};

	render() {
		const { searchText, error, autoFocus, disabled, id } = this.props;
		let { data } = this.state;

		return (
			<div className="travelers-autocomplete" style={Styles.travelersAutocomplete.container}>
				<Autocomplete
					id={id}
					options={data}
					freeSolo
					getOptionLabel={(option) => option.full_name_and_email || option}
					isOptionEqualToValue={(option, value) =>
						option.full_name_and_email.toLowerCase().includes(value.toLowerCase())
					}
					filterOptions={(options) => options}
					onInputChange={this.handleOnUpdateInput}
					onChange={this.handleOnNewRequest}
					openOnFocus
					value={searchText}
					autoFocus={autoFocus}
					disabled={disabled}
					open={data.length > 0 && this.state.open}
					onOpen={() => this.setState({ open: true })}
					onClose={() => this.setState({ open: false })}
					PopperComponent={CustomPopper}
					renderInput={(params) => (
						<TextField
							{...params}
							className={classNames('field-input', error && 'error')}
							onBlur={() => this.closeAutocomplete()}
							InputProps={{
								...params.InputProps,
								endAdornment: false,
							}}
						/>
					)}
				/>
				{this.state.busy ? (
					<i
						className="fa fa-spin fa-spinner busy-indicator"
						style={Styles.travelersAutocomplete.busyLoader}
					/>
				) : null}
			</div>
		);
	}
}

export default TravelersAutocomplete;
