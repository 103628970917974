import React from 'react';
import createClass from 'create-react-class';

import _ from 'lodash';

import ReportsStore from '../../../stores/ReportsStore';
import ReportsActions from '../../../actions/ReportsActions';
import moment from 'moment';
import ReportsConstants from '../../../constants/ReportsConstants';

import ProfileStore from '../../../stores/ProfileStore';

import Analytics from 'arbitrip-common/client/analytics';

import Currencies from '../../../utils/Currencies';
import JsonToCsvFileSaver from '../../../utils/JsonToCsvFileSaver';

const monthNames = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

function capitalizeTheFirstLetterOfEachWord(words) {
	var separateWord = words.toLowerCase().split(' ');
	for (var i = 0; i < separateWord.length; i++) {
		separateWord[i] = separateWord[i].charAt(0).toUpperCase() + separateWord[i].substring(1);
	}
	return separateWord.join(' ');
}

function getGeneralInfoFields(currency) {
	return [
		{ label: 'Total Bookings', value: 'total_bookings' },
		{ label: 'Total Room Nights', value: 'total_room_nights' },
		{ label: 'Average Stay', value: 'averageLengthOfStayInDays' },
		{ label: 'Total Spend', value: (row) => Currencies.getPrecisePriceWithCurrency(row['totalSpent'], currency) },
		{
			label: 'Average Spend per Night',
			value: (row) => Currencies.getPrecisePriceWithCurrency(row['averagePricePerNight'], currency),
		},
	];
}

function getTopDestinationFields(currency) {
	return [
		{ label: 'City', value: (row) => capitalizeTheFirstLetterOfEachWord(row['name']) },
		{ label: 'Nights', value: 'total_room_nights' },
		{
			label: 'Avg. Nightly Rate',
			value: (row) => Currencies.getPrecisePriceWithCurrency(row['averagePricePerNight'], currency),
		},
		{ label: 'Total Cost', value: (row) => Currencies.getPrecisePriceWithCurrency(row['totalSpent'], currency) },
	];
}

function getTopTravelersFields(currency) {
	return [
		{ label: 'Traveler Name', value: (row) => capitalizeTheFirstLetterOfEachWord(row['fullName']) },
		{ label: 'Nights', value: 'total_room_nights' },
		{ label: 'Total Cost', value: (row) => Currencies.getPrecisePriceWithCurrency(row['totalSpent'], currency) },
	];
}

const travel_policy_data_fields = [
	{ label: 'Check In', value: 'checkIn' },
	{ label: 'Check Out', value: 'checkOut' },
	{ label: 'Traveler(s)', value: (row) => capitalizeTheFirstLetterOfEachWord(row['travelers']) },
	{ label: 'Hotel Name', value: 'hotelName' },
	{ label: 'Destination', value: 'cityCountry' },
	{ label: 'Deviation', value: 'deviation' },
];

function getYearAndMonth(date) {
	// return moment(date).format('MMMM YYYY');
	return moment(date).format('MMM YYYY');
}

function getMonths(tag) {
	var months = [];
	for (var i = 1; i <= 12; i++) {
		months.push(
			<option key={tag + '-month-' + i} value={i}>
				{monthNames[i - 1]}
			</option>,
		);
	}
	return months;
}

function getYears(tag) {
	var years = [];
	var nextYear = new Date().getFullYear() + 1;
	for (var i = 2014; i <= nextYear; i++) {
		years.push(
			<option key={tag + '-year-' + i} value={i}>
				{i}
			</option>,
		);
	}
	return years;
}

function getReportsPageState() {
	return {
		reports: ReportsStore.getReportsSummary(),
		status: ReportsStore.getStatus(),

		startDate: ReportsStore.getStartDate(),
		// startMonth: ReportsStore.getStartDate().getMonth() + 1,
		// startYear: ReportsStore.getStartDate().getFullYear(),

		endDate: ReportsStore.getEndDate(),
		// endMonth: ReportsStore.getEndDate().getMonth() + 1,
		// endYear: ReportsStore.getEndDate().getFullYear(),

		profile: ProfileStore.getProfile(),
		client_contract_id: ReportsStore.getClientContractId(),
	};
}

// Define main Controller View
var ReportsFilterComponent = createClass({
	displayName: 'ReportsFilterComponent',

	// Get initial state from stores
	getInitialState: function () {
		return getReportsPageState();
	},

	// Add change listeners to stores
	componentDidMount: function () {
		ReportsStore.addChangeListener(this._onChange);
	},

	// Remove change listers from stores
	componentWillUnmount: function () {
		ReportsStore.removeChangeListener(this._onChange);
	},

	handleStartMonthChange: function (e) {
		var newMonth = e.target.value - 1;

		var newStartDate = moment(this.state.startDate).month(newMonth).startOf('month');

		// if (newStartDate.isBefore(endDate)) {
		this.setState({
			startDate: newStartDate.toDate(),
		});
		// }
	},

	handleStartYearChange: function (e) {
		var newYear = e.target.value;

		var newStartDate = moment(this.state.startDate).year(newYear).startOf('month');

		// if (newStartDate.isBefore(endDate)) {
		this.setState({
			startDate: newStartDate.toDate(),
		});
		// }
	},

	handleEndMonthChange: function (e) {
		var newMonth = e.target.value - 1;

		var newEndDate = moment(this.state.endDate).month(newMonth).endOf('month');

		// if (newEndDate.isAfter(startDate)) {
		this.setState({
			endDate: newEndDate.toDate(),
		});
		// }
	},

	handleEndYearChange: function (e) {
		var newYear = e.target.value;

		var newEndDate = moment(this.state.endDate).year(newYear).endOf('month');

		// if (newEndDate.isAfter(startDate)) {
		this.setState({
			endDate: newEndDate.toDate(),
		});
		// }
	},

	handleClientNameChange: function (e) {
		this.setState({
			client_contract_id: e.target.value,
		});
	},

	handleUpdateClick: function () {
		ReportsActions.updateReportsDates(this.state.startDate, this.state.endDate, this.state.client_contract_id);

		var startDate = moment(this.state.startDate).format('MMMM YYYY');
		var endDate = moment(this.state.endDate).format('MMMM YYYY');

		let client_company = this.state.client_contract_id
			? ProfileStore.getContractClientCompany(this.state.client_contract_id)
			: null;
		Analytics.actions.interactions.filteredReports(startDate, endDate, client_company ? client_company.name : null);
	},

	getReportFilename(reportName) {
		const { profile, reports } = this.state;

		const company_name = _.get(profile, 'company.name');

		// return `Arbitrip - ${reports.reportName} - From ${getYearAndMonth(reports.startDate)} To ${getYearAndMonth(endDate)}.csv`;
		return `${company_name} - ${reportName} (${getYearAndMonth(reports.startDate)} - ${getYearAndMonth(reports.endDate)}).csv`;
	},

	saveCsvs: function () {
		const { reports } = this.state;

		const general_info_fields = getGeneralInfoFields(reports.currency);
		JsonToCsvFileSaver.saveJsonAsCsv(reports, general_info_fields, this.getReportFilename('General Info'));

		const top_destinations_fields = getTopDestinationFields(reports.currency);
		JsonToCsvFileSaver.saveJsonAsCsv(
			reports.topCities,
			top_destinations_fields,
			this.getReportFilename('Top Destinations'),
		);

		const top_travelers_fields = getTopTravelersFields(reports.currency);
		JsonToCsvFileSaver.saveJsonAsCsv(
			reports.topTravelers,
			top_travelers_fields,
			this.getReportFilename('Top Travelers'),
		);

		JsonToCsvFileSaver.saveJsonAsCsv(
			reports.topOOPBookings,
			travel_policy_data_fields,
			this.getReportFilename('Out of Policy'),
		);
	},

	isBusy: function () {
		return this.state.status === ReportsConstants.STATUS.BUSY;
	},

	// Render our child components, passing state via props
	render: function () {
		const startDate = moment(this.state.startDate);
		const endDate = moment(this.state.endDate);

		const isStartDateSameOrBeforeEndDate = startDate.isSameOrBefore(endDate);
		const areDatesLessThanYearApart = endDate.diff(startDate, 'days') <= 365;
		const areDatesValid = isStartDateSameOrBeforeEndDate && areDatesLessThanYearApart;

		const { profile, client_contract_id } = this.state;

		return (
			<div className="reports-filter">
				<div className="filter-container date-filter-container start-date-containers">
					<label>From</label>
					<div className="date-container month-container start-month-container">
						<div className="select-container">
							<select
								id="reports-filter-start-month"
								className="form-control"
								onChange={this.handleStartMonthChange}
								value={this.state.startDate.getMonth() + 1}
							>
								{getMonths('start')}
							</select>
						</div>
					</div>

					<div className="date-container year-container start-year-container">
						<div className="select-container">
							<select
								id="reports-filter-start-year"
								className="form-control"
								onChange={this.handleStartYearChange}
								value={this.state.startDate.getFullYear()}
							>
								{getYears('start')}
							</select>
						</div>
					</div>
				</div>

				<div className="filter-container date-filter-container end-date-containers">
					<label>To</label>
					<div className="date-container month-container end-month-container">
						<div className="select-container">
							<select
								id="reports-filter-end-month"
								className="form-control"
								onChange={this.handleEndMonthChange}
								value={this.state.endDate.getMonth() + 1}
							>
								{getMonths('end')}
							</select>
						</div>
					</div>

					<div className="date-container year-container end-year-container">
						<div className="select-container">
							<select
								id="reports-filter-end-year"
								className="form-control"
								onChange={this.handleEndYearChange}
								value={this.state.endDate.getFullYear()}
							>
								{getYears('end')}
							</select>
						</div>
					</div>
				</div>

				{profile.agent ? (
					<div className="filter-container client-name-filter-container">
						<label>Client Name</label>
						<div className="client-name-container">
							<div className="select-container">
								<select
									id="reports-filter-client-name"
									className="form-control"
									onChange={this.handleClientNameChange}
									value={client_contract_id}
								>
									{profile.agency_contracts_dashboard.map((contract) => (
										<option value={contract._id} key={contract._id}>
											{contract.company.name} {contract.agency ? '(Agency)' : null}
										</option>
									))}
								</select>
							</div>
						</div>
					</div>
				) : null}

				<div className="filter-container update-filters-container">
					{areDatesValid && !this.isBusy() ? (
						<button
							type="button"
							name="update-filters"
							className="update-filters-button btn btn-primary"
							onClick={this.handleUpdateClick}
						>
							Update
						</button>
					) : (
						<button
							type="button"
							name="update-filters"
							className="update-filters-button btn btn-primary disabled"
							disabled
						>
							Update
						</button>
					)}
					{areDatesValid ? null : (
						<span className="update-filters-error">
							{
								!isStartDateSameOrBeforeEndDate
									? 'Starting month should be before ending month' //." // "Choose a maximum period of 12 months."
									: 'Choose a maximum period of 12 months' //." // "Showing more than 12 months will require us to wake the bears, I guess we all want to avoid this."
							}
						</span>
					)}
				</div>
				<div className="export-to-csv">
					<button className="btn btn-link" onClick={this.saveCsvs} disabled={this.isBusy()}>
						Export to CSV
					</button>
				</div>
			</div>
		);
	},

	// Method to setState based upon Store changes
	_onChange: function () {
		this.setState(getReportsPageState());
	},
});

export default ReportsFilterComponent;
