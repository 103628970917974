import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import MapBoxOverlay from './MapBoxOverlay.react';
import MapIcon from './MapIcon';
import MapThumb from './MapThumb';

import MapStore from '../../../../../stores/MapStore';

import Analytics from 'arbitrip-common/client/analytics';
import MapActions from '../../../../../actions/MapActions';

function getMapBoxState() {
	return {
		mapData: MapStore.getMapData(),
	};
}

class MapBox extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			...getMapBoxState(),
			fullMapMode: MapStore.getFullMapMode(),
		};

		this.openMap = this.openMap.bind(this);
		this.onCloseMap = this.onCloseMap.bind(this);
		this._onChange = this._onChange.bind(this);
	}

	componentDidMount() {
		MapStore.addChangeListener(this._onChange);

		const _self = this;

		setTimeout(() => {
			const mapData = _.get(_self.state, 'mapData');

			if (mapData && (mapData.lastCenter || mapData.lastZoom || mapData.selectedHotelId)) {
				_self.setState({ fullMapMode: true });
			}
		});
	}

	componentWillUnmount() {
		MapStore.removeChangeListener(this._onChange);
	}

	openMap() {
		if (window.google) {
			const { profile, handleMapBoxState, searchTerms } = this.props;
			this.setState({ fullMapMode: true });

			setTimeout(() => {
				Analytics.actions.interactions.openResultsMap(profile, searchTerms.hotel_id, searchTerms.address);

				if (_.isFunction(handleMapBoxState)) {
					handleMapBoxState(true);
				}
			});
		}
	}

	onCloseMap() {
		const { handleMapBoxState } = this.props;
		if (_.isFunction(handleMapBoxState)) {
			handleMapBoxState(false);
		}

		MapActions.clearMapFilters();

		const _self = this;
		setTimeout(() => {
			const { profile, searchTerms } = this.props;

			_self.setState({ fullMapMode: false });
			Analytics.actions.interactions.closeResultsMap(profile, searchTerms.hotel_id, searchTerms.address);
		});
	}

	renderMapWrapper() {
		if (window.google) {
			return (
				<div id="map-wrapper">
					<button id="map-box-toggle-button" onClick={this.openMap}>
						<MapIcon />
						See on map
					</button>
					<MapThumb />
				</div>
			);
		}

		return (
			<div id="map-wrapper" style={{ cursor: 'auto' }}>
				<MapThumb />
			</div>
		);
	}

	render() {
		const { fullMapMode } = this.state;

		return (
			<React.Fragment>
				{this.renderMapWrapper()}
				{window.google && (
					<MapBoxOverlay
						open={fullMapMode}
						onCloseMap={this.onCloseMap}
						profile={this.props.profile}
						searchTerms={this.props.searchTerms}
						searchResults={this.props.searchResults}
						breakfastOnly={this.props.breakfastOnly}
						filters={this.props.filters}
						regions={this.props.regions}
					/>
				)}
			</React.Fragment>
		);
	}

	_onChange() {
		this.setState(getMapBoxState());
	}
}

MapBox.propTypes = {
	handleMapBoxState: PropTypes.func,
	searchTerms: PropTypes.object.isRequired,
};

export default MapBox;
