import { Icon, IconButton, Tooltip } from '@mui/material';

import React from 'react';
import createClass from 'create-react-class';

import ManagementConstants from '../../../constants/ManagementConstants';
import ManagementActions from '../../../actions/ManagementActions';

import BookedAirfare from './BookedAirfare.react';
import _BookedAccommodation from './BookedAccommodation.react';

import UrlParamsUtils from 'arbitrip-common/general/utils/UrlParams';
import { withRouter } from '../../../utils/withRouter';

const BookedAccommodation = withRouter(_BookedAccommodation);

function getBookedComponentsPageState() {
	return {
		show_buttons: true,
		indeterminate: true,
		check_all: false,
	};
}

const BookedComponents = createClass({
	displayName: 'BookedComponents',

	getInitialState: function () {
		let state = getBookedComponentsPageState();
		state.hovered_id = null;
		return state;
	},

	handleChange: function (date) {
		console.log('changed date:', date);
	},

	handleOnBlur: function (date) {
		console.log('blur event:', date);
	},

	handleAddItineraryClick: function (e) {
		let open_status = this.state.show_buttons;
		this.setState({ show_buttons: !open_status });
	},

	handleAddFlightClick: function () {
		const { navigate } = this.props;
		// ManagementActions.initFlightReservation();
		ManagementActions.clearAirfareWizard();
		navigate('/' + ManagementConstants.SOURCES.ADD_AIRFARE_WIZARD);
	},

	getSearchUrl: function (trip) {
		if (!trip) {
			return '';
		}
		let num_travelers = trip.travlers ? trip.travlers.length : 1;
		let payload = {
			from_arbi_management: true,
			auto_search: true,
			management_trip_id: trip.id,
			management_trip_name: trip.name,
			rooms: num_travelers,
			guest_count: num_travelers,
			travelers: JSON.stringify(trip.travelers),
		};

		let params = UrlParamsUtils.objToURLParams(payload);
		return '/results?' + params;
	},

	handleAddAccommodationClick: function () {
		let { trip } = this.props;

		let search_link = this.getSearchUrl(trip);
		window.open(search_link, '_blank');
	},

	handleAddCarClick: function () {
		ManagementActions.initCarReservation();
	},

	handleCheckAllOnChange: function (e) {
		console.log('handleCheckAllOnChange', e);
	},

	handleOnHover: function (booked_component) {
		this.setState({
			hovered_id: booked_component.id,
		});
		ManagementActions.previewBookedComponent(booked_component);
	},

	render: function () {
		let {
			trip,
			profile,
			canCreate,
			previewed_booked_component,
			remove_trip_accommodation_status,
			remove_trip_airfare_status,
			can_edit,
			tooltipPopperProps,
			iconButtonSx,
		} = this.props;
		let itinerary = trip && trip.booked_itinerary;
		// let {hovered_id} = this.state;
		let hovered_id = previewed_booked_component && previewed_booked_component.id;

		let accommodations = (itinerary && Array.isArray(itinerary.accommodations) && itinerary.accommodations) || [];
		let airfares = (itinerary && Array.isArray(itinerary.airfares) && itinerary.airfares) || [];
		// let flights = airfares.reduce((acc, cur) => acc.concat(cur.flights), []);
		let flights = airfares.reduce((acc, cur) => {
			if (Array.isArray(cur.flights) && cur.flights.length) {
				return acc.concat(cur.flights);
			}

			return acc;
		}, []);
		let items = accommodations
			.map((accommodation) => ({
				type: ManagementConstants.TRIP_BOOKED_COMPONENT_TYPE.ACCOMMODATION,
				component: accommodation,
			}))
			.concat(
				flights.map((flight) => ({
					type: ManagementConstants.TRIP_BOOKED_COMPONENT_TYPE.FLIGHT,
					component: flight,
				})),
			);

		let bookings;

		if (!items.length) {
			bookings = (
				<div className="no-bookings">
					<h3>Go ahead and create your first reservation! :)</h3>
				</div>
			);
		} else {
			bookings = (
				<div className="booked-components">
					{airfares.map((airfare) => (
						<BookedAirfare
							airfare={airfare}
							key={airfare.id}
							onHover={() => this.handleOnHover(airfare)}
							hovered={hovered_id === airfare.id}
							trip={trip}
							previewed_booked_component={previewed_booked_component}
							remove_trip_airfare_status={remove_trip_airfare_status}
							can_edit={this.props.can_edit}
						/>
					))}
					{accommodations.map((accommodation) => (
						<BookedAccommodation
							accommodation={accommodation}
							key={accommodation.id}
							trip={trip}
							previewed_booked_component={previewed_booked_component}
							profile={profile}
							onHover={() => this.handleOnHover(accommodation)}
							hovered={hovered_id === accommodation.id}
							remove_trip_accommodation_status={remove_trip_accommodation_status}
							can_edit={this.props.can_edit}
						/>
					))}
				</div>
			);
		}

		// let add_travel_component_classes = 'add-travel-component';
		let add_buttons_classes = 'buttons more-left';
		if (this.state.show_buttons) {
			add_buttons_classes += ' open';
			// add_travel_component_classes += ' open';
		}
		if (profile.travel_manager) {
			add_buttons_classes += ' travel-manager-mode';
			// add_travel_component_classes += ' travel-manager-mode';
		}
		return (
			<div className="bookings components section">
				<div className="title-line">
					<span className="title">Bookings</span>

					{canCreate ? (
						<div className={add_buttons_classes}>
							{/*<a className="icon-button" onClick={this.handleAddFlightClick}>*/}
							{/*<i className="fa fa-plane fa-fw"/> Add Flight*/}
							{/*</a>*/}
							{can_edit ? (
								<Tooltip title="Add Airfare" PopperProps={tooltipPopperProps}>
									<IconButton
										className="add-flight-button"
										onClick={this.handleAddFlightClick}
										size="large"
										sx={iconButtonSx}
									>
										<Icon className="fa fa-plane" />
									</IconButton>
								</Tooltip>
							) : null}

							{/*<FlatButton*/}
							{/*label="Add Airfare"*/}
							{/*primary={true}*/}
							{/*style={{fontSize: 14}}*/}
							{/*icon={<FlightIcon />}*/}
							{/*onClick={this.handleAddFlightClick}*/}
							{/*/>*/}

							{can_edit ? (
								<Tooltip title="Add Hotel Booking" PopperProps={tooltipPopperProps}>
									<IconButton
										className="add-acc-button"
										onClick={this.handleAddAccommodationClick}
										size="large"
										sx={iconButtonSx}
									>
										<Icon className="fa fa-bed" />
									</IconButton>
								</Tooltip>
							) : null}

							{/*<FlatButton*/}
							{/*label="Add Hotel Booking"*/}
							{/*style={{fontSize: 14}}*/}
							{/*primary={true}*/}
							{/*icon={<AccommodationIcon />}*/}
							{/*/>*/}
							{/*<a className="icon-button" onClick={this.handleAddAccommodationClick}>*/}
							{/*<i className="fa fa-bed fa-fw"/> Add Accommodation*/}
							{/*</a>*/}
						</div>
					) : null}

					{/*<div className="buttons">*/}
					{/*<a className="icon-button" onClick={() => alert('add booked flight')}>*/}
					{/*<i className="fa fa-plane fa-fw"/>*/}
					{/*</a>*/}
					{/*<a className="icon-button" onClick={() => alert('add booked accommodation')}>*/}
					{/*<i className="fa fa-bed fa-fw"/>*/}
					{/*</a>*/}
					{/*</div>*/}
				</div>
				{bookings}
			</div>
		);

		// const trip_request = this.props.trip_request;
		// let components = [];
		//
		// if (trip_request && trip_request.id) {
		//   components = trip_request.booked_flights.concat(trip_request.booked_accommodations).concat(trip_request.booked_cars);
		//   components.sort(function (a, b) {
		//     return a.display_order - b.display_order;
		//   });
		//   components = components.map(renderReservationComponent.bind(this));
		// }
		//
		// let travel_components_classes = "travel-components section";
		// if (components.length >= 3) {
		//   travel_components_classes += " bottomless";
		// }
		//
		// return (
		//   <div className={travel_components_classes}>
		//
		//     {
		//       (this.props.profile.travel_manager && (components.length > 0))
		//         ? <div className="travel-manager-options">
		//         <Dropdown.Button onClick={this.handleForwardButtonOnClick} overlay={forward_menu} type="primary">
		//           Forward
		//         </Dropdown.Button>
		//
		//         <Checkbox
		//           indeterminate={this.state.indeterminate}
		//           onChange={this.handleCheckAllOnChange}
		//           checked={this.state.check_all}
		//         />
		//
		//       </div>
		//         : null
		//     }
		//
		//     <div className="adders">
		//       <ButtonGroup>
		//         <Button onClick={this.handleAddFlightClick} size="large">
		//           <i className="fa fa-plane fa-fw" aria-hidden="true"/>
		//           Add Flight
		//         </Button>
		//         <Button onClick={this.handleAddAccommodationClick} size="large">
		//           <i className="fa fa-bed fa-fw" aria-hidden="true"/>
		//           Add Accommodation
		//         </Button>
		//         <Button onClick={this.handleAddCarClick} size="large">
		//           <i className="fa fa-car fa-fw" aria-hidden="true"/>
		//           Add Car
		//         </Button>
		//       </ButtonGroup>
		//     </div>
		//
		//     <div className="components">
		//       {
		//         (components.length > 0)
		//           ? components
		//           : <div className="empty-itinerary"><span>Start adding your trip itinerary</span></div>
		//       }
		//     </div>
		//   </div>
		// );
	},
});

export default withRouter(BookedComponents);
