import Validator from 'arbitrip-common/client/utils/Validator';

class TravelersHelper {
	updateTravelerFullName(traveler) {
		if (traveler.first_name && traveler.last_name) {
			traveler.full_name = traveler.first_name + ' ' + traveler.last_name;
		} else {
			traveler.full_name = '';
		}

		return traveler;
	}

	getValidFirstName(name) {
		return Validator.validateEnglishOnly(name.trim());
	}

	getValidLastName(name) {
		return Validator.validateEnglishOnly(name.trim());
	}

	getValidEmail(email, isMandatory) {
		if (isMandatory) {
			return email && Validator.validateEmailAddress(email);
		} else {
			return !email || Validator.validateEmailAddress(email);
		}
	}

	getValidPhone(phone) {
		return phone && Validator.validatePhoneNumber(phone);
	}

	getValidationResults(traveler, isMandatory) {
		let validFirstName = this.getValidFirstName(traveler?.first_name ?? '');
		let validLastName = this.getValidLastName(traveler?.last_name ?? '');
		let validEmail = this.getValidEmail(traveler?.email ?? '', isMandatory);
		let validPhone = this.getValidPhone(traveler?.phone?.replaceAll(' ', '').trim());

		return {
			validFirstName,
			validLastName,
			validEmail,
			validPhone,
		};
	}

	isValidTraveler(traveler) {
		const { validFirstName, validLastName, validEmail } = this.getValidationResults(traveler);

		return validFirstName && validLastName && validEmail;
	}
}

export default new TravelersHelper();
