import DealUtils from '../../../../entities/DealUtils';

import React, { Component } from 'react';

const styles = {
	component: {
		position: 'absolute',
		right: '8px',
		top: '-1px',
	},
	bookmark: {
		color: '#D0D1D2',
		fontSize: '24px',
		position: 'relative',
		marginLeft: '250px',
		marginTop: '-1px',
	},
	provider: {
		color: 'black',
		fontSize: '15px',
		position: 'absolute',
		left: '4px',
		top: '2px',
		fontFamily: 'Arial',
	},
	provider_two: {
		color: 'black',
		fontSize: 11.5,
		position: 'absolute',
		left: 0,
		top: 4,
	},
};

class ProviderDealBookmark extends Component {
	render() {
		if (!this.props.deal || !this.props.display_debugging) {
			return null;
		}
		const { supplier, supplier_hub_code } = DealUtils.dealSupplierExtractor(this.props.deal);
		const provider_style = Object.assign({}, supplier.length === 1 ? styles.provider : styles.provider_two);

		if (this.props.deal.bookable) {
			provider_style.color = 'blue';
		}

		if (this.props.deal.superdeal) {
			provider_style.color = 'yellow';
		}

		if (this.props.deal.corporate) {
			provider_style.color = '#81d1a3'; // greenish
		}

		if (this.props.deal.fenced_deal) {
			provider_style.color = '#228B22'; // forest green
		}

		let styles_bookmark = styles.bookmark;
		if (supplier_hub_code) {
			styles_bookmark = Object.assign({}, styles.bookmark, { color: 'rgba(255,200,0,0.8)' });
		}

		return (
			<div style={styles.component}>
				<i className="fa fa-bookmark" aria-hidden="true" style={styles_bookmark}>
					<span style={provider_style}>{supplier}</span>
				</i>
			</div>
		);
	}
}

export default ProviderDealBookmark;
