import AppDispatcher from '../dispatcher/AppDispatcher';
import ResultsConstants from '../constants/ResultsConstants';
import FiltersConstants from '../constants/FiltersConstants';

var ResultsActions = {
	// Receive initial search results data
	getResults: function (data) {
		AppDispatcher.handleAction({
			actionType: ResultsConstants.RECEIVE_SEARCH_RESULTS_DATA,
			data: data, // ???
		});
	},

	clearFiltersAndApply: function () {
		AppDispatcher.handleAction({
			actionType: FiltersConstants.CLEAR_FILTERS_AND_APPLY,
		});
	},

	recalculateRecommendations: function () {
		AppDispatcher.handleAction({
			actionType: ResultsConstants.RECALCULATE_RECOMMENDATIONS,
		});
	},
};

export default ResultsActions;
