import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Analytics from 'arbitrip-common/client/analytics';

const SettlePaymentTitle = ({ title, booking, onClose }) => {
	const handleCloseClick = () => {
		Analytics.actions.interactions.closedPointsModal(booking?.id);
		if (_.isFunction(onClose)) {
			onClose();
		}
	};

	return (
		<div className="settle-payment-title">
			<IconButton aria-label="close" onClick={handleCloseClick} className="close-button" size="large">
				<ArrowBackIcon />
			</IconButton>
			<div className="title-text" dangerouslySetInnerHTML={{ __html: title }} />
		</div>
	);
};

SettlePaymentTitle.propTypes = {
	booking: PropTypes.shape({
		id: PropTypes.string,
		sort_id: PropTypes.string,
	}),
	onClose: PropTypes.func,
};

export default SettlePaymentTitle;
