import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';

import FormControlLabel from '@mui/material/FormControlLabel';

import React from 'react';

import FilterCheckbox from './FilterCheckbox.react';

const styles = {
	label: {
		color: 'rgba(32,36,43,0.7)',
		fontSize: 13,
	},
};

function FilterCheckboxWithLabel(props) {
	const { checked, label, category, onChange, classes } = props;

	return (
		<FormControlLabel
			control={
				<FilterCheckbox checked={checked} onChange={(e, c) => onChange(e, c, category)} disableRipple={true} />
			}
			label={label}
			classes={{
				label: classes.label,
			}}
			{...(props.disabled ? { disabled: true } : {})}
			{...(props.style ? { style: props.style } : {})}
		/>
	);
}

FilterCheckboxWithLabel.propTypes = {
	label: PropTypes.any.isRequired,
	category: PropTypes.any,
	checked: PropTypes.bool.isRequired,
	onChange: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FilterCheckboxWithLabel);
