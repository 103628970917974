/*global Promise*/
import moment from 'moment';
import _ from 'lodash';
import Events from '../../../events/Events';

import React from 'react';

import { Link } from 'react-router-dom';

import ReservationConstants from '../../../constants/ReservationConstants';
import PaymentConstants from '../../../constants/PaymentConstants';
import ReservationStore from '../../../stores/ReservationStore';
import HotelStore from '../../../stores/HotelStore';
import DealStore from '../../../stores/DealStore';
import ResultsStore from '../../../stores/ResultsStore';
import ProfileStore from '../../../stores/ProfileStore';
import SearchStore from '../../../stores/SearchStore';
import PaymentStore from '../../../stores/PaymentStore';

import ReservationActions from '../../../actions/ReservationActions';
import SearchActions from '../../../actions/SearchActions';
import RouterWrapper from '../../../utils/RouterWrapper';

import TravelersDetailsContainer from './components/TravelersDetailsContainer.react';
import CombtasContainer from './components/CombtasContainer.react';
import TravelBoosterContainer from './components/TravelBoosterContainer.react';

import CustomFieldsContainer from './components/CustomFieldsContainer.react';
import SpecialRequests from './components/SpecialRequests';
import TermsOfSupplier from './components/TermsOfSupplier';

import MaterialDesignDialog from '../../general/MaterialDesignDialog.react';

import PaymentComponent from './PaymentComponent.react';
import ValidationDialog from './ValidationDialog.react';

import TitleManager from '../../../utils/TitleManager';

import Validator from 'arbitrip-common/client/utils/Validator';
import PriceBreakdown from 'arbitrip-common/client/utils/PriceBreakdown';

import Analytics from 'arbitrip-common/client/analytics';
import Points from 'arbitrip-common/general/utils/Points';

import EventsConstants from '../../../events/constants/ReservationPaymentPage';

import { Select, MenuItem, FormControl, Checkbox, Button, Tooltip, IconButton } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { STATUS } from 'arbitrip-common/client/constants/ReservationConstants';
import ClientPriceService from 'arbitrip-common/client/utils/ClientPriceService';
import { withRouter } from '../../../utils/withRouter';
import DocumentTitle from '../../general/DocumentTitle.react';
import Currencies from '../../../utils/Currencies';
import ProfileConstants from '../../../constants/ProfileConstants';
import PointsStore from '../../../stores/PointsStore';
import PointsActions from '../../../actions/PointsActions';
import { ReactComponent as PointsIcon } from '../../../img/search_page/loyalty/present-plain.svg';
import HotelDetailsContainer from './components/HotelDetailsContainer.react';
import DealDetailsContainer from './components/DealDetailsContainer.react';
import CancellationTermsContainer from './components/CancellationTermsContainer.react';
import ContentLoader from 'react-content-loader';
import PointsConstants from '../../../constants/PointsConstants';
import * as Sentry from '@sentry/react';
import AutoFiller from './helpers/AutoFiller';
import { Config } from 'arbitrip-common/client/utils';
import ConfirmationPage from '../confirmation/ConfirmationPage';
import ExpediaDealDetailsContainer from './components/ExpediaDealDetailsContainer.react';
import { ReactComponent as AttentionIcon } from '../../../img/icons/attention.svg';
import HotelActions from '../../../actions/HotelActions';
import channel from '@/utils/BroadcastChannel';
import { getTasIdFromSessionStorage, removeTasIdFromSessionStorage } from '@/utils/TasId';
import { isPendingStatus, RESERVATION_DIALOG_VARIANT } from './helpers';
import ReservationDialog from './components/ReservationDialog.react';
import PaymentActions from '../../../actions/PaymentActions';

const { SCROLL_TO_TRAVELER, SAVE_TRAVELER_CARD } = EventsConstants;

function isNonEmptyArray(arr) {
	return Array.isArray(arr) && !!arr.length;
}

function getReservationPageState() {
	const reservation_data = ReservationStore.getReservationData();

	if (SearchStore.isPrivateTravel()) {
		// Ignores custom fields when it's private travel
		reservation_data.company_custom_fields = null;
	}

	const points = PointsStore.getPoints();

	return {
		reservationData: reservation_data,
		room_mapping: ResultsStore.getRoomMappingData(),
		paymentData: PaymentStore.getPaymentData(),
		hotel: HotelStore.getHotelData(),
		deal: DealStore.getDealData(),
		travel_policy: ResultsStore.getTravelPolicy(),
		profile: ProfileStore.getProfile(),
		points_status: PointsStore.getStatus(),
		points_balance: _.get(points, 'balance', 0),
		points_upcoming_value: _.get(points, 'upcoming_points_value', 0),
		points_upcoming_timestamp: _.get(points, 'upcoming_points_timestamp', null),
		search_token: SearchStore.getToken(),
		hotel_search_token: SearchStore.getHotelToken(),
		search_extra: SearchStore.getExtraData(),
		search_terms: SearchStore.getSearchTerms(),
		contract_client_name: SearchStore.getContractClientName(),
		contract_client_company_id: SearchStore.getContractClientCompanyId(),
		showConfirmationPage: false,
		modalIsOpen: false,
		isShowReservationError: false,
		creditLineModalIsOpen: false,
		validationModalIsOpen: false,
		failedReservationInitializationModalIsOpen: false,
		textBoxError: false,
		invoice_option: ReservationStore.getInvoiceId(),
		out_of_policy_state: _.get(reservation_data, 'deal.out_of_policy_state', null),
		out_of_policy_request_status: ReservationStore.getOutOfPolicyRequestStatus(),
		create_traveler_statuses: ReservationStore.getCreateTravelerStatuses(),
		private_travel: SearchStore.isPrivateTravel(),
		derived_from: SearchStore.getDerivedFrom(),
		post_pay: DealStore.isPostPay(),
		selected_company_custom_field: -1,
		selected_company_custom_field_secondary: -1,
		company_custom_text_fields: {},

		company_custom_input_selectable: null,
		company_custom_input_text: null,
		company_custom_input_number: null,
		company_custom_input_datetime: null,
		selected_custom_fields_path: '',
		selected_custom_fields_paths: [],
		combtas_id: getTasIdFromSessionStorage() || SearchStore.getCombtasId() || '',
		tas_id_ref: getTasIdFromSessionStorage(),
		travel_booster_docket_number: SearchStore.getTravelBoosterDocketNumber() || '',

		points,
	};
}

function getContentLoader(width, height, radius = 0) {
	return (
		<ContentLoader
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			className="maximum-points-earned-content-loader"
		>
			<rect x="0" y="0" rx={radius} yx={radius} width={width} height={height} />
		</ContentLoader>
	);
}

class ReservationPaymentPage extends React.Component {
	constructor(props) {
		super(props);

		let initState = getReservationPageState();

		initState.quotedReservationModalIsOpen = false;
		initState.send_email = initState.profile.send_traveler_emails_under_guest_email;
		initState.show_send_voucher_separately_from_confirmation_mail =
			initState.profile.show_send_voucher_separately_from_confirmation_mail;

		if (initState.profile.agent) {
			initState.send_voucher_separately_from_confirmation_mail =
				localStorage.getItem('sendVoucherSeparatelyFromConfirmationMail') === 'true';
		}

		initState.combtas_integration_enabled = initState.profile.company.settings.combtas_integration_enabled;
		initState.combtas_integration_mandatory = initState.profile.company.settings.combtas_integration_mandatory;

		initState.travel_booster_integration_enabled = initState.profile.settings.travel_booster_integration_enabled;
		initState.travel_booster_integration_mandatory =
			initState.profile.company.settings.travel_booster_integration_mandatory;

		initState.isSpecialRequestsChecked = false;

		// initState.show_package_disclaimer = initState.profile.package_disclaimer_enabled && initState.deal.package;
		// initState.package_disclaimer_checked = true;
		initState.loading = true;

		this.state = initState;

		////////////////////////////////////////////////////////////////////////////////
		// bindings //
		//////////////

		this._onChange = this._onChange.bind(this);
		this.addQuote = this.addQuote.bind(this);
		this.getAddQuoteDisabledStatus = this.getAddQuoteDisabledStatus.bind(this);
		this.getPaymentOptionsDisabledStatus = this.getPaymentOptionsDisabledStatus.bind(this);
		this.getTravelersContactPromise = this.getTravelersContactPromise.bind(this);
		this.requestThisRoom = this.requestThisRoom.bind(this);
		this.getAdvanceReservationFields = this.getAdvanceReservationFields.bind(this);
		this.updateOutOfPolicyRequestReason = this.updateOutOfPolicyRequestReason.bind(this);
		this.onArbiManagementTripAssociation = this.onArbiManagementTripAssociation.bind(this);
		this.showPaymentComponentOnQuoteFlow = this.showPaymentComponentOnQuoteFlow.bind(this);
		this.getOutOfPolicyRequestButton = this.getOutOfPolicyRequestButton.bind(this);
		this.getMaximumPointsEarnedRender = this.getMaximumPointsEarnedRender.bind(this);
		this.isLoyaltyEnabled = this.isLoyaltyEnabled.bind(this);
		this.clearSpecialRequests = this.clearSpecialRequests.bind(this);

		// booleans

		this.didGetPaymentData = this.didGetPaymentData.bind(this);
		this.didMakeReservation = this.didMakeReservation.bind(this);

		this.isReservationPostInitialStatus = this.isReservationPostInitialStatus.bind(this);
		this.isPayAtHotel = this.isPayAtHotel.bind(this);
		this.isMakingOutOfPolicyRequest = this.isMakingOutOfPolicyRequest.bind(this);
		this.isQuoting = this.isQuoting.bind(this);
		this.isMakingReservation = this.isMakingReservation.bind(this);
		this.isInitializingPayment = this.isInitializingPayment.bind(this);
		this.isBusy = this.isBusy.bind(this);
		this.isCombtasEnabled = this.isCombtasEnabled.bind(this);
		this.isTravelBoosterEnabled = this.isTravelBoosterEnabled.bind(this);
		this.isAgencyClientPriceEnabled = this.isAgencyClientPriceEnabled.bind(this);

		this.shouldLockForm = this.shouldLockForm.bind(this);
		this.shouldDisableAddAsQuoteAndProceedButtons = this.shouldDisableAddAsQuoteAndProceedButtons.bind(this);
		this.shouldShowQuote = this.shouldShowQuote.bind(this);

		// modals

		this.openModal = this.openModal.bind(this);
		this.openValidationModal = this.openValidationModal.bind(this);
		this.openQuotedReservationModal = this.openQuotedReservationModal.bind(this);
		this.openFailedReservationInitializationModal = this.openFailedReservationInitializationModal.bind(this);

		this.closeModal = this.closeModal.bind(this);
		this.closeValidationModal = this.closeValidationModal.bind(this);
		this.closeQuotedReservationModal = this.closeQuotedReservationModal.bind(this);
		this.closeFailedReservationInitializationModal = this.closeFailedReservationInitializationModal.bind(this);

		this.afterOpenModal = this.afterOpenModal.bind(this);

		// validations

		this.areReservationAndFormValid = this.areReservationAndFormValid.bind(this);

		this.validateForm = this.validateForm.bind(this);
		this.validateTravelers = this.validateTravelers.bind(this);
		this.validateTravelersContacts = this.validateTravelersContacts.bind(this);
		this.forceTravelBoosterDocketNumberValidation = this.forceTravelBoosterDocketNumberValidation.bind(this);

		// change handlers

		this.onTripIdChange = this.onTripIdChange.bind(this);
		this.onCombtasIdChange = this.onCombtasIdChange.bind(this);
		this.onTravelBoosterDocketNumberChange = this.onTravelBoosterDocketNumberChange.bind(this);
		this.onShouldCreateNewDocketChanged = this.onShouldCreateNewDocketChanged.bind(this);
		this.onAgencyClientPriceChange = this.onAgencyClientPriceChange.bind(this);
		this.onPriceComparableChange = this.onPriceComparableChange.bind(this);

		this.handleSendEmailCheckboxChange = this.handleSendEmailCheckboxChange.bind(this);
		this.handleSendVoucherSeparatelyCheckboxChange = this.handleSendVoucherSeparatelyCheckboxChange.bind(this);
		this.handleCompanyCustomInputNumberChange = this.handleCompanyCustomInputNumberChange.bind(this);
		this.handleCompanyCustomInputTextChange = this.handleCompanyCustomInputTextChange.bind(this);
		this.handleCustomTextFieldChange = this.handleCustomTextFieldChange.bind(this);
		this.handleInvoiceChange = this.handleInvoiceChange.bind(this);
		// this.handlePackageDisclaimerCheckboxChange = this.handlePackageDisclaimerCheckboxChange.bind(this);
		this.handleSelectedCustomFieldChange = this.handleSelectedCustomFieldChange.bind(this);
		this.handleSelectedCustomFieldPathChange = this.handleSelectedCustomFieldPathChange.bind(this);
		this.handleSelectedSecondaryCustomFieldChange = this.handleSelectedSecondaryCustomFieldChange.bind(this);
		this.handleSpecialRequestCheckboxChange = this.handleSpecialRequestCheckboxChange.bind(this);
		// renders

		this.getClientTripIdRender = this.getClientTripIdRender.bind(this);
		this.getCombtasRender = this.getCombtasRender.bind(this);
		// this.getLoyaltyBoxRender = this.getLoyaltyBoxRender.bind(this);
		this.getTravelBoosterRender = this.getTravelBoosterRender.bind(this);
		// this.getAgencyClientPriceRender = this.getAgencyClientPriceRender.bind(this);
		this.getGoToPaymentContainerRender = this.getGoToPaymentContainerRender.bind(this);
		this.getCovidDisclaimerRender = this.getCovidDisclaimerRender.bind(this);
		this.getPaymentComponentRender = this.getPaymentComponentRender.bind(this);
		this.getReservationErrorRender = this.getReservationErrorRender.bind(this);
		this.getPriceChangedModal = this.getPriceChangedModal.bind(this);
		this.getSpinnerRender = this.getSpinnerRender.bind(this);
		this.getMakeReservationRender = this.getMakeReservationRender.bind(this);
		this.getManagementTripIdRender = this.getManagementTripIdRender.bind(this);
		this.getIssueInvoiceRender = this.getIssueInvoiceRender.bind(this);
		this.getOutOfPolicyRequestRender = this.getOutOfPolicyRequestRender.bind(this);
		// this.getPackageDislaimerRender = this.getPackageDislaimerRender.bind(this);

		// points
		this.handleApproveRedeemPoints = this.handleApproveRedeemPoints.bind(this);
		this.handleCloseRedeemPoints = this.handleCloseRedeemPoints.bind(this);
		this.handleClickRedeemPoints = this.handleClickRedeemPoints.bind(this);

		this.reservationStateDialogs = this.reservationStateDialogs.bind(this);

		////////////////////////////////////////////////////////////////////////////////

		this.displayName = 'ReservationPaymetPage';
	}

	componentDidMount() {
		window.scrollTo(0, 0);

		const { navigate } = this.props;
		const {
			deal,
			room_mapping,
			reservationData,
			hotel,
			search_token,
			search_terms,
			travel_policy,
			search_extra,
			profile,
			hotel_search_token,
		} = this.state;

		if (!deal || !deal.chooseprod) {
			return RouterWrapper.goToHotelPage(navigate, hotel, search_token, search_terms);
		} else if (!reservationData || Object.keys(deal).length === 0 || Object.keys(hotel).length === 0) {
			return RouterWrapper.goToSearchPage(navigate);
		}

		ReservationStore.addChangeListener(this._onChange);
		PaymentStore.addChangeListener(this._onChange);
		ProfileStore.addChangeListener(this._onChange);
		PointsStore.addChangeListener(this._onChange);

		ReservationActions.clearReservation();

		let optimization_token;
		if (room_mapping) {
			let optimizedDeal = room_mapping[deal.id];
			if (optimizedDeal) {
				optimization_token = optimizedDeal.token;
			}
		}

		let room_mapping_data;
		let mapping_room_name;
		if (deal.roomMappingData) {
			room_mapping_data = _.omit(deal.roomMappingData, 'debug.original_deal');
			mapping_room_name = _.get(room_mapping_data, 'debug.room_name');
		}

		this.setState({ loading: true });
		ReservationActions.openNewReservation(
			deal,
			hotel,
			search_token,
			travel_policy,
			search_extra.group_id,
			profile,
			optimization_token,
			hotel_search_token,
			mapping_room_name,
			room_mapping_data,
		);

		PointsActions.getPointsIfApplicable(profile);

		Analytics.actions.views.reservationPage(profile, hotel, search_terms, deal);

		if (Config.force_auto_fill || (Config.dev_mode && Config.auto_fill)) {
			setTimeout(() => {
				AutoFiller.autoFill({ profile, search_terms });
			}, 1);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const {
			// reservationData,
			// selected_company_custom_field,
			// selected_company_custom_field_secondary,
			// company_custom_text_fields,
			out_of_policy_request_status,
		} = this.state;

		// const client_custom_fields = getClientCustomFields(
		//     reservationData.company_custom_fields,
		//     selected_company_custom_field,
		//     selected_company_custom_field_secondary,
		//     company_custom_text_fields
		// );

		// console.log("client_custom_fields:", client_custom_fields);

		if (
			prevState.out_of_policy_request_status === ReservationConstants.STATUS.BUSY &&
			(out_of_policy_request_status === ReservationConstants.STATUS.SUCCESS ||
				out_of_policy_request_status === ReservationConstants.STATUS.FAILED)
		) {
			this.openModal();
		}

		const status = _.get(this.state, 'reservationData.status');
		if (this.state.loading && status === ReservationConstants.STATUS.INITIALIZED) {
			this.setState({ loading: false });
		}
	}

	componentWillUnmount() {
		setTimeout(() => {
			ReservationActions.clearReservation();
		}, 1);

		PaymentStore.removeChangeListener(this._onChange);
		ReservationStore.removeChangeListener(this._onChange);
		ProfileStore.removeChangeListener(this._onChange);
		PointsStore.removeAllListeners(this._onChange);

		// this.setState({
		//     modalIsOpen: false,
		//     validationModalIsOpen: false,
		//     failedReservationInitializationModalIsOpen: false
		// });
	}

	closeValidationModal() {
		this.setState({ validationModalIsOpen: false });
	}

	requestThisRoom() {
		const {
			profile,
			reservationData,
			selected_company_custom_field,
			selected_company_custom_field_secondary,
			company_custom_text_fields,
			trip_id,
			search_token,
			send_email,
			combtas_id,
			travel_booster_docket_number,
			travel_booster_should_create_a_new_docket,
			price_comparable,
			deal,
			search_terms,
			hotel_search_token,
		} = this.state;

		if (!reservationData.out_of_policy_booking_reason?.trim()) {
			this.setState({ textBoxError: true });
			return;
		}

		this.setState({ textBoxError: false });

		if (this.validateForm()) {
			const client_custom_fields = getClientCustomFields(
				reservationData.company_custom_fields,
				selected_company_custom_field,
				selected_company_custom_field_secondary,
				company_custom_text_fields,
			);

			ReservationActions.requestOutOfPolicyRoom({
				reservation: reservationData,
				trip_id,
				search_token,
				send_email_confirmation: send_email,
				combtas_id,
				travel_booster_docket_number,
				travel_booster_should_create_a_new_docket,
				price_comparable,
				currency: deal.currency,
				client_custom_fields,
				search_terms,
				hotel_search_token,
			});

			try {
				Analytics.actions.interactions.requestOutOfPolicyRoom(
					profile.company,
					profile,
					reservationData.out_of_policy_booking_reason,
				);
			} catch (e) {
				console.error(e);
				Sentry.captureException(e);
			}
		}
	}

	openValidationModal() {
		this.setState({ validationModalIsOpen: true });
	}

	validateTravelers() {
		const travelers = _.get(this.state, 'reservationData.travelers', []);
		return travelers.every((traveler) => traveler.isValid());
	}

	validateForm() {
		const {
			post_pay,
			private_travel,
			profile,
			invoice_option,
			out_of_policy_state,
			reservationData,
			combtas_id,
			combtas_integration_mandatory,
			travel_booster_docket_number,
			travel_booster_integration_mandatory,
			travel_booster_should_create_a_new_docket,
			selected_company_custom_field: selected_company_custom_field_index,
			selected_company_custom_field_secondary,
			company_custom_text_fields,
		} = this.state;

		const logInvalidation = (msg) => console.warn('validateForm :::', msg);

		if (
			!post_pay &&
			!private_travel &&
			isNonEmptyArray(profile.company.invoice_ids) &&
			profile.company.invoice_ids[0] &&
			profile.company.invoice_ids[1] &&
			!invoice_option
		) {
			logInvalidation('no invoice option');
			return false;
		}

		if (this.isCombtasEnabled()) {
			// combtas is mandatory but no value was filled
			if (combtas_integration_mandatory && !combtas_id) {
				logInvalidation('no combtas id');
				return false;
			}

			// combtas was filled but value is invalid
			if (combtas_id && !Validator.validateCombtasId(combtas_id)) {
				logInvalidation('invalid combtas id');
				return false;
			}
		}

		// if (profile.loyalty.enabled) {
		//     if (this.state.points_status !== ProfileConstants.STATUS.SUCCESS) {
		//         return false;
		//     }
		// }

		if (this.isTravelBoosterEnabled()) {
			if (
				travel_booster_integration_mandatory &&
				!travel_booster_should_create_a_new_docket &&
				!travel_booster_docket_number
			) {
				logInvalidation('missing travel booster docket number (or create a new docket option)');
				return false;
			}

			if (
				travel_booster_docket_number &&
				!Validator.validateTravelBoosterDocketNumber(travel_booster_docket_number, profile)
			) {
				logInvalidation('invalid travel booster docket number');
				return false;
			}
		}

		const company_fields = reservationData.company_custom_fields;

		// validate custom combobox fields
		if (isNonEmptyArray(company_fields)) {
			const first_combobox_custom_field = company_fields.find((custom_field) =>
				isNonEmptyArray(_.get(custom_field, 'list')),
			);
			if (first_combobox_custom_field) {
				if (Number(selected_company_custom_field_index) === -1) {
					// index might be string - that's why '==' and not '==='
					logInvalidation('no custom field');
					return false;
				}

				if (
					first_combobox_custom_field.list[selected_company_custom_field_index] &&
					first_combobox_custom_field.list[selected_company_custom_field_index].list &&
					Number(selected_company_custom_field_secondary) === -1
				) {
					// index might be string - that's why '==' and not '==='
					logInvalidation('no secondary custom field');
					return false;
				}
			}
		}

		// validate custom text fields
		if (_.isArray(company_fields) && !_.isEmpty(company_fields)) {
			// iterate through custom fields
			const isInvalid = _.some(company_fields, (field) => {
				if (field.field_required && field.field_type === 'text') {
					// retrieve the corresponding field from the form
					const textFieldValue = _.get(company_custom_text_fields, [field._id, 'value'], '');

					if (_.isEmpty(_.trim(textFieldValue))) {
						logInvalidation('required custom text field missing or empty: ' + field.label);
						return true;
					}
				}
			});

			if (isInvalid) {
				return false;
			}
		}

		return true;
	}

	getAdvanceReservationFields() {
		const {
			reservationData,
			selected_company_custom_field,
			selected_company_custom_field_secondary,
			company_custom_text_fields,
			trip_id,
			search_token,
			send_voucher_separately_from_confirmation_mail,
			send_email,
			combtas_id,
			travel_booster_docket_number,
			travel_booster_should_create_a_new_docket,
			price_comparable,
			deal,
			search_terms,
			hotel_search_token,
			points = {},
		} = this.state;

		const client_custom_fields = getClientCustomFields(
			reservationData.company_custom_fields,
			selected_company_custom_field,
			selected_company_custom_field_secondary,
			company_custom_text_fields,
		);

		return {
			reservationData,
			trip_id,
			search_token,
			send_voucher_separately_from_confirmation_mail,
			send_email,
			combtas_id,
			travel_booster_docket_number,
			travel_booster_should_create_a_new_docket,
			price_comparable,
			currency: deal.currency,
			search_terms,
			client_custom_fields,
			hotel_search_token,

			...(points.show && { show_points: points.show, user_chosen_points: points.user_chosen_points }),
		};
	}

	validateTravelersContacts() {
		Events.emit(SAVE_TRAVELER_CARD);

		const _self = this;
		return new Promise((resolve, reject) => _self.getTravelersContactPromise(resolve, reject));
	}

	forceTravelBoosterDocketNumberValidation() {
		if (this.state.travel_booster_integration_mandatory) {
			this.setState({
				force_travel_booster_docket_number_validation: true,
			});
		}
	}

	getTravelersContactPromise(resolve, reject) {
		const { reservationData, create_traveler_statuses } = this.state;

		const travelersBusy = create_traveler_statuses.includes(ReservationConstants.STATUS.BUSY);

		if (travelersBusy) {
			const _self = this;
			// user has tried to create a traveler
			// call again to this function every 200ms till there is a response
			setTimeout(() => {
				return _self.getTravelersContactPromise(resolve, reject);
			}, 200);
		} else {
			const travelersError = !_.isEmpty(reservationData.travelersErrors);

			if (travelersError) {
				// error in travelers
				Events.emit(SCROLL_TO_TRAVELER, reservationData.travelersErrors);
				return reject();
			} else {
				// no errors in travelers, continue to form validation
				return resolve();
			}
		}
	}

	addQuote() {
		this.validateTravelersContacts()
			.then(() => {
				if (!this.validateForm()) {
					this.openValidationModal();
				} else {
					const { reservationData, search_terms } = this.state;

					ReservationActions.quoteReservation(reservationData, search_terms);

					const trip_id = reservationData.management_trip_id;
					const trip_name = reservationData.management_trip_name;
					const traveler = reservationData.travelers[0];
					const traveler_id = traveler && traveler.id;
					const traveler_name = traveler && traveler.first_name + ' ' + traveler.last_name;
					const traveler_email = traveler && traveler.email;

					Analytics.actions.interactions.addedAsQuote(
						trip_id,
						trip_name,
						traveler_id,
						traveler_name,
						traveler_email,
					);
				}
			})
			.catch((e) => {
				console.log(e);
			});
	}

	openModal() {
		const { profile, reservationData } = this.state;
		const isShowModal = !profile?.company?.partners_ui?.show_reservation_errors || !reservationData.bookingError;
		this.setState({
			modalIsOpen: isShowModal,
			isShowReservationError: !isShowModal,
		});
	}

	afterOpenModal() {
		//// references are now sync'd and can be accessed.
		//this.refs.subtitle.style.color = '#f00';
	}

	closeModal() {
		const { navigate } = this.props;
		const { reservationData, out_of_policy_request_status, hotel, search_terms, deal, search_token } = this.state;

		/// if there was any error when trying to book, app will go back to hotel page and show other deals.
		/// if reservation confirmed, go to main page.
		if (reservationData.status === ReservationConstants.RESERVATION_OPERATION_STATE.QUOTED) {
			SearchActions.clearSearchTerms();
			RouterWrapper.goToTripPage(navigate, reservationData.management_trip_id);
		} else if (out_of_policy_request_status === ReservationConstants.STATUS.SUCCESS) {
			SearchActions.clearSearchTerms();
			RouterWrapper.goToSearchPage(navigate);
		} else if (
			out_of_policy_request_status === ReservationConstants.STATUS.FAILED ||
			(reservationData.status !== ReservationConstants.STATUS.BOOKED && !isPendingStatus(reservationData.status))
		) {
			ReservationActions.resetOutOfPolicyRequestStatus();
			HotelActions.banDeal(hotel, deal);
			RouterWrapper.goToHotelPage(navigate, hotel, search_token, search_terms);
		} else if (reservationData.include_management_trip_id) {
			SearchActions.clearSearchTerms();
			RouterWrapper.goToTripPage(navigate, reservationData.management_trip_id);
		} else {
			this.handleCombtasSession();
			SearchActions.clearSearchTerms();
			RouterWrapper.goToSearchPage(navigate);
		}
		// TODO: clear stores
		ReservationActions.clearReservation();
		PaymentActions.clearPaymentData();
	}

	openQuotedReservationModal() {
		this.setState({ quotedReservationModalIsOpen: true });
	}

	closeQuotedReservationModal() {
		this.setState({ quotedReservationModalIsOpen: false });
	}

	openFailedReservationInitializationModal() {
		this.setState({ failedReservationInitializationModalIsOpen: true });
	}

	closeFailedReservationInitializationModal() {
		ReservationActions.clearReservation();

		const { navigate } = this.props;
		const { hotel, search_token, search_terms } = this.state;

		RouterWrapper.goToHotelPage(navigate, hotel, search_token, search_terms);
	}

	updateOutOfPolicyRequestReason(e) {
		ReservationActions.updateOutOfPolicyRequestReason(e.target.value);
	}

	onArbiManagementTripAssociation(event, isInputChecked) {
		event.persist();
		ReservationActions.updateIncludeManagementTripId(isInputChecked);
	}

	onTripIdChange(e) {
		// don't mistake with changing of management trip id ...
		this.setState({ trip_id: e.target.value });
	}

	onCombtasIdChange(e) {
		this.setState({
			combtas_id: e.target.value
				.replace(/\s/g, '') // removes spaces
				.toUpperCase(),
		});
	}

	onTravelBoosterDocketNumberChange(e) {
		this.setState({
			travel_booster_docket_number: e.target.value.replace(/\s/g, ''), // removes spaces
		});
	}

	onShouldCreateNewDocketChanged(event) {
		this.setState({
			travel_booster_should_create_a_new_docket: event.target.checked,
			...(event.target.checked && { travel_booster_docket_number: '' }),
		});
	}

	onAgencyClientPriceChange(e) {
		ReservationActions.updateAgencyClientPrice(e.target.value);
	}

	onPriceComparableChange(e) {
		this.setState({ price_comparable: e.target.value });
	}

	handleInvoiceChange(e) {
		const invoice_id = e.target.value;
		const invoice_option = this.state.profile.company.invoice_ids.find((option) => option.id === invoice_id);
		ReservationActions.updateInvoiceOption(invoice_option);
		this.setState({ isInvoiceOptionChanged: true });
	}

	handleSendVoucherSeparatelyCheckboxChange(event) {
		event.persist();
		this.setState({
			send_voucher_separately_from_confirmation_mail: event.target.checked,
			// TODO! Setting the separate voucher should disable the send to traveller and uncheck it.
			// for some odd reason it does not uncheck it now.
			send_email: false,
		});
	}

	handleSendEmailCheckboxChange(event) {
		event.persist();
		this.setState({ send_email: event.target.checked });
	}

	handleSpecialRequestCheckboxChange(event) {
		this.setState({ isSpecialRequestsChecked: event.target.checked });
	}

	clearSpecialRequests() {
		const { isSpecialRequestsChecked } = this.state;

		if (!isSpecialRequestsChecked) {
			ReservationActions.updateSpecialRequests('');
			ReservationActions.updateSpecialRequestsFromSuggestions({});
		}
	}

	handleSelectedCustomFieldChange(event) {
		this.setState({
			selected_company_custom_field: event.target.value,
			selected_company_custom_field_secondary: -1,
		});
	}

	handleSelectedSecondaryCustomFieldChange(event) {
		this.setState({ selected_company_custom_field_secondary: event.target.value });
	}

	handleCustomTextFieldChange(event, field) {
		event.persist();
		const custom_text_fields = this.state.company_custom_text_fields;
		custom_text_fields[field._id] = {
			label: field.label,
			value: event.target.value,
			show_on_invoice: field.show_on_invoice,
		};
		this.setState({ company_custom_text_fields: custom_text_fields });
	}

	handleCompanyCustomInputTextChange(event) {
		event.persist();
		this.setState({ company_custom_input_text: event.target.value });
	}

	handleCompanyCustomInputNumberChange(event) {
		event.persist();
		this.setState({ company_custom_input_number: event.target.value });
	}

	handleSelectedCustomFieldPathChange(event, path) {
		event.persist();
		this.setState({ selected_custom_fields_path: path + '_' + event.target.value });
	}

	didGetPaymentData() {
		const { paymentData } = this.state;

		return !!(paymentData && paymentData.creditCardUrl);
	}

	isReservationPostInitialStatus() {
		const { status } = this.state.reservationData;

		return (
			status === ReservationConstants.STATUS.BUSY ||
			status === ReservationConstants.STATUS.SUCCESS ||
			status === ReservationConstants.STATUS.FAILED
		);
	}

	shouldLockForm() {
		return this.isBusy() || this.didGetPaymentData() || this.isReservationPostInitialStatus();
		// TODO: think if we are missing more cases
	}

	shouldDisableAddAsQuoteAndProceedButtons() {
		// TODO: implement
		return false;
	}

	areReservationAndFormValid() {
		return this.state.reservationData.id && this.validateForm() && this.validateTravelers();
	}

	getClientTripIdRender() {
		const { profile, trip_id } = this.state;

		const client_trip_id = _.get(profile, 'company.settings.client_trip_id', false);
		const client_trip_id_label = _.get(profile, 'company.settings.client_trip_id_label', 'Trip ID');

		return client_trip_id ? (
			<div className="trip-id-container">
				<label htmlFor="client_trip_id">{client_trip_id_label}</label>
				<input
					type="text"
					className="form-control"
					id="client_trip_id"
					value={trip_id}
					disabled={this.shouldLockForm()}
					onChange={this.didGetPaymentData() ? null : this.onTripIdChange}
					placeholder={'Your ' + client_trip_id_label + ' (Optional)'}
					maxLength="36"
				/>
			</div>
		) : null;
	}

	isCombtasEnabled() {
		const { deal } = this.state;
		return this.state.combtas_integration_enabled && !_.get(deal, 'private_travel', false);
	}

	isTravelBoosterEnabled() {
		return this.state.travel_booster_integration_enabled;
	}

	isAgencyClientPriceEnabled() {
		const { profile, contract_client_company_id, deal } = this.state;

		return ClientPriceService.isEnabled(profile, contract_client_company_id, deal);
	}

	getCombtasRender() {
		const { reservationData, combtas_id, tas_id_ref } = this.state;

		return this.isCombtasEnabled() ? (
			<CombtasContainer
				reservationData={reservationData}
				combtasId={combtas_id}
				shouldLockForm={this.shouldLockForm() || tas_id_ref}
				onCombtasIdChange={this.onCombtasIdChange}
			/>
		) : null;
	}

	handleClickRedeemPoints() {
		this.setState({
			redeem_points_modal_open: true,
		});
	}

	handleCloseRedeemPoints() {
		this.setState({
			redeem_points_modal_open: false,
		});
	}

	handleApproveRedeemPoints(_points) {
		PointsActions.saveChosenUserPoints(_points);

		this.setState({
			redeem_points_modal_open: false,
		});
	}

	// getLoyaltyBoxRender() {
	//     const {
	//         private_travel,
	//         profile,
	//         points_status,
	//         points_balance,
	//         points_upcoming_value,
	//         points_upcoming_timestamp,
	//         reservationData,
	//         points
	//     } = this.state;
	//     return profile.loyalty.enabled
	//         ? <React.Fragment>
	//             <Button
	//                 aria-label="redeem points"
	//                 variant="contained"
	//                 color="primary"
	//                 className="use-points"
	//                 onClick={this.handleClickRedeemPoints}
	//                 disabled={this.props.initializing || this.props.shouldLock}
	//             >
	//                 Redeem Points
	//             </Button>

	//             <RedeemPoints
	//                 points={points}
	//                 reservation={reservationData}
	//                 initializing={reservationData.status === STATUS.INITIALIZING}
	//                 disabled={this.shouldLockForm()}
	//                 dialogOpen={this.state.redeem_points_modal_open}
	//                 dialogTitle="Redeem Points"
	//                 onClose={this.handleCloseRedeemPoints}
	//                 onApprove={this.handleApproveRedeemPoints}
	//                 profile={profile}
	//             />
	//             <LoyaltyBox
	//                 status={points_status}
	//                 balance={points_balance}
	//                 upcomingValue={points_upcoming_value}
	//                 upcomingTimestamp={points_upcoming_timestamp}
	//                 hasReservation={true}
	//                 reservation={reservationData}
	//                 points={points}
	//                 privateTravel={private_travel}
	//                 profile={profile}
	//             />
	//         </React.Fragment>
	//         : null;
	// }

	// TODO: implement if server and client both separate usage and earn
	// isLoyaltyUsageEnabled() {
	//     return this
	// }

	isLoyaltyEnabled() {
		const post_pay = _.get(this.state, 'reservationData.deal.post_pay', false);
		const loyalty = _.get(this.state, 'profile.loyalty', {});

		// TODO: comment out if server supports earn
		const out_of_policy_request =
			_.get(this.state, 'out_of_policy_state') === ReservationConstants.OUT_OF_POLICY_STATE.BLOCK;
		const quote = this.shouldShowQuote();

		return (
			!post_pay &&
			loyalty.enabled &&
			// TODO: comment out if server supports earn
			!out_of_policy_request &&
			!quote
		);
	}

	getMaximumPointsEarnedRender() {
		if (this.isLoyaltyEnabled()) {
			const loyalty = _.get(this.state, 'profile.loyalty', {});
			const price_in_currency =
				_.get(this.state, 'deal.net_price_total') || _.get(this.state, 'reservationData.deal.totalPrice', 0);
			const expected_points_earned = Points.getExpectedPointsEarned({
				price_in_currency,
				points_used: 0,
				arbitrip_points_to_currency_exchange_rate: loyalty.arbitrip_points_to_currency_exchange_rate,
				private_travel: _.get(this.state, 'private_travel', false),
				arbitrip_loyalty_program: loyalty,
			});
			const expected_points_earned_currency_value = Currencies.getPriceWithDisplayCurrencyByPrecision(
				expected_points_earned * loyalty.arbitrip_points_to_currency_exchange_rate,
				1,
				_.get(this.state, 'profile.company_currency'),
				2,
			);
			const status = _.get(this.state, 'reservationData.status');
			const points_loading =
				status === ReservationConstants.STATUS.NEW ||
				status === ReservationConstants.STATUS.INITIALIZING ||
				status === ReservationConstants.STATUS.BUSY ||
				this.state.points_status === PointsConstants.STATUS.BUSY;
			return (
				<div className="maximum-points-earned">
					<div className="maximum-points-earned-title">Points</div>
					<div className="maximum-points-earned-inner">
						<div className="maximum-points-earned-icon">
							<div className="maximum-points-earned-icon-inner">
								<PointsIcon />
							</div>
						</div>
						<div className="maximum-points-earned-text">For this reservation, you can earn up to</div>

						{/* TODO: content loader */}
						{points_loading ? (
							getContentLoader(170.81, 44, 8)
						) : (
							<React.Fragment>
								<div className="maximum-points-earned-points">
									<span className="maximum-points-earned-points-value">
										{expected_points_earned.toLocaleString()}
									</span>
									<span className="maximum-points-earned-points-suffix">
										{expected_points_earned === 1 ? 'Point' : 'Points'}
									</span>
									<span className="maximum-points-earned-points-suffix">
										({expected_points_earned_currency_value})
									</span>
									<span className="maximum-points-earned-disclaimer">
										<Tooltip
											PopperProps={{
												modifiers: [{ name: 'offset', options: { offset: [0, -10] } }],
											}}
											placement="top"
											className="maximum-points-earned-disclaimer-tooltip"
											title={PointsStore.getPointsEarnedDisclaimerText()}
										>
											<IconButton sx={{ width: 20, height: 20 }}>
												<div className="maximum-points-earned-disclaimer-icon">
													<InfoOutlined sx={{ fontSize: 20 }} />
												</div>
											</IconButton>
										</Tooltip>
									</span>
								</div>
							</React.Fragment>
						)}
					</div>
				</div>
			);
		}

		return null;
	}

	getTravelBoosterRender() {
		const {
			travel_booster_integration_mandatory,
			travel_booster_docket_number,
			travel_booster_should_create_a_new_docket,
			force_travel_booster_docket_number_validation,
			profile,
		} = this.state;

		return this.isTravelBoosterEnabled() ? (
			<TravelBoosterContainer
				mandatory={!!travel_booster_integration_mandatory}
				travelBoosterDocketNumber={travel_booster_docket_number}
				shouldLockForm={this.shouldLockForm()}
				onTravelBoosterDocketNumberChange={this.onTravelBoosterDocketNumberChange}
				shouldCreateNewDocket={!!travel_booster_should_create_a_new_docket}
				onShouldCreateNewDocketChanged={this.onShouldCreateNewDocketChanged}
				shouldForceTravelBoosterDocketNumberValidation={!!force_travel_booster_docket_number_validation}
				profile={profile}
			/>
		) : null;
	}

	// getAgencyClientPriceRender() {
	//     return this.isAgencyClientPriceEnabled()
	//         // ? <AgencyClientPriceContainer
	//         //     agencyClientPrice={this.state.agency_client_price}
	//         //     shouldLockForm={this.shouldLockForm()}
	//         //     onAgencyClientPriceChange={this.onAgencyClientPriceChange}
	//         // />
	//         ? <AgentPricing
	//             initializing={(this.state.reservationData.status === STATUS.INITIALIZING)}
	//             reservation={this.state.reservationData}
	//             shouldLockForm={this.shouldLockForm()}
	//         />
	//         : null;
	// }

	getAddQuoteDisabledStatus() {
		return (
			!this.state.reservationData.include_management_trip_id ||
			this.isInitializingPayment() ||
			this.didGetPaymentData() ||
			this.isBusy()
		);
	}

	getPaymentOptionsDisabledStatus() {
		return this.isBusy() || this.state.showPaymentComponentOnQuoteFlow;
	}

	shouldShowQuote() {
		const { reservationData } = this.state;

		// show a quote if it came from management and is not a quote
		return reservationData.management_trip_id && !reservationData.quote_id;
	}

	getAddAsQuoteRender() {
		// add a quote if it came from management and is not a quote
		return this.shouldShowQuote() ? (
			<React.Fragment>
				<input
					type="button"
					className="btn btn-warning quote-button"
					onClick={this.addQuote}
					disabled={this.getAddQuoteDisabledStatus()}
					value="Add as Quote"
				/>

				<input
					type="button"
					className="btn btn-success proceed-button"
					onClick={this.showPaymentComponentOnQuoteFlow}
					disabled={this.getPaymentOptionsDisabledStatus()}
					value="Payment Options"
				/>
			</React.Fragment>
		) : null;
	}

	showPaymentComponentOnQuoteFlow() {
		this.setState({ showPaymentComponentOnQuoteFlow: true });
	}

	getBackToHotelRender() {
		const { hotel, search_token, searchTerms } = this.state;

		return (
			<div className="back-to-hotel">
				<Link to={RouterWrapper.buildHotelRoute(hotel, search_token, searchTerms)}>
					<img src="/img/back_icon.svg" alt="back button" />
					Back to Hotel
				</Link>
			</div>
		);
	}

	getTripIdCombtasBoosterContainerRender() {
		const clientTripIdRender = this.getClientTripIdRender();
		const combtasRender = this.getCombtasRender();
		const travelBoosterRender = this.getTravelBoosterRender();

		return clientTripIdRender || combtasRender || travelBoosterRender ? (
			<div className="inputs-container trip-id flex flex-wrap">
				{clientTripIdRender}
				{combtasRender}
				{travelBoosterRender}
			</div>
		) : null;
	}

	getCovidDisclaimerRender() {
		// lets hide this message… Keep it in code for future need…
		return null;
		// return (
		//     <div className='disclaimer-container'>
		//         <h4 className='title'>Travel guidance on COVID-19</h4>
		//         <div className="disclaimer-content">
		//             In accordance to the government guidelines in booking accommodations during this pandemic,
		//             guests might be asked for additional documents by the properties to confirm identity and
		//             travel itinerary, and other relevant requirements, as mandated by the government, to
		//             minimize the transmission of the coronavirus (COVID-19). Guests are advised to check on
		//             specific requirements prior to stay in line with the destination’s local government
		//             guidelines, including (but not limited to) testing, health declarations and others.
		//         </div>
		//     </div>
		// )
	}

	getCreditLineErrorDialogRender() {
		const { creditLineModalIsOpen } = this.state;

		return (
			<MaterialDesignDialog
				open={creditLineModalIsOpen}
				onClose={this.closeModal}
				closeTimeoutInMilliseconds={150}
				title={'Your credit line balance is not enough to book the deal!'}
				content={<div>Please check your credit line balance or contact your travel agency for assistant. </div>}
				actions={[{ onClick: this.closeModal, text: 'Got it' }]}
			/>
		);
	}

	getGoToPaymentContainerRender() {
		return (
			<div className="go-to-payment-container">
				<div className="final-approval-make-reservation">
					{this.getOutOfPolicyRequestRender()}
					{this.getMakeReservationRender()}
				</div>
			</div>
		);
	}

	getPaymentComponentRender(canChangeChargeCurrency = false) {
		const { out_of_policy_state, showPaymentComponentOnQuoteFlow, points, reservationData } = this.state;

		return out_of_policy_state !== ReservationConstants.OUT_OF_POLICY_STATE.BLOCK &&
			(!this.shouldShowQuote() || showPaymentComponentOnQuoteFlow) ? (
			<PaymentComponent
				validForm={this.validateForm()}
				openValidationModal={this.openValidationModal}
				validateTravelersContacts={this.validateTravelersContacts}
				forceTravelBoosterDocketNumberValidation={this.forceTravelBoosterDocketNumberValidation}
				clearSpecialRequests={this.clearSpecialRequests}
				advanceReservationFields={this.getAdvanceReservationFields()}
				shouldLockForm={this.shouldLockForm()}
				busy={this.isBusy()}
				madeReservation={this.didMakeReservation()}
				profile={this.state.profile}
				canChangeChargeCurrency={canChangeChargeCurrency}
				loyaltyEnabled={this.isLoyaltyEnabled()}
				points={points}
				privateTravel={SearchStore.isPrivateTravel()}
				reservation={reservationData}
			/>
		) : null;
	}

	isInitializingPayment() {
		return this.state.paymentData.status === PaymentConstants.STATUS.INITIALIZING;
	}

	isFetchingPoints() {
		if (this.state.profile.loyalty.enabled) {
			if (this.state.points_status === ProfileConstants.STATUS.BUSY) {
				return false;
			}
		}

		return false;
	}

	isBusy() {
		return (
			this.isInitializingPayment() ||
			this.isMakingOutOfPolicyRequest() ||
			this.isQuoting() ||
			this.isMakingReservation() ||
			this.isFetchingPoints()
		);
	}

	didMakeReservation() {
		const { status } = this.state.reservationData;

		return (
			status === ReservationConstants.STATUS.SUCCESS ||
			status === ReservationConstants.STATUS.FAILED ||
			status === ReservationConstants.STATUS.BOOKED
		);
	}

	getSpinnerRender(condition) {
		return condition ? <i className="fa fa-spin fa-spinner fa-2x" /> : null;
	}

	handleCombtasSession() {
		const { combtas_id, tas_id_ref } = this.state;

		if (this.isCombtasEnabled() && tas_id_ref) {
			removeTasIdFromSessionStorage();

			if (combtas_id === tas_id_ref) {
				try {
					// Notify other tabs to close the current tasId session if a reservation was made with the same tasId
					channel.postMessage(this.state.combtas_id);
				} catch (error) {
					console.log(error);
				}
			}
		}
	}

	getOutOfPolicyRequestButton() {
		const { out_of_policy_request_status, reservationData } = this.state;

		const out_of_policy_request_disabled =
			out_of_policy_request_status === ReservationConstants.STATUS.BUSY ||
			!this.areReservationAndFormValid() ||
			reservationData.status === ReservationConstants.STATUS.INITIALIZING;

		return (
			<Button
				variant="contained"
				color="primary"
				className="btn request-this-room"
				onClick={out_of_policy_request_disabled ? _.noop : this.requestThisRoom}
				disabled={out_of_policy_request_disabled}
			>
				Request This Room
			</Button>
		);
	}

	isMakingOutOfPolicyRequest() {
		return this.state.out_of_policy_request_status === ReservationConstants.STATUS.BUSY;
	}

	isQuoting() {
		return this.state.reservationData.status === ReservationConstants.RESERVATION_OPERATION_STATE.QUOTING;
	}

	isMakingReservation() {
		return this.state.reservationData.status === ReservationConstants.STATUS.BOOKING;
	}

	getMakeReservationRender() {
		const { out_of_policy_state } = this.state;

		// TODO: remove MaterialDesignDialog after tests

		if (out_of_policy_state === ReservationConstants.OUT_OF_POLICY_STATE.BLOCK) {
			return (
				<div id="make-reservation" className="make-reservation">
					{this.getOutOfPolicyRequestButton()}
					{this.getSpinnerRender(this.isMakingOutOfPolicyRequest())}
					{this.reservationStateDialogs()}
				</div>
			);
		} else {
			return (
				<div id="make-reservation" className="make-reservation">
					{this.getAddAsQuoteRender()}
					{this.getSpinnerRender(this.isQuoting())}
					{this.reservationStateDialogs()}
				</div>
			);
		}
	}

	reservationStateDialogs() {
		const {
			out_of_policy_state,
			out_of_policy_request_status,
			modalIsOpen,
			quotedReservationModalIsOpen,
			failedReservationInitializationModalIsOpen,
			reservationData,
			hotel,
			profile,
		} = this.state;

		const isPending = isPendingStatus(reservationData.status);
		const isSuccess = reservationData.status === ReservationConstants.STATUS.BOOKED;
		const isOutOfPolicy =
			out_of_policy_state === ReservationConstants.OUT_OF_POLICY_STATE.BLOCK &&
			out_of_policy_request_status === ReservationConstants.STATUS.SUCCESS;
		const isError = !isPending && !isSuccess && !isOutOfPolicy;
		return (
			<div>
				<ReservationDialog
					profile={profile}
					hotel={hotel}
					variant={RESERVATION_DIALOG_VARIANT.SUCCESS}
					shortId={reservationData?.short_id}
					reservationId={reservationData?.id}
					modalIsOpen={modalIsOpen && isSuccess}
					primeButtonAction={this.closeModal}
				/>

				<ReservationDialog
					profile={profile}
					hotel={hotel}
					variant={RESERVATION_DIALOG_VARIANT.PENDING}
					shortId={reservationData?.short_id}
					reservationId={reservationData?.id}
					modalIsOpen={modalIsOpen && isPending}
					isNonRefundable={_.get(reservationData, 'deal.dca.nonRefundable') === true}
					primeButtonAction={this.closeModal}
				/>

				<ReservationDialog
					profile={profile}
					hotel={hotel}
					variant={RESERVATION_DIALOG_VARIANT.FAILED}
					reservationId={reservationData?.id}
					modalIsOpen={modalIsOpen && isError}
					primeButtonAction={this.closeModal}
				/>

				<ReservationDialog
					profile={profile}
					hotel={hotel}
					variant={RESERVATION_DIALOG_VARIANT.ROOM_NOT_AVAILABLE}
					reservationId={reservationData?.id}
					modalIsOpen={failedReservationInitializationModalIsOpen}
					primeButtonAction={this.closeModal}
				/>

				<ReservationDialog
					profile={profile}
					hotel={hotel}
					variant={RESERVATION_DIALOG_VARIANT.OUT_OF_POLICY}
					reservationId={reservationData?.id}
					modalIsOpen={modalIsOpen && isOutOfPolicy}
					primeButtonAction={this.closeModal}
				/>

				<ReservationDialog
					profile={profile}
					hotel={hotel}
					variant={RESERVATION_DIALOG_VARIANT.QUOTE}
					reservationId={reservationData?.id}
					modalIsOpen={quotedReservationModalIsOpen}
					tripName={reservationData?.management_trip_name}
					primeButtonAction={() => {
						this.closeQuotedReservationModal();
						this.closeModal();
					}}
				/>
			</div>
		);
	}

	isPayAtHotel() {
		// TODO: obtain current payment method (currently stored in PaymentComponent state)
		// return false;

		// logically pay-at-hotel payment method is the only option when deal is post_pay
		return this.state.post_pay;
	}

	getManagementTripIdRender() {
		const { reservationData } = this.state;

		return reservationData.management_trip_id ? (
			<div className="management-trip-container">
				<Checkbox
					disabled={this.shouldLockForm()}
					label={`Attach booking to: '${reservationData.management_trip_name}'`}
					checked={reservationData.include_management_trip_id}
					onCheck={this.onArbiManagementTripAssociation}
				/>
			</div>
		) : null;
	}

	getIssueInvoiceRender() {
		const { private_travel, profile, post_pay, invoice_option, isInvoiceOptionChanged } = this.state;

		const { invoice_ids } = profile.company;

		// TODO: should we support a single invoice id as well? (originally it wasn't supported)
		if (!post_pay && !private_travel && isNonEmptyArray(invoice_ids) && invoice_ids[0] && invoice_ids[1]) {
			const disabled =
				this.shouldLockForm() ||
				(profile.employee && !profile.isApprovalFlowPowerUser() && invoice_option && !isInvoiceOptionChanged);

			return (
				<div className="invoice-options-container">
					<h4 className="title">Issue Invoice / Office Site:</h4>
					<div className="invoice-options-content">
						<FormControl fullWidth variant="outlined" disabled={this.shouldLockForm()}>
							<label>Select company</label>
							<Select
								id="invoice-options"
								className={this.state.invoice_option ? 'selected' : ''}
								value={this.state.invoice_option || ''}
								onChange={this.handleInvoiceChange}
								displayEmpty
								disabled={disabled}
							>
								<MenuItem value="" disabled>
									Select company...
								</MenuItem>
								{invoice_ids.map((invoice) => (
									<MenuItem key={invoice.id} value={invoice.id}>
										{invoice.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}

	getOutOfPolicyRequestRender() {
		const { reservationData, out_of_policy_state, textBoxError } = this.state;
		const initializing = reservationData.status === ReservationConstants.STATUS.INITIALIZING;

		if (out_of_policy_state && out_of_policy_state !== ReservationConstants.OUT_OF_POLICY_STATE.BYPASS) {
			let out_of_policy_request_title, out_of_policy_request_explanation;
			if (out_of_policy_state === ReservationConstants.OUT_OF_POLICY_STATE.WARNING) {
				out_of_policy_request_title = 'Book Out of Policy Room?';
				out_of_policy_request_explanation = (
					<div className="out-of-policy-request-explanation">
						<span>
							Oh, Snap! The room you're about to book is <b>out of the policy.</b>
						</span>
						<br />
						<span>
							You see, while we understand that sometimes there's no other option, we'd still have to ask
							you to provide the reason for booking this room.
						</span>
					</div>
				);
			} else if (out_of_policy_state === ReservationConstants.OUT_OF_POLICY_STATE.BLOCK) {
				out_of_policy_request_title = 'Request This Room';
				out_of_policy_request_explanation = (
					<div className="out-of-policy-request-explanation">
						<span>
							The room you are trying to book requires your travel manager's approval due to the company
							policy.
						</span>
						<br />
						{this.getTravelPolicyText()}
						<br />
						<span>Please provide the reason for your request.</span>
					</div>
				);
			}

			return (
				<div className="out-of-policy-request-container">
					<h4 className="title">{out_of_policy_request_title}</h4>
					<div className="out-of-policy-request-content">
						<div className="out-of-policy-request" id="out-of-policy-request">
							{out_of_policy_request_explanation}
							<div className="out-of-policy-request-reason">
								<textarea
									className={`text-box ${textBoxError ? 'error' : ''}`}
									onChange={this.updateOutOfPolicyRequestReason}
									disabled={initializing}
									value={reservationData.out_of_policy_booking_reason}
									placeholder="e.g There's a limited availability in the area on these dates."
								/>
							</div>
						</div>
					</div>
				</div>
			);
		}

		return null;
	}

	getTravelPolicyText() {
		const { deal, travel_policy: travelPolicy } = this.state;
		const doesTravelPolicyExist = travelPolicy && (travelPolicy.default || travelPolicy.place);
		const isInPolicy = deal.in_policy;
		if (!doesTravelPolicyExist || isInPolicy) return null;
		const travelPolicyCurrency = doesTravelPolicyExist ? Currencies.getSymbol(travelPolicy.currency) : '';
		const travelPolicyLocation =
			travelPolicy && travelPolicy.default
				? 'This destination'
				: travelPolicy && travelPolicy.place
					? travelPolicy.place.city || travelPolicy.place.state || travelPolicy.place.country
					: '';
		return (
			<span className="out-of-policy-message">
				{deal.out_of_policy_message ||
					`The maximum price allowed for ${travelPolicyLocation} is ${travelPolicyCurrency}${travelPolicy.price} per night.`}
			</span>
		);
	}

	getReservationErrorRender() {
		const { hotel, reservationData, search_terms, search_token } = this.state;
		const { navigate } = this.props;

		let errorText = reservationData.bookingError;

		if (errorText === 'error') {
			errorText = 'An issue occurred while processing your payment';
		}
		if (!errorText) {
			return null;
		}

		const searchText = 'Please try choosing';
		const parts = errorText.split(searchText);
		const beforeText = parts[0];
		const afterText = parts[1] ? searchText + parts[1] : '';

		const handleClick = () => {
			RouterWrapper.goToHotelPage(navigate, hotel, search_token, search_terms);
		};

		return (
			<div className="booking-error-message">
				<AttentionIcon className="icon" />
				<span>{beforeText}</span>
				{afterText && (
					<span className="error-action" onClick={handleClick}>
						{afterText}
					</span>
				)}
			</div>
		);
	}

	getPriceChangedModal() {
		const { reservationData, hotel, profile } = this.state;
		const priceChangedData = reservationData.deal?.priceChangedData;
		if (!priceChangedData) {
			return null;
		}

		const isShowPriceChangedModal = !priceChangedData.confirmedChange;

		return (
			<ReservationDialog
				profile={profile}
				oldPrice={priceChangedData.oldPrice}
				newPrice={priceChangedData.newPrice}
				hotel={hotel}
				variant={RESERVATION_DIALOG_VARIANT.PRICE_CHANGED}
				shortId={reservationData?.short_id}
				reservationId={reservationData?.id}
				primeButtonAction={() => {
					ReservationActions.confirmPriceChanged();
				}}
				modalIsOpen={isShowPriceChangedModal}
				deal={reservationData.deal}
			/>
		);
	}

	render() {
		const {
			profile,
			private_travel,
			reservationData,
			create_traveler_statuses,
			contract_client_name,
			send_email,
			send_voucher_separately_from_confirmation_mail,
			selected_company_custom_field,
			selected_company_custom_field_secondary,
			hotel,
			deal,
			validationModalIsOpen,
			contract_client_company_id,
			points,
			isShowReservationError,
			showConfirmationPage,
			isSpecialRequestsChecked,
		} = this.state;

		const hotel_name = _.get(hotel, 'name');
		const title = TitleManager.buildTitleStartsWith(`Hotel${hotel_name ? ' ' + hotel_name : ''}`, true, true);

		const leisure_allowed_payment_currencies = _.get(profile, 'leisure_allowed_payment_currencies', []);
		const canChangeChargeCurrency =
			leisure_allowed_payment_currencies.length > 1 && // If less, no change possible
			private_travel; // currently supported only for private travel on credit cards
		const initializing = reservationData.status === STATUS.INITIALIZING;

		if (showConfirmationPage) {
			return <ConfirmationPage reservation={reservationData} hotel={hotel} profile={profile} />;
		}

		return (
			<DocumentTitle title={title}>
				<div className="reservation-page flex flex-column">
					{
						<React.Fragment>
							{this.getBackToHotelRender()}
							<div className="reservation-container top-container">
								<div className="reservation-main">
									<HotelDetailsContainer
										profile={profile}
										hotel={hotel}
										canChangeChargeCurrency={canChangeChargeCurrency}
										reservation={reservationData}
									/>

									<CancellationTermsContainer
										profile={profile}
										hotel={hotel}
										reservation={reservationData}
									/>

									{/* {this.getLoyaltyBoxRender()} */}

									{this.getMaximumPointsEarnedRender()}

									<TravelersDetailsContainer
										profile={profile}
										reservationData={reservationData}
										createTravelerStatuses={create_traveler_statuses}
										shouldLockForm={this.shouldLockForm()}
										contractClientName={contract_client_name}
										sendVoucherSeparatelyFromConfirmationMail={
											send_voucher_separately_from_confirmation_mail
										}
										handleSendVoucherSeparatelyCheckboxChange={
											this.handleSendVoucherSeparatelyCheckboxChange
										}
										sendEmail={send_email}
										handleSendEmailCheckboxChange={this.handleSendEmailCheckboxChange}
										autoFill={Config.dev_mode && Config.auto_fill}
									/>

									<CustomFieldsContainer
										reservationData={reservationData}
										shouldLockForm={this.shouldLockForm()}
										handleCustomTextFieldChange={this.handleCustomTextFieldChange}
										handleSelectedCustomFieldChange={this.handleSelectedCustomFieldChange}
										handleSelectedSecondaryCustomFieldChange={
											this.handleSelectedSecondaryCustomFieldChange
										}
										selectedCompanyCustomField={selected_company_custom_field}
										selectedCompanyCustomFieldSecondary={selected_company_custom_field_secondary}
									/>

									<SpecialRequests
										isChecked={isSpecialRequestsChecked}
										handleChecked={this.handleSpecialRequestCheckboxChange}
										reservationData={reservationData}
										shouldLockForm={this.shouldLockForm()}
									/>

									<TermsOfSupplier deal={deal} initializing={initializing} />

									{this.getTripIdCombtasBoosterContainerRender()}

									{this.getManagementTripIdRender()}

									{this.getCovidDisclaimerRender()}

									{this.getIssueInvoiceRender()}

									{this.getGoToPaymentContainerRender()}

									{this.getCreditLineErrorDialogRender()}

									{!isShowReservationError && this.getPaymentComponentRender(canChangeChargeCurrency)}

									{isShowReservationError && this.getReservationErrorRender()}

									<ValidationDialog
										open={validationModalIsOpen}
										onClose={this.closeValidationModal}
									/>

									{this.getPriceChangedModal()}
								</div>
								<div className="reservation-side">
									{PriceBreakdown.isBreakdownPrice({ profile, reservation: reservationData }) ? (
										<ExpediaDealDetailsContainer
											profile={profile}
											initializing={initializing}
											initialized={
												reservationData.status === ReservationConstants.STATUS.INITIALIZED
											}
											reservation={reservationData}
										/>
									) : (
										<DealDetailsContainer
											profile={profile}
											shouldLock={this.shouldLockForm()}
											hotel={hotel}
											initializing={initializing}
											initialized={
												reservationData.status === ReservationConstants.STATUS.INITIALIZED
											}
											canChangeChargeCurrency={canChangeChargeCurrency}
											agencyClientPriceEnabled={this.isAgencyClientPriceEnabled()}
											reservation={reservationData}
											contractClientCompanyId={contract_client_company_id}
											points={points}
										/>
									)}
								</div>
							</div>
						</React.Fragment>
					}
				</div>
			</DocumentTitle>
		);
	}

	_onChange() {
		const prevState = this.state;
		let nextState = getReservationPageState();

		nextState.selected_company_custom_field = prevState.selected_company_custom_field;
		nextState.selected_company_custom_field_secondary = prevState.selected_company_custom_field_secondary;
		nextState.company_custom_text_fields = prevState.company_custom_text_fields;
		nextState.isInvoiceOptionChanged = prevState.isInvoiceOptionChanged;

		// patch around combtas being overridden by search store
		if (prevState.combtas_id) {
			nextState.combtas_id = prevState.combtas_id;
		}

		// patch around travel booster being overridden by search store
		if (prevState.travel_booster_docket_number) {
			nextState.travel_booster_docket_number = prevState.travel_booster_docket_number;
		}

		this.setState(nextState);

		if (prevState.reservationData.status) {
			if (prevState.reservationData.status === ReservationConstants.STATUS.FAILED_RESERVATION_INITIALIZATION) {
				console.log('RSRV STATUS = FAILED INIT');
				this.openFailedReservationInitializationModal();
			} else if (prevState.reservationData.status === ReservationConstants.RESERVATION_OPERATION_STATE.QUOTED) {
				console.log('RSRV quoted');
				this.openQuotedReservationModal();
			} else if (
				prevState.reservationData.status === ReservationConstants.STATUS.BOOKED ||
				isPendingStatus(prevState.reservationData.status) ||
				prevState.reservationData.status === ReservationConstants.STATUS.FAILED ||
				prevState.out_of_policy_request_status === ReservationConstants.STATUS.SUCCESS ||
				prevState.out_of_policy_request_status === ReservationConstants.STATUS.FAILED
			) {
				const credit_line_status = _.get(prevState, 'reservationData.credit_line_status');
				if (credit_line_status === ReservationConstants.STATUS.FAILED) {
					this.setState({ creditLineModalIsOpen: true });
				} else {
					this.handleCombtasSession();
					const shouldShowConfirmationPage = _.get(
						prevState,
						'profile.company.partners_ui.show_check_out_page',
						false,
					);
					if (
						shouldShowConfirmationPage &&
						prevState.reservationData.status !== ReservationConstants.STATUS.FAILED
					) {
						this.setState({ showConfirmationPage: true });
					} else {
						this.openModal();
					}
				}
			}
		}
	}
}

function getClientCustomFields(
	company_custom_fields,
	selected_company_custom_field_index,
	selected_company_custom_field_secondary_index,
	company_custom_text_fields,
) {
	let client_custom_fields = null;
	const company_fields = company_custom_fields;
	if (company_fields) {
		client_custom_fields = Object.assign({}, company_custom_text_fields);

		const first_combobox_custom_field = company_fields.find((custom_field) =>
			isNonEmptyArray(_.get(custom_field, 'list')),
		);

		if (
			first_combobox_custom_field &&
			first_combobox_custom_field.list &&
			first_combobox_custom_field.list[selected_company_custom_field_index] &&
			first_combobox_custom_field.list[selected_company_custom_field_index].label
		) {
			const selected_field = first_combobox_custom_field.list[selected_company_custom_field_index];
			// company_custom_fields_results.first = selected_field.label;
			const key_first = selected_field._id || moment() + 'a';
			client_custom_fields[key_first] = {
				label: first_combobox_custom_field.label,
				value: selected_field.label,
				show_on_invoice: first_combobox_custom_field.show_on_invoice,
			};

			if (selected_field.meta) {
				client_custom_fields[key_first].meta = selected_field.meta;
			}

			if (
				selected_field.list &&
				selected_field.list[selected_company_custom_field_secondary_index] &&
				selected_field.list[selected_company_custom_field_secondary_index].label
			) {
				// company_custom_fields_results.second = selected_field.list[this.state.selected_company_custom_field_secondary].label;
				const selected_second_field = selected_field.list[selected_company_custom_field_secondary_index];
				const key_second = selected_second_field._id || moment() + 'b';
				client_custom_fields[key_second] = {
					label: selected_field.label,
					value: selected_second_field.label,
					show_in_invoice: first_combobox_custom_field.show_on_invoice,
				};

				if (selected_second_field.meta) {
					client_custom_fields[key_second].meta = selected_second_field.meta;
				}
			}
		}
	}

	return client_custom_fields;
}

export default withRouter(ReservationPaymentPage);
