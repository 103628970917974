import { EXTRAS } from '../consts';
import moment from 'moment';
import _ from 'lodash';
import ManagementConstants from '@/constants/ManagementConstants';

const format = ManagementConstants.DATE_PICKER_FORMAT;

export const getUserBranchName = (profile) => {
	return profile.agency_branch && profile.agency_branch.name;
};

export const getFilterName = (key) => {
	if (key === EXTRAS.TRAVEL_BOOSTER_NOT_SYNCED) {
		return 'Extra';
	}

	if (key === EXTRAS.AGENCY_BRANCHES || key === EXTRAS.BOOKINGS_FROM_BRANCH) {
		return 'Branch';
	}

	return _.startCase(key);
};

export const getLabel = (key, filters) => {
	const truncate = (label) => {
		if (!label) return '';
		const maxLength = 55;
		return label.length > maxLength ? `${label.slice(0, maxLength)}...` : label;
	};

	if (key === EXTRAS.TRAVEL_BOOSTER_NOT_SYNCED) {
		return truncate(_.startCase(EXTRAS.TRAVEL_BOOSTER_NOT_SYNCED));
	}

	const rawLabel = moment.isMoment(filters[key])
		? filters[key].format(format)
		: Object.values(EXTRAS).includes(filters[key])
			? _.startCase(filters[key])
			: filters[key];

	return truncate(String(rawLabel || ''));
};
