import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { FormControl, RadioGroup, FormControlLabel, Radio, Button, CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import { ReactComponent as CreditCardsIcon } from '@/img/bookings/credit-cards.svg';
import PaymentLinkActions from '../../../../actions/PaymentLinkActions';
import PaymentLinkStore from '../../../../stores/PaymentLinkStore';
import PaymentLinkConstants from '../../../../constants/PaymentLinkConstants';
import { PAYMENT_TYPE } from './const';
import helpers from './helpers';

const SettlePaymentType = ({ booking, chargeCurrency, name, updatePaymentType }) => {
	const [paymentType, setPaymentType] = useState(PAYMENT_TYPE.PAY_NOW);
	const [paymentLinkStatus, setPaymentLinkStatus] = useState(PaymentLinkStore.getStatus());
	const [showError, setShowError] = useState(false);
	const [loading, setLoading] = useState(false);

	const link = useMemo(() => {
		return helpers.getPaymentLink(booking);
	}, [booking, booking?.payment?.payment_link?.token]);

	useEffect(() => {
		function handleStoreChange() {
			setPaymentLinkStatus(PaymentLinkStore.getStatus());
		}

		PaymentLinkStore.addChangeListener(handleStoreChange);

		return () => {
			PaymentLinkStore.removeChangeListener(handleStoreChange);
		};
	}, []);

	useEffect(() => {
		console.log('paymentLinkStatus');
		console.log(paymentLinkStatus);
		if (paymentLinkStatus === PaymentLinkConstants.STATUS.BUSY) {
			setLoading(true);
			return;
		}

		setLoading(false);
		if (paymentLinkStatus === PaymentLinkConstants.STATUS.FAILED) {
			setShowError(true);
		} else if (paymentLinkStatus === PaymentLinkConstants.STATUS.SUCCESS) {
			setShowError(false);
		}
	}, [paymentLinkStatus]);

	const payment_link_expiration_exists = useMemo(() => {
		return helpers.isPaymentLinkExpirationExists(booking);
	}, [booking]);

	const token_expired = useMemo(() => {
		return helpers.isTokenExpired(booking);
	}, [booking]);

	const link_expired_time = useMemo(() => {
		const pay_later_deadline = moment(booking.pay_later_deadline).startOf('day').subtract(1, 'minute');
		const date_48hrs = moment().add(48, 'hours');

		const earlier_date = moment.min(pay_later_deadline, date_48hrs);

		return helpers.getTimeFormatted(earlier_date);
	}, []);

	const handlePaymentTypeChange = (event) => {
		setPaymentType(event.target.value);
		updatePaymentType(event.target.value);
	};

	const handleGenerateLink = () => {
		// generate the link
		setLoading(true);
		const payment_id = _.get(booking, 'payment._id');
		const agent_pricing = _.get(booking, 'deal.agent_pricing');

		PaymentLinkActions.generatePaymentLink({
			id: payment_id,
			booking_id: booking.id,
			currency: chargeCurrency,
			invoice_name: name,
			agent_pricing,
		});
		updatePaymentType(PAYMENT_TYPE.GENERATED_LINK);
	};

	const handleCloseError = () => {
		PaymentLinkActions.resetPaymentLink();
	};

	return (
		<div>
			{payment_link_expiration_exists && token_expired && (
				<div className="expired-message">
					The payment link you generated has expired,
					<br />
					please generate a new one
				</div>
			)}
			<FormControl fullWidth variant="outlined" className="settle-payment-type">
				<RadioGroup value={paymentType} onChange={handlePaymentTypeChange}>
					<FormControlLabel
						value={PAYMENT_TYPE.PAY_NOW}
						control={<Radio />}
						label={
							<Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
								Pay now
								<CreditCardsIcon />
							</Box>
						}
					/>
					<FormControlLabel
						value={PAYMENT_TYPE.SEND_LINK}
						control={<Radio />}
						label={
							<div className="send-link-label">
								<div className="send-link-label-title">Send a payment link</div>
								<div className="send-link-label-subtitle">
									Available for 48h, will expire on {link_expired_time}
								</div>
							</div>
						}
						style={{ alignItems: 'flex-start' }}
					/>
				</RadioGroup>
				{paymentType === PAYMENT_TYPE.SEND_LINK && (
					<div className="copy-link-wrap">
						{showError && (
							<div className="copy-link full-width">
								<div className="show-error" onClose={handleCloseError}>
									Something went wrong
								</div>
								<Button className="generate-button" variant="contained" onClick={handleGenerateLink}>
									{loading ? <CircularProgress size="20px" /> : 'Try Again'}
								</Button>
							</div>
						)}
						{!showError && (
							<Button
								className="generate-button full-width"
								variant="contained"
								onClick={handleGenerateLink}
							>
								{loading ? <CircularProgress size="20px" /> : 'Generate Link'}
							</Button>
						)}
					</div>
				)}
			</FormControl>
		</div>
	);
};

export default SettlePaymentType;
