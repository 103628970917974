const keyMirror = require('keymirror');

export const EVENTS = keyMirror({
    SELECTED_RECENT_SEARCH: null,
    SELECTED_BUSINESS_DESTINATION: null,
    SELECTED_LEISURE_DESTINATION: null,
    ADDED_BUSINESS_DESTINATION: null,
    ADDED_BUSINESS_DESTINATION_FAILED: null,
    EXECUTED_SEARCH: null,
    CHANGED_SEARCH: null,

    RESULTS_RECEIVED: null,
    RESULTS_RECEIVED_FIRE: null,
    RESULTS_RECEIVED_HOTELS: null,

    SHOW_GROUP_BOOKINGS_MODAL: null,
    OPEN_GROUP_BOOKING_REQUEST_FORM: null,

    GEO_LOCATION_DESTINATION_SELECTED: null,
});