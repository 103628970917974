import _ from 'lodash';
import Recipient from './BasketRecipient';
import Dates from 'arbitrip-common/client/utils/Dates';
import moment from 'moment';

function emailsReducer(recipients, email) {
  return recipients.hasOwnProperty(email)
    ? recipients
    : Object.assign(recipients, { [email]: new Recipient(email) });
}

export default class Basket {
  constructor(basket) {
    this.remarks = basket.remarks || '';
    this.deals = basket.deals || [];

    this.combtas_id = basket.combtas_id || '';
    this.recipients = basket.recipients || {};

    // backward compatibilty for emails only baskets
    if (Array.isArray(basket.emails) && basket.emails.length) {
      this.recipients = basket.emails.reduce(emailsReducer, this.recipients);
    }
  }

  static reformatPayload(basket) {
    let new_basket = Object.assign({}, basket);
    new_basket.deals = basket.deals.map((deal) => {
      let new_deal = {
        id: _.get(deal, 'id'),
        place_id: _.get(deal, 'place_id'),

        details: {
          check_in: moment.utc(_.get(deal, 'check_in')).endOf('day'),
          check_out: moment.utc(_.get(deal, 'check_out')).endOf('day'),
          room_description: _.get(deal, 'room'),
          nights: _.get(deal, 'nights'),
          guest_count: _.get(deal, 'guests'),
          room_count: _.get(deal, 'rooms'),
          children_ages: _.get(deal, 'children_ages'),
          private_travel: _.get(deal, 'private_travel'),

          breakfast_included: _.get(deal, 'details.breakfast_included', false)
        },

        hotel: _.get(deal, 'hotel'),

        cheapopoPricing: {
          currency: _.get(deal, 'currency'),
          pricePerNight: _.get(deal, 'pricePerNight'),
          cheapopoTotalPrice: _.get(deal, 'totalPrice')
        },

        dca: {
          heuristicOptions: {
            includesBreakfast: _.get(deal, 'dca.heuristicOptions.includesBreakfast', false),
            isHalfBoard: _.get(deal, 'dca.heuristicOptions.isHalfBoard', false),
            isFullBoard: _.get(deal, 'dca.heuristicOptions.isFullBoard', false),
            isAllInclusive: _.get(deal, 'dca.heuristicOptions.isAllInclusive', false),
          }
        },

        chooseprod: _.get(deal, 'chooseprod'),

        // url: _.get(deal, 'url') // obsolete

        // show_policy: deal.travel_policy_exists
      };

      const score_description = _.get(new_deal, 'hotel.review.description');

      if (score_description) {
        _.set(new_deal, 'hotel.review.score_description', score_description);
        delete new_deal.hotel.review.description;
      }

      const agent_pricing = _.get(deal, 'agent_pricing', {});
      if (!_.isEmpty(agent_pricing)) {
        new_deal.agent_pricing = agent_pricing;
      }

      return new_deal;
    });

    return new_basket;
  }
}