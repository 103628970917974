const _ = require('lodash');
const { CHAT_PROVIDERS } = require('../../general/constants/chat-providers');

const _injectIntercom = () => {
    const REACT_APP_INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;

    if (!REACT_APP_INTERCOM_APP_ID) {
        console.log('_injectIntercom:: please set REACT_APP_INTERCOM_APP_ID.');
        return;
    }

    const scriptUrl = `https://widget.intercom.io/widget/${REACT_APP_INTERCOM_APP_ID}`;

    return new Promise((resolve, reject) => {
        if (!_isScriptSrcExist(scriptUrl)) {
            /**
             * Start Intercom snippet with small changes
             */
            (function () {
                var w = window;
                var ic = w.Intercom;
                if (typeof ic === 'function') {
                    ic('reattach_activator');
                } else {
                    var d = document;
                    var i = function () {
                        i.c(arguments);
                    };
                    i.q = [];
                    i.c = function (args) {
                        i.q.push(args);
                    };
                    w.Intercom = i;

                    var s = d.createElement('script');
                    s.type = 'text/javascript';
                    s.async = true;
                    s.rel = 'preconnect';
                    s.src = scriptUrl;

                    /**
                     * Added code for the promise
                     */
                    s.onload = () => {
                        resolve('Intercom loaded successfully');
                    };
                    s.onerror = () => {
                        reject(new Error(`Failed to load script: ${scriptUrl}`));
                    };
                    /**
                     * End Added code for the promise
                     */

                    var x = d.getElementsByTagName('script')[0];
                    x.parentNode.insertBefore(s, x);
                }
            })();
            /**
             * End Intercom snippet
             */
        } else {
            resolve('Intercom loaded successfully');
        }
    });
};

const _isScriptSrcExist = (scriptUrl) => {
    // Check if the script already exists
    return Array.from(document.head.getElementsByTagName('script')).some(
        (script) => script.src === scriptUrl
    );
};

const inject = (provider) => {
    if (!provider) {
        return new Promise((resolve, reject) => reject('Chat will not be initiated, chat provider was not delivered'));
    }

    if (provider === CHAT_PROVIDERS.INTERCOM) {
        return _injectIntercom();
    }
};

module.exports = {
    inject
};