export default {
    isBreakdownPrice: ({profile, deal, reservation, company}) => {
        const comp = company ?? profile?.company
        if (!comp?.partners_ui?.breakdown_price_taxes) {
            return false;
        }

        if (deal?.expedia_pricing) {
            return true;
        }

        if (reservation?.deal?.expedia_pricing) {
            return true;
        }
        return false;
    },
    EXPEDIA_TOOLTIP_TEXT: "This charge includes estimated amounts the travel service provider (i.e. hotel, car rental company) " +
        "pays for their taxes, and/or taxes that we pay, to taxing authorities on your booking " +
        "(including but not limited to sales, occupancy, and value added tax). " +
        "This amount may also include any amounts charged to us for resort fees, cleaning fees, and other fees and/or a fee we, " +
        "the hotel supplier and/or the site you booked on, retain as part of the compensation for our and/or their services, " +
        "which varies based on factors such as location, the amount, and how you booked. " +
        "For more details, please see the terms and conditions.",
    TOTAL_PRICE_TOOLTIP_TEXT: "Hotels can sometimes add special fees, please refer to the 'Terms of Supplier’ section before you book your room.",
    isShowTotalPrice: ({profile, company}) => {
        const comp = company ?? profile?.company;
        return comp?.partners_ui?.show_total_price
    },
}
