import ManagementConstants from '../constants/ManagementConstants';

function validateTripRequest(trip_request) {
	return trip_request && trip_request.id;
}

function validateTripRequestComponentInitialized(component) {
	return component && component.id;
}

function validateTripRequestComponent(component, validate_init) {
	if (!component) return false;

	if (validate_init && validateTripRequestComponentInitialized(component)) return false;

	switch (component.component_type) {
		case ManagementConstants.TRIP_REQUEST_COMPONENT_TYPE.FLIGHT:
			return (
				component.from &&
				component.from.display_name &&
				component.to &&
				component.to.display_name &&
				component.depart_date
			);
		case ManagementConstants.TRIP_REQUEST_COMPONENT_TYPE.ACCOMMODATION:
			return !!(
				(component.destination && component.destination.address) ||
				component.check_in ||
				component.check_out
			);
		case ManagementConstants.TRIP_REQUEST_COMPONENT_TYPE.CAR:
			return component.pick_up && component.drop_off && component.pick_up_date && component.drop_off_date;
		default: // Do nothing
	}

	return false;
}

function validateTripRequestFlight(flight, validate_init) {
	return validateTripRequestComponent(flight, validate_init);
}

function validateTripRequestAccommodation(accommodation, validate_init) {
	return validateTripRequestComponent(accommodation, validate_init);
}

function validateTripRequestCar(car, validate_init) {
	return validateTripRequestComponent(car, validate_init);
}

export default {
	validateTripRequest: validateTripRequest,
	validateTripRequestFlight: validateTripRequestFlight,
	validateTripRequestAccommodation: validateTripRequestAccommodation,
	validateTripRequestCar: validateTripRequestCar,
	validateTripRequestComponentInitialized: validateTripRequestComponentInitialized,
};
