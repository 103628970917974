import React, { Component } from 'react';
import moment from 'moment';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';

import ManagementActions from '../../../actions/ManagementActions';
import EntityValidator from '../../../utils/EntityValidator';
import ManagementConstants from '../../../constants/ManagementConstants';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import MaterialDesignAirportsAutocomplete from '../../NewerMaterialDesignAirportsAutocomplete.react';
import Icon from '../../ReactEvilIcon';
import ManagementStore from '../../../stores/ManagementStore';

function formatDate(date) {
	return date && moment(date).format('MMM DD');
}

const _preferredTimes = ['Morning', 'Afternoon', 'Evening', 'Night', 'Any Time'];

function getAddFlightPageState() {
	return {
		hovering: false,
	};
}

class AddFlight extends Component {
	constructor(props) {
		super(props);
		this.state = getAddFlightPageState();
	}

	componentDidUpdate(prevProps) {
		const { flight } = this.props;
		const prevFlight = prevProps.flight;
		const nextFlight = flight;
		if (
			EntityValidator.validateTripRequestFlight(nextFlight) &&
			(prevFlight.from.id !== nextFlight.from.id ||
				prevFlight.to.id !== nextFlight.to.id ||
				Boolean(moment(prevFlight.depart_date).diff(moment(nextFlight.depart_date), 'days')) ||
				prevFlight.depart_time !== nextFlight.depart_time)
		) {
			if (!ManagementStore.isTripRequestSaving()) {
				if (!EntityValidator.validateTripRequestComponentInitialized(nextFlight)) {
					ManagementActions.createTripRequestComponent(nextFlight);
				} else {
					ManagementActions.updateTripRequestComponent(nextFlight);
				}
			} else {
				ManagementActions.updateTripLater(nextFlight);
			}
		}
	}

	getSearchLink() {
		let details = this.props.flight;
		if (!details || !details.from.display_name || !details.to.display_name || !details.depart_date) return null;
		let from_iata =
			details.from.iata_code || details.from.display_name.substring(0, details.from.display_name.indexOf(' '));
		let to_iata =
			details.to.iata_code || details.to.display_name.substring(0, details.to.display_name.indexOf(' '));
		let depart_date = details.depart_date ? moment(details.depart_date).format('YYYY-MM-DD') : '';

		return `https://www.kayak.com/flights/${from_iata}-${to_iata}/${depart_date}`;
	}

	handleMouseEnter = () => {
		this.setState({
			hovering: true,
		});
	};

	handleMouseLeave = () => {
		this.setState({
			hovering: false,
		});
	};

	handleDepartDateChange = (date) => {
		ManagementActions.updateFlightDepartDate(this.props.flight, moment(date));
	};

	handleCancelClick = () => {
		ManagementActions.removeTripRequestComponent(this.props.flight);
	};

	handleDepartTimeOnChange = (event) => {
		ManagementActions.updateFlightDepartTime(this.props.flight, event.target.value);
	};

	onFromAirportAutoCompleteNewRequest = (data) => {
		ManagementActions.updateFlightFrom(this.props.flight, data);
	};

	onToAirportAutoCompleteNewRequest = (data) => {
		console.log('onToAirportAutoCompleteNewRequest', data);
		ManagementActions.updateFlightTo(this.props.flight, data);
	};

	render() {
		let { flight, disabling, forward, travel_manager_mode, can_edit, should_validate } = this.props;

		let component_classes = 'add-flight add-component';
		if (this.state.hovering) {
			component_classes += ' hovering';
		}

		let depart_date = (flight.depart_date && moment(flight.depart_date)) || null;

		let search_link = this.getSearchLink();

		return (
			<div
				className={component_classes}
				onMouseEnter={this.handleMouseEnter}
				onMouseLeave={this.handleMouseLeave}
			>
				<div className="component-icon">
					<i className="fa fa-plane fa-fw" />
				</div>
				<div className="left-inputs">
					<div className="from input">
						{can_edit ? (
							<MaterialDesignAirportsAutocomplete
								placeholder="e.g. Heathrow"
								label="From"
								fullWidth={true}
								searchText={(flight.from && flight.from.display_name) || ''}
								onNewRequest={this.onFromAirportAutoCompleteNewRequest}
								autoFocus={this.props.autoFocus}
								error={should_validate && !(flight.from && flight.from.display_name)}
							/>
						) : (
							<TextField
								className="field-input"
								label="From"
								placeholder="e.g. Heathrow"
								fullWidth
								value={(flight.from && flight.from.display_name) || ManagementConstants.NO_VALUE}
								disabled
								variant="standard"
							/>
						)}
					</div>
					<div className="to input">
						{can_edit ? (
							<MaterialDesignAirportsAutocomplete
								className="field-input"
								placeholder="e.g. JFK"
								label="To"
								fullWidth={true}
								searchText={(flight.to && flight.to.display_name) || ''}
								onNewRequest={this.onToAirportAutoCompleteNewRequest}
								error={should_validate && !(flight.to && flight.to.display_name)}
							/>
						) : (
							<TextField
								className="field-input"
								label="To"
								placeholder="e.g. JFK"
								fullWidth
								value={(flight.to && flight.to.display_name) || ManagementConstants.NO_VALUE}
								disabled
								variant="standard"
							/>
						)}
					</div>
				</div>
				<div className="depart right-inputs">
					{can_edit ? (
						<LocalizationProvider
							dateAdapter={AdapterMoment}
							adapterLocale={ManagementConstants.ADAPTER_MOMENT_LOCALE}
						>
							<DesktopDatePicker
								format={ManagementConstants.DATE_PICKER_FORMAT}
								className="field-input date input"
								label="Depart"
								placeholder="e.g. Dec 20"
								value={depart_date}
								onChange={this.handleDepartDateChange}
								variant="standard"
								slotProps={{ textField: { variant: 'standard' } }}
							/>
						</LocalizationProvider>
					) : (
						<TextField
							className="field-input date"
							label="Depart"
							placeholder="e.g Dec 20"
							fullWidth
							value={(depart_date && formatDate(depart_date.toDate())) || ManagementConstants.NO_VALUE}
							disabled
							variant="standard"
						/>
					)}
					{can_edit ? (
						<FormControl fullWidth className="date input">
							<InputLabel variant="standard">Depart Time</InputLabel>
							<Select
								placeholder="Depart Time"
								value={flight.depart_time || ''}
								onChange={this.handleDepartTimeOnChange}
								variant="standard"
							>
								{_preferredTimes.map((time, idx) => (
									<MenuItem key={idx} value={time}>
										{time || ''}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					) : (
						<TextField
							className="field-input date depart"
							label="Depart Time"
							placeholder="Depart Time"
							value={flight.depart_time || ManagementConstants.NO_VALUE}
							disabled
							variant="standard"
						/>
					)}
				</div>
				{search_link && travel_manager_mode && this.state.hovering && !forward ? (
					<div className="field find-link">
						<a href={search_link} target="_blank" rel="noopener noreferrer">
							Find a flight
							<Icon name="ei-external-link" size="s" className="evil-icon" />
						</a>
					</div>
				) : null}
				{this.state.hovering && !disabling && !forward && can_edit ? (
					<div className="cancel-icon">
						<a href="#!" className="action cancel" onClick={this.handleCancelClick}>
							<Icon name="ei-close" size="m" className="evil-icon" />
						</a>
					</div>
				) : null}
			</div>
		);
	}
}

export default AddFlight;
