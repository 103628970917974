import _ from 'lodash';
import React from 'react';
import {
	getPrecisePriceWithCurrency,
	getPriceWithDisplayCurrencyByPrecision,
} from 'arbitrip-common/general/utils/Currencies';
import { getPriceValue } from './utils';
import helpers from './helpers';

const SettlePaymentSummary = ({ booking, showEditCommission, chargeCurrency, profile }) => {
	const total_net_price = booking?.deal?.cheapopoPricing?.purchase_price;
	const total_client_price = booking.deal?.cheapopoPricing?.cheapopoTotalPrice;

	const total_commission =
		booking?.deal?.cheapopoPricing?.agentPricing?.absolute_margin_total ||
		booking?.deal?.cheapopoPricing?.commissionTotal ||
		0;
	const commission_percentage = helpers.getCommissionPercentage(booking);

	const currency = booking?.deal?.cheapopoPricing?.currency;
	const total_to_pay = total_net_price + total_commission || total_client_price;

	const additional_currency = chargeCurrency && chargeCurrency !== currency;

	return (
		<div className="change-client-price">
			<div className="pricing-summary">
				<label>Pricing Summary</label>
				<div className="pricing-item">
					<div className="price-title">Total net price</div>
					{getPriceValue(total_net_price, currency, chargeCurrency, profile)}
				</div>
				<div className="pricing-item">
					<div className="price-title">% Commission</div>
					<div className="price-value">
						{_.isFunction(showEditCommission) && (
							<span className="edit-commission" onClick={showEditCommission}>
								Edit Commission
							</span>
						)}
						{commission_percentage}%
					</div>
				</div>
				<div className="pricing-item">
					<div className="price-title">Total commission</div>
					{getPriceValue(total_commission, currency, chargeCurrency, profile)}
				</div>
				<div className="pricing-item bold">
					<div className="price-title">Total client price</div>
					{getPriceValue(total_client_price, currency, chargeCurrency, profile)}
				</div>
				<hr />

				<div className="pricing-item bold sum">
					<div className="price-title">Total to pay</div>
					<div className="price-value">
						{additional_currency ? (
							<React.Fragment>
								<span className="approx">approx.</span>
								{getPriceWithDisplayCurrencyByPrecision(
									total_to_pay,
									profile.freelance_agent_pay_later_exchange_rates[chargeCurrency],
									chargeCurrency,
									2,
								)}
							</React.Fragment>
						) : (
							getPrecisePriceWithCurrency(total_to_pay, currency)
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default SettlePaymentSummary;
