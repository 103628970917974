import Ajax from '@/utils/Ajax';

export const getLocation = async (text) => {
	return new Promise((resolve, reject) => {
		Ajax.searchAutocomplete(text, {
			category: 'location',
			type: 'country|locality',
			googleLimit: 5,
		})
			.done((res) => {
				resolve(res.data);
			})
			.fail((err) => {
				console.log('Search AutoComplete error', err);
				reject(err);
			});
	});
};

export const getPlace = async (data) => {
	return new Promise((resolve, reject) => {
		Ajax.getPlace(data)
			.done((res) => {
				resolve(res.data);
			})
			.fail((err) => {
				console.log('Get place error', err);
				reject(err);
			});
	});
};
