import React from 'react';
import createClass from 'create-react-class';
import _ from 'lodash';

// import SearchStore from '../../../../stores/SearchStore';

import MapStore from '../../../../stores/MapStore';

function parameterize(obj) {
	let sArr = [];
	for (const key in obj) {
		if (key === 'styles') sArr.push(parameterizeStyles(obj[key]));
		else if (key === 'hotel') sArr.push('markers=' + obj[key]);
		else sArr.push(key + '=' + obj[key]);
	}
	return sArr.join('&');
}
function parameterizeStyles(array) {
	let sArr = [];
	for (const i in array) {
		let stylers = [];
		for (const j in array[i].stylers) {
			const styleObj = array[i].stylers[j];
			const styleObjValueString = styleObj[Object.keys(styleObj)[0]] + '';
			stylers.push(Object.keys(styleObj)[0] + ':' + styleObjValueString.replace('#', '0x'));
		}
		sArr.push(
			'style=feature:' + array[i].featureType + '|element:' + array[i].elementType + '|' + stylers.join('|'),
		);
	}
	return sArr.join('&');
}

// Method to retrieve state from Stores
function getHotelMapBoxFilterState() {
	return {
		// searchTerms: SearchStore.getSearchTerms(),
		mapData: MapStore.getMapData(),
		initialMode: true,
		fullMapMode: MapStore.getFullMapMode(),
		initialFullMapMode: MapStore.getFullMapMode(),
		hotelMapDynamicMode: true,
		polyline: null,
	};
}

const HotelMapBoxFilter = createClass({
	displayName: 'HotelMapBoxFilter',

	map: null,

	destinationMarker: null,

	hotelsMarkers: [],

	renderHotelDestinationRoute: function () {
		if (this.destinationMarker) {
			// TODO: uncomment when hotel map is working
			const directionsService = new window.google.maps.DirectionsService(); // eslint-disable-line
			const directionsDisplay = new window.google.maps.DirectionsRenderer(); // eslint-disable-line
			directionsDisplay.setMap(this.map);
			const _self = this;

			directionsDisplay.setOptions({
				suppressMarkers: true,
			});

			if (this.validateHotel()) {
				directionsService.route(
					{
						origin: {
							lng: _self.props.hotel.geoJSON.coordinates[0],
							lat: _self.props.hotel.geoJSON.coordinates[1],
						},
						destination: _self.destinationMarker.getPosition(),
						travelMode: this.props.hotel.walking_distance ? 'WALKING' : 'DRIVING',
					},
					function (response, status) {
						if (status === 'OK') {
							directionsDisplay.setDirections(response);
						} else {
							console.error('Directions request failed due to ' + status);
						}
					},
				);
			}
		}
	},

	// Get initial state from stores
	getInitialState: function () {
		return getHotelMapBoxFilterState();
	},
	/*
    UNSAFE_componentWillMount: function () {
      const center = this.getDestination();
      const hotel = this.getHotelDestination();
      axios.get(`https://maps.googleapis.com/maps/api/directions/json?origin=` + center + `&destination=` + hotel + `&mode=driving&key=${process.env.REACT_APP_GOOGLE_API_KEY}`)
      .then((result) => {
        const points = result.data.routes[0].overview_polyline.points;
        console.log("COMPONENT WILL Mount messages : ", messages);
        this.setState({
          polyline: points
        })
      })
    },*/

	// Add change listeners to stores
	componentDidMount: function () {
		this.initMapWithDestination();
		if (this.props.mode === 'hotel') {
			this.renderHotelDestinationRoute();
		}
		this.forceUpdate();
	},

	isValidSearchTermsCoordinates: function (search_terms) {
		return search_terms && search_terms.destination && search_terms.destination.place;
	},

	componentDidUpdate: function (prevProps) {
		// if ((!this.validateHotel(prevProps.hotel) && this.validateHotel(this.props.hotel))
		//   || (!this.isValidSearchTermsCoordinates(prevProps.searchTerms) && this.isValidSearchTermsCoordinates(this.props.searchTerms))) {
		//   this.initMapWithDestination();
		//   if (this.props.mode === "hotel") {
		//     this.renderHotelDestinationRoute();
		//   }
		//   this.forceUpdate();
		// }
	},

	//Render our child components, passing state via props
	render: function () {
		if (!this.props.hotel || !this.props.hotel.geoJSON) {
			return null;
		}

		if (this.map?.zoom > 17) {
			this.map.setZoom(17);
		}

		window.mapbox = this;
		const mapBoxStyle = {};

		//static:
		const baseUrl = 'https://maps.googleapis.com/maps/api/staticmap?';
		//const CENTER = this.state.hotelData.geoJSON.coordinates[1] + ',' + this.state.hotelData.geoJSON.coordinates[0];
		const CENTER = this.getDestination();
		const HOTEL_CENTER = this.getHotelDestination();
		// const CENTER = this.state.mapData.lastCenter;
		//const CENTER = "Brooklyn+Bridge,New+York,NY";
		const uri =
			baseUrl +
			parameterize({
				styles: this.getMapStyles(),
				center: CENTER, //"Brooklyn+Bridge,New+York,NY",
				zoom: this.state.mapData.lastZoom || 14, //13,//15,
				size: '594x294', //"848x420",//"248x112",//"264x228",
				//markers: "color:blue%7Clabel:S%7C40.702147,-74.015794",
				markers: 'icon:https://i.pinimg.com/originals/f2/57/78/f25778f30e29a96c44c4f72ef645aa63.png|' + CENTER,
				hotel: 'icon:https://i.imgur.com/CmbiFWN.png|' + HOTEL_CENTER.lat + ',' + HOTEL_CENTER.lng,
				maptype: 'roadmap',
				//path: "path=enc%3A" + this.state.polyline,
				key: process.env.REACT_APP_GOOGLE_API_KEY,
			});
		//uri = `https://maps.googleapis.com/maps/api/staticmap?center=Brooklyn+Bridge,New+York,NY&zoom=13&size=600x300&maptype=roadmap&markers=color:blue%7Clabel:S%7C40.702147,-74.015794&markers=color:green%7Clabel:G%7C40.711614,-74.012318&markers=color:red%7Clabel:C%7C40.718217,-73.998284&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
		const MAP_URL = encodeURI(uri);

		if (this.state.hotelMapDynamicMode) {
			return (
				<div className="filter-container map-container">
					<div className="filter map-filter" id="hotel-map-box" style={mapBoxStyle}></div>
				</div>
			);
		} else {
			return (
				<div className="filter-container map-container map-hover-layer" onClick={this.activateMap}>
					<img src={MAP_URL} alt="" />
					<div className="map-overlay">
						<div className="map-overlay-text">Click to zoom the map</div>
					</div>
				</div>
			);
		}
	},

	validateHotel: function () {
		return (
			this.props.hotel &&
			this.props.hotel.geoJSON &&
			Array.isArray(this.props.hotel.geoJSON.coordinates) &&
			this.props.hotel.geoJSON.coordinates.length === 2
		);
	},

	getHotelDestination: function () {
		return {
			lng: this.props.hotel.geoJSON.coordinates[0],
			lat: this.props.hotel.geoJSON.coordinates[1],
		};
	},

	getMapStyles: function () {
		return [
			// hide Ecuator line and date line
			{
				featureType: 'administrative',
				elementType: 'geometry',
				stylers: [
					{
						visibility: 'off',
					},
				],
			},
			// // change label color
			// {
			//   "featureType": "administrative",
			//   "elementType": "labels.text.fill",
			//   "stylers": [
			//     {
			//       "color": "#999"
			//     }
			//   ]
			// },
			// show country borders
			{
				featureType: 'administrative.country',
				elementType: 'geometry',
				stylers: [
					{
						visibility: 'on',
					},
				],
			},
			// change color for country borders
			{
				featureType: 'administrative.country',
				elementType: 'geometry.stroke',
				stylers: [
					{
						color: '#CCCCCC',
					},
				],
			},
			// set ground color
			{
				featureType: 'landscape',
				elementType: 'all',
				stylers: [
					{
						color: '#f5f5f5',
					},
				],
			},
			// hide points of interests
			{
				featureType: 'poi',
				elementType: 'all',
				stylers: [
					{
						visibility: 'off',
					},
				],
			},
			// show attraction, park and medical
			{
				featureType: 'poi.attraction',
				elementType: 'all',
				stylers: [
					{
						visibility: 'on',
					},
				],
			},
			{
				featureType: 'poi.park',
				elementType: 'all',
				stylers: [
					{
						visibility: 'on',
					},
				],
			},
			{
				featureType: 'poi.medical',
				elementType: 'all',
				stylers: [
					{
						visibility: 'on',
					},
				],
			},
			// make roads grayscale
			{
				featureType: 'road',
				elementType: 'all',
				stylers: [
					{
						saturation: -100,
					},
				],
			},
			// make highways simple
			{
				featureType: 'road.highway',
				elementType: 'all',
				stylers: [
					{
						visibility: 'simplified',
					},
				],
			},
			// hide arterial roads
			{
				featureType: 'road.arterial',
				elementType: 'labels.icon',
				stylers: [
					{
						visibility: 'off',
					},
				],
			},
			// // hide transit roads
			// {
			//   "featureType": "transit",
			//   "elementType": "all",
			//   "stylers": [
			//     {
			//       "visibility": "on"
			//     }
			//   ]
			// },
			{
				featureType: 'transit.line',
				elementType: 'geometry',
				stylers: [{ visibility: 'off' }],
			},
			// set water color
			{
				featureType: 'water',
				elementType: 'geometry',
				stylers: [
					{
						color: '#3498db',
					},
					{
						visibility: 'on',
					},
				],
			},
		];
	},

	getDestination: function () {
		let destLat = null,
			destLng = null;

		if (!this.props.searchTerms.destination || !this.props.searchTerms.destination.place) {
			console.log('no destination');
			// return;
		} else {
			destLat =
				_.get(this.props.searchTerms, 'destination.place.geometry.location.lat') ||
				_.get(this.props.searchTerms, 'destination.place.location.lat');

			if (_.isFunction(destLat)) {
				destLat = destLat();
			}

			destLng =
				_.get(this.props.searchTerms, 'destination.place.geometry.location.lng') ||
				_.get(this.props.searchTerms, 'destination.place.location.lng');

			if (_.isFunction(destLng)) {
				destLng = destLng();
			}

			if (_.isNumber(destLat) && _.isNumber(destLng)) {
				return `${destLat},${destLng}`;
			} else {
				return _.get(this.props.searchTerms, 'destination.address');
			}
		}
	},

	activateMap: function () {
		setImmediate(() => {
			this.setState({
				hotelMapDynamicMode: true,
			});
			this.initMapWithDestination();
			if (this.props.mode === 'hotel') {
				this.renderHotelDestinationRoute();
			}
			this.forceUpdate();
		});
	},

	initMapWithDestination: function () {
		const _self = this;
		let destLat = null,
			destLng = null,
			destAddress = null;

		if (!this.props.searchTerms.destination || !this.props.searchTerms.destination.place) {
			console.log('no destination');
			// return;
		} else {
			destLat =
				_.get(this.props.searchTerms, 'destination.place.geometry.location.lat') ||
				_.get(this.props.searchTerms, 'destination.place.location.lat');
			if (_.isFunction(destLat)) {
				destLat = destLat();
			}

			destLng =
				_.get(this.props.searchTerms, 'destination.place.geometry.location.lng') ||
				_.get(this.props.searchTerms, 'destination.place.location.lng');
			if (_.isFunction(destLng)) {
				destLng = destLng();
			}

			destAddress = _.get(this.props.searchTerms, 'destination.address');
		}

		const mapBoxEl = document.getElementById('hotel-map-box');

		let center;
		if (_self.state.mapData.lastCenter || (destLat && destLng)) {
			center = {
				lat: destLat,
				lng: destLng,
			};
		} else if (this.validateHotel()) {
			center = {
				lng: _self.props.hotel.geoJSON.coordinates[0],
				lat: _self.props.hotel.geoJSON.coordinates[1],
			};
		}

		if (!center || !window.google) {
			return null;
		}

		this.map = new window.google.maps.Map(mapBoxEl, {
			// eslint-disable-line
			center: center,
			zoom: _self.state.mapData.lastZoom || 14,
			disableDefaultUI: true,

			zoomControl: _self.props.mode === 'hotel' || _self.state.fullMapMode,
			mapTypeControl: false,
			scaleControl: false,
			streetViewControl: false,
			rotateControl: false,
			fullscreenControl: false,

			disableDoubleClickZoom: _self.props.mode !== 'hotel' && !_self.state.fullMapMode,
			draggable: _self.props.mode === 'hotel' || _self.state.fullMapMode,
			scrollwheel: _self.state.fullMapMode,

			clickableIcons: _self.props.mode === 'hotel' || _self.state.fullMapMode,

			styles: this.getMapStyles(),
		});

		if (destLat && destLng) {
			this.destinationMarker = new window.google.maps.Marker({
				// eslint-disable-line
				position: {
					lat: destLat,
					lng: destLng,
				},
				map: _self.map,
				icon: '/img/icons/destination-36x36.svg',
				zIndex: window.google.maps.Marker.MAX_ZINDEX + 1, // eslint-disable-line
				title: destAddress,
				clickable: false,
			});
			this.destinationMarker.setMap(this.map);
		}

		// Clear out the old markers.
		// this.hotelsMarkers.forEach(function (marker) {
		//   marker.setMap(null);
		// });
		this.hotelsMarkers = [];

		//create empty LatLngBounds object
		const bounds = new window.google.maps.LatLngBounds(); // eslint-disable-line

		// HOTEL MODE

		if (this.validateHotel()) {
			this.hotelsMarkers.push(
				new window.google.maps.Marker({
					// eslint-disable-line
					position: {
						lng: _self.props.hotel.geoJSON.coordinates[0],
						lat: _self.props.hotel.geoJSON.coordinates[1],
					},
					map: _self.map,
					icon: '/img/icons/hotel-16x16.svg',
					title: _self.props.hotel.name,
					clickable: _self.props.mode !== 'hotel' && _self.state.fullMapMode,
				}),
			);

			//extend the bounds to include each marker's position
			bounds.extend(this.hotelsMarkers[0].position);
		}

		if (this.destinationMarker) {
			bounds.extend(this.destinationMarker.position);
		} else {
			this.hotelsMarkers[0].setMap(this.map);
		}

		//now fit the map to the newly inclusive bounds
		this.map.fitBounds(bounds);

		this.forceUpdate();
	},
});

export default HotelMapBoxFilter;
