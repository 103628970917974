import React from 'react';

import FiltersActions from '../../../../actions/FiltersActions';

import Slider from '@mui/material/Slider';

import FiltersConstants from '../../../../constants/FiltersConstants';
import Analytics from 'arbitrip-common/client/analytics';

const sliderId = 'hotel-distance-input';

let timer = 0;

class DistanceFilter extends React.Component {
	constructor(props) {
		super(props);

		this.handleChange = this.handleChange.bind(this);
		this.handleChangeCommited = this.handleChangeCommited.bind(this);
	}

	handleChange(event, newValue) {
		console.log('slide', newValue);

		if (Number(newValue) !== Number(this.props.filter)) {
			FiltersActions.filterByDistance(newValue);
			setTimeout(() => {
				FiltersActions.filterByDistance(newValue);
			}, 10);
		}
	}

	handleChangeCommited(event, newValue) {
		const { profile } = this.props;

		clearTimeout(timer);
		console.log('stop', newValue);

		const filtered_value = newValue + 'km';
		Analytics.actions.interactions.filteredResults(
			profile,
			FiltersConstants.FILTER_METHOD.DISTANCE,
			filtered_value,
		);

		if (Number(newValue) !== Number(this.props.filter)) {
			FiltersActions.filterByDistance(newValue);
			setTimeout(() => {
				FiltersActions.filterByDistance(newValue);
			}, 10);
		}
	}

	onMaxDistanceChange(e) {
		const distance = e.target.value;

		if ((distance === '' || !isNaN(parseFloat(distance))) && Number(this.props.filter) !== Number(distance)) {
			FiltersActions.filterByDistance(distance);
			setTimeout(() => {
				FiltersActions.filterByDistance(distance);
			}, 10);
		}
	}

	onClearClick() {
		if (this.props.filter) {
			FiltersActions.filterByDistance(0);
			setTimeout(() => {
				FiltersActions.filterByDistance(0);
			}, 10);
		}
	}

	render() {
		return (
			<div className="filter">
				<label className="filter-title" htmlFor={sliderId}>
					Max Distance from Destination
				</label>
				<div className="input-container">
					<Slider
						className="slider"
						id={sliderId}
						value={this.props.filter}
						onChange={this.handleChange}
						onChangeCommitted={this.handleChangeCommited}
						aria-label="Max Distance From Destination"
						aria-valuetext={`${this.props.filter} km`}
						step={0.5}
						min={0.5}
						max={FiltersConstants.MAXIMUM_FILTERED_DISTANCE_IN_KM}
					/>
				</div>

				<div className="distance-description">{this.props.filter + 'km'}</div>
			</div>
		);
	}
}

export default DistanceFilter;
