import _ from 'lodash';

import withStyles from '@mui/styles/withStyles';

import {
	Paper,
	Toolbar,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TableFooter,
	TablePagination,
	TableSortLabel,
	Fab,
	Typography,
	Tooltip,
	Input,
	InputAdornment,
	Avatar,
} from '@mui/material';

import { Add as AddIcon, Search as SearchIcon } from '@mui/icons-material';

import UsersManagementConstants from '../../../constants/UsersManagementConstants';

import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import UsersManagementService from './UsersManagementService';
import ProfileStore from '../../../stores/ProfileStore';
import UsersManagementActions from '../../../actions/UsersManagementActions';
import UsersManagementStore from '../../../stores/users-management/UsersManagementStore';

import NewUserDialog from './NewUserDialog.react';
import Oops from '../../general/Oops.react';
import PageLoader from '../../general/PageLoader.react';
import SnackbarMessage from '../../general/snackbar/SnackbarMessage.react';
import SimpleActionMenu from '../../general/SimpleActionMenu.react';
import TitleManager from '../../../utils/TitleManager';
import TablePaginationActions from './TablePaginationActions.react';
import DocumentTitle from '../../general/DocumentTitle.react';
import { avatarProps } from '../../../utils/AvatarUtils';

const styles = () => ({
	actions: {
		maxWidth: '80px',
	},
	loader: {
		position: 'absolute',
		top: 120,
		left: 0,
		right: 0,
	},
	search: {
		width: 180,
	},
});

const actionsStyles = (theme) => ({
	root: {
		flexShrink: 0,
		color: theme.palette.text.secondary,
		marginLeft: 20,
	},
});

const TablePaginationActionsWrapped = withStyles(actionsStyles, { withTheme: true })(TablePaginationActions);

const rows = [
	{ id: UsersManagementConstants.USER_MANAGEMENT_ROWS.FULL_NAME, label: 'Name', sort: true },
	{ id: UsersManagementConstants.USER_MANAGEMENT_ROWS.EMAIL, label: 'Email', sort: true },
	{ id: UsersManagementConstants.USER_MANAGEMENT_ROWS.ROLE, label: 'Role', sort: true },
	{ id: UsersManagementConstants.USER_MANAGEMENT_ROWS.STATUS, label: 'Status', sort: true },
	{ id: UsersManagementConstants.USER_MANAGEMENT_ROWS.APPROVER, label: 'Approver', sort: true },
];

class UsersManagementPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = this.getInitialState();

		this.onCloseDialog = this.onCloseDialog.bind(this);
		this.onSaveUser = this.onSaveUser.bind(this);
		this.openUserModal = this.openUserModal.bind(this);
		this.editUser = this.editUser.bind(this);
		this.resetPassword = this.resetPassword.bind(this);
		this.toggleActivation = this.toggleActivation.bind(this);
		this.inviteUser = this.inviteUser.bind(this);
		this.closeSnackbar = this.closeSnackbar.bind(this);
		this.handleChangePage = this.handleChangePage.bind(this);
		this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
		this.search = this.search.bind(this);
	}

	getInitialState() {
		return {
			profile: ProfileStore.getProfile(),
			users: UsersManagementStore.getUsers(),
			filteredUsers: UsersManagementStore.getUsers(),
			status: UsersManagementStore.getStatus(),
			orderBy: UsersManagementConstants.USER_MANAGEMENT_ROWS.FULL_NAME,
			orderDirection: UsersManagementConstants.SORT_DIRECTION.ASC,
			newUserModalOpen: false,
			snackbar: {
				open: false,
				message: null,
				variant: 'info',
			},
			userLoading: null,
			user: null,
			page: 0,
			rowsPerPage: 30,
			search: '',
		};
	}

	sortHandler = (property) => () => {
		const orderBy = property;
		let orderDirection = UsersManagementConstants.SORT_DIRECTION.DESC;

		if (
			this.state.orderBy === property &&
			this.state.orderDirection === UsersManagementConstants.SORT_DIRECTION.DESC
		) {
			orderDirection = UsersManagementConstants.SORT_DIRECTION.ASC;
		}

		this.setState({ orderDirection, orderBy });
	};

	handleChangePage = (event, page) => {
		this.setState({ page });
	};

	handleChangeRowsPerPage = (event) => {
		this.setState({ page: 0, rowsPerPage: parseInt(event.target.value) });
	};

	closeSnackbar() {
		let snackbar = {
			open: false,
			message: this.state.snackbar.message,
			variant: this.state.snackbar.variant,
		};
		this.setState({ snackbar });
	}

	openSnackbar(message, variant) {
		let snackbar = {
			open: true,
			message,
			variant: variant ? variant : 'info',
		};
		this.setState({ snackbar });
	}

	editUser(user) {
		this.openUserModal(user);
	}

	resetPassword(user) {
		this.startActionLoader(user);
		UsersManagementActions.resetPassword({ user: user })
			.done(() => {
				this.stopActionLoader();
				this.openSnackbar(UsersManagementConstants.MESSAGES.RESET_PASSWORD, 'success');
			})
			.fail((err) => {
				console.log(err);
				this.stopActionLoader();
				this.openSnackbar(UsersManagementConstants.ERRORS.RESET_PASSWORD, 'error');
			});
	}

	toggleActivation(user) {
		this.startActionLoader(user);
		let data = {
			id: user.id,
			active: !user.active,
		};

		UsersManagementActions.activateUser({ user: data })
			.done(() => {
				this.stopActionLoader();
				user.active = !user.active;

				let message = user.active
					? UsersManagementConstants.MESSAGES.ACTIVE_USER
					: UsersManagementConstants.MESSAGES.SUSPEND_USER;
				this.openSnackbar(message, 'success');
			})
			.fail((err) => {
				console.log(err);
				this.stopActionLoader();
				let message = user.active
					? UsersManagementConstants.ERRORS.ACTIVE_USER
					: UsersManagementConstants.ERRORS.SUSPEND_USER;
				this.openSnackbar(message, 'error');
			});
	}

	startActionLoader(user) {
		this.setState({ userLoading: user.id });
	}

	stopActionLoader() {
		this.setState({ userLoading: null });
	}

	inviteUser(user) {
		this.startActionLoader(user);
		UsersManagementActions.inviteUser({ user: user })
			.done(() => {
				this.stopActionLoader();
				this.openSnackbar(UsersManagementConstants.MESSAGES.INVITATION_SENT, 'success');
			})
			.fail((err) => {
				console.log(err);
				this.stopActionLoader();
				this.openSnackbar(UsersManagementConstants.ERRORS.INVITATION_SENT, 'error');
			});
	}

	onCloseDialog() {
		this.setState({ newUserModalOpen: false });
	}

	openUserModal(user = null) {
		// if user an event
		if (user.target) user = null;

		this.setState({ newUserModalOpen: true, user: user });
	}

	onSaveUser(data) {
		this.onCloseDialog();

		if (!data) {
			setTimeout(() => {
				this.openSnackbar(UsersManagementConstants.MESSAGES.EDIT_USER, 'success');
			});
			return;
		}

		let userExists = false;
		let users = this.state.users.slice();
		let userId = data.user.id || data.user._id;

		users.forEach((user) => {
			if (user.id === userId) {
				userExists = true;
				user = Object.assign(user, data.user);
			}
		});

		if (userExists) {
			let filteredUsers = UsersManagementService.getFilteredUsers(users, this.state.search);
			this.setState({ users, filteredUsers });
			setTimeout(() => {
				this.openSnackbar(UsersManagementConstants.MESSAGES.EDIT_USER, 'success');
			});
		} else {
			this.setState({ search: '' });
			UsersManagementActions.addNewUserToUsers(data.user);
			setTimeout(() => {
				this.openSnackbar(UsersManagementConstants.MESSAGES.NEW_USER, 'success');
			});
		}
	}

	getUserStatus(user) {
		return UsersManagementService.getUserStatus(user);
	}

	search(event) {
		let { users } = this.state;
		let search = event.currentTarget.value;

		let filteredUsers = UsersManagementService.getFilteredUsers(users, search);

		this.setState({ search, filteredUsers });
	}

	getActionsRender(user) {
		let { userLoading } = this.state;
		let isLoading = user.id === userLoading;

		let menu = [{ label: 'Edit User', action: () => this.editUser(user) }];

		let userMenu = [];

		if (user.verified) {
			if (user.active) {
				userMenu.push({ label: 'Reset Password', action: () => this.resetPassword(user) });
			}

			userMenu.push({
				label: user.active ? 'Suspend User' : 'Activate User',
				action: () => this.toggleActivation(user),
			});
		} else {
			userMenu.push({ label: 'Invite User', action: () => this.inviteUser(user) });
		}

		menu = menu.concat(userMenu);
		let classes = classNames({ 'action-menu': true, 'is-loading': isLoading });

		return (
			<div className={classes}>
				{isLoading ? (
					<div className="spinner">
						<i className="fa fa-spin fa-spinner" />
					</div>
				) : (
					<SimpleActionMenu menu={menu} />
				)}
			</div>
		);
	}

	getAgencyBranchName(user, agencyBranches) {
		const filtered = agencyBranches.filter(({ _id }) => _id === user.agency_branch);
		return filtered.length ? filtered[0].name : '-';
	}

	getFooterRender() {
		let { filteredUsers, page, rowsPerPage } = this.state;

		return (
			<TableFooter>
				<TableRow>
					<TablePagination
						rowsPerPageOptions={[10, 30, 60]}
						colSpan={10}
						count={filteredUsers.length}
						rowsPerPage={rowsPerPage}
						page={page}
						SelectProps={{
							native: true,
						}}
						onPageChange={this.handleChangePage}
						onRowsPerPageChange={this.handleChangeRowsPerPage}
						ActionsComponent={TablePaginationActionsWrapped}
					/>
				</TableRow>
			</TableFooter>
		);
	}

	getUsersTableRender() {
		let { userLoading } = this.state;
		let { classes } = this.props;
		let { filteredUsers, rowsPerPage, page, orderBy, orderDirection, profile } = this.state;
		const agencyBranches = _.get(profile, 'company.agency_branches', []);
		let sortedUsers = UsersManagementService.getSortedUsers(filteredUsers, orderDirection, orderBy, agencyBranches);
		let usersOnPage = sortedUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
		if (agencyBranches.length && rows.length !== 6) {
			rows.push({
				id: UsersManagementConstants.USER_MANAGEMENT_ROWS.BRANCH,
				label: 'Branch',
				sort: true,
			});
		}
		return (
			<Table>
				<TableHead>
					<TableRow>
						{rows.map(
							(row) => (
								<TableCell
									key={row.id}
									align="left"
									padding="normal"
									sortDirection={orderBy === row.id ? orderDirection : false}
									className="table-font"
								>
									{row.sort ? (
										<Tooltip title="Sort" placement="bottom-start" enterDelay={300}>
											<TableSortLabel
												active={orderBy === row.id}
												direction={orderDirection}
												onClick={this.sortHandler(row.id)}
											>
												{row.label}
											</TableSortLabel>
										</Tooltip>
									) : (
										<div>{row.label}</div>
									)}
								</TableCell>
							),
							this,
						)}
						<TableCell className={classes.actions + ' table-font'} />
					</TableRow>
				</TableHead>

				<TableBody>
					{usersOnPage.map((user) => (
						<TableRow
							hover={true}
							key={user.id}
							className={classNames('table-row', { loading: user.id === userLoading })}
						>
							<TableCell title={user.full_name} className="ellipsis table-font">
								<Avatar
									className="avatar"
									{...avatarProps({
										fullName: user.full_name,
										url: user.picture,
										size: 26,
									})}
								/>
								<div className="avatar-text">{user.full_name}</div>
							</TableCell>
							<TableCell title={user.email} className="ellipsis table-font">
								{user.email}
							</TableCell>
							<TableCell className="table-font">{UsersManagementService.getUserRoles(user)}</TableCell>
							<TableCell title={this.getUserStatus(user)} className="table-font">
								{this.getUserStatus(user)}
							</TableCell>
							<TableCell
								className="ellipsis table-font"
								title={UsersManagementService.getUserApprover(user)}
							>
								{UsersManagementService.getUserApprover(user)}
							</TableCell>
							{!!agencyBranches.length && (
								<TableCell
									title={this.getAgencyBranchName(user, agencyBranches)}
									className="table-font"
								>
									{this.getAgencyBranchName(user, agencyBranches)}
								</TableCell>
							)}
							<TableCell className={classes.actions + ' table-font'}>
								{this.getActionsRender(user)}
							</TableCell>
						</TableRow>
					))}
				</TableBody>

				{this.getFooterRender()}
			</Table>
		);
	}

	getFailedRender() {
		return <Oops tryAgain={UsersManagementActions.getUsers} fullName={ProfileStore.getProfile().full_name} />;
	}

	render() {
		let { classes } = this.props;
		let { profile, status, newUserModalOpen, user, search, users } = this.state;
		let loading = status === UsersManagementConstants.STATUS.BUSY;
		let onTop = users && users.length > 0;
		const company_name = _.get(profile, 'company.name');
		const title = TitleManager.buildTitleStartsWith(`${company_name ? company_name + "'s " : ''}Users Management`);

		return (
			<DocumentTitle title={title}>
				<div className={'users-management-page-wrapper' + (loading && onTop ? ' blur cursor-progress' : '')}>
					<div className="users-management-page">
						{status === UsersManagementConstants.STATUS.FAILED ? (
							this.getFailedRender()
						) : loading && !onTop ? (
							<div className={classes.loader}>
								<PageLoader />
							</div>
						) : (
							<React.Fragment>
								<Paper className="paper">
									{loading && onTop && <PageLoader onTop={true} />}
									<Toolbar className="toolbar">
										<div className="add-user">
											<Tooltip title="New user">
												<Fab
													onClick={this.openUserModal}
													size="medium"
													color="secondary"
													aria-label="Add"
												>
													<AddIcon />
												</Fab>
											</Tooltip>
										</div>
										<div className="title">
											<Typography variant="h6" id="tableTitle">
												Users
											</Typography>
										</div>
										<div className="flex-spacer" />
										<div className="search">
											<Input
												id="search-input"
												className={classes.search}
												value={search}
												onChange={this.search}
												label="Find users"
												placeholder="e.g. John Smith"
												startAdornment={
													<InputAdornment position="start">
														<SearchIcon />
													</InputAdornment>
												}
											/>
										</div>
									</Toolbar>

									{this.getUsersTableRender()}
								</Paper>
							</React.Fragment>
						)}

						<NewUserDialog
							user={user}
							isNewUser={!user}
							profile={profile}
							open={newUserModalOpen}
							onClose={this.onCloseDialog}
							onSave={(data) => this.onSaveUser(data)}
							onMessage={(message, variant) => this.openSnackbar(message, variant)}
						/>

						{this.state.snackbar && (
							<SnackbarMessage
								open={this.state.snackbar.open}
								onClose={this.closeSnackbar}
								message={this.state.snackbar.message || ''}
								variant={this.state.snackbar.variant}
							/>
						)}
					</div>
				</div>
			</DocumentTitle>
		);
	}

	componentDidMount() {
		UsersManagementStore.addChangeListener(this._onChange);

		setTimeout(() => {
			UsersManagementActions.getUsers();
		});
	}

	componentWillUnmount() {
		UsersManagementStore.removeChangeListener(this._onChange);
	}

	_onChange = () => {
		if (this.state.newUserModalOpen) {
			return;
		}
		this.setState(this.getInitialState());
	};
}

UsersManagementPage.propTypes = {
	classes: PropTypes.object,
};

export default withStyles(styles)(UsersManagementPage);
