import moment from 'moment';
import _ from 'lodash';
import { EventEmitter } from 'events';
import SearchSessionService from '../utils/SearchSessionService';
import RoomMapping from "./RoomMapping";

import { HotelConstants } from '../constants';

import SearchResults from './SearchResults';

import TokenGenerator from '../utils/TokenGenerator';
import FirebaseManager from '../utils/Firebase/Manager';
import OfflineDB from '../utils/OfflineDB';

import {
    RESULTS_EXPIRATION_TIMEOUT_MILLIS,
    SEARCH_SESSION_EVENT
} from '../constants/SearchSessionConstants';

import Timer from '../utils/Timer';

const delay_timeout = process.env.REACT_APP_RESULTS_DELAY_IN_SECONDS
    ? Number(process.env.REACT_APP_RESULTS_DELAY_IN_SECONDS) * 1000
    : 4500;

function reformatDate(d) {
    return moment.utc(d).format('YYYY-MM-DD');
}

function cloneSearchSessionForOfflineDB(searchSession) {
    let offline_db_search_session = _.cloneDeep(_.pick(searchSession, ['created_timestamp', 'search_terms', 'arbitrip_points_applied']));

    _.set(offline_db_search_session, 'search_terms.check_in', reformatDate(_.get(searchSession, 'search_terms.check_in')));
    _.set(offline_db_search_session, 'search_terms.check_out', reformatDate(_.get(searchSession, 'search_terms.check_out')));

    return offline_db_search_session;
}

class SearchSession extends EventEmitter {
    /**
     *
     * @param {*} searchToken
     * @param {*} searchTerms
     * @param {*} recommendationWeights
     * @param {*} options {created_timestamp, travel_policy}
     */
    constructor(searchToken, searchTerms, recommendationWeights, options = {}) {
        super();

        this.created_timestamp = (options.created_timestamp)
            ? moment(options.created_timestamp).toDate()
            : new Date();
        this.setExpirationTimer();

        this.search_token = searchToken || TokenGenerator.generateParameterizedToken(searchTerms);
        this.search_terms = searchTerms;

        this.search_results = new SearchResults(recommendationWeights, _.pick(options, ['travel_policy', 'disableThrottle']));

        if (options.completed_delay) {
            this.search_results.completed_delay = true;
        }
        if (options.from_offline_db) {
            // TODO: in case re-listening after offline db is implemented, update accordingly
            this.search_results.completed_listening = true;
        }

        if (_.isBoolean(options.arbitrip_points_applied)) {
            this.arbitrip_points_applied = options.arbitrip_points_applied;
        }

        const room_mapping_settings = _.get(options, 'room_mapping_settings', {});
        this.room_mapping = new RoomMapping(room_mapping_settings);

        this._search_results_delay_in_seconds = _.isNumber(options.search_results_delay_in_seconds)
            ? Number(options.search_results_delay_in_seconds)
            : delay_timeout;

        this._search_listener_id = null;
        this._hotels_recheck_listener_ids = {};
        this.banned_deal_ids = options.banned_deal_ids ?? [];
        this.reservation_attempts = options.reservation_attempts ?? 0;

        const offline_session = cloneSearchSessionForOfflineDB(this);
        OfflineDB.setSearchSession(searchToken, offline_session); // consider _search_listener_id as well
        OfflineDB.setRoomMappingSettings(searchToken, room_mapping_settings);
    }

    notifyEvent(event, param) {
        this.search_results.timestamp = new Date();
        this.emit(SEARCH_SESSION_EVENT.CHANGED);

        if (event) {
            param ? this.emit(event, param) : this.emit(event);
        }
    }

    toggleArbitripPointsApplied(applied) {
        this.arbitrip_points_applied = applied;

        const offline_session = cloneSearchSessionForOfflineDB(this);
        OfflineDB.setSearchSession(this.search_token, offline_session); // consider _search_listener_id as well
    }

    setExpirationTimer() {
        const _self = this;
        const expiration_timestamp = this.created_timestamp.getTime() + RESULTS_EXPIRATION_TIMEOUT_MILLIS;
        this.expiration_timer = new Timer(expiration_timestamp - Date.now(), () => {
            _self.emit(SEARCH_SESSION_EVENT.EXPIRED, _self.search_token);
        });
        this.expiration_timer.restart();
    }


    // + shallow flag [might be useful for other flows at O(1)]
    // + hotels (on inner SearchResults instance)
    onHotels(hotelsResponse = {}) {
        const { hotels } = hotelsResponse;
        const shallow = true;

        this.search_results.addHotels(hotels, shallow);
        this.search_results.completed_hotels = true;

        this.search_results.recalculateRecommendationScore();

        this.emit(SEARCH_SESSION_EVENT.CHANGED);
        this.emit(SEARCH_SESSION_EVENT.HOTELS);

        OfflineDB.setSearchShallows(this.search_token, hotels);
    }

    stopSearchListener() {
        FirebaseManager.search.unsubscribe(this._search_listener_id);
    }

    onSearch(searchResponse = {}) {
        const {
            hotels,
            listener_id
        } = searchResponse;
        let { travel_policy } = searchResponse;

        const shallow = false;
        const onSearchData = SearchSessionService.getOnSearchDataCallback(this, listener_id);
        const onSearchError = SearchSessionService.getOnSearchErrorCallback(this, listener_id);
        const onSearchTimeout = SearchSessionService.getOnSearchTimeoutCallback(this, listener_id);

        this.search_results.travel_policy = travel_policy; // uses c'tor in SearchResults travel_policy setter
        this.search_results.addHotels(hotels, shallow);

        this.search_results.completed_search = true;

        this.search_results.recalculateRecommendationScore();

        this.emit(SEARCH_SESSION_EVENT.CHANGED);
        this.emit(SEARCH_SESSION_EVENT.SEARCH);

        this._search_listener_id = listener_id;
        FirebaseManager.search.subscribe(listener_id, onSearchData, onSearchError, onSearchTimeout);

        const _self = this;
        setTimeout(() => {
            _self.search_results.completed_delay = true;
            _self.emit(SEARCH_SESSION_EVENT.CHANGED);
        }, this._search_results_delay_in_seconds);

        OfflineDB.setSearchTravelPolicy(this.search_token, travel_policy);
        OfflineDB.setSearchHotels(this.search_token, hotels);
    }

    cleanHotelDeals(hotel_id) {
        this.search_results.cleanHotelDeals(hotel_id);
    }

    onHotelDeals(hotelDealsResponse = {}) {
        const {
            hotel_id,
            travel_policy
        } = hotelDealsResponse;
        const hotel = this.search_results.getHotelById(hotel_id);
        const hotels = Object.keys(this.search_results.getHotels()).length;

        if (!hotel && hotels === 0) {
            // when there are no hotels in results
            // add the hotel manually
            const raw_hotels_array = [{ _id: hotel_id }];
            const shallow = true;
            this.search_results.addHotels(raw_hotels_array, shallow);
            OfflineDB.setSearchHotels(this.search_token, raw_hotels_array);
        } else if (!hotel && hotels > 0) {
            // if there are results, but hotel not found
            console.log('onHotelDeals:: No hotel found');
            return;
        }

        this.search_results.travel_policy = travel_policy;
        OfflineDB.setSearchTravelPolicy(this.search_token, travel_policy);

        this.onRecheck(hotelDealsResponse);
    }

    stopAllRecheckListener() {
        for (const hotel_id in this._hotels_recheck_listener_ids) {
            this.stopRecheckListener(hotel_id);
        }
    }

    stopRecheckListener(hotelId) {
        const hotel_recheck_listener_id = this._hotels_recheck_listener_ids[hotelId];
        FirebaseManager.recheck.unsubscribe(hotel_recheck_listener_id);
    }

    onRecheck(recheckResponse = {}) {
        const {
            hotel_id,
            listener_id,
            wait_time
        } = recheckResponse;
        const hotel = this.search_results.getHotelById(hotel_id);
        if (listener_id) {
            hotel.RecheckStatus = HotelConstants.RECHECK_STATUS.BUSY;
            hotel.recheck_timestamp = Date.now();

            const onRecheckData = SearchSessionService.getOnRecheckDataCallback(this, hotel_id, listener_id);
            const onRecheckError = SearchSessionService.getOnRecheckErrorCallback(this, hotel_id, listener_id);
            const onRecheckTimeout = SearchSessionService.getOnRecheckTimeoutCallback(this, hotel_id, listener_id);

            this._hotels_recheck_listener_ids[hotel_id] = listener_id;

            FirebaseManager.recheck.subscribe(listener_id, onRecheckData, onRecheckError, onRecheckTimeout, wait_time);
        } else {
            console.warn(`onRecheck got no listener id (hotel_id = ${hotel_id})`);
            hotel.RecheckStatus = HotelConstants.RECHECK_STATUS.FAIL;
        }
    }

    static isValid(searchSession) {
        const search_token = _.get(searchSession, 'search_token');

        if (!search_token) {
            console.warn('SearchSession.isValid search_token is invalid:', search_token);
            return false;
        }

        return true;
    }

    clearAllListeners() {
        const event_names = this.eventNames();
        for (const event_name of event_names) {
            this.removeAllListeners(event_name)
        }
    }

    clear() {
        this.clearAllListeners();

        if (this.expiration_timer) {
            this.expiration_timer.stop();
        }
    }

    updateSearchTerms(searchToken, searchTerms) {
        this.search_terms = searchTerms;
        const offline_session = cloneSearchSessionForOfflineDB(this);
        OfflineDB.setSearchSession(searchToken, offline_session); // consider _search_listener_id as well
    }
}

export default SearchSession;
