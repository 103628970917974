const _ = require('lodash');
const keyMirror = require('keymirror');
const moment = require('moment');

const DEFAULT_RESULTS_LOADING_LIMIT_IN_SECONDS = 30;

const MIN_DURATION_NUMBER_OF_NIGHTS = 1;
const MAX_DURATION_NUMBER_OF_NIGHTS = 30;

let MAX_NUMBER_OF_ROOMS = 5;
if (typeof document === 'undefined') {
    MAX_NUMBER_OF_ROOMS = 5; // When running in nodeJs there is no document object
} else {
    const queryString = require('query-string');
    // eslint-disable-next-line no-undef
    MAX_NUMBER_OF_ROOMS = '8_rooms' in queryString.parse(document.location.search) ? 8 : 5;
}

const _expire_in_minutes = process.env.REACT_APP_RESULTS_EXPIRE_IN_MINUTES || 30;
const SEARCH_CONSTANTS = {
    RESULTS_EXPIRATION_TIMEOUT_MILLIS: _expire_in_minutes * 60 * 1000,

    SHARED_ATTRIBUTES_NAMES: {
        OPTION: 'option',
        SEARCH_DATA: 'search-data',
        SEARCH_DATA_CONTENT: 'search-data-content'
    },

    FIELDS: {
        ADULTS: 'Adults',
        CHILDREN: 'Children',
        ROOMS: 'Rooms'
    },

    MIN: {
        ADULTS: 1,
        CHILDREN: 0,
        ROOMS: 1
    },
    MAX: {
        ADULTS: 4,
        ADULTS_IF_AGENT: 8,
        CHILDREN: 4,
        CHILD_AGE: 17,
        ROOMS: MAX_NUMBER_OF_ROOMS
    },
    DEFAULT_CHILD_AGE: '',

    MIN_CHECK_IN: moment.duration({ 'days': 0 }),
    MAX_CHECK_IN: moment.duration({ 'years': 1 }),

    MIN_DURATION_NUMBER_OF_NIGHTS,
    MIN_DURATION: moment.duration({ 'days': MIN_DURATION_NUMBER_OF_NIGHTS }),

    MAX_DURATION_NUMBER_OF_NIGHTS,
    MAX_DURATION: moment.duration({ 'days': MAX_DURATION_NUMBER_OF_NIGHTS }),

    MIN_SEARCH_RESULTS_HOTEL_DEALS: 15,
    MAX_SEARCH_RESULTS_HOTEL_DEALS: 30,

    SEARCH_TYPE_FAST: 'fast', // obsolete
    SEARCH_TYPE_NORMAL: 'normal', // obsolete
    SEARCH_TYPE_SHALLOW: 'shallow', // obsolete
    SEARCH_TYPE_HOTELS: 'hotels', // new-shallow
    SEARCH_TYPE_HOTEL_PAGE: 'hotel_page',
    SEARCH_TYPE_SELECTED_HOTELS_WITH_DEALS: 'selected_hotels_with_deals',

    SEARCH_STATUS_DONE: 'done',
    SEARCH_STATUS_FAILED: 'failed',

    RESULTS_LOADING_LIMIT_IN_SECONDS: process.env.REACT_APP_FIREBASE_SEARCH_TIMEOUT_IN_SECONDS || DEFAULT_RESULTS_LOADING_LIMIT_IN_SECONDS
};

// Define action constants
module.exports = _.extend({}, SEARCH_CONSTANTS, keyMirror({
    RECEIVE_SEARCH_TERMS_DATA: null,

    RECEIVE_SEARCH_DESTINATION_DATA: null,
    RECEIVE_SEARCH_CHECK_IN_DATA: null,
    RECEIVE_SEARCH_CHECK_OUT_DATA: null,
    RECEIVE_SEARCH_GUESTS_PER_ROOMS_DATA: null,
    RECEIVE_SEARCH_TRAVELER_DATA: null,

    UPDATE_SEARCH_DATA: null,
    UPDATE_SEARCH_DESTINATION_DATA: null,
    UPDATE_CHECK_IN: null,
    UPDATE_CHECK_OUT: null,
    UPDATE_GUESTS: null,
    UPDATE_ROOMS: null,
    UPDATE_CHILDREN_AGES: null,
    UPDATE_SEARCH_CONTRACT: null,
    UPDATE_SEARCH_CONTRACT_BY_ID: null,
    UPDATE_PRIVATE_TRAVEL: null,
    UPDATE_ALL_SEARCH_TERMS_DATA: null,
    UPDATE_ALL_SEARCH_TERMS_DATA_VIA_URL: null,
    CLEAR_CHILDREN_DATA: null,
    CLEAR_ALL_SEARCH_TERMS_DATA: null,
    SHOW_HOTEL_LOADER: null,

    SEARCH: null, // After manually searching

    FIRE_SEARCH: null,
    FIRE_SEARCH_RESULTS: null,
    FIRE_SEARCH_ERROR: null,
    CLEAR_FIRE_SEARCH: null,

    HOTELS_SEARCH: null,
    HOTELS_SEARCH_RESULTS: null,
    HOTELS_SEARCH_ERROR: null,
    CLEAR_HOTELS_SEARCH: null,

}));
