import _ from 'lodash';
import queryString from 'query-string';
import React from 'react';
import createClass from 'create-react-class';

import Button from '@mui/material/Button';

import ResultsStore from '../../../stores/ResultsStore';
import HotelStore from '../../../stores/HotelStore';
import BasketStore from '../../../stores/BasketStore';
import ProfileStore from '../../../stores/ProfileStore';
import FiltersStore from '../../../stores/FiltersStore';
import SearchStore from '../../../stores/SearchStore';

import HotelActions from '../../../actions/HotelActions';
import SearchActions from '../../../actions/SearchActions';

import HotelConstants from '../../../constants/HotelConstants';
import { HOTEL_TABS_LABELS } from './constants';
import DealConstants from '../../../constants/DealConstants';
import ExpiredResultsConstants from '../../../components/general/expired-results/constants';

import RoomsCatalog from './RoomsCatalog.react';
import RoomsCatalogDialog from './RoomsCatalogDialog.react';

import DealsStatistics from './DealsStatistics.react';
import StarsComponent from '../../StarsComponent.react';
import HotelAddress from '../hotel/HotelAddressComponent.react';
import NoAvailabilityMessage from '../hotel/NoAvailabilityMessage.react';
import HotelMapBox from '../results/filters/HotelMapBox.react';
import DealComponent from '../results/deal/DealComponent.react';
import AmenitiesComponent from './AmenitiesComponent.react';
import TripDetails from './TripDetails.react';
import HotelTabs from './HotelTabs.react';
import HotelReview from './HotelReview.react';
import HotelReasons from './HotelReasons.react';
import ExpiredResultsComponent from '../../general/expired-results/ExpiredResultsComponent.react';
import AboutComponent from './AboutComponent.react';
import TrustYouComponent from './TrustYouComponent.react';
import SupplierTerms from './SupplierTerms.react';
import ExtraInformation from './ExtraInformation.react';
import FilterDealsComponent from './FilterDealsComponent.react';
import DealsTitle from './DealsTitle.react';
import LoadMapping from './room-mapping/LoadMapping.react';
import InstallmentsBanner from '../../InstallmentsBanner.react';

import { Link } from 'react-router-dom';

import HotelImagesCollage from './HotelImagesCollage.react';
import TitleManager from '../../../utils/TitleManager';
import RouterWrapper from '../../../utils/RouterWrapper';
import Validator from 'arbitrip-common/client/utils/Validator';
import SearchTermsParser from '../../../utils/SearchTermsParser';

import DealUtils from '../../../entities/DealUtils';

import Analytics from 'arbitrip-common/client/analytics';
import Config from 'arbitrip-common/client/utils/Config';
import RoomMappingService from 'arbitrip-common/client/utils/RoomMappingService';
import DocumentTitle from '../../general/DocumentTitle.react';
import ReservationManipulation from './ReservationManipulation.react';

const { EXPIRED_TYPES } = ExpiredResultsConstants;

const _busy_recheck_display_timeout_in_seconds = process.env.REACT_APP_FIREBASE_RECHECK_TIMEOUT_IN_SECONDS || 30;
const BUSY_RECHECK_DISPLAY_TIMEOUT_IN_MILLISECONDS = _busy_recheck_display_timeout_in_seconds * 1000;

const _search_threshold_in_seconds = process.env.REACT_APP_HOTEL_SEARCH_RECHECK_DELAY_IN_SECONDS || 10;
const SEARCH_THRESHOLD_IN_MILLISECONDS = _search_threshold_in_seconds * 1000;

function getHotelPageState(hotel_id) {
	const profile = ProfileStore.getProfile();

	let contract_client_company_id = SearchStore.getContractClientCompanyId();
	if (!contract_client_company_id && profile.agent) {
		contract_client_company_id = ProfileStore.getSelfContractCompanyId();
	}

	return {
		hotel_info: HotelStore.getHotelById(hotel_id),
		hotel: ResultsStore.getHotelById(hotel_id),
		got_results: ResultsStore.didReturnMultipleResults(),
		mapBoxData: FiltersStore.getMapBoxData(),
		hotelDeals: _.sortBy(ResultsStore.getHotelIndexedDealsById(hotel_id), 'totalPrice'),
		hotel_pre_pay_deals: ResultsStore.getHotelIndexedPrePayDealsById(hotel_id),
		hotel_post_pay_deals: ResultsStore.getHotelIndexedPostPayDealsById(hotel_id),
		hotel_half_board_deals: ResultsStore.getHotelIndexedHalfBoardDealsById(hotel_id),
		hotel_all_inclusive_deals: ResultsStore.getHotelIndexedAllInclusiveDealsById(hotel_id),
		info_status: ResultsStore.getHotelInfoStatusById(hotel_id),
		deals_status: ResultsStore.getHotelDealsStatusById(hotel_id),
		recheck_status: ResultsStore.getHotelRecheckStatusById(hotel_id),
		deals_cached_status: ResultsStore.getHotelDealsCachedStatusById(hotel_id),
		travel_policy: ResultsStore.getTravelPolicy(),
		search_terms: SearchStore.getSearchTerms(),
		search_token: SearchStore.getToken(),
		deals_filter: FiltersStore.getDealsFilterData(),
		banned_deal_ids: ResultsStore.getBannedDealIds(),
		basket: BasketStore.getBasket(),
		profile,
		unified_catalog: ResultsStore.getUnifiedCatalogData(),
		recheck_timestamp: ResultsStore.getRecheckTimestamp(hotel_id),
		show_recheck_loader: true,
		arbitrip_points_applied: ResultsStore.areArbitripPointsApplied(),
		search_session: ResultsStore.getCurrentSearchSession(),
		room_mapping: ResultsStore.getRoomMappingData(),
		room_mapping_vocabulary: ResultsStore.getRoomMappingVocabulary(),
		enable_room_mapping: ResultsStore.isRoomMappingEnabled(),
		contract_client_company_id,
	};
}

const HotelPage = createClass({
	displayName: 'HotelPage',

	getInitialState: function () {
		const hotelPageState = getHotelPageState(this.props.hotelId);

		if (hotelPageState.deals_status !== HotelConstants.DEALS_STATUS.SUCCESS) {
			hotelPageState.deals_status = HotelConstants.DEALS_STATUS.INITIAL;
		}
		if (hotelPageState.recheck_status !== HotelConstants.RECHECK_STATUS.SUCCESS) {
			hotelPageState.recheck_status = HotelConstants.RECHECK_STATUS.INITIAL;
		}
		hotelPageState.max_deals_to_show = this.getMaxDeals(hotelPageState);
		hotelPageState.show_superseded_deals = false;
		hotelPageState.dynamicPriceIdFilter = '';
		hotelPageState.hide_expedia_deals = false;
		hotelPageState.show_only_expedia_deals = false;
		hotelPageState.show_only_mapping_deals = false;

		hotelPageState.showRoomDialog = false;
		hotelPageState.roomDialogData = null;
		hotelPageState.roomDialogName = null;
		hotelPageState.roomDialogAnalysticFunc = null;
		hotelPageState.showMappingDialog = false;
		hotelPageState.mappingDebugMode = false;
		hotelPageState.called_vocabulary = false;
		hotelPageState.activeTab = HOTEL_TABS_LABELS.DESCRIPTION;
		hotelPageState.reviewScoreClickCounter = 0;
		hotelPageState.reservation_manipulation = {
			is_fail_init: false,
			is_fail_recheck: false,
			increase_price: '',
		};

		return hotelPageState;
	},

	componentDidUpdate: function (prevProps, prevState) {
		let hotel_id = this.props.hotelId;
		let search_params = queryString.parse(this.props.location.search);
		let search_token = search_params.search_token;
		// let previous_cached_fail = prevState.deals_cached_status === HotelConstants.DEALS_STATUS.FAIL;
		// let current_cached_fail = this.state.deals_cached_status === HotelConstants.DEALS_STATUS.FAIL;
		let previous_cached_fail = prevState.recheck_status === HotelConstants.RECHECK_STATUS.FAIL;
		let current_cached_fail = this.state.recheck_status === HotelConstants.RECHECK_STATUS.FAIL;
		const _self = this;

		if (
			!this.state.requested_hotels &&
			Validator.validateSearchToken(search_token, SearchStore.getSignedParams()) &&
			!previous_cached_fail &&
			current_cached_fail
		) {
			setTimeout(() => {
				HotelActions.getHotelDeals(hotel_id);
				_self.setState({ requested_hotels: true });
			}, 11); // VERY BAD PRACTICE
		}

		if (!prevState.recheck_timestamp && this.state.recheck_timestamp) {
			const recheck_duration_in_milliseconds = Date.now() - this.state.recheck_timestamp;
			if (recheck_duration_in_milliseconds < BUSY_RECHECK_DISPLAY_TIMEOUT_IN_MILLISECONDS) {
				this.setState({ show_recheck_loader: true });
				this.recheck_loader_timeout = setTimeout(() => {
					_self.setState({
						show_recheck_loader: false,
					});
				}, BUSY_RECHECK_DISPLAY_TIMEOUT_IN_MILLISECONDS - recheck_duration_in_milliseconds);
			}
		}

		if (!this.state.called_vocabulary && !this.state.room_mapping_vocabulary && this.state.enable_room_mapping) {
			this.setState({ called_vocabulary: true });

			RoomMappingService.setServerVocabulary(this.state.search_session).catch((e) => {
				console.error(e);
			});
		}

		if (this.state.mappingDebugMode !== prevState.mappingDebugMode) {
			const max_deals_to_show = this.state.mappingDebugMode ? 10000 : this.getMaxDeals(this.state);
			this.setState({ max_deals_to_show });
		}

		if (prevState.hotelDeals.length !== this.state.hotelDeals.length) {
			const total =
				this.getRepresentativeDeal().length + this.getPrePayDeals().length + this.getPostPayDeals().length;
			HotelActions.setTotalDisplayedDealsCount(total);
		}

		if (prevState.recheck_status !== this.state.recheck_status) {
			if (
				this.state.recheck_status !== HotelConstants.RECHECK_STATUS.INITIAL &&
				this.state.recheck_status !== HotelConstants.RECHECK_STATUS.BUSY &&
				this.getValidDealsCount(this.state.hotelDeals) === 0
			) {
				Analytics.actions.responses.searchWithoutResultsHotel(this.state.hotel, this.state.search_terms);
			}
		}
	},

	componentDidMount: function () {
		////////////////////////////////////////////////////////////////////////////////
		// componentWillMount
		////////////////////////////////////////////////////////////////////////////////
		const { navigate, location, hotelId } = this.props;
		const { profile, search_terms } = this.state;
		const hotel = this.getHotel();
		const search_params = queryString.parse(location.search);
		const { search_token } = search_params;
		const hotel_id = _.get(hotel, 'id', hotelId);

		if (!Validator.validateObjectId(hotel_id)) {
			return RouterWrapper.goToSearchPage(navigate);
		}

		if (!_.get(hotel, 'hotelInfo')) {
			setTimeout(() => {
				HotelActions.getHotelInfo(hotel_id);
			});
		}

		let skip_recheck = this.state.recheck_status === HotelConstants.RECHECK_STATUS.SUCCESS;

		if (!skip_recheck && Validator.validateSearchToken(search_token, SearchStore.getSignedParams())) {
			const ml = ResultsStore.getTimePassedSinceSearchSessionCreatedInMilliSeconds();
			const timeout = hotel.shallow || ml < 0 ? 0 : Math.max(SEARCH_THRESHOLD_IN_MILLISECONDS - ml, 0);

			setTimeout(() => {
				if (hotel.shallow) {
					HotelActions.getHotelDeals(hotel_id);
				} else {
					HotelActions.getHotelRecheckedDeals(hotel_id, search_token);
				}
			}, timeout);
			this.setState({ requested_hotels: true });
		} else if (!skip_recheck && this.validateSearchParams(search_params)) {
			setTimeout(() => {
				HotelActions.getHotelDeals(hotel_id);
			});
			this.setState({ requested_hotels: true });
		} else {
			this.setState({ info_only: true });
		}

		window.scrollTo(0, 0);

		if (hotel) {
			Analytics.actions.views.hotelPage(profile, hotel, search_terms);
		} else {
			console.warn('hotel page mounted with no hotel in state... skipped analytics view hotel page action');
		}

		HotelStore.addChangeListener(this._onChange);
		ResultsStore.addChangeListener(this._onChange);
		FiltersStore.addChangeListener(this._onChange);
		BasketStore.addChangeListener(this._onChange);
		ProfileStore.addChangeListener(this._onChange);
	},

	getMaxDeals(state) {
		const { hotelDeals } = state;
		let counter = 0;
		if (hotelDeals) {
			for (const key in hotelDeals) {
				const deal = hotelDeals[key];
				if (deal.status && deal.status !== 'initial') {
					counter++;
				}
			}
		}

		return Math.max(HotelConstants.MAX_DEALS_TO_SHOW, counter);
	},

	validateSearchParams: function (search_params) {
		if (!search_params) {
			return false;
		}

		let { check_in, check_out, rooms, guests } = search_params;

		if (!SearchTermsParser.validateDates(check_in, check_out)) {
			return false;
		}

		if (!SearchTermsParser.validateRooms(rooms)) {
			return false;
		}

		if (!SearchTermsParser.validateRooms(guests)) {
			return false;
		}

		return true;
	},

	componentWillUnmount: function () {
		if (this.recheck_loader_timeout) {
			clearTimeout(this.recheck_loader_timeout);
		}

		HotelStore.removeChangeListener(this._onChange);
		ResultsStore.removeChangeListener(this._onChange);
		FiltersStore.removeChangeListener(this._onChange);
		BasketStore.removeChangeListener(this._onChange);
		ProfileStore.removeChangeListener(this._onChange);
	},

	showRoomDialog(room, name, analyticsFunc) {
		this.setState({
			showRoomDialog: true,
			roomDialogData: room,
			roomDialogName: name,
			roomDialogAnalysticFunc: analyticsFunc,
		});
	},

	onCloseRoomDialog() {
		this.setState({ showRoomDialog: false });
	},

	doesDealPassFilters: function (deal) {
		let any_filter =
			this.state.deals_filter.breakfast_only ||
			this.state.deals_filter.half_board_only ||
			this.state.deals_filter.all_inclusive_only ||
			this.state.deals_filter.post_pay_only ||
			this.state.deals_filter.negotiated_rate_only ||
			this.state.deals_filter.free_cancellation_only ||
			this.state.deals_filter.in_policy_only;
		if (!any_filter) {
			return true;
		}
		if (deal.status === DealConstants.DEAL_STATUS.FAIL || deal.status === DealConstants.DEAL_STATUS.NA) {
			return false;
		}
		if (this.state.deals_filter.breakfast_only && !deal.breakfast) {
			return false;
		}
		if (this.state.deals_filter.post_pay_only && !deal.post_pay) {
			return false;
		}
		if (this.state.deals_filter.negotiated_rate_only && !deal.negotiated_rate) {
			return false;
		}
		if (this.state.deals_filter.free_cancellation_only && deal.chooseprod && deal.chooseprod.dca.nonRefundable) {
			return false;
		}
		if (this.state.deals_filter.in_policy_only && !deal.in_policy) {
			return false;
		}
		if (
			this.state.deals_filter.half_board_only &&
			this.state.deals_filter.all_inclusive_only &&
			(_.get(deal, 'board_bases.half_board') ||
				_.get(deal, 'board_bases.full_board') ||
				_.get(deal, 'board_bases.all_inclusive'))
		) {
			return true;
		} else if (this.state.deals_filter.half_board_only && !_.get(deal, 'board_bases.half_board')) {
			return false;
		} else if (
			this.state.deals_filter.all_inclusive_only &&
			!(_.get(deal, 'board_bases.full_board') || _.get(deal, 'board_bases.all_inclusive'))
		) {
			return false;
		}
		return true;
	},

	showDeal: function (deal) {
		if (!deal) {
			return false;
		}
		if (deal.not_available) {
			return false;
		}
		if (deal.ignore) {
			return false;
		}
		if (deal.superseded && !this.state.show_superseded_deals) {
			return false;
		}

		if (this.state.show_only_mapping_deals && _.get(this.state.room_mapping[deal.id], 'debug.no_match', false)) {
			return false;
		}

		if (this.state.hide_expedia_deals && deal.id && deal.id.startsWith('E:')) {
			return false;
		}

		if (this.state.show_only_expedia_deals && deal.id && !deal.id.startsWith('E:')) {
			return false;
		}

		if (this.state.dynamicPriceIdFilter) {
			return deal.dynamicPricing?.dynamic_pricing_group_id?.includes(this.state.dynamicPriceIdFilter);
		}

		return this.doesDealPassFilters(deal);
	},

	showSupersededDeals: function () {
		this.setState({
			show_superseded_deals: !this.state.show_superseded_deals,
		});
	},

	onFilterByDynamicPriceId: function (event) {
		this.setState({
			dynamicPriceIdFilter: event.target.value,
		});
	},

	getValidDealsCount: function (hotelDeals) {
		let valid_deals = Object.values(hotelDeals).filter(DealUtils.isValidDeal);
		const filter_superseded = !this.state.show_superseded_deals;
		if (filter_superseded) {
			valid_deals = valid_deals.filter((d) => !d.superseded);
		}
		return valid_deals.length;
	},

	getDealComponents(deals, options = {}) {
		const { showRepresentativeDeal = true, bestRate = false, startingIndex = 0 } = options;
		const {
			hotel,
			basket,
			info_status,
			max_deals_to_show,
			show_superseded_deals,
			profile,
			mappingDebugMode,
			room_mapping,
			arbitrip_points_applied,
			reservation_manipulation,
		} = this.state;

		const _hotel = this.getHotel();

		const representativeDealId = _.get(hotel, 'representativeDeal.id');

		let index = startingIndex + 1;
		let DEALS_COMPONENTS = [];

		if (_.isObject(deals)) {
			deals.forEach((deal) => {
				const deal_id = deal.id;
				if (
					(showRepresentativeDeal || deal_id !== representativeDealId) &&
					this.showDeal(deal) &&
					DEALS_COMPONENTS.length < max_deals_to_show
				) {
					let roomMappingData = room_mapping[deal.id];

					DEALS_COMPONENTS.push(
						<DealComponent
							key={deal_id}
							deal={deal}
							index={index}
							displayed_deal_order_count={index++}
							hotel={_hotel}
							basket={basket}
							add_to_list_disabled={info_status === HotelConstants.INFO_STATUS.BUSY}
							representative={representativeDealId && deal_id === representativeDealId}
							show_superseded_deals={show_superseded_deals}
							showRoomDialog={this.showRoomDialog}
							bestRate={bestRate}
							profile={profile}
							mappingDebugMode={mappingDebugMode}
							roomMappingData={roomMappingData}
							arbitripPointsApplied={arbitrip_points_applied}
							contractClientCompanyId={this.state.contract_client_company_id}
							searchTerms={this.state.search_terms}
							roomDetailsClickHandler={this.showRoomDialog}
							reservationManipulation={reservation_manipulation}
						/>,
					);
				}
			});
		}

		return DEALS_COMPONENTS;
	},

	getPostPayDeals() {
		let { hotel_post_pay_deals } = this.state;

		if (hotel_post_pay_deals) {
			// First sort by price
			// Then, group by room_group which we hope keeps original ordering
			// Then, take values; notice - use the underscore implementation as it keeps ordering
			// And finally, flatten to one array

			let sortedDealsPrice = _.sortBy(hotel_post_pay_deals, 'totalPrice');
			let countByRoomGroup = _.countBy(sortedDealsPrice, 'room_group');
			let groupByRoomGroup = _.groupBy(sortedDealsPrice, 'room_group');

			for (let room_group in groupByRoomGroup) {
				for (let deal of groupByRoomGroup[room_group]) {
					if (deal) {
						deal.room_group_description = deal.room_group_description || '';
						deal.room_group_count = countByRoomGroup[deal.room_group];
					}
				}
			}

			hotel_post_pay_deals = sortedDealsPrice;

			const indexStart = this.getRepresentativeDeal().length + this.getPrePayDeals().length;

			return this.getDealComponents(hotel_post_pay_deals, {
				showRepresentativeDeal: false,
				startingIndex: indexStart,
			});
		}

		return [];
	},

	getRepresentativeDeal() {
		if (this.state.hotel) {
			const { representativeDeal } = this.state.hotel;
			if (this.state.banned_deal_ids.includes(representativeDeal?.id)) {
				representativeDeal.not_available = true;
				representativeDeal.status = DealConstants.DEAL_STATUS.NA;
			}

			if (representativeDeal) {
				return this.getDealComponents([representativeDeal], { bestRate: true, startingIndex: 0 });
			}
		}

		return [];
	},

	getPrePayDeals() {
		let { hotel_pre_pay_deals } = this.state;

		if (hotel_pre_pay_deals) {
			// First sort by price
			// Then, group by room_group which we hope keeps original ordering
			// Then, take values; notice - use the underscore implementation as it keeps ordering
			// And finally, flatten to one array

			let sortedDealsPrice = _.sortBy(hotel_pre_pay_deals, 'totalPrice');
			let countByRoomGroup = _.countBy(sortedDealsPrice, 'room_group');
			let groupByRoomGroup = _.groupBy(sortedDealsPrice, 'room_group');

			for (let room_group in groupByRoomGroup) {
				for (let deal of groupByRoomGroup[room_group]) {
					if (deal) {
						deal.room_group_description = deal.room_group_description || '';
						deal.room_group_count = countByRoomGroup[deal.room_group];
					}
				}
			}

			hotel_pre_pay_deals = sortedDealsPrice;

			const indexStart = this.getRepresentativeDeal().length;

			return this.getDealComponents(hotel_pre_pay_deals, {
				showRepresentativeDeal: false,
				startingIndex: indexStart,
			});
		}

		return [];
	},

	getDealsPlaceholderRender(deals_loading, valid_deals_count) {
		const results_route = RouterWrapper.buildResultsRoute(this.state.search_terms, this.state.search_token);
		const { show_recheck_loader } = this.state;

		if (deals_loading && show_recheck_loader) {
			return (
				<div className="info-deals-cached-status-container">
					<i className="fa fa-spinner fa-spin" />
				</div>
			);
		}

		if (
			this.state.recheck_status !== HotelConstants.RECHECK_STATUS.INITIAL &&
			this.state.recheck_status !== HotelConstants.RECHECK_STATUS.BUSY &&
			valid_deals_count === 0
		) {
			return (
				<div className="no-valid-deals-wrapper">
					<img
						className="empty-state-image"
						src="../../img/recent_searches/recent_searches_empty.svg"
						alt="no results"
					></img>
					<div> It looks like there is no availability on these dates.</div>
					<div>
						{' '}
						Try to change the dates or <Link to={results_route}> select a different hotel</Link>
					</div>
				</div>
			);
		}
	},

	getMapBoxRender(hotel) {
		return (
			hotel &&
			window.google && (
				<div className="hotel-map-box-container" id="hotel-map-box">
					<HotelMapBox
						mapBoxData={this.state.mapBoxData}
						mode="hotel"
						hotel={hotel}
						searchTerms={this.state.search_terms}
					/>
				</div>
			)
		);
	},

	getHotelOrHotelInfoPropertyByKey(key) {
		const { hotel_info, hotel } = this.state;

		return (
			_.get(hotel, `hotelInfo.${key}`) ||
			_.get(hotel, key) ||
			_.get(hotel_info, `hotelInfo.${key}`) ||
			_.get(hotel_info, key)
		);
	},

	getAboutRender() {
		const name = this.getHotelOrHotelInfoPropertyByKey('name');
		const about = this.getHotelOrHotelInfoPropertyByKey('about');

		return <AboutComponent hotel_name={name} description={about} />;
	},

	getAmenitiesRender() {
		const amenities = this.getHotelOrHotelInfoPropertyByKey('amenities') || [];

		return (
			amenities.length > 0 && (
				<div className="amenities-container">
					<AmenitiesComponent amenities={amenities} />
				</div>
			)
		);
	},

	toggleMappingDebugMode() {
		const { mappingDebugMode, hotel, search_session, unified_catalog } = this.state;

		if (!mappingDebugMode && !unified_catalog) {
			RoomMappingService.setUnifiedCatalog(search_session, hotel.id);
		}
		const optimizedDeals = hotel.deals
			? {
					optimized: hotel.deals.filter((d) => d.optimizedData && d.optimizedData.optimized),
					not_optimized: hotel.deals.filter(
						(d) => !d.optimizedData || (d.optimizedData && !d.optimizedData.optimized),
					),
				}
			: [];

		console.log('optimizedDeals', optimizedDeals);
		this.setState({ mappingDebugMode: !mappingDebugMode });
	},

	getMappingStatistics() {
		const { room_mapping, hotelDeals } = this.state;
		const display_debug = this.getDebugMode();

		const num_hotel_deals = hotelDeals ? Object.keys(hotelDeals).length : 0;

		const num_mapping_deals = _.filter(hotelDeals, (d) => {
			return !_.get(room_mapping[d.id], 'debug.no_match', false);
		}).length;

		const mapping_cover = ((num_mapping_deals / num_hotel_deals) * 100).toFixed(2);

		return (
			display_debug &&
			room_mapping && (
				<div className="mapping-statistics">
					Mapped Deals: {`${num_mapping_deals}`} | Mapping cover: {`${mapping_cover}%`}
				</div>
			)
		);
	},

	getMappingActions() {
		const { hotelId } = this.props;
		const {
			room_mapping,
			search_session,
			mappingDebugMode,
			show_only_mapping_deals,
			hide_expedia_deals,
			show_only_expedia_deals,
		} = this.state;
		const display_debug = this.getDebugMode();

		return display_debug && room_mapping ? (
			<div>
				<LoadMapping searchSession={search_session} hotelId={hotelId} />

				<div className="mapping-actions">
					<Button
						className="mapping-actions-button"
						color="primary"
						variant={mappingDebugMode ? 'contained' : 'outlined'}
						onClick={this.toggleMappingDebugMode}
					>
						Debug Mode
					</Button>

					<Button
						className="mapping-actions-button"
						color="primary"
						variant={show_only_mapping_deals ? 'contained' : 'outlined'}
						onClick={() => {
							this.setState({ show_only_mapping_deals: !show_only_mapping_deals });
						}}
					>
						Show only mapped deals
					</Button>

					<Button
						className="mapping-actions-button"
						color="primary"
						variant={hide_expedia_deals ? 'contained' : 'outlined'}
						onClick={() => {
							this.setState({
								hide_expedia_deals: !hide_expedia_deals,
								show_only_expedia_deals: false,
							});
						}}
					>
						Hide Expedia Deals
					</Button>

					<Button
						className="mapping-actions-button"
						color="primary"
						variant={show_only_expedia_deals ? 'contained' : 'outlined'}
						onClick={() => {
							this.setState({
								show_only_expedia_deals: !show_only_expedia_deals,
								hide_expedia_deals: false,
							});
						}}
					>
						Show ONLY Expedia Deals
					</Button>
				</div>
			</div>
		) : null;
	},

	getDealsStatisticsRender() {
		let { hotelDeals, reservation_manipulation, profile } = this.state;
		let display_debug = this.getDebugMode();

		let countByRoomGroup = _.countBy(hotelDeals, 'room_group');
		let numGroups = Object.keys(countByRoomGroup).length;

		let numHotelDeals = this.state.hotelDeals ? Object.keys(hotelDeals).length : 0;

		let supersededNum = hotelDeals ? hotelDeals.filter((d) => d.superseded).length : 0;

		return (
			<div>
				<DealsStatistics
					deals={numHotelDeals}
					groups={numGroups}
					superseded_num={supersededNum}
					showSupersededDealsCallback={this.showSupersededDeals}
					display_debugging={display_debug}
					onFilterByDynamicPriceId={this.onFilterByDynamicPriceId}
					dynamicPriceIdFilter={this.state.dynamicPriceIdFilter}
				/>

				<ReservationManipulation
					profile={profile}
					reservation_manipulation={reservation_manipulation}
					onInitStatusChange={(e) => {
						this.setState({
							reservation_manipulation: {
								...reservation_manipulation,
								is_fail_init: e.target.checked,
							},
						});
					}}
					onRecheckChange={(e) => {
						this.setState({
							reservation_manipulation: {
								...reservation_manipulation,
								is_fail_recheck: e.target.checked,
							},
						});
					}}
					onPriceChange={(e) => {
						this.setState({
							reservation_manipulation: {
								...reservation_manipulation,
								increase_price: e.target.value,
							},
						});
					}}
				/>
			</div>
		);
	},

	getHotel() {
		const { hotel_info, hotel } = this.state;

		return _.merge({}, hotel, hotel_info, _.pick(hotel, ['distanceInKm', 'distanceDescription', 'deals']));
	},

	getDebugMode() {
		return Config.dev_mode || (Config.prod && this.state.profile.is_arbi_support);
	},

	getBackToResultsRender() {
		const { search_token, search_terms, got_results } = this.state;

		if (!SearchTermsParser.validate(search_terms)) return null;

		const linkContent = got_results ? (
			<span>Back to Results</span>
		) : (
			<span onClick={this.seeOtherHotels}>See Other Hotels</span>
		);

		return (
			<div className="back-to-results">
				<Link to={RouterWrapper.buildResultsRoute(search_terms, got_results ? search_token : null)}>
					<img src={'/img/back_icon.svg'} alt={'back button'} />
					{linkContent}
				</Link>
			</div>
		);
	},

	seeOtherHotels() {
		SearchActions.search();
		RouterWrapper.goToResultsPage(this.props.navigate, this.state.search_terms);
	},

	filterDealsRender(num_hotel_deals, post_pay_deals_exists, half_board_deals_exists, all_inclusive_deals_exists) {
		const { travel_policy } = this.state;

		return (
			num_hotel_deals > 0 && (
				<FilterDealsComponent
					post_pay_deals_exists={post_pay_deals_exists}
					half_board_deals_exists={half_board_deals_exists}
					all_inclusive_deals_exists={all_inclusive_deals_exists}
					travel_policy={travel_policy}
				/>
			)
		);
	},

	onReviewScoreClick() {
		this.setState(
			(prevState) => ({
				activeTab: HOTEL_TABS_LABELS.REVIEWS,
				reviewScoreClickCounter: prevState.reviewScoreClickCounter + 1,
			}),
			() => {
				setTimeout(this.scrollToTabs, 0);
			},
		);
	},

	scrollToTabs() {
		const tabsWrapper = document.querySelector('.tabs-wrapper');
		if (tabsWrapper) {
			window.Velocity(tabsWrapper, 'scroll', {
				duration: 1250,
				easing: 'ease-in-out',
				offset: -74, // top bar height
			});
		}
	},

	render() {
		const {
			profile,
			hotelDeals,
			hotel_post_pay_deals,
			hotel_half_board_deals,
			hotel_all_inclusive_deals,
			mappingDebugMode,
			search_session,
			search_terms,
		} = this.state;

		const _hotel = this.getHotel();

		if (_.isEmpty(_hotel)) {
			console.log("Hotel page couldn't be rendered");
			return <div className="hotel-page"></div>;
		}

		const enable_room_mapping = _.get(profile, 'settings.enable_room_mapping', false);
		const display_debug = this.getDebugMode();
		// here is the place where we can hide rooms catalog for room_mapping: !_.get(profile, 'company.settings.enabled_room_mapping', false) || display_debug;
		const isShowRoomCatalog = true;

		const any_deals_filter =
			this.state.deals_filter.in_policy_only ||
			this.state.deals_filter.free_cancellation_only ||
			this.state.deals_filter.breakfast_only ||
			this.state.deals_filter.post_pay_only ||
			this.state.deals_filter.negotiated_rate_only;
		const deals_loading = this.state.recheck_status
			? this.state.recheck_status === HotelConstants.RECHECK_STATUS.BUSY ||
				this.state.recheck_status === HotelConstants.RECHECK_STATUS.INITIAL
			: true;

		const REPRESENTATIVE_DEAL = this.getRepresentativeDeal();
		const PRE_PAY_DEALS = this.getPrePayDeals();
		const POST_PAY_DEALS = this.getPostPayDeals();
		const ALL_DEALS = PRE_PAY_DEALS.concat(POST_PAY_DEALS, REPRESENTATIVE_DEAL);
		const multipleKindOfDealsExist = !!(PRE_PAY_DEALS.length && POST_PAY_DEALS.length);

		const num_hotel_deals = _.size(hotelDeals);

		const post_pay_deals_exists = _.size(hotel_post_pay_deals) > 0;
		const half_board_deals_exists = _.size(hotel_half_board_deals) > 0;
		const all_inclusive_deals_exists = _.size(hotel_all_inclusive_deals) > 0;

		let valid_deals_count = 0;
		if (typeof hotelDeals === 'object') {
			valid_deals_count += this.getValidDealsCount(hotelDeals);
		}

		const INFO_SPINNER = !_hotel &&
			this.state.info_status === HotelConstants.INFO_STATUS.BUSY &&
			Array.isArray(_hotel.images) &&
			_hotel.images.length > 0 && (
				<div className="info-status-container">
					<i className="fa fa-spinner fa-spin" />
				</div>
			);

		const title = TitleManager.buildTitleStartsWith(_.get(_hotel, 'name', ''), true, true);

		return (
			<DocumentTitle title={title}>
				<div className="hotel-page">
					{this.getBackToResultsRender()}
					<div className="hotel-container top-container">
						<div className="hotel-main">
							{_hotel.images?.length > 0 && <HotelImagesCollage images={_hotel.images} />}

							<StarsComponent stars={_hotel.stars} />

							<div className="hotel-name"> {_hotel.name} </div>

							<HotelAddress
								hotel={_hotel}
								showDistance={true}
								showAlternativeDistance={true}
								forceAddress={true}
							/>

							{INFO_SPINNER}

							{this.getMapBoxRender(_hotel)}

							{display_debug && this.getDealsStatisticsRender()}

							{display_debug && enable_room_mapping && this.getMappingStatistics()}
							{display_debug && enable_room_mapping && this.getMappingActions()}
						</div>
						<div className="hotel-side">
							<TripDetails hotel={_hotel} searchTerms={search_terms} />
							<div className="review-reasons-container">
								<HotelReview hotel={_hotel} onReviewScoreClick={this.onReviewScoreClick} />
								<HotelReasons hotel={_hotel} />
							</div>
						</div>
					</div>
					<div className="hotel-container">
						<div className="hotel-main" style={{ paddingTop: '28px' }}>
							<div className="filters-and-deals">
								{num_hotel_deals > 0 && <div className="title">Choose your room</div>}

								{this.filterDealsRender(
									num_hotel_deals,
									post_pay_deals_exists,
									half_board_deals_exists,
									all_inclusive_deals_exists,
								)}

								{num_hotel_deals > 0 && <InstallmentsBanner />}

								{this.state.info_only
									? null
									: this.getDealsPlaceholderRender(deals_loading, valid_deals_count)}

								{ALL_DEALS.length === 0 && any_deals_filter && valid_deals_count > 0 && (
									<NoAvailabilityMessage />
								)}

								{multipleKindOfDealsExist ? (
									<>
										{REPRESENTATIVE_DEAL.length > 0 && (
											<DealsTitle title="Best Rate">{REPRESENTATIVE_DEAL}</DealsTitle>
										)}

										{PRE_PAY_DEALS.length > 0 && (
											<DealsTitle title="Pre-pay Rates">{PRE_PAY_DEALS}</DealsTitle>
										)}

										{POST_PAY_DEALS.length > 0 && (
											<DealsTitle title="Post-pay Rates (to be paid at the hotel)">
												{POST_PAY_DEALS}
											</DealsTitle>
										)}
									</>
								) : (
									<>
										{REPRESENTATIVE_DEAL}
										{PRE_PAY_DEALS}
										{POST_PAY_DEALS}
									</>
								)}
							</div>

							<div className="tabs-wrapper">
								<HotelTabs
									key={this.state.reviewScoreClickCounter}
									activeTab={this.state.activeTab}
									descriptionContent={this.getAboutRender()}
									hotel={_hotel}
									amenitiesContent={this.getAmenitiesRender()}
									policiesContent={
										<>
											{/* E */}
											<SupplierTerms
												getHotelOrHotelInfoPropertyByKey={this.getHotelOrHotelInfoPropertyByKey}
											/>

											{/* B */}
											<ExtraInformation
												getHotelOrHotelInfoPropertyByKey={this.getHotelOrHotelInfoPropertyByKey}
											/>
										</>
									}
								/>
							</div>
						</div>
						<div className="hotel-side">
							{isShowRoomCatalog && _hotel.rooms && (
								<div className="catalog">
									<RoomsCatalog
										onOpen={(room) =>
											this.showRoomDialog(
												room,
												null,
												Analytics.actions.views.roomInfoDialogSidemenu,
											)
										}
										mappingDebugMode={mappingDebugMode}
										hotel={_hotel}
										profile={profile}
										searchSession={search_session}
										rooms={_hotel.rooms}
									/>
								</div>
							)}
						</div>
					</div>
					<ExpiredResultsComponent
						type={EXPIRED_TYPES.BACK_TO_RESULTS}
						expiredEntityText="deals"
						hotelId={this.props.hotelId}
						hotelName={this.props.hotelName}
					/>
					<RoomsCatalogDialog
						open={this.state.showRoomDialog}
						onClose={this.onCloseRoomDialog}
						room={this.state.roomDialogData}
						roomName={this.state.roomDialogName}
						analyticsFunc={this.state.roomDialogAnalysticFunc}
						isDev={display_debug}
						hotel={_hotel}
						profile={profile}
					/>
				</div>
			</DocumentTitle>
		);
	},

	// Method to setState based upon Store changes
	_onChange: function () {
		this.setState(getHotelPageState(this.props.hotelId));
	},
});

export default HotelPage;
