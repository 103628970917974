import keyMirror from 'keymirror';
import _ from 'lodash';

const DESTINATIONS_CONSTANTS = {
	STATUS: {
		INITIAL: 'initial',
		BUSY: 'busy',
		IDLE: 'idle',
		SUCCESS: 'success',
		FAILED: 'failed',
	},
};

// Define action constants
export default _.extend(
	{},
	DESTINATIONS_CONSTANTS,
	keyMirror({
		RECEIVE_DESTINATIONS_DATA: null, // After postback
		ADD_DESTINATION: null,
		REMOVE_DESTINATION: null,

		CHOOSE_DESTINATION: null,

		DESTINATION_RETRIEVED: null,
		DESTINATION_ADDED: null,
		DESTINATION_REMOVED: null,

		DESTINATIONS_RETRIEVAL_FAILED: null,
		DESTINATION_ADDITION_FAILED: null,
		DESTINATION_REMOVAL_FAILED: null,
	}),
);
