const SearchIcon = () => {
	return (
		<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle
				cx="16.8333"
				cy="16.8333"
				r="9.33333"
				stroke="var(--general-primary-button-color)"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M28.5 28.4999L23.425 23.4249"
				stroke="var(--general-primary-button-color)"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default SearchIcon;
