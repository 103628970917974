import StarsComponent from '../../../StarsComponent.react';

const HotelCardReviewComponent = ({ hotel }) => {
	const { stars, hasReview, review } = hotel;

	return (
		<div className="hotel-review">
			<StarsComponent stars={stars} />
			{hasReview ? (
				<>
					<span className="review-score">{review.score}</span>
					<span>{review.description}</span>
					<span>|</span>
					<span>{review.votes.toLocaleString() + ' Reviews'}</span>
				</>
			) : (
				<>
					<span>|</span>
					<span>Score Not Available</span>
				</>
			)}
		</div>
	);
};

export default HotelCardReviewComponent;
