import React, { useState, useRef, useEffect } from 'react';
import { HOTEL_TABS_LABELS } from './constants';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TrustYouComponent from './TrustYouComponent.react';

const TabPanel = (props) => {
	const { children, value, index } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`hotel-tabpanel-${index}`}
			aria-labelledby={`hotel-tab-${index}`}
		>
			{value === index && <Box sx={{ p: '0 0 72px 0' }}>{children}</Box>}
		</div>
	);
};

const HotelTabs = ({ activeTab, descriptionContent, hotel, amenitiesContent, policiesContent }) => {
	const initialTabValue = activeTab === HOTEL_TABS_LABELS.REVIEWS ? 1 : 0;
	const [value, setValue] = useState(initialTabValue);
	const [containerHeight, setContainerHeight] = useState('0px');
	const tabsRef = useRef(null);

	useEffect(() => {
		const tabIndex = [
			HOTEL_TABS_LABELS.DESCRIPTION,
			HOTEL_TABS_LABELS.REVIEWS,
			HOTEL_TABS_LABELS.AMENITIES,
			HOTEL_TABS_LABELS.POLICIES,
		].indexOf(activeTab);
		setValue(tabIndex !== -1 ? tabIndex : 0);
	}, [activeTab]);

	const SCROLL_OFFSET = 80;

	const getReview = () => {
		if (hotel.hasReview && hotel.review?.ty_id) {
			return <TrustYouComponent hotel={hotel} containerHeight={containerHeight} />;
		}
		return null;
	};

	const tabData = [
		{ label: HOTEL_TABS_LABELS.DESCRIPTION, content: descriptionContent },
		{ label: HOTEL_TABS_LABELS.REVIEWS, content: getReview() },
		{ label: HOTEL_TABS_LABELS.AMENITIES, content: amenitiesContent },
		{ label: HOTEL_TABS_LABELS.POLICIES, content: policiesContent },
	];

	// Filter out tabs with null content
	const filteredTabData = tabData.filter(({ content }) => content !== null);

	const handleChange = (event, newValue) => {
		const windowHeight = window.innerHeight;
		const rect = tabsRef.current.getBoundingClientRect();
		const newHeight = `${windowHeight - rect.top}px`;
		setContainerHeight(newHeight);

		setValue(newValue);
		window.scrollTo({
			top: window.scrollY + rect.top - SCROLL_OFFSET,
			behavior: 'smooth',
		});
	};

	return (
		<Box sx={{ width: '100%' }} ref={tabsRef}>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs value={value} onChange={handleChange} aria-label="hotel tabs">
					{filteredTabData.map(({ label }, index) => (
						<Tab key={index} label={label} disableRipple />
					))}
				</Tabs>
			</Box>
			{filteredTabData.map(({ content }, index) => (
				<TabPanel key={index} value={value} index={index}>
					{content}
				</TabPanel>
			))}
		</Box>
	);
};

export default HotelTabs;
