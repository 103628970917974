import React from 'react';
import createClass from 'create-react-class';

import ProfileStore from '../../../stores/ProfileStore';
import SearchStore from '../../../stores/SearchStore';

function getPrivateTravelState() {
	return {
		private_travel_company: ProfileStore.isPrivateTravel(),
		private_travel_search: SearchStore.isPrivateTravel(),
	};
}

var PrivateTravelComponent = createClass({
	displayName: 'PrivateTravelComponent',

	getInitialState: function () {
		return getPrivateTravelState();
	},

	_onChange: function (event, value) {
		this.setState(getPrivateTravelState());
	},

	render: function () {
		return (
			<div className="private-travel-component">
				<span>Leisure travel - company's travel policy does not apply.</span>
			</div>
		);
	},
});

export default PrivateTravelComponent;
