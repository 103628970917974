import withStyles from '@mui/styles/withStyles';
import _ from 'lodash';

import React from 'react';
import PropTypes from 'prop-types';

import RecentSearchesBoxStore from '../../../stores/RecentSearchesBoxStore';
import RecentSearchComponent from './RecentSearchComponent.react';
import EmptyStateSearchesDestination from './EmptyStateSearchesDestination.react';
import ProfileStore from '../../../stores/ProfileStore';

import { CircularProgress } from '@mui/material';

const styles = {};

function getState() {
	return {
		profile: ProfileStore.getProfile(),
		recentSearches: RecentSearchesBoxStore.getItems(),
		isLoading: RecentSearchesBoxStore.isLoading(),
	};
}

const MAX_NUM_RECENT_SEARCHES = 5;

class RecentSearchesBox extends React.Component {
	constructor(props) {
		super(props);

		this.state = getState();

		this._onChange = this._onChange.bind(this);
	}

	getRecentSearches() {
		const { profile, recentSearches } = this.state;

		if (recentSearches && recentSearches.length > 0) {
			return _.times(Math.min(recentSearches.length, MAX_NUM_RECENT_SEARCHES), (i) => {
				return <RecentSearchComponent profile={profile} search={recentSearches[i]} key={`rec-${i}`} />;
			});
		}

		return (
			<EmptyStateSearchesDestination
				title="No recent searches found"
				description="Your searches will appear here"
			/>
		);
	}

	render() {
		const { isLoading } = this.state;

		if (isLoading) {
			return (
				<div className="recent-searches-box flex flex-center">
					<CircularProgress />
				</div>
			);
		}

		return (
			<div className="recent-searches-box">
				<div className="title">Recent Searches</div>
				{this.getRecentSearches()}
			</div>
		);
	}

	_onChange() {
		this.setState(getState());
	}

	componentDidMount() {
		RecentSearchesBoxStore.addChangeListener(this._onChange);
	}

	componentWillUnmount() {
		RecentSearchesBoxStore.removeChangeListener(this._onChange);
	}
}

RecentSearchesBox.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RecentSearchesBox);
