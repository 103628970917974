import { ReactComponent as SpaceManIcon } from '@/img/space-man.svg';
import { ArbiCancelButton } from '@/components/general/styled/buttons';

const NoOngoingTripsMessage = ({ handleBackToTripsOnClick }) => (
	<div className="no-ongoing-trips flex flex-column align-center">
		<SpaceManIcon />
		<span className="message">There are no ongoing trips at the moment</span>
		<ArbiCancelButton className="back-button" onClick={handleBackToTripsOnClick} disableRipple>
			Back to All Trips
		</ArbiCancelButton>
	</div>
);

export default NoOngoingTripsMessage;
