import { Button, styled } from '@mui/material';

const BaseButton = styled(Button)(() => ({
	borderRadius: 4,
	fontWeight: 700,
	textTransform: 'none',
}));

// Dialog start
export const StyledDialogCancelButton = styled(BaseButton)(({ theme }) => ({
	backgroundColor: theme.palette.gray.gray5,
	color: theme.palette.blue.main,
	'&:hover': {
		backgroundColor: '#e0e0e0',
	},
}));

export const StyledDialogDeleteButton = styled(BaseButton)(({ theme }) => ({
	backgroundColor: theme.palette.white,
	color: theme.palette.danger.main,
	border: `1px solid ${theme.palette.danger.main}`,
	'&:hover': {
		backgroundColor: 'rgba(244, 67, 54, 0.1)',
		boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
	},
}));

export const StyledDialogAddButton = styled(BaseButton)(({ theme }) => ({
	backgroundColor: theme.palette.blue.main,
	color: theme.palette.white,
	'&.Mui-disabled': {
		opacity: 0.5,
		color: theme.palette.white,
	},
	'&:hover': {
		backgroundColor: theme.palette.blue.mainHover,
	},
}));
// Dialog end

export const StyledCancelButton = styled(BaseButton)(({ theme }) => ({
	color: theme.palette.gray.gray2,
	border: `1px solid ${theme.palette.gray.gray2}`,
}));

export const StyledPrimaryActionButton = styled(BaseButton)(({ theme }) => ({
	backgroundColor: theme.palette.blue.lightBlue,
	color: theme.palette.blue.main,
	border: 'none',
	'&:hover': {
		backgroundColor: theme.palette.blue.lightBlueHover,
		border: 'none',
	},
}));
