import Deal from './Deal';

import DealUtils from 'arbitrip-common/client/entities/DealUtils';

const dealSupplierExtractor = DealUtils.dealSupplierExtractor;
const isValidDeal = DealUtils.isValidDeal;
const isValidRecheck = DealUtils.isValidRecheck;
const updateDealRecheck = DealUtils.updateDealRecheck;
// TODO! Find a way to refactor this out
function createDeal(deal, idx) {
	return new Deal(deal, idx + 1); // 1 based
}

function createDeals(deals) {
	if (!deals || !Array.isArray(deals)) {
		// console.error('invalid deals received: ', deals);
		return [];
	}
	return deals.filter(isValidDeal).map(createDeal);
}

function createRecheckDeal(recheck, idx) {
	const deal = createDeal(recheck, idx);
	return DealUtils.updateDealRecheck(deal, recheck);
}

function createRecheckedDeals(rechecked_deals) {
	if (!rechecked_deals || !Array.isArray(rechecked_deals)) {
		return [];
	}
	const valid_deals = rechecked_deals.filter(isValidDeal);
	const valid_rechecks = valid_deals.filter(isValidRecheck);
	const new_deals_with_rechecks = valid_rechecks.map(createRecheckDeal);
	return new_deals_with_rechecks;
	// return rechecked_deals.filter(isValidDeal)
	//   .filter(isValidRecheck)
	//   .map(createDeal)
	//   .map((d) => DealUtils.updateDealRecheck(d, d)); // Yes, same object
}

// Need to copy the create function because the deal entity is not the same
export default {
	dealSupplierExtractor,
	isValidDeal,
	isValidRecheck,
	createDeal,
	createDeals,
	createRecheckDeal,
	createRecheckedDeals,
	updateDealRecheck,
};
