import withStyles from '@mui/styles/withStyles';

import _ from 'lodash';
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import PlusIcon from './PlusIcon';
import MinusIcon from './MinusIcon';

import SEARCH_CONSTANTS from 'arbitrip-common/general/constants/search-constants';

const styles = (theme) => ({
	item: {
		marginBottom: 12,
	},
	flex: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	itemWrap: {
		width: 72,
		color: theme.palette.black,
	},
	itemName: {
		fontSize: 15,
		fontWeight: 700,
	},
	countAction: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: 41,
		height: 41,
		cursor: 'pointer',
		borderRadius: 4,
		backgroundColor: theme.palette.general.countAction.background,
	},
	countNumber: {
		width: 54,
		textAlign: 'center',
		fontSize: 15,
		color: theme.palette.black,
		fontWeight: 700,
	},
	icon: {
		marginTop: 9,
	},
	perRoom: {
		fontSize: 13,
		color: theme.palette.gray.gray2,
	},
});

class SearchDataAction extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};

		this.minusCount = this.minusCount.bind(this);
		this.plusCount = this.plusCount.bind(this);
	}

	minusCount() {
		let { name, count, setNewCount } = this.props;

		if (count > SEARCH_CONSTANTS.MIN[name.toUpperCase()]) {
			count--;
			setNewCount(name, count);
		}
	}

	plusCount() {
		let { name, count, setNewCount, setFlag, maxValue } = this.props;

		if (count < maxValue) {
			count++;
			setNewCount(name, count);
		} else {
			if (_.isFunction(setFlag)) {
				setFlag(name);
			}
		}
	}

	render() {
		let { classes, count, name, perRoomText } = this.props;

		return (
			<div className={classNames(classes.item, classes.flex)}>
				<div className={classes.itemWrap}>
					<div className={classes.itemName}>{name}</div>
					{perRoomText && <div className={classes.perRoom}>(Per room)</div>}
				</div>
				<div className={classes.flex}>
					<button className={classes.countAction} onClick={this.minusCount} aria-label={`Decrease ${name}`}>
						<MinusIcon />
					</button>
					<div aria-live="polite" className={classes.countNumber} aria-label={`${count} ${name}`}>
						{count}
					</div>
					<button className={classes.countAction} onClick={this.plusCount} aria-label={`Add ${name}`}>
						<PlusIcon />
					</button>
				</div>
			</div>
		);
	}
}

SearchDataAction.propTypes = {
	classes: PropTypes.object.isRequired,
	name: PropTypes.string.isRequired,
	count: PropTypes.number.isRequired,
	setNewCount: PropTypes.func.isRequired,
	setFlag: PropTypes.func,
	perRoomText: PropTypes.bool,
	isAgent: PropTypes.bool,
};

export default withStyles(styles, { withTheme: true })(SearchDataAction);
