import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';

import React from 'react';

import _ from 'lodash';
import classNames from 'classnames';

import DestinationsActions from '../../../../actions/DestinationsActions';
import SearchAutocomplete from '../../../search/SearchAutocomplete.react';

import { dietPlace } from 'arbitrip-common/client/utils/PlacesWrapper';

import Consts from './consts';
import { VARIANT } from '../../../search/constants';
const { CLASSES } = Consts;

let businessDestinationAutoCompleteInput;
let _lastPickedBusinessDestinationLocation;

const styles = (theme) => ({
	root: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 50,
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: 305,
		zIndex: 1,
		backgroundColor: '#fff',
		borderRadius: 4,
		boxShadow: '0 0 8px 0 rgba(0,0,0,0.3)',
		padding: 24,
	},
	input: {
		height: 50,
		width: '100%',
		color: '#41494C',
		backgroundColor: theme.palette.gray.gray5,
		fontSize: 16,
		lineHeight: 19,
		border: `1px solid ${theme.palette.gray.gray3}`,
		borderRadius: 4,
	},
	destination: {
		padding: '0 20px',
		outline: 'none',
	},
	autocomplete: {
		border: 'none',
		borderRadius: 4,
	},
	actions: {
		display: 'flex',
		height: 42,
		marginTop: 'auto',
		fontSize: 18,
		fontWeight: 500,
		color: theme.palette.gray.gray6,
		cursor: 'pointer',
		outline: 'none',
	},
	save: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: 66,
		backgroundColor: theme.palette.blue.lightBlue,
		color: theme.palette.blue.main,
		borderRadius: 8,
		marginLeft: 16,
		fontSize: 14,
		'&:hover:not($notValid)': {
			backgroundColor: '#B0E5FF',
		},
	},
	cancel: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: 91,
		color: theme.palette.blue.main,
		border: `1px solid ${theme.palette.blue.main}`,
		borderRadius: 8,
		marginLeft: 'auto',
		fontSize: 14,
	},
	notValid: {
		opacity: 0.65,
		cursor: 'not-allowed',
		outline: 'none',
	},
	message: {
		display: 'flex',
		alignItems: 'center',
		color: theme.palette.gray.gray2,
		marginRight: 'auto',
		fontSize: 15,
	},
});

function getState() {
	return {
		showValidationMessage: false,
		locationTitle: '',
		destinationAddress: '',
		businessDestination: {
			address: '',
			place: null,
			autocomplete_category: '',
			autocomplete_index: '',
		},
	};
}

class AddNewBusinessDestinationComponent extends React.Component {
	constructor(props) {
		super(props);

		this.state = getState();

		this.myRef = React.createRef();

		this.handleClick = this.handleClick.bind(this);
		this.handleBusinessDestinationUpdate = this.handleBusinessDestinationUpdate.bind(this);
		this.handleLocationTitleChange = this.handleLocationTitleChange.bind(this);
		this.onClose = this.onClose.bind(this);
		this.onSave = this.onSave.bind(this);
	}

	fieldsAreValid() {
		let { locationTitle, destinationAddress, businessDestination } = this.state;

		return locationTitle && destinationAddress && businessDestination.address && businessDestination.place;
	}

	handleLocationTitleChange(e) {
		this.setState({
			locationTitle: e.target.value,
		});
	}

	handleBusinessDestinationBlur() {
		if (businessDestinationAutoCompleteInput.value && _lastPickedBusinessDestinationLocation) {
			return this.setState({
				destinationAddress: _lastPickedBusinessDestinationLocation.address,
				businessDestination: _lastPickedBusinessDestinationLocation,
			});
		}
		_lastPickedBusinessDestinationLocation = null;
		this.setState({
			destinationAddress: '',
			businessDestination: {
				address: '',
				place: null,
				autocomplete_category: '',
				autocomplete_index: '',
			},
		});
	}

	handleBusinessDestinationUpdate(item) {
		if (!item) {
			let addDestinationElement = document.getElementById('add-destination');

			this.setState({
				destinationAddress: '',
				businessDestination: {
					address: '',
					place: null,
					autocomplete_category: '',
					autocomplete_index: '',
				},
			});

			if (window.Velocity) {
				return window.Velocity(addDestinationElement, 'callout.shake', {});
			} else {
				console.error('Velocity is not on window');
				return;
			}
		}

		_lastPickedBusinessDestinationLocation = {
			address: _.get(item, 'address'),
			place: dietPlace(item),
			autocomplete_category: _.get(item, 'category'),
			autocomplete_index: _.get(item, 'index'),
		};

		this.setState({
			destinationAddress: _lastPickedBusinessDestinationLocation.address,
			businessDestination: _lastPickedBusinessDestinationLocation,
		});
	}

	handleBusinessDestinationBlurNew(e, data) {
		if (data.searchInput && _lastPickedBusinessDestinationLocation) {
			return this.setState({
				destinationAddress: _lastPickedBusinessDestinationLocation.address,
				businessDestination: _lastPickedBusinessDestinationLocation,
			});
		}
		_lastPickedBusinessDestinationLocation = null;
		this.setState({
			destinationAddress: '',
			businessDestination: {
				address: '',
				place: null,
				autocomplete_category: '',
				autocomplete_index: '',
			},
		});
	}

	onSave() {
		let { locationTitle, businessDestination } = this.state;

		if (!this.fieldsAreValid()) {
			this.setState({ showValidationMessage: true });
			return;
		}

		DestinationsActions.addDestination({
			name: locationTitle,
			destination: businessDestination,
		});

		this.onClose();
	}

	onClose() {
		const { onClose } = this.props;
		this.setState(getState());

		if (_.isFunction(onClose)) {
			onClose();
		}
	}

	getDestinationRender() {
		const { classes } = this.props;
		const { destinationAddress } = this.state;
		const value = destinationAddress ? { address: destinationAddress } : null;

		return (
			<div className="input-wrapper">
				<label htmlFor="destinationAddress">Destination address</label>
				<div className={classNames(classes.input, classes.autocomplete)}>
					<SearchAutocomplete
						id="destinationAddress"
						placeholder=" "
						value={value}
						onBlur={(e, data) => this.handleBusinessDestinationBlurNew(e, data)}
						onUpdate={this.handleBusinessDestinationUpdate}
						tabIndex="2"
						showLoader={true}
						updateItemBeforePlaceId={false}
						variant={VARIANT.CLEAN}
					/>
				</div>
			</div>
		);
	}

	getActionsRender() {
		const { classes } = this.props;
		const { showValidationMessage } = this.state;

		return (
			<div className={classes.actions}>
				{showValidationMessage && <div className={classes.message}>Please fill both fields</div>}
				<button onClick={this.onClose} className={classes.cancel} tabIndex="3">
					Cancel
				</button>
				<button
					onClick={this.onSave}
					className={classNames(classes.save, !this.fieldsAreValid() && classes.notValid)}
					tabIndex="4"
				>
					Save
				</button>
			</div>
		);
	}

	getTitleRender() {
		const { classes } = this.props;
		const { locationTitle } = this.state;

		return (
			<div className="input-wrapper">
				<label htmlFor="destinationName">Destination name</label>
				<input
					id="destinationName"
					className={classNames(classes.input, classes.destination)}
					type="text"
					autoFocus
					onChange={this.handleLocationTitleChange}
					value={locationTitle}
					tabIndex="1"
				/>
			</div>
		);
	}

	render() {
		const { classes } = this.props;

		return (
			<div className={classes.root} ref={this.myRef}>
				<div>
					{this.getTitleRender()}
					{this.getDestinationRender()}
				</div>
				{this.getActionsRender()}
			</div>
		);
	}

	handleClick(e) {
		if (this.myRef.current.contains(e.target) || e.target.classList.contains(CLASSES.TITLE_ACTION)) {
			// click inside the window or children option
			// do nothing
			return;
		}

		// click outside the window
		// close window
		const { onClose } = this.props;
		if (_.isFunction(onClose)) {
			onClose();
		}
	}

	scrollToComponent() {
		if (this.myRef.current) {
			window.Velocity(this.myRef.current, 'scroll', {
				duration: 500,
				easing: 'ease-in-out',
				offset: -100,
			});
		}
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClick, false);
		this.scrollToComponent();
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClick, false);
	}
}

AddNewBusinessDestinationComponent.propTypes = {
	onClose: PropTypes.func,
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddNewBusinessDestinationComponent);
