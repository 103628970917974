const _ = require('lodash');
const Review = require('./Review');
const ImageUtils = require('../utils/ImageUtils');
const ProtocolStripper = require('../utils/ProtocolStripper');

function isValidRawHotel(rawHotel) {
  return !!_.get(rawHotel, '_id');
}

function setImages(hotel, images) {
  if (Array.isArray(images) && (images.length > 0)) {
    images.sort((a, b) => a.display_order - b.display_order);
    hotel.images = ImageUtils.transformImagesToGallery(images);
    hotel.image = ProtocolStripper.stripProtocol(images[0].path); // Originally mobile was with source_path which is the largest picture
  }
}

function setRooms(hotel, rooms) {
  if (Array.isArray(rooms) && (rooms.length > 0)) {
    hotel.rooms = rooms.sort((a, b) => a.name - b.name);

    for (const room of hotel.rooms) {
      if (room && Array.isArray(room.images) && (room.images.length > 0)) {
        room.images.sort((a, b) => (a.display_order - b.display_order));
        room.images = ImageUtils.transformImagesToGallery(room.images);
      }
    }
  }
}

function setGeoJSON(hotel, lon_lat) {
  if (lon_lat && Array.isArray(lon_lat.coordinates)) {
    hotel.geoJSON = {
      "type": "Point",
      "coordinates": lon_lat.coordinates // [lon, lat]
    };
  }
}

function setReview(hotel, review) {
  if (review && (review.trustyou_id || review.count >= 10)) {
    hotel.hasReview = true;
    hotel.review = new Review({
      ty_id: review.trustyou_id,
      votes: review.count,
      score: review.score,
      description: review.score_description
    })
  }
}

module.exports = {
  isValidRawHotel,
  setImages,
  setRooms,
  setGeoJSON,
  setReview
};
