import React from 'react';

import withStyles from '@mui/styles/withStyles';

import _ from 'lodash';
import PropTypes from 'prop-types';

const styles = () => ({
	oops: {
		display: 'block',
		width: '100%',
		border: '1px solid #ccc',
		borderRadius: '4px',
		marginBottom: '20px',
		fontSize: '19px',
		marginTop: '49px',
		textAlign: 'center',
		padding: '25px',
	},
});

class Oops extends React.Component {
	constructor(props) {
		super(props);

		this.try = this.try.bind(this);
	}

	try() {
		let { tryAgain } = this.props;

		if (_.isFunction(tryAgain)) {
			tryAgain();
		}
	}

	render() {
		let { classes, fullName, email } = this.props;

		let supportEmail = email ? email : 'support@arbitrip.com';
		let subject = 'Arbitrip for Corporate Request' + (fullName ? 'For ' + fullName : '');
		let mailtoUpdate = encodeURI('mailto:' + supportEmail + '?subject=' + subject);

		return (
			<div className={classes.oops}>
				Oops, it looks like something went wrong. Please{' '}
				<a href="#!" onClick={this.try}>
					Try again
				</a>{' '}
				or <a href={mailtoUpdate}>contact us</a>
			</div>
		);
	}
}

Oops.propTypes = {
	tryAgain: PropTypes.func,
	email: PropTypes.string,
	fullName: PropTypes.string,
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Oops);
