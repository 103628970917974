import _ from 'lodash';
import { Info as InfoIcon } from '@mui/icons-material';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import { ProfileConstants } from 'arbitrip-common/client/constants';
import React from 'react';
import Currencies from '../../../utils/Currencies';
import NumberFormatter from '../../../utils/NumberFormatter';

const NOT_AVAILABLE = 'N/A';

const getPointsDescription = () => {
	return `Our loyalty program allows you to earn points on each reservation you make. \ 
    You can then use these points as credit towards future reservations in Arbitrip. \ 
    The more you book the more you save!`;
};

const LoyaltyBox = ({ status, balance, upcomingValue, hasReservation, reservation, points = {}, profile }) => {
	const busy = status === ProfileConstants.STATUS.BUSY;
	const points_ex_rate = points.points_ex_rate;
	const balance_currency_value = Currencies.getPriceWithDisplayCurrencyByPrecision(
		points.balance * points_ex_rate,
		1,
		_.get(profile, 'company_currency'),
		2,
	);
	const upcoming_currency_value = Currencies.getPriceWithDisplayCurrencyByPrecision(
		points.upcoming_points_value * points_ex_rate,
		1,
		_.get(profile, 'company_currency'),
		2,
	);
	const points_upcoming_tooltip_text =
		'Upcoming points are updated every 24 hours.\r\nPoints are only available to use two weeks after the checkout of a reservation.';

	const valid_balance = _.isNumber(balance);
	const valid_upcoming = _.isNumber(upcomingValue);

	return (
		<div className={`loyalty-box${hasReservation ? ' reservation' : ''}`}>
			<div className="loyalty-info">
				<div className="points-data">
					<div className="points-icon-container">
						<div className="points-icon"></div>
					</div>
					<div className="points-amount points-balance">
						<div className="amount-title">Your balance</div>
						<div className="amount">
							{busy ? (
								<CircularProgress size={32} style={{ marginBottom: 6 }} />
							) : (
								<React.Fragment>
									<div className="amount-points">
										{valid_balance ? NumberFormatter.format(balance) : NOT_AVAILABLE}
									</div>
									{valid_balance && (
										<React.Fragment>
											<div className="amount-label">{balance === 1 ? 'Point' : 'Points'}</div>
											<div className="amount-points-value">({balance_currency_value})</div>
										</React.Fragment>
									)}
								</React.Fragment>
							)}
						</div>
					</div>
					<div className="points-amount points-upcoming">
						<div className="amount-title">
							Upcoming{' '}
							<Tooltip
								PopperProps={{ modifiers: [{ name: 'offset', options: { offset: [0, -10] } }] }}
								placement="top"
								className="points-upcoming-tooltip"
								title={<div style={{ whiteSpace: 'pre-line' }}>{points_upcoming_tooltip_text}</div>}
							>
								<IconButton>
									<InfoIcon />
								</IconButton>
							</Tooltip>
						</div>
						<div className="amount">
							{busy ? (
								<CircularProgress size={32} style={{ marginBottom: 6 }} />
							) : (
								<React.Fragment>
									<div className="amount-points">
										{valid_upcoming ? NumberFormatter.format(upcomingValue) : NOT_AVAILABLE}
									</div>
									{valid_upcoming && (
										<React.Fragment>
											<div className="amount-label">
												{upcomingValue === 1 ? 'Point' : 'Points'}
											</div>
											<div className="amount-points-value">({upcoming_currency_value})</div>
										</React.Fragment>
									)}
								</React.Fragment>
							)}
						</div>
					</div>
				</div>
				<div className="points-description">{getPointsDescription(reservation)}</div>
			</div>
		</div>
	);
};

export default LoyaltyBox;
