const keyMirror = require('keymirror');

export const EVENTS = keyMirror({
    CREATED_TRAVELER: null,
    FAILED_CREATE_TRAVELER: null,
    PICKED_TRAVELER_FROM_AUTOCOMPLETE: null,
    REMOVED_TRAVELER: null,
    BOOKING_CREATED: null,
    RESERVATION_CANCELED: null,
    RESERVATION_CANCELED_FAILED: null,
    RESERVATION_FAILED_TO_BOOK: null,
    CONTACT_FIELD_ERROR: null,
    TRAVELER_FIELD_ERROR: null,
    SHOWED_VOUCHER: null,
    DOWNLOADED_INVOICE: null,
    ADDED_AS_QUOTE: null,
    ADDED_CONTACT_DETAILS: null,
    ADDED_TRAVELER_DETAILS: null,
    ADDED_SPECIAL_REQUESTS: null,
    CHANGE_INSTALLMENT: null,
    CHANGE_INSTALLMENT_ERROR: null,
    REQUEST_OUT_OF_POLICY_ROOM: null
});