import _ from 'lodash';
import { EventEmitter } from 'events';

class RoomMapping extends EventEmitter {
    constructor(settings = {}) {
        super();

        this.finished_process = false;
        this.settings = settings;
        
        this.mapping = {};
        this.room_suppliers = [];

        this.unified_catalog = null;
        this.vocabulary = null;
        this.original_vocabulary = null;
    }

    setFinishedProcess(finished) {
        this.finished_process = finished;
    }

    setUnifiedCatalog(data) {
        this.unified_catalog = data
    }

    setVocabulary(data) {
        if (_.isString(data)) {
            data = JSON.parse(data);
        }

        if (!this.original_vocabulary) {
            this.original_vocabulary = data;
        }

        this.vocabulary = data
    }

    resetVocabulary() {
        this.vocabulary = this.original_vocabulary;
    }

    addMapping(data = {}) {
        this.mapping = data;
    }

    addRoomSuppliers(data = []) {
        this.room_suppliers = data;
    }
}

export default RoomMapping;
