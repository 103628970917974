import React from 'react';

import { withFormsy } from 'formsy-react';
import { TextField, FormHelperText, FormControl } from '@mui/material';

import PropTypes from 'prop-types';

class InputField extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			blur: false,
		};

		this.setBlur = this.setBlur.bind(this);
		this.changeValue = this.changeValue.bind(this);
	}

	setBlur(bool) {
		this.setState({ blur: bool });
	}

	changeValue(event) {
		let { firstCapitalLetter } = this.props;

		let value = event.currentTarget.value;
		if (firstCapitalLetter) {
			value = value.charAt(0).toUpperCase() + value.slice(1);
		}
		// setValue() will set the value of the component, which in
		// turn will validate it and the rest of the form
		// Important: Don't skip this step. This pattern is required
		// for Formsy to work.
		this.props.setValue(value);
	}

	render() {
		let { blur } = this.state;
		let { label, placeholder, autoFocus, required, touched, inputLabelProps, errorMessage, showRequired, value } =
			this.props;
		// An error message is returned only if the component is invalid

		return (
			<div>
				<FormControl style={{ width: '100%' }} error={(touched || blur) && (!!errorMessage || showRequired)}>
					<TextField
						autoFocus={autoFocus}
						margin="dense"
						error={(touched || blur) && (!!errorMessage || showRequired)}
						label={label}
						placeholder={placeholder}
						type="text"
						onFocus={() => this.setBlur(false)}
						onBlur={() => this.setBlur(true)}
						onChange={this.changeValue}
						value={value || ''}
						disabled={this.props.disabled}
						fullWidth
						InputLabelProps={inputLabelProps}
						required={required}
					/>

					{(touched || blur) && (
						<React.Fragment>
							{!showRequired && !!errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
							{showRequired && <FormHelperText>Field is required</FormHelperText>}
						</React.Fragment>
					)}
				</FormControl>
			</div>
		);
	}
}

InputField.propTypes = {
	label: PropTypes.string,
	placeholder: PropTypes.string,
	disabled: PropTypes.bool,
	autoFocus: PropTypes.bool,
	required: PropTypes.bool,
	touched: PropTypes.bool,
	value: PropTypes.any,
	setValue: PropTypes.func,
	errorMessage: PropTypes.string,
	showRequired: PropTypes.bool,
	isPristine: PropTypes.bool,
	firstCapitalLetter: PropTypes.bool,
	inputLabelProps: PropTypes.object,
};

export default withFormsy(InputField);
