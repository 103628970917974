const { getHighestRole } = require('../../utils/RolesService');

const getUserObject = (user) => {
    if (!user) {
        return;
    }

    return {
        ...(user.id && { user_id: user.id }),
        ...(user.full_name && { full_name: user.full_name }),
        ...(user.first_name && { first_name: user.first_name }),
        ...(user.last_name && { last_name: user.last_name }),
        ...(user.email && { email: user.email }),
        ...(user.private_travel && { private_travel: user.private_travel }),
        ...(user.company && user.company.id && { company_id: user.company.id }),
        ...(user.company && user.company.name && { company_name: user.company.name }),
        ...(user.language && { language: user.language }),
        ...(user.currency && { currency: user.currency }),
        ...(user.display_currency && { display_currency: user.display_currency }),
        ...(user.display_currency_exchange_rate && { display_currency_exchange_rate: user.display_currency_exchange_rate }),
        ...(user.anonymous && { anonymous: user.anonymous }),
        ...(user.loyalty && { loyalty: !!user.loyalty.enabled }),
        ...(user.loyalty && user.loyalty.points_provider && { points_provider: user.loyalty.points_provider }),
        ...(user.roles && user.roles.length > 0 && { role: getHighestRole(user.roles) }),
        ...({ variant_type: (user.company && user.company.settings && user.company.settings.enabled_room_mapping ? 'room mapping' : 'Baseline') })
    };
};

module.exports = {
    getUserObject
};