import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
// import WebStorageManager from '../../../utils/WebStorageManager';
// import { ProfileConstants } from 'arbitrip-common/client/constants';
class HotelTempBookmark extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			container_style: {
				position: 'absolute',
				left: 0,
				top: 4,
			},
			style: {
				background: '#ccc',
				padding: '1px 6px 2px 4px',
				borderRadius: '0 4px 4px 0',
				fontSize: 12.5,
				display: 'block',
				textAlign: 'left',
			},
		};
	}

	normalizeScore(score) {
		return _.isNumber(score) ? Number(score.toFixed(2)).toLocaleString() : '?';
	}

	renderResearchDebug() {
		const { style } = this.state;

		// const recommendation_mode = WebStorageManager.getFromWebStorage('_recommendation_mode') || ProfileConstants.RECOMMENDATION_MODES.BY_REGION;
		// const research_normal_scores_path = (recommendation_mode === ProfileConstants.RECOMMENDATION_MODES.BY_RADIUS)
		//     ? 'research.normal'
		//     : 'scores.normal';
		// const research_normal_scores = _.get(this.props.hotel, research_normal_scores_path);

		const {
			hotel_score,
			price_score,
			distance_score,
			total_score,
			// debug = {}
			// } = research_normal_scores
		} = _.get(this.props.hotel, 'scores.normal', {});

		return (
			<div style={{ borderTop: '1px solid #888' }}>
				<span style={style}>HotelScore: {this.normalizeScore(hotel_score)}</span>
				<span style={style}>PriceScore: {this.normalizeScore(price_score)}</span>
				<span style={style}>DistanceScore: {this.normalizeScore(distance_score)}</span>
				<span style={style}>TotalScore: {this.normalizeScore(total_score)}</span>
			</div>
		);
	}

	render() {
		const { hotel } = this.props;
		const { container_style, style } = this.state;

		// const diff = hotel.recommendation_final_score - hotel.recommendation_original_score;
		// const score = `${Math.round(hotel.recommendation_final_score).toLocaleString()}${diff !== 0 ? ` (Diff: ${diff > 0 ? '+' : ''}${Math.round(diff).toLocaleString()})` : ''}`;
		const score = Number(hotel.recommendation_final_score.toFixed(2)).toLocaleString();

		const providers_by_symbol = {};
		for (const deal of hotel.deals || []) {
			const symbol = _.get(deal, 'id', '?').split(':')[0];
			if (providers_by_symbol[symbol]) {
				providers_by_symbol[symbol]++;
			} else {
				providers_by_symbol[symbol] = 1;
			}
		}
		const providers = Object.keys(providers_by_symbol)
			.map((symbol) => `${symbol}-${providers_by_symbol[symbol]}`)
			.join(' | ');

		return (
			<div style={container_style}>
				<span style={style}>Score: {score}</span>
				{providers && <span style={style}>Providers: {providers}</span>}
				{this.renderResearchDebug(hotel)}
			</div>
		);
	}
}

HotelTempBookmark.propTypes = {
	hotel: PropTypes.object.isRequired,
};

export default HotelTempBookmark;
