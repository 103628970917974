const CONSTANTS = module.exports = {};

CONSTANTS.COUNTRY_CODES = {
    AF: 'AF',
    AX: 'AX',
    AL: 'AL',
    DZ: 'DZ',
    AS: 'AS',
    AD: 'AD',
    AO: 'AO',
    AI: 'AI',
    AQ: 'AQ',
    AG: 'AG',
    AR: 'AR',
    AM: 'AM',
    AW: 'AW',
    AU: 'AU',
    AT: 'AT',
    AZ: 'AZ',
    BS: 'BS',
    BH: 'BH',
    BD: 'BD',
    BB: 'BB',
    BY: 'BY',
    BE: 'BE',
    BZ: 'BZ',
    BJ: 'BJ',
    BM: 'BM',
    BT: 'BT',
    BO: 'BO',
    BQ: 'BQ',
    BA: 'BA',
    BW: 'BW',
    BV: 'BV',
    BR: 'BR',
    IO: 'IO',
    BN: 'BN',
    BG: 'BG',
    BF: 'BF',
    BI: 'BI',
    KH: 'KH',
    CM: 'CM',
    CA: 'CA',
    CV: 'CV',
    KY: 'KY',
    CF: 'CF',
    TD: 'TD',
    CL: 'CL',
    CN: 'CN',
    CX: 'CX',
    CC: 'CC',
    CO: 'CO',
    KM: 'KM',
    CG: 'CG',
    CD: 'CD',
    CK: 'CK',
    CR: 'CR',
    CI: 'CI',
    HR: 'HR',
    CU: 'CU',
    CW: 'CW',
    CY: 'CY',
    CZ: 'CZ',
    DK: 'DK',
    DJ: 'DJ',
    DM: 'DM',
    DO: 'DO',
    EC: 'EC',
    EG: 'EG',
    SV: 'SV',
    GQ: 'GQ',
    ER: 'ER',
    EE: 'EE',
    ET: 'ET',
    FK: 'FK',
    FO: 'FO',
    FJ: 'FJ',
    FI: 'FI',
    FR: 'FR',
    GF: 'GF',
    PF: 'PF',
    TF: 'TF',
    GA: 'GA',
    GM: 'GM',
    GE: 'GE',
    DE: 'DE',
    GH: 'GH',
    GI: 'GI',
    GR: 'GR',
    GL: 'GL',
    GD: 'GD',
    GP: 'GP',
    GU: 'GU',
    GT: 'GT',
    GG: 'GG',
    GN: 'GN',
    GW: 'GW',
    GY: 'GY',
    HT: 'HT',
    HM: 'HM',
    VA: 'VA',
    HN: 'HN',
    HK: 'HK',
    HU: 'HU',
    IS: 'IS',
    IN: 'IN',
    ID: 'ID',
    IR: 'IR',
    IQ: 'IQ',
    IE: 'IE',
    IM: 'IM',
    IL: 'IL',
    IT: 'IT',
    JM: 'JM',
    JP: 'JP',
    JE: 'JE',
    JO: 'JO',
    KZ: 'KZ',
    KE: 'KE',
    KI: 'KI',
    KP: 'KP',
    KR: 'KR',
    XK: 'XK',
    KW: 'KW',
    KG: 'KG',
    LA: 'LA',
    LV: 'LV',
    LB: 'LB',
    LS: 'LS',
    LR: 'LR',
    LY: 'LY',
    LI: 'LI',
    LT: 'LT',
    LU: 'LU',
    MO: 'MO',
    MK: 'MK',
    MG: 'MG',
    MW: 'MW',
    MY: 'MY',
    MV: 'MV',
    ML: 'ML',
    MT: 'MT',
    MH: 'MH',
    MQ: 'MQ',
    MR: 'MR',
    MU: 'MU',
    YT: 'YT',
    MX: 'MX',
    FM: 'FM',
    MD: 'MD',
    MC: 'MC',
    MN: 'MN',
    ME: 'ME',
    MS: 'MS',
    MA: 'MA',
    MZ: 'MZ',
    MM: 'MM',
    NA: 'NA',
    NR: 'NR',
    NP: 'NP',
    NL: 'NL',
    NC: 'NC',
    NZ: 'NZ',
    NI: 'NI',
    NE: 'NE',
    NG: 'NG',
    NU: 'NU',
    NF: 'NF',
    MP: 'MP',
    NO: 'NO',
    OM: 'OM',
    PK: 'PK',
    PW: 'PW',
    PS: 'PS',
    PA: 'PA',
    PG: 'PG',
    PY: 'PY',
    PE: 'PE',
    PH: 'PH',
    PN: 'PN',
    PL: 'PL',
    PT: 'PT',
    PR: 'PR',
    QA: 'QA',
    RE: 'RE',
    RO: 'RO',
    RU: 'RU',
    RW: 'RW',
    BL: 'BL',
    SH: 'SH',
    KN: 'KN',
    LC: 'LC',
    MF: 'MF',
    PM: 'PM',
    VC: 'VC',
    WS: 'WS',
    SM: 'SM',
    ST: 'ST',
    SA: 'SA',
    SN: 'SN',
    RS: 'RS',
    SC: 'SC',
    SL: 'SL',
    SG: 'SG',
    SX: 'SX',
    SK: 'SK',
    SI: 'SI',
    SB: 'SB',
    SO: 'SO',
    ZA: 'ZA',
    GS: 'GS',
    SS: 'SS',
    ES: 'ES',
    LK: 'LK',
    SD: 'SD',
    SR: 'SR',
    SJ: 'SJ',
    SZ: 'SZ',
    SE: 'SE',
    CH: 'CH',
    SY: 'SY',
    TW: 'TW',
    TJ: 'TJ',
    TZ: 'TZ',
    TH: 'TH',
    TL: 'TL',
    TG: 'TG',
    TK: 'TK',
    TO: 'TO',
    TT: 'TT',
    TN: 'TN',
    TR: 'TR',
    TM: 'TM',
    TC: 'TC',
    TV: 'TV',
    UG: 'UG',
    UA: 'UA',
    AE: 'AE',
    GB: 'GB',
    US: 'US',
    UM: 'UM',
    UY: 'UY',
    UZ: 'UZ',
    VU: 'VU',
    VE: 'VE',
    VN: 'VN',
    VG: 'VG',
    VI: 'VI',
    WF: 'WF',
    EH: 'EH',
    YE: 'YE',
    ZM: 'ZM',
    ZW: 'ZW',
};

CONSTANTS.COUNTRIES = { // index 0 of value is the representative name we will use
    "AF": ["Afghanistan"],
    "AX": ["Åland Islands"],
    "AL": ["Albania"],
    "DZ": ["Algeria"],
    "AS": ["American Samoa"],
    "AD": ["Andorra"],
    "AO": ["Angola"],
    "AI": ["Anguilla"],
    "AQ": ["Antarctica"],
    "AG": ["Antigua and Barbuda"],
    "AR": ["Argentina"],
    "AM": ["Armenia"],
    "AW": ["Aruba"],
    "AU": ["Australia"],
    "AT": ["Austria"],
    "AZ": ["Azerbaijan"],
    "BS": ["Bahamas"],
    "BH": ["Bahrain"],
    "BD": ["Bangladesh"],
    "BB": ["Barbados"],
    "BY": ["Belarus", " Belarus"],
    "BE": ["Belgium"],
    "BZ": ["Belize"],
    "BJ": ["Benin"],
    "BM": ["Bermuda"],
    "BT": ["Bhutan"],
    "BO": ["Bolivia", "Bolivia, Plurinational State of"],
    "BQ": ["Bonaire, Sint Eustatius and Saba", "Bonaire, Sint Eustatius Und Saba"],
    "BA": ["Bosnia and Herzegovina"],
    "BW": ["Botswana"],
    "BV": ["Bouvet Island"],
    "BR": ["Brazil", "Brasil"],
    "IO": ["British Indian Ocean Territory"],
    "BN": ["Brunei Darussalam"],
    "BG": ["Bulgaria"],
    "BF": ["Burkina Faso"],
    "BI": ["Burundi"],
    "KH": ["Cambodia"],
    "CM": ["Cameroon"],
    "CA": ["Canada"],
    "CV": ["Cape Verde"],
    "KY": ["Cayman Islands"],
    "CF": ["Central African Republic"],
    "TD": ["Chad"],
    "CL": ["Chile"],
    "CN": ["China", "People's Republic of China"], // if adding please update also hong kong and macau
    "CX": ["Christmas Island"],
    "CC": ["Cocos (Keeling) Islands"],
    "CO": ["Colombia"],
    "KM": ["Comoros"],
    "CG": ["Congo"],
    "CD": ["Congo, the Democratic Republic of the", "Democratic Republic of the Congo"],
    "CK": ["Cook Islands"],
    "CR": ["Costa Rica"],
    "CI": ["Côte d'Ivoire", "Côte D'Ivoire"],
    "HR": ["Croatia"],
    "CU": ["Cuba"],
    "CW": ["Curaçao", "CURAÇAO"],
    "CY": ["Cyprus"],
    "CZ": ["Czech Republic"],
    "DK": ["Denmark"],
    "DJ": ["Djibouti"],
    "DM": ["Dominica"],
    "DO": ["Dominican Republic"],
    "EC": ["Ecuador"],
    "EG": ["Egypt"],
    "SV": ["El Salvador"],
    "GQ": ["Equatorial Guinea"],
    "ER": ["Eritrea"],
    "EE": ["Estonia"],
    "ET": ["Ethiopia"],
    "FK": ["Falkland Islands (Malvinas)"],
    "FO": ["Faroe Islands"],
    "FJ": ["Fiji"],
    "FI": ["Finland"],
    "FR": ["France"],
    "GF": ["French Guiana"],
    "PF": ["French Polynesia"],
    "TF": ["French Southern Territories"],
    "GA": ["Gabon"],
    "GM": ["Gambia", "The Gambia"],
    "GE": ["Georgia"],
    "DE": ["Germany"],
    "GH": ["Ghana"],
    "GI": ["Gibraltar"],
    "GR": ["Greece"],
    "GL": ["Greenland"],
    "GD": ["Grenada"],
    "GP": ["Guadeloupe"],
    "GU": ["Guam"],
    "GT": ["Guatemala"],
    "GG": ["Guernsey"],
    "GN": ["Guinea"],
    "GW": ["Guinea-Bissau"],
    "GY": ["Guyana"],
    "HT": ["Haiti"],
    "HM": ["Heard Island and McDonald Islands"],
    "VA": ["Holy See (Vatican City State)"],
    "HN": ["Honduras"],
    "HK": ["Hong Kong", "CN", "China", "People's Republic of China"],  //adding china as some suppliers map to mainland
    "HU": ["Hungary"],
    "IS": ["Iceland"],
    "IN": ["India"],
    "ID": ["Indonesia"],
    "IR": ["Iran", "Iran, Islamic Republic of"],
    "IQ": ["Iraq"],
    "IE": ["Ireland", "Republic of Ireland"],
    "IM": ["Isle of Man"],
    "IL": ["Israel"],
    "IT": ["Italy"],
    "JM": ["Jamaica"],
    "JP": ["Japan"],
    "JE": ["Jersey"],
    "JO": ["Jordan"],
    "KZ": ["Kazakhstan", " Kazakhstan"],
    "KE": ["Kenya"],
    "KI": ["Kiribati"],
    "KP": ["Korea, Democratic People's Republic of"],
    "KR": ["Korea, Republic of", "South Korea"],
    "XK": ["Kosovo"],
    "KW": ["Kuwait"],
    "KG": ["Kyrgyzstan"],
    "LA": ["Lao People's Democratic Republic", "Laos"],
    "LV": ["Latvia"],
    "LB": ["Lebanon"],
    "LS": ["Lesotho"],
    "LR": ["Liberia"],
    "LY": ["Libya"],
    "LI": ["Liechtenstein"],
    "LT": ["Lithuania"],
    "LU": ["Luxembourg"],
    "MO": ["Macau", "Macao", "CN", "China", "People's Republic of China"], //adding china as some suppliers map to mainland
    "MK": ["Macedonia", "Macedonia, the former Yugoslav Republic of", "Republic of Macedonia"],
    "MG": ["Madagascar"],
    "MW": ["Malawi"],
    "MY": ["Malaysia"],
    "MV": ["Maldives"],
    "ML": ["Mali"],
    "MT": ["Malta"],
    "MH": ["Marshall Islands"],
    "MQ": ["Martinique"],
    "MR": ["Mauritania"],
    "MU": ["Mauritius"],
    "YT": ["Mayotte"],
    "MX": ["Mexico"],
    "FM": ["Micronesia, Federated States of"],
    "MD": ["Moldova, Republic of", "Moldova"],
    "MC": ["Monaco"],
    "MN": ["Mongolia"],
    "ME": ["Montenegro"],
    "MS": ["Montserrat"],
    "MA": ["Morocco"],
    "MZ": ["Mozambique"],
    "MM": ["Myanmar"],
    "NA": ["Namibia"],
    "NR": ["Nauru"],
    "NP": ["Nepal"],
    "NL": ["Netherlands"],
    "NC": ["New Caledonia"],
    "NZ": ["New Zealand"],
    "NI": ["Nicaragua"],
    "NE": ["Niger"],
    "NG": ["Nigeria"],
    "NU": ["Niue"],
    "NF": ["Norfolk Island"],
    "MP": ["Northern Mariana Islands"],
    "NO": ["Norway"],
    "OM": ["Oman"],
    "PK": ["Pakistan"],
    "PW": ["Palau"],
    "PS": ["Palestinian Territory", "Palestinian Territory, Occupied", "West Bank and Gaza Strip"],
    "PA": ["Panama"],
    "PG": ["Papua New Guinea"],
    "PY": ["Paraguay"],
    "PE": ["Peru"],
    "PH": ["Philippines"],
    "PN": ["Pitcairn"],
    "PL": ["Poland"],
    "PT": ["Portugal"],
    "PR": ["Puerto Rico"],
    "QA": ["Qatar"],
    "RE": ["Réunion", "Reunion"],
    "RO": ["Romania"],
    "RU": ["Russia", "Russian Federation", " Russia"],
    "RW": ["Rwanda"],
    "BL": ["Saint Barthélemy", "Saint Barthelemy"],
    "SH": ["Saint Helena, Ascension and Tristan da Cunha"],
    "KN": ["Saint Kitts and Nevis"],
    "LC": ["Saint Lucia"],
    "MF": ["Saint Martin (French part)", "Sint Marteen"],
    "PM": ["Saint Pierre and Miquelon"],
    "VC": ["Saint Vincent and the Grenadines"],
    "WS": ["Samoa"],
    "SM": ["San Marino", "San Marino (Republic Of)"],
    "ST": ["Sao Tome and Principe", "Sao Tome And Principe"],
    "SA": ["Saudi Arabia", "Saudia"],
    "SN": ["Senegal"],
    "RS": ["Serbia"],
    "SC": ["Seychelles"],
    "SL": ["Sierra Leone"],
    "SG": ["Singapore"],
    "SX": ["Sint Maarten (Dutch part)"],
    "SK": ["Slovakia"],
    "SI": ["Slovenia"],
    "SB": ["Solomon Islands"],
    "SO": ["Somalia"],
    "ZA": ["South Africa"],
    "GS": ["South Georgia and the South Sandwich Islands"],
    "SS": ["South Sudan"],
    "ES": ["Spain"],
    "LK": ["Sri Lanka", "Sri-Lanka"],
    "SD": ["Sudan"],
    "SR": ["Suriname"],
    "SJ": ["Svalbard and Jan Mayen"],
    "SZ": ["Swaziland"],
    "SE": ["Sweden"],
    "CH": ["Switzerland"],
    "SY": ["Syria", "Syrian Arab Republic"],
    "TW": ["Taiwan", "Taiwan, Province of China", "Republic of China Taiwan"],
    "TJ": ["Tajikistan"],
    "TZ": ["Tanzania", "Tanzania, United Republic of"],
    "TH": ["Thailand"],
    "TL": ["Timor-Leste"],
    "TG": ["Togo"],
    "TK": ["Tokelau"],
    "TO": ["Tonga"],
    "TT": ["Trinidad and Tobago", "Trinidad Tobago", "Trinidad  Tobago"],
    "TN": ["Tunisia"],
    "TR": ["Turkey"],
    "TM": ["Turkmenistan"],
    "TC": ["Turks and Caicos Islands"],
    "TV": ["Tuvalu"],
    "UG": ["Uganda", "Republic of Uganda"],
    "UA": ["Ukraine", " Ukraine"],
    "AE": ["United Arab Emirates"],
    "GB": ["United Kingdom"],
    "US": ["United States"],
    "UM": ["United States Minor Outlying Islands"],
    "UY": ["Uruguay"],
    "UZ": ["Uzbekistan", " Uzbekistan"],
    "VU": ["Vanuatu"],
    "VE": ["Venezuela", "Venezuela, Bolivarian Republic of"],
    "VN": ["Vietnam", "Viet Nam"],
    "VG": ["Virgin Islands, British"],
    "VI": ["Virgin Islands, U.S.", "U.S. Virgin Islands", "Virgin Islands (Usa)"],
    "WF": ["Wallis and Futuna"],
    "EH": ["Western Sahara"],
    "YE": ["Yemen", "Yemen Republic"],
    "ZM": ["Zambia"],
    "ZW": ["Zimbabwe"]
};

CONSTANTS.GEO_PROPS = {
    CONTINENT: 'continent',
    COUNTRY: 'country',
    STATE: 'state',
    CITY: 'city',
};

CONSTANTS.STRENGTH_LEVELS = {
    NONE: 0,
    GEO: 1,
    'GEO.CONTINENT': 1.1,
    'GEO.COUNTRY': 1.2,
    'GEO.STATE': 1.3,
    'GEO.CITY': 1.4,
    PROPS: 2,
    ID: 3
};