import React from 'react';
import createClass from 'create-react-class';

import FiltersActions from '../../../../actions/FiltersActions';

import FiltersConstants from '../../../../constants/FiltersConstants';
import Analytics from 'arbitrip-common/client/analytics';

import FilterCheckboxWithLabel from './components/FilterCheckboxWithLabel.react';

function getReviewScore(review) {
	switch (review) {
		case FiltersConstants.REVIEW_LEVELS.EXCELLENT:
			return 8.6;
		case FiltersConstants.REVIEW_LEVELS.VERY_GOOD:
			return 8.0;
		case FiltersConstants.REVIEW_LEVELS.GOOD:
			return 7.5;
		case FiltersConstants.REVIEW_LEVELS.FAIR:
			return 6.8;
		default: // Do nothing
	}
	return null;
}

const ReviewFilter = createClass({
	displayName: 'ReviewFilter',

	getInitialState: function () {
		return { reviewOnly: -1 };
	},

	handleCheckboxChangeByReview: function (event, isInputChecked, review) {
		const { profile } = this.props;

		event.persist();
		FiltersActions.filterByReview(review, isInputChecked);
		setTimeout(function () {
			FiltersActions.filterByReview(review, isInputChecked);
		}, 10);

		const filter_value = (isInputChecked ? 'Show ' : 'Hide ') + review + ' Review';
		Analytics.actions.interactions.filteredResults(profile, FiltersConstants.FILTER_METHOD.REVIEW, filter_value);
	},

	// TODO: if budget user (from profile)
	// TODO: or flag show_unknown_quality_hotel_categories is on (in company or in user - user is stronger than comapny)
	// TODO: [default is budget or not?]
	// TODO: add 2 review filtering

	handleMouseEnter: function (e) {
		this.setState({
			reviewOnly: e.target.getAttribute('data-review'),
		});
	},

	handleMouseLeave: function () {
		this.setState({
			reviewOnly: -1,
		});
	},

	handleReviewOnlyClick: function (e) {
		const { profile } = this.props;

		e.persist();
		var filterReviewOnly = e.target.getAttribute('data-review');
		FiltersActions.filterByReviewOnly(filterReviewOnly);
		setTimeout(function () {
			FiltersActions.filterByReviewOnly(filterReviewOnly);
		}, 10);

		const filter_value = `Show Only ${filterReviewOnly} Review`;
		Analytics.actions.interactions.filteredResults(
			profile,
			FiltersConstants.FILTER_METHOD.REVIEW_ONLY,
			filter_value,
		);
	},

	handleResetReviewClick: function (e) {
		const { profile } = this.props;

		e.persist();
		FiltersActions.resetReviewFilter();
		setTimeout(function () {
			FiltersActions.resetReviewFilter();
		}, 10);

		const filter_value = 'Reset Review Filter';
		Analytics.actions.interactions.filteredResults(
			profile,
			FiltersConstants.FILTER_METHOD.RESET_REVIEW_FILTER,
			filter_value,
		);
	},

	render() {
		let REVIEWS = [];
		const data = this.props.filter;
		// for (let i = 5; i >= 2; i--) {
		for (let review in data) {
			const formatted_review = review.replace(' ', '-').toLowerCase();
			const reviewFilterKey = `${formatted_review}-review-filter`;
			const score = getReviewScore(review);
			const review_clone = (' ' + review).slice(1);
			const review_label = score ? (
				<span>
					{review}: {score}+
				</span>
			) : (
				<span>{review}</span>
			);
			REVIEWS.push(
				<div
					className={
						'checkbox-container' + (review === FiltersConstants.REVIEW_LEVELS.UNRATED ? ' hidden' : '')
					}
					key={reviewFilterKey}
				>
					<FilterCheckboxWithLabel
						checked={data[review]}
						label={review_label}
						onChange={(e, c) => this.handleCheckboxChangeByReview(e, c, review_clone)}
					/>

					<button
						className="btn btn-link"
						data-review={review}
						onClick={this.handleReviewOnlyClick}
						aria-label={`Select ${review} ${score && score} only`}
					>
						Only
					</button>
				</div>,
			);
		}

		return (
			<div className="filter disabled">
				<label className="filter-title" htmlFor="review-filter">
					Review Score
				</label>
				<div name="review-filter" className="check-list" aria-label="Review Score filters">
					{REVIEWS}
				</div>
				<button
					className="clear-button-wrap"
					onClick={this.handleResetReviewClick}
					aria-label="Reset Review Score filters"
				>
					<div className="clear-button-img" />
				</button>
			</div>
		);
	},
});

export default ReviewFilter;
