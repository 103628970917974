import withStyles from '@mui/styles/withStyles';

import { Radio, RadioGroup, FormControlLabel, Typography } from '@mui/material';

import classNames from 'classnames';

import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import ProfileStore from '../../stores/ProfileStore';
import SearchFull from './SearchFull.react';
import CompaniesAutoComplete from './CompaniesAutoComplete.react';
import GroupBookings from './GroupBookings.react';
import SearchActions from '../../actions/SearchActions';
import SearchStore from '../../stores/SearchStore';

import { withTranslation } from 'react-i18next';

const styles = (theme) => ({
	radioGroup: {
		flexDirection: 'row',
		marginTop: 30,
		fontSize: 15,
		color: theme.palette.white,
		textShadow: '0 0 13px rgba(0,0,0,.4)',
	},
	radioButton: {
		color: theme.palette.white,
		'&.Mui-checked': {
			color: theme.palette.white,
		},
	},
});

function getState() {
	return {
		is_private_travel_only: ProfileStore.isPrivateTravelOnly(),
	};
}

class SearchBar extends React.Component {
	constructor(props) {
		super(props);

		this.state = getState();

		this._onChange = this._onChange.bind(this);
		this.onChangeTravelingPurpose = this.onChangeTravelingPurpose.bind(this);
	}

	getIsAgent() {
		const { profile } = this.props;
		return profile.agent && Array.isArray(profile.agency_contracts);
	}

	onChangeTravelingPurpose(e) {
		const privateTravel = e.target.value === true || e.target.value === 'true';
		SearchActions.updatePrivateTravel(privateTravel);
	}

	render() {
		const { t, profile, classes, searchTerms } = this.props;
		const { is_private_travel_only } = this.state;
		const { private_travel } = searchTerms;

		const userName = _.get(profile, 'full_name', null);
		const agent = this.getIsAgent();
		const show_companies_complete = agent && profile.agency_contracts.length > 1; // Array.isArray(profile.agency_contracts); checked in getIsAgent
		const travel_manager = _.get(profile, 'travel_manager', false);
		const group_bookings_enabled = travel_manager || agent;
		return (
			<div className="search-bar-container">
				<div className="search-bar" id="search_bar">
					{userName && <div className="search-user-name">Hello {userName}</div>}
					<div className={classNames('search-title', is_private_travel_only && 'private-travel')}>
						{t('MainTitle')}
					</div>
					<div className={classNames('search-sub-title', is_private_travel_only && 'private-travel')}>
						{t('MainSubTitle')}
					</div>
					{!agent && !is_private_travel_only && (
						<RadioGroup
							className={classes.radioGroup}
							aria-label="traveling-for"
							name="traveling-for"
							value={private_travel}
							onChange={this.onChangeTravelingPurpose}
						>
							<FormControlLabel
								value={false}
								control={<Radio className={classes.radioButton} />}
								label={
									<Typography variant="body1" style={{ fontSize: '1.15rem' }}>
										I'm traveling for business
									</Typography>
								}
							/>
							<FormControlLabel
								value={true}
								control={<Radio className={classes.radioButton} />}
								label={
									<Typography variant="body1" style={{ fontSize: '1.15rem' }}>
										I'm traveling for leisure
									</Typography>
								}
							/>
						</RadioGroup>
					)}
					<SearchFull searchTerms={searchTerms} style={agent ? { marginTop: '24px' } : {}} />

					{/* {(agent || group_bookings_enabled) &&
                        < div className="special-search-options"> */}
					{show_companies_complete && <CompaniesAutoComplete />}
					{group_bookings_enabled && <GroupBookings profile={profile} />}
					{/* </div>
                    } */}
				</div>
			</div>
		);
	}

	_onChange() {
		this.setState(getState());
	}

	componentDidMount() {
		SearchStore.addChangeListener(this._onChange);
	}

	componentWillUnmount() {
		SearchStore.removeChangeListener(this._onChange);
	}
}

SearchBar.propTypes = {
	classes: PropTypes.object.isRequired,
};

const SearchBarTranslated = withTranslation()(SearchBar);
export default withStyles(styles, { withTheme: true })(SearchBarTranslated);
