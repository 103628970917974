import { useState } from 'react';
import { TextField, InputAdornment } from '@mui/material';
import ManagementActions from '@/actions/ManagementActions';
import moment from 'moment';
import Validator from 'arbitrip-common/client/utils/Validator';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { ReactComponent as Calendar } from '@/img/icons/calendar.svg';
import { ReactComponent as Plane } from '@/img/trip_management/plane.svg';
import ManagementConstants from '@/constants/ManagementConstants';

const flights = ['KLM621', 'KLM2141', 'LH322', 'LH231', 'AA2311', 'DL312', 'LY027', 'LY001'];

function randomFlightExample() {
	let flight = flights[Math.floor(Math.random() * flights.length)];
	return 'e.g ' + flight;
}

function formatDate(date) {
	return moment(date).format('DD MMM YYYY');
}

const max_date = moment().add(2, 'years');
const max_flight_number_characters = 8;
const valid_flight_number_characters_regex = new RegExp('^[a-z0-9]{0,' + max_flight_number_characters + '}$', 'i');

const AddAirfareFlight = (props) => {
	const [flightExample, setFlightExample] = useState(randomFlightExample());
	const [hovering, setHovering] = useState(false);
	const [startOpen, setStartOpen] = useState(false);

	const handleFlightNumberOnChange = (event) => {
		let { value } = event.target;
		value = value.replace(/\s/g, '');
		if (valid_flight_number_characters_regex.test(value)) {
			ManagementActions.updateAirfareWizardFlightNumber(props.flight, value);
		}
	};

	const handleDepartDateOnClick = () => {
		let { flight, start_date } = props;
		if (!flight.departure_date && start_date)
			ManagementActions.updateAirfareWizardFlightDepartDate(props.flight, moment(start_date));
	};

	const handleDepartDateOnChange = (date) => {
		ManagementActions.updateAirfareWizardFlightDepartDate(props.flight, moment(date));
	};

	const handleRemoveOnClick = () => {
		ManagementActions.removeAirfareWizardFlight(props.flight);
	};

	let { flight_number, departure_date } = props.flight;
	let { single, parent_validity } = props;
	let multipleProps = single ? {} : { onMouseEnter: () => setHovering(true), onMouseLeave: () => setHovering(false) };
	let addAirfareFlightClasses = 'add-airfare-flight' + (hovering ? ' hovering' : '');

	return (
		<div className={addAirfareFlightClasses} {...multipleProps}>
			<div className="flight-container flex align-center">
				<Plane />
				<div className="field flight-number">
					<TextField
						autoFocus
						placeholder={flightExample}
						label="Flight Number"
						onChange={handleFlightNumberOnChange}
						value={flight_number.toUpperCase()}
						error={!parent_validity && !Validator.validateFlightNumber(flight_number)}
					/>
				</div>
				<div className="field departure-date">
					<LocalizationProvider
						dateAdapter={AdapterMoment}
						adapterLocale={ManagementConstants.ADAPTER_MOMENT_LOCALE}
					>
						<DesktopDatePicker
							format={ManagementConstants.DATE_PICKER_FORMAT}
							open={startOpen}
							onClose={() => setStartOpen(false)}
							label="Departure Date"
							fullWidth={true}
							formatDate={formatDate}
							maxDate={max_date}
							value={(moment.isMoment(departure_date) && departure_date) || null}
							onOpen={handleDepartDateOnClick}
							onChange={handleDepartDateOnChange}
							error={!parent_validity && !moment.isMoment(departure_date)}
							className="field-input"
							slotProps={{
								textField: {
									InputProps: {
										startAdornment: (
											<InputAdornment position="start">
												<Calendar />
											</InputAdornment>
										),
										onClick: (e) => {
											e.preventDefault();
											setStartOpen(true);
										},
										placeholder: '',
									},
								},
							}}
						/>
					</LocalizationProvider>
				</div>
				{hovering && <button className="remove-button" onClick={handleRemoveOnClick} />}
			</div>
		</div>
	);
};

export default AddAirfareFlight;
