const ty_api_key = process.env.REACT_APP_TRUSTYOU_API_KEY;

class Review {
  constructor(review) {
    let score = Number(review.score);
    this.score = score >= 10 ? (score / 10).toFixed(1) : score;

    this.votes = review.votes;

    this.description = review.description;

    if (review.ty_id) {
      this.ty_id = review.ty_id;
      this.ty_url = `//api.trustyou.com/hotels/${review.ty_id}/meta_review.html?key=${ty_api_key}&iframe_resizer=true&scale=100`;
    }
  }
}

module.exports = Review;
