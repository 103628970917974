import { Tab, Tabs } from '@mui/material';
import ContentLoader from 'react-content-loader';

const TAB_WIDTH = 140;
const TABS_GAP = 16;

// The loader dynamically reflects the number of tabs
const TabsLoader = ({ tabsConfig }) => (
	<div className="tabs-loader">
		<ContentLoader
			height={32}
			viewBox={`0 0 ${tabsConfig.length * TAB_WIDTH + (tabsConfig.length - 1) * TABS_GAP} 32`}
		>
			{tabsConfig.map((_, index) => (
				<rect key={index} x={index * (TAB_WIDTH + TABS_GAP)} rx="4" ry="4" width={TAB_WIDTH} height="32" />
			))}
		</ContentLoader>
	</div>
);

const BookingsTabs = ({ selectedTab, onChange, showLoader, tabsConfig }) => {
	return (
		<div className="tabs-wrapper">
			{showLoader ? (
				<TabsLoader tabsConfig={tabsConfig} />
			) : (
				<Tabs value={selectedTab} onChange={onChange} aria-label="Bookings tabs">
					{tabsConfig.map((tab, index) => (
						<Tab key={index} label={tab.label} disableRipple />
					))}
				</Tabs>
			)}
		</div>
	);
};

export default BookingsTabs;
