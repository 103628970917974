import React, { Component } from 'react';
import moment from 'moment';
import urlParamsUtil from 'arbitrip-common/general/utils/UrlParams';
import _ from 'lodash';

import ManagementActions from '../../../actions/ManagementActions';
import EntityValidator from '../../../utils/EntityValidator';

import ManagementConstants from '../../../constants/ManagementConstants';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TextField } from '@mui/material';
import MaterialDesignGooglePlacesAutocomplete from '../../MaterialDesignGooglePlacesAutocomplete.react';
import Icon from '../../ReactEvilIcon';
import styles from '../../../utils/Styles';
import ManagementStore from '../../../stores/ManagementStore';

function formatDate(date) {
	return date && moment(date).format('MMM DD');
}

function getAddAccommodationPageState() {
	return {
		txt: '',
		hovering: false,
	};
}

function destinationsAreDifferent(a, b) {
	return (a.place && !b.place) || (!a.place && b.place) || _.get(a, 'place.place_id') !== _.get(b, 'place.place_id');
}

class AddAccommodation extends Component {
	constructor(props) {
		super(props);
		this.state = getAddAccommodationPageState();
	}

	componentDidUpdate(prevProps) {
		const { accommodation } = this.props;
		const prevAccommodation = prevProps.accommodation;
		const nextAccommodation = accommodation;

		if (
			EntityValidator.validateTripRequestAccommodation(nextAccommodation) &&
			(destinationsAreDifferent(prevAccommodation.destination, nextAccommodation.destination) ||
				Boolean(moment(prevAccommodation.check_in).diff(moment(nextAccommodation.check_in), 'days')) ||
				Boolean(moment(prevAccommodation.check_out).diff(moment(nextAccommodation.check_out), 'days')))
		) {
			if (!ManagementStore.isTripRequestSaving()) {
				if (!EntityValidator.validateTripRequestComponentInitialized(nextAccommodation)) {
					ManagementActions.createTripRequestComponent(nextAccommodation);
				} else {
					ManagementActions.updateTripRequestComponent(nextAccommodation);
				}
			} else {
				ManagementActions.updateTripLater(nextAccommodation);
			}
		}
	}

	getSearchLink() {
		let trip = this.props.trip;
		let details = this.props.accommodation;
		let payload = {
			rooms: 1,
			guests: 1,
			management_trip_id: trip.id,
			management_trip_name: trip.name,
			check_in: details.check_in,
			check_out: details.check_out,
			address: details.destination.address,
			place_id: _.get(details, 'destination.place.place_id'),
		};
		if (trip.travelers) {
			let travelers_reduced = trip.travelers.map((t) => ({
				id: t._id,
				first_name: t.first_name,
				last_name: t.last_name,
				email: t.email,
				phone: t.phone,
			}));
			payload.travelers = JSON.stringify(travelers_reduced);
		}
		if (!payload.check_in || !payload.check_out || !payload.place_id) return null;
		let params = urlParamsUtil.objToURLParams(payload);
		return '/results?' + params;
	}

	handleMouseEnter = () => {
		this.setState({
			hovering: true,
		});
	};

	handleMouseLeave = () => {
		this.setState({
			hovering: false,
		});
	};

	handleCancelClick = () => {
		ManagementActions.removeTripRequestComponent(this.props.accommodation);
	};

	handleDestinationOnChange = (place, address) => {
		ManagementActions.updateAccommodationDestination(this.props.accommodation, {
			address,
			place,
		});
	};

	handleDestinationAddressOnChange = (value) => {
		this.setState({ txt: value });
		ManagementActions.updateAccommodationDestinationAddress(this.props.accommodation, value);
	};

	handleCheckInChange = (date) => {
		ManagementActions.updateAccommodationCheckIn(this.props.accommodation, moment(date));
		let { check_out } = this.props.accommodation;
		if (!check_out || moment(date).isSameOrAfter(moment(check_out), 'day')) {
			ManagementActions.updateAccommodationCheckOut(this.props.accommodation, moment(date).add(1, 'day'));
		}
	};

	handleCheckOutChange = (date) => {
		ManagementActions.updateAccommodationCheckOut(this.props.accommodation, moment(date));
	};

	render() {
		let { accommodation, disabling, travel_manager_mode, can_edit, should_validate } = this.props;
		const { txt } = this.state;

		let component_classes = 'add-accommodation add-component';
		if (this.state.hovering) {
			component_classes += ' hovering';
		}

		let check_in = accommodation.check_in && moment(accommodation.check_in);
		let check_out = accommodation.check_out && moment(accommodation.check_out);

		let menu_props = {
			listStyle: styles.listStyle,
			menuItemStyle: styles.menuItemStyle,
			selectedMenuItemStyle: styles.selectedMenuItemStyle,
		};

		let search_link = this.getSearchLink();

		return (
			<div
				className={component_classes}
				onMouseEnter={this.handleMouseEnter}
				onMouseLeave={this.handleMouseLeave}
			>
				<div className="component-icon">
					<i className="fa fa-bed fa-fw" />
				</div>
				<div className="destination left-inputs">
					{can_edit ? (
						<MaterialDesignGooglePlacesAutocomplete
							placeholder="e.g. 45th east avenue"
							label="Destination"
							fullWidth={true}
							searchText={txt || accommodation.destination.address}
							onChange={this.handleDestinationAddressOnChange}
							onNewRequest={this.handleDestinationOnChange}
							{...(disabling ? styles.disablingProps : {})}
							error={should_validate && !accommodation.destination.address}
						/>
					) : (
						<TextField
							className="field-input"
							label="Destination"
							placeholder="e.g. 45th east avenue"
							fullWidth
							value={accommodation.destination.address || ManagementConstants.NO_VALUE}
							disabled
							variant="standard"
						/>
					)}
				</div>
				<div className="dates right-inputs">
					{can_edit ? (
						<LocalizationProvider
							dateAdapter={AdapterMoment}
							adapterLocale={ManagementConstants.ADAPTER_MOMENT_LOCALE}
						>
							<DesktopDatePicker
								format={ManagementConstants.DATE_PICKER_FORMAT}
								className="field-input date check-in input"
								label="Check-in"
								placeholder="e.g Dec 20"
								value={check_in}
								onChange={this.handleCheckInChange}
								variant="standard"
								slotProps={{ textField: { variant: 'standard' } }}
							/>
						</LocalizationProvider>
					) : (
						<TextField
							className="field-input date check-in"
							label="Check-in"
							placeholder="e.g Dec 20"
							fullWidth
							value={(check_in && formatDate(check_in.toDate())) || ManagementConstants.NO_VALUE}
							disabled
							variant="standard"
						/>
					)}
					{can_edit ? (
						<LocalizationProvider
							dateAdapter={AdapterMoment}
							adapterLocale={ManagementConstants.ADAPTER_MOMENT_LOCALE}
						>
							<DesktopDatePicker
								format={ManagementConstants.DATE_PICKER_FORMAT}
								className="field-input date check-out input"
								label="Check-out"
								placeholder="e.g Dec 25"
								value={check_out}
								onChange={this.handleCheckOutChange}
								minDate={check_in ? check_in : null}
								slotProps={{ textField: { variant: 'standard' } }}
							/>
						</LocalizationProvider>
					) : (
						<TextField
							className="field-input date check-out"
							label="Check-out"
							placeholder="e.g Dec 25"
							value={(check_out && formatDate(check_out.toDate())) || ManagementConstants.NO_VALUE}
							disabled
							variant="standard"
						/>
					)}
					{/* TODO: add checkout after checkin validation to CheckOut's errorText */}
				</div>
				{search_link && travel_manager_mode && this.state.hovering ? (
					<div className="field find-link">
						<a href={search_link} target="_blank" rel="noopener noreferrer">
							Find a hotel
							<Icon name="ei-external-link" size="s" className="evil-icon" />
						</a>
					</div>
				) : null}
				{this.state.hovering && !disabling && can_edit ? (
					<div className="cancel-icon">
						<a href="#!" className="action cancel" onClick={this.handleCancelClick}>
							<Icon name="ei-close" size="s" className="evil-icon" />
						</a>
					</div>
				) : null}
			</div>
		);
	}
}

export default AddAccommodation;
