import _ from 'lodash';
import AppDispatcher from '../dispatcher/AppDispatcher';
import ReservationConstants from '../constants/ReservationConstants';
import Ajax from '../utils/Ajax';
import PaymentActions from './PaymentActions';
import BookingActions from '../actions/BookingsActions';
import { PAYMENT_TYPE } from 'arbitrip-common/general/constants';
import PaymentConstants from '../constants/PaymentConstants';

import Analytics from 'arbitrip-common/client/analytics';

import RouterWrapper from '../utils/RouterWrapper';
import SearchStore from '../stores/SearchStore';
import ResultsStore from '../stores/ResultsStore';
import DealUtils from '../entities/DealUtils';
import HotelConstants from '../constants/HotelConstants';
import { RESERVATION_STATUS } from '@arbitrip/constants';

function paymentMethodToPaymentAnalyticsMethod(payment_method) {
	//TODO move to a helper function
	switch (payment_method) {
		case PaymentConstants.PAYMENT_METHOD_TYPES.CREDIT_CARD:
			return PaymentConstants.PAYMENT_METHODS_ANALYTICS.CREDIT_CARD;
		case PaymentConstants.PAYMENT_METHOD_TYPES.PRIVATE_CARD:
			return PaymentConstants.PAYMENT_METHODS_ANALYTICS.CREDIT_CARD;
		case PaymentConstants.PAYMENT_METHOD_TYPES.COMPANY_POLICY:
			return PaymentConstants.PAYMENT_METHODS_ANALYTICS.COMPANY_POLICY;
		case PaymentConstants.PAYMENT_METHOD_TYPES.PAY_AT_HOTEL:
			return PaymentConstants.PAYMENT_METHODS_ANALYTICS.PAY_AT_HOTEL;
		case PaymentConstants.PAYMENT_METHOD_TYPES.PAY_LATER:
			return PaymentConstants.PAYMENT_METHODS_ANALYTICS.PAY_LATER;
		default:
			return null;
	}
}

function shouldContinueForPointsOnly(paymentType, res) {
	return paymentType === PAYMENT_TYPE.CREDIT_CARD && res && !res.url && res.points_only;
}

const ReservationActions = {
	// updateAgentPricing: function ({ pricing_method, absolute_price, absolute_margin, margin_percentage, valid }) {
	//   AppDispatcher.handleAction({
	//     actionType: ReservationConstants.UPDATE_AGENT_PRICING,
	//     data: {
	//       valid,
	//       pricing_method,
	//       absolute_price,
	//       absolute_margin,
	//       margin_percentage
	//     }
	//   });
	// },
	updateAgentPricing: function (agentPricing) {
		AppDispatcher.handleAction({
			actionType: ReservationConstants.UPDATE_AGENT_PRICING,
			data: agentPricing,
		});
	},

	updateAgencyClientPrice: function (agencyClientPrice) {
		AppDispatcher.handleAction({
			actionType: ReservationConstants.UPDATE_AGENCY_CLIENT_PRICE,
			data: agencyClientPrice,
		});
	},

	updateInvoiceOption: function (invoice_option) {
		AppDispatcher.handleAction({
			actionType: ReservationConstants.UPDATE_INVOICE_OPTION,
			data: {
				invoice_id: invoice_option.id,
				invoice_currency: invoice_option.currency,
			},
		});
	},

	approveReservation: function (reservation_request) {
		AppDispatcher.handleAction({
			actionType: ReservationConstants.APPROVE_REQUEST,
			data: reservation_request,
		});

		Ajax.approveCompanyRequest(reservation_request)
			.done(function (res) {
				AppDispatcher.handleAction({
					actionType: ReservationConstants.REQUEST_APPROVAL_SUCCEEDED,
					data: res,
				});

				BookingActions.getBookings();
				BookingActions.getRequests();
			})
			.fail(function (err) {
				console.error('ERROR WHILE APPROVING RESERVATION REQUEST: ', reservation_request, err);
				AppDispatcher.handleAction({
					actionType: ReservationConstants.REQUEST_APPROVAL_FAILED,
					data: {
						reservation_request: reservation_request,
						error: err,
					},
				});
			});
	},

	rejectReservation: function (reservation_request) {
		AppDispatcher.handleAction({
			actionType: ReservationConstants.REJECT_REQUEST,
			data: reservation_request,
		});

		Ajax.rejectCompanyRequest(reservation_request)
			.done(function (res) {
				AppDispatcher.handleAction({
					actionType: ReservationConstants.REQUEST_REJECTION_SUCCEEDED,
					data: res,
				});

				BookingActions.getBookings();
				BookingActions.getRequests();
			})
			.fail(function (err) {
				console.error('ERROR WHILE REJECTING RESERVATION REQUEST: ', reservation_request, err);
				AppDispatcher.handleAction({
					actionType: ReservationConstants.REQUEST_REJECTION_FAILED,
					data: {
						reservation_request: reservation_request,
						error: err,
					},
				});
			});
	},

	cancelReservation: function (profile, reservation) {
		AppDispatcher.handleAction({
			actionType: ReservationConstants.CANCEL_RESERVATION,
			data: reservation,
		});

		Ajax.cancelReservation(reservation.id)
			.done(function (res) {
				Analytics.actions.responses.reservationCanceled(profile, reservation.id, _.get(reservation, 'hotel'));

				// if 200, you can change reservation status in UI to cancelled
				AppDispatcher.handleAction({
					actionType: ReservationConstants.RESERVATION_CANCELLATION_SUCCEEDED,
					data: reservation,
				});
			})
			.fail(function (err) {
				console.error('ERROR WHILE CANCELING RESERVATION: ', reservation, err);

				Analytics.actions.responses.reservationCanceledFailed(
					profile,
					reservation.id,
					_.get(reservation, 'hotel.name'),
				);

				AppDispatcher.handleAction({
					actionType: ReservationConstants.RESERVATION_CANCELLATION_FAILED,
					data: {
						reservation: reservation,
						error: err,
					},
				});
			});
	},

	sendBookingConfirmation: function (reservation_id, profile) {
		AppDispatcher.handleAction({
			actionType: ReservationConstants.SEND_BOOKING_CONFIRMATION,
			data: reservation_id,
		});

		var sendBookingConfirmationCallback = Ajax.sendBookingConfirmationByTravelManager;
		if (profile.agent || profile.super_agent) {
			sendBookingConfirmationCallback = Ajax.sendBookingConfirmation;
		}

		sendBookingConfirmationCallback(reservation_id)
			.done(function (res) {
				AppDispatcher.handleAction({
					actionType: ReservationConstants.SEND_BOOKING_CONFIRMATION_SUCCESS,
					data: {
						reservation_id,
						response: res,
					},
				});
			})
			.fail(function (err) {
				console.error('ERROR WHILE SENDING BOOKING CONFIRMATION: ', reservation_id, err);
				AppDispatcher.handleAction({
					actionType: ReservationConstants.SEND_BOOKING_CONFIRMATION_FAILED,
					data: {
						reservation_id,
						error: err,
					},
				});
			});
	},

	resetReservationCancellationStatus: function (reservation_id) {
		AppDispatcher.handleAction({
			actionType: ReservationConstants.RESET_RESERVATION_CANCELLATION_STATUS,
			data: reservation_id,
		});
	},

	markForNonRefundableQuery: function () {
		AppDispatcher.handleAction({
			actionType: ReservationConstants.MARK_RESERVATION_FOR_NON_REFUNDABLE_QUERY,
		});
	},

	queriedNonRefundableReservation: function () {
		AppDispatcher.handleAction({
			actionType: ReservationConstants.QUERIED_NON_REFUNDABLE_RESERVATION,
		});
	},

	// Receive initial search results data
	getReservationData: function (data) {
		AppDispatcher.handleAction({
			actionType: ReservationConstants.RECEIVE_RESERVATION_DATA,
			data: data, // ???
		});
	},

	openNewReservation: function (
		deal,
		hotel,
		token,
		travel_policy,
		group_id,
		profile,
		optimization_token,
		hotel_token,
		mapping_room_name,
		room_mapping_data,
	) {
		const search_terms = SearchStore.getSearchTerms();
		const hotel_search_url = RouterWrapper.buildHotelSearchURL(hotel, search_terms);
		const { derived_from, combtas_id, proposal_id, travel_booster_docket_number, contract_id } = search_terms;

		AppDispatcher.handleAction({
			actionType: ReservationConstants.OPEN_NEW_RESERVATION,
			data: {
				// hotel,
				// deal,
				// travel_policy,
				profile,
			},
		});

		PaymentActions.clearPaymentData();

		const excluded_payments_reference = ResultsStore.getPaymentsExcludedDeal(hotel.id, deal);
		const excluded_payments_reference_id = _.get(excluded_payments_reference, 'recheck_id');

		Ajax.openNewReservation(
			deal,
			hotel,
			token,
			travel_policy,
			group_id,
			hotel_search_url,
			derived_from,
			contract_id,
			optimization_token,
			hotel_token,
			mapping_room_name,
			room_mapping_data,
			excluded_payments_reference_id,
		)
			.done(function (res) {
				let reservationDeal = res.deal;
				// const excluded_payments_reference = _.get(reservationDeal, 'excluded_payments_reference');
				reservationDeal = DealUtils.createDeal(reservationDeal, 1);
				// if (excluded_payments_reference) {
				//   reservationDeal.excluded_payments_reference = DealUtils.createDeal(excluded_payments_reference, 1);
				// }

				if (deal.totalPrice !== reservationDeal.totalPrice) {
					reservationDeal.priceChangedData = {
						oldPrice: deal.totalPrice,
						newPrice: reservationDeal.totalPrice,
						confirmedChange: false,
					};
				}

				if (reservationDeal && deal.id === reservationDeal.id) {
					_.mergeWith(deal, reservationDeal, (a, b) => (b === null ? a : undefined));
				}

				res.deal = deal;

				AppDispatcher.handleAction({
					actionType: ReservationConstants.NEW_RESERVATION_OPENED,
					data: {
						reservation: res,
						profile,
						combtas_id,
						proposal_id,
						travel_booster_docket_number,
					},
				});
			})
			.fail(function (err) {
				console.error('ERROR WHILE OPENING NEW RESERVATION: ', err);
				ResultsStore.increaseReservationAttempts(hotel.id);
				Analytics.actions.responses.reservationFailed({
					user: profile,
					hotel,
					deal,
					error: err,
					status: 'init',
					failure_reason: 'init reservation failed',
					...ResultsStore.getReservationAttemptsObject(hotel.id),
				});
				AppDispatcher.handleAction({
					actionType: ReservationConstants.NEW_RESERVATION_CANNOT_BE_OPENED,
					data: {
						deal,
						hotel,
					},
				});
			});
	},

	//autocompleteContact: function(data) {
	//
	//},

	addContactToReservation: function (room, contact) {
		AppDispatcher.handleAction({
			actionType: ReservationConstants.ADD_CONTACT_TO_RESERVATION,
			data: {
				room: room,
				contact: contact,
			},
		});
	},

	removeContactFromReservation: function (email) {
		AppDispatcher.handleAction({
			actionType: ReservationConstants.REMOVE_CONTACT_FROM_RESERVATION,
			data: email,
		});
	},

	quoteReservation: function (reservation, search_terms) {
		AppDispatcher.handleAction({
			actionType: ReservationConstants.QUOTE_RESERVATION,
			data: {
				reservation: reservation,
				search_terms: search_terms,
			},
		});

		Ajax.quoteReservation(reservation, search_terms)
			.done(function (res) {
				console.log(
					'SUCCESSFULLY Quoted Reservation [ID = ',
					reservation.id,
					+'trip id: = ' + reservation.management_trip_id + ']',
				);
				AppDispatcher.handleAction({
					actionType: ReservationConstants.RESERVATION_QUOTED,
					data: {
						reservation: reservation,
						response: res,
					},
				});

				// var price_per_night = Currencies.getPriceWithCurrency(reservation.deal.pricePerNight, reservation.deal.currency);
				// Analytics.actions.responses.quotingDone(reservation.hotel.name, reservation.deal.room, price_per_night, reservation.management_trip_id);
			})
			.fail(function (err) {
				console.error(
					'ERROR quoted Reservation [ID = ',
					reservation.id,
					+'trip id: = ' + reservation.management_trip_id + ']',
				);
				AppDispatcher.handleAction({
					actionType: ReservationConstants.RESERVATION_QUOTED_FAILED,
					data: {
						reservation: reservation,
						error: err,
					},
				});
			});
	},

	getAjaxFuncByPaymentType(payment_type) {
		switch (payment_type) {
			case PAYMENT_TYPE.CREDIT_CARD: {
				return Ajax.payGenerateCreditUrl;
			}
			case PAYMENT_TYPE.POINTS_ONLY: {
				return Ajax.payPointsOnly; // currently not enabled in desktop
			}
			case PAYMENT_TYPE.PAY_AT_HOTEL: {
				return Ajax.payAtHotel;
			}
			case PAYMENT_TYPE.PAY_LATER: {
				return Ajax.payLater;
			}
			case PAYMENT_TYPE.COMPANY_POLICY: {
				return Ajax.payCompanyPolicy;
			}
			default: {
				return Ajax.payGenerateCreditUrl; // as this is a harmless operation, have it the default
			}
		}
	},

	payLaterReservation: (profile, params) => {
		const { reservation, payment_type } = params;

		return Ajax.payReGenerateCreditUrl(params)
			.done(function (res) {
				console.log('SUCCESSFULLY Pay Later RESERVATION  [ID = ', reservation.id, ']');
				AppDispatcher.handleAction({
					actionType: ReservationConstants.REGENERATE_CREDIT_CARD_URL_SUCCESS,
					data: {
						response: res,
						payment_type,
					},
				});
			})
			.fail(function (err) {
				console.error('ERROR WHILE Pay Later RESERVATION [ID = ' + reservation.id + '], ERROR = ', err);
				AppDispatcher.handleAction({
					actionType: ReservationConstants.REGENERATE_CREDIT_CARD_URL_FAILURE,
					data: {
						error: err,
					},
				});
			});
	},

	banDeal: function (hotel, deal) {
		AppDispatcher.handleAction({
			actionType: HotelConstants.BAN_DEAL,
			data: {
				deal,
				hotel,
			},
		});
	},

	advanceReservation: function (profile, params) {
		const {
			hotel,
			payment_type,
			reservation,
			// hotel_recommended_index,
			charge_currency,
			// trip_id,
			search_terms,
			// use_bank_money,
			// search_token,
			// combtas_id,
			//  travel_booster_docket_number,
			// travel_booster_should_create_a_new_docket,
			// price_comparable,
			// currency,
			// client_custom_fields,
			// hotel_search_token,
			// send_email_confirmation,
		} = params;

		AppDispatcher.handleAction({
			actionType: ReservationConstants.ADVANCE_RESERVATION,
			data: {
				reservation,
				payment_type,
			},
		});
		const ajaxFunc = this.getAjaxFuncByPaymentType(payment_type);
		const _self = this;
		ajaxFunc(params)
			.done(function (res) {
				if (
					res.reservation_status &&
					res.reservation_status !== ReservationConstants.RESERVATION_STATUS.APPROVED
				) {
					console.log('advanced RESERVATION  [ID = ', reservation.id, ']', res);
					AppDispatcher.handleAction({
						actionType: ReservationConstants.ADVANCE_RESERVATION_FAILURE,
						data: {
							error: res,
							reservation_status: res.reservation_status,
						},
					});
					const analytics_payment_method = paymentMethodToPaymentAnalyticsMethod(payment_type);
					if (
						[RESERVATION_STATUS.PENDING_SUPPORT, RESERVATION_STATUS.PENDING_SUPPLIER].includes(
							res.reservation_status,
						)
					) {
						const hotelData = ResultsStore.getHotelById(hotel.id);
						const distanceInKm = hotelData?.distanceInKm;
						Analytics.actions.responses.bookingCreated({
							user: profile,
							hotel,
							search: search_terms,
							deal: reservation.deal,
							payment_method: analytics_payment_method,
							distance: distanceInKm,
							...ResultsStore.getReservationAttemptsObject(hotel.id),
							booking_id: reservation.short_id,
							charge_currency,
							status: res.reservation_status,
						});
						ResultsStore.cleanReservationAttempts();
					} else {
						ResultsStore.increaseReservationAttempts(hotel.id);
						Analytics.actions.responses.reservationFailed({
							user: profile,
							hotel,
							deal: reservation.deal,
							payment_method: analytics_payment_method,
							error: res,
							status: res.reservation_status,
							...ResultsStore.getReservationAttemptsObject(hotel.id),
						});
					}

					_self.banDeal(hotel, reservation.deal);
					return;
				}
				if (shouldContinueForPointsOnly(payment_type, res)) {
					return _self.advanceReservation(
						profile,
						Object.assign({}, params, { payment_type: PAYMENT_TYPE.POINTS_ONLY }),
					);
				}

				console.log('SUCCESSFULLY advanced RESERVATION  [ID = ', reservation.id, ']');
				AppDispatcher.handleAction({
					actionType: ReservationConstants.ADVANCE_RESERVATION_SUCCESS,
					data: {
						response: res,
						payment_type,
					},
				});
				const analytics_payment_method = paymentMethodToPaymentAnalyticsMethod(payment_type);

				const hotelData = ResultsStore.getHotelById(hotel.id);
				const distanceInKm = hotelData?.distanceInKm;
				// credit cards success payments are tracked in another place
				if (
					payment_type !== PaymentConstants.PAYMENT_METHOD_TYPES.CREDIT_CARD &&
					payment_type !== PaymentConstants.PAYMENT_METHOD_TYPES.PRIVATE_CARD
				) {
					Analytics.actions.responses.bookingCreated({
						user: profile,
						hotel,
						search: search_terms,
						deal: reservation.deal,
						payment_method: analytics_payment_method,
						distance: distanceInKm,
						...ResultsStore.getReservationAttemptsObject(hotel.id),
						booking_id: reservation.short_id,
						charge_currency,
						status: 'success',
					});
					ResultsStore.cleanReservationAttempts();
				}
			})
			.fail(function (err) {
				console.error('ERROR WHILE ADVANCING RESERVATION [ID = ' + reservation.id + '], ERROR = ', err);
				AppDispatcher.handleAction({
					actionType: ReservationConstants.ADVANCE_RESERVATION_FAILURE,
					data: {
						error: err,
					},
				});
				const analytics_payment_method = paymentMethodToPaymentAnalyticsMethod(payment_type);

				ResultsStore.increaseReservationAttempts(reservation.hotel?.id);
				Analytics.actions.responses.reservationFailed({
					user: profile,
					hotel: reservation.hotel,
					deal: reservation.deal,
					payment_method: analytics_payment_method,
					error: err,
					status: reservation.status,
					...ResultsStore.getReservationAttemptsObject(reservation.hotel?.id),
				});
				_self.banDeal(hotel, reservation.deal);
			});
	},

	updateTravelerFirstName: function (travelerIndex, firstName) {
		AppDispatcher.handleAction({
			actionType: ReservationConstants.UPDATE_TRAVELER_FIRST_NAME,
			data: {
				travelerIndex: travelerIndex,
				first_name: firstName,
			},
		});
	},

	updateTravelerLastName: function (travelerIndex, lastName) {
		AppDispatcher.handleAction({
			actionType: ReservationConstants.UPDATE_TRAVELER_LAST_NAME,
			data: {
				travelerIndex: travelerIndex,
				last_name: lastName,
			},
		});
	},

	updateTravelerEmail: function (travelerIndex, email) {
		AppDispatcher.handleAction({
			actionType: ReservationConstants.UPDATE_TRAVELER_EMAIL,
			data: {
				travelerIndex: travelerIndex,
				email: email,
			},
		});
	},

	updateTravelerPhone: function (travelerIndex, phone) {
		AppDispatcher.handleAction({
			actionType: ReservationConstants.UPDATE_TRAVELER_PHONE,
			data: {
				travelerIndex: travelerIndex,
				phone: phone,
			},
		});
	},

	updateTravelerRoom: function (travelerIndex, room) {
		AppDispatcher.handleAction({
			actionType: ReservationConstants.UPDATE_TRAVELER_ROOM,
			data: {
				travelerIndex: travelerIndex,
				room: room,
			},
		});
	},

	updateTraveler: function (travelerIndex, traveler) {
		AppDispatcher.handleAction({
			actionType: ReservationConstants.UPDATE_TRAVELER,
			data: {
				travelerIndex: travelerIndex,
				traveler: traveler,
			},
		});
	},

	resetTraveler: function (travelerIndex) {
		AppDispatcher.handleAction({
			actionType: ReservationConstants.RESET_TRAVELER,
			data: {
				travelerIndex: travelerIndex,
			},
		});
	},

	addGuestTraveler: function (travelerIndex, contact, room_number) {
		AppDispatcher.handleAction({
			actionType: ReservationConstants.ADD_GUEST_TRAVELER,
			data: {
				travelerIndex,
				contact,
				room_number,
			},
		});
	},

	addTravelerError: function (roomIndex, travelerIndex) {
		AppDispatcher.handleAction({
			actionType: ReservationConstants.ADD_TRAVELER_ERROR,
			data: {
				roomIndex,
				travelerIndex,
			},
		});
	},

	removeTravelerError: function (roomIndex, travelerIndex) {
		AppDispatcher.handleAction({
			actionType: ReservationConstants.REMOVE_TRAVELER_ERROR,
			data: {
				roomIndex,
				travelerIndex,
			},
		});
	},

	confirmPriceChanged: function () {
		AppDispatcher.handleAction({
			actionType: ReservationConstants.CONFIRM_PRICE_CHANGED,
		});
	},

	updateSpecialRequests: function (specialRequests) {
		AppDispatcher.handleAction({
			actionType: ReservationConstants.UPDATE_SPECIAL_REQUESTS,
			data: specialRequests,
		});
	},

	updateSpecialRequestsFromSuggestions: function (data) {
		AppDispatcher.handleAction({
			actionType: ReservationConstants.UPDATE_SPECIAL_REQUESTS_FROM_SUGGESTIONS,
			data,
		});
	},

	updateOutOfPolicyRequestReason: function (reason) {
		AppDispatcher.handleAction({
			actionType: ReservationConstants.UPDATE_OUT_OF_POLICY_BOOKING_REASON,
			data: reason,
		});
	},

	resetOutOfPolicyRequestStatus: function () {
		AppDispatcher.handleAction({
			actionType: ReservationConstants.RESET_OUT_OF_POLICY_REQUEST_STATUS,
		});
	},

	requestOutOfPolicyRoom: function (params) {
		const { reservation, trip_id, combtas_id } = params;
		AppDispatcher.handleAction({
			actionType: ReservationConstants.REQUEST_OUT_OF_POLICY_ROOM,
			data: {
				reservation,
				trip_id,
				combtas_id,
			},
		});

		Ajax.requestOutOfPolicyRoom(params)
			.done(function (res) {
				AppDispatcher.handleAction({
					actionType: ReservationConstants.REQUEST_OUT_OF_POLICY_ROOM_SUCCESS,
					data: {
						reservation,
						trip_id,
						combtas_id,
						response: res,
					},
				});
			})
			.fail(function (err) {
				console.error('ERROR WHILE REQUESTING OUT-OF-POLICY ROOM:', reservation, err);
				const profile = SearchStore.getProfile();
				const hotel = ResultsStore.getHotelById(reservation.hotel?.id);
				ResultsStore.increaseReservationAttempts(hotel?.id);
				Analytics.actions.responses.reservationFailed({
					user: profile,
					hotel,
					deal: reservation.deal,
					error: err,
					status: reservation.status,
					failure_reason: 'fail request out of policy room',
					...ResultsStore.getReservationAttemptsObject(hotel?.id),
				});
				AppDispatcher.handleAction({
					actionType: ReservationConstants.REQUEST_OUT_OF_POLICY_ROOM_FAILED,
					data: {
						reservation,
						trip_id,
						combtas_id,
						error: err,
					},
				});
			});
	},

	updateIncludeManagementTripId: function (specialRequests) {
		AppDispatcher.handleAction({
			actionType: ReservationConstants.UPDATE_INCLUDE_MANAGEMENT_TRIP_ID,
			data: specialRequests,
		});
	},

	clearReservation: function () {
		AppDispatcher.handleAction({
			actionType: ReservationConstants.CLEAR_RESERVATION,
		});
	},

	toggleBankMoneyUsage: function (use_bank_money) {
		AppDispatcher.handleAction({
			actionType: ReservationConstants.TOGGLE_BANK_MONEY_USAGE,
			data: use_bank_money,
		});
	},

	checkDoubleBooking(data) {
		AppDispatcher.handleAction({
			actionType: ReservationConstants.DOUBLE_BOOKING,
		});

		Ajax.checkDoubleBooking(data)
			.done(function (res) {
				AppDispatcher.handleAction({
					actionType: ReservationConstants.DOUBLE_BOOKING_SUCCESS,
					data: res,
				});
			})
			.fail(function (err) {
				console.error('ERROR WHILE CHECKING FOR DOUBLE BOOKING: ', err);
				AppDispatcher.handleAction({
					actionType: ReservationConstants.DOUBLE_BOOKING_FAILED,
					data: err,
				});
			});
	},

	createExpediaBooking(data) {
		AppDispatcher.handleAction({
			actionType: ReservationConstants.CREATE_EXPEDIA_BOOKING,
		});
		Ajax.createExpediaBooking(data)
			.done(function (res) {
				AppDispatcher.handleAction({
					actionType: ReservationConstants.CREATE_EXPEDIA_BOOKING_SUCCESS,
					data: res,
				});
			})
			.fail(function (err) {
				console.error('ERROR WHILE CREATE EXPEDIA BOOKING: ', err);
				AppDispatcher.handleAction({
					actionType: ReservationConstants.CREATE_EXPEDIA_BOOKING_FAILED,
					data: err,
				});
			});
	},

	completeExpediaSession(data) {
		Ajax.completeExpediaSession(data)
			.done(function (res) {
				if (res.supplier_ref) {
					AppDispatcher.handleAction({
						actionType: ReservationConstants.UPDATE_SUPPLIER_REF,
						data: res.supplier_ref,
					});
				}
				PaymentActions.markPaymentAsCharged();
			})
			.fail(function (err) {
				console.error('ERROR WHILE COMPLETING EXPEDIA PAYMENT SESSION: ', err);
				AppDispatcher.handleAction({
					actionType: ReservationConstants.ADVANCE_RESERVATION_FAILURE,
					data: err,
				});
			});
	},

	updateSupplierRef: function (supplierRef) {
		AppDispatcher.handleAction({
			actionType: ReservationConstants.UPDATE_SUPPLIER_REF,
			data: supplierRef,
		});
	},
	restartPaymentWithError: function (data) {
		AppDispatcher.handleAction({
			actionType: ReservationConstants.RESTART_PAYMENT_WITH_ERROR,
			data,
		});
	},
};

export default ReservationActions;
