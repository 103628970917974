import $ from 'jquery';
import _ from 'lodash';

import createClass from 'create-react-class';

import HotelCardReviewComponent from './HotelCardReviewComponent.react';
import HotelAmenitiesComponent from './HotelAmenitiesComponent.react';
import RepresentativeDealComponent from '../deal/RepresentativeDealComponent.react';
import RepresentativeDealLoader from '../deal/RepresentativeDealLoader.react';
import ReasonsBoxComponent from './ReasonsBoxComponent.react';
import NoDealComponent from './NoDealComponent.react';
import HotelActions from '../../../../actions/HotelActions';

import HotelAddress from '../../hotel/HotelAddressComponent.react';

import ResultsStore from '../../../../stores/ResultsStore';

import RouterWrapper from '../../../../utils/RouterWrapper';

import { Link } from 'react-router-dom';

import HotelCategoryBookmark from '../HotelCategoryBookmark.react';
import HotelDealsBookmark from '../HotelDealsBookmark.react';
import HotelNeighborhoodsBookmark from '../HotelNeighborhoodsBookmark.react';
import HotelTempBookmark from '../HotelTempBookmark.react';

import DealUtils from '../../../../entities/DealUtils';
import ImageUtils from 'arbitrip-common/client/utils/ImageUtils';
import Analytics from 'arbitrip-common/client/analytics';
import Config from 'arbitrip-common/client/utils/Config';
import WebStorageManager from '../../../../utils/WebStorageManager';

const PERCENT = 0.01;

function fontSizeByWidth(name) {
	let size = 20;
	if (name && name.length > 30) {
		size -= Math.round((name.length - 30) / 2.7);
	}
	return size;
}

function trimNumber(num, percentify = false) {
	return _.isNumber(num)
		? (num * (percentify ? PERCENT : 1)).toLocaleString(undefined, { maximumFractionDigits: 2 })
		: num;
}

const ProviderBookmark = createClass({
	displayName: 'ProviderBookmark',

	styles: {
		re_overlay: {
			position: 'absolute',
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
			height: '100%',
			width: '100%',
			visibility: 'hidden',
			transition: 'visibility 0s, opacity 0.5s linear',
		},
		re_text: {
			color: 'white',
			fontSize: '9px',
			position: 'absolute',
			top: '100%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
			MsTransform: 'translate(-50%, -50%)',
			textAlign: 'left',
			width: '100%',
			zIndex: 99999,
		},
		pre: {
			outline: '1px solid #ccc',
			padding: '5px',
			margin: '5px',
		},
		bookmark: {
			color: '#D0D1D2',
			fontSize: '24px',
			position: 'relative',
			marginLeft: '250px',
			marginTop: '-3px',
		},
		provider: {
			color: 'black',
			fontSize: '15px',
			position: 'absolute',
			left: '4px',
			top: '2px',
			fontFamily: 'lato',
		},
	},

	patterns: {
		b: /bstatic/,
		a: /property-images/,
		p: /co\.il/,
		x: /840x460/,
		e: /i\.travelapi\.com/,
	},

	setOverlayOpacity(opacity) {
		if (Config.dev_mode) {
			if (opacity) {
				$('#re-overlay-' + this.props.hotel.id).css('visibility', 'visible');
			} else {
				$('#re-overlay-' + this.props.hotel.id).css('visibility', 'hidden');
			}
		}
	},

	getProvider(img) {
		const { patterns } = this;

		if (patterns.b.test(img) && !patterns.x.test(img)) {
			return 'B';
		} else if (patterns.a.test(img)) {
			return 'A';
		} else if (patterns.p.test(img)) {
			return 'P';
		} else if (patterns.x.test(img)) {
			return 'X';
		} else if (patterns.e.test(img)) {
			return 'E';
		}

		return '?';
	},

	render() {
		if (!Config.dev_mode) {
			return null;
		}

		const { styles } = this;
		const { hotel } = this.props;

		return (
			<div className="pb">
				{Config.dev_mode ? (
					<div id={'re-overlay-' + hotel.id} style={styles.re_overlay}>
						<div id={'re-text-' + hotel.id} style={styles.re_text}>
							<pre style={styles.pre}>
								{JSON.stringify(hotel.original.recommendationEngine, undefined, 4)}
							</pre>
						</div>
					</div>
				) : null}
				<i
					className="fa fa-bookmark"
					aria-hidden="true"
					style={styles.bookmark}
					onMouseEnter={() => this.setOverlayOpacity(1)}
					onMouseLeave={() => this.setOverlayOpacity(0)}
				>
					<span style={styles.provider}>{this.getProvider(hotel.image)}</span>
				</i>
			</div>
		);
	},
});

const HotelCardComponent = createClass({
	displayName: 'HotelCardComponent',

	handleOnHotelClick: function () {
		const { profile, searchTerms } = this.props;

		HotelActions.getHotelData(this.props.hotel);

		const recommended_index = ResultsStore.calculateRecommendedIndex(this.props.hotel);
		HotelActions.setHotelRecommendedIndex(this.props.hotel.id, recommended_index);

		const recommended_count = ResultsStore.getRecommendedCount();
		const sort_method = ResultsStore.getSortMethod();
		const time_to_click_ml = ResultsStore.getTimePassedSinceSearchSessionCreatedInMilliSeconds();

		Analytics.actions.interactions.selectedHotel(
			profile,
			searchTerms.destination,
			searchTerms,
			recommended_index,
			recommended_count,
			sort_method,
			time_to_click_ml,
		);

		HotelActions.setHotelScroll(window.scrollY, this.props.hotel.id);
	},

	getHotelClass() {
		const { hotel } = this.props;
		let hotel_classes = 'hotel';

		if (hotel && hotel.hotelMatch) {
			hotel_classes += ' hotel_match';
		}

		if (Config.dev_mode) {
			if (hotel.shallow) {
				hotel_classes += ' shallow';
			} else if (!hotel.representativeDeal) {
				hotel_classes += ' dealless';
			}
		}

		return hotel_classes;
	},

	getRecommendationRender() {
		const { hotel } = this.props;

		const recommendation_profile = WebStorageManager.getFromWebStorage('_recommendation_profile');
		const weights = WebStorageManager.getFromWebStorage('_recommendation_weights')[recommendation_profile];

		const leisure_diff_abs = _.get(hotel, 'recommendation.leisure_diff_abs', 0);
		const leisure_diff_per = _.get(hotel, 'recommendation.leisure_diff_per', 0);
		const leisure_score_total = _.get(hotel, 'recommendation.leisure_score_total', 0);
		const leisure_cheapest_supplier_factor = _.get(hotel, 'recommendation.leisure_cheapest_supplier_factor', 0);
		const leisure_review_score = _.get(hotel, 'recommendation.leisure_review_score', 0);
		const leisure_stars = _.get(hotel, 'recommendation.leisure_stars', 0);

		const leisure_diff_abs_final = (weights.leisure_diff_abs || 0) * leisure_diff_abs;
		const leisure_diff_per_final = (weights.leisure_diff_per || 0) * leisure_diff_per;
		const leisure_score_total_final = (weights.leisure_score_total || 0) * leisure_score_total;
		const leisure_cheapest_supplier_factor_final =
			(weights.leisure_cheapest_supplier_factor || 0) * leisure_cheapest_supplier_factor;
		const leisure_review_score_final = (weights.leisure_review_score || 0) * leisure_review_score;
		const leisure_stars_final = (weights.leisure_stars || 0) * leisure_stars;

		const leisure_score_location = _.get(hotel, 'recommendation.leisure_score_location', 0);
		const leisure_score_amenities = _.get(hotel, 'recommendation.leisure_score_amenities', 0);
		const leisure_score_vibe = _.get(hotel, 'recommendation.leisure_score_vibe', 0);
		const leisure_score_popularity = _.get(hotel, 'recommendation.leisure_score_popularity', 0);

		const leisure_score_location_final = (weights.leisure_score_location || 0) * leisure_score_location;
		const leisure_score_amenities_final = (weights.leisure_score_amenities || 0) * leisure_score_amenities;
		const leisure_score_vibe_final = (weights.leisure_score_vibe || 0) * leisure_score_vibe;
		const leisure_score_popularity_final = (weights.leisure_score_popularity || 0) * leisure_score_popularity;

		const leisure_distance = _.get(hotel, 'recommendation.leisure_distance_score', 0);

		const leisure_price = _.get(hotel, 'recommendation.leisure_price', 0);
		const leisure_cheapest = _.get(hotel, 'recommendation.leisure_cheapest', 0);

		const leisure_distance_final = (weights.leisure_distance || 0) * leisure_distance;

		const leisure_price_final = (weights.leisure_price || 0) * leisure_price;
		const leisure_cheapest_final = (weights.leisure_cheapest || 0) * leisure_cheapest;

		const neighborhood_match = _.get(hotel, 'neighborhood_match', false) ? 100 : 0;
		const neighborhood_match_final = (weights.neighborhood_match || 100) * neighborhood_match;

		const final_style = {
			position: 'absolute',
			right: -72,
			paddingRight: 4,
		};

		return (
			<div
				style={{
					border: '1px solid #ccc',
					borderLeft: 0,
					height: 287,
					width: 144,
					position: 'absolute',
					top: 0,
					right: -144,
					padding: 4,
					lineHeight: '13px',
					fontSize: 13,
				}}
			>
				<div
					style={{
						position: 'absolute',
						left: -24,
						background: '#ccc',
						padding: 2,
						zIndex: 99,
						width: 24,
					}}
				>
					<span>#{this.props.sortPosition}</span>
				</div>
				<div>
					<span>DiffAbs:</span> <span>{trimNumber(leisure_diff_abs)}</span>{' '}
					<span style={final_style}>{trimNumber(leisure_diff_abs_final, true)}</span>
				</div>
				<div>
					<span>DiffPer:</span> <span>{trimNumber(leisure_diff_per)}</span>{' '}
					<span style={final_style}>{trimNumber(leisure_diff_per_final, true)}</span>
				</div>
				<br />
				<div>
					<span>LS:</span> <span>{trimNumber(leisure_score_total)}</span>{' '}
					<span style={final_style}>{trimNumber(leisure_score_total_final, true)}</span>
				</div>
				<div>
					<span>&nbsp;&nbsp;&nbsp;&nbsp;* Location:</span> <span>{trimNumber(leisure_score_location)}</span>{' '}
					<span style={final_style}>{trimNumber(leisure_score_location_final, true)}</span>
				</div>
				<div>
					<span>&nbsp;&nbsp;&nbsp;&nbsp;* Amenities:</span> <span>{trimNumber(leisure_score_amenities)}</span>{' '}
					<span style={final_style}>{trimNumber(leisure_score_amenities_final, true)}</span>
				</div>
				<div>
					<span>&nbsp;&nbsp;&nbsp;&nbsp;* Vibe:</span> <span>{trimNumber(leisure_score_vibe)}</span>{' '}
					<span style={final_style}>{trimNumber(leisure_score_vibe_final, true)}</span>
				</div>
				<div>
					<span>&nbsp;&nbsp;&nbsp;&nbsp;* Popularity:</span>{' '}
					<span>{trimNumber(leisure_score_popularity)}</span>{' '}
					<span style={final_style}>{trimNumber(leisure_score_popularity_final, true)}</span>
				</div>
				<br />
				<div>
					<span>CSF:</span> <span>{trimNumber(leisure_cheapest_supplier_factor)}</span>{' '}
					<span style={final_style}>{trimNumber(leisure_cheapest_supplier_factor_final, true)}</span>
				</div>
				<div>
					<span>ReviewScore:</span> <span>{trimNumber(leisure_review_score)}</span>{' '}
					<span style={final_style}>{trimNumber(leisure_review_score_final, true)}</span>
				</div>
				<div>
					<span>Stars:</span> <span>{trimNumber(leisure_stars)}</span>{' '}
					<span style={final_style}>{trimNumber(leisure_stars_final, true)}</span>
				</div>
				<br />
				<div>
					<span>Distance:</span> <span>{trimNumber(leisure_distance)}</span>{' '}
					<span style={final_style}>{trimNumber(leisure_distance_final, true)}</span>
				</div>
				<br />
				<div>
					<span>Price:</span> <span>{trimNumber(leisure_price)}</span>{' '}
					<span style={final_style}>{trimNumber(leisure_price_final, true)}</span>
				</div>
				<div>
					<span>Cheapest:</span> <span>{trimNumber(leisure_cheapest)}</span>{' '}
					<span style={final_style}>{trimNumber(leisure_cheapest_final, true)}</span>
				</div>
				<br />
				<div style={{ fontSize: 11 }}>
					<span>NeighborhoodMatch:</span> <span>{trimNumber(neighborhood_match)}</span>{' '}
					<span style={final_style}>{trimNumber(neighborhood_match_final, true)}</span>
				</div>
			</div>
		);
	},

	render() {
		const {
			profile,
			hotel,
			searchToken,
			searchTerms,
			enableDealLoader,
			completedListening,
			breakfastOnly,
			forceDealLoader,
			arbitripPointsApplied,
			amenitiesData,
		} = this.props;

		const nameStyle = {
			fontSize: fontSizeByWidth(hotel.name) + 'px !important',
			...(hotel.neighborhood_match ? { color: 'red' } : {}),
		};

		let result_class = 'result';
		if (hotel.matched_supplier_flag) {
			result_class += ' m_supplier';
		}
		if (Config.dev_mode && hotel.shallow) {
			result_class += ' shallow';
		}

		const deal = breakfastOnly ? hotel.representativeBreakfastDeal : hotel.representativeDeal;

		const show_deal_loader = forceDealLoader || (enableDealLoader && !completedListening && !deal);

		const neighborhoods_title = Array.isArray(hotel.neighborhoods) && hotel.neighborhoods.join(' | ');

		const HOTEL_NAME_SPAN = (
			<span
				style={nameStyle}
				className="hotel-name"
				onClick={this.handleOnHotelClick}
				{...(neighborhoods_title && { title: neighborhoods_title })}
			>
				{hotel.name}
			</span>
		);
		const HOTEL_NAME = show_deal_loader ? (
			HOTEL_NAME_SPAN
		) : (
			<button
				role="link"
				tabIndex={-1}
				onClick={() => {
					window.open(RouterWrapper.buildHotelRoute(hotel, searchToken, searchTerms), '_blank');
				}}
			>
				{HOTEL_NAME_SPAN}
			</button>
		);

		const noDealToDisplay = !DealUtils.isValidDeal(deal) && !show_deal_loader;

		const isDevMode = Config.dev_mode;

		return (
			<div className={result_class}>
				{Config.research_mode && this.getRecommendationRender()}
				<div className={this.getHotelClass()}>
					<div className={`hotel-inner-container${noDealToDisplay ? ' extended' : ''}`}>
						<button
							role="link"
							tabIndex={-1}
							onClick={() => {
								window.open(RouterWrapper.buildHotelRoute(hotel, searchToken, searchTerms), '_blank');
							}}
						>
							<div
								className="hotel-image"
								style={isDevMode ? { position: 'relative' } : {}}
								onClick={this.handleOnHotelClick}
							>
								<img
									src={`${ImageUtils.getHotelImage(hotel)}`}
									loading="lazy"
									decoding="async"
									alt=""
									onError={(i) => (i.target.style.display = 'none')}
								/>

								{isDevMode && (
									<>
										<ProviderBookmark hotel={hotel} />
										<HotelCategoryBookmark hotel={hotel} />
										<HotelDealsBookmark hotel={hotel} />
										<HotelNeighborhoodsBookmark hotel={hotel} />
										<HotelTempBookmark hotel={hotel} />
									</>
								)}
							</div>
						</button>

						<div className="hotel-content">
							<div className="hotel-info flex flex-column">
								<div className="hotel-details">
									<HotelCardReviewComponent hotel={hotel} />
									<div className="title">{HOTEL_NAME}</div>
									<HotelAddress hotel={hotel} />
								</div>
								<ReasonsBoxComponent hotel={hotel} />
								<HotelAmenitiesComponent
									hotel={hotel}
									amenitiesData={amenitiesData}
									breakfastOnly={breakfastOnly}
								/>
								{noDealToDisplay && (
									<NoDealComponent
										hotel={hotel}
										deal={deal}
										searchToken={searchToken}
										searchTerms={searchTerms}
										profile={profile}
									/>
								)}
							</div>
							<div className="representative-deal">
								{show_deal_loader ? (
									<RepresentativeDealLoader />
								) : (
									<RepresentativeDealComponent
										hotel={hotel}
										deal={deal}
										searchToken={searchToken}
										searchTerms={searchTerms}
										profile={profile}
										arbitripPointsApplied={arbitripPointsApplied}
										contractClientCompanyId={this.props.contract_client_company_id}
									/>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	},
});

export default HotelCardComponent;
