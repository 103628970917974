const SEARCH_TYPES = {
	RECENT_SEARCH: 'recent_searches',
	REGION: 'region',
	HOTEL: 'hotel',
	LOCATION: 'location',
	SEARCH: 'search',
};

const ACTIONS_TYPES = {
	INPUT_CHANGE: 'input-change',
	MENU_CLOSE: 'menu-close',
	INPUT_BLUE: 'input-blur',
	SET_VALUE: 'set-value',
};

const VARIANT = {
	DEFAULT: 'default',
	CLEAN: 'clean',
	SMALL: 'small',
	CITY_SEARCH: 'city_search',
};

const AUTO_COMPLETE_ID = 'search_autocomplete';
const AUTO_COMPLETE_SELECT_ID = 'autocomplete_select';
const TYPING_TIMEOUT = process.env.REACT_APP_AUTOCOMPLETE_TYPING_TIMEOUT_IN_MILLISECONDS || 350;

const START_DATE_ID = 'startDate';
const END_DATE_ID = 'endDate';
const SEARCH_BOX_ID = 'searchBox';

module.exports = {
	SEARCH_TYPES,
	ACTIONS_TYPES,
	VARIANT,
	AUTO_COMPLETE_ID,
	AUTO_COMPLETE_SELECT_ID,
	TYPING_TIMEOUT,
	START_DATE_ID,
	END_DATE_ID,
	SEARCH_BOX_ID,
};
