const BASE_URL = 'https://maps.googleapis.com/maps/api/staticmap?';
const MARKER_URL = 'https://i.pinimg.com/originals/f2/57/78/f25778f30e29a96c44c4f72ef645aa63.png';
const PIN_MARKER_URL = document.location.protocol + '//' + document.location.host + '/img/map/pin.png';
const DEFAULT_ZOOM = 14;

export default {
	BASE_URL,
	MARKER_URL,
	PIN_MARKER_URL,
	DEFAULT_ZOOM,
};
