const FAQ = [
    {
        title: 'How do I amend names / dates for an existing booking?',
        description: 'All booking modifications need to be requested manually with one of our customer success representatives',
        secondary: [
            {
                title: 'With free cancellation',
                description: 'We will create a new reservation according to the changes you have requested, once we receive a confirmation from the supplier for the new reservation, we will cancel the old booking. Of course, if the rate has changed we will confirm with you before making any modifications.\n' +
                    'You can also make these changes by yourself in the same way. Making the new reservation via your user and canceling the old one. It is the easiest and fastest way.'
            },
            {
                title: 'Non refundable',
                description: 'We will contact our supplier to check if it\'s possible to make any requested changes.\n' +
                    'We will do our best to negotiate on your behalf but modifications are never guaranteed and based on discretion of the hotel. Please note that the supplier may take a few days to give us an answer. However we will continue to follow-up and update you as soon as we can.'
            }
        ]
    },
    {
        title: 'How do I add extra night(s) to my booking?',
        description: 'All booking modifications need to be requested manually with one of our customer success representatives',
        secondary: [
            {
                title: 'In case there are still rooms available',
                description: 'We will make a second booking for the extra night(s) needed. Once we receive a confirmation voucher, we will inform the hotel that this is the same guest for both bookings and request they keep the traveler in the same room throughout.\n' +
                    'You can also do the same by yourself - just always make sure to book the same room type, so the guest won\'t be asked to move to a different room and note the same room request in the special request section on the booking page. You are always welcome to ask us if you are not sure.'
            },
            {
                title: 'In case there is no availability for the same type of room but it’s still has free cancelation',
                description: 'In this case, we suggest you make a whole new reservation with a room type that is available for the entire stay, and cancel the original one afterward.\n' +
                    'We can also check what rooms are currently available at the hotel for you if you want some assistance.'
            },
            {
                title: 'In case there is no availability for the same type of room and it’s non-refundable',
                description: 'We can book the guest a different type of room, but that means that he\\she will likely have to move during their stay.'
            }
        ]
    },
    {
        title: 'How do I add breakfast to my “Room Only” booking?',
        secondary: [
            {
                title: 'With free cancellation and we are able to find a deal including breakfast',
                description: 'We can book for you the same room including breakfast, of course we will confirm any difference in rate before booking. Then we will cancel the old booking.'
            },
            {
                title: 'Nonrefundable with prepaid breakfast option',
                description: 'We will check with the hotel if we can add breakfast to the reservation. Since it’s a non-refundable reservation we aren\'t able to change or cancel it.\n' +
                    'If we can pre-pay the hotel we will, some hotels do not allow this option and the traveler will need to pay for this extra at the hotel.'
            }
        ]
    },
    {
        title: 'How do I cancel my booking?',
        secondary: [
            {
                title: 'Reservation with free cancellation',
                description: 'You can do it easily by yourself on the system, all you need to do is to go to the booking section, press on the 3 dots under the price on the right side of the booking. This will open a small menu. Choose the ‘cancel booking’ option.\n' +
                    'If you are unsure, we will be happy to assist you.'
            },
            {
                title: 'Non Refundable reservation',
                description: 'When a  booking is non refundable we will have to contact our supplier and request an exception. We will do our best to negotiate the exception but ultimately it will be up to the hotel. It always helps to provide a reason and proof for cancellation.\n' +
                    'We will keep you updated throughout the process.'
            }
        ]
    },
    {
        title: 'I saw a cheaper price in a different online booking tool, do you compare rates?',
        secondary: [
            {
                description: 'We will be happy to check this out and compare if the price is cheaper elsewhere. We would need you to send us the details of the reservation you want to make, and the link to the website from which you saw the cheaper price. \n' +
                    'Please note, we work very hard to provide the best deals for our clients. In most cases our prices are cheaper than the other online booking tools. In the few cases we are not, we compare the price.'
            }
        ]
    },
    {
        title: ' I can’t find a specific hotel I want in Arbitrip, can you help me?',
        secondary: [
            {
                title: 'The Hotel does not exist in the system but there is availability:',
                description: 'Please send us the hotel’s name, the required dates, the number of rooms and how many guests will stay in each room. We will check if we can book the reservation for you manually and load it in our system for you.\n' +
                    '\n' +
                    'Sometimes the hotel has changed names recently and is under new ownership which is why it might be hard to find.'
            },
            {
                title: 'The hotel exist in the system but it’s sold out',
                description: 'In case the hotel is sold out on specific dates it will not appear on our site and this is why you couldn\'t find it.\n' +
                    'For your information, after you search the hotel, on the left side you can find the option "Hide no-availability hotels", which means that if you are looking for an hotel that has no availability, the system will not show it to you. You can always tick this option off. \n' +
                    'We will be more than happy to help you find a substitute hotel nearby and with a similar price.'
            }
        ]
    },
    {
        title: 'Where can I find the invoice for my booking?',
        secondary: [
            {
                title: 'In case we issued an invoice',
                description: 'You can see all your invoices on the system.\n' +
                    'All you need to do is to go to the booking section, press on the 3 dots under the price on the right side of the booking. This will open a small menu and then just choose ‘download invoice’.'
            },
            {
                title: 'In case the invoice is not issued yet',
                description: 'I can see that your invoice is not issued yet, in this case we will forward your request to our finance department and they will get back to you as soon as possible.'
            }
        ]
    },
    {
        title: 'The hotel informed the guest they will charge him for the booking. Why did this happen and what can you do to assist?',
        secondary: [
            {
                description: 'This is not supposed to happen and we will check it right away with our supplier. We will make sure that the hotel will refund the guest\'s credit card if it has been charged. Often this is due to a miscommunication with the hotel system and the supplier system.\n' +
                    'From our experience, the supplier and the hotel will need proof of payment. Therefore, we will need you to send us a picture from the credit card statement with the name of the hotel.\n' +
                    'Once you send it to us, we will forward it to the supplier, please note it might take a few days until they will give us an answer.'
            }
        ]
    },
    {
        title: 'How can I edit and add a new user?',
        secondary: [
            {
                description: 'Please send us the employee’s name and email address, and we will send him/her a verification email.  \n' +
                    'Please note that you can also do it yourself on the system. \n' +
                    'All you need to do is to press on the circle (with your initials) on the top right corner, and choose the option ‘manage users’. \n' +
                    'This will open for you a page where you can add new employees and edit info of current employees.'
            }
        ]
    },
    {
        title: 'How can I edit and add a traveler name?',
        secondary: [
            {
                description: 'When you enter the traveler’s name, tick the option ‘create a new traveler’, if you want this name to be shown automatically next time, you need to add the traveler’s email and press the ‘Add’ button. \n' +
                    'Please note, we use this email to save this traveler in the database and we will not use it to send marketing content.\n' +
                    'In case you don’t have the traveler email or you don’t want to add him/her to the database, you can choose the option ‘This traveler is not a company employee’ and press the ‘Add’ button.'
            }
        ]
    },
    {
        title: 'Can I get a refund for early check out?',
        secondary: [
            {
                description: 'If a traveler needs to leave the hotel early, please provide us with the guest’s name, when he/she is planning to leave and why he/she is leaving earlier than planned.\n' +
                    'We will contact the supplier as soon as possible and try to get a refund for the remaining nights. It is important to say that we cannot guarantee this and it depends on the hotel and supplier. \n' +
                    'We recommend, in order to promote the matter, that the guest will approach the hotel’s front desk and ask for their approval, please ask him/her to take the name of the employee who approved/denied it.'
            }
        ]
    },
    {
        title: 'The guest needs to check in early, how can I guarantee it?',
        secondary: [
            {
                description: 'In order to ensure an early check-in, most hotels require you to book the night before. \n' +
                    'When you make a reservation and book the night before, it is important to let us know the estimated arrival time of the guest, so we can make sure the hotel will keep the room for him/her. \n' +
                    'In addition, if you add the night before to an existing reservation, we will recommend booking the same room type so the hotel will not move him/ her.\n' +
                    'Otherwise, early check-in is not guaranteed and based on availability on the day of arrival.'
            }
        ]
    },
    {
        title: 'How can I add my hotel chain membership number to the booking?',
        secondary: [
            {
                description: 'You can add the membership ID in the ‘special request’ section on the booking page. If you have already booked, send us the guest’s membership number and we will forward it to the hotel. Please note that since this reservation is made through a third party, we cannot guarantee that the hotel will accept it (it’s up to the hotel to decide). With that said, we’ll do our very best to let them know the importance of it.'
            }
        ]
    }
    // {
    //     title: '',
    //     description: '',
    //     secondary: [
    //         {
    //             title: '',
    //             description: ''
    //         }
    //     ]
    // },
];

module.exports = {
    FAQ
};
