import AirfareReservation from './AirfareReservation';
import Reservation from './Reservation';

function BookedItinerary(booked_itinerary) {
	if (!booked_itinerary) {
		return;
	}

	this.accommodations = Array.isArray(booked_itinerary.accommodations)
		? booked_itinerary.accommodations.map((accommodation) => new Reservation(accommodation.current_reservation)) // notice: the id is that of current_reservation, not of accommodation
		: [];
	this.airfares = Array.isArray(booked_itinerary.airfares)
		? booked_itinerary.airfares.map((airfare) => new AirfareReservation(airfare))
		: [];

	this.sent_to_approval = booked_itinerary.was_sent_to_approval;
}

export default BookedItinerary;
