const { TravelPolicy } = require('./TravelPolicy');

const _ = require('lodash');

const {
    isValidRawHotel,
} = require('./HotelUtils');

const Hotel = require('./Hotel');

function average(...prices) {
    return prices.reduce((sum, price) => sum + price, 0) / prices.length;
}
function median(...prices) {
    const half = (prices.length - 1) / 2;
    return (prices[Math.floor(half)] + prices[Math.ceil(half)]) / 2;
}

class SearchResults {
    /**
     * 
     * @param {*} recommendationWeights 
     * @param {*} options {travel_policy}
     */
    constructor(recommendationWeights, options = {}) {
        this._hotels = {};
        this.travel_policy = options.travel_policy;

        this.completed_hotels = false;
        this.completed_search = false;
        this.completed_listening = false;
        this.completed_delay = false;

        this.timestamp = new Date();

        this._recommendation_weights = recommendationWeights;

        if (!_.get(options, 'disableThrottle', false)) {
            this.recalculateRecommendationScore = _.throttle(this.recalculateRecommendationScore, 1000);
        }
    }

    get length() {
        return this.hotels.length; // TODO: make sure from getter and not from local variable
    }

    set travel_policy(rawTravelPolicy) {
        if (rawTravelPolicy) {
            this._travel_policy = new TravelPolicy(rawTravelPolicy);
        } else {
            this._travel_policy = null;
            console.warn('SearchResults.setTravelPolicy got invalid travel policy', rawTravelPolicy);
        }
    }

    get travel_policy() {
        return this._travel_policy;
    }

    getHotelById(hotelId) {
        return this._hotels[hotelId];
    }

    cleanHotelDeals(hotelId) {
        const hotel = this._hotels[hotelId];
        if (hotel) {
            hotel.deals = [];
            hotel.representativeDeal = {};
        }
    }

    /**
     * 
     * @param {Array} rawHotelsArray [{_id, name}]
     * @param {boolean} shallow 
     */
    addHotels(rawHotelsArray, shallow) {
        if (Array.isArray(rawHotelsArray)) {
            for (const raw_hotel of rawHotelsArray) {
                if (isValidRawHotel(raw_hotel)) {
                    const hotel_id = raw_hotel['_id'];
                    const hotel = this._hotels[hotel_id];
                    if (!hotel || hotel.shallow) {
                        this._hotels[hotel_id] = new Hotel(raw_hotel, shallow);
                        this._hotels[hotel_id]._recommendation_weights = this._recommendation_weights;
                    }
                }
            }
        }
    }

    __TEMP__calculateMargins() {
        for (const hotel of this.hotels) {
            hotel.__TEMP__calculateMargins();
        }
    }

    recalculateRecommendationScore(hotels = this.hotels) {
        const prices = hotels
            .filter(hotel => _.get(hotel, 'stars', 0) >= 4)
            .map(hotel => _.get(hotel, 'representative_deals.cheapest.pricePerNight', 0))
            .filter(price => price > 0)
            .sort((a, b) => a - b);

        const virtual_margins = hotels
            .map(hotel => _.get(hotel, 'virtual_cheapest_margin_percentage', 0))
            .filter(virtual_margin => virtual_margin > 0)
            .sort((a, b) => b - a);

        if (prices.length > 0) {
            const average_price = average(...prices);
            const median_price = median(...prices);
            const highest_price = Math.max(...prices);
            const lowest_price = Math.min(...prices);
            let price_spread = highest_price - lowest_price;
            if (price_spread <= 0) {
                price_spread = 1.0;
            }
            let min_median_spread = Math.min(highest_price - median_price, median_price - lowest_price);
            if (min_median_spread <= 0) {
                min_median_spread = 1.0;
            }
            let cheapest_average_spread = average_price - lowest_price;
            if (cheapest_average_spread <= 0) {
                cheapest_average_spread = 1.0;
            }

            const average_virtual_margin = average(...virtual_margins);
            const median_virtual_margin = median(...virtual_margins);
            const highest_virtual_margin = Math.max(...virtual_margins);
            const lowest_virtual_margin = Math.min(...virtual_margins);
            let virtual_margin_spread = highest_virtual_margin - lowest_virtual_margin;
            if (virtual_margin_spread <= 0) {
                virtual_margin_spread = 1.0;
            }

            for (const hotel of this.hotels) {
                const price = _.get(hotel, 'representative_deals.cheapest.pricePerNight', 0);

                if (price > 0) {
                    if (price <= average_price) { // add a good reason
                        hotel.addPriceReason();
                    } else {
                        hotel.removePriceReason();
                    }

                    // const bonus_score = this._recommendation_weights.price * Math.max(
                    //     100 * (highest_price - price) / (price_spread),
                    //     1 // normalizes really pricy deals
                    // );

                    // hotel.updateRecommendationFinalScore(bonus_score);
                    hotel.updateRecommendationFinalScore({
                        average_price,
                        median_price,
                        highest_price,
                        lowest_price,
                        price_spread,

                        min_median_spread,
                        cheapest_average_spread,

                        average_virtual_margin,
                        median_virtual_margin,
                        highest_virtual_margin,
                        lowest_virtual_margin,
                        virtual_margin_spread
                    })
                } else {
                    if (hotel.shallow) {
                        hotel.updateRecommendationFinalScore(_.get(this, '_recommendation_weights.shallow_penalty'));
                    } else if (this.completed_listening) {
                        hotel.updateRecommendationFinalScore(_.get(this, '_recommendation_weights.none_penalty'));
                    }
                }
            }
        }
    }

    /**
     * @param {Object} search_session {}
     * @param {Array} rawHotelsDealsArray [{_id, deals}]     */
    addHotelsDeals(search_session, rawHotelsDealsArray = []) {
        const banned_deal_ids = search_session.banned_deal_ids ?? [];
        if (Array.isArray(rawHotelsDealsArray)) {
            for (const raw_hotel_deals of rawHotelsDealsArray) {
                if (raw_hotel_deals) {
                    let {
                        _id,
                        deals
                    } = raw_hotel_deals;

                    deals = deals.filter((d) => !banned_deal_ids.includes(d.id));

                    const hotel = this._hotels[_id];
                    if (hotel) {
                        const [
                            raw_rechecked_deals,
                            primal_deals
                        ] = _.partition(deals, (deal) => deal.recheck_id);
                        hotel.addPrimalDeals(primal_deals);
                        hotel.addRawRecheckedDeals(search_session, raw_rechecked_deals);
                        this.recalculateRecommendationScore();
                    } else {
                        console.warn('SearchResults.addHotelsDeals no hotel with id', _id);
                    }
                }
            }
        }
    }

    addRecheckedDeals(search_session, hotelId, rawRecheckedDealsArray) {
        const hotel = this.getHotel(hotelId);
        if (hotel && Array.isArray(rawRecheckedDealsArray) && (rawRecheckedDealsArray.length > 0)) {
            hotel.addRawRecheckedDeals(search_session, rawRecheckedDealsArray);
        } else {
            console.warn('SearchResults.addRecheckDeals ::: could not find hotel', hotelId);
        }
    }

    markRoomMappingSupersededDeals(search_session, hotelId) {
        const hotel = this.getHotel(hotelId);
        if (hotel) {
            hotel.markRoomMappingSupersededDeals(search_session);
        } else {
            console.warn('markRoomMappingSupersededDeals ::: could not find hotel', hotelId);
        }
    }

    getHotel(hotelId) {
        return this._hotels[hotelId];
    }

    getHotels() {
        return this._hotels || {};
    }

    get hotels() {
        return Object.values(this._hotels);
    }

    filterHotels(callback) {
        return this.hotels.filter(callback);
    }
}

export default SearchResults;
