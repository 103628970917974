const keyMirror = require('keymirror');
const _expire_in_minutes = process.env.REACT_APP_RESULTS_EXPIRE_IN_MINUTES || 30;

const SEARCH_SESSION_CONSTANTS = {
  RESULTS_EXPIRATION_TIMEOUT_MILLIS: _expire_in_minutes * 60 * 1000,
  SEARCH_SESSION_EVENT: keyMirror({
    CHANGED: null,
    COMPLETED: null,

    SEARCH: null,
    HOTELS: null,

    EXPIRED: null,

    SEARCH_HOTELS_DEALS: null,
    SEARCH_COMPLETED: null,
    SEARCH_ERROR: null,

    RECHECK_DEALS: null,
    RECHECK_COMPLETED: null,
    RECHECK_ERROR: null,

    ROOM_MAPPING: null,
    ROOM_MAPPING_COMPLETED: null
  })
};

module.exports = Object.assign({}, SEARCH_SESSION_CONSTANTS);
