import { useEffect, useState } from 'react';
import { iframeResizer as iFrameResize } from 'iframe-resizer';
import PageLoader from '../../general/PageLoader.react';

const waitForElement = async (id, callback) => {
	let finished = false;
	setTimeout(() => {
		finished = true;
	}, 10000);

	while (!document.getElementById(id)) {
		if (finished) {
			console.warn('skipped iframe resizing...');
			return;
		}

		await new Promise((resolve) => {
			setTimeout(resolve, 500);
		});
	}

	return callback();
};

const TrustYouComponent = ({ hotel, containerHeight }) => {
	const hasReview = hotel.hasReview;
	const trustyouid = hasReview ? hotel.review.ty_id : null;

	const [isShowIframe, setShowIframe] = useState(false);

	useEffect(() => {
		waitForElement('hotel-frame', () => {
			console.info('resizing iframe...', new Date().getTime());
			iFrameResize({
				checkOrigin: ['http://api.trustyou.com', 'https://api.trustyou.com'],
				warningTimeout: 0,
			});
		});
	}, []);

	return (
		trustyouid && (
			<div className="reviews-container">
				<div
					className="loading-container"
					style={
						isShowIframe
							? {
									maxHeight: '0px',
								}
							: { maxHeight: containerHeight }
					}
				>
					<div style={{ height: containerHeight }}>
						<PageLoader />
					</div>
				</div>
				{/*<!-- TrustYou Widget -->*/}
				<iframe
					style={isShowIframe ? {} : { display: 'none' }}
					id="hotel-frame"
					title="hotel-frame"
					src={`//api.trustyou.com/hotels/${trustyouid}/meta_review.html?key=${process.env.REACT_APP_TRUSTYOU_API_KEY}&iframe_resizer=true&scale=100&lang=en`}
					frameBorder="0"
					scrolling="no"
					width="100%"
					marginWidth="0"
					marginHeight="0"
					onLoad={() => setTimeout(() => setShowIframe(true), 400)}
				/>
				{/*<!-- /TrustYou Widget -->*/}
			</div>
		)
	);
};

export default TrustYouComponent;
