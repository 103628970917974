const _ = require('lodash');
const moment = require('moment');
const { validateCurrency: isValidCurrency } = require('../utils/Validator');

const ProfileConstants = require('../constants').ProfileConstants;
const UsersManagementConstants = require('../constants').UsersManagementConstants;
// const UsersManagementService = {};//require('../components/pages/users-management/UsersManagementService');
const Config = require('../utils').Config;

const WebStorageManager = require('../utils/WebStorageManager');
const { EN } = require('../../translation/const');

const default_display_currency = 'USD';
const default_display_currency_exchange_rate = 1;

function setForceLongPolling(profile) {
  const force_long_polling = _.get(profile, 'settings.force_long_polling') || _.get(profile, 'company.settings.force_long_polling', false);

  if (force_long_polling) {
    WebStorageManager.addToWebStorage('force_long_polling', true);
  } else {
    WebStorageManager.removeFromWebStorage('force_long_polling');
  }
}

function Profile(data) {
  if (!data)
    throw new Error('No data payload provided');
  for (var prop in data) {
    this[prop] = data[prop];
  }

  this.id = data.id || data._id;

  this.anonymous = !data.first_name && !data.last_name;

  if (this.anonymous) {
    this.picture = '/img/profile-icon.png';
  }

  this.full_name = data.full_name || '';
  if (!this.full_name && this.first_name && this.last_name) {
    this.full_name = this.first_name + ' ' + this.last_name;
  }

  let enhanceContract = function (contract) {
    let c = contract;
    if (contract.company._id === data.company.id) {
      c.agency = true;
      c.self_contract = true;
    }
    return c;
  };

  const travel_agency = (data?.company?.company_type === 'travel_agency');

  this.employee = Array.isArray(this.roles) && (this.roles.indexOf(UsersManagementConstants.SYSTEM_ROLES.EMPLOYEE) > -1);
  this.regional_admin = Array.isArray(this.roles) && (this.roles.indexOf(UsersManagementConstants.SYSTEM_ROLES.REGIONAL_ADMIN) > -1);
  this.travel_manager = Array.isArray(this.roles) && ((this.roles.indexOf(UsersManagementConstants.SYSTEM_ROLES.TRAVEL_MANAGER) > -1) || (this.roles.indexOf(UsersManagementConstants.SYSTEM_ROLES.EXECUTIVE) > -1) || (this.roles.indexOf(UsersManagementConstants.SYSTEM_ROLES.SUPERVISOR) > -1));
  this.executive = Array.isArray(this.roles) && ((this.roles.indexOf(UsersManagementConstants.SYSTEM_ROLES.EXECUTIVE) > -1) || (this.roles.indexOf(UsersManagementConstants.SYSTEM_ROLES.SUPERVISOR) > -1));
  this.approver = Array.isArray(this.roles) && (this.roles.indexOf('approver') > -1);
  this.agent = travel_agency && Array.isArray(this.roles) && ((this.roles.indexOf(UsersManagementConstants.SYSTEM_ROLES.TRAVEL_AGENT) > -1 || this.roles.indexOf(UsersManagementConstants.SYSTEM_ROLES.SUPER_TRAVEL_AGENT) > -1));
  this.super_agent = travel_agency && Array.isArray(this.roles) && this.roles.indexOf(UsersManagementConstants.SYSTEM_ROLES.SUPER_TRAVEL_AGENT) > -1;

  if (data.agency_branch) {
    const filtered = _.get(data, 'company.agency_branches', []).filter(({ _id }) => _id === data.agency_branch);
    this.agency_branch = filtered.length ? filtered[0] : null;
  }

  if (this.super_agent) {
    this.company_branches = _.get(data, 'company.agency_branches', []);
  }

  this.reports_dashboard = data.reports_dashboard;
  this.access_all_company_reservations = data.access_all_company_reservations;
  this.blocked_reserving = data.blocked_reserving;

  if (this.agent) {
    this.agency_contracts = [];
    this.show_send_voucher_separately_from_confirmation_mail = data.show_send_voucher_separately_from_confirmation_mail;
    if (data.contracts) {
      var contracts = (data.contracts.map(enhanceContract).sort((c1, c2) => {
        if (c1.agency) { // travel agency comes first
          return -1;
        }
        var comparison = c1.company.name.toLowerCase().localeCompare(c2.company.name.toLowerCase());
        if (comparison === 0) {
          return c1.company.name.localeCompare(c2.company.name);
        }
        // TODO: add flag if this is the agency itself
        // TODO: discuss if contract name is needed (and what should replace it if not)
        return comparison;
      })).map(c => {
        if (c.active) {
          return c;
        }
        c.company.name += ' (inactive)';
        return c;
      });
      this.agency_contracts = _.sortBy(contracts.filter(c => c.active), 'company.name');
      // this.agency_contracts_dashboard = (this.access_all_company_reservations ? [ProfileConstants.REPORTS_COMPANY_ALL].concat(contracts) : contracts.filter(c => c.company._id !== this.company.id));
      this.agency_contracts_dashboard = (this.access_all_company_reservations ? contracts : contracts.filter(c => c.company._id !== this.company.id));
    } else {
        this.agency_contracts = [];
        this.agency_contracts_dashboard = [];
    }
  }

  if (this.settings) {
    if (this.settings.loyalty_program && this.settings.loyalty_program.enabled) {
      this.loyalty_program_percentage = this.settings.loyalty_program.percentage;
    }
    if (this.settings.is_arbi_support) {
      this.is_arbi_support = this.settings.is_arbi_support;
    }

    if (this.settings.is_arbi_tester) {
      this.is_arbi_tester = this.settings.is_arbi_tester;
    }

    if (this.settings.show_superseded_deals) {
      this.show_superseded_deals = this.settings.show_superseded_deals;
    }

    if (this.settings.skip_traveler_autopick) {
      this.skip_traveler_autopick = this.settings.skip_traveler_autopick;
    }

    if (!this.settings.travel_booster_integration_enabled) {
      this.settings.travel_booster_integration_enabled = false;
    }
  }

  if (!this.company) {
    this.company = {};
  }
  if (!this.company.settings) {
    this.company.settings = {
      management_enabled: false,
      employees_hotel_booking_enabled: false,
      combtas_integration_enabled: false,
      combtas_integration_mandatory: false,
      travel_booster_integration_mandatory: false,
      employees_private_travel_only: false,
      leisure_experience: false,
      hide_social_links: false
    };
  } else {
    if (this.company.settings.block_dev_mode) {
      (typeof Config.blockDevMode === 'function') && Config.blockDevMode();
    }
  }

  // if (this.agent) {
  this.enable_clients_settings = this.company.settings.enable_clients_settings;
  this.enable_user_management = this.company.settings.enable_user_management;
  this.enable_pricing_extra_info = this.company.settings.enable_pricing_extra_info;
  // this.enable_my_list_price_edit = this.company.settings.enable_my_list_price_edit;
  this.enable_basket_price_edit = this.company.settings.enable_basket_price_edit;
  // }

  /// patch
  // this.package_disclaimer_enabled = this.agent || this.super_agent || this.company.settings.ecert || this.is_arbi_support;
  this.passport_details = data.passport_details
    || {
    given_name: '',
    surname: '',
    date_of_birth: {
      month: '',
      day: '',
      year: ''
    },
    nationality: '',
    gender: '',
    number: '',
    issued_in: '',
    expiry_date: null
  };
  var dob = moment(data.passport_details && data.passport_details.date_of_birth);
  if (data.passport_details && (data.passport_details.date_of_birth instanceof Date) && dob.isValid()) {
    this.passport_details.date_of_birth = {
      month: (dob.month() + 1).toString(),
      day: dob.date().toString(),
      year: dob.year().toString()
    }
  }
  this.flight_preferences = data.flight_preferences || {
    special_meals: '',
    preferred_seat_type: ''
  };
  this.travel_programs_membership = data.travel_programs_membership || [
    {
      name: '',
      member_number: ''
    },
    {
      name: '',
      member_number: ''
    }
  ];
  this.visas = data.visas || [
    {
      country: '',
      expiry_date: null
    }
  ];

  const company_skip_traveler_autopick = _.get(this, 'company.settings.skip_traveler_autopick', false);
  this.shouldAutoPick = () => this.employee && !this.skip_traveler_autopick && !this.anonymous && !company_skip_traveler_autopick;

  // this.display_reservation_cost_centers = ['5b34a42b83e5d207589df34b','5b4ef188b2faff09a45330fa'].includes(this.company.id);

  this.isApprovalFlowPowerUser = () => this.regional_admin || this.approver || this.travel_manager || this.executive;
  this.private_travel_search_enabled = data.private_travel_search_enabled;

  this.isPrivateTravelOnly = () => !this.travel_manager && (this.private_travel_only || this.company.settings.employees_private_travel_only);

  this.isPrivateTravel = () => this.private_travel;

  this.isLeisureExperienceEnabled = () => _.get(this, 'company.settings.leisure_experience', false);
  this.hasBankEnabled = () => _.get(this, 'settings.bank_enabled', false);
  this.hasArbitripPoints = () => (_.get(this, 'bank.balance', 0) > 0);
  this.hasPositiveBalanceBankAccount = () => this.hasBankEnabled() && this.hasArbitripPoints();

  this.forcePrivateTravel = () => this.isPrivateTravelOnly() || this.isPrivateTravel();


  ////////////////////////////////////////////////////////////////////////////////
  // Display language
  ////////////////////////////////////////////////////////////////////////////////

  this.language = _.get(data, 'settings.language', EN);

  ////////////////////////////////////////////////////////////////////////////////
  // Display currency
  ////////////////////////////////////////////////////////////////////////////////
  this.setUserCurrency = (currency) => {
    if (this.company.partners_ui?.use_user_currency) {
      this.user_currency = currency;
    }
  }

  this.company_currency = data.currency;
  this.setUserCurrency(data.currency);

  this.display_currency = _.get(data, 'settings.display_currency', this.company_currency) || default_display_currency;
  this.setDisplayCurrency = (currency) => {
    if (isValidCurrency(currency)) {
      this.display_currency = currency;
    } else {
      console.error(`Display currency '${currency}' is invalid for profile entity.`);
    }
  };

  this.display_currency_exchange_rate = _.get(data, 'settings.display_currency_exchange_rate', default_display_currency_exchange_rate);
  this.setDisplayCurrencyExchangeRate = (exchangeRate) => {
    if (!isNaN(exchangeRate)) {
      this.display_currency_exchange_rate = Number(exchangeRate);
    } else {
      console.error(`Display currency exchange rate [${exchangeRate}] is invalid for profile entity.`);
    }
  };

  this.display_currency_status = ProfileConstants.STATUS.IDLE;
  this.setDisplayCurrencyStatus = (status) => {
    this.display_currency_status = status;
  };
  this.isDisplayCurrencyBusy = () => (this.display_currency_status === ProfileConstants.STATUS.BUSY);

  ////////////////////////////////////////////////////////////////////////////////

  this.weights = {
    price: _.get(data, 'weights.price', 14),
    none_penalty: _.get(data, 'weights.none_penalty', -30000),
    shallow_penalty: _.get(data, 'weights.shallow_penalty', -10000),

    leisure: {}
  };

  const leisure_weights_prefix = 'weights.leisure';

  for (const recommendation_profile in ProfileConstants.RECOMMENDATION_PROFILES) {
    const leisure_weights_profile = ProfileConstants.RECOMMENDATION_PROFILES[recommendation_profile];
    this.weights.leisure[leisure_weights_profile] = {
      leisure_diff_abs: _.get(data, `${leisure_weights_prefix}.${leisure_weights_profile}.leisure_diff_abs`, 0),
      leisure_diff_per: _.get(data, `${leisure_weights_prefix}.${leisure_weights_profile}.leisure_diff_per`, 0),
      leisure_score_total: _.get(data, `${leisure_weights_prefix}.${leisure_weights_profile}.leisure_score_total`, 0),
      leisure_cheapest_supplier_factor: _.get(data, `${leisure_weights_prefix}.${leisure_weights_profile}.leisure_cheapest_supplier_factor`, 0),
      leisure_review_score: _.get(data, `${leisure_weights_prefix}.${leisure_weights_profile}.leisure_review_score`, 0),
      leisure_stars: _.get(data, `${leisure_weights_prefix}.${leisure_weights_profile}.leisure_stars`, 0),

      leisure_score_location: _.get(data, `${leisure_weights_prefix}.${leisure_weights_profile}.leisure_score_location`, 0),
      leisure_score_amenities: _.get(data, `${leisure_weights_prefix}.${leisure_weights_profile}.leisure_score_amenities`, 0),
      leisure_score_vibe: _.get(data, `${leisure_weights_prefix}.${leisure_weights_profile}.leisure_score_vibe`, 0),
      leisure_score_popularity: _.get(data, `${leisure_weights_prefix}.${leisure_weights_profile}.leisure_score_popularity`, 0),

      leisure_distance: _.get(data, `${leisure_weights_prefix}.${leisure_weights_profile}.leisure_distance`, 0),
      // leisure_specific_distance: _.get(data, `${leisure_weights_prefix}.${leisure_weights_profile}.leisure_specific_distance`, 0),
      // leisure_alternative_distance: _.get(data, `${leisure_weights_prefix}.${leisure_weights_profile}.leisure_alternative_distance`, 0),

      leisure_price: _.get(data, `${leisure_weights_prefix}.${leisure_weights_profile}.leisure_price`, 0),
      leisure_cheapest: _.get(data, `${leisure_weights_prefix}.${leisure_weights_profile}.leisure_cheapest`, 0),

      neighborhood_match: _.get(data, `${leisure_weights_prefix}.${leisure_weights_profile}.neighborhood_match`, 0),
    }
  }

  const web_storage_recommendation_weights = WebStorageManager.getFromWebStorage('_recommendation_weights');
  if (!web_storage_recommendation_weights) {
    WebStorageManager.addToWebStorage('_recommendation_weights', this.weights.leisure);
  } else {
    WebStorageManager.updateInWebStorage('_recommendation_weights', this.weights.leisure);
  }

  const recommendation_profile = _.get(data, 'weights.recommendation_profile', ProfileConstants.RECOMMENDATION_PROFILES.NORMAL);
  if (!WebStorageManager.getFromWebStorage('_recommendation_profile')) {
    WebStorageManager.addToWebStorage('_recommendation_profile', recommendation_profile);
  } else {
    WebStorageManager.updateInWebStorage('_recommendation_profile', recommendation_profile);
  }

  this.travel_policy_enabled = _.get(data, 'travel_policy_enabled', false);

  setForceLongPolling(data);

  this.loyalty = _.get(data, 'loyalty', {});

  if (this.company.partners_ui?.custom_logout_link) {
    this.custom_logout_link = this.company.partners_ui?.custom_logout_link;
  }
  
    this.freelance_agent = data?.pay_later_allowed;
    this.freelance_agent_pay_later_buffer_days = data?.company?.settings?.pay_later_buffer || 7;
    this.freelance_agent_pay_later_exchange_rates = data?.pay_later_exchange_rates || {};
}

module.exports = Profile;