import React from 'react';
import createClass from 'create-react-class';

import ReservationStore from '../../../stores/ReservationStore';
import PaymentStore from '../../../stores/PaymentStore';
import ReservationActions from '../../../actions/ReservationActions';
import { withTranslation } from 'react-i18next';

// Method to retrieve state from Stores
function getCompanyPolicyComponentState() {
	return {
		reservationData: ReservationStore.getReservationData(),
		paymentData: PaymentStore.getPaymentData(),
	};
}

// Define main Controller View
var CompanyPolicyComponent = createClass({
	displayName: 'CompanyPolicyComponent',

	// Get initial state from stores
	getInitialState: function () {
		return getCompanyPolicyComponentState();
	},

	// Add change listeners to stores
	componentDidMount: function () {
		ReservationStore.addChangeListener(this._onChange);
		PaymentStore.addChangeListener(this._onChange);
	},

	// Remove change listeners from stores
	componentWillUnmount: function () {
		PaymentStore.removeChangeListener(this._onChange);
		ReservationStore.removeChangeListener(this._onChange);
	},

	makeReservation: function (e) {
		if (this.props.valid) {
			// if (!this.state.reservationData.deal.refundable) {
			//   // this.openNonRefundableModal();
			//   ReservationActions.markForNonRefundableQuery();
			//
			// } else {
			//   // ReservationActions.bookReservation(this.state.reservationData, 'company-policy');
			//   ReservationActions.bookReservationWithCompanyPolicy(this.state.reservationData, this.state.paymentData);
			// }

			const hotel_recommended_index = this.state.reservationData.hotel.recommended_index;
			const deal_index = this.state.reservationData.deal.deal_index;

			ReservationActions.bookReservationWithCompanyPolicy(
				this.state.reservationData,
				this.state.paymentData,
				hotel_recommended_index,
				deal_index,
			);
		} else {
			// TODO: alert the user
		}
	},

	// Render our child components, passing state via props
	render: function () {
		const { t } = this.props;
		// var buttonValue = "Complete Booking";
		// var RESERVATION_BUTTON = (this.props.valid && (this.state.reservationData.status === 'initialized'))
		//   ? <input type="button" className="btn btn-success" onClick={this.makeReservation} value={buttonValue} />
		//   : <input type="button" className="btn btn-success disabled" value={buttonValue} />;

		// var SPINNER = (this.state.paymentData.status === 'booking')
		//   // ? <img src="./img/reservation_page/spinner.svg"/>
		//   ? <i className="fa fa-spin fa-spinner fa-2x" />
		//   : null;

		return (
			<div className="company-policy-component">
				<div className="content">{t('ThePaymentForThisReservation')}</div>
				{/* <div className="button-container">
          { RESERVATION_BUTTON }
          { SPINNER }
        </div> */}
			</div>
		);
	},

	// Method to setState based upon Store changes
	_onChange: function () {
		this.setState(getCompanyPolicyComponentState());
	},
});

export default withTranslation()(CompanyPolicyComponent);
