const ObjectId = (oid) => oid;
// module.exports = null;
module.exports = [
	/* 1 */
	{
		_id: ObjectId('61b274bfd17acfb0a7fbac74'),
		id: '6143288',
		coordinates: {
			center_longitude: 24.776167,
			center_latitude: 35.342632,
			bounding_polygon: {
				type: 'Polygon',
				coordinates: [
					[
						[24.643733, 35.413021],
						[24.644525, 35.411301],
						[24.645049, 35.410316],
						[24.647228, 35.407482],
						[24.647897, 35.406459],
						[24.648864, 35.404434],
						[24.649466, 35.402592],
						[24.649697, 35.401265],
						[24.64977, 35.399813],
						[24.649678, 35.398082],
						[24.649455, 35.396659],
						[24.649073, 35.396174],
						[24.648622, 35.395951],
						[24.647983, 35.395855],
						[24.646606, 35.395799],
						[24.6461, 35.395694],
						[24.645875, 35.395371],
						[24.645881, 35.394622],
						[24.646006, 35.394072],
						[24.646242, 35.393692],
						[24.646503, 35.393537],
						[24.647491, 35.393189],
						[24.647744, 35.392926],
						[24.648549, 35.390451],
						[24.649732, 35.38545],
						[24.650308, 35.383418],
						[24.650687, 35.382577],
						[24.651623, 35.381003],
						[24.649569, 35.378877],
						[24.648287, 35.377099],
						[24.646821, 35.374474],
						[24.646573, 35.373096],
						[24.645966, 35.37016],
						[24.645805, 35.368186],
						[24.645897, 35.364939],
						[24.646244, 35.362692],
						[24.647102, 35.360361],
						[24.648161, 35.358372],
						[24.649162, 35.357095],
						[24.651326, 35.354866],
						[24.653556, 35.352599],
						[24.654031, 35.351902],
						[24.654402, 35.351071],
						[24.65473, 35.349825],
						[24.655011, 35.34793],
						[24.65512, 35.343926],
						[24.657281, 35.343553],
						[24.658371, 35.343457],
						[24.661556, 35.343431],
						[24.668017, 35.343558],
						[24.670609, 35.336411],
						[24.674222, 35.336012],
						[24.675395, 35.335807],
						[24.676017, 35.335624],
						[24.676799, 35.335268],
						[24.677469, 35.334804],
						[24.678114, 35.334187],
						[24.678764, 35.333336],
						[24.679057, 35.332757],
						[24.679228, 35.332044],
						[24.679259, 35.327986],
						[24.679382, 35.327111],
						[24.67962, 35.32637],
						[24.680194, 35.325655],
						[24.682282, 35.324299],
						[24.682719, 35.3239],
						[24.682974, 35.323529],
						[24.683299, 35.322427],
						[24.683425, 35.320299],
						[24.683556, 35.319486],
						[24.684934, 35.315881],
						[24.686182, 35.313169],
						[24.68755, 35.31051],
						[24.69077, 35.305515],
						[24.693571, 35.302848],
						[24.694569, 35.301625],
						[24.695072, 35.30072],
						[24.695287, 35.299312],
						[24.695164, 35.295832],
						[24.695321, 35.294335],
						[24.695636, 35.293792],
						[24.696858, 35.292676],
						[24.697182, 35.292034],
						[24.697622, 35.289425],
						[24.698399, 35.288014],
						[24.698591, 35.287508],
						[24.698629, 35.286976],
						[24.698484, 35.285706],
						[24.698512, 35.285074],
						[24.699376, 35.281417],
						[24.700225, 35.279086],
						[24.701082, 35.277548],
						[24.702285, 35.275837],
						[24.707131, 35.270065],
						[24.708297, 35.269806],
						[24.714652, 35.266937],
						[24.721581, 35.264191],
						[24.730673, 35.260719],
						[24.731606, 35.260425],
						[24.732328, 35.260294],
						[24.732635, 35.260347],
						[24.733433, 35.260676],
						[24.735404, 35.260826],
						[24.738272, 35.260835],
						[24.740356, 35.26076],
						[24.74166, 35.260589],
						[24.742489, 35.260314],
						[24.743211, 35.259922],
						[24.746093, 35.257767],
						[24.746831, 35.257104],
						[24.748023, 35.25477],
						[24.748351, 35.254318],
						[24.748759, 35.253954],
						[24.749269, 35.25369],
						[24.749867, 35.253515],
						[24.752141, 35.25323],
						[24.753002, 35.253053],
						[24.753863, 35.252444],
						[24.755843, 35.250195],
						[24.756137, 35.249662],
						[24.756175, 35.249183],
						[24.755903, 35.24877],
						[24.755289, 35.24835],
						[24.752146, 35.247026],
						[24.754527, 35.241971],
						[24.755823, 35.239493],
						[24.757276, 35.237906],
						[24.759457, 35.234366],
						[24.7598, 35.234013],
						[24.761015, 35.233085],
						[24.762586, 35.230307],
						[24.763144, 35.229546],
						[24.763904, 35.228775],
						[24.76554, 35.227376],
						[24.765986, 35.226535],
						[24.76642, 35.226297],
						[24.767132, 35.226059],
						[24.768869, 35.225714],
						[24.773123, 35.225263],
						[24.77638, 35.224467],
						[24.777597, 35.224071],
						[24.778234, 35.223733],
						[24.781846, 35.221149],
						[24.783187, 35.22041],
						[24.784008, 35.220225],
						[24.785847, 35.220159],
						[24.787211, 35.219519],
						[24.787912, 35.219289],
						[24.788771, 35.219211],
						[24.789671, 35.219304],
						[24.790412, 35.219498],
						[24.791468, 35.219951],
						[24.794929, 35.22184],
						[24.795993, 35.223663],
						[24.814227, 35.219259],
						[24.816278, 35.221912],
						[24.816768, 35.227072],
						[24.817256, 35.231871],
						[24.817718, 35.234149],
						[24.81847, 35.236185],
						[24.819227, 35.238702],
						[24.822324, 35.241082],
						[24.826442, 35.243095],
						[24.830119, 35.244871],
						[24.829713, 35.248115],
						[24.82842, 35.250526],
						[24.82829, 35.252087],
						[24.829344, 35.254962],
						[24.829665, 35.257721],
						[24.830123, 35.259639],
						[24.834521, 35.260209],
						[24.835711, 35.262122],
						[24.835446, 35.264765],
						[24.836204, 35.267281],
						[24.841356, 35.270007],
						[24.848106, 35.271521],
						[24.847239, 35.272607],
						[24.843897, 35.275032],
						[24.837061, 35.279522],
						[24.835203, 35.283857],
						[24.836571, 35.28877],
						[24.839271, 35.293254],
						[24.838735, 35.294518],
						[24.838638, 35.29578],
						[24.839093, 35.297308],
						[24.840087, 35.297751],
						[24.841046, 35.298381],
						[24.841202, 35.299364],
						[24.840556, 35.300629],
						[24.838373, 35.301995],
						[24.835864, 35.303723],
						[24.833765, 35.305441],
						[24.831587, 35.309299],
						[24.83198, 35.313209],
						[24.832401, 35.315127],
						[24.832668, 35.315486],
						[24.833658, 35.316146],
						[24.833979, 35.316496],
						[24.834478, 35.317583],
						[24.835358, 35.32012],
						[24.836105, 35.321539],
						[24.83652, 35.322068],
						[24.837028, 35.322534],
						[24.837643, 35.322944],
						[24.838224, 35.32322],
						[24.839149, 35.32343],
						[24.841038, 35.323444],
						[24.845138, 35.324145],
						[24.846495, 35.324208],
						[24.847413, 35.324102],
						[24.847885, 35.323928],
						[24.848212, 35.323637],
						[24.848651, 35.322453],
						[24.848897, 35.322054],
						[24.849347, 35.321672],
						[24.849992, 35.321298],
						[24.850701, 35.321014],
						[24.851528, 35.320792],
						[24.855725, 35.320023],
						[24.857846, 35.319115],
						[24.862756, 35.315563],
						[24.863361, 35.315947],
						[24.865015, 35.315764],
						[24.865762, 35.315831],
						[24.866785, 35.316139],
						[24.869514, 35.317165],
						[24.87088, 35.317462],
						[24.871777, 35.317455],
						[24.872864, 35.317294],
						[24.874626, 35.316542],
						[24.878915, 35.323138],
						[24.88046, 35.322658],
						[24.883263, 35.322142],
						[24.88511, 35.32193],
						[24.886201, 35.321904],
						[24.887522, 35.321984],
						[24.890564, 35.322422],
						[24.891495, 35.322712],
						[24.892669, 35.323227],
						[24.893435, 35.323618],
						[24.893922, 35.323984],
						[24.894579, 35.32489],
						[24.895915, 35.327594],
						[24.896406, 35.328176],
						[24.897189, 35.328702],
						[24.89779, 35.328959],
						[24.898512, 35.329161],
						[24.900882, 35.329577],
						[24.903298, 35.329811],
						[24.904648, 35.32963],
						[24.904274, 35.33103],
						[24.902497, 35.334325],
						[24.900777, 35.337151],
						[24.898582, 35.34017],
						[24.897705, 35.340159],
						[24.896123, 35.340792],
						[24.894819, 35.341478],
						[24.894235, 35.341879],
						[24.89364, 35.342425],
						[24.892993, 35.34316],
						[24.892502, 35.343858],
						[24.890453, 35.347957],
						[24.889187, 35.351023],
						[24.889068, 35.351737],
						[24.889074, 35.352918],
						[24.889297, 35.354945],
						[24.88997, 35.360575],
						[24.889998, 35.363235],
						[24.889809, 35.364778],
						[24.889532, 35.365628],
						[24.889154, 35.366234],
						[24.888571, 35.366771],
						[24.886862, 35.368028],
						[24.886457, 35.368445],
						[24.886211, 35.368835],
						[24.885536, 35.370607],
						[24.884698, 35.374382],
						[24.88577, 35.374843],
						[24.888183, 35.376304],
						[24.889426, 35.377188],
						[24.890051, 35.377787],
						[24.890589, 35.379],
						[24.89085, 35.379386],
						[24.891596, 35.380048],
						[24.892569, 35.380663],
						[24.893725, 35.381204],
						[24.89467, 35.381468],
						[24.89666, 35.381642],
						[24.898137, 35.381676],
						[24.899237, 35.381596],
						[24.900168, 35.3814],
						[24.901233, 35.380995],
						[24.902353, 35.380437],
						[24.9037, 35.379615],
						[24.90602, 35.378029],
						[24.9072, 35.376722],
						[24.907835, 35.376221],
						[24.908549, 35.375765],
						[24.909228, 35.375471],
						[24.910601, 35.375236],
						[24.913814, 35.375049],
						[24.915418, 35.374721],
						[24.917102, 35.374032],
						[24.920795, 35.372048],
						[24.921737, 35.371662],
						[24.922494, 35.371449],
						[24.923289, 35.371632],
						[24.924453, 35.372488],
						[24.924647, 35.372803],
						[24.924857, 35.373495],
						[24.925636, 35.373724],
						[24.925902, 35.373965],
						[24.926372, 35.375242],
						[24.927223, 35.378364],
						[24.927907, 35.379874],
						[24.928589, 35.380671],
						[24.930557, 35.382423],
						[24.931249, 35.383337],
						[24.931667, 35.38438],
						[24.931888, 35.385902],
						[24.931949, 35.387092],
						[24.931873, 35.388174],
						[24.931647, 35.389132],
						[24.931263, 35.390072],
						[24.930615, 35.391231],
						[24.929716, 35.392582],
						[24.92733, 35.395296],
						[24.926847, 35.39612],
						[24.926608, 35.396825],
						[24.926607, 35.397718],
						[24.926854, 35.399952],
						[24.927238, 35.406875],
						[24.927206, 35.408762],
						[24.926999, 35.408036],
						[24.926712, 35.407963],
						[24.926872, 35.407499],
						[24.926284, 35.407442],
						[24.925465, 35.407047],
						[24.925686, 35.406851],
						[24.925686, 35.40656],
						[24.925352, 35.406437],
						[24.92467, 35.406579],
						[24.924267, 35.406749],
						[24.924121, 35.406465],
						[24.924334, 35.406155],
						[24.923951, 35.405987],
						[24.923196, 35.405941],
						[24.922844, 35.406193],
						[24.922598, 35.406109],
						[24.922318, 35.40576],
						[24.921856, 35.405483],
						[24.921457, 35.405998],
						[24.921324, 35.405673],
						[24.920895, 35.40563],
						[24.920812, 35.405131],
						[24.921663, 35.405052],
						[24.921823, 35.404608],
						[24.921776, 35.404279],
						[24.92147, 35.403491],
						[24.921237, 35.403217],
						[24.920735, 35.402881],
						[24.92025, 35.402843],
						[24.920053, 35.403095],
						[24.919535, 35.403168],
						[24.918457, 35.403548],
						[24.917526, 35.403718],
						[24.917492, 35.40532],
						[24.91707, 35.405413],
						[24.916598, 35.405331],
						[24.916375, 35.405508],
						[24.916119, 35.406014],
						[24.916162, 35.406321],
						[24.9157, 35.406196],
						[24.915344, 35.406532],
						[24.915004, 35.406695],
						[24.914612, 35.407218],
						[24.913877, 35.407655],
						[24.913877, 35.407945],
						[24.913248, 35.408224],
						[24.913331, 35.408476],
						[24.913022, 35.408649],
						[24.912423, 35.408804],
						[24.912058, 35.408709],
						[24.911615, 35.408815],
						[24.911482, 35.409126],
						[24.910784, 35.409753],
						[24.910674, 35.410298],
						[24.909816, 35.410634],
						[24.909886, 35.410962],
						[24.90945, 35.410924],
						[24.909264, 35.411347],
						[24.908954, 35.411339],
						[24.908449, 35.411734],
						[24.907747, 35.411957],
						[24.906613, 35.412149],
						[24.905751, 35.412388],
						[24.904764, 35.412179],
						[24.904604, 35.412355],
						[24.905053, 35.413082],
						[24.905422, 35.413133],
						[24.905302, 35.413464],
						[24.90497, 35.41374],
						[24.904471, 35.413881],
						[24.903975, 35.413247],
						[24.903526, 35.413223],
						[24.902841, 35.41348],
						[24.902845, 35.41371],
						[24.902173, 35.413908],
						[24.90187, 35.414396],
						[24.901577, 35.414117],
						[24.901321, 35.414253],
						[24.900746, 35.414095],
						[24.900619, 35.414385],
						[24.900337, 35.414491],
						[24.900214, 35.414778],
						[24.899698, 35.41506],
						[24.899362, 35.415006],
						[24.898797, 35.415318],
						[24.898401, 35.41509],
						[24.898348, 35.414665],
						[24.898052, 35.414516],
						[24.897217, 35.414624],
						[24.896868, 35.414806],
						[24.896362, 35.414873],
						[24.895684, 35.414852],
						[24.895251, 35.415231],
						[24.895072, 35.41564],
						[24.894762, 35.415806],
						[24.894355, 35.415772],
						[24.893626, 35.415529],
						[24.892842, 35.416406],
						[24.892939, 35.416604],
						[24.892349, 35.417011],
						[24.892349, 35.417288],
						[24.891815, 35.417639],
						[24.891239, 35.418656],
						[24.890909, 35.418671],
						[24.890389, 35.419058],
						[24.890534, 35.419208],
						[24.890073, 35.41955],
						[24.889525, 35.419807],
						[24.888532, 35.420158],
						[24.888203, 35.420133],
						[24.88762, 35.420483],
						[24.887255, 35.421275],
						[24.886579, 35.421425],
						[24.886083, 35.421928],
						[24.885684, 35.422033],
						[24.885503, 35.421889],
						[24.8855, 35.421504],
						[24.885788, 35.421052],
						[24.884764, 35.421334],
						[24.884452, 35.420797],
						[24.883222, 35.421012],
						[24.882, 35.421424],
						[24.881259, 35.42152],
						[24.880758, 35.422024],
						[24.879586, 35.421676],
						[24.879144, 35.421264],
						[24.878904, 35.420733],
						[24.878241, 35.420265],
						[24.878138, 35.420056],
						[24.877179, 35.420557],
						[24.876857, 35.420561],
						[24.876605, 35.420811],
						[24.876237, 35.420863],
						[24.875915, 35.420715],
						[24.875581, 35.419069],
						[24.876232, 35.418549],
						[24.876288, 35.417965],
						[24.875829, 35.417484],
						[24.875837, 35.417286],
						[24.876298, 35.416896],
						[24.876158, 35.416372],
						[24.876627, 35.416242],
						[24.876455, 35.416042],
						[24.877174, 35.415696],
						[24.87749, 35.415768],
						[24.87788, 35.41493],
						[24.878182, 35.414768],
						[24.877885, 35.414404],
						[24.877029, 35.414372],
						[24.876892, 35.414204],
						[24.87631, 35.414322],
						[24.875002, 35.413718],
						[24.875194, 35.413304],
						[24.874828, 35.413098],
						[24.875437, 35.412795],
						[24.875429, 35.412211],
						[24.87489, 35.412023],
						[24.874499, 35.411473],
						[24.874198, 35.411485],
						[24.874217, 35.410719],
						[24.874632, 35.410563],
						[24.874222, 35.409881],
						[24.873761, 35.409829],
						[24.873668, 35.409473],
						[24.873076, 35.409279],
						[24.87251, 35.409313],
						[24.872426, 35.409729],
						[24.872186, 35.409923],
						[24.870938, 35.409746],
						[24.870467, 35.409558],
						[24.869989, 35.409833],
						[24.868973, 35.409435],
						[24.869393, 35.410009],
						[24.870284, 35.410315],
						[24.870214, 35.410427],
						[24.869412, 35.410229],
						[24.869027, 35.410324],
						[24.868208, 35.410157],
						[24.867899, 35.410001],
						[24.86619, 35.410381],
						[24.865468, 35.410417],
						[24.864767, 35.410329],
						[24.86427, 35.410386],
						[24.863623, 35.410101],
						[24.861832, 35.409693],
						[24.859977, 35.409517],
						[24.858824, 35.409853],
						[24.85823, 35.409929],
						[24.857661, 35.409605],
						[24.857048, 35.409585],
						[24.856704, 35.409433],
						[24.856346, 35.409661],
						[24.855752, 35.409565],
						[24.85567, 35.409159],
						[24.855085, 35.408458],
						[24.854697, 35.40841],
						[24.854197, 35.408542],
						[24.853834, 35.40877],
						[24.853686, 35.409145],
						[24.853397, 35.409409],
						[24.853304, 35.409697],
						[24.853446, 35.409913],
						[24.852877, 35.410221],
						[24.852622, 35.410521],
						[24.852494, 35.410885],
						[24.852097, 35.410657],
						[24.851542, 35.410693],
						[24.851331, 35.410413],
						[24.851488, 35.409589],
						[24.850716, 35.409621],
						[24.850735, 35.408797],
						[24.850397, 35.408057],
						[24.849872, 35.408033],
						[24.849592, 35.408553],
						[24.848925, 35.408989],
						[24.848453, 35.408833],
						[24.847781, 35.408985],
						[24.847717, 35.409297],
						[24.847344, 35.409541],
						[24.846682, 35.409481],
						[24.84676, 35.409261],
						[24.847104, 35.409185],
						[24.847158, 35.408917],
						[24.848262, 35.408333],
						[24.848645, 35.408041],
						[24.848733, 35.407733],
						[24.848659, 35.407317],
						[24.848383, 35.406947],
						[24.847936, 35.406888],
						[24.847162, 35.406995],
						[24.846877, 35.40725],
						[24.845909, 35.406978],
						[24.845642, 35.406713],
						[24.845264, 35.406551],
						[24.844976, 35.40678],
						[24.844563, 35.406916],
						[24.844556, 35.407408],
						[24.844291, 35.40766],
						[24.843765, 35.407827],
						[24.84266, 35.407607],
						[24.842132, 35.406885],
						[24.841806, 35.406809],
						[24.841279, 35.406365],
						[24.84088, 35.406654],
						[24.840928, 35.406917],
						[24.840391, 35.407219],
						[24.840172, 35.40697],
						[24.83958, 35.40719],
						[24.839147, 35.406899],
						[24.838595, 35.406783],
						[24.838154, 35.406885],
						[24.837589, 35.406582],
						[24.837141, 35.406611],
						[24.836839, 35.406433],
						[24.836548, 35.406014],
						[24.835042, 35.405926],
						[24.834771, 35.406045],
						[24.83414, 35.405966],
						[24.833671, 35.406201],
						[24.832807, 35.406862],
						[24.832228, 35.407219],
						[24.831475, 35.407369],
						[24.830757, 35.407895],
						[24.830465, 35.407858],
						[24.83009, 35.408209],
						[24.830139, 35.408559],
						[24.830524, 35.40941],
						[24.829889, 35.409515],
						[24.828841, 35.409467],
						[24.82838, 35.409739],
						[24.828047, 35.410106],
						[24.82806, 35.410443],
						[24.829004, 35.411092],
						[24.828876, 35.411595],
						[24.829006, 35.411974],
						[24.828364, 35.412421],
						[24.828458, 35.413117],
						[24.828243, 35.4133],
						[24.828312, 35.413668],
						[24.828115, 35.413815],
						[24.827479, 35.413456],
						[24.827358, 35.413688],
						[24.827538, 35.414055],
						[24.827403, 35.414324],
						[24.827479, 35.414663],
						[24.827299, 35.415195],
						[24.826917, 35.415153],
						[24.826352, 35.415339],
						[24.826102, 35.415523],
						[24.825685, 35.415574],
						[24.825439, 35.415212],
						[24.824732, 35.41492],
						[24.824939, 35.414736],
						[24.825199, 35.414232],
						[24.82541, 35.414048],
						[24.825787, 35.414068],
						[24.827076, 35.413708],
						[24.827207, 35.4134],
						[24.827197, 35.412978],
						[24.826972, 35.41235],
						[24.826488, 35.411719],
						[24.826128, 35.411667],
						[24.825649, 35.411924],
						[24.825227, 35.41193],
						[24.824435, 35.412356],
						[24.823871, 35.411938],
						[24.823326, 35.411325],
						[24.823345, 35.411093],
						[24.82283, 35.410509],
						[24.822845, 35.410081],
						[24.822354, 35.409825],
						[24.822261, 35.409593],
						[24.821618, 35.409177],
						[24.820975, 35.408889],
						[24.820872, 35.408673],
						[24.820072, 35.408265],
						[24.819459, 35.407821],
						[24.819199, 35.408045],
						[24.818497, 35.408025],
						[24.817825, 35.407505],
						[24.817481, 35.407557],
						[24.816848, 35.407921],
						[24.816775, 35.408145],
						[24.816255, 35.408089],
						[24.815926, 35.408257],
						[24.815263, 35.407773],
						[24.814439, 35.407845],
						[24.81383, 35.408285],
						[24.81334, 35.408053],
						[24.81281, 35.408317],
						[24.812564, 35.408605],
						[24.81225, 35.409389],
						[24.811995, 35.409725],
						[24.811112, 35.409869],
						[24.809129, 35.410493],
						[24.808325, 35.410893],
						[24.807736, 35.411037],
						[24.80701, 35.410925],
						[24.806637, 35.410317],
						[24.805243, 35.409533],
						[24.804144, 35.409597],
						[24.80222, 35.409901],
						[24.800728, 35.409789],
						[24.799551, 35.409517],
						[24.799021, 35.409709],
						[24.797784, 35.409053],
						[24.797156, 35.409373],
						[24.796744, 35.409693],
						[24.796763, 35.410397],
						[24.796292, 35.411229],
						[24.796041, 35.41123],
						[24.795556, 35.410439],
						[24.794418, 35.409799],
						[24.793945, 35.410674],
						[24.79343, 35.411497],
						[24.792598, 35.410694],
						[24.792531, 35.409879],
						[24.793528, 35.409251],
						[24.792829, 35.408867],
						[24.792348, 35.408234],
						[24.791383, 35.407584],
						[24.789378, 35.407285],
						[24.789033, 35.407127],
						[24.788724, 35.406715],
						[24.787837, 35.405917],
						[24.787359, 35.40616],
						[24.786775, 35.406825],
						[24.785844, 35.407023],
						[24.784663, 35.407675],
						[24.783397, 35.408472],
						[24.783442, 35.408783],
						[24.783144, 35.409003],
						[24.783359, 35.409657],
						[24.783775, 35.409819],
						[24.783794, 35.410092],
						[24.783163, 35.410077],
						[24.782919, 35.410185],
						[24.782663, 35.410572],
						[24.782599, 35.410958],
						[24.782916, 35.410984],
						[24.783315, 35.411403],
						[24.783985, 35.41192],
						[24.784429, 35.412882],
						[24.783863, 35.412957],
						[24.783552, 35.412804],
						[24.783347, 35.413063],
						[24.783248, 35.413893],
						[24.783472, 35.414501],
						[24.784087, 35.414285],
						[24.78449, 35.413783],
						[24.784659, 35.413903],
						[24.78428, 35.414351],
						[24.78487, 35.414601],
						[24.785156, 35.415248],
						[24.784863, 35.416097],
						[24.785764, 35.416159],
						[24.785212, 35.416743],
						[24.785436, 35.417206],
						[24.786185, 35.416956],
						[24.786297, 35.417186],
						[24.786743, 35.417349],
						[24.787183, 35.417366],
						[24.78675, 35.41781],
						[24.78636, 35.41806],
						[24.786174, 35.417707],
						[24.785554, 35.41761],
						[24.785065, 35.418195],
						[24.785407, 35.418545],
						[24.785132, 35.418633],
						[24.785231, 35.41902],
						[24.785088, 35.419308],
						[24.78386, 35.419361],
						[24.783325, 35.419538],
						[24.782938, 35.419928],
						[24.782256, 35.419745],
						[24.782045, 35.419412],
						[24.780898, 35.419601],
						[24.780526, 35.41976],
						[24.780068, 35.419533],
						[24.779576, 35.41916],
						[24.778661, 35.419464],
						[24.77864, 35.420026],
						[24.778331, 35.420243],
						[24.77801, 35.420207],
						[24.777646, 35.419532],
						[24.777188, 35.419077],
						[24.776431, 35.418678],
						[24.775904, 35.417916],
						[24.774729, 35.417727],
						[24.773883, 35.417864],
						[24.773076, 35.41783],
						[24.772316, 35.417927],
						[24.772021, 35.418145],
						[24.771507, 35.41822],
						[24.770741, 35.418071],
						[24.770311, 35.417715],
						[24.769925, 35.417664],
						[24.769488, 35.417429],
						[24.769334, 35.417136],
						[24.768961, 35.417285],
						[24.768398, 35.417314],
						[24.767997, 35.416987],
						[24.768328, 35.416724],
						[24.768053, 35.416483],
						[24.768039, 35.416208],
						[24.767617, 35.415995],
						[24.767251, 35.41611],
						[24.767152, 35.416616],
						[24.766267, 35.417406],
						[24.764909, 35.417864],
						[24.764438, 35.417497],
						[24.764023, 35.417532],
						[24.76353, 35.417721],
						[24.763544, 35.418019],
						[24.763052, 35.418627],
						[24.762531, 35.418403],
						[24.762257, 35.418438],
						[24.76218, 35.418741],
						[24.762419, 35.419137],
						[24.762278, 35.419341],
						[24.756107, 35.419931],
						[24.748897, 35.419232],
						[24.742374, 35.419511],
						[24.739284, 35.42175],
						[24.738597, 35.424408],
						[24.733109, 35.425413],
						[24.717531, 35.425586],
						[24.715851, 35.424509],
						[24.714584, 35.424644],
						[24.713703, 35.424442],
						[24.713317, 35.424789],
						[24.711128, 35.42599],
						[24.709131, 35.425822],
						[24.708002, 35.425395],
						[24.707616, 35.425811],
						[24.7069, 35.425833],
						[24.706405, 35.426035],
						[24.705468, 35.426214],
						[24.705, 35.426574],
						[24.704408, 35.426719],
						[24.704188, 35.426237],
						[24.703527, 35.425855],
						[24.703017, 35.426069],
						[24.701902, 35.425956],
						[24.701027, 35.426061],
						[24.701197, 35.426734],
						[24.701001, 35.426997],
						[24.700631, 35.426986],
						[24.699869, 35.427327],
						[24.699169, 35.42731],
						[24.698859, 35.427146],
						[24.699243, 35.426602],
						[24.698452, 35.425588],
						[24.697973, 35.425513],
						[24.69752, 35.424879],
						[24.696972, 35.424515],
						[24.696898, 35.423999],
						[24.696635, 35.423911],
						[24.695826, 35.423401],
						[24.695894, 35.422929],
						[24.695763, 35.422355],
						[24.695187, 35.421731],
						[24.694941, 35.421147],
						[24.694541, 35.420859],
						[24.694713, 35.420724],
						[24.694529, 35.420429],
						[24.694391, 35.419814],
						[24.694043, 35.419719],
						[24.693718, 35.419435],
						[24.693606, 35.419138],
						[24.693117, 35.41875],
						[24.692364, 35.418567],
						[24.692074, 35.4188],
						[24.691579, 35.418637],
						[24.69092, 35.41893],
						[24.690847, 35.419324],
						[24.691577, 35.419771],
						[24.69216, 35.42],
						[24.692045, 35.420164],
						[24.691455, 35.419913],
						[24.690849, 35.419539],
						[24.690107, 35.419364],
						[24.689442, 35.418889],
						[24.689112, 35.418455],
						[24.6886, 35.418526],
						[24.688218, 35.418793],
						[24.688048, 35.419602],
						[24.687628, 35.419723],
						[24.687267, 35.419552],
						[24.687285, 35.419996],
						[24.686703, 35.419608],
						[24.686307, 35.419751],
						[24.686312, 35.420113],
						[24.686041, 35.420475],
						[24.685708, 35.420296],
						[24.684913, 35.420643],
						[24.684937, 35.420951],
						[24.684614, 35.420971],
						[24.684476, 35.420679],
						[24.684127, 35.420683],
						[24.683819, 35.420867],
						[24.683519, 35.420851],
						[24.682863, 35.420172],
						[24.682482, 35.420236],
						[24.682081, 35.420066],
						[24.681764, 35.420137],
						[24.68118, 35.420019],
						[24.680752, 35.420281],
						[24.680184, 35.420207],
						[24.680218, 35.419789],
						[24.679748, 35.419104],
						[24.678914, 35.418594],
						[24.677405, 35.419064],
						[24.674341, 35.420273],
						[24.674189, 35.420061],
						[24.672648, 35.419427],
						[24.671237, 35.419607],
						[24.670624, 35.419277],
						[24.669473, 35.419401],
						[24.668501, 35.419887],
						[24.667848, 35.420118],
						[24.667598, 35.4204],
						[24.667074, 35.420676],
						[24.666649, 35.420327],
						[24.664857, 35.420027],
						[24.664191, 35.420041],
						[24.663347, 35.420174],
						[24.663065, 35.419951],
						[24.662799, 35.419548],
						[24.661962, 35.419838],
						[24.661569, 35.419801],
						[24.660894, 35.420257],
						[24.660378, 35.42006],
						[24.659734, 35.420158],
						[24.658814, 35.420026],
						[24.658527, 35.419753],
						[24.658487, 35.41948],
						[24.657911, 35.419492],
						[24.657874, 35.419306],
						[24.657176, 35.419436],
						[24.656536, 35.419452],
						[24.656081, 35.419931],
						[24.655421, 35.419924],
						[24.654845, 35.419656],
						[24.654456, 35.419254],
						[24.65425, 35.419461],
						[24.653458, 35.419621],
						[24.652766, 35.419906],
						[24.652385, 35.419819],
						[24.651925, 35.419941],
						[24.651314, 35.419804],
						[24.65105, 35.41993],
						[24.650377, 35.419796],
						[24.650451, 35.419586],
						[24.650188, 35.419079],
						[24.649754, 35.419403],
						[24.64888, 35.419042],
						[24.648306, 35.419172],
						[24.647966, 35.419449],
						[24.647894, 35.41889],
						[24.647184, 35.418525],
						[24.64724, 35.417941],
						[24.647121, 35.417423],
						[24.646954, 35.417389],
						[24.646648, 35.417861],
						[24.646525, 35.417273],
						[24.646284, 35.416896],
						[24.646347, 35.416547],
						[24.646186, 35.416146],
						[24.646349, 35.415965],
						[24.646127, 35.415695],
						[24.646334, 35.415147],
						[24.645883, 35.415082],
						[24.645406, 35.414655],
						[24.645274, 35.414366],
						[24.645576, 35.414284],
						[24.645481, 35.414006],
						[24.644764, 35.413333],
						[24.644435, 35.413315],
						[24.643733, 35.413021],
					],
				],
			},
			arbitrip_center: {
				type: 'Point',
				coordinates: [24.776167, 35.342632],
			},
		},
		descendants: {
			high_level_region: ['553248635976432079'],
			city: [
				'553248635975863126',
				'3000427222',
				'3000030290',
				'3000030802',
				'3000756377',
				'553248635975855568',
				'553248635975858129',
				'3000702674',
				'3000739346',
				'553248635975866012',
				'3000739280',
				'553248635975867098',
				'11983',
				'3000739338',
				'6035278',
				'3000469380',
				'553248635975858754',
				'3000739331',
				'3000030409',
				'3000739394',
				'553248635975870541',
				'553248635975857354',
				'6125057',
				'3000471882',
				'3000739333',
				'553248635975863794',
				'553248635975848816',
				'553248635975852222',
				'3000702513',
				'553248635975871930',
				'3000403256',
				'6129459',
				'553248623649197692',
				'553248635975863147',
				'6131875',
				'3000739365',
			],
			point_of_interest: [
				'553248635997386638',
				'6160316',
				'553248635997385307',
				'553248635997386635',
				'553248621533101489',
				'553248635997385733',
				'553248635975913458',
				'6160305',
			],
		},
		name: 'Mylopotamos',
		name_full: 'Mylopotamos, Crete, Greece',
		property_ids: [
			'22722946',
			'52969371',
			'62983957',
			'17342384',
			'34551162',
			'37216402',
			'62983961',
			'22324938',
			'65491390',
			'37211109',
			'32166338',
			'2876532',
			'52420457',
			'4238754',
			'22326973',
			'21719837',
			'67388540',
			'44466383',
			'3688151',
			'47001429',
			'5308400',
			'18801462',
			'44940992',
			'6057315',
			'12608693',
			'5075378',
			'10000225',
			'32621794',
			'66953254',
			'66674966',
			'6986865',
			'25056489',
			'27771959',
			'2942156',
			'19676373',
			'12066629',
			'11904095',
			'21720285',
			'45959214',
			'67388556',
			'57904428',
			'67388557',
			'27108962',
			'22681625',
			'51815602',
			'70409965',
			'66726156',
			'9853608',
			'62984347',
			'50719281',
			'26445698',
			'23510947',
			'53159469',
			'16236401',
			'53159365',
			'53159364',
			'53159363',
			'21720352',
			'52110680',
			'53159362',
			'53159361',
			'17329373',
			'12475411',
			'68892975',
			'18809494',
			'15253843',
			'16553711',
			'18139882',
			'8369951',
			'9613588',
			'36389216',
			'28670936',
			'7742992',
			'23370834',
			'20187823',
			'2893774',
			'62984447',
			'34364121',
			'14818938',
			'30999011',
			'36274360',
			'40068737',
			'9746501',
			'37416565',
			'3498133',
			'5377734',
			'12066378',
			'28817298',
			'44391345',
			'46065211',
			'19560466',
			'42407238',
			'31726089',
			'36245256',
			'69538579',
			'42627090',
			'4430964',
			'3444165',
			'28658278',
			'46340623',
			'5370707',
			'62983966',
			'67626258',
			'62983968',
			'62983969',
			'28777433',
			'57161769',
			'22509494',
			'63875210',
			'62983974',
			'19483911',
			'29864058',
			'25108275',
			'26963495',
		],
		type: 'city',
	},

	/* 2 */
	{
		_id: ObjectId('61b276c7d17acfb0a7fc5751'),
		id: '6046550',
		coordinates: {
			center_longitude: 24.59979,
			center_latitude: 35.263573,
			bounding_polygon: {
				type: 'Polygon',
				coordinates: [
					[
						[24.270196, 35.249197],
						[24.270459, 35.248908],
						[24.272505, 35.246667],
						[24.274008, 35.245366],
						[24.278089, 35.242606],
						[24.27891, 35.242153],
						[24.280073, 35.241646],
						[24.284173, 35.240094],
						[24.285905, 35.239531],
						[24.286863, 35.239312],
						[24.287847, 35.239202],
						[24.288081, 35.239192],
						[24.289271, 35.239144],
						[24.294337, 35.239204],
						[24.298749, 35.239311],
						[24.300931, 35.239233],
						[24.302209, 35.239005],
						[24.304613, 35.238322],
						[24.302873, 35.235892],
						[24.302006, 35.234244],
						[24.30107, 35.232028],
						[24.300059, 35.229262],
						[24.299065, 35.22546],
						[24.298558, 35.223991],
						[24.29756, 35.221992],
						[24.295011, 35.21758],
						[24.294064, 35.215472],
						[24.293714, 35.214057],
						[24.293498, 35.212345],
						[24.293647, 35.209369],
						[24.293558, 35.208115],
						[24.293165, 35.205519],
						[24.292141, 35.199985],
						[24.290201, 35.197402],
						[24.289115, 35.19616],
						[24.288288, 35.195441],
						[24.286137, 35.193877],
						[24.285336, 35.193149],
						[24.284743, 35.192348],
						[24.284444, 35.191582],
						[24.28446, 35.190788],
						[24.284808, 35.189714],
						[24.285145, 35.189055],
						[24.285975, 35.18808],
						[24.286222, 35.187682],
						[24.287711, 35.184442],
						[24.288336, 35.182438],
						[24.288467, 35.181383],
						[24.288412, 35.180229],
						[24.287069, 35.175317],
						[24.282588, 35.072849],
						[24.283288, 35.072847],
						[24.285631, 35.072847],
						[24.293008, 35.073028],
						[24.301054, 35.07364],
						[24.309035, 35.074683],
						[24.316915, 35.076153],
						[24.324662, 35.078042],
						[24.332243, 35.080344],
						[24.333097, 35.080632],
						[24.333857, 35.080544],
						[24.33814, 35.080121],
						[24.342619, 35.078481],
						[24.350164, 35.076102],
						[24.357881, 35.074132],
						[24.362725, 35.073124],
						[24.366932, 35.071305],
						[24.374269, 35.068523],
						[24.376316, 35.067831],
						[24.378295, 35.066856],
						[24.385395, 35.063685],
						[24.392731, 35.060902],
						[24.400273, 35.058519],
						[24.406233, 35.056955],
						[24.409098, 35.056249],
						[24.410022, 35.055924],
						[24.412968, 35.054937],
						[24.4202, 35.052659],
						[24.427913, 35.050685],
						[24.435766, 35.049129],
						[24.443727, 35.047999],
						[24.451759, 35.047298],
						[24.452703, 35.047245],
						[24.453762, 35.045896],
						[24.455945, 35.043351],
						[24.453957, 35.039105],
						[24.451554, 35.032762],
						[24.449661, 35.026304],
						[24.448286, 35.019758],
						[24.447434, 35.013152],
						[24.44711, 35.006515],
						[24.447107, 35.005615],
						[24.44711, 35.003852],
						[24.447317, 34.998112],
						[24.448048, 34.991496],
						[24.449303, 34.984934],
						[24.451076, 34.978454],
						[24.453361, 34.972083],
						[24.456146, 34.965848],
						[24.45942, 34.959777],
						[24.463169, 34.953895],
						[24.467377, 34.948227],
						[24.472025, 34.942798],
						[24.475301, 34.939379],
						[24.475595, 34.939086],
						[24.477387, 34.937337],
						[24.482855, 34.932453],
						[24.488698, 34.927874],
						[24.494891, 34.923619],
						[24.501409, 34.919707],
						[24.508222, 34.916153],
						[24.515302, 34.912974],
						[24.52262, 34.910183],
						[24.530142, 34.907791],
						[24.537838, 34.905809],
						[24.545675, 34.904246],
						[24.553619, 34.903107],
						[24.55672, 34.902782],
						[24.560943, 34.902231],
						[24.56896, 34.901522],
						[24.577015, 34.901246],
						[24.578118, 34.901242],
						[24.587492, 34.901242],
						[24.59445, 34.901403],
						[24.602481, 34.901994],
						[24.609294, 34.902868],
						[24.609559, 34.944057],
						[24.609846, 34.988748],
						[24.654088, 35.029977],
						[24.690196, 35.063611],
						[24.721437, 35.0927],
						[24.722119, 35.093917],
						[24.722474, 35.094677],
						[24.722708, 35.096921],
						[24.723106, 35.098956],
						[24.724376, 35.104188],
						[24.724904, 35.107602],
						[24.724913, 35.108684],
						[24.724812, 35.109388],
						[24.723869, 35.111612],
						[24.724636, 35.111553],
						[24.72694, 35.111792],
						[24.728465, 35.11199],
						[24.729726, 35.112253],
						[24.730395, 35.112528],
						[24.731684, 35.113404],
						[24.732896, 35.113893],
						[24.733842, 35.11414],
						[24.734467, 35.114136],
						[24.735957, 35.113532],
						[24.736495, 35.11351],
						[24.736854, 35.113779],
						[24.737251, 35.114407],
						[24.737553, 35.115037],
						[24.737746, 35.116181],
						[24.737861, 35.116388],
						[24.738055, 35.116504],
						[24.738985, 35.116471],
						[24.741226, 35.115898],
						[24.74213, 35.115775],
						[24.744532, 35.115851],
						[24.747161, 35.116123],
						[24.749473, 35.116542],
						[24.750277, 35.116681],
						[24.75559, 35.117838],
						[24.760332, 35.119008],
						[24.765001, 35.120286],
						[24.765481, 35.120499],
						[24.766074, 35.12091],
						[24.767047, 35.121788],
						[24.767704, 35.122514],
						[24.768463, 35.123709],
						[24.769669, 35.126199],
						[24.773467, 35.122847],
						[24.774503, 35.122092],
						[24.775491, 35.121536],
						[24.775848, 35.121461],
						[24.77624, 35.12154],
						[24.777918, 35.122359],
						[24.779282, 35.122611],
						[24.779862, 35.122518],
						[24.780244, 35.122245],
						[24.78098, 35.121392],
						[24.781457, 35.121011],
						[24.782438, 35.120409],
						[24.783423, 35.120033],
						[24.784186, 35.119866],
						[24.784963, 35.119798],
						[24.785594, 35.119857],
						[24.786211, 35.120042],
						[24.787184, 35.120505],
						[24.788129, 35.121121],
						[24.788957, 35.121855],
						[24.789721, 35.122733],
						[24.790196, 35.123452],
						[24.790646, 35.124368],
						[24.792033, 35.127894],
						[24.789696, 35.130943],
						[24.789109, 35.131691],
						[24.788318, 35.13845],
						[24.788229, 35.140245],
						[24.788277, 35.14185],
						[24.788643, 35.143515],
						[24.789899, 35.146627],
						[24.790283, 35.147851],
						[24.790322, 35.148139],
						[24.79044, 35.149013],
						[24.790497, 35.151817],
						[24.790755, 35.152374],
						[24.792284, 35.155755],
						[24.792708, 35.157068],
						[24.79287, 35.15805],
						[24.792681, 35.160657],
						[24.791769, 35.16785],
						[24.791674, 35.169545],
						[24.791836, 35.170446],
						[24.792312, 35.171381],
						[24.793403, 35.172726],
						[24.798656, 35.178408],
						[24.800178, 35.180174],
						[24.800566, 35.180821],
						[24.800924, 35.181675],
						[24.801392, 35.183836],
						[24.798889, 35.202121],
						[24.797453, 35.211517],
						[24.796548, 35.216428],
						[24.795524, 35.221214],
						[24.795398, 35.221449],
						[24.794929, 35.22184],
						[24.795993, 35.223663],
						[24.814227, 35.219259],
						[24.813112, 35.217968],
						[24.812747, 35.217339],
						[24.812699, 35.216663],
						[24.812984, 35.215859],
						[24.81341, 35.215189],
						[24.813979, 35.214527],
						[24.816805, 35.211956],
						[24.817807, 35.210786],
						[24.818461, 35.209474],
						[24.819277, 35.20625],
						[24.819846, 35.204902],
						[24.820977, 35.203371],
						[24.824092, 35.200058],
						[24.824722, 35.199225],
						[24.824962, 35.198736],
						[24.825116, 35.198014],
						[24.825242, 35.196381],
						[24.825265, 35.193937],
						[24.825034, 35.192334],
						[24.824249, 35.189607],
						[24.824003, 35.188454],
						[24.823964, 35.187373],
						[24.824101, 35.185199],
						[24.824459, 35.18379],
						[24.82515, 35.182468],
						[24.826418, 35.18099],
						[24.827368, 35.180244],
						[24.829067, 35.179863],
						[24.83358, 35.179805],
						[24.836649, 35.179973],
						[24.839659, 35.18043],
						[24.842851, 35.181129],
						[24.846844, 35.182147],
						[24.848518, 35.18283],
						[24.852723, 35.184974],
						[24.853717, 35.185336],
						[24.854909, 35.185553],
						[24.857012, 35.18562],
						[24.858543, 35.185401],
						[24.859481, 35.184989],
						[24.861049, 35.183842],
						[24.862526, 35.182046],
						[24.86372, 35.180991],
						[24.86857, 35.177711],
						[24.871356, 35.175941],
						[24.873327, 35.174818],
						[24.874097, 35.174488],
						[24.875506, 35.174207],
						[24.877267, 35.174113],
						[24.878911, 35.1743],
						[24.880239, 35.174705],
						[24.883153, 35.17619],
						[24.884724, 35.177188],
						[24.885678, 35.178047],
						[24.887075, 35.179542],
						[24.891252, 35.184615],
						[24.894591, 35.188648],
						[24.895607, 35.189704],
						[24.896932, 35.190903],
						[24.898908, 35.192475],
						[24.901309, 35.194198],
						[24.902291, 35.194776],
						[24.904351, 35.195807],
						[24.903896, 35.196735],
						[24.903767, 35.197269],
						[24.902781, 35.208776],
						[24.902576, 35.211094],
						[24.90285, 35.218125],
						[24.903208, 35.22172],
						[24.903524, 35.223413],
						[24.903853, 35.224646],
						[24.904773, 35.226479],
						[24.905732, 35.227806],
						[24.906743, 35.228619],
						[24.909904, 35.230732],
						[24.910476, 35.231278],
						[24.911562, 35.232532],
						[24.91537, 35.239428],
						[24.916613, 35.241429],
						[24.91766, 35.242909],
						[24.919138, 35.24471],
						[24.920808, 35.246501],
						[24.92146, 35.24701],
						[24.923205, 35.248097],
						[24.92364, 35.248481],
						[24.923901, 35.248831],
						[24.923938, 35.248948],
						[24.924157, 35.24964],
						[24.924191, 35.250623],
						[24.923843, 35.253412],
						[24.923605, 35.254018],
						[24.923044, 35.255888],
						[24.92263, 35.256712],
						[24.921401, 35.258191],
						[24.918426, 35.261234],
						[24.917211, 35.262623],
						[24.916943, 35.263103],
						[24.916637, 35.264268],
						[24.916173, 35.264975],
						[24.915632, 35.265556],
						[24.914941, 35.266139],
						[24.91312, 35.267153],
						[24.912233, 35.267926],
						[24.911849, 35.268479],
						[24.911797, 35.268813],
						[24.911916, 35.26975],
						[24.91242, 35.271595],
						[24.913638, 35.27484],
						[24.914532, 35.276394],
						[24.91642, 35.276596],
						[24.916918, 35.276754],
						[24.917475, 35.277039],
						[24.918315, 35.277645],
						[24.919275, 35.278522],
						[24.919776, 35.279122],
						[24.920123, 35.279778],
						[24.920949, 35.282016],
						[24.921861, 35.285192],
						[24.922307, 35.287885],
						[24.922568, 35.288839],
						[24.923176, 35.290105],
						[24.924922, 35.292968],
						[24.925456, 35.29429],
						[24.925339, 35.294994],
						[24.924645, 35.296081],
						[24.924298, 35.296417],
						[24.923445, 35.29701],
						[24.920372, 35.299405],
						[24.918414, 35.301259],
						[24.91783, 35.301597],
						[24.917289, 35.3017],
						[24.916714, 35.30166],
						[24.916013, 35.301476],
						[24.913174, 35.300334],
						[24.911139, 35.299916],
						[24.90905, 35.299842],
						[24.907054, 35.299893],
						[24.905431, 35.300059],
						[24.904497, 35.300318],
						[24.902694, 35.301215],
						[24.902083, 35.301625],
						[24.901639, 35.302034],
						[24.900997, 35.303058],
						[24.900028, 35.305283],
						[24.899545, 35.306189],
						[24.899126, 35.306715],
						[24.898053, 35.307751],
						[24.895638, 35.309328],
						[24.894365, 35.310032],
						[24.892074, 35.310933],
						[24.889975, 35.311507],
						[24.88848, 35.311725],
						[24.886722, 35.311765],
						[24.883732, 35.311589],
						[24.881954, 35.311187],
						[24.881284, 35.311273],
						[24.880536, 35.311802],
						[24.879847, 35.31269],
						[24.879709, 35.313124],
						[24.879651, 35.314188],
						[24.879435, 35.31456],
						[24.878786, 35.31497],
						[24.878041, 35.315318],
						[24.874626, 35.316542],
						[24.878915, 35.323138],
						[24.88046, 35.322658],
						[24.883263, 35.322142],
						[24.88511, 35.32193],
						[24.886202, 35.321904],
						[24.887523, 35.321984],
						[24.890564, 35.322422],
						[24.891495, 35.322713],
						[24.892669, 35.323227],
						[24.893435, 35.323618],
						[24.893922, 35.323984],
						[24.894579, 35.32489],
						[24.895915, 35.327594],
						[24.896406, 35.328176],
						[24.897189, 35.328702],
						[24.89779, 35.32896],
						[24.898512, 35.329162],
						[24.900882, 35.329577],
						[24.903298, 35.329811],
						[24.904648, 35.32963],
						[24.904274, 35.33103],
						[24.902497, 35.334325],
						[24.900777, 35.337151],
						[24.898582, 35.34017],
						[24.89802, 35.340111],
						[24.897705, 35.340159],
						[24.896123, 35.340793],
						[24.894819, 35.341478],
						[24.894235, 35.34188],
						[24.89364, 35.342425],
						[24.892993, 35.34316],
						[24.892502, 35.343858],
						[24.890453, 35.347958],
						[24.889187, 35.351023],
						[24.889068, 35.351737],
						[24.889074, 35.352918],
						[24.889297, 35.354945],
						[24.88997, 35.360575],
						[24.889998, 35.363235],
						[24.889809, 35.364778],
						[24.889532, 35.365628],
						[24.889154, 35.366235],
						[24.888571, 35.366771],
						[24.886862, 35.368028],
						[24.886457, 35.368445],
						[24.886211, 35.368835],
						[24.885536, 35.370607],
						[24.884698, 35.374382],
						[24.88577, 35.374843],
						[24.888183, 35.376304],
						[24.889426, 35.377188],
						[24.890051, 35.377787],
						[24.890589, 35.379],
						[24.89085, 35.379386],
						[24.891596, 35.380048],
						[24.892569, 35.380663],
						[24.893725, 35.381204],
						[24.89467, 35.381468],
						[24.89666, 35.381642],
						[24.898137, 35.381676],
						[24.899237, 35.381596],
						[24.900168, 35.3814],
						[24.901233, 35.380995],
						[24.902353, 35.380437],
						[24.9037, 35.379615],
						[24.90602, 35.378029],
						[24.9072, 35.376722],
						[24.907835, 35.376221],
						[24.908549, 35.375765],
						[24.909228, 35.375471],
						[24.910602, 35.375236],
						[24.913814, 35.375049],
						[24.915418, 35.374721],
						[24.917102, 35.374032],
						[24.920795, 35.372048],
						[24.921737, 35.371662],
						[24.922494, 35.371449],
						[24.922887, 35.371473],
						[24.923289, 35.371632],
						[24.924453, 35.372489],
						[24.924647, 35.372803],
						[24.924857, 35.373495],
						[24.925636, 35.373724],
						[24.925902, 35.373965],
						[24.926372, 35.375242],
						[24.927223, 35.378364],
						[24.927907, 35.379874],
						[24.928589, 35.380671],
						[24.930558, 35.382423],
						[24.931249, 35.383337],
						[24.931667, 35.38438],
						[24.931888, 35.385902],
						[24.931949, 35.387092],
						[24.931873, 35.388174],
						[24.931647, 35.389132],
						[24.931263, 35.390072],
						[24.930615, 35.391232],
						[24.929716, 35.392582],
						[24.92733, 35.395296],
						[24.926847, 35.39612],
						[24.926608, 35.396826],
						[24.926607, 35.397718],
						[24.926854, 35.399952],
						[24.927238, 35.406875],
						[24.927249, 35.407742],
						[24.927206, 35.408762],
						[24.926874, 35.409364],
						[24.9434, 35.528422],
						[24.934967, 35.526397],
						[24.926632, 35.523846],
						[24.918542, 35.520814],
						[24.915888, 35.519624],
						[24.913836, 35.520048],
						[24.901792, 35.521533],
						[24.890887, 35.522039],
						[24.87997, 35.521761],
						[24.869125, 35.520702],
						[24.858436, 35.518869],
						[24.849897, 35.516812],
						[24.842553, 35.514564],
						[24.839282, 35.514968],
						[24.828377, 35.515474],
						[24.827795, 35.515459],
						[24.823537, 35.516713],
						[24.81173, 35.519153],
						[24.799687, 35.520637],
						[24.788781, 35.521144],
						[24.777864, 35.520866],
						[24.767019, 35.519807],
						[24.756331, 35.517974],
						[24.756269, 35.517959],
						[24.750163, 35.520332],
						[24.738708, 35.523704],
						[24.726901, 35.526144],
						[24.714857, 35.527628],
						[24.703952, 35.528135],
						[24.693035, 35.527857],
						[24.68219, 35.526798],
						[24.671501, 35.524965],
						[24.662962, 35.522908],
						[24.657381, 35.5212],
						[24.652624, 35.521421],
						[24.641707, 35.521143],
						[24.630862, 35.520084],
						[24.620174, 35.518251],
						[24.611634, 35.516194],
						[24.603298, 35.513643],
						[24.595209, 35.51061],
						[24.587409, 35.507113],
						[24.579273, 35.502786],
						[24.571579, 35.497953],
						[24.564374, 35.492643],
						[24.557702, 35.486889],
						[24.552236, 35.481394],
						[24.547364, 35.479567],
						[24.546128, 35.479012],
						[24.541099, 35.478521],
						[24.530411, 35.476687],
						[24.521872, 35.474629],
						[24.513536, 35.472076],
						[24.50803, 35.470011],
						[24.49892, 35.471895],
						[24.486877, 35.47338],
						[24.475971, 35.473887],
						[24.465054, 35.473609],
						[24.458561, 35.472975],
						[24.451774, 35.47329],
						[24.440856, 35.473012],
						[24.430011, 35.471952],
						[24.419323, 35.470118],
						[24.410784, 35.46806],
						[24.40427, 35.466065],
						[24.397872, 35.465902],
						[24.387027, 35.464842],
						[24.379394, 35.463532],
						[24.378175, 35.463589],
						[24.367415, 35.46328],
						[24.366914, 35.463278],
						[24.317506, 35.353104],
						[24.317799, 35.352443],
						[24.317849, 35.351829],
						[24.317727, 35.351478],
						[24.317198, 35.350713],
						[24.317107, 35.350398],
						[24.317305, 35.350036],
						[24.318283, 35.349303],
						[24.318517, 35.348942],
						[24.318464, 35.348528],
						[24.317978, 35.347555],
						[24.317886, 35.347113],
						[24.31839, 35.345913],
						[24.318402, 35.344109],
						[24.318619, 35.343604],
						[24.319321, 35.342457],
						[24.31954, 35.341861],
						[24.319581, 35.341158],
						[24.319442, 35.339337],
						[24.319745, 35.336631],
						[24.319995, 35.335765],
						[24.320434, 35.334754],
						[24.322294, 35.331458],
						[24.322384, 35.328373],
						[24.322519, 35.32703],
						[24.322881, 35.325631],
						[24.323521, 35.324087],
						[24.324248, 35.322751],
						[24.325039, 35.321577],
						[24.325499, 35.321188],
						[24.326687, 35.320463],
						[24.327109, 35.320065],
						[24.327322, 35.319587],
						[24.327379, 35.318261],
						[24.327521, 35.317747],
						[24.327907, 35.317349],
						[24.32918, 35.316696],
						[24.329646, 35.316325],
						[24.330303, 35.315115],
						[24.330799, 35.313798],
						[24.331164, 35.312173],
						[24.331307, 35.310117],
						[24.331279, 35.308052],
						[24.331011, 35.302905],
						[24.331034, 35.301372],
						[24.330958, 35.30056],
						[24.330753, 35.299858],
						[24.330004, 35.298002],
						[24.329867, 35.296975],
						[24.329977, 35.296469],
						[24.330233, 35.295891],
						[24.331843, 35.293353],
						[24.331988, 35.292821],
						[24.331983, 35.29228],
						[24.331803, 35.291568],
						[24.331464, 35.290902],
						[24.32871, 35.287375],
						[24.327562, 35.285836],
						[24.326524, 35.284892],
						[24.32548, 35.284218],
						[24.323999, 35.283645],
						[24.321797, 35.283011],
						[24.319761, 35.282629],
						[24.318675, 35.282595],
						[24.316565, 35.282853],
						[24.315699, 35.282883],
						[24.314735, 35.282723],
						[24.313701, 35.28232],
						[24.31289, 35.281736],
						[24.312208, 35.280827],
						[24.311411, 35.279206],
						[24.311157, 35.277962],
						[24.311041, 35.277331],
						[24.310736, 35.276971],
						[24.3082, 35.275824],
						[24.307277, 35.275547],
						[24.306537, 35.275431],
						[24.305758, 35.27546],
						[24.303825, 35.275682],
						[24.302776, 35.275576],
						[24.302017, 35.27528],
						[24.301112, 35.274805],
						[24.297907, 35.27264],
						[24.296439, 35.27185],
						[24.295416, 35.271546],
						[24.29274, 35.271182],
						[24.291628, 35.27086],
						[24.290845, 35.270421],
						[24.290056, 35.269809],
						[24.287429, 35.267255],
						[24.284308, 35.26591],
						[24.282409, 35.26485],
						[24.278776, 35.262361],
						[24.277576, 35.261426],
						[24.276775, 35.26067],
						[24.276038, 35.259779],
						[24.275119, 35.258456],
						[24.271522, 35.252549],
						[24.270565, 35.250738],
						[24.270196, 35.249197],
					],
				],
			},
			arbitrip_center: {
				type: 'Point',
				coordinates: [24.59979, 35.263573],
			},
		},
		descendants: {
			city: ['6353124', '182119', '6143288', '6130247', '6158240'],
			point_of_interest: [
				'6346069',
				'553248635997384809',
				'553248635997386041',
				'6322327',
				'553248621532502094',
				'553248634304658784',
				'678526279232360448',
				'553248635998055493',
			],
		},
		name: 'Rethymnon',
		name_full: 'Rethymnon, Crete, Greece',
		property_ids: [
			'66120135',
			'32167258',
			'21037184',
			'34032963',
			'19544803',
			'31114078',
			'4564685',
			'66727134',
			'37216402',
			'19171154',
			'1368875',
			'22324938',
			'11236152',
			'37170447',
			'13560974',
			'5067975',
			'12243568',
			'28746071',
			'15566359',
			'45754549',
			'14677426',
			'14876804',
			'11254821',
			'22328072',
			'5026291',
			'2876532',
			'18762027',
			'28749448',
			'21656299',
			'21719837',
			'35355686',
			'62984245',
			'66727140',
			'4482252',
			'2684600',
			'36013034',
			'18916727',
			'22627134',
			'6057315',
			'12608693',
			'45778968',
			'65540139',
			'5075378',
			'42081096',
			'64922284',
			'7750428',
			'11116824',
			'62984112',
			'10266053',
			'66674966',
			'27771959',
			'8285778',
			'2942156',
			'19676373',
			'52419949',
			'11467433',
			'17174470',
			'45959214',
			'24078794',
			'22328177',
			'66177135',
			'31061668',
			'44877985',
			'51815602',
			'66726156',
			'9853608',
			'62984346',
			'2336425',
			'62984347',
			'19669972',
			'62984106',
			'46540459',
			'36332076',
			'60336408',
			'11429069',
			'22330368',
			'1940539',
			'28680693',
			'28758093',
			'2966388',
			'16401431',
			'5622381',
			'53012706',
			'26958521',
			'46320067',
			'22284085',
			'17082229',
			'2336536',
			'13454402',
			'24425738',
			'16553711',
			'22404501',
			'62984338',
			'66188478',
			'22330572',
			'21648002',
			'52655415',
			'2255294',
			'8369951',
			'1092350',
			'18819341',
			'19676312',
			'2099658',
			'48629379',
			'28720962',
			'7742992',
			'61616941',
			'1092349',
			'20187823',
			'62984447',
			'3512277',
			'22137467',
			'53159409',
			'14818938',
			'7830751',
			'1101039',
			'44350952',
			'37960553',
			'19184290',
			'38953797',
			'17827644',
			'3498133',
			'10935872',
			'2335783',
			'44391345',
			'19560466',
			'20514192',
			'2563061',
			'52965872',
			'42407238',
			'16872518',
			'15635868',
			'22585767',
			'66199481',
			'22148565',
			'71145884',
			'31059545',
			'34496157',
			'1399392',
			'44490930',
			'69538579',
			'19360971',
			'53159416',
			'4430964',
			'53159414',
			'33461774',
			'44942681',
			'67626258',
			'3066881',
			'53109650',
			'60280786',
			'32750033',
			'57321330',
			'32750152',
			'27091121',
			'29864058',
			'4542344',
			'3878006',
			'22722946',
			'52653322',
			'46935721',
			'2804770',
			'24378587',
			'42249796',
			'62984090',
			'51935429',
			'1096085',
			'66798963',
			'19897111',
			'3450294',
			'32749590',
			'67388541',
			'2804778',
			'17510445',
			'64924093',
			'36829038',
			'42883279',
			'67388543',
			'17342384',
			'17885188',
			'3547617',
			'66727096',
			'28757114',
			'1104357',
			'19906704',
			'18329379',
			'30992136',
			'48659026',
			'65491390',
			'34889600',
			'3700523',
			'37211109',
			'52419649',
			'24378577',
			'67388538',
			'67388539',
			'32166338',
			'1534344',
			'67388530',
			'21652096',
			'67388531',
			'14689134',
			'22326973',
			'67388654',
			'1853964',
			'4973216',
			'67388540',
			'2875964',
			'39212858',
			'1851789',
			'27109163',
			'31056941',
			'25141249',
			'44566030',
			'4214838',
			'53159449',
			'18801462',
			'51302120',
			'2573709',
			'25003354',
			'14802504',
			'3700519',
			'31075680',
			'10000225',
			'33478162',
			'3775192',
			'27372095',
			'6986865',
			'14877077',
			'41928837',
			'21765678',
			'33217745',
			'12066629',
			'33460642',
			'53159353',
			'53159352',
			'53159351',
			'53159350',
			'67388556',
			'67388557',
			'24400011',
			'20391965',
			'22681625',
			'32749246',
			'50719281',
			'39212878',
			'26445698',
			'23510947',
			'53159349',
			'51815203',
			'53159469',
			'53159369',
			'53159368',
			'2946933',
			'53159367',
			'53159366',
			'53159365',
			'53159364',
			'1099275',
			'53159363',
			'53159362',
			'53159361',
			'67388589',
			'6931400',
			'53159481',
			'21825538',
			'27109109',
			'3584951',
			'2336372',
			'12475411',
			'68892975',
			'67388588',
			'23709749',
			'18809494',
			'32749672',
			'15253843',
			'28600659',
			'72085161',
			'65344355',
			'22331044',
			'53159379',
			'53159378',
			'29306321',
			'53159377',
			'53159376',
			'9613588',
			'53159375',
			'45827498',
			'53159372',
			'21874091',
			'28670936',
			'53159371',
			'53159370',
			'3848359',
			'58024818',
			'6171336',
			'22181628',
			'8011612',
			'23370834',
			'38396443',
			'24205692',
			'1522055',
			'66727064',
			'38434543',
			'8639768',
			'45863990',
			'70605654',
			'26751776',
			'63475411',
			'22722922',
			'38980539',
			'53159383',
			'53159382',
			'28817298',
			'53159381',
			'53159380',
			'41060304',
			'52419574',
			'9783058',
			'9131093',
			'31112621',
			'37816155',
			'15254716',
			'29098813',
			'61498995',
			'70605649',
			'36245256',
			'42627090',
			'22202058',
			'46340623',
			'33460962',
			'28851394',
			'67625204',
			'19353113',
			'35178976',
			'58024837',
			'57161769',
			'58024832',
			'921756',
			'25108275',
			'24433065',
			'12639637',
			'52969371',
			'32038402',
			'35866525',
			'22720921',
			'4332953',
			'28756550',
			'3884257',
			'62983957',
			'12516925',
			'3504280',
			'47000322',
			'2333502',
			'28414608',
			'62983960',
			'62983961',
			'31111115',
			'1439236',
			'17430049',
			'12465368',
			'36768747',
			'44399339',
			'46935553',
			'2538538',
			'12318527',
			'26451625',
			'3657311',
			'4819124',
			'17090314',
			'52420457',
			'53104225',
			'62983944',
			'62983946',
			'4238754',
			'13403645',
			'9406157',
			'4395189',
			'1100705',
			'44466383',
			'62983953',
			'8313432',
			'3688151',
			'47001429',
			'25121496',
			'4546596',
			'44940992',
			'46935789',
			'45826210',
			'45827546',
			'32621794',
			'12658194',
			'67388841',
			'1718974',
			'34080260',
			'26893136',
			'52659133',
			'44938677',
			'16635311',
			'46142137',
			'62983942',
			'33757911',
			'10927777',
			'2476055',
			'12464256',
			'36831066',
			'32598254',
			'32749533',
			'7619618',
			'11017961',
			'1099883',
			'21720285',
			'8334490',
			'11904095',
			'4332962',
			'16207177',
			'57904428',
			'16234876',
			'44832475',
			'62983928',
			'2077174',
			'70409965',
			'15253556',
			'28580059',
			'12240606',
			'37155931',
			'44513448',
			'52969101',
			'63060508',
			'67388628',
			'52110680',
			'21720352',
			'69807201',
			'17329373',
			'32511835',
			'67388744',
			'67388745',
			'49018121',
			'67388746',
			'5356657',
			'5960397',
			'28780334',
			'31112128',
			'12470928',
			'32182046',
			'52794084',
			'28757319',
			'32724773',
			'5864875',
			'12704001',
			'18139882',
			'46530399',
			'35127023',
			'21720466',
			'14887344',
			'2104658',
			'14858960',
			'65345829',
			'28782525',
			'3004624',
			'2893774',
			'34364121',
			'44119391',
			'63208851',
			'37955984',
			'15102138',
			'18139870',
			'4192645',
			'30999011',
			'28711940',
			'13299447',
			'9746501',
			'5094608',
			'18230342',
			'16132572',
			'46306584',
			'12066378',
			'32494793',
			'46065211',
			'28777030',
			'19531020',
			'5094610',
			'15804537',
			'23047940',
			'31726089',
			'44938878',
			'32205481',
			'3096258',
			'44023883',
			'23220654',
			'29086318',
			'16409967',
			'5370707',
			'29840656',
			'67388630',
			'22509494',
			'3948834',
			'31105831',
			'19483911',
			'12886051',
			'12466766',
			'17827067',
			'32749908',
			'33462235',
			'12508906',
			'8421076',
			'15352686',
			'16411831',
			'63548180',
			'53105821',
			'10640181',
			'4511982',
			'5276173',
			'5205779',
			'36802473',
			'34551162',
			'38793173',
			'31709128',
			'36015180',
			'6172182',
			'33311280',
			'58026125',
			'58026124',
			'21057066',
			'7762819',
			'10914767',
			'4925255',
			'28778621',
			'13376917',
			'39342213',
			'4628921',
			'28776202',
			'23412813',
			'33461161',
			'2921169',
			'22723113',
			'3512191',
			'5308400',
			'39298639',
			'13437956',
			'10914851',
			'2806370',
			'70442376',
			'64922442',
			'66953254',
			'14581465',
			'2350263',
			'25056489',
			'18328822',
			'32749810',
			'51816094',
			'52113563',
			'10946317',
			'16583845',
			'33462385',
			'11529603',
			'27108962',
			'56533828',
			'2528557',
			'10911116',
			'66726792',
			'13234555',
			'15277463',
			'27359506',
			'66726790',
			'45942749',
			'68860343',
			'31070774',
			'40036846',
			'46205528',
			'16092488',
			'36297003',
			'12702276',
			'17870251',
			'36332479',
			'36243307',
			'16236401',
			'4014067',
			'21196484',
			'37299878',
			'15061975',
			'31107984',
			'22325028',
			'28922867',
			'1636850',
			'28776598',
			'50708305',
			'66726766',
			'44940524',
			'14581282',
			'3015220',
			'66726764',
			'5147915',
			'3748589',
			'36426467',
			'23408486',
			'33462564',
			'36389216',
			'48555812',
			'3062107',
			'12829258',
			'26699830',
			'15768325',
			'12318691',
			'11105172',
			'55521801',
			'10144433',
			'2519997',
			'15818764',
			'23174407',
			'22798171',
			'36274360',
			'40068737',
			'52113532',
			'36262117',
			'37416565',
			'5377734',
			'38427426',
			'20133954',
			'28781801',
			'36954612',
			'27486545',
			'30082716',
			'28757619',
			'13394397',
			'3444165',
			'28658278',
			'2690220',
			'13370081',
			'10051767',
			'18789104',
			'33462100',
			'30558797',
			'62983966',
			'32594935',
			'44469397',
			'62983968',
			'18730090',
			'45726056',
			'62983969',
			'15244125',
			'62983609',
			'28777433',
			'9371151',
			'9329657',
			'37155174',
			'11543800',
			'50914382',
			'63875210',
			'32222638',
			'62983610',
			'62983611',
			'10109200',
			'62983974',
			'6117174',
			'62983612',
			'33359412',
			'52969147',
			'67443581',
			'12318554',
			'26963495',
			'22723453',
		],
		type: 'province_state',
	},

	/* 3 */
	{
		_id: ObjectId('61b2778dd17acfb0a7fcb1a6'),
		id: '180100',
		coordinates: {
			center_longitude: 24.86915,
			center_latitude: 35.23342,
			bounding_polygon: {
				type: 'MultiPolygon',
				coordinates: [
					[
						[
							[23.864387, 34.957619],
							[23.863063, 34.951067],
							[23.862263, 34.944458],
							[23.863781, 34.920387],
							[23.864622, 34.916254],
							[23.866443, 34.909784],
							[23.868774, 34.903426],
							[23.870245, 34.899907],
							[23.873075, 34.893688],
							[23.876391, 34.887635],
							[23.880181, 34.881773],
							[23.884427, 34.876128],
							[23.889111, 34.870724],
							[23.894214, 34.865583],
							[23.899712, 34.860729],
							[23.900933, 34.859734],
							[23.906752, 34.854574],
							[23.911715, 34.850729],
							[23.912437, 34.850056],
							[23.912533, 34.847632],
							[23.913308, 34.841021],
							[23.914606, 34.834467],
							[23.916421, 34.827996],
							[23.918745, 34.821637],
							[23.921567, 34.815417],
							[23.924877, 34.809363],
							[23.928658, 34.8035],
							[23.932897, 34.797853],
							[23.937573, 34.792447],
							[23.940755, 34.789154],
							[23.942962, 34.787011],
							[23.947355, 34.783076],
							[23.953352, 34.776651],
							[23.957926, 34.772063],
							[23.963414, 34.767206],
							[23.969274, 34.762654],
							[23.97115, 34.761318],
							[23.976021, 34.75767],
							[23.982228, 34.753444],
							[23.987307, 34.750378],
							[23.993957, 34.745827],
							[24.000484, 34.741944],
							[24.003883, 34.740124],
							[24.006347, 34.73872],
							[24.013167, 34.735198],
							[24.02025, 34.73205],
							[24.024653, 34.730333],
							[24.031973, 34.72707],
							[24.036972, 34.725133],
							[24.040359, 34.723877],
							[24.041745, 34.722979],
							[24.048268, 34.719094],
							[24.053083, 34.71655],
							[24.055669, 34.715275],
							[24.062748, 34.712125],
							[24.070062, 34.709363],
							[24.077578, 34.707002],
							[24.085265, 34.705051],
							[24.089368, 34.704171],
							[24.097193, 34.702638],
							[24.105121, 34.701532],
							[24.11312, 34.700855],
							[24.121156, 34.700611],
							[24.124001, 34.70061],
							[24.131537, 34.700801],
							[24.139542, 34.701424],
							[24.147481, 34.702478],
							[24.155321, 34.703957],
							[24.163026, 34.705857],
							[24.170565, 34.708168],
							[24.177906, 34.710881],
							[24.185016, 34.713983],
							[24.188429, 34.715652],
							[24.192451, 34.717757],
							[24.195637, 34.719556],
							[24.201248, 34.722443],
							[24.207809, 34.726285],
							[24.214053, 34.730472],
							[24.219952, 34.734987],
							[24.225482, 34.739811],
							[24.228978, 34.743215],
							[24.23091, 34.74522],
							[24.235631, 34.750598],
							[24.239917, 34.756219],
							[24.243748, 34.76206],
							[24.247109, 34.768094],
							[24.249984, 34.774297],
							[24.252363, 34.780642],
							[24.254233, 34.787102],
							[24.255588, 34.793649],
							[24.256421, 34.800255],
							[24.256729, 34.806892],
							[24.256735, 34.809951],
							[24.256514, 34.816171],
							[24.255768, 34.822784],
							[24.2545, 34.829343],
							[24.252713, 34.835819],
							[24.250417, 34.842185],
							[24.248229, 34.847159],
							[24.248592, 34.854421],
							[24.248595, 34.855714],
							[24.248373, 34.861942],
							[24.247626, 34.868555],
							[24.246356, 34.875114],
							[24.244568, 34.881591],
							[24.242269, 34.887957],
							[24.23947, 34.894186],
							[24.236181, 34.90025],
							[24.228024, 34.912877],
							[24.223801, 34.918535],
							[24.219137, 34.923954],
							[24.216173, 34.927042],
							[24.213758, 34.929403],
							[24.208274, 34.934274],
							[24.202414, 34.938838],
							[24.196204, 34.943077],
							[24.189669, 34.946971],
							[24.182839, 34.950505],
							[24.175741, 34.953664],
							[24.168408, 34.956432],
							[24.163544, 34.958013],
							[24.159886, 34.959539],
							[24.152551, 34.962306],
							[24.145012, 34.964673],
							[24.141781, 34.965555],
							[24.134069, 34.96751],
							[24.126219, 34.969044],
							[24.121234, 34.969738],
							[24.119167, 34.973412],
							[24.115391, 34.979282],
							[24.111156, 34.984936],
							[24.10648, 34.99035],
							[24.101383, 34.995501],
							[24.095887, 35.000366],
							[24.090016, 35.004925],
							[24.083794, 35.009157],
							[24.077248, 35.013045],
							[24.073983, 35.014792],
							[24.071377, 35.016276],
							[24.064535, 35.019803],
							[24.051554, 35.025596],
							[24.035692, 35.031588],
							[24.028143, 35.033946],
							[24.020421, 35.035893],
							[24.012561, 35.03742],
							[24.004597, 35.03852],
							[23.996561, 35.039189],
							[23.98849, 35.039423],
							[23.985828, 35.039424],
							[23.978068, 35.039223],
							[23.970029, 35.038587],
							[23.962057, 35.03752],
							[23.954188, 35.036026],
							[23.946455, 35.034112],
							[23.938891, 35.031785],
							[23.931529, 35.029055],
							[23.9244, 35.025935],
							[23.921666, 35.024605],
							[23.91695, 35.022148],
							[23.910378, 35.018288],
							[23.904129, 35.014083],
							[23.898227, 35.00955],
							[23.8927, 35.004709],
							[23.887569, 34.999581],
							[23.882858, 34.994188],
							[23.878587, 34.988552],
							[23.874773, 34.982699],
							[23.871432, 34.976652],
							[23.86858, 34.970439],
							[23.866229, 34.964085],
							[23.864387, 34.957619],
						],
					],
					[
						[
							[25.464859, 35.562243],
							[25.469886, 35.553797],
							[25.475857, 35.545766],
							[25.482721, 35.538222],
							[25.490418, 35.53123],
							[25.49888, 35.52485],
							[25.508036, 35.519138],
							[25.517804, 35.514143],
							[25.528101, 35.50991],
							[25.538837, 35.506475],
							[25.54992, 35.503868],
							[25.561252, 35.502111],
							[25.572735, 35.501221],
							[25.584271, 35.501203],
							[25.595759, 35.50206],
							[25.607099, 35.503783],
							[25.618192, 35.506358],
							[25.628944, 35.509761],
							[25.63926, 35.513964],
							[25.64905, 35.518929],
							[25.658231, 35.524614],
							[25.666722, 35.530969],
							[25.673829, 35.537329],
							[25.680245, 35.544157],
							[25.685922, 35.551406],
							[25.690819, 35.55902],
							[25.694901, 35.566946],
							[25.698558, 35.576399],
							[25.701056, 35.586098],
							[25.70237, 35.595948],
							[25.702488, 35.605854],
							[25.700887, 35.618488],
							[25.697342, 35.630855],
							[25.691911, 35.642759],
							[25.686694, 35.651205],
							[25.680525, 35.659216],
							[25.673459, 35.666721],
							[25.667194, 35.672322],
							[25.660429, 35.677524],
							[25.653201, 35.682298],
							[25.645552, 35.686617],
							[25.637526, 35.690456],
							[25.626533, 35.694719],
							[25.615078, 35.698084],
							[25.603272, 35.700519],
							[25.591228, 35.702],
							[25.580323, 35.702505],
							[25.569405, 35.702228],
							[25.55856, 35.701171],
							[25.547872, 35.699342],
							[25.539333, 35.69729],
							[25.530997, 35.694744],
							[25.522908, 35.691719],
							[25.515107, 35.688229],
							[25.506971, 35.683912],
							[25.499277, 35.67909],
							[25.492072, 35.673792],
							[25.485401, 35.668051],
							[25.47969, 35.662323],
							[25.474511, 35.656269],
							[25.46989, 35.649924],
							[25.465854, 35.64332],
							[25.462424, 35.636493],
							[25.459618, 35.629479],
							[25.457452, 35.622317],
							[25.455528, 35.612226],
							[25.454872, 35.602026],
							[25.455489, 35.591824],
							[25.457375, 35.581724],
							[25.460509, 35.571829],
							[25.464859, 35.562243],
						],
					],
					[
						[
							[25.558333, 34.886754],
							[25.557786, 34.882935],
							[25.557366, 34.876301],
							[25.557343, 34.8742],
							[25.557347, 34.87156],
							[25.557478, 34.867018],
							[25.558112, 34.860394],
							[25.55927, 34.85382],
							[25.560947, 34.847322],
							[25.563136, 34.840928],
							[25.565827, 34.834666],
							[25.569008, 34.828562],
							[25.572666, 34.822643],
							[25.576785, 34.816934],
							[25.581348, 34.811458],
							[25.585585, 34.806981],
							[25.585879, 34.806688],
							[25.586628, 34.805948],
							[25.592017, 34.80101],
							[25.597784, 34.796373],
							[25.599237, 34.795297],
							[25.600132, 34.794617],
							[25.606254, 34.790301],
							[25.612704, 34.786324],
							[25.619456, 34.782703],
							[25.626479, 34.779455],
							[25.633744, 34.776591],
							[25.64122, 34.774125],
							[25.648876, 34.772068],
							[25.65214, 34.771327],
							[25.655645, 34.770371],
							[25.658724, 34.769532],
							[25.664698, 34.767964],
							[25.672499, 34.766323],
							[25.680413, 34.765105],
							[25.688406, 34.764317],
							[25.696445, 34.763961],
							[25.699011, 34.763939],
							[25.701647, 34.763938],
							[25.704593, 34.763967],
							[25.70494, 34.763961],
							[25.707527, 34.763938],
							[25.709285, 34.763938],
							[25.714748, 34.764038],
							[25.722775, 34.76455],
							[25.730743, 34.765492],
							[25.738621, 34.766863],
							[25.746373, 34.768655],
							[25.749606, 34.769538],
							[25.756872, 34.771639],
							[25.757801, 34.771878],
							[25.763455, 34.773476],
							[25.770402, 34.7751],
							[25.773066, 34.775873],
							[25.77349, 34.775947],
							[25.781244, 34.777736],
							[25.78884, 34.77994],
							[25.796246, 34.782548],
							[25.79643, 34.78262],
							[25.798588, 34.783428],
							[25.805771, 34.78643],
							[25.809762, 34.788316],
							[25.810349, 34.788606],
							[25.813289, 34.790103],
							[25.819938, 34.793852],
							[25.826276, 34.797951],
							[25.832275, 34.802383],
							[25.837912, 34.807129],
							[25.84316, 34.812169],
							[25.847999, 34.817481],
							[25.852406, 34.823043],
							[25.856363, 34.82883],
							[25.859854, 34.834818],
							[25.862863, 34.840981],
							[25.865376, 34.847294],
							[25.867384, 34.853729],
							[25.868877, 34.860257],
							[25.86985, 34.866853],
							[25.870296, 34.873487],
							[25.870328, 34.875657],
							[25.870331, 34.877419],
							[25.870219, 34.881893],
							[25.869611, 34.888519],
							[25.868479, 34.895097],
							[25.866826, 34.9016],
							[25.864661, 34.908],
							[25.8645, 34.908377],
							[25.866949, 34.909237],
							[25.874147, 34.912235],
							[25.875939, 34.913059],
							[25.878869, 34.91396],
							[25.88629, 34.916563],
							[25.886818, 34.916766],
							[25.890304, 34.917622],
							[25.895645, 34.919115],
							[25.900116, 34.919939],
							[25.907887, 34.92172],
							[25.915501, 34.923916],
							[25.921698, 34.926054],
							[25.925021, 34.926679],
							[25.932794, 34.928459],
							[25.936532, 34.929479],
							[25.937161, 34.929572],
							[25.941514, 34.929188],
							[25.943883, 34.929078],
							[25.95009, 34.926616],
							[25.954535, 34.925082],
							[25.95933, 34.923542],
							[25.964547, 34.922077],
							[25.970215, 34.920583],
							[25.977304, 34.919056],
							[25.9816, 34.917683],
							[25.989261, 34.915603],
							[25.99707, 34.913939],
							[26.001446, 34.913253],
							[26.003087, 34.906746],
							[26.005241, 34.900343],
							[26.007508, 34.894993],
							[26.008093, 34.892672],
							[26.010247, 34.886269],
							[26.012903, 34.879996],
							[26.016051, 34.873879],
							[26.019678, 34.867945],
							[26.023766, 34.862219],
							[26.0283, 34.856725],
							[26.033076, 34.851669],
							[26.033372, 34.851376],
							[26.033555, 34.851194],
							[26.038919, 34.846235],
							[26.044664, 34.841575],
							[26.050766, 34.837235],
							[26.057198, 34.833233],
							[26.063933, 34.829585],
							[26.070942, 34.826309],
							[26.078196, 34.823417],
							[26.085663, 34.820922],
							[26.093312, 34.818834],
							[26.101109, 34.817163],
							[26.109022, 34.815915],
							[26.117017, 34.815097],
							[26.125059, 34.814711],
							[26.128195, 34.814678],
							[26.130831, 34.814678],
							[26.135752, 34.814758],
							[26.143787, 34.81524],
							[26.151766, 34.816153],
							[26.159657, 34.817495],
							[26.167425, 34.819258],
							[26.175036, 34.821436],
							[26.182459, 34.82402],
							[26.189662, 34.826998],
							[26.196613, 34.830358],
							[26.203284, 34.834085],
							[26.209646, 34.838163],
							[26.215671, 34.842576],
							[26.221335, 34.847303],
							[26.226146, 34.851856],
							[26.226438, 34.852149],
							[26.226903, 34.85262],
							[26.228129, 34.853954],
							[26.230472, 34.855455],
							[26.232904, 34.857166],
							[26.233403, 34.857505],
							[26.239431, 34.861917],
							[26.245097, 34.866643],
							[26.250376, 34.871665],
							[26.255246, 34.87696],
							[26.259686, 34.882507],
							[26.263678, 34.88828],
							[26.267203, 34.894257],
							[26.270246, 34.90041],
							[26.272795, 34.906714],
							[26.274838, 34.913142],
							[26.275756, 34.916777],
							[26.277254, 34.923187],
							[26.278262, 34.929779],
							[26.278364, 34.931183],
							[26.279116, 34.931397],
							[26.286553, 34.933974],
							[26.29377, 34.936946],
							[26.300737, 34.9403],
							[26.307422, 34.944021],
							[26.313798, 34.948094],
							[26.319838, 34.952502],
							[26.325515, 34.957225],
							[26.330806, 34.962243],
							[26.335688, 34.967536],
							[26.340139, 34.973079],
							[26.344141, 34.978851],
							[26.347676, 34.984825],
							[26.349198, 34.987748],
							[26.34949, 34.988336],
							[26.351022, 34.991564],
							[26.35358, 34.997867],
							[26.355632, 35.004294],
							[26.357169, 35.010817],
							[26.357507, 35.01301],
							[26.360477, 35.015901],
							[26.363369, 35.018946],
							[26.367521, 35.022933],
							[26.372409, 35.028224],
							[26.376866, 35.033767],
							[26.380874, 35.039537],
							[26.384414, 35.04551],
							[26.385965, 35.048486],
							[26.386258, 35.049073],
							[26.387766, 35.052249],
							[26.390329, 35.058551],
							[26.392386, 35.064978],
							[26.392707, 35.066167],
							[26.394123, 35.068675],
							[26.397184, 35.074826],
							[26.398979, 35.079088],
							[26.400338, 35.082596],
							[26.402396, 35.089023],
							[26.403939, 35.095546],
							[26.40496, 35.102139],
							[26.405443, 35.108488],
							[26.409758, 35.112577],
							[26.410932, 35.113751],
							[26.411309, 35.11413],
							[26.416206, 35.11942],
							[26.420671, 35.124961],
							[26.424653, 35.130678],
							[26.42645, 35.133368],
							[26.429998, 35.139341],
							[26.433064, 35.145491],
							[26.435634, 35.151793],
							[26.437697, 35.158219],
							[26.439244, 35.164742],
							[26.440269, 35.171335],
							[26.440766, 35.177968],
							[26.440817, 35.180753],
							[26.440818, 35.181278],
							[26.441168, 35.183946],
							[26.441665, 35.190579],
							[26.441716, 35.193369],
							[26.441723, 35.197477],
							[26.441688, 35.199094],
							[26.442456, 35.199729],
							[26.447682, 35.204653],
							[26.447974, 35.204947],
							[26.448063, 35.205036],
							[26.452967, 35.210324],
							[26.457441, 35.215865],
							[26.461464, 35.221633],
							[26.465019, 35.227605],
							[26.46809, 35.233754],
							[26.470666, 35.240056],
							[26.472734, 35.246482],
							[26.474286, 35.253005],
							[26.475314, 35.259597],
							[26.475815, 35.266231],
							[26.475867, 35.269051],
							[26.475872, 35.271691],
							[26.47579, 35.275517],
							[26.475231, 35.282148],
							[26.474143, 35.288734],
							[26.472533, 35.295248],
							[26.470406, 35.301662],
							[26.467771, 35.307947],
							[26.46464, 35.314078],
							[26.461026, 35.320028],
							[26.456945, 35.325772],
							[26.452413, 35.331284],
							[26.450346, 35.333473],
							[26.428344, 35.358068],
							[26.427743, 35.35917],
							[26.425512, 35.362845],
							[26.42313, 35.366546],
							[26.416961, 35.374586],
							[26.409895, 35.382117],
							[26.40363, 35.387738],
							[26.396865, 35.392958],
							[26.389637, 35.397749],
							[26.381989, 35.402083],
							[26.373962, 35.405937],
							[26.362969, 35.410214],
							[26.351515, 35.413591],
							[26.339708, 35.416035],
							[26.327664, 35.417521],
							[26.316759, 35.418028],
							[26.305842, 35.41775],
							[26.294997, 35.416689],
							[26.294684, 35.416636],
							[26.292603, 35.421209],
							[26.287386, 35.429679],
							[26.281218, 35.437712],
							[26.274151, 35.445238],
							[26.267886, 35.450854],
							[26.261121, 35.456071],
							[26.253894, 35.460858],
							[26.246245, 35.465189],
							[26.238218, 35.469039],
							[26.227226, 35.473313],
							[26.215771, 35.476688],
							[26.203964, 35.479129],
							[26.191921, 35.480614],
							[26.181015, 35.481121],
							[26.170098, 35.480843],
							[26.159253, 35.479783],
							[26.148565, 35.477949],
							[26.140025, 35.475891],
							[26.131689, 35.473339],
							[26.1236, 35.470305],
							[26.1158, 35.466805],
							[26.107664, 35.462476],
							[26.09997, 35.457641],
							[26.092765, 35.452328],
							[26.086093, 35.446572],
							[26.080382, 35.440827],
							[26.075203, 35.434757],
							[26.070583, 35.428394],
							[26.066547, 35.421772],
							[26.063117, 35.414926],
							[26.060311, 35.407893],
							[26.058144, 35.400711],
							[26.05622, 35.390592],
							[26.055598, 35.380898],
							[26.055431, 35.38048],
							[26.052251, 35.376097],
							[26.048216, 35.369471],
							[26.044785, 35.36262],
							[26.041979, 35.355583],
							[26.039813, 35.348396],
							[26.037889, 35.33827],
							[26.037641, 35.334395],
							[26.033641, 35.333708],
							[26.025101, 35.331646],
							[26.025019, 35.331621],
							[26.02491, 35.331618],
							[26.014065, 35.330556],
							[26.003377, 35.328719],
							[25.994837, 35.326657],
							[25.986501, 35.3241],
							[25.978412, 35.32106],
							[25.970612, 35.317554],
							[25.962476, 35.313217],
							[25.954782, 35.308373],
							[25.947577, 35.30305],
							[25.945857, 35.301564],
							[25.945558, 35.301404],
							[25.936916, 35.299918],
							[25.928377, 35.297856],
							[25.920041, 35.295297],
							[25.913959, 35.293011],
							[25.908545, 35.29461],
							[25.896739, 35.297057],
							[25.88776, 35.298166],
							[25.891516, 35.305483],
							[25.895174, 35.314967],
							[25.897671, 35.324697],
							[25.898985, 35.334579],
							[25.899103, 35.344517],
							[25.897502, 35.357192],
							[25.893957, 35.369599],
							[25.888526, 35.381542],
							[25.883309, 35.390016],
							[25.87714, 35.398053],
							[25.870074, 35.405583],
							[25.863809, 35.411202],
							[25.857044, 35.416421],
							[25.849816, 35.42121],
							[25.842168, 35.425543],
							[25.834141, 35.429395],
							[25.823148, 35.433672],
							[25.811694, 35.437048],
							[25.799887, 35.43949],
							[25.787843, 35.440976],
							[25.776938, 35.441483],
							[25.766021, 35.441205],
							[25.755175, 35.440145],
							[25.754681, 35.44006],
							[25.750908, 35.440841],
							[25.738864, 35.442327],
							[25.727959, 35.442833],
							[25.717042, 35.442556],
							[25.713376, 35.442197],
							[25.702954, 35.445268],
							[25.691148, 35.44771],
							[25.679104, 35.449196],
							[25.668199, 35.449703],
							[25.657281, 35.449425],
							[25.646436, 35.448365],
							[25.635748, 35.44653],
							[25.627209, 35.444471],
							[25.618873, 35.441918],
							[25.610784, 35.438883],
							[25.602983, 35.435382],
							[25.600955, 35.434302],
							[25.59414, 35.434619],
							[25.583223, 35.434341],
							[25.572378, 35.433281],
							[25.561689, 35.431446],
							[25.55315, 35.429386],
							[25.544814, 35.426832],
							[25.536725, 35.423797],
							[25.534157, 35.422644],
							[25.52851, 35.421674],
							[25.51997, 35.419615],
							[25.511634, 35.417061],
							[25.503545, 35.414025],
							[25.495745, 35.410523],
							[25.479536, 35.405191],
							[25.475708, 35.408624],
							[25.468943, 35.413843],
							[25.461715, 35.418633],
							[25.454066, 35.422966],
							[25.44604, 35.426819],
							[25.435047, 35.431095],
							[25.423592, 35.434471],
							[25.411786, 35.436914],
							[25.399742, 35.4384],
							[25.388837, 35.438907],
							[25.377919, 35.438629],
							[25.373339, 35.438181],
							[25.374061, 35.443602],
							[25.374179, 35.453527],
							[25.372578, 35.466184],
							[25.369033, 35.478575],
							[25.363602, 35.490502],
							[25.358385, 35.498964],
							[25.352216, 35.506991],
							[25.34515, 35.51451],
							[25.338885, 35.520121],
							[25.33212, 35.525333],
							[25.324892, 35.530116],
							[25.317244, 35.534443],
							[25.309217, 35.53829],
							[25.301233, 35.541392],
							[25.294829, 35.54563],
							[25.28718, 35.549956],
							[25.279153, 35.553802],
							[25.268161, 35.558072],
							[25.256706, 35.561443],
							[25.2449, 35.563882],
							[25.232856, 35.565365],
							[25.221951, 35.565871],
							[25.211033, 35.565594],
							[25.200188, 35.564535],
							[25.1895, 35.562703],
							[25.18096, 35.560647],
							[25.172624, 35.558097],
							[25.164535, 35.555067],
							[25.156735, 35.551571],
							[25.148599, 35.547246],
							[25.140905, 35.542416],
							[25.1337, 35.537109],
							[25.130592, 35.534431],
							[25.125572, 35.53218],
							[25.117436, 35.527854],
							[25.109742, 35.523023],
							[25.102537, 35.517715],
							[25.095865, 35.511963],
							[25.090154, 35.506223],
							[25.089415, 35.505357],
							[25.088066, 35.506121],
							[25.080039, 35.509969],
							[25.069047, 35.514241],
							[25.057592, 35.517614],
							[25.045786, 35.520054],
							[25.033742, 35.521538],
							[25.027268, 35.521839],
							[25.022168, 35.523821],
							[25.010713, 35.527193],
							[24.998907, 35.529633],
							[24.986863, 35.531117],
							[24.975958, 35.531623],
							[24.96504, 35.531346],
							[24.954195, 35.530286],
							[24.946676, 35.529033],
							[24.9434, 35.528422],
							[24.934967, 35.526397],
							[24.926632, 35.523846],
							[24.918542, 35.520814],
							[24.915888, 35.519624],
							[24.913836, 35.520048],
							[24.901792, 35.521533],
							[24.890887, 35.522039],
							[24.87997, 35.521761],
							[24.869125, 35.520702],
							[24.858436, 35.518869],
							[24.849897, 35.516812],
							[24.842553, 35.514564],
							[24.839282, 35.514968],
							[24.828377, 35.515474],
							[24.827795, 35.515459],
							[24.823537, 35.516713],
							[24.81173, 35.519153],
							[24.799687, 35.520637],
							[24.788781, 35.521144],
							[24.777864, 35.520866],
							[24.767019, 35.519807],
							[24.756331, 35.517974],
							[24.756269, 35.517959],
							[24.750163, 35.520332],
							[24.738708, 35.523704],
							[24.726901, 35.526144],
							[24.714857, 35.527628],
							[24.703952, 35.528135],
							[24.693035, 35.527857],
							[24.68219, 35.526798],
							[24.671501, 35.524965],
							[24.662962, 35.522908],
							[24.657381, 35.5212],
							[24.652624, 35.521421],
							[24.641707, 35.521143],
							[24.630862, 35.520084],
							[24.620174, 35.518251],
							[24.611634, 35.516194],
							[24.603298, 35.513643],
							[24.595209, 35.51061],
							[24.587409, 35.507113],
							[24.579273, 35.502786],
							[24.571579, 35.497953],
							[24.564374, 35.492643],
							[24.557702, 35.486889],
							[24.552236, 35.481394],
							[24.547364, 35.479567],
							[24.546128, 35.479012],
							[24.541099, 35.478521],
							[24.530411, 35.476687],
							[24.521872, 35.474629],
							[24.513536, 35.472076],
							[24.50803, 35.470011],
							[24.49892, 35.471895],
							[24.486877, 35.47338],
							[24.475971, 35.473887],
							[24.465054, 35.473609],
							[24.458561, 35.472975],
							[24.451774, 35.47329],
							[24.440856, 35.473012],
							[24.430011, 35.471952],
							[24.419323, 35.470118],
							[24.410784, 35.46806],
							[24.40427, 35.466065],
							[24.397872, 35.465902],
							[24.387027, 35.464842],
							[24.379394, 35.463532],
							[24.378175, 35.463589],
							[24.367415, 35.46328],
							[24.366914, 35.463278],
							[24.367775, 35.469743],
							[24.367893, 35.479665],
							[24.366292, 35.492318],
							[24.362747, 35.504704],
							[24.357316, 35.516627],
							[24.352099, 35.525087],
							[24.345931, 35.533111],
							[24.338864, 35.540627],
							[24.332599, 35.546237],
							[24.326929, 35.550604],
							[24.326143, 35.556809],
							[24.322599, 35.569185],
							[24.317167, 35.581099],
							[24.311951, 35.589552],
							[24.305782, 35.597569],
							[24.300664, 35.603009],
							[24.300615, 35.603391],
							[24.297071, 35.615761],
							[24.29164, 35.627667],
							[24.286423, 35.636115],
							[24.280254, 35.644128],
							[24.273188, 35.651634],
							[24.266923, 35.657236],
							[24.260158, 35.662439],
							[24.25293, 35.667214],
							[24.245281, 35.671533],
							[24.237255, 35.675374],
							[24.226262, 35.679637],
							[24.214807, 35.683003],
							[24.205859, 35.684848],
							[24.205082, 35.68522],
							[24.19409, 35.689483],
							[24.182721, 35.692823],
							[24.175208, 35.695736],
							[24.163754, 35.699101],
							[24.151947, 35.701535],
							[24.139903, 35.703016],
							[24.128998, 35.703522],
							[24.118081, 35.703245],
							[24.107236, 35.702188],
							[24.096547, 35.700359],
							[24.088008, 35.698306],
							[24.079672, 35.695761],
							[24.073643, 35.693506],
							[24.06574, 35.692736],
							[24.055051, 35.690906],
							[24.046512, 35.688854],
							[24.038176, 35.686308],
							[24.030087, 35.683282],
							[24.022287, 35.679792],
							[24.014151, 35.675475],
							[24.006457, 35.670652],
							[24.004177, 35.668976],
							[24.000512, 35.66703],
							[23.992818, 35.662207],
							[23.985613, 35.656908],
							[23.978941, 35.651166],
							[23.97323, 35.645437],
							[23.968051, 35.639382],
							[23.965698, 35.63615],
							[23.957372, 35.637869],
							[23.945328, 35.639351],
							[23.934423, 35.639857],
							[23.923505, 35.639579],
							[23.91266, 35.638522],
							[23.901972, 35.636691],
							[23.893432, 35.634637],
							[23.892099, 35.63423],
							[23.892129, 35.634287],
							[23.895786, 35.643732],
							[23.898284, 35.653422],
							[23.899598, 35.663264],
							[23.899715, 35.673162],
							[23.898114, 35.685785],
							[23.89457, 35.698141],
							[23.889138, 35.710035],
							[23.883921, 35.718475],
							[23.877753, 35.726479],
							[23.870686, 35.733977],
							[23.864421, 35.739573],
							[23.857656, 35.744771],
							[23.850429, 35.749541],
							[23.84278, 35.753856],
							[23.837458, 35.7564],
							[23.835088, 35.758914],
							[23.828824, 35.764508],
							[23.822058, 35.769704],
							[23.814831, 35.774472],
							[23.807182, 35.778786],
							[23.799155, 35.782622],
							[23.788163, 35.786879],
							[23.776708, 35.79024],
							[23.764902, 35.792672],
							[23.752858, 35.794151],
							[23.741953, 35.794656],
							[23.731035, 35.794379],
							[23.72019, 35.793324],
							[23.709502, 35.791497],
							[23.700962, 35.789447],
							[23.692626, 35.786904],
							[23.684537, 35.783882],
							[23.676737, 35.780397],
							[23.668601, 35.776084],
							[23.660907, 35.771268],
							[23.653702, 35.765976],
							[23.647031, 35.760242],
							[23.64132, 35.75452],
							[23.63614, 35.748474],
							[23.63152, 35.742136],
							[23.631137, 35.741509],
							[23.629138, 35.742283],
							[23.617684, 35.745646],
							[23.605877, 35.74808],
							[23.593833, 35.74956],
							[23.582928, 35.750065],
							[23.572011, 35.749788],
							[23.561166, 35.748731],
							[23.550477, 35.746904],
							[23.541938, 35.744852],
							[23.533602, 35.742309],
							[23.525513, 35.739285],
							[23.517713, 35.735797],
							[23.509577, 35.731483],
							[23.501883, 35.726663],
							[23.494678, 35.721369],
							[23.488006, 35.715632],
							[23.4811, 35.710607],
							[23.476709, 35.707715],
							[23.473241, 35.703741],
							[23.468961, 35.698087],
							[23.465144, 35.692217],
							[23.46319, 35.688668],
							[23.451518, 35.687307],
							[23.439834, 35.684977],
							[23.428487, 35.681728],
							[23.417586, 35.677593],
							[23.407236, 35.672609],
							[23.397538, 35.666826],
							[23.388583, 35.660298],
							[23.380422, 35.653053],
							[23.373179, 35.64519],
							[23.366922, 35.636785],
							[23.361714, 35.62792],
							[23.357605, 35.61868],
							[23.354634, 35.609155],
							[23.352138, 35.594946],
							[23.351352, 35.585191],
							[23.351747, 35.575419],
							[23.353317, 35.565725],
							[23.35605, 35.556201],
							[23.359917, 35.54694],
							[23.364882, 35.538032],
							[23.370712, 35.529795],
							[23.377481, 35.522047],
							[23.385126, 35.514858],
							[23.393578, 35.508295],
							[23.40276, 35.502417],
							[23.412588, 35.497278],
							[23.422974, 35.492924],
							[23.433508, 35.489498],
							[23.432793, 35.486947],
							[23.431498, 35.480388],
							[23.430731, 35.473774],
							[23.430493, 35.467328],
							[23.430494, 35.466741],
							[23.430495, 35.466545],
							[23.430507, 35.465456],
							[23.430498, 35.464123],
							[23.430502, 35.462365],
							[23.430502, 35.462147],
							[23.43052, 35.46076],
							[23.430507, 35.459137],
							[23.43051, 35.457375],
							[23.43051, 35.457158],
							[23.430805, 35.450518],
							[23.430959, 35.448889],
							[23.430765, 35.448505],
							[23.427932, 35.442279],
							[23.427595, 35.441443],
							[23.426177, 35.438172],
							[23.425016, 35.435185],
							[23.423548, 35.433143],
							[23.419748, 35.427272],
							[23.416426, 35.421211],
							[23.413595, 35.414985],
							[23.411269, 35.40862],
							[23.409456, 35.402144],
							[23.408164, 35.395586],
							[23.4074, 35.388972],
							[23.407164, 35.382581],
							[23.407168, 35.38054],
							[23.407142, 35.380433],
							[23.405851, 35.373874],
							[23.405087, 35.36726],
							[23.404853, 35.36062],
							[23.405131, 35.354395],
							[23.402543, 35.350392],
							[23.399225, 35.344331],
							[23.396399, 35.338104],
							[23.394075, 35.331739],
							[23.392266, 35.325264],
							[23.390977, 35.318706],
							[23.390215, 35.312092],
							[23.390198, 35.311613],
							[23.390771, 35.294874],
							[23.391105, 35.292205],
							[23.392424, 35.285788],
							[23.392654, 35.282096],
							[23.39348, 35.275488],
							[23.394831, 35.268938],
							[23.3967, 35.262475],
							[23.399081, 35.256125],
							[23.400332, 35.25343],
							[23.401609, 35.247237],
							[23.403478, 35.240773],
							[23.405858, 35.234424],
							[23.408738, 35.228216],
							[23.412106, 35.222176],
							[23.415949, 35.216329],
							[23.420248, 35.210701],
							[23.424986, 35.205315],
							[23.427329, 35.202904],
							[23.429384, 35.200853],
							[23.432197, 35.198145],
							[23.437749, 35.193313],
							[23.443673, 35.188788],
							[23.449945, 35.184591],
							[23.456537, 35.18074],
							[23.463421, 35.17725],
							[23.470567, 35.174137],
							[23.477946, 35.171414],
							[23.485525, 35.169093],
							[23.488203, 35.168433],
							[23.490238, 35.166659],
							[23.496157, 35.162132],
							[23.496185, 35.162112],
							[23.498452, 35.160211],
							[23.50437, 35.155684],
							[23.510636, 35.151484],
							[23.517222, 35.147628],
							[23.5241, 35.144135],
							[23.531241, 35.141018],
							[23.538615, 35.138291],
							[23.540619, 35.13763],
							[23.541251, 35.137415],
							[23.548825, 35.135089],
							[23.550936, 35.134522],
							[23.552049, 35.134209],
							[23.559792, 35.132295],
							[23.56767, 35.130799],
							[23.57565, 35.12973],
							[23.583697, 35.129092],
							[23.591779, 35.128886],
							[23.593748, 35.128902],
							[23.600845, 35.127971],
							[23.608893, 35.127331],
							[23.61678, 35.127124],
							[23.617365, 35.127124],
							[23.617558, 35.127124],
							[23.621012, 35.127221],
							[23.622709, 35.126592],
							[23.63028, 35.124261],
							[23.638019, 35.122341],
							[23.645895, 35.12084],
							[23.653873, 35.119765],
							[23.661919, 35.119121],
							[23.669881, 35.11891],
							[23.672518, 35.11891],
							[23.672636, 35.11891],
							[23.680716, 35.119133],
							[23.688761, 35.119789],
							[23.696736, 35.120875],
							[23.704608, 35.122387],
							[23.712344, 35.124319],
							[23.71991, 35.126661],
							[23.727273, 35.129404],
							[23.729494, 35.13038],
							[23.732662, 35.131521],
							[23.73294, 35.131459],
							[23.740815, 35.129952],
							[23.748792, 35.128871],
							[23.756839, 35.12822],
							[23.76492, 35.128002],
							[23.764936, 35.128002],
							[23.766694, 35.128002],
							[23.77476, 35.128218],
							[23.782806, 35.128867],
							[23.790784, 35.129947],
							[23.79787, 35.131282],
							[23.800092, 35.131504],
							[23.80807, 35.132583],
							[23.815946, 35.134087],
							[23.823686, 35.136011],
							[23.831256, 35.138346],
							[23.832042, 35.138615],
							[23.833721, 35.138234],
							[23.841595, 35.13672],
							[23.849572, 35.135631],
							[23.85639, 35.135045],
							[23.859607, 35.133461],
							[23.866734, 35.130323],
							[23.874094, 35.127574],
							[23.881658, 35.125226],
							[23.889392, 35.123288],
							[23.897263, 35.12177],
							[23.905237, 35.120678],
							[23.913282, 35.120016],
							[23.921362, 35.119787],
							[23.921585, 35.119786],
							[23.929202, 35.119786],
							[23.937061, 35.119991],
							[23.940124, 35.120234],
							[23.945947, 35.116791],
							[23.946269, 35.116614],
							[23.950049, 35.114444],
							[23.95456, 35.112071],
							[23.955035, 35.111807],
							[23.961891, 35.108287],
							[23.969011, 35.105142],
							[23.976366, 35.102386],
							[23.983923, 35.100031],
							[23.989831, 35.098505],
							[23.994977, 35.097253],
							[23.996523, 35.096809],
							[24.004251, 35.094864],
							[24.012117, 35.093338],
							[24.020087, 35.092237],
							[24.028128, 35.091567],
							[24.031253, 35.091423],
							[24.031805, 35.091361],
							[24.039846, 35.09069],
							[24.047671, 35.090453],
							[24.054071, 35.089602],
							[24.062112, 35.088929],
							[24.070188, 35.088689],
							[24.070607, 35.088689],
							[24.074121, 35.088688],
							[24.081781, 35.088883],
							[24.089827, 35.089511],
							[24.097805, 35.090569],
							[24.105683, 35.092053],
							[24.110857, 35.093275],
							[24.111479, 35.093134],
							[24.119343, 35.0916],
							[24.127312, 35.090492],
							[24.135352, 35.089814],
							[24.143428, 35.089569],
							[24.143943, 35.089568],
							[24.144823, 35.089568],
							[24.145693, 35.089571],
							[24.146066, 35.089569],
							[24.146582, 35.089568],
							[24.149218, 35.089568],
							[24.149972, 35.08957],
							[24.150168, 35.089569],
							[24.150696, 35.089568],
							[24.155677, 35.089568],
							[24.163227, 35.089757],
							[24.169883, 35.09024],
							[24.169944, 35.090211],
							[24.177054, 35.087053],
							[24.1844, 35.084284],
							[24.19195, 35.081915],
							[24.199672, 35.079956],
							[24.207533, 35.078416],
							[24.215499, 35.077302],
							[24.223536, 35.076617],
							[24.231611, 35.076366],
							[24.232239, 35.076365],
							[24.233997, 35.076365],
							[24.241447, 35.076549],
							[24.248172, 35.077034],
							[24.250652, 35.076453],
							[24.258511, 35.07491],
							[24.266477, 35.073792],
							[24.274514, 35.073104],
							[24.282588, 35.072849],
							[24.283288, 35.072847],
							[24.285631, 35.072847],
							[24.293008, 35.073028],
							[24.301054, 35.07364],
							[24.309035, 35.074683],
							[24.316915, 35.076153],
							[24.324662, 35.078042],
							[24.332243, 35.080344],
							[24.333097, 35.080632],
							[24.333857, 35.080544],
							[24.33814, 35.080121],
							[24.342619, 35.078481],
							[24.350164, 35.076102],
							[24.357881, 35.074132],
							[24.362725, 35.073124],
							[24.366932, 35.071305],
							[24.374269, 35.068523],
							[24.376316, 35.067831],
							[24.378295, 35.066856],
							[24.385395, 35.063685],
							[24.392731, 35.060902],
							[24.400273, 35.058519],
							[24.406233, 35.056955],
							[24.409098, 35.056249],
							[24.410022, 35.055924],
							[24.412968, 35.054937],
							[24.4202, 35.052659],
							[24.427913, 35.050685],
							[24.435766, 35.049129],
							[24.443727, 35.047999],
							[24.451759, 35.047298],
							[24.452703, 35.047245],
							[24.453762, 35.045896],
							[24.455945, 35.043351],
							[24.453957, 35.039105],
							[24.451554, 35.032762],
							[24.449661, 35.026304],
							[24.448286, 35.019758],
							[24.447434, 35.013152],
							[24.44711, 35.006515],
							[24.447107, 35.005615],
							[24.44711, 35.003852],
							[24.447317, 34.998112],
							[24.448048, 34.991496],
							[24.449303, 34.984934],
							[24.451076, 34.978454],
							[24.453361, 34.972083],
							[24.456146, 34.965848],
							[24.45942, 34.959777],
							[24.463169, 34.953895],
							[24.467377, 34.948227],
							[24.472025, 34.942798],
							[24.475301, 34.939379],
							[24.475595, 34.939086],
							[24.477387, 34.937337],
							[24.482855, 34.932453],
							[24.488698, 34.927874],
							[24.494891, 34.923619],
							[24.501409, 34.919707],
							[24.508222, 34.916153],
							[24.515302, 34.912974],
							[24.52262, 34.910183],
							[24.530142, 34.907791],
							[24.537838, 34.905809],
							[24.545675, 34.904246],
							[24.553619, 34.903107],
							[24.55672, 34.902782],
							[24.560943, 34.902231],
							[24.56896, 34.901522],
							[24.577015, 34.901246],
							[24.578118, 34.901242],
							[24.587492, 34.901242],
							[24.59445, 34.901403],
							[24.602481, 34.901994],
							[24.609294, 34.902868],
							[24.611024, 34.896487],
							[24.613293, 34.890113],
							[24.616062, 34.883875],
							[24.61932, 34.877799],
							[24.623053, 34.871911],
							[24.627244, 34.866238],
							[24.627473, 34.865951],
							[24.628647, 34.864484],
							[24.63305, 34.859334],
							[24.638102, 34.85416],
							[24.643554, 34.849269],
							[24.649381, 34.844681],
							[24.655559, 34.840418],
							[24.662061, 34.836496],
							[24.66886, 34.832933],
							[24.675926, 34.829744],
							[24.68323, 34.826942],
							[24.690739, 34.824539],
							[24.698423, 34.822547],
							[24.706248, 34.820972],
							[24.714181, 34.819822],
							[24.722188, 34.819102],
							[24.730235, 34.818815],
							[24.73154, 34.818809],
							[24.737084, 34.818809],
							[24.743869, 34.818226],
							[24.751916, 34.817937],
							[24.759968, 34.818083],
							[24.763659, 34.818295],
							[24.76467, 34.818228],
							[24.772717, 34.817937],
							[24.774077, 34.817931],
							[24.775833, 34.817931],
							[24.782525, 34.818081],
							[24.790549, 34.818658],
							[24.79851, 34.819666],
							[24.799743, 34.819862],
							[24.807249, 34.820414],
							[24.815211, 34.821421],
							[24.823076, 34.822855],
							[24.830811, 34.824709],
							[24.838382, 34.826976],
							[24.841372, 34.828002],
							[24.846589, 34.829613],
							[24.85001, 34.830792],
							[24.855751, 34.829337],
							[24.863574, 34.827752],
							[24.871505, 34.826591],
							[24.879512, 34.82586],
							[24.885647, 34.825593],
							[24.891114, 34.824531],
							[24.899044, 34.823368],
							[24.90705, 34.822635],
							[24.915097, 34.822335],
							[24.916645, 34.822326],
							[24.920748, 34.822326],
							[24.927254, 34.822468],
							[24.935279, 34.823035],
							[24.937909, 34.823316],
							[24.940973, 34.822993],
							[24.944748, 34.822493],
							[24.952753, 34.821756],
							[24.9608, 34.821453],
							[24.962428, 34.821444],
							[24.963308, 34.821444],
							[24.969733, 34.821583],
							[24.977759, 34.822147],
							[24.985723, 34.823142],
							[24.993592, 34.824564],
							[25.001331, 34.826407],
							[25.006884, 34.82806],
							[25.010899, 34.827907],
							[25.018953, 34.828034],
							[25.026091, 34.828511],
							[25.029314, 34.828804],
							[25.030203, 34.828887],
							[25.038169, 34.829879],
							[25.046039, 34.831297],
							[25.053781, 34.833136],
							[25.056823, 34.833985],
							[25.059516, 34.83452],
							[25.059744, 34.834568],
							[25.060543, 34.834379],
							[25.068362, 34.83278],
							[25.076291, 34.831604],
							[25.084297, 34.830859],
							[25.092344, 34.830546],
							[25.094137, 34.830535],
							[25.095015, 34.830535],
							[25.101277, 34.830667],
							[25.109305, 34.831222],
							[25.117272, 34.832207],
							[25.125144, 34.83362],
							[25.132888, 34.835454],
							[25.14047, 34.837701],
							[25.147858, 34.840352],
							[25.15502, 34.843395],
							[25.156666, 34.844165],
							[25.158378, 34.844871],
							[25.159581, 34.845335],
							[25.163601, 34.846983],
							[25.16416, 34.846882],
							[25.172089, 34.8457],
							[25.180095, 34.844948],
							[25.188144, 34.844628],
							[25.190055, 34.844616],
							[25.193278, 34.844616],
							[25.199423, 34.844743],
							[25.207454, 34.84529],
							[25.215424, 34.846269],
							[25.223299, 34.847675],
							[25.231047, 34.849503],
							[25.231559, 34.84964],
							[25.235019, 34.850312],
							[25.242767, 34.852138],
							[25.245231, 34.852816],
							[25.245991, 34.853015],
							[25.24632, 34.853102],
							[25.248498, 34.853535],
							[25.256247, 34.85536],
							[25.260057, 34.856427],
							[25.266665, 34.857634],
							[25.269439, 34.858235],
							[25.275748, 34.859394],
							[25.283498, 34.861218],
							[25.291087, 34.863455],
							[25.291257, 34.86351],
							[25.292583, 34.863854],
							[25.300172, 34.866091],
							[25.307569, 34.868731],
							[25.31474, 34.871764],
							[25.317303, 34.87297],
							[25.323395, 34.875177],
							[25.330568, 34.878209],
							[25.334119, 34.879897],
							[25.335071, 34.879932],
							[25.343106, 34.880471],
							[25.351082, 34.88144],
							[25.358963, 34.882837],
							[25.366718, 34.884655],
							[25.374312, 34.886887],
							[25.379844, 34.888812],
							[25.380162, 34.888736],
							[25.387981, 34.887114],
							[25.395911, 34.885917],
							[25.403919, 34.885148],
							[25.411972, 34.884813],
							[25.414173, 34.884797],
							[25.417396, 34.884797],
							[25.423256, 34.884911],
							[25.431293, 34.885443],
							[25.439081, 34.886379],
							[25.445237, 34.886747],
							[25.451065, 34.885236],
							[25.458882, 34.883609],
							[25.466811, 34.882406],
							[25.474818, 34.881633],
							[25.481246, 34.881326],
							[25.484098, 34.880944],
							[25.492105, 34.880169],
							[25.500156, 34.879828],
							[25.502464, 34.87981],
							[25.503343, 34.87981],
							[25.509095, 34.87992],
							[25.517132, 34.880446],
							[25.52511, 34.881403],
							[25.532995, 34.882788],
							[25.540163, 34.884441],
							[25.543097, 34.885177],
							[25.547013, 34.886263],
							[25.552454, 34.886371],
							[25.558333, 34.886754],
						],
					],
				],
			},
			arbitrip_center: {
				type: 'Point',
				coordinates: [24.86915, 35.23342],
			},
		},
		descendants: {
			province_state: ['6051661', '6046550', '6046551', '6053601'],
			high_level_region: ['6322142'],
			city: [
				'6353124',
				'553248635975849296',
				'500608',
				'553248635975848913',
				'6276434',
				'6035410',
				'553248635975870553',
				'553248635975862220',
				'6130247',
				'553248635975849332',
				'6206205',
				'1444',
				'601135',
				'553248635975865983',
				'602021',
				'553248635975856760',
				'553248635975859192',
				'6034035',
				'6277485',
				'553248635975864174',
				'6051552',
				'553248635975863656',
				'6143330',
				'1728',
				'553248635975862290',
				'553248635975852318',
				'5193',
				'182094',
				'553248635975870233',
				'553248635975869825',
				'6048775',
				'553248635975866765',
				'6264192',
				'6130238',
				'182119',
				'553248635975871283',
				'553248635975873075',
				'6143288',
				'553248635975868336',
				'553248635975858492',
				'6134966',
				'553248635975868346',
				'6145072',
				'553248635975855289',
				'6222380',
				'6143278',
				'553248635975863470',
				'6158240',
				'553248635975866025',
			],
			point_of_interest: [
				'553248625390612669',
				'553248633988216207',
				'553248621532502076',
				'553248621532502140',
				'553248634304658784',
				'553248621532499130',
				'678526279232360448',
				'6292719',
				'553248635997385310',
				'553248621532502118',
				'553248635997386335',
				'553248635997385819',
				'553248625390613477',
				'6322299',
				'6258880',
				'553248621532502103',
				'6258876',
				'553248633988215404',
				'553248635998122463',
				'553248635997384809',
				'553248625390613458',
				'553248622620208108',
				'553248635997385535',
				'6105774',
				'6278315',
				'6346069',
				'553248621532502083',
				'6278314',
				'553248635997386041',
				'6322327',
				'553248621532502094',
				'553248621533101461',
				'6062113',
				'553248635998055493',
				'553248621564822907',
			],
		},
		name: 'Crete',
		name_full: 'Crete, Greece',
		property_ids: [
			'13417264',
			'17855814',
			'50912736',
			'7567664',
			'19343136',
			'29859758',
			'37732644',
			'3983698',
			'3983694',
			'32822664',
			'50717964',
			'30787296',
			'5026291',
			'32715282',
			'16411421',
			'28749448',
			'62583382',
			'46141878',
			'1850856',
			'33863878',
			'36075984',
			'46245605',
			'46310343',
			'5026281',
			'11021538',
			'19874657',
			'7782017',
			'66466771',
			'22627134',
			'25749963',
			'18536620',
			'7750428',
			'23499860',
			'5205834',
			'20220762',
			'23152468',
			'17460236',
			'10085780',
			'5026287',
			'18328547',
			'22644516',
			'34385541',
			'2876434',
			'10623200',
			'16423422',
			'1848566',
			'9746030',
			'22722507',
			'61398961',
			'9368168',
			'44467811',
			'10912417',
			'2335564',
			'1400410',
			'24157161',
			'53869789',
			'48629379',
			'7564580',
			'7899132',
			'3478779',
			'18332624',
			'34914912',
			'17867820',
			'45819232',
			'46972379',
			'61616941',
			'1101039',
			'68601532',
			'16401215',
			'20243447',
			'18332610',
			'27165994',
			'32869308',
			'541819',
			'68039543',
			'12441905',
			'8147738',
			'4022035',
			'16872518',
			'36818726',
			'22148565',
			'32503684',
			'71145884',
			'1971182',
			'18174830',
			'3835782',
			'4896566',
			'66419558',
			'5302160',
			'1056753',
			'60280786',
			'32401615',
			'13216306',
			'6852632',
			'16422139',
			'12461514',
			'55435056',
			'27091121',
			'6760837',
			'3878006',
			'2804770',
			'24378587',
			'58013974',
			'2804779',
			'67388541',
			'2804778',
			'23388971',
			'67388543',
			'2804776',
			'15193659',
			'18971158',
			'69766971',
			'13385195',
			'66727096',
			'3124261',
			'19906704',
			'24378593',
			'25814637',
			'67114611',
			'24378577',
			'67388538',
			'13082188',
			'67388539',
			'67388534',
			'2804785',
			'20939307',
			'48661462',
			'67388530',
			'67388531',
			'67388533',
			'1853964',
			'4973216',
			'14689246',
			'67388540',
			'2875964',
			'5662598',
			'7631936',
			'1106751',
			'24401474',
			'2804757',
			'4050940',
			'67388570',
			'67388571',
			'9707250',
			'67388573',
			'13368625',
			'5009780',
			'1106756',
			'1106757',
			'5009797',
			'33460642',
			'67388556',
			'67388557',
			'67388559',
			'2804766',
			'2804765',
			'67388560',
			'67388561',
			'2804769',
			'21300353',
			'36985805',
			'39470732',
			'24996935',
			'1106745',
			'43287163',
			'6113041',
			'67388589',
			'2266456',
			'15193619',
			'50717937',
			'26820384',
			'67388586',
			'67388587',
			'67388588',
			'15253843',
			'11835583',
			'15067950',
			'33007520',
			'37811909',
			'12638894',
			'64899298',
			'8057727',
			'15226182',
			'67388574',
			'27166418',
			'38396443',
			'1522055',
			'66727064',
			'53117685',
			'44396994',
			'7996235',
			'66535833',
			'28817298',
			'52419574',
			'4673424',
			'9783058',
			'54218263',
			'31112621',
			'21651821',
			'44233125',
			'15755956',
			'41956352',
			'18087870',
			'3454763',
			'36119213',
			'15635245',
			'12729256',
			'20377003',
			'32807016',
			'42106499',
			'24433065',
			'16425246',
			'34362006',
			'29323370',
			'36964840',
			'6924872',
			'11824088',
			'33456510',
			'13083542',
			'5276211',
			'17934280',
			'4114719',
			'32806630',
			'36768747',
			'15926229',
			'12318527',
			'52420457',
			'4049082',
			'44468322',
			'20248314',
			'44469647',
			'4053101',
			'33673525',
			'32872153',
			'19477661',
			'2539500',
			'10493521',
			'38388739',
			'67388608',
			'3746152',
			'67388606',
			'32621794',
			'9533177',
			'1982768',
			'5493156',
			'3935956',
			'3760425',
			'13343361',
			'46141059',
			'12458709',
			'16316921',
			'44513448',
			'2535178',
			'36066165',
			'67388628',
			'45016770',
			'67388629',
			'67388623',
			'10650071',
			'28780334',
			'19192571',
			'36767456',
			'48662745',
			'46530399',
			'7756599',
			'33894901',
			'31727386',
			'4453230',
			'31995120',
			'44467032',
			'2893774',
			'4453234',
			'44119391',
			'46142366',
			'63208851',
			'39607164',
			'53840932',
			'15389995',
			'15103362',
			'57949685',
			'16132572',
			'12066378',
			'15077223',
			'33535121',
			'9086194',
			'63500023',
			'59018659',
			'45299664',
			'31726089',
			'44938878',
			'32205481',
			'48623157',
			'7649261',
			'34552229',
			'1506093',
			'15539338',
			'4530930',
			'41960315',
			'29305536',
			'16409967',
			'67388630',
			'21756883',
			'13425685',
			'17562310',
			'40210345',
			'14866950',
			'520936',
			'8421076',
			'10425457',
			'15352686',
			'9982997',
			'54039521',
			'5205779',
			'36802473',
			'35742306',
			'12706503',
			'4144519',
			'6172182',
			'15227519',
			'71656999',
			'2564520',
			'32811138',
			'28776202',
			'29161106',
			'39298639',
			'30441281',
			'8402775',
			'10177612',
			'38762332',
			'71009056',
			'31827735',
			'2350263',
			'57856655',
			'699330',
			'4850393',
			'21627365',
			'10093322',
			'27475989',
			'52113563',
			'60611378',
			'10178950',
			'33462385',
			'5376458',
			'10911116',
			'35117520',
			'64897413',
			'70949858',
			'35577304',
			'1450665',
			'21465147',
			'45942749',
			'68860343',
			'5026313',
			'6964904',
			'46205528',
			'48660915',
			'45287628',
			'5905700',
			'21196484',
			'23591327',
			'32696405',
			'36300340',
			'1636850',
			'24893582',
			'15233391',
			'32115564',
			'517311',
			'60378630',
			'44122160',
			'26699830',
			'42602465',
			'15768325',
			'4111632',
			'9294974',
			'12318571',
			'44764680',
			'4316156',
			'2519997',
			'12748335',
			'2855413',
			'45988928',
			'52113532',
			'36432912',
			'4613754',
			'5384024',
			'5384025',
			'24036867',
			'5377734',
			'44335999',
			'36954612',
			'53594063',
			'30387904',
			'44882614',
			'34362811',
			'13370081',
			'20049987',
			'48307261',
			'19267776',
			'23655827',
			'19941148',
			'9247775',
			'33359412',
			'1677395',
			'12318554',
			'15386861',
			'66333',
			'1399014',
			'18329782',
			'37420000',
			'19544803',
			'51935148',
			'27482444',
			'66727134',
			'8334824',
			'25034154',
			'66594981',
			'70928990',
			'11579168',
			'10624427',
			'37640776',
			'20611874',
			'688621',
			'69331420',
			'29859888',
			'33008082',
			'6047768',
			'35575742',
			'66727140',
			'31998154',
			'8720573',
			'14868078',
			'48455046',
			'46245724',
			'20093336',
			'49815126',
			'45778968',
			'5075378',
			'1092348',
			'10910778',
			'1092343',
			'1092344',
			'32184848',
			'66674966',
			'9761783',
			'69906825',
			'66727114',
			'18063135',
			'1342145',
			'65265027',
			'16505707',
			'23396425',
			'44541331',
			'53161964',
			'19397082',
			'53012706',
			'53326177',
			'64443695',
			'36676291',
			'1092366',
			'14689341',
			'17082229',
			'32811224',
			'41967746',
			'28992853',
			'46310256',
			'40065875',
			'1092350',
			'68699927',
			'34856665',
			'5857521',
			'1092349',
			'3512277',
			'48526124',
			'39178673',
			'9804545',
			'38796418',
			'66466632',
			'68064610',
			'15670397',
			'18914625',
			'8421770',
			'36177240',
			'44391345',
			'25693798',
			'15635990',
			'52965872',
			'64629661',
			'68059241',
			'44490930',
			'20060483',
			'37135855',
			'8362069',
			'2318092',
			'4569805',
			'6992835',
			'15036048',
			'42731949',
			'64608752',
			'3512297',
			'6047787',
			'8767205',
			'16422053',
			'10512421',
			'42249796',
			'62984090',
			'3450294',
			'33074436',
			'44467734',
			'4352716',
			'32002051',
			'18948026',
			'27332737',
			'20843078',
			'67388670',
			'67388671',
			'67388672',
			'12500735',
			'29363544',
			'8078501',
			'12242638',
			'35058009',
			'26723277',
			'13271042',
			'52419649',
			'34924233',
			'1590041',
			'31994626',
			'22326973',
			'31281586',
			'67388654',
			'30030468',
			'5329926',
			'50902660',
			'4214838',
			'61526868',
			'64199172',
			'28946143',
			'1186786',
			'16066306',
			'660214',
			'19749572',
			'40353578',
			'46305728',
			'13097325',
			'6178267',
			'32615713',
			'15231431',
			'62984077',
			'39036596',
			'24400011',
			'49765166',
			'53012754',
			'1336311',
			'13185602',
			'48662890',
			'55216160',
			'16337727',
			'24941744',
			'13216227',
			'2946933',
			'39201778',
			'17183833',
			'45268307',
			'12475411',
			'1336325',
			'65344355',
			'45142542',
			'5906106',
			'31617903',
			'9597051',
			'12418875',
			'21874091',
			'7565158',
			'23120188',
			'15451739',
			'16296893',
			'10011566',
			'35632669',
			'8639768',
			'12706498',
			'3897921',
			'6631908',
			'37912799',
			'11000518',
			'15095291',
			'11476719',
			'28863028',
			'9131093',
			'62984036',
			'15086333',
			'28890617',
			'52968985',
			'32091093',
			'3935913',
			'16650712',
			'2621700',
			'5870584',
			'23295793',
			'9597031',
			'4514869',
			'63062086',
			'20133027',
			'58100183',
			'10928016',
			'37186697',
			'28991599',
			'36704885',
			'4984874',
			'37253035',
			'52969371',
			'21632893',
			'49758945',
			'28756550',
			'33684311',
			'21321343',
			'24064834',
			'37271706',
			'13299479',
			'33310915',
			'12318649',
			'13216299',
			'4514846',
			'24458291',
			'61324720',
			'4238754',
			'9406157',
			'27296613',
			'44469534',
			'13394722',
			'45791029',
			'41888489',
			'12318657',
			'47001429',
			'12318653',
			'15088067',
			'56967576',
			'2186713',
			'2835777',
			'13083689',
			'48623240',
			'45826210',
			'45827546',
			'1226726',
			'32945616',
			'66801481',
			'5387062',
			'52659133',
			'13299494',
			'36147480',
			'46142492',
			'2860785',
			'1099883',
			'45729924',
			'23391539',
			'64689292',
			'44832475',
			'530206',
			'3521996',
			'31997600',
			'12318633',
			'4817096',
			'12946363',
			'1744090',
			'41545360',
			'12318618',
			'36935364',
			'10937035',
			'19933453',
			'44437968',
			'69807201',
			'18948065',
			'17329373',
			'67388744',
			'67388745',
			'67388746',
			'3930163',
			'8762321',
			'1119002',
			'11645499',
			'22316728',
			'35127023',
			'42703838',
			'68126590',
			'3472551',
			'22797484',
			'66241047',
			'8120845',
			'25167178',
			'48950823',
			'4478637',
			'46141155',
			'42005805',
			'30999011',
			'40232453',
			'13299447',
			'68080758',
			'5460104',
			'12845777',
			'22724153',
			'46142458',
			'27729349',
			'66870018',
			'7397',
			'68891377',
			'24378596',
			'11724660',
			'13299459',
			'13446484',
			'66461845',
			'8313136',
			'16621471',
			'53826502',
			'56249926',
			'5002490',
			'17827067',
			'33462235',
			'71675383',
			'15005718',
			'45865314',
			'63548180',
			'44292486',
			'53105821',
			'5276173',
			'2908735',
			'14847087',
			'53777902',
			'21311921',
			'35630956',
			'12483871',
			'21539707',
			'37199016',
			'13401507',
			'2876256',
			'31078043',
			'11869718',
			'13376917',
			'2908725',
			'14613861',
			'3512198',
			'16326264',
			'49790161',
			'19256314',
			'69646958',
			'27269508',
			'41183895',
			'3512191',
			'2806370',
			'36367375',
			'5127063',
			'13141135',
			'8077194',
			'5256596',
			'11967284',
			'18327976',
			'5377662',
			'11529603',
			'15186473',
			'15223003',
			'25024726',
			'18559463',
			'52657361',
			'13481654',
			'13480322',
			'50707623',
			'48446041',
			'1450784',
			'47000954',
			'36297003',
			'28849568',
			'37419852',
			'17557696',
			'17732293',
			'22680903',
			'25788592',
			'21382244',
			'48529243',
			'15397843',
			'3062107',
			'55321738',
			'9703567',
			'19654700',
			'12318691',
			'21607008',
			'27484152',
			'12815096',
			'36274360',
			'26886926',
			'15224308',
			'34858359',
			'17804360',
			'4770393',
			'4791297',
			'26695584',
			'24314586',
			'24384403',
			'22549989',
			'15362783',
			'35511660',
			'1398599',
			'54217978',
			'2690220',
			'19654725',
			'18730090',
			'45726056',
			'20060419',
			'15386743',
			'28777433',
			'50707669',
			'22396872',
			'32987437',
			'35556545',
			'11292458',
			'8342322',
			'14689413',
			'37216402',
			'1252683',
			'24913595',
			'18944182',
			'57126173',
			'16485902',
			'11700830',
			'13560974',
			'15566359',
			'14677426',
			'62984119',
			'47030559',
			'37647298',
			'66097131',
			'32810441',
			'70124488',
			'2684600',
			'2459161',
			'24429169',
			'62984112',
			'46310115',
			'66091862',
			'18021892',
			'60378494',
			'36716541',
			'13093938',
			'44877985',
			'12756259',
			'24386334',
			'51815602',
			'56947006',
			'6266827',
			'62984104',
			'62984106',
			'46540459',
			'36332076',
			'15537804',
			'16333648',
			'8022438',
			'28680693',
			'16401431',
			'25067166',
			'13270040',
			'22295377',
			'26958521',
			'16192341',
			'20041839',
			'66097191',
			'64982451',
			'13512455',
			'13454402',
			'16433031',
			'23047489',
			'49197106',
			'55204952',
			'55212593',
			'21376649',
			'21648002',
			'33753193',
			'18819341',
			'4966333',
			'38986572',
			'24092705',
			'44350952',
			'37960553',
			'19184290',
			'6254815',
			'4666883',
			'30953736',
			'15060266',
			'20514192',
			'2684611',
			'44942681',
			'19654323',
			'27447379',
			'61603428',
			'57321330',
			'11429386',
			'39178532',
			'71752984',
			'26776771',
			'18329687',
			'19920860',
			'2533663',
			'39291332',
			'33007717',
			'48661215',
			'51935429',
			'19897111',
			'7998235',
			'2917211',
			'19377555',
			'44466764',
			'16333698',
			'19747493',
			'67428533',
			'1607373',
			'1104357',
			'34889600',
			'42655599',
			'69364096',
			'21652096',
			'19566555',
			'36910008',
			'35832577',
			'22657646',
			'44566151',
			'38166775',
			'25812682',
			'2573709',
			'62984190',
			'36079260',
			'35781373',
			'33478162',
			'66159585',
			'31565821',
			'7830827',
			'27372095',
			'6986865',
			'14877077',
			'41928837',
			'21765678',
			'54218069',
			'48417079',
			'64544493',
			'4255615',
			'13269081',
			'20391965',
			'50913413',
			'16636119',
			'33942903',
			'23510947',
			'10065563',
			'44272966',
			'55086383',
			'66449137',
			'3234951',
			'55207159',
			'1099275',
			'37213335',
			'44468510',
			'44873565',
			'32749672',
			'62984178',
			'31727670',
			'35203862',
			'32835711',
			'35075909',
			'4255619',
			'3501895',
			'46935638',
			'64431750',
			'36830212',
			'23602770',
			'42007712',
			'45827498',
			'2340749',
			'10910888',
			'38443002',
			'46990421',
			'27361396',
			'16412159',
			'48741096',
			'19654390',
			'25756099',
			'11871435',
			'44566196',
			'4620609',
			'37910255',
			'921755',
			'921754',
			'6163960',
			'921752',
			'13460232',
			'5206048',
			'65264881',
			'2604496',
			'37816155',
			'2334059',
			'6172881',
			'31831124',
			'34297918',
			'19653078',
			'45841759',
			'5126320',
			'12835101',
			'18108299',
			'9727057',
			'48662579',
			'48673225',
			'921756',
			'25108275',
			'6162615',
			'39413577',
			'12420348',
			'23546156',
			'11598132',
			'49134379',
			'70523720',
			'48283851',
			'2333502',
			'31111115',
			'57973521',
			'1439236',
			'46972507',
			'38913683',
			'16428442',
			'44399339',
			'46935553',
			'3657311',
			'67388815',
			'13403645',
			'36061906',
			'10135010',
			'45359985',
			'1100705',
			'36797096',
			'4353782',
			'46096048',
			'44940992',
			'3769475',
			'48660740',
			'1855897',
			'67388841',
			'34080260',
			'27307955',
			'44938677',
			'46142137',
			'16099940',
			'10927777',
			'32598254',
			'39463815',
			'2535367',
			'10163919',
			'67388837',
			'44407482',
			'9835402',
			'27810612',
			'15253556',
			'31997960',
			'46143478',
			'33007770',
			'49810356',
			'5194359',
			'14801831',
			'22199569',
			'49018121',
			'5356657',
			'67388860',
			'22237404',
			'45784662',
			'48303988',
			'12318729',
			'50724979',
			'66383772',
			'44112518',
			'13436551',
			'37980951',
			'61475953',
			'68355747',
			'38235051',
			'10655512',
			'6446961',
			'37955984',
			'17112986',
			'24233616',
			'46143452',
			'22632367',
			'67302590',
			'12500288',
			'16611792',
			'41974732',
			'9353334',
			'3874319',
			'32807785',
			'13375910',
			'3096257',
			'3096258',
			'44433031',
			'19361139',
			'39458411',
			'10623916',
			'37649809',
			'61629237',
			'12318705',
			'36014732',
			'31876074',
			'11677174',
			'16588014',
			'39289987',
			'30373575',
			'8182815',
			'29860015',
			'6179440',
			'43269135',
			'13137138',
			'16728756',
			'8401224',
			'24432872',
			'45359912',
			'31409503',
			'36015180',
			'36839525',
			'10623973',
			'31783729',
			'4436527',
			'44106714',
			'17765285',
			'15385357',
			'28778621',
			'9024544',
			'23412813',
			'48889247',
			'35665922',
			'1103813',
			'32896746',
			'32692274',
			'12461705',
			'23149460',
			'48530762',
			'25056489',
			'1103807',
			'66726788',
			'48630061',
			'66726789',
			'15386673',
			'9435679',
			'52969078',
			'66726787',
			'2810766',
			'37801140',
			'12307234',
			'34858421',
			'66726791',
			'66726792',
			'17699540',
			'66726793',
			'66726794',
			'36137339',
			'66726790',
			'13524481',
			'66398807',
			'16092488',
			'36243307',
			'16236401',
			'36416250',
			'3738316',
			'4026578',
			'31107984',
			'62983642',
			'4026575',
			'66726766',
			'66726767',
			'12654202',
			'22723568',
			'15574721',
			'1103867',
			'66726764',
			'3748589',
			'36426467',
			'35732317',
			'33462564',
			'8195279',
			'12309877',
			'1613994',
			'13141201',
			'26674791',
			'66726771',
			'4026585',
			'2842397',
			'18329621',
			'23174407',
			'42615527',
			'2312612',
			'12443042',
			'30313000',
			'9259502',
			'35002942',
			'43405423',
			'12316140',
			'12756188',
			'3761554',
			'18419597',
			'10051767',
			'7782684',
			'5222065',
			'5222066',
			'50110029',
			'62983607',
			'574550',
			'3866798',
			'62983608',
			'62983609',
			'9371151',
			'9329657',
			'12756179',
			'11543800',
			'5466150',
			'32222638',
			'62983610',
			'62983611',
			'6117174',
			'62983612',
			'46310205',
			'1677153',
			'57937879',
			'9049888',
			'4724319',
			'32167258',
			'6162530',
			'37201279',
			'36121862',
			'2774518',
			'1093893',
			'37214585',
			'41952444',
			'39419562',
			'12756165',
			'1368513',
			'22324938',
			'27918884',
			'11236152',
			'37170447',
			'1457221',
			'1368515',
			'1368514',
			'28746071',
			'44266347',
			'31529229',
			'19654465',
			'11254821',
			'2876532',
			'48660098',
			'21719837',
			'62984245',
			'583319',
			'26593767',
			'34191896',
			'35550663',
			'15889424',
			'66422879',
			'30559456',
			'60378364',
			'3584970',
			'38391227',
			'36116491',
			'10633113',
			'10359446',
			'21410987',
			'24722947',
			'38999103',
			'22748806',
			'32821232',
			'3584963',
			'15246349',
			'53809780',
			'16411567',
			'8717185',
			'3992274',
			'1355224',
			'46141735',
			'15191543',
			'17460128',
			'62558269',
			'12756131',
			'57750728',
			'49140169',
			'28758093',
			'2966388',
			'32851914',
			'66443754',
			'18330573',
			'36177467',
			'2336536',
			'8055267',
			'41884633',
			'15200997',
			'5628921',
			'58009720',
			'3075259',
			'22278741',
			'2099658',
			'31994825',
			'36901855',
			'2221263',
			'15383532',
			'46398066',
			'15165254',
			'7830751',
			'38953797',
			'41956865',
			'36348338',
			'61632073',
			'3644086',
			'35770517',
			'31483322',
			'48663137',
			'12693758',
			'34496157',
			'11069668',
			'35557246',
			'16426606',
			'67626258',
			'5997859',
			'20485231',
			'47001286',
			'15060370',
			'41967517',
			'16423583',
			'33716934',
			'37122764',
			'1096084',
			'1096085',
			'12478726',
			'32749590',
			'3566253',
			'17885188',
			'15354193',
			'2926284',
			'1096083',
			'48659026',
			'10508153',
			'3860435',
			'904250',
			'31056941',
			'44440009',
			'44566030',
			'18063776',
			'10630918',
			'16508136',
			'61638257',
			'10000225',
			'11456823',
			'20118922',
			'9789914',
			'4184683',
			'36296844',
			'36907182',
			'11941754',
			'21337972',
			'4129812',
			'57128654',
			'12367799',
			'55357567',
			'19653168',
			'49200969',
			'9470135',
			'44433763',
			'54071735',
			'44762701',
			'69331235',
			'3584951',
			'44464025',
			'28600659',
			'8356018',
			'23039114',
			'34831525',
			'52049977',
			'4460395',
			'9492397',
			'61638208',
			'67543461',
			'21756818',
			'21976720',
			'9169477',
			'8011612',
			'39808767',
			'24205692',
			'3007182',
			'38980539',
			'19897054',
			'27531961',
			'17377588',
			'44439095',
			'46141765',
			'38001247',
			'1485272',
			'36245256',
			'19564661',
			'5198901',
			'16773491',
			'19276921',
			'11598043',
			'2336581',
			'16413386',
			'36038659',
			'3677803',
			'49105798',
			'35866525',
			'39300895',
			'4332953',
			'14707978',
			'17814823',
			'45359880',
			'20030450',
			'32823081',
			'8763857',
			'64624995',
			'3842629',
			'5429929',
			'12465368',
			'16336689',
			'23540960',
			'2538538',
			'15539650',
			'520666',
			'32165527',
			'23048927',
			'6118362',
			'28861857',
			'66423384',
			'12445741',
			'51934958',
			'40208529',
			'1188687',
			'35193916',
			'19487105',
			'2705193',
			'4401952',
			'37291522',
			'24462590',
			'64014677',
			'30991342',
			'33316470',
			'2148201',
			'32749533',
			'46142250',
			'54728341',
			'4332962',
			'16207177',
			'10639349',
			'63942854',
			'520643',
			'13231201',
			'7762081',
			'32807851',
			'60798572',
			'12240606',
			'48663900',
			'66093476',
			'4110481',
			'37190863',
			'52969101',
			'3024458',
			'14699829',
			'72370',
			'27573824',
			'17459983',
			'44765023',
			'32182046',
			'5864875',
			'15539678',
			'35478891',
			'12704001',
			'36384041',
			'14887344',
			'2104658',
			'65345829',
			'15916767',
			'20839176',
			'32806540',
			'18823253',
			'18230342',
			'36802973',
			'3024473',
			'15804537',
			'50698038',
			'10251301',
			'44023883',
			'35098633',
			'29086318',
			'22165208',
			'31411796',
			'55024110',
			'29860131',
			'12886051',
			'16428350',
			'9853345',
			'12508906',
			'3239809',
			'1259477',
			'5344509',
			'16388732',
			'29828856',
			'15257846',
			'13561542',
			'25001403',
			'10623857',
			'8092135',
			'9259412',
			'21057066',
			'4525241',
			'45989700',
			'44983179',
			'22451087',
			'66457567',
			'12479133',
			'33461161',
			'37419657',
			'4260878',
			'23547540',
			'691965',
			'2246590',
			'16873527',
			'32696500',
			'11456036',
			'2893827',
			'51816094',
			'9255078',
			'23501315',
			'691955',
			'46306033',
			'1191451',
			'15226752',
			'15277463',
			'9304451',
			'19087389',
			'12702276',
			'21642899',
			'9854636',
			'55563678',
			'16469738',
			'29455842',
			'11645654',
			'39217557',
			'22409595',
			'26724727',
			'27484386',
			'48659865',
			'36086220',
			'520623',
			'11904707',
			'9081005',
			'15277485',
			'55521801',
			'17710248',
			'20166501',
			'22798171',
			'27416282',
			'40068737',
			'22202601',
			'35106711',
			'12768046',
			'37416565',
			'62584442',
			'36300475',
			'11513073',
			'30082716',
			'44391507',
			'37156154',
			'28757619',
			'18119850',
			'19443532',
			'3281377',
			'10074504',
			'16400877',
			'13420025',
			'46938564',
			'48672816',
			'52969147',
			'48658520',
			'25149588',
			'22723453',
			'3738194',
			'57118783',
			'70092543',
			'564919',
			'29078225',
			'48949233',
			'24124666',
			'66726164',
			'66726165',
			'4564685',
			'66726166',
			'46142713',
			'4564683',
			'19171154',
			'4482268',
			'10108540',
			'58101750',
			'25013571',
			'30676780',
			'23002375',
			'36797767',
			'14876804',
			'3992179',
			'61629813',
			'2829665',
			'29480680',
			'3883854',
			'8217743',
			'21656299',
			'24249353',
			'61475242',
			'48514468',
			'4482252',
			'44439303',
			'14860435',
			'54218697',
			'69740',
			'18916727',
			'564933',
			'17429875',
			'54129941',
			'65540139',
			'10631297',
			'42081096',
			'11116824',
			'70584464',
			'34362455',
			'2281629',
			'44440310',
			'44440312',
			'12445070',
			'34178304',
			'27771959',
			'51815830',
			'19676373',
			'52419949',
			'23222474',
			'23540186',
			'5122577',
			'11467433',
			'65282947',
			'52419940',
			'22328177',
			'66177135',
			'30399596',
			'66726156',
			'62984346',
			'2336425',
			'62984347',
			'37216614',
			'62984348',
			'60336408',
			'62984341',
			'15223518',
			'10456954',
			'12792176',
			'63671845',
			'3605861',
			'22284085',
			'17497981',
			'15165142',
			'35821265',
			'3605868',
			'16345809',
			'62984335',
			'5529280',
			'33969819',
			'62984338',
			'14638032',
			'34363721',
			'45835711',
			'19676312',
			'10177127',
			'4482215',
			'4482211',
			'22137467',
			'3540875',
			'14818938',
			'32834230',
			'16422596',
			'31111727',
			'69647147',
			'24118808',
			'18087465',
			'14931312',
			'24939919',
			'37627474',
			'31839249',
			'36194906',
			'42407238',
			'31727852',
			'1399392',
			'19920630',
			'19360971',
			'31852206',
			'13083932',
			'46198338',
			'55287056',
			'10910221',
			'17175000',
			'53109650',
			'45271662',
			'32750033',
			'48664114',
			'36299109',
			'18329446',
			'9199239',
			'22722946',
			'39629778',
			'48979965',
			'1592353',
			'9775882',
			'36829038',
			'15165190',
			'48658291',
			'3235',
			'32892931',
			'62556155',
			'18253778',
			'48700338',
			'5429893',
			'36325176',
			'6659140',
			'11606932',
			'19180941',
			'1851789',
			'15068830',
			'17804626',
			'54271716',
			'18801462',
			'51302120',
			'35214348',
			'25003354',
			'14802504',
			'35197329',
			'33904441',
			'3775192',
			'36901771',
			'33217745',
			'18329477',
			'21644267',
			'19414492',
			'31793325',
			'3531038',
			'40207943',
			'3531036',
			'34029928',
			'67403439',
			'13123696',
			'14927283',
			'50719281',
			'36166834',
			'29994771',
			'17802883',
			'57930681',
			'1266245',
			'63210784',
			'33039506',
			'15103739',
			'46141429',
			'61000414',
			'21126942',
			'42185695',
			'55989602',
			'4276792',
			'10621918',
			'6171336',
			'10075178',
			'1397604',
			'3998357',
			'46352621',
			'5370698',
			'49770852',
			'42238730',
			'1851744',
			'42032084',
			'68113643',
			'32613603',
			'60257938',
			'22722922',
			'41060304',
			'15254716',
			'9308047',
			'46340623',
			'4299434',
			'11579661',
			'67625204',
			'2336461',
			'24390633',
			'9844023',
			'22771055',
			'35712159',
			'47000471',
			'11031366',
			'36259047',
			'21523090',
			'44466127',
			'3504280',
			'3998265',
			'15193252',
			'69362857',
			'71433329',
			'27767905',
			'3941373',
			'18154721',
			'5175886',
			'14626879',
			'15538443',
			'16729832',
			'38306530',
			'13232879',
			'4819124',
			'1852110',
			'8167859',
			'32723252',
			'68100254',
			'19649643',
			'34368522',
			'25121496',
			'60615327',
			'46935789',
			'5539598',
			'12343997',
			'44797192',
			'1718974',
			'41889467',
			'28869689',
			'12375584',
			'3837128',
			'29920554',
			'32812081',
			'16234876',
			'7763292',
			'3576789',
			'46143236',
			'3837139',
			'66461523',
			'565487',
			'4192642',
			'12801651',
			'21057794',
			'5370719',
			'52988804',
			'30520015',
			'1452892',
			'66795527',
			'21720466',
			'1590643',
			'14858960',
			'15078100',
			'31730545',
			'27833935',
			'2528417',
			'13401275',
			'28782525',
			'48407846',
			'34364121',
			'6233353',
			'4192645',
			'25094088',
			'9746501',
			'16304519',
			'54181687',
			'45541628',
			'34979984',
			'5370708',
			'16179617',
			'5370707',
			'5370706',
			'48661883',
			'1674729',
			'5370705',
			'29840656',
			'5370704',
			'5370702',
			'26588555',
			'13480006',
			'24074718',
			'3948834',
			'19483911',
			'12466766',
			'22189521',
			'55238137',
			'21852430',
			'16411831',
			'55400050',
			'35361718',
			'31709128',
			'9325486',
			'42427667',
			'17033000',
			'45051577',
			'23171167',
			'27309031',
			'36804640',
			'33870556',
			'7762819',
			'14916607',
			'14988238',
			'40296107',
			'31884785',
			'44438975',
			'71721125',
			'2921169',
			'27256766',
			'8377729',
			'11475025',
			'22703769',
			'19816250',
			'14581465',
			'66458747',
			'2601583',
			'2759293',
			'44436344',
			'32749810',
			'16583845',
			'11912679',
			'46322852',
			'27108962',
			'981640',
			'48664033',
			'54005781',
			'9324161',
			'11868976',
			'70125076',
			'23932772',
			'40036846',
			'15378809',
			'16884120',
			'66471703',
			'18327682',
			'46197077',
			'6621305',
			'12773947',
			'8318520',
			'5434362',
			'36389216',
			'48555812',
			'32827993',
			'4460838',
			'8694091',
			'13237722',
			'21630555',
			'14867825',
			'29277407',
			'17254265',
			'11236688',
			'36797696',
			'17853619',
			'68511173',
			'30077487',
			'528444',
			'50914130',
			'33371510',
			'38498947',
			'3444165',
			'11057063',
			'18445846',
			'27514975',
			'32594935',
			'27065375',
			'37155174',
			'36137536',
			'2717421',
			'11609227',
			'32660011',
			'20290224',
			'4537160',
			'7483925',
			'24997028',
			'26748801',
			'32894785',
			'45215418',
			'5006336',
			'5532141',
			'24112569',
			'31114078',
			'27515222',
			'8333976',
			'7622482',
			'25817404',
			'12243568',
			'15104068',
			'45754549',
			'4772626',
			'3484918',
			'39550817',
			'3883738',
			'64173853',
			'16125899',
			'18762027',
			'66726290',
			'51282160',
			'31727987',
			'28863476',
			'53191348',
			'48526548',
			'1858485',
			'5465439',
			'23222347',
			'11549786',
			'67671907',
			'2785430',
			'46142813',
			'53191311',
			'2942156',
			'17174470',
			'31620239',
			'45959214',
			'31061668',
			'12302616',
			'21626569',
			'11429069',
			'11234188',
			'66315450',
			'23191741',
			'34990178',
			'46320067',
			'35821137',
			'36803120',
			'4886851',
			'24425738',
			'31105160',
			'22404501',
			'21944063',
			'48657960',
			'22330572',
			'2255294',
			'8369951',
			'38085557',
			'46392525',
			'66801876',
			'8484977',
			'12757688',
			'28720962',
			'35203597',
			'53191386',
			'62984447',
			'4521401',
			'15791084',
			'4015417',
			'16426838',
			'16101039',
			'19560466',
			'5998957',
			'19102471',
			'66199481',
			'8398476',
			'22238084',
			'37110111',
			'6163677',
			'3487131',
			'8364244',
			'9516026',
			'9786606',
			'42178434',
			'19815987',
			'15085415',
			'16414878',
			'44440227',
			'53191369',
			'69363450',
			'16333726',
			'23958545',
			'22722885',
			'4542344',
			'1862564',
			'52653322',
			'46935721',
			'62584184',
			'3778365',
			'50719192',
			'18328047',
			'18329379',
			'66461373',
			'3700523',
			'4196898',
			'27834426',
			'1534344',
			'21520794',
			'17364043',
			'39212858',
			'45360216',
			'2594980',
			'39979796',
			'12650831',
			'3700513',
			'3405229',
			'3868194',
			'3700519',
			'65330163',
			'40636622',
			'44766007',
			'9330057',
			'10625331',
			'10927137',
			'36347299',
			'48663732',
			'7787239',
			'15068982',
			'1627449',
			'12066629',
			'3431503',
			'52792302',
			'18761556',
			'10637328',
			'2917123',
			'22957396',
			'35057123',
			'35699372',
			'48367264',
			'17147718',
			'13510523',
			'39212878',
			'42038323',
			'54041289',
			'5067347',
			'12376062',
			'48976131',
			'60378185',
			'35782768',
			'66905675',
			'6931400',
			'21825538',
			'2336372',
			'17669448',
			'64707497',
			'23709749',
			'34362598',
			'72085161',
			'46141542',
			'2653617',
			'8022500',
			'15116928',
			'22331044',
			'15178353',
			'44336182',
			'32819579',
			'10637385',
			'2336360',
			'58102377',
			'45863990',
			'48673124',
			'46142880',
			'12767876',
			'26751776',
			'66532206',
			'32654032',
			'15222920',
			'33252910',
			'19076501',
			'45928083',
			'43283937',
			'32877413',
			'22202058',
			'25833537',
			'33460962',
			'34944283',
			'28949622',
			'35178976',
			'6172533',
			'2745304',
			'19871915',
			'21458263',
			'31328285',
			'66032774',
			'10438386',
			'17331130',
			'12639637',
			'32038402',
			'3884257',
			'62983957',
			'53105421',
			'42342375',
			'4997714',
			'62983960',
			'62983961',
			'20165046',
			'1164026',
			'2335808',
			'17430049',
			'46142033',
			'26451625',
			'17090314',
			'10936138',
			'62983944',
			'62983946',
			'4180595',
			'62983953',
			'10912181',
			'37413254',
			'5311407',
			'62983932',
			'62983933',
			'62983934',
			'62983935',
			'62983936',
			'62983937',
			'62983939',
			'47000542',
			'34853936',
			'62983940',
			'22403157',
			'62983941',
			'62983942',
			'41902840',
			'22781176',
			'2476055',
			'12464256',
			'70947704',
			'62983929',
			'8334490',
			'11904095',
			'57904428',
			'35843676',
			'62983926',
			'36696364',
			'62983928',
			'2077174',
			'70409965',
			'38857400',
			'19806167',
			'23364455',
			'62983930',
			'37155931',
			'1845972',
			'36264503',
			'43990489',
			'15036404',
			'66664383',
			'32723156',
			'2482718',
			'52051145',
			'1462339',
			'5281964',
			'26886546',
			'66456035',
			'46398733',
			'4409694',
			'6042563',
			'62983920',
			'12470928',
			'34768890',
			'52794084',
			'1150291',
			'9367401',
			'2457743',
			'54839389',
			'3004624',
			'27223788',
			'12749143',
			'66400486',
			'19828394',
			'58060865',
			'28777030',
			'19531020',
			'9630510',
			'52420171',
			'23220654',
			'20108493',
			'22509494',
			'69362924',
			'5152435',
			'46143312',
			'2409246',
			'45863132',
			'16092131',
			'35181792',
			'3624339',
			'6162250',
			'10640181',
			'64107494',
			'1451640',
			'44437531',
			'58026125',
			'24418694',
			'58026124',
			'16198893',
			'30070924',
			'8172496',
			'16583987',
			'8428810',
			'36063360',
			'10914767',
			'26805117',
			'4925255',
			'19527853',
			'46994026',
			'4730730',
			'13437956',
			'42010733',
			'6248572',
			'66953254',
			'24233125',
			'19774042',
			'9302029',
			'18328822',
			'10946317',
			'66320758',
			'19505620',
			'12758036',
			'967247',
			'2528557',
			'21026558',
			'2473879',
			'2497819',
			'13234555',
			'21436969',
			'12375618',
			'1451631',
			'19641865',
			'17870251',
			'12488292',
			'4014067',
			'23185891',
			'37741329',
			'38705289',
			'530658',
			'6854883',
			'28776598',
			'5147915',
			'4631051',
			'23408486',
			'48659623',
			'20045248',
			'12829258',
			'26803376',
			'10636975',
			'6058050',
			'10636967',
			'53843444',
			'35290812',
			'18769521',
			'19567025',
			'444282',
			'38427426',
			'12459700',
			'14774906',
			'11962813',
			'13394397',
			'12792346',
			'17895580',
			'18789104',
			'30558797',
			'4310516',
			'62983966',
			'62983968',
			'37907775',
			'62983969',
			'2566843',
			'10937409',
			'12829233',
			'19087182',
			'3028676',
			'4274826',
			'13480762',
			'62983974',
			'9699429',
			'67443581',
			'66965277',
			'5175136',
			'36803045',
			'23222200',
			'21037184',
			'34032963',
			'58228842',
			'41973027',
			'25722262',
			'36347591',
			'66456743',
			'1368875',
			'12509841',
			'39297958',
			'53191458',
			'32545243',
			'36990567',
			'61638529',
			'15094053',
			'19102364',
			'25016858',
			'34501751',
			'12517478',
			'36982954',
			'4761854',
			'18302165',
			'36013034',
			'24377874',
			'13269474',
			'15224628',
			'44508671',
			'44468099',
			'32811318',
			'10266053',
			'36244247',
			'53191430',
			'18602457',
			'14860662',
			'8339141',
			'33460487',
			'37419127',
			'10911751',
			'13270455',
			'16126409',
			'31726792',
			'9853608',
			'57128384',
			'28921307',
			'34362204',
			'2706900',
			'36155558',
			'28921385',
			'3134271',
			'20521742',
			'66239760',
			'16553711',
			'66188478',
			'7761388',
			'12393599',
			'52655415',
			'25094313',
			'29285638',
			'5906063',
			'44438252',
			'20187823',
			'9140309',
			'10926815',
			'12760722',
			'5328997',
			'32685590',
			'53159303',
			'53159302',
			'53159301',
			'17896141',
			'53159300',
			'18119348',
			'3498133',
			'41952149',
			'2335783',
			'19677436',
			'11020885',
			'12759732',
			'2563061',
			'23260531',
			'15635868',
			'32856239',
			'42396603',
			'33461774',
			'53159314',
			'53159313',
			'53159312',
			'53159311',
			'53159310',
			'8511263',
			'44465867',
			'3066881',
			'2563059',
			'2894041',
			'53191485',
			'66593988',
			'29864058',
			'53159309',
			'53159308',
			'31852439',
			'53159307',
			'21648495',
			'53159306',
			'53159305',
			'53159304',
			'53159325',
			'53159324',
			'53159323',
			'53159322',
			'53159321',
			'53159320',
			'18230881',
			'69677217',
			'17510445',
			'27835406',
			'36711221',
			'28982333',
			'42883279',
			'17342384',
			'26769161',
			'3547617',
			'15085189',
			'19749670',
			'56966632',
			'38807557',
			'53159319',
			'53159318',
			'53159317',
			'53159316',
			'53159315',
			'53159336',
			'12742046',
			'50912588',
			'53159335',
			'37211109',
			'53159334',
			'53159333',
			'53159332',
			'53159331',
			'53159330',
			'6487840',
			'66732212',
			'40840475',
			'48659390',
			'27109163',
			'25141249',
			'35194074',
			'53159329',
			'53159328',
			'53159327',
			'53159326',
			'22438451',
			'53159347',
			'53159346',
			'53159345',
			'53159344',
			'53159342',
			'53159341',
			'53159340',
			'3066031',
			'17518399',
			'13403245',
			'21543756',
			'34362298',
			'16539487',
			'53159339',
			'53159338',
			'1975341',
			'53159337',
			'53159353',
			'53159352',
			'46944107',
			'53159351',
			'1789428',
			'15779706',
			'47030179',
			'53159350',
			'3134281',
			'43280752',
			'32749246',
			'46290478',
			'21812993',
			'53159349',
			'51815203',
			'53159348',
			'4173603',
			'53159369',
			'53159368',
			'53159367',
			'53159366',
			'42268796',
			'53159365',
			'53159364',
			'53159363',
			'53159362',
			'45430583',
			'53159361',
			'7439549',
			'27109109',
			'37975198',
			'2526989',
			'1709372',
			'20145115',
			'16401040',
			'34795626',
			'35178873',
			'35830361',
			'53159379',
			'53159378',
			'53159377',
			'53159376',
			'9613588',
			'53159375',
			'17510417',
			'46968957',
			'53159372',
			'66471495',
			'53159371',
			'8415383',
			'53159370',
			'32047030',
			'58024818',
			'38642225',
			'4895856',
			'12465737',
			'38434543',
			'48731299',
			'66461226',
			'70605654',
			'18436202',
			'70605651',
			'64921952',
			'38651159',
			'53159383',
			'53159382',
			'53159381',
			'9367452',
			'10650200',
			'53159380',
			'50913841',
			'11608916',
			'18076524',
			'41888731',
			'61498995',
			'70605649',
			'29863594',
			'21389553',
			'28866413',
			'66932708',
			'7484106',
			'42627090',
			'3556583',
			'63112022',
			'1046315',
			'4985013',
			'28851394',
			'3547641',
			'19353113',
			'36433211',
			'58024837',
			'18948108',
			'57161769',
			'58024832',
			'4895872',
			'34120337',
			'27269008',
			'22720921',
			'15409143',
			'17842298',
			'28414608',
			'8654295',
			'66471590',
			'66147186',
			'2340271',
			'14627986',
			'30404468',
			'53159299',
			'53159298',
			'53159297',
			'53159296',
			'53104225',
			'53159295',
			'1590440',
			'876989',
			'15540981',
			'4395189',
			'2591072',
			'44466383',
			'8313432',
			'2536234',
			'11940059',
			'8728984',
			'10916632',
			'66966849',
			'45859499',
			'18118856',
			'29993739',
			'71081889',
			'12658194',
			'4411602',
			'20201007',
			'9843486',
			'26893136',
			'36893451',
			'16635311',
			'28944914',
			'7619618',
			'21720285',
			'37419920',
			'19871831',
			'10637289',
			'36433133',
			'68685397',
			'41970798',
			'12410568',
			'36802637',
			'57938760',
			'18471473',
			'23972599',
			'28580059',
			'7048752',
			'52110680',
			'70537092',
			'5960397',
			'13507550',
			'15231814',
			'32723446',
			'28757319',
			'32724773',
			'66471535',
			'44982314',
			'12410505',
			'46399959',
			'28711940',
			'48441977',
			'5094608',
			'44564734',
			'46306584',
			'980524',
			'32494793',
			'9329263',
			'5094610',
			'19232054',
			'2822375',
			'23047940',
			'10630680',
			'14730600',
			'48662966',
			'17174702',
			'2902652',
			'64445041',
			'9462816',
			'10176977',
			'46142589',
			'15889807',
			'27108734',
			'34551162',
			'6118096',
			'17486128',
			'36797483',
			'31996261',
			'35798589',
			'33311280',
			'55984639',
			'45918849',
			'4668239',
			'64922475',
			'34124683',
			'34448277',
			'2417805',
			'9315449',
			'5704778',
			'24233256',
			'42078409',
			'10636807',
			'22723113',
			'16126496',
			'46143073',
			'10914851',
			'36804437',
			'64922442',
			'57667848',
			'3991452',
			'66092675',
			'67282',
			'4821630',
			'63061738',
			'37136224',
			'2536289',
			'67283',
			'3300418',
			'3066848',
			'27441513',
			'13426724',
			'2699355',
			'27359506',
			'8357075',
			'5321541',
			'15853835',
			'16434758',
			'35607619',
			'24094970',
			'15061975',
			'48991005',
			'44940524',
			'14581282',
			'3015220',
			'59014439',
			'68697321',
			'54728644',
			'48623308',
			'11105172',
			'29473326',
			'34921955',
			'3066818',
			'13177615',
			'37138878',
			'46143054',
			'972986',
			'10631130',
			'44766605',
			'7737688',
			'11880802',
			'15190800',
			'20133954',
			'28781801',
			'50931705',
			'37133153',
			'9814074',
			'24135077',
			'46143023',
			'9344803',
			'38721617',
			'1605928',
			'28658278',
			'7782201',
			'13505779',
			'24001554',
			'33462100',
			'18655705',
			'45997607',
			'50914382',
			'63875210',
			'36162713',
			'26963495',
			'66120135',
			'32823402',
			'36710096',
			'33779256',
			'8327047',
			'43273482',
			'10064744',
			'10085683',
			'5067975',
			'20045042',
			'35023555',
			'69379605',
			'22328072',
			'69548798',
			'44462694',
			'35355686',
			'21592652',
			'67665442',
			'54296039',
			'42399800',
			'13337140',
			'18063389',
			'41950282',
			'15398866',
			'10498692',
			'6057315',
			'12608693',
			'64922284',
			'16637183',
			'15659762',
			'55604971',
			'24458839',
			'15425289',
			'21938829',
			'1081456',
			'8285778',
			'3240427',
			'3638678',
			'53969213',
			'24316172',
			'44975009',
			'34857732',
			'2475779',
			'24078794',
			'26887148',
			'49798749',
			'19669972',
			'38258841',
			'44440423',
			'44440425',
			'53191560',
			'22330368',
			'41967957',
			'71442744',
			'1940539',
			'14731122',
			'5622381',
			'15050560',
			'4197531',
			'10426105',
			'57125157',
			'49784113',
			'10245892',
			'14757747',
			'37994428',
			'2908897',
			'54689611',
			'7742992',
			'39556315',
			'39764240',
			'44802025',
			'16424415',
			'53159409',
			'24399385',
			'58007530',
			'32914738',
			'66091308',
			'5383696',
			'17827644',
			'5147788',
			'10935872',
			'22585767',
			'32722930',
			'31059545',
			'69538579',
			'53159416',
			'4430964',
			'53159414',
			'54218549',
			'2520004',
			'39156639',
			'57210351',
			'68041730',
			'32809159',
			'32750152',
			'3540971',
			'31852397',
			'38384552',
			'66798963',
			'19896141',
			'4556776',
			'64924093',
			'21632131',
			'22426572',
			'16411161',
			'41888637',
			'28757114',
			'11836599',
			'30992136',
			'6555513',
			'65491390',
			'32166338',
			'10108809',
			'32267014',
			'50708294',
			'57648665',
			'14689134',
			'18331459',
			'29489533',
			'2520023',
			'18076611',
			'5662472',
			'27332504',
			'53159449',
			'66461146',
			'34074666',
			'7697867',
			'31075680',
			'41910182',
			'42510922',
			'24232831',
			'17596598',
			'46981877',
			'46972926',
			'1166948',
			'2571654',
			'55562813',
			'14626597',
			'20145294',
			'1518096',
			'2520048',
			'22681625',
			'7761271',
			'53721684',
			'1522111',
			'26445698',
			'33317294',
			'53159469',
			'24158522',
			'23165244',
			'919778',
			'10639799',
			'53159481',
			'9145038',
			'68892975',
			'18809494',
			'52068654',
			'69579945',
			'14774244',
			'31727311',
			'47000360',
			'11941350',
			'23043606',
			'53487101',
			'25792045',
			'3802112',
			'29306321',
			'35564814',
			'1916067',
			'66533303',
			'28670936',
			'3848359',
			'34562649',
			'66457099',
			'2520069',
			'22181628',
			'22422181',
			'55223501',
			'2520064',
			'42424520',
			'23370834',
			'34610793',
			'34362342',
			'70121053',
			'25094484',
			'52737701',
			'31854112',
			'63475411',
			'23144363',
			'4628894',
			'12757823',
			'58024969',
			'1341937',
			'15765884',
			'67388496',
			'46522038',
			'29098813',
			'30531517',
			'11579308',
			'58024963',
			'36466006',
			'12801489',
			'56946909',
			'10453758',
			'42700155',
			'2520085',
			'16436491',
			'10911829',
			'35551531',
			'6924990',
			'11626929',
			'11057190',
			'12516925',
			'47000322',
			'65928946',
			'15068687',
			'9998821',
			'4957019',
			'38693046',
			'45681741',
			'51815137',
			'66457177',
			'4010732',
			'46399872',
			'22095286',
			'50708339',
			'3688151',
			'9479786',
			'8269655',
			'15882431',
			'3792380',
			'33284752',
			'4546596',
			'32828315',
			'38706790',
			'31953832',
			'18330079',
			'21219429',
			'9353889',
			'26804867',
			'33757911',
			'41464128',
			'46143102',
			'36831066',
			'9495805',
			'12946592',
			'4418366',
			'11017961',
			'11448018',
			'1368089',
			'35820853',
			'15231700',
			'67671216',
			'13446281',
			'17410227',
			'36811429',
			'24008933',
			'63060508',
			'21720352',
			'32511835',
			'37142850',
			'1098349',
			'5686365',
			'47001610',
			'31112128',
			'55250338',
			'44466290',
			'32723322',
			'18139882',
			'922558',
			'27722916',
			'922550',
			'42105778',
			'13401398',
			'57263666',
			'55089070',
			'15102138',
			'16411909',
			'18139870',
			'4969042',
			'4969041',
			'15679402',
			'37701524',
			'21118850',
			'46065211',
			'70096445',
			'10630562',
			'13404406',
			'15067337',
			'12488426',
			'18692825',
			'22775926',
			'67388516',
			'67388517',
			'67388518',
			'67388519',
			'53642475',
			'67388515',
			'68275460',
			'40419905',
			'63858609',
			'7274631',
			'22623759',
			'45024295',
			'31105831',
			'55089050',
			'14730733',
			'38644900',
			'10494310',
			'15255653',
			'1097851',
			'19871764',
			'32749908',
			'29118849',
			'32139620',
			'15394524',
			'4511982',
			'5275083',
			'13368296',
			'38793173',
			'4460754',
			'14931465',
			'43508165',
			'18357183',
			'11236550',
			'17123068',
			'18764045',
			'44461407',
			'44564642',
			'50752449',
			'28899158',
			'39632494',
			'32834157',
			'11969692',
			'39342213',
			'4628921',
			'29369890',
			'32986321',
			'5308400',
			'50752452',
			'25755004',
			'70442376',
			'17804165',
			'67649383',
			'19928280',
			'17186609',
			'24317413',
			'4395232',
			'16422625',
			'16277445',
			'3540889',
			'9487351',
			'31277621',
			'37252352',
			'3020520',
			'1181666',
			'4676581',
			'4680601',
			'1875196',
			'56533828',
			'50752471',
			'36299425',
			'2908946',
			'2144254',
			'56228801',
			'31070774',
			'37136114',
			'39185938',
			'24361579',
			'36332479',
			'16895306',
			'46143172',
			'16074906',
			'68892057',
			'15409220',
			'37299878',
			'18164854',
			'46996877',
			'9656527',
			'20060624',
			'22325028',
			'28922867',
			'1083250',
			'50708305',
			'42580733',
			'65923217',
			'2759399',
			'10911051',
			'10911052',
			'4320519',
			'14699439',
			'38898169',
			'10589917',
			'36802540',
			'11435561',
			'10144433',
			'15818764',
			'10018872',
			'53191519',
			'36262117',
			'64176656',
			'15889906',
			'18463124',
			'24721263',
			'2059170',
			'22148282',
			'27486545',
			'15059793',
			'2908915',
			'50752445',
			'68045388',
			'44261110',
			'12793436',
			'7783412',
			'9061881',
			'10499173',
			'44469397',
			'15244125',
			'33064031',
			'37651606',
			'10109200',
			'50752434',
			'21629474',
			'36709896',
			'4727790',
		],
		type: 'province_state',
	},

	/* 4 */
	{
		_id: ObjectId('61b2788ed17acfb0a7fce971'),
		id: '67',
		coordinates: {
			center_longitude: 23.999144,
			center_latitude: 38.247967,
			bounding_polygon: {
				type: 'MultiPolygon',
				coordinates: [
					[
						[
							[19.247788, 39.863268],
							[19.247796, 39.859159],
							[19.247957, 39.854284],
							[19.248666, 39.84766],
							[19.248699, 39.847436],
							[19.248699, 39.847424],
							[19.248705, 39.844197],
							[19.248867, 39.839323],
							[19.249574, 39.8327],
							[19.250842, 39.826126],
							[19.252665, 39.81963],
							[19.255034, 39.81324],
							[19.257482, 39.807889],
							[19.257775, 39.807302],
							[19.258233, 39.806396],
							[19.261662, 39.800299],
							[19.265601, 39.794388],
							[19.270031, 39.788688],
							[19.274935, 39.783224],
							[19.28029, 39.778018],
							[19.286074, 39.773094],
							[19.292263, 39.768472],
							[19.298829, 39.764171],
							[19.302652, 39.761916],
							[19.305575, 39.760071],
							[19.31249, 39.756111],
							[19.319725, 39.752507],
							[19.32725, 39.749276],
							[19.331044, 39.747829],
							[19.337375, 39.745551],
							[19.34538, 39.743105],
							[19.353574, 39.741067],
							[19.361923, 39.739447],
							[19.362959, 39.739277],
							[19.366904, 39.738567],
							[19.373821, 39.73759],
							[19.37422, 39.736507],
							[19.377112, 39.730247],
							[19.380527, 39.724147],
							[19.384452, 39.718232],
							[19.388868, 39.712527],
							[19.389948, 39.711319],
							[19.391225, 39.708552],
							[19.394638, 39.702451],
							[19.39856, 39.696536],
							[19.402973, 39.690831],
							[19.407859, 39.68536],
							[19.413198, 39.680149],
							[19.418965, 39.675218],
							[19.41915, 39.67507],
							[19.420617, 39.6739],
							[19.426603, 39.669418],
							[19.433151, 39.66511],
							[19.44005, 39.661142],
							[19.447269, 39.657531],
							[19.454777, 39.654292],
							[19.462543, 39.651438],
							[19.470533, 39.648983],
							[19.478713, 39.646937],
							[19.487048, 39.645308],
							[19.495503, 39.644103],
							[19.504041, 39.643328],
							[19.512627, 39.642986],
							[19.515097, 39.642968],
							[19.515975, 39.642968],
							[19.522101, 39.643078],
							[19.530671, 39.643603],
							[19.539178, 39.64456],
							[19.547587, 39.645945],
							[19.548618, 39.64617],
							[19.549666, 39.643885],
							[19.553062, 39.63778],
							[19.556967, 39.631858],
							[19.561363, 39.626147],
							[19.566233, 39.620671],
							[19.568187, 39.618671],
							[19.569361, 39.617501],
							[19.572728, 39.614281],
							[19.578478, 39.609342],
							[19.584633, 39.604705],
							[19.591166, 39.600388],
							[19.598049, 39.59641],
							[19.601892, 39.594431],
							[19.602391, 39.593532],
							[19.606289, 39.58761],
							[19.610679, 39.581897],
							[19.615541, 39.576418],
							[19.61757, 39.574341],
							[19.618743, 39.573171],
							[19.62203, 39.570026],
							[19.627773, 39.565085],
							[19.633921, 39.560444],
							[19.640447, 39.556125],
							[19.647323, 39.552144],
							[19.648082, 39.551762],
							[19.652105, 39.548724],
							[19.658628, 39.544403],
							[19.665502, 39.540422],
							[19.671129, 39.537587],
							[19.673662, 39.535157],
							[19.679398, 39.530213],
							[19.68554, 39.52557],
							[19.692059, 39.521248],
							[19.698929, 39.517264],
							[19.704004, 39.514651],
							[19.704363, 39.514476],
							[19.703221, 39.509738],
							[19.702201, 39.503138],
							[19.701741, 39.496501],
							[19.701712, 39.494238],
							[19.701714, 39.493361],
							[19.701844, 39.488977],
							[19.702505, 39.48235],
							[19.703725, 39.475771],
							[19.705497, 39.469268],
							[19.707813, 39.462868],
							[19.710664, 39.456599],
							[19.714038, 39.450489],
							[19.717919, 39.444562],
							[19.72229, 39.438844],
							[19.727135, 39.433361],
							[19.729267, 39.431171],
							[19.729855, 39.430584],
							[19.733018, 39.427547],
							[19.738742, 39.4226],
							[19.74487, 39.417954],
							[19.748968, 39.415229],
							[19.750751, 39.412092],
							[19.754627, 39.406164],
							[19.758993, 39.400445],
							[19.763832, 39.39496],
							[19.769122, 39.389732],
							[19.77484, 39.384783],
							[19.776594, 39.383428],
							[19.778235, 39.379806],
							[19.781599, 39.373693],
							[19.78547, 39.367764],
							[19.789831, 39.362044],
							[19.794665, 39.356558],
							[19.79995, 39.351328],
							[19.805663, 39.346378],
							[19.806447, 39.345747],
							[19.807914, 39.344577],
							[19.813247, 39.340558],
							[19.819742, 39.336228],
							[19.826586, 39.332237],
							[19.833752, 39.328601],
							[19.841207, 39.325337],
							[19.848921, 39.322459],
							[19.85686, 39.319977],
							[19.864991, 39.317904],
							[19.873278, 39.316247],
							[19.881687, 39.315015],
							[19.890181, 39.314212],
							[19.898725, 39.313841],
							[19.901801, 39.313814],
							[19.902386, 39.313814],
							[19.907866, 39.313905],
							[19.916399, 39.314403],
							[19.924872, 39.315332],
							[19.926266, 39.315558],
							[19.93165, 39.312772],
							[19.935144, 39.311059],
							[19.942593, 39.307789],
							[19.947492, 39.305899],
							[19.952646, 39.304023],
							[19.95482, 39.303294],
							[19.957011, 39.302567],
							[19.957042, 39.302556],
							[19.964975, 39.300067],
							[19.9731, 39.297987],
							[19.977351, 39.297077],
							[19.978091, 39.29691],
							[19.980105, 39.296074],
							[19.98781, 39.293186],
							[19.99141, 39.291997],
							[19.99312, 39.291142],
							[19.996695, 39.289385],
							[19.99704, 39.289222],
							[19.997664, 39.288828],
							[20.002408, 39.286049],
							[20.001464, 39.284551],
							[19.999979, 39.282039],
							[19.996773, 39.275876],
							[19.994094, 39.269564],
							[19.991952, 39.263128],
							[19.990356, 39.256598],
							[19.989313, 39.250001],
							[19.988828, 39.243364],
							[19.98879, 39.240772],
							[19.988795, 39.238128],
							[19.988906, 39.234074],
							[19.989539, 39.227445],
							[19.990681, 39.221074],
							[19.990691, 39.220877],
							[19.991323, 39.214248],
							[19.992511, 39.207665],
							[19.99425, 39.201157],
							[19.996532, 39.194751],
							[19.999347, 39.188475],
							[19.99952, 39.188128],
							[19.999815, 39.18754],
							[20.002977, 39.181769],
							[20.006819, 39.175832],
							[20.011151, 39.170103],
							[20.011925, 39.169164],
							[20.01346, 39.165903],
							[20.016793, 39.159783],
							[20.020633, 39.153846],
							[20.024963, 39.148117],
							[20.029764, 39.14262],
							[20.032238, 39.140063],
							[20.034292, 39.138009],
							[20.037069, 39.135325],
							[20.042748, 39.130363],
							[20.048831, 39.125701],
							[20.055292, 39.121358],
							[20.059266, 39.118955],
							[20.060542, 39.117744],
							[20.066218, 39.112781],
							[20.072298, 39.108117],
							[20.078755, 39.103773],
							[20.085563, 39.099766],
							[20.092692, 39.096115],
							[20.100111, 39.092834],
							[20.102637, 39.091881],
							[20.104198, 39.08981],
							[20.108988, 39.084311],
							[20.111649, 39.081561],
							[20.111943, 39.081267],
							[20.114524, 39.078773],
							[20.120193, 39.073807],
							[20.126266, 39.06914],
							[20.132717, 39.064793],
							[20.136589, 39.06251],
							[20.137115, 39.062156],
							[20.143916, 39.058147],
							[20.151037, 39.054492],
							[20.155052, 39.052712],
							[20.155093, 39.052688],
							[20.157737, 39.050369],
							[20.163806, 39.0457],
							[20.170252, 39.041351],
							[20.177049, 39.037339],
							[20.184166, 39.033682],
							[20.191575, 39.030396],
							[20.199242, 39.027494],
							[20.203216, 39.026233],
							[20.205055, 39.025417],
							[20.212721, 39.022514],
							[20.220614, 39.020008],
							[20.228699, 39.017909],
							[20.236943, 39.016227],
							[20.24531, 39.014969],
							[20.253764, 39.014139],
							[20.26227, 39.013742],
							[20.265805, 39.013705],
							[20.266391, 39.013705],
							[20.271377, 39.013779],
							[20.279877, 39.01425],
							[20.288318, 39.015154],
							[20.296667, 39.016485],
							[20.304886, 39.018239],
							[20.312941, 39.020408],
							[20.320797, 39.022983],
							[20.328422, 39.025953],
							[20.335781, 39.029305],
							[20.342845, 39.033024],
							[20.349583, 39.037096],
							[20.355965, 39.041502],
							[20.361965, 39.046224],
							[20.367557, 39.051241],
							[20.369167, 39.052817],
							[20.369755, 39.053404],
							[20.373304, 39.05712],
							[20.37801, 39.062664],
							[20.382241, 39.068435],
							[20.38598, 39.074409],
							[20.38921, 39.080561],
							[20.391917, 39.086865],
							[20.39409, 39.093293],
							[20.395719, 39.099818],
							[20.396798, 39.106412],
							[20.39732, 39.113047],
							[20.397373, 39.115784],
							[20.397375, 39.116666],
							[20.397286, 39.120576],
							[20.396693, 39.127207],
							[20.395544, 39.133794],
							[20.394203, 39.13893],
							[20.398998, 39.135683],
							[20.404071, 39.132677],
							[20.407137, 39.1306],
							[20.409353, 39.129287],
							[20.410727, 39.128356],
							[20.417519, 39.12433],
							[20.424633, 39.120659],
							[20.432039, 39.117357],
							[20.439706, 39.114439],
							[20.444946, 39.112766],
							[20.447011, 39.110377],
							[20.450328, 39.106953],
							[20.450431, 39.106804],
							[20.454723, 39.101059],
							[20.459488, 39.095545],
							[20.463082, 39.091846],
							[20.463096, 39.091824],
							[20.467387, 39.086079],
							[20.472149, 39.080564],
							[20.477363, 39.075304],
							[20.483007, 39.07032],
							[20.489056, 39.065635],
							[20.491678, 39.063784],
							[20.494199, 39.062051],
							[20.496642, 39.059724],
							[20.497002, 39.059383],
							[20.499565, 39.057037],
							[20.507372, 39.050104],
							[20.510449, 39.047466],
							[20.516495, 39.042779],
							[20.522919, 39.03841],
							[20.529696, 39.034378],
							[20.535346, 39.031408],
							[20.536665, 39.030753],
							[20.541463, 39.026551],
							[20.547504, 39.021863],
							[20.553925, 39.017492],
							[20.560697, 39.013458],
							[20.567793, 39.009778],
							[20.570446, 39.008589],
							[20.56998, 39.002838],
							[20.569999, 38.99619],
							[20.570576, 38.989558],
							[20.571707, 38.982969],
							[20.573387, 38.976452],
							[20.575609, 38.970035],
							[20.578364, 38.963745],
							[20.581639, 38.957609],
							[20.581711, 38.957486],
							[20.597145, 38.931123],
							[20.598866, 38.92758],
							[20.596854, 38.92725],
							[20.59358, 38.926128],
							[20.585955, 38.923175],
							[20.578595, 38.919839],
							[20.57153, 38.916134],
							[20.571351, 38.916033],
							[20.568303, 38.914369],
							[20.561565, 38.91031],
							[20.555181, 38.905915],
							[20.549181, 38.901203],
							[20.544626, 38.897175],
							[20.540655, 38.893552],
							[20.535495, 38.888267],
							[20.530789, 38.88273],
							[20.526556, 38.876964],
							[20.52636, 38.876673],
							[20.525807, 38.875981],
							[20.523974, 38.873589],
							[20.522584, 38.87187],
							[20.518353, 38.866103],
							[20.516951, 38.863978],
							[20.516598, 38.863462],
							[20.515668, 38.862065],
							[20.51253, 38.859521],
							[20.506943, 38.85451],
							[20.505192, 38.852794],
							[20.504052, 38.851652],
							[20.502559, 38.850419],
							[20.496973, 38.845407],
							[20.494987, 38.843455],
							[20.494696, 38.843161],
							[20.491528, 38.839827],
							[20.486828, 38.834288],
							[20.482602, 38.82852],
							[20.478868, 38.822548],
							[20.475642, 38.816398],
							[20.472938, 38.810096],
							[20.471219, 38.805159],
							[20.46989, 38.801029],
							[20.469083, 38.798078],
							[20.46885, 38.797477],
							[20.468163, 38.795618],
							[20.467974, 38.79513],
							[20.467173, 38.792949],
							[20.465274, 38.789851],
							[20.463956, 38.787625],
							[20.460733, 38.781475],
							[20.458031, 38.775172],
							[20.455862, 38.768745],
							[20.454546, 38.763661],
							[20.45336, 38.758699],
							[20.452942, 38.756523],
							[20.451319, 38.752575],
							[20.449337, 38.74677],
							[20.445167, 38.741063],
							[20.441441, 38.73509],
							[20.440453, 38.733319],
							[20.439311, 38.731669],
							[20.436103, 38.726586],
							[20.434707, 38.72423],
							[20.431489, 38.718079],
							[20.428792, 38.711776],
							[20.426628, 38.705348],
							[20.425005, 38.698823],
							[20.42393, 38.692228],
							[20.423408, 38.685593],
							[20.423354, 38.682518],
							[20.423371, 38.67313],
							[20.423458, 38.669557],
							[20.424045, 38.662925],
							[20.424937, 38.657529],
							[20.424875, 38.657028],
							[20.424353, 38.650392],
							[20.424299, 38.647328],
							[20.4243, 38.646741],
							[20.424387, 38.643157],
							[20.424509, 38.641106],
							[20.423406, 38.638862],
							[20.420713, 38.632559],
							[20.418552, 38.626131],
							[20.416949, 38.619691],
							[20.415751, 38.616715],
							[20.41359, 38.610287],
							[20.411971, 38.603761],
							[20.410899, 38.597167],
							[20.410378, 38.590532],
							[20.410325, 38.587466],
							[20.41033, 38.584827],
							[20.410417, 38.581244],
							[20.410656, 38.577766],
							[20.410061, 38.573698],
							[20.409542, 38.567063],
							[20.409489, 38.563999],
							[20.409491, 38.562531],
							[20.409578, 38.558946],
							[20.410166, 38.552314],
							[20.411305, 38.545727],
							[20.412989, 38.539212],
							[20.415213, 38.532797],
							[20.417964, 38.52651],
							[20.420755, 38.521209],
							[20.421399, 38.520078],
							[20.421877, 38.519247],
							[20.42226, 38.518643],
							[20.422072, 38.518385],
							[20.418359, 38.512411],
							[20.415152, 38.506259],
							[20.412464, 38.499955],
							[20.410308, 38.493527],
							[20.408691, 38.487002],
							[20.407621, 38.480407],
							[20.407102, 38.473772],
							[20.407049, 38.470705],
							[20.407052, 38.46924],
							[20.407139, 38.465659],
							[20.407181, 38.464959],
							[20.402319, 38.463644],
							[20.394521, 38.461075],
							[20.394163, 38.460935],
							[20.389314, 38.460669],
							[20.380937, 38.459772],
							[20.372652, 38.458447],
							[20.364495, 38.456698],
							[20.356501, 38.454532],
							[20.348706, 38.451959],
							[20.341142, 38.448991],
							[20.333841, 38.445639],
							[20.326836, 38.441919],
							[20.320155, 38.437846],
							[20.31839, 38.436616],
							[20.317513, 38.436081],
							[20.311187, 38.431673],
							[20.305242, 38.426948],
							[20.299703, 38.421927],
							[20.29842, 38.420664],
							[20.298126, 38.42037],
							[20.2943, 38.416338],
							[20.289643, 38.41079],
							[20.287119, 38.407414],
							[20.287007, 38.407271],
							[20.282822, 38.401496],
							[20.279127, 38.395517],
							[20.275938, 38.389362],
							[20.273267, 38.383055],
							[20.271126, 38.376624],
							[20.269524, 38.370097],
							[20.268469, 38.363501],
							[20.267963, 38.356865],
							[20.267986, 38.353636],
							[20.26703, 38.347657],
							[20.266525, 38.341021],
							[20.266548, 38.337741],
							[20.265624, 38.334961],
							[20.264958, 38.332515],
							[20.264237, 38.330879],
							[20.263672, 38.330402],
							[20.259786, 38.326871],
							[20.258688, 38.325996],
							[20.25316, 38.320973],
							[20.251941, 38.319771],
							[20.251647, 38.319477],
							[20.247768, 38.315382],
							[20.243121, 38.309833],
							[20.238946, 38.304056],
							[20.235259, 38.298076],
							[20.232077, 38.291919],
							[20.229414, 38.285611],
							[20.22728, 38.27918],
							[20.225684, 38.272652],
							[20.224634, 38.266056],
							[20.224575, 38.26527],
							[20.223765, 38.260188],
							[20.223265, 38.253551],
							[20.223219, 38.2507],
							[20.223221, 38.249822],
							[20.223227, 38.249598],
							[20.222302, 38.248303],
							[20.218619, 38.242323],
							[20.215441, 38.236165],
							[20.212781, 38.229857],
							[20.21065, 38.223425],
							[20.209058, 38.216897],
							[20.20801, 38.210301],
							[20.207511, 38.203664],
							[20.207564, 38.197017],
							[20.20766, 38.195405],
							[20.20753, 38.192812],
							[20.207583, 38.186164],
							[20.208186, 38.179533],
							[20.209337, 38.172947],
							[20.211031, 38.166435],
							[20.211728, 38.16425],
							[20.211994, 38.162975],
							[20.213687, 38.156463],
							[20.214743, 38.153221],
							[20.21546, 38.151102],
							[20.21739, 38.145655],
							[20.220144, 38.139373],
							[20.223412, 38.133247],
							[20.22718, 38.127302],
							[20.231432, 38.121565],
							[20.236148, 38.11606],
							[20.24131, 38.110809],
							[20.246894, 38.105837],
							[20.252877, 38.101163],
							[20.259234, 38.096808],
							[20.262894, 38.094547],
							[20.263926, 38.093878],
							[20.270628, 38.089861],
							[20.277647, 38.086198],
							[20.283413, 38.083558],
							[20.284437, 38.082693],
							[20.287025, 38.080595],
							[20.287665, 38.080056],
							[20.289564, 38.078504],
							[20.291031, 38.07733],
							[20.29511, 38.074206],
							[20.301462, 38.069849],
							[20.308159, 38.065829],
							[20.315175, 38.062164],
							[20.322477, 38.058869],
							[20.328546, 38.056494],
							[20.33238, 38.055078],
							[20.339107, 38.052904],
							[20.343924, 38.05001],
							[20.350936, 38.046343],
							[20.358235, 38.043045],
							[20.365791, 38.040132],
							[20.368441, 38.039275],
							[20.370772, 38.038376],
							[20.378551, 38.035859],
							[20.386522, 38.033749],
							[20.39465, 38.032055],
							[20.4029, 38.030785],
							[20.411237, 38.029944],
							[20.419626, 38.029535],
							[20.422109, 38.029543],
							[20.426748, 38.025901],
							[20.429592, 38.023943],
							[20.432321, 38.021801],
							[20.438659, 38.017436],
							[20.445345, 38.013408],
							[20.452348, 38.009735],
							[20.459639, 38.006431],
							[20.46091, 38.005939],
							[20.461237, 38.005714],
							[20.467919, 38.001685],
							[20.47492, 37.99801],
							[20.482209, 37.994705],
							[20.489755, 37.991784],
							[20.497525, 37.989259],
							[20.505487, 37.987141],
							[20.513607, 37.985439],
							[20.52185, 37.98416],
							[20.53018, 37.98331],
							[20.538563, 37.982893],
							[20.542441, 37.982847],
							[20.545077, 37.982847],
							[20.549598, 37.98291],
							[20.557978, 37.983361],
							[20.566303, 37.984244],
							[20.570581, 37.984829],
							[20.567652, 37.983027],
							[20.56135, 37.978632],
							[20.555425, 37.97392],
							[20.552321, 37.97116],
							[20.55167, 37.970705],
							[20.545747, 37.965993],
							[20.540226, 37.960984],
							[20.535132, 37.955699],
							[20.530486, 37.950162],
							[20.530086, 37.949609],
							[20.526446, 37.946305],
							[20.525212, 37.945088],
							[20.524918, 37.944794],
							[20.52106, 37.940725],
							[20.516417, 37.935187],
							[20.512241, 37.929421],
							[20.50855, 37.92345],
							[20.505362, 37.9173],
							[20.502688, 37.910999],
							[20.500541, 37.904572],
							[20.500352, 37.903809],
							[20.497761, 37.89881],
							[20.495089, 37.892509],
							[20.492943, 37.886082],
							[20.491332, 37.879558],
							[20.490263, 37.872964],
							[20.489741, 37.866329],
							[20.489685, 37.863179],
							[20.489686, 37.862298],
							[20.489768, 37.85885],
							[20.489768, 37.8588],
							[20.490343, 37.852168],
							[20.491463, 37.84558],
							[20.491534, 37.845255],
							[20.489936, 37.838781],
							[20.488868, 37.832188],
							[20.488346, 37.825553],
							[20.48829, 37.822403],
							[20.488292, 37.821522],
							[20.488374, 37.818024],
							[20.488948, 37.811391],
							[20.490068, 37.804803],
							[20.491728, 37.798287],
							[20.49291, 37.794627],
							[20.494511, 37.790113],
							[20.497228, 37.783825],
							[20.500456, 37.777691],
							[20.504183, 37.771737],
							[20.508392, 37.76599],
							[20.513064, 37.760473],
							[20.51818, 37.755211],
							[20.523718, 37.750225],
							[20.526047, 37.748312],
							[20.527514, 37.747138],
							[20.53112, 37.744363],
							[20.537429, 37.739993],
							[20.544083, 37.73596],
							[20.546326, 37.734776],
							[20.548692, 37.732353],
							[20.554226, 37.727365],
							[20.554877, 37.72682],
							[20.555371, 37.725973],
							[20.555953, 37.724944],
							[20.559672, 37.718989],
							[20.563873, 37.71324],
							[20.568538, 37.707721],
							[20.573646, 37.702456],
							[20.576401, 37.699889],
							[20.579349, 37.695247],
							[20.583547, 37.689497],
							[20.588209, 37.683977],
							[20.59189, 37.68011],
							[20.593064, 37.678936],
							[20.594488, 37.677537],
							[20.600015, 37.672548],
							[20.60467, 37.668811],
							[20.607287, 37.665806],
							[20.611061, 37.661844],
							[20.611649, 37.661257],
							[20.612977, 37.659952],
							[20.618501, 37.654962],
							[20.624424, 37.650269],
							[20.630718, 37.645894],
							[20.635357, 37.643016],
							[20.638824, 37.640975],
							[20.645782, 37.63729],
							[20.647945, 37.636247],
							[20.652311, 37.633652],
							[20.659267, 37.629966],
							[20.662323, 37.628505],
							[20.664796, 37.625667],
							[20.669891, 37.620397],
							[20.675409, 37.615404],
							[20.681324, 37.610708],
							[20.687612, 37.60633],
							[20.69356, 37.602681],
							[20.695052, 37.601461],
							[20.69913, 37.596657],
							[20.703035, 37.592552],
							[20.70509, 37.590501],
							[20.706276, 37.589335],
							[20.711788, 37.58434],
							[20.717699, 37.579643],
							[20.723882, 37.575328],
							[20.725912, 37.573782],
							[20.732194, 37.569401],
							[20.738822, 37.565357],
							[20.745767, 37.561665],
							[20.753, 37.558343],
							[20.760489, 37.555404],
							[20.765131, 37.553818],
							[20.770841, 37.55198],
							[20.778747, 37.549843],
							[20.785003, 37.548469],
							[20.791932, 37.546621],
							[20.799996, 37.544898],
							[20.808184, 37.543599],
							[20.816461, 37.542729],
							[20.824791, 37.542291],
							[20.829047, 37.542234],
							[20.829925, 37.542234],
							[20.834018, 37.542287],
							[20.842349, 37.542717],
							[20.847176, 37.543166],
							[20.849088, 37.543303],
							[20.857366, 37.544165],
							[20.865556, 37.545456],
							[20.873623, 37.547169],
							[20.873934, 37.547245],
							[20.881826, 37.548925],
							[20.889736, 37.551054],
							[20.897454, 37.55359],
							[20.904948, 37.556522],
							[20.912186, 37.559838],
							[20.919137, 37.563522],
							[20.91944, 37.563695],
							[20.925671, 37.566578],
							[20.932623, 37.570262],
							[20.939258, 37.574299],
							[20.945548, 37.578673],
							[20.951466, 37.583365],
							[20.956986, 37.588354],
							[20.958299, 37.589643],
							[20.958593, 37.589937],
							[20.962379, 37.593913],
							[20.964964, 37.596978],
							[20.972885, 37.596135],
							[20.975006, 37.595979],
							[20.9794, 37.595685],
							[20.985615, 37.595391],
							[20.99397, 37.595376],
							[21.002308, 37.595794],
							[21.010594, 37.596645],
							[21.018793, 37.597925],
							[21.026869, 37.599628],
							[21.034788, 37.601747],
							[21.042517, 37.604273],
							[21.050022, 37.607196],
							[21.057272, 37.610502],
							[21.064235, 37.614178],
							[21.070881, 37.618208],
							[21.077183, 37.622575],
							[21.083113, 37.62726],
							[21.088646, 37.632243],
							[21.093757, 37.637503],
							[21.095609, 37.639598],
							[21.096105, 37.640141],
							[21.100775, 37.645655],
							[21.104981, 37.6514],
							[21.108706, 37.657352],
							[21.11093, 37.661451],
							[21.113994, 37.667297],
							[21.116712, 37.673584],
							[21.118907, 37.679999],
							[21.12057, 37.686514],
							[21.121694, 37.693102],
							[21.122273, 37.699734],
							[21.122358, 37.703319],
							[21.122359, 37.7042],
							[21.122306, 37.707263],
							[21.122198, 37.708653],
							[21.12229, 37.70971],
							[21.122375, 37.713278],
							[21.122384, 37.718264],
							[21.12233, 37.721343],
							[21.121815, 37.727979],
							[21.121463, 37.730164],
							[21.121469, 37.73132],
							[21.12145, 37.731567],
							[21.126433, 37.730764],
							[21.131273, 37.730243],
							[21.160738, 37.723104],
							[21.174935, 37.717964],
							[21.179652, 37.715422],
							[21.180157, 37.712267],
							[21.180926, 37.709122],
							[21.178937, 37.703326],
							[21.177268, 37.696812],
							[21.17614, 37.690224],
							[21.175556, 37.683593],
							[21.175468, 37.679884],
							[21.175085, 37.674937],
							[21.175048, 37.668289],
							[21.175088, 37.667329],
							[21.175173, 37.665536],
							[21.175644, 37.65986],
							[21.176699, 37.653266],
							[21.177412, 37.650066],
							[21.177395, 37.643889],
							[21.177905, 37.637253],
							[21.17861, 37.632496],
							[21.178791, 37.631482],
							[21.17914, 37.629644],
							[21.180735, 37.623118],
							[21.182862, 37.616689],
							[21.185512, 37.610385],
							[21.188675, 37.604231],
							[21.192337, 37.598256],
							[21.192408, 37.598148],
							[21.198165, 37.588682],
							[21.200215, 37.585458],
							[21.204358, 37.579685],
							[21.208966, 37.57414],
							[21.214018, 37.568846],
							[21.219494, 37.563827],
							[21.22537, 37.559104],
							[21.231621, 37.554695],
							[21.23822, 37.550622],
							[21.245139, 37.5469],
							[21.252347, 37.543547],
							[21.259815, 37.540575],
							[21.267511, 37.537998],
							[21.275402, 37.535827],
							[21.283453, 37.534071],
							[21.291631, 37.532737],
							[21.299901, 37.531832],
							[21.308228, 37.531359],
							[21.316575, 37.53132],
							[21.324908, 37.531715],
							[21.333191, 37.532542],
							[21.341388, 37.533799],
							[21.349465, 37.53548],
							[21.357387, 37.537577],
							[21.359816, 37.538313],
							[21.36067, 37.53858],
							[21.36292, 37.53933],
							[21.364669, 37.538093],
							[21.365456, 37.537459],
							[21.371696, 37.533043],
							[21.378285, 37.528962],
							[21.382577, 37.526583],
							[21.429994, 37.501419],
							[21.464034, 37.482233],
							[21.488447, 37.45832],
							[21.498594, 37.447016],
							[21.551306, 37.36999],
							[21.563712, 37.344176],
							[21.568424, 37.329897],
							[21.566288, 37.328413],
							[21.560351, 37.323753],
							[21.55481, 37.318792],
							[21.551023, 37.31499],
							[21.539818, 37.308598],
							[21.538374, 37.307761],
							[21.523743, 37.299161],
							[21.519258, 37.296398],
							[21.512957, 37.292056],
							[21.507027, 37.287393],
							[21.501491, 37.282431],
							[21.501211, 37.282161],
							[21.49969, 37.280906],
							[21.494156, 37.275943],
							[21.48904, 37.270701],
							[21.487058, 37.268463],
							[21.478896, 37.25898],
							[21.476204, 37.25572],
							[21.471991, 37.249988],
							[21.468257, 37.244049],
							[21.465214, 37.238334],
							[21.458603, 37.224778],
							[21.458405, 37.22437],
							[21.457165, 37.221683],
							[21.449403, 37.204089],
							[21.447913, 37.200498],
							[21.445703, 37.19409],
							[21.444022, 37.18758],
							[21.442877, 37.180996],
							[21.442273, 37.174366],
							[21.442174, 37.169592],
							[21.442319, 37.152858],
							[21.442357, 37.150984],
							[21.442839, 37.144348],
							[21.442845, 37.144309],
							[21.443682, 37.138696],
							[21.445705, 37.127177],
							[21.44275, 37.124519],
							[21.441145, 37.122945],
							[21.438213, 37.120009],
							[21.434716, 37.116339],
							[21.430055, 37.110839],
							[21.425854, 37.105106],
							[21.42213, 37.099166],
							[21.4189, 37.093043],
							[21.416178, 37.086763],
							[21.413974, 37.080355],
							[21.412298, 37.073845],
							[21.411158, 37.067261],
							[21.410557, 37.060631],
							[21.410457, 37.056454],
							[21.41046, 37.054989],
							[21.410502, 37.052519],
							[21.410986, 37.045884],
							[21.412011, 37.039287],
							[21.413571, 37.032759],
							[21.41566, 37.026327],
							[21.41827, 37.020017],
							[21.419614, 37.017218],
							[21.419892, 37.016664],
							[21.420623, 37.01503],
							[21.422073, 37.01202],
							[21.422369, 37.011433],
							[21.424036, 37.008284],
							[21.427119, 37.003119],
							[21.429154, 36.998319],
							[21.43227, 36.99216],
							[21.435881, 36.986177],
							[21.439971, 36.980397],
							[21.444523, 36.974843],
							[21.449256, 36.9698],
							[21.44955, 36.969506],
							[21.449811, 36.969246],
							[21.455226, 36.964216],
							[21.461039, 36.959481],
							[21.467224, 36.95506],
							[21.473756, 36.950974],
							[21.480606, 36.947238],
							[21.487745, 36.94387],
							[21.495143, 36.940883],
							[21.502768, 36.93829],
							[21.510587, 36.936103],
							[21.518567, 36.93433],
							[21.526675, 36.93298],
							[21.528608, 36.932762],
							[21.528628, 36.932635],
							[21.530176, 36.926105],
							[21.532252, 36.919671],
							[21.534847, 36.913359],
							[21.536659, 36.909624],
							[21.537255, 36.907943],
							[21.538684, 36.904298],
							[21.540193, 36.897953],
							[21.542268, 36.891518],
							[21.544861, 36.885206],
							[21.54674, 36.881337],
							[21.547627, 36.879569],
							[21.549728, 36.875525],
							[21.550406, 36.874316],
							[21.552374, 36.870542],
							[21.555969, 36.864555],
							[21.55947, 36.859584],
							[21.560624, 36.854702],
							[21.562695, 36.848267],
							[21.563711, 36.845622],
							[21.565537, 36.839618],
							[21.565936, 36.834418],
							[21.566944, 36.82782],
							[21.568486, 36.82129],
							[21.570556, 36.814855],
							[21.57242, 36.81031],
							[21.571415, 36.807449],
							[21.570256, 36.80389],
							[21.568573, 36.797382],
							[21.567422, 36.790801],
							[21.56681, 36.784172],
							[21.566702, 36.779822],
							[21.566717, 36.770913],
							[21.565706, 36.764985],
							[21.565093, 36.758357],
							[21.564985, 36.754011],
							[21.564992, 36.749903],
							[21.565028, 36.747602],
							[21.565088, 36.746085],
							[21.565005, 36.742283],
							[21.565008, 36.740815],
							[21.565044, 36.738508],
							[21.565512, 36.731872],
							[21.566518, 36.725275],
							[21.568058, 36.718745],
							[21.570125, 36.71231],
							[21.572709, 36.705998],
							[21.574291, 36.7027],
							[21.574586, 36.702117],
							[21.576095, 36.699252],
							[21.57968, 36.693265],
							[21.583742, 36.68748],
							[21.588265, 36.681921],
							[21.593229, 36.676612],
							[21.598543, 36.671637],
							[21.602135, 36.668351],
							[21.607915, 36.663608],
							[21.614067, 36.65918],
							[21.620564, 36.655085],
							[21.627379, 36.651341],
							[21.634483, 36.647964],
							[21.641845, 36.644967],
							[21.649435, 36.642365],
							[21.657218, 36.640168],
							[21.665163, 36.638385],
							[21.667995, 36.637907],
							[21.669768, 36.636629],
							[21.676261, 36.632531],
							[21.679149, 36.630878],
							[21.683843, 36.627545],
							[21.690334, 36.623446],
							[21.697143, 36.619698],
							[21.699419, 36.618559],
							[21.706, 36.61544],
							[21.713356, 36.612439],
							[21.715502, 36.611654],
							[21.721563, 36.609216],
							[21.727906, 36.607003],
							[21.731783, 36.605728],
							[21.73956, 36.603525],
							[21.747499, 36.601736],
							[21.755566, 36.60037],
							[21.763726, 36.599431],
							[21.771945, 36.598923],
							[21.780187, 36.59885],
							[21.788418, 36.599211],
							[21.796602, 36.600004],
							[21.804704, 36.601227],
							[21.81269, 36.602874],
							[21.817103, 36.604036],
							[21.822626, 36.601418],
							[21.829977, 36.598409],
							[21.837554, 36.595794],
							[21.845328, 36.593584],
							[21.853263, 36.591788],
							[21.861327, 36.590414],
							[21.869485, 36.589467],
							[21.877702, 36.588952],
							[21.883349, 36.58885],
							[21.885107, 36.58885],
							[21.887701, 36.588871],
							[21.895932, 36.589224],
							[21.904116, 36.59001],
							[21.912161, 36.591215],
							[21.917785, 36.592105],
							[21.925772, 36.593744],
							[21.933611, 36.5958],
							[21.941266, 36.598265],
							[21.948705, 36.601128],
							[21.955897, 36.604376],
							[21.957316, 36.605077],
							[21.957901, 36.605371],
							[21.963395, 36.60829],
							[21.970001, 36.612266],
							[21.976271, 36.616582],
							[21.982178, 36.62122],
							[21.987697, 36.626158],
							[21.992804, 36.631376],
							[21.997477, 36.636853],
							[22.001697, 36.642564],
							[22.005444, 36.648484],
							[22.008648, 36.654477],
							[22.009587, 36.656351],
							[22.012345, 36.662616],
							[22.014589, 36.669012],
							[22.016309, 36.675513],
							[22.017033, 36.679148],
							[22.017321, 36.680335],
							[22.01928, 36.681681],
							[22.025194, 36.686316],
							[22.030721, 36.691252],
							[22.035835, 36.696469],
							[22.03978, 36.701031],
							[22.043988, 36.706192],
							[22.044724, 36.707105],
							[22.048951, 36.712814],
							[22.050819, 36.71576],
							[22.056195, 36.719448],
							[22.062115, 36.724082],
							[22.067647, 36.729017],
							[22.072767, 36.734232],
							[22.077453, 36.739705],
							[22.081684, 36.745413],
							[22.085443, 36.751332],
							[22.088714, 36.757435],
							[22.090279, 36.760816],
							[22.090806, 36.762017],
							[22.092008, 36.764899],
							[22.094262, 36.771294],
							[22.095991, 36.777794],
							[22.097188, 36.784371],
							[22.097848, 36.790996],
							[22.097968, 36.797643],
							[22.097547, 36.804281],
							[22.096908, 36.808678],
							[22.101929, 36.806268],
							[22.109289, 36.803242],
							[22.109965, 36.802988],
							[22.118751, 36.799711],
							[22.125664, 36.797331],
							[22.13345, 36.795101],
							[22.1414, 36.793285],
							[22.149481, 36.791891],
							[22.149769, 36.791857],
							[22.151917, 36.788864],
							[22.156404, 36.783282],
							[22.161334, 36.777948],
							[22.166687, 36.772884],
							[22.172439, 36.768113],
							[22.175885, 36.765605],
							[22.176113, 36.763738],
							[22.176493, 36.761041],
							[22.177074, 36.757488],
							[22.17856, 36.750949],
							[22.180575, 36.744503],
							[22.183109, 36.738177],
							[22.186151, 36.731997],
							[22.189688, 36.725991],
							[22.193704, 36.720184],
							[22.198184, 36.7146],
							[22.203107, 36.709264],
							[22.208451, 36.704199],
							[22.211119, 36.701904],
							[22.212842, 36.699102],
							[22.215107, 36.695824],
							[22.21539, 36.694969],
							[22.21583, 36.693762],
							[22.216101, 36.692986],
							[22.217747, 36.688713],
							[22.218991, 36.684937],
							[22.221519, 36.678609],
							[22.224556, 36.672429],
							[22.228087, 36.666422],
							[22.232097, 36.660613],
							[22.23657, 36.655028],
							[22.237968, 36.653475],
							[22.238349, 36.650851],
							[22.238542, 36.649999],
							[22.238207, 36.646697],
							[22.238064, 36.640672],
							[22.23808, 36.639501],
							[22.23809, 36.63888],
							[22.238497, 36.632241],
							[22.239442, 36.625638],
							[22.239992, 36.623206],
							[22.239402, 36.617378],
							[22.239269, 36.610733],
							[22.239373, 36.607964],
							[22.239839, 36.599404],
							[22.240142, 36.595535],
							[22.241086, 36.588932],
							[22.242374, 36.583235],
							[22.238559, 36.578099],
							[22.234796, 36.572186],
							[22.231521, 36.566086],
							[22.228748, 36.559828],
							[22.22649, 36.553436],
							[22.224754, 36.546938],
							[22.223549, 36.540364],
							[22.22288, 36.533739],
							[22.222749, 36.527094],
							[22.22275, 36.527044],
							[22.222768, 36.526203],
							[22.222673, 36.520197],
							[22.22308, 36.513558],
							[22.223364, 36.511141],
							[22.223598, 36.508382],
							[22.224543, 36.50178],
							[22.225591, 36.496908],
							[22.226616, 36.492747],
							[22.227045, 36.491081],
							[22.229048, 36.484634],
							[22.231569, 36.478307],
							[22.234596, 36.472127],
							[22.236396, 36.468929],
							[22.236952, 36.467647],
							[22.239978, 36.461467],
							[22.243498, 36.45546],
							[22.245974, 36.451758],
							[22.247052, 36.450023],
							[22.249725, 36.446042],
							[22.250479, 36.444606],
							[22.253997, 36.438598],
							[22.257993, 36.432789],
							[22.259789, 36.430441],
							[22.263755, 36.425409],
							[22.266416, 36.422172],
							[22.271314, 36.416833],
							[22.276633, 36.411765],
							[22.28235, 36.406988],
							[22.288441, 36.402524],
							[22.294879, 36.398391],
							[22.301637, 36.394606],
							[22.307944, 36.391547],
							[22.310046, 36.390411],
							[22.317094, 36.386991],
							[22.323369, 36.384381],
							[22.325913, 36.382956],
							[22.33296, 36.379535],
							[22.340268, 36.376494],
							[22.347805, 36.373845],
							[22.353099, 36.372308],
							[22.355991, 36.366372],
							[22.359497, 36.360361],
							[22.363481, 36.354548],
							[22.367925, 36.348959],
							[22.370954, 36.345648],
							[22.373211, 36.34179],
							[22.377192, 36.335977],
							[22.381635, 36.330386],
							[22.386519, 36.325043],
							[22.391823, 36.319969],
							[22.397526, 36.315187],
							[22.403602, 36.310717],
							[22.410026, 36.306577],
							[22.41677, 36.302786],
							[22.423805, 36.299359],
							[22.431101, 36.296312],
							[22.438627, 36.293658],
							[22.446352, 36.291406],
							[22.454241, 36.289568],
							[22.462261, 36.288152],
							[22.469252, 36.287273],
							[22.469921, 36.287204],
							[22.471047, 36.287093],
							[22.479227, 36.286535],
							[22.487435, 36.28641],
							[22.495635, 36.28672],
							[22.503792, 36.287462],
							[22.511873, 36.288634],
							[22.519841, 36.290231],
							[22.527664, 36.292246],
							[22.535308, 36.294671],
							[22.542741, 36.297494],
							[22.549929, 36.300705],
							[22.556844, 36.304288],
							[22.563455, 36.30823],
							[22.569734, 36.312513],
							[22.575655, 36.317119],
							[22.581191, 36.322028],
							[22.586319, 36.327219],
							[22.586911, 36.327907],
							[22.592491, 36.332246],
							[22.59803, 36.337154],
							[22.60316, 36.342345],
							[22.607861, 36.347796],
							[22.612111, 36.353483],
							[22.615893, 36.359384],
							[22.619191, 36.365472],
							[22.621989, 36.37172],
							[22.624277, 36.378104],
							[22.626044, 36.384595],
							[22.627282, 36.391165],
							[22.627986, 36.397787],
							[22.628162, 36.402901],
							[22.62817, 36.405212],
							[22.628161, 36.406743],
							[22.62779, 36.413384],
							[22.626882, 36.419989],
							[22.625984, 36.424072],
							[22.626755, 36.426903],
							[22.627354, 36.429728],
							[22.62751, 36.430393],
							[22.62864, 36.436244],
							[22.629027, 36.438723],
							[22.629136, 36.439443],
							[22.629841, 36.446065],
							[22.629953, 36.450492],
							[22.630087, 36.451203],
							[22.630792, 36.457825],
							[22.630959, 36.46447],
							[22.63091, 36.466306],
							[22.630934, 36.466659],
							[22.631102, 36.473304],
							[22.631064, 36.474835],
							[22.630814, 36.4822],
							[22.630855, 36.482792],
							[22.631022, 36.489437],
							[22.630823, 36.494022],
							[22.630678, 36.496059],
							[22.630506, 36.498114],
							[22.630044, 36.501467],
							[22.630011, 36.502071],
							[22.629102, 36.508677],
							[22.627733, 36.514943],
							[22.626745, 36.51914],
							[22.624775, 36.525593],
							[22.622286, 36.531929],
							[22.619289, 36.53812],
							[22.616366, 36.543156],
							[22.619131, 36.545599],
							[22.624277, 36.550789],
							[22.628992, 36.556239],
							[22.629351, 36.556719],
							[22.631484, 36.558869],
							[22.6362, 36.56432],
							[22.640465, 36.570007],
							[22.64426, 36.575907],
							[22.646329, 36.579585],
							[22.64713, 36.581087],
							[22.64837, 36.583497],
							[22.65104, 36.589436],
							[22.653822, 36.592239],
							[22.658542, 36.597689],
							[22.66281, 36.603375],
							[22.666609, 36.609275],
							[22.669921, 36.615362],
							[22.672733, 36.62161],
							[22.675033, 36.627994],
							[22.67681, 36.634484],
							[22.677164, 36.636089],
							[22.678118, 36.640608],
							[22.67901, 36.645574],
							[22.679721, 36.652197],
							[22.679892, 36.658842],
							[22.679525, 36.665483],
							[22.678618, 36.67209],
							[22.678131, 36.674335],
							[22.679268, 36.675479],
							[22.67961, 36.675873],
							[22.680297, 36.670864],
							[22.681093, 36.666951],
							[22.681218, 36.666226],
							[22.682659, 36.659681],
							[22.684627, 36.653226],
							[22.686098, 36.649309],
							[22.688386, 36.643537],
							[22.691382, 36.637344],
							[22.691641, 36.636861],
							[22.693188, 36.63399],
							[22.69642, 36.628451],
							[22.700393, 36.622626],
							[22.704, 36.618009],
							[22.70718, 36.612566],
							[22.71115, 36.60674],
							[22.715584, 36.601137],
							[22.720462, 36.595778],
							[22.725763, 36.590689],
							[22.731466, 36.585889],
							[22.737544, 36.5814],
							[22.743974, 36.577241],
							[22.746512, 36.575746],
							[22.748039, 36.57487],
							[22.752252, 36.572554],
							[22.759297, 36.569106],
							[22.766607, 36.566037],
							[22.774149, 36.56336],
							[22.777174, 36.562471],
							[22.778581, 36.560476],
							[22.783007, 36.554869],
							[22.787877, 36.549508],
							[22.793171, 36.544415],
							[22.798865, 36.539613],
							[22.804936, 36.53512],
							[22.811357, 36.530958],
							[22.811855, 36.530676],
							[22.810398, 36.528007],
							[22.807578, 36.521762],
							[22.805271, 36.515382],
							[22.803485, 36.508893],
							[22.80223, 36.502324],
							[22.801509, 36.495703],
							[22.801327, 36.490215],
							[22.800648, 36.483968],
							[22.800458, 36.478256],
							[22.800478, 36.466522],
							[22.800485, 36.465589],
							[22.800841, 36.458949],
							[22.801734, 36.452341],
							[22.803159, 36.445795],
							[22.805111, 36.439338],
							[22.807581, 36.432998],
							[22.810559, 36.426803],
							[22.811223, 36.425651],
							[22.808315, 36.421142],
							[22.805026, 36.415112],
							[22.804733, 36.414525],
							[22.804707, 36.414472],
							[22.802828, 36.41045],
							[22.79923, 36.405608],
							[22.795786, 36.400306],
							[22.794416, 36.398113],
							[22.791332, 36.393259],
							[22.788019, 36.387176],
							[22.785871, 36.382527],
							[22.785177, 36.381543],
							[22.78138, 36.375648],
							[22.778069, 36.369564],
							[22.775257, 36.363319],
							[22.772957, 36.356939],
							[22.771626, 36.352286],
							[22.769745, 36.34696],
							[22.767967, 36.340471],
							[22.766717, 36.333902],
							[22.766001, 36.327281],
							[22.765814, 36.321607],
							[22.765818, 36.319261],
							[22.765825, 36.31829],
							[22.766184, 36.311651],
							[22.767077, 36.305044],
							[22.768503, 36.298499],
							[22.770454, 36.292043],
							[22.772922, 36.285704],
							[22.775896, 36.27951],
							[22.779364, 36.273486],
							[22.782414, 36.268905],
							[22.782158, 36.268279],
							[22.779877, 36.26195],
							[22.778107, 36.256908],
							[22.77633, 36.250419],
							[22.775081, 36.243851],
							[22.774365, 36.23723],
							[22.774178, 36.231545],
							[22.774179, 36.230668],
							[22.774187, 36.229708],
							[22.774543, 36.223069],
							[22.775435, 36.216462],
							[22.776858, 36.209916],
							[22.778806, 36.203462],
							[22.781271, 36.197123],
							[22.781934, 36.195631],
							[22.782223, 36.191393],
							[22.783114, 36.184786],
							[22.784535, 36.178241],
							[22.786482, 36.171786],
							[22.788944, 36.165447],
							[22.791913, 36.159252],
							[22.795373, 36.153227],
							[22.799312, 36.147399],
							[22.803712, 36.141793],
							[22.808553, 36.136432],
							[22.809536, 36.135429],
							[22.811051, 36.133587],
							[22.815891, 36.128225],
							[22.821153, 36.123132],
							[22.822006, 36.122368],
							[22.825331, 36.116591],
							[22.829266, 36.110762],
							[22.831018, 36.108429],
							[22.831029, 36.108415],
							[22.835423, 36.102807],
							[22.84026, 36.097445],
							[22.841691, 36.09599],
							[22.843746, 36.093939],
							[22.847573, 36.0903],
							[22.853229, 36.085495],
							[22.857159, 36.0825],
							[22.861605, 36.079243],
							[22.867984, 36.075078],
							[22.872314, 36.07261],
							[22.874721, 36.066387],
							[22.877678, 36.06019],
							[22.881127, 36.054164],
							[22.885054, 36.048333],
							[22.889442, 36.042724],
							[22.891947, 36.039849],
							[22.892964, 36.038619],
							[22.897792, 36.033254],
							[22.89933, 36.031689],
							[22.900504, 36.030519],
							[22.904215, 36.026986],
							[22.909862, 36.022179],
							[22.915884, 36.017681],
							[22.922255, 36.013513],
							[22.928947, 36.009691],
							[22.935931, 36.006234],
							[22.943178, 36.003154],
							[22.950657, 36.000465],
							[22.958336, 35.998179],
							[22.959688, 35.997856],
							[22.961559, 35.997299],
							[22.969405, 35.995425],
							[22.977385, 35.993972],
							[22.985464, 35.992945],
							[22.993608, 35.99235],
							[23.001783, 35.992188],
							[23.009954, 35.99246],
							[23.018085, 35.993165],
							[23.026142, 35.994301],
							[23.034091, 35.995861],
							[23.041898, 35.997841],
							[23.049529, 36.00023],
							[23.056952, 36.003019],
							[23.064135, 36.006197],
							[23.071048, 36.009749],
							[23.077661, 36.01366],
							[23.083945, 36.017914],
							[23.089874, 36.022493],
							[23.095423, 36.027376],
							[23.097696, 36.029575],
							[23.098871, 36.030745],
							[23.101742, 36.033714],
							[23.10646, 36.039144],
							[23.107058, 36.039937],
							[23.201235, 35.989222],
							[23.194619, 35.985318],
							[23.18833, 35.98107],
							[23.182398, 35.976497],
							[23.176848, 35.971617],
							[23.171702, 35.966453],
							[23.166984, 35.961027],
							[23.162713, 35.95536],
							[23.158908, 35.949479],
							[23.155584, 35.943408],
							[23.152757, 35.937174],
							[23.150437, 35.930802],
							[23.15029, 35.930338],
							[23.14956, 35.928161],
							[23.147758, 35.921679],
							[23.146481, 35.915116],
							[23.145735, 35.908498],
							[23.145528, 35.898716],
							[23.145528, 35.898628],
							[23.14585, 35.891988],
							[23.146705, 35.885379],
							[23.148089, 35.87883],
							[23.149996, 35.872369],
							[23.152418, 35.866023],
							[23.15286, 35.865006],
							[23.155069, 35.859278],
							[23.155876, 35.857443],
							[23.155885, 35.857414],
							[23.158306, 35.851068],
							[23.160162, 35.846995],
							[23.160383, 35.846359],
							[23.160891, 35.844809],
							[23.163311, 35.838462],
							[23.166235, 35.832259],
							[23.167296, 35.83027],
							[23.167429, 35.829957],
							[23.170352, 35.823753],
							[23.172265, 35.820246],
							[23.173738, 35.817537],
							[23.176233, 35.812319],
							[23.179647, 35.806284],
							[23.183538, 35.800444],
							[23.18615, 35.796972],
							[23.186181, 35.796929],
							[23.190531, 35.791308],
							[23.195322, 35.785931],
							[23.197384, 35.783827],
							[23.19768, 35.783533],
							[23.20083, 35.780527],
							[23.20644, 35.775705],
							[23.212424, 35.771192],
							[23.218758, 35.767007],
							[23.222735, 35.764649],
							[23.224923, 35.763063],
							[23.225131, 35.762855],
							[23.226987, 35.761048],
							[23.227011, 35.761023],
							[23.228883, 35.759106],
							[23.229176, 35.758813],
							[23.232512, 35.755617],
							[23.238119, 35.750794],
							[23.2441, 35.746279],
							[23.250429, 35.742092],
							[23.25708, 35.738252],
							[23.264024, 35.734774],
							[23.271232, 35.731673],
							[23.278672, 35.728963],
							[23.286314, 35.726655],
							[23.294124, 35.724758],
							[23.302069, 35.723282],
							[23.310115, 35.722232],
							[23.318228, 35.721613],
							[23.325767, 35.721426],
							[23.327526, 35.721426],
							[23.328131, 35.721427],
							[23.336274, 35.721676],
							[23.344379, 35.722358],
							[23.352412, 35.72347],
							[23.36034, 35.725007],
							[23.368128, 35.726963],
							[23.375742, 35.72933],
							[23.383151, 35.732098],
							[23.390323, 35.735254],
							[23.397226, 35.738786],
							[23.403833, 35.742677],
							[23.409225, 35.746313],
							[23.409996, 35.746834],
							[23.441677, 35.728259],
							[23.476709, 35.707715],
							[23.473241, 35.703741],
							[23.468961, 35.698087],
							[23.465144, 35.692217],
							[23.46319, 35.688668],
							[23.451518, 35.687306],
							[23.439833, 35.684977],
							[23.428487, 35.681728],
							[23.417586, 35.677593],
							[23.407236, 35.672609],
							[23.397538, 35.666826],
							[23.388583, 35.660298],
							[23.380422, 35.653053],
							[23.373179, 35.64519],
							[23.366922, 35.636785],
							[23.361714, 35.62792],
							[23.357605, 35.61868],
							[23.354634, 35.609155],
							[23.352138, 35.594946],
							[23.351352, 35.585191],
							[23.351747, 35.575419],
							[23.353317, 35.565725],
							[23.35605, 35.556201],
							[23.359917, 35.54694],
							[23.364882, 35.538032],
							[23.370712, 35.529795],
							[23.377481, 35.522047],
							[23.385126, 35.514858],
							[23.393578, 35.508295],
							[23.40276, 35.502417],
							[23.412588, 35.497278],
							[23.422974, 35.492924],
							[23.433508, 35.489497],
							[23.432793, 35.486947],
							[23.431498, 35.480387],
							[23.430731, 35.473774],
							[23.430493, 35.467328],
							[23.430494, 35.466741],
							[23.430495, 35.466544],
							[23.430507, 35.465455],
							[23.430498, 35.464123],
							[23.430502, 35.462364],
							[23.430502, 35.462147],
							[23.43052, 35.46076],
							[23.430507, 35.459136],
							[23.43051, 35.457375],
							[23.43051, 35.457158],
							[23.430805, 35.450517],
							[23.430959, 35.448889],
							[23.430765, 35.448504],
							[23.427932, 35.442279],
							[23.427595, 35.441443],
							[23.426177, 35.438172],
							[23.425016, 35.435184],
							[23.423548, 35.433143],
							[23.419748, 35.427272],
							[23.416426, 35.421211],
							[23.413595, 35.414985],
							[23.411269, 35.40862],
							[23.409456, 35.402144],
							[23.408164, 35.395586],
							[23.4074, 35.388972],
							[23.407164, 35.382581],
							[23.407168, 35.380539],
							[23.407142, 35.380432],
							[23.405851, 35.373874],
							[23.405087, 35.36726],
							[23.404853, 35.36062],
							[23.405131, 35.354394],
							[23.402543, 35.350392],
							[23.399225, 35.34433],
							[23.396399, 35.338104],
							[23.394075, 35.331739],
							[23.392266, 35.325263],
							[23.390977, 35.318706],
							[23.390215, 35.312092],
							[23.390198, 35.311613],
							[23.390771, 35.294874],
							[23.391105, 35.292204],
							[23.392424, 35.285787],
							[23.392654, 35.282095],
							[23.39348, 35.275487],
							[23.394831, 35.268937],
							[23.3967, 35.262475],
							[23.399081, 35.256124],
							[23.400332, 35.25343],
							[23.401609, 35.247236],
							[23.403478, 35.240772],
							[23.405858, 35.234423],
							[23.408738, 35.228215],
							[23.412106, 35.222175],
							[23.415949, 35.216329],
							[23.420248, 35.210701],
							[23.424986, 35.205315],
							[23.427329, 35.202903],
							[23.429384, 35.200853],
							[23.432197, 35.198144],
							[23.437749, 35.193312],
							[23.443673, 35.188787],
							[23.449945, 35.18459],
							[23.456537, 35.18074],
							[23.463421, 35.17725],
							[23.470567, 35.174137],
							[23.477946, 35.171413],
							[23.485525, 35.169092],
							[23.488203, 35.168433],
							[23.490238, 35.166658],
							[23.496157, 35.162132],
							[23.496185, 35.162112],
							[23.498452, 35.16021],
							[23.50437, 35.155684],
							[23.510636, 35.151484],
							[23.517222, 35.147628],
							[23.5241, 35.144135],
							[23.531241, 35.141018],
							[23.538615, 35.138291],
							[23.540619, 35.13763],
							[23.541251, 35.137415],
							[23.548825, 35.135089],
							[23.550936, 35.134522],
							[23.552049, 35.134209],
							[23.559792, 35.132295],
							[23.56767, 35.130799],
							[23.57565, 35.12973],
							[23.583697, 35.129092],
							[23.591779, 35.128886],
							[23.593748, 35.128902],
							[23.600845, 35.127971],
							[23.608893, 35.127331],
							[23.61678, 35.127124],
							[23.617365, 35.127124],
							[23.617558, 35.127124],
							[23.621012, 35.127221],
							[23.622709, 35.126592],
							[23.63028, 35.124261],
							[23.638019, 35.122341],
							[23.645895, 35.12084],
							[23.653873, 35.119765],
							[23.661919, 35.119121],
							[23.669881, 35.11891],
							[23.672518, 35.11891],
							[23.672636, 35.11891],
							[23.680716, 35.119133],
							[23.688761, 35.119789],
							[23.696736, 35.120875],
							[23.704608, 35.122387],
							[23.712344, 35.124319],
							[23.71991, 35.126661],
							[23.727273, 35.129404],
							[23.729494, 35.13038],
							[23.732662, 35.131521],
							[23.73294, 35.131459],
							[23.740815, 35.129952],
							[23.748792, 35.128871],
							[23.756839, 35.12822],
							[23.76492, 35.128002],
							[23.764936, 35.128002],
							[23.766694, 35.128002],
							[23.77476, 35.128218],
							[23.782806, 35.128867],
							[23.790784, 35.129947],
							[23.79787, 35.131282],
							[23.800092, 35.131504],
							[23.80807, 35.132583],
							[23.815946, 35.134087],
							[23.823686, 35.136011],
							[23.831256, 35.138346],
							[23.832042, 35.138615],
							[23.833721, 35.138234],
							[23.841595, 35.13672],
							[23.849572, 35.135631],
							[23.85639, 35.135045],
							[23.859607, 35.133461],
							[23.866734, 35.130323],
							[23.874094, 35.127574],
							[23.881658, 35.125226],
							[23.889392, 35.123288],
							[23.897263, 35.12177],
							[23.905237, 35.120678],
							[23.913282, 35.120016],
							[23.921362, 35.119787],
							[23.921585, 35.119786],
							[23.929202, 35.119786],
							[23.937061, 35.119991],
							[23.940124, 35.120234],
							[23.945947, 35.116791],
							[23.946269, 35.116614],
							[23.950049, 35.114444],
							[23.95456, 35.112071],
							[23.955035, 35.111807],
							[23.961891, 35.108287],
							[23.969011, 35.105142],
							[23.976366, 35.102386],
							[23.983923, 35.100031],
							[23.989831, 35.098505],
							[23.994977, 35.097253],
							[23.996523, 35.096809],
							[24.004251, 35.094864],
							[24.012117, 35.093338],
							[24.020087, 35.092237],
							[24.028128, 35.091567],
							[24.031253, 35.091423],
							[24.031805, 35.091361],
							[24.039846, 35.09069],
							[24.047671, 35.090453],
							[24.054071, 35.089602],
							[24.062112, 35.088929],
							[24.070188, 35.088689],
							[24.070607, 35.088689],
							[24.074121, 35.088688],
							[24.081781, 35.088883],
							[24.089827, 35.089511],
							[24.097805, 35.090569],
							[24.105683, 35.092053],
							[24.110857, 35.093275],
							[24.111479, 35.093134],
							[24.119343, 35.0916],
							[24.127312, 35.090492],
							[24.135352, 35.089814],
							[24.143428, 35.089569],
							[24.143943, 35.089568],
							[24.144823, 35.089568],
							[24.145693, 35.089571],
							[24.146066, 35.089569],
							[24.146582, 35.089568],
							[24.149218, 35.089568],
							[24.149972, 35.08957],
							[24.150168, 35.089569],
							[24.150696, 35.089568],
							[24.155677, 35.089568],
							[24.163227, 35.089757],
							[24.169883, 35.09024],
							[24.169944, 35.090211],
							[24.177054, 35.087053],
							[24.1844, 35.084284],
							[24.19195, 35.081915],
							[24.199672, 35.079956],
							[24.207533, 35.078416],
							[24.215499, 35.077302],
							[24.223536, 35.076617],
							[24.231611, 35.076366],
							[24.232239, 35.076365],
							[24.233997, 35.076365],
							[24.241447, 35.076549],
							[24.248172, 35.077034],
							[24.250652, 35.076453],
							[24.258511, 35.07491],
							[24.266477, 35.073792],
							[24.274514, 35.073104],
							[24.282588, 35.072849],
							[24.283288, 35.072847],
							[24.285631, 35.072847],
							[24.293008, 35.073028],
							[24.301054, 35.07364],
							[24.309035, 35.074683],
							[24.316915, 35.076153],
							[24.324662, 35.078042],
							[24.332243, 35.080344],
							[24.333097, 35.080632],
							[24.333857, 35.080544],
							[24.33814, 35.080121],
							[24.342619, 35.078481],
							[24.350164, 35.076102],
							[24.357881, 35.074132],
							[24.362725, 35.073124],
							[24.366932, 35.071305],
							[24.374269, 35.068523],
							[24.376316, 35.067831],
							[24.378295, 35.066856],
							[24.385395, 35.063685],
							[24.392731, 35.060902],
							[24.400273, 35.058519],
							[24.406233, 35.056955],
							[24.409098, 35.056249],
							[24.410022, 35.055924],
							[24.412968, 35.054937],
							[24.4202, 35.052659],
							[24.427913, 35.050685],
							[24.435766, 35.049129],
							[24.443727, 35.047999],
							[24.451759, 35.047298],
							[24.452703, 35.047245],
							[24.453762, 35.045896],
							[24.455945, 35.043351],
							[24.453957, 35.039105],
							[24.451554, 35.032762],
							[24.449661, 35.026304],
							[24.448286, 35.019758],
							[24.447434, 35.013152],
							[24.44711, 35.006515],
							[24.447107, 35.005615],
							[24.44711, 35.003852],
							[24.447317, 34.998112],
							[24.448048, 34.991496],
							[24.449303, 34.984934],
							[24.451076, 34.978454],
							[24.453361, 34.972083],
							[24.456146, 34.965848],
							[24.45942, 34.959777],
							[24.463169, 34.953895],
							[24.467377, 34.948227],
							[24.472025, 34.942798],
							[24.475301, 34.939379],
							[24.475595, 34.939086],
							[24.477387, 34.937337],
							[24.482855, 34.932453],
							[24.488698, 34.927874],
							[24.494891, 34.923619],
							[24.501409, 34.919707],
							[24.508222, 34.916153],
							[24.515302, 34.912974],
							[24.52262, 34.910183],
							[24.530142, 34.907791],
							[24.537838, 34.905809],
							[24.545675, 34.904246],
							[24.553619, 34.903107],
							[24.55672, 34.902782],
							[24.560943, 34.902231],
							[24.56896, 34.901522],
							[24.577015, 34.901246],
							[24.578118, 34.901242],
							[24.587492, 34.901242],
							[24.59445, 34.901403],
							[24.602481, 34.901994],
							[24.609294, 34.902868],
							[24.611024, 34.896487],
							[24.613293, 34.890113],
							[24.616062, 34.883875],
							[24.61932, 34.877799],
							[24.623053, 34.871911],
							[24.627244, 34.866238],
							[24.627473, 34.865951],
							[24.628647, 34.864484],
							[24.63305, 34.859334],
							[24.638102, 34.85416],
							[24.643554, 34.849269],
							[24.649381, 34.844681],
							[24.655559, 34.840418],
							[24.662061, 34.836496],
							[24.66886, 34.832933],
							[24.675926, 34.829744],
							[24.68323, 34.826942],
							[24.690739, 34.824539],
							[24.698423, 34.822547],
							[24.706248, 34.820972],
							[24.714181, 34.819822],
							[24.722188, 34.819102],
							[24.730235, 34.818815],
							[24.73154, 34.818809],
							[24.737084, 34.818809],
							[24.743869, 34.818226],
							[24.751916, 34.817937],
							[24.759968, 34.818083],
							[24.763659, 34.818295],
							[24.76467, 34.818228],
							[24.772717, 34.817937],
							[24.774077, 34.817931],
							[24.775833, 34.817931],
							[24.782525, 34.818081],
							[24.790549, 34.818658],
							[24.79851, 34.819666],
							[24.799743, 34.819862],
							[24.807249, 34.820414],
							[24.815211, 34.821421],
							[24.823076, 34.822855],
							[24.830811, 34.824709],
							[24.838382, 34.826976],
							[24.841372, 34.828002],
							[24.846589, 34.829613],
							[24.85001, 34.830792],
							[24.855751, 34.829337],
							[24.863574, 34.827752],
							[24.871505, 34.826591],
							[24.879512, 34.82586],
							[24.885647, 34.825593],
							[24.891114, 34.824531],
							[24.899044, 34.823368],
							[24.90705, 34.822635],
							[24.915097, 34.822335],
							[24.916645, 34.822326],
							[24.920748, 34.822326],
							[24.927254, 34.822468],
							[24.935279, 34.823035],
							[24.937909, 34.823316],
							[24.940973, 34.822993],
							[24.944748, 34.822493],
							[24.952753, 34.821756],
							[24.9608, 34.821453],
							[24.962428, 34.821444],
							[24.963308, 34.821444],
							[24.969733, 34.821583],
							[24.977759, 34.822147],
							[24.985723, 34.823142],
							[24.993592, 34.824564],
							[25.001331, 34.826407],
							[25.006884, 34.82806],
							[25.010899, 34.827907],
							[25.018953, 34.828034],
							[25.026091, 34.828511],
							[25.029314, 34.828804],
							[25.030203, 34.828887],
							[25.038169, 34.829879],
							[25.046039, 34.831297],
							[25.053781, 34.833136],
							[25.056823, 34.833985],
							[25.059516, 34.83452],
							[25.059744, 34.834568],
							[25.060543, 34.834379],
							[25.068362, 34.83278],
							[25.076291, 34.831604],
							[25.084297, 34.830859],
							[25.092344, 34.830546],
							[25.094137, 34.830535],
							[25.095015, 34.830535],
							[25.101277, 34.830667],
							[25.109305, 34.831222],
							[25.117272, 34.832207],
							[25.125144, 34.83362],
							[25.132888, 34.835454],
							[25.14047, 34.837701],
							[25.147858, 34.840352],
							[25.15502, 34.843395],
							[25.156666, 34.844165],
							[25.158378, 34.844871],
							[25.159581, 34.845335],
							[25.163601, 34.846983],
							[25.16416, 34.846882],
							[25.172089, 34.8457],
							[25.180095, 34.844948],
							[25.188144, 34.844628],
							[25.190055, 34.844616],
							[25.193278, 34.844616],
							[25.199423, 34.844743],
							[25.207454, 34.84529],
							[25.215424, 34.846269],
							[25.223299, 34.847675],
							[25.231047, 34.849503],
							[25.231559, 34.84964],
							[25.235019, 34.850312],
							[25.242767, 34.852138],
							[25.245231, 34.852816],
							[25.245991, 34.853015],
							[25.24632, 34.853102],
							[25.248498, 34.853535],
							[25.256247, 34.855359],
							[25.260057, 34.856426],
							[25.266665, 34.857633],
							[25.269439, 34.858235],
							[25.275748, 34.859394],
							[25.283498, 34.861218],
							[25.291087, 34.863455],
							[25.291257, 34.863509],
							[25.292583, 34.863853],
							[25.300172, 34.86609],
							[25.307569, 34.86873],
							[25.31474, 34.871764],
							[25.317303, 34.87297],
							[25.323395, 34.875177],
							[25.330568, 34.878209],
							[25.334119, 34.879897],
							[25.335071, 34.879932],
							[25.343106, 34.880471],
							[25.351082, 34.88144],
							[25.358963, 34.882837],
							[25.366718, 34.884655],
							[25.374312, 34.886887],
							[25.379844, 34.888812],
							[25.380162, 34.888736],
							[25.387981, 34.887114],
							[25.395911, 34.885917],
							[25.403919, 34.885148],
							[25.411972, 34.884813],
							[25.414173, 34.884797],
							[25.417396, 34.884797],
							[25.423256, 34.884911],
							[25.431293, 34.885443],
							[25.439081, 34.886379],
							[25.445237, 34.886747],
							[25.451065, 34.885236],
							[25.458882, 34.883609],
							[25.466811, 34.882406],
							[25.474818, 34.881633],
							[25.481246, 34.881326],
							[25.484098, 34.880944],
							[25.492105, 34.880169],
							[25.500156, 34.879828],
							[25.502464, 34.87981],
							[25.503343, 34.87981],
							[25.509095, 34.87992],
							[25.517132, 34.880446],
							[25.52511, 34.881403],
							[25.532995, 34.882787],
							[25.540163, 34.88444],
							[25.543097, 34.885177],
							[25.547013, 34.886263],
							[25.552454, 34.88637],
							[25.558333, 34.886753],
							[25.557786, 34.882934],
							[25.557366, 34.8763],
							[25.557343, 34.8742],
							[25.557347, 34.87156],
							[25.557478, 34.867018],
							[25.558112, 34.860394],
							[25.55927, 34.85382],
							[25.560947, 34.847322],
							[25.563136, 34.840928],
							[25.565827, 34.834665],
							[25.569008, 34.828561],
							[25.572666, 34.822643],
							[25.576785, 34.816933],
							[25.581348, 34.811458],
							[25.585585, 34.806981],
							[25.585879, 34.806688],
							[25.586628, 34.805948],
							[25.592017, 34.80101],
							[25.597784, 34.796373],
							[25.599237, 34.795297],
							[25.600132, 34.794617],
							[25.606254, 34.790301],
							[25.612704, 34.786324],
							[25.619456, 34.782703],
							[25.626479, 34.779455],
							[25.633744, 34.776591],
							[25.64122, 34.774125],
							[25.648876, 34.772068],
							[25.65214, 34.771327],
							[25.655645, 34.770371],
							[25.658724, 34.769532],
							[25.664698, 34.767964],
							[25.672499, 34.766323],
							[25.680413, 34.765105],
							[25.688406, 34.764317],
							[25.696445, 34.763961],
							[25.699011, 34.763939],
							[25.701647, 34.763938],
							[25.704593, 34.763967],
							[25.70494, 34.763961],
							[25.707527, 34.763938],
							[25.709285, 34.763938],
							[25.714748, 34.764038],
							[25.722775, 34.76455],
							[25.730743, 34.765492],
							[25.738621, 34.766863],
							[25.746373, 34.768655],
							[25.749606, 34.769538],
							[25.756872, 34.771639],
							[25.757801, 34.771878],
							[25.763455, 34.773476],
							[25.770402, 34.7751],
							[25.773066, 34.775873],
							[25.77349, 34.775947],
							[25.781244, 34.777736],
							[25.78884, 34.77994],
							[25.796246, 34.782548],
							[25.79643, 34.78262],
							[25.798588, 34.783428],
							[25.805771, 34.78643],
							[25.809762, 34.788316],
							[25.810349, 34.788606],
							[25.813289, 34.790103],
							[25.819938, 34.793852],
							[25.826276, 34.797951],
							[25.832275, 34.802383],
							[25.837912, 34.807128],
							[25.84316, 34.812168],
							[25.847999, 34.817481],
							[25.852406, 34.823042],
							[25.856363, 34.828829],
							[25.859854, 34.834817],
							[25.862863, 34.840981],
							[25.865376, 34.847293],
							[25.867384, 34.853729],
							[25.868877, 34.860256],
							[25.86985, 34.866853],
							[25.870296, 34.873486],
							[25.870328, 34.875656],
							[25.870331, 34.877418],
							[25.870219, 34.881892],
							[25.869611, 34.888519],
							[25.868479, 34.895097],
							[25.866826, 34.9016],
							[25.864661, 34.908],
							[25.8645, 34.908377],
							[25.866949, 34.909236],
							[25.874147, 34.912234],
							[25.875939, 34.913058],
							[25.878869, 34.913959],
							[25.88629, 34.916562],
							[25.886818, 34.916765],
							[25.890304, 34.917622],
							[25.895645, 34.919114],
							[25.900116, 34.919939],
							[25.907887, 34.92172],
							[25.915501, 34.923915],
							[25.921698, 34.926054],
							[25.925021, 34.926679],
							[25.932794, 34.928458],
							[25.936532, 34.929479],
							[25.937161, 34.929572],
							[25.941514, 34.929187],
							[25.943883, 34.929077],
							[25.95009, 34.926615],
							[25.954535, 34.925081],
							[25.95933, 34.923541],
							[25.964547, 34.922077],
							[25.970215, 34.920583],
							[25.977304, 34.919055],
							[25.9816, 34.917682],
							[25.989261, 34.915602],
							[25.99707, 34.913939],
							[26.001446, 34.913252],
							[26.003087, 34.906745],
							[26.005241, 34.900343],
							[26.007508, 34.894993],
							[26.008093, 34.892671],
							[26.010247, 34.886268],
							[26.012903, 34.879995],
							[26.016051, 34.873879],
							[26.019678, 34.867945],
							[26.023766, 34.862218],
							[26.0283, 34.856725],
							[26.033076, 34.851669],
							[26.033372, 34.851376],
							[26.033555, 34.851194],
							[26.038919, 34.846235],
							[26.044664, 34.841575],
							[26.050766, 34.837235],
							[26.057198, 34.833233],
							[26.063933, 34.829585],
							[26.070942, 34.826309],
							[26.078196, 34.823417],
							[26.085663, 34.820922],
							[26.093312, 34.818834],
							[26.101109, 34.817163],
							[26.109022, 34.815915],
							[26.117017, 34.815097],
							[26.125059, 34.814711],
							[26.128195, 34.814678],
							[26.130831, 34.814678],
							[26.135752, 34.814758],
							[26.143787, 34.81524],
							[26.151766, 34.816153],
							[26.159657, 34.817495],
							[26.167425, 34.819258],
							[26.175036, 34.821436],
							[26.182459, 34.82402],
							[26.189662, 34.826998],
							[26.196613, 34.830358],
							[26.203284, 34.834085],
							[26.209646, 34.838163],
							[26.215671, 34.842576],
							[26.221335, 34.847303],
							[26.226146, 34.851856],
							[26.226438, 34.852149],
							[26.226903, 34.85262],
							[26.228129, 34.853954],
							[26.230472, 34.855455],
							[26.232904, 34.857166],
							[26.233403, 34.857505],
							[26.239431, 34.861917],
							[26.245097, 34.866643],
							[26.250376, 34.871665],
							[26.255246, 34.87696],
							[26.259686, 34.882507],
							[26.263678, 34.88828],
							[26.267203, 34.894257],
							[26.270246, 34.90041],
							[26.272795, 34.906714],
							[26.274838, 34.913142],
							[26.275756, 34.916777],
							[26.277254, 34.923187],
							[26.278262, 34.929779],
							[26.278364, 34.931183],
							[26.279116, 34.931397],
							[26.286553, 34.933974],
							[26.29377, 34.936946],
							[26.300737, 34.9403],
							[26.307422, 34.944021],
							[26.313798, 34.948094],
							[26.319838, 34.952502],
							[26.325515, 34.957225],
							[26.330806, 34.962243],
							[26.335688, 34.967536],
							[26.340139, 34.973079],
							[26.344141, 34.978851],
							[26.347676, 34.984825],
							[26.349198, 34.987748],
							[26.34949, 34.988336],
							[26.351022, 34.991564],
							[26.35358, 34.997867],
							[26.355632, 35.004294],
							[26.357169, 35.010817],
							[26.357507, 35.01301],
							[26.360477, 35.015901],
							[26.363369, 35.018946],
							[26.367521, 35.022933],
							[26.372409, 35.028224],
							[26.376866, 35.033767],
							[26.380874, 35.039537],
							[26.384414, 35.04551],
							[26.385965, 35.048486],
							[26.386258, 35.049073],
							[26.387766, 35.052249],
							[26.390329, 35.058551],
							[26.392386, 35.064978],
							[26.392707, 35.066167],
							[26.394123, 35.068675],
							[26.397184, 35.074826],
							[26.398979, 35.079088],
							[26.400338, 35.082596],
							[26.402396, 35.089023],
							[26.403939, 35.095546],
							[26.40496, 35.102139],
							[26.405443, 35.108488],
							[26.409758, 35.112577],
							[26.410932, 35.113751],
							[26.411309, 35.11413],
							[26.416206, 35.11942],
							[26.420671, 35.124961],
							[26.424653, 35.130678],
							[26.42645, 35.133368],
							[26.429998, 35.139341],
							[26.433064, 35.145491],
							[26.435634, 35.151793],
							[26.437697, 35.158219],
							[26.439244, 35.164742],
							[26.440269, 35.171335],
							[26.440766, 35.177968],
							[26.440817, 35.180753],
							[26.440818, 35.181278],
							[26.441168, 35.183946],
							[26.441665, 35.190579],
							[26.441716, 35.193369],
							[26.441723, 35.197477],
							[26.441688, 35.199094],
							[26.442456, 35.199729],
							[26.447682, 35.204653],
							[26.447974, 35.204947],
							[26.448063, 35.205036],
							[26.452967, 35.210324],
							[26.457441, 35.215865],
							[26.461464, 35.221633],
							[26.465019, 35.227605],
							[26.46809, 35.233754],
							[26.470666, 35.240056],
							[26.472734, 35.246482],
							[26.474286, 35.253005],
							[26.475314, 35.259597],
							[26.475815, 35.266231],
							[26.475867, 35.269051],
							[26.475872, 35.271691],
							[26.47579, 35.275517],
							[26.475231, 35.282148],
							[26.474143, 35.288734],
							[26.472533, 35.295248],
							[26.470406, 35.301662],
							[26.467771, 35.307947],
							[26.46464, 35.314078],
							[26.461026, 35.320028],
							[26.456945, 35.325772],
							[26.452413, 35.331284],
							[26.450346, 35.333472],
							[26.477086, 35.344065],
							[26.503132, 35.354381],
							[26.534766, 35.366909],
							[26.56738, 35.379823],
							[26.595859, 35.391098],
							[26.622648, 35.401702],
							[26.646271, 35.411052],
							[26.675515, 35.422625],
							[26.708782, 35.435789],
							[26.708792, 35.429142],
							[26.709332, 35.42251],
							[26.710402, 35.415921],
							[26.711995, 35.409403],
							[26.714105, 35.402984],
							[26.716723, 35.396692],
							[26.718469, 35.393114],
							[26.720721, 35.388793],
							[26.723532, 35.384136],
							[26.722903, 35.382206],
							[26.721328, 35.375685],
							[26.720277, 35.369094],
							[26.719755, 35.362461],
							[26.719693, 35.359102],
							[26.719694, 35.358224],
							[26.719765, 35.354937],
							[26.720304, 35.348304],
							[26.721371, 35.341715],
							[26.722962, 35.335198],
							[26.725069, 35.328779],
							[26.727544, 35.322793],
							[26.72919, 35.317927],
							[26.731804, 35.311635],
							[26.734914, 35.305496],
							[26.738506, 35.299538],
							[26.742566, 35.293784],
							[26.747075, 35.288261],
							[26.752014, 35.28299],
							[26.757362, 35.277996],
							[26.763097, 35.273299],
							[26.769193, 35.268919],
							[26.775625, 35.264875],
							[26.782365, 35.261185],
							[26.783881, 35.260425],
							[26.784468, 35.260135],
							[26.789971, 35.257574],
							[26.79724, 35.254636],
							[26.804726, 35.252093],
							[26.812399, 35.249957],
							[26.81345, 35.2497],
							[26.818047, 35.247897],
							[26.825533, 35.245353],
							[26.833204, 35.243215],
							[26.84103, 35.241494],
							[26.848975, 35.240195],
							[26.857007, 35.239325],
							[26.864618, 35.238901],
							[26.869312, 35.238445],
							[26.877396, 35.238006],
							[26.881537, 35.23795],
							[26.884174, 35.23795],
							[26.888134, 35.238002],
							[26.896218, 35.238431],
							[26.904251, 35.239293],
							[26.912199, 35.240583],
							[26.920027, 35.242296],
							[26.927702, 35.244426],
							[26.935191, 35.246962],
							[26.942463, 35.249894],
							[26.949486, 35.253209],
							[26.949637, 35.253287],
							[26.951243, 35.254089],
							[26.957987, 35.257774],
							[26.964424, 35.261812],
							[26.970526, 35.266187],
							[26.973112, 35.268226],
							[26.976938, 35.269342],
							[26.984432, 35.271875],
							[26.991707, 35.274804],
							[26.998735, 35.278117],
							[27.005483, 35.281799],
							[27.011925, 35.285834],
							[27.018031, 35.290206],
							[27.023776, 35.294896],
							[27.029135, 35.299884],
							[27.030911, 35.301692],
							[27.032459, 35.302355],
							[27.039491, 35.305665],
							[27.042876, 35.307445],
							[27.045034, 35.308455],
							[27.04653, 35.309095],
							[27.053563, 35.312405],
							[27.059487, 35.315632],
							[27.062466, 35.313748],
							[27.069197, 35.310041],
							[27.076209, 35.306702],
							[27.083471, 35.303745],
							[27.090953, 35.301184],
							[27.098624, 35.299029],
							[27.106449, 35.297289],
							[27.114396, 35.295972],
							[27.122431, 35.295083],
							[27.130519, 35.294627],
							[27.134992, 35.294561],
							[27.137335, 35.294561],
							[27.14097, 35.294604],
							[27.149062, 35.295016],
							[27.157104, 35.29586],
							[27.165062, 35.297133],
							[27.172901, 35.29883],
							[27.175309, 35.299442],
							[27.176418, 35.29971],
							[27.184107, 35.301822],
							[27.188788, 35.303339],
							[27.194247, 35.305218],
							[27.201534, 35.308134],
							[27.208574, 35.311434],
							[27.215335, 35.315104],
							[27.22179, 35.319128],
							[27.227911, 35.323489],
							[27.233672, 35.328169],
							[27.239047, 35.333147],
							[27.239378, 35.333476],
							[27.245114, 35.338135],
							[27.25049, 35.343113],
							[27.250856, 35.343477],
							[27.251444, 35.344064],
							[27.251696, 35.344316],
							[27.252847, 35.34518],
							[27.25861, 35.349858],
							[27.263988, 35.354835],
							[27.264344, 35.355188],
							[27.266399, 35.357239],
							[27.271014, 35.36214],
							[27.275555, 35.367649],
							[27.279647, 35.37339],
							[27.283272, 35.379338],
							[27.284347, 35.38131],
							[27.288911, 35.383186],
							[27.295961, 35.386481],
							[27.302733, 35.390146],
							[27.309199, 35.394166],
							[27.315331, 35.398523],
							[27.321102, 35.403198],
							[27.326488, 35.408173],
							[27.326972, 35.408653],
							[27.327266, 35.408947],
							[27.33176, 35.413718],
							[27.336308, 35.419225],
							[27.340408, 35.424964],
							[27.34404, 35.43091],
							[27.34719, 35.437037],
							[27.348268, 35.43943],
							[27.352163, 35.4449],
							[27.355797, 35.450845],
							[27.358949, 35.456972],
							[27.361605, 35.463255],
							[27.363753, 35.469666],
							[27.365384, 35.476179],
							[27.366492, 35.482764],
							[27.36707, 35.489395],
							[27.367162, 35.493174],
							[27.367168, 35.496695],
							[27.367123, 35.499564],
							[27.366638, 35.5062],
							[27.365624, 35.512796],
							[27.364083, 35.519324],
							[27.362024, 35.525755],
							[27.359454, 35.532062],
							[27.356384, 35.538219],
							[27.352828, 35.544197],
							[27.351081, 35.546811],
							[27.348726, 35.550656],
							[27.344698, 35.556432],
							[27.343538, 35.557946],
							[27.343106, 35.560345],
							[27.341562, 35.566872],
							[27.3395, 35.573303],
							[27.338804, 35.575148],
							[27.338623, 35.57565],
							[27.33605, 35.581957],
							[27.334155, 35.585886],
							[27.332097, 35.589876],
							[27.328537, 35.595854],
							[27.324532, 35.60159],
							[27.322, 35.607789],
							[27.320121, 35.611684],
							[27.318047, 35.615704],
							[27.314484, 35.621682],
							[27.31045, 35.627457],
							[27.305961, 35.633003],
							[27.303062, 35.636206],
							[27.305941, 35.640988],
							[27.309096, 35.647117],
							[27.311754, 35.653401],
							[27.313903, 35.659814],
							[27.314385, 35.661525],
							[27.314615, 35.661756],
							[27.3189, 35.666306],
							[27.323462, 35.671814],
							[27.327574, 35.677554],
							[27.329641, 35.680808],
							[27.332688, 35.685846],
							[27.335847, 35.691974],
							[27.338509, 35.698258],
							[27.340662, 35.70467],
							[27.342296, 35.711183],
							[27.343404, 35.71777],
							[27.343982, 35.724402],
							[27.344073, 35.728152],
							[27.344077, 35.730791],
							[27.344044, 35.733286],
							[27.344039, 35.738676],
							[27.343551, 35.745312],
							[27.342531, 35.751908],
							[27.342507, 35.752032],
							[27.344553, 35.755355],
							[27.34628, 35.758292],
							[27.349444, 35.764421],
							[27.352109, 35.770704],
							[27.354265, 35.777116],
							[27.355901, 35.78363],
							[27.357012, 35.790216],
							[27.357548, 35.79634],
							[27.359487, 35.800909],
							[27.361644, 35.807321],
							[27.363282, 35.813835],
							[27.364394, 35.820421],
							[27.364974, 35.827053],
							[27.365066, 35.830834],
							[27.365069, 35.832303],
							[27.36506, 35.833655],
							[27.365299, 35.835382],
							[27.36588, 35.842014],
							[27.365972, 35.845787],
							[27.365981, 35.851654],
							[27.365936, 35.854529],
							[27.365448, 35.861166],
							[27.364428, 35.867763],
							[27.363686, 35.871182],
							[27.363554, 35.871872],
							[27.363002, 35.874473],
							[27.362937, 35.874981],
							[27.36427, 35.880427],
							[27.365383, 35.887014],
							[27.365963, 35.893646],
							[27.366056, 35.897414],
							[27.366059, 35.899172],
							[27.366013, 35.902053],
							[27.365525, 35.90869],
							[27.364505, 35.915287],
							[27.362956, 35.921815],
							[27.360885, 35.928247],
							[27.358301, 35.934555],
							[27.355215, 35.940712],
							[27.353921, 35.942993],
							[27.350468, 35.948749],
							[27.346419, 35.954525],
							[27.341913, 35.960074],
							[27.340937, 35.961119],
							[27.406398, 35.99042],
							[27.462959, 36.015729],
							[27.530739, 36.046048],
							[27.59507, 36.074812],
							[27.596065, 36.072854],
							[27.599627, 36.066867],
							[27.603664, 36.061082],
							[27.608157, 36.055523],
							[27.613087, 36.050214],
							[27.613383, 36.049918],
							[27.613677, 36.049624],
							[27.618728, 36.044885],
							[27.624469, 36.040144],
							[27.630578, 36.035718],
							[27.630821, 36.035564],
							[27.630449, 36.034056],
							[27.630412, 36.033871],
							[27.629693, 36.032416],
							[27.626997, 36.026138],
							[27.626553, 36.024959],
							[27.626538, 36.024929],
							[27.625306, 36.022436],
							[27.623895, 36.019314],
							[27.623844, 36.019207],
							[27.621747, 36.014444],
							[27.621212, 36.013338],
							[27.619803, 36.01022],
							[27.619749, 36.010109],
							[27.617054, 36.003831],
							[27.61692, 36.003479],
							[27.615108, 35.999942],
							[27.613899, 35.997492],
							[27.611204, 35.991213],
							[27.610385, 35.988992],
							[27.608608, 35.986004],
							[27.605415, 35.979882],
							[27.602722, 35.973604],
							[27.60054, 35.967195],
							[27.598879, 35.960685],
							[27.597745, 35.9541],
							[27.597144, 35.947469],
							[27.597039, 35.943192],
							[27.597042, 35.941723],
							[27.597079, 35.939352],
							[27.597547, 35.932714],
							[27.598548, 35.926115],
							[27.600077, 35.919583],
							[27.602128, 35.913147],
							[27.604691, 35.906834],
							[27.606747, 35.902576],
							[27.608637, 35.898912],
							[27.611155, 35.894571],
							[27.613014, 35.888809],
							[27.615576, 35.882496],
							[27.61768, 35.878142],
							[27.619522, 35.874574],
							[27.623073, 35.868586],
							[27.626879, 35.863094],
							[27.627098, 35.861903],
							[27.628624, 35.855371],
							[27.63067, 35.848934],
							[27.633229, 35.84262],
							[27.634869, 35.839173],
							[27.635164, 35.838586],
							[27.636584, 35.835869],
							[27.640132, 35.82988],
							[27.644152, 35.824094],
							[27.648629, 35.818534],
							[27.653541, 35.813224],
							[27.658869, 35.808186],
							[27.664589, 35.803443],
							[27.670677, 35.799015],
							[27.677107, 35.79492],
							[27.683851, 35.791177],
							[27.69088, 35.7878],
							[27.698165, 35.784804],
							[27.705674, 35.782203],
							[27.713376, 35.780007],
							[27.721237, 35.778225],
							[27.729224, 35.776866],
							[27.737302, 35.775935],
							[27.742816, 35.775549],
							[27.74492, 35.775349],
							[27.753056, 35.774849],
							[27.758361, 35.774756],
							[27.761584, 35.774756],
							[27.764437, 35.774783],
							[27.772583, 35.775151],
							[27.780683, 35.775953],
							[27.788701, 35.777183],
							[27.795794, 35.778648],
							[27.79924, 35.779425],
							[27.806993, 35.781497],
							[27.814564, 35.783977],
							[27.82192, 35.786855],
							[27.82903, 35.790118],
							[27.829589, 35.790397],
							[27.833368, 35.791462],
							[27.84094, 35.79394],
							[27.843232, 35.794785],
							[27.84856, 35.796577],
							[27.855919, 35.799453],
							[27.86294, 35.802669],
							[27.863527, 35.802958],
							[27.863619, 35.803003],
							[27.870455, 35.806636],
							[27.876985, 35.810625],
							[27.883182, 35.814953],
							[27.883868, 35.815469],
							[27.889368, 35.818403],
							[27.896043, 35.820607],
							[27.903406, 35.82348],
							[27.910522, 35.826738],
							[27.917363, 35.830368],
							[27.923898, 35.834354],
							[27.930099, 35.83868],
							[27.935941, 35.843326],
							[27.94083, 35.847727],
							[27.942376, 35.848719],
							[27.94858, 35.853044],
							[27.954423, 35.857689],
							[27.959882, 35.862635],
							[27.961359, 35.864092],
							[27.96165, 35.864385],
							[27.965224, 35.868155],
							[27.969845, 35.873638],
							[27.974015, 35.879356],
							[27.976951, 35.883974],
							[27.980067, 35.886384],
							[27.981534, 35.887557],
							[27.984063, 35.88964],
							[27.989526, 35.894585],
							[27.99458, 35.89981],
							[27.999205, 35.905293],
							[28.003379, 35.911009],
							[28.007085, 35.916935],
							[28.010308, 35.923046],
							[28.012127, 35.927082],
							[28.012599, 35.927954],
							[28.014436, 35.931008],
							[28.016391, 35.93458],
							[28.01901, 35.938782],
							[28.020314, 35.940978],
							[28.021064, 35.942305],
							[28.024429, 35.947718],
							[28.025227, 35.949132],
							[28.025622, 35.949776],
							[28.028359, 35.953519],
							[28.031995, 35.959318],
							[28.033707, 35.960143],
							[28.033869, 35.960228],
							[28.039293, 35.958335],
							[28.047003, 35.956117],
							[28.054874, 35.954313],
							[28.062873, 35.952931],
							[28.070967, 35.951977],
							[28.079119, 35.951454],
							[28.084866, 35.951347],
							[28.087503, 35.951347],
							[28.089933, 35.951366],
							[28.098099, 35.951712],
							[28.106221, 35.95249],
							[28.114263, 35.953699],
							[28.12219, 35.955332],
							[28.12997, 35.957383],
							[28.137569, 35.959842],
							[28.144954, 35.9627],
							[28.152094, 35.965944],
							[28.158958, 35.96956],
							[28.165517, 35.973533],
							[28.171744, 35.977846],
							[28.17761, 35.98248],
							[28.183092, 35.987416],
							[28.184551, 35.988845],
							[28.184845, 35.989138],
							[28.18846, 35.992926],
							[28.193104, 35.998401],
							[28.197297, 36.004111],
							[28.201023, 36.010031],
							[28.204265, 36.016137],
							[28.207009, 36.022402],
							[28.209243, 36.028799],
							[28.210957, 36.035301],
							[28.212144, 36.04188],
							[28.212799, 36.048508],
							[28.21294, 36.053192],
							[28.212941, 36.054059],
							[28.213685, 36.056371],
							[28.21461, 36.059619],
							[28.215464, 36.061062],
							[28.218709, 36.067167],
							[28.221456, 36.073432],
							[28.223692, 36.079828],
							[28.225409, 36.08633],
							[28.226598, 36.092909],
							[28.227255, 36.099538],
							[28.227376, 36.106187],
							[28.226969, 36.112757],
							[28.227591, 36.113277],
							[28.233085, 36.118211],
							[28.234735, 36.119827],
							[28.235909, 36.121001],
							[28.239345, 36.124599],
							[28.244, 36.130073],
							[28.248204, 36.135781],
							[28.249332, 36.13747],
							[28.249379, 36.137538],
							[28.252238, 36.141979],
							[28.254585, 36.145806],
							[28.257836, 36.15191],
							[28.260589, 36.158174],
							[28.262831, 36.16457],
							[28.263973, 36.168638],
							[28.264126, 36.169023],
							[28.266369, 36.175419],
							[28.267306, 36.1787],
							[28.268033, 36.179678],
							[28.270682, 36.182133],
							[28.272343, 36.183758],
							[28.272931, 36.184345],
							[28.276362, 36.187933],
							[28.281024, 36.193405],
							[28.285234, 36.199112],
							[28.288976, 36.20503],
							[28.292177, 36.211019],
							[28.293114, 36.212891],
							[28.295872, 36.219154],
							[28.298119, 36.22555],
							[28.299605, 36.231008],
							[28.300436, 36.234396],
							[28.301634, 36.240974],
							[28.302299, 36.247602],
							[28.302364, 36.248848],
							[28.303662, 36.249921],
							[28.309171, 36.254852],
							[28.310968, 36.256609],
							[28.313021, 36.258663],
							[28.316325, 36.262118],
							[28.320994, 36.267588],
							[28.325211, 36.273293],
							[28.32896, 36.27921],
							[28.332223, 36.285312],
							[28.334627, 36.290681],
							[28.33487, 36.291177],
							[28.337635, 36.297439],
							[28.339887, 36.303834],
							[28.341618, 36.310334],
							[28.342821, 36.316912],
							[28.342925, 36.31767],
							[28.343073, 36.317831],
							[28.347747, 36.3233],
							[28.351572, 36.32843],
							[28.35362, 36.33092],
							[28.357844, 36.336625],
							[28.361598, 36.342541],
							[28.364866, 36.348642],
							[28.367635, 36.354904],
							[28.367799, 36.35532],
							[28.368224, 36.356372],
							[28.370481, 36.362766],
							[28.372216, 36.369266],
							[28.373423, 36.375844],
							[28.374094, 36.382472],
							[28.374229, 36.389121],
							[28.373825, 36.395762],
							[28.372884, 36.402369],
							[28.371411, 36.408911],
							[28.369411, 36.415361],
							[28.366894, 36.421692],
							[28.366561, 36.422372],
							[28.366829, 36.425014],
							[28.366962, 36.431663],
							[28.366897, 36.433667],
							[28.366992, 36.437491],
							[28.366995, 36.439249],
							[28.366978, 36.441051],
							[28.366573, 36.447692],
							[28.365631, 36.454299],
							[28.364156, 36.460841],
							[28.363635, 36.462703],
							[28.363282, 36.464069],
							[28.36128, 36.470519],
							[28.35876, 36.47685],
							[28.356619, 36.48134],
							[28.354855, 36.484797],
							[28.351332, 36.490808],
							[28.347329, 36.49662],
							[28.342863, 36.502207],
							[28.337954, 36.507547],
							[28.336861, 36.508642],
							[28.336567, 36.508932],
							[28.332328, 36.512906],
							[28.328856, 36.515872],
							[28.32703, 36.51806],
							[28.322118, 36.523399],
							[28.321818, 36.523703],
							[28.321526, 36.523997],
							[28.316492, 36.528761],
							[28.310758, 36.533536],
							[28.304648, 36.537998],
							[28.303611, 36.538661],
							[28.298189, 36.542128],
							[28.291408, 36.545908],
							[28.288811, 36.54716],
							[28.284335, 36.549321],
							[28.282708, 36.549934],
							[28.277, 36.552353],
							[28.269434, 36.554991],
							[28.261669, 36.557224],
							[28.253741, 36.559042],
							[28.245681, 36.560437],
							[28.237525, 36.561403],
							[28.229309, 36.561936],
							[28.221066, 36.562034],
							[28.212834, 36.561696],
							[28.209405, 36.561373],
							[28.12543, 36.525125],
							[28.002097, 36.46959],
							[27.960677, 36.470373],
							[27.930917, 36.537333],
							[27.918692, 36.592535],
							[27.907992, 36.640854],
							[27.895497, 36.697277],
							[27.813951, 36.708633],
							[27.774831, 36.69349],
							[27.728834, 36.647493],
							[27.725615, 36.644274],
							[27.641671, 36.574322],
							[27.499735, 36.545406],
							[27.493237, 36.544282],
							[27.485094, 36.543274],
							[27.476886, 36.542697],
							[27.46865, 36.542554],
							[27.462142, 36.542785],
							[27.351478, 36.586101],
							[27.298108, 36.633572],
							[27.272613, 36.67054],
							[27.250234, 36.70299],
							[27.247118, 36.725991],
							[27.35101, 36.807575],
							[27.367524, 36.83157],
							[27.384791, 36.856653],
							[27.393714, 36.905093],
							[27.351647, 36.918478],
							[27.324878, 36.921028],
							[27.287273, 36.938874],
							[27.245762, 36.942682],
							[27.244664, 36.946503],
							[27.243659, 36.949996],
							[27.237022, 36.97308],
							[27.213539, 36.973102],
							[27.176885, 36.983388],
							[27.158591, 36.995982],
							[27.156232, 36.997548],
							[27.162766, 37.011714],
							[27.146522, 37.040734],
							[27.173598, 37.047511],
							[27.17367, 37.051642],
							[27.140922, 37.061455],
							[27.142861, 37.07189],
							[27.160212, 37.165167],
							[27.160127, 37.189324],
							[27.159526, 37.200794],
							[27.158882, 37.213201],
							[27.159107, 37.219754],
							[27.159086, 37.227025],
							[27.158828, 37.234354],
							[27.158742, 37.24176],
							[27.159363, 37.242622],
							[27.159103, 37.251215],
							[27.158775, 37.262032],
							[27.158448, 37.265195],
							[27.1577, 37.272425],
							[27.156962, 37.279563],
							[27.156215, 37.286784],
							[27.155714, 37.291624],
							[27.153627, 37.311796],
							[27.131717, 37.383461],
							[27.109012, 37.457656],
							[27.081437, 37.511099],
							[27.0541, 37.564047],
							[27.040397, 37.576252],
							[26.991131, 37.620117],
							[26.958483, 37.64917],
							[26.976679, 37.676349],
							[27.008265, 37.696726],
							[27.086704, 37.69961],
							[27.164928, 37.724056],
							[27.160491, 37.790558],
							[27.100962, 37.846013],
							[27.068188, 37.886451],
							[27.053962, 37.913859],
							[26.989205, 37.938252],
							[26.918742, 37.964784],
							[26.835893, 37.983938],
							[26.758406, 38.001847],
							[26.674648, 38.021201],
							[26.589414, 38.040891],
							[26.587574, 38.041325],
							[26.524817, 38.056463],
							[26.466413, 38.070548],
							[26.363204, 38.095433],
							[26.359349, 38.096411],
							[26.351472, 38.098746],
							[26.343805, 38.101484],
							[26.33638, 38.104611],
							[26.329229, 38.108115],
							[26.326705, 38.109479],
							[26.274371, 38.138419],
							[26.221702, 38.167533],
							[26.208755, 38.2029],
							[26.194618, 38.241496],
							[26.223012, 38.311061],
							[26.213074, 38.383465],
							[26.242887, 38.445932],
							[26.315292, 38.45303],
							[26.320971, 38.498461],
							[26.312452, 38.536792],
							[26.2798, 38.573704],
							[26.250277, 38.607077],
							[26.215782, 38.651549],
							[26.21591, 38.656517],
							[26.216164, 38.662198],
							[26.216974, 38.668815],
							[26.218335, 38.675377],
							[26.22024, 38.681855],
							[26.222682, 38.688221],
							[26.225651, 38.694448],
							[26.229134, 38.70051],
							[26.233116, 38.70638],
							[26.237579, 38.712034],
							[26.242506, 38.717446],
							[26.247875, 38.722594],
							[26.253663, 38.727456],
							[26.259846, 38.73201],
							[26.266396, 38.736237],
							[26.273286, 38.74012],
							[26.280486, 38.743641],
							[26.287965, 38.746785],
							[26.295691, 38.749538],
							[26.303632, 38.75189],
							[26.311752, 38.753829],
							[26.320017, 38.755348],
							[26.323876, 38.755906],
							[26.45123, 38.772686],
							[26.527567, 38.789311],
							[26.618532, 38.809116],
							[26.632137, 38.857967],
							[26.646563, 38.90973],
							[26.660325, 38.92807],
							[26.669872, 38.940792],
							[26.680382, 38.954794],
							[26.690251, 38.967938],
							[26.699302, 38.979992],
							[26.700719, 38.993691],
							[26.701974, 39.005827],
							[26.703444, 39.020031],
							[26.704688, 39.032057],
							[26.700288, 39.045006],
							[26.695817, 39.058161],
							[26.69122, 39.071684],
							[26.685448, 39.088657],
							[26.679882, 39.105024],
							[26.674168, 39.121822],
							[26.665233, 39.131858],
							[26.655787, 39.142465],
							[26.646751, 39.152611],
							[26.637706, 39.162766],
							[26.629429, 39.172057],
							[26.620383, 39.182209],
							[26.611163, 39.192556],
							[26.603563, 39.201083],
							[26.594261, 39.21152],
							[26.586197, 39.220565],
							[26.578289, 39.231524],
							[26.571432, 39.241023],
							[26.563886, 39.251478],
							[26.55753, 39.260282],
							[26.550795, 39.269609],
							[26.545172, 39.277395],
							[26.54215, 39.28158],
							[26.538779, 39.286248],
							[26.535621, 39.290619],
							[26.532548, 39.294874],
							[26.529698, 39.29882],
							[26.526429, 39.303345],
							[26.522759, 39.308424],
							[26.519011, 39.313612],
							[26.516431, 39.317183],
							[26.512425, 39.322727],
							[26.503388, 39.335231],
							[26.493911, 39.348341],
							[26.484167, 39.36182],
							[26.47426, 39.375519],
							[26.464172, 39.389468],
							[26.454652, 39.402628],
							[26.446907, 39.413333],
							[26.438285, 39.425247],
							[26.4318, 39.434207],
							[26.415699, 39.432607],
							[26.397143, 39.430763],
							[26.377733, 39.428835],
							[26.356798, 39.426755],
							[26.337829, 39.424869],
							[26.305765, 39.421683],
							[26.274303, 39.418557],
							[26.254187, 39.416557],
							[26.222153, 39.413374],
							[26.201115, 39.411283],
							[26.174007, 39.408588],
							[26.142755, 39.405482],
							[26.113025, 39.403765],
							[26.091923, 39.402546],
							[26.067946, 39.401161],
							[26.042936, 39.399716],
							[26.020341, 39.398411],
							[25.997895, 39.397114],
							[25.981732, 39.39618],
							[25.970951, 39.404632],
							[25.965531, 39.40888],
							[25.960561, 39.412994],
							[25.95509, 39.418109],
							[25.950062, 39.423491],
							[25.945497, 39.429116],
							[25.941416, 39.434961],
							[25.937836, 39.440999],
							[25.934773, 39.447207],
							[25.932239, 39.453556],
							[25.930245, 39.46002],
							[25.928801, 39.466571],
							[25.927913, 39.473182],
							[25.927585, 39.479823],
							[25.927583, 39.480612],
							[25.927734, 39.534112],
							[25.9279, 39.593097],
							[25.928099, 39.663653],
							[25.928242, 39.714352],
							[25.910907, 39.788523],
							[25.892637, 39.841675],
							[25.890922, 39.8473],
							[25.889467, 39.85385],
							[25.888571, 39.860459],
							[25.888237, 39.867098],
							[25.888467, 39.873741],
							[25.889261, 39.880358],
							[25.890615, 39.88692],
							[25.891613, 39.890548],
							[25.757572, 39.944193],
							[25.753214, 39.946014],
							[25.745849, 39.94948],
							[25.738793, 39.95331],
							[25.732077, 39.957485],
							[25.72573, 39.96199],
							[25.719778, 39.966804],
							[25.714247, 39.971907],
							[25.709161, 39.977277],
							[25.704542, 39.982892],
							[25.700409, 39.988726],
							[25.698335, 39.992046],
							[25.667064, 40.044432],
							[25.633362, 40.100846],
							[25.631803, 40.103555],
							[25.628684, 40.109753],
							[25.626099, 40.116094],
							[25.62406, 40.122551],
							[25.622574, 40.129096],
							[25.621649, 40.135702],
							[25.621289, 40.14234],
							[25.621496, 40.148982],
							[25.622268, 40.1556],
							[25.623603, 40.162164],
							[25.625494, 40.168647],
							[25.627935, 40.175022],
							[25.630915, 40.18126],
							[25.634421, 40.187336],
							[25.638438, 40.193222],
							[25.642949, 40.198894],
							[25.647935, 40.204327],
							[25.653374, 40.209499],
							[25.659244, 40.214386],
							[25.665519, 40.218968],
							[25.672172, 40.223226],
							[25.679176, 40.22714],
							[25.686498, 40.230694],
							[25.693721, 40.233723],
							[25.75421, 40.257126],
							[25.82235, 40.28348],
							[25.902095, 40.314309],
							[25.987641, 40.347365],
							[26.049914, 40.371418],
							[26.034249, 40.432139],
							[26.017322, 40.497691],
							[26.001106, 40.560427],
							[25.982484, 40.6324],
							[25.966396, 40.694518],
							[25.955178, 40.729474],
							[26.01331, 40.728417],
							[26.02611, 40.729549],
							[26.036273, 40.730199],
							[26.037735, 40.730166],
							[26.038523, 40.730177],
							[26.03951, 40.73019],
							[26.04202, 40.730207],
							[26.044872, 40.73016],
							[26.047356, 40.729987],
							[26.049389, 40.729817],
							[26.049818, 40.729763],
							[26.052467, 40.729426],
							[26.055864, 40.729066],
							[26.057023, 40.729008],
							[26.058888, 40.729061],
							[26.060391, 40.729253],
							[26.062107, 40.729343],
							[26.064275, 40.729182],
							[26.066163, 40.72885],
							[26.067866, 40.728376],
							[26.068996, 40.727963],
							[26.070433, 40.72757],
							[26.071696, 40.727418],
							[26.072933, 40.727388],
							[26.073597, 40.727447],
							[26.074268, 40.727562],
							[26.075419, 40.727809],
							[26.076352, 40.728046],
							[26.077708, 40.728487],
							[26.078852, 40.728907],
							[26.07964, 40.7292],
							[26.080244, 40.729388],
							[26.081039, 40.729624],
							[26.082056, 40.72987],
							[26.082655, 40.730044],
							[26.083175, 40.730219],
							[26.084278, 40.730674],
							[26.085595, 40.731389],
							[26.086366, 40.731974],
							[26.086739, 40.732468],
							[26.086809, 40.732683],
							[26.086805, 40.732881],
							[26.086672, 40.733264],
							[26.086433, 40.733818],
							[26.086415, 40.734037],
							[26.086433, 40.734232],
							[26.086606, 40.734564],
							[26.086959, 40.734793],
							[26.08743, 40.735086],
							[26.088209, 40.735435],
							[26.088746, 40.735589],
							[26.089423, 40.735728],
							[26.090075, 40.735804],
							[26.090635, 40.735822],
							[26.091426, 40.735773],
							[26.0933, 40.73562],
							[26.094005, 40.735574],
							[26.094555, 40.735565],
							[26.095156, 40.735618],
							[26.095879, 40.735691],
							[26.097233, 40.735925],
							[26.09802, 40.736094],
							[26.098678, 40.73625],
							[26.099324, 40.736447],
							[26.100204, 40.73679],
							[26.101199, 40.737204],
							[26.102141, 40.737648],
							[26.103643, 40.738394],
							[26.104209, 40.738597],
							[26.104707, 40.738742],
							[26.105181, 40.738825],
							[26.105575, 40.738837],
							[26.106136, 40.738809],
							[26.107002, 40.738673],
							[26.107481, 40.73863],
							[26.10797, 40.738605],
							[26.108399, 40.73861],
							[26.108956, 40.738594],
							[26.1096, 40.738654],
							[26.110313, 40.738739],
							[26.111397, 40.738928],
							[26.112395, 40.73917],
							[26.113635, 40.739561],
							[26.11473, 40.740003],
							[26.115487, 40.740345],
							[26.116206, 40.74072],
							[26.116917, 40.741071],
							[26.117584, 40.741327],
							[26.118232, 40.74154],
							[26.119124, 40.741763],
							[26.120007, 40.741931],
							[26.120874, 40.742033],
							[26.121392, 40.742064],
							[26.121761, 40.742047],
							[26.122198, 40.742032],
							[26.12279, 40.742004],
							[26.123188, 40.74205],
							[26.123572, 40.742113],
							[26.123986, 40.74221],
							[26.124439, 40.742368],
							[26.12471, 40.742525],
							[26.125003, 40.742727],
							[26.125217, 40.742951],
							[26.125356, 40.74322],
							[26.125408, 40.743398],
							[26.125458, 40.743725],
							[26.125389, 40.744742],
							[26.125348, 40.745142],
							[26.125348, 40.745671],
							[26.125376, 40.746234],
							[26.125421, 40.746567],
							[26.125529, 40.746978],
							[26.125802, 40.747763],
							[26.126239, 40.748719],
							[26.126441, 40.749218],
							[26.126648, 40.749614],
							[26.126852, 40.750183],
							[26.127265, 40.751796],
							[26.127699, 40.752872],
							[26.128371, 40.75461],
							[26.128469, 40.754886],
							[26.128484, 40.755133],
							[26.128415, 40.755496],
							[26.128301, 40.755854],
							[26.128161, 40.756207],
							[26.128104, 40.756686],
							[26.128105, 40.756953],
							[26.128086, 40.7571],
							[26.128056, 40.757328],
							[26.127993, 40.757748],
							[26.127966, 40.757833],
							[26.127909, 40.758011],
							[26.12769, 40.758401],
							[26.127551, 40.758718],
							[26.127493, 40.759006],
							[26.127494, 40.759359],
							[26.127541, 40.759718],
							[26.127654, 40.760242],
							[26.127728, 40.760536],
							[26.127929, 40.760914],
							[26.128157, 40.761252],
							[26.128422, 40.761663],
							[26.128587, 40.762078],
							[26.128752, 40.762952],
							[26.128789, 40.763579],
							[26.128775, 40.763979],
							[26.128709, 40.764381],
							[26.128451, 40.76536],
							[26.12827, 40.766652],
							[26.128187, 40.76773],
							[26.128062, 40.768528],
							[26.127611, 40.76947],
							[26.126782, 40.770398],
							[26.126865, 40.770725],
							[26.126876, 40.771221],
							[26.126952, 40.771452],
							[26.127056, 40.77171],
							[26.127199, 40.772251],
							[26.129857, 40.777699],
							[26.130587, 40.778711],
							[26.131101, 40.779016],
							[26.13177, 40.778987],
							[26.132629, 40.77875],
							[26.133326, 40.778719],
							[26.134027, 40.778855],
							[26.13448, 40.779076],
							[26.134874, 40.779462],
							[26.135162, 40.77989],
							[26.135457, 40.780287],
							[26.135886, 40.781455],
							[26.135986, 40.781924],
							[26.136271, 40.783103],
							[26.136796, 40.783926],
							[26.138068, 40.784897],
							[26.141419, 40.78641],
							[26.141763, 40.787754],
							[26.14128, 40.789293],
							[26.140805, 40.790341],
							[26.140773, 40.791122],
							[26.140837, 40.791783],
							[26.140997, 40.792321],
							[26.141438, 40.793115],
							[26.141879, 40.79355],
							[26.142501, 40.793932],
							[26.143592, 40.79462],
							[26.145056, 40.795183],
							[26.145747, 40.795781],
							[26.146388, 40.796288],
							[26.146901, 40.797106],
							[26.147225, 40.797911],
							[26.147525, 40.798562],
							[26.147719, 40.798929],
							[26.147984, 40.799422],
							[26.148524, 40.800145],
							[26.149144, 40.800843],
							[26.149778, 40.801408],
							[26.150764, 40.80197],
							[26.152467, 40.80284],
							[26.153285, 40.803476],
							[26.154941, 40.805453],
							[26.155963, 40.806781],
							[26.156073, 40.807314],
							[26.15616, 40.808239],
							[26.156246, 40.808527],
							[26.156352, 40.808773],
							[26.156654, 40.809005],
							[26.157046, 40.80909],
							[26.157441, 40.809064],
							[26.157789, 40.808968],
							[26.159458, 40.807914],
							[26.160119, 40.807598],
							[26.160969, 40.80743],
							[26.161518, 40.807322],
							[26.162582, 40.807175],
							[26.163382, 40.80726],
							[26.164123, 40.807486],
							[26.164717, 40.807797],
							[26.165194, 40.808126],
							[26.165854, 40.809137],
							[26.167116, 40.811247],
							[26.169437, 40.814286],
							[26.169626, 40.814454],
							[26.169827, 40.814621],
							[26.170035, 40.814788],
							[26.170453, 40.815105],
							[26.170667, 40.815263],
							[26.170888, 40.815412],
							[26.173394, 40.816995],
							[26.174078, 40.817424],
							[26.174315, 40.817554],
							[26.174411, 40.817575],
							[26.175667, 40.817658],
							[26.176208, 40.817711],
							[26.176486, 40.817671],
							[26.176658, 40.817589],
							[26.176755, 40.817484],
							[26.176776, 40.817305],
							[26.176676, 40.817106],
							[26.176521, 40.816816],
							[26.176419, 40.816491],
							[26.176415, 40.816079],
							[26.17661, 40.815667],
							[26.1768, 40.815296],
							[26.177022, 40.815063],
							[26.17735, 40.814818],
							[26.177765, 40.814515],
							[26.178025, 40.814346],
							[26.178173, 40.814199],
							[26.178248, 40.814065],
							[26.178228, 40.813765],
							[26.178205, 40.813353],
							[26.178302, 40.813],
							[26.178561, 40.812675],
							[26.179046, 40.812481],
							[26.179499, 40.812422],
							[26.179993, 40.812431],
							[26.180314, 40.812514],
							[26.180463, 40.81264],
							[26.180645, 40.812835],
							[26.180849, 40.813354],
							[26.180945, 40.813825],
							[26.180924, 40.814183],
							[26.180838, 40.814808],
							[26.180688, 40.815368],
							[26.18061, 40.81624],
							[26.180602, 40.817236],
							[26.180613, 40.818892],
							[26.180721, 40.819678],
							[26.180904, 40.820234],
							[26.182024, 40.820241],
							[26.182852, 40.820306],
							[26.183806, 40.820434],
							[26.185205, 40.820657],
							[26.186676, 40.820933],
							[26.187511, 40.821246],
							[26.188512, 40.821907],
							[26.189787, 40.823096],
							[26.1907, 40.823739],
							[26.191719, 40.824312],
							[26.192654, 40.824847],
							[26.19358, 40.825285],
							[26.19405, 40.825416],
							[26.194329, 40.825457],
							[26.194726, 40.825506],
							[26.195466, 40.825522],
							[26.196088, 40.825441],
							[26.196828, 40.825221],
							[26.197183, 40.82501],
							[26.197955, 40.824288],
							[26.198373, 40.823695],
							[26.198491, 40.823305],
							[26.198534, 40.822599],
							[26.198524, 40.822055],
							[26.198599, 40.821479],
							[26.199017, 40.820732],
							[26.199457, 40.820277],
							[26.19994, 40.820042],
							[26.200626, 40.819887],
							[26.201141, 40.819855],
							[26.201957, 40.819936],
							[26.203008, 40.820228],
							[26.203834, 40.82061],
							[26.204274, 40.820902],
							[26.204585, 40.8213],
							[26.204671, 40.821584],
							[26.204693, 40.821966],
							[26.204639, 40.822242],
							[26.204306, 40.822558],
							[26.203588, 40.823054],
							[26.202461, 40.823671],
							[26.20156, 40.824312],
							[26.200541, 40.825254],
							[26.199993, 40.825952],
							[26.199832, 40.826301],
							[26.19979, 40.826732],
							[26.199983, 40.828574],
							[26.200133, 40.829443],
							[26.200412, 40.830165],
							[26.200766, 40.83075],
							[26.201184, 40.831188],
							[26.201635, 40.831464],
							[26.201957, 40.831578],
							[26.202311, 40.831578],
							[26.202611, 40.831489],
							[26.20304, 40.83118],
							[26.203405, 40.830758],
							[26.204221, 40.829354],
							[26.20466, 40.828534],
							[26.205025, 40.827957],
							[26.20598, 40.827016],
							[26.206495, 40.826699],
							[26.206924, 40.826594],
							[26.207418, 40.826585],
							[26.207986, 40.82665],
							[26.208244, 40.826748],
							[26.209049, 40.827202],
							[26.209338, 40.827421],
							[26.209564, 40.827819],
							[26.20966, 40.828282],
							[26.209671, 40.828802],
							[26.209617, 40.829329],
							[26.209488, 40.8298],
							[26.209188, 40.830441],
							[26.208995, 40.830807],
							[26.208705, 40.831294],
							[26.208619, 40.831757],
							[26.208598, 40.831976],
							[26.208652, 40.832357],
							[26.20877, 40.832633],
							[26.20981, 40.834305],
							[26.21024, 40.835223],
							[26.210586, 40.83687],
							[26.210876, 40.836874],
							[26.211492, 40.836862],
							[26.21178, 40.836847],
							[26.212235, 40.836497],
							[26.212643, 40.83584],
							[26.213093, 40.835028],
							[26.213512, 40.834671],
							[26.214134, 40.834297],
							[26.214681, 40.834054],
							[26.215432, 40.833867],
							[26.218061, 40.833599],
							[26.218608, 40.833591],
							[26.219215, 40.833684],
							[26.21967, 40.833875],
							[26.219981, 40.834119],
							[26.220164, 40.834427],
							[26.220217, 40.834727],
							[26.220213, 40.835015],
							[26.220153, 40.835369],
							[26.219853, 40.835815],
							[26.219112, 40.836546],
							[26.218593, 40.836858],
							[26.217595, 40.83732],
							[26.21575, 40.837921],
							[26.214209, 40.838372],
							[26.213894, 40.838603],
							[26.213604, 40.838919],
							[26.213289, 40.839472],
							[26.213155, 40.839736],
							[26.214853, 40.840409],
							[26.215475, 40.840698],
							[26.216438, 40.841151],
							[26.217043, 40.842762],
							[26.217368, 40.843504],
							[26.217479, 40.843858],
							[26.217529, 40.844232],
							[26.217449, 40.844573],
							[26.217045, 40.845489],
							[26.216447, 40.846508],
							[26.216081, 40.846944],
							[26.21546, 40.847246],
							[26.214805, 40.847431],
							[26.213274, 40.847658],
							[26.211455, 40.847842],
							[26.210742, 40.847808],
							[26.210321, 40.847807],
							[26.20984, 40.847741],
							[26.209525, 40.847658],
							[26.209081, 40.847381],
							[26.208756, 40.847055],
							[26.20857, 40.846804],
							[26.20853, 40.846552],
							[26.208609, 40.846307],
							[26.20874, 40.846122],
							[26.208812, 40.845996],
							[26.208748, 40.845895],
							[26.208211, 40.845822],
							[26.207362, 40.845737],
							[26.206652, 40.845728],
							[26.206064, 40.845845],
							[26.205465, 40.846114],
							[26.205021, 40.846458],
							[26.20471, 40.846894],
							[26.204481, 40.847202],
							[26.204311, 40.847665],
							[26.204222, 40.8482],
							[26.204167, 40.848942],
							[26.204222, 40.850066],
							[26.204221, 40.850833],
							[26.204383, 40.851851],
							[26.204475, 40.85246],
							[26.20486, 40.853401],
							[26.205054, 40.853976],
							[26.205221, 40.854547],
							[26.205332, 40.854849],
							[26.205569, 40.85502],
							[26.205836, 40.855108],
							[26.206165, 40.855133],
							[26.206622, 40.855095],
							[26.207035, 40.855],
							[26.207639, 40.854773],
							[26.208282, 40.854505],
							[26.208882, 40.854178],
							[26.209625, 40.853867],
							[26.210335, 40.853657],
							[26.211045, 40.853649],
							[26.211899, 40.853515],
							[26.212653, 40.853305],
							[26.213385, 40.852978],
							[26.213696, 40.852877],
							[26.214004, 40.85287],
							[26.214219, 40.852925],
							[26.214444, 40.853041],
							[26.214721, 40.85344],
							[26.214755, 40.853677],
							[26.214737, 40.85386],
							[26.214561, 40.854263],
							[26.214204, 40.854825],
							[26.213752, 40.855772],
							[26.21333, 40.856426],
							[26.212965, 40.85718],
							[26.212975, 40.857635],
							[26.213065, 40.857906],
							[26.213317, 40.858176],
							[26.213352, 40.858213],
							[26.213574, 40.85844],
							[26.213685, 40.858717],
							[26.213585, 40.85901],
							[26.213352, 40.859254],
							[26.212975, 40.859413],
							[26.212498, 40.859438],
							[26.21191, 40.859321],
							[26.211056, 40.858994],
							[26.210335, 40.858776],
							[26.209702, 40.858742],
							[26.209125, 40.858876],
							[26.208405, 40.859195],
							[26.207828, 40.859547],
							[26.207659, 40.85966],
							[26.207206, 40.860093],
							[26.206989, 40.860392],
							[26.206818, 40.860823],
							[26.206319, 40.861902],
							[26.206031, 40.862488],
							[26.205553, 40.863281],
							[26.205324, 40.863847],
							[26.205261, 40.864238],
							[26.205307, 40.864645],
							[26.205471, 40.865078],
							[26.205742, 40.865437],
							[26.206385, 40.866066],
							[26.206985, 40.866611],
							[26.207144, 40.866695],
							[26.207694, 40.866981],
							[26.208837, 40.867165],
							[26.209858, 40.867199],
							[26.211167, 40.86709],
							[26.212305, 40.866837],
							[26.213199, 40.866523],
							[26.213936, 40.866123],
							[26.215089, 40.865775],
							[26.216061, 40.865513],
							[26.216962, 40.865381],
							[26.218156, 40.865315],
							[26.219434, 40.865382],
							[26.220699, 40.865437],
							[26.221463, 40.865552],
							[26.221897, 40.865683],
							[26.222313, 40.865854],
							[26.222721, 40.866214],
							[26.222981, 40.866562],
							[26.223215, 40.86708],
							[26.223423, 40.867683],
							[26.223449, 40.868615],
							[26.22346, 40.86962],
							[26.223582, 40.870372],
							[26.223883, 40.871185],
							[26.224085, 40.871915],
							[26.224231, 40.872574],
							[26.224276, 40.873444],
							[26.224246, 40.87438],
							[26.224115, 40.875087],
							[26.223966, 40.875704],
							[26.223709, 40.876346],
							[26.223036, 40.877783],
							[26.222452, 40.87922],
							[26.221955, 40.880621],
							[26.221426, 40.882344],
							[26.221147, 40.88331],
							[26.220882, 40.884175],
							[26.220752, 40.884739],
							[26.220787, 40.885381],
							[26.220874, 40.886247],
							[26.221038, 40.887007],
							[26.221111, 40.887481],
							[26.2213, 40.888185],
							[26.221437, 40.888673],
							[26.221645, 40.8891],
							[26.222088, 40.889781],
							[26.222625, 40.890292],
							[26.223449, 40.890797],
							[26.224224, 40.891135],
							[26.224782, 40.891368],
							[26.225565, 40.891616],
							[26.227039, 40.892097],
							[26.227512, 40.892202],
							[26.227908, 40.892245],
							[26.228423, 40.892229],
							[26.228783, 40.892137],
							[26.229021, 40.891955],
							[26.229207, 40.891713],
							[26.229471, 40.891166],
							[26.229581, 40.89064],
							[26.229667, 40.890076],
							[26.229815, 40.889577],
							[26.230127, 40.88845],
							[26.230222, 40.887919],
							[26.23017, 40.887506],
							[26.229962, 40.887145],
							[26.229633, 40.886889],
							[26.229234, 40.886732],
							[26.228713, 40.886681],
							[26.228269, 40.886719],
							[26.227963, 40.886841],
							[26.227716, 40.887007],
							[26.227456, 40.887296],
							[26.227187, 40.887538],
							[26.226788, 40.88767],
							[26.226389, 40.887722],
							[26.225904, 40.887676],
							[26.225583, 40.887597],
							[26.225305, 40.88746],
							[26.22508, 40.88723],
							[26.224863, 40.886909],
							[26.224819, 40.886647],
							[26.224837, 40.886404],
							[26.225002, 40.885775],
							[26.22542, 40.884837],
							[26.225724, 40.884176],
							[26.226046, 40.883414],
							[26.226281, 40.882871],
							[26.226561, 40.882613],
							[26.226882, 40.882507],
							[26.227227, 40.882492],
							[26.227563, 40.882554],
							[26.227881, 40.882687],
							[26.228314, 40.882975],
							[26.228685, 40.883568],
							[26.229102, 40.884155],
							[26.229711, 40.884805],
							[26.230201, 40.885257],
							[26.230882, 40.885666],
							[26.231289, 40.885827],
							[26.231957, 40.886069],
							[26.23256, 40.88618],
							[26.233266, 40.886216],
							[26.233792, 40.886205],
							[26.234346, 40.886175],
							[26.234977, 40.88611],
							[26.235626, 40.885951],
							[26.236534, 40.885644],
							[26.237685, 40.885297],
							[26.238298, 40.885173],
							[26.239163, 40.885111],
							[26.239478, 40.884981],
							[26.24089, 40.884126],
							[26.242754, 40.883454],
							[26.244283, 40.88284],
							[26.245876, 40.882094],
							[26.246689, 40.881741],
							[26.247838, 40.881438],
							[26.248904, 40.881391],
							[26.249127, 40.881415],
							[26.249842, 40.881489],
							[26.250593, 40.882857],
							[26.250859, 40.883495],
							[26.251239, 40.884627],
							[26.251441, 40.88586],
							[26.251462, 40.887315],
							[26.251358, 40.888957],
							[26.251607, 40.891006],
							[26.252124, 40.892977],
							[26.252537, 40.894728],
							[26.252604, 40.895406],
							[26.252724, 40.89662],
							[26.2526, 40.898888],
							[26.252682, 40.90139],
							[26.252848, 40.903142],
							[26.253282, 40.904893],
							[26.254213, 40.907067],
							[26.255248, 40.908959],
							[26.256407, 40.910725],
							[26.257778, 40.912113],
							[26.259732, 40.913803],
							[26.261582, 40.91524],
							[26.262991, 40.914381],
							[26.263419, 40.913908],
							[26.263664, 40.913314],
							[26.263724, 40.912564],
							[26.263599, 40.911778],
							[26.2632, 40.909569],
							[26.263181, 40.90722],
							[26.264193, 40.90525],
							[26.264386, 40.903811],
							[26.26503, 40.902566],
							[26.266263, 40.901037],
							[26.267931, 40.899569],
							[26.26929, 40.899025],
							[26.27595, 40.894075],
							[26.277822, 40.893389],
							[26.279618, 40.893137],
							[26.281223, 40.892961],
							[26.290814, 40.893348],
							[26.29264, 40.894032],
							[26.293894, 40.894656],
							[26.295541, 40.895704],
							[26.298029, 40.897609],
							[26.299702, 40.899503],
							[26.299863, 40.899689],
							[26.300011, 40.899884],
							[26.301168, 40.901455],
							[26.301309, 40.90166],
							[26.301432, 40.901855],
							[26.301546, 40.902069],
							[26.30165, 40.902274],
							[26.301747, 40.902488],
							[26.301839, 40.902703],
							[26.301892, 40.903071],
							[26.301881, 40.903419],
							[26.30179, 40.903877],
							[26.301585, 40.904267],
							[26.30106, 40.905007],
							[26.296617, 40.908677],
							[26.29549, 40.909949],
							[26.2942, 40.912242],
							[26.293568, 40.913408],
							[26.291947, 40.915372],
							[26.290509, 40.916573],
							[26.289406, 40.91758],
							[26.288596, 40.918405],
							[26.285812, 40.921713],
							[26.285105, 40.922686],
							[26.284939, 40.923009],
							[26.284833, 40.923381],
							[26.284715, 40.924],
							[26.284983, 40.925627],
							[26.285362, 40.930725],
							[26.287315, 40.931473],
							[26.288516, 40.931874],
							[26.289793, 40.932208],
							[26.29064, 40.932696],
							[26.291631, 40.933063],
							[26.292077, 40.933106],
							[26.292516, 40.933122],
							[26.292949, 40.933],
							[26.293326, 40.932808],
							[26.294257, 40.932094],
							[26.295142, 40.931167],
							[26.296027, 40.930126],
							[26.297228, 40.929237],
							[26.29853, 40.928463],
							[26.299883, 40.927881],
							[26.301489, 40.927479],
							[26.302817, 40.927308],
							[26.303394, 40.927346],
							[26.303879, 40.927422],
							[26.304373, 40.927546],
							[26.304675, 40.92768],
							[26.305525, 40.92814],
							[26.306243, 40.928722],
							[26.307432, 40.929801],
							[26.308557, 40.931148],
							[26.309455, 40.932323],
							[26.310252, 40.933583],
							[26.310821, 40.934304],
							[26.311699, 40.935124],
							[26.313577, 40.936583],
							[26.315009, 40.937647],
							[26.316577, 40.938634],
							[26.318102, 40.939594],
							[26.319103, 40.940206],
							[26.319865, 40.940628],
							[26.32057, 40.941006],
							[26.322883, 40.941856],
							[26.324401, 40.94262],
							[26.325855, 40.943413],
							[26.327334, 40.944005],
							[26.328889, 40.944272],
							[26.33023, 40.94433],
							[26.33176, 40.944186],
							[26.333328, 40.943823],
							[26.334402, 40.94346],
							[26.33568, 40.943317],
							[26.336513, 40.943252],
							[26.337745, 40.94329],
							[26.339182, 40.943374],
							[26.340895, 40.9435],
							[26.343361, 40.943635],
							[26.345046, 40.943594],
							[26.346148, 40.94354],
							[26.347253, 40.943398],
							[26.347983, 40.943327],
							[26.34882, 40.943113],
							[26.349542, 40.942849],
							[26.350539, 40.942528],
							[26.351427, 40.942418],
							[26.352392, 40.942377],
							[26.35319, 40.942426],
							[26.354558, 40.942561],
							[26.355394, 40.942775],
							[26.356096, 40.943023],
							[26.356998, 40.943432],
							[26.357984, 40.944158],
							[26.359009, 40.945265],
							[26.359828, 40.946492],
							[26.360048, 40.947087],
							[26.360065, 40.948178],
							[26.359919, 40.949213],
							[26.359717, 40.949678],
							[26.359211, 40.950174],
							[26.35825, 40.950786],
							[26.356758, 40.95153],
							[26.355228, 40.952648],
							[26.353938, 40.953994],
							[26.352876, 40.955178],
							[26.352003, 40.956773],
							[26.351283, 40.958673],
							[26.350385, 40.960994],
							[26.349475, 40.962789],
							[26.348678, 40.964001],
							[26.347603, 40.965023],
							[26.34534, 40.966455],
							[26.342381, 40.968642],
							[26.338133, 40.971162],
							[26.333909, 40.973549],
							[26.330596, 40.9752],
							[26.328586, 40.976413],
							[26.327386, 40.977372],
							[26.326569, 40.978142],
							[26.326192, 40.978579],
							[26.325626, 40.979558],
							[26.325532, 40.980947],
							[26.325555, 40.982816],
							[26.325461, 40.984578],
							[26.325862, 40.986394],
							[26.326378, 40.988023],
							[26.326861, 40.989109],
							[26.32733, 40.990023],
							[26.327757, 40.990644],
							[26.328202, 40.990999],
							[26.32893, 40.991328],
							[26.329918, 40.991539],
							[26.331025, 40.991608],
							[26.332041, 40.991592],
							[26.333447, 40.991471],
							[26.33524, 40.991246],
							[26.336611, 40.990988],
							[26.337993, 40.990671],
							[26.339257, 40.990328],
							[26.340955, 40.989794],
							[26.342558, 40.989509],
							[26.343903, 40.989385],
							[26.345035, 40.989634],
							[26.346072, 40.99015],
							[26.346827, 40.991005],
							[26.347605, 40.99234],
							[26.34836, 40.993603],
							[26.349633, 40.994832],
							[26.351072, 40.995864],
							[26.353029, 40.996772],
							[26.355293, 40.997858],
							[26.356731, 40.998641],
							[26.357911, 40.999637],
							[26.358925, 41.000865],
							[26.35942, 41.002111],
							[26.359962, 41.003571],
							[26.36067, 41.005012],
							[26.361519, 41.006632],
							[26.361943, 41.008055],
							[26.362226, 41.009603],
							[26.362604, 41.011009],
							[26.363075, 41.012522],
							[26.363806, 41.013821],
							[26.364537, 41.014888],
							[26.365646, 41.015956],
							[26.366424, 41.017113],
							[26.36673, 41.017967],
							[26.366754, 41.019052],
							[26.366566, 41.020404],
							[26.366236, 41.02105],
							[26.365858, 41.021792],
							[26.365056, 41.023002],
							[26.363971, 41.024052],
							[26.361943, 41.025315],
							[26.360092, 41.026241],
							[26.357484, 41.027258],
							[26.354536, 41.028284],
							[26.352029, 41.02899],
							[26.350441, 41.029189],
							[26.34894, 41.029283],
							[26.347934, 41.029376],
							[26.346981, 41.029576],
							[26.346151, 41.030068],
							[26.345268, 41.030788],
							[26.344562, 41.032133],
							[26.34375, 41.034223],
							[26.343026, 41.036194],
							[26.342655, 41.037525],
							[26.342214, 41.038244],
							[26.341173, 41.039203],
							[26.340025, 41.039909],
							[26.338331, 41.040495],
							[26.336936, 41.040734],
							[26.334941, 41.040788],
							[26.332787, 41.040655],
							[26.330863, 41.040601],
							[26.329133, 41.040841],
							[26.327897, 41.041107],
							[26.327191, 41.041413],
							[26.326768, 41.042013],
							[26.326715, 41.042678],
							[26.326909, 41.043371],
							[26.327509, 41.044036],
							[26.328374, 41.044662],
							[26.329398, 41.045195],
							[26.330016, 41.045754],
							[26.330422, 41.046646],
							[26.330634, 41.047844],
							[26.330404, 41.049388],
							[26.329836, 41.050846],
							[26.328896, 41.0519],
							[26.327836, 41.053182],
							[26.327158, 41.054905],
							[26.326326, 41.056337],
							[26.324949, 41.057842],
							[26.323272, 41.059332],
							[26.321895, 41.06069],
							[26.320925, 41.061968],
							[26.318805, 41.063715],
							[26.317308, 41.064462],
							[26.315709, 41.06557],
							[26.315307, 41.066206],
							[26.31494, 41.067319],
							[26.313704, 41.069435],
							[26.312883, 41.070747],
							[26.312434, 41.071508],
							[26.312072, 41.072613],
							[26.311572, 41.074161],
							[26.311244, 41.075773],
							[26.310899, 41.077659],
							[26.310692, 41.079401],
							[26.310485, 41.080312],
							[26.310588, 41.08117],
							[26.31083, 41.081651],
							[26.311365, 41.082132],
							[26.311951, 41.083146],
							[26.312538, 41.084303],
							[26.313124, 41.085201],
							[26.314107, 41.086033],
							[26.315263, 41.087216],
							[26.31596, 41.087808],
							[26.316488, 41.088086],
							[26.316967, 41.088501],
							[26.317771, 41.089147],
							[26.31886, 41.089823],
							[26.320421, 41.090551],
							[26.321997, 41.091068],
							[26.323378, 41.091664],
							[26.324897, 41.092162],
							[26.326183, 41.092628],
							[26.327451, 41.09317],
							[26.32884, 41.093983],
							[26.329974, 41.094836],
							[26.331169, 41.096021],
							[26.331936, 41.097026],
							[26.3324, 41.098332],
							[26.332591, 41.099241],
							[26.333086, 41.100374],
							[26.33336, 41.10137],
							[26.33365, 41.102507],
							[26.334204, 41.103534],
							[26.334835, 41.104528],
							[26.335781, 41.105563],
							[26.336092, 41.106339],
							[26.33604, 41.107022],
							[26.335841, 41.107676],
							[26.335371, 41.108396],
							[26.334169, 41.10956],
							[26.333349, 41.110089],
							[26.332322, 41.11066],
							[26.331218, 41.110962],
							[26.329779, 41.11114],
							[26.328078, 41.111114],
							[26.327136, 41.111191],
							[26.326037, 41.111399],
							[26.324768, 41.11135],
							[26.323294, 41.110963],
							[26.321818, 41.11042],
							[26.320197, 41.109809],
							[26.318329, 41.109097],
							[26.315993, 41.108554],
							[26.314247, 41.108281],
							[26.31282, 41.108188],
							[26.310602, 41.108255],
							[26.308304, 41.108474],
							[26.306827, 41.10877],
							[26.305732, 41.109322],
							[26.304643, 41.110207],
							[26.303957, 41.111188],
							[26.303219, 41.112469],
							[26.302978, 41.113489],
							[26.303098, 41.114952],
							[26.303597, 41.116289],
							[26.304769, 41.117616],
							[26.305967, 41.11861],
							[26.30726, 41.119389],
							[26.308383, 41.119986],
							[26.310139, 41.120901],
							[26.312158, 41.121716],
							[26.314376, 41.122303],
							[26.316595, 41.123069],
							[26.318576, 41.123556],
							[26.31999, 41.123745],
							[26.321179, 41.123785],
							[26.322062, 41.123906],
							[26.322523, 41.124086],
							[26.322813, 41.124376],
							[26.322876, 41.124875],
							[26.32272, 41.12558],
							[26.322337, 41.126457],
							[26.321901, 41.12781],
							[26.321598, 41.129471],
							[26.321295, 41.130751],
							[26.321045, 41.132817],
							[26.320886, 41.133772],
							[26.320914, 41.13418],
							[26.321131, 41.134717],
							[26.321712, 41.135183],
							[26.32288, 41.135611],
							[26.324899, 41.13594],
							[26.32626, 41.136059],
							[26.327952, 41.136342],
							[26.329131, 41.136691],
							[26.329931, 41.137181],
							[26.330936, 41.13771],
							[26.331837, 41.137981],
							[26.332914, 41.138128],
							[26.333574, 41.138334],
							[26.334341, 41.138914],
							[26.335147, 41.140186],
							[26.335794, 41.141071],
							[26.336389, 41.142286],
							[26.336785, 41.143419],
							[26.33685, 41.144291],
							[26.336706, 41.145038],
							[26.336438, 41.145765],
							[26.336035, 41.146342],
							[26.335342, 41.146956],
							[26.334355, 41.147607],
							[26.333323, 41.148507],
							[26.332787, 41.149278],
							[26.332341, 41.150322],
							[26.3321, 41.151686],
							[26.331756, 41.152343],
							[26.330904, 41.152948],
							[26.329668, 41.153362],
							[26.32767, 41.153765],
							[26.326077, 41.154287],
							[26.324677, 41.154933],
							[26.323146, 41.155698],
							[26.321813, 41.156263],
							[26.319478, 41.157111],
							[26.317009, 41.158216],
							[26.315547, 41.158851],
							[26.314757, 41.159323],
							[26.314064, 41.160109],
							[26.313789, 41.161158],
							[26.313844, 41.16244],
							[26.313754, 41.16365],
							[26.313255, 41.164915],
							[26.312604, 41.166132],
							[26.311751, 41.167348],
							[26.310822, 41.168311],
							[26.30948, 41.169442],
							[26.308281, 41.170721],
							[26.307431, 41.171893],
							[26.306929, 41.173154],
							[26.306641, 41.174632],
							[26.306725, 41.175951],
							[26.307089, 41.177329],
							[26.307688, 41.178732],
							[26.308601, 41.18025],
							[26.309525, 41.181385],
							[26.31026, 41.182136],
							[26.311176, 41.18287],
							[26.312449, 41.183474],
							[26.313718, 41.183807],
							[26.315505, 41.184039],
							[26.317387, 41.184018],
							[26.319088, 41.183953],
							[26.320063, 41.183853],
							[26.321338, 41.183975],
							[26.322247, 41.184359],
							[26.323072, 41.185197],
							[26.323514, 41.186079],
							[26.323684, 41.187191],
							[26.323146, 41.188755],
							[26.322413, 41.190029],
							[26.321969, 41.191677],
							[26.321066, 41.193307],
							[26.320286, 41.19493],
							[26.319528, 41.196261],
							[26.319184, 41.197541],
							[26.319108, 41.199222],
							[26.31921, 41.200788],
							[26.319878, 41.202967],
							[26.320423, 41.205176],
							[26.321559, 41.207747],
							[26.323207, 41.211],
							[26.324097, 41.212623],
							[26.324717, 41.213521],
							[26.325342, 41.214114],
							[26.326587, 41.215014],
							[26.328424, 41.216239],
							[26.329627, 41.217079],
							[26.330181, 41.217559],
							[26.330723, 41.218131],
							[26.331025, 41.218826],
							[26.331046, 41.219629],
							[26.330965, 41.220119],
							[26.330675, 41.220693],
							[26.32997, 41.221345],
							[26.328927, 41.221909],
							[26.327646, 41.222376],
							[26.326059, 41.223113],
							[26.325032, 41.224085],
							[26.324346, 41.224681],
							[26.323192, 41.225858],
							[26.322633, 41.226914],
							[26.322482, 41.228185],
							[26.322165, 41.229717],
							[26.32135, 41.231147],
							[26.32038, 41.232564],
							[26.31897, 41.234209],
							[26.318225, 41.235266],
							[26.317681, 41.236164],
							[26.317268, 41.237346],
							[26.317034, 41.238621],
							[26.31696, 41.239699],
							[26.316978, 41.240587],
							[26.3171, 41.241498],
							[26.317339, 41.242429],
							[26.31774, 41.243119],
							[26.318559, 41.243921],
							[26.319847, 41.245089],
							[26.320962, 41.246361],
							[26.322095, 41.248138],
							[26.322277, 41.248743],
							[26.322366, 41.250206],
							[26.322546, 41.251278],
							[26.322861, 41.252545],
							[26.323163, 41.253305],
							[26.323744, 41.253931],
							[26.324393, 41.254367],
							[26.325282, 41.255188],
							[26.325965, 41.255567],
							[26.327378, 41.255916],
							[26.328538, 41.255966],
							[26.329657, 41.255838],
							[26.330459, 41.255654],
							[26.331448, 41.255187],
							[26.332795, 41.254967],
							[26.334041, 41.255001],
							[26.335288, 41.255158],
							[26.336246, 41.255402],
							[26.336897, 41.255671],
							[26.337435, 41.256076],
							[26.338999, 41.258374],
							[26.339507, 41.258939],
							[26.340025, 41.259291],
							[26.340927, 41.259535],
							[26.342287, 41.259563],
							[26.345438, 41.259165],
							[26.347339, 41.258676],
							[26.348854, 41.258025],
							[26.350214, 41.257049],
							[26.351874, 41.255367],
							[26.352523, 41.2543],
							[26.352656, 41.253558],
							[26.353365, 41.252437],
							[26.354616, 41.250727],
							[26.355446, 41.249968],
							[26.356276, 41.249669],
							[26.357202, 41.249452],
							[26.358201, 41.249525],
							[26.35885, 41.249787],
							[26.359223, 41.25014],
							[26.359945, 41.250583],
							[26.361617, 41.251152],
							[26.363927, 41.251568],
							[26.366272, 41.251804],
							[26.368329, 41.252057],
							[26.370085, 41.252048],
							[26.371192, 41.25184],
							[26.372479, 41.251614],
							[26.374079, 41.251523],
							[26.375883, 41.25155],
							[26.376798, 41.251831],
							[26.378241, 41.252174],
							[26.37954, 41.25231],
							[26.38108, 41.252265],
							[26.382427, 41.252012],
							[26.384304, 41.251415],
							[26.38612, 41.2507],
							[26.388009, 41.250221],
							[26.389921, 41.249841],
							[26.391449, 41.249642],
							[26.392688, 41.249705],
							[26.393783, 41.249877],
							[26.394516, 41.250203],
							[26.39519, 41.250845],
							[26.396164, 41.251713],
							[26.397127, 41.252165],
							[26.398486, 41.252482],
							[26.40106, 41.252735],
							[26.402852, 41.252817],
							[26.404909, 41.253242],
							[26.406329, 41.253685],
							[26.407471, 41.254046],
							[26.408133, 41.254489],
							[26.408999, 41.255267],
							[26.409372, 41.2559],
							[26.409649, 41.256705],
							[26.409817, 41.257537],
							[26.409853, 41.258649],
							[26.410082, 41.259662],
							[26.410623, 41.260612],
							[26.411345, 41.261597],
							[26.412283, 41.262438],
							[26.413847, 41.263469],
							[26.415495, 41.264382],
							[26.416746, 41.265295],
							[26.418971, 41.266869],
							[26.420583, 41.267745],
							[26.422159, 41.268514],
							[26.423855, 41.269554],
							[26.425034, 41.270024],
							[26.426152, 41.270322],
							[26.4275, 41.270982],
							[26.428522, 41.271669],
							[26.429893, 41.272818],
							[26.43106, 41.27393],
							[26.432179, 41.275381],
							[26.433033, 41.276398],
							[26.43438, 41.277645],
							[26.435379, 41.278513],
							[26.435968, 41.278811],
							[26.436918, 41.279019],
							[26.438566, 41.279037],
							[26.440082, 41.278938],
							[26.441513, 41.2791],
							[26.442825, 41.279363],
							[26.443655, 41.279679],
							[26.444039, 41.280104],
							[26.444292, 41.280565],
							[26.444653, 41.281008],
							[26.445254, 41.281406],
							[26.446361, 41.281749],
							[26.44742, 41.281903],
							[26.448442, 41.28183],
							[26.449946, 41.281659],
							[26.453795, 41.281017],
							[26.455299, 41.280963],
							[26.456802, 41.281107],
							[26.458486, 41.281388],
							[26.460519, 41.281794],
							[26.46153, 41.282228],
							[26.462311, 41.282807],
							[26.462768, 41.28334],
							[26.463214, 41.284172],
							[26.464092, 41.286151],
							[26.464224, 41.287091],
							[26.464368, 41.287724],
							[26.464669, 41.288655],
							[26.464958, 41.289758],
							[26.464994, 41.29067],
							[26.464922, 41.291619],
							[26.465054, 41.292352],
							[26.46515, 41.293219],
							[26.465162, 41.293897],
							[26.464934, 41.294484],
							[26.464537, 41.295108],
							[26.463911, 41.29584],
							[26.463827, 41.296527],
							[26.463959, 41.297774],
							[26.464176, 41.299193],
							[26.46444, 41.299898],
							[26.46509, 41.300657],
							[26.465776, 41.301063],
							[26.467003, 41.301434],
							[26.468699, 41.301768],
							[26.469735, 41.301865],
							[26.470744, 41.301958],
							[26.472524, 41.302446],
							[26.474148, 41.302943],
							[26.475543, 41.303097],
							[26.477408, 41.30297],
							[26.479624, 41.303068],
							[26.480894, 41.303062],
							[26.482111, 41.302924],
							[26.483127, 41.302999],
							[26.483904, 41.303114],
							[26.484921, 41.303502],
							[26.486222, 41.304028],
							[26.48713, 41.30463],
							[26.488223, 41.305549],
							[26.488901, 41.306266],
							[26.489417, 41.307035],
							[26.489771, 41.307666],
							[26.490094, 41.308562],
							[26.490271, 41.309476],
							[26.490402, 41.310557],
							[26.490279, 41.3115],
							[26.490248, 41.312466],
							[26.490225, 41.313957],
							[26.490055, 41.315125],
							[26.490156, 41.315762],
							[26.490356, 41.316334],
							[26.490687, 41.317525],
							[26.490818, 41.318225],
							[26.491118, 41.318855],
							[26.491518, 41.319387],
							[26.492142, 41.319809],
							[26.492681, 41.320462],
							[26.493458, 41.32152],
							[26.494182, 41.322798],
							[26.495537, 41.325203],
							[26.496168, 41.326151],
							[26.496923, 41.326914],
							[26.497546, 41.327787],
							[26.497946, 41.328261],
							[26.498455, 41.328655],
							[26.49914, 41.328978],
							[26.500063, 41.328996],
							[26.500964, 41.328799],
							[26.502104, 41.328319],
							[26.503181, 41.327782],
							[26.504513, 41.327325],
							[26.506236, 41.32696],
							[26.509034, 41.326876],
							[26.510719, 41.327054],
							[26.512175, 41.327542],
							[26.513787, 41.328273],
							[26.515182, 41.329294],
							[26.516084, 41.330143],
							[26.516469, 41.33092],
							[26.516625, 41.331724],
							[26.516421, 41.332754],
							[26.516168, 41.33372],
							[26.516144, 41.334271],
							[26.516229, 41.335734],
							[26.516638, 41.337188],
							[26.517191, 41.3382],
							[26.517853, 41.339094],
							[26.518334, 41.3395],
							[26.518947, 41.339735],
							[26.519873, 41.339798],
							[26.520739, 41.339573],
							[26.521702, 41.339193],
							[26.522592, 41.338498],
							[26.523711, 41.337613],
							[26.524757, 41.336944],
							[26.525828, 41.33652],
							[26.526898, 41.336384],
							[26.528294, 41.336402],
							[26.529605, 41.336818],
							[26.530483, 41.337233],
							[26.531758, 41.337631],
							[26.533574, 41.338019],
							[26.534633, 41.338543],
							[26.535932, 41.339175],
							[26.537399, 41.339889],
							[26.538374, 41.340485],
							[26.539649, 41.341216],
							[26.540792, 41.34165],
							[26.542259, 41.342074],
							[26.543294, 41.342255],
							[26.5441, 41.342183],
							[26.545854, 41.341197],
							[26.550149, 41.339734],
							[26.550776, 41.339311],
							[26.55246, 41.337974],
							[26.553506, 41.337441],
							[26.554348, 41.337188],
							[26.555226, 41.337107],
							[26.556201, 41.336935],
							[26.557115, 41.336574],
							[26.55904, 41.335788],
							[26.560194, 41.335291],
							[26.560952, 41.334695],
							[26.561818, 41.333602],
							[26.562408, 41.332392],
							[26.562745, 41.330929],
							[26.56331, 41.330062],
							[26.564332, 41.329195],
							[26.565018, 41.32849],
							[26.565716, 41.327469],
							[26.566413, 41.326729],
							[26.567436, 41.325843],
							[26.568482, 41.325121],
							[26.569221, 41.324694],
							[26.57, 41.324343],
							[26.570957, 41.324064],
							[26.571875, 41.323851],
							[26.572769, 41.323816],
							[26.574473, 41.323657],
							[26.576157, 41.323332],
							[26.577362, 41.323162],
							[26.578084, 41.32282],
							[26.579093, 41.322474],
							[26.580024, 41.322308],
							[26.580973, 41.322198],
							[26.582058, 41.322105],
							[26.583811, 41.322149],
							[26.585549, 41.322388],
							[26.586631, 41.322602],
							[26.587657, 41.322924],
							[26.5883, 41.323172],
							[26.589284, 41.323625],
							[26.591083, 41.324702],
							[26.593374, 41.325885],
							[26.595483, 41.326824],
							[26.596987, 41.32731],
							[26.598238, 41.327405],
							[26.599714, 41.328144],
							[26.601228, 41.329115],
							[26.603697, 41.330691],
							[26.605732, 41.332334],
							[26.606832, 41.333237],
							[26.608921, 41.334477],
							[26.610594, 41.334804],
							[26.612843, 41.335004],
							[26.614459, 41.335131],
							[26.615964, 41.335564],
							[26.616948, 41.336187],
							[26.617313, 41.336641],
							[26.617665, 41.337559],
							[26.618058, 41.338276],
							[26.618789, 41.338962],
							[26.619956, 41.339627],
							[26.621446, 41.340493],
							[26.622655, 41.341432],
							[26.62276, 41.341534],
							[26.623934, 41.342677],
							[26.625143, 41.343754],
							[26.626042, 41.344999],
							[26.626562, 41.346002],
							[26.627139, 41.347468],
							[26.627659, 41.348767],
							[26.627954, 41.350233],
							[26.628095, 41.351373],
							[26.628446, 41.352892],
							[26.628784, 41.354169],
							[26.629402, 41.355583],
							[26.630045, 41.357185],
							[26.630386, 41.359336],
							[26.630813, 41.36138],
							[26.63121, 41.362452],
							[26.631931, 41.363402],
							[26.632255, 41.364253],
							[26.632265, 41.364919],
							[26.631971, 41.366227],
							[26.63183, 41.367285],
							[26.631924, 41.368569],
							[26.632407, 41.370011],
							[26.632999, 41.371039],
							[26.633632, 41.372531],
							[26.633882, 41.374232],
							[26.633794, 41.375823],
							[26.633397, 41.376883],
							[26.633205, 41.378087],
							[26.633308, 41.378927],
							[26.634074, 41.380219],
							[26.634692, 41.381798],
							[26.635428, 41.383731],
							[26.635575, 41.38509],
							[26.635178, 41.386216],
							[26.634354, 41.387575],
							[26.633411, 41.388823],
							[26.632896, 41.390248],
							[26.632955, 41.391462],
							[26.633191, 41.392556],
							[26.632808, 41.393549],
							[26.632455, 41.39493],
							[26.63219, 41.396089],
							[26.631586, 41.39705],
							[26.631218, 41.398176],
							[26.630541, 41.399601],
							[26.630143, 41.40086],
							[26.630173, 41.402394],
							[26.63035, 41.403885],
							[26.630129, 41.406325],
							[26.629761, 41.408202],
							[26.62926, 41.409328],
							[26.629025, 41.410808],
							[26.62901, 41.412464],
							[26.629378, 41.413491],
							[26.629761, 41.414572],
							[26.62982, 41.415809],
							[26.62954, 41.41752],
							[26.629025, 41.418834],
							[26.628333, 41.419695],
							[26.626964, 41.420478],
							[26.625492, 41.421019],
							[26.623946, 41.421229],
							[26.6219, 41.421273],
							[26.619721, 41.421317],
							[26.61794, 41.421483],
							[26.616777, 41.421847],
							[26.615114, 41.422565],
							[26.613862, 41.42337],
							[26.613156, 41.424154],
							[26.61295, 41.425015],
							[26.612802, 41.426274],
							[26.612876, 41.42741],
							[26.612685, 41.428823],
							[26.612493, 41.430523],
							[26.612155, 41.431748],
							[26.611654, 41.432774],
							[26.611183, 41.4339],
							[26.611095, 41.434617],
							[26.611322, 41.43586],
							[26.611357, 41.437747],
							[26.611707, 41.439293],
							[26.61169, 41.439821],
							[26.61162, 41.440355],
							[26.611899, 41.441927],
							[26.612564, 41.443801],
							[26.613193, 41.444784],
							[26.613735, 41.445963],
							[26.6137, 41.446684],
							[26.613053, 41.447496],
							[26.612546, 41.448807],
							[26.612529, 41.449698],
							[26.612634, 41.450576],
							[26.612459, 41.451414],
							[26.611882, 41.452463],
							[26.611602, 41.453118],
							[26.611532, 41.453982],
							[26.611812, 41.454978],
							[26.612459, 41.455974],
							[26.613385, 41.458018],
							[26.613595, 41.45976],
							[26.613525, 41.461516],
							[26.613245, 41.462983],
							[26.612459, 41.464096],
							[26.611532, 41.464895],
							[26.610186, 41.465668],
							[26.608963, 41.466729],
							[26.608473, 41.467686],
							[26.608001, 41.469009],
							[26.606672, 41.470672],
							[26.605291, 41.472152],
							[26.60391, 41.473685],
							[26.602564, 41.474707],
							[26.601026, 41.475807],
							[26.599383, 41.477431],
							[26.598369, 41.478714],
							[26.597687, 41.479867],
							[26.59739, 41.480797],
							[26.597635, 41.481753],
							[26.598054, 41.482892],
							[26.598858, 41.484202],
							[26.600432, 41.486101],
							[26.60176, 41.487463],
							[26.603701, 41.488982],
							[26.60426, 41.489728],
							[26.604767, 41.490828],
							[26.604819, 41.492059],
							[26.604575, 41.493133],
							[26.60419, 41.494324],
							[26.603753, 41.495607],
							[26.603334, 41.496498],
							[26.603421, 41.497218],
							[26.603578, 41.498187],
							[26.603316, 41.499876],
							[26.603543, 41.501237],
							[26.603281, 41.502324],
							[26.603141, 41.503646],
							[26.602844, 41.504838],
							[26.602215, 41.506605],
							[26.602075, 41.507312],
							[26.601743, 41.508006],
							[26.601113, 41.509341],
							[26.600589, 41.510114],
							[26.599662, 41.510611],
							[26.598369, 41.51154],
							[26.597372, 41.512574],
							[26.597075, 41.513347],
							[26.59704, 41.513975],
							[26.597355, 41.51459],
							[26.5976, 41.515389],
							[26.597355, 41.516423],
							[26.596516, 41.517941],
							[26.595537, 41.519159],
							[26.594733, 41.519826],
							[26.593684, 41.52107],
							[26.592617, 41.522287],
							[26.592093, 41.523386],
							[26.591673, 41.524603],
							[26.591166, 41.525899],
							[26.591114, 41.527129],
							[26.591149, 41.528294],
							[26.591499, 41.529262],
							[26.592111, 41.530859],
							[26.593037, 41.532966],
							[26.593701, 41.534235],
							[26.594575, 41.535635],
							[26.595275, 41.536381],
							[26.595939, 41.537611],
							[26.596463, 41.539195],
							[26.596813, 41.54083],
							[26.597372, 41.541812],
							[26.597949, 41.54278],
							[26.597984, 41.543722],
							[26.598334, 41.54452],
							[26.598509, 41.545357],
							[26.598369, 41.546129],
							[26.597759, 41.547385],
							[26.59702, 41.548126],
							[26.595929, 41.54883],
							[26.594753, 41.549986],
							[26.593209, 41.551683],
							[26.591597, 41.553769],
							[26.590875, 41.554963],
							[26.590623, 41.555691],
							[26.590656, 41.55647],
							[26.591026, 41.557287],
							[26.591244, 41.558318],
							[26.591714, 41.559813],
							[26.592167, 41.560818],
							[26.592906, 41.562125],
							[26.593628, 41.563419],
							[26.594065, 41.564449],
							[26.594149, 41.565102],
							[26.593964, 41.565781],
							[26.593511, 41.566497],
							[26.592839, 41.566899],
							[26.591365, 41.567577],
							[26.589649, 41.568444],
							[26.587969, 41.569336],
							[26.587298, 41.569977],
							[26.58718, 41.570555],
							[26.587348, 41.571183],
							[26.587886, 41.572351],
							[26.588624, 41.573243],
							[26.589733, 41.574386],
							[26.591563, 41.575668],
							[26.593746, 41.576886],
							[26.595727, 41.577929],
							[26.597356, 41.579122],
							[26.598632, 41.580328],
							[26.599455, 41.581458],
							[26.599707, 41.582363],
							[26.599539, 41.583166],
							[26.598985, 41.583983],
							[26.598229, 41.585503],
							[26.597759, 41.586809],
							[26.597457, 41.587964],
							[26.596903, 41.589471],
							[26.596517, 41.591029],
							[26.596533, 41.592561],
							[26.596181, 41.59408],
							[26.59561, 41.595198],
							[26.59514, 41.596717],
							[26.594955, 41.597973],
							[26.594821, 41.599869],
							[26.594888, 41.601627],
							[26.594972, 41.603913],
							[26.594804, 41.605269],
							[26.594132, 41.606185],
							[26.593645, 41.60724],
							[26.593931, 41.608194],
							[26.594999, 41.609409],
							[26.595173, 41.610191],
							[26.591647, 41.610149],
							[26.590397, 41.61018],
							[26.589587, 41.61019],
							[26.588445, 41.610197],
							[26.586761, 41.610176],
							[26.586355, 41.610167],
							[26.586083, 41.610147],
							[26.585772, 41.610101],
							[26.585491, 41.610115],
							[26.585164, 41.610145],
							[26.584245, 41.610176],
							[26.583602, 41.610221],
							[26.583165, 41.610217],
							[26.582649, 41.610184],
							[26.582217, 41.610183],
							[26.581709, 41.610193],
							[26.581037, 41.610214],
							[26.580278, 41.610229],
							[26.579594, 41.610219],
							[26.578605, 41.610216],
							[26.57785, 41.61018],
							[26.5773, 41.610176],
							[26.576467, 41.610178],
							[26.575767, 41.610194],
							[26.574402, 41.610228],
							[26.573454, 41.610253],
							[26.572691, 41.610283],
							[26.572245, 41.610288],
							[26.571557, 41.610279],
							[26.570929, 41.61025],
							[26.570548, 41.610253],
							[26.570047, 41.610245],
							[26.569768, 41.610263],
							[26.569476, 41.610269],
							[26.56907, 41.610267],
							[26.568735, 41.610251],
							[26.568444, 41.610256],
							[26.568145, 41.610305],
							[26.567789, 41.610381],
							[26.567362, 41.610492],
							[26.566729, 41.610725],
							[26.566239, 41.610899],
							[26.565861, 41.611015],
							[26.565486, 41.611121],
							[26.564933, 41.611276],
							[26.562823, 41.611825],
							[26.562551, 41.61192],
							[26.561368, 41.612333],
							[26.560562, 41.612629],
							[26.560253, 41.61271],
							[26.559934, 41.612787],
							[26.559579, 41.612857],
							[26.559239, 41.612926],
							[26.558943, 41.612996],
							[26.558632, 41.613098],
							[26.558247, 41.613224],
							[26.557552, 41.613423],
							[26.557155, 41.613514],
							[26.556699, 41.613644],
							[26.556224, 41.613784],
							[26.555827, 41.613887],
							[26.555111, 41.614091],
							[26.552056, 41.614975],
							[26.550387, 41.615469],
							[26.549624, 41.61572],
							[26.549133, 41.615862],
							[26.548334, 41.616087],
							[26.547134, 41.616362],
							[26.546525, 41.616514],
							[26.546265, 41.616576],
							[26.545934, 41.616696],
							[26.545215, 41.617019],
							[26.544872, 41.617183],
							[26.544609, 41.617271],
							[26.54442, 41.617327],
							[26.543943, 41.617419],
							[26.543233, 41.617573],
							[26.542319, 41.617821],
							[26.540353, 41.618397],
							[26.538202, 41.619066],
							[26.5371, 41.619377],
							[26.536026, 41.619696],
							[26.535377, 41.619884],
							[26.53451, 41.620111],
							[26.533883, 41.620297],
							[26.531897, 41.620893],
							[26.530755, 41.62123],
							[26.529639, 41.621552],
							[26.529498, 41.621613],
							[26.529211, 41.62181],
							[26.528707, 41.622198],
							[26.528047, 41.62272],
							[26.526792, 41.623728],
							[26.525786, 41.62452],
							[26.523881, 41.626028],
							[26.522047, 41.627477],
							[26.520654, 41.628582],
							[26.519061, 41.629821],
							[26.517201, 41.631292],
							[26.514741, 41.633225],
							[26.513008, 41.634616],
							[26.511678, 41.635659],
							[26.509214, 41.637588],
							[26.507726, 41.638765],
							[26.506267, 41.639927],
							[26.489757, 41.652948],
							[26.48865, 41.653824],
							[26.488441, 41.654076],
							[26.488136, 41.65457],
							[26.487823, 41.65542],
							[26.487042, 41.657158],
							[26.486309, 41.659811],
							[26.49236, 41.659709],
							[26.493823, 41.659831],
							[26.494327, 41.660062],
							[26.494588, 41.660317],
							[26.494896, 41.660973],
							[26.49288, 41.662443],
							[26.492894, 41.663598],
							[26.493254, 41.66509],
							[26.494584, 41.666965],
							[26.494691, 41.668119],
							[26.494155, 41.670483],
							[26.49335, 41.67191],
							[26.491805, 41.672888],
							[26.489724, 41.673384],
							[26.489209, 41.673384],
							[26.488672, 41.673272],
							[26.488072, 41.673208],
							[26.486668, 41.67309],
							[26.48546, 41.672863],
							[26.484586, 41.672542],
							[26.483075, 41.671979],
							[26.480103, 41.67089],
							[26.474975, 41.669677],
							[26.474685, 41.66962],
							[26.474393, 41.669581],
							[26.474096, 41.669552],
							[26.473797, 41.669522],
							[26.473496, 41.669502],
							[26.472591, 41.669458],
							[26.472292, 41.669447],
							[26.471989, 41.669435],
							[26.471687, 41.669433],
							[26.471385, 41.669439],
							[26.471086, 41.669455],
							[26.47079, 41.669488],
							[26.470501, 41.66953],
							[26.470213, 41.669599],
							[26.469929, 41.669669],
							[26.467942, 41.670198],
							[26.467659, 41.670276],
							[26.467378, 41.670354],
							[26.467099, 41.670441],
							[26.466823, 41.670528],
							[26.459438, 41.673019],
							[26.459171, 41.673124],
							[26.458906, 41.673228],
							[26.458644, 41.673333],
							[26.458382, 41.673447],
							[26.457857, 41.673674],
							[26.457596, 41.673788],
							[26.457079, 41.674024],
							[26.456824, 41.674146],
							[26.456575, 41.674278],
							[26.456329, 41.674409],
							[26.45609, 41.67454],
							[26.455857, 41.674681],
							[26.455631, 41.674829],
							[26.455413, 41.674978],
							[26.455202, 41.675136],
							[26.454996, 41.675293],
							[26.454796, 41.675469],
							[26.454601, 41.675644],
							[26.454411, 41.675819],
							[26.454228, 41.676003],
							[26.45405, 41.676187],
							[26.45388, 41.67638],
							[26.453716, 41.676572],
							[26.453559, 41.676765],
							[26.453409, 41.676957],
							[26.453267, 41.677158],
							[26.453133, 41.67735],
							[26.453034, 41.67756],
							[26.452984, 41.677777],
							[26.452961, 41.678002],
							[26.452539, 41.679299],
							[26.451351, 41.681135],
							[26.450546, 41.682044],
							[26.44879, 41.68327],
							[26.448622, 41.683463],
							[26.44845, 41.683647],
							[26.448274, 41.68383],
							[26.448088, 41.684005],
							[26.447885, 41.684163],
							[26.447664, 41.684321],
							[26.447429, 41.684461],
							[26.447182, 41.684592],
							[26.44693, 41.684715],
							[26.446675, 41.684837],
							[26.446414, 41.684942],
							[26.446142, 41.685047],
							[26.445866, 41.685134],
							[26.441646, 41.686313],
							[26.441365, 41.686391],
							[26.441077, 41.686442],
							[26.440784, 41.686476],
							[26.440486, 41.6865],
							[26.440184, 41.686515],
							[26.439878, 41.686522],
							[26.439271, 41.686499],
							[26.438972, 41.686487],
							[26.438673, 41.686466],
							[26.438363, 41.686428],
							[26.438075, 41.686335],
							[26.434217, 41.681272],
							[26.428482, 41.680871],
							[26.423177, 41.683079],
							[26.418717, 41.683414],
							[26.414971, 41.682794],
							[26.409666, 41.683296],
							[26.408935, 41.686004],
							[26.405894, 41.687729],
							[26.400348, 41.686757],
							[26.392022, 41.689757],
							[26.386201, 41.690971],
							[26.381696, 41.693375],
							[26.376359, 41.69684],
							[26.376115, 41.699718],
							[26.375042, 41.704101],
							[26.371383, 41.707768],
							[26.368342, 41.70937],
							[26.363891, 41.708705],
							[26.358072, 41.708109],
							[26.357961, 41.711128],
							[26.355887, 41.711092],
							[26.355363, 41.711168],
							[26.353843, 41.71114],
							[26.353268, 41.711188],
							[26.347198, 41.711258],
							[26.346642, 41.7118],
							[26.345554, 41.712474],
							[26.344787, 41.71271],
							[26.343662, 41.712825],
							[26.342845, 41.712809],
							[26.339583, 41.713924],
							[26.335873, 41.715282],
							[26.334566, 41.715535],
							[26.332474, 41.714986],
							[26.331052, 41.71476],
							[26.329407, 41.714784],
							[26.32872, 41.715022],
							[26.326659, 41.715285],
							[26.325254, 41.715261],
							[26.323608, 41.714807],
							[26.321532, 41.71352],
							[26.319519, 41.712685],
							[26.31781, 41.711803],
							[26.315702, 41.710026],
							[26.313513, 41.709012],
							[26.311724, 41.708607],
							[26.31067, 41.708845],
							[26.308513, 41.709656],
							[26.307507, 41.709847],
							[26.30567, 41.709775],
							[26.303753, 41.70906],
							[26.302763, 41.709072],
							[26.299137, 41.709406],
							[26.297619, 41.709823],
							[26.296182, 41.710551],
							[26.294697, 41.710598],
							[26.293786, 41.710169],
							[26.287045, 41.711063],
							[26.284857, 41.711469],
							[26.282237, 41.712721],
							[26.279585, 41.712757],
							[26.27711, 41.713305],
							[26.275145, 41.71414],
							[26.272582, 41.715459],
							[26.270881, 41.716128],
							[26.269317, 41.716167],
							[26.267433, 41.716597],
							[26.259539, 41.719163],
							[26.258389, 41.720384],
							[26.256587, 41.721214],
							[26.254772, 41.721938],
							[26.252689, 41.722892],
							[26.251309, 41.723045],
							[26.249559, 41.723112],
							[26.247833, 41.724132],
							[26.246428, 41.725296],
							[26.245725, 41.726221],
							[26.244073, 41.728125],
							[26.241922, 41.729672],
							[26.239076, 41.732265],
							[26.238677, 41.732897],
							[26.237497, 41.734476],
							[26.236597, 41.736192],
							[26.235534, 41.737581],
							[26.234246, 41.738824],
							[26.232825, 41.739625],
							[26.231158, 41.740151],
							[26.229814, 41.740236],
							[26.227156, 41.740221],
							[26.22663, 41.74029],
							[26.226578, 41.740122],
							[26.226502, 41.739982],
							[26.225836, 41.739501],
							[26.225358, 41.738938],
							[26.223834, 41.737515],
							[26.221572, 41.73617],
							[26.21913, 41.734863],
							[26.214584, 41.731954],
							[26.214436, 41.731899],
							[26.214334, 41.731875],
							[26.214168, 41.731911],
							[26.213583, 41.732103],
							[26.213362, 41.732208],
							[26.212353, 41.732536],
							[26.212055, 41.732595],
							[26.211705, 41.732623],
							[26.211192, 41.732596],
							[26.210846, 41.732667],
							[26.210594, 41.732781],
							[26.210344, 41.732847],
							[26.210041, 41.732997],
							[26.209716, 41.733102],
							[26.208752, 41.733355],
							[26.208431, 41.73342],
							[26.208182, 41.733486],
							[26.207379, 41.733666],
							[26.206657, 41.733742],
							[26.206003, 41.733787],
							[26.205567, 41.733779],
							[26.20495, 41.733723],
							[26.204624, 41.733675],
							[26.204338, 41.733613],
							[26.203831, 41.733533],
							[26.203439, 41.733495],
							[26.202993, 41.733471],
							[26.202642, 41.733462],
							[26.202021, 41.733518],
							[26.196457, 41.734788],
							[26.195859, 41.734797],
							[26.195139, 41.734884],
							[26.194016, 41.735194],
							[26.19234, 41.735797],
							[26.188174, 41.73762],
							[26.182531, 41.739199],
							[26.18226, 41.739294],
							[26.181999, 41.739407],
							[26.181744, 41.73952],
							[26.181493, 41.739642],
							[26.181248, 41.739773],
							[26.181008, 41.739903],
							[26.180771, 41.740043],
							[26.180073, 41.740479],
							[26.179614, 41.740776],
							[26.179383, 41.740921],
							[26.179146, 41.74106],
							[26.178854, 41.741267],
							[26.178723, 41.741379],
							[26.178301, 41.741702],
							[26.177883, 41.742025],
							[26.177669, 41.742191],
							[26.177457, 41.742357],
							[26.177254, 41.742523],
							[26.177076, 41.742707],
							[26.176929, 41.742889],
							[26.176836, 41.743098],
							[26.176791, 41.743324],
							[26.176745, 41.743784],
							[26.176729, 41.74401],
							[26.176721, 41.744235],
							[26.176469, 41.745564],
							[26.176086, 41.747337],
							[26.176022, 41.747554],
							[26.175184, 41.748182],
							[26.174917, 41.748409],
							[26.174505, 41.748728],
							[26.173682, 41.748886],
							[26.172493, 41.748759],
							[26.171032, 41.748633],
							[26.169454, 41.748342],
							[26.16654, 41.747644],
							[26.166361, 41.74759],
							[26.162434, 41.745853],
							[26.160774, 41.745587],
							[26.157467, 41.743671],
							[26.150225, 41.74052],
							[26.148521, 41.739886],
							[26.14696, 41.738149],
							[26.146788, 41.737963],
							[26.146581, 41.737796],
							[26.14636, 41.737647],
							[26.146124, 41.737507],
							[26.14587, 41.737386],
							[26.14326, 41.736497],
							[26.140935, 41.735244],
							[26.140662, 41.735168],
							[26.140367, 41.735147],
							[26.139982, 41.735161],
							[26.139757, 41.735158],
							[26.138276, 41.73526],
							[26.137837, 41.735136],
							[26.137454, 41.73554],
							[26.13702, 41.735649],
							[26.135709, 41.735309],
							[26.134398, 41.735316],
							[26.133678, 41.735144],
							[26.133249, 41.734844],
							[26.131145, 41.733897],
							[26.130249, 41.733828],
							[26.130115, 41.733518],
							[26.128982, 41.733491],
							[26.126248, 41.732275],
							[26.124714, 41.730995],
							[26.122722, 41.731131],
							[26.122021, 41.730857],
							[26.121517, 41.730422],
							[26.119218, 41.728303],
							[26.118415, 41.727139],
							[26.117718, 41.725867],
							[26.117055, 41.725276],
							[26.117129, 41.724829],
							[26.116975, 41.724292],
							[26.117005, 41.723615],
							[26.116604, 41.722954],
							[26.116117, 41.722284],
							[26.115853, 41.721674],
							[26.1155, 41.720531],
							[26.115092, 41.719622],
							[26.114133, 41.718408],
							[26.113854, 41.717635],
							[26.113707, 41.716409],
							[26.114013, 41.715447],
							[26.11395, 41.7148],
							[26.113604, 41.714251],
							[26.112689, 41.713659],
							[26.111109, 41.713354],
							[26.110427, 41.713509],
							[26.11039, 41.713509],
							[26.110335, 41.713516],
							[26.109882, 41.713581],
							[26.109818, 41.713581],
							[26.109411, 41.713451],
							[26.10894, 41.713275],
							[26.108877, 41.713276],
							[26.108534, 41.713299],
							[26.108198, 41.713602],
							[26.10759, 41.713819],
							[26.107009, 41.713678],
							[26.106507, 41.713709],
							[26.106498, 41.713722],
							[26.105706, 41.713794],
							[26.10511, 41.714023],
							[26.104831, 41.714337],
							[26.104396, 41.714411],
							[26.104057, 41.714243],
							[26.103899, 41.713972],
							[26.10389, 41.713952],
							[26.103495, 41.713842],
							[26.103165, 41.713699],
							[26.10275, 41.713532],
							[26.102519, 41.713339],
							[26.102418, 41.71317],
							[26.101292, 41.713575],
							[26.098623, 41.714252],
							[26.096114, 41.714615],
							[26.094029, 41.715093],
							[26.089548, 41.7168],
							[26.085905, 41.716914],
							[26.084172, 41.716562],
							[26.082767, 41.716067],
							[26.081086, 41.715363],
							[26.079691, 41.713643],
							[26.078861, 41.712201],
							[26.078557, 41.712193],
							[26.077987, 41.712136],
							[26.076691, 41.711862],
							[26.075592, 41.711483],
							[26.074175, 41.710062],
							[26.073197, 41.710215],
							[26.07216, 41.710328],
							[26.070608, 41.70971],
							[26.070187, 41.709157],
							[26.070412, 41.708125],
							[26.068372, 41.706914],
							[26.067713, 41.706338],
							[26.068155, 41.705449],
							[26.06843, 41.70503],
							[26.068584, 41.704113],
							[26.068479, 41.703926],
							[26.068518, 41.701483],
							[26.069847, 41.699712],
							[26.067871, 41.698475],
							[26.066006, 41.699112],
							[26.065082, 41.699245],
							[26.063956, 41.699498],
							[26.062835, 41.699505],
							[26.062046, 41.699053],
							[26.060965, 41.69857],
							[26.061599, 41.69665],
							[26.063173, 41.693859],
							[26.061738, 41.691502],
							[26.060365, 41.688606],
							[26.060576, 41.687819],
							[26.062748, 41.686133],
							[26.064664, 41.684124],
							[26.065826, 41.683658],
							[26.065823, 41.679316],
							[26.065512, 41.677815],
							[26.065506, 41.676944],
							[26.066506, 41.676618],
							[26.067492, 41.676076],
							[26.067628, 41.674758],
							[26.068633, 41.672401],
							[26.066425, 41.669875],
							[26.064694, 41.66726],
							[26.064255, 41.662425],
							[26.065744, 41.660903],
							[26.068802, 41.657305],
							[26.070114, 41.656044],
							[26.070546, 41.655066],
							[26.071117, 41.653658],
							[26.071047, 41.653416],
							[26.069888, 41.652934],
							[26.069398, 41.651726],
							[26.070573, 41.6506],
							[26.071883, 41.649047],
							[26.073485, 41.647318],
							[26.080794, 41.642436],
							[26.081016, 41.642288],
							[26.081271, 41.642175],
							[26.081542, 41.642071],
							[26.082375, 41.641759],
							[26.082655, 41.641699],
							[26.082924, 41.641739],
							[26.083193, 41.641834],
							[26.083457, 41.641955],
							[26.083719, 41.642076],
							[26.084482, 41.64245],
							[26.084947, 41.642675],
							[26.085443, 41.642936],
							[26.085689, 41.643076],
							[26.085938, 41.643206],
							[26.086195, 41.64331],
							[26.086473, 41.643368],
							[26.086776, 41.643362],
							[26.090053, 41.642916],
							[26.091051, 41.641636],
							[26.091388, 41.640842],
							[26.0914, 41.639892],
							[26.092287, 41.639014],
							[26.092534, 41.637434],
							[26.093757, 41.635546],
							[26.09296, 41.635038],
							[26.09277, 41.634814],
							[26.092582, 41.633655],
							[26.092356, 41.632599],
							[26.091542, 41.631638],
							[26.094213, 41.626822],
							[26.095172, 41.626781],
							[26.097655, 41.626597],
							[26.098863, 41.62671],
							[26.101435, 41.627144],
							[26.102299, 41.627301],
							[26.102916, 41.627325],
							[26.103674, 41.627287],
							[26.104844, 41.627388],
							[26.107666, 41.627136],
							[26.108999, 41.626101],
							[26.109318, 41.625324],
							[26.110686, 41.624963],
							[26.112692, 41.625577],
							[26.113102, 41.62558],
							[26.113416, 41.62555],
							[26.114637, 41.625344],
							[26.115539, 41.625445],
							[26.116315, 41.625346],
							[26.117629, 41.624761],
							[26.118064, 41.624572],
							[26.118735, 41.624253],
							[26.11906, 41.624083],
							[26.119333, 41.623939],
							[26.119649, 41.623916],
							[26.119991, 41.62396],
							[26.120268, 41.624018],
							[26.12068, 41.624143],
							[26.121064, 41.624452],
							[26.122006, 41.625911],
							[26.122293, 41.626841],
							[26.122013, 41.628772],
							[26.123249, 41.631242],
							[26.124519, 41.630612],
							[26.126264, 41.630251],
							[26.128788, 41.630106],
							[26.130639, 41.631751],
							[26.135953, 41.63285],
							[26.137522, 41.632556],
							[26.138013, 41.632329],
							[26.138669, 41.631784],
							[26.1375, 41.629389],
							[26.137994, 41.628434],
							[26.13884, 41.619592],
							[26.138798, 41.619368],
							[26.138735, 41.619144],
							[26.138647, 41.61893],
							[26.1386, 41.61875],
							[26.138386, 41.618561],
							[26.137706, 41.617885],
							[26.1374, 41.617499],
							[26.137286, 41.617258],
							[26.137205, 41.617024],
							[26.137054, 41.616545],
							[26.137321, 41.616212],
							[26.137455, 41.615996],
							[26.138002, 41.614846],
							[26.138359, 41.61359],
							[26.144679, 41.60911],
							[26.145067, 41.608622],
							[26.145656, 41.60803],
							[26.145943, 41.607848],
							[26.146296, 41.607643],
							[26.146598, 41.607435],
							[26.146912, 41.607161],
							[26.147233, 41.606947],
							[26.147512, 41.6068],
							[26.14804, 41.606719],
							[26.148824, 41.606749],
							[26.149554, 41.606989],
							[26.150091, 41.607069],
							[26.150491, 41.607132],
							[26.151041, 41.607234],
							[26.151616, 41.607381],
							[26.152072, 41.607197],
							[26.15258, 41.606378],
							[26.153419, 41.605949],
							[26.153959, 41.605584],
							[26.153895, 41.603744],
							[26.153557, 41.602758],
							[26.151193, 41.601399],
							[26.151083, 41.600797],
							[26.151024, 41.600573],
							[26.150969, 41.600349],
							[26.150923, 41.600124],
							[26.150882, 41.599909],
							[26.150843, 41.599684],
							[26.150807, 41.59946],
							[26.150772, 41.599235],
							[26.150063, 41.596282],
							[26.149704, 41.593083],
							[26.149648, 41.592431],
							[26.14959, 41.591596],
							[26.149546, 41.590727],
							[26.149511, 41.589361],
							[26.149353, 41.587887],
							[26.149254, 41.586874],
							[26.149133, 41.584962],
							[26.148918, 41.582216],
							[26.148629, 41.578836],
							[26.148424, 41.576621],
							[26.148459, 41.576306],
							[26.148155, 41.573623],
							[26.148041, 41.569235],
							[26.147941, 41.565748],
							[26.147827, 41.561554],
							[26.147725, 41.558899],
							[26.147565, 41.556747],
							[26.147556, 41.5555],
							[26.147319, 41.554347],
							[26.150239, 41.553978],
							[26.152332, 41.553698],
							[26.153821, 41.553637],
							[26.159308, 41.553048],
							[26.160078, 41.553048],
							[26.16274, 41.553273],
							[26.164617, 41.552976],
							[26.17026, 41.553245],
							[26.176484, 41.554966],
							[26.178407, 41.552534],
							[26.178498, 41.551906],
							[26.178675, 41.550662],
							[26.179129, 41.548682],
							[26.17914, 41.548635],
							[26.17924, 41.548235],
							[26.179391, 41.547712],
							[26.179433, 41.547419],
							[26.179526, 41.547006],
							[26.179631, 41.546692],
							[26.179882, 41.545945],
							[26.180391, 41.544645],
							[26.180636, 41.543991],
							[26.180777, 41.543592],
							[26.180936, 41.543168],
							[26.181055, 41.542899],
							[26.181138, 41.542644],
							[26.181197, 41.542382],
							[26.181256, 41.542163],
							[26.181361, 41.541832],
							[26.181481, 41.541527],
							[26.181739, 41.540886],
							[26.181676, 41.539986],
							[26.181679, 41.539715],
							[26.18173, 41.539455],
							[26.181846, 41.539181],
							[26.182236, 41.538246],
							[26.182472, 41.535653],
							[26.182843, 41.532215],
							[26.182911, 41.531993],
							[26.183861, 41.529707],
							[26.183767, 41.526281],
							[26.182172, 41.524431],
							[26.182496, 41.523792],
							[26.182682, 41.523415],
							[26.183232, 41.522685],
							[26.183825, 41.521911],
							[26.184081, 41.52151],
							[26.184243, 41.521189],
							[26.184324, 41.520767],
							[26.184385, 41.520471],
							[26.18488, 41.52011],
							[26.184836, 41.519962],
							[26.184141, 41.518128],
							[26.183869, 41.516935],
							[26.182984, 41.516185],
							[26.182099, 41.515935],
							[26.181611, 41.51637],
							[26.181408, 41.516338],
							[26.179704, 41.51578],
							[26.179427, 41.515527],
							[26.179186, 41.515124],
							[26.178073, 41.513215],
							[26.175825, 41.509634],
							[26.171989, 41.503432],
							[26.17186, 41.503122],
							[26.171909, 41.502953],
							[26.172153, 41.502783],
							[26.172789, 41.502609],
							[26.173986, 41.501397],
							[26.174079, 41.500736],
							[26.173808, 41.500252],
							[26.173608, 41.499793],
							[26.172971, 41.498804],
							[26.173009, 41.49823],
							[26.172743, 41.497125],
							[26.172766, 41.496973],
							[26.172878, 41.496682],
							[26.172866, 41.496416],
							[26.172788, 41.496167],
							[26.172708, 41.495952],
							[26.172612, 41.495774],
							[26.172408, 41.495497],
							[26.172137, 41.495221],
							[26.171859, 41.494794],
							[26.171837, 41.494653],
							[26.171837, 41.494495],
							[26.171891, 41.494235],
							[26.171907, 41.49405],
							[26.171871, 41.493905],
							[26.171781, 41.493738],
							[26.170814, 41.492273],
							[26.169316, 41.491661],
							[26.16305, 41.490573],
							[26.160994, 41.489344],
							[26.158795, 41.488062],
							[26.156001, 41.487494],
							[26.153529, 41.486545],
							[26.153495, 41.485749],
							[26.153476, 41.485394],
							[26.153374, 41.485129],
							[26.153278, 41.484931],
							[26.153207, 41.484646],
							[26.153239, 41.484371],
							[26.153207, 41.484114],
							[26.153316, 41.483755],
							[26.153659, 41.483158],
							[26.153575, 41.482924],
							[26.153575, 41.482469],
							[26.153976, 41.481985],
							[26.154166, 41.481196],
							[26.15418, 41.480552],
							[26.154278, 41.479458],
							[26.154292, 41.478919],
							[26.154655, 41.478307],
							[26.154655, 41.477611],
							[26.15485, 41.477265],
							[26.154857, 41.476643],
							[26.154997, 41.476219],
							[26.154997, 41.475614],
							[26.155215, 41.474971],
							[26.155308, 41.474695],
							[26.155249, 41.47446],
							[26.155131, 41.474087],
							[26.155398, 41.473739],
							[26.155807, 41.473684],
							[26.156208, 41.473595],
							[26.156546, 41.47352],
							[26.156778, 41.473355],
							[26.156899, 41.473103],
							[26.157129, 41.472715],
							[26.157424, 41.472458],
							[26.157728, 41.4722],
							[26.158383, 41.472033],
							[26.158529, 41.471701],
							[26.158808, 41.471505],
							[26.159464, 41.471406],
							[26.160378, 41.47116],
							[26.160708, 41.471013],
							[26.161162, 41.47071],
							[26.161198, 41.47045],
							[26.161127, 41.470254],
							[26.161318, 41.470014],
							[26.161581, 41.469851],
							[26.16224, 41.469656],
							[26.16274, 41.469527],
							[26.163155, 41.469374],
							[26.163351, 41.469144],
							[26.163376, 41.468951],
							[26.163787, 41.468644],
							[26.16413, 41.468438],
							[26.164556, 41.468356],
							[26.164735, 41.468222],
							[26.165256, 41.467927],
							[26.165695, 41.467637],
							[26.166282, 41.467281],
							[26.166463, 41.467061],
							[26.166914, 41.466685],
							[26.167358, 41.466585],
							[26.167819, 41.4665],
							[26.168391, 41.466344],
							[26.169101, 41.466263],
							[26.16953, 41.466067],
							[26.170314, 41.465898],
							[26.170815, 41.465706],
							[26.171382, 41.465258],
							[26.171758, 41.465021],
							[26.1721, 41.464925],
							[26.172469, 41.464694],
							[26.173155, 41.46418],
							[26.174357, 41.463794],
							[26.1747, 41.463279],
							[26.175044, 41.462765],
							[26.176417, 41.462122],
							[26.177962, 41.460707],
							[26.178649, 41.460063],
							[26.179679, 41.459163],
							[26.180365, 41.458134],
							[26.180232, 41.457303],
							[26.18097, 41.456613],
							[26.181623, 41.456149],
							[26.182774, 41.455657],
							[26.183124, 41.455191],
							[26.183226, 41.454645],
							[26.183935, 41.453945],
							[26.184485, 41.453245],
							[26.185515, 41.452859],
							[26.186545, 41.452087],
							[26.186986, 41.451815],
							[26.187736, 41.45154],
							[26.188526, 41.450953],
							[26.189463, 41.449899],
							[26.189858, 41.449434],
							[26.189894, 41.448443],
							[26.190087, 41.448158],
							[26.190805, 41.447972],
							[26.191762, 41.447409],
							[26.192705, 41.446753],
							[26.192758, 41.446019],
							[26.192911, 41.445552],
							[26.193087, 41.445023],
							[26.193406, 41.444436],
							[26.193908, 41.443939],
							[26.194493, 41.44273],
							[26.194796, 41.441995],
							[26.195473, 41.441328],
							[26.19654, 41.440865],
							[26.197213, 41.440445],
							[26.197689, 41.439979],
							[26.197928, 41.43948],
							[26.198392, 41.4393],
							[26.198985, 41.439221],
							[26.199792, 41.439008],
							[26.200228, 41.43876],
							[26.201304, 41.438392],
							[26.200168, 41.437256],
							[26.199714, 41.436828],
							[26.199389, 41.436554],
							[26.199065, 41.436334],
							[26.198533, 41.43604],
							[26.198124, 41.435881],
							[26.197547, 41.435759],
							[26.196884, 41.435741],
							[26.196395, 41.435828],
							[26.195768, 41.436026],
							[26.195161, 41.436245],
							[26.194326, 41.436658],
							[26.193353, 41.437098],
							[26.19288, 41.437359],
							[26.192365, 41.437579],
							[26.192011, 41.43776],
							[26.191598, 41.437906],
							[26.191297, 41.438035],
							[26.191153, 41.438093],
							[26.190709, 41.438212],
							[26.190287, 41.43828],
							[26.189808, 41.438366],
							[26.189021, 41.438425],
							[26.188616, 41.438417],
							[26.188088, 41.438313],
							[26.187757, 41.438195],
							[26.187334, 41.437941],
							[26.186858, 41.437629],
							[26.186331, 41.437203],
							[26.185547, 41.436505],
							[26.184818, 41.43577],
							[26.184145, 41.435133],
							[26.183271, 41.434567],
							[26.182264, 41.434018],
							[26.181495, 41.433879],
							[26.180813, 41.433927],
							[26.180222, 41.43405],
							[26.179365, 41.434294],
							[26.178525, 41.434449],
							[26.177558, 41.434443],
							[26.176913, 41.434291],
							[26.176278, 41.434103],
							[26.175627, 41.433907],
							[26.173525, 41.432359],
							[26.172768, 41.431746],
							[26.171755, 41.430557],
							[26.171197, 41.430136],
							[26.170815, 41.429694],
							[26.170622, 41.429247],
							[26.17052, 41.428942],
							[26.17045, 41.428648],
							[26.170373, 41.42828],
							[26.170305, 41.427861],
							[26.17015, 41.427378],
							[26.169767, 41.426359],
							[26.169533, 41.425848],
							[26.168776, 41.424857],
							[26.167746, 41.423886],
							[26.167042, 41.423677],
							[26.163879, 41.422659],
							[26.163612, 41.422556],
							[26.162816, 41.422237],
							[26.162552, 41.422125],
							[26.162291, 41.422013],
							[26.161775, 41.421788],
							[26.161521, 41.421676],
							[26.161276, 41.421546],
							[26.161042, 41.421406],
							[26.160815, 41.421257],
							[26.160377, 41.42095],
							[26.159933, 41.420634],
							[26.159702, 41.420459],
							[26.159538, 41.420281],
							[26.159496, 41.420075],
							[26.159526, 41.419858],
							[26.159601, 41.419641],
							[26.159698, 41.419414],
							[26.161123, 41.416207],
							[26.161806, 41.41497],
							[26.162524, 41.413984],
							[26.163792, 41.411942],
							[26.163899, 41.411733],
							[26.163989, 41.411524],
							[26.16407, 41.411307],
							[26.164141, 41.41108],
							[26.164205, 41.410863],
							[26.164258, 41.410637],
							[26.164308, 41.410419],
							[26.164348, 41.410193],
							[26.164377, 41.409968],
							[26.164384, 41.409743],
							[26.16438, 41.409517],
							[26.164325, 41.408393],
							[26.164312, 41.408168],
							[26.164288, 41.407943],
							[26.164248, 41.407719],
							[26.164189, 41.407494],
							[26.164121, 41.40728],
							[26.164049, 41.407065],
							[26.163974, 41.406841],
							[26.163897, 41.406626],
							[26.163818, 41.406412],
							[26.163649, 41.405974],
							[26.163561, 41.405759],
							[26.163471, 41.405545],
							[26.163379, 41.40533],
							[26.163283, 41.405116],
							[26.163186, 41.404902],
							[26.163087, 41.404687],
							[26.162883, 41.404268],
							[26.162779, 41.404053],
							[26.162566, 41.403634],
							[26.162455, 41.40342],
							[26.162228, 41.403001],
							[26.162108, 41.402796],
							[26.161985, 41.402591],
							[26.161859, 41.402386],
							[26.161728, 41.402182],
							[26.161591, 41.401986],
							[26.16145, 41.401791],
							[26.161303, 41.401595],
							[26.161141, 41.401409],
							[26.160956, 41.401232],
							[26.160753, 41.401065],
							[26.160537, 41.400907],
							[26.159871, 41.400442],
							[26.15921, 41.399986],
							[26.158987, 41.399837],
							[26.158766, 41.399689],
							[26.158096, 41.399233],
							[26.157893, 41.399065],
							[26.157714, 41.398889],
							[26.157555, 41.398694],
							[26.157413, 41.398489],
							[26.157296, 41.398284],
							[26.157213, 41.39806],
							[26.157168, 41.397845],
							[26.157149, 41.39762],
							[26.157149, 41.397395],
							[26.157167, 41.39717],
							[26.157201, 41.396944],
							[26.157247, 41.396718],
							[26.157305, 41.396501],
							[26.157388, 41.396274],
							[26.157504, 41.396065],
							[26.157647, 41.395864],
							[26.157827, 41.395689],
							[26.158331, 41.395384],
							[26.158546, 41.395192],
							[26.158797, 41.394923],
							[26.158895, 41.394714],
							[26.158979, 41.394488],
							[26.15905, 41.394261],
							[26.159108, 41.394035],
							[26.159152, 41.393809],
							[26.159177, 41.393583],
							[26.159187, 41.393367],
							[26.159183, 41.393142],
							[26.159167, 41.392917],
							[26.159141, 41.392692],
							[26.159103, 41.392459],
							[26.158908, 41.392097],
							[26.158594, 41.391649],
							[26.158428, 41.391334],
							[26.158347, 41.391111],
							[26.158427, 41.390742],
							[26.15829, 41.390392],
							[26.15809, 41.390069],
							[26.157666, 41.389731],
							[26.157326, 41.389437],
							[26.156867, 41.389247],
							[26.15636, 41.389086],
							[26.154155, 41.388666],
							[26.153664, 41.388527],
							[26.153093, 41.388337],
							[26.152577, 41.38806],
							[26.151888, 41.386999],
							[26.151627, 41.386423],
							[26.151624, 41.386185],
							[26.151468, 41.38599],
							[26.151312, 41.385794],
							[26.151165, 41.385599],
							[26.151021, 41.385404],
							[26.15094, 41.385062],
							[26.150806, 41.384866],
							[26.150679, 41.38467],
							[26.150562, 41.384474],
							[26.150493, 41.384268],
							[26.150471, 41.384044],
							[26.150488, 41.383818],
							[26.150531, 41.383583],
							[26.150142, 41.38283],
							[26.150224, 41.382184],
							[26.15022, 41.381521],
							[26.149986, 41.380911],
							[26.149308, 41.379765],
							[26.148718, 41.378864],
							[26.148137, 41.3782],
							[26.146887, 41.377258],
							[26.145737, 41.376487],
							[26.145201, 41.376032],
							[26.144386, 41.375596],
							[26.143648, 41.37539],
							[26.143178, 41.375289],
							[26.142263, 41.375244],
							[26.141629, 41.37523],
							[26.141012, 41.375378],
							[26.140283, 41.37571],
							[26.13904, 41.376398],
							[26.137006, 41.37566],
							[26.136389, 41.375354],
							[26.135959, 41.37492],
							[26.136729, 41.371197],
							[26.13709, 41.368552],
							[26.136595, 41.36585],
							[26.134154, 41.36204],
							[26.134031, 41.361835],
							[26.133923, 41.361621],
							[26.133832, 41.361407],
							[26.132295, 41.356995],
							[26.132232, 41.356771],
							[26.132201, 41.356546],
							[26.132214, 41.35633],
							[26.132279, 41.356104],
							[26.132361, 41.355886],
							[26.132417, 41.355669],
							[26.132421, 41.355434],
							[26.132374, 41.355201],
							[26.132219, 41.355015],
							[26.131997, 41.354875],
							[26.131759, 41.354744],
							[26.131506, 41.354614],
							[26.131245, 41.354492],
							[26.130976, 41.35438],
							[26.130704, 41.354277],
							[26.13043, 41.354183],
							[26.13016, 41.354107],
							[26.129865, 41.354068],
							[26.129556, 41.354091],
							[26.125574, 41.354876],
							[26.125289, 41.354936],
							[26.125008, 41.355013],
							[26.12473, 41.355099],
							[26.123075, 41.355643],
							[26.122802, 41.355738],
							[26.122535, 41.355842],
							[26.122276, 41.355946],
							[26.122027, 41.356068],
							[26.121702, 41.356307],
							[26.120023, 41.355956],
							[26.118072, 41.355298],
							[26.115497, 41.353877],
							[26.11502, 41.353641],
							[26.114765, 41.353547],
							[26.114432, 41.353396],
							[26.113842, 41.353181],
							[26.113297, 41.353016],
							[26.112856, 41.352951],
							[26.112511, 41.353048],
							[26.11216, 41.353194],
							[26.111779, 41.353441],
							[26.111317, 41.353848],
							[26.110958, 41.354254],
							[26.110441, 41.354868],
							[26.11029, 41.35508],
							[26.109891, 41.355713],
							[26.109454, 41.356213],
							[26.109158, 41.356605],
							[26.108827, 41.356954],
							[26.108456, 41.357191],
							[26.108196, 41.357313],
							[26.107899, 41.357405],
							[26.107592, 41.357428],
							[26.107187, 41.357417],
							[26.10663, 41.357247],
							[26.106164, 41.35704],
							[26.105486, 41.35661],
							[26.105299, 41.356433],
							[26.105124, 41.356247],
							[26.104953, 41.356061],
							[26.104786, 41.355875],
							[26.104622, 41.355689],
							[26.104466, 41.355494],
							[26.104312, 41.355298],
							[26.104155, 41.355103],
							[26.103989, 41.354917],
							[26.103808, 41.35474],
							[26.103397, 41.354414],
							[26.103175, 41.354256],
							[26.102945, 41.354107],
							[26.102704, 41.353958],
							[26.102458, 41.353837],
							[26.1022, 41.353724],
							[26.101935, 41.353639],
							[26.101639, 41.353555],
							[26.10132, 41.353479],
							[26.100991, 41.353422],
							[26.100697, 41.353323],
							[26.100424, 41.353316],
							[26.10011, 41.353315],
							[26.099885, 41.353355],
							[26.099691, 41.353418],
							[26.099427, 41.353579],
							[26.099212, 41.353758],
							[26.099107, 41.353974],
							[26.099022, 41.354309],
							[26.099165, 41.354619],
							[26.099571, 41.354925],
							[26.099907, 41.355078],
							[26.100253, 41.355322],
							[26.100488, 41.355629],
							[26.100786, 41.356067],
							[26.100826, 41.356366],
							[26.100732, 41.356586],
							[26.100394, 41.356725],
							[26.099998, 41.356736],
							[26.099155, 41.356537],
							[26.098725, 41.356273],
							[26.098361, 41.355978],
							[26.09765, 41.355444],
							[26.09649, 41.354341],
							[26.094834, 41.352844],
							[26.094626, 41.352538],
							[26.093878, 41.351742],
							[26.093218, 41.351395],
							[26.092672, 41.351175],
							[26.092174, 41.350816],
							[26.091872, 41.350417],
							[26.091488, 41.349915],
							[26.091242, 41.349579],
							[26.091146, 41.34934],
							[26.091337, 41.349],
							[26.091713, 41.348798],
							[26.092335, 41.348612],
							[26.092614, 41.348601],
							[26.093069, 41.34824],
							[26.093113, 41.348014],
							[26.093101, 41.34778],
							[26.093087, 41.347546],
							[26.093054, 41.347313],
							[26.092976, 41.347098],
							[26.092802, 41.346939],
							[26.092511, 41.346845],
							[26.09221, 41.346806],
							[26.088255, 41.346787],
							[26.087068, 41.346746],
							[26.083793, 41.346508],
							[26.083199, 41.346465],
							[26.082903, 41.346425],
							[26.082611, 41.346385],
							[26.082323, 41.346336],
							[26.082043, 41.346251],
							[26.081765, 41.346166],
							[26.081487, 41.346072],
							[26.081206, 41.346005],
							[26.080916, 41.345957],
							[26.080617, 41.345944],
							[26.080315, 41.345949],
							[26.080014, 41.345964],
							[26.079722, 41.346005],
							[26.079433, 41.346055],
							[26.075438, 41.346902],
							[26.075154, 41.346979],
							[26.074876, 41.347065],
							[26.074602, 41.34716],
							[26.074335, 41.347255],
							[26.074072, 41.347359],
							[26.073813, 41.347472],
							[26.073554, 41.347585],
							[26.0733, 41.347706],
							[26.073047, 41.347828],
							[26.072795, 41.347949],
							[26.072545, 41.348071],
							[26.072301, 41.348202],
							[26.07206, 41.348332],
							[26.071821, 41.348472],
							[26.07158, 41.348602],
							[26.071337, 41.348732],
							[26.07109, 41.348863],
							[26.070837, 41.348985],
							[26.07058, 41.349106],
							[26.070322, 41.349228],
							[26.070057, 41.349332],
							[26.069788, 41.349436],
							[26.069512, 41.349504],
							[26.069226, 41.349563],
							[26.068925, 41.349613],
							[26.068617, 41.349646],
							[26.068307, 41.349661],
							[26.068005, 41.349657],
							[26.067717, 41.349626],
							[26.067448, 41.349559],
							[26.067192, 41.349455],
							[26.066945, 41.349334],
							[26.066703, 41.349194],
							[26.066466, 41.349045],
							[26.066228, 41.348896],
							[26.065989, 41.348757],
							[26.065265, 41.348346],
							[26.065021, 41.348216],
							[26.06477, 41.348094],
							[26.064505, 41.348],
							[26.06423, 41.347914],
							[26.063949, 41.347829],
							[26.063662, 41.347753],
							[26.063372, 41.347687],
							[26.063079, 41.347638],
							[26.062785, 41.347598],
							[26.062492, 41.347567],
							[26.059982, 41.347329],
							[26.054597, 41.346794],
							[26.053531, 41.346674],
							[26.053229, 41.346652],
							[26.052928, 41.34664],
							[26.052634, 41.346636],
							[26.052347, 41.34665],
							[26.052407, 41.346898],
							[26.051763, 41.347133],
							[26.049913, 41.347614],
							[26.048772, 41.348272],
							[26.044425, 41.349736],
							[26.044143, 41.349813],
							[26.043576, 41.349958],
							[26.043291, 41.350027],
							[26.042719, 41.350154],
							[26.04243, 41.350213],
							[26.04214, 41.350263],
							[26.041848, 41.350313],
							[26.041554, 41.350355],
							[26.040963, 41.350428],
							[26.040667, 41.35046],
							[26.039473, 41.350581],
							[26.038871, 41.350627],
							[26.038573, 41.350633],
							[26.038278, 41.350629],
							[26.037986, 41.350607],
							[26.037697, 41.350567],
							[26.032084, 41.349685],
							[26.03183, 41.349626],
							[26.031625, 41.349531],
							[26.031516, 41.349352],
							[26.031484, 41.349128],
							[26.031489, 41.348866],
							[26.031489, 41.348614],
							[26.031452, 41.348381],
							[26.031408, 41.348156],
							[26.031358, 41.347941],
							[26.031294, 41.347717],
							[26.031219, 41.347484],
							[26.031128, 41.347243],
							[26.03095, 41.347093],
							[26.030691, 41.347052],
							[26.02736, 41.346544],
							[26.027081, 41.346566],
							[26.026782, 41.346626],
							[26.025278, 41.346733],
							[26.021741, 41.346426],
							[26.02145, 41.346395],
							[26.012312, 41.344565],
							[26.012024, 41.344507],
							[26.011739, 41.344449],
							[26.011461, 41.344373],
							[26.011203, 41.34426],
							[26.009658, 41.34353],
							[26.009403, 41.3434],
							[26.009166, 41.343269],
							[26.008952, 41.34312],
							[26.008775, 41.34296],
							[26.004564, 41.339125],
							[26.004384, 41.338939],
							[26.004234, 41.338734],
							[26.004127, 41.338529],
							[26.004065, 41.338323],
							[26.004025, 41.338099],
							[26.004002, 41.337883],
							[26.003991, 41.337658],
							[26.003991, 41.337433],
							[26.003999, 41.337198],
							[26.004025, 41.336288],
							[26.004021, 41.33455],
							[26.003999, 41.333866],
							[26.003975, 41.333641],
							[26.003927, 41.333425],
							[26.003101, 41.331981],
							[26.002961, 41.331785],
							[25.998926, 41.326974],
							[25.997837, 41.326218],
							[25.997584, 41.326087],
							[25.997313, 41.325993],
							[25.997021, 41.325953],
							[25.996718, 41.325958],
							[25.993493, 41.326203],
							[25.993196, 41.326226],
							[25.992329, 41.326394],
							[25.99204, 41.326453],
							[25.991757, 41.326521],
							[25.991468, 41.32658],
							[25.991172, 41.326613],
							[25.990872, 41.326627],
							[25.990575, 41.326614],
							[25.990296, 41.326529],
							[25.990037, 41.326416],
							[25.989012, 41.325956],
							[25.988705, 41.325816],
							[25.985461, 41.324334],
							[25.983085, 41.323195],
							[25.980587, 41.321598],
							[25.977393, 41.319384],
							[25.977184, 41.319216],
							[25.976768, 41.31889],
							[25.976554, 41.318731],
							[25.976329, 41.318591],
							[25.97609, 41.318451],
							[25.975842, 41.318311],
							[25.975584, 41.318199],
							[25.975314, 41.318113],
							[25.975027, 41.318064],
							[25.974724, 41.31806],
							[25.974241, 41.318066],
							[25.970336, 41.31811],
							[25.966847, 41.31815],
							[25.966552, 41.318173],
							[25.963888, 41.318507],
							[25.963602, 41.318557],
							[25.963332, 41.318642],
							[25.963077, 41.318755],
							[25.962829, 41.318876],
							[25.962584, 41.319016],
							[25.962094, 41.319285],
							[25.961841, 41.319398],
							[25.961055, 41.319744],
							[25.960791, 41.319857],
							[25.960526, 41.319969],
							[25.960255, 41.320064],
							[25.959982, 41.32015],
							[25.959703, 41.320226],
							[25.95942, 41.320285],
							[25.959131, 41.320317],
							[25.958839, 41.320349],
							[25.958546, 41.320372],
							[25.952567, 41.320618],
							[25.949409, 41.320064],
							[25.946639, 41.319539],
							[25.946345, 41.319499],
							[25.946052, 41.319485],
							[25.945751, 41.319491],
							[25.945449, 41.319505],
							[25.945149, 41.319546],
							[25.944861, 41.319605],
							[25.944585, 41.319681],
							[25.944316, 41.319776],
							[25.944048, 41.319879],
							[25.943784, 41.319983],
							[25.943523, 41.320096],
							[25.94326, 41.320208],
							[25.942928, 41.320344],
							[25.942733, 41.320424],
							[25.941927, 41.320735],
							[25.941657, 41.32083],
							[25.941382, 41.320924],
							[25.941102, 41.321001],
							[25.940817, 41.321051],
							[25.940525, 41.321074],
							[25.936942, 41.321179],
							[25.936643, 41.321184],
							[25.936339, 41.32118],
							[25.936036, 41.321168],
							[25.935742, 41.321128],
							[25.935459, 41.321069],
							[25.935182, 41.320984],
							[25.934913, 41.320871],
							[25.934665, 41.32074],
							[25.934452, 41.320582],
							[25.932221, 41.318503],
							[25.930762, 41.317095],
							[25.930615, 41.316899],
							[25.930295, 41.316517],
							[25.93013, 41.316331],
							[25.92945, 41.315577],
							[25.929273, 41.3154],
							[25.929075, 41.315232],
							[25.928849, 41.315092],
							[25.928595, 41.314988],
							[25.928324, 41.314893],
							[25.925836, 41.314159],
							[25.92468, 41.313829],
							[25.923674, 41.3136],
							[25.922989, 41.313479],
							[25.922675, 41.313482],
							[25.922391, 41.31353],
							[25.922081, 41.313641],
							[25.921562, 41.313851],
							[25.921017, 41.314019],
							[25.920697, 41.314049],
							[25.920098, 41.314053],
							[25.919865, 41.314006],
							[25.919519, 41.313924],
							[25.919027, 41.313702],
							[25.918136, 41.313114],
							[25.917315, 41.3125],
							[25.914966, 41.311088],
							[25.911451, 41.30899],
							[25.910975, 41.308758],
							[25.9102, 41.308452],
							[25.907456, 41.307683],
							[25.906914, 41.307594],
							[25.903132, 41.306524],
							[25.901328, 41.30628],
							[25.898305, 41.305985],
							[25.89749, 41.306094],
							[25.896034, 41.306541],
							[25.895121, 41.306929],
							[25.893725, 41.307302],
							[25.893493, 41.307327],
							[25.893043, 41.307232],
							[25.892626, 41.307053],
							[25.89043, 41.305756],
							[25.890251, 41.305681],
							[25.886982, 41.30507],
							[25.885983, 41.304752],
							[25.88552, 41.304673],
							[25.884128, 41.30436],
							[25.883919, 41.304409],
							[25.880694, 41.306011],
							[25.879095, 41.307168],
							[25.877786, 41.308268],
							[25.876594, 41.30921],
							[25.873597, 41.311318],
							[25.873345, 41.311427],
							[25.872896, 41.311467],
							[25.872194, 41.311472],
							[25.871354, 41.311546],
							[25.870798, 41.31169],
							[25.870123, 41.311959],
							[25.86896, 41.312571],
							[25.868674, 41.312739],
							[25.868562, 41.312797],
							[25.868568, 41.312958],
							[25.868588, 41.313266],
							[25.868707, 41.313639],
							[25.869124, 41.314389],
							[25.86966, 41.31511],
							[25.870606, 41.316476],
							[25.87105, 41.316919],
							[25.871407, 41.317411],
							[25.871996, 41.31826],
							[25.872148, 41.318897],
							[25.872254, 41.319254],
							[25.873001, 41.320427],
							[25.873061, 41.320631],
							[25.873074, 41.32076],
							[25.873028, 41.320929],
							[25.872783, 41.321322],
							[25.872684, 41.321674],
							[25.872604, 41.322325],
							[25.872326, 41.322807],
							[25.872313, 41.323468],
							[25.87238, 41.323935],
							[25.872585, 41.324298],
							[25.872591, 41.32471],
							[25.872737, 41.325247],
							[25.872909, 41.325505],
							[25.872882, 41.325898],
							[25.872896, 41.326469],
							[25.872909, 41.327026],
							[25.872743, 41.327612],
							[25.872714, 41.328001],
							[25.87234, 41.328238],
							[25.871835, 41.328471],
							[25.87116, 41.328659],
							[25.870065, 41.328983],
							[25.869145, 41.32907],
							[25.868379, 41.329102],
							[25.867399, 41.328997],
							[25.866512, 41.32903],
							[25.865836, 41.329001],
							[25.864584, 41.328869],
							[25.8634, 41.329169],
							[25.862901, 41.329127],
							[25.862449, 41.329059],
							[25.86201, 41.328914],
							[25.861735, 41.328762],
							[25.86167, 41.328636],
							[25.861541, 41.328339],
							[25.860904, 41.328025],
							[25.860629, 41.327877],
							[25.860349, 41.327696],
							[25.860069, 41.327411],
							[25.859717, 41.327153],
							[25.85908, 41.326826],
							[25.858653, 41.326997],
							[25.85824, 41.32714],
							[25.8579, 41.327224],
							[25.857457, 41.327288],
							[25.856652, 41.327405],
							[25.856454, 41.327427],
							[25.856226, 41.327537],
							[25.856093, 41.32767],
							[25.855938, 41.327741],
							[25.85577, 41.327744],
							[25.855409, 41.327612],
							[25.85509, 41.327495],
							[25.854806, 41.327424],
							[25.854578, 41.327414],
							[25.854277, 41.327424],
							[25.853601, 41.327838],
							[25.85327, 41.328009],
							[25.852883, 41.328255],
							[25.852086, 41.328791],
							[25.851927, 41.328936],
							[25.851755, 41.329602],
							[25.851678, 41.330087],
							[25.851626, 41.330278],
							[25.851505, 41.330404],
							[25.851316, 41.330581],
							[25.851204, 41.330727],
							[25.850959, 41.331147],
							[25.850825, 41.331409],
							[25.850752, 41.331674],
							[25.850722, 41.331845],
							[25.850752, 41.331955],
							[25.850813, 41.332077],
							[25.85095, 41.332223],
							[25.851424, 41.332507],
							[25.851105, 41.33328],
							[25.850421, 41.333603],
							[25.850111, 41.333806],
							[25.849414, 41.334052],
							[25.849018, 41.33421],
							[25.848829, 41.334333],
							[25.848704, 41.334485],
							[25.848639, 41.33464],
							[25.84854, 41.334818],
							[25.848416, 41.334976],
							[25.848243, 41.335047],
							[25.847994, 41.335141],
							[25.847813, 41.335238],
							[25.847723, 41.335344],
							[25.847632, 41.335538],
							[25.847589, 41.335739],
							[25.847559, 41.335984],
							[25.847469, 41.336191],
							[25.847305, 41.336375],
							[25.847, 41.336608],
							[25.846737, 41.336808],
							[25.846556, 41.336986],
							[25.846406, 41.337167],
							[25.846212, 41.337335],
							[25.84604, 41.337441],
							[25.845726, 41.337539],
							[25.845274, 41.337652],
							[25.844891, 41.337761],
							[25.844383, 41.3379],
							[25.843867, 41.338098],
							[25.843613, 41.338159],
							[25.843338, 41.338211],
							[25.843118, 41.338298],
							[25.842933, 41.33843],
							[25.842744, 41.33864],
							[25.842511, 41.338918],
							[25.842042, 41.338715],
							[25.841724, 41.338643],
							[25.84153, 41.338634],
							[25.841035, 41.338698],
							[25.840446, 41.33874],
							[25.839886, 41.338844],
							[25.839314, 41.339012],
							[25.838767, 41.339225],
							[25.838337, 41.339503],
							[25.837889, 41.339829],
							[25.837627, 41.339965],
							[25.837455, 41.34003],
							[25.837214, 41.340055],
							[25.836469, 41.340068],
							[25.835036, 41.340573],
							[25.834567, 41.341348],
							[25.834434, 41.341496],
							[25.834063, 41.341645],
							[25.833689, 41.341816],
							[25.833474, 41.341952],
							[25.833315, 41.342143],
							[25.833138, 41.34244],
							[25.832992, 41.342792],
							[25.832979, 41.343028],
							[25.833143, 41.343668],
							[25.833087, 41.344073],
							[25.833026, 41.344224],
							[25.832898, 41.344325],
							[25.832722, 41.34437],
							[25.83243, 41.344425],
							[25.8319, 41.344521],
							[25.831474, 41.344658],
							[25.830799, 41.344928],
							[25.830208, 41.345097],
							[25.829423, 41.34522],
							[25.828699, 41.345394],
							[25.827951, 41.345641],
							[25.827159, 41.346033],
							[25.826496, 41.346203],
							[25.826338, 41.346276],
							[25.826015, 41.346399],
							[25.825674, 41.346632],
							[25.825327, 41.346851],
							[25.82466, 41.347255],
							[25.824317, 41.347576],
							[25.824255, 41.347726],
							[25.823752, 41.347818],
							[25.823346, 41.34789],
							[25.822938, 41.347962],
							[25.822597, 41.348072],
							[25.822366, 41.348113],
							[25.822061, 41.348091],
							[25.821562, 41.347955],
							[25.821263, 41.347538],
							[25.821044, 41.347147],
							[25.820991, 41.346866],
							[25.821004, 41.346558],
							[25.821093, 41.346241],
							[25.821231, 41.345955],
							[25.821236, 41.345838],
							[25.821198, 41.345658],
							[25.820899, 41.344944],
							[25.820474, 41.344265],
							[25.820226, 41.343724],
							[25.819772, 41.343059],
							[25.819601, 41.342799],
							[25.819547, 41.342636],
							[25.819585, 41.342381],
							[25.819761, 41.342043],
							[25.819765, 41.341825],
							[25.819667, 41.341541],
							[25.819437, 41.341198],
							[25.819429, 41.340904],
							[25.819521, 41.340319],
							[25.819529, 41.340082],
							[25.819456, 41.33987],
							[25.819189, 41.339598],
							[25.818164, 41.338934],
							[25.817672, 41.338733],
							[25.817268, 41.337972],
							[25.816699, 41.33762],
							[25.816078, 41.337482],
							[25.81572, 41.337494],
							[25.814847, 41.337093],
							[25.814647, 41.336796],
							[25.81421, 41.336419],
							[25.813914, 41.336077],
							[25.813755, 41.335976],
							[25.813334, 41.33602],
							[25.812833, 41.336292],
							[25.812073, 41.336517],
							[25.81205, 41.33675],
							[25.811991, 41.336908],
							[25.811738, 41.337199],
							[25.811624, 41.337292],
							[25.81136, 41.337398],
							[25.810988, 41.337483],
							[25.810789, 41.337462],
							[25.81054, 41.337487],
							[25.810278, 41.337599],
							[25.809813, 41.337854],
							[25.809557, 41.337902],
							[25.809348, 41.337851],
							[25.809204, 41.337702],
							[25.808963, 41.337462],
							[25.808755, 41.337325],
							[25.808577, 41.337294],
							[25.807898, 41.337265],
							[25.80688, 41.337286],
							[25.80648, 41.337286],
							[25.806217, 41.337229],
							[25.805896, 41.33708],
							[25.805454, 41.336831],
							[25.804928, 41.336504],
							[25.804463, 41.336101],
							[25.804186, 41.335936],
							[25.803922, 41.335866],
							[25.803633, 41.335795],
							[25.803401, 41.335714],
							[25.803016, 41.335453],
							[25.802391, 41.33517],
							[25.801975, 41.335043],
							[25.801671, 41.334842],
							[25.80186, 41.334522],
							[25.801894, 41.334224],
							[25.801752, 41.333877],
							[25.801839, 41.333609],
							[25.80172, 41.333095],
							[25.800636, 41.332949],
							[25.800324, 41.332858],
							[25.799965, 41.33236],
							[25.798744, 41.333212],
							[25.798625, 41.333544],
							[25.798447, 41.333714],
							[25.798113, 41.333922],
							[25.797908, 41.333968],
							[25.797604, 41.33396],
							[25.797021, 41.333978],
							[25.796536, 41.33409],
							[25.795086, 41.334026],
							[25.794151, 41.33374],
							[25.793924, 41.333695],
							[25.793693, 41.333728],
							[25.793331, 41.333857],
							[25.792905, 41.33372],
							[25.792123, 41.333363],
							[25.79111, 41.332752],
							[25.790942, 41.33295],
							[25.790759, 41.333088],
							[25.789648, 41.332954],
							[25.789147, 41.332825],
							[25.788942, 41.332687],
							[25.788489, 41.331812],
							[25.788333, 41.331578],
							[25.787698, 41.330872],
							[25.787332, 41.330414],
							[25.787123, 41.330042],
							[25.786993, 41.329853],
							[25.786753, 41.329693],
							[25.785578, 41.32967],
							[25.784892, 41.329667],
							[25.784728, 41.329547],
							[25.784557, 41.329403],
							[25.784343, 41.329183],
							[25.784213, 41.328702],
							[25.784019, 41.328333],
							[25.783889, 41.328144],
							[25.783691, 41.327975],
							[25.783813, 41.327568],
							[25.783904, 41.327222],
							[25.783939, 41.326769],
							[25.783935, 41.326526],
							[25.78387, 41.326231],
							[25.783721, 41.32601],
							[25.783546, 41.325824],
							[25.782921, 41.325192],
							[25.782719, 41.325043],
							[25.782619, 41.325014],
							[25.782242, 41.325028],
							[25.78204, 41.324931],
							[25.781656, 41.3245],
							[25.781363, 41.323988],
							[25.78106, 41.323654],
							[25.781457, 41.32249],
							[25.781791, 41.321348],
							[25.780864, 41.321465],
							[25.780002, 41.321745],
							[25.77949, 41.321828],
							[25.779349, 41.321849],
							[25.777651, 41.32131],
							[25.777543, 41.321241],
							[25.777451, 41.320931],
							[25.777486, 41.320125],
							[25.777039, 41.320188],
							[25.776858, 41.320247],
							[25.776672, 41.32049],
							[25.776332, 41.320664],
							[25.775496, 41.321446],
							[25.775205, 41.321703],
							[25.774876, 41.321695],
							[25.774801, 41.321687],
							[25.77413, 41.321367],
							[25.773776, 41.321205],
							[25.773426, 41.32115],
							[25.772862, 41.321],
							[25.77242, 41.320911],
							[25.772051, 41.320903],
							[25.771827, 41.320759],
							[25.771471, 41.32038],
							[25.771049, 41.319918],
							[25.770062, 41.319412],
							[25.769798, 41.31929],
							[25.76955, 41.319404],
							[25.769124, 41.319789],
							[25.768655, 41.320274],
							[25.768195, 41.320664],
							[25.76749, 41.32103],
							[25.767124, 41.32152],
							[25.7667, 41.321909],
							[25.765774, 41.322963],
							[25.765526, 41.323206],
							[25.765107, 41.323413],
							[25.76471, 41.323656],
							[25.763606, 41.324512],
							[25.763237, 41.324598],
							[25.762897, 41.324587],
							[25.762506, 41.324528],
							[25.761862, 41.324368],
							[25.761509, 41.324192],
							[25.761174, 41.324042],
							[25.760859, 41.323999],
							[25.760754, 41.32386],
							[25.760242, 41.322928],
							[25.759353, 41.321899],
							[25.759284, 41.321251],
							[25.758808, 41.320793],
							[25.75811, 41.32051],
							[25.75795, 41.320358],
							[25.757637, 41.319089],
							[25.757572, 41.318961],
							[25.757439, 41.31886],
							[25.75716, 41.318909],
							[25.756974, 41.319095],
							[25.756852, 41.31925],
							[25.756707, 41.31929],
							[25.756531, 41.319201],
							[25.756325, 41.318992],
							[25.756203, 41.318706],
							[25.755967, 41.318433],
							[25.755746, 41.318265],
							[25.7553, 41.318539],
							[25.754705, 41.318754],
							[25.754369, 41.318929],
							[25.754114, 41.319167],
							[25.753889, 41.319476],
							[25.753549, 41.319593],
							[25.752634, 41.319539],
							[25.751609, 41.319639],
							[25.751253, 41.319526],
							[25.750808, 41.319356],
							[25.749794, 41.319055],
							[25.748825, 41.318522],
							[25.748692, 41.318413],
							[25.748471, 41.31695],
							[25.748375, 41.316813],
							[25.747982, 41.316692],
							[25.746503, 41.316417],
							[25.746274, 41.315813],
							[25.74603, 41.315664],
							[25.74497, 41.315172],
							[25.744543, 41.315023],
							[25.744082, 41.315905],
							[25.744271, 41.316423],
							[25.744271, 41.316616],
							[25.744009, 41.316982],
							[25.743788, 41.317124],
							[25.742915, 41.317304],
							[25.742103, 41.317436],
							[25.741286, 41.317833],
							[25.741283, 41.318126],
							[25.741184, 41.318428],
							[25.740944, 41.318647],
							[25.740628, 41.318839],
							[25.74024, 41.318884],
							[25.739215, 41.318892],
							[25.738272, 41.318791],
							[25.737518, 41.318419],
							[25.737085, 41.318531],
							[25.736702, 41.318564],
							[25.736174, 41.318539],
							[25.735575, 41.318446],
							[25.735079, 41.318284],
							[25.734583, 41.318017],
							[25.734227, 41.317847],
							[25.733855, 41.317794],
							[25.733424, 41.317778],
							[25.733106, 41.317796],
							[25.732538, 41.317882],
							[25.732222, 41.317971],
							[25.732111, 41.318106],
							[25.73181, 41.318512],
							[25.731589, 41.318753],
							[25.731268, 41.319036],
							[25.731054, 41.31923],
							[25.730719, 41.319111],
							[25.730559, 41.319033],
							[25.73025, 41.31881],
							[25.730044, 41.318595],
							[25.729758, 41.318214],
							[25.729564, 41.318088],
							[25.729171, 41.317965],
							[25.728755, 41.317945],
							[25.728481, 41.317885],
							[25.728008, 41.317679],
							[25.727631, 41.31765],
							[25.727295, 41.317561],
							[25.726792, 41.317321],
							[25.726353, 41.317266],
							[25.726022, 41.317192],
							[25.725629, 41.317223],
							[25.725328, 41.317349],
							[25.725064, 41.317579],
							[25.724557, 41.317974],
							[25.724485, 41.318197],
							[25.723768, 41.318804],
							[25.723547, 41.31896],
							[25.723291, 41.318885],
							[25.722709, 41.318635],
							[25.722245, 41.318493],
							[25.721129, 41.317999],
							[25.720789, 41.317866],
							[25.720045, 41.317428],
							[25.719581, 41.317278],
							[25.719279, 41.31709],
							[25.719009, 41.316922],
							[25.718573, 41.316748],
							[25.718362, 41.316578],
							[25.717758, 41.315695],
							[25.717742, 41.315512],
							[25.717807, 41.314281],
							[25.717904, 41.313179],
							[25.717866, 41.313026],
							[25.717791, 41.312823],
							[25.716987, 41.312568],
							[25.716793, 41.312118],
							[25.716718, 41.311653],
							[25.716604, 41.310409],
							[25.716637, 41.309761],
							[25.71696, 41.308736],
							[25.716178, 41.307958],
							[25.715666, 41.30729],
							[25.715456, 41.3068],
							[25.714593, 41.304605],
							[25.714364, 41.304242],
							[25.71383, 41.303675],
							[25.713021, 41.303062],
							[25.712243, 41.302584],
							[25.711961, 41.302378],
							[25.71187, 41.302166],
							[25.71179, 41.301816],
							[25.711965, 41.301315],
							[25.712182, 41.300894],
							[25.71232, 41.299702],
							[25.712236, 41.298685],
							[25.712373, 41.298081],
							[25.712541, 41.2978],
							[25.712758, 41.297683],
							[25.713296, 41.297528],
							[25.712892, 41.29668],
							[25.712735, 41.295769],
							[25.713002, 41.295591],
							[25.713223, 41.295356],
							[25.713475, 41.294975],
							[25.713677, 41.294182],
							[25.714157, 41.293557],
							[25.714313, 41.293124],
							[25.71445, 41.292748],
							[25.713941, 41.292431],
							[25.713547, 41.292248],
							[25.71245, 41.291719],
							[25.711695, 41.291017],
							[25.711137, 41.29104],
							[25.710446, 41.291188],
							[25.708891, 41.291482],
							[25.708162, 41.291556],
							[25.707009, 41.291578],
							[25.706075, 41.291638],
							[25.705549, 41.291693],
							[25.703101, 41.292248],
							[25.702102, 41.2924],
							[25.701534, 41.292924],
							[25.70035, 41.293668],
							[25.699925, 41.293996],
							[25.699603, 41.294168],
							[25.699406, 41.294291],
							[25.697722, 41.295838],
							[25.697247, 41.295896],
							[25.696455, 41.295873],
							[25.696339, 41.296045],
							[25.696164, 41.296624],
							[25.695347, 41.299348],
							[25.694835, 41.300348],
							[25.694679, 41.301357],
							[25.694075, 41.30145],
							[25.69325, 41.301515],
							[25.692014, 41.30186],
							[25.690054, 41.302886],
							[25.688801, 41.303604],
							[25.687595, 41.303785],
							[25.686606, 41.304373],
							[25.686101, 41.304363],
							[25.685853, 41.304386],
							[25.685403, 41.304488],
							[25.685024, 41.304685],
							[25.684501, 41.305062],
							[25.684194, 41.305446],
							[25.683752, 41.306155],
							[25.683431, 41.306847],
							[25.682844, 41.306663],
							[25.682243, 41.306515],
							[25.680696, 41.306308],
							[25.679261, 41.306183],
							[25.678118, 41.306158],
							[25.675229, 41.306745],
							[25.67463, 41.30681],
							[25.6732, 41.307072],
							[25.671356, 41.307294],
							[25.670834, 41.307384],
							[25.670391, 41.30737],
							[25.670019, 41.307444],
							[25.667808, 41.307914],
							[25.666218, 41.308205],
							[25.66415, 41.30877],
							[25.663656, 41.309414],
							[25.66305, 41.309967],
							[25.662216, 41.310518],
							[25.661393, 41.310881],
							[25.660854, 41.311209],
							[25.660623, 41.311405],
							[25.660444, 41.311608],
							[25.660154, 41.312568],
							[25.660064, 41.313183],
							[25.660008, 41.313376],
							[25.659696, 41.313651],
							[25.659235, 41.313977],
							[25.658728, 41.314243],
							[25.658116, 41.314544],
							[25.657126, 41.314342],
							[25.655899, 41.313983],
							[25.654746, 41.313437],
							[25.654273, 41.313246],
							[25.653827, 41.312942],
							[25.653606, 41.312816],
							[25.652214, 41.312875],
							[25.651829, 41.312932],
							[25.651358, 41.312842],
							[25.651072, 41.312808],
							[25.650622, 41.31277],
							[25.650405, 41.312834],
							[25.650004, 41.313037],
							[25.648761, 41.313781],
							[25.648127, 41.314209],
							[25.647546, 41.314172],
							[25.647367, 41.314097],
							[25.647217, 41.313974],
							[25.646462, 41.313069],
							[25.646039, 41.312442],
							[25.645814, 41.312196],
							[25.645497, 41.312015],
							[25.644929, 41.311881],
							[25.644353, 41.311692],
							[25.643949, 41.31152],
							[25.643621, 41.311276],
							[25.642767, 41.310927],
							[25.642102, 41.311271],
							[25.641573, 41.311377],
							[25.640829, 41.311478],
							[25.640345, 41.311484],
							[25.639327, 41.311321],
							[25.63845, 41.311281],
							[25.637016, 41.310974],
							[25.636825, 41.310851],
							[25.636322, 41.310393],
							[25.635848, 41.310098],
							[25.635621, 41.309867],
							[25.635254, 41.309603],
							[25.63402, 41.309012],
							[25.632758, 41.308336],
							[25.63245, 41.308242],
							[25.631194, 41.308238],
							[25.630892, 41.30821],
							[25.630601, 41.307975],
							[25.629916, 41.30759],
							[25.628897, 41.30723],
							[25.628622, 41.307104],
							[25.628482, 41.306946],
							[25.628142, 41.306355],
							[25.627268, 41.305415],
							[25.625791, 41.304747],
							[25.625111, 41.30454],
							[25.624561, 41.304042],
							[25.62434, 41.303888],
							[25.623995, 41.303787],
							[25.623343, 41.303746],
							[25.622555, 41.303649],
							[25.622151, 41.303576],
							[25.621919, 41.303588],
							[25.62166, 41.303669],
							[25.620555, 41.30422],
							[25.619854, 41.304378],
							[25.61959, 41.304402],
							[25.617654, 41.304411],
							[25.617276, 41.304358],
							[25.616856, 41.304099],
							[25.614796, 41.3039],
							[25.613647, 41.303896],
							[25.612412, 41.304378],
							[25.610972, 41.304342],
							[25.608519, 41.30405],
							[25.607904, 41.304508],
							[25.607279, 41.304893],
							[25.606572, 41.305038],
							[25.604928, 41.305873],
							[25.604566, 41.306027],
							[25.60326, 41.306157],
							[25.602188, 41.306157],
							[25.601691, 41.306198],
							[25.601089, 41.306281],
							[25.600776, 41.306478],
							[25.600024, 41.307399],
							[25.599795, 41.307589],
							[25.599314, 41.307937],
							[25.599001, 41.308086],
							[25.597323, 41.307949],
							[25.595389, 41.30799],
							[25.593275, 41.307856],
							[25.591776, 41.307885],
							[25.591366, 41.3079],
							[25.589716, 41.308549],
							[25.587996, 41.309231],
							[25.587605, 41.309339],
							[25.587041, 41.309488],
							[25.586706, 41.309559],
							[25.586356, 41.309803],
							[25.586066, 41.309994],
							[25.585638, 41.310188],
							[25.585045, 41.310366],
							[25.584826, 41.310578],
							[25.585049, 41.311417],
							[25.585516, 41.312646],
							[25.58564, 41.313158],
							[25.585695, 41.313495],
							[25.584169, 41.314516],
							[25.584099, 41.314602],
							[25.583604, 41.314569],
							[25.583306, 41.314528],
							[25.582988, 41.314511],
							[25.582692, 41.314494],
							[25.582389, 41.314488],
							[25.582121, 41.314504],
							[25.5818, 41.314543],
							[25.581525, 41.314588],
							[25.581244, 41.314656],
							[25.580969, 41.314732],
							[25.580685, 41.314825],
							[25.580419, 41.314919],
							[25.579629, 41.315245],
							[25.579419, 41.315334],
							[25.577799, 41.316018],
							[25.577545, 41.316129],
							[25.577312, 41.316267],
							[25.5771, 41.316423],
							[25.576905, 41.316597],
							[25.576542, 41.316953],
							[25.576375, 41.317145],
							[25.576217, 41.317336],
							[25.575941, 41.317736],
							[25.574811, 41.319292],
							[25.57454, 41.319242],
							[25.573643, 41.319056],
							[25.573384, 41.31896],
							[25.573148, 41.318819],
							[25.572914, 41.318669],
							[25.572663, 41.318556],
							[25.5724, 41.318442],
							[25.572129, 41.318347],
							[25.571853, 41.318261],
							[25.569033, 41.317524],
							[25.568747, 41.317465],
							[25.568449, 41.317433],
							[25.568147, 41.317428],
							[25.567852, 41.31745],
							[25.567557, 41.317481],
							[25.566676, 41.317602],
							[25.56609, 41.3177],
							[25.565799, 41.317749],
							[25.565218, 41.317856],
							[25.564932, 41.317923],
							[25.564647, 41.31799],
							[25.564364, 41.318065],
							[25.564083, 41.318141],
							[25.560352, 41.319467],
							[25.559337, 41.317363],
							[25.557377, 41.315827],
							[25.554782, 41.3157],
							[25.55008, 41.312985],
							[25.545306, 41.310685],
							[25.545168, 41.310524],
							[25.545135, 41.3103],
							[25.545151, 41.310056],
							[25.545171, 41.309822],
							[25.545211, 41.309596],
							[25.544988, 41.307609],
							[25.544053, 41.303244],
							[25.544, 41.303019],
							[25.543881, 41.30257],
							[25.543807, 41.302355],
							[25.543706, 41.302149],
							[25.54356, 41.301944],
							[25.543375, 41.301758],
							[25.543156, 41.301607],
							[25.542897, 41.301512],
							[25.542607, 41.301462],
							[25.542302, 41.30143],
							[25.542009, 41.30137],
							[25.541709, 41.301302],
							[25.541418, 41.301207],
							[25.541209, 41.301066],
							[25.538877, 41.298242],
							[25.538755, 41.298045],
							[25.535427, 41.290983],
							[25.53267, 41.282868],
							[25.532046, 41.28067],
							[25.529671, 41.278359],
							[25.525965, 41.276967],
							[25.525697, 41.276872],
							[25.525402, 41.276876],
							[25.525113, 41.276952],
							[25.524866, 41.277072],
							[25.524624, 41.277201],
							[25.524385, 41.277339],
							[25.52415, 41.277478],
							[25.523919, 41.277625],
							[25.523692, 41.277772],
							[25.523468, 41.277919],
							[25.52325, 41.278075],
							[25.52304, 41.278231],
							[25.520547, 41.280174],
							[25.520125, 41.280503],
							[25.519937, 41.280677],
							[25.519814, 41.280877],
							[25.519739, 41.281094],
							[25.519675, 41.281311],
							[25.519595, 41.281528],
							[25.519508, 41.281745],
							[25.51941, 41.281963],
							[25.519288, 41.282163],
							[25.519156, 41.282372],
							[25.519017, 41.282572],
							[25.51886, 41.282772],
							[25.518677, 41.282945],
							[25.518461, 41.283092],
							[25.51823, 41.283231],
							[25.517986, 41.283369],
							[25.517734, 41.283489],
							[25.517475, 41.28361],
							[25.516954, 41.283833],
							[25.515615, 41.284301],
							[25.508112, 41.286345],
							[25.507833, 41.286403],
							[25.502954, 41.286899],
							[25.498448, 41.286597],
							[25.498166, 41.286529],
							[25.497883, 41.286461],
							[25.497598, 41.286392],
							[25.497311, 41.286324],
							[25.496732, 41.286214],
							[25.496441, 41.286164],
							[25.496148, 41.286123],
							[25.495854, 41.286082],
							[25.495555, 41.28604],
							[25.495255, 41.286009],
							[25.494957, 41.286003],
							[25.494668, 41.286025],
							[25.494379, 41.286074],
							[25.494091, 41.286141],
							[25.493807, 41.286225],
							[25.493529, 41.28631],
							[25.493258, 41.286413],
							[25.492996, 41.286515],
							[25.492744, 41.286636],
							[25.492499, 41.286765],
							[25.492258, 41.286894],
							[25.492018, 41.287032],
							[25.491777, 41.28717],
							[25.491052, 41.287567],
							[25.490809, 41.287696],
							[25.490562, 41.287826],
							[25.490365, 41.287914],
							[25.490312, 41.287935],
							[25.490041, 41.28804],
							[25.489769, 41.288142],
							[25.48949, 41.288227],
							[25.489208, 41.288294],
							[25.488913, 41.288325],
							[25.488607, 41.288328],
							[25.488315, 41.288287],
							[25.488037, 41.288219],
							[25.487768, 41.288114],
							[25.487508, 41.287991],
							[25.487257, 41.287869],
							[25.487014, 41.287745],
							[25.486775, 41.287605],
							[25.48653, 41.287473],
							[25.486279, 41.287359],
							[25.486017, 41.287245],
							[25.485751, 41.287131],
							[25.485479, 41.287036],
							[25.485198, 41.286958],
							[25.484911, 41.286917],
							[25.484618, 41.286885],
							[25.484322, 41.286862],
							[25.484021, 41.286847],
							[25.48372, 41.286842],
							[25.483117, 41.28685],
							[25.482819, 41.286863],
							[25.482522, 41.286885],
							[25.482229, 41.286915],
							[25.481935, 41.286955],
							[25.481353, 41.287062],
							[25.480487, 41.287244],
							[25.479917, 41.287377],
							[25.479352, 41.287529],
							[25.478787, 41.287671],
							[25.478503, 41.287738],
							[25.475363, 41.288472],
							[25.475077, 41.288521],
							[25.474778, 41.288533],
							[25.474474, 41.28851],
							[25.474183, 41.288469],
							[25.473889, 41.288401],
							[25.473611, 41.288305],
							[25.473385, 41.288164],
							[25.473184, 41.288004],
							[25.47299, 41.287827],
							[25.47281, 41.287649],
							[25.472641, 41.287453],
							[25.47249, 41.287257],
							[25.472357, 41.28706],
							[25.472257, 41.286854],
							[25.472181, 41.28663],
							[25.472117, 41.286415],
							[25.472051, 41.28619],
							[25.471968, 41.285975],
							[25.471862, 41.28576],
							[25.471744, 41.285555],
							[25.471617, 41.285349],
							[25.470992, 41.28433],
							[25.470865, 41.284125],
							[25.470728, 41.283919],
							[25.470569, 41.283732],
							[25.470403, 41.283536],
							[25.470226, 41.283349],
							[25.470037, 41.283163],
							[25.469829, 41.283003],
							[25.469598, 41.282871],
							[25.469344, 41.282766],
							[25.469071, 41.282689],
							[25.468786, 41.282611],
							[25.468489, 41.282552],
							[25.456593, 41.28064],
							[25.453631, 41.280669],
							[25.453042, 41.280748],
							[25.452747, 41.28077],
							[25.452448, 41.280783],
							[25.452143, 41.280787],
							[25.451839, 41.280772],
							[25.451545, 41.280731],
							[25.451264, 41.280672],
							[25.450996, 41.280585],
							[25.450734, 41.28048],
							[25.45048, 41.280357],
							[25.445563, 41.277807],
							[25.445328, 41.277666],
							[25.445108, 41.277515],
							[25.444896, 41.277365],
							[25.444686, 41.277197],
							[25.444479, 41.277037],
							[25.444272, 41.276868],
							[25.443643, 41.27639],
							[25.443432, 41.27623],
							[25.44321, 41.27608],
							[25.442732, 41.275789],
							[25.442478, 41.275657],
							[25.442208, 41.27557],
							[25.441924, 41.275547],
							[25.441634, 41.275541],
							[25.441044, 41.275549],
							[25.440448, 41.275592],
							[25.439846, 41.275645],
							[25.439546, 41.275675],
							[25.438649, 41.275768],
							[25.438056, 41.275829],
							[25.437463, 41.275891],
							[25.437169, 41.27593],
							[25.436584, 41.27601],
							[25.436294, 41.276068],
							[25.436016, 41.276179],
							[25.435739, 41.276237],
							[25.435461, 41.276195],
							[25.432097, 41.275255],
							[25.431805, 41.27516],
							[25.431554, 41.275046],
							[25.431396, 41.274867],
							[25.431267, 41.274662],
							[25.431152, 41.274456],
							[25.431049, 41.274241],
							[25.430867, 41.273802],
							[25.430786, 41.273587],
							[25.429678, 41.270547],
							[25.42962, 41.270323],
							[25.429647, 41.270115],
							[25.429766, 41.269898],
							[25.429873, 41.269689],
							[25.429956, 41.269472],
							[25.430019, 41.269255],
							[25.430039, 41.26903],
							[25.430034, 41.268804],
							[25.429997, 41.267904],
							[25.429983, 41.267679],
							[25.429962, 41.267454],
							[25.429933, 41.267229],
							[25.429897, 41.267005],
							[25.429853, 41.26678],
							[25.429799, 41.266556],
							[25.429742, 41.26634],
							[25.429679, 41.266116],
							[25.429603, 41.2659],
							[25.42951, 41.265685],
							[25.429435, 41.265508],
							[25.429472, 41.265321],
							[25.429493, 41.265172],
							[25.429542, 41.264916],
							[25.42949, 41.264774],
							[25.429432, 41.264697],
							[25.4293, 41.264621],
							[25.428883, 41.26468],
							[25.428636, 41.26468],
							[25.42833, 41.264747],
							[25.428017, 41.264805],
							[25.423551, 41.264534],
							[25.423278, 41.264574],
							[25.42302, 41.264676],
							[25.422769, 41.264805],
							[25.422517, 41.264943],
							[25.422262, 41.265073],
							[25.422002, 41.265184],
							[25.421474, 41.265397],
							[25.421202, 41.265482],
							[25.420924, 41.265566],
							[25.420638, 41.265624],
							[25.420347, 41.265682],
							[25.417169, 41.266243],
							[25.416877, 41.266265],
							[25.416574, 41.266251],
							[25.416276, 41.266218],
							[25.415994, 41.26615],
							[25.41585, 41.266099],
							[25.41572, 41.266054],
							[25.41544, 41.265976],
							[25.415151, 41.265917],
							[25.414868, 41.265848],
							[25.412317, 41.26524],
							[25.412045, 41.265162],
							[25.411844, 41.265003],
							[25.411673, 41.264807],
							[25.411531, 41.26461],
							[25.4114, 41.264405],
							[25.411278, 41.264199],
							[25.411166, 41.263993],
							[25.411072, 41.263778],
							[25.410998, 41.263563],
							[25.410949, 41.263338],
							[25.410918, 41.263113],
							[25.410897, 41.262889],
							[25.410875, 41.262664],
							[25.410864, 41.262244],
							[25.410797, 41.261916],
							[25.410498, 41.261942],
							[25.409906, 41.261859],
							[25.409238, 41.261989],
							[25.408472, 41.262277],
							[25.408002, 41.262212],
							[25.407279, 41.261895],
							[25.406508, 41.261672],
							[25.406243, 41.261652],
							[25.405918, 41.261733],
							[25.405631, 41.2618],
							[25.405314, 41.261862],
							[25.405082, 41.261978],
							[25.404836, 41.262098],
							[25.404732, 41.262154],
							[25.40445, 41.26225],
							[25.404203, 41.262422],
							[25.403904, 41.262595],
							[25.403522, 41.262671],
							[25.402982, 41.262206],
							[25.401137, 41.26121],
							[25.397974, 41.260219],
							[25.396566, 41.259467],
							[25.394804, 41.258065],
							[25.394377, 41.257954],
							[25.393667, 41.258194],
							[25.392155, 41.258354],
							[25.388742, 41.258377],
							[25.388654, 41.258031],
							[25.388268, 41.257755],
							[25.388133, 41.257482],
							[25.387992, 41.257312],
							[25.387741, 41.257063],
							[25.387358, 41.256854],
							[25.387035, 41.256746],
							[25.386543, 41.256548],
							[25.386225, 41.256387],
							[25.385857, 41.256172],
							[25.385075, 41.25646],
							[25.384718, 41.256665],
							[25.384538, 41.256825],
							[25.383865, 41.256992],
							[25.383436, 41.257053],
							[25.382298, 41.257135],
							[25.381929, 41.257462],
							[25.378408, 41.257671],
							[25.378116, 41.25772],
							[25.377826, 41.257768],
							[25.377536, 41.257826],
							[25.37725, 41.257892],
							[25.376966, 41.257959],
							[25.373723, 41.259087],
							[25.372168, 41.259637],
							[25.371717, 41.259948],
							[25.371482, 41.260086],
							[25.37123, 41.260188],
							[25.370945, 41.260246],
							[25.370641, 41.260276],
							[25.37034, 41.260289],
							[25.368543, 41.260274],
							[25.368246, 41.260269],
							[25.367957, 41.260227],
							[25.367663, 41.260159],
							[25.36738, 41.260072],
							[25.367124, 41.259958],
							[25.359506, 41.254562],
							[25.357284, 41.251337],
							[25.357133, 41.251141],
							[25.356921, 41.25099],
							[25.356659, 41.250867],
							[25.351064, 41.248942],
							[25.346251, 41.248503],
							[25.342969, 41.248406],
							[25.342656, 41.248392],
							[25.342369, 41.24835],
							[25.342128, 41.248245],
							[25.341928, 41.248094],
							[25.341756, 41.247907],
							[25.3416, 41.247702],
							[25.341306, 41.2473],
							[25.341171, 41.247094],
							[25.339469, 41.244988],
							[25.336623, 41.241481],
							[25.336429, 41.241349],
							[25.336152, 41.241271],
							[25.335839, 41.241229],
							[25.335527, 41.241215],
							[25.335242, 41.241245],
							[25.33417, 41.242032],
							[25.333934, 41.242161],
							[25.333686, 41.24229],
							[25.333431, 41.24241],
							[25.333167, 41.242521],
							[25.332899, 41.242632],
							[25.332626, 41.242726],
							[25.33235, 41.242801],
							[25.332068, 41.242867],
							[25.331768, 41.242925],
							[25.33146, 41.242973],
							[25.331151, 41.242995],
							[25.330851, 41.24299],
							[25.33057, 41.242957],
							[25.330312, 41.242879],
							[25.330072, 41.242755],
							[25.329843, 41.242605],
							[25.329619, 41.242445],
							[25.329395, 41.242277],
							[25.329163, 41.242126],
							[25.328445, 41.24172],
							[25.327953, 41.241465],
							[25.3277, 41.241351],
							[25.327438, 41.241245],
							[25.327162, 41.241149],
							[25.32688, 41.241081],
							[25.326591, 41.241021],
							[25.326284, 41.24098],
							[25.325975, 41.240956],
							[25.325684, 41.240977],
							[25.325426, 41.241061],
							[25.325181, 41.241181],
							[25.324945, 41.241319],
							[25.324713, 41.241466],
							[25.324483, 41.241622],
							[25.32425, 41.241768],
							[25.324011, 41.241906],
							[25.323279, 41.242302],
							[25.32278, 41.242551],
							[25.322523, 41.242662],
							[25.32226, 41.242764],
							[25.321991, 41.242857],
							[25.32172, 41.24295],
							[25.321444, 41.243035],
							[25.321166, 41.243119],
							[25.320608, 41.243279],
							[25.317248, 41.244227],
							[25.316966, 41.244293],
							[25.316683, 41.244359],
							[25.316389, 41.244426],
							[25.316091, 41.244483],
							[25.315791, 41.244514],
							[25.303965, 41.244837],
							[25.303669, 41.244841],
							[25.303371, 41.244835],
							[25.300982, 41.244745],
							[25.29775, 41.245205],
							[25.297459, 41.245226],
							[25.297167, 41.245229],
							[25.296871, 41.245215],
							[25.296571, 41.245182],
							[25.29627, 41.24514],
							[25.295972, 41.245099],
							[25.29568, 41.24503],
							[25.295395, 41.244961],
							[25.295122, 41.244874],
							[25.29486, 41.244778],
							[25.294603, 41.244664],
							[25.294355, 41.244541],
							[25.294111, 41.244408],
							[25.293875, 41.244267],
							[25.293645, 41.244116],
							[25.293422, 41.243966],
							[25.293206, 41.243806],
							[25.292994, 41.243646],
							[25.2928, 41.243477],
							[25.292623, 41.243299],
							[25.292458, 41.243112],
							[25.290405, 41.240622],
							[25.290261, 41.240425],
							[25.290127, 41.240228],
							[25.289448, 41.239218],
							[25.288354, 41.237546],
							[25.288379, 41.235681],
							[25.288256, 41.235459],
							[25.287994, 41.235393],
							[25.287407, 41.235478],
							[25.284915, 41.235507],
							[25.283269, 41.235473],
							[25.281546, 41.235818],
							[25.280827, 41.235999],
							[25.280324, 41.236819],
							[25.279753, 41.237668],
							[25.278636, 41.238259],
							[25.277429, 41.238749],
							[25.275197, 41.239934],
							[25.274547, 41.241366],
							[25.274297, 41.241486],
							[25.274045, 41.241605],
							[25.273789, 41.241725],
							[25.27353, 41.241836],
							[25.267782, 41.244062],
							[25.266351, 41.243991],
							[25.265181, 41.243973],
							[25.263917, 41.243847],
							[25.263416, 41.244041],
							[25.262499, 41.244572],
							[25.259697, 41.245546],
							[25.259113, 41.245634],
							[25.258819, 41.245664],
							[25.258522, 41.245685],
							[25.258222, 41.245698],
							[25.25732, 41.245717],
							[25.253145, 41.246879],
							[25.252864, 41.246954],
							[25.252579, 41.247011],
							[25.252291, 41.247041],
							[25.251995, 41.247063],
							[25.251697, 41.247057],
							[25.251096, 41.247027],
							[25.250794, 41.247004],
							[25.250496, 41.24698],
							[25.250203, 41.246947],
							[25.249861, 41.24687],
							[25.242462, 41.246753],
							[25.241297, 41.246196],
							[25.239526, 41.245539],
							[25.239053, 41.245253],
							[25.238274, 41.245391],
							[25.23792, 41.24549],
							[25.236985, 41.246117],
							[25.236099, 41.246618],
							[25.234893, 41.247077],
							[25.234024, 41.247189],
							[25.233218, 41.247236],
							[25.232161, 41.247372],
							[25.231573, 41.24794],
							[25.231313, 41.248925],
							[25.232259, 41.250915],
							[25.232854, 41.252115],
							[25.233343, 41.253362],
							[25.233452, 41.254289],
							[25.230277, 41.259142],
							[25.225767, 41.264417],
							[25.22564, 41.264514],
							[25.225021, 41.264632],
							[25.224725, 41.264668],
							[25.224026, 41.264658],
							[25.223584, 41.26462],
							[25.223373, 41.264632],
							[25.223254, 41.264708],
							[25.223398, 41.265565],
							[25.224266, 41.267069],
							[25.224592, 41.267525],
							[25.225057, 41.268178],
							[25.22556, 41.268956],
							[25.225202, 41.270761],
							[25.222808, 41.278533],
							[25.222432, 41.279267],
							[25.220591, 41.280998],
							[25.218523, 41.282785],
							[25.216707, 41.282994],
							[25.215313, 41.2829],
							[25.214406, 41.283063],
							[25.212715, 41.284756],
							[25.212613, 41.285793],
							[25.212509, 41.286713],
							[25.209328, 41.290602],
							[25.208648, 41.291365],
							[25.203397, 41.292375],
							[25.202699, 41.291761],
							[25.202, 41.291408],
							[25.200033, 41.291933],
							[25.198734, 41.292955],
							[25.197512, 41.294535],
							[25.197198, 41.295439],
							[25.196426, 41.295862],
							[25.193837, 41.29597],
							[25.192459, 41.295741],
							[25.191607, 41.294921],
							[25.190878, 41.294397],
							[25.189804, 41.294444],
							[25.18754, 41.295594],
							[25.183287, 41.297754],
							[25.17892, 41.302032],
							[25.178726, 41.302521],
							[25.18066, 41.30596],
							[25.181633, 41.307535],
							[25.181091, 41.308468],
							[25.18016, 41.308973],
							[25.178264, 41.310002],
							[25.175693, 41.310967],
							[25.171089, 41.312714],
							[25.169679, 41.312458],
							[25.167828, 41.311459],
							[25.166052, 41.310387],
							[25.165115, 41.310595],
							[25.163844, 41.31113],
							[25.162913, 41.314049],
							[25.16287, 41.315481],
							[25.162146, 41.316336],
							[25.159923, 41.318078],
							[25.15709, 41.318269],
							[25.153876, 41.317148],
							[25.153647, 41.316925],
							[25.153403, 41.316387],
							[25.15316, 41.315714],
							[25.152931, 41.315275],
							[25.152688, 41.314872],
							[25.152112, 41.314734],
							[25.149764, 41.314839],
							[25.148733, 41.314975],
							[25.148736, 41.315686],
							[25.149284, 41.316762],
							[25.149453, 41.317904],
							[25.148366, 41.319068],
							[25.146279, 41.321179],
							[25.144783, 41.322355],
							[25.14342, 41.322918],
							[25.142481, 41.323216],
							[25.140951, 41.323413],
							[25.140209, 41.323509],
							[25.139375, 41.323184],
							[25.138752, 41.322604],
							[25.138298, 41.322221],
							[25.137811, 41.321821],
							[25.136736, 41.321742],
							[25.132737, 41.322934],
							[25.131967, 41.323671],
							[25.130063, 41.325978],
							[25.127813, 41.329801],
							[25.127557, 41.330263],
							[25.126602, 41.330605],
							[25.125331, 41.330879],
							[25.124392, 41.331167],
							[25.123831, 41.331443],
							[25.122882, 41.333939],
							[25.122792, 41.334588],
							[25.123492, 41.335824],
							[25.12404, 41.336638],
							[25.124906, 41.337414],
							[25.125666, 41.338523],
							[25.125838, 41.341692],
							[25.125399, 41.342007],
							[25.12487, 41.342386],
							[25.122582, 41.342688],
							[25.116277, 41.343001],
							[25.115443, 41.343],
							[25.114095, 41.34286],
							[25.111563, 41.342353],
							[25.110819, 41.342018],
							[25.10877, 41.340849],
							[25.105779, 41.337842],
							[25.104473, 41.336458],
							[25.103365, 41.335244],
							[25.102999, 41.33477],
							[25.102636, 41.334603],
							[25.098709, 41.334046],
							[25.097983, 41.334026],
							[25.095119, 41.334512],
							[25.094772, 41.334822],
							[25.094954, 41.335532],
							[25.095228, 41.336196],
							[25.095426, 41.336887],
							[25.095579, 41.337444],
							[25.095291, 41.337978],
							[25.094746, 41.338272],
							[25.092641, 41.339256],
							[25.09196, 41.339352],
							[25.090459, 41.339015],
							[25.0892, 41.338478],
							[25.088351, 41.33826],
							[25.083757, 41.33789],
							[25.082743, 41.338133],
							[25.082258, 41.339075],
							[25.081655, 41.340359],
							[25.080838, 41.341169],
							[25.079338, 41.341534],
							[25.078095, 41.341528],
							[25.076973, 41.341853],
							[25.075112, 41.344258],
							[25.074628, 41.345361],
							[25.074129, 41.345852],
							[25.069328, 41.349752],
							[25.066543, 41.35176],
							[25.065108, 41.355736],
							[25.064957, 41.356773],
							[25.063976, 41.3598],
							[25.061308, 41.360752],
							[25.060565, 41.36075],
							[25.059641, 41.360993],
							[25.057352, 41.36168],
							[25.056928, 41.361937],
							[25.056687, 41.362696],
							[25.056581, 41.363831],
							[25.056202, 41.364456],
							[25.055748, 41.364848],
							[25.053884, 41.364595],
							[25.051245, 41.364547],
							[25.050063, 41.364882],
							[25.046592, 41.365949],
							[25.045288, 41.36643],
							[25.044052, 41.366944],
							[25.042544, 41.367338],
							[25.041724, 41.367433],
							[25.038722, 41.366165],
							[25.034021, 41.36509],
							[25.030837, 41.365983],
							[25.03011, 41.366566],
							[25.028383, 41.367717],
							[25.027579, 41.36803],
							[25.025987, 41.368161],
							[25.024395, 41.368005],
							[25.02197, 41.367954],
							[25.021135, 41.368133],
							[25.018724, 41.369253],
							[25.0183, 41.369833],
							[25.017543, 41.370542],
							[25.008021, 41.372518],
							[25.001894, 41.373625],
							[24.999924, 41.373417],
							[24.995539, 41.374194],
							[24.994123, 41.374548],
							[24.992506, 41.375139],
							[24.991483, 41.375607],
							[24.989795, 41.376549],
							[24.989254, 41.376734],
							[24.988669, 41.376757],
							[24.983977, 41.376689],
							[24.980046, 41.376326],
							[24.979244, 41.376027],
							[24.978659, 41.375906],
							[24.977761, 41.375913],
							[24.976878, 41.376056],
							[24.975033, 41.376783],
							[24.973005, 41.377755],
							[24.967891, 41.380465],
							[24.966805, 41.381239],
							[24.966295, 41.381404],
							[24.962798, 41.382543],
							[24.962531, 41.382446],
							[24.961936, 41.38237],
							[24.961314, 41.382375],
							[24.961042, 41.382441],
							[24.958812, 41.383891],
							[24.957512, 41.384803],
							[24.952331, 41.387728],
							[24.952066, 41.387775],
							[24.951459, 41.387672],
							[24.949373, 41.386798],
							[24.945793, 41.386089],
							[24.941099, 41.386415],
							[24.940184, 41.38654],
							[24.939961, 41.38665],
							[24.939838, 41.386822],
							[24.939544, 41.388437],
							[24.939448, 41.388645],
							[24.939139, 41.389026],
							[24.938516, 41.389526],
							[24.932365, 41.39371],
							[24.927211, 41.396941],
							[24.926671, 41.397486],
							[24.925369, 41.399514],
							[24.924003, 41.401254],
							[24.923612, 41.401676],
							[24.923288, 41.401981],
							[24.923066, 41.402126],
							[24.922518, 41.402311],
							[24.921922, 41.402325],
							[24.920728, 41.402262],
							[24.919908, 41.40253],
							[24.91917, 41.402914],
							[24.918122, 41.404012],
							[24.917826, 41.404402],
							[24.917615, 41.404827],
							[24.917451, 41.405495],
							[24.91724, 41.407677],
							[24.916962, 41.407778],
							[24.91635, 41.407873],
							[24.915635, 41.407869],
							[24.911291, 41.40785],
							[24.900253, 41.407504],
							[24.899389, 41.407322],
							[24.898569, 41.407049],
							[24.895167, 41.40567],
							[24.894691, 41.405413],
							[24.894125, 41.404886],
							[24.893549, 41.404098],
							[24.893027, 41.403543],
							[24.891065, 41.401847],
							[24.889325, 41.400653],
							[24.887313, 41.399273],
							[24.885958, 41.398788],
							[24.885745, 41.398637],
							[24.885397, 41.39827],
							[24.885119, 41.397867],
							[24.882868, 41.393984],
							[24.881971, 41.392081],
							[24.878881, 41.393267],
							[24.878333, 41.393424],
							[24.877149, 41.393568],
							[24.875642, 41.393598],
							[24.875072, 41.393476],
							[24.874278, 41.393158],
							[24.872515, 41.392306],
							[24.87133, 41.391613],
							[24.870809, 41.391391],
							[24.869693, 41.391067],
							[24.868806, 41.390965],
							[24.867907, 41.390945],
							[24.867005, 41.390988],
							[24.866127, 41.39113],
							[24.865579, 41.391296],
							[24.862509, 41.392493],
							[24.862388, 41.392545],
							[24.85813, 41.394621],
							[24.857399, 41.395023],
							[24.856729, 41.395469],
							[24.856308, 41.395806],
							[24.855962, 41.396169],
							[24.85566, 41.396792],
							[24.855403, 41.397911],
							[24.853206, 41.398919],
							[24.849084, 41.399489],
							[24.848495, 41.399521],
							[24.840502, 41.398976],
							[24.837929, 41.398445],
							[24.834636, 41.398307],
							[24.834059, 41.398374],
							[24.83384, 41.398444],
							[24.833543, 41.398544],
							[24.833229, 41.398649],
							[24.833125, 41.398692],
							[24.832702, 41.398861],
							[24.83126, 41.399442],
							[24.830072, 41.399914],
							[24.829016, 41.400338],
							[24.828596, 41.400573],
							[24.82832, 41.400726],
							[24.82783, 41.401004],
							[24.820737, 41.401353],
							[24.819859, 41.401188],
							[24.819606, 41.401081],
							[24.81918, 41.400742],
							[24.819047, 41.400545],
							[24.818936, 41.400104],
							[24.819136, 41.396167],
							[24.820077, 41.392863],
							[24.822657, 41.38626],
							[24.822757, 41.385368],
							[24.82262, 41.384918],
							[24.822367, 41.384515],
							[24.82127, 41.383766],
							[24.819977, 41.38319],
							[24.819827, 41.383011],
							[24.818604, 41.380705],
							[24.817988, 41.378962],
							[24.815388, 41.370891],
							[24.815326, 41.370216],
							[24.815373, 41.368865],
							[24.815489, 41.367738],
							[24.815655, 41.366845],
							[24.815889, 41.366195],
							[24.816041, 41.366005],
							[24.816997, 41.365142],
							[24.816849, 41.362261],
							[24.81604, 41.36033],
							[24.815957, 41.359655],
							[24.816199, 41.356338],
							[24.816478, 41.355247],
							[24.816601, 41.355039],
							[24.816654, 41.354579],
							[24.813491, 41.349638],
							[24.81287, 41.349147],
							[24.812365, 41.348907],
							[24.812059, 41.348874],
							[24.811774, 41.348921],
							[24.811286, 41.349185],
							[24.809758, 41.349304],
							[24.80533, 41.348119],
							[24.805172, 41.347931],
							[24.804962, 41.3475],
							[24.804817, 41.346835],
							[24.804786, 41.346384],
							[24.804598, 41.346233],
							[24.804287, 41.346244],
							[24.80404, 41.346363],
							[24.801458, 41.348119],
							[24.80005, 41.349264],
							[24.799226, 41.350206],
							[24.798445, 41.350887],
							[24.792402, 41.355496],
							[24.790911, 41.3572],
							[24.790787, 41.358101],
							[24.79028, 41.359168],
							[24.790096, 41.359331],
							[24.788878, 41.359961],
							[24.788023, 41.360072],
							[24.787182, 41.360182],
							[24.78626, 41.360304],
							[24.78596, 41.360341],
							[24.785072, 41.360345],
							[24.784159, 41.360431],
							[24.783285, 41.360522],
							[24.782699, 41.360607],
							[24.782115, 41.360709],
							[24.781824, 41.360766],
							[24.781247, 41.360878],
							[24.78096, 41.360943],
							[24.780675, 41.361007],
							[24.780392, 41.361081],
							[24.780113, 41.361165],
							[24.77984, 41.361256],
							[24.779012, 41.361559],
							[24.778755, 41.361678],
							[24.778535, 41.361824],
							[24.77837, 41.361996],
							[24.778261, 41.362204],
							[24.778182, 41.36243],
							[24.778108, 41.362655],
							[24.778014, 41.362872],
							[24.777866, 41.363053],
							[24.777607, 41.363172],
							[24.776558, 41.363621],
							[24.776299, 41.363731],
							[24.776032, 41.363831],
							[24.775758, 41.363923],
							[24.77548, 41.364006],
							[24.775197, 41.36408],
							[24.774911, 41.364145],
							[24.774621, 41.364201],
							[24.772009, 41.364678],
							[24.771716, 41.364725],
							[24.771418, 41.364727],
							[24.771118, 41.36472],
							[24.770818, 41.364713],
							[24.770512, 41.364733],
							[24.770231, 41.364798],
							[24.770015, 41.364944],
							[24.766328, 41.36758],
							[24.764851, 41.368689],
							[24.764641, 41.368853],
							[24.764436, 41.369016],
							[24.764236, 41.36918],
							[24.761109, 41.371885],
							[24.76094, 41.372075],
							[24.760789, 41.372265],
							[24.760658, 41.372473],
							[24.760547, 41.372681],
							[24.760454, 41.372898],
							[24.760413, 41.37302],
							[24.760283, 41.373358],
							[24.760383, 41.37352],
							[24.760661, 41.373581],
							[24.760983, 41.373633],
							[24.761262, 41.373721],
							[24.761522, 41.373836],
							[24.761739, 41.373988],
							[24.761929, 41.374167],
							[24.76209, 41.374364],
							[24.762215, 41.374561],
							[24.76231, 41.374777],
							[24.762379, 41.375002],
							[24.762417, 41.375218],
							[24.762435, 41.375443],
							[24.762447, 41.375677],
							[24.76244, 41.375902],
							[24.762334, 41.37611],
							[24.762159, 41.376309],
							[24.761929, 41.376446],
							[24.755778, 41.378369],
							[24.755501, 41.378452],
							[24.749634, 41.380157],
							[24.749263, 41.380213],
							[24.748102, 41.380311],
							[24.747811, 41.380295],
							[24.745276, 41.383464],
							[24.741634, 41.391558],
							[24.739847, 41.392731],
							[24.735629, 41.396785],
							[24.735538, 41.396984],
							[24.735651, 41.39719],
							[24.735797, 41.397396],
							[24.735955, 41.397593],
							[24.735178, 41.40221],
							[24.734458, 41.404494],
							[24.735483, 41.406622],
							[24.735639, 41.40681],
							[24.735768, 41.407016],
							[24.735878, 41.407223],
							[24.735981, 41.407438],
							[24.736074, 41.407654],
							[24.736155, 41.407869],
							[24.736223, 41.408094],
							[24.736273, 41.408319],
							[24.736306, 41.408535],
							[24.736313, 41.40876],
							[24.736293, 41.408986],
							[24.73625, 41.409211],
							[24.736185, 41.409437],
							[24.736106, 41.409653],
							[24.736016, 41.40987],
							[24.735915, 41.410078],
							[24.735799, 41.410286],
							[24.735667, 41.410494],
							[24.735525, 41.410693],
							[24.735375, 41.410883],
							[24.735216, 41.411082],
							[24.735052, 41.411263],
							[24.734879, 41.411445],
							[24.734698, 41.411626],
							[24.734507, 41.411807],
							[24.732856, 41.413537],
							[24.732291, 41.41386],
							[24.731148, 41.41443],
							[24.73076, 41.415012],
							[24.730388, 41.415765],
							[24.729832, 41.416073],
							[24.729018, 41.416352],
							[24.72825, 41.416398],
							[24.727443, 41.416455],
							[24.726508, 41.416934],
							[24.725655, 41.417185],
							[24.725252, 41.41718],
							[24.724347, 41.416843],
							[24.722871, 41.41589],
							[24.722209, 41.415816],
							[24.721288, 41.416238],
							[24.719318, 41.41783],
							[24.718298, 41.418943],
							[24.717574, 41.419864],
							[24.717326, 41.420009],
							[24.717063, 41.420017],
							[24.71695, 41.419933],
							[24.716659, 41.419678],
							[24.71638, 41.419444],
							[24.716154, 41.41944],
							[24.71547, 41.419485],
							[24.714669, 41.419622],
							[24.714255, 41.419812],
							[24.713555, 41.41992],
							[24.71306, 41.420046],
							[24.712581, 41.420235],
							[24.712264, 41.4203],
							[24.711887, 41.420324],
							[24.711414, 41.420376],
							[24.710935, 41.420449],
							[24.710381, 41.420465],
							[24.709768, 41.42063],
							[24.709316, 41.420961],
							[24.70895, 41.421179],
							[24.708568, 41.421135],
							[24.708046, 41.420969],
							[24.70626, 41.420824],
							[24.704566, 41.4204],
							[24.704076, 41.420247],
							[24.704108, 41.420054],
							[24.704082, 41.419844],
							[24.703641, 41.419373],
							[24.703468, 41.419114],
							[24.703175, 41.418815],
							[24.702581, 41.418888],
							[24.702188, 41.418912],
							[24.701763, 41.418722],
							[24.701445, 41.418605],
							[24.700983, 41.418807],
							[24.700219, 41.419206],
							[24.70002, 41.419275],
							[24.699681, 41.419287],
							[24.699245, 41.419263],
							[24.698852, 41.419331],
							[24.69818, 41.419553],
							[24.697416, 41.419634],
							[24.697034, 41.419565],
							[24.696432, 41.419448],
							[24.695764, 41.419348],
							[24.695302, 41.419336],
							[24.694758, 41.419319],
							[24.694242, 41.418924],
							[24.693822, 41.418601],
							[24.693376, 41.418182],
							[24.693091, 41.41842],
							[24.692752, 41.418855],
							[24.691466, 41.419904],
							[24.69067, 41.420606],
							[24.690465, 41.420961],
							[24.690186, 41.421268],
							[24.69003, 41.421881],
							[24.689895, 41.422099],
							[24.689653, 41.42241],
							[24.689486, 41.422611],
							[24.689346, 41.422865],
							[24.689276, 41.423031],
							[24.689121, 41.423103],
							[24.688765, 41.423075],
							[24.688357, 41.423091],
							[24.687872, 41.42316],
							[24.686549, 41.423124],
							[24.686291, 41.423148],
							[24.686092, 41.423325],
							[24.685979, 41.423519],
							[24.685866, 41.423688],
							[24.685661, 41.423813],
							[24.685435, 41.42389],
							[24.685139, 41.423898],
							[24.684897, 41.423878],
							[24.684634, 41.423914],
							[24.683413, 41.424548],
							[24.68302, 41.424773],
							[24.682552, 41.424902],
							[24.681885, 41.425015],
							[24.681481, 41.425064],
							[24.681056, 41.425044],
							[24.680583, 41.424689],
							[24.680206, 41.424459],
							[24.677656, 41.423212],
							[24.677183, 41.422986],
							[24.676979, 41.422744],
							[24.676774, 41.422531],
							[24.676408, 41.422317],
							[24.67587, 41.421502],
							[24.675655, 41.421264],
							[24.674854, 41.420768],
							[24.674568, 41.420481],
							[24.67431, 41.420013],
							[24.67402, 41.418928],
							[24.673864, 41.417976],
							[24.673433, 41.417936],
							[24.672884, 41.41777],
							[24.672696, 41.417742],
							[24.67247, 41.41777],
							[24.67218, 41.417875],
							[24.671615, 41.418149],
							[24.670867, 41.418468],
							[24.670507, 41.418589],
							[24.670087, 41.418658],
							[24.669124, 41.418726],
							[24.667682, 41.418867],
							[24.666542, 41.419057],
							[24.665746, 41.419162],
							[24.6653, 41.419169],
							[24.664589, 41.419078],
							[24.66323, 41.419024],
							[24.661222, 41.41873],
							[24.660394, 41.416494],
							[24.660058, 41.415652],
							[24.659804, 41.415965],
							[24.659392, 41.416745],
							[24.659274, 41.416836],
							[24.658365, 41.417335],
							[24.65776, 41.417678],
							[24.656923, 41.418331],
							[24.656239, 41.418684],
							[24.657813, 41.419791],
							[24.659716, 41.42067],
							[24.659411, 41.421971],
							[24.65932, 41.42245],
							[24.659259, 41.423231],
							[24.659213, 41.423602],
							[24.658993, 41.423979],
							[24.658924, 41.424127],
							[24.658947, 41.424213],
							[24.659023, 41.424321],
							[24.659335, 41.424503],
							[24.659434, 41.424612],
							[24.659518, 41.424783],
							[24.659617, 41.42492],
							[24.659685, 41.425165],
							[24.659624, 41.425393],
							[24.659464, 41.425587],
							[24.659251, 41.425776],
							[24.659092, 41.426346],
							[24.659023, 41.426489],
							[24.65881, 41.426591],
							[24.658308, 41.426785],
							[24.657966, 41.426968],
							[24.658643, 41.427367],
							[24.659016, 41.427493],
							[24.659411, 41.427658],
							[24.659814, 41.428023],
							[24.659997, 41.428291],
							[24.660126, 41.42852],
							[24.66056, 41.42885],
							[24.660956, 41.429067],
							[24.661937, 41.429278],
							[24.662561, 41.429489],
							[24.66285, 41.429626],
							[24.662949, 41.429763],
							[24.662956, 41.429997],
							[24.663025, 41.430208],
							[24.663223, 41.430402],
							[24.663497, 41.430567],
							[24.66358, 41.430755],
							[24.66355, 41.431183],
							[24.664395, 41.432495],
							[24.663375, 41.432421],
							[24.662713, 41.432495],
							[24.66164, 41.432684],
							[24.660621, 41.432912],
							[24.659601, 41.433014],
							[24.659076, 41.433077],
							[24.658582, 41.433203],
							[24.658331, 41.433419],
							[24.658118, 41.433676],
							[24.658034, 41.433921],
							[24.658034, 41.434115],
							[24.65811, 41.434663],
							[24.658095, 41.434914],
							[24.65795, 41.435056],
							[24.657677, 41.435165],
							[24.657205, 41.435148],
							[24.656764, 41.435074],
							[24.656467, 41.434914],
							[24.65614, 41.4348],
							[24.655843, 41.434697],
							[24.655569, 41.434435],
							[24.655234, 41.434184],
							[24.654922, 41.434115],
							[24.654565, 41.434121],
							[24.65388, 41.434326],
							[24.652891, 41.43452],
							[24.652282, 41.434606],
							[24.651674, 41.434623],
							[24.650837, 41.434606],
							[24.650418, 41.434623],
							[24.650243, 41.434714],
							[24.650091, 41.43484],
							[24.649916, 41.434857],
							[24.649635, 41.43488],
							[24.649308, 41.43484],
							[24.648798, 41.434663],
							[24.648395, 41.434514],
							[24.648014, 41.434218],
							[24.647482, 41.433853],
							[24.64717, 41.433551],
							[24.646873, 41.43334],
							[24.646477, 41.43318],
							[24.646051, 41.432997],
							[24.645755, 41.43278],
							[24.645443, 41.432473],
							[24.64488, 41.431868],
							[24.644537, 41.431611],
							[24.643678, 41.431235],
							[24.642711, 41.43087],
							[24.642407, 41.430773],
							[24.642072, 41.430738],
							[24.641753, 41.430721],
							[24.641342, 41.430755],
							[24.641098, 41.430796],
							[24.640847, 41.430778],
							[24.640322, 41.430664],
							[24.640011, 41.430642],
							[24.639798, 41.430681],
							[24.639448, 41.430824],
							[24.638983, 41.430978],
							[24.638192, 41.43132],
							[24.637789, 41.431543],
							[24.637584, 41.4316],
							[24.637203, 41.431606],
							[24.636633, 41.431543],
							[24.634236, 41.431577],
							[24.633422, 41.43156],
							[24.633072, 41.431452],
							[24.632828, 41.431337],
							[24.632585, 41.431138],
							[24.632334, 41.430978],
							[24.632068, 41.43087],
							[24.631672, 41.43079],
							[24.631223, 41.430784],
							[24.630675, 41.430818],
							[24.630166, 41.430892],
							[24.629625, 41.431286],
							[24.629237, 41.431503],
							[24.628857, 41.431674],
							[24.628378, 41.431788],
							[24.62799, 41.431839],
							[24.627602, 41.431708],
							[24.627313, 41.431508],
							[24.627039, 41.431269],
							[24.626354, 41.430539],
							[24.625981, 41.429923],
							[24.623904, 41.428246],
							[24.623615, 41.428086],
							[24.620967, 41.427014],
							[24.620587, 41.42682],
							[24.619914, 41.426429],
							[24.619895, 41.426335],
							[24.61992, 41.426113],
							[24.62001, 41.425871],
							[24.619544, 41.425248],
							[24.619062, 41.424727],
							[24.618363, 41.424279],
							[24.617469, 41.423997],
							[24.616714, 41.423936],
							[24.615111, 41.424026],
							[24.614756, 41.42395],
							[24.614386, 41.423809],
							[24.612002, 41.424763],
							[24.611174, 41.425605],
							[24.610151, 41.426415],
							[24.608092, 41.427679],
							[24.607398, 41.428889],
							[24.605741, 41.431002],
							[24.606067, 41.431779],
							[24.606261, 41.432537],
							[24.606306, 41.433549],
							[24.606247, 41.434112],
							[24.605941, 41.434067],
							[24.605169, 41.433681],
							[24.604208, 41.434152],
							[24.602645, 41.434627],
							[24.601728, 41.434999],
							[24.600799, 41.434995],
							[24.600899, 41.435315],
							[24.601251, 41.43595],
							[24.601109, 41.436372],
							[24.600961, 41.436612],
							[24.600703, 41.436855],
							[24.600295, 41.437138],
							[24.599494, 41.438945],
							[24.599141, 41.439315],
							[24.59855, 41.439695],
							[24.597633, 41.440804],
							[24.597319, 41.442343],
							[24.596959, 41.443927],
							[24.596937, 41.444156],
							[24.596991, 41.444336],
							[24.596595, 41.444849],
							[24.596409, 41.445228],
							[24.596515, 41.445691],
							[24.596616, 41.446044],
							[24.596688, 41.446485],
							[24.596835, 41.446804],
							[24.596755, 41.446987],
							[24.59644, 41.447207],
							[24.595987, 41.44742],
							[24.595894, 41.447523],
							[24.595756, 41.447883],
							[24.595983, 41.448528],
							[24.596165, 41.449181],
							[24.596529, 41.44968],
							[24.596777, 41.450439],
							[24.596884, 41.450938],
							[24.596866, 41.451144],
							[24.596764, 41.451346],
							[24.596609, 41.451737],
							[24.596662, 41.452103],
							[24.596147, 41.452575],
							[24.59612, 41.452735],
							[24.59636, 41.452861],
							[24.597177, 41.453101],
							[24.597568, 41.453327],
							[24.598127, 41.45374],
							[24.598456, 41.453933],
							[24.598784, 41.454379],
							[24.598855, 41.454678],
							[24.598856, 41.455238],
							[24.599157, 41.455897],
							[24.599568, 41.456268],
							[24.59922, 41.45698],
							[24.598504, 41.457573],
							[24.598184, 41.458396],
							[24.598231, 41.459093],
							[24.598109, 41.46],
							[24.597687, 41.460587],
							[24.597614, 41.460904],
							[24.597583, 41.461138],
							[24.597365, 41.461531],
							[24.597414, 41.461948],
							[24.59688, 41.462291],
							[24.596108, 41.462633],
							[24.595894, 41.462869],
							[24.595739, 41.463254],
							[24.595748, 41.463633],
							[24.596023, 41.463899],
							[24.596325, 41.464165],
							[24.596473, 41.464411],
							[24.596498, 41.464656],
							[24.596379, 41.464733],
							[24.596014, 41.464879],
							[24.595873, 41.46505],
							[24.59576, 41.465415],
							[24.595303, 41.465818],
							[24.59501, 41.466384],
							[24.595178, 41.466717],
							[24.594867, 41.467216],
							[24.593988, 41.467043],
							[24.593589, 41.467016],
							[24.593027, 41.467085],
							[24.592342, 41.466925],
							[24.591796, 41.467019],
							[24.591363, 41.466991],
							[24.590804, 41.466783],
							[24.590584, 41.466831],
							[24.590277, 41.46692],
							[24.589919, 41.466934],
							[24.58963, 41.467094],
							[24.589322, 41.467499],
							[24.588895, 41.467734],
							[24.588198, 41.467757],
							[24.587388, 41.467663],
							[24.586955, 41.467795],
							[24.586018, 41.468162],
							[24.584945, 41.468623],
							[24.584475, 41.46907],
							[24.584236, 41.469719],
							[24.583979, 41.470326],
							[24.583438, 41.470783],
							[24.583231, 41.471018],
							[24.583137, 41.471366],
							[24.583263, 41.471921],
							[24.583131, 41.472368],
							[24.582898, 41.472792],
							[24.582616, 41.473022],
							[24.582145, 41.473088],
							[24.581731, 41.473022],
							[24.581266, 41.472881],
							[24.580914, 41.472811],
							[24.580757, 41.472905],
							[24.58023, 41.473751],
							[24.579859, 41.474307],
							[24.579646, 41.474349],
							[24.579112, 41.474292],
							[24.578773, 41.474316],
							[24.578534, 41.474471],
							[24.578302, 41.474631],
							[24.578038, 41.474697],
							[24.576902, 41.474288],
							[24.57655, 41.474198],
							[24.575922, 41.474203],
							[24.574836, 41.474354],
							[24.573932, 41.474565],
							[24.572871, 41.474937],
							[24.5724, 41.475069],
							[24.57267, 41.47616],
							[24.572852, 41.476631],
							[24.573222, 41.477054],
							[24.573511, 41.477374],
							[24.573536, 41.477675],
							[24.573429, 41.478146],
							[24.573134, 41.4788],
							[24.573018, 41.479265],
							[24.572704, 41.479637],
							[24.571197, 41.48097],
							[24.570723, 41.481269],
							[24.570726, 41.481633],
							[24.570001, 41.482377],
							[24.569873, 41.482607],
							[24.569822, 41.482823],
							[24.569923, 41.483369],
							[24.569973, 41.484211],
							[24.569992, 41.484634],
							[24.569832, 41.484985],
							[24.569618, 41.485328],
							[24.569424, 41.485601],
							[24.568975, 41.486175],
							[24.568598, 41.486687],
							[24.568433, 41.488008],
							[24.56852, 41.489085],
							[24.568562, 41.490084],
							[24.568693, 41.490752],
							[24.568857, 41.491358],
							[24.568118, 41.49178],
							[24.567745, 41.492292],
							[24.567181, 41.492776],
							[24.566767, 41.493032],
							[24.565672, 41.493829],
							[24.564874, 41.494675],
							[24.564171, 41.495456],
							[24.563593, 41.495879],
							[24.563587, 41.496119],
							[24.563863, 41.496448],
							[24.564133, 41.497046],
							[24.563694, 41.497855],
							[24.563826, 41.498311],
							[24.56365, 41.498593],
							[24.563166, 41.498913],
							[24.562915, 41.499195],
							[24.562846, 41.499632],
							[24.562714, 41.499849],
							[24.562224, 41.5003],
							[24.561892, 41.500784],
							[24.561609, 41.500963],
							[24.561019, 41.501062],
							[24.560579, 41.5011],
							[24.559719, 41.501339],
							[24.559116, 41.501466],
							[24.558909, 41.501584],
							[24.558841, 41.501731],
							[24.559104, 41.502144],
							[24.559493, 41.502918],
							[24.560035, 41.503321],
							[24.560314, 41.503716],
							[24.560723, 41.503969],
							[24.560504, 41.504306],
							[24.56046, 41.504593],
							[24.56051, 41.504815],
							[24.560737, 41.505464],
							[24.560981, 41.505906],
							[24.561448, 41.506332],
							[24.561881, 41.506692],
							[24.562414, 41.507043],
							[24.562422, 41.507855],
							[24.562, 41.508032],
							[24.561809, 41.508766],
							[24.561845, 41.509006],
							[24.56208, 41.509617],
							[24.562449, 41.510063],
							[24.562662, 41.510602],
							[24.562715, 41.51132],
							[24.562911, 41.511742],
							[24.563181, 41.512035],
							[24.563417, 41.512301],
							[24.563466, 41.512497],
							[24.563377, 41.512733],
							[24.563488, 41.513544],
							[24.563634, 41.514113],
							[24.563173, 41.514571],
							[24.563115, 41.515083],
							[24.563022, 41.515748],
							[24.562866, 41.516044],
							[24.562609, 41.516407],
							[24.562635, 41.517264],
							[24.562311, 41.517849],
							[24.562031, 41.518079],
							[24.561725, 41.518272],
							[24.561614, 41.518631],
							[24.56097, 41.519309],
							[24.560669, 41.51986],
							[24.560587, 41.520424],
							[24.560016, 41.521129],
							[24.559514, 41.521552],
							[24.559286, 41.521647],
							[24.558441, 41.521796],
							[24.558446, 41.522225],
							[24.558081, 41.522373],
							[24.557919, 41.522526],
							[24.557837, 41.522635],
							[24.557758, 41.522739],
							[24.557718, 41.523139],
							[24.557466, 41.523306],
							[24.557106, 41.523358],
							[24.556702, 41.523384],
							[24.556437, 41.523616],
							[24.556197, 41.523983],
							[24.555913, 41.524241],
							[24.555663, 41.524514],
							[24.555593, 41.524675],
							[24.556227, 41.524967],
							[24.556492, 41.524937],
							[24.55673, 41.525348],
							[24.557354, 41.525942],
							[24.557846, 41.526475],
							[24.55795, 41.527048],
							[24.557685, 41.527347],
							[24.557547, 41.527598],
							[24.557353, 41.527833],
							[24.557208, 41.528169],
							[24.557166, 41.528658],
							[24.556509, 41.529415],
							[24.556735, 41.529785],
							[24.556459, 41.530081],
							[24.556148, 41.530716],
							[24.55588, 41.530817],
							[24.555428, 41.530817],
							[24.555279, 41.531028],
							[24.555089, 41.531188],
							[24.554742, 41.530954],
							[24.554368, 41.530965],
							[24.553916, 41.53079],
							[24.553485, 41.530928],
							[24.552383, 41.531626],
							[24.552107, 41.532705],
							[24.55169, 41.532816],
							[24.551655, 41.533086],
							[24.550934, 41.533731],
							[24.550998, 41.533921],
							[24.550623, 41.534233],
							[24.550348, 41.534604],
							[24.549952, 41.535217],
							[24.549627, 41.535487],
							[24.549253, 41.536095],
							[24.549033, 41.536333],
							[24.54897, 41.536687],
							[24.548807, 41.537047],
							[24.548327, 41.537507],
							[24.548341, 41.537729],
							[24.54818, 41.537936],
							[24.547756, 41.538184],
							[24.547731, 41.538422],
							[24.547046, 41.538851],
							[24.546644, 41.538965],
							[24.546297, 41.539146],
							[24.546179, 41.539264],
							[24.546364, 41.53988],
							[24.546584, 41.540466],
							[24.546596, 41.540699],
							[24.546615, 41.541056],
							[24.546711, 41.541339],
							[24.546789, 41.541566],
							[24.546923, 41.541878],
							[24.547149, 41.542195],
							[24.547488, 41.542412],
							[24.547672, 41.542687],
							[24.548449, 41.542819],
							[24.548386, 41.543211],
							[24.548428, 41.54347],
							[24.548689, 41.543697],
							[24.548838, 41.543956],
							[24.548796, 41.544252],
							[24.548605, 41.544617],
							[24.548605, 41.544898],
							[24.548753, 41.545125],
							[24.548923, 41.545236],
							[24.549128, 41.545527],
							[24.549036, 41.54576],
							[24.54946, 41.546093],
							[24.549661, 41.54623],
							[24.549629, 41.546399],
							[24.550286, 41.546918],
							[24.55095, 41.54696],
							[24.550972, 41.547103],
							[24.551643, 41.547177],
							[24.551989, 41.54732],
							[24.551784, 41.547785],
							[24.551869, 41.547917],
							[24.551777, 41.548351],
							[24.55266, 41.548959],
							[24.553035, 41.54889],
							[24.553346, 41.548705],
							[24.553706, 41.548965],
							[24.553601, 41.549227],
							[24.553689, 41.550043],
							[24.553463, 41.550344],
							[24.553435, 41.550587],
							[24.553089, 41.550894],
							[24.552318, 41.551851],
							[24.551838, 41.552121],
							[24.55169, 41.552533],
							[24.551266, 41.552697],
							[24.550609, 41.553284],
							[24.549527, 41.553823],
							[24.548906, 41.553998],
							[24.548109, 41.554362],
							[24.548016, 41.554405],
							[24.547888, 41.5546],
							[24.547366, 41.554992],
							[24.547055, 41.555404],
							[24.546087, 41.556049],
							[24.545807, 41.556187],
							[24.544899, 41.556454],
							[24.544722, 41.556859],
							[24.543756, 41.557528],
							[24.542946, 41.557825],
							[24.54208, 41.557967],
							[24.540265, 41.558026],
							[24.539633, 41.558033],
							[24.539263, 41.55808],
							[24.538899, 41.558186],
							[24.538017, 41.557659],
							[24.537367, 41.557139],
							[24.537195, 41.557053],
							[24.536621, 41.557038],
							[24.536356, 41.556963],
							[24.536193, 41.556677],
							[24.535994, 41.556271],
							[24.535656, 41.555619],
							[24.535381, 41.555114],
							[24.535202, 41.555305],
							[24.534167, 41.556329],
							[24.533793, 41.55674],
							[24.533063, 41.557578],
							[24.533008, 41.55769],
							[24.533028, 41.557783],
							[24.53307, 41.557827],
							[24.533208, 41.557974],
							[24.533558, 41.558288],
							[24.533958, 41.558535],
							[24.534302, 41.558808],
							[24.534377, 41.558983],
							[24.534367, 41.559152],
							[24.534217, 41.55972],
							[24.534208, 41.559876],
							[24.534192, 41.560128],
							[24.534242, 41.560456],
							[24.534477, 41.560998],
							[24.534737, 41.561952],
							[24.534842, 41.562258],
							[24.534967, 41.562561],
							[24.534967, 41.562935],
							[24.534877, 41.563297],
							[24.534842, 41.563716],
							[24.534647, 41.563911],
							[24.534577, 41.564086],
							[24.534572, 41.564254],
							[24.534757, 41.564556],
							[24.534792, 41.564613],
							[24.534897, 41.564721],
							[24.535054, 41.564881],
							[24.535177, 41.565006],
							[24.535051, 41.565112],
							[24.534917, 41.565226],
							[24.533673, 41.565258],
							[24.533603, 41.56526],
							[24.533472, 41.56536],
							[24.533126, 41.565626],
							[24.532983, 41.565735],
							[24.530965, 41.566052],
							[24.53056, 41.566236],
							[24.530146, 41.566367],
							[24.529871, 41.566819],
							[24.529671, 41.566774],
							[24.529251, 41.566923],
							[24.528887, 41.566722],
							[24.527847, 41.567477],
							[24.527608, 41.567589],
							[24.527223, 41.567563],
							[24.526953, 41.567342],
							[24.526354, 41.567723],
							[24.526124, 41.567989],
							[24.525774, 41.568531],
							[24.525664, 41.568647],
							[24.525399, 41.568748],
							[24.52509, 41.568748],
							[24.524605, 41.568695],
							[24.52446, 41.568628],
							[24.524385, 41.568482],
							[24.5244, 41.568236],
							[24.524075, 41.567473],
							[24.521757, 41.567581],
							[24.521317, 41.567443],
							[24.521158, 41.567398],
							[24.521018, 41.56729],
							[24.520613, 41.566912],
							[24.519904, 41.566232],
							[24.519669, 41.566101],
							[24.518924, 41.565847],
							[24.518295, 41.565563],
							[24.517925, 41.565552],
							[24.517556, 41.565518],
							[24.517121, 41.565387],
							[24.516711, 41.565084],
							[24.516386, 41.564722],
							[24.516261, 41.5644],
							[24.516327, 41.564154],
							[24.515187, 41.56344],
							[24.513933, 41.562733],
							[24.513529, 41.562569],
							[24.513339, 41.562425],
							[24.512849, 41.561935],
							[24.512362, 41.561176],
							[24.511672, 41.560211],
							[24.509605, 41.558551],
							[24.506387, 41.558764],
							[24.502856, 41.558407],
							[24.501499, 41.558428],
							[24.500429, 41.558613],
							[24.500024, 41.558665],
							[24.499683, 41.558723],
							[24.49909, 41.558821],
							[24.498283, 41.557507],
							[24.497561, 41.556011],
							[24.497344, 41.555398],
							[24.49726, 41.5552],
							[24.497037, 41.554952],
							[24.496326, 41.554344],
							[24.49579, 41.554006],
							[24.495219, 41.553776],
							[24.494644, 41.553614],
							[24.493926, 41.553475],
							[24.49325, 41.555105],
							[24.49278, 41.555555],
							[24.492359, 41.555884],
							[24.491582, 41.55615],
							[24.490901, 41.55642],
							[24.48983, 41.55683],
							[24.488975, 41.557249],
							[24.486271, 41.557217],
							[24.48499, 41.557255],
							[24.484181, 41.557379],
							[24.483656, 41.557472],
							[24.483474, 41.556911],
							[24.483474, 41.556003],
							[24.4833, 41.554404],
							[24.483021, 41.553995],
							[24.481997, 41.554442],
							[24.48129, 41.554308],
							[24.479434, 41.553843],
							[24.478366, 41.553263],
							[24.476492, 41.552913],
							[24.475117, 41.551199],
							[24.473532, 41.550748],
							[24.472677, 41.549953],
							[24.471699, 41.54721],
							[24.470418, 41.546769],
							[24.469698, 41.545955],
							[24.469664, 41.544992],
							[24.468421, 41.544967],
							[24.467107, 41.54517],
							[24.465883, 41.545355],
							[24.464616, 41.545252],
							[24.463942, 41.544795],
							[24.461677, 41.540257],
							[24.460981, 41.538545],
							[24.461686, 41.537863],
							[24.463485, 41.535363],
							[24.463442, 41.534413],
							[24.46317, 41.531736],
							[24.46317, 41.528434],
							[24.462276, 41.528097],
							[24.458675, 41.527231],
							[24.458191, 41.526873],
							[24.457232, 41.526124],
							[24.456732, 41.525582],
							[24.455684, 41.524507],
							[24.455012, 41.523859],
							[24.454436, 41.523543],
							[24.453764, 41.523306],
							[24.453034, 41.522867],
							[24.451738, 41.522393],
							[24.450382, 41.521873],
							[24.44987, 41.52193],
							[24.449088, 41.522097],
							[24.44817, 41.522723],
							[24.447422, 41.523582],
							[24.44671, 41.524644],
							[24.44445, 41.525595],
							[24.443315, 41.526058],
							[24.443174, 41.526199],
							[24.442567, 41.527085],
							[24.442138, 41.52782],
							[24.440978, 41.529142],
							[24.440714, 41.529345],
							[24.44029, 41.52938],
							[24.439831, 41.529278],
							[24.438548, 41.528785],
							[24.435376, 41.527331],
							[24.433887, 41.527481],
							[24.434199, 41.526212],
							[24.433217, 41.526829],
							[24.431904, 41.52734],
							[24.430933, 41.527631],
							[24.429745, 41.527917],
							[24.42782, 41.529077],
							[24.424604, 41.529669],
							[24.423797, 41.528872],
							[24.422632, 41.528445],
							[24.421866, 41.528604],
							[24.421433, 41.528713],
							[24.420354, 41.529308],
							[24.420291, 41.529325],
							[24.419747, 41.529089],
							[24.419129, 41.528829],
							[24.418629, 41.528714],
							[24.417934, 41.528591],
							[24.416622, 41.528556],
							[24.416069, 41.528789],
							[24.415539, 41.529063],
							[24.414874, 41.529811],
							[24.413744, 41.530327],
							[24.412473, 41.530406],
							[24.412038, 41.531111],
							[24.412061, 41.531983],
							[24.411955, 41.533058],
							[24.411897, 41.534151],
							[24.411455, 41.534939],
							[24.411102, 41.535238],
							[24.410837, 41.53549],
							[24.410702, 41.535785],
							[24.410655, 41.536097],
							[24.410696, 41.536692],
							[24.410814, 41.537349],
							[24.410843, 41.537622],
							[24.410749, 41.537815],
							[24.41049, 41.538],
							[24.409866, 41.5383],
							[24.40836, 41.538921],
							[24.406777, 41.539604],
							[24.405494, 41.539745],
							[24.404246, 41.539485],
							[24.40377, 41.538525],
							[24.402705, 41.538],
							[24.400739, 41.538278],
							[24.400222, 41.538314],
							[24.399202, 41.538301],
							[24.398395, 41.538298],
							[24.397758, 41.538329],
							[24.397059, 41.539021],
							[24.396926, 41.539108],
							[24.396543, 41.539092],
							[24.396061, 41.539024],
							[24.395711, 41.538934],
							[24.395395, 41.538762],
							[24.394796, 41.538401],
							[24.39418, 41.538111],
							[24.393743, 41.537965],
							[24.393571, 41.537946],
							[24.393359, 41.537984],
							[24.393386, 41.536131],
							[24.393294, 41.535029],
							[24.391655, 41.535253],
							[24.391072, 41.535371],
							[24.390506, 41.535527],
							[24.389125, 41.536025],
							[24.388384, 41.536355],
							[24.387552, 41.536823],
							[24.385937, 41.536574],
							[24.384722, 41.536461],
							[24.383565, 41.5361],
							[24.383507, 41.535128],
							[24.383041, 41.534119],
							[24.382717, 41.533185],
							[24.382151, 41.53235],
							[24.380969, 41.531839],
							[24.379879, 41.53159],
							[24.378639, 41.530942],
							[24.378273, 41.529939],
							[24.378173, 41.529652],
							[24.37758, 41.528599],
							[24.377026, 41.527797],
							[24.376494, 41.52717],
							[24.375014, 41.525817],
							[24.374713, 41.525548],
							[24.374045, 41.524983],
							[24.374292, 41.523983],
							[24.374403, 41.523072],
							[24.373537, 41.522584],
							[24.37232, 41.522108],
							[24.370992, 41.522304],
							[24.369808, 41.52262],
							[24.368591, 41.522334],
							[24.368234, 41.521435],
							[24.36848, 41.521042],
							[24.368742, 41.520525],
							[24.369394, 41.519745],
							[24.369641, 41.518739],
							[24.370078, 41.51828],
							[24.369768, 41.517727],
							[24.368448, 41.517846],
							[24.367701, 41.517649],
							[24.36697, 41.517483],
							[24.365928, 41.517375],
							[24.364934, 41.516917],
							[24.363583, 41.516495],
							[24.362366, 41.516673],
							[24.361611, 41.516405],
							[24.361317, 41.516667],
							[24.361182, 41.51737],
							[24.360896, 41.517983],
							[24.359965, 41.518435],
							[24.359425, 41.518453],
							[24.359162, 41.518453],
							[24.358773, 41.518554],
							[24.358439, 41.518769],
							[24.35832, 41.519025],
							[24.35832, 41.519399],
							[24.358367, 41.520519],
							[24.357238, 41.521667],
							[24.355931, 41.52165],
							[24.354397, 41.522657],
							[24.351167, 41.524082],
							[24.348955, 41.525078],
							[24.348585, 41.5252],
							[24.347895, 41.524974],
							[24.347308, 41.524852],
							[24.345453, 41.524437],
							[24.344605, 41.524383],
							[24.344174, 41.52436],
							[24.343733, 41.524475],
							[24.343336, 41.524601],
							[24.343197, 41.524786],
							[24.342933, 41.525384],
							[24.342695, 41.525658],
							[24.342497, 41.525852],
							[24.342284, 41.525894],
							[24.341354, 41.525975],
							[24.339901, 41.525911],
							[24.337621, 41.526761],
							[24.336809, 41.525344],
							[24.335777, 41.524792],
							[24.334811, 41.524292],
							[24.334456, 41.524022],
							[24.333801, 41.523349],
							[24.333329, 41.522865],
							[24.332939, 41.522351],
							[24.332614, 41.521787],
							[24.332406, 41.521355],
							[24.332153, 41.521022],
							[24.331746, 41.520689],
							[24.331444, 41.520425],
							[24.330889, 41.520166],
							[24.330603, 41.520067],
							[24.330268, 41.520022],
							[24.330081, 41.52008],
							[24.329559, 41.520392],
							[24.328999, 41.520734],
							[24.328576, 41.520931],
							[24.32829, 41.52096],
							[24.327686, 41.520931],
							[24.327202, 41.52087],
							[24.327005, 41.520763],
							[24.326543, 41.520438],
							[24.326108, 41.520247],
							[24.325933, 41.520072],
							[24.325587, 41.52001],
							[24.324883, 41.519965],
							[24.323949, 41.519932],
							[24.322493, 41.519932],
							[24.322158, 41.519607],
							[24.321636, 41.519134],
							[24.320658, 41.518656],
							[24.319394, 41.51866],
							[24.317559, 41.518331],
							[24.31696, 41.518241],
							[24.31682, 41.518152],
							[24.316691, 41.517694],
							[24.316416, 41.516965],
							[24.315731, 41.515366],
							[24.315203, 41.514452],
							[24.31509, 41.514226],
							[24.315214, 41.51397],
							[24.315315, 41.513681],
							[24.315262, 41.513507],
							[24.315214, 41.513385],
							[24.315059, 41.513277],
							[24.314686, 41.51321],
							[24.314087, 41.513158],
							[24.313147, 41.513123],
							[24.31263, 41.513516],
							[24.311982, 41.513239],
							[24.311554, 41.512972],
							[24.310913, 41.512762],
							[24.310326, 41.512672],
							[24.309425, 41.512687],
							[24.30798, 41.512841],
							[24.307129, 41.512765],
							[24.305913, 41.512041],
							[24.305586, 41.511796],
							[24.30526, 41.510583],
							[24.304864, 41.510435],
							[24.304277, 41.510769],
							[24.303205, 41.511389],
							[24.302583, 41.511884],
							[24.302167, 41.512253],
							[24.301981, 41.512524],
							[24.301899, 41.512788],
							[24.301841, 41.51307],
							[24.30188, 41.513536],
							[24.301526, 41.513871],
							[24.301149, 41.514211],
							[24.300959, 41.514479],
							[24.30085, 41.514764],
							[24.300722, 41.515805],
							[24.300364, 41.51637],
							[24.300065, 41.516719],
							[24.2993, 41.517487],
							[24.298857, 41.518083],
							[24.298779, 41.518217],
							[24.298795, 41.518398],
							[24.298896, 41.518537],
							[24.299047, 41.518679],
							[24.299211, 41.519447],
							[24.299296, 41.52007],
							[24.299342, 41.520875],
							[24.299331, 41.521487],
							[24.299353, 41.521815],
							[24.29944, 41.522386],
							[24.299548, 41.522734],
							[24.299661, 41.523038],
							[24.299802, 41.523367],
							[24.299824, 41.523595],
							[24.299119, 41.523372],
							[24.298614, 41.523244],
							[24.297328, 41.523226],
							[24.297136, 41.523263],
							[24.296311, 41.523463],
							[24.295984, 41.523581],
							[24.295786, 41.523727],
							[24.295583, 41.523925],
							[24.295386, 41.524229],
							[24.295313, 41.524386],
							[24.294776, 41.526418],
							[24.295277, 41.527354],
							[24.296521, 41.52801],
							[24.297435, 41.528449],
							[24.298453, 41.529047],
							[24.298621, 41.529285],
							[24.298782, 41.529623],
							[24.298889, 41.529989],
							[24.298966, 41.530379],
							[24.299044, 41.530939],
							[24.299125, 41.531218],
							[24.299336, 41.531864],
							[24.299539, 41.532211],
							[24.299961, 41.532843],
							[24.300313, 41.533746],
							[24.301368, 41.534447],
							[24.302078, 41.535254],
							[24.302166, 41.536196],
							[24.302354, 41.5368],
							[24.302513, 41.537156],
							[24.303195, 41.538013],
							[24.299756, 41.539417],
							[24.298247, 41.540454],
							[24.297765, 41.541405],
							[24.297511, 41.542296],
							[24.297619, 41.54354],
							[24.29776, 41.544281],
							[24.297056, 41.544941],
							[24.296786, 41.545172],
							[24.296277, 41.545524],
							[24.295855, 41.545787],
							[24.293777, 41.546816],
							[24.293317, 41.547399],
							[24.292841, 41.547934],
							[24.292035, 41.548254],
							[24.291028, 41.54874],
							[24.288474, 41.550768],
							[24.287706, 41.551335],
							[24.286959, 41.55187],
							[24.286223, 41.552135],
							[24.285027, 41.552457],
							[24.284579, 41.552499],
							[24.283955, 41.552592],
							[24.282341, 41.552551],
							[24.281091, 41.552819],
							[24.280017, 41.553355],
							[24.279319, 41.554367],
							[24.279085, 41.555059],
							[24.278977, 41.555329],
							[24.27852, 41.55548],
							[24.277303, 41.556612],
							[24.276066, 41.55647],
							[24.27519, 41.556696],
							[24.274917, 41.556742],
							[24.274382, 41.556742],
							[24.273831, 41.556593],
							[24.271992, 41.557569],
							[24.271514, 41.558269],
							[24.27133, 41.558486],
							[24.270943, 41.558767],
							[24.270346, 41.559051],
							[24.270102, 41.55935],
							[24.270224, 41.559528],
							[24.270351, 41.559653],
							[24.270416, 41.559781],
							[24.27024, 41.559973],
							[24.270163, 41.560102],
							[24.270167, 41.560224],
							[24.270236, 41.560319],
							[24.270367, 41.560398],
							[24.270485, 41.560578],
							[24.270657, 41.560624],
							[24.270828, 41.560578],
							[24.270996, 41.560569],
							[24.271175, 41.560639],
							[24.271265, 41.56082],
							[24.271359, 41.560975],
							[24.271583, 41.56114],
							[24.271686, 41.561256],
							[24.271543, 41.561458],
							[24.271739, 41.561559],
							[24.271873, 41.561647],
							[24.272012, 41.561757],
							[24.272041, 41.561934],
							[24.272053, 41.56213],
							[24.272094, 41.562298],
							[24.272261, 41.562539],
							[24.272412, 41.562823],
							[24.272408, 41.56304],
							[24.272424, 41.56322],
							[24.272743, 41.56355],
							[24.272837, 41.563749],
							[24.273024, 41.564109],
							[24.273147, 41.564546],
							[24.273257, 41.56483],
							[24.27347, 41.565004],
							[24.273686, 41.565114],
							[24.273923, 41.565148],
							[24.274135, 41.56512],
							[24.27449, 41.564986],
							[24.274555, 41.564949],
							[24.274715, 41.564952],
							[24.274878, 41.565071],
							[24.275143, 41.565303],
							[24.275176, 41.565471],
							[24.275172, 41.565798],
							[24.275123, 41.566113],
							[24.275106, 41.566339],
							[24.275151, 41.566479],
							[24.275404, 41.566675],
							[24.275559, 41.566809],
							[24.275629, 41.566974],
							[24.275641, 41.567194],
							[24.275617, 41.567432],
							[24.275527, 41.567673],
							[24.27551, 41.567878],
							[24.275453, 41.568055],
							[24.275291, 41.568262],
							[24.275112, 41.568405],
							[24.274858, 41.568552],
							[24.274541, 41.568638],
							[24.274033, 41.568768],
							[24.273651, 41.568906],
							[24.273328, 41.569092],
							[24.27297, 41.569381],
							[24.272768, 41.569649],
							[24.272537, 41.569878],
							[24.272179, 41.57002],
							[24.271146, 41.570318],
							[24.270811, 41.57034],
							[24.269714, 41.570517],
							[24.268681, 41.570603],
							[24.268646, 41.570478],
							[24.268652, 41.570301],
							[24.268514, 41.570094],
							[24.268144, 41.569904],
							[24.267405, 41.569645],
							[24.267012, 41.569424],
							[24.266135, 41.569973],
							[24.26525, 41.570606],
							[24.264359, 41.571736],
							[24.264361, 41.572131],
							[24.264131, 41.57233],
							[24.263741, 41.572444],
							[24.263315, 41.572529],
							[24.262681, 41.57286],
							[24.262515, 41.573009],
							[24.262366, 41.57315],
							[24.262218, 41.57333],
							[24.261921, 41.573502],
							[24.261543, 41.573667],
							[24.261355, 41.573353],
							[24.261182, 41.573224],
							[24.260762, 41.573002],
							[24.26046, 41.572821],
							[24.260368, 41.572663],
							[24.259953, 41.571659],
							[24.259991, 41.571353],
							[24.259985, 41.571054],
							[24.259791, 41.570643],
							[24.259678, 41.570482],
							[24.259495, 41.570296],
							[24.259199, 41.570099],
							[24.258961, 41.569998],
							[24.258659, 41.569917],
							[24.258428, 41.569921],
							[24.258147, 41.570078],
							[24.257673, 41.57034],
							[24.257414, 41.570445],
							[24.256315, 41.570655],
							[24.255183, 41.570276],
							[24.254784, 41.570151],
							[24.254158, 41.570086],
							[24.253792, 41.570006],
							[24.253592, 41.569848],
							[24.253539, 41.569679],
							[24.253592, 41.569018],
							[24.253334, 41.568776],
							[24.253264, 41.568485],
							[24.253124, 41.568195],
							[24.253166, 41.567884],
							[24.253097, 41.567723],
							[24.252611, 41.56736],
							[24.25197, 41.566783],
							[24.251727, 41.5669],
							[24.251447, 41.566985],
							[24.25107, 41.567025],
							[24.250854, 41.567074],
							[24.250644, 41.567231],
							[24.250212, 41.567114],
							[24.249905, 41.567054],
							[24.249576, 41.567102],
							[24.249285, 41.567211],
							[24.248822, 41.567045],
							[24.248285, 41.566964],
							[24.248109, 41.56687],
							[24.247984, 41.566739],
							[24.2479, 41.566482],
							[24.247861, 41.566112],
							[24.248006, 41.565855],
							[24.248022, 41.56565],
							[24.247999, 41.565461],
							[24.24777, 41.565102],
							[24.247442, 41.564862],
							[24.247191, 41.564617],
							[24.247061, 41.564298],
							[24.246947, 41.56403],
							[24.246726, 41.563899],
							[24.246362, 41.563866],
							[24.246221, 41.563842],
							[24.246092, 41.563709],
							[24.245984, 41.563584],
							[24.245914, 41.56337],
							[24.245839, 41.563205],
							[24.24565, 41.563092],
							[24.245267, 41.563015],
							[24.244895, 41.563003],
							[24.244577, 41.563039],
							[24.244055, 41.563176],
							[24.243343, 41.563265],
							[24.243063, 41.563261],
							[24.24268, 41.563217],
							[24.24227, 41.56318],
							[24.241796, 41.563116],
							[24.241548, 41.562971],
							[24.241181, 41.56268],
							[24.240788, 41.562426],
							[24.240551, 41.562281],
							[24.240572, 41.562144],
							[24.240788, 41.561789],
							[24.240874, 41.561599],
							[24.240912, 41.561369],
							[24.24082, 41.561067],
							[24.240745, 41.560877],
							[24.240615, 41.56053],
							[24.24061, 41.560321],
							[24.240675, 41.560119],
							[24.240648, 41.559982],
							[24.240534, 41.559865],
							[24.240308, 41.55972],
							[24.24013, 41.559607],
							[24.23999, 41.55947],
							[24.23999, 41.559236],
							[24.240049, 41.558849],
							[24.24003, 41.558573],
							[24.240019, 41.558248],
							[24.240057, 41.558045],
							[24.240145, 41.557894],
							[24.240377, 41.557686],
							[24.240713, 41.557426],
							[24.240389, 41.557021],
							[24.240049, 41.556696],
							[24.239516, 41.556288],
							[24.239222, 41.556194],
							[24.238879, 41.556108],
							[24.238731, 41.55594],
							[24.238464, 41.555412],
							[24.2383, 41.555173],
							[24.238144, 41.555045],
							[24.237905, 41.554854],
							[24.237797, 41.55466],
							[24.237759, 41.554334],
							[24.237797, 41.554096],
							[24.237388, 41.553987],
							[24.236994, 41.553866],
							[24.23666, 41.553789],
							[24.23618, 41.553741],
							[24.235515, 41.553587],
							[24.23521, 41.553559],
							[24.234663, 41.553556],
							[24.234313, 41.55352],
							[24.234213, 41.553488],
							[24.2341, 41.553409],
							[24.233949, 41.553103],
							[24.233757, 41.552744],
							[24.233687, 41.552538],
							[24.233633, 41.552312],
							[24.233531, 41.552078],
							[24.23338, 41.551884],
							[24.233213, 41.551755],
							[24.232631, 41.551473],
							[24.232156, 41.551227],
							[24.231898, 41.551053],
							[24.23165, 41.550811],
							[24.231499, 41.550537],
							[24.231466, 41.550291],
							[24.231504, 41.549959],
							[24.23159, 41.549496],
							[24.231585, 41.549153],
							[24.231558, 41.548831],
							[24.231457, 41.548511],
							[24.231358, 41.548346],
							[24.231163, 41.548206],
							[24.230881, 41.548066],
							[24.230504, 41.547892],
							[24.230121, 41.547662],
							[24.229679, 41.547291],
							[24.229394, 41.546985],
							[24.229239, 41.546749],
							[24.228704, 41.54697],
							[24.228254, 41.547193],
							[24.227873, 41.547427],
							[24.227652, 41.547644],
							[24.227469, 41.548071],
							[24.227118, 41.548374],
							[24.226875, 41.548534],
							[24.226638, 41.548636],
							[24.226402, 41.548648],
							[24.225518, 41.548351],
							[24.224656, 41.548066],
							[24.224466, 41.548054],
							[24.224145, 41.548134],
							[24.223841, 41.54818],
							[24.223596, 41.548134],
							[24.223337, 41.548009],
							[24.223147, 41.548003],
							[24.222956, 41.548066],
							[24.222232, 41.548454],
							[24.221881, 41.548591],
							[24.221165, 41.548859],
							[24.220471, 41.549195],
							[24.220151, 41.54927],
							[24.219663, 41.549384],
							[24.219144, 41.549532],
							[24.218771, 41.549532],
							[24.21807, 41.549441],
							[24.217719, 41.549447],
							[24.217277, 41.549612],
							[24.21685, 41.549703],
							[24.216255, 41.549909],
							[24.215592, 41.550085],
							[24.215211, 41.550331],
							[24.214044, 41.551477],
							[24.213686, 41.551683],
							[24.213275, 41.551888],
							[24.212909, 41.552145],
							[24.212596, 41.552385],
							[24.212405, 41.55231],
							[24.212245, 41.552185],
							[24.21207, 41.551934],
							[24.211841, 41.551477],
							[24.211674, 41.551204],
							[24.211361, 41.550856],
							[24.21098, 41.550622],
							[24.210736, 41.550491],
							[24.210439, 41.550046],
							[24.209928, 41.5496],
							[24.208914, 41.548876],
							[24.208762, 41.548693],
							[24.208815, 41.548237],
							[24.20899, 41.547507],
							[24.209425, 41.54608],
							[24.209783, 41.545521],
							[24.210164, 41.544654],
							[24.210187, 41.544215],
							[24.210035, 41.543872],
							[24.209753, 41.543598],
							[24.209341, 41.543324],
							[24.208769, 41.543045],
							[24.207877, 41.54272],
							[24.206795, 41.542452],
							[24.205331, 41.54228],
							[24.204561, 41.542155],
							[24.204249, 41.541984],
							[24.204172, 41.54179],
							[24.203944, 41.540882],
							[24.203715, 41.540437],
							[24.203227, 41.539844],
							[24.201962, 41.538925],
							[24.201184, 41.538423],
							[24.20078, 41.538366],
							[24.19998, 41.538349],
							[24.199461, 41.538463],
							[24.198973, 41.538686],
							[24.198093, 41.539065],
							[24.197823, 41.539041],
							[24.197468, 41.538832],
							[24.197209, 41.538485],
							[24.196972, 41.537984],
							[24.196918, 41.536729],
							[24.197031, 41.536194],
							[24.197058, 41.535779],
							[24.196945, 41.535472],
							[24.196627, 41.535097],
							[24.196034, 41.534661],
							[24.195268, 41.534373],
							[24.195839, 41.533533],
							[24.195854, 41.53322],
							[24.195221, 41.532609],
							[24.194047, 41.532055],
							[24.193605, 41.531987],
							[24.193049, 41.532015],
							[24.192134, 41.531976],
							[24.190731, 41.531759],
							[24.189855, 41.53153],
							[24.190091, 41.531194],
							[24.190518, 41.53076],
							[24.190815, 41.530418],
							[24.191448, 41.529116],
							[24.191911, 41.528436],
							[24.192148, 41.528025],
							[24.19218, 41.527476],
							[24.192228, 41.527051],
							[24.192657, 41.526013],
							[24.19275, 41.525631],
							[24.192857, 41.525082],
							[24.193085, 41.524384],
							[24.194387, 41.52221],
							[24.194402, 41.521847],
							[24.194302, 41.521192],
							[24.194152, 41.52059],
							[24.192316, 41.519758],
							[24.192003, 41.519545],
							[24.192035, 41.518282],
							[24.191927, 41.517971],
							[24.191583, 41.517695],
							[24.190287, 41.516965],
							[24.189606, 41.516667],
							[24.188674, 41.516049],
							[24.18693, 41.515311],
							[24.186482, 41.515255],
							[24.186193, 41.515329],
							[24.185813, 41.515588],
							[24.185375, 41.516006],
							[24.185065, 41.516179],
							[24.184677, 41.515905],
							[24.183335, 41.514858],
							[24.182347, 41.514643],
							[24.180805, 41.514874],
							[24.17944, 41.514824],
							[24.178445, 41.514884],
							[24.17789, 41.515292],
							[24.177762, 41.515377],
							[24.177427, 41.515532],
							[24.177076, 41.515658],
							[24.176757, 41.515676],
							[24.176446, 41.515625],
							[24.176189, 41.515493],
							[24.175904, 41.515291],
							[24.175696, 41.515201],
							[24.175069, 41.515319],
							[24.174328, 41.515475],
							[24.173959, 41.515579],
							[24.173131, 41.515887],
							[24.172763, 41.516032],
							[24.172331, 41.516272],
							[24.17222, 41.516855],
							[24.171907, 41.518059],
							[24.171761, 41.518699],
							[24.171747, 41.518955],
							[24.17193, 41.519305],
							[24.172265, 41.519814],
							[24.172621, 41.520176],
							[24.17298, 41.520421],
							[24.173471, 41.520668],
							[24.173937, 41.520989],
							[24.174295, 41.521319],
							[24.174667, 41.521538],
							[24.174848, 41.521692],
							[24.174914, 41.521877],
							[24.1749, 41.522101],
							[24.17482, 41.522304],
							[24.174643, 41.522674],
							[24.174431, 41.523093],
							[24.174229, 41.523361],
							[24.174024, 41.52352],
							[24.17378, 41.523564],
							[24.173492, 41.523596],
							[24.173057, 41.523601],
							[24.172664, 41.523578],
							[24.172347, 41.52358],
							[24.171968, 41.52365],
							[24.171436, 41.523757],
							[24.17098, 41.523752],
							[24.170531, 41.523768],
							[24.170048, 41.52384],
							[24.169317, 41.52401],
							[24.168618, 41.524236],
							[24.168455, 41.52432],
							[24.168208, 41.524466],
							[24.167526, 41.524918],
							[24.166778, 41.525309],
							[24.166637, 41.525438],
							[24.166632, 41.525694],
							[24.166759, 41.526037],
							[24.166785, 41.526282],
							[24.166795, 41.526422],
							[24.166759, 41.526588],
							[24.166572, 41.527085],
							[24.166444, 41.527391],
							[24.166183, 41.527737],
							[24.166129, 41.527881],
							[24.166129, 41.528073],
							[24.166144, 41.528367],
							[24.166139, 41.528478],
							[24.166016, 41.528533],
							[24.165859, 41.52854],
							[24.165691, 41.528514],
							[24.16549, 41.528426],
							[24.165337, 41.528286],
							[24.165062, 41.528043],
							[24.164786, 41.527859],
							[24.164614, 41.527789],
							[24.164329, 41.527763],
							[24.164078, 41.527774],
							[24.163763, 41.527804],
							[24.163473, 41.527774],
							[24.162996, 41.527697],
							[24.162848, 41.527686],
							[24.1627, 41.527734],
							[24.162612, 41.527807],
							[24.162499, 41.527918],
							[24.162282, 41.528146],
							[24.162076, 41.528319],
							[24.161697, 41.528474],
							[24.161387, 41.528595],
							[24.161062, 41.528813],
							[24.160797, 41.529082],
							[24.160575, 41.529468],
							[24.160433, 41.529826],
							[24.160285, 41.530304],
							[24.16029, 41.53075],
							[24.160354, 41.531159],
							[24.160506, 41.531755],
							[24.160506, 41.532252],
							[24.160654, 41.533306],
							[24.160585, 41.533589],
							[24.16026, 41.533968],
							[24.159985, 41.534219],
							[24.159832, 41.534469],
							[24.16, 41.534731],
							[24.160147, 41.535029],
							[24.160216, 41.535405],
							[24.160565, 41.536016],
							[24.159975, 41.536351],
							[24.158489, 41.537227],
							[24.158012, 41.537467],
							[24.156344, 41.537585],
							[24.155739, 41.537794],
							[24.155149, 41.53806],
							[24.15445, 41.538303],
							[24.153103, 41.538439],
							[24.1521, 41.538633],
							[24.151523, 41.538804],
							[24.150813, 41.539039],
							[24.15041, 41.539065],
							[24.149805, 41.538966],
							[24.149485, 41.538763],
							[24.149067, 41.538492],
							[24.148358, 41.538174],
							[24.147801, 41.538013],
							[24.147154, 41.537935],
							[24.146152, 41.537841],
							[24.145547, 41.537711],
							[24.144141, 41.537154],
							[24.143961, 41.537029],
							[24.143613, 41.536633],
							[24.143355, 41.536336],
							[24.143007, 41.535841],
							[24.142736, 41.535529],
							[24.142444, 41.535326],
							[24.142013, 41.535159],
							[24.141727, 41.535013],
							[24.141171, 41.534821],
							[24.140148, 41.534513],
							[24.139772, 41.534456],
							[24.138437, 41.534347],
							[24.138131, 41.534331],
							[24.137873, 41.534341],
							[24.1374, 41.53455],
							[24.136948, 41.534649],
							[24.136579, 41.534644],
							[24.136148, 41.534555],
							[24.135396, 41.534284],
							[24.134478, 41.533883],
							[24.133475, 41.533367],
							[24.132918, 41.534208],
							[24.132728, 41.534605],
							[24.132571, 41.535323],
							[24.132619, 41.536051],
							[24.132707, 41.536707],
							[24.133013, 41.537038],
							[24.132986, 41.537496],
							[24.132891, 41.53882],
							[24.132809, 41.539293],
							[24.132558, 41.539644],
							[24.131993, 41.54024],
							[24.131721, 41.541034],
							[24.131266, 41.541049],
							[24.129824, 41.540978],
							[24.128872, 41.541003],
							[24.128124, 41.541125],
							[24.127689, 41.541237],
							[24.127091, 41.5414],
							[24.126696, 41.541461],
							[24.126254, 41.541426],
							[24.125874, 41.541263],
							[24.125574, 41.540998],
							[24.125044, 41.540438],
							[24.124568, 41.540046],
							[24.124085, 41.539756],
							[24.123453, 41.539542],
							[24.122888, 41.539476],
							[24.122419, 41.539456],
							[24.121596, 41.539456],
							[24.120563, 41.539334],
							[24.119597, 41.539496],
							[24.118958, 41.539552],
							[24.117985, 41.539568],
							[24.117591, 41.539634],
							[24.117183, 41.53969],
							[24.116632, 41.539675],
							[24.116224, 41.539751],
							[24.115918, 41.53996],
							[24.115585, 41.540331],
							[24.115442, 41.540723],
							[24.115347, 41.541054],
							[24.115245, 41.541212],
							[24.115028, 41.541354],
							[24.113763, 41.541802],
							[24.112675, 41.542316],
							[24.111784, 41.542703],
							[24.111349, 41.54284],
							[24.110968, 41.542901],
							[24.110703, 41.542907],
							[24.110553, 41.54281],
							[24.110356, 41.542733],
							[24.11007, 41.542728],
							[24.109724, 41.5428],
							[24.108867, 41.543019],
							[24.108411, 41.543181],
							[24.107942, 41.543288],
							[24.107527, 41.543293],
							[24.107072, 41.543354],
							[24.106698, 41.543512],
							[24.106378, 41.543762],
							[24.106222, 41.543965],
							[24.106167, 41.544311],
							[24.106147, 41.544647],
							[24.106065, 41.544958],
							[24.105888, 41.54539],
							[24.105117, 41.546455],
							[24.10478, 41.547568],
							[24.104297, 41.548764],
							[24.103998, 41.549131],
							[24.103488, 41.54964],
							[24.103087, 41.549996],
							[24.102686, 41.550225],
							[24.102298, 41.550413],
							[24.101958, 41.550479],
							[24.101659, 41.550479],
							[24.101339, 41.550444],
							[24.100904, 41.550357],
							[24.100183, 41.550113],
							[24.099497, 41.549853],
							[24.099021, 41.549573],
							[24.098755, 41.549273],
							[24.098599, 41.549024],
							[24.098483, 41.548627],
							[24.097783, 41.54852],
							[24.097171, 41.548311],
							[24.096396, 41.54796],
							[24.095723, 41.547594],
							[24.095043, 41.547182],
							[24.09439, 41.546836],
							[24.093846, 41.54653],
							[24.093275, 41.546347],
							[24.092608, 41.54621],
							[24.091989, 41.546138],
							[24.091534, 41.546164],
							[24.090684, 41.546459],
							[24.08897, 41.545573],
							[24.088005, 41.544805],
							[24.087372, 41.544454],
							[24.086407, 41.544011],
							[24.085965, 41.544026],
							[24.08523, 41.544097],
							[24.084435, 41.544087],
							[24.083755, 41.543965],
							[24.083292, 41.543797],
							[24.083, 41.543599],
							[24.082748, 41.543375],
							[24.082361, 41.542922],
							[24.081211, 41.542484],
							[24.080838, 41.542301],
							[24.080226, 41.542118],
							[24.079845, 41.542046],
							[24.079369, 41.542021],
							[24.078845, 41.542006],
							[24.078284, 41.541843],
							[24.077875, 41.541773],
							[24.077322, 41.541646],
							[24.077183, 41.541589],
							[24.076927, 41.541603],
							[24.076891, 41.541518],
							[24.076877, 41.541307],
							[24.076904, 41.541083],
							[24.07694, 41.540774],
							[24.076847, 41.540097],
							[24.076841, 41.539386],
							[24.076922, 41.538521],
							[24.077018, 41.537857],
							[24.076972, 41.537499],
							[24.076995, 41.537091],
							[24.076957, 41.536501],
							[24.076588, 41.535471],
							[24.076348, 41.535227],
							[24.076136, 41.534896],
							[24.07598, 41.53454],
							[24.07595, 41.534182],
							[24.07604, 41.533667],
							[24.076068, 41.533298],
							[24.076072, 41.533114],
							[24.076005, 41.532825],
							[24.075812, 41.532198],
							[24.075526, 41.531661],
							[24.07544, 41.531193],
							[24.07538, 41.531104],
							[24.073559, 41.530034],
							[24.072821, 41.529551],
							[24.071519, 41.52886],
							[24.071077, 41.528636],
							[24.070286, 41.528385],
							[24.069774, 41.528188],
							[24.069608, 41.528086],
							[24.069472, 41.527987],
							[24.069212, 41.527825],
							[24.069007, 41.527745],
							[24.068591, 41.527638],
							[24.068212, 41.527569],
							[24.067674, 41.527519],
							[24.066664, 41.527404],
							[24.065062, 41.527136],
							[24.064238, 41.527041],
							[24.063673, 41.526887],
							[24.063474, 41.526802],
							[24.063241, 41.526529],
							[24.063055, 41.526175],
							[24.062882, 41.525877],
							[24.062703, 41.525598],
							[24.061462, 41.523916],
							[24.061103, 41.523441],
							[24.06092, 41.523162],
							[24.060854, 41.522971],
							[24.060595, 41.522573],
							[24.060388, 41.522247],
							[24.060276, 41.522001],
							[24.060237, 41.521829],
							[24.060216, 41.521534],
							[24.060089, 41.521243],
							[24.060018, 41.521007],
							[24.059772, 41.520652],
							[24.059635, 41.52021],
							[24.059679, 41.519853],
							[24.059846, 41.519379],
							[24.060243, 41.518552],
							[24.060876, 41.517608],
							[24.061317, 41.51712],
							[24.061559, 41.516567],
							[24.06164, 41.516177],
							[24.061633, 41.515815],
							[24.061503, 41.515322],
							[24.061304, 41.514513],
							[24.060876, 41.513617],
							[24.060547, 41.513031],
							[24.059989, 41.512441],
							[24.05966, 41.511939],
							[24.059586, 41.511474],
							[24.059741, 41.511168],
							[24.060051, 41.510736],
							[24.060392, 41.510433],
							[24.061013, 41.51015],
							[24.061565, 41.509862],
							[24.061993, 41.509546],
							[24.062266, 41.509216],
							[24.062583, 41.508654],
							[24.062726, 41.508226],
							[24.06267, 41.507975],
							[24.062539, 41.507692],
							[24.062558, 41.507501],
							[24.063632, 41.505675],
							[24.064184, 41.505182],
							[24.064246, 41.504912],
							[24.06419, 41.504782],
							[24.063992, 41.504661],
							[24.063799, 41.504531],
							[24.063712, 41.504383],
							[24.0637, 41.504127],
							[24.063818, 41.503597],
							[24.063961, 41.503295],
							[24.064259, 41.503025],
							[24.065016, 41.50263],
							[24.065922, 41.501645],
							[24.067144, 41.500599],
							[24.066735, 41.50013],
							[24.066188, 41.499591],
							[24.064811, 41.498182],
							[24.064234, 41.497592],
							[24.064128, 41.497471],
							[24.064103, 41.497299],
							[24.064054, 41.496672],
							[24.064004, 41.496323],
							[24.063762, 41.495417],
							[24.063706, 41.495115],
							[24.063734, 41.494799],
							[24.063877, 41.494521],
							[24.063917, 41.494295],
							[24.063877, 41.494181],
							[24.063634, 41.493655],
							[24.063548, 41.493419],
							[24.063476, 41.493059],
							[24.063359, 41.4928],
							[24.063528, 41.491872],
							[24.063546, 41.491191],
							[24.063413, 41.490523],
							[24.06322, 41.4899],
							[24.062939, 41.489474],
							[24.062327, 41.488906],
							[24.061484, 41.48821],
							[24.061047, 41.487724],
							[24.060826, 41.487428],
							[24.060634, 41.486929],
							[24.060592, 41.486624],
							[24.060574, 41.485916],
							[24.06061, 41.485434],
							[24.060689, 41.485152],
							[24.060817, 41.484934],
							[24.061035, 41.484766],
							[24.061277, 41.48463],
							[24.061678, 41.484621],
							[24.062533, 41.484725],
							[24.062885, 41.484707],
							[24.06374, 41.484521],
							[24.065044, 41.484189],
							[24.065711, 41.483989],
							[24.066561, 41.483653],
							[24.067367, 41.483294],
							[24.068132, 41.482885],
							[24.06852, 41.482617],
							[24.068732, 41.482417],
							[24.068957, 41.48193],
							[24.069091, 41.480717],
							[24.069187, 41.480454],
							[24.069272, 41.480258],
							[24.069515, 41.479995],
							[24.069879, 41.479722],
							[24.0704, 41.479377],
							[24.070667, 41.479131],
							[24.070867, 41.478931],
							[24.071098, 41.478572],
							[24.071104, 41.478095],
							[24.070788, 41.477531],
							[24.070704, 41.477382],
							[24.070613, 41.477018],
							[24.070661, 41.476568],
							[24.070807, 41.476286],
							[24.071116, 41.475891],
							[24.071431, 41.475464],
							[24.071529, 41.475282],
							[24.071589, 41.475014],
							[24.071639, 41.474487],
							[24.072062, 41.473755],
							[24.072208, 41.473537],
							[24.072329, 41.473214],
							[24.072384, 41.473064],
							[24.072414, 41.472519],
							[24.07296, 41.471287],
							[24.073221, 41.470473],
							[24.073348, 41.469924],
							[24.073463, 41.46911],
							[24.073512, 41.468478],
							[24.073494, 41.467956],
							[24.073373, 41.467101],
							[24.073289, 41.466876],
							[24.073142, 41.466483],
							[24.072796, 41.465906],
							[24.072329, 41.465228],
							[24.072074, 41.464906],
							[24.071741, 41.464656],
							[24.071225, 41.464592],
							[24.070503, 41.464551],
							[24.070176, 41.464551],
							[24.0696, 41.464606],
							[24.068277, 41.464819],
							[24.067707, 41.464915],
							[24.067434, 41.464949],
							[24.067159, 41.464945],
							[24.066894, 41.46486],
							[24.066755, 41.464742],
							[24.066393, 41.464316],
							[24.066173, 41.46411],
							[24.065815, 41.463901],
							[24.065305, 41.463737],
							[24.06473, 41.463644],
							[24.063863, 41.463576],
							[24.062976, 41.463178],
							[24.062515, 41.462919],
							[24.061247, 41.462287],
							[24.060926, 41.462083],
							[24.060028, 41.461433],
							[24.059785, 41.461251],
							[24.059354, 41.461072],
							[24.059077, 41.460958],
							[24.05888, 41.460861],
							[24.058509, 41.460653],
							[24.058249, 41.460479],
							[24.058011, 41.460275],
							[24.057739, 41.460062],
							[24.057304, 41.459797],
							[24.057026, 41.459645],
							[24.054349, 41.458455],
							[24.054134, 41.458281],
							[24.053845, 41.457856],
							[24.053462, 41.457239],
							[24.053283, 41.456961],
							[24.053016, 41.456753],
							[24.05275, 41.456644],
							[24.051973, 41.45644],
							[24.051762, 41.456342],
							[24.051553, 41.456167],
							[24.051434, 41.455947],
							[24.051301, 41.4557],
							[24.050927, 41.455307],
							[24.0507, 41.454746],
							[24.050438, 41.454373],
							[24.050199, 41.454197],
							[24.049406, 41.453948],
							[24.049294, 41.45364],
							[24.049163, 41.453274],
							[24.049098, 41.453113],
							[24.048993, 41.452923],
							[24.048932, 41.452788],
							[24.048772, 41.45248],
							[24.048584, 41.452202],
							[24.048301, 41.451911],
							[24.047794, 41.451514],
							[24.046686, 41.450818],
							[24.046662, 41.451039],
							[24.046489, 41.451298],
							[24.04634, 41.451418],
							[24.045913, 41.4515],
							[24.045375, 41.4515],
							[24.044146, 41.451447],
							[24.043575, 41.451438],
							[24.043181, 41.451466],
							[24.042649, 41.45162],
							[24.042228, 41.451762],
							[24.041339, 41.451725],
							[24.040704, 41.451691],
							[24.04031, 41.45162],
							[24.040049, 41.451491],
							[24.039543, 41.451036],
							[24.039234, 41.450808],
							[24.03901, 41.450758],
							[24.038749, 41.450747],
							[24.038545, 41.450798],
							[24.038297, 41.451046],
							[24.038059, 41.451841],
							[24.037896, 41.452489],
							[24.037754, 41.452757],
							[24.03755, 41.452971],
							[24.037098, 41.453351],
							[24.036694, 41.453766],
							[24.036559, 41.45402],
							[24.036528, 41.454377],
							[24.03662, 41.455086],
							[24.036694, 41.455266],
							[24.036888, 41.455351],
							[24.037088, 41.455419],
							[24.037166, 41.455504],
							[24.03718, 41.455626],
							[24.037105, 41.455836],
							[24.03697, 41.45602],
							[24.036603, 41.456238],
							[24.036189, 41.456463],
							[24.035863, 41.45649],
							[24.035557, 41.456435],
							[24.035258, 41.456435],
							[24.034763, 41.456497],
							[24.034219, 41.456517],
							[24.032708, 41.456382],
							[24.032087, 41.456454],
							[24.031253, 41.456591],
							[24.030692, 41.456608],
							[24.030392, 41.45654],
							[24.030106, 41.45644],
							[24.029921, 41.456312],
							[24.029698, 41.456063],
							[24.029366, 41.45565],
							[24.029058, 41.455295],
							[24.028511, 41.454863],
							[24.028175, 41.454728],
							[24.027757, 41.454704],
							[24.027219, 41.454771],
							[24.026384, 41.454853],
							[24.025981, 41.454901],
							[24.025433, 41.455002],
							[24.024987, 41.455165],
							[24.024382, 41.455401],
							[24.02393, 41.455631],
							[24.023628, 41.455857],
							[24.023359, 41.456159],
							[24.023018, 41.456755],
							[24.022409, 41.457835],
							[24.022087, 41.458233],
							[24.021813, 41.458449],
							[24.021419, 41.45867],
							[24.020963, 41.458824],
							[24.020728, 41.458968],
							[24.020632, 41.459184],
							[24.020576, 41.459524],
							[24.020608, 41.460549],
							[24.020704, 41.46106],
							[24.020869, 41.461665],
							[24.020944, 41.462198],
							[24.020985, 41.462962],
							[24.021023, 41.463324],
							[24.021112, 41.463651],
							[24.021335, 41.464037],
							[24.021481, 41.46426],
							[24.021594, 41.464534],
							[24.021611, 41.464654],
							[24.021573, 41.464868],
							[24.021397, 41.465195],
							[24.021034, 41.465637],
							[24.020865, 41.465832],
							[24.020621, 41.466071],
							[24.020245, 41.466367],
							[24.019809, 41.466655],
							[24.019219, 41.466916],
							[24.018538, 41.467141],
							[24.017941, 41.467412],
							[24.017669, 41.467546],
							[24.017511, 41.467526],
							[24.016657, 41.467378],
							[24.015879, 41.467127],
							[24.015331, 41.466827],
							[24.014759, 41.466457],
							[24.014352, 41.466118],
							[24.01413, 41.465823],
							[24.014056, 41.465575],
							[24.014053, 41.465086],
							[24.014212, 41.464477],
							[24.014268, 41.464104],
							[24.014094, 41.463781],
							[24.013807, 41.463521],
							[24.013409, 41.463301],
							[24.013015, 41.463076],
							[24.013173, 41.462779],
							[24.013209, 41.462563],
							[24.013177, 41.462365],
							[24.012968, 41.462119],
							[24.012651, 41.461858],
							[24.012304, 41.461607],
							[24.011894, 41.461438],
							[24.011459, 41.461331],
							[24.011178, 41.461224],
							[24.01066, 41.460991],
							[24.010312, 41.460782],
							[24.010359, 41.460662],
							[24.010381, 41.460507],
							[24.010352, 41.460308],
							[24.010254, 41.460076],
							[24.009896, 41.459533],
							[24.009409, 41.459122],
							[24.008507, 41.458119],
							[24.008453, 41.457985],
							[24.008493, 41.457765],
							[24.008598, 41.457338],
							[24.008623, 41.457099],
							[24.008598, 41.456922],
							[24.008554, 41.456817],
							[24.008431, 41.456763],
							[24.007617, 41.456545],
							[24.007288, 41.45639],
							[24.006865, 41.456108],
							[24.006586, 41.455938],
							[24.006246, 41.455797],
							[24.00599, 41.455739],
							[24.005642, 41.455746],
							[24.005306, 41.455826],
							[24.005125, 41.455862],
							[24.004894, 41.455854],
							[24.004637, 41.455645],
							[24.004456, 41.455337],
							[24.004337, 41.455026],
							[24.004235, 41.454701],
							[24.00413, 41.454502],
							[24.003964, 41.45435],
							[24.003649, 41.454205],
							[24.002253, 41.453594],
							[24.000245, 41.45439],
							[24.000122, 41.45461],
							[24.000032, 41.454881],
							[23.999927, 41.45503],
							[23.999511, 41.455222],
							[23.999319, 41.455388],
							[23.999102, 41.455594],
							[23.998878, 41.455786],
							[23.998704, 41.455883],
							[23.998538, 41.455916],
							[23.998353, 41.455912],
							[23.998031, 41.455829],
							[23.997818, 41.4558],
							[23.997641, 41.455847],
							[23.997427, 41.455909],
							[23.997268, 41.455891],
							[23.997055, 41.455808],
							[23.996892, 41.45571],
							[23.996642, 41.455518],
							[23.996415, 41.455229],
							[23.996183, 41.455008],
							[23.995894, 41.454802],
							[23.995734, 41.454679],
							[23.995648, 41.454469],
							[23.995648, 41.454346],
							[23.995644, 41.454176],
							[23.995695, 41.453861],
							[23.995648, 41.453579],
							[23.995528, 41.453301],
							[23.995394, 41.453109],
							[23.995203, 41.45291],
							[23.994968, 41.452689],
							[23.994823, 41.45248],
							[23.994736, 41.452154],
							[23.994703, 41.451915],
							[23.994747, 41.451709],
							[23.994881, 41.451336],
							[23.994895, 41.451098],
							[23.994874, 41.450808],
							[23.994823, 41.45032],
							[23.99483, 41.450114],
							[23.99491, 41.449987],
							[23.995173, 41.449781],
							[23.995037, 41.449707],
							[23.994543, 41.449512],
							[23.993988, 41.449369],
							[23.993533, 41.449326],
							[23.993282, 41.449323],
							[23.992998, 41.44941],
							[23.99286, 41.449446],
							[23.992704, 41.449449],
							[23.991661, 41.449374],
							[23.991341, 41.44929],
							[23.991101, 41.449182],
							[23.990796, 41.44894],
							[23.990517, 41.448645],
							[23.990351, 41.448418],
							[23.990185, 41.448126],
							[23.990016, 41.447901],
							[23.989673, 41.447635],
							[23.989113, 41.447088],
							[23.988983, 41.446852],
							[23.988868, 41.44664],
							[23.988255, 41.446707],
							[23.987719, 41.446729],
							[23.987133, 41.446693],
							[23.986844, 41.446617],
							[23.986594, 41.446526],
							[23.986388, 41.446472],
							[23.984988, 41.446342],
							[23.984709, 41.446255],
							[23.984471, 41.446053],
							[23.984338, 41.445821],
							[23.984262, 41.445578],
							[23.984226, 41.445347],
							[23.984183, 41.44516],
							[23.984096, 41.445011],
							[23.983922, 41.444901],
							[23.983669, 41.444827],
							[23.983303, 41.444784],
							[23.983193, 41.44474],
							[23.982994, 41.444577],
							[23.982802, 41.444293],
							[23.982695, 41.444042],
							[23.982664, 41.44384],
							[23.982697, 41.443408],
							[23.982697, 41.443139],
							[23.982684, 41.442998],
							[23.982613, 41.442842],
							[23.982508, 41.442709],
							[23.982104, 41.442303],
							[23.982017, 41.442144],
							[23.981989, 41.44198],
							[23.981948, 41.441591],
							[23.981937, 41.441266],
							[23.981944, 41.440987],
							[23.98194, 41.440774],
							[23.981846, 41.440676],
							[23.981741, 41.440647],
							[23.981213, 41.440604],
							[23.981, 41.440553],
							[23.980729, 41.440455],
							[23.980425, 41.440296],
							[23.980197, 41.440246],
							[23.979904, 41.440235],
							[23.979086, 41.440227],
							[23.97879, 41.440188],
							[23.978392, 41.440039],
							[23.977889, 41.439772],
							[23.977632, 41.439522],
							[23.977187, 41.438878],
							[23.976695, 41.438885],
							[23.976066, 41.438846],
							[23.975762, 41.438799],
							[23.975266, 41.438665],
							[23.974803, 41.438607],
							[23.974503, 41.438524],
							[23.97404, 41.438339],
							[23.973772, 41.438281],
							[23.973233, 41.438285],
							[23.972792, 41.438198],
							[23.972398, 41.438187],
							[23.971895, 41.43822],
							[23.971559, 41.438274],
							[23.971291, 41.438473],
							[23.970828, 41.438936],
							[23.970633, 41.439077],
							[23.97018, 41.439312],
							[23.96992, 41.439504],
							[23.969489, 41.439848],
							[23.969381, 41.439866],
							[23.968922, 41.439703],
							[23.968173, 41.439341],
							[23.967894, 41.439236],
							[23.967507, 41.439309],
							[23.967015, 41.439294],
							[23.965988, 41.439435],
							[23.965778, 41.439518],
							[23.965648, 41.439754],
							[23.965528, 41.44018],
							[23.965463, 41.440564],
							[23.965351, 41.440821],
							[23.965159, 41.441041],
							[23.964762, 41.441295],
							[23.964056, 41.441642],
							[23.963456, 41.441859],
							[23.963163, 41.442062],
							[23.962909, 41.442275],
							[23.961788, 41.443834],
							[23.961604, 41.443972],
							[23.9613, 41.444055],
							[23.96105, 41.444051],
							[23.960786, 41.443993],
							[23.960504, 41.443917],
							[23.959314, 41.443928],
							[23.957744, 41.444995],
							[23.957603, 41.445313],
							[23.957299, 41.44625],
							[23.957147, 41.446504],
							[23.957002, 41.446638],
							[23.956792, 41.446663],
							[23.95612, 41.446605],
							[23.955704, 41.446605],
							[23.955378, 41.446775],
							[23.954669, 41.447426],
							[23.953114, 41.448638],
							[23.952904, 41.448942],
							[23.95281, 41.449141],
							[23.952607, 41.449351],
							[23.952379, 41.449314],
							[23.951953, 41.449119],
							[23.951019, 41.448768],
							[23.950744, 41.448631],
							[23.950509, 41.448442],
							[23.949873, 41.448153],
							[23.949699, 41.448518],
							[23.949457, 41.448895],
							[23.949247, 41.449104],
							[23.948925, 41.449314],
							[23.948487, 41.449477],
							[23.947999, 41.449575],
							[23.947467, 41.44968],
							[23.947047, 41.449832],
							[23.946714, 41.449918],
							[23.945094, 41.4499],
							[23.943973, 41.450222],
							[23.943426, 41.450374],
							[23.943245, 41.45058],
							[23.94313, 41.450891],
							[23.943083, 41.451391],
							[23.942963, 41.451857],
							[23.94275, 41.452338],
							[23.942464, 41.45287],
							[23.941921, 41.453709],
							[23.941578, 41.454104],
							[23.941412, 41.454411],
							[23.941346, 41.454784],
							[23.941317, 41.4552],
							[23.941426, 41.455844],
							[23.941527, 41.456596],
							[23.94169, 41.456936],
							[23.942055, 41.457457],
							[23.941553, 41.458857],
							[23.941491, 41.459476],
							[23.941534, 41.460394],
							[23.941629, 41.460666],
							[23.941719, 41.461678],
							[23.940529, 41.462525],
							[23.939635, 41.463064],
							[23.939368, 41.46318],
							[23.939125, 41.46318],
							[23.93872, 41.463107],
							[23.937505, 41.462854],
							[23.937027, 41.462919],
							[23.936712, 41.462963],
							[23.936441, 41.463075],
							[23.936155, 41.463295],
							[23.935906, 41.463386],
							[23.934549, 41.463581],
							[23.934224, 41.463697],
							[23.932885, 41.464598],
							[23.932697, 41.464851],
							[23.932133, 41.4659],
							[23.931775, 41.466486],
							[23.931514, 41.467249],
							[23.931619, 41.468128],
							[23.932053, 41.469456],
							[23.932516, 41.470364],
							[23.932556, 41.470537],
							[23.932155, 41.470726],
							[23.931811, 41.471008],
							[23.931453, 41.471391],
							[23.930917, 41.471897],
							[23.930299, 41.47257],
							[23.929684, 41.473095],
							[23.928838, 41.473677],
							[23.927984, 41.474122],
							[23.92713, 41.47448],
							[23.92552, 41.47494],
							[23.924851, 41.47507],
							[23.92378, 41.475269],
							[23.923115, 41.475457],
							[23.922713, 41.475714],
							[23.922182, 41.476079],
							[23.92093, 41.477063],
							[23.920532, 41.477338],
							[23.919798, 41.477722],
							[23.918495, 41.478286],
							[23.918148, 41.478492],
							[23.917649, 41.478944],
							[23.917302, 41.479342],
							[23.916951, 41.479867],
							[23.916329, 41.480572],
							[23.916198, 41.480778],
							[23.916159, 41.480966],
							[23.916202, 41.481248],
							[23.916217, 41.481465],
							[23.916133, 41.481614],
							[23.915934, 41.481701],
							[23.915489, 41.481769],
							[23.914835, 41.481838],
							[23.914339, 41.481849],
							[23.914137, 41.48182],
							[23.913909, 41.481762],
							[23.91363, 41.481581],
							[23.913348, 41.481303],
							[23.913004, 41.480966],
							[23.912541, 41.48038],
							[23.912339, 41.480185],
							[23.912118, 41.480058],
							[23.911807, 41.479946],
							[23.910671, 41.479635],
							[23.909984, 41.479364],
							[23.909575, 41.479125],
							[23.909286, 41.478901],
							[23.90922, 41.478781],
							[23.909231, 41.478644],
							[23.909481, 41.478004],
							[23.909586, 41.477866],
							[23.909727, 41.47774],
							[23.90981, 41.477533],
							[23.909843, 41.477298],
							[23.909839, 41.477092],
							[23.909791, 41.476835],
							[23.909727, 41.476684],
							[23.909302, 41.475994],
							[23.9091, 41.475561],
							[23.908855, 41.47529],
							[23.908776, 41.475019],
							[23.908719, 41.47463],
							[23.908607, 41.474374],
							[23.908506, 41.474157],
							[23.908285, 41.473929],
							[23.908231, 41.473802],
							[23.908205, 41.473636],
							[23.908234, 41.473488],
							[23.908603, 41.472558],
							[23.908766, 41.472366],
							[23.909008, 41.472225],
							[23.909334, 41.472077],
							[23.909551, 41.472015],
							[23.909735, 41.472026],
							[23.910014, 41.472066],
							[23.910191, 41.472023],
							[23.910343, 41.471911],
							[23.910745, 41.471346],
							[23.910741, 41.470728],
							[23.910601, 41.47045],
							[23.91013, 41.469983],
							[23.909931, 41.469679],
							[23.909913, 41.469429],
							[23.909913, 41.469053],
							[23.909819, 41.46846],
							[23.909746, 41.467468],
							[23.909639, 41.46691],
							[23.909531, 41.466622],
							[23.90927, 41.466191],
							[23.909156, 41.466003],
							[23.908978, 41.465392],
							[23.908937, 41.464922],
							[23.908922, 41.464431],
							[23.908876, 41.464272],
							[23.908798, 41.464159],
							[23.908484, 41.463906],
							[23.908341, 41.463756],
							[23.908228, 41.463545],
							[23.908167, 41.463213],
							[23.90811, 41.462699],
							[23.90829, 41.462234],
							[23.908347, 41.461972],
							[23.908345, 41.461687],
							[23.90829, 41.461354],
							[23.90824, 41.461132],
							[23.908133, 41.460899],
							[23.907787, 41.460697],
							[23.907596, 41.460509],
							[23.907502, 41.460299],
							[23.907489, 41.460124],
							[23.907515, 41.459962],
							[23.907551, 41.459776],
							[23.907609, 41.459166],
							[23.907593, 41.45897],
							[23.907546, 41.458839],
							[23.90747, 41.458726],
							[23.906478, 41.458043],
							[23.906366, 41.457904],
							[23.906316, 41.457765],
							[23.906266, 41.457519],
							[23.906211, 41.457346],
							[23.90607, 41.457148],
							[23.905894, 41.457087],
							[23.905394, 41.45703],
							[23.904169, 41.456928],
							[23.900622, 41.456588],
							[23.900067, 41.456518],
							[23.899834, 41.456429],
							[23.898675, 41.455488],
							[23.898316, 41.455285],
							[23.897894, 41.45514],
							[23.897431, 41.455033],
							[23.897198, 41.454889],
							[23.897005, 41.454641],
							[23.896857, 41.454274],
							[23.89682, 41.453989],
							[23.896761, 41.453545],
							[23.896698, 41.45333],
							[23.896595, 41.453171],
							[23.896317, 41.452982],
							[23.895825, 41.45283],
							[23.895206, 41.452686],
							[23.894851, 41.452568],
							[23.894633, 41.452456],
							[23.894196, 41.452097],
							[23.89385, 41.451819],
							[23.893151, 41.451337],
							[23.892076, 41.450822],
							[23.891639, 41.450692],
							[23.891095, 41.450562],
							[23.890836, 41.450455],
							[23.890583, 41.450326],
							[23.890145, 41.450055],
							[23.890048, 41.450106],
							[23.889926, 41.450221],
							[23.889876, 41.450301],
							[23.889844, 41.450491],
							[23.889748, 41.450643],
							[23.889608, 41.450755],
							[23.889028, 41.451039],
							[23.888518, 41.451406],
							[23.888032, 41.451807],
							[23.887702, 41.452163],
							[23.887537, 41.45238],
							[23.887265, 41.452898],
							[23.886953, 41.4535],
							[23.886859, 41.453594],
							[23.886467, 41.453731],
							[23.885925, 41.453877],
							[23.88543, 41.45395],
							[23.884163, 41.453903],
							[23.883512, 41.453848],
							[23.882702, 41.453962],
							[23.882312, 41.453962],
							[23.881998, 41.453946],
							[23.881822, 41.453998],
							[23.881631, 41.454142],
							[23.881406, 41.45431],
							[23.881225, 41.454354],
							[23.881032, 41.454333],
							[23.880875, 41.454257],
							[23.880759, 41.454108],
							[23.880621, 41.453981],
							[23.880224, 41.453777],
							[23.87962, 41.453557],
							[23.879465, 41.453532],
							[23.87911, 41.453534],
							[23.87868, 41.45336],
							[23.878192, 41.453279],
							[23.876369, 41.453077],
							[23.876006, 41.452984],
							[23.875754, 41.452847],
							[23.875521, 41.452703],
							[23.875265, 41.452603],
							[23.875106, 41.452533],
							[23.875017, 41.452431],
							[23.874967, 41.452263],
							[23.874933, 41.452156],
							[23.874862, 41.452043],
							[23.874747, 41.451886],
							[23.874611, 41.451763],
							[23.874349, 41.451666],
							[23.874116, 41.451561],
							[23.873818, 41.451357],
							[23.873611, 41.451127],
							[23.873464, 41.450899],
							[23.873077, 41.450546],
							[23.872491, 41.450133],
							[23.87202, 41.449703],
							[23.871496, 41.449373],
							[23.871135, 41.44919],
							[23.870784, 41.449148],
							[23.870566, 41.449093],
							[23.86993, 41.448856],
							[23.868886, 41.448608],
							[23.868534, 41.448593],
							[23.868223, 41.448649],
							[23.867931, 41.448764],
							[23.867697, 41.448893],
							[23.867424, 41.449256],
							[23.867016, 41.449785],
							[23.866702, 41.450048],
							[23.866517, 41.450133],
							[23.866372, 41.450156],
							[23.866206, 41.450067],
							[23.865706, 41.449748],
							[23.865358, 41.449608],
							[23.864773, 41.449474],
							[23.863981, 41.449182],
							[23.863766, 41.449126],
							[23.863562, 41.449138],
							[23.8626, 41.449515],
							[23.862078, 41.449582],
							[23.861267, 41.449671],
							[23.860723, 41.449645],
							[23.860031, 41.449585],
							[23.85952, 41.44963],
							[23.858354, 41.449759],
							[23.856866, 41.449863],
							[23.856558, 41.44986],
							[23.85638, 41.449776],
							[23.856439, 41.449304],
							[23.856431, 41.448955],
							[23.856389, 41.448601],
							[23.856186, 41.448066],
							[23.856052, 41.447759],
							[23.855896, 41.447514],
							[23.855837, 41.447156],
							[23.855365, 41.446879],
							[23.85518, 41.44671],
							[23.855024, 41.44658],
							[23.854814, 41.446537],
							[23.85459, 41.446537],
							[23.854354, 41.446537],
							[23.854131, 41.446436],
							[23.853984, 41.446154],
							[23.853849, 41.445775],
							[23.853613, 41.445346],
							[23.853373, 41.445114],
							[23.853205, 41.445009],
							[23.852771, 41.444874],
							[23.852628, 41.444806],
							[23.852544, 41.444672],
							[23.852544, 41.444524],
							[23.852602, 41.444326],
							[23.852548, 41.444137],
							[23.852144, 41.443686],
							[23.851903, 41.443568],
							[23.851541, 41.443421],
							[23.851478, 41.44324],
							[23.851524, 41.442448],
							[23.851478, 41.4422],
							[23.851339, 41.441892],
							[23.851289, 41.441669],
							[23.851326, 41.441395],
							[23.85136, 41.441117],
							[23.851337, 41.440599],
							[23.851239, 41.439909],
							[23.85091, 41.439856],
							[23.850425, 41.439773],
							[23.849996, 41.439757],
							[23.849798, 41.43982],
							[23.849507, 41.439994],
							[23.849052, 41.44028],
							[23.848617, 41.44053],
							[23.848381, 41.44055],
							[23.847562, 41.440544],
							[23.847176, 41.44057],
							[23.846894, 41.440646],
							[23.846518, 41.440842],
							[23.846103, 41.441075],
							[23.845284, 41.441143],
							[23.845074, 41.441189],
							[23.844838, 41.441314],
							[23.844463, 41.441675],
							[23.844312, 41.441783],
							[23.844161, 41.441777],
							[23.843772, 41.441575],
							[23.843402, 41.44137],
							[23.843066, 41.441268],
							[23.842555, 41.441217],
							[23.841997, 41.441163],
							[23.840866, 41.440882],
							[23.840578, 41.440825],
							[23.839623, 41.440509],
							[23.839376, 41.440446],
							[23.839236, 41.440427],
							[23.838989, 41.440446],
							[23.838662, 41.440449],
							[23.838167, 41.440353],
							[23.837576, 41.440205],
							[23.837138, 41.440045],
							[23.836194, 41.439636],
							[23.835748, 41.439528],
							[23.835358, 41.439482],
							[23.834997, 41.439428],
							[23.834605, 41.439278],
							[23.833004, 41.438194],
							[23.832694, 41.43764],
							[23.832512, 41.437441],
							[23.831963, 41.43712],
							[23.831249, 41.437165],
							[23.830194, 41.437299],
							[23.829995, 41.437376],
							[23.829853, 41.437486],
							[23.829577, 41.437788],
							[23.829446, 41.43789],
							[23.829333, 41.437916],
							[23.82879, 41.437867],
							[23.828636, 41.437845],
							[23.828437, 41.437913],
							[23.828212, 41.438052],
							[23.827957, 41.43818],
							[23.827706, 41.438206],
							[23.827485, 41.438149],
							[23.827214, 41.437964],
							[23.826649, 41.437506],
							[23.826396, 41.437336],
							[23.82608, 41.437259],
							[23.825781, 41.437228],
							[23.825056, 41.437196],
							[23.823856, 41.436827],
							[23.823271, 41.436579],
							[23.822924, 41.436332],
							[23.822364, 41.436517],
							[23.821724, 41.436696],
							[23.820929, 41.436797],
							[23.820607, 41.436796],
							[23.820264, 41.43689],
							[23.819536, 41.437131],
							[23.818901, 41.437424],
							[23.818481, 41.437471],
							[23.817852, 41.437415],
							[23.816762, 41.437435],
							[23.815536, 41.436474],
							[23.813529, 41.436248],
							[23.813226, 41.436257],
							[23.812941, 41.436389],
							[23.811838, 41.436865],
							[23.81158, 41.437039],
							[23.811104, 41.437506],
							[23.810736, 41.437806],
							[23.809934, 41.438288],
							[23.809493, 41.438473],
							[23.809114, 41.438547],
							[23.808665, 41.438552],
							[23.807613, 41.438462],
							[23.807191, 41.438169],
							[23.806985, 41.438115],
							[23.806776, 41.4381],
							[23.806262, 41.438226],
							[23.805976, 41.438247],
							[23.805768, 41.438187],
							[23.805267, 41.437919],
							[23.804074, 41.437408],
							[23.803472, 41.4373],
							[23.802977, 41.43735],
							[23.80217, 41.437537],
							[23.801742, 41.437574],
							[23.801198, 41.437591],
							[23.800691, 41.437491],
							[23.798521, 41.435903],
							[23.798276, 41.435654],
							[23.798064, 41.43528],
							[23.79808, 41.434843],
							[23.797823, 41.434415],
							[23.797698, 41.434012],
							[23.796667, 41.432856],
							[23.79594, 41.432108],
							[23.795582, 41.431942],
							[23.794944, 41.431226],
							[23.794497, 41.430395],
							[23.79436, 41.429826],
							[23.794156, 41.429527],
							[23.793633, 41.429061],
							[23.793279, 41.428878],
							[23.792781, 41.428753],
							[23.792456, 41.428529],
							[23.792639, 41.428255],
							[23.792427, 41.427423],
							[23.792295, 41.427084],
							[23.790426, 41.425408],
							[23.789964, 41.424967],
							[23.789791, 41.424697],
							[23.789738, 41.424441],
							[23.789767, 41.424235],
							[23.790067, 41.423398],
							[23.790102, 41.423236],
							[23.790041, 41.423083],
							[23.789597, 41.422648],
							[23.789474, 41.422434],
							[23.789397, 41.422201],
							[23.789382, 41.421978],
							[23.789676, 41.420932],
							[23.789726, 41.420417],
							[23.789715, 41.420112],
							[23.789556, 41.419386],
							[23.789479, 41.418433],
							[23.78943, 41.418278],
							[23.788997, 41.417937],
							[23.788542, 41.417631],
							[23.788368, 41.417505],
							[23.788263, 41.417352],
							[23.788183, 41.417184],
							[23.78806, 41.416793],
							[23.787985, 41.416632],
							[23.787833, 41.416477],
							[23.787604, 41.416403],
							[23.786899, 41.416336],
							[23.786184, 41.416264],
							[23.785256, 41.416064],
							[23.785056, 41.416012],
							[23.784894, 41.415906],
							[23.784588, 41.4156],
							[23.78415, 41.415244],
							[23.783768, 41.415003],
							[23.782901, 41.414598],
							[23.781578, 41.413819],
							[23.781305, 41.413692],
							[23.780982, 41.41359],
							[23.780817, 41.413434],
							[23.780713, 41.413257],
							[23.780735, 41.412885],
							[23.780662, 41.412561],
							[23.780547, 41.411429],
							[23.779674, 41.410653],
							[23.778924, 41.409895],
							[23.778824, 41.409757],
							[23.778701, 41.409457],
							[23.778569, 41.409146],
							[23.778398, 41.40886],
							[23.77796, 41.408378],
							[23.776381, 41.406465],
							[23.776255, 41.406241],
							[23.776085, 41.405804],
							[23.77606, 41.405478],
							[23.776027, 41.405247],
							[23.775892, 41.404943],
							[23.775646, 41.404657],
							[23.775459, 41.404403],
							[23.775293, 41.40385],
							[23.775139, 41.40347],
							[23.775025, 41.403351],
							[23.774491, 41.403023],
							[23.773946, 41.402611],
							[23.773589, 41.402221],
							[23.773343, 41.401971],
							[23.77305, 41.401782],
							[23.772847, 41.401714],
							[23.772063, 41.40146],
							[23.771113, 41.40125],
							[23.770814, 41.399795],
							[23.770469, 41.399014],
							[23.770249, 41.398714],
							[23.769176, 41.397954],
							[23.768195, 41.397318],
							[23.767896, 41.396977],
							[23.767676, 41.396428],
							[23.767588, 41.396042],
							[23.767231, 41.396291],
							[23.766824, 41.396478],
							[23.766275, 41.396686],
							[23.766017, 41.396752],
							[23.76568, 41.396711],
							[23.765485, 41.396711],
							[23.765219, 41.396898],
							[23.764845, 41.397172],
							[23.764321, 41.397355],
							[23.763411, 41.3975],
							[23.762571, 41.397596],
							[23.762139, 41.397721],
							[23.761812, 41.397904],
							[23.761431, 41.398615],
							[23.761362, 41.398976],
							[23.761357, 41.399521],
							[23.76137, 41.400306],
							[23.761507, 41.400689],
							[23.761308, 41.401138],
							[23.76122, 41.401258],
							[23.760751, 41.401474],
							[23.760314, 41.401653],
							[23.759903, 41.401907],
							[23.758813, 41.402668],
							[23.758422, 41.402659],
							[23.758104, 41.402674],
							[23.757772, 41.402721],
							[23.75734, 41.402865],
							[23.756752, 41.403176],
							[23.755938, 41.403441],
							[23.755441, 41.403638],
							[23.754489, 41.403764],
							[23.753819, 41.403784],
							[23.75302, 41.403594],
							[23.752507, 41.403457],
							[23.752289, 41.403348],
							[23.751994, 41.403111],
							[23.751862, 41.403005],
							[23.751618, 41.402958],
							[23.751267, 41.402967],
							[23.750779, 41.403048],
							[23.750389, 41.403136],
							[23.749976, 41.403149],
							[23.749585, 41.403049],
							[23.748781, 41.402897],
							[23.747389, 41.403109],
							[23.746732, 41.403184],
							[23.745531, 41.40382],
							[23.744803, 41.404281],
							[23.744064, 41.405143],
							[23.743843, 41.40529],
							[23.743381, 41.405421],
							[23.742629, 41.405766],
							[23.742394, 41.405774],
							[23.741989, 41.405787],
							[23.741544, 41.405728],
							[23.740247, 41.405479],
							[23.739354, 41.405375],
							[23.736989, 41.405363],
							[23.736507, 41.405396],
							[23.735924, 41.405521],
							[23.734603, 41.406103],
							[23.733165, 41.406759],
							[23.732395, 41.407034],
							[23.731443, 41.407474],
							[23.730875, 41.407775],
							[23.730604, 41.407895],
							[23.730338, 41.40793],
							[23.729545, 41.407766],
							[23.727176, 41.406994],
							[23.726883, 41.406935],
							[23.726371, 41.406888],
							[23.723564, 41.406757],
							[23.722968, 41.406704],
							[23.722409, 41.406666],
							[23.721548, 41.406739],
							[23.71972, 41.406566],
							[23.718921, 41.406534],
							[23.718649, 41.406556],
							[23.717764, 41.406688],
							[23.71615, 41.406805],
							[23.714402, 41.406794],
							[23.713667, 41.406744],
							[23.713015, 41.406634],
							[23.712464, 41.406449],
							[23.712038, 41.406273],
							[23.710968, 41.406028],
							[23.71026, 41.405806],
							[23.708799, 41.405403],
							[23.708174, 41.405284],
							[23.707176, 41.405221],
							[23.706464, 41.40514],
							[23.705646, 41.405089],
							[23.70451, 41.405005],
							[23.704124, 41.404966],
							[23.703878, 41.404898],
							[23.703288, 41.404622],
							[23.703015, 41.404552],
							[23.702752, 41.404557],
							[23.702299, 41.404593],
							[23.701735, 41.404677],
							[23.701233, 41.404792],
							[23.70085, 41.40493],
							[23.700424, 41.404981],
							[23.699774, 41.405064],
							[23.698661, 41.405097],
							[23.698106, 41.405103],
							[23.697364, 41.4051],
							[23.697106, 41.405084],
							[23.696915, 41.405048],
							[23.696202, 41.404791],
							[23.695567, 41.404607],
							[23.695028, 41.404485],
							[23.694408, 41.404408],
							[23.693176, 41.404287],
							[23.692313, 41.404313],
							[23.691363, 41.404398],
							[23.690549, 41.404638],
							[23.689586, 41.405138],
							[23.689197, 41.405324],
							[23.688372, 41.405523],
							[23.687561, 41.405699],
							[23.686596, 41.405938],
							[23.685651, 41.406254],
							[23.684875, 41.406583],
							[23.684702, 41.406696],
							[23.683783, 41.407308],
							[23.683416, 41.407477],
							[23.680509, 41.407866],
							[23.679448, 41.408127],
							[23.678595, 41.40838],
							[23.677891, 41.408641],
							[23.677278, 41.408914],
							[23.67645, 41.409328],
							[23.676231, 41.409427],
							[23.675653, 41.409734],
							[23.675026, 41.410112],
							[23.674615, 41.410449],
							[23.674227, 41.4107],
							[23.673963, 41.410482],
							[23.673046, 41.409961],
							[23.672648, 41.409715],
							[23.672135, 41.409296],
							[23.67155, 41.408859],
							[23.671204, 41.408534],
							[23.67068, 41.408382],
							[23.669801, 41.408283],
							[23.669653, 41.408023],
							[23.669462, 41.407839],
							[23.669422, 41.407556],
							[23.668892, 41.407154],
							[23.667975, 41.406551],
							[23.667029, 41.405918],
							[23.666158, 41.40528],
							[23.665987, 41.405106],
							[23.665687, 41.404718],
							[23.665172, 41.404036],
							[23.664946, 41.403649],
							[23.664762, 41.403055],
							[23.664526, 41.402538],
							[23.664169, 41.401983],
							[23.663934, 41.401743],
							[23.663108, 41.401011],
							[23.662426, 41.400542],
							[23.662004, 41.400085],
							[23.661869, 41.399983],
							[23.661591, 41.399833],
							[23.660847, 41.399627],
							[23.660472, 41.399565],
							[23.659754, 41.399415],
							[23.659374, 41.399309],
							[23.659099, 41.399208],
							[23.658824, 41.399083],
							[23.658542, 41.39898],
							[23.658295, 41.398924],
							[23.658105, 41.398924],
							[23.657465, 41.398947],
							[23.656864, 41.398918],
							[23.656253, 41.398839],
							[23.655951, 41.398772],
							[23.655138, 41.398458],
							[23.654744, 41.398295],
							[23.654369, 41.398192],
							[23.653864, 41.398077],
							[23.65357, 41.398001],
							[23.653404, 41.397928],
							[23.652804, 41.397624],
							[23.652244, 41.397411],
							[23.651565, 41.397119],
							[23.651113, 41.396861],
							[23.651022, 41.396824],
							[23.650749, 41.39676],
							[23.650715, 41.39673],
							[23.650683, 41.396574],
							[23.650638, 41.396452],
							[23.649811, 41.395781],
							[23.648477, 41.39494],
							[23.648044, 41.39459],
							[23.647943, 41.394443],
							[23.64787, 41.394155],
							[23.647868, 41.393908],
							[23.648091, 41.393275],
							[23.648107, 41.39273],
							[23.648067, 41.392448],
							[23.647976, 41.391889],
							[23.647942, 41.391475],
							[23.648129, 41.390833],
							[23.648384, 41.390112],
							[23.648559, 41.389657],
							[23.648581, 41.3895],
							[23.648275, 41.389167],
							[23.648069, 41.388901],
							[23.647909, 41.388528],
							[23.647829, 41.388114],
							[23.647807, 41.387631],
							[23.647841, 41.387265],
							[23.648078, 41.386272],
							[23.648408, 41.385088],
							[23.648438, 41.384779],
							[23.647815, 41.383935],
							[23.647601, 41.381976],
							[23.646994, 41.381131],
							[23.646216, 41.380363],
							[23.645448, 41.379729],
							[23.645138, 41.379544],
							[23.644796, 41.379521],
							[23.644462, 41.37934],
							[23.643699, 41.3791],
							[23.642565, 41.378295],
							[23.641547, 41.377564],
							[23.6407, 41.376842],
							[23.640376, 41.376652],
							[23.638997, 41.376032],
							[23.638521, 41.375842],
							[23.637947, 41.375694],
							[23.637197, 41.375583],
							[23.636411, 41.375509],
							[23.635962, 41.375514],
							[23.635258, 41.375666],
							[23.634416, 41.375935],
							[23.63375, 41.376208],
							[23.633444, 41.376272],
							[23.633116, 41.376513],
							[23.632436, 41.376832],
							[23.63219, 41.376967],
							[23.631814, 41.377251],
							[23.631432, 41.377409],
							[23.631096, 41.377543],
							[23.630874, 41.377548],
							[23.630641, 41.377541],
							[23.630249, 41.377458],
							[23.629445, 41.377307],
							[23.628162, 41.377139],
							[23.627361, 41.377004],
							[23.626948, 41.376893],
							[23.626422, 41.37672],
							[23.625869, 41.376487],
							[23.625502, 41.376291],
							[23.6251, 41.376078],
							[23.624737, 41.375849],
							[23.62445, 41.375661],
							[23.623516, 41.376167],
							[23.623058, 41.376324],
							[23.622256, 41.376661],
							[23.621674, 41.376949],
							[23.621488, 41.376999],
							[23.62132, 41.377021],
							[23.620791, 41.377041],
							[23.620104, 41.376858],
							[23.619294, 41.376696],
							[23.618817, 41.376603],
							[23.61822, 41.376441],
							[23.616268, 41.375655],
							[23.615884, 41.37549],
							[23.615638, 41.375372],
							[23.615451, 41.37537],
							[23.615143, 41.375446],
							[23.614787, 41.375641],
							[23.614489, 41.375849],
							[23.614141, 41.376004],
							[23.613344, 41.376216],
							[23.612569, 41.376441],
							[23.612209, 41.376614],
							[23.611855, 41.376768],
							[23.611417, 41.37686],
							[23.610602, 41.377],
							[23.610216, 41.377104],
							[23.610022, 41.377222],
							[23.609571, 41.377557],
							[23.609069, 41.377821],
							[23.608465, 41.3783],
							[23.607886, 41.37867],
							[23.607598, 41.378953],
							[23.607313, 41.379178],
							[23.606346, 41.379956],
							[23.605945, 41.379893],
							[23.605295, 41.379749],
							[23.603508, 41.379334],
							[23.601694, 41.378969],
							[23.600956, 41.378571],
							[23.600572, 41.378297],
							[23.600274, 41.37815],
							[23.599331, 41.377927],
							[23.598725, 41.377714],
							[23.598384, 41.377631],
							[23.598016, 41.377564],
							[23.597826, 41.377569],
							[23.597303, 41.377738],
							[23.597051, 41.377869],
							[23.596882, 41.378034],
							[23.596785, 41.378213],
							[23.59615, 41.379081],
							[23.596024, 41.379517],
							[23.595888, 41.380011],
							[23.595297, 41.381005],
							[23.594422, 41.382193],
							[23.593007, 41.382988],
							[23.592349, 41.383408],
							[23.591679, 41.383765],
							[23.591128, 41.384246],
							[23.589673, 41.385049],
							[23.588919, 41.385693],
							[23.588359, 41.38633],
							[23.58733, 41.386869],
							[23.586817, 41.387303],
							[23.586153, 41.387505],
							[23.58537, 41.38772],
							[23.584878, 41.387953],
							[23.584265, 41.388312],
							[23.58358, 41.389006],
							[23.583436, 41.389105],
							[23.582912, 41.389249],
							[23.581577, 41.389744],
							[23.580453, 41.389907],
							[23.579387, 41.390021],
							[23.578119, 41.390557],
							[23.577897, 41.390619],
							[23.576835, 41.390377],
							[23.576072, 41.390224],
							[23.575552, 41.390063],
							[23.574591, 41.389964],
							[23.573871, 41.39],
							[23.573477, 41.389954],
							[23.572993, 41.389787],
							[23.572048, 41.389424],
							[23.57117, 41.389211],
							[23.569882, 41.389034],
							[23.569123, 41.389044],
							[23.568177, 41.389123],
							[23.567178, 41.38907],
							[23.566363, 41.388995],
							[23.565535, 41.388942],
							[23.563969, 41.388804],
							[23.562219, 41.388576],
							[23.561505, 41.388468],
							[23.561152, 41.388337],
							[23.557924, 41.387546],
							[23.557475, 41.387524],
							[23.556735, 41.387374],
							[23.555471, 41.387347],
							[23.55472, 41.387242],
							[23.55436, 41.387093],
							[23.5537, 41.386959],
							[23.552426, 41.386502],
							[23.551953, 41.38639],
							[23.551538, 41.38639],
							[23.551321, 41.386435],
							[23.550759, 41.386503],
							[23.550363, 41.386564],
							[23.549726, 41.386809],
							[23.549375, 41.387119],
							[23.549055, 41.387376],
							[23.548346, 41.387903],
							[23.548016, 41.388074],
							[23.547466, 41.388282],
							[23.547043, 41.388599],
							[23.546545, 41.389156],
							[23.546349, 41.389307],
							[23.545851, 41.389534],
							[23.545441, 41.389774],
							[23.545015, 41.390026],
							[23.5448, 41.390317],
							[23.544669, 41.390591],
							[23.544571, 41.390858],
							[23.54424, 41.391162],
							[23.544047, 41.391312],
							[23.543595, 41.391772],
							[23.543367, 41.392162],
							[23.543202, 41.392319],
							[23.542904, 41.392451],
							[23.542454, 41.392668],
							[23.541826, 41.393038],
							[23.541273, 41.393221],
							[23.540804, 41.393422],
							[23.539845, 41.393923],
							[23.539322, 41.393989],
							[23.538166, 41.394021],
							[23.537418, 41.393901],
							[23.537083, 41.394058],
							[23.536377, 41.394915],
							[23.535964, 41.39594],
							[23.535627, 41.396383],
							[23.535359, 41.396636],
							[23.535056, 41.396838],
							[23.534906, 41.396983],
							[23.534766, 41.397166],
							[23.534601, 41.397495],
							[23.534285, 41.397907],
							[23.534239, 41.398029],
							[23.534794, 41.39911],
							[23.534949, 41.399438],
							[23.535069, 41.400079],
							[23.535125, 41.400563],
							[23.535074, 41.400776],
							[23.535005, 41.400903],
							[23.534799, 41.401204],
							[23.534389, 41.401822],
							[23.53415, 41.402094],
							[23.53399, 41.402237],
							[23.533802, 41.402397],
							[23.533496, 41.402493],
							[23.533123, 41.402567],
							[23.532711, 41.402621],
							[23.532315, 41.402675],
							[23.531903, 41.402752],
							[23.53128, 41.402944],
							[23.530021, 41.403412],
							[23.529407, 41.403702],
							[23.529107, 41.403789],
							[23.528871, 41.403862],
							[23.527863, 41.404294],
							[23.527622, 41.404348],
							[23.527148, 41.404431],
							[23.526872, 41.404531],
							[23.526728, 41.404589],
							[23.526475, 41.404756],
							[23.526272, 41.404874],
							[23.525845, 41.405047],
							[23.52553, 41.405149],
							[23.525161, 41.405218],
							[23.52499, 41.405204],
							[23.524772, 41.405094],
							[23.524551, 41.404997],
							[23.52422, 41.404885],
							[23.524014, 41.404877],
							[23.52366, 41.4049],
							[23.52334, 41.404961],
							[23.522948, 41.405049],
							[23.522852, 41.40506],
							[23.52239, 41.404972],
							[23.521798, 41.404877],
							[23.521482, 41.404855],
							[23.520943, 41.40474],
							[23.519537, 41.404514],
							[23.518932, 41.40451],
							[23.518565, 41.404557],
							[23.518337, 41.404617],
							[23.51678, 41.404635],
							[23.516273, 41.404415],
							[23.515964, 41.404235],
							[23.515335, 41.403826],
							[23.514922, 41.403655],
							[23.51426, 41.403281],
							[23.513287, 41.402826],
							[23.512168, 41.402287],
							[23.51109, 41.401824],
							[23.51026, 41.401497],
							[23.509953, 41.401386],
							[23.509578, 41.401365],
							[23.509135, 41.401411],
							[23.507974, 41.401081],
							[23.507607, 41.400928],
							[23.50722, 41.400747],
							[23.506888, 41.400625],
							[23.506485, 41.400658],
							[23.505725, 41.400899],
							[23.504495, 41.40062],
							[23.503998, 41.400608],
							[23.502199, 41.40092],
							[23.501347, 41.401236],
							[23.500887, 41.401324],
							[23.500383, 41.401337],
							[23.499638, 41.401507],
							[23.49882, 41.401579],
							[23.498045, 41.401509],
							[23.497573, 41.401412],
							[23.494758, 41.400945],
							[23.493925, 41.400818],
							[23.492411, 41.40069],
							[23.491924, 41.400514],
							[23.491413, 41.400286],
							[23.490088, 41.399941],
							[23.488705, 41.399579],
							[23.486704, 41.399131],
							[23.485626, 41.399069],
							[23.484221, 41.399096],
							[23.483366, 41.399172],
							[23.482421, 41.399234],
							[23.481213, 41.399536],
							[23.480099, 41.399654],
							[23.478311, 41.399999],
							[23.475645, 41.400444],
							[23.474019, 41.400645],
							[23.472366, 41.400962],
							[23.470935, 41.401279],
							[23.46929, 41.401656],
							[23.467143, 41.402189],
							[23.465112, 41.402519],
							[23.463634, 41.402719],
							[23.462579, 41.402726],
							[23.460703, 41.402745],
							[23.459844, 41.40283],
							[23.458545, 41.402794],
							[23.457521, 41.403025],
							[23.457144, 41.403038],
							[23.456329, 41.403118],
							[23.453773, 41.403536],
							[23.452282, 41.403846],
							[23.449792, 41.404304],
							[23.448688, 41.404605],
							[23.447795, 41.40473],
							[23.446788, 41.404807],
							[23.445883, 41.404972],
							[23.445069, 41.405219],
							[23.444863, 41.405279],
							[23.442583, 41.405357],
							[23.441317, 41.405259],
							[23.441173, 41.405295],
							[23.439676, 41.406008],
							[23.438669, 41.406587],
							[23.438432, 41.406573],
							[23.436721, 41.406253],
							[23.435792, 41.405895],
							[23.435431, 41.405686],
							[23.434828, 41.404905],
							[23.434129, 41.404604],
							[23.433134, 41.404185],
							[23.432461, 41.40397],
							[23.431513, 41.40358],
							[23.430672, 41.403135],
							[23.430228, 41.402802],
							[23.429986, 41.402674],
							[23.427708, 41.401995],
							[23.426351, 41.40156],
							[23.426079, 41.401319],
							[23.425617, 41.401036],
							[23.424506, 41.400122],
							[23.424345, 41.400031],
							[23.423493, 41.399727],
							[23.422504, 41.399374],
							[23.421493, 41.398883],
							[23.421047, 41.398667],
							[23.419768, 41.39839],
							[23.419082, 41.398303],
							[23.418613, 41.39832],
							[23.41747, 41.39889],
							[23.41603, 41.399502],
							[23.415075, 41.399943],
							[23.414783, 41.400209],
							[23.4144, 41.400716],
							[23.413504, 41.401154],
							[23.41265, 41.401223],
							[23.411444, 41.401297],
							[23.410896, 41.401333],
							[23.410361, 41.40146],
							[23.409926, 41.401467],
							[23.40907, 41.401308],
							[23.408243, 41.401183],
							[23.407705, 41.401159],
							[23.407229, 41.401246],
							[23.406904, 41.401308],
							[23.40673, 41.401322],
							[23.40622, 41.401323],
							[23.405756, 41.401281],
							[23.405576, 41.401301],
							[23.405339, 41.401392],
							[23.405009, 41.401527],
							[23.404831, 41.401539],
							[23.404624, 41.401491],
							[23.404448, 41.40144],
							[23.404155, 41.40132],
							[23.403987, 41.401305],
							[23.403694, 41.401332],
							[23.403442, 41.401397],
							[23.403293, 41.401394],
							[23.403089, 41.401349],
							[23.402827, 41.401305],
							[23.402588, 41.401273],
							[23.402252, 41.401144],
							[23.402006, 41.401056],
							[23.401792, 41.400912],
							[23.401307, 41.400652],
							[23.400846, 41.400276],
							[23.400011, 41.399407],
							[23.399342, 41.398551],
							[23.398982, 41.397924],
							[23.398828, 41.397348],
							[23.398042, 41.396735],
							[23.397508, 41.396284],
							[23.397359, 41.396058],
							[23.397457, 41.395857],
							[23.397667, 41.395675],
							[23.397733, 41.395409],
							[23.397656, 41.39512],
							[23.397301, 41.394922],
							[23.39683, 41.394734],
							[23.396378, 41.394414],
							[23.396178, 41.393998],
							[23.396072, 41.393644],
							[23.395882, 41.393227],
							[23.395568, 41.39274],
							[23.394734, 41.39259],
							[23.394088, 41.392642],
							[23.393526, 41.392706],
							[23.393142, 41.392739],
							[23.392827, 41.392788],
							[23.392682, 41.392806],
							[23.392553, 41.392803],
							[23.392267, 41.392799],
							[23.391775, 41.39281],
							[23.391421, 41.392733],
							[23.390892, 41.392423],
							[23.389751, 41.391823],
							[23.388988, 41.391404],
							[23.388649, 41.391115],
							[23.388096, 41.390727],
							[23.387512, 41.390358],
							[23.38701, 41.389955],
							[23.386662, 41.38962],
							[23.386257, 41.38934],
							[23.385775, 41.389194],
							[23.385004, 41.389109],
							[23.384639, 41.389083],
							[23.384382, 41.389003],
							[23.384128, 41.388934],
							[23.38398, 41.38875],
							[23.383744, 41.388315],
							[23.383739, 41.388119],
							[23.383688, 41.387612],
							[23.383489, 41.387111],
							[23.383338, 41.386828],
							[23.383214, 41.386512],
							[23.38307, 41.386357],
							[23.382916, 41.386278],
							[23.382478, 41.386195],
							[23.382151, 41.38612],
							[23.381988, 41.386052],
							[23.381726, 41.386105],
							[23.381474, 41.38612],
							[23.38091, 41.386048],
							[23.380661, 41.385933],
							[23.380235, 41.385682],
							[23.379923, 41.38553],
							[23.379533, 41.385359],
							[23.379246, 41.385101],
							[23.379177, 41.384925],
							[23.379131, 41.384709],
							[23.379089, 41.384179],
							[23.379158, 41.383955],
							[23.379276, 41.383821],
							[23.379568, 41.38366],
							[23.379819, 41.383529],
							[23.379901, 41.383298],
							[23.379676, 41.382987],
							[23.379168, 41.382895],
							[23.378764, 41.382971],
							[23.378395, 41.383041],
							[23.378036, 41.382956],
							[23.377744, 41.382768],
							[23.37756, 41.382645],
							[23.377399, 41.382465],
							[23.377247, 41.382334],
							[23.377132, 41.3821],
							[23.377057, 41.381876],
							[23.377033, 41.38166],
							[23.376959, 41.381548],
							[23.376767, 41.381487],
							[23.376276, 41.381433],
							[23.375757, 41.381356],
							[23.374781, 41.381252],
							[23.374368, 41.381236],
							[23.373807, 41.381134],
							[23.37349, 41.381073],
							[23.373072, 41.38098],
							[23.372835, 41.380908],
							[23.372643, 41.3809],
							[23.37231, 41.380937],
							[23.371963, 41.380915],
							[23.371205, 41.380938],
							[23.370487, 41.380953],
							[23.36998, 41.380946],
							[23.369642, 41.380915],
							[23.36937, 41.380846],
							[23.369058, 41.380801],
							[23.368826, 41.38074],
							[23.368616, 41.380727],
							[23.368216, 41.380761],
							[23.367872, 41.380778],
							[23.367563, 41.38074],
							[23.36731, 41.380623],
							[23.366851, 41.380399],
							[23.366552, 41.380154],
							[23.366193, 41.379962],
							[23.365569, 41.37965],
							[23.36542, 41.379591],
							[23.365071, 41.379501],
							[23.364857, 41.379429],
							[23.364603, 41.379333],
							[23.364293, 41.379225],
							[23.363834, 41.379115],
							[23.3632, 41.379042],
							[23.36306, 41.379032],
							[23.362771, 41.378993],
							[23.362375, 41.37892],
							[23.362085, 41.378826],
							[23.361941, 41.378629],
							[23.361725, 41.37812],
							[23.361581, 41.377811],
							[23.361381, 41.377539],
							[23.361019, 41.37728],
							[23.360581, 41.377091],
							[23.360278, 41.37696],
							[23.36008, 41.376843],
							[23.359907, 41.376619],
							[23.359531, 41.376441],
							[23.359051, 41.376267],
							[23.358716, 41.375963],
							[23.358038, 41.375233],
							[23.357695, 41.374948],
							[23.357476, 41.374716],
							[23.357127, 41.374543],
							[23.356986, 41.374377],
							[23.356994, 41.373871],
							[23.356924, 41.373125],
							[23.356919, 41.372631],
							[23.356871, 41.372285],
							[23.35673, 41.372106],
							[23.356223, 41.371808],
							[23.355943, 41.371629],
							[23.355567, 41.371251],
							[23.355189, 41.371077],
							[23.354192, 41.37043],
							[23.35348, 41.36999],
							[23.353289, 41.369801],
							[23.353134, 41.369537],
							[23.353062, 41.369145],
							[23.35315, 41.368964],
							[23.353286, 41.368801],
							[23.353315, 41.36863],
							[23.353275, 41.368476],
							[23.35311, 41.368321],
							[23.352689, 41.368135],
							[23.351804, 41.367895],
							[23.351247, 41.367793],
							[23.350905, 41.367679],
							[23.350729, 41.367553],
							[23.350498, 41.367319],
							[23.350364, 41.367194],
							[23.350207, 41.367092],
							[23.35005, 41.366898],
							[23.349794, 41.36661],
							[23.349485, 41.366365],
							[23.349215, 41.366181],
							[23.34892, 41.366023],
							[23.348589, 41.365919],
							[23.348061, 41.365824],
							[23.347536, 41.365781],
							[23.346846, 41.365709],
							[23.346547, 41.365632],
							[23.346371, 41.365549],
							[23.346251, 41.36548],
							[23.346073, 41.36533],
							[23.345854, 41.365176],
							[23.345636, 41.365117],
							[23.345158, 41.365032],
							[23.34494, 41.364955],
							[23.344748, 41.364803],
							[23.344588, 41.364597],
							[23.344463, 41.364451],
							[23.344321, 41.364376],
							[23.34406, 41.364429],
							[23.343831, 41.364597],
							[23.34358, 41.364741],
							[23.34326, 41.364832],
							[23.342979, 41.364948],
							[23.342796, 41.365004],
							[23.342605, 41.36493],
							[23.342303, 41.364772],
							[23.341783, 41.364447],
							[23.341339, 41.36414],
							[23.340493, 41.363521],
							[23.338856, 41.367636],
							[23.338535, 41.368383],
							[23.337929, 41.36956],
							[23.334933, 41.37535],
							[23.333392, 41.378292],
							[23.331908, 41.381169],
							[23.33136, 41.382298],
							[23.331043, 41.383233],
							[23.330217, 41.384689],
							[23.32945, 41.385806],
							[23.329003, 41.386634],
							[23.327621, 41.389649],
							[23.326994, 41.390777],
							[23.326398, 41.391845],
							[23.326325, 41.392235],
							[23.326138, 41.392967],
							[23.325911, 41.393375],
							[23.325142, 41.394649],
							[23.324324, 41.396003],
							[23.323442, 41.397742],
							[23.322696, 41.39911],
							[23.322515, 41.399382],
							[23.322395, 41.399522],
							[23.322218, 41.399613],
							[23.321748, 41.399753],
							[23.321138, 41.399836],
							[23.320124, 41.399852],
							[23.319519, 41.399823],
							[23.31878, 41.399778],
							[23.317961, 41.399683],
							[23.317705, 41.399592],
							[23.317351, 41.399337],
							[23.317042, 41.39916],
							[23.316115, 41.398916],
							[23.31569, 41.398871],
							[23.314656, 41.398793],
							[23.313852, 41.398628],
							[23.313057, 41.398513],
							[23.312422, 41.398471],
							[23.31206, 41.398418],
							[23.311816, 41.39841],
							[23.311569, 41.398451],
							[23.311215, 41.398579],
							[23.310741, 41.398826],
							[23.310345, 41.399081],
							[23.309805, 41.399213],
							[23.309702, 41.399283],
							[23.309711, 41.399675],
							[23.309175, 41.400075],
							[23.30873, 41.400351],
							[23.308075, 41.400709],
							[23.307667, 41.400956],
							[23.307209, 41.401303],
							[23.306896, 41.401447],
							[23.306484, 41.401521],
							[23.306073, 41.401439],
							[23.304959, 41.401224],
							[23.30451, 41.401105],
							[23.304357, 41.400915],
							[23.30418, 41.400767],
							[23.303488, 41.400754],
							[23.30315, 41.400796],
							[23.302874, 41.400697],
							[23.302717, 41.400697],
							[23.30254, 41.400928],
							[23.302433, 41.401245],
							[23.302272, 41.40164],
							[23.30214, 41.401793],
							[23.301901, 41.401863],
							[23.301456, 41.401884],
							[23.300912, 41.401822],
							[23.300014, 41.401216],
							[23.299878, 41.401187],
							[23.2994, 41.401196],
							[23.298782, 41.40129],
							[23.298431, 41.401443],
							[23.298077, 41.40155],
							[23.297776, 41.401505],
							[23.297451, 41.401277],
							[23.297331, 41.400767],
							[23.297137, 41.400136],
							[23.296969, 41.399963],
							[23.295456, 41.400186],
							[23.295048, 41.400297],
							[23.294084, 41.400631],
							[23.292345, 41.400693],
							[23.292023, 41.400688],
							[23.291924, 41.400668],
							[23.291286, 41.400388],
							[23.290783, 41.400239],
							[23.289394, 41.400462],
							[23.28899, 41.400503],
							[23.288288, 41.400227],
							[23.28791, 41.400025],
							[23.287049, 41.399679],
							[23.286563, 41.399518],
							[23.28578, 41.399308],
							[23.285588, 41.399296],
							[23.28491, 41.399934],
							[23.284243, 41.40019],
							[23.283778, 41.400314],
							[23.283377, 41.400301],
							[23.282829, 41.400252],
							[23.282343, 41.4],
							[23.281725, 41.39979],
							[23.280427, 41.398224],
							[23.280324, 41.397783],
							[23.280232, 41.397443],
							[23.278361, 41.398098],
							[23.278069, 41.397851],
							[23.277595, 41.39647],
							[23.277521, 41.396301],
							[23.277224, 41.396001],
							[23.276787, 41.39563],
							[23.27605, 41.395078],
							[23.275887, 41.395122],
							[23.274687, 41.395505],
							[23.274148, 41.395617],
							[23.273966, 41.395621],
							[23.273525, 41.395122],
							[23.273039, 41.394046],
							[23.272697, 41.393461],
							[23.27266, 41.393387],
							[23.271568, 41.392736],
							[23.270591, 41.392443],
							[23.270294, 41.391673],
							[23.270138, 41.391434],
							[23.269998, 41.391306],
							[23.269783, 41.391277],
							[23.269466, 41.391233],
							[23.269309, 41.391174],
							[23.268161, 41.390665],
							[23.267039, 41.390296],
							[23.266791, 41.390258],
							[23.265465, 41.390062],
							[23.26478, 41.389771],
							[23.264484, 41.389619],
							[23.264395, 41.389516],
							[23.263993, 41.386294],
							[23.263902, 41.386211],
							[23.263375, 41.386125],
							[23.262244, 41.386043],
							[23.260111, 41.385018],
							[23.25824, 41.384391],
							[23.258263, 41.383577],
							[23.258374, 41.383066],
							[23.258527, 41.382571],
							[23.257641, 41.382435],
							[23.257163, 41.382283],
							[23.256446, 41.382143],
							[23.256062, 41.382163],
							[23.254887, 41.382459],
							[23.254039, 41.382605],
							[23.253841, 41.382599],
							[23.253127, 41.382517],
							[23.252774, 41.382313],
							[23.252232, 41.381789],
							[23.25209, 41.381684],
							[23.251891, 41.381707],
							[23.25144, 41.381786],
							[23.250959, 41.381841],
							[23.25076, 41.381818],
							[23.249916, 41.381401],
							[23.249729, 41.381328],
							[23.249557, 41.381308],
							[23.248266, 41.381288],
							[23.248016, 41.381261],
							[23.247287, 41.381081],
							[23.246975, 41.381008],
							[23.246733, 41.380993],
							[23.244868, 41.380967],
							[23.244624, 41.380935],
							[23.244364, 41.380877],
							[23.244073, 41.38076],
							[23.243682, 41.380544],
							[23.24351, 41.380413],
							[23.24342, 41.380247],
							[23.243339, 41.38],
							[23.243263, 41.379656],
							[23.243181, 41.379472],
							[23.242985, 41.379303],
							[23.242676, 41.379091],
							[23.242517, 41.378923],
							[23.242461, 41.378781],
							[23.242451, 41.37827],
							[23.242368, 41.377949],
							[23.242247, 41.377699],
							[23.242123, 41.377535],
							[23.241951, 41.377409],
							[23.241835, 41.377369],
							[23.240987, 41.377261],
							[23.240013, 41.376949],
							[23.239679, 41.376885],
							[23.239375, 41.376821],
							[23.239167, 41.376796],
							[23.237485, 41.376894],
							[23.236846, 41.376878],
							[23.236376, 41.376847],
							[23.235873, 41.376712],
							[23.23499, 41.376365],
							[23.234755, 41.376294],
							[23.234637, 41.376294],
							[23.234031, 41.376355],
							[23.233777, 41.376341],
							[23.233406, 41.376271],
							[23.232795, 41.376111],
							[23.232447, 41.375979],
							[23.232269, 41.375843],
							[23.232043, 41.375524],
							[23.231768, 41.375081],
							[23.231677, 41.374908],
							[23.231879, 41.374208],
							[23.231888, 41.37386],
							[23.231883, 41.372916],
							[23.231813, 41.371976],
							[23.231747, 41.371435],
							[23.231686, 41.371182],
							[23.231534, 41.370972],
							[23.231268, 41.370716],
							[23.22967, 41.369847],
							[23.229341, 41.369654],
							[23.228881, 41.369222],
							[23.228618, 41.368997],
							[23.228307, 41.368837],
							[23.227118, 41.368301],
							[23.226611, 41.368136],
							[23.226348, 41.36801],
							[23.226188, 41.367873],
							[23.226131, 41.367716],
							[23.226141, 41.367201],
							[23.226108, 41.366797],
							[23.226014, 41.366205],
							[23.226019, 41.365768],
							[23.226038, 41.365496],
							[23.226118, 41.365265],
							[23.226447, 41.364772],
							[23.22662, 41.364466],
							[23.227029, 41.363419],
							[23.227454, 41.362141],
							[23.227781, 41.361464],
							[23.228483, 41.359986],
							[23.228511, 41.359865],
							[23.228527, 41.359312],
							[23.228502, 41.358605],
							[23.228429, 41.358141],
							[23.228429, 41.357971],
							[23.228479, 41.357801],
							[23.228677, 41.357521],
							[23.228932, 41.357241],
							[23.229442, 41.356815],
							[23.229615, 41.356667],
							[23.229722, 41.356508],
							[23.22974, 41.356389],
							[23.22965, 41.356096],
							[23.229059, 41.354576],
							[23.229027, 41.354316],
							[23.229085, 41.354082],
							[23.229254, 41.353758],
							[23.229468, 41.353466],
							[23.229936, 41.353043],
							[23.230384, 41.352634],
							[23.230754, 41.352108],
							[23.231209, 41.351381],
							[23.231277, 41.351154],
							[23.23121, 41.350697],
							[23.231383, 41.350207],
							[23.231324, 41.349788],
							[23.230975, 41.348608],
							[23.230835, 41.348196],
							[23.23034, 41.347491],
							[23.229477, 41.346317],
							[23.229281, 41.345905],
							[23.229141, 41.345626],
							[23.228152, 41.344535],
							[23.226788, 41.342556],
							[23.226326, 41.342157],
							[23.225783, 41.341605],
							[23.225411, 41.341147],
							[23.225256, 41.340826],
							[23.225388, 41.340425],
							[23.225509, 41.339864],
							[23.225474, 41.338335],
							[23.225336, 41.337905],
							[23.225008, 41.337212],
							[23.222142, 41.334958],
							[23.221742, 41.334644],
							[23.22145, 41.334479],
							[23.221155, 41.334238],
							[23.220968, 41.333962],
							[23.220812, 41.333622],
							[23.220603, 41.333019],
							[23.220533, 41.332921],
							[23.220403, 41.332807],
							[23.218944, 41.332077],
							[23.217055, 41.331367],
							[23.216666, 41.331189],
							[23.216494, 41.331006],
							[23.216475, 41.330983],
							[23.21624, 41.330663],
							[23.216001, 41.330415],
							[23.215691, 41.330264],
							[23.213146, 41.329451],
							[23.211389, 41.32895],
							[23.210811, 41.32876],
							[23.210209, 41.328404],
							[23.209568, 41.328049],
							[23.208705, 41.327751],
							[23.207861, 41.327567],
							[23.207461, 41.327446],
							[23.207131, 41.32725],
							[23.206477, 41.326634],
							[23.206243, 41.326279],
							[23.205923, 41.32593],
							[23.205564, 41.325632],
							[23.205051, 41.325308],
							[23.204505, 41.325116],
							[23.203365, 41.324886],
							[23.202472, 41.324682],
							[23.201703, 41.324459],
							[23.2013, 41.324316],
							[23.200835, 41.323956],
							[23.200438, 41.323603],
							[23.199998, 41.322952],
							[23.199781, 41.322753],
							[23.199502, 41.322598],
							[23.199136, 41.322543],
							[23.198594, 41.322562],
							[23.195599, 41.32271],
							[23.194818, 41.322698],
							[23.192457, 41.322195],
							[23.19175, 41.322169],
							[23.191348, 41.322191],
							[23.190662, 41.322367],
							[23.189925, 41.322512],
							[23.188885, 41.322688],
							[23.187912, 41.322247],
							[23.187257, 41.322002],
							[23.187121, 41.321945],
							[23.186725, 41.321784],
							[23.185168, 41.321336],
							[23.184782, 41.321171],
							[23.183855, 41.320693],
							[23.183125, 41.320193],
							[23.181983, 41.319302],
							[23.181429, 41.318951],
							[23.18105, 41.318815],
							[23.180384, 41.31869],
							[23.18015, 41.31869],
							[23.180033, 41.318734],
							[23.179472, 41.319075],
							[23.178289, 41.319693],
							[23.176705, 41.320385],
							[23.174863, 41.321154],
							[23.174644, 41.32117],
							[23.172904, 41.321014],
							[23.171946, 41.320996],
							[23.169862, 41.320972],
							[23.16902, 41.32059],
							[23.168444, 41.321045],
							[23.168069, 41.321208],
							[23.167094, 41.321481],
							[23.166142, 41.321681],
							[23.16367, 41.321996],
							[23.162261, 41.322397],
							[23.16108, 41.322233],
							[23.159873, 41.321801],
							[23.158945, 41.321472],
							[23.158446, 41.321486],
							[23.157283, 41.321506],
							[23.15509, 41.32099],
							[23.153248, 41.320741],
							[23.152725, 41.320741],
							[23.151457, 41.320613],
							[23.148792, 41.320655],
							[23.148372, 41.320407],
							[23.14797, 41.320236],
							[23.147, 41.319909],
							[23.146265, 41.31991],
							[23.145776, 41.319987],
							[23.145279, 41.320124],
							[23.144097, 41.320544],
							[23.143317, 41.320587],
							[23.142006, 41.320587],
							[23.140296, 41.320493],
							[23.138939, 41.320462],
							[23.138055, 41.32036],
							[23.136704, 41.320172],
							[23.135541, 41.319839],
							[23.134462, 41.320475],
							[23.131815, 41.320747],
							[23.129119, 41.320305],
							[23.128627, 41.320275],
							[23.127228, 41.320256],
							[23.126077, 41.320075],
							[23.125883, 41.320063],
							[23.12475, 41.320293],
							[23.123829, 41.32065],
							[23.123242, 41.320796],
							[23.121751, 41.32059],
							[23.12051, 41.320245],
							[23.118467, 41.320356],
							[23.116492, 41.320441],
							[23.116166, 41.320223],
							[23.115562, 41.319782],
							[23.1145, 41.31913],
							[23.113909, 41.318818],
							[23.112246, 41.318124],
							[23.111544, 41.317897],
							[23.110269, 41.317558],
							[23.109857, 41.317463],
							[23.106653, 41.316793],
							[23.105885, 41.316651],
							[23.105442, 41.316622],
							[23.105125, 41.316647],
							[23.104657, 41.31673],
							[23.104472, 41.316781],
							[23.103148, 41.31723],
							[23.102214, 41.317457],
							[23.101434, 41.317337],
							[23.100753, 41.317273],
							[23.098517, 41.31729],
							[23.09685, 41.317251],
							[23.095025, 41.317286],
							[23.093656, 41.318339],
							[23.093499, 41.318378],
							[23.091216, 41.318622],
							[23.090756, 41.318694],
							[23.088172, 41.319226],
							[23.087288, 41.319353],
							[23.085951, 41.319448],
							[23.084737, 41.319523],
							[23.082992, 41.319559],
							[23.081023, 41.319626],
							[23.080048, 41.319765],
							[23.07923, 41.319923],
							[23.07878, 41.319949],
							[23.078212, 41.319983],
							[23.07743, 41.319989],
							[23.076279, 41.320044],
							[23.075055, 41.320311],
							[23.073183, 41.320795],
							[23.071535, 41.321413],
							[23.069888, 41.322219],
							[23.069312, 41.322455],
							[23.067712, 41.322904],
							[23.066945, 41.323218],
							[23.065846, 41.323564],
							[23.064683, 41.323879],
							[23.063484, 41.324037],
							[23.062605, 41.324218],
							[23.061212, 41.324564],
							[23.0608, 41.324606],
							[23.059946, 41.324103],
							[23.058728, 41.324176],
							[23.057456, 41.324476],
							[23.05734, 41.324539],
							[23.057001, 41.324891],
							[23.056189, 41.325497],
							[23.055614, 41.325854],
							[23.05465, 41.326466],
							[23.05425, 41.326708],
							[23.053889, 41.326789],
							[23.053505, 41.326781],
							[23.052694, 41.326642],
							[23.051651, 41.326436],
							[23.05084, 41.32629],
							[23.050409, 41.326278],
							[23.050228, 41.326351],
							[23.049844, 41.326715],
							[23.049584, 41.326925],
							[23.049363, 41.327061],
							[23.048825, 41.327108],
							[23.048654, 41.327139],
							[23.048539, 41.327211],
							[23.048169, 41.327693],
							[23.047906, 41.32796],
							[23.0477, 41.328032],
							[23.046684, 41.328291],
							[23.045688, 41.328885],
							[23.045164, 41.329273],
							[23.044843, 41.329488],
							[23.044343, 41.32967],
							[23.043471, 41.329951],
							[23.042671, 41.330169],
							[23.042288, 41.330299],
							[23.041268, 41.330857],
							[23.040453, 41.331248],
							[23.039941, 41.331457],
							[23.039296, 41.331675],
							[23.038996, 41.331887],
							[23.038572, 41.332163],
							[23.038353, 41.332245],
							[23.038044, 41.33224],
							[23.03766, 41.332217],
							[23.036548, 41.331899],
							[23.036395, 41.331891],
							[23.036185, 41.331933],
							[23.034867, 41.33239],
							[23.034179, 41.332532],
							[23.033701, 41.332602],
							[23.032289, 41.332563],
							[23.03135, 41.332502],
							[23.030941, 41.332511],
							[23.030626, 41.332569],
							[23.030335, 41.332699],
							[23.029926, 41.332935],
							[23.029596, 41.333199],
							[23.029254, 41.333593],
							[23.029114, 41.33372],
							[23.028848, 41.333732],
							[23.028539, 41.333756],
							[23.027915, 41.333932],
							[23.027724, 41.333914],
							[23.027009, 41.33352],
							[23.026164, 41.33316],
							[23.025637, 41.332911],
							[23.025331, 41.332684],
							[23.024583, 41.331896],
							[23.024304, 41.331681],
							[23.023901, 41.331484],
							[23.023159, 41.331178],
							[23.022362, 41.330524],
							[23.022165, 41.330439],
							[23.02102, 41.330505],
							[23.020366, 41.330497],
							[23.019081, 41.330382],
							[23.01777, 41.3303],
							[23.017049, 41.330236],
							[23.016673, 41.33023],
							[23.016382, 41.330233],
							[23.016135, 41.330173],
							[23.015604, 41.329939],
							[23.015219, 41.329791],
							[23.015062, 41.329788],
							[23.01485, 41.329852],
							[23.014147, 41.330203],
							[23.013741, 41.330339],
							[23.012641, 41.330494],
							[23.012147, 41.330524],
							[23.011087, 41.330476],
							[23.010969, 41.330521],
							[23.0106, 41.330772],
							[23.010406, 41.330854],
							[23.00933, 41.33103],
							[23.009012, 41.331027],
							[23.007897, 41.330821],
							[23.007222, 41.330515],
							[23.006725, 41.330339],
							[23.004795, 41.329703],
							[23.004541, 41.329518],
							[23.004065, 41.329971],
							[23.003386, 41.330535],
							[23.003091, 41.330769],
							[23.002819, 41.33077],
							[23.001983, 41.330836],
							[23.001559, 41.33093],
							[23.000896, 41.331157],
							[23.000166, 41.331381],
							[22.999021, 41.331569],
							[22.998406, 41.331718],
							[22.997688, 41.331881],
							[22.997297, 41.331915],
							[22.996422, 41.331948],
							[22.995885, 41.332115],
							[22.995016, 41.332321],
							[22.994495, 41.332499],
							[22.994107, 41.332669],
							[22.991838, 41.333484],
							[22.991606, 41.333544],
							[22.991114, 41.333666],
							[22.990539, 41.333744],
							[22.988579, 41.33398],
							[22.987758, 41.334148],
							[22.986559, 41.334419],
							[22.986104, 41.33455],
							[22.98386, 41.335359],
							[22.983202, 41.335538],
							[22.982878, 41.335541],
							[22.982315, 41.335432],
							[22.981424, 41.335171],
							[22.980681, 41.334834],
							[22.979161, 41.334126],
							[22.978344, 41.33366],
							[22.978098, 41.33359],
							[22.976862, 41.333545],
							[22.976408, 41.333189],
							[22.976035, 41.332815],
							[22.975366, 41.332551],
							[22.974875, 41.332463],
							[22.973875, 41.332497],
							[22.972624, 41.332803],
							[22.971282, 41.333027],
							[22.97004, 41.333384],
							[22.968656, 41.333635],
							[22.966859, 41.333729],
							[22.966302, 41.333749],
							[22.96572, 41.333904],
							[22.96546, 41.333911],
							[22.964297, 41.333762],
							[22.963206, 41.333762],
							[22.960077, 41.333965],
							[22.959229, 41.334141],
							[22.95832, 41.334383],
							[22.957684, 41.334595],
							[22.956024, 41.335252],
							[22.955754, 41.335312],
							[22.954951, 41.335172],
							[22.954012, 41.335074],
							[22.953706, 41.335087],
							[22.953297, 41.335148],
							[22.952735, 41.33531],
							[22.952379, 41.335463],
							[22.951931, 41.335767],
							[22.95155, 41.335955],
							[22.95123, 41.336026],
							[22.949292, 41.335855],
							[22.948955, 41.335851],
							[22.948737, 41.335905],
							[22.947644, 41.336439],
							[22.947008, 41.336616],
							[22.946474, 41.33669],
							[22.945996, 41.336721],
							[22.945607, 41.336761],
							[22.945044, 41.336895],
							[22.944062, 41.337198],
							[22.942928, 41.337587],
							[22.941777, 41.337899],
							[22.940453, 41.338068],
							[22.939245, 41.338281],
							[22.93841, 41.338427],
							[22.937246, 41.338717],
							[22.935476, 41.339275],
							[22.935121, 41.339418],
							[22.935004, 41.339565],
							[22.934987, 41.339708],
							[22.935066, 41.339917],
							[22.934009, 41.34027],
							[22.933195, 41.340448],
							[22.932477, 41.340521],
							[22.931738, 41.340443],
							[22.931296, 41.340327],
							[22.930919, 41.340279],
							[22.9284, 41.340488],
							[22.927613, 41.338531],
							[22.927469, 41.338523],
							[22.927315, 41.338453],
							[22.925911, 41.338584],
							[22.92409, 41.338817],
							[22.921512, 41.339567],
							[22.920695, 41.33978],
							[22.919191, 41.34033],
							[22.917766, 41.340766],
							[22.914735, 41.341432],
							[22.913385, 41.341537],
							[22.909023, 41.341344],
							[22.908351, 41.341276],
							[22.907909, 41.341231],
							[22.904574, 41.340828],
							[22.904353, 41.340154],
							[22.904029, 41.339855],
							[22.903355, 41.339542],
							[22.902364, 41.339244],
							[22.900711, 41.338923],
							[22.898278, 41.338512],
							[22.897505, 41.33846],
							[22.895506, 41.338631],
							[22.891387, 41.339005],
							[22.89011, 41.339594],
							[22.889309, 41.340188],
							[22.888399, 41.341106],
							[22.887334, 41.341266],
							[22.883832, 41.340922],
							[22.881151, 41.340372],
							[22.879695, 41.340896],
							[22.87908, 41.341117],
							[22.878625, 41.341048],
							[22.878042, 41.340676],
							[22.877797, 41.340547],
							[22.877536, 41.340428],
							[22.877269, 41.340317],
							[22.876993, 41.340224],
							[22.87671, 41.340177],
							[22.876418, 41.340156],
							[22.876121, 41.340144],
							[22.87582, 41.340159],
							[22.875518, 41.340174],
							[22.875217, 41.340198],
							[22.874917, 41.340222],
							[22.87462, 41.340255],
							[22.874323, 41.340279],
							[22.874027, 41.340313],
							[22.873731, 41.340355],
							[22.873439, 41.340406],
							[22.873152, 41.340457],
							[22.872869, 41.340535],
							[22.872597, 41.340623],
							[22.872327, 41.340728],
							[22.872059, 41.340825],
							[22.871787, 41.340921],
							[22.871509, 41.341],
							[22.871223, 41.34106],
							[22.87093, 41.341111],
							[22.870634, 41.341144],
							[22.870335, 41.341177],
							[22.870036, 41.341192],
							[22.869738, 41.341207],
							[22.869441, 41.341204],
							[22.869142, 41.341192],
							[22.868842, 41.341181],
							[22.868544, 41.34116],
							[22.868247, 41.34113],
							[22.867951, 41.3411],
							[22.867656, 41.341061],
							[22.867363, 41.341022],
							[22.866779, 41.340926],
							[22.866487, 41.340869],
							[22.866195, 41.340821],
							[22.865609, 41.340743],
							[22.865314, 41.340704],
							[22.865017, 41.340683],
							[22.864717, 41.340671],
							[22.864416, 41.34066],
							[22.864116, 41.340657],
							[22.863817, 41.340645],
							[22.863518, 41.340633],
							[22.863223, 41.340612],
							[22.862931, 41.340573],
							[22.862644, 41.340516],
							[22.862362, 41.340441],
							[22.862085, 41.340348],
							[22.861813, 41.340255],
							[22.861546, 41.340154],
							[22.861283, 41.340043],
							[22.861023, 41.339932],
							[22.860765, 41.339822],
							[22.860509, 41.339702],
							[22.860254, 41.339591],
							[22.859999, 41.339472],
							[22.859746, 41.339352],
							[22.859491, 41.339233],
							[22.859237, 41.339113],
							[22.858987, 41.338984],
							[22.858738, 41.338865],
							[22.858487, 41.338736],
							[22.858235, 41.338617],
							[22.85798, 41.338497],
							[22.857717, 41.338395],
							[22.857638, 41.338377],
							[22.857443, 41.338312],
							[22.857151, 41.338246],
							[22.856853, 41.338207],
							[22.856559, 41.338195],
							[22.856262, 41.338219],
							[22.855965, 41.338261],
							[22.855674, 41.338321],
							[22.855394, 41.338399],
							[22.855122, 41.338496],
							[22.854855, 41.338592],
							[22.854592, 41.338698],
							[22.85433, 41.338812],
							[22.854069, 41.338927],
							[22.853808, 41.339032],
							[22.853552, 41.339165],
							[22.853299, 41.339288],
							[22.853042, 41.339412],
							[22.852781, 41.339508],
							[22.852508, 41.339569],
							[22.852203, 41.339565],
							[22.851903, 41.339509],
							[22.85164, 41.339407],
							[22.8514, 41.339269],
							[22.851165, 41.339132],
							[22.850918, 41.338994],
							[22.850659, 41.338884],
							[22.850395, 41.338782],
							[22.850129, 41.33868],
							[22.849862, 41.338578],
							[22.849593, 41.338477],
							[22.849322, 41.338384],
							[22.84905, 41.338291],
							[22.848778, 41.338198],
							[22.848503, 41.338105],
							[22.848228, 41.338013],
							[22.847951, 41.337929],
							[22.84767, 41.337854],
							[22.847387, 41.337779],
							[22.847101, 41.337722],
							[22.84681, 41.337674],
							[22.846516, 41.337635],
							[22.845624, 41.337545],
							[22.84533, 41.337506],
							[22.845036, 41.337458],
							[22.84474, 41.337401],
							[22.844446, 41.337353],
							[22.844151, 41.337314],
							[22.843858, 41.337284],
							[22.843567, 41.337281],
							[22.843279, 41.337323],
							[22.842993, 41.337402],
							[22.842707, 41.33748],
							[22.841847, 41.337687],
							[22.841556, 41.337747],
							[22.841263, 41.337772],
							[22.840968, 41.337769],
							[22.84067, 41.337756],
							[22.84037, 41.337727],
							[22.84007, 41.337705],
							[22.839175, 41.337669],
							[22.838877, 41.337657],
							[22.838579, 41.337654],
							[22.83828, 41.337642],
							[22.836785, 41.337627],
							[22.836488, 41.337615],
							[22.836188, 41.337594],
							[22.835888, 41.337573],
							[22.835589, 41.337552],
							[22.835297, 41.337504],
							[22.835016, 41.337438],
							[22.834741, 41.337354],
							[22.834473, 41.337253],
							[22.834209, 41.337151],
							[22.833945, 41.33704],
							[22.833681, 41.336929],
							[22.833412, 41.336827],
							[22.832874, 41.336642],
							[22.832603, 41.33654],
							[22.832332, 41.336447],
							[22.83206, 41.336354],
							[22.831788, 41.336262],
							[22.831241, 41.336076],
							[22.831141, 41.336045],
							[22.830967, 41.335992],
							[22.830694, 41.335899],
							[22.830418, 41.335815],
							[22.830142, 41.335722],
							[22.829865, 41.335639],
							[22.82959, 41.335546],
							[22.829314, 41.335462],
							[22.829037, 41.335378],
							[22.828758, 41.335294],
							[22.828477, 41.335219],
							[22.828194, 41.335153],
							[22.827904, 41.335105],
							[22.827607, 41.335066],
							[22.827306, 41.335045],
							[22.827005, 41.335042],
							[22.826709, 41.335057],
							[22.826414, 41.335081],
							[22.826122, 41.335132],
							[22.825831, 41.335183],
							[22.825539, 41.335243],
							[22.825251, 41.335303],
							[22.824975, 41.335391],
							[22.824681, 41.335442],
							[22.824381, 41.335474],
							[22.824086, 41.335481],
							[22.823808, 41.335424],
							[22.82354, 41.335313],
							[22.823273, 41.335202],
							[22.823008, 41.335082],
							[22.822732, 41.335016],
							[22.822426, 41.335004],
							[22.822113, 41.335028],
							[22.821826, 41.335088],
							[22.821603, 41.335203],
							[22.821441, 41.335399],
							[22.821279, 41.335605],
							[22.821066, 41.335756],
							[22.820825, 41.335889],
							[22.820569, 41.336012],
							[22.820307, 41.336126],
							[22.820041, 41.336223],
							[22.819754, 41.336292],
							[22.819467, 41.336352],
							[22.819187, 41.33643],
							[22.81891, 41.336517],
							[22.818636, 41.336605],
							[22.818355, 41.336692],
							[22.818079, 41.336788],
							[22.817846, 41.336921],
							[22.817663, 41.337099],
							[22.817518, 41.337296],
							[22.817405, 41.337511],
							[22.817363, 41.337736],
							[22.817332, 41.337969],
							[22.817214, 41.338166],
							[22.81703, 41.338336],
							[22.816827, 41.338496],
							[22.816608, 41.338656],
							[22.81638, 41.338806],
							[22.81615, 41.338957],
							[22.8157, 41.339259],
							[22.815447, 41.3394],
							[22.815206, 41.339551],
							[22.815023, 41.339711],
							[22.814955, 41.339909],
							[22.815017, 41.340143],
							[22.815118, 41.34037],
							[22.815275, 41.340578],
							[22.815462, 41.340797],
							[22.815554, 41.340987],
							[22.815448, 41.341139],
							[22.815194, 41.341253],
							[22.814887, 41.341358],
							[22.814619, 41.34149],
							[22.814411, 41.34165],
							[22.81422, 41.34182],
							[22.814035, 41.341998],
							[22.813849, 41.342176],
							[22.813656, 41.342354],
							[22.81327, 41.342692],
							[22.813078, 41.342871],
							[22.812889, 41.34304],
							[22.812705, 41.343218],
							[22.812532, 41.343406],
							[22.812386, 41.343611],
							[22.812237, 41.343808],
							[22.812049, 41.343968],
							[22.811787, 41.344073],
							[22.811488, 41.344142],
							[22.811189, 41.344148],
							[22.810912, 41.344073],
							[22.810667, 41.343936],
							[22.810468, 41.343762],
							[22.810291, 41.34359],
							[22.810125, 41.343399],
							[22.809961, 41.343208],
							[22.809792, 41.343017],
							[22.809619, 41.342835],
							[22.809458, 41.342644],
							[22.80933, 41.342445],
							[22.809266, 41.342228],
							[22.809258, 41.341994],
							[22.809302, 41.341778],
							[22.809394, 41.341554],
							[22.809504, 41.341348],
							[22.809626, 41.341142],
							[22.809737, 41.340936],
							[22.809842, 41.340721],
							[22.809935, 41.340505],
							[22.80997, 41.34028],
							[22.809933, 41.340064],
							[22.809856, 41.339838],
							[22.809766, 41.339621],
							[22.809681, 41.339404],
							[22.809594, 41.339187],
							[22.809492, 41.338979],
							[22.809357, 41.338779],
							[22.809174, 41.338588],
							[22.808947, 41.338423],
							[22.808697, 41.338313],
							[22.808416, 41.338301],
							[22.808109, 41.33837],
							[22.80783, 41.338457],
							[22.807578, 41.33858],
							[22.807334, 41.338722],
							[22.807092, 41.338855],
							[22.806841, 41.338978],
							[22.806122, 41.339217],
							[22.804985, 41.338866],
							[22.804191, 41.338721],
							[22.803349, 41.33878],
							[22.801872, 41.338683],
							[22.801358, 41.338608],
							[22.8005, 41.339092],
							[22.800285, 41.339655],
							[22.800092, 41.340187],
							[22.799835, 41.340558],
							[22.799556, 41.340558],
							[22.79932, 41.340461],
							[22.79917, 41.340058],
							[22.799062, 41.339527],
							[22.798826, 41.338995],
							[22.798333, 41.33856],
							[22.797839, 41.338318],
							[22.797539, 41.337948],
							[22.797517, 41.337561],
							[22.797882, 41.337287],
							[22.798268, 41.337046],
							[22.798547, 41.336756],
							[22.798547, 41.336337],
							[22.798419, 41.335837],
							[22.797968, 41.335531],
							[22.797496, 41.335418],
							[22.797055, 41.335689],
							[22.796873, 41.335293],
							[22.796594, 41.335023],
							[22.796395, 41.334714],
							[22.796073, 41.334637],
							[22.79551, 41.334677],
							[22.795188, 41.334549],
							[22.795108, 41.334254],
							[22.794732, 41.333791],
							[22.794432, 41.333457],
							[22.794329, 41.333135],
							[22.793927, 41.332587],
							[22.793659, 41.332523],
							[22.793847, 41.332237],
							[22.793948, 41.331685],
							[22.793975, 41.331302],
							[22.793873, 41.330927],
							[22.79366, 41.330593],
							[22.79324, 41.330388],
							[22.792838, 41.330243],
							[22.792645, 41.330577],
							[22.791985, 41.33038],
							[22.791294, 41.330395],
							[22.790869, 41.330202],
							[22.790484, 41.330117],
							[22.790044, 41.329928],
							[22.789787, 41.329686],
							[22.789958, 41.329352],
							[22.789475, 41.329203],
							[22.788912, 41.329155],
							[22.788671, 41.329227],
							[22.788457, 41.329138],
							[22.788284, 41.329066],
							[22.787807, 41.329014],
							[22.787378, 41.328623],
							[22.786943, 41.328156],
							[22.786438, 41.327669],
							[22.786337, 41.327326],
							[22.785774, 41.326995],
							[22.785221, 41.326807],
							[22.784991, 41.326786],
							[22.784593, 41.326875],
							[22.784079, 41.326963],
							[22.783504, 41.327157],
							[22.782887, 41.327342],
							[22.781986, 41.327286],
							[22.781605, 41.327314],
							[22.781342, 41.327254],
							[22.781244, 41.327301],
							[22.78112, 41.327267],
							[22.781104, 41.327294],
							[22.781043, 41.327267],
							[22.780962, 41.327314],
							[22.780876, 41.327292],
							[22.780811, 41.327256],
							[22.780761, 41.327269],
							[22.780714, 41.327224],
							[22.78068, 41.327221],
							[22.780605, 41.327169],
							[22.780497, 41.327145],
							[22.780438, 41.327203],
							[22.780371, 41.32724],
							[22.780245, 41.327296],
							[22.780157, 41.327252],
							[22.780028, 41.32731],
							[22.779908, 41.327318],
							[22.779757, 41.327342],
							[22.779548, 41.327348],
							[22.779262, 41.32734],
							[22.7791, 41.327213],
							[22.778499, 41.326955],
							[22.777791, 41.326971],
							[22.77704, 41.326939],
							[22.776568, 41.326762],
							[22.776117, 41.326891],
							[22.775559, 41.326697],
							[22.774851, 41.326262],
							[22.774021, 41.326254],
							[22.773091, 41.325988],
							[22.772405, 41.325634],
							[22.771768, 41.325191],
							[22.770645, 41.325038],
							[22.769959, 41.324764],
							[22.769401, 41.32449],
							[22.768774, 41.324151],
							[22.767738, 41.324095],
							[22.767315, 41.323845],
							[22.766418, 41.32304],
							[22.765577, 41.322548],
							[22.764519, 41.322105],
							[22.764047, 41.321412],
							[22.764165, 41.320671],
							[22.76292, 41.318648],
							[22.762706, 41.317649],
							[22.762674, 41.316521],
							[22.761805, 41.316074],
							[22.761483, 41.315481],
							[22.76144, 41.315022],
							[22.761365, 41.314515],
							[22.760667, 41.314039],
							[22.760131, 41.313781],
							[22.759916, 41.313249],
							[22.759659, 41.312621],
							[22.759419, 41.312548],
							[22.759193, 41.312217],
							[22.758989, 41.312137],
							[22.75879, 41.31167],
							[22.759552, 41.311073],
							[22.759691, 41.310534],
							[22.75967, 41.310163],
							[22.759498, 41.30984],
							[22.759262, 41.309607],
							[22.758678, 41.309348],
							[22.758436, 41.308954],
							[22.758329, 41.30864],
							[22.757932, 41.308511],
							[22.757487, 41.308035],
							[22.757127, 41.30785],
							[22.757009, 41.307108],
							[22.756548, 41.306552],
							[22.756376, 41.306246],
							[22.756741, 41.306117],
							[22.756833, 41.305996],
							[22.756425, 41.305746],
							[22.756409, 41.305384],
							[22.755475, 41.305489],
							[22.755261, 41.305214],
							[22.755711, 41.304932],
							[22.755937, 41.304682],
							[22.756344, 41.304433],
							[22.756441, 41.303909],
							[22.756977, 41.303651],
							[22.757127, 41.303175],
							[22.757238, 41.302974],
							[22.757406, 41.302668],
							[22.757192, 41.302241],
							[22.757239, 41.301446],
							[22.757432, 41.300986],
							[22.757346, 41.30039],
							[22.757475, 41.300067],
							[22.757807, 41.300059],
							[22.758086, 41.299898],
							[22.758215, 41.2996],
							[22.758387, 41.299205],
							[22.758483, 41.298939],
							[22.758462, 41.298609],
							[22.758569, 41.29827],
							[22.758623, 41.297939],
							[22.758816, 41.297819],
							[22.759031, 41.297649],
							[22.759224, 41.297464],
							[22.759492, 41.297375],
							[22.759749, 41.297311],
							[22.759953, 41.297399],
							[22.760232, 41.297448],
							[22.760533, 41.297472],
							[22.76093, 41.297456],
							[22.761326, 41.297351],
							[22.761483, 41.297227],
							[22.761617, 41.297026],
							[22.761848, 41.296905],
							[22.761901, 41.29678],
							[22.762062, 41.296651],
							[22.76232, 41.296691],
							[22.762497, 41.296582],
							[22.762669, 41.29628],
							[22.762931, 41.29622],
							[22.763184, 41.296216],
							[22.763312, 41.296103],
							[22.763505, 41.29599],
							[22.763634, 41.296034],
							[22.763758, 41.295974],
							[22.763784, 41.295853],
							[22.763849, 41.295655],
							[22.763918, 41.295514],
							[22.763956, 41.295293],
							[22.764111, 41.295196],
							[22.764251, 41.295127],
							[22.764299, 41.295051],
							[22.764272, 41.294882],
							[22.764358, 41.294765],
							[22.764473, 41.294663],
							[22.76454, 41.294603],
							[22.764657, 41.294437],
							[22.764866, 41.294292],
							[22.765126, 41.294197],
							[22.765266, 41.294205],
							[22.765399, 41.294108],
							[22.765655, 41.293888],
							[22.765832, 41.293779],
							[22.765993, 41.293545],
							[22.766176, 41.293505],
							[22.766236, 41.293338],
							[22.766112, 41.293257],
							[22.766139, 41.293136],
							[22.766037, 41.293031],
							[22.765753, 41.292967],
							[22.76578, 41.29283],
							[22.765978, 41.292798],
							[22.766059, 41.292846],
							[22.766123, 41.292931],
							[22.766182, 41.292866],
							[22.76622, 41.292745],
							[22.766086, 41.292596],
							[22.766032, 41.292435],
							[22.765941, 41.292294],
							[22.765887, 41.292137],
							[22.765715, 41.29208],
							[22.765807, 41.291887],
							[22.765673, 41.291657],
							[22.765608, 41.291419],
							[22.765512, 41.291218],
							[22.765581, 41.291069],
							[22.765442, 41.290871],
							[22.765254, 41.290855],
							[22.765136, 41.290746],
							[22.76512, 41.290617],
							[22.765254, 41.290569],
							[22.765281, 41.29048],
							[22.765163, 41.290375],
							[22.765044, 41.290204],
							[22.764873, 41.290154],
							[22.764766, 41.290093],
							[22.764648, 41.290004],
							[22.764389, 41.29001],
							[22.764121, 41.289938],
							[22.763907, 41.289873],
							[22.763606, 41.289906],
							[22.763284, 41.289793],
							[22.763241, 41.289664],
							[22.763049, 41.289577],
							[22.762855, 41.289446],
							[22.762705, 41.289357],
							[22.762685, 41.289214],
							[22.762716, 41.289003],
							[22.762706, 41.288827],
							[22.762631, 41.288714],
							[22.762545, 41.288577],
							[22.762599, 41.288497],
							[22.76276, 41.2884],
							[22.762898, 41.288221],
							[22.762919, 41.288011],
							[22.762878, 41.287836],
							[22.762877, 41.287552],
							[22.762963, 41.287294],
							[22.763091, 41.287036],
							[22.76321, 41.286715],
							[22.763189, 41.28653],
							[22.762999, 41.286269],
							[22.762746, 41.286151],
							[22.762642, 41.286054],
							[22.762631, 41.285933],
							[22.76276, 41.285788],
							[22.762953, 41.285667],
							[22.763189, 41.285546],
							[22.76379, 41.285425],
							[22.763961, 41.28532],
							[22.763789, 41.285004],
							[22.763994, 41.284942],
							[22.763982, 41.284819],
							[22.763725, 41.284756],
							[22.763434, 41.284722],
							[22.763243, 41.284579],
							[22.763221, 41.284426],
							[22.763532, 41.284248],
							[22.763951, 41.284119],
							[22.764133, 41.283974],
							[22.764132, 41.283811],
							[22.763983, 41.283627],
							[22.763693, 41.283563],
							[22.763371, 41.283603],
							[22.763039, 41.283619],
							[22.762576, 41.283327],
							[22.762254, 41.283142],
							[22.762137, 41.28295],
							[22.761848, 41.282958],
							[22.761547, 41.282789],
							[22.761471, 41.282448],
							[22.761278, 41.282344],
							[22.761054, 41.282394],
							[22.760893, 41.282571],
							[22.760516, 41.282714],
							[22.760238, 41.282837],
							[22.759819, 41.282803],
							[22.759626, 41.282626],
							[22.759874, 41.2822],
							[22.760099, 41.28191],
							[22.760581, 41.281497],
							[22.760666, 41.28107],
							[22.760677, 41.280707],
							[22.760645, 41.280409],
							[22.760548, 41.280215],
							[22.760249, 41.280177],
							[22.759949, 41.280273],
							[22.75982, 41.280483],
							[22.759777, 41.280765],
							[22.759722, 41.280932],
							[22.759561, 41.281013],
							[22.759198, 41.280983],
							[22.759046, 41.280868],
							[22.758801, 41.280797],
							[22.758961, 41.280602],
							[22.759101, 41.280419],
							[22.759101, 41.280169],
							[22.759005, 41.279862],
							[22.758907, 41.279594],
							[22.75893, 41.279354],
							[22.759219, 41.27908],
							[22.759487, 41.278927],
							[22.759648, 41.278717],
							[22.759443, 41.278336],
							[22.759336, 41.278111],
							[22.759358, 41.277901],
							[22.759197, 41.277732],
							[22.759058, 41.277516],
							[22.758833, 41.277427],
							[22.758628, 41.277369],
							[22.75865, 41.2772],
							[22.758393, 41.277008],
							[22.758189, 41.276895],
							[22.757975, 41.276895],
							[22.757674, 41.276879],
							[22.75748, 41.276941],
							[22.757299, 41.277048],
							[22.757115, 41.277079],
							[22.756923, 41.277],
							[22.75675, 41.276917],
							[22.756675, 41.276764],
							[22.756686, 41.276562],
							[22.75659, 41.276337],
							[22.756371, 41.276218],
							[22.756118, 41.276121],
							[22.756065, 41.276037],
							[22.755829, 41.275875],
							[22.755759, 41.275787],
							[22.75584, 41.275722],
							[22.755882, 41.275662],
							[22.755818, 41.275581],
							[22.755861, 41.275464],
							[22.755995, 41.275351],
							[22.756167, 41.275085],
							[22.756253, 41.274892],
							[22.756397, 41.274726],
							[22.756532, 41.274537],
							[22.756617, 41.274408],
							[22.756805, 41.274315],
							[22.7571, 41.274275],
							[22.757304, 41.274279],
							[22.757771, 41.274186],
							[22.758184, 41.274214],
							[22.758318, 41.274134],
							[22.758463, 41.27398],
							[22.758506, 41.273823],
							[22.758484, 41.27371],
							[22.758366, 41.273642],
							[22.758232, 41.273557],
							[22.758162, 41.27342],
							[22.758077, 41.273263],
							[22.758119, 41.273162],
							[22.758275, 41.273114],
							[22.758291, 41.272936],
							[22.758189, 41.27288],
							[22.758066, 41.272799],
							[22.758093, 41.272598],
							[22.758018, 41.272392],
							[22.758018, 41.272154],
							[22.757937, 41.271904],
							[22.758012, 41.271646],
							[22.758136, 41.271469],
							[22.758136, 41.271287],
							[22.758415, 41.27115],
							[22.758447, 41.271005],
							[22.758495, 41.270868],
							[22.758339, 41.270767],
							[22.758511, 41.270646],
							[22.758457, 41.270477],
							[22.758565, 41.270356],
							[22.758506, 41.270182],
							[22.758575, 41.270033],
							[22.758731, 41.26988],
							[22.758732, 41.26964],
							[22.758774, 41.269517],
							[22.758935, 41.269392],
							[22.759069, 41.269203],
							[22.759224, 41.26909],
							[22.759418, 41.269001],
							[22.759407, 41.268916],
							[22.759595, 41.268844],
							[22.7596, 41.268763],
							[22.759745, 41.268642],
							[22.759756, 41.268517],
							[22.759992, 41.268441],
							[22.76012, 41.268364],
							[22.760212, 41.268191],
							[22.760276, 41.26811],
							[22.760415, 41.268042],
							[22.760448, 41.267981],
							[22.760549, 41.267908],
							[22.760721, 41.267824],
							[22.76078, 41.26761],
							[22.760871, 41.267481],
							[22.760936, 41.267465],
							[22.760946, 41.267356],
							[22.760882, 41.267291],
							[22.760807, 41.266892],
							[22.760855, 41.266808],
							[22.760796, 41.266719],
							[22.760818, 41.266428],
							[22.760827, 41.266156],
							[22.76086, 41.265241],
							[22.760752, 41.264717],
							[22.760571, 41.263194],
							[22.760399, 41.261815],
							[22.760216, 41.260959],
							[22.760173, 41.260174],
							[22.759922, 41.258785],
							[22.759595, 41.255831],
							[22.759293, 41.253393],
							[22.759115, 41.252137],
							[22.758785, 41.25008],
							[22.758715, 41.248959],
							[22.758547, 41.24775],
							[22.758195, 41.246003],
							[22.758091, 41.245423],
							[22.75487, 41.226843],
							[22.751381, 41.193252],
							[22.749638, 41.176461],
							[22.749384, 41.175828],
							[22.74926, 41.175511],
							[22.749211, 41.175295],
							[22.749155, 41.175069],
							[22.749094, 41.174852],
							[22.749029, 41.174626],
							[22.748959, 41.174409],
							[22.748883, 41.174192],
							[22.748804, 41.173975],
							[22.748722, 41.173758],
							[22.748644, 41.173541],
							[22.748607, 41.173432],
							[22.74857, 41.173324],
							[22.748503, 41.173107],
							[22.748447, 41.172881],
							[22.748395, 41.172656],
							[22.748349, 41.172439],
							[22.748311, 41.172213],
							[22.748283, 41.171988],
							[22.748265, 41.171762],
							[22.748264, 41.171537],
							[22.748303, 41.171321],
							[22.748431, 41.171007],
							[22.748464, 41.170916],
							[22.748552, 41.170667],
							[22.74861, 41.170442],
							[22.748656, 41.170217],
							[22.74868, 41.169992],
							[22.74868, 41.169767],
							[22.74868, 41.169542],
							[22.748676, 41.169317],
							[22.74867, 41.169092],
							[22.748659, 41.168866],
							[22.748643, 41.168641],
							[22.748619, 41.168415],
							[22.748586, 41.16819],
							[22.748544, 41.167973],
							[22.74849, 41.167747],
							[22.748432, 41.167531],
							[22.748368, 41.167314],
							[22.748302, 41.167088],
							[22.748231, 41.166871],
							[22.748158, 41.166654],
							[22.748082, 41.166437],
							[22.748004, 41.16622],
							[22.747923, 41.166003],
							[22.747842, 41.165786],
							[22.747759, 41.165569],
							[22.747675, 41.165352],
							[22.747591, 41.165135],
							[22.747425, 41.1647],
							[22.747342, 41.164483],
							[22.747257, 41.164266],
							[22.747166, 41.164058],
							[22.747074, 41.163841],
							[22.746977, 41.163623],
							[22.746875, 41.163415],
							[22.746769, 41.163207],
							[22.74666, 41.162999],
							[22.746537, 41.16279],
							[22.746394, 41.16259],
							[22.746223, 41.162408],
							[22.746027, 41.162235],
							[22.745817, 41.16208],
							[22.745379, 41.161769],
							[22.745167, 41.161613],
							[22.744756, 41.161284],
							[22.744552, 41.16112],
							[22.744349, 41.160956],
							[22.74415, 41.160791],
							[22.743952, 41.160618],
							[22.743555, 41.160289],
							[22.74336, 41.160116],
							[22.743167, 41.159943],
							[22.742977, 41.15977],
							[22.742792, 41.159597],
							[22.742611, 41.159414],
							[22.742437, 41.159232],
							[22.742267, 41.15905],
							[22.742101, 41.158859],
							[22.741938, 41.158668],
							[22.741777, 41.158487],
							[22.741623, 41.158287],
							[22.741476, 41.158096],
							[22.741333, 41.157896],
							[22.741052, 41.157497],
							[22.740917, 41.157297],
							[22.74065, 41.156898],
							[22.740517, 41.156689],
							[22.740381, 41.15649],
							[22.740243, 41.15629],
							[22.740099, 41.156099],
							[22.739951, 41.155899],
							[22.739792, 41.155709],
							[22.73962, 41.155527],
							[22.739438, 41.155344],
							[22.739252, 41.155171],
							[22.739067, 41.154989],
							[22.738888, 41.154816],
							[22.73872, 41.154625],
							[22.738569, 41.154434],
							[22.738443, 41.154235],
							[22.738334, 41.154026],
							[22.738231, 41.153809],
							[22.738122, 41.153601],
							[22.737998, 41.153392],
							[22.737859, 41.153192],
							[22.737713, 41.153002],
							[22.737568, 41.152802],
							[22.737147, 41.152203],
							[22.737009, 41.152003],
							[22.736872, 41.151803],
							[22.736743, 41.151604],
							[22.736619, 41.151395],
							[22.736492, 41.151196],
							[22.736351, 41.150996],
							[22.736199, 41.150805],
							[22.736042, 41.150614],
							[22.735883, 41.150423],
							[22.735721, 41.150232],
							[22.735558, 41.150041],
							[22.735393, 41.149859],
							[22.735226, 41.149668],
							[22.735056, 41.149486],
							[22.734886, 41.149304],
							[22.734717, 41.149113],
							[22.734547, 41.148931],
							[22.734375, 41.148749],
							[22.734203, 41.148558],
							[22.73403, 41.148376],
							[22.733856, 41.148194],
							[22.73368, 41.148012],
							[22.733501, 41.14783],
							[22.73332, 41.147657],
							[22.733136, 41.147475],
							[22.73295, 41.147301],
							[22.732758, 41.147128],
							[22.732563, 41.146955],
							[22.732365, 41.146791],
							[22.732162, 41.146626],
							[22.731955, 41.146462],
							[22.731745, 41.146297],
							[22.731532, 41.146142],
							[22.731314, 41.145995],
							[22.731092, 41.14584],
							[22.730847, 41.14572],
							[22.730586, 41.145609],
							[22.730323, 41.145498],
							[22.730065, 41.145387],
							[22.729812, 41.145258],
							[22.729557, 41.145147],
							[22.729295, 41.145036],
							[22.729021, 41.144952],
							[22.728733, 41.144895],
							[22.728441, 41.144847],
							[22.728147, 41.144816],
							[22.727849, 41.144795],
							[22.727553, 41.144774],
							[22.727258, 41.144734],
							[22.726964, 41.144704],
							[22.726671, 41.144656],
							[22.726378, 41.144617],
							[22.726084, 41.144577],
							[22.72579, 41.144538],
							[22.725496, 41.144508],
							[22.725201, 41.144478],
							[22.724904, 41.144456],
							[22.724608, 41.144444],
							[22.724311, 41.144423],
							[22.724014, 41.14441],
							[22.723717, 41.144398],
							[22.721038, 41.144287],
							[22.720742, 41.144275],
							[22.720444, 41.144254],
							[22.720147, 41.144241],
							[22.71985, 41.14422],
							[22.719553, 41.144199],
							[22.719257, 41.144168],
							[22.718962, 41.144147],
							[22.718668, 41.144108],
							[22.718376, 41.144069],
							[22.718087, 41.144002],
							[22.7178, 41.143945],
							[22.717512, 41.143888],
							[22.717219, 41.143848],
							[22.716913, 41.143854],
							[22.716608, 41.143869],
							[22.716325, 41.143839],
							[22.716084, 41.143719],
							[22.715873, 41.143554],
							[22.715669, 41.143381],
							[22.715466, 41.143216],
							[22.715274, 41.143043],
							[22.715102, 41.142861],
							[22.714948, 41.14267],
							[22.714805, 41.14247],
							[22.714668, 41.142271],
							[22.714545, 41.142062],
							[22.714436, 41.141845],
							[22.714323, 41.141636],
							[22.714192, 41.141437],
							[22.714028, 41.141255],
							[22.713818, 41.141099],
							[22.713575, 41.14097],
							[22.713316, 41.140841],
							[22.71306, 41.140721],
							[22.712822, 41.140584],
							[22.712623, 41.140419],
							[22.712477, 41.14022],
							[22.71236, 41.139993],
							[22.71224, 41.139775],
							[22.712088, 41.139612],
							[22.711838, 41.139573],
							[22.711517, 41.139659],
							[22.711247, 41.139755],
							[22.710988, 41.13987],
							[22.710739, 41.139993],
							[22.710499, 41.140134],
							[22.710263, 41.140267],
							[22.710031, 41.140408],
							[22.709801, 41.14055],
							[22.709573, 41.140701],
							[22.709346, 41.140842],
							[22.70912, 41.140993],
							[22.708896, 41.141134],
							[22.708672, 41.141285],
							[22.708451, 41.141436],
							[22.708234, 41.141595],
							[22.708019, 41.141746],
							[22.707598, 41.142066],
							[22.707389, 41.142225],
							[22.707182, 41.142385],
							[22.706975, 41.142554],
							[22.706771, 41.142714],
							[22.70657, 41.142883],
							[22.706373, 41.143052],
							[22.706178, 41.143221],
							[22.706, 41.143399],
							[22.705851, 41.143595],
							[22.705708, 41.143792],
							[22.705547, 41.143988],
							[22.705382, 41.144175],
							[22.70522, 41.144363],
							[22.705075, 41.144559],
							[22.704946, 41.144756],
							[22.70483, 41.144971],
							[22.704723, 41.145177],
							[22.704637, 41.145392],
							[22.704555, 41.145607],
							[22.704449, 41.145822],
							[22.704317, 41.146019],
							[22.704172, 41.146216],
							[22.703868, 41.146608],
							[22.703712, 41.146796],
							[22.70355, 41.146992],
							[22.703382, 41.147171],
							[22.703201, 41.147349],
							[22.703008, 41.147527],
							[22.702795, 41.147677],
							[22.702597, 41.147846],
							[22.702382, 41.148006],
							[22.702168, 41.148166],
							[22.701902, 41.148352],
							[22.701684, 41.148502],
							[22.701485, 41.148671],
							[22.701308, 41.14885],
							[22.701139, 41.149037],
							[22.700975, 41.149224],
							[22.700816, 41.149411],
							[22.700663, 41.149608],
							[22.700513, 41.149804],
							[22.70037, 41.150001],
							[22.700238, 41.150198],
							[22.700112, 41.150403],
							[22.699987, 41.150609],
							[22.699856, 41.150815],
							[22.699719, 41.151012],
							[22.699568, 41.151208],
							[22.699405, 41.151395],
							[22.699241, 41.151583],
							[22.699072, 41.15177],
							[22.698903, 41.151948],
							[22.69873, 41.152135],
							[22.698556, 41.152322],
							[22.698204, 41.152679],
							[22.698026, 41.152866],
							[22.697671, 41.153222],
							[22.697492, 41.153409],
							[22.697139, 41.153766],
							[22.696964, 41.153953],
							[22.696776, 41.154131],
							[22.696561, 41.154282],
							[22.69631, 41.154396],
							[22.69604, 41.154501],
							[22.695773, 41.154606],
							[22.695528, 41.154729],
							[22.695302, 41.15488],
							[22.695082, 41.155031],
							[22.694853, 41.155172],
							[22.694614, 41.155313],
							[22.694393, 41.155464],
							[22.694213, 41.155642],
							[22.694059, 41.15583],
							[22.693912, 41.156026],
							[22.693765, 41.156232],
							[22.693627, 41.156428],
							[22.69346, 41.156615],
							[22.693248, 41.156775],
							[22.693008, 41.156908],
							[22.692752, 41.157022],
							[22.692475, 41.1571],
							[22.692186, 41.157159],
							[22.691903, 41.157237],
							[22.69162, 41.157306],
							[22.69134, 41.157384],
							[22.69106, 41.157462],
							[22.690786, 41.157549],
							[22.690509, 41.157636],
							[22.690234, 41.157732],
							[22.68997, 41.157828],
							[22.689721, 41.157951],
							[22.689488, 41.158093],
							[22.689266, 41.158243],
							[22.689055, 41.158403],
							[22.688847, 41.158563],
							[22.688642, 41.158732],
							[22.688439, 41.158891],
							[22.688246, 41.159069],
							[22.688073, 41.159247],
							[22.687923, 41.159444],
							[22.687788, 41.159641],
							[22.687664, 41.159846],
							[22.687546, 41.160061],
							[22.687435, 41.160267],
							[22.687325, 41.160473],
							[22.68722, 41.160688],
							[22.687121, 41.160903],
							[22.687025, 41.161109],
							[22.686932, 41.161324],
							[22.686841, 41.161539],
							[22.686752, 41.161755],
							[22.686668, 41.16197],
							[22.686588, 41.162194],
							[22.686512, 41.162409],
							[22.686436, 41.162634],
							[22.686359, 41.162849],
							[22.68628, 41.163073],
							[22.686194, 41.163289],
							[22.686099, 41.163495],
							[22.685995, 41.163701],
							[22.685852, 41.163888],
							[22.685615, 41.164038],
							[22.685354, 41.164162],
							[22.685094, 41.164267],
							[22.684825, 41.164363],
							[22.684556, 41.164468],
							[22.684293, 41.164573],
							[22.684032, 41.164678],
							[22.683768, 41.164792],
							[22.683509, 41.164906],
							[22.683376, 41.164973],
							[22.683263, 41.16503],
							[22.683039, 41.16518],
							[22.682848, 41.165349],
							[22.68269, 41.165545],
							[22.682566, 41.165751],
							[22.682487, 41.165966],
							[22.682453, 41.166117],
							[22.682318, 41.166284],
							[22.682226, 41.166446],
							[22.682092, 41.166664],
							[22.681909, 41.166874],
							[22.681723, 41.167201],
							[22.681775, 41.167407],
							[22.681813, 41.167688],
							[22.681657, 41.167885],
							[22.681518, 41.16807],
							[22.681384, 41.168242],
							[22.68122, 41.1684],
							[22.680981, 41.168573],
							[22.680678, 41.168773],
							[22.680429, 41.168978],
							[22.680263, 41.16911],
							[22.679987, 41.169194],
							[22.679672, 41.169259],
							[22.679371, 41.169298],
							[22.679059, 41.169368],
							[22.678833, 41.169519],
							[22.678548, 41.16964],
							[22.678335, 41.169762],
							[22.678083, 41.169923],
							[22.677882, 41.170061],
							[22.677659, 41.170183],
							[22.677486, 41.170362],
							[22.677322, 41.17054],
							[22.67722, 41.170766],
							[22.677056, 41.170961],
							[22.67695, 41.171109],
							[22.676778, 41.171283],
							[22.676638, 41.171409],
							[22.676477, 41.171582],
							[22.676302, 41.171775],
							[22.676132, 41.171967],
							[22.676062, 41.172114],
							[22.676095, 41.172333],
							[22.676181, 41.172676],
							[22.676296, 41.172972],
							[22.676417, 41.17323],
							[22.676578, 41.173497],
							[22.676705, 41.173754],
							[22.676745, 41.174115],
							[22.676623, 41.174321],
							[22.676492, 41.174527],
							[22.676361, 41.174733],
							[22.676229, 41.174929],
							[22.676098, 41.175135],
							[22.675964, 41.175332],
							[22.67583, 41.175537],
							[22.675695, 41.175734],
							[22.675558, 41.175939],
							[22.675421, 41.176136],
							[22.675282, 41.176333],
							[22.675142, 41.176538],
							[22.675001, 41.176735],
							[22.674859, 41.176931],
							[22.674714, 41.177128],
							[22.674568, 41.177324],
							[22.67442, 41.177521],
							[22.67427, 41.177717],
							[22.674118, 41.177904],
							[22.673958, 41.178101],
							[22.673789, 41.178297],
							[22.673613, 41.178475],
							[22.673426, 41.178653],
							[22.673231, 41.178831],
							[22.673025, 41.178982],
							[22.672803, 41.179123],
							[22.672534, 41.17921],
							[22.67224, 41.17927],
							[22.671946, 41.179339],
							[22.671676, 41.179435],
							[22.671147, 41.179645],
							[22.670884, 41.17975],
							[22.670622, 41.179864],
							[22.670361, 41.179969],
							[22.670102, 41.180083],
							[22.669846, 41.180197],
							[22.669591, 41.18032],
							[22.669341, 41.180435],
							[22.669102, 41.180567],
							[22.66889, 41.180727],
							[22.668695, 41.180904],
							[22.668516, 41.181083],
							[22.668364, 41.181279],
							[22.668253, 41.181485],
							[22.668168, 41.1817],
							[22.668099, 41.181925],
							[22.668042, 41.18214],
							[22.667992, 41.182365],
							[22.667944, 41.182589],
							[22.667897, 41.182814],
							[22.667854, 41.18303],
							[22.66781, 41.183254],
							[22.667767, 41.183479],
							[22.667724, 41.183704],
							[22.667682, 41.183919],
							[22.667639, 41.184144],
							[22.667595, 41.184369],
							[22.667549, 41.184593],
							[22.667501, 41.184818],
							[22.667462, 41.185043],
							[22.667422, 41.185268],
							[22.667372, 41.185492],
							[22.667301, 41.185707],
							[22.667191, 41.185904],
							[22.666977, 41.186064],
							[22.666718, 41.186196],
							[22.666436, 41.186292],
							[22.666156, 41.186262],
							[22.665891, 41.18615],
							[22.665625, 41.186039],
							[22.665358, 41.185937],
							[22.66509, 41.185844],
							[22.664823, 41.185742],
							[22.664554, 41.18564],
							[22.664285, 41.185546],
							[22.664015, 41.185453],
							[22.663746, 41.185351],
							[22.663476, 41.185258],
							[22.662933, 41.185071],
							[22.662659, 41.184978],
							[22.662382, 41.184903],
							[22.662103, 41.184818],
							[22.66182, 41.184743],
							[22.661537, 41.184677],
							[22.661251, 41.18461],
							[22.660964, 41.184544],
							[22.660675, 41.184487],
							[22.660385, 41.184438],
							[22.660092, 41.184408],
							[22.659797, 41.184377],
							[22.659501, 41.184365],
							[22.659203, 41.184352],
							[22.658904, 41.184358],
							[22.658304, 41.184351],
							[22.658006, 41.184356],
							[22.657695, 41.18438],
							[22.657381, 41.184412],
							[22.657086, 41.184418],
							[22.656837, 41.184361],
							[22.656662, 41.184188],
							[22.656522, 41.183961],
							[22.656369, 41.183761],
							[22.65622, 41.183561],
							[22.65614, 41.183353],
							[22.656118, 41.183127],
							[22.656118, 41.182902],
							[22.65613, 41.182677],
							[22.656109, 41.182452],
							[22.656057, 41.182226],
							[22.655991, 41.182],
							[22.655901, 41.181792],
							[22.65578, 41.181583],
							[22.6556, 41.18141],
							[22.655378, 41.181254],
							[22.655144, 41.181107],
							[22.654878, 41.180996],
							[22.654595, 41.180939],
							[22.654298, 41.180944],
							[22.653996, 41.180968],
							[22.6537, 41.181],
							[22.653407, 41.181042],
							[22.653116, 41.181093],
							[22.652825, 41.181134],
							[22.65253, 41.181176],
							[22.652234, 41.18119],
							[22.651935, 41.181196],
							[22.651637, 41.181202],
							[22.651339, 41.181189],
							[22.65104, 41.181185],
							[22.650741, 41.181173],
							[22.650448, 41.181134],
							[22.650152, 41.181085],
							[22.64986, 41.181027],
							[22.649594, 41.180925],
							[22.649371, 41.180788],
							[22.649186, 41.180605],
							[22.649009, 41.180423],
							[22.648828, 41.180241],
							[22.648652, 41.180059],
							[22.64848, 41.179876],
							[22.648311, 41.179694],
							[22.64814, 41.179503],
							[22.647971, 41.179321],
							[22.647805, 41.17913],
							[22.647645, 41.178948],
							[22.647494, 41.178748],
							[22.647351, 41.178557],
							[22.647216, 41.178348],
							[22.646948, 41.177949],
							[22.646665, 41.177549],
							[22.646506, 41.177358],
							[22.646343, 41.177167],
							[22.646186, 41.176976],
							[22.646042, 41.176776],
							[22.645921, 41.176577],
							[22.645835, 41.176368],
							[22.645783, 41.176143],
							[22.645749, 41.175917],
							[22.645716, 41.175691],
							[22.64567, 41.175466],
							[22.645615, 41.175249],
							[22.645666, 41.175136],
							[22.645377, 41.174933],
							[22.645226, 41.174896],
							[22.645261, 41.174559],
							[22.645529, 41.174281],
							[22.645224, 41.17405],
							[22.645393, 41.173704],
							[22.645048, 41.173601],
							[22.645101, 41.173261],
							[22.64505, 41.173035],
							[22.644683, 41.17281],
							[22.644662, 41.172563],
							[22.64458, 41.172284],
							[22.644493, 41.172022],
							[22.644361, 41.171787],
							[22.644021, 41.17173],
							[22.643846, 41.171579],
							[22.643622, 41.171409],
							[22.643205, 41.171188],
							[22.642692, 41.170937],
							[22.642411, 41.170666],
							[22.642091, 41.170394],
							[22.64178, 41.170132],
							[22.641474, 41.16996],
							[22.641201, 41.169703],
							[22.641214, 41.169631],
							[22.64124, 41.169481],
							[22.641419, 41.16921],
							[22.641577, 41.16899],
							[22.641651, 41.168525],
							[22.641529, 41.168139],
							[22.641841, 41.167493],
							[22.642412, 41.166877],
							[22.642604, 41.166423],
							[22.642703, 41.165771],
							[22.642351, 41.165254],
							[22.642069, 41.164844],
							[22.641862, 41.16453],
							[22.641907, 41.163982],
							[22.642066, 41.163516],
							[22.642026, 41.163007],
							[22.641655, 41.162662],
							[22.641488, 41.162284],
							[22.641898, 41.161706],
							[22.642105, 41.161419],
							[22.642079, 41.161024],
							[22.64205, 41.160645],
							[22.641792, 41.160418],
							[22.641524, 41.160282],
							[22.641409, 41.160114],
							[22.64139, 41.159766],
							[22.641282, 41.159551],
							[22.641021, 41.159249],
							[22.640798, 41.159034],
							[22.640435, 41.158824],
							[22.639902, 41.158661],
							[22.63958, 41.158557],
							[22.639488, 41.158386],
							[22.639404, 41.158057],
							[22.639323, 41.157786],
							[22.639188, 41.157379],
							[22.639031, 41.157034],
							[22.638762, 41.156762],
							[22.638609, 41.156463],
							[22.63832, 41.156301],
							[22.638079, 41.156015],
							[22.637655, 41.155676],
							[22.637937, 41.155458],
							[22.638232, 41.15541],
							[22.638509, 41.155296],
							[22.638604, 41.155214],
							[22.638399, 41.154985],
							[22.638273, 41.154948],
							[22.638229, 41.15487],
							[22.638125, 41.154804],
							[22.638069, 41.154699],
							[22.637923, 41.154548],
							[22.637834, 41.154356],
							[22.637747, 41.154291],
							[22.637436, 41.154391],
							[22.637247, 41.154489],
							[22.637176, 41.154382],
							[22.637071, 41.154308],
							[22.636982, 41.154101],
							[22.636849, 41.153874],
							[22.636859, 41.153817],
							[22.637195, 41.15368],
							[22.637696, 41.153493],
							[22.637965, 41.153419],
							[22.638067, 41.153413],
							[22.638175, 41.153519],
							[22.638263, 41.15365],
							[22.638231, 41.15374],
							[22.638353, 41.153791],
							[22.638492, 41.153759],
							[22.638607, 41.15367],
							[22.638674, 41.153618],
							[22.638782, 41.153427],
							[22.638935, 41.153343],
							[22.638967, 41.153255],
							[22.63876, 41.153388],
							[22.638589, 41.153465],
							[22.638465, 41.153354],
							[22.638215, 41.153143],
							[22.638127, 41.153045],
							[22.637964, 41.152946],
							[22.637787, 41.152882],
							[22.637694, 41.152783],
							[22.637585, 41.152629],
							[22.637561, 41.152468],
							[22.637534, 41.152279],
							[22.637545, 41.152217],
							[22.637488, 41.152136],
							[22.637352, 41.152111],
							[22.637195, 41.152024],
							[22.637109, 41.152084],
							[22.637045, 41.152102],
							[22.636986, 41.152082],
							[22.636845, 41.152067],
							[22.636675, 41.152048],
							[22.636507, 41.152052],
							[22.636326, 41.152057],
							[22.636245, 41.151984],
							[22.63638, 41.151855],
							[22.63629, 41.151823],
							[22.636209, 41.151843],
							[22.636244, 41.151935],
							[22.636136, 41.152044],
							[22.636058, 41.152104],
							[22.635884, 41.152197],
							[22.635769, 41.152292],
							[22.635657, 41.152338],
							[22.635583, 41.152311],
							[22.635302, 41.151988],
							[22.63514, 41.151821],
							[22.634972, 41.151698],
							[22.634816, 41.151616],
							[22.634713, 41.151523],
							[22.634596, 41.151401],
							[22.634465, 41.151315],
							[22.634313, 41.151189],
							[22.634167, 41.151039],
							[22.633967, 41.150885],
							[22.633872, 41.150852],
							[22.633733, 41.150745],
							[22.633639, 41.150644],
							[22.633459, 41.150473],
							[22.633374, 41.150432],
							[22.633253, 41.150323],
							[22.633131, 41.150177],
							[22.633016, 41.150059],
							[22.632876, 41.149967],
							[22.632655, 41.149793],
							[22.63235, 41.149533],
							[22.632233, 41.149422],
							[22.632226, 41.149386],
							[22.632036, 41.149217],
							[22.631912, 41.149144],
							[22.631683, 41.148881],
							[22.631272, 41.148489],
							[22.631089, 41.148352],
							[22.630943, 41.14823],
							[22.63091, 41.148164],
							[22.630816, 41.148099],
							[22.630679, 41.147972],
							[22.630585, 41.147915],
							[22.630454, 41.147799],
							[22.630277, 41.147624],
							[22.630095, 41.147482],
							[22.62994, 41.147313],
							[22.629721, 41.147115],
							[22.629502, 41.146939],
							[22.629314, 41.146774],
							[22.628934, 41.146461],
							[22.628798, 41.146364],
							[22.628708, 41.146269],
							[22.628601, 41.146194],
							[22.628501, 41.146071],
							[22.628358, 41.145964],
							[22.628268, 41.145878],
							[22.628269, 41.145833],
							[22.628747, 41.145566],
							[22.628513, 41.145673],
							[22.628355, 41.14569],
							[22.628207, 41.145656],
							[22.628168, 41.145572],
							[22.628202, 41.145453],
							[22.628334, 41.145345],
							[22.628505, 41.145294],
							[22.628535, 41.14522],
							[22.628643, 41.145183],
							[22.628683, 41.145113],
							[22.62878, 41.144981],
							[22.628774, 41.144919],
							[22.628665, 41.144915],
							[22.628572, 41.144963],
							[22.628528, 41.144945],
							[22.628443, 41.144926],
							[22.628317, 41.144927],
							[22.628312, 41.144877],
							[22.628195, 41.144908],
							[22.628099, 41.144975],
							[22.627998, 41.145013],
							[22.627891, 41.144984],
							[22.627606, 41.144836],
							[22.627461, 41.144761],
							[22.627324, 41.144754],
							[22.627185, 41.144745],
							[22.626849, 41.144596],
							[22.626617, 41.14447],
							[22.626426, 41.144344],
							[22.626149, 41.144073],
							[22.626079, 41.143948],
							[22.625918, 41.143809],
							[22.625437, 41.143465],
							[22.62508, 41.143112],
							[22.624589, 41.142667],
							[22.624484, 41.14262],
							[22.624221, 41.142261],
							[22.62407, 41.14206],
							[22.623908, 41.141843],
							[22.623613, 41.141443],
							[22.623464, 41.141211],
							[22.623035, 41.140691],
							[22.622579, 41.140091],
							[22.622368, 41.139831],
							[22.622173, 41.139561],
							[22.622063, 41.139365],
							[22.621962, 41.139213],
							[22.621894, 41.139134],
							[22.62176, 41.138998],
							[22.621699, 41.138838],
							[22.621481, 41.138676],
							[22.621276, 41.138393],
							[22.621143, 41.138188],
							[22.620966, 41.137957],
							[22.6206, 41.137465],
							[22.619963, 41.136628],
							[22.619692, 41.136289],
							[22.619523, 41.136114],
							[22.619441, 41.13594],
							[22.619059, 41.135469],
							[22.618762, 41.135092],
							[22.618585, 41.134755],
							[22.618348, 41.134419],
							[22.618112, 41.134272],
							[22.618097, 41.134162],
							[22.617883, 41.133933],
							[22.61784, 41.133783],
							[22.617934, 41.133704],
							[22.617547, 41.133361],
							[22.617463, 41.13323],
							[22.617251, 41.133149],
							[22.616971, 41.133035],
							[22.616653, 41.13289],
							[22.616381, 41.132727],
							[22.616312, 41.132632],
							[22.616207, 41.132633],
							[22.616034, 41.132507],
							[22.615922, 41.132501],
							[22.615574, 41.13222],
							[22.61549, 41.132105],
							[22.615577, 41.13192],
							[22.615284, 41.131812],
							[22.615431, 41.131614],
							[22.61539, 41.13154],
							[22.615372, 41.131507],
							[22.614976, 41.131345],
							[22.614755, 41.131364],
							[22.614657, 41.131296],
							[22.614404, 41.131296],
							[22.614192, 41.131263],
							[22.614, 41.13116],
							[22.613927, 41.131011],
							[22.613937, 41.130854],
							[22.613903, 41.130729],
							[22.613817, 41.130663],
							[22.613678, 41.130662],
							[22.6136, 41.130691],
							[22.613462, 41.130636],
							[22.612988, 41.130414],
							[22.612692, 41.130255],
							[22.612256, 41.129959],
							[22.611926, 41.129782],
							[22.611545, 41.129609],
							[22.61138, 41.129684],
							[22.610903, 41.12943],
							[22.61044, 41.129153],
							[22.609462, 41.128633],
							[22.609448, 41.128625],
							[22.608919, 41.128323],
							[22.608892, 41.128307],
							[22.607185, 41.127368],
							[22.606324, 41.126897],
							[22.605745, 41.126594],
							[22.605413, 41.126394],
							[22.605381, 41.126373],
							[22.604835, 41.12605],
							[22.603571, 41.125372],
							[22.602925, 41.125008],
							[22.602893, 41.124991],
							[22.601396, 41.124171],
							[22.601073, 41.123991],
							[22.600326, 41.123579],
							[22.598506, 41.122572],
							[22.596157, 41.121279],
							[22.595057, 41.120721],
							[22.593293, 41.119743],
							[22.591264, 41.118604],
							[22.590052, 41.117929],
							[22.589381, 41.117557],
							[22.589173, 41.117468],
							[22.588786, 41.117476],
							[22.588405, 41.117318],
							[22.588207, 41.11729],
							[22.587735, 41.11729],
							[22.587526, 41.117351],
							[22.587203, 41.11744],
							[22.587026, 41.11744],
							[22.586769, 41.117484],
							[22.585433, 41.1175],
							[22.585084, 41.1175],
							[22.584907, 41.11744],
							[22.584683, 41.117213],
							[22.584194, 41.116931],
							[22.583846, 41.116773],
							[22.583293, 41.116785],
							[22.583164, 41.116866],
							[22.582988, 41.1171],
							[22.582569, 41.117509],
							[22.58244, 41.117707],
							[22.582215, 41.118204],
							[22.582135, 41.11824],
							[22.581152, 41.118212],
							[22.579951, 41.118288],
							[22.578904, 41.118442],
							[22.578385, 41.118495],
							[22.577638, 41.118499],
							[22.576818, 41.118479],
							[22.57611, 41.118495],
							[22.575735, 41.118588],
							[22.574817, 41.118834],
							[22.574206, 41.119052],
							[22.573729, 41.119481],
							[22.573364, 41.119901],
							[22.573074, 41.120115],
							[22.572817, 41.120337],
							[22.572049, 41.12064],
							[22.571465, 41.120928],
							[22.570821, 41.121271],
							[22.570392, 41.121578],
							[22.57001, 41.121901],
							[22.5697, 41.122548],
							[22.568917, 41.123263],
							[22.568874, 41.123368],
							[22.567871, 41.124338],
							[22.566733, 41.12547],
							[22.566411, 41.12578],
							[22.564614, 41.127542],
							[22.564148, 41.128023],
							[22.563075, 41.128993],
							[22.562892, 41.129539],
							[22.562693, 41.129882],
							[22.562071, 41.131078],
							[22.561942, 41.131106],
							[22.56136, 41.131096],
							[22.560562, 41.131068],
							[22.55965, 41.13107],
							[22.55918, 41.131056],
							[22.558667, 41.131039],
							[22.558031, 41.131019],
							[22.557106, 41.130965],
							[22.557009, 41.130936],
							[22.556748, 41.130938],
							[22.55632, 41.130918],
							[22.554343, 41.130888],
							[22.552849, 41.130831],
							[22.552561, 41.130818],
							[22.552495, 41.130816],
							[22.552319, 41.130811],
							[22.552263, 41.130809],
							[22.552212, 41.130807],
							[22.552157, 41.130805],
							[22.552102, 41.130804],
							[22.551748, 41.130792],
							[22.5517, 41.130791],
							[22.551652, 41.130789],
							[22.551619, 41.130788],
							[22.551587, 41.130787],
							[22.551466, 41.130783],
							[22.551352, 41.13078],
							[22.551237, 41.130776],
							[22.551128, 41.130772],
							[22.551063, 41.13077],
							[22.551011, 41.130769],
							[22.550845, 41.130763],
							[22.550714, 41.130753],
							[22.550317, 41.13073],
							[22.549811, 41.130708],
							[22.549159, 41.130658],
							[22.548869, 41.130668],
							[22.548762, 41.130641],
							[22.548438, 41.130631],
							[22.547936, 41.130603],
							[22.547928, 41.130831],
							[22.547734, 41.130809],
							[22.54727, 41.130726],
							[22.547041, 41.130656],
							[22.546616, 41.130613],
							[22.546256, 41.130565],
							[22.545632, 41.130524],
							[22.544819, 41.1305],
							[22.544434, 41.13053],
							[22.544278, 41.130501],
							[22.544112, 41.13024],
							[22.54411, 41.130396],
							[22.544112, 41.130462],
							[22.544089, 41.130508],
							[22.544048, 41.130517],
							[22.543629, 41.130489],
							[22.543538, 41.130482],
							[22.542713, 41.13046],
							[22.541362, 41.130413],
							[22.541043, 41.130401],
							[22.540885, 41.130437],
							[22.540802, 41.130387],
							[22.540587, 41.130387],
							[22.540586, 41.130419],
							[22.540509, 41.130421],
							[22.540493, 41.130369],
							[22.540409, 41.130367],
							[22.54022, 41.130401],
							[22.540082, 41.130381],
							[22.539683, 41.130342],
							[22.539512, 41.1303],
							[22.539315, 41.130353],
							[22.539066, 41.130367],
							[22.538832, 41.130344],
							[22.538498, 41.130351],
							[22.538302, 41.130316],
							[22.538337, 41.130243],
							[22.538044, 41.130128],
							[22.537878, 41.130193],
							[22.537653, 41.130147],
							[22.537494, 41.130161],
							[22.537352, 41.1301],
							[22.53725, 41.129993],
							[22.537151, 41.130159],
							[22.536741, 41.130072],
							[22.536062, 41.130022],
							[22.536041, 41.130015],
							[22.534409, 41.129776],
							[22.53218, 41.129486],
							[22.528896, 41.128974],
							[22.527376, 41.128767],
							[22.525977, 41.128577],
							[22.524432, 41.128367],
							[22.521169, 41.127965],
							[22.519483, 41.127757],
							[22.51847, 41.127632],
							[22.517308, 41.127488],
							[22.51718, 41.127473],
							[22.516676, 41.127411],
							[22.515943, 41.127268],
							[22.514623, 41.127062],
							[22.512541, 41.126791],
							[22.511965, 41.126706],
							[22.511671, 41.126667],
							[22.511378, 41.126636],
							[22.511083, 41.126596],
							[22.510494, 41.126534],
							[22.509975, 41.126502],
							[22.509312, 41.12642],
							[22.50844, 41.126274],
							[22.50815, 41.126225],
							[22.507862, 41.126176],
							[22.507565, 41.126136],
							[22.507268, 41.126105],
							[22.506977, 41.126073],
							[22.5067, 41.12599],
							[22.506441, 41.125885],
							[22.506171, 41.125776],
							[22.505395, 41.125441],
							[22.505131, 41.125332],
							[22.504867, 41.125236],
							[22.504594, 41.125143],
							[22.504316, 41.125058],
							[22.504039, 41.124982],
							[22.503768, 41.124889],
							[22.503503, 41.124786],
							[22.503238, 41.124675],
							[22.502968, 41.124581],
							[22.502827, 41.124543],
							[22.502688, 41.124505],
							[22.502635, 41.124493],
							[22.502405, 41.124439],
							[22.502338, 41.124639],
							[22.501915, 41.124553],
							[22.501367, 41.124366],
							[22.500729, 41.124278],
							[22.50035, 41.124088],
							[22.500017, 41.124097],
							[22.499914, 41.124099],
							[22.49938, 41.124318],
							[22.499192, 41.124397],
							[22.498413, 41.124522],
							[22.497985, 41.124372],
							[22.497741, 41.124243],
							[22.497476, 41.12414],
							[22.497196, 41.124065],
							[22.496913, 41.123989],
							[22.496629, 41.123922],
							[22.496344, 41.123864],
							[22.496059, 41.123798],
							[22.495772, 41.123731],
							[22.495482, 41.123682],
							[22.495189, 41.123642],
							[22.494896, 41.123602],
							[22.494602, 41.123562],
							[22.494314, 41.123505],
							[22.494028, 41.123447],
							[22.493743, 41.12338],
							[22.493461, 41.123304],
							[22.493182, 41.123229],
							[22.492908, 41.123144],
							[22.492638, 41.123041],
							[22.492372, 41.122948],
							[22.492107, 41.122836],
							[22.491842, 41.122734],
							[22.491573, 41.12264],
							[22.4913, 41.122546],
							[22.491024, 41.122462],
							[22.490746, 41.122386],
							[22.490464, 41.12231],
							[22.49018, 41.122243],
							[22.489892, 41.122186],
							[22.4896, 41.122146],
							[22.489306, 41.122106],
							[22.489012, 41.122066],
							[22.488716, 41.122035],
							[22.48842, 41.122013],
							[22.488117, 41.122009],
							[22.487811, 41.122032],
							[22.487524, 41.12201],
							[22.487279, 41.121908],
							[22.487062, 41.121743],
							[22.486839, 41.121587],
							[22.486614, 41.12144],
							[22.486389, 41.121293],
							[22.486162, 41.121146],
							[22.485932, 41.120999],
							[22.485697, 41.12086],
							[22.485458, 41.120731],
							[22.485217, 41.120593],
							[22.484974, 41.120464],
							[22.484725, 41.120343],
							[22.484474, 41.120223],
							[22.484219, 41.120102],
							[22.483958, 41.119991],
							[22.483691, 41.119879],
							[22.483418, 41.119795],
							[22.483136, 41.119737],
							[22.48284, 41.119706],
							[22.482535, 41.119711],
							[22.482236, 41.119734],
							[22.481948, 41.119775],
							[22.481661, 41.119844],
							[22.481383, 41.11993],
							[22.481114, 41.120025],
							[22.480856, 41.120139],
							[22.480606, 41.120262],
							[22.480362, 41.120394],
							[22.480128, 41.120535],
							[22.479904, 41.120685],
							[22.47972, 41.12089],
							[22.47956, 41.121095],
							[22.479353, 41.121164],
							[22.479101, 41.121071],
							[22.478829, 41.120923],
							[22.478555, 41.120811],
							[22.478277, 41.120727],
							[22.477994, 41.12066],
							[22.477706, 41.120602],
							[22.477412, 41.120562],
							[22.477115, 41.12054],
							[22.47682, 41.120518],
							[22.476525, 41.120487],
							[22.47623, 41.120447],
							[22.475935, 41.120416],
							[22.475346, 41.120355],
							[22.47505, 41.120333],
							[22.474754, 41.120311],
							[22.474457, 41.120298],
							[22.47416, 41.120294],
							[22.473861, 41.120281],
							[22.473266, 41.120273],
							[22.472898, 41.120259],
							[22.472601, 41.120237],
							[22.472006, 41.120211],
							[22.471709, 41.120198],
							[22.470815, 41.120186],
							[22.470518, 41.120173],
							[22.470221, 41.12016],
							[22.469921, 41.120139],
							[22.469625, 41.120089],
							[22.469368, 41.119987],
							[22.469137, 41.119849],
							[22.46892, 41.119693],
							[22.468705, 41.119528],
							[22.468272, 41.119216],
							[22.468055, 41.119069],
							[22.467838, 41.118913],
							[22.467623, 41.118757],
							[22.467407, 41.118601],
							[22.467191, 41.118445],
							[22.466976, 41.118289],
							[22.466766, 41.118124],
							[22.466554, 41.117968],
							[22.466339, 41.117803],
							[22.466116, 41.117656],
							[22.465884, 41.117527],
							[22.465629, 41.117406],
							[22.465348, 41.117321],
							[22.465057, 41.117263],
							[22.464765, 41.117224],
							[22.464458, 41.117219],
							[22.464157, 41.117252],
							[22.463895, 41.117329],
							[22.463697, 41.117506],
							[22.463485, 41.117666],
							[22.46325, 41.117807],
							[22.463012, 41.117948],
							[22.462782, 41.118089],
							[22.46256, 41.118239],
							[22.462343, 41.118389],
							[22.462125, 41.118548],
							[22.4619, 41.11869],
							[22.461452, 41.11899],
							[22.461226, 41.11914],
							[22.460996, 41.119281],
							[22.46076, 41.119422],
							[22.460514, 41.119545],
							[22.460251, 41.119649],
							[22.459977, 41.119736],
							[22.459696, 41.119813],
							[22.45942, 41.1199],
							[22.459156, 41.120004],
							[22.4589, 41.120118],
							[22.458649, 41.12024],
							[22.458403, 41.120372],
							[22.458161, 41.120504],
							[22.457927, 41.120645],
							[22.457703, 41.120804],
							[22.457486, 41.12099],
							[22.457272, 41.121168],
							[22.457051, 41.121318],
							[22.45682, 41.121423],
							[22.456571, 41.121465],
							[22.456304, 41.121416],
							[22.456026, 41.121313],
							[22.455739, 41.121183],
							[22.455451, 41.12108],
							[22.455164, 41.121023],
							[22.45487, 41.120982],
							[22.454573, 41.120961],
							[22.454275, 41.120947],
							[22.453974, 41.120934],
							[22.453668, 41.120921],
							[22.453367, 41.120926],
							[22.453077, 41.120967],
							[22.452816, 41.121045],
							[22.452584, 41.121186],
							[22.45236, 41.121345],
							[22.452122, 41.121486],
							[22.451879, 41.121618],
							[22.451633, 41.12175],
							[22.45138, 41.121872],
							[22.451114, 41.121968],
							[22.450821, 41.122054],
							[22.450518, 41.122113],
							[22.450232, 41.122145],
							[22.449996, 41.122079],
							[22.449837, 41.121861],
							[22.4497, 41.121661],
							[22.449557, 41.12146],
							[22.449384, 41.121269],
							[22.449193, 41.121095],
							[22.448973, 41.120948],
							[22.448717, 41.120837],
							[22.448443, 41.120752],
							[22.448168, 41.120658],
							[22.447636, 41.120453],
							[22.447372, 41.12035],
							[22.447109, 41.120248],
							[22.446847, 41.120136],
							[22.446585, 41.120033],
							[22.446064, 41.11981],
							[22.445799, 41.119707],
							[22.445535, 41.119596],
							[22.44528, 41.119484],
							[22.445037, 41.119355],
							[22.444805, 41.119181],
							[22.44458, 41.118988],
							[22.444359, 41.118796],
							[22.44414, 41.11864],
							[22.443922, 41.118547],
							[22.443704, 41.118544],
							[22.44349, 41.11864],
							[22.443279, 41.118809],
							[22.443067, 41.119013],
							[22.442852, 41.119217],
							[22.442633, 41.119385],
							[22.442412, 41.119545],
							[22.442188, 41.119695],
							[22.44196, 41.119845],
							[22.441722, 41.119977],
							[22.441453, 41.120036],
							[22.441144, 41.120005],
							[22.440849, 41.119983],
							[22.440552, 41.119961],
							[22.440257, 41.11993],
							[22.43996, 41.119908],
							[22.439664, 41.119877],
							[22.439372, 41.119846],
							[22.439084, 41.119788],
							[22.438516, 41.119654],
							[22.438228, 41.119596],
							[22.437938, 41.119538],
							[22.437648, 41.119489],
							[22.437356, 41.119449],
							[22.437064, 41.1194],
							[22.43677, 41.11936],
							[22.436477, 41.11932],
							[22.436184, 41.119289],
							[22.435889, 41.119249],
							[22.435595, 41.119218],
							[22.435299, 41.119187],
							[22.434707, 41.119143],
							[22.43441, 41.11913],
							[22.434113, 41.119135],
							[22.433813, 41.11914],
							[22.433218, 41.11915],
							[22.432917, 41.119164],
							[22.432616, 41.119178],
							[22.432323, 41.11921],
							[22.432052, 41.119296],
							[22.431924, 41.119492],
							[22.43191, 41.119717],
							[22.431901, 41.119951],
							[22.431875, 41.120176],
							[22.431824, 41.120401],
							[22.43172, 41.120607],
							[22.431586, 41.120812],
							[22.431455, 41.121008],
							[22.431358, 41.121232],
							[22.431273, 41.121465],
							[22.431132, 41.121643],
							[22.430869, 41.121712],
							[22.430543, 41.121707],
							[22.430245, 41.121694],
							[22.42995, 41.121654],
							[22.429676, 41.121569],
							[22.429413, 41.121458],
							[22.429133, 41.121382],
							[22.428843, 41.121333],
							[22.428551, 41.121284],
							[22.428265, 41.121226],
							[22.427979, 41.121159],
							[22.427693, 41.121101],
							[22.427408, 41.121034],
							[22.427119, 41.120976],
							[22.426829, 41.120918],
							[22.426545, 41.120851],
							[22.426274, 41.120767],
							[22.426017, 41.120655],
							[22.425766, 41.120525],
							[22.425513, 41.120405],
							[22.425252, 41.120302],
							[22.42499, 41.12019],
							[22.424724, 41.120088],
							[22.424456, 41.119985],
							[22.424182, 41.1199],
							[22.423902, 41.119833],
							[22.423617, 41.119766],
							[22.423328, 41.119708],
							[22.423037, 41.119659],
							[22.422744, 41.119619],
							[22.422449, 41.119579],
							[22.422156, 41.119539],
							[22.421862, 41.119499],
							[22.421272, 41.119437],
							[22.420976, 41.119415],
							[22.420679, 41.119393],
							[22.420383, 41.119371],
							[22.420086, 41.119349],
							[22.419788, 41.119336],
							[22.419202, 41.119327],
							[22.418895, 41.119323],
							[22.418597, 41.119337],
							[22.418003, 41.119365],
							[22.417108, 41.119434],
							[22.416813, 41.119475],
							[22.416522, 41.119525],
							[22.41624, 41.119584],
							[22.415962, 41.119661],
							[22.415691, 41.119766],
							[22.415432, 41.119879],
							[22.415192, 41.12002],
							[22.414976, 41.12017],
							[22.414794, 41.120348],
							[22.414641, 41.120544],
							[22.414512, 41.120749],
							[22.414435, 41.120964],
							[22.41438, 41.121189],
							[22.4143, 41.121404],
							[22.414218, 41.121628],
							[22.414139, 41.121843],
							[22.414052, 41.122067],
							[22.413949, 41.122273],
							[22.413821, 41.122478],
							[22.413669, 41.122665],
							[22.413505, 41.122852],
							[22.413334, 41.12303],
							[22.413157, 41.123217],
							[22.412974, 41.123394],
							[22.412786, 41.123572],
							[22.412595, 41.12374],
							[22.412402, 41.123918],
							[22.412208, 41.124086],
							[22.412013, 41.124264],
							[22.411819, 41.124432],
							[22.411625, 41.124601],
							[22.411423, 41.124769],
							[22.411215, 41.124928],
							[22.411001, 41.125088],
							[22.410783, 41.125238],
							[22.410557, 41.125388],
							[22.410327, 41.125529],
							[22.410087, 41.12567],
							[22.409842, 41.125792],
							[22.409591, 41.125915],
							[22.409334, 41.126028],
							[22.409067, 41.126133],
							[22.408794, 41.126219],
							[22.408514, 41.126296],
							[22.408231, 41.126373],
							[22.407947, 41.126442],
							[22.407655, 41.126492],
							[22.407361, 41.126533],
							[22.407066, 41.126573],
							[22.406775, 41.126624],
							[22.40649, 41.126683],
							[22.406215, 41.126769],
							[22.405953, 41.126864],
							[22.405703, 41.126987],
							[22.40546, 41.127128],
							[22.405221, 41.12726],
							[22.404981, 41.1274],
							[22.404742, 41.127532],
							[22.404504, 41.127664],
							[22.404266, 41.127805],
							[22.404031, 41.127937],
							[22.403794, 41.128078],
							[22.40356, 41.128218],
							[22.403325, 41.12835],
							[22.403091, 41.128491],
							[22.402857, 41.128632],
							[22.402624, 41.128773],
							[22.402391, 41.128914],
							[22.402147, 41.129055],
							[22.401902, 41.129195],
							[22.401672, 41.129336],
							[22.401473, 41.129505],
							[22.401333, 41.129683],
							[22.401288, 41.129907],
							[22.401299, 41.130151],
							[22.401331, 41.130367],
							[22.40139, 41.130593],
							[22.401462, 41.130811],
							[22.401527, 41.131028],
							[22.401573, 41.131253],
							[22.401611, 41.131479],
							[22.401646, 41.131705],
							[22.401682, 41.131921],
							[22.401721, 41.132147],
							[22.401767, 41.132373],
							[22.401824, 41.13259],
							[22.401885, 41.132816],
							[22.401951, 41.133033],
							[22.402023, 41.13325],
							[22.402103, 41.133468],
							[22.402189, 41.133685],
							[22.402284, 41.133893],
							[22.402393, 41.134102],
							[22.40252, 41.134311],
							[22.402662, 41.134511],
							[22.402818, 41.134703],
							[22.402986, 41.134885],
							[22.403162, 41.135068],
							[22.403346, 41.13525],
							[22.403537, 41.135424],
							[22.403733, 41.135589],
							[22.403937, 41.135754],
							[22.404156, 41.13591],
							[22.404428, 41.136058],
							[22.404697, 41.136188],
							[22.404904, 41.136317],
							[22.4049, 41.13647],
							[22.404603, 41.136628],
							[22.404344, 41.136741],
							[22.404078, 41.136837],
							[22.403807, 41.136932],
							[22.403531, 41.137018],
							[22.403252, 41.137104],
							[22.402974, 41.137182],
							[22.402695, 41.137259],
							[22.402414, 41.137336],
							[22.402131, 41.137413],
							[22.401848, 41.137481],
							[22.401562, 41.13754],
							[22.401274, 41.1376],
							[22.400986, 41.13765],
							[22.400694, 41.13769],
							[22.400399, 41.137732],
							[22.400104, 41.137763],
							[22.399509, 41.137809],
							[22.399212, 41.137823],
							[22.398915, 41.137846],
							[22.398617, 41.13786],
							[22.398317, 41.137883],
							[22.397716, 41.13791],
							[22.397418, 41.137915],
							[22.396825, 41.137907],
							[22.396533, 41.137885],
							[22.396245, 41.137836],
							[22.395962, 41.137778],
							[22.395682, 41.137693],
							[22.395405, 41.137599],
							[22.394857, 41.137411],
							[22.394585, 41.137326],
							[22.394316, 41.137223],
							[22.394048, 41.13713],
							[22.39378, 41.137027],
							[22.393513, 41.136933],
							[22.393247, 41.13683],
							[22.392981, 41.136727],
							[22.392712, 41.136625],
							[22.392436, 41.13654],
							[22.392161, 41.136446],
							[22.391907, 41.136325],
							[22.391698, 41.136178],
							[22.39154, 41.135987],
							[22.391406, 41.135778],
							[22.391285, 41.135569],
							[22.391174, 41.13536],
							[22.391066, 41.135151],
							[22.390949, 41.134943],
							[22.390825, 41.134743],
							[22.390698, 41.134534],
							[22.390567, 41.134334],
							[22.39043, 41.134134],
							[22.390285, 41.133943],
							[22.390138, 41.133742],
							[22.389989, 41.133542],
							[22.389837, 41.133351],
							[22.389682, 41.133159],
							[22.389523, 41.132968],
							[22.38936, 41.132777],
							[22.389191, 41.132594],
							[22.388962, 41.132438],
							[22.38869, 41.132389],
							[22.388394, 41.13243],
							[22.388097, 41.132489],
							[22.387812, 41.132557],
							[22.387533, 41.132643],
							[22.387256, 41.13272],
							[22.386974, 41.132797],
							[22.386687, 41.132856],
							[22.386398, 41.132915],
							[22.386109, 41.132965],
							[22.385818, 41.133015],
							[22.385526, 41.133065],
							[22.385235, 41.133106],
							[22.384649, 41.133188],
							[22.384352, 41.133202],
							[22.384053, 41.133198],
							[22.383754, 41.133184],
							[22.38346, 41.133153],
							[22.383169, 41.133113],
							[22.382878, 41.133055],
							[22.382589, 41.132997],
							[22.382297, 41.132957],
							[22.381994, 41.132926],
							[22.381673, 41.132876],
							[22.381355, 41.132845],
							[22.381057, 41.13284],
							[22.380798, 41.1329],
							[22.380595, 41.133014],
							[22.380435, 41.133192],
							[22.380299, 41.133406],
							[22.380172, 41.133639],
							[22.380035, 41.133853],
							[22.379879, 41.13404],
							[22.379721, 41.134236],
							[22.379556, 41.134423],
							[22.379384, 41.134609],
							[22.379199, 41.134778],
							[22.379006, 41.134955],
							[22.378803, 41.135124],
							[22.378587, 41.135283],
							[22.378356, 41.135424],
							[22.378104, 41.135528],
							[22.37783, 41.135614],
							[22.377544, 41.135691],
							[22.377257, 41.135759],
							[22.376977, 41.135837],
							[22.376701, 41.135923],
							[22.37642, 41.136009],
							[22.376142, 41.136104],
							[22.375873, 41.136199],
							[22.375617, 41.136304],
							[22.375386, 41.136445],
							[22.37519, 41.136613],
							[22.375011, 41.136791],
							[22.374821, 41.136968],
							[22.374607, 41.137127],
							[22.374389, 41.137286],
							[22.374165, 41.137445],
							[22.373935, 41.137586],
							[22.373698, 41.137718],
							[22.373438, 41.137795],
							[22.373127, 41.137764],
							[22.372828, 41.137769],
							[22.37253, 41.137755],
							[22.372238, 41.137715],
							[22.371952, 41.137648],
							[22.371657, 41.137626],
							[22.371359, 41.137613],
							[22.37106, 41.137617],
							[22.370763, 41.137631],
							[22.370468, 41.137654],
							[22.370172, 41.137695],
							[22.369877, 41.137727],
							[22.369582, 41.137759],
							[22.369287, 41.137782],
							[22.368985, 41.137786],
							[22.368684, 41.137764],
							[22.368669, 41.137645],
							[22.368589, 41.137567],
							[22.368392, 41.137341],
							[22.368114, 41.136976],
							[22.367937, 41.136662],
							[22.367867, 41.136513],
							[22.367637, 41.136291],
							[22.367441, 41.136011],
							[22.367262, 41.13579],
							[22.366998, 41.135639],
							[22.366804, 41.135548],
							[22.366651, 41.135458],
							[22.366458, 41.135353],
							[22.366215, 41.135306],
							[22.366073, 41.135115],
							[22.365919, 41.134915],
							[22.365752, 41.134732],
							[22.365571, 41.134558],
							[22.365381, 41.134384],
							[22.365185, 41.134211],
							[22.364985, 41.134045],
							[22.364787, 41.133872],
							[22.364594, 41.133707],
							[22.364371, 41.133343],
							[22.364254, 41.133152],
							[22.364111, 41.133042],
							[22.363922, 41.132841],
							[22.363708, 41.132631],
							[22.363488, 41.132448],
							[22.36321, 41.13239],
							[22.36292, 41.132458],
							[22.362667, 41.132571],
							[22.362421, 41.132703],
							[22.362173, 41.132835],
							[22.361919, 41.132957],
							[22.361662, 41.133071],
							[22.361405, 41.133184],
							[22.361147, 41.133297],
							[22.360884, 41.133402],
							[22.360618, 41.133506],
							[22.36008, 41.133697],
							[22.359809, 41.133792],
							[22.359538, 41.133887],
							[22.359266, 41.133973],
							[22.358993, 41.134068],
							[22.358721, 41.134164],
							[22.358447, 41.13425],
							[22.358172, 41.134336],
							[22.35762, 41.134508],
							[22.357341, 41.134585],
							[22.357063, 41.134662],
							[22.356778, 41.134721],
							[22.356487, 41.134771],
							[22.356192, 41.134803],
							[22.355896, 41.134835],
							[22.355601, 41.134876],
							[22.355311, 41.134926],
							[22.355025, 41.134994],
							[22.35474, 41.135062],
							[22.354457, 41.13513],
							[22.35389, 41.135266],
							[22.353603, 41.135334],
							[22.353315, 41.135384],
							[22.353023, 41.135424],
							[22.352729, 41.135456],
							[22.352432, 41.135479],
							[22.351835, 41.135506],
							[22.351537, 41.135511],
							[22.351239, 41.135525],
							[22.350938, 41.135539],
							[22.350636, 41.135552],
							[22.350333, 41.135557],
							[22.350032, 41.135562],
							[22.349736, 41.135548],
							[22.349446, 41.135526],
							[22.349171, 41.135468],
							[22.348918, 41.135357],
							[22.348676, 41.135209],
							[22.348432, 41.13507],
							[22.348585, 41.134712],
							[22.348652, 41.134488],
							[22.348717, 41.134273],
							[22.348793, 41.134049],
							[22.348923, 41.133825],
							[22.348901, 41.133636],
							[22.348645, 41.133488],
							[22.348361, 41.13343],
							[22.348066, 41.133399],
							[22.347765, 41.133386],
							[22.347464, 41.133372],
							[22.34687, 41.133364],
							[22.346572, 41.133368],
							[22.346273, 41.133373],
							[22.345975, 41.133378],
							[22.345678, 41.133373],
							[22.345381, 41.13336],
							[22.345083, 41.133338],
							[22.344787, 41.133325],
							[22.3448, 41.132892],
							[22.344755, 41.132667],
							[22.34467, 41.132449],
							[22.344593, 41.132232],
							[22.344549, 41.132015],
							[22.344526, 41.131781],
							[22.344501, 41.131555],
							[22.344463, 41.131338],
							[22.344391, 41.131121],
							[22.344266, 41.130921],
							[22.344102, 41.130721],
							[22.343919, 41.130547],
							[22.343722, 41.130373],
							[22.343506, 41.130208],
							[22.343271, 41.130078],
							[22.34299, 41.130029],
							[22.342684, 41.13007],
							[22.342404, 41.130147],
							[22.342136, 41.130251],
							[22.341883, 41.130373],
							[22.341637, 41.130496],
							[22.341401, 41.130637],
							[22.341178, 41.130787],
							[22.341006, 41.130982],
							[22.340739, 41.131024],
							[22.340438, 41.130965],
							[22.340169, 41.130871],
							[22.339917, 41.13075],
							[22.339676, 41.130612],
							[22.339207, 41.130335],
							[22.338976, 41.130196],
							[22.338746, 41.130049],
							[22.338517, 41.129911],
							[22.338285, 41.129763],
							[22.338049, 41.129625],
							[22.337804, 41.129504],
							[22.337554, 41.129374],
							[22.3373, 41.129263],
							[22.337049, 41.129133],
							[22.336805, 41.129012],
							[22.336576, 41.128865],
							[22.336374, 41.128682],
							[22.336179, 41.128499],
							[22.335965, 41.12837],
							[22.335697, 41.128357],
							[22.335395, 41.128451],
							[22.335129, 41.128556],
							[22.334886, 41.128696],
							[22.334631, 41.12881],
							[22.334339, 41.12885],
							[22.33403, 41.128846],
							[22.333759, 41.128779],
							[22.333547, 41.128605],
							[22.333468, 41.128397],
							[22.333441, 41.128162],
							[22.333336, 41.127953],
							[22.333197, 41.127753],
							[22.333003, 41.127561],
							[22.33279, 41.127378],
							[22.332625, 41.127195],
							[22.332586, 41.127024],
							[22.332804, 41.126865],
							[22.333051, 41.126697],
							[22.333241, 41.12652],
							[22.333436, 41.126342],
							[22.333623, 41.126156],
							[22.33379, 41.125969],
							[22.333922, 41.125782],
							[22.333939, 41.125566],
							[22.333859, 41.12534],
							[22.333822, 41.125114],
							[22.333794, 41.124888],
							[22.333775, 41.124663],
							[22.333762, 41.124437],
							[22.333756, 41.124212],
							[22.333774, 41.123987],
							[22.33382, 41.123763],
							[22.333876, 41.123547],
							[22.333941, 41.123323],
							[22.334012, 41.123108],
							[22.334087, 41.122884],
							[22.334172, 41.122669],
							[22.334272, 41.122454],
							[22.334376, 41.12224],
							[22.334475, 41.122025],
							[22.334561, 41.12181],
							[22.334623, 41.121595],
							[22.334644, 41.121379],
							[22.334576, 41.121161],
							[22.334459, 41.120944],
							[22.334349, 41.120726],
							[22.333916, 41.120693],
							[22.333624, 41.120715],
							[22.333336, 41.120765],
							[22.333046, 41.120824],
							[22.332755, 41.120874],
							[22.33246, 41.120906],
							[22.332163, 41.120919],
							[22.331866, 41.120933],
							[22.331569, 41.120956],
							[22.331273, 41.120984],
							[22.330983, 41.121019],
							[22.330681, 41.121051],
							[22.330388, 41.121083],
							[22.330095, 41.121124],
							[22.329805, 41.121173],
							[22.329518, 41.121232],
							[22.329235, 41.1213],
							[22.328954, 41.121377],
							[22.328677, 41.121463],
							[22.328402, 41.12155],
							[22.328128, 41.121636],
							[22.327845, 41.12174],
							[22.327587, 41.121853],
							[22.327403, 41.122012],
							[22.327329, 41.122236],
							[22.32732, 41.122471],
							[22.327345, 41.122687],
							[22.327391, 41.122913],
							[22.327437, 41.123139],
							[22.327467, 41.123364],
							[22.327496, 41.12359],
							[22.327503, 41.123824],
							[22.327443, 41.124031],
							[22.327282, 41.124218],
							[22.32707, 41.124386],
							[22.326881, 41.124563],
							[22.326709, 41.12475],
							[22.326547, 41.124936],
							[22.32642, 41.125142],
							[22.32634, 41.125357],
							[22.326276, 41.125572],
							[22.326225, 41.125796],
							[22.32618, 41.126021],
							[22.326099, 41.12647],
							[22.326057, 41.126695],
							[22.326008, 41.126919],
							[22.325947, 41.127134],
							[22.325866, 41.127358],
							[22.325782, 41.127583],
							[22.325709, 41.127798],
							[22.325663, 41.128013],
							[22.325659, 41.128238],
							[22.325727, 41.128446],
							[22.325842, 41.128664],
							[22.325968, 41.128873],
							[22.326076, 41.129082],
							[22.326183, 41.129291],
							[22.32629, 41.129508],
							[22.326399, 41.129717],
							[22.326507, 41.129926],
							[22.326613, 41.130135],
							[22.326714, 41.130343],
							[22.326807, 41.130561],
							[22.326893, 41.130778],
							[22.326973, 41.130996],
							[22.327051, 41.131213],
							[22.327205, 41.131647],
							[22.327291, 41.131865],
							[22.327383, 41.132082],
							[22.327466, 41.1323],
							[22.327525, 41.132517],
							[22.32753, 41.132733],
							[22.327441, 41.132957],
							[22.327307, 41.133153],
							[22.327105, 41.133321],
							[22.326902, 41.13349],
							[22.326697, 41.133658],
							[22.326514, 41.133835],
							[22.326361, 41.134031],
							[22.326246, 41.134237],
							[22.326174, 41.134452],
							[22.326121, 41.134676],
							[22.32606, 41.134901],
							[22.326003, 41.135116],
							[22.325963, 41.13534],
							[22.325958, 41.135566],
							[22.325977, 41.135791],
							[22.326006, 41.136017],
							[22.326032, 41.136242],
							[22.326042, 41.136468],
							[22.326023, 41.136693],
							[22.325984, 41.136908],
							[22.325932, 41.137132],
							[22.325879, 41.137357],
							[22.325833, 41.137582],
							[22.325797, 41.137806],
							[22.325766, 41.138022],
							[22.325734, 41.138247],
							[22.325698, 41.138471],
							[22.325653, 41.138696],
							[22.325589, 41.138911],
							[22.325513, 41.139135],
							[22.325437, 41.13935],
							[22.325378, 41.139575],
							[22.325356, 41.139808],
							[22.325278, 41.140014],
							[22.325087, 41.140183],
							[22.324844, 41.140323],
							[22.324601, 41.140455],
							[22.324349, 41.140577],
							[22.324099, 41.1407],
							[22.323863, 41.140841],
							[22.323629, 41.140981],
							[22.3234, 41.141122],
							[22.323186, 41.141281],
							[22.322997, 41.141449],
							[22.32283, 41.141636],
							[22.32268, 41.141832],
							[22.322538, 41.142028],
							[22.3224, 41.142233],
							[22.322262, 41.142429],
							[22.322118, 41.142635],
							[22.321967, 41.142831],
							[22.321814, 41.143026],
							[22.321664, 41.143223],
							[22.321521, 41.143419],
							[22.321389, 41.143624],
							[22.321275, 41.143829],
							[22.321182, 41.144035],
							[22.321107, 41.14425],
							[22.321047, 41.144475],
							[22.321001, 41.144699],
							[22.320965, 41.144924],
							[22.32094, 41.145148],
							[22.320924, 41.145373],
							[22.320914, 41.145598],
							[22.320911, 41.145824],
							[22.320914, 41.146049],
							[22.320925, 41.146283],
							[22.320944, 41.146509],
							[22.320973, 41.146734],
							[22.321012, 41.14696],
							[22.321064, 41.147177],
							[22.321127, 41.147394],
							[22.321211, 41.147611],
							[22.321329, 41.147811],
							[22.32147, 41.148012],
							[22.321626, 41.148212],
							[22.321783, 41.148404],
							[22.321931, 41.148604],
							[22.322069, 41.148804],
							[22.322206, 41.148995],
							[22.322481, 41.149395],
							[22.32262, 41.149596],
							[22.322762, 41.149796],
							[22.322909, 41.149996],
							[22.323067, 41.150188],
							[22.323229, 41.150379],
							[22.323384, 41.150571],
							[22.323522, 41.150762],
							[22.323644, 41.150989],
							[22.323745, 41.151278],
							[22.323748, 41.151468],
							[22.323599, 41.151447],
							[22.323369, 41.151318],
							[22.323093, 41.151152],
							[22.322813, 41.150986],
							[22.322549, 41.150874],
							[22.322281, 41.15078],
							[22.322008, 41.150686],
							[22.321731, 41.150601],
							[22.321454, 41.150524],
							[22.3209, 41.150354],
							[22.320622, 41.150269],
							[22.320342, 41.150193],
							[22.32006, 41.150117],
							[22.319776, 41.150059],
							[22.319487, 41.15],
							[22.319194, 41.14996],
							[22.318899, 41.14992],
							[22.318603, 41.149888],
							[22.318308, 41.149857],
							[22.318016, 41.149808],
							[22.317729, 41.149758],
							[22.317451, 41.149673],
							[22.317187, 41.149561],
							[22.316941, 41.149441],
							[22.316723, 41.149284],
							[22.316514, 41.149128],
							[22.31631, 41.148963],
							[22.316111, 41.148789],
							[22.315918, 41.148624],
							[22.315734, 41.148441],
							[22.315554, 41.148268],
							[22.315377, 41.148085],
							[22.315194, 41.147902],
							[22.315005, 41.147737],
							[22.314809, 41.147563],
							[22.314608, 41.147398],
							[22.314401, 41.147233],
							[22.314182, 41.147076],
							[22.313948, 41.146938],
							[22.313692, 41.146817],
							[22.313422, 41.146723],
							[22.313133, 41.146692],
							[22.312832, 41.146678],
							[22.31253, 41.146692],
							[22.312232, 41.146697],
							[22.311936, 41.146719],
							[22.311638, 41.146742],
							[22.311342, 41.146747],
							[22.311041, 41.146742],
							[22.310745, 41.14672],
							[22.310461, 41.146653],
							[22.310186, 41.146568],
							[22.309919, 41.146465],
							[22.309661, 41.146353],
							[22.309409, 41.146223],
							[22.309156, 41.146102],
							[22.308895, 41.145999],
							[22.308624, 41.145914],
							[22.308339, 41.145856],
							[22.308047, 41.145807],
							[22.30775, 41.145775],
							[22.307452, 41.145744],
							[22.307157, 41.145712],
							[22.306861, 41.145681],
							[22.306563, 41.145668],
							[22.306265, 41.145672],
							[22.305966, 41.145668],
							[22.305667, 41.145682],
							[22.305372, 41.145704],
							[22.305081, 41.145763],
							[22.304807, 41.145858],
							[22.304583, 41.145999],
							[22.304395, 41.146176],
							[22.30422, 41.146363],
							[22.304058, 41.146549],
							[22.30389, 41.146736],
							[22.303712, 41.146914],
							[22.30353, 41.147091],
							[22.303346, 41.147269],
							[22.30316, 41.147446],
							[22.302972, 41.147623],
							[22.302787, 41.147801],
							[22.302605, 41.147978],
							[22.302418, 41.148156],
							[22.302227, 41.148324],
							[22.302025, 41.148492],
							[22.30181, 41.148642],
							[22.301587, 41.148792],
							[22.301356, 41.148933],
							[22.30112, 41.149073],
							[22.300879, 41.149205],
							[22.300636, 41.149336],
							[22.300392, 41.149468],
							[22.300148, 41.149599],
							[22.299899, 41.149722],
							[22.299647, 41.149844],
							[22.29939, 41.149958],
							[22.299128, 41.150062],
							[22.298858, 41.150157],
							[22.298587, 41.150252],
							[22.298313, 41.150338],
							[22.298035, 41.150424],
							[22.297757, 41.15051],
							[22.297476, 41.150587],
							[22.297194, 41.150655],
							[22.296911, 41.150732],
							[22.296627, 41.15079],
							[22.296339, 41.150849],
							[22.296048, 41.15089],
							[22.29575, 41.150922],
							[22.295452, 41.150944],
							[22.295156, 41.150976],
							[22.294863, 41.151007],
							[22.294573, 41.151057],
							[22.294284, 41.151116],
							[22.293997, 41.151184],
							[22.293716, 41.151261],
							[22.293442, 41.151347],
							[22.293175, 41.151442],
							[22.292912, 41.151546],
							[22.29265, 41.151659],
							[22.292391, 41.151773],
							[22.292133, 41.151877],
							[22.291875, 41.15199],
							[22.291616, 41.152103],
							[22.291355, 41.152217],
							[22.29109, 41.152321],
							[22.290824, 41.152416],
							[22.290555, 41.152511],
							[22.290283, 41.152606],
							[22.29001, 41.152701],
							[22.289736, 41.152787],
							[22.289462, 41.152873],
							[22.289184, 41.152959],
							[22.288906, 41.153036],
							[22.288625, 41.153113],
							[22.288344, 41.15319],
							[22.288064, 41.153267],
							[22.287786, 41.153352],
							[22.287511, 41.153439],
							[22.287238, 41.153525],
							[22.286972, 41.15362],
							[22.286714, 41.153742],
							[22.286462, 41.153864],
							[22.28621, 41.153987],
							[22.285957, 41.154109],
							[22.285698, 41.154213],
							[22.28543, 41.154308],
							[22.285148, 41.154376],
							[22.284857, 41.154426],
							[22.284748, 41.154441],
							[22.28456, 41.154466],
							[22.284263, 41.154498],
							[22.283967, 41.154521],
							[22.28367, 41.154525],
							[22.283072, 41.154516],
							[22.282774, 41.154503],
							[22.282174, 41.154494],
							[22.281874, 41.15448],
							[22.281581, 41.154449],
							[22.281301, 41.154391],
							[22.28103, 41.154297],
							[22.280765, 41.154185],
							[22.280502, 41.154082],
							[22.280243, 41.15397],
							[22.279992, 41.15384],
							[22.279733, 41.153737],
							[22.279453, 41.153652],
							[22.279162, 41.153593],
							[22.278862, 41.153589],
							[22.278581, 41.153666],
							[22.278379, 41.153825],
							[22.278199, 41.154011],
							[22.278008, 41.154189],
							[22.277785, 41.154338],
							[22.27755, 41.154479],
							[22.277306, 41.15461],
							[22.277053, 41.154724],
							[22.276784, 41.15481],
							[22.276482, 41.154841],
							[22.276189, 41.154882],
							[22.275894, 41.154941],
							[22.275612, 41.155008],
							[22.275356, 41.155122],
							[22.275112, 41.155253],
							[22.274883, 41.155403],
							[22.274676, 41.155562],
							[22.274502, 41.155739],
							[22.274365, 41.155944],
							[22.274253, 41.15615],
							[22.274156, 41.156365],
							[22.274063, 41.156579],
							[22.273972, 41.156794],
							[22.273884, 41.157009],
							[22.273799, 41.157233],
							[22.273719, 41.157448],
							[22.273645, 41.157663],
							[22.273576, 41.157887],
							[22.273513, 41.158102],
							[22.273453, 41.158327],
							[22.2734, 41.158551],
							[22.273354, 41.158776],
							[22.27332, 41.159],
							[22.2733, 41.159225],
							[22.273297, 41.159441],
							[22.273331, 41.159667],
							[22.273408, 41.159884],
							[22.273498, 41.160102],
							[22.273572, 41.160328],
							[22.273605, 41.160545],
							[22.273591, 41.16077],
							[22.273546, 41.160994],
							[22.27348, 41.16121],
							[22.273401, 41.161434],
							[22.273321, 41.161648],
							[22.273233, 41.161872],
							[22.27313, 41.162087],
							[22.273004, 41.162292],
							[22.272844, 41.16247],
							[22.272625, 41.162629],
							[22.272372, 41.162751],
							[22.272108, 41.162855],
							[22.271836, 41.16295],
							[22.271556, 41.163027],
							[22.271273, 41.163104],
							[22.270989, 41.163172],
							[22.270706, 41.16324],
							[22.270424, 41.163317],
							[22.270141, 41.163384],
							[22.269858, 41.163461],
							[22.269574, 41.163529],
							[22.26929, 41.163597],
							[22.269004, 41.163664],
							[22.268719, 41.163723],
							[22.268431, 41.163791],
							[22.268144, 41.163841],
							[22.267853, 41.16389],
							[22.26756, 41.16394],
							[22.267264, 41.163981],
							[22.266968, 41.164021],
							[22.266671, 41.164053],
							[22.266375, 41.164075],
							[22.266081, 41.164089],
							[22.265788, 41.164093],
							[22.265502, 41.164044],
							[22.26522, 41.16395],
							[22.264933, 41.163891],
							[22.264638, 41.16386],
							[22.264039, 41.163851],
							[22.263741, 41.163864],
							[22.263444, 41.163878],
							[22.263147, 41.163919],
							[22.262862, 41.163986],
							[22.262597, 41.16409],
							[22.262362, 41.164231],
							[22.262178, 41.164408],
							[22.262039, 41.164604],
							[22.261921, 41.164819],
							[22.261817, 41.165033],
							[22.26172, 41.165248],
							[22.261623, 41.165463],
							[22.261515, 41.165677],
							[22.261388, 41.165865],
							[22.261132, 41.165969],
							[22.260827, 41.165991],
							[22.26053, 41.165978],
							[22.260234, 41.165946],
							[22.25994, 41.165906],
							[22.259648, 41.165865],
							[22.259356, 41.165816],
							[22.258485, 41.165641],
							[22.258194, 41.1656],
							[22.257902, 41.165569],
							[22.257609, 41.165564],
							[22.257314, 41.165596],
							[22.257019, 41.165645],
							[22.256724, 41.165695],
							[22.25643, 41.165727],
							[22.255844, 41.165808],
							[22.255549, 41.165848],
							[22.255254, 41.16588],
							[22.254957, 41.165911],
							[22.254657, 41.165943],
							[22.254359, 41.165956],
							[22.254068, 41.165943],
							[22.253786, 41.165876],
							[22.253508, 41.16579],
							[22.253231, 41.165696],
							[22.252951, 41.165611],
							[22.252673, 41.165526],
							[22.252396, 41.165431],
							[22.252115, 41.165355],
							[22.251832, 41.165306],
							[22.251543, 41.165274],
							[22.251248, 41.165288],
							[22.250951, 41.165319],
							[22.250652, 41.16536],
							[22.250354, 41.1654],
							[22.249374, 41.165178],
							[22.248605, 41.165004],
							[22.248221, 41.164863],
							[22.248022, 41.164698],
							[22.247824, 41.164524],
							[22.24758, 41.164394],
							[22.247323, 41.164255],
							[22.24707, 41.164287],
							[22.246871, 41.164473],
							[22.246754, 41.164679],
							[22.246582, 41.164865],
							[22.246384, 41.165034],
							[22.246205, 41.165211],
							[22.246048, 41.165407],
							[22.245895, 41.165603],
							[22.245734, 41.165789],
							[22.245546, 41.165958],
							[22.245265, 41.166052],
							[22.244971, 41.166057],
							[22.244671, 41.166034],
							[22.24438, 41.165985],
							[22.244101, 41.1659],
							[22.243824, 41.165814],
							[22.243539, 41.165747],
							[22.243252, 41.165679],
							[22.242985, 41.165585],
							[22.242743, 41.165456],
							[22.242512, 41.165317],
							[22.242283, 41.165169],
							[22.24205, 41.165031],
							[22.241808, 41.164892],
							[22.241566, 41.164762],
							[22.241332, 41.164623],
							[22.241105, 41.164476],
							[22.240878, 41.164328],
							[22.240644, 41.164189],
							[22.240405, 41.16406],
							[22.240161, 41.16393],
							[22.239914, 41.1638],
							[22.239669, 41.163679],
							[22.239429, 41.16354],
							[22.239191, 41.163402],
							[22.238953, 41.163263],
							[22.238708, 41.163133],
							[22.238453, 41.163021],
							[22.238183, 41.162936],
							[22.237896, 41.162868],
							[22.237598, 41.162819],
							[22.237297, 41.162796],
							[22.237001, 41.162783],
							[22.236707, 41.162805],
							[22.236419, 41.162891],
							[22.236173, 41.163013],
							[22.235976, 41.163181],
							[22.235785, 41.163359],
							[22.235588, 41.163527],
							[22.235386, 41.163695],
							[22.235163, 41.163844],
							[22.234929, 41.163976],
							[22.234693, 41.164116],
							[22.234465, 41.164266],
							[22.23425, 41.164425],
							[22.23401, 41.164556],
							[22.233741, 41.164642],
							[22.233176, 41.164796],
							[22.232896, 41.164872],
							[22.232612, 41.164949],
							[22.232322, 41.164999],
							[22.232028, 41.165021],
							[22.231731, 41.165044],
							[22.231434, 41.165057],
							[22.231134, 41.165071],
							[22.230837, 41.165084],
							[22.230539, 41.165107],
							[22.23024, 41.16512],
							[22.229943, 41.165133],
							[22.229645, 41.165129],
							[22.229349, 41.165115],
							[22.229054, 41.165093],
							[22.228758, 41.16507],
							[22.228462, 41.165039],
							[22.228168, 41.164998],
							[22.227875, 41.164948],
							[22.227583, 41.164908],
							[22.227293, 41.164858],
							[22.227007, 41.164791],
							[22.226723, 41.164715],
							[22.22644, 41.164647],
							[22.226157, 41.164571],
							[22.225873, 41.164503],
							[22.225584, 41.164463],
							[22.225286, 41.16444],
							[22.224984, 41.164445],
							[22.224688, 41.164467],
							[22.224401, 41.164526],
							[22.224123, 41.164611],
							[22.223858, 41.164715],
							[22.2236, 41.164828],
							[22.223347, 41.164942],
							[22.223097, 41.165073],
							[22.222848, 41.165195],
							[22.222102, 41.165562],
							[22.221854, 41.165693],
							[22.221607, 41.165816],
							[22.221361, 41.165947],
							[22.221115, 41.166069],
							[22.22087, 41.166201],
							[22.220629, 41.166332],
							[22.220391, 41.166463],
							[22.220152, 41.166604],
							[22.21991, 41.166735],
							[22.219664, 41.166857],
							[22.21941, 41.16698],
							[22.21915, 41.167084],
							[22.218886, 41.167197],
							[22.218627, 41.167301],
							[22.218373, 41.167423],
							[22.217889, 41.167686],
							[22.217649, 41.167826],
							[22.217417, 41.167967],
							[22.217193, 41.168116],
							[22.216983, 41.168275],
							[22.216788, 41.168443],
							[22.216622, 41.16863],
							[22.216479, 41.168826],
							[22.216343, 41.169031],
							[22.216207, 41.169236],
							[22.216054, 41.169423],
							[22.215874, 41.169609],
							[22.21566, 41.169768],
							[22.215418, 41.169899],
							[22.215142, 41.169976],
							[22.214834, 41.170007],
							[22.214546, 41.169985],
							[22.214357, 41.169802],
							[22.214187, 41.169619],
							[22.214009, 41.169436],
							[22.213786, 41.169288],
							[22.213562, 41.169141],
							[22.213336, 41.168993],
							[22.213097, 41.168854],
							[22.212848, 41.168733],
							[22.212602, 41.168604],
							[22.212361, 41.168474],
							[22.212121, 41.168344],
							[22.211882, 41.168205],
							[22.211639, 41.168075],
							[22.211389, 41.167954],
							[22.21113, 41.167842],
							[22.210863, 41.167739],
							[22.210598, 41.167636],
							[22.21034, 41.167524],
							[22.210079, 41.16742],
							[22.209812, 41.167317],
							[22.209539, 41.167223],
							[22.20927, 41.167129],
							[22.209012, 41.167017],
							[22.208765, 41.166886],
							[22.208531, 41.166748],
							[22.208315, 41.166591],
							[22.208117, 41.166426],
							[22.207928, 41.166252],
							[22.207743, 41.166078],
							[22.207559, 41.165895],
							[22.207373, 41.165721],
							[22.207113, 41.165393],
							[22.206961, 41.165201],
							[22.206804, 41.165009],
							[22.20664, 41.164818],
							[22.206464, 41.164635],
							[22.206274, 41.164461],
							[22.20608, 41.164287],
							[22.205889, 41.164113],
							[22.20571, 41.163939],
							[22.205552, 41.163747],
							[22.205427, 41.163547],
							[22.205334, 41.163329],
							[22.205267, 41.163103],
							[22.205228, 41.162886],
							[22.205222, 41.162661],
							[22.205234, 41.162436],
							[22.205263, 41.162202],
							[22.20533, 41.161978],
							[22.20536, 41.161753],
							[22.205274, 41.161545],
							[22.205063, 41.161361],
							[22.204808, 41.161285],
							[22.204517, 41.161308],
							[22.204208, 41.161375],
							[22.203911, 41.161425],
							[22.203623, 41.161474],
							[22.203335, 41.161533],
							[22.203044, 41.161591],
							[22.202751, 41.161632],
							[22.202457, 41.161663],
							[22.20216, 41.161695],
							[22.201864, 41.161717],
							[22.201568, 41.161721],
							[22.201275, 41.161681],
							[22.200979, 41.16164],
							[22.200682, 41.161635],
							[22.200384, 41.161622],
							[22.199788, 41.161612],
							[22.19949, 41.161617],
							[22.199192, 41.161612],
							[22.198894, 41.161625],
							[22.198298, 41.161634],
							[22.198001, 41.161639],
							[22.197404, 41.161665],
							[22.196513, 41.161705],
							[22.196217, 41.161728],
							[22.19592, 41.16175],
							[22.195625, 41.161781],
							[22.195331, 41.161813],
							[22.195037, 41.161853],
							[22.194448, 41.161934],
							[22.194154, 41.161966],
							[22.193859, 41.161988],
							[22.193562, 41.162019],
							[22.192964, 41.162064],
							[22.192666, 41.162077],
							[22.192368, 41.162082],
							[22.192071, 41.162077],
							[22.191777, 41.162054],
							[22.191484, 41.162023],
							[22.1909, 41.161941],
							[22.190608, 41.161892],
							[22.190026, 41.161793],
							[22.189735, 41.161743],
							[22.189446, 41.161684],
							[22.189155, 41.161626],
							[22.188866, 41.161576],
							[22.188576, 41.161518],
							[22.188286, 41.161468],
							[22.187997, 41.161409],
							[22.187707, 41.16136],
							[22.18742, 41.161301],
							[22.18713, 41.161243],
							[22.186843, 41.161184],
							[22.186555, 41.161125],
							[22.18627, 41.161067],
							[22.185984, 41.160999],
							[22.185984, 41.160741],
							[22.185817, 41.160713],
							[22.185637, 41.160683],
							[22.185339, 41.160596],
							[22.184833, 41.160588],
							[22.184331, 41.160479],
							[22.184032, 41.16041],
							[22.183763, 41.160289],
							[22.183513, 41.160191],
							[22.183219, 41.160099],
							[22.183037, 41.160004],
							[22.182769, 41.159887],
							[22.18252, 41.159791],
							[22.182252, 41.159642],
							[22.181946, 41.159379],
							[22.181556, 41.159215],
							[22.18124, 41.159036],
							[22.18104, 41.1589],
							[22.180808, 41.158894],
							[22.180497, 41.15883],
							[22.180204, 41.158779],
							[22.179839, 41.158643],
							[22.179608, 41.158592],
							[22.179304, 41.158531],
							[22.178953, 41.158425],
							[22.178633, 41.158318],
							[22.178261, 41.158232],
							[22.177937, 41.158213],
							[22.177582, 41.158213],
							[22.177203, 41.158189],
							[22.176889, 41.158208],
							[22.176595, 41.158176],
							[22.176298, 41.158153],
							[22.176, 41.158139],
							[22.175702, 41.158117],
							[22.175405, 41.158103],
							[22.17511, 41.158071],
							[22.174817, 41.158031],
							[22.174522, 41.15799],
							[22.174229, 41.15794],
							[22.173939, 41.157882],
							[22.173653, 41.157823],
							[22.173372, 41.157747],
							[22.173097, 41.15767],
							[22.172825, 41.157576],
							[22.172556, 41.157481],
							[22.172291, 41.157378],
							[22.172027, 41.157266],
							[22.171767, 41.157154],
							[22.171509, 41.157041],
							[22.171253, 41.156929],
							[22.170994, 41.156808],
							[22.170737, 41.156687],
							[22.17049, 41.156557],
							[22.170259, 41.156418],
							[22.170051, 41.156262],
							[22.169928, 41.156071],
							[22.169876, 41.155836],
							[22.169809, 41.155609],
							[22.16975, 41.155392],
							[22.169681, 41.155175],
							[22.169605, 41.154958],
							[22.169538, 41.15474],
							[22.169499, 41.154514],
							[22.169484, 41.154289],
							[22.169449, 41.154063],
							[22.169372, 41.153846],
							[22.169284, 41.153628],
							[22.169189, 41.15342],
							[22.169086, 41.153211],
							[22.168979, 41.152993],
							[22.168868, 41.152784],
							[22.168747, 41.152584],
							[22.168604, 41.152384],
							[22.168448, 41.152192],
							[22.168282, 41.152],
							[22.168108, 41.151826],
							[22.167947, 41.151626],
							[22.167788, 41.151434],
							[22.167606, 41.15126],
							[22.167384, 41.151112],
							[22.167139, 41.150991],
							[22.166881, 41.15087],
							[22.166622, 41.150758],
							[22.166374, 41.150628],
							[22.166146, 41.150489],
							[22.165934, 41.150332],
							[22.165733, 41.150167],
							[22.165537, 41.149993],
							[22.165341, 41.149819],
							[22.165141, 41.149653],
							[22.164939, 41.149488],
							[22.164746, 41.149314],
							[22.164569, 41.14914],
							[22.164416, 41.148948],
							[22.164283, 41.148739],
							[22.164165, 41.14853],
							[22.164061, 41.148321],
							[22.163977, 41.148103],
							[22.163875, 41.147895],
							[22.163739, 41.147694],
							[22.163586, 41.147503],
							[22.163546, 41.147453],
							[22.163432, 41.147311],
							[22.163288, 41.147111],
							[22.163168, 41.146902],
							[22.163065, 41.146693],
							[22.162978, 41.146475],
							[22.162912, 41.146258],
							[22.162865, 41.146032],
							[22.16283, 41.145815],
							[22.162797, 41.14559],
							[22.162758, 41.145364],
							[22.162709, 41.145138],
							[22.162662, 41.144921],
							[22.162611, 41.144695],
							[22.162554, 41.144478],
							[22.162486, 41.144252],
							[22.162403, 41.144043],
							[22.162307, 41.143825],
							[22.162199, 41.143617],
							[22.162081, 41.143408],
							[22.161954, 41.143207],
							[22.161823, 41.143007],
							[22.161678, 41.142807],
							[22.161518, 41.142615],
							[22.161345, 41.142432],
							[22.161165, 41.142249],
							[22.160961, 41.142092],
							[22.16074, 41.141936],
							[22.160524, 41.141779],
							[22.160302, 41.141632],
							[22.160061, 41.141502],
							[22.159802, 41.14139],
							[22.159536, 41.141286],
							[22.159271, 41.141183],
							[22.159015, 41.141071],
							[22.158765, 41.140949],
							[22.158517, 41.140819],
							[22.158269, 41.140698],
							[22.15802, 41.140577],
							[22.157707, 41.140427],
							[22.157516, 41.140335],
							[22.157011, 41.140093],
							[22.156759, 41.139972],
							[22.156508, 41.139851],
							[22.156005, 41.139608],
							[22.155748, 41.139496],
							[22.155494, 41.139375],
							[22.155246, 41.139245],
							[22.15501, 41.139115],
							[22.154809, 41.13895],
							[22.154657, 41.138758],
							[22.154496, 41.138566],
							[22.154317, 41.138383],
							[22.154149, 41.138201],
							[22.153999, 41.138],
							[22.153849, 41.137808],
							[22.153681, 41.137625],
							[22.153524, 41.137434],
							[22.153399, 41.137225],
							[22.153302, 41.137016],
							[22.153237, 41.136799],
							[22.153191, 41.136573],
							[22.153151, 41.136347],
							[22.153117, 41.136121],
							[22.15306, 41.135904],
							[22.152986, 41.135687],
							[22.152906, 41.135469],
							[22.152824, 41.135252],
							[22.152744, 41.135034],
							[22.15259, 41.1346],
							[22.15251, 41.134382],
							[22.152428, 41.134165],
							[22.152338, 41.133947],
							[22.152189, 41.133755],
							[22.152, 41.133581],
							[22.15181, 41.133407],
							[22.151619, 41.133233],
							[22.151428, 41.133059],
							[22.151244, 41.132885],
							[22.151076, 41.132702],
							[22.150921, 41.13251],
							[22.150781, 41.13231],
							[22.150665, 41.132101],
							[22.15058, 41.131883],
							[22.150516, 41.131666],
							[22.150455, 41.131449],
							[22.150385, 41.131222],
							[22.150307, 41.131005],
							[22.150225, 41.130787],
							[22.150138, 41.130579],
							[22.150044, 41.130361],
							[22.149941, 41.130153],
							[22.149833, 41.129944],
							[22.149718, 41.129735],
							[22.1496, 41.129525],
							[22.149477, 41.129325],
							[22.149352, 41.129116],
							[22.149221, 41.128916],
							[22.149078, 41.128715],
							[22.148916, 41.128533],
							[22.148727, 41.128349],
							[22.148516, 41.128193],
							[22.148286, 41.128045],
							[22.148045, 41.127915],
							[22.14779, 41.127794],
							[22.147529, 41.127691],
							[22.147263, 41.127587],
							[22.146977, 41.127529],
							[22.146687, 41.12747],
							[22.146406, 41.127393],
							[22.145848, 41.12724],
							[22.145564, 41.127164],
							[22.145278, 41.127105],
							[22.144992, 41.127046],
							[22.144706, 41.126979],
							[22.144424, 41.126902],
							[22.144158, 41.126808],
							[22.1439, 41.126695],
							[22.143641, 41.126583],
							[22.14338, 41.126471],
							[22.143116, 41.126368],
							[22.142854, 41.126264],
							[22.142598, 41.126152],
							[22.142346, 41.126031],
							[22.142098, 41.125901],
							[22.141849, 41.12578],
							[22.141598, 41.125659],
							[22.141344, 41.125537],
							[22.141081, 41.125434],
							[22.140816, 41.125331],
							[22.140548, 41.125227],
							[22.140276, 41.125124],
							[22.140003, 41.125038],
							[22.139725, 41.124953],
							[22.139445, 41.124885],
							[22.139159, 41.124836],
							[22.138866, 41.124795],
							[22.138568, 41.124772],
							[22.138264, 41.124758],
							[22.137962, 41.124753],
							[22.137662, 41.124775],
							[22.13737, 41.124798],
							[22.137083, 41.124847],
							[22.136798, 41.124905],
							[22.136517, 41.124982],
							[22.136239, 41.125068],
							[22.135963, 41.125162],
							[22.13569, 41.125257],
							[22.135421, 41.125352],
							[22.135158, 41.125464],
							[22.1349, 41.125577],
							[22.134641, 41.12569],
							[22.134379, 41.125803],
							[22.134111, 41.125889],
							[22.133824, 41.125929],
							[22.13352, 41.125924],
							[22.133221, 41.125937],
							[22.132627, 41.125928],
							[22.13233, 41.125896],
							[22.132037, 41.125855],
							[22.131746, 41.125805],
							[22.131461, 41.125729],
							[22.131207, 41.125617],
							[22.131014, 41.125442],
							[22.130899, 41.125233],
							[22.130786, 41.125024],
							[22.130659, 41.124824],
							[22.130538, 41.124615],
							[22.130434, 41.124406],
							[22.130333, 41.124188],
							[22.130212, 41.123979],
							[22.130048, 41.123805],
							[22.12983, 41.123649],
							[22.129576, 41.123509],
							[22.12931, 41.123415],
							[22.129024, 41.123374],
							[22.128716, 41.123396],
							[22.128428, 41.123446],
							[22.128159, 41.123549],
							[22.12791, 41.123672],
							[22.127672, 41.123803],
							[22.12744, 41.123952],
							[22.12722, 41.124102],
							[22.127007, 41.12426],
							[22.126795, 41.124419],
							[22.126576, 41.124578],
							[22.126343, 41.124709],
							[22.126094, 41.124831],
							[22.125842, 41.124953],
							[22.125586, 41.125066],
							[22.125326, 41.125179],
							[22.125065, 41.125292],
							[22.124801, 41.125395],
							[22.124534, 41.125499],
							[22.124265, 41.125594],
							[22.123996, 41.125689],
							[22.123724, 41.125783],
							[22.12345, 41.125869],
							[22.123167, 41.125936],
							[22.122878, 41.125994],
							[22.122587, 41.126044],
							[22.122295, 41.126093],
							[22.122007, 41.126151],
							[22.121721, 41.126219],
							[22.12144, 41.126286],
							[22.12116, 41.126372],
							[22.120882, 41.126448],
							[22.120605, 41.126534],
							[22.120333, 41.12662],
							[22.120062, 41.126714],
							[22.119802, 41.126836],
							[22.119591, 41.126986],
							[22.119423, 41.127172],
							[22.119274, 41.127368],
							[22.119129, 41.127573],
							[22.118983, 41.127769],
							[22.11884, 41.127964],
							[22.118699, 41.12816],
							[22.11856, 41.128365],
							[22.118421, 41.128561],
							[22.118278, 41.128757],
							[22.118133, 41.128953],
							[22.117981, 41.129148],
							[22.117824, 41.129335],
							[22.117658, 41.129521],
							[22.11749, 41.129708],
							[22.117319, 41.129894],
							[22.117145, 41.13008],
							[22.116968, 41.130258],
							[22.116789, 41.130444],
							[22.116608, 41.130621],
							[22.116424, 41.130798],
							[22.116238, 41.130975],
							[22.11605, 41.131152],
							[22.115859, 41.13132],
							[22.115668, 41.131488],
							[22.11547, 41.131656],
							[22.115269, 41.131824],
							[22.115061, 41.131992],
							[22.114852, 41.132151],
							[22.114639, 41.132309],
							[22.114424, 41.132459],
							[22.114207, 41.132617],
							[22.113989, 41.132767],
							[22.113769, 41.132925],
							[22.113547, 41.133075],
							[22.113322, 41.133224],
							[22.113094, 41.133365],
							[22.112862, 41.133505],
							[22.112625, 41.133645],
							[22.112385, 41.133777],
							[22.112138, 41.133899],
							[22.111886, 41.13402],
							[22.111631, 41.134142],
							[22.111371, 41.134255],
							[22.111108, 41.134359],
							[22.110845, 41.134463],
							[22.110577, 41.134566],
							[22.110309, 41.134661],
							[22.110036, 41.134756],
							[22.109763, 41.134841],
							[22.109485, 41.134927],
							[22.109205, 41.135003],
							[22.108923, 41.13508],
							[22.108639, 41.135138],
							[22.108353, 41.135196],
							[22.108062, 41.135246],
							[22.107768, 41.135286],
							[22.107474, 41.135326],
							[22.107179, 41.135366],
							[22.106884, 41.135398],
							[22.106585, 41.13542],
							[22.106284, 41.135433],
							[22.105983, 41.135446],
							[22.105685, 41.135468],
							[22.105394, 41.135508],
							[22.105111, 41.135558],
							[22.104842, 41.135643],
							[22.104587, 41.135756],
							[22.104338, 41.135887],
							[22.104093, 41.136018],
							[22.103842, 41.136149],
							[22.103584, 41.136262],
							[22.103321, 41.136375],
							[22.103055, 41.136469],
							[22.102781, 41.136555],
							[22.102484, 41.136586],
							[22.102183, 41.13659],
							[22.101878, 41.136594],
							[22.101593, 41.136626],
							[22.101364, 41.136766],
							[22.101147, 41.136924],
							[22.100913, 41.137065],
							[22.100683, 41.137205],
							[22.100468, 41.137364],
							[22.100266, 41.137531],
							[22.100068, 41.137708],
							[22.099871, 41.137876],
							[22.099667, 41.138044],
							[22.099451, 41.138193],
							[22.099213, 41.138316],
							[22.098939, 41.138401],
							[22.098646, 41.13846],
							[22.098357, 41.138527],
							[22.09778, 41.138616],
							[22.097482, 41.138629],
							[22.097182, 41.138633],
							[22.096891, 41.138602],
							[22.096614, 41.138516],
							[22.096362, 41.138395],
							[22.096132, 41.138256],
							[22.095909, 41.138099],
							[22.09568, 41.13796],
							[22.095449, 41.137812],
							[22.095219, 41.137673],
							[22.094984, 41.137534],
							[22.094746, 41.137404],
							[22.094504, 41.137265],
							[22.094258, 41.137126],
							[22.094009, 41.137005],
							[22.093753, 41.136892],
							[22.093478, 41.136834],
							[22.093183, 41.13691],
							[22.09293, 41.137023],
							[22.092685, 41.137154],
							[22.092437, 41.137276],
							[22.092188, 41.137398],
							[22.091938, 41.137529],
							[22.091687, 41.137642],
							[22.091432, 41.137764],
							[22.091174, 41.137876],
							[22.090912, 41.137989],
							[22.090645, 41.138102],
							[22.090375, 41.138188],
							[22.090097, 41.138255],
							[22.0898, 41.138241],
							[22.0895, 41.138191],
							[22.089219, 41.138123],
							[22.088946, 41.13802],
							[22.088676, 41.137925],
							[22.0884, 41.137848],
							[22.0881, 41.137834],
							[22.087807, 41.137875],
							[22.087565, 41.138015],
							[22.087398, 41.138201],
							[22.087256, 41.138397],
							[22.087103, 41.138593],
							[22.08695, 41.138788],
							[22.086797, 41.138975],
							[22.086642, 41.13917],
							[22.086484, 41.139357],
							[22.086321, 41.139552],
							[22.086145, 41.13973],
							[22.085954, 41.139907],
							[22.085764, 41.140084],
							[22.085588, 41.140261],
							[22.085455, 41.140457],
							[22.085384, 41.140681],
							[22.0853, 41.140895],
							[22.0851, 41.141325],
							[22.084995, 41.14153],
							[22.084882, 41.141744],
							[22.084759, 41.141949],
							[22.084618, 41.142145],
							[22.084453, 41.142332],
							[22.084263, 41.142509],
							[22.084054, 41.142667],
							[22.083833, 41.142817],
							[22.083604, 41.142957],
							[22.083371, 41.143106],
							[22.083136, 41.143246],
							[22.082903, 41.143387],
							[22.08267, 41.143527],
							[22.082433, 41.143658],
							[22.082184, 41.14378],
							[22.081913, 41.143875],
							[22.081625, 41.143933],
							[22.081331, 41.143973],
							[22.081033, 41.143995],
							[22.080733, 41.143999],
							[22.080428, 41.143985],
							[22.080124, 41.14398],
							[22.079829, 41.143993],
							[22.07955, 41.144042],
							[22.079291, 41.144146],
							[22.079053, 41.144295],
							[22.078837, 41.144454],
							[22.078667, 41.14464],
							[22.078578, 41.144855],
							[22.078571, 41.14508],
							[22.0786, 41.145305],
							[22.078648, 41.145531],
							[22.078707, 41.145749],
							[22.078783, 41.145975],
							[22.078881, 41.146184],
							[22.079023, 41.146375],
							[22.079212, 41.146559],
							[22.079393, 41.146733],
							[22.079574, 41.146916],
							[22.079754, 41.14709],
							[22.079936, 41.147273],
							[22.080128, 41.147448],
							[22.080323, 41.147622],
							[22.080505, 41.147796],
							[22.08066, 41.147988],
							[22.080782, 41.148188],
							[22.080874, 41.148406],
							[22.080938, 41.148632],
							[22.080975, 41.148849],
							[22.08097, 41.149074],
							[22.080935, 41.149299],
							[22.080885, 41.149523],
							[22.080839, 41.149747],
							[22.080794, 41.149972],
							[22.080747, 41.150196],
							[22.080692, 41.150421],
							[22.080626, 41.150636],
							[22.080542, 41.15085],
							[22.080443, 41.151065],
							[22.080329, 41.151279],
							[22.080199, 41.151484],
							[22.080049, 41.15168],
							[22.079874, 41.151848],
							[22.079635, 41.151979],
							[22.079371, 41.152101],
							[22.079125, 41.152232],
							[22.078883, 41.152363],
							[22.07864, 41.152494],
							[22.078403, 41.152634],
							[22.078182, 41.152784],
							[22.077985, 41.152951],
							[22.077814, 41.153138],
							[22.077662, 41.153324],
							[22.077381, 41.153734],
							[22.077232, 41.15393],
							[22.077066, 41.154116],
							[22.076875, 41.154284],
							[22.07667, 41.154443],
							[22.076455, 41.154601],
							[22.076232, 41.154751],
							[22.076003, 41.1549],
							[22.075771, 41.15504],
							[22.075539, 41.15518],
							[22.075308, 41.15533],
							[22.075078, 41.15547],
							[22.074844, 41.15561],
							[22.074606, 41.15575],
							[22.074364, 41.155881],
							[22.074116, 41.156003],
							[22.073841, 41.156098],
							[22.073539, 41.156111],
							[22.073275, 41.156016],
							[22.073019, 41.155895],
							[22.07276, 41.155782],
							[22.072494, 41.155679],
							[22.072213, 41.155593],
							[22.071923, 41.155516],
							[22.071633, 41.155484],
							[22.07135, 41.155507],
							[22.07107, 41.155592],
							[22.070787, 41.155677],
							[22.070499, 41.155745],
							[22.070207, 41.155785],
							[22.069913, 41.155825],
							[22.069617, 41.155865],
							[22.069323, 41.155896],
							[22.069031, 41.155945],
							[22.068741, 41.155994],
							[22.068456, 41.156053],
							[22.068167, 41.156129],
							[22.067883, 41.156214],
							[22.067614, 41.156309],
							[22.067367, 41.156422],
							[22.06717, 41.15658],
							[22.06703, 41.156794],
							[22.066904, 41.156999],
							[22.066789, 41.157205],
							[22.066684, 41.157419],
							[22.066578, 41.157624],
							[22.066466, 41.157839],
							[22.066357, 41.158053],
							[22.066241, 41.158258],
							[22.066107, 41.158454],
							[22.065946, 41.158641],
							[22.065776, 41.158827],
							[22.065596, 41.159013],
							[22.065407, 41.15919],
							[22.06521, 41.159358],
							[22.065006, 41.159517],
							[22.064796, 41.159675],
							[22.064579, 41.159834],
							[22.064356, 41.159983],
							[22.064128, 41.160132],
							[22.063895, 41.160272],
							[22.063658, 41.160413],
							[22.063408, 41.160525],
							[22.063118, 41.160584],
							[22.062828, 41.160651],
							[22.062544, 41.160727],
							[22.062246, 41.160713],
							[22.061989, 41.16061],
							[22.061816, 41.160427],
							[22.061645, 41.160235],
							[22.061565, 41.160026],
							[22.061593, 41.159801],
							[22.061646, 41.159577],
							[22.061701, 41.159353],
							[22.061741, 41.159137],
							[22.061776, 41.158913],
							[22.061796, 41.158688],
							[22.061802, 41.158463],
							[22.061797, 41.158238],
							[22.061796, 41.158012],
							[22.061799, 41.157112],
							[22.061802, 41.156887],
							[22.061804, 41.156661],
							[22.061804, 41.156436],
							[22.0618, 41.156211],
							[22.061794, 41.155986],
							[22.061785, 41.15576],
							[22.061775, 41.155535],
							[22.061761, 41.15531],
							[22.061727, 41.154859],
							[22.061709, 41.154633],
							[22.061687, 41.154408],
							[22.06166, 41.154182],
							[22.061625, 41.153965],
							[22.061583, 41.153739],
							[22.061542, 41.153514],
							[22.061493, 41.153287],
							[22.061433, 41.15307],
							[22.061351, 41.152853],
							[22.061241, 41.152644],
							[22.061096, 41.152452],
							[22.060926, 41.152269],
							[22.060744, 41.152086],
							[22.060565, 41.151903],
							[22.06039, 41.15172],
							[22.06021, 41.151536],
							[22.060029, 41.151362],
							[22.05985, 41.151179],
							[22.059679, 41.150996],
							[22.059519, 41.150804],
							[22.059365, 41.150613],
							[22.059207, 41.150421],
							[22.059039, 41.150238],
							[22.058852, 41.150063],
							[22.058646, 41.149898],
							[22.058425, 41.14975],
							[22.057969, 41.149454],
							[22.05752, 41.149158],
							[22.057295, 41.14901],
							[22.057067, 41.148862],
							[22.056833, 41.148723],
							[22.056594, 41.148593],
							[22.056348, 41.148463],
							[22.056093, 41.14835],
							[22.055832, 41.148238],
							[22.055564, 41.148134],
							[22.055293, 41.14804],
							[22.055016, 41.147963],
							[22.054735, 41.147886],
							[22.054448, 41.147818],
							[22.054157, 41.147768],
							[22.053864, 41.147718],
							[22.05357, 41.147686],
							[22.053277, 41.147664],
							[22.052979, 41.147649],
							[22.052681, 41.147644],
							[22.052381, 41.147657],
							[22.052083, 41.147661],
							[22.051785, 41.147674],
							[22.05149, 41.147696],
							[22.051195, 41.147736],
							[22.0509, 41.147776],
							[22.050604, 41.147798],
							[22.05001, 41.147824],
							[22.049711, 41.147837],
							[22.049414, 41.14785],
							[22.049116, 41.147854],
							[22.048522, 41.147844],
							[22.047928, 41.147798],
							[22.047631, 41.147775],
							[22.047326, 41.147752],
							[22.047031, 41.147702],
							[22.04687, 41.147546],
							[22.046855, 41.14732],
							[22.046881, 41.147096],
							[22.046915, 41.146871],
							[22.04693, 41.146646],
							[22.046903, 41.146421],
							[22.046856, 41.146204],
							[22.046801, 41.145978],
							[22.046751, 41.145752],
							[22.046697, 41.145534],
							[22.046604, 41.145317],
							[22.046459, 41.145125],
							[22.046273, 41.144942],
							[22.046054, 41.144794],
							[22.045816, 41.144655],
							[22.045566, 41.144534],
							[22.045306, 41.144412],
							[22.04504, 41.144317],
							[22.044766, 41.144232],
							[22.044478, 41.144173],
							[22.044184, 41.144123],
							[22.04389, 41.144073],
							[22.043581, 41.144049],
							[22.043356, 41.143937],
							[22.043203, 41.143737],
							[22.043051, 41.143536],
							[22.04286, 41.143362],
							[22.042686, 41.143178],
							[22.042595, 41.14297],
							[22.042557, 41.142744],
							[22.04254, 41.142519],
							[22.042529, 41.142293],
							[22.04249, 41.142067],
							[22.042423, 41.14185],
							[22.042389, 41.141624],
							[22.042395, 41.141399],
							[22.042374, 41.141174],
							[22.042323, 41.140957],
							[22.042241, 41.140739],
							[22.04212, 41.14053],
							[22.041967, 41.140338],
							[22.041786, 41.140155],
							[22.041571, 41.140007],
							[22.04132, 41.139877],
							[22.041066, 41.139755],
							[22.040825, 41.139625],
							[22.04056, 41.139521],
							[22.040288, 41.139427],
							[22.040012, 41.139341],
							[22.039727, 41.139282],
							[22.039431, 41.13925],
							[22.039133, 41.139227],
							[22.038839, 41.139186],
							[22.038557, 41.139127],
							[22.038287, 41.139032],
							[22.038017, 41.138929],
							[22.037739, 41.138852],
							[22.037451, 41.138793],
							[22.03716, 41.138743],
							[22.036868, 41.138702],
							[22.036285, 41.138602],
							[22.035994, 41.138552],
							[22.035709, 41.138493],
							[22.035431, 41.138407],
							[22.035152, 41.13833],
							[22.034864, 41.138262],
							[22.034578, 41.138185],
							[22.03431, 41.138091],
							[22.034078, 41.137961],
							[22.033878, 41.137795],
							[22.033694, 41.137612],
							[22.03351, 41.137438],
							[22.033312, 41.137263],
							[22.033102, 41.137106],
							[22.032891, 41.13695],
							[22.032691, 41.136784],
							[22.032501, 41.13661],
							[22.032312, 41.136435],
							[22.032116, 41.136261],
							[22.03191, 41.136095],
							[22.031703, 41.135939],
							[22.031512, 41.135764],
							[22.031356, 41.135572],
							[22.031222, 41.135372],
							[22.031088, 41.135171],
							[22.030957, 41.134971],
							[22.030831, 41.134762],
							[22.030714, 41.134562],
							[22.030605, 41.134344],
							[22.030488, 41.134143],
							[22.030362, 41.133934],
							[22.030242, 41.133716],
							[22.030109, 41.133506],
							[22.029951, 41.133324],
							[22.029749, 41.133176],
							[22.029487, 41.133082],
							[22.02919, 41.133013],
							[22.028889, 41.132954],
							[22.028599, 41.132913],
							[22.028303, 41.132881],
							[22.028299, 41.132881],
							[22.028005, 41.132858],
							[22.027707, 41.132853],
							[22.027413, 41.132884],
							[22.02712, 41.132924],
							[22.026827, 41.132973],
							[22.026533, 41.132995],
							[22.026235, 41.133008],
							[22.025938, 41.133021],
							[22.025641, 41.133052],
							[22.025346, 41.133083],
							[22.02505, 41.133114],
							[22.024756, 41.133117],
							[22.024462, 41.133067],
							[22.02417, 41.133026],
							[22.023879, 41.132976],
							[22.023586, 41.132935],
							[22.023291, 41.132903],
							[22.023004, 41.132844],
							[22.022725, 41.132758],
							[22.022443, 41.132691],
							[22.022148, 41.132676],
							[22.021848, 41.13268],
							[22.02155, 41.132675],
							[22.021253, 41.132679],
							[22.020955, 41.132674],
							[22.020359, 41.132682],
							[22.020062, 41.132677],
							[22.019764, 41.132663],
							[22.019468, 41.132639],
							[22.019172, 41.132607],
							[22.018876, 41.132584],
							[22.01858, 41.132552],
							[22.018287, 41.132511],
							[22.018001, 41.132452],
							[22.01772, 41.132375],
							[22.017442, 41.132298],
							[22.017161, 41.132221],
							[22.016874, 41.132162],
							[22.016579, 41.132139],
							[22.016281, 41.132116],
							[22.015983, 41.132102],
							[22.015686, 41.132079],
							[22.015388, 41.132065],
							[22.015092, 41.132042],
							[22.0148, 41.13201],
							[22.014517, 41.131933],
							[22.01424, 41.131847],
							[22.013961, 41.13177],
							[22.013679, 41.131702],
							[22.01339, 41.131643],
							[22.013123, 41.131548],
							[22.012865, 41.131436],
							[22.012611, 41.131314],
							[22.012352, 41.131202],
							[22.012087, 41.131098],
							[22.011816, 41.130994],
							[22.011537, 41.130926],
							[22.011243, 41.130903],
							[22.010943, 41.130916],
							[22.010644, 41.130911],
							[22.010346, 41.130888],
							[22.010048, 41.130883],
							[22.009755, 41.130932],
							[22.009465, 41.13099],
							[22.009173, 41.131012],
							[22.008882, 41.13098],
							[22.0083, 41.130862],
							[22.008007, 41.13082],
							[22.007714, 41.130788],
							[22.007419, 41.130747],
							[22.007125, 41.130715],
							[22.006822, 41.13071],
							[22.006565, 41.130606],
							[22.006329, 41.130467],
							[22.00609, 41.130328],
							[22.00584, 41.130206],
							[22.005614, 41.130058],
							[22.005436, 41.129875],
							[22.005309, 41.129675],
							[22.00514, 41.129492],
							[22.004959, 41.129309],
							[22.004773, 41.129134],
							[22.004581, 41.12896],
							[22.004388, 41.128794],
							[22.004188, 41.12862],
							[22.003972, 41.128472],
							[22.003734, 41.128332],
							[22.003483, 41.128211],
							[22.003232, 41.12809],
							[22.002994, 41.12795],
							[22.00275, 41.12782],
							[22.002468, 41.12777],
							[22.00217, 41.127747],
							[22.001867, 41.127742],
							[22.001273, 41.127713],
							[22.000975, 41.127708],
							[22.000678, 41.127694],
							[22.000082, 41.127684],
							[21.999785, 41.12767],
							[21.99937, 41.127689],
							[21.997548, 41.127775],
							[21.997254, 41.127743],
							[21.996958, 41.12771],
							[21.996663, 41.127678],
							[21.996368, 41.127646],
							[21.996071, 41.127632],
							[21.995773, 41.127636],
							[21.995474, 41.127631],
							[21.995179, 41.127616],
							[21.994883, 41.127575],
							[21.994596, 41.127516],
							[21.994325, 41.12743],
							[21.994068, 41.127309],
							[21.993819, 41.127187],
							[21.993578, 41.127057],
							[21.993344, 41.126918],
							[21.993108, 41.126779],
							[21.992865, 41.126648],
							[21.992621, 41.126518],
							[21.992374, 41.126388],
							[21.992121, 41.126275],
							[21.991863, 41.126162],
							[21.991596, 41.126059],
							[21.991058, 41.125869],
							[21.990686, 41.126025],
							[21.99043, 41.126137],
							[21.990179, 41.126259],
							[21.989931, 41.12639],
							[21.989687, 41.126521],
							[21.989453, 41.126652],
							[21.989231, 41.126801],
							[21.989013, 41.126959],
							[21.988801, 41.127118],
							[21.988595, 41.127285],
							[21.988398, 41.127453],
							[21.988222, 41.12763],
							[21.988069, 41.127835],
							[21.987905, 41.128021],
							[21.987688, 41.128161],
							[21.987415, 41.128256],
							[21.987126, 41.128332],
							[21.986832, 41.128363],
							[21.986532, 41.128366],
							[21.986236, 41.128361],
							[21.985937, 41.128347],
							[21.98564, 41.128351],
							[21.985342, 41.128346],
							[21.984746, 41.128353],
							[21.983257, 41.128327],
							[21.982959, 41.128313],
							[21.982663, 41.128299],
							[21.982367, 41.128275],
							[21.98207, 41.128252],
							[21.981773, 41.12822],
							[21.981477, 41.128179],
							[21.981187, 41.128129],
							[21.980904, 41.128061],
							[21.980642, 41.127957],
							[21.980401, 41.127818],
							[21.980152, 41.127696],
							[21.979874, 41.127628],
							[21.979582, 41.127587],
							[21.979281, 41.127564],
							[21.978979, 41.127549],
							[21.978679, 41.127544],
							[21.978387, 41.127548],
							[21.977991, 41.127685],
							[21.977837, 41.127737],
							[21.977569, 41.12784],
							[21.977424, 41.128026],
							[21.977323, 41.12825],
							[21.977187, 41.128455],
							[21.977043, 41.12865],
							[21.97692, 41.128855],
							[21.976834, 41.12907],
							[21.976758, 41.129285],
							[21.976667, 41.129499],
							[21.976526, 41.129695],
							[21.976341, 41.129881],
							[21.976114, 41.13003],
							[21.975829, 41.130052],
							[21.975526, 41.130029],
							[21.975235, 41.129979],
							[21.974944, 41.129937],
							[21.974646, 41.129905],
							[21.974371, 41.129828],
							[21.974106, 41.129725],
							[21.973845, 41.129612],
							[21.973585, 41.129499],
							[21.973322, 41.129396],
							[21.973069, 41.129274],
							[21.972841, 41.129135],
							[21.972629, 41.128969],
							[21.972437, 41.128794],
							[21.972271, 41.128611],
							[21.972119, 41.12842],
							[21.971979, 41.128219],
							[21.971848, 41.128019],
							[21.971726, 41.127809],
							[21.971613, 41.1276],
							[21.971515, 41.127391],
							[21.971424, 41.127173],
							[21.971339, 41.126956],
							[21.971253, 41.126747],
							[21.971158, 41.126529],
							[21.971052, 41.12632],
							[21.970935, 41.126111],
							[21.970809, 41.125911],
							[21.970677, 41.12571],
							[21.970541, 41.125509],
							[21.970405, 41.125309],
							[21.970267, 41.125108],
							[21.970131, 41.124908],
							[21.969999, 41.124707],
							[21.969871, 41.124498],
							[21.969744, 41.124297],
							[21.969615, 41.124088],
							[21.969482, 41.123888],
							[21.969343, 41.123687],
							[21.969192, 41.123495],
							[21.969032, 41.123312],
							[21.968863, 41.12312],
							[21.968685, 41.122937],
							[21.968499, 41.122762],
							[21.968302, 41.122588],
							[21.968092, 41.122431],
							[21.967862, 41.122292],
							[21.967608, 41.122179],
							[21.967341, 41.122066],
							[21.967068, 41.121972],
							[21.966797, 41.121886],
							[21.966518, 41.1218],
							[21.966234, 41.121732],
							[21.965949, 41.121663],
							[21.965663, 41.121595],
							[21.965364, 41.121545],
							[21.96508, 41.121486],
							[21.964838, 41.121365],
							[21.96464, 41.121199],
							[21.964474, 41.121007],
							[21.964341, 41.120806],
							[21.96424, 41.120588],
							[21.964118, 41.120388],
							[21.963979, 41.120188],
							[21.963836, 41.119987],
							[21.963687, 41.119795],
							[21.963534, 41.119603],
							[21.963378, 41.119411],
							[21.96322, 41.119219],
							[21.963059, 41.119027],
							[21.962897, 41.118835],
							[21.96273, 41.118652],
							[21.96256, 41.118469],
							[21.962386, 41.118286],
							[21.962207, 41.118103],
							[21.962022, 41.117928],
							[21.96183, 41.117754],
							[21.96163, 41.117588],
							[21.96142, 41.117431],
							[21.961152, 41.117318],
							[21.960865, 41.117286],
							[21.960565, 41.117317],
							[21.960271, 41.117357],
							[21.959976, 41.117387],
							[21.959678, 41.117391],
							[21.95938, 41.117386],
							[21.959086, 41.117345],
							[21.958809, 41.117268],
							[21.95856, 41.117137],
							[21.958313, 41.117016],
							[21.958074, 41.116876],
							[21.957845, 41.116728],
							[21.957573, 41.116669],
							[21.957269, 41.116709],
							[21.956982, 41.116767],
							[21.956695, 41.116834],
							[21.956403, 41.116874],
							[21.956112, 41.116923],
							[21.955829, 41.116999],
							[21.955546, 41.117057],
							[21.955314, 41.117035],
							[21.955002, 41.117074],
							[21.954673, 41.117059],
							[21.954424, 41.116947],
							[21.954206, 41.116781],
							[21.95401, 41.116615],
							[21.953826, 41.116432],
							[21.95365, 41.116248],
							[21.953497, 41.116057],
							[21.953367, 41.115856],
							[21.953257, 41.115647],
							[21.953175, 41.115429],
							[21.9531, 41.115212],
							[21.953031, 41.114995],
							[21.952968, 41.114777],
							[21.952908, 41.114551],
							[21.952854, 41.114334],
							[21.952807, 41.114108],
							[21.952768, 41.113882],
							[21.952737, 41.113656],
							[21.952704, 41.113439],
							[21.952669, 41.113214],
							[21.952624, 41.112988],
							[21.952565, 41.11277],
							[21.952494, 41.112553],
							[21.952407, 41.112335],
							[21.952303, 41.112126],
							[21.952179, 41.111917],
							[21.952037, 41.111725],
							[21.951884, 41.111524],
							[21.951721, 41.111341],
							[21.951551, 41.111149],
							[21.951378, 41.110966],
							[21.951185, 41.110782],
							[21.950959, 41.110643],
							[21.950677, 41.110629],
							[21.950369, 41.110678],
							[21.950072, 41.110709],
							[21.949779, 41.110757],
							[21.949521, 41.110861],
							[21.94928, 41.110992],
							[21.948994, 41.11105],
							[21.948695, 41.111071],
							[21.948397, 41.111084],
							[21.9481, 41.11107],
							[21.947813, 41.11101],
							[21.94753, 41.110942],
							[21.947247, 41.110865],
							[21.946963, 41.110797],
							[21.946686, 41.11072],
							[21.946416, 41.110625],
							[21.946149, 41.110521],
							[21.945358, 41.11021],
							[21.945095, 41.110106],
							[21.944831, 41.110003],
							[21.944566, 41.109899],
							[21.944303, 41.109795],
							[21.943773, 41.109587],
							[21.943246, 41.10938],
							[21.942983, 41.109267],
							[21.942721, 41.109163],
							[21.94246, 41.10906],
							[21.9422, 41.108947],
							[21.941941, 41.108834],
							[21.941684, 41.108721],
							[21.941429, 41.108609],
							[21.941175, 41.108487],
							[21.940921, 41.108374],
							[21.940417, 41.108131],
							[21.939914, 41.107888],
							[21.939662, 41.107766],
							[21.939411, 41.107654],
							[21.939158, 41.107532],
							[21.938909, 41.107411],
							[21.938659, 41.10728],
							[21.938411, 41.107159],
							[21.938162, 41.107037],
							[21.937912, 41.106915],
							[21.937661, 41.106794],
							[21.937409, 41.106672],
							[21.937155, 41.10656],
							[21.936899, 41.106438],
							[21.936639, 41.106334],
							[21.936375, 41.106231],
							[21.936109, 41.106127],
							[21.935839, 41.106032],
							[21.93557, 41.105937],
							[21.935302, 41.105833],
							[21.935036, 41.105738],
							[21.934507, 41.105531],
							[21.934241, 41.105427],
							[21.933974, 41.105323],
							[21.933709, 41.105219],
							[21.933448, 41.105106],
							[21.93319, 41.104993],
							[21.932939, 41.104881],
							[21.932694, 41.10475],
							[21.932462, 41.104611],
							[21.932247, 41.104454],
							[21.932048, 41.104279],
							[21.931867, 41.104105],
							[21.931723, 41.103913],
							[21.931648, 41.103687],
							[21.931556, 41.103469],
							[21.931445, 41.103269],
							[21.93133, 41.10306],
							[21.931213, 41.10285],
							[21.931099, 41.102641],
							[21.930986, 41.102432],
							[21.93088, 41.102223],
							[21.93078, 41.102014],
							[21.930685, 41.101796],
							[21.930586, 41.101587],
							[21.930483, 41.101369],
							[21.930368, 41.101169],
							[21.930241, 41.100959],
							[21.930094, 41.100758],
							[21.929924, 41.100575],
							[21.929669, 41.100472],
							[21.929378, 41.100403],
							[21.929082, 41.100362],
							[21.928805, 41.100285],
							[21.928553, 41.100172],
							[21.928323, 41.100024],
							[21.928105, 41.099867],
							[21.92789, 41.09971],
							[21.927677, 41.099553],
							[21.927467, 41.099396],
							[21.927258, 41.099239],
							[21.927053, 41.099074],
							[21.926849, 41.098908],
							[21.926648, 41.098742],
							[21.926451, 41.098576],
							[21.926256, 41.098402],
							[21.926062, 41.098236],
							[21.92587, 41.098061],
							[21.925676, 41.097887],
							[21.925481, 41.097721],
							[21.925281, 41.097555],
							[21.925079, 41.09739],
							[21.924671, 41.097058],
							[21.924466, 41.096892],
							[21.924259, 41.096735],
							[21.92405, 41.096569],
							[21.923838, 41.096412],
							[21.923624, 41.096255],
							[21.923404, 41.096107],
							[21.92318, 41.095959],
							[21.922951, 41.09582],
							[21.922717, 41.095672],
							[21.922481, 41.095541],
							[21.922241, 41.095402],
							[21.921998, 41.095271],
							[21.921752, 41.09515],
							[21.921503, 41.095019],
							[21.921252, 41.094898],
							[21.921, 41.094776],
							[21.920746, 41.094663],
							[21.920488, 41.094551],
							[21.920226, 41.094447],
							[21.919958, 41.094343],
							[21.919687, 41.094248],
							[21.919412, 41.094162],
							[21.919134, 41.094076],
							[21.918856, 41.093999],
							[21.918576, 41.093922],
							[21.918295, 41.093854],
							[21.91801, 41.093785],
							[21.917723, 41.093726],
							[21.917435, 41.093667],
							[21.917146, 41.093617],
							[21.916856, 41.093557],
							[21.916566, 41.093507],
							[21.916277, 41.093457],
							[21.915697, 41.093356],
							[21.915404, 41.093306],
							[21.915112, 41.093264],
							[21.914818, 41.093232],
							[21.914524, 41.0932],
							[21.914225, 41.093203],
							[21.913926, 41.093207],
							[21.913632, 41.093184],
							[21.91334, 41.093142],
							[21.91305, 41.093092],
							[21.912763, 41.093033],
							[21.912477, 41.092965],
							[21.911912, 41.092828],
							[21.911626, 41.09276],
							[21.911334, 41.09271],
							[21.911046, 41.09265],
							[21.91077, 41.092573],
							[21.910524, 41.092443],
							[21.910361, 41.09226],
							[21.910242, 41.09205],
							[21.910148, 41.091832],
							[21.910075, 41.091615],
							[21.910024, 41.091389],
							[21.909999, 41.091163],
							[21.910005, 41.090938],
							[21.910013, 41.090713],
							[21.910014, 41.090488],
							[21.91004, 41.090263],
							[21.910114, 41.090048],
							[21.910211, 41.089834],
							[21.910434, 41.089415],
							[21.910541, 41.08921],
							[21.910645, 41.088995],
							[21.91075, 41.088781],
							[21.910854, 41.088576],
							[21.910958, 41.088361],
							[21.911061, 41.088156],
							[21.911164, 41.087942],
							[21.911366, 41.087513],
							[21.911465, 41.087308],
							[21.911579, 41.087094],
							[21.911698, 41.08688],
							[21.911804, 41.086665],
							[21.911878, 41.08645],
							[21.911903, 41.086235],
							[21.911879, 41.086009],
							[21.911821, 41.085783],
							[21.911742, 41.085565],
							[21.911648, 41.085347],
							[21.911468, 41.085164],
							[21.911219, 41.08506],
							[21.910915, 41.085019],
							[21.910617, 41.084995],
							[21.910318, 41.084981],
							[21.910039, 41.084913],
							[21.909865, 41.08473],
							[21.909813, 41.084503],
							[21.909908, 41.084298],
							[21.910083, 41.084103],
							[21.910217, 41.083907],
							[21.910348, 41.083703],
							[21.910488, 41.083507],
							[21.910646, 41.083312],
							[21.910793, 41.083116],
							[21.910902, 41.082911],
							[21.910919, 41.082686],
							[21.910887, 41.08246],
							[21.910855, 41.082235],
							[21.910805, 41.082009],
							[21.910724, 41.081791],
							[21.910626, 41.081582],
							[21.910517, 41.081373],
							[21.910392, 41.081163],
							[21.910252, 41.080963],
							[21.910098, 41.08078],
							[21.909934, 41.080588],
							[21.909764, 41.080404],
							[21.909591, 41.080221],
							[21.909419, 41.080038],
							[21.909253, 41.079846],
							[21.909085, 41.079662],
							[21.908918, 41.079479],
							[21.908751, 41.079287],
							[21.908584, 41.079104],
							[21.908417, 41.078912],
							[21.908248, 41.078728],
							[21.908074, 41.078545],
							[21.907901, 41.078362],
							[21.907737, 41.078179],
							[21.907586, 41.077978],
							[21.907455, 41.077777],
							[21.907343, 41.077568],
							[21.907247, 41.077359],
							[21.907163, 41.077142],
							[21.907106, 41.076924],
							[21.90708, 41.076699],
							[21.907045, 41.076473],
							[21.906995, 41.076247],
							[21.906944, 41.07603],
							[21.906904, 41.075804],
							[21.906889, 41.075578],
							[21.906895, 41.075353],
							[21.90691, 41.075128],
							[21.906927, 41.074903],
							[21.906934, 41.074678],
							[21.906923, 41.074453],
							[21.906885, 41.074227],
							[21.906825, 41.07401],
							[21.906756, 41.073793],
							[21.906687, 41.073575],
							[21.90663, 41.073349],
							[21.906604, 41.073123],
							[21.906607, 41.072898],
							[21.9066, 41.072673],
							[21.906539, 41.072455],
							[21.906435, 41.072246],
							[21.906305, 41.072037],
							[21.906156, 41.071845],
							[21.906153, 41.071665],
							[21.905987, 41.071662],
							[21.905793, 41.071487],
							[21.905593, 41.071321],
							[21.90541, 41.071138],
							[21.905236, 41.070955],
							[21.905064, 41.070771],
							[21.904888, 41.070597],
							[21.904695, 41.070422],
							[21.904504, 41.070248],
							[21.904343, 41.070056],
							[21.904215, 41.069855],
							[21.90413, 41.069637],
							[21.904072, 41.06942],
							[21.904036, 41.069194],
							[21.904034, 41.068969],
							[21.904129, 41.068755],
							[21.904307, 41.068578],
							[21.904502, 41.068401],
							[21.904708, 41.068243],
							[21.90492, 41.068075],
							[21.905135, 41.067926],
							[21.905362, 41.067777],
							[21.905596, 41.067637],
							[21.905828, 41.067498],
							[21.906052, 41.067349],
							[21.906265, 41.06719],
							[21.906472, 41.067032],
							[21.906677, 41.066865],
							[21.906881, 41.066706],
							[21.907084, 41.066539],
							[21.907289, 41.066371],
							[21.90758, 41.066133],
							[21.907785, 41.065966],
							[21.907987, 41.065798],
							[21.908174, 41.065631],
							[21.908343, 41.065445],
							[21.908491, 41.065249],
							[21.90862, 41.065044],
							[21.908727, 41.06483],
							[21.908808, 41.064615],
							[21.90886, 41.064391],
							[21.908883, 41.064175],
							[21.908891, 41.063941],
							[21.908889, 41.063716],
							[21.908891, 41.063491],
							[21.908906, 41.063266],
							[21.90892, 41.063014],
							[21.908937, 41.062753],
							[21.90897, 41.06252],
							[21.909036, 41.062368],
							[21.909243, 41.062525],
							[21.90945, 41.062672],
							[21.909512, 41.062511],
							[21.90953, 41.06225],
							[21.909534, 41.061989],
							[21.909537, 41.061764],
							[21.909516, 41.061313],
							[21.909514, 41.061088],
							[21.909536, 41.060873],
							[21.909582, 41.060648],
							[21.909634, 41.060424],
							[21.909674, 41.0602],
							[21.909704, 41.059975],
							[21.909732, 41.05975],
							[21.909751, 41.059525],
							[21.909759, 41.0593],
							[21.909751, 41.059075],
							[21.909728, 41.058849],
							[21.909697, 41.058633],
							[21.909666, 41.058407],
							[21.909641, 41.058181],
							[21.909628, 41.057956],
							[21.909617, 41.05773],
							[21.909611, 41.057505],
							[21.90961, 41.05728],
							[21.909615, 41.057055],
							[21.909626, 41.05683],
							[21.909649, 41.056605],
							[21.909679, 41.056381],
							[21.909715, 41.056156],
							[21.909753, 41.055931],
							[21.909789, 41.055707],
							[21.909831, 41.055482],
							[21.909886, 41.055267],
							[21.90996, 41.055043],
							[21.910074, 41.054838],
							[21.910219, 41.054643],
							[21.910376, 41.054448],
							[21.910538, 41.054261],
							[21.910706, 41.054075],
							[21.91088, 41.053889],
							[21.911055, 41.053712],
							[21.911235, 41.053526],
							[21.911416, 41.05335],
							[21.9116, 41.053173],
							[21.911786, 41.052996],
							[21.911971, 41.052819],
							[21.912158, 41.052651],
							[21.91234, 41.052475],
							[21.912525, 41.052289],
							[21.912709, 41.052112],
							[21.912894, 41.051935],
							[21.913077, 41.051758],
							[21.913259, 41.051581],
							[21.913437, 41.051405],
							[21.913611, 41.051219],
							[21.913788, 41.051033],
							[21.913968, 41.050829],
							[21.914124, 41.050624],
							[21.914226, 41.050446],
							[21.91399, 41.050361],
							[21.91369, 41.050319],
							[21.913395, 41.050287],
							[21.912804, 41.05024],
							[21.912511, 41.050199],
							[21.912228, 41.050131],
							[21.911951, 41.050045],
							[21.911681, 41.04995],
							[21.911436, 41.049819],
							[21.911205, 41.04968],
							[21.910997, 41.049514],
							[21.910946, 41.049504],
							[21.910828, 41.049331],
							[21.910693, 41.04913],
							[21.910573, 41.048921],
							[21.910455, 41.048711],
							[21.910322, 41.048511],
							[21.910183, 41.04831],
							[21.91005, 41.04811],
							[21.909944, 41.0479],
							[21.909865, 41.047683],
							[21.909774, 41.047474],
							[21.909653, 41.047265],
							[21.909513, 41.047064],
							[21.909354, 41.046872],
							[21.909195, 41.046689],
							[21.909051, 41.046488],
							[21.908878, 41.046305],
							[21.908669, 41.046148],
							[21.908454, 41.045991],
							[21.908225, 41.045843],
							[21.907956, 41.045757],
							[21.907669, 41.045688],
							[21.907371, 41.045656],
							[21.907125, 41.045543],
							[21.906906, 41.045386],
							[21.906684, 41.045229],
							[21.906488, 41.045063],
							[21.906316, 41.04488],
							[21.906139, 41.044697],
							[21.905962, 41.044522],
							[21.905783, 41.044339],
							[21.905598, 41.044165],
							[21.905405, 41.04399],
							[21.905203, 41.043824],
							[21.904998, 41.043667],
							[21.904788, 41.043501],
							[21.904576, 41.043344],
							[21.904151, 41.04303],
							[21.903939, 41.042873],
							[21.90373, 41.042716],
							[21.903525, 41.042551],
							[21.903322, 41.042385],
							[21.903121, 41.042219],
							[21.902921, 41.042044],
							[21.902719, 41.041878],
							[21.902515, 41.041722],
							[21.902307, 41.041556],
							[21.902093, 41.041408],
							[21.901845, 41.041277],
							[21.901557, 41.041218],
							[21.901267, 41.041258],
							[21.901166, 41.041472],
							[21.901089, 41.041696],
							[21.901023, 41.04192],
							[21.900953, 41.042135],
							[21.900775, 41.042311],
							[21.900483, 41.042369],
							[21.900268, 41.04223],
							[21.900112, 41.042029],
							[21.899987, 41.041829],
							[21.899862, 41.041619],
							[21.899712, 41.041427],
							[21.899577, 41.041227],
							[21.899472, 41.041018],
							[21.899369, 41.0408],
							[21.899236, 41.040608],
							[21.899075, 41.040416],
							[21.898899, 41.040233],
							[21.898709, 41.040058],
							[21.898504, 41.039892],
							[21.898285, 41.039744],
							[21.89806, 41.039596],
							[21.897833, 41.039448],
							[21.897599, 41.039308],
							[21.897349, 41.039186],
							[21.897067, 41.039109],
							[21.896773, 41.039077],
							[21.896471, 41.039062],
							[21.896176, 41.039075],
							[21.895902, 41.039151],
							[21.89564, 41.039272],
							[21.895472, 41.039319],
							[21.895363, 41.039348],
							[21.895079, 41.039415],
							[21.894798, 41.039491],
							[21.894516, 41.039567],
							[21.894234, 41.039634],
							[21.893954, 41.03971],
							[21.89367, 41.039777],
							[21.893382, 41.039835],
							[21.89309, 41.039874],
							[21.892796, 41.039905],
							[21.892505, 41.039954],
							[21.892217, 41.040011],
							[21.891924, 41.040051],
							[21.891628, 41.040073],
							[21.891339, 41.040121],
							[21.891053, 41.040197],
							[21.890767, 41.040255],
							[21.890473, 41.040277],
							[21.890174, 41.040271],
							[21.889877, 41.040257],
							[21.889582, 41.040233],
							[21.889288, 41.040201],
							[21.888993, 41.040169],
							[21.888697, 41.040145],
							[21.8884, 41.040122],
							[21.888103, 41.040116],
							[21.887805, 41.040102],
							[21.887504, 41.040087],
							[21.887205, 41.040091],
							[21.886912, 41.040103],
							[21.886633, 41.040161],
							[21.886373, 41.040283],
							[21.886131, 41.040413],
							[21.885905, 41.040562],
							[21.885672, 41.040702],
							[21.885425, 41.040833],
							[21.885183, 41.040963],
							[21.884964, 41.041122],
							[21.884737, 41.041261],
							[21.884449, 41.041292],
							[21.884148, 41.041287],
							[21.883851, 41.041263],
							[21.883556, 41.04124],
							[21.88326, 41.041216],
							[21.882957, 41.041211],
							[21.882642, 41.041223],
							[21.882344, 41.041227],
							[21.882114, 41.04115],
							[21.882043, 41.040906],
							[21.882012, 41.040689],
							[21.882011, 41.040464],
							[21.882016, 41.040014],
							[21.882013, 41.039788],
							[21.882002, 41.039563],
							[21.881988, 41.039337],
							[21.881971, 41.039112],
							[21.881957, 41.038886],
							[21.881948, 41.038661],
							[21.881947, 41.038436],
							[21.88195, 41.037986],
							[21.881944, 41.03776],
							[21.881936, 41.037535],
							[21.881942, 41.03731],
							[21.881979, 41.037085],
							[21.882052, 41.036871],
							[21.88214, 41.036656],
							[21.882235, 41.036442],
							[21.882337, 41.036227],
							[21.882445, 41.036013],
							[21.882561, 41.035808],
							[21.88268, 41.035603],
							[21.882798, 41.035398],
							[21.88291, 41.035193],
							[21.883026, 41.034979],
							[21.883143, 41.034774],
							[21.88326, 41.034569],
							[21.88337, 41.034355],
							[21.883472, 41.03414],
							[21.883561, 41.033926],
							[21.883637, 41.033711],
							[21.883707, 41.033496],
							[21.88377, 41.033272],
							[21.883827, 41.033057],
							[21.88388, 41.032833],
							[21.88393, 41.032609],
							[21.883977, 41.032384],
							[21.884022, 41.03216],
							[21.884066, 41.031945],
							[21.884105, 41.03172],
							[21.884137, 41.031496],
							[21.884163, 41.031271],
							[21.884186, 41.031046],
							[21.884206, 41.030821],
							[21.884224, 41.030596],
							[21.884238, 41.030372],
							[21.884245, 41.030146],
							[21.884248, 41.029921],
							[21.884253, 41.029471],
							[21.884256, 41.029246],
							[21.884257, 41.029021],
							[21.884255, 41.028796],
							[21.884254, 41.02857],
							[21.884251, 41.028336],
							[21.884203, 41.028119],
							[21.884049, 41.027927],
							[21.883848, 41.027752],
							[21.883603, 41.027631],
							[21.883313, 41.02758],
							[21.883012, 41.027584],
							[21.882717, 41.027605],
							[21.882423, 41.027645],
							[21.882131, 41.027685],
							[21.88184, 41.027733],
							[21.881549, 41.027782],
							[21.881259, 41.027831],
							[21.880971, 41.027889],
							[21.880685, 41.027946],
							[21.880398, 41.028013],
							[21.880107, 41.028053],
							[21.879809, 41.028038],
							[21.879521, 41.027988],
							[21.879251, 41.027893],
							[21.879013, 41.027753],
							[21.878782, 41.027614],
							[21.878557, 41.027466],
							[21.87833, 41.027318],
							[21.87808, 41.027196],
							[21.877822, 41.027083],
							[21.877557, 41.026979],
							[21.877284, 41.026893],
							[21.877005, 41.026816],
							[21.876721, 41.026747],
							[21.876432, 41.026697],
							[21.876144, 41.026638],
							[21.875858, 41.026569],
							[21.875578, 41.026501],
							[21.875298, 41.026424],
							[21.875021, 41.026338],
							[21.874744, 41.026261],
							[21.874467, 41.026174],
							[21.874183, 41.026106],
							[21.873888, 41.026065],
							[21.873614, 41.025979],
							[21.873545, 41.02577],
							[21.873608, 41.025546],
							[21.873707, 41.025332],
							[21.87381, 41.025126],
							[21.873924, 41.024912],
							[21.874033, 41.024707],
							[21.874117, 41.024493],
							[21.874179, 41.024269],
							[21.874216, 41.024044],
							[21.874231, 41.023819],
							[21.874234, 41.023594],
							[21.874222, 41.023369],
							[21.874182, 41.023143],
							[21.874117, 41.022916],
							[21.874008, 41.022698],
							[21.873823, 41.022542],
							[21.873579, 41.02242],
							[21.873302, 41.022325],
							[21.873017, 41.022239],
							[21.872747, 41.022143],
							[21.872216, 41.021935],
							[21.871947, 41.021831],
							[21.871674, 41.021745],
							[21.871393, 41.021677],
							[21.871104, 41.021609],
							[21.870807, 41.021558],
							[21.870509, 41.021526],
							[21.870215, 41.021511],
							[21.869928, 41.021533],
							[21.869644, 41.021591],
							[21.869364, 41.021667],
							[21.868804, 41.021836],
							[21.868523, 41.021912],
							[21.868236, 41.021979],
							[21.867945, 41.022028],
							[21.867648, 41.022067],
							[21.86735, 41.022089],
							[21.867055, 41.022092],
							[21.866763, 41.022078],
							[21.866471, 41.022027],
							[21.866181, 41.021968],
							[21.865893, 41.021909],
							[21.865605, 41.02184],
							[21.865332, 41.021763],
							[21.865075, 41.02165],
							[21.864826, 41.021519],
							[21.864587, 41.021389],
							[21.864359, 41.021241],
							[21.864144, 41.021084],
							[21.863944, 41.020918],
							[21.863763, 41.020743],
							[21.863594, 41.020551],
							[21.86343, 41.020359],
							[21.863263, 41.020176],
							[21.863082, 41.020001],
							[21.862869, 41.019844],
							[21.862614, 41.019722],
							[21.86234, 41.019636],
							[21.862045, 41.019621],
							[21.861742, 41.019616],
							[21.861444, 41.019601],
							[21.861144, 41.019587],
							[21.860852, 41.019563],
							[21.860585, 41.019468],
							[21.860353, 41.01932],
							[21.860195, 41.019137],
							[21.860167, 41.018911],
							[21.860128, 41.018685],
							[21.860084, 41.018468],
							[21.860041, 41.018242],
							[21.860001, 41.018016],
							[21.859943, 41.017799],
							[21.859877, 41.017582],
							[21.859837, 41.017356],
							[21.859805, 41.01713],
							[21.859739, 41.016912],
							[21.859604, 41.016712],
							[21.859414, 41.016537],
							[21.859175, 41.016398],
							[21.858917, 41.016294],
							[21.85865, 41.01619],
							[21.858122, 41.015982],
							[21.857596, 41.015774],
							[21.857065, 41.015566],
							[21.856797, 41.015471],
							[21.856528, 41.015376],
							[21.855989, 41.015186],
							[21.855721, 41.01509],
							[21.855455, 41.014986],
							[21.855188, 41.014891],
							[21.854924, 41.014787],
							[21.854662, 41.014674],
							[21.854408, 41.014562],
							[21.854173, 41.014422],
							[21.853968, 41.014256],
							[21.853789, 41.014082],
							[21.853675, 41.013872],
							[21.853575, 41.013663],
							[21.853433, 41.013463],
							[21.853279, 41.01327],
							[21.85313, 41.013079],
							[21.852988, 41.012878],
							[21.852847, 41.012677],
							[21.852707, 41.012476],
							[21.852567, 41.012284],
							[21.852427, 41.012084],
							[21.852286, 41.011883],
							[21.852144, 41.011691],
							[21.852, 41.01149],
							[21.851856, 41.011289],
							[21.85171, 41.011097],
							[21.851563, 41.010897],
							[21.851416, 41.010705],
							[21.851268, 41.010513],
							[21.851118, 41.010312],
							[21.850971, 41.01012],
							[21.850823, 41.009928],
							[21.850675, 41.009727],
							[21.850524, 41.009535],
							[21.850372, 41.009343],
							[21.850216, 41.009151],
							[21.850055, 41.008959],
							[21.849887, 41.008776],
							[21.849711, 41.008592],
							[21.849527, 41.008418],
							[21.849343, 41.008243],
							[21.849159, 41.00806],
							[21.848981, 41.007885],
							[21.84881, 41.007702],
							[21.848646, 41.007509],
							[21.848491, 41.007317],
							[21.848342, 41.007126],
							[21.848199, 41.006916],
							[21.84817, 41.006708],
							[21.848268, 41.006485],
							[21.848394, 41.00628],
							[21.84854, 41.006084],
							[21.848668, 41.00588],
							[21.848794, 41.005675],
							[21.848931, 41.005479],
							[21.849081, 41.005284],
							[21.849225, 41.005088],
							[21.849342, 41.004883],
							[21.849435, 41.004669],
							[21.849513, 41.004445],
							[21.84957, 41.004221],
							[21.849604, 41.004006],
							[21.849619, 41.003781],
							[21.849618, 41.003555],
							[21.849608, 41.00333],
							[21.849597, 41.003096],
							[21.849589, 41.00287],
							[21.849578, 41.002645],
							[21.849551, 41.002419],
							[21.849488, 41.002202],
							[21.849371, 41.002002],
							[21.849237, 41.001792],
							[21.849114, 41.001592],
							[21.84899, 41.001382],
							[21.848869, 41.001182],
							[21.848754, 41.000972],
							[21.84864, 41.000763],
							[21.848535, 41.000554],
							[21.848448, 41.000336],
							[21.848387, 41.000119],
							[21.848342, 40.999893],
							[21.848304, 40.999676],
							[21.848264, 40.99945],
							[21.848231, 40.999062],
							[21.848195, 40.998611],
							[21.847997, 40.998445],
							[21.847799, 40.99827],
							[21.847605, 40.998104],
							[21.847417, 40.99793],
							[21.847311, 40.99783],
							[21.847232, 40.997755],
							[21.847056, 40.997572],
							[21.846906, 40.99738],
							[21.846777, 40.99717],
							[21.846586, 40.997005],
							[21.846386, 40.996839],
							[21.846205, 40.996655],
							[21.846034, 40.996472],
							[21.845842, 40.996297],
							[21.845636, 40.99614],
							[21.845425, 40.995983],
							[21.845212, 40.995826],
							[21.84499, 40.995669],
							[21.844747, 40.995547],
							[21.844497, 40.995416],
							[21.844244, 40.995303],
							[21.843986, 40.99519],
							[21.84373, 40.995078],
							[21.84348, 40.994956],
							[21.843232, 40.994834],
							[21.842986, 40.994703],
							[21.84274, 40.994573],
							[21.842486, 40.99446],
							[21.84221, 40.994374],
							[21.841922, 40.994314],
							[21.841629, 40.994282],
							[21.841334, 40.994258],
							[21.840441, 40.994241],
							[21.839846, 40.994248],
							[21.83955, 40.994252],
							[21.838956, 40.994259],
							[21.838658, 40.994253],
							[21.838365, 40.99422],
							[21.838075, 40.99417],
							[21.837791, 40.994102],
							[21.837505, 40.994033],
							[21.837217, 40.993983],
							[21.836926, 40.993932],
							[21.836641, 40.993873],
							[21.836356, 40.993814],
							[21.836069, 40.993754],
							[21.83578, 40.993695],
							[21.835487, 40.993662],
							[21.835192, 40.993629],
							[21.834306, 40.993559],
							[21.834013, 40.993517],
							[21.833717, 40.993503],
							[21.83342, 40.993506],
							[21.833125, 40.993492],
							[21.83283, 40.993459],
							[21.832531, 40.993435],
							[21.832263, 40.99334],
							[21.83201, 40.993218],
							[21.831773, 40.993088],
							[21.831562, 40.992931],
							[21.831367, 40.992756],
							[21.831174, 40.992581],
							[21.830993, 40.992407],
							[21.830828, 40.992223],
							[21.830665, 40.992031],
							[21.830498, 40.991848],
							[21.830328, 40.991656],
							[21.830158, 40.991472],
							[21.829989, 40.991289],
							[21.829821, 40.991106],
							[21.829661, 40.990913],
							[21.82952, 40.990713],
							[21.829386, 40.990512],
							[21.829257, 40.990311],
							[21.8291, 40.990119],
							[21.828849, 40.990006],
							[21.828566, 40.989938],
							[21.828274, 40.989878],
							[21.827983, 40.989846],
							[21.827686, 40.989822],
							[21.827388, 40.98979],
							[21.827107, 40.989721],
							[21.826849, 40.989608],
							[21.826606, 40.989478],
							[21.826393, 40.98932],
							[21.826222, 40.989137],
							[21.82607, 40.988945],
							[21.825912, 40.988753],
							[21.825746, 40.988561],
							[21.825576, 40.988377],
							[21.825409, 40.988185],
							[21.825141, 40.988117],
							[21.824535, 40.988106],
							[21.824238, 40.988109],
							[21.824022, 40.988105],
							[21.823644, 40.988098],
							[21.823348, 40.988083],
							[21.822754, 40.988072],
							[21.821863, 40.988028],
							[21.821568, 40.988004],
							[21.821278, 40.987954],
							[21.820984, 40.987912],
							[21.820692, 40.987871],
							[21.820403, 40.98782],
							[21.820117, 40.987761],
							[21.819848, 40.987666],
							[21.819605, 40.987535],
							[21.819374, 40.987395],
							[21.81914, 40.987256],
							[21.818916, 40.987108],
							[21.818721, 40.986933],
							[21.818544, 40.986758],
							[21.818358, 40.986575],
							[21.818167, 40.9864],
							[21.817931, 40.986269],
							[21.81766, 40.986183],
							[21.817373, 40.986106],
							[21.817086, 40.986046],
							[21.816789, 40.986041],
							[21.816491, 40.986026],
							[21.816194, 40.985993],
							[21.815904, 40.985943],
							[21.815635, 40.985856],
							[21.815386, 40.985735],
							[21.815149, 40.985595],
							[21.81492, 40.985447],
							[21.814699, 40.985298],
							[21.814492, 40.985141],
							[21.814294, 40.984966],
							[21.814102, 40.984801],
							[21.813912, 40.984626],
							[21.813724, 40.984451],
							[21.813549, 40.984268],
							[21.813385, 40.984075],
							[21.813226, 40.983892],
							[21.81308, 40.983691],
							[21.812966, 40.983482],
							[21.812866, 40.983273],
							[21.812736, 40.983072],
							[21.812604, 40.982872],
							[21.812468, 40.982671],
							[21.812328, 40.98247],
							[21.812183, 40.982269],
							[21.812024, 40.982086],
							[21.81184, 40.981902],
							[21.811631, 40.981745],
							[21.811426, 40.981579],
							[21.811243, 40.981405],
							[21.811028, 40.981247],
							[21.810796, 40.981108],
							[21.81056, 40.980968],
							[21.810319, 40.980838],
							[21.810069, 40.980716],
							[21.809807, 40.980612],
							[21.809538, 40.980517],
							[21.809262, 40.98043],
							[21.808974, 40.98038],
							[21.808681, 40.980338],
							[21.808386, 40.980314],
							[21.808088, 40.980291],
							[21.807794, 40.980258],
							[21.807512, 40.98019],
							[21.807253, 40.980077],
							[21.807049, 40.979911],
							[21.806873, 40.979727],
							[21.806721, 40.979535],
							[21.806598, 40.979326],
							[21.806497, 40.979117],
							[21.806403, 40.978899],
							[21.806325, 40.978681],
							[21.80616, 40.978498],
							[21.805965, 40.978332],
							[21.805757, 40.978166],
							[21.805539, 40.978009],
							[21.805307, 40.977869],
							[21.805031, 40.977783],
							[21.804814, 40.977634],
							[21.804612, 40.977468],
							[21.804394, 40.977311],
							[21.804172, 40.977163],
							[21.803953, 40.977015],
							[21.803737, 40.976857],
							[21.803522, 40.9767],
							[21.80331, 40.976543],
							[21.803101, 40.976386],
							[21.8029, 40.97622],
							[21.802695, 40.976054],
							[21.802477, 40.975906],
							[21.802251, 40.975757],
							[21.802023, 40.975609],
							[21.801794, 40.975469],
							[21.801585, 40.975303],
							[21.801392, 40.975137],
							[21.801203, 40.974962],
							[21.800999, 40.974787],
							[21.80081, 40.974613],
							[21.800686, 40.974421],
							[21.800639, 40.974195],
							[21.800632, 40.97397],
							[21.800656, 40.973745],
							[21.80071, 40.973521],
							[21.800736, 40.973296],
							[21.800749, 40.973071],
							[21.800754, 40.972846],
							[21.80075, 40.972621],
							[21.80072, 40.972395],
							[21.800701, 40.97217],
							[21.800727, 40.971945],
							[21.800809, 40.97173],
							[21.800909, 40.971516],
							[21.80099, 40.971301],
							[21.80103, 40.971077],
							[21.801063, 40.970852],
							[21.801133, 40.970638],
							[21.8012, 40.970414],
							[21.801224, 40.970189],
							[21.801237, 40.969964],
							[21.801254, 40.969739],
							[21.801284, 40.969515],
							[21.801318, 40.96929],
							[21.801351, 40.969074],
							[21.801387, 40.96885],
							[21.801417, 40.968625],
							[21.801429, 40.968401],
							[21.801425, 40.968175],
							[21.801427, 40.96795],
							[21.801437, 40.967725],
							[21.801441, 40.9675],
							[21.801448, 40.967275],
							[21.801424, 40.966599],
							[21.801363, 40.966372],
							[21.801289, 40.966155],
							[21.801205, 40.965946],
							[21.801127, 40.965728],
							[21.801065, 40.965502],
							[21.801014, 40.965285],
							[21.800982, 40.965059],
							[21.800973, 40.964834],
							[21.801002, 40.964609],
							[21.801093, 40.964395],
							[21.801181, 40.96418],
							[21.801249, 40.963956],
							[21.801211, 40.96374],
							[21.801126, 40.963522],
							[21.801031, 40.963304],
							[21.800945, 40.963086],
							[21.80086, 40.962877],
							[21.800787, 40.96266],
							[21.800721, 40.962433],
							[21.800669, 40.962216],
							[21.800646, 40.96199],
							[21.800645, 40.961765],
							[21.800652, 40.96154],
							[21.800671, 40.961315],
							[21.800704, 40.961091],
							[21.800732, 40.960866],
							[21.800747, 40.960641],
							[21.800757, 40.960416],
							[21.800757, 40.960191],
							[21.800773, 40.959966],
							[21.800828, 40.959742],
							[21.800884, 40.959527],
							[21.800901, 40.959302],
							[21.800898, 40.959077],
							[21.800892, 40.958852],
							[21.800897, 40.958626],
							[21.800917, 40.958402],
							[21.800937, 40.958177],
							[21.800927, 40.957942],
							[21.801048, 40.957747],
							[21.801253, 40.957579],
							[21.801473, 40.95743],
							[21.801713, 40.957291],
							[21.801948, 40.957151],
							[21.802173, 40.957011],
							[21.802404, 40.956863],
							[21.802632, 40.956723],
							[21.802858, 40.956574],
							[21.803077, 40.956425],
							[21.80326, 40.956249],
							[21.803405, 40.956053],
							[21.803535, 40.955848],
							[21.803651, 40.955635],
							[21.803755, 40.955429],
							[21.803849, 40.955215],
							[21.803934, 40.955],
							[21.804019, 40.954786],
							[21.8041, 40.954562],
							[21.804197, 40.954357],
							[21.804327, 40.954152],
							[21.804467, 40.953948],
							[21.804588, 40.953743],
							[21.80469, 40.953538],
							[21.804792, 40.953323],
							[21.804906, 40.953118],
							[21.805026, 40.952913],
							[21.805157, 40.952709],
							[21.805286, 40.952504],
							[21.805373, 40.952289],
							[21.805407, 40.952065],
							[21.805418, 40.95184],
							[21.805404, 40.951615],
							[21.805361, 40.951389],
							[21.805297, 40.951171],
							[21.805218, 40.950953],
							[21.805168, 40.950844],
							[21.805125, 40.950744],
							[21.805016, 40.950526],
							[21.804883, 40.950326],
							[21.804798, 40.950117],
							[21.804767, 40.949891],
							[21.804684, 40.949673],
							[21.804584, 40.949464],
							[21.804445, 40.949263],
							[21.804394, 40.949197],
							[21.804297, 40.949071],
							[21.804153, 40.94887],
							[21.804015, 40.94867],
							[21.803874, 40.948469],
							[21.803731, 40.948277],
							[21.803584, 40.948076],
							[21.803436, 40.947884],
							[21.803282, 40.947692],
							[21.803145, 40.947491],
							[21.803067, 40.947274],
							[21.803047, 40.947085],
							[21.803054, 40.946823],
							[21.803101, 40.946599],
							[21.803234, 40.946403],
							[21.803376, 40.946199],
							[21.803652, 40.945808],
							[21.803791, 40.945603],
							[21.803933, 40.945408],
							[21.804076, 40.945212],
							[21.804212, 40.945008],
							[21.80436, 40.944812],
							[21.804461, 40.944598],
							[21.804424, 40.944381],
							[21.804224, 40.944215],
							[21.803984, 40.944075],
							[21.80373, 40.943962],
							[21.803437, 40.943921],
							[21.803167, 40.943826],
							[21.802907, 40.943713],
							[21.802655, 40.943591],
							[21.802399, 40.943478],
							[21.802136, 40.943374],
							[21.80193, 40.943217],
							[21.801783, 40.943016],
							[21.801668, 40.942806],
							[21.801574, 40.942597],
							[21.801464, 40.942388],
							[21.801328, 40.942187],
							[21.801127, 40.942021],
							[21.800899, 40.941873],
							[21.800648, 40.941751],
							[21.800376, 40.941665],
							[21.8001, 40.941578],
							[21.799822, 40.941501],
							[21.799535, 40.941442],
							[21.799244, 40.9414],
							[21.798953, 40.941349],
							[21.798667, 40.94129],
							[21.798385, 40.941221],
							[21.798104, 40.941144],
							[21.797817, 40.941093],
							[21.797522, 40.94107],
							[21.797225, 40.941055],
							[21.796928, 40.941031],
							[21.796629, 40.940999],
							[21.796359, 40.940921],
							[21.796123, 40.940782],
							[21.795904, 40.940624],
							[21.795698, 40.940467],
							[21.79551, 40.940293],
							[21.795317, 40.940118],
							[21.795127, 40.939943],
							[21.79493, 40.939777],
							[21.794721, 40.93962],
							[21.7945, 40.939472],
							[21.794277, 40.939314],
							[21.794049, 40.939175],
							[21.793825, 40.939026],
							[21.793623, 40.93886],
							[21.793444, 40.938686],
							[21.793262, 40.938502],
							[21.793059, 40.938336],
							[21.792839, 40.938188],
							[21.792624, 40.938031],
							[21.792427, 40.937865],
							[21.792245, 40.93769],
							[21.792072, 40.937498],
							[21.791897, 40.937323],
							[21.791673, 40.937175],
							[21.791427, 40.937044],
							[21.791176, 40.936922],
							[21.790923, 40.936809],
							[21.790699, 40.936661],
							[21.790495, 40.936495],
							[21.790299, 40.936329],
							[21.790109, 40.936154],
							[21.789928, 40.935979],
							[21.789766, 40.935787],
							[21.789639, 40.935586],
							[21.78953, 40.935368],
							[21.789467, 40.935151],
							[21.789484, 40.934926],
							[21.789528, 40.934702],
							[21.789542, 40.934477],
							[21.789553, 40.934252],
							[21.789613, 40.934037],
							[21.789664, 40.933813],
							[21.789669, 40.933588],
							[21.789667, 40.933362],
							[21.789658, 40.933137],
							[21.789635, 40.932911],
							[21.789571, 40.932694],
							[21.789481, 40.932476],
							[21.789375, 40.932267],
							[21.789263, 40.932057],
							[21.789128, 40.931857],
							[21.788949, 40.931682],
							[21.788749, 40.931507],
							[21.788671, 40.931432],
							[21.78856, 40.931332],
							[21.78732, 40.931276],
							[21.786775, 40.931098],
							[21.786372, 40.930972],
							[21.786108, 40.930824],
							[21.785906, 40.930656],
							[21.785426, 40.930207],
							[21.784866, 40.929668],
							[21.78416, 40.928981],
							[21.78324, 40.928546],
							[21.782944, 40.928567],
							[21.782646, 40.92858],
							[21.782054, 40.928604],
							[21.781759, 40.928626],
							[21.781463, 40.928647],
							[21.781176, 40.928705],
							[21.780897, 40.92878],
							[21.780607, 40.928829],
							[21.780314, 40.928859],
							[21.780022, 40.928898],
							[21.779728, 40.928938],
							[21.779437, 40.928977],
							[21.779146, 40.929026],
							[21.778855, 40.929065],
							[21.77856, 40.929105],
							[21.778267, 40.929135],
							[21.777976, 40.929174],
							[21.777687, 40.929223],
							[21.777397, 40.929271],
							[21.777104, 40.92932],
							[21.776811, 40.929341],
							[21.776219, 40.929293],
							[21.775922, 40.929279],
							[21.775628, 40.929255],
							[21.775332, 40.929231],
							[21.775038, 40.929208],
							[21.774448, 40.929142],
							[21.774161, 40.929092],
							[21.773882, 40.929014],
							[21.773609, 40.928919],
							[21.773339, 40.928833],
							[21.773072, 40.928728],
							[21.772804, 40.928633],
							[21.772537, 40.928538],
							[21.772268, 40.928443],
							[21.771994, 40.928347],
							[21.771472, 40.928265],
							[21.771175, 40.92825],
							[21.770881, 40.928227],
							[21.769694, 40.928204],
							[21.769397, 40.928207],
							[21.768801, 40.928195],
							[21.768505, 40.928208],
							[21.768218, 40.928256],
							[21.767932, 40.928314],
							[21.767346, 40.928392],
							[21.767051, 40.928423],
							[21.766759, 40.928453],
							[21.766472, 40.92852],
							[21.766185, 40.928577],
							[21.765886, 40.928589],
							[21.7656, 40.928539],
							[21.765317, 40.92847],
							[21.765038, 40.928384],
							[21.764766, 40.928297],
							[21.764498, 40.928202],
							[21.764239, 40.928089],
							[21.764003, 40.927958],
							[21.763796, 40.927792],
							[21.763582, 40.927635],
							[21.763357, 40.927486],
							[21.763147, 40.927329],
							[21.762946, 40.927163],
							[21.762744, 40.926997],
							[21.762532, 40.92684],
							[21.762297, 40.926709],
							[21.762021, 40.926614],
							[21.761737, 40.926545],
							[21.761445, 40.926504],
							[21.761157, 40.926444],
							[21.760886, 40.926358],
							[21.760624, 40.926253],
							[21.760368, 40.926131],
							[21.760122, 40.926009],
							[21.759866, 40.925896],
							[21.759575, 40.925846],
							[21.759278, 40.92584],
							[21.758989, 40.925789],
							[21.758711, 40.925712],
							[21.758442, 40.925616],
							[21.758186, 40.925503],
							[21.757943, 40.925373],
							[21.757687, 40.92526],
							[21.7574, 40.9252],
							[21.757109, 40.925158],
							[21.75682, 40.925098],
							[21.756524, 40.925084],
							[21.756227, 40.925087],
							[21.755634, 40.925075],
							[21.755336, 40.925079],
							[21.755039, 40.9251],
							[21.754757, 40.925166],
							[21.754471, 40.925215],
							[21.754176, 40.925254],
							[21.753888, 40.925303],
							[21.753598, 40.925369],
							[21.753447, 40.925555],
							[21.753364, 40.925779],
							[21.753334, 40.926003],
							[21.753339, 40.926229],
							[21.753361, 40.926445],
							[21.753345, 40.92667],
							[21.753347, 40.926895],
							[21.753354, 40.927139],
							[21.753215, 40.927307],
							[21.752964, 40.927428],
							[21.752703, 40.927549],
							[21.75247, 40.927689],
							[21.752236, 40.927838],
							[21.751983, 40.92795],
							[21.751714, 40.928044],
							[21.751457, 40.928156],
							[21.751217, 40.928286],
							[21.750988, 40.928435],
							[21.750745, 40.928565],
							[21.750488, 40.928677],
							[21.750228, 40.92878],
							[21.749876, 40.928908],
							[21.749365, 40.928979],
							[21.749177, 40.929003],
							[21.74886, 40.929213],
							[21.748656, 40.92938],
							[21.748447, 40.929538],
							[21.748223, 40.929687],
							[21.747986, 40.929826],
							[21.747743, 40.929948],
							[21.747493, 40.930078],
							[21.747228, 40.930172],
							[21.746944, 40.930238],
							[21.746649, 40.930278],
							[21.746355, 40.930299],
							[21.746059, 40.930275],
							[21.745763, 40.930242],
							[21.745472, 40.9302],
							[21.74518, 40.930159],
							[21.744884, 40.930144],
							[21.74429, 40.930132],
							[21.743993, 40.930144],
							[21.743697, 40.930148],
							[21.7434, 40.930169],
							[21.742809, 40.930211],
							[21.742515, 40.930242],
							[21.742219, 40.930272],
							[21.741927, 40.930311],
							[21.741642, 40.93036],
							[21.741364, 40.930444],
							[21.741093, 40.930538],
							[21.740823, 40.930632],
							[21.740557, 40.930735],
							[21.740291, 40.930828],
							[21.740019, 40.930922],
							[21.739747, 40.931016],
							[21.739484, 40.931119],
							[21.73923, 40.93124],
							[21.738973, 40.931352],
							[21.738701, 40.931437],
							[21.738411, 40.931485],
							[21.738114, 40.931515],
							[21.737817, 40.931519],
							[21.73752, 40.931495],
							[21.737235, 40.931435],
							[21.736966, 40.93134],
							[21.736694, 40.931244],
							[21.736157, 40.931054],
							[21.735889, 40.930958],
							[21.735626, 40.930854],
							[21.735363, 40.93075],
							[21.734836, 40.930541],
							[21.73457, 40.930437],
							[21.734301, 40.930342],
							[21.734025, 40.930264],
							[21.733741, 40.930196],
							[21.733453, 40.930145],
							[21.733162, 40.930094],
							[21.732871, 40.930052],
							[21.732576, 40.930011],
							[21.73228, 40.929987],
							[21.731986, 40.929981],
							[21.731689, 40.930011],
							[21.7314, 40.930077],
							[21.731137, 40.930171],
							[21.730897, 40.930311],
							[21.730683, 40.930469],
							[21.730495, 40.930645],
							[21.730316, 40.930822],
							[21.730147, 40.931007],
							[21.729982, 40.931193],
							[21.729826, 40.931388],
							[21.729668, 40.931575],
							[21.729506, 40.93177],
							[21.729339, 40.931955],
							[21.72916, 40.932132],
							[21.728971, 40.932308],
							[21.728783, 40.932476],
							[21.728596, 40.932652],
							[21.7284, 40.93282],
							[21.728185, 40.932977],
							[21.72795, 40.933117],
							[21.727705, 40.933247],
							[21.727448, 40.933359],
							[21.727191, 40.933471],
							[21.726942, 40.933593],
							[21.726695, 40.933714],
							[21.726448, 40.933844],
							[21.726197, 40.933965],
							[21.72594, 40.934077],
							[21.72569, 40.934198],
							[21.725462, 40.934338],
							[21.725256, 40.934505],
							[21.725061, 40.934672],
							[21.724873, 40.934849],
							[21.724694, 40.935025],
							[21.72451, 40.935202],
							[21.724315, 40.935378],
							[21.724118, 40.935545],
							[21.723913, 40.935704],
							[21.723696, 40.935861],
							[21.723465, 40.936001],
							[21.723222, 40.936131],
							[21.722955, 40.936234],
							[21.722711, 40.936355],
							[21.722482, 40.936504],
							[21.722254, 40.936653],
							[21.722016, 40.936783],
							[21.721758, 40.936895],
							[21.721483, 40.936979],
							[21.721209, 40.937073],
							[21.720952, 40.937176],
							[21.720715, 40.937316],
							[21.720484, 40.937455],
							[21.720247, 40.937595],
							[21.719993, 40.937707],
							[21.719725, 40.937809],
							[21.71945, 40.937894],
							[21.719168, 40.937961],
							[21.718888, 40.938036],
							[21.718613, 40.93813],
							[21.718337, 40.938205],
							[21.718054, 40.938272],
							[21.717766, 40.938329],
							[21.717475, 40.938377],
							[21.717179, 40.938399],
							[21.716883, 40.93842],
							[21.716589, 40.938441],
							[21.716292, 40.938462],
							[21.715998, 40.938492],
							[21.715704, 40.938532],
							[21.715406, 40.938562],
							[21.715113, 40.938601],
							[21.714831, 40.938667],
							[21.714572, 40.93877],
							[21.714334, 40.93891],
							[21.714115, 40.939058],
							[21.713926, 40.939235],
							[21.71374, 40.939411],
							[21.713539, 40.939578],
							[21.713331, 40.939745],
							[21.71311, 40.939894],
							[21.712864, 40.940015],
							[21.712591, 40.940109],
							[21.712306, 40.940157],
							[21.712004, 40.940151],
							[21.711709, 40.940137],
							[21.711415, 40.940095],
							[21.711116, 40.940044],
							[21.710821, 40.940011],
							[21.710542, 40.940032],
							[21.71028, 40.940153],
							[21.710061, 40.940311],
							[21.709877, 40.940488],
							[21.709719, 40.940683],
							[21.709624, 40.940897],
							[21.709499, 40.941102],
							[21.70937, 40.941306],
							[21.709247, 40.941502],
							[21.709121, 40.941707],
							[21.708982, 40.941911],
							[21.708839, 40.942106],
							[21.708524, 40.942352],
							[21.708299, 40.942501],
							[21.708054, 40.942631],
							[21.7078, 40.942743],
							[21.707537, 40.942846],
							[21.707277, 40.942958],
							[21.707018, 40.94307],
							[21.706754, 40.943173],
							[21.70648, 40.943257],
							[21.7062, 40.943333],
							[21.705913, 40.94339],
							[21.705618, 40.94342],
							[21.705023, 40.943444],
							[21.704725, 40.943457],
							[21.704428, 40.943451],
							[21.704136, 40.943409],
							[21.703843, 40.943358],
							[21.703559, 40.943289],
							[21.7033, 40.943194],
							[21.703087, 40.943027],
							[21.702874, 40.94287],
							[21.702665, 40.942713],
							[21.70246, 40.942547],
							[21.702256, 40.94238],
							[21.702057, 40.942214],
							[21.701871, 40.942039],
							[21.7017, 40.941856],
							[21.701526, 40.941672],
							[21.701328, 40.941506],
							[21.701112, 40.941349],
							[21.700885, 40.941209],
							[21.700643, 40.941078],
							[21.700378, 40.940965],
							[21.700099, 40.940896],
							[21.699803, 40.940908],
							[21.699503, 40.940938],
							[21.69921, 40.940978],
							[21.698926, 40.941044],
							[21.698657, 40.941138],
							[21.698392, 40.94124],
							[21.698128, 40.941343],
							[21.697869, 40.941455],
							[21.697594, 40.94154],
							[21.697312, 40.941615],
							[21.69703, 40.941682],
							[21.696746, 40.941748],
							[21.696459, 40.941805],
							[21.696164, 40.941845],
							[21.695868, 40.941866],
							[21.695578, 40.941824],
							[21.695293, 40.941755],
							[21.695007, 40.941695],
							[21.694725, 40.941618],
							[21.694451, 40.941531],
							[21.694188, 40.941427],
							[21.693916, 40.94134],
							[21.693631, 40.941271],
							[21.69334, 40.94123],
							[21.693042, 40.941215],
							[21.692751, 40.941173],
							[21.692478, 40.941095],
							[21.692211, 40.940991],
							[21.691945, 40.940886],
							[21.691673, 40.9408],
							[21.691387, 40.94074],
							[21.691091, 40.940707],
							[21.690804, 40.940656],
							[21.690534, 40.940561],
							[21.690254, 40.940483],
							[21.689962, 40.940432],
							[21.689669, 40.940408],
							[21.689367, 40.940429],
							[21.689091, 40.940514],
							[21.688898, 40.940672],
							[21.688758, 40.940886],
							[21.688664, 40.9411],
							[21.688537, 40.941296],
							[21.688294, 40.941426],
							[21.688005, 40.941492],
							[21.687706, 40.941486],
							[21.687418, 40.941417],
							[21.687153, 40.941322],
							[21.686636, 40.941095],
							[21.686379, 40.940982],
							[21.686117, 40.940878],
							[21.685844, 40.940782],
							[21.685569, 40.940705],
							[21.685303, 40.9406],
							[21.685051, 40.940478],
							[21.684791, 40.940374],
							[21.684497, 40.940314],
							[21.684198, 40.940272],
							[21.683938, 40.940185],
							[21.683785, 40.939993],
							[21.683674, 40.939775],
							[21.683573, 40.939566],
							[21.683485, 40.939348],
							[21.683408, 40.93913],
							[21.683344, 40.938912],
							[21.683304, 40.938695],
							[21.683275, 40.93847],
							[21.68324, 40.938244],
							[21.683199, 40.938018],
							[21.683175, 40.937792],
							[21.683159, 40.937567],
							[21.68314, 40.93735],
							[21.683107, 40.937124],
							[21.68306, 40.936898],
							[21.683012, 40.936681],
							[21.682915, 40.936445],
							[21.682826, 40.936236],
							[21.682742, 40.936018],
							[21.682652, 40.9358],
							[21.682553, 40.935591],
							[21.68248, 40.935373],
							[21.682441, 40.935147],
							[21.682399, 40.93493],
							[21.682355, 40.934704],
							[21.682326, 40.934478],
							[21.682314, 40.934253],
							[21.682283, 40.934027],
							[21.6822, 40.933818],
							[21.682081, 40.933609],
							[21.681936, 40.933408],
							[21.68174, 40.933242],
							[21.681493, 40.933119],
							[21.681213, 40.933033],
							[21.68094, 40.932946],
							[21.680658, 40.932878],
							[21.680366, 40.932836],
							[21.680081, 40.932776],
							[21.679802, 40.932698],
							[21.679527, 40.932612],
							[21.67926, 40.932507],
							[21.678989, 40.932421],
							[21.678714, 40.932334],
							[21.678482, 40.932194],
							[21.678278, 40.932028],
							[21.678096, 40.931853],
							[21.677937, 40.931661],
							[21.677774, 40.931468],
							[21.677599, 40.931294],
							[21.677437, 40.931101],
							[21.677294, 40.9309],
							[21.677137, 40.930717],
							[21.676973, 40.930524],
							[21.6768, 40.930341],
							[21.676625, 40.930157],
							[21.676442, 40.929982],
							[21.676253, 40.929807],
							[21.676055, 40.929641],
							[21.675858, 40.929475],
							[21.675662, 40.9293],
							[21.675465, 40.929134],
							[21.675258, 40.928977],
							[21.675039, 40.928819],
							[21.674816, 40.928671],
							[21.67459, 40.928531],
							[21.674361, 40.928382],
							[21.674127, 40.928242],
							[21.673889, 40.928111],
							[21.673655, 40.927972],
							[21.67319, 40.927692],
							[21.672964, 40.927543],
							[21.672763, 40.927377],
							[21.672583, 40.927202],
							[21.672416, 40.927019],
							[21.672259, 40.926826],
							[21.672106, 40.926634],
							[21.671952, 40.926442],
							[21.671786, 40.926249],
							[21.671608, 40.926075],
							[21.671428, 40.925891],
							[21.671257, 40.925707],
							[21.671088, 40.925524],
							[21.670925, 40.925331],
							[21.670773, 40.925139],
							[21.670631, 40.924947],
							[21.670497, 40.924737],
							[21.67037, 40.924537],
							[21.670255, 40.924327],
							[21.67015, 40.924118],
							[21.67004, 40.923908],
							[21.669927, 40.923699],
							[21.669805, 40.923498],
							[21.669664, 40.923297],
							[21.669511, 40.923105],
							[21.669356, 40.922913],
							[21.669208, 40.922721],
							[21.669061, 40.922519],
							[21.668921, 40.922328],
							[21.668796, 40.922118],
							[21.668686, 40.921908],
							[21.668589, 40.921699],
							[21.66851, 40.921481],
							[21.668458, 40.921255],
							[21.668429, 40.921039],
							[21.668427, 40.920804],
							[21.66842, 40.920579],
							[21.668365, 40.920362],
							[21.668247, 40.920161],
							[21.668107, 40.91996],
							[21.667974, 40.91975],
							[21.667838, 40.919558],
							[21.667697, 40.919357],
							[21.66755, 40.919156],
							[21.66739, 40.918973],
							[21.667237, 40.918781],
							[21.667099, 40.91858],
							[21.666968, 40.918379],
							[21.666837, 40.918178],
							[21.666696, 40.917977],
							[21.666569, 40.917776],
							[21.666464, 40.917558],
							[21.666373, 40.917349],
							[21.666308, 40.917132],
							[21.666287, 40.916906],
							[21.6663, 40.916681],
							[21.666342, 40.916457],
							[21.666405, 40.916233],
							[21.666493, 40.916018],
							[21.666599, 40.915804],
							[21.66672, 40.9156],
							[21.666869, 40.915405],
							[21.667047, 40.915228],
							[21.667232, 40.915051],
							[21.667423, 40.914875],
							[21.667611, 40.914708],
							[21.66781, 40.914541],
							[21.668033, 40.914392],
							[21.668279, 40.914262],
							[21.668546, 40.914159],
							[21.668826, 40.914093],
							[21.669114, 40.914036],
							[21.669405, 40.913988],
							[21.669699, 40.913948],
							[21.66999, 40.9139],
							[21.670279, 40.913834],
							[21.670498, 40.913694],
							[21.670512, 40.91346],
							[21.670492, 40.913235],
							[21.670458, 40.913009],
							[21.670398, 40.912792],
							[21.670304, 40.912583],
							[21.670199, 40.912364],
							[21.670096, 40.912155],
							[21.669981, 40.911946],
							[21.669841, 40.911754],
							[21.669685, 40.911561],
							[21.669513, 40.911378],
							[21.669396, 40.911168],
							[21.669295, 40.910959],
							[21.669186, 40.91075],
							[21.669097, 40.910532],
							[21.66905, 40.910305],
							[21.669044, 40.91008],
							[21.669123, 40.909866],
							[21.669264, 40.909661],
							[21.669414, 40.909475],
							[21.669588, 40.909289],
							[21.669777, 40.909113],
							[21.669977, 40.908946],
							[21.670189, 40.908788],
							[21.670405, 40.908639],
							[21.670621, 40.908482],
							[21.670825, 40.908315],
							[21.671023, 40.908148],
							[21.671211, 40.90798],
							[21.671388, 40.907795],
							[21.671551, 40.907609],
							[21.671696, 40.907414],
							[21.671816, 40.9072],
							[21.671908, 40.906995],
							[21.671969, 40.906771],
							[21.672017, 40.906546],
							[21.672072, 40.906322],
							[21.672106, 40.906107],
							[21.672109, 40.905882],
							[21.672108, 40.905648],
							[21.672111, 40.905422],
							[21.672113, 40.905197],
							[21.672109, 40.904972],
							[21.672103, 40.904756],
							[21.672106, 40.904522],
							[21.672107, 40.904296],
							[21.672094, 40.90408],
							[21.67205, 40.903854],
							[21.671982, 40.903636],
							[21.671924, 40.90341],
							[21.671875, 40.903193],
							[21.671832, 40.902967],
							[21.671794, 40.902741],
							[21.671771, 40.902524],
							[21.671766, 40.902299],
							[21.671765, 40.902074],
							[21.671761, 40.901848],
							[21.671756, 40.901623],
							[21.671748, 40.901173],
							[21.671747, 40.900947],
							[21.671741, 40.900722],
							[21.671733, 40.900497],
							[21.671728, 40.900262],
							[21.67171, 40.900046],
							[21.671664, 40.89982],
							[21.671572, 40.899602],
							[21.671444, 40.899401],
							[21.671285, 40.899209],
							[21.671083, 40.899043],
							[21.670836, 40.89892],
							[21.670555, 40.898843],
							[21.670267, 40.898792],
							[21.66997, 40.898777],
							[21.669671, 40.898771],
							[21.669369, 40.898756],
							[21.669075, 40.898768],
							[21.668812, 40.898852],
							[21.668571, 40.898992],
							[21.668328, 40.899122],
							[21.668086, 40.899252],
							[21.667847, 40.899391],
							[21.667613, 40.899531],
							[21.667358, 40.899634],
							[21.667068, 40.899691],
							[21.666769, 40.899712],
							[21.666176, 40.8997],
							[21.665877, 40.899685],
							[21.665582, 40.899652],
							[21.665305, 40.899574],
							[21.665038, 40.89947],
							[21.664772, 40.899365],
							[21.664497, 40.899287],
							[21.664208, 40.899264],
							[21.663913, 40.899267],
							[21.663613, 40.899279],
							[21.663314, 40.899291],
							[21.663016, 40.899302],
							[21.662423, 40.899273],
							[21.662126, 40.899276],
							[21.66183, 40.899287],
							[21.661533, 40.899299],
							[21.661236, 40.899302],
							[21.660943, 40.899332],
							[21.660649, 40.899372],
							[21.660357, 40.899411],
							[21.660067, 40.899459],
							[21.659778, 40.899507],
							[21.659487, 40.899555],
							[21.659196, 40.899594],
							[21.658907, 40.899642],
							[21.65862, 40.8997],
							[21.658334, 40.899766],
							[21.65805, 40.899832],
							[21.657485, 40.899965],
							[21.657205, 40.90004],
							[21.656926, 40.900125],
							[21.656648, 40.9002],
							[21.656366, 40.900266],
							[21.656079, 40.900324],
							[21.655791, 40.900381],
							[21.655504, 40.900438],
							[21.655218, 40.900495],
							[21.654932, 40.900562],
							[21.654648, 40.900628],
							[21.654364, 40.900694],
							[21.6538, 40.900827],
							[21.653518, 40.900902],
							[21.653233, 40.900959],
							[21.652944, 40.901016],
							[21.652652, 40.901055],
							[21.652361, 40.901095],
							[21.652068, 40.901134],
							[21.651482, 40.901212],
							[21.65119, 40.901242],
							[21.650895, 40.901272],
							[21.6506, 40.901302],
							[21.650305, 40.901314],
							[21.650009, 40.901335],
							[21.649713, 40.901347],
							[21.649416, 40.901359],
							[21.649119, 40.901362],
							[21.648821, 40.901356],
							[21.64853, 40.901323],
							[21.648246, 40.901254],
							[21.647957, 40.901212],
							[21.64766, 40.901197],
							[21.647366, 40.901164],
							[21.647075, 40.901113],
							[21.646799, 40.901035],
							[21.646536, 40.900931],
							[21.646276, 40.900817],
							[21.646017, 40.900713],
							[21.645758, 40.9006],
							[21.645481, 40.900522],
							[21.645221, 40.900418],
							[21.64501, 40.90026],
							[21.644811, 40.900085],
							[21.644595, 40.899936],
							[21.644367, 40.899788],
							[21.64414, 40.899648],
							[21.643937, 40.899482],
							[21.643734, 40.899315],
							[21.643503, 40.899175],
							[21.643297, 40.899018],
							[21.643126, 40.898826],
							[21.642946, 40.898651],
							[21.642736, 40.898493],
							[21.642512, 40.898345],
							[21.642285, 40.898196],
							[21.642055, 40.898038],
							[21.641812, 40.897925],
							[21.641533, 40.897883],
							[21.641228, 40.897922],
							[21.640937, 40.897961],
							[21.640647, 40.898009],
							[21.640355, 40.898057],
							[21.640063, 40.898096],
							[21.63977, 40.898126],
							[21.639474, 40.898147],
							[21.639177, 40.898132],
							[21.63889, 40.898081],
							[21.638648, 40.89795],
							[21.638428, 40.897793],
							[21.638232, 40.897627],
							[21.638047, 40.897452],
							[21.637856, 40.897276],
							[21.637659, 40.89711],
							[21.637447, 40.896953],
							[21.63719, 40.896839],
							[21.636912, 40.896762],
							[21.636628, 40.896693],
							[21.636336, 40.896651],
							[21.636042, 40.896627],
							[21.635748, 40.896594],
							[21.635454, 40.896561],
							[21.635159, 40.896537],
							[21.634859, 40.89653],
							[21.634572, 40.896489],
							[21.634303, 40.896384],
							[21.63408, 40.896235],
							[21.633925, 40.896043],
							[21.633818, 40.895834],
							[21.633737, 40.895616],
							[21.63367, 40.895398],
							[21.633616, 40.895172],
							[21.63355, 40.894954],
							[21.633482, 40.894737],
							[21.633411, 40.894519],
							[21.633346, 40.894302],
							[21.63329, 40.894075],
							[21.633236, 40.893858],
							[21.633186, 40.893632],
							[21.63314, 40.893415],
							[21.633089, 40.893189],
							[21.633024, 40.892971],
							[21.63297, 40.892745],
							[21.63292, 40.892527],
							[21.632854, 40.89231],
							[21.63276, 40.892092],
							[21.632653, 40.891883],
							[21.632556, 40.891673],
							[21.632467, 40.891455],
							[21.632378, 40.891237],
							[21.632284, 40.891028],
							[21.632182, 40.890819],
							[21.632078, 40.890601],
							[21.631968, 40.890391],
							[21.63185, 40.890191],
							[21.631716, 40.88999],
							[21.631565, 40.889789],
							[21.631395, 40.889605],
							[21.631196, 40.889439],
							[21.630954, 40.889317],
							[21.630663, 40.889257],
							[21.630429, 40.889126],
							[21.630215, 40.888959],
							[21.629996, 40.88881],
							[21.629776, 40.888653],
							[21.629541, 40.888522],
							[21.62928, 40.888418],
							[21.629008, 40.888322],
							[21.62873, 40.888244],
							[21.628448, 40.888175],
							[21.628155, 40.888133],
							[21.627857, 40.888118],
							[21.627561, 40.888112],
							[21.627271, 40.88816],
							[21.626999, 40.888253],
							[21.626725, 40.888338],
							[21.626447, 40.888413],
							[21.626173, 40.888507],
							[21.625902, 40.888591],
							[21.625619, 40.888666],
							[21.625328, 40.888705],
							[21.625031, 40.888708],
							[21.624734, 40.888702],
							[21.624144, 40.888654],
							[21.623851, 40.888612],
							[21.623556, 40.888588],
							[21.62326, 40.888591],
							[21.622963, 40.888594],
							[21.622369, 40.888617],
							[21.622073, 40.88862],
							[21.621777, 40.888605],
							[21.621482, 40.888581],
							[21.621166, 40.888535],
							[21.620815, 40.888279],
							[21.620378, 40.887904],
							[21.619683, 40.887385],
							[21.619008, 40.887102],
							[21.618423, 40.886913],
							[21.618131, 40.886776],
							[21.617737, 40.886307],
							[21.617465, 40.886052],
							[21.617278, 40.885886],
							[21.617117, 40.885777],
							[21.616739, 40.885603],
							[21.616519, 40.885437],
							[21.616342, 40.885117],
							[21.616158, 40.884843],
							[21.615892, 40.884535],
							[21.615502, 40.884191],
							[21.615025, 40.883851],
							[21.614581, 40.883606],
							[21.614218, 40.88341],
							[21.613832, 40.883177],
							[21.613682, 40.882807],
							[21.613534, 40.882556],
							[21.613514, 40.882038],
							[21.613572, 40.88156],
							[21.613211, 40.881333],
							[21.612919, 40.881115],
							[21.612138, 40.880451],
							[21.611868, 40.880818],
							[21.610869, 40.880352],
							[21.610346, 40.880149],
							[21.60993, 40.880049],
							[21.609502, 40.880001],
							[21.609252, 40.880013],
							[21.608828, 40.880098],
							[21.608549, 40.880208],
							[21.608409, 40.880299],
							[21.608286, 40.880258],
							[21.608085, 40.880589],
							[21.607943, 40.880678],
							[21.6072, 40.880842],
							[21.607058, 40.881096],
							[21.606859, 40.88113],
							[21.606516, 40.881076],
							[21.606092, 40.880966],
							[21.60554, 40.880763],
							[21.604859, 40.88054],
							[21.60385, 40.880281],
							[21.603654, 40.880196],
							[21.603721, 40.880062],
							[21.603616, 40.879905],
							[21.603483, 40.879443],
							[21.603286, 40.878991],
							[21.603013, 40.878492],
							[21.602836, 40.878287],
							[21.60255, 40.878034],
							[21.602387, 40.877897],
							[21.602295, 40.87778],
							[21.60201, 40.87748],
							[21.601472, 40.876939],
							[21.601326, 40.876737],
							[21.600704, 40.874948],
							[21.600696, 40.874416],
							[21.600683, 40.873835],
							[21.600168, 40.872754],
							[21.599342, 40.871405],
							[21.598597, 40.870469],
							[21.598088, 40.869909],
							[21.596873, 40.868482],
							[21.595956, 40.867643],
							[21.595285, 40.867177],
							[21.595194, 40.867189],
							[21.594583, 40.866806],
							[21.593883, 40.866388],
							[21.593615, 40.866266],
							[21.593107, 40.866227],
							[21.592874, 40.866286],
							[21.592842, 40.866321],
							[21.592555, 40.866377],
							[21.592531, 40.866337],
							[21.592434, 40.866371],
							[21.592279, 40.866313],
							[21.592137, 40.866191],
							[21.592083, 40.86612],
							[21.591984, 40.866087],
							[21.591967, 40.866059],
							[21.591737, 40.866035],
							[21.591748, 40.866282],
							[21.591804, 40.866532],
							[21.591684, 40.866761],
							[21.591434, 40.866862],
							[21.591026, 40.867124],
							[21.590669, 40.867317],
							[21.589964, 40.867666],
							[21.588529, 40.868422],
							[21.58755, 40.868933],
							[21.587258, 40.868978],
							[21.586786, 40.868939],
							[21.586523, 40.868911],
							[21.586464, 40.868994],
							[21.586061, 40.868978],
							[21.585053, 40.869191],
							[21.584667, 40.869256],
							[21.584345, 40.869221],
							[21.583347, 40.869071],
							[21.583261, 40.868982],
							[21.583073, 40.868635],
							[21.582953, 40.868422],
							[21.583041, 40.868333],
							[21.582918, 40.86832],
							[21.582701, 40.868299],
							[21.581893, 40.868359],
							[21.581376, 40.868341],
							[21.580557, 40.868266],
							[21.578846, 40.868053],
							[21.578109, 40.867899],
							[21.577978, 40.867907],
							[21.577768, 40.867899],
							[21.577594, 40.867852],
							[21.577341, 40.86784],
							[21.576964, 40.867765],
							[21.576301, 40.867684],
							[21.575834, 40.867643],
							[21.574981, 40.867497],
							[21.573372, 40.86652],
							[21.573195, 40.866447],
							[21.572176, 40.866455],
							[21.571671, 40.86639],
							[21.570968, 40.866232],
							[21.570647, 40.866142],
							[21.570507, 40.866142],
							[21.569917, 40.866284],
							[21.569236, 40.866467],
							[21.567809, 40.866439],
							[21.566629, 40.866341],
							[21.566398, 40.866418],
							[21.566103, 40.866893],
							[21.565701, 40.86755],
							[21.565395, 40.86798],
							[21.565298, 40.868236],
							[21.5654, 40.868516],
							[21.565838, 40.869479],
							[21.565947, 40.870016],
							[21.565722, 40.870917],
							[21.565325, 40.872101],
							[21.56451, 40.875152],
							[21.564081, 40.875923],
							[21.563383, 40.876928],
							[21.561827, 40.87787],
							[21.561141, 40.877821],
							[21.560594, 40.877886],
							[21.559639, 40.878202],
							[21.558233, 40.8789],
							[21.557922, 40.879135],
							[21.556592, 40.879808],
							[21.555326, 40.88036],
							[21.555176, 40.880522],
							[21.554564, 40.880571],
							[21.55436, 40.880465],
							[21.553695, 40.880619],
							[21.55259, 40.880903],
							[21.551925, 40.881252],
							[21.551163, 40.881658],
							[21.550916, 40.881934],
							[21.550401, 40.882461],
							[21.549897, 40.883021],
							[21.549779, 40.883304],
							[21.549616, 40.883943],
							[21.548962, 40.885265],
							[21.549135, 40.885397],
							[21.548962, 40.885776],
							[21.548867, 40.886151],
							[21.548663, 40.887433],
							[21.548513, 40.887587],
							[21.548331, 40.888244],
							[21.548103, 40.888639],
							[21.547762, 40.889201],
							[21.54759, 40.889331],
							[21.546453, 40.889793],
							[21.54597, 40.890142],
							[21.545691, 40.890799],
							[21.5442, 40.892015],
							[21.54361, 40.892235],
							[21.543782, 40.892015],
							[21.543567, 40.891951],
							[21.543299, 40.891991],
							[21.542762, 40.892235],
							[21.542558, 40.892291],
							[21.542419, 40.892527],
							[21.541378, 40.893378],
							[21.540861, 40.893911],
							[21.539941, 40.895381],
							[21.53949, 40.895787],
							[21.538213, 40.896776],
							[21.537248, 40.897895],
							[21.536733, 40.898479],
							[21.536362, 40.898995],
							[21.535853, 40.899704],
							[21.534834, 40.90122],
							[21.534481, 40.901635],
							[21.533793, 40.902445],
							[21.533755, 40.902487],
							[21.532999, 40.903337],
							[21.532975, 40.903513],
							[21.533224, 40.904723],
							[21.533686, 40.905421],
							[21.533686, 40.905867],
							[21.533096, 40.907042],
							[21.532291, 40.907626],
							[21.530864, 40.907691],
							[21.529856, 40.90778],
							[21.527581, 40.908356],
							[21.526884, 40.909321],
							[21.525403, 40.910124],
							[21.52506, 40.909929],
							[21.524513, 40.909832],
							[21.521927, 40.909321],
							[21.520929, 40.909183],
							[21.519116, 40.908551],
							[21.518762, 40.908421],
							[21.518011, 40.90851],
							[21.516187, 40.908752],
							[21.516176, 40.908753],
							[21.514084, 40.909013],
							[21.513323, 40.909134],
							[21.511971, 40.909313],
							[21.506048, 40.910099],
							[21.502792, 40.910638],
							[21.502325, 40.910679],
							[21.501963, 40.910774],
							[21.501435, 40.910861],
							[21.500974, 40.910918],
							[21.500915, 40.910843],
							[21.500129, 40.910894],
							[21.5, 40.910926],
							[21.499018, 40.911084],
							[21.498632, 40.911133],
							[21.498198, 40.911196],
							[21.49746, 40.911303],
							[21.495875, 40.911488],
							[21.495414, 40.911538],
							[21.495256, 40.911591],
							[21.495041, 40.911622],
							[21.492289, 40.912],
							[21.49191, 40.912051],
							[21.490774, 40.912222],
							[21.490567, 40.912238],
							[21.490436, 40.912234],
							[21.490156, 40.912157],
							[21.489985, 40.912134],
							[21.489808, 40.912059],
							[21.489333, 40.911952],
							[21.487359, 40.91151],
							[21.487201, 40.911471],
							[21.4872, 40.911443],
							[21.486064, 40.911196],
							[21.486071, 40.911171],
							[21.485812, 40.911109],
							[21.48579, 40.911131],
							[21.484808, 40.910924],
							[21.484591, 40.910916],
							[21.484285, 40.910768],
							[21.48425, 40.910807],
							[21.484036, 40.910728],
							[21.483841, 40.910689],
							[21.483829, 40.910687],
							[21.48355, 40.910669],
							[21.483547, 40.910644],
							[21.483271, 40.910622],
							[21.483067, 40.910578],
							[21.482483, 40.910432],
							[21.48148, 40.910223],
							[21.480643, 40.910036],
							[21.479809, 40.90984],
							[21.478806, 40.909653],
							[21.478668, 40.909609],
							[21.478422, 40.909546],
							[21.478191, 40.909505],
							[21.47795, 40.909473],
							[21.477929, 40.909501],
							[21.477797, 40.909477],
							[21.476939, 40.909286],
							[21.476624, 40.909234],
							[21.47626, 40.909169],
							[21.476112, 40.909126],
							[21.475209, 40.908891],
							[21.474729, 40.908772],
							[21.473921, 40.908607],
							[21.473908, 40.908632],
							[21.473395, 40.908518],
							[21.473403, 40.908492],
							[21.472444, 40.908315],
							[21.470526, 40.907922],
							[21.470399, 40.907904],
							[21.468809, 40.907578],
							[21.467529, 40.907338],
							[21.465974, 40.90702],
							[21.465936, 40.907012],
							[21.464984, 40.906805],
							[21.464965, 40.906732],
							[21.465083, 40.906481],
							[21.464933, 40.906468],
							[21.464873, 40.906365],
							[21.464863, 40.906348],
							[21.46476, 40.906395],
							[21.464547, 40.906813],
							[21.464463, 40.906825],
							[21.462983, 40.907486],
							[21.462304, 40.907766],
							[21.461589, 40.908071],
							[21.461229, 40.908224],
							[21.461186, 40.908259],
							[21.461092, 40.908356],
							[21.459998, 40.908767],
							[21.459268, 40.909098],
							[21.458445, 40.909434],
							[21.457407, 40.909888],
							[21.455497, 40.910709],
							[21.453585, 40.911502],
							[21.452018, 40.912167],
							[21.450352, 40.91287],
							[21.449446, 40.913237],
							[21.448859, 40.913509],
							[21.448843, 40.913476],
							[21.448218, 40.913732],
							[21.448102, 40.913815],
							[21.447923, 40.913896],
							[21.446777, 40.914374],
							[21.445332, 40.914968],
							[21.44394, 40.915546],
							[21.44189, 40.916415],
							[21.441029, 40.916802],
							[21.440871, 40.916841],
							[21.44056, 40.916859],
							[21.440004, 40.916896],
							[21.440012, 40.916853],
							[21.43994, 40.916857],
							[21.43971, 40.916888],
							[21.43906, 40.916906],
							[21.438012, 40.916928],
							[21.437328, 40.916942],
							[21.4367, 40.916993],
							[21.435555, 40.917015],
							[21.434294, 40.917062],
							[21.433353, 40.91709],
							[21.433173, 40.917141],
							[21.432712, 40.917153],
							[21.431894, 40.917165],
							[21.431489, 40.917141],
							[21.430346, 40.917204],
							[21.429153, 40.917248],
							[21.428707, 40.917234],
							[21.426985, 40.917293],
							[21.425459, 40.917368],
							[21.424268, 40.91741],
							[21.423573, 40.917457],
							[21.423337, 40.917471],
							[21.421557, 40.917556],
							[21.420465, 40.917625],
							[21.419697, 40.917645],
							[21.418758, 40.917696],
							[21.417627, 40.917749],
							[21.417448, 40.917688],
							[21.417349, 40.917662],
							[21.417114, 40.917628],
							[21.417135, 40.91754],
							[21.416248, 40.916962],
							[21.414564, 40.915795],
							[21.413974, 40.915398],
							[21.413615, 40.915144],
							[21.413574, 40.915179],
							[21.41336, 40.915024],
							[21.412587, 40.914465],
							[21.412633, 40.914429],
							[21.410549, 40.912951],
							[21.409516, 40.912254],
							[21.40954, 40.912209],
							[21.408478, 40.911498],
							[21.407223, 40.910598],
							[21.406507, 40.910132],
							[21.405914, 40.909702],
							[21.405856, 40.909689],
							[21.405694, 40.909651],
							[21.404897, 40.909065],
							[21.402231, 40.907219],
							[21.402164, 40.90728],
							[21.400233, 40.905921],
							[21.399058, 40.905119],
							[21.398291, 40.904598],
							[21.397773, 40.904221],
							[21.397535, 40.904048],
							[21.396467, 40.9033],
							[21.395397, 40.90256],
							[21.395217, 40.902469],
							[21.395062, 40.902319],
							[21.39408, 40.901676],
							[21.393943, 40.901553],
							[21.393417, 40.901232],
							[21.39312, 40.900971],
							[21.392492, 40.900543],
							[21.390995, 40.899497],
							[21.390875, 40.899428],
							[21.390781, 40.899341],
							[21.39048, 40.899126],
							[21.390384, 40.899079],
							[21.390392, 40.899063],
							[21.389469, 40.898447],
							[21.389209, 40.898309],
							[21.389104, 40.898246],
							[21.389094, 40.898193],
							[21.388396, 40.897702],
							[21.388262, 40.897607],
							[21.388155, 40.897555],
							[21.388074, 40.897488],
							[21.387651, 40.89721],
							[21.387044, 40.896776],
							[21.386548, 40.896425],
							[21.385977, 40.896022],
							[21.384982, 40.895343],
							[21.384483, 40.894982],
							[21.383735, 40.894475],
							[21.38279, 40.893822],
							[21.382257, 40.893457],
							[21.382007, 40.893291],
							[21.381267, 40.892774],
							[21.380674, 40.892376],
							[21.379977, 40.891877],
							[21.379041, 40.891227],
							[21.378526, 40.890833],
							[21.378282, 40.890714],
							[21.376726, 40.889702],
							[21.37657, 40.889647],
							[21.376535, 40.889582],
							[21.376377, 40.889536],
							[21.376316, 40.889454],
							[21.3762, 40.889412],
							[21.376127, 40.889327],
							[21.375878, 40.889193],
							[21.375875, 40.889162],
							[21.375747, 40.889075],
							[21.375527, 40.888905],
							[21.375395, 40.888767],
							[21.375261, 40.888702],
							[21.375055, 40.888554],
							[21.374977, 40.888459],
							[21.375082, 40.888388],
							[21.374282, 40.887824],
							[21.373888, 40.887557],
							[21.372853, 40.886847],
							[21.372311, 40.886462],
							[21.37123, 40.885717],
							[21.370784, 40.885441],
							[21.370624, 40.885352],
							[21.370149, 40.885014],
							[21.36984, 40.88477],
							[21.36983, 40.88473],
							[21.368821, 40.884],
							[21.368403, 40.883748],
							[21.368228, 40.883614],
							[21.368025, 40.883485],
							[21.367791, 40.883284],
							[21.36741, 40.883014],
							[21.366818, 40.882613],
							[21.366555, 40.882449],
							[21.36645, 40.882361],
							[21.366372, 40.88227],
							[21.366365, 40.882175],
							[21.366257, 40.882183],
							[21.366147, 40.882134],
							[21.366115, 40.88216],
							[21.365954, 40.882033],
							[21.365901, 40.881958],
							[21.365713, 40.88187],
							[21.365359, 40.88167],
							[21.364805, 40.881365],
							[21.364531, 40.881203],
							[21.364519, 40.881127],
							[21.364409, 40.880941],
							[21.364331, 40.880916],
							[21.364219, 40.880854],
							[21.364134, 40.880902],
							[21.364078, 40.880894],
							[21.364105, 40.880796],
							[21.364183, 40.880562],
							[21.364103, 40.880543],
							[21.363983, 40.880611],
							[21.363911, 40.880573],
							[21.363799, 40.880654],
							[21.363748, 40.880657],
							[21.363638, 40.880594],
							[21.363583, 40.880609],
							[21.363457, 40.880559],
							[21.363353, 40.880494],
							[21.363261, 40.880472],
							[21.363206, 40.880422],
							[21.363307, 40.880378],
							[21.363291, 40.88023],
							[21.363363, 40.880181],
							[21.363292, 40.880133],
							[21.363298, 40.880045],
							[21.363271, 40.879977],
							[21.363164, 40.879994],
							[21.363105, 40.880046],
							[21.36305, 40.880122],
							[21.362983, 40.880084],
							[21.362853, 40.879928],
							[21.362779, 40.879759],
							[21.362805, 40.879674],
							[21.362638, 40.879584],
							[21.362768, 40.879474],
							[21.362695, 40.879349],
							[21.362486, 40.879339],
							[21.362423, 40.879312],
							[21.362417, 40.879249],
							[21.362285, 40.879258],
							[21.362175, 40.879213],
							[21.362068, 40.879239],
							[21.361963, 40.879214],
							[21.361921, 40.879249],
							[21.361837, 40.879204],
							[21.361812, 40.87915],
							[21.361702, 40.879119],
							[21.3616, 40.879177],
							[21.361549, 40.879137],
							[21.361635, 40.879044],
							[21.361562, 40.879005],
							[21.361542, 40.878969],
							[21.361537, 40.878929],
							[21.361387, 40.878932],
							[21.361307, 40.878871],
							[21.361001, 40.878842],
							[21.360777, 40.878791],
							[21.360152, 40.878612],
							[21.360077, 40.878568],
							[21.360037, 40.87859],
							[21.35996, 40.878546],
							[21.35998, 40.878494],
							[21.359913, 40.878454],
							[21.359838, 40.87846],
							[21.359655, 40.878354],
							[21.359633, 40.878295],
							[21.359498, 40.878216],
							[21.359242, 40.878219],
							[21.359155, 40.878169],
							[21.359015, 40.877975],
							[21.358975, 40.877919],
							[21.358917, 40.877926],
							[21.358871, 40.877929],
							[21.358814, 40.877848],
							[21.358833, 40.877743],
							[21.358711, 40.877729],
							[21.358666, 40.877658],
							[21.358693, 40.877608],
							[21.358654, 40.877591],
							[21.358615, 40.877626],
							[21.358522, 40.877579],
							[21.358454, 40.877597],
							[21.357739, 40.877645],
							[21.357697, 40.877697],
							[21.357614, 40.877702],
							[21.357574, 40.877731],
							[21.357577, 40.877769],
							[21.357494, 40.877802],
							[21.357347, 40.877861],
							[21.357143, 40.877871],
							[21.357092, 40.877821],
							[21.357001, 40.877791],
							[21.356965, 40.877721],
							[21.35673, 40.877823],
							[21.356489, 40.87793],
							[21.356352, 40.877986],
							[21.356265, 40.878048],
							[21.356143, 40.878096],
							[21.356087, 40.878102],
							[21.355971, 40.878058],
							[21.355895, 40.878029],
							[21.355754, 40.877984],
							[21.35571, 40.877915],
							[21.355592, 40.877867],
							[21.355603, 40.877832],
							[21.355518, 40.877799],
							[21.355464, 40.87786],
							[21.35539, 40.877857],
							[21.355322, 40.877865],
							[21.355287, 40.877796],
							[21.355237, 40.877723],
							[21.355209, 40.877671],
							[21.355186, 40.877627],
							[21.35517, 40.877542],
							[21.355131, 40.877536],
							[21.355062, 40.877591],
							[21.354976, 40.877563],
							[21.354971, 40.877584],
							[21.354812, 40.877502],
							[21.354601, 40.877439],
							[21.354346, 40.877349],
							[21.354066, 40.877309],
							[21.353678, 40.877293],
							[21.353391, 40.877241],
							[21.353284, 40.877239],
							[21.353238, 40.877187],
							[21.353137, 40.877206],
							[21.353086, 40.877277],
							[21.352964, 40.877277],
							[21.352848, 40.877218],
							[21.352794, 40.877227],
							[21.352732, 40.877257],
							[21.352679, 40.877246],
							[21.35264, 40.877171],
							[21.352409, 40.877179],
							[21.352068, 40.8772],
							[21.351951, 40.877264],
							[21.351855, 40.877241],
							[21.351758, 40.877264],
							[21.351678, 40.877239],
							[21.351302, 40.877067],
							[21.350973, 40.876784],
							[21.350645, 40.87675],
							[21.350491, 40.876708],
							[21.350319, 40.876595],
							[21.349919, 40.876424],
							[21.349807, 40.876427],
							[21.349491, 40.876319],
							[21.349141, 40.876186],
							[21.349065, 40.876184],
							[21.348701, 40.87602],
							[21.348374, 40.875948],
							[21.348367, 40.875888],
							[21.348166, 40.875837],
							[21.348091, 40.875809],
							[21.348176, 40.875782],
							[21.34823, 40.875742],
							[21.348141, 40.875697],
							[21.348155, 40.875645],
							[21.347922, 40.87559],
							[21.347922, 40.875553],
							[21.347769, 40.87547],
							[21.347758, 40.875417],
							[21.347694, 40.875387],
							[21.347506, 40.875432],
							[21.347431, 40.87547],
							[21.347329, 40.875385],
							[21.34741, 40.875304],
							[21.347361, 40.875203],
							[21.347259, 40.875243],
							[21.347211, 40.875202],
							[21.347117, 40.875158],
							[21.347146, 40.875123],
							[21.347069, 40.875089],
							[21.347074, 40.875008],
							[21.346911, 40.874957],
							[21.346669, 40.87489],
							[21.346473, 40.87484],
							[21.346565, 40.874708],
							[21.346125, 40.874706],
							[21.345872, 40.874705],
							[21.345685, 40.874695],
							[21.345706, 40.874653],
							[21.345805, 40.874657],
							[21.345843, 40.874626],
							[21.345819, 40.874495],
							[21.345443, 40.874355],
							[21.345376, 40.874288],
							[21.345314, 40.874205],
							[21.34518, 40.874186],
							[21.345041, 40.874128],
							[21.344955, 40.874109],
							[21.344625, 40.873943],
							[21.344115, 40.873773],
							[21.34406, 40.873732],
							[21.343976, 40.873825],
							[21.343826, 40.873829],
							[21.343705, 40.873842],
							[21.343681, 40.873767],
							[21.343582, 40.873771],
							[21.343225, 40.873698],
							[21.342839, 40.873677],
							[21.342525, 40.873645],
							[21.342334, 40.8736],
							[21.342241, 40.873606],
							[21.342166, 40.873704],
							[21.342074, 40.873689],
							[21.342029, 40.87361],
							[21.341857, 40.873548],
							[21.341785, 40.873612],
							[21.341712, 40.873525],
							[21.341608, 40.873548],
							[21.341557, 40.873499],
							[21.341353, 40.873481],
							[21.341337, 40.873525],
							[21.341219, 40.873438],
							[21.341168, 40.873452],
							[21.341053, 40.873401],
							[21.340916, 40.873379],
							[21.340736, 40.873422],
							[21.340629, 40.873371],
							[21.340494, 40.873324],
							[21.340334, 40.87331],
							[21.340293, 40.873249],
							[21.340014, 40.873213],
							[21.340044, 40.873162],
							[21.339907, 40.873051],
							[21.339693, 40.87312],
							[21.339719, 40.873006],
							[21.33965, 40.872961],
							[21.339532, 40.872961],
							[21.339516, 40.872892],
							[21.339542, 40.872828],
							[21.339387, 40.872751],
							[21.339269, 40.872645],
							[21.339124, 40.872572],
							[21.338963, 40.87256],
							[21.338604, 40.872503],
							[21.338577, 40.872434],
							[21.338432, 40.872442],
							[21.338282, 40.872381],
							[21.337745, 40.872316],
							[21.337676, 40.872219],
							[21.337547, 40.872178],
							[21.337547, 40.872264],
							[21.337354, 40.872385],
							[21.337266, 40.872359],
							[21.337155, 40.87241],
							[21.336925, 40.872373],
							[21.336826, 40.872432],
							[21.336672, 40.87241],
							[21.336426, 40.872499],
							[21.336388, 40.87245],
							[21.336131, 40.872458],
							[21.336136, 40.872572],
							[21.335991, 40.872572],
							[21.335975, 40.872523],
							[21.335734, 40.872576],
							[21.335385, 40.872377],
							[21.335396, 40.8723],
							[21.335251, 40.87215],
							[21.335052, 40.872106],
							[21.334929, 40.872016],
							[21.3348, 40.871955],
							[21.334575, 40.871874],
							[21.334226, 40.871749],
							[21.334022, 40.871704],
							[21.334038, 40.871631],
							[21.333867, 40.871586],
							[21.33369, 40.871659],
							[21.333523, 40.871574],
							[21.333389, 40.871534],
							[21.333389, 40.871481],
							[21.332815, 40.871363],
							[21.332477, 40.871298],
							[21.33244, 40.871246],
							[21.331536, 40.871218],
							[21.331501, 40.871115],
							[21.330759, 40.871012],
							[21.32995, 40.871056],
							[21.329321, 40.87088],
							[21.328502, 40.870711],
							[21.327817, 40.870674],
							[21.327373, 40.870762],
							[21.327137, 40.870644],
							[21.327205, 40.870523],
							[21.327012, 40.870298],
							[21.326938, 40.870051],
							[21.326443, 40.869715],
							[21.326255, 40.869735],
							[21.326209, 40.869857],
							[21.326001, 40.869548],
							[21.325806, 40.869496],
							[21.325666, 40.869385],
							[21.325734, 40.869308],
							[21.325662, 40.869272],
							[21.325528, 40.869429],
							[21.325296, 40.869136],
							[21.324987, 40.869144],
							[21.324709, 40.869275],
							[21.32467, 40.86914],
							[21.324445, 40.868999],
							[21.323952, 40.86885],
							[21.323547, 40.868598],
							[21.323556, 40.868385],
							[21.323314, 40.868195],
							[21.323094, 40.86791],
							[21.322888, 40.867866],
							[21.322874, 40.867981],
							[21.322657, 40.867939],
							[21.322398, 40.867711],
							[21.322236, 40.867753],
							[21.322009, 40.867705],
							[21.321917, 40.867782],
							[21.321725, 40.867779],
							[21.321562, 40.867568],
							[21.321462, 40.867609],
							[21.321259, 40.867486],
							[21.321147, 40.86759],
							[21.320998, 40.867633],
							[21.320579, 40.867389],
							[21.320466, 40.867087],
							[21.318642, 40.866327],
							[21.318887, 40.865763],
							[21.318693, 40.86563],
							[21.318549, 40.865326],
							[21.318122, 40.865234],
							[21.316937, 40.864746],
							[21.316741, 40.864557],
							[21.316677, 40.864432],
							[21.316678, 40.864287],
							[21.316337, 40.864392],
							[21.316028, 40.864445],
							[21.315769, 40.864557],
							[21.315458, 40.864693],
							[21.315242, 40.86474],
							[21.315054, 40.864651],
							[21.31501, 40.864562],
							[21.314833, 40.864606],
							[21.314576, 40.86487],
							[21.314287, 40.865062],
							[21.314334, 40.865346],
							[21.314014, 40.865479],
							[21.313864, 40.865683],
							[21.313843, 40.865996],
							[21.313736, 40.866017],
							[21.313625, 40.866211],
							[21.31356, 40.866363],
							[21.313497, 40.86643],
							[21.313394, 40.866467],
							[21.313154, 40.866609],
							[21.313054, 40.866595],
							[21.312953, 40.866662],
							[21.312251, 40.866643],
							[21.312039, 40.866588],
							[21.311462, 40.866567],
							[21.311345, 40.866813],
							[21.310795, 40.866632],
							[21.310555, 40.866794],
							[21.310265, 40.86658],
							[21.309957, 40.86678],
							[21.309124, 40.867121],
							[21.308928, 40.867119],
							[21.307844, 40.867076],
							[21.307165, 40.867013],
							[21.306588, 40.86694],
							[21.305877, 40.86686],
							[21.305419, 40.866804],
							[21.304896, 40.866622],
							[21.30419, 40.866221],
							[21.303923, 40.866119],
							[21.30332, 40.866022],
							[21.30291, 40.865929],
							[21.302478, 40.865872],
							[21.301946, 40.865787],
							[21.301314, 40.865741],
							[21.300574, 40.865699],
							[21.299689, 40.865632],
							[21.299202, 40.865602],
							[21.298578, 40.865567],
							[21.297411, 40.865509],
							[21.297036, 40.865498],
							[21.296107, 40.865465],
							[21.29568, 40.86545],
							[21.29436, 40.865443],
							[21.293616, 40.865442],
							[21.293087, 40.865536],
							[21.292469, 40.865647],
							[21.291897, 40.865689],
							[21.2913, 40.865659],
							[21.290599, 40.865619],
							[21.290142, 40.865572],
							[21.289234, 40.865419],
							[21.288688, 40.865316],
							[21.288428, 40.865253],
							[21.288181, 40.865178],
							[21.287826, 40.865086],
							[21.287439, 40.864984],
							[21.286805, 40.864808],
							[21.286129, 40.864654],
							[21.285677, 40.864549],
							[21.285041, 40.864416],
							[21.284278, 40.864317],
							[21.283872, 40.86426],
							[21.283472, 40.86425],
							[21.282937, 40.864226],
							[21.281829, 40.864085],
							[21.281407, 40.864057],
							[21.280916, 40.864033],
							[21.280431, 40.864023],
							[21.280255, 40.864024],
							[21.279719, 40.864035],
							[21.279208, 40.864085],
							[21.278841, 40.864125],
							[21.278242, 40.864186],
							[21.27794, 40.864244],
							[21.277434, 40.864363],
							[21.277092, 40.864438],
							[21.276861, 40.864492],
							[21.276536, 40.864592],
							[21.275484, 40.864934],
							[21.274967, 40.865078],
							[21.274905, 40.865131],
							[21.27464, 40.865105],
							[21.274387, 40.865193],
							[21.27424, 40.865421],
							[21.273723, 40.865135],
							[21.272996, 40.864967],
							[21.272687, 40.865017],
							[21.272251, 40.865088],
							[21.271518, 40.864836],
							[21.271183, 40.865117],
							[21.270898, 40.865009],
							[21.27039, 40.864849],
							[21.269555, 40.864727],
							[21.268901, 40.864428],
							[21.268383, 40.864227],
							[21.267933, 40.864013],
							[21.267304, 40.863719],
							[21.266647, 40.863461],
							[21.26593, 40.863136],
							[21.265477, 40.862854],
							[21.265318, 40.862725],
							[21.265104, 40.862562],
							[21.264695, 40.86224],
							[21.264447, 40.862047],
							[21.263558, 40.86154],
							[21.263143, 40.861317],
							[21.262701, 40.861072],
							[21.262318, 40.860858],
							[21.261999, 40.860726],
							[21.261761, 40.860632],
							[21.261455, 40.860475],
							[21.261022, 40.860306],
							[21.260725, 40.860179],
							[21.260204, 40.860056],
							[21.260048, 40.860043],
							[21.259909, 40.860044],
							[21.259747, 40.860073],
							[21.259038, 40.860235],
							[21.258507, 40.860487],
							[21.25736, 40.861108],
							[21.255766, 40.861708],
							[21.255187, 40.861887],
							[21.25435, 40.862037],
							[21.25258, 40.862243],
							[21.252024, 40.862379],
							[21.25156, 40.862649],
							[21.251206, 40.863022],
							[21.250901, 40.863899],
							[21.25075, 40.864836],
							[21.250643, 40.866369],
							[21.250532, 40.866793],
							[21.250364, 40.866962],
							[21.250182, 40.867095],
							[21.249833, 40.867225],
							[21.2492, 40.867501],
							[21.247975, 40.868039],
							[21.247462, 40.8683],
							[21.247145, 40.868544],
							[21.246759, 40.86942],
							[21.246116, 40.870458],
							[21.24581, 40.871047],
							[21.245673, 40.871243],
							[21.245599, 40.871349],
							[21.245552, 40.871414],
							[21.245381, 40.871631],
							[21.244957, 40.872227],
							[21.244544, 40.872541],
							[21.243809, 40.872929],
							[21.243036, 40.873618],
							[21.242146, 40.874201],
							[21.242013, 40.874251],
							[21.241864, 40.874296],
							[21.240525, 40.87476],
							[21.239968, 40.874886],
							[21.238804, 40.875008],
							[21.237036, 40.875537],
							[21.23632, 40.875754],
							[21.234864, 40.876318],
							[21.232892, 40.877091],
							[21.231548, 40.877555],
							[21.229553, 40.878005],
							[21.228356, 40.878196],
							[21.226473, 40.878725],
							[21.224996, 40.878822],
							[21.224079, 40.878829],
							[21.223547, 40.878774],
							[21.222684, 40.878555],
							[21.222383, 40.878537],
							[21.221807, 40.878605],
							[21.221549, 40.878701],
							[21.221312, 40.878857],
							[21.220744, 40.879297],
							[21.219671, 40.880139],
							[21.218942, 40.880836],
							[21.218516, 40.881161],
							[21.218048, 40.88139],
							[21.216719, 40.881919],
							[21.215495, 40.882292],
							[21.213983, 40.882765],
							[21.211426, 40.8834],
							[21.20865, 40.883742],
							[21.207945, 40.883838],
							[21.207341, 40.883854],
							[21.206922, 40.883832],
							[21.206446, 40.883797],
							[21.205874, 40.883706],
							[21.204769, 40.883373],
							[21.202648, 40.882568],
							[21.201913, 40.882061],
							[21.201092, 40.881512],
							[21.19959, 40.879889],
							[21.198206, 40.878802],
							[21.197409, 40.878361],
							[21.196352, 40.877774],
							[21.19591, 40.877577],
							[21.194708, 40.877074],
							[21.194043, 40.876945],
							[21.192404, 40.876816],
							[21.191417, 40.876792],
							[21.189571, 40.876638],
							[21.187415, 40.876078],
							[21.185612, 40.875989],
							[21.184205, 40.875776],
							[21.182671, 40.875712],
							[21.181664, 40.875672],
							[21.180493, 40.875541],
							[21.180031, 40.875419],
							[21.179023, 40.875062],
							[21.178712, 40.875002],
							[21.17722, 40.874973],
							[21.176936, 40.874924],
							[21.176681, 40.874811],
							[21.176276, 40.874442],
							[21.176008, 40.874081],
							[21.175809, 40.873643],
							[21.175557, 40.872783],
							[21.175096, 40.872223],
							[21.174718, 40.871916],
							[21.174002, 40.871538],
							[21.173556, 40.871249],
							[21.172333, 40.870665],
							[21.172033, 40.870515],
							[21.17133, 40.870093],
							[21.170676, 40.869615],
							[21.170386, 40.869197],
							[21.170316, 40.868992],
							[21.170257, 40.868548],
							[21.170236, 40.867449],
							[21.170107, 40.867209],
							[21.169753, 40.86686],
							[21.168857, 40.866292],
							[21.167451, 40.865485],
							[21.166749, 40.865181],
							[21.163683, 40.864152],
							[21.162522, 40.864016],
							[21.161959, 40.863862],
							[21.161433, 40.863655],
							[21.161218, 40.863521],
							[21.160843, 40.863156],
							[21.160209, 40.862158],
							[21.158847, 40.860686],
							[21.158429, 40.860097],
							[21.157962, 40.859241],
							[21.15772, 40.858884],
							[21.15705, 40.858113],
							[21.156364, 40.857635],
							[21.155553, 40.857396],
							[21.154766, 40.857171],
							[21.153333, 40.856831],
							[21.152115, 40.85671],
							[21.151353, 40.856726],
							[21.150107, 40.856938],
							[21.149406, 40.857046],
							[21.148526, 40.857164],
							[21.148006, 40.857168],
							[21.147432, 40.857111],
							[21.146922, 40.856941],
							[21.146429, 40.856665],
							[21.145184, 40.855728],
							[21.144943, 40.855602],
							[21.143617, 40.85505],
							[21.143108, 40.854916],
							[21.142513, 40.8549],
							[21.139541, 40.855216],
							[21.138983, 40.855135],
							[21.137648, 40.854735],
							[21.13674, 40.854624],
							[21.136097, 40.854616],
							[21.134132, 40.854915],
							[21.133519, 40.854893],
							[21.1326, 40.854785],
							[21.130658, 40.855434],
							[21.130206, 40.855426],
							[21.129932, 40.855376],
							[21.129033, 40.855257],
							[21.127578, 40.854868],
							[21.126635, 40.854797],
							[21.125577, 40.85458],
							[21.124955, 40.854095],
							[21.124472, 40.854042],
							[21.124152, 40.854092],
							[21.123174, 40.853983],
							[21.122949, 40.854034],
							[21.122602, 40.853996],
							[21.121879, 40.853925],
							[21.121538, 40.853853],
							[21.121149, 40.85366],
							[21.11969, 40.853713],
							[21.118357, 40.853719],
							[20.980075, 40.855127],
							[20.984285, 40.801446],
							[20.984308, 40.801046],
							[20.98448, 40.797974],
							[20.985079, 40.79508],
							[20.985098, 40.794538],
							[20.985104, 40.794342],
							[20.984955, 40.793244],
							[20.984232, 40.791142],
							[20.983126, 40.788912],
							[20.982603, 40.787909],
							[20.981282, 40.786679],
							[20.980011, 40.785732],
							[20.979787, 40.784558],
							[20.979004, 40.783087],
							[20.977508, 40.781762],
							[20.975838, 40.781212],
							[20.974168, 40.780644],
							[20.97389, 40.780059],
							[20.97362, 40.779489],
							[20.969722, 40.77781],
							[20.968333, 40.777095],
							[20.965436, 40.775972],
							[20.96399, 40.775442],
							[20.962505, 40.773612],
							[20.960237, 40.772116],
							[20.956524, 40.770562],
							[20.956026, 40.770222],
							[20.958011, 40.770525],
							[20.958859, 40.770753],
							[20.960429, 40.770848],
							[20.962049, 40.770678],
							[20.964393, 40.769486],
							[20.967497, 40.767029],
							[20.969268, 40.764777],
							[20.971537, 40.76243],
							[20.972833, 40.761862],
							[20.975276, 40.761901],
							[20.977459, 40.762238],
							[20.978431, 40.762144],
							[20.980375, 40.762031],
							[20.982717, 40.762599],
							[20.982742, 40.761539],
							[20.98364, 40.759384],
							[20.984463, 40.757889],
							[20.984762, 40.756734],
							[20.984936, 40.753881],
							[20.984887, 40.752461],
							[20.984991, 40.749507],
							[20.98461, 40.746914],
							[20.98431, 40.744523],
							[20.983366, 40.742879],
							[20.983226, 40.74192],
							[20.983327, 40.740702],
							[20.983387, 40.739392],
							[20.983367, 40.738253],
							[20.983547, 40.737644],
							[20.983751, 40.73705],
							[20.984218, 40.736639],
							[20.985836, 40.734671],
							[20.986566, 40.733865],
							[20.988088, 40.732165],
							[20.990976, 40.730863],
							[20.994864, 40.72906],
							[20.99628, 40.728555],
							[20.997009, 40.728184],
							[20.997584, 40.728093],
							[20.997975, 40.72793],
							[20.998546, 40.727819],
							[20.998876, 40.727743],
							[21.000054, 40.727472],
							[21.002928, 40.72635],
							[21.005883, 40.724453],
							[21.007895, 40.723199],
							[21.0102, 40.721595],
							[21.013724, 40.718623],
							[21.014698, 40.717469],
							[21.018095, 40.714468],
							[21.021238, 40.711654],
							[21.024497, 40.708787],
							[21.026541, 40.706589],
							[21.027188, 40.70571],
							[21.028095, 40.704478],
							[21.030751, 40.701145],
							[21.03161, 40.699968],
							[21.032138, 40.699224],
							[21.034945, 40.696178],
							[21.035809, 40.695208],
							[21.036865, 40.6937],
							[21.037808, 40.692029],
							[21.038383, 40.690794],
							[21.04128, 40.68574],
							[21.04299, 40.683442],
							[21.046263, 40.679904],
							[21.048113, 40.677878],
							[21.052513, 40.673251],
							[21.054062, 40.671453],
							[21.055602, 40.669778],
							[21.057306, 40.667768],
							[21.057433, 40.666185],
							[21.056593, 40.662815],
							[21.05551, 40.656571],
							[21.054142, 40.647992],
							[21.053541, 40.6442],
							[21.052545, 40.637712],
							[21.052025, 40.635467],
							[21.051984, 40.634648],
							[21.055014, 40.628517],
							[21.055561, 40.627207],
							[21.055987, 40.623231],
							[21.056012, 40.622691],
							[21.056631, 40.620432],
							[21.056828, 40.619365],
							[21.056963, 40.617976],
							[21.057101, 40.61698],
							[21.05701, 40.61682],
							[21.056753, 40.616703],
							[21.056442, 40.616631],
							[21.05582, 40.616583],
							[21.055053, 40.616603],
							[21.052594, 40.616247],
							[21.050322, 40.616218],
							[21.04777, 40.615097],
							[21.045809, 40.614316],
							[21.045354, 40.613705],
							[21.045326, 40.612699],
							[21.046702, 40.609948],
							[21.047004, 40.60887],
							[21.047849, 40.606028],
							[21.048802, 40.602862],
							[21.048789, 40.602639],
							[21.048718, 40.602425],
							[21.046753, 40.596448],
							[21.046008, 40.594242],
							[21.045429, 40.59391],
							[21.044262, 40.593728],
							[21.042614, 40.593196],
							[21.041497, 40.592812],
							[21.040245, 40.592095],
							[21.040165, 40.591731],
							[21.043941, 40.58285],
							[21.046295, 40.578252],
							[21.046194, 40.575777],
							[21.046411, 40.573779],
							[21.047139, 40.572817],
							[21.047139, 40.572391],
							[21.046701, 40.571725],
							[21.044098, 40.568084],
							[21.042639, 40.566308],
							[21.042656, 40.56511],
							[21.043287, 40.564092],
							[21.043359, 40.5635],
							[21.04314, 40.562501],
							[21.042896, 40.561465],
							[21.042556, 40.561169],
							[21.041633, 40.560929],
							[21.041297, 40.560564],
							[21.041418, 40.560008],
							[21.041709, 40.559509],
							[21.042389, 40.558917],
							[21.04336, 40.558639],
							[21.042777, 40.558102],
							[21.042024, 40.557862],
							[21.040615, 40.55753],
							[21.037724, 40.556254],
							[21.036558, 40.555829],
							[21.036048, 40.556809],
							[21.035441, 40.556865],
							[21.034882, 40.556662],
							[21.034809, 40.555792],
							[21.034372, 40.555237],
							[21.033643, 40.555071],
							[21.032995, 40.554449],
							[21.031927, 40.55419],
							[21.030737, 40.554339],
							[21.029717, 40.554654],
							[21.026854, 40.554964],
							[21.026048, 40.55521],
							[21.025807, 40.557191],
							[21.025323, 40.557253],
							[21.02488, 40.557099],
							[21.024315, 40.5567],
							[21.023992, 40.557007],
							[21.022904, 40.55713],
							[21.020424, 40.557116],
							[21.019477, 40.557039],
							[21.018953, 40.556763],
							[21.018872, 40.555749],
							[21.018489, 40.555319],
							[21.018267, 40.55515],
							[21.016815, 40.555366],
							[21.013919, 40.554778],
							[21.013072, 40.554656],
							[21.012729, 40.554302],
							[21.012931, 40.553719],
							[21.013032, 40.553335],
							[21.01285, 40.553074],
							[21.011923, 40.553258],
							[21.010199, 40.55156],
							[21.00899, 40.551299],
							[21.007418, 40.55113],
							[21.005825, 40.551591],
							[21.004596, 40.549625],
							[21.003034, 40.547804],
							[21.002328, 40.547113],
							[21.001623, 40.54676],
							[21.001945, 40.545731],
							[21.001844, 40.544502],
							[21.001462, 40.543906],
							[21.000817, 40.543737],
							[21.000031, 40.543568],
							[20.999345, 40.543107],
							[20.999104, 40.541572],
							[20.998739, 40.54024],
							[20.999102, 40.538966],
							[20.999929, 40.537169],
							[21.000173, 40.536144],
							[20.999992, 40.535438],
							[20.999407, 40.534746],
							[20.99855, 40.533707],
							[20.99732, 40.533441],
							[20.995926, 40.533147],
							[20.994825, 40.532519],
							[20.993523, 40.531889],
							[20.991946, 40.531093],
							[20.990258, 40.53017],
							[20.988851, 40.529376],
							[20.985953, 40.527838],
							[20.983789, 40.526706],
							[20.981313, 40.525503],
							[20.979651, 40.524667],
							[20.978496, 40.523912],
							[20.977965, 40.522919],
							[20.977262, 40.521447],
							[20.976957, 40.520808],
							[20.976367, 40.51997],
							[20.975468, 40.519131],
							[20.974753, 40.519033],
							[20.974056, 40.519033],
							[20.973231, 40.519368],
							[20.972552, 40.519773],
							[20.971085, 40.520346],
							[20.970406, 40.520486],
							[20.969911, 40.52043],
							[20.969398, 40.519912],
							[20.968243, 40.518822],
							[20.967918, 40.518512],
							[20.967845, 40.518163],
							[20.9679, 40.517771],
							[20.968157, 40.516751],
							[20.968213, 40.516262],
							[20.968121, 40.515815],
							[20.967791, 40.51499],
							[20.967388, 40.514321],
							[20.966875, 40.512895],
							[20.966307, 40.512057],
							[20.965849, 40.511693],
							[20.965244, 40.511567],
							[20.964804, 40.511427],
							[20.964584, 40.511217],
							[20.964584, 40.510938],
							[20.964786, 40.510143],
							[20.964731, 40.508843],
							[20.964438, 40.507823],
							[20.964347, 40.50718],
							[20.964384, 40.506817],
							[20.964696, 40.506202],
							[20.964898, 40.505936],
							[20.96554, 40.505113],
							[20.966916, 40.50345],
							[20.96721, 40.502933],
							[20.967228, 40.502387],
							[20.967008, 40.501675],
							[20.967009, 40.501129],
							[20.966973, 40.500502],
							[20.967102, 40.500096],
							[20.967395, 40.499873],
							[20.967872, 40.499513],
							[20.969231, 40.497654],
							[20.969334, 40.497183],
							[20.969334, 40.496398],
							[20.968409, 40.493453],
							[20.968307, 40.49259],
							[20.96908, 40.490352],
							[20.969183, 40.489214],
							[20.968772, 40.48835],
							[20.967068, 40.486866],
							[20.964444, 40.484942],
							[20.962643, 40.484313],
							[20.960687, 40.484038],
							[20.960069, 40.483488],
							[20.958835, 40.481211],
							[20.957395, 40.479248],
							[20.956058, 40.477246],
							[20.955161, 40.476471],
							[20.95372, 40.475921],
							[20.953875, 40.474821],
							[20.953516, 40.473565],
							[20.953825, 40.472898],
							[20.954495, 40.47227],
							[20.954753, 40.470975],
							[20.954908, 40.470269],
							[20.955937, 40.470073],
							[20.956658, 40.469877],
							[20.956504, 40.469523],
							[20.955475, 40.468973],
							[20.948786, 40.46689],
							[20.939968, 40.464621],
							[20.931891, 40.462575],
							[20.93014, 40.463281],
							[20.927567, 40.463515],
							[20.924941, 40.464809],
							[20.923191, 40.464886],
							[20.922264, 40.465553],
							[20.921079, 40.466965],
							[20.92005, 40.467121],
							[20.918609, 40.46716],
							[20.917888, 40.467551],
							[20.917423, 40.469239],
							[20.91662, 40.471108],
							[20.916105, 40.471933],
							[20.915384, 40.472246],
							[20.914149, 40.472206],
							[20.913171, 40.472323],
							[20.911986, 40.473303],
							[20.910079, 40.474872],
							[20.908482, 40.475891],
							[20.907512, 40.476098],
							[20.907195, 40.476165],
							[20.906269, 40.476164],
							[20.904931, 40.47581],
							[20.902873, 40.474984],
							[20.901433, 40.474786],
							[20.899631, 40.474824],
							[20.897315, 40.47494],
							[20.895565, 40.474742],
							[20.894279, 40.474545],
							[20.893061, 40.474586],
							[20.89234, 40.47486],
							[20.891619, 40.474781],
							[20.89059, 40.474858],
							[20.888839, 40.475524],
							[20.888324, 40.475563],
							[20.887141, 40.475208],
							[20.886524, 40.47458],
							[20.885908, 40.473794],
							[20.885033, 40.473518],
							[20.882717, 40.473555],
							[20.880864, 40.47371],
							[20.878805, 40.473865],
							[20.877159, 40.473549],
							[20.874894, 40.473586],
							[20.874742, 40.472566],
							[20.874229, 40.471544],
							[20.873553, 40.471247],
							[20.872096, 40.471034],
							[20.870932, 40.471033],
							[20.869101, 40.471834],
							[20.868158, 40.472383],
							[20.864061, 40.473707],
							[20.861176, 40.475183],
							[20.85779, 40.477842],
							[20.855404, 40.478727],
							[20.854074, 40.47881],
							[20.851026, 40.47851],
							[20.848309, 40.478718],
							[20.846201, 40.479603],
							[20.844259, 40.480276],
							[20.843538, 40.480318],
							[20.842652, 40.480147],
							[20.841878, 40.479132],
							[20.841217, 40.47744],
							[20.839613, 40.476212],
							[20.838561, 40.475576],
							[20.837938, 40.473302],
							[20.837386, 40.472456],
							[20.835503, 40.47203],
							[20.835116, 40.471565],
							[20.834842, 40.470042],
							[20.834458, 40.46852],
							[20.833021, 40.466954],
							[20.831696, 40.464923],
							[20.831482, 40.460532],
							[20.831217, 40.45605],
							[20.831126, 40.455739],
							[20.830667, 40.454147],
							[20.828901, 40.451439],
							[20.82614, 40.447926],
							[20.824043, 40.447001],
							[20.821718, 40.446278],
							[20.821388, 40.445559],
							[20.821834, 40.444461],
							[20.824112, 40.441801],
							[20.824169, 40.441209],
							[20.823839, 40.440659],
							[20.821459, 40.439556],
							[20.818803, 40.438369],
							[20.815482, 40.438781],
							[20.812708, 40.440468],
							[20.812098, 40.44072],
							[20.811434, 40.440381],
							[20.809609, 40.439321],
							[20.804964, 40.436185],
							[20.802752, 40.434956],
							[20.800315, 40.434952],
							[20.797545, 40.43482],
							[20.796827, 40.434311],
							[20.794616, 40.432828],
							[20.792569, 40.431894],
							[20.789525, 40.430285],
							[20.789366, 40.430187],
							[20.787203, 40.428844],
							[20.786267, 40.427109],
							[20.785995, 40.425375],
							[20.786948, 40.421911],
							[20.788122, 40.418489],
							[20.790609, 40.416179],
							[20.791215, 40.415472],
							[20.791779, 40.414874],
							[20.792163, 40.414261],
							[20.792305, 40.413801],
							[20.792226, 40.413401],
							[20.792147, 40.413002],
							[20.791967, 40.412695],
							[20.791809, 40.411764],
							[20.791792, 40.410996],
							[20.791774, 40.410275],
							[20.791716, 40.409431],
							[20.791557, 40.408955],
							[20.791337, 40.408448],
							[20.791057, 40.408171],
							[20.790047, 40.407344],
							[20.789668, 40.40656],
							[20.789389, 40.405792],
							[20.78891, 40.404595],
							[20.788592, 40.403581],
							[20.788392, 40.40291],
							[20.788092, 40.402541],
							[20.787772, 40.40185],
							[20.787633, 40.401343],
							[20.787413, 40.400944],
							[20.787154, 40.400467],
							[20.787034, 40.400084],
							[20.786955, 40.399777],
							[20.786915, 40.3995],
							[20.787138, 40.398979],
							[20.787401, 40.398427],
							[20.788293, 40.396261],
							[20.788416, 40.395463],
							[20.788298, 40.394696],
							[20.7884, 40.394205],
							[20.788604, 40.393162],
							[20.788868, 40.392349],
							[20.789152, 40.391429],
							[20.789499, 40.390512],
							[20.789843, 40.389592],
							[20.790087, 40.388841],
							[20.790209, 40.388319],
							[20.790412, 40.38746],
							[20.790495, 40.386724],
							[20.790496, 40.386325],
							[20.790519, 40.385788],
							[20.790762, 40.385067],
							[20.791408, 40.384163],
							[20.79163, 40.383857],
							[20.791633, 40.382767],
							[20.791635, 40.382107],
							[20.791556, 40.381539],
							[20.791078, 40.380295],
							[20.790699, 40.379236],
							[20.790581, 40.378437],
							[20.790403, 40.377547],
							[20.790508, 40.376258],
							[20.79051, 40.375445],
							[20.790572, 40.374974],
							[20.790592, 40.374815],
							[20.790816, 40.373972],
							[20.79108, 40.373205],
							[20.790984, 40.371839],
							[20.791046, 40.371272],
							[20.79103, 40.370044],
							[20.790792, 40.368893],
							[20.790353, 40.368032],
							[20.790074, 40.36745],
							[20.789955, 40.366943],
							[20.789956, 40.366345],
							[20.789859, 40.36527],
							[20.790444, 40.365142],
							[20.791149, 40.364628],
							[20.791028, 40.364317],
							[20.790833, 40.363843],
							[20.790606, 40.363778],
							[20.790347, 40.363353],
							[20.790308, 40.363015],
							[20.790189, 40.362539],
							[20.79011, 40.362309],
							[20.789648, 40.361758],
							[20.788424, 40.361111],
							[20.787743, 40.36068],
							[20.78678, 40.360172],
							[20.785179, 40.35865],
							[20.784538, 40.358096],
							[20.783869, 40.357486],
							[20.783227, 40.35727],
							[20.781983, 40.357084],
							[20.7812, 40.357082],
							[20.780617, 40.35725],
							[20.77917, 40.357385],
							[20.777825, 40.357291],
							[20.777069, 40.356951],
							[20.776689, 40.356352],
							[20.77643, 40.35563],
							[20.775851, 40.3548],
							[20.775731, 40.354493],
							[20.775531, 40.354185],
							[20.775413, 40.353556],
							[20.775374, 40.353234],
							[20.775396, 40.352651],
							[20.775459, 40.352039],
							[20.775741, 40.351733],
							[20.776366, 40.350875],
							[20.777093, 40.349786],
							[20.778461, 40.348853],
							[20.779085, 40.348486],
							[20.779568, 40.348256],
							[20.779649, 40.348088],
							[20.779186, 40.348148],
							[20.77786, 40.348422],
							[20.776916, 40.348712],
							[20.776394, 40.348649],
							[20.775731, 40.348541],
							[20.774628, 40.348278],
							[20.773946, 40.348138],
							[20.772161, 40.347413],
							[20.771432, 40.346738],
							[20.77031, 40.345892],
							[20.768988, 40.344999],
							[20.767746, 40.344229],
							[20.766703, 40.343798],
							[20.76574, 40.343443],
							[20.764459, 40.342473],
							[20.764019, 40.342012],
							[20.763372, 40.340892],
							[20.762311, 40.340046],
							[20.761952, 40.339201],
							[20.762014, 40.338725],
							[20.762037, 40.338081],
							[20.761938, 40.337513],
							[20.761338, 40.336928],
							[20.760092, 40.3357],
							[20.759573, 40.334839],
							[20.758974, 40.333779],
							[20.75885, 40.333451],
							[20.758616, 40.332836],
							[20.758367, 40.332513],
							[20.75806, 40.331986],
							[20.757975, 40.331539],
							[20.758011, 40.331078],
							[20.758133, 40.330802],
							[20.758461, 40.330448],
							[20.758932, 40.329832],
							[20.759744, 40.328808],
							[20.760452, 40.328034],
							[20.761212, 40.327286],
							[20.761593, 40.326524],
							[20.761973, 40.32588],
							[20.762451, 40.325146],
							[20.763125, 40.324003],
							[20.763818, 40.322623],
							[20.764303, 40.321796],
							[20.764287, 40.32148],
							[20.763849, 40.320982],
							[20.763438, 40.320626],
							[20.762923, 40.320349],
							[20.762339, 40.320071],
							[20.761549, 40.319807],
							[20.760896, 40.319595],
							[20.7599, 40.319119],
							[20.758698, 40.318604],
							[20.757274, 40.317904],
							[20.756742, 40.317443],
							[20.756438, 40.316786],
							[20.756337, 40.316063],
							[20.755944, 40.315405],
							[20.755482, 40.314891],
							[20.754847, 40.314469],
							[20.753696, 40.314098],
							[20.75258, 40.31378],
							[20.75112, 40.313316],
							[20.750364, 40.31317],
							[20.749951, 40.313169],
							[20.74928, 40.313247],
							[20.748856, 40.313406],
							[20.748557, 40.313752],
							[20.748303, 40.313893],
							[20.748007, 40.313987],
							[20.74755, 40.313939],
							[20.74607, 40.31335],
							[20.744704, 40.312995],
							[20.743939, 40.312804],
							[20.74331, 40.312463],
							[20.742118, 40.311139],
							[20.741785, 40.311006],
							[20.741278, 40.311108],
							[20.740201, 40.311795],
							[20.73931, 40.312303],
							[20.738981, 40.312328],
							[20.738587, 40.312035],
							[20.736273, 40.310084],
							[20.735557, 40.309507],
							[20.734708, 40.308873],
							[20.734019, 40.308229],
							[20.733467, 40.30734],
							[20.732738, 40.306263],
							[20.732406, 40.305866],
							[20.732345, 40.305696],
							[20.732287, 40.304855],
							[20.732241, 40.303798],
							[20.732319, 40.302732],
							[20.732212, 40.301769],
							[20.73182, 40.300984],
							[20.731256, 40.299887],
							[20.731233, 40.299284],
							[20.731178, 40.297659],
							[20.731168, 40.296923],
							[20.731034, 40.296554],
							[20.730566, 40.296251],
							[20.729529, 40.295682],
							[20.729073, 40.295455],
							[20.72847, 40.294981],
							[20.727968, 40.294026],
							[20.727587, 40.293421],
							[20.727501, 40.293349],
							[20.726849, 40.292806],
							[20.726562, 40.292624],
							[20.726102, 40.292333],
							[20.725987, 40.292193],
							[20.725873, 40.292055],
							[20.725547, 40.29166],
							[20.725525, 40.291633],
							[20.724379, 40.291187],
							[20.724152, 40.29105],
							[20.723662, 40.290756],
							[20.722864, 40.290277],
							[20.721347, 40.289113],
							[20.720629, 40.288577],
							[20.719784, 40.287947],
							[20.719187, 40.287659],
							[20.71746, 40.286823],
							[20.71677, 40.286463],
							[20.716733, 40.286444],
							[20.715528, 40.285336],
							[20.714419, 40.284268],
							[20.71293, 40.283093],
							[20.712746, 40.282781],
							[20.712669, 40.282388],
							[20.712565, 40.281855],
							[20.712547, 40.281622],
							[20.712517, 40.281245],
							[20.712496, 40.280976],
							[20.712471, 40.280656],
							[20.712447, 40.280496],
							[20.712407, 40.280222],
							[20.712378, 40.280028],
							[20.712207, 40.278871],
							[20.71205, 40.278125],
							[20.711581, 40.277454],
							[20.71076, 40.276159],
							[20.709371, 40.273928],
							[20.708682, 40.272694],
							[20.707629, 40.270809],
							[20.706653, 40.268115],
							[20.706671, 40.267398],
							[20.706771, 40.267144],
							[20.708292, 40.264203],
							[20.709854, 40.262441],
							[20.710974, 40.261322],
							[20.712786, 40.258862],
							[20.713885, 40.257764],
							[20.717001, 40.256346],
							[20.718615, 40.255472],
							[20.720456, 40.254684],
							[20.722125, 40.253923],
							[20.722719, 40.253443],
							[20.722794, 40.253169],
							[20.722218, 40.252112],
							[20.721937, 40.251441],
							[20.721781, 40.250814],
							[20.721585, 40.250024],
							[20.721199, 40.249508],
							[20.721204, 40.247726],
							[20.72124, 40.2471],
							[20.721415, 40.246135],
							[20.721793, 40.245249],
							[20.721864, 40.244571],
							[20.721493, 40.243683],
							[20.721599, 40.242822],
							[20.722149, 40.241702],
							[20.722185, 40.241024],
							[20.721303, 40.24003],
							[20.720658, 40.239402],
							[20.719917, 40.238558],
							[20.719544, 40.237983],
							[20.719616, 40.237148],
							[20.720097, 40.236158],
							[20.720209, 40.235535],
							[20.720043, 40.234569],
							[20.719707, 40.23329],
							[20.719712, 40.232012],
							[20.719958, 40.230186],
							[20.720543, 40.228988],
							[20.722047, 40.227843],
							[20.723106, 40.227115],
							[20.724579, 40.225924],
							[20.725127, 40.225351],
							[20.725471, 40.224361],
							[20.725985, 40.22371],
							[20.72609, 40.223032],
							[20.725921, 40.222692],
							[20.725139, 40.222195],
							[20.723608, 40.221643],
							[20.722352, 40.220649],
							[20.721878, 40.219735],
							[20.721643, 40.218847],
							[20.721465, 40.217541],
							[20.721273, 40.216045],
							[20.720945, 40.215208],
							[20.72012, 40.214623],
							[20.717411, 40.213451],
							[20.715663, 40.211977],
							[20.714443, 40.211112],
							[20.713419, 40.210628],
							[20.712622, 40.210222],
							[20.712591, 40.209791],
							[20.713422, 40.208932],
							[20.713755, 40.208476],
							[20.713756, 40.208122],
							[20.71313, 40.207664],
							[20.71145, 40.20586],
							[20.710034, 40.204235],
							[20.70954, 40.203549],
							[20.70918, 40.202712],
							[20.70902, 40.201445],
							[20.708593, 40.200656],
							[20.708264, 40.200274],
							[20.707466, 40.199769],
							[20.707037, 40.199286],
							[20.705263, 40.199002],
							[20.704239, 40.198619],
							[20.701928, 40.197448],
							[20.700341, 40.197064],
							[20.6972, 40.196599],
							[20.693893, 40.19616],
							[20.691909, 40.195926],
							[20.689928, 40.194983],
							[20.688213, 40.193788],
							[20.687867, 40.193376],
							[20.687094, 40.192543],
							[20.686203, 40.191958],
							[20.68541, 40.191753],
							[20.684352, 40.191699],
							[20.682334, 40.191617],
							[20.68046, 40.190981],
							[20.678646, 40.189735],
							[20.678088, 40.188846],
							[20.678424, 40.187909],
							[20.679986, 40.186216],
							[20.680488, 40.185026],
							[20.681054, 40.184318],
							[20.681288, 40.183761],
							[20.680004, 40.182363],
							[20.679224, 40.181219],
							[20.67858, 40.180397],
							[20.67803, 40.179949],
							[20.677712, 40.178755],
							[20.677944, 40.177712],
							[20.678044, 40.176966],
							[20.677626, 40.17612],
							[20.67669, 40.175322],
							[20.675884, 40.174177],
							[20.675887, 40.173605],
							[20.676234, 40.17294],
							[20.676919, 40.172122],
							[20.676923, 40.171227],
							[20.677154, 40.170433],
							[20.67719, 40.169638],
							[20.676289, 40.168219],
							[20.675936, 40.167422],
							[20.675295, 40.166179],
							[20.674806, 40.165288],
							[20.674868, 40.164828],
							[20.676135, 40.16368],
							[20.677101, 40.162624],
							[20.677299, 40.162507],
							[20.678486, 40.1618],
							[20.67975, 40.161297],
							[20.680715, 40.160425],
							[20.682222, 40.159233],
							[20.682945, 40.158544],
							[20.683308, 40.157993],
							[20.683541, 40.157855],
							[20.68385, 40.157672],
							[20.68475, 40.157905],
							[20.68541, 40.157953],
							[20.685651, 40.157723],
							[20.685708, 40.157426],
							[20.685775, 40.157079],
							[20.686197, 40.156574],
							[20.68698, 40.156208],
							[20.687641, 40.155979],
							[20.688482, 40.155982],
							[20.689231, 40.156055],
							[20.689442, 40.156076],
							[20.689797, 40.156054],
							[20.689923, 40.155985],
							[20.691397, 40.15512],
							[20.69218, 40.154524],
							[20.693203, 40.154066],
							[20.693505, 40.153745],
							[20.693808, 40.153055],
							[20.693634, 40.151766],
							[20.693459, 40.150615],
							[20.692668, 40.149505],
							[20.692027, 40.148494],
							[20.691551, 40.147664],
							[20.689161, 40.145034],
							[20.68665, 40.142634],
							[20.685394, 40.141526],
							[20.683359, 40.140369],
							[20.683, 40.139954],
							[20.683006, 40.138711],
							[20.682159, 40.136598],
							[20.680906, 40.13503],
							[20.680375, 40.133049],
							[20.678763, 40.131157],
							[20.67727, 40.12945],
							[20.676795, 40.128436],
							[20.676079, 40.127514],
							[20.675482, 40.126776],
							[20.675127, 40.12567],
							[20.675853, 40.124521],
							[20.676458, 40.123556],
							[20.677666, 40.121949],
							[20.677968, 40.121443],
							[20.677913, 40.120167],
							[20.67816, 40.118603],
							[20.678892, 40.11612],
							[20.679627, 40.112992],
							[20.680115, 40.111336],
							[20.679879, 40.110369],
							[20.679529, 40.108296],
							[20.678521, 40.105352],
							[20.677927, 40.1042],
							[20.677289, 40.103574],
							[20.676804, 40.102706],
							[20.676759, 40.10251],
							[20.676856, 40.102264],
							[20.677585, 40.100287],
							[20.678014, 40.098309],
							[20.677961, 40.096928],
							[20.678146, 40.095915],
							[20.678146, 40.095813],
							[20.678147, 40.095714],
							[20.678147, 40.09561],
							[20.678148, 40.095363],
							[20.675266, 40.095815],
							[20.673403, 40.096409],
							[20.671996, 40.097171],
							[20.670255, 40.097258],
							[20.668398, 40.096747],
							[20.666002, 40.095957],
							[20.663666, 40.095076],
							[20.66271, 40.094291],
							[20.660197, 40.092948],
							[20.659901, 40.092073],
							[20.659244, 40.091519],
							[20.657923, 40.091699],
							[20.656782, 40.092018],
							[20.652762, 40.092098],
							[20.649282, 40.092088],
							[20.645858, 40.092814],
							[20.644418, 40.092993],
							[20.64139, 40.09285],
							[20.639648, 40.093212],
							[20.638748, 40.09321],
							[20.637489, 40.093022],
							[20.631199, 40.091299],
							[20.628443, 40.090553],
							[20.627482, 40.090735],
							[20.626524, 40.090363],
							[20.624832, 40.089757],
							[20.623562, 40.089568],
							[20.623174, 40.089175],
							[20.622881, 40.088161],
							[20.622528, 40.08758],
							[20.621387, 40.085864],
							[20.620332, 40.084489],
							[20.620333, 40.084404],
							[20.619557, 40.083879],
							[20.61833, 40.082949],
							[20.617889, 40.082792],
							[20.614536, 40.082507],
							[20.61371, 40.081632],
							[20.614751, 40.080844],
							[20.613956, 40.08011],
							[20.611557, 40.078983],
							[20.610288, 40.078596],
							[20.609484, 40.077993],
							[20.608483, 40.077208],
							[20.606927, 40.076424],
							[20.6061, 40.075903],
							[20.60537, 40.075357],
							[20.605091, 40.075193],
							[20.604938, 40.075178],
							[20.604262, 40.07483],
							[20.601262, 40.07317],
							[20.600616, 40.072813],
							[20.599805, 40.072557],
							[20.598595, 40.072439],
							[20.596995, 40.07209],
							[20.596674, 40.072017],
							[20.595971, 40.071858],
							[20.595597, 40.071751],
							[20.593788, 40.071014],
							[20.593142, 40.070988],
							[20.591942, 40.07122],
							[20.590804, 40.071404],
							[20.590037, 40.071189],
							[20.590048, 40.071126],
							[20.590069, 40.071001],
							[20.589888, 40.070316],
							[20.589953, 40.069944],
							[20.589583, 40.070059],
							[20.589211, 40.070479],
							[20.588684, 40.070968],
							[20.588577, 40.07104],
							[20.588282, 40.071155],
							[20.588132, 40.071122],
							[20.586936, 40.070695],
							[20.584715, 40.06984],
							[20.582414, 40.069077],
							[20.579305, 40.067843],
							[20.577662, 40.067244],
							[20.574786, 40.066995],
							[20.573006, 40.066706],
							[20.572087, 40.066207],
							[20.569937, 40.065987],
							[20.568095, 40.065602],
							[20.566529, 40.065408],
							[20.565086, 40.065119],
							[20.563438, 40.065494],
							[20.562117, 40.065394],
							[20.560167, 40.065083],
							[20.559949, 40.06502],
							[20.55984, 40.065117],
							[20.559133, 40.065878],
							[20.557994, 40.066157],
							[20.556794, 40.066294],
							[20.555721, 40.065983],
							[20.554432, 40.065648],
							[20.552149, 40.064778],
							[20.551428, 40.064481],
							[20.549865, 40.063838],
							[20.549526, 40.064001],
							[20.548506, 40.064776],
							[20.547487, 40.065574],
							[20.54656, 40.066372],
							[20.545914, 40.06644],
							[20.545125, 40.066326],
							[20.543865, 40.06625],
							[20.542502, 40.066854],
							[20.541459, 40.067585],
							[20.540316, 40.068335],
							[20.539328, 40.069015],
							[20.538309, 40.069837],
							[20.537244, 40.070573],
							[20.53533, 40.071886],
							[20.534065, 40.072518],
							[20.531845, 40.073523],
							[20.529471, 40.074575],
							[20.527207, 40.075713],
							[20.526128, 40.076203],
							[20.525018, 40.076694],
							[20.523846, 40.077185],
							[20.522583, 40.077581],
							[20.520331, 40.078798],
							[20.519129, 40.079241],
							[20.517974, 40.079568],
							[20.516864, 40.080012],
							[20.515539, 40.080431],
							[20.514276, 40.080945],
							[20.51344, 40.081743],
							[20.512236, 40.082422],
							[20.511711, 40.082821],
							[20.510794, 40.082062],
							[20.50997, 40.081257],
							[20.509419, 40.080806],
							[20.509146, 40.080427],
							[20.508844, 40.079577],
							[20.508112, 40.078748],
							[20.5075, 40.078368],
							[20.507318, 40.078085],
							[20.507597, 40.077779],
							[20.508012, 40.077056],
							[20.508087, 40.076899],
							[20.508096, 40.076767],
							[20.508008, 40.076224],
							[20.507489, 40.075732],
							[20.505685, 40.074498],
							[20.504645, 40.073833],
							[20.502532, 40.072739],
							[20.50011, 40.071809],
							[20.499343, 40.071593],
							[20.499186, 40.071489],
							[20.49913, 40.07138],
							[20.499041, 40.070955],
							[20.498564, 40.069856],
							[20.498071, 40.069041],
							[20.497145, 40.067444],
							[20.495434, 40.066038],
							[20.493816, 40.06532],
							[20.492359, 40.065364],
							[20.492156, 40.065365],
							[20.492061, 40.065261],
							[20.49137, 40.064724],
							[20.488956, 40.064205],
							[20.486109, 40.064142],
							[20.483826, 40.063877],
							[20.480882, 40.064286],
							[20.480084, 40.064638],
							[20.478716, 40.064687],
							[20.477533, 40.064728],
							[20.475707, 40.06533],
							[20.474278, 40.066061],
							[20.47348, 40.066464],
							[20.47314, 40.067187],
							[20.473076, 40.067417],
							[20.473007, 40.067631],
							[20.47201, 40.068033],
							[20.471146, 40.068436],
							[20.47048, 40.068941],
							[20.469881, 40.069294],
							[20.468887, 40.06929],
							[20.467828, 40.069183],
							[20.467825, 40.06959],
							[20.468382, 40.070406],
							[20.46835, 40.070652],
							[20.468115, 40.070709],
							[20.466555, 40.071033],
							[20.466021, 40.071864],
							[20.465515, 40.073031],
							[20.465309, 40.073945],
							[20.465074, 40.074376],
							[20.464014, 40.07432],
							[20.46335, 40.074495],
							[20.463168, 40.074668],
							[20.462852, 40.074696],
							[20.462645, 40.074657],
							[20.461898, 40.074514],
							[20.459779, 40.074428],
							[20.458422, 40.074269],
							[20.457459, 40.074646],
							[20.455638, 40.074562],
							[20.45458, 40.074277],
							[20.453586, 40.074272],
							[20.452475, 40.074152],
							[20.452186, 40.073963],
							[20.451912, 40.073879],
							[20.450988, 40.073845],
							[20.449673, 40.073957],
							[20.447219, 40.072954],
							[20.44511, 40.071699],
							[20.443296, 40.070673],
							[20.441281, 40.070003],
							[20.440898, 40.069887],
							[20.438804, 40.069254],
							[20.43765, 40.068536],
							[20.437461, 40.068363],
							[20.437254, 40.068356],
							[20.435569, 40.067942],
							[20.4333, 40.066731],
							[20.433239, 40.066699],
							[20.433041, 40.066593],
							[20.432939, 40.066535],
							[20.431558, 40.065747],
							[20.430604, 40.06498],
							[20.430376, 40.064827],
							[20.430209, 40.064698],
							[20.428795, 40.063446],
							[20.426785, 40.062216],
							[20.425298, 40.061853],
							[20.423046, 40.061841],
							[20.421757, 40.061581],
							[20.420162, 40.060892],
							[20.419336, 40.060659],
							[20.419377, 40.059795],
							[20.419019, 40.05898],
							[20.41883, 40.057937],
							[20.418895, 40.057622],
							[20.418503, 40.057325],
							[20.415477, 40.055022],
							[20.412185, 40.052718],
							[20.410866, 40.051647],
							[20.409916, 40.050447],
							[20.409499, 40.048869],
							[20.409777, 40.047346],
							[20.410056, 40.045771],
							[20.410633, 40.044071],
							[20.411778, 40.042785],
							[20.413752, 40.040329],
							[20.414471, 40.038862],
							[20.414661, 40.038507],
							[20.41473, 40.03825],
							[20.415136, 40.037235],
							[20.41538, 40.035813],
							[20.415157, 40.03482],
							[20.413473, 40.033466],
							[20.41203, 40.031883],
							[20.411019, 40.030048],
							[20.410012, 40.027857],
							[20.409364, 40.026176],
							[20.408204, 40.024929],
							[20.407287, 40.023806],
							[20.404958, 40.021303],
							[20.403378, 40.020304],
							[20.402133, 40.018925],
							[20.400947, 40.018334],
							[20.399235, 40.017232],
							[20.397942, 40.015879],
							[20.397705, 40.015587],
							[20.397609, 40.015282],
							[20.397235, 40.014692],
							[20.397151, 40.013938],
							[20.397222, 40.012147],
							[20.3974, 40.010699],
							[20.397544, 40.009075],
							[20.397399, 40.006863],
							[20.397294, 40.00366],
							[20.396521, 40.001241],
							[20.39574, 39.999502],
							[20.394869, 39.997794],
							[20.39411, 39.996627],
							[20.393716, 39.996225],
							[20.392703, 39.995762],
							[20.391859, 39.995795],
							[20.39151, 39.995946],
							[20.390814, 39.996114],
							[20.390119, 39.996072],
							[20.389746, 39.995931],
							[20.389403, 39.995699],
							[20.388954, 39.995561],
							[20.388577, 39.995206],
							[20.387911, 39.99468],
							[20.387003, 39.994403],
							[20.385648, 39.994237],
							[20.384739, 39.994083],
							[20.383768, 39.99399],
							[20.382798, 39.99391],
							[20.382175, 39.993781],
							[20.381514, 39.993635],
							[20.3808, 39.993489],
							[20.380112, 39.993363],
							[20.379389, 39.993305],
							[20.378922, 39.993113],
							[20.378229, 39.992371],
							[20.377984, 39.992105],
							[20.377768, 39.991561],
							[20.377402, 39.990618],
							[20.377091, 39.989931],
							[20.376741, 39.989509],
							[20.376179, 39.989139],
							[20.375416, 39.989124],
							[20.374907, 39.989153],
							[20.373573, 39.9892],
							[20.367304, 39.989865],
							[20.362845, 39.990285],
							[20.360089, 39.990557],
							[20.356855, 39.990879],
							[20.354609, 39.991076],
							[20.348424, 39.99174],
							[20.343727, 39.992184],
							[20.340698, 39.99248],
							[20.337362, 39.992827],
							[20.330492, 39.993544],
							[20.322443, 39.992557],
							[20.317756, 39.99198],
							[20.312476, 39.991249],
							[20.311788, 39.991154],
							[20.312282, 39.989353],
							[20.312493, 39.988649],
							[20.31294, 39.988129],
							[20.313558, 39.987584],
							[20.313908, 39.986515],
							[20.314395, 39.985446],
							[20.315117, 39.984641],
							[20.315498, 39.983911],
							[20.315918, 39.98279],
							[20.316211, 39.981412],
							[20.316526, 39.980551],
							[20.316943, 39.979587],
							[20.316949, 39.979012],
							[20.317089, 39.978595],
							[20.317773, 39.978154],
							[20.318459, 39.977557],
							[20.318804, 39.977037],
							[20.318232, 39.976511],
							[20.317763, 39.975803],
							[20.3178, 39.975437],
							[20.318247, 39.975021],
							[20.318765, 39.974162],
							[20.319655, 39.97354],
							[20.320848, 39.973208],
							[20.321734, 39.97303],
							[20.322584, 39.972957],
							[20.323773, 39.973094],
							[20.324521, 39.973098],
							[20.324964, 39.972944],
							[20.325561, 39.972588],
							[20.325735, 39.972171],
							[20.325979, 39.971624],
							[20.326847, 39.969774],
							[20.327231, 39.968678],
							[20.327239, 39.967946],
							[20.327383, 39.967085],
							[20.327557, 39.966668],
							[20.32869, 39.965551],
							[20.329751, 39.964747],
							[20.334042, 39.961905],
							[20.335308, 39.960946],
							[20.335728, 39.959746],
							[20.336451, 39.958731],
							[20.337994, 39.957407],
							[20.339467, 39.956213],
							[20.340857, 39.95445],
							[20.341786, 39.95328],
							[20.342032, 39.952419],
							[20.342452, 39.951167],
							[20.342701, 39.94994],
							[20.343048, 39.949236],
							[20.344016, 39.947761],
							[20.344637, 39.946719],
							[20.345054, 39.945807],
							[20.345064, 39.944709],
							[20.344837, 39.94361],
							[20.344066, 39.942482],
							[20.343227, 39.941406],
							[20.342657, 39.940619],
							[20.341809, 39.939366],
							[20.341247, 39.937691],
							[20.340655, 39.935675],
							[20.340064, 39.933555],
							[20.339295, 39.930935],
							[20.33872, 39.928978],
							[20.337398, 39.924475],
							[20.33751, 39.923991],
							[20.337852, 39.923732],
							[20.338296, 39.923525],
							[20.338674, 39.923031],
							[20.339224, 39.922381],
							[20.339603, 39.921782],
							[20.340288, 39.921237],
							[20.341144, 39.920536],
							[20.342373, 39.91989],
							[20.343125, 39.919398],
							[20.344527, 39.91836],
							[20.345723, 39.917688],
							[20.346474, 39.9173],
							[20.348042, 39.916656],
							[20.348725, 39.91617],
							[20.348959, 39.915847],
							[20.350952, 39.914749],
							[20.352738, 39.913709],
							[20.353094, 39.913485],
							[20.353177, 39.913593],
							[20.35333, 39.913693],
							[20.354305, 39.914055],
							[20.354494, 39.914093],
							[20.354692, 39.913978],
							[20.356175, 39.91339],
							[20.357, 39.913202],
							[20.357465, 39.912941],
							[20.35822, 39.912542],
							[20.358278, 39.912453],
							[20.358333, 39.912369],
							[20.358356, 39.912335],
							[20.359046, 39.912039],
							[20.360988, 39.910822],
							[20.36259, 39.910217],
							[20.363981, 39.910225],
							[20.364163, 39.910008],
							[20.364274, 39.909077],
							[20.36441, 39.908305],
							[20.364602, 39.904266],
							[20.36459, 39.904003],
							[20.364709, 39.903811],
							[20.366349, 39.901919],
							[20.366523, 39.901696],
							[20.367029, 39.901268],
							[20.369585, 39.898246],
							[20.371135, 39.896312],
							[20.371382, 39.895978],
							[20.372, 39.894638],
							[20.373569, 39.891921],
							[20.374327, 39.892163],
							[20.375231, 39.89227],
							[20.37771, 39.891485],
							[20.381539, 39.890198],
							[20.38348, 39.889608],
							[20.383636, 39.889388],
							[20.383188, 39.887721],
							[20.382658, 39.885291],
							[20.382371, 39.884191],
							[20.381989, 39.88244],
							[20.382931, 39.88073],
							[20.384054, 39.879519],
							[20.38557, 39.877744],
							[20.386266, 39.876372],
							[20.387102, 39.87419],
							[20.387622, 39.872852],
							[20.387747, 39.871171],
							[20.388023, 39.870158],
							[20.388786, 39.868702],
							[20.390233, 39.867198],
							[20.39148, 39.865881],
							[20.392115, 39.864886],
							[20.393915, 39.863316],
							[20.395516, 39.861898],
							[20.396452, 39.860782],
							[20.397039, 39.859719],
							[20.397705, 39.85923],
							[20.399177, 39.857387],
							[20.40078, 39.855663],
							[20.402091, 39.854549],
							[20.403124, 39.853623],
							[20.404102, 39.852745],
							[20.404838, 39.851815],
							[20.405133, 39.850832],
							[20.405431, 39.84956],
							[20.406408, 39.848767],
							[20.407663, 39.847552],
							[20.408846, 39.845894],
							[20.410645, 39.844443],
							[20.411508, 39.844091],
							[20.412549, 39.843502],
							[20.414498, 39.842544],
							[20.415928, 39.841451],
							[20.416954, 39.840115],
							[20.417155, 39.839776],
							[20.415804, 39.837902],
							[20.413809, 39.835675],
							[20.413021, 39.834923],
							[20.412855, 39.833802],
							[20.413078, 39.832841],
							[20.414157, 39.831508],
							[20.415087, 39.830306],
							[20.41607, 39.828778],
							[20.41635, 39.82809],
							[20.416132, 39.8274],
							[20.415247, 39.82615],
							[20.414208, 39.825494],
							[20.414086, 39.825263],
							[20.414318, 39.824924],
							[20.414699, 39.824006],
							[20.41481, 39.822628],
							[20.414738, 39.822302],
							[20.414343, 39.821955],
							[20.413484, 39.820648],
							[20.413438, 39.820208],
							[20.414267, 39.819254],
							[20.414424, 39.817839],
							[20.414446, 39.815292],
							[20.414277, 39.81464],
							[20.413663, 39.813832],
							[20.411838, 39.812042],
							[20.411075, 39.81114],
							[20.410341, 39.809815],
							[20.40899, 39.807816],
							[20.407864, 39.805819],
							[20.407372, 39.805241],
							[20.405702, 39.804239],
							[20.405406, 39.804046],
							[20.404861, 39.803737],
							[20.403371, 39.803614],
							[20.402824, 39.803592],
							[20.402527, 39.803457],
							[20.40211, 39.802823],
							[20.40105, 39.801802],
							[20.400608, 39.80113],
							[20.400049, 39.79969],
							[20.398883, 39.798348],
							[20.397402, 39.797153],
							[20.396662, 39.796357],
							[20.395727, 39.795363],
							[20.393381, 39.793646],
							[20.392764, 39.793107],
							[20.392513, 39.79244],
							[20.392252, 39.791002],
							[20.391695, 39.789352],
							[20.391056, 39.788525],
							[20.389771, 39.78779],
							[20.38694, 39.787546],
							[20.385079, 39.787134],
							[20.384221, 39.786436],
							[20.38373, 39.78582],
							[20.382525, 39.784742],
							[20.382374, 39.784607],
							[20.38153, 39.784526],
							[20.380534, 39.784693],
							[20.378716, 39.785162],
							[20.3768, 39.785381],
							[20.375381, 39.785661],
							[20.373571, 39.786393],
							[20.372083, 39.787168],
							[20.370286, 39.787782],
							[20.368385, 39.788488],
							[20.366878, 39.789475],
							[20.365941, 39.790213],
							[20.364962, 39.790838],
							[20.364335, 39.791578],
							[20.363778, 39.792226],
							[20.362584, 39.792829],
							[20.361774, 39.792891],
							[20.361604, 39.793617],
							[20.361009, 39.794609],
							[20.359746, 39.795239],
							[20.358448, 39.795948],
							[20.357459, 39.796673],
							[20.357176, 39.796986],
							[20.356962, 39.797821],
							[20.356969, 39.798936],
							[20.35674, 39.799439],
							[20.355736, 39.799977],
							[20.354474, 39.800435],
							[20.353103, 39.80103],
							[20.35184, 39.801634],
							[20.350714, 39.802397],
							[20.349382, 39.802947],
							[20.348112, 39.803839],
							[20.346506, 39.804294],
							[20.34504, 39.804498],
							[20.344037, 39.804891],
							[20.342934, 39.804951],
							[20.341423, 39.805144],
							[20.339903, 39.805534],
							[20.338419, 39.805857],
							[20.338222, 39.805976],
							[20.336598, 39.806107],
							[20.334994, 39.806364],
							[20.333084, 39.80679],
							[20.331358, 39.807093],
							[20.330063, 39.807701],
							[20.329347, 39.808353],
							[20.328309, 39.808785],
							[20.327029, 39.809105],
							[20.325907, 39.80977],
							[20.325195, 39.809969],
							[20.324647, 39.810091],
							[20.32346, 39.810036],
							[20.322812, 39.810001],
							[20.320364, 39.809408],
							[20.318338, 39.809272],
							[20.316939, 39.809248],
							[20.315941, 39.809695],
							[20.315035, 39.8102],
							[20.314807, 39.810651],
							[20.314679, 39.811291],
							[20.314209, 39.811726],
							[20.313537, 39.81194],
							[20.31258, 39.812419],
							[20.311277, 39.812864],
							[20.310174, 39.813733],
							[20.309385, 39.814722],
							[20.308591, 39.815045],
							[20.30751, 39.815633],
							[20.306227, 39.81625],
							[20.304701, 39.816709],
							[20.304146, 39.816706],
							[20.30391, 39.816705],
							[20.303164, 39.816294],
							[20.303255, 39.815189],
							[20.303248, 39.815071],
							[20.303203, 39.814377],
							[20.303213, 39.813377],
							[20.302714, 39.812546],
							[20.301155, 39.811183],
							[20.299585, 39.810096],
							[20.297486, 39.809038],
							[20.297043, 39.808815],
							[20.294151, 39.80701],
							[20.29298, 39.806456],
							[20.290717, 39.80574],
							[20.28971, 39.805109],
							[20.289655, 39.804755],
							[20.289843, 39.804163],
							[20.290317, 39.803416],
							[20.29053, 39.802433],
							[20.290688, 39.801316],
							[20.2911, 39.800584],
							[20.29186, 39.799714],
							[20.293405, 39.798091],
							[20.294714, 39.797052],
							[20.296497, 39.795111],
							[20.298791, 39.793348],
							[20.29943, 39.792274],
							[20.299762, 39.791589],
							[20.300317, 39.790842],
							[20.30085, 39.790252],
							[20.302076, 39.789468],
							[20.30267, 39.788831],
							[20.303669, 39.788275],
							[20.304647, 39.787812],
							[20.304838, 39.786923],
							[20.305101, 39.785243],
							[20.305266, 39.784916],
							[20.305498, 39.784058],
							[20.306566, 39.782674],
							[20.307371, 39.781248],
							[20.307501, 39.780436],
							[20.307287, 39.779529],
							[20.306351, 39.777718],
							[20.30579, 39.77709],
							[20.304146, 39.775331],
							[20.302553, 39.77364],
							[20.300579, 39.772503],
							[20.299174, 39.771167],
							[20.297133, 39.769723],
							[20.296633, 39.769095],
							[20.295733, 39.767856],
							[20.294706, 39.767287],
							[20.292946, 39.766917],
							[20.292485, 39.766477],
							[20.292842, 39.765611],
							[20.293812, 39.763899],
							[20.294644, 39.761237],
							[20.294677, 39.760035],
							[20.295026, 39.758357],
							[20.295044, 39.756592],
							[20.295156, 39.75575],
							[20.295197, 39.755437],
							[20.295967, 39.755441],
							[20.297024, 39.755057],
							[20.298676, 39.754918],
							[20.300486, 39.754195],
							[20.301647, 39.753561],
							[20.303012, 39.752898],
							[20.303687, 39.75223],
							[20.304567, 39.751314],
							[20.305, 39.750613],
							[20.305051, 39.749508],
							[20.305134, 39.745784],
							[20.305722, 39.745277],
							[20.306952, 39.744217],
							[20.310639, 39.741954],
							[20.314209, 39.740333],
							[20.31823, 39.738237],
							[20.320659, 39.736579],
							[20.32183, 39.735432],
							[20.322081, 39.734797],
							[20.321956, 39.732519],
							[20.321776, 39.729127],
							[20.319375, 39.727982],
							[20.316208, 39.727116],
							[20.31223, 39.72656],
							[20.311558, 39.726579],
							[20.308911, 39.722536],
							[20.307812, 39.72202],
							[20.305859, 39.718923],
							[20.305407, 39.718285],
							[20.304858, 39.718234],
							[20.303034, 39.717493],
							[20.302339, 39.716806],
							[20.301381, 39.714963],
							[20.300031, 39.714323],
							[20.297991, 39.71384],
							[20.296463, 39.713948],
							[20.295209, 39.714199],
							[20.295113, 39.714646],
							[20.293644, 39.715038],
							[20.29285, 39.715057],
							[20.291543, 39.714483],
							[20.289966, 39.713437],
							[20.289232, 39.713551],
							[20.287497, 39.713116],
							[20.287167, 39.712502],
							[20.285226, 39.712201],
							[20.284283, 39.711912],
							[20.283679, 39.711296],
							[20.283625, 39.710542],
							[20.28363, 39.710071],
							[20.282935, 39.709431],
							[20.281116, 39.708172],
							[20.280269, 39.707413],
							[20.279637, 39.706585],
							[20.278883, 39.705756],
							[20.277922, 39.704242],
							[20.277496, 39.702927],
							[20.278145, 39.702154],
							[20.2787, 39.701521],
							[20.276186, 39.699763],
							[20.273759, 39.698335],
							[20.270354, 39.697088],
							[20.266907, 39.695894],
							[20.266057, 39.695465],
							[20.265067, 39.693857],
							[20.264477, 39.691946],
							[20.264344, 39.690037],
							[20.264139, 39.688253],
							[20.264039, 39.686227],
							[20.263416, 39.684551],
							[20.264076, 39.682671],
							[20.264409, 39.68124],
							[20.264876, 39.680371],
							[20.264522, 39.679239],
							[20.264591, 39.678462],
							[20.264629, 39.677756],
							[20.263909, 39.676597],
							[20.263826, 39.675796],
							[20.264606, 39.674176],
							[20.264457, 39.672887],
							[20.264685, 39.671475],
							[20.263403, 39.671609],
							[20.261167, 39.672372],
							[20.259243, 39.672618],
							[20.258251, 39.674096],
							[20.257296, 39.674914],
							[20.256043, 39.675118],
							[20.254456, 39.675108],
							[20.253511, 39.675102],
							[20.252218, 39.676153],
							[20.250639, 39.677553],
							[20.249896, 39.678585],
							[20.24911, 39.680699],
							[20.248574, 39.681638],
							[20.246823, 39.682627],
							[20.245576, 39.682799],
							[20.241243, 39.684151],
							[20.236819, 39.684457],
							[20.231394, 39.685131],
							[20.230825, 39.683201],
							[20.230241, 39.682843],
							[20.229632, 39.682523],
							[20.229818, 39.681854],
							[20.229648, 39.681005],
							[20.229199, 39.680095],
							[20.229457, 39.67982],
							[20.231333, 39.678926],
							[20.231591, 39.678632],
							[20.230748, 39.677902],
							[20.229888, 39.677107],
							[20.229461, 39.676474],
							[20.22899, 39.67513],
							[20.228907, 39.673374],
							[20.228813, 39.672723],
							[20.228258, 39.672049],
							[20.227079, 39.671103],
							[20.226137, 39.670781],
							[20.2242, 39.670354],
							[20.222826, 39.669852],
							[20.221964, 39.669333],
							[20.220525, 39.667726],
							[20.219913, 39.666169],
							[20.219425, 39.665753],
							[20.21902, 39.665494],
							[20.21863, 39.66509],
							[20.218615, 39.664827],
							[20.218781, 39.664412],
							[20.219725, 39.663518],
							[20.220071, 39.663052],
							[20.220092, 39.662816],
							[20.220256, 39.662582],
							[20.220727, 39.662197],
							[20.221126, 39.661826],
							[20.221309, 39.661467],
							[20.221553, 39.660512],
							[20.221887, 39.6596],
							[20.222161, 39.659145],
							[20.222416, 39.658828],
							[20.22327, 39.658336],
							[20.224014, 39.657663],
							[20.224379, 39.657111],
							[20.224729, 39.65624],
							[20.22508, 39.65537],
							[20.225528, 39.654573],
							[20.225765, 39.654215],
							[20.225994, 39.652983],
							[20.226087, 39.652748],
							[20.226339, 39.652639],
							[20.226716, 39.652614],
							[20.227309, 39.652576],
							[20.228011, 39.652359],
							[20.22868, 39.651934],
							[20.228919, 39.651395],
							[20.228996, 39.650925],
							[20.228731, 39.650122],
							[20.228252, 39.649592],
							[20.227613, 39.648978],
							[20.226777, 39.64828],
							[20.225975, 39.647818],
							[20.224813, 39.647422],
							[20.223792, 39.647249],
							[20.22267, 39.646992],
							[20.221741, 39.646695],
							[20.221028, 39.646274],
							[20.220741, 39.646217],
							[20.220418, 39.646215],
							[20.219861, 39.646308],
							[20.218637, 39.646674],
							[20.217052, 39.647148],
							[20.215956, 39.647321],
							[20.215264, 39.647254],
							[20.213102, 39.646491],
							[20.211759, 39.64626],
							[20.21081, 39.646018],
							[20.20986, 39.64597],
							[20.208352, 39.646029],
							[20.206, 39.646124],
							[20.204677, 39.646061],
							[20.202544, 39.645811],
							[20.201398, 39.645665],
							[20.200395, 39.645478],
							[20.19918, 39.645054],
							[20.19857, 39.644994],
							[20.197995, 39.645059],
							[20.197186, 39.645248],
							[20.196204, 39.645258],
							[20.193553, 39.644866],
							[20.193033, 39.644863],
							[20.192243, 39.644926],
							[20.191397, 39.645128],
							[20.190568, 39.645469],
							[20.189701, 39.645961],
							[20.188653, 39.646522],
							[20.187625, 39.646972],
							[20.186767, 39.647203],
							[20.185955, 39.647557],
							[20.185251, 39.64794],
							[20.184384, 39.648419],
							[20.183463, 39.648856],
							[20.182241, 39.649083],
							[20.180966, 39.649157],
							[20.180086, 39.649179],
							[20.179279, 39.649132],
							[20.178742, 39.649031],
							[20.178152, 39.648846],
							[20.177543, 39.648801],
							[20.176093, 39.648754],
							[20.175447, 39.648722],
							[20.174891, 39.648718],
							[20.174155, 39.64874],
							[20.172539, 39.648853],
							[20.17074, 39.649201],
							[20.168905, 39.649603],
							[20.167203, 39.649929],
							[20.165225, 39.650261],
							[20.162688, 39.650839],
							[20.161235, 39.651035],
							[20.160872, 39.651131],
							[20.160503, 39.651264],
							[20.160281, 39.65138],
							[20.159681, 39.651761],
							[20.158133, 39.652823],
							[20.157967, 39.652919],
							[20.157302, 39.653288],
							[20.156507, 39.653712],
							[20.15557, 39.654024],
							[20.154238, 39.654361],
							[20.152815, 39.654793],
							[20.151992, 39.655024],
							[20.1512, 39.655281],
							[20.15048, 39.655442],
							[20.150103, 39.655481],
							[20.149852, 39.655452],
							[20.149299, 39.655157],
							[20.148852, 39.655029],
							[20.147938, 39.654939],
							[20.146575, 39.654873],
							[20.144871, 39.654764],
							[20.141934, 39.654644],
							[20.140374, 39.654577],
							[20.138635, 39.654467],
							[20.136787, 39.654384],
							[20.135281, 39.654276],
							[20.134463, 39.654318],
							[20.132627, 39.654775],
							[20.131169, 39.655069],
							[20.129099, 39.65558],
							[20.127388, 39.656066],
							[20.12593, 39.656456],
							[20.124955, 39.656879],
							[20.124276, 39.65728],
							[20.123575, 39.657658],
							[20.12324, 39.657728],
							[20.122772, 39.657807],
							[20.122075, 39.658008],
							[20.121352, 39.658277],
							[20.120719, 39.6586],
							[20.11981, 39.659157],
							[20.119255, 39.659709],
							[20.118853, 39.660228],
							[20.118719, 39.660407],
							[20.118259, 39.660716],
							[20.117625, 39.66115],
							[20.116746, 39.661691],
							[20.115868, 39.662162],
							[20.114819, 39.662826],
							[20.114266, 39.663221],
							[20.113602, 39.66363],
							[20.113182, 39.663948],
							[20.112976, 39.664197],
							[20.112757, 39.664696],
							[20.11247, 39.665359],
							[20.111995, 39.665824],
							[20.111216, 39.666162],
							[20.110421, 39.666537],
							[20.110115, 39.666664],
							[20.109748, 39.666662],
							[20.109201, 39.666563],
							[20.108607, 39.666441],
							[20.107525, 39.666327],
							[20.106381, 39.666318],
							[20.105557, 39.666347],
							[20.104851, 39.666683],
							[20.104403, 39.667115],
							[20.103807, 39.668382],
							[20.103558, 39.668734],
							[20.103419, 39.668839],
							[20.102853, 39.668964],
							[20.102034, 39.669107],
							[20.100979, 39.669299],
							[20.099818, 39.669408],
							[20.099205, 39.669603],
							[20.098912, 39.66986],
							[20.098213, 39.670832],
							[20.096806, 39.672986],
							[20.095665, 39.67505],
							[20.094744, 39.676668],
							[20.094492, 39.677089],
							[20.094443, 39.677372],
							[20.094269, 39.67783],
							[20.093452, 39.678424],
							[20.092592, 39.678888],
							[20.092162, 39.679096],
							[20.091864, 39.679707],
							[20.091542, 39.680241],
							[20.091032, 39.680803],
							[20.090338, 39.681327],
							[20.089538, 39.681839],
							[20.088677, 39.682303],
							[20.088248, 39.682477],
							[20.08788, 39.682544],
							[20.087545, 39.682506],
							[20.086297, 39.682249],
							[20.085202, 39.682017],
							[20.083922, 39.681818],
							[20.083118, 39.681748],
							[20.0826, 39.681745],
							[20.081595, 39.681572],
							[20.080971, 39.681402],
							[20.080516, 39.681198],
							[20.079944, 39.680616],
							[20.079337, 39.680341],
							[20.078439, 39.680192],
							[20.077768, 39.680187],
							[20.077216, 39.680324],
							[20.076548, 39.680703],
							[20.075958, 39.68105],
							[20.075576, 39.681486],
							[20.075207, 39.681683],
							[20.073159, 39.682721],
							[20.072463, 39.68371],
							[20.07097, 39.684699],
							[20.07074, 39.68507],
							[20.070155, 39.685744],
							[20.069411, 39.686355],
							[20.068926, 39.68663],
							[20.068186, 39.687077],
							[20.067503, 39.687979],
							[20.06674, 39.688582],
							[20.065599, 39.689409],
							[20.065137, 39.6897],
							[20.064648, 39.689743],
							[20.063926, 39.689663],
							[20.063149, 39.689551],
							[20.062098, 39.689413],
							[20.061213, 39.689382],
							[20.060066, 39.689538],
							[20.059162, 39.689848],
							[20.058647, 39.69011],
							[20.058319, 39.690684],
							[20.058125, 39.691483],
							[20.058045, 39.691789],
							[20.057952, 39.691906],
							[20.05757, 39.691903],
							[20.057054, 39.691675],
							[20.055913, 39.69143],
							[20.054863, 39.691221],
							[20.054191, 39.691192],
							[20.05323, 39.691173],
							[20.051841, 39.691197],
							[20.050606, 39.691447],
							[20.049656, 39.691686],
							[20.048739, 39.691773],
							[20.048174, 39.691827],
							[20.047773, 39.692118],
							[20.04737, 39.692586],
							[20.046946, 39.69343],
							[20.046723, 39.694147],
							[20.046475, 39.694392],
							[20.046214, 39.694496],
							[20.045955, 39.694518],
							[20.04562, 39.694397],
							[20.045198, 39.69404],
							[20.044881, 39.693743],
							[20.044374, 39.692926],
							[20.044165, 39.692595],
							[20.043923, 39.692452],
							[20.042569, 39.692087],
							[20.041357, 39.691629],
							[20.040886, 39.691495],
							[20.040322, 39.691385],
							[20.038978, 39.691468],
							[20.038169, 39.691473],
							[20.037433, 39.691761],
							[20.036386, 39.692377],
							[20.03562, 39.692606],
							[20.035131, 39.692673],
							[20.034838, 39.69287],
							[20.034589, 39.693198],
							[20.034229, 39.693724],
							[20.034021, 39.69437],
							[20.033787, 39.695887],
							[20.033632, 39.697624],
							[20.033568, 39.698385],
							[20.033871, 39.699602],
							[20.033868, 39.699828],
							[20.033651, 39.700094],
							[20.032929, 39.700232],
							[20.031751, 39.70049],
							[20.030786, 39.700832],
							[20.030333, 39.700848],
							[20.029907, 39.700783],
							[20.028317, 39.699988],
							[20.027896, 39.699593],
							[20.027868, 39.699021],
							[20.027873, 39.698671],
							[20.028074, 39.697623],
							[20.028216, 39.697028],
							[20.028006, 39.696738],
							[20.02737, 39.696465],
							[20.025935, 39.696103],
							[20.024815, 39.696011],
							[20.023111, 39.695832],
							[20.022416, 39.695909],
							[20.021879, 39.696172],
							[20.021371, 39.69625],
							[20.020891, 39.696225],
							[20.020118, 39.696219],
							[20.019528, 39.696378],
							[20.01851, 39.696699],
							[20.018083, 39.696716],
							[20.01771, 39.696692],
							[20.016441, 39.695879],
							[20.015463, 39.695274],
							[20.014349, 39.694812],
							[20.012784, 39.69416],
							[20.011981, 39.693687],
							[20.01132, 39.693249],
							[20.009545, 39.692452],
							[20.009124, 39.691477],
							[20.008818, 39.690447],
							[19.99928, 39.669609],
							[19.98038, 39.650172],
							[19.958387, 39.673085],
							[19.929737, 39.688855],
							[19.92699, 39.694932],
							[19.926647, 39.698894],
							[19.927849, 39.702724],
							[19.93111, 39.707214],
							[19.935917, 39.712365],
							[19.942097, 39.718835],
							[19.94759, 39.724381],
							[19.950508, 39.728341],
							[19.953941, 39.734942],
							[19.957031, 39.743522],
							[19.962181, 39.760151],
							[19.963383, 39.76279],
							[19.965958, 39.767409],
							[19.970764, 39.774929],
							[19.973339, 39.779415],
							[19.975227, 39.784164],
							[19.975571, 39.788781],
							[19.974197, 39.793002],
							[19.962353, 39.820563],
							[19.960293, 39.823727],
							[19.957203, 39.8261],
							[19.948322, 39.831128],
							[19.863647, 39.872885],
							[19.808816, 39.919426],
							[19.806149, 39.918853],
							[19.798006, 39.91665],
							[19.790069, 39.914041],
							[19.782371, 39.911037],
							[19.774945, 39.907651],
							[19.767823, 39.903899],
							[19.761424, 39.900044],
							[19.753798, 39.900341],
							[19.750962, 39.900365],
							[19.747438, 39.900365],
							[19.741645, 39.900266],
							[19.733042, 39.899756],
							[19.728982, 39.899308],
							[19.728879, 39.900342],
							[19.727655, 39.906921],
							[19.725874, 39.913424],
							[19.723544, 39.919822],
							[19.720676, 39.92609],
							[19.71728, 39.9322],
							[19.713372, 39.938126],
							[19.708969, 39.943842],
							[19.70713, 39.945999],
							[19.704865, 39.948832],
							[19.699984, 39.954314],
							[19.694646, 39.959538],
							[19.688875, 39.964482],
							[19.682696, 39.969124],
							[19.681115, 39.970219],
							[19.679764, 39.971182],
							[19.673201, 39.975504],
							[19.666285, 39.979485],
							[19.659044, 39.983109],
							[19.651511, 39.986361],
							[19.647248, 39.987988],
							[19.642492, 39.989721],
							[19.640645, 39.990471],
							[19.63285, 39.993336],
							[19.624826, 39.995802],
							[19.61661, 39.997858],
							[19.608236, 39.999496],
							[19.59974, 40.000709],
							[19.591159, 40.001491],
							[19.58253, 40.001839],
							[19.579946, 40.001859],
							[19.571721, 40.001859],
							[19.565665, 40.001751],
							[19.55705, 40.001229],
							[19.548499, 40.000273],
							[19.540048, 39.998889],
							[19.531733, 39.997082],
							[19.52359, 39.99486],
							[19.515654, 39.992232],
							[19.507959, 39.989211],
							[19.500538, 39.985808],
							[19.493423, 39.982039],
							[19.486644, 39.97792],
							[19.480231, 39.973468],
							[19.474211, 39.968702],
							[19.470443, 39.965374],
							[19.470261, 39.965206],
							[19.469711, 39.965343],
							[19.466609, 39.965999],
							[19.465599, 39.966226],
							[19.457225, 39.967853],
							[19.448731, 39.969055],
							[19.440152, 39.969826],
							[19.431526, 39.970163],
							[19.429159, 39.97018],
							[19.425928, 39.97018],
							[19.419658, 39.970065],
							[19.411049, 39.969531],
							[19.406715, 39.969096],
							[19.398435, 39.96983],
							[19.389809, 39.970164],
							[19.387501, 39.97018],
							[19.384856, 39.97018],
							[19.378527, 39.970063],
							[19.369918, 39.969526],
							[19.361374, 39.968557],
							[19.35293, 39.967159],
							[19.344624, 39.965339],
							[19.33649, 39.963104],
							[19.328565, 39.960464],
							[19.320881, 39.95743],
							[19.313472, 39.954016],
							[19.306371, 39.950235],
							[19.305147, 39.949529],
							[19.304317, 39.949059],
							[19.3033, 39.948473],
							[19.302262, 39.947887],
							[19.295498, 39.943756],
							[19.2891, 39.939294],
							[19.283096, 39.934518],
							[19.277512, 39.929451],
							[19.27237, 39.924112],
							[19.267694, 39.918526],
							[19.263503, 39.912717],
							[19.259816, 39.906708],
							[19.257604, 39.902524],
							[19.257313, 39.901936],
							[19.256356, 39.899939],
							[19.253719, 39.893611],
							[19.253384, 39.892689],
							[19.252841, 39.891264],
							[19.250748, 39.884817],
							[19.249207, 39.878278],
							[19.248225, 39.871675],
							[19.247805, 39.865037],
							[19.247788, 39.863268],
						],
					],
					[
						[
							[20.869162, 37.262393],
							[20.869165, 37.26063],
							[20.869228, 37.257557],
							[20.869763, 37.250923],
							[20.87084, 37.244332],
							[20.872453, 37.237811],
							[20.87257, 37.23741],
							[20.873337, 37.234585],
							[20.874617, 37.230746],
							[20.875698, 37.226374],
							[20.87784, 37.219952],
							[20.880502, 37.213655],
							[20.883673, 37.20751],
							[20.887339, 37.201545],
							[20.891485, 37.195784],
							[20.896092, 37.190252],
							[20.900194, 37.185909],
							[20.90078, 37.185322],
							[20.901726, 37.184386],
							[20.907194, 37.179382],
							[20.913059, 37.174675],
							[20.919296, 37.170284],
							[20.925877, 37.166229],
							[20.932775, 37.162526],
							[20.939959, 37.159192],
							[20.947401, 37.156241],
							[20.947544, 37.156189],
							[20.953992, 37.153846],
							[20.961515, 37.151342],
							[20.969372, 37.149192],
							[20.977388, 37.147457],
							[20.985528, 37.146145],
							[20.993758, 37.145262],
							[21.002042, 37.14481],
							[21.010346, 37.144793],
							[21.018633, 37.14521],
							[21.026648, 37.146031],
							[21.032143, 37.146646],
							[21.040292, 37.147924],
							[21.048319, 37.149625],
							[21.056191, 37.151742],
							[21.063873, 37.154266],
							[21.071333, 37.157187],
							[21.078539, 37.160492],
							[21.085461, 37.164166],
							[21.088849, 37.166163],
							[21.093532, 37.169074],
							[21.099629, 37.173322],
							[21.099797, 37.173439],
							[21.105692, 37.178122],
							[21.111193, 37.183103],
							[21.112185, 37.184076],
							[21.112773, 37.184659],
							[21.116863, 37.188945],
							[21.121505, 37.194458],
							[21.125688, 37.200201],
							[21.129392, 37.206152],
							[21.132603, 37.212283],
							[21.135306, 37.218569],
							[21.137489, 37.224983],
							[21.139144, 37.231497],
							[21.140262, 37.238084],
							[21.140839, 37.244715],
							[21.140925, 37.2483],
							[21.140928, 37.249769],
							[21.140875, 37.252831],
							[21.140365, 37.259466],
							[21.139313, 37.26606],
							[21.137723, 37.272585],
							[21.135602, 37.279013],
							[21.132959, 37.285316],
							[21.131466, 37.288373],
							[21.130594, 37.290121],
							[21.12805, 37.29499],
							[21.124399, 37.300963],
							[21.120266, 37.306733],
							[21.115668, 37.312274],
							[21.111694, 37.316501],
							[21.111402, 37.316795],
							[21.110334, 37.317857],
							[21.110102, 37.318069],
							[21.106874, 37.32196],
							[21.101831, 37.327248],
							[21.096364, 37.332262],
							[21.090498, 37.336979],
							[21.084258, 37.341379],
							[21.07767, 37.345444],
							[21.070762, 37.349155],
							[21.063564, 37.352497],
							[21.056107, 37.355456],
							[21.048423, 37.358019],
							[21.040546, 37.360174],
							[21.032508, 37.361913],
							[21.029945, 37.362374],
							[21.029276, 37.362502],
							[21.021113, 37.363817],
							[21.012859, 37.364702],
							[21.004549, 37.365154],
							[20.996221, 37.365171],
							[20.987909, 37.364752],
							[20.979649, 37.363899],
							[20.971478, 37.362617],
							[20.963429, 37.36091],
							[20.95745, 37.359344],
							[20.952309, 37.357908],
							[20.944609, 37.355376],
							[20.937134, 37.352447],
							[20.929915, 37.349133],
							[20.929384, 37.348869],
							[20.928796, 37.348575],
							[20.922396, 37.345155],
							[20.915783, 37.341117],
							[20.909515, 37.336742],
							[20.90362, 37.332049],
							[20.898122, 37.327057],
							[20.893046, 37.321789],
							[20.888413, 37.316267],
							[20.884243, 37.310514],
							[20.880553, 37.304556],
							[20.87736, 37.298417],
							[20.874676, 37.292125],
							[20.872514, 37.285706],
							[20.870882, 37.279188],
							[20.869787, 37.272599],
							[20.869234, 37.265966],
							[20.869162, 37.262393],
						],
					],
					[
						[
							[23.862263, 34.944458],
							[23.863781, 34.920387],
							[23.864622, 34.916254],
							[23.866443, 34.909784],
							[23.868774, 34.903426],
							[23.870245, 34.899907],
							[23.873075, 34.893688],
							[23.876391, 34.887635],
							[23.880181, 34.881773],
							[23.884427, 34.876128],
							[23.889111, 34.870724],
							[23.894214, 34.865583],
							[23.899712, 34.860729],
							[23.900933, 34.859734],
							[23.906752, 34.854574],
							[23.911715, 34.850729],
							[23.912437, 34.850056],
							[23.912533, 34.847632],
							[23.913308, 34.841021],
							[23.914606, 34.834467],
							[23.916421, 34.827996],
							[23.918745, 34.821637],
							[23.921567, 34.815417],
							[23.924877, 34.809363],
							[23.928658, 34.8035],
							[23.932897, 34.797853],
							[23.937573, 34.792447],
							[23.940755, 34.789154],
							[23.942962, 34.787011],
							[23.947355, 34.783076],
							[23.953352, 34.776651],
							[23.957926, 34.772063],
							[23.963414, 34.767206],
							[23.969274, 34.762654],
							[23.97115, 34.761318],
							[23.976021, 34.75767],
							[23.982228, 34.753444],
							[23.987307, 34.750378],
							[23.993957, 34.745827],
							[24.000484, 34.741944],
							[24.003883, 34.740124],
							[24.006347, 34.73872],
							[24.013167, 34.735198],
							[24.02025, 34.73205],
							[24.024653, 34.730333],
							[24.031973, 34.72707],
							[24.036972, 34.725133],
							[24.040359, 34.723877],
							[24.041745, 34.722979],
							[24.048268, 34.719094],
							[24.053083, 34.71655],
							[24.055669, 34.715275],
							[24.062748, 34.712125],
							[24.070062, 34.709363],
							[24.077578, 34.707002],
							[24.085265, 34.705051],
							[24.089368, 34.704171],
							[24.097193, 34.702638],
							[24.105121, 34.701532],
							[24.11312, 34.700855],
							[24.121156, 34.700611],
							[24.124001, 34.70061],
							[24.131537, 34.700801],
							[24.139542, 34.701424],
							[24.147481, 34.702478],
							[24.155321, 34.703957],
							[24.163026, 34.705857],
							[24.170565, 34.708168],
							[24.177906, 34.710881],
							[24.185016, 34.713983],
							[24.188429, 34.715652],
							[24.192451, 34.717757],
							[24.195637, 34.719556],
							[24.201248, 34.722443],
							[24.207809, 34.726285],
							[24.214053, 34.730472],
							[24.219952, 34.734987],
							[24.225482, 34.739811],
							[24.228978, 34.743215],
							[24.23091, 34.74522],
							[24.235631, 34.750598],
							[24.239917, 34.756219],
							[24.243748, 34.76206],
							[24.247109, 34.768094],
							[24.249984, 34.774297],
							[24.252363, 34.780642],
							[24.254233, 34.787102],
							[24.255588, 34.793649],
							[24.256421, 34.800255],
							[24.256729, 34.806892],
							[24.256735, 34.809951],
							[24.256514, 34.816171],
							[24.255768, 34.822784],
							[24.2545, 34.829343],
							[24.252713, 34.835819],
							[24.250417, 34.842185],
							[24.248229, 34.847159],
							[24.248592, 34.854421],
							[24.248595, 34.855714],
							[24.248373, 34.861942],
							[24.247626, 34.868555],
							[24.246356, 34.875114],
							[24.244568, 34.881591],
							[24.242269, 34.887957],
							[24.23947, 34.894186],
							[24.236181, 34.90025],
							[24.228024, 34.912877],
							[24.223801, 34.918535],
							[24.219137, 34.923954],
							[24.216173, 34.927042],
							[24.213758, 34.929403],
							[24.208274, 34.934274],
							[24.202414, 34.938838],
							[24.196204, 34.943077],
							[24.189669, 34.946971],
							[24.182839, 34.950505],
							[24.175741, 34.953664],
							[24.168408, 34.956432],
							[24.163544, 34.958013],
							[24.159886, 34.959539],
							[24.152551, 34.962306],
							[24.145012, 34.964673],
							[24.141781, 34.965555],
							[24.134069, 34.96751],
							[24.126219, 34.969044],
							[24.121234, 34.969738],
							[24.119167, 34.973412],
							[24.115391, 34.979282],
							[24.111156, 34.984936],
							[24.10648, 34.99035],
							[24.101383, 34.995501],
							[24.095887, 35.000366],
							[24.090016, 35.004925],
							[24.083794, 35.009157],
							[24.077248, 35.013045],
							[24.073983, 35.014792],
							[24.071377, 35.016276],
							[24.064535, 35.019803],
							[24.051554, 35.025596],
							[24.035692, 35.031588],
							[24.028143, 35.033946],
							[24.020421, 35.035893],
							[24.012561, 35.03742],
							[24.004597, 35.03852],
							[23.996561, 35.039189],
							[23.98849, 35.039423],
							[23.985828, 35.039424],
							[23.978068, 35.039223],
							[23.970029, 35.038587],
							[23.962057, 35.03752],
							[23.954188, 35.036026],
							[23.946455, 35.034112],
							[23.938891, 35.031785],
							[23.931529, 35.029055],
							[23.9244, 35.025935],
							[23.921666, 35.024605],
							[23.91695, 35.022148],
							[23.910378, 35.018288],
							[23.904129, 35.014083],
							[23.898227, 35.00955],
							[23.8927, 35.004709],
							[23.887569, 34.999581],
							[23.882858, 34.994188],
							[23.878587, 34.988552],
							[23.874773, 34.982699],
							[23.871432, 34.976652],
							[23.86858, 34.970439],
							[23.866229, 34.964085],
							[23.864387, 34.957619],
							[23.863063, 34.951067],
							[23.862263, 34.944458],
						],
					],
					[
						[
							[29.310589, 36.011],
							[29.354691, 35.979645],
							[29.399908, 35.947485],
							[29.47398, 35.932627],
							[29.555934, 35.916185],
							[29.637933, 35.919401],
							[29.729699, 35.923],
							[29.725849, 35.942124],
							[29.721359, 35.964422],
							[29.714676, 36.004978],
							[29.707082, 36.046987],
							[29.700458, 36.085771],
							[29.670245, 36.115453],
							[29.659602, 36.119197],
							[29.647586, 36.125298],
							[29.641406, 36.128071],
							[29.629905, 36.135142],
							[29.619777, 36.14249],
							[29.615142, 36.148866],
							[29.615485, 36.15247],
							[29.616, 36.15649],
							[29.613425, 36.163004],
							[29.616344, 36.168824],
							[29.613597, 36.174783],
							[29.604842, 36.17603],
							[29.592654, 36.176723],
							[29.587161, 36.175476],
							[29.573943, 36.173536],
							[29.56227, 36.167716],
							[29.547248, 36.16614],
							[29.533774, 36.170764],
							[29.526908, 36.175337],
							[29.506824, 36.184482],
							[29.483821, 36.185313],
							[29.468886, 36.169517],
							[29.430587, 36.132135],
							[29.389178, 36.090044],
							[29.348936, 36.049816],
							[29.310589, 36.011],
						],
					],
				],
			},
			arbitrip_center: {
				type: 'Point',
				coordinates: [23.999144, 38.247967],
			},
		},
		descendants: {
			province_state: [
				'6051352',
				'180100',
				'6051354',
				'6283481',
				'553248635976852064',
				'677237589091147776',
				'6054804',
				'6051350',
				'553248635975751962',
				'6051347',
				'6052275',
				'553248635975751590',
				'6051339',
				'6051335',
			],
			high_level_region: [
				'6051356',
				'632716962364821504',
				'6271740',
				'6051353',
				'6051355',
				'6142581',
				'553248635975177878',
				'629994275614412800',
				'6139776',
				'553248635976196179',
				'553248635977044039',
				'630012007025000448',
			],
			point_of_interest: ['553248635050901425', '553248634289900567', '553248635975793642'],
		},
		name: 'Greece',
		name_full: 'Greece',
		property_ids: [],
		type: 'country',
	},

	/* 5 */
	{
		_id: ObjectId('61b26264d17acfb0a7f288eb'),
		id: '648855853362323456',
		coordinates: {
			center_longitude: 13.226043,
			center_latitude: 50.574673,
			bounding_polygon: {
				type: 'MultiPolygon',
				coordinates: [
					[
						[
							[-31.577729, 39.445783],
							[-31.576931, 39.440057],
							[-31.574529, 39.434797],
							[-31.400138, 39.164871],
							[-31.395357, 39.159738],
							[-31.389098, 39.156572],
							[-31.38213, 39.155761],
							[-31.003386, 39.178693],
							[-30.997112, 39.180109],
							[-30.991612, 39.183443],
							[-30.987454, 39.188351],
							[-30.98507, 39.194325],
							[-30.852275, 39.792962],
							[-30.851882, 39.799092],
							[-30.853367, 39.805052],
							[-30.856591, 39.81028],
							[-30.861249, 39.814283],
							[-30.866903, 39.816684],
							[-31.253974, 39.91446],
							[-31.26008, 39.915032],
							[-31.266072, 39.913728],
							[-31.271388, 39.910669],
							[-31.275526, 39.906143],
							[-31.574383, 39.456725],
							[-31.576856, 39.451499],
							[-31.577729, 39.445783],
						],
					],
					[
						[
							[-29.115618, 38.592361],
							[-29.114289, 38.585996],
							[-29.110988, 38.580393],
							[-28.908517, 38.33791],
							[-28.903829, 38.33381],
							[-28.898108, 38.331349],
							[-28.224648, 38.15956],
							[-28.21778, 38.159032],
							[-28.211141, 38.160865],
							[-27.505081, 38.495426],
							[-27.00038, 38.409663],
							[-26.993518, 38.409691],
							[-26.987069, 38.412037],
							[-26.981793, 38.416425],
							[-26.744886, 38.695051],
							[-26.741422, 38.700917],
							[-26.740127, 38.707604],
							[-26.741152, 38.714338],
							[-26.744377, 38.720338],
							[-26.749429, 38.724908],
							[-27.217812, 39.021279],
							[-27.224999, 39.024069],
							[-27.232707, 39.023932],
							[-27.545301, 38.95678],
							[-27.551058, 38.954571],
							[-27.555868, 38.950714],
							[-27.559275, 38.945575],
							[-27.652065, 38.743606],
							[-27.848365, 38.820679],
							[-27.684131, 39.103862],
							[-27.681858, 39.109791],
							[-27.681558, 39.116135],
							[-27.683262, 39.122253],
							[-27.686798, 39.127528],
							[-27.69181, 39.131429],
							[-28.043947, 39.324678],
							[-28.050249, 39.326867],
							[-28.05692, 39.326862],
							[-28.063218, 39.324663],
							[-28.312269, 39.187478],
							[-28.317905, 39.182857],
							[-28.321512, 39.176524],
							[-28.322609, 39.16932],
							[-28.316372, 38.974448],
							[-28.976247, 38.790738],
							[-28.982436, 38.787797],
							[-28.987268, 38.782939],
							[-29.112022, 38.604679],
							[-29.114835, 38.598816],
							[-29.115618, 38.592361],
						],
					],
					[
						[
							[-26.117174, 37.829552],
							[-26.11626, 37.822792],
							[-26.113127, 37.816733],
							[-25.307856, 36.754302],
							[-25.303344, 36.749969],
							[-25.297714, 36.747242],
							[-25.291517, 36.746387],
							[-24.850448, 36.75521],
							[-24.844365, 36.756285],
							[-24.838901, 36.759166],
							[-24.834576, 36.763576],
							[-24.522771, 37.199853],
							[-24.520093, 37.205087],
							[-24.519052, 37.210874],
							[-24.519739, 37.216713],
							[-24.522095, 37.222101],
							[-25.040034, 38.048743],
							[-25.044079, 38.053405],
							[-25.049352, 38.056611],
							[-25.055352, 38.058058],
							[-25.853534, 38.123336],
							[-25.859692, 38.122884],
							[-25.865419, 38.120574],
							[-25.870168, 38.116627],
							[-26.112192, 37.842038],
							[-26.115763, 37.836226],
							[-26.117174, 37.829552],
						],
					],
					[
						[
							[-18.279354, 27.739264],
							[-18.278086, 27.732669],
							[-18.274707, 27.726865],
							[-18.103459, 27.521884],
							[-18.098349, 27.517526],
							[-18.092084, 27.515106],
							[-18.085372, 27.514895],
							[-17.842226, 27.548506],
							[-17.835546, 27.550674],
							[-17.830027, 27.555018],
							[-17.826351, 27.561002],
							[-17.754453, 27.743941],
							[-17.753102, 27.750065],
							[-17.753715, 27.756307],
							[-17.75623, 27.762052],
							[-17.894797, 27.978172],
							[-17.898987, 27.982871],
							[-17.904429, 27.986034],
							[-17.910586, 27.987349],
							[-17.916846, 27.986686],
							[-17.92259, 27.984109],
							[-18.270314, 27.75642],
							[-18.275236, 27.751849],
							[-18.278366, 27.745907],
							[-18.279354, 27.739264],
						],
					],
					[
						[
							[-18.25741, 28.758129],
							[-18.255838, 28.752102],
							[-18.080587, 28.350568],
							[-18.076941, 28.34499],
							[-18.071664, 28.340919],
							[-17.856812, 28.226407],
							[-17.850922, 28.224368],
							[-17.844689, 28.224242],
							[-17.838721, 28.22604],
							[-17.627663, 28.327773],
							[-17.62158, 28.332301],
							[-17.61762, 28.338768],
							[-17.472848, 28.724882],
							[-17.471634, 28.730365],
							[-17.471992, 28.73597],
							[-17.473895, 28.741254],
							[-17.611674, 29.00177],
							[-17.614961, 29.006307],
							[-17.619378, 29.009754],
							[-17.624577, 29.011841],
							[-17.898801, 29.079287],
							[-17.905237, 29.079797],
							[-17.911501, 29.07823],
							[-17.916938, 29.074749],
							[-18.250868, 28.774985],
							[-18.254799, 28.770154],
							[-18.257052, 28.764347],
							[-18.25741, 28.758129],
						],
					],
					[
						[
							[-17.597897, 28.098048],
							[-17.596216, 28.092273],
							[-17.592894, 28.08726],
							[-17.342798, 27.811199],
							[-17.338433, 27.807578],
							[-17.333227, 27.805328],
							[-17.327599, 27.80463],
							[-17.322001, 27.805541],
							[-16.935052, 27.926691],
							[-16.472573, 27.810609],
							[-16.465439, 27.810136],
							[-16.458595, 27.812202],
							[-16.452914, 27.816544],
							[-15.913462, 28.409104],
							[-15.909947, 28.414508],
							[-15.908334, 28.420749],
							[-15.888756, 28.635127],
							[-15.88934, 28.642066],
							[-15.892266, 28.648384],
							[-15.897182, 28.653316],
							[-15.903491, 28.656263],
							[-16.393593, 28.787753],
							[-16.399779, 28.788411],
							[-16.405868, 28.787136],
							[-17.445842, 28.392721],
							[-17.452101, 28.388912],
							[-17.456568, 28.383103],
							[-17.59589, 28.10977],
							[-17.597785, 28.104062],
							[-17.597897, 28.098048],
						],
					],
					[
						[
							[-17.524552, 32.804429],
							[-17.522905, 32.798473],
							[-17.519518, 32.793305],
							[-17.243315, 32.487872],
							[-17.239578, 32.484648],
							[-17.235164, 32.482436],
							[-16.406232, 32.18854],
							[-16.400379, 32.187407],
							[-16.394452, 32.18805],
							[-16.388978, 32.190412],
							[-16.384444, 32.194282],
							[-16.211655, 32.393387],
							[-16.208523, 32.398287],
							[-16.206932, 32.40388],
							[-16.207018, 32.409695],
							[-16.208773, 32.415239],
							[-16.387326, 32.78258],
							[-16.085551, 32.90096],
							[-16.079825, 32.904406],
							[-16.075553, 32.909545],
							[-16.073214, 32.915804],
							[-16.025589, 33.163612],
							[-16.025409, 33.17006],
							[-16.027291, 33.176229],
							[-16.031038, 33.181479],
							[-16.036262, 33.185263],
							[-16.339486, 33.337366],
							[-16.34533, 33.339244],
							[-16.351468, 33.339261],
							[-16.357322, 33.337416],
							[-16.579227, 33.227634],
							[-16.58413, 33.224212],
							[-16.587802, 33.219493],
							[-16.589915, 33.213899],
							[-16.64152, 32.973085],
							[-17.213214, 33.096554],
							[-17.219629, 33.096884],
							[-17.225818, 33.095163],
							[-17.231142, 33.09157],
							[-17.51839, 32.821285],
							[-17.522182, 32.816406],
							[-17.524303, 32.810603],
							[-17.524552, 32.804429],
						],
					],
					[
						[
							[-16.30149, 30.020661],
							[-16.301466, 30.014354],
							[-16.299479, 30.008368],
							[-16.295727, 30.003299],
							[-16.290582, 29.99965],
							[-15.941429, 29.827254],
							[-15.935164, 29.825355],
							[-15.928621, 29.825581],
							[-15.922503, 29.827908],
							[-15.917463, 29.832085],
							[-15.615803, 30.180016],
							[-15.612585, 30.185115],
							[-15.611033, 30.190942],
							[-15.611288, 30.196966],
							[-15.613326, 30.20264],
							[-15.616963, 30.207449],
							[-15.621868, 30.210955],
							[-15.936111, 30.370317],
							[-15.942044, 30.372236],
							[-15.948279, 30.372235],
							[-15.954211, 30.370313],
							[-15.959263, 30.366657],
							[-16.295835, 30.03176],
							[-16.299548, 30.026662],
							[-16.30149, 30.020661],
						],
					],
					[
						[
							[-16.084102, 27.909812],
							[-16.082956, 27.903938],
							[-16.080122, 27.898666],
							[-15.836089, 27.573154],
							[-15.832375, 27.569371],
							[-15.827797, 27.566697],
							[-15.822677, 27.56532],
							[-15.497861, 27.522891],
							[-15.490267, 27.523359],
							[-15.483398, 27.526628],
							[-15.15444, 27.769306],
							[-15.149984, 27.773853],
							[-15.147182, 27.779569],
							[-15.146319, 27.785876],
							[-15.15665, 28.220418],
							[-15.157577, 28.225981],
							[-15.160021, 28.231064],
							[-15.163787, 28.235262],
							[-15.350508, 28.391975],
							[-15.357004, 28.395616],
							[-15.364381, 28.396629],
							[-15.787568, 28.375124],
							[-15.793811, 28.373786],
							[-15.799315, 28.370549],
							[-15.803517, 28.365743],
							[-16.081084, 27.921256],
							[-16.083459, 27.915762],
							[-16.084102, 27.909812],
						],
					],
					[
						[
							[-14.755383, 28.060386],
							[-14.754729, 28.053894],
							[-14.75202, 28.047958],
							[-14.634404, 27.873045],
							[-14.630695, 27.86891],
							[-14.625991, 27.865956],
							[-14.620656, 27.864409],
							[-14.315169, 27.820448],
							[-14.309098, 27.820505],
							[-14.303324, 27.822382],
							[-13.71859, 28.116884],
							[-13.713786, 28.12027],
							[-13.710174, 28.124907],
							[-13.088061, 29.225837],
							[-13.085913, 29.231507],
							[-13.085562, 29.23756],
							[-13.087042, 29.24344],
							[-13.35085, 29.869769],
							[-13.354263, 29.875213],
							[-13.359226, 29.879294],
							[-13.365227, 29.881591],
							[-13.371647, 29.881866],
							[-13.719638, 29.840435],
							[-13.725976, 29.838582],
							[-13.731366, 29.834766],
							[-13.735219, 29.829403],
							[-14.405943, 28.465997],
							[-14.750474, 28.072289],
							[-14.753912, 28.066743],
							[-14.755383, 28.060386],
						],
					],
					[
						[
							[-11.03355, 52.022919],
							[-11.032136, 52.016541],
							[-11.028741, 52.010959],
							[-10.527113, 51.427781],
							[-10.522221, 51.423662],
							[-10.516278, 51.421298],
							[-9.52804, 51.202274],
							[-9.520183, 51.202114],
							[-8.38216, 51.406123],
							[-8.377405, 51.407606],
							[-7.268793, 51.912123],
							[-6.062341, 52.002865],
							[-6.056844, 52.004072],
							[-6.051901, 52.006764],
							[-5.878401, 52.135877],
							[-5.874337, 52.139927],
							[-5.87159, 52.144965],
							[-5.870386, 52.150575],
							[-5.870825, 52.156296],
							[-5.87287, 52.161657],
							[-5.984875, 52.362653],
							[-5.65151, 52.926777],
							[-5.649432, 52.931692],
							[-5.648728, 52.936981],
							[-5.649727, 53.633087],
							[-5.650639, 53.63903],
							[-5.653276, 53.644433],
							[-5.6574, 53.648808],
							[-6.268143, 54.12681],
							[-6.273207, 54.129695],
							[-6.278888, 54.130998],
							[-6.284704, 54.130607],
							[-6.617066, 54.058621],
							[-6.964166, 54.422669],
							[-6.968609, 54.42617],
							[-6.973854, 54.428287],
							[-6.979482, 54.42885],
							[-6.985043, 54.427816],
							[-7.195483, 54.356713],
							[-7.200517, 54.354172],
							[-7.204643, 54.350327],
							[-7.207531, 54.345484],
							[-7.292359, 54.142718],
							[-7.542306, 54.142352],
							[-8.125226, 54.459644],
							[-7.691398, 54.590737],
							[-7.685833, 54.593415],
							[-7.68135, 54.597663],
							[-7.678377, 54.603075],
							[-7.677197, 54.609137],
							[-7.677923, 54.61527],
							[-7.680485, 54.620889],
							[-7.684639, 54.625459],
							[-7.689989, 54.628543],
							[-7.838876, 54.685942],
							[-7.540787, 54.722646],
							[-7.535046, 54.724248],
							[-7.530033, 54.72747],
							[-7.526191, 54.732026],
							[-7.344243, 55.028145],
							[-6.712352, 55.261276],
							[-6.706844, 55.264373],
							[-6.702576, 55.269033],
							[-6.699976, 55.274792],
							[-6.699302, 55.281075],
							[-6.700622, 55.287255],
							[-6.703803, 55.292714],
							[-6.936557, 55.576841],
							[-6.941335, 55.581068],
							[-6.947201, 55.583576],
							[-6.953558, 55.584108],
							[-8.41391, 55.472133],
							[-8.420554, 55.470445],
							[-8.426237, 55.466614],
							[-9.172624, 54.749531],
							[-9.176535, 54.744291],
							[-9.178548, 54.738071],
							[-9.178446, 54.731534],
							[-9.176242, 54.725379],
							[-9.061525, 54.519343],
							[-9.908578, 54.574154],
							[-9.914298, 54.573699],
							[-9.919654, 54.571638],
							[-10.643544, 54.165564],
							[-10.648128, 54.162033],
							[-10.651509, 54.157338],
							[-10.653404, 54.151871],
							[-10.653656, 54.14609],
							[-10.569921, 53.325859],
							[-10.56831, 53.319787],
							[-10.564895, 53.314515],
							[-10.560013, 53.310562],
							[-9.86134, 52.907845],
							[-11.025532, 52.040036],
							[-11.030062, 52.035329],
							[-11.032833, 52.029412],
							[-11.03355, 52.022919],
						],
					],
					[
						[
							[-9.83838, 39.464383],
							[-9.748593, 38.637424],
							[-9.747256, 38.632097],
							[-9.74452, 38.627334],
							[-9.171015, 37.887115],
							[-9.268788, 37.024023],
							[-9.268389, 37.017216],
							[-9.265727, 37.010938],
							[-9.074818, 36.714671],
							[-9.070216, 36.709664],
							[-9.064203, 36.706489],
							[-9.057474, 36.705512],
							[-7.851075, 36.737613],
							[-7.846771, 36.738199],
							[-7.013611, 36.945813],
							[-6.226256, 36.026919],
							[-6.221109, 36.022636],
							[-6.214837, 36.020291],
							[-5.67031, 35.915835],
							[-5.664965, 35.915539],
							[-5.659732, 35.916672],
							[-4.038932, 36.50365],
							[-2.09467, 36.507331],
							[-2.08717, 36.508805],
							[-2.080771, 36.512986],
							[-1.344033, 37.228741],
							[-0.460322, 37.494519],
							[-0.454951, 37.497056],
							[-0.450561, 37.501059],
							[-0.447541, 37.506174],
							[-0.161366, 38.213955],
							[0.471532, 38.611482],
							[0.476425, 38.615817],
							[0.479659, 38.621497],
							[0.480888, 38.627917],
							[0.486387, 38.847231],
							[0.485771, 38.852683],
							[0.483697, 38.857762],
							[0.48032, 38.862087],
							[0.067502, 39.262578],
							[0.074418, 39.572135],
							[0.35752, 39.893667],
							[0.540874, 39.624217],
							[0.544993, 39.619789],
							[0.550247, 39.616795],
							[0.556155, 39.615508],
							[0.562178, 39.616045],
							[0.973087, 39.716945],
							[0.978603, 39.719216],
							[0.983205, 39.723013],
							[0.986481, 39.727998],
							[0.988142, 39.733728],
							[0.988039, 39.739693],
							[0.932701, 40.067982],
							[0.930495, 40.074311],
							[0.926322, 40.079556],
							[0.920651, 40.083128],
							[0.914118, 40.084625],
							[0.537394, 40.106108],
							[0.628373, 40.213336],
							[1.387279, 40.903787],
							[2.239896, 41.088365],
							[2.245484, 41.090489],
							[3.446588, 41.767431],
							[3.452313, 41.77227],
							[3.455854, 41.778877],
							[3.624407, 42.317069],
							[3.625313, 42.323617],
							[3.624034, 42.330104],
							[3.620711, 42.335819],
							[3.336466, 42.67831],
							[3.430865, 43.039424],
							[4.00481, 43.281987],
							[6.477042, 42.778432],
							[6.482445, 42.778079],
							[6.487744, 42.779189],
							[6.492552, 42.781679],
							[7.511984, 43.499833],
							[7.516447, 43.504159],
							[7.519367, 43.509645],
							[7.520462, 43.515763],
							[7.519633, 43.521862],
							[7.524518, 43.518418],
							[7.530999, 43.516585],
							[7.537721, 43.517007],
							[8.07257, 43.643286],
							[8.078851, 43.645967],
							[8.877101, 44.163285],
							[9.898625, 43.701732],
							[9.645672, 43.556559],
							[9.639848, 43.551502],
							[9.636371, 43.544616],
							[9.544068, 43.215679],
							[9.160708, 43.173267],
							[9.155169, 43.171831],
							[9.150263, 43.168884],
							[8.256728, 42.439826],
							[8.252191, 42.434569],
							[8.249726, 42.428078],
							[8.249629, 42.421135],
							[8.371575, 41.66773],
							[8.373613, 41.661623],
							[8.377485, 41.65648],
							[8.75377, 41.296163],
							[8.242032, 41.35008],
							[8.236124, 41.349823],
							[8.230548, 41.347849],
							[8.225794, 41.344331],
							[7.88566, 41.004176],
							[7.881709, 40.998556],
							[7.879893, 40.99193],
							[7.83989, 40.571896],
							[7.840671, 40.564161],
							[7.844344, 40.557308],
							[8.068472, 40.284369],
							[7.990109, 39.8435],
							[7.989949, 39.837561],
							[7.991541, 39.831838],
							[7.994745, 39.826834],
							[8.12696, 39.675644],
							[7.920995, 39.106809],
							[7.919811, 39.100667],
							[7.920582, 39.09446],
							[7.923233, 39.088795],
							[8.153271, 38.748679],
							[8.157002, 38.744545],
							[8.161729, 38.741601],
							[8.167085, 38.740074],
							[8.8873, 38.639997],
							[8.89229, 38.639933],
							[8.897141, 38.641105],
							[9.847271, 39.001217],
							[9.853155, 39.004696],
							[9.857524, 39.009954],
							[9.859866, 39.016376],
							[10.129884, 40.516452],
							[10.129951, 40.523138],
							[10.059911, 40.963263],
							[10.058383, 40.968362],
							[10.055563, 40.972877],
							[9.642983, 41.47104],
							[9.881517, 42.201917],
							[11.59715, 41.902337],
							[12.361206, 41.354858],
							[12.670277, 40.746351],
							[12.673632, 40.741609],
							[12.678208, 40.738031],
							[12.683619, 40.735919],
							[13.776855, 40.484089],
							[15.522981, 39.679951],
							[15.808359, 39.041086],
							[15.513295, 38.523727],
							[15.313008, 38.501903],
							[15.528547, 38.831994],
							[15.531167, 38.837935],
							[15.531746, 38.844403],
							[15.530223, 38.850715],
							[15.526758, 38.856206],
							[15.521716, 38.860298],
							[15.212767, 39.03667],
							[15.207497, 39.038753],
							[15.201855, 39.039276],
							[15.196292, 39.038194],
							[15.191256, 39.035596],
							[14.867722, 38.805364],
							[14.08991, 38.632828],
							[14.084343, 38.630681],
							[14.079656, 38.626987],
							[14.076268, 38.622076],
							[14.07448, 38.616383],
							[14.074451, 38.610416],
							[14.076183, 38.604706],
							[14.198624, 38.347511],
							[14.201758, 38.342793],
							[14.206089, 38.339143],
							[14.211269, 38.336854],
							[14.216884, 38.336108],
							[14.432557, 38.338286],
							[14.020411, 38.267845],
							[13.35292, 38.444411],
							[13.346712, 38.445046],
							[12.690131, 38.409094],
							[12.683597, 38.407612],
							[11.768039, 38.029902],
							[11.762987, 38.026881],
							[11.759033, 38.022519],
							[11.75652, 38.017194],
							[11.755667, 38.011369],
							[11.756546, 38.005548],
							[11.759083, 38.000235],
							[11.763056, 37.995891],
							[12.539735, 37.364946],
							[12.544538, 37.362056],
							[14.393175, 36.578229],
							[14.396995, 36.577044],
							[15.137548, 36.426376],
							[15.144559, 36.426204],
							[15.151198, 36.428464],
							[15.15665, 36.432876],
							[15.601136, 36.94579],
							[15.604458, 36.951137],
							[15.605954, 36.957251],
							[15.605476, 36.963528],
							[15.461504, 37.567195],
							[15.559442, 37.757867],
							[16.243793, 37.758349],
							[16.251108, 37.75974],
							[16.257406, 37.76371],
							[17.408108, 38.834881],
							[17.412624, 38.841105],
							[17.414458, 38.848573],
							[17.433377, 39.247457],
							[18.552401, 39.623518],
							[18.557394, 39.626018],
							[18.561501, 39.629801],
							[18.564402, 39.634572],
							[18.794653, 40.169719],
							[18.796153, 40.17536],
							[18.79596, 40.181195],
							[18.794091, 40.186725],
							[18.790704, 40.19148],
							[18.174983, 40.83237],
							[18.167989, 40.837083],
							[16.5559, 41.482053],
							[16.444549, 41.989786],
							[16.442253, 41.99564],
							[16.438253, 42.000492],
							[16.432944, 42.003862],
							[16.02251, 42.181144],
							[15.930699, 42.392544],
							[15.927077, 42.398114],
							[15.921831, 42.40219],
							[15.915539, 42.404322],
							[15.908896, 42.404276],
							[15.060271, 42.255286],
							[14.40619, 42.644133],
							[13.863817, 43.682917],
							[13.860319, 43.687713],
							[13.855567, 43.691272],
							[12.644782, 44.342979],
							[12.588151, 44.570373],
							[12.839843, 44.897646],
							[12.842642, 44.902623],
							[12.843921, 44.908188],
							[12.87934, 45.336154],
							[13.196702, 45.42802],
							[13.332692, 44.958356],
							[13.334567, 44.953943],
							[13.33744, 44.950104],
							[13.686431, 44.584722],
							[13.689539, 44.582072],
							[15.051159, 43.643026],
							[15.054303, 43.641253],
							[15.77317, 43.317576],
							[15.339822, 43.29294],
							[15.334128, 43.29177],
							[15.329011, 43.289013],
							[15.324903, 43.2849],
							[15.171513, 43.078442],
							[15.168435, 43.072339],
							[15.167591, 43.065556],
							[15.16908, 43.058886],
							[15.172728, 43.053106],
							[15.178109, 43.048892],
							[15.627383, 42.80776],
							[15.631782, 42.806033],
							[15.636464, 42.805386],
							[16.220181, 42.794363],
							[16.310782, 42.593284],
							[15.979791, 42.44377],
							[15.974307, 42.440098],
							[15.970316, 42.43484],
							[15.968255, 42.42857],
							[15.968346, 42.42197],
							[15.970581, 42.41576],
							[16.083539, 42.21436],
							[16.08692, 42.209922],
							[16.091396, 42.20659],
							[16.096618, 42.204625],
							[16.102179, 42.204179],
							[16.548877, 42.230961],
							[16.554514, 42.232129],
							[16.559584, 42.234854],
							[16.563668, 42.23891],
							[16.566428, 42.243961],
							[16.567635, 42.249589],
							[16.567189, 42.255328],
							[16.505387, 42.529145],
							[17.280851, 42.551322],
							[18.413529, 42.190346],
							[18.419889, 42.189403],
							[18.42622, 42.190528],
							[18.431867, 42.193604],
							[18.436246, 42.198313],
							[18.556664, 42.379074],
							[18.559083, 42.384114],
							[18.560012, 42.389628],
							[18.559379, 42.395183],
							[18.557232, 42.400346],
							[18.454879, 42.573353],
							[18.45073, 42.578313],
							[18.445215, 42.58169],
							[17.604262, 42.924434],
							[17.717375, 42.953763],
							[17.723527, 42.956533],
							[17.728407, 42.961192],
							[17.731461, 42.967208],
							[17.73234, 42.973897],
							[17.730946, 42.980498],
							[17.727435, 42.98626],
							[17.292868, 43.485074],
							[17.289185, 43.488372],
							[16.220567, 44.229341],
							[16.07371, 44.632597],
							[16.070211, 44.638641],
							[16.064848, 44.643113],
							[15.744417, 44.826399],
							[15.783168, 45.150676],
							[15.979883, 45.207358],
							[16.336568, 44.983808],
							[16.341988, 44.981443],
							[16.347862, 44.980766],
							[16.353678, 44.981836],
							[16.358926, 44.98456],
							[16.363148, 44.9887],
							[16.52403, 45.20169],
							[16.930879, 45.255803],
							[17.84658, 45.024696],
							[17.852705, 45.024126],
							[18.661099, 45.073957],
							[18.749946, 44.893331],
							[18.753486, 44.888285],
							[18.758395, 44.884557],
							[18.764207, 44.882501],
							[19.015488, 44.835395],
							[19.020823, 44.835121],
							[19.02604, 44.836268],
							[19.030768, 44.838756],
							[19.168277, 44.936597],
							[19.172876, 44.941157],
							[19.175778, 44.946947],
							[19.176676, 44.953361],
							[19.175479, 44.959725],
							[19.114031, 45.128779],
							[19.450259, 45.176105],
							[19.45652, 45.178074],
							[19.461807, 45.181964],
							[19.46555, 45.187356],
							[19.467345, 45.19367],
							[19.467, 45.200224],
							[19.464552, 45.206314],
							[19.460263, 45.211284],
							[19.454597, 45.214597],
							[19.006843, 45.385345],
							[19.11587, 45.500586],
							[19.119219, 45.505364],
							[19.121046, 45.510906],
							[19.121196, 45.516739],
							[19.119656, 45.522367],
							[19.116557, 45.527311],
							[19.112163, 45.531151],
							[19.106848, 45.533558],
							[18.918144, 45.587597],
							[18.911225, 45.909539],
							[19.571267, 46.158501],
							[20.096, 46.156785],
							[20.800764, 45.764163],
							[20.751724, 45.483024],
							[20.751698, 45.476306],
							[20.753897, 45.469959],
							[20.758074, 45.464697],
							[20.763758, 45.461116],
							[21.479107, 45.164155],
							[21.346501, 45.033943],
							[21.342898, 45.029142],
							[21.340881, 45.023488],
							[21.340633, 45.017491],
							[21.342176, 45.01169],
							[21.34537, 45.006608],
							[21.349929, 45.002703],
							[21.521852, 44.895459],
							[21.379353, 44.798183],
							[21.374881, 44.793993],
							[21.371888, 44.788647],
							[21.370653, 44.782645],
							[21.371294, 44.776551],
							[21.37375, 44.770937],
							[21.37779, 44.76633],
							[21.383035, 44.763162],
							[22.132234, 44.455641],
							[22.138995, 44.45416],
							[22.145856, 44.455072],
							[22.151994, 44.458268],
							[22.456706, 44.69179],
							[22.702991, 44.567152],
							[22.453031, 44.495489],
							[22.447603, 44.493006],
							[22.443149, 44.489032],
							[22.440066, 44.483921],
							[22.43863, 44.478127],
							[22.438967, 44.472168],
							[22.441047, 44.466573],
							[22.444686, 44.461841],
							[22.658819, 44.256109],
							[22.340814, 43.821268],
							[22.338194, 43.816384],
							[22.337015, 43.810968],
							[22.337367, 43.805437],
							[22.339224, 43.800215],
							[22.51567, 43.461804],
							[22.518842, 43.457342],
							[22.523104, 43.453907],
							[22.975334, 43.182221],
							[22.733925, 42.904573],
							[22.436007, 42.843782],
							[22.429692, 42.841321],
							[22.424561, 42.836892],
							[22.421204, 42.831004],
							[22.420007, 42.824333],
							[22.421106, 42.817645],
							[22.535608, 42.48675],
							[22.346701, 42.327008],
							[22.342551, 42.322169],
							[22.340135, 42.31627],
							[22.339698, 42.30991],
							[22.341285, 42.303736],
							[22.344733, 42.298374],
							[22.349694, 42.29437],
							[22.852043, 42.007393],
							[23.012868, 41.706282],
							[22.948329, 41.371259],
							[22.761303, 41.34202],
							[22.755514, 41.340181],
							[22.750543, 41.336689],
							[22.74685, 41.331866],
							[22.744776, 41.326157],
							[22.712886, 41.165614],
							[22.062707, 41.180907],
							[22.056927, 41.180195],
							[22.051597, 41.177848],
							[21.587636, 40.886358],
							[20.979589, 40.875282],
							[20.973817, 40.874321],
							[20.96857, 40.87173],
							[20.964298, 40.867732],
							[20.961366, 40.862668],
							[20.960025, 40.856972],
							[20.96039, 40.851132],
							[21.020203, 40.569439],
							[20.778023, 40.446824],
							[20.773711, 40.443876],
							[20.770309, 40.439912],
							[20.768049, 40.435202],
							[20.662246, 40.111921],
							[20.306165, 40.010526],
							[20.300666, 40.00801],
							[20.29617, 40.003964],
							[20.293089, 39.99876],
							[20.291705, 39.992873],
							[20.292143, 39.986842],
							[20.294364, 39.981217],
							[20.388718, 39.819392],
							[20.218378, 39.668077],
							[19.998231, 39.670282],
							[19.980284, 39.826173],
							[19.978478, 39.832471],
							[19.974734, 39.837848],
							[19.969454, 39.841726],
							[19.625687, 40.015895],
							[19.620542, 40.017672],
							[19.615107, 40.017995],
							[19.609787, 40.016841],
							[19.240727, 39.882056],
							[19.235579, 39.879263],
							[19.231461, 39.875099],
							[19.228726, 39.86992],
							[19.227608, 39.86417],
							[19.228204, 39.858344],
							[19.230462, 39.85294],
							[19.234189, 39.848422],
							[20.119206, 39.049779],
							[20.124857, 39.046189],
							[20.131375, 39.044665],
							[20.138032, 39.045377],
							[20.390519, 39.116556],
							[20.545372, 39.001981],
							[20.18908, 38.211901],
							[20.18749, 38.206342],
							[20.187556, 38.200561],
							[20.189273, 38.195041],
							[20.192498, 38.190242],
							[20.196961, 38.186566],
							[20.546645, 37.975054],
							[20.493523, 37.765253],
							[20.492937, 37.759322],
							[20.494125, 37.753482],
							[20.496981, 37.748252],
							[20.501251, 37.744094],
							[20.80479, 37.52628],
							[20.809665, 37.523716],
							[20.815054, 37.522578],
							[20.820549, 37.522954],
							[20.825734, 37.524814],
							[21.158328, 37.6991],
							[21.473111, 37.444744],
							[21.545081, 37.329239],
							[21.3942, 37.04873],
							[21.392069, 37.042444],
							[21.392113, 37.035806],
							[21.394328, 37.029548],
							[21.609808, 36.641452],
							[21.613383, 36.63679],
							[21.61816, 36.633368],
							[21.623725, 36.631481],
							[21.930093, 36.575927],
							[21.936177, 36.575765],
							[21.942028, 36.57744],
							[21.947104, 36.580798],
							[22.139494, 36.755432],
							[22.234649, 36.432826],
							[22.23779, 36.426541],
							[22.242911, 36.42173],
							[22.476521, 36.269455],
							[22.482695, 36.266782],
							[22.489407, 36.266307],
							[22.495896, 36.268085],
							[22.501429, 36.271914],
							[22.505378, 36.277361],
							[22.674037, 36.619193],
							[22.815967, 36.097434],
							[22.819249, 36.090706],
							[22.824774, 36.085656],
							[23.444989, 35.703526],
							[23.337004, 35.58914],
							[23.333711, 35.584459],
							[23.331879, 35.579038],
							[23.331657, 35.573319],
							[23.333064, 35.567771],
							[23.512688, 35.133191],
							[23.515468, 35.128445],
							[23.519436, 35.124636],
							[23.524291, 35.122051],
							[23.529666, 35.120887],
							[24.331176, 35.06039],
							[24.702769, 34.803179],
							[24.708949, 34.800313],
							[24.715733, 34.799687],
							[25.552205, 34.866007],
							[25.710871, 34.748288],
							[25.716597, 34.745333],
							[25.722965, 34.744351],
							[25.729315, 34.745445],
							[25.734987, 34.748502],
							[25.93962, 34.90602],
							[26.15883, 34.801025],
							[26.164602, 34.79927],
							[26.170635, 34.799315],
							[26.17638, 34.801158],
							[26.181314, 34.80463],
							[26.184989, 34.809416],
							[26.464744, 35.317447],
							[26.703395, 35.411931],
							[26.881749, 35.224447],
							[26.886409, 35.220815],
							[26.891928, 35.218702],
							[26.897823, 35.218295],
							[26.903579, 35.219628],
							[27.333969, 35.389427],
							[27.339016, 35.392329],
							[27.343013, 35.396561],
							[27.345622, 35.401766],
							[27.346622, 35.407502],
							[27.360934, 35.947936],
							[27.580478, 36.046155],
							[27.628905, 35.814309],
							[27.631123, 35.808465],
							[27.635038, 35.803591],
							[27.640266, 35.800164],
							[27.646296, 35.798518],
							[27.846447, 35.776511],
							[27.852747, 35.776818],
							[27.858637, 35.779073],
							[28.198609, 35.97547],
							[28.203188, 35.979102],
							[28.206522, 35.983902],
							[28.382966, 36.339675],
							[28.384785, 36.345325],
							[28.384866, 36.351259],
							[28.383202, 36.356956],
							[28.287642, 36.563579],
							[28.283644, 36.569312],
							[28.277918, 36.57332],
							[28.271162, 36.575113],
							[28.264202, 36.574472],
							[27.974568, 36.49508],
							[27.914745, 36.703005],
							[27.911917, 36.708934],
							[27.907321, 36.713626],
							[27.901452, 36.716577],
							[27.894944, 36.717467],
							[27.888499, 36.7162],
							[27.486064, 36.565206],
							[27.323067, 36.643934],
							[27.412762, 36.898524],
							[27.413889, 36.904548],
							[27.413139, 36.910631],
							[27.410582, 36.9162],
							[27.406458, 36.920734],
							[27.401156, 36.923807],
							[27.172801, 37.012729],
							[26.982094, 37.641139],
							[27.172146, 37.774263],
							[27.176464, 37.778371],
							[27.179379, 37.78357],
							[27.180633, 37.789397],
							[27.180114, 37.795334],
							[27.177869, 37.800855],
							[27.174096, 37.805469],
							[27.169131, 37.808767],
							[26.221785, 38.250982],
							[26.339117, 38.489611],
							[26.340934, 38.495375],
							[26.340946, 38.501417],
							[26.339153, 38.507188],
							[26.254183, 38.681795],
							[26.624296, 38.789798],
							[26.629345, 38.79207],
							[26.633577, 38.795638],
							[26.636669, 38.800231],
							[26.638384, 38.805495],
							[26.694048, 39.118383],
							[26.694252, 39.123935],
							[26.692921, 39.129328],
							[26.690158, 39.134148],
							[26.44765, 39.446662],
							[26.443075, 39.450953],
							[26.437396, 39.453616],
							[26.431172, 39.454389],
							[25.972645, 39.438852],
							[25.911625, 39.893272],
							[25.909693, 39.899552],
							[25.905829, 39.904868],
							[25.656369, 40.150272],
							[26.059085, 40.353446],
							[26.064531, 40.357479],
							[26.068318, 40.3631],
							[26.070009, 40.369662],
							[26.069413, 40.376413],
							[25.978831, 40.71909],
							[26.368606, 40.927517],
							[26.373375, 40.931069],
							[26.376888, 40.935866],
							[26.378836, 40.941484],
							[26.379046, 40.947427],
							[26.345639, 41.239495],
							[26.63257, 41.326795],
							[26.637889, 41.329319],
							[26.642242, 41.333282],
							[26.645253, 41.338341],
							[26.64666, 41.344058],
							[26.646342, 41.349937],
							[26.644326, 41.355468],
							[26.398184, 41.809023],
							[27.237424, 42.082888],
							[27.549385, 41.887967],
							[27.555433, 41.885453],
							[27.56197, 41.885027],
							[28.325985, 41.961319],
							[28.331809, 41.962809],
							[28.336927, 41.965961],
							[28.340877, 41.970492],
							[28.343303, 41.975992],
							[28.343984, 41.981965],
							[28.34286, 41.98787],
							[28.340033, 41.993174],
							[28.094284, 42.322811],
							[28.183021, 42.976042],
							[28.299008, 43.16416],
							[28.588249, 43.161184],
							[28.59553, 43.162476],
							[28.601844, 43.166326],
							[28.844993, 43.385444],
							[28.848286, 43.389269],
							[28.850517, 43.393796],
							[29.239107, 44.523551],
							[29.73234, 44.615868],
							[29.739483, 44.618708],
							[29.745027, 44.624032],
							[30.061962, 45.075271],
							[30.064814, 45.081229],
							[30.06557, 45.087791],
							[30.064147, 45.094242],
							[30.0607, 45.099876],
							[30.055606, 45.104081],
							[29.439758, 45.460098],
							[29.434714, 45.462157],
							[29.429301, 45.462778],
							[29.423922, 45.461916],
							[28.712553, 45.245263],
							[28.203627, 45.488474],
							[28.106085, 46.00745],
							[28.271283, 46.614437],
							[28.271933, 46.621123],
							[28.270333, 46.627647],
							[28.114605, 46.98673],
							[28.111654, 46.991537],
							[28.107478, 46.995328],
							[27.595816, 47.342144],
							[27.183369, 48.005903],
							[27.178146, 48.011522],
							[26.822316, 48.270328],
							[26.817028, 48.273077],
							[26.811165, 48.274145],
							[26.805248, 48.273438],
							[24.890422, 47.746744],
							[24.582104, 47.985117],
							[24.576326, 47.988224],
							[24.569854, 47.989295],
							[23.499371, 47.988407],
							[23.175281, 48.13832],
							[23.169011, 48.140055],
							[23.162516, 48.139685],
							[23.156483, 48.13725],
							[22.904305, 47.983693],
							[22.842612, 48.125679],
							[22.839424, 48.130759],
							[22.834873, 48.134666],
							[22.829368, 48.137048],
							[22.823405, 48.13769],
							[22.596374, 48.127871],
							[22.164437, 48.424406],
							[22.562902, 49.056806],
							[22.887598, 48.988194],
							[22.893925, 48.987883],
							[22.900032, 48.989565],
							[22.905307, 48.993074],
							[22.90922, 48.998055],
							[22.911378, 49.004011],
							[22.911566, 49.010343],
							[22.909764, 49.016416],
							[22.666447, 49.523363],
							[24.103108, 50.545687],
							[24.107344, 50.549761],
							[24.110213, 50.554891],
							[24.111467, 50.560633],
							[24.110997, 50.566491],
							[24.108845, 50.57196],
							[23.986513, 50.784458],
							[24.153177, 50.850812],
							[24.158256, 50.853763],
							[24.162259, 50.858062],
							[24.16484, 50.863338],
							[24.165777, 50.869137],
							[24.164989, 50.874958],
							[24.162544, 50.880299],
							[24.158652, 50.8847],
							[23.665684, 51.299315],
							[23.561297, 51.602969],
							[23.6734, 52.067917],
							[23.673834, 52.074818],
							[23.671893, 52.081455],
							[23.667807, 52.087033],
							[23.662066, 52.090887],
							[23.223258, 52.285531],
							[23.948667, 52.695434],
							[23.953033, 52.698768],
							[23.956328, 52.703165],
							[23.958302, 52.708291],
							[23.958807, 52.713762],
							[23.938421, 53.158618],
							[23.936294, 53.166721],
							[23.548675, 53.934],
							[24.435977, 53.880929],
							[24.444366, 53.882232],
							[25.493292, 54.286686],
							[25.562585, 54.228802],
							[25.517657, 54.167709],
							[25.514784, 54.16215],
							[25.513769, 54.155975],
							[25.514713, 54.149789],
							[25.517522, 54.144197],
							[25.521921, 54.139747],
							[25.527481, 54.136875],
							[25.533656, 54.135861],
							[25.785343, 54.134433],
							[25.79133, 54.135315],
							[25.796779, 54.137946],
							[25.801191, 54.142087],
							[25.804164, 54.147359],
							[25.805423, 54.153277],
							[25.804855, 54.159302],
							[25.80251, 54.164881],
							[25.701467, 54.32981],
							[25.697738, 54.334276],
							[25.692882, 54.33748],
							[25.687309, 54.339151],
							[25.574009, 54.355735],
							[25.777069, 54.562814],
							[25.78107, 54.568705],
							[25.782754, 54.575624],
							[25.798579, 54.840395],
							[26.843199, 55.26688],
							[26.848709, 55.270258],
							[26.852855, 55.275217],
							[26.855202, 55.281239],
							[26.855507, 55.287695],
							[26.853736, 55.293911],
							[26.850075, 55.299238],
							[26.844907, 55.30312],
							[26.838771, 55.30515],
							[26.472041, 55.363294],
							[26.658286, 55.687388],
							[27.134655, 55.830995],
							[27.612897, 55.767052],
							[27.618179, 55.767049],
							[27.623277, 55.76843],
							[27.627836, 55.771096],
							[28.252639, 56.257688],
							[28.257195, 56.262686],
							[28.259826, 56.268916],
							[28.260229, 56.275667],
							[28.258359, 56.282167],
							[27.986163, 56.845663],
							[27.983029, 56.850333],
							[27.978719, 56.853946],
							[27.973573, 56.856216],
							[27.967999, 56.856963],
							[27.69056, 56.854817],
							[27.885711, 57.28913],
							[27.887327, 57.294958],
							[27.887128, 57.301002],
							[27.88513, 57.30671],
							[27.881518, 57.311561],
							[27.876621, 57.31511],
							[27.359086, 57.581488],
							[27.82974, 57.873551],
							[27.834272, 57.877405],
							[27.83747, 57.882422],
							[27.839051, 57.888158],
							[27.838875, 57.894105],
							[27.836957, 57.899737],
							[27.833467, 57.904555],
							[27.509339, 58.234754],
							[27.380196, 58.778545],
							[28.220224, 59.367467],
							[28.224981, 59.372168],
							[28.227923, 59.378175],
							[28.228719, 59.384816],
							[28.227281, 59.391348],
							[28.22377, 59.397041],
							[28.218578, 59.401258],
							[27.867101, 59.599762],
							[27.858316, 59.60232],
							[26.921436, 59.651556],
							[26.450993, 59.955374],
							[26.444451, 59.958104],
							[26.437368, 59.95838],
							[23.188838, 59.503332],
							[23.184724, 59.502302],
							[21.644663, 58.937273],
							[21.639916, 58.934764],
							[21.636008, 58.931082],
							[21.63322, 58.926493],
							[21.364078, 58.309466],
							[21.362584, 58.304102],
							[21.362627, 58.298535],
							[21.364203, 58.293194],
							[21.36719, 58.288495],
							[21.806475, 57.773178],
							[21.3577, 57.632902],
							[21.351159, 57.629418],
							[21.346335, 57.623792],
							[21.049125, 57.107607],
							[20.700617, 56.864856],
							[20.696185, 56.860626],
							[20.693242, 56.855254],
							[20.692064, 56.849242],
							[20.633608, 55.384737],
							[20.634491, 55.378011],
							[20.637572, 55.371967],
							[20.642495, 55.367301],
							[20.648696, 55.364548],
							[22.070882, 55.00541],
							[22.077536, 55.004879],
							[22.582423, 55.049401],
							[22.858009, 54.81213],
							[22.66872, 54.599313],
							[22.665504, 54.594399],
							[22.663853, 54.588763],
							[22.663911, 54.58289],
							[22.665672, 54.577287],
							[22.759275, 54.384481],
							[19.653069, 54.473318],
							[18.852805, 54.953993],
							[18.844511, 54.956747],
							[17.934369, 55.048406],
							[17.927668, 55.047948],
							[14.213648, 54.15064],
							[14.016344, 54.656366],
							[14.012467, 54.662598],
							[14.006603, 54.667012],
							[13.554533, 54.891385],
							[13.54863, 54.893245],
							[13.54244, 54.893212],
							[12.745541, 54.763948],
							[12.738273, 54.761247],
							[12.158501, 54.404994],
							[11.670086, 54.351586],
							[11.149862, 54.595373],
							[11.144411, 54.597031],
							[11.138715, 54.597085],
							[10.652927, 54.53188],
							[10.352425, 54.610503],
							[10.251544, 54.79934],
							[10.659738, 54.557053],
							[10.665294, 54.5548],
							[10.671268, 54.554295],
							[10.677123, 54.555584],
							[10.682334, 54.55855],
							[10.904675, 54.733963],
							[12.140533, 54.448648],
							[12.146777, 54.448212],
							[12.15285, 54.449727],
							[12.158158, 54.453045],
							[12.162179, 54.457841],
							[12.253888, 54.610596],
							[12.899189, 54.882288],
							[12.904263, 54.885383],
							[12.908204, 54.889833],
							[12.910663, 54.895244],
							[12.911424, 54.901139],
							[12.910417, 54.906998],
							[12.907734, 54.912301],
							[12.669008, 55.248449],
							[12.865014, 55.118136],
							[12.870639, 55.115547],
							[12.876787, 55.114803],
							[14.290159, 55.164322],
							[14.295751, 55.165325],
							[14.300836, 55.16786],
							[14.709976, 55.450826],
							[14.714692, 55.4554],
							[14.717672, 55.461255],
							[14.718594, 55.46776],
							[14.717358, 55.474213],
							[14.618431, 55.741685],
							[15.153275, 55.877371],
							[15.78411, 55.730441],
							[15.789766, 55.729951],
							[15.795331, 55.73107],
							[16.726501, 56.061292],
							[16.732101, 56.064359],
							[16.736448, 56.069034],
							[17.526837, 57.252524],
							[17.529608, 57.258778],
							[17.530108, 57.265601],
							[17.52828, 57.272193],
							[17.266323, 57.825264],
							[17.590433, 58.380279],
							[18.855155, 58.787404],
							[18.858037, 58.788586],
							[19.822856, 59.275462],
							[19.829428, 59.28078],
							[20.029839, 59.52986],
							[20.033046, 59.535545],
							[20.034252, 59.54196],
							[20.033328, 59.548422],
							[20.030372, 59.554242],
							[20.0257, 59.5588],
							[19.108524, 60.198661],
							[19.135091, 60.257473],
							[20.272767, 59.449576],
							[20.278482, 59.446762],
							[20.284792, 59.445887],
							[22.397009, 59.4929],
							[22.399345, 59.49309],
							[27.457903, 60.20353],
							[27.463624, 60.205234],
							[27.468588, 60.208549],
							[27.885299, 60.588062],
							[31.173307, 62.427881],
							[31.178308, 62.431846],
							[31.601657, 62.895303],
							[31.60512, 62.900564],
							[31.606775, 62.906642],
							[31.606457, 62.912933],
							[31.604199, 62.918813],
							[31.600223, 62.923699],
							[31.253237, 63.234021],
							[31.247719, 63.237524],
							[30.016669, 63.76006],
							[30.563978, 64.084478],
							[30.568479, 64.088121],
							[30.571748, 64.0929],
							[30.573511, 64.098415],
							[30.573621, 64.104204],
							[30.572067, 64.109781],
							[30.500853, 64.270592],
							[30.497881, 64.275356],
							[30.493703, 64.279106],
							[30.488646, 64.281547],
							[30.06685, 64.416166],
							[30.106909, 64.772099],
							[30.106504, 64.778911],
							[30.103833, 64.785191],
							[30.099205, 64.790207],
							[30.093161, 64.793375],
							[29.632816, 64.94149],
							[29.643955, 65.042799],
							[29.900389, 65.085388],
							[29.906281, 65.087343],
							[29.91129, 65.091012],
							[29.914933, 65.09604],
							[29.916858, 65.101942],
							[29.91688, 65.108151],
							[29.914998, 65.114067],
							[29.911391, 65.119121],
							[29.906409, 65.122826],
							[29.63378, 65.265955],
							[29.879005, 65.547238],
							[29.882203, 65.552251],
							[29.883786, 65.557983],
							[29.883613, 65.563927],
							[29.8817, 65.569558],
							[29.878216, 65.574377],
							[29.87347, 65.577959],
							[29.775321, 65.631223],
							[30.125415, 65.732012],
							[30.130768, 65.734453],
							[30.135179, 65.738347],
							[30.138266, 65.743356],
							[30.139763, 65.749046],
							[30.139538, 65.754925],
							[30.137613, 65.760485],
							[29.941838, 66.135604],
							[29.937463, 66.141238],
							[29.064697, 66.92423],
							[30.029632, 67.657714],
							[30.034111, 67.662455],
							[30.036827, 67.668384],
							[30.037491, 67.674872],
							[30.036032, 67.681229],
							[30.032606, 67.686778],
							[30.027576, 67.69093],
							[29.337264, 68.091991],
							[29.330735, 68.094386],
							[28.660245, 68.214216],
							[28.460219, 68.536051],
							[28.815009, 68.854477],
							[28.819085, 68.859562],
							[28.82131, 68.865688],
							[28.821447, 68.872204],
							[28.819481, 68.878419],
							[28.815622, 68.883671],
							[28.81028, 68.887403],
							[28.80402, 68.88922],
							[28.522372, 68.922839],
							[28.934699, 69.032445],
							[28.940257, 69.034875],
							[28.944832, 69.038859],
							[28.948002, 69.044032],
							[28.949474, 69.049916],
							[28.949115, 69.055972],
							[28.946957, 69.061642],
							[28.859502, 69.215817],
							[29.345664, 69.460555],
							[29.350674, 69.46414],
							[29.354356, 69.469079],
							[29.35636, 69.474904],
							[29.356496, 69.481063],
							[29.354751, 69.486971],
							[29.351291, 69.492068],
							[29.148613, 69.709169],
							[29.143466, 69.713136],
							[29.13732, 69.715243],
							[28.411446, 69.837692],
							[27.969938, 70.109328],
							[27.963935, 70.111787],
							[27.957462, 70.112194],
							[26.484656, 69.964429],
							[26.478291, 69.962697],
							[25.96277, 69.725451],
							[25.957728, 69.722127],
							[25.953908, 69.71745],
							[25.684278, 69.260711],
							[25.682152, 69.255606],
							[25.681505, 69.250114],
							[25.682387, 69.244654],
							[25.750504, 69.023598],
							[24.900656, 68.576195],
							[23.87675, 68.856011],
							[23.871272, 68.856717],
							[23.86581, 68.855898],
							[23.165807, 68.649054],
							[22.382291, 68.73617],
							[21.639184, 69.292764],
							[21.634463, 69.295389],
							[21.629211, 69.296654],
							[21.280783, 69.331975],
							[21.272583, 69.331097],
							[20.996993, 69.241505],
							[20.991517, 69.238735],
							[20.987138, 69.234434],
							[20.98427, 69.229007],
							[20.983182, 69.222967],
							[20.983978, 69.216881],
							[21.030716, 69.056768],
							[20.060108, 69.065911],
							[20.054123, 69.065054],
							[20.048668, 69.062447],
							[20.044241, 69.05833],
							[20.041247, 69.053078],
							[20.039959, 69.047171],
							[20.040495, 69.041149],
							[20.042805, 69.035563],
							[20.046679, 69.030922],
							[20.302145, 68.805283],
							[19.926856, 68.575054],
							[19.922348, 68.571272],
							[19.919134, 68.566342],
							[19.917496, 68.56069],
							[19.917573, 68.554805],
							[19.919359, 68.549198],
							[19.9227, 68.544354],
							[19.927306, 68.540691],
							[19.932779, 68.538528],
							[20.163114, 68.484892],
							[19.918108, 68.376651],
							[18.127893, 68.556612],
							[18.1221, 68.55635],
							[18.116627, 68.554437],
							[18.111932, 68.551035],
							[18.108411, 68.546429],
							[18.106358, 68.541006],
							[18.105948, 68.535222],
							[18.130463, 68.207071],
							[17.89412, 67.991503],
							[17.28619, 68.138452],
							[17.280262, 68.138974],
							[17.274443, 68.137729],
							[16.730951, 67.933086],
							[16.726582, 67.93079],
							[16.722907, 67.927493],
							[16.397824, 67.553686],
							[16.156694, 67.539286],
							[16.150343, 67.537844],
							[16.144792, 67.534439],
							[16.140629, 67.52943],
							[16.138297, 67.523349],
							[16.138041, 67.516842],
							[16.13989, 67.510597],
							[16.361278, 67.053918],
							[15.367371, 66.501927],
							[15.362493, 66.498123],
							[15.35901, 66.493011],
							[15.357256, 66.487079],
							[15.357399, 66.480895],
							[15.359424, 66.47505],
							[15.453571, 66.298046],
							[14.513066, 66.152456],
							[14.507527, 66.150749],
							[14.50271, 66.147525],
							[14.49902, 66.143055],
							[14.496766, 66.137714],
							[14.496139, 66.131952],
							[14.49719, 66.126252],
							[14.60446, 65.810837],
							[14.488681, 65.32079],
							[13.641058, 64.595548],
							[13.637023, 64.590826],
							[13.634631, 64.585095],
							[13.634113, 64.578906],
							[13.635518, 64.572856],
							[13.638711, 64.567529],
							[13.643384, 64.563439],
							[13.649087, 64.560979],
							[14.096048, 64.446238],
							[14.135501, 64.202153],
							[13.960233, 64.02915],
							[13.213379, 64.115433],
							[13.206608, 64.115058],
							[12.679026, 63.993904],
							[12.671898, 63.9907],
							[12.138027, 63.610378],
							[12.133851, 63.606375],
							[12.130995, 63.601345],
							[12.129697, 63.595708],
							[12.130068, 63.589935],
							[12.132075, 63.584509],
							[12.187409, 63.483094],
							[11.961184, 63.284283],
							[11.957158, 63.279416],
							[11.95485, 63.273536],
							[11.95449, 63.26723],
							[11.956116, 63.261126],
							[11.959564, 63.255833],
							[12.194588, 62.996353],
							[12.037485, 62.619609],
							[12.035978, 62.61308],
							[12.036713, 62.606421],
							[12.039605, 62.600377],
							[12.277192, 62.26382],
							[12.118308, 61.729447],
							[12.117515, 61.722551],
							[12.119126, 61.715799],
							[12.122948, 61.710005],
							[12.12852, 61.705866],
							[12.841822, 61.34848],
							[12.669624, 61.078625],
							[12.221772, 61.033024],
							[12.215924, 61.031512],
							[12.210794, 61.028322],
							[12.20685, 61.023746],
							[12.204452, 61.018201],
							[12.20382, 61.012194],
							[12.20501, 61.006271],
							[12.207914, 61.000974],
							[12.585269, 60.507758],
							[12.52313, 60.202601],
							[12.330158, 59.983754],
							[11.834756, 59.86072],
							[11.828746, 59.858123],
							[11.823896, 59.853723],
							[11.820727, 59.847994],
							[11.819578, 59.841548],
							[11.820573, 59.835076],
							[11.823605, 59.829272],
							[11.913615, 59.709836],
							[11.69085, 59.627611],
							[11.685419, 59.624574],
							[11.681184, 59.620016],
							[11.678555, 59.614377],
							[11.677786, 59.608203],
							[11.678952, 59.602091],
							[11.807786, 59.24319],
							[11.639288, 58.925429],
							[11.467565, 58.910447],
							[11.357655, 59.124315],
							[11.354049, 59.129275],
							[11.349111, 59.132909],
							[11.343304, 59.134876],
							[11.337173, 59.134992],
							[11.331296, 59.133244],
							[10.584328, 58.778996],
							[10.579023, 58.775329],
							[10.575161, 58.770166],
							[10.573142, 58.764041],
							[10.573178, 58.757593],
							[10.575263, 58.751491],
							[11.142722, 57.690685],
							[11.146711, 57.685498],
							[12.273558, 56.633738],
							[12.070673, 56.314644],
							[12.068063, 56.308413],
							[12.067676, 56.301669],
							[12.069557, 56.295181],
							[12.073491, 56.289689],
							[12.079028, 56.28582],
							[12.151906, 56.251496],
							[11.519143, 56.406988],
							[11.512705, 56.407497],
							[11.506441, 56.405927],
							[11.501003, 56.402443],
							[10.74177, 55.720237],
							[10.76811, 55.827512],
							[11.281159, 56.296238],
							[11.284602, 56.300361],
							[11.286824, 56.305252],
							[11.287664, 56.310558],
							[11.29262, 56.532822],
							[11.91007, 56.581448],
							[11.916577, 56.583089],
							[11.922174, 56.586791],
							[11.926232, 56.592136],
							[11.928294, 56.598522],
							[11.928127, 56.605231],
							[11.92575, 56.611507],
							[11.729328, 56.9463],
							[11.725428, 56.951071],
							[11.720261, 56.954428],
							[11.714316, 56.956053],
							[11.708159, 56.955791],
							[11.219043, 56.858058],
							[11.212752, 56.855644],
							[11.20762, 56.851278],
							[11.20423, 56.845455],
							[11.202965, 56.838836],
							[11.198687, 56.619727],
							[10.73432, 56.752126],
							[10.773095, 57.02313],
							[11.154475, 56.993732],
							[11.15991, 56.994057],
							[11.165056, 56.995835],
							[11.516093, 57.173822],
							[11.521224, 57.177551],
							[11.52493, 57.182701],
							[11.526836, 57.188751],
							[11.526752, 57.195094],
							[11.524685, 57.201092],
							[11.520844, 57.20614],
							[10.730498, 57.959093],
							[10.725313, 57.962664],
							[10.719275, 57.964446],
							[10.712982, 57.964263],
							[9.736952, 57.779491],
							[9.732268, 57.777989],
							[9.728092, 57.775388],
							[9.227569, 57.370399],
							[8.366812, 57.306866],
							[8.360963, 57.305532],
							[8.355773, 57.302523],
							[7.914674, 56.94885],
							[7.909063, 56.941706],
							[7.732062, 56.562514],
							[7.730195, 56.554693],
							[7.69767, 55.536416],
							[7.69849, 55.530073],
							[7.701271, 55.524314],
							[7.705728, 55.519726],
							[8.032428, 55.276879],
							[7.911631, 54.748676],
							[7.91133, 54.741375],
							[7.913672, 54.734454],
							[8.127177, 54.352719],
							[7.583963, 54.331042],
							[7.57789, 54.329841],
							[7.572476, 54.326841],
							[7.568238, 54.322328],
							[7.565583, 54.316736],
							[7.514885, 54.145521],
							[7.514065, 54.139536],
							[7.515069, 54.133578],
							[7.517806, 54.128193],
							[7.522026, 54.12387],
							[7.677636, 54.006617],
							[5.433904, 53.656421],
							[5.430608, 53.655615],
							[4.654684, 53.394454],
							[4.650306, 53.392359],
							[4.646561, 53.389271],
							[4.324055, 53.049672],
							[4.319841, 53.04295],
							[4.030923, 52.276015],
							[3.071842, 51.569735],
							[1.348864, 50.968174],
							[1.343073, 50.964997],
							[1.338633, 50.960107],
							[1.336028, 50.954037],
							[1.162042, 50.24164],
							[-3.4e-5, 49.889919],
							[-0.006022, 49.886938],
							[-0.010692, 49.882148],
							[-0.252378, 49.532462],
							[-0.87657, 49.6139],
							[-1.146141, 49.90199],
							[-1.152352, 49.906478],
							[-1.159794, 49.908302],
							[-2.016611, 49.949088],
							[-2.02236, 49.948527],
							[-2.027708, 49.946346],
							[-2.03221, 49.942728],
							[-2.035491, 49.937975],
							[-2.037276, 49.932482],
							[-2.11741, 49.463834],
							[-2.117348, 49.456744],
							[-2.114815, 49.450121],
							[-2.110132, 49.444798],
							[-1.857723, 49.244425],
							[-1.996505, 48.904645],
							[-2.507151, 48.946588],
							[-2.53918, 49.223293],
							[-2.540826, 49.229239],
							[-2.544206, 49.2344],
							[-2.548999, 49.238286],
							[-2.554747, 49.240526],
							[-2.93299, 49.323804],
							[-2.93911, 49.324189],
							[-2.945059, 49.322701],
							[-2.950278, 49.319481],
							[-3.166246, 49.135074],
							[-5.370119, 48.624978],
							[-5.375632, 48.6228],
							[-5.380266, 48.619102],
							[-5.383611, 48.614207],
							[-5.469994, 48.435751],
							[-5.471672, 48.430605],
							[-5.471908, 48.425197],
							[-5.470685, 48.419925],
							[-5.277062, 47.910994],
							[-5.274058, 47.905702],
							[-5.269601, 47.901558],
							[-5.264105, 47.898946],
							[-3.695307, 47.429305],
							[-3.365782, 47.097957],
							[-3.36038, 47.09409],
							[-3.354009, 47.092206],
							[-2.703004, 47.013256],
							[-2.656895, 46.592404],
							[-2.655321, 46.586531],
							[-2.652056, 46.581401],
							[-2.6474, 46.577491],
							[-1.725376, 46.017158],
							[-1.464752, 45.470057],
							[-1.737652, 43.705577],
							[-2.194511, 43.544743],
							[-3.579197, 43.735963],
							[-3.584619, 43.73597],
							[-4.356317, 43.631399],
							[-5.846055, 43.885773],
							[-5.851098, 43.885988],
							[-6.892948, 43.798329],
							[-7.691931, 44.012825],
							[-7.697914, 44.013493],
							[-7.703825, 44.01235],
							[-9.017091, 43.544822],
							[-9.02333, 43.541225],
							[-9.588601, 43.061138],
							[-9.592753, 43.056269],
							[-9.595154, 43.050337],
							[-9.595559, 43.04395],
							[-9.593926, 43.037763],
							[-9.203232, 42.159863],
							[-9.043931, 40.668218],
							[-9.171362, 40.278839],
							[-9.833897, 39.479303],
							[-9.836716, 39.474793],
							[-9.838246, 39.4697],
							[-9.83838, 39.464383],
						],
						[
							[-5.370397, 36.1285],
							[-5.301112, 36.129093],
							[-5.305798, 36.104164],
							[-5.352679, 36.086478],
							[-5.370397, 36.1285],
						],
						[
							[1.467946, 42.593676],
							[1.550589, 42.634089],
							[1.736061, 42.57007],
							[1.523101, 42.457164],
							[1.467946, 42.593676],
						],
						[
							[6.035156, 46.185948],
							[6.184977, 46.352639],
							[6.18855, 46.358282],
							[6.190065, 46.364786],
							[6.189355, 46.371427],
							[6.133709, 46.569157],
							[7.069647, 47.318639],
							[7.07365, 47.322954],
							[7.076223, 47.328246],
							[7.077145, 47.334058],
							[7.076335, 47.339887],
							[7.073864, 47.345228],
							[7.069946, 47.349619],
							[7.064919, 47.352679],
							[7.059219, 47.354143],
							[6.916235, 47.369044],
							[6.992025, 47.47266],
							[7.382584, 47.411862],
							[7.387999, 47.411761],
							[7.393242, 47.413116],
							[7.397929, 47.415829],
							[7.596053, 47.569712],
							[8.58273, 47.575932],
							[8.588909, 47.576952],
							[8.59447, 47.579832],
							[8.598868, 47.584291],
							[8.60167, 47.589892],
							[8.625858, 47.666246],
							[8.626792, 47.672377],
							[8.625802, 47.678499],
							[8.622985, 47.684023],
							[8.618611, 47.68842],
							[8.613101, 47.691264],
							[8.606984, 47.692285],
							[8.460779, 47.693688],
							[8.572986, 47.786362],
							[9.436868, 47.576054],
							[9.631013, 47.446129],
							[9.514261, 47.282215],
							[9.511405, 47.276393],
							[9.510562, 47.269962],
							[9.511821, 47.2636],
							[9.591563, 47.050613],
							[9.594071, 47.045927],
							[9.597725, 47.042068],
							[9.602266, 47.039307],
							[10.097395, 46.822347],
							[10.103773, 46.820734],
							[10.11033, 46.821278],
							[10.468215, 46.911881],
							[10.45822, 46.580239],
							[10.105033, 46.630659],
							[10.098663, 46.630543],
							[10.092653, 46.62843],
							[10.087612, 46.624534],
							[10.084052, 46.61925],
							[10.082334, 46.613115],
							[10.082633, 46.606751],
							[10.153169, 46.270685],
							[10.078165, 46.241689],
							[9.974909, 46.38976],
							[9.970707, 46.394166],
							[9.965373, 46.397103],
							[9.959403, 46.398299],
							[9.95335, 46.397644],
							[9.627099, 46.310627],
							[9.480047, 46.520514],
							[9.475247, 46.525344],
							[9.469105, 46.528284],
							[9.462333, 46.528993],
							[9.281256, 46.516889],
							[9.275742, 46.515725],
							[9.270772, 46.513069],
							[9.26674, 46.509131],
							[9.263966, 46.504225],
							[9.007767, 45.849842],
							[8.814425, 45.993283],
							[8.868065, 46.063666],
							[8.870959, 46.06897],
							[8.872138, 46.074897],
							[8.871492, 46.080905],
							[8.869082, 46.086446],
							[8.865126, 46.091015],
							[8.859987, 46.094193],
							[8.464759, 46.262321],
							[8.466156, 46.46376],
							[8.465322, 46.469617],
							[8.462811, 46.474973],
							[8.458842, 46.47936],
							[8.453764, 46.482395],
							[8.448021, 46.483812],
							[8.442114, 46.483486],
							[8.436561, 46.481446],
							[8.075927, 46.284232],
							[8.070932, 46.280363],
							[8.067393, 46.275128],
							[8.065664, 46.26905],
							[8.065917, 46.262737],
							[8.068127, 46.256817],
							[8.127959, 46.151333],
							[7.855936, 45.9364],
							[7.111393, 45.88018],
							[6.698261, 46.466059],
							[6.693405, 46.470904],
							[6.687197, 46.473823],
							[6.680368, 46.474473],
							[6.673722, 46.472777],
							[6.035156, 46.185948],
						],
						[
							[12.454861, 43.942448],
							[12.48362, 43.954581],
							[12.47583, 43.928397],
							[12.454861, 43.942448],
						],
						[
							[17.797777, 61.172544],
							[18.312164, 62.403276],
							[19.439505, 63.05456],
							[20.187267, 63.204829],
							[20.154001, 63.181093],
							[20.149685, 63.176903],
							[20.146811, 63.171619],
							[20.145638, 63.165719],
							[20.146272, 63.159737],
							[20.148656, 63.154215],
							[20.646479, 62.357481],
							[20.882175, 61.645505],
							[20.754058, 61.140503],
							[20.017848, 60.746544],
							[19.195898, 60.539443],
							[17.903732, 60.957497],
							[17.797777, 61.172544],
						],
						[
							[21.398652, 63.656484],
							[21.423857, 63.945672],
							[22.088547, 64.382117],
							[22.09303, 64.386147],
							[22.096108, 64.39133],
							[22.097502, 64.397195],
							[22.097086, 64.403208],
							[22.094896, 64.408825],
							[21.854787, 64.825282],
							[23.134432, 65.265618],
							[24.112602, 65.371895],
							[23.821029, 64.682399],
							[23.092848, 64.484897],
							[23.087967, 64.482848],
							[23.083795, 64.47959],
							[23.080624, 64.475352],
							[22.947627, 64.237376],
							[22.369337, 64.081282],
							[22.362695, 64.078082],
							[21.876779, 63.720548],
							[21.398652, 63.656484],
						],
					],
					[
						[
							[-5.441935, 35.916235],
							[-5.441923, 35.909764],
							[-5.439844, 35.903635],
							[-5.435918, 35.898491],
							[-5.430555, 35.89487],
							[-5.424316, 35.893151],
							[-5.418443, 35.892524],
							[-5.412561, 35.892767],
							[-5.407004, 35.894712],
							[-5.402255, 35.898191],
							[-5.399159, 35.902322],
							[-5.398342, 35.901074],
							[-5.356997, 35.856782],
							[-5.352132, 35.852969],
							[-5.346335, 35.850824],
							[-5.340159, 35.850552],
							[-5.334196, 35.852179],
							[-5.257341, 35.886631],
							[-5.252052, 35.890098],
							[-5.248109, 35.895042],
							[-5.245908, 35.90097],
							[-5.245667, 35.907289],
							[-5.247411, 35.913367],
							[-5.250966, 35.918597],
							[-5.255975, 35.922456],
							[-5.261939, 35.924558],
							[-5.36687, 35.943662],
							[-5.372112, 35.943916],
							[-5.37724, 35.942798],
							[-5.3819, 35.940384],
							[-5.39517, 35.931121],
							[-5.399726, 35.926608],
							[-5.401313, 35.929236],
							[-5.405747, 35.933198],
							[-5.411151, 35.935682],
							[-5.417045, 35.936467],
							[-5.418954, 35.936434],
							[-5.426194, 35.934947],
							[-5.432398, 35.930931],
							[-5.435974, 35.927531],
							[-5.43988, 35.922372],
							[-5.441935, 35.916235],
						],
					],
					[
						[
							[-4.322164, 35.170172],
							[-4.320423, 35.163932],
							[-4.316779, 35.158576],
							[-4.311614, 35.154666],
							[-4.307928, 35.152722],
							[-4.301413, 35.150612],
							[-4.294567, 35.150823],
							[-4.288195, 35.153331],
							[-4.285376, 35.155048],
							[-4.280655, 35.159042],
							[-4.27738, 35.164288],
							[-4.275864, 35.170283],
							[-4.276252, 35.176455],
							[-4.278507, 35.182214],
							[-4.282413, 35.187008],
							[-4.287597, 35.190379],
							[-4.292031, 35.192367],
							[-4.297421, 35.193921],
							[-4.303031, 35.193917],
							[-4.308419, 35.192355],
							[-4.313162, 35.189358],
							[-4.315233, 35.187598],
							[-4.319425, 35.18266],
							[-4.321819, 35.17664],
							[-4.322164, 35.170172],
						],
					],
					[
						[
							[-3.924606, 35.213494],
							[-3.92301, 35.207317],
							[-3.919549, 35.201956],
							[-3.914577, 35.197957],
							[-3.908598, 35.195729],
							[-3.892312, 35.192485],
							[-3.886175, 35.192225],
							[-3.880249, 35.193839],
							[-3.875092, 35.197175],
							[-3.871191, 35.201919],
							[-3.870211, 35.203576],
							[-3.86802, 35.208917],
							[-3.867446, 35.214661],
							[-3.868537, 35.22033],
							[-3.871201, 35.225451],
							[-3.875217, 35.229598],
							[-3.88025, 35.232425],
							[-3.893411, 35.237484],
							[-3.900375, 35.238815],
							[-3.907365, 35.237632],
							[-3.913504, 35.234085],
							[-3.917609, 35.230612],
							[-3.92176, 35.225767],
							[-3.924175, 35.219861],
							[-3.924606, 35.213494],
						],
					],
					[
						[
							[-3.291673, 35.94524],
							[-3.29108, 35.939232],
							[-3.288721, 35.933674],
							[-3.284811, 35.929074],
							[-3.058174, 35.732362],
							[-3.05219, 35.728779],
							[-3.045339, 35.727468],
							[-3.038454, 35.72859],
							[-3.032374, 35.732008],
							[-2.799085, 35.923525],
							[-2.795194, 35.927801],
							[-2.792688, 35.933012],
							[-2.791777, 35.938721],
							[-2.792538, 35.944453],
							[-2.794906, 35.949727],
							[-2.798684, 35.954104],
							[-3.007098, 36.134556],
							[-3.012714, 36.137986],
							[-3.01914, 36.139408],
							[-3.025679, 36.138668],
							[-3.031624, 36.135845],
							[-3.283135, 35.960587],
							[-3.287511, 35.956427],
							[-3.290446, 35.951151],
							[-3.291673, 35.94524],
						],
					],
					[
						[
							[-2.99038, 35.28975],
							[-2.989825, 35.284041],
							[-2.987672, 35.278725],
							[-2.9841, 35.274238],
							[-2.959458, 35.251076],
							[-2.954277, 35.247553],
							[-2.948261, 35.245805],
							[-2.941999, 35.246005],
							[-2.936106, 35.248132],
							[-2.931161, 35.251978],
							[-2.904038, 35.280945],
							[-2.900675, 35.285818],
							[-2.898887, 35.291463],
							[-2.89883, 35.297384],
							[-2.900509, 35.303062],
							[-2.903777, 35.308],
							[-2.93027, 35.337411],
							[-2.934849, 35.341181],
							[-2.940332, 35.343442],
							[-2.946237, 35.343995],
							[-2.952045, 35.342792],
							[-2.957244, 35.339939],
							[-2.961379, 35.335687],
							[-2.98665, 35.300472],
							[-2.989292, 35.295381],
							[-2.99038, 35.28975],
						],
					],
					[
						[
							[-2.465064, 35.177985],
							[-2.463814, 35.172056],
							[-2.460846, 35.166774],
							[-2.456433, 35.162622],
							[-2.450346, 35.158433],
							[-2.443958, 35.155531],
							[-2.43696, 35.155014],
							[-2.430215, 35.156946],
							[-2.426781, 35.159457],
							[-2.426153, 35.159445],
							[-2.425743, 35.159581],
							[-2.424249, 35.15922],
							[-2.419834, 35.158945],
							[-2.413568, 35.159548],
							[-2.4078, 35.162069],
							[-2.403101, 35.166257],
							[-2.399936, 35.171698],
							[-2.39862, 35.177854],
							[-2.399282, 35.184113],
							[-2.401856, 35.189857],
							[-2.405659, 35.195669],
							[-2.409741, 35.200207],
							[-2.415002, 35.203302],
							[-2.420952, 35.204666],
							[-2.427036, 35.204172],
							[-2.427231, 35.204093],
							[-2.427309, 35.204118],
							[-2.433932, 35.204004],
							[-2.436428, 35.203093],
							[-2.440973, 35.203594],
							[-2.447375, 35.202163],
							[-2.45297, 35.198738],
							[-2.458193, 35.194212],
							[-2.462118, 35.189596],
							[-2.46448, 35.184016],
							[-2.465064, 35.177985],
						],
					],
					[
						[
							[0.877951, 38.982359],
							[0.878406, 38.97589],
							[0.880907, 38.969907],
							[1.182485, 38.487544],
							[1.186252, 38.483113],
							[1.191135, 38.479954],
							[1.19672, 38.478333],
							[1.581076, 38.425502],
							[1.587591, 38.425678],
							[1.593703, 38.427939],
							[1.830814, 38.563078],
							[1.835303, 38.566567],
							[1.838629, 38.571178],
							[1.840523, 38.576538],
							[1.932506, 39.03719],
							[1.932811, 39.042916],
							[1.931478, 39.048494],
							[1.928618, 39.053464],
							[1.924465, 39.057418],
							[1.53038, 39.336971],
							[1.52543, 39.339531],
							[1.519966, 39.340625],
							[1.514412, 39.340169],
							[1.5092, 39.338199],
							[0.888257, 38.99805],
							[0.88315, 38.994054],
							[0.87959, 38.988634],
							[0.877951, 38.982359],
						],
					],
					[
						[
							[2.020087, 39.577882],
							[2.020656, 39.571754],
							[2.023061, 39.566089],
							[2.027075, 39.561424],
							[2.740708, 38.953197],
							[2.745184, 38.950314],
							[2.750262, 38.948713],
							[2.999074, 38.90554],
							[3.00436, 38.905332],
							[3.009516, 38.906519],
							[3.01418, 38.909015],
							[3.453742, 39.225534],
							[3.459087, 39.23128],
							[3.750922, 39.705376],
							[4.348434, 39.580933],
							[4.355653, 39.580761],
							[4.362462, 39.583163],
							[4.367975, 39.587828],
							[4.606734, 39.878861],
							[4.609774, 39.883952],
							[4.611188, 39.889711],
							[4.61085, 39.895631],
							[4.608792, 39.901192],
							[4.47134, 40.150849],
							[4.46682, 40.156401],
							[4.460634, 40.160006],
							[4.041831, 40.311781],
							[4.03632, 40.312936],
							[4.030706, 40.312508],
							[3.654488, 40.229478],
							[3.649425, 40.227616],
							[3.645044, 40.224468],
							[3.641664, 40.220265],
							[3.522663, 40.022632],
							[3.285004, 40.176337],
							[3.278575, 40.179046],
							[3.271608, 40.179382],
							[2.839207, 40.12413],
							[2.830746, 40.120997],
							[2.029053, 39.593351],
							[2.024494, 39.589217],
							[2.021408, 39.583893],
							[2.020087, 39.577882],
						],
					],
					[
						[
							[7.388635, 54.007642],
							[7.389438, 54.002003],
							[7.391797, 53.99682],
							[7.395523, 53.992511],
							[7.395751, 53.992313],
							[7.400539, 53.98923],
							[7.406002, 53.987621],
							[7.411697, 53.987617],
							[7.552978, 54.00784],
							[7.559323, 54.009868],
							[7.564649, 54.013868],
							[7.568367, 54.019394],
							[7.570063, 54.025835],
							[7.569551, 54.032476],
							[7.566886, 54.03858],
							[7.562364, 54.043471],
							[7.421043, 54.152538],
							[7.415478, 54.155565],
							[7.409246, 54.1567],
							[7.402971, 54.155829],
							[7.397284, 54.153039],
							[7.392754, 54.148611],
							[7.389836, 54.142988],
							[7.388824, 54.136734],
							[7.388635, 54.007642],
						],
					],
					[
						[
							[8.638682, 47.688864],
							[8.640345, 47.683147],
							[8.643617, 47.678172],
							[8.648209, 47.674382],
							[8.65966, 47.667507],
							[8.665826, 47.665085],
							[8.672446, 47.66481],
							[8.720547, 47.670848],
							[8.726151, 47.672404],
							[8.73107, 47.675506],
							[8.734889, 47.679892],
							[8.737284, 47.685191],
							[8.738054, 47.690955],
							[8.737133, 47.696698],
							[8.734599, 47.701932],
							[8.717813, 47.726638],
							[8.713806, 47.730983],
							[8.708703, 47.733966],
							[8.702952, 47.735328],
							[8.697053, 47.734949],
							[8.691523, 47.732863],
							[8.648756, 47.708993],
							[8.644047, 47.705349],
							[8.640618, 47.70048],
							[8.638776, 47.694818],
							[8.638682, 47.688864],
						],
					],
					[
						[
							[8.938643, 45.96693],
							[8.938863, 45.960964],
							[8.940833, 45.955329],
							[8.944379, 45.950526],
							[8.949184, 45.946984],
							[8.954821, 45.945018],
							[8.972487, 45.941697],
							[8.979178, 45.941579],
							[8.985534, 45.943674],
							[8.990842, 45.947749],
							[8.99451, 45.953346],
							[8.996125, 45.959841],
							[8.99776, 45.981399],
							[8.997285, 45.987492],
							[8.994993, 45.993157],
							[8.991098, 45.997866],
							[8.985962, 46.001178],
							[8.980066, 46.002785],
							[8.969283, 46.004005],
							[8.962962, 46.003713],
							[8.957049, 46.001461],
							[8.952135, 45.997474],
							[8.948713, 45.992152],
							[8.940194, 45.972694],
							[8.938643, 45.96693],
						],
					],
					[
						[
							[11.640024, 36.842987],
							[11.640058, 36.836901],
							[11.641921, 36.831107],
							[11.645441, 36.826141],
							[11.95558, 36.505893],
							[11.961054, 36.501893],
							[11.967551, 36.499951],
							[11.974322, 36.500291],
							[11.980591, 36.502875],
							[12.330842, 36.723041],
							[12.335359, 36.72693],
							[12.33853, 36.731977],
							[12.340073, 36.737735],
							[12.33985, 36.743692],
							[12.337881, 36.749318],
							[12.334341, 36.754115],
							[12.034163, 37.05434],
							[12.02894, 37.058099],
							[12.022794, 37.060006],
							[12.016361, 37.059861],
							[12.010306, 37.057682],
							[11.650094, 36.857538],
							[11.645285, 36.853806],
							[11.641822, 36.848802],
							[11.640024, 36.842987],
						],
					],
					[
						[
							[12.040047, 35.563133],
							[12.040153, 35.556378],
							[12.042501, 35.550043],
							[12.152643, 35.359786],
							[12.156254, 35.355233],
							[12.161021, 35.351911],
							[12.166542, 35.350099],
							[12.686667, 35.260108],
							[12.692381, 35.259949],
							[12.697906, 35.261412],
							[12.702792, 35.264378],
							[12.70664, 35.268605],
							[13.146754, 35.918847],
							[13.149289, 35.924118],
							[13.150191, 35.929897],
							[13.149382, 35.935689],
							[13.146932, 35.941],
							[13.143051, 35.945375],
							[13.13807, 35.948441],
							[12.787796, 36.098565],
							[12.782484, 36.100017],
							[12.776977, 36.099965],
							[12.771693, 36.098413],
							[12.767033, 36.095479],
							[12.576926, 35.935359],
							[12.573046, 35.930969],
							[12.570604, 35.925643],
							[12.569811, 35.919838],
							[12.570734, 35.914052],
							[12.62162, 35.752537],
							[12.281543, 35.78012],
							[12.276109, 35.779818],
							[12.270959, 35.778063],
							[12.266472, 35.774984],
							[12.046356, 35.574861],
							[12.042197, 35.569538],
							[12.040047, 35.563133],
						],
					],
					[
						[
							[12.86988, 38.651786],
							[12.870238, 38.645837],
							[12.872333, 38.640257],
							[12.87598, 38.635543],
							[12.880853, 38.632113],
							[13.221122, 38.461925],
							[13.227702, 38.459953],
							[13.234561, 38.460323],
							[13.24089, 38.462993],
							[13.245942, 38.467646],
							[13.476062, 38.767901],
							[13.478959, 38.773165],
							[13.480162, 38.779051],
							[13.479563, 38.785029],
							[13.477216, 38.790559],
							[13.473331, 38.795143],
							[13.468261, 38.798366],
							[13.128001, 38.948485],
							[13.121742, 38.950104],
							[13.115294, 38.949642],
							[13.10933, 38.947148],
							[13.104473, 38.942881],
							[12.874345, 38.662694],
							[12.871291, 38.657576],
							[12.86988, 38.651786],
						],
					],
					[
						[
							[13.912259, 36.070277],
							[13.913786, 36.063729],
							[13.917397, 36.058058],
							[14.308108, 35.626524],
							[14.314052, 35.622029],
							[14.321228, 35.620021],
							[14.613722, 35.594978],
							[14.619046, 35.595235],
							[14.624112, 35.596888],
							[14.628562, 35.599822],
							[14.826863, 35.772497],
							[14.831322, 35.778067],
							[14.833541, 35.784847],
							[14.83324, 35.791975],
							[14.784863, 36.006731],
							[14.781719, 36.013831],
							[14.776108, 36.019198],
							[14.359177, 36.285157],
							[14.353435, 36.287657],
							[14.347202, 36.288258],
							[14.057626, 36.270569],
							[14.051816, 36.26933],
							[14.046626, 36.266439],
							[14.042514, 36.262151],
							[13.915892, 36.083026],
							[13.912989, 36.076961],
							[13.912259, 36.070277],
						],
					],
					[
						[
							[14.313226, 55.091752],
							[14.314089, 55.085802],
							[14.316682, 55.080378],
							[14.320769, 55.075968],
							[14.594385, 54.858122],
							[14.599017, 54.855363],
							[14.604219, 54.853942],
							[15.196218, 54.775615],
							[15.20292, 54.775862],
							[15.209164, 54.778312],
							[15.467159, 54.933781],
							[15.471298, 54.937096],
							[15.47442, 54.941381],
							[15.476306, 54.946336],
							[15.569378, 55.342344],
							[15.569866, 55.34823],
							[15.568613, 55.354001],
							[15.56573, 55.359155],
							[15.561467, 55.363242],
							[15.318434, 55.535341],
							[15.312371, 55.538249],
							[15.305687, 55.538983],
							[14.849065, 55.511796],
							[14.843225, 55.510556],
							[14.838012, 55.507647],
							[14.320984, 55.10743],
							[14.316837, 55.103078],
							[14.314171, 55.097689],
							[14.313226, 55.091752],
						],
					],
					[
						[
							[17.658934, 57.30797],
							[17.659923, 57.302148],
							[17.831556, 56.777866],
							[17.834383, 56.772332],
							[17.838772, 56.767933],
							[17.844299, 56.765094],
							[17.850431, 56.764088],
							[18.55179, 56.759462],
							[18.558006, 56.760409],
							[18.563621, 56.76324],
							[19.324387, 57.311912],
							[19.329144, 57.316766],
							[19.332, 57.322933],
							[19.332627, 57.329701],
							[19.314657, 57.558345],
							[19.725128, 57.875702],
							[19.729484, 57.880353],
							[19.732156, 57.886138],
							[19.732872, 57.892471],
							[19.73156, 57.898707],
							[19.728353, 57.904214],
							[19.513972, 58.165383],
							[19.712441, 58.402764],
							[19.715563, 58.40791],
							[19.717012, 58.413752],
							[19.716658, 58.419761],
							[19.714531, 58.425392],
							[19.710826, 58.430136],
							[19.705877, 58.433562],
							[19.354606, 58.605199],
							[19.349295, 58.606926],
							[19.343714, 58.607118],
							[18.900298, 58.560025],
							[18.894557, 58.55853],
							[18.889506, 58.555417],
							[18.885592, 58.550959],
							[18.773222, 58.376331],
							[18.770585, 58.370141],
							[18.770149, 58.363427],
							[18.771965, 58.356948],
							[18.775827, 58.351438],
							[18.935317, 58.190319],
							[18.357306, 58.060996],
							[18.350142, 58.05782],
							[17.75654, 57.638961],
							[17.75175, 57.634181],
							[17.748829, 57.628078],
							[17.659689, 57.313828],
							[17.658934, 57.30797],
						],
					],
					[
						[
							[20.927187, 37.235738],
							[20.929509, 37.229171],
							[20.967442, 37.162137],
							[20.970946, 37.157609],
							[20.97559, 37.154259],
							[20.980992, 37.152363],
							[21.067526, 37.135359],
							[21.073213, 37.135067],
							[21.078751, 37.13639],
							[21.083692, 37.139221],
							[21.087635, 37.143329],
							[21.090261, 37.148381],
							[21.126416, 37.251762],
							[21.127536, 37.258171],
							[21.12654, 37.2646],
							[21.123533, 37.27037],
							[21.118833, 37.274869],
							[21.01274, 37.347481],
							[21.006837, 37.350236],
							[21.000362, 37.350947],
							[20.994002, 37.349541],
							[20.988432, 37.346165],
							[20.984241, 37.341178],
							[20.929713, 37.249222],
							[20.927258, 37.242703],
							[20.927187, 37.235738],
						],
					],
					[
						[
							[23.842138, 34.942765],
							[23.843635, 34.936714],
							[23.846921, 34.931418],
							[24.047523, 34.698878],
							[24.051974, 34.695041],
							[24.057348, 34.692662],
							[24.063181, 34.691949],
							[24.06897, 34.692962],
							[24.256469, 34.755232],
							[24.262115, 34.758175],
							[24.26655, 34.762744],
							[24.269324, 34.768474],
							[24.270157, 34.774787],
							[24.268964, 34.781041],
							[24.265865, 34.786603],
							[24.067335, 35.038169],
							[24.062525, 35.042554],
							[24.05656, 35.045163],
							[24.050074, 35.045718],
							[24.043753, 35.04416],
							[23.854182, 34.962863],
							[23.848906, 34.959544],
							[23.844909, 34.954762],
							[23.842577, 34.948982],
							[23.842138, 34.942765],
						],
					],
					[
						[
							[29.290486, 36.012386],
							[29.2912, 36.005703],
							[29.294089, 35.999634],
							[29.298825, 35.994864],
							[29.388246, 35.931012],
							[29.393071, 35.92848],
							[29.3984, 35.927343],
							[29.728429, 35.903036],
							[29.734324, 35.903478],
							[29.739832, 35.905624],
							[29.744473, 35.909286],
							[29.74784, 35.914145],
							[29.749639, 35.919776],
							[29.749715, 35.925687],
							[29.748058, 35.931361],
							[29.631785, 36.18336],
							[29.628135, 36.188746],
							[29.622948, 36.192675],
							[29.616775, 36.194731],
							[29.610268, 36.194697],
							[29.604117, 36.192576],
							[29.300938, 36.028736],
							[29.295646, 36.024592],
							[29.292025, 36.018929],
							[29.290486, 36.012386],
						],
					],
					[
						[
							[32.002696, 35.069991],
							[32.003112, 35.062968],
							[32.005935, 35.056523],
							[32.372358, 34.508835],
							[32.376832, 34.504069],
							[32.382603, 34.501001],
							[32.389055, 34.499956],
							[33.391705, 34.503648],
							[33.397924, 34.504664],
							[34.264788, 34.791998],
							[34.270625, 34.795081],
							[34.275141, 34.799896],
							[34.277844, 34.805918],
							[34.278438, 34.812493],
							[34.251645, 35.166195],
							[34.757517, 35.489473],
							[34.761973, 35.493358],
							[34.765099, 35.498376],
							[34.869632, 35.739717],
							[34.871135, 35.745265],
							[34.870998, 35.751011],
							[34.869233, 35.756481],
							[34.865984, 35.761223],
							[34.861521, 35.764845],
							[34.585957, 35.929126],
							[34.580635, 35.931333],
							[34.574905, 35.931931],
							[34.569242, 35.930871],
							[33.550594, 35.582402],
							[32.854102, 35.618301],
							[32.848275, 35.617744],
							[32.842858, 35.615523],
							[32.838317, 35.611829],
							[32.660421, 35.417401],
							[32.122774, 35.292659],
							[32.117383, 35.290548],
							[32.112822, 35.286981],
							[32.109474, 35.282258],
							[32.004738, 35.076724],
							[32.002696, 35.069991],
						],
					],
				],
			},
			arbitrip_center: {
				type: 'Point',
				coordinates: [13.226043, 50.574673],
			},
		},
		name: 'European Union',
		name_full: 'European Union',
		property_ids: [
			'51040998',
			'52907929',
			'4975562',
			'45733677',
			'55295051',
			'26885334',
			'46349006',
			'52996596',
			'23014297',
			'36981977',
			'46371731',
			'36627241',
			'54496459',
			'24349416',
			'52840230',
			'24706096',
			'52655255',
			'4478271',
			'36710341',
			'24706214',
			'39553092',
			'38692564',
			'52988345',
			'37648661',
			'41522846',
			'41332698',
			'4542289',
			'58024891',
			'43410135',
			'48254319',
			'46357252',
			'52419735',
			'44447112',
			'26827954',
			'8821680',
			'66317',
			'45781650',
			'39500246',
			'24707279',
			'24938512',
			'46356915',
			'46371673',
			'57325301',
			'1062931',
			'35860690',
			'46359546',
			'24326836',
			'35754244',
			'46356799',
			'32527915',
			'27398005',
			'58026262',
			'48254309',
			'48455191',
			'107442',
			'52111025',
			'52924872',
			'46357222',
			'24311757',
			'53105457',
			'46349117',
			'52791933',
			'10208974',
			'30353872',
			'46348473',
			'17953663',
			'52110724',
			'46355679',
		],
		type: 'high_level_region',
	},
];
