import React, { useEffect, useMemo, useState } from 'react';
import ReservationStore from '../../../../stores/ReservationStore';
import { ReservationConstants } from 'arbitrip-common/client/constants';
import { isPendingStatus, RoomAvailable, RoomNotAvailableError } from '../../reservation-payment/helpers';
import { Button } from '@mui/material';

const SettlePaymentCreditCardResult = ({ onClose }) => {
	const [reservationData, setReservationData] = useState(ReservationStore.getReservationData());

	useEffect(() => {
		function handleStoreChange() {
			setReservationData(ReservationStore.getReservationData());
		}

		ReservationStore.addChangeListener(handleStoreChange);

		return () => {
			ReservationStore.removeChangeListener(handleStoreChange);
		};
	}, []);

	const getResultText = useMemo(() => {
		return reservationData.status === ReservationConstants.STATUS.BOOKED || isPendingStatus(reservationData.status)
			? 'Your reservation is being processed.'
			: 'Something went wrong :(';
	}, []);

	return (
		<div className="settle-payment-result">
			<div className="title">{getResultText}</div>

			{reservationData.status === ReservationConstants.STATUS.BOOKED ||
			isPendingStatus(reservationData.status) ? (
				RoomAvailable(reservationData.short_id)
			) : (
				<div className="text">
					<RoomNotAvailableError />
				</div>
			)}

			<div className="actions">
				<Button variant="contained" fullWidth onClick={onClose}>
					Got It
				</Button>
			</div>
		</div>
	);
};

export default SettlePaymentCreditCardResult;
