const _ = require('lodash');
const { SUPPORTED_LANGUAGES } = require('../../../../translation/const');
const { CHAT_PROVIDERS } = require('../../../../general/constants/chat-providers');
const {loadZendeskChat, updateZendeskProfile} = require("./utils");

function boot(profile, options = {}) {
    const { id, language } = profile;
    const enabled = _.get(profile, 'chat.enabled', false);
    const chatLanguage = profile?.chat?.provider
    const token = _.get(profile, 'settings.zendesk_jwt_token', '');
    const mainButtonHidden = _.get(options, 'zendesk_settings.hide', false);



    const chatId =
        chatLanguage === CHAT_PROVIDERS.ZENDESK_EN ? process.env.REACT_APP_ZENDESK_INTEGRATION_EN_ID : process.env.REACT_APP_ZENDESK_INTEGRATION_HE_ID;


    const zendeskConfig = {
        integrationId: chatId,
        externalId: id,
        jwt: token,
        locale: language || SUPPORTED_LANGUAGES.EN,
    };

    if (mainButtonHidden) {
        zendeskConfig.buttonHeight = '0';
        zendeskConfig.buttonWidth = '0';
    }

    if (enabled && token) {

        loadZendeskChat(chatId)
        try {
            window.Smooch.init(zendeskConfig).then(() => {
                updateZendeskProfile(profile);
            });
            console.log("Called Zendesk boot");
        } catch (error) {
            console.error("Failed to initialize Zendesk:", error);
        }
    } else {
        console.log("Zendesk boot wasn't called");
    }
}



module.exports = {
    boot,
};
