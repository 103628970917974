const CONSTANTS = module.exports = {};

CONSTANTS.AUTH_PROVIDERS = {
    AUTH0: 'auth0', // user/password
    ARBITRIP_AUTH: 'arbitrip_auth', // API Based
    AUTH0_MAGIC_LINK: 'email', // magic_link by email
    AUTH0_SOCIAL_GMAIL: 'google-oauth2', // Gmail button
    SAMLP: 'samlp', // Okta or other Samlp
    MICROSOFT: 'windowslive' // microsoft account
};

CONSTANTS.AUTH_PROVIDERS_ISSUER = {
    OKTA: 'okta',
    WINDOWS: 'windows'
}

CONSTANTS.OKTA_DEPARTMENT_KEY = 'department';
CONSTANTS.OKTA_COST_CENTER_KEY = 'costCenter';
CONSTANTS.OKTA_COUNTRY_KEY = 'Country';