const { PAYMENT_STATUS } = require('arbitrip-common/general/constants');

const CONSTANTS = module.exports = {};

CONSTANTS.PAYMENT_STATUS_ERRORS = {
    [PAYMENT_STATUS.BILLED]: {
        message: 'This payment has already been billed',
        errorCode: 'PAYMENT_ALREADY_BILLED',
        logLevel: 'warn'
    },
    [PAYMENT_STATUS.CANCELLED]: {
        message: 'This payment has been cancelled',
        errorCode: 'PAYMENT_CANCELLED',
        logLevel: 'warn'
    },
    [PAYMENT_STATUS.ERROR]: {
        message: 'An error occurred with this payment',
        errorCode: 'PAYMENT_ERROR',
        logLevel: 'error'
    }
};
