import { Avatar, Menu } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import { default as MaterialAvatar } from '@mui/material/Avatar';

import _ from 'lodash';

import { Badge, Dialog, MenuItem, Divider, Tooltip, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import React from 'react';

import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';

import ProfileStore from '../../../../stores/ProfileStore';
import ApiURLs from '../../../../utils/ApiURLs';
import Currencies from 'arbitrip-common/general/utils/Currencies';

import Analytics from 'arbitrip-common/client/analytics';

import AgentSettingsModal from './agent-settings/AgentSettingsModal.react';
import TravelAgentStore from '../../../../stores/TravelAgentStore';
import TravelAgentActions from '../../../../actions/TravelAgentActions';

import Config from 'arbitrip-common/client/utils/Config';

import Popover from '@mui/material/Popover';
import { withRouter } from '../../../../utils/withRouter';
import { avatarProps } from '../../../../utils/AvatarUtils';

const SimplePopover = withStyles((theme) => ({
	typography: {
		padding: theme.spacing(2),
	},
}))(Popover);

const SmallAvatar = withStyles((theme) => ({
	root: {
		width: 18,
		height: 18,
		paddingRight: 2,
		border: `2px solid ${theme.palette.background.paper}`,
	},
}))(MaterialAvatar);

const HtmlTooltip = withStyles((theme) => ({
	arrow: {
		color: '#ffffff',
		border: '1px solid #dadde9',
	},
	tooltip: {
		backgroundColor: '#ffffff',
		color: 'rgba(0, 0, 0, 0.87)',
		maxWidth: 220,
		fontSize: theme.typography.pxToRem(12),
		border: '1px solid #dadde9',
	},
}))(Tooltip);

const StyledMenuItem = withStyles(() => ({
	root: {
		'&:hover': {
			color: 'inherit',
		},
		'&:focus': {
			outline: 'none',
			textDecoration: 'none',
			color: 'inherit',
		},
	},
}))(MenuItem);

function getProfileState() {
	return {
		profile: ProfileStore.getProfile(),
		agent_settings_modal_open: TravelAgentStore.getTravelAgentSettingsModalOpen(),
		agent_settings_modal_close_request_count: 0,
	};
}

const ProfileComponent = createReactClass({
	getInitialState: function () {
		return getProfileState();
	},

	componentDidMount: function () {
		ProfileStore.addChangeListener(this._onChange);
		TravelAgentStore.addChangeListener(this._onChange);
	},

	componentWillUnmount: function () {
		ProfileStore.removeChangeListener(this._onChange);
		TravelAgentStore.removeChangeListener(this._onChange);
	},

	handleManagementClick: function (e) {
		e.preventDefault();
		const { navigate } = this.props;
		navigate('/management');
		this.handleClose();
	},

	handleTravelPolicyClick: function (e) {
		e.preventDefault();
		const { navigate } = this.props;
		navigate('/travel_policy');
		this.handleClose();
	},

	toggleTM: function (e) {
		ProfileStore.toggleTM();
	},

	toggleAgent: function (e) {
		ProfileStore.toggleAgent();
	},

	toggleDevMode: function (e) {
		Config.toggleDevMode();
		this.forceUpdate();
	},

	openAgentSettings: function (e) {
		// this.setState({
		//   agent_settings_modal_open: true
		// });
		TravelAgentActions.openTravelAgentSettingsModal();
	},

	handleAgentSettingsModalClose: function (buttonClicked) {
		// this.setState({
		//   agent_settings_modal_open: false
		// });
		// TravelAgentActions.closeTravelAgentSettingsModal();
		if (!buttonClicked) {
			let { agent_settings_modal_close_request_count } = this.state;
			agent_settings_modal_close_request_count++;
			this.setState({
				agent_settings_modal_close_request_count,
			});
		}
		this.handleClose();
	},

	handleClick: function (event) {
		this.setState({ anchorEl: event.currentTarget });
	},

	handleClose: function () {
		this.setState({
			anchorEl: null,
		});
	},

	render: function () {
		const { profile, agent_settings_modal_open, anchorEl } = this.state;

		const open = Boolean(anchorEl) && !agent_settings_modal_open;
		const id = open ? 'simple-popover' : undefined;

		const bank_balance = _.get(profile, 'bank.balance', 0);
		const bank_currency = _.get(profile, 'bank.currency', 'USD');
		const display_balance = Currencies.getPriceWithDisplayCurrencyByPrecision(
			bank_balance,
			1, // no display currency exchange
			bank_currency,
			2,
		);

		const LEISURE_EXPERIENCE = (
			<div key={'leisure_experience'}>
				<div className="leisure-experience-rewards" style={{ zIndex: 666 }}>
					<div className="name">
						<span>{profile.full_name}</span>
					</div>
					<div className="email">
						<span>{profile.email}</span>
					</div>
					{profile &&
					_.isFunction(profile.hasPositiveBalanceBankAccount) &&
					profile.hasPositiveBalanceBankAccount() ? (
						<React.Fragment>
							<div className="balance">
								<span>{display_balance}</span>
							</div>
							<div className="info">
								<span>
									Points value{' '}
									<HtmlTooltip
										title={
											<React.Fragment>
												<Typography color="inherit">Arbitrip Rewards program</Typography>
												This is what your Arbitrip Rewards points are worth toward bookings.
											</React.Fragment>
										}
									>
										<InfoOutlinedIcon style={{ fontSize: 12 }} />
									</HtmlTooltip>
								</span>
							</div>
						</React.Fragment>
					) : null}
				</div>
				<Divider />
			</div>
		);

		const MANAGE_USERS = (profile.travel_manager || profile.regional_admin) && profile.enable_user_management && (
			<StyledMenuItem key={'manage_users'} onClick={this.handleManagementClick} component="a" href="/management">
				Manage Users
			</StyledMenuItem>
		);

		const TRAVEL_POLICY = (profile.travel_manager || profile.regional_admin) && profile.travel_policy_enabled && (
			<StyledMenuItem
				key={'travel_policy'}
				onClick={this.handleTravelPolicyClick}
				component="a"
				href="/travel_policy"
			>
				Travel Policy
			</StyledMenuItem>
		);

		const TOGGLE_DEV_MODE = !Config.prod ? (
			<MenuItem key="toggle_dev" onClick={this.toggleDevMode} component="div">
				Toggle Dev Mode
			</MenuItem>
		) : null;

		const TOGGLE_TM_AND_TOGGLE_AGENT = Config.dev_mode
			? [
					<MenuItem key="toggle_tm" onClick={this.toggleTM} component="div">
						Toggle TM
					</MenuItem>,
					<MenuItem key="toggle_agent" onClick={this.toggleAgent} component="div">
						Toggle Agent
					</MenuItem>,
				]
			: [];

		const CLIENT_SETTINGS =
			profile.agent && profile.enable_clients_settings ? (
				<MenuItem key="client_settings" onClick={this.openAgentSettings}>
					Clients Settings
				</MenuItem>
			) : null;

		const LOGOUT = Config.prod ? (
			<StyledMenuItem
				component="a"
				key={'logout'}
				href="/logout"
				onClick={this.logoutClickHandler}
				style={{ color: 'black', textDecoration: 'none' }}
			>
				Sign out
			</StyledMenuItem>
		) : (
			<StyledMenuItem
				component="a"
				key={'logout'}
				href={ApiURLs.LOGOUT}
				onClick={this.logoutClickHandler}
				style={{ color: 'black', textDecoration: 'none' }}
			>
				Sign out
			</StyledMenuItem>
		);

		return (
			<div>
				<button aria-describedby={id} onClick={this.handleClick} aria-label="Open profile menu">
					<Badge
						overlap="circular"
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						style={{ cursor: 'pointer' }}
						badgeContent={
							<SmallAvatar>
								<span className="caret" />
							</SmallAvatar>
						}
					>
						<Avatar
							className="avatar"
							{...avatarProps({
								fullName: profile.full_name,
								url: profile.picture,
								size: 36,
							})}
						/>
					</Badge>
				</button>
				<SimplePopover
					id={id}
					open={open}
					anchorEl={anchorEl}
					onClose={this.handleClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
				>
					<Menu
						id="simple-menu"
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={this.handleClose}
					>
						{[
							LEISURE_EXPERIENCE,
							MANAGE_USERS,
							TRAVEL_POLICY,
							TOGGLE_DEV_MODE,
							...TOGGLE_TM_AND_TOGGLE_AGENT,
							CLIENT_SETTINGS,
							(LEISURE_EXPERIENCE ||
								MANAGE_USERS ||
								TOGGLE_DEV_MODE ||
								TOGGLE_TM_AND_TOGGLE_AGENT ||
								CLIENT_SETTINGS) && <Divider key={'divider'} />,
							LOGOUT,
						].filter(Boolean)}
					</Menu>
				</SimplePopover>
				<Dialog open={agent_settings_modal_open} onClose={this.handleAgentSettingsModalClose}>
					<AgentSettingsModal
						profile={profile}
						closeRequestCount={this.state.agent_settings_modal_close_request_count}
						closeTopMenu={this.handleClose}
					/>
				</Dialog>
			</div>
		);
	},

	logoutClickHandler: function (e) {
		const profile = ProfileStore.getProfile();
		Analytics.endSession(profile);
		Analytics.actions.global_properties.clear();

		function deleteCookieBy(name) {
			document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
		}

		deleteCookieBy('logged_in');
		console.log('should invoke logout event');

		ProfileStore.clear();

		document.location.reload();
	},

	_onChange: function () {
		this.setState(getProfileState());
	},

	propTypes: function () {
		return {
			history: PropTypes.any,
		};
	},
});

export default withRouter(ProfileComponent);
