import moment from 'moment';

function WizardFlight(wizard_flight, display_order) {
	this.flight_number = (wizard_flight && wizard_flight.flight_number) || '';
	this.departure_date = wizard_flight && wizard_flight.departure_date && moment(wizard_flight.departure_date);

	this.display_order = display_order || wizard_flight.display_order;
}

export default WizardFlight;
