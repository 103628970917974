const Config = require('./Config').default;
const Dates = require('./Dates');
const TokenGenerator = require('./TokenGenerator');
const ImageUtils = require('./ImageUtils');
const LocationService = require('./LocationService');
const ProtocolStripper = require('./ProtocolStripper');
const Validator = require('./Validator');
const WebStorageManager = require('./WebStorageManager');
const Catering = require('./Catering');
const DealRoomName = require('./DealRoomName');
const RoomMappingService = require('./RoomMappingService');
const PlacesUtils = require('./PlacesUtils');
const ColorsService = require('./ColorsService');
const RoomData = require('./RoomData');

module.exports = {
    Config,
    Dates,
    TokenGenerator,
    ImageUtils,
    LocationService,
    ProtocolStripper,
    Validator,
    WebStorageManager,
    Catering,
    DealRoomName,
    RoomMappingService,
    PlacesUtils,
    ColorsService,
    RoomData
};
