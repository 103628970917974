import _ from 'lodash';
import keyMirror from 'keymirror';

const SORT_CONSTANTS = {
	SORT_METHOD: {
		ABSOLUTE_MARGIN: 'Abs(Margin)',
		MARGIN_PERCENTAGE: 'Margin(%)',
		RECOMMENDED: 'Recommended',
		SCORES: 'Scores',
		PRICE: 'Price (Low to High)',
		PRICE_HIGH_TO_LOW: 'Price (High to Low)',
		DISTANCE: 'Distance',
		REVIEWS: 'Review score',
		// STARS: "Stars"
	},
	// SORT_BY: {
	//   ASC: "Ascending",
	//   DESC: "Descending"
	// }
};

// Define action constants
export default _.extend(
	{},
	SORT_CONSTANTS,
	keyMirror({
		SORT_BY_ABSOLUTE_MARGIN: null,
		SORT_BY_MARGIN_PERCENTAGE: null,
		SORT_BY_RECOMMENDED: null,
		SORT_BY_SCORES: null,
		SORT_BY_PRICE: null,
		SORT_BY_PRICE_HIGH_TO_LOW: null,
		SORT_BY_DISTANCE: null,
		SORT_BY_REVIEWS: null,
	}),
);
