import React from 'react';
import _ from 'lodash';

import Config from 'arbitrip-common/client/utils/Config';

function average(...prices) {
	return prices.reduce((sum, price) => sum + price, 0) / prices.length;
}
function median(...prices) {
	const half = (prices.length - 1) / 2;
	return (prices[Math.floor(half)] + prices[Math.ceil(half)]) / 2;
}

class InternalsComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			time: 0,
		};
	}

	componentDidMount() {
		const _self = this;
		this.timer = setInterval(
			() =>
				_self.setState({
					time: Date.now() - _self.props.searchSessionCreatedTimestamp.getTime(),
				}),
			100,
		);
	}

	componentWillUnmount() {
		if (this.timer) {
			clearInterval(this.timer);
		}
	}

	render() {
		if (Config.dev_mode) {
			const { time } = this.state;
			const { hotels } = this.props;

			if (!Array.isArray(hotels)) {
				return null;
			}

			const shallow_hotels = hotels.filter((h) => h.shallow).length;
			const searched_hotels = hotels.length - shallow_hotels;
			const deal_hotels = hotels.filter((h) => h.representativeDeal).length;

			const prices = hotels
				.filter((hotel) => _.get(hotel, 'stars', 0) >= 4)
				.map((hotel) => _.get(hotel, 'representativeDeal.pricePerNight', 0))
				.filter((price) => price > 0);

			const average_price = average(...prices);
			const median_price = median(...prices);
			const highest_price = Math.max(...prices);
			const lowest_price = Math.min(...prices);
			let price_spread = highest_price - lowest_price;
			if (price_spread <= 0) {
				price_spread = 1.0;
			}

			const seconds = time / 1000;
			const minutes = Math.floor(seconds / 60);
			const minutes_string = minutes ? `${minutes}m ` : '';
			const seconds_string = `${(seconds % 60).toFixed(1)}s`;

			return (
				<div className="internals-component">
					{/* <span style={{ fontWeight: 'bold', marginRight: 4 }}> */}
					{/* <span>
            Deal hotels: {deal_hotels.toLocaleString()}
          </span>
          <span>
            Searched hotels: {searched_hotels.toLocaleString()}
          </span>
          <span>
            Shallow hotels: {shallow_hotels.toLocaleString()}
          </span>
          <span>
            Total hotels: {hotels.length.toLocaleString()}
          </span> */}
					<div>
						<span>
							w/ deals | searched | total : {deal_hotels.toLocaleString()} |{' '}
							{searched_hotels.toLocaleString()} | {hotels.length.toLocaleString()}
						</span>
						<span>shallow : {shallow_hotels.toLocaleString()}</span>
						<span>
							min | max | (spread) : {!isNaN(lowest_price) ? '$' + lowest_price : '-'} |{' '}
							{!isNaN(highest_price) ? '$' + highest_price : '-'} |{' '}
							{!isNaN(price_spread) ? '($' + price_spread + ')' : '-'}
						</span>
						<span>
							avg | median : {!isNaN(average_price) ? '$' + Math.round(average_price) : '-'} |{' '}
							{!isNaN(median_price) ? '$' + Math.round(median_price) : '-'}
						</span>
						{Config.localhost && (
							<span className="time">
								{minutes_string}
								{seconds_string}
							</span>
						)}
					</div>
				</div>
			);
		}

		return null;
	}
}

export default InternalsComponent;
