import AppDispatcher from '../dispatcher/AppDispatcher';
import { EventEmitter } from 'events';
import RecentSearchesConstants from '../constants/RecentSearchesConstants';
import _ from 'lodash';

import RecentSearchEntity from '../entities/RecentSearch';

// TEMP
var defaultImagesPool = [
	'./img/recent_searches/1_454x340.jpg', //"//assets.regus.com/images/1157/officespace/1_454x340.jpg",
	'./img/recent_searches/lac-trung-software-city-2-520x389.jpg', //"//www.e-architect.co.uk/wp-content/uploads/2009/01/lac-trung-software-city-2-520x389.jpg",
	'./img/recent_searches/City-Plaza-Office-Building-and-Business-Centre-at-Ravala-Blvd-4-Tallinn.-Alver-Trummal-Architects-2004-2006.-Photo-Tiit-Veermae.jpg',
	'./img/recent_searches/Lyra_670.jpg', //"//www.erstegroupimmorent.com/content/a11fdc59/-b6e1-41ca-8cfc-7f5f39f6daf1/Lyra_670.jpg",
	'./img/recent_searches/Low-Energy-Office-Building-CF-Moller-1.jpg', //"//assets.inhabitat.com/wp-content/blogs.dir/1/files/2011/02/Low-Energy-Office-Building-CF-Moller-1.jpg",
	//
	//
	// "//www.crainsnewyork.com/apps/pbcsi.dll/storyimage/CN/20151005/BLOGS03/151009947/AR/0/Falchi-Building.jpg&imageversion=widescreen&maxw=770",
	//
	// "//assets.regus.com/images/1159/businesscentre/1_454x340.jpg",
	//
	// "//media.glassdoor.com/l/54/8a/63/94/general-business-building-sfebb.jpg",
	// "//www.crainsnewyork.com/apps/pbcsi.dll/storyimage/CN/20150717/REAL_ESTATE/150719884/AR/0/AR-150719884.jpg&imageversion=widescreen&maxw=770",
];

// Define initial data points
// var _data = {};
var _isLoading = false;
var _data = [];

function removeRecentSearch(searchId) {
	for (var i = 0; i < _data.length; i++) {
		if (_data[i].id === searchId) {
			return _data.splice(i, 1);
		}
	}
}

function isEqualSearch(lho, rho) {
	let lhoValueOmitted = _.omit(lho, ['id', 'search_timestamp', 'image']);
	let rhoValueOmitted = _.omit(rho, ['id', 'search_timestamp', 'image']);

	return _.isEqual(lhoValueOmitted, rhoValueOmitted);
}

// Method to load result data from mock API
function loadRecentSearchesBoxData(data) {
	_isLoading = false;
	_data = [];
	data.recent_searches.forEach((recentSearch) => {
		_data.push(new RecentSearchEntity(recentSearch));
	});

	// for (var i = 0; i < Math.min(_data.length, defaultImagesPool.length); i++) {
	//   _data[i].image = defaultImagesPool[i];
	// }
	_data.forEach((recentSearch, idx) => {
		recentSearch.image = defaultImagesPool[idx % defaultImagesPool.length];
	});

	// Deduplicate search results
	_data = _.uniqWith(_data, isEqualSearch);
}

// Extend Search Store with EventEmitter to add eventing capabilities
var RecentSearchesBoxStore = _.extend({}, EventEmitter.prototype, {
	// Return search results
	getItems: function () {
		//console.log('RecentSearchesBoxData.getItems', _data);
		return _data;
	},

	// Return # of results in search
	getItemCount: function () {
		return Object.keys(_data).length;
	},

	// TODO: method for currency conversion
	isLoading: function () {
		return _isLoading;
	},

	// Emit Change event
	emitChange: function () {
		this.emit('change');
	},

	// Add change listener
	addChangeListener: function (callback) {
		this.on('change', callback);
	},

	// Remove change listener
	removeChangeListener: function (callback) {
		this.removeListener('change', callback);
	},
});

// Register callback with AppDispatcher
AppDispatcher.register(function (payload) {
	var action = payload.action;
	//var text;

	switch (action.actionType) {
		case RecentSearchesConstants.START_FETCH_RECENT_SEARCHES:
			_isLoading = true;
			break;

		// Respond to RECEIVE_DATA action
		case RecentSearchesConstants.RECEIVE_RECENT_SEARCHES_DATA:
			loadRecentSearchesBoxData(action.data);
			break;

		case RecentSearchesConstants.RECENT_SEARCH_REMOVED:
			console.log('RECENT SEARCH REMOVED IN STORE: ', action.data);
			removeRecentSearch(action.data.id);
			break;

		default:
			return true;
	}

	// If action was responded to, emit change event
	RecentSearchesBoxStore.emitChange();

	return true;
});

export default RecentSearchesBoxStore;
