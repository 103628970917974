import _ from 'lodash';

import React from 'react';
import createClass from 'create-react-class';

import TitleManager from '../../../utils/TitleManager';

import _NameBar from './NameBar.react';

import TravelRequest from './TravelRequest.react';

import _TravelComponents from './TravelComponents.react';

import AdditionalInfo from './AdditionalInfo.react';

import _BookedComponents from './BookedComponents.react';

import Analytics from 'arbitrip-common/client/analytics';

import ManagementConstants from '../../../constants/ManagementConstants';
import ManagementActions from '../../../actions/ManagementActions';
import ManagementStore from '../../../stores/ManagementStore';
import ProfileStore from '../../../stores/ProfileStore';

import Icon from '../../ReactEvilIcon';

import { Paper, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { withRouter } from '../../../utils/withRouter';
import DocumentTitle from '../../general/DocumentTitle.react';

const NameBar = withRouter(_NameBar);

const TravelComponents = withRouter(_TravelComponents);

const BookedComponents = withRouter(_BookedComponents);

const ObjectID_rx = /^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i;

const icon_button_sx = {
	'&:hover': {
		backgroundColor: 'transparent',
	},
};

const tooltip_popper_props = {
	modifiers: [
		{
			name: 'offset',
			options: {
				offset: [0, -25],
			},
		},
	],
};

function canEdit(trip, profile) {
	let travel_manager_can_edit =
		profile.travel_manager &&
		trip.status !== ManagementConstants.TRIP_STATUS.DISCARDED &&
		trip.status !== ManagementConstants.TRIP_STATUS.CANCELED;

	return (
		trip.status === ManagementConstants.TRIP_STATUS.INIT ||
		trip.status === ManagementConstants.TRIP_STATUS.DRAFT ||
		travel_manager_can_edit
	);
}

function getTripRequestPageState() {
	let trip_request = ManagementStore.getTripRequest();
	let profile = ProfileStore.getProfile();

	return {
		trip_request,
		profile,
		saving: ManagementStore.isTripRequestSaving(), // || ManagementStore.isAboutToSave(),
		draft_status: ManagementStore.getDraftStatus(),
		about_to_save: ManagementStore.isAboutToSave(),
		submit_status: ManagementStore.getSubmitStatus(),
		discard_status: ManagementStore.getDiscardStatus(),
		cancel_status: ManagementStore.getCancelStatus(),
		approval_status: ManagementStore.getApprovalStatus(),
		clone_status: ManagementStore.getCloneStatus(),
		cloned_trip_id: ManagementStore.getClonedTripId(),
		load_status: ManagementStore.getTripRequestByIdStatus(),
		busy:
			ManagementStore.getTripRequestByIdStatus() === ManagementConstants.STATUS.BUSY ||
			ManagementStore.getTripRequestByIdStatus() === ManagementConstants.STATUS.INIT,
		valid: ManagementStore.tripRequestIsValid(profile),
		source: ManagementStore.getTripRequestPageSource(),
		previewed_booked_component: ManagementStore.getPreviewedBookedComponent(),
		remove_trip_airfare_status: ManagementStore.getRemoveTripAirfareStatus(),
		remove_trip_accommodation_status: ManagementStore.getRemoveTripAccommodationStatus(),
		server_error: ManagementStore.getTripRequestError(),
		can_edit: canEdit(trip_request, profile),
		pre_approval_needed: ManagementStore.getPreApprovalNeeded(profile),
		post_approval_needed: ManagementStore.getPostApprovalNeeded(profile),
	};
}

var TripRequestPage = createClass({
	displayName: 'TripRequestPage',

	getInitialState: function () {
		let state = getTripRequestPageState();
		state.cancel_modal_open = false;
		state.discard_modal_open = false;
		state.busy = true; //(state.load_status === ManagementConstants.STATUS.BUSY || state.load_status === ManagementConstants.STATUS.INIT);
		return state;
	},

	handleNameOnChange: function (e) {
		ManagementActions.updateName(e.target.value);
	},

	message: null,

	componentDidUpdate(prevProps, prevState) {
		let nextState = this.state;

		if (
			(prevState.draft_status === ManagementConstants.STATUS.BUSY &&
				nextState.draft_status === ManagementConstants.STATUS.SUCCESS) ||
			(prevState.submit_status === ManagementConstants.STATUS.BUSY &&
				nextState.submit_status === ManagementConstants.STATUS.SUCCESS) ||
			(prevState.discard_status === ManagementConstants.STATUS.BUSY &&
				nextState.discard_status === ManagementConstants.STATUS.SUCCESS) ||
			(prevState.cancel_status === ManagementConstants.STATUS.BUSY &&
				nextState.cancel_status === ManagementConstants.STATUS.SUCCESS) ||
			(prevState.approval_status === ManagementConstants.STATUS.BUSY &&
				nextState.approval_status === ManagementConstants.STATUS.SUCCESS) ||
			(prevState.load_status === ManagementConstants.STATUS.BUSY &&
				nextState.load_status === ManagementConstants.STATUS.FAILED) ||
			(prevState.clone_status === ManagementConstants.STATUS.BUSY &&
				nextState.clone_status === ManagementConstants.STATUS.FAILED)
		) {
			const { navigate } = this.props;
			navigate('/trips');
		}
	},

	componentDidMount: function () {
		const { navigate } = this.props;
		const { profile } = this.state;

		let oid = this.props.params && this.props.params.trip_request_id;

		if (ObjectID_rx.test(oid)) {
			setTimeout(function () {
				ManagementActions.getTripRequestById(oid);
			}, 0); // very bad practice
		} else {
			return navigate('/trips');
		}

		ManagementStore.addChangeListener(this._onChange);
		ProfileStore.addChangeListener(this._onChange);
		window.scrollTo(0, 0);

		Analytics.actions.views.tripRequestPage(profile);
	},

	componentWillUnmount: function () {
		ManagementStore.removeChangeListener(this._onChange);
		ProfileStore.removeChangeListener(this._onChange);

		// ManagementActions.resetTripRequest();

		// VERY BAD PRACTICE - HAVE TO FIX THIS
		setTimeout(ManagementActions.clearPreview, 1);
	},

	handleChange: function (date) {
		console.log('changed date:', date);
	},

	handleOnBlur: function (date) {
		console.log('blur event:', date);
	},

	handleOnSaveAsDraftClick: function (e) {
		if (!this.isBusy()) {
			if (ManagementStore.tripRequestIsValid(this.state.profile)) {
				ManagementActions.saveTripRequestAsDraft();
			} else {
				this.setState({
					should_validate: true,
				});
			}
		}
	},

	handleOnSubmitClick: function (e) {
		if (!this.isBusy()) {
			if (this.state.valid) {
				ManagementActions.submitTripRequest();
			} else {
				this.setState({
					should_validate: true,
				});
			}
		}
	},

	handleOnBackClick: function () {
		const { navigate } = this.props;

		if (!this.isBusy()) {
			if (this.state.source) navigate('/' + this.state.source);
			else navigate('/trips');
		}
	},

	handleOnCloseClick: function (e) {
		const { navigate } = this.props;

		navigate('/trips');
	},

	handleOnCancelClick: function (e) {
		if (!this.isBusy()) {
			ManagementActions.cancelTripRequest(this.state.trip_request);
			this.handleCloseCancelModal();
		}
	},

	handleOpenCancelModal: function (e) {
		if (canEdit(this.state.trip_request, this.state.profile)) {
			this.setState({ cancel_modal_open: true });
		}
	},

	handleCloseCancelModal: function (e) {
		this.setState({ cancel_modal_open: false });
	},

	handleOpenDiscardModal: function (e) {
		if (canEdit(this.state.trip_request, this.state.profile)) {
			this.setState({ discard_modal_open: true });
		}
	},

	handleCloseDiscardModal: function (e) {
		this.setState({ discard_modal_open: false });
	},

	handleOnDiscardClick: function (e) {
		if (!this.isBusy()) {
			ManagementActions.discardTripRequest(this.state.trip_request);
			this.handleCloseDiscardModal();
		}
	},

	handleOnSendToApprovalClick: function (e) {
		if (!this.isBusy()) {
			if (this.state.valid) {
				ManagementActions.sendForApproval(this.state.trip_request);
			} else {
				this.setState({
					should_validate: true,
				});
			}
		}
	},

	handleOnSendToPreApprovalClick: function (e) {
		// after rejection, allow resending of pre approval
		if (!this.isBusy()) {
			if (this.state.valid) {
				ManagementActions.sendForPreApproval(this.state.trip_request);
			} else {
				this.setState({
					should_validate: true,
				});
			}
		}
	},

	isBusy() {
		return (
			this.state.draft_status === ManagementConstants.STATUS.BUSY ||
			this.state.submit_status === ManagementConstants.STATUS.BUSY ||
			this.state.discard_status === ManagementConstants.STATUS.BUSY ||
			this.state.cancel_status === ManagementConstants.STATUS.BUSY ||
			this.state.approval_status === ManagementConstants.STATUS.BUSY ||
			this.state.clone_status === ManagementConstants.STATUS.BUSY ||
			this.state.load_status === ManagementConstants.STATUS.BUSY
		);
	},

	handleOnCloneClick: function (e) {
		if (!this.isBusy()) {
			ManagementActions.cloneTrip(this.state.trip_request);
		}
	},

	handleOnClonedStatusChanged: function (new_clone_status, cloned_trip_id) {
		const { navigate } = this.props;

		// TODO! Paz verify issue
		if (new_clone_status === ManagementConstants.STATUS.SUCCESS && cloned_trip_id) {
			setTimeout(() => ManagementActions.clearCloneState(), 1);
			navigate('/trip/' + cloned_trip_id);
			setTimeout(function () {
				ManagementActions.getTripRequestById(cloned_trip_id);
				window.scrollTo(0, 0);
			}, 3); // very bad practice
		}
	},

	updateTravelRequestBorder: function (border) {
		this.setState({
			travel_request_border: border,
		});
	},

	updateTravelComponentsBorder: function (border) {
		this.setState({
			travel_components_border: border,
		});
	},

	render: function () {
		const {
			trip_request,
			profile,
			about_to_save,
			saving,
			load_status,
			valid,
			should_validate,
			server_error,
			can_edit,
			post_approval_needed,
			pre_approval_needed,
		} = this.state;

		const title = TitleManager.buildTitleStartsWith(_.get(trip_request, 'name', ''));

		// let submit_props = valid
		//   ? {onClick: this.handleOnSubmitClick}
		//   : {disabled: true};
		//
		// let send_to_approval_props = valid
		//   ? {onClick: this.handleOnSendToApprovalClick}
		//   : {disabled: true};

		let trip_submitted =
			trip_request.status !== ManagementConstants.TRIP_STATUS.INIT &&
			trip_request.status !== ManagementConstants.TRIP_STATUS.DRAFT;
		let travel_manager_mode = profile.travel_manager && trip_submitted;
		let cancelable =
			profile.travel_manager &&
			trip_submitted &&
			trip_request.status !== ManagementConstants.TRIP_STATUS.DECLINED &&
			trip_request.status !== ManagementConstants.TRIP_STATUS.CANCELED &&
			trip_request.status !== ManagementConstants.TRIP_STATUS.DISCARDED;
		let saving_text = saving ? 'Saving...' : about_to_save ? 'Not Saved' : 'Saved';

		let busy = this.isBusy();
		let lock_action_buttons = saving || about_to_save || busy; //this.state.about_to_save || busy;

		let general_error_message =
			(should_validate && !valid) || server_error
				? 'Please make sure all fields were filled with valid values'
				: '';

		const discard_actions = [
			<Button key={'return-button'} variant="text" color="primary" onClick={this.handleCloseDiscardModal}>
				Return
			</Button>,
			<Button key={'discard-button'} variant="text" color="primary" onClick={this.handleOnDiscardClick}>
				Discard
			</Button>,
		];

		const cancel_actions = [
			<Button key={'return-button'} variant="text" color="primary" onClick={this.handleCloseCancelModal}>
				Return
			</Button>,
			<Button key={'cancel-button'} variant="text" color="primary" onClick={this.handleOnCancelClick}>
				Cancel Trip
			</Button>,
		];
		let actions = travel_manager_mode ? (
			<div className={'actions-container' + (lock_action_buttons ? ' busy' : '')}>
				{trip_request.status !== ManagementConstants.TRIP_STATUS.APPROVED ? (
					trip_request.waiting_for_pre_approval ? (
						<a
							href="#!"
							className="action send-to-approval disabled"
							title="Request has not been approved yet"
						>
							{post_approval_needed ? 'Send to Final Confirmation' : 'Confirm'}
						</a>
					) : pre_approval_needed && !trip_request.waiting_for_pre_approval && !trip_request.pre_approved ? (
						<a href="#!" className="action send-to-approval" onClick={this.handleOnSendToPreApprovalClick}>
							{'Resend to Initial Approval'}
						</a>
					) : (
						<a href="#!" className="action send-to-approval" onClick={this.handleOnSendToApprovalClick}>
							{post_approval_needed ? 'Send to Final Confirmation' : 'Confirm'}
						</a>
					)
				) : null}
				<a href="#!" className="action clone" onClick={this.handleOnCloneClick}>
					Clone Request
				</a>
				<a href="#!" className="action back" onClick={this.handleOnBackClick}>
					{this.state.source === 'new-trip' ? 'Back' : 'Close'}
				</a>
				{general_error_message ? <span className="error">{general_error_message}</span> : null}
				{<span className="saving">{saving_text}</span>}

				{!trip_submitted ? (
					<div>
						{busy ? (
							<i className="fa fa-spin fa-spinner bottom-right-corner busy-indicator" />
						) : can_edit ? (
							<a href="#!" className="action trash" onClick={this.handleOpenDiscardModal}>
								<Icon name="ei-trash" size="s" className="evil-icon" />
							</a>
						) : null}

						<Dialog open={this.state.discard_modal_open}>
							<DialogTitle>Discard Trip</DialogTitle>
							<DialogContent>Are you sure you want to discard {trip_request.name} draft?</DialogContent>
							<DialogActions>{discard_actions}</DialogActions>
						</Dialog>
					</div>
				) : cancelable ? (
					<div>
						{busy ? (
							<i className="fa fa-spin fa-spinner bottom-right-corner busy-indicator" />
						) : can_edit ? (
							<a href="#!" className="action trash" onClick={this.handleOpenCancelModal}>
								<Icon name="ei-trash" size="s" className="evil-icon" />
							</a>
						) : null}
						<Dialog open={this.state.cancel_modal_open}>
							<DialogTitle>Cancel Trip</DialogTitle>
							<DialogContent>Are you sure you want to cancel {trip_request.name}?</DialogContent>
							<DialogActions>{cancel_actions}</DialogActions>
						</Dialog>
					</div>
				) : busy ? (
					<i className="fa fa-spin fa-spinner bottom-right-corner busy-indicator" />
				) : (
					<a href="#!" className="action trash disabled">
						<Icon name="ei-trash" size="s" className="evil-icon" />
					</a>
				)}
			</div>
		) : (
			<div className={'actions-container' + (lock_action_buttons ? ' busy' : '')}>
				{trip_submitted ? (
					<a href="#!" className="action clone" onClick={this.handleOnCloneClick}>
						Clone Request
					</a>
				) : (
					<a href="#!" className="action submit" onClick={this.handleOnSubmitClick}>
						{profile.travel_manager ? 'Create' : 'Submit'}
					</a>
				)}
				<a href="#!" className="action back" onClick={this.handleOnBackClick}>
					{this.state.source === 'new-trip' ? 'Back' : 'Close'}
				</a>
				{/*{*/}
				{/*can_edit*/}
				{/*? null*/}
				{/*: <a className="action close-action" onClick={this.handleOnCloseClick}>*/}
				{/*Close*/}
				{/*</a>*/}
				{/*}*/}
				{general_error_message ? <span className="error">{general_error_message}</span> : null}
				{<span className="saving">{saving_text}</span>}
				{!trip_submitted ? (
					<div>
						{busy ? (
							<i className="fa fa-spin fa-spinner bottom-right-corner busy-indicator" />
						) : can_edit ? (
							<a href="#!" className="action trash" onClick={this.handleOpenDiscardModal}>
								<Icon name="ei-trash" size="s" className="evil-icon" />
							</a>
						) : null}

						<Dialog open={this.state.discard_modal_open}>
							<DialogTitle>Discard Trip</DialogTitle>
							<DialogContent>Are you sure you want to discard {trip_request.name} draft?</DialogContent>
							<DialogActions>{discard_actions}</DialogActions>
						</Dialog>
					</div>
				) : cancelable && can_edit ? (
					<div>
						<a href="#!" className="action trash" onClick={this.handleOpenCancelModal}>
							<Icon name="ei-trash" size="s" className="evil-icon" />
						</a>
						<Dialog open={this.state.cancel_modal_open}>
							<DialogTitle>Discard Trip</DialogTitle>
							<DialogContent>Are you sure you want to cancel {trip_request.name}?</DialogContent>
							<DialogActions>{cancel_actions}</DialogActions>
						</Dialog>
					</div>
				) : can_edit ? (
					<a href="#!" className="action trash disabled">
						<Icon name="ei-trash" size="s" className="evil-icon" />
					</a>
				) : null}
			</div>
		);

		let paper = this.state.busy ? (
			<Paper elevation={2} className="trip-request paper full-height busy">
				<i className="fa fa-spin fa-spinner" />
			</Paper>
		) : travel_manager_mode ? (
			<Paper elevation={2} className="trip-request paper full-height">
				<TravelRequest
					trip_request={trip_request}
					profile={profile}
					borderHook={this.updateTravelRequestBorder}
					can_edit={can_edit}
					should_validate={should_validate}
				/>

				{this.state.travel_request_border ? null : <hr className="separator header" />}

				<BookedComponents
					trip={trip_request}
					profile={profile}
					canCreate={true}
					previewed_booked_component={this.state.previewed_booked_component}
					remove_trip_airfare_status={this.state.remove_trip_airfare_status}
					remove_trip_accommodation_status={this.state.remove_trip_accommodation_status}
					can_edit={can_edit}
					tooltipPopperProps={tooltip_popper_props}
					iconButtonSx={icon_button_sx}
				/>

				{this.state.travel_components_border ? null : <hr className="separator header" />}

				<TravelComponents
					trip_request={trip_request}
					profile={profile}
					load_status={load_status}
					borderHook={this.updateTravelComponentsBorder}
					travel_manager_mode={travel_manager_mode}
					can_edit={can_edit}
					should_validate={should_validate}
					tooltipPopperProps={tooltip_popper_props}
					iconButtonSx={icon_button_sx}
				/>

				{this.state.travel_components_border ? null : <hr className="separator thin" />}

				{actions}
			</Paper>
		) : (
			<Paper elevation={2} className="trip-request paper full-height">
				<TravelRequest
					trip_request={trip_request}
					profile={profile}
					can_edit={can_edit}
					should_validate={should_validate}
				/>

				{trip_request.hasItinerary() ? <hr className="separator header" /> : null}
				{trip_request.hasItinerary() ? (
					<BookedComponents
						trip={trip_request}
						profile={profile}
						canCreate={false}
						previewed_booked_component={this.state.previewed_booked_component}
						can_edit={can_edit}
						tooltipPopperProps={tooltip_popper_props}
						iconButtonSx={icon_button_sx}
					/>
				) : null}

				<hr className="separator header" />

				<TravelComponents
					trip_request={trip_request}
					profile={profile}
					load_status={load_status}
					can_edit={can_edit}
					should_validate={should_validate}
					tooltipPopperProps={tooltip_popper_props}
					iconButtonSx={icon_button_sx}
				/>

				<hr className="separator" />

				<AdditionalInfo trip_request={trip_request} can_edit={can_edit} />

				<hr className="separator thin" />

				{actions}
			</Paper>
		);

		return (
			<DocumentTitle title={title}>
				<div className="trip-request-page">
					<NameBar
						busy={this.state.busy}
						profile={profile}
						trip_request={trip_request}
						can_edit={can_edit}
						should_validate={should_validate}
					/>
					{paper}
				</div>
			</DocumentTitle>
		);
	},

	_onChange: function () {
		this.setState(getTripRequestPageState());
		this.handleOnClonedStatusChanged(this.state.clone_status, this.state.cloned_trip_id);
	},
});
export default withRouter(TripRequestPage);
