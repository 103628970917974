import { Typography } from '@mui/material';

const TravelersComponent = ({ travelers }) => {
	const openMoreTravelers = (e) => {
		// TODO: open more travelers name
	};

	const travelerNames = travelers.map((traveler) => traveler.full_name);
	const comp = [<span key="travelers">{travelerNames.slice(0, 2).join(', ')}</span>];

	if (travelerNames.length > 2) {
		comp.push(<br key="br" />);
		comp.push(
			<span key="more">
				<a href="#!" onClick={openMoreTravelers}>
					{'+' + (travelerNames.length - 2) + ' more'}
				</a>
			</span>,
		);
	}

	return <Typography>{comp}</Typography>;
};

export default TravelersComponent;
