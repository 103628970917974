import React from 'react';
import { useNavigate } from 'react-router';

import _ from 'lodash';
import TravelerIcon from './TravelerIcon';

const Travelers = ({ travelers, bookingId }) => {
	const navigate = useNavigate();

	const formatTravelers = (travelers) => {
		if (!_.isArray(travelers) || travelers.length === 0) return '';

		const travelersCount = travelers.length;
		const [firstTraveler, secondTraveler] = travelers;

		if (travelersCount === 1) return firstTraveler.full_name;
		if (travelersCount === 2) return `${firstTraveler.full_name}, ${secondTraveler.full_name}`;

		const otherTravelersCount = travelersCount - 2;
		const otherTravelersText =
			otherTravelersCount === 1 ? 'and 1 other guest' : `and ${otherTravelersCount} other guests`;

		return `${firstTraveler.full_name}, ${secondTraveler.full_name} ${otherTravelersText}`;
	};

	const handleClick = () => {
		navigate('/bookings', { state: { id: bookingId }, replace: true });
	};

	return (
		<div className="travelers">
			<div className="travelers__title">
				<TravelerIcon />
				<div className="travelers__title-text">Travelers:</div>
			</div>
			<div className="travelers__names">{formatTravelers(travelers)}</div>
			<div className="go-to-booking-page" onClick={handleClick}>
				For the entire guest list - go to the booking page
			</div>
		</div>
	);
};

export default Travelers;
