import { useState, useEffect, useRef } from 'react';
import { TableRow, TableCell, Grow, TextField, InputAdornment, Tooltip } from '@mui/material';
import { ReactComponent as WorldWideIcon } from '../../../../img/travel_policy/worldwide.svg';
import { StyledPrimaryActionButton, StyledCancelButton } from './styled/buttons';
import Currencies from '../../../../utils/Currencies';
import TravelPolicyStore from '../../../../stores/TravelPolicyStore';
import TravelPolicyActions from '../../../../actions/TravelPolicyActions';
import TravelPolicyConstants from '../../../../constants/TravelPolicyConstants';

const getState = () => ({
	editingDefaultStatus: TravelPolicyStore.getEditingDefaultStatus(),
});

const WorldWideRow = ({ travelPolicy }) => {
	const [state, setState] = useState(getState());
	const { editingDefaultStatus } = state;
	const [isEditing, setIsEditing] = useState(false);
	const [defaultPrice, setDefaultPrice] = useState('');
	const [isSaving, setIsSaving] = useState(false);
	const defaultPriceInputRef = useRef(null);

	useEffect(() => {
		TravelPolicyStore.addChangeListener(_onChange);

		return () => {
			TravelPolicyStore.removeChangeListener(_onChange);
		};
	}, []);

	useEffect(() => {
		if (travelPolicy?.default?.price !== undefined) {
			setDefaultPrice(travelPolicy.default.price);
		}
	}, [travelPolicy]);

	useEffect(() => {
		if (editingDefaultStatus === TravelPolicyConstants.STATUS.BUSY) {
			setIsSaving(true);
		} else if (isSaving) {
			setIsSaving(false);
		}
	}, [editingDefaultStatus, isSaving]);

	useEffect(() => {
		if (isEditing && defaultPriceInputRef.current) {
			defaultPriceInputRef.current.select();
		}
	}, [isEditing]);

	useEffect(() => {
		const handleKeyDown = (event) => {
			if (event.key === 'Escape') {
				if (isEditing) {
					handleCancelClick();
				}
			}
		};

		const handleKeyPress = (event) => {
			if (event.key === 'Enter') {
				const activeElement = document.activeElement;
				const isCancelButton =
					activeElement &&
					activeElement.classList.contains('cancel') &&
					activeElement.textContent === 'Cancel';

				if (!isCancelButton) {
					if (isEditing && isFormValid()) {
						handleSaveClick();
					}
				}
			}
		};

		window.addEventListener('keydown', handleKeyDown);
		window.addEventListener('keypress', handleKeyPress);

		return () => {
			window.removeEventListener('keydown', handleKeyDown);
			window.removeEventListener('keypress', handleKeyPress);
		};
	}, [isEditing, defaultPrice]);

	const _onChange = () => {
		const newEditingDefaultStatus = TravelPolicyStore.getEditingDefaultStatus();

		setState({
			editingDefaultStatus: newEditingDefaultStatus,
		});
	};

	const handleEditClick = () => {
		setIsEditing(true);
	};

	const handleCancelClick = () => {
		setIsEditing(false);
		setDefaultPrice(travelPolicy?.default.price);
	};

	const isFormValid = () => {
		return defaultPrice > 0;
	};

	const handleSaveClick = () => {
		TravelPolicyActions.editDefaultTravelPolicy({ price: defaultPrice });
		setIsEditing(false);
		setIsSaving(true);
	};

	const renderDefaultPriceOrLoader = () => {
		if (isSaving) {
			return <div />;
		} else if (isEditing) {
			return (
				<TextField
					className="edit-price-input"
					inputRef={defaultPriceInputRef}
					type="text"
					value={defaultPrice}
					onChange={(e) => /^\d*$/.test(e.target.value) && setDefaultPrice(e.target.value)}
					variant="outlined"
					InputProps={{
						startAdornment: (
							<InputAdornment className="currency-symbol" position="start">
								{Currencies.getSymbol(travelPolicy?.default.currency)}
							</InputAdornment>
						),
					}}
				/>
			);
		} else {
			return (
				<Grow in={true} timeout={100}>
					<span>
						{Currencies.getSymbol(travelPolicy?.default.currency)}
						{defaultPrice}
					</span>
				</Grow>
			);
		}
	};

	return (
		<TableRow className="worldwide-row">
			<TableCell className="flag-cell">
				<WorldWideIcon />
			</TableCell>
			<TableCell className="country-name">Worldwide</TableCell>
			<TableCell className="price-cell">
				<div className="entry-row-wrapper flex align-center align-flex-start">
					{renderDefaultPriceOrLoader()}
				</div>
			</TableCell>
			<TableCell className="actions-cell" align="right">
				<div className="entry-row-wrapper flex align-center flex-end">
					{isEditing ? (
						<>
							<StyledCancelButton className="action-button cancel" onClick={handleCancelClick}>
								Cancel
							</StyledCancelButton>
							<StyledPrimaryActionButton
								className="action-button"
								onClick={handleSaveClick}
								disabled={!isFormValid()}
							>
								Save
							</StyledPrimaryActionButton>
						</>
					) : (
						<Tooltip title="Edit default travel policy" arrow placement="top">
							<button className="action-icon edit-button" onClick={handleEditClick} />
						</Tooltip>
					)}
				</div>
			</TableCell>
		</TableRow>
	);
};

export default WorldWideRow;
