/**
 * This file provided by Facebook is for non-commercial testing and evaluation
 * purposes only. Facebook reserves all rights not expressly granted.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL
 * FACEBOOK BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN
 * ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN
 * CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

// eslint-disable-next-line strict
'use strict';

import FixedDataTable from 'fixed-data-table';
import React from 'react';
import Currencies from '../../../utils/Currencies';
import _ from 'lodash';

const { Table, Column, ColumnGroup, Cell } = FixedDataTable;

// const DateCell = ({rowIndex, data, col, ...props}) => (
//   <Cell {...props}>
//     {data.getObjectAt(rowIndex)[col].toLocaleString()}
//   </Cell>
// );
//
// const ImageCell = ({rowIndex, data, col, ...props}) => (
//   <ExampleImage
//     src={data.getObjectAt(rowIndex)[col]}
//   />
// );
//
// const LinkCell = ({rowIndex, data, col, ...props}) => (
//   <Cell {...props}>
//     <a href="#">{data.getObjectAt(rowIndex)[col]}</a>
//   </Cell>
// );

const TextCell = ({ rowIndex, data, col, ...props }) =>
	props.currency ? (
		<Cell {..._.omit(props, ['currency', 'cellClass'])} title={data[rowIndex][col]}>
			<span className={'cell-span ' + props.cellClass}>
				{data[rowIndex][col]
					? Currencies.getSymbol(props.currency) + data[rowIndex][col].toLocaleString()
					: null}
			</span>
		</Cell>
	) : (
		<Cell {..._.omit(props, ['currency', 'cellClass'])} title={data[rowIndex][col]}>
			<span className={'cell-span ' + props.cellClass}>{data[rowIndex][col]}</span>
		</Cell>
	);

class TopOOPBookings extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			dataList: props.data,
		};

		this.displayName = 'TopOOPBookings';
	}

	componentDidUpdate(prevProps) {
		if (this.props.dataList !== prevProps.dataList) {
			this.setState({
				dataList: this.props.dataList,
			});
		}
	}

	render() {
		var { dataList } = this.state;
		var unifiedHeight = 30;

		// var travelerNameHeight = 240;
		// var number_of_room_nights = 96;
		// var totalSpendHeight = 160;

		var travelerNameProps = _.extend({}, this.props, {
			cellClass: 'traveler-name',
		});

		// var number_of_room_nights_props = _.extend({}, this.props, {
		//     cellClass: "number-of-room-nights"
		// });

		// var totalSpentProps = _.extend({}, this.props, {
		//     // currency: this.props.currency,
		//     cellClass: "total-spent",
		// });

		return (
			<Table
				rowHeight={unifiedHeight}
				groupHeaderHeight={unifiedHeight}
				headerHeight={unifiedHeight}
				rowsCount={dataList.length}
				width={900} //{travelerNameHeight + number_of_room_nights_height + totalSpendHeight}
				height={dataList.length * unifiedHeight + 2 * unifiedHeight + 2}
				{...this.props}
			>
				<ColumnGroup
					fixed={true}
					header={
						<Cell className="table-header">
							<span className="table-title">Top Out of Policy Reservations </span>
							<span className="table-sort">(by % of deviation)</span>
						</Cell>
					}
				>
					<Column
						header={<Cell className="column-header">Check In</Cell>}
						cell={<TextCell data={dataList} col="checkIn" />}
						fixed={true}
						width={96}
					/>
					<Column
						header={<Cell className="column-header">Check Out</Cell>}
						cell={<TextCell data={dataList} col="checkOut" />}
						fixed={true}
						width={96}
					/>
					<Column
						header={<Cell className="column-header">Traveler(s)</Cell>}
						cell={<TextCell data={dataList} col="travelers" {..._.omit(travelerNameProps, 'currency')} />}
						fixed={true}
						width={196}
					/>
					<Column
						header={<Cell className="column-header">Hotel Name</Cell>}
						cell={<TextCell data={dataList} col="hotelName" />}
						fixed={true}
						width={256}
					/>
					<Column
						header={<Cell className="column-header">City, Country</Cell>}
						cell={<TextCell data={dataList} col="cityCountry" />}
						fixed={true}
						width={160}
					/>
					<Column
						header={<Cell className="column-header">Deviation from policy</Cell>}
						cell={<TextCell data={dataList} col="deviation" {...this.props} />}
						fixed={true}
						width={96}
					/>
				</ColumnGroup>
			</Table>
		);
	}
}

export default TopOOPBookings;
