import React from 'react';
import createClass from 'create-react-class';

import Config from 'arbitrip-common/client/utils/Config';

const RepresentativeBookmark = createClass({
	displayName: 'RepresentativeBookmark',

	styles: {
		component: {
			position: 'absolute',
			left: '4px',
			top: '-1px',
		},
		bookmark: {
			color: '#D0D1D2',
			fontSize: '24px',
			position: 'relative',
			marginTop: '-2px',
		},
		provider: {
			color: 'black',
			fontSize: '13px',
			position: 'absolute',
			left: '3px',
			top: '4px',
		},
	},

	render() {
		if (!Config.dev_mode) {
			return null;
		}

		let { styles } = this;

		return (
			<div style={styles.component}>
				<i className="fa fa-bookmark" aria-hidden="true" style={styles.bookmark}>
					<i className="fa fa-star-o" aria-hidden="true" style={styles.provider} />
				</i>
			</div>
		);
	},
});

export default RepresentativeBookmark;
