const FAQ = [
    {
        title: 'מי אנחנו?',
        secondary: [
            {
                description: `Let'Stay הוא אתר הזמנת בתי מלון ישראלי המופעל ע״י חברת אריגאטו טכנולוגיות תיירות מתקדמות בע״מ, הפעילה מאז שנת 2015 בתחום התיירות.\n\n
                החברה פיתחה טכנולוגיה להזמנת חדרים אשר סורקת מיליוני מלונות ודירות ברחבי העולם, מזהה מחירים נמוכים ממחיר השוק, ומאפשרת לאתר את המלונות המתאימים ביותר לנסיעה, תוך הבטחה למציאת מחיר משתלם.\n\n
                החברה פעילה בעולם נסיעות העסקים ועובדת עם אלפי חברות בכל העולם.\n\n
בשנה האחרונה החלה החברה לפעול בתחום הנסיעות הפרטיות תחת המותג Let'Stay, זאת בזכות הטכנולוגיה המתקדמת שפיתחה והכוח המסחרי הגדול שצברה בעולם.`
            }
        ]
    },
    {
        title: 'איך המחירים נמוכים כל כך, והאם הם כוללים מיסים?',
        secondary: [
            {
                title: 'כן, זו לא טעות - המחירים באמת נמוכים!',
                description: `Let'Stay מצליחה להציע מחירים כל-כך נמוכים בזכות השילוב בין טכנולוגיה ייחודית שפיתחנו וכוח מסחרי משמעותי, מה שמבטיח שתקבלו מחירים מצוינים ששמורים בד"כ רק לארגונים גדולים או סוכני נסיעות.\n\n
המחירים המוצגים באתר כוללים את כל המיסים והחיובים, מלבד מיסים מקומיים ועמלות לתשלום ישירות במלון, כמפורט בעמוד ההצעה טרם ביצוע ההזמנה.\n\n
כשאתם משווים את המחירים באתר לאתרי תיירות אחרים, חשוב להשוות את המחיר הסופי כולל כל המיסים והחיובים.\n\n
בכל שאלה בנושא ניתן לפנות אלינו דרך עמוד "צור קשר" באתר ואנחנו נשמח לסייע.`
            }
        ]
    },
    {
        title: 'מדוע נדרש להזין כתובת מייל כדי להיכנס לאתר?',
        secondary: [
            {
                description: `שאלה מצוינת!\n\n
אצלנו ב Let'Stay אנחנו מחוברים לעשרות ספקי מלונאות מכל העולם, שמאפשרים לנו להציע מחירים נמוכים משמעותית ממחירי השוק הרגילים. \n\n
ישנו רק תנאי אחד מצד הספקים - עלינו להגביל את הצגת המחירים המיוחדים הללו רק למשתמשים רשומים. לכן אנו מבקשים מכל משתמש להזין כתובת מייל בעת הכניסה לאתר, \n\n
כדי לאפשר לכם ליהנות ממלוא ההטבות והמחירים המשתלמים שאנו יכולים להציע.`
            }
        ]
    },
    {
        title: 'מתי מתבצע התשלום על ההזמנה?',
        secondary: [
            {
                description: `'התשלום הוא מיידי על מלוא סכום ההזמנה.\n\n
כאשר המערכת מציעה זאת, ניתן לבחור לשלם באמצעות עד 3 תשלומים ללא ריבית, כל עוד שההזמנה עדיין בטווח התאריכים בו ניתן לבטלה ללא עלות.\n\n
לדוגמא, בוצעה הזמנה בתאריך ה-5.5. ההזמנה ניתנת לביטול ללא עלות, עלות ההזמנה 7500 ש"ח ומועד ההגעה למלון הינו ה-2.7. במקרה זה ניתן לשלם בתשלומים.\n\n
במקרה של הזמנה עם אופציה לביטול ללא עלות, אם תתקבל בקשת ביטול במערכת עד לתאריך הביטול האחרון המצוין בעת ההזמנה, ההזמנה תבוטל מיידית והזיכוי בכרטיס האשראי ישתקף תוך מספר ימי עסקים.`

            }
        ]
    },
    {
        title: 'האם ניתן לערוך שינויים בשם ההזמנה ו/או תאריכי ההזמנה?',
        description: 'ישנן מספר אפשרויות:',
        secondary: [
            {
                title: 'במקרה שההזמנה עדיין ניתנת לביטול ללא עלות:',
                description: 'הדרך הפשוטה ביותר היא לבצע הזמנה חדשה עם הפרטים המעודכנים, לאחר קבלת אישור על ההזמנה החדשה, ניתן לבטל את ההזמנה הישנה.'
            },
            {
                title: 'במקרה שההזמנה בסטטוס של דמי ביטול מלאים:',
                description: `יש ליצור קשר עם צוות השירות (כמוגדר בעמוד "צור קשר") ואנו ניצור קשר עם בית המלון ונבקש לבצע את השינויים הרצויים.`
            }
        ]
    },
    {
        title: 'כיצד אוכל  להוסיף לילה/לילות נוספים להזמנה קיימת?',
        description: '',
        secondary: [
            {
                title: 'במידה וההזמנה ניתנת לביטול ללא עלות',
                description: 'ניתן לבצע הזמנה חדשה עם התאריכים המעודכנים ולאחר אישורה לבטל את ההזמנה הישנה.'
            },
            {
                title: 'במידה וההזמנה המקורית אינה ניתנת לביטול או שמכל סיבה אחרת רוצים להוסיף על הקיים',
                description: 'יש לבצע הזמנה נוספת על הלילות המבוקשים לאותו סוג החדר המוזמן ולציין בהערות כי מדובר בהארכת הזמנה קיימת.' +
                    'צוות השירות יפנה ישירות אל בית המלון ויעדכן אותם על איחוד הזמנה קיימת על מנת למנוע מעבר בין חדרים.\n' +
                    'במידה ויוזמן סוג חדר שונה מסוג החדר המוגדר בהזמנה המקורית, יידרש הנוסע לעבור חדר במהלך שהותו.\n'
            }
        ]
    },
    {
        title: 'הזמנתי חדר בלבד ואני מעוניין להוסיף ארוחת בוקר-כיצד ניתן לבצע?',
        description: '',
        secondary: [
            {
                title: 'במידה וההזמנה ניתנת לביטול ללא עלות',
                description: 'ניתן לבצע הזמנה חדשה הכוללת ארוחת בוקר. לאחר אישור סופי על הזמנה החדשה- ניתן לבטל את ההזמנה הישנה.'
            },
            {
                title: 'ניתן לפנות ישירות לבית המלון לטיפול עצמאי בנושא (תשלום ישירות לבית המלון)'
            }
        ],
    },
    {
        title: 'הטיסה שלי נוחתת בשעה מאוחרת, האם עלי להודיע למלון?',
        secondary: [
            {
                description: `בהחלט! אם אם אתם צפויים להגיע למלון בשעה מאוחרת, חשוב להודיע על כך מראש לצוות השירות שלנו, על מנת להבטיח שהחדר שלכם יישמר והזמנתכם לא תבוטל ע״י בית המלון. \n\n
מלונות רבים נוהגים לבטל הזמנות של אורחים שלא מגיעים עד שעות הערב (No Show), למרות שההזמנה שולמה מראש.
`
            }
        ]
    },
    {
        title: 'כיצד ניתן לבטל הזמנה שביצעתי?',
        secondary: [
            {
                title: 'במידה וההזמנה ניתנת לביטול ללא עלות',
                description: 'ניתן לבטלה באופן עצמאי דרך המערכת:\n' +
                    'דרך עמוד "ההזמנות שלי", בחירת ההזמנה הרצויה, ולחיצה על " ביטול הזמנה בתחתית מסך פירוט ההזמנה. '
            },
            {
                title: 'במידה וההזמנה בסטטוס של דמי ביטול מלאים',
                description: `יש לפנות לצוות התמיכה שלנו כמוגדר בעמוד "צור קשר" על מנת שנפנה למלון וננהל מולו מו"מ על החזר חלקי/מלא.\n\n
                    חשוב לדעת - אנחנו נעשה כמיטב יכולתנו, אך בסופו של דבר ההחלטה האם לזכות הזמנה שכוללת דמי ביטול הינה של בית המלון בלבד.\n\n
על מנת להגדיל את סיכויי ההצלחה, רצוי לספק סיבה מוצדקת ולצרף מסמכים והוכחות אותם נוכל להעביר לבית המלון יחד עם הבקשה.`
            }
        ]
    },
    {
        title: 'אני לא מוצא מלון ספציפי במערכת - תוכלו לעזור לי?',
        secondary: [
            {
                description: `אנחנו מציעים למעלה ממיליון בתי מלון ודירות הארחה בכל רחבי העולם. עם זאת, יכולות להיות מספר סיבות שמלון ספציפי לא יחזור בעמוד התוצאות:\n\n
המלון נמצא בתפוסה מלאה ואין חדרים פנויים - במקרה כזה המלון לא יופיע בעמוד התוצאות.1\n\n
המלון שינה את שמו או את הבעלות - במקרה כזה ניתן לחפש מה שם המלון בגוגל או באתר המלון.2\n\n
בכל מקרה בו אתם לא מוצאים את המלון המבוקש, ניתן לשלוח אלינו, כמוגדר בעמוד "צור קשר", את שם בית המלון, תאריכים רצויים ומספר חדרים אשר אתם מעוניינים להזמין ואנחנו נבצע בדיקה האם המלון זמין ונעדכן אתכם בהקדם.`
            }
        ]
    },
    {
        title: 'היכן ניתן לצפות בחשבוניות עבור ההזמנות שלי?',
        description: '',
        secondary: [
            {
                description: `לאחר ביצוע ההזמנה, נשלחת החשבונית ישירות לכתובת הדוא״ל (או מייל בעברית) אותה הזנתם בעת ההזמנה.\n\n
ניתן לצפות בחשבונית גם דרך עמוד "ההזמנות שלי", ע״י בחירת ההזמנה הרצויה ולחיצה על "הצג חשבונית".\n\n
במידה ואינכם מוצאים את החשבונית, פנו אלינו דרך עמוד "צור קשר" ואנחנו נשלח אליכם את המסמך.`
            }
        ]
    },
];

module.exports = {
    FAQ
};