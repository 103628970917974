import _ from 'lodash';

import moment from 'moment';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Button, Chip, IconButton, Stack, Switch, TextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { ReactComponent as ResetIcon } from '../../../img/bookings/reset.svg';
import { ReactComponent as SearchIcon } from '../../../img/bookings/search.svg';

const format = 'DD/MM/YYYY';

const RequestsFilterComponent = ({
	initFilters,
	filters,
	setFilters,

	where,
	setWhere,

	handleDeleteTag,

	initPagination,
	setPagination,
}) => {
	const [startOpen, setStartOpen] = useState(false);
	const [endOpen, setEndOpen] = useState(false);

	const [showArchived, setShowArchived] = useState(filters.show_archived);

	const clearAllClick = () => {
		setPagination(initPagination);
		setFilters(initFilters);
	};

	const onSearchClick = () => {
		if (_.isEmpty(where)) {
			return;
		}

		setPagination(initPagination);
		setWhere(initFilters.where);
		setFilters({ ...filters, where });
	};

	const onSearchEnterClick = (e) => {
		if (e.key === 'Enter') {
			onSearchClick();
		}
	};

	const getFilterName = (key) => {
		return _.startCase(key);
	};

	const getLabel = (key) => {
		return moment.isMoment(filters[key]) ? filters[key].format(format) : filters[key];
	};

	const getTags = () => {
		return Object.keys(filters).map((key, index) => {
			const filter_name = getFilterName(key);

			const label = getLabel(key);

			const labelRender = (
				<div className="tag">
					<div>{filter_name}:</div>
					<div>{label}</div>
				</div>
			);

			return (
				!_.isEmpty(filters[key]) && (
					<Chip label={labelRender} key={`tag-${index}`} onDelete={() => handleDeleteTag(key)} />
				)
			);
		});
	};

	const hasTags = () => {
		return _.some(filters, (value) => !_.isEmpty(value));
	};

	return (
		<div className={classNames('bookings-filters-component', { tags: hasTags() })}>
			<div className="bookings-filters-component__filters">
				<div className="bookings-filters-component__filter search space-right-sm">
					<label htmlFor="search-input">Where</label>
					<TextField
						id="search-input"
						placeholder="Filter by destination or hotel name"
						variant="outlined"
						InputProps={{
							endAdornment: (
								<IconButton aria-label="Search" onClick={onSearchClick}>
									<SearchIcon />
								</IconButton>
							),
						}}
						value={where}
						onChange={(e) => setWhere(e.target.value)}
						onKeyDown={onSearchEnterClick}
					/>
				</div>

				<div className="bookings-filters-component__filter when space-right-sm">
					<label htmlFor="when-input">When</label>
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<DesktopDatePicker
							onClose={() => setStartOpen(false)}
							open={startOpen}
							className="desktop-date-picker"
							slotProps={{
								textField: {
									InputProps: {
										onClick: (e) => {
											e.preventDefault();
											setStartOpen(true);
										},
										onKeyDown: (e) => {
											e.preventDefault();
											setStartOpen(true);
										},
									},
								},
							}}
							label="Start"
							format={format}
							value={filters.start_date}
							onChange={(date) => {
								setFilters({
									...filters,
									start_date: date,
									// if start_date date is after end_date data, reset end_date date
									...(date.isAfter(filters.end_date) && { end_date: initFilters.end_date }),
								});
							}}
						/>
					</LocalizationProvider>
				</div>

				<div className="bookings-filters-component__filter when no-label space-right-md">
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<DesktopDatePicker
							onClose={() => setEndOpen(false)}
							open={endOpen}
							className="desktop-date-picker"
							slotProps={{
								textField: {
									InputProps: {
										onClick: (e) => {
											e.preventDefault();
											setEndOpen(true);
										},
										onKeyDown: (e) => {
											e.preventDefault();
											setEndOpen(true);
										},
									},
								},
							}}
							label="End"
							format={format}
							value={filters.end_date}
							minDate={filters.start_date}
							onChange={(date) => setFilters({ ...filters, end_date: date })}
						/>
					</LocalizationProvider>
				</div>

				<div className="bookings-filters-component__filter clear no-label space-right-sm toggle">
					<Button
						variant="outlined"
						size="large"
						onClick={() => {
							setShowArchived(!showArchived);
							setPagination(initPagination);
							setFilters({
								...filters,
								show_archived: !showArchived,
							});
						}}
					>
						<Switch
							checked={showArchived}
							label={{
								inputProps: { 'aria-label': 'Show Archived' },
							}}
						/>{' '}
						Show Archived
					</Button>
				</div>

				<div className="bookings-filters-component__filter clear no-label space-right-sm end">
					<Button
						id="clear-input"
						variant="outlined"
						size="large"
						startIcon={<ResetIcon />}
						onClick={clearAllClick}
					>
						Clear All
					</Button>
				</div>
			</div>
			<div className="bookings-filters-component__tags">
				<Stack direction="row" spacing={1}>
					{getTags()}
				</Stack>
			</div>
		</div>
	);
};

export default RequestsFilterComponent;
