const { getDateInFormat, getDateInIso } = require('../../utils/Dates');
const _ = require("lodash");

const getBookingObject = (booking) => {
    if (!booking) {
        return;
    }

    const check_in = _.get(booking, 'deal.details.check_in')
    const check_out = _.get(booking, 'deal.details.check_out')
    const cancellation_date = _.get(booking, 'deal.dca.dealIsFullyRefundableTill')
    const currency = _.get(booking, 'payment.currency')
    const points_provider = _.get(booking, 'payment.loyalty.points_provider')

    return {
        ...(booking.id && { reservation_id: booking.id }),
        ...(booking.childrenAges && { children_ages: booking.childrenAges }),
        ...(booking.guests && { guests: booking.guests }),
        ...(booking.nights && { nights: booking.nights }),
        ...(booking.price && { total_price: booking.price }),
        ...(booking.roomDescription && { room_description: booking.roomDescription }),
        ...(booking.short_id && { short_id: booking.short_id }),
        ...(currency && { currency }),
        ...(points_provider && { points_provider }),
        ...(check_in && { check_in: getDateInFormat(check_in) }),
        ...(check_out && { check_out: getDateInFormat(check_out) }),
        ...(check_in && { check_in_date: getDateInIso(check_in) }),
        ...(check_out && { check_out_date: getDateInIso(check_out) }),
        ...(cancellation_date && { cancellation_date: getDateInFormat(cancellation_date) }),
    };
};

module.exports = {
    getBookingObject
};