import ReportsConstants from '../constants/ReportsConstants';

import AppDispatcher from '../dispatcher/AppDispatcher';
import _ from 'lodash';
import moment from 'moment';
import ReportEntitities from '../entities/Report';
import { EventEmitter } from 'events';

const { Report, MonthlyReport } = ReportEntitities;

// Define initial data points
var _data = {};
var _graphsData = [];
var _graphsDataObject = {};

var _status = ReportsConstants.STATUS.INITIAL;

var _startDate = moment().subtract(1, 'month').startOf('month').toDate();
var _endDate = moment().add(1, 'month').endOf('month').toDate();
// var _startDate = moment().subtract(1, 'month').startOf('month').toDate();
// var _endDate = moment().subtract(1, 'month').endOf('month').toDate();

let _client_contract_id;

// Method to load result data from mock API
function loadReportsData(data) {
	_data = new Report(data);
}

function loadGraphsData(data) {
	for (var month_year in data) {
		_graphsDataObject[month_year] = new MonthlyReport(month_year, data[month_year]);
	}

	_graphsData = [];
	for (var key in _graphsDataObject) {
		_graphsData.push(_graphsDataObject[key]);
	}

	_graphsData.sort(function (a, b) {
		return a.year * 100 + a.month - (b.year * 100 + b.month);
	});

	// console.log("sorted out", _graphsData);
}

// Extend Search Store with EventEmitter to add eventing capabilities
var ReportsStore = _.extend({}, EventEmitter.prototype, {
	getStartDate: function () {
		return _startDate;
	},

	getEndDate: function () {
		return _endDate;
	},

	getClientContractId: function () {
		return _client_contract_id;
	},

	getReportsSummary: function () {
		return _data;
	},

	getReportsGraphsData: function () {
		return _graphsData;
	},

	getStatus: function () {
		return _status;
	},

	// TODO: method for currency conversion

	// Emit Change event
	emitChange: function () {
		this.emit('change');
	},

	// Add change listener
	addChangeListener: function (callback) {
		this.on('change', callback);
	},

	// Remove change listener
	removeChangeListener: function (callback) {
		this.removeListener('change', callback);
	},
});

// Register callback with AppDispatcher
AppDispatcher.register(function (payload) {
	var action = payload.action;
	//var text;

	switch (action.actionType) {
		case ReportsConstants.UPDATE_REPORTS_START_DATE:
			_startDate = action.data;
			break;

		case ReportsConstants.UPDATE_REPORTS_END_DATE:
			_endDate = action.data;
			break;

		case ReportsConstants.UPDATE_REPORTS_CLIENT_CONTRACT_ID:
			_client_contract_id = action.data;
			break;

		case ReportsConstants.UPDATE_REPORTS_DATES:
			_startDate = action.data.startDate;
			_endDate = action.data.endDate;
			_client_contract_id = action.data.client_contract_id;
			break;

		////////////////////////////////////////////////////////////////////////////////

		case ReportsConstants.RETRIEVE_REPORTS:
			_status = ReportsConstants.STATUS.BUSY;
			// action.data.startDate
			// action.data.endDate
			break;

		// Respond to RECEIVE_DATA action
		case ReportsConstants.REPORTS_RETRIEVAL_SUCCEEDED:
			loadReportsData(action.data);
			_status = ReportsConstants.STATUS.SUCCESS;
			break;

		case ReportsConstants.REPORTS_RETRIEVAL_FAILED:
			// TODO: handle...
			_status = ReportsConstants.STATUS.FAILED;
			break;

		////////////////////////////////////////////////////////////////////////////////

		case ReportsConstants.RETRIEVE_GRAPHS_DATA:
			_status = ReportsConstants.STATUS.BUSY;
			_graphsDataObject = {};
			var startDate = moment(action.data.startDate);
			var endDate = moment(action.data.endDate);
			var cur = moment(startDate);
			while (cur.isBefore(endDate)) {
				var month_year = cur.format('MMM-YY');
				_graphsDataObject[month_year] = new MonthlyReport(month_year, {
					currency: (_data && _data.currency) || 'USD', // TODO: VERIFY!

					trips: 0,
					total_nights: 0,
					total_spent: 0,
					total_spent_one_night: 0,
					avg_night_price: 0,

					year: cur.year(),
					month: cur.month() + 1,
					label: cur.format('MMMM'),
				});
				cur.add(1, 'month');
			}
			break;

		// Respond to RECEIVE_DATA action
		case ReportsConstants.GRAPHS_DATA_RETRIEVAL_SUCCEEDED:
			loadGraphsData(action.data);
			_status = ReportsConstants.STATUS.SUCCESS;
			break;

		case ReportsConstants.GRAPHS_DATA_RETRIEVAL_FAILED:
			_status = ReportsConstants.STATUS.FAILED;
			break;

		////////////////////////////////////////////////////////////////////////////////

		default:
			return true;
	}

	// If action was responded to, emit change event
	ReportsStore.emitChange();

	return true;
});

export default ReportsStore;
