import _ from 'lodash';
import classNames from 'classnames';
import withStyles from '@mui/styles/withStyles';

import React from 'react';
import PropTypes from 'prop-types';

const styles = {
	match: {
		borderBottom: '1px solid #d4d4d4',
		paddingBottom: 6,
	},
	originalName: {
		color: 'gray',
		marginBottom: 6,
	},
	defaultMatch: {
		color: 'purple',
		marginTop: 2,
	},
	bestMatch: {
		color: 'green',
	},
	perfectMatch: {
		color: 'green',
	},
	lowerPriceMatch: {
		color: 'green',
	},
	higherScoreMatch: {
		color: 'green',
	},
	catalogMappingMatch: {
		color: 'orange',
	},
	bookingNoMappingMatch: {
		color: 'red',
	},
	noMatch: {
		color: 'red',
	},
	medText: {
		fontSize: 14,
		color: 'gray',
		marginTop: 4,
	},
	smallText: {
		fontSize: 12,
		color: 'gray',
	},
};

class DealRoomMapping extends React.Component {
	constructor(props) {
		super(props);

		this.getScore = this.getScore.bind(this);
		this.getResults = this.getResults.bind(this);
	}

	getScore(roomsMatchDebug) {
		return Number.parseFloat(roomsMatchDebug.match_score).toFixed(2);
	}

	getRoomMappingRender(room, index) {
		const { classes, deals } = this.props;

		let expedia_deal = false;
		if (room.booking_to_expedia_catalog_mapping_match) {
			expedia_deal = _.find(deals, (d) => _.get(d, 'room_id') === _.get(room, 'matches[0].id'));
		}

		return (
			<div
				key={`item-${index}`}
				className={classNames(
					classes.match,
					classes.defaultMatch,
					room.best_match && classes.bestMatch,
					room.perfect_match && classes.perfectMatch,
					room.lower_price_match && classes.lowerPriceMatch,
					room.higher_score_match && classes.higherScoreMatch,
					room.no_match && classes.noMatch,
					room.no_match_jaccard_score && classes.noMatch,
					room.no_match_match_score && classes.noMatch,
					room.booking_no_mapping_match && classes.bookingNoMappingMatch,
				)}
			>
				{room.room_name}
				{room.catalog && <span> | {room.catalog}</span>}
				{room.no_price && <span> | No Price</span>}
				{room.catalog_deal && (
					<React.Fragment>
						<span>
							{' '}
							| {room.catalog_deal.price} {room.catalog_deal.currency}
						</span>
					</React.Fragment>
				)}
				{room.match_score && <span> | Match Score: {this.getScore(room)}</span>}
				{room.jaccard_score && <span> | Jaccard Score: {room.jaccard_score.toFixed(2)}</span>}
				{room.perfect_match && <span> | Perfect Match</span>}
				{room.expedia_match && <span> | Expedia Match</span>}
				{room.expedia_direct_catalog_match && <span> | Catalog Direct Match</span>}
				{room.expedia_booking_mapping_match && <span> | Expedia and Booking 2 Rooms Match</span>}
				{room.booking_to_expedia_catalog_mapping_match && (
					<span className={classes.catalogMappingMatch}>
						{' '}
						| Booking to Expedia Match ({room.room_name_original_booking} > {room.room_name}
						{expedia_deal && ` > ${expedia_deal.pricePerNight}${expedia_deal.currency}`})
					</span>
				)}
				{room.best_match && (
					<span>
						{' '}
						| Best Match (
						<React.Fragment>
							{room.name_match && <span>By name</span>}
							{room.only_match && <span>Only match</span>}
							{room.lower_price_match && <span>By lower price</span>}
							{room.higher_score_match && <span>By higher score</span>}
							{room.multiple_matches && <span>Multiple matches</span>}
							{room.double_match_score_match && <span>Double match score 1.0</span>}
							{room.jaccard_score_match && <span>Jaccard Score 1</span>}
						</React.Fragment>
						)
					</span>
				)}
				{room.no_match && <span> | No Match</span>}
				{room.booking_no_mapping_match && (
					<React.Fragment>
						<br />
						<span>(Booking has no Expedia match)</span>
					</React.Fragment>
				)}
				{room.no_match_multiple_matches && <span> (Multiple Matches)</span>}
				{room.no_match_no_room_name_in_mapping_matches && <span> (Room name is not in mapping matches)</span>}
				{room.no_match_jaccard_score && <span> (Jaccard Score)</span>}
				{room.no_match_match_score && <span> (Match Score)</span>}
				{room.no_match_poi && <span> (Point of View)</span>}
				{room.critical_mismatch_flag && <span> (Critical Mismatch)</span>}
				{room.no_match_one_token_and_less_than_1_jaccard_score && (
					<span> (One token and less than 1.0 jaccard score)</span>
				)}
				<br />
				{room.normalizes_room_name && (
					<div className={classes.medText}>Normalized: {room.normalizes_room_name}</div>
				)}
			</div>
		);
	}

	getResults() {
		const {
			roomMappingData,
			classes,
			//  deal
		} = this.props;

		let roomsMatchDebug = _.get(roomMappingData, 'debug', null);
		let roomsMatchProposals = _.get(roomsMatchDebug, 'proposals', null);
		let originalDealName = _.get(roomsMatchDebug, 'original_deal.name', null);

		let originalDealId = _.get(roomMappingData, 'deal_id', null);
		let originalGroupId = _.get(roomMappingData, 'group_id', null);

		let normalizedProposalName =
			_.get(roomsMatchDebug, 'normalized_proposal_name', null) ||
			_.get(roomsMatchDebug, 'proposals.0.normalized_proposal_name', null);

		return (
			<div>
				{originalDealName && (
					<div className={classes.originalName}>
						Original Name: {originalDealName}
						<br />
						{normalizedProposalName && (
							<div className={classes.medText}>Normalized: {normalizedProposalName}</div>
						)}
						<span className={classes.smallText}>Deal ID: {originalDealId}</span>
						<br />
						<span className={classes.smallText}>Group ID: {originalGroupId}</span>
						<br />
					</div>
				)}
				{roomsMatchDebug && this.getRoomMappingRender(roomsMatchDebug, -1)}
				{roomsMatchProposals &&
					_.map(roomsMatchProposals, (room, index) => {
						return this.getRoomMappingRender(room, index);
					})}
			</div>
		);
	}

	render() {
		const { roomMappingData } = this.props;

		if (roomMappingData) {
			return (
				<div style={{ margin: '4px 0' }} onClick={() => console.log('Mapping Data: ', roomMappingData)}>
					{this.getResults()}
				</div>
			);
		} else {
			return <div />;
		}
	}
}

DealRoomMapping.propTypes = {
	deal: PropTypes.any,
	deals: PropTypes.any,
	roomMappingData: PropTypes.any,
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DealRoomMapping);
