/* eslint-disable react/prop-types */
import React from 'react';
import createClass from 'create-react-class';
import { SmallCheckbox } from '../../general/SmallCheckbox.react';

const DealsStatistics = createClass({
	displayName: 'DealsStatistics',

	styles: {
		component: {
			border: '1px solid lightgrey',
			borderRadius: '4px',
			padding: '4px 4px 4px 15px',
			marginTop: '10px',
		},
		span: {
			paddingRight: '10px',
		},
	},

	render() {
		if (!this.props.display_debugging) {
			return null;
		}

		let { styles, props } = this;

		let ratio = ((props.groups / props.deals) * 100).toFixed(0);
		let average_group_size = (props.deals / props.groups).toFixed(2);
		let superseded_ratio = ((props.superseded_num / props.deals) * 100).toFixed(0);
		return (
			<div style={styles.component}>
				<span style={styles.span}>Deals: {props.deals}</span>
				<span style={styles.span}>Groups: {props.groups}</span>
				<span style={styles.span}>Ratio(G): {ratio}% </span>
				<span style={styles.span}>Avg. Group Size: {average_group_size} </span>
				<span style={styles.span}>Superseded: {props.superseded_num} </span>
				<span style={styles.span}>Ratio(S): {superseded_ratio} % </span>
				<div className={'checkbox-container'} style={{ display: 'inline-block', marginLeft: '46px' }}>
					<SmallCheckbox
						id="show-supersdeded-deals"
						label="Show superseded"
						checked={props.show_superseded_deals}
						onChange={this.props.showSupersededDealsCallback}
					/>
				</div>
				<div className="price-id-container" style={{ display: 'flex' }}>
					<label htmlFor="price_id" style={{ flex: 'none', alignContent: 'center', marginRight: '20px' }}>
						Dynamic pricing group ID
					</label>
					<input
						style={{ maxWidth: '200px' }}
						type="text"
						className="form-control"
						id="price_id"
						value={this.props.dynamicPriceIdFilter}
						onChange={this.props.onFilterByDynamicPriceId}
						placeholder="00123T32r234434343434"
					/>
				</div>
			</div>
		);
	},
});

export default DealsStatistics;
