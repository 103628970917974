import { useState, useEffect } from 'react';
import SearchStore from '../../../stores/SearchStore';
import ResultsStore from '../../../stores/ResultsStore';
import ProfileStore from '../../../stores/ProfileStore';
import SortConstants from '../../../constants/SortConstants';
import SortActions from '../../../actions/SortActions';

import Analytics from 'arbitrip-common/client/analytics';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Config from 'arbitrip-common/client/utils/Config';

const StyledMenuItem = (props) => {
	return (
		<MenuItem
			{...props}
			sx={{
				height: '46px',
			}}
		/>
	);
};

const SortDropdownComponent = () => {
	const [method, setMethod] = useState(ResultsStore.getSortMethod());
	const [profile, setProfile] = useState(ProfileStore.getProfile());

	useEffect(() => {
		const handleChange = () => {
			setMethod(ResultsStore.getSortMethod());
			setProfile(ProfileStore.getProfile());
		};

		ResultsStore.addChangeListener(handleChange);
		return () => ResultsStore.removeChangeListener(handleChange);
	}, []);

	const handleSortChange = (event) => {
		const value = event.target.value;

		const actionMapper = {
			[SortConstants.SORT_METHOD.RECOMMENDED]: SortActions.sortByRecommended,
			[SortConstants.SORT_METHOD.PRICE]: SortActions.sortByPrice,
			[SortConstants.SORT_METHOD.PRICE_HIGH_TO_LOW]: SortActions.sortByPriceHighToLow,
			[SortConstants.SORT_METHOD.DISTANCE]: SortActions.sortByDistance,
			[SortConstants.SORT_METHOD.REVIEWS]: SortActions.sortByReviews,
			[SortConstants.SORT_METHOD.SCORES]: SortActions.sortByScores,
			[SortConstants.SORT_METHOD.MARGIN_PERCENTAGE]: SortActions.sortByMarginPercentage,
			[SortConstants.SORT_METHOD.ABSOLUTE_MARGIN]: SortActions.sortByAbsoluteMargin,
		};

		actionMapper[value]?.();

		Analytics.actions.interactions.sortedResults(profile, value);
	};

	const labelPrefix = 'Sort by:';
	const distanceLabel = SearchStore.isSpecificSearch() ? 'Distance' : 'Distance from Center';

	const menuItemsData = [
		{ value: SortConstants.SORT_METHOD.RECOMMENDED, label: 'Recommended' },
		{ value: SortConstants.SORT_METHOD.PRICE, label: 'Price (Low to High)' },
		{ value: SortConstants.SORT_METHOD.PRICE_HIGH_TO_LOW, label: 'Price (High to Low)' },
		{ value: SortConstants.SORT_METHOD.DISTANCE, label: distanceLabel },
		{ value: SortConstants.SORT_METHOD.REVIEWS, label: 'Review score' },
		...(Config.research_mode
			? [
					{ value: SortConstants.SORT_METHOD.SCORES, label: 'Server(*)' },
					{ value: SortConstants.SORT_METHOD.MARGIN_PERCENTAGE, label: 'Margin(%)' },
					{ value: SortConstants.SORT_METHOD.ABSOLUTE_MARGIN, label: 'Abs(Margin)' },
				]
			: []),
	];

	const getSelectedLabel = (value) => {
		const selectedItem = menuItemsData.find((item) => item.value === value);
		return selectedItem ? `${labelPrefix} ${selectedItem.label}` : '';
	};

	return (
		<FormControl>
			<Select
				value={method}
				onChange={handleSortChange}
				renderValue={(selected) => getSelectedLabel(selected)}
				MenuProps={{
					PaperProps: {
						style: {
							borderRadius: '8px',
						},
					},
				}}
			>
				{menuItemsData.map((item) => (
					<StyledMenuItem key={item.value} value={item.value}>
						{item.label}
					</StyledMenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default SortDropdownComponent;
