import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

class ValidationDialog extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			open: false,
		};

		this.closeModal = this.closeModal.bind(this);
	}

	componentDidUpdate(prevProps) {
		const { open } = this.props;

		if (prevProps.open !== open) {
			this.setState({ open });
		}
	}

	closeModal() {
		const { onClose } = this.props;

		this.setState({
			open: false,
		});

		if (_.isFunction(onClose)) {
			onClose();
		}
	}

	getActions() {
		return [
			<Button key="ok" variant="text" color="primary" onClick={this.closeModal}>
				Got it!
			</Button>,
		];
	}

	render() {
		const { open } = this.state;

		return (
			<Dialog open={open} onClose={() => {}}>
				<DialogTitle>It looks like you forgot something</DialogTitle>
				<DialogContent>
					<div>Please complete all mandatory fields.</div>
				</DialogContent>
				<DialogActions>{this.getActions()}</DialogActions>
			</Dialog>
		);
	}
}

ValidationDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
};

export default ValidationDialog;
