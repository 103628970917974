import AppDispatcher from '../dispatcher/AppDispatcher';
import TravelPolicyConstants from '../constants/TravelPolicyConstants';
import Ajax from '../utils/Ajax';
import Analytics from 'arbitrip-common/client/analytics';

const TravelPolicyActions = {
	getTravelPolicy() {
		AppDispatcher.handleAction({
			actionType: TravelPolicyConstants.RETRIEVE_TRAVEL_POLICIES,
		});

		Ajax.getCompanyTravelPolicies()
			.done(function (res) {
				AppDispatcher.handleAction({
					actionType: TravelPolicyConstants.TRAVEL_POLICIES_RETRIEVAL_SUCCESS,
					data: res,
				});
			})
			.fail(function (err) {
				console.error('ERROR WHILE GETTING TRAVEL POLICIES: ', err);
				AppDispatcher.handleAction({
					actionType: TravelPolicyConstants.TRAVEL_POLICIES_RETRIEVAL_FAILED,
					data: err,
				});
			});
	},

	addTravelPolicyEntry(data) {
		AppDispatcher.handleAction({
			actionType: TravelPolicyConstants.CREATE_TRAVEL_POLICY_ENTRY,
		});

		Ajax.addTravelPolicyEntry(data)
			.done(function (res) {
				AppDispatcher.handleAction({
					actionType: TravelPolicyConstants.TRAVEL_POLICY_ENTRY_CREATION_SUCCESS,
					data: res,
				});

				Analytics.actions.interactions.addedTravelPolicyEntry(data);
			})
			.fail(function (err) {
				console.error('ERROR WHILE CREATING TRAVEL POLICY ENTRY: ', err);
				AppDispatcher.handleAction({
					actionType: TravelPolicyConstants.TRAVEL_POLICY_ENTRY_CREATION_FAILED,
					data: err,
				});

				Analytics.actions.interactions.addedTravelPolicyEntryFailed(data, err);
			});
	},

	editTravelPolicyEntry(entryId, data) {
		AppDispatcher.handleAction({
			actionType: TravelPolicyConstants.EDIT_TRAVEL_POLICY_ENTRY,
		});

		Ajax.editTravelPolicyEntry(entryId, data)
			.done(function (res) {
				AppDispatcher.handleAction({
					actionType: TravelPolicyConstants.TRAVEL_POLICY_ENTRY_EDITION_SUCCESS,
					data: res,
				});

				Analytics.actions.interactions.editedTravelPolicyEntry(entryId, data);
			})
			.fail(function (err) {
				console.error('ERROR WHILE EDITING TRAVEL POLICY ENTRY: ', err);
				AppDispatcher.handleAction({
					actionType: TravelPolicyConstants.TRAVEL_POLICY_ENTRY_EDITION_FAILED,
					data: err,
				});

				Analytics.actions.interactions.editedTravelPolicyEntryFailed(err);
			});
	},

	editDefaultTravelPolicy(data) {
		AppDispatcher.handleAction({
			actionType: TravelPolicyConstants.EDIT_DEFAULT_TRAVEL_POLICY,
		});

		Ajax.editDefaultTravelPolicy(data)
			.done(function (res) {
				AppDispatcher.handleAction({
					actionType: TravelPolicyConstants.DEFAULT_TRAVEL_POLICY_EDITION_SUCCESS,
					data: res,
				});

				Analytics.actions.interactions.editedDefaultTravelPolicy(data);
			})
			.fail(function (err) {
				console.error('ERROR WHILE EDITING DEFAULT TRAVEL POLICY: ', err);
				AppDispatcher.handleAction({
					actionType: TravelPolicyConstants.DEFAULT_TRAVEL_POLICY_EDITION_FAILED,
					data: err,
				});

				Analytics.actions.interactions.editedDefaultTravelPolicyFailed(err);
			});
	},

	removeTravelPolicyEntry(entryId) {
		AppDispatcher.handleAction({
			actionType: TravelPolicyConstants.REMOVE_TRAVEL_POLICY_ENTRY,
		});

		Ajax.removeTravelPolicyEntry(entryId)
			.done(function (res) {
				AppDispatcher.handleAction({
					actionType: TravelPolicyConstants.TRAVEL_POLICY_ENTRY_REMOVAL_SUCCESS,
					data: res,
				});

				Analytics.actions.interactions.removedTravelPolicyEntry(entryId);
			})
			.fail(function (err) {
				console.error('ERROR WHILE REMOVING TRAVEL POLICY ENTRY: ', err);
				AppDispatcher.handleAction({
					actionType: TravelPolicyConstants.TRAVEL_POLICY_ENTRY_REMOVAL_FAILED,
					data: err,
				});

				Analytics.actions.interactions.removedTravelPolicyEntryFailed(entryId, err);
			});
	},

	resetTravelPolicyStatus() {
		AppDispatcher.handleAction({
			actionType: TravelPolicyConstants.RESET_TRAVEL_POLICY_STATUS,
		});
	},
};

export default TravelPolicyActions;
