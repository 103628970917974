import React from 'react';
import CreditCardComponent from '../../reservation-payment/CreditCardComponent.react';
import { getPriceWithCurrency } from 'arbitrip-common/general/utils/Currencies';

const { CURRENCIES } = require('@arbitrip/constants');

const SettlePaymentCreditCard = ({ profile, payment }) => (
	<div className="settle-payment-wrap">
		{payment?.charge_currency === CURRENCIES.ILS && payment?.sum_in_charge_currency && (
			<div className="order-total">
				<div className="order-total-title">Order Total</div>
				<div className="order-total-value">
					{getPriceWithCurrency(payment.sum_in_charge_currency, payment.charge_currency)}
				</div>
			</div>
		)}
		<CreditCardComponent profile={profile} />
	</div>
);

export default SettlePaymentCreditCard;
