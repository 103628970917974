import _ from 'lodash';
import async from 'async';
import moment from 'moment';

import AppDispatcher from '../dispatcher/AppDispatcher';
import ProfileConstants from '../constants/ProfileConstants';
import Ajax from '../utils/Ajax';

function toAjaxPayload(profile) {
	const _date_of_birth = moment({
		month: profile.passport_details.date_of_birth.month - 1,
		day: profile.passport_details.date_of_birth.day,
		year: profile.passport_details.date_of_birth.year,
	});

	let payload = _.extend({}, profile);
	payload.passport_details = _.extend({}, profile.passport_details);
	// payload.passport_details.date_of_birth = _.extend({}, profile.passport_details.date_of_birth);

	if (_date_of_birth.isValid()) {
		console.log('DoB isValid');
		payload.passport_details.date_of_birth = _date_of_birth.toDate();
	} else {
		delete payload.passport_details.date_of_birth;
	}

	return payload;
}

const ProfileActions = {
	toggleArbitripPointsApplied: function (applied) {
		AppDispatcher.handleAction({
			actionType: ProfileConstants.TOGGLE_ARBITRIP_POINTS_APPLIED,
			data: { applied },
		});
	},

	saveProfile: function (profile) {
		AppDispatcher.handleAction({
			actionType: ProfileConstants.SAVE_PROFILE,
		});

		const profile_payload = toAjaxPayload(profile);

		Ajax.saveProfile(profile_payload)
			.done(function (res) {
				AppDispatcher.handleAction({
					actionType: ProfileConstants.PROFILE_SAVED,
					data: res,
				});
			})
			.fail(function (err) {
				console.error(
					'Error while saving profile: ',
					profile,
					' | PAYLOAD: ',
					profile_payload,
					' | ERR: ',
					err,
				);
				AppDispatcher.handleAction({
					actionType: ProfileConstants.PROFILE_SAVE_FAILED,
					data: {
						profile: profile,
						payload: profile_payload,
						error: err,
					},
				});
			});
	},

	changeDisplayCurrency: function (currency) {
		AppDispatcher.handleAction({
			actionType: ProfileConstants.CHANGE_DISPLAY_CURRENCY,
		});

		let skip_retry = false;
		let last_err = null;
		let final_callback_invoke = false;

		async.retry(
			{ times: 3, interval: 1 },
			function (callback) {
				if (skip_retry) {
					return callback(last_err);
				}

				Ajax.changeDisplayCurrency(currency)
					.done(function (res) {
						return callback(null, res);
					})
					.fail(function (err) {
						console.log('changeDisplayCurrency got an error.', { err });
						if (err.status === 404) {
							skip_retry = true;
							last_err = err;
							final_callback_invoke(err);
						}
						callback(err);
					});
			},
			finalCallback,
		);

		function finalCallback(err, res) {
			if (!final_callback_invoke) {
				final_callback_invoke = true;

				if (err || !res) {
					console.error('changeDisplayCurrency got a final callback error', err);
					console.error('Error while changing display currency: ', currency, ' | ERR: ', err);

					AppDispatcher.handleAction({
						actionType: ProfileConstants.DISPLAY_CURRENCY_CHANGE_FAILED,
						data: {
							currency,
							error: err,
						},
					});
				} else {
					AppDispatcher.handleAction({
						actionType: ProfileConstants.DISPLAY_CURRENCY_CHANGED,
						data: {
							currency,
							response: res,
						},
					});
				}
			}
		}
	},

	resetSaveProfileStatus: function () {
		AppDispatcher.handleAction({
			actionType: ProfileConstants.RESET_SAVE_PROFILE_STATUS,
		});
	},

	updateApprovedCurrencyTerms() {
		AppDispatcher.handleAction({
			actionType: ProfileConstants.UPDATE_APPROVED_CURRENCY_TERMS,
		});
	},

	changeChargeCurrency: function (currency) {
		AppDispatcher.handleAction({
			actionType: ProfileConstants.CHANGE_CHARGE_CURRENCY,
			data: {
				currency,
			},
		});
	},
};

export default ProfileActions;
