import _ from 'lodash';

import React from 'react';
import createClass from 'create-react-class';

import Config from 'arbitrip-common/client/utils/Config';
import RouterWrapper from '../../../../utils/RouterWrapper';
import { withRouter } from '../../../../utils/withRouter';

// Method to retrieve state from Stores
function getReservationSummaryState() {
	return {
		should_render: true,
	};
}

// Define main Controller View
const CancellationTermsContainer = createClass({
	displayName: 'CancellationTermsContainer',

	// Get initial state from stores
	getInitialState: function () {
		return getReservationSummaryState();
	},

	// Add change listeners to stores
	componentDidMount: function () {
		const { reservation } = this.props;

		if (!_.get(reservation, 'deal.chooseprod')) {
			this.setState({
				should_render: false,
			});
		}
	},

	handleOnBottomHalfClick: function () {
		if (Config.dev_mode) {
		}

		// We always want a printout of this deal identifier
		console.log('S: ' + this.props.reservation.deal.id);
	},

	// Render our child components, passing state via props
	render() {
		const { profile, reservation, navigate } = this.props;
		if (_.isEmpty(reservation.deal)) {
			RouterWrapper.goToSearchPage(navigate);
			return;
		}

		if (!this.state.should_render) {
			return null;
		}

		const DEAL = reservation.deal;

		const postPay = DEAL.post_pay;

		let CANCELLATION_TERMS = [];

		if (DEAL.original.dca.cancellationPoliciesText) {
			let texts = DEAL.original.dca.cancellationPoliciesText.split('\n').map((t) => t.trim());
			texts.forEach((text, idx) => {
				let cancelKey = 'cancel-key-' + idx;
				CANCELLATION_TERMS.push(
					<div key={cancelKey} className="cancellation-term">
						{text}
					</div>,
				);
			});
		} else {
			console.error('no cancelPolicies!');
		}

		// TODO: double check with three currencies scenario
		const hasDifferentDisplayCurrency =
			postPay && DEAL.hotel_currency
				? DEAL.hotel_currency !== profile.display_currency
				: DEAL.currency !== profile.display_currency;

		const cancellation_terms_title = postPay
			? "Cancellation terms in company's currency"
			: `Cancellation terms${hasDifferentDisplayCurrency ? ' in billing currency' : ''}`;

		if (!CANCELLATION_TERMS.length) {
			return null;
		}

		return (
			<div className="cancellation-terms-container" onClick={this.handleOnBottomHalfClick}>
				<div className="title">{cancellation_terms_title}</div>
				{CANCELLATION_TERMS}
			</div>
		);
	},
});

export default withRouter(CancellationTermsContainer);
