const TravelerIcon = () => {
	return (
		<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clip-path="url(#clip0_11757_23742)">
				<path
					d="M12.1904 24.5059C18.8167 24.3972 24.1002 18.9374 23.9914 12.3112C23.8827 5.68495 18.423 0.401455 11.7967 0.510175C5.17046 0.618896 -0.113036 6.07866 -0.00431586 12.7049C0.104405 19.3312 5.56417 24.6147 12.1904 24.5059Z"
					fill="#EEEEEE"
				/>
				<path
					d="M20.4117 21.0558C20.228 19.2216 19.8242 16.3164 19.0721 15.5653C17.9829 14.4761 16.0912 14.57 14.3135 12.9508C14.1853 12.8051 14.043 12.6726 13.8885 12.5551V11.5325C14.364 10.8683 14.682 9.96583 14.8687 9.06741C14.8869 9.10879 14.9121 9.13706 14.9424 9.14211C15.0534 9.16028 15.1928 8.90488 15.2513 8.56974C15.3098 8.2346 15.2695 7.97012 15.1655 7.93478C15.324 7.1474 15.2422 5.88961 14.9172 4.92053C14.5921 3.95144 13.1698 3.5315 12.0675 3.50122H11.9292C10.8278 3.5315 9.4055 3.94034 9.08045 4.92053C8.7554 5.90072 8.67666 7.1474 8.83212 7.93478C8.73117 7.97012 8.68878 8.2457 8.74632 8.56974C8.80385 8.89378 8.94821 9.16028 9.05521 9.14211C9.08549 9.14211 9.10972 9.10879 9.12991 9.06741C9.31666 9.96583 9.63464 10.8683 10.1101 11.5325V12.5551C9.95511 12.6723 9.81238 12.8049 9.68411 12.9508C7.90745 14.566 6.01471 14.4761 4.9255 15.5653C4.17345 16.3164 3.77067 19.2216 3.58594 21.0558C5.82647 23.2644 8.84666 24.5018 11.9927 24.5001H12.0049C15.1511 24.5024 18.1715 23.2649 20.4117 21.0558Z"
					fill="var(--general-colors-primary)"
				/>
			</g>
			<defs>
				<clipPath id="clip0_11757_23742">
					<rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default TravelerIcon;
