import React from 'react';
import createClass from 'create-react-class';

import { Badge, Dialog, SvgIcon, IconButton } from '@mui/material';

// import styles from '../../../../../utils/Styles';
import Analytics from 'arbitrip-common/client/analytics';
import ProfileStore from '../../../../../stores/ProfileStore';
import BasketStore from '../../../../../stores/BasketStore';
import BasketActions from '../../../../../actions/BasketActions';

import BasketModal from './BasketModal.react';

const suggestions_list_icon_color = '#666';
const suggestions_list_icon = (
	<svg width="25px" height="25px" viewBox="0 0 25 25" version="1.1" xmlns="http://www.w3.org/2000/svg">
		<title>Suggestions List</title>
		<desc>Created with Sketch.</desc>
		<defs></defs>
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="Suggestions-List-Icon-Copy-3" transform="translate(-1101.000000, -121.000000)" strokeWidth="2">
				<g id="Suggestions-List" transform="translate(1102.000000, 122.000000)">
					<path
						d="M0,19.2341319 C7.99666659,19.156088 15.9933332,19.078044 23.9899998,19"
						id="Path-2"
						stroke={suggestions_list_icon_color}
					></path>
					<path
						d="M0,14.2341319 C7.99666659,14.156088 15.9933332,14.078044 23.9899998,14"
						id="Path-2-Copy"
						stroke={suggestions_list_icon_color}
					></path>
					<path
						d="M0,9.23413194 C7.99666659,9.15608796 15.9933332,9.07804398 23.9899998,9"
						id="Path-2-Copy-2"
						stroke={suggestions_list_icon_color}
					></path>
					<path
						d="M0,4.23413194 C7.99666659,4.15608796 15.9933332,4.07804398 23.9899998,4"
						id="Path-2-Copy-5"
						stroke={suggestions_list_icon_color}
					></path>
					<path
						d="M-7.49499989,11.7291318 C0.501666705,11.6510878 8.4983333,11.5730439 16.4949999,11.4949999"
						id="Path-2-Copy-4"
						stroke="#FFFFFF"
						transform="translate(4.500000, 11.995000) rotate(-90.000000) translate(-4.500000, -11.995000) "
					></path>
				</g>
			</g>
		</g>
	</svg>
);

function getBasketComponentState() {
	return {
		deal_count: BasketStore.getDealCount(),
		modal_open: BasketStore.getBasketOpen(),
		basket: BasketStore.getBasket(),
		send_status: BasketStore.getSendBasketStatus(),
	};
}

var BasketComponent = createClass({
	displayName: 'BasketComponent',

	getInitialState: function () {
		return getBasketComponentState();
	},

	componentDidMount: function () {
		BasketStore.addChangeListener(this._onChange);
		setTimeout(function () {
			BasketActions.initializeBasket(ProfileStore.getProfile());
		}, 11); //bad practice :(
	},

	componentWillUnmount: function () {
		BasketStore.removeChangeListener(this._onChange);
	},

	// UNSAFE_componentWillUpdate: function (nextState, nextProps) {
	//   if (this.state.deal_count !== nextState.deal_count) {

	//   }
	// },

	handleComponentOnClick: function (e) {
		BasketActions.openBasket();
		Analytics.actions.interactions.openedBasket();
	},

	handleModalClose: function () {
		BasketActions.closeBasket();
		Analytics.actions.interactions.closedBasket();
	},

	render: function () {
		let { deal_count, modal_open, basket, send_status } = this.state;

		let icon = (
			<IconButton
				tooltip="Suggestions List"
				onClick={this.handleComponentOnClick}
				key="share-icon"
				className="share-icon"
				size="large"
			>
				<SvgIcon>{suggestions_list_icon}</SvgIcon>
			</IconButton>
		);
		let icon_dialog = [
			icon,
			<Dialog
				fullWidth={true}
				maxWidth={'md'}
				open={modal_open}
				onClose={this.handleModalClose}
				key="share-dialog"
				PaperProps={{ className: 'basket-modal-paper' }}
			>
				<BasketModal basket={basket} send_status={send_status} profile={this.props.profile} />
			</Dialog>,
		];

		if (deal_count) {
			return (
				<Badge
					badgeContent={deal_count}
					color="primary"
					style={{ padding: 0, cursor: 'pointer' }}
					id="share-badge"
				>
					{icon_dialog}
				</Badge>
			);
		}

		return <div>{icon_dialog}</div>;
	},

	_onChange: function () {
		this.setState(getBasketComponentState());
	},
});

export default BasketComponent;
