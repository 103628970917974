import moment from 'moment';

export const TAS_ID_KEY = 'tas_id';
const TAS_ID_EXPIRATION_HOURS = 2;
const TAS_ID_EXPIRATION_TIME = TAS_ID_EXPIRATION_HOURS * 60 * 60 * 1000;

export const setTasIdInSessionStorage = (tas_id) => {
	const creationDate = moment().valueOf();
	const data = { tas_id, creation_date: creationDate };
	sessionStorage.setItem(TAS_ID_KEY, JSON.stringify(data));
};

export const parseTasIdFromUrl = (tas_id) => {
	if (!tas_id) return null;
	return tas_id.endsWith('T') ? tas_id : `${tas_id}T`;
};

export const removeTasIdFromSessionStorage = () => {
	sessionStorage.removeItem(TAS_ID_KEY);
};

const getTasIdData = () => {
	try {
		return JSON.parse(sessionStorage.getItem(TAS_ID_KEY));
	} catch (error) {
		console.error('Error parsing tas_id data:', error);
		return null; // Return null if there's an error
	}
};

export const getTasIdFromSessionStorage = () => {
	const tasIdData = getTasIdData();

	if (tasIdData) {
		const { tas_id, creation_date } = tasIdData;
		const currentTime = moment().valueOf();
		const elapsedTime = currentTime - creation_date;

		// Check if the tas_id is still valid
		if (elapsedTime < TAS_ID_EXPIRATION_TIME) {
			return tas_id;
		}
	}

	removeTasIdFromSessionStorage();
	return null;
};

export const getFormattedExpirationTime = () => {
	const tasIdData = getTasIdData();

	if (tasIdData) {
		const { creation_date } = tasIdData;
		const expirationTimestamp = creation_date + TAS_ID_EXPIRATION_TIME;
		return moment(expirationTimestamp).format('HH:mm');
	}

	return null;
};
