import keyMirror from 'keymirror';
import _ from 'lodash';

const TRAVEL_POLICY_CONSTANTS = {
	STATUS: {
		INITIAL: 'initial',
		BUSY: 'busy',
		SUCCESS: 'success',
		FAILED: 'failed',
	},
};

// Define action constants
export default _.extend(
	{},
	TRAVEL_POLICY_CONSTANTS,
	keyMirror({
		CREATE_TRAVEL_POLICY: null,
		TRAVEL_POLICY_CREATION_SUCCESS: null,
		TRAVEL_POLICY_CREATION_FAILED: null,

		CREATE_TRAVEL_POLICY_ENTRY: null,
		TRAVEL_POLICY_ENTRY_CREATION_SUCCESS: null,
		TRAVEL_POLICY_ENTRY_CREATION_FAILED: null,

		EDIT_TRAVEL_POLICY_ENTRY: null,
		TRAVEL_POLICY_ENTRY_EDITION_SUCCESS: null,
		TRAVEL_POLICY_ENTRY_EDITION_FAILED: null,

		EDIT_DEFAULT_TRAVEL_POLICY: null,
		DEFAULT_TRAVEL_POLICY_EDITION_SUCCESS: null,
		DEFAULT_TRAVEL_POLICY_EDITION_FAILED: null,

		REMOVE_TRAVEL_POLICY_ENTRY: null,
		TRAVEL_POLICY_ENTRY_REMOVAL_SUCCESS: null,
		TRAVEL_POLICY_ENTRY_REMOVAL_FAILED: null,

		REMOVE_TRAVEL_POLICY: null,
		TRAVEL_POLICY_REMOVAL_SUCCESS: null,
		TRAVEL_POLICY_REMOVAL_FAILED: null,

		RETRIEVE_TRAVEL_POLICIES: null,
		TRAVEL_POLICIES_RETRIEVAL_SUCCESS: null,
		TRAVEL_POLICIES_RETRIEVAL_FAILED: null,

		RESET_TRAVEL_POLICY_STATUS: null,
	}),
);
