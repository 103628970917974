import React from 'react';
import createClass from 'create-react-class';
import TripStatusComponent from './trip-table/TripStatusComponent';
import ManagementStore from '@/stores/ManagementStore';

import BookedItems from './BookedItems.react';
import FlightStatus from './FlightStatus.react';

import { ReactComponent as SpaceManIcon } from '@/img/space-man.svg';

// function momentifyFlightDepartDateTime(flight) {
//   let date_time = moment(flight.depart_date);
//   let time = moment(flight.depart_time, 'HH:mm aa');
//   date_time.set({
//     hour: time.get('hour'),
//     minute: time.get('minute'),
//     second: time.get('second')
//   });
//   return date_time;
// }
//
// function flightSort(a, b) {
//
//   let date_time_a = momentifyFlightDepartDateTime(a);
//   let date_time_b = momentifyFlightDepartDateTime(b);
//
//   return date_time_a.diff(date_time_b, 'minutes');
// }

function tripToPathInitialsString(trip) {
	let path = trip && trip.meta && trip.meta.path;
	if (Array.isArray(path)) {
		return path.map((location) => location.code).join(' > ');
		// path = path
		//   .filter((location) => location && location.code)
		//   .map((location, idx) => <span key={location.code} {...(location && location.display_name ? {title: location.display_name} : {})}>location.code</span>).join(' > ');
		//
		// if (path.length >= 2) {
		//   for (let i = path.length - 2; i >= 0; i--) {
		//     path.splice(i, 0, <span> > </span>);
		//   }
		// }
		// return path;
	}

	return null;
}

function getTripPreviewPageState() {
	return {
		trip: ManagementStore.getPreviewedTrip(),
	};
}

var TripPreview = createClass({
	displayName: 'TripPreview',

	getInitialState: function () {
		return getTripPreviewPageState();
	},

	componentDidMount: function () {
		ManagementStore.addChangeListener(this._onChange);
	},

	componentWillUnmount: function () {
		ManagementStore.removeChangeListener(this._onChange);
	},

	render: function () {
		let { trip } = this.state;

		if (!trip) {
			return (
				<div className="no-trip">
					<div className="sidebar-header" key="sh">
						<span>Trip Information</span>
					</div>
					<div className="sidebar-content" key="sc">
						<span>Hover the table to see trip information.</span>
					</div>
					<div className="space-man-icon flex justify-center">
						<SpaceManIcon />
					</div>
				</div>
			);
		}

		let path = tripToPathInitialsString(trip);

		return (
			<div className="trip-preview">
				<div className="name" title={trip.name}>
					<span>{trip.name}</span>
				</div>

				<TripStatusComponent
					trip={trip}
					show_sub_status={true}
					ongoing={trip.live_meta && trip.live_meta.current_location && trip.live_meta.current_location.type}
				/>
				<div className="path">
					<span>{path}</span>
				</div>

				{trip.live_meta &&
				trip.live_meta.current_location &&
				trip.live_meta.current_location.type === 'flight' ? (
					<FlightStatus
						flight_number={trip.live_meta.current_location.details.flight_number}
						show_title={true}
					/>
				) : null}

				<BookedItems itinerary={trip.booked_itinerary} />
			</div>
		);
	},

	_onChange: function () {
		this.setState(getTripPreviewPageState());
	},
});

export default TripPreview;
