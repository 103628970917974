const EXTRAS = {
	SHOW_CONFIRMED: 'show_confirmed',
	TRAVEL_BOOSTER_NOT_SYNCED: 'travel_booster_not_synced',
	SHOW_UPCOMING: 'show_upcoming',
	SHOW_ONGOING: 'show_ongoing',
	CANCELLATION_DEADLINE: 'cancellation_deadline',
	BOOKINGS_FROM_BRANCH: 'bookings_from_branch',
	AGENCY_BRANCHES: 'agency_branches',
	FURTHEST_DEADLINE: 'furthest_deadline',
	CLOSEST_DEADLINE: 'closest_deadline_excludes_passed_due',
	CLOSEST_DEADLINE_PASSED: 'closest_deadline_includes_passed_due',
};

const TAB_LABELS = {
	UPCOMING: 'Upcoming',
	ONGOING: 'Ongoing',
	ENDED: 'Ended',
	CANCELED: 'Canceled',
	ALL: 'All',
	UNPAID: 'Unpaid',
	MISSING_DOCKET: 'Missing Docket',
};

const TAB_FILTER_KEYS = {
	SHOW_UPCOMING: 'show_upcoming',
	SHOW_ONGOING: 'show_ongoing',
	SHOW_ARCHIVE: 'show_archive',
	SHOW_CANCELED: 'show_cancelled',
	ALL: null,
	SHOW_UNPAID: 'show_unpaid',
	SHOW_TRAVEL_BOOSTER_NOT_SYNCED: 'travel_booster_not_synced',
};

const TABS_CONFIG = [
	{ label: TAB_LABELS.ALL, filterKey: TAB_FILTER_KEYS.ALL, shouldDisplay: true },
	{ label: TAB_LABELS.UPCOMING, filterKey: TAB_FILTER_KEYS.SHOW_UPCOMING, shouldDisplay: true },
	{ label: TAB_LABELS.ONGOING, filterKey: TAB_FILTER_KEYS.SHOW_ONGOING, shouldDisplay: true },
	{ label: TAB_LABELS.ENDED, filterKey: TAB_FILTER_KEYS.SHOW_ARCHIVE, shouldDisplay: true },
	{ label: TAB_LABELS.CANCELED, filterKey: TAB_FILTER_KEYS.SHOW_CANCELED, shouldDisplay: true },
	{ label: TAB_LABELS.UNPAID, filterKey: TAB_FILTER_KEYS.SHOW_UNPAID, shouldDisplay: false },
	{
		label: TAB_LABELS.MISSING_DOCKET,
		filterKey: TAB_FILTER_KEYS.SHOW_TRAVEL_BOOSTER_NOT_SYNCED,
		shouldDisplay: false,
	},
];

module.exports = {
	EXTRAS,
	TAB_LABELS,
	TABS_CONFIG,
};
