const CheckboxActiveIcon = () => {
	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				x="0.5"
				y="0.5"
				width="15"
				height="15"
				rx="2.5"
				fill="var(--general-checkbox-background)"
				stroke="var(--general-checkbox-background)"
			/>
			<path
				d="M12 6L7.1875 10.8125L5 8.625"
				stroke="var(--general-checkbox-color)"
				strokeWidth="1.5"
				strokeLinecap="square"
			/>
		</svg>
	);
};

export default CheckboxActiveIcon;
