import ManagementConstants from '../constants/ManagementConstants';

import WizardDestination from '../entities/WizardDestination';
import WizardFlight from '../entities/WizardFlight';
import TripRequest from '../entities/TripRequest';

import AppDispatcher from '../dispatcher/AppDispatcher';
import { EventEmitter } from 'events';
import ReservationConstants from '../constants/ReservationConstants';
import Airfare from '../entities/AirfareReservation';

import _ from 'lodash';
import moment from 'moment';
import DeepDiff from 'deep-diff';

import TripRequestFlight from '../entities/TripRequestFlight';
import TripRequestAccommodation from '../entities/TripRequestAccommodation';
import TripRequestCar from '../entities/TripRequestCar';

import FlightReservation from '../entities/FlightReservation';
import AccommodationReservation from '../entities/Reservation';
import CarReservation from '../entities/CarReservation';

var _init_status = ManagementConstants.STATUS.INITIAL;
var _draft_status = ManagementConstants.STATUS.INITIAL;
var _edit_meta_status = ManagementConstants.STATUS.INITIAL;
var _create_component_status = ManagementConstants.STATUS.INITIAL;
var _update_component_status = ManagementConstants.STATUS.INITIAL;
var _remove_component_status = ManagementConstants.STATUS.INITIAL;
var _submit_status = ManagementConstants.STATUS.INITIAL;
var _trip_requests_status = ManagementConstants.STATUS.INITIAL;
var _trip_request_by_id_status = ManagementConstants.STATUS.INITIAL;
var _is_about_to_save = false;
var _current_display_order = 1;
var _current_booked_display_order = 1;

var _previewed_trip;
var _previewed_booked_component;
var _previewed_tips_source;
var _wizard_destinations = [];
var _current_wizard_display_order = 1;
var _submit_wizard_destinations_status = ManagementConstants.STATUS.INITIAL;

var _airfare_wizard_flights = [];
var _airfare_wizard_cost;
var _airfare_wizard_currency;
var _current_airfare_wizard_display_order = 1;
var _submit_airfare_wizard_flights_status = ManagementConstants.STATUS.INITIAL;

var _trip_request_page_source = ManagementConstants.SOURCES.TRIPS;

var _discard_trip_request_status = ManagementConstants.STATUS.INITIAL;
var _cancel_trip_request_status = ManagementConstants.STATUS.INITIAL;
var _send_for_approval_status = ManagementConstants.STATUS.INITIAL; // currently, also used for pre approval status
var _clone_status = ManagementConstants.STATUS.INITIAL;
var _forward_trip_request_status = ManagementConstants.STATUS.INITIAL;
var _create_trip_airfare_status = ManagementConstants.STATUS.INITIAL;
var _remove_trip_airfare_status = ManagementConstants.STATUS.INITIAL;
var _remove_trip_accommodation_status = ManagementConstants.STATUS.INITIAL;
var _cancel_trip_hotel_booking_status = ManagementConstants.STATUS.INITIAL;

var _trip_request_error = false;

var _current_trip_request_id = '';
var _cloned_trip_id = '';
var _update_trip_request;

function resetWizardDestinations() {
	_wizard_destinations = [new WizardDestination(null, _current_display_order)];
	_current_wizard_display_order++;
}

resetWizardDestinations();

function resetAirfareWizardFlights() {
	_airfare_wizard_flights = [new WizardFlight(null, _current_airfare_wizard_display_order)];
	_current_airfare_wizard_display_order++;
}

resetAirfareWizardFlights();

function getComponentCollection(component) {
	if (!component || !component.component_type) {
		return null;
	}

	var collection_name = component.component_type + 's';
	return _trip_request[collection_name];
}

function getComponentByDisplayOrder(component) {
	const component_collection = getComponentCollection(component);
	if (Array.isArray(component_collection)) {
		for (let i = 0; i < component_collection.length; i++) {
			if (component_collection[i].display_order === component.display_order) {
				return component_collection[i];
			}
		}
	}
	return null;
}

function setComponentId(component, id) {
	const trip_request_component = getComponentByDisplayOrder(component);
	trip_request_component.id = id;
}

function removeTripRequestComponent(component) {
	var component_collection = getComponentCollection(component);
	if (Array.isArray(component_collection)) {
		for (var i = 0; i < component_collection.length; i++) {
			if (component_collection[i].display_order === component.display_order) {
				component_collection.splice(i, 1);
				break;
			}
		}
	}
}

function updateComponentField(component, field, value) {
	var trip_request_component = getComponentByDisplayOrder(component);
	if (trip_request_component && trip_request_component[field] !== value) {
		trip_request_component[field] = value;
	}
}

function getDestinationByDisplayOrder(destination) {
	for (let i = 0; i < _wizard_destinations.length; i++) {
		if (_wizard_destinations[i].display_order === destination.display_order) {
			return _wizard_destinations[i];
		}
	}

	return null;
}

function updateDestinationField(destination, field, value) {
	let wizard_destination = getDestinationByDisplayOrder(destination);
	if (wizard_destination && wizard_destination[field] !== value) {
		wizard_destination[field] = value;
	}
}

function removeDestination(destination) {
	for (let i = 0; i < _wizard_destinations.length; i++) {
		if (_wizard_destinations[i].display_order === destination.display_order) {
			_wizard_destinations.splice(i, 1);
			break;
		}
	}
}

function getAirfareWizardFlightByDisplayOrder(flight) {
	for (let i = 0; i < _airfare_wizard_flights.length; i++) {
		if (_airfare_wizard_flights[i].display_order === flight.display_order) {
			return _airfare_wizard_flights[i];
		}
	}

	return null;
}

function updateAirfareWizardFlightField(flight, field, value) {
	let airfare_wizard_flight = getAirfareWizardFlightByDisplayOrder(flight);
	if (airfare_wizard_flight && airfare_wizard_flight[field] !== value) {
		airfare_wizard_flight[field] = value;
	}
}

function removeAirfareWizardFlight(flight) {
	for (let i = 0; i < _airfare_wizard_flights.length; i++) {
		if (_airfare_wizard_flights[i].display_order === flight.display_order) {
			_airfare_wizard_flights.splice(i, 1);
			break;
		}
	}
}

const conference_purpose_regex = new RegExp('conference', 'i');

function validateTravelers(trip_request) {
	return (
		Array.isArray(trip_request.travelers) &&
		trip_request.travelers.length &&
		trip_request.travelers.every((traveler) => (traveler.id || traveler._id) && traveler.full_name)
	);
}

function validatePurposes(trip_request) {
	if (!Array.isArray(trip_request.purposes) || !trip_request.purposes.length) {
		return false;
	}

	if (trip_request.purposes.some((purpose) => conference_purpose_regex.test(purpose))) {
		return (
			trip_request.conference_name_or_website &&
			trip_request.conference_estimated_cost_currency &&
			trip_request.conference_estimated_cost_amount
		);
	}

	return true;
}

function validateAirport(airport) {
	return airport && (airport.iata_code || airport.id || airport.display_name);
}

function validateFlight(flight) {
	return (
		flight &&
		flight.id &&
		validateAirport(flight.from) &&
		validateAirport(flight.to) &&
		flight.depart_date &&
		moment(flight.depart_date).isValid()
	);
}

function validateAccommodation(accommodation) {
	return (
		accommodation &&
		accommodation.id &&
		accommodation.destination &&
		accommodation.destination.address &&
		accommodation.destination.place && // TODO: maybe geometry also?
		accommodation.check_in &&
		accommodation.check_out &&
		moment(accommodation.check_in).isValid() &&
		moment(accommodation.check_out).isValid()
	);
}

function validateComponents(trip_request) {
	if (!Array.isArray(trip_request.flights) && Array.isArray(trip_request.accommodations)) return false;
	if (!trip_request.flights.length && !trip_request.accommodations.length) return false;
	return trip_request.flights.every(validateFlight) && trip_request.accommodations.every(validateAccommodation);
	// return Array.isArray(trip_request.flights)
	//   && Array.isArray(trip_request.accommodations)
	//   && (trip_request.flights.length + trip_request.accommodations.length)
	//   && trip_request.flights.every(validateFlight)
	//   && trip_request.accommodations.every(validateAccommodation);
}

function validateTripScheduleAndAgenda(trip_request, profile) {
	if (
		profile &&
		profile.company &&
		profile.company.settings &&
		profile.company.settings.trips_schedule_and_agenda_enabled
	) {
		return trip_request.trips_schedule_and_agenda_text;
	}

	return true;
}

function validateTripRequest(trip_request, profile) {
	return (
		trip_request &&
		trip_request.id &&
		trip_request.name &&
		validatePurposes(trip_request) &&
		trip_request.cost_center &&
		validateComponents(trip_request) &&
		validateTravelers(trip_request) &&
		validateTripScheduleAndAgenda(trip_request, profile)
	);
}

function removeTripRequestFromTrips(trip_request) {
	let remove_index = -1;
	for (let i = 0; i < _trip_requests.length; i++) {
		if (_trip_requests[i].id === trip_request.id) {
			return _trip_requests.splice(remove_index, 1);
		}
	}
}

function clearTripPreview() {
	_previewed_trip = null;
	_previewed_booked_component = null;
	_previewed_tips_source = null;
}

var _trip_request = new TripRequest({});
var _trip_requests = [];
var _trip_request_snapshot = new TripRequest({});

function updateTripRequestSnapshot() {
	_trip_request_snapshot = cloneObject(_trip_request);
}

function cloneObject(obj) {
	return JSON.parse(JSON.stringify(obj));
}

function setAboutToSaveChangesStatus(state) {
	if (state === true) {
		_is_about_to_save = true;
		window.onbeforeunload = function (e) {
			e = e || window.event;

			// For IE and Firefox prior to version 4
			if (e) {
				e.returnValue = 'Any string';
			}

			// For Safari
			return 'Any string';
		};
	} else {
		_is_about_to_save = false;
		window.onbeforeunload = null;
	}
}

var ManagementStore = _.extend({}, EventEmitter.prototype, {
	getTripRequestSnapshot: function () {
		return _trip_request_snapshot;
	},

	getUpdateTripRequest: function () {
		return _update_trip_request;
	},

	createTripRequestSnapshot: function () {
		return cloneObject(_trip_request);
	},

	cloneTripRequest: function (trip_request) {
		return cloneObject(trip_request);
	},

	getTripRequestsDiff: function (current, snapshot) {
		let diff = null;

		// name
		// travelers
		// staying_with_text
		// purposes
		// cost_center
		// conference_name
		// conference_name_or_website
		// conference_estimated_cost_currency
		// conference_estimated_cost_amount
		// flights
		// accommodations
		// special_requests
		// vacation_days_from
		// vacation_days_to
		// booked_itinerary/airfares
		// booked_itinerary/accommodations
		const meta_fields = [
			'name',
			'travelers',
			'staying_with_text',
			'trips_schedule_and_agenda_text',
			'purposes',
			'cost_center',
			'conference_name_or_website',
			'conference_estimated_cost_currency',
			'conference_estimated_cost_amount',
			'special_requests',
			'vacation_days_from',
			'vacation_days_to',
		];

		// if (_trip_request_snapshot.name !== _trip_request.name) {
		//   diff.name = _trip_request.name;
		// }
		//
		// if (_trip_request_snapshot.cost_center._id !== _trip_request.cost_center._id) {
		//   diff.cost_center = _trip_request_snapshot.cost_center;
		// }

		// DeepDiff.observableDiff(_trip_request_snapshot, _trip_request, function (d) {
		//   // Apply all changes except those to the 'name' property...
		//   if (d.path.length !== 1 || d.path.join('.') !== 'name') {
		//     DeepDiff.applyChange(diff, _trip_request_snapshot, d);
		//   }
		// });

		let differences = DeepDiff.diff(snapshot, current);
		if (differences) {
			diff = {};
			for (let i = 0; i < differences.length; i++) {
				let field = differences[i].path[0];
				if (meta_fields.indexOf(field) >= 0) {
					diff[field] = current[field];
				}
			}
		}

		return diff;
	},

	getTripRequestSnapshotDiff: function () {
		return this.getTripRequestsDiff(_trip_request, _trip_request_snapshot);
	},

	getPreApprovalNeeded: function (profile) {
		let current_cost_center =
			_trip_request &&
			_trip_request.cost_center &&
			profile.company.cost_centers.filter((cc) => cc._id === _trip_request.cost_center._id)[0];
		return current_cost_center && current_cost_center.pre_approval_needed;
	},

	getPostApprovalNeeded: function (profile) {
		let current_cost_center =
			_trip_request &&
			_trip_request.cost_center &&
			profile.company.cost_centers.filter((cc) => cc._id === _trip_request.cost_center._id)[0];
		return current_cost_center && current_cost_center.post_approval_needed;
	},

	getTripRequestError: function () {
		return _trip_request_error;
	},

	getCreateTripAirfareStatus: function () {
		return _create_trip_airfare_status;
	},

	getRemoveTripAirfareStatus: function () {
		return _remove_trip_airfare_status;
	},
	getRemoveTripAccommodationStatus: function () {
		return _remove_trip_accommodation_status;
	},
	getCancelTripHotelBookingStatus: function () {
		return _cancel_trip_hotel_booking_status;
	},

	getForwardTripRequestStatus: function () {
		return _forward_trip_request_status;
	},

	isTripRequestSaving: function () {
		return (
			_edit_meta_status === ManagementConstants.STATUS.BUSY ||
			_create_component_status === ManagementConstants.STATUS.BUSY ||
			_update_component_status === ManagementConstants.STATUS.BUSY ||
			_remove_component_status === ManagementConstants.STATUS.BUSY
		);
	},

	getCurrentTripRequestId: function () {
		return _current_trip_request_id;
		// return _trip_request.id;
	},

	getCurrentTripStartDate: function () {
		return _trip_request && _trip_request.meta.start_date;
		// return _trip_request.id;
	},

	getSubmitWizardDestinationsStatus: function () {
		return _submit_wizard_destinations_status;
	},

	getWizardDestinations: function () {
		return _wizard_destinations;
	},

	getSubmitAirfareWizardFlightsStatus: function () {
		return _submit_airfare_wizard_flights_status;
	},

	getAirfareWizardFlights: function () {
		return _airfare_wizard_flights;
	},

	getAirfareWizardCost: function () {
		return _airfare_wizard_cost;
	},

	getAirfareWizardCurrency: function () {
		return _airfare_wizard_currency;
	},

	getPreviewedTrip: function () {
		return _previewed_trip && _.extend({}, _previewed_trip);
	},

	getPreviewedBookedComponent: function () {
		return _previewed_booked_component && _.extend({}, _previewed_booked_component);
	},

	getPreviewedTipsSource: function () {
		return _previewed_tips_source;
	},

	getTripRequests: function () {
		return _trip_requests.slice(0);
	},

	getTripRequestsStatus: function () {
		return _trip_requests_status;
	},

	getTripRequest: function () {
		return _.extend({}, _trip_request);
	},

	isAboutToSave: function () {
		return _is_about_to_save;
	},

	getDraftStatus: function () {
		return _draft_status;
	},

	getSubmitStatus: function () {
		return _submit_status;
	},

	getEditMetaStatus: function () {
		return _edit_meta_status;
	},

	getInitStatus: function () {
		return _init_status;
	},

	getDiscardStatus: function () {
		return _discard_trip_request_status;
	},

	getCancelStatus: function () {
		return _cancel_trip_request_status;
	},

	getApprovalStatus: function () {
		return _send_for_approval_status;
	},

	getCloneStatus: function () {
		return _clone_status;
	},

	getClonedTripId: function () {
		return _cloned_trip_id;
	},

	getTripRequestByIdStatus: function () {
		return _trip_request_by_id_status;
	},

	tripRequestIsValid: function (profile) {
		return validateTripRequest(_trip_request, profile);
	},

	// TODO: method for currency conversion

	emitChange: function () {
		this.emit('change');
	},

	addChangeListener: function (callback) {
		this.on('change', callback);
	},

	removeChangeListener: function (callback) {
		this.removeListener('change', callback);
	},

	getTripRequestPageSource: function () {
		return _trip_request_page_source;
	},
});

AppDispatcher.register(function (payload) {
	var action = payload.action;

	switch (action.actionType) {
		case ManagementConstants.RESET_INIT_TRIP_REQUEST_STATUS:
			_init_status = ManagementConstants.STATUS.INITIAL;
			break;

		case ManagementConstants.INIT_TRIP_REQUEST:
			_init_status = ManagementConstants.STATUS.BUSY;
			break;

		case ManagementConstants.TRIP_REQUEST_INITIALIZED:
			_trip_request = null; //new TripRequest(action.data, profile);
			_init_status = ManagementConstants.STATUS.SUCCESS;
			_current_trip_request_id = action.data.trip_request._id;
			_trip_request_page_source = ManagementConstants.SOURCES.WIZARD;
			_trip_request_error = false;
			break;

		case ManagementConstants.TRIP_REQUEST_INIT_FAILED:
			_init_status = ManagementConstants.STATUS.FAILED;
			break;

		////////////////////////////////////////////////////////////////////////////////

		case ManagementConstants.INIT_TRIP_REQUEST_FLIGHT:
			var flight_request = new TripRequestFlight({}, _current_display_order);
			_trip_request.flights.push(flight_request);
			_current_display_order++;
			break;

		case ManagementConstants.INIT_TRIP_REQUEST_ACCOMMODATION:
			var accommodation_request = new TripRequestAccommodation({}, _current_display_order);
			_trip_request.accommodations.push(accommodation_request);
			_current_display_order++;
			break;

		case ManagementConstants.INIT_TRIP_REQUEST_CAR:
			var car_request = new TripRequestCar({}, _current_display_order);
			_trip_request.cars.push(car_request);
			_current_display_order++;
			break;

		///

		case ManagementConstants.INIT_FLIGHT_RESERVATION:
			var flight = new FlightReservation({}, _current_booked_display_order);
			_trip_request.booked_flights.push(flight);
			_current_booked_display_order++;
			break;
		case ManagementConstants.INIT_ACCOMMODATION_RESERVATION:
			var accommodation = new AccommodationReservation({}, _current_booked_display_order);
			_trip_request.booked_accommodations.push(accommodation);
			_current_booked_display_order++;
			break;
		case ManagementConstants.INIT_CAR_RESERVATION:
			var car = new CarReservation({}, _current_booked_display_order);
			_trip_request.booked_cars.push(car);
			_current_booked_display_order++;
			break;

		///

		case ManagementConstants.CANCEL_NON_INIT_TRIP_REQUEST_COMPONENT:
			removeTripRequestComponent(action.data);
			break;

		// case ManagementConstants.CANCEL_NON_INIT_TRIP_REQUEST_FLIGHT:
		//   for (var i = 0; i < _trip_request.flights.length; i++) {
		//     if (_trip_request.flights[i].display_order == action.data.display_order) {
		//       _trip_request.flights.splice(i, 1);
		//       break;
		//     }
		//   }
		//   break;
		// case ManagementConstants.CANCEL_NON_INIT_TRIP_REQUEST_ACCOMMODATION:
		//   for (var i = 0; i < _trip_request.accommodations.length; i++) {
		//     if (_trip_request.accommodations[i].display_order == action.data.display_order) {
		//       _trip_request.accommodations.splice(i, 1);
		//       break;
		//     }
		//   }
		//   break;
		// case ManagementConstants.CANCEL_NON_INIT_TRIP_REQUEST_CAR:
		//   for (var i = 0; i < _trip_request.cars.length; i++) {
		//     if (_trip_request.cars[i].display_order == action.data.display_order) {
		//       _trip_request.cars.splice(i, 1);
		//       break;
		//     }
		//   }
		//   break;

		////////////////////////////////////////////////////////////////////////////////

		case ManagementConstants.UPDATE_TRIP_REQUEST_NAME:
			if (_trip_request.name !== action.data) {
				// experimental
				_trip_request.name = action.data;
			}
			break;

		case ManagementConstants.ADD_TRIP_REQUEST_PURPOSE:
			_trip_request.purposes.push(action.data);
			break;

		case ManagementConstants.REMOVE_TRIP_REQUEST_PURPOSE:
			var purpose_index = _trip_request.purposes.indexOf(action.data);
			_trip_request.purposes.splice(purpose_index, 1);
			break;

		case ManagementConstants.UPDATE_TRIP_REQUEST_PURPOSES:
			_trip_request.purposes = action.data;
			break;

		// case ManagementConstants.ADD_TRIP_REQUEST_COST_CENTER:
		//   _trip_request.cost_centers.push(action.data);
		//   break;
		//
		// case ManagementConstants.REMOVE_TRIP_REQUEST_COST_CENTER:
		//   _trip_request.cost_centers.splice(action.data, 1);
		//   break;

		case ManagementConstants.UPDATE_TRIP_REQUEST_COST_CENTER:
			_trip_request.cost_center = action.data;
			break;

		case ManagementConstants.ADD_TRIP_REQUEST_TRAVELER:
			_trip_request.travelers.push(action.data);
			break;

		case ManagementConstants.REMOVE_TRIP_REQUEST_TRAVELER:
			var idx = -1;
			for (var i = 0; i < _trip_request.travelers.length; i++) {
				if (_trip_request.travelers[i].id === action.data.id) {
					idx = i;
					break;
				}
			}
			if (idx >= 0) {
				_trip_request.travelers.splice(idx, 1);
			}
			break;

		case ManagementConstants.UPDATE_TRIP_REQUEST_STAYING_WITH:
			_trip_request.staying_with_text = action.data;
			break;

		case ManagementConstants.UPDATE_TRIP_REQUEST_SCHEDULE_AND_AGENDA:
			_trip_request.trips_schedule_and_agenda_text = action.data;
			break;

		case ManagementConstants.UPDATE_TRIP_REQUEST_SELF_TRAVEL:
			_trip_request.self_travel = action.data;
			break;

		case ManagementConstants.UPDATE_TRIP_REQUEST_CONFERENCE_NAME_OR_WEBSITE:
			_trip_request.conference_name_or_website = action.data;
			break;

		case ManagementConstants.UPDATE_TRIP_REQUEST_CONFERENCE_ESTIMATED_COST_CURRENCY:
			_trip_request.conference_estimated_cost_currency = action.data;
			break;

		case ManagementConstants.UPDATE_TRIP_REQUEST_CONFERENCE_ESTIMATED_COST_AMOUNT:
			_trip_request.conference_estimated_cost_amount = Number(action.data);
			break;

		case ManagementConstants.UPDATE_TRIP_REQUEST_SPECIAL_REQUESTS:
			_trip_request.special_requests = action.data;
			break;

		case ManagementConstants.UPDATE_TRIP_REQUEST_VACATION_DAYS_FROM:
			_trip_request.vacation_days_from = action.data;
			break;

		case ManagementConstants.UPDATE_TRIP_REQUEST_VACATION_DAYS_TO:
			_trip_request.vacation_days_to = action.data;
			break;

		case ManagementConstants.CLEAR_REQUEST_VACATION_DAYS:
			_trip_request.vacation_days_from = _trip_request.vacation_days_to = null;
			break;

		////////////////////////////////////////////////////////////////////////////////

		case ManagementConstants.UPDATE_TRIP_REQUEST_FLIGHT_FROM:
			console.log('UPDATE FROM, ', action.data);
			updateComponentField(action.data.flight, 'from', action.data.from);
			break;

		case ManagementConstants.SWITCH_TRIP_REQUEST_FLIGHT_FROM_TO:
			console.log('SWITCH payload, ', action.data);
			var trip_flight = getComponentByDisplayOrder(action.data);
			console.log('SWITCH flight-init, ', JSON.stringify(trip_flight));
			if (trip_flight) {
				var from = trip_flight.from;
				trip_flight.from = trip_flight.to;
				trip_flight.to = from;
				console.log('SWITCH flight-switched, ', JSON.stringify(trip_flight));
			}
			break;

		case ManagementConstants.UPDATE_TRIP_REQUEST_FLIGHT_TO:
			updateComponentField(action.data.flight, 'to', action.data.to);
			break;

		case ManagementConstants.UPDATE_TRIP_REQUEST_FLIGHT_ONE_WAY:
			updateComponentField(action.data.flight, 'one_way', action.data.one_way);
			break;

		case ManagementConstants.UPDATE_TRIP_REQUEST_FLIGHT_DEPART_DATE:
			updateComponentField(action.data.flight, 'depart_date', action.data.depart_date);
			break;

		case ManagementConstants.UPDATE_TRIP_REQUEST_FLIGHT_DEPART_TIME:
			updateComponentField(action.data.flight, 'depart_time', action.data.depart_time);
			break;

		case ManagementConstants.UPDATE_TRIP_REQUEST_FLIGHT_RETURN_DATE:
			updateComponentField(action.data.flight, 'return_date', action.data.return_date);
			break;

		case ManagementConstants.UPDATE_TRIP_REQUEST_FLIGHT_RETURN_TIME:
			updateComponentField(action.data.flight, 'return_time', action.data.return_time);
			break;

		////////////////////////////////////////////////////////////////////////////////

		case ManagementConstants.UPDATE_TRIP_REQUEST_ACCOMMODATION_DESTINATION:
			updateComponentField(action.data.accommodation, 'destination', action.data.destination);
			break;

		case ManagementConstants.UPDATE_TRIP_REQUEST_ACCOMMODATION_DESTINATION_ADDRESS:
			let accommodation_component = getComponentByDisplayOrder(action.data.accommodation);
			accommodation_component.destination.address = action.data.address;
			break;

		case ManagementConstants.UPDATE_TRIP_REQUEST_ACCOMMODATION_CHECK_IN:
			updateComponentField(action.data.accommodation, 'check_in', action.data.check_in);
			// TODO: update checkout if needed
			break;

		case ManagementConstants.UPDATE_TRIP_REQUEST_ACCOMMODATION_CHECK_OUT:
			updateComponentField(action.data.accommodation, 'check_out', action.data.check_out);
			// TODO: update checkin if needed
			break;

		case ManagementConstants.UPDATE_TRIP_REQUEST_ACCOMMODATION_CHECK_IN_SPECIAL_REQUEST:
			switch (action.data.special_request) {
				case ManagementConstants.CHECK_IN_SPECIAL_REQUESTS.EARLY_CHECK_IN:
					updateComponentField(action.data.accommodation, 'early_check_in', true);
					updateComponentField(action.data.accommodation, 'late_check_in', false);
					break;
				case ManagementConstants.CHECK_IN_SPECIAL_REQUESTS.LATE_CHECK_IN:
					updateComponentField(action.data.accommodation, 'early_check_in', false);
					updateComponentField(action.data.accommodation, 'late_check_in', true);
					break;
				default:
					updateComponentField(action.data.accommodation, 'early_check_in', false);
					updateComponentField(action.data.accommodation, 'late_check_in', false);
					break;
			}
			break;

		case ManagementConstants.UPDATE_TRIP_REQUEST_ACCOMMODATION_CHECK_OUT_SPECIAL_REQUEST:
			if (action.data.special_request) {
				updateComponentField(action.data.accommodation, 'late_check_out', true);
			} else {
				updateComponentField(action.data.accommodation, 'late_check_out', false);
			}
			break;

		////////////////////////////////////////////////////////////////////////////////

		case ManagementConstants.UPDATE_TRIP_REQUEST_CAR_PICK_UP:
			updateComponentField(action.data.car, 'pick_up', action.data.pick_up);
			break;

		case ManagementConstants.UPDATE_TRIP_REQUEST_CAR_AIRPORT:
			updateComponentField(action.data.car, 'airport', action.data.airport);
			break;

		case ManagementConstants.UPDATE_TRIP_REQUEST_CAR_DROP_OFF:
			updateComponentField(action.data.car, 'drop_off', action.data.drop_off);
			break;

		case ManagementConstants.UPDATE_TRIP_REQUEST_CAR_PICK_UP_DATE:
			updateComponentField(action.data.car, 'pick_up_date', action.data.pick_up_date);
			break;

		case ManagementConstants.UPDATE_TRIP_REQUEST_CAR_DROP_OFF_DATE:
			updateComponentField(action.data.car, 'drop_off_date', action.data.drop_off_date);
			break;

		////////////////////////////////////////////////////////////////////////////////

		case ManagementConstants.SAVE_TRIP_REQUEST_AS_DRAFT:
			setAboutToSaveChangesStatus(true);
			_draft_status = ManagementConstants.STATUS.BUSY;
			break;

		// Respond to RECEIVE_DATA action
		case ManagementConstants.TRIP_REQUEST_DRAFT_SAVED:
			_draft_status = ManagementConstants.STATUS.SUCCESS;
			setAboutToSaveChangesStatus(false);
			_trip_request_error = false;
			break;

		case ManagementConstants.TRIP_REQUEST_DRAFT_FAILED:
			_draft_status = ManagementConstants.STATUS.FAILED;
			_trip_request_error = true;
			break;

		case ManagementConstants.RESET_TRIP_REQUEST_DRAFT_STATUS:
			_draft_status = ManagementConstants.STATUS.INITIAL;
			break;

		case ManagementConstants.ABOUT_TO_SAVE_CHANGES:
			let status = true;
			if (action.data && action.data.status === false) {
				status = false;
			}
			setAboutToSaveChangesStatus(status);
			break;

		case ManagementConstants.EDIT_TRIP_REQUEST_META:
			_edit_meta_status = ManagementConstants.STATUS.BUSY;
			// _draft_status = ManagementConstants.STATUS.BUSY;
			break;

		// Respond to RECEIVE_DATA action
		case ManagementConstants.TRIP_REQUEST_META_EDITED:
			_edit_meta_status = ManagementConstants.STATUS.SUCCESS;
			// _draft_status = ManagementConstants.STATUS.SUCCESS;
			setAboutToSaveChangesStatus(false);
			console.log('EDIT META EDIT RESPONSE', action.data);
			_trip_request_snapshot = action.data.snapshot;
			break;

		case ManagementConstants.TRIP_REQUEST_META_EDIT_FAILED:
			_edit_meta_status = ManagementConstants.STATUS.FAILED;
			// _draft_status = ManagementConstants.STATUS.FAILED;
			break;

		case ManagementConstants.RESET_EDIT_TRIP_REQUEST_META_STATUS:
			_edit_meta_status = ManagementConstants.STATUS.IDLE;
			// _draft_status = ManagementConstants.STATUS.IDLE;
			break;

		////////////////////////////////////////////////////////////////////////////////

		case ManagementConstants.CREATE_TRIP_REQUEST_COMPONENT:
			_create_component_status = ManagementConstants.STATUS.BUSY;
			break;

		case ManagementConstants.TRIP_REQUEST_COMPONENT_CREATED:
			_create_component_status = ManagementConstants.STATUS.SUCCESS;
			setComponentId(action.data.component, action.data.response._id);
			break;

		case ManagementConstants.TRIP_REQUEST_COMPONENT_FAILED_TO_CREATE:
			_create_component_status = ManagementConstants.STATUS.FAILED;
			break;

		case ManagementConstants.RESET_CREATE_TRIP_REQUEST_COMPONENT_STATUS:
			_create_component_status = ManagementConstants.STATUS.IDLE;
			break;

		////////////////////////////////////////

		case ManagementConstants.UPDATE_TRIP_REQUEST_COMPONENT:
			_update_component_status = ManagementConstants.STATUS.BUSY;
			break;

		case ManagementConstants.TRIP_REQUEST_COMPONENT_UPDATED:
			_update_component_status = ManagementConstants.STATUS.SUCCESS;
			break;

		case ManagementConstants.UPDATE_TRIP_LATER_REQUEST:
			_update_trip_request = action.data;
			break;

		case ManagementConstants.CLEAR_TRIP_LATER_REQUEST:
			_update_trip_request = null;
			break;

		case ManagementConstants.TRIP_REQUEST_COMPONENT_FAILED_TO_UPDATE:
			_update_component_status = ManagementConstants.STATUS.FAILED;
			break;

		case ManagementConstants.RESET_UPDATE_TRIP_REQUEST_COMPONENT_STATUS:
			_update_component_status = ManagementConstants.STATUS.IDLE;
			break;

		////////////////////////////////////////

		case ManagementConstants.REMOVE_TRIP_REQUEST_COMPONENT:
			_remove_component_status = ManagementConstants.STATUS.BUSY;
			break;

		case ManagementConstants.TRIP_REQUEST_COMPONENT_REMOVED:
			// eslint-disable-next-line eqeqeq
			if (action.data.response == true) {
				removeTripRequestComponent(action.data.component);
				_remove_component_status = ManagementConstants.STATUS.SUCCESS;
			} else {
				_remove_component_status = ManagementConstants.STATUS.FAILED;
			}
			break;

		case ManagementConstants.TRIP_REQUEST_COMPONENT_FAILED_TO_REMOVE:
			_remove_component_status = ManagementConstants.STATUS.FAILED;
			break;

		case ManagementConstants.RESET_REMOVE_TRIP_REQUEST_COMPONENT_STATUS:
			_remove_component_status = ManagementConstants.STATUS.IDLE;
			break;

		////////////////////////////////////////

		case ManagementConstants.SUBMIT_TRIP_REQUEST:
			_submit_status = ManagementConstants.STATUS.BUSY;
			break;

		case ManagementConstants.TRIP_REQUEST_SUBMITTED:
			_submit_status = ManagementConstants.STATUS.SUCCESS;
			_trip_request_error = false;
			break;

		case ManagementConstants.TRIP_REQUEST_SUBMIT_FAILED:
			_submit_status = ManagementConstants.STATUS.FAILED;
			_trip_request_error = true;
			break;

		case ManagementConstants.RESET_TRIP_REQUEST_SUBMIT_STATUS:
			_submit_status = ManagementConstants.STATUS.IDLE;
			break;

		////////////////////////////////////////////////////////////////////////////////

		case ManagementConstants.GET_TRIP_REQUESTS:
			_trip_requests_status = ManagementConstants.STATUS.BUSY;
			break;
		case ManagementConstants.GET_TRIP_REQUESTS_SUCCESS:
			if (action.data.trips && Array.isArray(action.data.trips)) {
				_trip_requests = action.data.trips.map((trip_request) => new TripRequest(trip_request));
				_trip_requests_status = ManagementConstants.STATUS.SUCCESS;
			} else {
				console.error('WTF - no trips?!');
				_trip_requests_status = ManagementConstants.STATUS.FAILED;
			}
			break;
		case ManagementConstants.GET_TRIP_REQUESTS_FAIL:
			_trip_requests_status = ManagementConstants.STATUS.FAILED;
			break;

		////////////////////////////////////////////////////////////////////////////////

		case ManagementConstants.GET_TRIP_REQUEST_BY_ID:
			_trip_request_by_id_status = ManagementConstants.STATUS.BUSY;
			break;
		case ManagementConstants.GET_TRIP_REQUEST_BY_ID_SUCCESS:
			_trip_request = new TripRequest(action.data);
			_current_trip_request_id = _trip_request.id;
			let components_display_orders = _trip_request.flights
				.concat(_trip_request.accommodations)
				// .concat(_trip_request.cars)
				.map((comp) => comp.display_order);
			if (components_display_orders.length) {
				components_display_orders.forEach(function (display_order) {
					if (display_order > _current_display_order) {
						_current_display_order = display_order;
					}
				});
				_current_display_order++;
			}
			_trip_request_by_id_status = ManagementConstants.STATUS.SUCCESS;
			_trip_request_error = false;
			updateTripRequestSnapshot();
			break;
		case ManagementConstants.GET_TRIP_REQUEST_BY_ID_FAIL:
			_trip_request_by_id_status = ManagementConstants.STATUS.FAILED;
			break;

		////////////////////////////////////////////////////////////////////////////////

		case ManagementConstants.PREVIEW_TRIP:
			clearTripPreview();
			_previewed_trip = action.data;
			break;

		case ManagementConstants.CLEAR_TRIP_PREVIEW:
			clearTripPreview();
			break;

		case ManagementConstants.PREVIEW_BOOKED_COMPONENT:
			clearTripPreview();
			_previewed_tips_source = ManagementConstants.SOURCES.TRIP;
			_previewed_booked_component = action.data;
			console.log('PREVIEW booked component', _previewed_booked_component);
			break;

		case ManagementConstants.PREVIEW_AIRFARE_TIPS:
			clearTripPreview();
			_previewed_tips_source = ManagementConstants.SOURCES.ADD_AIRFARE_WIZARD;
			break;

		case ManagementConstants.PREVIEW_TRIP_COST_ESTIMATION:
			clearTripPreview();
			_previewed_tips_source = ManagementConstants.SOURCES.COST_ESTIMATION;
			break;
		////////////////////////////////////////////////////////////////////////////////

		case ManagementConstants.RESET_TRIP_REQUEST:
			clearTripPreview();
			_trip_request = new TripRequest({});
			_trip_request_error = false;
			break;

		////////////////////////////////////////////////////////////////////////////////

		case ManagementConstants.ADD_WIZARD_DESTINATION:
			_wizard_destinations.push(new WizardDestination(action.data, _current_wizard_display_order));
			_current_wizard_display_order++;
			break;

		case ManagementConstants.UPDATE_WIZARD_DESTINATION_LOCATION:
			updateDestinationField(action.data.destination, 'destination', action.data.location);
			break;

		case ManagementConstants.UPDATE_WIZARD_DESTINATION_START_DATE:
			updateDestinationField(action.data.destination, 'start_date', action.data.start_date);
			break;

		case ManagementConstants.UPDATE_WIZARD_DESTINATION_END_DATE:
			updateDestinationField(action.data.destination, 'end_date', action.data.end_date);
			break;

		case ManagementConstants.CLEAR_WIZARD_DESTINATION:
			updateDestinationField(action.data, 'destination', { address: '', place: null });
			updateDestinationField(action.data, 'start_date', null);
			updateDestinationField(action.data, 'end_date', null);
			break;

		case ManagementConstants.REMOVE_WIZARD_DESTINATION:
			removeDestination(action.data);
			break;

		case ManagementConstants.RESET_WIZARD_DESTINATIONS:
			resetWizardDestinations();
			break;

		case ManagementConstants.SUBMIT_WIZARD_DESTINATIONS:
			_submit_wizard_destinations_status = ManagementConstants.STATUS.BUSY;
			// reset trip request
			break;

		case ManagementConstants.SUBMIT_WIZARD_DESTINATIONS_SUCCESS:
			_submit_wizard_destinations_status = ManagementConstants.STATUS.SUCCESS;
			console.log('SUBMIT WIZARD DESTINATIONS SUCCESS IN STORE', action.data);
			// TODO: do shit
			_trip_request = null; //new TripRequest(action.data, profile);
			_current_trip_request_id = action.data._id;
			_trip_request_page_source = ManagementConstants.SOURCES.WIZARD;
			_trip_request_error = false;
			break;

		case ManagementConstants.SUBMIT_WIZARD_DESTINATIONS_FAIL:
			_submit_wizard_destinations_status = ManagementConstants.STATUS.FAILED;
			console.log('SUBMIT WIZARD DESTINATIONS FAIL IN STORE', action.data);
			// TODO: do shit
			break;

		////////////////////////////////////////////////////////////////////////////////

		case ManagementConstants.DISCARD_TRIP_REQUEST:
			_discard_trip_request_status = ManagementConstants.STATUS.BUSY;
			break;

		case ManagementConstants.DISCARD_TRIP_REQUEST_SUCCESS:
			_discard_trip_request_status = ManagementConstants.STATUS.SUCCESS;
			removeTripRequestFromTrips(action.data.trip_request);
			break;

		case ManagementConstants.DISCARD_TRIP_REQUEST_FAIL:
			_discard_trip_request_status = ManagementConstants.STATUS.FAILED;
			break;

		case ManagementConstants.CANCEL_TRIP_REQUEST:
			_cancel_trip_request_status = ManagementConstants.STATUS.BUSY;
			break;

		case ManagementConstants.CANCEL_TRIP_REQUEST_SUCCESS:
			_cancel_trip_request_status = ManagementConstants.STATUS.SUCCESS;
			_trip_request.status = ManagementConstants.TRIP_STATUS.CANCELED;
			break;

		case ManagementConstants.CANCEL_TRIP_REQUEST_FAIL:
			_cancel_trip_request_status = ManagementConstants.STATUS.FAILED;
			break;

		case ManagementConstants.SEND_TRIP_FOR_APPROVAL:
			_send_for_approval_status = ManagementConstants.STATUS.BUSY;
			break;

		case ManagementConstants.SEND_TRIP_FOR_APPROVAL_SUCCESS:
			_send_for_approval_status = ManagementConstants.STATUS.SUCCESS;
			_trip_request_error = false;
			break;

		case ManagementConstants.SEND_TRIP_FOR_APPROVAL_FAIL:
			_send_for_approval_status = ManagementConstants.STATUS.FAILED;
			_trip_request_error = true;
			break;

		case ManagementConstants.SEND_TRIP_FOR_PRE_APPROVAL:
			_send_for_approval_status = ManagementConstants.STATUS.BUSY;
			break;

		case ManagementConstants.SEND_TRIP_FOR_PRE_APPROVAL_SUCCESS:
			_send_for_approval_status = ManagementConstants.STATUS.SUCCESS;
			_trip_request_error = false;
			break;

		case ManagementConstants.SEND_TRIP_FOR_PRE_APPROVAL_FAIL:
			_send_for_approval_status = ManagementConstants.STATUS.FAILED;
			_trip_request_error = true;
			break;

		case ManagementConstants.CLONE_TRIP_CLEAR:
			_clone_status = ManagementConstants.STATUS.IDLE;
			_cloned_trip_id = '';
			break;

		case ManagementConstants.CLONE_TRIP:
			_clone_status = ManagementConstants.STATUS.BUSY;
			_cloned_trip_id = '';
			break;

		case ManagementConstants.CLONE_TRIP_SUCCESS:
			_clone_status = ManagementConstants.STATUS.SUCCESS;
			_cloned_trip_id = action.data && action.data._id ? action.data._id : '';
			break;

		case ManagementConstants.CLONE_TRIP_FAIL:
			_clone_status = ManagementConstants.STATUS.FAILED;
			break;

		case ManagementConstants.FORWARD_TRIP_REQUEST:
			_forward_trip_request_status = ManagementConstants.STATUS.BUSY;
			break;

		case ManagementConstants.FORWARD_TRIP_REQUEST_SUCCESS:
			_forward_trip_request_status = ManagementConstants.STATUS.SUCCESS;
			break;

		case ManagementConstants.FORWARD_TRIP_REQUEST_FAIL:
			_forward_trip_request_status = ManagementConstants.STATUS.FAILED;
			break;
		////////////////////////////////////////////////////////////////////////////////

		case ManagementConstants.UPDATE_TRIP_REQUEST_PAGE_SOURCE:
			_trip_request_page_source = action.data;
			break;

		case ManagementConstants.UPDATE_TRIP_REQUEST_TRAVELER:
			_trip_request.travelers = action.data ? [action.data] : [];
			break;

		////////////////////////////////////////////////////////////////////////////////

		case ManagementConstants.UPDATE_AIRFARE_WIZARD_FLIGHT_NUMBER:
			updateAirfareWizardFlightField(action.data.flight, 'flight_number', action.data.flight_number);
			break;

		case ManagementConstants.UPDATE_AIRFARE_WIZARD_FLIGHT_DEPARTURE_DATE:
			updateAirfareWizardFlightField(action.data.flight, 'departure_date', action.data.departure_date);
			break;

		case ManagementConstants.REMOVE_AIRFARE_WIZARD_FLIGHT:
			removeAirfareWizardFlight(action.data);
			break;

		case ManagementConstants.ADD_AIRFARE_WIZARD_FLIGHT:
			_airfare_wizard_flights.push(new WizardFlight(null, _current_airfare_wizard_display_order));
			_current_airfare_wizard_display_order++;
			break;

		case ManagementConstants.RESET_AIRFARE_WIZARD_FLIGHTS:
			resetAirfareWizardFlights();
			break;

		case ManagementConstants.UPDATE_AIRFARE_WIZARD_COST:
			_airfare_wizard_cost = action.data;
			break;

		case ManagementConstants.UPDATE_AIRFARE_WIZARD_CURRENCY:
			_airfare_wizard_currency = action.data;
			break;

		case ManagementConstants.CLEAR_AIRFARE_WIZARD:
			resetAirfareWizardFlights();
			_airfare_wizard_cost = '';
			break;

		////////////////////////////////////////////////////////////////////////////////

		case ManagementConstants.CREATE_TRIP_AIRFARE:
			_create_trip_airfare_status = ManagementConstants.STATUS.BUSY;
			break;

		case ManagementConstants.CREATE_TRIP_AIRFARE_SUCCESS:
			_create_trip_airfare_status = ManagementConstants.STATUS.SUCCESS;
			_trip_request.booked_itinerary.airfares.push(new Airfare(action.data.trip.airfare));
			_trip_request_page_source = action.data.source;

			resetAirfareWizardFlights();
			_airfare_wizard_cost = '';
			break;

		case ManagementConstants.CREATE_TRIP_AIRFARE_FAIL:
			_create_trip_airfare_status = ManagementConstants.STATUS.FAILED;
			break;

		case ManagementConstants.REMOVE_TRIP_AIRFARE:
			_remove_trip_airfare_status = ManagementConstants.STATUS.BUSY;
			break;

		case ManagementConstants.REMOVE_TRIP_AIRFARE_SUCCESS:
			_remove_trip_airfare_status = ManagementConstants.STATUS.SUCCESS;
			_trip_request.booked_itinerary.airfares = _trip_request.booked_itinerary.airfares.filter(
				(airfare) => airfare.id !== action.data.airfare.id,
			);
			break;

		case ManagementConstants.REMOVE_TRIP_AIRFARE_FAIL:
			_remove_trip_airfare_status = ManagementConstants.STATUS.FAILED;
			break;

		case ManagementConstants.REMOVE_TRIP_ACCOMMODATION:
			_remove_trip_accommodation_status = ManagementConstants.STATUS.BUSY;
			break;

		case ManagementConstants.REMOVE_TRIP_ACCOMMODATION_SUCCESS:
			_remove_trip_accommodation_status = ManagementConstants.STATUS.SUCCESS;
			_trip_request.booked_itinerary.accommodations = _trip_request.booked_itinerary.accommodations.filter(
				(accommodation) => accommodation.id !== action.data.accommodation.id,
			);
			break;

		case ManagementConstants.REMOVE_TRIP_ACCOMMODATION_FAIL:
			_remove_trip_accommodation_status = ManagementConstants.STATUS.FAILED;
			break;

		case ManagementConstants.CANCEL_TRIP_HOTEL_BOOKING:
			_cancel_trip_hotel_booking_status = ManagementConstants.STATUS.BUSY;
			break;

		case ManagementConstants.CANCEL_TRIP_HOTEL_BOOKING_SUCCESS:
			for (let i = 0; i < _trip_request.booked_itinerary.accommodations.length; i++) {
				if (_trip_request.booked_itinerary.accommodations[i].id === action.data.hotel_booking.id) {
					_trip_request.booked_itinerary.accommodations[i].status =
						ReservationConstants.RESERVATION_STATUS.CANCELED;
				}
			}
			_cancel_trip_hotel_booking_status = ManagementConstants.STATUS.SUCCESS;
			break;

		case ManagementConstants.CANCEL_TRIP_HOTEL_BOOKING_FAIL:
			_cancel_trip_hotel_booking_status = ManagementConstants.STATUS.FAILED;
			break;

		default:
			return true;
	}

	// If action was responded to, emit change event
	ManagementStore.emitChange();

	return true;
});

export default ManagementStore;
