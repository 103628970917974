import { useState, useEffect, useRef } from 'react';
import ProfileStore from '@/stores/ProfileStore';
import TravelPolicyStore from '@/stores/TravelPolicyStore';
import TravelPolicyActions from '@/actions/TravelPolicyActions';
import TravelPolicyConstants from '@/constants/TravelPolicyConstants';
import Currencies from '@/utils/Currencies';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	TextField,
	Typography,
	InputAdornment,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { StyledDialogCancelButton, StyledDialogAddButton } from './components/styled/buttons';
import GooglePlacesAutocomplete from '@/components/general/google-places-autocomplete/GooglePlacesAutocomplete';
import { SnackbarVariants } from '../../general/snackbar/SnackbarConstants';

const useStyles = makeStyles((theme) => ({
	dialog: {
		padding: theme.spacing(1),
		width: 500,
	},
	title: {
		fontWeight: 700,
		fontSize: 21,
	},
	priceLabel: {
		marginTop: 8,
	},
	locationLabel: {
		marginBottom: 8,
	},
	priceInput: {
		'& .MuiInputBase-root': {
			height: 42,

			'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
				borderColor: theme.palette.blue.secondary,
			},
			'&:hover .MuiOutlinedInput-notchedOutline': {
				borderColor: theme.palette.blue.secondary,
			},
			'&:focus-within': {
				backgroundColor: theme.palette.blue.lightBlue,
			},
		},
	},
	currencySymbol: {
		'& .MuiTypography-root': {
			marginRight: -4,
			fontSize: 15,
			color: theme.palette.black.main,
		},
	},
}));

const initialState = {
	location: '',
	country: '',
	state: '',
	city: '',
	countryCode: '',
	price: '',
	placeId: '',
};

const SnackbarMessages = {
	SUCCESS: 'Travel policy successfully added',
	ERROR: 'Failed to add travel policy',
};

const AddTravelPolicyDialog = ({ open, onClose, onSnackbarShow, onEntryAdded }) => {
	const classes = useStyles();
	const [addNewModalInputs, setAddNewModalInputs] = useState(initialState);
	const priceInputRef = useRef(null);
	const profile = ProfileStore.getProfile();

	useEffect(() => {
		TravelPolicyStore.addChangeListener(_onChange);

		return () => {
			TravelPolicyStore.removeChangeListener(_onChange);
		};
	}, []);

	const _onChange = () => {
		const status = TravelPolicyStore.getSavingStatus();
		let message, variant;

		if (status === TravelPolicyConstants.STATUS.SUCCESS) {
			const newEntryId = TravelPolicyStore.getNewEntryId();
			message = SnackbarMessages.SUCCESS;
			variant = SnackbarVariants.SUCCESS;
			onEntryAdded(newEntryId);
		} else if (status === TravelPolicyConstants.STATUS.FAILED) {
			message = TravelPolicyStore.getErrorMessage() ?? SnackbarMessages.ERROR;
			variant = SnackbarVariants.ERROR;
		}

		if (message && variant) {
			onSnackbarShow(message, variant);
			setTimeout(() => {
				TravelPolicyActions.resetTravelPolicyStatus();
			}, 0);
		}
	};

	useEffect(() => {
		if (open) {
			setAddNewModalInputs(initialState);
		}
	}, [open]);

	const handleAutocompleteSelect = (location, { country, city, state, countryCode, placeId }) => {
		setAddNewModalInputs({
			...addNewModalInputs,
			location: location.description,
			country,
			state,
			city,
			countryCode,
			placeId,
		});

		priceInputRef.current?.focus();
	};

	const handlePriceChange = (e) => {
		const newValue = e.target.value;

		// Allow only numeric input
		if (/^\d*$/.test(newValue)) {
			setAddNewModalInputs({
				...addNewModalInputs,
				price: newValue,
			});
		}
	};

	const isFormValid = () => {
		const { location, countryCode, placeId, price } = addNewModalInputs;

		const isStringAndNotEmpty = (value) => typeof value === 'string' && value.trim() !== '';

		const isValidPrice = (value) => {
			const parsedPrice = Number(value);
			return !isNaN(parsedPrice) && parsedPrice > 0;
		};

		return (
			isStringAndNotEmpty(location) &&
			isStringAndNotEmpty(countryCode) &&
			isStringAndNotEmpty(placeId) &&
			isValidPrice(price)
		);
	};

	const handleAdd = () => {
		const { price, country, state, city, countryCode, placeId } = addNewModalInputs;

		TravelPolicyActions.addTravelPolicyEntry({
			price,
			place: {
				country,
				state,
				city,
				countryCode,
				placeId,
			},
		});

		onClose();
	};

	return (
		<Dialog open={open} onClose={onClose} PaperProps={{ className: classes.dialog }}>
			<DialogTitle className={classes.title}>New Location</DialogTitle>
			<DialogContent>
				<Typography variant="body1" className={classes.locationLabel}>
					Location
				</Typography>
				<GooglePlacesAutocomplete
					onAutocompleteSelect={handleAutocompleteSelect}
					placeholder="City or country"
					autoFocus
				/>
				<Typography variant="body1" className={classes.priceLabel}>
					Budget
				</Typography>
				<TextField
					inputRef={priceInputRef}
					className={classes.priceInput}
					fullWidth
					margin="dense"
					inputMode="decimal"
					variant="outlined"
					value={addNewModalInputs.price}
					onChange={handlePriceChange}
					InputProps={{
						startAdornment: (
							<InputAdornment className={classes.currencySymbol} position="start">
								{Currencies.getSymbol(profile.currency)}
							</InputAdornment>
						),
					}}
				/>
			</DialogContent>
			<DialogActions>
				<StyledDialogCancelButton onClick={onClose}>Cancel</StyledDialogCancelButton>
				<StyledDialogAddButton onClick={handleAdd} disabled={!isFormValid()}>
					Add
				</StyledDialogAddButton>
			</DialogActions>
		</Dialog>
	);
};

export default AddTravelPolicyDialog;
