const Events = require('../events/index');
const Core = require('../core');
const { getDestinationObject } = require('../services/destinationService');
const { getUserObject } = require('../services/userService');
const { getSearchTermsObject } = require('../services/searchTermsService');
const { getDealObject } = require('../services/dealService');
const { getRoomObject } = require('../services/roomService');
const { getPointsObject } = require('../services/pointsService');
const { getCompanyObject } = require('../services/companyService');
const { getTravelPolicyEntryObject } = require('../services/travelPolicyEntryService');

function loggedOut() {
    Core.trackEvent(Events.LOGGED_OUT);
}

function mobileAppInstalled(user) {
    const metadata = {
        ...getUserObject(user)
    };

    Core.trackEvent(Events.MENU.MOBILE_APP_INSTALLED, metadata);
}

function logoClicked(user) {
    const metadata = {
        ...getUserObject(user)
    };

    Core.trackEvent(Events.MENU.LOGO_CLICKED, metadata);
}

function searchClicked(user) {
    const metadata = {
        ...getUserObject(user),
    };

    Core.trackEvent(Events.MENU.SEARCH_CLICKED, metadata);
}

function bookingsClicked(user) {
    const metadata = {
        ...getUserObject(user),
    };

    Core.trackEvent(Events.MENU.BOOKINGS_CLICKED, metadata);
}

function openMenu(user, page_name, page_url) {
    const metadata = {
        ...getUserObject(user),
        page_name,
        page_url
    };

    Core.trackEvent(Events.MENU.OPEN_MENU, metadata);
}

function closeMenu(user, page_name, page_url) {
    const metadata = {
        ...getUserObject(user),
        page_name,
        page_url
    };

    Core.trackEvent(Events.MENU.CLOSE_MENU, metadata);
}

function changedDisplayCurrency(user, from, to) {
    const metadata = {
        ...getUserObject(user),
        from,
        to
    };

    Core.trackEvent(Events.MENU.CHANGED_DISPLAY_CURRENCY, metadata);
}

function changedLanguage(from, to) {
    const metadata = {
        from,
        to
    };

    Core.trackEvent(Events.MENU.CHANGED_LANGUAGE, metadata);
}

function executedSearch(user, destination, search, client, geolocationSearch) {
    const metadata = {
        ...getUserObject(user),
        ...getDestinationObject(destination),
        ...getSearchTermsObject(search),
        client,
        geolocationSearch
    };

    Core.trackEvent(Events.SEARCH.EXECUTED_SEARCH, metadata);
}

function selectedRecentSearch(user, destination, search) {
    const metadata = {
        ...getUserObject(user),
        ...getDestinationObject(destination),
        ...getSearchTermsObject(search)
    };

    Core.trackEvent(Events.SEARCH.SELECTED_RECENT_SEARCH, metadata);
}

function selectedBusinessDestination(user, destination) {
    const metadata = {
        ...getUserObject(user),
        ...getDestinationObject(destination)
    };

    Core.trackEvent(Events.SEARCH.SELECTED_BUSINESS_DESTINATION, metadata);
}

function selectedLeisureDestination(user, destination) {
    const metadata = {
        ...getUserObject(user),
        ...getDestinationObject(destination)
    };

    Core.trackEvent(Events.SEARCH.SELECTED_LEISURE_DESTINATION, metadata);
}

function addedBusinessDestination(destination) {
    const metadata = {
        ...getDestinationObject(destination)
    };

    Core.trackEvent(Events.SEARCH.ADDED_BUSINESS_DESTINATION, metadata);
}

function addedBusinessDestinationFailed(destination, error) {
    const metadata = {
        ...getDestinationObject(destination),
        error
    };

    Core.trackEvent(Events.SEARCH.ADDED_BUSINESS_DESTINATION_FAILED, metadata);
}

function changedSearch(user, destination, search) {
    const metadata = {
        ...getUserObject(user),
        ...getDestinationObject(destination),
        ...getSearchTermsObject(search)
    };

    Core.trackEvent(Events.SEARCH.CHANGED_SEARCH, metadata);
}

function sortedResults(user, sort_method) {
    const metadata = {
        ...getUserObject(user),
        sort_method
    };

    Core.trackEvent(Events.RESULTS.RESULTS_SORTED, metadata);
}

function filteredResults(user, filter_method, filter_value) {
    const metadata = {
        ...getUserObject(user),
        filter_method,
        filter_value
    };

    Core.trackEvent(Events.RESULTS.RESULTS_FILTERED, metadata);
}

function clearedResultsFilters(user) {
    const metadata = {
        ...getUserObject(user)
    };

    Core.trackEvent(Events.RESULTS.RESULTS_CLEARED_FILTERS, metadata);
}

function filteredDeals(user, filter_method, filter_value) {
    const metadata = {
        ...getUserObject(user),
        filter_method,
        filter_value
    };

    Core.trackEvent(Events.RESULTS.FILTERED_DEALS, metadata);
}

function selectedHotel(user, destination, search, recommended_index, recommended_count, sort_method, time_to_click_ml, geolocationSearch) {
    const metadata = {
        ...getUserObject(user),
        ...getDestinationObject(destination),
        ...getSearchTermsObject(search),
        recommended_index,
        recommended_count,
        sort_method,
        time_to_click_ml,
        geolocationSearch,
    };

    Core.trackEvent(Events.RESULTS.SELECTED_HOTEL, metadata);
}

function selectedDeal(user, destination, search, deal, deal_index, deal_count, time_to_click_ml, displayed_deal_order_count,
    total_displayed_deals_count, geolocationSearch) {
    const metadata = {
        ...getUserObject(user),
        ...getDestinationObject(destination),
        ...getSearchTermsObject(search),
        ...getDealObject(deal),
        deal_index,
        deal_count,
        time_to_click_ml,
        displayed_deal_order_count,
        total_displayed_deals_count,
        geolocationSearch
    };

    Core.trackEvent(Events.RESULTS.SELECTED_DEAL, metadata);
}

function showReviewsModal(company, hotel_id, hotel_name) {
    const metadata = {
        ...getCompanyObject(company),
        hotel_id,
        hotel_name
    };

    Core.trackEvent(Events.VIEWS.OPEN_REVIEWS_MODAL, metadata);
}

function showHotelDetailsModal(company, hotel_id, hotel_name) {
    const metadata = {
        ...getCompanyObject(company),
        hotel_id,
        hotel_name
    };

    Core.trackEvent(Events.VIEWS.OPEN_HOTEL_DETAILS_MODAL, metadata);
}


function openResultsMap(user, hotel_id, hotel_name) {
    const metadata = {
        ...getUserObject(user),
        hotel_id,
        hotel_name
    };

    Core.trackEvent(Events.VIEWS.OPEN_RESULTS_MAP, metadata);
}

function closeResultsMap(user, hotel_id, hotel_name) {
    const metadata = {
        ...getUserObject(user),
        hotel_id,
        hotel_name
    };

    Core.trackEvent(Events.VIEWS.CLOSE_RESULTS_MAP, metadata);
}

function openHotelMap(user, hotel_id, hotel_name) {
    const metadata = {
        ...getUserObject(user),
        hotel_id,
        hotel_name
    };

    Core.trackEvent(Events.VIEWS.OPEN_HOTEL_MAP, metadata);
}

function selectedRoomFromCatalog(destination, room, room_index) {
    const metadata = {
        ...getDestinationObject(destination),
        ...getRoomObject(room),
        room_index
    };

    Core.trackEvent(Events.RESULTS.SELECTED_ROOM_FROM_CATALOG, metadata);
}

function requestOutOfPolicyRoom(company, user, out_of_policy_booking_reason) {
    const metadata = {
        ...getCompanyObject(company),
        ...getUserObject(user),
        out_of_policy_booking_reason
    };

    Core.trackEvent(Events.RESERVATION.REQUEST_OUT_OF_POLICY_ROOM, metadata);
}

function pickedTravelerFromAutocomplete(company, reservation_id, first_name, last_name, email) {
    const metadata = {
        ...getCompanyObject(company),
        reservation_id,
        first_name,
        last_name,
        email
    };

    Core.trackEvent(Events.RESERVATION.PICKED_TRAVELER_FROM_AUTOCOMPLETE, metadata);
}

function removedTraveler(reservation_id, first_name, last_name, email) {
    const metadata = { reservation_id, first_name, last_name, email };
    Core.trackEvent(Events.RESERVATION.REMOVED_TRAVELER, metadata);
}

function proceededToPayment(user) {
    const metadata = {
        ...getUserObject(user)
    };

    Core.trackEvent(Events.PAYMENT.PROCEEDED_TO_PAYMENT, metadata);
}

function accountPayment(user, destination, search, deal, points) {
    const metadata = {
        ...getUserObject(user),
        ...getDestinationObject(destination),
        ...getSearchTermsObject(search),
        ...getDealObject(deal),
        ...getPointsObject(points)
    };

    Core.trackEvent(Events.PAYMENT.ACCOUNT_PAYMENT, metadata);
}

function GCashPayment(user, destination, search, deal) {
    const metadata = {
        ...getUserObject(user),
        ...getDestinationObject(destination),
        ...getSearchTermsObject(search),
        ...getDealObject(deal)
    };

    Core.trackEvent(Events.PAYMENT.GCASH_PAYMENT, metadata);
}


function GCashPaymentSuccess(user, resultCode) {
    const metadata = {
        ...getUserObject(user),
        resultCode
    };

    Core.trackEvent(Events.PAYMENT.GCASH_PAYMENT_SUCCESS, metadata);
}

function GCashPaymentError(user, error) {
    const metadata = {
        ...getUserObject(user),
        error
    };

    Core.trackEvent(Events.PAYMENT.GCASH_PAYMENT_ERROR, metadata);
}

function creditCardPayment(user, destination, search, deal, points) {
    const metadata = {
        ...getUserObject(user),
        ...getDestinationObject(destination),
        ...getSearchTermsObject(search),
        ...getDealObject(deal),
        ...getPointsObject(points)
    };

    Core.trackEvent(Events.PAYMENT.CREDIT_CARD_PAYMENT, metadata);
}

function pointsOnlyPayment(user, destination, search, deal, points) {
    const metadata = {
        ...getUserObject(user),
        ...getDestinationObject(destination),
        ...getSearchTermsObject(search),
        ...getDealObject(deal),
        ...getPointsObject(points)
    };

    Core.trackEvent(Events.PAYMENT.POINTS_ONLY_PAYMENT, metadata);
}

function addedAsQuote(trip_id, trip_name, traveler_id, traveler_name, traveler_email) {
    const metadata = {
        trip_id,
        trip_name,
        traveler_id,
        traveler_name,
        traveler_email
    };
    Core.trackEvent(Events.RESERVATION.ADDED_AS_QUOTE, metadata);
}

function showedVoucher(user, reservation_id, voucher_url, destination, search) {
    const metadata = {
        ...getUserObject(user),
        reservation_id,
        voucher_url,
        ...getDestinationObject(destination),
        ...getSearchTermsObject(search)
    };

    Core.trackEvent(Events.RESERVATION.SHOWED_VOUCHER, metadata);
    // TODO: consider using Core.trackLinks
}

function downloadedInvoice(user, reservation_id, invoice_url, destination, search) {
    const metadata = {
        ...getUserObject(user),
        reservation_id,
        invoice_url,
        ...getDestinationObject(destination),
        ...getSearchTermsObject(search)
    };

    Core.trackEvent(Events.RESERVATION.DOWNLOADED_INVOICE, metadata);
    // TODO: consider using Core.trackLinks
}

function downloadedBookingsCsv(company_id, is_agent, filters) {
    const metadata = {
        company_id,
        is_agent,
        filters
    };
    Core.trackEvent(Events.BOOKINGS.DOWNLOADED_BOOKINGS_CSV, metadata);
}

function sentBookingConfirmation(reservation_id, invoice_url, hotel, deal) {
    const metadata = {
        reservation_id,
        invoice_url,
        ...getDealObject(deal),
        ...getDestinationObject(hotel)
    };

    Core.trackEvent(Events.BOOKINGS.SENT_BOOKING_CONFIRMATION, metadata);
}

function openedBookingsSidepanel(bookingId, redeemable) {
    const metadata = {
        booking_ID: bookingId,
        redeemable
    };

    Core.trackEvent(Events.BOOKINGS.OPEN_BOOKINGS_SIDEPANEL, metadata);
}

function openedBookingRequestsSidepanel(bookingId, requestTicketId) {
    const metadata = {
        booking_ID: bookingId,
        request_ticket_ID: requestTicketId
    };

    Core.trackEvent(Events.BOOKINGS.OPEN_BOOKING_REQUESTS_SIDEPANEL, metadata);
}

function viewedPointsRedeemModal(bookingId) {
    const metadata = {
        booking_ID: bookingId
    };

    Core.trackEvent(Events.BOOKINGS.VIEWED_POINTS_REDEEM_MODAL, metadata);
}

function editedPoints(bookingId, editType, userChosenPoints) {
    const metadata = {
        booking_ID: bookingId,
        edit_type: editType,
        user_chosen_points: userChosenPoints
    };

    Core.trackEvent(Events.BOOKINGS.POINTS_EDITED, metadata);
}

function redeemedPoints(bookingId, points) {
    const metadata = {
        booking_ID: bookingId,
        points
    };

    Core.trackEvent(Events.BOOKINGS.POINTS_REDEEMED, metadata);
}

function closedPointsModal(bookingId) {
    const metadata = {
        booking_ID: bookingId
    };

    Core.trackEvent(Events.BOOKINGS.POINTS_MODAL_CLOSED, metadata);
}

function openPayLaterModal(bookingId) {
    const metadata = {
        booking_ID: bookingId
    };

    Core.trackEvent(Events.BOOKINGS.OPEN_PAY_LATER_MODAL, metadata);
}

function closedPayLaterModal(bookingId) {
    const metadata = {
        booking_ID: bookingId
    };

    Core.trackEvent(Events.BOOKINGS.CLOSE_PAY_LATER_MODAL, metadata);
}

function payNowClicked({ bookingId, currency, payment_method, markup }) {
    const metadata = {
        booking_ID: bookingId,
        currency,
        payment_method,
        markup
    };

    Core.trackEvent(Events.BOOKINGS.PAY_NOW_CLICKED, metadata);
}

function payLaterPaymentCreated(user, destination, deal, payment_method, charge_currency) {
    const metadata = {
        ...getUserObject(user),
        ...getDestinationObject(destination),
        ...getDealObject(deal),
        payment_method,
        charge_currency,
    };

    Core.trackEvent(Events.BOOKINGS.PAY_LATER_PAYMENT_CREATED, metadata);
}

function filteredBookings(user, filter_method, filter_value) {
    const metadata = {
        ...getUserObject(user),
        filter_method,
        filter_value
    };

    Core.trackEvent(Events.BOOKINGS.FILTERED_BOOKINGS, metadata);
}

function filteredBookingRequests(filter_method, filter_value) {
    const metadata = {
        filter_method,
        filter_value
    };
    Core.trackEvent(Events.BOOKINGS.FILTERED_BOOKING_REQUESTS, metadata);
}

function filteredReports(start_date, end_date, client_company_name) {
    // TODO: format dates in unix timestamp...
    const metadata = {
        start_date,
        end_date,
        client_company_name
    };
    Core.trackEvent(Events.MANAGEMENT.FILTERED_REPORTS, metadata);
}

function openedBasket() {
    Core.trackEvent(Events.BASKET.OPENED_BASKET);
}

function closedBasket() {
    Core.trackEvent(Events.BASKET.CLOSED_BASKET);
}

function addedToBasket(user, destination, deal) {
    const metadata = {
        ...getUserObject(user),
        ...getDestinationObject(destination),
        ...getDealObject(deal)
    };

    Core.trackEvent(Events.BASKET.ADDED_TO_BASKET, metadata);
}

function removedFromBasket(destination, deal) {
    const metadata = {
        ...getDestinationObject(destination),
        ...getDealObject(deal)
    };

    Core.trackEvent(Events.BASKET.REMOVED_FROM_BASKET, metadata);
}

function clearedBasket() {
    Core.trackEvent(Events.BASKET.CLEARED_BASKET);
}

function sentBasket(emails, name, remarks, deal_count) {
    const metadata = {
        emails,
        name,
        remarks,
        deal_count
    };
    Core.trackEvent(Events.BASKET.SENT_BASKET, metadata);
}

function failedToSendBasket(emails, name, remarks, deal_count) {
    const metadata = {
        emails,
        name,
        remarks,
        deal_count
    };
    Core.trackEvent(Events.BASKET.FAILED_TO_SEND_BASKET, metadata);
}

function clickedLiveChat(user, page_name, page_url) {
    const metadata = {
        ...getUserObject(user),
        page_name,
        page_url
    };

    Core.trackEvent(Events.MENU.LIVE_CHAT, metadata);
}

function sendEmailToSupportClicked(company) {
    const metadata = {
        ...getCompanyObject(company)
    };

    Core.trackEvent(Events.MENU.SEND_EMAIL_TO_SUPPORT_CLICKED, metadata);
}

function contactPhoneClicked(company, country, phone) {
    const metadata = {
        ...getCompanyObject(company),
        country,
        phone
    };

    Core.trackEvent(Events.MENU.CONTACT_PHONE_CLICKED, metadata);
}

function contactFieldError(company, field_type, error, user_input) {
    const metadata = {
        ...getCompanyObject(company),
        field_type,
        error,
        user_input
    };

    Core.trackEvent(Events.RESERVATION.CONTACT_FIELD_ERROR, metadata);
}

function travelerFieldError(company, field_type, error, user_input) {
    const metadata = {
        ...getCompanyObject(company),
        field_type,
        error,
        user_input
    };

    Core.trackEvent(Events.RESERVATION.TRAVELER_FIELD_ERROR, metadata);
}

function addedContactDetails(company, { first_name, last_name, email, tel }) {
    const metadata = {
        ...getCompanyObject(company),
        first_name,
        last_name,
        email,
        tel
    };

    Core.trackEvent(Events.RESERVATION.ADDED_CONTACT_DETAILS, metadata);
}

function addedTravelerDetails(company, { first_name, last_name, email }) {
    const metadata = {
        ...getCompanyObject(company),
        first_name,
        last_name,
        email
    };

    Core.trackEvent(Events.RESERVATION.ADDED_TRAVELER_DETAILS, metadata);
}

function addedSpecialRequests(company, reservation_id, deal) {
    const metadata = {
        ...getCompanyObject(company),
        ...getDealObject(deal),
        reservation_id
    };

    Core.trackEvent(Events.RESERVATION.ADDED_SPECIAL_REQUESTS, metadata);
}

function changeInstallment(user, num_installments) {
    const metadata = {
        ...getUserObject(user),
        num_installments
    };

    Core.trackEvent(Events.RESERVATION.CHANGE_INSTALLMENT, metadata);
}

function changeInstallmentError(user, num_installments, error) {
    const metadata = {
        ...getUserObject(user),
        num_installments,
        error
    };

    Core.trackEvent(Events.RESERVATION.CHANGE_INSTALLMENT_ERROR, metadata);
}

function showGroupBookingsModal(user) {
    const metadata = getUserObject(user);

    Core.trackEvent(Events.SEARCH.SHOW_GROUP_BOOKINGS_MODAL, metadata);
}

function openGroupBookingRequestForm(user) {
    const metadata = getUserObject(user);

    Core.trackEvent(Events.SEARCH.OPEN_GROUP_BOOKING_REQUEST_FORM, metadata);
}

// travel policy page
function addedTravelPolicyEntry(entry) {
    const metadata = { ...getTravelPolicyEntryObject(entry) };

    Core.trackEvent(Events.TRAVEL_POLICY.ADDED_TRAVEL_POLICY_ENTRY, metadata);
}

function addedTravelPolicyEntryFailed(entry, err) {
    const metadata = { ...getTravelPolicyEntryObject(entry), err };

    Core.trackEvent(Events.TRAVEL_POLICY.ADDED_TRAVEL_POLICY_ENTRY_FAILED, metadata);
}

function editedDefaultTravelPolicy(data) {
    const metadata = { ...data };

    Core.trackEvent(Events.TRAVEL_POLICY.EDITED_DEFAULT_TRAVEL_POLICY_ENTRY, metadata);
}

function editedDefaultTravelPolicyFailed(err) {
    const metadata = { err };

    Core.trackEvent(Events.TRAVEL_POLICY.EDITED_DEFAULT_TRAVEL_POLICY_ENTRY_FAILED, metadata);
}

function editedTravelPolicyEntry(entry_id, price) {
    const metadata = { entry_id, price };

    Core.trackEvent(Events.TRAVEL_POLICY.EDITED_TRAVEL_POLICY_ENTRY, metadata);
}

function editedTravelPolicyEntryFailed(err) {
    const metadata = { err };

    Core.trackEvent(Events.TRAVEL_POLICY.EDITED_TRAVEL_POLICY_ENTRY_FAILED, metadata);
}

function removedTravelPolicyEntry(entry_id) {
    const metadata = { entry_id };

    Core.trackEvent(Events.TRAVEL_POLICY.REMOVED_TRAVEL_POLICY_ENTRY, metadata);
}

function removedTravelPolicyEntryFailed(entry_id, err) {
    const metadata = { entry_id, err };

    Core.trackEvent(Events.TRAVEL_POLICY.REMOVED_TRAVEL_POLICY_ENTRY_FAILED, metadata);
}

function currentGeolocationSelected(user) {
    const metadata = {
        ...getUserObject(user),
    };

    Core.trackEvent(Events.SEARCH.GEO_LOCATION_DESTINATION_SELECTED, metadata);
}

function hotelNameFilterOpened(user, mobile = false) {
    const metadata = {
        ...getUserObject(user),
        mobile,
    };

    Core.trackEvent(Events.RESULTS.HOTEL_NAME_FILTER_OPENED, metadata);
}

function hotelNameFilterClosed({ user, search_input, results_count, mobile = false }) {
    const metadata = {
        ...getUserObject(user),
        typedCharacters: search_input,
        resultCount: results_count,
        mobile,
    };

    Core.trackEvent(Events.RESULTS.HOTEL_NAME_FILTER_CLOSED, metadata);
}

function hotelNameFilterSelectedSuggestion({ user, search_input, results_count, selected_hotel_name, is_in_results, mobile = false }) {
    const metadata = {
        ...getUserObject(user),
        hotelName: selected_hotel_name,
        isInResults: is_in_results,
        typedCharacters: search_input,
        resultCount: results_count,
        mobile,
    };

    Core.trackEvent(Events.RESULTS.HOTEL_SUGGESTION_SELECTED, metadata);
}

module.exports = {
    logoClicked,
    searchClicked,
    bookingsClicked,
    loggedOut,
    mobileAppInstalled,
    openMenu,
    closeMenu,
    // top bar
    changedDisplayCurrency,
    changedLanguage,

    showGroupBookingsModal,
    openGroupBookingRequestForm,

    // main page
    executedSearch,
    selectedRecentSearch,

    // removed recent search
    selectedBusinessDestination,
    selectedLeisureDestination,
    addedBusinessDestination,
    addedBusinessDestinationFailed,
    // removed business destination

    // results page
    changedSearch,
    // chose hotel (map)
    // selected deal in hotel (map)
    // minimized map
    sortedResults,
    filteredResults,
    clearedResultsFilters,
    filteredDeals,
    // filtered results by name
    // filtered results by distance
    // filtered results by price
    // filtered results by travel policy
    // filtered results by stars
    // chosen  hotel (hotel name, hotel component)
    // selected deal in hotel (select deal, deal component)
    // scroll
    selectedHotel,
    selectedDeal,
    showReviewsModal,
    showHotelDetailsModal,
    openResultsMap,
    closeResultsMap,
    openHotelMap,
    selectedRoomFromCatalog,

    // map //
    /////////
    // move
    // zoom in
    // zoom out

    // hotel page //
    ////////////////
    // back to results
    // continue
    // gallery
    // map
    // tax included
    // book deal
    // trust you
    // about
    // amenities


    // reservation page //
    //////////////////////
    // back to hotel
    // trip id
    // travelers
    // special requests
    // terms of supplier
    // redeem arbibank
    requestOutOfPolicyRoom,
    pickedTravelerFromAutocomplete,
    removedTraveler,
    proceededToPayment,
    accountPayment,
    GCashPayment,
    GCashPaymentSuccess,
    GCashPaymentError,
    creditCardPayment,
    pointsOnlyPayment,
    // continue (non refundable)
    // cancel (noon refundable)
    // chose credit card
    // chose company policy
    addedAsQuote: addedAsQuote,

    // bookings page
    // viewed_information
    // approved_booking
    // rejected_booking
    showedVoucher,
    downloadedInvoice,
    downloadedBookingsCsv,
    // cancelled_reservation
    filteredBookings,
    filteredBookingRequests,
    sentBookingConfirmation,

    // bookings page (retroactive points usage)
    openedBookingsSidepanel,
    openedBookingRequestsSidepanel,
    viewedPointsRedeemModal,
    editedPoints,
    redeemedPoints,
    closedPointsModal,

    // booking page (settle payemtn)
    openPayLaterModal,
    closedPayLaterModal,
    payNowClicked,
    payLaterPaymentCreated,

    // reports page
    filteredReports,

    // basket
    openedBasket,
    closedBasket,
    addedToBasket,
    removedFromBasket,
    clearedBasket,
    sentBasket,
    failedToSendBasket,

    clickedLiveChat,
    sendEmailToSupportClicked,
    contactPhoneClicked,
    contactFieldError,
    travelerFieldError,
    addedContactDetails,
    addedTravelerDetails,
    addedSpecialRequests,
    changeInstallment,
    changeInstallmentError,

    // travel policy page
    addedTravelPolicyEntry,
    addedTravelPolicyEntryFailed,
    editedDefaultTravelPolicy,
    editedDefaultTravelPolicyFailed,
    editedTravelPolicyEntry,
    editedTravelPolicyEntryFailed,
    removedTravelPolicyEntry,
    removedTravelPolicyEntryFailed,

    // geo search
    currentGeolocationSelected,

    // city search
    hotelNameFilterSelectedSuggestion,
    hotelNameFilterClosed,
    hotelNameFilterOpened,
};
