import React, { Component } from 'react';
import { IconButton, Icon, Tooltip } from '@mui/material';
import _ from 'lodash';
import { withRouter } from '@/utils/withRouter';

import AddFlight from './AddFlight.react';
import AddAccommodation from './AddAccommodation.react';
// import AddCar from './AddCar.react';

import ManagementConstants from '@/constants/ManagementConstants';
import ManagementActions from '@/actions/ManagementActions';
import AdditionalInfo from './AdditionalInfo.react';

function getTravelComponentsPageState() {
	return {
		show_buttons: true,
		hovering: false,
		editing: false,
	};
}

function renderComponentByType(component) {
	const component_clone = _.extend({}, component);
	switch (component.component_type) {
		case ManagementConstants.TRIP_REQUEST_COMPONENT_TYPE.FLIGHT:
			return (
				<AddFlight
					currency={this.props.profile.currency}
					trip={this.props.trip_request}
					flight={component_clone}
					travel_manager_mode={this.props.travel_manager_mode}
					key={'flight-' + component.display_order}
					autoFocus={!component.id}
					profile={this.props.profile}
					can_edit={this.props.can_edit}
					should_validate={this.props.should_validate}
				/>
			);
		case ManagementConstants.TRIP_REQUEST_COMPONENT_TYPE.ACCOMMODATION:
			return (
				<AddAccommodation
					trip={this.props.trip_request}
					accommodation={component_clone}
					travel_manager_mode={this.props.travel_manager_mode}
					key={'accommodation-' + component.display_order}
					autoFocus={!component.id}
					profile={this.props.profile}
					can_edit={this.props.can_edit}
					should_validate={this.props.should_validate}
				/>
			);
		// case ManagemnamentConstants.TRIP_REQUEST_COMPONENT_TYPE.CAR:
		//   return <AddCar car={component_clone} key={"car-" + component.display_order} profile={this.props.profile}
		//                  autoFocus={!component.id} {...disabling_props} />;
		default:
			// Do nothing
			break;
	}
	return null;
}

class TravelComponents extends Component {
	constructor(props) {
		super(props);
		this.state = getTravelComponentsPageState();
	}

	handleChange(date) {
		console.log('changed date:', date);
	}

	handleOnBlur(date) {
		console.log('blur event:', date);
	}

	handleAddFlightClick() {
		ManagementActions.initTripRequestFlight();
	}

	handleAddAccommodationClick() {
		ManagementActions.initTripRequestAccommodation();
	}

	// handleAddCarClick: function () {
	//   ManagementActions.initTripRequestCar();
	// },

	handleAddTravelComponentClick(e) {
		let open_status = this.state.show_buttons;
		this.setState({ show_buttons: !open_status });
	}

	handleCheckAllOnChange(e) {
		console.log('handleCheckAllOnChange', e);
	}

	handleOnMouseEnter(e) {
		if (this.props.hoverHook) {
			this.props.hoverHook(true);
		}

		this.setState({
			hovering: true,
		});
	}

	handleOnMouseLeave(e) {
		if (this.props.hoverHook) {
			this.props.hoverHook(false);
		}

		this.setState({
			hovering: false,
		});
	}

	handleOnForwardClick(e) {
		const { navigate } = this.props;
		ManagementActions.updateTripRequestSource(ManagementConstants.SOURCES.TRIP + '/' + this.props.trip_request.id);
		navigate('/' + ManagementConstants.SOURCES.FORWARD);
	}

	handleOnEditClick(e) {
		this.setState({
			editing: true,
		});
	}

	clearPreviewPane() {
		// ManagementActions.clearPreview();
		ManagementActions.previewCostEstimation();
	}

	render() {
		let {
			trip_request,
			// profile,
			travel_manager_mode,
			can_edit,
			should_validate,
			tooltipPopperProps,
			iconButtonSx,
		} = this.props;
		let components = [];
		if (trip_request && trip_request.id) {
			components = trip_request.flights.concat(trip_request.accommodations); //.concat(trip_request.cars);
			components.sort(function (a, b) {
				return a.display_order - b.display_order;
			});
			components = components.map(renderComponentByType.bind(this));
		}

		let travel_request_classes = 'requests components section';
		let add_buttons_classes = 'buttons';
		if (this.state.show_buttons) {
			add_buttons_classes += ' open';
		}
		if (travel_manager_mode) {
			add_buttons_classes += ' travel-manager-mode';
		} else {
			add_buttons_classes += ' more-left';
		}

		return (
			<div className={travel_request_classes} onMouseEnter={this.clearPreviewPane}>
				<div className="title-line">
					<span className="title">Requests</span>

					<div className={add_buttons_classes}>
						{travel_manager_mode && can_edit ? (
							<Tooltip title="Forward" PopperProps={tooltipPopperProps}>
								<IconButton
									className="forward-button"
									onClick={this.handleOnForwardClick}
									size="large"
									sx={iconButtonSx}
								>
									<Icon className="fa fa-share" />
								</IconButton>
							</Tooltip>
						) : null}

						{can_edit ? (
							<Tooltip title="Add Flight" PopperProps={tooltipPopperProps}>
								<IconButton
									className="add-flight-button"
									onClick={this.handleAddFlightClick}
									size="large"
									sx={iconButtonSx}
								>
									<Icon className="fa fa-plane" />
								</IconButton>
							</Tooltip>
						) : null}

						{can_edit ? (
							<Tooltip title="Add Accommodation" PopperProps={tooltipPopperProps}>
								<IconButton
									className="add-acc-button"
									onClick={this.handleAddAccommodationClick}
									size="large"
									sx={iconButtonSx}
								>
									<Icon className="fa fa-bed" />
								</IconButton>
							</Tooltip>
						) : null}
					</div>
				</div>
				<div className="trip-components">
					{components.length > 0 ? (
						components
					) : (
						<div className="empty-itinerary add-component">
							<h3 className={should_validate ? 'error' : ''}>Start adding your trip itinerary</h3>
						</div>
					)}
				</div>
				{travel_manager_mode ? <AdditionalInfo trip_request={trip_request} can_edit={can_edit} /> : null}
			</div>
		);
	}
}

export default withRouter(TravelComponents);
