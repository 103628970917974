import _ from 'lodash';
import moment from 'moment';
import { useLayoutEffect, useRef, useState } from 'react';

const airplaneIconWidth = 30;
const dashWidthPx = 3;

const BookedAccommodation = ({ accommodation }) => {
	const iconRef = useRef();
	const [dashes, setDashes] = useState('');

	useLayoutEffect(() => {
		if (!hasData) return;
		const iconContainerWidth = iconRef.current.clientWidth;
		const dashLineWidth = Math.round((iconContainerWidth - airplaneIconWidth) / 2 / dashWidthPx) + 1;
		setDashes(Array(dashLineWidth).join('-'));
	}, []);

	const date_format = 'MMM DD';

	const hotel_name = _.get(accommodation, 'hotel.name', '').toLowerCase();
	const checkIn = _.get(accommodation, 'deal.details.check_in', '');
	const checkInString = checkIn && moment(checkIn).format(date_format);
	const checkOut = _.get(accommodation, 'deal.details.check_out', '');
	const checkOutString = checkOut && moment(checkOut).format(date_format);

	const hasData = checkInString && checkOutString;

	return (
		<div className="booked-item booked-hotel">
			<div className="hotel-name">{hotel_name}</div>
			<div className="flex space-between align-center" key={accommodation.id}>
				<div className="booked-item-container">
					<div className="text">Check-in</div>
					<div className="check-in-out">{checkInString}</div>
				</div>
				{hasData && (
					<div ref={iconRef} className="icon-container hotel-icon flex-1">
						<span className="dash left">{dashes}</span>
						<img className="icon" src="../../../img/trip/bed.svg" alt="accommodation" />
						<span className="dash right">{dashes}</span>
					</div>
				)}
				<div className="booked-item-container">
					<div className="text">Check-out</div>
					<div className="check-in-out">{checkOutString}</div>
				</div>
			</div>
		</div>
	);
};

export default BookedAccommodation;
