import _ from 'lodash';
import moment from 'moment';

import SEARCH_CONSTANTS from 'arbitrip-common/general/constants/search-constants';
import { WebStorageManager, PlacesUtils, Validator } from 'arbitrip-common/client/utils';

import ProfileStore from '../stores/ProfileStore';

const lat_lon_conversions = {
	// New York, NY, United States --> Midtown, New York, NY, United States (id = "8509d8c2133827617033e5688c3202fca0d57c70")
	'7eae6a016a9c6f58e2044573fb8f14227b6e1f96': { lat: 40.7549309, lng: -73.98401949999999 },

	// Las Vegas, NV, United States --> Las Vegas Strip, Las Vegas, United States (id = "b659fda58a754efe28597dbc2367a262e3ffd2db")
	'667973acb71b9f5c746bb145f389a30422c31b7a': { lat: 36.11470649999999, lng: -115.17284840000002 },

	// San Francisco, CA, United States --> Union Square, San Francisco, CA, United States (id = "1b2e2792e02e0dba27d8e8f79db7b13f073cb255")
	'1b9ea3c094d3ac23c9a3afa8cd4d8a41f05de50a': { lat: 37.7879938, lng: -122.40743739999999 },

	// Manhattan, NY, United States --> Midtown, New York, NY, United States (id = "8509d8c2133827617033e5688c3202fca0d57c70")
	'476d37be9512ae1c37df40b54d55c5a911a413f1': { lat: 40.7549309, lng: -73.98401949999999 },

	// Bangkok Thailand --> JW Marriott Hotel Bangkok Bangkok Thailand (id = "51634da8fd1ed6e8ddf12cf0bbaa9c087d482b3d")
	'56ef65d942d42054613887fd09cee596d5949359': { lat: 13.741861, lng: 100.55193600000007 },
};

class SearchService {
	getLatLonConversions() {
		return lat_lon_conversions;
	}

	setDestination(StoreData, data) {
		if (!data) {
			StoreData.destination = null;
			StoreData.address = null;
			StoreData.hotel_id = null;
		} else {
			if (data.place && lat_lon_conversions[data.place.id]) {
				if (!data.place.geometry) {
					data.place.geometry = {};
				}
				data.place.geometry.location = lat_lon_conversions[data.place.id];
			}

			StoreData.destination = {};
			StoreData.destination.address = data.address;
			StoreData.destination.autocomplete_category = data.category;
			StoreData.destination.autocomplete_index = data.index;

			// if place object already exists - use it
			// if no place  - use the data that was sent
			StoreData.destination.place = data.place || data;
			WebStorageManager.updateInWebStorage('_recommendation_place', StoreData.destination.place);

			StoreData.address = data.address;
			StoreData.hotel_id = _.get(StoreData.destination, 'place.hotel_id');
		}

		return StoreData;
	}

	setUserCurrency(StoreData, currency) {
		if (ProfileStore.isUseUserCurrency()) {
			StoreData.user_currency = currency ?? ProfileStore.getUserCurrency();
		}
		return StoreData;
	}

	isValid(StoreData) {
		const place = _.get(StoreData, 'destination.place');

		return (
			this.isFull(StoreData) &&
			this.isDestinationValid(StoreData) &&
			this.areDatesValid(StoreData) &&
			this.areRoomsValid(StoreData) &&
			this.areGuestsPerRoomValid(StoreData) &&
			this.areLOSValid(StoreData) &&
			this.isDestinationAllowed(place)
		);
	}

	isFull(StoreData) {
		return (
			StoreData.destination &&
			StoreData.destination.address &&
			StoreData.check_in &&
			StoreData.check_out &&
			StoreData.rooms &&
			StoreData.guests
		);
	}

	isDestinationValid(StoreData) {
		return StoreData.destination && StoreData.destination.address;
	}

	isDestinationAllowed(place) {
		const profile = ProfileStore.getProfile() || {};

		if (!profile.employee) {
			return true;
		}

		if (PlacesUtils.isIsrael(place) && _.get(profile, 'company.settings.block_israel')) {
			return false;
		}

		return true;
	}

	areDatesValid(StoreData) {
		return (
			moment(StoreData.check_in).isValid() &&
			moment(StoreData.check_out).isValid() &&
			moment(StoreData.check_out).diff(moment(StoreData.check_in), 'days') >= 1
		);
	}

	areRoomsValid(StoreData) {
		return !isNaN(StoreData.rooms) && StoreData.rooms >= 1 && StoreData.rooms <= SEARCH_CONSTANTS.MAX.ROOMS;
	}

	areGuestsPerRoomValid(StoreData) {
		const profile = ProfileStore.getProfile() || {};
		const maxAdults = profile?.agent ? SEARCH_CONSTANTS.MAX.ADULTS_IF_AGENT : SEARCH_CONSTANTS.MAX.ADULTS;

		return !isNaN(StoreData.guests) && StoreData.guests >= 1 && StoreData.guests <= maxAdults;
	}

	areChildrenAgesValid(StoreData) {
		return _.isArray(StoreData.children_ages) && StoreData.children_ages.every(Validator.validateAge);
	}

	areLOSValid(StoreData) {
		let nights = this.getNumberOfNights(StoreData);
		return !isNaN(nights) && nights <= SEARCH_CONSTANTS.MAX_DURATION_NUMBER_OF_NIGHTS;
	}

	isValidDate(date) {
		return date && moment(date).startOf('day').isSameOrAfter(moment().startOf('day'));
	}

	getNumberOfNights(StoreData) {
		return moment(StoreData.check_out).diff(moment(StoreData.check_in), 'days');
	}

	updateCheckIn(StoreData, data) {
		if (this.isValidDate(data)) {
			StoreData = this.setCheckIn(StoreData, data);

			if (this.isValidDate(StoreData.check_out)) {
				StoreData = this.setCheckOut(StoreData, StoreData.check_out);
			}
		}

		return StoreData;
	}

	setCheckIn(StoreData, data) {
		let newCheckIn = moment.utc(data).startOf('day');

		let minCheckIn = moment.utc().add(SEARCH_CONSTANTS.MIN_CHECK_IN).startOf('day');
		let maxCheckIn = moment.utc().add(SEARCH_CONSTANTS.MAX_CHECK_IN).startOf('day');

		if (newCheckIn.isBefore(minCheckIn)) {
			StoreData.check_in = null;
		} else if (newCheckIn.isAfter(maxCheckIn)) {
			StoreData.check_in = maxCheckIn;
		} else {
			StoreData.check_in = newCheckIn;
		}

		return StoreData;
	}

	setCheckOut(StoreData, data) {
		let newCheckOut = moment.utc(data).startOf('day');

		let minCheckOut = moment.utc(StoreData.check_in).add(SEARCH_CONSTANTS.MIN_DURATION).startOf('day');
		let maxCheckOut = moment.utc(StoreData.check_in).add(1, 'y').startOf('day');

		if (!StoreData.check_in || newCheckOut.isBefore(minCheckOut)) {
			StoreData.check_out = null;
		} else if (newCheckOut.isAfter(maxCheckOut)) {
			StoreData.check_out = maxCheckOut;
		} else {
			StoreData.check_out = newCheckOut;
		}

		return StoreData;
	}

	updateGuests(StoreData, data) {
		StoreData.guests = data.guests;

		return StoreData;
	}

	updateRooms(StoreData, data) {
		StoreData.rooms = data;

		return StoreData;
	}

	updateChildrenAges(StoreData, data) {
		StoreData.children_ages = data;

		return StoreData;
	}
}

export default new SearchService();
