import React from 'react';
import _ from 'lodash';

import UsersManagementConstants from '../../../constants/UsersManagementConstants';

class UserManagementService {
	getSortedUsers(filteredUsers, orderDirection, orderBy, agencyBranches) {
		switch (orderBy) {
			case UsersManagementConstants.USER_MANAGEMENT_ROWS.APPROVER:
				return this.getSortedUsersByApprover(filteredUsers, orderDirection);
			case UsersManagementConstants.USER_MANAGEMENT_ROWS.STATUS:
				return this.getSortedUsersByStatus(filteredUsers, orderDirection);
			case UsersManagementConstants.USER_MANAGEMENT_ROWS.ROLE:
				return this.getSortedUsersByRoles(filteredUsers, orderDirection);
			case UsersManagementConstants.USER_MANAGEMENT_ROWS.BRANCH:
				return this.getSortedUsersByBranch(filteredUsers, orderDirection, agencyBranches);
			default:
				return this._stableSort(filteredUsers, this._getSortingOrder(orderDirection, orderBy));
		}
	}

	getSortedUsersByApprover(users, orderDirection) {
		let sort = _.sortBy(users, (user) => {
			return user && user.approver && user.approver.full_name ? user.approver.full_name : '';
		});

		if (orderDirection === UsersManagementConstants.SORT_DIRECTION.DESC) sort = sort.slice().reverse();
		return sort;
	}

	getSortedUsersByBranch(users, orderDirection, agencyBranches) {
		let sort = _.sortBy(users, (user) => {
			const branch = agencyBranches.filter(({ _id }) => _id === user.agency_branch);
			return branch.length ? branch[0].name.trim().toLowerCase().replace(' ', '') : '';
		});
		if (orderDirection === UsersManagementConstants.SORT_DIRECTION.DESC) sort = sort.slice().reverse();
		return sort;
	}

	getSortedUsersByStatus(users, orderDirection) {
		let sort = _.sortBy(users, (user) => {
			return this.getUserStatus(user);
		});

		if (orderDirection === UsersManagementConstants.SORT_DIRECTION.DESC) sort = sort.slice().reverse();
		return sort;
	}

	getSortedUsersByRoles(users, orderDirection) {
		let roles = UsersManagementConstants.SYSTEM_ROLES_ORDER;
		if (orderDirection === UsersManagementConstants.SORT_DIRECTION.ASC) roles = roles.slice().reverse();

		return _.sortBy(users, (user) => {
			let i = roles.indexOf(UsersManagementConstants.SYSTEM_ROLES.EMPLOYEE);
			if (!Array.isArray(user.roles)) return i;

			for (let role of user.roles) {
				if (roles.indexOf(role) > -1) {
					i = roles.indexOf(role);
					break;
				}
			}

			return i;
		});
	}

	getUserStatus(user) {
		let status;
		if (user.active && user.verified) {
			status = UsersManagementConstants.USER_STATUS.ACTIVE;
		} else if (!user.verified) {
			status = UsersManagementConstants.USER_STATUS.INACTIVE;
		} else if (!user.active) {
			status = UsersManagementConstants.USER_STATUS.SUSPENDED;
		}

		return status;
	}

	_stableSort(array, cmp) {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = cmp(a[0], b[0]);
			if (order !== 0) return order;
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	}

	_getSortingOrder(order, orderBy) {
		return order === UsersManagementConstants.SORT_DIRECTION.DESC
			? (a, b) => this._desc(a, b, orderBy)
			: (a, b) => -this._desc(a, b, orderBy);
	}

	getUserRoles(user) {
		if (!user.roles) {
			return null;
		}

		let roles = [];
		user.roles.forEach((role, idx) => {
			role = role.replace(/_/g, ' ');
			roles.push(
				<div key={idx} className="role">
					{role}
				</div>,
			);
		});

		return roles;
	}

	getUserSelectedRole(user) {
		if (!user || !user.roles) {
			return UsersManagementConstants.SYSTEM_ROLES.EMPLOYEE;
		}

		return this.getHighestRoleByText(user.roles);
	}

	getHighestRoleByText(userRoles) {
		if (!Array.isArray(userRoles)) return null;
		let roles = UsersManagementConstants.SYSTEM_ROLES_ORDER;

		let t = UsersManagementConstants.SYSTEM_ROLES.EMPLOYEE;
		for (let role of roles) {
			if (userRoles.indexOf(role) > -1) {
				t = role;
				break;
			}
		}

		return t;
	}

	getUserApprover(user) {
		return user.approver ? user.approver.full_name : '-';
	}

	initUserModel(model, user, profile, props, state) {
		model.active = true;
		model.first_name = model.first_name.trim();
		model.last_name = model.last_name.trim();
		model.full_name = `${model.first_name} ${model.last_name}`;
		model.roles = [state.role];
		model.company_id = props.profile.company.id;
		if (user) {
			model.id = user.id;
		} else if (profile) {
			if (profile.hasOwnProperty('can_book_nonrefundable_deals'))
				model.can_book_nonrefundable_deals = profile.can_book_nonrefundable_deals;
			if (profile.hasOwnProperty('company_policy_allowed'))
				model.company_policy_allowed = profile.company_policy_allowed;
			if (profile.hasOwnProperty('credit_card_allowed')) model.credit_card_allowed = profile.credit_card_allowed;
			if (profile.hasOwnProperty('is_israeli_credit_holder'))
				model.is_israeli_credit_holder = profile.is_israeli_credit_holder;
			if (profile.hasOwnProperty('pay_at_hotel_allowed'))
				model.pay_at_hotel_allowed = profile.pay_at_hotel_allowed;
		}

		model.approver = state.approver ? state.approver : null;
		if (state.department) model.department = state.department;
		if (state.location) model.location = state.location;
		if (state.costCenter) model.cost_center = state.costCenter;

		return model;
	}

	getFilteredUsers(users, search) {
		return users.filter((u) => {
			return (
				u.full_name.toLowerCase().includes(search.toLowerCase()) ||
				u.email.toLowerCase().includes(search.toLowerCase())
			);
		});
	}

	_desc(a, b, orderBy) {
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
		return 0;
	}
}

export default new UserManagementService();
