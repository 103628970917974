const moment = require('moment');
const _ = require('lodash');

const localforage = require('localforage');
localforage.setDriver(localforage.INDEXEDDB);

const search_db_name = 'search';
const createSearchInstance = (storeName) => localforage.createInstance({
    name: search_db_name,
    storeName
});

const room_mapping_db_name = 'room_mapping';
const createRoomMappingInstance = (storeName) => localforage.createInstance({
    name: room_mapping_db_name,
    storeName
});

const poi_db_name = 'poi';
const createPointOfInterestInstance = (storeName) => localforage.createInstance({
    name: poi_db_name,
    storeName
});

const SEARCH = {
    SESSIONS: createSearchInstance('sessions'),
    TRAVEL_POLICIES: createSearchInstance('travel-policies'),
    HOTELS: createSearchInstance('hotels'),
    SHALLOWS: createSearchInstance('shallows'),
    DEALS: createSearchInstance('deals'),
    BANNED_DEALS: createSearchInstance('banned_deals'),
    RESERVATION_ATTEMPTS: createSearchInstance('reservation_attempts'),
};

const ROOM_MAPPING = {
    FINISHED: createRoomMappingInstance('finished'),
    SETTINGS: createRoomMappingInstance('settings'),
    ROOM_SUPPLIERS: createRoomMappingInstance('room_suppliers'),
    MAPPING: createRoomMappingInstance('mapping')
};

const POI = {
    HOTELS: createPointOfInterestInstance('hotels'),
    PLACES: createPointOfInterestInstance('places_23-08-01')
};

const CLEAN_PLACES_CACHE_INTERVAL = 12.345; // minutes;
const POI_TTL_MOMENT_DURATION = moment.duration(30, 'days');
const ROOM_MAPPING_TTL_MOMENT_DURATION = moment.duration(30, 'days');

const CLEAN_RESULTS_CACHE_INTERVAL = 10; // minutes;
const RESULTS_CACHE_TTL = process.env.REACT_APP_RESULTS_EXPIRE_IN_MINUTES || 30; // minutes;
const RESULTS_CACHE_TTL_MOMENT_DURATION = moment.duration(RESULTS_CACHE_TTL, 'minutes');

function cleanStores(storesObject, ttlMomentDuration) {
    for (const storeName in storesObject) {
        const store = storesObject[storeName];
        store.iterate((value, key, iterationNumber) => {
            try {
                const json = JSON.parse(value);
                const {
                    timestamp,
                    data
                } = json;

                if (_.isEmpty(data) || !timestamp || !moment(timestamp).isValid() || moment(timestamp).add(ttlMomentDuration).isSameOrBefore(moment())) {
                    store.removeItem(key);
                }
            } catch (err) {
                store.removeItem(key);
            }
        });
    }
}

function cleanExpiredPointsOfInterest() {
    cleanStores(POI, POI_TTL_MOMENT_DURATION);
}

cleanExpiredPointsOfInterest();
setInterval(cleanExpiredPointsOfInterest, moment.duration(CLEAN_PLACES_CACHE_INTERVAL, 'minutes').asMilliseconds());

function cleanExpiredRoomMapping() {
    cleanStores(ROOM_MAPPING, ROOM_MAPPING_TTL_MOMENT_DURATION);
}
cleanExpiredRoomMapping();

function cleanExpiredSearches() {
    cleanStores(SEARCH, RESULTS_CACHE_TTL_MOMENT_DURATION);
}

cleanExpiredSearches();
setInterval(cleanExpiredSearches, moment.duration(CLEAN_RESULTS_CACHE_INTERVAL, 'minutes').asMilliseconds());

function set(instance, key, value) {
    return new Promise((resolve, reject) => {
        const str = JSON.stringify({
            timestamp: new Date(),
            data: value
        });
        instance.setItem(key, str, (err, res) => {
            if (err) {
                console.error('OfflineDB.set :::', err);
                return reject(err);
            }

            return resolve(res);
        });
    });
}

function get(instance, key) {
    return new Promise((resolve, reject) => {
        instance.getItem(key, (err, res) => {
            if (err) {
                console.error('OfflineDB.get :::', err);
                return reject(err);
            }

            if (!res) {
                console.warn('no res', key, err, res);
                instance.removeItem(key);
                return resolve();
            }

            try {
                const { data } = JSON.parse(res);
                return resolve(data);
            } catch (err) {
                console.error('OfflineDB.get :::', err);
                instance.removeItem(key);
                return reject(err);
            }
        });
    })
}

////////////////////////////////////////////////////////////////////////////////

module.exports = {
    setSearchSession: (searchToken, searchSession) => set(SEARCH.SESSIONS, searchToken, searchSession),
    getSearchSession: (searchToken) => get(SEARCH.SESSIONS, searchToken),

    setSearchTravelPolicy: (searchToken, travelPolicy) => set(SEARCH.TRAVEL_POLICIES, searchToken, travelPolicy),
    getSearchTravelPolicy: (searchToken) => get(SEARCH.TRAVEL_POLICIES, searchToken),

    setSearchHotels: (searchToken, rawHotels) => set(SEARCH.HOTELS, searchToken, rawHotels),
    getSearchHotels: (searchToken) => get(SEARCH.HOTELS, searchToken),

    setSearchShallows: (searchToken, rawShallows) => set(SEARCH.SHALLOWS, searchToken, rawShallows),
    getSearchShallows: (searchToken) => get(SEARCH.SHALLOWS, searchToken),

    setSearchDeals: (searchToken, rawDeals) => set(SEARCH.DEALS, searchToken, rawDeals),
    getSearchDeals: (searchToken) => get(SEARCH.DEALS, searchToken),

    setBannedDeals: (searchToken, dealIds) => set(SEARCH.BANNED_DEALS, searchToken, dealIds),
    getBannedDeals: (searchToken) => get(SEARCH.BANNED_DEALS, searchToken),

    getReservationAttempts: (searchToken) => get(SEARCH.RESERVATION_ATTEMPTS, searchToken),
    setReservationAttempts: (searchToken, reservationAttempts) => set(SEARCH.RESERVATION_ATTEMPTS, searchToken, reservationAttempts),

    setRoomMappingSettings: (searchToken, rawSettings) => set(ROOM_MAPPING.SETTINGS, searchToken, rawSettings),
    getRoomMappingSettings: (searchToken) => get(ROOM_MAPPING.SETTINGS, searchToken),

    setPointOfInterestPlace: (placeId, poi) => set(POI.PLACES, placeId, poi),
    getPointOfInterestPlace: (placeId) => get(POI.PLACES, placeId),

    setPointOfInterestHotel: (hotelId, poi) => set(POI.HOTELS, hotelId, poi),
    getPointOfInterestHotel: (hotelId) => get(POI.HOTELS, hotelId),

    clearPointOfInterestPlaces: POI.PLACES.clear
};
