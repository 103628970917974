const PreviewAirfareTips = ({ title = 'Tips' }) => {
	const examples = ['AA8372', 'LY316', 'ENT242', 'EZY2083'];

	return (
		<div className="tips">
			<div className="title">
				<span>{title}</span>
			</div>
			<div className="sub-title">
				<span>Flight numbers should include the airline code, as shown in the examples below:</span>
			</div>
			<div className="preview-content">
				{examples.map((example, i) => (
					<div key={i} className="example">
						{example}
					</div>
				))}
			</div>
		</div>
	);
};

export default PreviewAirfareTips;
