const FAQ = [
    {
        title: 'האם וכיצד ניתן לעדכן את שם ההזמנה ו/או תאריכי ההזמנה?',
        description: 'ישנן כמה אופציות:',
        secondary: [
            {
                title: 'במידה וההזמנה עדין ניתנת לביטול ללא עלות:',
                description: 'יש לבצע הזמנה חדשה עם הפרטים המעודכנים, לאחר קבלת אישור על ההזמנה החדשה, ניתן לבטל את ההזמנה הישנה.'
            },
            {
                title: 'במידה וההזמנה הינה בסטטוס של דמי ביטול מלאים:',
                description: 'יש ליצור קשר עם צוות השירות הפועל 24/7 ואנו ניצור קשר עם בית המלון ונבקש לבצע את השינויים הרצויים.\n' +
                    'יש לקחת בחשבון שתהליך זה תלוי באישור בית המלון בלבד ולכן ייתן וייקח כמה ימים לאישור אם בכלל.'
            }
        ]
    },
    {
        title: 'כיצד אוכל  להוסיף לילה/לילות נוספים להזמנה קיימת?',
        description: '',
        secondary: [
            {
                title: 'במידה וההזמנה ניתנת לביטול ללא עלות',
                description: 'ניתן לבצע הזמנה חדשה עם התאריכים המעודכנים ולאחר אישורה לבטל את ההזמנה הישנה.'
            },
            {
                title: 'במידה והתעריף עלה ורוצים  להוסיף על הקיים',
                description: 'יש לבצע הזמנה נוספת על הלילות המבוקשים לאותו סוג החדר המוזמן ולציין בהערות כי מדובר בהארכת הזמנה קיימת.\n' +
                    'צוות השירות יפנה ישירות אל בית המלון ויעדכן אותם על איחוד הזמנה קיימת על מנת שלא למנוע מעבר בין חדרים.'
            }
        ]
    },
    {
        title: 'הזמנתי חדר בלבד ואני מעוניין להוסיף ארוחת בוקר-כיצד ניתן לבצע?',
        description: '',
        secondary: [
            {
                title: 'במידה וההזמנה ניתנת לביטול ללא עלות',
                description: 'ניתן לבצע הזמנה חדשה הכוללת ארוחת בוקר. לאחר אישור סופי על הזמנה החדשה- ניתן לבטל את ההזמנה הישנה.'
            },
            {
                title: 'במידה וההזמנה הינה בסטטוס של דמי ביטול מלאים',
                description: 'יש לפנות אלינו עם הבקשה ואנו נבדוק מול בית המלון האם ניתן להוסיף ארוחת בוקר בנפרד להזמנה. במידה וניתן לשלם מראש על ארוחת הבוקר- נעשה זאת.\n' +
                    'שימו לב כי ישנם בתי מלון אשר לא מאפשרים תשלום מראש על ארוחות הבוקר והתשלום יתבצע במלון בלבד.'
            }
        ]
    },
    {
        title: 'איך ניתן לבטל את ההזמנה שלי?',
        description: '',
        secondary: [
            {
                title: 'במידה וההזמנה עדיין ניתנת לביטול ללא עלות',
                description: 'ניתן לבטל באופן עצמאי דרך המערכת שלנו -\n' +
                    'התחברו למערכת -> לחצו על האופציה של הזמנות (bookings) -> לחצו על שלוש הנקודות מתחת למחיר ההזמנה המבוקשת ->  יפתח תפריט קטן ->  לחצו - בטל הזמנה'
            },
            {
                title: 'במידה וההזמנה בסטטוס של דמי ביטול מלאים',
                description: 'יש לפנות לצוות התמיכה שלנו על מנת שנפנה למלון וננהל מולו מו"מ על החזר חלקי/מלא. קחו בחשבון כי נעשה כמיטב יכולתנו  אך בסופו של דבר ההחלטה תלויה בבית המלון בלבד.\n' +
                    'רצוי לספק סיבה והוכחות לביטול על מנת שנוכל לספקם למלון עם הבקשה.'
            }
        ]
    },
    {
        title: 'ראיתי מחיר זול יותר בפלטפורמה מקבילה - האם אתן משווים מחירים?',
        description: 'נשמח לעשות בדיקה ולבצע את ההשוואה אם אכן המחיר זול יותר.\n' +
            'יש לספק לנו את כל הפרטים על ההזמנה אשר ברצונכם לבצע ולינק לאתר אשר בו קיימת ההצעה הזולה יותר.\n' +
            'שימו לב כי אנו עובדים קשה על מנת לספק את הדילים הכי משתלמים ללקוחות שלנו ואכן המחירים שלנו זולים יותר ברוב המקרים.\n' +
            'במידה וקיים דיל זהה לדיל שאנו מציעים אשר הוא זול יותר - נשווה בשמחה.'
    },
    {
        title: 'אני לא מוצא את מלון ספציפי במערכת - תוכלו לעזור לי?',
        description: '',
        secondary: [
            {
                title: 'במידה והמלון אינו מופיע במערכת אך קיימת זמינות',
                description: 'יש לשלוח אלינו את שם בית המלון, תאריכים רצויים ומספר חדרים אשר אתם מעוניינים להזמין.\n' +
                    'נבצע בדיקה האם נוכל לבצע את ההזמנה עבורכם ישירות מבית המלון ולעדכנה אצלנו במערכת באופן ידני. לעתים, בית המלון משנה את שמו את את הבעלות והדבר מקשה על מציאתו אצלנון במערכת.'
            },
            {
                title: 'במידה והמלון קיים במערכת אך הוא בתפוסה מלאה',
                description: 'לעיתים מלון בתפוסה מלאה בתאריכים ספציפיים לא יופיע בתוצאות החיפוש אצלנו במערכת ןלכן לא תוכלו לראותו מוצג באתר.\n' +
                    'שימו לב כי לאחר חיפוש המלון - בצד שמאל של המסך, קיימת האופציה להוריד את הסימון מהתיבה של "הראה לי מלונות ללא זמינות" וכך תוכלו לראות אם המלון אכן קיים אצלנו.\n' +
                    'נשמח כמובן לעזור לכם למצוא חלופה הולמת במחיר דומה.'
            }
        ]
    },
    {
        title: 'היכן ניתן לצפות בחשבוניות עבור ההזמנות שלי?',
        description: '',
        secondary: [
            {
                title: 'במקרה והונפקה חשבונית',
                description: 'תוכל לצפות בה במערכת\n' +
                    'לחצו על האופציה של הזמנות (bookings) -> לחצו על שלוש הנקודות מתחת למחיר ההזמנה המבוקשת. -> יפתח תפריט קטן -> לחצו על האופציה של הורד חשבונית.'
            },
            {
                title: 'במקרה וטרם הונפקה חשבונית',
                description: 'צרו איתנו קשר ואנו מעביר את הבקשה למחלקה הפיננסית שלנו אשר יצרו קשר בהקדם.'
            }
        ]
    },
    {
        title: 'הגעתי למלון ועודכנתי כי אני צריך לשלם על ההזמנה, מדוע זה קורה והאם תוכלו לעזור לי?',
        description: 'זהו תרחיש חריג אשר לא אמור לקרות - אנו ניצור קשר עם בית המלון ונוודא כי לא תחוייבו או תזוכו במידה וחוייבתם ע"י בית המלון. מצב זה נוצר בעקבות קצר בתקשורת במערכות בית המלון. \n' +
            'במידה וחוייבתם - נבקש כי תעבירו לנו הוכחה על החיוב על מנת שנוכל להעבירה לבית המלון עם הבקשה לזיכוי. יש לקחת בחשבון כי הטיפול בנושאים אלו לוקח כמה ימים ואנו נדאג לעדכנכם בסופו של הטיפול.'
    },
    {
        title: 'עשיתי צ\'ק אין מוקדם מבית המלון - האם אוכל לקבל החזר?',
        description: 'במידה ואתם נאלצים לצאת מוקדם מבית המלון - נא עדכנו אותנו עם כל פרטי ההזמנה וגם את הסיבה לכך. אנו ניצור קשר עם בית המלון וננהל מולם מו"מ לגבי החזר אפשרי. חשוב לציין כי ההחזר אינו מובטח ותלוי בבית המלון בלבד. לקידום הנושא אנו ממליצים לפנות בנוסף לקבלה בבית המלון עם הבקשה ולהעביר אלינו את השם של הנציג אשר דיברתם והאם אישר/סירב לבקשתכם.\n'
    },
    {
        title: 'אני צריך לעשות צ\'ק אין מוקדם - איך ניתן להבטיח זאת?',
        description: 'רוב בתי המלון דורשים להזמין את הלילה הקודם על מנת לאפשר כניסה מוקדמת לחדר. \n' +
            'חשוב להזמין את אותו סוג חדר ולעדכן אותנו לגבי כך על מנת שנוכל לעדכן את בית המלון כי יש לשמור את החדר עבורכם.\n' +
            'ללא הזמנה של הלילה הקודם - לא נוכל להבטיח את הכניסה המוקדמת שכן הדבר תלוי בזמינות החדרים בבית המלון בלבד.'
    },
    {
        title: 'איך אוכל לצרף את מספר חבר המועדון שלי עבור רשת מלונות להזמנה אשר אני מבצע דרך המערכת?',
        description: 'תוכלו לציין את מספר חבר המועדון תחת - בקשות מיוחדות.\n' +
            'במידה וההזמנה כבר בוצעה, תוכלו להעבירו אלינו ואנו נעביר את הבקשה לבית המלון.\n' +
            'שימו לב כי מכיוון שההזמנה בוצעה דרך גורם שלישי - לא נוכל להבטיח כי בית המלון יקבל זאת. כמובן שנעשה כל שביכולתנו על מנת לטפל בבקשה זאת מול בית המלון.'
    }
    // {
    //     title: '',
    //     description: '',
    //     secondary: [
    //         {
    //             title: '',
    //             description: ''
    //         }
    //     ]
    // },
];

module.exports = {
    FAQ
};