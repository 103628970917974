const _ = require('lodash');
const moment = require('moment');

class SearchTerms {
    constructor(terms) {
        this.private_travel = terms.private_travel;
        this.destination = terms.destination;
        this.check_in = moment.utc(terms.check_in);
        this.check_out = moment.utc(terms.check_out);
        this.rooms = terms.room_count;
        this.guests = terms.guest_count;
        this.contract_id = _.get(terms, 'contract_id', '');
        this.private_travel = terms.private_travel;
        this.children_ages = _.get(terms, 'children_ages', []);
        this.user_currency = _.get(terms, 'user_currency', '');
    }
}

module.exports = SearchTerms;
