const Events = require('../events/index');
const Core = require('../core');
const { getUserObject } = require('../services/userService');
const { getDestinationObject } = require('../services/destinationService');
const { getSearchTermsObject } = require('../services/searchTermsService');
const { getDealObject } = require('../services/dealService');
const { getPointsObject } = require('../services/pointsService');

function fireResults(count, wait_time_ml) {
    const metadata = {
        count,
        wait_time_ml
    };

    Core.trackEvent(Events.SEARCH.RESULTS_RECEIVED_FIRE, metadata);
}

function hotelsResults(count, wait_time_ml) {
    const metadata = {
        count,
        wait_time_ml
    };

    Core.trackEvent(Events.SEARCH.RESULTS_RECEIVED_HOTELS, metadata);
}

function resultsFetchingTime(load_time) {
    const metadata = {
        load_time,
    }
    
    Core.trackEvent(Events.RESULTS.RESULTS_FETCHING_TIME, metadata);
}

function searchWithoutResults(destination, search) {
    const metadata = {
        ...getDestinationObject(destination),
        ...getSearchTermsObject(search),
    };
    
    Core.trackEvent(Events.RESULTS.SEARCH_WITHOUT_RESULTS, metadata);
}

function searchedHotelWithoutDeals(user, destination, search) {
    const metadata = {
        ...getUserObject(user),
        ...getDestinationObject(destination),
        ...getSearchTermsObject(search),
    };

    Core.trackEvent(Events.RESULTS.SEARCHED_HOTEL_WITHOUT_DEALS, metadata);
}

function searchWithoutResultsHotel(destination, search) {
    const metadata = {
        ...getDestinationObject(destination),
        ...getSearchTermsObject(search),
    };

    Core.trackEvent(Events.RESULTS.SEARCH_WITHOUT_RESULTS_HOTEL, metadata);
}

function rateUnavailable(room_name, price, hotel_name) {
    const metadata = {
        room_name,
        price,
        hotel_name,
    };

    Core.trackEvent(Events.RESULTS.RATE_UNAVAILABLE, metadata);
}

function bookingCreated({
                            user,
                            destination,
                            hotel,
                            search,
                            deal,
                            payment_method,
                            charge_currency,
                            points,
                            geolocationSearch,
                            distance,
                            attempt_count,
                            session_attempt_count,
                            hotel_attempt_count,
                            booking_id,
                            status,
                        }) {
    const metadata = {
        ...getUserObject(user),
        ...getDestinationObject(destination, hotel),
        ...getSearchTermsObject(search),
        ...getDealObject(deal),
        ...getPointsObject(points),
        payment_method,
        charge_currency,
        geolocationSearch,
        distance,
        attempt_count,
        session_attempt_count,
        hotel_attempt_count,
        booking_id,
        status,
    };

    Core.trackEvent(Events.RESERVATION.BOOKING_CREATED, metadata);
}

function reservationCanceled(user, reservation_id, destination) {
    const metadata = {
        ...getUserObject(user),
        reservation_id,
        ...getDestinationObject(destination)
    };

    Core.trackEvent(Events.RESERVATION.RESERVATION_CANCELED, metadata);
}

function reservationCanceledFailed(user, reservation_id, hotel_name) {
    const metadata = {
        ...getUserObject(user),
        reservation_id,
        hotel_name
    };

    Core.trackEvent(Events.RESERVATION.RESERVATION_CANCELED_FAILED, metadata);
}

function reservationFailed({
                               user,
                               destination,
                               deal,
                               payment_method,
                               error,
                               hotel,
                               status,
                               failure_reason,
                               attempt_count,
                               session_attempt_count,
                               hotel_attempt_count,
                           }) {
    const metadata = {
        ...getUserObject(user),
        ...getDestinationObject(destination, hotel),
        ...getDealObject(deal),
        payment_method,
        error,
        status,
        failure_reason,
        attempt_count,
        session_attempt_count,
        hotel_attempt_count,
    };
    Core.trackEvent(Events.RESERVATION.RESERVATION_FAILED_TO_BOOK, metadata);
}

function httpError(request, respond, endpoint) {
    const metadata = {
        request,
        respond,
        endpoint
    };
    
    Core.trackEvent(Events.HTTP.HTTP_ERROR, metadata);
}

module.exports = {
    fireResults,
    hotelsResults,
    resultsFetchingTime,
    searchWithoutResults,
    searchWithoutResultsHotel,
    rateUnavailable,
    bookingCreated,
    reservationCanceled,
    reservationCanceledFailed,
    reservationFailed,
    httpError,
    searchedHotelWithoutDeals,
};
