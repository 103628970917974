import React from 'react';

import { Button, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import PropTypes from 'prop-types';
import _ from 'lodash';

class SimpleActionMenu extends React.Component {
	state = {
		anchorEl: null,
	};

	handleClick = (event) => {
		this.setState({ anchorEl: event.currentTarget });
	};

	handleClose = () => {
		this.setState({ anchorEl: null });
	};

	handleAction = (action) => {
		this.handleClose();

		if (_.isFunction(action)) {
			action();
		}
	};

	render() {
		const { anchorEl } = this.state;
		const { menu } = this.props;

		return (
			<div>
				<Button
					aria-owns={anchorEl ? 'simple-menu' : undefined}
					aria-haspopup="true"
					onClick={this.handleClick}
				>
					<MoreVertIcon />
				</Button>
				<Menu
					id="simple-menu"
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					onClose={this.handleClose}
					disableAutoFocusItem={true}
				>
					{menu &&
						menu.length &&
						menu.map((i) => (
							<MenuItem onClick={() => this.handleAction(i.action)} key={i.label}>
								{i.label}
							</MenuItem>
						))}
				</Menu>
			</div>
		);
	}
}

SimpleActionMenu.propTypes = {
	menu: PropTypes.array.isRequired,
};

export default SimpleActionMenu;
