import React from 'react';
import { RESULTS_LOADING_LIMIT_IN_SECONDS } from 'arbitrip-common/general/constants/search-constants';
import LinearProgress from '@mui/material/LinearProgress';
import Fade from '@mui/material/Fade';

const interval_duration_in_millis = 200; // sampling speed
const duration_in_millis = RESULTS_LOADING_LIMIT_IN_SECONDS * 1000;
const FADE_OUT_TIMEOUT = 1000;

class SearchProgress extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			progress: this.getNewProgress(),
		};
		this.setProgress = this.setProgress.bind(this);
	}

	getResultsCoverage() {
		const { results } = this.props;
		if (results.hotels) {
			const non_shallow_hotels = results.hotels.filter((hotel) => !hotel.shallow);
			const hotels_with_deals_count = non_shallow_hotels.filter((hotel) => hotel.representativeDeal).length;

			if (non_shallow_hotels.length > 0) {
				return hotels_with_deals_count / non_shallow_hotels.length;
			}
		}
		return 0;
	}

	getNewProgress() {
		const { searchSessionCreatedTimestamp } = this.props;

		if (searchSessionCreatedTimestamp) {
			const search_session_duration_in_millis = Date.now() - searchSessionCreatedTimestamp.getTime();
			const results_coverage = this.getResultsCoverage();
			const coverage_multiplier = 1 + Math.pow(results_coverage, 2);
			return 100 * (search_session_duration_in_millis / duration_in_millis) * coverage_multiplier;
		}

		return 0;
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.progress >= 100) {
			clearInterval(this.interval);

			const _self = this;
			this.timeout = setTimeout(() => {
				_self.setState({ progress: 0 });
			}, FADE_OUT_TIMEOUT);
		}

		if (prevProps.searchSessionCreatedTimestamp !== this.props.searchSessionCreatedTimestamp) {
			clearTimeout(this.timeout);
			this.setState({ progress: 0 });
			this.interval = setInterval(this.setProgress, interval_duration_in_millis);
		}
	}

	componentDidMount() {
		clearInterval(this.interval);

		this.interval = setInterval(this.setProgress, interval_duration_in_millis);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	setProgress() {
		this.setState({ progress: this.getNewProgress() });
	}

	render() {
		const { progress } = this.state;

		return (
			<Fade in={progress > 0 && progress < 100} timeout={FADE_OUT_TIMEOUT}>
				<LinearProgress variant="determinate" value={progress} />
			</Fade>
		);
	}
}

export default SearchProgress;
