import _ from 'lodash';
import { EventEmitter } from 'events';
import AppDispatcher from '../dispatcher/AppDispatcher';
import PaymentLinkConstants from '../constants/PaymentLinkConstants';

let _status = PaymentLinkConstants.STATUS.INITIAL;

const PaymentLinkStore = _.extend({}, EventEmitter.prototype, {
	getStatus: function () {
		return _status;
	},

	emitChange: function () {
		this.emit('change');
	},

	addChangeListener: function (callback) {
		this.on('change', callback);
	},

	removeChangeListener: function (callback) {
		this.removeListener('change', callback);
	},
});

AppDispatcher.register(function (payload) {
	let action = payload.action;

	switch (action.actionType) {
		case PaymentLinkConstants.PAYMENT_LINK_LOADING:
			_status = PaymentLinkConstants.STATUS.BUSY;
			break;

		case PaymentLinkConstants.PAYMENT_LINK_SUCCESS:
			_status = PaymentLinkConstants.STATUS.SUCCESS;
			break;

		case PaymentLinkConstants.PAYMENT_LINK_FAILED:
			_status = PaymentLinkConstants.STATUS.FAILED;
			break;

		case PaymentLinkConstants.PAYMENT_LINK_RESET:
			_status = PaymentLinkConstants.STATUS.INITIAL;
			break;

		default:
			return true;
	}

	// If action was responded to, emit change event
	PaymentLinkStore.emitChange();

	return true;
});

export default PaymentLinkStore;
