import { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { Paper, CircularProgress } from '@mui/material';
import AddDestination from './AddDestination';
import ManagementStore from '@/stores/ManagementStore';
import ManagementActions from '@/actions/ManagementActions';
import ManagementConstants from '@/constants/ManagementConstants';
import TitleManager from '@/utils/TitleManager';
import { withRouter } from '@/utils/withRouter';
import DocumentTitle from '@/components/general/DocumentTitle.react';
import { ArbiPrimaryButton, ArbiCancelButton } from '@/components/general/styled/buttons';
import { TripActionButton } from './components/action_buttons';
import Plus from '@/components/general/icons/Plus';

const getTripRequestWizardPageState = () => ({
	destinations: ManagementStore.getWizardDestinations(),
	submit_status: ManagementStore.getSubmitWizardDestinationsStatus(),
	init_status: ManagementStore.getInitStatus(),
	current_trip_request_id: ManagementStore.getCurrentTripRequestId(),
});

const destinationIsValid = (wizard_destination) =>
	wizard_destination &&
	wizard_destination.destination &&
	wizard_destination.destination.address &&
	wizard_destination.destination.place &&
	moment(wizard_destination.start_date).isValid() &&
	moment(wizard_destination.end_date).isValid();

const destinationsSelectedFromList = (destinations) =>
	destinations.every((dest) => dest && dest.destination && dest.destination.place);

const destinationsAreValid = (destinations) =>
	Array.isArray(destinations) && destinations.length && destinations.every(destinationIsValid);

const useTripRequestWizardPageState = () => {
	const [state, setState] = useState(getTripRequestWizardPageState());

	useEffect(() => {
		const handleChange = () => {
			setState(getTripRequestWizardPageState());
		};

		ManagementStore.addChangeListener(handleChange);
		return () => ManagementStore.removeChangeListener(handleChange);
	}, []);

	return [state, setState];
};

const TripRequestWizardPage = ({ navigate }) => {
	const [
		{
			destinations,
			submit_status,
			init_status,
			current_trip_request_id,
			validity = true,
			select_from_list_message = false,
		},
		setState,
	] = useTripRequestWizardPageState();

	const isBusy = () => {
		return submit_status === ManagementConstants.STATUS.BUSY || init_status === ManagementConstants.STATUS.BUSY;
	};

	const prevSubmitStatusRef = useRef();
	const prevInitStatusRef = useRef();

	const hasStatusChangedToSuccess = (prevStatus, currentStatus) => {
		return (
			prevStatus &&
			prevStatus !== ManagementConstants.STATUS.SUCCESS &&
			currentStatus === ManagementConstants.STATUS.SUCCESS
		);
	};

	useEffect(() => {
		if (
			hasStatusChangedToSuccess(prevSubmitStatusRef.current, submit_status) ||
			hasStatusChangedToSuccess(prevInitStatusRef.current, init_status)
		) {
			navigate('/trip/' + current_trip_request_id);
		}

		prevSubmitStatusRef.current = submit_status;
		prevInitStatusRef.current = init_status;
	}, [submit_status, init_status, current_trip_request_id, navigate]);

	const handleCancelOnClick = () => {
		ManagementActions.resetWizardDestinations();
		navigate('/trips');
	};

	const handleNextOnClick = () => {
		if (!isBusy()) {
			if (destinationsAreValid(destinations)) {
				ManagementActions.submitWizardDestinations(destinations);
			} else {
				setState((prevState) => ({
					...prevState,
					validity: false,
					select_from_list_message: !destinationsSelectedFromList(destinations),
				}));
			}
		}
	};

	const handleAddAnotherDestinationOnClick = () => {
		if (!isBusy()) {
			let start_date = destinations.length ? destinations[destinations.length - 1]['end_date'] : null;
			ManagementActions.addWizardDestination(start_date);
		}
	};

	const handleSkipWizard = () => {
		if (!isBusy()) {
			ManagementActions.initTripRequest(ManagementConstants.SOURCES.WIZARD);
		}
	};

	let add_destinations =
		destinations.length === 1 ? (
			<AddDestination destination={destinations[0]} single />
		) : (
			destinations.map((destination, index) => <AddDestination key={index} destination={destination} />)
		);

	let title = TitleManager.buildTitleStartsWith('New Trip');

	return (
		<DocumentTitle title={title}>
			<div className="trip-request-wizard-page">
				<div className="title">New Trip</div>
				<Paper elevation={2} className="wizard paper">
					<div className={classNames('destinations', { busy: isBusy() })}>{add_destinations}</div>
					<div className="footer flex space-between align-center">
						<div
							className={classNames('add-another-destination', 'flex', 'flex-center', {
								busy: isBusy(),
							})}
							onClick={handleAddAnotherDestinationOnClick}
						>
							<TripActionButton>
								<Plus />
							</TripActionButton>
							Add another destination
						</div>
						{!validity && (
							<span className="error">
								{select_from_list_message
									? 'Please select destination from the list'
									: 'Please fill out all the fields'}
							</span>
						)}
					</div>
				</Paper>
				<div className="actions-bar flex align-center flex-end">
					{isBusy() && <CircularProgress size={24} />}
					<ArbiCancelButton onClick={handleCancelOnClick} disabled={isBusy()} disableRipple>
						Cancel
					</ArbiCancelButton>
					<ArbiCancelButton onClick={handleSkipWizard} disabled={isBusy()} disableRipple>
						Skip Wizard
					</ArbiCancelButton>
					<ArbiPrimaryButton onClick={handleNextOnClick} disabled={isBusy()} disableRipple>
						Next
					</ArbiPrimaryButton>
				</div>
			</div>
		</DocumentTitle>
	);
};

export default withRouter(TripRequestWizardPage);
