import moment from 'moment';

import AppDispatcher from '../dispatcher/AppDispatcher';
import ManagementConstants from '../constants/ManagementConstants';
import ManagementStore from '../stores/ManagementStore';
import Ajax from '../utils/Ajax';
import EntityValidator from '../utils/EntityValidator';
import _ from 'lodash';
import Dates from '../utils/Dates';

// function removeTripRequestComponent(component) {
//   var trip_request = ManagementStore.getTripRequest();
//
//   AppDispatcher.handleAction({
//     actionType: ManagementConstants.REMOVE_TRIP_REQUEST_COMPONENT,
//     data: component
//   });
//
//   Ajax.removeTripRequestComponent(trip_request, component)
//     .done(function (res) {
//       AppDispatcher.handleAction({
//         actionType: ManagementConstants.TRIP_REQUEST_COMPONENT_REMOVED,
//         data: res
//       });
//     })
//     .fail(function (err) {
//       console.error("Error while canceling trip request " + component.component_type + ": ", trip_request, component, " | ERR: ", err);
//       AppDispatcher.handleAction({
//         actionType: ManagementConstants.TRIP_REQUEST_COMPONENT_FAILED_TO_REMOVE,
//         data: {
//           trip_request: trip_request,
//           error: err
//         }
//       });
//     });
// }

var _delay = (function () {
	var timer = 0;

	return function (callback, millis) {
		clearTimeout(timer);
		timer = setTimeout(callback, millis);
	};
})();

function reformatDateIfNeeded(date) {
	// if (moment.isMoment(date) && date.isValid() || moment.isDate(date)) {
	if (moment(date).isValid()) {
		return Dates.reformatDate(date);
	}

	return date;
}

function reformatFlight(flight) {
	flight.depart_date = reformatDateIfNeeded(flight.depart_date);
	flight.return_date = reformatDateIfNeeded(flight.return_date);
}

function reformatAccommodation(accommodation) {
	accommodation.check_in = reformatDateIfNeeded(accommodation.check_in);
	accommodation.check_out = reformatDateIfNeeded(accommodation.check_out);
}

function reformatCar(car) {
	car.pick_up_date = reformatDateIfNeeded(car.pick_up_date);
	car.drop_off_date = reformatDateIfNeeded(car.drop_off_date);
}

function reformatComponent(component) {
	let comp = _.extend({}, component);

	switch (comp.component_type) {
		case ManagementConstants.TRIP_REQUEST_COMPONENT_TYPE.FLIGHT:
			reformatFlight(comp);
			break;
		case ManagementConstants.TRIP_REQUEST_COMPONENT_TYPE.ACCOMMODATION:
			reformatAccommodation(comp);
			break;
		case ManagementConstants.TRIP_REQUEST_COMPONENT_TYPE.CAR:
			reformatCar(comp);
			break;
		default: // Do nothing
	}

	return comp;
}

function toAjaxPayload(trip_request, changes) {
	let payload = _.extend({}, changes);

	payload.id = trip_request.id;

	if (payload.vacation_days_from) {
		payload.vacation_days_from = reformatDateIfNeeded(payload.vacation_days_from);
	}
	if (payload.vacation_days_to) {
		payload.vacation_days_to = reformatDateIfNeeded(payload.vacation_days_to);
	}

	if (payload.flights) {
		payload.flights.map(reformatFlight);
	}
	if (payload.accommodations) {
		payload.accommodations.map(reformatAccommodation);
	}
	// payload.cars.map(reformatCar);

	return payload;
}

function reformatWizardDestination(wizard_destination) {
	let destination = _.extend({}, wizard_destination);

	destination.start_date = reformatDateIfNeeded(destination.start_date);
	destination.end_date = reformatDateIfNeeded(destination.end_date);

	return destination;
}

function wizardDestinationToAjaxPayload(wizard_destinations) {
	return wizard_destinations.map(reformatWizardDestination);
}

var ManagementActions = {
	resetInitTripRequestStatus: function () {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.RESET_INIT_TRIP_REQUEST_STATUS,
		});
	},

	initTripRequest: function (source) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.INIT_TRIP_REQUEST,
		});

		Ajax.initTripRequest()
			.done(function (res) {
				AppDispatcher.handleAction({
					actionType: ManagementConstants.TRIP_REQUEST_INITIALIZED,
					data: {
						trip_request: res,
						source: source,
					},
				});
			})
			.fail(function (err) {
				console.error('Error while initializing trip request | ERR: ', err);
				AppDispatcher.handleAction({
					actionType: ManagementConstants.TRIP_REQUEST_INIT_FAILED,
					data: {
						error: err,
					},
				});
			});
	},

	initTripRequestFlight: function () {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.INIT_TRIP_REQUEST_FLIGHT,
		});
	},

	initTripRequestAccommodation: function () {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.INIT_TRIP_REQUEST_ACCOMMODATION,
		});
	},

	initTripRequestCar: function () {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.INIT_TRIP_REQUEST_CAR,
		});
	},

	///

	initFlightReservation: function () {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.INIT_FLIGHT_RESERVATION,
		});
	},

	initAccommodationReservation: function () {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.INIT_ACCOMMODATION_RESERVATION,
		});
	},

	initCarReservation: function () {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.INIT_CAR_RESERVATION,
		});
	},

	///

	// cancelTripRequestFlight: function (flight) {
	//   if (flight.id) {
	//     removeTripRequestComponent(flight);
	//   } else {
	//     AppDispatcher.handleAction({
	//       actionType: ManagementConstants.CANCEL_NON_INIT_TRIP_REQUEST_FLIGHT,
	//       data: flight
	//     });
	//   }
	// },
	//
	// cancelTripRequestAccommodation: function (accommodation) {
	//   if (accommodation.id) {
	//     removeTripRequestComponent(accommodation);
	//   } else {
	//     AppDispatcher.handleAction({
	//       actionType: ManagementConstants.CANCEL_NON_INIT_TRIP_REQUEST_ACCOMMODATION,
	//       data: accommodation
	//     });
	//   }
	// },
	//
	// cancelTripRequestCar: function (car) {
	//   if (car.id) {
	//     removeTripRequestComponent(car);
	//   } else {
	//     AppDispatcher.handleAction({
	//       actionType: ManagementConstants.CANCEL_NON_INIT_TRIP_REQUEST_CAR,
	//       data: car
	//     });
	//   }
	// },

	updateName: function (name) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.UPDATE_TRIP_REQUEST_NAME,
			data: name,
		});
	},

	addPurpose: function (purpose) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.ADD_TRIP_REQUEST_PURPOSE,
			data: purpose,
		});
	},

	removePurpose: function (purpose) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.REMOVE_TRIP_REQUEST_PURPOSE,
			data: purpose,
		});
	},

	updatePurposes: function (purposes) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.UPDATE_TRIP_REQUEST_PURPOSES,
			data: purposes,
		});
	},

	addCostItem: function (item) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.ADD_TRIP_REQUEST_COST_CENTER,
			data: item,
		});
	},

	removeCostItem: function (item_index) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.REMOVE_TRIP_REQUEST_COST_CENTER,
			data: item_index,
		});
	},

	addTraveler: function (traveler) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.ADD_TRIP_REQUEST_TRAVELER,
			data: traveler,
		});
	},

	removeTraveler: function (traveler) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.REMOVE_TRIP_REQUEST_TRAVELER,
			data: traveler,
		});
	},

	updateTravelingByMyself: function (self_travel) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.UPDATE_TRIP_REQUEST_SELF_TRAVEL,
			data: self_travel,
		});
	},

	updateStayingWith: function (staying_with) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.UPDATE_TRIP_REQUEST_STAYING_WITH,
			data: staying_with,
		});
	},

	updateTripsScheduleAndAgenda: function (trips_schedule_and_agenda) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.UPDATE_TRIP_REQUEST_SCHEDULE_AND_AGENDA,
			data: trips_schedule_and_agenda,
		});
	},

	updateCostCenter: function (cost_center) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.UPDATE_TRIP_REQUEST_COST_CENTER,
			data: cost_center,
		});
	},

	updateConferenceNameOrWebsite: function (conference_name_or_website) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.UPDATE_TRIP_REQUEST_CONFERENCE_NAME_OR_WEBSITE,
			data: conference_name_or_website,
		});
	},

	updateConferenceEstimatedCostCurrency: function (conference_estimated_cost_currency) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.UPDATE_TRIP_REQUEST_CONFERENCE_ESTIMATED_COST_CURRENCY,
			data: conference_estimated_cost_currency,
		});
	},

	updateConferenceEstimatedCostAmount: function (conference_estimated_cost_currency_amount) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.UPDATE_TRIP_REQUEST_CONFERENCE_ESTIMATED_COST_AMOUNT,
			data: conference_estimated_cost_currency_amount,
		});
	},

	updateSpecialRequests: function (special_requests) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.UPDATE_TRIP_REQUEST_SPECIAL_REQUESTS,
			data: special_requests,
		});
	},

	updateVacationDaysFrom: function (vacation_days_from) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.UPDATE_TRIP_REQUEST_VACATION_DAYS_FROM,
			data: vacation_days_from,
		});
	},

	clearVacationDaysTo: function () {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.CLEAR_REQUEST_VACATION_DAYS,
		});
	},

	updateVacationDaysTo: function (vacation_days_to) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.UPDATE_TRIP_REQUEST_VACATION_DAYS_TO,
			data: vacation_days_to,
		});
	},
	/////////////
	// FLIGHTS //
	/////////////

	updateFlightFrom: function (flight, from) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.UPDATE_TRIP_REQUEST_FLIGHT_FROM,
			data: {
				flight: flight,
				from: from,
			},
		});
	},

	switchFlightFromTo: function (flight) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.SWITCH_TRIP_REQUEST_FLIGHT_FROM_TO,
			data: flight,
		});
	},

	updateFlightTo: function (flight, to) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.UPDATE_TRIP_REQUEST_FLIGHT_TO,
			data: {
				flight: flight,
				to: to,
			},
		});
	},

	updateFlightOneWay: function (flight, one_way) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.UPDATE_TRIP_REQUEST_FLIGHT_ONE_WAY,
			data: {
				flight: flight,
				one_way: one_way,
			},
		});
	},

	updateFlightDepartDate: function (flight, depart_date) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.UPDATE_TRIP_REQUEST_FLIGHT_DEPART_DATE,
			data: {
				flight: flight,
				depart_date: depart_date,
			},
		});
	},

	updateFlightDepartTime: function (flight, depart_time) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.UPDATE_TRIP_REQUEST_FLIGHT_DEPART_TIME,
			data: {
				flight: flight,
				depart_time: depart_time,
			},
		});
	},

	updateFlightReturnDate: function (flight, return_date) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.UPDATE_TRIP_REQUEST_FLIGHT_RETURN_DATE,
			data: {
				flight: flight,
				return_date: return_date,
			},
		});
	},

	updateFlightReturnTime: function (flight, return_time) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.UPDATE_TRIP_REQUEST_FLIGHT_RETURN_TIME,
			data: {
				flight: flight,
				return_time: return_time,
			},
		});
	},

	////////////////////
	// ACCOMMODATIONS //
	////////////////////

	updateAccommodationDestination: function (accommodation, destination) {
		console.log('updateDEST', accommodation, destination);
		AppDispatcher.handleAction({
			actionType: ManagementConstants.UPDATE_TRIP_REQUEST_ACCOMMODATION_DESTINATION,
			data: {
				accommodation: accommodation,
				destination: destination,
			},
		});
	},

	updateAccommodationDestinationAddress: function (accommodation, address) {
		console.log('updateADDR', accommodation, address);
		AppDispatcher.handleAction({
			actionType: ManagementConstants.UPDATE_TRIP_REQUEST_ACCOMMODATION_DESTINATION_ADDRESS,
			data: {
				accommodation: accommodation,
				address: address,
			},
		});
	},

	updateAccommodationCheckIn: function (accommodation, check_in) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.UPDATE_TRIP_REQUEST_ACCOMMODATION_CHECK_IN,
			data: {
				accommodation: accommodation,
				check_in: check_in,
			},
		});
	},

	updateAccommodationCheckOut: function (accommodation, check_out) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.UPDATE_TRIP_REQUEST_ACCOMMODATION_CHECK_OUT,
			data: {
				accommodation: accommodation,
				check_out: check_out,
			},
		});
	},

	updateAccommodationCheckInSpecialRequest: function (accommodation, special_request) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.UPDATE_TRIP_REQUEST_ACCOMMODATION_CHECK_IN_SPECIAL_REQUEST,
			data: {
				accommodation: accommodation,
				special_request: special_request,
			},
		});
	},

	updateAccommodationCheckOutSpecialRequest: function (accommodation, special_request) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.UPDATE_TRIP_REQUEST_ACCOMMODATION_CHECK_OUT_SPECIAL_REQUEST,
			data: {
				accommodation: accommodation,
				special_request: special_request,
			},
		});
	},

	//////////
	// CARS //
	//////////

	updateCarPickUp: function (car, pick_up) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.UPDATE_TRIP_REQUEST_CAR_PICK_UP,
			data: {
				car: car,
				pick_up: pick_up,
			},
		});
	},

	updateCarAirport: function (car, airport) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.UPDATE_TRIP_REQUEST_CAR_AIRPORT,
			data: {
				car: car,
				airport: airport,
			},
		});
	},

	updateCarDropOff: function (car, drop_off) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.UPDATE_TRIP_REQUEST_CAR_DROP_OFF,
			data: {
				car: car,
				drop_off: drop_off,
			},
		});
	},

	updateCarPickUpDate: function (car, pick_up_date) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.UPDATE_TRIP_REQUEST_CAR_PICK_UP_DATE,
			data: {
				car: car,
				pick_up_date: pick_up_date,
			},
		});
	},

	updateCarDropOffDate: function (car, drop_off_date) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.UPDATE_TRIP_REQUEST_CAR_DROP_OFF_DATE,
			data: {
				car: car,
				drop_off_date: drop_off_date,
			},
		});
	},

	resetTripRequestDraftStatus: function () {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.RESET_TRIP_REQUEST_DRAFT_STATUS,
		});
	},

	resetTripRequest: function () {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.RESET_TRIP_REQUEST,
		});
	},

	saveTripRequestAsDraft: function () {
		var trip_request = ManagementStore.getTripRequest();

		// TODO: validate trip request

		AppDispatcher.handleAction({
			actionType: ManagementConstants.SAVE_TRIP_REQUEST_AS_DRAFT,
			data: trip_request,
		});

		Ajax.saveTripRequestAsDraft(toAjaxPayload(trip_request))
			.done(function (res) {
				AppDispatcher.handleAction({
					actionType: ManagementConstants.TRIP_REQUEST_DRAFT_SAVED,
					data: res,
				});
			})
			.fail(function (err) {
				console.error('Error while saving trip request draft: ', trip_request, ' | ERR: ', err);
				AppDispatcher.handleAction({
					actionType: ManagementConstants.TRIP_REQUEST_DRAFT_FAILED,
					data: {
						trip_request: trip_request,
						error: err,
					},
				});
			});
	},

	editTripRequestMeta: function (delay) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.ABOUT_TO_SAVE_CHANGES,
		});
		_delay(function () {
			// var trip_request = ManagementStore.getTripRequest();
			let current_trip_request = ManagementStore.createTripRequestSnapshot();
			let original_snapshot = ManagementStore.getTripRequestSnapshot();
			let diff = ManagementStore.getTripRequestsDiff(current_trip_request, original_snapshot);

			console.log('Diff', diff);
			if (!diff) {
				return AppDispatcher.handleAction({
					actionType: ManagementConstants.ABOUT_TO_SAVE_CHANGES,
					data: { status: false },
				});
			}
			console.log('edit meta ', current_trip_request);

			// TODO: validate trip request

			AppDispatcher.handleAction({
				actionType: ManagementConstants.EDIT_TRIP_REQUEST_META,
				data: current_trip_request,
			});

			Ajax.editTripRequestMeta(toAjaxPayload(current_trip_request, diff))
				.done(function (res) {
					AppDispatcher.handleAction({
						actionType: ManagementConstants.TRIP_REQUEST_META_EDITED,
						data: {
							snapshot: current_trip_request,
							res: res,
						},
					});
				})
				.fail(function (err) {
					console.error('Error while saving trip request meta: ', current_trip_request, ' | ERR: ', err);
					AppDispatcher.handleAction({
						actionType: ManagementConstants.TRIP_REQUEST_META_EDIT_FAILED,
						data: {
							snapshot: current_trip_request,
							error: err,
						},
					});
				});
		}, delay);
	},

	createTripRequestComponent: function (component) {
		var trip_request = ManagementStore.getTripRequest();

		// TODO: validate trip request

		AppDispatcher.handleAction({
			actionType: ManagementConstants.CREATE_TRIP_REQUEST_COMPONENT,
			data: {
				trip_request: trip_request,
				component: component,
			},
		});

		Ajax.createTripRequestComponent(toAjaxPayload(trip_request), reformatComponent(component))
			.done(function (res) {
				AppDispatcher.handleAction({
					actionType: ManagementConstants.TRIP_REQUEST_COMPONENT_CREATED,
					data: {
						trip_request: trip_request,
						component: component,
						response: res,
					},
				});
			})
			.fail(function (err) {
				console.error(
					'Error while creating trip request component: ',
					trip_request,
					component,
					' | ERR: ',
					err,
				);
				AppDispatcher.handleAction({
					actionType: ManagementConstants.TRIP_REQUEST_COMPONENT_FAILED_TO_CREATE,
					data: {
						trip_request: trip_request,
						component: component,
						error: err,
					},
				});
			});
	},

	updateTripRequestComponent: function (component) {
		var trip_request = ManagementStore.getTripRequest();

		// TODO: validate trip request

		AppDispatcher.handleAction({
			actionType: ManagementConstants.UPDATE_TRIP_REQUEST_COMPONENT,
			data: {
				trip_request: trip_request,
				component: component,
			},
		});

		Ajax.updateTripRequestComponent(toAjaxPayload(trip_request), reformatComponent(component))
			.done(function (res) {
				AppDispatcher.handleAction({
					actionType: ManagementConstants.TRIP_REQUEST_COMPONENT_UPDATED,
					data: res,
				});
			})
			.fail(function (err) {
				console.error(
					'Error while updating trip request component: ',
					trip_request,
					component,
					' | ERR: ',
					err,
				);
				AppDispatcher.handleAction({
					actionType: ManagementConstants.TRIP_REQUEST_COMPONENT_FAILED_TO_UPDATE,
					data: {
						trip_request: trip_request,
						component: component,
						error: err,
					},
				});
			})
			.then(function () {
				const nextRequest = ManagementStore.getUpdateTripRequest();
				if (nextRequest) {
					AppDispatcher.handleAction({
						actionType: ManagementConstants.CLEAR_TRIP_LATER_REQUEST,
					});
					ManagementActions.updateTripRequestComponent(nextRequest);
				}
			});
	},

	updateTripLater: function (component) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.UPDATE_TRIP_LATER_REQUEST,
			data: component,
		});
	},

	removeTripRequestComponent: function (component) {
		var trip_request = ManagementStore.getTripRequest();

		// TODO: validate trip request
		if (EntityValidator.validateTripRequestComponentInitialized(component)) {
			AppDispatcher.handleAction({
				actionType: ManagementConstants.REMOVE_TRIP_REQUEST_COMPONENT,
				data: {
					trip_request: trip_request,
					component: component,
				},
			});

			Ajax.removeTripRequestComponent(toAjaxPayload(trip_request), reformatComponent(component))
				.done(function (res) {
					AppDispatcher.handleAction({
						actionType: ManagementConstants.TRIP_REQUEST_COMPONENT_REMOVED,
						data: {
							trip_request: trip_request,
							component: component,
							response: res,
						},
					});
				})
				.fail(function (err) {
					console.error(
						'Error while removing trip request component: ',
						trip_request,
						component,
						' | ERR: ',
						err,
					);
					AppDispatcher.handleAction({
						actionType: ManagementConstants.TRIP_REQUEST_COMPONENT_FAILED_TO_REMOVE,
						data: {
							trip_request: trip_request,
							component: component,
							error: err,
						},
					});
				});
		} else {
			AppDispatcher.handleAction({
				actionType: ManagementConstants.CANCEL_NON_INIT_TRIP_REQUEST_COMPONENT,
				data: component,
			});
		}
	},

	submitTripRequest: function () {
		var trip_request = ManagementStore.getTripRequest();

		// TODO: validate trip request

		// TODO: submit trip request
		AppDispatcher.handleAction({
			actionType: ManagementConstants.SUBMIT_TRIP_REQUEST,
			data: {
				trip_request: trip_request,
			},
		});

		Ajax.submitTripRequest(toAjaxPayload(trip_request))
			.done(function (res) {
				AppDispatcher.handleAction({
					actionType: ManagementConstants.TRIP_REQUEST_SUBMITTED,
					data: res,
				});
			})
			.fail(function (err) {
				console.error('Error while submitting trip request: ', trip_request, ' | ERR: ', err);
				AppDispatcher.handleAction({
					actionType: ManagementConstants.TRIP_REQUEST_SUBMIT_FAILED,
					data: {
						trip_request: trip_request,
						error: err,
					},
				});
			});
	},

	getTripRequests: function () {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.GET_TRIP_REQUESTS,
		});

		Ajax.getTripRequests()
			.done(function (res) {
				AppDispatcher.handleAction({
					actionType: ManagementConstants.GET_TRIP_REQUESTS_SUCCESS,
					data: res,
				});
			})
			.fail(function (err) {
				console.error('Error while getting trip requests: ', err);
				AppDispatcher.handleAction({
					actionType: ManagementConstants.GET_TRIP_REQUESTS_FAIL,
					data: err,
				});
			});
	},

	getTripRequestById: function (id) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.GET_TRIP_REQUEST_BY_ID,
		});

		Ajax.getTripRequestById(id)
			.done(function (res) {
				AppDispatcher.handleAction({
					actionType: ManagementConstants.GET_TRIP_REQUEST_BY_ID_SUCCESS,
					data: res,
				});
			})
			.fail(function (err) {
				console.error('Error while getting trip requests: ', err);
				AppDispatcher.handleAction({
					actionType: ManagementConstants.GET_TRIP_REQUEST_BY_ID_FAIL,
					data: err,
				});
			});
	},

	previewTrip: function (trip) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.PREVIEW_TRIP,
			data: trip,
		});
	},

	clearPreview: function () {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.CLEAR_TRIP_PREVIEW,
		});
	},

	previewBookedComponent: function (booked_component) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.PREVIEW_BOOKED_COMPONENT,
			data: booked_component,
		});
	},

	previewCostEstimation: function () {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.PREVIEW_TRIP_COST_ESTIMATION,
		});
	},

	previewAirfareTips: function () {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.PREVIEW_AIRFARE_TIPS,
		});
	},

	addWizardDestination: function (start_date) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.ADD_WIZARD_DESTINATION,
			data: { start_date: start_date },
		});
	},

	updateWizardDestinationLocation: function (destination, location) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.UPDATE_WIZARD_DESTINATION_LOCATION,
			data: {
				destination: destination,
				location: location,
			},
		});
	},

	updateWizardDestinationStartDate: function (destination, start_date) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.UPDATE_WIZARD_DESTINATION_START_DATE,
			data: {
				destination: destination,
				start_date: start_date,
			},
		});
	},

	updateWizardDestinationEndDate: function (destination, end_date) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.UPDATE_WIZARD_DESTINATION_END_DATE,
			data: {
				destination: destination,
				end_date: end_date,
			},
		});
	},

	clearWizardDestination: function (destination) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.CLEAR_WIZARD_DESTINATION,
			data: destination,
		});
	},

	clearAirfareWizard: function () {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.CLEAR_AIRFARE_WIZARD,
		});
	},

	removeWizardDestination: function (destination) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.REMOVE_WIZARD_DESTINATION,
			data: destination,
		});
	},

	resetWizardDestinations: function () {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.RESET_WIZARD_DESTINATIONS,
		});
	},

	submitWizardDestinations: function (destinations) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.SUBMIT_WIZARD_DESTINATIONS,
		});

		Ajax.submitWizardDestinations(wizardDestinationToAjaxPayload(destinations))
			.done(function (res) {
				AppDispatcher.handleAction({
					actionType: ManagementConstants.SUBMIT_WIZARD_DESTINATIONS_SUCCESS,
					data: res,
				});
			})
			.fail(function (err) {
				AppDispatcher.handleAction({
					actionType: ManagementConstants.SUBMIT_WIZARD_DESTINATIONS_FAIL,
					data: err,
				});
			});
	},

	discardTripRequest: function (trip_request) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.DISCARD_TRIP_REQUEST,
		});

		Ajax.discardTripRequest(trip_request)
			.done(function (res) {
				AppDispatcher.handleAction({
					actionType: ManagementConstants.DISCARD_TRIP_REQUEST_SUCCESS,
					data: {
						trip_request: trip_request,
						res: res,
					},
				});
			})
			.fail(function (err) {
				AppDispatcher.handleAction({
					actionType: ManagementConstants.DISCARD_TRIP_REQUEST_FAIL,
					data: err,
				});
			});
	},

	// same as discard but for TMs
	cancelTripRequest: function (trip_request) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.CANCEL_TRIP_REQUEST,
		});

		Ajax.cancelTripRequest(trip_request)
			.done(function (res) {
				AppDispatcher.handleAction({
					actionType: ManagementConstants.CANCEL_TRIP_REQUEST_SUCCESS,
					data: res,
				});
			})
			.fail(function (err) {
				AppDispatcher.handleAction({
					actionType: ManagementConstants.CANCEL_TRIP_REQUEST_FAIL,
					data: err,
				});
			});
	},

	updateTripRequestSource: function (source) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.UPDATE_TRIP_REQUEST_PAGE_SOURCE,
			data: source,
		});
	},

	updateTraveler: function (traveler) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.UPDATE_TRIP_REQUEST_TRAVELER,
			data: traveler,
		});
	},

	sendForApproval: function (trip) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.SEND_TRIP_FOR_APPROVAL,
		});

		Ajax.sendTripForApproval(trip)
			.done(function (res) {
				AppDispatcher.handleAction({
					actionType: ManagementConstants.SEND_TRIP_FOR_APPROVAL_SUCCESS,
					data: res,
				});
			})
			.fail(function (err) {
				AppDispatcher.handleAction({
					actionType: ManagementConstants.SEND_TRIP_FOR_APPROVAL_FAIL,
					data: err,
				});
			});
	},
	sendForPreApproval: function (trip) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.SEND_TRIP_FOR_PRE_APPROVAL,
		});

		Ajax.sendTripForPreApproval(trip)
			.done(function (res) {
				AppDispatcher.handleAction({
					actionType: ManagementConstants.SEND_TRIP_FOR_PRE_APPROVAL_SUCCESS,
					data: res,
				});
			})
			.fail(function (err) {
				AppDispatcher.handleAction({
					actionType: ManagementConstants.SEND_TRIP_FOR_PRE_APPROVAL_FAIL,
					data: err,
				});
			});
	},

	cloneTrip: function (trip) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.CLONE_TRIP,
		});

		Ajax.cloneTrip(trip)
			.done(function (res) {
				AppDispatcher.handleAction({
					actionType: ManagementConstants.CLONE_TRIP_SUCCESS,
					data: res,
				});
			})
			.fail(function (err) {
				AppDispatcher.handleAction({
					actionType: ManagementConstants.CLONE_TRIP_FAIL,
					data: err,
				});
			});
	},

	clearCloneState: function () {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.CLONE_TRIP_CLEAR,
		});
	},

	forwardTripRequest: function (component_ids, to_emails, special_requests, trip_id) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.FORWARD_TRIP_REQUEST,
		});

		Ajax.forwardTripRequest(component_ids, to_emails, special_requests, trip_id)
			.done(function (res) {
				AppDispatcher.handleAction({
					actionType: ManagementConstants.FORWARD_TRIP_REQUEST_SUCCESS,
					data: res,
				});
			})
			.fail(function (err) {
				AppDispatcher.handleAction({
					actionType: ManagementConstants.FORWARD_TRIP_REQUEST_FAIL,
					data: err,
				});
			});
	},

	addAirfareWizardFlight: function () {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.ADD_AIRFARE_WIZARD_FLIGHT,
		});
	},

	updateAirfareWizardFlightNumber: function (flight, flight_number) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.UPDATE_AIRFARE_WIZARD_FLIGHT_NUMBER,
			data: {
				flight: flight,
				flight_number: flight_number,
			},
		});
	},

	updateAirfareWizardFlightDepartDate: function (flight, departure_date) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.UPDATE_AIRFARE_WIZARD_FLIGHT_DEPARTURE_DATE,
			data: {
				flight: flight,
				departure_date: departure_date,
			},
		});
	},

	removeAirfareWizardFlight: function (flight) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.REMOVE_AIRFARE_WIZARD_FLIGHT,
			data: flight,
		});
	},

	submitWizardFlights: function (flight) {},

	resetAirfareWizardFlights: function () {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.RESET_AIRFARE_WIZARD_FLIGHTS,
		});
	},

	updateAirfareWizardCost: function (cost) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.UPDATE_AIRFARE_WIZARD_COST,
			data: cost,
		});
	},

	updateAirfareWizardCurrency: function (currency) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.UPDATE_AIRFARE_WIZARD_CURRENCY,
			data: currency,
		});
	},

	createTripAirfare: function (trip_airfare, source) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.CREATE_TRIP_AIRFARE,
		});

		let airfare = {
			trip_id: trip_airfare.trip_id,
			...(trip_airfare.cost ? { cost: trip_airfare.cost, currency: trip_airfare.currency } : {}),
		};

		if (Array.isArray(trip_airfare.flights)) {
			airfare.flights = trip_airfare.flights.map((flight) => {
				return {
					flight_number: flight.flight_number,
					departure_date: reformatDateIfNeeded(flight.departure_date),
				};
			});
		}

		Ajax.createTripAirfare(airfare)
			.done(function (res) {
				AppDispatcher.handleAction({
					actionType: ManagementConstants.CREATE_TRIP_AIRFARE_SUCCESS,
					data: {
						trip: res,
						source: source,
					},
				});
			})
			.fail(function (err) {
				console.error('Error while initializing trip request | ERR: ', err);
				AppDispatcher.handleAction({
					actionType: ManagementConstants.CREATE_TRIP_AIRFARE_FAIL,
					data: {
						error: err,
					},
				});
			});
	},

	removeTripAirfare: function (trip, airfare) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.REMOVE_TRIP_AIRFARE,
		});

		Ajax.removeTripAirfare(trip, airfare)
			.done(function (res) {
				AppDispatcher.handleAction({
					actionType: ManagementConstants.REMOVE_TRIP_AIRFARE_SUCCESS,
					data: {
						response: res,
						trip: trip,
						airfare: airfare,
					},
				});
			})
			.fail(function (err) {
				AppDispatcher.handleAction({
					actionType: ManagementConstants.REMOVE_TRIP_AIRFARE_FAIL,
					data: err,
				});
			});
	},
	removeTripAccommodation: function (trip, accommodation) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.REMOVE_TRIP_ACCOMMODATION,
		});

		Ajax.removeTripAccommodation(trip, accommodation)
			.done(function (res) {
				console.log('removeTripAccommodation SUCCESS (res, trip, accommodation)', res, trip, accommodation);
				AppDispatcher.handleAction({
					actionType: ManagementConstants.REMOVE_TRIP_ACCOMMODATION_SUCCESS,
					data: {
						response: res,
						trip: trip,
						accommodation: accommodation,
					},
				});
			})
			.fail(function (err) {
				AppDispatcher.handleAction({
					actionType: ManagementConstants.REMOVE_TRIP_ACCOMMODATION_FAIL,
					data: err,
				});
			});
	},
	cancelTripHotelBooking: function (trip, hotel_booking) {
		AppDispatcher.handleAction({
			actionType: ManagementConstants.CANCEL_TRIP_HOTEL_BOOKING,
		});

		Ajax.cancelReservation(hotel_booking.id)
			.done(function (res) {
				console.log('cancelTripHotelBooking SUCCESS (res, trip, accommodation)', res, trip, hotel_booking);
				// if 200, you can change reservation status in UI to cancelled
				AppDispatcher.handleAction({
					actionType: ManagementConstants.CANCEL_TRIP_HOTEL_BOOKING_SUCCESS,
					data: {
						trip: trip,
						hotel_booking: hotel_booking,
						res: res,
					},
				});
			})
			.fail(function (err) {
				console.error('ERROR WHILE CANCELING TRIP HOTEL BOOKING: ', trip, hotel_booking, err);
				AppDispatcher.handleAction({
					actionType: ManagementConstants.CANCEL_TRIP_HOTEL_BOOKING_FAIL,
					data: {
						trip: trip,
						hotel_booking: hotel_booking,
						error: err,
					},
				});
			});
	},
};

export default ManagementActions;
