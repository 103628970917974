import React from 'react';
import createClass from 'create-react-class';

import ResultsStore from '../../../stores/ResultsStore';
import SearchStore from '../../../stores/SearchStore';
import ProfileStore from '../../../stores/ProfileStore';
import InternalsComponent from './InternalsComponent.react';
import HotelLoader from './hotel/HotelLoader.react';
import HotelCardComponent from './hotel/HotelCardComponent.react';
import ResultsActions from '../../../actions/ResultsActions';
import SearchActions from '../../../actions/SearchActions';
import TitleManager from '../../../utils/TitleManager';
import RouterWrapper from '../../../utils/RouterWrapper';
import HotelStore from '../../../stores/HotelStore';

import Analytics from 'arbitrip-common/client/analytics';
import ResultsConstants from '../../../constants/ResultsConstants';
import { RESULTS_LOADING_LIMIT_IN_SECONDS } from 'arbitrip-common/general/constants/search-constants';
import { withRouter } from '../../../utils/withRouter';
import DocumentTitle from '../../general/DocumentTitle.react';
import { Virtuoso } from 'react-virtuoso';

function getResultsState() {
	return {
		search_results: ResultsStore.getSearchResults(),
		results_status: ResultsStore.getResultsStatus(),
		search_destination: SearchStore.getDestinationAddress(),
		search_token: SearchStore.getToken(),

		has_unfiltered_results: ResultsStore.didReturnResults(),
		completed_search: ResultsStore.getSearchResultsCompletedSearch(),
		completed_hotels: ResultsStore.getSearchResultsCompletedHotels(),
		completed_listening: ResultsStore.getSearchResultsCompletedListening(),
		completed_delay: ResultsStore.getSearchResultsCompletedDelay(),
		breakfast_only: ResultsStore.isBreakfastOnly(),

		amenities_data: ResultsStore.getAmenitiesState(),

		arbitrip_points_applied: ResultsStore.areArbitripPointsApplied(),

		private_travel: SearchStore.isPrivateTravel(),
		contract_client_company_id: SearchStore.getContractClientCompanyId(),
		is_show_loader: false,
	};
}

let _should_scroll = false;
let _last_scroll_y = -1;
let _mounted = false; // antipattern

function scrollLoop(scroll_target) {
	if (_should_scroll) {
		window.scrollTo(0, scroll_target);

		setTimeout(function () {
			if (_mounted) {
				if (_last_scroll_y < window.scrollY && window.scrollY < scroll_target) {
					_last_scroll_y = window.scrollY;
					scrollLoop(scroll_target);
				} else {
					_should_scroll = false;
					_last_scroll_y = -1;
				}
			}
		}, 50);
	}
}

const ResultsComponent = createClass({
	displayName: 'ResultsComponent',

	getInitialState: function () {
		let resultsState = getResultsState();

		resultsState.profile = ProfileStore.getProfile();

		resultsState.elements = [];
		resultsState.isInfiniteLoading = false;
		resultsState.resultsTimer = false;

		return resultsState;
	},

	buildElements: function (start, end) {
		let elements = [];

		const { search_results, completed_search, completed_hotels } = this.state;
		const { hotels } = search_results;

		if (hotels) {
			for (let i = start; i < end && i < hotels.length; i++) {
				const hotel = hotels[i];
				const hotelKey = 'hotel-key-' + hotel.id;

				elements.push({
					hotel,
					key: hotelKey,
					searchToken: this.state.search_token,
					searchTerms: this.props.searchTerms,
					profile: this.state.profile,
					enableDealLoader: completed_hotels || completed_search,
					completedListening: this.state.completed_listening,
					breakfastOnly: this.state.breakfast_only,
					forceDealLoader: !this.state.completed_delay,
					arbitripPointsApplied: this.state.arbitrip_points_applied,
					amenitiesData: this.state.amenities_data,
					sortPosition: start + i + 1,
					privateTravel: this.state.private_travel,
					contract_client_company_id: this.state.contract_client_company_id,
				});
			}
		}

		return elements;
	},

	rebuildElementsAndHandleInfiniteLoad: function () {
		const _self = this;

		// timeout here is fix to the issue with elements not updating in time
		setTimeout(() => {
			this.setState({
				isInfiniteLoading: false,
				// update the current elements
				elements: _self.buildElements(0, _self.state.elements.length),
			});
		}, 0);
	},

	_onChange: function () {
		this.setState(getResultsState(), () => {
			this.rebuildElementsAndHandleInfiniteLoad();
		});
	},

	_onProfileChange: function () {
		this.setState({ profile: ProfileStore.getProfile() }, () => {
			this.rebuildElementsAndHandleInfiniteLoad();
		});
	},

	componentDidMount: function () {
		ResultsStore.addChangeListener(this._onChange);
		ProfileStore.addChangeListener(this._onProfileChange);

		_mounted = true; // antipattern!

		this.startResultsTimer();

		const results = ResultsStore.getSearchResults();
		const selected_hotel = HotelStore.getHotelData();
		if (results && Array.isArray(results.hotels) && selected_hotel && selected_hotel.id) {
			for (const hotel of results.hotels) {
				if (hotel.id === selected_hotel.id) {
					_should_scroll = true;
					const scroll_target = HotelStore.getHotelScroll();
					return scrollLoop(scroll_target);
				}
			}
		}

		window.scrollTo(0, 0);
	},

	componentWillUnmount: function () {
		_mounted = false; // antipattern!
		_should_scroll = false;
		_last_scroll_y = -1;
		ResultsStore.removeChangeListener(this._onChange);
		ProfileStore.removeChangeListener(this._onProfileChange);
		if (this.resultsTimer) {
			clearTimeout(this.resultsTimer);
		}
	},

	componentDidUpdate(prevProps, prevState) {
		const { elements, search_results, is_show_loader } = this.state;
		const { hotels } = search_results;

		if (this.props.searchSessionCreatedTimestamp !== prevProps.searchSessionCreatedTimestamp) {
			this.setState({ resultsTimer: false }, this.startResultsTimer);
		}

		// on first load init the elements
		if (elements?.length === 0 && hotels?.length > 0) {
			this.setState({
				elements: this.buildElements(0, 20),
			});
		}

		if (this.props.showHotelLoading !== is_show_loader) {
			this.setState({
				is_show_loader: this.props.showHotelLoading,
			});
		}

		if (prevState.completed_listening !== this.state.completed_listening) {
			if (this.state.completed_listening) {
				Analytics.actions.responses.resultsFetchingTime(
					Math.ceil(Date.now() - this.props.searchSessionCreatedTimestamp.getTime()),
				);

				if (elements?.length === 0 || hotels?.length === 0) {
					Analytics.actions.responses.searchWithoutResults(
						SearchStore.getDestination(),
						this.props.searchTerms,
					);
				}

				const matched_hotel = (hotels ?? []).filter((h) => h.hotelMatch)[0];
				if (matched_hotel?.representative_deals && !Object.keys(matched_hotel.representative_deals).length) {
					Analytics.actions.responses.searchedHotelWithoutDeals(
						this.state.profile,
						SearchStore.getDestination(),
						this.props.searchTerms,
					);
				}
			}
		}
	},

	handleInfiniteLoad: function () {
		const { hotels } = this.state.search_results;
		const { elements } = this.state;

		if (!hotels) {
			return;
		}

		if (
			hotels.length === 0 ||
			(elements.length > 0 && hotels[hotels.length - 1].id === elements[elements.length - 1].hotel.id)
		) {
			this.setState({
				isInfiniteLoading: false,
			});
			return;
		}

		this.setState({
			isInfiniteLoading: true,
		});

		const elemLength = elements.length;
		let newElements = this.buildElements(elemLength, elemLength + 10);
		newElements = elements.concat(newElements);

		this.setState({
			isInfiniteLoading: false,
			elements: newElements,
		});
	},

	elementInfiniteLoad: function () {
		const { hotels } = this.state.search_results;
		if (this.state.has_unfiltered_results || (hotels && hotels.length > 0)) {
			return (
				<div className="loading">
					<i title="Loading..." className="fa fa-spin fa-spinner fa-1x" />
				</div>
			);
		} else {
			return null;
		}
	},

	handleClearFilters: function () {
		const { profile } = this.state;

		ResultsActions.clearFiltersAndApply();
		setTimeout(function () {
			ResultsActions.clearFiltersAndApply();
		}, 10);

		Analytics.actions.interactions.clearedResultsFilters(profile);
	},

	searchAgain: function () {
		SearchActions.search();
	},

	backToSearch: function () {
		const { navigate } = this.props;
		SearchActions.clearSearchTerms();
		return RouterWrapper.goToSearchPage(navigate);
	},

	startResultsTimer() {
		const results_duration_in_millis = RESULTS_LOADING_LIMIT_IN_SECONDS * 1000;

		if (this.resultsTimer) {
			clearTimeout(this.resultsTimer);
		}

		this.resultsTimer = setTimeout(() => {
			this.setState({ resultsTimer: true });
		}, results_duration_in_millis);
	},

	render: function () {
		const { completed_search, completed_hotels, has_unfiltered_results, resultsTimer, is_show_loader } = this.state;

		const { hotels } = this.state.search_results;
		const title = TitleManager.buildTitleStartsWith(this.state.search_destination);
		const custom_error = ResultsStore.getCustomError();
		let NOTES = null;

		const noHotelsFound = resultsTimer && completed_search && hotels?.length === 0;

		const show_hotel_loaders =
			((!completed_search || !completed_hotels) && !has_unfiltered_results && !noHotelsFound) || is_show_loader;

		// TODO: consider waiting until stopping to listen to firebase
		if (!hotels || hotels.length === 0) {
			if (this.state.has_unfiltered_results) {
				NOTES = (
					<div className="results-notes">
						{custom_error ? (
							<span>
								{custom_error}.{' '}
								<a href="#!" onClick={this.backToSearch}>
									Back
								</a>
							</span>
						) : (
							<>
								<img className="no-results-image" src="../img/results_page/space-man.svg" />
								<span>
									Bummer, no results came up, try to broaden your filters criteria.
									<br />{' '}
									<a href="#!" onClick={this.handleClearFilters}>
										Clear Filters
									</a>
								</span>
							</>
						)}
					</div>
				);
			} else if (ResultsStore.isErrorReturned()) {
				NOTES = (
					<div className="results-notes">
						{custom_error ? (
							<span>
								{custom_error}.{' '}
								<a href="#!" onClick={this.backToSearch}>
									Back
								</a>
							</span>
						) : (
							<span>
								Something went wrong...{' '}
								<a href="#!" onClick={this.searchAgain}>
									Search Again
								</a>
							</span>
						)}
					</div>
				);
			} else if (this.state.completed_search && this.state.completed_hotels) {
				NOTES = (
					<div className="results-notes">
						{custom_error ? (
							<span>
								{custom_error}.{' '}
								<a href="#!" onClick={this.backToSearch}>
									Back
								</a>
							</span>
						) : (
							<span>
								Bummer, no results came up, try to broaden your search criteria.{' '}
								<a href="/search">Try Again</a>
							</span>
						)}
					</div>
				);
			} else if (noHotelsFound) {
				NOTES = (
					<div className="results-notes">
						<img className="no-results-image" src="../img/results_page/space-man.svg" />
						<span>No results for this destination</span>
					</div>
				);
			}
		} else if (this.state.results_status === ResultsConstants.STATUSES.WAITING) {
			NOTES = this.elementInfiniteLoad();
		}

		// const POWERED_BY_TRUST_YOU = (hotels && hotels.length > 0)
		//   ? <div className="trust-you">
		//     <a href="//trustyou.com" className="verified-reviews" target="_blank" rel="noopener noreferrer">
		//       <img src="//api.trustyou.com/static/img/powered-by-trustyou-small.png" alt="Verified Reviews TrustYou" />
		//     </a>
		//   </div>
		//   : null;

		let results_classes = 'results';
		if (this.state.results_status === ResultsConstants.STATUSES.WAITING) {
			results_classes += ' loading';
		}

		return (
			<DocumentTitle title={title}>
				<React.Fragment>
					<InternalsComponent
						hotels={hotels}
						searchSessionCreatedTimestamp={this.props.searchSessionCreatedTimestamp}
					/>
					<div className={results_classes}>
						<Virtuoso
							useWindowScroll
							data={show_hotel_loaders ? new Array(10).fill() : this.state.elements}
							endReached={this.handleInfiniteLoad}
							itemContent={(index, hotel) =>
								show_hotel_loaders ? (
									<HotelLoader key={`hotel-loader-${index}`} hotel={{ id: index }} />
								) : (
									<HotelCardComponent {...hotel} />
								)
							}
						/>

						{NOTES}

						{/* {POWERED_BY_TRUST_YOU} */}
					</div>
				</React.Fragment>
			</DocumentTitle>
		);
	},
});

export default withRouter(ResultsComponent);
