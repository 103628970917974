import _ from 'lodash';
import React from 'react';

import Formsy from 'formsy-react';

import withStyles from '@mui/styles/withStyles';

import {
	FormHelperText,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Select,
	MenuItem,
	Button,
	FormControl,
} from '@mui/material';

import InputField from '../../../components/general/InputField.react';

import PropTypes from 'prop-types';

import classNames from 'classnames';

import UsersManagementService from './UsersManagementService';
import UsersManagementActions from '../../../actions/UsersManagementActions';
import UsersManagementConstants from '../../../constants/UsersManagementConstants';

import PageLoader from '../../general/PageLoader.react';
import EmployeesAutocomplete from '../../general/EmployeesAutocomplete.react';

const styles = () => ({
	autocomplete: {
		marginTop: 6,
	},
	role: {
		marginTop: 16,
	},
	select: {
		marginTop: 24,
	},
	selectWidth: {
		minWidth: '30%',
	},
	input: {
		marginBottom: '10px',
	},
	label: {
		fontSize: 14,
		fontWeight: 'bold',
		color: 'rgba(0, 0, 0, 0.54)',
		transform: 'translate(0, 1.5px) scale(0.75)',
		transformOrigin: 'top left',
		transition: 'color 200ms',
	},
});

class NewUserDialog extends React.Component {
	constructor(props) {
		super(props);

		this.newUserFormRef = React.createRef();

		this.state = {
			open: false,
			role: UsersManagementConstants.SYSTEM_ROLES.EMPLOYEE,
			department: props.user && props.user.department ? props.user.department : null,
			location: props.user && props.user.location ? props.user.location : null,
			costCenter: props.user && props.user.cost_center ? props.user.cost_center : null,
			approver: props.user && props.user.approver ? props.user.approver : null,
			inviteUser: false,
			loading: false,
			errorReturned: false,
			originalUser: null,
			disableBackdropClick: true,
			departmentTouched: false,
			locationTouched: false,
			costCenterTouched: false,
			formTouched: false,
		};

		this.submit = this.submit.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.saveUser = this.saveUser.bind(this);
		this.enableSaveButtonCheck = this.enableSaveButtonCheck.bind(this);
		this.hasFormChanged = this.hasFormChanged.bind(this);
		this.handleSelectChange = this.handleSelectChange.bind(this);
		this.selectDepartmentTouched = this.selectDepartmentTouched.bind(this);
		this.selectLocationTouched = this.selectLocationTouched.bind(this);
		this.selectCostCenterTouched = this.selectCostCenterTouched.bind(this);
		this.onUpdateApprover = this.onUpdateApprover.bind(this);
	}

	componentDidUpdate(prevProps, prevState) {
		let { user, open } = this.props;

		if ((!prevProps.open && open) || (prevProps.open && !open)) {
			this.setState({ open });
		}

		if (
			(!prevProps.user && user) ||
			(prevProps.user && !user) ||
			(prevProps.user && prevProps.user.roles && user && user.roles)
		) {
			let prevRoles = prevProps.user ? prevProps.user.roles : [];
			let userRoles = user ? user.roles : [];

			let difference = prevRoles
				.filter((x) => !userRoles.includes(x))
				.concat(userRoles.filter((x) => !prevRoles.includes(x)));

			if (difference.length) {
				let role = UsersManagementService.getUserSelectedRole(user);
				this.setState({ role: role });
			}
		}

		if (prevProps.user && !user) {
			this.setState({
				department: null,
				departmentTouched: false,
				location: null,
				locationTouched: false,
				costCenter: null,
				costCenterTouched: false,
				formTouched: false,
				approver: null,
			});
		}

		if ((!prevProps.user && user) || (prevProps.user && user && prevProps.user.department !== user.department)) {
			this.setState({ department: user.department });
		}

		if ((!prevProps.user && user) || (prevProps.user && user && prevProps.user.location !== user.location)) {
			this.setState({ location: user.location });
		}

		if ((!prevProps.user && user) || (prevProps.user && user && prevProps.user.cost_center !== user.cost_center)) {
			this.setState({ costCenter: user.cost_center });
		}

		if ((!prevProps.user && user) || (prevProps.user && user && prevProps.user.approver !== user.approver)) {
			this.setState({ approver: user.approver });
		}

		if (
			(!prevState.originalUser && user) ||
			(prevState.originalUser && user && prevState.originalUser.id !== user.id) ||
			(prevState.originalUser && !user)
		) {
			this.setState({ originalUser: user });
		}
	}

	handleSelectChange(event, type) {
		this.setState({ [type]: event.target.value });
		setTimeout(() => {
			this.enableSaveButtonCheck();
		});
	}

	selectDepartmentTouched() {
		this.setState({ departmentTouched: true });
	}

	selectLocationTouched() {
		this.setState({ locationTouched: true });
	}

	selectCostCenterTouched() {
		this.setState({ costCenterTouched: true });
	}

	/**
	 * Check when data has changed
	 * Only if data has changed we want to give the option
	 * to send the form to the server
	 * @returns {boolean}
	 */
	hasFormChanged() {
		let { originalUser, department, location, costCenter, role, approver } = this.state;
		let model = this.newUserFormRef.getModel();

		let isEqual =
			originalUser &&
			originalUser.first_name === model.first_name &&
			originalUser.last_name === model.last_name &&
			(originalUser.employee_number === model.employee_number ||
				(!originalUser.employee_number && _.isEmpty(model.employee_number))) &&
			originalUser.department === department &&
			originalUser.location === location &&
			originalUser.cost_center === costCenter &&
			originalUser.roles[0] === role &&
			_.isEqual(originalUser.approver, approver);

		return !isEqual;
	}

	enableSaveButtonCheck() {
		if (this.hasFormChanged()) {
			this.setState({ disableBackdropClick: true });
		} else {
			this.setState({ disableBackdropClick: false });
		}
	}

	saveUser(data) {
		this.setState({ formTouched: true });

		let { user, profile } = this.props;
		let { department, location, costCenter, approver } = this.state;

		let company = profile.company;
		let approvalFlowEnabled = _.get(profile, 'approval_flow_enabled');

		if (!user) {
			// If there is no department, location or cost center
			// set them all as touched
			if (
				(company && company.departments && company.departments.length && !department) ||
				(company.locations && company.locations.length && !location) ||
				(company.cost_centers && company.cost_centers.length && !costCenter)
			) {
				this.setState({ departmentTouched: true, locationTouched: true, costCenterTouched: true });
				return;
			}

			if (approvalFlowEnabled && !approver) {
				return;
			}
		}

		if (data.inviteUser) {
			this.setState({ inviteUser: true });
		}
		setTimeout(() => {
			this.newUserFormRef.submit();
		});
	}

	closeModal() {
		this._resetState();
		this.props.onClose();
	}

	setLoading(bool) {
		this.setState({ loading: bool });
	}

	sendMessage(message, variant) {
		let { onMessage } = this.props;

		if (_.isFunction(onMessage)) {
			onMessage(message, variant);
		}
	}

	submit(model) {
		if (!this.hasFormChanged()) {
			this._resetState();
			this.props.onSave();
			return;
		}

		if (_.isObject(model)) {
			let options = {
				invite_user: this.state.inviteUser,
			};
			let { user, profile } = this.props;

			this.setLoading(true);

			model = UsersManagementService.initUserModel(model, user, profile, this.props, this.state);

			let action = user ? UsersManagementActions.updateUser : UsersManagementActions.addNewUser;

			action({ user: model, options: options })
				.done((res) => {
					this.setLoading(false);
					if (res.success === false) {
						this.sendMessage(res.message, 'error');
					} else {
						this._resetState();
						this.props.onSave(res);

						if (!user) {
							this.setState({
								role: UsersManagementConstants.SYSTEM_ROLES.EMPLOYEE,
								department: null,
								location: null,
								costCenter: null,
								approver: null,
								departmentTouched: false,
								locationTouched: false,
								costCenterTouched: false,
								formTouched: false,
							});
						}
					}
				})
				.fail((err) => {
					console.log(err);
					this.setLoading(false);
					this.sendMessage(UsersManagementConstants.ERRORS.SAVE_USER, 'error');
					this.setState({ errorReturned: true });
				});
		}
	}

	onUpdateApprover(approver) {
		this.setState({ approver });

		setTimeout(() => {
			this.enableSaveButtonCheck();
		});
	}

	getNewUserDialogContentRender() {
		let { user, profile, isNewUser, classes } = this.props;
		let {
			role,
			department,
			location,
			costCenter,
			departmentTouched,
			locationTouched,
			costCenterTouched,
			formTouched,
		} = this.state;

		let company = profile.company;
		let approvalFlowEnabled = _.get(profile, 'approval_flow_enabled');

		return (
			<React.Fragment>
				<DialogContent>
					<Formsy
						ref={(f) => (this.newUserFormRef = f)}
						onValidSubmit={this.submit}
						onValid={this.enableSaveButtonCheck}
						onInvalid={this.enableSaveButtonCheck}
					>
						<div className={classNames(classes.input)}>
							<InputField
								label="Email"
								autoFocus={true}
								id="email"
								name="email"
								value={user ? user.email : ''}
								validations="isEmail"
								validationError="This is not a valid email"
								disabled={!isNewUser}
								required={true}
								touched={formTouched}
								inputLabelProps={{
									shrink: true,
								}}
							/>
						</div>

						<div className={classNames(classes.input)}>
							<InputField
								label="First Name"
								id="first_name"
								name="first_name"
								validations={{ matchRegexp: UsersManagementConstants.REGEX.LETTERS_AND_SPACING }}
								validationError="First name should include letters only"
								value={user ? user.first_name : ''}
								required={true}
								touched={formTouched}
								firstCapitalLetter={true}
								inputLabelProps={{
									shrink: true,
								}}
							/>
						</div>

						<div className={classNames(classes.input)}>
							<InputField
								label="Last Name"
								id="last_name"
								name="last_name"
								validations={{ matchRegexp: UsersManagementConstants.REGEX.LETTERS_AND_SPACING }}
								validationError="Last name should include letters only"
								value={user ? user.last_name : ''}
								required={true}
								touched={formTouched}
								firstCapitalLetter={true}
								inputLabelProps={{
									shrink: true,
								}}
							/>
						</div>

						<div className={classNames(classes.input)}>
							<InputField
								label="Employee Number"
								id="employee_number"
								name="employee_number"
								value={user ? user.employee_number : ''}
								inputLabelProps={{
									shrink: true,
								}}
							/>
						</div>

						<div className={classNames(classes.autocomplete, classes.input)}>
							<EmployeesAutocomplete
								required={approvalFlowEnabled}
								touched={formTouched}
								label="Approver"
								value={user ? user.approver : null}
								onUpdate={this.onUpdateApprover}
							/>
						</div>

						<FormControl className={classNames(classes.role, classes.selectWidth)}>
							<label htmlFor="role" className={classNames(classes.label)}>
								Role
							</label>
							<Select
								value={role}
								onChange={(e) => this.handleSelectChange(e, 'role')}
								inputProps={{
									name: 'role',
									id: 'role',
								}}
							>
								<MenuItem value={UsersManagementConstants.SYSTEM_ROLES.EMPLOYEE}>Employee</MenuItem>
								<MenuItem value={UsersManagementConstants.SYSTEM_ROLES.REGIONAL_ADMIN}>
									Regional Admin
								</MenuItem>
								<MenuItem value={UsersManagementConstants.SYSTEM_ROLES.EXECUTIVE}>Executive</MenuItem>
								<MenuItem value={UsersManagementConstants.SYSTEM_ROLES.TRAVEL_MANAGER}>
									Travel Manager
								</MenuItem>
							</Select>
						</FormControl>

						{company && company.departments && company.departments.length ? (
							<React.Fragment>
								<br />
								<FormControl
									className={classNames(classes.select, classes.selectWidth)}
									error={departmentTouched && !department}
								>
									<label htmlFor="department" className={classNames(classes.label)}>
										Department *
									</label>
									<Select
										displayEmpty
										value={department ? department : ''}
										onChange={(e) => this.handleSelectChange(e, 'department')}
										inputProps={{
											name: 'department',
											id: 'department',
											onBlur: () => this.selectDepartmentTouched(),
										}}
									>
										<MenuItem value="" disabled>
											Pick a Department
										</MenuItem>
										{company.departments.map((d) => (
											<MenuItem value={d._id} key={d._id}>
												{d.name}
											</MenuItem>
										))}
									</Select>
									{departmentTouched && !department && (
										<FormHelperText>Field is required</FormHelperText>
									)}
								</FormControl>
							</React.Fragment>
						) : null}

						{company && company.locations && company.locations.length ? (
							<React.Fragment>
								<br />
								<FormControl
									className={classNames(classes.select, classes.selectWidth)}
									error={locationTouched && !location}
								>
									<label htmlFor="location" className={classNames(classes.label)}>
										Location *
									</label>
									<Select
										displayEmpty
										value={location ? location : ''}
										onChange={(e) => this.handleSelectChange(e, 'location')}
										inputProps={{
											name: 'location',
											id: 'location',
											onBlur: () => this.selectLocationTouched(),
										}}
									>
										<MenuItem value="" disabled>
											Pick a Location
										</MenuItem>
										{company.locations.map((l) => (
											<MenuItem value={l._id} key={l._id}>
												{l.name}
											</MenuItem>
										))}
									</Select>
									{locationTouched && !location && <FormHelperText>Field is required</FormHelperText>}
								</FormControl>
							</React.Fragment>
						) : null}

						{company && company.cost_centers && company.cost_centers.length ? (
							<React.Fragment>
								<br />
								<FormControl
									className={classNames(classes.select, classes.selectWidth)}
									error={costCenterTouched && !costCenter}
								>
									<label htmlFor="costCenter" className={classNames(classes.label)}>
										Cost Center *
									</label>
									<Select
										displayEmpty
										value={costCenter ? costCenter : ''}
										onChange={(e) => this.handleSelectChange(e, 'costCenter')}
										inputProps={{
											name: 'costCenter',
											id: 'costCenter',
											onBlur: () => this.selectCostCenterTouched(),
										}}
									>
										<MenuItem value="" disabled>
											Pick a Cost Center
										</MenuItem>
										{company.cost_centers.map((c) => (
											<MenuItem value={c._id} key={c._id}>
												{c.name}
											</MenuItem>
										))}
									</Select>
									{costCenterTouched && !costCenter && (
										<FormHelperText>Field is required</FormHelperText>
									)}
								</FormControl>
							</React.Fragment>
						) : null}
						<br />
						<br />
						<br />
					</Formsy>
				</DialogContent>
			</React.Fragment>
		);
	}

	getDialogActionsRender() {
		let { user } = this.props;
		let { errorReturned } = this.state;

		return (
			<div className="flex">
				<DialogActions>
					<Button style={{ float: 'left' }} onClick={this.closeModal}>
						Cancel
					</Button>
				</DialogActions>
				<div className="flex-spacer" />
				<DialogActions>
					<Button onClick={this.saveUser} color="primary">
						{errorReturned ? 'Retry' : user ? 'Save' : 'Create'}
					</Button>
					<Button
						style={{ display: user ? 'none' : 'inline-block' }}
						onClick={() => this.saveUser({ inviteUser: true })}
						color="primary"
					>
						{errorReturned ? 'Retry and Invite' : 'Create and Invite'}
					</Button>
				</DialogActions>
			</div>
		);
	}

	render() {
		let { isNewUser } = this.props;
		let { loading, open, disableBackdropClick } = this.state;

		return (
			<React.Fragment>
				<Dialog
					className={'new-user-dialog' + (loading ? ' blur cursor-progress' : '')}
					open={open}
					onClose={this.closeModal}
					fullWidth={true}
					maxWidth="sm"
					aria-labelledby="form-dialog-title"
				>
					<DialogTitle id="form-dialog-title">{isNewUser ? 'Create New User' : 'Edit User'}</DialogTitle>

					{this.getNewUserDialogContentRender()}
					{this.getDialogActionsRender()}
				</Dialog>
				{loading && <PageLoader onTop={true} />}
			</React.Fragment>
		);
	}

	_resetState() {
		this.setState({
			inviteUser: false,
			errorReturned: false,
			departmentTouched: false,
			locationTouched: false,
			formTouched: false,
		});
	}
}

NewUserDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	user: PropTypes.object,
	isNewUser: PropTypes.bool,
	profile: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
	onMessage: PropTypes.func,
	classes: PropTypes.object,
};

export default withStyles(styles)(NewUserDialog);
