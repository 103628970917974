const CONSTANTS = module.exports = {};
CONSTANTS.SUPPLIER_GATEWAYS = {
    TRAVELGATE: 'travelgate'
};

CONSTANTS.TRAVELGATE_BOOKABLE_SUPPLIERS = {
    COSMOBEDS: 'cosmobeds',
    DARINA: 'darina',
    DIDA: 'dida',
    DOTW: 'dotw',
    G2TRAVEL: 'g2travel',
    IMPERA: 'impera',
    MIKI: 'miki',
    PRICETRAVEL: 'pricetravel',
    RTS: 'rts',
    SMYROOMS: 'smyrooms',
    TRAVCO: 'travco',
    VOYAGETOURS: 'voyagetours',
    WORLD2MEET: 'world2meet',
};

CONSTANTS.SUPPLIERS = CONSTANTS.RESERVATION_SOURCES = {
    NA: 'not_exists',
    AGODA_OTA: 'ota-agoda.com', // maybe hotel website or conference website etc..

    ARBITRIP: 'sup-arbitrip',

    ARN: 'sup-arn', // Obsolete
    BOOKING: 'sup-booking',
    BOOKING_OTA: 'ota-booking.com', // direct booking on site, booked outside the system
    // EAN : 'sup-ean', // for ean and not expediaSite. Currently, not supported
    EXPEDIA: 'sup-expedia', // for expediaSite and not ean,
    EXPEDIA_OTA: 'ota-expedia.com', // direct booking on site, booked outside the system

    HOTELBEDS: 'sup-hotelbeds',
    HOTELS_OTA: 'ota-hotels.com',    // direct booking on site, booked outside the system,
    HYPERGUEST: 'sup-hyperguest',
    GENERAL: 'general', // maybe hotel website or conference website etc..
    GOGLOBAL: 'sup-goglobal',
    PREMIERINN: 'sup-premierinn',
    PTC: 'sup-ptc',
    RESTEL: 'sup-restel',
    SUNHOTELS: 'sup-sunhotels',
    CTRIP: 'sup-ctrip',
    TBOHOLIDAYS: 'sup-tboholidays',
    TRAVELPORT: 'sup-travelport',
    TRAVOLUTIONARY: 'sup-travolutionary',
    YAYA: 'sup-yaya',

    // Travelgate
    COSMOBEDS: 'tgx-cosmobeds',
    DARINA: 'tgx-darina',
    DIDA: 'tgx-dida',
    DOTW: 'tgx-dotw',
    G2TRAVEL: 'tgx-g2travel',
    IMPERA: 'tgx-impera',
    MIKI: 'tgx-miki',
    PRICETRAVEL: 'tgx-pricetravel',
    RTS: 'tgx-rts',
    SMYROOMS: 'tgx-smyrooms',
    TRAVCO: 'tgx-travco',
    VOYAGETOURS: 'tgx-voyagetours',
    WORLD2MEET: 'tgx-world2meet',
};

////////////////////////////////////////////////////////////////////////////////

CONSTANTS.TRAVOLUTIONARY_SUB_SUPPLIERS = {
    // Add here when more subsuppliers are added
    BONOTEL: 'bonotel',
    // DIDA: 'dida',
    GETAROOM: 'getaroom',
    // GOGLOBAL: 'go global',
    HOTELSPRO: 'hotelspro',
    // HYPERGUEST: 'hyperguest',
    // MIKITOURS: 'miki',
    NUITEE: 'nuitee',
    TBO: 'tbo',
    TOTALSTAY: 'totalstay',
    TRAVCO: 'travco', // TODO: ask jerome if we can comment this out...
    // WORLD2MEET: 'world2meet',
    YALAGO: 'yalago',
};

CONSTANTS.DIRECT_BOOKABLE_SUPPLIERS = { // these are source we can book from directly
    BOOKING: 'booking',
    EXPEDIA: 'expedia',
    // make sure starts with lowercase
    PTC: 'ptc',
    // ARN: 'arn', Obsolete
    GOGLOBAL: 'goglobal',
    HOTELBEDS: 'hotelbeds',
    HYPERGUEST: 'hyperguest',
    PREMIERINN: 'premierinn',
    RESTEL: 'restel',
    SUNHOTELS: 'sunhotels',
    CTRIP: 'ctrip',
    TRAVELPORT: 'travelport',
    TBOHOLIDAYS: 'tboholidays',
    TRAVOLUTIONARY: 'travolutionary',
    YAYA: 'yaya',
    // If you add another here, don't forget to update reservations-etl getPriceAfterChooseProd and CONSTANTS.SUPPLIERS_FIELD_NAMES

    // Travelgate
    ...CONSTANTS.TRAVELGATE_BOOKABLE_SUPPLIERS,

    // If you add a new supplier, please make sure you also updated list of suppliers in packages/deals-grouper/gimmonix_deals_grouper.js
};

CONSTANTS.BOOKABLE_SUPPLIERS = {
    // If you add another here, don't forget to update reservations-etl getPriceAfterChooseProd and CONSTANTS.SUPPLIERS_FIELD_NAMES
    ...CONSTANTS.DIRECT_BOOKABLE_SUPPLIERS
};

CONSTANTS.SUPPLIERS_FIELD_NAMES = {
    [CONSTANTS.RESERVATION_SOURCES.ARN]: CONSTANTS.BOOKABLE_SUPPLIERS.ARN, // obsolete
    [CONSTANTS.RESERVATION_SOURCES.BOOKING]: CONSTANTS.BOOKABLE_SUPPLIERS.BOOKING,
    [CONSTANTS.RESERVATION_SOURCES.EXPEDIA]: CONSTANTS.BOOKABLE_SUPPLIERS.EXPEDIA,
    [CONSTANTS.RESERVATION_SOURCES.HOTELBEDS]: CONSTANTS.BOOKABLE_SUPPLIERS.HOTELBEDS,
    [CONSTANTS.RESERVATION_SOURCES.GOGLOBAL]: CONSTANTS.BOOKABLE_SUPPLIERS.GOGLOBAL,
    [CONSTANTS.RESERVATION_SOURCES.PREMIERINN]: CONSTANTS.BOOKABLE_SUPPLIERS.PREMIERINN,
    [CONSTANTS.RESERVATION_SOURCES.PTC]: CONSTANTS.BOOKABLE_SUPPLIERS.PTC,
    [CONSTANTS.RESERVATION_SOURCES.RESTEL]: CONSTANTS.BOOKABLE_SUPPLIERS.RESTEL,
    [CONSTANTS.RESERVATION_SOURCES.SUNHOTELS]: CONSTANTS.BOOKABLE_SUPPLIERS.SUNHOTELS,
    [CONSTANTS.RESERVATION_SOURCES.CTRIP]: CONSTANTS.BOOKABLE_SUPPLIERS.CTRIP,
    'sup-ean': 'ean', // obsolete
    [CONSTANTS.RESERVATION_SOURCES.TBOHOLIDAYS]: CONSTANTS.BOOKABLE_SUPPLIERS.TBOHOLIDAYS,
    [CONSTANTS.RESERVATION_SOURCES.TRAVELPORT]: CONSTANTS.BOOKABLE_SUPPLIERS.TRAVELPORT,
    [CONSTANTS.RESERVATION_SOURCES.TRAVOLUTIONARY]: CONSTANTS.BOOKABLE_SUPPLIERS.TRAVOLUTIONARY, // obsolete
    [CONSTANTS.RESERVATION_SOURCES.YAYA]: CONSTANTS.BOOKABLE_SUPPLIERS.YAYA,
    [CONSTANTS.RESERVATION_SOURCES.HYPERGUEST]: CONSTANTS.BOOKABLE_SUPPLIERS.HYPERGUEST,

    // Travelgate
    [CONSTANTS.RESERVATION_SOURCES.COSMOBEDS]: CONSTANTS.BOOKABLE_SUPPLIERS.COSMOBEDS,
    [CONSTANTS.RESERVATION_SOURCES.DARINA]: CONSTANTS.BOOKABLE_SUPPLIERS.DARINA,
    [CONSTANTS.RESERVATION_SOURCES.DIDA]: CONSTANTS.BOOKABLE_SUPPLIERS.DIDA,
    [CONSTANTS.RESERVATION_SOURCES.DOTW]: CONSTANTS.BOOKABLE_SUPPLIERS.DOTW,
    [CONSTANTS.RESERVATION_SOURCES.G2TRAVEL]: CONSTANTS.BOOKABLE_SUPPLIERS.G2TRAVEL,
    [CONSTANTS.RESERVATION_SOURCES.IMPERA]: CONSTANTS.BOOKABLE_SUPPLIERS.IMPERA,
    [CONSTANTS.RESERVATION_SOURCES.MIKI]: CONSTANTS.BOOKABLE_SUPPLIERS.MIKI,
    [CONSTANTS.RESERVATION_SOURCES.PRICETRAVEL]: CONSTANTS.BOOKABLE_SUPPLIERS.PRICETRAVEL,
    [CONSTANTS.RESERVATION_SOURCES.RTS]: CONSTANTS.BOOKABLE_SUPPLIERS.RTS,
    [CONSTANTS.RESERVATION_SOURCES.SMYROOMS]: CONSTANTS.BOOKABLE_SUPPLIERS.SMYROOMS,
    [CONSTANTS.RESERVATION_SOURCES.TRAVCO]: CONSTANTS.BOOKABLE_SUPPLIERS.TRAVCO,
    [CONSTANTS.RESERVATION_SOURCES.VOYAGETOURS]: CONSTANTS.BOOKABLE_SUPPLIERS.VOYAGETOURS,
    [CONSTANTS.RESERVATION_SOURCES.WORLD2MEET]: CONSTANTS.BOOKABLE_SUPPLIERS.WORLD2MEET,
};