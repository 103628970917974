const BEAUTIFIERS = {
	EXPEDIA: {
		patterns: [
			/note: We understand that sometimes your travel plans change/,
			/Please note: non-refundable room note: This rate is non-refundable and cannot be changed or cancelled/,
		],
		beautify: beautifyExpedia,
		extract: extractFromExpedia,
	},
};

const CONSTS = {
	NEW_LINE: '\r\n',
};

const EXTRACT_PATTERNS = {
	RESORT_FEE: /^Resort\s?Fee/i,
	HOTEL_FEE: /^Hotel\s?Fee/i,
};

function beautifyExpedia(supplier_terms) {
	return genericBeautify(supplier_terms);
}

function extractFromExpedia(supplier_terms) {
	var split_terms = beautifyExpedia(supplier_terms).split(CONSTS.NEW_LINE);

	var extract = [];
	split_terms.forEach(function (line, idx) {
		Object.keys(EXTRACT_PATTERNS).forEach(function (pattern) {
			if (EXTRACT_PATTERNS[pattern].test(line)) {
				console.log('FOUND pattern', pattern, line);
				if (extract.indexOf(line) < 0) {
					if (idx > 0) {
						extract.push(line);
					}
				}
			}
		});
	});

	if (extract.length > 0) {
		return extract.join(CONSTS.NEW_LINE) + CONSTS.NEW_LINE + CONSTS.NEW_LINE + split_terms.join(CONSTS.NEW_LINE);
	} else {
		return split_terms.join(CONSTS.NEW_LINE);
	}
}

function genericBeautify(supplier_terms) {
	var split_terms = supplier_terms.split(CONSTS.NEW_LINE).map(function (line) {
		var mapped_line = line
			.trim()
			.replace('&amp;', '&')
			.replace(/&?nbsp;/, ' ');

		return mapped_line;
	});

	return split_terms.join(CONSTS.NEW_LINE);
}

function beautify(supplier_terms) {
	var supplier = Object.keys(BEAUTIFIERS).find(function (supplier) {
		return BEAUTIFIERS[supplier].patterns.some(function (pattern) {
			return pattern.test(supplier_terms);
		});
	});

	if (supplier) {
		return BEAUTIFIERS[supplier].beautify(supplier_terms);
	} else {
		return genericBeautify(supplier_terms);
	}
}

function extract(supplier_terms) {
	var supplier = Object.keys(BEAUTIFIERS).find(function (supplier) {
		return BEAUTIFIERS[supplier].patterns.some(function (pattern) {
			return pattern.test(supplier_terms);
		});
	});

	if (supplier) {
		return BEAUTIFIERS[supplier].extract(BEAUTIFIERS[supplier].beautify(supplier_terms));
	} else {
		return genericExtract(supplier_terms);
	}
}

function genericExtract(supplier_terms) {
	return supplier_terms;
}

export default {
	beautify: beautify,
	extract: extract,
};
