import React from 'react';
import PropTypes from 'prop-types';

class HotelNeighborhoodsBookmark extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			container_style: {
				position: 'absolute',
				right: 0,
				top: 30,
				lineHeight: '12px',
			},
			style: {
				background: '#ccc',
				padding: '0 0 1px 2px',
				borderRadius: '2px 0 0 2px',
				fontSize: 12,
				float: 'right',
				marginBottom: 2,
			},
		};
	}

	render() {
		const { hotel } = this.props;
		const { container_style, style } = this.state;

		const neighborhoods = hotel.neighborhoods || [];
		// (Array.isArray(hotel.neighborhoods) && (hotel.neighborhoods.length > 0))
		//     ? hotel.neighborhoods.join(' | ')
		//     : '-';

		return (
			<div style={container_style}>
				{neighborhoods.length > 0 ? (
					neighborhoods.map((n) => (
						<React.Fragment key={n}>
							<span style={style} key={n}>
								{n}
							</span>
							<br />
						</React.Fragment>
					))
				) : (
					<span style={style}>-</span>
				)}
			</div>
		);
	}
}

HotelNeighborhoodsBookmark.propTypes = {
	hotel: PropTypes.object.isRequired,
};

export default HotelNeighborhoodsBookmark;
