import React from 'react';
import createClass from 'create-react-class';

import FiltersActions from '../../../../actions/FiltersActions';

import FiltersConstants from '../../../../constants/FiltersConstants';
import Analytics from 'arbitrip-common/client/analytics';

import FilterCheckboxWithLabel from './components/FilterCheckboxWithLabel.react';

const TravelPolicyFilter = createClass({
	displayName: 'TravelPolicyFilter',

	handleCheckboxChange: function (event, isInputChecked) {
		const { profile } = this.props;

		event.persist();
		FiltersActions.filterByInPolicy(isInputChecked);
		setTimeout(function () {
			FiltersActions.filterByInPolicy(isInputChecked);
		}, 10);

		const filter_value = isInputChecked ? 'Show Only In Policy' : 'Show All';
		Analytics.actions.interactions.filteredResults(
			profile,
			FiltersConstants.FILTER_METHOD.TRAVEL_POLICY,
			filter_value,
		);
	},

	render() {
		return (
			<div className="filter disabled">
				<label className="filter-title" htmlFor="in-policy-filter">
					Travel Policy
				</label>
				<div name="in-policy-filter" className="check-list">
					<div className="checkbox-container">
						<FilterCheckboxWithLabel
							checked={this.props.filter}
							label="Show Only In Policy"
							onChange={(e, c) => this.handleCheckboxChange(e, c)}
						/>
					</div>
				</div>
			</div>
		);
	},
});

export default TravelPolicyFilter;
