import _ from 'lodash';
import React from 'react';
import classNames from 'classnames';

const close_to_prefix = 'Close to ';
const minimal_number_of_words = 3;
const service_regex = new RegExp('service', 'i');
const blacklist = ['Close to Starbucks'];
const base_max_reasons = 4;
function shouldAddNewLine(reason) {
	return reason.startsWith(close_to_prefix) || reason.split(' ').length >= minimal_number_of_words;
}

function getMaxReasons(hotel) {
	const long_name = hotel.name.length >= 27;
	const long_address = hotel.address.length >= 33;
	const has_flags =
		hotel.reasons.flags &&
		(hotel.reasons.flags.free_wifi ||
			hotel.reasons.flags.good_wifi ||
			hotel.reasons.flags.good_breakfast ||
			(hotel.reasons.flags.good_service && !service_regex.test(_.get(hotel, 'ty_text_descriptions.summary'))));

	return base_max_reasons - ((long_name && long_address ? 1 : 0) + (has_flags ? 1 : 0));
}

class HotelService {
	getFlagsRender(hotel, reasonsFlags) {
		let flags = [];

		if (reasonsFlags) {
			if (reasonsFlags.free_wifi) {
				flags.push(
					<li key={hotel.id + '-free_wifi'} className="flag inline wifi">
						Free Wi-Fi
					</li>,
				);
			} else if (reasonsFlags.good_wifi) {
				flags.push(
					<li key={hotel.id + '-good_wifi'} className="flag inline wifi">
						Good Wi-Fi
					</li>,
				);
			}

			if (reasonsFlags.good_breakfast) {
				// goodFlagsReason.push(<li className="flag"><i className="fa fa-cutlery" aria-hidden="true" /> Good Breakfast</li>);
				flags.push(
					<li key={hotel.id + '-good_breakfast'} className="flag inline coffee">
						Good Breakfast
					</li>,
				);
			}

			if (reasonsFlags.good_service && !service_regex.test(_.get(hotel, 'ty_text_descriptions.summary'))) {
				// goodFlagsReason.push(<li className="flag"><i className="fa fa-hand-o-up" aria-hidden="true" /> Good Service</li>);
				// goodFlagsReason.push(<li className="flag"><i className="fa fa-smile-o" aria-hidden="true" /> Good Service</li>);
				flags.push(
					<li key={hotel.id + '-good_service'} className="flag inline user">
						Good Service
					</li>,
				);
			}
		}

		return flags;
	}

	getReasonsRender(hotel, maxReasonsOverride) {
		let reasons = [];

		for (let reason in hotel.reasons) {
			if (reason !== 'flags') {
				let reasonKey = 'reason-key-' + hotel.id + '-' + reason;
				let elm = (
					<li key={reasonKey} className={classNames(reason, 'flag', 'thumbs-up')}>
						{hotel.reasons[reason]}
					</li>
				);
				if (reason === 'negotiated_rate') {
					reasons.unshift(elm);
				} else {
					reasons.push(elm);
				}
			}
		}

		const max_reasons = maxReasonsOverride || getMaxReasons(hotel);
		const flags = this.getFlagsRender(hotel, hotel.reasons.flags);

		return reasons.slice(0, max_reasons).concat(flags);
	}

	getSummaryRender(hotel, maxReasonsOverride) {
		let summary = _.get(hotel, 'ty_text_descriptions.summary');
		let lines = [];
		if (summary) {
			let split_summary = summary
				.split('.')
				.map((sentence) => sentence.trim())
				.filter((sentence) => sentence && blacklist.every((x) => !sentence.includes(x)));
			// let multiple_sentences = [];
			// // for (const sentence of split_summary) {
			// for (let i = 0; i < split_summary.length; i++) {
			//     const sentence = split_summary[i];
			//     if (shouldAddNewLine(sentence)) {
			//         lines.push(<span style={{ display: 'block' }}>{`${multiple_sentences.join('. ')}${sentence}${i === (split_summary.length - 1) ? '.' : ''}`}</span>);
			//         // lines.push(<br />);
			//         multiple_sentences = [];
			//     } else {
			//         multiple_sentences.push(sentence);
			//     }
			// }
			// if (multiple_sentences.length > 0) {
			//     lines.push(<span style={{ display: 'block' }}>{`${multiple_sentences.join('. ')}`}</span>);
			// }
			let line = '';
			for (const sentence of split_summary) {
				if (shouldAddNewLine(sentence)) {
					lines.push(`${line ? `${line} ` : ''}${sentence}.`);
					line = '';
				} else {
					line += `${line ? ' ' : ''}${sentence}.`;
				}
			}
			if (line) {
				lines.push(`${line}`);
			}
		}

		const max_reasons = maxReasonsOverride || getMaxReasons(hotel);

		// return lines.map(line => <span style={{ display: 'block' }}>{line}</span>);
		return lines
			.map((line, idx) => (
				<li key={idx} className="flag thumbs-up">
					{line}
				</li>
			))
			.slice(0, max_reasons);
	}
}

export default new HotelService();
