import BookingsConstants from '../constants/BookingsConstants';
import ReservationConstants from '../constants/ReservationConstants';
import PointsConstants from '../constants/PointsConstants';

import moment from 'moment';

import AppDispatcher from '../dispatcher/AppDispatcher';
import { EventEmitter } from 'events';
import _ from 'lodash';

import ReservationEntity from '../entities/Reservation';
import ReservationRequestEntity from '../entities/ReservationRequest';

import DownloadFile from '../utils/DownloadFile';

import { CSV_EXPORT_TYPES, PAYMENT_TYPE } from 'arbitrip-common/general/constants';

// Define initial data points
let _data = [];
let _requests_data = [];

let _status = BookingsConstants.STATUS.INITIAL;
let _download_status = BookingsConstants.STATUS.INITIAL;
let _requests_status = BookingsConstants.REQUESTS_STATUS.INITIAL;
let _agent_notes_status = BookingsConstants.STATUS.INITIAL;

let _send_booking_confirmation_status = ReservationConstants.STATUS.INITIAL;
let _booking_request_approval_status = ReservationConstants.STATUS.INITIAL;
let _booking_request_decline_status = ReservationConstants.STATUS.INITIAL;

let _sync_travel_booster_status = BookingsConstants.STATUS.INITIAL;

let _count = 0;
let _requests_count = 0;

function getEnhancedReservation(booking) {
	let reservation = new ReservationEntity(booking);

	_.set(reservation, 'deal.net_price_total', reservation?.deal?.cheapopoPricing?.purchase_price);
	_.set(reservation, 'deal.cheapopoPricing.agentPricing.net_price_total', reservation?.deal?.net_price_total);

	_.set(reservation, 'deal.client_price_total', reservation?.deal?.cheapopoPricing?.cheapopoTotalPrice);
	_.set(reservation, 'deal.cheapopoPricing.agentPricing.client_price_total', reservation?.deal?.client_price_total);

	_.set(reservation, 'deal.nights', reservation?.deal?.details?.nights);

	_.set(reservation, 'deal.net_price_per_night', reservation?.deal?.net_price_total / reservation?.deal?.nights);
	_.set(reservation, 'deal.cheapopoPricing.agentPricing.net_price_per_night', reservation?.deal?.net_price_per_night);

	_.set(
		reservation,
		'deal.client_price_per_night',
		reservation?.deal?.cheapopoPricing?.pricePerNight ||
			reservation?.deal?.client_price_total / reservation?.deal?.nights,
	);
	_.set(
		reservation,
		'deal.cheapopoPricing.agentPricing.client_price_per_night',
		reservation?.deal?.client_price_per_night,
	);

	_.set(reservation, 'deal.agent_pricing', reservation?.deal?.cheapopoPricing?.agentPricing);

	return reservation;
}

function loadBookingsData(data) {
	_data = [];

	if (Array.isArray(data?.bookings)) {
		_data = data.bookings.map(getEnhancedReservation);
	}

	if (data.count || data.count === 0) {
		_count = data.count;
	}
}

function loadRequestsData(data) {
	_requests_data = [];
	const requests = data.requests;

	for (let i = 0; i < requests.length; i++) {
		_requests_data.push(new ReservationRequestEntity(requests[i]));
	}

	_requests_data.sort(function (a, b) {
		const a_pending = a.request_ticket.status === ReservationConstants.REQUEST_STATUS.PENDING;
		const b_pending = b.request_ticket.status === ReservationConstants.REQUEST_STATUS.PENDING;
		if (a_pending && !b_pending) {
			return -1;
		} else if (!a_pending && b_pending) {
			return 1;
		} else if (a_pending && b_pending) {
			return a.request_ticket.expiration_date - b.request_ticket.expiration_date;
		} else {
			// both not pending...
			return b.request_ticket.changed_date - a.request_ticket.changed_date;
		}
	});

	if (data.count || data.count === 0) {
		_requests_count = data.count;
	}
}

const BookingsStore = _.extend({}, EventEmitter.prototype, {
	getAgentNotesStatus: function () {
		return _agent_notes_status;
	},

	getSendBookingConfirmationStatus: function () {
		return _send_booking_confirmation_status;
	},

	getBookingRequestApprovalStatus: function () {
		return _booking_request_approval_status;
	},

	getBookingRequestDeclineStatus: function () {
		return _booking_request_decline_status;
	},

	getItems: function () {
		return _data;
	},

	getCount: function () {
		return _count;
	},

	getRequestItems: function () {
		return _requests_data;
	},

	getRequestsCount: function () {
		return _requests_count;
	},

	//////////////

	getStatus: function () {
		return _status;
	},

	getDownloadStatus: function () {
		return _download_status;
	},

	getRequestsStatus: function () {
		return _requests_status;
	},

	getSyncTravelBoosterStatus: function () {
		return _sync_travel_booster_status;
	},

	getTravelBoosterDocketNumberByReservationId: function (reservationId) {
		let reservation = _data.find((rsrv) => rsrv.id === reservationId);
		if (reservation) {
			return reservation.travel_booster_docket_number;
		}
		return '';
	},

	// TODO: method for currency conversion

	emitChange: function () {
		this.emit('change');
	},

	addChangeListener: function (callback) {
		this.on('change', callback);
	},

	removeChangeListener: function (callback) {
		this.removeListener('change', callback);
	},
});

function updateBooking(bookingId, callback) {
	for (const booking of _data) {
		if (booking && booking.id === bookingId) {
			return callback(booking);
		}
	}
}

AppDispatcher.register(function (payload) {
	let action = payload.action;

	switch (action.actionType) {
		case ReservationConstants.CANCEL_RESERVATION:
			updateBooking(action.data.id, (booking) => {
				booking.originalStatus = booking.status;
				booking.status = BookingsConstants.STATUS.CANCELING;
			});
			break;

		case ReservationConstants.RESERVATION_CANCELLATION_SUCCEEDED:
			updateBooking(action.data.id, (booking) => {
				if (_.isFunction(booking.setCanceled)) {
					booking.setCanceled();
				}
			});
			break;

		case ReservationConstants.RESERVATION_CANCELLATION_FAILED:
			updateBooking(action.data.reservation.id, (booking) => {
				booking.cancelStatus = 'failed';
				booking.status = booking.originalStatus;
			});
			console.error('Reservation cancellation failed', action.data.reservation, action.data.error);
			break;

		case ReservationConstants.RESET_RESERVATION_CANCELLATION_STATUS:
			updateBooking(action.data, (booking) => {
				booking.cancelStatus = null;
			});
			break;

		case BookingsConstants.UPDATE_PAYMENT_LINK:
			updateBooking(action.data.booking_id, (booking) => {
				_.set(booking, 'payment.payment_link', action.data.payment_link);
			});
			break;

		case BookingsConstants.RETRIEVE_BOOKINGS:
			_status = BookingsConstants.STATUS.BUSY;
			break;

		case BookingsConstants.BOOKINGS_TRANSITION:
			_status = BookingsConstants.STATUS.TRANSITION;
			break;

		// Respond to RECEIVE_DATA action
		case BookingsConstants.BOOKINGS_RETRIEVAL_SUCCEEDED:
			loadBookingsData(action.data.response, action.data.has_limit, action.data.skip === 0);
			_status = BookingsConstants.STATUS.SUCCESS;
			break;

		case BookingsConstants.BOOKINGS_RETRIEVAL_FAILED:
			// TODO: handle...
			_status = BookingsConstants.STATUS.FAILED;
			break;

		case BookingsConstants.DOWNLOADED_BOOKINGS_CSV:
			_download_status = BookingsConstants.STATUS.BUSY;
			break;

		// Respond to RECEIVE_DATA action
		case BookingsConstants.DOWNLOADED_BOOKINGS_CSV_SUCCEEDED:
			console.log('DOWNLOADED_BOOKINGS_CSV_SUCCEEDED');
			const file_name =
				action.data.export_type === CSV_EXPORT_TYPES.PRIORITY
					? `Arbitrip ${action.data.active_tab} Reservations (Priority) - ${moment().format('MMMM D')}.csv`
					: `Arbitrip ${action.data.active_tab} Reservations - ${moment().format('MMMM D')}.csv`;
			DownloadFile.download(action.data.response, file_name, 'text/csv;charset=utf-8;');
			_download_status = BookingsConstants.STATUS.SUCCESS;
			break;

		case BookingsConstants.DOWNLOADED_BOOKINGS_CSV_FAILED:
			console.log('DOWNLOADED_BOOKINGS_CSV_FAILED');

			// TODO: handle...
			_download_status = BookingsConstants.STATUS.FAILED;
			break;

		////////////////////////////////////////////////////////////////////////////////

		case BookingsConstants.RETRIEVE_REQUESTS:
			_requests_status = BookingsConstants.REQUESTS_STATUS.BUSY;
			break;

		case BookingsConstants.REQUESTS_RETRIEVAL_SUCCEEDED:
			loadRequestsData(action.data);
			_requests_status = BookingsConstants.REQUESTS_STATUS.SUCCESS;
			break;

		case BookingsConstants.REQUESTS_RETRIEVAL_FAILED:
			// TODO: handle...
			_requests_status = BookingsConstants.REQUESTS_STATUS.FAILED;
			break;

		////////////////////////////////////////////////////////////////////////////////

		case ReservationConstants.APPROVE_REQUEST:
			console.log('reservation store - approve request');
			_booking_request_approval_status = ReservationConstants.STATUS.BUSY;
			updateBooking(action.data.id, (booking) => {
				booking.status = BookingsConstants.REQUESTS_STATUS.APPROVING;
			});
			break;

		case ReservationConstants.REQUEST_APPROVAL_SUCCEEDED:
			console.log('reservation store - request approval succeeded');
			_booking_request_approval_status = ReservationConstants.STATUS.SUCCESS;
			updateBooking(action.data.id, (booking) => {
				booking.status = BookingsConstants.REQUESTS_STATUS.APPROVED;
				booking.approveStatus = 'succeeded';
			});
			break;

		case ReservationConstants.REQUEST_APPROVAL_FAILED:
			_booking_request_approval_status = ReservationConstants.STATUS.FAILED;
			updateBooking(action.data.id, (booking) => {
				booking.status = BookingsConstants.REQUESTS_STATUS.FAILED;
				booking.approveStatus = 'failed';
			});
			console.error('Reservation approval failed', action.data.reservation, action.data.error);
			break;

		case ReservationConstants.REJECT_REQUEST:
			console.log('reservation store - reject request');
			_booking_request_decline_status = ReservationConstants.STATUS.BUSY;
			updateBooking(action.data.id, (booking) => {
				booking.status = BookingsConstants.REQUESTS_STATUS.REJECTING;
			});
			break;

		case ReservationConstants.REQUEST_REJECTION_SUCCEEDED:
			console.log('reservation store - request rejection succeeded');
			_booking_request_decline_status = ReservationConstants.STATUS.SUCCESS;
			updateBooking(action.data.id, (booking) => {
				booking.status = BookingsConstants.REQUESTS_STATUS.REJECTED;
				booking.rejectStatus = 'succeeded';
			});
			break;

		case ReservationConstants.REQUEST_REJECTION_FAILED:
			_booking_request_decline_status = ReservationConstants.STATUS.FAILED;
			updateBooking(action.data.id, (booking) => {
				booking.status = BookingsConstants.REQUESTS_STATUS.FAILED;
				booking.rejectStatus = 'failed';
			});
			console.error('Reservation rejection failed', action.data.reservation, action.data.error);
			break;

		case BookingsConstants.UPDATE_PAYMENT_TYPE_TO_CREDIT_CARD:
			updateBooking(action?.data?.id, (booking) => {
				if (booking) {
					_.set(booking, 'payment.payment_type', PAYMENT_TYPE.CREDIT_CARD);
				}
			});
			break;

		////////////////////////////////////////////////////////////////////////////////

		case ReservationConstants.SEND_BOOKING_CONFIRMATION:
			_send_booking_confirmation_status = ReservationConstants.STATUS.BUSY;
			break;

		case ReservationConstants.SEND_BOOKING_CONFIRMATION_SUCCESS:
			_send_booking_confirmation_status = ReservationConstants.STATUS.SUCCESS;
			break;

		case ReservationConstants.SEND_BOOKING_CONFIRMATION_FAILED:
			_send_booking_confirmation_status = ReservationConstants.STATUS.FAILED;
			break;

		////////////////////////////////////////////////////////////////////////////////

		case BookingsConstants.SYNC_TRAVEL_BOOSTER:
			_sync_travel_booster_status = BookingsConstants.STATUS.BUSY;
			break;

		case BookingsConstants.SYNC_TRAVEL_BOOSTER_SUCCEEDED:
			const {
				reservation_id,
				// travel_booster_docket_number,
				response,
			} = action.data;

			const travel_booster_docket_number = _.get(response, 'docket_number');

			let reservation = _data.find((rsrv) => rsrv.id === reservation_id);
			if (reservation) {
				reservation.travel_booster_docket_number = travel_booster_docket_number;
			}

			_sync_travel_booster_status = BookingsConstants.STATUS.SUCCESS;
			break;

		case BookingsConstants.SYNC_TRAVEL_BOOSTER_FAILED:
			_sync_travel_booster_status = BookingsConstants.STATUS.FAILED;
			break;

		////////////////////////////////////////////////////////////////////////////////

		case BookingsConstants.UPDATE_AGENT_NOTES:
			_agent_notes_status = BookingsConstants.STATUS.BUSY;
			break;

		case BookingsConstants.UPDATE_AGENT_NOTES_SUCCEEDED:
			_agent_notes_status = BookingsConstants.STATUS.SUCCESS;
			for (const reservation of _data) {
				if (reservation.id === action.data.reservation_id) {
					reservation.agent_notes = {
						// TODO: agent
						timestamp: new Date(),
						note: action.data.notes,
					};
					reservation.agent_note = action.data.notes;
				}
			}

			break;

		case BookingsConstants.UPDATE_AGENT_NOTES_FAILED:
			_agent_notes_status = BookingsConstants.STATUS.FAILED;
			break;

		////////////////////////////////////////////////////////////////////////////////

		case PointsConstants.UPDATE_POINTS_USAGE_SUCCESS:
			updateBooking(action.data?.reservation?._id, (booking) => {
				if (booking) {
					_.set(booking, 'payment.loyalty.points', action.data?.payment?.loyalty?.points);
					_.set(
						booking,
						'payment.loyalty.points_in_currency',
						action.data?.payment?.loyalty?.points_in_currency,
					);
				}
			});
			break;

		case BookingsConstants.UPDATE_AGENT_PRICING_ON_BOOKING:
			const { reservation_id: booking_id, agent_pricing } = action.data;
			if (booking_id && !_.isEmpty(agent_pricing)) {
				updateBooking(action?.data?.reservation_id, (booking) => {
					if (booking) {
						_.set(booking, 'deal.agent_pricing', agent_pricing);
						_.set(booking, 'deal.cheapopoPricing.agentPricing', agent_pricing);

						_.set(booking, 'deal.cheapopoPricing.commissionTotal', agent_pricing?.absolute_margin);
						_.set(booking, 'deal.cheapopoPricing.cheapopoTotalPrice', agent_pricing?.client_price_total);
						_.set(booking, 'deal.cheapopoPricing.pricePerNight', agent_pricing?.client_price_per_night);
					}
				});
			} else {
				console.warn(`Could not ${BookingsConstants.UPDATE_AGENT_PRICING}`);
			}
			break;

		default:
			return true;
	}

	// If action was responded to, emit change event
	BookingsStore.emitChange();

	return true;
});

export default BookingsStore;
