import { Tooltip } from '@mui/material';
import FiltersConstants from '../../../../constants/FiltersConstants';

const { AMENITIES, AMENITY_FILTERS_TITLES } = FiltersConstants;

const amenitiesList = {
	[AMENITIES.BREAKFAST_INCLUDED]: {
		src: '/img/amenities/breakfast.svg',
		description: AMENITY_FILTERS_TITLES[AMENITIES.BREAKFAST_INCLUDED],
	},
	[AMENITIES.FREE_WIFI]: {
		src: '/img/amenities/wifi.svg',
		description: AMENITY_FILTERS_TITLES[AMENITIES.FREE_WIFI],
	},
	[AMENITIES.PARKING]: {
		src: '/img/amenities/parking.svg',
		description: AMENITY_FILTERS_TITLES[AMENITIES.PARKING],
	},
	[AMENITIES.FITNESS_CENTRE]: {
		src: '/img/amenities/fitness.svg',
		description: AMENITY_FILTERS_TITLES[AMENITIES.FITNESS_CENTRE],
	},
	[AMENITIES.AIRPORT_SHUTTLE]: {
		src: '/img/amenities/shuttle.svg',
		description: AMENITY_FILTERS_TITLES[AMENITIES.AIRPORT_SHUTTLE],
	},
	[AMENITIES.RESTAURANT]: {
		src: '/img/amenities/restaurant.svg',
		description: AMENITY_FILTERS_TITLES[AMENITIES.RESTAURANT],
	},
	[AMENITIES.MEETING_ROOM]: {
		src: '/img/amenities/meeting_room.svg',
		description: AMENITY_FILTERS_TITLES[AMENITIES.MEETING_ROOM],
	},
	[AMENITIES.SWIMMING_POOL]: {
		src: 'img/amenities/swimming_pool.svg',
		description: AMENITY_FILTERS_TITLES[AMENITIES.SWIMMING_POOL],
	},
	[AMENITIES.AIR_CONDITIONING]: {
		src: 'img/amenities/air_conditioning.svg',
		description: AMENITY_FILTERS_TITLES[AMENITIES.AIR_CONDITIONING],
	},
	[AMENITIES.ELECTRIC_VEHICLE_CHARGING_STATION]: {
		src: 'img/amenities/charging_station.svg',
		description: AMENITY_FILTERS_TITLES[AMENITIES.ELECTRIC_VEHICLE_CHARGING_STATION],
	},
};

const HotelAmenitiesComponent = ({ hotel, amenitiesData, breakfastOnly }) => {
	const shouldDisplayBreakfast = amenitiesData[AMENITIES.BREAKFAST_INCLUDED] && breakfastOnly;
	let displayedAmenitiesCount = 0;

	const renderIcon = (iconName) => {
		const { amenities_flags } = hotel;
		let amenityAvailableInHotel = amenitiesData[iconName] && amenities_flags[iconName];

		if (iconName === AMENITIES.BREAKFAST_INCLUDED && shouldDisplayBreakfast) {
			amenityAvailableInHotel = true;
		}

		if (amenityAvailableInHotel) {
			displayedAmenitiesCount++;

			return (
				<Tooltip
					title={amenitiesList[iconName].description}
					key={iconName}
					placement={displayedAmenitiesCount === 1 ? 'top-start' : 'top'}
					arrow
				>
					<div className="amenity">
						<img className="icon" src={amenitiesList[iconName].src} alt={iconName} />
					</div>
				</Tooltip>
			);
		}

		return null;
	};

	return (
		<div className="amenities-container">{Object.keys(amenitiesList).map((iconName) => renderIcon(iconName))}</div>
	);
};

export default HotelAmenitiesComponent;
