import _ from 'lodash';
import moment from 'moment';

import FirebaseChangeTypes from './Consts';

function onSnapshotWrapper(onData, field_name, prefix_message, subscription_timestamp) {
    return (snapshot) => {
        const subcollections = [];
        // snapshot.forEach((doc) => subcollections.push(doc.data()));
        const changes = snapshot.docChanges();
        changes.forEach((change) => {
            // console.log(change.type);
            switch (change.type) {
                case FirebaseChangeTypes.ADDED:
                case FirebaseChangeTypes.MODIFIED:
                    subcollections.push(change.doc.data());
                    break;
                case FirebaseChangeTypes.REMOVED:
                default:
                    // TODO:
                    break;
            }
        });

        // const data = _.flatMap(subcollections, (data) => _.get(data, field_name)); // original json mode
        // const data = _.flatMap(subcollections, (data) => _.get(JSON.parse(data), field_name)); // suggested stringified mode
        const data = _.flatMap(subcollections, (data) => _.get(_.isString(data) ? JSON.parse(data) : data, field_name)); // hybrid mode to support both from server
        // if (data.length > 0) {
        //     const duration = moment.duration(moment().diff(subscription_timestamp)).asMilliseconds() / 1000;
        //     console.log(`$$$ ${prefix_message} - time since subscription: ${duration}s`, data);
        // }
        return onData(data);
    };
}

export default onSnapshotWrapper;