const { isSpecificPlace } = require('arbitrip-common/client/utils/PlacesUtils');

const moment = require('moment');
const _ = require('lodash');
const Deal = require('./Deal');
const DealUtils = require('./DealUtils');
const Config = require('../utils').Config;
const FiltersConstants = require('../constants').FiltersConstants;
const { MAX_REASONS } = require('../constants').HotelConstants;
const HotelUtils = require('./HotelUtils');
const HotelConstants = require('../constants/HotelConstants');
const { DealConstants } = require("../constants");
const WebStorageManager = require('../utils').WebStorageManager;

const CHEAPEST_SUPPLIER_FACTORS = {
    'HB': 200 / 3,
    'GG': 100 / 3
};

const PERCENT = 0.01;

const stars_breakfast_values = {
    '5': 30,
    '4.5': 26.67,
    '4': 25,
    '3.5': 18.33,
    '3': 15,
    '2.5': 11.67,
    '2': 10
};

function __TEMP__percentify(num) {
    return Number((num * 100).toFixed(2));
}

const KM_IN_MILES = 0.6213727366;

function setRepresentativeDeal(hotel, deal) {
    hotel.representative_deals['cheapest'] = hotel.representative_deals['cheapest'] || deal;

    if (hotel.representativeDeal.id === deal.id) {
        hotel.representative_deals['cheapest'] = deal;
    }

    if (deal.breakfast) {
        hotel.representative_deals['breakfast'] = hotel.representative_deals['breakfast'] || deal;

        if (hotel.representativeBreakfastDeal.id === deal.id) {
            hotel.representative_deals['breakfast'] = deal;
        }
    }

    const cheapest_comparison = DealUtils.compareByPrice(hotel.representative_deals['cheapest'], deal);
    if (deal.breakfast) {
        if (cheapest_comparison >= 0) {
            hotel.representative_deals['cheapest'] = deal;
            hotel.representative_deals['breakfast'] = deal;
        } else if (DealUtils.compareByPrice(hotel.representative_deals['breakfast'], deal) > 0) {
            hotel.representative_deals['breakfast'] = deal;
        }
    } else if (cheapest_comparison > 0) {
        hotel.representative_deals['cheapest'] = deal;
    }

    if (deal.negotiated_rate) {
        hotel.markAsPreferredHotelWithAReason();
    }
}

function isDeal1BetterThanDeal2(deal1, deal2) {
    // Is there any dimension where deal1 is better than deal2?
    if (deal1.totalPrice * 1.01 < deal2.totalPrice) {
        return true;
    }

    if (deal1.board_bases.all_inclusive && !deal2.board_bases.all_inclusive) {
        return true;
    }

    if (deal1.board_bases.full_board && !deal2.board_bases.full_board) {
        return true;
    }

    if (deal1.board_bases.half_board && !deal2.board_bases.half_board) {
        return true;
    }

    if (deal1.breakfast && !deal2.breakfast) {
        return true;
    }

    if (!deal1.chooseprod.dca.nonRefundable && deal2.chooseprod.dca.nonRefundable) {
        return true;
    }

    const deal1RefundableTill = moment(deal1.chooseprod.dca.dealIsFullyRefundableTill);
    const deal2RefundableTill = moment(deal2.chooseprod.dca.dealIsFullyRefundableTill);

    if (deal1RefundableTill.isAfter(deal2RefundableTill) &&
        deal1RefundableTill.startOf('day').diff(deal2RefundableTill.startOf('day'), 'days') >= 1) {
        return true;
    }

    if (deal1.id.startsWith('E:') && deal2.id.startsWith('E:') &&
        deal1.bed_type && deal2.bed_type &&
        deal1.bed_type !== deal2.bed_type) {
        return true;
    }

    if (deal1.id.startsWith('E:') && deal2.id.startsWith('E:') &&
        deal1.totalPrice === deal2.totalPrice &&
        !deal1.package && deal2.package) {
        return true;
    }

    // show better deal by cancellation terms if the price difference <= 1%
    if ((deal1.chooseprod.dca.nonRefundable && !deal2.chooseprod.dca.nonRefundable ||
        deal2RefundableTill.isAfter(deal1RefundableTill)) &&
        deal1.totalPrice <= deal2.totalPrice * 1.01) {
        return false;
    }

    if (deal1.totalPrice < deal2.totalPrice) {
        return true;
    }

    return false;
}

function compareSuperseded(deal1, deal2) {
    // if deal2 is better than deal1 return positive
    // if deal1 is better thant deal 2 return negative
    // otherwise (non comparable) return 0
    if (deal1.room_group !== deal2.room_group) {
        return 0;
    }

    const isDeal1Better = isDeal1BetterThanDeal2(deal1, deal2);
    const isDeal2Better = isDeal1BetterThanDeal2(deal2, deal1);

    if (isDeal1Better && isDeal2Better) {
        return 0; // both deals should appear
    }

    if (isDeal1Better && !isDeal2Better) {
        return -1; // should show deal1
    }

    if (isDeal2Better && !isDeal1Better) {
        return 1; // should show deal2
    }

    // in case of both deals are equal use id as arbiter. Disregard the supplier reference
    return deal1.id.substring(2) < deal2.id.substring(2) ? 1 : -1;
}

const MAXIMUM_SCORE_MARGIN_PERCENTAGE_VALUE = 30;
const MAXIMUM_SCORE_MARGIN_PERCENTAGE_MULTIPLIER = 100 / MAXIMUM_SCORE_MARGIN_PERCENTAGE_VALUE;

// eslint-disable-next-line no-unused-vars
function getLeisureDiffPer(hotel, priceOptions) {
    // const virtual_margin = _.get(hotel, 'virtual_cheapest_margin_percentage', 0);

    // if (virtual_margin > 0) {
    //     return 100 * ((virtual_margin - (priceOptions.lowest_virtual_mergin || 0)) / (priceOptions.virtual_margin_spread || 1));
    // }

    // return 0;
    // const virtual_margin_absolute = _.get(hotel, 'virtual_cheapest_margin_absolute', 0);
    // const virtual_margin_percentage = _.get(hotel, 'virtual_cheapest_margin_percentage', 0);

    // return (virtual_margin_absolute + virtual_margin_percentage) / 2;
    // return 2 * _.get(hotel, 'virtual_cheapest_margin_percentage', 0);
    return Math.min(_.get(hotel, 'virtual_cheapest_margin_percentage', 0), MAXIMUM_SCORE_MARGIN_PERCENTAGE_VALUE) * MAXIMUM_SCORE_MARGIN_PERCENTAGE_MULTIPLIER;
}

const MAXIMUM_SCORE_MARGIN_ABSOLUTE_VALUE = 30;
const MAXIMUM_SCORE_MARGIN_ABSOLUTE_MULTIPLIER = 100 / MAXIMUM_SCORE_MARGIN_ABSOLUTE_VALUE;

// eslint-disable-next-line no-unused-vars
function getLeisureDiffAbs(hotel, priceOptions) {
    return Math.min(_.get(hotel, 'virtual_cheapest_margin_absolute', 0), MAXIMUM_SCORE_MARGIN_ABSOLUTE_VALUE) * MAXIMUM_SCORE_MARGIN_ABSOLUTE_MULTIPLIER;
}

function getLeisureCheapestSupplierFactor(hotel) {
    const supplier = _.get(hotel, 'cheapest_primal.id', '').split(':')[0];

    return CHEAPEST_SUPPLIER_FACTORS[supplier] || 0;
}

function getLeisureStars(hotel) {
    const stars = _.get(hotel, 'stars', 0);

    return (stars >= 3)
        ? stars * 20
        : 0;
}

const METERS_IN_KM = 1000;

const max_specific_distance_in_meters = 1200;
const max_specific_distance_multiplier = 100 / max_specific_distance_in_meters;

const max_alternative_distance_in_meters = 5000;
const max_alternative_distance_multiplier = 100 / max_alternative_distance_in_meters;

function calculateDistanceScore(distanceInMeters, maxDistanceInMeters, maxDistanceMultiplier, locationScore) {
    const distance_in_meters = _.isNumber(distanceInMeters)
        ? distanceInMeters
        : maxDistanceInMeters;

    const distance_score = maxDistanceMultiplier * (maxDistanceInMeters - distance_in_meters);

    return percentify(distance_score * ((locationScore > 0) ? locationScore : Math.abs(distance_score)));
}

function getLeisureSpecificDistance(hotel) {
    const distance_in_km = _.get(hotel, 'recommendationEngine.additionalDescriptions.geoDistanceInKm');
    const distance_in_meters = distance_in_km * METERS_IN_KM;

    return calculateDistanceScore(distance_in_meters, max_specific_distance_in_meters, max_specific_distance_multiplier);
}

function getLeisureAlternativeDistance(hotel, locationScore) {
    const alternative_distance = _.get(hotel, 'alternative_distance.distance');

    return calculateDistanceScore(alternative_distance, max_alternative_distance_in_meters, max_alternative_distance_multiplier, locationScore);
}

function getLeisureDistanceScore(hotel, leisureScoreLocation, recommendationSpecific) {
    return recommendationSpecific
        ? getLeisureSpecificDistance(hotel)
        : getLeisureAlternativeDistance(hotel, leisureScoreLocation);
}

function getLeisurePrice(hotel, priceOptions = {}) {
    const price = _.get(hotel, 'representative_deals.cheapest.pricePerNight', 0);

    if (price > 0) {
        // return 100 * (1 - Math.min(1, Math.abs(price - priceOptions.median_price) / priceOptions.min_median_spread));
        const price_median_ratio = price / priceOptions.median_price;
        return (price_median_ratio >= 2)
            ? 100 - (100 * price_median_ratio)
            : 100;
    }

    return 0;
}

function getLeisureCheapest(hotel, priceOptions = {}) {
    const price = _.get(hotel, 'representative_deals.cheapest.pricePerNight', 0);

    if (price > 0) {
        // return 100 - (price - priceOptions.lowest_price) / priceOptions.cheapest_average_spread;
        return 100 - (price - priceOptions.lowest_price);
    }

    return 0;
}

function percentify(score) {
    return PERCENT * score;
}

// returntries to find  closest more expensive deal
// if there's none, finds closest cheaper one
function chooseClosestDealByPrice(deals, pricePerNight = Number.MAX_SAFE_INTEGER) {
    if (Array.isArray(deals)) {
        deals.sort((a, b) => a.pricePerNight - b.pricePerNight);
        for (const deal of deals) {
            if (deal.pricePerNight >= pricePerNight) {
                return deal;
            }
        }

        return deals[deals.length - 1];
    }

    return null;
}

class Hotel {
    constructor(hotel, shallow, ignore_dealless) {
        if (!hotel) {
            return console.error('Cannot build hotel object, no payload provided');
        }
        this.scores = hotel.scores;
        this.id = hotel._id;

        if (typeof hotel.name === 'string') {
            this.name = hotel.name.toLowerCase();
        }
        this.stars = hotel.stars;
        HotelUtils.setGeoJSON(this, hotel.lon_lat);

        this.address = hotel.address;
        this.city = hotel.city;
        this.state = hotel.state;
        this.country = hotel.country;
        this.country_code = hotel.country_code;

        if (hotel.alternative_distance) {
            this.alternative_distance = hotel.alternative_distance;
            this.alternative_distance.distance_in_km = this.alternative_distance.distance / 1000;
        }

        if (hotel.neighborhood_match) {
            this.neighborhood_match = hotel.neighborhood_match;
        }

        if (hotel.recommendationEngine) {
            this.hotelMatch = !!hotel.recommendationEngine.hotelMatch;

            const destination = WebStorageManager.getFromWebStorage('_filtered_hotel');
            if (destination?.hotel_id) {
                this.hotelMatch = this.id === destination?.hotel_id;
            }

            let additionalDescriptions = hotel.recommendationEngine.additionalDescriptions;
            if (additionalDescriptions) {
                if (additionalDescriptions.geoDistanceInKm) {
                    this.distanceInKm = additionalDescriptions.geoDistanceInKm;
                } else if (additionalDescriptions.geoDistanceInMiles) {
                    this.distanceInKm = additionalDescriptions.geoDistanceInMiles * KM_IN_MILES;
                }

                this.distanceDescription = isNaN(this.distanceInKm)
                    ? ''
                    : `${Number(this.distanceInKm).toFixed(1)} km`;
            }

            this.reasons = {};
            if (hotel.recommendationEngine.reasons) {
                this.reasons = Object.assign({}, hotel.recommendationEngine.reasons);

                for (let p in hotel.recommendationEngine.reasons) {
                    if ((p === 'geo') && (typeof hotel.recommendationEngine.reasons[p] === 'string') && hotel.recommendationEngine.reasons[p]) {
                        this.walking_distance = true;
                    }
                }
            }

            let reasonsKeys = Object.keys(this.reasons);
            while (reasonsKeys.length > MAX_REASONS) {
                let randomKey = 2 + Math.floor(Math.random() * 3);
                if (reasonsKeys[randomKey] !== 'negotiated_rate') {
                    delete this.reasons[reasonsKeys[randomKey]];
                    reasonsKeys.splice(randomKey, 1);
                }
            }
        } else {
            this.distanceDescription = '';
            this.distanceInKm = '';
            this.reasons = {};
        }

        this.hasReview = false;
        HotelUtils.setReview(this, hotel.review);

        if (hotel.dcaAggregators && DealUtils.isValidDeal(hotel.dcaAggregators.representativeDeal)) {
            this.representativeDeal = new Deal(hotel.dcaAggregators.representativeDeal, 1);

            if (!hotel.deals) {
                hotel.deals = [];
            }

            if (!this._indexed_deals) {
                this._indexed_deals = {};
            }

            if (!this._payments_excluded_deals) {
                this._payments_excluded_deals = {};
            }

            let all_deals = DealUtils.createDeals(hotel.deals);
            this.deals = [];

            for (let deal of all_deals) {
                this._indexed_deals[deal.id] = deal;

                if (deal.payments_excluded && !deal.payments_excluded_raw) {
                    this._payments_excluded_deals[deal.id] = deal;
                }
            }
            this.deals = Object.values(this.indexed_deals);
        } else {
            this.representative_deals = {};
            this._indexed_deals = {};
            this._payments_excluded_deals = {};
        }

        // if (!Array.isArray(this.images) || (this.images.length === 0)) {
        HotelUtils.setImages(this, hotel.images);
        // }
        // if (!Array.isArray(this.rooms) || (this.rooms.length === 0)) {
        HotelUtils.setRooms(this, hotel.rooms);
        // }

        if (hotel.rooms_src) {
            this.rooms_src = hotel.rooms_src.charAt(0).toUpperCase();
        }

        this.original = hotel;

        this.ignore_dealless = ignore_dealless && (hotel.matching_group && hotel.matching_group.length > 1);

        if (Config.dev_mode) {
            this.debug = hotel;
            this.matched_supplier_flag = hotel.matching_group && (hotel.matching_group.length > 1);
        }

        // represents quality of wifi/breakfast/service (TY)
        this.flags = {};

        // represents existence of wifi/parking/airpot shuttle/gym (B)
        this.amenities_flags = {};

        this.category = hotel.category_mw || hotel.category;
        this.b_converted_category = hotel.category_booking_normalized || hotel.booking_converted_category;
        this.b_original_category = hotel.category_booking || hotel.booking_original_category;
        this.e_converted_category = hotel.category_expedia_normalized || hotel.expedia_converted_category;
        this.e_original_category = hotel.category_expedia || hotel.expedia_original_category;

        if (hotel.category_flags) {
            this.category_flags = {
                [FiltersConstants.CATEGORIES.HOTEL]: hotel.category_flags.hotel,
                [FiltersConstants.CATEGORIES.APARTMENT]: hotel.category_flags.apartment,
                [FiltersConstants.CATEGORIES.OTHER]: hotel.category_flags.other
            };
        }
        if (hotel.booking_category_flags) {
            this.b_category_flags = {
                hotel: hotel.booking_category_flags.hotel,
                apartment: hotel.booking_category_flags.apartment,
                apart_hotel: hotel.booking_category_flags.apart_hotel,
                hostel: hotel.booking_category_flags.hostel,
                guest_house: hotel.booking_category_flags.guest_house,
                other: hotel.booking_category_flags.other,
                na: hotel.booking_category_flags.na
            };
        }
        if (hotel.expedia_category_flags) {
            this.e_category_flags = {
                hotel: hotel.expedia_category_flags.hotel,
                apartment: hotel.expedia_category_flags.apartment,
                apart_hotel: hotel.expedia_category_flags.apart_hotel,
                hostel: hotel.expedia_category_flags.hostel,
                guest_house: hotel.expedia_category_flags.guest_house,
                other: hotel.expedia_category_flags.other,
                na: hotel.expedia_category_flags.na
            };
        }

        if (hotel.flags) {
            this.flags = {
                free_wifi: hotel.complimentary_wifi || hotel.flags.free_wifi,
                good_wifi: hotel.flags.good_wifi,
                good_breakfast: hotel.flags.good_breakfast,
                good_service: hotel.flags.good_service
            };

            this.amenities_flags = {
                // [FiltersConstants.AMENITIES.COMPLEMENTARY_BREAKFAST]: hotel.flags.complementary_breakfast,
                [FiltersConstants.AMENITIES.FREE_WIFI]: hotel.flags.free_wifi,
                // [FiltersConstants.AMENITIES.TWIN_BEDS]:hotel.flags.twin_beds,
                [FiltersConstants.AMENITIES.PARKING]: hotel.flags.parking,
                [FiltersConstants.AMENITIES.AIRPORT_SHUTTLE]: hotel.flags.airport_shuttle,
                [FiltersConstants.AMENITIES.FITNESS_CENTRE]: hotel.flags.fitness_centre,
                // [FiltersConstants.AMENITIES.FREE_CANCELLATION]: hotel.flags.free_cancellation,
                [FiltersConstants.AMENITIES.RESTAURANT]: hotel.flags.restaurant,
                [FiltersConstants.AMENITIES.MEETING_ROOM]: hotel.flags.meeting_room,
                [FiltersConstants.AMENITIES.SWIMMING_POOL]: hotel.flags.swimming_pool,
                [FiltersConstants.AMENITIES.AIR_CONDITIONING]: hotel.flags.air_conditioning,
                [FiltersConstants.AMENITIES.ELECTRIC_VEHICLE_CHARGING_STATION]: hotel.flags.electric_vehicle_charging_station
            };
        }

        this.also_known_as = hotel.also_known_as;

        this.shallow = Boolean(shallow);

        this.recommended_index = -1;

        this.addPriceReason = function () {
            this.reasons.price = 'Price is lower than similar hotel rooms at this area';
        };
        this.removePriceReason = function () {
            delete this.reasons.price;
        };

        this.markAsPreferredHotelWithAReason = function (companyName = 'Corporate') {
            this.preferred = true;
            this.reasons.negotiated_rate = companyName + ' Preferred Hotel';
        };

        this.recommendation_original_score = _.get(hotel, 'recommendationEngine.totalScore', Number.MIN_SAFE_INTEGER);
        this.recommendation_final_score = this.recommendation_original_score;
        // this.updateRecommendationFinalScore = function (bonusScore = 0) {
        this.updateRecommendationFinalScore = function (priceOptions = {}) {
            // this.recommendation_final_score = this.recommendation_original_score + bonusScore;

            ////////////////////////////////////////////////////////////////////////////////
            // New Search Algorithm

            const recommendation_profile = WebStorageManager.getFromWebStorage('_recommendation_profile');
            const recommendation_weights = WebStorageManager.getFromWebStorage('_recommendation_weights');
            const recommendation_place = WebStorageManager.getFromWebStorage('_recommendation_place');
            const filtered_hotel = WebStorageManager.getFromWebStorage('_filtered_hotel');
            const recommendation_specific = !!filtered_hotel ?? isSpecificPlace(recommendation_place);

            const {
                neighborhood_match: w0 = 100,

                leisure_diff_per: w1 = 0,
                leisure_diff_abs: w1b = 0,
                leisure_score_total: w2 = 0,
                leisure_cheapest_supplier_factor: w3 = 0,
                leisure_review_score: w4 = 0,
                leisure_stars: w5 = 0,

                leisure_score_location: ls_w1 = 0,
                leisure_score_amenities: ls_w2 = 0,
                leisure_score_vibe: ls_w3 = 0,
                leisure_score_popularity: ls_w4 = 0,

                leisure_distance: w6 = 0,
                // leisure_specific_distnace: w6a = 0,
                // leisure_alternative_distance: w6b = 0,
                leisure_price: w7 = 0,
                leisure_cheapest: w7b = 0
            } = recommendation_weights[recommendation_profile];

            this.recommendation = this.recommendation || {};

            this.recommendation.neighborhood_match = this.neighborhood_match
                ? 100
                : 0;

            this.recommendation.leisure_diff_abs = getLeisureDiffAbs(this, priceOptions);
            this.recommendation.leisure_diff_per = getLeisureDiffPer(this, priceOptions);

            // this.recommendation.leisure_score_total = _.get(hotel, 'recommendationEngine.leisureScore', 0);

            this.recommendation.leisure_score_location = _.get(hotel, 'recommendationEngine.leisureLocationScore', 0);
            this.recommendation.leisure_score_amenities = _.get(hotel, 'recommendationEngine.leisureAmenitiesScore', 0);
            this.recommendation.leisure_score_vibe = _.get(hotel, 'recommendationEngine.leisureVibeScore', 0);
            this.recommendation.leisure_score_popularity = _.get(hotel, 'recommendationEngine.leisurePopularityScore', 0);
            if (this.recommendation.leisure_score_popularity > 0) {
                this.recommendation.leisure_score_popularity = 100 - this.recommendation.leisure_score_popularity;
            } else {
                this.recommendation.leisure_score_popularity = _.get(hotel, 'recommendationEngine.reviewScore', 0);
            }

            this.recommendation.leisure_score_total = percentify(
                + (ls_w1 * this.recommendation.leisure_score_location)
                + (ls_w2 * this.recommendation.leisure_score_amenities)
                + (ls_w3 * this.recommendation.leisure_score_vibe)
                + (ls_w4 * this.recommendation.leisure_score_popularity)
            );

            this.recommendation.leisure_cheapest_supplier_factor = getLeisureCheapestSupplierFactor(this);
            this.recommendation.leisure_review_score = _.get(hotel, 'recommendationEngine.reviewScore', 0);

            this.recommendation.leisure_stars = getLeisureStars(this);

            // this.recommendation.leisure_distance = getLeisureDistance(hotel, this.recommendation.leisure_score_location); // hotel (!!) - not "this"
            // this.recommendation.leisure_specific_distance = getLeisureSpecificDistance(hotel); // hotel (!!) - not "this"
            // this.recommendation.leisure_alternative_distance = getLeisureAlternativeDistance(hotel, this.recommendation.leisure_score_location); // hotel (!!) - not "this"
            this.recommendation.leisure_distance_score = getLeisureDistanceScore(hotel, this.recommendation.leisure_score_location, recommendation_specific); // hotel (!!) - not "this"
            this.recommendation.leisure_price = getLeisurePrice(this, priceOptions);
            this.recommendation.leisure_cheapest = getLeisureCheapest(this, priceOptions);

            this.recommendation_final_score = percentify(
                + (w0 * this.recommendation.neighborhood_match)

                + (w1 * this.recommendation.leisure_diff_per)
                + (w1b * this.recommendation.leisure_diff_abs)

                + (w2 * this.recommendation.leisure_score_total)
                + (w3 * this.recommendation.leisure_cheapest_supplier_factor)
                + (w4 * this.recommendation.leisure_review_score)
                + (w5 * this.recommendation.leisure_stars)

                + ((w6 + (recommendation_specific ? 80 : 0)) * this.recommendation.leisure_distance_score)

                + (w7 * this.recommendation.leisure_price)
                + (w7b * this.recommendation.leisure_cheapest)
            );

            // overrides
            const price_score = percentify(
                + (w1 * this.recommendation.leisure_diff_per)
                + (w1b * this.recommendation.leisure_diff_abs)

                + (w3 * this.recommendation.leisure_cheapest_supplier_factor)

                + (w7 * this.recommendation.leisure_price)
                + (w7b * this.recommendation.leisure_cheapest)
            );
            _.set(this, 'scores.normal.price_score', price_score);
            const hotel_score = _.get(this, 'scores.normal.hotel_score', 0);
            const distance_score = _.get(this, 'scores.normal.distance_score', 0);
            _.set(this, 'scores.normal.total_score', hotel_score + price_score + distance_score);
            if (filtered_hotel?.hotel_id) {
                this.setHotelMatch(filtered_hotel?.hotel_id);
            }
            ////////////////////////////////////////////////////////////////////////////////
        };

        this.setShallow = function (shallow) {
            this.shallow = shallow;
        };

        if (!Array.isArray(this.deals)) {
            this.deals = [];
        }

        this.recheck_status = HotelConstants.RECHECK_STATUS.INITIAL;

        ////////////////////////////////////////////////////////////////////////////////

        this.ty_text_descriptions = hotel.ty_text_descriptions;
        this.text_descriptions = hotel.text_descriptions;
        this.fees = hotel.fees;
        this.taxes_to_be_paid_at_hotel_may_apply = hotel.taxes_to_be_paid_at_hotel_may_apply;
        this.neighborhoods = hotel.neighborhoods;

        this.getPaymentsExcludedDeal = function (deal = {}) {
            const payments_excluded_deals = Object.values(this._payments_excluded_deals);

            return chooseClosestDealByPrice(payments_excluded_deals, deal.pricePerNight);
        };
    }

    isInfoStatusSuccess() {
        return this.info_status === HotelConstants.INFO_STATUS.SUCCESS;
    }

    isInfoStatusBusy() {
        return this.info_status === HotelConstants.INFO_STATUS.BUSY;
    }

    isRecheckStatusBusy() {
        return this.recheck_status === HotelConstants.RECHECK_STATUS.BUSY;
    }

    isRecheckStatusInitial() {
        return this.recheck_status === HotelConstants.RECHECK_STATUS.INITIAL;
    }

    get primalDeals() {
        return Array.isArray(this.primal_deals)
            ? this.primal_deals
            : [];
    }

    get representativeDeal() {
        return this.representative_deals['cheapest'];
    }

    set representativeDeal(data) {
       this.representative_deals = data ?? {};
    }

    getRepresentativeDeal(breakfast) {
        return breakfast
            ? this.representative_deals['breakfast']
            : this.representative_deals['cheapest'];
    }

    get representativeBreakfastDeal() {
        return this.representative_deals['breakfast'];
    }

    set representativeDefinition(definition) {
        if (!definition) {
            definition = {};
        }

        Object.assign(this.representative_definition, definition);

        setRepresentativeDeal(this);
    }

    hasBreakfastDeal() {
        if (this.representativeBreakfastDeal) {
            return true;
            // TODO: what is N/A?
        }

        return this.deals.some((deal) => deal.breakfast);
    }

    // TODO: optimize
    __TEMP__calculateMargins() {
        if (this.primal_deals) {
            // this.primal_deals.sort((a, b) => a.pricePerNight - b.pricePerNight);
            this.primal_deals.sort((a, b) => a.virtual_price - b.virtual_price);
            const cheapest_primal_expedia = this.primal_deals.find(d => d.id.startsWith('E:'));

            if (cheapest_primal_expedia) {
                this.cheapest_primal = this.primal_deals[0];

                this.cheapest_margin_percentage = __TEMP__percentify(1 - (this.cheapest_primal.pricePerNight / cheapest_primal_expedia.pricePerNight));
                this.cheapest_margin_absolute = cheapest_primal_expedia.pricePerNight - this.cheapest_primal.pricePerNight;
                this.virtual_cheapest_margin_percentage = __TEMP__percentify(1 - (this.cheapest_primal.virtual_price / cheapest_primal_expedia.virtual_price));
                this.virtual_cheapest_margin_absolute = cheapest_primal_expedia.virtual_price - this.cheapest_primal.virtual_price;
            }
        }
    }

    /*
        all deals
        every deal get a new price (incl. breakfast discount price)
    */

    // 5 $30
    // 4 $25
    // 3 $15

    // TODO: optimize
    __TEMP__updateMarginsByPrimalDeal(deal) {
        this.__TEMP__setVirtualPrice(deal);

        if (deal.id.startsWith('E:')) {
            if (this.primal_benchmark) {
                // if (deal.pricePerNight < this.primal_benchmark.pricePerNight) {
                if (deal.virtual_price < this.primal_benchmark.virtual_price) {
                    this.primal_benchmark = deal;
                    this.__TEMP__calculateMargins();
                }
            } else {
                this.primal_benchmark = deal;
            }
        } else if (this.primal_benchmark) {
            const margin_percentage = __TEMP__percentify(1 - (deal.pricePerNight / this.primal_benchmark.pricePerNight));
            const virtual_margin_percentage = __TEMP__percentify(1 - (deal.virtual_price / this.primal_benchmark.virtual_price));
            this.cheapest_margin_percentage = Math.max(this.cheapest_margin_percentage || 0, margin_percentage);
            this.virtual_cheapest_margin_percentage = Math.max(this.virtual_cheapest_margin_percentage || 0, virtual_margin_percentage);

            const margin_absolute = this.primal_benchmark.pricePerNight - deal.pricePerNight;
            const virtual_margin_absolute = this.primal_benchmark.virtual_price - deal.virtual_price;
            this.cheapest_margin_absolute = Math.max(this.cheapest_margin_absolute || 0, margin_absolute);
            this.virtual_cheapest_margin_absolute = Math.max(this.virtual_cheapest_margin_absolute || 0, virtual_margin_absolute);
        }
    }

    __TEMP__savePrimalDeal(deal) {
        if (!this.primal_deals) {
            this.primal_deals = [];
        }

        this.primal_deals.push(deal);
        // this.__TEMP__calculateMargins();
        this.__TEMP__updateMarginsByPrimalDeal(deal);
    }

    __TEMP__getBreakfastValue(deal) {
        return deal.breakfast
            ? stars_breakfast_values[this.stars] || 5
            : 0;
    }

    __TEMP__setVirtualPrice(deal) {
        deal.virtual_price = _.get(deal, 'pricePerNight');
        if (deal.breakfast) {
            deal.virtual_price -= stars_breakfast_values[this.stars] || 5;
        }
    }

    addPrimalDeal(rawDeal) {
        const deal = new Deal(rawDeal);
        // console.log(`addPrimalDeal | hotelId = ${this.id} | isRecheck = ${DealUtils.isValidRecheck(deal)}`);
        setRepresentativeDeal(this, deal);

        // todo:
        /*
        - save primal deals (aggregate)
        - wait for firebase to unsubscribe
        - compare cheapest non-expedia to cheapest expedia
        - bookmark ratio / filter / sort (starting threshold = 40%)
        - (*) 
        */
        this.__TEMP__savePrimalDeal(deal);

        if (Object.keys(this._payments_excluded_deals).length === 0 && deal.payments_excluded && !deal.payments_excluded_raw) {
            // Add payments_excluded deal
            // only one deal needed, because the taxes are the same
            this._payments_excluded_deals[deal.id] = deal;
        }
    }

    addPrimalDeals(deals) {
        if (Array.isArray(deals)) {
            deals.forEach(this.addPrimalDeal.bind(this));
        }
    }

    markRoomMappingSupersededDeals(search_session) {
        let enable_room_mapping = _.get(search_session, 'room_mapping.settings.enable_room_mapping', false);
        if (enable_room_mapping) {
            // room mapping superseded
            let room_mapping = _.get(search_session, 'room_mapping', {});

            let mapping_data = room_mapping.mapping;

            if (Object.keys(mapping_data).length) {
                let travel_policy = search_session.search_results.travel_policy;
                let optimized_data_matches = _.filter(mapping_data, d => d.optimized);

                this.deals.map(d => {
                    let optimized_deal = _.find(optimized_data_matches, m => m.deal_id === d.id);
                    if (optimized_deal) {
                        d.setOptimizationData(optimized_deal);
                        d.setOptimizedTravelPolicy(travel_policy);
                    } else {
                        // revert deal to its original price
                        d.resetOptimizedData();
                        d.resetOptimizedTravelPolicy();
                    }

                    // set compression data if exists
                    let compress_deal = _.find(mapping_data, l => l.deal_id === d.id);

                    // if group with expedia winner - show all expedia deals in group
                    if (_.get(compress_deal, 'debug.expedia_match')) {
                        const group = _.filter(mapping_data, d => d.group_id === compress_deal.group_id && d.category === compress_deal.category);
                        const winner_deal = _.find(group, d => d.show_deal === true);
                        const winner_deal_expedia_match = _.get(winner_deal, 'debug.expedia_match', false);

                        if (winner_deal_expedia_match) {
                            const winner_deal_price = _.get(winner_deal, 'debug.original_deal.price');
                            const compress_deal_price = _.get(compress_deal, 'debug.original_deal.price');

                            if (winner_deal_price === compress_deal_price) {
                                d.superseded = false;
                                return;
                            }
                        }
                    }

                    if (compress_deal && compress_deal.show_deal === false) {
                        d.superseded = true;
                    } else if (compress_deal && compress_deal.show_deal === true) {
                        d.superseded = false;
                    }
                });
            }
        }
    }

    addReservationAttempt() {
        this.reservation_attempts = (this.reservation_attempts || 0) + 1;
    }

    recalculateSuperseded(search_session, deal_to_ignore) {
        let enable_room_mapping = _.get(search_session, 'room_mapping.settings.enable_room_mapping', false);
        if (enable_room_mapping) {
            return;
        }
        deal_to_ignore.superseded = true;
        this._indexed_deals[deal_to_ignore.id] = deal_to_ignore;

        const hotel_deals_in_group = this.deals
            .filter((deal) => (deal.id !== deal_to_ignore.id) &&
                deal.room_group === deal_to_ignore.room_group &&
                deal.status !== DealConstants.DEAL_STATUS.NA);
        if (!hotel_deals_in_group.length) {
            deal_to_ignore.superseded = false;
            console.log('mark unavailable');
            return;
        }
        hotel_deals_in_group.forEach(deal => deal.superseded = false);

        for (const deal_in_group of hotel_deals_in_group) {
            this.addRecheckedDeal(search_session, deal_in_group);
        }
    }

    markSupersededDeals(search_session, deal_to_add) {
        let enable_room_mapping = _.get(search_session, 'room_mapping.settings.enable_room_mapping', false);
        if (enable_room_mapping) {
            return;
        }

        // regular superseded
        const hotel_deals_in_group = this.deals
            .filter((deal) => (deal.id !== deal_to_add.id) &&
                deal.chooseprod &&
                (deal.room_group === deal_to_add.room_group) &&
                !deal.superseded);

        for (const existing_deal of hotel_deals_in_group) {
            if (existing_deal.room_id && !deal_to_add.room_id) {
                deal_to_add.room_id = existing_deal.room_id;
            }

            if (!existing_deal.room_id && deal_to_add.room_id) {
                existing_deal.room_id = deal_to_add.room_id;
            }
            const superseded_comparison = compareSuperseded(deal_to_add, existing_deal);

            if (superseded_comparison > 0) {
                //other deals were already previouslyj marked to oblivion. Just mark the new existing_deal as superseded
                deal_to_add.superseded = true;
                return;
            } else if (superseded_comparison < 0) {
                existing_deal.superseded = true;
            }
        }
    }

    addRecheckedDeal(search_session, deal) {
        setRepresentativeDeal(this, deal);
        this._indexed_deals[deal.id] = deal;

        this.markSupersededDeals(search_session, deal);
    }

    clearDeals() {
        this.deals = [];
        this.representative_deals = {};
        this._indexed_deals = {};
        this._payments_excluded_deals = {};
        this.deals_status = HotelConstants.DEALS_STATUS.INITIAL;
        this.recheck_status = HotelConstants.RECHECK_STATUS.INITIAL;
    }

    addRawRecheckedDeals(search_session, rawRecheckedDealsArray) {
        this.rechecked = true;
        const representative_deals_ids = Object.values(this.representative_deals).map(i => i.id);
        for (const raw_rechecked_deal of rawRecheckedDealsArray) {
            const rechecked_deal = new Deal(raw_rechecked_deal);
            if (!rechecked_deal.faultDeal ||
                representative_deals_ids.includes(rechecked_deal.id)) { // for reprenstative display a fault message
                if (rechecked_deal.faultDeal) {
                    const primal_deal = this.primal_deals.find(pd => pd.id === rechecked_deal.id);
                    rechecked_deal.setPreviousPricing(primal_deal);
                }

                this.addRecheckedDeal(search_session, rechecked_deal);
                if (rechecked_deal.payments_excluded && !rechecked_deal.payments_excluded_raw) {
                    // Add payments_excluded deal
                    this._payments_excluded_deals[rechecked_deal.id] = rechecked_deal;
                }
            }
        }
    }

    setHotelMatch(hotelId) {
        const isMatch = hotelId === this.id;
        this.hotelMatch = isMatch;
        if (this.scores.normal.total_score > 500 && !isMatch) {
            this.scores.normal.total_score -= 1000;
            this.scores.normal.hotel_score -= 1000;
        }
        if (this.scores.normal.total_score < 500 && isMatch) {
            this.scores.normal.total_score += 1000;
            this.scores.normal.hotel_score += 1000;
        }
    }

    /// Setters:
    /// TODO! Should this be in common, or in a override class
    set RecheckStatus(status) {
        this.recheck_status = status;
    }

    set DealsCachedStatus(status) {
        this.deals_cached_status = status;
    }

    set InfoStatus(status) {
        this.info_status = status;
    }

    set HotelInfo(hotel_info) {
        // Add data to hotelInfo
        this.hotelInfo = _.pick(hotel_info, [
            'about',
            'amenities',
            'extra_information',
            'sanitization_standards',
            'supplier_terms'
        ]);

        // Override data on hotel
        _.extend(this, _.pick(hotel_info, [
            'address',
            'city',
            'country',
            'country_code',
            'name',
            'stars',
            'state',
            'zip'
        ]));

        // if (!Array.isArray(this.images) || (this.images.length === 0)) {
        HotelUtils.setImages(this, hotel_info.images);
        // }
        // if (!Array.isArray(this.rooms) || (this.rooms.length === 0)) {
        HotelUtils.setRooms(this, hotel_info.rooms);
        // }
        HotelUtils.setGeoJSON(this, hotel_info.lon_lat);
        HotelUtils.setReview(this, hotel_info.review);
    }

    hasDeals() {
        if (this.representativeDeal) {
            return true;
        }

        return this.deals.length > 0;
    }

    get deals() {
        return Object.values(this._indexed_deals);
    }

    get indexed_deals() {
        return Object.assign({}, this._indexed_deals);
    }

    set deals(data) {
        const deals = data || [];
        this._indexed_deals = deals;
    }

    get rechecked_deals() {
        const deals = Object.values(this._indexed_deals);
        return deals.filter(DealUtils.isValidRecheck);
    }

    /**
     * merges two hotels iff both are valid hotel entities and have the same id
     * @param {*} baseHotel base hotel to be overriden
     * @param {*} overrideHotel this hotel's properties are "stronger"
     * @returns merged hotel or baseHotel if params are invalid
     */
    static merge(baseHotel, overrideHotel) {
        if ((baseHotel instanceof Hotel) && (overrideHotel instanceof Hotel) && (baseHotel.id === overrideHotel.id)) {
            for (const key in overrideHotel) {
                if (!_.isEmpty(overrideHotel[key])) {
                    baseHotel[key] = overrideHotel[key];
                }
            }
        } else {
            console.warn('Hotel.merge ::: invalid params', baseHotel, overrideHotel);
        }

        return baseHotel;
    }
}

module.exports = Hotel;
