import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import classNames from 'classnames';

import ResultsStore from '../../../../stores/ResultsStore';

import DealActions from '../../../../actions/DealActions';
import HotelActions from '../../../../actions/HotelActions';

import Currencies from '../../../../utils/Currencies';
import RouterWrapper from '../../../../utils/RouterWrapper';

import DealPrice from '../../../DealPrice.react';
import NegotiatedRate from '../../../general/NegotiatedRate.react';

import DealUtils from '../../../../entities/DealUtils';

import { Tooltip, Button } from '@mui/material';
import Constants from '../deal/constants';

import Analytics from 'arbitrip-common/client/analytics';
import Config from 'arbitrip-common/client/utils/Config';
import ContentLoader from 'react-content-loader';
import ProfileStore from '../../../../stores/ProfileStore';
import { withRouter } from '../../../../utils/withRouter';
import PriceBreakdown from 'arbitrip-common/client/utils/PriceBreakdown';
import { Dates as DateUtils } from 'arbitrip-common/client/utils';

const { NEWLINE } = Constants;

class RepresentativeDealComponent extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			travel_policy: ResultsStore.getTravelPolicy(),
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.arbitripPointsApplied !== this.props.arbitripPointsApplied) {
			this.setState({ busy_applying_points: true });
			const _self = this;
			setTimeout(() => {
				_self.setState({ busy_applying_points: false });
			}, 1000);
		}
	}

	handleOnSelectClick = () => {
		const { profile, searchTerms, hotel, deal, searchToken } = this.props;

		HotelActions.getHotelData(hotel);
		if (deal) {
			DealActions.chooseDeal(deal);
		}

		const recommended_index = ResultsStore.calculateRecommendedIndex(hotel);
		HotelActions.setHotelRecommendedIndex(hotel.id, recommended_index);

		const recommended_count = ResultsStore.getRecommendedCount();
		const sort_method = ResultsStore.getSortMethod();
		const time_to_click_ml = ResultsStore.getTimePassedSinceSearchSessionCreatedInMilliSeconds();
		Analytics.actions.interactions.selectedHotel(
			profile,
			searchTerms.destination,
			searchTerms,
			recommended_index,
			recommended_count,
			sort_method,
			time_to_click_ml,
		);

		HotelActions.setHotelScroll(window.scrollY, hotel.id);
		window.open(RouterWrapper.buildHotelRoute(hotel, searchToken, searchTerms), '_blank');
	};

	getDealDisclaimerRender() {
		const { deal } = this.props;
		const doesIncludeTax = deal && deal.does_price_include_tax;
		const isPostPay = this.props.deal.post_pay;

		const tooltip_text = isPostPay ? 'Payment is handled by the property' : PriceBreakdown.TOTAL_PRICE_TOOLTIP_TEXT;

		return (
			<Tooltip title={tooltip_text} placement="top" arrow>
				{isPostPay ? (
					<div className="tax-included">Pay at Hotel</div>
				) : doesIncludeTax ? (
					<div className="tax-included tooltip-icon">
						<p>Tax Included</p>
					</div>
				) : (
					<div className="tax-not-included tooltip-icon">Might not include taxes</div>
				)}
			</Tooltip>
		);
	}

	render() {
		const { deal, profile, arbitripPointsApplied, contractClientCompanyId, hotel } = this.props;

		const faultDeal = deal && deal.faultDeal;
		const company_name = _.get(profile, 'company.name', null);

		const noDeal = !DealUtils.isValidDeal(deal);

		if (noDeal) {
			return null;
		}

		// We have a deal, continue with Render method ->

		const travelPolicy = this.state.travel_policy;
		const doesTravelPolicyExist = travelPolicy && (travelPolicy.default || travelPolicy.place);
		const isInPolicy = deal.in_policy;
		const travelPolicyCurrency = doesTravelPolicyExist ? Currencies.getSymbol(travelPolicy.currency) : '';
		const travelPolicyLocation =
			travelPolicy && travelPolicy.default
				? 'this destination'
				: travelPolicy && travelPolicy.place
					? travelPolicy.place.city || travelPolicy.place.state || travelPolicy.place.country
					: '';
		const travelPolicyText = doesTravelPolicyExist
			? deal.out_of_policy_message ||
				'The maximum price allowed for ' +
					travelPolicyLocation +
					' is ' +
					(travelPolicyCurrency + travelPolicy.price) +
					' per night.'
			: null;

		const splitTravelPolicyText = travelPolicyText ? travelPolicyText.split(NEWLINE) : null;

		const dealIdPolicy = 'deal-' + deal.id + '-policy';
		const tooltip = (
			<div className="policy-tooltip-container">
				{splitTravelPolicyText
					? splitTravelPolicyText.map((line, idx) => <span key={dealIdPolicy + (idx + 1)}>{line}</span>)
					: null}
			</div>
		);

		const previousPrice =
			Config.dev_mode && deal.previousPricePerNight
				? `the old price per night: ${deal.previousPricePerNight}`
				: '';
		const originalCurrencyPrice =
			Config.dev_mode && deal.currency !== profile.display_currency
				? `the price in original currency: ${deal.currency}${deal.pricePerNight}`
				: '';

		const arbitrip_points_reduction = arbitripPointsApplied ? _.get(profile, 'bank.balance', 0) : 0;

		let DEAL_PRICE = (
			<div className="deal-price-container">
				<div className="inner-container">
					{this.state.busy_applying_points ? (
						<ContentLoader width={124} height={32} viewBox="0 0 160 32">
							<rect x="0" y="0" rx="0" yx="0" width="160" height="32" />
						</ContentLoader>
					) : (
						<React.Fragment>
							{ProfileStore.hasArbitripPoints() && arbitripPointsApplied && (
								<div className="old-price">
									<span>
										{Currencies.getPriceWithDisplayCurrencyByPrecision(
											faultDeal ? deal.previousPricePerNight : deal.pricePerNight,
											profile.display_currency_exchange_rate,
											profile.display_currency,
											0,
										)}
									</span>
								</div>
							)}
							<div className="deal-price">
								<NegotiatedRate
									companyName={company_name}
									show={!!deal.negotiated_rate}
									rawCode={deal.original_negotiated_rate || ''}
									code={deal.negotiated_rate || ''}
									codeDisplayName={deal.negotiated_rate_display_name || ''}
								/>
								<div className="price-per-night">
									{PriceBreakdown.isBreakdownPrice({ profile, deal }) ? (
										<span>
											{Currencies.getPriceWithDisplayCurrencyByDefaultPrecision(
												deal.expedia_pricing?.totalPrice,
												profile.display_currency_exchange_rate,
												profile.display_currency,
											)}
										</span>
									) : PriceBreakdown.isShowTotalPrice({ profile }) ? (
										<span>
											{Currencies.getPriceWithDisplayCurrencyByDefaultPrecision(
												deal.totalPrice,
												profile.display_currency_exchange_rate,
												profile.display_currency,
											)}
										</span>
									) : (
										<>
											{profile.agent ? (
												<DealPrice
													previousPrice={previousPrice}
													displayTotalPrice={true}
													supplier={deal.supplier_name}
													currency={deal.currency}
													price={deal.pricePerNight}
													totalPrice={deal.totalPrice}
													netPrice={deal.net_price_total}
													commission={deal.commission_total}
													dealId={deal.id}
													faultDeal={faultDeal}
													profile={profile}
													contractClientCompanyId={contractClientCompanyId}
												/>
											) : (
												<span
													title={
														Config.dev_mode &&
														(previousPrice + (deal.currency !== profile.display_currency)
															? ' | ' + originalCurrencyPrice
															: '')
													}
													className={classNames(faultDeal && 'fault-deal')}
												>
													<span>
														{Currencies.getPriceWithDisplayCurrencyByDefaultPrecision(
															(faultDeal
																? deal.previousPricePerNight
																: deal.pricePerNight) - arbitrip_points_reduction,
															profile.display_currency_exchange_rate,
															profile.display_currency,
														)}
													</span>
												</span>
											)}
										</>
									)}
								</div>
							</div>
						</React.Fragment>
					)}
				</div>
			</div>
		);

		const IS_IN_TRAVEL_POLICY = doesTravelPolicyExist && !PriceBreakdown.isBreakdownPrice({ profile, deal }) && (
			<Tooltip title={tooltip} placement="top-end" arrow>
				<div className="is-in-policy">
					<div className={isInPolicy ? 'in-policy icon' : 'out-of-policy icon'}></div>
					<div className={isInPolicy ? 'in-policy text' : 'out-of-policy text'}>
						{isInPolicy ? 'In Policy' : 'Out of Policy'}
					</div>
				</div>
			</Tooltip>
		);

		const DEAL_ACTION = (
			<Button
				className="select-button tab-theme-color"
				onClick={this.handleOnSelectClick}
				variant="outline"
				disableRipple
				aria-label={`Select ${hotel.name}`}
				role="link"
			>
				Select
			</Button>
		);

		const checkIn = DateUtils.parseFormatDate(deal.check_in);
		const checkOut = DateUtils.parseFormatDate(deal.check_out);
		const nights = checkOut.diff(checkIn, 'days');

		return (
			<>
				<div
					className={
						PriceBreakdown.isBreakdownPrice({ profile, deal }) ? 'price-wrapper bottom' : 'price-wrapper'
					}
				>
					{DEAL_PRICE}
					{PriceBreakdown.isBreakdownPrice({ profile, deal }) ? (
						<div className="deal-price-per-night">
							{nights > 1 && (
								<div>
									{' '}
									<span className="price">
										{Currencies.getPriceWithDisplayCurrencyByPrecision(
											deal.expedia_pricing.totalPrice / nights,
											profile.display_currency_exchange_rate,
											profile.display_currency,
											0,
										)}
									</span>{' '}
									Avg/Night
								</div>
							)}
							<div>Includes taxes & fees</div>
						</div>
					) : PriceBreakdown.isShowTotalPrice({ profile }) ? (
						<div className="deal-price-per-night">
							{nights > 1 && (
								<div>
									{' '}
									<span className="price">
										{Currencies.getPriceWithDisplayCurrencyByPrecision(
											deal.totalPrice / nights,
											profile.display_currency_exchange_rate,
											profile.display_currency,
											0,
										)}
									</span>{' '}
									Avg/Night
								</div>
							)}
							{this.getDealDisclaimerRender()}
						</div>
					) : (
						<div className="per-night-text">Per night</div>
					)}
					{IS_IN_TRAVEL_POLICY}
				</div>
				{DEAL_ACTION}
			</>
		);
	}
}

RepresentativeDealComponent.propTypes = {
	deal: PropTypes.any,
	hotel: PropTypes.any,
	searchToken: PropTypes.any,
	searchTerms: PropTypes.any,
};

export default withRouter(RepresentativeDealComponent);
