import React from 'react';
import createClass from 'create-react-class';
import ReservationConstants from '../../../constants/ReservationConstants';

const BookingStatus = createClass({
	displayName: 'BookingStatus',

	render: function () {
		const status = this.props.status;

		if (status) {
			var statusText = '';
			var statusClass = 'status';
			var statusTitle = '';

			switch (status) {
				case ReservationConstants.RESERVATION_STATUS.QUOTE:
					statusText += 'Quote';
					statusClass += ' quote';
					break;
				case ReservationConstants.RESERVATION_STATUS.PENDING:
				case ReservationConstants.RESERVATION_STATUS.PENDING_SUPPLIER:
				case ReservationConstants.RESERVATION_STATUS.PENDING_PAYMENT:
				case ReservationConstants.RESERVATION_STATUS.PENDING_SUPPORT:
					statusText += 'Pending';
					statusClass += ' pending';
					break;
				case ReservationConstants.RESERVATION_STATUS.CANCELED:
				case ReservationConstants.RESERVATION_STATUS.CANCELLED:
					statusText += 'Canceled';
					statusClass += ' canceled';
					break;

				case ReservationConstants.RESERVATION_STATUS.CANCELING:
					statusText += 'Cancelling...';
					statusClass += ' canceling';
					break;

				case ReservationConstants.RESERVATION_STATUS.ABORTED:
					statusText += 'Aborted';
					statusClass += ' canceled';
					break;
				case ReservationConstants.RESERVATION_STATUS.ERROR:
					statusText += 'Error';
					statusClass += ' canceled';
					break;
				case ReservationConstants.RESERVATION_STATUS.APPROVED:
					statusText += 'Confirmed';
					statusClass += ' confirmed';
					break;
				default: // Do nothing
			}

			if (statusText) {
				return (
					<div className={statusClass}>
						{status === ReservationConstants.RESERVATION_STATUS.CANCELING ? (
							<i title="Canceling reservation..." className="fa fa-spin fa-spinner" />
						) : null}
						<span title={statusTitle}>{statusText}</span>
					</div>
				);
			}
		}

		return null;
	},
});

export default BookingStatus;
