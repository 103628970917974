import _ from 'lodash';
import React from 'react';
import classNames from 'classnames';

const BookingField = ({ fieldTitle, fieldValue, fieldAction, icon, onClick, className }) => {
	return (
		<div
			className={classNames('booking-field', className)}
			onClick={() => {
				if (_.isFunction(onClick)) {
					onClick();
				}
			}}
		>
			<div className="field-row">
				<div className="field-title">{fieldTitle}</div>
				{icon && <div className="field-icon">{icon}</div>}
			</div>
			<div className="field-row">
				<div className="field-value">{fieldValue}</div>
			</div>
			{fieldAction && <div className="field-row field-action">{fieldAction}</div>}
		</div>
	);
};

export default BookingField;
