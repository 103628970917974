import React from 'react';
import { FormControl, FormHelperText, MenuItem, Select } from '@mui/material';

const { CURRENCIES } = require('@arbitrip/constants');

const SettlePaymentChargeCurrency = ({ chargeCurrency, updateChargeCurrency }) => (
	<div className="settle-payment-charge-currency">
		<FormControl fullWidth>
			<FormHelperText id="choose-charge-currency">Currency</FormHelperText>
			<Select
				id="choose-charge-currency"
				value={chargeCurrency}
				size="small"
				onChange={(event) => updateChargeCurrency(event.target.value)}
			>
				<MenuItem value={CURRENCIES.USD}>{CURRENCIES.USD}</MenuItem>
				<MenuItem value={CURRENCIES.EUR}>{CURRENCIES.EUR}</MenuItem>
				<MenuItem value={CURRENCIES.ILS}>{CURRENCIES.ILS}</MenuItem>
				<MenuItem value={CURRENCIES.GBP}>{CURRENCIES.GBP}</MenuItem>
			</Select>
		</FormControl>
	</div>
);

export default SettlePaymentChargeCurrency;
