import { ReactComponent as LocationIcon } from '@/img/confirmation_page/location.svg';
import { ReactComponent as PeopleIcon } from '@/img/confirmation_page/people.svg';
import StarsComponent from '@/components/StarsComponent.react';
import _ from 'lodash';
import ImageUtils from '../../../utils/ImageUtils';

const ReservationDetails = ({ hotel, reservation }) => {
	const { image: hotelImage, name: hotelName, stars, hasReview, address, city } = hotel;

	const reviews = _.get(hotel, 'review.votes');

	const rooms = _.get(reservation, 'deal.details.room_count');
	const guestsPerRoom = _.get(reservation, 'deal.guestsPerRoom');
	const childrenAges = _.get(reservation, 'deal.children_ages', []);

	const numberOfGuests = guestsPerRoom * rooms;
	const numberOfChildren = childrenAges.length * rooms;

	const roomsText = `${rooms} ${rooms === 1 ? 'Room' : 'Rooms'}`;
	const guestsText = `${numberOfGuests} ${numberOfGuests === 1 ? 'Adult' : 'Adults'}`;

	const formattedChildrenAges = childrenAges.map((age) => (age === 0 ? 'under 1' : age));

	const childrenText =
		numberOfChildren > 0
			? `${numberOfChildren} ${numberOfChildren === 1 ? 'Child' : 'Children'} (${numberOfChildren === 1 ? 'age' : 'ages'} ${formattedChildrenAges.join(', ')})`
			: null;

	return (
		<div className="reservation-details">
			<div className="reservation-details__image">
				<img src={hotelImage ?? ImageUtils.FALLBACK_IMAGE} />
			</div>
			<div className="reservation-details__hotel-name">{hotelName}</div>
			<div className="reservation-details__reviews">
				{stars && <StarsComponent stars={stars} />}
				{hasReview && <span> | {reviews} Reviews</span>}
			</div>
			<div>
				<div className="reservation-details__address">
					<LocationIcon />
					{address}, {city}
				</div>
				<div className="reservation-details__rooms-and-guests">
					<PeopleIcon />
					{roomsText} | {guestsText} {childrenText && ` | ${childrenText}`}
				</div>
			</div>
		</div>
	);
};

export default ReservationDetails;
