import _ from 'lodash';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import useScrollToTopOnBookingChange from '../../../hooks/useScrollToTopOnBookingChange';
import Drawer from '@mui/material/Drawer';
import CollapseText from '../../general/CollapseText.react';
import { Button, IconButton, Snackbar } from '@mui/material';
import { ReactComponent as CopyIcon } from '../../../img/bookings/copy.svg';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import BookingStatus from '../../../utils/BookingStatus';
import { Catering } from 'arbitrip-common/client/utils';
import BookingField from '../new-bookings/BookingDrawerField.react';
import BookingDrawerReservationSummary from '../new-bookings/BookingDrawerReservationSummary.react';
import { ReactComponent as BreakfastIcon } from '../../../img/amenities/breakfast@3x.svg';
import { getBreakfastIncluded, isRequestActionable } from './helpers';
import ReservationConstants from '../../../constants/ReservationConstants';

const date_format = 'D MMM YYYY';

function getTravelersText(booking) {
	let travelers_text = booking.travelers.map((t) => `${t.first_name} ${t.last_name}`).join(', ');
	if (booking.deal.details.children_count > 0) {
		travelers_text += `\r\n+${booking.deal.details.children_count} ${booking.deal.details.children_count > 1 ? 'Children' : 'Child'}`;
	}
	return travelers_text;
}

const RequestDrawer = ({ open, onClose, booking, profile, handleApprove, handleDecline }) => {
	const reservation = _.get(booking, 'reservation');
	const ticket = _.get(booking, 'request_ticket');

	const is_approver = _.get(ticket, 'approver._id') === profile.id;

	const [copyFlash, setCopyFlash] = useState(false);

	const cancellation_policy_text = useMemo(() => {
		return _.get(reservation, 'deal.dca.cancellationPoliciesText');
	}, [reservation]);

	const breakfastIncluded = useMemo(() => {
		return getBreakfastIncluded(reservation);
	}, [reservation]);

	const copyReservationId = () => {
		setCopyFlash(true);
		navigator.clipboard.writeText(_.get(reservation, 'short_id'));

		setTimeout(() => {
			setCopyFlash(false);
		}, 4000);
	};

	const drawerRef = useScrollToTopOnBookingChange(booking);

	if (!reservation) {
		console.log('RequestDrawer ::: NO BOOKING');
		return null;
	}

	return (
		<React.Fragment>
			<Drawer
				anchor="right"
				open={open}
				onClose={onClose}
				className="booking-drawer"
				variant="persistent"
				SlideProps={{
					appear: true,
				}}
			>
				<div ref={drawerRef} tabIndex="-1" className="booking-drawer-inner">
					<div className="booking-title">
						<div className="flex space-between align-items-baseline">
							<div className="trip-to">
								<span className="trip-to-hotel-name">{reservation.hotel.name}</span>
							</div>
							<IconButton className="close-drawer-button" aria-label="close" onClick={onClose}>
								<CloseOutlined />
							</IconButton>
						</div>
						<div className="city">
							<div>{reservation.hotel.city}</div>
							<div>{`${reservation.hotel.address}, ${reservation.hotel.city}, ${reservation.hotel.country}`}</div>
						</div>
						<div className={`booking-status ${ticket.status}`}>
							<span>● {BookingStatus.getRequestStatus(ticket.status)}</span>
						</div>
					</div>

					{(profile.isApprovalFlowPowerUser() || is_approver) && isRequestActionable(ticket) && (
						<div className="actions">
							{reservation.status === ReservationConstants.RESERVATION_STATUS.REQUESTED ? (
								<Button
									variant="outlined"
									fullWidth
									color="success"
									onClick={() => window.open(ticket.action_url, '_blank')}
								>
									Book
								</Button>
							) : (
								<Button
									variant="outlined"
									fullWidth
									color="success"
									onClick={() => handleApprove(booking)}
								>
									Approve
								</Button>
							)}

							<Button variant="outlined" fullWidth onClick={() => handleDecline(booking)}>
								Decline
							</Button>
						</div>
					)}

					{!_.isEmpty(ticket.meta.reason) && (
						<BookingField fieldTitle="Request Reason" fieldValue={ticket.meta.reason} />
					)}

					<BookingDrawerReservationSummary booking={reservation} />

					<BookingField
						fieldTitle="Cancellation Policy"
						fieldValue={
							cancellation_policy_text ? (
								<CollapseText text={cancellation_policy_text} breakLines />
							) : (
								'None'
							)
						}
					/>

					<BookingField
						fieldTitle={`Room${reservation.deal.details.room_count > 1 ? `s (${reservation.deal.details.room_count}x)` : ''}`}
						fieldValue={
							<React.Fragment>
								{reservation.deal.details.room_description}
								{breakfastIncluded && (
									<div className="space">
										<BreakfastIcon />
										{Catering.getCatering(reservation.deal.dca.heuristicOptions)}
									</div>
								)}
							</React.Fragment>
						}
					/>

					<BookingField
						fieldTitle={`Traveler${reservation.travelers.length + (reservation.deal.details.children_count || 0) > 1 ? `s (${reservation.travelers.length + (reservation.deal.details.children_count || 0)}x)` : ''}`}
						fieldValue={getTravelersText(reservation)}
					/>

					{reservation.short_id && (
						<BookingField
							fieldTitle="Reservation ID"
							fieldValue={reservation.short_id}
							icon={<CopyIcon />}
							onClick={copyReservationId}
							className={{ active: copyFlash }}
						/>
					)}

					{!_.isEmpty(ticket.approver.full_name) && (
						<BookingField fieldTitle="Approver" fieldValue={ticket.approver.full_name} />
					)}

					{!_.isEmpty(reservation.creator) && (
						<BookingField fieldTitle="Creator" fieldValue={reservation.creator.full_name} />
					)}

					<BookingField
						fieldTitle="Creation Date"
						fieldValue={moment(reservation.creation_date).format(date_format)}
					/>
				</div>
			</Drawer>

			<Snackbar open={copyFlash} message={'Reservation ID copied to clipboard'} autoHideDuration={4000} />
		</React.Fragment>
	);
};

export default RequestDrawer;
