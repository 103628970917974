import { useState, useMemo } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import MinusIcon from '../search/search-data/MinusIcon';
import PlusIcon from '../search/search-data/PlusIcon';
import { useTranslation } from 'react-i18next';
import {
	FAQ_EN,
	FAQ_HE,
	FAQ_WONDER_EN,
	FAQ_WONDER_HE,
	FAQ_HTZ_EN,
	FAQ_HTZ_HE,
	SUPPORTED_LANGUAGES,
} from 'arbitrip-common/translation/const';
import _ from 'lodash';
import mapping from 'arbitrip-common/theme/mapping';
import ProfileStore from '../../stores/ProfileStore';
import { LOYALTY_POINTS_PROVIDER } from 'arbitrip-common/general/constants';

const FaqPage = () => {
	const { i18n } = useTranslation();

	const profile = ProfileStore.getProfile();
	const company_id = _.get(profile, 'company.id');
	const company_theme_name = _.get(mapping, company_id);
	const isHTZ = company_theme_name === 'HitechZone';
	const pointsProvider = _.get(profile, 'loyalty.points_provider', LOYALTY_POINTS_PROVIDER.NO_PROVIDER);
	const isWonder = pointsProvider === LOYALTY_POINTS_PROVIDER.WONDER;

	const faqData = useMemo(() => {
		switch (i18n.language) {
			case isWonder && SUPPORTED_LANGUAGES.HE:
				return FAQ_WONDER_HE;
			case isWonder && SUPPORTED_LANGUAGES.EN:
				return FAQ_WONDER_EN;
			case isHTZ && SUPPORTED_LANGUAGES.HE:
				return FAQ_HTZ_HE;
			case isHTZ && SUPPORTED_LANGUAGES.EN:
				return FAQ_HTZ_EN;
			case SUPPORTED_LANGUAGES.HE:
				return FAQ_HE;
			default:
				return FAQ_EN;
		}
	}, [i18n.language, isHTZ, isWonder]);

	const [expandedList, setExpandedList] = useState([]);

	const isExpanded = (question) => expandedList.includes(question);

	const handleChange = (question) => {
		setExpandedList((prev) => (prev.includes(question) ? prev.filter((q) => q !== question) : [...prev, question]));
	};

	return (
		<div className="faq-container">
			<Typography variant="h4" align="center" className="faq-title">
				FAQ
			</Typography>
			<div className="faq-list">
				{faqData.map((item) => (
					<Accordion
						key={item.title}
						className="faq-item"
						expanded={isExpanded(item.title)}
						onChange={() => handleChange(item.title)}
					>
						<AccordionSummary
							expandIcon={isExpanded(item.title) ? <MinusIcon /> : <PlusIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							<Typography className="faq-question">{item.title}</Typography>
						</AccordionSummary>
						<AccordionDetails>
							{item.description && <Typography className="faq-answer">{item.description}</Typography>}

							{item.secondary?.length > 0 &&
								item.secondary.map((sub_question, i) => (
									<div key={`question-${i}`}>
										<Typography className="faq-sub-question">{sub_question.title}</Typography>
										<Typography className="faq-answer">{sub_question.description}</Typography>
									</div>
								))}
						</AccordionDetails>
					</Accordion>
				))}
			</div>
		</div>
	);
};

export default FaqPage;
