import _ from 'lodash';
import moment from 'moment';
import { useLayoutEffect, useRef, useState } from 'react';

const airplaneIconWidth = 30;
const dashWidthPx = 3;

const BookedFlight = ({ flight }) => {
	const iconRef = useRef();
	const [dashes, setDashes] = useState('');

	useLayoutEffect(() => {
		if (!hasData) return;
		const iconContainerWidth = iconRef.current.clientWidth;
		const dashLineWidth = Math.round((iconContainerWidth - airplaneIconWidth) / 2 / dashWidthPx) + 1;
		setDashes(Array(dashLineWidth).join('-'));
	}, []);

	const date_format = 'ddd, DD MMM y';

	const depart_date = flight.depart_date && moment(flight.depart_date).format(date_format);
	const depart_city = _.get(flight, 'depart_airport.city', '');
	const depart_code = _.get(flight, 'depart_airport.iata_code', '');

	const arrival_date = flight.arrival_date && moment(flight.arrival_date).format(date_format);
	const arrival_city = _.get(flight, 'arrival_airport.city', '');
	const arrival_code = _.get(flight, 'arrival_airport.iata_code', '');

	const hasData = arrival_city && depart_city;

	const transformTime = (time) => {
		const timeParts = time.split(':');
		const hours = parseInt(timeParts[0]);
		const minutes = parseInt(timeParts[1]);

		const date = new Date();
		date.setHours(hours);
		date.setMinutes(minutes);

		return date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
	};

	return (
		<div className="booked-item booked-flight flex space-between align-center" key={flight.id}>
			<div className="booked-item-container">
				<div className="city">{depart_city}</div>
				<div className="code">{depart_code}</div>
				{!!flight.depart_time && <div className="time">{transformTime(flight.depart_time)}</div>}
				<div className="date">{depart_date}</div>
			</div>
			{hasData && (
				<div ref={iconRef} className="icon-container flight-icon flex-1">
					<span className="dash left">{dashes}</span>
					<img className="icon" src="../../../img/trip/airplane.svg" alt="airplane" />
					<span className="dash right">{dashes}</span>
				</div>
			)}
			<div className="booked-item-container">
				<div className="city">{arrival_city}</div>
				<div className="code">{arrival_code}</div>
				{!!flight.arrival_time && <div className="time">{transformTime(flight.arrival_time)}</div>}
				<div className="date">{arrival_date}</div>
			</div>
		</div>
	);
};

export default BookedFlight;
