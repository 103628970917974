import React from 'react';
import createClass from 'create-react-class';

import SearchStore from '../../../stores/SearchStore';
import Currencies from '../../../utils/Currencies';

import Config from 'arbitrip-common/client/utils/Config';

function getTravelPolicyState() {
	return {
		company_name: SearchStore.getContractClientName(),
	};
}

function isTravelPolicyValid(travel_policy) {
	return (
		travel_policy && (travel_policy.default || travel_policy.place) && travel_policy.currency && travel_policy.price
	);
}

const TravelPolicyComponent = createClass({
	displayName: 'TravelPolicyComponent',

	getInitialState: function () {
		return getTravelPolicyState();
	},

	_onChange: function (event, value) {
		this.setState(getTravelPolicyState());
	},

	componentDidMount: function () {
		SearchStore.addChangeListener(this._onChange);
	},

	componentWillUnmount: function () {
		SearchStore.removeChangeListener(this._onChange);
	},

	render: function () {
		const { company_name } = this.state;
		const { profile, travelPolicy } = this.props;
		const { display_currency_exchange_rate, display_currency, agent } = profile;

		// console.log("TP:", travelPolicy, "AG:", agent, "CN:", company_name);
		if (isTravelPolicyValid(travelPolicy)) {
			const travel_policy_prefix = (company_name ? '' : 'Policy: ') + 'The maximum price allowed for ';
			const cur = Currencies.getSymbol(travelPolicy.currency);
			// const travel_policy_suffix = " is " + cur + travel_policy.price + " per night.";
			const display_price = Currencies.getPriceWithDisplayCurrencyByPrecision(
				travelPolicy.price,
				display_currency_exchange_rate,
				display_currency,
				0,
			);
			const travel_policy_suffix = ` is ${display_price} per night.`;

			let message = travel_policy_prefix;
			if (travelPolicy.place) {
				message += travelPolicy.place.city || travelPolicy.place.state || travelPolicy.place.country;
			} else if (travelPolicy.default) {
				message += 'this destination';
			}
			message += travel_policy_suffix;

			return (
				<div
					className="travel-policy-component"
					title={
						Config.dev_mode && travelPolicy.currency !== display_currency
							? `policy in original currency was [${cur}${travelPolicy.price}]`
							: ''
					}
				>
					<div className="in-policy icon"></div>
					<div>
						{/* TODO: Add when new design would be ready */}
						{/* {
              agent && company_name
                ? <span style={{ fontWeight: 'bold', marginRight: 4 }}>
                  {company_name}:
                </span>
                : null
            } */}
						{message}
					</div>
				</div>
			);
		} else if (agent && company_name) {
			return (
				<div className="travel-policy-component">
					<span style={{ marginRight: 4 }}>Showing results for:</span>
					<span style={{ fontWeight: 'bold' }}>{company_name}</span>
				</div>
			);
		}

		return null;
	},
});

export default TravelPolicyComponent;
