import AppDispatcher from '../dispatcher/AppDispatcher';
import { EventEmitter } from 'events';
import TravelPolicyConstants from '../constants/TravelPolicyConstants';
import _ from 'lodash';

let _travel_policy = null;
let _newEntryId = null;
let _errorMessage = null;

let _statuses = {
	retrieve: TravelPolicyConstants.STATUS.INITIAL,
	edit: TravelPolicyConstants.STATUS.INITIAL,
	editDefault: TravelPolicyConstants.STATUS.INITIAL,
	save: TravelPolicyConstants.STATUS.INITIAL,
	delete: TravelPolicyConstants.STATUS.INITIAL,
};

const resetAllStatuses = () => {
	Object.keys(_statuses).forEach((key) => {
		_statuses[key] = TravelPolicyConstants.STATUS.INITIAL;
	});
};

// Extend Search Store with EventEmitter to add eventing capabilities
const TravelPolicyStore = _.extend({}, EventEmitter.prototype, {
	// Getters
	getTravelPolicy: () => _travel_policy,

	getNewEntryId: () => _newEntryId,

	getRetrieveStatus: () => _statuses.retrieve,

	getEditingStatus: () => _statuses.edit,

	getEditingDefaultStatus: () => _statuses.editDefault,

	getSavingStatus: () => _statuses.save,

	getDeleteStatus: () => _statuses.delete,

	getErrorMessage: () => _errorMessage,

	// Emit Change event
	emitChange: function () {
		this.emit('change');
	},

	// Add change listener
	addChangeListener: function (callback) {
		this.on('change', callback);
	},

	// Remove change listener
	removeChangeListener: function (callback) {
		this.removeListener('change', callback);
	},
});

// Register callback with AppDispatcher
AppDispatcher.register(function (payload) {
	let action = payload.action;

	switch (action.actionType) {
		case TravelPolicyConstants.RETRIEVE_TRAVEL_POLICIES:
			_statuses.retrieve = TravelPolicyConstants.STATUS.BUSY;
			break;

		case TravelPolicyConstants.TRAVEL_POLICIES_RETRIEVAL_SUCCESS:
			_statuses.retrieve = TravelPolicyConstants.STATUS.SUCCESS;
			_travel_policy = action.data.travelPolicy;
			break;

		case TravelPolicyConstants.TRAVEL_POLICIES_RETRIEVAL_FAILED:
			_statuses.retrieve = TravelPolicyConstants.STATUS.FAILED;
			break;

		////////////////////////////////////////////////////////////////////////////////

		case TravelPolicyConstants.CREATE_TRAVEL_POLICY_ENTRY:
			_statuses.save = TravelPolicyConstants.STATUS.BUSY;
			break;

		case TravelPolicyConstants.TRAVEL_POLICY_ENTRY_CREATION_SUCCESS:
			_statuses.save = TravelPolicyConstants.STATUS.SUCCESS;

			_travel_policy = action.data.travelPolicy;
			_newEntryId = action.data.newEntryId;
			break;

		case TravelPolicyConstants.TRAVEL_POLICY_ENTRY_CREATION_FAILED:
			_statuses.save = TravelPolicyConstants.STATUS.FAILED;
			_errorMessage = action.data.responseJSON?.message;
			break;

		////////////////////////////////////////////////////////////////////////////////

		case TravelPolicyConstants.EDIT_TRAVEL_POLICY_ENTRY:
			_statuses.edit = TravelPolicyConstants.STATUS.BUSY;
			break;

		case TravelPolicyConstants.TRAVEL_POLICY_ENTRY_EDITION_SUCCESS:
			_statuses.edit = TravelPolicyConstants.STATUS.SUCCESS;

			_travel_policy = action.data.travelPolicy;
			break;

		case TravelPolicyConstants.TRAVEL_POLICY_ENTRY_EDITION_FAILED:
			_statuses.edit = TravelPolicyConstants.STATUS.FAILED;
			break;

		////////////////////////////////////////////////////////////////////////////////

		case TravelPolicyConstants.EDIT_DEFAULT_TRAVEL_POLICY:
			_statuses.editDefault = TravelPolicyConstants.STATUS.BUSY;
			break;

		case TravelPolicyConstants.DEFAULT_TRAVEL_POLICY_EDITION_SUCCESS:
			_statuses.editDefault = TravelPolicyConstants.STATUS.SUCCESS;

			_travel_policy = action.data.travelPolicy;
			break;

		case TravelPolicyConstants.DEFAULT_TRAVEL_POLICY_EDITION_FAILED:
			_statuses.editDefault = TravelPolicyConstants.STATUS.FAILED;
			break;

		////////////////////////////////////////////////////////////////////////////////

		case TravelPolicyConstants.REMOVE_TRAVEL_POLICY_ENTRY:
			_statuses.delete = TravelPolicyConstants.STATUS.BUSY;
			break;

		case TravelPolicyConstants.TRAVEL_POLICY_ENTRY_REMOVAL_SUCCESS:
			_statuses.delete = TravelPolicyConstants.STATUS.SUCCESS;

			const removedEntryId = action.data.travelPolicyEntryId;
			if (Array.isArray(_travel_policy.entries) && removedEntryId) {
				const newGroupedEntries = _.reduce(
					_travel_policy.groupedEntries,
					(newEntries, value, key) => {
						const filteredEntries = _.filter(value, ({ _id }) => _id !== removedEntryId);
						if (!_.isEmpty(filteredEntries)) {
							newEntries[key] = filteredEntries;
						}
						return newEntries;
					},
					{},
				);

				_travel_policy.groupedEntries = newGroupedEntries;
			}
			break;

		case TravelPolicyConstants.TRAVEL_POLICY_ENTRY_REMOVAL_FAILED:
			_statuses.delete = TravelPolicyConstants.STATUS.FAILED;
			_errorMessage = action.data.responseJSON?.message;
			break;

		////////////////////////////////////////////////////////////////////////////////

		case TravelPolicyConstants.RESET_TRAVEL_POLICY_STATUS:
			resetAllStatuses();
			break;

		default:
			return true;
	}

	// If action was responded to, emit change event
	TravelPolicyStore.emitChange();

	return true;
});

export default TravelPolicyStore;
