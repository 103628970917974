import FormControlLabel from '@mui/material/FormControlLabel';

import React from 'react';
import createClass from 'create-react-class';

import FiltersActions from '../../../../actions/FiltersActions';
import StarsComponent from '../../../StarsComponent.react';

import FiltersConstants from '../../../../constants/FiltersConstants';
import Analytics from 'arbitrip-common/client/analytics';

import FilterCheckbox from './components/FilterCheckbox.react';

const StarsFilter = createClass({
	displayName: 'StarsFilter',

	getInitialState: function () {
		return { starsOnly: -1 };
	},

	handleCheckboxChangeByStars: function (event, isInputChecked, stars) {
		const { profile } = this.props;

		event.persist();
		FiltersActions.filterByStars(stars, isInputChecked);
		setTimeout(function () {
			FiltersActions.filterByStars(stars, isInputChecked);
		}, 10);

		const filter_value = (isInputChecked ? 'Show ' : 'Hide ') + stars + ' Stars';
		Analytics.actions.interactions.filteredResults(profile, FiltersConstants.FILTER_METHOD.STARS, filter_value);
	},

	// TODO: if budget user (from profile)
	// TODO: or flag show_unknown_quality_hotel_categories is on (in company or in user - user is stronger than comapny)
	// TODO: [default is budget or not?]
	// TODO: add 2 stars filtering

	handleMouseEnter: function (e) {
		this.setState({
			starsOnly: e.target.getAttribute('data-stars'),
		});
	},

	handleMouseLeave: function () {
		this.setState({
			starsOnly: -1,
		});
	},

	handleStarsOnlyClick: function (e) {
		const { profile } = this.props;

		e.persist();
		const filterStarsOnly = e.target.getAttribute('data-stars');
		FiltersActions.filterByStarsOnly(filterStarsOnly);
		setTimeout(function () {
			FiltersActions.filterByStarsOnly(filterStarsOnly);
		}, 10);

		const filter_value = `Show Only ${filterStarsOnly} Stars`;
		Analytics.actions.interactions.filteredResults(
			profile,
			FiltersConstants.FILTER_METHOD.STARS_ONLY,
			filter_value,
		);
	},

	handleResetStarsClick: function (e) {
		const { profile } = this.props;

		e.persist();
		FiltersActions.resetStarsFilter();
		setTimeout(function () {
			FiltersActions.resetStarsFilter();
		}, 10);

		const filter_value = 'Reset Stars Filter';
		Analytics.actions.interactions.filteredResults(profile, FiltersConstants.FILTER_METHOD.STARS, filter_value);
	},

	render() {
		let STARS = [];
		// for (let i = 5; i >= 2; i--) {
		const sorted_stars = Object.keys(this.props.filter).sort().reverse();
		const data = this.props.filter;
		for (let i in data) {
			if (typeof data[i] === 'string') {
				data[i] = data[i] === true.toString();
			}
		}
		// for (let i in this.props.filter) {
		for (let i = 0; i < sorted_stars.length; i++) {
			const idx = sorted_stars[i];
			const starFilterKey = `${idx}-stars-filter`;
			const stars_clone = Number(idx);

			const stars_label = (
				<label htmlFor={starFilterKey} className="checkbox-label" aria-label={starFilterKey}>
					{idx > 0 ? <StarsComponent stars={idx} mode="filter" /> : <span>No Rating</span>}
				</label>
			);

			STARS.push(
				<div className="checkbox-container" key={starFilterKey}>
					<FormControlLabel
						control={
							<FilterCheckbox
								checked={data[idx]}
								onChange={(e, c) => this.handleCheckboxChangeByStars(e, c, stars_clone)}
								disableRipple={true}
							/>
						}
						label={stars_label}
					/>

					<button
						className="btn btn-link"
						data-stars={idx}
						onClick={this.handleStarsOnlyClick}
						aria-label={`Select ${starFilterKey} only`}
					>
						Only
					</button>
				</div>,
			);
		}

		return (
			<div className="filter disabled">
				<label className="filter-title" htmlFor="stars-filter">
					<span>Stars</span>
				</label>

				<div name="stars-filter" className="check-list" aria-label="Stars filters">
					{STARS}
				</div>

				<button
					className="clear-button-wrap"
					onClick={this.handleResetStarsClick}
					aria-label="Reset Stars Filters"
				>
					<div className="clear-button-img" />
				</button>
			</div>
		);
	},
});

export default StarsFilter;
