import _ from 'lodash';
import React from 'react';
import createClass from 'create-react-class';

import Ajax from '../utils/Ajax';
import Airport from '../entities/Airport';
import { Autocomplete, TextField } from '@mui/material';

const data_source_config = {
	text: 'display_name',
	value: 'id',
};

function getAirportAutoCompleteState() {
	return {
		data: [],
		busy: false,
		txt: '',
	};
}

const NewerMaterialDesignAirportsAutocomplete = createClass({
	displayName: 'NewerMaterialDesignAirportsAutocomplete',

	getInitialState: function () {
		return getAirportAutoCompleteState();
	},

	delay: (function () {
		let timer = 0;

		return function (callback, millis) {
			clearTimeout(timer);
			timer = setTimeout(callback, millis);
		};
	})(),

	previous_data: null,

	updateData: function (data) {
		if (!data || !data.length) {
			return false;
		}

		if (this.state.data) {
			this.previous_data = { ...this.state.data };
		}

		this.setState({
			data: data.map((airport) => new Airport(airport)),
		});
	},

	handleOnUpdateInput: function (e, value) {
		if (!value || !value.length) {
			value = '';
			this.setState({
				busy: false,
				txt: value,
			});
			return false;
		}

		this.setState({
			busy: true,
			txt: value,
		});

		let _self = this;
		this.delay(function () {
			console.log('delayed airport ac callback');
			Ajax.staticAirportsLookup(value)
				.done(function (data) {
					console.log('Airports AutoComplete Search Results', data);
					_self.updateData(data);
				})
				.fail(function (err) {
					console.log('Airports AutoComplete Search Error', err);
				})
				.always(function () {
					_self.setState({
						busy: false,
					});
				});
		}, 200);
	},

	handleOnNewRequest: function (e, item) {
		if (!item) {
			item = {
				id: -1,
				display_name: '',
			};
		}

		console.log('ONR', item, ' PREV', this.previous_data, 'THISTATE', this.state.data);

		this.props.onNewRequest(item);
	},

	render: function () {
		const { searchText, fullWidth, autoFocus, label, placeholder, error } = this.props;
		let { data, txt } = this.state;

		return (
			<Autocomplete
				options={data}
				getOptionLabel={(option) => option.display_name || option}
				filterOptions={(data) => data} // To disable filtering
				isOptionEqualToValue={(option, value) =>
					option.display_name.toLowerCase().includes(value.toLowerCase())
				}
				onChange={this.handleOnNewRequest}
				onInputChange={this.handleOnUpdateInput}
				fullWidth={fullWidth}
				autoFocus={autoFocus}
				openOnFocus={true}
				value={typeof txt === 'string' && txt !== '' ? txt : searchText}
				renderInput={(params) => (
					<TextField {...params} label={label} placeholder={placeholder} error={error} variant="standard" />
				)}
			/>
		);
	},
});

export default NewerMaterialDesignAirportsAutocomplete;
