import { Tooltip } from '@mui/material';
import { StyledPrimaryActionButton, StyledCancelButton } from './styled/buttons';

const ActionButtons = ({
	entry,
	isEditing,
	editPrice,
	onSaveEdit,
	onCancelEdit,
	onOpenDeleteDialog,
	onEditTravelPolicy,
}) => {
	return (
		<div className="entry-row-wrapper flex align-center flex-end">
			{isEditing ? (
				<>
					<StyledCancelButton className="action-button cancel" onClick={onCancelEdit}>
						Cancel
					</StyledCancelButton>
					<StyledPrimaryActionButton
						className="action-button"
						onClick={() => onSaveEdit(entry._id)}
						disabled={!(editPrice > 0)}
					>
						Save
					</StyledPrimaryActionButton>
				</>
			) : (
				<>
					<Tooltip title="Remove travel policy" arrow placement="top">
						<button className="action-icon remove-button" onClick={() => onOpenDeleteDialog(entry._id)} />
					</Tooltip>
					<Tooltip title="Edit travel policy" arrow placement="top">
						<button className="action-icon edit-button" onClick={() => onEditTravelPolicy(entry)} />
					</Tooltip>
				</>
			)}
		</div>
	);
};

export default ActionButtons;
