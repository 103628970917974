import AppDispatcher from '../dispatcher/AppDispatcher';
import { EventEmitter } from 'events';
import DestinationsConstants from '../constants/DestinationsConstants';
import _ from 'lodash';

import BusinessDestinationEntity from '../entities/BusinessDestination';

// Define initial data points
var _data = [];
var _contract_id;
var _status = DestinationsConstants.STATUS.INITAL;

function removeBusinessDestination(destinationId) {
	for (var i = 0; i < _data.length; i++) {
		if (_data[i].id === destinationId) {
			return _data.splice(i, 1);
		}
	}
}

// Method to load result data from mock API
function loadDestinationsBoxData(data) {
	_data = [];
	var destinations = data.business_destinations;
	for (var i = 0; i < destinations.length; i++) {
		_data.push(new BusinessDestinationEntity(destinations[i]));
	}
}

// Extend Search Store with EventEmitter to add eventing capabilities
var DestinationsBoxStore = _.extend({}, EventEmitter.prototype, {
	getStatus: function () {
		return _status;
	},

	getContractId: function () {
		return _contract_id;
	},

	// Return search results
	getItems: function () {
		return _data;
	},

	// TODO: method for currency conversion

	// Emit Change event
	emitChange: function () {
		this.emit('change');
	},

	// Add change listener
	addChangeListener: function (callback) {
		this.on('change', callback);
	},

	// Remove change listener
	removeChangeListener: function (callback) {
		this.removeListener('change', callback);
	},
});

// Register callback with AppDispatcher
AppDispatcher.register(function (payload) {
	var action = payload.action;
	//var text;

	switch (action.actionType) {
		// // Respond to RECEIVE_DATA action
		// case DestinationsConstants.RECEIVE_DESTINATIONS_DATA:
		//   loadDestinationsBoxData(action.data);
		//   break;

		case DestinationsConstants.RECEIVE_DESTINATIONS_DATA:
			_status = DestinationsConstants.STATUS.BUSY;
			break;

		case DestinationsConstants.DESTINATION_RETRIEVED:
			loadDestinationsBoxData(action.data.destinations);
			_contract_id = action.data.contract_id;
			_status = DestinationsConstants.STATUS.SUCCESS;
			break;

		case DestinationsConstants.DESTINATIONS_RETRIEVAL_FAILED:
			_status = DestinationsConstants.STATUS.FAILED;
			break;

		case DestinationsConstants.ADD_DESTINATION:
			// TODO: handle...
			break;

		case DestinationsConstants.DESTINATION_REMOVED:
			console.log('BUSINESS DESTINATION REMOVED IN STORE: ', action.data);
			removeBusinessDestination(action.data.id);
			break;

		case DestinationsConstants.DESTINATION_ADDED:
			console.log('BUSINESS DESTINATION ADDED IN STORE: ', action.data);
			_data.push(new BusinessDestinationEntity(action.data.response.destination));
			break;

		default:
			return true;
	}

	// If action was responded to, emit change event
	DestinationsBoxStore.emitChange();

	return true;
});

export default DestinationsBoxStore;
