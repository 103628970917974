const COMMON_CONSTANTS = require('arbitrip-common/general/constants');
const AUTH_PROVIDERS = require('./auth-providers');
const CHAT_PROVIDERS = require('arbitrip-common/general/constants/chat-providers');

const BACKOFFICE_ROLES = require('./backoffice-roles');
const GEO = require('./geo');
const PAYMENT = require('./payment');
const PAYMENT_LINK = require('./payment-link');
const RESERVATIONS = require('./reservations');
const ROOM_MAPPING = require('./room-mapping');
const SYSTEM_ROLES = require('./system-roles');
const EXTERNAL_API_ROLES = require('./external-api-roles');
const PROVIDERS = require('./providers');

const CONSTANTS = module.exports = {
    ...COMMON_CONSTANTS,
    ...AUTH_PROVIDERS,
    ...CHAT_PROVIDERS,
    ...BACKOFFICE_ROLES,
    ...GEO,
    ...PAYMENT,
    ...PAYMENT_LINK,
    ...RESERVATIONS,
    ...ROOM_MAPPING,
    ...SYSTEM_ROLES,
    ...EXTERNAL_API_ROLES,
    ...PROVIDERS
};

CONSTANTS.RECOMMENDATION_PROFILES = {
    DEFAULT: 'default',
    BUDGET: 'budget',
    LUXURY: 'luxury',
    // LEISURE_DEFAULT: 'leisure-default',
    // LEISURE_BUDGET: 'leisure-budget',
    // LEISURE_LUXURY: 'leisure-luxury',
};

CONSTANTS.TRAVEL_POLICY_MODE = {
    DEFAULT: 'bypass',
    BYPASS: 'bypass',
    WARN: 'warn',
    BLOCK: 'block',
    BLOCK_ALL: 'block-all'
};

CONSTANTS.USER_TITLES = {
    MR: 'M',
    MS: 'M',
    DR: 'D',
    CHILD: 'C'
};

// initialized->pending_supplier->approved (good scenario!)
// initialized->pending_supplier->pending_support->aborted (deal was rejected by supplier)
// initialized->pending_supplier->pending_support->approved (deal was initially rejected by supplier and then we succeeded)
// initialized->pending_support->approved (manually booking succeeded)
// initialized->pending_support->cancelled (manually booking rejected)
// initialized->error (something went wrong, e.g. chooseprod had a different term than original deal)
// initialized->pending_supplier->approved->cancelled (support cancelled deal)

CONSTANTS.OP_METHOD = {
    NA: 'not_exists',                       // default 0-state of an enum
    ARBITRIP_AUTO: 'arbitrip-automatic',    // regular booking on arbitrip system - automatic flow
    ARBITRIP_MAN: 'arbitrip-manually'       // manually created with amsalem interface
};

CONSTANTS.COMBTAS_PUSH_TYPES = {
    INSERT: 'insert',
    UPDATE: 'update'
};

CONSTANTS.TRAVEL_BOOSTER_SYNC_TYPES = {
    CREATE: 'create',
    UPDATE: 'update',
    CANCEL_UPDATE: 'cancel_update',
    ISSUE_CLOSING_DOCUMENT: 'issue_closing_document',
    CANCEL_VOUCHER: 'cancel_voucher',
    GET: 'get',
};

CONSTANTS.TRAVEL_BOOSTER_CLIENTS = {
    TALMA: 'talma',
    OFAKIM: 'ofakim',
};

CONSTANTS.REQUEST_TICKETS_TYPES = {
    NA: 'not_exists',
    HOTEL_RESERVATION: 'hotel-reservation',
};

CONSTANTS.REQUEST_TICKETS_STATUS = { // This represents to outside world and to booking page specifically current state of reservation
    NA: 'not_exists',               // default 0-state of an enum
    PENDING: 'pending',             // We entered into reservation page for this deal
    APPROVED: 'approved',           // A request was approved by approver and reservation was confirmed by supplier
    REJECTED: 'rejected',           // A request was rejected by approver
    EXPIRED: 'expired' // a ticket request which has passed its expiration date and has not been approved/rejected/errrored
};

//Company settings

CONSTANTS.TIMING_EVENTS = {
    START_EVENT: 'START',
    END_EVENT: 'END'
};

CONSTANTS.FIELD_TYPE = {
    TEXT: "text",
    NUMBER: "number",
    DATETIME: "datetime",
    COMBOBOX: "combobox",
    LIST: "list",
    SELECTABLE: "selectable",
};


CONSTANTS.RULES_TYPE = {
    GEO: 'geo',
    PROPS: 'hotel-props',
    ID: 'hotel-id'
};

CONSTANTS.SEARCH_TYPES = {
    FAST: 'fast', // Obsolete
    NORMAL: 'normal', // Obsolete
    SHALLOW: 'shallow', // Obsolete
    SINGLE: 'single',
    TOP_X_HOTELS: 'top x hotels'
};

CONSTANTS.ERROR_TYPES = {
    CACHED_DEALS_NOT_FOUND: 'CACHED_DEALS_NOT_FOUND'
};

CONSTANTS.TRIP_STATUS = {
    INIT: 'initialized', // pre edit
    DRAFT: 'draft', // any edit / saved draft
    DISCARDED: 'discarded',
    CANCELED: 'canceled', // travel manager issued a cancel request
    PENDING: 'pending', // submitted but not yet approved or rejected
    APPROVED: 'approved',
    DECLINED: 'declined'
};

CONSTANTS.TRIP_PRE_APPROVAL_STATUS = {
    INITIALIZED: 'initialized', // trip hasn't been submitted yet
    NO_NEED: 'no_need', // no cost center pre approval defined for trip
    WAITING_FOR_MANUAL_SEND: 'waiting for manual sending', // exotic use case of manual sending
    SENT: 'sent',
    PRE_APPROVED: 'pre_approved', // don't change to approved
    PRE_REJECTED: 'pre_rejected' // don't change to rejected
};

CONSTANTS.TRIP_REQUEST_COMPONENT_TYPE = {
    FLIGHT: 'flight',
    ACCOMMODATION: 'accommodation',
    CAR: 'car'
};

CONSTANTS.FLIGHT_STATUS = {
    RESERVED: 'reserved', // for future use
    BOOKED: 'booked'
};

CONSTANTS.FLIGHT_SOURCES = {
    MANUAL: 'manual', // if we couldn't find flight in flight aware or if it
    FLIGHT_AWARE: 'flight_aware'
};

CONSTANTS.INBOUND_ACTIONS = {
    PRE_APPROVE_TRIP_REQUEST: 'pre_approve_trip_request', // pressing this link will try to pre approve request
    PRE_REJECT_TRIP_REQUEST: 'pre_reject_trip_request', // pressing this link will  try to post reject request
    POST_APPROVE_TRIP_REQUEST: 'approve_trip_request', // pressing this link will try to approve request
    POST_REJECT_TRIP_REQUEST: 'reject_trip_request' // pressing this link will  try to  reject request
};

CONSTANTS.PURPOSE_TYPES = {
    GENERAL: 'general',
    CONFERENCE: 'conference'
};

CONSTANTS.TRIP_EVENTS_LIFECYCLE = {
    SUBMITTED: 'submitted',
    CANCELED: CONSTANTS.TRIP_STATUS.CANCELED,
    FORWARDED: 'forwarded',
    DISCARDED: CONSTANTS.TRIP_STATUS.DISCARDED,
    PENDING: 'reset_to_pending',
    SENT_TO_APPROVAL: 'sent_to_approval',
    PRE_APPROVED: CONSTANTS.TRIP_PRE_APPROVAL_STATUS.PRE_APPROVED,
    PRE_REJECTED: CONSTANTS.TRIP_PRE_APPROVAL_STATUS.PRE_REJECTED,
    APPROVED: CONSTANTS.TRIP_STATUS.APPROVED,
    REJECTED: CONSTANTS.TRIP_STATUS.DECLINED
};

CONSTANTS.COMPANY_TYPE = {
    TRAVEL_AGENCY: 'travel_agency', // a company which provides travel services to other corporate companies
    CORPORATE: 'corporate' // regular company
};

CONSTANTS.MAIN_BUSINESS_LINE = {
    CORPORATIONS_DIRECT_INCLUDE_LEISURE: 'corporation_direct_include_leisure',
    CORPORATION_THROUGH_TMC: 'corporation_through_tmc',
    TRAVEL_AGENCY_BUSINESS_FOCUS: 'travel_agency_business_focus',
    TRAVEL_AGENCY_LEISURE__FOCUS: 'travel_agency_leisure_focus',
    TRAVEL_AGENCY_FREELANCERS: 'travel_agency_freelancers',
    B2C: 'b2c',
    NONE: ''
};

CONSTANTS.INVOICE_STATUS = { // if you update this, update the model ...
    NA: 'not_exists',
    PENDING: 'pending',
    RECEIVED: 'received',
    ERROR: 'error' // an irrecoverable error occurred
};

CONSTANTS.PAYMENT_INVOICE_STATUS = {
    OPEN: 'open', // iCount document status = 0
    CLOSED: 'closed', // iCount document status = 1
    PARTIALLY_CLOSED: 'partially_closed', // iCount document status = 2

    UNKNOWN: 'unknown', // iCount document status *HAS* value, but it's *NOT* 0, 1 nor 2
    NA: 'na' // iCount document status has *NO* value
};

CONSTANTS.PAYMENT_INVOICE_TYPE = {
    INVOICE: 'invoice', // iCount document type = "invoice"
    // RECEIPT: 'receipt', // iCount document type = "receipt"
    INVOICE_RECEIPT: 'invoice_receipt', // iCount document type = "invrec"
    REFUND: 'refund', // iCount document type = "refund"
    // DEAL: 'deal',
    // OFFER: 'offer',
    // TREC:'trec',
    // DELCERT: 'delcert',

    // UNKNOWN: 'unknown', // iCount document type *HAS* value, but it's *NOT* "invoice", "invrec", nor "refund" (or any of the above)
    // NA: 'na' // iCount document type has *NO* value
}

CONSTANTS.HTTP_STATUS_CODE = { // https://en.wikipedia.org/wiki/List_of_HTTP_status_codes
    '_200_': 200, // OK, Standard response for successful HTTP requests. The actual response will depend on the request method used. In a GET request, the response will contain an entity corresponding to the requested resource. In a POST request, the response will contain an entity describing or containing the result of the actio
    '_201_': 201, // OK, created
    '_204_': 204, // The HTTP 204 No Content successful response status code indicates that a request has succeeded, but the client doesn't need to navigate away from its current page. A 204 response is cacheable by default, and an ETag header is included in such cases.
    '_401_': 401, // Authentication error
    '_403_': 403, // Authentication error
    '_404_': 404, // User not found
    '_500_': 500, // Internal Server Error
};

CONSTANTS.TRADE_CONFIG_TYPES = {
    LEGACY: 'legacy',
    AGENCY: 'agency'
}

CONSTANTS.BOOKABLE_SUPPLIERS_MODE = {
    RESELLER: 'reseller', // We use arbitrip credentials and manipulate net rate. Source would be arbitrip.
    PIPE: 'pipe', // We use agency credentials and don't manipulate net rate
    AFFILIATE: 'affiliate' // We are just collecting payback money
};

CONSTANTS.NETWORK_ERRORS = {
    ECONNABORTED: 'ECONNABORTED', // Sometimes used when request times out
    ENOTFOUND: 'ENOTFOUND',
};

CONSTANTS.MOCK_FLAGS = {
    NO_MOCK: 'no_mock',
    MOCK_SUCCESS: 'mock_success',
    MOCK_ERROR_1: 'mock_error_1',
    MOCK_ERROR_2: 'mock_error_2'
};

CONSTANTS.PTC_CODE_TO_STATUS = {
    XL: CONSTANTS.RESERVATION_STATUS.CANCELLED,
    OK: CONSTANTS.RESERVATION_STATUS.APPROVED,
    OT: CONSTANTS.RESERVATION_STATUS.APPROVED,
    'OK*': CONSTANTS.RESERVATION_STATUS.PENDING_SUPPLIER,
    RQ: CONSTANTS.RESERVATION_STATUS.PENDING_SUPPORT,
    SO: CONSTANTS.RESERVATION_STATUS.PENDING_SUPPORT
};

CONSTANTS.NOTIFICATION_EVENTS = {
    BOOKING_CONFIRM: 'booking_confirm',
    BOOKING_CANCEL: 'booking_cancel',
    USER_MANAGEMENT: 'user_management',
    SEARCH: 'search',
    HOT_SEARCH: 'hot_search',
    ENDING_FREE_CANCELLATION: 'ending_free_cancellation'
}

CONSTANTS.PTC_CURRENCY_CODE_TO_CURRENCY = {
    1: 'USD',
    2: 'GBP',
    3: 'SEK',
    4: 'BF',
    5: 'CHF',
    6: 'CAD',
    7: 'FM',
    8: 'DM',
    9: 'NZD',
    10: 'IEP',
    12: 'DKK',
    13: 'SGD',
    14: 'HKD',
    15: 'NLG',
    16: 'LT',
    17: 'ZAR',
    18: 'AUD',
    19: 'PS',
    20: 'EUR',
    21: 'AS',
    22: 'FF',
    28: 'NOK',
    30: 'JPY',
    36: 'ESC',
    61: 'CZK',
    62: 'DS',
    74: 'CYP'
};

CONSTANTS.PTC_DATE_FORMAT = 'YYYYMMDD';
CONSTANTS.FREE_CANCELLATION_TILL_DATE_FORMAT = 'MMM D, YYYY'; // TODO! Move to a const file that is shared with client

CONSTANTS.MAGIC_IDS = {
    REPORT_CONTRACT_ALL: '5b30cb8b2cf3686778d592d1'
};

CONSTANTS.HOTEL_MATRIX_SOURCE = {
    OPS: 'ops',
    CORE: 'core',
    OPS_BKP: 'ops_bkp'
};

CONSTANTS.ENVIRONMENTS = {
    LOC: 'loc',
    DEV: 'dev',
    PROD: 'prod'
};

CONSTANTS.ERROR = {
    NO_COMPANY: 'NO_COMPANY',
    USER_NOT_FOUND: 'USER_NOT_FOUND',
    DOMAIN_NOT_ALLOWED: 'DOMAIN_NOT_ALLOWED',
    NO_DOMAINS: 'NO_DOMAINS',
};

CONSTANTS.MIN_TRUSTYOU_REVIEW_COUNT = 10;

CONSTANTS.PROMISE_STATUS = {
    REJECTED: 'rejected',
    FULFILLED: 'fulfilled'
};

CONSTANTS.CACHE_EXPIRATION_MINUTES = 5 * 60;

CONSTANTS.API_SERVICE_MODES = {
    FULL: 'full',
    OPS: COMMON_CONSTANTS.API_SERVICES.OPS,
    HOTELS: COMMON_CONSTANTS.API_SERVICES.HOTELS,
    PARTNERS: COMMON_CONSTANTS.API_SERVICES.PARTNERS,
};

CONSTANTS.REDIS_TTL_ERROR = {
    // see: https://redis.io/commands/ttl
    NO_EXPIRATION: -1,
    NO_KEY: -2
};

CONSTANTS.MANUAL_SUPPLIER_PAYMENT_TYPES = {
    PAY_NOW_CREDIT_CARD: 'pay_now_credit_card',
    FUTURE_CREDIT_CARD: 'future_credit_card',
    BANK_TRANSFER: 'bank_transfer'
};

CONSTANTS.AUTH0_STRATEGIES = {
    BUSINESS: 'business',
    PARTNERS: 'partners',
    JWT_PARTNERS: 'jwt-partners',
    GLOBAL: 'global' // Used for impersonation scenatios
};
CONSTANTS.AUTH0_STRATEGIES_DEFAULT = CONSTANTS.AUTH0_STRATEGIES.BUSINESS;

CONSTANTS.JWT_MAC_ALGORITHMS = {
    NONE: 'none',
    HS256: 'HS256', // Default
    HS384: 'HS384',
    HS512: 'HS512',
    RS256: 'RS256',
    RS384: 'RS384',
    RS512: 'RS512',
    ES256: 'ES256',
    ES384: 'ES384',
    ES512: 'ES512',
};

CONSTANTS.GENERIC_ERROR_MESSAGE = {
    INVALID_CREDENTIALS: 'Invalid Credentials', // user name password doesn't match
    INVALID_PARAMETER: 'Invalid Parameter',  // A parameter has a wrong format or values
    INVALID_TOKEN: 'Invalid Token', // Token bearer is invalid
    MISSING_REQUIRED_PARAMETERS: 'Missing Required Parameter(s)',
    NOT_AUTHENTICATED: 'Not Authenticated',
}

CONSTANTS.BEARER_PREFIX = 'Bearer';

CONSTANTS.MAGIC_DATES = {
    FAR_FAR_IN_THE_FUTURE: new Date(8640000000000).toUTCString() // Sat, 17 Oct 2243 00:00:00 UTC
}
