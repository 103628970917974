const getDestinationObject = (destination, hotel) => {
    if (!destination && !hotel) {
        return;
    }

    if (destination) {
        let { place } = destination;

        if (!place) {
            // if no place object and hotel id on destination root
            // try to copy the root
            if (destination._id || destination.id || destination.hotel_id || destination.place_id) {
                place = destination;
            } else {
                console.error('getDestinationObject::: no place provided in destination');
                return;
            }
        }

        return {
            ...(place._id && { hotel_id: place._id }),
            ...(place.id && { hotel_id: place.id }),
            ...(place.hotel_id && { hotel_id: place.hotel_id }),
            ...(place.place_id && { place_id: place.place_id }),
            ...(place.title && { name: place.title }),
            ...(place.name && { name: place.name }),
            ...(place.address && { address: place.address }),
            ...(place.country && { country: place.country }),
            ...(place.country_code && { country_code: place.country_code }),
            ...(place.city && { city: place.city }),
            ...(place.zip && { zip: place.zip }),
            ...(place.category && { autocomplete_category: place.category }), // mobile
            ...(destination.autocomplete_category && { autocomplete_category: destination.autocomplete_category }), // desktop
            ...(destination.autocomplete_index && { autocomplete_index: destination.autocomplete_index }) // desktop
        };
    } else {
        return {
            hotel_id: hotel.id,
            name: hotel.name,
            address: hotel.address,
            country: hotel.country,
            country_code: hotel.country_code,
            city: hotel.city,
            zip: hotel.zip
        };
    }
};

module.exports = {
    getDestinationObject
};