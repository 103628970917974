import React from 'react';

import FiltersActions from '../../../../actions/FiltersActions';

import FiltersConstants from '../../../../constants/FiltersConstants';
import Analytics from 'arbitrip-common/client/analytics';

class ResetAllFilter extends React.Component {
	constructor(props) {
		super(props);

		this.onClick = this.onClick.bind(this);
	}

	onClick() {
		const { profile } = this.props;

		Analytics.actions.interactions.filteredResults(profile, FiltersConstants.FILTER_METHOD.RESET_ALL);
		FiltersActions.resetAllFilters();
		setTimeout(function () {
			FiltersActions.resetAllFilters();
		}, 10);
	}

	render() {
		return (
			<div className="filter" style={{ textAlign: 'center' }}>
				{/* <label className="filter-title" htmlFor="reset-all-filters">Reset All</label> */}
				<div className="input-container">
					<button className="reset-all-filters" onClick={this.onClick}>
						Reset All
					</button>
				</div>
			</div>
		);
	}
}

export default ResetAllFilter;
