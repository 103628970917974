import createClass from 'create-react-class';
import Currencies from '../../../../utils/Currencies';
import Config from 'arbitrip-common/client/utils/Config';
import { Tooltip } from '@mui/material';

const DynamicPricingContainer = createClass({
	displayName: 'DealPrice',

	getDynamicPricingTooltipContent(dynamicPricing) {
		if (!dynamicPricing.calculated_price && dynamicPricing.calculated_price !== 0) {
			return (
				<div className="agent-tooltip dynamic">
					{dynamicPricing.dynamic_pricing_group_id && (
						<div>
							<div className="agent-tooltip-title">Dynamic price Group ID</div>
							<div className="agent-tooltip">{dynamicPricing.dynamic_pricing_group_id}</div>
						</div>
					)}
					{dynamicPricing.message && (
						<div>
							<div className="agent-tooltip-title">Dynamic price was not applied</div>
							<div className="agent-tooltip">{dynamicPricing.message}</div>
						</div>
					)}
				</div>
			);
		}
		return (
			<div className="agent-tooltip dynamic">
				{dynamicPricing.isClone && (
					<div className="agent-tooltip-item">
						<div className="agent-tooltip">CLONE</div>
					</div>
				)}
				<div className="agent-tooltip-item">
					<div className="agent-tooltip">
						Calculated based on{' '}
						{`${dynamicPricing.is_base_on_expedia ? 'Expedia marketing' : 'Average group'}`} price
					</div>
				</div>
				<div className="agent-tooltip-item">
					<div className="agent-tooltip-title">Original margin</div>
					<div className="agent-tooltip-value">{dynamicPricing.original_margin?.toFixed(2)}%</div>
				</div>
				<div className="agent-tooltip-item">
					<div className="agent-tooltip-title">Dynamic margin</div>
					<div className="agent-tooltip-value">{dynamicPricing.effective_margin?.toFixed(2)}%</div>
				</div>
				<div className="agent-tooltip-item">
					<div className="agent-tooltip-title">Profite factor</div>
					<div className="agent-tooltip-value">{dynamicPricing.profit_factor?.toFixed(2)}</div>
				</div>
				{dynamicPricing.is_used_max_margin && (
					<div className="agent-tooltip">
						Dynamic price cutoff with max dynamic margin: {dynamicPricing.max_margin?.toFixed(2)}%
					</div>
				)}
				<div className="agent-tooltip-item">
					<div className="agent-tooltip">------------</div>
				</div>
				<div className="agent-tooltip-item">
					<div className="agent-tooltip-title">Static margin profit absolute</div>
					<div className="agent-tooltip-value">
						{Currencies.getPriceByPrecision(dynamicPricing.profite_static_margin, 2)}
					</div>
				</div>
				<div className="agent-tooltip-item">
					<div className="agent-tooltip-title">Dynamic pricing profit absolute</div>
					<div className="agent-tooltip-value">
						{Currencies.getPriceByPrecision(dynamicPricing.profite_absolute, 2)}
					</div>
				</div>
				<div className="agent-tooltip-item">
					<div className="agent-tooltip-title">Profite compare to static margin</div>
					<div className="agent-tooltip-value">
						{dynamicPricing.profite_compare_to_static_margin?.toFixed(2)}
					</div>
				</div>
				<div className="agent-tooltip-item">
					<div className="agent-tooltip">------------</div>
				</div>
				<div className="agent-tooltip-item">
					<div className="agent-tooltip-title">Expedia marketing price per night</div>
					<div className="agent-tooltip-value">
						{Currencies.getPriceByPrecision(dynamicPricing.group_marketing_price_per_night, 2)}
					</div>
				</div>
				<div className="agent-tooltip-item">
					<div className="agent-tooltip-title">Dynamic price per night</div>
					<div className="agent-tooltip-value">
						{Currencies.getPriceByPrecision(dynamicPricing.calculated_price_per_night, 2)}
					</div>
				</div>
				<div className="agent-tooltip-item">
					<div className="agent-tooltip-title">Old price per night</div>
					<div className="agent-tooltip-value">
						{Currencies.getPriceByPrecision(dynamicPricing.static_margin_price_per_night, 2)}
					</div>
				</div>
				<div className="agent-tooltip-item">
					<div className="agent-tooltip-title">Supplier price per night</div>
					<div className="agent-tooltip-value">
						{Currencies.getPriceByPrecision(dynamicPricing.supplier_net_rate_per_night, 2)}
					</div>
				</div>
				<div className="agent-tooltip-item">
					<div className="agent-tooltip">------------</div>
				</div>
				<div className="agent-tooltip-item">
					<div className="agent-tooltip-title">Expedia marketing price</div>
					<div className="agent-tooltip-value">
						{Currencies.getPriceByPrecision(dynamicPricing.group_marketing_price, 2)}
					</div>
				</div>
				<div className="agent-tooltip-item">
					<div className="agent-tooltip-title">Dynamic price</div>
					<div className="agent-tooltip-value">
						{Currencies.getPriceByPrecision(dynamicPricing.calculated_price, 2)}
					</div>
				</div>
				<div className="agent-tooltip-item">
					<div className="agent-tooltip-title">Old price</div>
					<div className="agent-tooltip-value">
						{Currencies.getPriceByPrecision(dynamicPricing.static_margin_price, 2)}
					</div>
				</div>
				<div className="agent-tooltip-item">
					<div className="agent-tooltip-title">Supplier price</div>
					<div className="agent-tooltip-value">
						{Currencies.getPriceByPrecision(dynamicPricing.supplier_net_rate, 2)}
					</div>
				</div>

				{dynamicPricing.message && (
					<div>
						<div className="agent-tooltip-title">Dynamic price was not applied</div>
						<div className="agent-tooltip">{dynamicPricing.message}</div>
					</div>
				)}
				{dynamicPricing.dynamic_pricing_group_id && (
					<div>
						<div className="agent-tooltip-title">Dynamic price Group ID</div>
						<div className="agent-tooltip">{dynamicPricing.dynamic_pricing_group_id}</div>
					</div>
				)}
			</div>
		);
	},

	render() {
		const { dynamicPricing, profile, currency } = this.props;
		const is_show = Config.dev_mode || profile?.is_arbi_tester;
		if (!dynamicPricing || !is_show) {
			return null;
		}

		const _profile = profile || {
			display_currency: currency,
			display_currency_exchange_rate: 1,
		};

		const prefix = dynamicPricing.total_price ? 'old ' : '';
		const price = dynamicPricing.total_price ? dynamicPricing.static_margin_price_per_night : 0;

		return (
			<Tooltip title={this.getDynamicPricingTooltipContent(dynamicPricing)}>
				{dynamicPricing.calculated_price ? (
					<div>
						{prefix} (
						{Currencies.getPriceWithDisplayCurrencyByPrecision(
							price,
							_profile.display_currency_exchange_rate,
							_profile.display_currency,
							0,
						)}
						)
					</div>
				) : (
					<div>Not calculated</div>
				)}
			</Tooltip>
		);
	},
});
export default DynamicPricingContainer;
