import _ from 'lodash';
// import moment from 'moment';
import React, { useMemo } from 'react';
import { Button } from '@mui/material';
import BookingField from './BookingDrawerField.react';
import Currencies from 'arbitrip-common/general/utils/Currencies';
import { ReactComponent as PointsIcon } from '../../../img/search_page/loyalty/present-plain.svg';
import { PAYMENT_TYPE } from 'arbitrip-common/general/constants';
import Points from 'arbitrip-common/general/utils/Points';

const BookingDrawerPaymentDetails = ({ booking, retroactivePointsUsageEnabled, openRetroactiveModal, profile }) => {
	if (!booking || !booking.payment) {
		return null;
	}
	const { deal, payment } = booking;

	const credit_l4_digit = useMemo(() => {
		return _.get(payment, 'payment_method.credit_l4_digit', '');
	}, [payment]);

	const payment_type_value = useMemo(() => {
		if (_.get(payment, 'payment_type') === PAYMENT_TYPE.COMPANY_POLICY) {
			return 'Account';
		}

		if (_.get(payment, 'payment_type') === PAYMENT_TYPE.CREDIT_CARD) {
			return _.startCase(payment.payment_type);
		}

		return _.get(payment, 'payment_type') && _.startCase(payment.payment_type);
	}, [payment]);

	const points_used = _.get(payment, 'loyalty.points');
	const points_used_value = _.get(payment, 'loyalty.points_in_currency');
	const points_used_currency = _.get(payment, 'loyalty.currency');

	const payment_sum = _.get(payment, 'sum');
	const total_price = Points.extractTotalPrice({ profile, reservation: booking });
	const total_price_adjusted = payment_sum || total_price - (points_used_value || 0);
	const has_installments =
		payment.payment_type === PAYMENT_TYPE.CREDIT_CARD &&
		payment.installment &&
		payment.installment.num_installments > 1;

	const show_points = points_used > 0 || retroactivePointsUsageEnabled;

	return (
		<BookingField
			fieldTitle="Payment Details"
			fieldValue={
				<React.Fragment>
					<div className="payment-details">
						{payment.payment_type && <div>Payment Type</div>}
						{/* {(payment.sum > 0) && <div>Sum</div>} */}
						{total_price_adjusted > 0 && <div>Sum</div>}
					</div>
					<div className="payment-details bold">
						{payment.payment_type && (
							<div>
								{payment_type_value}
								{credit_l4_digit && ` (${credit_l4_digit})`}
							</div>
						)}
						{/* {(payment.sum > 0) && <div>{Currencies.getPriceWithDisplayCurrencyByPrecision(payment.sum, 1, payment.currency, 2)}</div>} */}
						{total_price_adjusted > 0 && (
							<div>
								{Currencies.getPriceWithDisplayCurrencyByPrecision(
									total_price_adjusted,
									1,
									payment.currency,
									2,
								)}
							</div>
						)}
					</div>
					<div className="payment-details bold">
						{has_installments && <div>Number of Installments</div>}
						{has_installments && <div>{payment.installment.num_installments}</div>}
					</div>
					<div className="payment-details">
						{show_points && <div>Points Used</div>}
						{show_points && <div>Points Value</div>}
					</div>
					<div className="payment-details bold">
						{show_points && <div>{points_used}</div>}
						{show_points && (
							<div>
								{Currencies.getPriceWithDisplayCurrencyByPrecision(
									points_used_value,
									1,
									points_used_currency,
									2,
								)}
							</div>
						)}
						{show_points &&
							(retroactivePointsUsageEnabled ? (
								<div className="booking-points-update">
									<Button variant="outlined" fullWidth onClick={openRetroactiveModal}>
										Update Points
									</Button>
								</div>
							) : (
								<div className="booking-points-icon">
									<PointsIcon />
								</div>
							))}
					</div>
				</React.Fragment>
			}
		/>
	);
};

export default BookingDrawerPaymentDetails;
