import AppDispatcher from '../dispatcher/AppDispatcher';
import FiltersConstants from '../constants/FiltersConstants';

let FiltersActions = {
	getMapBoxData: function (data) {
		AppDispatcher.handleAction({
			actionType: FiltersConstants.RECEIVE_MAP_BOX_DATA,
			data: data, // ???
		});
	},

	updateCurrencies: function () {
		AppDispatcher.handleAction({
			actionType: FiltersConstants.UPDATE_CURRENCIES,
		});
	},

	getPriceFilterData: function (data) {
		AppDispatcher.handleAction({
			actionType: FiltersConstants.RECEIVE_PRICE_FILTER_DATA,
			data: data, // ???
		});
	},

	getBusinessTravelersData: function (data) {
		AppDispatcher.handleAction({
			actionType: FiltersConstants.RECEIVE_BUSINESS_TRAVELERS_DATA,
			data: data, // ???
		});
	},

	getHotelClassData: function (data) {
		AppDispatcher.handleAction({
			actionType: FiltersConstants.RECEIVE_HOTEL_CLASS_DATA,
			data: data, // ???
		});
	},

	getReviewScoreData: function (data) {
		AppDispatcher.handleAction({
			actionType: FiltersConstants.RECEIVE_REVIEW_SCORE_DATA,
			data: data, // ???
		});
	},

	getStarsData: function (data) {
		AppDispatcher.handleAction({
			actionType: FiltersConstants.RECEIVE_STARS_FILTER_DATA,
			data: data, // ???
		});
	},

	getArbitripScoreData: function (data) {
		AppDispatcher.handleAction({
			actionType: FiltersConstants.RECEIVE_ARBITRIP_SCORE_FILTER_DATA,
			data: data, // ???
		});
	},

	getAmenitiesData: function (data) {
		AppDispatcher.handleAction({
			actionType: FiltersConstants.RECEIVE_AMENITIES_FILTER_DATA,
			data: data, // ???
		});
	},

	resetAllFilters: function () {
		AppDispatcher.handleAction({
			actionType: FiltersConstants.CLEAR_FILTERS_AND_APPLY,
		});
	},

	filterByRegion: function (region) {},

	filterByDistance: function (distance) {
		AppDispatcher.handleAction({
			actionType: FiltersConstants.FILTER_BY_DISTANCE,
			data: distance,
		});
	},

	filterByNameContains: function (nameContains) {
		AppDispatcher.handleAction({
			actionType: FiltersConstants.FILTER_BY_NAME_CONTAINS,
			data: nameContains,
		});
	},

	filterByDebugContains: function (mode, debugField, debugValue, reverseLogic) {
		AppDispatcher.handleAction({
			actionType: FiltersConstants.FILTER_BY_DEBUG_CONTAINS,
			data: {
				mode: mode,
				field: debugField,
				value: debugValue,
				reverseLogic: reverseLogic,
			},
		});
	},

	filterByPrice: function (price, currency) {
		AppDispatcher.handleAction({
			actionType: FiltersConstants.FILTER_BY_PRICE,
			data: {
				price,
				currency,
			},
		});
	},

	filterByTopX: function (x) {
		AppDispatcher.handleAction({
			actionType: FiltersConstants.FILTER_BY_TOP_X,
			data: x,
		});
	},

	filterByStars: function (stars, value) {
		AppDispatcher.handleAction({
			actionType: FiltersConstants.FILTER_BY_STARS,
			data: {
				stars: stars,
				value: value,
			},
		});
	},

	filterByStarsOnly: function (stars) {
		AppDispatcher.handleAction({
			actionType: FiltersConstants.FILTER_BY_STARS_ONLY,
			data: {
				stars: stars,
			},
		});
	},

	resetStarsFilter: function () {
		AppDispatcher.handleAction({
			actionType: FiltersConstants.RESET_STARS_FILTER,
		});
	},

	filterByReview: function (review, value) {
		AppDispatcher.handleAction({
			actionType: FiltersConstants.FILTER_BY_REVIEW,
			data: {
				review: review,
				value: value,
			},
		});
	},

	filterByReviewOnly: function (review) {
		AppDispatcher.handleAction({
			actionType: FiltersConstants.FILTER_BY_REVIEW_ONLY,
			data: {
				review: review,
			},
		});
	},

	resetReviewFilter: function () {
		AppDispatcher.handleAction({
			actionType: FiltersConstants.RESET_REVIEW_FILTER,
		});
	},

	filterBySanitizationStandards: function (value) {
		AppDispatcher.handleAction({
			actionType: FiltersConstants.FILTER_BY_SANITIZATION_STANDARDS,
			data: value,
		});
	},

	filterByMargin: function (margin, value) {
		AppDispatcher.handleAction({
			actionType: FiltersConstants.FILTER_BY_MARGIN,
			data: {
				margin,
				value,
			},
		});
	},

	filterByMarginOnly: function (margin) {
		AppDispatcher.handleAction({
			actionType: FiltersConstants.FILTER_BY_MARGIN_ONLY,
			data: {
				margin,
			},
		});
	},

	resetMarginsFilter: function () {
		AppDispatcher.handleAction({
			actionType: FiltersConstants.RESET_MARGINS_FILTER,
		});
	},

	filterByPreferredHotels: function (value) {
		AppDispatcher.handleAction({
			actionType: FiltersConstants.FILTER_BY_PREFERRED_HOTELS,
			data: value,
		});
	},

	filterByCategories: function (category, value) {
		AppDispatcher.handleAction({
			actionType: FiltersConstants.FILTER_BY_CATEGORIES,
			data: { category, value },
		});
	},

	filterByCategoriesOnly: function (category) {
		AppDispatcher.handleAction({
			actionType: FiltersConstants.FILTER_BY_CATEGORIES_ONLY,
			data: { category },
		});
	},

	resetCategoriesFilter: function () {
		AppDispatcher.handleAction({
			actionType: FiltersConstants.RESET_CATEGORIES_FILTER,
		});
	},

	//////////

	filterByNeighborhoods: function (neighborhood, value) {
		AppDispatcher.handleAction({
			actionType: FiltersConstants.FILTER_BY_NEIGHBORHOODS,
			data: { neighborhood, value },
		});
	},

	filterByNeighborhoodsOnly: function (neighborhood) {
		AppDispatcher.handleAction({
			actionType: FiltersConstants.FILTER_BY_NEIGHBORHOODS_ONLY,
			data: { neighborhood },
		});
	},

	resetNeighborhoodsFilter: function () {
		AppDispatcher.handleAction({
			actionType: FiltersConstants.RESET_NEIGHBORHOODS_FILTER,
		});
	},

	//////////

	filterByAmenity: function (amenity, value) {
		AppDispatcher.handleAction({
			actionType: FiltersConstants.FILTER_BY_AMENITY,
			data: { amenity, value },
		});
	},

	filterByAmenityOnly: function (amenity) {
		AppDispatcher.handleAction({
			actionType: FiltersConstants.FILTER_BY_AMENITY_ONLY,
			data: { amenity },
		});
	},

	resetAmenitiesFilter: function () {
		AppDispatcher.handleAction({
			actionType: FiltersConstants.RESET_AMENITIES_FILTER,
		});
	},

	filterByInPolicy: function (value) {
		AppDispatcher.handleAction({
			actionType: FiltersConstants.FILTER_BY_IN_POLICY,
			data: value,
		});
	},

	filterByAvailability: function (value) {
		AppDispatcher.handleAction({
			actionType: FiltersConstants.FILTER_BY_AVAILABILITY,
			data: value,
		});
	},

	resetDealsFilters: function () {
		AppDispatcher.handleAction({
			actionType: FiltersConstants.RESET_HOTEL_DEALS_FILTERS,
		});
	},

	filterDealsByBreakfastOnly: function (value) {
		AppDispatcher.handleAction({
			actionType: FiltersConstants.RECEIVE_DEALS_BREAKFAST_ONLY_FILTER_DATA,
			data: value,
		});
	},

	filterDealsByHalfBoardOnly: function (value) {
		AppDispatcher.handleAction({
			actionType: FiltersConstants.RECEIVE_DEALS_HALF_BOARD_ONLY_FILTER_DATA,
			data: value,
		});
	},

	filterDealsByAllInclusiveOnly: function (value) {
		AppDispatcher.handleAction({
			actionType: FiltersConstants.RECEIVE_DEALS_ALL_INCLUSIVE_ONLY_FILTER_DATA,
			data: value,
		});
	},

	filterDealsByCancellationOnly: function (value) {
		AppDispatcher.handleAction({
			actionType: FiltersConstants.RECEIVE_DEALS_FREE_CANCEL_ONLY_FILTER_DATA,
			data: value,
		});
	},

	filterDealsByInPolicyOnly: function (value) {
		AppDispatcher.handleAction({
			actionType: FiltersConstants.RECEIVE_DEALS_IN_POLICY_ONLY_FILTER_DATA,
			data: value,
		});
	},

	filterDealsByPostPayOnly: function (value) {
		AppDispatcher.handleAction({
			actionType: FiltersConstants.RECEIVE_DEALS_POST_PAY_FILTER_DATA,
			data: value,
		});
	},
	filterDealsByNegotiatedRateOnly: function (value) {
		AppDispatcher.handleAction({
			actionType: FiltersConstants.RECEIVE_DEALS_NEGOTIATED_RATE_FILTER_DATA,
			data: value,
		});
	},

	filterByAll: function () {
		AppDispatcher.handleAction({
			actionType: FiltersConstants.FILTER_BY_ALL,
		});
	},
};

export default FiltersActions;
