const keyMirror = require('keymirror');

export const EVENTS = keyMirror({
    OPEN_MENU: null,
    CLOSE_MENU: null,
    CHANGED_DISPLAY_CURRENCY: null,
    CHANGED_LANGUAGE: null,
    LIVE_CHAT: null,
    SEND_EMAIL_TO_SUPPORT_CLICKED: null,
    CONTACT_PHONE_CLICKED: null,
    SEARCH_CLICKED: null,
    BOOKINGS_CLICKED: null,
    LOGO_CLICKED: null,
    MOBILE_APP_INSTALLED: null
});