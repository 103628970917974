/* eslint-disable react/prop-types, react/jsx-handler-names */
import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';

import Select from 'react-select';
import Ajax from '../../../../../utils/Ajax';

import { FormControl, Typography, TextField, Paper, MenuItem, Chip } from '@mui/material';

import CancelIcon from '@mui/icons-material/Cancel';

import classNames from 'classnames';
import _ from 'lodash';

import Validator from 'arbitrip-common/client/utils/Validator';

const EMAIL_AUTOCOMPLETE = 'email_autocomplete';
const SHOW_LOADER = false;

const styles = (theme) => ({
	root: {
		flexGrow: 1,
		width: '100%',
	},
	input: {
		display: 'flex',
		padding: 0,
		marginLeft: 10,
		marginBottom: 1,
		height: 'auto',
	},
	valueContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		flex: 1,
		alignItems: 'center',
		overflow: 'hidden',
		minHeight: 40,
	},
	noOptionsMessage: {
		padding: `8px 16px`,
	},
	singleValue: {
		fontSize: 14,
	},
	placeholder: {
		position: 'absolute',
		left: 12,
		right: 70,
	},
	loader: {
		position: 'absolute',
		top: 8,
		right: 48,
	},
	paper: {
		position: 'absolute',
		zIndex: 1,
		marginTop: 8,
		left: 0,
		right: 0,
	},
	divider: {
		height: 16,
	},
	busyLoader: {
		position: 'absolute',
		top: 18,
		right: 0,
	},
	label: {
		color: 'rgba(0, 0, 0, 0.54)',
		padding: 0,
		fontSize: '1rem',
		fontFamily: 'Lato, Roboto, sans-serif',
		lineHeight: 1,
		transform: 'translate(0, 1.5px) scale(0.75)',
		transformOrigin: 'top left',
		transition: 'color 200ms',
	},
	labelHover: {
		color: theme.palette.primary.main,
	},
	error: {
		color: theme.palette.error.main,
	},
	chip: {
		margin: 2,
		borderRadius: 4,
		backgroundColor: '#fff',
		border: 'solid 1px #e0e0e0',
	},
	chipFocused: {
		backgroundColor: theme.palette.grey[400],
	},
});

const InputComponent = React.forwardRef((props, ref) => <div ref={ref} {...props} />);

class BasketAutocomplete extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			searchInput: '',
			value: props.value || null,
			data: [],
			busy: false,
			hoverLabel: false,
			openMenu: false,
			showError: false,
		};

		this.setError = this.setError.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.onInputKeyDown = this.onInputKeyDown.bind(this);
		this.Control = this.Control.bind(this);
		this.ValueContainer = this.ValueContainer.bind(this);
		this.getData = this.getData.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.toggleFocus = this.toggleFocus.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.updateData = this.updateData.bind(this);
	}

	getValueLabel(user) {
		return user.full_name + (user.email ? ' (' + user.email + ')' : '');
	}

	handleChange(value) {
		let { onUpdate } = this.props;

		this.hideMenu();

		this.setState({ value, searchInput: '' });

		if (_.isFunction(onUpdate)) {
			onUpdate(value);
		}
	}

	handleInputChange(e, { action }) {
		if (e === '' && action === 'input-change') {
			let { onUpdate } = this.props;

			this.setState({ searchInput: '' });
			this.setError(false);
			if (_.isFunction(onUpdate)) {
				onUpdate(null);
			}
			return;
		}

		if (action === 'input-change') {
			this.setState({ openMenu: true });
		}

		if (action !== 'menu-close' && action !== 'input-blur' && action !== 'set-value') {
			this.setState({ searchInput: e });
		}
	}

	onInputKeyDown(event) {
		let { value, searchInput } = this.state;

		switch (event.keyCode) {
			case 13: // ENTER
			case 32: // SPACE
			case 9: // TAB
				if (Validator.validateEmailAddress(searchInput)) {
					this.setError(false);
					event.preventDefault();
					value.push({ label: searchInput, value: searchInput });

					this.handleChange(value);
				} else if (searchInput !== '') {
					this.setError(true);
				}

				break;
			default: // Do nothing
		}
	}

	setError(isError) {
		let { onError } = this.props;

		this.setState({ showError: isError });

		if (_.isFunction(onError)) {
			onError(isError);
		}
	}

	hideMenu() {
		this.setState({ openMenu: false });
	}

	getData(event) {
		let { disableAutoComplete } = this.props;
		if (disableAutoComplete) {
			return;
		}

		let { agentContractId, agentClientCompanyId } = this.state;
		let value = event.target.value;

		if (_.isEmpty(value)) {
			console.log('There is no value in search field');
			this.updateData([]);
			return;
		}

		this.setState({ busy: true });

		Ajax.companyEmployeesLookup(value, null, agentContractId, agentClientCompanyId)
			.done((data) => {
				console.log('Travelers AutoComplete Search Results', data);
				if (data) {
					this.updateData(data);
				} else {
					this.updateData([]);
				}
			})
			.fail((err) => {
				console.log('Travelers AutoComplete Search Error', err);
			})
			.always(() => {
				this.setState({
					busy: false,
				});
			});
	}

	updateData(data) {
		if (!Array.isArray(data)) {
			return false;
		}

		let { value } = this.state;

		let filtered = data.map((user) => {
			return { label: this.getValueLabel(user), value: user.email };
		});

		if (value && _.isArray(value)) {
			filtered = filtered.filter((item) => {
				return !value.some((el) => {
					return el.value === item.value;
				});
			});
		}

		this.setState({
			data: filtered,
		});
	}

	Control(props) {
		let { showError } = this.state;

		const {
			children,
			innerProps,
			innerRef,
			selectProps: { classes, TextFieldProps },
		} = props;

		return (
			<TextField
				onChange={this.getData}
				fullWidth
				error={showError}
				InputProps={{
					inputComponent: InputComponent,
					inputProps: {
						className: classes.input,
						ref: innerRef,
						children,
						...innerProps,
					},
				}}
				{...TextFieldProps}
			/>
		);
	}

	NoOptionsMessage(props) {
		return (
			<Typography
				color="textSecondary"
				className={props.selectProps.classes.noOptionsMessage}
				{...props.innerProps}
			>
				{props.children}
			</Typography>
		);
	}

	Option(props) {
		return (
			<MenuItem
				selected={props.isFocused}
				component="div"
				style={{
					fontWeight: props.isSelected ? 500 : 400,
				}}
				{...props.innerProps}
			>
				{props.children}
			</MenuItem>
		);
	}

	Placeholder(props) {
		return (
			<Typography color="textSecondary" className={props.selectProps.classes.placeholder} {...props.innerProps}>
				{props.children}
			</Typography>
		);
	}

	MultiValue(props) {
		return (
			<Chip
				tabIndex={-1}
				label={props.children}
				className={classNames(props.selectProps.classes.chip, {
					[props.selectProps.classes.chipFocused]: props.isFocused,
				})}
				onDelete={props.removeProps.onClick}
				deleteIcon={<CancelIcon {...props.removeProps} fontSize="small" />}
			/>
		);
	}

	ValueContainer(props) {
		let { busy } = this.state;

		return (
			<div className={props.selectProps.classes.valueContainer}>
				{props.children}
				{SHOW_LOADER && busy && (
					<div className={props.selectProps.classes.loader}>
						<i className="fa fa-spin fa-spinner busy-indicator" />
					</div>
				)}
			</div>
		);
	}

	Menu(props) {
		return (
			<Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
				{props.children}
			</Paper>
		);
	}

	onBlur() {
		let { value, searchInput } = this.state;

		if (Validator.validateEmailAddress(searchInput)) {
			this.setError(false);
			value.push({ label: searchInput, value: searchInput });

			this.handleChange(value);
		} else if (searchInput !== '') {
			this.setError(true);
		}

		this.toggleFocus();
		this.hideMenu();
	}

	toggleFocus() {
		let { hoverLabel } = this.state;

		this.setState({ hoverLabel: !hoverLabel });
	}

	render() {
		const { data, value, hoverLabel, searchInput, openMenu } = this.state;
		const { classes, theme, label } = this.props;

		const selectStyles = {
			input: (base) => ({
				...base,
				color: theme.palette.primary,
				'& input': {
					font: 'inherit',
				},
			}),
			clearIndicator: (provided) => ({
				...provided,
				cursor: 'pointer',
				padding: '6px 8px',
			}),
			dropdownIndicator: (provided) => ({
				...provided,
				cursor: 'pointer',
				padding: '6px 8px',
			}),
		};

		const components = {
			Control: this.Control,
			Menu: this.Menu,
			NoOptionsMessage: this.NoOptionsMessage,
			Option: this.Option,
			Placeholder: this.Placeholder,
			MultiValue: this.MultiValue,
			ValueContainer: this.ValueContainer,
		};

		return (
			<FormControl className={classes.root}>
				{label && (
					<label
						htmlFor={EMAIL_AUTOCOMPLETE}
						className={classNames(classes.label, hoverLabel ? classes.labelHover : '')}
					>
						{label}
					</label>
				)}
				<Select
					inputId={EMAIL_AUTOCOMPLETE}
					classes={classes}
					styles={selectStyles}
					options={data}
					components={components}
					onChange={this.handleChange}
					onFocus={this.toggleFocus}
					onBlur={this.onBlur}
					placeholder="Type email addresses (e.g. john.smith@company.com)"
					isMulti
					filterOption={() => true}
					isClearable={false}
					value={value}
					inputValue={searchInput}
					onInputChange={this.handleInputChange}
					onKeyDown={this.onInputKeyDown}
					menuIsOpen={openMenu}
				/>
			</FormControl>
		);
	}
}

BasketAutocomplete.propTypes = {
	value: PropTypes.array,
	agentContractId: PropTypes.string,
	agentClientCompanyId: PropTypes.string,
	label: PropTypes.string,
	disableAutoComplete: PropTypes.bool,
	required: PropTypes.bool,
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
	onUpdate: PropTypes.func,
	onError: PropTypes.func,
};

export default withStyles(styles, { withTheme: true })(BasketAutocomplete);
