import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import React from 'react';

import _ from 'lodash';

import DistanceFilter from './filters/DistanceFilter.react';
import NameFilter from './filters/NameFilter.react';
import PriceFilter from './filters/PriceFilter.react';
import StarsFilter from './filters/StarsFilter.react';
import ReviewFilter from './filters/ReviewFilter.react';
import TravelPolicyFilter from './filters/TravelPolicyFilter.react';
import AvailabilityFilter from './filters/AvailabilityFilter.react';
import AmenitiesFilter from './filters/AmenitiesFilter.react';
import FilterDivider from './filters/FilterDivider.react';
import PreferredHotelsFilter from './filters/PreferredHotelsFilter.react';
import CategoriesFilter from './filters/CategoriesFilter.react';
import DebugFilter from './filters/DebugFilter.react';
import ResetAllFilter from './filters/ResetAllFilter.react';
import MarginsFilter from './filters/MarginsFilter.react';
import TopXFilter from './filters/TopXFilter.react';
import WeightsFilter from './filters/WeightsFilter.react';
import RegionsFilter from './filters/RegionsFilter';
import NeighborhoodsFilter from './filters/NeighborhoodsFilter.react';

import Config from 'arbitrip-common/client/utils/Config';
import NameFilterOld from './filters/NameFilterOld.react';

const styles = {};

class FiltersComponent extends React.Component {
	render() {
		const {
			profile,
			travelPolicy,
			filters,
			mapMode,
			drawPolygons,
			drawHeatmap,
			search_terms,
			showDistanceFilter,
			showOldNameFilter,
		} = this.props;

		const doesTravelPolicyExist = travelPolicy && (travelPolicy.default || travelPolicy.place);
		const hasNegotiatedRates = _.get(profile, 'company.has_negotiated_rates');

		return (
			<div className="filters">
				<div className="filter-container">
					{Config.dev_mode ? (
						<React.Fragment>
							<ResetAllFilter profile={profile} />
							<FilterDivider />
						</React.Fragment>
					) : null}

					{mapMode && Config.research_mode && (
						<React.Fragment>
							<RegionsFilter
								profile={profile}
								filter={filters.regions}
								drawPolygons={drawPolygons}
								drawHeatmap={drawHeatmap}
							/>
							<FilterDivider />
						</React.Fragment>
					)}

					{Config.research_mode && (
						<React.Fragment>
							<TopXFilter profile={profile} filter={filters.top_x} />
							<FilterDivider />

							<NeighborhoodsFilter profile={profile} filter={filters.neighborhoods} />
							<FilterDivider />

							<WeightsFilter profile={profile} filter={filters.weights} />
							<FilterDivider />

							<MarginsFilter profile={profile} filter={filters.margins} />
							<FilterDivider />
						</React.Fragment>
					)}

					{showDistanceFilter && (
						<>
							<DistanceFilter profile={profile} filter={filters.max_distance} />
							<FilterDivider />
						</>
					)}

					{showOldNameFilter && <NameFilterOld profile={profile} filter={filters.name_contains} />}
					{!showOldNameFilter && (
						<NameFilter profile={profile} filter={filters.name_contains} search_terms={search_terms} />
					)}
					<FilterDivider />

					<CategoriesFilter profile={profile} filter={filters.categories} />
					<FilterDivider />

					<PriceFilter profile={profile} filter={filters.max_price_per_night} />
					<FilterDivider />

					{doesTravelPolicyExist && (
						<React.Fragment>
							<TravelPolicyFilter profile={profile} filter={filters.in_policy} />
							<FilterDivider />
						</React.Fragment>
					)}

					{hasNegotiatedRates && (
						<React.Fragment>
							<PreferredHotelsFilter profile={profile} filter={filters.preferred_hotels} />
							<FilterDivider />
						</React.Fragment>
					)}

					<AmenitiesFilter profile={profile} filter={filters.amenities} />
					<FilterDivider />

					<StarsFilter profile={profile} filter={filters.stars} />
					<FilterDivider />

					<ReviewFilter profile={profile} filter={filters.review} />
					<FilterDivider />

					<AvailabilityFilter profile={profile} filter={filters.availability} />

					{Config.dev_mode && (
						<React.Fragment>
							<FilterDivider />
							<DebugFilter title="Show only non-shallow" field="non_shallow" mode="checkbox_only" />
							<DebugFilter title="Show only non trustyou" field="no_trust_you" mode="checkbox_only" />
							<DebugFilter title="Show only shallow hotels" field="shallow" mode="checkbox_only" />
							<DebugFilter title="Show only matched hotels" field="matched_hotels" mode="checkbox_only" />
							<DebugFilter title="Original Currency" field="orig_currency" mode="checkbox_text" />
						</React.Fragment>
					)}
				</div>
			</div>
		);
	}
}

FiltersComponent.propTypes = {
	filters: PropTypes.any.isRequired,
	profile: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	search_terms: PropTypes.object.isRequired,
	showDistanceFilter: PropTypes.bool,
	showOldNameFilter: PropTypes.bool,
};

export default withStyles(styles)(FiltersComponent);
