import _ from 'lodash';
import React, { useMemo } from 'react';
import { Button } from '@mui/material';
import SettlePaymentSummary from './SettlePaymentSummary';
import SettlePaymentCopyLink from './SettlePaymentCopyLink';
import helpers from './helpers';
import moment from 'moment/moment';

const SettlePaymentPayNowLocked = ({ profile, booking, chargeCurrency, editPayment }) => {
	const link = useMemo(() => {
		return helpers.getPaymentLink(booking);
	}, [booking]);

	const link_expired_time = useMemo(() => {
		const expiration = _.get(booking, 'payment.payment_link.expiration');
		const date = moment(expiration);
		return helpers.getTimeFormatted(date);
	}, []);

	return (
		<div className="settle-payment-wrap">
			<div className="settle-payment">
				<div className="copy-link-title">Payment Link</div>
				<div className="copy-link-wrap">
					<SettlePaymentCopyLink link={link} />
				</div>
				<div className="copy-link-time">Expires on {link_expired_time}</div>
				<hr />
				<div>
					<SettlePaymentSummary booking={booking} chargeCurrency={chargeCurrency} profile={profile} />
				</div>
			</div>
			<div className="space-top actions space-between">
				<Button variant="outlined" fullWidth onClick={editPayment}>
					Edit Price
				</Button>

				<div className="copy-link-text">
					Click on "Edit Price" will delete this
					<br />
					link, you can create a new one later
				</div>
			</div>
		</div>
	);
};

export default SettlePaymentPayNowLocked;
