import ManagementConstants from '../constants/ManagementConstants';
import moment from 'moment';

function TripRequestCar(car, display_order) {
	this.id = car._id;

	this.pick_up = car.pick_up;
	// this.airport = car.airport;
	this.drop_off = car.drop_off;

	this.pick_up_date = car.pick_up_date && moment(car.pick_up_date);
	this.drop_off_date = car.drop_off_date && moment(car.drop_off_date);

	this.component_type = ManagementConstants.TRIP_REQUEST_COMPONENT_TYPE.CAR;
	this.display_order = display_order || car.display_order;
}

export default TripRequestCar;
