import React from 'react';
import createClass from 'create-react-class';

import { NavLink } from 'react-router-dom';

import ProfileComponent from './ProfileComponent.react';
import TitleManager from '../../../../utils/TitleManager';

import BasketComponent from './basket/BasketComponent.react';

import DisplayCurrencyComponent from './display-currency/DisplayCurrencyComponent.react';

import CombtasBar from './CombtasBar';

import Config from 'arbitrip-common/client/utils/Config';
import DocumentTitle from '../../../general/DocumentTitle.react';

import Analytics from 'arbitrip-common/client/analytics';
import _ from 'lodash';

function invertColors() {
	document.documentElement.style.webkitFilter = document.documentElement.style.webkitFilter ? '' : 'invert(100%)';
}

function reloadCss() {
	let h, a, f;
	a = document.getElementsByTagName('link');
	for (h = 0; h < a.length; h++) {
		f = a[h];
		if (f.rel.toLowerCase().match(/stylesheet/) && f.href) {
			const g = f.href.replace(/(&|%5C?)forceReload=\d+/, '');
			f.href = g + (g.match(/\?/) ? '&' : '?') + 'forceReload=' + new Date().valueOf();
		}
	}
}

const TopBar = createClass({
	displayName: 'TopBar',

	render: function () {
		const profile = this.props.user;
		const partner_login_company_name = _.get(this.props, 'user.partner_login_company_name', false);
		const COMPANY_NAME = partner_login_company_name || this.props.company.name;
		let title = TitleManager.defaults();
		if (profile.travel_manager) {
			title = TitleManager.buildTitleEndsWith('for ' + COMPANY_NAME, false);
		}

		let LOGO_CLASSES = 'image';
		if (Config.dev_mode === true) {
			LOGO_CLASSES += ' dead-logo';
		}

		let SHOW_HOTEL_BOOKING_BUTTONS = true;
		if (this.props.company.settings.management_enabled) {
			if (!this.props.company.settings.employees_hotel_booking_enabled && !profile.travel_manager) {
				SHOW_HOTEL_BOOKING_BUTTONS = false;
			}
		}

		const partners_ui = _.get(profile, 'company.partners_ui', {});
		const { hide_basket, hide_brand, hide_user_menu } = partners_ui;

		let LOGO_IMG = this.props.company.is_premium ? (
			<img
				src="https://s3.amazonaws.com/arbitrip/logos/arbitrip_logo.png?holidays"
				alt="Arbitrip.com"
				className={LOGO_CLASSES}
			/>
		) : (
			<img
				src="https://s3.amazonaws.com/arbitrip/logos/arbitrip_logo.png?holidays"
				alt="Arbitrip.com"
				className={LOGO_CLASSES}
			/>
		);

		let img_title = 'Arbitrip.com';
		if (this.props.company.custom_header_logo) {
			img_title = this.props.company.custom_header_alt;
			LOGO_IMG = <img src={this.props.company.custom_header_logo} alt={img_title} className={LOGO_CLASSES} />;
		}

		return (
			<DocumentTitle title={title}>
				<div className="top-bar">
					<div className="company-bar">
						<div className="inner-company-bar">
							<div className="company-bar-div logo-div">
								<NavLink
									to="/"
									title={img_title}
									id="logo"
									className="link"
									onClick={() => Analytics.actions.interactions.logoClicked(profile)}
								>
									<div className="logo">{LOGO_IMG}</div>
								</NavLink>
							</div>
							<div className="navigation-container">
								{this.props.company.settings.management_enabled ? (
									<div className="company-bar-div navigation-div">
										<NavLink to="/trips" className="hvr-underline-from-center">
											Trips
										</NavLink>
									</div>
								) : null}

								{SHOW_HOTEL_BOOKING_BUTTONS
									? [
											<div className="company-bar-div navigation-div" key="hotels">
												<NavLink
													to="/search"
													className="hvr-underline-from-center"
													onClick={() =>
														Analytics.actions.interactions.searchClicked(profile)
													}
												>
													{this.props.company.settings.management_enabled
														? 'Hotels'
														: 'Search'}
												</NavLink>
											</div>,
											<div className="company-bar-div navigation-div" key="bookings">
												<NavLink
													to="/bookings"
													className="hvr-underline-from-center"
													onClick={() =>
														Analytics.actions.interactions.bookingsClicked(profile)
													}
												>
													Bookings
												</NavLink>
											</div>,
											profile.approval_flow_enabled ? (
												<div className="company-bar-div navigation-div" key="requests">
													<NavLink to="/requests" className="hvr-underline-from-center">
														Requests
													</NavLink>
												</div>
											) : null,
										]
									: null}

								{profile.reports_dashboard ? (
									<div className="company-bar-div navigation-div">
										<NavLink to="/dashboard" className="hvr-underline-from-center">
											Dashboard
										</NavLink>
									</div>
								) : null}

								{!hide_basket && SHOW_HOTEL_BOOKING_BUTTONS && (
									<div className="company-bar-div basket-div" aria-label="Send hotels for review">
										<BasketComponent profile={profile} />
									</div>
								)}

								<div className="company-bar-div profile-div display-currency-div">
									<DisplayCurrencyComponent
										profile={profile}
										reservationPage={this.props.reservationPage}
									/>
								</div>
								{!hide_brand && (
									<div
										className="company-bar-div profile-div company-name-div"
										title={COMPANY_NAME}
										onClick={Config.localhost ? invertColors : null}
										onDoubleClick={Config.localhost ? reloadCss : null}
									>
										{COMPANY_NAME}
									</div>
								)}
								{!hide_user_menu && (
									<div className="company-bar-div profile-div user-name-div">
										<ProfileComponent />
									</div>
								)}
							</div>
						</div>
					</div>
					<hr className="shadower" />
					{this.props.showCombtasBar && (
						<CombtasBar endCombtasSession={this.props.endCombtasSession} tasId={this.props.tasId} />
					)}
				</div>
			</DocumentTitle>
		);
	},
});

export default TopBar;
