import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import _ from 'lodash';

import ReservationActions from '@/actions/ReservationActions';

import ReservationDetails from './ReservationDetails';
import Travelers from './Travelers';
import TermsOfSupplier from './TermsOfSupplier';

import { ReactComponent as CheckmarkIcon } from '@/img/confirmation_page/checkmark.svg';
import ExpediaDealDetailsContainer from '../reservation-payment/components/ExpediaDealDetailsContainer.react';

const ConfirmationPage = ({ reservation, hotel, profile }) => {
	const navigate = useNavigate();

	const { supplier_ref: supplierRef, id, short_id: shortId, travelers, deal } = reservation;
	const nonRefundable = _.get(reservation, 'deal.dca.nonRefundable');
	const cancellationPoliciesText = _.get(reservation, 'deal.original.dca.cancellationPoliciesText')?.split('\n');
	const city = _.get(hotel, 'city');
	const itineraryId = (supplierRef ? `${supplierRef}` : '').split('-')[0];

	useEffect(() => {
		window.scrollTo(0, 0);

		return () => {
			ReservationActions.clearReservation();
		};
	}, []);

	// var PHONE = t('SupportPhoneNumber');

	return (
		<div className="confirmation-page">
			<div className="order-confirmation">
				<div className="order-confirmation__title">Order Confirmation</div>
				{shortId && city && (
					<div className="order-confirmation__content">
						<CheckmarkIcon />
						<div className="order-confirmation__text">
							Reservation Number: <b>{itineraryId ? itineraryId : shortId}</b> to {city} is now confirmed.
						</div>
					</div>
				)}
			</div>
			<div className="confirmation-page__content">
				<div className="confirmation-page__main">
					<Travelers travelers={travelers} bookingId={id} />

					<div className="cancellation-terms">
						<div className="cancellation-terms__content">
							<div className="title">Cancellation terms</div>
							<div className="cancellation-terms__text">
								{cancellationPoliciesText.map((line, index) => (
									<div
										key={index}
										className={`cancellation-terms__${
											index === 0 ? (nonRefundable ? 'text-red' : 'text-green') : 'term'
										}`}
									>
										{line.trim()}
									</div>
								))}
							</div>
						</div>
						<div>
							<button
								className="confirmation-page__button"
								onClick={() => navigate('/bookings', { state: { id: id }, replace: true })}
							>
								View your booking
							</button>
						</div>
					</div>

					<TermsOfSupplier deal={deal} />

					<div className="contact-us">
						<div className="contact-us__content">
							<div className="title">Contact us</div>
							<div className="contact-us__text">
								If you have any questions or need assistance, please don’t hesitate to contact us at any
								time. We are always happy to help and will respond as soon as possible. You can reach us
								at <a href="tel:+13122197193">+1 3122197193</a>.
								{/* TODO: FIX the phone 
                 If you have any questions or need assistance, please don’t hesitate to contact us at any time. We are always happy to help and will respond as soon as possible. You can reach us at <a href={PHONE}>{PHONE}</a> 
                 */}
							</div>
						</div>
					</div>
				</div>

				<div className="confirmation-page__side">
					<ReservationDetails hotel={hotel} reservation={reservation} />
					<ExpediaDealDetailsContainer profile={profile} reservation={reservation} isConfirmation={true} />
				</div>
			</div>
		</div>
	);
};

export default ConfirmationPage;
