const Intercom = require('./intercom');
const Zendesk = require('./zendesk');
const Mixpanel = require('./mixpanel');
const Clarity = require('./clarity');
// const Google = require('./google');

module.exports = {
  Intercom: Intercom,
    Zendesk_en: Zendesk,
    Zendesk_he: Zendesk,
  Mixpanel: Mixpanel,
  Clarity
  // Google: Google
};
