import ResultsConstants from '../constants/ResultsConstants';
import SortConstants from '../constants/SortConstants';
import FilterConstants from '../constants/FiltersConstants';

import AppDispatcher from '../dispatcher/AppDispatcher';
import HotelConstants from '../constants/HotelConstants';
import SEARCH_CONSTANTS from 'arbitrip-common/general/constants/search-constants';

import FiltersConstants from '../constants/FiltersConstants';
import ProfileConstants from '../constants/ProfileConstants';

import _ from 'lodash';
import DealUtils from '../entities/DealUtils';

import Analytics from 'arbitrip-common/client/analytics';

import ProfileStore from '../stores/ProfileStore';

import { EventEmitter } from 'events';

import SessionManager from 'arbitrip-common/client/utils/SessionManager';

import { SEARCH_SESSION_EVENT } from 'arbitrip-common/client/constants/SearchSessionConstants';

import Config from 'arbitrip-common/client/utils/Config';
import * as Sentry from '@sentry/react';
import { WebStorageManager } from 'arbitrip-common/client/utils';
import MapConstants from '../constants/MapConstants';
import ReservationConstants from '../constants/ReservationConstants';

const { getDisplayCurrencyExchangeRate, getRecommendationWeights, getProfile } = ProfileStore;

let _session_manager = new SessionManager();
let _filtered = {};
let _error = false;

let _status = ResultsConstants.STATUSES.INITIAL;
let _sortMethod = Config.research_mode ? SortConstants.SORT_METHOD.SCORES : SortConstants.SORT_METHOD.RECOMMENDED;
let _lastAction;
let _expired = false;
let _custom_error = null;

let _regions = null;

// TODO: REFACTOR REFACTOR REFACTOR
function onResultsExpired(searchToken) {
	console.log('----- -------- -------');
	console.log('INNER CALLBACK EXPIRED', searchToken);
	console.log('----- -------- -------');
	const current_search_token = _.get(_session_manager, 'current_search_session.search_token');
	if (current_search_token === searchToken) {
		_expired = true;

		Analytics.actions.sessions.resultsHaveExpired(getProfile());

		ResultsStore.emitChange();
	} else {
		console.warn('expired results for token', searchToken, 'yet current token is', current_search_token);
	}
}

function updateStatus(status) {
	if (status) {
		_status = status;
	}
}

function clearSearchResults() {
	_sortMethod = Config.research_mode ? SortConstants.SORT_METHOD.SCORES : SortConstants.SORT_METHOD.RECOMMENDED;
	_lastAction = null;
	markError(false);
	_custom_error = null;
	updateStatus(ResultsConstants.STATUSES.INITIAL);
	filterByAll();
	_regions = null;
}

function clearFilters() {
	_maxDistance = FilterConstants.MAXIMUM_FILTERED_DISTANCE_IN_KM;
	_nameContains = '';
	_maxPricePerNight = 0;
	_starsFilterData = getDefaultStarsFilterData();
	_reviewFilterData = getDefaultReviewFilterData();

	_inPolicyFilterData = FilterConstants.DEFAULT_IN_POLICY_FILTER_VALUE;
	_availabilityFilterData = FilterConstants.DEFAULT_AVAILABILITY_FILTER_VALUE;
	_preferredHotelsFilterData = FilterConstants.DEFAULT_PREFERRED_HOTELS_FILTER_VALUE;
	_sanitizationStandardsFilterData = FilterConstants.DEFAULT_SANITIZATION_STANDARDS_FILTER_VALUE;

	_marginsFilterData = getDefaultMarginsFilterData();

	_topX = Config.research_mode ? 30 : 0;

	_categoriesFilterData = getDefaultCategoriesFilterData();

	_debugContains = {};

	_amenitiesFilterData = getDefaultAmenitiesFilterData();

	_neighborhoodsFilterData = getDefaultNeighborhoodsFilterData();

	WebStorageManager.removeFromWebStorage('_filtered_hotel');
}

function clearMapFilters() {
	_maxDistance = FilterConstants.MAXIMUM_FILTERED_DISTANCE_IN_KM;
	_nameContains = '';
}

var _maxDistance = FilterConstants.MAXIMUM_FILTERED_DISTANCE_IN_KM;

function filterByDistance(distance) {
	_maxDistance = distance ? distance : FilterConstants.MAXIMUM_FILTERED_DISTANCE_IN_KM;

	filterByAll();
}

var _nameContains = '';

function filterByNameContains(nameContains) {
	_nameContains = nameContains;
	filterByAll();
}

var _debugContains = {};

function filterByDebugContains(debugContains) {
	_debugContains[debugContains.field] = {
		mode: debugContains.mode,
		value: debugContains.value,
		reverseLogic: debugContains.reverseLogic,
	};
	filterByAll();
}

let _maxPricePerNight = 0;
// let _maxPricePerNightCurrency = null; // used primarily for debugging
let _maxPricePerNightCurrencyExchangeRate = 1;

function filterByPrice(price, currency) {
	_maxPricePerNight = Math.min(price || 0, Number.MAX_SAFE_INTEGER);

	// _maxPricePerNightCurrency = currency;

	filterByAll();
}

let _topX = Config.research_mode ? 30 : 0;
function filterByTopX(x) {
	_topX = Math.min(x || 0, Number.MAX_SAFE_INTEGER);
	filterByAll();
}

function getDefaultStarsFilterData() {
	return {
		5: true,
		4: true,
		3: true,
		2: true,
		1: false,
		// '-1': true // No Rating (no stars)
	};
}

function getDefaultMarginsFilterData() {
	return {
		[FilterConstants.MARGINS.ABSOLUTE]: false,
		[FilterConstants.MARGINS.PERCENTAGE]: false,
		[FilterConstants.MARGINS.SCORE]: false,
		[FilterConstants.MARGINS.RECOMMENDED]: false,
		// [FilterConstants.MARGINS.TEN_PERCENT]: true,
		[FilterConstants.MARGINS.TEN_PERCENT]: false,
		[FilterConstants.MARGINS.TOP_FIFTY]: false,
	};
}

function getDefaultRegionsFilterData() {
	return {
		[FilterConstants.REGIONS.HIGH_LEVEL_REGION]: false,
		[FilterConstants.REGIONS.MULTI_CITY_VICINITY]: false,
		[FilterConstants.REGIONS.CITY]: false,
		[FilterConstants.REGIONS.NEIGHBORHOOD]: false,
	};
}

function getDefaultCategoriesFilterData() {
	return {
		[FilterConstants.CATEGORIES.HOTEL]: true,
		[FilterConstants.CATEGORIES.APARTMENT]: false,
		// [FilterConstants.CATEGORIES.APART_HOTEL]: true,
		// [FilterConstants.CATEGORIES.HOSTEL]: true,
		// [FilterConstants.CATEGORIES.GUEST_HOUSE]: true,
		[FilterConstants.CATEGORIES.OTHER]: false,
		// [FilterConstants.CATEGORIES.NA]: true,
	};
}

let neighborhood_counters = {};
function updateNeighborhoodCounters(hotels = []) {
	for (const hotel of hotels) {
		if (Array.isArray(hotel.neighborhoods)) {
			for (const neighborhood of hotel.neighborhoods) {
				if (neighborhood) {
					neighborhood_counters[neighborhood] = (neighborhood_counters[neighborhood] || 0) + 1;
					if (!_neighborhoodsFilterData[neighborhood]) {
						_neighborhoodsFilterData[neighborhood] = true;
					}
				}
			}
		}
	}
}

function getDefaultNeighborhoodsFilterData() {
	let data = {};
	for (const nei in neighborhood_counters) {
		if (nei) {
			data[nei] = true;
		}
	}
	return data;
}

function getDefaultReviewFilterData() {
	return {
		[FilterConstants.REVIEW_LEVELS.EXCELLENT]: true,
		[FilterConstants.REVIEW_LEVELS.VERY_GOOD]: true,
		[FilterConstants.REVIEW_LEVELS.GOOD]: true,
		[FilterConstants.REVIEW_LEVELS.FAIR]: true,
		[FilterConstants.REVIEW_LEVELS.POOR]: false,
		[FilterConstants.REVIEW_LEVELS.UNRATED]: true,
	};
}

function getDefaultAmenitiesFilterData() {
	return {
		[FilterConstants.AMENITIES.BREAKFAST_INCLUDED]: false,
		[FilterConstants.AMENITIES.FREE_WIFI]: false,
		// [FilterConstants.AMENITIES.TWIN_BEDS]: false,
		[FilterConstants.AMENITIES.PARKING]: false,
		[FilterConstants.AMENITIES.AIRPORT_SHUTTLE]: false,
		[FilterConstants.AMENITIES.FITNESS_CENTRE]: false,
		// [FilterConstants.AMENITIES.FREE_CANCELLATION]: false,
		[FilterConstants.AMENITIES.RESTAURANT]: false,
		[FilterConstants.AMENITIES.MEETING_ROOM]: false,
		[FilterConstants.AMENITIES.SWIMMING_POOL]: false,
		[FilterConstants.AMENITIES.AIR_CONDITIONING]: false,
		[FilterConstants.AMENITIES.ELECTRIC_VEHICLE_CHARGING_STATION]: false,
	};
}

var _starsFilterData = getDefaultStarsFilterData();
var _reviewFilterData = getDefaultReviewFilterData();
var _amenitiesFilterData = getDefaultAmenitiesFilterData();

function filterByStars(stars, value) {
	_starsFilterData[stars] = value;
	filterByAll();
}

function filterByStarsOnly(stars) {
	for (var star in _starsFilterData) {
		_starsFilterData[star] = Number(star) === Number(stars);
	}
	filterByAll();
}

function resetStarsFilter() {
	_starsFilterData = getDefaultStarsFilterData();
	filterByAll();
}

function filterByReview(review, value) {
	_reviewFilterData[review] = value;
	filterByAll();
}

function filterByReviewOnly(review) {
	for (var review_data in _reviewFilterData) {
		_reviewFilterData[review_data] = review_data === review;
	}
	filterByAll();
}

function resetReviewFilter() {
	_reviewFilterData = getDefaultReviewFilterData();
	filterByAll();
}

function filterByPreferredHotels(value) {
	_preferredHotelsFilterData = value;
	filterByAll();
}

//////////

function filterByCategories(category, value) {
	_categoriesFilterData[category] = value;
	filterByAll();
}

function filterByCategoriesOnly(category) {
	for (let cat in _categoriesFilterData) {
		_categoriesFilterData[cat] = cat === category;
	}
	filterByAll();
}

function resetCategoriesFilter() {
	_categoriesFilterData = getDefaultCategoriesFilterData();
	filterByAll();
}

//////////

function filterByNeighborhoods(neighborhood, value) {
	_neighborhoodsFilterData[neighborhood] = value;
	filterByAll();
}

function filterByNeighborhoodsOnly(neighborhood) {
	for (let nei in _neighborhoodsFilterData) {
		_neighborhoodsFilterData[nei] = nei === neighborhood;
	}
	filterByAll();
}

function resetNeighborhoodsFilter() {
	_neighborhoodsFilterData = getDefaultNeighborhoodsFilterData();
	filterByAll();
}

//////////

function filterByAmenity(amenity, value) {
	// const original_breakfast_filter_value = _amenitiesFilterData[FilterConstants.AMENITIES.BREAKFAST_INCLUDED];
	_amenitiesFilterData[amenity] = value;
	filterByAll();
}

function filterByAmenityOnly(amenity) {
	// const original_breakfast_filter_value = _amenitiesFilterData[FilterConstants.AMENITIES.BREAKFAST_INCLUDED];
	for (var amenity_data in _amenitiesFilterData) {
		_amenitiesFilterData[amenity_data] = amenity_data === amenity;
	}
	filterByAll();
}

function resetAmenitiesFilter() {
	// const original_breakfast_filter_value = _amenitiesFilterData[FilterConstants.AMENITIES.BREAKFAST_INCLUDED];
	_amenitiesFilterData = getDefaultAmenitiesFilterData();
	filterByAll();
}

var _inPolicyFilterData = FilterConstants.DEFAULT_IN_POLICY_FILTER_VALUE;
var _availabilityFilterData = FilterConstants.DEFAULT_AVAILABILITY_FILTER_VALUE;
var _preferredHotelsFilterData = FilterConstants.DEFAULT_PREFERRED_HOTELS_FILTER_VALUE;
var _sanitizationStandardsFilterData = FilterConstants.DEFAULT_SANITIZATION_STANDARDS_FILTER_VALUE;
var _categoriesFilterData = getDefaultCategoriesFilterData();
var _marginsFilterData = getDefaultMarginsFilterData();
var _regionsFilterData = getDefaultRegionsFilterData();

// const base_absolute_threshold = 25; // $25
// const base_absolute_stars_benchmark = 4; // 4 stars mean no change
// const base_absolute_stars_coefficient = 5; // $5 per star diff from benchmark (+-5 for 1 star diff)
const stars_absolute_thresholds = {
	5: 30,
	4.5: 27.5,
	4: 25,
	3.5: 22.5,
	3: 20,
	2.5: 17.5,
	2: 15,
};
const fallback_absolute_threshold = 35;

// const base_percentage_threshold = 15; // 15%
// const base_percentage_stars_benchmark = 4;
// const base_percentage_stars_coefficient = 5;
const stars_percentage_thresholds = {
	5: 15,
	// '4.5': 18.57,
	4.5: 17.5,
	4: 20,
	// '3.5': 21.43,
	3.5: 21,
	3: 22,
	// '2.5': 22.57,
	2.5: 22.5,
	// '2': 22.8,
	2: 23,
};
const fallback_percentage_threshold = 23.5;

const stars_score_thresholds = {
	5: 7.5,
	4.5: 8.75,
	4: 10,
	3.5: 10.5,
	3: 11,
	2.5: 11.25,
	2: 11.5,
};
const fallback_score_threshold = 11.75;
var _neighborhoodsFilterData = getDefaultNeighborhoodsFilterData();

function filterByInPolicy(value) {
	_inPolicyFilterData = value;
	filterByAll();
}

function filterByAvailability(value) {
	_availabilityFilterData = value;
	filterByAll();
}

function filterBySanitizationStandards(value) {
	_sanitizationStandardsFilterData = value;
	filterByAll();
}

function filterByMargins(margin, value) {
	console.log('filterByMargins :::', margin, value);
	_marginsFilterData[margin] = value;
	filterByAll();
}

function filterByMarginsOnly(margin) {
	console.log('filterByMarginsOnly :::', margin);
	for (var mgn in _marginsFilterData) {
		_marginsFilterData[mgn] = mgn === margin;
	}
	filterByAll();
}

function shouldIgnoreStarsFilter() {
	for (var star in _starsFilterData) {
		if (!_starsFilterData[star]) {
			return false;
		}
	}
	return true;
}

function shouldIgnoreReviewFilter() {
	for (var review in _reviewFilterData) {
		if (!_reviewFilterData[review]) {
			return false;
		}
	}
	return true;
}

function shouldIgnoreAmenitiesFilter() {
	for (var amenity in _amenitiesFilterData) {
		if (!_amenitiesFilterData[amenity]) {
			return false;
		}
	}
	return true;
}

function shouldIgnorePreferredHotelsFilter() {
	return !_preferredHotelsFilterData;
}

function shouldIgnoreCategoriesFilter() {
	for (var category in _categoriesFilterData) {
		if (!_categoriesFilterData[category]) {
			return false;
		}
	}
	return true;
}

function shouldIgnoreNeighborhoodsFilter() {
	for (let neighborhood in _neighborhoodsFilterData) {
		if (!_neighborhoodsFilterData[neighborhood]) {
			return false;
		}
	}
	return true;
}

function shouldIgnoreInPolicyFilter() {
	return !_inPolicyFilterData;
}

function shouldIgnoreAvailabilityFilter() {
	return !_availabilityFilterData || !_session_manager.current_search_results.completed_listening;
}

function shouldIgnoreDistanceFilter() {
	return !_maxDistance || _maxDistance === FilterConstants.MAXIMUM_FILTERED_DISTANCE_IN_KM;
}

function shouldIgnoreSanitizationStandardsFilter() {
	return !_sanitizationStandardsFilterData;
}

function shouldIgnoreMarginsFilter() {
	for (var mgn in _marginsFilterData) {
		if (_marginsFilterData[mgn]) {
			return false;
		}
	}
	return true;
}

/////////////////////////////////
/////////// amenities ///////////
/////////////////////////////////

function doesDealIncludeBreakfast(deal) {
	return _.get(deal, 'details.includes_breakfast') || _.get(deal, 'breakfast');
}

function doesHotelHaveBreakfastDeal(hotel) {
	if (doesDealIncludeBreakfast(hotel.getRepresentativeDeal(true))) {
		return true;
	}

	const deals = _.get(hotel, 'deals');
	if (Array.isArray(deals)) {
		if (deals.some(doesDealIncludeBreakfast)) {
			return true;
		}
	}

	return false;
}

function isHotelValidByDistance(hotel) {
	return (hotel.distanceInKm || hotel.distanceInKm === 0) && hotel.distanceInKm <= _maxDistance;
}

function isHotelDealValidByPrice(deal) {
	const display_currency_exchange_rate = getDisplayCurrencyExchangeRate() || 1;
	const price_in_display_currency = deal.pricePerNight * display_currency_exchange_rate;

	return price_in_display_currency <= _maxPricePerNight;
}

const _marginsFilterHotelValidators = {
	[FilterConstants.MARGINS.ABSOLUTE]: (hotel) => {
		const absolute = _.get(hotel, 'virtual_cheapest_margin_absolute', 0);
		// const threshold = base_absolute_threshold + ((hotel.stars - base_absolute_stars_benchmark) * base_absolute_stars_coefficient);
		const threshold = stars_absolute_thresholds[hotel.stars] || fallback_absolute_threshold;

		return absolute >= threshold;
	},
	[FilterConstants.MARGINS.PERCENTAGE]: (hotel) => {
		const percentage = _.get(hotel, 'virtual_cheapest_margin_percentage', 0);
		// const threshold = base_percentage_threshold + ((hotel.stars - base_percentage_stars_benchmark) * base_percentage_stars_coefficient);
		const threshold = stars_percentage_thresholds[hotel.stars] || fallback_percentage_threshold;

		return percentage >= threshold;
	},
	[FilterConstants.MARGINS.SCORE]: (hotel) => {
		const score = _.get(hotel, 'margin_score', 0);
		const threshold = stars_score_thresholds[hotel.stars] || fallback_score_threshold;

		return score >= threshold;
	},
	[FilterConstants.MARGINS.RECOMMENDED]: (hotel) => {
		// const score = _.get(hotel, 'recommendation_final_score', 0);
		// // const threshold = stars_score_thresholds[hotel.stars] || fallback_score_threshold;
		// const threshold = 12000;
		// return score >= threshold;
		const search_results = _.get(_session_manager, 'current_search_session.search_results');
		if (search_results) {
			return search_results.hotels
				.filter((h) => _.get(h, 'representative_deals.cheapest'))
				.sort((a, b) => _.get(b, 'recommendation_final_score', 0) - _.get(a, 'recommendation_final_score', 0))
				.slice(0, 50)
				.reduce((acc, cur) => Object.assign(acc, { [cur.id]: true }), {})[hotel.id];
		}
		return false;
	},
	[FilterConstants.MARGINS.TEN_PERCENT]: (hotel) => {
		const percentage = _.get(hotel, 'virtual_cheapest_margin_percentage', 0);
		const threshold = 10;

		return percentage >= threshold;
	},
	[FilterConstants.MARGINS.TOP_FIFTY]: (hotel) => {
		const search_results = _.get(_session_manager, 'current_search_session.search_results');
		if (search_results) {
			return (
				search_results.hotels
					// .filter(h => _.get(h, 'virtual_cheapest_margin_percentage', 0))
					// .sort((a, b) => _.get(b, 'virtual_cheapest_margin_percentage', 0) - _.get(a, 'virtual_cheapest_margin_percentage', 0))
					.sort(
						(a, b) =>
							_.get(b, 'virtual_cheapest_margin_absolute', 0) -
							_.get(a, 'virtual_cheapest_margin_absolute', 0),
					)
					.slice(0, 50)
					.reduce((acc, cur) => Object.assign(acc, { [cur.id]: true }), {})[hotel.id]
			);
		}
		return false;
	},
};

function isHotelValidByMargins(hotel) {
	for (const mgn in FilterConstants.MARGINS) {
		const margin = FilterConstants.MARGINS[mgn];
		if (_marginsFilterData[margin] && !_marginsFilterHotelValidators[margin](hotel)) {
			return false;
		}
	}

	return true;
}

function isHotelValidByAmenities(hotel) {
	if (_amenitiesFilterData[FilterConstants.AMENITIES.BREAKFAST_INCLUDED] && !doesHotelHaveBreakfastDeal(hotel)) {
		return false;
	}

	for (let filter_flag of FilterConstants.AMENITY_FILTER_FLAGS) {
		if (_amenitiesFilterData[filter_flag] && (!hotel.amenities_flags || !hotel.amenities_flags[filter_flag])) {
			return false;
		}
	}

	return true;
}

function isHotelValidByCategoryFlags(category_flags) {
	if (_.isObject(category_flags)) {
		return Object.entries(category_flags).some(([category, flag]) => flag && _categoriesFilterData[category]);
	}

	return _categoriesFilterData[FilterConstants.CATEGORIES.OTHER];
}

function isHotelValidByNeighborhoods(neighborhoods) {
	if (Array.isArray(neighborhoods)) {
		return neighborhoods.some((neighborhood) => _neighborhoodsFilterData[neighborhood]);
	}

	return false;
}

/////////////////////////////////
/////////////////////////////////
/////////////////////////////////
const throttledEnsureSort = _.throttle(ensureSort, 1000);
function ensureSort() {
	switch (_sortMethod) {
		case SortConstants.SORT_METHOD.RECOMMENDED:
			sortByRecommended();
			break;

		case SortConstants.SORT_METHOD.SCORES:
			sortByScores();
			break;

		case SortConstants.SORT_METHOD.REVIEWS:
			sortByReviews();
			break;

		case SortConstants.SORT_METHOD.DISTANCE:
			sortByDistance();
			break;

		case SortConstants.SORT_METHOD.PRICE:
			sortByPrice();
			break;
		case SortConstants.SORT_METHOD.PRICE_HIGH_TO_LOW:
			sortByPriceHighToLow();
			break;

		case SortConstants.SORT_METHOD.ABSOLUTE_MARGIN:
			sortByAbsoluteMargin();
			break;

		case SortConstants.SORT_METHOD.MARGIN_PERCENTAGE:
			sortByMarginPercentage();
			break;

		default:
			// sortByRecommended();
			sortByScores();
		// sortByAbsoluteMargin();
	}
}

function filterByAll() {
	const search_results = _.get(_session_manager, 'current_search_session.search_results');
	if (search_results) {
		_filtered = {
			hotels: search_results.filterHotels((hotel) => {
				// Filters by name contains
				if (_nameContains) {
					const re = new RegExp(_nameContains.replace(/\s+/g, ' +'), 'i');
					if (!re.test(hotel.name)) {
						return false;
					}
				}

				// commented out due to benny's request (now name filter won't work on dealless hotels)
				//  else if (!ignoreAvailabilityFilter && !hotel.representativeDeal && !hotel.shallow) { // Filters by availability
				//   return false;
				// }

				const current_representative_deal = getCurrentRepresentativeDeal(hotel);

				// Filters by price (maximum)
				if (
					_maxPricePerNight &&
					DealUtils.isValidDeal(current_representative_deal) &&
					!isHotelDealValidByPrice(current_representative_deal)
				) {
					return false;
				}

				// Filters by in-policy
				if (
					!shouldIgnoreInPolicyFilter() &&
					current_representative_deal &&
					current_representative_deal.in_policy === false
				) {
					return false;
				}

				// Filter by amenities (flags)
				if (!shouldIgnoreAmenitiesFilter() && !isHotelValidByAmenities(hotel)) {
					return false;
				}

				/**
				 * Show Hotel in case of a match regarding the following filters
				 */
				if (hotel.hotelMatch) {
					return true;
				}

				// Filters by stars (flags)
				if (!shouldIgnoreStarsFilter() && !_starsFilterData[Math.floor(hotel.stars)]) {
					return false;
				}

				// Filter by review (flags)
				if (
					!shouldIgnoreReviewFilter() &&
					((hotel.review && !_reviewFilterData[hotel.review.description]) ||
						(!hotel.review && !_reviewFilterData[FilterConstants.REVIEW_LEVELS.UNRATED]))
				) {
					return false;
				}

				// Filter by sanitization standards (TODO: bring threshold from consts)
				if (!shouldIgnoreSanitizationStandardsFilter()) {
					const score = _.get(hotel, 'original.sanitization_standards.arbitrip_score', 0);
					if (score < 5) {
						return false;
					}
				}

				if (!shouldIgnoreMarginsFilter() && !isHotelValidByMargins(hotel)) {
					return false;
				}

				if (!shouldIgnoreAvailabilityFilter() && !hotel.representativeDeal && !hotel.shallow) {
					// Filters by availability
					return false;
				}

				// Filters by distance (maximum)
				if (!shouldIgnoreDistanceFilter(hotel) && !isHotelValidByDistance(hotel)) {
					return false;
				}

				// Filter by preferred hotels
				if (!shouldIgnorePreferredHotelsFilter() && !hotel.preferred) {
					return false;
				}

				// Filter by categories
				if (!shouldIgnoreCategoriesFilter() && !isHotelValidByCategoryFlags(hotel.category_flags)) {
					return false;
				}

				// Filter by neighborhoods
				if (!shouldIgnoreNeighborhoodsFilter() && !isHotelValidByNeighborhoods(hotel.neighborhoods)) {
					return false;
				}

				for (var field in _debugContains) {
					var value = _debugContains[field].value;
					var mode = _debugContains[field].mode;
					var reverseLogic = _debugContains[field].reverseLogic;
					var wasMatched = false;
					if (mode === 'checkbox_only') {
						switch (field) {
							case 'non_shallow':
								wasMatched = !hotel.shallow;
								break;

							case 'no_trust_you':
								wasMatched = !hotel.hasReview;
								break;

							case 'matched_hotels':
								wasMatched = hotel.matched_supplier_flag;
								break;

							case 'breakfast':
								wasMatched =
									_.isArray(hotel.deals) && hotel.deals.some((deal) => deal.breakfast === true);
								break;

							case 'shallow':
								wasMatched = hotel.shallow;
								break;

							default: // Do nothing
						}
						if (reverseLogic && !wasMatched) {
							// filter isn't satisfied
							return false;
						}
					} else {
						if (value) {
							if (!wasMatched && !reverseLogic) {
								return false;
							} else if (wasMatched && reverseLogic) {
								return false;
							}
						}
					}
				}

				return true;
			}),
		};

		ensureSort();
	}
}

function markError(error) {
	_error = error ? true : false;
}

function sortHotels(compareFunction) {
	try {
		_filtered.hotels.sort(compareFunction);
	} catch (err) {
		console.error(err);
		Sentry.captureException(err);
	}
}

const sortByAbsoluteMargin = () => sortHotels(compareAbsoluteMargin);
const sortByMarginPercentage = () => sortHotels(compareMarginPercentage);

function compareAbsoluteMargin(a, b) {
	if (a.virtual_cheapest_margin_absolute && b.virtual_cheapest_margin_absolute) {
		return b.virtual_cheapest_margin_absolute - a.virtual_cheapest_margin_absolute;
	} else if (a.virtual_cheapest_margin_absolute) {
		return -1;
	} else if (b.virtual_cheapest_margin_absolute) {
		return 1;
	}

	return compareRecommended(a, b);
}

function compareMarginPercentage(a, b) {
	if (a.virtual_cheapest_margin_percentage && b.virtual_cheapest_margin_percentage) {
		return b.virtual_cheapest_margin_percentage - a.virtual_cheapest_margin_percentage;
	} else if (a.virtual_cheapest_margin_percentage) {
		return -1;
	} else if (b.virtual_cheapest_margin_percentage) {
		return 1;
	}

	return compareRecommended(a, b);
}

function compareScores(a, b) {
	const a_score = _.get(a, server_score_path, Number.MIN_SAFE_INTEGER);
	const b_score = _.get(b, server_score_path, Number.MIN_SAFE_INTEGER);

	return b_score - a_score;
}

const sortByScores = () => sortHotels(compareScores);

// const recommendation_score_path = 'original.recommendationEngine.totalScore';
const recommendation_score_path = 'recommendation_final_score';

function compareRecommended(a, b) {
	const a_score = _.get(a, recommendation_score_path, Number.MIN_SAFE_INTEGER);
	const b_score = _.get(b, recommendation_score_path, Number.MIN_SAFE_INTEGER);

	return b_score - a_score;
}

const server_score_path = 'scores.normal.total_score';

// const sortByRecommended = () => sortHotels(compareRecommended);
const sortByRecommended = () => sortHotels(Config.research_mode ? compareRecommended : compareScores);

function comparePrices(a, b) {
	const [a_deal, b_deal] = isBreakfastOnly()
		? [a.representativeBreakfastDeal, b.representativeBreakfastDeal]
		: [a.representativeDeal, b.representativeDeal];

	const a_validity = DealUtils.isValidDeal(a_deal);
	const b_validity = DealUtils.isValidDeal(b_deal);

	if (a_validity && b_validity) {
		if (a_deal.expedia_pricing && b_deal.expedia_pricing) {
			return a_deal.expedia_pricing.totalPrice - b_deal.expedia_pricing.totalPrice;
		}
		// tie breaker is recommendation score
		return a_deal.pricePerNight - b_deal.pricePerNight || compareRecommended(a, b);
	} else {
		return 1;
	}
}

const sortByPrice = () => sortHotels(comparePrices);
const sortByPriceHighToLow = () => sortHotels((a, b) => comparePrices(b, a));

function compareDistances(a, b) {
	const a_validity = a !== '';
	const b_validity = b !== '';

	if (a_validity && b_validity) {
		// tie breaker is recommendation score
		return a.distanceInKm - b.distanceInKm || compareRecommended(a, b);
	}

	if (a_validity && !b_validity) {
		return -1;
	}

	if (!a_validity && b_validity) {
		return 1;
	}

	// both are invalid
	return compareRecommended(a, b);
}

const sortByDistance = () => sortHotels(compareDistances);

function compareReviewScores(a, b) {
	const a_validity = a.review && a.review.score && a.review.votes;
	const b_validity = b.review && b.review.score && b.review.votes;

	if (a_validity && b_validity) {
		// 1st tie breaker is # of votes, 2nd (and final) tie breaker is recommendation score
		return b.review.score - a.review.score || b.review.votes - a.review.votes || compareRecommended(a, b);
	}

	if (a_validity && !b_validity) {
		return -1;
	}

	if (!a_validity && b_validity) {
		return 1;
	}

	// both are not valid
	return compareRecommended(a, b);
}

const sortByReviews = () => sortHotels(compareReviewScores);

function isBreakfastOnly() {
	return _amenitiesFilterData[FilterConstants.AMENITIES.BREAKFAST_INCLUDED];
}

function getCurrentRepresentativeDeal(hotel) {
	return isBreakfastOnly() ? hotel.representativeBreakfastDeal : hotel.representativeDeal;
}

function isValidTopX(x) {
	return !isNaN(x) && x > 0 && x < Number.MAX_SAFE_INTEGER;
}

const ResultsStore = _.extend({}, EventEmitter.prototype, {
	areArbitripPointsApplied: function () {
		if (ProfileStore.isLeisureExperienceEnabled() && ProfileStore.hasArbitripPoints()) {
			return _.get(_session_manager, 'current_search_session.arbitrip_points_applied', false);
		}

		return false;
	},

	isBreakfastOnly,

	getSessionManager: function () {
		return _session_manager;
	},

	getCurrentSearchSession: function () {
		return _.get(_session_manager, 'current_search_session', false);
	},

	getSessionTerms: function () {
		return _.get(_session_manager, 'current_search_session.search_terms', {});
	},

	isRoomMappingEnabled() {
		return _.get(_session_manager, 'current_search_session.room_mapping.settings.enable_room_mapping', false);
	},

	getRoomMappingVocabulary() {
		return _.get(_session_manager, 'current_search_session.room_mapping.vocabulary', null);
	},

	getUnifiedCatalogData: function () {
		return _.get(_session_manager, 'current_search_session.room_mapping.unified_catalog', null);
	},

	getRoomMappingData: function () {
		return _.get(_session_manager, 'current_search_session.room_mapping.mapping', null);
	},

	areExpired: function () {
		return _expired;
	},

	getTravelPolicy: function () {
		return _.get(_session_manager, 'current_search_results.travel_policy');
	},

	didReturnResults: function () {
		const { current_search_results } = _session_manager;
		if (current_search_results) {
			return current_search_results.length > 0;
		}

		console.warn('ResultsStore.didReturnResults no available search results in session manager');
		return false;
	},

	getSearchSessionCreatedTimestamp: function () {
		return _.get(_session_manager, 'current_search_session.created_timestamp', new Date());
	},

	getTimePassedSinceSearchSessionCreatedInMilliSeconds: function () {
		const created_timestamp = _.get(_session_manager, 'current_search_session.created_timestamp');
		if (created_timestamp) {
			return Date.now() - created_timestamp;
		}

		return -1;
	},

	getRecheckTimestamp: function (hotelId) {
		const search_results = _session_manager.current_search_results;
		if (search_results) {
			const hotel = search_results.getHotelById(hotelId);
			if (hotel) {
				return hotel.recheck_timestamp;
			}
		}

		return null;
	},

	didReturnFilteredResults: function () {
		return _filtered && _filtered.hotels && _filtered.hotels.length > 0;
	},

	didReturnMultipleResults: function () {
		return _.get(_session_manager, 'current_search_results.length', 0) > 1;
	},

	isErrorReturned: function () {
		return _error;
	},

	// Return search results
	getSearchResults: function () {
		const x = Number(_topX);

		if (isValidTopX(x) && Array.isArray(_filtered.hotels)) {
			return Object.assign({}, _filtered, {
				hotels: _filtered.hotels.slice(0, Math.round(x)),
			});
		}

		return _filtered;
	},

	getSearchResultsCompletedSearch: function () {
		return _.get(_session_manager, 'current_search_results.completed_search', false);
	},

	getSearchResultsCompletedHotels: function () {
		return _.get(_session_manager, 'current_search_results.completed_hotels', false);
	},

	getSearchResultsCompletedListening: function () {
		return _.get(_session_manager, 'current_search_results.completed_listening', false);
	},

	getSearchResultsCompletedDelay: function () {
		return _.get(_session_manager, 'current_search_results.completed_delay', false);
	},

	getResultsStatus: function () {
		return _status;
	},

	isInitialStatus: function () {
		return _status === ResultsConstants.STATUSES.INITIAL;
	},

	getMaxDistance: function () {
		if (Number(_maxDistance) === FilterConstants.MAXIMUM_FILTERED_DISTANCE_IN_KM) {
			// return 50; // TODO: important - at the moment, max should be updated here as well as in component!
			return FilterConstants.MAXIMUM_FILTERED_DISTANCE_IN_KM;
		} else {
			return _maxDistance;
		}
	},

	getNameContains: function () {
		return _nameContains;
	},

	getDebugContains: function (field) {
		var deb = _debugContains[field];
		return {
			debugContains: (deb && deb.value) || '',
			reverseLogic: (deb && deb.reverseLogic) || false,
		};
	},

	getMaxPricePerNight: function () {
		const price = Number(_maxPricePerNight);
		if (isNaN(price) || price <= 0 || price >= Number.MAX_SAFE_INTEGER) {
			return '';
		} else {
			return Math.round(_maxPricePerNight);
		}
	},

	getTopX: function () {
		const x = Number(_topX);
		return isValidTopX(x) ? Math.round(x) : '';
	},

	getCurrentToken: function () {
		return _session_manager.current_search_session.search_token;
	},

	getStarsState: function () {
		return _starsFilterData;
	},

	getReviewState: function () {
		return _reviewFilterData;
	},

	getSanitizationStandardsState: function () {
		return _sanitizationStandardsFilterData;
	},

	getMarginsState: function () {
		return _marginsFilterData;
	},

	getRegionsState: function () {
		return _regionsFilterData;
	},

	getAmenitiesState: function () {
		return _amenitiesFilterData;
	},

	getPreferredHotelsState: function () {
		return _preferredHotelsFilterData;
	},

	getCategoriesState: function () {
		return _categoriesFilterData;
	},

	getNeighborhoodsState: function () {
		return _neighborhoodsFilterData;
	},

	getInPolicyState: function () {
		return _inPolicyFilterData;
	},

	getAvailabilityState: function () {
		return _availabilityFilterData;
	},

	getFilters: function () {
		return {
			max_distance: this.getMaxDistance(),
			sanitization_standards: this.getSanitizationStandardsState(),
			margins: this.getMarginsState(),
			top_x: this.getTopX(),
			name_contains: this.getNameContains(),
			categories: this.getCategoriesState(),
			max_price_per_night: this.getMaxPricePerNight(),
			in_policy: this.getInPolicyState(),
			preferred_hotels: this.getPreferredHotelsState(),
			amenities: this.getAmenitiesState(),
			stars: this.getStarsState(),
			review: this.getReviewState(),
			availability: this.getAvailabilityState(),
			regions: this.getRegionsState(),
			neighborhoods: this.getNeighborhoodsState(),
		};
	},

	// getNeighborhoodLabel: function (neighborhood) {
	//     return `${neighborhood} (${neighborhood_counters[neighborhood]})`;
	// },
	getNeighborhoodCount: function (neighborhood) {
		return neighborhood_counters[neighborhood];
	},

	getCustomError: function () {
		return _custom_error;
	},

	// TODO: method for currency conversion

	// Emit Change event
	emitChange: function () {
		this.emit('change');
	},

	// Add change listener
	addChangeListener: function (callback) {
		this.on('change', callback);
	},

	// Remove change listener
	removeChangeListener: function (callback) {
		this.removeListener('change', callback);
	},

	// Emit Change event
	emitGeneralChange(name) {
		this.emit(name);
	},

	// Add change listener
	// Run only one time
	addGeneralChangeListenerOnce(name, callback) {
		this.once(name, callback);
	},

	// Remove change listener
	removeGeneralChangeListener(name, callback) {
		this.removeListener(name, callback);
	},

	getSortMethod: function () {
		return _sortMethod;
	},

	getLastAction: function () {
		return _lastAction;
	},

	getHotelById: function (hotel_id) {
		const search_results = _session_manager.current_search_results;
		if (search_results) {
			return search_results.getHotelById(hotel_id);
		}

		return null;
	},

	getBannedDealIds: function () {
		if (_session_manager) {
			return _session_manager.getBannedDealIds();
		}

		return null;
	},

	getPaymentsExcludedDeal: function (hotelId, deal) {
		const search_results = _session_manager.current_search_results;
		if (search_results && hotelId) {
			const payments_excluded_hotel = search_results.getHotelById(hotelId);
			if (payments_excluded_hotel) {
				return payments_excluded_hotel.getPaymentsExcludedDeal(deal);
			}
		}

		return null;
	},

	// TODO: REFACTOR REFACTOR REFACTOR
	getHotelDealsById: function (hotel_id) {
		const hotel = this.getHotelById(hotel_id);
		return _.get(hotel, 'deals', []);
	},

	getHotelIndexedDealsById: function (hotel_id) {
		const hotel = this.getHotelById(hotel_id);
		return _.get(hotel, 'indexed_deals', {});
	},

	getHotelIndexedPrePayDealsById: function (hotel_id) {
		const hotel = this.getHotelById(hotel_id);
		if (hotel) {
			return hotel.deals.filter((deal) => !deal.post_pay);
		}
		return null;
	},

	getHotelIndexedPostPayDealsById: function (hotel_id) {
		return this.getHotelDealsById(hotel_id).filter((deal) => deal.post_pay);
	},

	getHotelIndexedHalfBoardDealsById: function (hotel_id) {
		return this.getHotelDealsById(hotel_id).filter((d) => !!_.get(d, 'board_bases.half_board', false));
	},

	getHotelIndexedAllInclusiveDealsById: function (hotel_id) {
		return this.getHotelDealsById(hotel_id).filter(
			(d) => !!(_.get(d, 'board_bases.full_board', false) || _.get(d, 'board_bases.all_inclusive', false)),
		);
	},

	getHotelInfoStatusById: function (hotel_id) {
		const hotel = this.getHotelById(hotel_id);
		return _.get(hotel, 'info_status');
	},

	getHotelDealsCachedStatusById: function (hotel_id) {
		const hotel = this.getHotelById(hotel_id);
		return _.get(hotel, 'deals_cached_status');
	},

	getHotelDealsStatusById: function (hotel_id) {
		const hotel = this.getHotelById(hotel_id);
		return _.get(hotel, 'deals_status');
	},

	getHotelRecheckStatusById: function (hotel_id) {
		const hotel = this.getHotelById(hotel_id);
		return _.get(hotel, 'recheck_status');
	},

	calculateRecommendedIndex: function (hotel) {
		let hotels_clone = _session_manager.current_search_session.search_results.hotels.slice(0);
		hotels_clone.sort(compareRecommended);
		return hotels_clone.findIndex((h) => h.id === hotel.id) + 1;
	},

	getRecommendedCount: function () {
		return _session_manager.current_search_session.search_results.hotels.length;
	},

	getRegions: function () {
		const local_regions = require('./_regions'); // ???
		return local_regions || _regions;
		// return Array.isArray(_regions)
		//     ? _regions.filter(region => region.type === 'city')
		//     : _regions;
	},
	markHotelSelected: function ({ selected_hotel_id }) {
		_session_manager.current_search_session.search_results.hotels.forEach((hotel) => {
			hotel.setHotelMatch(selected_hotel_id);
		});
		ensureSort();
		this.emitChange();
	},
	updateSelectedHotelInSession: async function ({ selected_hotel_id }) {
		return _session_manager.updateSelectedHotelInSession({ selected_hotel_id });
	},

	increaseReservationAttempts: function (hotel_id) {
		_session_manager.increaseReservationAttempts(hotel_id);
	},

	getReservationAttemptsObject: function (hotel_id) {
		return _session_manager.getReservationAttemptsObject(hotel_id);
	},

	cleanReservationAttempts: function () {
		_session_manager.cleanReservationAttempts();
	},
});

/// /// /// /// /// /// /// /// /// /// /// /// /// /// /// /// /// /// ///
/// /// /// /// /// /// /// /// /// /// /// /// /// /// /// /// /// ///
/// /// /// /// /// /// /// /// /// /// /// /// /// /// /// /// /// /// ///

function enrichHotelWithAdditionalInfo(data) {
	const search_results = _session_manager.current_search_results;
	if (search_results) {
		const hotel_id = data.hotel_id || data._id;
		let _hotel = search_results.getHotelById(hotel_id);
		if (!_hotel) {
			search_results.addHotels([data]); // TODO: shallow or not?
			_hotel = search_results.getHotelById(hotel_id);
		}
		_hotel.HotelInfo = data;
		_hotel.InfoStatus = HotelConstants.INFO_STATUS.SUCCESS;
	}
}

/// /// /// /// /// /// /// /// /// /// /// /// /// /// /// /// /// /// ///
/// /// /// /// /// /// /// /// /// /// /// /// /// /// /// /// /// ///
/// /// /// /// /// /// /// /// /// /// /// /// /// /// /// /// /// /// ///

// const recalculateFilterAndSort = _.debounce(() => {
const recalculateFilterAndSort = _.throttle(() => {
	const search_results = _session_manager.current_search_results;
	if (search_results) {
		search_results.recalculateRecommendationScore();
		filterByAll();
		// // sortByRecommended();
		// sortByScores();
		ensureSort();
		ResultsStore.emitChange();
	}
}, 1000);

AppDispatcher.register((payload) => {
	const { action } = payload;

	switch (action.actionType) {
		case ProfileConstants.TOGGLE_ARBITRIP_POINTS_APPLIED:
			if (_session_manager.current_search_session) {
				_session_manager.current_search_session.toggleArbitripPointsApplied(action.data.applied);
				ResultsStore.emitChange();
			}
			break;

		case ResultsConstants.SET_RESULTS_FROM_OFFLINE_DB:
			const offline_db_search_session = action.data;
			if (offline_db_search_session) {
				filterByAll();
				ensureSort();
				ResultsStore.emitChange();
				_session_manager.current_search_session.on(SEARCH_SESSION_EVENT.CHANGED, () => {
					// CONSIDER: THROTTLING/DEBOUNCING
					filterByAll();
					ensureSort();
					ResultsStore.emitChange();
				});

				_expired = false;
				_session_manager.current_search_session.on(SEARCH_SESSION_EVENT.EXPIRED, (searchToken) => {
					onResultsExpired(searchToken);
				});
			}
			break;

		case FiltersConstants.UPDATE_CURRENCIES:
			_maxPricePerNightCurrencyExchangeRate = getDisplayCurrencyExchangeRate();
			break;

		case ProfileConstants.DISPLAY_CURRENCY_CHANGED:
			if (_maxPricePerNight) {
				_maxPricePerNight /= _maxPricePerNightCurrencyExchangeRate;

				// _maxPricePerNightCurrency = action.data.currency;
				_maxPricePerNightCurrencyExchangeRate = action.data.response.display_currency_exchange_rate;

				_maxPricePerNight *= _maxPricePerNightCurrencyExchangeRate;
			}
			break;

		// Respond to RECEIVE_DATA action
		case ResultsConstants.CLEAR_SEARCH_RESULTS:
			clearSearchResults();
			_lastAction = null;
			break;

		case ResultsConstants.UPDATE_STATUS:
			updateStatus(action.data.status);
			break;

		case ResultsConstants.ERROR_ON_SEARCH:
			_custom_error = action.data && action.data.custom_error;
			markError(true);
			break;

		case FilterConstants.CLEAR_FILTERS:
			clearFilters();
			_lastAction = ResultsConstants.ACTIONS.FILTER;
			break;

		case FilterConstants.CLEAR_FILTERS_AND_APPLY:
			clearFilters();
			filterByAll();
			_lastAction = ResultsConstants.ACTIONS.FILTER;
			break;

		case MapConstants.CLEAR_MAP_FILTERS:
			clearMapFilters();
			filterByAll();
			_lastAction = ResultsConstants.ACTIONS.FILTER;
			break;

		case FilterConstants.FILTER_BY_DISTANCE:
			filterByDistance(action.data);
			_lastAction = ResultsConstants.ACTIONS.FILTER;
			break;

		case FilterConstants.FILTER_BY_NAME_CONTAINS:
			filterByNameContains(action.data);
			_lastAction = ResultsConstants.ACTIONS.FILTER;
			break;

		case FilterConstants.FILTER_BY_DEBUG_CONTAINS:
			filterByDebugContains(action.data);
			_lastAction = ResultsConstants.ACTIONS.FILTER;
			break;

		case FilterConstants.FILTER_BY_PRICE:
			filterByPrice(action.data.price, action.data.currency);
			_lastAction = ResultsConstants.ACTIONS.FILTER;
			break;

		case FilterConstants.FILTER_BY_TOP_X:
			filterByTopX(action.data);
			_lastAction = ResultsConstants.ACTIONS.FILTER;
			break;

		case FilterConstants.FILTER_BY_STARS:
			filterByStars(action.data.stars, action.data.value);
			_lastAction = ResultsConstants.ACTIONS.FILTER;
			break;

		case FilterConstants.FILTER_BY_STARS_ONLY:
			filterByStarsOnly(action.data.stars);
			_lastAction = ResultsConstants.ACTIONS.FILTER;
			break;

		case FilterConstants.RESET_STARS_FILTER:
			resetStarsFilter();
			_lastAction = ResultsConstants.ACTIONS.FILTER;
			break;

		case FilterConstants.FILTER_BY_REVIEW:
			filterByReview(action.data.review, action.data.value);
			_lastAction = ResultsConstants.ACTIONS.FILTER;
			break;

		case FilterConstants.FILTER_BY_REVIEW_ONLY:
			filterByReviewOnly(action.data.review);
			_lastAction = ResultsConstants.ACTIONS.FILTER;
			break;

		case FilterConstants.RESET_REVIEW_FILTER:
			resetReviewFilter();
			_lastAction = ResultsConstants.ACTIONS.FILTER;
			break;

		case FilterConstants.FILTER_BY_SANITIZATION_STANDARDS:
			filterBySanitizationStandards(action.data);
			_lastAction = ResultsConstants.ACTIONS.FILTER;
			break;

		case FilterConstants.FILTER_BY_MARGIN:
			filterByMargins(action.data.margin, action.data.value);
			_lastAction = ResultsConstants.ACTIONS.FILTER;
			break;

		case FilterConstants.FILTER_BY_MARGIN_ONLY:
			filterByMarginsOnly(action.data.margin);
			_lastAction = ResultsConstants.ACTIONS.FILTER;
			break;

		case FilterConstants.FILTER_BY_AMENITY:
			filterByAmenity(action.data.amenity, action.data.value);
			_lastAction = ResultsConstants.ACTIONS.FILTER;
			break;

		case FilterConstants.FILTER_BY_AMENITY_ONLY:
			filterByAmenityOnly(action.data.amenity);
			_lastAction = ResultsConstants.ACTIONS.FILTER;
			break;

		case FilterConstants.RESET_AMENITIES_FILTER:
			resetAmenitiesFilter();
			_lastAction = ResultsConstants.ACTIONS.FILTER;
			break;

		case FilterConstants.FILTER_BY_PREFERRED_HOTELS:
			filterByPreferredHotels(action.data);
			_lastAction = ResultsConstants.ACTIONS.FILTER;
			break;

		////////////////////////////////////////////////////////////////////////////////

		case FilterConstants.FILTER_BY_CATEGORIES:
			filterByCategories(action.data.category, action.data.value);
			_lastAction = ResultsConstants.ACTIONS.FILTER;
			break;

		case FilterConstants.FILTER_BY_CATEGORIES_ONLY:
			filterByCategoriesOnly(action.data.category);
			_lastAction = ResultsConstants.ACTIONS.FILTER;
			break;

		case FilterConstants.RESET_CATEGORIES_FILTER:
			resetCategoriesFilter();
			_lastAction = ResultsConstants.ACTIONS.FILTER;
			break;

		////////////////////////////////////////////////////////////////////////////////

		case FilterConstants.FILTER_BY_NEIGHBORHOODS:
			filterByNeighborhoods(action.data.neighborhood, action.data.value);
			_lastAction = ResultsConstants.ACTIONS.FILTER;
			break;

		case FilterConstants.FILTER_BY_NEIGHBORHOODS_ONLY:
			filterByNeighborhoodsOnly(action.data.neighborhood);
			_lastAction = ResultsConstants.ACTIONS.FILTER;
			break;

		case FilterConstants.RESET_NEIGHBORHOODS_FILTER:
			resetNeighborhoodsFilter();
			_lastAction = ResultsConstants.ACTIONS.FILTER;
			break;

		////////////////////////////////////////////////////////////////////////////////

		case FilterConstants.FILTER_BY_IN_POLICY:
			filterByInPolicy(action.data);
			_lastAction = ResultsConstants.ACTIONS.FILTER;
			break;

		case FilterConstants.FILTER_BY_AVAILABILITY:
			filterByAvailability(action.data);
			_lastAction = ResultsConstants.ACTIONS.FILTER;
			break;

		case FilterConstants.FILTER_BY_ALL:
			filterByAll();
			break;

		////////////////////////////////////////////////////////

		case SortConstants.SORT_BY_ABSOLUTE_MARGIN:
			sortByAbsoluteMargin();
			_sortMethod = SortConstants.SORT_METHOD.ABSOLUTE_MARGIN;
			_lastAction = ResultsConstants.ACTIONS.SORT;
			break;

		case SortConstants.SORT_BY_MARGIN_PERCENTAGE:
			sortByMarginPercentage();
			_sortMethod = SortConstants.SORT_METHOD.MARGIN_PERCENTAGE;
			_lastAction = ResultsConstants.ACTIONS.SORT;
			break;

		case SortConstants.SORT_BY_RECOMMENDED:
			sortByRecommended();
			_sortMethod = SortConstants.SORT_METHOD.RECOMMENDED;
			_lastAction = ResultsConstants.ACTIONS.SORT;
			break;

		case SortConstants.SORT_BY_SCORES:
			sortByScores();
			_sortMethod = SortConstants.SORT_METHOD.SCORES;
			_lastAction = ResultsConstants.ACTIONS.SORT;
			break;

		case SortConstants.SORT_BY_PRICE:
			sortByPrice();
			_sortMethod = SortConstants.SORT_METHOD.PRICE;
			_lastAction = ResultsConstants.ACTIONS.SORT;
			break;
		case SortConstants.SORT_BY_PRICE_HIGH_TO_LOW:
			sortByPriceHighToLow();
			_sortMethod = SortConstants.SORT_METHOD.PRICE_HIGH_TO_LOW;
			_lastAction = ResultsConstants.ACTIONS.SORT;
			break;

		case SortConstants.SORT_BY_DISTANCE:
			sortByDistance();
			_sortMethod = SortConstants.SORT_METHOD.DISTANCE;
			_lastAction = ResultsConstants.ACTIONS.SORT;
			break;

		case SortConstants.SORT_BY_REVIEWS:
			sortByReviews();
			_sortMethod = SortConstants.SORT_METHOD.REVIEWS;
			_lastAction = ResultsConstants.ACTIONS.SORT;
			break;

		////////////////////////////////////////////////////////////////////////////////
		///////////////////////////////// HOTELS DEALS /////////////////////////////////
		////////////////////////////////////////////////////////////////////////////////

		case HotelConstants.ENRICH_HOTEL_WITH_ADDITIONAL_INFO:
			enrichHotelWithAdditionalInfo(action.data);
			break;

		case HotelConstants.GET_HOTEL_INFO: {
			const search_results = _session_manager.current_search_results;
			if (search_results) {
				const _hotel = search_results.getHotelById(action.data.hotel_id);
				if (_hotel) {
					_hotel.InfoStatus = HotelConstants.INFO_STATUS.BUSY;
				}
			}
			break;
		}

		case HotelConstants.GET_HOTEL_INFO_SUCCEEDED: {
			const search_results = _session_manager.current_search_results;
			if (search_results) {
				const _hotel = search_results.getHotelById(action.data.hotel_id);
				if (_hotel) {
					_hotel.InfoStatus = HotelConstants.INFO_STATUS.SUCCESS;
				}
			}
			break;
		}

		case HotelConstants.GET_HOTEL_INFO_FAILED: {
			const search_results = _session_manager.current_search_results;
			if (search_results) {
				const _hotel = search_results.getHotelById(action.data.hotel_id);
				if (_hotel) {
					_hotel.InfoStatus = HotelConstants.INFO_STATUS.FAIL;
				}
			}
			break;
		}

		////////////////////////////////////////////////////////////////////////////////

		case HotelConstants.GET_HOTEL_DEALS_SUCCEEDED: {
			const { search_terms, hotel_id, res } = action.data;
			if (!_session_manager.current_search_session) {
				const search_token = search_terms.token;
				const recommendation_weights = getRecommendationWeights();
				const room_mapping_settings = ProfileStore.getRoomMappingSettings();

				const options = {
					room_mapping_settings,
					...(ProfileStore.isLeisureExperienceEnabled() ? { arbitrip_points_applied: true } : {}),
					search_results_delay_in_seconds: ProfileStore.getSearchResultsDelayInSeconds(),
				};

				_session_manager.createSearchSession(search_token, search_terms, recommendation_weights, options);
				_session_manager.current_search_session.on(SEARCH_SESSION_EVENT.CHANGED, () => {
					filterByAll();
					ResultsStore.emitChange();
				});

				_expired = false;
				_session_manager.current_search_session.on(SEARCH_SESSION_EVENT.EXPIRED, (searchToken) => {
					onResultsExpired(searchToken);
				});
			}
			_session_manager.current_search_session.onHotelDeals({ hotel_id, ...res });
			break;
		}
		case HotelConstants.FIRE_RECHECKED_DEALS_RESULTS: {
			const { res, hotel_id } = action.data;
			console.log('res:', res);
			if (res) {
				const { listener_id, wait_time } = res;

				if (_session_manager.current_search_session) {
					_session_manager.current_search_session.onRecheck({ hotel_id, listener_id, wait_time });
				}
			}
			break;
		}
		case HotelConstants.FIRE_RECHECKED_DEALS_ERROR:
			console.error('fire recheck error', action.data.error);
			break;

		case HotelConstants.CLEAR_FIRE_RECHECKED_DEALS_RESULTS:
			// TODO: REFACTOR REFACTOR REFACTOR
			// _firebase_recheck.unsubscribe(_current_recheck_listener_id);
			break;

		case SEARCH_CONSTANTS.FIRE_SEARCH:
			const { search_token, search_terms } = action.data;

			const recommendation_weights = getRecommendationWeights();
			const room_mapping_settings = ProfileStore.getRoomMappingSettings();

			const options = {
				room_mapping_settings,
				...(ProfileStore.isLeisureExperienceEnabled() ? { arbitrip_points_applied: true } : {}),
				search_results_delay_in_seconds: ProfileStore.getSearchResultsDelayInSeconds(),
			};

			_session_manager.createSearchSession(search_token, search_terms, recommendation_weights, options);
			filterByAll();
			ResultsStore.emitChange();
			_session_manager.current_search_session.on(SEARCH_SESSION_EVENT.CHANGED, () => {
				// // CONSIDER: THROTTLING/DEBOUNCING
				// filterByAll();
				// ResultsStore.emitChange();
				recalculateFilterAndSort();
			});

			_expired = false;
			_session_manager.current_search_session.on(SEARCH_SESSION_EVENT.EXPIRED, (searchToken) => {
				onResultsExpired(searchToken);
			});

			break;

		case SEARCH_CONSTANTS.FIRE_SEARCH_RESULTS:
			const {
				res: search_res,
				// token: search_res_token
			} = action.data;

			if (search_res) {
				const { private_travel, travel_policy, hotels, listener_id, regions } = search_res;

				updateNeighborhoodCounters(hotels);

				const { current_search_session } = _session_manager;
				if (current_search_session) {
					current_search_session.onSearch({ private_travel, travel_policy, hotels, listener_id });
					current_search_session.on(SEARCH_SESSION_EVENT.SEARCH_HOTELS_DEALS, () => {
						throttledEnsureSort();
						ResultsStore.emitChange();
					});
					current_search_session.on(SEARCH_SESSION_EVENT.SEARCH_COMPLETED, () => {
						throttledEnsureSort();
						ResultsStore.emitChange();
					});
				}

				if (regions) {
					_regions = regions;
				}

				// "TEMP"
				setTimeout(() => {
					filterByAll();
					ResultsStore.emitChange();
				}, 10);
			}
			break;

		case SEARCH_CONSTANTS.FIRE_SEARCH_ERROR:
			console.error('fire search error', action.data.error);
			break;

		case SEARCH_CONSTANTS.CLEAR_FIRE_SEARCH:
			// TODO: REFACTOR REFACTOR REFACTOR
			// _firebase_search.unsubscribe(_current_search_listener_id);

			// "TEMP"
			setTimeout(() => {
				filterByAll();
				ResultsStore.emitChange();
			});
			break;

		////////////////////////////////////////////////////////////////////////////////

		case SEARCH_CONSTANTS.HOTELS_SEARCH_RESULTS:
			const {
				res: hotels_res,
				// token: hotels_res_token
			} = action.data;

			if (hotels_res) {
				const { hotels } = hotels_res;
				const { current_search_session } = _session_manager;
				if (current_search_session) {
					current_search_session.onHotels({ hotels });
					// current_search_session.on(SEARCH_SESSION_EVENT.HOTELS, () => {

					// });
				}

				updateNeighborhoodCounters(hotels);

				// "TEMP"
				setTimeout(() => {
					filterByAll();
					ResultsStore.emitChange();
				}, 10);
			}
			break;

		case SEARCH_CONSTANTS.HOTELS_SEARCH_ERROR:
			console.error('hotels search error', action.data.error);
			break;

		////////////////////////////////////////////////////////////////////////////////

		case ResultsConstants.RECALCULATE_RECOMMENDATIONS:
			const search_results = _session_manager.current_search_results;
			if (search_results) {
				search_results.recalculateRecommendationScore();
				filterByAll();
				// // sortByRecommended();
				// sortByScores();
				ensureSort();
			}
			break;

		case ReservationConstants.NEW_RESERVATION_CANNOT_BE_OPENED:
			_session_manager.banDealInSession({ ...action.data });
			break;
		////////////////////////////////////////////////////////////////////////////////

		case HotelConstants.CLEAR_HOTEL_DATA_BY_ID:
			_session_manager.clearHotelDealsInSession({ hotel_id: action.data });
			break;

		case HotelConstants.BAN_DEAL:
			_session_manager.banDealInSession({ ...action.data });
			break;

		default:
			return true;
	}

	ResultsStore.emitChange();

	return true;
});

export default ResultsStore;
