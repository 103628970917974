const { EVENTS: MANAGEMENT } = require('./management');
const { EVENTS: MENU } = require('./menu');
const { EVENTS: VIEWS } = require('./views');
const { EVENTS: SEARCH } = require('./search');
const { EVENTS: RESULTS } = require('./results');
const { EVENTS: RESERVATION } = require('./reservation');
const { EVENTS: PAYMENT } = require('./payment');
const { EVENTS: BOOKINGS } = require('./bookings');
const { EVENTS: BASKET } = require('./basket');
const { EVENTS: POINTS } = require('./points');
const { EVENTS: USER } = require('./user');
const { EVENTS: TRAVEL_POLICY } = require('./travelPolicy');
const { EVENTS: HTTP } = require('./http');

module.exports = {
    MANAGEMENT,
    MENU,
    VIEWS,
    SEARCH,
    RESULTS,
    RESERVATION,
    PAYMENT,
    BOOKINGS,
    BASKET,
    POINTS,
    USER,
    TRAVEL_POLICY,
    HTTP
}
