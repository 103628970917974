const keyMirror = require('keymirror');

export const EVENTS = keyMirror({
    DOWNLOADED_BOOKINGS_CSV: null,
    FILTERED_BOOKINGS: null,
    FILTERED_BOOKING_REQUESTS: null,
    SENT_BOOKING_CONFIRMATION: null,

    OPEN_BOOKINGS_SIDEPANEL: null,
    OPEN_BOOKING_REQUESTS_SIDEPANEL: null,

    VIEWED_POINTS_REDEEM_MODAL: null,
    POINTS_EDITED: null,
    POINTS_REDEEMED: null,
    POINTS_MODAL_CLOSED: null,

    OPEN_PAY_LATER_MODAL: null,
    CLOSE_PAY_LATER_MODAL: null,
    PAY_NOW_CLICKED: null,
    PAY_LATER_PAYMENT_CREATED: null,
});
