import TripEntity from '../entities/TripRequest';

import ManagementOngoingConstants from '../constants/ManagementOngoingConstants';

import AppDispatcher from '../dispatcher/AppDispatcher';
import { EventEmitter } from 'events';
import _ from 'lodash';

var _ongoing_trips = [];
var _ongoing_trips_status = ManagementOngoingConstants.STATUS.INIT;

// Extend Search Store with EventEmitter to add eventing capabilities
var ManagementOngoingStore = _.extend({}, EventEmitter.prototype, {
	getOngoingTrips: function () {
		return _ongoing_trips.slice(0);
	},

	getOngoingTripsStatus: function () {
		return _ongoing_trips_status;
	},

	// Emit Change event
	emitChange: function () {
		this.emit('change');
	},

	// Add change listener
	addChangeListener: function (callback) {
		this.on('change', callback);
	},

	// Remove change listener
	removeChangeListener: function (callback) {
		this.removeListener('change', callback);
	},
});

// Register callback with AppDispatcher
AppDispatcher.register(function (payload) {
	var action = payload.action;
	//var text;

	switch (action.actionType) {
		case ManagementOngoingConstants.GET_ONGOING_TRIPS:
			_ongoing_trips_status = ManagementOngoingConstants.STATUS.BUSY;
			break;

		case ManagementOngoingConstants.GET_ONGOING_TRIPS_SUCCESS:
			_ongoing_trips = action.data.trips.map((trip) => new TripEntity(trip));
			_ongoing_trips_status = ManagementOngoingConstants.STATUS.SUCCESS;
			break;

		case ManagementOngoingConstants.GET_ONGOING_TRIPS_FAILED:
			_ongoing_trips_status = ManagementOngoingConstants.STATUS.FAILED;
			break;

		default:
			return true;
	}

	// If action was responded to, emit change event
	ManagementOngoingStore.emitChange();

	return true;
});

export default ManagementOngoingStore;
