import AppDispatcher from '../dispatcher/AppDispatcher';
import AuthConstants from '../constants/AuthConstants';

var AuthActions = {
	getUserData: function (data) {
		AppDispatcher.handleAction({
			actionType: AuthConstants.GET_USER_DATA,
			data: data, // ???
		});
	},

	logIn: function (data) {
		AppDispatcher.handleAction({
			actionType: AuthConstants.LOG_IN,
			data: data, // ???
		});
	},

	logOut: function (data) {
		AppDispatcher.handleAction({
			actionType: AuthConstants.LOG_OUT,
			data: data, // ???
		});
	},

	sessionTimeout: function () {
		AppDispatcher.handleAction({
			actionType: AuthConstants.SESSION_TIMEOUT,
		});
	},
};

export default AuthActions;
