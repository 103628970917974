import UsersManagementConstants from '../../constants/UsersManagementConstants';

import AppDispatcher from '../../dispatcher/AppDispatcher';
import { EventEmitter } from 'events';
import _ from 'lodash';

import UserManagementService from './UserManagementService';

let _users = [];
let _status = UsersManagementConstants.STATUS.INITIAL;

let UsersManagementStore = _.extend({}, EventEmitter.prototype, {
	getStatus() {
		return _status;
	},

	getUsers() {
		return _users;
	},

	emitChange: function () {
		this.emit('change');
	},

	addChangeListener: function (callback) {
		this.on('change', callback);
	},

	removeChangeListener: function (callback) {
		this.removeListener('change', callback);
	},
});

AppDispatcher.register(function (payload) {
	let action = payload.action;
	let isChangedState = true;
	switch (action.actionType) {
		// Respond to RECEIVE_DATA action
		case UsersManagementConstants.USERS_RETRIEVAL_SUCCEEDED:
			_users = UserManagementService.getUsersData(action.data);
			_status = UsersManagementConstants.STATUS.SUCCESS;
			break;

		case UsersManagementConstants.USERS_RETRIEVAL_FAILED:
			_status = UsersManagementConstants.STATUS.FAILED;
			break;

		case UsersManagementConstants.RETRIEVE_USERS:
			_status = UsersManagementConstants.STATUS.BUSY;
			break;

		case UsersManagementConstants.ADD_USER_TO_USERS:
			_users = UserManagementService.addUserToUsers(action.data, _users);
			break;

		default: // Do nothing
			isChangedState = false;
	}
	if (isChangedState) {
		UsersManagementStore.emitChange();
	}

	return true;
});

export default UsersManagementStore;
