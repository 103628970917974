import React from 'react';
import PropTypes from 'prop-types';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import UtilsHelper from '../helpers/UtilsHelper';

class CustomFieldsContainer extends React.Component {
	getCustomFieldsRender() {
		const { reservationData, shouldLockForm, handleCustomTextFieldChange } = this.props;
		const companyCustomFields = reservationData.company_custom_fields;

		return Array.isArray(companyCustomFields) && companyCustomFields
			? companyCustomFields
					.filter((field) => field.field_type === 'text')
					.map((field) => (
						<div className="custom-fields-container" key={field._id}>
							<div className="custom-fields-block">
								<label className="title">
									{field.label} {field.field_required && '*'}{' '}
								</label>
								<input
									type="text"
									className="form-control"
									disabled={shouldLockForm}
									onChange={(e) => handleCustomTextFieldChange(e, field)}
								/>
							</div>
						</div>
					))
			: null;
	}

	getCompanyCustomInputRender(selectedCustomField) {
		const { selectedCompanyCustomFieldSecondary, shouldLockForm } = this.props;
		const selectedCustomFieldType = selectedCustomField.field_type;
		const selectedCustomFieldSecondary =
			selectedCustomField && selectedCustomField.list
				? selectedCustomField.list[selectedCompanyCustomFieldSecondary]
				: null;
		const selectedCustomFieldSecondaryType = selectedCustomFieldSecondary
			? selectedCustomFieldSecondary.field_type
			: null;

		switch (selectedCustomFieldType) {
			case 'combobox':
				if (selectedCustomFieldSecondaryType === 'text') {
					return <input type="text" className="form-control" disabled={shouldLockForm} />;
				} else if (selectedCustomFieldSecondaryType === 'number') {
					return <input type="number" className="form-control" disabled={shouldLockForm} />;
				} else if (selectedCustomFieldSecondaryType === 'datetime') {
					return (
						<div className="check-date-container" id="custom-fields-date-container">
							<input
								type="text"
								className="form-control date-range"
								data-date-format="dd/mm/yy"
								disabled={shouldLockForm}
							/>
							<i className="fa fa-calendar" />
						</div>
					);
				}
				break;
			case 'text':
				return <input type="text" className="form-control" disabled={shouldLockForm} />;
			case 'number':
				return <input type="number" className="form-control" disabled={shouldLockForm} />;
			case 'datetime':
				return (
					<div className="check-date-container" id="custom-fields-date-container">
						<input
							type="text"
							className="form-control date-range"
							data-date-format="dd/mm/yy"
							disabled={shouldLockForm}
						/>
						<i className="fa fa-calendar" />
					</div>
				);
			default:
				return null;
		}
	}

	getCustomFieldsOptionsRender() {
		const {
			reservationData,
			selectedCompanyCustomField,
			selectedCompanyCustomFieldSecondary,
			shouldLockForm,
			handleSelectedCustomFieldChange,
			handleSelectedSecondaryCustomFieldChange,
		} = this.props;

		const customFields = reservationData.company_custom_fields;

		if (!Array.isArray(customFields) || !customFields.length) {
			return null;
		}

		const firstComboboxCustomField = customFields.find(
			(customField) => Array.isArray(customField.list) && customField.list.length,
		);

		if (!firstComboboxCustomField) {
			return null;
		}

		const customFieldsPrimalLabel = firstComboboxCustomField.label || 'Custom Field';
		const customFieldsList = firstComboboxCustomField.list;
		const selectedCustomField = customFieldsList[selectedCompanyCustomField];

		return (
			<div className="custom-fields-container">
				<div className="custom-fields-block">
					<FormControl fullWidth variant="outlined">
						<label className="title">
							{customFieldsPrimalLabel} {firstComboboxCustomField.field_required && '*'}
						</label>
						<Select
							className={selectedCompanyCustomField >= 0 ? 'selected' : ''}
							value={selectedCompanyCustomField}
							onChange={handleSelectedCustomFieldChange}
							disabled={shouldLockForm}
							displayEmpty
						>
							<MenuItem disabled value="-1">
								-- select an option --
							</MenuItem>
							{customFieldsList.sort(UtilsHelper.sortCustomSelectAlphabetically).map((field, index) => (
								<MenuItem key={index} value={index}>
									{field.label}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</div>
				{selectedCustomField && selectedCustomField.field_type === 'combobox' ? (
					<div className="custom-fields-block no-top-padding">
						<FormControl fullWidth variant="outlined">
							<Select
								className={
									selectedCompanyCustomFieldSecondary >= 0
										? 'companyCustomField selected'
										: 'companyCustomField'
								}
								value={selectedCompanyCustomFieldSecondary}
								onChange={handleSelectedSecondaryCustomFieldChange}
								disabled={shouldLockForm}
								displayEmpty
							>
								<MenuItem disabled value="-1">
									-- select an option --
								</MenuItem>
								{selectedCustomField.list
									.sort(UtilsHelper.sortCustomSelectAlphabetically)
									.map((field, index) => (
										<MenuItem key={index} value={index}>
											{field.label}
										</MenuItem>
									))}
							</Select>
						</FormControl>
					</div>
				) : null}
				{selectedCustomField && this.getCompanyCustomInputRender(selectedCustomField) ? (
					<div className="custom-fields-block">{this.getCompanyCustomInputRender(selectedCustomField)}</div>
				) : null}
			</div>
		);
	}

	isEmptyRender() {
		return !this.getCustomFieldsRender()?.length && !this.getCustomFieldsOptionsRender();
	}

	render() {
		return (
			!this.isEmptyRender() && (
				<div className="inputs-container">
					{this.getCustomFieldsRender()}
					{this.getCustomFieldsOptionsRender()}
				</div>
			)
		);
	}
}

CustomFieldsContainer.propTypes = {
	reservationData: PropTypes.any.isRequired,
	shouldLockForm: PropTypes.bool.isRequired,
	handleCustomTextFieldChange: PropTypes.func.isRequired,
	handleSelectedCustomFieldChange: PropTypes.func.isRequired,
	handleSelectedSecondaryCustomFieldChange: PropTypes.func.isRequired,
	selectedCompanyCustomField: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	selectedCompanyCustomFieldSecondary: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default CustomFieldsContainer;
