// import Hotel from './Hotel';
import moment from 'moment';
import _ from 'lodash';

function TopCityReport(name, top_city) {
	this.name = name.replace(/_/g, ' ');

	this.totalNumberOfTrips = top_city.trips;
	this.total_room_nights = top_city.total_nights;
	this.totalSpent = Math.round(top_city.total_spent);
	this.totalSpentPerNight = top_city.total_spent_one_night;
	// this.averagePricePerNight = parseFloat(top_city.avg_night_price.toFixed(1));
	this.averagePricePerNight = Math.round(top_city.avg_night_price);

	let outOfPolicyPercentage = Math.round((100 * top_city.trips_out_of_policy) / this.totalNumberOfTrips);
	this.tripsOutOfPolicy = top_city.trips_out_of_policy + ' (' + outOfPolicyPercentage + '%)';
}

function TopTraveler(id, top_traveler) {
	this.id = id;

	this.fullName = top_traveler.full_name;
	this.totalNumberOfTrips = top_traveler.trips;
	this.total_room_nights = top_traveler.total_room_nights;
	this.totalSpent = Math.round(top_traveler.cost);
}

function dayOfYear() {
	var now = new Date();
	var start = new Date(now.getFullYear(), 0, 0);
	var diff = now - start;
	var oneDay = 1000 * 60 * 60 * 24;
	var day = Math.floor(diff / oneDay);
	console.log('Day of year: ' + day);
	return day;
}

function AnnualBudgetActualSpend(cost_center, budget_spend) {
	this.cost_center = cost_center;

	this.annual_budget = Math.round(budget_spend.annual_budget);
	this.actual_spend = Math.round(budget_spend.actual_spend);
	// this.remaining_budget = Math.max(this.annual_budget - this.actual_spend, 0);
	this.remaining_budget = this.annual_budget - this.actual_spend;
	let days = dayOfYear();
	let burn_rate = this.actual_spend / days;
	this.annual_prediction = Math.round(burn_rate * 365);
	this.variance = Math.round((100 * (this.annual_prediction - this.annual_budget)) / this.annual_budget); // + '%';
	// this.trips_out_of_policy = budget_spend.trips_out_of_policy;
	this.annual_prediction = this.annual_prediction.toLocaleString();
	this.annual_prediction += ' (' + (this.variance > 0 ? '+' : '') + this.variance + '%)';
}

function Report(report) {
	// this.id = reservation._id;

	this.startDate = moment(report.start);
	this.endDate = moment(report.end);

	this.currency = report.currency; // TODO: discuss - how come currency is not per deal...

	this.totalNumberOfTrips = report.trips;
	this.totalNumberOfOOPTrips = report.oop_count;
	this.totalNumberOfIPTrips = Math.max(0, report.trips - report.oop_count);
	this.total_room_nights = report.total_nights;
	this.total_out_of_policy_spendings = report.oop_spent;
	this.totalSpent = report.total_spent;
	this.totalOOPSpent = report.oop_spent;
	this.totalSpentPerNight = report.total_spent_one_night;
	this.totalBookingWindowInDays = report.total_booking_window;

	this.total_bookings = report.total_bookings;

	this.averagePricePerNight = Math.round(report.avg_night_price);
	this.averageLengthOfStayInDays = (report.avg_stay && parseFloat(report.avg_stay.toFixed(1))) || 0;
	this.averageBookingWindowInDays = report.avg_booking_window;

	// this.topCities = {};
	// for (var key in report.top_cities) {
	//   // this.topCities[key] = new Report(report.top_cities[key]);
	//   this.topCities[key] = new TopCity(key, report.top_cities[key]);
	// }

	this.topCities = [];
	for (var name in report.top_cities) {
		this.topCities.push(new TopCityReport(name, report.top_cities[name]));
	}
	this.topCities.sort(function (a, b) {
		// return b.totalSpent - a.totalSpent;
		// return b.totalNumberOfTrips - a.totalNumberOfTrips;
		const diff = b.total_room_nights - a.total_room_nights;
		if (diff === 0) {
			return b.totalSpent - a.totalSpent;
		}
		return diff;
	});

	//oop_top_bookings

	this.show_travel_policy_stats = report.show_travel_policy_stats === true;
	if (this.show_travel_policy_stats === true) {
		let oop_parsed = [];
		report.oop_top_bookings.forEach((resv) => {
			let country = _.get(resv, 'hotel.country_code') || _.get(resv, 'hotel.country') || '';
			oop_parsed.push({
				checkIn: moment(_.get(resv, 'deal.details.check_in')).format('DD/MM/YY'),
				checkOut: moment(_.get(resv, 'deal.details.check_out')).format('DD/MM/YY'),
				travelers: resv.travellers.join(', '),
				hotelName: _.get(resv, 'hotel.name'),
				cityCountry: _.get(resv, 'hotel.city') + (country ? ', ' + country : ''),
				deviation: resv.oop_spent + ' (' + resv.oop_overspend_precentage + '%)',
				currency: resv.currency,
			});
		});

		this.topOOPBookings = oop_parsed;
	}

	this.topTravelers = [];
	for (var id in report.top_travellers) {
		this.topTravelers.push(new TopTraveler(id, report.top_travellers[id]));
	}
	this.topTravelers.sort(function (a, b) {
		// return b.totalSpent - a.totalSpent;
		// return b.totalNumberOfTrips - a.totalNumberOfTrips;
		const diff = b.total_room_nights - a.total_room_nights;
		if (diff === 0) {
			return b.totalSpent - a.totalSpent;
		}
		return diff;
	});

	this.annualBudgetActualSpend = [];
	if (report.annualBudgetActualSpend) {
		for (var cost_center in report.annualBudgetActualSpend) {
			this.annualBudgetActualSpend.push(
				new AnnualBudgetActualSpend(cost_center, report.annualBudgetActualSpend[cost_center]),
			);
		}
		this.annualBudgetActualSpend.sort(function (a, b) {
			return b.annual_budget - a.annual_budget;
		});
	}

	console.info('Report', this);
	console.info('Report', this);
	console.info('Report', this);
	console.info('Report', this);
}

function MonthlyReport(month_year, monthly_report) {
	// this.monthYear = month_year; // example: "Sep-16"

	this.currency = monthly_report.currency; // TODO: discuss - how come currency is not per deal...

	this.totalNumberOfTrips = monthly_report.trips;
	this.total_room_nights = monthly_report.total_nights;
	this.totalSpent = Math.round(monthly_report.total_spent);
	this.totalSpentPerNight = monthly_report.total_spent_one_night;
	// this.averagePricePerNight = parseFloat(monthly_report.avg_night_price.toFixed(1));
	this.averagePricePerNight = Math.round(monthly_report.avg_night_price);

	this.year = monthly_report.year; // example: 2016
	this.month = monthly_report.month; // example: 9
	this.label = monthly_report.label; // example: "September"
}

export default {
	Report,
	MonthlyReport,
};
