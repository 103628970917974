const getCompanyObject = (company) => {
    if (!company) {
        return;
    }

    return {
        ...(company.id && { company_id: company.id }),
        ...(company.name && { company_name: company.name })
    };
};

module.exports = {
    getCompanyObject
};