import _ from 'lodash';
import React from 'react';
import BookingField from './BookingDrawerField.react';
import Currencies from 'arbitrip-common/general/utils/Currencies';
import { Tooltip } from '@mui/material';
import PriceBreakdown from 'arbitrip-common/client/utils/PriceBreakdown';

const getPriceString = (price, currency) => Currencies.getPriceWithDisplayCurrencyByPrecision(price, 1, currency, 2);

const BookingDrawerPaymentBreakdownDetails = ({ booking }) => {
	if (!booking || !booking.payment || !booking.deal?.expedia_pricing) {
		return null;
	}
	const { deal } = booking;
	const pricing = deal.expedia_pricing;
	const currency = pricing.currency;

	const taxServiceFeeExist = !!pricing.taxesAndFees;
	const totalPricePaid = _.get(deal, 'cheapopoPricing.cheapopoTotalPrice', 0);
	const isPaidInFull = pricing.totalPayNow === pricing.totalPrice;
	const payNowExist = !!pricing.totalPayNow;
	const totalPayLaterExist = !!pricing.totalPayLater;

	return (
		<BookingField
			fieldTitle="Payment Details"
			fieldValue={
				<React.Fragment>
					<div className="placeholder" />
					{taxServiceFeeExist && (
						<div className="payment-details breakdown">
							<Tooltip title={PriceBreakdown.EXPEDIA_TOOLTIP_TEXT} placement="bottom-end" arrow>
								<div className={'tooltip-icon text'}>Taxes and Fees</div>
							</Tooltip>
							<div>{getPriceString(pricing.taxesAndFees, pricing.taxesAndFeesCurrency)}</div>
						</div>
					)}

					<div className="payment-details bold margin-top margin-bottom breakdown">
						<div>Total {isPaidInFull ? 'Paid ' : ''}Price</div>
						<div>{getPriceString(pricing.totalPrice, currency)}</div>
					</div>

					{payNowExist && !isPaidInFull && (
						<div className="payment-details breakdown">
							<div>Paid</div>
							<div>{getPriceString(totalPricePaid, currency)}</div>
						</div>
					)}
					{totalPayLaterExist && (
						<div className="payment-details breakdown">
							<div>Pay at property</div>
							<div>{getPriceString(pricing.totalPayLater, pricing.totalPayLaterCurrency)}</div>
						</div>
					)}
					<div className="placeholder" />
					<div className="placeholder" />
				</React.Fragment>
			}
		/>
	);
};

export default BookingDrawerPaymentBreakdownDetails;
