import _ from 'lodash';
import React, { useMemo } from 'react';
import { Button, CircularProgress, Menu, MenuItem } from '@mui/material';
import { ReactComponent as DownloadIcon } from '../../../img/bookings/download.svg';
import { CSV_EXPORT_TYPES } from 'arbitrip-common/general/constants';
import BookingsActions from '../../../actions/BookingsActions';
import Analytics from 'arbitrip-common/client/analytics';
import BookingsConstants from '../../../constants/BookingsConstants';

const ExportButton = ({ profile, disabled, downloadStatus, activeFilters }) => {
	const [exportAnchorEl, setExportAnchorEl] = React.useState(null);
	const [exportMenuWidth, setExportMenuWidth] = React.useState(null);

	const exportButtonRef = React.useRef(null);

	const priority_export_enabled = useMemo(() => {
		return _.get(profile, 'company.settings', false);
	}, [profile]);

	React.useEffect(() => {
		if (exportButtonRef.current) {
			setExportMenuWidth(exportButtonRef.current.offsetWidth);
		}
	}, []);

	const onExportClick = (e) => {
		setExportAnchorEl(e.currentTarget);
	};

	const onExportClose = () => {
		setExportAnchorEl(null);
	};

	const onDownloadBookingsCsvClick = (exportType) => {
		const company_id = _.get(profile, 'company.id');
		const is_agent = _.get(profile, 'agent') || _.get(profile, 'super_agent');

		BookingsActions.downloadedBookingsCsv(is_agent, activeFilters, exportType);
		Analytics.actions.interactions.downloadedBookingsCsv(company_id, is_agent, activeFilters);
	};

	const onExportMenuChoice = (exportType) => {
		onExportClose();
		onDownloadBookingsCsvClick(exportType);
	};

	const isBusy = () => downloadStatus === BookingsConstants.STATUS.BUSY;

	return (
		<div className="bookings-filters-component__filter export no-label">
			<Button
				id="clear-input"
				variant="outlined"
				size="large"
				aria-controls="dropdown-menu"
				aria-haspopup="true"
				startIcon={
					isBusy() ? (
						<div className="busy-indicator">
							<CircularProgress size={16} />
						</div>
					) : (
						<DownloadIcon />
					)
				}
				onClick={priority_export_enabled ? onExportClick : onDownloadBookingsCsvClick(CSV_EXPORT_TYPES.CSV)}
				ref={exportButtonRef}
				disabled={disabled || isBusy()}
			>
				Download
			</Button>
			{priority_export_enabled && (
				<Menu
					id="dropdown-menu"
					anchorEl={exportAnchorEl}
					open={Boolean(exportAnchorEl)}
					onClose={onExportClose}
					PaperProps={{
						style: {
							width: exportMenuWidth,
						},
					}}
				>
					<MenuItem onClick={() => onExportMenuChoice(CSV_EXPORT_TYPES.CSV)}>CSV</MenuItem>
					<MenuItem onClick={() => onExportMenuChoice(CSV_EXPORT_TYPES.PRIORITY)}>Priority</MenuItem>
				</Menu>
			)}
		</div>
	);
};

export default ExportButton;
