const { letters_with_spacing } = require("../../general/constants/regexes");

const REGEX = {
    REGEX: {
        LETTERS_AND_SPACING: letters_with_spacing
    }
};

const USER_MANAGEMENT_ROWS = {
    USER_MANAGEMENT_ROWS: {
        FULL_NAME: 'full_name',
        EMAIL: 'email',
        ROLE: 'role',
        STATUS: 'status',
        APPROVER: 'approver',
    }
};

const SORT_DIRECTION = {
    SORT_DIRECTION: {
        ASC: 'asc',
        DESC: 'desc',
    }
};

const USER_STATUS = {
    USER_STATUS: {
        ACTIVE: 'Active',
        INACTIVE: 'Inactive',
        SUSPENDED: 'Suspended',
    }
};

const SYSTEM_ROLES = {
    SYSTEM_ROLES: {
        SUPERVISOR: 'supervisor',
        SUPER_TRAVEL_AGENT: 'super_travel_agent',
        TRAVEL_AGENT: 'travel_agent',
        EXECUTIVE: 'executive',
        TRAVEL_MANAGER: 'travel_manager',
        REGIONAL_ADMIN: 'regional_admin',
        EMPLOYEE: 'employee',
        APPROVER: 'approver',
    }
};

const SYSTEM_ROLES_ORDER = {
    SYSTEM_ROLES_ORDER: [
        SYSTEM_ROLES.SYSTEM_ROLES.SUPERVISOR,
        SYSTEM_ROLES.SYSTEM_ROLES.SUPER_TRAVEL_AGENT,
        SYSTEM_ROLES.SYSTEM_ROLES.TRAVEL_AGENT,
        SYSTEM_ROLES.SYSTEM_ROLES.EXECUTIVE,
        SYSTEM_ROLES.SYSTEM_ROLES.TRAVEL_MANAGER,
        SYSTEM_ROLES.SYSTEM_ROLES.REGIONAL_ADMIN,
        SYSTEM_ROLES.SYSTEM_ROLES.EMPLOYEE,
        SYSTEM_ROLES.SYSTEM_ROLES.APPROVER,
    ]
};

const ORDER_BY_TYPES = {
    ORDER_BY: {
        FULL_NAME: 'full_name',
        EMAIL: 'email'
    }
};

const USERS_MANAGEMENT_CONSTANTS = {
    STATUS: {
        INITIAL: 'initial',
        BUSY: 'busy',
        SUCCESS: 'success',
        FAILED: 'failed',
    },
};

const REQUESTS_CONSTANTS = {
    REQUESTS_STATUS: {
        INITIAL: 'initial',
        BUSY: 'busy',
        SUCCESS: 'success',
        FAILED: 'failed',
    }
};

const MESSAGES = {
    MESSAGES: {
        NEW_USER: 'User has been added successfully!',
        EDIT_USER: 'User has been updated successfully!',
        ACTIVE_USER: 'User has been activated successfully!',
        SUSPEND_USER: 'User has been suspended successfully!',
        RESET_PASSWORD: 'Reset password email has been sent to the user.',
        INVITATION_SENT: 'Invitation sent successfully!'
    },
    ERRORS: {
        SAVE_USER: 'There was a problem to save the user.',
        RESET_PASSWORD: 'There was a problem to reset the password.',
        ACTIVE_USER: 'There was a problem activating the user.',
        SUSPEND_USER: 'There was a problem suspending the user.',
        INVITATION_SENT: 'There was a problem inviting the user.'
    }
};

// Define action constants
module.exports = Object.assign({},
    REGEX,
    ORDER_BY_TYPES,
    USERS_MANAGEMENT_CONSTANTS,
    SORT_DIRECTION,
    USER_MANAGEMENT_ROWS,
    USER_STATUS,
    SYSTEM_ROLES,
    SYSTEM_ROLES_ORDER,
    MESSAGES,
    REQUESTS_CONSTANTS
);
