import { UsersManagementConstants } from '../constants';

export const getHighestRole = (roles = []) => {
    let highestRole;

    if (roles && roles.length > 0) {
        UsersManagementConstants.SYSTEM_ROLES_ORDER.every(r => {
            if (roles.indexOf(r) > -1) {
                highestRole = r;
                return false;
            }

            // don't remove the return true
            // because the 'every' function loop will break
            return true;
        });
    }

    return highestRole;
};
