
const getPointsObject = (points) => {
    if (!points) {
        return;
    }

    return {
        ...(points.show && { show_points: points.show }),
        ...(points.balance && { points_balance: points.balance }),
        ...(points.currency && { points_currency: points.currency }),
        ...(points.points_ex_rate && { points_ex_rate: points.points_ex_rate }),
        ...(points.max_cap && { points_max_cap: points.max_cap }),
        ...(points.provider && { points_provider: points.provider }),
        ...(points.user_chosen_points && { user_chosen_points: points.user_chosen_points }),
        ...(points.status && { points_status: points.status })
    };
};

module.exports = {
    getPointsObject
};