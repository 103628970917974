const CONSTANTS = module.exports = {};

CONSTANTS.ROOM_TYPE_MATCHING_STEP_TYPE = {
    BEDS_COUNT: 'BedsCount',
    VIEW: 'View',
};

CONSTANTS.ROOM_TYPE_MATCHING_MODEL_DEBUG_TYPE = {
    DEBURR: 'deburr',
    FILTER: 'filter',
    IF: 'if',
    JOIN: 'join',
    REGEX: 'regex',
    SORT: 'sort',
    TO_LOWER: 'toLower',
    TOKENIZER: 'tokenizer',
    WORDS: 'words'
};

CONSTANTS.BED_TYPES = {
    SINGLE: 'single',
    TWIN: 'twin',
    DOUBLE: 'double',
    QUEEN: 'queen',
    KING: 'king'
};

CONSTANTS.BED_TYPES_BY_PRIORITY = [
    CONSTANTS.BED_TYPES.SINGLE,
    CONSTANTS.BED_TYPES.TWIN,
    CONSTANTS.BED_TYPES.DOUBLE,
    CONSTANTS.BED_TYPES.QUEEN,
    CONSTANTS.BED_TYPES.KING,
];

CONSTANTS.ROOM_CLASSES = {
    STANDARD: 'standard',
    DELUXE: 'deluxe',
    SUPERIOR: 'superior',
    PREMIUM: 'premium',
    EXECUTIVE: 'executive',
    JUNIOR_SUITE: 'junior suite',
    SUITE: 'suite',
    EXECUTIVE_SUITE: 'executive suite',
};

CONSTANTS.ROOM_CLASSES_BY_PRIORITY = [
    CONSTANTS.ROOM_CLASSES.STANDARD,
    CONSTANTS.ROOM_CLASSES.DELUXE,
    CONSTANTS.ROOM_CLASSES.SUPERIOR,
    CONSTANTS.ROOM_CLASSES.PREMIUM,
    CONSTANTS.ROOM_CLASSES.EXECUTIVE,
    CONSTANTS.ROOM_CLASSES.JUNIOR_SUITE,
    CONSTANTS.ROOM_CLASSES.SUITE,
    CONSTANTS.ROOM_CLASSES.EXECUTIVE_SUITE,
];

CONSTANTS.ROOM_TYPES = {
    ROOM: 'room',
    APARTMENT: 'apartment',
    VILLA: 'villa'
};

CONSTANTS.ROOM_TYPES_BY_PRIORITY = [
    CONSTANTS.ROOM_TYPES.ROOM,
    CONSTANTS.ROOM_TYPES.APARTMENT,
    CONSTANTS.ROOM_TYPES.VILLA,
];

CONSTANTS.NON_SMOKING = [
    'non smoking'
];