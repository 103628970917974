const _ = require('lodash');
const React = require('react');

const RESERVATION_DIALOG_VARIANT = {
	PRICE_CHANGED: 'price changed',
	SUCCESS: 'success',
	PENDING: 'pending',
	FAILED: 'failed',
	ROOM_NOT_AVAILABLE: 'room not available',
	QUOTE: 'quote',
	OUT_OF_POLICY: 'out of policy',
};

function isPendingStatus(status) {
	return _.isString(status) && status.includes('pending');
}

function RoomAvailable(short_id) {
	return (
		<div className="reservation-modal-content">
			Reservation id: {short_id}
			<br />
			{`You'll get an email once the reservation is confirmed`}
			<br />
			<br />
			In any question contact us
			<br />
			<span>US: +1 (650) 532 9354</span> | <span>UK: +44 (20) 3608 0404</span> |{' '}
			<span>IL: +972 (3) 738 2100</span>
		</div>
	);
}

function RoomNotAvailableError() {
	return (
		<div className="reservation-modal-content modal-out-of-policy-request-problem">
			It appears the room is currently unavailable.
			<br />
			If you have any questions, please feel free to contact us:
			<br />
			<span>US: +1 (650) 532 9354</span> | <span>UK: +44 (20) 3608 0404</span> |{' '}
			<span>IL: +972 (3) 738 2100</span>
		</div>
	);
}

export { isPendingStatus, RoomAvailable, RoomNotAvailableError, RESERVATION_DIALOG_VARIANT };
