import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';

import { Button, Dialog, AppBar, Toolbar, IconButton, Typography, Slide } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import _ from 'lodash';

import classNames from 'classnames';

import Constants from './constants';

import MapBoxHotelCard from './MapBoxHotelCard.react';
import MapBoxStyles from './MapBoxStyles.react';
import LocationService from '../../../../../utils/LocationService';
import FiltersComponent from '../../FiltersComponent.react';

import PopUp from './PopUp';
import DestinationMarker from './DestinationMarker';
import Currencies from '../../../../../utils/Currencies';
import MapActions from '../../../../../actions/MapActions';

import ResultsStore from '../../../../../stores/ResultsStore';
import MapStore from '../../../../../stores/MapStore';

import Config from 'arbitrip-common/client/utils/Config';

const { PIN_MARKER_URL, DEFAULT_ZOOM } = Constants;

const drawerWidth = 330;
const POPUPS = 'popups';
const DESTINATION_MARKER = 'destination-marker';

const colors = [
	'#FF0000',
	'#00FF00',
	// '#0000FF',
	'#FFFF00',
	'#FF00FF',
	'#00FFFF',
	'#FFFFFF',

	'#FF8000',
	'#FF0080',
	'#80FF00',
	'#8000FF',
	'#00FF80',
	'#0080FF',

	'#FFFFFF',
	'#000000',
];

const region_options = {
	high_level_region: {
		strokeColor: '#00FF00',
		strokeOpacity: 0.8,
		strokeWeight: 5,
		fillColor: '#00FF00',
		fillOpacity: 0.35,
	},
	multi_city_vicinity: {
		strokeColor: '#FF0000',
		strokeOpacity: 0.8,
		strokeWeight: 4,
		fillColor: '#FF0000',
		fillOpacity: 0.25,
	},
	city: {
		strokeColor: '#0000FF',
		strokeOpacity: 0.8,
		strokeWeight: 3,
		fillColor: '#0000FF',
		fillOpacity: 0.15,
	},
	neighborhood: {
		strokeColor: '#FFFF00',
		strokeOpacity: 0.8,
		strokeWeight: 2,
		fillColor: '#FFFF00',
		fillOpacity: 0.05,
	},
};

const styles = (theme) => ({
	dialog: {
		// Above intercom icon
		zIndex: 2147483001,
	},
	appBar: {
		position: 'relative',
	},
	flex: {
		flex: 1,
	},
	heightContainer: {
		height: '100%',
	},
	container: {
		width: '100%',
		height: '100%',
		...PopUp.getStyle(),
		...DestinationMarker.getStyle(),
	},
	mapBox: {
		width: '100%',
		height: '100%',
	},
	cardWrapper: {
		position: 'absolute',
		bottom: 70,
		left: 'calc(50% - 350px)',
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
	},
	drawerHeader: {
		display: 'flex',
		alignItems: 'center',
		padding: '0 8px',
		...theme.mixins.toolbar.dense,
		justifyContent: 'flex-end',
	},
	mapBoxCardContainer: {
		position: 'relative',
		width: '100%',
		height: '100%',
	},
});

function getMapBoxState() {
	return {
		mapData: MapStore.getMapData(),
		fullMapMode: MapStore.getFullMapMode(),
	};
}

// eslint-disable-next-line react/display-name
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

class MapBoxOverlay extends React.Component {
	constructor(props) {
		super(props);

		this.hotelsPopups = [];
		this.destinationMarker = null;
		this.activeHotelId = null;

		this.polygons = {
			high_level_region: [],
			multi_city_vicinity: [],
			city: [],
			neighborhood: [],
		};
		this.heatmap = null;
		this.max_distance_circle = null;

		this.state = {
			...getMapBoxState(),
			open: props.open,
			hotelCardData: null,
			openHotelCard: false,
		};

		this.initMap = this.initMap.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.setPopups = this.setPopups.bind(this);
		this.setDestinationMarker = this.setDestinationMarker.bind(this);
		this.updateMapData = this.updateMapData.bind(this);
		this._onChange = this._onChange.bind(this);

		this.drawPolygons = this.drawPolygons.bind(this);
		this.drawRegion = this.drawRegion.bind(this);
		this.drawHeatmap = this.drawHeatmap.bind(this);
	}

	componentDidUpdate(prevProps) {
		const { open } = this.props;

		const justOpened = !prevProps.open && open;
		const justClosed = prevProps.open && !open;

		if (justOpened || justClosed) {
			this.setState({ open });

			if (justOpened) {
				const _self = this;

				setTimeout(() => {
					_self.setState({ mapData: MapStore.getMapData() });
					_self.initMap();
				});
			}
		}

		const prev_hotels = _.get(prevProps.searchResults, 'hotels', []);
		const hotels = _.get(this.props.searchResults, 'hotels', []);
		const price_path = `${this.props.breakfastOnly ? 'representativeBreakfastDeal' : 'representativeDeal'}.pricePerNight`;

		if (prev_hotels.length !== hotels.length) {
			this.refreshPopups();
		} else {
			for (let i = 0; i < hotels.length; i++) {
				if (prev_hotels[i].id !== hotels[i].id) {
					return this.refreshPopups();
				} else {
					const prev_price = _.get(prev_hotels[i], price_path);
					const price = _.get(hotels[i], price_path);
					if (prev_price !== price) {
						return this.refreshPopups();
					}
				}
			}
		}

		if (
			_.get(prevProps, 'searchTerms.destination.place.place_id') !==
			_.get(this.props, 'searchTerms.destination.place.place_id')
		) {
			this.setState({ did_draw_polygon: false });
		}
		if (!this.state.did_draw_polygon && this.map && this.props.regions) {
			this.drawPolygons();
			// this.drawHeatmap();
			this.setState({ did_draw_polygon: true });
		}
	}

	drawRegion(region, color, opacity) {
		if (Config.research_mode) {
			// if (region.properties > 0) {
			const polygon_type = _.get(region, 'coordinates.bounding_polygon.type');
			const region_coordinates = _.get(region, 'coordinates.bounding_polygon.coordinates', []);
			console.log(112, 'actually drawing', region.name_full);
			for (const coordinates of region_coordinates) {
				console.log(
					112,
					'drawing coordinates',
					(polygon_type === 'MultiPolygon' ? coordinates[0] : coordinates).length,
				);
				const paths = (polygon_type === 'MultiPolygon' ? coordinates[0] : coordinates)
					.filter(([lng, lat]) => !isNaN(lng) && !isNaN(lat))
					.map(([lng, lat]) => new window.google.maps.LatLng(parseFloat(lat), parseFloat(lng)));
				region_options[region.type] = region_options[region.type] || {};
				let options = region_options[region.type];
				if (color) {
					options.fillColor = color;
					options.strokeColor = color;
				}
				this.polygons[region.type] = this.polygons[region.type] || [];
				this.polygons[region.type].push(
					new window.google.maps.Polygon({
						paths,
						...options,
						map: this.map,
					}),
				);
			}
			// }
		}
	}

	drawPolygons() {
		if (Config.research_mode) {
			for (const region_type in this.polygons) {
				for (const polygon of this.polygons[region_type]) {
					polygon.setMap(null);
				}
				this.polygons[region_type] = [];
			}
			if (Array.isArray(this.props.regions)) {
				for (let i = 0; i < this.props.regions.length; i++) {
					const region = this.props.regions[i];
					// if (region.properties >= 0) {
					// const color = colors[i];
					// this.drawRegion(region, color);
					this.drawRegion(region);
					// }

					console.log(112, 'drawing', region.name_full);
					if (Array.isArray(region.arbitrip_descendants)) {
						// const min_properties = region.arbitrip_descendants.reduce((min, descendant_region) => Math.min(min, descendant_region.properties), region.arbitrip_descendants[0].properties);
						// const max_properties = region.arbitrip_descendants.reduce((max, descendant_region) => Math.max(max, descendant_region.properties), region.arbitrip_descendants[0].properties);
						console.log(112, 'drawing', region.name_full, 'has descendants');
						for (const descendant of region.arbitrip_descendants) {
							// if (descendant.type === 'city') {
							// const ratio = (max_properties === min_properties)
							//     ? 1
							//     : (descendant.properties - min_properties) / (max_properties - min_properties);
							// const red = Math.floor(ratio * 255);
							// const green = Math.floor((1 - ratio) * 255);
							// const descendant_color = `#${(red * 255 + green).toString(16)}`;
							// this.drawRegion(descendant, descendant_color, ratio);
							if (descendant.percents >= 4) {
								this.drawRegion(descendant);
							}
							// }
						}
					} else {
						const search_results = this.props.searchResults;
						if (search_results && Array.isArray(search_results.hotels)) {
							let alternative_regions = {};
							for (const hotel of search_results.hotels) {
								if (hotel.alternative_distance) {
									alternative_regions[hotel.alternative_distance.neighborhood.id] =
										hotel.alternative_distance.neighborhood;
								}
							}
							if (!_.isEmpty(alternative_regions)) {
								let cnt = 0;
								for (const alternative_region_id in alternative_regions) {
									this.drawRegion(alternative_regions[alternative_region_id], colors[cnt]);
									cnt++;
								}
							}
						}
					}
				}
			}
			const search_location = _.get(this.props.searchTerms, 'destination.place.geometry.location');
			if (search_location) {
				console.log('drawing circle');
				console.log('drawing circle');
				console.log('drawing circle');
				console.log('drawing circle');
				this.max_distance_circle = new window.google.maps.Circle({
					// strokeColor: "#FF0000",
					strokeColor: '#000000',
					strokeOpacity: 0.8,
					strokeWeight: 5,
					fillOpacity: 0,
					map: this.map,
					center: search_location,
					// radius: 10000,
					radius: 50000,
				});
			}
		}
	}

	drawHeatmap() {
		if (Config.research_mode) {
			if (this.heatmap) {
				this.heatmap.setMap(null);
			}
			// const { searchResults } = this.props;

			// const hotels = _.get(searchResults, 'hotels', []);
			// const heatmapData = hotels.map(hotel => ({
			//     location: new window.google.maps.LatLng(_.get(hotel, 'geoJSON.coordinates.1'), _.get(hotel, 'geoJSON.coordinates.0')),
			//     weight: 1
			// }));
			const { regions = [] } = this.props;
			let flat_regions = regions;
			for (const region of regions) {
				if (region.arbitrip_descendants) {
					flat_regions = flat_regions.concat(region.arbitrip_descendants);
				}
			}
			const heatmapData = flat_regions
				.filter(
					(region) =>
						region.type === 'city' &&
						!isNaN(_.get(region, 'coordinates.center_latitude')) &&
						_.get(region, 'coordinates.center_longitude'),
				)
				.map((region) => ({
					location: new window.google.maps.LatLng(
						parseFloat(_.get(region, 'coordinates.center_latitude')),
						parseFloat(_.get(region, 'coordinates.center_longitude')),
					),
					weight: region.properties,
				}));
			this.heatmap = new window.google.maps.visualization.HeatmapLayer({
				data: heatmapData,
				// gradient: [
				//     "rgba(0, 255, 255, 0)",
				//     "rgba(0, 255, 255, 1)",
				//     "rgba(0, 191, 255, 1)",
				//     "rgba(0, 127, 255, 1)",
				//     "rgba(0, 63, 255, 1)",
				//     "rgba(0, 0, 255, 1)",
				//     "rgba(0, 0, 223, 1)",
				//     "rgba(0, 0, 191, 1)",
				//     "rgba(0, 0, 159, 1)",
				//     "rgba(0, 0, 127, 1)",
				//     "rgba(63, 0, 91, 1)",
				//     "rgba(127, 0, 63, 1)",
				//     "rgba(191, 0, 31, 1)",
				//     "rgba(255, 0, 0, 1)",
				// ],
				// radius: 33,
				// opacity: 0.5,
				map: this.map,
			});
		}
	}

	initMap() {
		let { mapData } = this.state;
		const { searchTerms } = this.props;
		const destination = _.get(searchTerms, 'destination');

		let mapBoxEl = window.document.getElementById('map-box');

		if (!mapBoxEl) {
			console.log('No map-box, breaking...');
			return;
		}

		let lat = LocationService.getLat(destination);
		let lng = LocationService.getLng(destination);

		if (window.google) {
			this.map = new window.google.maps.Map(mapBoxEl, {
				center: mapData.lastCenter || { lat, lng },
				zoom: mapData.lastZoom || DEFAULT_ZOOM,
				disableDefaultUI: true,
				zoomControl: true,
				draggable: true,
				scrollwheel: true,
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: false,
				clickableIcons: false,
				disableDoubleClickZoom: true,
				styles: MapBoxStyles.getMapStyles(),
			});
		}

		this.refreshPopups();
		this.setDestinationMarker();
		this.setHotelCard();

		this.updateMapData();
	}

	setHotelCard() {
		let { mapData } = this.state;
		let hotelId = _.get(mapData, 'selectedHotelId');

		if (hotelId) {
			const hotel = ResultsStore.getHotelById(hotelId);

			if (hotel) {
				this.setState({ openHotelCard: true, hotelCardData: hotel });
			}
		}
	}

	updateMapData() {
		if (this.map) {
			MapActions.updateZoom(this.map.getZoom());
			MapActions.updateCenter(this.map.getCenter());
		}
		MapActions.updateSelectedHotelId(this.activeHotelId);
	}

	setDestinationMarker() {
		let _self = this;
		let { searchTerms } = this.props;
		let destination = _.get(searchTerms, 'destination');

		let lat = LocationService.getLat(destination);
		let lng = LocationService.getLng(destination);

		let element = document.createElement('div');
		element.innerHTML = `<img src='${PIN_MARKER_URL}' />`;

		let marker = document.getElementById(DESTINATION_MARKER);

		if (marker) {
			marker.appendChild(element);
		}

		if (window.google) {
			let Popup = DestinationMarker.getPopupClass();
			let popup = new Popup(new window.google.maps.LatLng(lat, lng), element);

			this.destinationMarker = popup;
			popup.setMap(_self.map);
		}
	}

	removeDestinationMarker() {
		if (this.destinationMarker) {
			this.destinationMarker.setMap(null);
			this.destinationMarker = null;
		}
	}

	setPopups() {
		const _self = this;
		const { profile, searchResults } = this.props;
		const { mapData } = this.state;

		const hotels = _.get(searchResults, 'hotels');

		if (hotels) {
			hotels.forEach((hotel) => {
				if (!hotel || !hotel.representativeDeal || !hotel.representativeDeal.pricePerNight) {
					return;
				}

				const pricePerNight = _.get(hotel, 'representativeDeal.pricePerNight');
				let element = document.createElement('div');
				element.innerHTML = Currencies.getPriceWithDisplayCurrencyByPrecision(
					pricePerNight,
					profile.display_currency_exchange_rate,
					profile.display_currency,
					0,
				);

				// Activate poopup on init if selected
				if (mapData && mapData.selectedHotelId && hotel.id === mapData.selectedHotelId) {
					_self.activeHotelId = hotel.id;
					element.classList.add('popup-active');
					_self.activatePopup(hotel.id);
				}

				element.onclick = () => {
					console.log('****');
					console.log('Hotel: ', hotel);
					console.log('****');
					_self.activatePopup(hotel.id);
					MapActions.updateSelectedHotelId(hotel.id);
					_self.setState({ openHotelCard: true, hotelCardData: hotel });
				};

				let popupsElement = document.getElementById(POPUPS);
				if (!popupsElement) {
					return;
				}

				popupsElement.appendChild(element);

				if (window.google) {
					let Popup = PopUp.getPopupClass();
					let popup = new Popup(
						window.google &&
							new window.google.maps.LatLng(hotel.geoJSON.coordinates[1], hotel.geoJSON.coordinates[0]),
						element,
						hotel.id,
					);

					_self.hotelsPopups.push(popup);
				}
			});

			if (_self.map) {
				_self.map.addListener('click', () => {
					if (_self.state.openHotelCard) {
						MapActions.updateSelectedHotelId(null);
						_self.setState({ mapData: MapStore.getMapData() });
						_self.refreshPopups();
						_self.resetHotelCard();

						this.removeDestinationMarker();
						this.setDestinationMarker();
					}
				});
			}
			_self.hotelsPopups.forEach((popup) => {
				popup.setMap(_self.map);
			});
		}
	}

	activatePopup(hotelId) {
		let popup;
		if (this.activeHotelId) {
			popup = window.document.getElementById(`popup-${this.activeHotelId}`);
			if (popup) {
				popup.classList.remove('popup-active');
			}
		}

		this.activeHotelId = hotelId;
		popup = window.document.getElementById(`popup-${this.activeHotelId}`);
		if (popup) {
			popup.classList.add('popup-active');
		}
	}

	refreshPopups() {
		this.removePopups();
		this.setPopups();
	}

	resetHotelCard() {
		this.setState({ openHotelCard: false });
		setTimeout(() => {
			this.setState({ hotelCardData: null });
			MapActions.updateSelectedHotelId(null);
		}, 100);
	}

	removePopups() {
		if (this.hotelsPopups) {
			this.hotelsPopups.forEach((popup) => {
				popup.setMap(null);
			});

			this.hotelsPopups = [];
		}
	}

	handleClose() {
		let { onCloseMap } = this.props;
		this.setState({ open: false });
		MapActions.clearMapData();
		this.resetHotelCard();

		setTimeout(() => {
			if (_.isFunction(onCloseMap)) {
				onCloseMap();
			}
		});
	}

	getMapRender() {
		let { classes } = this.props;

		return (
			<div className={classNames(classes.container, 'filter-container', 'map-container')}>
				<div className={classes.mapBoxCardContainer}>
					<div className={classNames(classes.mapBox, 'filter', 'map-filter')} id="map-box" />
					<div className={classes.cardWrapper}>{this.getHotelCard()}</div>
				</div>
			</div>
		);
	}

	getHotelCard() {
		let { openHotelCard, hotelCardData } = this.state;

		return (
			<MapBoxHotelCard
				open={openHotelCard}
				data={hotelCardData}
				updateMapData={this.updateMapData}
				profile={this.props.profile}
				searchTerms={this.props.searchTerms}
			/>
		);
	}

	getFiltersDrawer() {
		return (
			<div className="filters-container">
				<FiltersComponent
					profile={this.props.profile}
					filters={this.props.filters}
					mapMode={true}
					drawPolygons={this.drawPolygons}
					drawHeatmap={this.drawHeatmap}
					search_terms={this.props.searchTerms}
					showDistanceFilter={true}
					showOldNameFilter={true}
				/>
			</div>
		);
	}

	render() {
		const { classes } = this.props;

		return (
			<Dialog
				open={this.state.open}
				onClose={this.handleClose}
				TransitionComponent={Transition}
				className={classes.dialog}
				fullScreen
				id="map-box-overlay"
			>
				<AppBar className={classNames(classes.appBar)}>
					<Toolbar variant="dense">
						<IconButton color="inherit" onClick={this.handleClose} aria-label="Close" size="large">
							<CloseIcon />
						</IconButton>

						<Typography variant="h6" color="inherit" className={classes.flex}></Typography>
						<Button color="inherit" onClick={this.handleClose}>
							Close
						</Button>
					</Toolbar>
				</AppBar>
				<div className={classNames('flex', classes.heightContainer)}>
					{this.getFiltersDrawer()}
					{this.getMapRender()}
				</div>
				<div id={POPUPS} />
				<div id={DESTINATION_MARKER} />
			</Dialog>
		);
	}

	_onChange() {
		this.refreshPopups();

		this.removeDestinationMarker();
		this.setDestinationMarker();
	}
}

MapBoxOverlay.propTypes = {
	open: PropTypes.bool,
	onCloseMap: PropTypes.func,
	classes: PropTypes.object.isRequired,
	searchTerms: PropTypes.object.isRequired,
};

export default withStyles(styles)(MapBoxOverlay);
