import moment from 'moment';

import { TableRow, TableCell, Typography } from '@mui/material';

import BadgeComponent from './BadgeComponent';
import TripStatusComponent from './TripStatusComponent';
import TravelersComponentInRow from './TravelersComponentInRow';
import CurrentLocation from './CurrentLocation';

const TripTableRow = ({ trip, onRowClick, onRowHover, isHovered, showCurrentLocation = false }) => (
	<TableRow onClick={onRowClick} onMouseEnter={onRowHover} className={`trip-table-row${isHovered ? ' hovered' : ''}`}>
		<TableCell className="traveler-column">
			<div className="traveler-column-wrapper flex">
				<TripStatusComponent trip={trip} icon_only={true} />
				<TravelersComponentInRow travelers={trip.travelers} />
			</div>
		</TableCell>
		<TableCell className="name-column">
			<Typography>{trip.name}</Typography>
		</TableCell>
		<TableCell className="badge-column">
			<BadgeComponent trip={trip} />
		</TableCell>
		<TableCell className="destination-column">
			{showCurrentLocation ? <CurrentLocation trip={trip} /> : <Typography>{trip.meta.destination}</Typography>}
		</TableCell>
		<TableCell className="date-column">
			<Typography>{trip?.meta?.start_date && moment(trip?.meta?.start_date).format('DD MMM, YYYY')}</Typography>
		</TableCell>
	</TableRow>
);

export default TripTableRow;
