import _ from 'lodash';

const SupplierTerms = ({ getHotelOrHotelInfoPropertyByKey }) => {
	const supplier_terms = getHotelOrHotelInfoPropertyByKey('supplier_terms');

	if (supplier_terms) {
		const checkin_begin_time = _.get(supplier_terms, 'checkin.check_in_begin_time');
		const checkin_end_time = _.get(supplier_terms, 'checkin.check_in_end_time');
		const checkin_time_text =
			`${checkin_begin_time ? `from ${checkin_begin_time}` : ''} ${checkin_end_time ? `till ${checkin_end_time}` : ''}`.trim();
		const checkout_time = _.get(supplier_terms, 'checkout.checkout_time');
		const checkin_24_hour = _.get(supplier_terms, 'checkin.checkin_24_hour');
		const check_in_instructions = _.get(supplier_terms, 'checkin.check_in_instructions');
		const check_in_special_instructions = _.get(supplier_terms, 'checkin.check_in_special_instructions');
		const check_in_min_age = _.get(supplier_terms, 'checkin.check_in_min_age');
		const fees_mandatory = _.get(supplier_terms, 'fees.fees_mandatory');
		const fees_optional = _.get(supplier_terms, 'fees.fees_optional');
		const policies_know_before_you_go = _.get(supplier_terms, 'policies.policies_know_before_you_go');

		return (
			<div className="supplier-terms-container">
				<div className="supplier-terms-wrapper">
					<div className="title">Hotel Policies</div>
					{checkin_time_text ? (
						<p>
							<strong>Check-In Time:</strong> {checkin_time_text}
						</p>
					) : null}
					{checkout_time ? (
						<p>
							<strong>Check-Out Time:</strong> {checkout_time}
						</p>
					) : null}
					{checkin_24_hour ? (
						<p>
							<strong>24 Hour Check-In!</strong>
						</p>
					) : null}
					{check_in_instructions ? (
						<p>
							<strong>Check-In Instructions:</strong> {check_in_instructions}
						</p>
					) : null}
					{check_in_special_instructions ? (
						<p>
							<strong>Check-In Special Instructions:</strong> {check_in_special_instructions}
						</p>
					) : null}
					{check_in_min_age ? (
						<p>
							<strong>Minimum Age Allowed For Check-In:</strong> {check_in_min_age}
						</p>
					) : null}
					{fees_mandatory ? (
						<p>
							<strong>Mandatory Fees:</strong> {fees_mandatory}
						</p>
					) : null}
					{fees_optional ? (
						<p>
							<strong>Optional Fees:</strong> {fees_optional}
						</p>
					) : null}
					{policies_know_before_you_go ? (
						<p>
							<strong>You Need To Know:</strong> {policies_know_before_you_go}
						</p>
					) : null}
				</div>
			</div>
		);
	}

	return null;
};

export default SupplierTerms;
