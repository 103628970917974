
// LIMIT?

const ORDER_BY = {
    CHECK_IN: 'check_in',
    CHECK_OUT: 'check_out',
    // ROOMS: 'rooms',
    // NIGHTS:'nights',
    STATUS: 'status',
    CANCELLATION_DEADLINE: 'cancellation_deadline' ,
};
const ORDER = {
    ASC: 'asc',
    DESC: 'desc'
};

module.exports = {
    ORDER_BY,
    ORDER,
    ORDER_DEFAULTS: {
        [ORDER_BY.CHECK_IN]: ORDER.DESC,
        [ORDER_BY.CHECK_OUT]: ORDER.DESC,
        // [ORDER_BY.ROOMS]: ORDER.ASC,
        // [ORDER_BY.NIGHTS]: ORDER.ASC,
        [ORDER_BY.STATUS]: ORDER.ASC,
        [ORDER_BY.CANCELLATION_DEADLINE]: ORDER.ASC,
    },
    ORDER_OPPOSITES: {
        [ORDER.ASC]: ORDER.DESC,
        [ORDER.DESC]: ORDER.ASC
    },
    ORDER_NUMERICS: {
        [ORDER.ASC]: 1,
        [ORDER.DESC]: -1
    },

    ORDER_BY_PATHS: {
        [ORDER_BY.CHECK_IN]: 'deal.details.check_in',
        [ORDER_BY.CHECK_OUT]: 'deal.details.check_out',
        // [ORDER_BY.ROOMS]: 'deal.details.rooms',
        // [ORDER_BY.NIGHTS]: 'deal.details.nights',
        [ORDER_BY.STATUS]: 'status',
        [ORDER_BY.CANCELLATION_DEADLINE]: 'deal.dca.dealIsFullyRefundableTill',
    }
};
