import createClass from 'create-react-class';
import { Checkbox, FormControlLabel } from '@mui/material';
import CheckboxActiveIcon from '@/components/pages/results/filters/components/CheckboxActiveIcon';
import CheckboxInactiveIcon from '@/components/pages/results/filters/components/CheckboxInactiveIcon';

import FilterActions from '../../../actions/FiltersActions';
import FiltersConstants from '../../../constants/FiltersConstants';
import FiltersStore from '../../../stores/FiltersStore';
import ProfileStore from '../../../stores/ProfileStore';

import Analytics from 'arbitrip-common/client/analytics';

function getFilterDealsState() {
	return {
		profile: ProfileStore.getProfile(),
		deals_filter: FiltersStore.getDealsFilterData(),
	};
}

const FilterDealsComponent = createClass({
	displayName: 'FilterDealsComponent',

	getInitialState() {
		return getFilterDealsState();
	},

	componentDidMount() {
		FiltersStore.addChangeListener(this._onChange);
	},

	componentWillUnmount() {
		FiltersStore.removeChangeListener(this._onChange);
	},

	handleCheckBoxFilterDealBreakfastChanged(event, isInputChecked) {
		const { profile } = this.state;
		FilterActions.filterDealsByBreakfastOnly(isInputChecked);

		// uncomment if you want it to affect the filter for all hotels (results page) as well...
		// FilterActions.filterByAmenity(FiltersConstants.AMENITIES.BREAKFAST_INCLUDED, isInputChecked);

		Analytics.actions.interactions.filteredDeals(
			profile,
			FiltersConstants.FILTER_METHOD.DEAL_BREAKFAST_ONLY,
			isInputChecked ? 'breakfast only ' : 'unfiltered',
		);
	},

	handleCheckBoxFilterDealHalfBoardChanged(event, isInputChecked) {
		const { profile } = this.state;
		FilterActions.filterDealsByHalfBoardOnly(isInputChecked);

		Analytics.actions.interactions.filteredDeals(
			profile,
			FiltersConstants.FILTER_METHOD.DEAL_HALF_BOARD_ONLY,
			isInputChecked ? 'half board only' : 'unfiltered',
		);
	},

	handleCheckBoxFilterDealAllInclusiveChanged(event, isInputChecked) {
		const { profile } = this.state;
		FilterActions.filterDealsByAllInclusiveOnly(isInputChecked);

		Analytics.actions.interactions.filteredDeals(
			profile,
			FiltersConstants.FILTER_METHOD.DEAL_FULL_BOARD_OR_ALL_INCLUSIVE_ONLY,
			isInputChecked ? 'all inclusive or full board only' : 'unfiltered',
		);
	},

	handleCheckBoxFilterDealFreeCancellationChanged(event, isInputChecked) {
		const { profile } = this.state;

		FilterActions.filterDealsByCancellationOnly(isInputChecked);
		Analytics.actions.interactions.filteredDeals(
			profile,
			FiltersConstants.FILTER_METHOD.DEAL_CANCELABLE_ONLY,
			isInputChecked ? 'free cancellation only ' : 'unfiltered',
		);
	},

	handleCheckBoxFilterDealPostPayChanged(event, isInputChecked) {
		const { profile } = this.state;

		FilterActions.filterDealsByPostPayOnly(isInputChecked);
		Analytics.actions.interactions.filteredDeals(
			profile,
			FiltersConstants.FILTER_METHOD.DEAL_POST_PAY_ONLY,
			isInputChecked ? 'post pay only ' : 'unfiltered',
		);
	},

	handleCheckBoxFilterDealNegotiatedRateChanged(event, isInputChecked) {
		const { profile } = this.state;

		FilterActions.filterDealsByNegotiatedRateOnly(isInputChecked);
		Analytics.actions.interactions.filteredDeals(
			profile,
			FiltersConstants.FILTER_METHOD.DEAL_NEGOTIATED_RATE_ONLY,
			isInputChecked ? 'negotiated rate only ' : 'unfiltered',
		);
	},

	handleCheckBoxFilterDealTravelPolicyChanged(event, isInputChecked) {
		const { profile } = this.state;

		FilterActions.filterDealsByInPolicyOnly(isInputChecked);
		Analytics.actions.interactions.filteredDeals(
			profile,
			FiltersConstants.FILTER_METHOD.DEAL_TRAVEL_POLICY_ONLY,
			isInputChecked ? 'in travel policy only ' : 'unfiltered',
		);
	},

	isTravelPolicyEnabled() {
		const { travel_policy } = this.props;
		return (
			travel_policy &&
			(travel_policy.default || travel_policy.place) &&
			travel_policy.currency &&
			travel_policy.price
		);
	},

	render() {
		const { post_pay_deals_exists, half_board_deals_exists, all_inclusive_deals_exists } = this.props;

		return (
			<>
				<div className="deals-filter">
					<div
						className={`checkbox-container ${this.state.deals_filter.free_cancellation_only && 'checked'}`}
					>
						<FormControlLabel
							control={
								<Checkbox
									checked={this.state.deals_filter.free_cancellation_only}
									onChange={this.handleCheckBoxFilterDealFreeCancellationChanged}
									icon={<CheckboxInactiveIcon />}
									checkedIcon={<CheckboxActiveIcon />}
								/>
							}
							label="Free Cancellation"
						/>
					</div>
					<div className={`checkbox-container ${this.state.deals_filter.breakfast_only && 'checked'}`}>
						<FormControlLabel
							control={
								<Checkbox
									checked={this.state.deals_filter.breakfast_only}
									onChange={this.handleCheckBoxFilterDealBreakfastChanged}
									icon={<CheckboxInactiveIcon />}
									checkedIcon={<CheckboxActiveIcon />}
								/>
							}
							label="Breakfast Included"
						/>
					</div>
					{half_board_deals_exists && (
						<div className={`checkbox-container ${this.state.deals_filter.half_board_only && 'checked'}`}>
							<FormControlLabel
								control={
									<Checkbox
										checked={this.state.deals_filter.half_board_only}
										onChange={this.handleCheckBoxFilterDealHalfBoardChanged}
										icon={<CheckboxInactiveIcon />}
										checkedIcon={<CheckboxActiveIcon />}
									/>
								}
								label="Half board"
							/>
						</div>
					)}
					{all_inclusive_deals_exists && (
						<div
							className={`checkbox-container ${this.state.deals_filter.all_inclusive_only && 'checked'}`}
						>
							<FormControlLabel
								control={
									<Checkbox
										checked={this.state.deals_filter.all_inclusive_only}
										onChange={this.handleCheckBoxFilterDealAllInclusiveChanged}
										icon={<CheckboxInactiveIcon />}
										checkedIcon={<CheckboxActiveIcon />}
									/>
								}
								label="All Inclusive"
							/>
						</div>
					)}
					{post_pay_deals_exists && (
						<div
							className={`checkbox-container ${this.state.deals_filter.negotiated_rate_only && 'checked'}`}
						>
							<FormControlLabel
								control={
									<Checkbox
										checked={this.state.deals_filter.negotiated_rate_only}
										onChange={this.handleCheckBoxFilterDealNegotiatedRateChanged}
										icon={<CheckboxInactiveIcon />}
										checkedIcon={<CheckboxActiveIcon />}
									/>
								}
								label="Negotiated Rate"
							/>
						</div>
					)}
					{this.isTravelPolicyEnabled() && !ProfileStore.isLeisureExperienceEnabled() && (
						<div className={`checkbox-container ${this.state.deals_filter.in_policy_only && 'checked'}`}>
							<FormControlLabel
								control={
									<Checkbox
										checked={this.state.deals_filter.in_policy_only}
										onChange={this.handleCheckBoxFilterDealTravelPolicyChanged}
										icon={<CheckboxInactiveIcon />}
										checkedIcon={<CheckboxActiveIcon />}
									/>
								}
								label="In Policy"
							/>
						</div>
					)}
					{post_pay_deals_exists && (
						<div className={`checkbox-container ${this.state.deals_filter.post_pay_only && 'checked'}`}>
							<FormControlLabel
								control={
									<Checkbox
										checked={this.state.deals_filter.post_pay_only}
										onChange={this.handleCheckBoxFilterDealPostPayChanged}
										icon={<CheckboxInactiveIcon />}
										checkedIcon={<CheckboxActiveIcon />}
									/>
								}
								label="Pay at Hotel"
							/>
						</div>
					)}
				</div>
			</>
		);
	},

	_onChange: function () {
		this.setState(getFilterDealsState());
	},
});

export default FilterDealsComponent;
