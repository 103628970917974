class TravelPolicy {
  constructor(travel_policy) {
    if (travel_policy) {
      this.id = travel_policy._id;

      if (travel_policy.selected_entry) {
        this.price = travel_policy.selected_entry.price;
        this.currency = travel_policy.selected_entry.currency;

        if (travel_policy.selected_entry.place) {
          this.place = travel_policy.selected_entry.place;
        } else {
          this.default = true;
        }
      }
    }
  }
}

export { TravelPolicy };