const { PAYMENT_TYPE, PAYMENT_STATUS } = require('arbitrip-common/general/constants');
const { RESERVATION_STATUS } = require('@arbitrip/constants/reservations');

function getIsUnpaid(profile, booking) {
	if (!profile || !booking) {
		console.log('nop');
		return false;
	}

	return (
		booking.status === RESERVATION_STATUS.APPROVED &&
		profile.pay_later_allowed &&
		booking?.pay_later_deadline &&
		booking?.payment?.payment_type === PAYMENT_TYPE.PAY_LATER &&
		booking?.payment?.status !== PAYMENT_STATUS.BILLED
	);
}

module.exports = {
	getIsUnpaid,
};
