const { hotelsCurrencies } = require('../../general/utils/Currencies');

const _ = require('lodash');
const SEARCH_CONSTANTS = require('../../general/constants/search-constants');
const REGEXES = require('../../general/constants/regexes');

function validateFlightNumber(flight_number) {
  return REGEXES.flight_number_regex.test(flight_number);
}

function validateEmailAddress(email) {
  if (!email || !email.length) {
    return false;
  }

  return REGEXES.email_regex.test(email);
}

function validatePhoneNumber(phone) {
  return REGEXES.phone_regex.test(phone);
}

function validateEnglishOnly(str, use_custom = false) {
  if (!str || !str.length) {
    return false;
  }

  const regex = use_custom
    ? REGEXES.english_only_regex_custom
    : REGEXES.english_only_regex;

  return regex.test(str);
}

function validateEnglishAlphaNumeric(str) {
  return validateEnglishOnly(str, true);
}

function filterEnglishOnly(str, withWhiteSpace = false) {
  if (!str || !str.length)
    return '';

  let regex = /[a-zA-Z]+/g;
  if (withWhiteSpace) {
    regex = /[a-zA-Z ]+/g;
  }
  const matched = str.match(regex);
  if (!matched) {
    return '';
  }
  return matched.join(' ');
}

function validateObjectId(str) {
  return REGEXES.object_id_regex.test(str);
}

function validateCombtasId(str) {
  return REGEXES.combtas_id_regex.test(str);
}

function validateSearchToken(search_token, params_signed) {
  try {
    if (!search_token || !params_signed)
      return false;

    let sp = search_token.split('-');
    if (sp.length !== 2) {
      return false;
    }
    let token = sp[0];
    let signed = sp[1];
    if (!REGEXES.search_token_regex.test(token)) {
      return false;
    }

    return signed === params_signed;
  }
  catch (err) {
    console.error(err);
    return false;
  }
}

function validateCurrency(currency) {
  return !!hotelsCurrencies.find(c => (c.currency === currency));
}

function validateAge(age) {
  if (isNaN(age) || age === '') {
    return false;
  }

  const parsed_age = Number(age);
  if (!_.isInteger(parsed_age)) {
    return false;
  }

  if ((parsed_age < 0) || (parsed_age > SEARCH_CONSTANTS.MAX.CHILD_AGE)) {
    return false;
  }

  return true;
}

function validateTravelBoosterDocketNumber(str, profile) {
  return profile?.company?.settings?.travel_booster_integration_basic_validation
    ? REGEXES.travel_booster_docket_number_basic_validation_regex.test(str)
    : REGEXES.travel_booster_docket_number_regex.test(str);
}

module.exports = {
  validateEmailAddress,
  validatePhoneNumber,
  validateEnglishOnly,
  validateEnglishAlphaNumeric,
  filterEnglishOnly,
  validateFlightNumber,
  validateObjectId,
  validateCombtasId,
  validateTravelBoosterDocketNumber,
  validateSearchToken,
  validateCurrency,
  validateAge
}