import { useState, useEffect } from 'react';
import HotelStore from '../../../stores/HotelStore';

function getAmenitiesState() {
	return {
		amenities: HotelStore.getHotelAmenities(),
	};
}

const AmenitiesComponent = (props) => {
	const [amenitiesState, setAmenitiesState] = useState(getAmenitiesState());

	useEffect(() => {
		const onChange = () => {
			setAmenitiesState(getAmenitiesState());
		};

		HotelStore.addChangeListener(onChange);
		return () => {
			HotelStore.removeChangeListener(onChange);
		};
	}, []);

	let amenities = props.amenities || amenitiesState.amenities;

	if (!Array.isArray(amenities)) return null;
	amenities = amenities.filter(Boolean);

	const count = amenities.length;
	let c1 = Math.floor(count / 3);
	let c2 = c1;
	if (count % 3 >= 1) {
		c1++;
		if (count % 3 === 2) {
			c2++;
		}
	}

	const DIVS = amenities.map((amenity, i) => {
		const key = 'amenity-key-' + i;
		const actualAmenity = amenity.includes('(') ? amenity.split('(')[0].trim() : amenity;
		return (
			<div className="amenity flex" key={key}>
				<div className="name flex align-center">{actualAmenity}</div>
			</div>
		);
	});

	return (
		<div className="amenities">
			<div className="column" key="column-key-1">
				{DIVS.slice(0, c1)}
			</div>
			<div className="column" key="column-key-2">
				{DIVS.slice(c1, c1 + c2)}
			</div>
			<div className="column" key="column-key-3">
				{DIVS.slice(c1 + c2, amenities.length)}
			</div>
		</div>
	);
};

export default AmenitiesComponent;
