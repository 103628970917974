import AppDispatcher from '../dispatcher/AppDispatcher';
import UsersManagementConstants from '../constants/UsersManagementConstants';
import Ajax from '../utils/Ajax';

let UsersManagementActions = {
	getUsers(options) {
		AppDispatcher.handleAction({
			actionType: UsersManagementConstants.RETRIEVE_USERS,
		});

		Ajax.getUsersByCompany(options)
			.done(function (res) {
				console.log('Got users: ', res);
				AppDispatcher.handleAction({
					actionType: UsersManagementConstants.USERS_RETRIEVAL_SUCCEEDED,
					data: res,
				});
			})
			.fail(function (err) {
				console.error('Error while getting users: ', err);
				AppDispatcher.handleAction({
					actionType: UsersManagementConstants.USERS_RETRIEVAL_FAILED,
					data: err, // ???
				});
			});
	},

	addNewUser(data) {
		return Ajax.addNewUser(data)
			.done(function (res) {
				console.log('Added new user: ', res);
				return res;
			})
			.fail(function (err) {
				console.error('Error while adding user: ', err);
				return err;
			});
	},

	addNewUserToUsers(user) {
		AppDispatcher.handleAction({
			actionType: UsersManagementConstants.ADD_USER_TO_USERS,
			data: user, // ???
		});
	},

	activateUser(data) {
		return Ajax.activateUser(data)
			.done(function (res) {
				console.log('Activate user: ', res);
				return res;
			})
			.fail(function (err) {
				console.error('Error while activating user: ', err);
				return err;
			});
	},

	updateUser(data) {
		return Ajax.updateUser(data)
			.done(function (res) {
				console.log('Updated user: ', res);
				return res;
			})
			.fail(function (err) {
				console.error('Error while updating user: ', err);
				return err;
			});
	},

	inviteUser(data) {
		return Ajax.inviteUser(data)
			.done(function (res) {
				console.log('Invited user: ', res);
				return res;
			})
			.fail(function (err) {
				console.error('Error while inviting user: ', err);
				return err;
			});
	},

	resetPassword(data) {
		return Ajax.resetPassword(data)
			.done(function (res) {
				console.log('Reset password user: ', res);
				return res;
			})
			.fail(function (err) {
				console.error('Error while reset password user: ', err);
				return err;
			});
	},
};

export default UsersManagementActions;
