//import keyMirror from 'keymirror';
import keyMirror from 'fbjs/lib/keyMirror';

// Define action constants
export default keyMirror({
	GET_USER_DATA: null,
	LOG_IN: null,
	LOG_OUT: null,
	SESSION_TIMEOUT: null,
});
