import keyMirror from 'keymirror';
import _ from 'lodash';
import moment from 'moment';
import COMMON_BOOKINGS_CONSTANTS from 'arbitrip-common/general/constants/bookings-constants';

var max_date = new Date();
max_date.setYear(max_date.getFullYear() + 2);

const BOOKINGS_CONSTANTS = {
	STATUS: {
		INITIAL: 'initial',
		TRANSITION: 'transition',
		BUSY: 'busy',
		SUCCESS: 'success',
		FAILED: 'failed',

		CANCELING: 'canceling',
		CANCELED: 'canceled',
	},

	BOOKINGS_LIMIT: 10,
	REQUESTS_LIMIT: 10,

	MIN_DATE: new Date(0),
	MAX_DATE: moment(max_date).startOf('day').toDate(),
};

const REQUESTS_CONSTANTS = {
	REQUESTS_STATUS: {
		INITIAL: 'initial',
		BUSY: 'busy',
		SUCCESS: 'success',
		FAILED: 'failed',

		APPROVING: 'approving',
		APPROVED: 'approved',

		REJECTING: 'rejecting',
		REJECTED: ' rejected',
	},
};

// Define action constants
export default _.extend(
	{},
	COMMON_BOOKINGS_CONSTANTS,
	BOOKINGS_CONSTANTS,
	REQUESTS_CONSTANTS,
	keyMirror({
		RETRIEVE_BOOKINGS: null,
		BOOKINGS_TRANSITION: null,
		BOOKINGS_RETRIEVAL_SUCCEEDED: null, // After postback
		BOOKINGS_RETRIEVAL_FAILED: null,

		RETRIEVE_REQUESTS: null,
		REQUESTS_RETRIEVAL_SUCCEEDED: null,
		REQUESTS_RETRIEVAL_FAILED: null,

		SYNC_TRAVEL_BOOSTER: null,
		SYNC_TRAVEL_BOOSTER_SUCCEEDED: null,
		SYNC_TRAVEL_BOOSTER_FAILED: null,

		DOWNLOADED_BOOKINGS_CSV: null,
		DOWNLOADED_BOOKINGS_CSV_SUCCEEDED: null,
		DOWNLOADED_BOOKINGS_CSV_FAILED: null,

		DOWNLOADED_BOOKING_REQUESTS_CSV_SUCCEEDED: null,
		DOWNLOADED_BOOKING_REQUESTS_CSV_FAILED: null,

		UPDATE_AGENT_NOTES: null,
		UPDATE_AGENT_NOTES_SUCCEEDED: null,
		UPDATE_AGENT_NOTES_FAILED: null,

		UPDATE_PAYMENT_TYPE_TO_CREDIT_CARD: null,

		UPDATE_AGENT_PRICING_ON_BOOKING: null,
		UPDATE_PAYMENT_LINK: null,
	}),
);
