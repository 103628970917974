const keyMirror = require('keymirror');

const DEAL_CONSTANTS = {
    DEAL_STATUS: {
        INITIAL: "initial",
        FAIL: "fail",
        NA: "na",
        BUSY: "busy",
        SUCCESS: "success",
    },
    BOOKING_PROVIDER_STRING: 'B:',
    BOOKING_AFFILIATE_STRING:'BA:'
};

module.exports = Object.assign({}, DEAL_CONSTANTS, keyMirror({
    CHOOSE_DEAL: null,
    CLEAR_DEAL_DATA: null,
}));
