import $ from 'jquery';

import React from 'react';
import createClass from 'create-react-class';

var FlightStatus = createClass({
	displayName: 'FlightStatus',

	componentDidMount: function () {
		// muthafucka hack
		$('.flight-status-container').scrollTop(10000);
	},

	render: function () {
		let { show_title, flight_number } = this.props;
		if (!flight_number) {
			return null;
		}

		return (
			<div className="flight-status-component">
				{show_title ? (
					<div className="title">
						<span>Current Flight ({flight_number}):</span>
					</div>
				) : null}

				<div className="blocker"></div>

				<div className="flight-status-container">
					<iframe
						title="flight-status"
						id="flight-status"
						className="flight-status"
						src={'//uk.flightaware.com/live/flight/' + flight_number}
						height="510px"
						width="100%"
						frameBorder="0"
						scrolling="no"
					/>
				</div>
			</div>
		);
	},
});

export default FlightStatus;
