import { Link } from 'react-router-dom';

import withStyles from '@mui/styles/withStyles';

import PropTypes from 'prop-types';

import { Slide, Card, CardMedia, CardContent, Typography, Button } from '@mui/material';

/* eslint-disable react/display-name */
import React from 'react';

import _ from 'lodash';

import classNames from 'classnames';

import DealUtils from '../../../../../entities/DealUtils';
import Currencies from '../../../../../utils/Currencies';

import SearchStore from '../../../../../stores/SearchStore';
import HotelActions from '../../../../../actions/HotelActions';
import ResultsStore from '../../../../../stores/ResultsStore';
import RouterWrapper from '../../../../../utils/RouterWrapper';

import Analytics from 'arbitrip-common/client/analytics';

const styles = {
	card: {
		display: 'flex',
		maxWidth: 700,
		width: 700,
	},
	cover: {
		backgroundColor: '#7777',
		width: 200,
	},
	details: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
	},
	content: {
		padding: 16,
		borderBottom: '1px solid #EBEBEB',
		textTransform: 'capitalize',
		flex: 'auto',
	},
	contentReviews: {
		padding: '16px !important',
		fontSize: 12,
		borderLeft: '1px solid #EBEBEB',
		borderBottom: '1px solid #EBEBEB',
	},
	action: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '1px 10px 2px',
		height: '100%',
	},
	priceWrap: {
		display: 'flex',
		alignItems: 'center',
	},
	price: {
		fontWeight: 'bold',
		marginLeft: 8,
		marginRight: 4,
	},
	stars: {
		display: 'inline-block',
	},
	star: {
		backgroundColor: '#EAC200',
		width: 16,
		height: 16,
		display: 'inline-block',
		backgroundSize: '16px 16px',
		maskRepeat: 'no-repeat',
		maskImage: 'url("img/results_page/star.svg")',
	},
	emptyStar: {
		maskImage: 'url("img/results_page/empty_star.svg")',
	},
	address: {
		color: '#DEB24F',
	},
	review: {
		textAlign: 'center',
		color: '#223143',
		textTransform: 'uppercase',
	},
	votes: {
		whiteSpace: 'nowrap',
	},
	reviewScore: {
		fontSize: 18,
	},
	icon: {
		width: 13,
		height: 13,
		marginRight: 4,
	},
	policy: {
		marginLeft: 6,
	},
	border: {
		border: '1px solid',
		padding: '0 5px',
		borderRadius: 6,
		fontSize: 12,
	},
	inPolicy: {
		display: 'flex',
		alignItems: 'center',
		color: '#417505',
	},
	outOfPolicy: {
		display: 'flex',
		alignItems: 'center',
		color: '#d18a14',
	},
};

class MapBoxHotelCard extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			open: props.open,
		};
	}

	componentDidUpdate(prevProps) {
		const { open } = this.props;

		const justOpened = !prevProps.open && open;
		const justClosed = prevProps.open && !open;

		if (justOpened || justClosed) {
			this.setState({ open });
		}
	}

	getHotelPrice() {
		const { data, profile } = this.props;

		const representativeDeal = _.get(data, 'representativeDeal');

		const arbitrip_points_reduction = ResultsStore.areArbitripPointsApplied()
			? _.get(profile, 'bank.balance', 0)
			: 0;

		return representativeDeal
			? Currencies.getPriceWithDisplayCurrencyByPrecision(
					representativeDeal.pricePerNight - arbitrip_points_reduction,
					profile.display_currency_exchange_rate,
					profile.display_currency,
					0,
				)
			: null;
	}

	getPolicy() {
		const { profile, classes, data } = this.props;
		if (!profile.travel_policy_enabled || SearchStore.isPrivateTravel()) {
			return null;
		}
		const inPolicy = _.get(data, 'representativeDeal.in_policy');
		return inPolicy ? (
			<div className={classNames(classes.inPolicy, classes.border)}>In Policy</div>
		) : (
			<div className={classNames(classes.outOfPolicy, classes.border)}>Out of Policy</div>
		);
	}

	getDistanceDescription() {
		const { data } = this.props;

		let distanceInKm = _.get(data, 'distanceInKm');
		let distanceDescription = _.get(data, 'distanceDescription');

		return distanceInKm > 0 && distanceDescription ? `(${distanceDescription})` : null;
	}

	getStars() {
		const { classes, data } = this.props;
		let stars = _.get(data, 'stars');

		let starsArr = [];

		for (let i = 1; i <= 5; i++) {
			starsArr.push(<div key={i} className={classNames(classes.star, i > stars ? classes.emptyStar : null)} />);
		}

		return <div className={classes.stars}>{starsArr}</div>;
	}

	getHotelName() {
		const { data } = this.props;

		let name = _.get(data, 'name');

		return (
			data && (
				<React.Fragment>
					{name} {this.getStars()}
				</React.Fragment>
			)
		);
	}

	getFullAddress() {
		const { classes, data } = this.props;

		let address = _.get(data, 'address');
		let city = _.get(data, 'city');
		let countryCode = _.get(data, 'country_code');

		let addressArr = [address, city, countryCode].join(', ');

		return (
			<div className={classes.address}>
				<i className="fa fa-map-marker" /> <span>{addressArr}</span>{' '}
				<span>{this.getDistanceDescription()}</span>
			</div>
		);
	}

	getReview() {
		const { classes, data } = this.props;

		let votes = _.get(data, 'review.votes');
		let score = _.get(data, 'review.score');
		let description = _.get(data, 'review.description');

		return (
			<div className={classes.review}>
				<div className={classes.votes}>{votes} Reviews</div>
				<div className={classes.reviewScore}>{score}</div>
				<div>{description}</div>
			</div>
		);
	}

	getViewDealsButton(data) {
		let representativeDeal = _.get(data, 'representativeDeal');
		let shallow = _.get(data, 'shallow');

		return DealUtils.isValidDeal(representativeDeal) || shallow ? (
			<Link to={this.getViewDealsUrl(data.id)} target="_blank">
				<Button size="small" color="primary" onClick={() => this.viewHotelDealsClicked(data.id)}>
					View Deals
				</Button>
			</Link>
		) : (
			<Button size="small" color="primary" disabled>
				No Availability
			</Button>
		);
	}

	getViewDealsUrl(hotelId) {
		const hotel = ResultsStore.getHotelById(hotelId);

		return RouterWrapper.buildHotelRoute(hotel, SearchStore.getToken(), SearchStore.getSearchTerms());
	}

	viewHotelDealsClicked(hotelId) {
		const { profile, searchTerms } = this.props;

		let { updateMapData } = this.props;

		if (_.isFunction(updateMapData)) {
			updateMapData();
		}

		const hotel = ResultsStore.getHotelById(hotelId);
		HotelActions.getHotelData(hotel);

		const recommended_index = ResultsStore.calculateRecommendedIndex(hotel);
		HotelActions.setHotelRecommendedIndex(hotelId, recommended_index);
		const recommended_count = ResultsStore.getRecommendedCount();
		const sort_method = ResultsStore.getSortMethod();
		const time_to_click_ml = ResultsStore.getTimePassedSinceSearchSessionCreatedInMilliSeconds();

		Analytics.actions.interactions.selectedHotel(
			profile,
			searchTerms.destination,
			searchTerms,
			recommended_index,
			recommended_count,
			sort_method,
			time_to_click_ml,
		);
	}

	render() {
		const { classes, data } = this.props;
		let { open } = this.state;

		let image = _.get(data, 'image');
		let hasReview = _.get(data, 'hasReview');

		return (
			<Slide direction="up" in={open} mountOnEnter unmountOnExit>
				<Card className={classes.card}>
					{image && <CardMedia className={classes.cover} component="img" height="140" image={image} />}
					<div className={classes.details}>
						<div className="flex">
							<CardContent className={classes.content}>
								<Typography variant="h5" component="h2">
									{this.getHotelName()}
								</Typography>
								{this.getFullAddress()}
							</CardContent>
							{hasReview && (
								<CardContent className={classNames(classes.content, classes.contentReviews)}>
									{this.getReview()}
								</CardContent>
							)}
						</div>

						<div className={classes.action}>
							<div className={classes.priceWrap}>
								<div className={classes.price}>{this.getHotelPrice()}</div>
								<div>Avg/Room/Night</div>
								<div className={classes.policy}>{this.getPolicy()}</div>
							</div>
							{this.getViewDealsButton(data)}
						</div>
					</div>
				</Card>
			</Slide>
		);
	}
}

MapBoxHotelCard.propTypes = {
	data: PropTypes.object,
	open: PropTypes.bool,
	updateMapData: PropTypes.func,
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MapBoxHotelCard);
