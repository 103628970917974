const CONSTANTS = module.exports = {};

CONSTANTS.SYSTEM_ROLES = {
    SUPERVISOR: 'supervisor',
    GOD: null,
    // SUPER_TRAVEL_MANAGER: 'su_travel_manager',
    TRAVEL_MANAGER: 'travel_manager',
    APPROVER: 'approver', // an employee who can approve other employees approval workflow requests.
    EMPLOYEE: 'employee',
    EXECUTIVE: 'executive', // has all travel manager features - but not considered as travel manager
    CONSUMER: 'consumer',
    TRAVEL_AGENT: 'travel_agent', // represents a user that sells hotels to customers
    REGIONAL_ADMIN: 'regional_admin',
    SUPER_TRAVEL_AGENT: 'super_travel_agent' // represents an agent which has extra abilities (e.g. handling other agents reservations)
};

CONSTANTS.SYSTEM_ROLES.GOD = CONSTANTS.SYSTEM_ROLES.SUPERVISOR;