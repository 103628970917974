import { Checkbox, FormControlLabel } from '@mui/material';

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Events from '@/events/Events';

import RoomContactsLight from './RoomContactsLight.react';

import Constants from '@/events/constants/ReservationPaymentPage';

import CheckboxActiveIcon from '@/components/pages/results/filters/components/CheckboxActiveIcon';
import CheckboxInactiveIcon from '@/components/pages/results/filters/components/CheckboxInactiveIcon';

const { SCROLL_TO_TRAVELER } = Constants;

const roomRefPrefix = 'roomRef';

class TravelersDetailsContainer extends React.Component {
	constructor(props) {
		super(props);
		this.scrollToRoom = this.scrollToRoom.bind(this);
		Events.on(SCROLL_TO_TRAVELER, this.scrollToRoom);
	}

	componentWillUnmount() {
		Events.removeEventListener(SCROLL_TO_TRAVELER, this.scrollToRoom);
	}

	scrollToRoom(travelersErrors) {
		// get the first room with errors
		let roomNumber = Object.keys(travelersErrors).sort()[0];
		let roomNode = this[`${roomRefPrefix}${roomNumber}`];
		if (roomNode) {
			roomNode.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
			});
		}
	}

	getTravelerPlurality(reservationData) {
		const travelers = _.get(reservationData, 'travelers', []);
		const singleTraveler = travelers.length === 1;
		if (singleTraveler) {
			return 'traveler';
		}
		return 'travelers';
	}
	// checkbox: Email me the voucher separately
	getSendEmailVoucherOnlyRender() {
		const {
			shouldLockForm,
			sendVoucherSeparatelyFromConfirmationMail,
			handleSendVoucherSeparatelyCheckboxChange,
			sendEmail,
		} = this.props;

		const label_text = 'Email me the voucher separately';
		const onChange = (event) => {
			localStorage.setItem('sendVoucherSeparatelyFromConfirmationMail', event.target.checked);

			if (handleSendVoucherSeparatelyCheckboxChange) {
				handleSendVoucherSeparatelyCheckboxChange(event);
			}
		};

		const disabled = shouldLockForm || sendEmail;

		return (
			<div className="send-email-container">
				<div className="checkbox-container">
					<FormControlLabel
						className={disabled ? 'checkbox-label-disabled' : ''}
						label={label_text}
						control={
							<Checkbox
								disabled={disabled}
								checked={sendVoucherSeparatelyFromConfirmationMail && !sendEmail}
								onChange={onChange}
								icon={<CheckboxInactiveIcon />}
								checkedIcon={<CheckboxActiveIcon />}
							/>
						}
					/>
				</div>
			</div>
		);
	}

	// checkbox: Send email confirmation and hotel voucher to traveler
	getSendEmailConfirmationRender() {
		const {
			reservationData,
			shouldLockForm,
			sendEmail,
			handleSendEmailCheckboxChange,
			sendVoucherSeparatelyFromConfirmationMail,
		} = this.props;

		const plurality = this.getTravelerPlurality(reservationData);
		const emailConfirmationLabelText = 'Send email confirmation and hotel voucher to ' + plurality;

		const disabled = shouldLockForm || sendVoucherSeparatelyFromConfirmationMail;

		return (
			<div className="send-email-container">
				<div className="checkbox-container">
					<FormControlLabel
						className={disabled ? 'checkbox-label-disabled' : ''}
						label={emailConfirmationLabelText}
						control={
							<Checkbox
								disabled={disabled}
								checked={sendEmail && !sendVoucherSeparatelyFromConfirmationMail}
								onChange={handleSendEmailCheckboxChange}
								icon={<CheckboxInactiveIcon />}
								checkedIcon={<CheckboxActiveIcon />}
							/>
						}
					/>
				</div>
			</div>
		);
	}

	getRoomsRender() {
		let { reservationData, createTravelerStatuses, shouldLockForm, profile } = this.props;

		const { send_traveler_emails_under_guest_email, show_send_voucher_separately_from_confirmation_mail } = profile;

		const _self = this;
		const rooms = _.get(reservationData, 'deal.rooms', 1);

		const sendEmailConfirmationRender = !send_traveler_emails_under_guest_email
			? this.getSendEmailConfirmationRender()
			: null;
		const sendEmailVoucherOnlyRender =
			profile.agent &&
			show_send_voucher_separately_from_confirmation_mail &&
			!send_traveler_emails_under_guest_email
				? this.getSendEmailVoucherOnlyRender()
				: null;

		return _.times(rooms, (idx) => {
			const room_number = idx + 1;
			const roomRefName = `${roomRefPrefix}${room_number}`;
			const room_key = `room-${room_number}`;
			_self[roomRefName] = React.createRef();

			return (
				<div className="room-contacts" key={room_key} ref={(r) => (_self[roomRefName] = r)}>
					<RoomContactsLight
						count={room_number}
						reservation={reservationData}
						createTravelerStatuses={createTravelerStatuses}
						disabled={shouldLockForm}
						sendEmailVoucherOnlyRender={
							room_number === rooms && !reservationData.private_travel ? sendEmailVoucherOnlyRender : null
						}
						sendEmailConfirmationRender={
							room_number === rooms && !reservationData.private_travel
								? sendEmailConfirmationRender
								: null
						}
						autoFill={this.props.autoFill}
					/>
				</div>
			);
		});
	}

	render() {
		let { profile, contractClientName } = this.props;

		return (
			<div className="traveler-details-container">
				{profile.agent && contractClientName ? (
					<h4 className="client-name">Client Name: {contractClientName}</h4>
				) : null}

				{this.getRoomsRender()}
			</div>
		);
	}
}

TravelersDetailsContainer.propTypes = {
	profile: PropTypes.any.isRequired,
	reservationData: PropTypes.any.isRequired,
	createTravelerStatuses: PropTypes.array.isRequired,
	shouldLockForm: PropTypes.bool.isRequired,
	contractClientName: PropTypes.string.isRequired,
	sendEmail: PropTypes.bool,
};

export default TravelersDetailsContainer;
