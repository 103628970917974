import _ from 'lodash';
import Ajax from '../utils/Ajax';

var LogActions = {
	logError: function (data) {
		let payload = {};
		payload.type = 'error';
		payload.message = _.get(data, 'error.message', '///');
		payload.stack = _.get(data, 'error.stack', '///');
		payload.context = _.get(data, 'context', '///');

		Ajax.logError(payload)
			.done(function (res) {})
			.fail(function (err) {});
	},
};

export default LogActions;
