import React from 'react';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';

import FiltersActions from '../../../../actions/FiltersActions';

import Currencies from '../../../../utils/Currencies';

import FiltersConstants from '../../../../constants/FiltersConstants';
import Analytics from 'arbitrip-common/client/analytics';

const isIntegerRegex = /^[1-9]\d*$/;

const PriceFilter = createClass({
	displayName: 'PriceFilter',

	componentDidMount: function () {
		setTimeout(FiltersActions.updateCurrencies);
	},

	// delay: (function () {
	//     let timer = 0;

	//     return function (callback, millis) {
	//         clearTimeout(timer);
	//         timer = setTimeout(callback, millis);
	//     };
	// })(),

	onMaxPricePerNightChange: function (e) {
		const price = e.target.value;
		const { profile } = this.props;
		const { display_currency } = this.props.profile;
		const _self = this;

		// this.delay(function () {
		if ((price === '' || isIntegerRegex.test(price)) && _self.props.filter !== Number(price)) {
			console.log('delayed price filter callback');
			// TODO: verify is a number...

			FiltersActions.filterByPrice(price, display_currency);
			setTimeout(function () {
				FiltersActions.filterByPrice(price, display_currency);
			}, 10);

			const filter_value = Currencies.getPriceWithCurrency(price, display_currency);
			Analytics.actions.interactions.filteredResults(profile, FiltersConstants.FILTER_METHOD.PRICE, filter_value);
		}
		// }, 500);
	},

	onClearClick: function (e) {
		const { profile, filter } = this.props;

		if (filter) {
			const { display_currency } = this.props.profile;
			FiltersActions.filterByPrice(0, display_currency);
			setTimeout(function () {
				FiltersActions.filterByPrice(0, display_currency);
			}, 10);
			Analytics.actions.interactions.filteredResults(profile, FiltersConstants.FILTER_METHOD.PRICE, 'None');
		}
	},

	render: function () {
		const { display_currency } = this.props.profile;
		const maxPricePerNight = this.props.filter;

		const currencySymbol = Currencies.getSymbol(display_currency);
		const priceText = maxPricePerNight || '';
		const priceInputClass = `form-control${maxPricePerNight ? '' : ' empty'}`;

		return (
			<div className="filter">
				<label className="filter-title" htmlFor="hotel-price-input">
					Maximum Price Per Night
				</label>

				<div className="currency-input-wrapper">
					<input
						id="hotel-price-input"
						type="text"
						className={priceInputClass}
						aria-describedby="sizing-addon2"
						aria-label={`Maximum Price Per Night, ${currencySymbol}`}
						value={priceText}
						onChange={this.onMaxPricePerNightChange}
						maxLength="8"
					/>
					<div className="currency-icon" id="sizing-addon2">
						{currencySymbol}
					</div>
				</div>

				<button className="clear-button" onClick={this.onClearClick} aria-label="Clear" />
				<button
					className="clear-button-wrap"
					onClick={this.onClearClick}
					aria-label="Clear Maximum Price Per Night"
				>
					<div className="clear-button-img" />
				</button>
			</div>
		);
	},
});

PriceFilter.propTypes = {
	filter: PropTypes.any.isRequired,
	profile: PropTypes.object.isRequired,
};

export default PriceFilter;
