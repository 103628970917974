import _ from 'lodash';

import { Bar, Doughnut } from 'react-chartjs-2';

import $ from 'jquery';

import moment from 'moment';

import React from 'react';
import createClass from 'create-react-class';

import ReportsStore from '../../../stores/ReportsStore';
import ReportsActions from '../../../actions/ReportsActions';
import ReportsConstants from '../../../constants/ReportsConstants';
import ReportsFilterComponent from './ReportsFilterComponent.react';
import { Link } from 'react-router-dom';
import Currencies from '../../../utils/Currencies';
// import MapBox from '../../../components/pages/results/filters/MapBox.react';
import ProfileStore from '../../../stores/ProfileStore';
import RouterWrapper from '../../../utils/RouterWrapper';
import TextReport from './TextReport.react';

import TopCitiesTable from './TopCitiesTable.react';
import TopTravelersTable from './TopTravelersTable.react';
import TopOOPBookingsTable from './TopOOPBookings.react';

import Analytics from 'arbitrip-common/client/analytics';

import TitleManager from '../../../utils/TitleManager';
import { withRouter } from '../../../utils/withRouter';
import DocumentTitle from '../../general/DocumentTitle.react';

// import ManagementReportsPage from '../management-reports/ManagementReportsPage.react';

function chartifyMonthlyReportsData(monthlyReports, dataKey, startDate, endDate, color) {
	var chartData = {
		labels: [],
		datasets: [
			{
				// label: '# of Votes', // TODO: rename-

				backgroundColor: [],
				borderColor: [],

				borderWidth: 1,

				// hoverBackgroundColor: [],
				// hoverBorderColor: [],

				data: [],
			},
		],
	};

	var gray = 'rgba(208, 208, 208, 1)'; //0.2)'; // d0 d0 d0
	// console.log("+++ +++ +++", startDate, endDate);
	for (var i = 0; i < monthlyReports.length; i++) {
		chartData.labels.push(monthlyReports[i].label.substr(0, 3));

		var monthYear = moment()
			.year(monthlyReports[i].year)
			.month(monthlyReports[i].month - 1)
			.date(15);
		// console.log("monthlyReports[i]",monthlyReports[i],"|||",monthYear);
		var isInRange = monthYear.isSameOrAfter(startDate) && monthYear.isSameOrBefore(endDate);
		chartData.datasets[0].backgroundColor.push(isInRange ? color : gray);
		chartData.datasets[0].borderColor.push(isInRange ? color : gray);

		// console.log("*** *** ***", monthYear, isInRange);

		chartData.datasets[0].data.push(monthlyReports[i][dataKey]);
	}

	return chartData;
}

function chartifyMonthlyReportsOptions(currency, numberOfGridLines) {
	var chartOptions = {
		tooltips: {
			custom: function (tooltip) {
				// Added by us
				tooltip.yAlign = 'above';
				// console.log("TT", tooltipEl, tooltip);
				tooltip.title = [];
				if (
					Array.isArray(tooltip.body) &&
					tooltip.body.length > 0 &&
					Array.isArray(tooltip.body[0].lines) &&
					tooltip.body[0].lines[0]
				) {
					tooltip.body[0].lines[0] =
						currency + parseFloat(tooltip.body[0].lines[0].replace(': ', '')).toLocaleString();
				}

				// Tooltip Element
				var tooltipEl = $('#chartjs-tooltip');
				if (!tooltipEl[0]) {
					$('body').append('<div id="chartjs-tooltip"></div>');
					tooltipEl = $('#chartjs-tooltip');
				}
				// Hide if no tooltip
				if (!tooltip.opacity) {
					tooltipEl.css({
						opacity: 0,
					});
					$('.chartjs-wrap canvas').each(function (index, el) {
						$(el).css('cursor', 'default');
					});
					return;
				}
				// $(this._chart.canvas).css('cursor', 'pointer'); // commented out by us
				// Set caret Position
				tooltipEl.removeClass('above below no-transform');
				if (tooltip.yAlign) {
					tooltipEl.addClass(tooltip.yAlign);
				} else {
					tooltipEl.addClass('no-transform');
				}
				// Set Text
				if (tooltip.body) {
					var innerHtml = [
						(tooltip.beforeTitle || []).join('\n'),
						(tooltip.title || []).join('\n'),
						(tooltip.afterTitle || []).join('\n'),
						(tooltip.beforeBody || []).join('\n'),
						(tooltip.body || []).join('\n'),
						(tooltip.afterBody || []).join('\n'),
						(tooltip.beforeFooter || []).join('\n'),
						(tooltip.footer || []).join('\n'),
						(tooltip.afterFooter || []).join('\n'),
					];
					tooltipEl.html(innerHtml.join('\n'));
				}
				// Find Y Location on page
				var top = 0;
				if (tooltip.yAlign) {
					if (tooltip.yAlign === 'above') {
						top = tooltip.y - tooltip.caretHeight - tooltip.caretPadding;
					} else {
						top = tooltip.y + tooltip.caretHeight + tooltip.caretPadding;
					}
				}
				var position = $(this._chart.canvas)[0].getBoundingClientRect();
				// Display, position, and set styles for font
				tooltipEl.css({
					opacity: 1,
					width: tooltip.width ? tooltip.width + 'px' : 'auto',
					left: position.left + tooltip.x + 'px',
					top: position.top + top + 'px',
					fontFamily: tooltip._fontFamily,
					fontSize: tooltip.fontSize,
					fontStyle: tooltip._fontStyle,
					padding: tooltip.yPadding + 'px ' + tooltip.xPadding + 'px',
				});

				tooltip.displayColors = false;
			},
		},
		legend: {
			display: false,
		},
		scales: {
			xAxes: [
				{
					display: true,
					gridLines: {
						display: false,
					},
					scaleLabel: {
						display: false,
					},
					ticks: {
						display: true,
					},
				},
			],
			yAxes: [
				{
					display: true,
					gridLines: {
						display: true,
						// color: "rgba(0, 0, 0, 0)",
						drawBorder: false,
						// zeroLineWidth: 0,
						// zeroLineColor: "rgba(0, 0, 0, 0)"
					},

					scaleLabel: {
						display: false,
					},
					ticks: {
						display: false,
						maxTicksLimit: numberOfGridLines,
					},
				},
			],
		},
	};

	return chartOptions;
}

function getReportsPageState() {
	const profile = ProfileStore.getProfile();

	// console.log("getting reports page state...", ReportsStore.getReportsSummary(), ReportsStore.getReportsGraphsData());
	return {
		profile,
		startDate: ReportsStore.getStartDate(),
		endDate: ReportsStore.getEndDate(),
		reports: ReportsStore.getReportsSummary(),
		graphsData: ReportsStore.getReportsGraphsData(),
		status: ReportsStore.getStatus(),
		client_contract_id: ReportsStore.getClientContractId(),

		reports_page_title: TitleManager.buildDefaultPageTitle({ profile, page: 'Reports Dashboard' }),
	};
}

// Define main Controller View
var ReportsPage = createClass({
	displayName: 'ReportsPage',

	// Get initial state from stores
	getInitialState: function () {
		return getReportsPageState();
	},

	// Add change listeners to stores
	componentDidMount: function () {
		const { profile } = this.state;

		if (!profile.reports_dashboard) {
			const { navigate } = this.props;
			return RouterWrapper.goToSearchPage(navigate);
		}

		ReportsStore.addChangeListener(this._onChange);

		setTimeout(() => {
			let self_or_first_contract = this.state.client_contract_id;
			if (!self_or_first_contract) {
				let self_or_first_contract = ProfileStore.getSelfOrFirstContract();
				if (self_or_first_contract) {
					ReportsActions.updateReportsClientContractId(self_or_first_contract._id);
					return; // don't double invoke
				}
			}
			ReportsActions.getReports(this.state.startDate, this.state.endDate, self_or_first_contract);
		}, 1);

		window.scrollTo(0, 0);
		Analytics.actions.views.dashboardPage(profile);
	},

	// Remove change listers from stores
	componentWillUnmount: function () {
		ReportsStore.removeChangeListener(this._onChange);
	},

	chartifyTotalSpent: function () {
		var green = 'rgba(42, 196, 91, 1)'; //0.2)'; // 2a c4 5b
		return chartifyMonthlyReportsData(
			this.state.graphsData,
			'totalSpent',
			this.state.startDate,
			this.state.endDate,
			green,
		);
	},

	chartifyAverageSpentPerNight: function () {
		// var orange = 'rgba(243, 102, 74, 1)';//0.2)'; // f3 66 4a
		// var orange = 'rgba(243, 170, 111, 1)';
		var orange = 'rgba(243, 136, 74, 1)';
		return chartifyMonthlyReportsData(
			this.state.graphsData,
			'averagePricePerNight',
			this.state.startDate,
			this.state.endDate,
			orange,
		);
	},

	// Render our child components, passing state via props
	render: function () {
		var profile = this.state.profile;
		///
		/// Disbled Management Trips Custom Reports
		///
		// if (profile && profile.company && profile.company.settings && profile.company.settings.management_enabled) {
		//   return <ManagementReportsPage />;
		// }

		// console.log("REPORTS is rendering...");
		var loaderClasses = 'reports-loading';
		if (this.state.reports && Object.keys(this.state.reports).length > 0) {
			loaderClasses += ' on-top';
		}

		var LOADER = (
			<div className={loaderClasses}>
				<i title="Getting reports..." className="fa fa-spin fa-spinner fa-1x" />
			</div>
		);

		var REPORTS_PAGE_CONTENT;

		if (!this.state.reports || Object.keys(this.state.reports).length === 0) {
			// at this point, there are no reports...

			if (this.state.status === ReportsConstants.STATUS.BUSY) {
				// TODO: show loader (no filters, no table)
				REPORTS_PAGE_CONTENT = LOADER;
			} else {
				// at this point the status might be: initial / success / fail

				// TODO: show message (different from loader)
				REPORTS_PAGE_CONTENT = (
					<div className="reports-notes">
						<span>It looks like you haven’t booked anything yet.</span>
						<br />
						<span>
							<Link to="/search">Start now</Link> and become a travel champion!
						</span>
					</div>
				);
			}
		} else {
			var reports = this.state.reports;
			var graphsData = this.state.graphsData;

			var reportsClasses = 'reports-container';
			var ON_TOP_LOADER;
			if (this.state.status === ReportsConstants.STATUS.BUSY) {
				reportsClasses += ' loading';
				ON_TOP_LOADER = LOADER;
			}

			var CURRENCY_SYMBOL = Currencies.getSymbol(reports.currency);

			var totalSpentChartData = this.chartifyTotalSpent();
			var averageSpentPerNightChartData = this.chartifyAverageSpentPerNight();
			var totalSpentChartOptions = chartifyMonthlyReportsOptions(CURRENCY_SYMBOL, 4);
			var averageSpentPerNightChartOptions = chartifyMonthlyReportsOptions(CURRENCY_SYMBOL, 5);

			var TOTAL_SPENT_CHART_REPORT =
				reports && graphsData ? (
					<div className="chart-report total-spent">
						<TextReport
							currency={reports.currency}
							reportValue={reports.totalSpent}
							reportDescriptionLines={['Total', 'spend']}
							graphTextReport={true}
						/>
						<div className="bar-chart-container">
							<Bar data={totalSpentChartData} options={totalSpentChartOptions} height={200} redraw />
						</div>
					</div>
				) : null;

			var AVERAGE_PRICE_PER_NIGHT_CHART_REPORT =
				reports && graphsData ? (
					<div className="chart-report average-price-per-night">
						<TextReport
							currency={reports.currency}
							reportValue={reports.averagePricePerNight}
							reportDescriptionLines={['Average spend', 'per night']}
							graphTextReport={true}
						/>
						<div className="bar-chart-container">
							<Bar
								data={averageSpentPerNightChartData}
								options={averageSpentPerNightChartOptions}
								height={200}
								redraw
							/>
						</div>
					</div>
				) : null;

			var TOP_CITIES_TABLE = _.get(reports, 'topCities', '').length ? (
				<div className="top-cities-table-container table-container">
					<TopCitiesTable data={reports.topCities.slice(0, 10)} currency={reports.currency} />
				</div>
			) : null;

			var TOP_TRAVELERS_TABLE = _.get(reports, 'topTravelers', '').length ? (
				<div className="top-travelers-table-container table-container">
					<TopTravelersTable data={reports.topTravelers.slice(0, 10)} currency={reports.currency} />
				</div>
			) : null;

			var OOP_TOP_BOOKINGS =
				reports && reports.show_travel_policy_stats ? (
					<div className="oop-table table-container">
						<TopOOPBookingsTable data={reports.topOOPBookings.slice(0, 10)} currency={reports.currency} />
						{/* <a className="btn btn-link export">
            Download All
              </a> */}
					</div>
				) : null;

			var out_of_policy_spendings_percentage = (
				(reports.total_out_of_policy_spendings / reports.totalSpent) *
				100
			).toFixed(1);
			var in_policy_spendings_percentage = 100 - out_of_policy_spendings_percentage;

			var OOP_DOUGHNUT =
				reports && reports.show_travel_policy_stats ? (
					<div className="oop-gauge-container">
						<Doughnut
							data={{
								datasets: [
									{
										data: [
											reports.totalSpent - reports.total_out_of_policy_spendings,
											reports.total_out_of_policy_spendings,
										],
										backgroundColor: ['rgba(42, 196, 91, 1)', 'rgba(243, 136, 74, 1)'],
										hoverBackgroundColor: [
											// '#FF6384',
											// '#36A2EB',
										],
									},
								],

								// These labels appear in the legend and in the tooltips when hovering different arcs
								labels: [
									'In Policy (' + in_policy_spendings_percentage + '%)',
									'Out of Policy (' + out_of_policy_spendings_percentage + '%)',
								],
							}}
							options={{ circumference: Math.PI, rotation: -Math.PI }}
						/>
						<p className="title">
							{Currencies.getPriceWithCurrency(reports.totalOOPSpent, reports.currency)} Spent out of
							policy
						</p>
					</div>
				) : null;
			var startDate = moment(this.state.startDate);
			var endDate = moment(this.state.endDate);
			var reportHeader = '';
			if (profile.agent) {
				let client_company = ProfileStore.getContractClientCompany(this.state.client_contract_id);
				reportHeader += (client_company ? client_company.name : profile.company.name) + ': ';
			}
			reportHeader += startDate.format('MMMM YYYY') + ' - ' + endDate.format('MMMM YYYY'); // + " Report";

			var rounded_average_booking_window_in_days = Math.round(reports.averageBookingWindowInDays);

			REPORTS_PAGE_CONTENT = (
				<div className={reportsClasses}>
					<div className="report-header">
						<span>{reportHeader}</span>
					</div>
					<div className="upper">
						<div className="text-reports-container">
							<TextReport
								reportValue={reports.total_bookings}
								reportDescriptionLines={['Total Bookings']}
							/>
							<TextReport
								reportValue={reports.total_room_nights}
								reportDescriptionLines={['Total Room Nights']}
							/>
							<TextReport
								reportValue={reports.averageLengthOfStayInDays}
								reportUnit={reports.averageLengthOfStayInDays === 1 ? 'night' : 'nights'}
								reportDescriptionLines={['Average Stay']}
							/>
							<TextReport
								reportValue={rounded_average_booking_window_in_days}
								reportUnit={rounded_average_booking_window_in_days === 1 ? 'day' : 'days'}
								reportDescriptionLines={['Average Booking Window']}
							/>
						</div>
						<div className="chart-reports-container">
							{TOTAL_SPENT_CHART_REPORT}
							{AVERAGE_PRICE_PER_NIGHT_CHART_REPORT}
						</div>
					</div>
					<div className="bottom">
						{TOP_CITIES_TABLE}

						{TOP_TRAVELERS_TABLE}
						{/*<div className="top-cities-map-container">*/}
						{/*<MapBox />*/}
						{/*</div>*/}
					</div>
					<div className="oop">
						<div className="left-pie">{OOP_DOUGHNUT}</div>
						<div className="right-table">{OOP_TOP_BOOKINGS}</div>
					</div>
				</div>
			);
		}

		// if (this.props.route.prod) {

		return (
			<DocumentTitle title={this.state.reports_page_title}>
				<div className="reports-page">
					<div className="filters-container">
						<ReportsFilterComponent />
					</div>
					{REPORTS_PAGE_CONTENT}
					{ON_TOP_LOADER}
				</div>
			</DocumentTitle>
		);
		// } else {
		//   return (
		//   {/*<div className="reports-page">*/}
		//   {/*<img src="./img/reports-page-transparent-final.png"/>*/}
		// </div>
		// );
		// }
	},

	// Method to setState based upon Store changes
	_onChange: function () {
		this.setState(getReportsPageState());
	},
});

export default withRouter(ReportsPage);
