const { SUPPORTED_LANGUAGES } = require('../../translation/const')
const _ = require('lodash');
const moment = require('moment');
const { default: ClientPriceService } = require('../utils/ClientPriceService');

const CancelPolicy = require('./CancelPolicy');
const DateUtils = require('../utils').Dates;

const { getCatering } = require('../utils').Catering;
const { getRoomName } = require('../utils').DealRoomName;

const { DEALS_STATUS } = require('../constants').HotelConstants;
const {
    OUT_OF_POLICY_MARK_TYPE,
    OUT_OF_POLICY_STATE
} = require('../constants').ReservationConstants;

const double_for_single = "DFS";
const UNDEFINED = 'UN';

class Deal {
    constructor(deal, deal_index) {
        if (!deal || !deal.id) {
            return console.error('Cannot build deal object, no payload provided');
        }

        this.id = deal.id;
        this.dca = deal.dca; // Just keep it in case of a copy constructor
        this.details = deal.details;// Just keep it in case of a copy constructor
        this.status = DEALS_STATUS.INITIAL;

        this.nights = _.get(deal, 'details.nights');
        this.rooms = _.get(deal, 'rooms') || _.get(deal, 'details.room_count');
        this.guestsPerRoom = _.get(deal, 'details.guest_count', 1);
        const check_in = _.get(deal, 'details.check_in');
        if (check_in) {
            this.check_in = moment.utc(check_in).endOf('day');
        }
        const check_out = _.get(deal, 'details.check_out');
        if (check_out) {
            this.check_out = moment.utc(check_out).endOf('day');
        }

        if (_.get(deal, 'details.children_count')) {
            this.children_count = _.get(deal, 'details.children_count', 0);
        }

        if (_.get(deal, 'details.children_ages')) {
            this.children_ages = _.get(deal, 'details.children_ages', []);
        }

        if (_.get(deal, 'details.private_travel')) {
            this.private_travel = _.get(deal, 'details.private_travel', false);
        }

        // attributes
        this.breakfast = _.get(deal, 'dca.heuristicOptions.includesBreakfast', false) || _.get(deal, 'details.breakfast_included', false);
        this.catering = _.get(deal, 'dca.heuristicOptions') && getCatering(deal.dca.heuristicOptions);
        if (!this.catering && this.breakfast) {
            this.catering = 'Breakfast Included';
        }
        this.refundable = _.get(deal, 'details.non_refundable') && !deal.details.non_refundable; // make sure you really take the chooseprod DCA value?
        let refundable_until = _.get(deal, 'dca.dealIsFullyRefundableTill');
        this.freeCancellationUntil = moment(refundable_until).toDate();
        this.should_check_availability = true;
        this.cancelPolicies = [];

        if (deal.room_id) {
            this.room_id = deal.room_id;
        }

        if (_.get(deal, 'details.bed_type')) {
            this.bed_type = _.get(deal, 'details.bed_type');
        }

        if (deal.supplier_hub_code) {
            this.supplier_hub_code = deal.supplier_hub_code;
        }
        const cancellation_policies = _.get(deal, 'dca.cheapopoCancellationPolicy', []);
        this.cancelPolicies = cancellation_policies.reduce((acc, cancel_policy) => {
            acc.push(new CancelPolicy(cancel_policy));
            return acc;
        }, []);
        this.cancellationPoliciesText = _.get(deal, 'dca.cancellationPoliciesText', '');
        this.cancellationPoliciesMultiLanguageTexts = _.get(deal, 'dca.cancellationPoliciesMultiLanguageTexts', {
            [SUPPORTED_LANGUAGES.EN]: '',
            [SUPPORTED_LANGUAGES.HE]: ''
        });

        this.setPricing(deal.cheapopoPricing, deal.details.pureExpediaPrices);

        this.dynamicPricing = deal.dynamic_pricing;

        if (deal.details) {
            this.does_price_include_tax = deal.details.does_price_include_tax;

            this.post_pay = deal.details.post_pay;

            this.original_negotiated_rate = deal.details.original_negotiated_rate;
            this.negotiated_rate = deal.details.negotiated_rate;
            this.negotiated_rate_display_name = deal.details.negotiated_rate_display_name;

            this.hotel_currency = deal.details.hotel_currency;
            this.hotel_price = deal.details.hotel_price;

            if (deal.details.bookable) {
                this.bookable = true;
            }

            this.freeCancellationUntil = deal.details.refundable_until;

            if (deal.id.startsWith('S:')) {
                this.freeCancellationUntil = moment(deal.details.refundable_until).toDate();

                this.chooseprod = {
                    dca: Object.assign({}, deal.dca, {
                        nonRefundable: !deal.details.refundable,
                        dealIsFullyRefundableTill: deal.details.refundable_until
                    }),
                };
            }
        }

        this.original = deal;
        this.room_group = _.get(deal, 'dca.room_group', 0);
        this.room_group_description = _.get(deal, 'dca.room_group_description', UNDEFINED).toLowerCase();
        this.room_group_details = _.get(deal, 'dca.room_group_details');
        
        if (_.get(deal, 'details.room_description')) {
            const name = _.get(deal, 'details.room_description', '');
            this.description = getRoomName(name);
            this.room = this.description;
        }

        this.setTravelPolicy(deal);

        // board codes
        this.board_bases = {
            half_board: deal.dca.heuristicOptions.isHalfBoard,
            full_board: deal.dca.heuristicOptions.isFullBoard,
            all_inclusive: deal.dca.heuristicOptions.isAllInclusive
        };

        this.wifi = _.get(deal, 'details.wifi_included');

        if (deal.extended_deal_information_for_debugging) { // for debug purposes only
            this.extended_deal_information_for_debugging = deal.extended_deal_information_for_debugging;
        }

        if (deal.identifiers && deal.identifiers.supplier_name) {
            this.supplier_name = deal.identifiers.supplier_name;
        }

        if (_.get(deal, 'details.price_estimated')) {
            this.estimated_price = _.get(deal, 'details.price_estimated');
        }
        this.superseded = false; // When a better deal appears mark this as superseded

        this.superdeal = _.get(deal, 'details.superdeal');
        this.package = _.get(deal, 'details.is_package');
        this.corporate = _.get(deal, 'details.is_corporate');
        this.fenced_deal = _.get(deal, 'details.fenced_deal');

        this.deal_index = (deal_index || -1);
        if (deal.recheck_id) {
            this.setRecheck(deal.recheck_id, deal.chooseprod, deal.cheapopoPricing, deal.installments_eligibility);
        }
        if (deal.cashier_customer_session_id || deal.cashier_token) {
            this.setRecheckCashier(deal);
        }
    }

    setAgentPricing(selfContract) {
        this.agent_pricing = ClientPriceService.getAgentPricingBySelfContract(this, selfContract);
    }

    setRecheckId(recheckId) {
        this.recheck_id = recheckId;
    }

    setRecheckCashier(deal) {
        this.cashier_customer_session_id = deal.cashier_customer_session_id;
        this.cashier_token = deal.cashier_token;
    }

    setChooseprod(chooseprod) {
        this.chooseprod = chooseprod;

        // overrides originals
        this.refundable = !_.get(chooseprod, 'dca.nonRefundable');
        let cheapopoCancellationPolicy = _.get(chooseprod, 'dca.cheapopoCancellationPolicy');

        if (cheapopoCancellationPolicy && cheapopoCancellationPolicy.length) {
            for (let i = 0; i < cheapopoCancellationPolicy.length; i++) {
                this.cancelPolicies = [];
                this.cancelPolicies.push(new CancelPolicy(cheapopoCancellationPolicy[i]));
            }
        }
    }

    setPreviousPricing(primalDeal) {
        this.previousPricePerNight = (primalDeal || this).pricePerNight;
        this.previousPrice = (primalDeal || this).totalPrice;
    }

    setPricing(pricing, expediaPricing) {
        if (!pricing && !expediaPricing) {
            return;
        }
        this.currency = expediaPricing?.currency ?? pricing.currency;
        this.pricePerNight = expediaPricing?.pricePerNight ?? pricing.pricePerNight;
        this.totalPrice = expediaPricing?.totalPrice ?? pricing.cheapopoTotalPrice;
        this.totalPriceInUsd = pricing.totalPriceInUsd;

        if (typeof pricing.arbiPrice !== 'undefined') {
            this.net_price_total = pricing.arbiPrice;
        }
        if (typeof pricing.commissionTotal !== 'undefined') {
            this.commission_total = pricing.commissionTotal;
        }
        if (pricing.taxes_and_fees) {
            this.taxes_and_fees = pricing.taxes_and_fees;
            this.taxes_and_fees_currency = pricing.taxes_and_fees_currency || 'USD';
        }
        if (pricing.tax_service_fee) {
            this.tax_service_fee = pricing.tax_service_fee;
            this.tax_service_fee_currency = pricing.tax_service_fee_currency || 'USD';
        }
        if (expediaPricing) {
            this.expedia_pricing = expediaPricing;
        }

        if (pricing.sales_tax) {
            this.sales_tax = pricing.sales_tax;
            this.sales_tax_currency = pricing.sales_tax_currency || 'USD';
        }
        if (pricing.payments_excluded) {
            this.payments_excluded = pricing.payments_excluded;
            this.payments_excluded_currency = pricing.payments_excluded_currency || 'USD';
            this.payments_excluded_raw = pricing.payments_excluded_raw;
        }
        if (pricing.faultDeal) {
            this.faultDeal = true;
        }
    }

    setRecheckStatus() {
        this.Status = DEALS_STATUS.SUCCESS;
    }

    setInstallmentsEligibility(installmentsEligibility) {
        this.installments_eligibility = !!installmentsEligibility;
    }

    setRecheck(recheckId, chooseprod, pricing, installmentsEligibility) {
        this.setRecheckId(recheckId);
        this.setChooseprod(chooseprod);
        this.setPreviousPricing(); // before setPricing
        this.setPricing(pricing);
        this.setRecheckStatus();
        this.setInstallmentsEligibility(installmentsEligibility);
    }

    setTravelPolicy(deal) {
        this.in_policy = deal.in_policy;
        this.out_of_policy_state = OUT_OF_POLICY_STATE.BYPASS;

        if (deal.tp_config) {
            this.out_of_policy_message = deal.tp_config.message;
            if (deal.tp_config.block) {
                this.out_of_policy_state = OUT_OF_POLICY_STATE.BLOCK;
            } else if (deal.tp_config.warn) {
                this.out_of_policy_state = OUT_OF_POLICY_STATE.WARNING;
            }
        }
    }

    setOptimizationData(optimized_data) {
        let recheck_id = _.get(this, 'recheck_id');

        if (recheck_id && optimized_data && optimized_data.optimized) {
            this.optimizedData = optimized_data;

            this.pricePerNight = optimized_data.new_price;
            this.totalPrice = optimized_data.new_price * this.nights;
        }
    }

    setOptimizedTravelPolicy(travel_policy) {
        let tp_config = _.get(this, 'original.tp_config');

        if (tp_config && travel_policy) {
            if (tp_config.mark_type === OUT_OF_POLICY_MARK_TYPE.PRICE && this.pricePerNight <= travel_policy.price) {
                this.in_policy = true;
                this.out_of_policy_state = OUT_OF_POLICY_STATE.NONE;
                this.out_of_policy_message = '';
            }
        }
    }

    resetOptimizedData() {
        if (this.optimizedData) {
            delete this.optimizedData;
        }

        this.pricePerNight = this.original.cheapopoPricing.pricePerNight;
        this.totalPrice = this.original.cheapopoPricing.cheapopoTotalPrice;
    }

    resetOptimizedTravelPolicy() {
        let tp_config = _.get(this, 'original.tp_config', null);
        let in_policy = _.get(this, 'original.in_policy', null);
        this.setTravelPolicy({ tp_config, in_policy });
    }

    /// TODO! Should this be in common, or in a override class
    set Status(status) {
        this.status = status;
    }

    set Error(error) {
        this.error = error;
    }

    set Superseded(superseded) {
        this.superseded = superseded;
    }

}

module.exports = Deal;
