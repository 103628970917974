import React from 'react';
import { Button } from '@mui/material';
import ReservationConstants from '../../../constants/ReservationConstants';
import MaterialDesignDialog from '../../general/MaterialDesignDialog.react';
import ReservationActions from '../../../actions/ReservationActions';

const CancelReservationDialog = ({ open, onClose, booking, profile, in_cancel_policy }) => {
	if (!booking) {
		return null;
	}

	const closeCancelModal = () => {
		// TODO: why do we need this: because it close modal on 'OK' button click when cancellation failed
		ReservationActions.resetReservationCancellationStatus(booking.id);
		onClose();
	};

	const cancelBooking = () => {
		console.log('Canceling booking: ', booking);
		ReservationActions.cancelReservation(profile, booking);

		onClose();
	};

	const getFooter = () => {
		if (booking.status !== ReservationConstants.RESERVATION_STATUS.CANCELING) {
			return (
				<div className="cancel-modal-actions">
					<Button color="primary" onClick={cancelBooking}>
						I'm sure
					</Button>
					<Button color="primary" onClick={closeCancelModal}>
						Take me back
					</Button>
				</div>
			);
		} else {
			return (
				<div className="canceling-modal-status">
					<i title="Canceling reservation..." className="fa fa-spin fa-spinner" />
					<span>Canceling...</span>
				</div>
			);
		}
	};

	const getFailedFooter = () => (
		<div className="canceling-modal-status">
			<span>Bummer, It looks like there was a problem canceling your booking.</span>
			<br />
			<span>
				Please contact our{' '}
				<a href="mailto:support@arbitrip.com" target="_blank">
					support team
				</a>
				.
			</span>
			<br />
			<Button color="primary" onClick={closeCancelModal}>
				OK
			</Button>
		</div>
	);

	return (
		<MaterialDesignDialog
			open={open}
			onClose={onClose}
			closeTimeoutInMilliseconds={150}
			title="Cancel Reservation?"
			content={
				<React.Fragment>
					<div className="cancel-reservation-modal-content">
						<div className="description">
							{booking.deal.dca.cancellationPoliciesText && (
								<span style={in_cancel_policy ? {} : { color: 'red', fontWeight: 'bold' }}>
									{in_cancel_policy
										? 'Cancellation may incur costs according to the following:'
										: 'Cancellation will incur costs according to the following:'}
								</span>
							)}
						</div>
						<div className="cancellation-policy">{booking.deal.dca.cancellationPoliciesText}</div>
					</div>
					{booking.cancelStatus !== ReservationConstants.STATUS.FAILED ? getFooter() : getFailedFooter()}
				</React.Fragment>
			}
		/>
	);
};

export default CancelReservationDialog;
