import AppDispatcher from '../dispatcher/AppDispatcher';
import DealConstants from '../constants/DealConstants';

var DealActions = {
	chooseDeal: function (data) {
		AppDispatcher.handleAction({
			actionType: DealConstants.CHOOSE_DEAL,
			data: data, // ???
		});
	},
};

export default DealActions;
