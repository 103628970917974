import { useEffect, useRef } from 'react';
import _ from 'lodash';

const useScrollToTopOnBookingChange = (booking) => {
	const drawerRef = useRef();
	const prevBookingRef = useRef();

	const scrollToTop = () => {
		if (drawerRef.current) {
			drawerRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	};

	useEffect(() => {
		if (!_.isEqual(prevBookingRef.current, booking)) {
			scrollToTop();
		}
		prevBookingRef.current = booking;
	}, [booking]);

	return drawerRef;
};

export default useScrollToTopOnBookingChange;
