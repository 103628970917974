import AppDispatcher from '../dispatcher/AppDispatcher';
import SortConstants from '../constants/SortConstants';

var SortActions = {
	sortByMarginPercentage: function () {
		AppDispatcher.handleAction({
			actionType: SortConstants.SORT_BY_MARGIN_PERCENTAGE,
		});
	},

	sortByAbsoluteMargin: function () {
		AppDispatcher.handleAction({
			actionType: SortConstants.SORT_BY_ABSOLUTE_MARGIN,
		});
	},

	sortByRecommended: function () {
		AppDispatcher.handleAction({
			actionType: SortConstants.SORT_BY_RECOMMENDED,
		});
	},

	sortByScores: function () {
		AppDispatcher.handleAction({
			actionType: SortConstants.SORT_BY_SCORES,
		});
	},

	sortByPrice: function () {
		AppDispatcher.handleAction({
			actionType: SortConstants.SORT_BY_PRICE,
		});
	},
	sortByPriceHighToLow: function () {
		AppDispatcher.handleAction({
			actionType: SortConstants.SORT_BY_PRICE_HIGH_TO_LOW,
		});
	},
	sortByDistance: function () {
		AppDispatcher.handleAction({
			actionType: SortConstants.SORT_BY_DISTANCE,
		});
	},

	sortByReviews: function () {
		AppDispatcher.handleAction({
			actionType: SortConstants.SORT_BY_REVIEWS,
		});
	},
};

export default SortActions;
