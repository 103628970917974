function download(data, file_name, type) {
	var file = new Blob([data], { type: type });
	var link = document.createElement('a');
	var url = URL.createObjectURL(file);
	link.href = url;
	link.download = file_name;
	link.click();
}

export default {
	download: download,
};
