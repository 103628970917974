import SearchFirebase from './Search';
import RecheckFirebase from './Recheck';

import { FIREBASE_STRUCTURE } from '../../../general/constants';

const multi_snapshot_fields_template = {
    [FIREBASE_STRUCTURE.RECHECK.MESSAGE_TYPE]: {
        json_parse: false,
        should_flatten: true
    },
    [FIREBASE_STRUCTURE.RECHECK.FIELD]: {
        json_parse: true,
        should_flatten: true
    }
};

let _search_firebase = null;
let _recheck_firebase = null;

class Manager {
    static get search() {
        if (!_search_firebase) {
            _search_firebase = new SearchFirebase(FIREBASE_STRUCTURE.SEARCH.COLLECTION, FIREBASE_STRUCTURE.SEARCH.SUB_COLLECTION, FIREBASE_STRUCTURE.SEARCH.FIELD, 'Got hotel deals from firebase');
        }

        return _search_firebase;
    }

    static get recheck() {
        if (!_recheck_firebase) {
            _recheck_firebase = new RecheckFirebase(FIREBASE_STRUCTURE.RECHECK.COLLECTION, FIREBASE_STRUCTURE.RECHECK.SUB_COLLECTION, multi_snapshot_fields_template, 'Got rechecks from firebase');
        }

        return _recheck_firebase;
    }
}

export default Manager;
