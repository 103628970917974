import { Button, Dialog } from '@mui/material';

import React from 'react';
import PropTypes from 'prop-types';
import RouterWrapper from '../../../../utils/RouterWrapper';
import SearchStore from '../../../../stores/SearchStore';
import Currencies from '../../../../utils/Currencies';
import { RESERVATION_DIALOG_VARIANT } from '../helpers/index';
import ReservationActions from '../../../../actions/ReservationActions';
import HotelActions from '../../../../actions/HotelActions';
import { withRouter } from '../../../../utils/withRouter';
import companyIds from 'arbitrip-common/theme/mapping';

class ReservationDialog extends React.Component {
	goToHotel() {
		const { hotel, navigate, deal } = this.props;
		ReservationActions.clearReservation();
		HotelActions.banDeal(hotel, deal);
		ReservationActions.resetOutOfPolicyRequestStatus();
		const search_terms = SearchStore.getSearchTerms();
		const token = SearchStore.getToken();
		RouterWrapper.goToHotelPage(navigate, hotel, token, search_terms);
	}

	getTitleText() {
		const { variant, profile } = this.props;

		switch (variant) {
			case RESERVATION_DIALOG_VARIANT.SUCCESS:
				return 'Your reservation is being processed';
			case RESERVATION_DIALOG_VARIANT.FAILED:
				return 'Something went wrong...';
			case RESERVATION_DIALOG_VARIANT.ROOM_NOT_AVAILABLE:
				return 'This room is not available';
			case RESERVATION_DIALOG_VARIANT.PENDING:
				return 'We’re Confirming Your Booking';
			case RESERVATION_DIALOG_VARIANT.PRICE_CHANGED:
				return 'Price Change Detected';
			case RESERVATION_DIALOG_VARIANT.QUOTE:
				return 'Quote was attached';
			case RESERVATION_DIALOG_VARIANT.OUT_OF_POLICY:
				const tripManagerName = (profile && profile.approver_full_name) || 'your travel manager';
				return (
					<span>
						Your request was sent to
						<br />
						{tripManagerName}
					</span>
				);
			default:
				return null;
		}
	}

	getMainMessageComponent() {
		const { variant, shortId, oldPrice, newPrice, profile, tripName } = this.props;

		switch (variant) {
			case RESERVATION_DIALOG_VARIANT.SUCCESS:
				return (
					<p className="message">
						Reservation id: <b>{shortId}</b>
						<br />
						You'll get an email once the reservation is confirmed
					</p>
				);
			case RESERVATION_DIALOG_VARIANT.OUT_OF_POLICY:
				return <p className="message">You'll get an email once your booking was approved</p>;
			case RESERVATION_DIALOG_VARIANT.QUOTE:
				return (
					<p className="message">
						Hotel booking quote was added to <b>${tripName}</b>
					</p>
				);
			case RESERVATION_DIALOG_VARIANT.FAILED:
				return (
					<p className="message">
						We couldn't book this room at the moment
						<br />
						Please go back and select another deal to book
					</p>
				);
			case RESERVATION_DIALOG_VARIANT.ROOM_NOT_AVAILABLE:
				return (
					<p className="message">
						We couldn't book this room at the moment
						<br />
						Please go back and select another deal to book
					</p>
				);
			case RESERVATION_DIALOG_VARIANT.PENDING:
				return (
					<p className="message">
						You’ll receive a confirmation email as soon as your
						<br />
						reservation is approved
					</p>
				);
			case RESERVATION_DIALOG_VARIANT.PRICE_CHANGED:
				const value = Math.round(newPrice) - Math.round(oldPrice) || 1;
				const textValue = Currencies.getPriceWithCurrency(value, profile.company_currency);
				return (
					<p className="message">
						The room price has increased by <b>{textValue}</b>
					</p>
				);
			default:
				return null;
		}
	}

	getAdditionalMessageComponent() {
		const { variant, oldPrice, newPrice, profile, isNonRefundable } = this.props;

		switch (variant) {
			case RESERVATION_DIALOG_VARIANT.SUCCESS:
			case RESERVATION_DIALOG_VARIANT.FAILED:
			case RESERVATION_DIALOG_VARIANT.QUOTE:
			case RESERVATION_DIALOG_VARIANT.ROOM_NOT_AVAILABLE:
			case RESERVATION_DIALOG_VARIANT.OUT_OF_POLICY:
				return <div className="additional-placeholder" />;
			case RESERVATION_DIALOG_VARIANT.PENDING:
				return isNonRefundable ? (
					<div className="additional-message">
						<div className="additional-message-text">
							Your room is <b>Non-Refundable</b>, so please avoid rebooking
							<br />
							this deal at this time to prevent duplicate charges.
						</div>
					</div>
				) : (
					<div className="additional-placeholder" />
				);
			case RESERVATION_DIALOG_VARIANT.PRICE_CHANGED:
				const oldPriceText = Currencies.getPriceWithCurrency(Math.round(oldPrice), profile.company_currency);
				const newPriceText = Currencies.getPriceWithCurrency(Math.round(newPrice), profile.company_currency);
				return (
					<div className="price-changed-container flex">
						<div>
							<div>Old Price</div>
							<div className="price">{oldPriceText}</div>
						</div>
						<div className="right-arrow"></div>
						<div>
							<div>New Price</div>
							<div className="price">{newPriceText}</div>
						</div>
					</div>
				);
			default:
				return null;
		}
	}

	getActionButtonsComponent() {
		const { variant, primeButtonAction } = this.props;

		switch (variant) {
			case RESERVATION_DIALOG_VARIANT.SUCCESS:
			case RESERVATION_DIALOG_VARIANT.PENDING:
			case RESERVATION_DIALOG_VARIANT.OUT_OF_POLICY:
				return (
					<Button
						className="button-primary"
						key={'close-button'}
						variant="text"
						color="primary"
						onClick={primeButtonAction}
					>
						Close
					</Button>
				);
			case RESERVATION_DIALOG_VARIANT.QUOTE:
				return (
					<Button
						className="button-primary"
						key={'close-button'}
						variant="text"
						color="primary"
						onClick={primeButtonAction}
					>
						View Trip
					</Button>
				);
			case RESERVATION_DIALOG_VARIANT.FAILED:
			case RESERVATION_DIALOG_VARIANT.ROOM_NOT_AVAILABLE:
				return (
					<Button
						className="button-primary"
						key={'close-button'}
						variant="text"
						color="primary"
						onClick={primeButtonAction}
					>
						Select another room
					</Button>
				);
			case RESERVATION_DIALOG_VARIANT.PRICE_CHANGED:
				return (
					<div className="action-buttons">
						<Button
							className="button-primary"
							key={'approve-button'}
							variant="text"
							color="primary"
							onClick={primeButtonAction}
						>
							Confirm & Continue
						</Button>
						<Button
							className="button-secondary"
							key={'close-button'}
							variant="text"
							color="primary"
							onClick={() => this.goToHotel()}
						>
							Back to hotel
						</Button>
					</div>
				);
			default:
				return null;
		}
	}

	getLongIdComponent() {
		const { variant, reservationId } = this.props;

		if (!reservationId) {
			return null;
		}

		switch (variant) {
			case RESERVATION_DIALOG_VARIANT.PRICE_CHANGED:
			case RESERVATION_DIALOG_VARIANT.PENDING:
			case RESERVATION_DIALOG_VARIANT.FAILED:
			case RESERVATION_DIALOG_VARIANT.ROOM_NOT_AVAILABLE:
				return <div className="id-text">ID: {reservationId}</div>;
			case RESERVATION_DIALOG_VARIANT.SUCCESS:
			case RESERVATION_DIALOG_VARIANT.QUOTE:
			case RESERVATION_DIALOG_VARIANT.OUT_OF_POLICY:
			default:
				return null;
		}
	}

	getFooter() {
		const { variant, profile } = this.props;
		const isLetStay = companyIds[profile.company.id] === 'LetStay';

		if (isLetStay) {
			return null;
		}

		switch (variant) {
			case RESERVATION_DIALOG_VARIANT.PENDING:
			case RESERVATION_DIALOG_VARIANT.FAILED:
				return (
					<div className="footer">
						<div className="footer-header">On any question contact us</div>
						<div className="footer-numbers flex">
							<div>US: +1 (650) 532 9354</div>
							<div>|</div>
							<div>UK: +44 (20) 3608 0404</div>
							<div>|</div>
							<div>IL: +972 (3) 738 2100</div>
						</div>
					</div>
				);
			case RESERVATION_DIALOG_VARIANT.QUOTE:
			case RESERVATION_DIALOG_VARIANT.SUCCESS:
			case RESERVATION_DIALOG_VARIANT.ROOM_NOT_AVAILABLE:
			case RESERVATION_DIALOG_VARIANT.PRICE_CHANGED:
			case RESERVATION_DIALOG_VARIANT.OUT_OF_POLICY:
			default:
				return null;
		}
	}

	render() {
		const { variant } = this.props;
		let titleClasses = 'title';
		let imageClasses = 'image_header';
		switch (variant) {
			case RESERVATION_DIALOG_VARIANT.PENDING:
				titleClasses += ' green';
				imageClasses += ' pending-icon';
				break;
			case RESERVATION_DIALOG_VARIANT.SUCCESS:
			case RESERVATION_DIALOG_VARIANT.QUOTE:
			case RESERVATION_DIALOG_VARIANT.OUT_OF_POLICY:
				titleClasses += ' green';
				imageClasses += ' confirm-icon';
				break;
			case RESERVATION_DIALOG_VARIANT.ROOM_NOT_AVAILABLE:
			case RESERVATION_DIALOG_VARIANT.FAILED:
				titleClasses += ' red';
				imageClasses += ' error-icon';
				break;
			case RESERVATION_DIALOG_VARIANT.PRICE_CHANGED:
				imageClasses += ' pricing-icon';
				break;
			default:
				break;
		}
		return (
			<Dialog
				className={'reservation-dialog-main'}
				open={this.props.modalIsOpen}
				aria-describedby="alert-dialog-description"
				fullWidth={true}
			>
				<div className="reservation-dialog-container">
					<div className={imageClasses} />
					<h3 className={titleClasses}>{this.getTitleText()}</h3>
					{this.getMainMessageComponent()}
					{this.getAdditionalMessageComponent()}
					{this.getActionButtonsComponent()}
					{this.getLongIdComponent()}
					{this.getFooter()}
				</div>
			</Dialog>
		);
	}
}

ReservationDialog.propTypes = {
	oldPrice: PropTypes.number,
	newPrice: PropTypes.number,
	shortId: PropTypes.string,
	reservationId: PropTypes.string,
	hotel: PropTypes.any.isRequired,
	variant: PropTypes.oneOf([
		RESERVATION_DIALOG_VARIANT.FAILED,
		RESERVATION_DIALOG_VARIANT.SUCCESS,
		RESERVATION_DIALOG_VARIANT.PENDING,
		RESERVATION_DIALOG_VARIANT.ROOM_NOT_AVAILABLE,
		RESERVATION_DIALOG_VARIANT.QUOTE,
		RESERVATION_DIALOG_VARIANT.OUT_OF_POLICY,
	]).isRequired,
	profile: PropTypes.object,
	primeButtonAction: PropTypes.func,
	secondaryButtonAction: PropTypes.func,
	modalIsOpen: PropTypes.bool.isRequired,
	tripName: PropTypes.string,
	isNonRefundable: PropTypes.bool,
	deal: PropTypes.any,
};

export default withRouter(ReservationDialog);
