import _ from 'lodash';
import moment from 'moment';
import { useNavigate } from 'react-router';
import React, { useEffect, useMemo, useState } from 'react';

import ProfileStore from '../../../stores/ProfileStore';
import { withRouter } from '../../../utils/withRouter';
import BookingsStore from '../../../stores/BookingsStore';
import Analytics from 'arbitrip-common/client/analytics';
import BookingsActions from '../../../actions/BookingsActions';
import RouterWrapper from '../../../utils/RouterWrapper';
import RequestsTable from './RequestsTable.react';
import RequestDrawer from './RequestDrawer.react';
import RequestModals from './RequestModals.react';
import { Snackbar } from '@mui/material';
import Constants from './const';
import ReservationConstants from '../../../constants/ReservationConstants';
import BookingsConstants from '../../../constants/BookingsConstants';
import RequestsFilterComponent from './RequestsFilterComponent.react';

import TitleManager from '../../../utils/TitleManager';
import DocumentTitle from '../../general/DocumentTitle.react';

const { APPROVAL_SNACKBAR_MESSAGES, DECLINE_SNACKBAR_MESSAGES } = Constants;
const initFilters = {
	where: '',
	traveler_name: '',
	start_date: null,
	end_date: null,
	show_archived: false,
};

const initPagination = {
	page: 0,
	rows_per_page: BookingsConstants.REQUESTS_LIMIT,
};

function getState() {
	const profile = ProfileStore.getProfile();

	return {
		pending_support_bookings: [],
		profile,
		booking_requests: BookingsStore.getRequestItems(),
		bookings_count: BookingsStore.getRequestsCount(),
		requests_status: BookingsStore.getRequestsStatus(),

		approval_status: BookingsStore.getBookingRequestApprovalStatus(),
		decline_status: BookingsStore.getBookingRequestDeclineStatus(),

		requests_page_title: TitleManager.buildDefaultPageTitle({ profile, page: 'Requests' }),
	};
}

const RequestsPage = () => {
	let prevApprovalStatus = ReservationConstants.STATUS.INITIAL;
	let prevDeclineStatus = ReservationConstants.STATUS.INITIAL;

	const navigate = useNavigate();

	const [where, setWhere] = useState(initFilters.where);
	const [filters, setFilters] = useState(initFilters);

	const [state, setState] = useState(getState());
	const [pagination, setPagination] = useState(initPagination);
	const [approvalSnackbarOpen, setApprovalSnackbarOpen] = useState(false);
	const [declineSnackbarOpen, setDeclineSnackbarOpen] = useState(false);
	const [bookingDrawer, setBookingDrawer] = useState(null);
	const [openDrawer, setOpenDrawer] = useState(false);

	const [activeRequest, setActiveRequest] = useState({});
	const [showApprovalModal, setShowApprovalModal] = useState(false);
	const [showRejectionModal, setShowRejectionModal] = useState(false);

	useEffect(() => {
		BookingsStore.addChangeListener(_onChange);
		Analytics.actions.views.bookingRequestsPage(state.profile);

		getRequests();

		Analytics.actions.views.bookingRequestsPage(state.profile);

		return () => {
			BookingsStore.removeChangeListener(_onChange);
		};
	}, []);

	useEffect(() => {
		setPagination(initPagination);
		getRequests();
	}, [filters]);

	useEffect(() => {
		getRequests();
	}, [pagination]);

	useEffect(() => {
		if (state.profile && !state.profile.approval_flow_enabled) {
			RouterWrapper.goToSearchPage(navigate);
		}
	}, [state.profile]);

	useEffect(() => {
		console.log('BOOKING REQUESTS PAGE is updating...');

		if (state.approval_status !== prevApprovalStatus) {
			setApprovalSnackbarOpen(true);
		}

		if (state.decline_status !== prevDeclineStatus) {
			setDeclineSnackbarOpen(true);
		}

		// Save the current state to be used in the next render
		prevApprovalStatus = state.approval_status;
		prevDeclineStatus = state.decline_status;
	}, [state.approval_status, state.decline_status]);

	const _onChange = () => {
		setState(getState());
	};

	const getActivePagination = () => ({
		page: pagination.page > 0 ? pagination.page : 0,
		rows_per_page: pagination.rows_per_page > 0 ? pagination.rows_per_page : BookingsConstants.BOOKINGS_LIMIT,
	});

	const getActiveSkipLimit = () => {
		const active_pagination = getActivePagination();

		const limit =
			active_pagination.rows_per_page > 0 ? active_pagination.rows_per_page : BookingsConstants.BOOKINGS_LIMIT;

		return {
			skip: active_pagination.page > 0 ? active_pagination.page * limit : 0,

			limit,
		};
	};

	const getRequests = () => {
		const { skip, limit } = getActiveSkipLimit();
		// const { order_by, order } = getActiveSort();
		BookingsActions.getRequestsDebounced(skip, limit, getActiveFilters());
		// sendFiltersAnalytics();
		setBookingDrawer(null);
	};

	const getActiveFilters = () => {
		return {
			...(!_.isEmpty(filters.where) && { destination_hotel_name: filters.where }),
			...(!_.isEmpty(filters.traveler_name) && { traveler_name: filters.traveler_name }),
			...(filters.show_archived && { show_archived: filters.show_archived }),
			...(moment.isMoment(filters.start_date) && { after_date: moment(filters.start_date).format('YYYY-MM-DD') }),
			...(moment.isMoment(filters.end_date) && { before_date: moment(filters.end_date).format('YYYY-MM-DD') }),
		};
	};

	const openBookingDrawer = (booking) => {
		if (booking.reservation.id === _.get(bookingDrawer, 'reservation.id')) {
			// if row is already open, close it
			bookingDrawerOnClose();
		} else {
			setOpenDrawer(true);
			setBookingDrawer(booking);

			Analytics.actions.interactions.openedBookingsSidepanel(
				booking?.reservation?.id,
				booking?.request_ticket?.id,
			);
		}
	};

	const bookingDrawerOnClose = () => {
		setOpenDrawer(false);
		setTimeout(() => {
			setBookingDrawer(false);
		}, 400);
	};

	const getLoader = () => {
		return (
			state.requests_status === BookingsConstants.STATUS.BUSY && (
				<div className="bookings-loading">
					<i title="Getting bookings.." className="fa fa-spin fa-spinner fa-1x" />
				</div>
			)
		);
	};

	const handleDeleteTag = (key) => {
		if (key === 'where') {
			setWhere(initFilters.where);
		}

		// remove tag and set to original data
		setFilters({ ...filters, [key]: initFilters[key] });

		setPagination(initPagination);
	};

	return (
		<DocumentTitle title={state.requests_page_title}>
			<div className="new-bookings-page requests-page">
				<RequestsFilterComponent
					profile={state.profile}
					initFilters={initFilters}
					filters={filters}
					setFilters={setFilters}
					where={where}
					setWhere={setWhere}
					handleDeleteTag={handleDeleteTag}
					getActiveFilters={getActiveFilters}
					initPagination={initPagination}
					setPagination={setPagination}
				/>

				{getLoader()}

				<RequestsTable
					profile={state.profile}
					bookings={state.booking_requests}
					bookingsCount={state.bookings_count}
					selectedBooking={bookingDrawer}
					openDrawer={openBookingDrawer}
					status={state.requests_status}
					handleApprove={(item) => {
						setActiveRequest(item);
						setShowApprovalModal(true);
					}}
					handleDecline={(item) => {
						setActiveRequest(item);
						setShowRejectionModal(true);
					}}
					filters={filters}
					pagination={pagination}
					setPagination={setPagination}
				/>

				<RequestDrawer
					open={openDrawer}
					onClose={bookingDrawerOnClose}
					booking={bookingDrawer}
					profile={state.profile}
					handleApprove={(item) => {
						setActiveRequest(item);
						setShowApprovalModal(true);
					}}
					handleDecline={(item) => {
						setActiveRequest(item);
						setShowRejectionModal(true);
					}}
				/>

				<RequestModals
					activeRequest={activeRequest}
					setActiveRequest={setActiveRequest}
					showApprovalModal={showApprovalModal}
					showRejectionModal={showRejectionModal}
					setShowApprovalModal={setShowApprovalModal}
					setShowRejectionModal={setShowRejectionModal}
				/>

				<Snackbar
					open={approvalSnackbarOpen}
					message={APPROVAL_SNACKBAR_MESSAGES[state.approval_status] || ''}
					autoHideDuration={4000}
					onClose={() => setApprovalSnackbarOpen(false)}
				/>
				<Snackbar
					open={declineSnackbarOpen}
					message={DECLINE_SNACKBAR_MESSAGES[state.decline_status] || ''}
					autoHideDuration={4000}
					onClose={() => setDeclineSnackbarOpen(false)}
				/>
			</div>
		</DocumentTitle>
	);
};

export default withRouter(RequestsPage);
