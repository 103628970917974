import keyMirror from 'keymirror';
import _ from 'lodash';

const POINTS_CONSTANTS = {
	STATUS: {
		INITIAL: 'initial',
		BUSY: 'busy',
		SUCCESS: 'success',
		FAILED: 'failed',
	},
};

export default _.extend(
	{},
	POINTS_CONSTANTS,
	keyMirror({
		SAVE_CHOSEN_USER_POINTS: null,
		SAVE_POINTS_DATA: null,
		DELETE_POINTS_DATA: null,

		GET_POINTS: null,
		GET_POINTS_SUCCESS: null,
		GET_POINTS_FAIL: null,

		UPDATE_POINTS_USAGE: null,
		UPDATE_POINTS_USAGE_SUCCESS: null,
		UPDATE_POINTS_USAGE_FAIL: null,
	}),
);
