import keyMirror from 'keymirror';
import _ from 'lodash';

const BASKET_CONSTANTS = {
	STATUS: {
		INITIAL: 'initial',
		BUSY: 'busy',
		SUCCESS: 'success',
		FAILED: 'failed',
	},

	BASKET_LIMIT: 10,
};
export default _.extend(
	{},
	BASKET_CONSTANTS,
	keyMirror({
		UPDATE_AGENT_PRICING_ON_BASKET_DEAL: null,
		INITIALIZE_BASKET: null,

		BASKET_CHANGED: null,

		OPEN_BASKET: null,
		CLOSE_BASKET: null,

		ADD_EMAIL: null,
		REMOVE_EMAIL: null,

		UPDATE_NAME: null,
		UPDATE_REMARKS: null,
		UPDATE_SEND_TO_COMBTAS: null,
		UPDATE_COMBTAS_ID: null,

		ADD_HOTEL_DEAL: null,
		REMOVE_HOTEL_DEAL: null,

		SEND: null,
		CLEAR_BASKET: null,
	}),
);
