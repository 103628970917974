/* eslint-disable no-useless-escape */
const empty_space =  /^\s+$|^$/gi;
const string_with_space_and_hypen =  /^[a-zA-Z\s-]+$/;
// e.g. a name: *Joel Cohen- Levi* is valid
const valid_name_format = (s) => !empty_space.test(s) && string_with_space_and_hypen.test(s);

// eslint-disable-next-line no-useless-escape
const alphanumeric = /^[a-z0-9]+$/i;

const email_regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phone_regex = /^[0-9-+\s]{8,}$/;
const reservation_short_id_regex = /^\s*[A-Z0-9]{4}-[A-Z0-9]{4}\s*$/i;
const reservation_id_regex = /^\s*[0-9a-fA-F]{24}\s*$/i;
const english_only_regex = /^[\-a-zA-Z\s]*$/;
const english_only_regex_custom = /^[a-zA-Z0-9\_\@\.\-\+\s]*$/;
const letters_with_spacing = /^[a-zA-Z\s]*$/
const english_letters_with_spacing_underscore = /^[a-zA-Z\s_]*$/;
const hebrew_letters  = /[\u0590-\u05FF]/;

const flight_number_regex = new RegExp('^([a-z][a-z]|[a-z][0-9]|[0-9][a-z])[a-z]?[0-9]{1,4}[a-z]?$', 'i');
const object_id_regex = new RegExp('^[a-f0-9]{24}$', 'i');
const search_token_regex = new RegExp('^[a-f0-9]{20}$', 'i');
const combtas_id_regex = new RegExp('^TAS[0-9]{3,6}[A-Z]$');
const travel_booster_docket_number_regex = new RegExp('^[4-5][0-9]{6}$');
const travel_booster_docket_number_basic_validation_regex = new RegExp('^[0-9]{7}$');

// Some of our emails are marked as spam. Try remove all urls from them
// This is a simple implementation which matches https://url but not www.url.com
const url_regex = new RegExp(/(?:https?|ftp):\/\/[\n\S]+/, 'gi');

// This is a more thorough implementation for a URI but more difficult and can blow up on edge cases
// See https://gist.github.com/gruber/249502 and https://stackoverflow.com/questions/6927719/url-regex-does-not-work-in-javascript
// eslint-disable-next-line no-useless-escape
// const url_regex =  /\b((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))/gi;

// this is regex consts for clearRemarks function
const tag_regex = new RegExp(/<\/?[\w\s="/.':;#-/]+>/gi);
const space_regex = new RegExp(/\s\s+/gi);
const line_breaks_regex = new RegExp(/(\r\n|\r|\n){2,}/gi);
// eslint-disable-next-line no-control-regex
const x06_regex = new RegExp(/\x06/ig);
const t_regex = new RegExp(/\t/ig);

// related to image utils
const invalid_caption_patterns = [
    new RegExp('^todo$', 'i'),
    new RegExp('^[0-9]+[.]jpg$', 'i'),
    new RegExp('^Featured Image$', 'i')
];

module.exports = {
    alphanumeric,
    email_regex,
    empty_space,
    phone_regex, 
    string_with_space_and_hypen,
    url_regex,
    valid_name_format,
    reservation_short_id_regex,
    reservation_id_regex,
    english_only_regex,
    english_only_regex_custom,
    flight_number_regex,
    object_id_regex,
    search_token_regex,
    combtas_id_regex,
    travel_booster_docket_number_regex,
    travel_booster_docket_number_basic_validation_regex,
    tag_regex,
    space_regex,
    line_breaks_regex,
    x06_regex,
    t_regex,
    invalid_caption_patterns,
    letters_with_spacing,
    english_letters_with_spacing_underscore,
    hebrew_letters,
}
