import withStyles from '@mui/styles/withStyles';

import _ from 'lodash';
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import SearchStore from '../../../stores/SearchStore';
import SearchDataWindow from './SearchDataWindow.react';
import SEARCH_CONSTANTS from 'arbitrip-common/general/constants/search-constants';

const styles = (theme) => ({
	root: {
		alignSelf: 'center',
		textAlign: 'left',
	},
	label: {
		fontSize: 15,
		color: theme.palette.gray.gray2,
		marginLeft: '2px',
	},
	contentWrap: {
		display: 'flex',
		alignItems: 'center',
		whiteSpace: 'nowrap',
		fontSize: 15,
		fontWeight: 700,
		color: theme.palette.black.main,
	},
	content: {
		margin: '0 2px',
	},
});

function getState() {
	return {
		private_travel: SearchStore.getPrivateTravel(),
	};
}

class SearchData extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			showWindow: false,
			adults: this.props.adults || SEARCH_CONSTANTS.MIN.ADULTS,
			rooms: this.props.rooms || SEARCH_CONSTANTS.MIN.ROOMS,
			children: this.props.childrenAges ? this.props.childrenAges.length : SEARCH_CONSTANTS.MIN.CHILDREN,
			childrenAges: this.props.childrenAges,
			...getState(),
		};

		this._onChange = this._onChange.bind(this);
		this.toggleWindow = this.toggleWindow.bind(this);
		this.closeWindow = this.closeWindow.bind(this);
		this.setNewCount = this.setNewCount.bind(this);
		this.updateChildrenAges = this.updateChildrenAges.bind(this);
		this.updateSingleChildrenAge = this.updateSingleChildrenAge.bind(this);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.rooms !== this.props.rooms) {
			this.setState({ rooms: this.props.rooms });
		}

		if (prevProps.adults !== this.props.adults) {
			this.setState({ adults: this.props.adults });
		}

		if (this.state.adults !== prevState.adults) {
			if (_.isFunction(this.props.onGuestsChange)) {
				this.props.onGuestsChange(this.state.adults);
			}
		}

		if (!_.isEqual(prevProps.childrenAges, this.props.childrenAges)) {
			this.setState({ children: this.props.childrenAges.length, childrenAges: this.props.childrenAges });
		}

		if (this.state.children !== prevState.children) {
			this.updateChildrenAges();
		}

		if (this.state.rooms !== prevState.rooms) {
			if (_.isFunction(this.props.onRoomsChange)) {
				this.props.onRoomsChange(this.state.rooms);
			}
		}
	}

	updateChildrenAges() {
		let { children, childrenAges } = this.state;
		let { onChildrenChange } = this.props;
		if (!childrenAges) {
			childrenAges = [];
		}

		if (children > 0 && !childrenAges[children - 1]) {
			childrenAges = this.updateSingleChildrenAge(children - 1, SEARCH_CONSTANTS.DEFAULT_CHILD_AGE);
		}

		childrenAges.forEach((age, index) => {
			if (index + 1 > children) {
				childrenAges.splice(index, 1);
			}
		});

		if (_.isFunction(onChildrenChange)) {
			onChildrenChange(childrenAges);
		}
	}

	updateSingleChildrenAge(childNumber, age) {
		let { onChildrenChange } = this.props;
		let { childrenAges } = this.state;
		if (!childrenAges) {
			childrenAges = [];
		}

		childrenAges[childNumber] = age;

		if (_.isFunction(onChildrenChange)) {
			onChildrenChange(childrenAges);
		}

		return childrenAges;
	}

	toggleWindow(forceOpenOrClose = null) {
		let { showWindow } = this.state;

		if (forceOpenOrClose) {
			showWindow = forceOpenOrClose;
			this.setState({ showWindow: showWindow });
		} else {
			showWindow = !showWindow;
			this.setState({ showWindow: showWindow });
		}

		this.props.onDataWindowToggle(showWindow);
	}

	closeWindow() {
		this.setState({ showWindow: false });
	}

	setNewCount(name, count) {
		this.setState({ [name.toLowerCase()]: count });
	}

	render() {
		let { classes, showChildrenAgesError } = this.props;
		let { showWindow, adults, children, childrenAges, rooms } = this.state;

		return (
			<div className={classes.root} id="search-data-root">
				<span
					className={classNames(classes.label, {
						[classes.label]: !this.state.showWindow,
					})}
				>
					Guests
				</span>
				<div className={classNames(classes.contentWrap, 'search-data-content-wrap')}>
					<div
						id="adults"
						className={classNames(
							classes.content,
							SEARCH_CONSTANTS.SHARED_ATTRIBUTES_NAMES.SEARCH_DATA_CONTENT,
						)}
					>
						{adults} {adults > 1 ? 'adults' : 'adult'}
					</div>
					<div>&nbsp;·&nbsp;</div>
					<div
						id="rooms"
						className={classNames(
							classes.content,
							SEARCH_CONSTANTS.SHARED_ATTRIBUTES_NAMES.SEARCH_DATA_CONTENT,
						)}
					>
						{rooms} {rooms > 1 ? 'rooms' : 'room'}
					</div>
					{children > 0 && (
						<React.Fragment>
							<div>&nbsp;·&nbsp;</div>
							<div id="children" className={classes.content}>
								{children} {children > 1 ? 'children' : 'child'}
							</div>
						</React.Fragment>
					)}
				</div>
				{showWindow && (
					<SearchDataWindow
						adults={adults}
						childrenAges={childrenAges}
						rooms={rooms}
						setNewCount={this.setNewCount}
						closeWindow={this.toggleWindow}
						updateSingleChildrenAge={this.updateSingleChildrenAge}
						showChildrenAgesError={showChildrenAgesError}
						isAgent={this.props.isAgent}
					/>
				)}
			</div>
		);
	}

	_onChange() {
		this.setState(getState());
	}

	componentDidMount() {
		SearchStore.addChangeListener(this._onChange);
	}

	componentWillUnmount() {
		SearchStore.removeChangeListener(this._onChange);
	}
}

SearchData.propTypes = {
	classes: PropTypes.object.isRequired,
	adults: PropTypes.number,
	rooms: PropTypes.number,
	childrenAges: PropTypes.array,
	onGuestsChange: PropTypes.func.isRequired,
	onRoomsChange: PropTypes.func.isRequired,
	onChildrenChange: PropTypes.func.isRequired,
	onDataWindowToggle: PropTypes.func.isRequired,
	showChildrenAgesError: PropTypes.bool.isRequired,
	isAgent: PropTypes.bool,
};

export default withStyles(styles)(SearchData);
