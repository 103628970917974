import _ from 'lodash';
import keyMirror from 'keymirror';

const PAYMENT_CONSTANTS = {
	// TODO! Move to general constants
	PAYMENT_METHODS_ANALYTICS: {
		// Backward compatible to analytics names
		CREDIT_CARD: 'Credit Card',
		COMPANY_POLICY: 'Account Payment',
		PAY_AT_HOTEL: 'Pay at Hotel',
		ARBIBANK: 'ArbiBank', // TODO! Do we use this?
		PAY_LATER: 'Pay Later',
	},
	PAYMENT_METHOD_TYPES: {
		// Make sure same as server
		NONE: 'none',
		CREDIT_CARD: 'credit_card',
		COMPANY_POLICY: 'company_policy',
		PRIVATE_CARD: 'private_credit_card',
		PAY_AT_HOTEL: 'pay_at_hotel',
		POINTS_ONLY: 'points_only',
		PAY_LATER: 'pay_later',
	},
	STATUS: {
		INITIALIZING: 'initializing',
		INITIALIZED: 'initialized',
		// BOOKED: 'booked',
		PROCESSED: 'processed',
		FAILED: 'failed',
		FAILED_PAYMENT_INITIALIZATION: 'failedPaymentInitialization',
	},
};

// Define action constants
export default _.extend(
	{},
	PAYMENT_CONSTANTS,
	keyMirror({
		RECEIVE_PAYMENT_DATA: null, // After postback
		CREDIT_PAYMENT_CHARGED: null,
		CREDIT_PAYMENT_FAILED: null,
		THREEDS_INFORMATION_DATA: null,

		CLEAR_PAYMENT_DATA: null, // TODO: BEWARE  - CLEAR PAYMENT HAS A DIFFERENT MEANING THAN OTHER ENTITIES
		PAYMENT_ERROR_MESSAGE: null,
	}),
);
