import _ from 'lodash';

class LocationService {
	getLocation(destination) {
		let lat = this.getLat(destination);
		let lng = this.getLng(destination);

		return { lat, lng };
	}

	getLat(destination) {
		let lat =
			_.get(destination, 'location.lat') ||
			_.get(destination, 'place.location.lat') ||
			_.get(destination, 'place.geometry.location.lat') ||
			_.get(destination, 'place.lat');

		if (typeof lat === 'function') {
			lat = lat();
		}

		return lat;
	}

	getLng(destination) {
		let lng =
			_.get(destination, 'location.lon') ||
			_.get(destination, 'location.lng') ||
			_.get(destination, 'place.location.log') ||
			_.get(destination, 'place.location.lng') ||
			_.get(destination, 'place.geometry.location.log') ||
			_.get(destination, 'place.geometry.location.lng') ||
			_.get(destination, 'place.lon') ||
			_.get(destination, 'place.lng');

		if (typeof lng === 'function') {
			lng = lng();
		}

		return lng;
	}
}

export default new LocationService();
