import _ from 'lodash';

import AppDispatcher from '../dispatcher/AppDispatcher';
import PointsConstants from '../constants/PointsConstants';
import Ajax from '../utils/Ajax';

const PointsActions = {
	saveChosenUserPoints: function (points) {
		AppDispatcher.handleAction({
			actionType: PointsConstants.SAVE_CHOSEN_USER_POINTS,
			data: points,
		});
	},

	getPointsIfApplicable: function (profile) {
		if (_.get(profile, 'loyalty.enabled', false)) {
			AppDispatcher.handleAction({
				actionType: PointsConstants.GET_POINTS,
			});

			Ajax.getPoints()
				.then((res) => {
					AppDispatcher.handleAction({
						actionType: PointsConstants.GET_POINTS_SUCCESS,
						data: res,
					});
				})
				.fail((err) => {
					AppDispatcher.handleAction({
						actionType: PointsConstants.GET_POINTS_FAIL,
						data: err,
					});
				});
		}
	},

	updatePointsUsage: function ({ reservation_id, points }) {
		AppDispatcher.handleAction({
			actionType: PointsConstants.UPDATE_POINTS_USAGE,
		});

		Ajax.updatePointsUsage({ reservation_id, points })
			.then((res) => {
				let res_json = res;
				try {
					res_json = JSON.parse(res);
				} catch (err) {
					console.error('updatePointsUsage ==> JSON.parse :::', err);
				}

				// makes sure store is up to date with new balance (will not break original update success)
				Ajax.getPoints()
					.then((get_res) => {
						AppDispatcher.handleAction({
							actionType: PointsConstants.GET_POINTS_SUCCESS,
							data: get_res,
						});

						AppDispatcher.handleAction({
							actionType: PointsConstants.UPDATE_POINTS_USAGE_SUCCESS,
							data: res_json,
						});
					})
					.fail((err) => {
						console.error('updatePointsUsage ==> getPoints :::', err);
						AppDispatcher.handleAction({
							actionType: PointsConstants.UPDATE_POINTS_USAGE_SUCCESS,
							data: res,
						});
					});
			})
			.fail((err) => {
				AppDispatcher.handleAction({
					actionType: PointsConstants.UPDATE_POINTS_USAGE_FAIL,
					data: err,
				});
			});
	},
};

export default PointsActions;
