import { useState, useEffect } from 'react';
import { withRouter } from '@/utils/withRouter';
import _ from 'lodash';

import ProfileStore from '@/stores/ProfileStore';

import ManagementOngoingStore from '@/stores/ManagementOngoingStore';
import ManagementOngoingActions from '@/actions/ManagementOngoingActions';
import ManagementOngoingConstants from '@/constants/ManagementOngoingConstants';

import CircularProgress from '@mui/material/CircularProgress';

import ManagementConstants from '@/constants/ManagementConstants';
import ManagementActions from '@/actions/ManagementActions';

import TitleManager from '@/utils/TitleManager';

import NoOngoingTripsMessage from './trip-table/NoOngoingTripsMessage';
import TripTableLoading from './trip-table/TripTableLoading';
import TripTable from './trip-table/TripTable';
import { TripActionButton } from './components/action_buttons';
import { ReactComponent as CloseIcon } from '@/img/icons/x-close.svg';

const TripTablePage = ({ navigate }) => {
	const [profile, setProfile] = useState(ProfileStore.getProfile());
	const [trips, setTrips] = useState(ManagementOngoingStore.getOngoingTrips());
	const [status, setStatus] = useState(ManagementOngoingConstants.STATUS.BUSY);
	const [hovered_row, setHoveredRow] = useState(null);
	let _refreshInterval_ = null;

	useEffect(() => {
		window.scrollTo(0, 0);
		const refreshIntervalId = setTimeout(() => {
			startRefreshInterval();
		}, 2500);

		ManagementOngoingStore.addChangeListener(_onChange);
		ManagementOngoingActions.getOngoingTrips();

		return () => {
			clearTimeout(refreshIntervalId);
			ManagementOngoingStore.removeChangeListener(_onChange);
			ManagementActions.clearPreview();
			stopRefreshInterval();
		};
	}, []);

	useEffect(() => {
		const buildTitle = () => {
			const firstName = _.get(profile, 'first_name', '');
			const companyName = _.get(profile, 'company.name', '');

			if (profile.travel_manager && companyName) {
				TitleManager.buildTitleStartsWith(`${companyName}'s Ongoing Trips`);
			} else if (firstName) {
				TitleManager.buildTitleStartsWith(`${firstName}'s Ongoing Trips`);
			} else {
				TitleManager.buildTitleStartsWith(`Ongoing Trips`);
			}
		};

		buildTitle();
	}, [profile]);

	const _onChange = () => {
		setProfile(ProfileStore.getProfile());
		setTrips(ManagementOngoingStore.getOngoingTrips());
		setStatus(ManagementOngoingStore.getOngoingTripsStatus());
	};

	const startRefreshInterval = () => {
		stopRefreshInterval();
		_refreshInterval_ = setInterval(() => {
			if (!document.hidden) ManagementOngoingActions.getOngoingTrips();
		}, 35000);
	};

	const stopRefreshInterval = () => {
		if (_refreshInterval_) {
			clearInterval(_refreshInterval_);
			_refreshInterval_ = null;
		}
	};

	const handleOnCellClick = (rowNumber) => {
		let record = trips[rowNumber];
		ManagementActions.updateTripRequestSource(ManagementConstants.SOURCES.ONGOING_TRIPS);
		setTimeout(() => {
			navigate('/trip/' + record.id);
		}, 0);
	};

	const handleOnRowHover = (rowNumber) => {
		let record = trips[rowNumber];
		ManagementActions.previewTrip(record);
		setHoveredRow(rowNumber);
	};

	const handleBackToTripsOnClick = () => navigate('/' + ManagementConstants.SOURCES.TRIPS);

	return (
		<div className="trip-table-page">
			<div className="trips-table-header flex space-between">
				<span className="title">All Trips</span>
				<div className="actions flex">
					{status === ManagementConstants.STATUS.BUSY && (
						<div className="flex-center">
							<CircularProgress size={24} />
						</div>
					)}
					<TripActionButton onClick={handleBackToTripsOnClick}>
						<CloseIcon />
					</TripActionButton>
				</div>
			</div>
			{status === ManagementOngoingConstants.STATUS.BUSY ? (
				<TripTableLoading />
			) : trips.length > 0 ? (
				<TripTable
					trips={trips}
					onCellClick={handleOnCellClick}
					onRowHover={handleOnRowHover}
					hoveredRow={hovered_row}
					showCurrentLocation={true}
				/>
			) : (
				<NoOngoingTripsMessage handleBackToTripsOnClick={handleBackToTripsOnClick} />
			)}
		</div>
	);
};

export default withRouter(TripTablePage);
