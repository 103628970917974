import _ from 'lodash';
import ReservationActions from '../../../../actions/ReservationActions';

function getMockName(travelerIndex) {
	switch (travelerIndex) {
		case 0:
			return 'Gustav Silver';

		case 1:
			return 'Lillian Silver';

		case 2:
			return 'Gustav Mercury';

		case 3:
			return 'Lillian Mercury';

		default:
			return 'Gustav Silver';
	}
}

function getMockContact(travelerIndex) {
	const full_name = getMockName(travelerIndex);
	const [first_name, last_name] = full_name.split(' ');
	return {
		first_name,
		last_name,
		full_name,
		email: '',
		shouldCreateUser: false,
	};
}

function autoFillTravelers({ search_terms }) {
	for (let room_number = 1; room_number <= search_terms.rooms; room_number++) {
		for (let guest_number = 0; guest_number < search_terms.guests; guest_number++) {
			const traveler_index = search_terms.guests * (room_number - 1) + guest_number;
			const contact = getMockContact(traveler_index);
			ReservationActions.addGuestTraveler(traveler_index, contact, room_number);
		}
	}
}

function autoFillSpecialRequests() {
	ReservationActions.updateSpecialRequests('test');
	ReservationActions.updateSpecialRequestsFromSuggestions({
		ARRIVING_LATE: {
			key: '1',
			text: 'Arriving late between 20:00-00:00',
		},
	});
}

function autoFillInvoice({ profile }) {
	const invoices = _.get(profile, 'company.invoice_ids') || [];
	if (!_.isEmpty(invoices)) {
		ReservationActions.updateInvoiceOption(invoices[invoices.length - 1]);
	}
}

class AutoFiller {
	autoFill({ profile = {}, search_terms = {} }) {
		autoFillTravelers({ search_terms });
		autoFillSpecialRequests();
		autoFillInvoice({ profile });
	}
}

export default new AutoFiller();
