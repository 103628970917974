const Events = require('../events/index');
const Core = require('../core');
const { getUserObject } = require('../services/userService');
const { getDestinationObject } = require('../services/destinationService');
const { getSearchTermsObject } = require('../services/searchTermsService');
const { getDealObject } = require('../services/dealService');
const { getRoomObject } = require('../services/roomService');
const { getCompanyObject } = require('../services/companyService');
const { getBookingObject } = require('../services/bookingService');

function searchPage(user) {
    const metadata = {
        ...getUserObject(user)
    };

    Core.trackEvent(Events.VIEWS.VIEWED_SEARCH_PAGE, metadata);
}

function resultsPage(user, geolocationSearch) {
    const metadata = {
        ...getUserObject(user),
        geolocationSearch,
    };

    Core.trackEvent(Events.VIEWS.VIEWED_RESULTS_PAGE, metadata, { update: true, delay: 1000 * 4 }); //force update
}

function hotelPage(user, destination, search, geolocationSearch) {
    const metadata = {
        ...getUserObject(user),
        ...getDestinationObject(destination),
        ...getSearchTermsObject(search),
        geolocationSearch
    };

    Core.trackEvent(Events.VIEWS.VIEWED_HOTEL_PAGE, metadata);
}

// price should include currency!
function reservationPage(user, destination, search, deal, geolocationSearch) {
    const metadata = {
        ...getUserObject(user),
        ...getDestinationObject(destination),
        ...getSearchTermsObject(search),
        ...getDealObject(deal),
        geolocationSearch
    };

    Core.trackEvent(Events.VIEWS.VIEWED_RESERVATION_PAGE, metadata);
}

function bookingsPage(user) {
    const metadata = {
        ...getUserObject(user)
    };

    Core.trackEvent(Events.VIEWS.VIEWED_BOOKINGS_PAGE, metadata);
}

function bookingRequestsPage(user) {
    const metadata = {
        ...getUserObject(user)
    };

    Core.trackEvent(Events.VIEWS.VIEWED_BOOKING_REQUESTS_PAGE, metadata);
}

function travelPolicyPage(user) {
    const metadata = {
        ...getUserObject(user)
    };

    Core.trackEvent(Events.VIEWS.VIEWED_TRAVEL_POLICY_PAGE, metadata);
}

function dashboardPage(user) {
    const metadata = {
        ...getUserObject(user)
    };

    Core.trackEvent(Events.VIEWS.VIEWED_DASHBOARD_PAGE, metadata);
}

function managementPage(user) {
    const metadata = {
        ...getUserObject(user)
    };

    Core.trackEvent(Events.VIEWS.VIEWED_MANAGEMENT_PAGE, metadata);
}

function tripRequestPage(user) {
    const metadata = {
        ...getUserObject(user)
    };

    Core.trackEvent(Events.VIEWS.VIEWED_TRIP_REQUEST_PAGE, metadata);
}

function roomInfoDialog(company, destination, room) {
    const metadata = {
        ...getCompanyObject(company),
        ...getDestinationObject(destination),
        ...getRoomObject(room)
    };

    Core.trackEvent(Events.VIEWS.VIEWED_ROOM_INFO_DIALOG, metadata);
}

function roomInfoDialogSidemenu(company, destination, room) {
    const metadata = {
        ...getCompanyObject(company),
        ...getDestinationObject(destination),
        ...getRoomObject(room)
    };

    Core.trackEvent(Events.VIEWS.VIEWED_ROOM_INFO_DIALOG_FROM_SIDEMENU, metadata);
}

function viewedUseOfTerms(company) {
    const metadata = {
        ...getCompanyObject(company)
    };

    Core.trackEvent(Events.VIEWS.VIEWED_TERMS_OF_USE, metadata);
}

function viewedContactPage(company) {
    const metadata = {
        ...getCompanyObject(company)
    };

    Core.trackEvent(Events.VIEWS.VIEWED_CONTACT_PAGE, metadata);
}

function viewedFAQPage(company) {
    const metadata = {
        ...getCompanyObject(company)
    };

    Core.trackEvent(Events.VIEWS.VIEWED_FAQ_PAGE, metadata);
}

function openChooseLanguage(company) {
    const metadata = {
        ...getCompanyObject(company)
    };

    Core.trackEvent(Events.VIEWS.OPEN_CHOOSE_LANGUAGE, metadata);
}

function openDestinationAutocomplete(company) {
    const metadata = {
        ...getCompanyObject(company)
    };

    Core.trackEvent(Events.VIEWS.OPEN_DESTINATION_AUTOCOMPLETE, metadata);
}

function openDisplayCurrency(company) {
    const metadata = {
        ...getCompanyObject(company)
    };

    Core.trackEvent(Events.VIEWS.OPEN_DISPLAY_CURRENCY, metadata);
}

function openDatePicker(company) {
    const metadata = {
        ...getCompanyObject(company)
    };

    Core.trackEvent(Events.VIEWS.OPEN_DATE_PICKER, metadata);
}

function openSearchDataWindow(company) {
    const metadata = {
        ...getCompanyObject(company)
    };

    Core.trackEvent(Events.VIEWS.OPEN_SEARCH_DATA_WINDOW, metadata);
}

function openBookingsFilter(company) {
    const metadata = {
        ...getCompanyObject(company)
    };

    Core.trackEvent(Events.VIEWS.OPEN_BOOKINGS_FILTER, metadata);
}

function openBookingsItem(company, destination, booking) {
    const metadata = {
        ...getCompanyObject(company),
        ...getDestinationObject(destination),
        ...getBookingObject(booking)
    };

    Core.trackEvent(Events.VIEWS.OPEN_BOOKINGS_ITEM, metadata);
}

function openDealsFilter(company) {
    const metadata = {
        ...getCompanyObject(company)
    };

    Core.trackEvent(Events.VIEWS.OPEN_DEALS_FILTER, metadata);
}

function openHotelGallery(company) {
    const metadata = {
        ...getCompanyObject(company)
    };

    Core.trackEvent(Events.VIEWS.OPEN_HOTEL_GALLERY, metadata);
}

function openContactDetails(company) {
    const metadata = {
        ...getCompanyObject(company)
    };

    Core.trackEvent(Events.VIEWS.OPEN_CONTACT_DETAILS, metadata);
}

function openTravelerDialog(company) {
    const metadata = {
        ...getCompanyObject(company)
    };

    Core.trackEvent(Events.VIEWS.OPEN_TRAVELER_DIALOG, metadata);
}

function openTermsOfUseDialog(company) {
    const metadata = {
        ...getCompanyObject(company)
    };

    Core.trackEvent(Events.VIEWS.OPEN_TERMS_OF_USE_DIALOG, metadata);
}

function openPointsOnlyPaymentDialog(company) {
    const metadata = {
        ...getCompanyObject(company)
    };

    Core.trackEvent(Events.VIEWS.OPEN_POINTS_ONLY_PAYMENT_DIALOG, metadata);
}

function openCreditCardPaymentDialog(company) {
    const metadata = {
        ...getCompanyObject(company)
    };

    Core.trackEvent(Events.VIEWS.OPEN_CREDIT_CARD_PAYMENT_DIALOG, metadata);
}

function openChangeSearchDialog(company) {
    const metadata = {
        ...getCompanyObject(company)
    };

    Core.trackEvent(Events.VIEWS.OPEN_CHANGE_SEARCH_DIALOG, metadata);
}

function openResultsFilter(company) {
    const metadata = {
        ...getCompanyObject(company)
    };

    Core.trackEvent(Events.VIEWS.OPEN_RESULTS_FILTER, metadata);
}

function openHotelsMap(company) {
    const metadata = {
        ...getCompanyObject(company)
    };

    Core.trackEvent(Events.VIEWS.OPEN_HOTELS_MAP, metadata);
}

function openCancelReservationDialog(company) {
    const metadata = {
        ...getCompanyObject(company)
    };

    Core.trackEvent(Events.VIEWS.OPEN_CANCEL_RESERVATION_DIALOG, metadata);
}

function openResultsExpiredDialog(company, page_name) {
    const metadata = {
        ...getCompanyObject(company),
        page_name
    };

    Core.trackEvent(Events.VIEWS.OPEN_RESULTS_EXPIRED_DIALOG, metadata);
}

function openNonRefundableDialog(company) {
    const metadata = {
        ...getCompanyObject(company)
    };

    Core.trackEvent(Events.VIEWS.OPEN_NON_REFUNDABLE_DIALOG, metadata);
}

function openDoubleBookingDialog(user, reservation_id) {
    const metadata = {
        ...getUserObject(user),
        reservation_id
    };

    Core.trackEvent(Events.VIEWS.OPEN_DOUBLE_BOOKING_DIALOG, metadata);
}

function openProcessingReservationDialog(company) {
    const metadata = {
        ...getCompanyObject(company)
    };

    Core.trackEvent(Events.VIEWS.OPEN_PROCESSING_RESERVATION_DIALOG, metadata);
}

function openForbiddenDestinationDialog(company) {
    const metadata = {
        ...getCompanyObject(company)
    };

    Core.trackEvent(Events.VIEWS.OPEN_FORBIDDEN_DESTINATION_DIALOG, metadata);
}

function openSendingRequestToRegionalAdminDialog(company) {
    const metadata = {
        ...getCompanyObject(company)
    };

    Core.trackEvent(Events.VIEWS.OPEN_SENDING_REQUEST_TO_REGIONAL_ADMIN_DIALOG, metadata);
}

function openRequestHadSentToRegionalAdminDialog(company) {
    const metadata = {
        ...getCompanyObject(company)
    };

    Core.trackEvent(Events.VIEWS.OPEN_REQUEST_HAD_SENT_TO_REGIONAL_ADMIN_DIALOG, metadata);
}

function openErrorWithCreditCardDialog(company) {
    const metadata = {
        ...getCompanyObject(company)
    };

    Core.trackEvent(Events.VIEWS.OPEN_ERROR_WITH_CREDIT_CARD_DIALOG, metadata);
}

function openTTLErrorDialog(company) {
    const metadata = {
        ...getCompanyObject(company)
    };

    Core.trackEvent(Events.VIEWS.OPEN_TTL_ERROR_DIALOG, metadata);
}

function openPayOnlyWithBankHapoalimCreditCardDialog(company) {
    const metadata = {
        ...getCompanyObject(company)
    };

    Core.trackEvent(Events.VIEWS.OPEN_PAY_ONLY_WITH_BANK_HAPOALIM_CREDIT_CARD_DIALOG, metadata);
}

function openCardInsufficientPointsErrorDialog(company) {
    const metadata = {
        ...getCompanyObject(company)
    };

    Core.trackEvent(Events.VIEWS.OPEN_CARD_INSUFFICIENT_POINTS_ERROR_DIALOG, metadata);
}

function openInstallmentsErrorDialog(company) {
    const metadata = {
        ...getCompanyObject(company)
    };
    
    Core.trackEvent(Events.VIEWS.OPEN_INSTALLMENTS_ERROR_DIALOG, metadata);
}

function openNotEnoughPointsDialog(company) {
    const metadata = {
        ...getCompanyObject(company)
    };

    Core.trackEvent(Events.VIEWS.OPEN_NOT_ENOUGH_POINTS_DIALOG, metadata);
}

function openSpecialRequestSuggestions(company) {
    const metadata = {
        ...getCompanyObject(company)
    };

    Core.trackEvent(Events.VIEWS.OPEN_SPESIAL_REQUEST_SUGGESTIONS_DIALOG, metadata);
}

module.exports = {
    searchPage,
    resultsPage,
    hotelPage,
    reservationPage,
    bookingsPage,
    travelPolicyPage,
    bookingRequestsPage,
    dashboardPage,
    managementPage,
    tripRequestPage,
    roomInfoDialog,
    roomInfoDialogSidemenu,
    viewedUseOfTerms,
    viewedContactPage,
    viewedFAQPage,

    openChooseLanguage,
    openDestinationAutocomplete,
    openDisplayCurrency,
    openDatePicker,
    openSearchDataWindow,
    openBookingsFilter,
    openBookingsItem,
    openDealsFilter,
    openHotelGallery,
    openContactDetails,
    openTravelerDialog,
    openTermsOfUseDialog,
    openPointsOnlyPaymentDialog,
    openCreditCardPaymentDialog,
    openChangeSearchDialog,
    openResultsFilter,
    openHotelsMap,
    openCancelReservationDialog,
    openResultsExpiredDialog,
    openNonRefundableDialog,
    openProcessingReservationDialog,
    openForbiddenDestinationDialog,
    openSendingRequestToRegionalAdminDialog,
    openRequestHadSentToRegionalAdminDialog,
    openErrorWithCreditCardDialog,
    openTTLErrorDialog,
    openPayOnlyWithBankHapoalimCreditCardDialog,
    openCardInsufficientPointsErrorDialog,
    openInstallmentsErrorDialog,
    openNotEnoughPointsDialog,
    openSpecialRequestSuggestions,
    openDoubleBookingDialog,
};
