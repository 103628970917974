import React from 'react';
import _ from 'lodash';

import HotelConstants from '../../../constants/HotelConstants';

const normalizeSnakeText = (text) => {
	return text
		.split('_')
		.map((s) => s.charAt(0).toUpperCase() + s.substring(1))
		.join(' ');
};

const ExtraInformation = ({ getHotelOrHotelInfoPropertyByKey }) => {
	const extra_information = getHotelOrHotelInfoPropertyByKey('extra_information');

	const extra_information_hotel_policies = _.get(extra_information, 'hotel_policies', []);
	const extra_information_payment_details = _.get(extra_information, 'payment_details', []);
	const extra_information_key_collection_info = _.get(extra_information, 'key_collection_info', null);

	return extra_information_hotel_policies.length ||
		extra_information_payment_details.length ||
		(extra_information_key_collection_info && !_.isEmpty(extra_information_key_collection_info)) ? (
		<div className="extra-information-container">
			{extra_information_hotel_policies.length ? (
				<div className="hotel-policies-wrapper">
					<div className="title">Hotel Policies</div>
					{extra_information_hotel_policies.map((policy, idx) => (
						<p key={`policy-${idx + 1}`}>
							<strong>{policy.name}:</strong> {policy.content}
						</p>
					))}
				</div>
			) : null}
			{extra_information_payment_details.length ? (
				<div className="payment-details-wrapper">
					<div className="title">Payment Details</div>
					{extra_information_payment_details
						.filter(
							(payment) =>
								HotelConstants.EXTRA_INFORMATION_PAYMENT_TYPE[payment.payment_id] &&
								(payment.payable || payment.bookable || payment.cvc_required),
						)
						.map((payment, idx) => (
							<p key={`payment-${idx + 1}`}>
								<strong>{HotelConstants.EXTRA_INFORMATION_PAYMENT_TYPE[payment.payment_id]}:</strong>{' '}
								{[
									payment.payable ? 'Payable at the hotel' : null,
									payment.bookable ? 'Payable directly' : null,
									payment.cvc_required ? 'CVC required' : null,
								]
									.filter(_.identity)
									.join(', ')}
							</p>
						))}
				</div>
			) : null}
			{extra_information_key_collection_info ? (
				<div className="key-collection-info-wrapper">
					<div className="title">Key Collection Info</div>
					<strong>How to collect?</strong>{' '}
					{extra_information_key_collection_info.how_to_collect === 'other'
						? extra_information_key_collection_info.other_text
						: normalizeSnakeText(extra_information_key_collection_info.how_to_collect)}
				</div>
			) : null}
		</div>
	) : null;
};

export default ExtraInformation;
