import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormControl, TextField, Button } from '@mui/material';
import { ReactComponent as ContentCopy } from '@/img/bookings/copy.svg';

const SettlePaymentCopyLink = ({ link }) => {
	const handleCopyLink = () => {
		navigator.clipboard.writeText(link);
	};

	return (
		<div className="settle-payment-copy-link">
			<div className="copy-link full-width">
				<TextField
					className="input-link"
					variant="outlined"
					value={link}
					readOnly
					sx={{
						'& .MuiInputBase-root': {
							height: '36px',
						},
					}}
				/>
				<Button
					variant="contained"
					className="generate-button copy-link-width"
					onClick={handleCopyLink}
					sx={{
						height: '36px',
					}}
				>
					<ContentCopy width={16} height={16} />
					<span style={{ marginLeft: '12px' }}>Copy</span>
				</Button>
			</div>
		</div>
	);
};

export default SettlePaymentCopyLink;
