import React from 'react';
import createClass from 'create-react-class';

import Currencies from '../../../utils/Currencies';

// Define main Controller View
var TextReport = createClass({
	displayName: 'TextReport',

	// Render our child components, passing state via props
	render: function () {
		// var infoTooltipId = "booking-" + booking.id + "-info";
		// var tooltip = (
		//   <Tooltip id={infoTooltipId} placement="top" className="sheker">
		//     <div className="info-tooltip-container">
		//       <div className="reservation-id">
		//         <div className="title">
		//           <span>
		//             Reservation ID:
		//           </span>
		//         </div>
		//         <div className="id">
		//           <span>
		//              { booking.id }
		//           </span>
		//         </div>
		//       </div>
		//       <div className="cancellation-policy">
		//         <div className="title">
		//             <span>
		//               <strong>Cancellation Policy:</strong>
		//             </span>
		//         </div>
		//         <div className="policy">
		//             <span>
		//               { booking.deal.dca.cancellationPoliciesText }
		//             </span>
		//         </div>
		//       </div>
		//       <div className="supplier-terms">
		//         <div className="title">
		//           <span>
		//             <strong>Terms of Supplier:</strong>
		//           </span>
		//         </div>
		//         <div className="terms">
		//           <span>
		//             { booking.supplierTerms || "None" }
		//           </span>
		//         </div>
		//       </div>
		//     </div>
		//   </Tooltip>
		// );
		//
		// var INFO_ICON =
		//   <OverlayTrigger placement="top" overlay={tooltip} trigger="click" rootClose={true}>
		//     <div className="info"></div>
		//   </OverlayTrigger>;
		//
		// var breakfastTooltipId = "booking-" + booking.id + "-breakfast";
		// var breakfastTooltip = (
		//   <Tooltip id={breakfastTooltipId} placement="top" className="sheker">
		//     <div className="breakfast-tooltip-container">
		//       <span>
		//         Breakfast Included
		//       </span>
		//     </div>
		//   </Tooltip>
		// );
		//
		// var BREAKFAST_ICON = booking.deal.dca.heuristicOptions.includesBreakfast
		//     ? <OverlayTrigger placement="top" overlay={breakfastTooltip} trigger={['hover', 'focus']} rootClose={true}>
		//         <div className="icon-at-breakfast-o"></div>
		//       </OverlayTrigger>
		//     : null;

		var reportValue = this.props.reportValue;

		var formattedValue =
			reportValue && !isNaN(parseFloat(reportValue)) ? parseFloat(reportValue.toFixed(1)) : reportValue;

		if (this.props.currency) {
			formattedValue = Currencies.getSymbol(this.props.currency) + formattedValue.toLocaleString();
		} else if (formattedValue.toLocaleString) {
			formattedValue = formattedValue.toLocaleString();
		}

		var REPORT_DATA = (
			<div className="text-report-data">
				<div className="text-report-value">
					<span>{formattedValue}</span>
				</div>
				<div className="text-report-unit">
					<span>{this.props.reportUnit}</span>
				</div>
			</div>
		);

		var DESCRIPTION_CONTENT = [];
		var lines = this.props.reportDescriptionLines;
		var keyBase = lines.join(' ').replace(' ', '-').toLowerCase();
		for (var i = 0; i < lines.length; i++) {
			DESCRIPTION_CONTENT.push(
				<span key={keyBase + '-' + (i + 1)} className="report-description-line">
					{lines[i]}
				</span>,
			);
		}

		var REPORT_DESCRIPTION = <div className="text-report-description">{DESCRIPTION_CONTENT}</div>;

		return (
			<div className="text-report">
				{this.props.graphTextReport ? REPORT_DATA : REPORT_DESCRIPTION}
				{this.props.graphTextReport ? REPORT_DESCRIPTION : REPORT_DATA}
			</div>
		);
	},

	// Method to setState based upon Store changes
	_onChange: function () {
		// this.setState(getBookingEntryState());
	},
});

export default TextReport;
