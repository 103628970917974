import IconButton from '@mui/material/IconButton';

import {
	FirstPage as FirstPageIcon,
	KeyboardArrowLeft,
	KeyboardArrowRight,
	LastPage as LastPageIcon,
} from '@mui/icons-material';

import React from 'react';
import PropTypes from 'prop-types';

class TablePaginationActions extends React.Component {
	handleFirstPageButtonClick = (event) => {
		this.props.onPageChange(event, 0);
	};

	handleBackButtonClick = (event) => {
		this.props.onPageChange(event, this.props.page - 1);
	};

	handleNextButtonClick = (event) => {
		this.props.onPageChange(event, this.props.page + 1);
	};

	handleLastPageButtonClick = (event) => {
		this.props.onPageChange(event, Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1));
	};

	render() {
		const { classes, count, page, rowsPerPage, theme } = this.props;

		return (
			<div className={classes.root}>
				<IconButton
					onClick={this.handleFirstPageButtonClick}
					disabled={page === 0}
					aria-label="First Page"
					size="large"
				>
					{theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
				</IconButton>
				<IconButton
					onClick={this.handleBackButtonClick}
					disabled={page === 0}
					aria-label="Previous Page"
					size="large"
				>
					{theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
				</IconButton>
				<IconButton
					onClick={this.handleNextButtonClick}
					disabled={page >= Math.ceil(count / rowsPerPage) - 1}
					aria-label="Next Page"
					size="large"
				>
					{theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
				</IconButton>
				<IconButton
					onClick={this.handleLastPageButtonClick}
					disabled={page >= Math.ceil(count / rowsPerPage) - 1}
					aria-label="Last Page"
					size="large"
				>
					{theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
				</IconButton>
			</div>
		);
	}
}

TablePaginationActions.propTypes = {
	classes: PropTypes.object.isRequired,
	count: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
	theme: PropTypes.object.isRequired,
};

export default TablePaginationActions;
