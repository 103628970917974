import _ from 'lodash';
import classNames from 'classnames';

import AnalyticsInteractions from 'arbitrip-common/client/analytics/actions/interactions';

import React from 'react';
import PropTypes from 'prop-types';
import ProviderRoomsBookmark from './ProviderRoomsBookmark.react';

import Config from 'arbitrip-common/client/utils/Config';

class RoomsCatalog extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			open: false,
			room: null,
		};

		this.showRoom = this.showRoom.bind(this);
	}

	showRoom(room, room_index) {
		let { onOpen, hotel } = this.props;

		if (_.isFunction(onOpen)) {
			onOpen(room);
		}

		AnalyticsInteractions.selectedRoomFromCatalog(hotel, room, room_index);
	}

	getRoomPrice(hotel, room) {
		if (!hotel || !hotel.deals) {
			return;
		}

		let deal = _.find(hotel.deals, (d) => _.get(d, 'room_id') === room.id);
		if (deal) {
			return (
				<span>
					{' '}
					| ${deal.pricePerNight} ${deal.currency}
				</span>
			);
		}
	}

	render() {
		const { profile, hotel, mappingDebugMode, searchSession, rooms } = this.props;

		const unified_catalog = _.get(searchSession, 'room_mapping.unified_catalog');
		const display_debug = Config.debug_mode || profile.is_arbi_support;

		return (
			<div className={classNames('inner-container rooms-catalog-container', { fixed: mappingDebugMode })}>
				<ProviderRoomsBookmark hotel={hotel} display_debugging={display_debug} />
				<div className="title">Hotel Rooms</div>
				<ul>
					{rooms.map((room, index) => {
						return room.name ? (
							<li key={`room-${room.id}-${index}`}>
								<button onClick={() => this.showRoom(room, index + 1)}>
									<span className="room">{room.name}</span>
									{mappingDebugMode && this.getRoomPrice(hotel, room)}
								</button>
							</li>
						) : null;
					})}
					{unified_catalog &&
						Object.entries(unified_catalog).map((item, index) => {
							const roomExist = _.find(rooms, (r) => r.id === item[0]);
							if (!roomExist) {
								return (
									<li key={`room-${item[0]}-${index}`}>
										<span className="">
											{item[1].name} ({item[1].catalog})
										</span>
									</li>
								);
							}

							return null;
						})}
				</ul>
			</div>
		);
	}
}

RoomsCatalog.propTypes = {
	onOpen: PropTypes.func,
	hotel: PropTypes.object,
	mappingDebugMode: PropTypes.bool,
	searchSession: PropTypes.object,
	rooms: PropTypes.array,
};

export default RoomsCatalog;
