import TravelAgentConstants from '../constants/TravelAgentConstants';

import AppDispatcher from '../dispatcher/AppDispatcher';
import { EventEmitter } from 'events';
import _ from 'lodash';

var _margin = null;
var _fixed_fee = null;
var _contract_id = null;

var _get_contract_status = TravelAgentConstants.STATUS.INITIAL;
var _update_contract_status = TravelAgentConstants.STATUS.INITIAL;

function patchMarginForDemo() {
	if (!isNaN(_margin)) {
		_margin = Math.round(100 * (_margin * 100 - 100)) / 100;
	}
}

function loadContractData(data) {
	_margin =
		data && data.contract && data.contract.pricing_config
			? data.contract.pricing_config.margin_percent_fixed
			: null;
	_fixed_fee =
		data && data.contract && data.contract.pricing_config
			? data.contract.pricing_config.margin_addition_fixed
			: null;
	_contract_id = data && data.contract ? data.contract._id : null;
}

var _travel_agent_settings_modal_open = false;

var TravelAgentStore = _.extend({}, EventEmitter.prototype, {
	getGetContractStatus: function () {
		return _get_contract_status;
	},

	getUpdateContractStatus: function () {
		return _update_contract_status;
	},

	getTravelAgentSettingsModalOpen: function () {
		return _travel_agent_settings_modal_open;
	},

	getMargin: function () {
		return _margin;
	},

	getFixedFee: function () {
		return _fixed_fee;
	},

	getContractId: function () {
		return _contract_id;
	},

	// Emit Change event
	emitChange: function () {
		this.emit('change');
	},

	// Add change listener
	addChangeListener: function (callback) {
		this.on('change', callback);
	},

	// Remove change listener
	removeChangeListener: function (callback) {
		this.removeListener('change', callback);
	},
});

AppDispatcher.register(function (payload) {
	var action = payload.action;

	switch (action.actionType) {
		case TravelAgentConstants.OPEN_TRAVEL_AGENT_SETTINGS_MODAL:
			_travel_agent_settings_modal_open = true;
			break;

		case TravelAgentConstants.CLOSE_TRAVEL_AGENT_SETTINGS_MODAL:
			_travel_agent_settings_modal_open = false;
			break;

		case TravelAgentConstants.SEND_BOOKING_CONFIRMATION:
			break;

		case TravelAgentConstants.SEND_BOOKING_CONFIRMATION_SUCCESS:
			break;

		case TravelAgentConstants.SEND_BOOKING_CONFIRMATION_FAILED:
			break;

		case TravelAgentConstants.GET_COMPANIES:
			break;

		case TravelAgentConstants.GET_COMPANIES_SUCCESS:
			break;

		case TravelAgentConstants.GET_COMPANIES_FAILED:
			break;

		case TravelAgentConstants.GET_COMPANY_CONTRACT:
			_get_contract_status = TravelAgentConstants.STATUS.BUSY;
			break;

		case TravelAgentConstants.GET_COMPANY_CONTRACT_SUCCESS:
			loadContractData(action.data);
			patchMarginForDemo();
			_get_contract_status = TravelAgentConstants.STATUS.SUCCESS;
			break;

		case TravelAgentConstants.GET_COMPANY_CONTRACT_FAILED:
			_get_contract_status = TravelAgentConstants.STATUS.FAILED;
			break;

		case TravelAgentConstants.UPDATE_COMPANY_CONTRACT:
			_update_contract_status = TravelAgentConstants.STATUS.BUSY;
			break;

		case TravelAgentConstants.UPDATE_COMPANY_CONTRACT_SUCCESS:
			_update_contract_status = TravelAgentConstants.STATUS.SUCCESS;
			_margin = action.data.contract.margin;
			_fixed_fee = action.data.contract.fixed_fee;
			break;

		case TravelAgentConstants.UPDATE_COMPANY_CONTRACT_FAILED:
			_update_contract_status = TravelAgentConstants.STATUS.FAILED;
			break;

		default:
			return true;
	}

	TravelAgentStore.emitChange();

	return true;
});

export default TravelAgentStore;
