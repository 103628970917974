import Ajax from '../utils/Ajax';
import AppDispatcher from '../dispatcher/AppDispatcher';
import PaymentLinkConstants from '../constants/PaymentLinkConstants';
import BookingsConstants from '../constants/BookingsConstants';

export default {
	generatePaymentLink: function (payload) {
		AppDispatcher.handleAction({
			actionType: PaymentLinkConstants.PAYMENT_LINK_LOADING,
		});

		Ajax.generatePaymentLink(payload)
			.done((payment_link) => {
				console.log('Got payment link:', payment_link);
				const { token } = payment_link;
				if (!token) {
					throw new Error('Token not found on response');
				}

				AppDispatcher.handleAction({
					actionType: BookingsConstants.UPDATE_PAYMENT_LINK,
					data: {
						booking_id: payload.booking_id,
						payment_link,
					},
				});

				AppDispatcher.handleAction({
					actionType: PaymentLinkConstants.PAYMENT_LINK_SUCCESS,
				});
			})
			.fail((err) => {
				console.error('Error while getting payment link:', err);
				AppDispatcher.handleAction({
					actionType: PaymentLinkConstants.PAYMENT_LINK_FAILED,
				});
			});
	},

	resetPaymentLink: function () {
		AppDispatcher.handleAction({
			actionType: PaymentLinkConstants.PAYMENT_LINK_RESET,
		});
	},
};
