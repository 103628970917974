import React from 'react';

import FiltersActions from '../../../../actions/FiltersActions';

import FiltersConstants from '../../../../constants/FiltersConstants';
import Analytics from 'arbitrip-common/client/analytics';

import FilterCheckboxWithLabel from './components/FilterCheckboxWithLabel.react';

class RegionsFilter extends React.Component {
	constructor(props) {
		super(props);

		this.handleCheckboxChangeByRegion = this.handleCheckboxChangeByRegion.bind(this);
		this.handleRegionOnlyClick = this.handleRegionOnlyClick.bind(this);
		this.handleResetRegionsClick = this.handleResetRegionsClick.bind(this);
	}

	handleCheckboxChangeByRegion = (event, isInputChecked, region) => {
		const { profile } = this.props;

		event.persist();
		FiltersActions.filterByRegion(region, isInputChecked);
		setTimeout(() => {
			FiltersActions.filterByRegion(region, isInputChecked);
		}, 10);

		const filter_value = (isInputChecked ? 'Filter by "' : 'Don\'t filter by"') + region + '"';
		Analytics.actions.interactions.filteredResults(profile, FiltersConstants.FILTER_METHOD.REGION, filter_value);
	};

	handleRegionOnlyClick = (event) => {
		const { profile } = this.props;

		event.persist();
		let region_only = event.target.getAttribute('data-region');
		FiltersActions.filterByRegionOnly(region_only);
		setTimeout(() => {
			FiltersActions.filterByRegionOnly(region_only);
		}, 10);

		const filter_value = 'Show Only "' + region_only + '" Region';
		Analytics.actions.interactions.filteredResults(
			profile,
			FiltersConstants.FILTER_METHOD.REGION_ONLY,
			filter_value,
		);
	};

	handleResetRegionsClick = (event) => {
		const { profile } = this.props;

		event.persist();
		FiltersActions.resetRegionsFilter();
		setTimeout(FiltersActions.resetRegionsFilter, 10);

		const filter_value = 'Reset Regions Filter';
		Analytics.actions.interactions.filteredResults(
			profile,
			FiltersConstants.FILTER_METHOD.RESET_REGIONS_FILTER,
			filter_value,
		);
	};

	render() {
		const data = this.props.filter;
		const multiple_regions = Object.keys(data).length > 1;

		const REGIONS = [];
		for (let region in data) {
			const region_key = region.toLowerCase().replace(' ', '-');
			REGIONS.push(
				<div className="checkbox-container" key={region_key}>
					<FilterCheckboxWithLabel
						checked={data[region]}
						category={region}
						label={region}
						onChange={(e, c, region) => this.handleCheckboxChangeByRegion(e, c, region)}
					/>
					{multiple_regions ? (
						<button className="btn btn-link" data-region={region} onClick={this.handleRegionOnlyClick}>
							Only
						</button>
					) : null}
				</div>,
			);
		}

		return (
			<div className="filter disabled">
				<label className="filter-title" htmlFor="regions-filter">
					Regions
				</label>
				<div name="regions-filter" className="check-list">
					{REGIONS}
				</div>
				{REGIONS.length > 1 ? <div className="clear-button" onClick={this.handleResetRegionsClick} /> : null}
				<hr />
				<div
					style={{
						textAlign: 'center',
					}}
				>
					<a href="#!" onClick={this.props.drawPolygons}>
						Draw Polygons
					</a>
				</div>
				<hr />
				<div
					style={{
						textAlign: 'center',
						paddingTop: 8,
					}}
				>
					<a href="#!" onClick={this.props.drawHeatmap}>
						Draw Heatmap
					</a>
				</div>
			</div>
		);
	}
}

export default RegionsFilter;
