import BasketConstants from '../constants/BasketConstants';

import BasketEntity from 'arbitrip-common/client/entities/Basket';
import BasketDeal from 'arbitrip-common/client/entities/BasketDeal';

import WebStorageManager from 'arbitrip-common/client/utils/WebStorageManager';

import AppDispatcher from '../dispatcher/AppDispatcher';
import { EventEmitter } from 'events';
import RecipientEntity from 'arbitrip-common/client/entities/BasketRecipient';
import _ from 'lodash';
import BasketActions from '../actions/BasketActions';
import SearchStore from './SearchStore';
import ClientPriceService from 'arbitrip-common/client/utils/ClientPriceService';

let _basket;
let _send_basket_status;
let _basket_open;
let _basket_key;

function clearBasket() {
	_basket = new BasketEntity({});
	_send_basket_status = BasketConstants.STATUS.INIT;
	_basket_open = false;
	// WebStorageManager.removeFromWebStorage(_basket_key);
	WebStorageManager.updateInWebStorage(_basket_key, _basket);
}

clearBasket();

function updateAgentPricing({ deal, agent_pricing }) {
	if (_basket && Array.isArray(_basket.deals)) {
		const basket_deal = _basket.deals.find((d) => d.id === deal.id);
		if (basket_deal) {
			basket_deal.agent_pricing = agent_pricing;
		}
	}
}

function initializeBasket(profile) {
	// _basket_key = profile.id + '-basket';
	_basket_key = 'basket-0.1-' + profile.id;
	let basket = WebStorageManager.getFromWebStorage(_basket_key);
	if (basket) {
		_basket = new BasketEntity(basket);

		const company_id = _.get(profile, 'company.id');
		const contracts = _.get(profile, 'contracts');
		if (_.isArray(contracts)) {
			const self_contract = contracts.find((contract) => _.get(contract, 'company._id') === company_id);
			if (self_contract) {
				for (let deal of _basket.deals) {
					if (self_contract && deal) {
						deal.agent_pricing = ClientPriceService.getAgentPricingBySelfContract(deal, self_contract);
					}
				}
			}
		}
	} else {
		// WebStorageManager.addToWebStorage(_basket_key, _basket);
	}
}

const BasketStore = _.extend({}, EventEmitter.prototype, {
	getSendBasketStatus: function () {
		return _send_basket_status;
	},

	getBasket: function () {
		return _basket;
	},

	getBasketOpen: function () {
		return _basket_open;
	},

	getDealCount: function () {
		return _basket.deals.length;
	},

	emitChange: function () {
		this.emit('change');
	},

	addChangeListener: function (callback) {
		this.on('change', callback);
	},

	removeChangeListener: function (callback) {
		this.removeListener('change', callback);
	},
});

// document.addEventListener('visibilitychange', (e) => {
window.addEventListener('storage', (e) => {
	BasketActions.basketChanged();
});

const delay_millis = 555;
let delay = (function () {
	let timer = 0;

	return function (callback, millis) {
		clearTimeout(timer);
		timer = setTimeout(callback, millis);
	};
})();

function delayedUpdate() {
	delay(function () {
		WebStorageManager.updateInWebStorage(_basket_key, _basket);
	}, delay_millis);
}

AppDispatcher.register(function (payload) {
	const action = payload.action;

	switch (action.actionType) {
		case BasketConstants.UPDATE_AGENT_PRICING_ON_BASKET_DEAL:
			updateAgentPricing(action.data);
			delayedUpdate();
			break;

		case BasketConstants.INITIALIZE_BASKET:
			initializeBasket(action.data);
			break;

		case BasketConstants.BASKET_CHANGED:
			if (_basket_key) {
				let storage_basket = WebStorageManager.getFromWebStorage(_basket_key);
				if (storage_basket) {
					_basket = storage_basket;
				}
			}
			break;

		case BasketConstants.OPEN_BASKET:
			_basket_open = true;
			break;

		case BasketConstants.CLOSE_BASKET:
			_basket_open = false;
			break;

		case BasketConstants.ADD_EMAIL:
			if (!_basket.recipients.hasOwnProperty(action.data)) {
				_basket.recipients[action.data] = new RecipientEntity(action.data);
				WebStorageManager.updateInWebStorage(_basket_key, _basket);
			}
			break;

		case BasketConstants.REMOVE_EMAIL:
			if (_basket.recipients.hasOwnProperty(action.data)) {
				delete _basket.recipients[action.data];
				WebStorageManager.updateInWebStorage(_basket_key, _basket);
			}
			break;

		case BasketConstants.UPDATE_REMARKS:
			_basket.remarks = action.data;
			delayedUpdate();
			break;

		case BasketConstants.UPDATE_NAME:
			_basket.name = action.data;
			delayedUpdate();

			break;

		case BasketConstants.UPDATE_SEND_TO_COMBTAS:
			_basket.send_to_combtas = action.data;
			delayedUpdate();
			break;

		case BasketConstants.UPDATE_COMBTAS_ID:
			_basket.combtas_id = action.data;
			delayedUpdate();
			break;

		case BasketConstants.ADD_HOTEL_DEAL:
			if (_basket.deals.length < BasketConstants.BASKET_LIMIT) {
				let { hotel, deal, travel_policy_exists, profile, contract_client_company_id } = action.data;
				const change_client_price_enabled = ClientPriceService.isEnabled(
					profile,
					contract_client_company_id,
					deal,
				);
				let basket_deal = new BasketDeal(
					deal,
					hotel,
					SearchStore.getSearchTerms(),
					travel_policy_exists,
					change_client_price_enabled,
					contract_client_company_id,
				);
				if (travel_policy_exists) {
					basket_deal.travel_policy_exists = travel_policy_exists;
				}
				if (profile) {
					// TODO: move to service and re-use
					const company_id = _.get(profile, 'company.id');
					const contracts = _.get(profile, 'contracts');
					if (_.isArray(contracts)) {
						const self_contract = contracts.find(
							(contract) => _.get(contract, 'company._id') === company_id,
						);
						if (self_contract) {
							basket_deal.agent_pricing = ClientPriceService.getAgentPricingBySelfContract(
								basket_deal,
								self_contract,
							);
						}
					}
				}
				_basket.deals.push(basket_deal);
				WebStorageManager.updateInWebStorage(_basket_key, _basket);
			}
			break;

		case BasketConstants.REMOVE_HOTEL_DEAL:
			for (let i = 0; i < _basket.deals.length; i++) {
				if (_basket.deals[i].id === action.data.id) {
					_basket.deals.splice(i, 1);
					WebStorageManager.updateInWebStorage(_basket_key, _basket);
					break;
				}
			}
			break;

		case BasketConstants.CLEAR_BASKET:
			clearBasket();
			break;

		case BasketConstants.SEND:
			_send_basket_status = action.data.status;

			if (_send_basket_status === BasketConstants.STATUS.SUCCESS) {
				clearBasket();
			}
			break;

		default:
			return true;
	}

	BasketStore.emitChange();

	return true;
});

export default BasketStore;
