import ContentLoader from 'react-content-loader';

import { Table, TableRow, TableCell, TableBody, TableContainer } from '@mui/material';

const TripTableLoading = () => (
	<TableContainer>
		<Table className="trip-table">
			<TableBody>
				{[...Array(15)].map((_, index) => (
					<TableRow key={index + 1} className="trip-table-row">
						<TableCell className="traveler-column">
							<ContentLoader speed={1} width={200} height={5}>
								<circle cx="0" cy="0" r="2" />
								<rect x="10" y="0" height="4" rx="4" ry="4" width="100" />
							</ContentLoader>
						</TableCell>
						<TableCell className="name-column">
							<ContentLoader speed={1} width={200} height={5}>
								<rect x="0" y="0" height="4" rx="4" ry="4" width="250" />
							</ContentLoader>
						</TableCell>
						<TableCell className="destination-column">
							<ContentLoader speed={1} width={200} height={5}>
								<rect x="0" y="0" height="4" rx="4" ry="4" width="300" />
							</ContentLoader>
						</TableCell>
						<TableCell className="date-column">
							<ContentLoader speed={1} width={200} height={5}>
								<rect x="0" y="0" height="4" rx="4" ry="4" width="150" />
							</ContentLoader>
						</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	</TableContainer>
);

export default TripTableLoading;
