import AppGridConstants from '../constants/AppGridConstants';
import AppDispatcher from '../dispatcher/AppDispatcher';

import { EventEmitter } from 'events';
import _ from 'lodash';

var STATUS = Object.values(AppGridConstants.STATUSES);
// Define initial data points
var _items = {};

var _appStatus = AppGridConstants.STATUSES.IDLE;

// var _company = {
//   name: null,
//   logo: null,
//   users: null // array
// };

// var _user = {
//   name: null
// };

// Method to load result data from mock API
function loadAppGridData(data) {
	_items = data;
}

// function loadUserData(data) {
//   _user = data;
// }

// function loadCompanyData(data) {
//   _company = data;
// }

function setAppStatus(data) {
	if (STATUS.indexOf(data) > -1) _appStatus = data;
}

// Extend Search Store with EventEmitter to add eventing capabilities
var AppGridStore = _.extend({}, EventEmitter.prototype, {
	getAppStatus: function () {
		return '' + _appStatus;
	},

	isIdle: function () {
		return _appStatus === AppGridConstants.STATUSES.IDLE;
	},

	isPending: function () {
		return _appStatus === AppGridConstants.STATUSES.PENDING;
	},

	isParsing: function () {
		return _appStatus === AppGridConstants.STATUSES.PARSING;
	},

	// Return search results
	getSearchResults: function () {
		return _items;
	},

	// Return # of results in search
	getItemCount: function () {
		return Object.keys(_items).length;
	},

	// Emit Change event
	emitChange: function () {
		this.emit('change');
	},

	// Add change listener
	addChangeListener: function (callback) {
		this.on('change', callback);
	},

	// Remove change listener
	removeChangeListener: function (callback) {
		this.removeListener('change', callback);
	},
});

// Register callback with AppDispatcher
AppDispatcher.register(function (payload) {
	var action = payload.action;
	//var text;

	switch (action.actionType) {
		// Respond to RECEIVE_DATA action
		case AppGridConstants.RECEIVE_APP_GRID_DATA:
			loadAppGridData(action.data);
			break;

		// case AppGridConstants.RECEIVE_USER_DATA:
		//   loadUserData(action.data);
		//   break;

		// case AppGridConstants.RECEIVE_COMPANY_DATA:
		//   loadCompanyData(action.data);
		//   break;
		case AppGridConstants.SET_GLOBAL_APP_STATUS:
			setAppStatus(action.data);
			break;

		default:
			return true;
	}

	// If action was responded to, emit change event
	AppGridStore.emitChange();

	return true;
});

EventEmitter.defaultMaxListeners = 22;

export default AppGridStore;
