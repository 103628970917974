import React from 'react';
import PropTypes from 'prop-types';

import Select from 'react-select';
import Highlighter from 'react-highlight-words';
import ContentLoader from 'react-content-loader';

import withStyles from '@mui/styles/withStyles';

import { Typography, TextField, Paper, MenuItem, ListItemIcon } from '@mui/material';

import Ajax from '../../utils/Ajax';

import { Map as MapIcon, HourglassEmpty as HourglassEmptyIcon } from '@mui/icons-material';

import classNames from 'classnames';
import moment from 'moment';

import _ from 'lodash';
import { getPointOfInterest } from 'arbitrip-common/client/utils/PlacesWrapper';
import RecentSearchesBoxStore from '../../stores/RecentSearchesBoxStore';
import {
	SEARCH_TYPES,
	ACTIONS_TYPES,
	VARIANT,
	AUTO_COMPLETE_ID,
	AUTO_COMPLETE_SELECT_ID,
	TYPING_TIMEOUT,
} from './constants';
import ProfileStore from '../../stores/ProfileStore';

import RecentSearchesActions from '../../actions/RecentSearchesActions';

import { place_types } from 'arbitrip-common/client/utils/PlacesUtils';

import Config from 'arbitrip-common/client/utils/Config';

import SearchService from '../../utils/SearchService';
import { ReactComponent as CloseIcon } from '@/img/icons/x-close.svg';

import Dialog from '@mui/material/Dialog';

const styles = (theme) => ({
	root: {
		flexGrow: 1,
		height: '100%',
		'& #search_autocomplete': {
			overflow: 'hidden',
			maxWidth: '100%',
			width: '100% !important',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
			opacity: '1 !important',
			fontSize: '15px !important',
			lineHeight: '15px !important',
		},
		'& #autocomplete_select': {
			height: '100%',
		},
	},
	inputWrapper: {
		position: 'relative',
		height: '100%',
	},
	input: {
		height: 'auto',
		display: 'flex',
		padding: 0,
		backgroundColor: theme.palette.common.white,
	},
	inputIcon: {
		position: 'absolute',
		left: '1rem',
		top: '50%',
		transform: 'translateY(-50%)',
		zIndex: 1,
	},
	cssOutlinedInput: {
		'& $input': {
			height: '100%',
			backgroundColor: theme.palette.desktop.search.background,
			boxSizing: 'border-box',
			borderBottom: `2px solid ${theme.palette.desktop.search.background}`,
			borderRadius: 8,

			'&:hover': {
				backgroundColor: theme.palette.desktop.search.hover,
				borderBottom: `2px solid ${theme.palette.desktop.search.hover}`,
			},
		},
	},
	cssOutlinedInputFocus: {
		boxSizing: 'border-box',
		'& $input': {
			borderBottom: `2px solid ${theme.palette.desktop.search.border}`,

			'&:hover': {
				backgroundColor: theme.palette.desktop.search.background,
				borderBottom: `2px solid ${theme.palette.desktop.search.border}`,
			},
		},
	},
	cssOutlinedInputCitySearch: {
		boxSizing: 'border-box',
		backgroundColor: theme.palette.white,
		boxShadow: '0px 10px 16px 0px rgb(0 0 0 / 13%)',
		borderRadius: '8px 8px 0 0 ',

		'& $input': {
			height: '100%',
			boxSizing: 'border-box',
			backgroundColor: theme.palette.white,
			borderBottom: `2px solid ${theme.palette.white}`,
			borderRadius: '8px 8px 0 0 ',

			'&:hover': {
				backgroundColor: theme.palette.white,
				borderBottom: `2px solid ${theme.palette.white}`,
			},
		},
	},
	valueContainer: {
		position: 'relative',
		display: 'flex',
		flexWrap: 'wrap',
		flex: 1,
		alignItems: 'center',
		overflow: 'hidden',
		padding: '10px 36px 10px 45px',
		height: '100%',
		'& div': {
			width: '100%',
			display: 'flex',
			justifyContent: 'end',
			margin: 0,
			color: theme.palette.black.main,
			fontWeight: 700,
		},
	},
	valueContainerLabel: {
		fontSize: 15,
		color: theme.palette.gray.gray2,
		lineHeight: '18px',
		fontWeight: 400,
	},
	valueContainerPadding: {
		paddingLeft: 26,
	},
	valueContainerPaddingCitySearch: {
		paddingLeft: 20,
		paddingRight: 48,
	},
	noOptionsMessage: {
		padding: `8px 16px`,
	},
	singleValue: {
		fontSize: 15,
	},
	placeholder: {
		position: 'absolute',
		width: '100%',
		top: 30,
		left: 45,
		right: 70,
		fontSize: 15,
		fontWeight: 700,
		color: theme.palette.black.main,
		pointerEvents: 'none', // paz: important to allow pasting when component is empty
	},
	placeholderCitySearch: {
		position: 'absolute',
		width: '100%',
		left: 22,
		fontSize: 14,
		fontWeight: 700,
		color: theme.palette.gray.gray2,
		pointerEvents: 'none', // paz: important to allow pasting when component is empty
	},
	placeholderPadding: {
		left: 28,
	},
	loader: {
		position: 'absolute',
		right: 8,
	},
	close: {
		position: 'absolute',
		right: 8,
		cursor: 'pointer',
		height: 26,
		width: 26,
	},
	closeCitySearch: {
		right: 18,
	},
	paper: {
		width: '100%',
		position: 'absolute',
		zIndex: 1,
		marginTop: 0,
		top: 64,
		left: 0,
		boxShadow: '0px 10px 16px 0px rgb(0 0 0 / 13%)',
		borderRadius: 8,
	},
	paperCitySearch: {
		top: 76,
		minHeight: '400px',
		borderRadius: '0 0 8px 8px',
	},
	divider: {
		height: 16,
	},
	highlight: {
		fontWeight: 'bold',
		backgroundColor: 'transparent',
		padding: 0,
	},
	short_address_highlight: {
		color: '#626262',
	},
	menuItemWrap: {
		margin: 0,
		minWidth: 38,
	},
	menuItem: {
		fontSize: 13,
		height: 56,
		'&:not(:last-child)': {
			borderBottom: '1px solid var(--general-colors-gray-light)',
		},
	},
	menuItemCitySearch: {
		fontSize: 14,
		height: 56,
		'&:not(:last-child)': {
			borderBottom: '1px solid var(--general-colors-gray-light)',
		},
	},
	menuItemSelected: {
		backgroundColor: '#fff !important',
	},
	recentSearchItem: {
		height: 90,
	},
	iconSize: {
		width: 14,
		height: 14,
	},
	short_address: {
		fontSize: 11,
		color: '#898989',
	},
	option: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		width: '100%',
	},
	searchIcon: {
		position: 'absolute',
		textAlign: 'center',
		backgroundColor: '#fff',
		width: '28px !important',
		height: 32,
		top: 1,
		left: 1,
		paddingTop: 8,
		borderRadius: 6,
	},
	googleIcon: {
		float: 'right',
		margin: '0 4px 4px',
		maxHeight: 22,
		'& img': {
			verticalAlign: 'top',
		},
	},
	fullHeight: {
		height: '100%',
	},
});

function getRecentSearchesState() {
	return RecentSearchesBoxStore.getItems();
}

function callIfExists(func, ...params) {
	if (_.isFunction(func)) {
		func(...params);
	}
}

const InputComponent = React.forwardRef((props, ref) => <div ref={ref} {...props} />);

const MAX_NUM_RECENT_SEARCHES = 4;
class SearchAutocomplete extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			recentSearches: getRecentSearchesState(),
			searchInput: (props.value && props.value.label) || (props.value && props.value.address) || '',
			value: props.value || null,
			data: [],
			busy: false,
			hoverLabel: false,
			openMenu: !!props.openMenu,
			is_private_travel_only: ProfileStore.isPrivateTravelOnly(),
			popup_open: false,
		};

		this.timeout = null;
		this.Control = this.Control.bind(this);
		this.Placeholder = this.Placeholder.bind(this);
		this.ValueContainer = this.ValueContainer.bind(this);
		this.Menu = this.Menu.bind(this);
		this.handleData = this.handleData.bind(this);
		this.getData = this.getData.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.toggleFocus = this.toggleFocus.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.updateData = this.updateData.bind(this);
		this.Option = this.Option.bind(this);
		this.setLoader = this.setLoader.bind(this);
		this.formatOptionLabel = this.formatOptionLabel.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.clearInput = this.clearInput.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);
		this._onChange = this._onChange.bind(this);

		this.handlePopupOpen = this.handlePopupOpen.bind(this);
		this.handlePopupClose = this.handlePopupClose.bind(this);
		this.renderPopup = this.renderPopup.bind(this);
	}

	handlePopupOpen() {
		this.setState({ popup_open: true });
	}

	handlePopupClose() {
		this.setState({ popup_open: false });
	}

	renderPopup() {
		const { popup_open } = this.state;
		return (
			<Dialog
				open={popup_open}
				onClose={this.handlePopupClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				PaperProps={{
					style: {
						minWidth: 500,
						borderRadius: 8,
					},
				}}
			>
				{/* <DialogTitle id="alert-dialog-title">
                    {"Destination is not available"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography gutterBottom>
                            Searches within Israel are currently not supported.
                        </Typography>
                        <Typography gutterBottom>
                            {
                                this.props.privateTravel
                                    ? "Feel free to explore other countries."
                                    : "Please contact your travel manager or support."
                            }
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handlePopupClose} autoFocus>
                        GOT IT
                    </Button>
                </DialogActions> */}
				<div
					style={{
						textAlign: 'center',
						width: 500,
						height: 450,
						borderRadius: 4,
						padding: 48,
						fontFamily: 'Lato',
						color: 'var(--general-colors-black)',
					}}
				>
					<div>
						<img
							src="./img/destination-is-not-available.svg"
							alt="Destination is not available"
							width="200px"
						/>
					</div>
					<div
						style={{
							marginTop: 30,
							fontSize: 21,
							lineHeight: '25px',
							fontWeight: 600,
						}}
					>
						Destination is not available
					</div>
					<div style={{ marginTop: 8 }}>
						<div
							style={{
								marginTop: 1,
								fontSize: 15,
								lineHeight: '21px',
							}}
						>
							Search in this area is not currently supported.
						</div>
						<div
							style={{
								marginTop: 1,
								fontSize: 15,
								lineHeight: '21px',
							}}
						>
							{this.props.privateTravel
								? 'Countless other destinations are available to you.'
								: 'Please contact your travel manager or support.'}
						</div>
					</div>
					<div
						style={{
							marginTop: 25,
						}}
					>
						<button
							className="btn btn-primary"
							style={{
								width: 90,
								height: 40,
								borderRadius: 8,
								border: 'none',
								backgroundImage: 'none',
								backgroundColor: '#2B66A2',
								'&:hover': {
									backgroundColor: '#357FCB',
								},
								fontSize: 14,
							}}
							onClick={this.handlePopupClose}
						>
							Got it
						</button>
					</div>
				</div>
			</Dialog>
		);
	}

	componentDidUpdate(prevProps) {
		let { value, onUpdate } = this.props;

		if (value && value.address && !value.label) {
			value.label = value.address;
			this.setState({ value, searchInput: value.label });
		}

		if (prevProps.value !== value) {
			this.setState({ value, searchInput: value ? value.label : '' });

			if (value && !SearchService.isDestinationAllowed(value.place)) {
				this.setState({ searchInput: '', value: '' });
				callIfExists(onUpdate, null);
				// if (_.isFunction(onUpdate)) {
				//     onUpdate(null);
				// }
				this.handlePopupOpen();
			}
		}
	}

	isValidGoogleLocation(location) {
		return location && location.lat && location.lng;
	}

	handleChange(item) {
		const { onUpdate, updateItemBeforePlaceId } = this.props;

		this.hideMenu();

		if (!item || (!item.place_id && !item.address)) {
			this.setState({ value: '' });
			callIfExists(onUpdate, item);
			return;
		}

		if (Array.isArray(this.state.data)) {
			item.index = this.state.data.findIndex((datum) => datum && datum.id && datum.id === item.id);
		}

		if (updateItemBeforePlaceId) {
			this.updateItem(item);
		}

		this.setLoader(true);

		const _self = this;
		getPointOfInterest(item)
			.then((place) => {
				if (SearchService.isDestinationAllowed(place)) {
					_self.updateItem(Object.assign({}, item, place));
				} else {
					_self.setState({ searchInput: '', value: '' });
					callIfExists(onUpdate, null);
					_self.handlePopupOpen();
				}
			})
			.catch((err) => {
				console.error('SearchAutocomplete.react.handleChange.getPlaceObject', err);
			})
			.finally(() => {
				_self.setLoader(false);
			});
	}

	onKeyDown(e) {
		let input;
		switch (e.keyCode) {
			case 27:
				// esc key
				if (this.props.variant === VARIANT.CITY_SEARCH) {
					e.preventDefault();
					const { onFocus, onClean } = this.props;
					const { searchInput } = this.state;
					if (!searchInput) {
						callIfExists(onClean);
					}
					callIfExists(onFocus, false);
				}
				break;
			case 35:
				// End key
				e.preventDefault();
				input = document.getElementById(AUTO_COMPLETE_ID);
				input.selectionStart = input.selectionEnd = input.value.length;
				break;
			case 36:
				// Home key
				e.preventDefault();
				input = document.getElementById(AUTO_COMPLETE_ID);
				input.selectionStart = input.selectionEnd = 0;
				break;
			default: // Do nothing
		}
	}

	handleInputChange(e, { action }) {
		const { onUpdate, onInputChange } = this.props;
		if (e === '' && action === ACTIONS_TYPES.INPUT_CHANGE) {
			this.setState({ searchInput: '', value: '' });
			callIfExists(onUpdate, null);
			callIfExists(onInputChange, e);
			return;
		}

		if (action === ACTIONS_TYPES.INPUT_CHANGE) {
			this.setState({ openMenu: true });
			callIfExists(onInputChange, e);
		}

		if (
			action !== ACTIONS_TYPES.MENU_CLOSE &&
			action !== ACTIONS_TYPES.INPUT_BLUE &&
			action !== ACTIONS_TYPES.SET_VALUE
		) {
			this.setState({ searchInput: e });
		}
	}

	hideMenu() {
		this.setState({ openMenu: false });
	}

	clearInput() {
		const { onUpdate, onClean } = this.props;

		this.setState({ searchInput: '', value: '' });

		callIfExists(onUpdate, null);
		callIfExists(onClean);
	}

	updateItem(item) {
		let { onUpdate } = this.props;

		this.setState({
			searchInput: item.label,
			value: item,
		});

		callIfExists(onUpdate, item);
	}

	handleData(event) {
		// Get data only after user stop typing
		clearTimeout(this.timeout);

		const text = (event.target.value || '').trim();
		if (text) {
			this.timeout = setTimeout(() => {
				this.getData(text);
			}, TYPING_TIMEOUT);
		} else {
			this.setState({ data: [] });
			this.updateRecentSearches();
		}
	}

	setLoader(loading) {
		let { onLoading } = this.props;

		this.setState({ busy: loading });
		callIfExists(onLoading, loading);
	}

	getData(text) {
		const { baseDestination, onResultsLoaded } = this.props;
		this.setLoader(true);

		let showSearchIcon = true;
		this.updateData([], showSearchIcon);

		let params = {};

		if (baseDestination) {
			const location = baseDestination?.place?.location;
			params = {
				category: 'hotel',
				city_limit: Config.city_search_limit,
				...(location ? location : {}),
			};
		}

		Ajax.searchAutocomplete(text, params)
			.done((res) => {
				callIfExists(onResultsLoaded, res.data);
				if (text && res.data) {
					this.updateData(res.data);
				} else {
					this.updateRecentSearches();
				}
			})
			.fail((err) => {
				console.log('Search AutoComplete', err);
			})
			.always(() => {
				this.setLoader(false);
			});
	}

	updateData(data, showSearchIcon = false) {
		if (!Array.isArray(data)) {
			data = [];
		}

		if (showSearchIcon && !data.length) {
			const { searchInput } = this.state;
			data = [
				{
					label: `Search for: "${searchInput}"`,
					category: SEARCH_TYPES.SEARCH,
				},
			];
		}

		data = data.map((item) => Object.assign({}, { ...item }));

		this.setState({ data });
	}

	getNights(check_in, check_out) {
		const nightCount = moment(check_out).diff(moment(check_in), 'days');

		return nightCount + (nightCount > 1 ? ' Nights' : ' Night');
	}

	getRoomCount(rooms) {
		return rooms + (rooms > 1 ? ' Rooms' : ' Room');
	}

	getTravelers(rooms, guests) {
		const travelerCount = rooms * guests;
		return travelerCount + (travelerCount > 1 ? ' Travelers' : ' Traveler');
	}

	getChildren(children_ages) {
		const childrenCount = _.get(children_ages, '', []).length;
		let children = childrenCount + (childrenCount > 1 ? ' Children' : ' Child');

		return (
			childrenCount > 0 && (
				<React.Fragment>
					<span>&nbsp;·&nbsp;</span> {children}
				</React.Fragment>
			)
		);
	}

	formatOptionLabel(data, { inputValue }) {
		const { classes, variant } = this.props;
		const separator = variant === VARIANT.CITY_SEARCH ? <br /> : <span>,&nbsp;</span>;

		const words = inputValue.split(' ');

		if (data.category === SEARCH_TYPES.SEARCH) {
			return (
				<React.Fragment>
					<ContentLoader
						speed={2}
						width={400}
						height={82}
						viewBox="0 0 400 82"
						backgroundColor="#f3f3f3"
						foregroundColor="#ecebeb"
					>
						<rect x="0" y="32" rx="3" ry="3" width="144" height="20" />
					</ContentLoader>
				</React.Fragment>
			);
		} else if (data.category === SEARCH_TYPES.RECENT_SEARCH) {
			let address = _.get(data, 'terms.destination.address');
			let rooms = _.get(data, 'terms.rooms');
			let guests = _.get(data, 'terms.guests');
			let children_ages = _.get(data, 'terms.children_ages');
			let check_in = _.get(data, 'terms.check_in');
			let check_out = _.get(data, 'terms.check_out');

			const index = this.state.data.findIndex((d) => d === data);

			return (
				<React.Fragment>
					<div className="recent-searces-container">
						<div className="recent-searces-info">
							<div className="ellipsis adress">{address}</div>
							<div className="icons">
								<img
									src={'/img/search_page/search_bar/calender_list.svg'}
									className="icon"
									alt="calender"
								/>
								{moment(check_in).format('DD MMM')}
								{' - '}
								{moment(check_out).format('DD MMM')}
								<span>&nbsp;·&nbsp;</span>
								{this.getNights(check_in, check_out)}
							</div>
							<div className="icons">
								<img src={'/img/search_page/search_bar/user_list.svg'} className="icon" alt="user" />
								{this.getRoomCount(rooms)}
								<span>&nbsp;·&nbsp;</span>
								{this.getTravelers(rooms, guests)}
								{this.getChildren(children_ages)}
							</div>
						</div>
						<button
							aria-label="Remove Search"
							className="remove-button"
							onClick={(e) => this.onRemoveRecentSearchClick(e, index)}
						/>
					</div>
				</React.Fragment>
			);
		} else {
			return (
				<React.Fragment>
					{(data.name || data.label) && (
						<Highlighter
							highlightClassName={classes.highlight}
							searchWords={words}
							textToHighlight={data.name || data.label}
							autoEscape={true}
						/>
					)}

					{data.short_address && (
						<React.Fragment>
							{separator}
							<Highlighter
								highlightClassName={classNames(classes.highlight, classes.short_address_highlight)}
								className={classes.short_address}
								searchWords={words}
								textToHighlight={data.short_address}
								autoEscape={true}
							/>
						</React.Fragment>
					)}

					{Config.research_mode && Array.isArray(data.types) && (
						<div style={{ fontSize: 12 }}>
							{data.types.map((t, idx) => (
								<span
									style={{
										...(this.isSpecific({
											place_id: data.place_id,
											types: [t],
										})
											? { color: 'red' }
											: {}),
									}}
									key={t}
								>
									{t}
									{idx < data.types.length - 1 ? ' | ' : ''}
								</span>
							))}
						</div>
					)}
				</React.Fragment>
			);
		}
	}

	Control(props) {
		const { variant } = this.props;

		let icon;
		if (variant === VARIANT.DEFAULT) {
			const iconSrc = `/img/search_page/search_bar/location_input.svg`;
			icon = <img src={iconSrc} className={props.selectProps.classes.inputIcon} alt="location" />;
		}

		let classes = [
			'css-outlined-input',
			props.selectProps.classes.cssOutlinedInput,
			props.selectProps.classes.fullHeight,
		];
		if (variant === VARIANT.CITY_SEARCH) {
			classes = [props.selectProps.classes.cssOutlinedInputCitySearch, props.selectProps.classes.fullHeight];
		}

		return (
			<div className={props.selectProps.classes.inputWrapper}>
				{icon}

				<TextField
					onChange={this.handleData}
					style={{ height: '100%' }}
					fullWidth
					InputProps={{
						classes: {
							root: classNames(classes),
							focused: props.selectProps.classes.cssOutlinedInputFocus,
						},
						inputComponent: InputComponent,
						inputProps: {
							className: props.selectProps.classes.input,
							ref: props.innerRef,
							children: props.children,
							...props.innerProps,
						},
					}}
					{...props.selectProps.textFieldProps}
				/>
			</div>
		);
	}

	isSpecific(data) {
		if (data.hotel_id) {
			return true;
		}

		if (data.place_id) {
			if (Array.isArray(data.types)) {
				return data.types.some((t) => place_types[t]);
			}
		}

		return false;
	}

	getOptionIcon(props) {
		const { category } = props.data;

		const icon_style = Config.research_mode && this.isSpecific(props.data) ? { color: 'red' } : {};

		switch (category) {
			case SEARCH_TYPES.SEARCH:
				return <HourglassEmptyIcon className={props.selectProps.classes.iconSize} style={icon_style} />;
			case SEARCH_TYPES.RECENT_SEARCH:
				return null;
			case SEARCH_TYPES.REGION:
				return <MapIcon className={props.selectProps.classes.iconSize} style={icon_style} />;
			case SEARCH_TYPES.HOTEL:
				return (
					<img
						src={'/img/search_page/search_bar/hotel.svg'}
						className={props.selectProps.classes.iconSize}
						alt="hotel"
					/>
				);
			default:
				return (
					<img
						src={'/img/search_page/search_bar/location_option.svg'}
						className={props.selectProps.classes.iconSize}
						alt="location"
					/>
				);
		}
	}

	Option(props) {
		return (
			<MenuItem
				selected={props.isFocused}
				component="div"
				className={classNames(
					props.selectProps.classes.menuItem,
					props.data.category === SEARCH_TYPES.SEARCH && props.selectProps.classes.menuItemSelected,
					props.data.category === SEARCH_TYPES.RECENT_SEARCH && props.selectProps.classes.recentSearchItem,
				)}
				style={{
					fontWeight: props.isSelected ? 500 : 400,
				}}
				{...props.innerProps}
			>
				<ListItemIcon className={props.selectProps.classes.menuItemWrap}>
					{this.getOptionIcon(props)}
				</ListItemIcon>
				<div className={props.selectProps.classes.option}>{props.children}</div>
			</MenuItem>
		);
	}

	OptionSmall(props) {
		return (
			<MenuItem
				selected={props.isFocused}
				component="div"
				className={classNames(
					props.selectProps.classes.menuItemCitySearch,
					props.data.category === SEARCH_TYPES.SEARCH && props.selectProps.classes.menuItemSelected,
				)}
				style={{
					fontWeight: props.isSelected ? 500 : 400,
				}}
				{...props.innerProps}
			>
				<div className={props.selectProps.classes.option} title={props.data.label}>
					{props.children}
				</div>
			</MenuItem>
		);
	}

	Placeholder(props) {
		let { showSearchIcon, variant } = this.props;

		if (variant === VARIANT.CITY_SEARCH) {
			let { searchInput } = this.state;
			return !!searchInput ? null : (
				<Typography
					className={classNames(props.selectProps.classes.placeholderCitySearch)}
					color="textSecondary"
					{...props.innerProps}
				>
					{props.children}
				</Typography>
			);
		}
		return (
			<Typography
				className={classNames(
					'placeholder',
					props.selectProps.classes.placeholder,
					showSearchIcon ? props.selectProps.classes.placeholderPadding : '',
				)}
				color="textSecondary"
				{...props.innerProps}
			>
				{props.children}
			</Typography>
		);
	}

	SingleValue(props) {
		return (
			<Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
				{props.children}
			</Typography>
		);
	}

	ValueContainer(props) {
		const { hoverLabel, searchInput, value } = this.state;
		const { showSearchIcon, variant } = this.props;
		const isCitySearch = variant === VARIANT.CITY_SEARCH;
		return (
			<div
				className={classNames(
					'value-container',
					props.selectProps.classes.valueContainer,
					showSearchIcon ? props.selectProps.classes.valueContainerPadding : '',
					isCitySearch ? props.selectProps.classes.valueContainerPaddingCitySearch : '',
				)}
			>
				{showSearchIcon && (
					<div className={props.selectProps.classes.searchIcon}>
						<i className="fa fa-search" aria-hidden="true" />
					</div>
				)}

				{variant === VARIANT.DEFAULT && (
					<span className={classNames(props.selectProps.classes.valueContainerLabel)}>Destination</span>
				)}

				{props.children}
				{(searchInput || (value && isCitySearch)) && (
					<button
						aria-label="Clear destination input"
						onClick={this.clearInput}
						className={classNames(
							'close',
							props.selectProps.classes.close,
							isCitySearch ? props.selectProps.classes.closeCitySearch : '',
						)}
					>
						<CloseIcon />
					</button>
				)}
			</div>
		);
	}

	Menu(props) {
		const { data } = this.state;
		const { variant } = this.props;

		if (data.length === 0 && variant !== VARIANT.CITY_SEARCH) {
			// when no results remove menu
			return <div />;
		}

		const classes = [props.selectProps.classes.paper, 'paper-root'];
		if (variant === VARIANT.CITY_SEARCH) {
			classes.push(props.selectProps.classes.paperCitySearch);
		}

		return (
			<Paper square className={classNames(classes)} {...props.innerProps}>
				{props.children}
				{this.getGooglePoweredBy()}
			</Paper>
		);
	}

	getGooglePoweredBy() {
		let { data } = this.state;
		let { classes } = this.props;

		let showGoogleLogo = data.filter((item) => item.category === SEARCH_TYPES.LOCATION).length > 0;

		return (
			showGoogleLogo && (
				<div className={classes.googleIcon}>
					<img src="/img/google/powered_by_google_on_white.png" alt="" />
				</div>
			)
		);
	}

	onBlur(e) {
		let { onBlur, variant } = this.props;
		let { value, searchInput } = this.state;

		let label = (value && value.label) || (value && value.address) || '';
		if (variant === VARIANT.CITY_SEARCH && !label) {
			label = value;
		}
		this.setState({ searchInput: label });

		callIfExists(onBlur, e, { value, searchInput });

		this.toggleFocus();
		this.hideMenu();
	}

	toggleFocus(v) {
		const { hoverLabel, searchInput } = this.state;
		const { onFocus, onClean, variant } = this.props;

		if (!searchInput && variant === VARIANT.CITY_SEARCH) {
			callIfExists(onClean);
		}
		callIfExists(onFocus, !!v);

		this.setState({ hoverLabel: !hoverLabel });

		if (!hoverLabel && (!searchInput || searchInput === '')) {
			this.updateRecentSearches();
		}
	}

	updateRecentSearches() {
		const { recentSearches, openMenu } = this.state;
		const { variant } = this.props;

		const data =
			variant === VARIANT.DEFAULT
				? recentSearches.slice(0, MAX_NUM_RECENT_SEARCHES).map((recentSearch) => ({
						...recentSearch,
						label: recentSearch.terms.destination.address,
						category: SEARCH_TYPES.RECENT_SEARCH,
					}))
				: [];

		if (data && data.length > 0) {
			// show recent searches
			this.setState({ data });
			if (!openMenu) {
				this.setState({ openMenu: true });
			}
		} else {
			// hide recent searches
			if (openMenu && !this.props) {
				this.setState({ openMenu: false });
			}
			this.setState({ data: [] });
		}
	}

	onRemoveRecentSearchClick(e, index) {
		e.stopPropagation();
		const recentSearchItem = this.state.recentSearches[index];
		RecentSearchesActions.removeRecentSearch(recentSearchItem);

		const updatedRecentSearches = this.state.recentSearches.filter((_, i) => i !== index);
		this.setState({
			recentSearches: updatedRecentSearches,
			data: updatedRecentSearches.slice(0, MAX_NUM_RECENT_SEARCHES).map((recentSearch) => ({
				...recentSearch,
				label: recentSearch.terms.destination.address,
				category: SEARCH_TYPES.RECENT_SEARCH,
			})),
		});
	}

	render() {
		const { data, value, searchInput, openMenu } = this.state;
		const { classes, theme, placeholder, autoFocus, tabIndex, variant } = this.props;

		const selectStyles = {
			input: (base) => ({
				...base,
				color: theme.palette.text.primary,
				'&:after': {
					maxWidth: '2px',
				},
			}),
			clearIndicator: (provided) => ({
				...provided,
				cursor: 'pointer',
				padding: '6px 8px',
			}),
			menuList: (provided) => ({
				...provided,
				...(variant === VARIANT.CITY_SEARCH ? { maxHeight: '400px' } : {}),
			}),
		};

		const components = {
			Control: this.Control,
			Menu: this.Menu,
			Option: this.Option,
			Placeholder: this.Placeholder,
			SingleValue: this.SingleValue,
			ValueContainer: this.ValueContainer,
			DropdownIndicator: null,
			IndicatorSeparator: null,
		};

		return (
			<div className={classNames('search-root', classes.root, variant)}>
				<Select
					id={AUTO_COMPLETE_SELECT_ID}
					inputId={AUTO_COMPLETE_ID}
					classes={classes}
					styles={selectStyles}
					options={data}
					components={components}
					onChange={this.handleChange}
					onFocus={this.toggleFocus}
					onBlur={this.onBlur}
					placeholder={placeholder ? placeholder : 'Where are you going?'}
					autoFocus={autoFocus}
					formatOptionLabel={this.formatOptionLabel}
					noOptionsMessage={() => data}
					filterOption={() => true}
					onKeyDown={this.onKeyDown}
					backspaceRemovesValue={false}
					value={value}
					inputValue={searchInput}
					onInputChange={this.handleInputChange}
					menuIsOpen={openMenu}
					tabIndex={tabIndex}
					tabSelectsValue={false}
					aria-label="Choose destination"
					controlShouldRenderValue={variant !== VARIANT.CITY_SEARCH}
				/>
				{this.renderPopup()}
			</div>
		);
	}

	_onChange() {
		this.setState({ recentSearches: getRecentSearchesState() });
	}

	componentDidMount() {
		RecentSearchesBoxStore.addChangeListener(this._onChange);
	}

	componentWillUnmount() {
		RecentSearchesBoxStore.removeChangeListener(this._onChange);
	}
}

SearchAutocomplete.propTypes = {
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
	onLoading: PropTypes.func,
	onUpdate: PropTypes.func,
	onBlur: PropTypes.func,
	onFocus: PropTypes.func,
	onClean: PropTypes.func,
	placeholder: PropTypes.string,
	showSearchIcon: PropTypes.bool,
	showLoader: PropTypes.bool,
	updateItemBeforePlaceId: PropTypes.bool,
	autoFocus: PropTypes.bool,
	tabIndex: PropTypes.string,
	variant: PropTypes.oneOf([VARIANT.DEFAULT, VARIANT.CLEAN, VARIANT.CITY_SEARCH]),
	private_travel: PropTypes.bool,
	baseDestination: PropTypes.object,
	onInputChange: PropTypes.func,
	onResultsLoaded: PropTypes.func,
	openMenu: PropTypes.bool,
};

SearchAutocomplete.defaultProps = {
	variant: VARIANT.DEFAULT,
	updateItemBeforePlaceId: true,
};

export default withStyles(styles, { withTheme: true })(SearchAutocomplete);
