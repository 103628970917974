import 'react-dates/initialize';

import { DateRangePicker } from 'react-dates';

import _ from 'lodash';
import classNames from 'classnames';
import React from 'react';
import moment from 'moment';
import SearchActions from '../../actions/SearchActions';
import SearchStore from '../../stores/SearchStore';
import ProfileStore from '../../stores/ProfileStore';
import SearchService from '../../utils/SearchService';
import PropTypes from 'prop-types';

import DomService from './helpers/DomService';

import Analytics from 'arbitrip-common/client/analytics';
import RouterWrapper from '../../utils/RouterWrapper';
import SearchAutocomplete from './SearchAutocomplete.react';
import SearchData from './search-data/SearchData.react';
import SearchMessage from './SearchMessage.react';
import SEARCH_CONSTANTS from 'arbitrip-common/general/constants/search-constants';
import { SEARCH_TYPES, VARIANT, START_DATE_ID, END_DATE_ID, SEARCH_BOX_ID } from './constants';
import { withRouter } from '../../utils/withRouter';
import Dates from '../../utils/Dates';
import SearchIcon from './SearchIcon';

const delay_timeout = 2 * 1000;
const delay_options = {
	leading: true,
	trailing: false,
};

function getState() {
	return {
		profile: ProfileStore.getProfile(),
		store_search_terms: SearchStore.getSearchTerms(),
	};
}

const today = moment.utc();
const maxDate = moment.utc().add(1, 'y');

class SearchFull extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			autocompleteLoading: false,
			focusedDateInput: null,
			focusedGuestsInput: null,
			showPreviousMonthButtonOnCalendar: true,
			showNextMonthButtonOnCalendar: true,
			showChildrenAgesError: false,
			..._.extend(SearchStore.getSearchTerms(), getState()),
		};

		this.searchDataRef = React.createRef();
		this.searchDataButtonRef = React.createRef();

		this._onChange = this._onChange.bind(this);
		this.onLoadingDestination = this.onLoadingDestination.bind(this);
		this.onDatesChange = this.onDatesChange.bind(this);
		this.onGuestsChange = this.onGuestsChange.bind(this);
		this.onRoomsChange = this.onRoomsChange.bind(this);
		this.onPrevMonthClick = this.onPrevMonthClick.bind(this);
		this.onNextMonthClick = this.onNextMonthClick.bind(this);
		this.onChildrenChange = this.onChildrenChange.bind(this);
		this.onSearch = this.onSearch.bind(this);
		this.onSearch = _.debounce(this.onSearch, delay_timeout, delay_options);
		this.onUpdateDestinationSearch = this.onUpdateDestinationSearch.bind(this);
		this.openDatesWindow = this.openDatesWindow.bind(this);
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			!_.isEqual(
				_.get(prevState, 'store_search_terms.destination', {}),
				_.get(this.state, 'store_search_terms.destination', {}),
			)
		) {
			// update destination if change in store
			this.setState({
				address: this.state.store_search_terms.address,
				destination: this.state.store_search_terms.destination,
				location: this.state.store_search_terms.location,
				hotel_id: this.state.store_search_terms.hotel_id,
			});

			setTimeout(() => {
				this.openDatesWindow();
			});
		}

		if (
			!_.isEqual(
				_.get(prevState, 'store_search_terms.rooms', 1),
				_.get(this.state, 'store_search_terms.rooms', 1),
			)
		) {
			// update rooms if change in store
			this.setState({
				rooms: this.state.store_search_terms.rooms,
			});
		}

		if (
			!_.isEqual(
				_.get(prevState, 'store_search_terms.guests', 1),
				_.get(this.state, 'store_search_terms.guests', 1),
			)
		) {
			// update guests if change in store
			this.setState({
				guests: this.state.store_search_terms.guests,
			});
		}

		if (
			!_.isEqual(
				_.get(prevState, 'store_search_terms.children_ages', []),
				_.get(this.state, 'store_search_terms.children_ages', []),
			)
		) {
			// update children_ages if change in store
			this.setState({
				children_ages: this.state.store_search_terms.children_ages,
			});
		}

		if (
			!_.isEqual(
				_.get(prevState, 'store_search_terms.check_in', null),
				_.get(this.state, 'store_search_terms.check_in', null),
			)
		) {
			// update check_in if change in store
			this.setState({
				check_in: this.state.store_search_terms.check_in,
			});
		}

		if (
			!_.isEqual(
				_.get(prevState, 'store_search_terms.check_out', null),
				_.get(this.state, 'store_search_terms.check_out', null),
			)
		) {
			// update check_out if change in store
			this.setState({
				check_out: this.state.store_search_terms.check_out,
			});
		}

		if (
			!_.isEqual(
				_.get(prevState, 'store_search_terms.contract_id', null),
				_.get(this.state, 'store_search_terms.contract_id', null),
			)
		) {
			// update contract id
			// when search we build the url from current component state
			this.setState({
				contract_id: this.state.store_search_terms.contract_id,
			});
		}

		if (this.getAddress(this.state.destination) !== this.getAddress(prevState.destination)) {
			const searchBoxElement = document.getElementById(SEARCH_BOX_ID);
			if (window.Velocity && !DomService.isElementInViewport(searchBoxElement)) {
				window.Velocity(searchBoxElement, 'scroll', {
					duration: 600,
					offset: -100,
					easing: 'ease-in-out',
				});
			}
		}

		if (this.state.showChildrenAgesError && SearchService.areChildrenAgesValid(this.state)) {
			this.setState({ showChildrenAgesError: false });
		}
	}

	getAddress(destination) {
		return destination && destination.address;
	}

	onLoadingDestination(loading) {
		this.setState({ autocompleteLoading: loading });
	}

	onUpdateDestinationSearch(item) {
		console.log('onUpdateDestinationSearch', item);

		if (item && item.category === SEARCH_TYPES.RECENT_SEARCH) {
			SearchActions.updateSearchTerms(item.terms);
		} else {
			SearchActions.updateDestination(item);

			this.openDatesWindow();
		}
	}

	openDatesWindow() {
		const { check_in, check_out, destination } = this.state;

		if (check_in) {
			this.onPrevMonthClick(check_in);
		}

		if (!destination) {
			return;
		}

		if (window.$) {
			if (!check_in) {
				window.$(`#${START_DATE_ID}`).focus();
			} else if (!check_out) {
				window.$(`#${END_DATE_ID}`).focus();
			}
		} else {
			console.log('jQuery is not initiated!');
		}
	}

	openDataWindow = () => {
		this.searchDataRef.current.toggleWindow(true);
		this.setState({ focusedGuestsInput: true });
	};

	handleDataWindowToggle = (isFocused) => {
		this.setState({ focusedGuestsInput: isFocused });
	};

	onDatesChange(startDate, endDate) {
		if (startDate) {
			SearchActions.updateCheckIn(startDate);
		}

		if (endDate) {
			SearchActions.updateCheckOut(endDate);
			this.searchDataButtonRef?.current?.focus();
		}
	}

	searchIsValid() {
		const { autocompleteLoading } = this.state;

		return SearchService.isValid(this.state) && !autocompleteLoading;
	}

	onGuestsChange(guests) {
		SearchActions.updateGuests(guests);
	}

	onRoomsChange(rooms) {
		SearchActions.updateRooms(rooms);
	}

	onChildrenChange(childrenAges) {
		SearchActions.updateChildrenAges(childrenAges);
	}

	onSearch(e) {
		if (!this.searchIsValid()) {
			return;
		}

		const { navigate } = this.props;
		const clonedState = _.clone(this.state);

		SearchActions.updateSearchData(clonedState);

		if (SearchService.isValid(this.state)) {
			// // temp workaround for bad change search beheviour
			// RouterWrapper.goToResultsPage(clonedState);
			// if (this.props.variant === VARIANT.SMALL) {
			//     return RouterWrapper.refresh();
			// }

			if (!e.metaKey && !e.ctrlKey) {
				SearchActions.search();

				const { profile, contract_id } = this.state;
				const client = contract_id ? SearchStore.getContractClientName() + ' (' + contract_id + ')' : null;

				Analytics.actions.interactions.executedSearch(
					profile,
					this.state.destination,
					this.state, //all the search data is on the state
					client,
				);
			}
		} else {
			// shakes the search box when not all search terms are valid...
			window.Velocity(document.getElementById(SEARCH_BOX_ID), 'callout.shake', {});
		}

		RouterWrapper.goToResultsPage(navigate, clonedState);
	}

	handleSearch = (event) => {
		if (this.searchIsValid()) {
			const areChildrenAgesValid = SearchService.areChildrenAgesValid(this.state);
			if (areChildrenAgesValid) {
				this.onSearch(event);
			} else {
				this.setState({ showChildrenAgesError: true });
				this.openDataWindow();
			}
		}
	};

	getSearchButton() {
		return (
			<button
				className={classNames('search', !this.searchIsValid() && 'search-disabled default-style')}
				onClick={this.handleSearch}
				aria-label="Search"
				aria-disabled={!this.searchIsValid()}
				onFocus={() => {
					this.searchDataRef.current.closeWindow();
					this.setState({ focusedGuestsInput: false });
				}}
			>
				<SearchIcon />
			</button>
		);
	}

	getDateLabels(focusedDateInput) {
		return ['startDate', 'endDate'].map((dateType) => (
			<span
				key={dateType}
				className={classNames(
					'label',
					dateType === 'startDate' ? 'check-in' : 'check-out',
					focusedDateInput === dateType && 'focused',
				)}
			>
				{dateType === 'startDate' ? 'Check-in' : 'Check-out'}
			</span>
		));
	}

	onNextMonthClick(t) {
		const showPreviousMonthButtonOnCalendar = t.clone().startOf('month').isAfter(moment.utc().startOf('month'));
		const showNextMonthButtonOnCalendar = t
			.clone()
			.add(1, 'M')
			.startOf('month')
			.add(1, 'd')
			.isBefore(maxDate.clone().startOf('month'));
		this.setState({
			showPreviousMonthButtonOnCalendar,
			showNextMonthButtonOnCalendar,
		});
	}

	onPrevMonthClick(t) {
		const showPreviousMonthButtonOnCalendar = t.clone().startOf('month').isAfter(moment.utc());
		const showNextMonthButtonOnCalendar = t
			.clone()
			.add(1, 'M')
			.startOf('month')
			.add(1, 'd')
			.isBefore(maxDate.clone().startOf('month'));
		this.setState({
			showPreviousMonthButtonOnCalendar,
			showNextMonthButtonOnCalendar,
		});
	}

	getDateIcons(focusedDateInput) {
		return ['startDate', 'endDate'].map((dateType) => (
			<img
				key={dateType}
				src={`/img/search_page/search_bar/calendar.svg`}
				className={`calendar-icon ${dateType === 'startDate' ? 'check-in' : 'check-out'}`}
				alt="calendar"
			/>
		));
	}

	getNumberOfNights() {
		return SearchService.getNumberOfNights(this.state);
	}

	render() {
		const {
			destination,
			check_in,
			check_out,
			focusedDateInput,
			guests,
			rooms,
			children_ages,
			focusedGuestsInput,
			showPreviousMonthButtonOnCalendar,
			showNextMonthButtonOnCalendar,
			profile,
		} = this.state;
		const { variant } = this.props;

		return (
			<div>
				<div
					id={SEARCH_BOX_ID}
					className={classNames('search-box-container', variant === VARIANT.SMALL && VARIANT.SMALL)}
					style={this.props.style}
				>
					<div className="search-box-wrap">
						<div className={classNames('autocomplete')}>
							<div className="autocomplete-wrap">
								<SearchAutocomplete
									value={destination}
									onLoading={this.onLoadingDestination}
									onUpdate={this.onUpdateDestinationSearch}
									autoFocus={false}
									privateTravel={this.state.private_travel}
								/>
							</div>
						</div>
						<div onClick={() => this.openDatesWindow()} className="dates">
							{this.getDateLabels(focusedDateInput)}
							{this.getDateIcons(focusedDateInput)}

							<DateRangePicker
								minimumNights={0}
								startDate={check_in}
								startDateId={START_DATE_ID}
								endDate={check_out}
								endDateId={END_DATE_ID}
								onDatesChange={({ startDate, endDate }) => this.onDatesChange(startDate, endDate)}
								focusedInput={focusedDateInput}
								onFocusChange={(focusedDateInput) => this.setState({ focusedDateInput })}
								displayFormat="D MMM"
								startDatePlaceholderText="Choose a date"
								endDatePlaceholderText="Choose a date"
								noBorder={true}
								hideKeyboardShortcutsPanel={true}
								readOnly={true}
								onNextMonthClick={this.onNextMonthClick}
								onPrevMonthClick={this.onPrevMonthClick}
								navPrev={showPreviousMonthButtonOnCalendar ? null : <div />}
								navNext={showNextMonthButtonOnCalendar ? null : <div />}
								isOutsideRange={(date) =>
									Dates.isBeforeDay(date, today) || Dates.isBeforeDay(maxDate, date)
								}
							/>
						</div>
						<button
							ref={this.searchDataButtonRef}
							aria-label="Select Guests. Currently"
							aria-describedby="adults rooms children"
							onClick={this.openDataWindow}
							id={SEARCH_CONSTANTS.SHARED_ATTRIBUTES_NAMES.SEARCH_DATA}
							className={classNames('data', focusedGuestsInput && 'focused')}
						>
							<img src={`/img/search_page/search_bar/user.svg`} className="search-icon" alt="user" />
							<SearchData
								ref={this.searchDataRef}
								adults={guests}
								childrenAges={children_ages}
								rooms={rooms}
								onGuestsChange={this.onGuestsChange}
								onRoomsChange={this.onRoomsChange}
								onChildrenChange={this.onChildrenChange}
								onDataWindowToggle={this.handleDataWindowToggle}
								showChildrenAgesError={this.state.showChildrenAgesError}
								isAgent={profile.agent}
							/>
						</button>
					</div>
					{this.getSearchButton()}
				</div>
				{variant !== VARIANT.SMALL && <SearchMessage nights={this.getNumberOfNights()} />}
			</div>
		);
	}

	_onChange() {
		this.setState({ ...getState() });
	}

	componentDidMount() {
		SearchStore.addChangeListener(this._onChange);
	}

	componentWillUnmount() {
		SearchStore.removeChangeListener(this._onChange);
	}
}

SearchFull.propTypes = {
	variant: PropTypes.oneOf([VARIANT.DEFAULT, VARIANT.SMALL]),
};

export default withRouter(SearchFull);
