class BusinessDestination {
  constructor(business_destination) {
    this.id = business_destination._id;
    this.destination = business_destination.destination;
    this.image = business_destination.image;
    this.name = business_destination.name;
  }
}

module.exports = BusinessDestination;
