import React from 'react';
import ChangeClientPrice from '../../../general/ChangeClientPrice.react';
import BookingsActions from '../../../../actions/BookingsActions';

const SettlePaymentEditCommission = ({ profile, booking, onClose, chargeCurrency }) => {
	const onUpdate = (agentPricing) => {
		BookingsActions.updateAgentPricing(booking, agentPricing);
		onClose();
	};

	return (
		<div className="settle-payment-edit-commission change-client-price">
			<ChangeClientPrice
				deal={booking?.deal}
				initializing={false}
				disabled={false}
				onCancel={onClose}
				onUpdate={onUpdate}
				profile={profile}
				sellInNetPriceOption={true}
				chargeCurrency={chargeCurrency}
				payMode={true}
			/>
		</div>
	);
};

export default SettlePaymentEditCommission;
