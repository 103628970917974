import _ from 'lodash';
import React from 'react';
import { IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import Analytics from 'arbitrip-common/client/analytics';

class RetroactivePointsUsageTitle extends React.Component {
	constructor(props) {
		super(props);

		this.displayName = 'RetroactivePointsUsageTitle';

		this.onCloseClick = this.onCloseClick.bind(this);
	}

	onCloseClick() {
		Analytics.actions.interactions.closedPointsModal(this.props?.booking?.id);
		if (_.isFunction(this.props?.onClose)) {
			this.props.onClose();
		}
	}

	render() {
		return (
			<div className="retroactive-points-usage-title">
				<div className="title-text">Update Points</div>
				<IconButton aria-label="close" onClick={this.onCloseClick} className="close-button" size="large">
					<CloseIcon />
				</IconButton>
			</div>
		);
	}
}

export default RetroactivePointsUsageTitle;
