import AppDispatcher from '../dispatcher/AppDispatcher';
import PaymentConstants from '../constants/PaymentConstants';
import ReservationConstants from '../constants/ReservationConstants';
import ReservationStore from '../stores/ReservationStore';
import SearchStore from '../stores/SearchStore';
import ProfileStore from '../stores/ProfileStore';
import ResultsStore from '../stores/ResultsStore';

import Analytics from 'arbitrip-common/client/analytics';
import HotelStore from '../stores/HotelStore';

export default {
	clearPaymentData: function () {
		AppDispatcher.handleAction({
			actionType: PaymentConstants.CLEAR_PAYMENT_DATA,
		});
	},

	setPaymentErrorMessage: function (data) {
		AppDispatcher.handleAction({
			actionType: PaymentConstants.PAYMENT_ERROR_MESSAGE,
			data,
		});
	},

	markPaymentAsCharged: function () {
		AppDispatcher.handleAction({
			actionType: PaymentConstants.CREDIT_PAYMENT_CHARGED,
		});

		const search_terms = SearchStore.getSearchTerms();
		const reservation = ReservationStore.getReservationData();
		const profile = ProfileStore.getProfile();
		const hotel = HotelStore.getHotelData();
		const hotel_info = ResultsStore.getHotelById(hotel.id);
		const distanceInKm = hotel_info?.distanceInKm;

		Analytics.actions.responses.bookingCreated({
			user: profile,
			hotel,
			search: search_terms,
			deal: reservation.deal,
			payment_method: PaymentConstants.PAYMENT_METHODS_ANALYTICS.CREDIT_CARD,
			distance: distanceInKm,
			...ResultsStore.getReservationAttemptsObject(hotel.id),
			booking_id: reservation.short_id,
			status: 'success',
		});
		ResultsStore.cleanReservationAttempts();
	},

	save3DSInformation: function (data) {
		AppDispatcher.handleAction({
			actionType: PaymentConstants.THREEDS_INFORMATION_DATA,
			data,
		});
	},

	markPaymentAsFailed: function (err) {
		AppDispatcher.handleAction({
			actionType: PaymentConstants.CREDIT_PAYMENT_FAILED,
			data: { error_message: err },
		});

		let reservation = ReservationStore.getReservationData();
		let profile = ProfileStore.getProfile();
		ResultsStore.increaseReservationAttempts(reservation.hotel?.id);
		Analytics.actions.responses.reservationFailed({
			user: profile,
			hotel: reservation.hotel,
			deal: reservation.deal,
			payment_method: PaymentConstants.PAYMENT_METHODS_ANALYTICS.CREDIT_CARD,
			error: err,
			status: reservation.status,
			failure_reason: err,
			...ResultsStore.getReservationAttemptsObject(reservation.hotel?.id),
		});
	},

	markPaymentAsCannotBeOpened: function () {
		AppDispatcher.handleAction({
			actionType: ReservationConstants.ADVANCE_RESERVATION_FAILURE,
		});
	},
};
