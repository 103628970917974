import _ from 'lodash';
import moment from 'moment-timezone';
import classNames from 'classnames';
import React from 'react';
import BookingsConstants from '../../../constants/BookingsConstants';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import BookingStatus from '../../../utils/BookingStatus';
import Tooltip from '@mui/material/Tooltip';
import Currencies from 'arbitrip-common/general/utils/Currencies';
import ReservationConstants from '../../../constants/ReservationConstants';
import { isRequestActionable } from './helpers';
import { ReactComponent as NoBookingsFilterIcon } from '../../../img/bookings/no-bookings-filter.svg';
import { ReactComponent as NoBookingsIcon } from '../../../img/bookings/no-bookings.svg';
import TablePagination from '@mui/material/TablePagination';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import TableFooter from '@mui/material/TableFooter';

const border_spacing = {
	borderCollapse: 'separate',
	borderSpacing: '0 4px',
};

const date_format = 'D MMM YYYY';
const full_date_format = 'DD MMM YYYY';
const full_date_time_format = full_date_format + ' hh:mm A';

function formatRequestDate(date) {
	const timezone = moment.tz.guess();
	if (timezone) {
		return moment(date).tz(timezone).format(full_date_time_format) + ' (' + timezone + ')';
	}

	return moment(date).format(full_date_time_format);
}

const RequestsTable = ({
	profile,
	openDrawer,
	bookings,
	bookingsCount,
	selectedBooking,
	status,
	handleApprove,
	handleDecline,
	filters,
	pagination,
	setPagination,
}) => {
	const { page, rows_per_page } = pagination;

	const getTravelersText = (booking) => {
		let names_cap = 4;
		let children_text = '';
		if (booking.travelers.length >= 4) {
			names_cap--;
		}
		if (booking.deal.details.children_count >= 1) {
			names_cap--;
			children_text = `${booking.deal.details.children_count} ${booking.deal.details.children_count > 1 ? 'Children' : 'Child'}`;
		}

		let travelers_text = booking.travelers
			.map((t, idx) => (idx < names_cap ? `${t.first_name} ${t.last_name}` : null))
			.join(', ');
		if (booking.travelers.length > names_cap) {
			const additional_adults = booking.travelers.length - names_cap;
			travelers_text += ` +${additional_adults} ${additional_adults > 1 ? 'Adults' : 'Adult'}`;
			if (children_text) {
				travelers_text += `, ${children_text}`;
			}
		} else if (children_text) {
			travelers_text += ` +${children_text}`;
		}

		return travelers_text;
	};

	const hasTags = () => {
		return _.some(filters, (value) => !_.isEmpty(value));
	};

	const handleChangePage = (event, newPage) => {
		console.log('handleChangePage', event.target.value, newPage);
		setPagination({
			...pagination,
			page: newPage,
		});
	};

	return (
		<React.Fragment>
			<div className="bookings-table-component">
				{bookings.length > 0 && status !== BookingsConstants.STATUS.BUSY && (
					<TableContainer component={Paper} className="bookings-table-container">
						<Table className="bookings-table" aria-label="bookings table" sx={{ ...border_spacing }}>
							<TableHead>
								<TableRow>
									{profile.agent && <TableCell className="cell__client">Client</TableCell>}
									<TableCell className="cell__hotel">Hotel</TableCell>
									<TableCell className="cell__city">City</TableCell>
									<TableCell className="cell__date check-in" align="left">
										Check-In
									</TableCell>
									<TableCell className="cell__date check-out" align="left">
										Check-Out
									</TableCell>
									<TableCell className="cell__travelers">Traveler(s)</TableCell>
									<TableCell className="cell__id">Reservation ID</TableCell>
									<TableCell className="cell__price">Total Price</TableCell>
									<TableCell className="booking-status" align="left">
										Status
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{bookings.map((item, index) => {
									const reservation = _.get(item, 'reservation');
									const ticket = _.get(item, 'request_ticket');

									const travelers = getTravelersText(reservation);

									const is_approver = _.get(ticket, 'approver._id') === profile.id;

									return (
										<React.Fragment key={index}>
											<TableRow
												onClick={() => openDrawer(item)}
												selected={
													selectedBooking &&
													_.get(selectedBooking, 'reservation.id') === reservation.id
												}
												className={classNames('booking-row', {
													'power-user': profile.isApprovalFlowPowerUser(),
												})}
											>
												{profile.agent && (
													<TableCell className="cell__client">
														<Tooltip title={reservation.company.name}>
															<div className="booking-client-logo-container">
																<img
																	src={reservation.company.picture}
																	className="booking-client-logo"
																	alt={reservation.company.name}
																/>
															</div>
														</Tooltip>
													</TableCell>
												)}
												<TableCell className="cell__hotel">{reservation.hotel.name}</TableCell>
												<TableCell className="cell__city">{reservation.hotel.city}</TableCell>
												<TableCell className="cell__date">
													{moment(reservation.deal.details.check_in).format(date_format)}
												</TableCell>
												<TableCell className="cell__date">
													{moment(reservation.deal.details.check_out).format(date_format)}
												</TableCell>
												<TableCell className="cell__travelers">{travelers}</TableCell>
												<TableCell className="cell__id">{reservation.short_id}</TableCell>
												<TableCell className="cell__price">
													{Currencies.getPriceWithCurrency(
														reservation.deal.cheapopoPricing.cheapopoTotalPrice,
														reservation.deal.cheapopoPricing.currency,
													)}
												</TableCell>
												<TableCell className={`booking-status ${ticket.status}`}>
													● {BookingStatus.getRequestStatus(ticket.status)}
												</TableCell>
											</TableRow>
											{(profile.isApprovalFlowPowerUser() || is_approver) && (
												<React.Fragment>
													<TableRow className="approval-actions">
														<TableCell
															style={{ paddingBottom: 0, paddingTop: 0 }}
															colSpan={8}
														>
															<div
																className={classNames('approval-actions__content', {
																	[ticket.status]: true,
																})}
															>
																{ticket.status ===
																	ReservationConstants.REQUEST_STATUS.EXPIRED && (
																	<div className="expired-on">
																		Expired on{' '}
																		{formatRequestDate(ticket.changed_date)}
																	</div>
																)}

																{ticket.status ===
																	ReservationConstants.REQUEST_STATUS.REJECTED && (
																	<div className="declined-on">
																		Declined on{' '}
																		{formatRequestDate(ticket.changed_date)}
																	</div>
																)}

																{ticket.status ===
																	ReservationConstants.REQUEST_STATUS.APPROVED && (
																	<div className="approved-on">
																		Approved on{' '}
																		{formatRequestDate(ticket.changed_date)}
																	</div>
																)}

																{ticket.status ===
																	ReservationConstants.REQUEST_STATUS.PENDING && (
																	<div className="approvable-until">
																		Please respond before{' '}
																		{formatRequestDate(ticket.expiration_date)}
																	</div>
																)}

																{isRequestActionable(ticket) && (
																	<div className="approve-or-reject">
																		{reservation.status ===
																		ReservationConstants.RESERVATION_STATUS
																			.REQUESTED ? (
																			<a
																				className="action approve"
																				href={ticket.action_url}
																				target="_"
																			>
																				Book
																			</a>
																		) : (
																			<a
																				href="#!"
																				className="action approve"
																				onClick={() => handleApprove(item)}
																			>
																				Approve
																			</a>
																		)}{' '}
																		|{' '}
																		<a
																			href="#!"
																			className="action reject"
																			onClick={() => handleDecline(item)}
																		>
																			Decline
																		</a>
																	</div>
																)}
															</div>
														</TableCell>
													</TableRow>
													<TableRow className="empty-row"></TableRow>
												</React.Fragment>
											)}
										</React.Fragment>
									);
								})}
							</TableBody>

							<TableFooter>
								<TableRow>
									<TablePagination
										rowsPerPageOptions={[BookingsConstants.BOOKINGS_LIMIT]}
										count={bookingsCount}
										rowsPerPage={rows_per_page}
										page={page}
										SelectProps={{
											inputProps: {
												'aria-label': 'rows per page',
											},
											native: true,
										}}
										onPageChange={handleChangePage}
										ActionsComponent={TablePaginationActions}
										showFirstButton={false}
										showLastButton={false}
									/>
								</TableRow>
							</TableFooter>
						</Table>
					</TableContainer>
				)}

				{status === BookingsConstants.STATUS.SUCCESS && !hasTags() && bookings.length === 0 && (
					<div className="no-bookings">
						<NoBookingsIcon />
						<div>
							It seems that there are no active requests at the moment.
							<br />
							If you’re looking for past requests, you can check the "Show Archived" option.
						</div>
					</div>
				)}

				{status === BookingsConstants.STATUS.SUCCESS && hasTags() && bookings.length === 0 && (
					<div className="no-bookings">
						<NoBookingsFilterIcon />
						<div>We did not find any results for those filters!</div>
					</div>
				)}
			</div>
		</React.Fragment>
	);
};

export default RequestsTable;
