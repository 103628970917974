import React from 'react';

import FiltersActions from '../../../../actions/FiltersActions';

import FiltersConstants from '../../../../constants/FiltersConstants';
import Analytics from 'arbitrip-common/client/analytics';

import FilterCheckboxWithLabel from './components/FilterCheckboxWithLabel.react';

class MarginsFilter extends React.Component {
	constructor(props) {
		super(props);

		this.handleCheckboxChangeByMargin = this.handleCheckboxChangeByMargin.bind(this);
		this.handleMarginOnlyClick = this.handleMarginOnlyClick.bind(this);
		this.handleResetMarginsClick = this.handleResetMarginsClick.bind(this);
	}

	handleCheckboxChangeByMargin = (event, isInputChecked, margin) => {
		const { profile } = this.props;

		event.persist();
		FiltersActions.filterByMargin(margin, isInputChecked);
		setTimeout(() => {
			FiltersActions.filterByMargin(margin, isInputChecked);
		}, 10);

		const filter_value = (isInputChecked ? 'Filter by "' : 'Don\'t filter by"') + margin + '"';
		Analytics.actions.interactions.filteredResults(profile, FiltersConstants.FILTER_METHOD.MARGIN, filter_value);
	};

	handleMarginOnlyClick = (event) => {
		const { profile } = this.props;

		event.persist();
		let margin_only = event.target.getAttribute('data-margin');
		FiltersActions.filterByMarginOnly(margin_only);
		setTimeout(() => {
			FiltersActions.filterByMarginOnly(margin_only);
		}, 10);

		const filter_value = 'Show Only "' + margin_only + '" Margin';
		Analytics.actions.interactions.filteredResults(
			profile,
			FiltersConstants.FILTER_METHOD.MARGIN_ONLY,
			filter_value,
		);
	};

	handleResetMarginsClick = (event) => {
		const { profile } = this.props;

		event.persist();
		FiltersActions.resetMarginsFilter();
		setTimeout(FiltersActions.resetMarginsFilter, 10);

		const filter_value = 'Reset Margins Filter';
		Analytics.actions.interactions.filteredResults(
			profile,
			FiltersConstants.FILTER_METHOD.RESET_MARGINS_FILTER,
			filter_value,
		);
	};

	render() {
		const data = this.props.filter;
		const multiple_margins = Object.keys(data).length > 1;

		const MARGINS = [];
		for (let margin in data) {
			const margin_key = margin.toLowerCase().replace(' ', '-');
			MARGINS.push(
				<div className="checkbox-container" key={margin_key}>
					<FilterCheckboxWithLabel
						checked={data[margin]}
						category={margin}
						label={margin}
						onChange={(e, c, margin) => this.handleCheckboxChangeByMargin(e, c, margin)}
					/>
					{multiple_margins ? (
						<button className="btn btn-link" data-margin={margin} onClick={this.handleMarginOnlyClick}>
							Only
						</button>
					) : null}
				</div>,
			);
		}

		return (
			<div className="filter disabled">
				<label className="filter-title" htmlFor="margins-filter">
					Margins
				</label>
				<div name="margins-filter" className="check-list">
					{MARGINS}
				</div>
				{MARGINS.length > 1 ? <div className="clear-button" onClick={this.handleResetMarginsClick} /> : null}
			</div>
		);
	}
}

export default MarginsFilter;
