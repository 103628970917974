const keyMirror = require('keymirror');

export const EVENTS = keyMirror({
    VIEWED_SEARCH_PAGE: null,
    VIEWED_RESULTS_PAGE: null,
    VIEWED_HOTEL_PAGE: null,
    VIEWED_RESERVATION_PAGE: null,
    VIEWED_ROOM_INFO_DIALOG: null,
    VIEWED_ROOM_INFO_DIALOG_FROM_SIDEMENU: null,
    VIEWED_BOOKINGS_PAGE: null,
    VIEWED_BOOKING_REQUESTS_PAGE: null,
    VIEWED_DASHBOARD_PAGE: null,
    VIEWED_MANAGEMENT_PAGE: null,
    VIEWED_TRIP_REQUEST_PAGE: null,
    OPEN_REVIEWS_MODAL: null,
    OPEN_HOTEL_DETAILS_MODAL: null,
    VIEWED_TERMS_OF_USE: null,
    VIEWED_CONTACT_PAGE: null,
    VIEWED_FAQ_PAGE: null,
    VIEWED_TRAVEL_POLICY_PAGE: null,

    OPEN_CHOOSE_LANGUAGE: null,
    OPEN_DESTINATION_AUTOCOMPLETE: null,
    OPEN_DISPLAY_CURRENCY: null,
    OPEN_DATE_PICKER: null,
    OPEN_SEARCH_DATA_WINDOW: null,
    OPEN_BOOKINGS_FILTER: null,
    OPEN_BOOKINGS_ITEM: null,
    OPEN_DEALS_FILTER: null,
    OPEN_HOTEL_GALLERY: null,
    OPEN_CONTACT_DETAILS: null,
    OPEN_TRAVELER_DIALOG: null,
    OPEN_TERMS_OF_USE_DIALOG: null,
    OPEN_POINTS_ONLY_PAYMENT_DIALOG: null,
    OPEN_CREDIT_CARD_PAYMENT_DIALOG: null,
    OPEN_CHANGE_SEARCH_DIALOG: null,
    OPEN_RESULTS_FILTER: null,
    OPEN_RESULTS_MAP: null,
    CLOSE_RESULTS_MAP: null,
    OPEN_HOTEL_MAP: null,
    OPEN_HOTELS_MAP: null,
    OPEN_CANCEL_RESERVATION_DIALOG: null,
    OPEN_RESULTS_EXPIRED_DIALOG: null,
    OPEN_NON_REFUNDABLE_DIALOG: null,
    OPEN_PROCESSING_RESERVATION_DIALOG: null,
    OPEN_FORBIDDEN_DESTINATION_DIALOG: null,
    OPEN_DOUBLE_BOOKING_DIALOG: null,

    OPEN_SENDING_REQUEST_TO_REGIONAL_ADMIN_DIALOG: null,
    OPEN_REQUEST_HAD_SENT_TO_REGIONAL_ADMIN_DIALOG: null,
    OPEN_ERROR_WITH_CREDIT_CARD_DIALOG: null,
    OPEN_TTL_ERROR_DIALOG: null,
    OPEN_PAY_ONLY_WITH_BANK_HAPOALIM_CREDIT_CARD_DIALOG: null,
    OPEN_CARD_INSUFFICIENT_POINTS_ERROR_DIALOG: null,
    OPEN_INSTALLMENTS_ERROR_DIALOG: null,
    OPEN_NOT_ENOUGH_POINTS_DIALOG: null,
    OPEN_SPESIAL_REQUEST_SUGGESTIONS_DIALOG: null,
});