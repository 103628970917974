import classNames from 'classnames';

import React from 'react';

import SearchBar from '../../search/SearchBar.react';
import ProfileStore from '../../../stores/ProfileStore';
import SearchStore from '../../../stores/SearchStore';

import Analytics from 'arbitrip-common/client/analytics';
import RouterWrapper from '../../../utils/RouterWrapper';

import DestinationsBox from './destination/DestinationsBox.react';
import LoyaltyBox from './LoyaltyBox.react';
import RecentSearchesBox from './RecentSearchesBox.react';
import LeisureDestinations from './LeisureDestinations.react';
import { withRouter } from '../../../utils/withRouter';
import PointsStore from '../../../stores/PointsStore';
import RecentSearchesActions from '../../../actions/RecentSearchesActions';

import TitleManager from '../../../utils/TitleManager';
import DocumentTitle from '../../general/DocumentTitle.react';

import companyIds from 'arbitrip-common/theme/mapping';
import _ from 'lodash';

function getState() {
	const points = PointsStore.getPoints();
	const profile = ProfileStore.getProfile();

	return {
		profile,
		search_terms: SearchStore.getSearchTerms(),

		points_status: PointsStore.getStatus(),
		points_balance: _.get(points, 'balance', null),
		points_upcoming_value: _.get(points, 'upcoming_points_value', null),
		points_upcoming_timestamp: _.get(points, 'upcoming_points_timestamp', null),
		points,

		search_page_title: TitleManager.buildDefaultPageTitle({ profile, page: 'Search' }),
	};
}

// Define main Controller View
class SearchPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = getState();

		this._onChange = this._onChange.bind(this);
	}

	componentDidMount() {
		const { navigate } = this.props;
		let { profile } = this.state;
		let company_settings = profile.company.settings;

		if (company_settings.management_enabled) {
			if (!profile.travel_manager && !company_settings.employees_hotel_booking_enabled) {
				return RouterWrapper.goToTripsPage(navigate);
			}
		}

		RecentSearchesActions.getRecentSearches();
		SearchStore.addChangeListener(this._onChange);
		ProfileStore.addChangeListener(this._onChange);
		PointsStore.addChangeListener(this._onChange);
		window.scrollTo(0, 0);

		Analytics.actions.views.searchPage(profile);
	}

	componentWillUnmount() {
		PointsStore.removeChangeListener(this._onChange);
		ProfileStore.removeChangeListener(this._onChange);
		SearchStore.removeChangeListener(this._onChange);
	}

	render() {
		const {
			profile,
			search_terms,
			points_status,
			points_balance,
			points_upcoming_value,
			points_upcoming_timestamp,
			points,
			search_page_title,
		} = this.state;
		const { contract_id, private_travel } = search_terms;
		// const loyalty_program = profile.settings && profile.settings.loyalty_program && profile.settings.loyalty_program.enabled;

		const isHTZ = companyIds[profile.company.id] === 'HitechZone';
		const isLetStay = companyIds[profile.company.id] === 'LetStay';

		return (
			<DocumentTitle title={search_page_title}>
				<div className="search-page">
					<div className={classNames('higher', { private: private_travel, htz: isHTZ, letstay: isLetStay })}>
						<div className="gradient"></div>
						<SearchBar searchTerms={search_terms} profile={profile} />
					</div>

					<div className={classNames('lower', private_travel ? 'private' : '')}>
						<div className="lower-content">
							{ProfileStore.isLeisureExperienceEnabled() && <LeisureDestinations profile={profile} />}

							{!ProfileStore.isLeisureExperienceEnabled() && (
								<div className="actions-container">
									{profile.loyalty.enabled ? (
										<div className="loyalty">
											<LoyaltyBox
												status={points_status}
												balance={points_balance}
												upcomingValue={points_upcoming_value}
												upcomingTimestsamp={points_upcoming_timestamp}
												points={points}
												profile={profile}
											/>
										</div>
									) : (
										<div className="recent-search">
											<RecentSearchesBox />
										</div>
									)}
									<div className="business-destinations">
										{<DestinationsBox contractId={contract_id} />}
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</DocumentTitle>
		);
	}

	// Method to setState based upon Store changes
	_onChange() {
		this.setState(getState());
	}
}

export default withRouter(SearchPage);
