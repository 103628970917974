import React from 'react';
import { IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

class TravelBoosterTitle extends React.Component {
	constructor(props) {
		super(props);

		this.displayName = 'TravelBoosterTitle';
	}

	render() {
		const { onClose } = this.props;

		return (
			<div className="travel-booster-title">
				{/* <div className='title-text'>
                    Sync Travel Booster
                </div> */}
				<div className="title-logo">
					<img src="/img/travelbooster.svg" alt="Travel Booster" />
				</div>
				<IconButton aria-label="close" onClick={onClose} className="close-button" size="large">
					<CloseIcon />
				</IconButton>
			</div>
		);
	}
}

export default TravelBoosterTitle;
