import AppDispatcher from '../dispatcher/AppDispatcher';
import SEARCH_CONSTANTS from 'arbitrip-common/general/constants/search-constants';
import Ajax from '../utils/Ajax';
// import Entities from '../entities/Entities';
import moment from 'moment';
import _ from 'lodash';

import SearchStore from '../stores/SearchStore';
import ResultsStore from '../stores/ResultsStore';
import ProfileStore from '../stores/ProfileStore';

import ResultsConstants from '../constants/ResultsConstants';
import FilterConstants from '../constants/FiltersConstants';
import HotelConstants from '../constants/HotelConstants';
import DealConstants from '../constants/DealConstants';
import ReservationConstants from '../constants/ReservationConstants';
import PaymentConstants from '../constants/PaymentConstants';
import MapConstants from '../constants/MapConstants';
import AppGridActions from '../actions/AppGridActions';

import Analytics from 'arbitrip-common/client/analytics';
import ProfileConstants from '../constants/ProfileConstants';
import * as Sentry from '@sentry/react';
import { WebStorageManager } from 'arbitrip-common/client/utils';

let last_search = {};
let last_hotels_search = {};

function clearSpecificSearch(search_type) {
	if (last_search[search_type] && last_search[search_type].req && last_search[search_type].status === 'running') {
		console.warn(
			'new search called before last ended, aborting ' + search_type + ' hotels req (%s)',
			last_search.uid,
		);
		last_search[search_type].req.abort();
		last_search[search_type].status = 'aborted';
	}
}

const SearchActions = {
	updateStatus: function (data) {
		AppDispatcher.handleAction({
			actionType: ResultsConstants.UPDATE_STATUS,
			data: data, // ???
		});
	},

	// Receive initial search results data
	getResults: function (data) {
		AppDispatcher.handleAction({
			actionType: ResultsConstants.RECEIVE_SEARCH_RESULTS_DATA,
			data: data, // ???
		});
	},

	getFireResults: function (res, token) {
		AppDispatcher.handleAction({
			actionType: SEARCH_CONSTANTS.FIRE_SEARCH_RESULTS,
			data: {
				res,
				token,
			},
		});
	},

	getHotelsResults: function (res, token) {
		AppDispatcher.handleAction({
			actionType: SEARCH_CONSTANTS.HOTELS_SEARCH_RESULTS,
			data: {
				res,
				token,
			},
		});
	},

	getTravelPolicy: function (data) {
		AppDispatcher.handleAction({
			actionType: ResultsConstants.RECEIVE_TRAVEL_POLICY_DATA,
			data: data, // ???
		});
	},

	errorOnSearch: function (search_type) {
		AppDispatcher.handleAction({
			actionType: ResultsConstants.ERROR_ON_SEARCH,
			data: {
				search_type: search_type,
			},
		});
	},

	customErrorOnSearch: function (custom_error, search_type) {
		AppDispatcher.handleAction({
			actionType: ResultsConstants.ERROR_ON_SEARCH,
			data: {
				custom_error: custom_error,
				search_type: search_type,
			},
		});
	},

	clearFireSearch: function () {
		AppDispatcher.handleAction({
			actionType: SEARCH_CONSTANTS.CLEAR_FIRE_SEARCH,
		});
	},

	// Receive initial search results data
	clearResults: function () {
		AppDispatcher.handleAction({
			actionType: ResultsConstants.CLEAR_SEARCH_RESULTS,
		});

		AppDispatcher.handleAction({
			actionType: HotelConstants.CLEAR_HOTEL_DATA,
		});
		AppDispatcher.handleAction({
			actionType: DealConstants.CLEAR_DEAL_DATA,
		});
		AppDispatcher.handleAction({
			actionType: ReservationConstants.CLEAR_RESERVATION,
		});
		AppDispatcher.handleAction({
			actionType: PaymentConstants.CLEAR_PAYMENT_DATA,
		});
		AppDispatcher.handleAction({
			actionType: MapConstants.CLEAR_MAP_DATA,
		});
		AppDispatcher.handleAction({
			actionType: ResultsConstants.CLEAR_TRAVEL_POLICY,
		});
		AppDispatcher.handleAction({
			actionType: ProfileConstants.TOGGLE_ARBITRIP_POINTS_APPLIED,
			data: { applied: true },
		});
	},

	getSearchTerms: function (data) {
		AppDispatcher.handleAction({
			actionType: SEARCH_CONSTANTS.RECEIVE_SEARCH_TERMS_DATA,
			data: data, // ???
		});
	},

	updateSearchData(data) {
		AppDispatcher.handleAction({
			actionType: SEARCH_CONSTANTS.UPDATE_SEARCH_DATA,
			data: data, // ???
		});
	},

	updateDestination: function (data) {
		AppDispatcher.handleAction({
			actionType: SEARCH_CONSTANTS.UPDATE_SEARCH_DESTINATION_DATA,
			data: data, // ???
		});
	},

	updateCheckIn: function (data) {
		AppDispatcher.handleAction({
			actionType: SEARCH_CONSTANTS.UPDATE_CHECK_IN,
			data: data,
		});
	},

	updateCheckOut: function (data) {
		AppDispatcher.handleAction({
			actionType: SEARCH_CONSTANTS.UPDATE_CHECK_OUT,
			data: data,
		});
	},

	updateGuests: function (data) {
		AppDispatcher.handleAction({
			actionType: SEARCH_CONSTANTS.UPDATE_GUESTS,
			data: data,
		});
	},

	updateRooms: function (data) {
		AppDispatcher.handleAction({
			actionType: SEARCH_CONSTANTS.UPDATE_ROOMS,
			data: data,
		});
	},

	updateChildrenAges: function (data) {
		AppDispatcher.handleAction({
			actionType: SEARCH_CONSTANTS.UPDATE_CHILDREN_AGES,
			data: data,
		});
	},

	updateSearchForCompany: function (data) {
		AppDispatcher.handleAction({
			actionType: SEARCH_CONSTANTS.UPDATE_SEARCH_CONTRACT,
			data: data, // ???
		});
	},

	updateSearchContractById: function () {
		AppDispatcher.handleAction({
			actionType: SEARCH_CONSTANTS.UPDATE_SEARCH_CONTRACT_BY_ID,
		});
	},

	updatePrivateTravel: function (data) {
		AppDispatcher.handleAction({
			actionType: SEARCH_CONSTANTS.UPDATE_PRIVATE_TRAVEL,
			data: data,
		});
	},

	updateSearchTerms: function (data) {
		AppDispatcher.handleAction({
			actionType: SEARCH_CONSTANTS.UPDATE_ALL_SEARCH_TERMS_DATA,
			data: data, // ???
		});
	},

	updateSearchTermsViaUrl: function (data) {
		AppDispatcher.handleAction({
			actionType: SEARCH_CONSTANTS.UPDATE_ALL_SEARCH_TERMS_DATA_VIA_URL,
			data: data, // ???
		});
	},

	showHotelLoader: function (data) {
		AppDispatcher.handleAction({
			actionType: SEARCH_CONSTANTS.SHOW_HOTEL_LOADER,
			data: data,
		});
	},

	clearChildrenData: function () {
		AppDispatcher.handleAction({
			actionType: SEARCH_CONSTANTS.CLEAR_CHILDREN_DATA,
		});
	},

	clearSearchTerms: function () {
		AppDispatcher.handleAction({
			actionType: SEARCH_CONSTANTS.CLEAR_ALL_SEARCH_TERMS_DATA,
		});
	},

	////////////////////////////////////////

	clearSearch: function () {
		clearSpecificSearch(SEARCH_CONSTANTS.SEARCH_TYPE_FAST);
		clearSpecificSearch(SEARCH_CONSTANTS.SEARCH_TYPE_NORMAL);
		clearSpecificSearch(SEARCH_CONSTANTS.SEARCH_TYPE_SHALLOW);
	},

	search: async function (searchToken, options = {}) {
		// AppGridActions.setAppStatusAsPending();
		if (searchToken) {
			try {
				const session_manager = ResultsStore.getSessionManager();

				this.clearResults();
				this.clearSearch();
				this.clearFireSearch();

				const recommendation_weights = ProfileStore.getRecommendationWeights();
				const search_session = await session_manager.getSearchSessionFromOfflineDB(
					searchToken,
					recommendation_weights,
				);
				if (search_session) {
					AppDispatcher.handleAction({
						actionType: ResultsConstants.SET_RESULTS_FROM_OFFLINE_DB,
						data: search_session,
					});
					setTimeout(AppGridActions.setAppStatusAsIdle);
				} else {
					if (!options.forceCache) {
						this.executeFullSearch(null, options);
					} else {
						setTimeout(AppGridActions.setAppStatusAsIdle);
						// TODO: is this ok?
					}
				}
			} catch (err) {
				console.log('!@# SearchActions.search *ERROR*');
				console.error(err);
				Sentry.captureException(err);
			}
		} else {
			this.clearResults();
			this.clearSearch();
			this.clearFireSearch();

			this.executeFullSearch(null, options);
		}

		AppGridActions.setAppStatusAsIdle();
	},

	executeFullSearch: function (searchToken, options) {
		WebStorageManager.removeFromWebStorage('_filtered_hotel');
		const search_token = searchToken || SearchStore.newToken();
		Analytics.actions.global_properties.setItem('search_token', search_token);

		const search_terms = SearchStore.getSearchTerms();
		AppDispatcher.handleAction({
			actionType: SEARCH_CONSTANTS.FIRE_SEARCH,
			data: {
				search_token,
				search_terms,
			},
		});

		// AppGridActions.setAppStatusAsPending();

		this.clearResults();
		this.clearFireSearch();

		AppDispatcher.handleAction({
			actionType: FilterConstants.CLEAR_FILTERS,
		});

		if (last_search.req && last_search.status === 'running') {
			console.warn('new search called before last ended, aborting... hotels req (%s)', last_search.uid);
			last_search.req.abort();
			last_search.status = 'aborted';
		}

		if (last_hotels_search.req && last_hotels_search.status === 'running') {
			console.warn(
				'new hotels search called before last ended, aborting... hotels req (%s)',
				last_hotels_search.uid,
			);
			last_hotels_search.req.abort();
			last_hotels_search.status = 'aborted';
		}

		const _uid = (0 | (Math.random() * 9e6)).toString(36);
		last_search.uid = last_hotels_search.uid = _uid;

		if (_uid === last_search.uid) {
			if (last_search.status === 'running') {
				SearchActions.updateStatus({ status: 'waiting' });
			} else {
				SearchActions.updateStatus({ status: 'finished' });
			}
		}

		if (_uid === last_hotels_search.uid) {
			if (last_hotels_search.status === 'running') {
				SearchActions.updateStatus({ status: 'waiting' });
			} else {
				SearchActions.updateStatus({ status: 'finished' });
			}
		}

		last_search = last_hotels_search = {
			status: 'running',
			init_time: Date.now(),
		};

		this.executeFireSearch(search_token, options); // representative deals through firebase
		// this.executeFireHotels(search_token); // shallow // commented for now
		// }
	},

	executeFireSearch: function (token, options) {
		// const payload = SearchStore.toAjaxPayload(SEARCH_CONSTANTS.SEARCH_TYPE_SHALLOW);
		const payload = SearchStore.toAjaxPayload(SEARCH_CONSTANTS.SEARCH_TYPE_SELECTED_HOTELS_WITH_DEALS);
		if (Object.keys(options).includes('log_search')) {
			payload.log_search = options.log_search;
		}
		const fire_search_timestamp = moment();
		last_search.req = Ajax.executeSearch(payload)
			.done((res) => {
				const duration = moment.duration(moment().diff(fire_search_timestamp)).asMilliseconds() / 1000;
				console.log(`Got hotels from search request - time it took: ${duration}s`);
				// AppDispatcher.handleAction({
				//   actionsType: SEARCH_CONSTANTS.FIRE_SEARCH_RESULTS,
				//   data: {
				//     res,
				//     token
				//   }
				// });

				// const results = new Entities.SearchResults(res.hotels, token);
				// SearchActions.getResults(results);
				SearchActions.getFireResults(res, token);

				last_search.wait_duration = Math.round(
					moment.duration(Date.now() - last_search.init_time).asMilliseconds(),
				);
				const wait_time_ml = last_search.wait_duration;

				Analytics.actions.responses.fireResults(_.get(res, 'hotels.length'), wait_time_ml);
			})
			.fail((err) => {
				AppDispatcher.handleAction({
					actionsType: SEARCH_CONSTANTS.FIRE_SEARCH_ERROR,
					error: err,
				});
				AppGridActions.setAppStatusAsIdle();
			})
			.always((resOrErr) => {
				AppGridActions.setAppStatusAsIdle();
			});
	},

	executeFireHotels: function (token) {
		const hotels_payload = SearchStore.toAjaxPayload(SEARCH_CONSTANTS.SEARCH_TYPE_HOTELS);
		const fire_hotels_timestamp = moment();
		last_hotels_search.req = Ajax.executeHotelsSearch(hotels_payload)
			.done((res) => {
				const duration = moment.duration(moment().diff(fire_hotels_timestamp)).asMilliseconds() / 1000;
				console.log(`Got hotels from shallow request - time it took: ${duration}s`);
				SearchActions.getHotelsResults(res, token);

				last_hotels_search.wait_duration = Math.round(
					moment.duration(Date.now() - last_hotels_search.init_time).asMilliseconds(),
				);
				const wait_time_ml = last_hotels_search.wait_duration;
				Analytics.actions.responses.hotelsResults(_.get(res, 'hotels.length'), wait_time_ml);
			})
			.fail((err) => {
				AppDispatcher.handleAction({
					actionsType: SEARCH_CONSTANTS.HOTELS_SEARCH_ERROR,
					error: err,
				});
			});
		// .always(resOrErr => {
		//   AppGridActions.setAppStatusAsIdle();
		// });
	},
};

export default SearchActions;
