/* eslint-disable react/prop-types, react/jsx-handler-names */
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import { FormControl, FormHelperText, Typography, TextField, Paper, MenuItem } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Select from 'react-select';
import Ajax from '../../utils/Ajax';
import ProfileEntity from '../../entities/Profile';

import classNames from 'classnames';

const styles = (theme) => ({
	root: {
		flexGrow: 1,
		width: '100%',
	},
	input: {
		display: 'flex',
		padding: '0px 14px',
		marginBottom: 2,
		height: 'auto',
	},
	valueContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		flex: 1,
		alignItems: 'center',
		overflow: 'hidden',
	},
	noOptionsMessage: {
		padding: '8px 16px',
	},
	singleValue: {
		fontSize: 14,
	},
	placeholder: {
		position: 'absolute',
		left: 2,
		right: 70,
		fontSize: 14,
		fontWeight: 'bold',
	},
	loader: {
		position: 'absolute',
		top: 8,
		right: 48,
	},
	paper: {
		position: 'absolute',
		zIndex: 1,
		marginTop: 8,
		left: 0,
		right: 0,
	},
	divider: {
		height: 16,
	},
	busyLoader: {
		position: 'absolute',
		top: 18,
		right: 0,
	},
	label: {
		fontSize: 14,
		fontWeight: 'bold',
		color: 'rgba(0, 0, 0, 0.54)',
		transform: 'translate(0, 1.5px) scale(0.75)',
		transformOrigin: 'top left',
		transition: 'color 200ms',
	},
	labelHover: {
		color: theme.palette.primary.main,
	},
	error: {
		color: theme.palette.error.main,
	},
});

const inputComponent = React.forwardRef((props, ref) => <div ref={ref} {...props} />);

class EmployeesAutocomplete extends React.Component {
	constructor(props) {
		super(props);

		this.setValueLabel();

		this.state = {
			value: props.value || null,
			data: [],
			busy: false,
			hoverLabel: false,
			blur: false,
		};

		this.Control = this.Control.bind(this);
		this.ValueContainer = this.ValueContainer.bind(this);
		this.getData = this.getData.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.toggleFocus = this.toggleFocus.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.updateData = this.updateData.bind(this);
	}

	setValueLabel() {
		let { value } = this.props;

		if (value) {
			value.label = this.getValueLabel(value);
		}
	}

	getValueLabel(user) {
		return user.full_name + (user.email ? ' (' + user.email + ')' : '');
	}

	handleChange(value) {
		let { onUpdate } = this.props;

		this.setState({ value });

		if (_.isFunction(onUpdate)) {
			onUpdate(value);
		}
	}

	getData(event) {
		let { agentContractId, agentClientCompanyId } = this.state;
		let value = event.target.value;

		this.setState({ busy: true });

		Ajax.companyEmployeesLookup(value, null, agentContractId, agentClientCompanyId)
			.done((data) => {
				console.log('Travelers AutoComplete Search Results', data);
				if (value) {
					this.updateData(data);
				} else {
					console.log('box is empty so results are not stored');
					this.updateData([]);
				}
			})
			.fail((err) => {
				console.log('Travelers AutoComplete Search Error', err);
			})
			.always(() => {
				this.setState({
					busy: false,
				});
			});
	}

	updateData(data) {
		if (!Array.isArray(data)) {
			return false;
		}

		this.setState({
			data: data.map((user) => Object.assign(new ProfileEntity(user), { label: this.getValueLabel(user) })),
		});
	}

	Control(props) {
		return (
			<TextField
				onChange={this.getData}
				fullWidth
				InputProps={{
					inputComponent,
					inputProps: {
						className: props.selectProps.classes.input,
						children: props.children,
						...props.innerProps,
					},
				}}
				{...props.selectProps.textFieldProps}
			/>
		);
	}

	NoOptionsMessage(props) {
		return (
			<Typography
				color="textSecondary"
				className={props.selectProps.classes.noOptionsMessage}
				{...props.innerProps}
			>
				Type name or email...
			</Typography>
		);
	}

	Option(props) {
		return (
			<MenuItem
				selected={props.isFocused}
				component="div"
				style={{
					fontWeight: props.isSelected ? 500 : 400,
				}}
				{...props.innerProps}
			>
				{props.children}
			</MenuItem>
		);
	}

	Placeholder(props) {
		return (
			<Typography className={props.selectProps.classes.placeholder} color="textSecondary" {...props.innerProps}>
				{props.children}
			</Typography>
		);
	}

	SingleValue(props) {
		return (
			<Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
				{props.children}
			</Typography>
		);
	}

	ValueContainer(props) {
		let { busy } = this.state;

		return (
			<div className={props.selectProps.classes.valueContainer}>
				{props.children}
				{busy && (
					<div className={props.selectProps.classes.loader}>
						<i className="fa fa-spin fa-spinner busy-indicator" />
					</div>
				)}
			</div>
		);
	}

	Menu(props) {
		return (
			<Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
				{props.children}
			</Paper>
		);
	}

	onBlur() {
		this.toggleFocus();
		this.setState({ blur: true });
	}

	toggleFocus() {
		let { hoverLabel } = this.state;

		this.setState({ hoverLabel: !hoverLabel });
	}

	render() {
		const { data, value, hoverLabel, blur } = this.state;
		const { classes, theme, label, required, touched } = this.props;

		let showError = required && (blur || touched) && !value;

		const selectStyles = {
			input: (base) => ({
				...base,
				color: theme.palette.text.primary,
				'& input': {
					font: 'inherit',
				},
			}),
			clearIndicator: (provided) => ({
				...provided,
				cursor: 'pointer',
				padding: '6px 8px',
			}),
			dropdownIndicator: (provided) => ({
				...provided,
				cursor: 'pointer',
				padding: '6px 8px',
			}),
		};

		const components = {
			Control: this.Control,
			Menu: this.Menu,
			NoOptionsMessage: this.NoOptionsMessage,
			Option: this.Option,
			Placeholder: this.Placeholder,
			SingleValue: this.SingleValue,
			ValueContainer: this.ValueContainer,
		};

		return (
			<FormControl className={classes.root} error={showError}>
				{label && (
					<label
						htmlFor="employee_autocomplete"
						className={classNames(
							classes.label,
							hoverLabel ? classes.labelHover : '',
							showError ? classes.error : '',
						)}
					>
						{label}
					</label>
				)}
				<Select
					inputId="employee_autocomplete"
					classes={classes}
					styles={selectStyles}
					options={data}
					components={components}
					value={value}
					onChange={this.handleChange}
					onFocus={this.toggleFocus}
					onBlur={this.onBlur}
					placeholder=""
					isClearable
					filterOption={() => true}
				/>
				{showError && <FormHelperText>Field is required</FormHelperText>}
			</FormControl>
		);
	}
}

EmployeesAutocomplete.propTypes = {
	value: PropTypes.object,
	agentContractId: PropTypes.string,
	agentClientCompanyId: PropTypes.string,
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
	onUpdate: PropTypes.func,
	label: PropTypes.string,
	required: PropTypes.bool,
	touched: PropTypes.bool,
};

export default withStyles(styles, { withTheme: true })(EmployeesAutocomplete);
