import ContentLoader from 'react-content-loader';

const HOTEL_NAME_LEFT_X = 300;
const CONTAINER_WIDTH = 824;
const CONTAINER_HEIGHT = 240;
const PADDING = 24;

const HotelLoader = () => (
	<div className="result">
		<div className="hotel">
			<div className="hotel-inner-container">
				<ContentLoader
					width={CONTAINER_WIDTH}
					height={CONTAINER_HEIGHT}
					viewBox={`0 0 ${CONTAINER_WIDTH} ${CONTAINER_HEIGHT}`}
				>
					{/* image */}
					<path d="M0 0 H280 V230 A10 10 0 0 1 280 240 H10 A10 10 0 0 1 0 230 V10 A10 10 0 0 1 10 0" />

					{/* review */}
					<rect x={HOTEL_NAME_LEFT_X} y="24" rx="0" yx="0" width="248" height="13" />

					{/* name */}
					<rect x={HOTEL_NAME_LEFT_X} y="42" rx="0" yx="0" width="355" height="22" />

					{/* address */}
					<rect x={HOTEL_NAME_LEFT_X} y="71" rx="0" yx="0" width="258" height="13" />

					{/* reasons */}
					<circle cx="305" cy="108" r="5" />
					<rect x="315" y="103" rx="0" yx="0" width="300" height="10" />
					<circle cx="305" cy="126" r="5" />
					<rect x="315" y="118" rx="0" yx="0" width="250" height="10" />
					<circle cx="305" cy="141" r="5" />
					<rect x="315" y="133" rx="0" yx="0" width="275" height="10" />

					{/* amenities */}
					{/* <circle cx="305" cy="205" r="5" />
                    <rect x="315" y="200" rx="0" yx="0" width="200" height="10" /> */}

					{/* price */}
					<rect x={CONTAINER_WIDTH - 56 - PADDING} y="24" rx="0" ry="0" width="56" height="23" />

					{/* per night */}
					<rect x={CONTAINER_WIDTH - 52 - PADDING} y="53" rx="0" ry="0" width="52" height="13" />

					{/* policy */}
					<rect x={CONTAINER_WIDTH - 60 - PADDING} y="77" rx="0" ry="0" width="60" height="13" />

					{/* action */}
					<rect
						x={CONTAINER_WIDTH - 100 - PADDING}
						y={CONTAINER_HEIGHT - 31 - PADDING}
						rx="4"
						ry="4"
						width="99"
						height="31"
					/>
				</ContentLoader>
			</div>
		</div>
	</div>
);

export default HotelLoader;
