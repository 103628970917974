import _ from 'lodash';
import $ from 'jquery';

import React, { Component } from 'react';
import { getRoundedPriceWithCurrency } from 'arbitrip-common/general/utils/Currencies';

const styles = {
	component: {
		position: 'absolute',
		// right: '64px',
		right: '96px',
		top: '-1px',
	},
	bookmark: {
		color: '#D0D1D2',
		fontSize: '24px',
		position: 'relative',
		marginLeft: '250px',
		marginTop: '-1px',
		backgroundColor: '#ccc',
		width: 128,
	},
	div: {
		position: 'absolute',
		top: -3,
		right: 8,
	},
	margin_percentage: {
		color: 'black',
		fontSize: '15px',
		// position: 'absolute',
		// left: '4px',
		// top: '2px',
		fontFamily: 'Arial',
	},
	margin_absolute: {
		color: 'black',
		fontSize: '15px',
		// position: 'absolute',
		// left: '4px',
		// top: '18px',
		fontFamily: 'Arial',
	},
	re_overlay: {
		position: 'absolute',
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		height: '100%',
		width: '100%',
		visibility: 'hidden',
		transition: 'visibility 0s, opacity 0.5s linear',
	},
	re_text: {
		color: 'white',
		fontSize: '9px',
		position: 'absolute',
		top: '100%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		MsTransform: 'translate(-50%, -50%)',
		textAlign: 'left',
		width: '100%',
		zIndex: 99999,
	},
	pre: {
		// outline: "1px solid #ccc",
		// padding: "5px",
		// margin: "5px"
	},
};

class MarginsBookmark extends Component {
	setOverlayOpacity(opacity) {
		if (opacity) {
			$('#margin-' + this.props.hotel.id).css('visibility', 'visible');
		} else {
			$('#margin-' + this.props.hotel.id).css('visibility', 'hidden');
		}
	}

	render() {
		const { hotel } = this.props;
		if (!hotel) {
			return null;
		}

		const price_list = hotel.primal_deals
			? hotel.primal_deals.map((d) => {
					const provider = d.id.split(':')[0];
					const price = getRoundedPriceWithCurrency(d.pricePerNight, d.currency);
					return `${provider}: ${price}`;
				})
			: [];

		const margin_percentage = hotel.cheapest_margin_percentage >= 0 ? hotel.cheapest_margin_percentage + '%' : '-';

		const currency = _.get(hotel, 'representativeDeal.currency');
		const margin_absolute =
			hotel.cheapest_margin_absolute >= 0
				? getRoundedPriceWithCurrency(hotel.cheapest_margin_absolute, currency)
				: '-';

		return (
			<div style={styles.component}>
				<div id={'margin-' + hotel.id} style={styles.re_overlay}>
					<div id={'re-text-' + hotel.id} style={styles.re_text}>
						<pre style={styles.pre}>{JSON.stringify(price_list, undefined, 4)}</pre>
					</div>
				</div>
				<i
					className="fa fa-bookmark"
					aria-hidden="true"
					style={styles.bookmark}
					onMouseEnter={() => this.setOverlayOpacity(1)}
					onMouseLeave={() => this.setOverlayOpacity(0)}
				>
					<div style={styles.div}>
						<span style={styles.margin_percentage}>{margin_percentage}</span> |{' '}
						<span style={styles.margin_absolute}>{margin_absolute}</span>
					</div>
				</i>
			</div>
		);
	}
}

export default MarginsBookmark;
