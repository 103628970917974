import _ from 'lodash';

import { useMemo, useState, useEffect, useRef } from 'react';
import { Button, IconButton, TextField, Stack, Chip } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import classNames from 'classnames';

import { ReactComponent as SearchIcon } from '@/img/bookings/search-b.svg';
import { ReactComponent as Calendar } from '@/img/bookings/calendar.svg';
import { ReactComponent as ArrowDownIcon } from '@/img/icons/arrow-down.svg';
import { ReactComponent as CloseIcon } from '@/img/icons/x-close-gray.svg';

import NewExportButton from './NewExportButton';
import AdvancedFiltersPopover from './AdvancedFiltersPopover';
import BookingsConstants from '@/constants/BookingsConstants';
import { getLabel, getFilterName } from './helpers/utils';

import ManagementConstants from '@/constants/ManagementConstants';
import { EXTRAS } from './consts';
const format = ManagementConstants.DATE_PICKER_FORMAT;

const NewBookingsFiltersComponent = ({
	status,
	downloadStatus,
	profile,
	bookings,

	initFilters,
	filters,
	setFilters,

	search,
	setSearch,

	initPagination,
	setPagination,

	initSort,
	setSort,

	activeFilters,
	handleDeleteTag,

	activeTab,
}) => {
	const is_agent = useMemo(() => {
		const { agent, super_agent } = profile;
		return agent || super_agent;
	}, [profile]);

	const show_export = useMemo(() => {
		const { agent, super_agent, travel_manager } = profile;
		return agent || super_agent || travel_manager;
	}, [profile]);

	const export_to_csv_disabled = useMemo(() => {
		return !bookings || bookings.length === 0 || status === BookingsConstants.STATUS.BUSY;
	}, [bookings]);

	const [startOpen, setStartOpen] = useState(false);
	const [endOpen, setEndOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const containerRef = useRef(null);
	const wrapperRef = useRef(null);
	const isPopoverOpen = Boolean(anchorEl);

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => document.removeEventListener('mousedown', handleClickOutside);
	}, []);

	const handleClickOutside = (e) => {
		if (containerRef.current && !containerRef.current.contains(e.target)) {
			setAnchorEl(null);
		}
	};

	const clearAllFilters = () => {
		setPagination(initPagination);
		setSort(initSort);
		setSearch(initFilters.search);
		setFilters(initFilters);
	};

	const onSearchClick = () => {
		if (_.isEmpty(search)) {
			return;
		}

		setPagination(initPagination);
		setSearch(initFilters.search);
		setFilters({ ...filters, search });
	};
	const onSearchEnterClick = (e) => {
		if (e.key === 'Enter') {
			onSearchClick();
		}
	};

	const onOpenPopoverClick = () => {
		setAnchorEl(wrapperRef.current);
	};

	const handleClosePopover = () => {
		setAnchorEl(null);
	};

	const filtersAreActive = _.some(filters, (value) => !_.isEmpty(value));

	const getTags = () => {
		const tags = Object.keys(filters).map((key, index) => {
			if (key === EXTRAS.AGENCY_BRANCHES) {
				return false;
			}

			const filter_name = getFilterName(key);

			const label = getLabel(key, filters);

			const labelRender = (
				<div className="tag">
					<div>{filter_name}:</div>
					<div>{label}</div>
				</div>
			);

			return (
				!_.isEmpty(filters[key]) && (
					<Chip
						label={labelRender}
						aria-label={`Delete ${filter_name} filter`}
						key={`tag-${index}`}
						onDelete={() => handleDeleteTag(key)}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								handleDeleteTag(key);
							}
						}}
						deleteIcon={<CloseIcon />}
					/>
				)
			);
		});
		const agencyBranches = filters[EXTRAS.AGENCY_BRANCHES].map((id) => {
			const filtered = profile.company_branches.filter(({ _id }) => _id === id);
			if (!filtered.length) return false;
			const branch = filtered[0];
			const labelRender = (
				<div className="tag">
					<div>{getFilterName(EXTRAS.AGENCY_BRANCHES)}:</div>
					<div>
						{branch.name}
						{branch.is_archived ? ' (Archived)' : ''}
					</div>
				</div>
			);

			return (
				<Chip
					label={labelRender}
					key={`tag-${id}`}
					onDelete={() => handleDeleteTag(EXTRAS.AGENCY_BRANCHES, id)}
					deleteIcon={<CloseIcon />}
				/>
			);
		});
		tags.push(...agencyBranches);
		return tags;
	};

	return (
		<div className={classNames('new-bookings-filters-component', { tags: filtersAreActive })}>
			<div className="new-bookings-filters-component__filters">
				<div className="new-bookings-filters-component__filter search">
					<div className="new-bookings-filters-component__search-wrapper">
						<TextField
							id="search-input"
							placeholder={
								is_agent
									? 'Find by destination, travelers, client or reservation ID'
									: 'Find by destination, travelers or reservation ID'
							}
							variant="outlined"
							InputProps={{
								endAdornment: (
									<IconButton aria-label="Search" onClick={onSearchClick}>
										<SearchIcon />
									</IconButton>
								),
							}}
							value={search}
							onChange={(e) => setSearch(e.target.value)}
							onKeyDown={onSearchEnterClick}
						/>
					</div>
				</div>

				<div className="new-bookings-filters-component__filter when">
					<LocalizationProvider
						dateAdapter={AdapterMoment}
						adapterLocale={ManagementConstants.ADAPTER_MOMENT_LOCALE}
					>
						<DesktopDatePicker
							format={format}
							onClose={() => setStartOpen(false)}
							open={startOpen}
							className="desktop-date-picker"
							slots={{
								openPickerButton: () => (
									<IconButton>
										<Calendar />
									</IconButton>
								),
							}}
							slotProps={{
								textField: {
									InputProps: {
										onClick: (e) => {
											e.preventDefault();
											setStartOpen(true);
										},
										onKeyDown: (e) => {
											if (e.key !== 'Tab') {
												e.preventDefault();
												setStartOpen(true);
											}
										},
									},
								},
							}}
							label="Check in"
							value={filters.start_date}
							onChange={(date) => {
								setFilters({
									...filters,
									start_date: date,
									// if start_date date is after end_date data, reset end_date date
									...(date.isAfter(filters.end_date) && { end_date: initFilters.end_date }),
								});
							}}
						/>
					</LocalizationProvider>
				</div>

				<div className="new-bookings-filters-component__filter when">
					<LocalizationProvider
						dateAdapter={AdapterMoment}
						adapterLocale={ManagementConstants.ADAPTER_MOMENT_LOCALE}
					>
						<DesktopDatePicker
							format={format}
							onClose={() => setEndOpen(false)}
							open={endOpen}
							className="desktop-date-picker"
							slots={{
								openPickerButton: () => (
									<IconButton>
										<Calendar />
									</IconButton>
								),
							}}
							slotProps={{
								textField: {
									InputProps: {
										onClick: (e) => {
											e.preventDefault();
											setEndOpen(true);
										},
										onKeyDown: (e) => {
											if (e.key !== 'Tab') {
												e.preventDefault();
												setStartOpen(true);
											}
										},
									},
								},
							}}
							label="Check out"
							value={filters.end_date}
							minDate={filters.start_date}
							onChange={(date) => setFilters({ ...filters, end_date: date })}
						/>
					</LocalizationProvider>
				</div>

				<div className="new-bookings-filters-component__filter advanced-search" ref={wrapperRef}>
					<Button
						id="advanced-search-button"
						endIcon={<ArrowDownIcon />}
						onClick={onOpenPopoverClick}
						disableRipple
					>
						Advanced Search
					</Button>
				</div>

				{filtersAreActive && (
					<div className="new-bookings-filters-component__filter clear">
						<Button id="clear-button" onClick={clearAllFilters} disableRipple>
							Clear All
						</Button>
					</div>
				)}

				{show_export && (
					<NewExportButton
						profile={profile}
						disabled={export_to_csv_disabled}
						downloadStatus={downloadStatus}
						activeFilters={activeFilters}
						activeTab={activeTab}
					/>
				)}
			</div>

			<div className="new-bookings-filters-component__tags">
				<Stack direction="row" spacing={1} flexWrap="wrap">
					{getTags()}
				</Stack>
			</div>

			<AdvancedFiltersPopover
				anchorEl={anchorEl}
				open={isPopoverOpen}
				onClose={handleClosePopover}
				filters={filters}
				setFilters={setFilters}
				setPagination={setPagination}
				initPagination={initPagination}
				initFilters={initFilters}
				profile={profile}
			/>
		</div>
	);
};

export default NewBookingsFiltersComponent;
