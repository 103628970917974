import React from 'react';
import createClass from 'create-react-class';
import Currencies from '../../../utils/Currencies';
import moment from 'moment';
import Icon from '../../ReactEvilIcon';
import ManagementActions from '../../../actions/ManagementActions';
import ManagementConstants from '../../../constants/ManagementConstants';

const date_format = 'MMM D';
const time_format = 'hh:mm A';

function reformatTime(time) {
	if (time) {
		let date = moment();
		let split_time = time.split(':');
		date.set({
			hour: split_time[0],
			minute: split_time[1],
		});
		return date.format(time_format);
	}

	return null;
}

const BookedAirfare = createClass({
	displayName: 'BookedAirfare',

	handleOnMouseEnter: function () {
		let { onHover } = this.props;
		if (typeof onHover === 'function') {
			onHover();
		}
	},

	removeAirfare: function () {
		let { trip, airfare, remove_trip_airfare_status } = this.props;
		if (remove_trip_airfare_status !== ManagementConstants.STATUS.BUSY) {
			ManagementActions.removeTripAirfare(trip, airfare);
		}
	},

	render: function () {
		let { airfare, hovered, remove_trip_airfare_status, can_edit, previewed_booked_component } = this.props;

		let component_classes = 'booked-item airfare';
		if (hovered && previewed_booked_component && previewed_booked_component.id === airfare.id) {
			component_classes += ' hovered';
		}

		let busy = remove_trip_airfare_status === ManagementConstants.STATUS.BUSY;

		return (
			<div className={component_classes} onMouseEnter={this.handleOnMouseEnter}>
				<div className="column icon-column">
					<div className="icon-container">
						<i className="fa fa-plane fa-fw" />
					</div>
				</div>
				<div className="column flight-number">
					<div className="line title">
						<span className="header">Flight Number</span>
					</div>
					{airfare.flights.map((flight) => {
						return (
							<div className="line" key={flight.id}>
								<span>{flight.number}</span>
							</div>
						);
					})}
				</div>
				<div className="column route">
					<div className="line title">
						<span className="header">Route</span>
					</div>
					{airfare.flights.map((flight) => {
						return (
							<div className="line" key={flight.id}>
								<span>
									{flight.depart_airport.iata_code && flight.arrival_airport.iata_code
										? flight.depart_airport.iata_code + ' > ' + flight.arrival_airport.iata_code
										: null}
								</span>
							</div>
						);
					})}
				</div>

				<div className="column departure">
					<div className="line title">
						<span className="header">Departure</span>
					</div>
					{airfare.flights.map((flight) => {
						return (
							<div className="line" key={flight.id}>
								<span className="date">{moment(flight.depart_date).format(date_format)}</span>
								<span className="time">{reformatTime(flight.depart_time)}</span>
							</div>
						);
					})}
				</div>

				<div className="column cost">
					<div className="line title">
						<span className="header">Airfare Cost</span>
					</div>
					<div className="line">
						<span>
							{airfare.cost ? Currencies.getPriceWithCurrency(airfare.cost, airfare.currency) : ''}
						</span>
					</div>
				</div>

				{hovered && can_edit ? (
					<div className={'column actions' + (busy ? ' busy' : '')}>
						{busy ? (
							<i className="fa fa-spin fa-spinner bottom-right-corner busy-indicator" />
						) : (
							<a href="#!" className="remove-airfare" onClick={this.removeAirfare}>
								<Icon name="ei-close" size="m" className="evil-icon" />
							</a>
						)}
					</div>
				) : null}
			</div>
		);
	},
});

export default BookedAirfare;
