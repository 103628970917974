// //import keyMirror from 'keymirror';
// import keyMirror from 'fbjs/lib/keyMirror';
import keyMirror from 'keymirror';

const STATUSES = {
	IDLE: 'idle',
	PENDING: 'pending_search',
	PARSING: 'parsing_url',
};

const AppGridConstants = Object.assign(
	{ STATUSES },
	keyMirror({
		LOG_ERROR: null, // After postback
		RECEIVE_APP_GRID_DATA: null, // After postback
		RECEIVE_USER_DATA: null,
		RECEIVE_COMPANY_DATA: null,
		SET_GLOBAL_APP_STATUS: null,
	}),
);

// Define action constants
export default AppGridConstants;
