import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { PAYMENT_TYPE } from 'arbitrip-common/general/constants';
import { PAYMENT_TYPE as RADIO_PAYMENT_TYPE } from './const.js';
import ReservationActions from '../../../../actions/ReservationActions';
import SettlePaymentName from './SettlePaymentName';
import SettlePaymentSummary from './SettlePaymentSummary';
import SettlePaymentChargeCurrency from './SettlePaymentChargeCurrency';
import SettlePaymentType from './SettlePaymentType';
import PaymentStore from '../../../../stores/PaymentStore';
import PaymentConstants from '../../../../constants/PaymentConstants';
import Analytics from 'arbitrip-common/client/analytics';
import helpers from './helpers';

const SettlePaymentPayNow = ({
	profile,
	booking,
	onClose,
	showEditCommission,
	chargeCurrency,
	updateChargeCurrency,
}) => {
	const [name, setName] = useState();
	const [loading, setLoading] = useState(false);
	const [paymentData, setPaymentData] = useState(PaymentStore.getPaymentData());
	const [nameLocked, setNameLocked] = useState(PaymentStore.getPaymentData());
	const [paymentType, setPaymentType] = useState();

	useEffect(() => {
		function handleStoreChange() {
			setPaymentData(PaymentStore.getPaymentData());
		}

		PaymentStore.addChangeListener(handleStoreChange);

		return () => {
			PaymentStore.removeChangeListener(handleStoreChange);
		};
	}, []);

	useEffect(() => {
		if (paymentData.status === PaymentConstants.STATUS.INITIALIZING) {
			setLoading(false);
		}
	}, [paymentData]);

	const generateCreditUrl = () => {
		setLoading(true);

		const params = {
			payment_type: PAYMENT_TYPE.PAY_LATER,
			hotel: booking.hotel,
			reservation: booking,
			charge_currency: chargeCurrency,
			invoice_name: name,
		};

		ReservationActions.payLaterReservation(profile, params);
	};

	const handlePay = () => {
		// validate the form
		if (!chargeCurrency || !name) {
			return;
		}

		const markup = helpers.getCommissionPercentage(booking);
		Analytics.actions.interactions.payNowClicked({
			bookingId: booking.id,
			currency: chargeCurrency,
			payment_method: PAYMENT_TYPE.CREDIT_CARD,
			markup: `${markup}%`,
		});

		generateCreditUrl();
	};

	const handleClose = () => {
		if (_.isFunction(onClose)) {
			onClose();
		}
	};

	return (
		<div className="settle-payment-wrap">
			<div className="settle-payment">
				<div>
					<SettlePaymentChargeCurrency
						profile={profile}
						chargeCurrency={chargeCurrency}
						updateChargeCurrency={updateChargeCurrency}
					/>
					<hr />
					<SettlePaymentSummary
						booking={booking}
						showEditCommission={showEditCommission}
						chargeCurrency={chargeCurrency}
						profile={profile}
					/>

					<SettlePaymentName booking={booking} updateName={setName} updateNameLocked={setNameLocked} />

					<SettlePaymentType
						booking={booking}
						chargeCurrency={chargeCurrency}
						name={name}
						updatePaymentType={setPaymentType}
					/>
				</div>
			</div>
			<div className="actions">
				{(!paymentType || paymentType === RADIO_PAYMENT_TYPE.PAY_NOW) && (
					<Button variant="outlined" fullWidth onClick={() => handleClose()}>
						Cancel
					</Button>
				)}

				{(!paymentType || paymentType === RADIO_PAYMENT_TYPE.PAY_NOW) && (
					<Button
						variant="contained"
						fullWidth
						onClick={handlePay}
						disabled={!name || !nameLocked || loading}
					>
						{loading ? <CircularProgress size={21} /> : 'Pay'}
					</Button>
				)}
			</div>
		</div>
	);
};

export default SettlePaymentPayNow;
