import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { createBrowserHistory } from 'history';

/**
 * Wrap for React class components
 * to pass navigate and location parameters to props
 */
export const withRouter = (Component) => {
	const Wrapper = (props) => {
		const navigate = useNavigate();
		const location = useLocation();
		const params = useParams();
		const history = createBrowserHistory();

		return <Component history={history} navigate={navigate} location={location} params={params} {...props} />;
	};

	return Wrapper;
};
