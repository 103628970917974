import createClass from 'create-react-class';

var EmptyPage = createClass({
	displayName: 'EmptyPage',

	render: function () {
		return null;
	},
});

export default EmptyPage;
