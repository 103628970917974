import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import SearchStore from '../../../stores/SearchStore';

function formatDistance(distance) {
	return Math.round(distance * 10) / 10;
}

class HotelAddressComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	getDistanceDescription() {
		const { hotel } = this.props;

		if (hotel.distanceDescription) {
			return hotel.distanceDescription;
		}

		if (_.get(hotel, 'research.distance_in_meters', 0) > 0) {
			return `${formatDistance(hotel.research.distance_in_meters / 1000)} km`;
		}

		return null;
	}

	getAlternativeDistanceDescription() {
		const { hotel } = this.props;

		return hotel.alternative_distance
			? `(${formatDistance(hotel.alternative_distance.distance_in_km)} km from ${hotel.alternative_distance.neighborhood.name})`
			: null;
	}

	getAlternativeDistanceNeighborhood() {
		const neighborhood_name = _.get(this.props.hotel, 'alternative_distance.neighborhood.name', null);

		return neighborhood_name ? `Near ${neighborhood_name}` : null;
	}

	getFullAddress() {
		const { hotel } = this.props;
		return (hotel.address + ', ' + hotel.city).toLowerCase() + (hotel.zip ? ', ' + hotel.zip.toUpperCase() : '');
	}

	render() {
		const { hotel, forceAddress } = this.props;

		const distance_description = this.getDistanceDescription();

		const full_address = this.getFullAddress();
		const headline = _.get(hotel, 'text_descriptions.headline', null);
		const alternative_distance_neighborhood = this.getAlternativeDistanceNeighborhood();

		const specific_search = SearchStore.isSpecificSearch();

		return (
			<div className="hotel-address">
				<div className="icon-container"></div>
				<div>
					<span className="full-address" title={full_address}>
						{specific_search || forceAddress
							? full_address
							: headline || alternative_distance_neighborhood || full_address}
					</span>
					{distance_description && (
						<span className="distance-description">
							({distance_description} from {specific_search ? 'destination' : 'center'})
						</span>
					)}
				</div>
			</div>
		);
	}
}

HotelAddressComponent.propTypes = {
	hotel: PropTypes.object.isRequired,
	forceAddress: PropTypes.bool,
};

export default HotelAddressComponent;
