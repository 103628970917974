import withStyles from '@mui/styles/withStyles';

import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import SearchStore from '../../../stores/SearchStore';
import SearchDataAction from './SearchDataAction.react';
import SearchDataChildSelect from './SearchDataChildSelect.react';
import SEARCH_CONSTANTS from 'arbitrip-common/general/constants/search-constants';
import { withTranslation } from 'react-i18next';

const styles = {
	root: {
		position: 'absolute',
		top: 66,
		right: 0,
		width: 303,
		backgroundColor: '#fff',
		boxShadow: '0 10px 16px 0 rgba(0, 0, 0, 0.2)',
		zIndex: 100,
		padding: '30px 24px',
		userSelect: 'none',
		borderRadius: 8,
	},
	divider: {
		border: '1px solid #EEEEEE',
		marginTop: 24,
	},
	childrenContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		flexDirection: 'column',
	},
	childWrap: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: 24,
		fontSize: 15,
		fontWeight: 700,
		lineHeight: '18px',
	},
};

const getState = () => ({ private_travel: SearchStore.getPrivateTravel() });

class SearchDataWindow extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			...getState(),
			showMoreRoomsMessage: false,
		};

		this.myRef = React.createRef();
		this._onChange = this._onChange.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.setFlag = this.setFlag.bind(this);
		this.setNewCount = this.setNewCount.bind(this);
		this.hasTranslation = this.hasTranslation.bind(this);
		this.getMailToUpdate = this.getMailToUpdate.bind(this);
	}

	toggleShowMoreRoomsMessage(shouldShow) {
		this.setState({ showMoreRoomsMessage: shouldShow });
	}

	handleClick(e) {
		if (
			this.myRef.current.contains(e.target) ||
			e.target.getAttribute('role') === SEARCH_CONSTANTS.SHARED_ATTRIBUTES_NAMES.OPTION ||
			e.target.getAttribute('id') === SEARCH_CONSTANTS.SHARED_ATTRIBUTES_NAMES.SEARCH_DATA ||
			e.target.classList.contains(SEARCH_CONSTANTS.SHARED_ATTRIBUTES_NAMES.SEARCH_DATA_CONTENT)
		) {
			// click inside the window or children option
			// do nothing
			return;
		}

		// click outside the window
		// close window
		let { closeWindow } = this.props;
		if (_.isFunction(closeWindow)) {
			closeWindow();
		}
	}

	doNotCloseWindow(e) {
		e.stopPropagation();
	}

	setFlag(name) {
		if (name === SEARCH_CONSTANTS.FIELDS.ROOMS) {
			this.toggleShowMoreRoomsMessage(true);
		}
	}

	setNewCount(name, count) {
		let { setNewCount } = this.props;

		this.toggleShowMoreRoomsMessage(false);
		if (_.isFunction(setNewCount)) {
			setNewCount(name, count);
		}
	}

	getFourOnFourMessage() {
		const { adults, rooms } = this.props;

		return (
			adults === SEARCH_CONSTANTS.MAX.ADULTS &&
			rooms === SEARCH_CONSTANTS.MAX.ROOMS && (
				<div>
					<div>
						<span style={{ fontWeight: 800 }}>Please note!</span>
					</div>
					<div>
						<span>You’re searching {SEARCH_CONSTANTS.MAX.ADULTS} adults per room</span>
					</div>
					<div>
						<span>({SEARCH_CONSTANTS.MAX.ROOMS * SEARCH_CONSTANTS.MAX.ADULTS} guests)</span>
					</div>
				</div>
			)
		);
	}

	hasTranslation(key) {
		const { i18n, t } = this.props;
		return i18n.exists(key) && !_.isEmpty(t(key));
	}

	getMailToUpdate() {
		const { t } = this.props;
		const supportEmail = t('HelpEmail');
		const mailtoUpdate = encodeURI('mailto:' + supportEmail + '?subject=Reservation%20Request');

		return (
			<a href={mailtoUpdate} target="_blank">
				{supportEmail}
			</a>
		);
	}

	getRoomsMessage() {
		const { showMoreRoomsMessage } = this.state;

		return (
			showMoreRoomsMessage && (
				<div style={{ marginTop: 10 }}>
					<div>
						{this.hasTranslation('HelpEmail') ? (
							<span>
								For more than {SEARCH_CONSTANTS.MAX.ROOMS} rooms, please contact our team at&nbsp;(
								{this.getMailToUpdate()})
							</span>
						) : (
							<span>You can book up to 4 rooms</span>
						)}
					</div>
				</div>
			)
		);
	}

	render() {
		let { classes, adults, childrenAges, rooms, updateSingleChildrenAge, showChildrenAgesError } = this.props;
		let children = childrenAges.length;

		return (
			<div ref={this.myRef} className={classes.root} onClick={this.doNotCloseWindow}>
				<SearchDataAction
					name={SEARCH_CONSTANTS.FIELDS.ROOMS}
					count={rooms}
					setNewCount={this.setNewCount}
					setFlag={this.setFlag}
					maxValue={SEARCH_CONSTANTS.MAX.ROOMS}
				/>
				<SearchDataAction
					name={SEARCH_CONSTANTS.FIELDS.ADULTS}
					count={adults}
					setNewCount={this.setNewCount}
					perRoomText={true}
					maxValue={this.props.isAgent ? SEARCH_CONSTANTS.MAX.ADULTS_IF_AGENT : SEARCH_CONSTANTS.MAX.ADULTS}
				/>
				<SearchDataAction
					name={SEARCH_CONSTANTS.FIELDS.CHILDREN}
					count={children}
					setNewCount={this.setNewCount}
					perRoomText={true}
					maxValue={SEARCH_CONSTANTS.MAX.ROOMS}
				/>

				{this.getFourOnFourMessage()}
				{this.getRoomsMessage()}

				<React.Fragment>
					{children > 0 && (
						<div>
							<div className={classes.divider}></div>
							<div className={classes.childrenContainer}>
								{_.times(children, (n) => {
									return (
										<div key={n} className={classes.childWrap}>
											Child {n + 1}
											<SearchDataChildSelect
												key={n}
												age={childrenAges[n]}
												childNumber={n}
												updateSingleChildrenAge={updateSingleChildrenAge}
												showChildrenAgesError={showChildrenAgesError}
											/>
										</div>
									);
								})}
							</div>
						</div>
					)}
				</React.Fragment>
			</div>
		);
	}

	_onChange() {
		this.setState(getState());
	}

	componentDidMount() {
		SearchStore.addChangeListener(this._onChange);
		document.addEventListener('mousedown', this.handleClick, false);
	}

	componentWillUnmount() {
		SearchStore.removeChangeListener(this._onChange);
		document.removeEventListener('mousedown', this.handleClick, false);
	}
}

SearchDataWindow.propTypes = {
	classes: PropTypes.object.isRequired,
	adults: PropTypes.number.isRequired,
	childrenAges: PropTypes.array.isRequired,
	rooms: PropTypes.number.isRequired,
	setNewCount: PropTypes.func.isRequired,
	closeWindow: PropTypes.func.isRequired,
	updateSingleChildrenAge: PropTypes.func.isRequired,
	showChildrenAgesError: PropTypes.bool.isRequired,
	isAgent: PropTypes.bool,
};

export default withStyles(styles)(withTranslation()(SearchDataWindow));
