import FirebaseWrapper from './ListenerSubscriber';
import DefaultConfig from "./DefaultConfig";
import onSnapshotWrapper from './onSnapshotWrapper';

const DEFAULT_SEARCH_SUBSCRIPTION_TIMEOUT_IN_SECONDS = 40;

class SearchFirebaseWrapper extends FirebaseWrapper {
    constructor(collection, subcollection, snapshot_params, prefix_message) {
        super(DefaultConfig.default_config, collection, subcollection, snapshot_params, prefix_message, onSnapshotWrapper);

        this._config.subscriptionTimeoutInSeconds = process.env.REACT_APP_FIREBASE_SEARCH_TIMEOUT_IN_SECONDS || DEFAULT_SEARCH_SUBSCRIPTION_TIMEOUT_IN_SECONDS;
    }

    static isValid() {
        return DefaultConfig.isValid(DefaultConfig.default_config);
    }
}

export default SearchFirebaseWrapper;
