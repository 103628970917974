import AppDispatcher from '../dispatcher/AppDispatcher';
import { EventEmitter } from 'events';
import PaymentConstants from '../constants/PaymentConstants';
import PaymentEntity from '../entities/Payment';
import _ from 'lodash';
// import User from '../entities/User';
import ReservationStore from '../stores/ReservationStore';
import ReservationConstants from '../constants/ReservationConstants';

// Define initial data points
var _payment = null; //{};

// Method to load result data from mock API
// eslint-disable-next-line no-unused-vars
function loadPaymentData(data) {
	if (_payment) {
		_payment = _.extend({}, _payment, data);
	} else {
		_payment = data;
	}
}

function createNewCreditPayment(paymentId, creditCardUrl) {
	var reservation = ReservationStore.getReservationData();
	_payment = new PaymentEntity(reservation, paymentId, creditCardUrl);
}

function createNewPointsOnlyPayment(paymentId) {
	var reservation = ReservationStore.getReservationData();
	_payment = new PaymentEntity(reservation, paymentId, '', true);
}

function clearPaymentData() {
	console.log('CLEARING PAYMENT DATA');
	_payment = null;
}

function markPaymentProcessed() {
	_payment.status = PaymentConstants.STATUS.PROCESSED;
}

function markPaymentFailed() {
	_payment.status = PaymentConstants.STATUS.FAILED;
}

function markPaymentCannotBeMade() {
	if (_payment) {
		_payment.status = PaymentConstants.STATUS.FAILED_PAYMENT_INITIALIZATION;
	}
}

// Extend Search Store with EventEmitter to add eventing capabilities
var PaymentStore = _.extend({}, EventEmitter.prototype, {
	getPaymentData: function () {
		if (!_payment) {
			createNewCreditPayment(null, null);
		}
		return _payment;
	},

	// TODO: method for currency conversion

	// Emit Change event
	emitChange: function () {
		this.emit('change');
	},

	// Add change listener
	addChangeListener: function (callback) {
		this.on('change', callback);
	},

	// Remove change listener
	removeChangeListener: function (callback) {
		this.removeListener('change', callback);
	},
});

// Register callback with AppDispatcher
AppDispatcher.register(function (payload) {
	const action = payload.action;
	let paymentID = null;
	let url = null;
	let points_only = null;

	switch (action.actionType) {
		// // Respond to RECEIVE_DATA action
		// case PaymentConstants.RECEIVE_PAYMENT_DATA:
		//   loadPaymentData(action.data);
		//   break;

		case ReservationConstants.ADVANCE_RESERVATION:
			_payment.status = PaymentConstants.STATUS.INITIALIZING;
			break;

		case ReservationConstants.REGENERATE_CREDIT_CARD_URL_SUCCESS:
		case ReservationConstants.ADVANCE_RESERVATION_SUCCESS:
			paymentID = _.get(action, 'data.response.paymentID');
			url = _.get(action, 'data.response.url');
			points_only = _.get(action, 'data.response.points_only');

			if (paymentID && url) {
				createNewCreditPayment(paymentID, url);
				_payment.status = PaymentConstants.STATUS.INITIALIZED;
				_payment.sum_in_charge_currency = _.get(action, 'data.response.sum_in_charge_currency');
				_payment.charge_currency = _.get(action, 'data.response.charge_currency');
			} else if (paymentID && points_only) {
				createNewPointsOnlyPayment(paymentID);
				_payment.status = PaymentConstants.STATUS.INITIALIZED;
				_payment.sum_in_charge_currency = _.get(action, 'data.response.sum_in_charge_currency');
				_payment.charge_currency = _.get(action, 'data.response.charge_currency');
			}
			break;

		case ReservationConstants.REGENERATE_CREDIT_CARD_URL_FAILURE:
		case ReservationConstants.ADVANCE_RESERVATION_FAILURE:
			markPaymentCannotBeMade();
			break;

		case PaymentConstants.CREDIT_PAYMENT_CHARGED:
			markPaymentProcessed();
			break;

		case PaymentConstants.CREDIT_PAYMENT_FAILED:
			markPaymentFailed();
			break;

		case PaymentConstants.CLEAR_PAYMENT_DATA:
			clearPaymentData();
			break;

		case PaymentConstants.PAYMENT_ERROR_MESSAGE:
			_payment = {
				..._payment,
				error_message: action.data,
			};
			break;

		case PaymentConstants.THREEDS_INFORMATION_DATA:
			_payment = {
				..._payment,
				payment_session_response: action.data,
			};
			break;
		default:
			return true;
	}

	// If action was responded to, emit change event
	PaymentStore.emitChange();

	return true;
});

export default PaymentStore;
