import keyMirror from 'keymirror';
import _ from 'lodash';

const MANAGEMENT_CONSTANTS = {
	ADAPTER_MOMENT_LOCALE: 'en-gb',
	DATE_PICKER_FORMAT: 'DD/MM/YYYY',

	NO_VALUE: '-',

	TRIP_STATUS: {
		INIT: 'initialized', // pre edit
		DRAFT: 'draft', // any edit / saved draft
		DISCARDED: 'discarded',
		CANCELED: 'canceled', // travel manager issued a cancel request
		PENDING: 'pending', // submitted but not yet approved or declined
		APPROVED: 'approved',
		DECLINED: 'declined',
	},

	STATUS: {
		INITIAL: 'initial',
		BUSY: 'busy',
		IDLE: 'idle',
		SUCCESS: 'success',
		FAILED: 'failed',
	},
	TRIP_REQUEST_COMPONENT_TYPE: {
		FLIGHT: 'flight',
		ACCOMMODATION: 'accommodation',
		CAR: 'car',
	},
	TRIP_BOOKED_COMPONENT_TYPE: {
		AIRFARE: 'airfare',
		FLIGHT: 'flight',
		ACCOMMODATION: 'accommodation',
	},
	// TRIP_REQUEST_STATUS: {
	//   DRAFT: 'draft',
	//   PENDING_TRAVEL_MANAGER: 'pending-travel-manager',
	//   PENDING_TRAVEL_AGENT: 'pending-travel-agent',
	//   PENDING_TRAVELER: 'pending-traveler',
	//   PENDING_APPROVAL: 'pending-approval',
	//   ACTION_REQUIRED: 'action-required',
	//   APPROVED: 'approved',
	//   DECLINED: 'declined',
	//   CANCELLED: 'cancelled'
	// }
	CHECK_IN_SPECIAL_REQUESTS: {
		EARLY_CHECK_IN: 'Early Check-in',
		LATE_CHECK_IN: 'Late Check-in',
	},
	CHECK_OUT_SPECIAL_REQUESTS: {
		LATE_CHECK_OUT: 'Late Check-out',
	},
	TIPS: keyMirror({
		TRIP_NAME: null,
		CANCEL: null,
		DESTINATION: null,
		START_DATE: null,
		END_DATE: null,
		DISCARD_DESTINATION: null,
		NEXT: null,
		ADD_ANOTHER_DESTINATION: null,
		SKIP_WIZARD: null,
	}),
	SOURCES: {
		WIZARD: 'new-trip',
		TRIPS: 'trips',
		ONGOING_TRIPS: 'trips/live',
		FORWARD: 'forward',
		TRIP: 'trip',
		ADD_AIRFARE_WIZARD: 'new-airfare',
		AIRFARE: 'airfare',
		/// should have a preview display mode consts
		COST_ESTIMATION: 'cost-estimation',
	},
};

// Define action constants
export default _.extend(
	{},
	MANAGEMENT_CONSTANTS,
	keyMirror({
		INIT_TRIP_REQUEST: null,
		TRIP_REQUEST_INITIALIZED: null,
		TRIP_REQUEST_INIT_FAILED: null,
		RESET_INIT_TRIP_REQUEST_STATUS: null,

		ABOUT_TO_SAVE_CHANGES: null,
		EDIT_TRIP_REQUEST_META: null,
		TRIP_REQUEST_META_EDITED: null,
		TRIP_REQUEST_META_EDIT_FAILED: null,
		RESET_EDIT_TRIP_REQUEST_META_STATUS: null,

		////////////////////////////////////////

		CREATE_TRIP_REQUEST_COMPONENT: null,
		TRIP_REQUEST_COMPONENT_CREATED: null,
		TRIP_REQUEST_COMPONENT_FAILED_TO_CREATE: null,
		RESET_CREATE_TRIP_REQUEST_COMPONENT_STATUS: null,

		UPDATE_TRIP_REQUEST_COMPONENT: null,
		TRIP_REQUEST_COMPONENT_UPDATED: null,
		TRIP_REQUEST_COMPONENT_FAILED_TO_UPDATE: null,
		RESET_UPDATE_TRIP_REQUEST_COMPONENT_STATUS: null,

		REMOVE_TRIP_REQUEST_COMPONENT: null,
		TRIP_REQUEST_COMPONENT_REMOVED: null,
		TRIP_REQUEST_COMPONENT_FAILED_TO_REMOVE: null,
		RESET_REMOVE_TRIP_REQUEST_COMPONENT_STATUS: null,

		SUBMIT_TRIP_REQUEST: null,
		TRIP_REQUEST_SUBMITTED: null,
		TRIP_REQUEST_SUBMIT_FAILED: null,
		RESET_TRIP_REQUEST_SUBMIT_STATUS: null,

		////////////////////////////////////////

		INIT_TRIP_REQUEST_FLIGHT: null,
		INIT_TRIP_REQUEST_ACCOMMODATION: null,
		INIT_TRIP_REQUEST_CAR: null,

		///

		INIT_FLIGHT_RESERVATION: null,
		INIT_ACCOMMODATION_RESERVATION: null,
		INIT_CAR_RESERVATION: null,

		///

		CANCEL_NON_INIT_TRIP_REQUEST_FLIGHT: null,
		CANCEL_TRIP_REQUEST_FLIGHT: null,
		CANCEL_NON_INIT_TRIP_REQUEST_ACCOMMODATION: null,
		CANCEL_TRIP_REQUEST_ACCOMMODATION: null,
		CANCEL_NON_INIT_TRIP_REQUEST_CAR: null,
		CANCEL_TRIP_REQUEST_CAR: null,

		UPDATE_TRIP_REQUEST_NAME: null,
		UPDATE_TRIP_LATER_REQUEST: null,
		CLEAR_TRIP_LATER_REQUEST: null,
		ADD_TRIP_REQUEST_TRAVELER: null,
		REMOVE_TRIP_REQUEST_TRAVELER: null,
		// UPDATE_TRIP_REQUEST_SELF_TRAVEL: null,
		ADD_TRIP_REQUEST_PURPOSE: null,
		REMOVE_TRIP_REQUEST_PURPOSE: null,
		UPDATE_TRIP_REQUEST_PURPOSES: null,
		UPDATE_TRIP_REQUEST_STAYING_WITH: null,
		UPDATE_TRIP_REQUEST_SCHEDULE_AND_AGENDA: null,
		UPDATE_TRIP_REQUEST_CONFERENCE_NAME_OR_WEBSITE: null,
		UPDATE_TRIP_REQUEST_CONFERENCE_ESTIMATED_COST_CURRENCY: null,
		UPDATE_TRIP_REQUEST_CONFERENCE_ESTIMATED_COST_AMOUNT: null,
		UPDATE_TRIP_REQUEST_SPECIAL_REQUESTS: null,
		UPDATE_TRIP_REQUEST_COST_CENTER: null,
		CLEAR_REQUEST_VACATION_DAYS: null,
		UPDATE_TRIP_REQUEST_VACATION_DAYS_FROM: null,
		UPDATE_TRIP_REQUEST_VACATION_DAYS_TO: null,

		UPDATE_TRIP_REQUEST_FLIGHT_FROM: null,
		SWITCH_TRIP_REQUEST_FLIGHT_FROM_TO: null,
		UPDATE_TRIP_REQUEST_FLIGHT_TO: null,
		UPDATE_TRIP_REQUEST_FLIGHT_ONE_WAY: null,
		UPDATE_TRIP_REQUEST_FLIGHT_DEPART_DATE: null,
		UPDATE_TRIP_REQUEST_FLIGHT_DEPART_TIME: null,
		UPDATE_TRIP_REQUEST_FLIGHT_RETURN_DATE: null,
		UPDATE_TRIP_REQUEST_FLIGHT_RETURN_TIME: null,

		UPDATE_TRIP_REQUEST_ACCOMMODATION_DESTINATION: null,
		UPDATE_TRIP_REQUEST_ACCOMMODATION_DESTINATION_ADDRESS: null,
		UPDATE_TRIP_REQUEST_ACCOMMODATION_CHECK_IN: null,
		UPDATE_TRIP_REQUEST_ACCOMMODATION_CHECK_OUT: null,
		UPDATE_TRIP_REQUEST_ACCOMMODATION_CHECK_IN_SPECIAL_REQUEST: null,
		UPDATE_TRIP_REQUEST_ACCOMMODATION_CHECK_OUT_SPECIAL_REQUEST: null,

		UPDATE_TRIP_REQUEST_CAR_PICK_UP: null,
		UPDATE_TRIP_REQUEST_CAR_AIRPORT: null,
		UPDATE_TRIP_REQUEST_CAR_DROP_OFF: null,
		UPDATE_TRIP_REQUEST_CAR_PICK_UP_DATE: null,
		UPDATE_TRIP_REQUEST_CAR_DROP_OFF_DATE: null,

		SAVE_TRIP_REQUEST_AS_DRAFT: null,
		TRIP_REQUEST_DRAFT_SAVED: null,
		TRIP_REQUEST_DRAFT_FAILED: null,

		RESET_TRIP_REQUEST_DRAFT_STATUS: null,
		RESET_TRIP_REQUEST: null,

		GET_TRIP_REQUESTS: null,
		GET_TRIP_REQUESTS_SUCCESS: null,
		GET_TRIP_REQUESTS_FAIL: null,

		GET_TRIP_REQUEST_BY_ID: null,
		GET_TRIP_REQUEST_BY_ID_SUCCESS: null,
		GET_TRIP_REQUEST_BY_ID_FAIL: null,

		PREVIEW_TRIP: null,
		CLEAR_TRIP_PREVIEW: null,
		PREVIEW_BOOKED_COMPONENT: null,
		PREVIEW_AIRFARE_TIPS: null,
		PREVIEW_TRIP_COST_ESTIMATION: null,

		ADD_WIZARD_DESTINATION: null,
		UPDATE_WIZARD_DESTINATION_LOCATION: null,
		UPDATE_WIZARD_DESTINATION_START_DATE: null,
		UPDATE_WIZARD_DESTINATION_END_DATE: null,
		CLEAR_WIZARD_DESTINATION: null,
		REMOVE_WIZARD_DESTINATION: null,
		RESET_WIZARD_DESTINATIONS: null,

		SUBMIT_WIZARD_DESTINATIONS: null,
		SUBMIT_WIZARD_DESTINATIONS_SUCCESS: null,
		SUBMIT_WIZARD_DESTINATIONS_FAIL: null,

		DISCARD_TRIP_REQUEST: null,
		DISCARD_TRIP_REQUEST_SUCCESS: null,
		DISCARD_TRIP_REQUEST_FAIL: null,

		CANCEL_TRIP_REQUEST: null,
		CANCEL_TRIP_REQUEST_SUCCESS: null,
		CANCEL_TRIP_REQUEST_FAIL: null,

		SEND_TRIP_FOR_APPROVAL: null,
		SEND_TRIP_FOR_APPROVAL_SUCCESS: null,
		SEND_TRIP_FOR_APPROVAL_FAIL: null,

		SEND_TRIP_FOR_PRE_APPROVAL: null,
		SEND_TRIP_FOR_PRE_APPROVAL_SUCCESS: null,
		SEND_TRIP_FOR_PRE_APPROVAL_FAIL: null,

		CLONE_TRIP: null,
		CLONE_TRIP_SUCCESS: null,
		CLONE_TRIP_FAIL: null,
		CLONE_TRIP_CLEAR: null,

		FORWARD_TRIP_REQUEST: null,
		FORWARD_TRIP_REQUEST_SUCCESS: null,
		FORWARD_TRIP_REQUEST_FAIL: null,

		UPDATE_TRIP_REQUEST_PAGE_SOURCE: null,

		UPDATE_TRIP_REQUEST_TRAVELER: null,

		RESET_AIRFARE_WIZARD_FLIGHTS: null,
		UPDATE_AIRFARE_WIZARD_FLIGHT_NUMBER: null,
		UPDATE_AIRFARE_WIZARD_FLIGHT_DEPARTURE_DATE: null,
		REMOVE_AIRFARE_WIZARD_FLIGHT: null,
		ADD_AIRFARE_WIZARD_FLIGHT: null,
		UPDATE_AIRFARE_WIZARD_COST: null,
		UPDATE_AIRFARE_WIZARD_CURRENCY: null,
		CLEAR_AIRFARE_WIZARD: null,

		CREATE_TRIP_AIRFARE: null,
		CREATE_TRIP_AIRFARE_SUCCESS: null,
		CREATE_TRIP_AIRFARE_FAIL: null,

		REMOVE_TRIP_AIRFARE: null,
		REMOVE_TRIP_AIRFARE_SUCCESS: null,
		REMOVE_TRIP_AIRFARE_FAIL: null,
		REMOVE_TRIP_ACCOMMODATION: null,
		REMOVE_TRIP_ACCOMMODATION_SUCCESS: null,
		REMOVE_TRIP_ACCOMMODATION_FAIL: null,

		CANCEL_TRIP_HOTEL_BOOKING: null,
		CANCEL_TRIP_HOTEL_BOOKING_SUCCESS: null,
		CANCEL_TRIP_HOTEL_BOOKING_FAIL: null,
	}),
);
