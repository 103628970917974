const moment = require('moment');

const SearchTerms = require('./SearchTerms');

class RecentSearch {
    constructor(recent_search) {
        this.terms = new SearchTerms(recent_search.terms);
        this.search_timestamp = moment(recent_search.created_timestamp);
        this.id = recent_search._id;
    }
}

module.exports = RecentSearch;
