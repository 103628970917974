import Reservation from './Reservation';
import moment from "moment";

export default class ReservationRequest {
  constructor(request_reservation) {
    if (request_reservation) {
      this.reservation = new Reservation(request_reservation.reservation);

      this.request_ticket = request_reservation.ticket;
      if (request_reservation.ticket) {
        this.request_ticket.id = request_reservation.ticket._id;
        this.request_ticket.expiration_date = new Date(request_reservation.ticket.expiration_timestamp);
        this.request_ticket.changed_date = new Date(request_reservation.ticket.changed_timestamp);
        this.request_ticket.reason = request_reservation.ticket.meta && request_reservation.ticket.meta.reason;

        ///
        /// PATCH
        ///
        if (this.request_ticket.status == 'pending' && moment().isSameOrAfter(this.request_ticket.expiration_date)) {
          this.request_ticket.status = 'expired';
        }
      }
    }
  }
}