module.exports = {
  STATUS: {
    INITIAL: 'initial',
    BUSY: 'busy',
    IDLE: 'idle',
    SUCCESS: 'success',
    FAILED: 'failed'
  },
  REPORTS_COMPANY_ALL: {
    _id: '5b30cb8b2cf3686778d592d1',
    company: {
      _id: '5b30cb8b2cf3686778d592d1',
      name: 'All Bookings'
    },
    active: true
  },
  RECOMMENDATION_PROFILES: {
    NORMAL: 'normal',
    LUXURY: 'luxury',
    BUDGET: 'budget'
  },
  RECOMMENDATION_MODES: {
    BY_REGION: 'by_region',
    BY_RADIUS: 'by_radius'
  }
};
