import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';

import React from 'react';
import PropTypes from 'prop-types';

// import HotelStore from '../../../stores/HotelStore';
import ResultsStore from '../../../stores/ResultsStore';
import SearchStore from '../../../stores/SearchStore';

import RouterWrapper from '../../../utils/RouterWrapper';
import Constants from './constants';
import { Link } from 'react-router-dom';

const { EXPIRED_TYPES } = Constants;

function getExpiredResultsComponentState() {
	return {
		modalIsOpen: ResultsStore.areExpired(),
	};
}

function capitalizeFirstLetter(str) {
	if (typeof str === 'string' && str.length > 0) {
		return str.charAt(0).toUpperCase() + str.slice(1);
	}
	return str;
}

class ExpiredResultsComponent extends React.Component {
	constructor(props) {
		super(props);

		this.state = getExpiredResultsComponentState();

		this._onChange = this._onChange.bind(this);
	}

	componentDidMount() {
		ResultsStore.addChangeListener(this._onChange);
	}

	componentWillUnmount() {
		ResultsStore.removeChangeListener(this._onChange);
	}

	getHotelURL() {
		// let hotel = HotelStore.getHotelData();
		const hotel = {
			id: this.props.hotelId,
			name: this.props.hotelName,
		};

		const search_token = null;
		const search_terms = SearchStore.getSearchTerms();

		return RouterWrapper.buildHotelRoute(hotel, search_token, search_terms);
	}

	getResultsURL() {
		let search_terms = SearchStore.getSearchTerms();
		return RouterWrapper.buildResultsRoute(search_terms);
	}

	getSearchURL() {
		let search_terms = SearchStore.getSearchTerms();
		return RouterWrapper.buildSearchRoute(search_terms);
	}

	getBackToHotelPageActions() {
		return [
			<Button key={'cancel-button'} href={this.getSearchURL()} variant="text" color="primary">
				Back to Main
			</Button>,
			<Button key={'search-button'} href={this.getHotelURL()} variant="text" color="primary" autoFocus>
				Back to Hotel
			</Button>,
		];
	}

	getBackToResultsPageActions() {
		return [
			<Button key={'cancel-button'} href={this.getSearchURL()} variant="text" color="primary">
				Back to Main
			</Button>,
			<Button key={'search-button'} href={this.getResultsURL()} variant="text" color="primary" autoFocus>
				Back to Results
			</Button>,
		];
	}

	getGeneralActions() {
		return [
			<Button key={'cancel-button'} href={this.getSearchURL()} variant="text" color="primary">
				Back to Main
			</Button>,
			<Button key={'search-button'} href={this.getResultsURL()} variant="text" color="primary" autoFocus>
				Search Again
			</Button>,
		];
	}

	render() {
		let { type } = this.props;
		let expiredEntityText = capitalizeFirstLetter(this.props.expiredEntityText) || 'Results';
		expiredEntityText += this.props.singularEntityText ? ' has' : ' have';

		let expiredMessage = expiredEntityText + ' expired';

		let actions;
		switch (type) {
			case EXPIRED_TYPES.BACK_TO_HOTEL:
				actions = this.getBackToHotelPageActions();
				break;
			case EXPIRED_TYPES.BACK_TO_RESULTS:
				actions = this.getBackToResultsPageActions();
				break;
			default:
				actions = this.getGeneralActions();
		}

		return (
			<Dialog
				open={this.state.modalIsOpen}
				aria-describedby="alert-dialog-description"
				fullWidth={true}
				maxWidth={'xs'}
			>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">{expiredMessage}</DialogContentText>
				</DialogContent>
				<DialogActions>{actions}</DialogActions>
			</Dialog>
		);
	}

	_onChange() {
		this.setState(getExpiredResultsComponentState());
	}
}

ExpiredResultsComponent.propTypes = {
	expired: PropTypes.bool,
	type: PropTypes.string,
	expiredEntityText: PropTypes.any,
	singularEntityText: PropTypes.any,
};

export default ExpiredResultsComponent;
