const Validator = require('../utils').Validator;

class User {
    constructor(user) {
        this.id = user.id || user._id || user.user_ref;

        this.company_id = user.company_id;
        this.active = user.active;
        this.verified = user.verified;
        this.roles = user.roles;
        this.employee_number = user.employee_number;
        this.department = user.department;
        this.location = user.location;
        this.cost_center = user.cost_center;
        this.approver = user.approver;
        this.shouldCreateUser = !this.id && user.shouldCreateUser;

        this.first_name = (user.first_name || '').trim();
        this.last_name = (user.last_name || '').trim();
        this.full_name = (user.full_name || '').trim();
        if (!this.full_name && this.first_name && this.last_name) {
            this.full_name = this.first_name + ' ' + this.last_name;
        }

        this.email = (user.email || '').trim();
        this.tel = (user.tel || '').trim();
        this.room = user.room;
        this.picture = user.picture;
        this.ghost = user.ghost;

        if (user.terms_of_use) {
            this.terms_of_use = user.terms_of_use;
        }

        if (user.filled_name) {
            if (user.filled_name.toLowerCase() === this.full_name) {
                this.different_name_detected = true;
                this.filled_name = user.filled_name;
            }
        }

        const _self = this;
        this.isValid = function() {
            if (Validator.validateObjectId(_self.id))
                return true;
            if (!_self.first_name || !Validator.validateEnglishOnly(_self.first_name))
                return false;
            if (!_self.last_name || !Validator.validateEnglishOnly(_self.last_name))
                return false;
            if (_self.email && !Validator.validateEmailAddress(_self.email))
                return false;
            if (_self.tel && !Validator.validatePhoneNumber(_self.tel))
                return false;
            if (isNaN(_self.room) || _self.room <= 0)
                return false;
            return true;
        };

        if (user.okta_extra_fields) {
            this.okta_extra_fields = user.okta_extra_fields;
        }
    }
}

module.exports = User;