import { v4 as uuidV4 } from "uuid";

class PayThreeDSConnectorHelper {

    constructor() {
        this.threeds_iframe_id = 'threeds_iframe';
    }

    async initSession(deal) {
        const cashier_customer_session_id = deal.cashier_customer_session_id;

        // eslint-disable-next-line no-undef
        this.dSConnector = new PayThreeDSConnector.ThreeDSConnector(this.threeds_iframe_id, 'https://static.pay.expedia.com');
        // setup sets he payment session by collecting basic details about the browser needed by backend 3DS service, such as screen size, color depth, etc
        console.log('this.dSConnector', this.dSConnector)

        // TODO! Do we want to pass this information to client?
        // The reference ID identifies the traveler’s check-out session, used for logging and tracing.
        // Use a concatenation of your APIKey and Customer-Session-ID using an underscore, [APIKey]_[SessionID]
        const reference_id = uuidV4();
        try {
            const setup_response = await this.dSConnector.setup({ referenceId: (reference_id + cashier_customer_session_id) });
            console.log('Cashier 3DS Setup Output: ', setup_response);
            return setup_response;
        } catch (err) {
            console.log('error', err)
            throw err;
        }
    }

    async cashier3DSinitSession(paymentSessionId, encodedInitConfig) {
        console.log('this.dSConnector', this.dSConnector, paymentSessionId, encodedInitConfig)
        console.log('Cashier 3DS about to initSession');
        // Initializes the session for authentication with 3DS.
        // As a part of the initialization, additional data may be collected from the browser.
        // If required by the card issuer, a 3DS method URL could be loaded into the iframe to enable
        // the card issuer’s access control server to collect data from the browser directly.
        const initSessionResponse = await this.dSConnector.initSession({
            paymentSessionId,
            encodedInitConfig
        });
        console.log("Init Session Response: ", initSessionResponse);
        // InitSessionResponse return statusCode, message with one of the following values:
        // SUCCESS, SKIPPED, FAILED, TIMEOUT
        if (initSessionResponse.statusCode !== 'SUCCESS') {
            throw new Error(`Init session Error: ${initSessionResponse.message}`)
        }
    }

    async executeChallenge(paymentSessionId, encoded_challenge_config) {
        console.log('this.dSConnector', this.dSConnector)
        // If the Create Booking API contains an encoded_challenge_config field,
        // display the authentication challenge window
        // Perform the challenge using the information returned from Rapid's Register Payments API
        console.log('executeChallenge', encoded_challenge_config)
        console.log('executeChallenge paymentSessionId', paymentSessionId)
        const length = encoded_challenge_config.length;
        console.log('executeChallenge length', length)
        const encodedChallengeConfig = encoded_challenge_config.substring(0, length - length % 4);
        console.log('encodedChallengeConfig', encodedChallengeConfig)
        const challengeResponse = await this.dSConnector.challenge({
            paymentSessionId,
            encodedChallengeConfig,
        });
        console.log("Challenge Response: ", challengeResponse);
        if (challengeResponse?.statusCode !== 'SUCCESS') {
            throw new Error(`Challenge Error: ${challengeResponse.message}`);
        }
    }
}

export default new PayThreeDSConnectorHelper();