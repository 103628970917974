import React from 'react';
import { Paper, Table, TableHead, TableRow, TableCell, TableBody, TableContainer } from '@mui/material';
import ContentLoader from 'react-content-loader';

const BookingsTableLoader = ({ profile, classes }) => {
	const getContentLoader = (width = 80) => {
		const height = 14;
		return (
			<ContentLoader width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
				<rect x="0" y="0" rx="3" ry="3" width={width} height={height} />
			</ContentLoader>
		);
	};

	return (
		<TableContainer component={Paper} className="bookings-table-container loader">
			<Table sx={{ ...classes.border_spacing }} aria-label="Bookings table" className="bookings-table">
				<TableHead>
					<TableRow>
						{Array(profile.agent ? 9 : 8)
							.fill(1)
							.map((cell, index) => (
								<TableCell key={`cell-header-${index}`}>{getContentLoader()}</TableCell>
							))}
					</TableRow>
				</TableHead>
				<TableBody>
					{Array(10)
						.fill(1)
						.map((rows, index) => {
							return (
								<TableRow
									key={`row-${index}`}
									sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
								>
									<TableCell key={`cell-start-${index}`} colSpan={profile.agent ? 5 : 4}>
										{getContentLoader(700)}
									</TableCell>
									<TableCell key={`cell-middle-${index}`} colSpan={2}>
										{getContentLoader(160)}
									</TableCell>
									<TableCell key={`cell-end-${index}`} colSpan={2}>
										{getContentLoader(200)}
									</TableCell>
								</TableRow>
							);
						})}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default BookingsTableLoader;
