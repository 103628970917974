import CircularProgress from '@mui/material/CircularProgress';
import ManagementConstants from '@/constants/ManagementConstants';

import Plus from '@/components/general/icons/Plus';
import { COLORS } from '@/components/general/icons/constants';
import { ReactComponent as Clock } from '@/img/trip_management/clock.svg';
import { TripActionButton } from '../components/action_buttons';

const TripTableHeader = ({ profile, status, onNewTripClick, onOngoingTripsClick }) => (
	<div className="trips-table-header flex space-between">
		<span className="title">All Trips</span>
		<div className="actions flex">
			{status === ManagementConstants.STATUS.BUSY && (
				<div className="flex-center">
					<CircularProgress size={24} />
				</div>
			)}
			{profile.travel_manager && (
				<TripActionButton onClick={onOngoingTripsClick}>
					<Clock />
				</TripActionButton>
			)}
			<TripActionButton onClick={onNewTripClick}>
				<Plus color={COLORS.BLACK} />
			</TripActionButton>
		</div>
	</div>
);

export default TripTableHeader;
