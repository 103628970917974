import React, { useMemo, useState } from 'react';
import { Typography, Button } from '@mui/material';

const COLLAPSE_LENGTH = 120;

const CollapseText = ({ text, breakLines }) => {
	const [isTextVisible, setTextVisible] = useState(false);

	const handleToggleText = () => {
		setTextVisible(!isTextVisible);
	};

	const short_text = useMemo(() => {
		return text.slice(0, COLLAPSE_LENGTH);
	}, [text]);

	const full_text = useMemo(() => {
		return text.slice(COLLAPSE_LENGTH, text.length);
	}, [text]);

	const show_more = useMemo(() => {
		return text.length > COLLAPSE_LENGTH;
	}, [text]);

	return (
		<div>
			<Typography style={breakLines ? { whiteSpace: 'pre-line' } : {}}>
				{short_text}
				{!isTextVisible && show_more && <span>...</span>}
				{isTextVisible && full_text}
			</Typography>
			{show_more && (
				<div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 12 }}>
					<Button onClick={handleToggleText} className="show-more-btn">
						{isTextVisible ? 'Show Less' : 'Show More'}
					</Button>
				</div>
			)}
		</div>
	);
};

export default CollapseText;
