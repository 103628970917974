import UserEntity from '../../entities/User';

class UserManagementService {
	getUsersData(data) {
		let _data = [];
		let users = data.users;
		for (let i = 0; i < users.length; i++) {
			_data.push(new UserEntity(users[i]));
		}

		return _data.slice(0); // clone
	}

	addUserToUsers(user, users) {
		users.push(new UserEntity(user));

		return users.slice(0); // clone
	}
}

export default new UserManagementService();
