const keyMirror = require('keymirror');

const RESERVATION_CONSTANTS = {
  STATUS: {
    NEW: 'new',
    INITIAL: 'initial',
    INITIALIZING: 'initializing',
    INITIALIZED: 'initialized',
    FAILED_RESERVATION_INITIALIZATION: 'failedReservationInitialization',
    RESTART_PAYMENT_WITH_ERROR: 'restartPaymentWithError',

    BUSY: 'busy',
    SUCCESS: 'success',
    FAILED: 'failed',

    BOOKING: 'booking',
    BOOKED: 'booked',
    PENDING: 'pending',
  },

  RESERVATION_STATUS: {
    QUOTE: 'quote',
    PENDING: 'pending',
    PENDING_SUPPLIER: 'pending-supplier',
    PENDING_PAYMENT: 'pending-payment',
    PENDING_SUPPORT: 'pending-support',
    CANCELED: 'canceled',
    CANCELLED: 'cancelled',
    CANCELING: 'canceling',
    ABORTED: 'aborted',
    ERROR: 'error',
    CONFIRMED: 'confirmed',
    APPROVED: 'approved',
    APPROVING: 'approving',
    REJECTED: 'rejected',
    REJECTING: 'rejecting',
    REQUESTED: 'requested',
  },

  REQUEST_STATUS: {
    NA: 'not_exists',
    PENDING: 'pending',
    APPROVED: 'approved',
    REJECTED: 'rejected',
    ERROR: 'error',
    EXPIRED: 'expired',
  },

  RESERVATION_OPERATION_STATE: {
    QUOTING: 'quoting',
    QUOTED: 'quoted',
  },

  // TODO: yaron - move constant
  OUT_OF_POLICY_MARK_TYPE: {
    NONE: '',
    PRICE: 'price',
  },

  // TODO: yaron - move constant
  OUT_OF_POLICY_STATE: {
    BYPASS: 'bypass',
    WARNING: 'warning',
    BLOCK: 'block',
  },

  CREATE_NEW_TRAVELER_TEXT: 'Create New Traveler',

  NO_TERMS_OF_SUPPLIER_TEXT: 'No special terms',

  AGENT_PRICING_METHOD: {
    ABSOLUTE_PRICE: 'absolute_price',
    ABSOLUTE_MARGIN: 'absolute_margin',
    MARGIN_PERCENTAGE: 'margin_percentage',
  },

  RESERVATION_ATTEMPTS: 'reservation_attempts',
};

module.exports = Object.assign({}, RESERVATION_CONSTANTS, keyMirror({
  RECEIVE_RESERVATION_DATA: null, // After postback
  OPEN_NEW_RESERVATION: null,
  NEW_RESERVATION_OPENED: null,
  ADD_CONTACT_TO_RESERVATION: null,
  CONTACT_ADDED_TO_RESERVATION: null,
  REMOVE_CONTACT_FROM_RESERVATION: null,
  CONTACT_REMOVED_FROM_RESERVATION: null,
  ADVANCE_RESERVATION: null,
  ADVANCE_RESERVATION_SUCCESS: null,
  ADVANCE_RESERVATION_FAILURE: null,

  REGENERATE_CREDIT_CARD_URL_SUCCESS: null,
  REGENERATE_CREDIT_CARD_URL_FAILURE: null,
  RESTART_PAYMENT_WITH_ERROR: null,

  NEW_RESERVATION_CANNOT_BE_OPENED: null,

  QUOTE_RESERVATION: null,
  RESERVATION_QUOTED: null,
  RESERVATION_QUOTED_FAILED: null,

  UPDATE_TRAVELER_FIRST_NAME: null,
  UPDATE_TRAVELER_LAST_NAME: null,
  UPDATE_TRAVELER_EMAIL: null,
  UPDATE_TRAVELER_PHONE: null,
  UPDATE_TRAVELER_ROOM: null,
  UPDATE_TRAVELER: null,
  RESET_TRAVELER: null,

  CREATE_TRAVELER: null,
  CREATE_TRAVELER_SUCCESS: null,
  CREATE_TRAVELER_FAILED: null,
  ADD_GUEST_TRAVELER: null,

  ADD_TRAVELER_ERROR: null,
  REMOVE_TRAVELER_ERROR: null,
  CONFIRM_PRICE_CHANGED: null,

  UPDATE_SPECIAL_REQUESTS: null,
  UPDATE_SPECIAL_REQUESTS_FROM_SUGGESTIONS: null,

  UPDATE_OUT_OF_POLICY_BOOKING_REASON: null,
  RESET_OUT_OF_POLICY_REQUEST_STATUS: null,
  REQUEST_OUT_OF_POLICY_ROOM: null,
  REQUEST_OUT_OF_POLICY_ROOM_SUCCESS: null,
  REQUEST_OUT_OF_POLICY_ROOM_FAILED: null,

  CLEAR_RESERVATION: null,

  MARK_RESERVATION_FOR_NON_REFUNDABLE_QUERY: null,
  QUERIED_NON_REFUNDABLE_RESERVATION: null,

  CANCEL_RESERVATION: null,
  RESERVATION_CANCELLATION_SUCCEEDED: null,
  RESERVATION_CANCELLATION_FAILED: null,
  RESET_RESERVATION_CANCELLATION_STATUS: null,

  SEND_BOOKING_CONFIRMATION: null,
  SEND_BOOKING_CONFIRMATION_SUCCESS: null,
  SEND_BOOKING_CONFIRMATION_FAILED: null,

  APPROVE_REQUEST: null,
  REQUEST_APPROVAL_SUCCEEDED: null,
  REQUEST_APPROVAL_FAILED: null,

  REJECT_REQUEST: null,
  REQUEST_REJECTION_SUCCEEDED: null,
  REQUEST_REJECTION_FAILED: null,

  TOGGLE_BANK_MONEY_USAGE: null,

  UPDATE_INVOICE_OPTION: null,
  UPDATE_INCLUDE_MANAGEMENT_TRIP_ID: null,

  UPDATE_AGENCY_CLIENT_PRICE: null,
  UPDATE_AGENT_PRICING: null,

  DOUBLE_BOOKING: null,
  DOUBLE_BOOKING_SUCCESS: null,
  DOUBLE_BOOKING_FAILED: null,

  UPDATE_SUPPLIER_REF: null,

  CREATE_EXPEDIA_BOOKING: null,
  CREATE_EXPEDIA_BOOKING_SUCCESS: null,
  CREATE_EXPEDIA_BOOKING_FAILED: null,
}));
