import React, { useState, useEffect, useMemo, useRef } from 'react';
import TravelPolicyConstants from '@/constants/TravelPolicyConstants';
import TravelPolicyStore from '@/stores/TravelPolicyStore';
import TravelPolicyActions from '@/actions/TravelPolicyActions';
import ProfileStore from '@/stores/ProfileStore';
import Currencies from '@/utils/Currencies';
import { formatPlaceName, doesSearchMatchCountry } from './helpers/utils';

import DeleteConfirmationDialog from './DeleteConfirmationDialog';
import AddTravelPolicyDialog from './AddTravelPolicyDialog';
import WorldWideRow from './components/WorldWideRow';
import DividerRow from './components/DividerRow';
import ActionButtons from './components/ActionButtons';
import CountryFlag from './components/CountryFlag';
import { StyledPrimaryActionButton } from './components/styled/buttons';

import SnackbarMessage from '@/components/general/snackbar/SnackbarMessage.react';
import { SnackbarVariants } from '@/components/general/snackbar/SnackbarConstants';

import Analytics from 'arbitrip-common/client/analytics';

import {
	Paper,
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	TextField,
	InputAdornment,
	IconButton,
	Collapse,
	Fade,
	Grow,
} from '@mui/material';

import { ReactComponent as SearchIcon } from '@/img/travel_policy/search.svg';
import { ReactComponent as CloseIcon } from '@/img/icons/x-close.svg';
import { ReactComponent as CityIcon } from '@/img/travel_policy/city.svg';
import { ReactComponent as ExpandMoreIcon } from '@/img/travel_policy/expand_more.svg';
import { ReactComponent as ExpandLessIcon } from '@/img/travel_policy/expand_less.svg';
// import { ReactComponent as PlusIcon } from "@/img/icons/plus.svg";
import PlusIcon from '@/components/general/icons/Plus';

const getState = () => ({
	travelPolicy: TravelPolicyStore.getTravelPolicy(),
	editingStatus: TravelPolicyStore.getEditingStatus(),
	profile: ProfileStore.getProfile(),
});

const TravelPolicyPage = () => {
	const [state, setState] = useState(getState());
	const { travelPolicy, editingStatus, profile } = state;
	const [groupedEntries, setGroupedEntries] = useState(travelPolicy?.groupedEntries || {});
	const [open, setOpen] = useState({});
	// Edit entry
	const [editingId, setEditingId] = useState(null);
	const [editPrice, setEditPrice] = useState();
	const [isSaving, setIsSaving] = useState(false);
	// Search
	const [searchQuery, setSearchQuery] = useState('');
	// Delete confirmation dialog
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
	const [entryToDelete, setEntryToDelete] = useState(null);
	// Add new entry
	const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
	const [newEntryId, setNewEntryId] = useState(null);
	// Snackbar
	const [snackbar, setSnackbar] = useState({
		open: false,
		message: '',
		type: SnackbarVariants.INFO,
	});

	// Refs
	const editInputRef = useRef(null);
	const prevSearchQueryRef = useRef();
	const rowRefs = useRef({});

	useEffect(() => {
		TravelPolicyStore.addChangeListener(_onChange);
		TravelPolicyActions.getTravelPolicy();

		setOpen({});

		Analytics.actions.views.travelPolicyPage(profile);

		return () => {
			TravelPolicyStore.removeChangeListener(_onChange);
		};
	}, []);

	useEffect(() => {
		const entries = travelPolicy?.groupedEntries || {};
		setGroupedEntries(entries);

		let timeoutId;

		if (newEntryId !== null) {
			let foundCountry = null;
			for (const country in travelPolicy?.groupedEntries) {
				if (travelPolicy?.groupedEntries[country].some((entry) => entry._id === newEntryId)) {
					foundCountry = country;
					break;
				}
			}

			if (foundCountry) {
				setOpen((prevOpen) => ({
					...prevOpen,
					[foundCountry]: true,
				}));

				timeoutId = setTimeout(() => {
					if (rowRefs.current[newEntryId]) {
						rowRefs.current[newEntryId].scrollIntoView({
							behavior: 'smooth',
							block: 'center',
						});
					}
					setNewEntryId(null);
				}, 500);
			}
		}

		return () => {
			if (timeoutId) {
				clearTimeout(timeoutId);
			}
		};
	}, [travelPolicy?.groupedEntries]);

	// Edit focus
	useEffect(() => {
		if (editInputRef.current && editingId) {
			editInputRef.current.select();
		}
	}, [editingId]);

	useEffect(() => {
		const handleKeyDown = (event) => {
			if (event.key === 'Escape') {
				if (editingId) {
					onCancelEdit();
				}
			}
		};

		const handleKeyPress = (event) => {
			if (event.key === 'Enter') {
				const activeElement = document.activeElement;
				const isCancelButton =
					activeElement &&
					activeElement.classList.contains('cancel') &&
					activeElement.textContent === 'Cancel';

				if (!isCancelButton) {
					if (editingId && editPrice > 0) {
						onSaveEdit(editingId);
					}
				}
			}
		};

		window.addEventListener('keydown', handleKeyDown);
		window.addEventListener('keypress', handleKeyPress);

		return () => {
			window.removeEventListener('keydown', handleKeyDown);
			window.removeEventListener('keypress', handleKeyPress);
		};
	}, [editingId, editPrice]);

	useEffect(() => {
		if (editingStatus === TravelPolicyConstants.STATUS.BUSY) {
			setIsSaving(true);
		} else if (isSaving) {
			setIsSaving(false);
			setEditingId(null);
		}
	}, [editingStatus, isSaving]);

	const _onChange = () => {
		const newTravelPolicy = TravelPolicyStore.getTravelPolicy();
		const newEditingStatus = TravelPolicyStore.getEditingStatus();

		setState({
			travelPolicy: newTravelPolicy,
			editingStatus: newEditingStatus,
		});

		setOpen((prevOpen) => {
			const newGroupedEntries = newTravelPolicy?.groupedEntries ?? {};
			return Object.keys(newGroupedEntries).reduce((acc, countryName) => {
				acc[countryName] = newGroupedEntries[countryName].length > 0 && (prevOpen[countryName] ?? false);
				return acc;
			}, {});
		});
	};

	const handleCountryClick = (countryName) => {
		const isExpanding = !open[countryName];

		setOpen((prevOpen) => ({
			...prevOpen,
			[countryName]: !prevOpen[countryName],
		}));

		if (isExpanding) {
			setTimeout(() => {
				const rowRef = rowRefs.current[countryName];
				if (rowRef) {
					const rowRect = rowRef.getBoundingClientRect();
					const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

					// Check if the row is in the bottom 30% of the viewport
					const isInBottom30Percent = rowRect.top >= viewportHeight * 0.7;

					if (isInBottom30Percent) {
						rowRef.scrollIntoView({
							behavior: 'smooth',
							block: 'nearest',
						});

						setTimeout(() => {
							const elementPosition = rowRect.top + window.pageYOffset;
							const customOffset = 100;
							const scrollToPosition = elementPosition - customOffset;

							window.scrollTo({
								top: scrollToPosition,
								behavior: 'smooth',
							});
						}, 100);
					}
				}
			}, 300);
		}
	};

	// Edit entry
	const onEditTravelPolicy = (entry) => {
		setEditingId(entry._id);
		setEditPrice(entry.price);
	};

	const onCancelEdit = () => {
		setEditingId(null);
	};

	const onSaveEdit = (id) => {
		TravelPolicyActions.editTravelPolicyEntry(id, editPrice);
	};

	// Delete confirmation dialog
	const handleOpenDeleteDialog = (entryId) => {
		setEntryToDelete(entryId);
		setIsDeleteDialogOpen(true);
	};

	const handleCloseDeleteDialog = () => {
		setIsDeleteDialogOpen(false);
		setEntryToDelete(null);
	};

	// Add new entry
	const handleOpenAddDialog = () => {
		setIsAddDialogOpen(true);
	};

	const handleCloseAddDialog = () => {
		setIsAddDialogOpen(false);
	};

	const handleEntryAdded = (newEntryId) => {
		setNewEntryId(newEntryId);
	};

	// Snackbars
	const showSnackbar = (message, type = SnackbarVariants.INFO) => {
		setSnackbar({ open: true, message, type });
	};

	const handleSnackbarClose = () => {
		setSnackbar({ ...snackbar, open: false });
	};

	// Renders
	const renderPriceOrLoader = (entry) => {
		if (editingId === entry._id && isSaving) {
			return <div />;
		} else if (editingId === entry._id) {
			return (
				<TextField
					className="edit-price-input"
					inputRef={editInputRef}
					type="text"
					value={editPrice}
					onChange={(e) => {
						if (/^\d*$/.test(e.target.value)) {
							setEditPrice(e.target.value);
						}
					}}
					variant="outlined"
					InputProps={{
						startAdornment: (
							<InputAdornment className="currency-symbol" position="start">
								{Currencies.getSymbol(entry.currency)}
							</InputAdornment>
						),
					}}
				/>
			);
		} else {
			return (
				<Grow in={true} timeout={100}>
					<span>
						{Currencies.getSymbol(entry.currency)}
						{entry.price}
					</span>
				</Grow>
			);
		}
	};

	const filteredGroupedEntries = useMemo(() => {
		const sortedCountryNames = Object.keys(groupedEntries).sort();
		const lowerCaseSearchQuery = searchQuery.toLowerCase();

		return sortedCountryNames.reduce((acc, countryName) => {
			const entries = groupedEntries[countryName];

			// Check if the country name matches the search query
			if (doesSearchMatchCountry(lowerCaseSearchQuery, countryName)) {
				acc[countryName] = entries;
			} else {
				// Filter entries based on place name or country match
				const filteredEntries = entries.filter((entry) => {
					const placeName = formatPlaceName(entry.place).toLowerCase();
					return placeName.includes(lowerCaseSearchQuery);
				});

				if (filteredEntries.length > 0) {
					acc[countryName] = filteredEntries;
				}
			}

			return acc;
		}, {});
	}, [groupedEntries, searchQuery]);

	useEffect(() => {
		if (searchQuery) {
			setOpen(
				Object.keys(filteredGroupedEntries).reduce((acc, countryName) => {
					acc[countryName] = true;
					return acc;
				}, {}),
			);
		}

		if (prevSearchQueryRef.current && searchQuery === '') {
			setOpen({});
		}

		prevSearchQueryRef.current = searchQuery;
	}, [filteredGroupedEntries, searchQuery]);

	return (
		<div className="travel-policy-page flex space-between">
			<div className="left-section">
				<span className="header">Travel Policy</span>
				<p className="description">
					Effortlessly manage team budgets by location. Quickly add or update entries to adapt to changing
					needs. Construct efficient guidelines for team flexibility while aligning with organizational goals.
					Travel wisely and stay within budget.
				</p>
				<TextField
					className="search"
					placeholder="Search"
					variant="outlined"
					InputProps={{
						startAdornment: <SearchIcon className="search-icon" />,
						endAdornment: searchQuery && (
							<IconButton className="close-icon" aria-label="Search" onClick={() => setSearchQuery('')}>
								<CloseIcon />
							</IconButton>
						),
					}}
					value={searchQuery}
					onChange={(e) => setSearchQuery(e.target.value)}
				/>
				<StyledPrimaryActionButton onClick={handleOpenAddDialog} fullWidth disableRipple>
					<PlusIcon style={{ marginRight: '8px' }} /> Add location
				</StyledPrimaryActionButton>
			</div>
			<div className="right-section">
				<Fade
					in={!!travelPolicy?.default.price || Object.keys(filteredGroupedEntries).length > 0}
					timeout={500}
				>
					<TableContainer component={Paper} className="travel-policy-table-container">
						<Table size="small" aria-label="travel policy table" className="travel-policy-table">
							<TableBody>
								<WorldWideRow travelPolicy={travelPolicy} />
								<DividerRow height="16px" />
								{/* Rows for countries */}
								{Object.keys(filteredGroupedEntries).map((countryName, index) => (
									<React.Fragment key={countryName}>
										{index > 0 && <DividerRow height="16px" />}
										<TableRow
											ref={(el) => (rowRefs.current[countryName] = el)}
											className="country-row"
											onClick={() => handleCountryClick(countryName)}
										>
											{/* Icon column */}
											<TableCell className="flag-cell">
												<CountryFlag place={groupedEntries[countryName][0].place} />
											</TableCell>
											{/* Country name */}
											<TableCell className="country-name">
												{countryName} ({filteredGroupedEntries[countryName]?.length})
											</TableCell>
											<TableCell />
											{/* Expand/collapse icons */}
											<TableCell align="right">
												<IconButton disableRipple>
													{open[countryName] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
												</IconButton>
											</TableCell>
										</TableRow>

										{/* Rows for entries */}
										<TableRow>
											<TableCell className="collapsible-cell" colSpan={4}>
												<Collapse in={open[countryName]} timeout="auto" unmountOnExit>
													<Table size="small">
														<TableBody>
															{filteredGroupedEntries[countryName].map((entry) => (
																<React.Fragment key={entry._id}>
																	<DividerRow height="8px" />
																	<TableRow
																		className="entry-row"
																		ref={(el) => (rowRefs.current[entry._id] = el)}
																	>
																		{/* Icon column */}
																		<TableCell className="flag-cell">
																			<div className="entry-row-wrapper flex-center">
																				{entry.place.city ||
																				entry.place.state ? (
																					<CityIcon />
																				) : (
																					<CountryFlag place={entry.place} />
																				)}
																			</div>
																		</TableCell>
																		{/* City/Country name */}
																		<TableCell className="place-name">
																			<div className="entry-row-wrapper flex align-center">
																				{formatPlaceName(entry.place)}
																			</div>
																		</TableCell>
																		{/* Price */}
																		<TableCell className="price-cell">
																			<div className="entry-row-wrapper flex align-center align-flex-start">
																				{renderPriceOrLoader(entry)}
																			</div>
																		</TableCell>
																		{/* Actions */}
																		<TableCell
																			className="actions-cell"
																			align="right"
																		>
																			<ActionButtons
																				entry={entry}
																				isEditing={editingId === entry._id}
																				editPrice={editPrice}
																				onSaveEdit={onSaveEdit}
																				onCancelEdit={onCancelEdit}
																				onOpenDeleteDialog={
																					handleOpenDeleteDialog
																				}
																				onEditTravelPolicy={onEditTravelPolicy}
																			/>
																		</TableCell>
																	</TableRow>
																</React.Fragment>
															))}
														</TableBody>
													</Table>
												</Collapse>
											</TableCell>
										</TableRow>
									</React.Fragment>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Fade>
			</div>
			<AddTravelPolicyDialog
				open={isAddDialogOpen}
				onClose={handleCloseAddDialog}
				onSnackbarShow={showSnackbar}
				onEntryAdded={handleEntryAdded}
			/>
			<DeleteConfirmationDialog
				open={isDeleteDialogOpen}
				onClose={handleCloseDeleteDialog}
				onSnackbarShow={showSnackbar}
				entryToDelete={entryToDelete}
			/>
			<SnackbarMessage
				open={snackbar.open}
				onClose={handleSnackbarClose}
				message={snackbar.message}
				variant={snackbar.type}
				anchorPosition={{ vertical: 'bottom', horizontal: 'left' }}
			/>
		</div>
	);
};

export default TravelPolicyPage;
