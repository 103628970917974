import React, { useEffect } from 'react';

const DocumentTitle = ({ title, children }) => {
	useEffect(() => {
		document.title = title;
	}, []);

	useEffect(() => {
		document.title = title;
	}, [title]);

	return React.Children.only(children);
};

export default DocumentTitle;
