const _ = require('lodash');
const moment = require('moment');
const Deal = require('./Deal');
const { DEAL_STATUS } = require('../constants/DealConstants');

function dealSupplierExtractor(deal) {
  const deal_id = deal && deal.id;
  if (!deal_id) {
    return {};
  }
  let supplier = deal_id.substr(0, 1);// backward compatible
  const splitted = deal_id.split(':');
  const supplier_hub_code = deal.supplier_hub_code;
  if (splitted.length > 1) {
    supplier = splitted[0];
  }
  if (supplier === 'H') {// backward compatible
    supplier = 'HB';
  }
  return { supplier, supplier_hub_code };
}

function isValidDeal(deal) {
  if (!deal || !deal.id) {
    //console.error('invalid deal received: ', deal);
    return false;
  }
  return true;
}

function isValidRecheck(recheck) {
  return recheck && recheck.recheck_id && recheck.chooseprod && recheck.cheapopoPricing;
}

function createDeal(deal, idx) {
  return new Deal(deal, idx + 1); // 1 based
}

function createDeals(deals) {
  if (!Array.isArray(deals)) {
    // console.error('invalid deals received: ', deals);
    return [];
  }

  return deals.filter(isValidDeal).map(createDeal);
}

function isDeal1BetterThanDeal2(deal1, deal2) {
  // Is there any dimension where deal1 is better than deal2?
  if (deal1.totalPrice < deal2.totalPrice) {
    return true;
  }

  if (deal1.board_bases.all_inclusive && !deal2.board_bases.all_inclusive) {
    return true;
  }

  if (deal1.board_bases.full_board && !deal2.board_bases.full_board) {
    return true;
  }

  if (deal1.board_bases.half_board && !deal2.board_bases.half_board) {
    return true;
  }

  if (deal1.breakfast && !deal2.breakfast) {
    return true;
  }

  if (!deal1.chooseprod.dca.nonRefundable && deal2.chooseprod.dca.nonRefundable) {
    return true;
  }

  if (moment(deal1.chooseprod.dca.dealIsFullyRefundableTill).isAfter(moment(deal2.chooseprod.dca.dealIsFullyRefundableTill))) {
    return true;
  }

  return false;
}

function isDealSuperseded(deal1, deal2) {
  // if deal2 is better than deal1 return positive
  // if deal1 is better thant deal 2 return negative
  // otherwise (non comparable) return 0
  if (deal1.room_group !== deal2.room_group) {
    return 0;
  }

  const isDeal1Better = isDeal1BetterThanDeal2(deal1, deal2);
  const isDeal2Better = isDeal1BetterThanDeal2(deal2, deal1);

  if (isDeal1Better && isDeal2Better) {
    return 0;
  }

  if (isDeal1Better && !isDeal2Better) {
    return -1;
  }

  if (isDeal2Better && !isDeal1Better) {
    return 1;
  }

  return deal1.id.substring(2) < deal2.id.substring(2); // in case of ties use id as arbiter. Disregard the supplier reference
}

function updateDealRecheck(deal, recheck) {
  deal.setRecheck(recheck.recheck_id, recheck.chooseprod, recheck.cheapopoPricing, recheck.installments_eligibility);
  deal.status = DEAL_STATUS.SUCCESS;
  if (deal.error) {
    deal.error = false;
  }
  return deal;
}

function createRecheckDeal(recheck, idx) {
  const deal = createDeal(recheck, idx);
  return updateDealRecheck(deal, recheck);
}

function compareByPrice(dealA, dealB) {
  return dealA.totalPrice - dealB.totalPrice;
}

module.exports = {
  dealSupplierExtractor,
  isValidDeal,
  isValidRecheck,
  createDeal,
  createDeals,
  createRecheckDeal,
  updateDealRecheck,
  compareByPrice
};
