import './sass/index.scss';

import * as serviceWorker from './serviceWorker';

import App from './utils/App';

import EventEmitter from 'events';

import 'arbitrip-common/translation/desktop.js';
import Config from 'arbitrip-common/client/utils/Config';
import { createRoot } from 'react-dom/client';
import React from 'react';

import { initSentry } from '@/utils/SentryConfig';

if (Config.prod === true) {
	console.debug('Are you into Bed & Breakfast Engineering? send your CV now to jobs@arbitrip.com :)');
}

EventEmitter._maxListeners = 100;

initSentry();

// init App
const root = createRoot(document.getElementById('app-container'));
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
