/**
 * A small size deal for list purposes
 * The deal is saved in local storage so we want to keep it small
 */
const _ = require('lodash');
const moment = require('moment');
const RouteBuilder = require('../../general/utils/RouteBuilder');
const { default: ClientPriceService } = require('../utils/ClientPriceService');

function extractPlaceId(searchTerms) {
    return _.get(searchTerms, 'destination.place.place_id')
        || _.get(searchTerms, 'destination.place_id')
        || _.get(searchTerms, 'place_id', '');
}

class BasketDeal {
    constructor(deal, hotelInfo, searchTerms, travelPolicyExists, changeClientPriceEnabled, contractClientCompanyId) {
        if (!deal || !deal.id) {
            return console.error('Cannot build deal object, no payload provided');
        }

        this.id = _.get(deal, 'id');
        this.place_id = extractPlaceId(searchTerms);
        this.totalPrice = _.get(deal, 'totalPrice');
        this.pricePerNight = _.get(deal, 'pricePerNight');
        this.currency = _.get(deal, 'currency');
        this.room = _.get(deal, 'room');
        this.check_in = _.get(deal, 'check_in');
        this.check_out = _.get(deal, 'check_out');
        this.nights = _.get(deal, 'nights');
        this.catering = _.get(deal, 'catering');
        this.breakfast = _.get(deal, 'breakfast');

        this.hotel = {
            id: _.get(hotelInfo, 'id'),
            name: _.get(hotelInfo, 'name'),
            stars: _.get(hotelInfo, 'stars'),
            address: _.get(hotelInfo, 'address'),
            city: _.get(hotelInfo, 'city'),
            country: _.get(hotelInfo, 'country'),
            review: {
                score: _.get(hotelInfo, 'review.score'),
                description: _.get(hotelInfo, 'review.description')
            }
        };

        this.chooseprod = {
            dca: {
                dealIsFullyRefundableTill: _.get(deal, 'original.chooseprod.dca.dealIsFullyRefundableTill', null) || _.get(deal, 'chooseprod.dca.dealIsFullyRefundableTill', null),
                cancellationPoliciesText: _.get(deal, 'original.chooseprod.dca.cancellationPoliciesText', null) || _.get(deal, 'chooseprod.dca.cancellationPoliciesText', null),
                cancellationPoliciesMultiLanguageTexts: _.get(deal, 'original.chooseprod.dca.cancellationPoliciesMultiLanguageTexts', null) || _.get(deal, 'chooseprod.dca.cancellationPoliciesMultiLanguageTexts', null),
                nonRefundable: _.get(deal, 'original.chooseprod.dca.nonRefundable', null) || _.get(deal, 'chooseprod.dca.nonRefundable', null)
            }
        };

        this.dca = {
            heuristicOptions: {
                includesBreakfast: _.get(deal, 'dca.heuristicOptions.includesBreakfast', false),
                isHalfBoard: _.get(deal, 'dca.heuristicOptions.isHalfBoard', false),
                isFullBoard: _.get(deal, 'dca.heuristicOptions.isFullBoard', false),
                isAllInclusive: _.get(deal, 'dca.heuristicOptions.isAllInclusive', false),
            }
        };

        this.details = {
            breakfast_included: _.get(deal, 'details.breakfast_included', false)
        };

        searchTerms.guests = _.get(searchTerms, 'guest_count');
        searchTerms.rooms = _.get(searchTerms, 'room_count');
        searchTerms.check_in = moment.utc(searchTerms.check_in, 'YYYY-MM-DD').format('YYMMDD');
        searchTerms.check_out = moment.utc(searchTerms.check_out, 'YYYY-MM-DD').format('YYMMDD');

        this.url = RouteBuilder.buildHotelSearchURL(hotelInfo, searchTerms);
        // this.travel_policy_exists

        this.guests = _.get(deal, 'guestsPerRoom');
        this.rooms = _.get(deal, 'rooms');
        this.children_ages = _.get(deal, 'children_ages');
        this.private_travel = _.get(deal, 'private_travel');

        if (_.isBoolean(travelPolicyExists)) {
            this.travel_policy_exists = travelPolicyExists;
            this.in_policy = _.get(deal, 'in_policy');
        }

        if (changeClientPriceEnabled) {
            this.change_client_price_enabled = true;
        }

        this.net_price_total = deal.net_price_total;
        this.agent_pricing = deal.agent_pricing;
        this.commission_total = deal.commission_total;

        if (contractClientCompanyId) {
            this.contract_client_company_id = contractClientCompanyId;
        }
    }

    setAgentPricing(selfContract) {
        this.agent_pricing = ClientPriceService.getAgentPricingBySelfContract(this, selfContract);
    }
}

module.exports = BasketDeal;
