import AppDispatcher from '../dispatcher/AppDispatcher';
import SearchService from '../utils/SearchService';
import RecentSearchesConstants from '../constants/RecentSearchesConstants';
import DestinationsConstants from '../constants/DestinationsConstants';
import _ from 'lodash';
import Dates from '../utils/Dates';
import ProfileStore from './ProfileStore';
import TokenGenerator from 'arbitrip-common/client/utils/TokenGenerator';
import SEARCH_CONSTANTS from 'arbitrip-common/general/constants/search-constants';

import { EventEmitter } from 'events';
import {
	// WebStorageManager,
	PlacesUtils,
} from 'arbitrip-common/client/utils';

const Validator = require('arbitrip-common/client/utils/Validator');

// Init data
let StoreData = {
	search_token: null,
	terms: null,
	destination: null,
	hotel_id: null,
	address: null,
	check_in: null,
	check_out: null,
	guests: 1,
	children_ages: [],
	rooms: 1,
	traveler: null,
	contract_id: '',
	contract_client_name: '',
	contract_client_company_id: '',
	private_travel: false,
	derived_from: '',
	combtas_id: '',
	travel_booster_docket_number: '',
	proposal_id: '',
	hotel_search_token: null,
	user_currency: null,
	is_show_hotel_loader: false,
};

let _extra = {
	travelers: null,
	quote_id: null,
	management_trip_name: '',
	management_trip_id: null,
};

let _leisure_experience = false;

function loadSearchTermsData(data) {
	StoreData.terms = data;

	if (!_.isEmpty(data)) {
		StoreData.terms.timestamp = new Date();
		setPrivateTravel(StoreData.terms.private_travel);
	}
}

function loadRecentSearchTermsData(data) {
	loadSearchTermsData(data);
}

function setPrivateTravel(data) {
	if (ProfileStore.forcePrivateTravel()) {
		StoreData.private_travel = true;
	} else {
		if (_.isUndefined(data) || _.isNull(data)) {
			data = false;
		}
		StoreData.private_travel = data;
	}
}

function isValidRooms(rooms) {
	let number_of_rooms = parseInt(rooms);
	return !isNaN(number_of_rooms) && number_of_rooms >= 1 && number_of_rooms <= SEARCH_CONSTANTS.MAX.ROOMS;
}

function isValidGuests(guests) {
	let number_of_guests = parseInt(guests);

	const profile = ProfileStore.getProfile() || {};
	const maxAdults = profile?.agent ? SEARCH_CONSTANTS.MAX.ADULTS_IF_AGENT : SEARCH_CONSTANTS.MAX.ADULTS;

	return !isNaN(number_of_guests) && number_of_guests >= 1 && number_of_guests <= maxAdults;
}

function isValidChildrenAges(children_ages) {
	return (
		_.isArray(children_ages) &&
		children_ages.length <= SEARCH_CONSTANTS.MAX.CHILDREN &&
		children_ages.every(Validator.validateAge)
	);
}

function getCheckInFormatted() {
	return Dates.reformatDate(StoreData.check_in);
}

function getCheckOutFormatted() {
	return Dates.reformatDate(StoreData.check_out);
}

// function getDefaultContractV1(profile) {
//     return profile.agency_contracts.find(c => c.company._id === profile.company.id);
// }

// function getDefaultContractV2(profile) {
//     return profile.agency_contracts[0];
// }

// function getDefaultContractV3(profile) {
//     if (_.isArray(profile.agency_contracts)) {
//         const sorted = _.sortBy(profile.agency_contracts, 'name');
//         return sorted[0];
//     }

//     return null;
// }

// function getDefaultContractV4(profile) {
//     // 1. is connected user an account manager of one of the contracts?
//     // 2. identify default contract - put first
//     // 3. rest of sort by abc

//     // TODO: is profile account manager of one of the contracts?
//     if (_.isArray(profile.agency_contracts)) {
//         for (const contract of profile.agency_contracts) {
//             if (contract.account_manager === profile.id) {
//                 return contract;
//             }
//         }

//         for (const contract of profile.agency_contracts) {
//             if (contract.travel_agency === profile.company.id) {
//                 return contract;
//             }
//         }

//         return profile.agency_contracts[0];
//     }

//     return null;
// }

function getDefaultContractV5(profile) {
	// 1. is connected user an account manager / other account manager of *exactly* one of the contracts?
	// 2. self contract

	if (_.isArray(profile.agency_contracts)) {
		const agency_allocation = _.get(profile, 'agency_settings.agency_allocation', false);

		if (agency_allocation) {
			const selected_contracts = profile.agency_contracts.filter((c) => {
				if (!c) {
					return false;
				}
				return (c.other_account_managers || []).concat([c.account_manager]).some((am) => am === profile.id);
			});

			if (selected_contracts.length === 1) {
				return selected_contracts[0];
			}
		}

		// finds self contract
		return profile.agency_contracts.find(
			(c) => c && c.travel_agency === c.company._id && c.company._id === profile.company.id,
		);
	}

	return null;
}

function getDefaultContract(profile) {
	return getDefaultContractV5(profile);
}

function resetContractToDefaultIfNeeded() {
	if (!StoreData.contract_id) {
		let profile = ProfileStore.getProfile();
		if (profile.agent && Array.isArray(profile.agency_contracts)) {
			let search_for_contract = getDefaultContract(profile);
			StoreData.contract_id = _.get(search_for_contract, '_id');
			StoreData.contract_client_name = _.get(search_for_contract, 'company.name');
			StoreData.contract_client_company_id = _.get(search_for_contract, 'company._id');
		}
	}
}

function searchTermsToHotelsAjaxPayload() {
	resetContractToDefaultIfNeeded();

	return {
		contract_id: StoreData.contract_id,
		destination: StoreData.destination,
	};
}

function searchTermsAjaxPayload() {
	//hack - we want the search terms to be returned even if it wasn't explicitly selected
	resetContractToDefaultIfNeeded();

	return {
		destination: StoreData.destination,
		check_in: getCheckInFormatted(),
		check_out: getCheckOutFormatted(),
		guests: StoreData.guests,
		children_ages: StoreData.children_ages,
		rooms: StoreData.rooms,
		contract_id: StoreData.contract_id,
		private_travel: StoreData.private_travel,
		// recommendation_profile: WebStorageManager.getFromWebStorage('_recommendation_profile'),
		// recommendation_place: WebStorageManager.getFromWebStorage('_recommendation_place'),
		// recommendation_mode: WebStorageManager.getFromWebStorage('_recommendation_mode')
	};
}

function clearChildrenData() {
	StoreData.children_ages = [];
}

function clearSearchTerms() {
	StoreData.destination = null;
	StoreData.address = null;
	StoreData.hotel_id = null;
	StoreData.check_in = null;
	StoreData.check_out = null;
	StoreData.guests = _leisure_experience ? 2 : 1;
	StoreData.children_ages = [];
	StoreData.rooms = 1;
	StoreData.traveler = null;
	StoreData.private_travel = false;
	StoreData.derived_from = '';
	StoreData.combtas_id = '';
	StoreData.proposal_id = '';
	StoreData.travel_booster_docket_number = '';
	StoreData.user_currency = null;
}

clearSearchTerms();

// Extend Search Store with EventEmitter to add eventing capabilities
const SearchStore = _.extend({}, EventEmitter.prototype, {
	newToken() {
		const search_terms = this.getSearchTerms();
		StoreData.search_token = TokenGenerator.generateParameterizedToken(search_terms);
		return StoreData.search_token;
	},

	newHotelToken(hotelId) {
		const search_token = SearchStore.newToken();
		StoreData.hotel_search_token = `${search_token}-${hotelId}`;
		return StoreData.hotel_search_token;
	},

	getToken() {
		return StoreData.search_token;
	},

	getHotelToken() {
		return StoreData.hotel_search_token;
	},

	getSignedParams() {
		const search_terms = this.getSearchTerms();
		return TokenGenerator.getSignedParams(search_terms);
	},

	getSearchTerms() {
		return _.extend({}, _extra, {
			address: StoreData.address,
			destination: StoreData.destination,
			hotel_id: StoreData.hotel_id,
			check_in: StoreData.check_in,
			check_out: StoreData.check_out,
			guests: StoreData.guests,
			children_ages: StoreData.children_ages,
			rooms: StoreData.rooms,
			contract_id: StoreData.contract_id,
			traveler: StoreData.traveler,
			private_travel: StoreData.private_travel,
			derived_from: StoreData.derived_from,
			combtas_id: StoreData.combtas_id,
			proposal_id: StoreData.proposal_id,
			travel_booster_docket_number: StoreData.travel_booster_docket_number,
			user_currency: StoreData.user_currency,
		});
	},

	updateGuests(data) {
		if (!data) {
			return;
		}

		_leisure_experience = _.get(data, 'company.settings.leisure_experience', false);

		if (_leisure_experience && !StoreData.guests) {
			// update guests when _leisure_experience is true
			updateGuestsSearchTerm(2);
			SearchStore.emitChange();
		}
	},

	toAjaxPayload(search_type, hotel_id) {
		switch (search_type) {
			case SEARCH_CONSTANTS.SEARCH_TYPE_HOTELS:
				return _.extend({}, searchTermsToHotelsAjaxPayload(), {
					token: StoreData.search_token,
				});

			case SEARCH_CONSTANTS.SEARCH_TYPE_HOTEL_PAGE:
				return _.extend({}, searchTermsAjaxPayload(), {
					token: StoreData.hotel_search_token,
					search_type,
					log_search: true,
				});

			default:
				return _.extend({}, searchTermsAjaxPayload(), {
					token: StoreData.search_token,
					search_type,
					log_search: true,
				});
		}
	},

	getDestination() {
		return StoreData.destination;
	},

	getDestinationAddress() {
		return (StoreData.destination && StoreData.destination.address) || null;
	},

	isPrivateTravel() {
		return ProfileStore.isPrivateTravel() || StoreData.private_travel;
	},

	isShowHotelLoader() {
		return StoreData.is_show_hotel_loader;
	},

	isSpecificSearch() {
		const place = _.get(StoreData, 'destination.place');
		return PlacesUtils.isSpecificPlace(place);
	},

	isValid: function () {
		return SearchService.isValid(StoreData);
	},

	getExtraData() {
		return _extra;
	},

	getCheckInFormatted: getCheckInFormatted, // for sending across network
	getCheckOutFormatted: getCheckOutFormatted, // for sending across network

	getGuests() {
		return StoreData.guests;
	},

	getChildrenAges() {
		return StoreData.children_ages;
	},

	getRooms() {
		return StoreData.rooms;
	},

	getContractId() {
		return StoreData.contract_id;
	},

	getDefaultContractId(profile) {
		const default_contract = getDefaultContract(profile);

		return _.get(default_contract, '_id', '');
	},

	getContractClientName() {
		return StoreData.contract_client_name;
	},

	getContractClientCompanyId() {
		return StoreData.contract_client_company_id;
	},

	getDerivedFrom() {
		return StoreData.derived_from;
	},

	getCombtasId() {
		return StoreData.combtas_id;
	},

	getTravelBoosterDocketNumber() {
		return StoreData.travel_booster_docket_number;
	},

	getProposalId() {
		return StoreData.proposal_id;
	},

	getPrivateTravel() {
		return StoreData.private_travel;
	},

	// Emit Change event
	emitChange() {
		this.emit('change');
	},

	// Add change listener
	addChangeListener(callback) {
		this.on('change', callback);
	},

	// Remove change listener
	removeChangeListener(callback) {
		this.removeListener('change', callback);
	},
});

// Register callback with AppDispatcher
AppDispatcher.register(function (payload) {
	const action = payload.action;

	switch (action.actionType) {
		case SEARCH_CONSTANTS.RECEIVE_SEARCH_TERMS_DATA:
			loadSearchTermsData(action.data);
			break;

		case DestinationsConstants.CHOOSE_DESTINATION:
			StoreData = SearchService.setDestination(StoreData, action.data);
			break;

		case RecentSearchesConstants.RECEIVE_RECENT_SEARCHES_TERMS:
			loadRecentSearchTermsData(action.data);
			break;

		////////////////////////////////////////

		case SEARCH_CONSTANTS.UPDATE_SEARCH_DATA:
			StoreData = SearchService.setDestination(StoreData, action.data.destination);
			StoreData = SearchService.updateCheckIn(StoreData, action.data.check_in);
			StoreData = SearchService.setCheckOut(StoreData, action.data.check_out);
			StoreData = SearchService.updateGuests(StoreData, { guests: action.data.guests });
			StoreData = SearchService.updateRooms(StoreData, action.data.rooms);
			StoreData = SearchService.updateChildrenAges(StoreData, action.data.children_ages);
			break;

		case SEARCH_CONSTANTS.UPDATE_SEARCH_DESTINATION_DATA:
			StoreData = SearchService.setDestination(StoreData, action.data);
			break;

		case SEARCH_CONSTANTS.UPDATE_CHECK_IN:
			StoreData = SearchService.updateCheckIn(StoreData, action.data);
			break;

		case SEARCH_CONSTANTS.UPDATE_CHECK_OUT:
			StoreData = SearchService.setCheckOut(StoreData, action.data);
			break;

		case SEARCH_CONSTANTS.UPDATE_GUESTS:
			StoreData = SearchService.updateGuests(StoreData, { guests: action.data });
			break;

		case SEARCH_CONSTANTS.UPDATE_ROOMS:
			StoreData = SearchService.updateRooms(StoreData, action.data);
			break;

		case SEARCH_CONSTANTS.UPDATE_CHILDREN_AGES:
			StoreData = SearchService.updateChildrenAges(StoreData, action.data);
			break;

		case SEARCH_CONSTANTS.UPDATE_PRIVATE_TRAVEL:
			setPrivateTravel(action.data);
			break;

		case SEARCH_CONSTANTS.UPDATE_SEARCH_CONTRACT:
			StoreData.contract_id = action.data._id;
			StoreData.contract_client_name = action.data.company.name;
			StoreData.contract_client_company_id = action.data.company._id;
			break;

		case SEARCH_CONSTANTS.SHOW_HOTEL_LOADER:
			StoreData.is_show_hotel_loader = !!action.data;
			break;

		case SEARCH_CONSTANTS.UPDATE_ALL_SEARCH_TERMS_DATA:
			updateAllSearchTerms(action.data);
			if (!StoreData.search_token) {
				SearchStore.newToken();
			}
			break;

		case SEARCH_CONSTANTS.UPDATE_ALL_SEARCH_TERMS_DATA_VIA_URL:
			loadSearchTermsData(action.data);
			updateAllSearchTerms(action.data);
			if (!StoreData.search_token) {
				SearchStore.newToken();
			}
			break;

		case SEARCH_CONSTANTS.CLEAR_CHILDREN_DATA:
			clearChildrenData();
			break;

		case SEARCH_CONSTANTS.CLEAR_ALL_SEARCH_TERMS_DATA:
			clearSearchTerms();
			break;

		////////////////////////////////////////

		case SEARCH_CONSTANTS.UPDATE_SEARCH_CONTRACT_BY_ID:
			if (StoreData.contract_id) {
				let profile = ProfileStore.getProfile();
				if (profile.agent && Array.isArray(profile.agency_contracts)) {
					let search_for_contract = profile.agency_contracts.find(
						(c) => c && c._id === StoreData.contract_id,
					);
					let contract_company_id = _.get(search_for_contract, 'company._id');

					if (contract_company_id && StoreData.contract_client_company_id !== contract_company_id) {
						StoreData.contract_client_name = search_for_contract.company.name;
						StoreData.contract_client_company_id = search_for_contract.company._id;
					} else {
						return true;
					}
				}
			}
			break;

		////////////////////////////////////////

		default:
			return true;
	}

	// If action was responded to, emit change event
	SearchStore.emitChange();

	return true;
});

function updateGuestsSearchTerm(guests) {
	if (isValidGuests(guests)) {
		StoreData.guests = guests;
	}
}

function updateAllSearchTerms(data) {
	StoreData = SearchService.setDestination(StoreData, data.destination);
	if (data.check_in) {
		StoreData = SearchService.setCheckIn(StoreData, data.check_in);
	}
	if (data.check_out) {
		StoreData = SearchService.setCheckOut(StoreData, data.check_out);
	}
	if (isValidRooms(data.rooms)) {
		StoreData.rooms = data.rooms;
	}
	updateGuestsSearchTerm(data.guests);

	if (isValidChildrenAges(data.children_ages)) {
		// Detach reference to array
		StoreData.children_ages = _.clone(data.children_ages);
	}

	if (data.contract_id) {
		StoreData.contract_id = data.contract_id;
	}

	if (data.travelers) {
		_extra.travelers = data.travelers;
	}

	if (data.management_trip_id) {
		_extra.management_trip_id = data.management_trip_id;
	}

	if (data.management_trip_name) {
		_extra.management_trip_name = data.management_trip_name;
	}

	if (data.quote_id) {
		_extra.quote_id = data.quote_id;
	}

	if (data.group_id) {
		_extra.group_id = data.group_id;
	}

	if (data.search_token) {
		StoreData.search_token = data.search_token;
	}

	if (data.derived_from) {
		StoreData.derived_from = data.derived_from;
	}

	if (data.combtas_id) {
		StoreData.combtas_id = data.combtas_id;

		if (data.proposal_id) {
			StoreData.proposal_id = data.proposal_id;
		}
	}

	if (data.travel_booster_docket_number) {
		StoreData.travel_booster_docket_number = data.travel_booster_docket_number;
	}

	if (data.hotel_search_token) {
		StoreData.hotel_search_token = data.hotel_search_token;
	}

	StoreData.user_currency = ProfileStore.getUserCurrency();

	setPrivateTravel(data.private_travel);

	resetContractToDefaultIfNeeded();
}

export default SearchStore;
