const _ = require('lodash');
const moment = require('moment');
const { v4: uuidV4 } = require('uuid');

const LocationService = require('./LocationService');
const Base64 = require('../../general/utils/Base64');

const date_format = 'YYMMDD';

function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
}

function generateToken() {
    return s4() + s4() + s4() + s4() + s4();
}

function getSignedParams(searchTerms) {
    const {
        destination,
        check_in,
        check_out,
        guests,
        rooms,
        children_ages,
        private_travel,
        user_currency
    } = searchTerms;

    try {
        const hotel_id = _.get(destination, 'place.hotel_id');
        const place_id = _.get(destination, 'place.place_id');

        let s = [
            hotel_id || place_id,
            moment.utc(check_in).format(date_format),
            moment.utc(check_out).format(date_format),
            guests,
            children_ages,
            !!private_travel,
            rooms,
            user_currency,
        ].join('|');

        let params_signed = Base64.encode(s);
        return params_signed;
    } catch (err) {
        console.error(err);
    }
}

function generateParameterizedToken(searchTerms) {
    return generateToken() + '-' + getSignedParams(searchTerms);
}

function generateSessionId() {
    return uuidV4();
}

module.exports = {
    generateToken,
    getSignedParams,
    generateParameterizedToken,
    generateSessionId,
    // TokenGenerator
}