import _ from 'lodash';
import moment from 'moment';

import createClass from 'create-react-class';
import ReservationStore from '../../../../stores/ReservationStore';
import Currencies from '../../../../utils/Currencies';
import { Dates as DateUtils } from 'arbitrip-common/client/utils';

import ResultsStore from '../../../../stores/ResultsStore';
import RouterWrapper from '../../../../utils/RouterWrapper';
import { withRouter } from '../../../../utils/withRouter';
import { Tooltip } from '@mui/material';
import PriceBreakdown from 'arbitrip-common/client/utils/PriceBreakdown';

const pluralize = (count, noun, suffix = 's') => `${count} ${noun}${count !== 1 ? suffix : ''}`;

const formatDate = (date) => date && moment(date).format('DD MMM YYYY');

// Method to retrieve state from Stores
function getReservationSummaryState() {
	const reservation_data = ReservationStore.getReservationData();
	const hotel_id = _.get(reservation_data, 'hotel.id');

	return {
		should_render: true,
		arbitrip_points_applied: ResultsStore.areArbitripPointsApplied(),
		results_hotel: ResultsStore.getHotelById(hotel_id),
	};
}

const ExpediaDealDetailsContainer = createClass({
	displayName: 'ExpediaDealDetailsContainer',

	// Get initial state from stores
	getInitialState: function () {
		return getReservationSummaryState();
	},

	// Add change listeners to stores
	componentDidMount: function () {
		const { reservation } = this.props;

		if (!_.get(reservation, 'deal.chooseprod')) {
			this.setState({
				should_render: false,
			});
		}

		ReservationStore.addChangeListener(this._onChange);
		ResultsStore.addChangeListener(this._onChange);
	},

	// Remove change listers from stores
	componentWillUnmount: function () {
		ReservationStore.removeChangeListener(this._onChange);
		ResultsStore.removeChangeListener(this._onChange);
	},

	getInDisplayCurrency(price, rate, display_currency) {
		return Currencies.getPriceWithDisplayCurrencyByPrecision(price, rate, display_currency, 2);
	},

	getPriceString(price, currency, show_zero = true) {
		return price
			? Currencies.getPrecisePriceWithCurrency(price, currency)
			: show_zero
				? Currencies.getRoundedPriceWithCurrency(0, currency, 0)
				: '';
	},

	render() {
		const { reservation, navigate, profile, isConfirmation } = this.props;
		if (_.isEmpty(reservation.deal)) {
			RouterWrapper.goToSearchPage(navigate);
			return;
		}

		if (!this.state.should_render) {
			return null;
		}

		const DEAL = reservation.deal;
		const pricing = DEAL.expedia_pricing;

		if (!pricing) {
			return null;
		}

		const checkIn = DateUtils.parseFormatDate(DEAL.check_in);
		const checkOut = DateUtils.parseFormatDate(DEAL.check_out);
		const nights = checkOut.diff(checkIn, 'days');
		const showAvgNightPrice = nights > 1;
		const rooms = DEAL.rooms;
		const NIGHTS_ROOMS_TEXT = `${pluralize(nights, 'Night')} ${pluralize(rooms, 'Room')}`;
		const NIGHTS_ROOMS_PRICE = this.getInDisplayCurrency(
			pricing.exclusivePrice,
			profile.display_currency_exchange_rate,
			profile.display_currency,
		);

		const PRICE_PER_NIGHT = this.getInDisplayCurrency(
			pricing.pricePerNight,
			profile.display_currency_exchange_rate,
			profile.display_currency,
		);

		const taxServiceFeeExist = !!pricing.taxesAndFees;
		const TAX_SERVICE_FEE = this.getInDisplayCurrency(
			pricing.taxesAndFees,
			profile.display_currency_exchange_rate,
			profile.display_currency,
		);

		const TOTAL = this.getInDisplayCurrency(
			pricing.totalPrice,
			profile.display_currency_exchange_rate,
			profile.display_currency,
		);

		const payAtHotelExist = !!pricing.totalPayLater;
		const TOTAL_PAY_AT_HOTEL = this.getPriceString(pricing.totalPayLater, pricing.totalPayLaterCurrency);

		const showPayNow = !!pricing.totalPayNow;
		const TOTAL_PAY_NOW = this.getInDisplayCurrency(
			pricing.totalPayNow,
			profile.display_currency_exchange_rate,
			profile.display_currency,
		);

		return (
			<div className="deal-details-break-price">
				{!isConfirmation && (
					<>
						<div className="space-container total">Price details</div>
						<div className="space-container line" />
					</>
				)}
				<div className="flex space-between space-container">
					<span>Check-in</span>
					<span className="number">{formatDate(checkIn)}</span>
				</div>
				<div className="flex space-between space-container">
					<span>Check-out</span>
					<span className="number">{formatDate(checkOut)}</span>
				</div>
				<div className="flex space-between space-container line" />
				<div className="flex space-between space-container">
					<span>{NIGHTS_ROOMS_TEXT}</span>
					<span className="number">{NIGHTS_ROOMS_PRICE}</span>
				</div>
				{showAvgNightPrice && (
					<div className="flex space-between space-container">
						<span>Avg price per night</span>
						<span className="number">{PRICE_PER_NIGHT}</span>
					</div>
				)}
				<div className="flex space-between space-container line" />
				{taxServiceFeeExist ? (
					<div className="flex space-between space-container">
						<Tooltip title={PriceBreakdown.EXPEDIA_TOOLTIP_TEXT} placement="bottom-end" arrow>
							<div className={'tooltip-icon text'}>Taxes and Fees</div>
						</Tooltip>
						<span className="number">{TAX_SERVICE_FEE}</span>
					</div>
				) : null}

				<div className="flex space-between space-container line" />

				{showPayNow ? (
					<div className="flex space-between space-container pay">
						<span>{isConfirmation ? 'Paid' : 'Pay now'}</span>
						<span className="number">{TOTAL_PAY_NOW}</span>
					</div>
				) : null}
				{payAtHotelExist ? (
					<div className="flex space-between space-container">
						<span className="sales-tax">Pay at the property</span>
						<span className="number">{TOTAL_PAY_AT_HOTEL}</span>
					</div>
				) : null}

				<div className="flex space-between space-container total">
					<span>{isConfirmation ? 'Total price' : 'Total to pay'}</span>
					<span>{TOTAL}</span>
				</div>
			</div>
		);
	},

	// Method to setState based upon Store changes
	_onChange: function () {
		this.setState(getReservationSummaryState());
	},
});

export default withRouter(ExpediaDealDetailsContainer);
