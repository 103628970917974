const keyMirror = require('keymirror');

export const EVENTS = keyMirror({
    RESULTS_SORTED: null,
    RESULTS_FILTERED: null,
    RESULTS_CLEARED_FILTERS: null,
    FILTERED_DEALS: null,
    SELECTED_HOTEL: null,
    SELECTED_DEAL: null,
    SELECTED_ROOM_FROM_CATALOG: null,
    RESULTS_HAVE_EXPIRED: null,
    SESSION_HAS_EXPIRED: null,
    SESSION_EXPIRED_LOGIN_CLICKED: null,
    RESULTS_FETCHING_TIME: null,
    SEARCH_WITHOUT_RESULTS: null,
    SEARCH_WITHOUT_RESULTS_HOTEL: null,
    RATE_UNAVAILABLE: null,
    HOTEL_NAME_FILTER_OPENED: null,
    HOTEL_NAME_FILTER_CLOSED: null,
    HOTEL_SUGGESTION_SELECTED: null,
    SEARCHED_HOTEL_WITHOUT_DEALS: null,
});