import { ReactComponent as NoBookingsIcon } from '@/img/bookings/no-bookings.svg';
import { ReactComponent as NoBookingsFilterIcon } from '@/img/bookings/no-bookings-filter.svg';

const NoBookingsMessage = ({ iconType, message, subMessage }) => {
	const Icon = iconType === 'filter' ? NoBookingsFilterIcon : NoBookingsIcon;

	return (
		<div className="no-bookings">
			<Icon />
			<div>
				{message}
				<br />
				{subMessage}
			</div>
		</div>
	);
};

export default NoBookingsMessage;
