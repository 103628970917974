import { useState, useEffect } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { Paper, TextField, InputAdornment, CircularProgress } from '@mui/material';
import AddAirfareFlight from './AddAirfareFlight';
import ProfileStore from '@/stores/ProfileStore';
import ManagementStore from '@/stores/ManagementStore';
import ManagementActions from '@/actions/ManagementActions';
import ManagementConstants from '@/constants/ManagementConstants';
import TitleManager from '@/utils/TitleManager';
import Currencies from '@/utils/Currencies';
import Validator from 'arbitrip-common/client/utils/Validator';
import DocumentTitle from '@/components/general/DocumentTitle.react';
import { ArbiPrimaryButton, ArbiCancelButton } from '@/components/general/styled/buttons';
import { TripActionButton } from './components/action_buttons';
import Plus from '@/components/general/icons/Plus';

const COST_IS_REQUIRED = 'Cost is required!';

const flightIsValid = (wizard_flight) =>
	wizard_flight &&
	Validator.validateFlightNumber(wizard_flight.flight_number) &&
	moment(wizard_flight.departure_date).isValid();

const flightsAreValid = (flights) => Array.isArray(flights) && flights.length && flights.every(flightIsValid);

const costIsValid = (cost) => !isNaN(cost) && parseFloat(cost) > 0;

const getAddAirfareWizardPageState = () => ({
	flights: ManagementStore.getAirfareWizardFlights(),
	submit_status: ManagementStore.getSubmitAirfareWizardFlightsStatus(),
	trip_id: ManagementStore.getCurrentTripRequestId(),
	start_date: ManagementStore.getCurrentTripStartDate(),
	create_trip_airfare_status: ManagementStore.getCreateTripAirfareStatus(),
	cost: ManagementStore.getAirfareWizardCost(),
	currency: ManagementStore.getAirfareWizardCurrency(),
	profile: ProfileStore.getProfile(),
});

const AddAirfareWizardPage = ({ navigate }) => {
	const [state, setState] = useState({
		...getAddAirfareWizardPageState(),
		name: 'Add Airfare',
		validity: true,
		cost_error: '',
	});

	const isBusy = () => {
		return (
			submit_status === ManagementConstants.STATUS.BUSY ||
			create_trip_airfare_status === ManagementConstants.STATUS.BUSY
		);
	};

	useEffect(() => {
		const onChange = () => setState({ ...state, ...getAddAirfareWizardPageState() });

		ManagementStore.addChangeListener(onChange);
		if (!ManagementStore.getAirfareWizardCurrency()) {
			ManagementActions.updateAirfareWizardCurrency(ProfileStore.getProfile().currency);
		}
		if (!state.trip_id) {
			navigate('/trips');
		}

		setTimeout(function () {
			ManagementActions.previewAirfareTips();
		}, 99); // BAD PRACTICE - no clue why this one is even needed (who's calling clear preview after it triggered?!)

		return () => {
			ManagementStore.removeChangeListener(onChange);
			setTimeout(() => ManagementActions.clearPreview(), 10);
		};
	}, []);

	const handleCancelOnClick = () => {
		ManagementActions.resetAirfareWizardFlights();
		navigate(`/trip/${state.trip_id}`);
	};

	const handleNextOnClick = () => {
		const { flights, cost } = state;
		if (!isBusy()) {
			if (!costIsValid(cost)) {
				setState((prevState) => ({ ...prevState, cost_error: COST_IS_REQUIRED, validity: false }));
				return;
			}

			if (flightsAreValid(flights)) {
				setState((prevState) => ({ ...prevState, validity: true }));
				const trip_airfare = {
					trip_id: state.trip_id,
					flights: state.flights,
					cost: cost,
					currency: state.currency,
				};
				ManagementActions.createTripAirfare(trip_airfare, ManagementConstants.SOURCES.ADD_AIRFARE_WIZARD);
				navigate(`/trip/${state.trip_id}`);
			} else {
				setState((prevState) => ({ ...prevState, validity: false }));
			}
		}
	};

	const handleAddAnotherFlightOnClick = () => {
		if (!isBusy()) {
			ManagementActions.addAirfareWizardFlight();
		}
	};

	const handleCostOnChange = (e) => {
		let cost = e.target.value.replace(Currencies.getSymbol(state.profile.currency || state.currency), '');

		if (costIsValid(cost)) {
			ManagementActions.updateAirfareWizardCost(cost);
			setState((prevState) => ({ ...prevState, cost: cost, cost_error: '', validity: true }));
		} else {
			setState((prevState) => ({ ...prevState, cost_error: COST_IS_REQUIRED, validity: false }));
		}
	};

	let { flights, cost, currency, start_date, submit_status, create_trip_airfare_status, validity, cost_error } =
		state;

	let add_flights =
		flights.length === 1 ? (
			<AddAirfareFlight start_date={start_date} flight={flights[0]} single parent_validity={validity} />
		) : (
			flights.map((flight) => (
				<AddAirfareFlight
					start_date={start_date}
					key={flight.display_order}
					flight={flight}
					parent_validity={validity}
				/>
			))
		);

	let title = TitleManager.buildTitleStartsWith('Add Airfare');

	return (
		<DocumentTitle title={title}>
			<div className="add-airfare-wizard-page">
				<div className="title">Add airfare</div>
				<Paper elevation={2} className="wizard paper">
					<div className={`airfare-flights${isBusy() ? ' busy' : ''}`}>{add_flights}</div>
					<div className="footer flex space-between align-center">
						<div
							className={classNames('add-another-airfare', 'flex', 'space-between', 'align-center', {
								busy: isBusy(),
							})}
							onClick={handleAddAnotherFlightOnClick}
						>
							<TripActionButton>
								<Plus />
							</TripActionButton>
							Add another flight
						</div>

						<div className={`airfare-cost${isBusy() ? ' busy' : ''}`}>
							<TextField
								className="field-input cost"
								inputMode="decimal"
								label="Airfare Cost"
								variant="outlined"
								value={cost && !isNaN(cost) ? Number(cost) : ''}
								onChange={handleCostOnChange}
								error={!!cost_error}
								InputProps={{
									startAdornment: (
										<InputAdornment className="currency-symbol" position="start">
											{Currencies.getSymbol(currency)}
										</InputAdornment>
									),
								}}
							/>
						</div>
					</div>
				</Paper>
				<div className="actions-bar flex align-center space-between">
					{!validity && (
						<span className="error">
							Please fill out all the fields and make sure all flight numbers are correct
						</span>
					)}
					<div className="buttons flex align-center">
						{isBusy() && <CircularProgress size={24} />}
						<ArbiCancelButton onClick={handleCancelOnClick} disabled={isBusy()} disableRipple>
							Cancel
						</ArbiCancelButton>
						<ArbiPrimaryButton onClick={handleNextOnClick} disabled={isBusy()} disableRipple>
							Next
						</ArbiPrimaryButton>
					</div>
				</div>
			</div>
		</DocumentTitle>
	);
};

export default AddAirfareWizardPage;
