import async from 'async';

import AppDispatcher from '../dispatcher/AppDispatcher';
import Firebase from 'arbitrip-common/client/utils/Firebase/Search';
import SearchStore from '../stores/SearchStore';
import HotelConstants from '../constants/HotelConstants';
import SearchActions from './SearchActions';
import ResultsStore from '../stores/ResultsStore';
import Ajax from '../utils/Ajax';
import { SEARCH_TYPE_HOTEL_PAGE } from 'arbitrip-common/general/constants/search-constants';
import { Config } from 'arbitrip-common/client/utils';

function isFireRecheckMode() {
	return Firebase.isValid();
}

const HotelActions = {
	setHotelRecommendedIndex: function (hotel_id, recommended_index) {
		AppDispatcher.handleAction({
			actionType: HotelConstants.SET_HOTEL_RECOMMENDED_INDEX,
			data: {
				hotel_id,
				recommended_index,
			},
		});
	},

	setHotelScroll: function (scroll_y, hotel_id) {
		AppDispatcher.handleAction({
			actionType: HotelConstants.SET_HOTEL_SCROLL,
			data: {
				scroll_y,
				hotel_id,
			},
		});
	},

	getHotelData: function (data) {
		AppDispatcher.handleAction({
			actionType: HotelConstants.RECEIVE_HOTEL_DATA,
			data: data, // ???
		});
	},

	getHotelDataById: function (id) {
		AppDispatcher.handleAction({
			actionType: HotelConstants.RECEIVE_HOTEL_DATA_BY_ID,
			data: id, // ???
		});
	},

	enrichHotelWithAdditionalInfo: function (data) {
		AppDispatcher.handleAction({
			actionType: HotelConstants.ENRICH_HOTEL_WITH_ADDITIONAL_INFO,
			data: data, // ???
		});
	},

	getHotelInfo: function (hotel_id) {
		AppDispatcher.handleAction({
			actionType: HotelConstants.GET_HOTEL_INFO,
			data: {
				hotel_id: hotel_id,
			},
		});

		Ajax.getHotelInfo(hotel_id)
			.done(function (res) {
				res.hotel_id = hotel_id;
				HotelActions.enrichHotelWithAdditionalInfo(res); // This will be in effect like GET_HOTEL_INFO_SUCCEEDED
			})
			.fail(function (err) {
				AppDispatcher.handleAction({
					actionType: HotelConstants.GET_HOTEL_INFO_FAILED,
					data: {
						hotel_id: hotel_id,
					},
				});
				console.error(err);
			});
	},

	getHotelDeals: function (hotel_id) {
		// Used for in hotel search and fallback if an error happened when no deals appeared
		const deals_status = ResultsStore.getHotelDealsStatusById(hotel_id);
		if (deals_status === HotelConstants.DEALS_STATUS.SUCCESS || deals_status === HotelConstants.DEALS_STATUS.BUSY) {
			// no need for double check
			return;
		}

		const t = 'getHotelDeals RUN - ' + Date.now();
		console.time(t);

		AppDispatcher.handleAction({
			actionType: HotelConstants.GET_HOTEL_DEALS,
			data: {
				hotel_id,
			},
		});

		this.clearFireRecheckedDeals();
		// Should have we created A new search token?
		SearchStore.newHotelToken(hotel_id); // sets additional custom search token | Notes: in order to get past any server-side caching, a new (hotel-specific) search token is created | this custom token will be used in following steps in the search flow (new reservation, advance reservation, request out-of-policy room)
		const payload = Object.assign({ hotel_id }, SearchStore.toAjaxPayload(SEARCH_TYPE_HOTEL_PAGE, hotel_id));
		Ajax.getHotelDeals(payload)
			.done(function (res) {
				console.log('getHotelDeals - FINISHED');
				console.timeEnd(t);

				const search_terms = SearchStore.getSearchTerms();

				AppDispatcher.handleAction({
					actionType: HotelConstants.GET_HOTEL_DEALS_SUCCEEDED,
					data: {
						search_token: search_terms.token,
						search_terms,
						hotel_id,
						res,
					},
				});

				if (res.travel_policy) {
					SearchActions.getTravelPolicy(res.travel_policy);
				}
			})
			.fail(function (err) {
				console.error('HOTEL_DEALS_ERR: ', err);

				console.log('getHotelDeals - FINISHED (FAILED)');
				console.timeEnd(t);

				console.log('getHotelDeals got a final callback error ', err);
				AppDispatcher.handleAction({
					actionType: HotelConstants.GET_HOTEL_DEALS_FAILED,
					data: {
						hotel_id,
					},
				});
			});
	},

	getFireRecheckedDeals: function (res, token, hotel_id) {
		AppDispatcher.handleAction({
			actionType: HotelConstants.FIRE_RECHECKED_DEALS_RESULTS,
			data: {
				res,
				token,
				hotel_id,
			},
		});
	},

	clearFireRecheckedDeals: function () {
		AppDispatcher.handleAction({
			actionType: HotelConstants.CLEAR_FIRE_RECHECKED_DEALS_RESULTS,
		});
	},

	clearHotelDeals: function (hotel_id) {
		AppDispatcher.handleAction({
			actionType: HotelConstants.CLEAR_HOTEL_DATA_BY_ID,
			data: hotel_id,
		});
	},

	banDeal: function (hotel, deal) {
		AppDispatcher.handleAction({
			actionType: HotelConstants.BAN_DEAL,
			data: {
				deal,
				hotel,
			},
		});
	},

	getHotelRecheckedDeals: function (hotel_id, token) {
		// if we are already here ?
		const t = 'ASYNC getHotelRecheckedDeals RUN - ' + Date.now();
		console.time(t);
		token = token || SearchStore.getToken();
		const hotel = ResultsStore.getHotelById(hotel_id);
		if (!isFireRecheckMode()) {
			console.log('getHotelRecheckedDeals:: not fire recheck mode. Faulting to getHotelDeals(', hotel_id, ')');
			return HotelActions.getHotelDeals(hotel_id);
		}

		this.clearFireRecheckedDeals();

		AppDispatcher.handleAction({
			actionType: HotelConstants.GET_HOTEL_RECHECKED_DEALS,
			data: {
				hotel_id,
				token,
			},
		});
		let skip_retry = false;
		let last_err = null;
		let final_callback_invoke = false;
		const recheck_times = 3;
		const recheck_interval = 222;
		async.retry(
			{ times: recheck_times, interval: recheck_interval },
			function (callback) {
				if (skip_retry) {
					return callback(last_err);
				}
				Ajax.getHotelRecheckedDeals({
					token,
					hotel_id,
					deal_ids: Object.values(hotel?.representative_deals ?? {}).map((deal) => deal?.id),
					...(Config.localhost && { is_localhost: true }),
				})
					.done(function (res) {
						return callback(null, res);
					})
					.fail(function (err) {
						console.log(
							'getHotelRecheckedDeals got an error. Perhaps this is due to race condition with uploading data to redis. ',
							{ err },
						);
						if (err.status === 404) {
							// TODO! What if there are no deals?
							skip_retry = true;
							last_err = err;
							finalCallback(err);
						}
						callback(err);
					});
			},
			finalCallback,
		);

		function finalCallback(err, res) {
			if (!final_callback_invoke) {
				final_callback_invoke = true;
				console.timeEnd(t);
				console.log('err: ', err);
				console.log('res: ', res);

				if (err || !res) {
					console.error('getHotelRecheckedDeals got a final callback error ', err);
					AppDispatcher.handleAction({
						actionType: HotelConstants.GET_HOTEL_RECHECKED_DEALS_FAILED,
						data: {
							hotel_id,
						},
					});
					console.log('getHotelDeals: Try to get deals from DB');
					HotelActions.getHotelDeals(hotel_id); // fallback
				} else {
					res.hotel_id = hotel_id;
					HotelActions.getFireRecheckedDeals(res, token, hotel_id);
				}
			}
		}
	},

	setTotalDisplayedDealsCount: function (count) {
		AppDispatcher.handleAction({
			actionType: HotelConstants.SET_TOTAL_DISPLAYED_DEALS_COUNT,
			data: {
				count,
			},
		});
	},
};

export default HotelActions;
