import AppDispatcher from '../dispatcher/AppDispatcher';
import ManagementOngoingConstants from '../constants/ManagementOngoingConstants';
import Ajax from '../utils/Ajax';

var ManagementOngoingActions = {
	getOngoingTrips: function () {
		console.log('getting ongoing reports...');

		AppDispatcher.handleAction({
			actionType: ManagementOngoingConstants.GET_ONGOING_TRIPS,
		});

		Ajax.getOngoingTrips()
			.done(function (res) {
				AppDispatcher.handleAction({
					actionType: ManagementOngoingConstants.GET_ONGOING_TRIPS_SUCCESS,
					data: res, // ???
				});
			})
			.fail(function (err) {
				console.error('Error while getting ongoing trips: ', err);
				AppDispatcher.handleAction({
					actionType: ManagementOngoingConstants.GET_ONGOING_TRIPS_FAILED,
					data: err, // ???
				});
			});
	},
};

export default ManagementOngoingActions;
