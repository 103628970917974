import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';

import React from 'react';
import PropTypes from 'prop-types';

const styles = (theme) => ({
	loaderStyle: {
		display: 'block',
		width: '100%',
		marginBottom: '20px',
		fontSize: '36px',
		marginTop: '7%',
		textAlign: 'center',
		padding: '25px',
	},
	onTop: {
		position: 'absolute',
		top: '121px',
		marginLeft: 'auto',
		marginRight: 'auto',
		left: '0',
		right: '0',
		'z-index': '10000',
	},
});

class PageLoader extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			onTop: props.onTop,
		};
	}

	render() {
		let { title, onTop, classes } = this.props;

		return (
			<div className={classNames(classes.loaderStyle, onTop ? classes.onTop : null)}>
				<i title={title} className="fa fa-spin fa-spinner fa-1x" />
			</div>
		);
	}
}

PageLoader.propTypes = {
	title: PropTypes.string,
	onTop: PropTypes.bool,
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PageLoader);
