class MapBoxStyles {
	getMapStyles() {
		return [
			// hide Ecuator line and date line
			{
				featureType: 'administrative',
				elementType: 'geometry',
				stylers: [
					{
						visibility: 'off',
					},
				],
			},
			// // change label color
			// {
			//   "featureType": "administrative",
			//   "elementType": "labels.text.fill",
			//   "stylers": [
			//     {
			//       "color": "#999"
			//     }
			//   ]
			// },
			// show country borders
			{
				featureType: 'administrative.country',
				elementType: 'geometry',
				stylers: [
					{
						visibility: 'on',
					},
				],
			},
			// change color for country borders
			{
				featureType: 'administrative.country',
				elementType: 'geometry.stroke',
				stylers: [
					{
						color: '#CCCCCC',
					},
				],
			},
			// set ground color
			{
				featureType: 'landscape',
				elementType: 'all',
				stylers: [
					{
						color: '#f5f5f5',
					},
				],
			},
			// hide points of interests
			{
				featureType: 'poi',
				elementType: 'all',
				stylers: [
					{
						visibility: 'off',
					},
				],
			},
			// show attraction, park and medical
			{
				featureType: 'poi.attraction',
				elementType: 'all',
				stylers: [
					{
						visibility: 'on',
					},
				],
			},
			{
				featureType: 'poi.park',
				elementType: 'all',
				stylers: [
					{
						visibility: 'on',
					},
				],
			},
			{
				featureType: 'poi.medical',
				elementType: 'all',
				stylers: [
					{
						visibility: 'on',
					},
				],
			},
			// make roads grayscale
			{
				featureType: 'road',
				elementType: 'all',
				stylers: [
					{
						saturation: -100,
					},
				],
			},
			// make highways simple
			{
				featureType: 'road.highway',
				elementType: 'all',
				stylers: [
					{
						visibility: 'simplified',
					},
				],
			},
			// hide arterial roads
			{
				featureType: 'road.arterial',
				elementType: 'labels.icon',
				stylers: [
					{
						visibility: 'off',
					},
				],
			},
			// // hide transit roads
			// {
			//   "featureType": "transit",
			//   "elementType": "all",
			//   "stylers": [
			//     {
			//       "visibility": "on"
			//     }
			//   ]
			// },
			{
				featureType: 'transit.line',
				elementType: 'geometry',
				stylers: [{ visibility: 'off' }],
			},
			// set water color
			{
				featureType: 'water',
				elementType: 'geometry',
				stylers: [
					{
						color: '#3498db',
					},
					{
						visibility: 'on',
					},
				],
			},
		];
	}
}

export default new MapBoxStyles();
