import React, { useEffect, useMemo, useState } from 'react';
import SettlePaymentTitle from './SettlePaymentTitle.react';
import SettlePaymentPayNow from './SettlePaymentPayNow.react';
import MaterialDesignDialog from '../../../general/MaterialDesignDialog.react';
import { STEPS } from './const';
import PaymentStore from '../../../../stores/PaymentStore';
import SettlePaymentCreditCard from './SettlePaymentCreditCard.react';
import ReservationConstants from '../../../../constants/ReservationConstants';
import ReservationStore from '../../../../stores/ReservationStore';
import SettlePaymentCreditCardResult from './SettlePaymentCreditCardResult.react';
import { isPendingStatus } from '../../reservation-payment/helpers';
import BookingsActions from '../../../../actions/BookingsActions';
import Analytics from 'arbitrip-common/client/analytics';
import PaymentConstants from '../../../../constants/PaymentConstants';
import SettlePaymentEditCommission from './SettlePaymentEditCommission.react';
import { createDeal } from 'arbitrip-common/client/entities/DealUtils';
import SettlePaymentPayNowLocked from './SettlePaymentPayNowLocked.react';
import helpers from './helpers';
import PaymentLinkStore from '../../../../stores/PaymentLinkStore';
import PaymentLinkActions from '@/actions/PaymentLinkActions';
import PaymentActions from '@/actions/PaymentActions';
import PaymentLinkConstants from '../../../../constants/PaymentLinkConstants';

const { CURRENCIES } = require('@arbitrip/constants');

const DEFAULT_WINDOW_WIDTH = 418;
const WIDE_WINDOW_WIDTH = 875;

const DEFAULT_WINDOW_HEIGHT = null;
const HIGH_WINDOW_HEIGHT = 750;

const SettlePaymentMain = ({
	booking,
	profile,
	settlePaymentStatus,
	onKeyDown,
	showSettlePaymentModal,
	setShowSettlePaymentModal,
}) => {
	const token_expired = useMemo(() => {
		return helpers.isTokenExpired(booking);
	}, [booking, booking?.payment?.payment_link?.token]);

	const [currentStep, setCurrentStep] = useState(token_expired ? STEPS.PAY_NOW : STEPS.PAY_NOW_LOCKED);
	const [paymentData, setPaymentData] = useState(PaymentStore.getPaymentData());
	const [reservationStatus, setReservationStatus] = useState(ReservationStore.getReservationData().status);
	const [chargeCurrency, setChargeCurrency] = useState(profile?.company_currency || CURRENCIES.USD);
	const [dialogWidth, setDialogWidth] = useState(DEFAULT_WINDOW_WIDTH);
	const [dialogHeight, setDialogHeight] = useState(DEFAULT_WINDOW_HEIGHT);
	const [paymentLinkStatus, setPaymentLinkStatus] = useState(PaymentLinkStore.getStatus());

	useEffect(() => {
		function handleStoreChange() {
			setPaymentLinkStatus(PaymentLinkStore.getStatus());
		}

		PaymentLinkStore.addChangeListener(handleStoreChange);

		return () => {
			PaymentLinkStore.removeChangeListener(handleStoreChange);
		};
	}, []);

	useEffect(() => {
		if (paymentLinkStatus === PaymentLinkConstants.STATUS.SUCCESS) {
			setCurrentStep(STEPS.PAY_NOW_LOCKED);
		}
	}, [paymentLinkStatus]);

	useEffect(() => {
		function handleStoreChange() {
			setPaymentData(PaymentStore.getPaymentData());
			setReservationStatus(ReservationStore.getReservationData().status);
		}

		PaymentStore.addChangeListener(handleStoreChange);
		ReservationStore.addChangeListener(handleStoreChange);

		return () => {
			PaymentStore.removeChangeListener(handleStoreChange);
			ReservationStore.removeChangeListener(handleStoreChange);
		};
	}, []);

	useEffect(() => {
		if (paymentData.creditCardUrl) {
			setWizardStep(STEPS.CREDIT_CARD);
		}
	}, [paymentData]);

	useEffect(() => {
		if (
			reservationStatus === ReservationConstants.STATUS.BOOKED ||
			isPendingStatus(reservationStatus) ||
			reservationStatus === ReservationConstants.STATUS.FAILED
		) {
			setWizardStep(STEPS.CREDIT_CARD_RESULT);
			BookingsActions.updatePaymentMethodToCreditCard(booking.id);

			// set id on deal in order to create a deal entity
			const deal = createDeal({ ...booking.deal, id: booking.id });

			Analytics.actions.interactions.payLaterPaymentCreated(
				profile,
				booking?.hotel,
				deal,
				PaymentConstants.PAYMENT_METHODS_ANALYTICS.PAY_LATER,
				chargeCurrency,
			);
		}
	}, [reservationStatus]);

	const closeDialogAndLock = () => {
		setShowSettlePaymentModal(false);

		setTimeout(() => {
			if (!token_expired) {
				setCurrentStep(STEPS.PAY_NOW_LOCKED);
			}
		}, 200);
	};

	const steps = useMemo(
		() => ({
			[STEPS.PAY_NOW]: {
				title: (
					<SettlePaymentTitle
						title={`Set the payment for booking <br/>${booking.short_id}`}
						onClose={closeDialogAndLock}
						booking={booking}
					/>
				),
				content: (
					<SettlePaymentPayNow
						onClose={closeDialogAndLock}
						booking={booking}
						profile={profile}
						settlePaymentStatus={settlePaymentStatus}
						showEditCommission={() => setWizardStep(STEPS.EDIT_COMMISSION)}
						chargeCurrency={chargeCurrency}
						updateChargeCurrency={setChargeCurrency}
					/>
				),
			},
			[STEPS.PAY_NOW_LOCKED]: {
				title: (
					<SettlePaymentTitle
						title={`Payment for booking <br/>${booking.short_id}`}
						onClose={() => {
							setShowSettlePaymentModal(false);
							PaymentLinkActions.resetPaymentLink();
						}}
						booking={booking}
					/>
				),
				content: (
					<SettlePaymentPayNowLocked
						profile={profile}
						booking={booking}
						chargeCurrency={chargeCurrency}
						editPayment={() => setCurrentStep(STEPS.PAY_NOW)}
					/>
				),
			},
			[STEPS.CREDIT_CARD]: {
				title: (
					<SettlePaymentTitle
						title={`Set the payment for booking <br/>${booking.short_id}`}
						onClose={() => {
							setWizardStep(STEPS.PAY_NOW);
							PaymentActions.clearPaymentData();
						}}
						booking={booking}
					/>
				),
				content: <SettlePaymentCreditCard profile={profile} payment={paymentData} />,
			},
			[STEPS.CREDIT_CARD_RESULT]: {
				title: (
					<SettlePaymentTitle
						title={`Set the payment for booking <br/>${booking.short_id}`}
						onClose={() => {
							setShowSettlePaymentModal(false);
							PaymentActions.clearPaymentData();
						}}
						booking={booking}
					/>
				),
				content: (
					<SettlePaymentCreditCardResult
						onClose={() => {
							setShowSettlePaymentModal(false);
							PaymentActions.clearPaymentData();
						}}
					/>
				),
			},
			[STEPS.EDIT_COMMISSION]: {
				title: (
					<SettlePaymentTitle
						title="Change Client Price"
						onClose={() => setCurrentStep(STEPS.PAY_NOW)}
						booking={booking}
					/>
				),
				content: (
					<SettlePaymentEditCommission
						onClose={() => setWizardStep(STEPS.PAY_NOW)}
						booking={booking}
						profile={profile}
						chargeCurrency={chargeCurrency}
					/>
				),
			},
		}),
		[booking, profile, settlePaymentStatus, paymentData, chargeCurrency],
	);

	const setWizardStep = (step) => {
		if (step === STEPS.CREDIT_CARD) {
			setDialogWidth(WIDE_WINDOW_WIDTH);
			setDialogHeight(HIGH_WINDOW_HEIGHT);
			// TODO: consider different heights for different credit card providers
		} else {
			setDialogWidth(DEFAULT_WINDOW_WIDTH);
			setDialogHeight(DEFAULT_WINDOW_HEIGHT);
		}
		setCurrentStep(step);
	};

	return (
		<MaterialDesignDialog
			open={showSettlePaymentModal}
			onClose={() => setShowSettlePaymentModal(false)}
			closeTimeoutInMilliseconds={150}
			key={`settle-payment-modal-${booking.id}`}
			title={steps[currentStep].title}
			content={steps[currentStep].content}
			onKeyDown={onKeyDown}
			minWidth={dialogWidth}
			height={dialogHeight}
		/>
	);
};

export default SettlePaymentMain;
