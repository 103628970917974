import {
	getPrecisePriceWithCurrency,
	getPriceWithDisplayCurrencyByPrecision,
} from 'arbitrip-common/general/utils/Currencies';
import { Tooltip } from '@mui/material';

export function getPriceValue(priceValue, currency, chargeCurrency, profile) {
	const title =
		priceValue > 0 &&
		chargeCurrency &&
		profile?.freelance_agent_pay_later_exchange_rates &&
		chargeCurrency !== currency &&
		`approx. ${getPriceWithDisplayCurrencyByPrecision(priceValue, profile.freelance_agent_pay_later_exchange_rates[chargeCurrency], chargeCurrency, 2)}`;

	return (
		<Tooltip title={title} placement="left">
			<div className="price-value">{priceValue > 0 ? getPrecisePriceWithCurrency(priceValue, currency) : 0}</div>
		</Tooltip>
	);
}
