const SMALL = 'small';
const LARGE = 'large';

const TOP_DESTINATIONS = [
    {
        id: 'Dubai',
        title: 'Dubai',
        sub_title: '2,244 properties',
        image: 'leisure_destinations/dubai.jpg',
        destination: {
            "address": "Dubai - United Arab Emirates",
            "place": {
                "description": "Dubai - United Arab Emirates",
                "place_id": "ChIJRcbZaklDXz4RYlEphFBu5r0",
                "reference": "ChIJRcbZaklDXz4RYlEphFBu5r0",
                "types": [
                    "locality",
                    "political"
                ],
                "category": "location",
                "name": "Dubai",
                "label": "Dubai, United Arab Emirates",
                "address": "Dubai - United Arab Emirates",
                "short_address": "United Arab Emirates",
                "index": -1,
                "address_components": [
                    {
                        "long_name": "Dubai",
                        "short_name": "Dubai",
                        "types": [
                            "locality",
                            "political"
                        ]
                    },
                    {
                        "long_name": "Dubai",
                        "short_name": "Dubai",
                        "types": [
                            "administrative_area_level_1",
                            "political"
                        ]
                    },
                    {
                        "long_name": "United Arab Emirates",
                        "short_name": "AE",
                        "types": [
                            "country",
                            "political"
                        ]
                    }
                ],
                "formatted_address": "Dubai - United Arab Emirates",
                "geometry": {
                    "location": {
                        "lat": 25.2048493,
                        "lng": 55.2707828
                    },
                    "viewport": {
                        "northeast": {
                            "lat": 25.35856066265986,
                            "lng": 55.5650392624228
                        },
                        "southwest": {
                            "lat": 24.79348418590246,
                            "lng": 54.89045432509004
                        }
                    }
                },
                "icon": "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png",
                "location": {
                    "lat": 25.2048493,
                    "lng": 55.2707828
                },
                "viewport": {
                    "northeast": {
                        "lat": 25.35856066265986,
                        "lng": 55.5650392624228
                    },
                    "southwest": {
                        "lat": 24.79348418590246,
                        "lng": 54.89045432509004
                    }
                }
            }
        },
        type: SMALL
    },
    {
        id: 'NewYork',
        title: 'New York',
        sub_title: '876 properties',
        image: 'leisure_destinations/new-york.jpg',
        destination: {
            "address": "New York, NY, USA",
            "place": {
                "description": "New York, NY, USA",
                "place_id": "ChIJOwg_06VPwokRYv534QaPC8g",
                "reference": "ChIJOwg_06VPwokRYv534QaPC8g",
                "types": [
                    "locality",
                    "political"
                ],
                "category": "location",
                "name": "New York",
                "label": "New York, NY, USA",
                "address": "New York, NY, USA",
                "short_address": "NY, USA",
                "index": -1,
                "address_components": [
                    {
                        "long_name": "New York",
                        "short_name": "New York",
                        "types": [
                            "locality",
                            "political"
                        ]
                    },
                    {
                        "long_name": "New York",
                        "short_name": "NY",
                        "types": [
                            "administrative_area_level_1",
                            "political"
                        ]
                    },
                    {
                        "long_name": "United States",
                        "short_name": "US",
                        "types": [
                            "country",
                            "political"
                        ]
                    }
                ],
                "formatted_address": "New York, NY, USA",
                "geometry": {
                    "location": {
                        "lat": 40.7127753,
                        "lng": -74.0059728
                    },
                    "viewport": {
                        "northeast": {
                            "lat": 40.91757705070789,
                            "lng": -73.70027206817629
                        },
                        "southwest": {
                            "lat": 40.47739906045452,
                            "lng": -74.25908991427882
                        }
                    }
                },
                "icon": "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png",
                "location": {
                    "lat": 40.7127753,
                    "lng": -74.0059728
                },
                "viewport": {
                    "northeast": {
                        "lat": 40.91757705070789,
                        "lng": -73.70027206817629
                    },
                    "southwest": {
                        "lat": 40.47739906045452,
                        "lng": -74.25908991427882
                    }
                }
            }
        },
        type: SMALL
    },
    {
        id: 'London',
        title: 'London',
        sub_title: '4,950 properties',
        image: 'leisure_destinations/london.jpg',
        destination: {
            "address": "London, UK",
            "place": {
                "description": "London, UK",
                "place_id": "ChIJdd4hrwug2EcRmSrV3Vo6llI",
                "reference": "ChIJdd4hrwug2EcRmSrV3Vo6llI",
                "types": [
                    "locality",
                    "political"
                ],
                "category": "location",
                "name": "London",
                "label": "London, UK",
                "address": "London, UK",
                "short_address": "UK",
                "index": -1,
                "address_components": [
                    {
                        "long_name": "London",
                        "short_name": "London",
                        "types": [
                            "locality",
                            "political"
                        ]
                    },
                    {
                        "long_name": "London",
                        "short_name": "London",
                        "types": [
                            "postal_town"
                        ]
                    },
                    {
                        "long_name": "Greater London",
                        "short_name": "Greater London",
                        "types": [
                            "administrative_area_level_2",
                            "political"
                        ]
                    },
                    {
                        "long_name": "England",
                        "short_name": "England",
                        "types": [
                            "administrative_area_level_1",
                            "political"
                        ]
                    },
                    {
                        "long_name": "United Kingdom",
                        "short_name": "GB",
                        "types": [
                            "country",
                            "political"
                        ]
                    }
                ],
                "formatted_address": "London, UK",
                "geometry": {
                    "location": {
                        "lat": 51.5072178,
                        "lng": -0.1275862
                    },
                    "viewport": {
                        "northeast": {
                            "lat": 51.67234324898703,
                            "lng": 0.1482710335611201
                        },
                        "southwest": {
                            "lat": 51.38494012429096,
                            "lng": -0.3514683384218145
                        }
                    }
                },
                "icon": "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png",
                "location": {
                    "lat": 51.5072178,
                    "lng": -0.1275862
                },
                "viewport": {
                    "northeast": {
                        "lat": 51.67234324898703,
                        "lng": 0.1482710335611201
                    },
                    "southwest": {
                        "lat": 51.38494012429096,
                        "lng": -0.3514683384218145
                    }
                }
            }
        },
        type: LARGE
    }
];

const EXTENDED_TOP_DESTINATIONS = [
    {
        id: 'Lisbon',
        title: 'Lisbon',
        sub_title: 'Around 2,140 accommodation options',
        image: 'leisure_destinations/lisbon.png',
        destination: {
            "description": "Lisbon, Portugal",
            "place_id": "ChIJO_PkYRozGQ0R0DaQ5L3rAAQ",
            "reference": "ChIJO_PkYRozGQ0R0DaQ5L3rAAQ",
            "types": [
                "locality",
                "political"
            ],
            "category": "location",
            "name": "Lisbon",
            "label": "Lisbon, Portugal",
            "address": "Lisbon, Portugal",
            "short_address": "Portugal",
            "index": -1,
            "address_components": [
                {
                    "long_name": "Lisbon",
                    "short_name": "Lisbon",
                    "types": [
                        "locality",
                        "political"
                    ]
                },
                {
                    "long_name": "Lisbon",
                    "short_name": "Lisbon",
                    "types": [
                        "administrative_area_level_2",
                        "political"
                    ]
                },
                {
                    "long_name": "Lisbon",
                    "short_name": "Lisbon",
                    "types": [
                        "administrative_area_level_1",
                        "political"
                    ]
                },
                {
                    "long_name": "Portugal",
                    "short_name": "PT",
                    "types": [
                        "country",
                        "political"
                    ]
                }
            ],
            "formatted_address": "Lisbon, Portugal",
            "geometry": {
                "location": {
                    "lat": 38.7222524,
                    "lng": -9.1393366
                },
                "viewport": {
                    "northeast": {
                        "lat": 38.79675952753452,
                        "lng": -9.08963599547923
                    },
                    "southwest": {
                        "lat": 38.69139936595241,
                        "lng": -9.229835557691242
                    }
                }
            },
            "icon": "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png",
            "location": {
                "lat": 38.7222524,
                "lng": -9.1393366
            },
            "viewport": {
                "northeast": {
                    "lat": 38.79675952753452,
                    "lng": -9.08963599547923
                },
                "southwest": {
                    "lat": 38.69139936595241,
                    "lng": -9.229835557691242
                }
            }
        },
        type: SMALL
    },
    {
        id: 'Tokyo',
        title: 'Tokyo',
        sub_title: 'Over 6,000 accommodation options',
        image: 'leisure_destinations/tokyo.png',
        destination: {
            "description": "Tokyo, Japan",
            "place_id": "ChIJ51cu8IcbXWARiRtXIothAS4",
            "reference": "ChIJ51cu8IcbXWARiRtXIothAS4",
            "types": [
                "administrative_area_level_1",
                "political"
            ],
            "category": "location",
            "name": "Tokyo",
            "label": "Tokyo, Japan",
            "address": "Tokyo, Japan",
            "short_address": "Japan",
            "index": -1,
            "address_components": [
                {
                    "long_name": "Tokyo",
                    "short_name": "Tokyo",
                    "types": [
                        "administrative_area_level_1",
                        "political"
                    ]
                },
                {
                    "long_name": "Japan",
                    "short_name": "JP",
                    "types": [
                        "country",
                        "political"
                    ]
                }
            ],
            "formatted_address": "Tokyo, Japan",
            "geometry": {
                "location": {
                    "lat": 35.6764225,
                    "lng": 139.650027
                },
                "viewport": {
                    "northeast": {
                        "lat": 36.4408483,
                        "lng": 141.2405144
                    },
                    "southwest": {
                        "lat": 34.5776326,
                        "lng": 138.2991098
                    }
                }
            },
            "icon": "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png",
            "location": {
                "lat": 35.6764225,
                "lng": 139.650027
            },
            "viewport": {
                "northeast": {
                    "lat": 36.4408483,
                    "lng": 141.2405144
                },
                "southwest": {
                    "lat": 34.5776326,
                    "lng": 138.2991098
                }
            }
        },
        type: SMALL
    },
    {
        id: 'Prague',
        title: 'Prague',
        sub_title: 'Approximately 2,000 accommodation options',
        image: 'leisure_destinations/prague.png',
        destination: {
            "description": "Prague, Czechia",
            "place_id": "ChIJi3lwCZyTC0cRkEAWZg-vAAQ",
            "reference": "ChIJi3lwCZyTC0cRkEAWZg-vAAQ",
            "types": [
                "locality",
                "political"
            ],
            "category": "location",
            "name": "Prague",
            "label": "Prague, Czechia",
            "address": "Prague, Czechia",
            "short_address": "Czechia",
            "index": -1,
            "address_components": [
                {
                    "long_name": "Prague",
                    "short_name": "Prague",
                    "types": [
                        "locality",
                        "political"
                    ]
                },
                {
                    "long_name": "Hlavní město Praha",
                    "short_name": "Hlavní město Praha",
                    "types": [
                        "administrative_area_level_2",
                        "political"
                    ]
                },
                {
                    "long_name": "Prague",
                    "short_name": "Prague",
                    "types": [
                        "administrative_area_level_1",
                        "political"
                    ]
                },
                {
                    "long_name": "Czechia",
                    "short_name": "CZ",
                    "types": [
                        "country",
                        "political"
                    ]
                }
            ],
            "formatted_address": "Prague, Czechia",
            "geometry": {
                "location": {
                    "lat": 50.0755381,
                    "lng": 14.4378005
                },
                "viewport": {
                    "northeast": {
                        "lat": 50.17740304550632,
                        "lng": 14.7067945452399
                    },
                    "southwest": {
                        "lat": 49.94193629313551,
                        "lng": 14.22445333640556
                    }
                }
            },
            "icon": "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png",
            "location": {
                "lat": 50.0755381,
                "lng": 14.4378005
            },
            "viewport": {
                "northeast": {
                    "lat": 50.17740304550632,
                    "lng": 14.7067945452399
                },
                "southwest": {
                    "lat": 49.94193629313551,
                    "lng": 14.22445333640556
                }
            }
        },
        type: SMALL
    },
    {
        id: 'Seoul',
        title: 'Seoul',
        sub_title: 'Around 2,500 accommodation options',
        image: 'leisure_destinations/seoul.png',
        destination: {
            "description": "Seoul, South Korea",
            "place_id": "ChIJzzlcLQGifDURm_JbQKHsEX4",
            "reference": "ChIJzzlcLQGifDURm_JbQKHsEX4",
            "types": [
                "administrative_area_level_1",
                "political"
            ],
            "category": "location",
            "name": "Seoul",
            "label": "Seoul, South Korea",
            "address": "Seoul, South Korea",
            "short_address": "South Korea",
            "index": -1,
            "address_components": [
                {
                    "long_name": "Seoul",
                    "short_name": "Seoul",
                    "types": [
                        "administrative_area_level_1",
                        "political"
                    ]
                },
                {
                    "long_name": "South Korea",
                    "short_name": "KR",
                    "types": [
                        "country",
                        "political"
                    ]
                }
            ],
            "formatted_address": "Seoul, South Korea",
            "geometry": {
                "location": {
                    "lat": 37.550263,
                    "lng": 126.9970831
                },
                "viewport": {
                    "northeast": {
                        "lat": 37.7014549458459,
                        "lng": 127.18379492437
                    },
                    "southwest": {
                        "lat": 37.42829725537645,
                        "lng": 126.7644837308701
                    }
                }
            },
            "icon": "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png",
            "location": {
                "lat": 37.550263,
                "lng": 126.9970831
            },
            "viewport": {
                "northeast": {
                    "lat": 37.7014549458459,
                    "lng": 127.18379492437
                },
                "southwest": {
                    "lat": 37.42829725537645,
                    "lng": 126.7644837308701
                }
            }
        },
        type: SMALL
    },
    {
        id: 'Madrid',
        title: 'Madrid',
        sub_title: 'Roughly 3,000 hotels and accommodation options',
        image: 'leisure_destinations/madrid.jpg',
        destination: {
            "description": "Madrid, Spain",
            "place_id": "ChIJgTwKgJcpQg0RaSKMYcHeNsQ",
            "reference": "ChIJgTwKgJcpQg0RaSKMYcHeNsQ",
            "types": [
                "locality",
                "political"
            ],
            "category": "location",
            "name": "Madrid",
            "label": "Madrid, Spain",
            "address": "Madrid, Spain",
            "short_address": "Spain",
            "index": -1,
            "address_components": [
                {
                    "long_name": "Madrid",
                    "short_name": "Madrid",
                    "types": [
                        "locality",
                        "political"
                    ]
                },
                {
                    "long_name": "Madrid",
                    "short_name": "M",
                    "types": [
                        "administrative_area_level_2",
                        "political"
                    ]
                },
                {
                    "long_name": "Community of Madrid",
                    "short_name": "MD",
                    "types": [
                        "administrative_area_level_1",
                        "political"
                    ]
                },
                {
                    "long_name": "Spain",
                    "short_name": "ES",
                    "types": [
                        "country",
                        "political"
                    ]
                }
            ],
            "formatted_address": "Madrid, Spain",
            "geometry": {
                "location": {
                    "lat": 40.41672790000001,
                    "lng": -3.7032905
                },
                "viewport": {
                    "northeast": {
                        "lat": 40.56384473341083,
                        "lng": -3.524911528167741
                    },
                    "southwest": {
                        "lat": 40.31206394740597,
                        "lng": -3.834161809872056
                    }
                }
            },
            "icon": "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png",
            "location": {
                "lat": 40.41672790000001,
                "lng": -3.7032905
            },
            "viewport": {
                "northeast": {
                    "lat": 40.56384473341083,
                    "lng": -3.524911528167741
                },
                "southwest": {
                    "lat": 40.31206394740597,
                    "lng": -3.834161809872056
                }
            }
        },
        type: SMALL
    },
    {
        id: 'Oslo',
        title: 'Oslo',
        sub_title: 'Around 350 accommodation options',
        image: 'leisure_destinations/oslo.png',
        destination: {
            "description": "Oslo, Norway",
            "place_id": "ChIJOfBn8mFuQUYRmh4j019gkn4",
            "reference": "ChIJOfBn8mFuQUYRmh4j019gkn4",
            "types": [
                "locality",
                "political"
            ],
            "category": "location",
            "name": "Oslo",
            "label": "Oslo, Norway",
            "address": "Oslo, Norway",
            "short_address": "Norway",
            "index": -1,
            "address_components": [
                {
                    "long_name": "Oslo",
                    "short_name": "Oslo",
                    "types": [
                        "locality",
                        "political"
                    ]
                },
                {
                    "long_name": "Oslo",
                    "short_name": "Oslo",
                    "types": [
                        "administrative_area_level_1",
                        "political"
                    ]
                },
                {
                    "long_name": "Norway",
                    "short_name": "NO",
                    "types": [
                        "country",
                        "political"
                    ]
                }
            ],
            "formatted_address": "Oslo, Norway",
            "geometry": {
                "location": {
                    "lat": 59.9138688,
                    "lng": 10.7522454
                },
                "viewport": {
                    "northeast": {
                        "lat": 59.97803498566783,
                        "lng": 10.94766406742412
                    },
                    "southwest": {
                        "lat": 59.80967486051522,
                        "lng": 10.62256890842673
                    }
                }
            },
            "icon": "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png",
            "location": {
                "lat": 59.9138688,
                "lng": 10.7522454
            },
            "viewport": {
                "northeast": {
                    "lat": 59.97803498566783,
                    "lng": 10.94766406742412
                },
                "southwest": {
                    "lat": 59.80967486051522,
                    "lng": 10.62256890842673
                }
            }
        },
        type: SMALL
    },
]

module.exports = {
    SMALL,
    LARGE,
    TOP_DESTINATIONS,
    EXTENDED_TOP_DESTINATIONS
}
