import _ from 'lodash';
import React from 'react';
import createClass from 'create-react-class';
import { withTranslation } from 'react-i18next';

import FooterBarStore from '../../../../stores/FooterBarStore';
import ProfileStore from '../../../../stores/ProfileStore';

// Method to retrieve state from Stores
function getFooterBarState() {
	return {
		profile: ProfileStore.getProfile(),
	};
}

// Define main Controller View
var FooterBar = createClass({
	displayName: 'FooterBar',

	// Get initial state from stores
	getInitialState: function () {
		return getFooterBarState();
	},

	// Add change listeners to stores
	componentDidMount: function () {
		FooterBarStore.addChangeListener(this._onChange);
	},

	// Remove change listers from stores
	componentWillUnmount: function () {
		FooterBarStore.removeChangeListener(this._onChange);
	},

	shouldShowButton: function (key) {
		const { i18n, t } = this.props;
		return i18n.exists(key) && !_.isEmpty(t(key));
	},

	// Render our child components, passing state via props
	render: function () {
		const { t } = this.props;
		const { profile } = this.state;

		const show_accessibility_statement =
			this.shouldShowButton('AccessibilityStatementUrl') &&
			_.get(profile, 'company.show_accessibility_statement');

		var year = new Date().getFullYear();
		var DISCLAIMER = t('AllRightsReserved', { year });
		var PHONE = t('SupportPhoneNumber');
		var HELP_EMAIL = t('HelpEmail');

		return (
			<div className="footer-bar">
				<div className="inner-container">
					<div className="navigation">
						<div className="info">
							<div className="disclaimer info-div">
								<a href={t('DisclaimeryUrl')} target="_blank">
									{DISCLAIMER}
								</a>
							</div>
							{this.shouldShowButton('HelpEmail') && (
								<div className="help-email info-div">
									<a href={'mailto:' + HELP_EMAIL} target="_blank">
										{HELP_EMAIL}
									</a>
								</div>
							)}
							<div className="phone info-div">
								<a href={'tel:' + PHONE}> {PHONE}</a>
							</div>
						</div>

						<div className="nav-div">
							<a href={t('TermsAndConditionsUrl')} target="_blank">
								Terms & Conditions
							</a>
						</div>
						<div className="nav-div">
							<a href={t('PrivacyPolicyUrl')} target="_blank">
								Privacy Policy
							</a>
						</div>
						{show_accessibility_statement && (
							<div className="nav-div">
								<a href={t('AccessibilityStatementUrl')} target="_blank">
									Accessibility Statement
								</a>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	},

	// Method to setState based upon Store changes
	_onChange: function () {
		this.setState(getFooterBarState());
	},
});

export default withTranslation()(FooterBar);
