import _ from 'lodash';

import AppDispatcher from '../dispatcher/AppDispatcher';
import { EventEmitter } from 'events';
import HotelConstants from '../constants/HotelConstants';
// import ImageUtils from 'arbitrip-common/client/utils/ImageUtils';

import ResultsStore from '../stores/ResultsStore';
// import DealUtils from '../entities/DealUtils';
import Hotel from '../entities/Hotel';

// import FiltersActions from '../actions/FiltersActions';

// Define initial data points
// var _hotelDeals = {};

let _hotels = {};
let _hotels_scroll_y = {};
let _current_hotel_id = '';
let _total_displayed_deals_count = 0;

// Method to load result data from mock API
function loadHotelData(data) {
	let hotel_data = data instanceof Hotel ? data : new Hotel(data);
	_current_hotel_id = hotel_data.id;
	_hotels[_current_hotel_id] = hotel_data;
	_hotels_scroll_y[_current_hotel_id] = 0;
}

function loadHotelInfo(data) {
	_current_hotel_id = data._id || data.id;
	_hotels[_current_hotel_id] = _hotels[_current_hotel_id] || new Hotel(data);
	_hotels_scroll_y[_current_hotel_id] = _hotels_scroll_y[_current_hotel_id] || 0;

	_hotels[_current_hotel_id].HotelInfo = data;
}

// function enrichHotelWithAdditionalInfo(data) {
//   _hotel.hotelInfo = data;
//   _hotel.images = ImageUtils.transformImagesToGallery(data.images);
//   _hotel.info_status = HotelConstants.INFO_STATUS.SUCCESS;
// }
//
// function setRepresentativeDeal(representative_deal) {
//   if (DealUtils.isValidDeal(representative_deal) && !DealUtils.isValidDeal(_hotel.representativeDeal)) {
//     _hotel.representativeDeal = DealUtils.createDeal(representative_deal);
//     delete _hotel.shallow;
//     setTimeout(FiltersActions.filterByAll, 111); // VERY BAD PRACTICE
//   }
// }

// function enrichHotelWithDeals(data) {
//   _hotel.deals_status = HotelConstants.INFO_STATUS.SUCCESS;
//   var deals = DealUtils.createDeals(data.deals);
//   if (deals.length > 0) {
//     addDealsToHotelDeals(deals);
//
//     setRepresentativeDeal(data.representativeDeal);
//   } else {
//     delete _hotel.shallow;
//     setTimeout(FiltersActions.filterByAll, 111); // VERY BAD PRACTICE
//   }
// }
//
// function addDealsToHotelDeals(deals) {
//   console.log('about to add ' + deals.length + ' deals from cache');
//   for (var i = 0; i < deals.length; i++) {
//     var deal = deals[i];
//     _hotelDeals[deal.id] = _hotelDeals[deal.id] || deal;
//   }
//   _hotel.deals = Object.values(_hotelDeals);
// }

function clearHotelData() {
	_hotels = {};
	_hotels_scroll_y = {};
	_current_hotel_id = '';
	// _hotelDeals = {}; // TODO: verify no major effects
}

// function areDealsDuplicates(firstDeal, secondDeal) {
//   return (firstDeal.id !== secondDeal.id) // verifies we're not comparing a deal to itself
//     && (firstDeal.description === secondDeal.description) // room name
//     && (firstDeal.breakfast === secondDeal.breakfast)
//     && firstDeal.chooseprod && secondDeal.chooseprod
//     // && (firstDeal.chooseprod.dca.nonRefundable === secondDeal.chooseprod.dca.nonRefundable)
//     // TODO: verify free cancellation terms are also identical...
//     && (firstDeal.chooseprod.dca.cancellationPoliciesText === secondDeal.chooseprod.dca.cancellationPoliciesText) // atm we assume its text is a strong enough indication
//     && (firstDeal.pricePerNight === secondDeal.pricePerNight)
//     && (firstDeal.currency === secondDeal.currency);
// }

// function shouldIgnore(checkedDeal) {
//   for (var key in _hotelDeals) {
//     var hotelDeal = _hotelDeals[key];
//     if (areDealsDuplicates(hotelDeal, checkedDeal)) {
//       console.log("Found identical deals:", hotelDeal, checkedDeal);
//       return true;
//     }
//   }

//   return false;
// }

const HotelStore = _.extend({}, EventEmitter.prototype, {
	getHotelById: function (hotelId) {
		return _.get(_hotels, hotelId);
	},

	getHotelScrollById: function (hotelId) {
		return _.get(_hotels_scroll_y, hotelId, 0);
	},

	////////////////////////////////////////////////////////////////////////////////

	getHotelInfoStatus: function () {
		return _.get(_hotels[_current_hotel_id], 'info_status');
	},

	getHotelDealsStatus: function () {
		return _.get(_hotels[_current_hotel_id], 'deals_status');
	},

	getHotelScroll: function () {
		return _hotels_scroll_y[_current_hotel_id];
	},

	// Return search results
	getHotelData: function () {
		return _.extend({}, _hotels[_current_hotel_id]);
	},

	getHotelId: function () {
		return _current_hotel_id;
	},

	getHotelAmenities: function () {
		var hotel_data = ResultsStore.getHotelById(_current_hotel_id);
		return hotel_data && hotel_data.amenities;
	},

	getHotelDeals: function () {
		return ResultsStore.getHotelIndexedDealsById(_current_hotel_id);
	},

	// TODO: method for currency conversion

	// Emit Change event
	emitChange: function () {
		this.emit('change');
	},

	// Add change listener
	addChangeListener: function (callback) {
		this.on('change', callback);
	},

	// Remove change listener
	removeChangeListener: function (callback) {
		this.removeListener('change', callback);
	},

	// calculateDealIndex: function (deal) {
	//   var hotel_deals = ResultsStore.getHotelIndexedDealsById(_hotel.id);
	//   var deal_index = Object.keys(hotel_deals).indexOf(deal.id);
	//   return (deal_index >= 0)
	//     ? (deal_index + 1)
	//     : deal_index;
	// },

	getDealCount: function () {
		var hotel_deals = ResultsStore.getHotelIndexedDealsById(_current_hotel_id);
		return Object.keys(hotel_deals).length;
	},

	getTotalDisplayedDealsCount: function () {
		return _total_displayed_deals_count;
	},
});

// Register callback with AppDispatcher
AppDispatcher.register(function (payload) {
	var action = payload.action;
	//var text;

	switch (action.actionType) {
		case HotelConstants.SET_HOTEL_RECOMMENDED_INDEX: {
			let hotel = ResultsStore.getHotelById(action.data.hotel_id);
			if (hotel) {
				hotel.recommended_index = action.data.recommended_index;
			} else {
				console.error('SET_HOTEL_RECOMMENDED_INDEX not hotel found with id ' + action.data.hotel_id);
			}
			break;
		}

		case HotelConstants.SET_HOTEL_SCROLL: {
			const { scroll_y, hotel_id } = action.data;
			_hotels_scroll_y[hotel_id] = scroll_y;
			break;
		}

		// Respond to RECEIVE_DATA action
		case HotelConstants.RECEIVE_HOTEL_DATA:
			loadHotelData(action.data);
			break;

		case HotelConstants.RECEIVE_HOTEL_DATA_BY_ID: {
			let hotel = ResultsStore.getHotelById(action.data);
			if (hotel) {
				loadHotelData(hotel);
			} else {
				console.error('not hotel found with id ' + action.data);
			}
			break;
		}

		//// Respond to SEARCH action
		//case HotelConstants.RECEIVE_HOTEL_DEALS_DATA:
		//  loadDealsData(action.data);
		//    break;

		// case HotelConstants.ENRICH_HOTEL_WITH_DEALS:
		//   enrichHotelWithDeals(action.data);
		//   break;
		//
		// case HotelConstants.ENRICH_HOTEL_WITH_ADDITIONAL_INFO:
		//   enrichHotelWithAdditionalInfo(action.data);
		//   break;

		case HotelConstants.CLEAR_HOTEL_DATA:
			clearHotelData();
			break;

		case HotelConstants.CLEAR_HOTEL_DATA_BY_ID: {
			let hotel = ResultsStore.getHotelById(action.data);
			console.log('clear hotel data for ' + action.data);
			if (hotel) {
				hotel.deals = [];
				hotel.representativeDeal = {};
				hotel.RecheckStatus = HotelConstants.RECHECK_STATUS.INITIAL;
			} else {
				console.error('not hotel found with id ' + action.data);
			}
			break;
		}

		// case HotelConstants.CHECK_DEALS_AVAILABILITY:
		//   var checkedDeals = action.data;
		//   // console.log("checking deals: ", checkedDeals);
		//   checkedDeals.forEach(function (checkedDeal) {
		//     if (_hotelDeals[checkedDeal.id]) {
		//       (_hotelDeals[checkedDeal.id]).status = "busy";
		//     }
		//   });
		//   break;
		//
		// case HotelConstants.DEALS_AVAILABILITY_CHECK_SUCCESS:
		//   var checkedDeals = action.data.res;
		//   checkedDeals.forEach(function (checkedDeal) {
		//     if (_hotelDeals[checkedDeal.deal_id]) {
		//       if (checkedDeal.chooseprod) {
		//         (_hotelDeals[checkedDeal.deal_id]).chooseprod = checkedDeal.chooseprod;
		//         (_hotelDeals[checkedDeal.deal_id]).status = "success";
		//
		//         if (shouldIgnore(_hotelDeals[checkedDeal.deal_id])) {
		//
		//           (_hotelDeals[checkedDeal.deal_id]).ignore = true;
		//         }
		//       } else if (checkedDeal.err) {
		//         _hotelDeals[checkedDeal.deal_id].error = checkedDeal.err;
		//         (_hotelDeals[checkedDeal.deal_id]).status = "na";
		//       } else {
		//         console.warn("case HotelConstants.DEALS_AVAILABILITY_CHECK_SUCCESS: no error or chooseprod", checkedDeal);
		//       }
		//     }
		//   });
		//   break;
		//
		// case HotelConstants.DEALS_AVAILABILITY_CHECK_FAIL:
		//   var checkedDeals = action.data.deals;
		//   checkedDeals.forEach(function (checkedDeal) {
		//     if (_hotelDeals[checkedDeal.deal_id]) {
		//       _hotelDeals[checkedDeal.deal_id].error = checkedDeal.err;
		//       (_hotelDeals[checkedDeal.deal_id]).status = "fail";
		//     }
		//   });
		//   break;
		//
		// case HotelConstants.GET_HOTEL_DEALS:
		//   _hotel.deals_status = HotelConstants.DEALS_STATUS.BUSY;
		//   break;
		//
		// case HotelConstants.GET_HOTEL_DEALS_FAILED:
		//   _hotel.deals_status = HotelConstants.DEALS_STATUS.FAIL;
		//   break;
		//
		// case HotelConstants.GET_HOTEL_INFO:
		//   _hotel.info_status = HotelConstants.INFO_STATUS.BUSY;
		//   break;
		//
		// case HotelConstants.GET_HOTEL_INFO_SUCCEEDED:
		//   _hotel.info_status = HotelConstants.INFO_STATUS.SUCCESS;
		//   break;
		//
		// case HotelConstants.GET_HOTEL_INFO_FAILED:
		//   _hotel.info_status = HotelConstants.INFO_STATUS.FAIL;
		//   break;

		case HotelConstants.ENRICH_HOTEL_WITH_ADDITIONAL_INFO:
			// loadHotelData(action.data);
			loadHotelInfo(action.data);
			break;

		case HotelConstants.SET_TOTAL_DISPLAYED_DEALS_COUNT:
			_total_displayed_deals_count = action.data.count;
			break;

		default:
			return true;
	}

	// If action was responded to, emit change event
	HotelStore.emitChange();

	return true;
});

export default HotelStore;
