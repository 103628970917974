import keyMirror from 'keymirror';
import _ from 'lodash';
import moment from 'moment';

const RESULTS_CONSTANTS = {
	ACTIONS: {
		SORT: 'sort',
		FILTER: 'filter',
	},
	EXPIRATION_TIMEOUT_MILLIS: moment.duration(28, 'minutes').asMilliseconds(),
	STATUSES: {
		INITIAL: 'initial',
		WAITING: 'waiting',
		FINISHED: 'finished ',
	},
};

// Define action constants
export default _.extend(
	{},
	RESULTS_CONSTANTS,
	keyMirror({
		RECEIVE_TRAVEL_POLICY_DATA: null,
		RECEIVE_SEARCH_RESULTS_DATA: null,
		CLEAR_SEARCH_RESULTS: null,
		UPDATE_STATUS: null,
		ERROR_ON_SEARCH: null,
		CLEAR_TRAVEL_POLICY: null,
		SET_RESULTS_FROM_OFFLINE_DB: null,
		RECALCULATE_RECOMMENDATIONS: null,
	}),
);
