///
/// Important Note:
/// For legacy reasons, while (VERY) bad practice
/// Update changes in arbitrip-web-ui ::: src/entities/Reservation.js
///

import moment from 'moment';
import _ from 'lodash';
import Room from './Room';
import User from './User';
import ReservationConstants from '../constants/ReservationConstants';

function valueOrDefaultInArray(arr, i, property) {
	if (arr && arr[i] && arr[i][property]) {
		return arr[i][property];
	}
	return '';
}

function addTraveler(pre_filled_travelers, index, room_id) {
	this.travelers.push(
		new User({
			id: valueOrDefaultInArray(pre_filled_travelers, index, 'id'),
			first_name: valueOrDefaultInArray(pre_filled_travelers, index, 'first_name'),
			last_name: valueOrDefaultInArray(pre_filled_travelers, index, 'last_name'),
			email: valueOrDefaultInArray(pre_filled_travelers, index, 'email'),
			phone: valueOrDefaultInArray(pre_filled_travelers, index, 'phone'),
			room: room_id,
		}),
	);
}

export default function (reservation, extraParams = {}) {
	this.cityInitials = ' ';
	this.company = {
		name: '',
		picture: '',
	};

	this.group_id = reservation.group_id;
	this.payment = reservation.payment;

	this.id = reservation._id;
	this.short_id = reservation.short_id || this.id;
	this.status = reservation.status;
	this.confirmed = this.status === ReservationConstants.RESERVATION_STATUS.APPROVED;
	this.showInvoice = [
		ReservationConstants.RESERVATION_STATUS.APPROVED,
		ReservationConstants.RESERVATION_STATUS.CANCELLED,
	].includes(this.status);
	this.client_trip_id = reservation.client_trip_id;

	const tas_id = _.get(reservation, 'integrations.combtas.tas_id');
	if (tas_id) {
		this.combtas_id = tas_id;
	}
	const docket_number = _.get(reservation, 'integrations.travel_booster.docket_number');
	if (docket_number) {
		this.travel_booster_docket_number = docket_number;
	}

	const agency_client_price = _.get(reservation, 'agency_client_price');
	if (agency_client_price) {
		this.agency_client_price = agency_client_price;
	}

	const agent_pricing = _.get(reservation, 'agent_pricing');
	if (_.isEmpty(this.agent_pricing)) {
		this.agent_pricing = {
			pricing_method: ReservationConstants.AGENT_PRICING_METHOD.MARGIN_PERCENTAGE,
			absolute_price: _.get(reservation, 'deal.totalPrice'),
			absolute_margin: 0, // todo get from contract
			margin_percentage: 0, // todo get from contract
			valid: true,
		};
	} else {
		this.agent_pricing = agent_pricing;
	}

	this.creator = reservation.creator;
	this.handling_agent = reservation.handling_agent;

	this.hotel = reservation.hotel; //new Hotel(reservation.hotel);
	this.deal = reservation.deal; // TODO: Use entity
	if (this.deal && this.deal.details) {
		_.set(this, 'deal.details.check_in', moment.utc(this.deal.details.check_in));
		_.set(this, 'deal.details.check_out', moment.utc(this.deal.details.check_out));
	}
	if (this.deal?.details?.pureExpediaPrices) {
		_.set(this, 'deal.expedia_pricing', this.deal.details.pureExpediaPrices);
	}
	// this.hotel = new Hotel(hotel);
	// this.deal = new Deal(deal);

	if (reservation.deal && reservation.deal.rooms) {
		this.rooms = {};
		for (var i = 1; i <= reservation.deal.rooms; i++) {
			this.rooms[i] = new Room(reservation.deal.room, reservation.deal.guestsPerRoom);
		}
	}
	// console.log("Reservation.js >>> deal = ", reservation.deal, " | rms * GPR = ", (reservation.deal.rooms * reservation.deal.guestsPerRoom));
	this.private_travel = reservation.private_travel;

	if (reservation.travellers) {
		this.travelers = reservation.travellers;
		// this.travelers = this.travelers.concat(this.travelers); // used for +x more travelers testing
	} else {
		this.travelers = [];
		const pre_filled_travelers = extraParams.travelers ? extraParams.travelers : [];

		if (this.private_travel) {
			// If it's private travel, ensure only one traveler per room
			for (let j = 0; j < reservation.deal.rooms; j++) {
				const room_id = j + 1;
				addTraveler.call(this, pre_filled_travelers, j, room_id);
			}
		} else {
			// Normal behavior, add travelers based on rooms and guests per room
			for (let j = 0; j < reservation.deal.rooms * reservation.deal.guestsPerRoom; j++) {
				const room_id = Math.floor(j / reservation.deal.guestsPerRoom) + 1;
				addTraveler.call(this, pre_filled_travelers, j, room_id);
			}
		}
	}

	this.supplierTerms = reservation.supplier_terms;

	this.specialRequests = reservation.special_requests || '';
	this.specialRequestsFromSuggestions = {};

	this.travel_policy = reservation.travel_policy_snapshot;

	this.creation_date = new Date(reservation.created_timestamp);
	this.last_change_date = new Date(reservation.changed_timestamp);

	this.invoice_url =
		this.payment &&
		this.payment.invoice &&
		this.payment.invoice.response &&
		(this.payment.invoice.response.copy_doc_url || this.payment.invoice.response.doc_copy_url);
	if (extraParams.management_trip_id) {
		this.management_trip_id = extraParams.management_trip_id;
	}
	if (extraParams.management_trip_name) {
		this.management_trip_name = extraParams.management_trip_name;
	}
	if (extraParams.quote_id) {
		this.quote_id = extraParams.quote_id;
	}

	if (extraParams.group_id) {
		this.quote_id = extraParams.group_id;
	}

	this.include_management_trip_id = this.management_trip_id ? true : false;
	if (reservation.search_terms) {
		this.search_terms = reservation.search_terms;
	}

	if (reservation.contract && reservation.contract.company) {
		this.company = {
			id: reservation.contract.company._id,
			name: reservation.contract.company.name,
			picture: reservation.contract.company.picture,
		};
	}

	if (reservation.supplier_name) {
		this.supplier_name = reservation.supplier_name;
	}
	if (reservation.if_credit_card_charge_date) {
		this.if_credit_card_charge_date = new Date(reservation.if_credit_card_charge_date);
	}
	this.supplier_ref = reservation.supplier_ref;
	this.has_voucher = reservation.has_voucher;

	this.out_of_policy_booking_reason = reservation.out_of_policy_booking_reason;

	this.setCanceled = () => {
		this.status = ReservationConstants.RESERVATION_STATUS.CANCELED;
		this.confirmed = this.status === ReservationConstants.RESERVATION_STATUS.APPROVED;
		this.cancelStatus = 'succeeded';
	};

	this.mock = reservation.mock;

	this.agent_notes = reservation.agent_notes;
	this.agent_note = _.get(reservation, 'agent_notes.note', '');

	if (reservation.arbitrip_loyalty_earned) {
		this.arbitrip_loyalty_earned = reservation.arbitrip_loyalty_earned;
	}

	if (reservation.pay_later_deadline) {
		this.pay_later_deadline = reservation.pay_later_deadline;
	}
}
