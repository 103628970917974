import withStyles from '@mui/styles/withStyles';

import AddIcon from '@mui/icons-material/Add';

import _ from 'lodash';

import React from 'react';
import PropTypes from 'prop-types';

import DestinationsConstants from '../../../../constants/DestinationsConstants';
import DestinationsBoxStore from '../../../../stores/DestinationsBoxStore';
import DestinationComponent from './DestinationComponent.react';
import EmptyStateSearchesDestination from '../EmptyStateSearchesDestination.react';

import DestinationsActions from '../../../../actions/DestinationsActions';

import AddDestinationComponent from './AddNewBusinessDestinationComponent.react';
import ProfileStore from '../../../../stores/ProfileStore';

import { CircularProgress } from '@mui/material';

import classNames from 'classnames';

import Constants from './consts';
const { CLASSES } = Constants;

const styles = {};

function getState() {
	return {
		profile: ProfileStore.getProfile(),
		destinations: DestinationsBoxStore.getItems(),
		status: DestinationsBoxStore.getStatus(),
	};
}

class DestinationsBox extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			showAddDestination: false,
			...getState(),
		};

		this._onChange = this._onChange.bind(this);
		this.getManageDestination = this.getManageDestination.bind(this);
		this.toggleAddDestinationWindow = this.toggleAddDestinationWindow.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.contractId !== this.props.contractId) {
			DestinationsActions.getDestinations(this.props.contractId);
		}
	}

	getManageDestination() {
		const { profile } = this.state;
		let contract_client_company = ProfileStore.getContractClientCompany(this.props.contractId);

		return (
			(profile.agent || profile.travel_manager) &&
			(!contract_client_company || contract_client_company._id === profile.company.id)
		);
	}

	showDestinationBox() {
		const can_manage = this.getManageDestination();
		if (can_manage) return true;
		const { destinations } = this.state;
		return !!(destinations && destinations.length);
	}

	getDestinations() {
		const { profile, destinations } = this.state;

		if (destinations && destinations.length > 0) {
			const reversedDestinations = [...destinations].reverse();

			return reversedDestinations.map((destination, i) => (
				<DestinationComponent
					profile={profile}
					destination={destination}
					key={'destination-' + i}
					allowRemoval={this.getManageDestination()}
					index={i}
				/>
			));
		}

		return (
			<EmptyStateSearchesDestination
				title="No destinations found"
				description="Your destinations will appear here"
			/>
		);
	}

	toggleAddDestinationWindow(forceAction = null) {
		const { showAddDestination } = this.state;
		if (forceAction) {
			this.setState({ showAddDestination: forceAction });
		} else {
			this.setState({ showAddDestination: !showAddDestination });
		}
	}

	render() {
		const { showAddDestination, status } = this.state;

		if (status === DestinationsConstants.STATUS.BUSY && this.showDestinationBox()) {
			return (
				<div className="destinations-box flex flex-center">
					<CircularProgress />
				</div>
			);
		}

		if (status !== DestinationsConstants.STATUS.SUCCESS || !this.showDestinationBox()) {
			return null;
		}

		const boxClasses = classNames('destinations-box', {
			expanded: showAddDestination,
		});

		return (
			<div className={boxClasses}>
				<div className="title">
					<div className="title-main">Business Destinations</div>
					{this.getManageDestination() && (
						<button className={CLASSES.TITLE_ACTION} onClick={this.toggleAddDestinationWindow}>
							Add a new business destination
							<AddIcon />
						</button>
					)}

					{showAddDestination && (
						<AddDestinationComponent
							key="addMoreDestination"
							onClose={() => this.toggleAddDestinationWindow(false)}
						/>
					)}
				</div>
				<div className="destinations-components-container">{this.getDestinations()}</div>
			</div>
		);
	}

	_onChange() {
		this.setState(getState());
	}

	componentDidMount() {
		DestinationsBoxStore.addChangeListener(this._onChange);
		const { contractId } = this.props;
		setTimeout(() => {
			DestinationsActions.getDestinations(contractId);
		});
	}

	componentWillUnmount() {
		DestinationsBoxStore.removeChangeListener(this._onChange);
	}
}

DestinationsBox.propTypes = {
	contractId: PropTypes.string,
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DestinationsBox);
