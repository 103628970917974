import PropTypes from 'prop-types';
import React from 'react';

const DealsTitleComponent = ({ title, children }) => (
	<div className="deals-container">
		<span className="deals-title">{title}</span>
		{children}
	</div>
);

DealsTitleComponent.propTypes = {
	title: PropTypes.string,
	children: PropTypes.node,
};

export default DealsTitleComponent;
