import BookedItinerary from '../entities/BookedItinerary';

import TripRequestFlight from './TripRequestFlight';
import TripRequestAccommodation from './TripRequestAccommodation';
// import TripRequestCar from './TripRequestCar';
import moment from 'moment';
import ProfileEntity from '../entities/Profile';

const initialized_status_regex = new RegExp('initialized', 'i');

function TripRequest(trip_request, profile) {
	this.id = trip_request._id;

	this.status = trip_request.status;

	this.name = trip_request.name || '';
	this.travelers = [];
	if (Array.isArray(trip_request.travelers)) {
		this.travelers = trip_request.travelers.map((profile) => new ProfileEntity(profile));
	} else if (profile && (!this.status || initialized_status_regex.test(this.status))) {
		this.travelers = [profile];
	}
	this.staying_with_text = trip_request.staying_with_text;
	this.trips_schedule_and_agenda_text = trip_request.trips_schedule_and_agenda_text;

	// this.self_travel = trip_request.self_travel || false;
	//
	this.purposes = trip_request.purposes || [];
	// this.cost_centers = trip_request.cost_centers || [];
	this.cost_center = trip_request.cost_center || {};

	this.conference_name_or_website = trip_request.conference_name_or_website || '';
	this.conference_estimated_cost_currency = trip_request.conference_estimated_cost_currency || 'USD'; // TODO: profile default currency
	this.conference_estimated_cost_amount = trip_request.conference_estimated_cost_amount || '';

	let flights = (trip_request.trip_components_request && trip_request.trip_components_request.flights) || [];
	this.flights = flights.map((flight) => new TripRequestFlight(flight));
	this.flights.sort((a, b) => a.display_order - b.display_order);

	let accommodations =
		(trip_request.trip_components_request && trip_request.trip_components_request.accommodations) || [];
	this.accommodations = accommodations.map((accommodation) => new TripRequestAccommodation(accommodation));
	this.accommodations.sort((a, b) => a.display_order - b.display_order);

	this.waiting_for_pre_approval =
		trip_request.trip_components_request && trip_request.trip_components_request.pre_approval_state === 'sent';
	this.pre_approved =
		trip_request.trip_components_request &&
		trip_request.trip_components_request.pre_approval_state === 'pre_approved';
	this.pre_rejected =
		trip_request.trip_components_request &&
		trip_request.trip_components_request.pre_approval_state === 'pre_rejected';
	this.waiting_for_post_approval =
		trip_request.booked_itinerary &&
		trip_request.booked_itinerary.was_sent_to_approval &&
		this.status === 'pending';
	this.post_approved =
		trip_request.booked_itinerary &&
		trip_request.booked_itinerary.was_sent_to_approval &&
		this.status === 'approved';
	// let cars = (trip_request.trip_components_request && trip_request.trip_components_request.cars) || [];
	// this.cars = cars.map((car) => new TripRequestCar(car));

	this.special_requests = trip_request.special_requests || '';
	this.vacation_days_from = trip_request.vacation_days_from && moment(trip_request.vacation_days_from);
	this.vacation_days_to = trip_request.vacation_days_to && moment(trip_request.vacation_days_to);

	this.meta = trip_request.meta;

	this.booked_itinerary = new BookedItinerary(trip_request.booked_itinerary);

	this.hasItinerary = function () {
		return (
			this.booked_itinerary &&
			Array.isArray(this.booked_itinerary.airfares) &&
			Array.isArray(this.booked_itinerary.accommodations) &&
			this.booked_itinerary.airfares.concat(this.booked_itinerary.accommodations).length
		);
	};

	this.live_meta = trip_request.live_meta;
}

export default TripRequest;
