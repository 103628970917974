import React from 'react';

import FiltersActions from '../../../../actions/FiltersActions';

import FiltersConstants from '../../../../constants/FiltersConstants';
import Analytics from 'arbitrip-common/client/analytics';

import FilterCheckboxWithLabel from './components/FilterCheckboxWithLabel.react';
import ResultsStore from '../../../../stores/ResultsStore';

function isNeighborhoodEnabled(neighborhood) {
	return ResultsStore.getNeighborhoodCount(neighborhood) >= 10;
}

class NeighborhoodsFilter extends React.Component {
	constructor(props) {
		super(props);

		this.handleCheckboxChangeByNeighborhood = this.handleCheckboxChangeByNeighborhood.bind(this);
		this.handleNeighborhoodOnlyClick = this.handleNeighborhoodOnlyClick.bind(this);
		this.handleResetNeighborhoodsClick = this.handleResetNeighborhoodsClick.bind(this);
	}

	handleCheckboxChangeByNeighborhood = (event, isInputChecked, neighborhood) => {
		const { profile } = this.props;

		event.persist();
		FiltersActions.filterByNeighborhoods(neighborhood, isInputChecked);
		setTimeout(() => {
			FiltersActions.filterByNeighborhoods(neighborhood, isInputChecked);
		}, 10);

		const filter_value = (isInputChecked ? 'Filter by "' : 'Don\'t filter by"') + neighborhood + '"';
		Analytics.actions.interactions.filteredResults(
			profile,
			FiltersConstants.FILTER_METHOD.NEIGHBORHOODS,
			filter_value,
		);
	};

	handleNeighborhoodOnlyClick = (event) => {
		const { profile } = this.props;

		event.persist();
		let neighborhood_only = event.target.getAttribute('data-neighborhood');
		FiltersActions.filterByNeighborhoodsOnly(neighborhood_only);
		setTimeout(() => {
			FiltersActions.filterByNeighborhoodsOnly(neighborhood_only);
		}, 10);

		const filter_value = 'Show Only "' + neighborhood_only + '" Neighborhood';
		Analytics.actions.interactions.filteredResults(
			profile,
			FiltersConstants.FILTER_METHOD.NEIGHBORHOOD_ONLY,
			filter_value,
		);
	};

	handleResetNeighborhoodsClick = (event) => {
		const { profile } = this.props;

		event.persist();
		FiltersActions.resetNeighborhoodsFilter();
		setTimeout(FiltersActions.resetNeighborhoodsFilter, 10);

		const filter_value = 'Reset Neighborhoods Filter';
		Analytics.actions.interactions.filteredResults(
			profile,
			FiltersConstants.FILTER_METHOD.RESET_NEIGHBORHOODS_FILTER,
			filter_value,
		);
	};

	render() {
		const data = this.props.filter;
		const number_of_neighborhoods = Object.keys(data).length;
		const number_of_enabled = Object.keys(data).filter(isNeighborhoodEnabled).length;
		const multiple_neighborhoods = number_of_neighborhoods > 1;

		const NEIGHBORHOODS = Object.keys(data).map((neighborhood) => (
			<div className="checkbox-container" key={neighborhood.toLowerCase().replace(' ', '-')}>
				<FilterCheckboxWithLabel
					checked={data[neighborhood]}
					neighborhood={neighborhood}
					label={`${neighborhood} (${ResultsStore.getNeighborhoodCount(neighborhood)})`}
					onChange={(e, c, neighborhood) => this.handleCheckboxChangeByNeighborhood(e, c, neighborhood)}
					{...(!isNeighborhoodEnabled(neighborhood)
						? {
								disabled: true,
								style: { textDecoration: 'line-through' },
							}
						: {})}
				/>
				{multiple_neighborhoods ? (
					<button
						className="btn btn-link"
						data-neighborhood={neighborhood}
						onClick={this.handleNeighborhoodOnlyClick}
					>
						Only
					</button>
				) : null}
			</div>
		));

		return (
			<div className="filter disabled">
				<label className="filter-title" htmlFor="NEIGHBORHOODS-filter">
					Neighborhoods{' '}
					{number_of_neighborhoods > 0 && (
						<React.Fragment>
							(<span>{number_of_enabled}</span>/
							<span style={{ color: 'rgba(0, 0, 0, 0.38)', textDecoration: 'line-through' }}>
								{number_of_neighborhoods - number_of_enabled}
							</span>
							)
						</React.Fragment>
					)}
				</label>
				<div name="NEIGHBORHOODS-filter" className="check-list">
					{NEIGHBORHOODS}
				</div>
				{multiple_neighborhoods ? (
					<div className="clear-button" onClick={this.handleResetNeighborhoodsClick} />
				) : null}
			</div>
		);
	}
}

export default NeighborhoodsFilter;
