// routing
import { withRouter } from './withRouter';
import { Navigate, Route, Routes } from 'react-router';
import { BrowserRouter, useParams } from 'react-router-dom';

import ManagementConstants from '../constants/ManagementConstants';

import React from 'react';

// view components
import _AppGrid from '../components/grid/AppGrid.react';
import SearchPage from '../components/pages/search/SearchPage.react';
import ResultsPage from '../components/pages/results/ResultsPage.react';
import _HotelPage from '../components/pages/hotel/HotelPage.react';
import BookingsPage from '../components/pages/new-bookings/BookingsPage.react';
import RequestsPage from '../components/pages/requests/RequestsPage.react';
import ReportsPage from '../components/pages/reports/ReportsPage.react';
import UsersManagementPage from '../components/pages/users-management/UsersManagementPage.react';
import TravelPolicyPage from '../components/pages/travel_policy/TravelPolicyPage';

import ReservationPaymentPage from '../components/pages/reservation-payment/ReservationPaymentPage.react';

import ManagementPage from '../components/pages/management/ManagementPage';
import _TripTablePage from '../components/pages/management/TripTablePage';
import _OngoingTripsPage from '../components/pages/management/OngoingTripsPage';
import _TripRequestPage from '../components/pages/management/TripRequestPage.react';
import _TripRequestWizardPage from '../components/pages/management/TripRequestWizardPage';
import _ForwardRequestsPage from '../components/pages/management/ForwardRequestsPage.react';
import _AddAirfareWizardPage from '../components/pages/management/AddAirfareWizardPage';
import PreviewPane from '../components/pages/management/PreviewPane';
import TripPreview from '../components/pages/management/TripPreview.react';

import EmptyPage from '../components/pages/EmptyPage.react';
import FaqPage from '../components/pages/FaqPage';

const AppGrid = withRouter(_AppGrid);
const HotelPage = withRouter(_HotelPage);
const TripTablePage = withRouter(_TripTablePage);
const OngoingTripsPage = withRouter(_OngoingTripsPage);
const TripRequestPage = withRouter(_TripRequestPage);
const TripRequestWizardPage = withRouter(_TripRequestWizardPage);
const ForwardRequestsPage = withRouter(_ForwardRequestsPage);
const AddAirfareWizardPage = withRouter(_AddAirfareWizardPage);

function HotelPageWrapper() {
	const { hotel_name, hotel_id } = useParams();
	return <HotelPage hotelName={hotel_name} hotelId={hotel_id} />;
}

function isStrictMode() {
	const strict_mode = (process.env.REACT_APP_STRICT_MODE || '').toLowerCase();

	return strict_mode === 'true';
}

function StrictModeWrapper(component) {
	console.warn('App is running is StrictMode...');
	return <React.StrictMode>{component}</React.StrictMode>;
}

const BrowserRouterAppGrid = (
	<BrowserRouter>
		<AppGrid>
			<Routes>
				<Route path="/search" element={<SearchPage />} />
				{/* <Route path="/search" element={<BookingsPage />} /> */}
				<Route path="/results" element={<ResultsPage />} />

				<Route path="/hotel/:hotel_name/:hotel_id" element={<HotelPageWrapper />} />

				<Route path="/reservation" element={<ReservationPaymentPage />} />

				{/* <Route path="/bookings" element={<OldBookingsPage />} /> */}
				<Route path="/bookings" element={<BookingsPage />} />
				<Route path="/requests" element={<RequestsPage />} />
				<Route path="/dashboard" element={<ReportsPage />} />
				<Route path="/management" element={<UsersManagementPage />} />
				<Route path="/travel_policy" element={<TravelPolicyPage />} />
				<Route path="/faq" element={<FaqPage />} />

				<Route path="/management" element={<Navigate to="/trips" />} />

				<Route
					path="/trips/live"
					element={<ManagementPage main={<OngoingTripsPage />} sidebar={<TripPreview />} />}
				/>
				<Route
					path={'/' + ManagementConstants.SOURCES.TRIPS}
					element={<ManagementPage main={<TripTablePage />} sidebar={<TripPreview />} />}
				/>
				<Route
					path="/trip/:trip_request_id"
					element={<ManagementPage main={<TripRequestPage />} sidebar={<PreviewPane />} />}
				/>
				<Route
					path={'/' + ManagementConstants.SOURCES.WIZARD}
					element={<ManagementPage main={<TripRequestWizardPage />} />}
				/>
				<Route
					path={'/' + ManagementConstants.SOURCES.FORWARD}
					element={<ManagementPage main={<ForwardRequestsPage />} sidebar={<PreviewPane />} />}
				/>
				<Route
					path={'/' + ManagementConstants.SOURCES.ADD_AIRFARE_WIZARD}
					element={<ManagementPage main={<AddAirfareWizardPage />} sidebar={<PreviewPane />} />}
				/>

				<Route path="/unauthorized" element={<EmptyPage />} />

				<Route path="/" element={<Navigate to="/search" />} />

				<Route path="*" element={<Navigate to="/search" />} />
			</Routes>
		</AppGrid>
	</BrowserRouter>
);

const App = isStrictMode() ? () => StrictModeWrapper(BrowserRouterAppGrid) : () => BrowserRouterAppGrid;

export default App;
