class NumberFormatter {
	format(num) {
		if (Math.abs(num) > 999) {
			if (Math.abs(num) > 999999) {
				return Math.sign(num) * (Math.abs(num) / 1000000).toFixed(1) + 'M';
			} else {
				return Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'k';
			}
		}
		return Math.sign(num) * Math.abs(num);
	}
}

export default new NumberFormatter();
