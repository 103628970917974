const CONSTANTS = require('@arbitrip/constants')
const _default_precision = parseInt(process.env.REACT_APP_PRICE_PRECISION);
const default_precision = !isNaN(_default_precision) ? _default_precision : 0;

const currencySymbols = {

  'USD': '$',     // US Dollar
  'EUR': '€',     // Euro
  'CRC': '₡',     // Costa Rican Colón
  'GBP': '£',     // British Pound Sterling
  'ILS': '₪',     // Israeli New Sheqel
  'INR': '₹',     // Indian Rupee
  'JPY': '¥',     // Japanese Yen
  'KRW': '₩',     // South Korean Won
  'NGN': '₦',     // Nigerian Naira
  'PHP': '₱',     // Philippine Peso
  'PLN': 'zł',    // Polish Zloty
  'PYG': '₲',     // Paraguayan Guarani
  'THB': '฿',     // Thai Baht
  'UAH': '₴',     // Ukrainian Hryvnia
  'VND': '₫'      // Vietnamese Dong

  , 'AUD': 'AUD $' // Australian Dollar
  , 'BRL': 'R$'    // Brazilian Real
  , 'CAD': 'CAD $' // Canadian Dollar
  , 'SGD': 'SGD $' // Singaporian Dollar
  , 'CHF': 'CHF'   // Swiss Franc
  , 'MYR': 'RM'    // Malaysian Ringgit
  , 'NZD': 'NZD'   // New Zealandian Dollar
  , 'HUF': 'Ft'    // Hungarian Forint
  , 'HKD': 'HK$'   // Hong Kong, Dollar
  , 'MXN': 'MXN $' // Mexican Peso
  , 'ZAR': 'R'     // South African Rand
  , 'SEK': 'kr'    // Swedish Krona
  , 'IDR': 'Rp'    // Indonesian Rupiah
  , 'KES': 'KSh'   // Kenyan Shilling
};

const hotelsCurrencies = CONSTANTS.ARBITRIP_CURRENCIES;

function getSymbol(currency) {
  if (!currency) {
    currency = 'USD';
  }

  return currencySymbols[currency]
    ? currencySymbols[currency]
    : currency;
}

function getPriceWithCurrency(price, currency) {
  var symbol = getSymbol(currency);
  if (symbol === currency) {
    return (price && price.toLocaleString()) + ' ' + currency;
  } else {
    // eslint-disable-next-line eqeqeq
    return (price < 0 ? '-' : '') + symbol + ((price || price == 0) && Math.abs(price).toLocaleString());
  }
}

function getRoundedPriceWithCurrency(price, currency) {
  return getPriceWithCurrency(Math.round(price), currency);
}

function getPrecisePriceWithCurrency(price, currency) {
  return getPriceWithCurrency(price.toFixed(2), currency);
}

function getPriceByPrecision(price, precision) {
  // return Number(price).toFixed(precision || 0).toLocaleString(); // TODO: format properly 
  const verified_precision = !isNaN(precision) && (Number(precision) > 0)
    ? Number(precision)
    : 0;
  // return Number(price).toFixed(verified_precision); // TODO: format properly 
  return Math.max(Number(price), 0).toLocaleString(undefined, {
    minimumFractionDigits: verified_precision,
    maximumFractionDigits: verified_precision
  });
}

function getPriceWithDisplayCurrencyByPrecision(price, exchangeRate, displayCurrency, precision) {
  const display_price = price * exchangeRate;
  return getSymbol(displayCurrency) + getPriceByPrecision(display_price, precision);
}


function getPriceWithDisplayCurrencyByDefaultPrecision(price, exchangeRate, displayCurrency) {
  return getPriceWithDisplayCurrencyByPrecision(price, exchangeRate, displayCurrency, default_precision);
}

function isCurrencySupported(cur) {
  if (typeof cur !== 'string') {
    return false;
  }

  return hotelsCurrencies.some((hc) => (hc.currency === cur));
}

module.exports = {
  getSymbol,
  getPriceWithCurrency,

  getRoundedPriceWithCurrency,
  getPrecisePriceWithCurrency,

  hotelsCurrencies,
  currencySymbols,
  getPriceByPrecision,
  getPriceWithDisplayCurrencyByPrecision,
  getPriceWithDisplayCurrencyByDefaultPrecision,

  isCurrencySupported
};
