import FilterActions from '../../../actions/FiltersActions';
import Analytics from 'arbitrip-common/client/analytics';
import FiltersConstants from '../../../constants/FiltersConstants';

const NoAvailabilityMessage = () => {
	const handleResetFilterClick = () => {
		Analytics.actions.interactions.filteredDeals(
			FiltersConstants.FILTER_METHOD.DEAL_RESET_FILTER_ALL,
			'unfiltered',
		);
		FilterActions.resetDealsFilters();
	};

	return (
		<div className="no-valid-deals-wrapper">
			<img
				className="empty-state-image"
				src="../../img/recent_searches/recent_searches_empty.svg"
				alt="no results"
			></img>
			<div>No deals match your filter criteria. Try loosening the criteria.</div>
			<div>
				<a href="#!" onClick={handleResetFilterClick}>
					Reset
				</a>{' '}
				filters to show all deals.
			</div>
		</div>
	);
};

export default NoAvailabilityMessage;
