const SEARCH = require('./search-constants');
const BOOKINGS = require('./bookings-constants');

module.exports = {
    SEARCH_CONSTANTS: SEARCH,
    BOOKINGS_CONSTANTS: BOOKINGS,

    CLIENT_ROUTES: {
        SEARCH: 'search',
        RESULTS: 'results',
        HOTEL: 'hotel',
        RESERVATION: 'reservation',
        TRIP: 'trip',
        TRIPS: 'trips'
    },

    SEARCH_FIELDS: [
        'place_id',
        'hotel_id',
        'check_in',
        'check_out',
        'guests',
        'children_ages',
        'rooms',
        // 'search_token',
        'contract_id',
        'travelers',
        'management_trip_id',
        'management_trip_name',
        'private_travel',
        'derived_from',
        // 'combtas_id',
        // 'proposal_id',
        // 'travel_booster_docket_number',
        // 'agency_client_price',
        // 'agent_pricing',
        'search_token',
        '8_rooms',
    ],

    HOTEL_SEARCH_URL_FIELDS: [
        'place_id',
        'hotel_id',
        'check_in',
        'check_out',
        'guests',
        'children_ages',
        'rooms',
        'search_token',
        'contract_id',
        'travelers',
        'management_trip_id',
        'management_trip_name',
        'private_travel',
        'combtas_id',
        'proposal_id',
        'travel_booster_docket_number',
    ],

    FIREBASE_STRUCTURE: {
        SEARCH: {
            COLLECTION: 'searches',
            SUB_COLLECTION: 'batches',
            FIELD: 'hotels'
        },
        RECHECK: {
            COLLECTION: 'rechecks',
            SUB_COLLECTION: 'batches',
            FIELD: 'deals',
            MESSAGE_TYPE: 'message_type',
            MESSAGE_TYPE_RECHECK: 'message_type_recheck',
            MESSAGE_TYPE_RECHECK_COMPLETED: 'message_type_recheck_completed'
        }
    },

    API_SERVICES: {
        OPS: 'ops',
        HOTELS: 'hotels',
        PARTNERS: 'partners'
    },

    ERROR_MESSAGES: {
        KEY_NOT_FOUND: 'Key not found'
    },

    ERROR_LOYALTY_POINTS: {
        NONE: 'none',
        NOT_ENABLED: 'not enabled',
        INSUFFICIENT_POINTS: 'insufficient_points', // also change constant PAYMENT_CREDIT_CARD_ERROR_CODES.INSUFFICIENT_POINTS_ERROR
        ERROR_RETRIEVING: 'error_retrieving',
        INVALID_MAX_CAP: 'invalid_max_cap',
        NEGATIVE_POINTS: 'negative_points',
        CAP_OVERFLOW: 'cap_overflow',
        WRONG_PAYMENT_TYPE: 'wrong_payment_type',
        OTHER: 'other',
    },

    PAYMENT_TYPE: {
        NA: 'not_exists',
        COMPANY_POLICY: 'company_policy',
        CREDIT_CARD: 'credit_card', // credit card used by organically in our system
        CREDIT_CARD_DIRECT: 'credit_card_direct', // credit card used as an external system payment
        PAY_AT_HOTEL: 'pay_at_hotel',
        POINTS_ONLY: 'points_only', // Edge case where user is paying the whole amount using his points. No invoice in this case.
        GCASH_APP: 'gcash_app',
        HT_ZONE:'ht_zone',
        PAY_LATER: 'pay_later', // pay_later flow - cancel free cancellation reservations before it becomes non-refundable
        // TODO! Do we need this?
        EXPEDIA_MERCHANT_OF_RECORD: 'expedia_merchant_of_record', // Expedia merchant of record
        // BARTER : 'barter' // inside joke :)
        // Other: payments types: possible recurring payment, cash, ...
    },

    PAYMENT_STATUS: { // if you update this, update the model ...
        NA: 'not_exists',
        INITIALIZED: 'initialized',
        J5: 'J5', // we managed to secure credit via j5
        CHARGE_LATER: 'charge_later', // We will use a token to charge later on. But action isn't J5 performed
        // Pay_later is just a payment_type pay_later with status initialized
        POSTPONED: 'postponed', // an iska dehuya
        PENDING_MANUAL_BILLING: 'pending_manual_billing', // postponed deals that will be billed manually
        BILLED_IN_PROCESS: 'billed_in_process', // Intermediatery step. We got the money but billing process might fail later on (e.g. charge points). Needed so poller will continue
        BILLED: 'billed', // represents a payment we got (credit card we actually billed), company policy & pay at hotel
        CANCELLED: 'cancelled',
        ERROR: 'error', // an irrecoverable error occurred
        TOKENIZED_WITHOUT_BILLING: 'tokenized_without_billing', // We got a tokenized of credit card but haven't billed yet
        TOKENIZED_WITHOUT_BILLING_WITH_PAYMENT_SESSION: 'tokenized_without_billing_with_payment_session', // We got a tokenized of credit card haven't billed yet and started payment session protocol
        TOKENIZED_WITH_BILLING: 'tokenized_without_billing_with_payment_session', // We got a tokenized of credit card haven't billed yet and started payment session protocol
    },

    PAYMENT_CREDIT_CARD_ERROR_CODES: {
        NA: 'not_exists',
        NO_RESERVATION: 'missing_reservation',
        RESERVATION_NOT_APPROVED: 'reservation_not_approved',
        NO_PAYMENT_METHOD: 'missing_payment_method',
        MISSING_DATA: 'missing_data',
        COMMUNICATION_ERROR: 'communication_error',
        CREDIT_CARD_NOT_ACCEPTED_ERROR: 'credit_card_not_accepted_error',
        POLL_LATER_SUPPLIER_ERROR: 'poll_late_supplier_error',
        CREDIT_CARD_NOT_SUPPORTED_LOYALTY_CARD: 'credit_card_not_supported_loyalty_card',
        CHARGE_LOYALTY_POINTS_FAILED: 'charge_loyalty_points_failed',
        UNSPECIFIED_ERROR: 'unspecified_error', // Some generic catch all. There was an error
        VIRTUAL_BANK_CHARGE_ERROR: 'virtual_bank_charge_error',
        INSUFFICIENT_POINTS_ERROR: 'insufficient_points',  // also change constant ERROR_LOYALTY_POINTS.INSUFFICIENT_POINTS
        GCASH_PAYMENT_ERROR: 'gcash_payment_error'
    },

    CREDIT_CARD_CUSTOMER_TYPE: {
        // Differentiate between 'israeli' 'poalim' or world card by terminal
        NONE: 'none', // Not a credit card
        POALIM_WONDER: 'wonder', // poalim Loyalty
        ISRAEL: 'israel',
        WORLD: 'world',  // not creditguard
        HT_ZONE: 'ht-zone',
    },

    WEIGHTS: {
        DIFF_PER: 'diff_per',
        LEISURE_SCORE: 'leisure_score',
        CHEAPEST_SUPPLIER_FACTOR: 'cheapest_supplier_factor',
        REVIEW: 'review',
        STARS: 'stars'
    },

    LOYALTY_POINTS_PROVIDER: {
        DEPRECATED_NO_PROVIDER: 'N/A', // no loyalty program defined
        NO_PROVIDER: 'none', // no loyalty program defined
        ARBITRIP: 'arbitrip', // arbitrip defined points
        WONDER: 'wonder',  // wonder loyalty program
    },

    LOYALTY_POINTS_STATUS: {
        INITIALIZED: 'initialized',
        CHARGED: 'charged', // we called charge on these points and it succeeded
        CHARGED_ERROR: 'charged_error', // we got an error when charging
        REFUNDED: 'refunded', // we callred refund for these points and succeeded
        REFUNDED_ERROR: 'refunded_error', // got an error when trying to refund these points
    },
    
    LOYALTY_POINTS_UNLIMITED_MAX_CAP: -1,

    CSV_EXPORT_TYPES: {
        CSV: 'csv',
        PRIORITY: 'priority',
    },

    SPECIAL_REQUEST_SUGGESTIONS: {
        ARRIVING_LATE: {
            buttonText: "Arriving Late",
            dialogText: "Arriving late? Select your estimated time of arrival:",
            dialogTextTranslationKey: 'SelectYourEstimatedTimeOfArrival',
            selectPlaceHolder: 'Select time',
            options: [
                {
                    key: '1',
                    textValue: '20:00-00:00',
                },
                {
                    key: '2',
                    textValue: '00:00-03:00',
                    addNextDate: true,
                },
                {
                    key: '3',
                    textValue: '03:00-06:00',
                    addNextDate: true,
                },
                {
                    key: '4',
                    textValue: '06:00-12:00',
                    addNextDate: true,
                }
            ]
        },
    }
};