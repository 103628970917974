import * as Sentry from '@sentry/react';
import Config from 'arbitrip-common/client/utils/Config';

export function initSentry() {
	if (Config.prod === true) {
		Sentry.init({
			dsn: process.env.REACT_APP_SENTRY_DSN,
			integrations: [
				Sentry.browserTracingIntegration(),
				Sentry.replayIntegration({
					maskAllText: false,
					blockAllMedia: false,
				}),
			],
			// Performance Monitoring
			tracesSampleRate: 0.7, // capture most of the traces but also stay in quota limits
			// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
			tracePropagationTargets: ['localhost', /^(https?:\/\/)?.*\.?arbitrip\.com/],
			replaysSessionSampleRate: 0.0,
			replaysOnErrorSampleRate: 0.0,
		});
	}
}

export function sendUserToSentry({ id, full_name, email }) {
	if (Config.prod === true) {
		Sentry.setUser({
			...(id && { id }),
			...(full_name && { username: full_name }),
			...(email && { email }),
		});
	}
}
