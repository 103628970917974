import _ from 'lodash';

import RoomMappingService from './RoomMappingService';
import { FIREBASE_STRUCTURE } from '../../general/constants';
import {
    HotelConstants,
    DealConstants
} from '../constants';

import OfflineDB from '../utils/OfflineDB';

import {
    SEARCH_SESSION_EVENT
} from '../constants/SearchSessionConstants';


class SearchSessionService {
    getHotelDeals(hotel) {
        let hotel_deals = {};

        for (const primal_deal of hotel.primalDeals) {
            hotel_deals[primal_deal.id] = primal_deal;
        }

        if (hotel.representativeDeal) {
            hotel_deals[hotel.representativeDeal.id] = hotel.representativeDeal;
        }

        if (hotel.representativeBreakfastDeal) {
            hotel_deals[hotel.representativeBreakfastDeal.id] = hotel.representativeBreakfastDeal;
        }

        Object.assign(hotel_deals, hotel.indexed_deals);

        const deals = Object.values(hotel_deals).filter(_.identity);

        if (deals.length > 0) {
            return {
                _id: hotel.id,
                deals: deals.map((deal) => deal.original)
            }
        }

        return null;
    }

    getOnSearchDataCallback(searchSession, listenerId) {
        return (hotels) => {
            if (Array.isArray(hotels)) {
                if (hotels.length > 0) {
                    searchSession.search_results.addHotelsDeals(searchSession, hotels);
                    searchSession.notifyEvent(SEARCH_SESSION_EVENT.SEARCH_HOTELS_DEALS);

                    OfflineDB.setSearchDeals(
                        searchSession.search_token,
                        searchSession.search_results.hotels.map(this.getHotelDeals).filter(_.identity)
                    );
                } else {
                    // console.warn(`SearchSession.onSearchData (listenerId = ${listenerId}) got an empty hotels array`);
                }
            } else {
                console.warn(`SearchSession.onSearchData (listenerId = ${listenerId}) got invalid hotels payload:`, hotels);
            }
        };
    }

    getOnSearchErrorCallback(searchSession, listenerId) {
        return (err) => {
            if (err) {
                console.error(`SearchSession.onSearchError (listenerId = ${listenerId}):`, err);
                searchSession.notifyEvent(SEARCH_SESSION_EVENT.SEARCH_ERROR, err);
            } else {
                console.warn(`SearchSession.onSearchError (listenerId = ${listenerId}) got invalid error payload:`, err);
            }
        };
    }

    getOnSearchTimeoutCallback(searchSession, listenerId) {
        return () => {
            console.info(`SearchSession.getOnSearchTimeoutCallback (listenerId = ${listenerId})`);

            const hotelWithDeals = searchSession.search_results.hotels.filter(({representativeDeal}) => !!representativeDeal).length;
            if (!hotelWithDeals) {
                const errorMessage = `No deals returned for search,  (listenerId = ${listenerId}), (search_token = ${searchSession.search_token})`;
                console.error(errorMessage);
            }

            searchSession.stopSearchListener();

            searchSession.search_results.completed_listening = true;
            searchSession.search_results.recalculateRecommendationScore();

            searchSession.search_results.__TEMP__calculateMargins();

            searchSession.notifyEvent(SEARCH_SESSION_EVENT.SEARCH_COMPLETED);
        };
    }

    getOnRecheckDataCallback(searchSession, hotelId, listenerId) {
        return (rechecks) => {
            const banned_deal_ids = searchSession.banned_deal_ids ?? [];
            const deals = _.get(rechecks, FIREBASE_STRUCTURE.RECHECK.FIELD, [])
                .filter((deal) => _.get(deal, 'recheck_id') && !banned_deal_ids.includes(deal.id));
            const messages = _.get(rechecks, FIREBASE_STRUCTURE.RECHECK.MESSAGE_TYPE, []);
            let valid_payload = false;

            if (Array.isArray(deals) && (deals.length > 0)) {
                searchSession.search_results.addRecheckedDeals(searchSession, hotelId, deals);
                RoomMappingService.run(searchSession, hotelId);

                searchSession.notifyEvent(SEARCH_SESSION_EVENT.RECHECKED_DEALS);

                valid_payload = true;
            }

            if (Array.isArray(messages) && (messages.length > 0)) {
                const recheck_completed = messages.filter((msg) => (msg === FIREBASE_STRUCTURE.RECHECK.MESSAGE_TYPE_RECHECK_COMPLETED));
                if (recheck_completed.length > 0) {
                    RoomMappingService.flush(searchSession, hotelId);

                    const rechecked_hotel = searchSession.search_results.getHotelById(hotelId);
                    if (rechecked_hotel) {
                        rechecked_hotel.RecheckStatus = HotelConstants.RECHECK_STATUS.SUCCESS;
                    }

                    searchSession.notifyEvent(SEARCH_SESSION_EVENT.COMPLETED);
                    searchSession.notifyEvent(SEARCH_SESSION_EVENT.RECHECK_COMPLETED, hotelId);

                    valid_payload = true;
                }
            }

            if (!valid_payload && _.isEmpty(rechecks)) {
                console.warn('SearchSession.onRecheckData has empty rechecks object:', rechecks);
                searchSession.notifyEvent(SEARCH_SESSION_EVENT.RECHECK_ERROR);
                return;
            }

            if (!valid_payload) {
                console.warn('SearchSession.onRecheckData has invalid payload:', rechecks);
                searchSession.notifyEvent(SEARCH_SESSION_EVENT.RECHECK_ERROR);
            }
        }
    }

    getOnRecheckErrorCallback(searchSession, hotelId, listenerId) {
        return (err) => {
            if (err) {
                console.error(`SearchSession.onRecheckError (hotelId = ${hotelId} | listenerId = ${listenerId}):`, err);
                searchSession.notifyEvent(SEARCH_SESSION_EVENT.RECHECK_ERROR, err);
            } else {
                console.warn(`SearchSession.onRecheckError  (hotelId = ${hotelId} | listenerId = ${listenerId}) got invalid error payload:`, err);
            }
        };
    }

    getOnRecheckTimeoutCallback(searchSession, hotelId, listenerId) {
        return () => {
            const hotel = searchSession.search_results.getHotelById(hotelId);
            if (hotel.representativeDeal && !hotel.representativeDeal.recheck_id) {
                hotel.representativeDeal.Status = DealConstants.DEAL_STATUS.NA;
            }
            if (hotel.representativeBreakfastDeal && !hotel.representativeBreakfastDeal.recheck_id) {
                hotel.representativeBreakfastDeal.Status = DealConstants.DEAL_STATUS.NA;
            }

            hotel.RecheckStatus = HotelConstants.RECHECK_STATUS.SUCCESS;

            console.info(`SearchSession.getOnSearchTimeoutCallback (hotelId = ${hotelId} | listenerId = ${listenerId})`);
            searchSession.stopRecheckListener(hotelId);
            searchSession.notifyEvent(SEARCH_SESSION_EVENT.COMPLETED);
            searchSession.notifyEvent(SEARCH_SESSION_EVENT.RECHECK_COMPLETED);

            RoomMappingService.flush(searchSession);
        };
    }
}

export default new SearchSessionService();
