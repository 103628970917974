/**
 * Created by MrJossef on 12/20/16.
 */
const separator = ' | ';
const app_name = 'Arbitrip';

function defaults() {
	return app_name;
}

function Capitalize(phrase) {
	if (!phrase) return null;
	//remove all multiple spaces
	phrase = phrase.trim();
	phrase = phrase.replace(/\s{2,}/g, ' ');
	return phrase
		.split(' ')
		.map(function (string) {
			string = string.toLowerCase();
			return string[0].toUpperCase() + string.slice(1);
		})
		.join(' ');
}

export default {
	buildTitleStartsWith: function (phrase, show_separator, capitalize) {
		var title = defaults();
		if (phrase) {
			title = phrase + (show_separator === false ? ' ' : separator) + app_name;
		}
		return capitalize === true ? Capitalize(title) : title;
	},

	buildTitleEndsWith: function (phrase, show_separator, capitalize) {
		var title = defaults();
		if (phrase) {
			title = app_name + (show_separator === false ? ' ' : separator) + phrase;
		}
		return capitalize === true ? Capitalize(title) : title;
	},

	buildDefaultPageTitle: function ({ profile, page }) {
		const company_name = profile?.partner_login_company_name || profile?.company?.name;

		let phrase = company_name ? `for ${company_name}` : '';

		if (page) {
			phrase += `${separator}${page}`;
		}

		return this.buildTitleEndsWith(phrase, false);
	},

	defaults: defaults,
};
