/**
 * Returns the Popup class.
 *
 * Unfortunately, the Popup class can only be defined after
 * google.maps.OverlayView is defined, when the Maps API is loaded.
 * This function should be called by initMap.
 */

import * as Sentry from '@sentry/react';
class DestinationMarker {
	getPopupClass() {
		/**
		 * A customized popup on the map.
		 * @param {!google.maps.LatLng} position
		 * @param {!Element} content The bubble div.
		 * @constructor
		 * @extends {google.maps.OverlayView}
		 */
		function Marker(position, content) {
			this.position = position;

			content.classList.add('destination-marker');

			// This zero-height div is positioned at the bottom of the bubble.
			let bubbleAnchor = document.createElement('div');
			bubbleAnchor.classList.add('destination-marker-anchor');
			bubbleAnchor.appendChild(content);

			// This zero-height div is positioned at the bottom of the tip.
			this.containerDiv = document.createElement('div');
			this.containerDiv.classList.add('destination-marker-container');
			this.containerDiv.appendChild(bubbleAnchor);

			// Optionally stop clicks, etc., from bubbling up to the map.
			window.google.maps.OverlayView.preventMapHitsFrom(this.containerDiv);
		}

		if (window.google) {
			// ES5 magic to extend google.maps.OverlayView.
			Marker.prototype = Object.create(window.google.maps.OverlayView.prototype);

			/** Called when the popup is added to the map. */
			Marker.prototype.onAdd = function () {
				this.getPanes().floatPane.appendChild(this.containerDiv);
			};

			/** Called when the popup is removed from the map. */
			Marker.prototype.onRemove = function () {
				if (this.containerDiv.parentElement) {
					try {
						this.containerDiv.parentElement.removeChild(this.containerDiv);
					} catch (err) {
						console.error(err);
						Sentry.captureException(err);
					}
				}
			};

			/** Called each frame when the popup needs to draw itself. */
			Marker.prototype.draw = function () {
				let divPosition = this.getProjection().fromLatLngToDivPixel(this.position);

				// Hide the popup when it is far out of view.
				let display = Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ? 'block' : 'none';

				if (display === 'block') {
					this.containerDiv.style.left = divPosition.x + 'px';
					this.containerDiv.style.top = divPosition.y + 'px';
				}
				if (this.containerDiv.style.display !== display) {
					this.containerDiv.style.display = display;
				}
			};
		}

		return Marker;
	}

	getStyle() {
		return {
			'& .destination-marker': {
				position: 'absolute',
				top: 0,
				left: 0,
				transform: 'translate(-50%, -100%)',
			},
			'& .destination-marker-anchor': {
				position: 'absolute',
				width: '100%',
				bottom: 8,
				left: 0,
			},
			'& .destination-marker-container': {
				cursor: 'auto',
				height: 0,
				position: 'absolute',
				width: 200,
			},
		};
	}
}

export default new DestinationMarker();
