const AdvancedIcon = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M5.4541 19.7273V14"
				stroke="var(--desktop-bookings-button)"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M5.4541 10.7273V5"
				stroke="var(--desktop-bookings-button)"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M12 19.7273V12.3636"
				stroke="var(--desktop-bookings-button)"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path d="M12 9.09091V5" stroke="var(--desktop-bookings-button)" strokeWidth="1.5" strokeLinecap="round" />
			<path
				d="M18.5459 19.7273V15.6364"
				stroke="var(--desktop-bookings-button)"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M18.5459 12.3636V5"
				stroke="var(--desktop-bookings-button)"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path d="M3 14H7.90909" stroke="var(--desktop-bookings-button)" strokeWidth="1.5" strokeLinecap="round" />
			<path
				d="M9.5459 9.09091H14.455"
				stroke="var(--desktop-bookings-button)"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M16.0908 15.6364H20.9999"
				stroke="var(--desktop-bookings-button)"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
		</svg>
	);
};

export default AdvancedIcon;
