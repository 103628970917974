export const getCatering = (heuristicOptions) => {
  switch (true) {
    case heuristicOptions.isAllInclusive:
      return 'All Inclusive';

    case heuristicOptions.isFullBoard:
      return 'Full Board';

    case heuristicOptions.isHalfBoard:
      return 'Half Board';

    case heuristicOptions.includesBreakfast:
      return 'Breakfast Included';

    default:
      return null;
  }
};
