import React from 'react';
import createClass from 'create-react-class';
// import Icon from 'react-evil-icons';
import Icon from '../../ReactEvilIcon';
import { IconButton, TextField, Tooltip } from '@mui/material';
import ManagementActions from '../../../actions/ManagementActions';
import ManagementConstants from '../../../constants/ManagementConstants';
import ContentLoader from 'react-content-loader';
import { withRouter } from '../../../utils/withRouter';

const enter_key = 'Enter';
const escape_key_code = 27;

const NameBar = createClass({
	displayName: 'NameBar',

	componentDidUpdate(prevProps) {
		const { name } = this.state;
		const { trip_request } = this.props;

		if (!name && !prevProps.trip_request && prevProps.trip_request !== trip_request) {
			this.setState({
				name: this.props.trip_request?.name || '',
			});
		}

		if (
			prevProps.trip_request &&
			this.props.trip_request &&
			prevProps.trip_request.id === this.props.trip_request.id &&
			prevProps.trip_request.name !== this.props.trip_request.name
		) {
			ManagementActions.editTripRequestMeta(4);
		}
	},
	getInitialState: function () {
		let { trip_request } = this.props;
		return {
			hovering: false,
			editing: false,
			name: (trip_request && trip_request.name) || '',
		};
	},

	handleNameOnMouseEnter: function () {
		this.setState({
			hovering: true,
		});
	},

	handleNameOnMouseLeave: function () {
		this.setState({
			hovering: false,
		});
	},

	handleEditOnClick: function () {
		this.setState({
			name: this.props.trip_request && this.props.trip_request.name,
			editing: true,
		});
	},

	onCloseClick: function () {
		const { navigate, closeMode } = this.props;

		switch (closeMode) {
			case 'trip':
				return navigate(
					'/trip/' + this.props.trip_id || (this.props.trip_request && this.props.trip_request.id),
				);
			default:
				return navigate('/trips');
		}
	},

	handleNameOnChange: function (e) {
		let name = e.target.value;
		if (name.length <= 48) {
			this.setState({
				name: e.target.value,
			});
		}
	},

	saveName: function () {
		let { trip_request, onNameChange } = this.props;
		let { name } = this.state;
		name = name.trim();

		if (name) {
			if (onNameChange) {
				onNameChange(name.trim());
			} else {
				ManagementActions.updateName(name.trim());
			}

			this.setState({
				name: name,
			});
		} else {
			this.setState({
				name: (trip_request && trip_request.name) || '',
			});
		}

		this.setState({
			editing: false,
		});
	},

	handleEditOnBlur: function (e) {
		this.saveName();
	},

	handleOnKeyUp: function (e) {
		if (e.key === enter_key) {
			this.saveName();
		} else if (e.keyCode === escape_key_code) {
			let { trip_request } = this.props;

			this.setState({
				editing: false,
				name: (trip_request && trip_request.name) || '',
			});
		}
	},

	moveCaretAtEnd: function (e) {
		var temp_value = e.target.value;
		e.target.value = '';
		e.target.value = temp_value;
	},

	render: function () {
		let { trip_request, busy, viewOnly, can_edit } = this.props;
		let { name } = this.state;

		let editable = true;
		let style = {
			width: name.length * 11.25,
		};

		if (this.props.name) {
			editable = false;
			name = this.props.name;
		} else {
			name = trip_request && trip_request.name;
		}

		if (viewOnly || busy) {
			editable = false;
		}

		let name_props = editable
			? { onMouseEnter: this.handleNameOnMouseEnter, onMouseLeave: this.handleNameOnMouseLeave }
			: {};

		let edit_props = editable ? { onClick: this.handleEditOnClick } : {};

		let view_classes = 'view-name';
		if (editable) view_classes += ' editable';

		let lock_tooltip = 'This trip cannot be edited because it was already ';
		if (trip_request) {
			if (trip_request.status === ManagementConstants.TRIP_STATUS.DISCARDED) {
				lock_tooltip += 'discarded';
			} else if (trip_request.status === ManagementConstants.TRIP_STATUS.CANCELLED) {
				lock_tooltip += 'cancelled';
			} else {
				lock_tooltip += 'submitted';
			}
		}

		return (
			<div className="name-bar">
				<div className="name" {...name_props}>
					{!busy && can_edit === false ? (
						<a href="#!" className="action lock">
							{/*<Icon name="ei-lock" size="m" className="evil-icon"/>*/}
							<Tooltip title={lock_tooltip} arrow>
								<IconButton className="lock-button" aria-label={lock_tooltip} size="large">
									<div className="fa fa-lock" />
								</IconButton>
							</Tooltip>
							{/*<IconButton className='lock-button' tooltip={"Trip is Locked"} iconClassName="ei-lock"/>*/}
						</a>
					) : null}
					{busy ? (
						<span className="view-name">
							<ContentLoader speed={1} width={300} height={10}>
								<rect x="0" y="0" height="10" rx="4" ry="4" width="300" />
							</ContentLoader>
						</span>
					) : this.state.editing && can_edit ? (
						<TextField
							className="edit-name"
							placeholder="e.g. London Annual Conference"
							label="Trip Name"
							fullWidth={true}
							value={this.state.name}
							onChange={this.handleNameOnChange}
							autoFocus
							onFocus={this.moveCaretAtEnd}
							onBlur={this.handleEditOnBlur}
							onKeyUp={this.handleOnKeyUp}
						/>
					) : (
						<span className={view_classes} {...edit_props}>
							{name}
						</span>
					)}
					{editable && this.state.hovering && !this.state.editing && can_edit ? (
						<a href="#!" className="action edit" {...edit_props}>
							<Icon name="ei-pencil" size="m" className="evil-icon" />
						</a>
					) : null}
				</div>
				{
					<a href="#!" className="action close-trip" onClick={this.onCloseClick}>
						<Icon name="ei-close" size="m" className="evil-icon" />
					</a>
				}
			</div>
		);
	},
});

export default withRouter(NameBar);
