import { Button, styled } from '@mui/material';

const BaseButton = styled(Button)(() => ({
	borderRadius: 4,
	fontWeight: 700,
	textTransform: 'none',
	padding: '8px 16px',
}));

export const ArbiPrimaryButton = styled(BaseButton)(({ theme }) => ({
	backgroundColor: theme.palette.blue.main,
	color: theme.palette.white,
	'&.Mui-disabled': {
		opacity: 0.5,
		color: theme.palette.white,
	},
	'&:hover': {
		backgroundColor: theme.palette.blue.mainHover,
	},
}));

export const ArbiCancelButton = styled(BaseButton)(({ theme }) => ({
	backgroundColor: theme.palette.gray.gray5,
	color: theme.palette.gray.main,
	'&:hover': {
		backgroundColor: theme.palette.gray.mainHover,
	},
}));
