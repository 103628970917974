import _ from 'lodash';
import keyMirror from 'keymirror';

const AMENITIES = {
	BREAKFAST_INCLUDED: 'BREAKFAST_INCLUDED',
	FREE_WIFI: 'FREE_WIFI',
	// TWIN_BEDS: 'TWIN_BEDS',
	PARKING: 'PARKING',
	AIRPORT_SHUTTLE: 'AIRPORT_SHUTTLE',
	FITNESS_CENTRE: 'FITNESS_CENTRE',
	// FREE_CANCELLATION: 'FREE_CANCELLATION',
	RESTAURANT: 'RESTAURANT',
	MEETING_ROOM: 'MEETING_ROOM',
	SWIMMING_POOL: 'SWIMMING_POOL',
	AIR_CONDITIONING: 'AIR_CONDITIONING',
	ELECTRIC_VEHICLE_CHARGING_STATION: 'ELECTRIC_VEHICLE_CHARGING_STATION',
};

const FILTER_CONSTANTS = {
	MAXIMUM_FILTERED_DISTANCE_IN_KM: 50,
	FILTER_METHOD: {
		RESET_ALL: 'Reset All',
		HOTEL: 'Hotel Name',
		DISTANCE: 'Max Distance from Destination',
		PRICE: 'Max Price Per Night',
		STARS: 'Stars',
		STARS_ONLY: 'Stars Only',
		REVIEW: 'Review',
		REVIEW_ONLY: 'Review Only',
		SANITIZATION_STANDARDS: 'Sanitization Standards',

		MARGIN: 'Margin',
		MARGIN_ONLY: 'Margin Only',
		RESET_MARGINS_FILTER: 'Reset Margins Filter',

		AMENITIES: 'Amenities',
		AMENITY_ONLY: 'Amenity Only',
		RESET_AMENITIES_FILTER: 'Reset Amenities Filter',

		TRAVEL_POLICY: 'Travel Policy',
		AVAILABILITY: 'Availability',
		PREFERRED_HOTELS: 'Preferred Hotels',

		CATEGORIES: 'Categories',
		CATEGORY_ONLY: 'Category Only',
		RESET_CATEGORIES_FILTER: 'Reset Categories Filter',

		NEIGHBORHOODS: 'Neighborhoods',
		NEIGHBORHOOD_ONLY: 'Neighborhood Only',
		RESET_NEIGHBORHOODS_FILTER: 'Reset Neighborhoods Filter',

		DEAL_BREAKFAST_ONLY: 'Deal Breakfast Only',
		DEAL_HALF_BOARD_ONLY: 'Deal Breakfast Only',
		DEAL_FULL_BOARD_OR_ALL_INCLUSIVE_ONLY: 'Deal Full Board or All Inclusive Only',
		DEAL_POST_PAY_ONLY: 'Deal Post Pay Only',
		DEAL_NEGOTIATED_RATE_ONLY: 'Deal Negotiated Rate Only',
		DEAL_CANCELABLE_ONLY: 'Deal Cancelable Only',
		DEAL_TRAVEL_POLICY_ONLY: 'Deal Travel Policy Only',
		DEAL_RESET_FILTER_ALL: 'Deal Reset All Filters',
	},

	BOOKINGS_FILTER_METHOD: {
		DESTINATION_HOTEL_NAME: 'Destination or Hotel Name',
		DESTINATION: 'Destination',
		AGENT_NAME: 'Agent Name',
		COMPANY_NAME: 'Company Name',
		TRAVELER_NAME: 'Traveler Name',
		HOTEL_NAME: 'Hotel Name',
		AFTER_DATE: 'After Date',
		BEFORE_DATE: 'Before Date',
		SHOW_CONFIRMED: 'Show Confirmed',
		TRAVEL_BOOSTER_NOT_SYNCED: 'Travel Booster Not Synced',
	},

	BOOKING_REQUESTS_FILTER_METHOD: {
		DESTINATION_HOTEL_NAME: 'Requests by Destination or Hotel Name',
		AGENT_NAME: 'Requests by Agent Name',
		COMPANY_NAME: 'Requests by Company Name',
		TRAVELER_NAME: 'Requests by Traveler Name',
		AFTER_DATE: 'Requests by After Date',
		BEFORE_DATE: 'Requests by Before Date',
	},

	REVIEW_LEVELS: {
		EXCELLENT: 'Excellent',
		VERY_GOOD: 'Very Good',
		GOOD: 'Good',
		FAIR: 'Fair',
		POOR: 'Poor',

		// UNKNOWN: "Unknown"
		// NOT_AVAILABLE: "Not Available"
		UNRATED: 'No Rating',
	},

	AMENITIES,

	AMENITY_FILTERS_TITLES: {
		[AMENITIES.BREAKFAST_INCLUDED]: 'Breakfast included',
		[AMENITIES.FREE_WIFI]: 'Free WiFi',
		// [FLAGS.TWIN_BEDS]: 'Twin beds',
		[AMENITIES.PARKING]: 'Parking',
		[AMENITIES.AIRPORT_SHUTTLE]: 'Airport shuttle',
		[AMENITIES.FITNESS_CENTRE]: 'Fitness centre',
		// [FLAGS.FREE_CANCELLATION]: 'Free cancellation',
		[AMENITIES.RESTAURANT]: 'Restaurant',
		[AMENITIES.MEETING_ROOM]: 'Meeting room',
		[AMENITIES.SWIMMING_POOL]: 'Swimming pool',
		[AMENITIES.AIR_CONDITIONING]: 'Air conditioning',
		[AMENITIES.ELECTRIC_VEHICLE_CHARGING_STATION]: 'Charging station',
	},

	//     <!>  PAY - ATTENTION <!>
	//
	// TODO: THIS SECTION IS IMPORTANT TO MAKE THE FILTER RUN PROPERLY - IN CHECK ALL CASES
	//
	AMENITY_FILTER_FLAGS: [
		AMENITIES.FREE_WIFI,
		AMENITIES.PARKING,
		AMENITIES.AIRPORT_SHUTTLE,
		AMENITIES.FITNESS_CENTRE,
		AMENITIES.RESTAURANT,
		AMENITIES.MEETING_ROOM,
		AMENITIES.SWIMMING_POOL,
		AMENITIES.AIR_CONDITIONING,
		AMENITIES.ELECTRIC_VEHICLE_CHARGING_STATION,
	],

	CATEGORIES: {
		HOTEL: 'Hotel',
		APARTMENT: 'Apartment',
		// APART_HOTEL: 'Apart-hotel',
		// HOSTEL: 'Hostel',
		// GUEST_HOUSE: 'Guest House',
		OTHER: 'Other',
		// NA: 'N/A'
	},

	MARGINS: {
		ABSOLUTE: 'Absolute',
		PERCENTAGE: 'Percentage',
		SCORE: 'Score',
		RECOMMENDED: 'Recommended',
		TEN_PERCENT: '10%',
		TOP_FIFTY: 'Top 50',
	},

	REGIONS: {
		HIGH_LEVEL_REGION: 'High Level Region',
		MULTI_CITY_VICINITY: 'Multi City Vicinity',
		CITY: 'City',
		NEIGHBORHOOD: 'Neighborhood',
	},

	DEFAULT_IN_POLICY_FILTER_VALUE: false,
	DEFAULT_AVAILABILITY_FILTER_VALUE: true,
	DEFAULT_PREFERRED_HOTELS_FILTER_VALUE: false,
	DEFAULT_SANITIZATION_STANDARDS_FILTER_VALUE: false,
};

// Define action constants
export default _.extend(
	{},
	FILTER_CONSTANTS,
	keyMirror({
		RECEIVE_FILTERS_DATA: null, // After postback
		RECEIVE_MAP_BOX_DATA: null,
		RECEIVE_PRICE_FILTER_DATA: null,
		RECEIVE_BUSINESS_TRAVELERS_DATA: null,
		RECEIVE_HOTEL_CLASS_DATA: null,
		RECEIVE_REVIEW_SCORE_DATA: null,

		UPDATE_CURRENCIES: null,

		RECEIVE_STARS_FILTER_DATA: null,
		RECEIVE_ARBITRIP_SCORE_FILTER_DATA: null,
		RECEIVE_AMENITIES_FILTER_DATA: null,
		RECEIVE_DEBUG_FILTER_DATA: null,
		RESET_HOTEL_DEALS_FILTERS: null,
		RECEIVE_DEALS_BREAKFAST_ONLY_FILTER_DATA: null,
		RECEIVE_DEALS_HALF_BOARD_ONLY_FILTER_DATA: null,
		RECEIVE_DEALS_ALL_INCLUSIVE_ONLY_FILTER_DATA: null,
		RECEIVE_DEALS_FREE_CANCEL_ONLY_FILTER_DATA: null,
		RECEIVE_DEALS_IN_POLICY_ONLY_FILTER_DATA: null,
		RECEIVE_DEALS_POST_PAY_FILTER_DATA: null,
		RECEIVE_DEALS_NEGOTIATED_RATE_FILTER_DATA: null,

		FILTER_BY_DISTANCE: null,
		FILTER_BY_NAME_CONTAINS: null,
		FILTER_BY_DEBUG_CONTAINS: null,
		FILTER_BY_PRICE: null,
		FILTER_BY_TOP_X: null,
		FILTER_BY_STARS: null,
		FILTER_BY_STARS_ONLY: null,
		RESET_STARS_FILTER: null,
		FILTER_BY_REVIEW: null,
		FILTER_BY_REVIEW_ONLY: null,
		RESET_REVIEW_FILTER: null,
		FILTER_BY_SANITIZATION_STANDARDS: null,
		FILTER_BY_MARGIN: null,
		FILTER_BY_MARGIN_ONLY: null,
		RESET_MARGINS_FILTER: null,
		FILTER_BY_AMENITY: null,
		FILTER_BY_AMENITY_ONLY: null,
		RESET_AMENITIES_FILTER: null,
		FILTER_BY_PREFERRED_HOTELS: null,

		FILTER_BY_CATEGORIES: null,
		FILTER_BY_CATEGORIES_ONLY: null,
		RESET_CATEGORIES_FILTER: null,

		FILTER_BY_NEIGHBORHOODS: null,
		FILTER_BY_NEIGHBORHOODS_ONLY: null,
		RESET_NEIGHBORHOODS_FILTER: null,

		FILTER_BY_IN_POLICY: null,
		FILTER_BY_AVAILABILITY: null,
		FILTER_BY_ALL: null,

		CLEAR_FILTERS: null,
		CLEAR_FILTERS_AND_APPLY: null,
	}),
);
