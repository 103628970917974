const Sessions = require('./sessions');
const Views = require('./views');
const Interactions = require('./interactions');
const Responses = require('./responses');
const Points = require('./points');
const User = require('./user');
const GlobalProperties = require('../global_properties_attached_to_events');

module.exports = {
  sessions: Sessions,
  views: Views,
  interactions: Interactions,
  responses: Responses,
  points: Points,
  user: User,
  global_properties: GlobalProperties
};
