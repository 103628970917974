import { styled } from '@mui/material';

const draft_status_regex = new RegExp('draft', 'i');

const DraftBadge = styled('div')(({ theme }) => ({
	width: 52,
	height: 33,
	backgroundColor: theme.palette.gray.gray5,
	border: `1px solid ${theme.palette.gray.gray3}`,
	borderRadius: 4,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	color: theme.palette.gray.main,
	fontSize: 15,
}));

const BadgeComponent = ({ trip }) => {
	const isDraft = draft_status_regex.test(trip.status);

	return isDraft && <DraftBadge>Draft</DraftBadge>;
};

export default BadgeComponent;
