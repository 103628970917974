import Config from '../utils/Config';
const _ = require('lodash');

const PATHS = {
  DICTIONARY: 'dictionary',
  MAP: 'map',
  COMPRESS: 'compress',
  OPTIMIZE: 'optimize'
};

function buildFullURL(path) {
  return Config.rms_url + path;
}

export default _.mapValues(PATHS, buildFullURL);