import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import Analytics from 'arbitrip-common/client/analytics';
import ManagementStore from '@/stores/ManagementStore';
import ProfileStore from '@/stores/ProfileStore';
import RouterWrapper from '@/utils/RouterWrapper';

function getManagementPageState() {
	const profile = ProfileStore.getProfile();

	return {
		profile,
		previewed_trip: ManagementStore.getPreviewedTrip(),
		management_enabled: profile.company.settings.management_enabled,
	};
}

const ManagementPage = ({ main, sidebar }) => {
	const navigate = useNavigate();

	const [managementState, setManagementState] = useState(getManagementPageState());

	useEffect(() => {
		const { profile, management_enabled } = managementState;

		if (!management_enabled) {
			RouterWrapper.goToSearchPage(navigate);
			return;
		}

		ManagementStore.addChangeListener(onChange);
		window.scrollTo(0, 0);

		Analytics.actions.views.managementPage(profile);

		return () => {
			ManagementStore.removeChangeListener(onChange);
		};
	}, []);

	const onChange = () => {
		setManagementState(getManagementPageState());
	};

	if (!managementState.management_enabled) {
		return null;
	}

	return (
		<div className="management-page">
			<div className="layout">
				<div className="content">{main}</div>
				{sidebar && <div className="sidebar">{sidebar}</div>}
			</div>
		</div>
	);
};

export default ManagementPage;
