import { Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Fade } from '@mui/material';

import TripTableRow from './TripTableRow';

const TripTable = ({ trips, onCellClick, onRowHover, hoveredRow, showCurrentLocation }) => (
	<Fade in timeout={500}>
		<TableContainer>
			<Table className="trip-table">
				<TableHead>
					<TableRow>
						<TableCell>Traveler</TableCell>
						<TableCell>Trip Name</TableCell>
						<TableCell></TableCell>
						<TableCell>{showCurrentLocation ? 'Current Location' : 'Destination'}</TableCell>
						<TableCell>Start Date</TableCell>
					</TableRow>
				</TableHead>
				<TableBody className="trip-table-body">
					{trips.map((trip, index) => (
						<TripTableRow
							key={trip.id}
							trip={trip}
							onRowClick={() => onCellClick(index)}
							onRowHover={() => onRowHover(index)}
							isHovered={hoveredRow === index}
							showCurrentLocation={showCurrentLocation}
						/>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	</Fade>
);

export default TripTable;
