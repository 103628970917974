import withStyles from '@mui/styles/withStyles';

import { Select, MenuItem, FormControl } from '@mui/material';

import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import SEARCH_CONSTANTS from 'arbitrip-common/general/constants/search-constants';
import { Validator } from 'arbitrip-common/client/utils';

const styles = {
	root: {
		width: '10em',
	},
};

class SearchDataChildSelect extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			age: !this.props.age && this.props.age !== 0 ? SEARCH_CONSTANTS.DEFAULT_CHILD_AGE : this.props.age,
		};

		this.handleSelectChange = this.handleSelectChange.bind(this);
	}

	handleSelectChange(e, data) {
		let { childNumber, updateSingleChildrenAge } = this.props;

		const new_age = e.target.value;
		this.setState({ age: new_age });

		if (_.isFunction(updateSingleChildrenAge)) {
			updateSingleChildrenAge(childNumber, new_age);
		}
	}

	getYearsOldText(age) {
		return age === 0 ? 'Under 1 year old' : age === 1 ? '1 year old' : `${age} years old`;
	}

	render() {
		let { age } = this.state;
		let { classes, showChildrenAgesError } = this.props;
		const array_size = SEARCH_CONSTANTS.MAX.CHILD_AGE + 1;
		const ages = Array.from(Array(array_size).keys());

		return (
			<FormControl className={classes.root} size="small">
				<Select
					value={age}
					onChange={this.handleSelectChange}
					displayEmpty
					className={showChildrenAgesError && !Validator.validateAge(age) ? 'Mui-error' : ''}
					aria-label="Select child age"
				>
					<MenuItem value={''} hidden disabled>
						Age
					</MenuItem>
					{ages.map((a) => (
						<MenuItem value={a} key={a}>
							{this.getYearsOldText(a)}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		);
	}
}

SearchDataChildSelect.propTypes = {
	classes: PropTypes.object.isRequired,
	childNumber: PropTypes.number.isRequired,
	age: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	updateSingleChildrenAge: PropTypes.func.isRequired,
	showChildrenAgesError: PropTypes.bool.isRequired,
};

export default withStyles(styles)(SearchDataChildSelect);
