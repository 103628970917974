const developmentCompanyIds = {
    "5c60469aff5cd815748df923": "HitechZone",
    "6693dc362a605ba17f95b265": "HitechZone",
    "6673303c5e9606512f6b47de": "RailNinja",
    "667bbca297a422cbd5abfa9b": "LetStay"
}

const productionCompanyIds = {
    "6704e871022c6aec954dc5a3": "HitechZone",
    "670b89f5eff567d71ceff4fb": "RailNinja",
    "66f2bf384d1a7164b76fb307": "LetStay"
}

module.exports = {
    ...developmentCompanyIds,
    ...productionCompanyIds,
}