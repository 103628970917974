const moment = require('moment');

class CancelPolicy {
    constructor(cancelPolicy) {
        this.price = cancelPolicy.cheapopoPrice;
        this.currency = cancelPolicy.outputCurrency;
        this.from = moment(cancelPolicy.earlierDate).toDate();
        this.to = moment(cancelPolicy.laterDate).toDate();
    }
}

module.exports = CancelPolicy;
