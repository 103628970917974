import React from 'react';
import createClass from 'create-react-class';

import FiltersActions from '../../../../actions/FiltersActions';

import FiltersConstants from '../../../../constants/FiltersConstants';
import Analytics from 'arbitrip-common/client/analytics';

import FilterCheckboxWithLabel from './components/FilterCheckboxWithLabel.react';

const AvailabilityFilter = createClass({
	displayName: 'AvailabilityFilter',

	handleCheckboxChange: function (event, isInputChecked) {
		const { profile } = this.props;

		event.persist();
		FiltersActions.filterByAvailability(isInputChecked);
		setTimeout(function () {
			FiltersActions.filterByAvailability(isInputChecked);
		}, 10);

		const filter_value = isInputChecked ? 'Show Only In Availability' : 'Show All';
		Analytics.actions.interactions.filteredResults(
			profile,
			FiltersConstants.FILTER_METHOD.AVAILABILITY,
			filter_value,
		);
	},

	render() {
		return (
			<div className="filter disabled">
				<label className="filter-title" htmlFor="availability-filter">
					Availability
				</label>
				<div name="availability-filter" className="check-list" aria-label="Availability filter">
					<div className="checkbox-container">
						<FilterCheckboxWithLabel
							checked={this.props.filter}
							label="Hide no-availability hotels"
							onChange={(e, c) => this.handleCheckboxChange(e, c)}
						/>
					</div>
				</div>
			</div>
		);
	},
});

export default AvailabilityFilter;
