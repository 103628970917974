import _ from 'lodash';
import ApiURLs from '../../../../utils/ApiURLs';

function getCommissionPercentage(booking) {
	const total_client_price = booking.deal?.cheapopoPricing?.cheapopoTotalPrice;
	const total_commission =
		booking?.deal?.cheapopoPricing?.agentPricing?.absolute_margin_total ||
		booking?.deal?.cheapopoPricing?.commissionTotal ||
		0;

	const commission_percentage =
		booking?.deal?.cheapopoPricing?.agentPricing?.margin_percentage ||
		(total_commission / total_client_price) * 100;

	return commission_percentage.toFixed(2);
}

function isPaymentLinkExpirationExists(booking) {
	return !!_.get(booking, 'payment.payment_link.expiration');
}

function isTokenExpired(booking) {
	const token_expiration_date = _.get(booking, 'payment.payment_link.expiration');

	if (!token_expiration_date) {
		return true;
	}

	const expiration_time = new Date(token_expiration_date);
	const current_time = new Date();

	return current_time > expiration_time;
}

function getPaymentLink(booking) {
	const token = _.get(booking, 'payment.payment_link.token');

	if (!token) {
		console.log('Token is not available');
		return '';
	}

	return ApiURLs.GET_PAYMENT_LINK.replace(':token', token);
}

function getTimeFormatted(date) {
	return date.format('MMM D, YYYY hh:mm A') + ' (GMT)';
}

export default {
	getCommissionPercentage,
	isPaymentLinkExpirationExists,
	isTokenExpired,
	getPaymentLink,
	getTimeFormatted,
};
