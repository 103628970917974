const { DealUtils } = require('../../entities');
const { getDateInFormat, getDateInIso } = require('../../utils/Dates');

const getDealObject = (deal) => {
    if (!deal) {
        return;
    }

    const supplier = DealUtils.dealSupplierExtractor(deal).supplier;

    return {
        ...(deal.id && { deal_id: deal.id }),
        ...(deal.breakfast && { breakfast: deal.breakfast }),
        ...(deal.details && deal.details.board_code && { board_code: deal.details.board_code }),
        ...(deal.refundable && { refundable: deal.refundable }),
        ...(deal.room_id && { room_id: deal.room_id }),
        ...(deal.pricePerNight && { price_per_night: deal.pricePerNight }),
        ...(deal.totalPrice && { total_price: deal.totalPrice }),
        ...(deal.room && { room_name: deal.room }),
        ...(deal.details && { original_room_name: deal.details.room_description_original }),
        ...(deal.in_policy && { in_policy: deal.in_policy }),
        ...(deal.superseded && { superseded: deal.superseded }),
        ...(deal.currency && { currency: deal.currency }),
        ...(deal.nights && { nights: deal.nights }),
        ...(deal.rooms && { rooms: deal.rooms }),
        ...(deal.guests && { guests: deal.guests }),
        ...(deal.guestsPerRoom && { guests: deal.guestsPerRoom }),
        ...(deal.children_ages && { children_ages: deal.children_ages }),
        ...(deal.details && deal.details.private_travel && { private_travel: deal.details.private_travel }),
        ...(deal.deal_index && { deal_index: deal.deal_index }),
        ...(deal.check_in && { check_in: getDateInFormat(deal.check_in) }),
        ...(deal.check_out && { check_out: getDateInFormat(deal.check_out) }),
        ...(deal.check_in && { check_in_date: getDateInIso(deal.check_in) }),
        ...(deal.check_out && { check_out_date: getDateInIso(deal.check_out) }),
        ...(deal.dca && deal.dca.dealIsFullyRefundableTill && { cancellation_date: getDateInFormat(deal.dca.dealIsFullyRefundableTill) }),
        ...(supplier && { supplier }),
        ...(deal.dca && { mapped_rate: !!deal.dca.room_mapped})
    };
};

module.exports = {
    getDealObject
};