import classNames from 'classnames';

const NoReview = () => (
	<div className="review no-review">
		<span className="no-review-line">Score</span>
		<span className="no-review-line">Not</span>
		<span className="no-review-line">Available</span>
	</div>
);

const ReviewPresent = ({ score, votes, description, onReviewScoreClick, review }) => {
	const reviewClasses = classNames('review', 'flex', {
		'no-review': !review?.ty_id,
	});

	const handleClick = review?.ty_id ? onReviewScoreClick : null;

	return (
		<div className={reviewClasses} onClick={handleClick}>
			<div className="score-box flex-center">
				<div className="score">{score}</div>
			</div>
			<div className="flex flex-column justify-center align-flex-start">
				<div className="votes">{`${votes.toLocaleString()} ${votes === 1 ? 'Review' : 'Reviews'}`}</div>
				<div className="description">{description}</div>
			</div>
		</div>
	);
};

const HotelReview = ({ hotel: { hasReview, review = {} }, onReviewScoreClick }) => {
	return hasReview ? (
		<ReviewPresent {...review} onReviewScoreClick={onReviewScoreClick} review={review} />
	) : (
		<NoReview />
	);
};

export default HotelReview;
