const EmptyStateSearchesDestination = ({ title, description }) => {
	return (
		<div className="empty-state-container">
			<img
				className="empty-state-image"
				src="./img/recent_searches/recent_searches_empty.svg"
				alt="no results"
			></img>
			<div className="empty-state-text">
				<span className="empty-state-title"> {title} </span>
				<br />
				{description}
			</div>
		</div>
	);
};

export default EmptyStateSearchesDestination;
