import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import MapBox from './filters/map-box/MapBox.react';
import FiltersComponent from './FiltersComponent.react';
import TravelPolicyComponent from './TravelPolicyComponent.react';
import TravelPolicyLoader from './TravelPolicyLoader.react';
import PrivateTravelComponent from './PrivateTravelComponent.react';
import SortDropdownComponent from './SortDropdownComponent.react';
import ResultsComponent from './ResultsComponent.react';
import ResultsStore from '../../../stores/ResultsStore';
import SearchStore from '../../../stores/SearchStore';
import SearchFull from '../../search/SearchFull.react';
import MapStore from '../../../stores/MapStore';
import ProfileStore from '../../../stores/ProfileStore';
import { VARIANT } from '../../search/constants';

import ScrollToTop from 'react-scroll-up';

import Analytics from 'arbitrip-common/client/analytics';

import ExpiredResultsComponent from '../../general/expired-results/ExpiredResultsComponent.react';

import SearchProgress from './SearchProgress.react';
import * as Sentry from '@sentry/react';

function getResultsPageState() {
	return {
		initialFullMapMode: MapStore.getFullMapMode(),
		profile: ProfileStore.getProfile(),
		search_terms: _.get(ResultsStore.getCurrentSearchSession(), 'search_terms'),
		breakfast_only: ResultsStore.isBreakfastOnly(),
		search_terms_valid: SearchStore.isValid(),
		private_travel: SearchStore.isPrivateTravel(),
		search_results: ResultsStore.getSearchResults(),
		search_session_created_timestamp: ResultsStore.getSearchSessionCreatedTimestamp(),
		travel_policy: ResultsStore.getTravelPolicy(),
		filters: ResultsStore.getFilters(),
		regions: ResultsStore.getRegions(),
		is_show_hotel_loader: SearchStore.isShowHotelLoader(),
	};
}

class ResultsPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			...getResultsPageState(),
			mapBoxOpen: false,
		};

		this.handleMapBoxState = this.handleMapBoxState.bind(this);
		this._onChange = this._onChange.bind(this);
		this._onProfileChange = this._onProfileChange.bind(this);
		this._onSearchChange = this._onSearchChange.bind(this);
	}

	componentDidMount() {
		const { profile } = this.state;

		ProfileStore.addChangeListener(this._onProfileChange);
		ResultsStore.addChangeListener(this._onChange);
		SearchStore.addChangeListener(this._onSearchChange);

		Analytics.actions.views.resultsPage(profile);
	}

	componentWillUnmount() {
		SearchStore.removeChangeListener(this._onSearchChange);
		ProfileStore.removeChangeListener(this._onProfileChange);
		ResultsStore.removeChangeListener(this._onChange);
		// AppGridStore.removeChangeListener(this._onChange);
	}

	handleMapBoxState(bool) {
		this.setState({ mapBoxOpen: bool });
	}

	render() {
		const {
			mapBoxOpen,
			profile,
			search_session_created_timestamp,
			travel_policy,
			filters,
			regions,
			search_terms,
			is_show_hotel_loader,
		} = this.state;

		const scrollToTopStyle = {
			position: 'fixed',
			bottom: 0, //50,
			right: 'auto', //30,
			cursor: 'pointer',
			transitionDuration: '0.2s',
			transitionTimingFunction: 'linear',
			transitionDelay: '0s',
		};

		const searchResultsStyle = this.state.initialFullMapMode ? { paddingTop: '512px' } : {}; // paddingTop: '0' };

		const TRAVEL_POLICY =
			travel_policy || profile.agent ? (
				<TravelPolicyComponent profile={profile} travelPolicy={travel_policy} />
			) : (
				<TravelPolicyLoader />
			);

		return (
			<React.Fragment>
				<div className="results-progress">
					<SearchProgress
						searchSessionCreatedTimestamp={this.state.search_session_created_timestamp}
						results={this.state.search_results}
					/>
				</div>
				<div className="results-page">
					<div className="filters-container">
						<div className="filters">
							<MapBox
								handleMapBoxState={this.handleMapBoxState}
								profile={profile}
								searchTerms={search_terms}
								searchResults={this.state.search_results}
								breakfastOnly={this.state.breakfast_only}
								filters={filters}
								regions={regions}
							/>
						</div>
						{!mapBoxOpen ? (
							<React.Fragment>
								<FiltersComponent
									profile={profile}
									travelPolicy={travel_policy}
									filters={filters}
									search_terms={search_terms}
									showDistanceFilter={true}
								/>
								<ScrollToTop
									showUnder={716}
									topPosition={0}
									easing="easeOutCubic"
									duration={250}
									style={scrollToTopStyle}
								>
									<div className="scroll-to-top">
										<i className="fa fa-chevron-circle-up" aria-hidden="true" title="Go to top" />
									</div>
								</ScrollToTop>
							</React.Fragment>
						) : (
							<div style={{ minHeight: 1000 }} />
						)}
					</div>
					<div className="search-results-container" id="search-results" style={searchResultsStyle}>
						<SearchFull variant={VARIANT.SMALL} />
						<div className="results-actions">
							{ProfileStore.isLeisureExperienceEnabled() ? null : this.state.private_travel ? (
								<PrivateTravelComponent />
							) : profile.travel_policy_enabled || profile.agent ? (
								TRAVEL_POLICY
							) : null}
							<SortDropdownComponent />
						</div>
						<ResultsComponent
							profile={profile}
							searchSessionCreatedTimestamp={search_session_created_timestamp}
							searchTerms={search_terms}
							showHotelLoading={is_show_hotel_loader}
						/>
					</div>

					<ExpiredResultsComponent expiredEntityText="results" />
				</div>
			</React.Fragment>
		);
	}

	_onChange() {
		this.setState(getResultsPageState());
	}

	_onProfileChange() {
		this.setState({ profile: ProfileStore.getProfile() });
	}

	_onSearchChange() {
		this.setState({
			search_terms: _.get(ResultsStore.getCurrentSearchSession(), 'search_terms'),
			search_terms_valid: SearchStore.isValid(),
			is_show_hotel_loader: SearchStore.isShowHotelLoader(),
		});
	}
}

Sentry.withErrorBoundary(ResultsComponent);

ResultsPage.propTypes = {
	route: PropTypes.object,
};

export default ResultsPage;
