import AppDispatcher from '../dispatcher/AppDispatcher';
import RecentSearchesConstants from '../constants/RecentSearchesConstants';
import Ajax from '../utils/Ajax';

var RecentSearchesActions = {
	gotRecentSearches: function (data) {
		AppDispatcher.handleAction({
			actionType: RecentSearchesConstants.RECEIVE_RECENT_SEARCHES_DATA,
			data: data, // ???
		});
	},

	gotRecentSearchTerms: function (data) {
		AppDispatcher.handleAction({
			actionType: RecentSearchesConstants.RECEIVE_RECENT_SEARCHES_TERMS,
			data: data, // ???
		});
	},

	getRecentSearches: function () {
		AppDispatcher.handleAction({
			actionType: RecentSearchesConstants.START_FETCH_RECENT_SEARCHES,
		});

		Ajax.getRecentSearches()
			.done(function (res) {
				AppDispatcher.handleAction({
					actionType: RecentSearchesConstants.RECEIVE_RECENT_SEARCHES_DATA,
					data: res, // ???
				});
			})
			.fail(function (err) {
				console.error('Error while getting recent searches: ', err);
				AppDispatcher.handleAction({
					actionType: RecentSearchesConstants.RECENT_SEARCHES_RETRIEVAL_FAILED,
					data: err, // ???
				});
			});
	},

	removeRecentSearch: function (recent_search) {
		Ajax.removeRecentSearch(recent_search)
			.done(function (res) {
				console.log('Removed recent search: ', recent_search);
				console.log('response: ', res);
				AppDispatcher.handleAction({
					actionType: RecentSearchesConstants.RECENT_SEARCH_REMOVED,
					data: res, // ???
				});
			})
			.fail(function (err) {
				console.error('Error while removing recent search: ', recent_search);
				console.log('response: ', err);
				AppDispatcher.handleAction({
					actionType: RecentSearchesConstants.RECENT_SEARCH_REMOVAL_FAILED,
					data: err, // ???
				});
			});
	},
};

export default RecentSearchesActions;
