import AppDispatcher from '../dispatcher/AppDispatcher';
import TravelAgentConstants from '../constants/TravelAgentConstants';
import Ajax from '../utils/Ajax';

var TravelAgentActions = {
	openTravelAgentSettingsModal: function () {
		AppDispatcher.handleAction({
			actionType: TravelAgentConstants.OPEN_TRAVEL_AGENT_SETTINGS_MODAL,
		});
	},

	closeTravelAgentSettingsModal: function () {
		AppDispatcher.handleAction({
			actionType: TravelAgentConstants.CLOSE_TRAVEL_AGENT_SETTINGS_MODAL,
		});
	},

	sendBookingConfirmation: function (reservation_id) {
		AppDispatcher.handleAction({
			actionType: TravelAgentConstants.SEND_BOOKING_CONFIRMATION,
			data: reservation_id,
		});

		Ajax.sendBookingConfirmation(reservation_id)
			.done(function (res) {
				AppDispatcher.handleAction({
					actionType: TravelAgentConstants.SEND_BOOKING_CONFIRMATION_SUCCESS,
					data: {
						reservation_id,
						response: res,
					},
				});
			})
			.fail(function (err) {
				console.error('ERROR WHILE SENDING BOOKING CONFIRMATION: ', reservation_id, err);
				AppDispatcher.handleAction({
					actionType: TravelAgentConstants.SEND_BOOKING_CONFIRMATION_FAILED,
					data: {
						reservation_id,
						error: err,
					},
				});
			});
	},

	getCompanies: function () {
		AppDispatcher.handleAction({
			actionType: TravelAgentConstants.GET_COMPANIES,
		});

		Ajax.getAgencyCompanies()
			.done(function (res) {
				AppDispatcher.handleAction({
					actionType: TravelAgentConstants.GET_COMPANIES_SUCCESS,
					data: res,
				});
			})
			.fail(function (err) {
				console.error('ERROR WHILE GETTING AGENCY COMPANIES: ', err);
				AppDispatcher.handleAction({
					actionType: TravelAgentConstants.GET_COMPANIES_FAILED,
					data: err,
				});
			});
	},

	getCompanyContract: function (contract_id) {
		AppDispatcher.handleAction({
			actionType: TravelAgentConstants.GET_COMPANY_CONTRACT,
			data: contract_id,
		});

		Ajax.getAgencyCompanyContract(contract_id)
			.done(function (res) {
				AppDispatcher.handleAction({
					actionType: TravelAgentConstants.GET_COMPANY_CONTRACT_SUCCESS,
					data: res,
				});
			})
			.fail(function (err) {
				console.error('ERROR WHILE GETTING AGENCY COMPANY CONTRACT: ', err);
				AppDispatcher.handleAction({
					actionType: TravelAgentConstants.GET_COMPANY_CONTRACT_FAILED,
					data: err,
				});
			});
	},

	updateCompanyContract: function (contract_id, contract) {
		AppDispatcher.handleAction({
			actionType: TravelAgentConstants.UPDATE_COMPANY_CONTRACT,
		});

		// patch for demo
		let c = {
			margin: 1 + (Number(contract.margin) || 0) / 100,
			fixed_fee: Number(contract.fixed_fee) || 0,
		};

		Ajax.updateAgencyCompanyContract(contract_id, c)
			.done(function (res) {
				AppDispatcher.handleAction({
					actionType: TravelAgentConstants.UPDATE_COMPANY_CONTRACT_SUCCESS,
					data: {
						contract,
						res,
					},
				});
			})
			.fail(function (err) {
				console.error('ERROR WHILE UPDATING AGENCY COMPANY CONTRACT: ', err);
				AppDispatcher.handleAction({
					actionType: TravelAgentConstants.UPDATE_COMPANY_CONTRACT_FAILED,
					data: err,
				});
			});
	},
};

export default TravelAgentActions;
