import _ from 'lodash';
import { Dates as DateUtils } from 'arbitrip-common/client/utils';

const TripDetails = ({ hotel: { city, country }, searchTerms }) => {
	const { check_in, check_out, rooms, guests, children_ages } = searchTerms;

	const CHECK_IN = DateUtils.parseFormatDate(check_in).format('DD MMM YYYY');
	const CHECK_OUT = DateUtils.parseFormatDate(check_out).format('DD MMM YYYY');
	const ROOMS = rooms + (Number(rooms) === 1 ? ' Room' : ' Rooms');
	const GUESTS = guests + (Number(guests) === 1 ? ' Adult' : ' Adults');

	const childrenCount = _.size(children_ages);
	const CHILDREN = childrenCount > 0 ? `${childrenCount} ${childrenCount === 1 ? 'Child' : 'Children'}` : null;

	return (
		<div className="trip-details-container">
			<span className="destination">
				{' '}
				{city}, {country}
			</span>
			<div className="flex dates-container">
				<div className="flex flex-column check-in">
					<span>Check-in</span>
					<span>{CHECK_IN}</span>
				</div>
				<div className="flex flex-column check-out">
					<span>Check-out</span>
					<span>{CHECK_OUT}</span>
				</div>
			</div>
			<div className="flex flex-column guests-container">
				<span>Guests</span>
				<span>
					{ROOMS}&nbsp;·&nbsp;{GUESTS}
					{CHILDREN && <>&nbsp;·&nbsp;{CHILDREN}</>}
				</span>
			</div>
		</div>
	);
};

export default TripDetails;
