import _ from 'lodash';
import keyMirror from 'keymirror';

const CONSTANTS = {
	STATUS: {
		INITIAL: 'initial',
		BUSY: 'busy',
		SUCCESS: 'success',
		FAILED: 'failed',
	},
};

// Define action constants
export default _.extend(
	{},
	CONSTANTS,
	keyMirror({
		PAYMENT_LINK_FAILED: null,
		PAYMENT_LINK_RESET: null,
		PAYMENT_LINK_SUCCESS: null,
		PAYMENT_LINK_LOADING: null,
	}),
);
