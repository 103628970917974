///
/// Important Note:
/// For legacy reasons, while (VERY) bad practice
/// Update changes in arbitrip-common ::: client/entities/Reservation.js
///

const _ = require('lodash');
const moment = require('moment');

const Room = require('./Room');
const User = require('./User');
const ReservationConstants = require('../constants').ReservationConstants;
const SUPPORTED_LANGUAGES = require('../../translation/const').SUPPORTED_LANGUAGES;

const extra_params_fields = [
  'management_trip_id',
  'management_trip_name',
  'quote_id',
  'group_id',

  'combtas_id',
  'proposal_id',

  'travel_booster_docket_number',
  // 'agency_client_price',
  'agent_pricing',

  'hotel',
  'deal',
  'travel_policy'
];

function valueOrDefaultInArray(arr, idx, property) {
  return (Array.isArray(arr) && arr[idx] && arr[idx][property]) || '';
}

export default class Reservation {
  constructor(reservation, extraParams = {}) {
    this.language = reservation.language || SUPPORTED_LANGUAGES.EN;
    this.cityInitials = ' ';
    this.company = {
      name: '',
      picture: '',
    };
    this.group_id = reservation.group_id;
    this.payment = reservation.payment;

    this.id = reservation._id || reservation.id;
    this.short_id = reservation.short_id || '';

    this.status = reservation.status;
    this.confirmed = (this.status === ReservationConstants.RESERVATION_STATUS.APPROVED);
    this.client_trip_id = reservation.client_trip_id;

    const tas_id = _.get(reservation, 'integrations.combtas.tas_id');
    if (tas_id) {
      this.combtas_id = tas_id;
    }

    const docket_number = _.get(reservation, 'integrations.travel_booster.docket_number');
    if (docket_number) {
      this.travel_booster_docket_number = docket_number;
    }

    // const agency_client_price = _.get(reservation, 'agency_client_price');
    // if (agency_client_price) {
    //   this.agency_client_price = agency_client_price;
    // }

    const agent_pricing = _.get(reservation, 'agent_pricing');
    if (_.isEmpty(this.agent_pricing)) {
      this.agent_pricing = {
        pricing_method: ReservationConstants.AGENT_PRICING_METHOD.MARGIN_PERCENTAGE,
        absolute_price: _.get(reservation, 'deal.totalPrice'),
        absolute_margin: 0, // todo get from contract
        margin_percentage: 0, // todo get from contract
        valid: true

      };
    } else {
      this.agent_pricing = agent_pricing;
    }

    this.creator = reservation.creator;
    this.handling_agent = reservation.handling_agent;

    this.hotel = reservation.hotel;
    this.deal = reservation.deal;

    for (let field of extra_params_fields) {
      if (extraParams[field]) {
        _.set(this, field, extraParams[field]);
      }
    }

    this.terms_of_supplier = _.get(this, 'deal.chooseprod.dca.info');

    if (reservation.supplier_remarks) {
      this.terms_of_supplier = reservation.supplier_remarks;
    }

    this.travelers = Array.isArray(reservation.travellers)
      ? reservation.travellers.map((traveler) => new User(traveler))
      : [];
    const initial_travelers_length = this.travelers.length;

    const pre_filled_travelers = extraParams && Array.isArray(extraParams.travelers)
      ? extraParams.travelers
      : [];

    if (this.deal) {
      if (this.deal.details) {
        _.set(this, 'deal.details.check_in', moment.utc(this.deal.details.check_in));
        _.set(this, 'deal.details.check_out', moment.utc(this.deal.details.check_out));
        _.set(this, 'deal.expedia_pricing', this.deal.details.pureExpediaPrices);
      }

      if (Array.isArray(this.deal.rooms)) {
        this.rooms = {};
        for (var i = 1; i <= this.deal.rooms; i++) {
          this.rooms[i] = new Room(this.deal.room, this.deal.guestsPerRoom);
        }
      }

      const travelers_count = _.get(this, 'deal.rooms', 0) * _.get(this, 'deal.guestsPerRoom', 0);

      for (let i = initial_travelers_length; i < travelers_count; i++) {
        const room_id = Math.floor(i / _.get(this, 'deal.guestsPerRoom', 1)) + 1;

        this.travelers.push(new User({
          id: valueOrDefaultInArray(pre_filled_travelers, i, 'id'),
          first_name: valueOrDefaultInArray(pre_filled_travelers, i, 'first_name'),
          last_name: valueOrDefaultInArray(pre_filled_travelers, i, 'last_name'),
          email: valueOrDefaultInArray(pre_filled_travelers, i, 'email'),
          tel: valueOrDefaultInArray(pre_filled_travelers, i, 'tel'),
          room: room_id
        }));
      }
    }

    this.supplierTerms = reservation.supplier_terms;
    this.specialRequests = reservation.special_requests || '';
    this.travel_policy = reservation.travel_policy_snapshot;
    this.creation_date = new Date(reservation.created_timestamp);
    this.last_change_date = new Date(reservation.changed_timestamp);
    this.invoice_url = this.payment && this.payment.invoice && this.payment.invoice.response && (this.payment.invoice.response.copy_doc_url || this.payment.invoice.response.doc_copy_url);
    this.include_management_trip_id = (this.management_trip_id ? true : false);
    if (reservation.search_terms) {
      this.search_terms = reservation.search_terms;
    }
    if (reservation.contract && reservation.contract.company) {
      this.company = {
        id: reservation.contract.company._id,
        name: reservation.contract.company.name,
        picture: reservation.contract.company.picture
      };
    }
    if (reservation.supplier_name) {
      this.supplier_name = reservation.supplier_name;
    }

    if (reservation.if_credit_card_charge_date) {
      this.if_credit_card_charge_date = new Date(reservation.if_credit_card_charge_date);
    }

    this.supplier_ref = reservation.supplier_ref;
    this.has_voucher = reservation.has_voucher;
    this.out_of_policy_booking_reason = reservation.out_of_policy_booking_reason;
    this.setCanceled = () => {
      this.status = ReservationConstants.RESERVATION_STATUS.CANCELED;
      this.confirmed = (this.status === ReservationConstants.RESERVATION_STATUS.APPROVED);
      this.cancelStatus = 'succeeded';
    };

    this.private_travel = reservation.private_travel;

    this.isCancelable = () => {
      const now_utc = moment.utc();

      const refundable = (_.get(this, 'deal.dca.nonRefundable') === false);
      const deal_is_fully_refundable_until = _.get(this, 'deal.dca.dealIsFullyRefundableTill');

      const in_cancel_policy = (refundable && deal_is_fully_refundable_until)
        ? now_utc.isSameOrBefore(moment.utc(deal_is_fully_refundable_until))
        : false;

      const confirmed = (this.status === ReservationConstants.RESERVATION_STATUS.APPROVED);

      const check_in = _.get(this, 'deal.details.check_in');
      const future_reservation = now_utc.isBefore(moment.utc(check_in));

      return in_cancel_policy && confirmed && future_reservation;
    }

    this.mock = reservation.mock;

    this.agent_notes = reservation.agent_notes;
    this.agent_note = _.get(reservation, 'agent_notes.note', '');
    this.payment_country_code = reservation.payment_country_code;

    // /////////////
    // // Loyalty //
    // /////////////

    // this.points_limit = 120; // TODO: replace with real stuff
    // this.points_earned = 20; // TODO: replace with real stuff

    // this.payment = this.payment || {};
    // this.payment.loyalty = this.payment.loyalty || {};
    // this.payment.points = this.payment.points || {};

    // this.payment.points.balance = 100;
    // this.payment.points.currency = 'ILS';
    // this.payment.points.points_ex_rate = 1;
    // this.payment.points.max_cap = 100;
    // this.payment.points.provider = 'wonder';
    // this.payment.points.show = true;
    // this.payment.points.user_chosen_points = 0;
  }
}

