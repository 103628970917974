import React from 'react';
import createClass from 'create-react-class';

import ReservationStore from '../../../stores/ReservationStore';
import PaymentStore from '../../../stores/PaymentStore';
import ReservationActions from '../../../actions/ReservationActions';

// Method to retrieve state from Stores
function getPayAtHotelComponentState() {
	return {
		reservationData: ReservationStore.getReservationData(),
		paymentData: PaymentStore.getPaymentData(),
	};
}

// Define main Controller View
var PayAtHotelComponent = createClass({
	displayName: 'PayAtHotelComponent',

	// Get initial state from stores
	getInitialState: function () {
		return getPayAtHotelComponentState();
	},

	// Add change listeners to stores
	componentDidMount: function () {
		ReservationStore.addChangeListener(this._onChange);
		PaymentStore.addChangeListener(this._onChange);
	},

	// Remove change listeners from stores
	componentWillUnmount: function () {
		PaymentStore.removeChangeListener(this._onChange);
		ReservationStore.removeChangeListener(this._onChange);
	},

	makeReservation: function (e) {
		if (this.props.valid) {
			const hotel_recommended_index = this.state.reservationData.hotel.recommended_index;
			const deal_index = this.state.reservationData.deal.deal_index;

			ReservationActions.bookPayAtHotelReservation(
				this.state.reservationData,
				this.state.paymentData,
				hotel_recommended_index,
				deal_index,
			);
		} else {
			// TODO: alert the user
		}
	},

	render: function () {
		/* Obsolete?
    var buttonValue = "Complete Booking";
    var RESERVATION_BUTTON = (this.props.valid && (this.state.reservationData.status === 'initialized'))
      ? <input type="button" className="btn btn-success" onClick={this.makeReservation} value={buttonValue} />
      : <input type="button" className="btn btn-success disabled" value={buttonValue} />;

    var SPINNER = (this.state.paymentData.status === 'booking')
      ? <i className="fa fa-spin fa-spinner fa-2x" />
      : null;*/

		return (
			<div className="pay-at-hotel-component">
				<div className="content">
					{/* The payment for the stay will be paid in full at the hotel. */}
					Final payment will be settled directly with the property in the local currency, you will not be
					charged until your stay.
				</div>
				{/* <div className="button-container">
          { RESERVATION_BUTTON }
          { SPINNER }
        </div> */}
			</div>
		);
	},

	// Method to setState based upon Store changes
	_onChange: function () {
		this.setState(getPayAtHotelComponentState());
	},
});

export default PayAtHotelComponent;
