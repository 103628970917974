const keyMirror = require('keymirror');

export const EVENTS = keyMirror({
    OPENED_BASKET: null,
    CLOSED_BASKET: null,
    ADDED_TO_BASKET: null,
    REMOVED_FROM_BASKET: null,
    CLEARED_BASKET: null,
    SENT_BASKET: null,
    FAILED_TO_SEND_BASKET: null,
});