import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useMemo, useState } from 'react';
import useScrollToTopOnBookingChange from '../../../hooks/useScrollToTopOnBookingChange';
import Drawer from '@mui/material/Drawer';
import BookingField from './BookingDrawerField.react';
import CollapseText from '../../general/CollapseText.react';
import BookingDrawerReservationSummary from './BookingDrawerReservationSummary.react';
import BookingDrawerPaymentDetails from './BookingDrawerPaymentDetails.react';
import { Button, CircularProgress, IconButton, Snackbar, Tooltip } from '@mui/material';
import Analytics from 'arbitrip-common/client/analytics';
import ApiURLs from '../../../utils/ApiURLs';
import DownloadIcon from './DownloadIcon';
import VoucherIcon from './VoucherIcon';
import { ReactComponent as CopyIcon } from '../../../img/bookings/copy.svg';
import { ReactComponent as PointsIcon } from '../../../img/search_page/loyalty/present-plain.svg';
import { ReactComponent as BreakfastIcon } from '../../../img/amenities/breakfast@3x.svg';
import ReservationConstants from '../../../constants/ReservationConstants';
import CancelReservationDialog from './CancelReservationDialog.react';
import ReservationActions from '../../../actions/ReservationActions';
import TravelBoosterTitle from '../bookings/TravelBoosterTitle.react';
import TravelBooster from '../bookings/TravelBooster.react';
import RetroactivePointsUsageTitle from './RetroactivePointsUsageTitle.react';
import RetroactivePointsUsage from './RetroactivePointsUsage.react';
import MaterialDesignDialog from '../../general/MaterialDesignDialog.react';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import BookingStatus from '../../../utils/BookingStatus';
import BookingsConstants from '../../../constants/BookingsConstants';
import { Catering } from 'arbitrip-common/client/utils';
import { getBreakfastIncluded } from '../requests/helpers';
import { PAYMENT_TYPE } from 'arbitrip-common/general/constants';
import Config from 'arbitrip-common/client/utils/Config';
import PriceBreakdown from 'arbitrip-common/client/utils/PriceBreakdown';
import { isRetroactivePointsUsageEnabled } from 'arbitrip-common/client/utils/RetroactivePoints';
import BookingDrawerPaymentBreakdownDetails from './BookingDrawerPaymentBreakdownDetails.react';
import { getIsUnpaid } from '../../../utils/PayLater';
import SettlePaymentMain from './SettlePayment/SettlePaymentMain.react';
import helpers from './SettlePayment/helpers';

const date_format = 'D MMM YYYY';

const agent_notes_message = {
	[BookingsConstants.STATUS.SUCCESS]: 'Your note was saved.',
	[BookingsConstants.STATUS.FAILED]: 'Failed to save note.',
};

const ESCAPE_KEY_CODE = 27;

function getVoucherURL(booking) {
	return ApiURLs.VOUCHER_PRINT.replace(':reservation_id', booking.id) + '?redirect=true';
}

function getTravelersText(booking) {
	let travelers_text = booking.travelers.map((t) => `${t.first_name} ${t.last_name}`).join(', ');

	const children_count = _.get(booking, 'deal.details.children_count', 0);
	const children_ages = _.get(booking, 'deal.details.children_ages', []).map((age) => (age === 0 ? 'under 1' : age));

	if (children_count > 0) {
		const childrenText = `${children_count} ${children_count > 1 ? 'Children' : 'Child'} (${children_count === 1 ? 'age' : 'ages'} ${children_ages.join(', ')})`;
		travelers_text += `\r\n+${childrenText}`;
	}

	return travelers_text;
}

const BookingDrawer = ({
	open,
	onClose,
	booking,
	profile,
	syncTravelBoosterStatus,
	agentNotesStatus,
	confirmationStatus,
	points,
	updatePointsUsageStatus,
	settlePaymentStatus,
}) => {
	const display_dev = Config.dev_mode || (Config.prod && profile.is_arbi_support);
	const previousAgentNotesStatus = useRef(agentNotesStatus);

	const [showCancelModal, setShowCancelModal] = useState(false);
	const [showCancelSuccess, setShowCancelSuccess] = useState(false);
	const [showTravelBoosterModal, setShowTravelBoosterModal] = useState(false);
	const [showRetroactivePointsUsageModal, setShowRetroactivePointsUsageModal] = useState(false);
	const [showSettlePaymentModal, setShowSettlePaymentModal] = useState(false);
	const [copyFlashReservationId, setCopyFlashReservationId] = useState(false);
	const [copyFlashPaymentId, setCopyFlashPaymentId] = useState(false);
	const [agentNotesFlash, setAgentNotesFlash] = useState(false);
	const [isScrolled, setIsScrolled] = useState(false);

	const now_utc = useMemo(() => moment.utc(), []); // Memoize the current UTC moment

	const { travel_booster_integration_enabled = false } = profile.settings;
	const fullyRefundableTill = _.get(booking, 'deal.dca.dealIsFullyRefundableTill');
	const isConfirmed = _.get(booking, 'confirmed');
	const cancelStatus = _.get(booking, 'cancelStatus');
	const isShowInvoice = _.get(booking, 'showInvoice');
	const isFutureReservation =
		booking && now_utc.isBefore(moment.utc(_.get(booking, 'deal.dca.dealIsFullyRefundableTill')));
	const isGCashApp = _.get(booking, 'payment.payment_type') === PAYMENT_TYPE.GCASH_APP;

	const retroactive_points_usage_enabled = isRetroactivePointsUsageEnabled({ profile, booking });

	const isExpediaPartner = _.get(profile, 'company.partners_ui.expedia_partner', false);

	useEffect(() => {
		if (
			previousAgentNotesStatus.current === BookingsConstants.STATUS.BUSY &&
			agentNotesStatus !== BookingsConstants.STATUS.BUSY
		) {
			setAgentNotesFlash(true);
			setTimeout(() => {
				setAgentNotesFlash(false);
			}, 4000);
		}

		previousAgentNotesStatus.current = agentNotesStatus;
	}, [agentNotesStatus]);

	const drawerRef = useScrollToTopOnBookingChange(booking);

	useEffect(() => {
		setIsScrolled(false);
	}, [booking]);

	const is_agent = useMemo(() => {
		return profile.agent || profile.super_agent;
	}, [profile]);

	const in_cancel_policy = useMemo(() => {
		return (
			!_.get(booking, 'deal.dca.nonRefundable') &&
			fullyRefundableTill &&
			now_utc.isSameOrBefore(moment.utc(fullyRefundableTill))
		);
	}, [booking, fullyRefundableTill, now_utc]);

	const canCancelReservation = useMemo(() => {
		return in_cancel_policy && isConfirmed && isFutureReservation;
	}, [in_cancel_policy, isConfirmed, isFutureReservation]);

	const shouldPay = useMemo(() => {
		return getIsUnpaid(profile, booking);
	}, [profile, booking]);

	useEffect(() => {
		if (cancelStatus === 'succeeded') {
			ReservationActions.resetReservationCancellationStatus(booking.id);
			setShowCancelSuccess(true);
			scrollToTop();
			setTimeout(() => {
				setShowCancelSuccess(false);
			}, 4000);
		}
	}, [canCancelReservation]);

	const scrollToTop = () => {
		if (drawerRef.current) {
			drawerRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	};

	const cancellation_policy_text = useMemo(() => {
		return _.get(booking, 'deal.dca.cancellationPoliciesText');
	}, [booking]);

	const breakfastIncluded = useMemo(() => {
		return getBreakfastIncluded(booking);
	}, [booking]);

	const onDownloadInvoice = () => {
		Analytics.actions.interactions.downloadedInvoice(
			profile,
			booking.id,
			booking.invoice_url,
			booking.hotel,
			booking.deal.details,
		);

		window.open(booking.invoice_url, '_blank', 'noopener noreferrer');
	};

	const onShowVoucher = () => {
		const voucher_url = document.location.protocol + getVoucherURL(booking);
		Analytics.actions.interactions.showedVoucher(
			profile,
			booking.id,
			voucher_url,
			booking.hotel,
			booking.deal.details,
		);

		window.open(getVoucherURL(booking), '_blank', 'noopener noreferrer');
	};

	const onSendBookingConfirmation = () => {
		const deal = _.assign({}, booking.deal.details, booking.deal.cheapopoPricing, booking.deal.dca);

		ReservationActions.sendBookingConfirmation(booking.id, profile);
		Analytics.actions.interactions.sentBookingConfirmation(booking.id, booking.invoice_url, booking.hotel, deal);
	};

	const copyReservationId = () => {
		setCopyFlashReservationId(true);
		navigator.clipboard.writeText(booking.short_id);

		setTimeout(() => {
			setCopyFlashReservationId(false);
		}, 4000);
	};

	const copyPaymentId = () => {
		setCopyFlashPaymentId(true);
		navigator.clipboard.writeText(booking.payment._id);

		setTimeout(() => {
			setCopyFlashPaymentId(false);
		}, 4000);
	};

	const isBusy = () => confirmationStatus === BookingsConstants.STATUS.BUSY;

	if (!booking) {
		console.log('BookingDrawer ::: NO BOOKING');
		return null;
	}

	const points_applicable_date_time = moment.utc(booking.deal.details.check_out).add(1, 'day');
	const now = moment.utc();
	const points_upcoming_tooltip_text = points_applicable_date_time.isAfter(now)
		? `Points will be applicable at ${points_applicable_date_time.format('MMM D YYYY')} 11:59 PM (GMT)`
		: 'Points are applicable';

	const openRetroactiveModal = retroactive_points_usage_enabled
		? () => {
				setShowRetroactivePointsUsageModal(true);
				Analytics.actions.interactions.viewedPointsRedeemModal(booking.id);
			}
		: _.noop;

	const openSettlePaymentModal = profile.freelance_agent
		? () => {
				setShowSettlePaymentModal(true);
				Analytics.actions.interactions.openPayLaterModal(booking.id);
			}
		: _.noop;

	const PAYMENT_DETAILS =
		booking.payment.payment_type &&
		(PriceBreakdown.isBreakdownPrice({ profile, reservation: booking }) ? (
			<BookingDrawerPaymentBreakdownDetails booking={booking} />
		) : (
			<BookingDrawerPaymentDetails
				booking={booking}
				retroactivePointsUsageEnabled={retroactive_points_usage_enabled}
				openRetroactiveModal={openRetroactiveModal}
				profile={profile}
			/>
		));

	const onKeyDown = (e) => {
		if (e.keyCode === ESCAPE_KEY_CODE) {
			if (showRetroactivePointsUsageModal) {
				Analytics.actions.interactions.closedPointsModal(booking.id);
				setShowRetroactivePointsUsageModal(false);
			} else if (showSettlePaymentModal) {
				Analytics.actions.interactions.closedPayLaterModal(booking.id);
				setShowSettlePaymentModal(false);
			}
		}
	};

	const getPayLaterDeadlineFormat = () => {
		const date = moment(booking.pay_later_deadline).startOf('day').subtract(1, 'minute');
		return helpers.getTimeFormatted(date);
	};

	const handleScroll = (e) => {
		const scrollTop = e.target.scrollTop;
		setIsScrolled(scrollTop > 0);
	};

	return (
		<React.Fragment>
			<Drawer
				anchor="right"
				open={open}
				onClose={onClose}
				className="booking-drawer"
				SlideProps={{
					appear: true,
				}}
			>
				<div ref={drawerRef} tabIndex="-1" className="booking-drawer-inner" onScroll={handleScroll}>
					<div className={`booking-title ${isScrolled ? 'shadow' : ''}`}>
						<div className="flex space-between align-items-baseline">
							<div className="trip-to">{booking.hotel.name}</div>
							<IconButton className="close-drawer-button" onClick={onClose} aria-label="Close">
								<CloseOutlined />
							</IconButton>
						</div>
						<div className="city">
							<div>{booking.hotel.city}</div>
							<div>{`${booking.hotel.address}, ${booking.hotel.city}, ${booking.hotel.country}`}</div>
						</div>
						<div className="flex space-between">
							<div>
								<div className={`booking-status ${shouldPay ? 'unpaid' : booking.status}`}>
									<span>● {BookingStatus.getBookingStatus(booking.status)}</span>
									{shouldPay && <span className="booking-status pending gap">Unpaid</span>}
								</div>
								{shouldPay && (
									<div className="booking-status pending">
										Payment deadline {getPayLaterDeadlineFormat()}
									</div>
								)}
							</div>
							<div>
								{profile.freelance_agent && shouldPay && (
									<div>
										<Button variant="contained" fullWidth onClick={openSettlePaymentModal}>
											{/* Settle Payment */}
											Pay
										</Button>
									</div>
								)}
							</div>
						</div>
					</div>

					<BookingDrawerReservationSummary
						booking={booking}
						totalPrice={
							PriceBreakdown.isBreakdownPrice({ profile, reservation: booking })
								? booking.deal.expedia_pricing.totalPrice
								: 0
						}
					/>

					{profile.loyalty.enabled &&
						booking.arbitrip_loyalty_earned &&
						_.isNumber(booking.arbitrip_loyalty_earned.points) &&
						booking.arbitrip_loyalty_earned.points > 0 && (
							<BookingField
								fieldTitle="Loyalty Program"
								// fieldValue={booking.short_id}
								fieldValue={
									<div className="booking-points">
										{/* TODO: when not yet eligible-  update text from earned to eligible when... (instead of hover) */}
										<div className="booking-points-value">
											{booking.arbitrip_loyalty_earned.points}{' '}
											{booking.arbitrip_loyalty_earned.points === 1 ? 'point' : 'points'} earned
										</div>
										{/* <div className="booking-points-discount">$50 discount</div> */}
										{/* <div className="booking-points-icon"><PointsIcon /></div> */}
									</div>
								}
								// icon={<Tooltip PopperProps={{ modifiers: [{ name: 'offset', options: { offset: [0, -10] } }] }} placement='top' className='points-upcoming-tooltip' title={points_upcoming_tooltip_text}>
								//     <IconButton>
								//         <InfoIcon />
								//     </IconButton>
								// </Tooltip>}
								icon={
									<Tooltip
										PopperProps={{ modifiers: [{ name: 'offset', options: { offset: [0, -10] } }] }}
										placement="top"
										className="points-upcoming-tooltip"
										title={points_upcoming_tooltip_text}
									>
										<IconButton>
											<div className="booking-points-icon">
												<PointsIcon />
											</div>
										</IconButton>
									</Tooltip>
								}
							/>
						)}

					{retroactive_points_usage_enabled && PAYMENT_DETAILS}

					<BookingField
						fieldTitle="Cancellation Policy"
						fieldValue={
							cancellation_policy_text ? (
								<CollapseText text={cancellation_policy_text} breakLines />
							) : (
								'None'
							)
						}
					/>

					<BookingField
						fieldTitle={`Room${booking.deal.details.room_count > 1 ? `s (${booking.deal.details.room_count}x)` : ''}`}
						fieldValue={
							<React.Fragment>
								{booking.deal.details.room_description}
								{breakfastIncluded && (
									<div className="space">
										<BreakfastIcon />
										{Catering.getCatering(booking.deal.dca.heuristicOptions)}
									</div>
								)}
							</React.Fragment>
						}
					/>

					<BookingField
						fieldTitle={`Traveler${booking.travelers.length + (booking.deal.details.children_count || 0) > 1 ? `s (${booking.travelers.length + (booking.deal.details.children_count || 0)}x)` : ''}`}
						fieldValue={getTravelersText(booking)}
						className={'capitalize'}
					/>

					{is_agent && profile.company && (
						<BookingField fieldTitle="Client Name" fieldValue={booking.company.name} />
					)}

					{is_agent && booking.handling_agent && (
						<BookingField fieldTitle="Handling Agent" fieldValue={booking.handling_agent.full_name} />
					)}

					{booking.short_id && (
						<BookingField
							fieldTitle="Reservation ID"
							fieldValue={booking.short_id}
							icon={<CopyIcon />}
							onClick={copyReservationId}
							className={{ active: copyFlashReservationId }}
						/>
					)}

					{display_dev && booking.payment._id && (
						<BookingField
							fieldTitle="Payment ID"
							fieldValue={booking.payment._id}
							icon={<CopyIcon />}
							onClick={copyPaymentId}
							className={{ active: copyFlashPaymentId }}
						/>
					)}

					{!retroactive_points_usage_enabled && PAYMENT_DETAILS}

					{!_.isEmpty(booking.creator) && (
						<BookingField fieldTitle="Creator" fieldValue={booking.creator.full_name} />
					)}

					<BookingField
						fieldTitle="Creation Date"
						fieldValue={moment(booking.creation_date).format(date_format)}
					/>

					{booking.specialRequests && (
						<BookingField fieldTitle="Special Requests" fieldValue={booking.specialRequests} />
					)}

					<BookingField
						fieldTitle="Terms of Supplier"
						fieldValue={
							booking.supplierTerms ? <CollapseText text={booking.supplierTerms} breakLines /> : 'None'
						}
					/>

					{/* {is_agent && <BookingDrawerAgentNotes booking={booking} status={agentNotesStatus} />} */}

					<div className="actions column">
						{
							// if the payment done through GCash - don't show invoice button
							!isGCashApp && !isExpediaPartner && isShowInvoice && booking.invoice_url && (
								<Button variant="text" onClick={onDownloadInvoice} startIcon={<DownloadIcon />}>
									Show Invoice
								</Button>
							)
						}

						{isConfirmed && booking.has_voucher && !booking.deal.details.post_pay && (
							<Button variant="text" onClick={onShowVoucher} startIcon={<VoucherIcon />}>
								{booking.has_voucher ? 'Download Voucher' : 'Issue Voucher'}
							</Button>
						)}
					</div>

					{travel_booster_integration_enabled && (
						<div className="actions">
							<Button variant="outlined" fullWidth onClick={() => setShowTravelBoosterModal(true)}>
								Travel Booster
							</Button>
						</div>
					)}

					<div className="actions">
						{(profile.agent || profile.travel_manager) && (
							<Button
								variant="outlined"
								fullWidth
								onClick={onSendBookingConfirmation}
								disabled={!isConfirmed || isBusy()}
							>
								Send booking confirmation{' '}
								{isBusy() && <CircularProgress className="circular-progress" size={12} />}
							</Button>
						)}

						<Button
							variant="outlined"
							color="danger"
							fullWidth
							onClick={() => setShowCancelModal(true)}
							disabled={!canCancelReservation}
						>
							Cancel Reservation
						</Button>
					</div>
				</div>
			</Drawer>

			{canCancelReservation && (
				<CancelReservationDialog
					open={
						booking.status !== ReservationConstants.RESERVATION_STATUS.CANCELED &&
						(showCancelModal ||
							booking.status === ReservationConstants.RESERVATION_STATUS.CANCELING ||
							booking.cancelStatus === ReservationConstants.STATUS.FAILED)
					}
					onClose={() => setShowCancelModal(false)}
					booking={booking}
					profile={profile}
					in_cancel_policy={in_cancel_policy}
				/>
			)}

			{travel_booster_integration_enabled && (
				<MaterialDesignDialog
					open={showTravelBoosterModal}
					onClose={() => setShowTravelBoosterModal(false)}
					closeTimeoutInMilliseconds={150}
					key={`travel-booster-modal-${booking.id}`}
					title={<TravelBoosterTitle onClose={() => setShowTravelBoosterModal(false)} />}
					content={
						<TravelBooster
							booking={booking}
							syncTravelBoosterStatus={syncTravelBoosterStatus}
							onClose={() => setShowTravelBoosterModal(false)}
							profile={profile}
						/>
					}
				/>
			)}

			{retroactive_points_usage_enabled && (
				<MaterialDesignDialog
					open={showRetroactivePointsUsageModal}
					onClose={() => setShowRetroactivePointsUsageModal(false)}
					closeTimeoutInMilliseconds={150}
					key={`retroactive-points-usage-modal-${booking.id}`}
					title={
						<RetroactivePointsUsageTitle
							onClose={() => setShowRetroactivePointsUsageModal(false)}
							booking={booking}
						/>
					}
					content={
						<RetroactivePointsUsage
							booking={booking}
							profile={profile}
							points={points}
							updatePointsUsageStatus={updatePointsUsageStatus}
							onClose={() => setShowRetroactivePointsUsageModal(false)}
						/>
					}
					onKeyDown={onKeyDown}
					minWidth={825}
				/>
			)}

			{profile.freelance_agent && (
				<SettlePaymentMain
					booking={booking}
					profile={profile}
					onKeyDown={onKeyDown}
					settlePaymentStatus={settlePaymentStatus}
					showSettlePaymentModal={showSettlePaymentModal}
					setShowSettlePaymentModal={setShowSettlePaymentModal}
				/>
			)}

			<Snackbar open={showCancelSuccess} message={'Reservation cancellation succeeded'} autoHideDuration={4000} />

			<Snackbar
				open={copyFlashReservationId}
				message={'Reservation ID copied to clipboard'}
				autoHideDuration={4000}
			/>
			<Snackbar open={copyFlashPaymentId} message={'Payment ID copied to clipboard'} autoHideDuration={4000} />
			<Snackbar open={agentNotesFlash} message={agent_notes_message[agentNotesStatus]} autoHideDuration={4000} />
		</React.Fragment>
	);
};

export default BookingDrawer;
