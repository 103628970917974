const FAQ = [
        {
            "title": "Who Are We?",
        "description": "Let’Stay is an Israeli hotel booking platform operated by Arigato Advanced Travel Technologies Ltd., a company active in the tourism industry since 2015. The company has developed accommodation booking technology that scans millions of hotels and apartments worldwide, identifies prices below market value, and enables users to find the most suitable accommodations for their trip while guaranteeing competitive pricing. The company is active in the business travel sector and works with thousands of companies globally. In the past year, the company has expanded into the leisure travel market under the Let’Stay brand, thanks to its advanced technology and the significant commercial power it has accumulated worldwide.",
            "secondary": []
        },
        {
            "title": "How Are Prices So Low, and Do They Include Taxes?",
            "description": "Yes, it's not a mistake - the prices really are low! Let’Stay is able to offer such low prices thanks to the combination of our unique technology and significant commercial power, ensuring you get excellent rates typically reserved only for large organizations or travel agents. The prices displayed on the website include all taxes and fees, except for local taxes and charges to be paid directly at the hotel, as detailed on the offer page before completing your booking. When comparing our prices with other travel websites, it's important to compare the final price, including all taxes and charges. If you have any questions about this, please contact us through the 'Contact Us' page on our website, and we'll be happy to assist.",
            "secondary": []
        },
        {
            "title": "Why do I need to enter my email to access the site?",
            "description": "At Let’Stay, we work with dozens of hotel suppliers worldwide, allowing us to offer prices significantly lower than standard market rates. However, our suppliers have one condition – these special rates must be available only to registered users. That’s why we ask you to enter your email when accessing the site, ensuring you get full access to our exclusive deals and the best prices we can offer.",
            "secondary": []
        },
        {
            "title": "When is payment for the booking processed?",
            "description": "Payment is made immediately for the full amount of the booking. When available, our system allows you to split the payment into up to 3 interest-free installments, as long as the booking is still within its free cancellation window.",
            "secondary": []
        },
        {
            "title": "Can I make changes to the name on the booking or the travel dates?",
            "description": "There are a few options:",
            "secondary": [
                {
                    "title": "If the booking is still within the free cancellation period:",
                    "description": "The simplest way to make changes is to create a new booking with the updated details. Once the new booking is confirmed, you can cancel the original one."
                },
                {
                    "title": "If the booking is non-refundable:",
                    "description": "Please contact our customer support team (as listed on the ‘Contact Us’ page), and we will reach out to the hotel to request the changes. Keep in mind that this process depends solely on the hotel’s approval, so we cannot guarantee that the changes will be accepted."
                }
            ]
        },
        {
            "title": "Where can I view the invoices for my bookings?",
            "description": "After completing your booking, the invoice will be sent directly to the email address you provided during the booking process. You can also view the invoice through the ‘My Bookings’ page by selecting the desired booking and clicking on ‘View Invoice.’ If you can’t find the invoice, please contact us through the ‘Contact Us’ page, and we will send the document to you.",
            "secondary": []
        }
]

module.exports = {
    FAQ
};