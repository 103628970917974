import _ from 'lodash';

import React from 'react';
import createClass from 'create-react-class';
import ReservationStore from '../../../../stores/ReservationStore';
import Currencies from '../../../../utils/Currencies';
import DealPrice from '../../../DealPrice.react';
import { Dates as DateUtils } from 'arbitrip-common/client/utils';

import Config from 'arbitrip-common/client/utils/Config';
import ResultsStore from '../../../../stores/ResultsStore';

import ContentLoader from 'react-content-loader';
import { Button } from '@mui/material';
import ChangeClientPriceDialog from '../../../general/ChangeClientPriceDialog.react';
import InstallmentsBanner from '../../../InstallmentsBanner.react';

import ReservationActions from '../../../../actions/ReservationActions';
import RouterWrapper from '../../../../utils/RouterWrapper';
import { withRouter } from '../../../../utils/withRouter';

const content_loader_width = 235;
const content_loader_height = 32;

function isExtraChargeExcluded(extraCharge) {
	return extraCharge && extraCharge.excluded && !extraCharge._ignored_exclusion;
}

// Method to retrieve state from Stores
function getReservationSummaryState() {
	const reservation_data = ReservationStore.getReservationData();
	const hotel_id = _.get(reservation_data, 'hotel.id');

	return {
		should_render: true,
		arbitrip_points_applied: ResultsStore.areArbitripPointsApplied(),
		results_hotel: ResultsStore.getHotelById(hotel_id),
	};
}

// Define main Controller View
const DealDetailsContainer = createClass({
	displayName: 'DealDetailsContainer',

	// Get initial state from stores
	getInitialState: function () {
		return getReservationSummaryState();
	},

	// Add change listeners to stores
	componentDidMount: function () {
		const { reservation } = this.props;

		if (!_.get(reservation, 'deal.chooseprod')) {
			this.setState({
				should_render: false,
			});
		}

		ReservationStore.addChangeListener(this._onChange);
		ResultsStore.addChangeListener(this._onChange);
	},

	// Remove change listers from stores
	componentWillUnmount: function () {
		ReservationStore.removeChangeListener(this._onChange);
		ResultsStore.removeChangeListener(this._onChange);
	},

	handleClickChangeClientPrice: function () {
		this.setState({
			change_client_price_modal_open: true,
		});
	},

	handleCancelChangeClientPrice: function () {
		this.setState({
			change_client_price_modal_open: false,
		});
	},

	handleUpdateChangeClientPrice: function (agentPricing) {
		// TODO: save changes
		ReservationActions.updateAgentPricing(agentPricing);

		this.setState({
			change_client_price_modal_open: false,
		});
	},

	getPriceString(price, currency, show_zero) {
		return price
			? Currencies.getPriceWithDisplayCurrencyByDefaultPrecision(
					price,
					this.props.profile.display_currency_exchange_rate,
					this.props.profile.display_currency,
				)
			: show_zero
				? Currencies.getPriceWithDisplayCurrencyByPrecision(0, 1, this.props.profile.display_currency, 0)
				: '';
	},

	// TODO: make sure currency is correct!
	renderExtraCharges(extraCharges, excluded) {
		if (Array.isArray(extraCharges) && extraCharges.length) {
			const { profile } = this.props;

			return (
				<React.Fragment>
					{excluded ? (
						<div className="flex space-between excluded-extra-charges">
							<div>
								<span>Excluded extra charges</span>
							</div>
						</div>
					) : null}
					{extraCharges.map((extra_charge, idx) => {
						// state - company: USD, display: USD, hotel: USD
						// data - B.cur: USD, B.other: USD
						// required - USD

						// state - company: USD, display: USD, hotel: EUR
						// data - B.cur: EUR, B.other: USD
						// required - USD

						// state - company: USD, display: EUR, hotel: USD
						// data - B.cur: USD, B.other: USD
						// required - EUR

						// state - company: USD, display: EUR, hotel: EUR
						// data - B.cur: EUR, B.other: USD
						// required - EUR

						// state - company: USD, display: USD, hotel: GBP
						// data - B.cur: GBP, B.other: USD
						// required - EUR

						const extra_charge_adapted_price = extra_charge.other_currency
							? Currencies.getPriceWithDisplayCurrencyByPrecision(
									extra_charge.other_currency.amount,
									profile.display_currency_exchange_rate,
									profile.display_currency,
									0,
								) // extra_charge.other_currency.currency = company's currency
							: profile.currency === extra_charge.currency
								? Currencies.getPriceWithDisplayCurrencyByPrecision(
										extra_charge.amount,
										profile.display_currency_exchange_rate,
										profile.display_currency,
										0,
									) // extra_charge.currency = company's currency
								: Currencies.getRoundedPriceWithCurrency(extra_charge.amount, extra_charge.currency); // extra_charge.currency = hotel's currency (display as is without conversion)

						return (
							<div className="flex space-between extra-charge">
								<div title={Config.dev_mode && extra_charge.type}>
									{!excluded && idx === 0 ? <span>(</span> : null}
									{extra_charge.name}
								</div>
								<div>
									{extra_charge_adapted_price}
									{!excluded && idx === extraCharges.length - 1 ? <span>)</span> : null}
								</div>
							</div>
						);
					})}
				</React.Fragment>
			);
		}

		return null;
	},

	// Render our child components, passing state via props
	render() {
		const { profile, hotel, initializing, reservation, contractClientCompanyId, navigate } = this.props;
		if (_.isEmpty(reservation.deal)) {
			RouterWrapper.goToSearchPage(navigate);
			return;
		}

		if (!this.state.should_render) {
			return null;
		}

		const HOTEL_IN_ISRAEL = hotel.country_code === 'IL'; // exclude Eilat
		const ISRAEL_VAT_DISCLAIMER = HOTEL_IN_ISRAEL && hotel.city !== 'Eilat'; // exclude Eilat

		const DEAL = reservation.deal;

		const checkIn = DateUtils.parseFormatDate(DEAL.check_in);
		const checkOut = DateUtils.parseFormatDate(DEAL.check_out);

		const CHECK_IN = checkIn.format('DD MMM YYYY');
		const CHECK_OUT = checkOut.format('DD MMM YYYY');
		const nights = checkOut.diff(checkIn, 'days');
		const NIGHTS_TOTAL = `${nights}-night stay`;
		const currency = Currencies.getSymbol(DEAL.currency);
		const displayCurrency = Currencies.getSymbol(profile.display_currency);

		const {
			active: active_agent_pricing,
			client_price_total,
			client_price_per_night,
			absolute_margin_total,
		} = _.get(DEAL, 'agent_pricing', {});

		const agent_pricing_props = active_agent_pricing
			? {
					agentCommission: absolute_margin_total,
					agentTotalPrice: client_price_total,
				}
			: {};

		const pricePerNight =
			active_agent_pricing && client_price_per_night
				? Number(client_price_per_night.toFixed(2))
				: DEAL.pricePerNight;
		const PRICE_PER_NIGHT = this.getPriceString(pricePerNight, currency);

		const taxesAndFees = DEAL.taxes_and_fees;
		const TAXES_AND_FEES = this.getPriceString(taxesAndFees, DEAL.taxes_and_fees_currency);

		const taxServiceFee = DEAL.tax_service_fee;
		const taxServiceFeeExist = taxServiceFee && taxServiceFee > 0;
		const TAX_SERVICE_FEE = taxServiceFeeExist
			? Currencies.getPriceWithDisplayCurrencyByPrecision(
					taxServiceFee,
					profile.display_currency_exchange_rate,
					profile.display_currency,
					0,
				)
			: null;

		const salesTax = DEAL.sales_tax;
		const salesTaxExist = salesTax && salesTax > 0;
		const SALES_TAX = salesTaxExist
			? Currencies.getSymbol(DEAL.sales_tax_currency) + salesTax.toLocaleString()
			: null;

		const paymentsExcluded = DEAL.payments_excluded;
		const PAYMENTS_EXCLUDED =
			paymentsExcluded > 0
				? Currencies.getSymbol(DEAL.payments_excluded_currency) + paymentsExcluded.toLocaleString()
				: null;

		const extraCharges = _.get(DEAL, 'original.details.extra_charges', []);
		const [excludedExtraCharges, includedExtraCharges] = _.partition(extraCharges, isExtraChargeExcluded);
		const INCLUDED_EXTRA_CHARGES = includedExtraCharges.length
			? this.renderExtraCharges(includedExtraCharges, false)
			: null;
		const EXCLUDED_EXTRA_CHARGES = excludedExtraCharges.length
			? this.renderExtraCharges(excludedExtraCharges, true)
			: null;

		const postPay = DEAL.post_pay;
		const hotelPrice = DEAL.hotel_price;
		const hotelCurrency = DEAL.hotel_currency;
		const HOTEL_PRICE =
			hotelCurrency && hotelPrice ? Currencies.getRoundedPriceWithCurrency(hotelPrice, hotelCurrency) : null;

		const bank_balance = _.get(profile, 'bank.balance', 0);
		const exact_points_applied = this.state.arbitrip_points_applied ? Math.min(DEAL.totalPrice, bank_balance) : 0;

		// TODO: ask orel regarding the value (should points-adjusted-price be displayed only at the new points component at the bottom of the page)
		const TOTAL_PRICE = this.getPriceString(DEAL.totalPrice - exact_points_applied, currency, true);

		const total_price_base = client_price_total || DEAL.totalPrice;
		const TOTAL_BILLING_PRICE =
			total_price_base && total_price_base > 0
				? Currencies.getSymbol(currency) + total_price_base.toLocaleString()
				: '';

		// TODO: double check with three currencies scenario
		const hasDifferentDisplayCurrency =
			postPay && DEAL.hotel_currency
				? DEAL.hotel_currency !== profile.display_currency
				: DEAL.currency !== profile.display_currency;

		const PRICE_CONVERTED_DISCLAIMER = `This price is converted to show you the approximate cost in ${displayCurrency}, calculated as defined in Arbitrip's Terms of Service.`;

		const TERMS_OF_SUPPLIER_DISCLAIMER = HOTEL_IN_ISRAEL
			? 'See terms of supplier below for additional fees if applicable.'
			: 'See terms of supplier below for additional fees to be paid at the hotel if applicable.';

		return (
			<div className="deal-details">
				<span className="nights">{NIGHTS_TOTAL}</span>
				<div className="flex dates-container">
					<div className="flex flex-column check-in">
						<span>Check-in</span>
						<span>{CHECK_IN}</span>
					</div>
					<div className="flex flex-column check-out">
						<span>Check-out</span>
						<span>{CHECK_OUT}</span>
					</div>
				</div>
				<div className="flex space-between average-price-container">
					<span>Avg/Room/Night</span>
					<span>{PRICE_PER_NIGHT}</span>
				</div>
				<div
					className={`total-price-wrap flex align-center space-between${hasDifferentDisplayCurrency ? ' price-converted-wrap' : ''}`}
				>
					<span className="title">Total</span>
					{profile.agent ? (
						<DealPrice
							displayTotalPrice={false}
							supplier={DEAL.supplier_name}
							currency={DEAL.currency}
							price={DEAL.totalPrice}
							totalPrice={DEAL.totalPrice}
							netPrice={DEAL.net_price_total}
							commission={DEAL.commission_total}
							dealId={DEAL.id}
							profile={profile}
							reservationMode={true}
							{...agent_pricing_props}
							contractClientCompanyId={contractClientCompanyId}
						/>
					) : (
						<React.Fragment>
							<div>
								{hasDifferentDisplayCurrency ? <span className="approx">approx.</span> : null}
								<span>
									{HOTEL_IN_ISRAEL || hasDifferentDisplayCurrency ? '*' : null}
									{TOTAL_PRICE}
								</span>
							</div>
						</React.Fragment>
					)}
				</div>
				{taxServiceFeeExist ? (
					<div className="flex flex-end tax-info">
						<div>
							<span>(</span>
							<span className="tax-service-fee">Included taxes and fees</span>
						</div>
						&nbsp;
						<div>{TAX_SERVICE_FEE})</div>
					</div>
				) : null}
				{salesTaxExist ? (
					<div className="flex flex-end tax-info">
						<div>
							<span>(</span>
							<span className="sales-tax">Included sales tax</span>
						</div>
						&nbsp;
						<div>{SALES_TAX})</div>
					</div>
				) : null}
				{!postPay && taxesAndFees && taxesAndFees > 0 && (
					<div className="flex flex-end tax-info">
						<div>(Included taxes and fees</div>
						&nbsp;
						<div>{TAXES_AND_FEES})</div>
					</div>
				)}

				{INCLUDED_EXTRA_CHARGES}
				{EXCLUDED_EXTRA_CHARGES}
				{hasDifferentDisplayCurrency &&
					!postPay &&
					// If currency can be changed don't make potential conflicting statements
					!this.props.canChangeChargeCurrency && (
						<div className="billing-price-wrap flex space-between">
							<span>You will be charged</span>
							<span>{TOTAL_BILLING_PRICE}</span>
						</div>
					)}

				{this.props.agencyClientPriceEnabled && (
					<React.Fragment>
						<Button
							aria-label="change client price"
							variant="contained"
							color="primary"
							className="change-client-price"
							onClick={this.handleClickChangeClientPrice}
							disabled={this.props.initializing || this.props.shouldLock}
							disableRipple
						>
							Change Client Price
						</Button>
						{this.props.initialized && !this.props.shouldLock && (
							<ChangeClientPriceDialog
								dialogOpen={this.state.change_client_price_modal_open}
								dialogTitle="Change Client Price"
								deal={DEAL}
								initializing={this.props.initializing}
								disabled={this.props.shouldLock}
								onCancel={this.handleCancelChangeClientPrice}
								onUpdate={this.handleUpdateChangeClientPrice}
								profile={profile}
							/>
						)}
					</React.Fragment>
				)}
				<div className="flex flex-column flex-strech pay-container">
					{initializing ? (
						<ContentLoader
							width={content_loader_width}
							height={content_loader_height}
							viewBox={`0 0 ${content_loader_width} ${content_loader_height}`}
						>
							<rect
								x="0"
								y="0"
								rx="8"
								ry="8"
								width={content_loader_width}
								height={content_loader_height}
							/>
						</ContentLoader>
					) : (
						<React.Fragment>
							{postPay &&
								HOTEL_PRICE &&
								(hasDifferentDisplayCurrency ? (
									<>
										<div className="flex pay-wrap space-between">
											<div>{"Total in hotel's currency"}</div>
											<div>{HOTEL_PRICE}</div>
										</div>
										<div className="no-payments-excluded">
											{hasDifferentDisplayCurrency &&
												'The price is converted to show you the approximate cost in ' +
													profile.display_currency +
													", calculated as defined in Arbitrip's Terms of Service. "}
											{`You'll pay${hasDifferentDisplayCurrency ? ' in ' + DEAL.hotel_currency : ''} at the hotel. `}
											{hasDifferentDisplayCurrency &&
												'The exchange rate might change before you pay.'}
										</div>
									</>
								) : (
									<div className="pay-wrap flex-column space-between">
										<div className="no-payments-excluded">{`You'll pay at the hotel.`}</div>
									</div>
								))}
							{!postPay && paymentsExcluded > 0 && (
								<div
									className={`pay-wrap flex space-between${Config.research_dev_mode && DEAL.payments_excluded_raw ? ' reference-pay-wrap' : ''}`}
								>
									<div>Pay at the hotel</div>
									<div className="price">{PAYMENTS_EXCLUDED}</div>
								</div>
							)}
							{ISRAEL_VAT_DISCLAIMER && (
								<div className="no-payments-excluded">
									<b>
										* VAT is not included for Israeli citizens who will be charged the national
										Value-Added Tax directly at the hotel.
									</b>
								</div>
							)}

							<InstallmentsBanner />

							{hasDifferentDisplayCurrency && !postPay ? (
								<div className="price-converted-disclaimer">
									{HOTEL_IN_ISRAEL ? null : '*'} {PRICE_CONVERTED_DISCLAIMER}
								</div>
							) : null}
							{!postPay && !paymentsExcluded && (
								<div className="no-payments-excluded">{TERMS_OF_SUPPLIER_DISCLAIMER}</div>
							)}
						</React.Fragment>
					)}
				</div>
			</div>
		);
	},

	// Method to setState based upon Store changes
	_onChange: function () {
		this.setState(getReservationSummaryState());
	},
});

export default withRouter(DealDetailsContainer);
