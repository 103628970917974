const { API_SERVICES } = require('../../general/constants');

function Config() {
  this.localhost = (document.location.hostname === 'localhost');
  this.staging = (document.location.hostname === 'aws-staging.arbitrip.com');
  this.research = (document.location.hostname === 'ui-research.arbitrip.com');
  this.sandbox = (document.location.hostname === 'sandbox.arbitrip.com');
  this.htz = (document.location.hostname === 'htzone.arbitrip.com');
    this.htz_link = 'https://www.htzone.co.il/gotosite/arbitrip/?after=https://api.arbitrip.dev/htzone/login?target=search'
  this.rn = (document.location.hostname === 'railninja.arbitrip.com');
  this.rn_link = 'https://rail.ninja';
  this.prod =
      (document.location.hostname === 'app.arbitrip.com') ||
      (document.location.hostname === 'app-prod.arbitrip.com') ||
      this.htz ||
      this.rn;
  this.dev_mode = (this.localhost || this.staging || this.research || this.sandbox);
  this.research_dev_mode = (this.localhost || this.research);
  // research_mode opens some development ui mode to visualize weights to recommendation
  // this.research_mode = true;

  ///
  /// API PROPS
  ///
  const allowedDomains = [
    "letstay.co.il", // => api.letstay.co.il
    "letstay.co", // => api.letstay.co
  ];

  const allowedDomain = (hostname) => {
    const domain = allowedDomains.find((d) => hostname.includes(d));
    return domain;
  }

  // Override for 
  const callerDomain = document.location.hostname;
  const domain = allowedDomain(callerDomain);
  if (domain) {
    this.api_urls = {
      [API_SERVICES.OPS]: 'https://api.' + domain,
      [API_SERVICES.HOTELS]: 'https://api.' + domain,
    };
  } else {
      this.api_urls = {
        [API_SERVICES.OPS]: process.env.REACT_APP_API_OPS_URL,
        [API_SERVICES.HOTELS]: process.env.REACT_APP_API_HOTELS_URL
      };
    }

  // Room mapping service api
  this.rms_url = process.env.REACT_APP_RMS_URL;

  this.toggleDevMode = (function () {
    this.dev_mode = !this.dev_mode;
  });

  this.blockDevMode = (function () {
    this.localhost = false;
    this.staging = false;
    this.research = false;
    // this.prod = true;
    this.dev_mode = false;
  });
    
  this.auto_fill = false;
  this.force_auto_fill = (process.env.REACT_APP_FORCE_AUTO_FILL || '').toLowerCase() === 'true';

  this.city_search_limit = 20;
}

export default new Config();
