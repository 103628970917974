import { useState, useEffect } from 'react';
import _ from 'lodash';
import { withRouter } from '@/utils/withRouter';

import ProfileStore from '@/stores/ProfileStore';
import ManagementStore from '@/stores/ManagementStore';
import ManagementActions from '@/actions/ManagementActions';
import ManagementConstants from '@/constants/ManagementConstants';

import TitleManager from '@/utils/TitleManager';

import NoTripsMessage from './trip-table/NoTripsMessage';
import TripTableLoading from './trip-table/TripTableLoading';
import TripTableHeader from './trip-table/TripTableHeader';
import TripTable from './trip-table/TripTable';

const TripTablePage = ({ navigate }) => {
	const [profile, setProfile] = useState(ProfileStore.getProfile());
	const [trips, setTrips] = useState(ManagementStore.getTripRequests());
	const [status, setStatus] = useState(ManagementConstants.STATUS.BUSY);
	const [hovered_row, setHoveredRow] = useState(null);
	let _refreshInterval_ = null;

	useEffect(() => {
		window.scrollTo(0, 0);
		const refreshIntervalId = setTimeout(() => {
			startRefreshInterval();
		}, 2500);

		ManagementStore.addChangeListener(_onChange);
		ManagementActions.getTripRequests();

		return () => {
			clearTimeout(refreshIntervalId);
			ManagementStore.removeChangeListener(_onChange);
			ManagementActions.clearPreview();
			stopRefreshInterval();
		};
	}, []);

	useEffect(() => {
		const buildTitle = () => {
			const firstName = _.get(profile, 'first_name', '');
			const companyName = _.get(profile, 'company.name', '');

			if (profile.travel_manager && companyName) {
				TitleManager.buildTitleStartsWith(`${companyName}'s Trips`);
			} else if (firstName) {
				TitleManager.buildTitleStartsWith(`${firstName}'s Trips`);
			} else {
				TitleManager.buildTitleStartsWith(`Trips`);
			}
		};

		buildTitle();
	}, [profile]);

	const _onChange = () => {
		setProfile(ProfileStore.getProfile());
		setTrips(ManagementStore.getTripRequests());
		setStatus(ManagementStore.getTripRequestsStatus());
	};

	const startRefreshInterval = () => {
		stopRefreshInterval();
		_refreshInterval_ = setInterval(() => {
			if (!document.hidden) ManagementActions.getTripRequests();
		}, 35000);
	};

	const stopRefreshInterval = () => {
		if (_refreshInterval_) {
			clearInterval(_refreshInterval_);
			_refreshInterval_ = null;
		}
	};

	const handleOnCellClick = (rowNumber) => {
		let record = trips[rowNumber];
		ManagementActions.updateTripRequestSource(ManagementConstants.SOURCES.TRIPS);
		setTimeout(() => {
			navigate('/trip/' + record.id);
		}, 0);
	};

	const handleOnRowHover = (rowNumber) => {
		let record = trips[rowNumber];
		ManagementActions.previewTrip(record);
		setHoveredRow(rowNumber);
	};

	const handleNewTripOnClick = () => {
		ManagementActions.resetWizardDestinations();
		navigate('/' + ManagementConstants.SOURCES.WIZARD);
	};

	const handleOngoingTripsOnClick = () => {
		navigate('/' + ManagementConstants.SOURCES.ONGOING_TRIPS);
	};

	return (
		<div className="trip-table-page">
			<TripTableHeader
				profile={profile}
				status={status}
				onNewTripClick={handleNewTripOnClick}
				onOngoingTripsClick={handleOngoingTripsOnClick}
			/>
			{status === ManagementConstants.STATUS.BUSY ? (
				<TripTableLoading />
			) : trips.length > 0 ? (
				<TripTable
					trips={trips}
					onCellClick={handleOnCellClick}
					onRowHover={handleOnRowHover}
					hoveredRow={hovered_row}
				/>
			) : (
				<NoTripsMessage />
			)}
		</div>
	);
};

export default withRouter(TripTablePage);
