import FirebaseChangeTypes from './Consts';
import _ from 'lodash';

function tryParse(data) {
    try {
        return JSON.parse(data);
    }
    catch (e) {
        console.warn(`issue parsing data: ${data}, e: ${e}`);
        return undefined;
    }
}

// Snapshot params should look like: 
// {
//     field_name: {
//         json_parse,
//         should_flatten,
//     }

function onMultiSnapshotWrapper(onData, snapshot_params, prefix_message, timestamp) {
    return (snapshot) => {
        const subcollections = {};
        // snapshot.forEach((doc) => subcollections.push(doc.data()));
        const changes = snapshot.docChanges();
        changes.forEach((change) => {
            // console.log(change.type);
            switch (change.type) {
                case FirebaseChangeTypes.ADDED:
                case FirebaseChangeTypes.MODIFIED: {
                    const data = change.doc.data();
                    Object.entries(data).map(([key, value]) => {
                        subcollections[key] = subcollections[key] || [];
                        subcollections[key].push(value);
                    });
                    break;
                }
                case FirebaseChangeTypes.REMOVED:
                default:
                    // TODO:
                    break;
            }
        });

        const out = {};
        Object.entries(subcollections).forEach(([field_name, subcollection]) => {
            let data = {};
            const field_options = snapshot_params[field_name];
            if (field_options) {
                const parse_function = field_options.json_parse ? tryParse : _.identity;
                if (field_options.should_flatten) {
                    data = _.flatMap(subcollection, (data) => parse_function(data));
                } else {
                    data = parse_function(data);
                }
            } else {
                data = subcollection;
            }
            out[field_name] = data;
        });
        return onData(out);
    }
}

export default onMultiSnapshotWrapper;