import withStyles from '@mui/styles/withStyles';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import classNames from 'classnames';

import PropTypes from 'prop-types';

import React from 'react';
import SearchActions from '../../actions/SearchActions';
import SearchStore from '../../stores/SearchStore';
import ProfileStore from '../../stores/ProfileStore';
import _ from 'lodash';

const styles = (theme) => ({
	companies: {
		display: 'flex',
		alignItems: 'center',
		marginTop: 30,
		paddingLeft: 8,
		position: 'relative',
		zIndex: -1,
		float: 'left',
		paddingRight: 24,
	},
	bookings: {
		marginRight: 20,
	},
	font: {
		fontSize: 18,
		color: theme.palette.white,
		textShadow: '0 0 13px rgba(0,0,0,.4)',

		fontFamily: 'Lato',
		fontWeight: 500,
		lineHeight: '23px',
		letterSpacing: 0,
		textAlign: 'left',
	},
	fontDark: {
		// fontSize: 18,
		color: theme.palette.black.main,

		fontFamily: 'Lato',
		fontSize: 15,
		fontWeight: 700,
		lineHeight: '18px',
		letterSpacing: 0,
		textAlign: 'left',
	},
});

class CompaniesAutoComplete extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			contract_id: SearchStore.getContractId(),
			profile: ProfileStore.getProfile(),
		};

		this._onChange = this._onChange.bind(this);
		this.handleCompanyChange = this.handleCompanyChange.bind(this);
		this.getDefaultValue = this.getDefaultValue.bind(this);

		const default_contract = SearchStore.getDefaultContractId(this.state.profile);
		this.state.default_contract_id = _.get(default_contract, '_id', '');
	}

	_onChange() {
		this.setState({ contract_id: SearchStore.getContractId() });
	}

	componentDidMount() {
		SearchStore.addChangeListener(this._onChange);
	}

	componentWillUnmount() {
		SearchStore.removeChangeListener(this._onChange);
	}

	handleCompanyChange(e, value) {
		let { profile } = this.state;
		if (profile.agent && Array.isArray(profile.agency_contracts)) {
			let search_for_contract;
			if (value) {
				search_for_contract = profile.agency_contracts.find((c) => c && c._id === value._id);
			} else {
				search_for_contract = profile.agency_contracts[0];
			}

			SearchActions.updateSearchForCompany(search_for_contract);
		}
	}

	getContractLabel(contract) {
		return contract.company.name;
		//return `${contract.company.name} ${(contract.pricing_config && !contract.pricing_config.margin_addition_fixed && contract.pricing_config.margin_percent_fixed === 1) ? "(Net)" : ''}`;
	}

	filterOptions() {
		return createFilterOptions({
			matchFrom: 'start',
			stringify: (contract) => contract.company.name,
		});
	}

	getDefaultValue() {
		let { profile } = this.state;
		let { contract_id, default_contract_id } = this.state;

		const _contract_id = contract_id || default_contract_id;

		if (_contract_id) {
			return profile.agency_contracts.find((c) => c && c._id === _contract_id);
		} else {
			return profile.agency_contracts[0];
		}
	}

	render() {
		let { classes } = this.props;
		let { profile } = this.state;

		return (
			<div className={classes.companies}>
				<div className={classNames(classes.bookings, classes.font)}>Search for</div>
				<Autocomplete
					value={this.getDefaultValue()}
					className="companies-autocomplete"
					options={profile.agency_contracts}
					getOptionLabel={(contract) => this.getContractLabel(contract)}
					filterOptions={this.filterOptions()}
					onChange={this.handleCompanyChange}
					disableClearable={true}
					size="small"
					classes={{
						input: classes.fontDark,
						option: classes.fontDark,
					}}
					renderInput={(params) => <TextField {...params} label="" variant="standard" fullWidth />}
				/>
			</div>
		);
	}
}

CompaniesAutoComplete.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CompaniesAutoComplete);
