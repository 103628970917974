import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ContactCardLight from '../ContactCardLight.react';

class RoomContactsLight extends React.Component {
	getContactCards() {
		let { reservation, count, createTravelerStatuses, disabled } = this.props;
		const { private_travel } = reservation;

		// For private travelers, only one guest per room should be displayed
		const guestsPerRoom = private_travel ? 1 : reservation.deal.guestsPerRoom;

		return _.times(guestsPerRoom, (i) => {
			const idx = guestsPerRoom * (count - 1) + i;
			const travelerCountPerRoom = private_travel ? '' : i + 1;
			const mainGuest = count === 1 && private_travel;

			return (
				<ContactCardLight
					key={`room-${count}-contact-${i + 1}`}
					reservation={reservation}
					contactIndex={idx}
					roomNumber={count}
					mainGuest={mainGuest}
					travelerCountPerRoom={travelerCountPerRoom}
					createTravelerStatus={createTravelerStatuses[idx]}
					{...(disabled ? { disabled: true } : {})}
					autoFill={this.props.autoFill}
				/>
			);
		});
	}

	getRoomNumber() {
		let { reservation, count } = this.props;
		return reservation.deal.rooms > 1 ? '#' + count : null;
	}

	getRoomName() {
		let { reservation } = this.props;
		const roomName = _.get(reservation, 'deal.roomMappingData.debug.room_name') || reservation.deal.description;
		return roomName;
	}

	render() {
		const { sendEmailVoucherOnlyRender, sendEmailConfirmationRender, reservation, count } = this.props;
		return (
			<React.Fragment>
				{reservation.private_travel && (
					<h3 className="private-travel-guest-title">
						{count === 1 ? 'Main Guest' : count === 2 ? 'Extra Rooms' : ''}
					</h3>
				)}

				<h4 className="room-name">
					Room {this.getRoomNumber()}: {this.getRoomName()}
				</h4>
				<div className="contacts">
					{this.getContactCards()}
					{sendEmailVoucherOnlyRender}
					{sendEmailConfirmationRender}
				</div>
			</React.Fragment>
		);
	}
}

RoomContactsLight.propTypes = {
	reservation: PropTypes.any,
	count: PropTypes.number,
	createTravelerStatuses: PropTypes.array,
	disabled: PropTypes.bool,
};

export default RoomContactsLight;
