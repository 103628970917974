import _ from 'lodash';

function extractWordsAndNumbers(inputString) {
    const wordNumberRegex = /[a-zA-Z]+|\d+/g;
    const matches = inputString.match(wordNumberRegex);
    // In case of an empty inputString matches will return null 
    return matches?.filter(function (match) {
        return match !== null && match.trim() !== '' && match !== 'with';
    });
}

function isArraysEqual(array1, array2) {
    if (!array1 || !array2) {
        // Don't start handling nully edge cases
        return false;
    }
    if (array1.length !== array2.length) {
        return false;
    }

    const sortedArray1 = array1.slice().sort();
    const sortedArray2 = array2.slice().sort();

    for (let i = 0; i < sortedArray1.length; i++) {
        if (sortedArray1[i] !== sortedArray2[i]) {
            return false;
        }
    }

    return true;
}

export function getRoomData(hotel, deal) {
    const dealName = deal.room || deal.description || _.get(deal, 'details.room_description_original') || deal.name;
    if (hotel.rooms) {
        return _.find(hotel.rooms, { id: deal.room_id }) ||
            _.find(hotel.rooms, function (room) {
                return isArraysEqual(extractWordsAndNumbers(room.name.toLowerCase()), extractWordsAndNumbers(dealName.toLowerCase()))
            });
    } else {
        return null;
    }
}