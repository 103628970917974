import _ from 'lodash';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';

import { Checkbox, FormControlLabel, Select, FormControl, MenuItem, IconButton } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';

import { SPECIAL_REQUEST_SUGGESTIONS } from 'arbitrip-common/general/constants';
import ReservationActions from '@/actions/ReservationActions';

import CheckboxActiveIcon from '@/components/pages/results/filters/components/CheckboxActiveIcon';
import CheckboxInactiveIcon from '@/components/pages/results/filters/components/CheckboxInactiveIcon';

const SpecialRequests = ({ isChecked, handleChecked, reservationData, shouldLockForm }) => {
	const handleTextAreaChange = (e) => {
		ReservationActions.updateSpecialRequests(e.target.value);
	};

	const handleSelect = (e, requestKey) => {
		const optionKey = e.target.value;
		const options = SPECIAL_REQUEST_SUGGESTIONS[requestKey].options;
		const selectedOption = options.find((o) => o.key === optionKey);

		if (selectedOption) {
			const suggestionSpecialRequest = _.get(reservationData, 'specialRequestsFromSuggestions', {});
			ReservationActions.updateSpecialRequestsFromSuggestions({
				...suggestionSpecialRequest,
				[requestKey]: { key: optionKey, text: getTagText(selectedOption, requestKey) },
			});
		}
	};

	const clearSelection = (requestKey) => {
		const suggestionSpecialRequest = _.get(reservationData, 'specialRequestsFromSuggestions');
		delete suggestionSpecialRequest[requestKey];

		ReservationActions.updateSpecialRequestsFromSuggestions(suggestionSpecialRequest);
	};

	const transformOptionText = (option, requestKey) => {
		if (requestKey === 'ARRIVING_LATE') {
			const checkInDate = _.get(reservationData, 'deal.details.check_in');
			const nextDate = checkInDate ? checkInDate.clone().add(1, 'd') : checkInDate;
			return option.addNextDate && nextDate
				? `${option.textValue} (${nextDate.format('Do MMM')})`
				: option.textValue;
		}
		return option.textValue;
	};

	const getTagText = (option, requestKey) => {
		if (requestKey === 'ARRIVING_LATE') {
			return `Arriving late between ${transformOptionText(option, requestKey)}`;
		}
		return option.textValue;
	};

	return (
		<div className="special-requests">
			<div className="title">Special requests</div>
			<div className="checkbox-container">
				<FormControlLabel
					control={
						<Checkbox
							disabled={shouldLockForm}
							checked={isChecked}
							onChange={handleChecked}
							icon={<CheckboxInactiveIcon />}
							checkedIcon={<CheckboxActiveIcon />}
						/>
					}
					label="Have a Special Request?"
				/>
			</div>
			{isChecked && (
				<div>
					<div className="disclaimer">
						<Trans i18nKey="SpecialRequestsCantBeGuaranteed">
							Please note that your request is subject to <strong>hotel approval</strong> and
							availability. Additional charges may apply
						</Trans>
					</div>
					<div className="textarea-container">
						<label className="subtitle" htmlFor="special-requests-input">
							Add your special request
						</label>
						<div className="textarea-wrap">
							<textarea
								id="special-requests-input"
								className={
									!_.isEmpty(reservationData?.specialRequestsFromSuggestions) ? 'expanded' : ''
								}
								disabled={shouldLockForm}
								placeholder="Write here..."
								value={reservationData.specialRequests}
								onChange={handleTextAreaChange}
							/>
							{!_.isEmpty(reservationData?.specialRequestsFromSuggestions) && (
								<div className="tags-wrap">
									{Object.keys(reservationData.specialRequestsFromSuggestions).map((key) => {
										const specialRequest = reservationData.specialRequestsFromSuggestions[key];
										return (
											<div key={key} disabled={shouldLockForm} className="tag">
												<span>{specialRequest.text}</span>
												<IconButton
													className="clear-selection-button"
													aria-label="clear selection"
													onClick={() => clearSelection(key)}
												>
													<CloseOutlined />
												</IconButton>
											</div>
										);
									})}
								</div>
							)}
						</div>
					</div>
					{Object.keys(SPECIAL_REQUEST_SUGGESTIONS).map((key) => {
						const request = SPECIAL_REQUEST_SUGGESTIONS[key];
						const selectedRequest = reservationData?.specialRequestsFromSuggestions[key] || null;

						return (
							<div className="select-container" key={key}>
								<div className={`subtitle ${selectedRequest ? 'disabled' : ''}`}>
									{request.dialogText}
								</div>
								<FormControl
									size="small"
									disabled={!!selectedRequest || shouldLockForm}
									sx={{ width: 380 }}
								>
									<Select
										value={selectedRequest?.key || ''}
										onChange={(e) => handleSelect(e, key)}
										displayEmpty
										aria-label={request.dialogText}
									>
										<MenuItem value="" disabled hidden>
											{request.selectPlaceHolder}
										</MenuItem>
										{request.options.map((option) => (
											<MenuItem value={option.key} key={option.key}>
												{transformOptionText(option, key)}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};

export default SpecialRequests;
