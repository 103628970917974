import $ from 'jquery';

import TitleManager from '../../utils/TitleManager';
import getLogoBasedOnSubDomain from 'arbitrip-common/client/utils/dynamicLogo';

/**
 * Created by MrJossef on 3/28/16.
 */
import React from 'react';
import createClass from 'create-react-class';

import moment from 'moment';
import DocumentTitle from '../general/DocumentTitle.react';

const holidays = {
	ROSH_HASHANA: 'rosh',
	XMAS: 'xmas',
};

// var _messages = [
//   "The 'Arbi' in Arbitrip stands for arbitrage",
//   "There are 100 calories in a single tablespoon of Nutella",
//   "Book hotels upfront - You'll improve your chances for finding you a better deal",
//   "Watch out for non refundable rates - They contain cancellation penalties",
//   "Spread the word - Help your friends save on travel costs and tell them about Arbitrip"
// ];
let _messages = [
	'According to the American Hotel & Lodging Association (AHLA), in 1940, the average hotel room rate was just $3.21.',
	"In 2015, Guinness World Records listed the First World Hotel in Genting Highlands, Malaysia, as the world's largest hotel with a total of 7,351 rooms.",
	'Standing at 1,389 feet tall, the Trump International Hotel & Tower Chicago is the tallest hotel in the US.',
	"Book hotels upfront - You'll improve your chances for price drops.",
	'Watch for non refundable rates - They contain cancellation penalties.',
	'Spread the word - Help your friends save on travel costs and tell them about Arbitrip.',
];

const _Holidays = {
	[holidays.ROSH_HASHANA]: {
		from: 'Sep 24',
		to: 'Oct 4',
	},
	[holidays.XMAS]: {
		from: 'Dec 17',
		to: 'Dec 31',
	},
};

function getHoliday() {
	// holiday logic here..
	let NOW = moment();
	for (let holiday in _Holidays) {
		let from = moment(_Holidays[holiday].from, 'MMM D'),
			to = moment(_Holidays[holiday].to, 'MMM D');
		if (NOW.isAfter(from) && NOW.isBefore(to)) return holiday;
	}
}

function setHolidayCustom(holiday) {
	switch (holiday) {
		case holidays.ROSH_HASHANA:
			_messages.splice(2, 0, 'Shana Tova!<br/>From your friends at Arbitrip');
			break;

		case holidays.XMAS:
			_messages.splice(2, 0, 'Happy Holidays!<br/>From your friends at Arbitrip');
			break;

		default: // Do nothing
	}
}

let _lastRand = -1;
let _interval;
const _holiday = getHoliday();
if (_holiday) {
	setHolidayCustom(_holiday);
}

function randomMessage() {
	let _rand = Math.floor(Math.random() * _messages.length);
	while (_rand === _lastRand) {
		_rand = Math.floor(Math.random() * _messages.length);
	}
	if (_lastRand === -1 && _holiday) {
		_rand = 2;
	}
	_lastRand = _rand;
	return _messages[_rand];
}

const title = TitleManager.buildTitleEndsWith('finding you the Best Hotels!', false);
const LoadingComponent = createClass({
	displayName: 'LoadingComponent',

	animateMessages: function () {
		const $msg = document.querySelector('#loading-message .message');
		setTimeout(function () {
			window
				.Velocity($msg, { opacity: 0.01 }, { duration: 1 }) // eslint-disable-line
				.then(function () {
					$msg.innerHTML = randomMessage();
					window.Velocity($msg, { opacity: 1 }, { duration: 500 }); // eslint-disable-line
					//start the interval
					_interval = setInterval(function () {
						window
							.Velocity($msg, { opacity: 0.01 }, { duration: 500 }) // eslint-disable-line
							.then(function () {
								$msg.innerHTML = randomMessage();
								window.Velocity($msg, { opacity: 1 }, { duration: 500 }); // eslint-disable-line
							});
					}, 6500);
				});
		}, 100);
	},

	// Add change listeners to stores
	componentDidMount: function () {
		$('#intercom-container').hide();
		if (this.props.pending) {
			this.animateMessages();
		}
	},

	componentDidUpdate: function (prevProps) {
		if (!prevProps.pending && this.props.pending) {
			this.animateMessages();
		} else if (prevProps.pending && !this.props.pending) {
			if (_interval) {
				clearInterval(_interval);
			}
		}
	},

	componentWillUnmount: function () {
		setTimeout(function () {
			$('#intercom-container').show();
		}, 500);
		clearInterval(_interval);
	},

	getHolidayMask: function (holiday) {
		switch (holiday) {
			case holidays.ROSH_HASHANA:
				return [
					<img key="a" src="/img/apple.png" alt="Shana Tova" className="apple-leaf" />,
					// <img key='h' src="/img/honey.png" alt="Shana Tova" className="honey" />,
				];
			case holidays.XMAS:
				return <img src="/img/santacornerhat.png" alt="Happy Holidays" className="santa-hat" />;
			default:
				return null;
		}
	},

	// Render our child components, passing state via props
	render: function () {
		const logo = getLogoBasedOnSubDomain();
		if (this.props.pending) {
			const HOLIDAY_MASK = this.getHolidayMask(_holiday);
			if (_holiday) {
				console.log(_holiday, HOLIDAY_MASK);
			}
			const rosh = _holiday === holidays.ROSH_HASHANA;
			return (
				<DocumentTitle title={title}>
					<div className="loading" id="app_loader">
						<div className="logo-container">
							<span className={`logo hvr-bob${rosh ? ' rosh' : ''}`}></span>
							{HOLIDAY_MASK}
						</div>
						<div className="messages" id="loading-message">
							<p className="message"></p>
						</div>
					</div>
				</DocumentTitle>
			);
		} else {
			return (
				<DocumentTitle title={TitleManager.defaults()}>
					<div className="loading" id="init_loader">
						<div className="messages" id="loading-message">
							<p className="message">
								<i className="fa fa-spin fa-spinner" title="Loading Arbitrip" />
							</p>
						</div>
						<div className="banner">
							<img src={logo} alt="" className="logo" />
						</div>
					</div>
				</DocumentTitle>
			);
		}
	},
});

export default LoadingComponent;
