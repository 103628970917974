const _ = require('lodash');
const Routes = require('../constants').CLIENT_ROUTES;
const SEARCH_FIELDS = require('../constants').SEARCH_FIELDS;
const HOTEL_SEARCH_URL_FIELDS = require('../constants').HOTEL_SEARCH_URL_FIELDS;
const UrlParams = require('../utils/UrlParams');
const { objectifyPointOfInterestId } = require('../../client/utils/PointOfInterestUtils');

const hotel_name_delimiter = '-';
const hotel_name_regex_slash = new RegExp(/\//g);

const buildRoute = (route, ...params) => '/' + route + (params.length ? '/' + params.join('/') : '');
const buildLink = (path, params) => path + (params ? '?' + params : '');

const pickObjectKeys = (obj, keys) => (Array.isArray(keys) ? keys : [keys])
  .map(k => k in obj ? { [k]: obj[k] } : {})
  .reduce((res, o) => Object.assign(res, o), {});

const buildSearchParams = (search_terms, search_token, approved_fields = SEARCH_FIELDS) => {
  const place_id = _.get(search_terms, 'destination.place.place_id');
  const hotel_id = _.get(search_terms, 'destination.place.hotel_id');

  const poi_id = objectifyPointOfInterestId(hotel_id, place_id);

  const search_params = Object.assign(poi_id, { search_token }, search_terms);

  return UrlParams.objToURLParams(pickObjectKeys(search_params, approved_fields));
};

////////////////////////////////////////////////////////////////////////////////

const buildSearchRoute = (search_terms) => {
  if (search_terms) {
    const path = buildRoute(Routes.SEARCH);
    const params = buildSearchParams(search_terms);

    return buildLink(path, params);
  }
  return buildRoute(Routes.SEARCH);
};

const buildResultsRoute = (search_terms, search_token) => {
  if (search_terms) {
    const path = buildRoute(Routes.RESULTS);
    const params = buildSearchParams(search_terms, search_token);

    return buildLink(path, params);
  }
  return buildRoute(Routes.RESULTS);
};

const reformatHotelName = (hotelName) => {
  // reformats hotel_name replacing spaces with hotel_name_delimiter (avoiding duplicates)
  hotelName = hotelName
    .split(' ')
    .filter(s => s && (s !== hotel_name_delimiter))
    .join(hotel_name_delimiter);

  // we want hotel_name to look similair to original
  hotelName = hotelName.replace(hotel_name_regex_slash, hotel_name_delimiter);

  // fallback for any other html characters
  hotelName = encodeURIComponent(hotelName);

  return hotelName;
}

const buildHotelRoute = (hotel, search_token, search_terms, approved_fields = SEARCH_FIELDS) => {
  const hotel_id = _.get(hotel, 'id') || _.get(hotel, '_id');
  let hotel_name = _.get(hotel, 'name');

  if (!hotel_id || !hotel_name) {
    return '';
  }

  hotel_name = reformatHotelName(hotel_name);

  if (search_terms) {
    ////////////////////////////////////////////////////////////////////////////////
    // fallback for share basket //
    ///////////////////////////////
    if (!_.get(search_terms, 'destination.place.place_id') && _.get(search_terms, 'place_id')) {
      _.set(search_terms, 'destination.place.place_id', search_terms.place_id);
    }

    if (!_.get(search_terms, 'destination.place.hotel_id') && _.get(search_terms, 'hotel_id')) {
      _.set(search_terms, 'destination.place.hotel_id', search_terms.hotel_id);
    }
    ////////////////////////////////////////////////////////////////////////////////

    // fallback for no place_id nor hotel_id in search terms
    if (!_.get(search_terms, 'destination.place.place_id') && !_.get(search_terms, 'destination.place.hotel_id')) {
      _.set(search_terms, 'destination.place.hotel_id', hotel_id);
    }

    if (search_terms.rooms > 4) {
      _.set(search_terms, '8_rooms', true);
    }

    const path = buildRoute(Routes.HOTEL, hotel_name, hotel_id);
    const params = buildSearchParams(search_terms, search_token, approved_fields);

    return buildLink(path, params);
  }

  return buildRoute(Routes.HOTEL, hotel_name, hotel_id);
};

// eslint-disable-next-line no-undef
const buildHotelSearchURL = (hotel, search_terms) => window.location.origin
  + buildHotelRoute(hotel, null, search_terms, HOTEL_SEARCH_URL_FIELDS);

const buildReservationRoute = () => buildRoute(Routes.RESERVATION);

const buildTripsRoute = () => buildRoute(Routes.TRIPS);
const buildTripRoute = (tripId) => buildRoute(Routes.TRIP, tripId);

////////////////////////////////////////////////////////////////////////////////

module.exports = {
  buildSearchRoute,
  buildResultsRoute,
  buildHotelRoute,
  buildHotelSearchURL,
  buildReservationRoute,
  buildTripRoute,
  buildTripsRoute,
};
