import _ from "lodash";

const { AGENT_PRICING_METHOD } = require('arbitrip-common/client/constants/ReservationConstants');

// const MARGIN_PERCENTAGE_LIMIT = 2;
const MARGIN_PERCENTAGE_LIMIT = 1.49;

function getMarginMultiplierByPrices(clientPrice, netPrice) {
    return (clientPrice >= (MARGIN_PERCENTAGE_LIMIT * netPrice))
        ? (clientPrice / netPrice)
        : 2 - (netPrice / clientPrice);
}

function getMarginMultiplierByMarginPercentage(marginPercentage = 0) {
    return 1 + (marginPercentage / 100);
}

function getMarginPercentageByMarginMultiplier(marginMultiplier = 1) {
    return 100 * (marginMultiplier - 1);
}

function getClientPrice(netPrice, marginMultiplier = 1, marginAddition = 0) {
    return (marginMultiplier >= MARGIN_PERCENTAGE_LIMIT)
        ? (netPrice * marginMultiplier) + marginAddition
        : (netPrice / (2 - marginMultiplier)) + marginAddition;
}

class ClientPriceService {
    static isEnabled(profile = {}, contractClientCompanyId, deal) {
        const company_id = _.get(profile, 'company.id');
        const is_post_pay = _.get(deal, 'details.post_pay', false);
        return profile.agent &&
            !is_post_pay &&
            (!contractClientCompanyId || (contractClientCompanyId === company_id));
    }

    static getAgentPricingBySelfContract(deal, selfContract) {
        const {
            net_price_total,
            nights = 1
        } = deal;

        const {
            currency,
            margin_addition_fixed = 0,
            margin_percent_fixed = 1 // not in actual % (e.g. not 5% but 1.05)
        } = _.get(selfContract, 'pricing_config', {});

        let agent_pricing = {
            currency,

            pricing_config: {
                currency,
                margin_addition_fixed,
                margin_percent_fixed
            },

            pricing_method: AGENT_PRICING_METHOD.MARGIN_PERCENTAGE,

            net_price_total,
            net_price_per_night: net_price_total / nights
        };

        agent_pricing.client_price_total = getClientPrice(net_price_total, margin_percent_fixed, margin_addition_fixed);
        agent_pricing.client_price_per_night = agent_pricing.client_price_total / nights;

        const _margin_multiplier = getMarginMultiplierByPrices(agent_pricing.client_price_total, net_price_total);
        agent_pricing.margin_percentage = getMarginPercentageByMarginMultiplier(_margin_multiplier);

        agent_pricing.absolute_margin_total = agent_pricing.client_price_total - net_price_total;
        agent_pricing.absolute_margin_per_night = agent_pricing.client_price_per_night - agent_pricing.net_price_per_night;

        return ClientPriceService.getRoundedAgentPricing(deal, agent_pricing);
    }

    static getAgentPricingByMarginPercentage(deal, agentPricing, marginPercentage) {
        let agent_pricing = _.cloneDeep(agentPricing);

        agent_pricing.pricing_method = AGENT_PRICING_METHOD.MARGIN_PERCENTAGE;
        const _margin_multiplier = getMarginMultiplierByMarginPercentage(marginPercentage);

        agent_pricing.margin_percentage = marginPercentage;

        agent_pricing.client_price_total = getClientPrice(agent_pricing.net_price_total, _margin_multiplier);
        agent_pricing.client_price_per_night = agent_pricing.client_price_total / deal.nights;
        // console.log('client_price_total', agent_pricing.client_price_total, '|||', agent_pricing.client_price_per_night);

        agent_pricing.absolute_margin_total = agent_pricing.client_price_total - agent_pricing.net_price_total;
        agent_pricing.absolute_margin_per_night = agent_pricing.client_price_per_night - agent_pricing.net_price_per_night;

        return ClientPriceService.getRoundedAgentPricing(deal, agent_pricing);
    }

    static getAgentPricingByAbsoluteMargin(deal, agentPricing, absoluteMargin) {
        let agent_pricing = _.cloneDeep(agentPricing);

        agent_pricing.pricing_method = AGENT_PRICING_METHOD.ABSOLUTE_MARGIN;

        agent_pricing.absolute_margin_total = absoluteMargin;
        agent_pricing.absolute_margin_per_night = absoluteMargin / deal.nights;

        agent_pricing.client_price_total = agent_pricing.net_price_total + absoluteMargin;
        agent_pricing.client_price_per_night = agent_pricing.net_price_per_night + agent_pricing.absolute_margin_per_night;

        const _margin_multiplier = getMarginMultiplierByPrices(agent_pricing.client_price_total, agent_pricing.net_price_total)
        agent_pricing.margin_percentage = getMarginPercentageByMarginMultiplier(_margin_multiplier);

        return ClientPriceService.getRoundedAgentPricing(deal, agent_pricing);
    }

    static getRoundedAgentPricing(deal, agentPricing) {
        let agent_pricing = _.cloneDeep(agentPricing);

        agent_pricing.client_price_per_night = Math.round(agent_pricing.client_price_per_night);
        agent_pricing.client_price_total = deal.nights * agent_pricing.client_price_per_night;

        agent_pricing.absolute_margin_per_night = agent_pricing.client_price_per_night - agent_pricing.net_price_per_night;
        agent_pricing.absolute_margin_total = agent_pricing.client_price_total - agent_pricing.net_price_total;

        const _margin_multiplier = getMarginMultiplierByPrices(agent_pricing.client_price_total, agent_pricing.net_price_total);
        agent_pricing.margin_percentage = getMarginPercentageByMarginMultiplier(_margin_multiplier);

        return agent_pricing;
    }
}

export default ClientPriceService;