import Ajax from './Ajax';
import ReservationStore from '../stores/ReservationStore';
import PaymentStore from '../stores/PaymentStore';
import PaymentActions from '../actions/PaymentActions';
import ReservationActions from '@/actions/ReservationActions';

import { PAYMENT_STATUS, PAYMENT_CREDIT_CARD_ERROR_CODES } from 'arbitrip-common/general/constants';

const MILLISECONDS = 1;
const SECONDS = 1000 * MILLISECONDS;
const pollTimeout = 2.6 * SECONDS;

var poller = null;
var status = 'stopped';

export default {
	startPaymentStatusPolling: function () {
		var _self = this;
		status = 'running';
		if (poller) return;
		(function poll() {
			if (status === 'stopped') {
				return;
			}
			console.log('poll run - sending http req..');
			Ajax.samplePaymentStatus(ReservationStore.getReservationData(), PaymentStore.getPaymentData())
				.done(function (res) {
					const {
						paymentStatus: status,
						error_status_code,
						payment_url_ttl,
						threeds_expedia_additional_information,
						supplier_ref,
						allow_retry,
					} = res;
					if (payment_url_ttl) {
						const now = new Date();
						if (now > payment_url_ttl) {
							// TODO! Redirect to initPayment to generate another URL
							console.log('url ttl has expired');
						}
					}
					// TODO: if not what we're looking for - setTimeout(doPoll, 5000);
					// console.log("doPoll - done : ", res);
					console.log('url link is valid till: ', payment_url_ttl);
					switch (status) {
						case PAYMENT_STATUS.NA: {
							// TODO: (not very good)
							PaymentActions.markPaymentAsFailed('not_exists');
							break;
						}
						case PAYMENT_STATUS.TOKENIZED_WITHOUT_BILLING:
						case PAYMENT_STATUS.INITIALIZED: {
							console.log('poll - no changes, retrying..');
							return (poller = setTimeout(poll, pollTimeout));
						}
						case PAYMENT_STATUS.TOKENIZED_WITHOUT_BILLING_WITH_PAYMENT_SESSION: {
							PaymentActions.save3DSInformation(threeds_expedia_additional_information);
							break;
						}
						case PAYMENT_STATUS.J5:
						case PAYMENT_STATUS.CHARGE_LATER: {
							// TODO! Take from constants
							PaymentActions.markPaymentAsCharged();
							break;
						}
						case PAYMENT_STATUS.POSTPONED: {
							PaymentActions.markPaymentAsCharged();
							break;
						}
						case PAYMENT_STATUS.PENDING_MANUAL_BILLING: {
							PaymentActions.markPaymentAsCharged();
							break;
						}
						case PAYMENT_STATUS.BILLED: {
							PaymentActions.markPaymentAsCharged();
							if (supplier_ref) {
								ReservationActions.updateSupplierRef(supplier_ref);
							}
							break;
						}
						case PAYMENT_STATUS.CANCELLED: {
							// TODO: (not very good)
							PaymentActions.markPaymentAsFailed('cancelled');
							break;
						}
						case PAYMENT_STATUS.ERROR: {
							if (allow_retry) {
								let message = 'An issue occurred with payment card';
								if (
									error_status_code ===
									PAYMENT_CREDIT_CARD_ERROR_CODES.CREDIT_CARD_NOT_SUPPORTED_LOYALTY_CARD
								) {
									message +=
										'. Payment is enabled only with Bank HaPoalim credit card (which has the bank logo)';
								}
								PaymentActions.setPaymentErrorMessage(message);
								ReservationActions.restartPaymentWithError();
								break;
							}
							// TODO: (not very good)
							console.log('We got error status code: ', error_status_code);
							if (
								error_status_code ===
								PAYMENT_CREDIT_CARD_ERROR_CODES.CREDIT_CARD_NOT_SUPPORTED_LOYALTY_CARD
							) {
								console.log('send a message of wrong loyalty card');
								// TODO! Redirect to credit frame page to generate another URL
								PaymentActions.markPaymentAsFailed('mismatched_loyalty');
							} else {
								PaymentActions.markPaymentAsFailed('error');
							}
							break;
						}
						default: {
							// Do nothing
							console.warn('Poller: unknown payment type', res.paymentStatus);
							break;
						}
					}

					_self.stopPaymentStatusPolling();
				})
				.fail(function (err) {
					// TODO: if not what we're looking for - setTimeout(doPoll, 5000);
					console.error('doPoll - fail : ', err);
				})
				.always(function (data) {
					// TODO: if not what we're looking for - setTimeout(doPoll, 5000);
					// console.log("doPoll - always : ", data);
					// pollTimoeout = setTimeout(doPoll, 1500);
				});
		})();
	},

	stopPaymentStatusPolling: function () {
		console.log('poll - stop polling');
		status = 'stopped';
		poller = null;
	},
};

// export default {
//
//   timer: 0,
//
//   poll: function(callback) {
//     timer = setTimeout(function() {
//       $.ajax({ url: "server", success: function(data){
//         //Update your dashboard gauge
//         salesGauge.setValue(data.value);
//
//         //Setup the next poll recursively
//         poll();
//       }, dataType: "json"});
//     }, 30000);
//   },
//
//   stop: function() {
//     this.timer = 0;
//   }
// };
//
// (function doPoll() {
//   Ajax.samplePaymentStatus(_self.state.reservationData, _self.state.paymentData)
//     .done(function(res) {
//       // TODO: if not what we're looking for - setTimeout(doPoll, 5000);
//       console.log("doPoll - done : ", res);
//
//       switch (res.paymentStatus) {
//         case "not_exists":
//           // TODO: (not very good)
//           PaymentActions.markPaymentAsFailed();
//           break;
//         case "initialized":
//           pollTimoeout = setTimeout(doPoll, 1500);
//           break;
//         case "J5":
//           PaymentActions.markPaymentAsCharged();
//           break;
//         case "postponed":
//           PaymentActions.markPaymentAsCharged();
//           break;
//         case "pending_manual_billing":
//           PaymentActions.markPaymentAsCharged();
//           break;
//         case "billed":
//           PaymentActions.markPaymentAsCharged();
//           break;
//         case "cancelled":
//           // TODO: (not very good)
//           PaymentActions.markPaymentAsFailed();
//           break;
//         case "error":
//           // TODO: (not very good)
//           PaymentActions.markPaymentAsFailed();
//           break;
//       }
//     })
//     .fail(function(err) {
//       // TODO: if not what we're looking for - setTimeout(doPoll, 5000);
//       console.log("doPoll - fail : ", err);
//     })
//     .always(function(data) {
//       // TODO: if not what we're looking for - setTimeout(doPoll, 5000);
//       console.log("doPoll - always : ", data);
//       // pollTimoeout = setTimeout(doPoll, 1500);
//     });
// })();
