import React from 'react';
import createClass from 'create-react-class';
import moment from 'moment';
import Currencies from '../../../utils/Currencies';
import styles from '../../../utils/Styles';

import { Dialog, Button, Menu, IconButton, DialogTitle, DialogContent, DialogActions, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import BookingStatus from '../bookings/BookingStatus.react';

import _ from 'lodash';
import ManagementConstants from '../../../constants/ManagementConstants';
import ManagementActions from '../../../actions/ManagementActions';
import UrlParamsUtils from 'arbitrip-common/general/utils/UrlParams';
import ApiURLs from '../../../utils/ApiURLs';
// import ReservationActions from '../../../actions/ReservationActions';
import ReservationConstants from '../../../constants/ReservationConstants';

const date_format = 'MMM D';

const BookedAccommodation = createClass({
	displayName: 'BookedAccommodation',

	getInitialState: function () {
		return {
			menuOpen: false,
			anchorEl: null,
			cancel_reservation_modal_open: false,
		};
	},

	handleMouseEnter: function () {
		// this.setState({
		//   hovering: true
		// });

		let { onHover } = this.props;
		if (typeof onHover === 'function') {
			onHover();
		}
	},

	// handleMouseLeave: function () {
	//   this.setState({
	//     hovering: false
	//   });
	// },

	handleCancelReservationClick: function () {
		this.setState({ cancel_reservation_modal_open: true });
	},

	handleOpenCancelReservationModal: function (e) {
		this.setState({ cancel_reservation_modal_open: true });
	},

	handleCloseCancelReservationModal: function (e) {
		this.setState({ cancel_reservation_modal_open: false });
	},

	handleOnCancelClick: function () {
		console.log('Canceling booking: ', this.props.accommodation);
		// ReservationActions.cancelReservation(this.props.accommodation);
		ManagementActions.cancelTripHotelBooking(this.props.trip, this.props.accommodation);
		this.handleCloseCancelReservationModal();
	},

	handlePrintVoucherClick: function () {},

	handlePrintInvoiceClick: function () {},

	handleDetachClick: function () {},

	getVoucherPrintUrl: function (trip, accommodation) {
		let url = ApiURLs.VOUCHER_PRINT_TRIP.replace(':reservation_id', accommodation.id)
			.replace(':group_id', accommodation.group_id)
			.replace(':trip_id', trip.id);
		return url + '?ref=' + window.location.href + '&redirect=true';
	},

	getSearchUrlFromQuotedDeal: function (trip, accommodation, hotel, deal, search_terms) {
		if (!trip || !hotel || !deal || !deal.details || !search_terms || !search_terms.destination) {
			return '';
		}

		let payload = {
			rooms: deal.details.room_count,
			guests: deal.details.guest_count,
			management_trip_id: trip.id,
			management_trip_name: trip.name,
			check_in: deal.details.check_in,
			check_out: deal.details.check_out,
			place_id: _.get(search_terms, 'destination.place.place_id'),
			address: search_terms.destination.address,
			room_description: deal.details.room_description,
			travelers: JSON.stringify(accommodation.travelers),
			quote_id: accommodation.id,
			group_id: accommodation.group_id,
		};

		let params = UrlParamsUtils.objToURLParams(payload);
		let { name, _id } = hotel;
		let hotel_name = name
			.toLowerCase()
			.split(' ')
			.filter((s) => s && s !== '-')
			.join('-');

		return '/hotel/' + hotel_name + '/' + _id + '?' + params;
	},

	handleBookAQuotedDealClick: function () {
		let { trip, accommodation } = this.props;
		if (!accommodation) {
			return null;
		}

		let { hotel, deal, search_terms } = accommodation;
		let search_link = this.getSearchUrlFromQuotedDeal(trip, accommodation, hotel, deal, search_terms);
		window.open(search_link, '_blank');
	},

	handleRemoveQuote: function () {
		let { trip, accommodation, remove_trip_accommodation_status } = this.props;
		if (remove_trip_accommodation_status !== ManagementConstants.STATUS.BUSY) {
			ManagementActions.removeTripAccommodation(trip, accommodation);
		}
	},

	handleOnMouseEnter: function () {
		let { onHover } = this.props;
		if (typeof onHover === 'function') {
			onHover();
		}
	},

	render: function () {
		let { trip, accommodation, hovered, profile, previewed_booked_component } = this.props;
		const { menuOpen, anchorEl } = this.state;

		if (!accommodation) {
			return null;
		}

		let { hotel, deal } = accommodation;
		if (!hotel || !deal) {
			return null;
		}

		let check_in = deal.details && deal.details.check_in && moment(deal.details.check_in);
		let check_out = deal.details && deal.details.check_out && moment(deal.details.check_out);
		let nights = check_in && check_out && check_out.diff(check_in, 'days');
		let stay = nights && nights + ' ' + (nights === 1 ? 'Night' : 'Nights') + ' Stay';
		let price = deal.cheapopoPricing && deal.cheapopoPricing.cheapopoTotalPrice;
		let currency = deal.cheapopoPricing && deal.cheapopoPricing.currency;

		let accommodation_status = accommodation.status;

		let breakfast = deal.details && deal.details.breakfast_included;
		let travel_policy_exists = deal.hasOwnProperty('in_policy');
		let travel_policy_classes = 'travel-policy ' + (deal.in_policy ? 'in-policy' : 'out-of-policy');

		let component_classes = 'booked-item accommodation';
		// if (this.state.hovering) {
		//   component_classes += " hovering";
		// }
		if (hovered && previewed_booked_component && previewed_booked_component.id === accommodation.id) {
			component_classes += ' hovered';
		}
		let in_cancel_policy = false;
		let now = moment();
		if (accommodation.deal.dca.nonRefundable === false && accommodation.deal.dca.dealIsFullyRefundableTill) {
			in_cancel_policy = now.isSameOrBefore(moment(accommodation.deal.dca.dealIsFullyRefundableTill));
		}

		let isConfirmed = accommodation.status === 'confirmed' || accommodation.status === 'approved'; // TODO: verify if pending is also eligible for cancellation...
		let isFutureReservation = now.isBefore(check_out);
		let canCancelReservation = in_cancel_policy && isConfirmed && isFutureReservation && profile.travel_manager;

		const cancel_actions = [
			<Button
				key={'return-button'}
				variant="text"
				color="primary"
				onClick={this.handleCloseCancelReservationModal}
			>
				Return
			</Button>,
			<Button key={'cancel-button'} variant="text" color="secondary" onClick={this.handleOnCancelClick}>
				Cancel
			</Button>,
		];

		return (
			<div
				className={component_classes}
				key={accommodation.id}
				onMouseEnter={this.handleMouseEnter}
				onMouseLeave={this.handleMouseLeave}
			>
				<Dialog
					open={this.state.cancel_reservation_modal_open}
					onClose={this.handleCloseCancelReservationModal}
					className="cancel-reservation-modal"
				>
					<DialogTitle>Cancel hotel booking</DialogTitle>
					<DialogContent>
						{accommodation && accommodation.deal && accommodation.deal.dca.cancellationPoliciesText ? (
							<p>Cancellation may incur costs according to the following:</p>
						) : (
							<p>Are you sure you want to cancel the reservation?</p>
						)}

						{accommodation && accommodation.deal && accommodation.deal.dca.cancellationPoliciesText && (
							<div className="cancellation-policy">{accommodation.deal.dca.cancellationPoliciesText}</div>
						)}
					</DialogContent>
					<DialogActions>{cancel_actions}</DialogActions>
				</Dialog>
				<div className="column icon-column">
					<div className="icon-container">
						<i className="fa fa-bed fa-fw" />
					</div>
				</div>
				<div className="column hotel-name">
					<div className="line title">
						<span className="header">Hotel Name</span>
					</div>
					<div className="line">
						<span className="hotel-name">{hotel && hotel.name && hotel.name.toLowerCase()}</span>
					</div>
					<div className="line">
						<span className="room-name">
							{deal &&
								deal.details &&
								deal.details.room_description &&
								deal.details.room_description.toLowerCase()}
						</span>
					</div>
				</div>
				<div className="column check-in">
					<div className="line title">
						<span className="header">Check-in</span>
					</div>
					<div className="line">
						<span className="date">{check_in && check_in.format(date_format)}</span>
					</div>
					<div className="line">
						<span className="stay">{stay}</span>
					</div>
				</div>
				<div className="column check-out">
					<div className="line title">
						<span className="header">Check-out</span>
					</div>
					<div className="line">
						<span className="date">{check_out && check_out.format(date_format)}</span>
					</div>
					<div className="line">
						{breakfast ? (
							<div className="line">
								<i className="fa fa-coffee" />
								<span className="breakfast">Breakfast included</span>
							</div>
						) : null}
					</div>
				</div>
				<div className="column status-cost">
					<div className="line title">
						<BookingStatus status={accommodation.status} />
					</div>
					<div className="line">
						<span className="price">
							{price && currency && Currencies.getPriceWithCurrency(price, currency)}
						</span>
					</div>
					{travel_policy_exists ? (
						<div className={'line ' + travel_policy_classes}>
							<div className="icon" />
							<span className="text">{deal.in_policy ? 'In Policy' : 'Out of Policy'}</span>
						</div>
					) : null}
				</div>
				{
					// this.state.hovering ? <div className="column actions">
					hovered &&
					(profile.travel_manager ||
						accommodation_status !== ReservationConstants.RESERVATION_STATUS.QUOTE) ? (
						<div className="column actions">
							<IconButton
								id="basic-button"
								size="large"
								aria-controls={menuOpen ? 'basic-menu' : undefined}
								aria-haspopup="true"
								aria-expanded={menuOpen ? 'true' : undefined}
								onClick={(e) => {
									this.setState({ menuOpen: true, anchorEl: e.currentTarget });
								}}
							>
								<MoreVertIcon />
							</IconButton>

							<Menu
								id="basic-menu"
								open={menuOpen}
								anchorEl={anchorEl}
								onClose={() => this.setState({ menuOpen: false, anchorEl: null })}
								MenuListProps={{
									'aria-labelledby': 'basic-button',
								}}
							>
								{accommodation_status === ReservationConstants.RESERVATION_STATUS.QUOTE && (
									<MenuItem style={styles.basic} onClick={this.handleBookAQuotedDealClick}>
										Continue To Book
									</MenuItem>
								)}

								{accommodation_status === ReservationConstants.RESERVATION_STATUS.QUOTE && (
									<MenuItem style={styles.basic} onClick={this.handleRemoveQuote}>
										Remove
									</MenuItem>
								)}

								{accommodation_status !== ReservationConstants.RESERVATION_STATUS.QUOTE &&
								isConfirmed ? (
									<MenuItem
										style={styles.basic}
										component="a"
										href={this.getVoucherPrintUrl(trip, accommodation)}
										target="_blank"
										rel="noopener noreferrer"
										onClick={this.handlePrintVoucherClick}
									>
										Print Voucher
									</MenuItem>
								) : (
									<MenuItem style={styles.basic} disabled>
										Print Voucher
									</MenuItem>
								)}

								{accommodation_status !== ReservationConstants.RESERVATION_STATUS.QUOTE &&
								profile.travel_manager ? (
									<MenuItem
										style={styles.basic}
										component="a"
										disabled={!(isConfirmed && accommodation.invoice_url)}
										href={accommodation.invoice_url}
										onClick={this.handlePrintInvoiceClick}
									>
										Print Invoice
									</MenuItem>
								) : null}

								{accommodation_status !== ReservationConstants.RESERVATION_STATUS.QUOTE &&
									profile.travel_manager && (
										<MenuItem
											style={styles.basic}
											disabled={!canCancelReservation}
											onClick={this.handleOpenCancelReservationModal}
										>
											Cancel Reservation
										</MenuItem>
									)}
							</Menu>
						</div>
					) : null
				}
			</div>
		);
	},
});

export default BookedAccommodation;
