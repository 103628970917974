import ReservationConstants from '../constants/ReservationConstants';

import AppDispatcher from '../dispatcher/AppDispatcher';

import PaymentConstants from '../constants/PaymentConstants';
import ReservationEntity from '../entities/Reservation';
import _ from 'lodash';
import HotelStore from './HotelStore';
import DealStore from './DealStore';
import ResultsStore from './ResultsStore';
import SearchStore from './SearchStore';
import ProfileStore from './ProfileStore';
import User from '../entities/User';

import TravelersHelper from '../components/pages/reservation-payment/helpers/TravelersHelper';

import SEARCH_CONSTANTS from 'arbitrip-common/general/constants/search-constants';

import { EventEmitter } from 'events';
import ClientPriceService from 'arbitrip-common/client/utils/ClientPriceService';
import { ERROR_CONSTANTS } from 'arbitrip-common/general/constants/CreditLineConstants';
import * as Sentry from '@sentry/react';
import { RESERVATION_STATUS } from '@arbitrip/constants';

// Define initial data points
var _reservation = null; //{};
var _use_bank_money = false;
var _out_of_policy_request_status = ReservationConstants.STATUS.INITIAL;
var _double_booking_status = ReservationConstants.STATUS.INITIAL;
var _double_booking = false;
var _expedia_booking_response = null;
var _expedia_booking_response_status = ReservationConstants.STATUS.INITIAL;

let _create_traveler_statuses = null;

// Method to load result data from mock API
function loadReservationData(data, combtas_id, proposal_id, travel_booster_docket_number, profile) {
	if (_reservation) {
		console.log('extending reservation with data : ', data);
		_reservation = _.extend({}, _reservation, data);
		_reservation.travelersErrors = {};

		////////////////////////////////////////
		// derived from additions
		if (Array.isArray(data.travellers) && data.travellers.length) {
			_reservation.travelers = data.travellers.map((t) => new User(t));
		}
		_reservation.specialRequests = data.special_requests || _reservation.specialRequests;
		////////////////////////////////////////

		if (data.supplier_remarks) {
			_.set(_reservation, 'deal.chooseprod.dca.info', data.supplier_remarks);
		}

		// update invoice_id
		const { invoice_ids } = profile.company;
		const netsuite_subsidiary = _.get(profile, 'netsuite_subsidiary');

		const invoice_data = _.find(invoice_ids, { netsuite_id: netsuite_subsidiary });
		if (invoice_data) {
			_.set(_reservation, 'invoice_id', invoice_data.id);
			_.set(_reservation, 'invoice_currency', invoice_data.currency);
			_.set(_reservation, 'payment_country_code', data.payment_country_code);
		}
	} else {
		console.log('loading reservation | data = ', data);
		_reservation = data;
	}

	if (combtas_id) {
		_reservation.combtas_id = combtas_id;

		if (proposal_id) {
			_reservation.proposal_id = proposal_id;
		}
	}

	if (travel_booster_docket_number) {
		_reservation.travel_booster_docket_number = travel_booster_docket_number;
	}

	if (profile) {
		const company_id = _.get(profile, 'company.id');
		const contracts = _.get(profile, 'contracts');
		if (_.isArray(contracts)) {
			const self_contract = contracts.find((contract) => _.get(contract, 'company._id') === company_id);
			if (self_contract && _reservation.deal) {
				// const {
				//   currency,
				//   margin_addition_fixed = 0,
				//   margin_percent_fixed = 1 // not in actual % (e.g. not 5% but 1.05)
				// } = _.get(self_contract, 'pricing_config', {});

				// // const total_price = _.get(_reservation, 'deal.totalPrice');
				// const total_price = _.get(_reservation, 'deal.net_price_total');

				// const absolute_price = (margin_percent_fixed >= 2)
				//   ? total_price * margin_percent_fixed + margin_addition_fixed
				//   : (total_price / (2 - margin_percent_fixed)) + margin_addition_fixed;

				// const margin_multiplier = (absolute_price >= (2 * total_price))
				//   ? absolute_price / total_price
				//   : 2 - (total_price / absolute_price);

				// _.set(_reservation, 'agent_pricing.absolute_price', Math.round(absolute_price));
				// _.set(_reservation, 'agent_pricing.absolute_margin', Math.round(absolute_price - total_price));
				// _.set(_reservation, 'agent_pricing.margin_percentage', Math.round(100 * (margin_multiplier - 1)));

				// _.set(_reservation, 'agent_pricing.pricing_config', {
				//   currency,
				//   margin_addition_fixed,
				//   margin_percent_fixed,
				// });

				// _.set(_reservation.deal, 'agent_pricing', ClientPriceService.getAgentPricingBySelfContract(_reservation.deal, self_contract));
				_reservation.deal.agent_pricing = ClientPriceService.getAgentPricingBySelfContract(
					_reservation.deal,
					self_contract,
				);
				console.log(12321, '_reservation.deal', _reservation.deal);
			}
		}
	}

	if (ResultsStore.areArbitripPointsApplied()) {
		_use_bank_money = true;
	}
}

function createNewReservationIfNeeded() {
	var hotel = HotelStore.getHotelData();
	var deal = DealStore.getDealData();
	var travel_policy = ResultsStore.getTravelPolicy();

	if (!_reservation || _reservation.hotel.id !== hotel.id || _reservation.deal.id !== deal.id) {
		_reservation = new ReservationEntity(
			{
				hotel: hotel,
				deal: deal,
				travel_policy: travel_policy,
				status: ReservationConstants.STATUS.NEW,
				private_travel: SearchStore.isPrivateTravel(),
			},
			SearchStore.getExtraData(),
		);
	}
}

function clearReservationData() {
	const profile = ProfileStore.getProfile() || {};
	const maxAdults = profile?.agent ? SEARCH_CONSTANTS.MAX.ADULTS_IF_AGENT : SEARCH_CONSTANTS.MAX.ADULTS;

	_reservation = null;
	_out_of_policy_request_status = ReservationConstants.STATUS.INITIAL;
	_create_traveler_statuses = new Array(SEARCH_CONSTANTS.MAX.ROOMS * maxAdults);
	_create_traveler_statuses.fill(ReservationConstants.STATUS.INITIAL);
	_double_booking_status = ReservationConstants.STATUS.INITIAL;
	_double_booking = false;
	_expedia_booking_response = null;
	_expedia_booking_response_status = ReservationConstants.STATUS.INITIAL;
}

///
/// On Store load -- reset data
///
clearReservationData();

function markReservationQuoted() {
	_reservation.status = ReservationConstants.RESERVATION_OPERATION_STATE.QUOTED;
}

function markReservationBooked() {
	_reservation.status = ReservationConstants.STATUS.BOOKED;
}

function markReservationPending() {
	_reservation.status = ReservationConstants.STATUS.PENDING;
}

function markReservationFailed(bookingError) {
	if (_reservation) {
		_reservation.status = ReservationConstants.STATUS.FAILED;
		_reservation.bookingError = bookingError;
	}
}

function markReservationQuotedFailed(bookingError) {
	_reservation.status = ReservationConstants.STATUS.FAILED;
	_reservation.bookingError = bookingError;
}

function markReservationCannotBeOpened() {
	_reservation.status = ReservationConstants.STATUS.FAILED_RESERVATION_INITIALIZATION;
}

function markPaymentCreditLineFailed() {
	_reservation.credit_line_status = ReservationConstants.STATUS.FAILED;
}

// Extend Search Store with EventEmitter to add eventing capabilities
var ReservationStore = _.extend({}, EventEmitter.prototype, {
	getOutOfPolicyRequestStatus: function () {
		return _out_of_policy_request_status;
	},

	getInvoiceId: function () {
		return _reservation.invoice_id;
	},

	// Return search results
	getReservationData: function () {
		if (!_reservation) {
			createNewReservationIfNeeded();
		}
		return _reservation;
	},

	getCreateTravelerStatuses: function () {
		return _create_traveler_statuses;
	},

	isUsingBankMoney: function () {
		return _use_bank_money;
	},

	isDoubleBooking: function () {
		return _double_booking;
	},

	isDoubleBookingStatus: function () {
		return _double_booking_status;
	},

	expediaBookingResponse: function () {
		return _expedia_booking_response;
	},

	expediaBookingResponseStatus: function () {
		return _expedia_booking_response_status;
	},

	// TODO: method for currency conversion

	// Emit Change event
	emitChange: function () {
		this.emit('change');
	},

	// Add change listener
	addChangeListener: function (callback) {
		this.on('change', callback);
	},

	// Remove change listener
	removeChangeListener: function (callback) {
		this.removeListener('change', callback);
	},
});

function addTravelerError(data) {
	let roomIndex = _.get(data, 'roomIndex') || _.get(data, 'room_number');

	if (!_reservation.travelersErrors) {
		_reservation.travelersErrors = {};
	}
	if (!_reservation.travelersErrors[roomIndex]) {
		_reservation.travelersErrors[roomIndex] = {};
	}
	_reservation.travelersErrors[roomIndex][data.travelerIndex] = true;
}

function removeTravelerError(data) {
	let roomIndex = _.get(data, 'roomIndex') || _.get(data, 'room_number');

	if (!_reservation.travelersErrors) {
		_reservation.travelersErrors = {};
	}

	if (_reservation.travelersErrors[roomIndex] && _reservation.travelersErrors[roomIndex][data.travelerIndex]) {
		delete _reservation.travelersErrors[roomIndex][data.travelerIndex];
	}

	if (_.isEmpty(_reservation.travelersErrors[roomIndex])) {
		delete _reservation.travelersErrors[roomIndex];
	}
}

function updateAgencyClientPrice(agencyClientPrice) {
	if (agencyClientPrice) {
		// TODO: make sure valid number
		try {
			const normalized_price = parseFloat(agencyClientPrice);
			if (!isNaN(normalized_price)) {
				_reservation.agency_client_price = normalized_price;
			}
		} catch (err) {
			Sentry.captureException(err);
		}
	}
}

// function updateAgentPricing({ pricing_method, absolute_price, absolute_margin, margin_percentage, valid }) {

//   if (valid === false) {
//     _.set(_reservation, 'agent_pricing.valid', false);
//     return;
//   }

//   // const total_price = _.get(_reservation, 'deal.totalPrice');
//   const total_price = _.get(_reservation, 'deal.net_price_total');

//   if (pricing_method) {
//     _.set(_reservation, 'agent_pricing.pricing_method', pricing_method);
//   }

//   if (absolute_price) {
//     if (absolute_price < total_price) {
//       _.set(_reservation, 'agent_pricing.valid', false);
//       return;
//     }
//     _.set(_reservation, 'agent_pricing.absolute_price', Math.round(absolute_price));
//     _.set(_reservation, 'agent_pricing.absolute_margin', Math.round(absolute_price - total_price));

//     let _margin_multiplier = (absolute_price >= (2 * total_price))
//       ? absolute_price / total_price
//       : 2 - (total_price / absolute_price);
//     _.set(_reservation, 'agent_pricing.margin_percentage', Math.round(100 * (_margin_multiplier - 1)));
//   }

//   if (absolute_margin || (absolute_margin === 0)) {
//     if (absolute_margin < 0) {
//       _.set(_reservation, 'agent_pricing.valid', false);
//       return;
//     }
//     let _absolute_price = total_price + absolute_margin;
//     _.set(_reservation, 'agent_pricing.absolute_price', Math.round(_absolute_price));
//     _.set(_reservation, 'agent_pricing.absolute_margin', Math.round(absolute_margin));

//     let _margin_multiplier = (_absolute_price >= (2 * total_price))
//       ? _absolute_price / total_price
//       : 2 - (total_price / _absolute_price);
//     _.set(_reservation, 'agent_pricing.margin_percentage', Math.round(100 * (_margin_multiplier - 1)));
//   }

//   if (margin_percentage || (margin_percentage === 0)) {
//     if (margin_percentage < 0) {
//       _.set(_reservation, 'agent_pricing.valid', false);
//       return;
//     }
//     let _margin_multiplier = 1 + (margin_percentage / 100);
//     let _absolute_price = (_margin_multiplier >= 2)
//       ? total_price * _margin_multiplier
//       : total_price / (2 - _margin_multiplier);
//     _.set(_reservation, 'agent_pricing.absolute_price', Math.round(_absolute_price));
//     _.set(_reservation, 'agent_pricing.absolute_margin', Math.round(_absolute_price - total_price));
//     _.set(_reservation, 'agent_pricing.margin_percentage', Math.round(margin_percentage));
//   }

//   _.set(_reservation, 'agent_pricing.valid', true);
// }

function updateAgentPricing(agentPricing) {
	_.set(_reservation.deal, 'agent_pricing', agentPricing);
}

// Register callback with AppDispatcher
AppDispatcher.register(function (payload) {
	const action = payload.action;

	switch (action.actionType) {
		// Respond to RECEIVE_DATA action
		case ReservationConstants.RECEIVE_RESERVATION_DATA:
			loadReservationData(action.data);
			break;

		case ReservationConstants.OPEN_NEW_RESERVATION: {
			clearReservationData(); // uncommented due to new behaviour request
			createNewReservationIfNeeded();
			_reservation.status = ReservationConstants.STATUS.INITIALIZING;
			const private_travel = SearchStore.isPrivateTravel();
			if (
				action.data.profile &&
				_.isFunction(action.data.profile.shouldAutoPick) &&
				action.data.profile.shouldAutoPick(private_travel)
			) {
				action.data.profile.room = action.data.profile.room || 1;
				_reservation.travelers[0] = new User(action.data.profile);
			}
			break;
		}

		case ReservationConstants.NEW_RESERVATION_OPENED:
			loadReservationData(
				action.data.reservation,
				action.data.combtas_id,
				action.data.proposal_id,
				action.data.travel_booster_docket_number,
				action.data.profile,
			);
			_reservation.status = ReservationConstants.STATUS.INITIALIZED;
			break;

		case ReservationConstants.QUOTE_RESERVATION:
			_reservation.status = ReservationConstants.RESERVATION_OPERATION_STATE.QUOTING;
			break;

		case ReservationConstants.ADVANCE_RESERVATION:
			_reservation.status = ReservationConstants.STATUS.BOOKING;
			console.log('ReservationConstants.ADVANCE_RESERVATION::: _reservation.status ', _reservation.status);
			break;

		case ReservationConstants.RESTART_PAYMENT_WITH_ERROR:
			_reservation.status = ReservationConstants.STATUS.RESTART_PAYMENT_WITH_ERROR;
			break;

		case ReservationConstants.ADVANCE_RESERVATION_SUCCESS: {
			const { payment_type, points_only } = action.data;
			if (
				payment_type === PaymentConstants.PAYMENT_METHOD_TYPES.COMPANY_POLICY ||
				payment_type === PaymentConstants.PAYMENT_METHOD_TYPES.PAY_LATER ||
				payment_type === PaymentConstants.PAYMENT_METHOD_TYPES.PAY_AT_HOTEL ||
				payment_type === PaymentConstants.PAYMENT_METHOD_TYPES.POINTS_ONLY
			) {
				markReservationBooked();
			}
			break;
		}

		case ReservationConstants.ADVANCE_RESERVATION_FAILURE:
			console.log('ReservationConstants.ADVANCE_RESERVATION_FAILURE');
			if (
				[RESERVATION_STATUS.PENDING_SUPPORT, RESERVATION_STATUS.PENDING_SUPPLIER].includes(
					action.data?.reservation_status,
				)
			) {
				markReservationPending();
				return;
			}
			markReservationFailed(action.data?.error?.responseJSON?.message || 'error');

			if (_.get(action, 'data.error.responseJSON.code') === ERROR_CONSTANTS.NOT_ENOUGH_CREDIT_LINE_BALANCE) {
				markPaymentCreditLineFailed();
			}
			break;

		case ReservationConstants.RESERVATION_QUOTED:
			markReservationQuoted();
			break;

		case PaymentConstants.CREDIT_PAYMENT_CHARGED:
			markReservationBooked();
			break;

		case PaymentConstants.CREDIT_PAYMENT_FAILED:
			console.warn('CREDIT_PAYMENT_FAILED');
			//TODO: Update when Update payment response
			markReservationFailed(action.data?.error_message);
			break;

		case ReservationConstants.RESERVATION_QUOTED_FAILED:
			markReservationQuotedFailed();
			break;

		case ReservationConstants.ADD_CONTACT_TO_RESERVATION:
			var room = _reservation.rooms[action.data.room];
			room.users.push(new User(action.data.contact));
			break;

		case ReservationConstants.REMOVE_CONTACT_FROM_RESERVATION: {
			for (var key in _reservation.rooms) {
				let room = _reservation.rooms[key];
				for (var i = 0; i < room.users.length; i++) {
					if (room.users[i].email === action.data) {
						room.users.splice(i, 1);
					}
				}
			}
			break;
		}

		case ReservationConstants.UPDATE_TRAVELER_FIRST_NAME:
			console.log('update first name: ', _reservation);
			var traveler = _reservation.travelers[action.data.travelerIndex];
			traveler.first_name = action.data.first_name;
			traveler = TravelersHelper.updateTravelerFullName(traveler);
			break;

		case ReservationConstants.UPDATE_TRAVELER_LAST_NAME: {
			console.log('update last name: ', _reservation);
			let traveler = _reservation.travelers[action.data.travelerIndex];
			traveler.last_name = action.data.last_name;
			traveler = TravelersHelper.updateTravelerFullName(traveler);
			break;
		}

		case ReservationConstants.UPDATE_TRAVELER_EMAIL:
			console.log('update email: ', _reservation);
			_reservation.travelers[action.data.travelerIndex].email = action.data.email;
			break;

		case ReservationConstants.UPDATE_TRAVELER_PHONE:
			console.log('update phone: ', _reservation);
			_reservation.travelers[action.data.travelerIndex].phone = action.data.phone;
			break;

		case ReservationConstants.UPDATE_TRAVELER_ROOM:
			console.log('update room: ', _reservation);
			_reservation.travelers[action.data.travelerIndex].room = action.data.room;
			break;

		case ReservationConstants.UPDATE_TRAVELER: {
			// console.log("update traveler (profile): ", _reservation);
			// console.info('±±±±±±±±±±±±±±±±±±±±±±±±±±±±±±±±±±±±±±±±±±±±±±±±');
			// console.trace('ReservationConstants.UPDATE_TRAVELER', action.data);
			// console.info('§§§§§§§§§§§§§§§§§§§§§§§§§§§§§§§§§§§§§§§§§§§§§§§§');
			let traveler = _reservation.travelers[action.data.travelerIndex];
			Object.assign(traveler, action.data.traveler);
			traveler = TravelersHelper.updateTravelerFullName(traveler);
			break;
		}

		case ReservationConstants.RESET_TRAVELER:
			console.log('reset traveler (profile): ', _reservation);

			if (_.isArray(_create_traveler_statuses) && _create_traveler_statuses[action.data.travelerIndex]) {
				_create_traveler_statuses[action.data.travelerIndex] = ReservationConstants.STATUS.INITIAL;
			}

			Object.assign(
				_reservation.travelers[action.data.travelerIndex],
				new User({
					first_name: '',
					last_name: '',
					full_name: '',
					email: '',
					id: '',
					room: 0,
				}),
			);
			break;

		case ReservationConstants.CREATE_TRAVELER:
			_create_traveler_statuses[action.data.travelerIndex] = ReservationConstants.STATUS.BUSY;
			break;

		case ReservationConstants.CREATE_TRAVELER_SUCCESS: {
			removeTravelerError(action.data);
			_create_traveler_statuses[action.data.travelerIndex] = ReservationConstants.STATUS.SUCCESS;
			let filled_name =
				action.data.contact.full_name || action.data.contact.first_name + ' ' + action.data.contact.last_name;
			_reservation.travelers[action.data.travelerIndex] = new User(
				Object.assign({ room: action.data.room_number, filled_name }, action.data.response),
			);
			break;
		}
		case ReservationConstants.CREATE_TRAVELER_FAILED:
			addTravelerError(action.data);
			_create_traveler_statuses[action.data.travelerIndex] = ReservationConstants.STATUS.FAILED;
			break;

		case ReservationConstants.ADD_GUEST_TRAVELER:
			_reservation.travelers[action.data.travelerIndex] = new User(
				Object.assign({ room: action.data.room_number }, action.data.contact),
			);
			break;

		case ReservationConstants.UPDATE_SPECIAL_REQUESTS:
			_reservation.specialRequests = action.data;
			break;

		case ReservationConstants.UPDATE_SPECIAL_REQUESTS_FROM_SUGGESTIONS:
			_reservation.specialRequestsFromSuggestions = action.data;
			break;

		case ReservationConstants.UPDATE_OUT_OF_POLICY_BOOKING_REASON:
			_reservation.out_of_policy_booking_reason = action.data;
			break;

		case ReservationConstants.RESET_OUT_OF_POLICY_REQUEST_STATUS:
			_out_of_policy_request_status = ReservationConstants.STATUS.INITIAL;
			break;

		case ReservationConstants.REQUEST_OUT_OF_POLICY_ROOM:
			_out_of_policy_request_status = ReservationConstants.STATUS.BUSY;
			break;

		case ReservationConstants.REQUEST_OUT_OF_POLICY_ROOM_SUCCESS:
			_out_of_policy_request_status = ReservationConstants.STATUS.SUCCESS;
			break;

		case ReservationConstants.REQUEST_OUT_OF_POLICY_ROOM_FAILED:
			_out_of_policy_request_status = ReservationConstants.STATUS.FAILED;
			break;

		case ReservationConstants.NEW_RESERVATION_CANNOT_BE_OPENED:
			markReservationCannotBeOpened();
			break;

		case ReservationConstants.CLEAR_RESERVATION:
			clearReservationData();
			break;

		case ReservationConstants.MARK_RESERVATION_FOR_NON_REFUNDABLE_QUERY:
			_reservation.shouldQueryNonRefundable = true;
			break;

		case ReservationConstants.QUERIED_NON_REFUNDABLE_RESERVATION:
			_reservation.shouldQueryNonRefundable = false;
			break;

		case ReservationConstants.TOGGLE_BANK_MONEY_USAGE:
			_use_bank_money = action.data;
			break;

		case ReservationConstants.UPDATE_INVOICE_OPTION:
			_reservation.invoice_id = action.data.invoice_id;
			_reservation.invoice_currency = action.data.invoice_currency;
			break;

		case ReservationConstants.UPDATE_INCLUDE_MANAGEMENT_TRIP_ID:
			_reservation.include_management_trip_id = action.data;
			break;

		case ReservationConstants.ADD_TRAVELER_ERROR:
			addTravelerError(action.data);
			break;

		case ReservationConstants.REMOVE_TRAVELER_ERROR:
			removeTravelerError(action.data);
			break;

		case ReservationConstants.CONFIRM_PRICE_CHANGED:
			_reservation.deal.priceChangedData.confirmedChange = true;
			break;

		case ReservationConstants.UPDATE_AGENCY_CLIENT_PRICE:
			updateAgencyClientPrice(action.data);
			break;

		case ReservationConstants.UPDATE_AGENT_PRICING:
			updateAgentPricing(action.data);
			break;

		// Double booking start
		case ReservationConstants.DOUBLE_BOOKING:
			_double_booking_status = ReservationConstants.STATUS.BUSY;
			break;

		case ReservationConstants.DOUBLE_BOOKING_SUCCESS:
			_double_booking_status = ReservationConstants.STATUS.SUCCESS;
			_double_booking = action.data.isDoubleBooking;
			break;

		case ReservationConstants.DOUBLE_BOOKING_FAILED:
			_double_booking_status = ReservationConstants.STATUS.FAILED;
			break;
		// Double booking end

		case ReservationConstants.UPDATE_SUPPLIER_REF:
			_reservation.supplier_ref = action.data;
			break;

		// Create expedia booking start
		case ReservationConstants.CREATE_EXPEDIA_BOOKING:
			_expedia_booking_response_status = ReservationConstants.STATUS.BUSY;
			break;

		case ReservationConstants.CREATE_EXPEDIA_BOOKING_SUCCESS:
			_expedia_booking_response_status = ReservationConstants.STATUS.SUCCESS;
			_expedia_booking_response = action.data;
			break;

		case ReservationConstants.CREATE_EXPEDIA_BOOKING_FAILED:
			_expedia_booking_response_status = ReservationConstants.STATUS.FAILED;
			markReservationFailed(action.data?.error_message || 'error');
			break;
		// Create expedia booking end
		default:
			return true;
	}

	// If action was responded to, emit change event
	ReservationStore.emitChange();

	return true;
});

export default ReservationStore;
