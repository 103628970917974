import * as Sentry from '@sentry/react';
const FileSaver = require('file-saver');
const { parse } = require('json2csv');
// const BOM = "\uFEFF";

function saveJsonAsCsv(json, fields, filename) {
	try {
		const options = { fields };
		const csv = parse(json, options);
		// const data = new Blob([JSON.stringify(json)], { type: "application/json" });
		// const data = new Blob([BOM + csv], { type: "text/csv;charset=utf-8" });
		const data = new Blob([csv], { type: 'text/csv;charset=utf-8' });
		FileSaver.saveAs(data, filename);
	} catch (err) {
		console.error(err);
		Sentry.captureException(err);
	}
}

export default {
	saveJsonAsCsv,
};

// // save to file room pricing
// let blobRoomPricing = new Blob([JSON.stringify(room_pricing)], { type: "application/json" });

// saveAs(blobMapping, `hotel-${hotel.hotel_id}-${hotel.reservation_id}-${catalog_name}-mapping.json`);
// saveAs(blobNotMapped, `hotel-${hotel.hotel_id}-${hotel.reservation_id}-${catalog_name}-not-mapped.json`);
// saveAs(blobHotel, `hotel-${hotel.hotel_id}-${hotel.reservation_id}-${catalog_name}-${original_catalog.property_id}-catalog.json`);
// saveAs(blobRoomPricing, `hotel-${hotel.hotel_id}-${hotel.reservation_id}-${catalog_name}-room-pricing.json`);

// // save to csv
// let csv = window.Papa.unparse({
//     fields: ["Supplier Room Name", "Catalog Room Name", "Catalog Room ID"],
//     data: csvData
// });

// let csvBlob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
// saveAs(csvBlob, `hotel-${hotel.hotel_id}-${hotel.reservation_id}-${catalog_name}-mapping.csv`);
