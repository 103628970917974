import React, { useEffect, useMemo, useState } from 'react';
import { Button, Drawer, IconButton, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import ExtrasTooltip from './ExtrasTooltip.react';
import { CloseOutlined, Tune as TuneIcon, Info as InfoIcon } from '@mui/icons-material';
import { ReactComponent as AdvancedIcon } from '../../../img/bookings/advanced-black.svg';
import { EXTRAS } from './consts';
import CheckIcon from '@mui/icons-material/Check';
import { getUserBranchName } from './helpers/utils';

const AdvancedFiltersDrawer = ({
	open,
	onClose,

	filters,
	setFilters,

	initPagination,
	setPagination,

	profile,
}) => {
	const [travelBoosterNotSynced, setTravelBoosterNotSynced] = useState(filters.travel_booster_not_synced);
	const [extra, setExtra] = useState(filters.extra);
	const [where, setWhere] = useState(filters.where);
	const [agentName, setAgentName] = useState(filters.agent_name);
	const [companyName, setCompanyName] = useState(filters.company_name);
	const [travelerName, setTravelerName] = useState(filters.traveler_name);
	const [reservationId, setReservationId] = useState(filters.reservation_id);
	const [bookingFromBranch, setBookingFromBranch] = useState(filters.bookings_from_branch);
	const [agencyBranches, setAgencyBranches] = useState(filters.agency_branches);

	const { travel_booster_integration_enabled = false } = profile.settings;

	const showTravelBoosterExtra = useMemo(() => {
		return profile.agent && travel_booster_integration_enabled;
	}, [profile]);

	const bookingFromBranchExtra = useMemo(() => {
		return profile.agency_branch && (profile.agent || profile.travel_manager) && !profile.super_agent;
	}, [profile]);

	const agencyBranchesList = useMemo(() => {
		if (!profile.super_agent) return false;

		return profile.company_branches?.length ? profile.company_branches : false;
	}, [profile]);

	const isExpediaPartner = useMemo(() => {
		return profile.company?.partners_ui?.expedia_partner;
	}, [profile]);

	useEffect(() => {
		setWhere(filters.where);
		setAgentName(filters.agent_name);
		setCompanyName(filters.company_name);
		setTravelerName(filters.traveler_name);
		setReservationId(filters.reservation_id);
		setExtra(filters.extra);
		setTravelBoosterNotSynced(filters.travel_booster_not_synced);
		setBookingFromBranch(filters.bookings_from_branch);
		setAgencyBranches(filters.agency_branches);
	}, [filters]);

	const applyFilters = () => {
		setPagination(initPagination);

		setFilters({
			...filters,
			where,
			agent_name: agentName,
			company_name: companyName,
			traveler_name: travelerName,
			reservation_id: reservationId,
			extra,
			travel_booster_not_synced: travelBoosterNotSynced ? EXTRAS.TRAVEL_BOOSTER_NOT_SYNCED : null,
			bookings_from_branch: bookingFromBranch,
			agency_branches: agencyBranches,
		});

		onClose();
	};

	const getTooltip = () => {
		return (
			<div className="extras-tooltip">
				<div className="extras-tooltip__title">Information</div>
				{showTravelBoosterExtra && (
					<div className="extras-tooltip__item">
						<div>Travel Booster Not Synced</div>
						<div>Show bookings that are not synced with Travel Booster</div>
					</div>
				)}
				<div className="extras-tooltip__item">
					<div>Show Upcoming</div>
					<div>Show a list of reservations that have been confirmed and are guaranteed to take place</div>
				</div>
				<div className="extras-tooltip__item">
					<div>Show Ongoing</div>
					<div>Show a list of current reservations that are taking place at the moment</div>
				</div>
				<div className="extras-tooltip__item">
					<div>Show Confirmed</div>
					<div>Show a list of confirmed reservations, excluding any canceled bookings</div>
				</div>
				<div className="extras-tooltip__item">
					<div>Cancellation Deadline - next 48 hours</div>
					<div>
						Show a list of current reservations that are approaching their cancellation deadline within the
						next two days
					</div>
				</div>
				<div className="extras-tooltip__item">
					<div>Sort From Closest Excluding Passed Due</div>
					<div>
						Show a sorted list of current reservations (Excluding passed reservations), starting with the
						closest cancellation deadline.
					</div>
				</div>
				<div className="extras-tooltip__item">
					<div>Sort From Closest Including Passed Due</div>
					<div>Show a sorted list of all reservations starting with the closest cancellation deadline.</div>
				</div>
				<div className="extras-tooltip__item">
					<div>Sort From Furthest</div>
					<div>Show a sorted list of all reservations starting with the furthest cancellation deadline.</div>
				</div>
				{bookingFromBranchExtra && (
					<div className="extras-tooltip__item">
						<div>Branch</div>
						<div>Show a list of reservations created by agents attached to agency branch.</div>
					</div>
				)}
				{agencyBranchesList && (
					<div className="extras-tooltip__item">
						<div>Branches</div>
						<div>
							Show a list of reservations created by agents attached to the particular agency branch.
						</div>
					</div>
				)}
			</div>
		);
	};

	return (
		<Drawer anchor="right" open={open} onClose={onClose}>
			<div className="bookings-advanced-filters-drawer">
				<div className="bookings-advanced-filters-drawer__title-wrap">
					<div className="bookings-advanced-filters-drawer__title">
						<AdvancedIcon /> Advanced Search
					</div>
					<IconButton className="close-drawer-button" aria-label="close" onClick={onClose}>
						<CloseOutlined />
					</IconButton>
				</div>

				<div className="bookings-advanced-filters-drawer__box">
					<div className="bookings-advanced-filters-drawer__filter no-margin-top">
						<label htmlFor="destination-input">Destination</label>
						<TextField
							id="destination-input"
							placeholder="Find by destination or hotel name"
							variant="outlined"
							value={where}
							onChange={(e) => setWhere(e.target.value)}
						/>
					</div>

					{profile.agent && (
						<React.Fragment>
							<div className="bookings-advanced-filters-drawer__filter">
								<label htmlFor="agent-name-input">Agent Name</label>
								<TextField
									id="agent-name-input"
									placeholder="Find by travel agent name"
									variant="outlined"
									value={agentName}
									onChange={(e) => setAgentName(e.target.value)}
								/>
							</div>

							<div className="bookings-advanced-filters-drawer__filter">
								<label htmlFor="company-name-input">Company Name</label>
								<TextField
									id="company-name-input"
									placeholder="Find by company name"
									variant="outlined"
									value={companyName}
									onChange={(e) => setCompanyName(e.target.value)}
								/>
							</div>
						</React.Fragment>
					)}

					<div className="bookings-advanced-filters-drawer__filter">
						<label htmlFor="traveler-name-input">Traveler Name</label>
						<TextField
							id="traveler-name-input"
							placeholder="Find by traveler name"
							variant="outlined"
							value={travelerName}
							onChange={(e) => setTravelerName(e.target.value)}
						/>
					</div>

					{!isExpediaPartner && (
						<div className="bookings-advanced-filters-drawer__filter no-margin-bottom">
							<label htmlFor="booking-id-input">Reservation ID</label>
							<TextField
								id="booking-id-input"
								placeholder="Find by reservation ID"
								variant="outlined"
								value={reservationId}
								onChange={(e) => setReservationId(e.target.value)}
							/>
						</div>
					)}
				</div>

				<div className="bookings-advanced-filters-drawer__box">
					<div className="bookings-advanced-filters-drawer__filter no-column no-margin-top no-margin-bottom space-between">
						<label>
							<TuneIcon />
							Extras
						</label>
						<ExtrasTooltip arrow placement="left" title={getTooltip()}>
							<InfoIcon color="gray" className="tooltip-icon" />
						</ExtrasTooltip>
					</div>

					<div className="bookings-advanced-filters-drawer__extras">
						<div className="bookings-advanced-filters-drawer__filter">
							{showTravelBoosterExtra && (
								<ToggleButton
									color="primary"
									value={EXTRAS.TRAVEL_BOOSTER_NOT_SYNCED}
									selected={travelBoosterNotSynced}
									onChange={() => setTravelBoosterNotSynced(!travelBoosterNotSynced)}
								>
									Travel Booster Not Synced {travelBoosterNotSynced && <CheckIcon />}
								</ToggleButton>
							)}
						</div>
					</div>

					<div className="bookings-advanced-filters-drawer__extras">
						<div className="bookings-advanced-filters-drawer__filter">
							<ToggleButtonGroup
								color="primary"
								value={extra}
								orientation="vertical"
								fullWidth
								exclusive
								onChange={(e, newExtra) => setExtra(newExtra)}
								aria-label="Choose a Preset"
							>
								<ToggleButton value={EXTRAS.SHOW_UPCOMING}>
									Show Upcoming {extra === EXTRAS.SHOW_UPCOMING && <CheckIcon />}
								</ToggleButton>
								<ToggleButton value={EXTRAS.SHOW_ONGOING}>
									Show Ongoing {extra === EXTRAS.SHOW_ONGOING && <CheckIcon />}
								</ToggleButton>
								<ToggleButton value={EXTRAS.SHOW_CONFIRMED}>
									Show Confirmed {extra === EXTRAS.SHOW_CONFIRMED && <CheckIcon />}
								</ToggleButton>
							</ToggleButtonGroup>
						</div>
					</div>

					<div className="bookings-advanced-filters-drawer__subtitle">Booking Cancellation Until</div>

					<div className="bookings-advanced-filters-drawer__extras">
						<div className="bookings-advanced-filters-drawer__filter">
							<ToggleButtonGroup
								color="primary"
								value={extra}
								orientation="vertical"
								fullWidth
								exclusive
								onChange={(e, newExtra) => setExtra(newExtra)}
								aria-label="Choose a Preset"
							>
								<ToggleButton value={EXTRAS.CANCELLATION_DEADLINE}>
									Cancellation Until - next 48 hours{' '}
									{extra === EXTRAS.CANCELLATION_DEADLINE && <CheckIcon />}
								</ToggleButton>
								<ToggleButton value={EXTRAS.CLOSEST_DEADLINE}>
									Sort From Closest Excluding Passed Due{' '}
									{extra === EXTRAS.CLOSEST_DEADLINE && <CheckIcon />}
								</ToggleButton>
								<ToggleButton value={EXTRAS.CLOSEST_DEADLINE_PASSED}>
									Sort From Closest Including Passed Due{' '}
									{extra === EXTRAS.CLOSEST_DEADLINE_PASSED && <CheckIcon />}
								</ToggleButton>
								<ToggleButton value={EXTRAS.FURTHEST_DEADLINE}>
									Sort From Furthest {extra === EXTRAS.FURTHEST_DEADLINE && <CheckIcon />}
								</ToggleButton>
							</ToggleButtonGroup>
						</div>
					</div>

					{bookingFromBranchExtra && !agencyBranchesList.length && (
						<div className="bookings-advanced-filters-drawer__subtitle">Branch</div>
					)}

					{agencyBranchesList.length && (
						<div className="bookings-advanced-filters-drawer__subtitle">Branches</div>
					)}

					{bookingFromBranchExtra && (
						<div className="bookings-advanced-filters-drawer__extras">
							<div className="bookings-advanced-filters-drawer__filter">
								<ToggleButton
									color="primary"
									value={EXTRAS.BOOKINGS_FROM_BRANCH}
									selected={!!bookingFromBranch}
									onChange={() =>
										setBookingFromBranch(!!bookingFromBranch ? null : getUserBranchName(profile))
									}
								>
									<b>{getUserBranchName(profile)}</b> {!!bookingFromBranch && <CheckIcon />}
								</ToggleButton>
							</div>
						</div>
					)}

					{agencyBranchesList.length && (
						<div className="bookings-advanced-filters-drawer__extras">
							<ToggleButtonGroup
								color="primary"
								orientation="vertical"
								fullWidth
								exclusive
								onChange={(e, branchId) => {
									const list = [...agencyBranches];
									const index = list.indexOf(branchId);
									if (index > -1) {
										list.splice(index, 1);
									} else {
										list.push(branchId);
									}
									setAgencyBranches(list);
								}}
								aria-label="Choose a Branch"
							>
								{agencyBranchesList.map((branch) => {
									const isSelected = agencyBranches.includes(branch._id);
									return (
										<ToggleButton selected={isSelected} value={branch._id} key={branch._id}>
											<span>
												{' '}
												{branch.name}
												<b>{branch.is_archived ? ` (Archived)` : ''}</b>
											</span>{' '}
											{isSelected && <CheckIcon />}
										</ToggleButton>
									);
								})}
							</ToggleButtonGroup>
						</div>
					)}
				</div>

				<div className="bookings-advanced-filters-drawer__actions">
					<Button
						size="large"
						sx={{
							background: 'var(--general-primary-button-background)',
							color: 'var(--general-primary-button-color)',
							'&:hover': {
								background: 'var(--general-primary-button-hover)',
							},
						}}
						onClick={applyFilters}
					>
						Apply
					</Button>
				</div>
			</div>
		</Drawer>
	);
};

export default AdvancedFiltersDrawer;
