let global_properties = {};

function setItem(key, property) {
  global_properties[key] = property;
}

function getItem(key) {
  return global_properties[key];
}


function removeItem(key) {
  delete global_properties[key];
}

function clear() {
  global_properties = {};
}

function items() {
  return Object.assign({}, global_properties);
}

module.exports = {
  setItem,
  getItem,
  removeItem,
  clear,
  items
};
