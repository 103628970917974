/**
 * This file provided by Facebook is for non-commercial testing and evaluation
 * purposes only. Facebook reserves all rights not expressly granted.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL
 * FACEBOOK BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN
 * ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN
 * CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

// eslint-disable-next-line strict
'use strict';

import FixedDataTable from 'fixed-data-table';
import React from 'react';
import Currencies from '../../../utils/Currencies';
import _ from 'lodash';

const { Table, Column, ColumnGroup, Cell } = FixedDataTable;

// const DateCell = ({rowIndex, data, col, ...props}) => (
//   <Cell {...props}>
//     {data.getObjectAt(rowIndex)[col].toLocaleString()}
//   </Cell>
// );
//
// const ImageCell = ({rowIndex, data, col, ...props}) => (
//   <ExampleImage
//     src={data.getObjectAt(rowIndex)[col]}
//   />
// );
//
// const LinkCell = ({rowIndex, data, col, ...props}) => (
//   <Cell {...props}>
//     <a href="#">{data.getObjectAt(rowIndex)[col]}</a>
//   </Cell>
// );

const TextCell = ({ rowIndex, data, col, ...props }) =>
	props.currency ? (
		<Cell {..._.omit(props, ['currency', 'cellClass'])}>
			<span className={'cell-span ' + props.cellClass}>
				{Currencies.getSymbol(props.currency)}
				{data[rowIndex][col].toLocaleString()}
			</span>
		</Cell>
	) : (
		<Cell {..._.omit(props, ['currency', 'cellClass'])}>
			<span className={'cell-span ' + props.cellClass}>{data[rowIndex][col]}</span>
		</Cell>
	);

class TopTravelersTable extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			dataList: props.data,
		};

		this.displayName = 'TopTravelersTable';
	}

	componentDidUpdate(prevProps) {
		if (this.props.dataList !== prevProps.dataList) {
			this.setState({
				dataList: this.props.dataList,
			});
		}
	}

	render() {
		var { dataList } = this.state;
		var unifiedHeight = 30;

		var travelerNameHeight = 240;
		var number_of_room_nights_height = 96;
		var totalSpendHeight = 160;

		var travelerNameProps = _.extend({}, this.props, {
			cellClass: 'traveler-name',
		});

		var number_of_room_nights_props = _.extend({}, this.props, {
			cellClass: 'number-of-room-nights',
		});

		var totalSpentProps = _.extend({}, this.props, {
			// currency: this.props.currency,
			cellClass: 'total-spent',
		});

		return (
			<Table
				rowHeight={unifiedHeight}
				groupHeaderHeight={unifiedHeight}
				headerHeight={unifiedHeight}
				rowsCount={dataList.length}
				width={travelerNameHeight + number_of_room_nights_height + totalSpendHeight}
				height={dataList.length * unifiedHeight + 2 * unifiedHeight + 2}
				{...this.props}
			>
				<ColumnGroup
					fixed={true}
					header={
						<Cell className="table-header">
							<span className="table-title">Top Travelers</span>
							<span className="table-sort">(by # of room nights)</span>
						</Cell>
					}
				>
					<Column
						header={<Cell className="column-header">Traveler Name</Cell>}
						cell={<TextCell data={dataList} col="fullName" {..._.omit(travelerNameProps, 'currency')} />}
						fixed={true}
						width={travelerNameHeight}
					/>
					<Column
						header={<Cell className="column-header">Nights</Cell>}
						cell={
							<TextCell
								data={dataList}
								col="total_room_nights"
								{..._.omit(number_of_room_nights_props, 'currency')}
							/>
						}
						fixed={true}
						width={number_of_room_nights_height}
					/>
					<Column
						header={<Cell className="column-header">Total Cost</Cell>}
						cell={<TextCell data={dataList} col="totalSpent" {...totalSpentProps} />}
						fixed={true}
						width={totalSpendHeight}
					/>
				</ColumnGroup>
			</Table>
		);
	}
}

export default TopTravelersTable;
