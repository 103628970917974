import React from 'react';
import PropTypes from 'prop-types';

import Config from 'arbitrip-common/client/utils/Config';

class CombtasContainer extends React.Component {
	render() {
		let { combtasId, reservationData, shouldLockForm, onCombtasIdChange } = this.props;

		return (
			<div className="combtas-id-container">
				<label htmlFor="combtas_id">TAS ID</label>
				<input
					type="text"
					className="form-control"
					id="combtas_id"
					value={combtasId}
					disabled={shouldLockForm}
					onChange={onCombtasIdChange}
					placeholder="e.g. TAS00123T"
					maxLength="10"
				/>
				{Config.dev_mode && reservationData.proposal_id ? (
					<span>(Proposal ID = {reservationData.proposal_id})</span>
				) : null}
			</div>
		);
	}
}

CombtasContainer.propTypes = {
	reservationData: PropTypes.any.isRequired,
	combtasId: PropTypes.string.isRequired,
	shouldLockForm: PropTypes.bool.isRequired,
	onCombtasIdChange: PropTypes.func.isRequired,
};

export default CombtasContainer;
