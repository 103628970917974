const STEPS = {
	PAY_NOW: 'PAY_NOW',
	PAY_NOW_LOCKED: 'PAY_NOW_LOCKED',
	CREDIT_CARD: 'CREDIT_CARD',
	CREDIT_CARD_RESULT: 'CREDIT_CARD_RESULT',
	EDIT_COMMISSION: 'EDIT_COMMISSION',
};

const PAYMENT_TYPE = {
	PAY_NOW: 'PAY_NOW',
	SEND_LINK: 'SEND_LINK',
	GENERATED_LINK: 'GENERATED_LINK',
};

module.exports = {
	STEPS,
	PAYMENT_TYPE,
};
