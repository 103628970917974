const Events = require('../events/index');
const Core = require('../core');
const { getUserObject } = require("../services/userService");

function sessionHasExpired(user) {
    const metadata = {
        ...getUserObject(user)
    }

    Core.trackEvent(Events.RESULTS.SESSION_HAS_EXPIRED, metadata);
}

function resultsHaveExpired(user) {
    const metadata = {
        ...getUserObject(user)
    }

    Core.trackEvent(Events.RESULTS.RESULTS_HAVE_EXPIRED, metadata);
}

function sessionExpiredLoginClicked(user) {
    const metadata = {
        ...getUserObject(user)
    };

    Core.trackEvent(Events.RESULTS.SESSION_EXPIRED_LOGIN_CLICKED, metadata);
}

module.exports = {
    sessionHasExpired: sessionHasExpired,
    resultsHaveExpired: resultsHaveExpired,
    sessionExpiredLoginClicked: sessionExpiredLoginClicked
};
