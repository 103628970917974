import { useState, useRef } from 'react';
import withStyles from '@mui/styles/withStyles';

import PropTypes from 'prop-types';
import _ from 'lodash';
import ImageGallery from 'react-image-gallery';
import { Dialog, DialogContent } from '@mui/material';

const FALLBACK_IMAGE = '../../img/hotel_page/hotel_place_h@2x.png';

const styles = (theme) => ({
	fullScreen: {
		backgroundColor: '#000',
		width: '100vw',
		height: '100vh',
		maxWidth: '100%',
		maxHeight: 'none',
		margin: 0,
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		overflow: 'hidden',
	},
	dialogContentRoot: {
		display: 'flex',
		justifyContent: 'center',
	},
});

const getHotelImagesCSSBackground = (image) => {
	const { original, original_fallback } = image || {};

	const backgrounds = [original, original_fallback, FALLBACK_IMAGE].filter(Boolean);

	return backgrounds.map((bg) => `url(${bg})`).join(', ');
};

const HotelImagesCollage = ({ images, classes }) => {
	const [currentIndex, setCurrentIndex] = useState(0);
	const [isFullScreen, setIsFullScreen] = useState(false);
	const imageGalleryRef = useRef(null);

	const onCollageItemClick = (index) => {
		setIsFullScreen(true);
		setTimeout(() => {
			imageGalleryRef.current.slideToIndex(index);
		}, 0);
	};

	const handleCloseModal = () => {
		setIsFullScreen(false);
		setCurrentIndex(0);
	};

	const handleImageChange = (index) => {
		setCurrentIndex(index);
	};

	const renderGalleryImage = (item) => {
		const image_style = {
			backgroundImage: `url(${item.original})`,
			backgroundSize: 'cover',
		};

		if (item.original_fallback) {
			image_style.backgroundImage += `, url(${item.original_fallback})`;
		}

		return (
			<div className="gallery-image" style={image_style}>
				{item.description && <span className="image-gallery-description">{item.description}</span>}
			</div>
		);
	};

	const renderCollageItem = (index) => {
		const image = images[index];
		const hasMultipleImages = images.length > 1;
		const cursorStyle = hasMultipleImages ? 'pointer' : 'default';

		return (
			<button
				aria-label="Open Gallery"
				className={`collage-item ${index === 0 ? 'main-image' : 'side-image'}`}
				key={index}
				style={{
					backgroundImage: getHotelImagesCSSBackground(image),
					cursor: cursorStyle,
				}}
				onClick={hasMultipleImages ? () => onCollageItemClick(index) : _.noop}
			></button>
		);
	};

	const displayCount = Math.min(images.length, 4);

	return (
		<div className="collage-wrapper">
			<Dialog
				fullWidth={true}
				transitionDuration={400}
				open={isFullScreen}
				classes={{ paper: classes.fullScreen }}
				onClose={handleCloseModal}
			>
				<DialogContent classes={{ root: classes.dialogContentRoot }}>
					<button
						aria-label="Close"
						className="close-button-container flex align-center space-between"
						onClick={handleCloseModal}
					>
						<div className="icon"></div>
						<span className="text">Close</span>
					</button>
					<div className="images-count flex-center">
						{currentIndex + 1} / {images.length}
					</div>
					<div className="hotel-page-main-gallery flex">
						<ImageGallery
							ref={imageGalleryRef}
							items={images}
							showPlayButton={false}
							showFullscreenButton={false}
							showThumbnails={false}
							renderItem={renderGalleryImage}
							onSlide={handleImageChange}
						/>
					</div>
				</DialogContent>
			</Dialog>
			{!isFullScreen && (
				<div className={`collage-container collage-${displayCount}-items`}>
					{displayCount >= 1 && renderCollageItem(0)}
					{displayCount === 2 && renderCollageItem(1)}
					{displayCount > 2 && (
						<div className="side-images">
							{images.slice(1, displayCount).map((_, index) => renderCollageItem(index + 1))}
						</div>
					)}
				</div>
			)}
		</div>
	);
};

HotelImagesCollage.propTypes = {
	images: PropTypes.array,
};

export default withStyles(styles)(HotelImagesCollage);
