const _ = require('lodash');

const RUN_OF_HOUSE_REGEX = /\b(run of house)\b/i;
const ROH_REGEX = /\b(roh)\b/i;

function getRoomName(name) {
    name = _.deburr(name.trim().toLowerCase());

    // Test if name is in ROH array, or the full string is in the name
    // 'roh' apply checked only if it appears as a word
    if (RUN_OF_HOUSE_REGEX.test(name) || ROH_REGEX.test(name)) {
        name += ' (Room type will be assigned at check in)'
    }

    return name;
}

module.exports = {
    getRoomName
};
