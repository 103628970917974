const Events = require('../events/index');
const Core = require('../core');
const { getUserObject } = require('../services/userService');

function getProfile(user) {
    const metadata = {
        ...getUserObject(user)
    };

    Core.trackEvent(Events.USER.GET_PROFILE, metadata);
}

module.exports = {
    getProfile
};
