const ResetIcon = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M19 11.1119C18.786 9.57212 18.0717 8.14538 16.9671 7.05148C15.8624 5.95759 14.4288 5.25723 12.887 5.05828C11.3451 4.85934 9.78067 5.17285 8.43455 5.95052C7.08843 6.72818 6.03534 7.92687 5.4375 9.36193M5 5.86193V9.36193H8.5"
				stroke="var(--desktop-bookings-button)"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
			<path
				d="M5 12.8619C5.21399 14.4017 5.92831 15.8285 7.03294 16.9224C8.13757 18.0163 9.57121 18.7166 11.113 18.9156C12.6549 19.1145 14.2193 18.801 15.5654 18.0233C16.9116 17.2457 17.9647 16.047 18.5625 14.6119M19 18.1119V14.6119H15.5"
				stroke="var(--desktop-bookings-button)"
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
		</svg>
	);
};

export default ResetIcon;
