import _ from 'lodash';
import React, { useState } from 'react';
import MaterialDesignDialog from '../../general/MaterialDesignDialog.react';
import ReservationConstants from '../../../constants/ReservationConstants';
import Button from '@mui/material/Button';
import ReservationActions from '../../../actions/ReservationActions';

const RequestModals = ({
	activeRequest,
	showApprovalModal,
	showRejectionModal,
	setShowApprovalModal,
	setShowRejectionModal,
	setActiveRequest,
}) => {
	const reservation = _.get(activeRequest, 'reservation');

	const [rejectReason, setRejectReason] = useState('');

	const getApprovalModalFooter = () => {
		return reservation.status !== ReservationConstants.RESERVATION_STATUS.APPROVING ? (
			<div className="approve-modal-actions">
				<Button
					color="primary"
					onClick={() => {
						ReservationActions.approveReservation(activeRequest);
						setShowApprovalModal(false);
					}}
				>
					I'm sure
				</Button>
				<Button color="primary" onClick={() => setShowApprovalModal(false)}>
					Take me back
				</Button>
			</div>
		) : (
			<div className="approving-modal-status">
				<i title="Approving reservation..." className="fa fa-spin fa-spinner" />
				<span>Approving...</span>
			</div>
		);
	};

	const getApprovalModalFailedFooter = () => {
		return (
			<div className="approving-modal-status">
				<span>Bummer, It looks like there was a problem approving your booking.</span>
				<br />
				<span>
					Please contact our{' '}
					<a href="mailto:support@arbitrip.com" target="_blank">
						support team
					</a>
					.
				</span>
				<br />
				<Button color="primary" onClick={() => setShowApprovalModal(false)}>
					OK
				</Button>
			</div>
		);
	};

	const getRejectModalFooter = () => {
		return reservation.status !== ReservationConstants.RESERVATION_STATUS.REJECTING ? (
			<div className="reject-modal-actions">
				<Button
					color="primary"
					onClick={() => {
						ReservationActions.rejectReservation(Object.assign({ reason: rejectReason }, activeRequest));
						setShowRejectionModal(false);
					}}
				>
					I'm sure
				</Button>
				<Button color="primary" onClick={() => setShowRejectionModal(false)}>
					Take me back
				</Button>
			</div>
		) : (
			<div className="rejecting-modal-status">
				<i title="Declining reservation..." className="fa fa-spin fa-spinner" />
				<span>Declining...</span>
			</div>
		);
	};

	const getRejectModalFailedFooter = () => {
		return (
			<div className="rejecting-modal-status">
				<span>Bummer, It looks like there was a problem declining the booking.</span>
				<br />
				<span>
					Please contact our{' '}
					<a href="mailto:support@arbitrip.com" target="_blank">
						support team
					</a>
					.
				</span>
				<br />
				<Button color="primary" onClick={() => setShowRejectionModal(false)}>
					OK
				</Button>
			</div>
		);
	};

	if (!reservation) {
		return null;
	}

	return (
		<React.Fragment>
			<MaterialDesignDialog
				open={showApprovalModal || reservation.status === ReservationConstants.RESERVATION_STATUS.APPROVING}
				onClose={() => {
					setShowApprovalModal(false);
					setActiveRequest({});
				}}
				closeTimeoutInMilliseconds={150}
				key={`approve-modal-${reservation.id}`}
				title="Approve Reservation?"
				content={
					<React.Fragment>
						<div className="approve-reservation-modal-content">
							<div className="description">
								{_.get(reservation, 'deal.dca.cancellationPoliciesText') && (
									<span>Please review the cancellation terms:</span>
								)}
							</div>
							<div className="cancellation-policy">
								{_.get(reservation, 'deal.dca.cancellationPoliciesText')}
							</div>
						</div>
						{reservation.approveStatus !== ReservationConstants.STATUS.FAILED
							? getApprovalModalFooter()
							: getApprovalModalFailedFooter()}
					</React.Fragment>
				}
			/>

			<MaterialDesignDialog
				open={showRejectionModal || reservation.status === ReservationConstants.RESERVATION_STATUS.REJECTING}
				onClose={() => setShowRejectionModal(false)}
				closeTimeoutInMilliseconds={150}
				key={`reject-modal-${reservation.id}`}
				title="Decline Reservation?"
				content={
					<React.Fragment>
						<div className="reject-reservation-modal-content">
							<div className="reason-container">
								<label htmlFor="reject-reason">
									Please provide a reason for declining this reservation:
								</label>
								<span className="reject-notice">
									* Notice that the traveler will see the reason supplied
								</span>
								<div className="text-box-container">
									<textarea
										placeholder="(optional)"
										className="text-box"
										onChange={(e) => setRejectReason(e.target.value)}
										value={rejectReason}
									/>
								</div>
							</div>
						</div>
						{reservation.rejectStatus !== ReservationConstants.STATUS.FAILED
							? getRejectModalFooter()
							: getRejectModalFailedFooter()}
					</React.Fragment>
				}
			/>
		</React.Fragment>
	);
};

export default RequestModals;
