const { getPriceWithCurrency, getPriceByPrecision, getSymbol } = require('./Currencies');

const percentage_to_coefficient = 1 / 100;

function getCurrencyPriceFromPoints(points = 0, exchangeRate = 1, currency) {
    const price = points * exchangeRate;
    return getPriceWithCurrency(price, currency);
}

function getPriceWithDisplayCurrencyByPrecisionWithPoints(price, price_ex_rate = 1, points = 0, points_ex_rate = 1, currency, precision) {
    const points_to_price = points * points_ex_rate;
    const price_to_price = price * price_ex_rate;
    const display_price = price_to_price - points_to_price;
    return getSymbol(currency) + getPriceByPrecision(display_price, precision);
}

function getPointsFromPrice(price, points_ex_rate = 1) {
    return price / points_ex_rate;
}

////////////////////////////////////////////////////////////////////////////////

function getPointsEarnedCoefficient({ private_travel = false, arbitrip_loyalty_program }) {
    return (private_travel
        ? arbitrip_loyalty_program.private_booking_accumulation_percentage
        : arbitrip_loyalty_program.corporate_booking_accumulation_percentage) * percentage_to_coefficient;
}

////////////////////////////////////////

function getExpectedPointsEarned({ price_in_currency, points_used, arbitrip_points_to_currency_exchange_rate, private_travel, arbitrip_loyalty_program, user_currency_to_reservation_currency = 1 }) {
    const money_used_in_currency = price_in_currency - points_used * arbitrip_points_to_currency_exchange_rate;
    const points_earned_coefficient = getPointsEarnedCoefficient({ private_travel, arbitrip_loyalty_program });

    // example: 0.5% accumulation from a 100$ (used) deal | 0.005 (percentage as coefficient) * 100 (money used in usd) * 100 (usd to points ex rate) = 50 points
    return Math.ceil(points_earned_coefficient * money_used_in_currency * user_currency_to_reservation_currency / arbitrip_points_to_currency_exchange_rate);
}

////////////////////////////////////////////////////////////////////////////////

function extractTotalPrice({ profile, reservation }) {
    const arbi_price = reservation?.deal?.net_price_total || reservation?.deal?.cheapopoPricing?.purchase_price || 0;

    // consider profile agent fallback from profile.roles - is agent or super agent
    return profile?.agent && (arbi_price >= 0)
        ? arbi_price
        : reservation?.deal?.totalPrice || reservation?.deal?.cheapopoPricing?.cheapopoTotalPrice || 0;
}

module.exports = {
    getCurrencyPriceFromPoints,
    getPriceWithDisplayCurrencyByPrecisionWithPoints,
    getPointsFromPrice,
    getExpectedPointsEarned,
    extractTotalPrice,
};
