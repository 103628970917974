import Ajax from '../utils/Ajax';

let UserActions = {
	approveCurrencyTermsForUser() {
		return Ajax.approveCurrencyTermsForUser()
			.done((res) => {
				console.log('Approve currency terms saved: ', res);
				return res;
			})
			.fail(function (err) {
				console.error('Error while approve currency terms for user: ', err);
				return err;
			});
	},
};

export default UserActions;
