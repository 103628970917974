
function objectifyPointOfInterestId(hotel_id, place_id) {
    return hotel_id
        ? { hotel_id }
        : (place_id
            ? { place_id }
            : {});
}

module.exports = {
    objectifyPointOfInterestId
};
