import AppDispatcher from '../dispatcher/AppDispatcher';
import { EventEmitter } from 'events';
import MapConstants from '../constants/MapConstants';
import _ from 'lodash';

// Define initial data points
let _places = {};

let _fullMapMode = false;
let _lastCenter = null;
let _lastZoom = null;
let _selected_hotel_id = null;

let _lastInfoBubble = null;
let _lastMarker = null;
let _lastHotel = null;

let _dynamicMapExists = false;
let _dynamicMapWasLoaded = false;
let _mapUpdatedBefore = false;

// Method to load place data from mock API
function loadMapPlacesData(data) {
	_places = data;
}

// Extend Map Store with EventEmitter to add eventing capabilities
let MapStore = _.extend({}, EventEmitter.prototype, {
	getFullMapMode: function () {
		return _fullMapMode;
	},

	getDynamicMapExists: function () {
		return _dynamicMapExists;
	},
	getDynamicMapWasLoaded: function () {
		return _dynamicMapWasLoaded;
	},
	getMapUpdatedBefore: function () {
		return _mapUpdatedBefore;
	},

	getMapData: function () {
		return {
			fullMapMode: _fullMapMode,
			lastCenter: _lastCenter,
			lastZoom: _lastZoom,
			selectedHotelId: _selected_hotel_id,
			lastInfoBubble: _lastInfoBubble,
			lastMarker: _lastMarker,
			lastHotel: _lastHotel,
		};
	},

	// Return map places
	getMapPlaces: function () {
		return _places;
	},

	// Return # of places in map
	getPlaceCount: function () {
		return Object.keys(_places).length;
	},

	// TODO: method for currency conversion

	// Emit Change event
	emitChange: function () {
		this.emit('change');
	},

	// Add change listener
	addChangeListener: function (callback) {
		this.on('change', callback);
	},

	// Remove change listener
	removeChangeListener: function (callback) {
		this.removeListener('change', callback);
	},
});

// Register callback with AppDispatcher
AppDispatcher.register(function (payload) {
	let action = payload.action;

	switch (action.actionType) {
		case MapConstants.CLEAR_MAP_DATA:
			_lastMarker = null;
			_lastCenter = null;
			_lastInfoBubble = null;
			_fullMapMode = false;
			_lastZoom = null;
			_selected_hotel_id = null;
			break;

		case MapConstants.UPDATE_INFO_BUBBLE:
			_lastInfoBubble = action.data.infoBubble;
			_lastMarker = action.data.marker;
			_lastHotel = action.data.hotel;
			break;

		case MapConstants.UPDATE_SELECTED_HOTEL_ID:
			_selected_hotel_id = action.data;
			break;

		case MapConstants.UPDATE_ZOOM:
			_lastZoom = action.data;
			break;

		case MapConstants.UPDATE_CENTER:
			_lastCenter = action.data;
			break;

		case MapConstants.TOGGLE_FULL_MAP_MODE:
			_fullMapMode = !_fullMapMode;
			if (_fullMapMode) {
				_dynamicMapExists = true;
				_dynamicMapWasLoaded = true;
				_mapUpdatedBefore = true;
			}
			break;

		// Respond to RECEIVE_DATA action
		case MapConstants.RECEIVE_MAP_DATA:
			loadMapPlacesData(action.data);
			break;

		default:
			return true;
	}

	// If action was responded to, emit change event
	MapStore.emitChange();

	return true;
});

export default MapStore;
