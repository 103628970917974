import _ from 'lodash';

function normalizeAddress(address, name) {
    if (_.isString(address)) {
        if (_.isString(name)) {
            return address.includes(name)
                ? address
                : `${name}, ${address}`;
        }

        return address
    }

    return '';
}
class PointOfInterest {
    constructor(data) {
        if (!data) {
            console.warn('no data supplied for PointOfInterest');
            return;
        }

        for (const prop in data) {
            this[prop] = data[prop];
        }

        if (data.hotel_id) {
            this.hotel_id = data.hotel_id;

            this.address = data.address;
            this.city = data.city;
            if (data.state) {
                this.state = data.state;
            }
            this.country = data.country;
            this.country_code = data.country_code;

            if (data.location) {
                this.location = data.location;
            }

            if (data.name) {
                this.name = data.name;
            }
        } else if (data.place_id) {
            this.place_id = data.place_id;

            // TODO: consider getting address from original payload (ac response)
            // this.address = data.formatted_address;
            this.address = normalizeAddress(data.formatted_address, data.name);

            const geometry_location = _.get(data, 'geometry.location');
            if (geometry_location) {
                this.location = geometry_location;
            }

            const geometry_viewport = _.get(data, 'geometry.viewport');
            if (geometry_viewport) {
                this.viewport = geometry_viewport;
            }

            if (data.name) {
                this.name = data.name;
            }
        } else {
            console.warn('data supplied with no hotel_id nor place_id for PointOfInterest');
            return;
        }
    }
}

export default PointOfInterest;
