import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import ImageGallery from 'react-image-gallery';

class ImageCarousel extends React.Component {
	_renderItem(item) {
		let image_style = {
			backgroundImage: 'url(' + item.original + ')',
			backgroundSize: 'cover',
		};
		if (item.original_fallback) {
			image_style.backgroundImage += ', url(' + item.original_fallback + ')';
		}

		return (
			<div className="image-gallery-image" style={image_style}>
				{item.description && <span className="image-gallery-description">{item.description}</span>}
			</div>
		);
	}

	_renderThumbInner(thumb_inner) {
		let thumb_inner_style = {
			backgroundImage: 'url(' + thumb_inner.original + ')',
			backgroundSize: 'cover',

			width: 110,
			height: 72,
			border: '1px solid #EBEBEB',
			padding: 4,
			margin: 2,
		};

		if (thumb_inner.thumbnail) {
			thumb_inner_style.backgroundImage += ', url(' + thumb_inner.thumbnail + ')';
		} else {
			thumb_inner_style.width = '90px';
		}

		if (thumb_inner.thumbnail_fallback) {
			thumb_inner_style.backgroundImage += ', url(' + thumb_inner.thumbnail_fallback + ')';
		}

		return (
			<div>
				<div style={thumb_inner_style} />
			</div>
		);
	}

	render() {
		let { images, type, disclaimer } = this.props;

		return (
			<div className={classNames('carousel', { [type]: type })}>
				<ImageGallery
					ref={(i) => (this._imageGallery = i)}
					items={images}
					showPlayButton={false}
					showFullscreenButton={false}
					renderItem={this._renderItem}
					renderThumbInner={this._renderThumbInner}
					showThumbnails={true}
				/>
				{disclaimer}
			</div>
		);
	}
}

ImageCarousel.propTypes = {
	images: PropTypes.array,
	type: PropTypes.string,
};

export default ImageCarousel;
