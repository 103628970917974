import _ from 'lodash';
import React, { Component } from 'react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import createAppTheme from '@/utils/MuiArbitripTheme';
import ProfileStore from '../../stores/ProfileStore';
import AppGridStore from '../../stores/AppGridStore';
import SearchStore from '../../stores/SearchStore';
import AppGridActions from '../../actions/AppGridActions';
import TopBar from './bars/top/TopBar.react';
import FooterBar from './bars/footer/FooterBar.react';
import LoadingComponent from './LoadingComponent.react';
import LogActions from '../../actions/LogActions';
import MaterialDesignDialog from '../general/MaterialDesignDialog.react';
import SearchTermsParser from '../../utils/SearchTermsParser';
import RouterWrapper from '../../utils/RouterWrapper';
import SearchActions from '../../actions/SearchActions';
import { v4 as uuidV4 } from 'uuid';
import Analytics from 'arbitrip-common/client/analytics';
import Config from 'arbitrip-common/client/utils/Config';
import ChatProviderScriptService from 'arbitrip-common/client/utils/ChatProviderScriptService';
import { CHAT_PROVIDERS } from 'arbitrip-common/general/constants/chat-providers';
import { CLIENT_ROUTES as Routes } from 'arbitrip-common/general/constants';
import * as Sentry from '@sentry/react';
import { loadThemeConfig, setCssVariables } from 'arbitrip-common/theme/colors/helpers';
import HotelActions from '../../actions/HotelActions';
import channel from '@/utils/BroadcastChannel';
import {
	TAS_ID_KEY,
	setTasIdInSessionStorage,
	removeTasIdFromSessionStorage,
	getTasIdFromSessionStorage,
} from '@/utils/TasId';

let did_boot = false;
let did_parse = false;
let user_currency = '';

class AppGrid extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...getAppGridState(),
			themeConfig: null,
			materialUITheme: createAppTheme(),
			tasId: getTasIdFromSessionStorage() || null,
		};

		this._onChange = this._onChange.bind(this);
	}

	componentDidMount() {
		if (this.isSpecificPage(Routes.SEARCH)) {
			this.setState({ slowAnimation: true });
		}

		ProfileStore.addChangeListener(this._onChange);
		SearchStore.addChangeListener(this._onChange);
		AppGridStore.addChangeListener(this._onChange);

		window.addEventListener('storage', this.handleStorageChange);

		// Handle messages from other tabs to close tasId session
		channel.onmessage = (event) => {
			if (event.data === this.state.tasId) {
				removeTasIdFromSessionStorage();
				this.setState({ tasId: null });
			}
		};
	}

	componentWillUnmount() {
		ProfileStore.removeChangeListener(this._onChange);
		SearchStore.removeChangeListener(this._onChange);
		AppGridStore.removeChangeListener(this._onChange);

		window.removeEventListener('storage', this.handleStorageChange);
		channel.close();

		this.setState({ sessionTimeoutModalIsOpen: false });
	}

	componentDidCatch(error, info) {
		if (Config.prod === true) {
			let search_params = SearchStore.toAjaxPayload();
			LogActions.logError({ error, context: { info, search_params } });
			setTimeout(() => {
				document.location.href = document.location.origin;
			}, 100);
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { profile } = this.state;

		// TODO: consider moving to OfflineDB boot instead of AppGrid (specifically, loading search session related stuff)
		if (!did_boot && this.state.has_contract_id) {
			setTimeout(() => {
				SearchActions.updateSearchContractById();
			}, 10);
		}
		if (!did_boot && profile.email && AppGridStore.isIdle()) {
			try {
				const session_id = uuidV4();
				Analytics.actions.global_properties.setItem('session_id', session_id);

				this.loadAccessibilityWidget();

				const loadChatScriptAndStartSession = async () => {
					await this.loadChatScript(profile);
					Analytics.startSession(profile);
				};

				loadChatScriptAndStartSession();

				this.applyThemeConfig();
			} catch (err) {
				console.error(err);
				Sentry.captureException(err);
			}

			did_boot = true;
			this.setState({ did_boot });
		}
	}

	closeSessionTimeoutModal() {
		const profile = ProfileStore.getProfile();
		if (Config.rn) {
			document.location.href = Config.rn_link;
		} else if (Config.htz) {
			document.location.href = Config.htz_link;
		} else if (profile?.custom_logout_link) {
			document.location.href = profile.custom_logout_link;
		} else {
			document.location.reload();
		}
	}

	isSpecificPage(page_route) {
		let path = this.props.location && this.props.location.pathname;
		let route = typeof path === 'string' ? path.substr(1) : '';
		return route.startsWith(page_route);
	}

	areSpecificPages(routes) {
		return routes.some((route) => this.isSpecificPage(route));
	}

	loadChatScript(profile) {
		const chat_enabled = _.get(profile, 'chat.enabled', false);
		const chat_provider = _.get(profile, 'chat.provider', CHAT_PROVIDERS.INTERCOM);

		if (chat_enabled) {
			// return promise
			return ChatProviderScriptService.inject(chat_provider, profile);
		}

		return new Promise((resolve) => resolve());
	}

	async applyThemeConfig() {
		const companyId = _.get(this.state, 'profile.company.id');
		const themeConfig = await loadThemeConfig(companyId, Config.dev_mode);

		setCssVariables(themeConfig); // set css variables globally from the theme config
		const materialUITheme = createAppTheme(themeConfig); // adjust material-ui theme from the theme config

		this.setState({ themeConfig, materialUITheme });
	}

	loadAccessibilityWidget() {
		if (!ProfileStore.shouldShowAccessibilityWidget()) {
			return;
		}
		console.info('Loading accessibility widget...');
		// load script from
		// <script src="https://cdn.jsdelivr.net/gh/mickidum/acc_toolbar/acctoolbar/acctoolbar.min.js"></script>
		const script = document.createElement('script');
		script.src = 'https://cdn.jsdelivr.net/gh/mickidum/acc_toolbar/acctoolbar/acctoolbar.min.js';
		script.async = true;

		// append to top of body
		document.body.appendChild(script);
	}

	shouldDisplayCombtasBar() {
		const { profile } = this.state;
		const isCombtasEnabled = _.get(profile, 'company.settings.combtas_integration_enabled');
		const shouldDisplayOnCurrentPage = this.areSpecificPages([Routes.SEARCH, Routes.RESULTS, Routes.HOTEL]);
		return isCombtasEnabled && this.state.tasId && shouldDisplayOnCurrentPage;
	}

	endCombtasSession() {
		try {
			// Notify other tabs to close the current tasId session
			channel.postMessage(this.state.tasId);
		} catch (error) {
			console.log(error);
		}

		removeTasIdFromSessionStorage();
		this.setState({ tasId: null });

		const url = new URL(window.location);
		url.searchParams.delete(TAS_ID_KEY);
		window.history.replaceState({}, document.title, url.toString());
	}

	handleStorageChange = (event) => {
		if (!getTasIdFromSessionStorage()) {
			this.setState({ tasId: null });
		}
	};

	render() {
		let appGridClass = 'appGrid';
		if (!this.state.did_boot) {
			appGridClass += ' fade-in';
			if (this.state.slowAnimation) {
				appGridClass += ' slow-animation';
			}
		}
		if (
			this.state.pending ||
			this.state.parsing ||
			!this.state.profile.hasOwnProperty('email') ||
			!this.state.themeConfig
		) {
			return (
				<ThemeProvider theme={this.state.materialUITheme}>
					<StyledEngineProvider injectFirst>
						<LoadingComponent
							parsing={this.state.parsing}
							pending={this.state.pending && !this.isSpecificPage(Routes.HOTEL)}
						/>
					</StyledEngineProvider>
				</ThemeProvider>
			);
		} else {
			return (
				<ThemeProvider theme={this.state.materialUITheme}>
					<StyledEngineProvider injectFirst>
						<div className={appGridClass}>
							<div className="gridRow">
								<TopBar
									user={this.state.profile}
									company={this.state.profile.company}
									reservationPage={this.isSpecificPage(Routes.RESERVATION)}
									tasId={this.state.tasId}
									showCombtasBar={this.shouldDisplayCombtasBar()}
									endCombtasSession={() => this.endCombtasSession()}
								/>
							</div>
							<div
								id="page-container"
								className="gridRow"
								style={{
									paddingTop: this.shouldDisplayCombtasBar() ? '108px' : '75px',
								}}
							>
								{this.props.children}
							</div>
							{this.isSpecificPage(Routes.SEARCH) && (
								<div className="gridRow">
									<FooterBar />
								</div>
							)}
							<MaterialDesignDialog
								open={this.state.sessionTimeoutModalIsOpen}
								onClose={this.closeSessionTimeoutModal}
								closeTimeoutInMilliseconds={150}
								title="Your session has expired"
								content="Please login again"
								actions={[
									{
										onClick: () => {
											Analytics.actions.sessions.sessionExpiredLoginClicked(this.state.profile);
											this.closeSessionTimeoutModal();
										},
										text: 'Login',
									},
								]}
							/>
						</div>
					</StyledEngineProvider>
				</ThemeProvider>
			);
		}
	}

	_onChange() {
		let profile = ProfileStore.getProfile();
		if (profile && !did_parse) {
			did_parse = true;
			AppGridActions.setAppStatusAsParsing();
			SearchTermsParser.parseValidSearchTermsFromURL((search_terms) => {
				SearchActions.updateSearchTermsViaUrl(search_terms);
				let search_token = SearchStore.getToken();
				let hotel_page = this.isSpecificPage(Routes.HOTEL);
				if (search_terms.tas_id && _.get(profile, 'company.settings.combtas_integration_enabled')) {
					setTasIdInSessionStorage(search_terms.tas_id);
					this.setState({ tasId: search_terms.tas_id });
				}
				if (this.isSpecificPage(Routes.RESULTS) || (search_token && hotel_page)) {
					if (
						hotel_page &&
						!_.get(search_terms, 'destination.place.place_id') &&
						!_.get(search_terms, 'destination.place.hotel_id')
					) {
						const hotel_id = parseHotelId();
						if (hotel_id) {
							_.set(search_terms, 'destination.place.hotel_id', hotel_id);
						}
					}
					if (ProfileStore.isUseUserCurrency()) {
						_.set(search_terms, 'user_currency', ProfileStore.getUserCurrency());
					}
					if (SearchTermsParser.validate(search_terms)) {
						SearchActions.search(search_token, { forceCache: hotel_page });
					} else {
						AppGridActions.setAppStatusAsIdle();
						RouterWrapper.goToSearchPage(this.props.navigate, search_terms);
					}
				} else {
					AppGridActions.setAppStatusAsIdle();
				}
			});
		} else if (
			ProfileStore.isUseUserCurrency() &&
			user_currency &&
			user_currency !== ProfileStore.getUserCurrency()
		) {
			SearchTermsParser.parseValidSearchTermsFromURL((search_terms) => {
				let search_token = SearchStore.getToken();
				let hotel_page = this.isSpecificPage(Routes.HOTEL);
				if (this.isSpecificPage(Routes.RESULTS) || (search_token && hotel_page)) {
					_.set(search_terms, 'user_currency', ProfileStore.getUserCurrency());

					if (hotel_page) {
						const hotel_id = parseHotelId();
						if (hotel_id) {
							_.set(search_terms, 'destination.place.hotel_id', hotel_id);
							AppGridActions.setAppStatusAsIdle();
							HotelActions.clearHotelDeals(hotel_id);
							HotelActions.getHotelRecheckedDeals(hotel_id, search_token);
						}
					} else {
						SearchActions.search(null, { forceCache: hotel_page });
					}
				}
			});
		}
		user_currency = ProfileStore.getUserCurrency();
		this.setState(getAppGridState());
	}
}

function getAppGridState() {
	const _state = {
		profile: ProfileStore.getProfile(),
		sessionTimeoutModalIsOpen: !ProfileStore.isSessionActive(),
		idle: AppGridStore.isIdle(),
		pending: AppGridStore.isPending(),
		parsing: AppGridStore.isParsing(),
		did_boot,
		has_contract_id: !!SearchStore.getContractId(),
	};
	return _state;
}

function parseHotelId() {
	const split_path = new URL(window.location.href).pathname.split('/').filter(_.identity);

	if (split_path[0].toLowerCase() === 'hotel') {
		return split_path[2];
	}

	return null;
}

export default AppGrid;
