import { createTheme } from '@mui/material/styles';
import defaultThemeConfig from 'arbitrip-common/theme/colors/configs/default.json';
import { transformKeys } from 'arbitrip-common/theme/colors/helpers';

const createAppTheme = (themeConfig = defaultThemeConfig) => {
	const customConfig = transformKeys(themeConfig);

	const palette = {
		mode: 'light',
		primary: {
			main: '#275180',
		},
		danger: {
			main: '#FF5353',
		},
		black: {
			main: customConfig.general?.colors?.black ?? '#20242A',
		},
		success: {
			main: '#008F4B',
		},
		pending: {
			main: '#DD8605',
			orange: '#FF7923',
		},
		gray: {
			main: customConfig.general?.colors?.gray?.dark ?? '#4B575B',
			gray2: '#919595',
			gray3: '#BCC0C0',
			gray4: '#D7DADA',
			gray5: customConfig.general?.colors?.gray?.light ?? '#F6F6F6',
			gray6: '#8D8F93',
			mainHover: '#e0e0e0',
		},
		blue: {
			main: '#275180',
			mainHover: '#3f5c80',
			secondary: '#356E82',
			lightBlue: '#D1F0FF',
			lightBlueHover: '#B0E5FF',
			blue2: '#2d65a1',
		},
		white: '#FFF',
		...customConfig,
	};

	return createTheme({
		typography: {
			useNextVariants: true,
			fontFamily: ['Lato', 'Roboto', 'sans-serif'].join(','),
		},
		palette,
		components: {
			MuiButton: {
				styleOverrides: {
					root: {
						textTransform: 'capitalize',
						borderRadius: 8,
					},
				},
			},
			MuiToggleButton: {
				styleOverrides: {
					root: {
						backgroundColor: '#fff',
						textTransform: 'capitalize',
						borderRadius: 8,
					},
				},
			},
			MuiToggleButtonGroup: {
				styleOverrides: {
					grouped: {
						'&:not(:first-of-type)': {
							marginLeft: 0,
							marginTop: 0,
						},
					},
				},
			},
			MuiOutlinedInput: {
				styleOverrides: {
					root: {
						backgroundColor: '#fff',
						'&:hover .MuiOutlinedInput-notchedOutline': {
							borderColor: '#275180',
						},
					},
				},
			},
			MuiInput: {
				styleOverrides: {
					underline: {
						'&:hover:not($disabled):before': {
							borderBottom: '1px solid rgba(0, 0, 0, 0.67) !important',
						},
					},
				},
			},
			MuiCheckbox: {
				defaultProps: {
					disableRipple: true,
				},
				styleOverrides: {
					root: {
						boxColor: '#275180',
						color: '#BCC0C0',
					},
				},
			},
			MuiMenuItem: {
				styleOverrides: {
					root: {
						'&.Mui-selected': {
							backgroundColor: 'rgba(0, 0, 0, 0.08)',
						},
						'&.Mui-selected:hover': {
							backgroundColor: 'rgba(0, 0, 0, 0.04)',
						},
					},
				},
			},
			SearchAutocomplete: {
				styleOverrides: {
					root: {
						'& .Mui-selected': {
							backgroundColor: 'rgba(0, 0, 0, 0.08)',
						},
						'& .Mui-selected:hover': {
							backgroundColor: 'rgba(0, 0, 0, 0.04)',
						},
					},
				},
			},
			MuiAutocomplete: {
				styleOverrides: {
					option: {
						'&[aria-selected="true"]:not(.Mui-focused)': {
							backgroundColor: '#fff',
						},
					},
				},
			},
			MuiTooltip: {
				styleOverrides: {
					tooltip: {
						boxShadow:
							'0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
						backgroundColor: '#27292C',
						color: '#EEEFF0',
						fontSize: 12,
						padding: '4px 10px 5px 10px',
					},
					arrow: {
						color: '#27292C',
					},
				},
			},
			MuiTab: {
				styleOverrides: {
					root: {
						textTransform: 'none',
						padding: '0 16px',
						fontSize: 15,
						'&.Mui-selected': {
							fontWeight: 700,
						},
					},
				},
			},
		},
	});
};

export default createAppTheme;
