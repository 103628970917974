const CONSTANTS = module.exports = {};

CONSTANTS.RESERVATION_STATUS = { // This represents to outside world and to booking page specifically current state of reservation
    NA: 'not_exists',                               // default 0-state of an enum
    INITIALIZED: 'initialized',                     // We entered into reservation page for this deal
    QUOTE: 'quote',                                 // We are get a quote for a reservation. Nothing is booked yet
    PENDING_SUPPLIER: 'pending-supplier',           // We are waiting for supplier final acknowledgment
    PENDING_PAYMENT: 'pending-payment',             // Obsolete
    PENDING_BOOKING: 'pending-booking',             // We have received payment and now we are waiting to book from supplier
    PENDING_SUPPORT: 'pending-support',             // We are waiting for arbitrip support to deal with this (e.g. manually booking, deal rejected by supplier etc).
    PENDING_CANCELLATION: 'pending-cancellation',   // We are waiting for arbitrip support to check if this reservation was created and cancel it if so.
    REBOOKED: 'rebooked',                           // Reservation was rebooked for a better deal
    APPROVED: 'approved',                           // Reservation is confirmed by supplier
    ABORTED: 'aborted',                             // A reservation didn't reach an approved state and was stopped
    REJECTED: 'rejected',                           // A reservation rejected by travel manager rejecting a request
    CANCELLED: 'cancelled',                         // Arbitrip cancelled an approved the reservation. Need to show how much cancellation policy cost.
    REQUESTED: 'requested',                         // Reservation was sent as a request to Travel Manager (or direct manager), was not booked
    ERROR: 'error'                                  // an irrecoverable error occurred
};

CONSTANTS.EXTERNAL_CANCELLATION_SOURCES = {
    AFFILIATE: 'affiliate',
    HOTEL: 'hotel',
    FRAUD: 'fraud'
};

CONSTANTS.RESERVATION_PROVIDER_STATUS = {
    NA: 'not_exists',                                           // default 0-state of an enum
    PENDING: 'pending',                                         // received a reservation by client but supplier hasn't answered yet
    PENDING_ADMIN_BOOKING: 'pending-admin-booking',             // used for non refundable deals handled manually
    PENDING_ADMIN_REVIEW: 'pending-admin-review',               // got an answer by supplier, that isn't OK ...
    APPROVED: 'approved',                                       // Supplier has approved the deal
    CANCELLED: 'cancelled',                                     // Supplier rejected deal
    ERROR: 'error',                                             // an irrecoverable error occurred
    ERROR_PENDING_CONFIRMATION: 'error-pending-confirmation',   // an irrecoverable error occurred on reservation that is not refundable
    ERROR_PENDING_CANCELLATION: 'error-pending-cancellation',   // an irrecoverable error occurred on reservation that is refundable
};

CONSTANTS.STATUS_WEIGHT = (status) => {
    switch (status) {
        case CONSTANTS.RESERVATION_STATUS.NA:
        case CONSTANTS.RESERVATION_STATUS.PENDING_SUPPLIER:
        case CONSTANTS.RESERVATION_STATUS.INITIALIZED:
        case CONSTANTS.RESERVATION_STATUS.ERROR:
            return 0;
        case CONSTANTS.RESERVATION_STATUS.CANCELLED:
            return 1;
        case CONSTANTS.RESERVATION_STATUS.APPROVED:
            return 2;
        case CONSTANTS.RESERVATION_STATUS.PENDING_BOOKING:
        case CONSTANTS.RESERVATION_STATUS.PENDING_SUPPORT:
        case CONSTANTS.RESERVATION_STATUS.REBOOKED:
        case CONSTANTS.RESERVATION_STATUS.ABORTED:
        case CONSTANTS.RESERVATION_STATUS.REJECTED:
        case CONSTANTS.RESERVATION_STATUS.REQUESTED:
        case CONSTANTS.RESERVATION_STATUS.QUOTE:
            return 4;
        default:
            return 0;
    }

}

CONSTANTS.RESERVATION_REQUEST_ACTIONS = {
    APPROVE: 'approve',
    REJECT: 'reject'
};

CONSTANTS.ARBITRIP_LOYALTY_EARNED_SOURCE_TYPES = {
    PERSONAL: 'personal',
    COMPANY: 'company',
    CONTRACT: 'contract',
};
