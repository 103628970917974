import _ from 'lodash';
import { CircularProgress, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React from 'react';
import ResultsActions from '../../../../actions/ResultsActions';
import WebStorageManager from '../../../../utils/WebStorageManager';
import { Pie } from 'react-chartjs-2';
import { ProfileConstants } from 'arbitrip-common/client/constants';

import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const label_style = {
	color: '#888',
	fontWeight: 400,
	fontSize: 14,
	width: 112,
	display: 'inline-block',
	paddingLeft: 0,
};

const input_style = {
	fontSize: 12,
	width: 54, // 112,
	display: 'inline-block',
	paddingLeft: 4,
	paddingRight: 4,
};

const normalized_input_style = Object.assign({}, input_style, {
	marginLeft: 4,
});

const hr_style = {
	margin: 4,
};

const accordion_style = {
	width: '100%',
	padding: 0,
	border: 0,
	boxShadow: 0,
};

function fixit(num) {
	return Math.round(num * 100) / 100;
}

class WeightsFilter extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			recommendation_profile: WebStorageManager.getFromWebStorage('_recommendation_profile'),
			recommendation_weights: WebStorageManager.getFromWebStorage('_recommendation_weights'),
			recommendation_mode:
				WebStorageManager.getFromWebStorage('_recommendation_mode') ||
				ProfileConstants.RECOMMENDATION_MODES.BY_REGION,
			expanded: false,
		};

		// this.recalculate = _.debounce(() => {
		this.recalculate = _.throttle(() => {
			ResultsActions.recalculateRecommendations();
			this.setState({ busy: false });
			// }, 500);
		}, 1000);

		this.updateWeight = this.updateWeight.bind(this);
		this.renderWeight = this.renderWeight.bind(this);

		this.handleProfileChange = this.handleProfileChange.bind(this);
		this.renderProfiles = this.renderProfiles.bind(this);

		this.handleModeChange = this.handleModeChange.bind(this);
		this.renderModes = this.renderModes.bind(this);

		this.handleAccordionChange = this.handleAccordionChange.bind(this);
	}

	onClick(e) {
		ResultsActions.recalculateRecommendations();
	}

	updateWeight(weight, value) {
		const parsed_value = parseInt(value.trim() || 0);
		if (!isNaN(parsed_value)) {
			const { recommendation_profile, recommendation_weights } = this.state;

			recommendation_weights[recommendation_profile][weight] = parsed_value;

			WebStorageManager.updateInWebStorage('_recommendation_weights', recommendation_weights);
			this.setState({
				recommendation_weights,
				busy: true,
			});

			this.recalculate();
		}
	}

	renderWeight(weight, value, label, total, forceDisabled = false, overrideStyle = {}) {
		const normalized_value = total > 0 ? fixit((Number(value || 0) / Number(total)) * 100) : '-';
		const disabled = forceDisabled; // || this.state.busy;
		return (
			<div>
				<label
					className="filter-title"
					htmlFor="diff-per-input"
					style={_.merge({}, label_style, overrideStyle)}
				>
					{label}
				</label>
				<input
					style={input_style}
					id={`${weight}_input`}
					type="text"
					className="form-control"
					placeholder=""
					value={value}
					onChange={disabled ? _.noop : (e) => this.updateWeight(weight, e.target.value)}
					disabled={disabled}
				/>
				<input
					style={normalized_input_style}
					id={`normalized_${weight}_input`}
					type="text"
					className="form-control"
					placeholder=""
					value={normalized_value}
					disabled={true}
				/>
			</div>
		);
	}

	handleModeChange(event) {
		const recommendation_mode = event.target.value;

		WebStorageManager.updateInWebStorage('_recommendation_mode', recommendation_mode);
		this.setState({
			recommendation_mode,
			busy: true,
		});

		const _self = this;
		setTimeout(() => {
			_self.setState({ busy: false });
		}, 500);
	}

	handleProfileChange(event) {
		const recommendation_profile = event.target.value;

		WebStorageManager.updateInWebStorage('_recommendation_profile', recommendation_profile);
		this.setState({
			recommendation_profile,
			busy: true,
		});

		this.recalculate();
	}

	handleAccordionChange(panel) {
		const _self = this;
		return (event, isExpanded) => {
			_self.setState({
				expanded: isExpanded ? panel : false,
			});
		};
	}

	renderModes() {
		const radio_size = 'small';
		const label_placement = 'bottom';
		const label_style = { width: 80 };

		return (
			<FormControl component="fieldset" style={{ marginBottom: 8 }}>
				<RadioGroup
					row
					aria-label="recommendation-mode"
					name="recommendation-mode-radio-buttons-group"
					value={this.state.recommendation_mode}
					onChange={this.handleModeChange}
				>
					<FormControlLabel
						value={ProfileConstants.RECOMMENDATION_MODES.BY_REGION}
						control={<Radio size={radio_size} disabled={this.state.busy} />}
						label="By Region"
						labelPlacement={label_placement}
						style={label_style}
					/>
					<FormControlLabel
						value={ProfileConstants.RECOMMENDATION_MODES.BY_RADIUS}
						control={<Radio size={radio_size} disabled={this.state.busy} />}
						label="By Radius"
						labelPlacement={label_placement}
						style={label_style}
					/>
				</RadioGroup>
			</FormControl>
		);
	}

	renderProfiles() {
		const radio_size = 'small';
		const label_placement = 'bottom';
		const label_style = { width: 42 };

		return (
			<FormControl component="fieldset" style={{ marginBottom: 8 }}>
				<RadioGroup
					row
					aria-label="recommendation-profile"
					name="recommendation-profile-radio-buttons-group"
					value={this.state.recommendation_profile}
					onChange={this.handleProfileChange}
				>
					<FormControlLabel
						value={ProfileConstants.RECOMMENDATION_PROFILES.NORMAL}
						control={<Radio size={radio_size} disabled={this.state.busy} />}
						label="Normal"
						labelPlacement={label_placement}
						style={label_style}
					/>
					<FormControlLabel
						value={ProfileConstants.RECOMMENDATION_PROFILES.LUXURY}
						control={<Radio size={radio_size} disabled={this.state.busy} />}
						label="Luxury"
						labelPlacement={label_placement}
						style={label_style}
					/>
					<FormControlLabel
						value={ProfileConstants.RECOMMENDATION_PROFILES.BUDGET}
						control={<Radio size={radio_size} disabled={this.state.busy} />}
						label="Budget"
						labelPlacement={label_placement}
						style={label_style}
					/>
				</RadioGroup>
			</FormControl>
		);
	}

	render() {
		const { recommendation_profile, recommendation_weights } = this.state;

		const {
			leisure_diff_abs,
			leisure_diff_per,

			leisure_score_total,

			leisure_score_location,
			leisure_score_amenities,
			leisure_score_vibe,
			leisure_score_popularity,

			leisure_cheapest_supplier_factor,
			leisure_review_score,
			leisure_stars,

			leisure_distance,

			leisure_price,
			leisure_cheapest,

			neighborhood_match,
		} = recommendation_weights[recommendation_profile];

		const total =
			+Number(leisure_diff_abs || 0) +
			Number(leisure_diff_per || 0) +
			Number(leisure_score_total || 0) +
			Number(leisure_cheapest_supplier_factor || 0) +
			Number(leisure_review_score || 0) +
			Number(leisure_stars || 0) +
			Number(leisure_distance || 0) +
			Number(leisure_price || 0) +
			Number(leisure_cheapest || 0) +
			Number(neighborhood_match || 0);

		const leisure_total =
			+Number(leisure_score_location || 0) +
			Number(leisure_score_amenities || 0) +
			Number(leisure_score_vibe || 0) +
			Number(leisure_score_popularity || 0);

		const chart_data = {
			datasets: [
				{
					data: [
						neighborhood_match,

						leisure_diff_abs,
						leisure_diff_per,

						leisure_score_total,

						leisure_cheapest_supplier_factor,
						leisure_review_score,
						leisure_stars,

						leisure_distance,

						leisure_price,
						leisure_cheapest,
					],
					backgroundColor: [
						'#FF0000',
						'#00FF00',
						'#0000FF',

						'#FFFF00',
						'#FF00FF',
						'#00FFFF',

						'#FF8000',
						'#FF0080',

						'#0080FF',
						'#8000FF',

						'#00FF80',
						'#80FF00',

						// '#000000'
					],
				},
			],

			// These labels appear in the legend and in the tooltips when hovering different arcs
			labels: [
				'NeighborhoodMatch',

				'DiffAbs',
				'DiffPer',

				'LS',

				'CSF',
				'ReviewScore',
				'Stars',

				'Distance',

				'Price',
				'Cheapest',
			],
		};

		return (
			<div className="filter" style={{ paddingTop: 6, paddingBottom: 7 }}>
				<div
					style={{
						position: 'absolute',
						top: -60,
						left: -300,
					}}
				>
					{/* <Doughnut data={chart_data} /> */}
					<Pie data={chart_data} width={300} height={300} />
				</div>
				<label className="filter-title" htmlFor="weights-filter">
					Weights{' '}
					{this.state.busy && <CircularProgress size={15} style={{ position: 'absolute', right: 12 }} />}
				</label>
				{this.renderModes()}
				{this.renderProfiles()}
				<Accordion
					expanded={this.state.expanded === 'panel1'}
					onChange={this.handleAccordionChange('panel1')}
					style={accordion_style}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
						style={accordion_style}
					>
						<Typography style={accordion_style}>
							{this.state.expanded ? 'Hide Weights' : 'Show Weights'}
						</Typography>
					</AccordionSummary>
					<AccordionDetails style={accordion_style}>
						<div name="weights-filter" className="check-list">
							{this.renderWeight('leisure_diff_abs', leisure_diff_abs, 'DiffAbs', total)}
							<hr style={hr_style} />
							{this.renderWeight('leisure_diff_per', leisure_diff_per, 'DiffPer', total)}
							<hr style={hr_style} />
							{this.renderWeight('leisure_score_total', leisure_score_total, 'LS', total)}
							<hr style={hr_style} />

							{this.renderWeight(
								'leisure_score_location',
								leisure_score_location,
								'* Location',
								leisure_total,
							)}
							<hr style={hr_style} />
							{this.renderWeight(
								'leisure_score_amenities',
								leisure_score_amenities,
								'* Amenities',
								leisure_total,
							)}
							<hr style={hr_style} />
							{this.renderWeight('leisure_score_vibe', leisure_score_vibe, '* Vibe', leisure_total)}
							<hr style={hr_style} />
							{this.renderWeight(
								'leisure_score_popularity',
								leisure_score_popularity,
								'* Popularity',
								leisure_total,
							)}
							<hr style={hr_style} />

							{this.renderWeight(
								'leisure_cheapest_supplier_factor',
								leisure_cheapest_supplier_factor,
								'CSF',
								total,
							)}
							<hr style={hr_style} />
							{this.renderWeight('leisure_review_score', leisure_review_score, 'ReviewScore', total)}
							<hr style={hr_style} />
							{this.renderWeight('leisure_stars', leisure_stars, 'Stars', total)}
							<hr style={hr_style} />

							{this.renderWeight('leisure_distance', leisure_distance, 'Distance', total)}
							<hr style={hr_style} />

							{this.renderWeight('leisure_price', leisure_price, 'Price', total)}
							<hr style={hr_style} />
							{this.renderWeight('leisure_cheapest', leisure_cheapest, 'Cheapest', total)}
							<hr style={hr_style} />
							{this.renderWeight(
								'neighborhood_match',
								neighborhood_match,
								'NeighborhoodMatch',
								total,
								false,
								{ fontSize: 11 },
							)}

							<hr style={hr_style} />
							{this.renderWeight(null, total, 'Total', total, true)}
						</div>
					</AccordionDetails>
				</Accordion>
				{/* <hr style={hr_style} /> */}
				<div
					style={{
						textAlign: 'center',
						paddingTop: 8,
					}}
				>
					<a href="#!" onClick={this.onClick}>
						Refresh
					</a>
				</div>
				{/* {
                    (WEIGHTS.length > 1)
                        ? <div className="clear-button" onClick={this.handleResetWeightsClick} />
                        : null
                } */}
			</div>
		);
	}
}

export default WeightsFilter;

// import React from 'react';

// import FiltersActions from '../../../../actions/FiltersActions';

// import FiltersConstants from '../../../../constants/FiltersConstants';
// import Analytics from 'arbitrip-common/client/analytics';

// import FilterCheckboxWithLabel from './components/FilterCheckboxWithLabel.react';

// class WeightsFilter extends React.Component {
//     constructor(props) {
//         super(props);

//         this.handleCheckboxChangeByWeight = this.handleCheckboxChangeByWeight.bind(this);
//         this.handleWeightOnlyClick = this.handleWeightOnlyClick.bind(this);
//         this.handleResetWeightsClick = this.handleResetWeightsClick.bind(this);
//     }

//     handleCheckboxChangeByWeight = (event, isInputChecked, weight) => {
//         const { profile } = this.props;

//         event.persist();
//         FiltersActions.filterByWeight(weight, isInputChecked);
//         setTimeout(() => {
//             FiltersActions.filterByWeight(weight, isInputChecked)
//         }, 10);

//         const filter_value = (isInputChecked ? 'Filter by "' : 'Don\'t filter by"') + weight + '"';
//         Analytics.actions.interactions.filteredResults(profile, FiltersConstants.FILTER_METHOD.WEIGHT, filter_value);
//     };

//     handleWeightOnlyClick = (event) => {
//         const { profile } = this.props;

//         event.persist();
//         let weight_only = event.target.getAttribute('data-weight');
//         FiltersActions.filterByWeightOnly(weight_only);
//         setTimeout(() => {
//             FiltersActions.filterByWeightOnly(weight_only);
//         }, 10);

//         const filter_value = 'Show Only "' + weight_only + '" Weight';
//         Analytics.actions.interactions.filteredResults(profile, FiltersConstants.FILTER_METHOD.WEIGHT_ONLY, filter_value);
//     };

//     handleResetWeightsClick = (event) => {
//         const { profile } = this.props;

//         event.persist();
//         FiltersActions.resetWeightsFilter();
//         setTimeout(FiltersActions.resetWeightsFilter, 10);

//         const filter_value = 'Reset Weights Filter';
//         Analytics.actions.interactions.filteredResults(profile, FiltersConstants.FILTER_METHOD.RESET_WEIGHTS_FILTER, filter_value);
//     };

//     render() {
//         const data = this.props.filter;
//         const multiple_weights = Object.keys(data).length > 1;

//         const WEIGHTS = [];
//         for (let weight in data) {
//             const weight_key = weight.toLowerCase().replace(' ', '-');
//             WEIGHTS.push(
//                 <div className="checkbox-container" key={weight_key}>
//                     <FilterCheckboxWithLabel
//                         checked={data[weight]}
//                         category={weight}
//                         label={weight}
//                         onChange={(e, c, weight) => this.handleCheckboxChangeByWeight(e, c, weight)}
//                     />
//                     {
//                         multiple_weights
//                             ? <button className="btn btn-link" data-weight={weight}
//                                 onClick={this.handleWeightOnlyClick}>Only</button>
//                             : null
//                     }
//                 </div>
//             );
//         }

//         return (
//             <div className="filter disabled">
//                 <label className="filter-title" htmlFor='weights-filter'>
//                     Weights
//                 </label>
//                 <div name='weights-filter' className="check-list">
//                     {WEIGHTS}
//                 </div>
//                 {
//                     (WEIGHTS.length > 1)
//                         ? <div className="clear-button" onClick={this.handleResetWeightsClick} />
//                         : null
//                 }
//             </div>
//         );
//     }
// }

// export default WeightsFilter;
