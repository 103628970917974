import withStyles from '@mui/styles/withStyles';

import Icon from '../../ReactEvilIcon';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';

import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import ImageCarousel from '../../general/ImageCarousel.react';
import AmenitiesComponent from './AmenitiesComponent.react';
import { withTranslation } from 'react-i18next';
import { ReactComponent as DisclaimerIcon } from '@/img/hotel_page/disclaimer_icon.svg';

const styles = (theme) => ({
	dialogTitle: {
		padding: '32px 48px 32px',
		fontSize: 24,
		fontWeight: 700,
		lineHeight: '29px',
		color: theme.palette.black.main,
	},
	closeButton: {
		position: 'absolute',
		top: 19,
		right: 19,
		color: '#ccc',
		'&:hover': {
			color: '#666666',
		},
	},
	evilIcon: {
		width: 32,
		height: 32,
	},
});

const CloseIcon = ({ onClose, classes }) =>
	onClose ? (
		<button
			className={classNames('action', 'close-modal', classes.closeButton)}
			onClick={onClose}
			aria-label="Close"
		>
			<Icon name="ei-close" size="m" className={classes.evilIcon} />
		</button>
	) : null;
class RoomsCatalogDialog extends React.Component {
	componentDidUpdate(prevProps) {
		let { profile, hotel, room, analyticsFunc } = this.props;
		if (!prevProps.open && this.props.open && hotel && room) {
			if (_.isFunction(analyticsFunc)) {
				analyticsFunc(profile.company, hotel, room);
			}
		}
	}

	getNoRoomRender() {
		const { classes, onClose } = this.props;

		return (
			<React.Fragment>
				<DialogTitle id="dialog-title" className={classes.dialogTitle} style={{}}>
					It seems that the room data is missing
					<CloseIcon onClose={onClose} classes={classes} />
				</DialogTitle>
			</React.Fragment>
		);
	}

	getRoomContentRender() {
		const { room } = this.props;

		if (room.amenities) {
			return this.expediaRoom();
		} else {
			return this.gimmonixRoom();
		}
	}

	expediaRoom() {
		const { room, classes, onClose, roomName, isDev, profile } = this.props;

		const roomSize = _.get(room, 'info.room_size.metre_square');
		const amenitiesList = room.amenities.map((amenity) => amenity.name);

		let name = roomName ?? room.name;
		if (isDev) {
			name += `\n - Expedia: ${room.name}(${room.id})`;
		}

		const title_classes = `${classes.dialogTitle} rooms-catalog-dialog-title`;

		const isExpediaPartner = _.get(profile, 'company.partners_ui.expedia_partner', false);

		return (
			<React.Fragment>
				<DialogTitle id="dialog-title" className={title_classes}>
					{name}
					<CloseIcon onClose={onClose} classes={classes} />
				</DialogTitle>
				<DialogContent>
					<div className="flex flex-column rooms-catalog-dialog-content">
						{room.images?.length > 0 && <ImageCarousel images={room.images} type="medium" />}
						<div className={classNames({ [classes.description]: room.images && room.images.length > 0 })}>
							{room.description && (
								<div
									className="description-wrapper"
									dangerouslySetInnerHTML={{ __html: room.description }}
								/>
							)}
							<br />
							{roomSize && (
								<div className="room-size">
									<strong>Room size:</strong> {roomSize} Sq Meters
								</div>
							)}
							{room.amenities?.length > 0 && (
								<div className="amenities-container">
									<div className="amenities-title">Room Amenities:</div>
									<AmenitiesComponent amenities={amenitiesList} />
								</div>
							)}
							{isExpediaPartner && this.getAvailabilityDisclaimer()}
						</div>
					</div>
				</DialogContent>
			</React.Fragment>
		);
	}

	getAvailabilityDisclaimer() {
		return (
			<div className="disclaimer disclaimer-flex">
				<DisclaimerIcon />
				<div>Room and bed type depends on availability at check-in.</div>
			</div>
		);
	}

	gimmonixImageDisclaimer() {
		const { t } = this.props;

		return (
			<div className="disclaimer tooltip-icon">
				<span>Images and amenities are illustrative only.&nbsp;</span>
				<a href={t('TermsAndConditionsUrl')} target="_blank">
					See T&C for details
				</a>
			</div>
		);
	}

	gimmonixRoom() {
		const { room, classes, onClose } = this.props;

		const amenitiesList = room.perks;

		const images = room.images.map((image) => ({
			original: image.imageUrl,
			description: image.imageDescription,
		}));

		if (!images.length) {
			images.push({
				original: '../../img/hotel_page/room_placeholder.png',
			});
		}

		return (
			<React.Fragment>
				<DialogTitle id="dialog-title" className={classes.dialogTitle}>
					{room.name}
					<CloseIcon onClose={onClose} classes={classes} />
				</DialogTitle>
				<DialogContent>
					<div className="flex flex-column rooms-catalog-dialog-content">
						{images?.length > 0 && (
							<ImageCarousel
								images={images}
								type="medium"
								disclaimer={amenitiesList?.length < 6 && this.gimmonixImageDisclaimer()}
							/>
						)}
						<div className={classNames({ [classes.description]: images && images.length > 0 })}>
							{room.roomDescriptionTitle && (
								<p>
									<strong>{room.roomDescriptionTitle}</strong>
								</p>
							)}
							{room.roomDescriptionParagraph && (
								<div
									className="description-wrapper"
									dangerouslySetInnerHTML={{ __html: room.roomDescriptionParagraph }}
								/>
							)}
							<br />
							{amenitiesList?.length > 0 && (
								<div className="amenities-container">
									<div className="amenities-title">Room Amenities:</div>
									<AmenitiesComponent amenities={amenitiesList} />
								</div>
							)}
						</div>
					</div>
				</DialogContent>
			</React.Fragment>
		);
	}

	render() {
		const { open, room, onClose } = this.props;

		return (
			<Dialog fullWidth={true} maxWidth="md" transitionDuration={400} open={open} onClose={onClose}>
				{room ? this.getRoomContentRender() : this.getNoRoomRender()}
			</Dialog>
		);
	}
}

RoomsCatalogDialog.propTypes = {
	profile: PropTypes.any,
	room: PropTypes.object,
	roomName: PropTypes.string,
	isDev: PropTypes.bool,
	hotel: PropTypes.object,
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func,
	classes: PropTypes.object,
	analyticsFunc: PropTypes.func,
};

export default withStyles(styles)(withTranslation()(RoomsCatalogDialog));
