import keyMirror from 'keymirror';
import _ from 'lodash';

const TRAVEL_AGENT_CONSTANTS = {
	STATUS: {
		INITIAL: 'initial',
		BUSY: 'busy',
		SUCCESS: 'success',
		FAILED: 'failed',
	},

	MODAL_ACTIONS: {
		CANCEL: 'cancel',
		CLOSE: 'close',
		CHANGE_CLIENT: 'change-client',
		CLOSE_REQUEST: 'close-request',
	},
};

// Define action constants
export default _.extend(
	{},
	TRAVEL_AGENT_CONSTANTS,
	keyMirror({
		OPEN_TRAVEL_AGENT_SETTINGS_MODAL: null,
		CLOSE_TRAVEL_AGENT_SETTINGS_MODAL: null,

		SEND_BOOKING_CONFIRMATION: null,
		SEND_BOOKING_CONFIRMATION_SUCCESS: null,
		SEND_BOOKING_CONFIRMATION_FAILED: null,

		GET_COMPANIES: null,
		GET_COMPANIES_SUCCESS: null,
		GET_COMPANIES_FAILED: null,

		GET_COMPANY_CONTRACT: null,
		GET_COMPANY_CONTRACT_SUCCESS: null,
		GET_COMPANY_CONTRACT_FAILED: null,

		UPDATE_COMPANY_CONTRACT: null,
		UPDATE_COMPANY_CONTRACT_SUCCESS: null,
		UPDATE_COMPANY_CONTRACT_FAILED: null,
	}),
);
