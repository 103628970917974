import { useTheme } from '@mui/material/styles';
import { COLORS } from './constants';

const Plus = ({ color = COLORS.BLUE, style }) => {
	const theme = useTheme();

	const colorMap = {
		[COLORS.BLUE]: theme.palette.blue.blue2,
		[COLORS.BLACK]: theme.palette.black.main,
	};

	const strokeColor = colorMap[color];

	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={{ ...style }}
		>
			<path d="M12 5V19" stroke={strokeColor} strokeWidth="1.5" strokeLinejoin="round" />
			<path d="M5 12H19" stroke={strokeColor} strokeWidth="1.5" strokeLinejoin="round" />
		</svg>
	);
};

export default Plus;
