import FirebaseWrapper from './ListenerSubscriber';
import DefaultConfig from "./DefaultConfig";

import onMultiSnapshotWrapper from './onMultiFieldSnapshotWrapper';
import _ from 'lodash';

const DEFAULT_RECHECK_SUBSCRIPTION_TIMEOUT_IN_SECONDS = 60;

class RecheckfirebaseWrapper extends FirebaseWrapper {
    constructor(collection, subcollection, multi_snapshot_fields_template, prefix_message) {
        super(DefaultConfig.default_config, collection, subcollection, multi_snapshot_fields_template, prefix_message, onMultiSnapshotWrapper);

        this._config.subscriptionTimeoutInSeconds = process.env.REACT_APP_FIREBASE_RECHECK_TIMEOUT_IN_SECONDS || DEFAULT_RECHECK_SUBSCRIPTION_TIMEOUT_IN_SECONDS;
    }

    static isValid() {
        // const config = _.pick(this._config, [
        const config = _.pick(DefaultConfig.default_config, [
            'apiKey',
            'authDomain',
            'databaseURL',
            'projectId',
            'storageBucket',
            'messagingSenderId',
            'appId',
            'measurementId',
        ]);
        return Object.values(config).every((val) => _.isString(val) && (val !== ''));
    }
}
export default RecheckfirebaseWrapper;