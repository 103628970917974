import withStyles from '@mui/styles/withStyles';

import _ from 'lodash';
import classNames from 'classnames';

import React from 'react';
import PropTypes from 'prop-types';

import SearchActions from '../../../actions/SearchActions';
import RecentSearchesActions from '../../../actions/RecentSearchesActions';

import moment from 'moment';
import StringShortner from '../../../utils/StringShortener';

import Analytics from 'arbitrip-common/client/analytics';

const styles = {};

function getState() {
	return {
		isHovering: false,
		removalIsHovering: false,
	};
}

class RecentSearchComponent extends React.Component {
	constructor(props) {
		super(props);

		this.state = getState();

		this._onRecentSearchComponentClick = this._onRecentSearchComponentClick.bind(this);
		this.handleMouseEnter = this.handleMouseEnter.bind(this);
		this.handleMouseLeave = this.handleMouseLeave.bind(this);
		this.handleRemovalMouseEnter = this.handleRemovalMouseEnter.bind(this);
		this.handleRemovalMouseLeave = this.handleRemovalMouseLeave.bind(this);
		this.onRemoveRecentSearchClick = this.onRemoveRecentSearchClick.bind(this);
	}

	_onRecentSearchComponentClick() {
		const { profile, search } = this.props;
		let { removalIsHovering } = this.state;

		if (!removalIsHovering) {
			let recentSearchTerms = search.terms;
			SearchActions.updateSearchTerms(recentSearchTerms);
			Analytics.actions.interactions.selectedRecentSearch(
				profile,
				recentSearchTerms.destination,
				recentSearchTerms,
			);

			// PATCH: pulses the search box upon choosing a new set of search terms
			setTimeout(() => {
				window.Velocity(document.getElementById('searchBox'), 'callout.pulse', {});
			}, 11);
		}
	}

	handleMouseEnter() {
		this.setState({ isHovering: true });
	}

	handleMouseLeave() {
		this.setState({ isHovering: false });
	}

	handleRemovalMouseEnter() {
		this.setState({ removalIsHovering: true });
	}

	handleRemovalMouseLeave() {
		this.setState({ removalIsHovering: false });
	}

	onRemoveRecentSearchClick() {
		RecentSearchesActions.removeRecentSearch(this.props.search);
	}

	getNights() {
		let { search } = this.props;

		const nightCount = moment(search.terms.check_out).diff(moment(search.terms.check_in), 'days');

		return nightCount + (nightCount > 1 ? ' Nights' : ' Night');
	}

	getRoomCount() {
		let { search } = this.props;

		const roomCount = search.terms.rooms;
		return roomCount + (roomCount > 1 ? ' Rooms' : ' Room');
	}

	getTravelers() {
		let { search } = this.props;

		const roomCount = search.terms.rooms;
		const travelerCount = roomCount * search.terms.guests;
		const travelers = travelerCount + (travelerCount > 1 ? ' Travelers' : ' Traveler');

		return travelers;
	}

	getChildren() {
		let { search } = this.props;

		const childrenCount = _.get(search, 'terms.children_ages', []).length;
		let children = childrenCount + (childrenCount > 1 ? ' Children' : ' Child');

		return (
			childrenCount > 0 && (
				<React.Fragment>
					<span>&nbsp;·&nbsp;</span> {children}
				</React.Fragment>
			)
		);
	}

	getAddress() {
		const { search } = this.props;

		const destinationAddress = search.terms.destination.address;
		return StringShortner.shortenString(destinationAddress, 50);
	}

	getRemoveRecentSearch() {
		const { isHovering, removalIsHovering } = this.state;

		return isHovering ? (
			<div className="recent-search-removal-container">
				<button
					onClick={this.onRemoveRecentSearchClick}
					className={classNames('remove-button', removalIsHovering && 'hovering')}
					onMouseEnter={this.handleRemovalMouseEnter}
					onMouseLeave={this.handleRemovalMouseLeave}
				/>
			</div>
		) : null;
	}

	render() {
		const { isHovering } = this.state;
		const { search } = this.props;
		const destinationAddress = search.terms.destination.address;

		return (
			<div
				className={classNames('recent-search-component', isHovering && 'hovering')}
				role="button"
				onClick={this._onRecentSearchComponentClick}
				onMouseEnter={this.handleMouseEnter}
				onMouseLeave={this.handleMouseLeave}
			>
				<div className="recent-search-terms">
					<div className="recent-search-destination" title={destinationAddress}>
						{this.getAddress()}
					</div>
					<div className="recent-search-rest">
						<img src={'/img/search_page/search_bar/calender_list.svg'} className="icon" alt="calender" />
						{moment(search.terms.check_in).format('DD MMM')}
						{' - '}
						{moment(search.terms.check_out).format('DD MMM')}
						<span>&nbsp;·&nbsp;</span>
						{this.getNights()}
					</div>
					<div className="recent-search-rooms-and-travelers">
						<img src={'/img/search_page/search_bar/user_list.svg'} className="icon" alt="user" />
						{this.getRoomCount()}
						<span>&nbsp;·&nbsp;</span>
						{this.getTravelers()}
						{this.getChildren()}
					</div>
				</div>
				{this.getRemoveRecentSearch()}
			</div>
		);
	}
}

RecentSearchComponent.propTypes = {
	profile: PropTypes.any,
	search: PropTypes.any,
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RecentSearchComponent);
