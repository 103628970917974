import React from 'react';
import createClass from 'create-react-class';
import Currencies from '../../../utils/Currencies';
import moment from 'moment';

function formatDate(date) {
	return date && moment(date).format('MMM D');
}

function formatTime(time) {
	if (typeof time === 'string') {
		let split_time = time.split(':');
		let h = Number(split_time[0]);
		let ampm = h <= 11 ? 'am' : 'pm';
		h = (h > 12 ? h % 12 : h).toString(); // 12pm should be displayed as 12pm (not 00 pm)
		return h.padStart(2, '0') + ':' + split_time[1] + ' ' + ampm;
	}

	return '';
}

function formatDuration(duration_hours) {
	let h = Math.floor(duration_hours).toString().padStart(2, '0');
	let m = Math.floor((duration_hours - h) * 60)
		.toString()
		.padStart(2, '0');
	return h + ':' + m;
}

const SEATGURU_MAIN = 'https://www.seatguru.com';
const SEATGURU_LINK_TEMPLATE =
	SEATGURU_MAIN + '/findseatmap/findseatmap.php?carrier={{code}}&flightno={{number}}&date={{date}}';

function getSeatGuruLink(flight) {
	if (!flight || !flight.is_parsed || !flight.airline_code || !flight._number || !flight.depart_date) return null;
	let link = SEATGURU_LINK_TEMPLATE.replace('{{code}}', flight.airline_code)
		.replace('{{number}}', flight._number)
		.replace('{{date}}', encodeURIComponent(moment(flight.depart_date).format('DD/MM/YYYY')));
	return link;
}

const PreviewDetailedAirfare = createClass({
	displayName: 'PreviewDetailedAirfare',

	render: function () {
		let { airfare } = this.props;

		return (
			<div className="airfare">
				<div className="title">
					<span className="airfare-number">{'Airfare #' + airfare.display_order}</span>
				</div>
				<div className="sub-title">
					<div className="cost">
						<span>{'Cost: ' + Currencies.getPriceWithCurrency(airfare.cost, airfare.currency)}</span>
					</div>
					{airfare.pnr ? (
						<div className="pnr">
							<span>{'PNR: ' + airfare.pnr}</span>
						</div>
					) : null}
				</div>
				{Array.isArray(airfare.flights) && airfare.flights.length ? (
					<div className="section flights">
						<div className="title">
							<span>Flights included in this airfare:</span>
						</div>
						<div className="section-content">
							{airfare.flights.map((flight) => {
								let seatguru_link = getSeatGuruLink(flight);
								return (
									<div className="flight" key={flight.id}>
										<div className="field flight-number">
											<span className="field-icon">
												<i className="fa fa-plane" />
											</span>
											<span className="field-name">Flight Number:</span>
											<span className="field-value">{flight.number}</span>
										</div>
										<div className="field airline">
											<span className="field-name">Airline:</span>
											<span className="field-value">{flight.airline_name}</span>
										</div>
										<div className="field depart">
											<span className="field-name">Depart:</span>
											<span className="field-value">{flight.depart_airport.city}</span>
											<span className="field-value">{formatDate(flight.depart_date)}</span>
											<span className="field-value">{formatTime(flight.depart_time)}</span>
										</div>
										<div className="field arrival">
											<span className="field-name">Arrival:</span>
											<span className="field-value">{flight.arrival_airport.city}</span>
											<span className="field-value">{formatDate(flight.arrival_date)}</span>
											<span className="field-value">{formatTime(flight.arrival_time)}</span>
										</div>
										{flight.duration_hours ? (
											<div className="field duration">
												<span className="field-name">Duration:</span>
												<span className="field-value">
													{formatDuration(flight.duration_hours)}
												</span>
											</div>
										) : null}
										{seatguru_link ? (
											<div className="field duration">
												<span className="field-name">
													<a href={seatguru_link} target="_blank" rel="noopener noreferrer">
														View on SeatGuru
													</a>
												</span>
											</div>
										) : null}
									</div>
								);
							})}
						</div>
					</div>
				) : null}
			</div>
		);
	},
});

export default PreviewDetailedAirfare;
