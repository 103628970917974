// import DestinationEntity from './Destination';

function BusinessDestination(business_destination) {
	this.id = business_destination._id;
	this.destination = business_destination.destination; //new DestinationEntity(business_destination.destination.location, business_destination.destination.address);
	this.image = business_destination.image;
	this.name = business_destination.name;
}

export default BusinessDestination;
