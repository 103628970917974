import React from 'react';
import createClass from 'create-react-class';
import moment from 'moment';
import _ from 'lodash';

import ReservationStore from '../../../stores/ReservationStore';
import PaymentStore from '../../../stores/PaymentStore';

import Poller from '../../../utils/Poller';
import { Config } from 'arbitrip-common/client/utils';
const credit_card_url_state_path = 'paymentData.creditCardUrl';

const cg_regex = new RegExp('creditguard'); // for different iframe height component e.g. https://ppsuat.creditguard.co.il?txId=403204ef-4efe-484d-94c7-f1a9bc5561ac

function getCreditCardComponentState() {
	return {
		reservationData: ReservationStore.getReservationData(),
		paymentData: PaymentStore.getPaymentData(),
		didBook: false,
	};
}

const CreditCardComponent = createClass({
	displayName: 'CreditCardComponent',

	getInitialState: function () {
		return getCreditCardComponentState();
	},

	componentDidMount: function () {
		const credit_card_url_exists = _.get(this.state, credit_card_url_state_path);
		if (credit_card_url_exists) {
			Poller.startPaymentStatusPolling();
		}

		ReservationStore.addChangeListener(this._onChange);
		PaymentStore.addChangeListener(this._onChange);
	},

	componentDidUpdate: function (prevProps, prevState) {
		if (!_.get(prevState, credit_card_url_state_path) && _.get(this.state, credit_card_url_state_path)) {
			Poller.startPaymentStatusPolling();
		}
	},

	componentWillUnmount: function () {
		Poller.stopPaymentStatusPolling();

		PaymentStore.removeChangeListener(this._onChange);
		ReservationStore.removeChangeListener(this._onChange);
	},

	getIframeClass: function () {
		let addedAttribute = '';
		const cg = cg_regex.test(this.state.paymentData.creditCardUrl);
		if (cg) {
			addedAttribute = ' cg;';
			if (_.get(this.props, 'profile.company.settings.installments_iframe_enabled', false)) {
				addedAttribute = ' ci'; // We need place for the number of installments
			}
		}
		if (Config.htz) {
			addedAttribute = ' htz';
		}
		return `iframe-container${addedAttribute}`;
	},

	render: function () {
		let charge_date_text = 'Please note: Your credit card will be charged immediately.';
		const charge_date = moment(this.state.reservationData.if_credit_card_charge_date);
		if (charge_date.diff(moment(), 'hours') > 24) {
			const sum_in_charge_currency = _.get(this.state.paymentData, 'sum_in_charge_currency');
			const charge_currency = _.get(this.state.paymentData, 'charge_currency');
			if (sum_in_charge_currency && sum_in_charge_currency) {
				charge_date_text = `Please note: Your credit card will be charged ${sum_in_charge_currency} (${charge_currency}) on ${charge_date.format('DD MMM YYYY')}.`;
			} else {
				charge_date_text = `Please note: Your credit card will be charged on ${charge_date.format('DD MMM YYYY')}.`;
			}
		}

		// TODO: utilize private mode if needed
		// let private_mode = this.props.mode === "private";
		const iframe_container_class = this.getIframeClass();

		return (
			<div className="credit-card-component">
				{this.state.paymentData.creditCardUrl
					? [
							<div key="cccd" className="credit-card-charge-date">
								{charge_date_text}
							</div>,
							<div key="ccif" className={iframe_container_class}>
								<iframe
									title="credit-card-iframe"
									id="credit-card-iframe"
									src={this.state.paymentData.creditCardUrl}
									frameBorder="0"
									scrolling="no"
									width="100%"
									height="100%"
								></iframe>
							</div>,
						]
					: null}
				{/*<div className="info-container"></div>*/}
			</div>
		);
	},

	_onChange: function () {
		this.setState(getCreditCardComponentState());
	},
});

export default CreditCardComponent;
