import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FormControlLabel, Checkbox } from '@mui/material';
import Validator from 'arbitrip-common/client/utils/Validator';

class TravelBoosterContainer extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			valid: true,
		};

		this.validate = this.validate.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.shouldCreateNewDocket !== this.props.shouldCreateNewDocket) {
			this.setState({
				valid: !!this.props.shouldCreateNewDocket,
			});
		}

		if (
			prevProps.shouldForceTravelBoosterDocketNumberValidation !==
			this.props.shouldForceTravelBoosterDocketNumberValidation
		) {
			if (this.props.shouldCreateNewDocket) {
				this.setState({
					valid: true,
				});
			} else {
				this.validate();
			}
		}
	}

	validate() {
		const valid =
			!!this.props.travelBoosterDocketNumber &&
			Validator.validateTravelBoosterDocketNumber(this.props.travelBoosterDocketNumber, this.props.profile);
		this.setState({ valid });
	}

	render() {
		const {
			mandatory,
			travelBoosterDocketNumber,
			shouldLockForm,
			onTravelBoosterDocketNumberChange,
			shouldCreateNewDocket,
			onShouldCreateNewDocketChanged,
		} = this.props;

		const { valid } = this.state;

		let label_text = 'Docket Number';
		if (mandatory) {
			label_text += ' *';
		}

		return (
			<div className="travel-booster-docket-number-container">
				<div>
					<label htmlFor="travel-booster-docket-number">{label_text}</label>
					<input
						type="text"
						className={classNames(['form-control', !valid && 'error'])}
						id="travel-booster-docket-number"
						value={travelBoosterDocketNumber}
						disabled={shouldLockForm || shouldCreateNewDocket}
						onChange={onTravelBoosterDocketNumberChange}
						onBlur={this.validate}
						placeholder="e.g. 1234567"
						maxLength="10"
					/>
				</div>
				<div>
					<FormControlLabel
						className="tb-label"
						label="Create a New Docket"
						control={
							<Checkbox
								className="tb-checkbox"
								disabled={shouldLockForm}
								checked={shouldCreateNewDocket}
								onChange={onShouldCreateNewDocketChanged}
							/>
						}
					/>
				</div>
			</div>
		);
	}
}

TravelBoosterContainer.propTypes = {
	mandatory: PropTypes.bool.isRequired,
	travelBoosterDocketNumber: PropTypes.string.isRequired,
	shouldLockForm: PropTypes.bool.isRequired,
	onTravelBoosterDocketNumberChange: PropTypes.func.isRequired,
	shouldCreateNewDocket: PropTypes.bool.isRequired,
	onShouldCreateNewDocketChanged: PropTypes.func.isRequired,
	shouldForceTravelBoosterDocketNumberValidation: PropTypes.bool.isRequired,
};

export default TravelBoosterContainer;
