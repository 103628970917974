import FlightReservation from './FlightReservation';
import moment from 'moment';

function AirfareReservation(airfare, display_order) {
	if (!airfare) {
		return;
	}

	this.id = airfare._id;

	this.display_order = display_order || airfare.display_order;
	this.flights = Array.isArray(airfare.flights) ? airfare.flights.map((flight) => new FlightReservation(flight)) : [];
	this.flights.sort((a, b) => {
		let diff = moment(a.depart_date).diff(b.depart_date, 'days');
		if (diff === 0) {
			if (a.depart_time < b.depart_time) {
				return -1;
			} else if (a.depart_time > b.depart_time) {
				return 1;
			}

			return 0;
		}
		return diff;
	});

	this.cost = airfare.cost;
	this.currency = airfare.currency;
}

export default AirfareReservation;
