import withStyles from '@mui/styles/withStyles';

import FormControlLabel from '@mui/material/FormControlLabel';

import React from 'react';
import createClass from 'create-react-class';

import FiltersActions from '../../../../actions/FiltersActions';
import ResultsStore from '../../../../stores/ResultsStore';

import FilterCheckbox from './components/FilterCheckbox.react';

const styles = {
	debugRoot: {
		width: '100%',
		marginLeft: 0,
	},
	label: {
		color: 'red',
		fontSize: 14,
		lineHeight: '2em',
	},
};

const debugInputStyle = {
	display: 'inline-block',
};

// Method to retrieve state from Stores
function getDebugFilterState(field) {
	return ResultsStore.getDebugContains(field);
}

// Define main Controller View
var DebugFilter = createClass({
	displayName: 'DebugFilter',

	// Get initial state from stores
	getInitialState: function () {
		return getDebugFilterState(this.props.field);
	},

	// Add change listeners to stores
	componentDidMount: function () {
		ResultsStore.addChangeListener(this._onChange);
	},

	// Remove change listers from stores
	componentWillUnmount: function () {
		ResultsStore.removeChangeListener(this._onChange);
	},

	delay: (function () {
		var timer = 0;

		return function (callback, millis) {
			clearTimeout(timer);
			timer = setTimeout(callback, millis);
		};
	})(),

	onDebugContainsChange: function (e) {
		var debugValue = e.target.value;
		var debugField = this.props.field;
		var mode = this.props.mode;
		var reverseLogic = this.state.reverseLogic;

		// eslint-disable-next-line eqeqeq
		if (this.state.debugContains != debugValue) {
			this.setState({
				debugContains: debugValue,
			});

			this.delay(function () {
				console.log('delayed debug filter callback');
				FiltersActions.filterByDebugContains(mode, debugField, debugValue, reverseLogic);
				setTimeout(function () {
					FiltersActions.filterByDebugContains(mode, debugField, debugValue, reverseLogic);
				}, 10);
			}, 500);
		}
	},

	onClearClick: function (e) {
		var debugField = this.props.field;
		var mode = this.props.mode;
		FiltersActions.filterByDebugContains(mode, debugField, '', false);
		setTimeout(function () {
			FiltersActions.filterByDebugContains(mode, debugField, '', false);
		}, 10);
	},

	// Render our child components, passing state via props
	render() {
		let { title, mode, classes } = this.props;
		var debugContainsInputClass = 'form-control';
		debugContainsInputClass += this.state.debugContains ? '' : ' empty';

		var display_title = '[DEV] ' + title;
		if (mode === 'checkbox_only') {
			return (
				<div className="filter">
					<div className="checkbox-container">
						<FormControlLabel
							control={
								<FilterCheckbox
									checked={this.state.reverseLogic}
									onChange={(e, c) => this.handleCheckboxChange(e, c)}
									disableRipple={true}
								/>
							}
							label={display_title}
							classes={{
								root: classes.debugRoot,
								label: classes.label,
							}}
						/>
					</div>
				</div>
			);
		} // mode checkbox_text
		return (
			<div className="filter">
				<div className="checkbox-container">
					<FormControlLabel
						control={
							<FilterCheckbox
								checked={this.state.reverseLogic}
								onChange={(e, c) => this.handleCheckboxChange(e, c)}
								disableRipple={true}
							/>
						}
						label={title}
						classes={{
							root: classes.debugRoot,
							label: classes.label,
						}}
					/>

					<input
						type="text"
						style={debugInputStyle}
						className={debugContainsInputClass}
						placeholder=""
						value={this.state.debugContains}
						onChange={this.onDebugContainsChange}
					/>
				</div>
				<div className="clear-button" onClick={this.onClearClick} />
			</div>
		);
	},

	handleCheckboxChange: function (e) {
		var debugField = this.props.field;
		var mode = this.props.mode;
		var debugContains = this.state.debugContains;
		var reverseLogic = e.target.checked;
		e.persist();
		FiltersActions.filterByDebugContains(mode, debugField, debugContains, reverseLogic);
		setTimeout(function () {
			FiltersActions.filterByDebugContains(mode, debugField, debugContains, reverseLogic);
		}, 10);
	},

	// Method to setState based upon Store changes
	_onChange: function () {
		this.setState(getDebugFilterState(this.props.field));
	},
});

export default withStyles(styles)(DebugFilter);
