const moment = require('moment');
const { RESERVATION_STATUS, PAYMENT_TYPE } = require("@arbitrip/constants");

function isRetroactivePointsUsageEnabled({ profile, booking }) {
    if (!profile?.loyalty?.retroactive_usage_enabled && !profile?.company?.loyalty?.retroactive_usage_enabled) {
        return false;
    }

    if (profile?.id !== booking?.arbitrip_loyalty_earned?.benefactor) {
        return false;
    }

    if (booking?.status !== RESERVATION_STATUS.APPROVED) {
        return false;
    }

    if (!moment(booking?.deal?.details?.check_in).isAfter(moment(), 'day')) {
        return false;
    }

    if (booking?.payment?.payment_type !== PAYMENT_TYPE.COMPANY_POLICY) {
        return false;
    }

    // TODO: check post pay with einav (update on server too)

    return true;
}

module.exports = { isRetroactivePointsUsageEnabled };
