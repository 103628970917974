import {
	Button,
	Checkbox,
	Collapse,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	FormControl,
	RadioGroup,
	Radio,
} from '@mui/material';

import React from 'react';
import createClass from 'create-react-class';
import _ from 'lodash';
import moment from 'moment';

import ReservationStore from '../../../stores/ReservationStore';
import PaymentStore from '../../../stores/PaymentStore';
import ProfileStore from '../../../stores/ProfileStore';
import SearchStore from '../../../stores/SearchStore';
import HotelStore from '../../../stores/HotelStore';

import ReservationActions from '../../../actions/ReservationActions';
import PointsActions from '../../../actions/PointsActions';

import CompanyPolicyComponent from './CompanyPolicyComponent.react';
import CreditCardComponent from './CreditCardComponent.react';
import PayAtHotelComponent from './PayAtHotelComponent.react';

import Analytics from 'arbitrip-common/client/analytics';

import ReservationConstants from '../../../constants/ReservationConstants';
import PaymentConstants from '../../../constants/PaymentConstants';
import ProfileActions from '../../../actions/ProfileActions';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';

import Currencies from 'arbitrip-common/general/utils/Currencies';

import { withTranslation } from 'react-i18next';
import NewRedeemPoints from './NewRedeemPoints.react';
import ExpediaCreditCardComponent from './ExpediaCreditCardComponent.react';
import PayThreeDSConnectorHelper from 'arbitrip-common/general/utils/PayThreeDSConnectorHelper';
import MaterialDesignDialog from '../../general/MaterialDesignDialog.react';
import PaymentActions from '../../../actions/PaymentActions';
import ReservationManipulation from '../hotel/ReservationManipulation.react';
import Config from 'arbitrip-common/client/utils/Config';

const { PAYMENT_METHOD_TYPES } = PaymentConstants;

const PROCEED_TO_PAYMENT = 'Proceed to Payment';
const COMPLETE_BOOKING = 'Complete Booking';

// Method to retrieve state from Stores
function getPaymentComponentState(method) {
	const private_travel = SearchStore.isPrivateTravel();
	const reservation = ReservationStore.getReservationData();
	const hotel = HotelStore.getHotelData();
	const profile = ProfileStore.getProfile();
	const post_pay = _.get(reservation, 'deal.post_pay');

	if (profile.freelance_agent) {
		if (method === PAYMENT_METHOD_TYPES.NONE || method === PAYMENT_METHOD_TYPES.CREDIT_CARD) {
			method = PAYMENT_METHOD_TYPES.CREDIT_CARD;
		} else if (method === PAYMENT_METHOD_TYPES.PAY_LATER) {
			method = PAYMENT_METHOD_TYPES.PAY_LATER;
		}
	} else if (private_travel) {
		method = PAYMENT_METHOD_TYPES.PRIVATE_CARD;
	} else if (post_pay) {
		// TODO: profile.pay_at_hotel_allowed ?
		method = PAYMENT_METHOD_TYPES.PAY_AT_HOTEL;
	} else if (method === PAYMENT_METHOD_TYPES.NONE) {
		if (profile.company_policy_allowed) {
			method = PAYMENT_METHOD_TYPES.COMPANY_POLICY;
		} else if (profile.credit_card_allowed) {
			method = PAYMENT_METHOD_TYPES.CREDIT_CARD;
		}
	}

	return {
		hotel,
		reservationData: reservation,
		paymentData: PaymentStore.getPaymentData(),
		travelersStatuses: ReservationStore.getCreateTravelerStatuses(),
		private_travel,
		method,
		nonRefundableModalIsOpen: false,
		doubleBookingModalIsOpen: false,
		isDoubleBooking: ReservationStore.isDoubleBooking(),
		doubleBookingStatus: ReservationStore.isDoubleBookingStatus(),
		didBook: false,
		redeem_points_usage: '',
		payThreeDSConnectorHelper: PayThreeDSConnectorHelper,
	};
}

// Define main Controller View
const PaymentComponent = createClass({
	displayName: 'PaymentComponent',

	// Get initial state from stores
	getInitialState: function () {
		return {
			...getPaymentComponentState(PAYMENT_METHOD_TYPES.NONE),
			reservation_manipulation: {
				is_fail: false,
				increase_price: '',
			},
		};
	},

	// Add change listeners to stores
	componentDidMount: function () {
		ReservationStore.addChangeListener(this._onChange);
		PaymentStore.addChangeListener(this._onChange);

		Analytics.actions.interactions.proceededToPayment(this.props.profile);
	},

	// Remove change listeners from stores
	componentWillUnmount: function () {
		PaymentStore.removeChangeListener(this._onChange);
		ReservationStore.removeChangeListener(this._onChange);
	},

	// TODO: getPriceValueInPoints: function () {

	// },

	componentDidUpdate: function (prevProps, prevState) {
		// loyalty points
		const prev_user_chosen_points = _.get(prevProps, 'points.user_chosen_points');
		const user_chosen_points = _.get(this.props, 'points.user_chosen_points');
		if (prev_user_chosen_points !== user_chosen_points) {
			const arbitrip_points_to_currency_exchange_rate = _.get(
				this.props.profile,
				'loyalty.arbitrip_points_to_currency_exchange_rate',
				0,
			);
			const price_value_in_points =
				arbitrip_points_to_currency_exchange_rate > 0
					? _.get(this.props.reservation, 'deal.totalPrice', 0) // TODO: round to match price in currency
					: 0;
			const should_clear =
				(prev_user_chosen_points === null && !user_chosen_points) ||
				(prevState.redeem_locked && !this.state.redeem_locked && !this.state.redeem_points_usage);
			this.setState({
				redeem_points_usage: should_clear ? '' : user_chosen_points,
				points_only: price_value_in_points > 0 && user_chosen_points >= price_value_in_points,
			});
		}

		//double booking
		const busy = ReservationConstants.STATUS.BUSY;
		if (prevState.doubleBookingStatus === busy && this.state.doubleBookingStatus !== busy) {
			this.state.checkingDoubleBooking = false;
			if (this.state.isDoubleBooking) {
				this.setState({ doubleBookingModalIsOpen: true });
				Analytics.actions.views.openDoubleBookingDialog(this.props.profile, this.state.reservationData.id);
			} else {
				this.advanceRefundableReservationOrOpenNonRefundableModal();
			}
		}
	},

	handlePaymentMethodChange(e) {
		this.setState({
			method: e.target.value,
		});
	},
	getPaymentDeadlineDate() {
		if (this.state?.reservationData?.pay_later_deadline) {
			return moment(this.state?.reservationData?.pay_later_deadline);
		}

		return false;
	},
	getPaymentMethodsRender() {
		let payment_methods = [];

		if (this.props.profile.freelance_agent) {
			payment_methods.push({ method: PAYMENT_METHOD_TYPES.CREDIT_CARD, label: 'Credit Card' });
			if (this.getPaymentDeadlineDate()) {
				payment_methods.push({
					method: PAYMENT_METHOD_TYPES.PAY_LATER,
					label: 'Book now, Pay later (Credit line)',
				});
			}
		} else if (!this.state.private_travel) {
			if (this.state.method === PAYMENT_METHOD_TYPES.PAY_AT_HOTEL) {
				payment_methods.push({ method: PAYMENT_METHOD_TYPES.PAY_AT_HOTEL, label: 'Pay at Hotel' });
			} else if (this.props.profile.credit_card_allowed && this.props.profile.company_policy_allowed) {
				payment_methods.push({ method: PAYMENT_METHOD_TYPES.CREDIT_CARD, label: 'Credit Card' });
				payment_methods.push({ method: PAYMENT_METHOD_TYPES.COMPANY_POLICY, label: 'Account Payment' });
			}
		} else if (this.props.loyaltyEnabled) {
			payment_methods.push({ method: PAYMENT_METHOD_TYPES.PRIVATE_CARD, label: 'Credit Card' });
		}

		if (payment_methods.length > 0) {
			return (
				<FormControl>
					{/* <FormLabel id="payment-method-radio-buttons-group">Payment Method</FormLabel> */}
					<RadioGroup
						aria-labelledby="payment-method-radio-buttons-group"
						name="controlled-radio-buttons-group"
						value={this.state.method}
						onChange={this.handlePaymentMethodChange}
					>
						{payment_methods.map((pm) => (
							<FormControlLabel
								key={pm.method}
								value={pm.method}
								control={<Radio />}
								label={pm.label}
								disabled={this.props.shouldLockForm}
							/>
						))}
					</RadioGroup>
				</FormControl>
			);
		}

		return null;
	},
	getBookNowPayLaterDisclaimer() {
		if (this.state.method === PAYMENT_METHOD_TYPES.PAY_LATER) {
			const payment_deadline_date = this.getPaymentDeadlineDate();
			return (
				<div className="book-now-pay-later-disclaimer">
					<div>
						The payment must be settled before{' '}
						<span className="payment-deadline-date">{payment_deadline_date.format('D MMM YYYY')}</span>,
					</div>
					<div>In case the booking wasn't paid until this date, the booking will be canceled</div>
				</div>
			);
		}

		return null;
	},

	async advanceReservation() {
		const {
			reservationData: reservation,
			trip_id,
			use_bank_money,
			search_token,
			send_voucher_separately_from_confirmation_mail,
			send_email: send_email_confirmation,
			combtas_id,
			travel_booster_docket_number,
			travel_booster_should_create_a_new_docket,
			price_comparable,
			currency,
			client_custom_fields,
			search_terms,
			hotel_search_token,

			show_points,
			user_chosen_points,
		} = this.props.advanceReservationFields;

		// adding cotact details(Main guest data)
		if (this.state.private_travel) {
			const { first_name = '', last_name = '', email = '', phone = '' } = reservation.travelers[0] || {};

			reservation.contact_details = {
				first_name,
				last_name,
				email,
				tel: phone,
			};
		}

		const hotel = this.state.hotel;
		const payment_type = this.state.method;
		const hotel_recommended_index = this.state.reservationData.hotel.recommended_index;
		const deal_index = this.state.reservationData.deal.deal_index;
		const charge_currency = this.props.profile.charge_currency;

		const reservation_manipulation = this.state.reservation_manipulation;
		const isTester = Config.dev_mode || this.props.profile?.is_arbi_tester;

		const params = {
			hotel,
			payment_type,
			reservation,
			trip_id,
			use_bank_money,
			search_token,
			send_email_confirmation,
			combtas_id,
			travel_booster_docket_number,
			travel_booster_should_create_a_new_docket,
			price_comparable,
			currency,
			client_custom_fields,
			search_terms,
			hotel_recommended_index,
			deal_index,
			hotel_search_token,
			charge_currency,

			show_points,
			user_chosen_points,
			send_voucher_separately_from_confirmation_mail,
			...(isTester ? { reservation_manipulation } : {}),
		};

		if (this.props.profile?.company?.partners_ui?.use_expedia_payment) {
			try {
				params.threeDSData = await this.state.payThreeDSConnectorHelper.initSession(
					this.state.reservationData.deal,
				);
			} catch (err) {
				console.log('should be visible to user error', err);
			}
		}

		ReservationActions.advanceReservation(this.props.profile, params);
	},

	paymentClicked() {
		const { validateTravelersContacts, forceTravelBoosterDocketNumberValidation, clearSpecialRequests } =
			this.props;

		if (_.isFunction(forceTravelBoosterDocketNumberValidation)) {
			forceTravelBoosterDocketNumberValidation();
		}

		if (_.isFunction(validateTravelersContacts)) {
			validateTravelersContacts()
				.then(() => {
					this.checkFormValidation();
				})
				.catch((e) => {
					console.log(e);
				});
		}

		if (_.isFunction(clearSpecialRequests)) {
			clearSpecialRequests();
		}
	},

	checkFormValidation() {
		let { validForm, openValidationModal, profile } = this.props;
		const enableDoubleBookingCheck = _.get(profile, 'company.settings.enable_double_booking_check', true);

		if (validForm) {
			if (enableDoubleBookingCheck) {
				this.checkDoubleBooking();
			} else {
				this.advanceRefundableReservationOrOpenNonRefundableModal();
			}
		} else {
			if (_.isFunction(openValidationModal)) {
				openValidationModal();
			}
		}
	},

	checkDoubleBooking() {
		const { reservationData } = this.props.advanceReservationFields;
		const { profile } = this.props;

		const reservation_id = _.get(reservationData, 'id');
		const check_in = _.get(reservationData, 'deal.details.check_in');
		const check_out = _.get(reservationData, 'deal.details.check_out');
		const travellers = _.get(reservationData, 'travelers', []);
		const company_id = _.get(profile, 'company.id');
		const user_id = _.get(profile, 'id');

		ReservationActions.checkDoubleBooking({ reservation_id, check_in, check_out, travellers, company_id, user_id });

		this.setState({ checkingDoubleBooking: true });
	},

	handleDoubleBookingDialogResponse(response) {
		this.setState({ doubleBookingModalIsOpen: false });
		if (response) {
			this.advanceRefundableReservationOrOpenNonRefundableModal();
		}
	},

	renderDoubleBookingDialog() {
		return (
			<Dialog
				open={this.state.doubleBookingModalIsOpen}
				onClose={() => this.handleDoubleBookingDialogResponse(false)}
			>
				<DialogTitle>Double Booking detected!</DialogTitle>
				<DialogContent>
					It looks like you already have a trip booked for these dates. Would you like to proceed with a new
					booking?
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => this.handleDoubleBookingDialogResponse(false)}
						style={{ textTransform: 'none' }}
					>
						No, cancel
					</Button>
					<Button
						onClick={() => this.handleDoubleBookingDialogResponse(true)}
						style={{ textTransform: 'none' }}
					>
						Yes, book anyway
					</Button>
				</DialogActions>
			</Dialog>
		);
	},

	advanceRefundableReservationOrOpenNonRefundableModal() {
		// if (!this.state.reservationData.deal.refundable) {
		// if (_.get(this.state.reservationData, 'deal.chooseprod.dca.nonRefundable') === true) {
		if (_.get(this.state.reservationData, 'deal.dca.nonRefundable') === true) {
			this.setState({ nonRefundableModalIsOpen: true });
		} else {
			this.advanceReservation();
		}
	},

	onRedeemPoints(event) {
		this.setState({ redeem_points: event.target.checked });
		PointsActions.saveChosenUserPoints('');
	},

	handleRedeemPointsUsageChange(event) {
		const points_input = event.target.value.replaceAll(/\D/g, '');
		this.setState({
			redeem_points_usage: points_input === '' ? '' : parseInt(points_input, 10),
			invalid_points_input_error_message:
				this.state.redeem_points_usage === points_input ? this.state.invalid_points_input_error_message : '',
		});
	},

	handleAddClick(event) {
		const points_input = this.state.redeem_points_usage;
		const arbitrip_points_to_currency_exchange_rate = _.get(
			this.props.profile,
			'loyalty.arbitrip_points_to_currency_exchange_rate',
			0,
		);
		const price_value_in_points =
			arbitrip_points_to_currency_exchange_rate > 0
				? _.get(this.props.reservation, 'deal.totalPrice', 0) / arbitrip_points_to_currency_exchange_rate // TODO: round to match price in currency
				: 0;
		const balance = _.get(this.props.points, 'balance', 0);

		// if (!_.isNumber(points_input)) {
		//     this.setState({ invalid_points_input_error_message: 'Please provide a number' });
		// } else if (points_input < 0) {
		//     this.setState({ invalid_points_input_error_message: 'Please provide a positive number' });
		// } else if (points_input.indexOf('.') >= 0) {
		//     this.setState({ invalid_points_input_error_message: 'Please provide an integer' });
		// } else

		// TODO: move to initial state
		const limits = [
			{ value: price_value_in_points, reason: 'price value' },
			{ value: balance, reason: 'your balance' },
		].sort((a, b) => a.value - b.value);

		if (points_input > limits[0].value) {
			this.setState({
				invalid_points_input_error_message: `Please provide a value up to ${limits[0].value.toLocaleString()} (${limits[0].reason})`,
			});
		} else if (points_input > limits[1].value) {
			this.setState({
				invalid_points_input_error_message: `Please provide a value up to ${limits[1].value.toLocaleString()} (${limits[1].reason})`,
			});
		} else {
			this.setState({ redeem_busy: true });

			if (this.state.redeem_locked) {
				PointsActions.saveChosenUserPoints('');
				this.setState({
					redeem_locked: false,
					redeem_points_usage: '',
				});
			} else {
				PointsActions.saveChosenUserPoints(this.state.redeem_points_usage);
				this.setState({ redeem_locked: true });
			}

			const _self = this;
			setTimeout(() => {
				_self.setState({ redeem_busy: false });
			}, 1000);
		}
	},

	hasMultiplePaymentMethods() {
		return (
			!this.state.private_travel &&
			this.state.method !== PAYMENT_METHOD_TYPES.PAY_AT_HOTEL &&
			_.get(this.props.profile, 'credit_card_allowed', false) &&
			(_.get(this.props.profile, 'company_policy_allowed', false) ||
				_.get(this.props.profile, 'pay_later_allowed', false))
		);
	},

	renderRedeemPoints() {
		const label = 'I want to redeem my points';
		const checkbox = (
			<Checkbox
				label={label}
				checked={this.state.redeem_points}
				onChange={this.onRedeemPoints}
				disabled={this.props.shouldLockForm || this.state.redeem_busy || this.state.redeem_locked}
			/>
		);

		return this.props.loyaltyEnabled ? (
			<React.Fragment>
				{this.hasMultiplePaymentMethods() && <hr />}
				<FormControlLabel control={checkbox} label={label} style={{ marginBottom: 0 }} />
				<Collapse in={this.state.redeem_points}>
					<NewRedeemPoints
						profile={this.props.profile}
						points={this.props.points}
						redeemPoints={this.state.redeem_points}
						onRedeemPoints={this.onRedeemPoints}
						shouldLockForm={this.props.shouldLockForm}
						redeemBusy={this.state.redeem_busy}
						redeemLocked={this.state.redeem_locked}
						reservation={this.props.reservation}
						privateTravel={this.props.privateTravel}
						redeemPointsUsage={this.state.redeem_points_usage}
						handleRedeemPointsUsageChange={this.handleRedeemPointsUsageChange}
						invalidPointsInputErrorMessage={this.state.invalid_points_input_error_message}
						handleAddClick={this.handleAddClick}
					/>
				</Collapse>
			</React.Fragment>
		) : null;
	},

	getPaymentActionText() {
		if (this.state.points_only) {
			return COMPLETE_BOOKING;
		}

		switch (this.state.method) {
			case PAYMENT_METHOD_TYPES.PRIVATE_CARD:
			case PAYMENT_METHOD_TYPES.CREDIT_CARD:
				return PROCEED_TO_PAYMENT;
			case PAYMENT_METHOD_TYPES.COMPANY_POLICY:
			case PAYMENT_METHOD_TYPES.PAY_AT_HOTEL:
			case PAYMENT_METHOD_TYPES.PAY_LATER:
				return COMPLETE_BOOKING;
			default: // Do nothing
		}

		return '';
	},

	renderPaymentActionsDialog(paymentActionText) {
		if (paymentActionText) {
			const PAYMENT_ACTIONS = [
				<Button
					key="cancel"
					variant="text"
					color="primary"
					onClick={() => this.setState({ nonRefundableModalIsOpen: false })}
				>
					Cancel
				</Button>,
				<Button key="advance" variant="text" color="primary" onClick={this.advanceReservation}>
					{paymentActionText}
				</Button>,
			];
			return (
				<Dialog open={this.state.nonRefundableModalIsOpen}>
					<DialogTitle>Non Refundable</DialogTitle>
					<DialogContent>
						This reservation is <b>non refundable</b>. Cancellation or change will incur costs.
					</DialogContent>
					<DialogActions>{PAYMENT_ACTIONS}</DialogActions>
				</Dialog>
			);
		}

		return null;
	},

	renderPaymentMethod() {
		const isExpediaPayment = this.props.profile?.company?.partners_ui?.use_expedia_payment;
		switch (this.state.method) {
			case PAYMENT_METHOD_TYPES.PRIVATE_CARD:
				if (isExpediaPayment) {
					return (
						<ExpediaCreditCardComponent payThreeDSConnectorHelper={this.state.payThreeDSConnectorHelper} />
					);
				}
				return (
					<CreditCardComponent
						mode="private"
						advanceReservation={this.advanceReservation}
						profile={this.props.profile}
					/>
				);
			case PAYMENT_METHOD_TYPES.CREDIT_CARD:
				if (isExpediaPayment) {
					return (
						<ExpediaCreditCardComponent payThreeDSConnectorHelper={this.state.payThreeDSConnectorHelper} />
					);
				}
				return (
					<CreditCardComponent advanceReservation={this.advanceReservation} profile={this.props.profile} />
				);
			case PAYMENT_METHOD_TYPES.COMPANY_POLICY:
				return <CompanyPolicyComponent valid />;
			case PAYMENT_METHOD_TYPES.PAY_AT_HOTEL:
				return <PayAtHotelComponent valid />;
			case PAYMENT_METHOD_TYPES.PAY_LATER:
				return <CompanyPolicyComponent valid />;
			default: // Do nothing
		}

		return (
			<div className="payment-method">
				<div className="no-payment-message">
					To finalize the reservation, please{' '}
					<a href="mailto:support@arbitrip.com" target="_blank">
						contact us
					</a>
				</div>
			</div>
		);
	},

	handleChargeCurrencyChange(e) {
		const currency = e.target.value;
		ProfileActions.changeChargeCurrency(currency);
	},

	getCurrencyExchangeRate(currency, initializing) {
		const precision = 2;
		const { reservationData } = this.state;
		const exchange_rates = _.get(reservationData, 'leisure_allowed_payment_currencies_exchange_rates', {});
		const exchange_rate = exchange_rates[currency];
		if (!exchange_rate && !initializing) {
			return `${currency}`; // fallback
		}

		const sum = exchange_rate ? (
			Currencies.getPriceWithDisplayCurrencyByPrecision(
				this.state.reservationData.deal.totalPrice,
				exchange_rate,
				currency,
				precision,
			)
		) : (
			<CircularProgress size={13} />
		);

		return (
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					width: '100%',
				}}
			>
				<div>{currency}</div>
				<div
					style={{
						marginRight: 23,
					}}
				>
					{sum}
				</div>
			</div>
		);
	},

	changeChargeCurrencyRender() {
		const component_width = 200;
		const initializing = _.get(this.state.reservationData, 'status') === ReservationConstants.STATUS.INITIALIZING;
		return this.props.canChangeChargeCurrency ? (
			<div className="charge-currency-container">
				<label htmlFor="charge_currency">Choose credit charge currency</label>
				<div className="select-container">
					<Select
						id="charge-currency"
						value={this.props.profile.charge_currency}
						renderValue={() =>
							this.getCurrencyExchangeRate(this.props.profile.charge_currency, initializing)
						}
						onChange={this.handleChargeCurrencyChange}
						disabled={this.props.shouldLockForm || initializing}
						style={{
							background: '#eee',
							border: '1px solid #888',
							borderRadius: 4,
							width: component_width,
							height: 37,
							// TODO: blue borderColor when opened
						}}
						MenuProps={{
							anchorOrigin: {
								vertical: 'bottom',
								horizontal: 'left',
							},
							transformOrigin: {
								vertical: 'top',
								horizontal: 'left',
							},
							getContentAnchorEl: null,
							style: {
								marginTop: 3,
								marginLeft: -1,
							},
						}}
					>
						{this.props.profile.leisure_allowed_payment_currencies.map((cur) => (
							<MenuItem value={cur} key={cur} style={{ width: component_width }}>
								{this.getCurrencyExchangeRate(cur, initializing)}
							</MenuItem>
						))}
					</Select>
				</div>
			</div>
		) : null;
	},

	getCountryName(country_code) {
		let country_name = '';
		if (country_code) {
			const regionNamesInEnglish = new Intl.DisplayNames(['en'], { type: 'region' });
			try {
				country_name = regionNamesInEnglish.of(country_code);
			} catch (e) {
				console.error(`Can not parse country value of ${country_code}`);
			}
		}
		return country_name;
	},

	renderDisclaimer() {
		const { t, reservation } = this.props;
		const proceed_to_payment_mode =
			!this.state.paymentData.creditCardUrl &&
			(this.state.method === PAYMENT_METHOD_TYPES.PRIVATE_CARD ||
				this.state.method === PAYMENT_METHOD_TYPES.CREDIT_CARD);
		const country_name = this.getCountryName(reservation.payment_country_code);
		return (
			<div className="disclaimer">
				<p>
					{proceed_to_payment_mode ? 'By proceeding' : 'By completing this reservation'} you agree to the{' '}
					{t('SiteCom')}{' '}
					<a href={t('TermsAndConditionsUrl')} target="_blank">
						Terms & Conditions
					</a>
					, Terms of Supplier & Cancellation Policy.
				</p>
				{country_name && (
					<p>
						The payment will be processed in <strong>{country_name}</strong>.
					</p>
				)}
			</div>
		);
	},

	renderPaymentAction(paymentActionText) {
		const { busy, madeReservation } = this.props;
		const { checkingDoubleBooking, reservationData } = this.state;

		const initializing = reservationData?.status === ReservationConstants.STATUS.INITIALIZING;

		if (paymentActionText && !this.state.paymentData.creditCardUrl) {
			const spinner = (busy || checkingDoubleBooking) && <i className="fa fa-spin fa-spinner fa-2x" />;

			return (
				<div className="payment-action">
					<input
						role="button"
						type="button"
						className="btn btn-primary flat"
						onClick={this.paymentClicked}
						value={paymentActionText}
						disabled={initializing || busy || checkingDoubleBooking || madeReservation}
					/>
					{spinner}
				</div>
			);
		}

		return null;
	},

	closeErrorModal: function () {
		PaymentActions.clearPaymentData();
	},

	renderPaymentError() {
		const { paymentData } = this.state;

		return (
			<MaterialDesignDialog
				open={!!paymentData.error_message}
				onClose={this.closeErrorModal}
				title={'The Payment Failed'}
				content={
					<div>
						<div>{paymentData.error_message}</div>
						<div>Try using a different card.</div>
					</div>
				}
				actions={[{ onClick: this.closeErrorModal, text: 'Got it' }]}
			/>
		);
	},

	render() {
		const { reservation_manipulation } = this.state;
		const { profile } = this.props;

		const PAYMENT_METHODS = this.getPaymentMethodsRender();
		const BOOK_NOW_PAY_LATER_DISCLAIMER = this.getBookNowPayLaterDisclaimer();
		const CHARGE_CURRENCY = this.changeChargeCurrencyRender();
		const DISCLAIMER = this.renderDisclaimer();
		const PAYMENT_METHOD = this.renderPaymentMethod();

		const REDEEM_POINTS = this.renderRedeemPoints();

		const payment_action_text = this.getPaymentActionText();
		const PAYMENT_ACTION = this.renderPaymentAction(payment_action_text);
		const PAYMENT_ACTIONS_DIALOG = this.renderPaymentActionsDialog(payment_action_text);
		const DOUBLE_BOOKING_DIALOG = this.renderDoubleBookingDialog();
		const PAYMENT_ERROR_DIALOG = this.renderPaymentError();

		return (
			<div className="payment-component" id="payment-component">
				<h4 className="title">Payment</h4>
				<div className="payment-details-container">
					<div className="payment-method-container">
						{(this.hasMultiplePaymentMethods() || this.props.loyaltyEnabled) && (
							<div className="payment-method-container-inner payment-inputs">
								{PAYMENT_METHODS}
								{BOOK_NOW_PAY_LATER_DISCLAIMER}
								{REDEEM_POINTS}
								{CHARGE_CURRENCY}
							</div>
						)}

						<ReservationManipulation
							reservation_manipulation={reservation_manipulation}
							profile={profile}
							onFailStatusChange={(e) => {
								this.setState({
									reservation_manipulation: {
										...reservation_manipulation,
										is_fail: e.target.checked,
									},
								});
							}}
							onPriceChange={(e) => {
								this.setState({
									reservation_manipulation: {
										...reservation_manipulation,
										increase_price: e.target.value,
									},
								});
							}}
						/>

						<div className="payment-method-container-inner payment-completion">
							{DISCLAIMER}
							{PAYMENT_METHOD}

							{PAYMENT_ACTION}
							{PAYMENT_ACTIONS_DIALOG}
							{DOUBLE_BOOKING_DIALOG}
							{PAYMENT_ERROR_DIALOG}
						</div>
					</div>
				</div>
			</div>
		);
	},

	_onChange: function () {
		this.setState(getPaymentComponentState(this.state.method));
	},
});

export default withTranslation()(PaymentComponent);
