import keyMirror from 'keymirror';
import _ from 'lodash';

const REPORTS_CONSTANTS = {
	STATUS: {
		INITIAL: 'initial',
		BUSY: 'busy',
		SUCCESS: 'success',
		FAILED: 'failed',
	},

	MAX_NUMBER_OF_MONTH_TO_SHOW: 7,
	MAX_NUMBER_OF_TOP_CITIES: 5,
};

// Define action constants
export default _.extend(
	{},
	REPORTS_CONSTANTS,
	keyMirror({
		UPDATE_REPORTS_START_DATE: null,
		UPDATE_REPORTS_END_DATE: null,
		UPDATE_REPORTS_CLIENT_CONTRACT_ID: null,
		UPDATE_REPORTS_DATES: null,

		RETRIEVE_REPORTS: null,
		REPORTS_RETRIEVAL_SUCCEEDED: null, // After postback
		REPORTS_RETRIEVAL_FAILED: null,

		RETRIEVE_GRAPHS_DATA: null,
		GRAPHS_DATA_RETRIEVAL_SUCCEEDED: null,
		GRAPHS_DATA_RETRIEVAL_FAILED: null,
	}),
);
