import React from 'react';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';

import FiltersActions from '../../../../actions/FiltersActions';

import FiltersConstants from '../../../../constants/FiltersConstants';
import Analytics from 'arbitrip-common/client/analytics';

const isIntegerRegex = /^[1-9]\d*$/;

const TopXFilter = createClass({
	displayName: 'TopXFilter',

	onTopXChange: function (e) {
		const top_x = e.target.value;
		const _self = this;

		const { profile, filter } = _self.props;

		if ((top_x === '' || isIntegerRegex.test(top_x)) && filter !== Number(top_x)) {
			console.log('delayed top_x filter callback');
			// TODO: verify is a number...

			FiltersActions.filterByTopX(top_x);
			setTimeout(function () {
				FiltersActions.filterByTopX(top_x);
			}, 10);

			Analytics.actions.interactions.filteredResults(profile, FiltersConstants.FILTER_METHOD.TOP_X, top_x);
		}
	},

	onClearClick: function (e) {
		const { profile, filter } = this.props;

		if (filter) {
			FiltersActions.filterByTopX(0);
			setTimeout(function () {
				FiltersActions.filterByTopX(0);
			}, 10);
			Analytics.actions.interactions.filteredResults(profile, FiltersConstants.FILTER_METHOD.TOP_X, 'None');
		}
	},

	render: function () {
		const top_x = this.props.filter;

		const top_x_text = top_x || '';
		const top_x_input_class = `form-control${top_x ? '' : ' empty'}`;

		const top_x_style = {
			display: 'inline-block',
			width: '50%',
			// verticalAlign: 'middle'
		};

		return (
			<div className="filter">
				<label className="filter-title" htmlFor="top-x-input" style={top_x_style}>
					Top X
				</label>

				<div className="input-group" style={Object.assign({ verticalAlign: 'middle' }, top_x_style)}>
					{/* <span className="input-group-addon" id="sizing-addon2">
                        Extra icon or something
                    </span> */}

					<input
						id="top-x-input"
						type="text"
						className={top_x_input_class}
						aria-describedby="sizing-addon2"
						value={top_x_text}
						onChange={this.onTopXChange}
						maxLength="4"
					/>
				</div>

				<div className="clear-button" onClick={this.onClearClick} />
			</div>
		);
	},
});

TopXFilter.propTypes = {
	filter: PropTypes.isRequired,
};

export default TopXFilter;
