import withStyles from '@mui/styles/withStyles';

import classNames from 'classnames';

import React from 'react';
import PropTypes from 'prop-types';

import DestinationsActions from '../../../../actions/DestinationsActions';
import SearchActions from '../../../../actions/SearchActions';

import Analytics from 'arbitrip-common/client/analytics';

import StringShortener from '../../../../utils/StringShortener';

const { shortenString } = StringShortener;

const styles = {};

function getState() {
	return {
		isHovering: false,
		removalIsHovering: false,
		removalMode: false,
	};
}

class DestinationComponent extends React.Component {
	constructor(props) {
		super(props);

		this.state = getState();

		this._onDestinationComponentClick = this._onDestinationComponentClick.bind(this);
		this.handleMouseEnter = this.handleMouseEnter.bind(this);
		this.handleMouseLeave = this.handleMouseLeave.bind(this);
		this.handleRemovalMouseEnter = this.handleRemovalMouseEnter.bind(this);
		this.handleRemovalMouseLeave = this.handleRemovalMouseLeave.bind(this);
		this.onRemoveBusinessDestinationClick = this.onRemoveBusinessDestinationClick.bind(this);
		this.handleApproveRemovalClick = this.handleApproveRemovalClick.bind(this);
		this.handleCancelRemovalClick = this.handleCancelRemovalClick.bind(this);
	}

	_onDestinationComponentClick() {
		const { removalIsHovering } = this.state;
		const { profile, destination } = this.props;

		if (!removalIsHovering) {
			SearchActions.updateDestination(destination.destination);

			window.Velocity(document.getElementById('autocomplete_select'), 'callout.pulse', {
				complete: function () {
					window.$(this).css('transform', '');
				},
			});

			Analytics.actions.interactions.selectedBusinessDestination(profile, destination);
		}
	}

	handleMouseEnter() {
		this.setState({ isHovering: true });
	}

	handleMouseLeave() {
		this.setState({ isHovering: false });
	}

	handleRemovalMouseEnter() {
		this.setState({ removalIsHovering: true });
	}

	handleRemovalMouseLeave() {
		this.setState({ removalIsHovering: false });
	}

	onRemoveBusinessDestinationClick() {
		this.setState({ removalMode: true });
	}

	handleApproveRemovalClick() {
		DestinationsActions.removeDestination(this.props.destination);
		this.setState({ removalMode: false });
	}

	handleCancelRemovalClick() {
		this.setState({ removalMode: false });
	}

	getDestinationTitle() {
		const { destination } = this.props;
		return shortenString(destination.name, 48);
	}

	getAddress() {
		const { destination } = this.props;

		return (
			<div className={classNames('address', destination.add && 'add')}>
				{shortenString(destination.destination.address, 80)}
			</div>
		);
	}

	getRemoveBusinessDestination() {
		const { removalIsHovering } = this.state;

		return this.state.isHovering && this.props.allowRemoval ? (
			<div className="destination-removal-container">
				<button
					onClick={this.onRemoveBusinessDestinationClick}
					className={classNames('remove-button', removalIsHovering && 'hovering')}
					onMouseEnter={this.handleRemovalMouseEnter}
					onMouseLeave={this.handleRemovalMouseLeave}
				/>
			</div>
		) : null;
	}

	getRemovalPopover() {
		const { removalMode } = this.state;

		return (
			removalMode && (
				<div className="removal-popover">
					<div className="removal-approval-text">
						<span>Are you sure you want to remove this destination?</span>
					</div>
					<div className="removal-buttons-container">
						<button className="approve-removal-button" onClick={this.handleApproveRemovalClick}>
							Yes
						</button>
						<button className="cancel-removal-button" onClick={this.handleCancelRemovalClick}>
							Cancel
						</button>
					</div>
				</div>
			)
		);
	}

	getImageBackgroundStyle() {
		const { isHovering } = this.state;
		const { destination, index } = this.props;

		const IMAGES = [
			'./img/business_destinations/1.png',
			'./img/business_destinations/2.png',
			'./img/business_destinations/3.png',
			'./img/business_destinations/4.png',
			'./img/business_destinations/5.png',
			'./img/business_destinations/6.png',
		];

		const selectedImage = IMAGES[index % IMAGES.length];

		const HOVER_GRADIENT = 'linear-gradient(180deg, rgba(230,230,230,0.2) 0%, rgba(230,230,230,0.2) 100%)';

		const backgroundImageList = [
			...(isHovering ? [HOVER_GRADIENT] : []),
			...(destination.image ? [`url("${destination.image}")`] : []),
			`url("${selectedImage}")`,
		];

		return {
			backgroundImage: backgroundImageList.join(', '),
		};
	}

	render() {
		const { removalMode, isHovering } = this.state;
		const { destination } = this.props;

		return (
			<div className="destination-component-container">
				<button
					className={classNames('destination-component', removalMode && 'removal', isHovering && 'hovering')}
					style={this.getImageBackgroundStyle()}
					onClick={this._onDestinationComponentClick}
					title={destination.name + ' - ' + destination.destination.address}
					onMouseEnter={this.handleMouseEnter}
					onMouseLeave={this.handleMouseLeave}
				>
					<div className="destination-content-container">
						<div className="destination-content">
							<div className="destination-title">{this.getDestinationTitle()}</div>
							{this.getAddress()}
						</div>
					</div>
					{this.getRemoveBusinessDestination()}
				</button>
				{this.getRemovalPopover()}
			</div>
		);
	}
}

DestinationComponent.propTypes = {
	profile: PropTypes.any,
	allowRemoval: PropTypes.bool,
	destination: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	index: PropTypes.number,
};

export default withStyles(styles)(DestinationComponent);
