/**
 * Created by MrJossef on 1/29/17.
 */
import moment from 'moment';
import * as Sentry from '@sentry/react';

export default {
	dateToCheckInOutString: function (date) {
		if (date) return moment(date).format('DD MM YYYY');
	},

	reformatDate: function (date) {
		return moment(date).format('YYYY-MM-DD');
	},

	customDateOnlyParse: function (isoString) {
		try {
			return moment(isoString.split('T')[0]).toDate();
		} catch (ex) {
			console.error(ex);
			Sentry.captureException(ex);
			return isoString;
		}
	},

	isBeforeDay: function (a, b) {
		let aYear = a.year();
		let bYear = b.year();
		let isSameYear = aYear === bYear;
		if (isSameYear) return a.dayOfYear() < b.dayOfYear();
		return aYear < bYear;
	},
};
