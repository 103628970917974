import React from 'react';

import FiltersActions from '../../../../actions/FiltersActions';

import FiltersConstants from '../../../../constants/FiltersConstants';
import Analytics from 'arbitrip-common/client/analytics';

import FilterCheckboxWithLabel from './components/FilterCheckboxWithLabel.react';

class CategoriesFilter extends React.Component {
	constructor(props) {
		super(props);

		this.handleCheckboxChangeByCategory = this.handleCheckboxChangeByCategory.bind(this);
		this.handleCategoryOnlyClick = this.handleCategoryOnlyClick.bind(this);
		this.handleResetCategoriesClick = this.handleResetCategoriesClick.bind(this);
	}

	handleCheckboxChangeByCategory = (event, isInputChecked, category) => {
		const { profile } = this.props;

		event.persist();
		FiltersActions.filterByCategories(category, isInputChecked);
		setTimeout(() => {
			FiltersActions.filterByCategories(category, isInputChecked);
		}, 10);

		const filter_value = (isInputChecked ? 'Filter by "' : 'Don\'t filter by"') + category + '"';
		Analytics.actions.interactions.filteredResults(
			profile,
			FiltersConstants.FILTER_METHOD.CATEGORIES,
			filter_value,
		);
	};

	handleCategoryOnlyClick = (event) => {
		const { profile } = this.props;

		event.persist();
		let category_only = event.target.getAttribute('data-category');
		FiltersActions.filterByCategoriesOnly(category_only);
		setTimeout(() => {
			FiltersActions.filterByCategoriesOnly(category_only);
		}, 10);

		const filter_value = 'Show Only "' + category_only + '" Category';
		Analytics.actions.interactions.filteredResults(
			profile,
			FiltersConstants.FILTER_METHOD.CATEGORY_ONLY,
			filter_value,
		);
	};

	handleResetCategoriesClick = (event) => {
		const { profile } = this.props;

		event.persist();
		FiltersActions.resetCategoriesFilter();
		setTimeout(FiltersActions.resetCategoriesFilter, 10);

		const filter_value = 'Reset Categories Filter';
		Analytics.actions.interactions.filteredResults(
			profile,
			FiltersConstants.FILTER_METHOD.RESET_CATEGORIES_FILTER,
			filter_value,
		);
	};

	render() {
		const data = this.props.filter;
		const multiple_categories = Object.keys(data).length > 1;

		const CATEGORIES = [];
		for (let category in data) {
			const category_key = category.toLowerCase().replace(' ', '-');
			CATEGORIES.push(
				<div className="checkbox-container" key={category_key}>
					<FilterCheckboxWithLabel
						checked={data[category]}
						category={category}
						label={category}
						onChange={(e, c, category) => this.handleCheckboxChangeByCategory(e, c, category)}
					/>
					{multiple_categories ? (
						<button
							className="btn btn-link"
							data-category={category}
							onClick={this.handleCategoryOnlyClick}
							aria-label={`Select ${category} only`}
						>
							Only
						</button>
					) : null}
				</div>,
			);
		}

		return (
			<div className="filter disabled">
				<label className="filter-title" htmlFor="categories-filter">
					Categories
				</label>
				<div name="categories-filter" className="check-list" aria-label="Categories filters">
					{CATEGORIES}
				</div>
				{CATEGORIES.length > 1 ? (
					<button
						className="clear-button-wrap"
						onClick={this.handleResetCategoriesClick}
						aria-label="Reset categories filters"
					>
						<div className="clear-button-img" />
					</button>
				) : null}
			</div>
		);
	}
}

export default CategoriesFilter;
