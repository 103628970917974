const keyMirror = require('keymirror');

export const EVENTS = keyMirror({  
  ADDED_TRAVEL_POLICY_ENTRY: null,
  ADDED_TRAVEL_POLICY_ENTRY_FAILED: null,  
  EDITED_DEFAULT_TRAVEL_POLICY_ENTRY: null,
  EDITED_DEFAULT_TRAVEL_POLICY_ENTRY_FAILED: null,
  EDITED_TRAVEL_POLICY_ENTRY: null,
  EDITED_TRAVEL_POLICY_ENTRY_FAILED: null,
  REMOVED_TRAVEL_POLICY_ENTRY: null,
  REMOVED_TRAVEL_POLICY_ENTRY_FAILED: null,
});
