const { ReservationConstants } = require('arbitrip-common/client/constants');

const APPROVAL_SNACKBAR_MESSAGES = {
	[ReservationConstants.STATUS.BUSY]: 'Approving reservation request...',
	[ReservationConstants.STATUS.SUCCESS]: 'Reservation request approved',
	[ReservationConstants.STATUS.FAILED]: 'Reservation request approval failed',
};

const DECLINE_SNACKBAR_MESSAGES = {
	[ReservationConstants.STATUS.BUSY]: 'Declining reservation request...',
	[ReservationConstants.STATUS.SUCCESS]: 'Reservation request declined',
	[ReservationConstants.STATUS.FAILED]: 'Reservation request decline failed',
};

export default {
	APPROVAL_SNACKBAR_MESSAGES,
	DECLINE_SNACKBAR_MESSAGES,
};
