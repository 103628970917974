import React from 'react';
import createClass from 'create-react-class';
import { SmallCheckbox } from '../../general/SmallCheckbox.react';
import Config from 'arbitrip-common/client/utils/Config';

const ReservationManipulation = createClass({
	displayName: 'DealsStatistics',

	styles: {
		component: {
			border: '1px solid lightgrey',
			borderRadius: '4px',
			padding: '4px 4px 4px 15px',
			marginTop: '10px',
			display: 'flex',
		},
		priceContainer: {
			display: 'flex',
			marginRight: '20px',
			paddingRight: '10px',
			whiteSpace: 'nowrap',
			gap: '16px',
			alignItems: 'center',
		},
	},

	render() {
		const {
			profile,
			reservation_manipulation,
			onInitStatusChange,
			onPriceChange,
			onRecheckChange,
			onFailStatusChange,
		} = this.props;

		const is_recheck_allowed = profile?.settings?.is_recheck_allowed;

		const is_show = Config.dev_mode || profile?.is_arbi_tester;

		return (
			is_show && (
				<div className={'reservation-manipulation-container'} style={this.styles.component}>
					{is_recheck_allowed && (
						<div className="price-container" style={this.styles.priceContainer}>
							<label htmlFor="increase-price">Increase deal price on recheck</label>
							<input
								type="number"
								className="form-control"
								id="increase-price"
								value={reservation_manipulation.increase_price}
								onChange={onPriceChange}
								placeholder={'Change price'}
							/>
						</div>
					)}
					{is_recheck_allowed && onRecheckChange && (
						<SmallCheckbox
							id="init-failure"
							label="Fail Recheck"
							checked={reservation_manipulation.is_fail_recheck}
							onChange={onRecheckChange}
						/>
					)}
					{onInitStatusChange && (
						<SmallCheckbox
							id="init-failure"
							label="Fail Init Reservation"
							checked={reservation_manipulation.is_fail_init}
							onChange={onInitStatusChange}
						/>
					)}
					{onFailStatusChange && (
						<SmallCheckbox
							id="init-failure"
							label="Fail Reservation"
							checked={reservation_manipulation.is_fail}
							onChange={onFailStatusChange}
						/>
					)}
				</div>
			)
		);
	},
});

export default ReservationManipulation;
