import AppDispatcher from '../dispatcher/AppDispatcher';
import { EventEmitter } from 'events';
import DealConstants from '../constants/DealConstants';
import _ from 'lodash';

// Define initial data points
var _deal = {};

// Method to load result data from mock API
function loadDealData(data) {
	_deal = data;
}

function clearDealData() {
	_deal = {};
}

// Extend Search Store with EventEmitter to add eventing capabilities
var DealStore = _.extend({}, EventEmitter.prototype, {
	// Return search results
	getDealData: function () {
		return _.extend({}, _deal);
	},

	isPostPay() {
		return _deal.post_pay;
	},

	// Emit Change event
	emitChange: function () {
		this.emit('change');
	},

	// Add change listener
	addChangeListener: function (callback) {
		this.on('change', callback);
	},

	// Remove change listener
	removeChangeListener: function (callback) {
		this.removeListener('change', callback);
	},
});

// Register callback with AppDispatcher
AppDispatcher.register(function (payload) {
	var action = payload.action;

	switch (action.actionType) {
		// Respond to RECEIVE_DATA action
		case DealConstants.CHOOSE_DEAL:
			loadDealData(action.data);
			break;

		case DealConstants.CLEAR_DEAL_DATA:
			clearDealData();
			break;

		default:
			return true;
	}

	// If action was responded to, emit change event
	DealStore.emitChange();

	return true;
});

export default DealStore;
