import React, { useState } from 'react';
import ContentLoader from 'react-content-loader';
import SupplierTermsBeautifier from '@/utils/SupplierTermsBeautifier';
import _ from 'lodash';

const CONTENT_LOADER_WIDTH = 761;
const CONTENT_LOADER_HEIGHT = 80;
const MAX_CHARS = 325;

const TermsOfSupplier = ({ deal, initializing = false }) => {
	const [isExpanded, setIsExpanded] = useState(false);

	const hotelSupplierTerms = _.get(deal, 'original.details.hotel_supplier_terms', 'No special terms');
	let specialTerms = _.get(deal, 'chooseprod.dca.info', '').trim() || hotelSupplierTerms;
	specialTerms = SupplierTermsBeautifier.extract(specialTerms);

	const isTooLong = specialTerms.length > MAX_CHARS;

	const truncatedContent = isTooLong && !isExpanded ? `${specialTerms.slice(0, MAX_CHARS).trim()}...` : specialTerms;

	return (
		<div className="terms-of-supplier">
			<div className="title">Terms of supplier</div>
			{initializing ? (
				<ContentLoader
					width={CONTENT_LOADER_WIDTH}
					height={CONTENT_LOADER_HEIGHT}
					viewBox={`0 0 ${CONTENT_LOADER_WIDTH} ${CONTENT_LOADER_HEIGHT}`}
				>
					<rect x="5" y="5" rx="0" ry="0" width="720" height="13" />
					<rect x="5" y="27" rx="0" ry="0" width="660" height="13" />
					<rect x="5" y="49" rx="0" ry="0" width="700" height="13" />
				</ContentLoader>
			) : (
				<>
					<div className="terms-of-supplier__text">
						<span>{truncatedContent}</span>
					</div>
					{isTooLong && (
						<button
							className="text-button"
							aria-label={isExpanded ? 'Read less' : 'Read more'}
							onClick={() => setIsExpanded((prevIsExpanded) => !prevIsExpanded)}
						>
							{isExpanded ? 'Read Less' : 'Read More'}
						</button>
					)}
				</>
			)}
		</div>
	);
};

export default TermsOfSupplier;
