const keyMirror = require('keymirror');

export const EVENTS = keyMirror({
    PROCEEDED_TO_PAYMENT: null,
    ACCOUNT_PAYMENT: null,
    CREDIT_CARD_PAYMENT: null,
    POINTS_ONLY_PAYMENT: null,
    GCASH_PAYMENT: null,
    GCASH_PAYMENT_SUCCESS: null,
    GCASH_PAYMENT_ERROR: null,
});
