import React from 'react';
import createClass from 'create-react-class';
import _ from 'lodash';

const styles = {
	component: {
		position: 'absolute',
		right: '4px',
		top: '-1px',
	},
	bookmark: {
		color: '#D0D1D2',
		fontSize: '24px',
		position: 'relative',
		marginLeft: '250px',
		marginTop: '-2px',
	},
	provider: {
		color: 'black',
		fontSize: '15px',
		position: 'absolute',
		left: '4px',
		top: '2px',
		fontFamily: 'lato',
	},
};

const ProviderRoomsBookmark = createClass({
	displayName: 'ProviderRoomsBookmark',

	render() {
		const { display_debugging, hotel } = this.props;
		if (!display_debugging) {
			return null;
		}
		const provider = _.get(hotel, 'rooms_src', '?').charAt(0).toUpperCase();

		return (
			<div style={styles.component}>
				<i className="fa fa-bookmark" aria-hidden="true" style={styles.bookmark}>
					<span style={styles.provider}>{provider}</span>
				</i>
			</div>
		);
	},
});

export default ProviderRoomsBookmark;
