import _ from 'lodash';
import SearchSession from '../entities/SearchSession';
import OfflineDB from './OfflineDB';
import DateUtils from './Dates';
import { DealConstants, HotelConstants, ReservationConstants } from "../constants";
import { WebStorageManager } from "./index";

let _search_sessions = {};
let _current_search_token = '';

function markSelectedHotel({ selected_hotel_id }) {
    return hotel => {
        if (!hotel?.scores?.normal) {
            return;
        }
        const isMatch = selected_hotel_id === hotel._id;
        hotel.hotelMatch = isMatch;
        if (hotel.recommendationEngine) {
            hotel.recommendationEngine = {
                ...hotel.recommendationEngine,
                hotelMatch: isMatch,
            };
        }
        if (hotel.scores.normal.total_score > 500 && !isMatch) {
            hotel.scores.normal.total_score -= 1000;
            hotel.scores.normal.hotel_score -= 1000;
        }
        if (hotel.scores.normal.total_score < 500 && isMatch) {
            hotel.scores.normal.total_score += 1000;
            hotel.scores.normal.hotel_score += 1000;
        }
    };
}

class SessionManager {
    addSearchSession(searchSession) {
        if (SearchSession.isValid(searchSession)) {
            _search_sessions[searchSession.search_token] = searchSession;
            _current_search_token = searchSession.search_token;

            // optional
            this.clearOldSearchSessions();

            setTimeout(() => {
                searchSession.notifyEvent();
            });
        } else {
            console.warn('SessionManager.addSearchSession got an invalid searchSession', searchSession);
        }
    }

    async getSearchSessionFromOfflineDB(searchToken, recommendationWeights, options = {}) {
        const raw_search_session = await OfflineDB.getSearchSession(searchToken);

        // TODO: more than basic validation?
        if (raw_search_session) {
            let raw_search_terms = raw_search_session.search_terms;
            raw_search_terms.check_in = DateUtils.customDateOnlyParse(raw_search_terms.check_in);
            raw_search_terms.check_out = DateUtils.customDateOnlyParse(raw_search_terms.check_out);

            const search_travel_policy = await OfflineDB.getSearchTravelPolicy(searchToken);
            const raw_search_hotels = await OfflineDB.getSearchHotels(searchToken);
            const raw_search_shallows = await OfflineDB.getSearchShallows(searchToken);
            const raw_search_deals = await OfflineDB.getSearchDeals(searchToken);
            const banned_deal_ids = await OfflineDB.getBannedDeals(searchToken);
            const reservation_attempts_count = await OfflineDB.getReservationAttempts(searchToken);

            const room_mapping_settings = await OfflineDB.getRoomMappingSettings(searchToken);

            const search_session = new SearchSession(
                searchToken,
                raw_search_terms,
                recommendationWeights,
                {
                    created_timestamp: raw_search_session.created_timestamp,
                    travel_policy: search_travel_policy,
                    room_mapping_settings,
                    banned_deal_ids,
                    reservation_attempts: reservation_attempts_count?.count ?? 0,
                    completed_delay: true,
                    from_offline_db: true,
                    ...(_.isBoolean(raw_search_session.arbitrip_points_applied) ? { arbitrip_points_applied: raw_search_session.arbitrip_points_applied } : {}),
                    ...options
                }
            );

            search_session.search_results.addHotels(raw_search_hotels, false);
            search_session.search_results.addHotels(raw_search_shallows, true);
            search_session.search_results.addHotelsDeals(search_session, raw_search_deals);

            this.addSearchSession(search_session, options);
            return search_session;
        }

        return null;
    }

    createSearchSession(searchToken, searchTerms, recommendationWeights, options = {}) {
        this.addSearchSession(new SearchSession(searchToken, searchTerms, recommendationWeights, options));
        return this.current_search_session;
    }

    get current_search_session() {
        return _search_sessions[_current_search_token];
    }

    async updateSelectedHotelInSession({ selected_hotel_id }) {
        const raw_search_session = await OfflineDB.getSearchSession(_current_search_token);
        selected_hotel_id = selected_hotel_id ?? raw_search_session.search_terms?.destination?.hotel_id
        const raw_search_hotels = await OfflineDB.getSearchHotels(_current_search_token);
        raw_search_hotels?.forEach(markSelectedHotel({ selected_hotel_id }));

        _search_sessions[_current_search_token]?.search_results?.hotels?.forEach(hotel => {
            hotel.setHotelMatch(selected_hotel_id);
        });
    }

    clearHotelDealsInSession({ hotel_id }) {
        const hotels = _search_sessions[_current_search_token]?.search_results?.hotels?.filter(hotel => hotel.id === hotel_id);
        if (hotels.length === 1) {
            hotels[0].clearDeals();
            hotels[0].RecheckStatus = HotelConstants.RECHECK_STATUS.INITIAL;
        }
    }

    banDealInSession({ hotel, deal }) {
        if (!this.current_search_session) {
            console.warn('no current session');
            return;
        }
        if (this.current_search_session.banned_deal_ids) {
            this.current_search_session.banned_deal_ids.push(deal.id);
        } else {
            this.current_search_session.banned_deal_ids = [ deal.id ];
        }
        OfflineDB.setBannedDeals(_current_search_token, this.current_search_session.banned_deal_ids);
        const session_hotel = this.current_search_results.getHotelById(hotel.id);
        deal.status = DealConstants.DEAL_STATUS.NA;
        session_hotel?.recalculateSuperseded(_search_sessions, deal);
    }

    getBannedDealIds() {
        if (!this.current_search_session) {
            console.warn('no current session');
            return;
        }
        return this.current_search_session.banned_deal_ids;
    }

    increaseReservationAttempts(hotel_id) {
        const attempts = this.getTotalReservationAttempts() + 1;
        WebStorageManager.addToWebStorage(ReservationConstants.RESERVATION_ATTEMPTS, attempts);

        if (!this.current_search_session) {
            console.warn('no current session');
            return;
        }
        this.current_search_session.reservation_attempts =  this.getSessionReservationAttempts() + 1;
        console.log(_current_search_token, this.current_search_session.reservation_attempts)
        OfflineDB.setReservationAttempts(_current_search_token, { count: this.current_search_session.reservation_attempts });

        if (!this.current_search_results || !hotel_id) {
            console.warn('no current session results');
            return;
        }
        const session_hotel = this.current_search_results.getHotelById(hotel_id);
        session_hotel?.addReservationAttempt();
    }

    getHotelReservationAttempts(hotel_id) {
        if (!this.current_search_results) {
            console.warn('no current session results');
            return;
        }
        const session_hotel = this.current_search_results.getHotelById(hotel_id);
        return session_hotel?.reservation_attempts;
    }

    getSessionReservationAttempts() {
        if (!this.current_search_session) {
            console.warn('no current session');
            return;
        }
        return this.current_search_session.reservation_attempts ?? 0;
    }

    getTotalReservationAttempts() {
        return WebStorageManager.getFromWebStorage(ReservationConstants.RESERVATION_ATTEMPTS) ?? 0;
    }

    getReservationAttemptsObject(hotel_id) {
        if (!this.current_search_session) {
            return {
                attempt_count: this.getTotalReservationAttempts(),
            };
        }
        return {
            attempt_count: this.getTotalReservationAttempts(),
            session_attempt_count: this.getSessionReservationAttempts(),
            hotel_attempt_count: this.getHotelReservationAttempts(hotel_id),
        }
    }

    cleanReservationAttempts() {
        WebStorageManager.removeFromWebStorage(ReservationConstants.RESERVATION_ATTEMPTS);
        if (this.current_search_session) {
            this.current_search_session.reservation_attempts = 0;
            OfflineDB.setReservationAttempts(_current_search_token, { count: 0 });
        }
    }

    clearSearchSession(searchToken) {
        const old_search_session = _search_sessions[searchToken];
        if (old_search_session) {
            old_search_session.clear();
        }

        delete _search_sessions[searchToken];
    }

    clearOldSearchSessions() {
        const search_tokens = Object.keys(_search_sessions)
            .filter((searchToken) => (searchToken !== _current_search_token));

        for (const searchToken of search_tokens) {
            this.clearSearchSession(searchToken);
        }
    }

    ////////////////////////////////////////

    get current_search_results() {
        return _.get(this.current_search_session, 'search_results');
    }

    getHotelById(hotelId) {
        const search_results = this.current_search_results;
        if (search_results) {
            return search_results.getHotelById(hotelId);
        }

        return null;
    }

    getTravelPolicy() {
        const search_results = this.current_search_results;
        if (search_results) {
            return search_results.travel_policy;
        }

        return null;
    }

    getSearchTerms() {
        const search_session = this.current_search_session;
        if (search_session) {
            return search_session.search_terms;
        }

        return null;
    }

    onSearch(searchResponse) {
        const search_session = this.current_search_session;
        if (search_session) {
            search_session.onSearch(searchResponse);
        }
    }

    onHotels(hotelsResponse) {
        const search_session = this.current_search_session;
        if (search_session) {
            search_session.onHotels(hotelsResponse);
        }
    }

    onRecheck(recheckResponse) {
        const search_session = this.current_search_session;
        if (search_session) {
            search_session.onRecheck(recheckResponse);
        }
    }

    getSearchResults() {
        const search_session = this.current_search_session;
        if (search_session) {
            return search_session.search_results;
        } else {
            return {};
        }
    }

    getHotels() {
        const search_session = this.current_search_session;
        if (search_session) {
            return search_session.search_results.hotels;
        } else {
            return [];
        }
    }

    getTimestamp() {
        const search_session = this.current_search_session;
        if (search_session) {
            return search_session.search_results.timestamp;
        } else {
            return new Date();
        }
    }

    getHotelsObject() {
        const search_session = this.current_search_session;
        if (search_session) {
            return search_session.search_results._hotels;
        } else {
            return {};
        }
    }
}

export default SessionManager;

// from inside
// this.emit(<EVENT_NAME>, <COMMA_SEPARATED_PARAMETERS>);
// e.g. this.emit('change', 'string', 1, {a:2, b:'3'});

// from outside
// session_manager.on(<EVENT_NAME>, (<COMMA_SEPARATED_PARAMETERS>) => {...});
// e.g. session_manager.on('change', (str, num, obj) => {...});
