import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import RouterWrapper from '../../../../utils/RouterWrapper';
import DealActions from '../../../../actions/DealActions';
import HotelActions from '../../../../actions/HotelActions';
import Analytics from 'arbitrip-common/client/analytics';
import ResultsStore from '../../../../stores/ResultsStore';

const NoDealComponent = ({ deal, hotel, searchToken, searchTerms, profile }) => {
	const handleOnSelectClick = () => {
		HotelActions.getHotelData(hotel);
		if (deal) {
			DealActions.chooseDeal(deal);
		}

		const recommended_index = ResultsStore.calculateRecommendedIndex(hotel);
		HotelActions.setHotelRecommendedIndex(hotel.id, recommended_index);

		const recommended_count = ResultsStore.getRecommendedCount();
		const sort_method = ResultsStore.getSortMethod();
		const time_to_click_ml = ResultsStore.getTimePassedSinceSearchSessionCreatedInMilliSeconds();
		Analytics.actions.interactions.selectedHotel(
			profile,
			searchTerms.destination,
			searchTerms,
			recommended_index,
			recommended_count,
			sort_method,
			time_to_click_ml,
		);

		HotelActions.setHotelScroll(window.scrollY, hotel.id);
	};

	const shallow = hotel?.shallow;

	return (
		<div className="no-deal-shallow-wrapper">
			<div className="shallow">
				{shallow ? (
					<span style={{ marginLeft: 'auto', marginRight: 'auto' }}>
						Select hotel to check for availability
					</span>
				) : hotel.hotelMatch ? (
					<span>
						It looks like there’s no availability for these dates.
						<br />
						But we got some great alternatives below.
					</span>
				) : (
					<span>It looks like there is no availability on these dates</span>
				)}
			</div>
			<div className="shallow-action select-action">
				{shallow ? (
					<Link to={RouterWrapper.buildHotelRoute(hotel, searchToken, searchTerms)} target="_blank">
						<Button className="select-button" onClick={handleOnSelectClick} variant="outline" disableRipple>
							Select
						</Button>
					</Link>
				) : (
					<Button disabled className="select-button">
						Select
					</Button>
				)}
			</div>
		</div>
	);
};

export default NoDealComponent;
