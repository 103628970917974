import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import { AGENT_PRICING_METHOD } from 'arbitrip-common/client/constants/ReservationConstants';
import { ButtonGroup, Button, TextField, InputAdornment, FormControlLabel, Checkbox } from '@mui/material';
import Currencies from 'arbitrip-common/general/utils/Currencies';

import ContentLoader from 'react-content-loader';
import ClientPriceService from 'arbitrip-common/client/utils/ClientPriceService';
import ReservationStore from '../../stores/ReservationStore';

function getContentLoader(width, height, radius = 0) {
	return (
		<ContentLoader width={width} height={height} viewBox={`0 0 ${width} ${height}`} className="content-loader">
			<rect x="0" y="0" rx={radius} yx={radius} width={width} height={height} />
		</ContentLoader>
	);
}

function normalizeValue(value) {
	return Number(Number(value).toFixed(2)).toString();
}

function normalizePrice(price, currency) {
	return isNaN(price) || !isFinite(price) ? 0 : Currencies.getPrecisePriceWithCurrency(Number(price), currency);
}

function normalizeMarginPercentage(marginPercentage) {
	return isNaN(marginPercentage) ? 0 : normalizeValue(marginPercentage) + '%';
}

function isValidPricingValue(value) {
	const _value = value.toString();

	if (_value.trim() === '') {
		return true;
	}

	if (!isNaN(_value) && Number(_value) >= 0) {
		return true;
	}

	return false;
}

class ChangeClientPrice extends React.Component {
	constructor(props) {
		super(props);

		let { basketMode, payMode, deal } = props;

		if (!basketMode && !payMode) {
			const reservation_data = ReservationStore.getReservationData();
			if (!_.isEmpty(reservation_data?.deal)) {
				deal = reservation_data.deal;
			}
		}

		let agent_pricing = {};
		if (!_.isEmpty(deal?.cheapopoPricing?.agentPricing)) {
			agent_pricing = deal.cheapopoPricing.agentPricing;
		} else if (!_.isEmpty(deal?.agent_pricing)) {
			agent_pricing = deal.agent_pricing;
		}

		const {
			pricing_method = AGENT_PRICING_METHOD.MARGIN_PERCENTAGE,

			absolute_margin_total,
			margin_percentage,
		} = agent_pricing;

		let pricing_value = '';
		if (pricing_method === AGENT_PRICING_METHOD.MARGIN_PERCENTAGE) {
			if (!isNaN(margin_percentage) && margin_percentage >= 0) {
				pricing_value = normalizeValue(margin_percentage);
			}
		} else if (pricing_method === AGENT_PRICING_METHOD.ABSOLUTE_MARGIN) {
			if (!isNaN(absolute_margin_total) && absolute_margin_total >= 0) {
				pricing_value = normalizeValue(absolute_margin_total);
			}
		}

		this.state = {
			agent_pricing,

			pricing_method,
			pricing_value,

			valid: true,

			sell_in_net_price: false,
		};

		this.handlePricingMethodChange = this.handlePricingMethodChange.bind(this);
		this.handlePricingValueChange = this.handlePricingValueChange.bind(this);

		this.isPricingMethod = this.isPricingMethod.bind(this);

		this.onCancelClick = this.onCancelClick.bind(this);
		this.onUpdateClick = this.onUpdateClick.bind(this);
		this.onSellInNetPriceChange = this.onSellInNetPriceChange.bind(this);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.pricing_method !== this.state.pricing_method) {
			console.log(
				'componentDidUpdate',
				'pricing_method',
				prevState.pricing_method,
				'=>',
				this.state.pricing_method,
			);
		}

		if (prevState.pricing_value !== this.state.pricing_value) {
			console.log('componentDidUpdate', 'pricing_value', prevState.pricing_value, '=>', this.state.pricing_value);
		}

		if (!_.isEmpty(prevState.agent_pricing) && _.isEmpty(this.state.agent_pricing)) {
			console.log('componentDidUpdate', 'agent_pricing', prevState.agent_pricing, '=>', this.state.agent_pricing);
		}
	}

	handlePricingMethodChange(event, pricingMethod) {
		const { agent_pricing = {}, valid } = this.state;

		if (valid) {
			const { margin_percentage, absolute_margin_total } = agent_pricing;

			if (pricingMethod === AGENT_PRICING_METHOD.MARGIN_PERCENTAGE) {
				console.log('handlePricingMethodChange', pricingMethod);
				this.setState({
					pricing_method: pricingMethod,
					pricing_value: isNaN(margin_percentage) ? '' : normalizeValue(margin_percentage),
				});
			} else if (pricingMethod === AGENT_PRICING_METHOD.ABSOLUTE_MARGIN) {
				console.log('handlePricingMethodChange', pricingMethod);
				this.setState({
					pricing_method: pricingMethod,
					pricing_value: isNaN(absolute_margin_total) ? '' : normalizeValue(absolute_margin_total),
				});
			} else {
				console.log('handlePricingMethodChange', 'FISHY');
			}
		}
	}

	handlePricingValueChange(event) {
		const pricing_value = event.target.value;

		this.setState({
			pricing_value,
		});

		console.log('handlePricingValueChange', pricing_value);

		const _self = this;
		setTimeout(() => {
			if (isValidPricingValue(pricing_value)) {
				if (
					this.isPricingMethod(AGENT_PRICING_METHOD.MARGIN_PERCENTAGE) ||
					this.isPricingMethod(AGENT_PRICING_METHOD.ABSOLUTE_MARGIN)
				) {
					let getAgentPricing = null;
					if (this.isPricingMethod(AGENT_PRICING_METHOD.MARGIN_PERCENTAGE)) {
						getAgentPricing = ClientPriceService.getAgentPricingByMarginPercentage;
					} else if (this.isPricingMethod(AGENT_PRICING_METHOD.ABSOLUTE_MARGIN)) {
						getAgentPricing = ClientPriceService.getAgentPricingByAbsoluteMargin;
					}

					if (_.isFunction(getAgentPricing)) {
						const agent_pricing = getAgentPricing(
							_self.props.deal,
							_self.state.agent_pricing,
							Number(pricing_value),
						);
						if (!_.isEmpty(agent_pricing)) {
							_self.setState({
								agent_pricing,

								valid: true,
							});
						}
					} else {
						console.warn('SOMETHING FISHY');
					}
				}
			} else {
				this.setState({ valid: false });
			}
		}, 100);
	}

	isPricingMethod(pricingMethod) {
		const { pricing_method = AGENT_PRICING_METHOD.MARGIN_PERCENTAGE } = this.state;

		return pricing_method === pricingMethod;
	}

	onUpdateClick() {
		const { valid, agent_pricing } = this.state;

		if (valid) {
			this.props.onUpdate(Object.assign({}, agent_pricing, { active: true }));
		} else {
			// TODO: alert?
		}
	}

	onCancelClick() {
		this.props.onCancel();
	}

	onSellInNetPriceChange(event) {
		this.setState({
			sell_in_net_price: event.target.checked,
		});

		const pricing_value_event = {
			target: {
				value: 0,
			},
		};
		this.handlePricingValueChange(pricing_value_event);
	}

	render() {
		const {
			initializing,
			disabled,

			profile,

			sellInNetPriceOption,

			chargeCurrency,
		} = this.props;

		const {
			pricing_value = '',

			agent_pricing = {},
			valid,

			sell_in_net_price,
		} = this.state;

		const { currency } = profile;

		const value_content_loader = getContentLoader(208, 33.44, 4);
		const summary_content_loader = getContentLoader(90, 20);

		const additional_currency = chargeCurrency && chargeCurrency !== currency;

		return (
			<React.Fragment>
				<div className="agent-pricing-container">
					<div className="agent-pricing">
						<div className="edit-pricing">
							<label>Set your commission in preferred method</label>
							<div className="pricing-method">
								<ButtonGroup
									aria-label="pricing method"
									disableElevation
									color="primary"
									disabled={!valid}
								>
									<Button
										value={AGENT_PRICING_METHOD.ABSOLUTE_MARGIN}
										aria-label="absolute commission"
										variant={
											this.isPricingMethod(AGENT_PRICING_METHOD.ABSOLUTE_MARGIN)
												? 'contained'
												: 'outlined'
										}
										onClick={
											valid
												? (event) =>
														this.handlePricingMethodChange(
															event,
															AGENT_PRICING_METHOD.ABSOLUTE_MARGIN,
														)
												: _.noop
										}
										disabled={!valid}
										disableRipple
										className="absolute-margin"
									>
										Absolute ({Currencies.getSymbol(currency)})
									</Button>
									<Button
										value={AGENT_PRICING_METHOD.MARGIN_PERCENTAGE}
										aria-label="margin percentage"
										variant={
											this.isPricingMethod(AGENT_PRICING_METHOD.MARGIN_PERCENTAGE)
												? 'contained'
												: 'outlined'
										}
										onClick={
											valid
												? (event) =>
														this.handlePricingMethodChange(
															event,
															AGENT_PRICING_METHOD.MARGIN_PERCENTAGE,
														)
												: _.noop
										}
										disabled={!valid}
										disableRipple
										className="margin-percentage"
									>
										Percentage (%)
									</Button>
								</ButtonGroup>
							</div>
							{
								<div className="pricing-value">
									{initializing ? (
										value_content_loader
									) : (
										<TextField
											className="pricing-text-field"
											variant="outlined"
											disabled={disabled || sell_in_net_price}
											{...(valid ? {} : { error: true, helperText: 'Invalid Commission' })}
											id="pricing-value"
											value={pricing_value}
											onChange={this.handlePricingValueChange}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														{this.isPricingMethod(AGENT_PRICING_METHOD.MARGIN_PERCENTAGE)
															? '%'
															: Currencies.getSymbol(currency)}
													</InputAdornment>
												),
											}}
										/>
									)}
								</div>
							}
						</div>
						<div className="pricing-summary">
							<label>Pricing Summary</label>
							<div className="pricing-item net-price">
								<div className="price-title">Net Price</div>
								<div className="price-value">
									{normalizePrice(agent_pricing.net_price_total, currency)}
								</div>
							</div>
							<div className="pricing-item markup">
								<div className="price-title">Commission</div>
								{initializing ? (
									summary_content_loader
								) : (
									<div className="price-value">
										{valid
											? agent_pricing.absolute_margin_total > 0
												? `${normalizePrice(agent_pricing.absolute_margin_total, currency)} (${normalizeMarginPercentage(agent_pricing.margin_percentage)})`
												: 0
											: 'Invalid'}
									</div>
								)}
							</div>
							<label>Selling Price</label>
							<div className="pricing-item avg-room-night">
								<div className="price-title">Avg/Room/Night</div>
								{initializing ? (
									summary_content_loader
								) : (
									<div className="price-value">
										{valid
											? normalizePrice(agent_pricing.client_price_per_night, currency)
											: 'Invalid'}
									</div>
								)}
							</div>
							<div className="pricing-item total">
								<div className="price-title">Total</div>
								{initializing ? (
									summary_content_loader
								) : (
									<div className="price-value">
										{valid
											? // ? normalizePrice(agent_pricing.absolute_margin_total, currency)
												// ? `${normalizePrice(agent_pricing.client_price_total, currency)} (${normalizeMarginPercentage(agent_pricing.margin_percentage)})`
												`${normalizePrice(agent_pricing.client_price_total, currency)}`
											: 'Invalid'}
									</div>
								)}
							</div>
							{additional_currency && (
								<div className="pricing-item total">
									<div className="price-title">Total in {chargeCurrency}</div>
									{initializing ? (
										summary_content_loader
									) : (
										<div className="price-value">
											{valid ? (
												<React.Fragment>
													<span className="approx">approx.</span>
													{Currencies.getPriceWithDisplayCurrencyByPrecision(
														agent_pricing.client_price_total,
														profile.freelance_agent_pay_later_exchange_rates[
															chargeCurrency
														],
														chargeCurrency,
														2,
													)}
												</React.Fragment>
											) : (
												'Invalid'
											)}
										</div>
									)}
								</div>
							)}
						</div>
						{sellInNetPriceOption && (
							<div className="sell-in-net-price">
								<FormControlLabel
									className="sell-in-net-price-label"
									label="Sell in Net Price"
									control={
										<Checkbox
											checked={sell_in_net_price}
											onChange={this.onSellInNetPriceChange}
											className="sell-in-net-price-checkbox"
										/>
									}
								/>
							</div>
						)}
					</div>
				</div>
				<div className="change-client-price-actions">
					<Button
						autoFocus
						onClick={this.onCancelClick}
						color="primary"
						disableRipple
						className="change-client-price-cancel"
					>
						Cancel
					</Button>
					<Button
						onClick={valid ? this.onUpdateClick : _.noop}
						color="primary"
						disabled={!valid}
						disableRipple
						className="change-client-price-update"
					>
						Update
					</Button>
				</div>
			</React.Fragment>
		);
	}
}

ChangeClientPrice.propTypes = {
	deal: PropTypes.object.isRequired,
	initializing: PropTypes.bool,
	disabled: PropTypes.bool,

	onCancel: PropTypes.func,
	onUpdate: PropTypes.func,

	profile: PropTypes.object,

	basketMode: PropTypes.bool,
};

export default ChangeClientPrice;
